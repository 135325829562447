import { Box, IconButton, Stack, Typography, Grid } from '@mui/material'
import React from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import moment from 'moment';
import { CalendarStyle } from './style'
import { startOfWeek, startOfMonth, endOfMonth, endOfWeek, addMonths, subMonths } from "date-fns";

export const Calendar = ({ getAmenities = () => false, selected_date = "" }) => {

    const [month, setMonth] = React.useState(new Date())
    const [finalDays, setFinalDays] = React.useState([])

    const monthStart = startOfMonth(month);
    const monthEnd = endOfMonth(monthStart);
    const monthstartDate = startOfWeek(monthStart);
    const monthendDate = endOfWeek(monthEnd);

    const next = () => {
        let tempMonth = addMonths(month, 1)
        let monthStart = startOfMonth(tempMonth);
        let monthEnd = endOfMonth(monthStart);
        setMonth(tempMonth)
        let finaldays = dateRange(startOfWeek(monthStart), endOfWeek(monthEnd))
        setFinalDays(finaldays)
    }

    const prev = () => {
        let tempMonth = subMonths(month, 1)
        let monthStart = startOfMonth(tempMonth);
        let monthEnd = endOfMonth(monthStart);
        setMonth(tempMonth)
        let finaldays = dateRange(startOfWeek(monthStart), endOfWeek(monthEnd))
        setFinalDays(finaldays)
    }

    //dateRange
    const dateRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());

        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }

    React.useEffect(()=>{
        let finaldays = dateRange(monthstartDate, monthendDate)
        setFinalDays(finaldays)
        // eslint-disable-next-line
    },[])

    return (
        <Box p={2}>
            <Header next={next} prev={prev} month={month} />
            <Body finaldays={finalDays} month={month} getAmenities={getAmenities} selected_date={selected_date} />
        </Box>
    )
}

// Calendar header section 
const Header = ({ next = () => false, prev = () => false, month = {} }) => {
    const classes = CalendarStyle()
    return (
        <Stack direction={"row"} spacing={4} alignItems={"center"} justifyContent={"space-between"}>
            <IconButton className={classes.iconbutton} onClick={prev}>
                <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography>{moment(month).format("MMMM YYYY")}</Typography>
            <IconButton className={classes.iconbutton} onClick={next}>
                <KeyboardArrowRightIcon />
            </IconButton>
        </Stack>
    )
}

// Calendar body section

const Body = ({ finaldays = {}, month = {}, getAmenities = () => false, selected_date = "" }) => {
    const classes = CalendarStyle()
    const days = ["sun","mon", "tue", "wed", "thu", "fri", "sat"]
    return (
        <Box pl={3} pt={1}>
            <Grid container>
                {
                    days.map((day) => {
                        return (
                            <Grid item xs={1.7} textAlign={"center"} className={classes.day} >
                                <Box><span>{day}</span></Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid container>
                {
                    finaldays.map(e => {
                        return (
                            <Grid item xs={1.7} className={moment(e).format("M") === moment(month).format("M") ? classes.date_curr_month : classes.date_not_curr_month} textAlign={"center"}
                                onClick={() => (moment(e).format("M") === moment(month).format("M") && moment(new Date()).format("YYYY-MM-DD") <= moment(e).format("YYYY-MM-DD")) && getAmenities(e)} >
                                <Stack className={moment(e).format("DD MM") === moment(selected_date).format("DD MM") && classes.today} p={1}>
                                    <Box mt={1}><span>{moment(e).format("DD")}</span></Box>
                                </Stack>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}