import { Box, Grid, IconButton, List, ListItem, Stack, Typography } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import React from "react";
import {
  GroupPieCharts,
  PieCharts,
  SearchFilter,
  SelectBox,
  TableWithPagination
} from "../../../components";
import { useStylesCreation } from "./style";
import { Bar, Line } from "react-chartjs-2";

export const GraphComponent = (props) => {
  const classes = useStylesCreation(props);
  const lineChartOptions = {
    plugins: {
      legend: {
        display: false
      }
    }
  }
  return (
    <div className={classes.card}>
      <Box className={classes.flexBoxWithSpace}>
        {props?.isTab ? (
          <Box>
            <TabComponent
              tabList={props?.tabList}
              tabNameState={props?.tabNameState}
              keyType={props?.keyType}
              updateState={props?.updateState}
            />
          </Box>
        ) : (
          <Box>
            <Typography className={classes.titleFont}>
              {props?.title}
            </Typography>
            {props?.titleTab && (
              <div style={{ margin: props?.margin }}>
                <TabComponent
                  tabList={props?.tabList}
                  tabNameState={props?.tabNameState}
                  keyType={props?.keyType}
                  updateState={props?.updateState}
                />
              </div>
            )}

          </Box>
        )}
        {props?.isSearch &&
          <Box sx={{ width: "250px" }}>
            <SearchFilter label={false} placeholder={"Search"} handleChange={(value) => props?.handleSearch(value)} value={props?.searchText} />
          </Box>
        }
        {props?.isZoom &&
          <IconButton onClick={() => props?.onZoomClick(props?.title)}>

            <img
              src="/images/propertdashboard/enlare.png"
              alt="zoom"
              onClick={() => props?.onZoomClick(props?.title)}
            />

          </IconButton>
        }
      </Box>

      <>
        {props?.isTable ? (
          <>
            <TableWithPagination
              heading={props?.heading}
              rows={props?.tableData ?? []}
              path={props?.path}
              showpagination={props?.showpagination ?? false}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              tableType="no-side"
              count="2"
              dataType={props?.dataType}
              height={props?.tabelheight ?? `calc(100vh - 355px)`}
              handlePagination={props?.handlePagination}
              handleChangeLimit={props?.handleChangeLimit}
              totalRowsCount={props?.totalRowsCount}
              page={props?.page}
              limit={props?.limit}
              view={true}
              edit={true}
              delete={true} />
          </>
        ) : (
          <>
            {props?.isImageList ? (
              <Box>
                <ImageList
                  variant="quilted"
                  cols={3}
                  gap={5}
                  sx={{
                    width: "100%",
                    height: props?.imageheight ?? "100%",
                    overflow: "auto",
                  }}
                >
                  {props?.iamgeData?.map((item) => (
                    <ImageListItem key={item.name}>
                      <Box
                        className={classes.dividerBox}
                        style={{ backgroundColor: item?.color }}
                      >
                        <Typography className={classes.dividerBoxText}>
                          {item?.name}
                        </Typography>
                        <Box height={"42px"} />
                        <Typography className={classes.dividerBoxText}>
                          {item?.meter}
                        </Typography>
                      </Box>
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
            ) : (
              <Grid container spacing={2} className={classes.graphParentFlex}>
                <Grid item xs={12} lg={props?.divider ?? 12}>
                  {props?.line ? (
                    <div style={{ margin: props?.margin }}>
                      {props?.graphData?.datasets?.length > 0 && (
                        <GroupPieCharts
                          data={props?.graphData}
                          scale={props?.scale}
                          indexAxis={props?.indexAxis}
                          line={props?.line}
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      {props?.isBar ? (
                        <Box mt={2}>
                          <Grid item xs={4} md={6} lg={12}
                          >
                            {props?.graphData?.datasets?.length > 0 ? (
                              // <GroupPieCharts
                              //   data={props?.graphData}
                              //   scale={props?.scale}
                              //   indexAxis={props?.indexAxis}
                              //   datalabels={props?.isDatalabels}
                              //   legend={props?.isLegend}
                              // />
                              <Bar data={props?.graphData} options={lineChartOptions} />
                            ) :
                              <Grid item xs={12}>
                                <Box alignItems={"center"} display={"flex"} justifyContent={"center"} height={"180px"}>
                                  <Typography textAlign={"center"}>No Data Found</Typography>
                                </Box>
                              </Grid>
                            }
                          </Grid>
                        </Box>
                      ) : props?.isUtility ? (
                        <Box>
                          <Grid container spacing={1} mt={0.1}>
                            <Grid item xs={4}>
                              <SelectBox
                                isRequired={true}
                                label={""}
                                options={[]}
                                value={props?.data?.property}
                                placeholder="Property"
                              // onChange={(value) => updateState('gender', value)}
                              // isReadonly={disable}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <SelectBox
                                isRequired={true}
                                label={""}
                                options={[]}
                                value={props?.data?.unit}
                                placeholder="unit"
                              // onChange={(value) => updateState('gender', value)}
                              // isReadonly={disable}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <SelectBox
                                isRequired={true}
                                label={""}
                                options={[]}
                                value={props?.data?.maintenance}
                                placeholder="Electrical"
                              // onChange={(value) => updateState('gender', value)}
                              // isReadonly={disable}
                              />
                            </Grid>
                          </Grid>
                          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={1}>
                            {props?.graphData?.datasets?.length > 0 && (

                              // <GroupPieCharts
                              //   data={props?.graphData}
                              //   //scale={props?.scale}
                              //   //indexAxis={props?.indexAxis}
                              //   line={true}
                              //   width={"350px"}
                              //   height={"150px"}
                              //   datalabels={false}
                              //   legend={false}
                              // />
                              <Box width={350} height={150}>
                                <Line data={props?.graphData} options={lineChartOptions} />
                              </Box>
                            )}
                            <Box>
                              {props?.utilityData?.map((x) => {
                                return (
                                  <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                                    <img src={x?.image} alt="current_reading" width={"32px"} height={"32px"} />
                                    < Box >
                                      <Typography className={classes.reading}>{x.reading}</Typography>
                                      <Typography className={classes.utilityTitle}>{x?.title}</Typography>

                                    </Box>
                                  </Stack>

                                )
                              })}
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <div>
                          {props?.timeOff &&
                            <Box className={classes.type} mt={1}>
                              <Stack direction="row" spacing={1}>
                                <Box className={props?.type === "PTO" ? classes.typebtn : classes.nobtn} onClick={() => props?.onChangeType("PTO")}>
                                  <Typography className={props?.type === "PTO" ? classes.textcontd : classes.textcont}>Paid Time Off</Typography>
                                </Box>
                                <Box className={props?.type === "LOP" ? classes.typebtn : classes.nobtn2} onClick={() => props?.onChangeType("LOP")}>
                                  <Typography className={props?.type === "LOP" ? classes.textcontd : classes.textcont}>Loss of Pay</Typography>
                                </Box>
                              </Stack>
                            </Box>
                          }
                          <div className={classes.graphMargin}>
                            <Grid container spacing={2}>
                              {props?.graphData?.length > 0 ?
                                <>
                                  <Grid item xs={5} md={6} lg={props?.title === "Job Completion History" ? 12 : 5}>
                                    <PieCharts
                                      data={props?.graphData}
                                      radius={props?.innerRadius}
                                      width={160}
                                      isTotal={props?.isTotal}
                                      total={props?.total}
                                      height={180}
                                    />
                                  </Grid>
                                  <>
                                    {props?.isPie && (
                                      props?.title === "Job Completion History" ?
                                        <Grid item xs={5} md={6} lg={props?.divider1 ?? 12} className={props?.title === "Job Completion History" ? classes.newGridFlex : classes.gridGraphParent}>


                                          <>
                                            {props?.graphData?.map((x) => {
                                              return (

                                                <Stack direction={"row"} alignItems={"center"} ml={2}>

                                                  <Box
                                                    className={classes.dot}
                                                    style={{ backgroundColor: x?.fill }}
                                                  />
                                                  <Typography
                                                    className={classes.graphDataTitle}
                                                    noWrap
                                                  >
                                                    {x?.type ?? x?.name}
                                                  </Typography>
                                                </Stack>

                                              );
                                            })}
                                          </>

                                        </Grid>
                                        :
                                        <Grid item xs={5} md={6} lg={props?.divider1 ?? 6} className={classes.gridGraphParent}>


                                          <Grid container spacing={2}>
                                            {props?.graphData?.map((x) => {
                                              return (
                                                <Grid item xs={props?.title === "Inspection Job Type"?6:12}>
                                                  <Stack direction={"row"} alignItems={"center"} ml={2}>

                                                    <Box
                                                      className={classes.dot}
                                                      style={{ backgroundColor: x?.fill }}
                                                    />
                                                    <Typography
                                                      className={classes.graphDataTitle}
                                                      noWrap
                                                    >
                                                      {x?.type ?? x?.name}
                                                    </Typography>
                                                  </Stack>
                                                </Grid>
                                              );
                                            })}
                                          </Grid>

                                        </Grid>
                                    )}
                                  </>
                                </>
                                :
                                <Grid item xs={12}>
                                  <Box alignItems={"center"} display={"flex"} justifyContent={"center"} height={"180px"}>
                                    <Typography textAlign={"center"}>No Data Found</Typography>
                                  </Box>
                                </Grid>
                              }

                            </Grid>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </Grid>

                {/* pie chart */}

              </Grid>
            )}
          </>
        )
        }
      </>
    </div >
  );
};

// tab component
const TabComponent = (props) => {
  const classes = useStylesCreation(props);
  return (
    <List className={classes.list}>
      {props?.tabList?.map((x) => {
        return (
          <ListItem
            className={
              x === props?.tabNameState ? classes.listText : classes.listText1
            }
            onClick={() => props?.updateState(props?.keyType, x)}
          >
            <Typography className={classes.tabname}>{x}</Typography>
          </ListItem>
        );
      })}
    </List>
  );
};
