import { makeStyles } from "@mui/styles";
import { SemiBold } from "../../utils";

export const CreateInvoiceFreeTextStyle = makeStyles((theme)=>({
    addreason:{
        fontSize:12,
        fontWeight:SemiBold,
        color:theme.palette.primary.main,
        cursor:"pointer"
    },
    invoiceType:{
        background:theme.palette.info.light,
        padding:"2px 4px",
        borderRadius:4
    },
    invoiceText:{
        fontSize:12,
        fontWeight:"bold",
        color:theme.palette.info.main
    },
    debitType:{
        background:theme.palette.warning.light,
        padding:"2px 4px",
        borderRadius:4
    },
    debitText:{
        fontSize:12,
        fontWeight:"bold",
        color:theme.palette.warning.main
    },
    creditType:{
        background:theme.palette.success.light,
        padding:"2px 4px",
        borderRadius:4
    },
    creditText:{
        fontSize:12,
        fontWeight:"bold",
        color:theme.palette.success.main
    },
    remarktxt:{
        '& .MuiInput-input':{
            height:"12px !important",
            width:"100%"
        }
    },
    bodyContent:{
        height:"calc(100vh - 230px)",
        overflow:"auto"
    },
    editIcon:{
        height:40,
        width:40,
        textAlign:"center",
        border:`1px solid ${theme.palette.primary.main}`,
        borderRadius:4,
        alignItems:"center",
        justifyContent:"center",
        display:"flex"
    },
    topBtn:{
        height:40
    },
    noneBtn:{
        display:"none"
    }
}))

export const CustomerPopupStyle = makeStyles((theme)=>({
    box:{
        border:`1px solid ${theme.palette.border.secondary}`,
        borderRadius:4,
        cursor:"pointer"
    },
    selectedBox:{
        border:`1px solid ${theme.palette.primary.main}`,
        borderRadius:4,
        background:theme.palette.background.selected,
        cursor:"pointer"
    },
    avatar:{
        height:40,
        width:40
    },
    addbtn:{
        height:40
    },
    tabs:{
        '& .MuiTabs-flexContainer MuiTabs-flexContainer':{
            justifyContent:"space-between"  
        }
    },
    name:{
        fontSize:14,
        color:theme.typography.color.primary,
        fontWeight:"bold"
    },
    desc:{
        fontSize:12,
        color:theme.typography.color.secondary
    },
    pricingLabel:{
        fontSize:14,
        fontWeight:"bold",
        color:theme.typography.color.secondary,
        // textAlign:"center"
    }
}))