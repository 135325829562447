import { Grid } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { SearchFilter, TableWithPagination } from "../../components";
import { Aggreementheading, Aggreementpath } from "../../utils/quotations";
const AggreementsTable = ({
    handleSearch = () => false,
    searchText = "",
    revenue = {},
    handleIcon = () => false,
    list = [],
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    t = () => false,
    permission = {}
}) => {


    return (
        <div >
            <Grid container justifyContent="space-between">
                <Grid item xs={4}>
                    {/*search field*/}
                    <SearchFilter value={searchText} placeholder={t("seacrhAgreement")} handleChange={(value) => handleSearch(value)} />
                </Grid>
                <Grid item xs={2}>
                    {/*reavnue Type select box*/}
                    {/* <SelectBox isReadOnly value={revenue} options={revenueType} color="#F5F7FA" /> */}
                </Grid>
            </Grid>
            {/*table container*/}
            <TableWithPagination
                heading={Aggreementheading}
                rows={list?.list}
                path={Aggreementpath}
                showpagination={true}
                count="2"
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                handleIcon={handleIcon}
                onClick={() => console.log("")}
                dataType={[
                    { type: ["text"], name: "agreement_no" },
                    { type: ["text"], name: "account_no" },
                    { type: ["text"], name: "account_name" },
                    { type: ["date"], name: "generate_date" },
                    { type: ["date"], name: "start_date" },
                    { type: ["date"], name: "end_date" },
                    { type: ["q_status"], name: "status" },
                    { type: ["info"], icon: "icon" }
                ]}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={list?.count}
                page={page}
                limit={limit}
                height={`calc(100vh - 300px)`}
                view={permission?.read}
                edit={permission?.update}
                delete={permission?.delete} />
        </div>
    )
}
export default withNamespaces("agreement")(AggreementsTable);