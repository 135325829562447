import React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { CustomPaper, CustomTypography, TitleDesc, IconWithText } from '../../companyCreation/components';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
// import MyGoogleMap from '../../../components/interactivemap/myGoogleMaps';
import { PhoneIcon, Location, BuldingIcon } from '../../../components/svg';
import { returnValue } from '../../companyCreation/utils';
import { Bold, SemiBold } from '../../../utils';
const Image = styled(Avatar)({
    width: 141,
    height: 141,
    borderRadius: 71,
    backgroundColor: "#F2F4F7",
})

export const VendorBasicDetailsView = (props) => {

    const theme = useTheme();

    const {
        data = {}
    } = props;

    const {
        profileDetails = null,
        address = null,
        contactInformation = null,
        logo = null,
    } = data;

    const constructAddress = (value = "", delimiter = "") => value ? value?.concat(",") : delimiter;


    return (
        <div>
            <CustomPaper height={'100%'} marginBottom={theme.spacing(3)}>
                <Stack
                    direction={{ sm: "row", xs: "column" }}
                    divider={<Divider flexItem orientation={"vertical"} />}
                    gap={{ xs: 2, sm: 3 }}
                    alignItems={{ sm: "center" }}
                >
                    {
                        <Image
                            src={logo?.src ? logo?.src : ""}
                            alt={"profile_img"}
                            sx={{
                                border: logo?.src ? 0 : '1px solid #e3e5ec'
                            }}
                        >
                            {!(!!logo?.src) && <BuldingIcon />}
                        </Image>
                    }
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CustomTypography
                                fontSize={12}
                                fontFamily={Bold}
                                // marginBottom={theme?.spacing(2)}
                                color={theme?.typography?.color?.secondary}
                            >
                                Profile Details
                            </CustomTypography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TitleDesc
                                title={"Vendor's Company"}
                                desc={returnValue(profileDetails?.vendorCompany?.label, "-")}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TitleDesc
                                title={"Vendor Code"}
                                desc={returnValue(profileDetails?.vendorCode, "-")}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TitleDesc
                                title={"Vendor Name"}
                                desc={returnValue(profileDetails?.vendorName, "-")}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TitleDesc
                                title={"Vendor's Country"}
                                desc={returnValue(profileDetails?.vendorCountry?.label, '-')}
                            />
                        </Grid>
                        <Grid item xs={6} md={12}>
                            <TitleDesc
                                title={"Vendor Description"}
                                dangerouslySetInnerHTML={returnValue(profileDetails?.vendorDescription, "-")}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </CustomPaper>
            <CustomPaper>
                <Grid container spacing={2}>
                    {/* <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4.5}
                        sx={{
                            height: { xs: "300px" }
                        }}
                    >
                        <div
                            style={{
                                borderRadius: 8,
                                overflow: "hidden",
                                width: "100%",
                                height: "100%"
                            }}
                        >
                            <MyGoogleMap
                                lat={address?.latitude}
                                lng={address?.longitude}
                                center={{
                                    lat: address?.latitude,
                                    lng: address?.longitude
                                }}
                                readonly
                            />
                        </div>
                    </Grid> */}
                    {/* when map component is needed plz use below commented grid item */}

                    {/* <Grid item xs={12} sm={6} md={3}> */}
                    <Grid item xs={12} sm={6} md={6}>
                        <CustomPaper
                            border={"1px solid #E4E8EE"}
                            boxShadow={"none"}
                            borderRadius={8}
                            padding={theme.spacing(2)}
                            height={'100%'}
                        >
                            <IconWithText
                                icon={<Location />}
                                text={"Address"}
                            />
                            <CustomTypography
                                fontFamily={SemiBold}
                                marginBottom={theme?.spacing(2)}
                                marginTop={theme?.spacing(2)}
                                color={theme?.typography?.color?.primary}
                            >
                                {
                                    returnValue((`
                                    ${constructAddress(address?.doorNumber)} 
                                    ${constructAddress(address?.addressLineTwo)}
                                    ${constructAddress(address?.addressLineTwo)}
                                    ${constructAddress(address?.landmark)}
                                    ${constructAddress(address?.area)}
                                    ${constructAddress(address?.state)}
                                    ${constructAddress(address?.country)}
                                    ${returnValue(address?.pincode)}
                                    `)
                                        .trim(), "-"
                                    )
                                }
                            </CustomTypography>
                            <CustomTypography
                                fontSize={12}
                                fontFamily={SemiBold}
                                marginBottom={theme?.spacing(2)}
                                color={theme?.typography?.color?.tertiary}
                            >
                                Latitude : {" "}
                                <CustomTypography
                                    component={"span"}
                                    fontSize={12}
                                    fontFamily={SemiBold}
                                    color={theme?.typography?.color?.primary}
                                >
                                    {returnValue(address?.latitude, '-')}
                                </CustomTypography>
                            </CustomTypography>
                            <CustomTypography
                                fontSize={12}
                                fontFamily={SemiBold}
                                // marginBottom={theme?.spacing(2)}
                                color={theme?.typography?.color?.tertiary}
                            >
                                Longitude : {" "}
                                <CustomTypography
                                    component={"span"}
                                    fontSize={12}
                                    fontFamily={SemiBold}
                                    color={theme?.typography?.color?.primary}
                                >
                                    {returnValue(address?.longitude, '-')}
                                </CustomTypography>
                            </CustomTypography>
                        </CustomPaper>
                    </Grid>
                    {/* when map component is needed plz use below commented grid item */}
                    {/* <Grid item xs={12} sm={6} md={4.5}> */}
                    <Grid item xs={12} sm={6} md={6}>
                        <CustomPaper
                            border={"1px solid #E4E8EE"}
                            boxShadow={"none"}
                            borderRadius={8}
                            padding={theme.spacing(2)}
                            height={'100%'}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <IconWithText
                                        icon={<PhoneIcon />}
                                        text={"Contact & Other Information"}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TitleDesc
                                        title={"Primary Telephone"}
                                        desc={(
                                            returnValue((`${returnValue(contactInformation?.primaryTelephone?.mobile_code)} ${returnValue(contactInformation?.primaryTelephone?.mobile)}`)?.trim(), "-")
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TitleDesc
                                        title={"Primary Mobile"}
                                        desc={(
                                            returnValue((`${returnValue(contactInformation?.primaryMobile?.mobile_code)} ${returnValue(contactInformation?.primaryMobile?.mobile)}`)?.trim(), "-")
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TitleDesc
                                        title={"Email Address :"}
                                        desc={returnValue(contactInformation?.primaryEmailAddress, "-")}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TitleDesc
                                        title={"Website"}
                                        desc={returnValue(contactInformation?.website, "-")}
                                    />
                                </Grid>
                            </Grid>
                        </CustomPaper>
                    </Grid>
                </Grid >
            </CustomPaper >
        </div >
    )
}