import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import FilterIcon from "../../../assets/filterIcon";
import { SearchFilter, TableWithPagination } from "../../../components";
import { VehicleMasterDataType, VehicleMasterHeading, VehicleMasterListPath } from "../utils";
import { VehicleMasterComponentStyles } from "./styles";
const VehicleMasterTable = ({
    permission = {},
    handleIcon = () => false,
    list = [],
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    path = [],
    heading = [],
    dataType = [],
    placeholder = "",
    searchText = "",
    handleSearch = () => false,
    onClick = () => false,
    height = "",
    add = false,
    handleAddInspection = () => false,
    onFilter = () => false,
    dot = false,
    options = [],
    onAdd = () => false,
    permissions = {},
    t
}) => {

    const classes = VehicleMasterComponentStyles()


    return (
        <Box>
            <Grid container>
                {/* searchbar */}
                <Grid item xs={4}>
                    <SearchFilter
                        handleChange={handleSearch}
                        value={searchText}
                        placeholder={t("Search Vehicles")} />
                </Grid>
                {/* filterIcon */}
                <Grid item md={8} className={classes.filterIconBox}>

                    <Stack
                        direction="row"
                        divider={<Divider className={classes.dividerStyle} orientation="vertical" flexItem />}
                        spacing={2}
                        justifyContent={"end"}
                    >
                        <Box className={classes.filterIconBox} onClick={onFilter}>
                            <FilterIcon />
                        </Box>
                        {
                            permissions?.create &&
                            <Box className={classes.filterIconBox}>
                                <Button variant="contained"
                                    onClick={onAdd}>
                                    {t("addNew")}
                                </Button>
                            </Box>
                        }


                    </Stack>

                </Grid>
            </Grid>


            {/* Vehicle table */}
            <TableWithPagination
                heading={VehicleMasterHeading}
                rows={list?.list}
                path={VehicleMasterListPath}
                dataType={VehicleMasterDataType}
                showpagination={true}
                count="2"
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                onClick={onClick}
                handleIcon={handleIcon}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={list?.count}
                page={page}
                limit={limit}
                height={height}
                view={permission?.read}
                edit={permission?.update}
                delete={permission?.delete}
            />

        </Box>

    )
}
export default withNamespaces("inspection")(VehicleMasterTable)