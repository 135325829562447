import { makeStyles } from "@mui/styles";

export const InspectionMappingStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
    margin: "12px",
    backgroundColor: theme.palette.background.tertiary1,
    border: `1px solid ${theme.palette.border.secondary}`,
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: theme.palette.borderRadius,
  },
  content: {
    height: `calc(100vh - 100px)`,
    overflow: "auto"
  },
  viewCard: {
    // margin: "10px 20px 20px 20px"
  }
}))