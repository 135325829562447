import { LocalStorageKeys } from '../utils'
import {
    GET_GENERAL_CATEGORY,
    GET_GENERAL_SUB_CATEGORY,
    GET_STATUS_MASTER,
    GET_MAINTENANCE_CATEGORY,
    GET_MAINTENANCE_SUB_CATEGORY,
    GET_MAINTENANCE_STATUS,
    ADD_SERVICE_MASTER,
    EDIT_SERVICE_MASTER

} from '../graphql/servicesMasterQuery';
import {
    Generalheading,
    GeneralPath,
    GeneralType,
    Generaldrawer,
    SubGeneralheading,
    SubGeneralPath,
    SubGeneralType,
    SubGeneraldrawer,
    GeneralStatusPath,
    GeneralStatusheading,
    GeneralStatusdrawer,
    MaintenancePath,
    Maintenanceheading,
    MaintenanceType,
    Maintenancedrawer,
    SubMaintenancePath,
    SubMaintenanceheading,
    SubMaintenanceType,
    SubMaintenancedrawer,
    StatusMaintenancePath,
    StatusMaintenanceheading,
    StatusMaintenanceType,
    StatusMaintenancedrawer
} from '.'



export const serviceActions = (type, offset, limit, search, isActive = true ) => {
    const client = localStorage.getItem(LocalStorageKeys.clinetID)
    switch (type) {
        case 'General':
            return {
                type: GET_GENERAL_CATEGORY(offset, limit, search, {isActive , client}).loc.source.body,
                resfunc: (array) => {
                    let arr = array?.general_category_master?.map((data, index) => {
                        let mainData = {}
                        mainData.id = data?.id;
                        mainData.type = data?.type;
                        mainData.is_active = data?.is_active;
                        mainData.status = data?.is_active ? "Active" : "Inactive";
                        mainData.is_external = data?.is_external;
                        mainData.index = offset + index + 1;
                        mainData.icon = "editviewtoggle";
                        return mainData
                    })
                    return arr
                },
                tbdata: {
                    heading: Generalheading,
                    path: GeneralPath,
                    type: GeneralType,
                    drawer: Generaldrawer

                }

            }
        case 'General Sub-Category':
            return {
                type: GET_GENERAL_SUB_CATEGORY(offset, limit, search , client).loc.source.body,
                resfunc: (array) => {
                    let arr = array?.general_sub_category_master?.map((data, index) => {
                        let mainData = {}
                        mainData.id = data?.id;
                        mainData.type = data?.type;
                        mainData.generalcategory = {
                            label: data?.general_category_master?.type,
                            value: data?.general_category_master?.id
                        }
                        mainData.valueID = data?.general_category_master?.id;
                        mainData.is_active = data?.is_active;
                        mainData.status = data?.is_active ? "Active" : "Inactive";
                        mainData.index = offset + index + 1;
                        mainData.is_external = data?.is_external;
                        mainData.icon = "editviewtoggle";
                        return mainData
                    })
                    return arr
                },
                tbdata: {
                    heading: SubGeneralheading,
                    path: SubGeneralPath,
                    type: SubGeneralType,
                    drawer: SubGeneraldrawer
                }
            }
        case 'General status':
            return {
                type: GET_STATUS_MASTER(offset, limit, search , client).loc.source.body,
                resfunc: (array) => {
                    let arr = array?.general_status_master?.map((data, index) => {
                        let mainData = {}
                        mainData.id = data?.id;
                        mainData.type = data?.type
                        mainData.is_active = data?.is_active;
                        mainData.status = data?.is_active ? "Active" : "Inactive";
                        mainData.is_external = data?.is_external;
                        mainData.index = offset + index + 1;
                        mainData.icon = "editviewtoggle";
                        return mainData
                    })
                    return arr
                },
                tbdata: {
                    heading: GeneralStatusheading,
                    path: GeneralStatusPath,
                    type: GeneralType,
                    drawer: GeneralStatusdrawer
                }
            }
        case 'Maintenance':
            return {
                type: GET_MAINTENANCE_CATEGORY(offset, limit, search, {isActive , client}).loc.source.body,
                resfunc: (array) => {
                    let arr = array?.maintenance_category_master?.map((data, index) => {
                        let mainData = {}
                        mainData.id = data?.id;
                        mainData.type = data?.type;
                        mainData.is_active = data?.is_active;
                        mainData.status = data?.is_active ? "Active" : "Inactive";
                        mainData.is_external = data?.is_external;
                        mainData.index = offset + index + 1;
                        mainData.icon = "editviewtoggle";
                        return mainData
                    })
                    return arr
                },
                tbdata: {
                    heading: Maintenanceheading,
                    path: MaintenancePath,
                    type: MaintenanceType,
                    drawer: Maintenancedrawer
                }
            }
        case 'Maintenance Sub-Category':
            return {
                type: GET_MAINTENANCE_SUB_CATEGORY(offset, limit, search,client).loc.source.body,
                resfunc: (array) => {
                    let arr = array?.maintenance_sub_category_master?.map((data, index) => {
                        let mainData = {}
                        mainData.id = data?.id;
                        mainData.type = data?.type;
                        mainData.maintenancecategory = {
                            label: data?.maintenance_category_master?.type,
                            value: data?.maintenance_category_master?.id
                        }
                        mainData.valueID = data?.maintenance_category_master?.id;
                        mainData.is_active = data?.is_active;
                        mainData.status = data?.is_active ? "Active" : "Inactive";
                        mainData.is_external = data?.is_external;
                        mainData.icon = "editviewtoggle";
                        mainData.index = offset + index + 1;
                        return mainData
                    })
                    return arr
                },
                tbdata: {
                    heading: SubMaintenanceheading,
                    path: SubMaintenancePath,
                    type: SubMaintenanceType,
                    drawer: SubMaintenancedrawer
                }
            }
        case 'Maintenance status':
            return {
                type: GET_MAINTENANCE_STATUS(offset, limit, search , client).loc.source.body,
                resfunc: (array) => {
                    let arr = array?.maintenance_status_master?.map((data, index) => {
                        let mainData = {}
                        mainData.id = data?.id;
                        mainData.type = data?.type;
                        mainData.is_active = data?.is_active;
                        mainData.status = data?.is_active ? "Active" : "Inactive";
                        mainData.is_external = data?.is_external;
                        mainData.index = offset + index + 1;
                        mainData.icon = "editviewtoggle";
                        return mainData
                    })
                    return arr
                },
                tbdata: {
                    heading: StatusMaintenanceheading,
                    path: StatusMaintenancePath,
                    type: StatusMaintenanceType,
                    drawer: StatusMaintenancedrawer
                }
            }
        default:
            return ''
    }
}


export const serviceAddActions = {
    'General': {
        type: ADD_SERVICE_MASTER('general_category_master', 'general_category_masterInput'),
    },
    'General Sub-Category': {
        type: ADD_SERVICE_MASTER('general_sub_category_master', 'general_sub_category_masterInput'),
    },
    'General status': {
        type: ADD_SERVICE_MASTER('general_status_master', 'general_status_masterInput'),
    },
    'Maintenance': {
        type: ADD_SERVICE_MASTER('maintenance_category_master', 'maintenance_category_masterInput'),
    },
    'Maintenance Sub-Category': {
        type: ADD_SERVICE_MASTER('maintenance_sub_category_master', 'maintenance_sub_category_masterOutput'),
    },
    'Maintenance status': {
        type: ADD_SERVICE_MASTER('maintenance_status_master', 'maintenance_status_masterOutput'),
    },
}

export const serviceEditActions = {
    'General': {
        type: EDIT_SERVICE_MASTER('general_category_master')
    },
    'General Sub-Category': {
        type: EDIT_SERVICE_MASTER('general_sub_category_master')
    },
    'General status': {
        type: EDIT_SERVICE_MASTER('general_status_master')
    },
    'Maintenance': {
        type: EDIT_SERVICE_MASTER('maintenance_category_master')
    },
    'Maintenance Sub-Category': {
        type: EDIT_SERVICE_MASTER('maintenance_sub_category_master')
    },
    'Maintenance status': {
        type: EDIT_SERVICE_MASTER('maintenance_status_master')
    },
}
