import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { VenueCardStyle } from './style'

const VenueCard = ({ list = {}, showAvailableSlot = () => false, selectedVenue = "", t }) => {
    const classes = VenueCardStyle()
    return (
        <Box className={classes.root}>
            {
                list.map(li => {
                    return (
                        <Box className={(selectedVenue?.facility_id === li.facility_id) ? classes.selectedbox : classes.box} p={2} mt={1}
                            onClick={() => showAvailableSlot(li)}>
                            <Stack direction={"row"} alignItems="center" spacing={3}>
                                <Avatar src={li.assets[0]}></Avatar>
                                <Stack>
                                    <Typography className={classes.name}> {li.amenities_name} </Typography>
                                    <Typography className={classes.slot}> {li.free_slots} {t("slotavailable")}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    )
                })
            }
        </Box>
    )
}

export default withNamespaces("amenityBooking")(VenueCard)
