import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React from "react";
import { TableWithPagination } from "../../../components";
import { AggrementComponentStyles } from "./styles";
export const PaymentScheduleAccordion = ({ title = "", row = [], path = [], type = [], heading = [] }) => {

    const classes = AggrementComponentStyles()
    return (
        <div className={`${classes.PaymentScheduleAccordion}`}>
            <Accordion sx={{ boxShadow: "none" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={`${classes.paymentScheduletitle}`}>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableWithPagination
                        heading={heading}
                        path={path}
                        rows={row}
                        dataType={type}
                        showpagination={false}
                        count="2"
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        tableType="no-side"
                        view={true}
                        edit={true}
                        delete={true} />
                </AccordionDetails>
            </Accordion>

        </div>
    )
}