/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Box, Divider, Grid, IconButton, Stack } from '@mui/material';
import React from 'react';
import FilterIMG from '../../assets/filter';
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { accessCheckRender, AlertProps, enumName, enumSelect, enum_types, getARS, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods, useWindowDimensions, convertTimeUtcToZoneCalander } from '../../utils';
import { useStyles } from "./style";
import { Heading, Path, Type, StatusOptionList } from '../../utils/agreementRenewal/agreementRenewalUtils';
import moment from "moment";
import { config } from '../../config';
import { NetworkCall } from '../../networkcall';
import { RequestView } from './components/requestView';
import { Remarks } from './components/remarks';
import { AcceptConfirm } from './components/acceptConfirm';
import { UnitView } from './components/unitView';

export const AgreementRenewal = () => {

    const defaultRequestState = {
        id: "",
        formType: "",
        status: "",
        requestNo: "",
        agreementNo: "",
        requestedBy: "",
        requestedOn: "",
        remarks: "",
        acceptDeclineRemarks: "",
        leaseStart: "",
        leaseEnd: "",
        contactImg: "",
        contactName: "",
        contactNo: "",
        contactEmail: "",
        units: [],
        autoRenewalEscalation: "",
        currency: "",
        unitView: "",
        error: {
            acceptDeclineRemarks: ""
        }
    }

    const classes = useStyles()
    const debounce = UseDebounce()
    const size = useWindowDimensions()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)

    // useState
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [list, setList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const now = new Date();
    const [filterData, setFilterData] = React.useState({
        status: [true],
        agreementStatus: [getARS(enumName.pending, "renewal", "table")],
        dateRange: { startDate: now, endDate: now }
    })
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [requestDialogOpen, setRequestDialogOpen] = React.useState(false)
    const [requestState, setRequestState] = React.useState({ ...defaultRequestState })
    const [acceptDeclineDialogOpen, setAcceptDeclineDialogOpen] = React.useState(false)
    const [acceptDialogOpen, setAcceptDialogOpen] = React.useState(false)
    const [unitViewDialogOpen, setUnitViewDialogOpen] = React.useState(false)
    const [enumValue, setEnumValue] = React.useState({ agreement_request_action: [] })
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getEnum()
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // useEffect to get agreement termination list using selected company and filter data when loading the screen
    React.useEffect(() => {
        setPage(1)
        if (selectedCompany?.value) { getList() }
    }, [selectedCompany, filterData])

    // Function to get agreement renewal list based on the input data
    const getList = (offset = 0, limit = 10, search = "", agreementStatus = [enumName.pending]) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        let startDate = filterData?.dateRange?.startDate ?? new Date()
        let endDate = new Date(filterData?.dateRange?.endDate ?? new Date()) ?? new Date()
        let payload = {
            company_id: selectedCompany?.value, search, offset, limit,
            status: filterData?.agreementStatus?.map((_) => _?.value) ?? agreementStatus,
            request_purpose: enumName.renewal,
            from_date: moment(startDate).format("YYYY-MM-DD"),
            to_date: moment(endDate).format("YYYY-MM-DD"),
            active: (!filterData?.status || filterData?.status?.length === 0) ?
                [true, false] : filterData?.status,
        }
        NetworkCall(
            `${config.api_url}/agreement_request/list`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            setList({
                data: r?.data?.data ?? [],
                totalRowsCount: r?.data?.count
            })
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((error) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true, msg: "Some Thing Went Wrong",
                severity: AlertProps.severity.error
            })
        })
    }

    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types.agreement_request_action])
        let agreement_request_action = result?.agreement_request_action?.map((_) => {
            return getARS(_?.value, "renewal", "table")
        })
        setEnumValue({ agreement_request_action })
    }

    // Set row data for table
    const Rows = React.useCallback(list?.data?.map((_) => {
        let j
        try {
            j = {
                id: _?.id,
                requestNo: _?.reference_id ?? "-",
                agreementNo: _?.agreement_no ?? "-",
                requestedBy: _?.created_by ?? "-",
                requestedOn: _?.created_at ? convertTimeUtcToZoneCalander(_?.created_at) : "-",
                noOfUnits: _?.no_of_units ?? "-",
                status: getARS(_?.status, "renewal", "table"),
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: "Some Thing Went Wrong"
            })
        }
        return j
    }), [list])

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in agreement request list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getList(0, limit, e)
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getList(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getList(0, value, searchText)
    }

    // Function to update agreementRequestState
    const updateRequestDialogState = (k, v) => {
        let error = requestState?.error
        error[k] = ""
        setRequestState({ ...requestState, [k]: v, error })
    }

    // Function for updating addNewState
    const validate = () => {
        let isValid = true
        let error = requestState.error
        if (requestState?.acceptDeclineRemarks?.length === 0) { isValid = false; error.acceptDeclineRemarks = "Remarks is Required" }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Please fill all mandatory field",
            })
        }
        setRequestState({ ...requestState, error })
        return isValid
    }

    // Function to accept/decline a agreement request
    const handleAcceptDecline = () => {
        if (validate()) {
            backdrop.setBackDrop({
                ...backdrop, open: true,
                message: `${requestState?.formType === "Accept" ? "Accepting" : "Declining"} the request`,
            });
            let data = requestState?.data
            let unitPrice = requestState?.unitPrice
            let agreementUnits = unitPrice?.units?.map((_) => {
                let agreementRentBreakup = _?.pricing?.map((i) => {
                    return {
                        unit_id: i?.unitid,
                        rent_breakup_id: i?.PCid,
                        // period_type: null,
                        // lease_period:null,
                        rent_amount: i?.componentvalue,
                        primary: i?.primary,
                        refundable: i?.refundable,
                        taxable: i?.taxable,
                        is_active: true,
                        created_by: data?.agreement?.created_by,
                        updated_by: data?.agreement?.updated_by,
                        vat_group_id: i?.vatGroupId,
                        currency_id: i?.currencyId,
                        property_id: i?.propertyId,
                        company_id: i?.companyId,
                        client: data?.agreement?.client,
                        applied_value: i?.componentvalue + i?.appliedTaxAmount,
                        tax: i?.appliedTaxAmount,
                        tax_percentage: i?.value,
                        before_rent_amount: i?.componentvalue,
                        before_tax: i?.appliedTaxAmount,
                        component_value: i?.value,
                        is_one_time: i?.isOnetime,
                        is_quantity: i?.isQuantity,
                        quantity: i?.quantity,
                        payment_period: i?.paymentPeriod,
                        value_basis_type: i?.valueBasisType
                    }
                })
                return {
                    unit_id: _?.id,
                    unit_total_primary: _?.totalPricing,
                    unit_total_refundable: _?.unitTotalRefundable,
                    // unit_total_others: null,
                    is_active: true,
                    created_by: data?.agreement?.created_by,
                    updated_by: data?.agreement?.updated_by,
                    is_external: false,
                    contact_id: data?.agreement?.contact_id,
                    client: data?.agreement?.client,
                    agreement_unit_users: [
                        {
                            contact_id: data?.agreement?.contact_id,
                            is_active: true,
                            created_by: data?.agreement?.created_by,
                            updated_by: data?.agreement?.updated_by,
                            client: data?.agreement?.client,
                        }
                    ],
                    agreement_rent_breakup: agreementRentBreakup
                }
            })
            let agreement = {
                ...data?.agreement,
                created_at: undefined,
                updated_at: undefined,
                lease_start_date: data?.renewal_period?.from_date,
                lease_end_date: data?.renewal_period?.to_date,
                id: undefined,
                parent_agreement_id: data?.agreement?.id,
                account_name: undefined,
                agreement_units: agreementUnits,
            }
            const payload = {
                request_id: requestState?.id,
                status: requestState?.formType === "Accept" ? "Approved" : "Rejected",
                manager_remarks: requestState?.acceptDeclineRemarks,
                latest_price: requestState?.autoRenewalEscalation === "Latest Price" ? agreement : undefined
            }
            NetworkCall(
                `${config.api_url}/agreement/renewal`,
                NetWorkCallMethods.post,
                payload, null, true, false
            ).then((_) => {
                alert.setSnack({
                    ...alert, open: true, severity: AlertProps.severity.success,
                    msg: `Agreement Renewal Request ${requestState?.formType === "Accept" ? "Accepted" : "Declined"}`,
                });
                getList()
                setAcceptDeclineDialogOpen(false)
                setRequestDialogOpen(false)
                setAcceptDialogOpen(false)
                setRequestState({ ...defaultRequestState })
                backdrop.setBackDrop({ ...backdrop, open: false, message: "", })
            }).catch(() => {
                backdrop.setBackDrop({ ...backdrop, open: false, message: "", })
                alert.setSnack({
                    ...alert, open: true, msg: "Some Thing Went Wrong",
                    severity: AlertProps.severity.error,
                })
            })
        } else { return false }
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading Request", });
        let payload = { request_id: data?.id, is_renewal: true }
        NetworkCall(
            `${config.api_url}/agreement_request`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((res) => {
            let _ = res?.data?.data
            let tempStatus = getARS(_?.agreement_request?.status, "renewal", "view")
            setRequestState({
                id: _?.agreement_request?.id ?? "",
                status: tempStatus ?? "",
                requestNo: _?.agreement_request?.reference_id ?? "-",
                agreementNo: _?.agreement?.agreement_no ?? "-",
                requestedBy: _?.agreement_request?.requested_by?.name ?? "-",
                requestedOn: _?.agreement_request?.created_at ?
                    convertTimeUtcToZoneCalander(_?.agreement_request?.created_at) : "-",
                acceptDeclineRemarks: _?.agreement_request?.remarks ?? "-",
                leaseStart: _?.agreement?.lease_start_date ?
                    convertTimeUtcToZoneCalander(_?.agreement?.lease_start_date) : "-",
                leaseEnd: _?.agreement?.lease_end_date ?
                    convertTimeUtcToZoneCalander(_?.agreement?.lease_end_date) : "-",
                contactImg: _?.contact?.image_url ?? "",
                contactName: _?.contact?.name ?? "-",
                contactNo: _?.contact?.mobile_no ?
                    (_?.contact?.mobile_no_country_code + " " + _?.contact?.mobile_no) : "-",
                contactEmail: _?.contact?.email_id ?? "",
                units: _?.agreement_units?.map((i) => {
                    return {
                        ...i,
                        iconToolTip: (tempStatus.value !== enumName.approved &&
                            i?.is_occupied) && "This Unit Is Occupied."
                    }
                }) ?? [],
                occupiedUnits: _?.agreement_units?.filter((i) => i?.is_occupied === true) ?? [],
                autoRenewalEscalation: _?.agreement?.auto_renewal_escalation ?? "",
                currency: _?.currency?.symbol ?? "",
                accountNo: _?.agreement?.account_id ?? "-",
                accountName: _?.agreement?.account_name ?? "-",
                approvedBY: _?.agreement_request?.approved?.manager_name ?? "-",
                managerRemarks: _?.agreement_request?.manager_remarks ?? "-",
                updatedOn: _?.agreement_request?.updated_at ?
                    moment(_?.agreement_request?.updated_at).format("DD MMM YYYY") : "-",
                data: _,
                error: defaultRequestState?.error
            })
            setRequestDialogOpen(true)
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
        }).catch((error) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Internal error. Please try again later.",
            });
        });
    }

    const getUnitPrice = () => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading", })
        setRequestDialogOpen(false)
        setAcceptDeclineDialogOpen(false)
        let payload = {
            unitId: requestState?.units?.map?.((_) => _?.id),
            startDate: requestState?.data?.renewal_period?.from_date ?? "",
            endDate: requestState?.data?.renewal_period?.to_date ?? "",
            gracePeriod: requestState?.data?.agreement?.grace_period ?? "",
            revenue_type: requestState?.data?.agreement?.revenue_type ?? ""
        }
        NetworkCall(
            `${config.api_url}/quotation/unitprice`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((res) => {
            let _ = res?.data?.data
            setRequestState({
                ...requestState,
                formType: "Accept",
                acceptDeclineRemarks: "Agreement Renewed",
                unitPrice: _,
                error: defaultRequestState?.error
            })
            setAcceptDialogOpen(true)
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
        }).catch((error) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Internal error. Please try again later.",
            });
        });
    }

    const render = () => {
        return <>
            <Subheader hideBackButton={true} title="Agreement Renewal"
                select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
            <div className={classes.root}>
                <Grid container className={classes.content} spacing={1}>
                    <Grid item xs={4}>
                        <SearchFilter value={searchText} placeholder="Search Agreement"
                            handleChange={(value) => handleSearch(value)} />
                    </Grid>
                    <Grid item xs={8}>
                        <Box display={"flex"} justifyContent={"end"}>
                            <Stack direction="row" spacing={2}
                                divider={<Divider orientation="vertical" flexItem />}>
                                <IconButton onClick={() => setFilterDrawer(!filterDrawer)}
                                    className={classes.filterButton}>
                                    <Badge variant="dot" color="primary"
                                        invisible={!(filterData.status?.length > 0 || filterData?.dateRange)}>
                                        <FilterIMG color="#091b29" />
                                    </Badge>
                                </IconButton>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TableWithPagination
                            heading={Heading}
                            rows={Rows}
                            path={Path}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            onClick={() => console.log("")}
                            tableType="no-side"
                            dataType={Type}
                            handleIcon={handleTableIcon}
                            handlePagination={handleTablePagination}
                            handleChangeLimit={handleTablePageLimit}
                            totalRowsCount={list?.totalRowsCount}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 290px)'}
                            view={permission?.read}
                            edit={permission?.update}
                            delete={permission?.delete} />
                    </Grid>
                </Grid>
                <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                    onApply={(value) => setFilterData(value)}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: StatusOptionList,
                            isMulti: true,
                            state_name: "status",
                            label: "Status"
                        },
                        {
                            component: "select",
                            value: filterData?.agreementStatus,
                            options: enumValue?.agreement_request_action,
                            isMulti: true,
                            state_name: "agreementStatus",
                            label: "Agreement Status",
                        },
                        {
                            component: "date_range",
                            value: filterData?.dateRange,
                            state_name: "dateRange",
                            label: "Requested On"
                        },
                    ]} />
                <RequestView
                    permission={permission}
                    requestDialogOpen={requestDialogOpen}
                    setRequestDialogOpen={setRequestDialogOpen}
                    setAcceptDeclineDialogOpen={setAcceptDeclineDialogOpen}
                    requestState={requestState}
                    setRequestState={setRequestState}
                    getUnitPrice={getUnitPrice} />
                <Remarks
                    acceptDeclineDialogOpen={acceptDeclineDialogOpen}
                    setAcceptDeclineDialogOpen={setAcceptDeclineDialogOpen}
                    setRequestDialogOpen={setRequestDialogOpen}
                    requestState={requestState}
                    setRequestState={setRequestState}
                    updateRequestDialogState={updateRequestDialogState}
                    handleAcceptDecline={handleAcceptDecline} />
                <AcceptConfirm
                    acceptDialogOpen={acceptDialogOpen}
                    setAcceptDialogOpen={setAcceptDialogOpen}
                    setRequestDialogOpen={setRequestDialogOpen}
                    setAcceptDeclineDialogOpen={setAcceptDeclineDialogOpen}
                    setUnitViewDialogOpen={setUnitViewDialogOpen}
                    requestState={requestState}
                    setRequestState={setRequestState}
                    handleAcceptDecline={handleAcceptDecline}
                    windowHeight={size?.height} />
                <UnitView
                    unitViewDialogOpen={unitViewDialogOpen}
                    setUnitViewDialogOpen={setUnitViewDialogOpen}
                    setAcceptDialogOpen={setAcceptDialogOpen}
                    setRequestDialogOpen={setRequestDialogOpen}
                    setAcceptDeclineDialogOpen={setAcceptDeclineDialogOpen}
                    requestState={requestState}
                    setRequestState={setRequestState}
                    updateRequestDialogState={updateRequestDialogState}
                    handleAcceptDecline={handleAcceptDecline}
                    windowHeight={size?.height} />
            </div>
        </>
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div>
}