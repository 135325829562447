import gql from "graphql-tag";

export const GET_ROLES = (offset, limit, searchText) => gql`
query GET_ROLES{
      count: roles(
        where:{
          name:{iregex:"${searchText}"}
          is_active:{eq:true}

        }
        offset:${offset}
        limit:${limit}
      ) {
        count:count_id
      }

         roles(
          where: { is_active: { eq: true }
           name:{iregex:"${searchText}"}
        }
           offset:${offset}
           limit:${limit}
          ) {
        value: id
        label: name

      }
    }`;

export const GET_COMPANY = (offset, limit, searchText, client) => gql`
query GET_COMPANY{
      count: company_master(
        where:{
          name:{iregex:"${searchText}"}
          is_active:{eq:true}
          client:"${client}"
        }
        offset:${offset}
        limit:${limit}
      ) {
        count:count_id
      }

      company_master(
          where: { is_active: { eq: true }
           name:{iregex:"${searchText}"}
           client:"${client}"
        }
           offset:${offset}
           limit:${limit}
          ) {
        value: id
        label: name

      }

      all_company_master:company_master(
        where: { is_active: { eq: true }
         client:"${client}"
      }
        ) {
      value: id
      label: name

    }
    }`;

export const GET_PROPERTY = `
query GET_PROPERTY($search:String , $client:String , $company_id:[Int]){
      count: property(
        where:{
          name:{iregex:$search}
          is_active:{eq:true}
          client:$client
          company_id:{in:$company_id}
        }
        offset:$offset
        limit:$limit
      ) {
        count:count_id
      }

      property(
        where:{
          name:{iregex:$search}
          is_active:{eq:true}
          client:$client
          company_id:{in:$company_id}
        }
        offset:$offset
        limit:$limit
          ) {
        value: id
        label: name

      }
    }`;

    export const GET_PROPERTY_SELECT_ALL = `
query GET_PROPERTY($search:String , $client:String ){
      count: property(
        where:{
          name:{iregex:$search}
          is_active:{eq:true}
          client:$client
        }
        offset:$offset
        limit:$limit
      ) {
        count:count_id
      }

      property(
        where:{
          name:{iregex:$search}
          is_active:{eq:true}
          client:$client
        }
        offset:$offset
        limit:$limit
          ) {
        value: id
        label: name

      }
    }`;

export const GET_ROLES_BY_ID = (offset, limit, searchText, client, id) => gql`
    query GET_ROLES_BY_ID{
          count: client_roles(
            where:{
              name:{iregex:"${searchText}"}
              is_active:{eq:true}
              client:"${client}"
              is_delete:{eq:false}
              module_id:${id}
            }
            offset:${offset}
            limit:${limit}
          ) {
            count:count_id
          }

          client_roles(
              where: { is_active: { eq: true }
               name:{iregex:"${searchText}"}
               client:"${client}"
               is_delete:{eq:false}
               module_id:${id}
            }
               offset:${offset}
               limit:${limit}
              ) {
            value: id
            label: name

          }
        }`;
