
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import React from "react";
import { withNamespaces } from "react-i18next";
import { Download } from "../../../assets";
import { AlertDialog, SearchFilter, TableWithInfinityScroll } from "../../../components";
import { DocumentViewer } from "../../../components/fileViewer";
import { getInvoiceHeading, invoicedataType, invoicePath } from "../../../utils/receipt";
import { ReceiptStyles } from "../styles";

const InvoiceTable = ({ t, id = "", list = [], currency = "", getInvoice = () => false, setList = () => false, total = 0, availableTotal = 0, updateState = () => false, view = false, datas = {}, setData = () => false }) => {
    const classes = ReceiptStyles();
    const invoiceHeading = getInvoiceHeading(t, list?.filter((val) => val?.is_active)?.length);
    const [search, setSearch] = React.useState("");
    const [offset, setOffset] = React.useState(0);



    const [open, setOpen] = React.useState({
        bool: false,
        data: {}
    })
    //initial load
    React.useEffect(() => {
        if (id) {
            getInvoice(0, "", true)
        }
        // eslint-disable-next-line
    }, [id])
    //if create
    const create = (data, check) => {
        const edited = list.map((val) =>
            val?.id === data?.main?.id ? {
                ...val, is_active: check
            } : val
        );
        setList(edited);
        let total = edited?.filter((i) => i?.is_active)?.map(val => parseInt(val?.total_amount_value ?? 0)).reduce((a, b) => a + b, 0);
        updateState("amountTopay", total)
    }
    //if view
    const views = (data, check) => {

        const edited = list.map((val) => val?.id === data?.main?.id ? { ...val, is_active: check, } : val);
        let total = parseInt(datas?.available_balance);
        let tempArray = []
        for (let item of edited) {

            if (item?.is_active) {

                let amount = item?.total_amount_value - total <= 0 ? 0 : item?.total_amount_value - total

                tempArray.push({
                    ...item,
                    invoice_due_amount: amount,
                    result: item?.total_amount_value - amount,
                })

                total = total - item?.total_amount_value

            } else {
                tempArray.push({ ...item });
            }
        }
        if (calculation(list)?.avalBalance > 0) {
            setList(tempArray);
        } else {
            if (check === false) {
                setList(edited)
            }
        }
    }
    //handle icon
    const handleIcon = (type, data, check) => {
        if (type === "checkBox") {
            if (view) {
                views(data, check)
            } else {
                create(data, check)
            }
        }
    }
    //handleOpen
    const handleOpen = (data = {}) => {
        setOpen({
            bool: !open?.bool,
            data: data
        })
    }
    //handleIcon
    const handleLink = (data) => {
        handleOpen(data?.main)
    }
    //download pdf
    const downloadPdf = () => {
        if (open?.data?.asset) {
            saveAs(open?.data?.asset, `${open?.data?.invoice_id}.pdf`)
        }
    }
    //render btn
    const renderButton = () => {
        return (
            <Button onClick={downloadPdf} className={classes.downBtn} variant="outlined" startIcon={<Download />}>
                {t("download")}
            </Button>
        )
    }
    // infinity scroll
    const fetchMoreData = () => {
        setOffset(offset + 10);
        getInvoice(offset + 10, search, false);
    };
    //handle search
    const handleSearch = (value) => {
        setSearch(value);
        getInvoice(0, value, true)
    }
    //calculate available  
    const calculation = (list) => {

        let ourBalance = datas?.available_balance ? datas?.available_balance : 0;

        let activeAmt = list?.reduce((total, item) => {
            if (item.is_active) {
                return total = total + (item?.total_amount_value ? item?.total_amount_value : 0)
            }
            return total
        }, 0)


        let avalBalance = ourBalance - activeAmt; //-200
        let totalAmt = activeAmt > ourBalance ? ourBalance : activeAmt

        return {
            avalBalance: avalBalance < 0 ? 0 : avalBalance,
            totalAmt,
            as: avalBalance
        }
    }


    return (
        <Box >
            <Grid container alignItems="center" className={classes.invoiceRoot}>
                <Grid item xs={8}>
                    <Typography className={classes.title}>{t("invoices")}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <SearchFilter value={search} handleChange={(value) => handleSearch(value)} placeholder={t("search")} />
                </Grid>
            </Grid>
            <Box p={1}>
                <TableWithInfinityScroll
                    heading={invoiceHeading}
                    rows={list}
                    path={invoicePath}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    tableType="no-side"
                    msg="All Invoices Are Paid"
                    handleLink={handleLink}
                    fetchMoreData={fetchMoreData}
                    showpagination={false}
                    view={true}
                    handleIcon={handleIcon}
                    dataType={invoicedataType}
                    height={list?.length > 4 ? 300 : "auto"}
                />
            </Box>
            <Stack direction="row" className={classes.totalRoot} justifyContent={view ? "space-between" : "flex-end"}>
                {
                    view &&
                    <Box>
                        <Typography className={classes.available}>{t("availableAmount")}</Typography>
                        <Typography className={classes.totalAmountValue}>{currency ?? list?.[0]?.symbol} {calculation(list)?.avalBalance ?? 0}</Typography>
                    </Box>
                }

                {
                    view ?
                        <Box>
                            <Typography textAlign="right" className={classes.totalAmount}>{t("totalAmount")}</Typography>
                            <Typography textAlign="right" className={classes.totalAmountValue}>{currency ?? list?.[0]?.symbol} {calculation(list)?.totalAmt ?? 0}</Typography>
                        </Box>
                        :
                        <Box>
                            <Typography textAlign="right" className={classes.totalAmount}>{t("totalAmount")}</Typography>
                            <Typography textAlign="right" className={classes.totalAmountValue}>{currency ?? list?.[0]?.symbol} {total}</Typography>
                        </Box>
                }

            </Stack>
            <AlertDialog
                header="INV-12124464"
                onClose={handleOpen}
                open={open?.bool}
                render={open?.data?.asset ? renderButton() : false}
                component={
                    <Box p={1} bgcolor={"#F2F4F7"}>
                        <center>
                            {
                                open?.data?.asset ?

                                    <DocumentViewer url={open?.data?.asset} />
                                    :
                                    "No Document Found"
                            }
                        </center>

                    </Box>
                }
            />
        </Box>
    )
}
export default withNamespaces("receipt")(InvoiceTable); 