/* eslint-disable no-unused-vars */
import {
  Box, Grid, IconButton
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GenerateForm, SearchFilter, Subheader, TableWithPagination } from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { Routes } from '../../router/routes';
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, Quotationheading, Quotationpath } from '../../utils';
import { UseDebounce } from '../customHooks';
import { FilterComponent } from './filter';
import { QueryConstruction } from './queryConstruction';
import { Bold } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
  },
  content: {
    border: "2px solid white",
    borderRadius: "12px",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    // height: `calc(100vh - 88px)`
  },

  btn: {
    borderRadius: theme.palette.borderRadius,
  },
  btn2: {
    borderRadius: theme.palette.borderRadius,
    color: theme.palette.secondary.main,
  },
  text: {
    fontSize: "16px",
    fontFamily: Bold,
    color: theme.typography.color.primary
  },
  subText: {
    fontSize: "12px",
    fontFamily: Bold,
    color: theme.typography.color.secondary
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "10px"
  },
  searchdrop: {
    borderRadius: "10px",
    border: "1px solid #E4E8EE",
    cursor: "pointer",
  },
  chip: {
    backgroundColor: "#F2F4F7",
    marginLeft: "6px",
    marginTop: "10px",
    borderRadius: theme.palette.borderRadius,
    fontSize: "14px",
    cursor: "pointer",
    border: "none",
    boxShadow: "none",
    color: theme.typography.color.secondary,
    "&:hover": {
      border: "none",
      boxShadow: "none",
    },
    display: "flex",
    alignItems: "center",
  },
  Label: {
    color: theme.typography.color.secondary,
    fontSize: "12px",
    marginTop: "6px",
  },
  UnitStatusactive: {
    padding: "10px 16px",
    textAlign: "center",
    borderRadius: theme.palette.borderRadius,
    color: "white",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  UnitStatus: {
    padding: "10px 16px",
    textAlign: "center",
    borderRadius: theme.palette.borderRadius,
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#F2F4F7",
  },
  previousbtn: {
    backgroundColor: "white",
    color: "#091B29",
    border: "1px solid #CED3DD",
    borderRadius: "10px",
    padding: "12px 8px",
    boxShadow: "none",
  },
}))

const FilterDependency = {
  companyName: ['propertyName', 'unitName', 'createdBy'],
  propertyName: ['unitName']
}

export const Quotation = props => {
  const companyIDs = JSON.parse(localStorage.getItem(LocalStorageKeys.role))?.filter(_ => _?.name === 'Manager')?.map(_ => _?.company_id);
  const { setBackDrop, open: loading } = React.useContext(BackdropContext);
  const classes = useStyles();
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const history = useHistory();
  const [quotationData, setQuotationData] = useState({});
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState('');
  const [openFilter, setOpen] = React.useState(false);
  const [filterData, setFilterData] = useState({});
  const [filterApplied, setFilterApplied] = useState(false);

  //debounce hook
  const debounce = UseDebounce();
  //handle pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getQuotationData(offset, limit, searchValue)
  }
  //change limit
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getQuotationData(0, value, searchValue)
  }
  //handle search
  const handleSearch = (e) => {
    setSearchValue(e)
    debounce(() => searchTableFunction(e), 800)
  }
  //table function
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getQuotationData(0, limit, e)
  }
  //handle more icon 
  const handleIcon = (type, data) => {
    history.push({
      pathname: `${Routes.quotation_view.split('/:')[0]}/${data?.id}`
    })
  }
  //initial load
  useEffect(() => {
    getQuotationData();
    // eslint-disable-next-line
  }, [])
  //mapping quotation data
  const getQuotationData = async (offset = 0, limit = 10, searchText = "", payloadVariables) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    let variables = {
      company_id: payloadVariables?.company_id?.length ? payloadVariables?.company_id : companyIDs,
      unit_id: payloadVariables?.unit_id ?? [],
      created_by: payloadVariables?.created_by ?? [],
      status: payloadVariables?.status ?? [],
      final_status: payloadVariables?.final_status ?? ''
    }

    let payloadQuery = QueryConstruction('quotation_listing', offset, limit, searchText, { ...variables });
    const payload = {
      query: payloadQuery.type,
      variables: {
        ...variables
      }
    };
    await NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let result = response.data.data;
        let data = result?.quotation?.length ? payloadQuery.construct(result?.quotation) : [];
        setQuotationData({
          data: data,
          totalRowsCount: result?.count?.[0]?.count
        })
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      });

    setBackDrop({
      open: false,
      message: "",
    });
  }
  //fillter drawer
  const handleFilterDrawer = (bool = false) => {
    if (bool && !filterApplied) {
      setFilterData({});
    }
    setOpen(bool)
  }

  const onChangeFilterState = (key, value) => {

    setFilterData(() => {
      if (FilterDependency.hasOwnProperty(key)) {
        FilterDependency[key].map(_ => filterData[_] = []);
        return {
          ...filterData, [key]: value
        }
      }
      else {
        return {
          ...filterData, [key]: value
        }
      }
    })
  }

  const applyFilter = () => {

    const retrunIdfromMap = (array) => array?.map(({ value }) => value) ?? [];

    let payload = {
      company_id: retrunIdfromMap(filterData?.companyName),
      property_id: retrunIdfromMap(filterData?.propertyName),
      unit_id: retrunIdfromMap(filterData?.unitName),
      created_by: retrunIdfromMap(filterData?.createdBy),
      status: retrunIdfromMap(filterData?.status),
      final_status: filterData?.finalStatus ?? '',
    }

    if (page > 1) {
      setPage(1);
    }

    setBackDrop({
      open: true,
      message: "Fetching data",
    });

    getQuotationData(0, limit, searchValue, payload);
    handleFilterDrawer();
    setFilterApplied(true)


  }

  const clearAllFilter = () => {
    setFilterData({});
  }

  const goBack = () => {
    history.goBack()
  }

  return (
    <>
      <Subheader title="Quotation" goBack={goBack} />
      <div className={classes.root}>

        <Grid container className={classes.content} >
          <Grid item xs={4}>
            <SearchFilter
              value={searchValue}
              handleChange={handleSearch}
              placeholder="Search Quotation"

            />
          </Grid>
          <Grid
            item xs={8}
            textAlign={"right"}
          >
            <Box>
              <IconButton size='small' className={classes.img} onClick={() => handleFilterDrawer(true)}>
                <img src='/images/filter.svg' alt='filter' />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {Quotationheading && <TableWithPagination
              heading={Quotationheading}
              // rows={Quotationrow}
              rows={quotationData?.data}
              path={Quotationpath}
              showpagination={true}
              count="2"
              tableType="no-side"
              onClick={() => false}
              handleIcon={handleIcon}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              dataType={[
                { type: ["text"], name: "quotationId" },
                { type: ["text"], name: "leadName" },
                { type: ["text"], name: "agent" },
                { type: ["text"], name: "units" },
                { type: ["text"], name: "value" },
                { type: ["text"], name: "period" },
                { type: ["text"], name: "expireOn" },
                { type: ["status"], name: "status" },
                { type: ["icon"], icon: "icon" },
              ]}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              totalRowsCount={quotationData?.totalRowsCount}
              page={page}
              limit={limit}
              height={370}
              view={true}
              edit={true}
              delete={true} />}
          </Grid>
          {openFilter &&
            <GenerateForm
              parent_id={"quotation_listing_drawer"}
              nocancel
              readOnly
              loading={false}
              open={openFilter}
              handlecancel={handleFilterDrawer}
              header={'Filter'}
            >
              <FilterComponent
                data={filterData}
                onChange={onChangeFilterState}
                applyFilter={applyFilter}
                clearAllFilter={clearAllFilter}
              />
            </GenerateForm>}
        </Grid>
      </div>
    </>
  )
}









