import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DetailsCardCoponent, FormGenerator } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from '../../../contexts';
import { GET_INVOICE_ACCOUNT } from "../../../graphql/reciept";
import { NetworkCall } from "../../../networkcall";
import { Routes } from "../../../router/routes";
import { AlertProps, enumSelect, enum_types, LocalStorageKeys, NetWorkCallMethods } from "../../../utils";
import { getAccountDetails } from "../../../utils/receipt";
import { SettingSave } from "../../companyCreation/components/settingSaveSections";
import { InvoiceTable } from "../components";
import { ReceiptStyles } from "../styles";

const Details = ({
    t,
    selected = {},
    data = {},
    list = [],
    setData = () => false,
    setList = () => false,
    currency = "",
    type = "",
    company = {},
    permission = {},
    validateForm = () => false
}) => {

    const classes = ReceiptStyles();
    const alert = React.useContext(AlertContext);
    const history = useHistory()
    const detailsData = getAccountDetails(t, selected);
    const client = useApolloClient();
    const [enumValue, setEnum] = React.useState({
        payment_mode: [],
        cheque_status: []
    })
    const getEnum = async () => {
        const result = await enumSelect([enum_types.payment_mode, enum_types?.cheque_status])
        setEnum({
            payment_mode: result?.payment_mode,
            cheque_status: result?.cheque_status
        })
    }
    //get invoice against account
    const getInvoice = (offset, searchText, reload) => {
        client.query({
            query: GET_INVOICE_ACCOUNT,
            fetchPolicy: 'network-only',
            variables: {
                offset: offset,
                limit: 10,
                id: selected?.subTitle1,
                search: searchText,
                client: localStorage.getItem(LocalStorageKeys.clinetID)
            }
        }).then((response) => {

            let data = response?.data?.invoice?.map((val) => {
                return (
                    {
                        id: val?.id,
                        invoice_id: val?.invoice_no,
                        agreement_id: val?.agreement?.agreement_no,
                        invoice_generate_date: val?.created_at,
                        status: val?.payment_status === "no-due" ? "Paid" : "Unpaid",
                        pending_amount: `${currency ?? val?.currency_master?.symbol} ${val?.invoice_due_amount ?? 0} `,
                        total_amount: `${currency ?? val?.currency_master?.symbol} ${val?.invoice_total_amount ?? 0} `,
                        total_amount_value: val?.invoice_due_amount,
                        url: "View PDF",
                        is_active: false,
                        asset: val?.asset_url,
                        symbol: currency ?? val?.currency_master?.symbol,
                        amount_tagged: val?.invoice_due_amount,

                    }
                )
            })
            if (reload) {
                setList(data)
            } else {
                setList([...list, ...data])
            }

        }).catch((err) => {
            console.log(err)
        })
    }
    //initial load
    React.useEffect(() => {
        getEnum()
    }, [])

    //update state 
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    };
    //form data
    const changeInput = [
        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: true,
            component: "text",
            label: t("amountToPay"),
            type: "number",
            placeholder: t("amountToPay"),
            value: data?.amountTopay,
            onChange: (value) => updateState("amountTopay", value.target.value),
            error: data?.error?.amountTopay,
            isRequired: true,
            isReadonly: type === "invoice",
        },
        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: true,
            component: "select",
            label: t("paymentMethod"),
            placeholder: t("paymentMethod"),
            value: data?.paymentmethod,
            onChange: (value) => updateState("paymentmethod", value),
            error: data?.error?.paymentmethod,
            isRequired: true,
            options: enumValue?.payment_mode,
            menuPlacement: "top"
        },
        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: data?.paymentmethod?.value !== "Cash",
            component: "text",
            label: t("bankName"),
            placeholder: t("bankName"),
            value: data?.bankName,
            onChange: (value) => updateState("bankName", value.target.value),
            error: data?.error?.bankName,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: data?.paymentmethod?.value !== "Cash",
            component: "text",
            label: t("branch"),
            placeholder: t("branch"),
            value: data?.branch,
            onChange: (value) => updateState("branch", value.target.value),
            error: data?.error?.branch,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: data?.paymentmethod?.value !== "Cash",
            component: "text",
            label: t("chequeNo"),
            placeholder: t("chequeNo"),
            value: data?.chequeNo,
            onChange: (value) => updateState("chequeNo", value.target.value),
            error: data?.error?.chequeNo,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: data?.paymentmethod?.value !== "Cash",
            component: "date",
            label: t("chequeDeposite"),
            placeholder: t("chequeDeposite"),
            value: data?.depoiteDate,
            onChange: (value) => updateState("depoiteDate", value),
            error: data?.error?.depoiteDate,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: data?.paymentmethod?.value !== "Cash",
            component: "select",
            label: t("chqueClearenc"),
            placeholder: t("chqueClearenc"),
            value: data?.clearanceStatus,
            onChange: (value) => updateState("clearanceStatus", value),
            error: data?.error?.clearanceStatus,
            isRequired: true,
            options: enumValue?.cheque_status
        },
    ]
    //on save
    const onSave = () => {
        if (validateForm()) {
            const payload = {
                account_no: selected?.subTitle1,
                invoices: list?.filter((val) => val?.is_active).map((val) => {
                    return ({
                        id: val?.id,
                        amount_tagged: val?.amount_tagged
                    })
                }),
                type: type,
                payment_amount: data?.amountTopay,
                payment_mode: data?.paymentmethod?.value,
                bank_name: data?.paymentmethod?.value !== "Cash" ? data?.bankName : undefined,
                bank_branch: data?.paymentmethod?.value !== "Cash" ? data?.branch : undefined,
                cheque_no: data?.paymentmethod?.value !== "Cash" ? data?.chequeNo : undefined,
                cheque_deposit_date: data?.paymentmethod?.value !== "Cash" ? data?.depoiteDate : undefined,
                cheque_status: data?.paymentmethod?.value !== "Cash" ? data?.clearanceStatus?.value : undefined,
                company_id: company?.value,
                currency_id: company?.currency_id,
            }
            NetworkCall(
                `${config.api_url}/settlement`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: "Receipt Created Successfully",
                    })
                    getInvoice(0, "", true)
                    history.push(Routes.receipt)
                }).catch((err) => {
                    console.log(err)
                })
        }

    }

    return (
        <Box>
            <SettingSave onSave={onSave} cancel={true} btn={permission?.create} title={selected?.name} btnName={t("save")} cancelName={t("cancel")} />
            <Box marginTop="8px" className={classes.detailRoots}>
                <Box p={2} >
                    <DetailsCardCoponent title={t("accountDetailsCap")} components={detailsData} />
                </Box>
                {
                    type === "invoice" &&
                    <InvoiceTable
                        currency={currency}
                        id={selected?.subTitle1}
                        updateState={updateState}
                        list={list}
                        getInvoice={getInvoice}
                        total={data?.amountTopay}
                        setList={setList}
                        datas={data}
                        setData={setData}
                    />
                }

                <Box p={2} marginTop="12px">
                    <FormGenerator components={changeInput} />
                </Box>

            </Box>
        </Box>
    )
}
export default withNamespaces("receipt")(Details); 