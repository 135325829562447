import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../../utils";
export const DateStyles = makeStyles((theme) => ({
    text: {
        fontSize: "14px",
        fontFamily: Bold,
        color: "#091B29"
    },
    btn: {
        border: "1px solid #E4E8EE", padding: "6px", marginRight: "8px"
    },
    title: {
        color: "#4E5A6B",
        fontSize: 12,
        fontFamily: SemiBold,
        marginBottom: 8
    },
    clear: {
        color: "#071741",
        fontSize: 12,
        fontFamily: Bold,
        cursor: 'pointer',
        userSelect: 'none'
    }
}))