/* eslint-disable array-callback-return */
// import TextField from "@material-ui/core/TextField";
import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
// import { Box } from "@mui/system";
import React from "react";
import { AlertContext } from "../../contexts";
import { AlertProps, ExtraBold } from "../../utils";
import { generateComponent } from './formsList';
import styles from './styles';
// import { withAllContexts } from "../../../HOCs";
//import { AlertProps } from "../../../utils";
import { Bold } from "../../utils";
const initialstates = {
  list: [],
  states: {},
  error: {}
}

export const GenerateForm = (props) => {

  const classes = styles(props);
  const [state, setState] = React.useState(initialstates);
  const alert = React.useContext(AlertContext);
  const [loading, setLoading] = React.useState(null);
  const parentID = "generate_drawer_parent"
  const {
    //  ispadTop,
    // nocancel,
    parent_id,
    readOnly,
    save = () => false
  } = props;

  const handleChange = (type, val, isUpperCase, limit) => {
    if (limit) {
      if (val?.length > limit) {
        return false;
      }
    }
    //construct dependency state while generate json props
    let findDependency = state?.list?.find(_ => _?.state_name === type);
    if (findDependency?.dependency && findDependency?.dependencyState?.length > 0) {
      findDependency?.dependencyState?.map(_ => {
        if (state?.states?.hasOwnProperty(_)) {
          state.states[_] = ""
        }
      })
    }
    // end
    if (props.changeDrop) {
      props?.changeDrop(type, val?.value)
    }
    setState({
      ...state,
      states: {
        ...state.states,
        [type]: isUpperCase ? val.toUpperCase()?.replaceAll(" ", "") : val
      }
    })
  };

  const validation = () => {
    let error = {};
    state.list.map(val => {
      if (val.required) {
        if (val?.componet_type !== "select" && state?.states[val?.state_name]?.length === 0) {
          error[val.state_name] = true
        }
        else if (val?.componet_type === "select") {
          if ((state?.states[val?.state_name] ? Object.keys(state?.states[val?.state_name])?.length === 0 : true))
            error[val.state_name] = true
        }
        else if (val?.componet_type === "textField_select") {
          if ((state?.states[val?.state_name]?.type ? Object.keys(state?.states[val?.state_name]?.type)?.length === 0 : true)) {
            error[val.state_name] = true
          }
          if (state?.states[val?.state_name]?.value?.length === 0) {
            error[val.state_name] = true
          }
        }
      }
      else {
        error[val.state_name] = false
      }
    })
    if (error && Object.keys(error).length > 0 && Object.keys(error).filter(val => error[val] === true).length > 0) {
      setState({
        ...state,
        error: error
      })
      return true
    } else {
      setState({
        ...state,
        error: error
      })
      return false
    }
  }

  const handlesave = () => {
    if (validation()) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Fill All the fields !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center
      })
      return false
    }
    else {
      save(state?.states, props?.header)
      clearState()
    }

  };

  React.useEffect(() => {
    if (props.open && props?.json) {
      let statesList = {}
      props.json.map(val => {
        return statesList[val.state_name] = (props?.edit || props?.readOnly || val.preserve) ? val.value : ""
        // return statesList[val.state_name] = val.value  ?? ""
      })
      setState({
        ...state,
        list: props.json,
        states: statesList
      });
    }
    // eslint-disable-next-line
  }, [props.open])

  React.useEffect(() => {
    if ((state?.list?.length ?? -1) > 0) {
      setState({
        ...state,
        list: state.list
      })
    }
    // eslint-disable-next-line
  }, [props.json])

  const handlecancel = () => {
    if (props?.handlecancel) {
      clearState()
      props.handlecancel()
    }
  }

  const handleLoading = (loading = null) => {
    setLoading(loading)
  }

  const clearState = () => {
    setState({})
  }
  console.log("state from generate drawer",state)

  return (
    <>
      {(props?.open && props?.children ? props?.children : state?.list?.length > 0) &&
        <Dialog
          id={parentID}
          className={classes.drawer}
          anchor="right"
          onClose={handlecancel}
          open={props.open}>
          {
            <div id={`${parent_id}_generateform_content_div`}
              style={{ overflowX: "hidden" }}>
              <div id={`${parent_id}_generateform_header_div`}>
                <Box style={{ display: "flex", alignItems: "center", padding: "16px 24px" }}>
                  <Box flexGrow={1}>
                    <Typography id={`${parent_id}_generateform_header_typography`}
                      style={{ color: "#091B29", fontSize: "16px", fontFamily: ExtraBold, wordBreak: "break-word" }}>
                      {(readOnly ? "View " : "Add ") + props.header}
                    </Typography>
                  </Box>
                  <IconButton id={`${parent_id}_generateform_header_close_button`}
                    onClick={handlecancel} style={{ padding: "0px" }}>
                    <Close htmlColor="#7C8594" />
                  </IconButton>
                </Box>
                <Box id={`${parent_id}_generateform_header_divider_box`}
                  style={{ backgroundColor: "#E4E8EE", height: "2px" }} />
              </div>
              <div id={`${parent_id}_generateform_body_grid`}
                style={{ padding: '14px', maxHeight: "410px", width: "320px", overflow: "overlay" }}>
                {props?.children ? props?.children : state?.list?.length > 0 && state?.list?.map(val => {
                  return generateComponent(
                    {
                      data: val,
                      handleChange: handleChange,
                      state: state,
                      classes: classes,
                      parent_id: parentID,
                      readOnly: readOnly,
                      handleLoading,
                      loading
                    }
                  )
                })}
              </div>
              {!readOnly && <div id={`${parent_id}_generateform_footer_div`}
                style={{ padding: "24px" }}>
                <Button id={`${parent_id}_generateform_create_button`}
                  // disabled={props?.loading ?? false}
                  fullWidth variant={"contained"}
                  color={"primary"} size={"small"} contained
                  style={{ height: "48px", borderRadius: "4px", fontSize: "14px", color: "#FFFFFF", fontFamily: Bold }}
                  onClick={() => handlesave()}>
                  {/* {props?.loading ? "Creating..." : "Create"} */}
                  {props?.edit ? "Update" : "Create"}
                </Button>
              </div>}
            </div>
          }
        </Dialog>
      }
    </>
  );
}
