import React from "react";
import { SelectAnnounsment } from "../../../components/selectAnnounsment";
import { Grid, Box } from "@mui/material";
import { useStyles } from "../style";

export const PropertySelect = ({
  t,
  listingDetails = {},
  chooseProperty = () => false,
  chooseRole = () => false,
  ChooseAll = () => false,
  searchFunctionBuild = () => false,
  searchFunctionRole = () => false,
  searchText = {},
  fetchMoreDataProperty = () => false,
  fetchMoreDataRole = () => false,
  listingDetails1 = {}
}) => {
  // classes
  const classes = useStyles();
  return (
    <div>
      <Box className={classes.roleRoot}>
        <Grid container spacing={"14px"}>
          <Grid item xs={6}>
            <SelectAnnounsment
              title={t("Select_Property")}
              subtitle={t("Select_Property_Sub")}
              selectAll={t("all_Property")}
              selectAllValue={listingDetails?.propertyselectAll ?? false}
              count="01"
              data={listingDetails?.property ?? []}
              chooseFuncion={chooseProperty}
              selectAllFunction={(e) => ChooseAll("property")}
              searchFunction={searchFunctionRole}
              searchValue={searchText?.propertySearch}
              fetchMoreData={fetchMoreDataProperty}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectAnnounsment
              title={t("Select_Role")}
              subtitle={t("Select_Role_Sub")}
              selectAll={t("all_Role")}
              selectAllValue={listingDetails1?.roleselectAll ?? false}
              count="02"
              data={listingDetails1?.role ?? []}
              chooseFuncion={chooseRole}
              selectAllFunction={(e) => ChooseAll("role")}
              searchFunction={searchFunctionBuild}
              searchValue={searchText?.roleSearch}
              fetchMoreData={fetchMoreDataRole}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
