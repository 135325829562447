import { Avatar, Box, Typography } from "@mui/material";
import { ComponentStyles } from "./styles";
export const Contact = ({ selectedContact = "", onContactSelect = () => false, contact = [] }) => {
    const classes = ComponentStyles()


    return (
        <div className={classes.card}>
            <Typography className={classes.title}>CONTACTS ASSOCIATED</Typography>
            <Box height={`calc(100vh - 465px)`} overflow="auto">
                {
                    contact?.length > 0 ?
                        <>
                            {
                                contact?.map((val) => {
                                    return (
                                        <Box onClick={() => onContactSelect(val)} display="flex" alignItems="center" p={1} className={classes.contactCard}>
                                            <Box>
                                                <Avatar src={val?.url} />
                                            </Box>
                                            <Box flexGrow={1} marginLeft="8px">
                                                <Typography className={classes.name}>{val?.name}</Typography>
                                            </Box>

                                        </Box>
                                    )
                                })
                            }

                        </>
                        : <Typography textAlign="center" className={classes.title}>No Contact Found</Typography>
                }
            </Box>

        </div>
    )
}