import { useApolloClient } from '@apollo/client';
import { Box } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CityProperty from '../../assets/cityproperty';
import ImagesProperty from '../../assets/imagesproperty';
import { ProofComponent, PropertyTabs, Subheader } from '../../components';
import { NewLoader } from '../../components/newLoader';
import { config } from '../../config';
import { AlertContext, AuthContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { accessCheckRender, AlertProps, checkWebsiteUrl, getRoutePermissionNew, networkCallback, NetWorkCallMethods, ValidateEmail } from '../../utils';
import { initialBasicSettings } from "../../utils/companySettings";
import { useStylesCreation } from "../propertcreation/createproperty/style";
import {
    Assets, BankingDetails, CompanyProfile, GovernmentalDetails, Settings
} from './tabs';
import { TermsAndCondition } from './tabs/termsAndCondition';
import { constructCompanyDetails, constructPayload, getDefaultSetting, GET_COMPANY_DETAILS, GET_TOTAL_RATE, listErrorState, returnEditSettings } from './utils';

export const CompanyCreation = (props) => {

    const { isEdit, loading, handleLoading } = props;
    const COMPANY_ID = props?.match?.params?.id;
    const client = useApolloClient();

    const classes = useStylesCreation();
    const history = useHistory();
    const alert = useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const [data, setData] = useState({

        companyProfile: {
            profileDetails: {
                status: { label: "Active", value: true },
            }
        },
    });
    const [settings, setSettings] = React.useState({ ...initialBasicSettings })
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(null);
    const [mapLoad, setMapLoad] = useState(!Boolean(isEdit))
    const [value, setValue] = React.useState(1);
    const [permission, setPermission] = React.useState({});
    const [buttonDisable, setButtonDisable] = React.useState(false);


    const getSettingDetails = () => {
        NetworkCall(
            `${config.api_url}/client/settings/get`,
            NetWorkCallMethods.get,
            null,
            null,
            true,
            false
        )
            .then((response) => {
                let result = getDefaultSetting(response?.data?.settings)
                setSettings(result)
                handleLoading(false)
            }).catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                if (isEdit) {
                    getCompanyDetails(COMPANY_ID)
                } else {
                    getSettingDetails()
                }
            }
        }
        // eslint-disable-next-line
    }, [auth])
    //company details
    const getCompanyDetails = (id) => {
        setLoader('screen')
        client.query({
            query: GET_COMPANY_DETAILS,
            fetchPolicy: "network-only",
            variables: {
                id
            }
        }).then(res => {
            let settingEdit = returnEditSettings(res?.data?.company_master?.[0])
            setSettings(settingEdit)
            onChangeTaxGroup(res?.data?.company_master?.[0])
            // constructCompanyDetailsData(res?.data?.company_master?.[0])
        }).catch(err => {
            console.log(err);
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something went wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        })
    }
    const onChangeTaxGroup = (value) => {
        if (value?.vat_group?.id) {
            client.query({
                query: GET_TOTAL_RATE,
                fetchPolicy: "network-only",
                variables: {
                    id: value?.vat_group?.id
                }
            }).then(res => {
                constructCompanyDetailsData(value, res?.data?.vat_group_item?.[0]?.sum_rate)
            }).catch(err => {

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something went wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            })
        } else {
            constructCompanyDetailsData(value, "")
        }


    }

    const constructCompanyDetailsData = (val, totalTax) => {
        let companyDetails = constructCompanyDetails(val, totalTax);
        setData(companyDetails)
        setLoader(null);
        handleLoading(false)
    }

    const tabTittle = useMemo(() => (
        [
            {
                label: 'Company Profile',
                sub: 'Enter Profile, Address & Contact Details',
                imgSelect: value > 1 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
                value: 1,
                className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                label: 'Company Settings',
                sub: 'Enter Company Settings',
                imgSelect: value > 2 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
                value: 2,
                className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                label: 'KYC Details',
                sub: 'Enter KYC Details',
                imgSelect: value > 3 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
                value: 3,
                className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
            },

            {
                label: 'Attachments',
                sub: 'Upload assets like Image, Videos',
                imgSelect: <ImagesProperty color={value === 4 ? '#5078E1' : '#98A0AC'} />,
                value: 4,
                className: value === 4 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 4 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 4 ? classes.subtabtitle1 : classes.subtabtitle
            }
        ]
        // eslint-disable-next-line
    ), [value])

    const updateState = (stateKey, parentKey, key, value) => {
        let updateData;
        if (parentKey) {
            updateData = {
                ...data?.[stateKey], [parentKey]: {
                    ...data?.[stateKey]?.[parentKey], [key]: value,
                }


            }
            if (key === "companyCountry") {
                if (updateData?.tax?.taxGroup) {
                    updateData.tax.taxGroup = null;
                }
                if (updateData?.tax?.totalRateTax) {
                    updateData.tax.totalRateTax = "";
                }
            }
        }
        else {
            updateData = {
                ...data?.[stateKey], [key]: value
            }
        }

        setData({
            ...data, [stateKey]: updateData
        })

    }
    //validate settings
    const validateSettings = () => {
        let isValid = true;
        let error = settings.error;

        if (settings?.default_base_currency?.length === 0) {
            isValid = false;
            error.default_base_currency = "Default Base Currency is Required";
        }
        if (settings?.default_time_zone?.length === 0) {
            isValid = false;
            error.default_time_zone = "Default Time Zone is Required";
        }
        if (settings?.default_uom?.length === 0) {
            isValid = false;
            error.default_uom = "Default Unit Measurement is Required";
        }
        if (settings?.quotation_validity?.length === 0) {
            isValid = false;
            error.quotation_validity = "Default Quotation Validity is Required";
        }
        if (settings?.billing_payment_peroid?.length === 0) {
            isValid = false;
            error.billing_payment_peroid = "Default Billing Payment Period is Required";
        }
        if (settings?.billing_payment_terms?.length === 0) {
            isValid = false;
            error.billing_payment_terms = "Default Billing Payment Terms is Required";
        }
        if (settings?.billing_payment_terms?.value === "User Defined") {
            if (settings?.billing_payment_terms_value?.length === 0) {
                isValid = false;
                error.billing_payment_terms = "Default Billing Payment Terms Value is Required";
            }
        }
        if (settings?.billing_cycle_method?.length === 0) {
            isValid = false;
            error.billing_cycle_method = "Default Billing Cycle Methods is Required";
        }
        if (settings?.lease_period?.length === 0) {
            isValid = false;
            error.lease_period = "Default Lease Peroid is Required";
        }
        if (settings?.billing_generate_date?.length === 0) {
            isValid = false;
            error.billing_generate_date = "Default Billing Generate Date is Required";
        }
        if (settings?.default_payment_gateway?.length === 0) {
            isValid = false;
            error.default_payment_gateway = "Default Payment Gateway is Required";
        }

        if (settings?.default_payment_gateway?.value === "Solution Provider") {
            if (settings?.loginName?.length === 0 || settings?.loginPassword?.length === 0 || settings?.loginToken?.length === 0 || settings?.paymentProviderName?.length === 0 || settings?.provider_url?.length === 0) {
                isValid = false;
                error.default_payment_gateway = "All User Defined Value is Required";
            }
        }
        if (settings?.reservation_feature?.is_active === true) {
            if (settings?.reservation_feature?.value?.length === 0) {
                isValid = false;
                error.reservation_feature = "Reservation Feature value is Required";
            }
        }
        if (settings?.grace_peroid?.is_active === true) {
            if (settings?.grace_peroid?.value?.length === 0) {
                isValid = false;
                error.grace_peroid = "Grace Peroid value is Required";
            }
        }
        setSettings({ ...settings, error });
        return isValid;
    }
    //update settings
    const updateStateSettings = (key, value) => {
        let error = settings.error;
        error[key] = "";
        setSettings({ ...settings, [key]: value, error });
    }

    const onChangeParticularState = (key, value) => {
        setData({
            ...data,
            [key]: {
                ...data?.[key],
                ...value
            }
        })
    }

    const validation = (value) => {
        if (value === 3) return true
        let listCheck = listErrorState[value - 1];
        let stateKey = ['companyProfile'];
        let returnCheckError = checkError(listCheck, stateKey[value - 1]);
        return !Object.keys(returnCheckError)?.length

    }

    const checkError = (object, stateKey) => {
        let value, component, errorState = {};


        Object.keys(object)?.map(parentKey => {
            object?.[parentKey]?.map(key => {
                value = data?.[stateKey]?.[parentKey]?.[key?.key];
                component = key?.component;
                let errorMsg = key?.errorMsg ?? "This field is required";

                if (key?.dependency?.key) {
                    if (key?.dependency?.component === "select" && key?.dependency?.value) {
                        if (data?.[stateKey]?.[parentKey]?.[key?.dependency?.key]?.value === key?.dependency?.value) {
                            checkComponentError(value, component, errorState, stateKey, parentKey, key?.key, errorMsg)
                        }
                    }
                    else if (key?.dependency?.component === "boolean") {
                        if (data?.[stateKey]?.[parentKey]?.[key?.dependency?.key]) {
                            checkComponentError(value, component, errorState, stateKey, parentKey, key?.key, errorMsg)
                        }
                    }
                    else if (!data?.[stateKey]?.[parentKey]?.[key?.key]) {
                        checkComponentError(value, component, errorState, stateKey, parentKey, key?.key, errorMsg)
                    }
                }
                else {
                    checkComponentError(value, component, errorState, stateKey, parentKey, key?.key, errorMsg)
                }

                return key
            })

            return parentKey
        })
        //lat lng throws error is null
        if (errorState?.companyProfile?.address?.latitude || errorState?.companyProfile?.address?.longitude) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please choose location in map and proceed",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        }
        setError(errorState)
        return errorState
    }

    const checkComponentError = (value, component, errorState, stateKey, parentKey, key, errorMsg) => {
        // if (component === "mobile_number") {
        //     if (!(!!value?.mobile_code)) {
        //         constructErrorState(errorState, stateKey, parentKey, key, "Please select country code");
        //     }
        //     if (value?.mobile?.length !== 10) {
        //         constructErrorState(errorState, stateKey, parentKey, key, "Please enter 10 digits");
        //     }
        // }
        if (component === "company_code") {

            if (value?.length > 6) {
                constructErrorState(errorState, stateKey, parentKey, key, "company code  should be 6 digits");
            }
        }
        if (component === "boolean") {
            if (typeof value !== "boolean") {
                constructErrorState(errorState, stateKey, parentKey, key, errorMsg);
            }
        }
        else if (component === "integer") {
            if (typeof value !== "number") {
                constructErrorState(errorState, stateKey, parentKey, key, errorMsg);
            }
        }
        else if (component === "website") {
            if (!checkWebsiteUrl(value)) {
                constructErrorState(errorState, stateKey, parentKey, key, value ? 'Please enter valid URL' : errorMsg);
            }
        }
        else if (component === "email") {
            if (!value) {
                constructErrorState(errorState, stateKey, parentKey, key, "This field is required");
            }
            else if (!ValidateEmail(value)) {
                constructErrorState(errorState, stateKey, parentKey, key, errorMsg);
            }
        }
        else {
            if (
                !value ||
                //to check Object
                (value?.constructor === Object && !Object?.keys(value)?.length) ||
                //to check Date
                (value?.constructor === Date && !value) ||
                //to check Array
                (value?.constructor === Array && !value?.length) ||
                //to check string
                (value?.constructor === String && !value?.trim()?.length)
            ) {
                constructErrorState(errorState, stateKey, parentKey, key, errorMsg);
            }
        }
    }

    const constructErrorState = (errorState, stateKey, parentKey, key, errorMsg) => {

        if (!errorState[stateKey]) {
            errorState[stateKey] = {}
        }
        if (!errorState[stateKey][parentKey]) {
            errorState[stateKey][parentKey] = {}
        }
        if (!errorState[stateKey][parentKey][key]) {
            errorState[stateKey][parentKey][key] = {}
        }
        errorState[stateKey][parentKey][key]['error'] = true;
        errorState[stateKey][parentKey][key]['errorMsg'] = errorMsg;
    }

    const previous = () => {
        setValue(prevValue => prevValue - 1);
        if (!isEdit) {
            setMapLoad(false)
        }
    };

    const next = () => {
        if (value === 2) {
            if (validateSettings()) {
                setValue(3)
            } else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: `Please fill All fields`,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            }
        } else {
            if (validation(value)) {
                setValue(prevValue => prevValue + 1)
            }
        }
    }

    //on save
    const save = async () => {
        setButtonDisable(true)
        setLoader('save')
        const { companyProfile, companyConfiguration, governmentalDetails, bankingDetails, assets } = data;
        let payload = constructPayload(companyProfile, companyConfiguration, governmentalDetails, bankingDetails, assets, settings);

        if (isEdit) {
            payload.id = COMPANY_ID
        }

        let result = await networkCallback(payload, 'company/upsert', setLoader, true);
        if (result?.data?.type === "success") {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: `${isEdit ? result?.data?.data : result?.data?.data?.message}`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
            history.push({
                pathname: "/masters/business_master/Company"
            })
        }
        else {
            if (result?.status === 406) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Your  Company  limit has been hit! Please upgrade your plan!",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            }
            else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: `Unable to create`,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
                setButtonDisable(false)
                setLoader(null)
            }
        }

    }


    const render = () => {
        return (
            <div style={{ position: 'relative' }}>
                <Subheader
                    title={isEdit ? data?.companyProfile?.profileDetails?.companyName : "Create Companies"}
                    goBack={() => { history.goBack(-1) }}
                    previousAndNextBtn={true}
                    previousBtn={previous}
                    nextBtn={next}
                    previousBtnDisabled={value <= 1 && true}
                    nextBtnDisabled={value === 4 ? true : false}
                />
                {
                    loader === "screen" ? (
                        <NewLoader minusHeight="100px" />
                    ) : (
                        <>
                            <PropertyTabs value={value} isHeigth
                                tab1={(
                                    <>
                                        <CompanyProfile
                                            id='pushTitle'
                                            data={data?.companyProfile}
                                            error={error?.companyProfile}
                                            updateState={(parentKey, key, value) => updateState('companyProfile', parentKey, key, value)}
                                            mapLoad={mapLoad}
                                        />
                                        <Box height="24px" />
                                        <GovernmentalDetails
                                            isEdit={isEdit}
                                            data={data?.companyProfile}
                                            error={error?.companyProfile}
                                            updateState={(parentKey, key, value) => updateState('companyProfile', parentKey, key, value)}
                                            countryID={data?.companyProfile?.profileDetails?.companyCountry?.value}
                                            onChangeParticularState={onChangeParticularState}
                                        />
                                        <Box height="24px" />
                                        <BankingDetails
                                            data={data?.companyProfile}
                                            error={error?.companyProfile}
                                            updateState={(parentKey, key, value) => updateState('companyProfile', parentKey, key, value)}
                                        />
                                        <Box height="24px" />
                                        <TermsAndCondition
                                            id='pushTitle'
                                            data={data?.companyProfile}
                                            updateState={(parentKey, key, value) => updateState('companyProfile', parentKey, key, value)}
                                        />
                                    </>
                                )}
                                tab2={(
                                    <Settings
                                        data={settings}
                                        isEdit={isEdit}
                                        countryID={data?.companyProfile?.profileDetails?.companyCountry?.value}
                                        updateState={updateStateSettings}
                                        dataLogo={data?.companyProfile}
                                        updateStateLogo={(parentKey, key, value) => updateState('companyProfile', parentKey, key, value)}
                                    />
                                )}
                                tab3={(
                                    <Box className={classes.imagebox1}>
                                        <ProofComponent file={settings?.proof} update={(val) => updateStateSettings("proof", val)} company />
                                    </Box>
                                )}

                                tab4={(
                                    <Assets
                                        isEdit={isEdit}
                                        data={data?.assets}
                                        error={error?.assets}
                                        updateState={(parentKey, key, value) => updateState('assets', parentKey, key, value)}
                                        onChangeParticularState={onChangeParticularState}
                                    />
                                )}
                                tabTittle={tabTittle}
                                showBtns={true}
                                isSaveDisable={buttonDisable ? buttonDisable : (value === 4 ? false : true || loader === "save" ? true : false)}
                                saveBtnText={isEdit ? "Update" : "Submit"}
                                save={save}
                            />

                        </>
                    )
                }
            </div>
        )
    }

    return <div>{accessCheckRender(render, permission, "", loading)}</div>;
}