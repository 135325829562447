import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    content: {
        height: (size) => (size?.height - 125),
        overflow: "auto",
        padding: "16px",
        rowGap: "16px",
    },
    card: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
    },
    profileDetailCard: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px 16px 16px 0px"
    },
    image: {
        height: "120px",
        width: "120px",
        borderRadius: "50%",
        margin: "4px",
        objectFit: "fill"
    },
    profileImageDiv: {
        display: "flex",
        justifyContent: "center",
        position: "relative",
        borderRight: "1px solid #00000014",
        padding: "4px",
    },
    profileDetailDiv: {
        padding: "0px 12px"
    },
    title: {
        color: theme.typography.color.secondary,
        fontSize: "12px",
        fontFamily: Bold
    },
    heading: {
        color: theme.typography.color.tertiary,
        fontSize: "14px",
        fontFamily: SemiBold,
        textTransform: "capitalize"
    },
    sub: {
        color: theme.typography.color.primary,
        fontSize: "14px",
        fontFamily: SemiBold,
        textTransform: "capitalize"
    },
    sub1: {
        color: theme.typography.color.primary,
        fontSize: "12px",
        fontFamily: SemiBold,
        textTransform: "capitalize"
    },
    subEmail: {
        color: theme.typography.color.primary,
        fontSize: "14px",
        fontFamily: SemiBold,
    },
    mapDiv: {
        borderRadius: theme.palette.borderRadius,
        overflow: "hidden",
        height: "200px"
    },
    addressDiv: {
        padding: "12px",
        minHeight: "200px",
        border: "1px solid #E4E8EE",
        borderRadius: "5px"
    },
    contactDiv: {
        padding: "12px",
        minHeight: "200px",
        border: "1px solid #E4E8EE",
        borderRadius: "5px"
    },
}));