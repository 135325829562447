import { useApolloClient } from "@apollo/client";
import { Box, Button } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { UseDebounce } from "../../components/index";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { CREATE_ACCOUNT_USER } from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, isEmptyObject, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions, ValidateEmail } from "../../utils";
import { DialogHeader, Stepper } from "../index";
import { Account, AddContact, Preview } from "./steps";
const initialState = {
    profilePic: "",
    name: "",
    email: "",
    mobile: "",
    alternativemobile: "",
    telephone: "",
    fax: "",
    nationality: "",
    gender: "",
    files: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    idType: "",
    idNo: "",
    idExpire: "",
    address: "",
    passportNo: "",
    visaNo: "",
    passportExipre: "",
    contact_id: "",
    proofFile: [],
    error: {
        name: "",
        email: "",
        mobile: "",
        nationality: "",
        gender: "",
        idType: "",
        idNo: "",
        idExpire: "",
        passportNo: "",
        visaNo: "",
        passportExipre: "",
        proofFile: ""
    }
}
export const QuotationContactCreate = ({
    onClose = () => false,
    data = {},
    company_id = "",
    setSelectedAcc = () => false,
    selectedAcc = {},
    total = "",
    reload = () => false,
    quotation_id = "",
    email_id = null,
    is_prospect=false
}) => {
    const client = useApolloClient();
    const debounce = UseDebounce()
    const size = useWindowDimensions()
    const [step, setStep] = React.useState(1);
    const history = useHistory()
    const [disable, setDisable] = React.useState(false)
    const [contact, setContact] = React.useState({ ...initialState })
    const [contactID, setContactID] = React.useState("")
    const alert = React.useContext(AlertContext);
    const [disableBTN, setDisableBTN] = React.useState(false)


    const steps = [
        {
            label: "Contact Details",
            step: 1,
            value: step
        },
        {
            label: "Account Details",
            step: 2,
            value: step
        },
        {
            label: "Preview and Create",
            step: 3,
            value: step
        },
    ]
    //check existing contact
    const checkExsits = (type, value, msg) => {
        setDisable(false)
        let datas = {
            tenantId: `${config.tenantId}`,
            [type]: value
        }
        NetworkCall(
            `${config.api_url}/contact/findContactExisting`,
            NetWorkCallMethods.post,
            datas,
            null,
            true,
            false
        )
            .then((response) => {
                if (response?.data?.is_Exist && value?.length > 0) {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.info,
                        msg: `${msg} is already exists`,
                    });
                    setDisableBTN(true)
                } else {
                    setDisableBTN(false)
                }

            })
            .catch((error) => {
                console.log(error)
            });
    }
    //update state of contact
    const updateState = (key, value) => {
        if (key === 'email') {
            debounce(() => checkExsits("email_id", value, 'Email Id'), 1000);
        }
        if (key === "mobile") {
            debounce(() => checkExsits("mobile_no", value?.mobile, 'Mobile Number'), 1000);
        }
        let error = contact.error;
        error[key] = "";
        setContact({ ...contact, [key]: value, error });
    };
    //validate contact
    const validateContact = () => {
        let isValid = true;
        let error = contact.error;
        //validate name
        if (contact?.name?.length === 0) {
            isValid = false;
            error.name = "Name is Required";
        }
        //validate email
        if (contact?.email?.length === 0) {
            isValid = false;
            error.email = "Email ID is Required";
        }
        //validate email
        if (contact?.email?.length > 0 && ValidateEmail(contact?.email) === false) {
            isValid = false;
            error.email = "Invalide Email ID";
        }
        //validate phone
        if (contact?.mobile?.length === 0) {
            isValid = false;
            error.mobile = "Phone Number is Required";
        }
        //validate proof
        if (contact?.proofFile?.length === 0) {
            isValid = false;
            error.proofFile = "Atleast upload one proof is Required";
        }

        setContact({ ...contact, error });
        return isValid;
    }
    //create contact form
    const createContact = () => {
        setDisable(true)

        let proof = contact?.proofFile?.filter((val) => val?.is_active).map(val => {
            return {
                proof_id: val?.type?.value,
                proof_type: val?.type?.proof_type,
                kyc_type: "Contact",
                name: val?.name,
                number: val?.id_no,
                valid_from: val?.valid_from,
                valid_to: val?.valid_to,
                url: val?.file,
                is_mandatory: val?.mandatry_verify,
                country_id: val?.issuing_country?.value,
                is_active: true
            }
        })

        const formData = new FormData();
        formData.append("first_name", `${contact?.name}`);
        formData.append("lead_id", `${data?.quott?.lead_id}`);
        formData.append("email_id", `${contact?.email}`);
        formData.append("mobile_no", `${contact?.mobile?.mobile}`);
        formData.append("mobile_no_country_code", `${contact?.mobile?.mobile_code}`);
        if (contact?.alternativemobile?.mobile) {
            formData.append("alternative_mobile", `${contact?.alternativemobile?.mobile}`);
            formData.append("alternative_mobile_country_code", `${contact?.alternativemobile?.mobile_code}`);
        }
        formData.append("phone", `${contact?.telephone?.mobile}`);
        formData.append("phone_code", `${contact?.telephone?.mobile_code}`);
        formData.append("fax_no", `${contact?.fax?.mobile}`);
        formData.append("nationality", `${contact?.nationality}`);
        if (contact?.profilePic?.src) {
            formData.append("image_url", `${contact?.profilePic?.src}`);
        }
        formData.append("facebook", `${contact?.facebook}`);
        if (contact?.facebook) {
            formData.append("facebook", `${contact?.facebook}`);
        }
        if (contact?.twitter) {
            formData.append("twitter", `${contact?.twitter}`);
        }
        if (contact?.linkedin) {
            formData.append("linkedin", `${contact?.linkedin}`);
        }
        if (contact?.address?.addressLineOne) {
            formData.append("street_1", `${contact?.address?.addressLineOne}`);
        }
        if (contact?.address?.addressLineTwo) {
            formData.append("street_2", `${contact?.address?.addressLineTwo}`);
        }
        if (contact?.address?.landmark) {
            formData.append("landmark", `${contact?.address?.landmark}`);
        }
        if (contact?.address?.country) {
            formData.append("country", `${contact?.address?.country}`);
        }
        if (contact?.address?.state) {
            formData.append("state", `${contact?.address?.state}`);
        }
        if (contact?.address?.district) {
            formData.append("state", `${contact?.address?.district}`);
        }
        formData.append("proof", JSON.stringify(proof));
        formData.append("company_id", `${data?.quott?.company_id}`)
        formData.append("revenue_type", `${data?.quott?.revenue_type}`)

        NetworkCall(
            `${config.api_url}/contact/add`,
            NetWorkCallMethods.post,
            formData,
            null,
            true,
            false
        )
            .then((response) => {

                setContactID(response?.data?.data?.result)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Contact Successfully Created",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                setStep(2)
                reload()
                setDisableBTN(false)
            })
            .catch((error) => {
                setDisableBTN(false)
                console.log(error)
            });
    }
    //on create Agreement
    const createAgreemment = () => {
        setDisableBTN(true)
        const data = {
            account_id: selectedAcc?.account_no,
            quotationId: quotation_id,
            contact_id: contactID
        }

        NetworkCall(
            `${config.api_url}/agreement/create`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Agreement Successfully Created",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                setDisableBTN(false)
                history.push(Routes.aggreements)
            })
            .catch((error) => {
                setDisableBTN(false)

            });
    }
    //on create account user
    const createAccountUser = () => {
        setDisable(true)
        client.mutate({
            mutation: CREATE_ACCOUNT_USER,
            variables: {
                insert: [
                    {
                        contact_account: selectedAcc?.id,
                        contact_id: contactID,
                        client: localStorage.getItem(LocalStorageKeys.clinetID)
                    }
                ]
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Account User Successfully Created",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
            setDisable(false)
            setStep(3)
        }).catch(er => {
            setStep(3)
        })
    }
    //onsubmit
    const submit = () => {
        if (step === 1) {
            if (disable) {
                setStep(2)
            } else {

                if (validateContact() && isEmptyObject(contact?.proofFile)) {
                    createContact()
                } else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Please Fill all Mendatery Field",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                }
            }
        }
        if (step === 2) {
            if (selectedAcc?.id) {
                createAccountUser()
            } else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.warning,
                    msg: "Please Select Account",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            }

        }
        if (step === 3) {
            createAgreemment()
        }
    }
    //on previous
    const previous = () => {
        setStep(step - 1)
    }


    let components = {
        1: <AddContact setContactID={setContactID} data={contact} setContact={setContact} contactId={contactID} email_id={email_id} contact={contact} updateState={updateState} disable={disable} setDisable={setDisable} is_prospect={is_prospect}/>,
        2: <Account company_id={company_id} setSelectedAcc={setSelectedAcc} email_id={email_id} selectedAcc={selectedAcc} contactId={contactID} />,
        3: <Preview total={total} email_id={email_id} contact={contact} disable={disable} data={data} selectedAcc={selectedAcc} />
    }



    return (
        <Box>
            <DialogHeader title="Convert to Agreement" onClose={onClose} />
            <Stepper step={step} steps={steps} />
            <Box p={2} height={size?.height - 250} overflow="auto">
                {components[step]}
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" p={1}>
                <Button onClick={previous} variant="outlined" disabled={step === 1 ? true : false}>
                    Previous
                </Button>
                <Button disabled={disableBTN} onClick={submit} variant="contained">
                    Next
                </Button>
            </Box>
        </Box>
    )
}