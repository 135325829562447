
import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Regular, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => (
      {
            root: {
                  height: "100vh",
                  backgroundColor: "white",
            },
            grid: {
                  alignItems: "start",
            },
            signupform: {
                  overflow: "overlay",
                  padding: "0px 70px",
                  alignContent: "start",
                  height: (size) => size?.height
            },
            image: {
                  marginTop: "70px",
                  marginBottom: (size) => size?.height > 788.13 ? ((size?.height - 748.13) / 2) : "20px"
            },
            text: {
                  fontSize: "24px",
                  fontFamily: ExtraBold,
                  color: theme.typography.color.primary,
                  marginTop: "0",
            },
            btn: {
                  borderRadius: theme.palette.borderRadius,
                  boxShadow: "0px 6px 10px #00000014",
                  opacity: 1,
                  padding: "8px",
                  "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                  },
            },
            verificationText: {
                  fontSize: "14px",
                  fontFamily: Regular,
                  textAlign: "center",
                  color: theme.typography.color.primary,
                  backgroundColor: theme.palette.background.secondary,
                  borderRadius: theme.palette.borderRadius,
                  padding: "20px",
            },
            poweredby: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  fontSize: "8px",
                  color: theme.typography.color.tertiary,
            },
            pa: {
                  color: theme.typography.color.secondary,
                  fontFamily: SemiBold,
                  fontSize: "10px",
            },
            companyName: {
                  color: theme.typography.color.secondary,
                  fontFamily: Bold,
                  fontSize: "14px",
                  marginLeft: '12px'
            },
            companySelect: {
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer'
            },
            comapnyImage: {
                  width: 50,
                  height: 50,
                  borderRadius: 0
            },
            selectCompany: {
                  padding: '14px',
                  boxShadow: '0px 3px 30px #5C86CB2E',
                  margin: '8px 0px',
                  borderRadius: 12,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                  width: '100%'
            },
            selectCompanyBorder: {
                  padding: '14px',
                  boxShadow: '0px 3px 30px #5C86CB2E',
                  margin: '8px 0px',
                  borderRadius: 12,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: `1px solid ${theme.palette.primary.main}`,
                  cursor: 'pointer',
                  width: '100%'
            },
            list: {
                  overflow: 'scroll',
                  padding: '0px 20px',
                  // "&::-webkit-scrollbar": {
                  //       display: "none",
                  // },
            },
            title: {
                  fontFamily: ExtraBold,
                  fontSize: "24px",
                  color: "#091B29",
            },
            content: {
                  width: "inherit",
            },
            backgroundImage: {
                  backgroundColor: "#7EA1FF",
                  height: "213px",
                  backgroundImage: `url("/images/login_background_1-min.png")`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "100%"
            },
            img: {
                  height: "8%",
                  width: "28%",
            },
            background: {
                  backgroundImage: `url(/images/login_background_1-min.png)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "100vh",
                  [theme.breakpoints.down("sm")]: {
                        display: "none"
                  },
            },
            tickIcon:{
                  color:"#5078e1",
                  fontSize:"25px"
            }
      }));