import React from "react";
import { withNavBars } from "../../HOCs";
import { PropertyCreation } from "./createproperty";

class PropertyCreationParent extends React.Component {
    render() {
        return <PropertyCreation />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(PropertyCreationParent, props);
