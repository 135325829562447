import { ActivePropertyIcon } from "../../assets";
import { startAndEndOfMonth, startAndEndOfWeek } from "../../utils";

export const stratureData = (data) => {
  let visitorCard = [
    {
      count: data?.visitorWokerEntiries ?? 0,
      image: <ActivePropertyIcon />,
      sub: "Visitors & Workers Entries",
    },
    {
      count: data?.domesticHelperEntieirs ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: "Domestic Help Entries",
      image_type: true,
    },
    {
      count: data?.vendorAndServiceEntieirs ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: "Vendors & Service Providers Entries",
      image_type: true,
    },
  ];
  let agreementCard = [
    {
      count: data?.executedAgreements ?? 0,
      image: <ActivePropertyIcon />,
      sub: "New Agreement",
    },
    {
      count: data?.declinedAgreements ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: "Declined Agreement",
      image_type: true,
    },
    {
      count: data?.underOnboardingAgreements ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: "Under Onboarding",
      image_type: true,
    },
  ];
  const Requests = [
    {
      sub: data?.openVisitorRequest ?? 0,
      sub1: "Open Visitors Requests",
    },
    {
      sub: `${data?.openDomesticHelperRequest ?? 0}`,
      sub1: "Open Domestic Help Requests",
    },
    {
      sub: data?.openCollectionRequest ?? 0,
      sub1: "Open Collection Requests",
    },
  ];
  let visitors = [
    {
      count: data?.openVisitorParking ?? 0,
      image: <ActivePropertyIcon />,
      sub: "Open Visitor Parking Requests",
    },
    {
      count: data?.vacantVisitorParking ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: "Vacant Visitor Parking Slots",
      image_type: true,
    },
    {
      count: data?.reservedVisitorParking ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: "Reserved Parking Slots",
      image_type: true,
    },
  ];
  let vicle = [
    {
      count: data?.vehicleStats?.fourWheelers ?? 0,
      image: <ActivePropertyIcon />,
      sub: "Registered Four Wheelers",
    },
    {
      count: data?.vehicleStats?.twoWheelers ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: "Registered Two Wheelers",
      image_type: true,
    },
    {
      count: data?.vehicleStats?.biCycles ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: "Registered Bicycles",
      image_type: true,
    },
  ];
  let security = [
    {
      count: data?.violatedRequests ?? 0,
      image: <ActivePropertyIcon />,
      sub: "Security Violation Recorded",
    },
    {
      count: data?.secrityStaffs ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: "Security Staff",
      image_type: true,
    },
    {
      count: data?.securityStaffAppAccess ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: "Security Staff With App Access",
      image_type: true,
    },
  ];
  let visitor_requests = [
    {
      name: "Checked In Entires" ?? "",
      count: data?.checkInAndOut?.checkedInEntires ?? 0,
      fill: `#F3E137`,
    },
    {
      name: "Checked Out Entires" ?? "",
      count: data?.checkInAndOut?.checkedOutEntires ?? 0,
      fill: `#5AC782`,
    },
  ];
  let workersRequest = data?.workersRequest?.map((x) => {
    return {
      name: x?.month ?? "",
      value: x?.count ?? 0,
    };
  });
  let collectionRequest = data?.collectionRequest?.data?.map((x) => {
    return {
      name: x?.delivery_status ?? 0,
      count: x?.count ?? 0,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  let collectionRequestTotal = data?.collectionRequest?.total ?? 0;
  let visitor_requestsTotal = data?.checkInAndOut?.total ?? 0;
  let domesticHelperRequest = data?.domesticHelperRequest?.data?.map((x) => {
    return {
      name: x?.request_status ?? 0,
      count: x?.count ?? 0,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  let domesticRequestTotal = data?.domesticHelperRequest?.total ?? 0;
  let parkingSlotRequest = data?.parkingSlotRequest?.data?.map((x) => {
    return {
      name: x?.parking_type ?? 0,
      count: x?.count ?? 0,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  let parkingSlotRequestToltal = data?.parkingSlotRequest?.total ?? 0;
  let parkingSlotOccupancy = data?.parkingSlotOccupancy?.map((x) => {
    return {
      name: x?.parking_status ?? 0,
      count: JSON.parse(x?.count) ?? 0,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  let entriesByGates = data?.entriesByGates?.map((x) => {
    return {
      name: x?.name ?? 0,
      count: JSON.parse(x?.count) ?? 0,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  let gatesAndParkingArea = data?.gatesAndParkingArea?.map((x) => {
    return {
      name: x?.label,
      value: x?.value ?? 0,
    };
  });
  let checkInEntiries = data?.checkInEntiries ?? [];
  let checkInAndOutEntiries = data?.checkInAndOutEntiries ?? [];
  let deliveryCollectionSummary = data?.deliveryCollectionSummary ?? [];
  const final = {
    visitorCard,
    agreementCard,
    Requests,
    visitors,
    vicle,
    security,
    visitor_requests,
    workersRequest,
    collectionRequest,
    collectionRequestTotal,
    visitor_requestsTotal,
    domesticHelperRequest,
    domesticRequestTotal,
    parkingSlotRequest,
    parkingSlotRequestToltal,
    parkingSlotOccupancy,
    entriesByGates,
    gatesAndParkingArea,
    checkInEntiries,
    checkInAndOutEntiries,
    deliveryCollectionSummary,
  };
  return final;
};
export const getMonth = [
  {
    label: "Today",
    value: {
      startDate: new Date(),
      endDate: new Date(),
    },
  },
  {
    label: "YesterDay",
    value: {
      startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      endDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
    },
  },
  {
    label: "This Week",
    value: startAndEndOfWeek(new Date()),
  },
  {
    label: "This Month",
    value: startAndEndOfMonth(),
  },
];
