import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import OtpInput from "react-otp-input";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps } from "../../utils";
import { NetWorkCallMethods } from "../../utils/constants";
import { useStyles } from "./styles";

export const EnterOtps = (props) => {
  const classes = useStyles(props);
  const [counter, setCounter] = React.useState(59);
  const alert = React.useContext(AlertContext);
  const [resendBtn, setResendBtn] = React.useState(false);

  const resendOtp = () => {
    const data = {
      tenantId: `${config.tenantId}`,
      email_id: props?.emailId,
    };
    NetworkCall(
      `${config?.authapi}/auth/resendOTP`,
      NetWorkCallMethods.post,
      data,
      null,
      false,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "OTP Resend Successfully",
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
      if (counter === 1) {
        setResendBtn(true);
      }
    };
  }, [counter]);
  return (
    <Container maxWidth={"sm"}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs>
          <OtpInput
            isInputNum
            shouldAutoFocus={true}
            className={classes.otp}
            value={props.value}
            onChange={props.handleChange}
            numInputs={4}
            separator={<span>&nbsp;&nbsp;&nbsp;</span>}
          />
        </Grid>
        <br />
        <Grid item xs>
          {resendBtn ? (
            <Typography className={classes.resendOtps} onClick={resendOtp}>
              Resend OTP
            </Typography>
          ) : (
            <Typography className={classes.resendOtp}>
              Resend in &nbsp;
              <span>00:{counter}</span>
            </Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
