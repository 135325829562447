import { useApolloClient } from "@apollo/client";
import Grid from '@mui/material/Grid';
import React, { useEffect, useMemo, useState } from 'react';
import { Location } from '../../../components';
import { GET_UNIT_CATEGORY_LEASE } from "../../../graphql/propertySearch";
import { GETCOMPANYDETAILS, GETLEADBYOWNERDROPDOWN } from "../../../graphql/quotationQueries";
import { enumSelect, enum_types, LocalStorageKeys } from '../../../utils';
import { loadOptions as loadOptionsQuery } from "../../../utils/asyncPaginateLoadOptions";
import { CustomAsyncSelect, CustomDatePicker, LeadSelect, LocationTextField, RequiredArea } from '../components';
import { Purpose, SearchIcon, UnitCategory } from '../components/svg';
import { getDateValue, loadOptions, measurementUnits ,returnMomentFormat} from '../utils';


export const Commercial = (props) => {

    const {
        data = {},
        filterData = {},
        onChangeState,
        location,
        handleLead,
        selectedLead
    } = props;

    const [loading, setLoading] = useState(null);
    const client = useApolloClient();
    const [enumValue, setEnum] = React.useState({
        purpose: []
    })
    const current_date = new Date();
    let min_date_duration = current_date.setFullYear(current_date.getFullYear()-1)

    const getCompnayDetails = () => {
        client.query({
            query: GETCOMPANYDETAILS,
            fetchPolicy: 'network-only',
            variables: {
                id: props?.company,
            }
        }).then((res) => {
            onChangeState('requiredArea', {
                maxArea: "10000",
                minArea: "1",
                measurement: res?.data?.company_master[0]?.uom_master?.value,
                mesurmentLabel: res?.data?.company_master[0]?.uom_master?.label,
            })
        }).catch((err) => console.log(err))

    }
    React.useEffect(() => {
        if (props?.company) {
            getCompnayDetails()
        }
        // eslint-disable-next-line
    }, [props?.company])
    const handleLoadOptions = (search, array, type, functionParams) => {
        setLoading(type)
        return loadOptions(search, array, type, {
            revenue: props?.type ?? 'Commercial', ...functionParams
        }, setLoading, props?.company)
    }

    const getDateOutput = useMemo(() => {
        return getDateValue(data?.duration, false, filterData?.facilitesbool)
    }, [data?.duration, filterData?.facilitesbool])

    const returnRequiredArea = useMemo(() => {
        const { maxArea = "", minArea = "", mesurmentLabel = "" } = data?.requiredArea ?? {}
        return minArea && maxArea ? `${minArea} - ${maxArea} ${mesurmentLabel}` : ""
    }, [data?.requiredArea])

    const getEnum = async () => {
        const result = await enumSelect([enum_types.revenue_type])
        setEnum({
            purpose: result?.revenue_type
        })
    }
    useEffect(() => {
        getEnum()
        // eslint-disable-next-line
    }, [])

    const manualResponse = (array) => array?.map(_ => {
        return {
            ..._,
            label: _?.contact?.first_name,
            value: _?.contact?.id,
        }
    });
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <LeadSelect
                        label={"Search"}
                        icon={<SearchIcon width={12} height={12} />}
                        value={selectedLead ?? null}
                        onChange={(value) => handleLead(value)}
                        isPaginate
                        loadOptions={(search, array, handleLoading) => loadOptionsQuery(
                            search,
                            array,
                            handleLoading,
                            GETLEADBYOWNERDROPDOWN,
                            'lead',
                            { company: props?.company, client: localStorage.getItem(LocalStorageKeys.clinetID) },
                            {},
                            {},
                            manualResponse
                        )}
                        debounceTimeout={800}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Location
                        value={location}
                        handleChange={(value) => onChangeState('location', value)}
                        textFieldComponent={(ref, value, handleChange) => (
                            <LocationTextField
                                label={"Location"}
                                inputRef={ref}
                                handleChange={handleChange}
                                value={value}
                                placeholder={'Select City'}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    {
                        data?.propertyPurpose?.value === 'Sale' ? (
                            <CustomDatePicker
                                placeholder={"Choose Handover Date"}
                                label={"Handover"}
                                value={data?.handOverDate}
                                data={data?.handOverDate?.date ? returnMomentFormat(data?.handOverDate?.date) : ''}
                                onChange={(value) => onChangeState('handOverDate', value)}
                                title="Handover Date"
                                min_date_duration={min_date_duration}
                            />
                        ) : (

                            <CustomDatePicker
                                placeholder={"Choose Date Range"}
                                label={"Duration"}
                                value={data?.duration}
                                data={getDateOutput}
                                onChange={(value) => onChangeState('duration', value)}
                                title="Lease Duration"
                                showEndDate
                                min_date_duration={min_date_duration}
                            />
                        )
                    }
                </Grid>
                <Grid item xs={3} md={3}>
                    <CustomAsyncSelect
                        placeholder={"Select Purpose"}
                        options={enumValue?.purpose}
                        value={data?.propertyPurpose ?? null}
                        onChange={value => onChangeState('propertyPurpose', value)}
                        label={"Property Purpose"}
                        icon={<Purpose width={15} height={15} />}
                    />
                </Grid>
                <Grid item xs={3} md={3}>
                    <CustomAsyncSelect
                        placeholder={"Select Type"}
                        value={data?.propertyType ?? null}
                        onChange={value => onChangeState('propertyType', value)}
                        label={"Property Type"}
                        icon={<Purpose width={15} height={15} />}
                        loading={loading === "property_group_master"}
                        isPaginate
                        debounceTimeout={800}
                        loadOptions={(search, array) => handleLoadOptions(search, array, 'property_group_master', { all: true })}
                    />
                </Grid>

                <Grid item xs={3} md={3}>
                    <CustomAsyncSelect
                        placeholder={"Select Category"}
                        // options={UNITCATEGORYLIST(props?.type ? 'storage' : 'commercial')}
                        value={data?.unitCategory ?? null}
                        onChange={value => onChangeState('unitCategory', value)}
                        label={"Unit Category"}
                        icon={<UnitCategory width={15} height={15} />}
                        loading={loading === "unit_category_master"}
                        isPaginate={true}
                        loadOptions={(search, array, handleLoading) => loadOptionsQuery(
                            search,
                            array,
                            handleLoading,
                            GET_UNIT_CATEGORY_LEASE,
                            'unit_category_master',
                            { all: true }
                        )}
                    />
                </Grid>
                <Grid item xs={3} md={3}>
                    <RequiredArea
                        label={"Required Area"}
                        // disabled={data?.unitCategory?.value === unitCategoryEnum?.plot || data?.unitCategory?.value === unitCategoryEnum?.land ? false : true}
                        data={data?.requiredArea}
                        onChange={value => onChangeState('requiredArea', value)}
                        options={measurementUnits}
                        value={returnRequiredArea}
                    />
                </Grid>
            </Grid>
        </div >
    )
}