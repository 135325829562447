import { Box, Grid, Typography, Stack, Divider, Avatar } from "@mui/material";
import React from "react";
import {
  LoadingSection,
  TableWithPagination,
} from "../../../components";
import { useWindowDimensions, assestType ,convertTimeUtcToZoneCalander} from "../../../utils";
import { unitStyles } from "../style";
import { AssetView } from "../../propertyFinder3/component/assetView";
import Location from "../../../assets/locationsymbol";
import PhoneImg from "../../../assets/phone";
import EmailIMG from "../../../assets/mailImg";
import TelephoneImg from "../../../assets/telephone";
import WebSiteImg from "../../../assets/website";
import Bed from "../../../assets/bed";
import Bath from "../../../assets/bath";
import Furniture from "../../../assets/furnichair";
import { UnitChidpath, UnitChidheading } from "../../../utils/block";
import ShowMoreText from "react-show-more-text";


export const DetailsContainer = (props) => {
  const classes = unitStyles();
  // const [loading, setLoading] = React.useState(true);
  const size = useWindowDimensions();


  const unitDetaild = [
    {
      name: props?.stats?.data?.unit_details?.block_name,
      color: "#E29336",
      backgroundColor: "#FFEACC",
      isTrue: props?.stats?.data?.unit_details?.block_name?.length > 0 ? true : false,
    },
    {
      name: props?.stats?.data?.unit_details?.floor_name,
      color: "#6A69D2",
      backgroundColor: "#CFCFFF",
      isTrue: props?.stats?.data?.unit_details?.floor_name?.length > 0 ? true : false,
    },
  ];
  const unitDetails = [
    {
      result: props?.stats?.data?.unit_details?.year_built ? convertTimeUtcToZoneCalander(props?.stats?.data?.unit_details?.year_built) : "-",
      catagory: "Year Built",
      divider: 3
    },
    {
      result: props?.stats?.data?.unit_details?.unit_category,
      catagory: "Category",
      divider: 4.5
    },
    {
      result: props?.stats?.data?.unit_details?.revenue_type,
      catagory: "Revenue",
      divider: 4.5
    },
    {
      result: props?.stats?.data?.unit_details?.unit_type,
      catagory: "Unit Type",
      divider: 3
    },
    {
      result: props?.stats?.data?.unit_details?.orientation,
      catagory: "Orientation",
      divider: 4.5
    },
    {
      result: props?.stats?.data?.unit_details?.unit_purpose,
      catagory: "Purpose",
      divider: 4.5
    },
  ];
  const details = [
    {
      title: "BUILT-UP AREA",
      contant: `${props?.stats?.data?.unit_details?.carpet_area ?? 0} ${props?.stats?.data?.unit_details?.area_metric}`,
    },
    {
      title: "SALEABLE AREA",
      contant: `${props?.stats?.data?.unit_details?.total_area ?? 0} ${props?.stats?.data?.unit_details?.area_metric}`,
    },
    {
      title: "BALCONY AREA",
      contant: `${props?.stats?.data?.unit_details?.balcony_area ?? 0} ${props?.stats?.data?.unit_details?.area_metric}`,
    },
    // {
    //   title: "DAILY RATE",
    //   contant: "10000",
    // },
    // {
    //   title: "MONTHLY RATE",
    //   contant: "10000",
    // },
    // {
    //   title: "ANNUAL RATE",
    //   contant: `${props?.stats?.data?.unit_details?.lease_or_sale_value ?? 0} ${props?.stats?.data?.unit_details?.symbol}`,
    // },
  ];
  return (
    <>
      {props?.loading ? (
        <LoadingSection message={"Loading"} />
      ) : (
        <Box>
          <Box style={{ height: size?.height - 210, overflow: "auto" }}>
            <Box>
              <AssetView
                asset={props?.stats?.data?.assets
                  ?.filter((x) => x?.asset_type !== assestType?.Videos)
                  ?.filter((x) => x?.asset_type !== assestType?.Documents)
                  ?.map((x) => x)}
                assetAll={props?.stats?.data?.assets}
              />
            </Box>

            <Stack
              direction="row"
              divider={
                <Divider orientation="vertical" className={classes.divider} />
              }
              marginBottom={1}
              spacing={2}
              justifyContent="start"
              alignItems={"center"}
            >
              <Avatar src={props?.stats?.data?.unit_details?.logo} alt={""} />
              <Box>
                <Typography className={classes.aminitiesDeatils}>
                  {props?.stats?.data?.unit_details?.unit_no}
                </Typography>
              </Box>

              <Box>
                <Typography className={classes.aminitiesDeatils}>
                  {props?.stats?.data?.unit_details?.name}
                </Typography>
              </Box>
            </Stack>

            <Grid container spacing={2}>
              {unitDetaild?.map((x) => {

                return(
                  x?.isTrue && 
                  <Grid item xs={4}>
                      <Typography
                        className={classes.chip}
                        style={{
                          backgroundColor: x?.backgroundColor,
                          color: x?.color,
                        }}
                      >
                        {x?.name}
                      </Typography>
                      </Grid>
                )
                
              })}
            </Grid>

            <Box className={classes.graftParent}>
              <Grid container spacing={1}>
                {unitDetails?.map((x) => {
                  return (
                    <Grid item xs={x?.divider}>
                      <Box className={classes.flexBox}>
                        <Typography className={classes.catagory} noWrap>
                          {x?.catagory + ":"}
                        </Typography>
                        &nbsp;
                        <Typography className={classes.catagoryName} noWrap>
                          {x?.result}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            {props?.stats?.data?.unit_details?.description?.length > 0 && (
              <Box className={classes.borderBottom}>
                <ShowMoreText
                  lines={1}
                  more="Show More"
                  less="Show Less"
                  className={classes.detailsub}
                  anchorClass={classes.seeMoreLessTextStyle}
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: props?.stats?.data?.unit_details?.description,
                    }}
                  ></span>
                </ShowMoreText>
              </Box>
            )}

            <Box className={classes.addressDetailsBox}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box className={classes.addressflexBox}>
                    <Box>
                      <Location />
                    </Box>
                    <Box>
                      <Typography className={classes.addressDeatils}>
                        {props?.stats?.data?.unit_details?.door_no?.length >
                          0 && props?.stats?.data?.unit_details?.door_no + ","}
                        &nbsp;{" "}
                        {props?.stats?.data?.unit_details?.street_1?.length >
                          0 &&
                          props?.stats?.data?.unit_details?.street_1 + ","}{" "}
                        {props?.stats?.data?.unit_details?.street_2?.length >
                          0 && props?.stats?.data?.unit_details?.street_2 + ","}
                        {props?.stats?.data?.unit_details?.landmark?.length >
                          0 &&
                          props?.stats?.data?.unit_details?.landmark + ","}{" "}
                        {props?.stats?.data?.unit_details?.district?.length >
                          0 && props?.stats?.data?.unit_details?.district + ","}
                        {props?.stats?.data?.unit_details?.country?.length >
                          0 && props?.stats?.data?.unit_details?.country + ","}
                        -{props?.stats?.data?.unit_details?.zipcode + "."}
                      </Typography>
                    </Box>
                  </Box>
                  <Box height={"10px"} />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box className={classes.addressflexBox}>
                    <Box>
                      <PhoneImg />
                    </Box>
                    <Box>
                      <Typography className={classes.addressDeatils}>
                        {props?.stats?.data?.unit_details?.mobile_country_code}-{" "}
                        {props?.stats?.data?.unit_details?.mobile_phone}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className={classes.addressflexBox}>
                    <Box>
                      <TelephoneImg />
                    </Box>
                    <Box>
                      <Typography className={classes.addressDeatils}>
                        {
                          props?.stats?.data?.unit_details
                            ?.business_phone_country_code
                        }
                        - {props?.stats?.data?.unit_details?.business_phone}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box className={classes.addressflexBox}>
                    <Box>
                      <EmailIMG />
                    </Box>
                    <Box>
                      <Typography className={classes.addressDeatils}>
                        {props?.stats?.data?.unit_details?.email}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className={classes.addressflexBox}>
                    <Box>
                      <WebSiteImg />
                    </Box>
                    <Box>
                      <Typography className={classes.addressDeatils}>
                        {props?.stats?.data?.unit_details?.website}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.bathDetails}>
              <Box className={classes.flexBox}>
                <Bed />
                <Typography className={classes.bedText}>
                  {props?.stats?.data?.unit_details?.total_rooms} Bedrooms
                </Typography>
              </Box>
              <Box className={classes.dot} />
              <Box className={classes.flexBox}>
                <Bath />
                <Typography className={classes.bedText}>
                  {props?.stats?.data?.unit_details?.total_baths} Baths
                </Typography>
              </Box>
              <Box className={classes.dot} />
              <Box className={classes.flexBox}>
                <Furniture />
                <Typography className={classes.bedText}>
                  {props?.stats?.data?.unit_details?.furnishing}{" "}
                </Typography>
              </Box>
            </Box>
            {/* amount */}
            <Box className={classes.amountParnt}>
              <Grid container spacing={2}>
                {details?.map((x) => {
                  return (
                    <Grid item xs={4}>
                      <Box className={classes.amountCard}>
                        <Typography className={classes.amountTitle}>
                          {x?.title}
                        </Typography>
                        <Typography className={classes.amountContant}>
                          {x?.contant}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            {/* pricing tabel */}
            <Box className={classes.pricingTabelParent}>
              <Typography className={classes.aminitiesDeatils}>
                Pricing Table
              </Typography>
              <Box>
                {props?.stats?.data?.pricing?.component?.map((x) => {
                  return (
                    <Box className={classes.pricingBoxFlex}>
                      <Typography className={classes.addressDeatils}>
                        {x?.rentalBreakup}
                      </Typography>
                      <Typography className={classes.count}>
                        {x?.amount}
                      </Typography>
                    </Box>
                  );
                })}
                <Box className={classes.addressBoxFlex}>
                  <Typography className={classes.addressDeatils}>
                    Tax
                  </Typography>
                  <Typography className={classes.count}>
                    {props?.stats?.data?.unit_details?.symbol ?? ""} {props?.stats?.data?.pricing?.totalTax ?? 0}
                  </Typography>
                </Box>
              </Box>
{/* 
              <Box className={classes.totalMountBox}>
                <Box className={classes.addressBoxFlex}>
                  <Typography className={classes.addressDeatils}>
                    Total Per Month
                  </Typography>
                  <Typography className={classes.count}>
                    {props?.stats?.data?.unit_details?.symbol ?? ""} {props?.stats?.data?.pricing?.totalAmount ?? 0}
                  </Typography>
                </Box>
              </Box> */}
            </Box>
            {props?.stats?.data?.unit_amenities?.length > 0 && (
              <Box className={classes.aminitiesParent}>
                <Typography className={classes.aminitiesDeatils}>
                  Unit Amenities
                </Typography>
                <Grid container spacing={2}>
                  {props?.stats?.data?.unit_amenities?.map((x) => {
                    return (
                      <Grid item xs={4}>
                        <Typography className={classes.amenitieslist}>
                          {x}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            )}

            {props?.stats?.data?.unit_utilties?.length > 0 && (
              <Box
                className={classes.aminitiesParent}
                style={{ marginTop: "16px" }}
              >
                <Typography className={classes.aminitiesDeatils}>
                  Unit Utilities
                </Typography>
                <Grid container spacing={2}>
                  {props?.stats?.data?.unit_utilties?.map((x) => {
                    return (
                      <Grid item xs={4}>
                        <Typography className={classes.amenitieslist}>
                          {x}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            )}
            <Grid container className={classes.unitVacancyParent}>
              <Grid item xs={12}>
                <Typography className={classes.aminitiesDeatils}>
                  Unit Vacancy
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableWithPagination
                  heading={UnitChidheading}
                  rows={props?.stats?.data?.unit_vacancy_period ?? []}
                  path={UnitChidpath}
                  // showpagination={true}
                  showpdfbtn={false}
                  showexcelbtn={false}
                  showSearch={false}
                  tableType="no-side"
                  count="2"
                  dataType={[
                    { type: ["date"], name: "available_from" },
                    { type: ["date"], name: "available_to" },
                    { type: ["status"], name: "status" },
                  ]}
                  height={`calc(100vh - 500px)`}
                  view={true}
                  edit={true}
                  delete={true} />
              </Grid>
            </Grid>

            {/* <Box className={classes.revenueBorder}>
              <Box className={classes.revenueParent}>
                <Typography className={classes.aminitiesDeatils}>
                  Revenue
                </Typography>
                <div className={classes.graphPadding}>
                  <GroupPieCharts data={barData} />
                </div>
                <Box className={classes.textCenter}>
                  <Typography className={classes.detailsText}>
                    This Year Renvenue
                  </Typography>
                  <Typography className={classes.totalCount}>
                    $ 20000
                  </Typography>
                </Box>
              </Box>
            </Box> */}
          </Box>
          {/* <Button variant="contained" className={classes.shortlistbtn}>
            shortlist This Unit
          </Button> */}
        </Box>
      )}
    </>
  );
};
