import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Avatar, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
    outerCircle: {
        padding: "3px",
        border: "1px solid #5078E1",
        borderRadius: "50%",
    },
    Avatar: {
        backgroundColor: theme.palette.primary.main,
        width: "18px",
        height: "18px",
        fontSize: "9px",
    },
    stepInactive: {
        fontSize: '14px',
        color: theme.typography.color.tertiary,
    },
    stepactive: {
        fontSize: '14px',
        color: theme.palette.primary.main,
    },
    stepInactiveArrow: {
        color: theme.typography.color.tertiary,
        fontSize: "20px",
        marginTop: "8px"
    },
    stepactiveArrow: {
        color: theme.palette.primary.main,
        fontSize: "20px",
        marginTop: "8px"
    },
    stepRoot: {
        borderBottom: "1px solid #E4E8EE",
        borderTop: "1px solid #E4E8EE",
        padding: "6px 12px",
        backgroundColor: "white"
    }
}));
export const Stepper = ({ step = 1, steps = [] }) => {
    const classes = useStyles()
    return (
        <Box display="flex" alignItems="center" className={classes.stepRoot}>
            {
                steps?.map((val, index) => {
                    return (
                        <Box display="flex" alignItems="center">
                            <Box>
                                {
                                    val?.value === val?.step &&
                                    <div className={classes.outerCircle}>
                                        <Avatar className={classes.Avatar}>{val?.stepValue ? val?.stepValue : val?.step}</Avatar>
                                    </div>
                                }

                            </Box>
                            <Box marginLeft="4px" marginRight="4px">
                                <Typography noWrap className={val?.value === val?.step ? classes.stepactive : classes.stepInactive}>{val?.label}</Typography>
                            </Box>
                            {
                                index !== (steps?.length - 1) &&
                                <Box>
                                    <ChevronRightIcon className={val?.value === val?.step ? classes.stepactiveArrow : classes.stepInactiveArrow} />
                                </Box>
                            }

                        </Box>
                    )
                })
            }

        </Box>
    )
}