import { useApolloClient } from "@apollo/client";
import DoneIcon from '@mui/icons-material/Done';
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Account as AccountIMG } from "../../../assets";
import { AlertDialog, SearchFilter,UseDebounce } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { GET_ACCOUNT_BY_COMPANY } from "../../../graphql/quotationQueries";
import { NetworkCall } from "../../../networkcall";
import { AccountList } from "../../../screens/quotationDetails/components/accountList";
import { AlertProps, isEmptyObject, LocalStorageKeys, NetWorkCallMethods, ValidateEmail } from '../../../utils';
import { AccountForm } from "../component";
import { useStyles } from "./styles";
import InfiniteScroll from "react-infinite-scroll-component";

const initialState = {
    name: "",
    url: "",
    accountNo: "",
    mobile: "",
    currency: "",
    tax: "",
    email: "",
    proofFile: [],
    error: {
        name: "",
        email: "",
        accountNo: "",
        mobile: "",
        relationShip: "",
        currency: "",
        tax: "",
    }
}
export const Account = ({ company_id = "", setSelectedAcc = () => false, selectedAcc = "", contactId = "" }) => {
    const debounce = UseDebounce()
    const classes = useStyles()
    const client = useApolloClient();
    const [account, setAccout] = React.useState([])
    const [open, setOpen] = React.useState(selectedAcc?.id ? false : true)
    const [acc, setAcc] = React.useState({ ...initialState })
    const [search, setSearch] = React.useState("")
    const [disableAcc, setDisableAcc] = React.useState(false)
    const [openAcc, setOpenAcc] = React.useState(false)
    const [offset , setOffset] = React.useState(0)

    const alert = React.useContext(AlertContext);
    const getAccount = (searchText , offset , filter) => {
        client.query({
            query: GET_ACCOUNT_BY_COMPANY,
            fetchPolicy: 'network-only',
            variables: {
                client: localStorage.getItem(LocalStorageKeys.clinetID),
                id: company_id,
                searchText,
                offset:offset ?? 0,
                limit:10
            }
        }).then((response) => {
            if(filter){
                setAccout(account.concat(response?.data?.contact_account))
            }
            else{
                setAccout(response?.data?.contact_account)
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    //update state of Account
    const updateStateAcc = (key, value) => {
        let error = acc.error;
        error[key] = "";
        setAcc({ ...acc, [key]: value, error });
    };
    React.useEffect(() => {
        getAccount("" , 0)
        // eslint-disable-next-line
    }, [])
    const onSelectAccount = (val) => {
        setSelectedAcc(val)
        setOpen(false)
    }
    //validate account form
    const validateAccountForm = () => {
        let isValid = true;
        let error = acc.error;
        //validate first name
        if (acc?.name?.length === 0) {
            isValid = false;
            error.name = "Account Name is Required";
        }

        //validate email
        if (acc?.email?.length === 0) {
            isValid = false;
            error.email = "Email ID is Required";
        }
        //validate email
        if (acc?.email?.length > 0) {
            if (ValidateEmail(acc?.email) === false) {
                isValid = false;
                error.email = "Invalide Email ID";
            }
        }

        //validate Mobile Number
        if (acc?.mobile?.length === 0) {
            isValid = false;
            error.mobile = "Mobile Number is Required";
        }

        setAcc({ ...acc, error });
        return isValid;
    }
    //on submit create account
    const onSubmitAccount = () => {

        if (validateAccountForm() && isEmptyObject(acc?.proofFile)) {
            setDisableAcc(true)
            let proof = acc?.proofFile?.filter((val) => val?.is_active)?.map(val => {
                return {
                    proof_id: val?.type?.value,
                    proof_type: val?.type?.proof_type,
                    kyc_type: "Both",
                    name: val?.name,
                    number: val?.id_no,
                    valid_from: val?.valid_from,
                    valid_to: val?.valid_to,
                    url: val?.file,
                    is_mandatory: val?.mandatry_verify,
                    country_id: val?.issuing_country?.value
                }
            })

            const formData = new FormData();
            for (let i = 0; i < acc?.url?.length; i++) {
                formData.append("files", acc?.url[i]);
            }
            formData.append("tenantId", `${config.tenantId}`);
            formData.append("name", `${acc?.name}`);
            formData.append("email_id", `${acc?.email}`);
            formData.append("mobile_no", `${acc?.mobile?.mobile}`);
            formData.append("mobile_no_country_code", `${acc?.mobile?.mobile_code}`);
            formData.append("contact_id", `${contactId}`);
            formData.append("company_id", `${company_id}`);
            formData.append("proof", JSON.stringify(proof));
            NetworkCall(
                `${config.api_url}/account/create`,
                NetWorkCallMethods.post,
                formData,
                null,
                true,
                false
            )
                .then((response) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: "Account Successfully Created",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });

                    setDisableAcc(false)
                    getAccount("" , 0)
                    setOffset(0)
                    setOpenAcc(false)

                })
                .catch((error) => {
                    setDisableAcc(false)
                });
        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please fill All fields",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }
    }
        // infinity scroll
        const fetchMoreData = () => {
            setOffset(offset + 10);
            getAccount("",offset + 10 , true)
        };
        const searchFunction =(e)=>{
            setSearch(e)
            debounce(() =>  getAccount(e , 0), 1000);
        }
    return (
        <Box style={{position:"relative"}}>
            <Stack direction="row" justifyContent="space-between">
                <Typography className={classes.addresstitle}>ACCOUNT DETAILS</Typography>
                <Typography onClick={() => setOpenAcc(true)} className={classes.addAddress}>Add Account</Typography>
            </Stack>
            <Box height="8px" />
            <Box onClick={() => setOpen(!open)}>
                <SearchFilter
                    value={search}
                    handleChange={(e) => searchFunction(e)}
                    placeholder="Search Existing Account" />
            </Box>
            <InfiniteScroll
                dataLength={account?.length ?? ""}
                next={fetchMoreData}
                hasMore={true}
                height={250}
                className={classes.accountListRoot}
            >
                <Box  >
                    {
                        account?.map((val) => {
                            return (
                                <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }} p={1} onClick={() => onSelectAccount(val)} >
                                    <Box className={classes.AccountImg1}>
                                        <AccountIMG color="#98A0AC" />
                                    </Box>
                                    <Box flexGrow={1} marginLeft="8px">
                                        <Typography className={classes.accountNamelist}>
                                            {val?.name}
                                        </Typography>
                                        <Typography className={classes.accountNo}>
                                            {val?.account_no}
                                        </Typography>
                                    </Box>
                                    {
                                        val?.id === selectedAcc?.id &&
                                        <Box>
                                            <DoneIcon fontSize='small' color='primary' />
                                        </Box>
                                    }


                                </Box>
                            )
                        })
                    }
                </Box>
                </InfiniteScroll>
            <Box height="12px" />
            {
                selectedAcc?.id && <AccountList iconColor="#5AC782" color="#EEF9EE" disable value={selectedAcc} />
            }

            {/*account dialog*/}
            <AlertDialog
                open={openAcc}
                onClose={() => setOpenAcc(false)}
                header={"Add Account"}
                component={<AccountForm disableAcc={disableAcc} onSubmitAccount={onSubmitAccount} onClose={() => setOpenAcc(false)} account={acc} updateStateAcc={updateStateAcc} />}
            />
        </Box>
    )
}