import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { CustomCheckBox, Subheader, TextBox } from '../../../components';
import { config } from '../../../config';
import { AlertContext } from '../../../contexts';
import { NetworkCall } from '../../../networkcall';
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from '../../../utils';
import { UploadImageURL } from '../../configurationMaster/imageUploadURL';
import { UploadImage } from '../../configurationMaster/upload';
import { conditions } from '../../configurationMaster/utils';
import { useStyles } from "./styles";




export const Banners = ({ type = "", company = {}, goBack = () => false, condition = "", title = "", reload = () => false }) => {

    const alert = React.useContext(AlertContext);
    const classes = useStyles()
    const [loading, setLoading] = useState(null);
    const [state, setState] = useState({
        bannerStatus: false,
        uploadedImages: [],
        isEdit: false,
        deleteAssests: [],
        existingAssests: [],
        updateAssests: []
    })
    const returnAssests = (data) => {
        return data?.map(_ => ({
            src: _?.url,
            url: _?.action_url ?? '',
            priority: _?.priority,
            id: _?.id,
            name: _?.file_meta?.name,
            type: _?.file_meta?.type,
            size: _?.file_meta?.size
        }))
    }
    const getConfigurationDetails = async (id) => {

        setLoading('screen');
        const payload = {
            id: company?.id,
            tenantId: config.tenantId
        };
        await NetworkCall(
            `${config.api_url}/company-banner/get-banner`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let result = response.data.data?.find(x=>x?.type === type);
                setState({
                    ...state,
                    company: {
                        label: result?.company_name,
                        value: result?.company_id
                    },
                    bannerStatus: result?.is_active,
                    uploadedImages: returnAssests(result?.assets ?? []),

                    isEdit: true,
                    existingAssests: result?.assets?.map(({ id }) => id)
                })
                setLoading(null);
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                });
            });
    }

    const onChangeState = (key, value) => {
        setState({
            ...state, [key]: value
        })
        setLoading(null)
    }

    const submit = () => {
        const validationCheck = validation();
        if (validationCheck) {
            setLoading('submit')
            networkCallback()
        }
    }

    useEffect(() => {
        onChangeState('isEdit', true)
        getConfigurationDetails()
        // eslint-disable-next-line
    }, [])

    const networkCallback = () => {
        let payload = {
            "tenantId": config.tenantId,
            "company_id": company?.id,
            "id": company?.bid,
            "deleteAssets": state?.deleteAssests?.length ? state?.deleteAssests : [],
            "updateAssets": state?.updateAssests?.length ? state?.updateAssests : [],
            "type": type,
            "is_active": state?.bannerStatus,
            "user_profile_id": localStorage.getItem(LocalStorageKeys.profileID),
            "assets": state?.uploadedImages?.filter(({ id }) => !state?.existingAssests?.includes(id))?.map(({ src, url, name, size, type }) => ({
                url: src,
                action_url: url ?? '',
                file_meta: {
                    name, size, type
                }
            })),
        };
        NetworkCall(
            `${config.api_url}/company-banner/upsert`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let main = response.data.data;
                alert.setSnack({
                    open: true,
                    horizontal: AlertProps.horizontal.center,
                    vertical: AlertProps.vertical.top,
                    msg: JSON.stringify(main),
                    severity: AlertProps.severity.success,
                });
                reload()
                setLoading(null)
                goBack()
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                });
                setLoading(null)
            });
    }

    const validation = () => {
        if (company.name && state?.uploadedImages?.length) {
            return true
        } else {
            alert.setSnack({
                open: true,
                horizontal: AlertProps.horizontal.center,
                vertical: AlertProps.vertical.top,
                msg: returnErrorMsg(),
                severity: AlertProps.severity.error,
            });
            return false
        }
    }

    const returnErrorMsg = () => {
        if (state?.uploadedImages?.length === 0 && !state?.company?.value) {
            return `Please select company and upload atleast one image`
        }
        else if (state?.uploadedImages?.length === 0) {
            return `Please upload atleast one image`
        }
        else {
            return `Please select company`
        }
    }

    const handleUploadImages = (value) => {
        setLoading('uploadedImages')
        onChangeState('uploadedImages', [...state?.uploadedImages, value]);
    }

    const onChangeUrl = (value, index) => {

        let copyImage = [...state?.uploadedImages];
        copyImage[index] = value;
        setState(() => {
            if (value?.id) {
                if (state.updateAssests?.find(_ => _?.id === value?.id)) {
                    state.updateAssests.find(_ => _?.id === value?.id)['action_url'] = value?.url;
                }
                else {
                    state.updateAssests.push({ id: value?.id, action_url: value?.url })
                }

                return {
                    ...state,
                    uploadedImages: copyImage,
                    updateAssests: [...state.updateAssests]
                }
            }
            else {
                return {
                    ...state, uploadedImages: copyImage,
                }
            }
        })
    }

    const handledelete = (index, { id }) => {

        setLoading('uploadedImages');

        const filteredImage = state?.uploadedImages?.filter(_ => _?.id !== id);
        setState(() => {
            if (id) {
                return {
                    ...state,
                    uploadedImages: filteredImage,
                    deleteAssests: [...state?.deleteAssests, id]
                }
            }
            else {
                return {
                    ...state,
                    uploadedImages: filteredImage
                }
            }
        })

        setLoading(null);
    }


    return (
        <div>
            <Subheader title={title} goBack={goBack} />
            <Box p={1}>
                <Box className={classes.root}>
                    <Stack spacing={2} direction={'row'} >
                        <TextBox placeholder="" label="Company" value={company?.name} isReadonly={true} />
                        <Box>
                            <CustomCheckBox label="Banner Status" checked={state?.bannerStatus} onChange={(e) => onChangeState('bannerStatus', e.target.checked)} />
                        </Box>
                    </Stack>
                    <Box height="12px" />
                    <Typography className={classes.label}>Upload Images</Typography>
                    <Grid container marginTop="12px">
                        <Grid item xs={12}>
                            <Stack direction={'row'} spacing={2} flexWrap={"wrap"}>
                                <Box>

                                    <UploadImage
                                        height={274}
                                        width={220}
                                        handleChange={handleUploadImages}
                                        conditions={conditions[condition]}
                                    />
                                </Box>

                                {
                                    state?.uploadedImages?.length > 0 &&
                                    state?.uploadedImages?.map((_, index) => (
                                        <>
                                            <UploadImageURL
                                                data={{
                                                    // src: 'https://protomate.objectstore.e2enetworks.net/156-337x175.png',
                                                    src: _?.src,
                                                    url: _?.url ?? '',
                                                    name: _?.name,
                                                    type: _?.type,
                                                    size: _?.size,
                                                    id: _?.id

                                                }}
                                                bottom={20}
                                                loading={loading === "uploadedImages"}
                                                handleChange={(value) => onChangeUrl(value, index)}
                                                handledelete={() => handledelete(index, _)}
                                                isReadOnly={!state?.isEdit}
                                                size={{
                                                    height: 200,
                                                    width: 220
                                                }}
                                            />

                                        </>
                                    ))
                                }
                            </Stack>
                        </Grid>
                    </Grid>


                </Box>
                <Box p={1} display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="primary" disabled={loading === "submit"} onClick={submit}>
                        Save
                    </Button>
                </Box>
            </Box>
        </div>
    )
}
