import { Box, Grid, List, ListItem, Typography, IconButton } from "@mui/material";
import React from "react";
import {
  GroupPieCharts,
  PieCharts,
  TableWithPagination,
} from "../../../components";
import { useStylesCreation } from "./style";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

export const GraphComponent = (props) => {
  const classes = useStylesCreation(props);
  return (
    <div className={classes.card}>
      <Box className={classes.flexBoxWithSpace}>
        {props?.isTab ? (
          <Box>
            <TabComponent
              tabList={props?.tabList}
              tabNameState={props?.tabNameState}
              keyType={props?.keyType}
              updateState={props?.updateState}
            />
          </Box>
        ) : (
          <Box>
            <Typography className={classes.titleFont}>
              {props?.title}
            </Typography>
            {props?.titleTab && (
              <div style={{ margin: props?.margin }}>
                <TabComponent
                  tabList={props?.tabList}
                  tabNameState={props?.tabNameState}
                  keyType={props?.keyType}
                  updateState={props?.updateState}
                />
              </div>
            )}
          </Box>
        )}

        <IconButton onClick={() => props?.onZoomClick(props?.title)}>
          {props?.isZoom && (
            <img
              src="/images/propertdashboard/enlare.png"
              alt="zoom"
              onClick={() => props?.onZoomClick(props?.title)}
            />
          )}
        </IconButton>
      </Box>

      <>
        {props?.isTable ? (
          <>
            <TableWithPagination
              heading={props?.heading}
              rows={props?.tableData ?? []}
              path={props?.path}
              showpagination={props?.showpagination ?? false}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              tableType="no-side"
              count="2"
              dataType={props?.dataType}
              height={props?.tabelheight ?? `calc(100vh - 355px)`}
              handlePagination={props?.handlePagination}
              handleChangeLimit={props?.handleChangeLimit}
              totalRowsCount={props?.totalRowsCount}
              page={props?.page}
              limit={props?.limit}
              view={true}
              edit={true}
              delete={true} />
          </>
        ) : (
          <>
            {props?.isImageList ? (
              <Box>
                <ImageList
                  variant="quilted"
                  cols={3}
                  gap={5}
                  sx={{
                    width: "100%",
                    height: props?.imageheight ?? "100%",
                    overflow: "auto",
                  }}
                >
                  {props?.iamgeData?.map((item) => (
                    <ImageListItem key={item.name}>
                      <Box
                        className={classes.dividerBox}
                        style={{ backgroundColor: item?.color }}
                      >
                        <Typography className={classes.dividerBoxText}>
                          {item?.name}
                        </Typography>
                        <Box height={"42px"} />
                        <Typography className={classes.dividerBoxText}>
                          {item?.meter}
                        </Typography>
                      </Box>
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
            ) : (
              <Grid container spacing={2} className={classes.graphParentFlex}>
                <Grid item xs={12} lg={props?.divider ?? 12}>
                  {props?.line ? (
                    <div style={{ margin: props?.margin }}>
                      {props?.graphData?.datasets?.length > 0 && (
                        <GroupPieCharts
                          data={props?.graphData}
                          scale={props?.scale}
                          indexAxis={props?.indexAxis}
                          line={props?.line}
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      {props?.isBar ? (
                        <div
                          style={{
                            margin: props?.margin,
                            height: props?.height ?? "100%",
                            overflow: "auto",
                          }}
                        >
                          {props?.graphData?.datasets?.length > 0 && (
                            <GroupPieCharts
                              data={props?.graphData}
                              scale={props?.scale}
                              indexAxis={props?.indexAxis}
                              datalabels={props?.isDatalabels}
                              legend={props?.isLegend}
                            />
                          )}
                        </div>
                      ) : (
                        <div className={classes.graphMargin}>
                          <PieCharts
                            data={props?.graphData}
                            radius={props?.innerRadius}
                            width={200}
                            isTotal={props?.isTotal}
                            total={props?.total}
                            height={props?.isZoom ? null : "200px !important"}
                          />
                        </div>
                      )}
                    </>
                  )}
                </Grid>

                {/* pie chart */}

                {/* mapping data */}
                {props?.isPie && (
                  <Grid item xs={12} lg={props?.divider1 ?? 12} className={classes.gridGraphParent}>
                    <Box className={classes.graphmapData}>
                      <Grid
                        container
                        spacing={2}
                        className={classes.scrollLgend}
                      >
                        <>
                          {props?.graphData?.map((x) => {
                            return (
                              <Grid item xs={6}>
                                <Typography className={classes.graphDataCount} noWrap>
                                  {x?.count ?? 0}
                                  {x?.preffix?.length > 0 && (
                                    <Typography className={classes.graphDataTitle} noWrap>
                                      {x?.preffix}
                                    </Typography>
                                  )}
                                </Typography>
                                <Box className={classes.dotParent}>
                                  <Box
                                    className={classes.dot}
                                    style={{ backgroundColor: x?.fill }}
                                  />
                                  <Box>
                                    <Typography
                                      className={classes.graphDataTitle}
                                      noWrap
                                    >
                                      {x?.name}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            );
                          })}
                        </>
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}
      </>
    </div>
  );
};

// tab component
const TabComponent = (props) => {
  const classes = useStylesCreation(props);
  return (
    <List className={classes.list}>
      {props?.tabList?.map((x) => {
        return (
          <ListItem
            className={
              x === props?.tabNameState ? classes.listText : classes.listText1
            }
            onClick={() => props?.updateState(props?.keyType, x)}
          >
            <Typography className={classes.tabname}>{x}</Typography>
          </ListItem>
        );
      })}
    </List>
  );
};
