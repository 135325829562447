import React, { useEffect } from 'react';
import { CustomControls } from './customControls';
import './index.css';

export const MapComponent = ({
    map,
    setMap,
    onIdle,
    onClick,
    zoom_changed,
    children,
    duration,
    radius,
    setRadius,
    hideControl,
    map_id="map",
    is_zoom=false,
    ...props
}) => {

    const ref = React.useRef(null);

    // const [map, setMap] = React.useState();

    useEffect(() => {
        if (ref?.current && !map) {
            setMap(new window.google.maps.Map(ref.current));
        }
        if (map) {
            map.setOptions(props);
            // map.panTo(props?.center);
            // map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
            //     document.querySelector(".controls")
            // );
            map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(
                document.querySelector(".map-types")
            );
            map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(
                document.querySelector(".map-controls")
            );
            // map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(
            //     document.querySelector(".fullscreen-control")
            // );

            // //find whether document is full screen or not
            // let mapWrapper = document?.getElementsByClassName('map-wrapper')?.[0];
            // document.onwebkitfullscreenchange =
            //     document.onmsfullscreenchange =
            //     document.onmozfullscreenchange =
            //     document.onfullscreenchange =
            //     function () {
            //         if (isFullscreen(mapWrapper)) {
            //             mapWrapper.classList.add("is-fullscreen");
            //         } else {
            //             mapWrapper.classList.remove("is-fullscreen");
            //         }
            //     };
        }
        // eslint-disable-next-line
    }, [ref, map]);

    //useEffect fir event handlers
    useEffect(() => {
        if (map) {
            ["idle", "click"].forEach((eventName) =>
                window.google.maps.event.clearListeners(map, eventName)
            );

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }

            if (onClick) {
                map.addListener("click", (e) => onClick(e));
            }

            if (zoom_changed) {
                map.addListener("zoom_changed", (e) => zoom_changed(e, map));
            }

        }
        // eslint-disable-next-line
    }, [map, onIdle, onClick, zoom_changed]);

    const zoomIn = () => {
        map.setZoom(map.getZoom() + 1);
    }

    const zoomOut = () => {
        map.setZoom(map.getZoom() - 1);
    }

    const fullScreenControl = () => {
        const elementToSendFullscreen = document?.getElementsByClassName('map-wrapper')?.[0];
        if (!isFullscreen(elementToSendFullscreen)) {
            requestFullscreen(elementToSendFullscreen);
        }
    }

    // const ExitFullScreen = () => {
    //     exitFullscreen();
    // }

    const isFullscreen = (element) => {
        return (
            (document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||
                document.msFullscreenElement) === element
        );
    }

    const requestFullscreen = (element) => {

        if (element?.requestFullscreen) {
            element.requestFullscreen();
        } else if (element?.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
        } else if (element?.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element?.msRequestFullScreen) {
            element.msRequestFullScreen();
        }
    }

    // const exitFullscreen = () => {
    //     if (document.exitFullscreen) {
    //         document.exitFullscreen();
    //     } else if (document.webkitExitFullscreen) {
    //         document.webkitExitFullscreen();
    //     } else if (document.mozCancelFullScreen) {
    //         document.mozCancelFullScreen();
    //     } else if (document.msExitFullscreen) {
    //         document.msExitFullscreen();
    //     }
    // }

    const getCurrentLocation = () => {
        // Try HTML5 geolocation.
        // let infoWindow = new window.google.maps.InfoWindow();
        let infoWindow = props?.infoWindow;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    infoWindow.setPosition(pos);
                    infoWindow.setContent("Location found.");
                    infoWindow.open(map);
                    map.setCenter(pos);
                },
                () => {
                    handleLocationError(true, infoWindow, map.getCenter());
                }
            );
        } else {
            // Browser doesn't support Geolocation
            handleLocationError(false, infoWindow, map.getCenter());
        }
    }

    const handleLocationError = (browserHasGeolocation, infoWindow, pos) => {
        infoWindow.setPosition(pos);
        infoWindow.setContent(
            browserHasGeolocation
                ? "Error: Provide permission to location"
                : "Error: Your browser doesn't support geolocation."
        );
        infoWindow.open(map);
    }

    const changeMapTypes = (value) => {
        map.setMapTypeId(value);
    }

    return (
        <div className='map-wrapper'>
            <div ref={ref} id={map_id ?? "map"} style={{ flexGrow: "1", height: "100%" }}>
                {React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                        // set the map prop on the child component
                        return React.cloneElement(child, { map });
                    }
                })}
            </div>
            {/* Hide controls until they are moved into the map */}
            <div style={{ display: 'none' }}>
                <CustomControls
                    setRadius={setRadius}
                    zoomIn={zoomIn}
                    radius={radius}
                    hideControl={hideControl}
                    zoomOut={zoomOut}
                    fullScreenControl={fullScreenControl}
                    getCurrentLocation={getCurrentLocation}
                    changeMapTypes={changeMapTypes}
                    is_zoom={is_zoom}
                />
            </div>
        </div>
    )
}