import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../../utils";
export const ComponentStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: "white",
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: theme.palette.borderRadius,
        padding: "16px",
        cursor: "pointer"
    },
    title: {
        fontSize: "12px",
        fontFamily: Bold,
        color: "#4E5A6B",
        marginBottom: "12px"
    },
    name: {
        fontSize: "12px",
        fontFamily: Bold,

    },
    contactCard: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        marginTop: "12px",
        "&:hover": {
            backgroundColor: '#071741',
            color: "white"
        }
    },
    contactCardactive: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        marginTop: "12px",
        backgroundColor: '#071741',
        color: "white"
    }
}));