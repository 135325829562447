import { Badge, Box, Button, Divider, Grid, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { SearchFilter } from "../../../components";
import { Routes } from '../../../router/routes';
import { ReceiptStyles } from "../style";
const SearchAndFilter = ({
    searchText = "",
    handleSearch = () => false,
    selected = {},
    openfilter = () => false,
    filterData = {},
    permission = {},
    t
}) => {
    const classes = ReceiptStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory()
    const open = Boolean(anchorEl);
    //onClick
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    //handle close
    const handleClose = () => {
        setAnchorEl(null);
    };
    //go create
    const goCreate = (type) => {
        history.push({
            pathname: Routes.createReceipt,
            state: {
                type: type,
                company: selected
            }
        })
        handleClose()
    }

    return (
        <Box>
            <Grid container alignItems="center">
                {/* search and filter */}
                <Grid item xs={4}>
                    <SearchFilter value={searchText} handleChange={(e) => handleSearch(e)} placeholder={t("searchReceipt")} />
                </Grid>
                <Grid item xs={8}>
                    {/* search and filter */}
                    <Stack
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                        justifyContent="flex-end"
                        alignItems="center"
                        direction="row">
                        {
                            filterData?.account?.length > 0 || filterData?.payment_method?.length > 0 || (filterData?.date?.startDate?.length > 0 && filterData?.date?.ebdDate?.length > 0) ?
                                <Badge variant="dot" color="primary">
                                    <IconButton className={classes.iconButton} onClick={openfilter}>
                                        <img src="/images/filter.svg" alt="filter" />
                                    </IconButton>
                                </Badge>
                                :
                                <IconButton className={classes.iconButton} onClick={openfilter}>
                                    <img src="/images/filter.svg" alt="filter" />
                                </IconButton>
                        }


                        {permission?.create &&
                            <Button onClick={handleClick} variant="contained">
                                {t("createNewReceipt")}
                            </Button>}
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            className={classes.menuList}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => goCreate("account")}>{t("payAgainstAccount")}</MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => goCreate("invoice")}>{t("payAgainstInvoice")}</MenuItem>
                        </Menu>
                    </Stack>
                </Grid>
            </Grid>

        </Box>
    )
}

export default withNamespaces("receipt")(SearchAndFilter); 