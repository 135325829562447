import { Grid, Typography } from "@mui/material";
import React from 'react';
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { contactViewStyles } from "../style";



export const BankDetails = (props) => {
      const classes = contactViewStyles()
      const size = useWindowDimensions()
      return (
            <div style={{ height: size?.height - 210, overflow: "auto", padding: "4px", margin: "-4px" }}>
                  <div className={classes.card}>
                        <Typography className={classes.title} >Banking Basic Details</Typography>
                        <Grid container rowSpacing={2} marginTop={1}>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Bank Name</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.bank_name && props?.data?.bank_name !== "") ? props?.data?.bank_name : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Branch Name</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.branch_name && props?.data?.branch_name !== "") ? props?.data?.branch_name : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Currency</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.bank_currency_id && props?.data?.bank_currency_id !== "") ?
                                                (props?.data?.bank_currency_symbol + "-" + props?.data?.bank_currency_code + "-" + props?.data?.bank_currency_name) : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Bank Account Type</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.account_type && props?.data?.account_type !== "") ? props?.data?.account_type : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Account Number</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.account_no && props?.data?.account_no !== "") ? props?.data?.account_no : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Bank Routing Type</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.routing_type && props?.data?.routing_type !== "") ? props?.data?.routing_type : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Bank Routing Code</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.routing_code && props?.data?.routing_code !== "") ? props?.data?.routing_code : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Preferred Cash Collection Office</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.cash_collection_office && props?.data?.cash_collection_office !== "") ? props?.data?.cash_collection_office : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Cheque Name</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.cheque_name && props?.data?.cheque_name !== "") ? props?.data?.cheque_name : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Address Line 1</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.address_1 && props?.data?.address_1 !== "") ? props?.data?.address_1 : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Address Line 2</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.address_2 && props?.data?.address_2 !== "") ? props?.data?.address_2 : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>City</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.bank_city && props?.data?.bank_city !== "") ? props?.data?.bank_city : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Country</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.bank_country && props?.data?.bank_country !== "") ? props?.data?.bank_country : "-"}
                                    </Typography>
                              </Grid>
                        </Grid>
                  </div>
            </div>
      )
}