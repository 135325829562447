import CircleIcon from '@mui/icons-material/Circle';
import { Box, Grid, Typography } from '@mui/material';
import React from "react";
import { Cell, Pie, PieChart } from "recharts";
import { AggreementChartStyles } from "./styles";

export const AggreementChart = ({ rentalbreakUp = [], label = "" }) => {
    const classes = AggreementChartStyles()


    const COLORS = rentalbreakUp?.map(i => i?.color);


    return (
        <Box className={classes.cardRoot} >
            <Typography className={classes.title}>{label}</Typography>
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <PieChart width={200} height={120}>
                        <Pie
                            data={rentalbreakUp}
                            cx={120}
                            cy={60}
                            labelLine={false}

                            outerRadius={50}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {rentalbreakUp.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{ marginLeft: "4px" }}>
                        {
                            rentalbreakUp?.map((val) => {
                                return (
                                    <>
                                        <Grid item xs={8}>
                                            <Typography noWrap className={classes.name}>
                                                <CircleIcon style={{ color: val?.color, fontSize: "10px" }} />&nbsp;{val?.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography noWrap className={classes.value}>
                                                {val?.label}
                                            </Typography>
                                        </Grid>
                                    </>
                                )
                            })
                        }
                    </Grid>

                </Grid>
            </Grid>


        </Box>
    )
}




