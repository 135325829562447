import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Badge, Box, Dialog, Divider, Drawer, Grid, Hidden, IconButton, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { LoadingSection, SearchFilter, Subheader, TableWithPagination } from '../../../components';
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts";
import { GET_ENTRIES_FOR_DOMESTICT_HELPER_VENDOR_SERVICE_PROVIDER_BY_VISITOR_TYPE } from '../../../graphql/securityManager/queries';
import { AlertProps, constructPropertyList, ExtraBold, getCompanyOption, LocalStorageKeys, SemiBold, timeZoneConverter } from '../../../utils';
import { EntriesHeading, EntriesPath, EntriesType } from '../../../utils/securityManager/vendorEntryDataPoints';
import { Routes } from "../../../router/routes";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import lightFormat from "date-fns/lightFormat";
import { VisitorSecurityFilter } from "../../../components/visitorSecurityFilter";
import FilterIMG from "../../../assets/filter";
const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    innerWindow: {
        borderRadius: theme.palette.borderRadius,
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        // padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
        margin: "14px"
    },
    searchFilter: {
        padding: "16px 0px 0px 0px",
        width: "50%"
    },
    table: {
        padding: "0px 16px 0px 16px"
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
        },
    },
    drawerHeaderStyle: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
    },
    drawerHeaderTextStyle: {
        fontSize: "16px",
        fontWeight: "bolder",
        color: theme.typography.color.primary,
    },
}))


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (children)}
        </div>
    );
}

export const VendorEntry = props => {

    const classes = useStyles();
    const history = useHistory();
    const [initialLoad, setInitialLoad] = React.useState(false)

    const [value, setValue] = React.useState(0);
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext)

    const [searchText, setSearchText] = React.useState("");


    const client = useApolloClient()

    const [unitList, setUnitList] = React.useState([]);

    const [isUnitListDialogOpen, setIsUnitListDialogOpen] = React.useState(false);
    const backdrop = React.useContext(BackdropContext);
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [selectedProperty, setSelectedProperty] = React.useState({})
    const [propertyData, setPropertyData] = React.useState([])
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [filterData, setFilterData] = React.useState({})
    const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
    const [entriesData, setEntriesData] = React.useState({
        heading: EntriesHeading,
        row: [],
        path: EntriesPath,
        type: EntriesType,
    });

    const [tab, setTab] = React.useState({
        offset: 0,
        page: 1,
        limit: 10
    })

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handlePagination = (value) => {
        const offset = (value - 1) * tab.limit;
        setTab({ ...tab, page: value, offset: offset })
    }

    const handleChangeLimit = (value) => {
        setTab({ ...tab, limit: value, page: 1, offset: 0 })
    }

    // useEffect to get company list for company switcher when loading the screen
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getPropertyList(company?.selected)
        }
        // eslint-disable-next-line
    }, [auth])

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getPropertyList(value)
    }

    // Function to change property list
    const getPropertyList = (company) => {
        let property = constructPropertyList(company)
        setPropertyData(property?.list);
        setSelectedProperty(property?.list[0])
    }

    //handlePropertyChange
    const onPropertyChange = (val) => {
        setSelectedProperty(val)
    }

    React.useEffect(() => {
        setInitialLoad(true)
        if (propertyData !== null && propertyData?.length > 0 && selectedProperty?.value) {
            let created_start = filterData?.createdDate?.startDate ? lightFormat(filterData?.createdDate?.startDate, 'yyyy-MM-dd') : "";
            let created_end = filterData?.createdDate?.endDate ? lightFormat(filterData?.createdDate?.endDate, 'yyyy-MM-dd') : "";
            let check_in_start = filterData?.checkInDate?.startDate ? lightFormat(filterData?.checkInDate?.startDate, 'yyyy-MM-dd') : "";
            let check_in_end = filterData?.checkInDate?.endDate ? lightFormat(filterData?.checkInDate?.endDate, 'yyyy-MM-dd') : "";
            let check_out_start = filterData?.checkOutDate?.startDate ? lightFormat(filterData?.checkOutDate?.startDate, 'yyyy-MM-dd') : "";
            let check_out_end = filterData?.checkOutDate?.endDate ? lightFormat(filterData?.checkOutDate?.endDate, 'yyyy-MM-dd') : "";
            let accessGateID = filterData?.gate?.map((i) => i?.value);
            client.query({
                query: GET_ENTRIES_FOR_DOMESTICT_HELPER_VENDOR_SERVICE_PROVIDER_BY_VISITOR_TYPE(
                    created_start, created_end, check_in_start, check_in_end, check_out_start, check_out_end, accessGateID),
                variables: {
                    visitorType: "VE", clientID: localStorage.getItem(LocalStorageKeys.clinetID),
                    propertyID: selectedProperty?.label === "All Properties" ? selectedProperty?.value : [selectedProperty?.value],
                    created_start, created_end, check_in_start, check_in_end, check_out_start, check_out_end, accessGateID,
                    search: searchText, offset: tab.offset, limit: tab.limit,
                },
                fetchPolicy: "network-only",
            }).then((response) => {
                let tempEntries = response?.data?.swift_in_out_log?.map((item, index) => {
                    let _item = {
                        id: item?.id ? item?.id : "",
                        vendor_id: item?.vendor_master?.id ? item?.vendor_master?.id : "-",
                        vendor_type: item?.vendor_master?.name ? item?.vendor_master?.name : "-",
                        image: item?.vendor_master?.image_url ? item?.vendor_master?.image_url : "-",
                        vendor_person_name: item?.person_name ? item?.person_name : "-",
                        reference_id: item?.reference_id ? item?.reference_id : "-",
                        requested_by: item?.created_by?.first_name ? item?.created_by?.first_name : "-",
                        unit: item?.swift_in_out_log_units?.length ? item?.swift_in_out_log_units?.[0]?.unit?.unit_no : "",
                        unitList: item?.swift_in_out_log_units ? item?.swift_in_out_log_units : [],
                        checked_in_gate: item?.access_gates_master?.name ?? '-',
                        checked_in_by: item?.check_in_byByID?.first_name ?? '-',
                        checked_in: ((item?.check_in_time && item?.check_in_time !== null) ? timeZoneConverter(item?.check_in_time, "DD MMM YY , hh:mm") : "-"),
                        checked_out_gate: item?.access_gates_master?.name ? item?.access_gates_master?.name : "-",
                        checked_out_by: item?.check_out_byByID?.first_name ? item?.check_out_byByID?.first_name : "-",
                        checked_out: ((item?.check_out_time && item?.check_out_time !== null) ? timeZoneConverter(item?.check_out_time, "DD MMM YY , hh:mm") : "-"),
                        status: (item?.check_in_time && item?.check_out_time === null) ? "Yet to Check-Out" : item?.check_out_time !== null ? "Checked-Out" : item?.check_in_time === null && "Yet to Check-In"
                    };
                    return _item;
                });
                setEntriesData({ ...entriesData, row: tempEntries, totalRowsCount: response?.data?.totalCount?.[0]?.count_id });
                setInitialLoad(false)
            }).catch((error) => {
                setInitialLoad(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            });
        } else if (selectedCompany?.property?.length === 0) {
            setEntriesData({ ...entriesData, row: [], totalRowsCount: 0 });

        }

        // eslint-disable-next-line
    }, [selectedCompany, searchText, tab, selectedProperty]);

    const onUnitClick = (data) => {

        setUnitList(data?.unitList);
        setIsUnitListDialogOpen(true);
    }


    // Filter Component Functions
    const apply = (value) => {
        setFilterData(value)
    }
    const filterKeys = ["reqDate", "checkOutDate", "checkInDate"]


    const unitDetails = () => {
        return (<Grid container>
            {unitList?.map((item, index, array) => {
                return <>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" p={2}>
                            <Box>
                                <Avatar>
                                    <Typography style={{ fontSize: "20px", fontFamily: ExtraBold }}>
                                        {item?.unit?.name?.[0] ? item?.unit?.name?.[0] : ""}
                                    </Typography>
                                </Avatar>
                            </Box>
                            <Box marginLeft="10px">
                                <Typography style={{ fontSize: "16px", fontFamily: SemiBold }}>
                                    {(item?.unit?.name ? (item?.unit?.name + (item?.unit?.unit_no ? ", " : "")) : "") + (item?.unit?.unit_no ? item?.unit?.unit_no : "-")}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </>
            })}
        </Grid>);
    }

    const closeUnitListDialog = () => {
        setIsUnitListDialogOpen(false);
    }

    return <div className={classes.root}>
        <Subheader hideBackButton={true}
            title="Vendor Entries"
            select options={companyList}
            value={selectedCompany}
            goBack={() => history.push(Routes.home)}
            onchange={(e) => handleCompanyChange(e)}
            onPropertyChange={(e) => {
                onPropertyChange(e)
            }}
            propertyValue={selectedProperty}
            selectProperty
            propertyOptions={propertyData} />
        <Grid direction={"column"} className={classes.innerWindow}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Entries" />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {entriesData.heading.length > 0 && (
                        <>
                            <Grid container className={classes.table}>
                                <Grid item xs={8}>
                                    <div className={classes.searchFilter}>
                                        <SearchFilter placeholder="Search By Reference ID" value={searchText} handleChange={(value) => setSearchText(value)} />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={"flex"} justifyContent={"end"} p={2}>
                                        <IconButton onClick={() => setFilterDrawer(!filterDrawer)} className={classes.filterBox}>
                                            <Badge variant="dot" color="primary"
                                                invisible={!(Object.keys(filterData).length > 0)}>
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton>
                                    </Box>
                                </Grid>

                            </Grid>
                            {initialLoad ? <LoadingSection message={"feteching data..."} /> :

                                <div className={classes.table}>
                                    <TableWithPagination
                                        heading={entriesData.heading}
                                        rows={entriesData.row}
                                        path={entriesData.path}
                                        showpagination={entriesData.row.length > 0 ? true : false}
                                        count="2"
                                        handleIcon={() => false}
                                        onClick={() => false}
                                        onUnitClick={onUnitClick}
                                        showpdfbtn={false}
                                        showexcelbtn={false}
                                        showSearch={false}
                                        dataType={entriesData.type}
                                        handlePagination={handlePagination}
                                        handleChangeLimit={handleChangeLimit}
                                        totalRowsCount={entriesData?.totalRowsCount}
                                        page={tab.page}
                                        limit={tab.limit}
                                        height={'calc(100vh - 330px)'}
                                        tableType="no-side"
                                        view={true}
                                        edit={true}
                                        delete={true} />
                                </div>
                            }
                        </>
                    )}
                </TabPanel>
            </Box>
        </Grid>

        <Hidden smUp>
            <Drawer
                className={classes.drawer}
                anchor="bottom"
                open={isUnitListDialogOpen}
                onClose={closeUnitListDialog}>
                <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}>
                    <Grid>
                        <Typography className={classes.drawerHeaderTextStyle}>
                            Unit Details
                        </Typography>
                    </Grid>
                    <Grid>
                        <IconButton
                            style={{
                                padding: "0px",
                            }}
                            onClick={closeUnitListDialog}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                {unitDetails()}
            </Drawer>
        </Hidden>
        <Hidden smDown>
            <Dialog
                className={classes.dialog}
                fullWidth
                open={isUnitListDialogOpen}
                onClose={closeUnitListDialog}>
                <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}>
                    <Grid>
                        <Typography className={classes.drawerHeaderTextStyle}>
                            Unit Details
                        </Typography>
                    </Grid>
                    <Grid>
                        <IconButton
                            style={{
                                padding: "0px",
                            }}
                            onClick={closeUnitListDialog}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                {unitDetails()}
            </Dialog>
        </Hidden>
        <VisitorSecurityFilter
            open={filterDrawer}
            close={() => setFilterDrawer(!filterDrawer)}
            apply={apply}
            filterData={filterData}
            filterKeys={filterKeys}
            clientId={clientId}
            property={selectedProperty}
        />
    </div>
}