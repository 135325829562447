import React from "react"

export const LeadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="29.526" height="29" viewBox="0 0 29.526 29">
        <g id="Leads" transform="translate(0.318 -0.001)">
            <rect id="Rectangle_33" data-name="Rectangle 33" width="29" height="29" transform="translate(-0.318 0.001)" fill="none" />
            <path id="icons8-rental-house-contract_2_" data-name="icons8-rental-house-contract (2)" d="M10.731,4A2.734,2.734,0,0,0,8,6.731V25.543a2.734,2.734,0,0,0,2.731,2.731H24.688a2.734,2.734,0,0,0,2.731-2.731V13.71H20.44a2.734,2.734,0,0,1-2.731-2.731V4Zm8.8.533v6.445a.911.911,0,0,0,.91.91h6.445ZM17.711,15.548a2.621,2.621,0,0,1,1.619.544l2.845,2.24a2.606,2.606,0,0,1,1,2.056v3.392a2.066,2.066,0,0,1-2.048,2.066H14.3a2.066,2.066,0,0,1-2.048-2.066V20.389a2.6,2.6,0,0,1,1-2.056l2.845-2.24A2.624,2.624,0,0,1,17.711,15.548Zm-.911,4.23a.911.911,0,0,0-.91.91v1.821a.911.911,0,0,0,.91.91H18.62a.911.911,0,0,0,.91-.91V20.688a.911.911,0,0,0-.91-.91Z" transform="translate(1.789 -1.701)" fill="#2cab7a" />
        </g>
    </svg>

)