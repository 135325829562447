export const initialData = {
    image_url: "",
    logo_name: "",
    company_name: "",
    module: "",
    status: "",
    error: {
        image_url: "",
        logo_name: "",
        company_name: "",
        module: "",
        status: "",
    }
}

export const initialBasicSettings = {
    default_base_currency: "",
    default_uom: "",
    quotation_validity: { value: null, label: "None" },
    billing_payment_peroid: "",
    billing_payment_terms: "",
    billing_cycle_method: "",
    billing_generate_date: "",
    default_payment_gateway: { value: null, label: "None" },
    loginName: "",
    loginPassword: "",
    loginToken: "",
    paymentProviderName: "",
    provider_url: "",
    reservation_feature: { value: null, label: "None" },
    auto_deposite_cal: "",
    auto_reservation_cal: "",
    grace_peroid: { value: null, label: "None" },
    bill_custom_date: "",
    pricing_override: "",
    short_term_retals: "",
    storage_offering: "",
    index_calculation: "",
    refer_win: "",
    professoinal_banners: "",
    logo: "",
    billing_payment_terms_value: "",
    logo_status: "",
    lease_period: "",
    proof: [],
    default_time_zone:"",
    error: {
        default_base_currency: "",
        default_uom: "",
        quotation_validity: "",
        billing_payment_peroid: "",
        billing_payment_terms: "",
        billing_cycle_method: "",
        billing_generate_date: "",
        default_payment_gateway: "",
        reservation_feature: "",
        auto_deposite_cal: "",
        auto_reservation_cal: "",
        grace_peroid: "",
        pricing_override: "",
        short_term_retals: "",
        storage_offering: "",
        index_calculation: "",
        refer_win: "",
        professoinal_banners: "",
        logo: "",
        logo_status: "",
        loginName: "",
        loginPassword: "",
        loginToken: "",
        paymentProviderName: "",
        provider_url: "",
        lease_period: "",
        default_time_zone:''
    }
}

export const status = [
    {
        label: "Active",
        value: true
    },
    {
        label: "InActive",
        value: false
    },
]


export const moduleOption = [
    {
        label: "Resident",
        value: "resident"
    },
    {
        label: "Manager",
        value: "manager"
    }
]

export const quotation_validity = [
    {
        label: "None",
        value: null
    },
]
export const Billing_cycle_method = [
    {
        label: "Prepaid",
        value: "Start of the Payment Period"
    },
    {
        label: "Post Paid",
        value: "End of the Payment Period"
    },
]
export const Billing_cycle_date= [
    {
        label: "As Agreement",
        value: "As per the Agreement Date"
    },
]
export const reservation_feature = [

    {
        label: "None",
        value: null
    },
]

export const validateCustom = (array, value) => {
    let result = array.includes(value);
    return !result
}

export const validatePaymentperoid = (options, value, type) => {
    switch (type) {
        case "Monthly":
            if (parseInt(value) < 12) {
                return options?.filter((val) => val?.value !== "Yearly");
            } else if (parseInt(value) < 6) {
                return options?.filter((val) => val?.value !== "Half Yearly");
            } else if (parseInt(value) < 3) {
                return options?.filter((val) => val?.value !== "Quarterly");
            }
            else {
                return options
            }
        default: return options
    }
}

