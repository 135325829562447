import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, SemiBold } from "../../utils";
export const ReceiptStyles = makeStyles((theme) => ({
    root: {
        padding: "12px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px"
    },
    detailRoot: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px"
    },
    detailRoots: {
        height: `calc(100vh - 220px)`,
        overflow: "auto"
    },
    invoiceRoot: {
        borderTop: `1px solid ${theme.palette.border.third}`,
        borderBottom: `1px solid ${theme.palette.border.third}`,
        padding: "4px 12px 8px 12px"
    },
    title: {
        fontFamily: Bold,
        fontSize: "16px",
        color: theme?.typography?.color?.primary
    },
    totalRoot: {
        border: `1px solid ${theme.palette.border.third}`,
        padding: "8px",
        margin: "12px",
        borderRadius: "4px"
    },
    totalAmount: {
        fontFamily: SemiBold,
        fontSize: "12px",
        color: theme?.typography?.color?.secondary,
        textAlign: "right"
    },
    totalAmountValue: {
        fontFamily: ExtraBold,
        fontSize: "22px",
        color: theme?.typography?.color?.primary,
    },
    available: {
        fontFamily: SemiBold,
        fontSize: "12px",
        color: theme?.palette?.success?.main,
    },
    downBtn: {
        border: `1px solid ${theme?.typography?.color?.primary}`,
        color: theme?.typography?.color?.primary,
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "white",
            border: `1px solid ${theme?.typography?.color?.primary}`,
        }
    }
}));