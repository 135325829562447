import makeStyles from "@mui/styles/makeStyles";
import { ExtraBold } from "../../utils";

export const useStyles = makeStyles((size) => ({
    titleRoot: {
        borderBottom: "1px solid #c1c1c1",

    },
    title: {
        fontSize: "18px",
        fontFamily: ExtraBold,
    }
}));