import { Box, Typography } from "@mui/material";
import React from "react";
import { CompanyToggle, CustomCheckBox, SelectBox, TextBox } from "../index";
import { useStyles } from "./styles";

export const CompanySettingsList = ({
    title = "",
    options = [],
    checked = true,
    onChange = () => onChange,
    value = "",
    bgColor = "white",
    border = true,
    custom = false,
    toggle = false,
    type = "",
    loading = {},
    loadOptionData = () => false,
    customValue = 30,
    onChangeText = () => false,
    textValue = "",
    render = () => false,
    isError = false,
    errorMessage = "",
    openCustom = false
}) => {
    const classes = useStyles()

    const onChangeValue = (key, val) => {
        let updateValue = {
            ...value,
            [key]: val
        }
        onChange && onChange(updateValue)
    }
    return (
        <Box >
            <Box display="flex" className={classes.root}
                alignItems={type === "select" ? "center" : "auto"}
                sx={{
                    backgroundColor: bgColor,
                    border: border ? "1px solid #E4E8EE" : "none"
                }}>
                <Box flexGrow={1} >
                    <Typography className={classes.title}>{title}</Typography>
                    {
                        options?.length > 0 &&
                        <Box marginTop="10px" display="flex" alignItems={type === "textbox" ? "center" : "auto"} >
                            <CompanyToggle
                                options={options}
                                value={value?.value}
                                onChange={(value) => onChangeValue('value', value)}
                                isMulti={true}
                                fullWidth={false}
                                custom={custom}
                                height="46px"
                                customValue={customValue}
                                openCustom={openCustom}
                                onCustomChange={(value) => onChangeValue('value', value)}
                            />
                            {
                                type === "textbox" &&
                                <Box width="100px" marginLeft="12px" marginTop="-4px">
                                    <TextBox
                                        label={null}
                                        onChange={(e) => onChangeText('value', e.target.value)}
                                        value={textValue}
                                        placeholder=""
                                    />
                                </Box>
                            }
                        </Box>
                    }
                    {render()}

                </Box>
                <Box>
                    {
                        toggle &&
                        <CustomCheckBox
                            checked={value?.is_active}
                            onChange={(e) => onChangeValue('is_active', e.target.checked)}
                        />
                    }

                    {
                        type === "select" &&
                        <Box width="180px">
                            <SelectBox
                                onChange={(value) => onChangeValue('value', value)}
                                value={value?.value}
                                color="white"
                                loading={loading === "currency"}
                                loadOptions={(search, array) => loadOptionData(search, array, "currency")}
                                isPaginate
                                debounceTimeout={800}

                            />
                        </Box>
                    }
  {
                        type === "currency_select" &&
                        <Box width="180px">
                            <SelectBox
                                onChange={(value) => onChangeValue('value', value)}
                                value={value?.value}
                                color="white"
                                loading={loading}
                                loadOptions={loadOptionData}
                                isPaginate
                                debounceTimeout={800}

                            />
                        </Box>
                    }


                </Box>

            </Box>
            {isError && (
                <Typography variant={"caption"} color={"error"}>
                    {errorMessage}
                </Typography>
            )}
        </Box >

    )
}