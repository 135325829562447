import { Box, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import CreditNotesSvg from '../../../assets/creditNotes'
import DebitNotesSvg from '../../../assets/debitNotes';
import InvoiceNotesSvg from '../../../assets/invoiceNotes';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CustomerSvg from '../utlis/customer';
import VendorSvg from '../utlis/vendor'
import { CreateCardStyle } from './style'

export const CreateCard = ({ data = {}, click = () => false, enableStep1 = () => false }) => {
    const icons = {
        "Credit": <CreditNotesSvg />,
        "Debit": <DebitNotesSvg />,
        "Invoice": <InvoiceNotesSvg />,
        "Customer": <CustomerSvg />,
        "Vendor": <VendorSvg />
    }

    const classes = CreateCardStyle()
    return (
        <Box onClick={click} sx={{cursor:"pointer"}}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={2}>
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <Box>
                        {
                            icons[data?.name]
                        }
                    </Box>
                    <Stack>
                        <Typography className={classes.title}>{data?.name}</Typography>
                        {data?.content && <Typography className={classes.content}>{data?.content}</Typography>}
                    </Stack>
                </Stack>

                <IconButton>
                    <KeyboardArrowRightIcon />
                </IconButton>
            </Stack>
        </Box>
    )
}