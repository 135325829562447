import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { AlertContext, BackdropContext } from "../../contexts";
import {
  AlertProps,
  singleImageUpload,
  assestType,
  SemiBold,
} from "../../utils";
const useStyles = makeStyles((theme) => ({
  fileroot: {
    width: "100%",
    height: (props) => props?.height ? props?.height : "306px",
    fontSize: "10px",
    color: "#5078E1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "2px dashed #E4E8EE",
    borderRadius: "15px",
    padding: "9px",
  },
  fileroot1: {
    width: "100%",
    height: (props) => props?.height ? props?.height : "306px",
    fontSize: "10px",
    color: "#5078E1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "15px",
    padding: "9px",
    backgroundColor: "#cee8ff",
  },
  imgContainer: {
    width: "100%",
    maxWidth: "150px",
    height: (props) => props?.height ? props?.height : "306px",
    margin: "0px 10px",
    borderRadius: "8px",
    display: "flex",
  },
  uploadTittle: {
    fontSize: "18px",
    color: "#5078E1",
    fontFamily: SemiBold,
  },
  uploadTittleselect: {
    fontSize: "18px",
    color: "#98A0AC",
    fontFamily: SemiBold,
  },
  uploadTittle1: {
    fontSize: "58px",
    color: "#5078E1",
    fontFamily: SemiBold,
  },
  uploadTittle1select: {
    fontSize: "58px",
    color: "#98A0AC",
    fontFamily: SemiBold,
  },
  uploadcount: {
    fontSize: "16px",
    color: "#404E61",
    fontFamily: SemiBold,
    paddingLeft: "16px",
  },
  edit: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    zIndex: 1,
    color: "#fff",
    padding: "10px",
    cursor: "pointer",
  },
  delete: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "red",
    zIndex: 1,
    color: "#fff",
    padding: "10px",
    cursor: "pointer",
  },
  options: {
    position: "absolute",
    left: "0",
    rigth: "0",
    top: "0",
    bottom: "0",
    cursor: "pointer",
    opacity: "12",
    backgroundColor: "#0000009c",
    height: (props) => props?.height ? props?.height : "306px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
  },
  optionsHidden: {
    display: "none",
  },
  imageParent: {
    position: "relative", display: "flex"
  },
  image: {
    width: "100%",
    height: (props) => props?.height ? props?.height : "306px",
    objectFit: "cover"
  },
  marginTop: {
    marginTop: "2px"
  }
}));
export const ImageUpload = (props) => {
  const classes = useStyles({ height: props?.height });
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const [hover, setHover] = React.useState(false);
  const [indexValue, setIndex] = React.useState(null);
  const types = ["image/png", "image/jpeg"];


  const updateState = async (data, key, index, edit) => {
    if (types.includes(data.type)) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "...UpLoading",
      });
      try {
        const tumbil = await singleImageUpload(
          data,
          props?.companyID?.value ?? 1,
          assestType?.Thumbnails
        );
        if (edit) {
          props?.editImage(tumbil, "images", index);
        }
        else {
          addUpdateState(tumbil, "images", index);
        }

        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      } catch (err) {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Some Thing Went Wrong",
        });
      }
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Allowed png or jpeg only",
      });
    }
  };

  const addUpdateState = (value, key, index) => {
    let data1 = props.data1;
    const values = [...data1];
    values[index][key] = value;
    props?.updateImageURL(values);
  };

  const hoverIn = (index) => {
    setIndex(index);
    setHover(true);
  };

  const hoverOut = () => {
    setHover(true);
    setIndex(false);
  };

  return (
    <div>
      <Grid container spacing={2} className={classes.marginTop}>
        <>
          {props?.selectedImageURL?.images?.length > 0 ? (
            <>
              <Grid item xs={12}>
                <Box className={classes.imageParent}>
                  <img
                    src={props?.selectedImageURL?.images}
                    className={classes.image}
                    alt="images"
                    onMouseOver={() => hoverIn(props?.index)}
                    onMouseLeave={() => hoverOut(props?.index)}
                  />

                  <Box
                    className={
                      hover && indexValue === props?.index
                        ? classes.options
                        : classes.optionsHidden
                    }
                    onMouseOver={() => hoverIn(props?.index)}
                    onMouseLeave={() => hoverOut(props?.index)}
                  >
                    <Stack
                      direction="row"
                      divider={
                        <Divider
                          orientation="vertical"
                          flexItem
                          style={{ color: "white" }}
                        />
                      }
                      spacing={2}
                    >
                      <label className="btn label">
                        <Box className={classes.edit}>
                          <ModeEditOutlineOutlinedIcon />
                          <input
                            accept={props?.accept}
                            type="file"
                            name="myImage"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              updateState(
                                e.target.files[0],
                                "images",
                                props?.index,
                                true
                              );
                            }}
                          />
                        </Box>
                      </label>
                      <Box
                        className={classes.delete}
                        onClick={() =>
                          props?.handleRemoveFields(
                            props?.data,
                            "images",
                            props?.index
                          )
                        }
                      >
                        <DeleteOutlineOutlinedIcon />
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <label className="btn label">
                  <div
                    className={
                      hover && indexValue === props?.index
                        ? classes.fileroot1
                        : classes.fileroot
                    }
                    onMouseOver={() => hoverIn(props?.index)}
                    onMouseLeave={() => hoverOut(props?.index)}
                  >
                    <input
                      accept={props?.accept}
                      type="file"
                      name="myImage"
                      // value={selectedImageURL}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        updateState(e.target.files[0], "images", props?.index, false);
                      }}
                    />
                    <center>
                      <AddPhotoAlternateIcon
                        className={
                          hover
                            ? classes.uploadTittle1
                            : classes.uploadTittle1select
                        }
                      />
                      <Typography
                        className={
                          hover
                            ? classes.uploadTittle
                            : classes.uploadTittleselect
                        }
                      >
                        Upload Image
                      </Typography>
                    </center>
                  </div>
                </label>
              </Grid>
            </>
          )}
        </>
      </Grid>
    </div>
  );
};
