import { bussinessActions, bussinesssEditActions, bussinessUpdateActions, propertyActions, propertyEditActions, propertyUpdateActions, serviceActions, serviceAddActions, serviceEditActions } from "../../../utils";

export const getTableActions = (type, name, offset, limit, search, company, status) => {
    switch (type) {
        case 'Property Master':
            return propertyActions(name, offset, limit, search, company, status);
        case 'Business Master':
            return bussinessActions(name, offset, limit, search, company, status);
        case 'Service Master':
            return serviceActions(name, offset, limit, search);
        default:
            return null
    }
}

export const addActions = (type, name) => {
    switch (type) {
        case 'Property Master':
            return propertyUpdateActions[name];
        case 'Business Master':
            return bussinessUpdateActions[name];
        case 'Service Master':
            return serviceAddActions[name];
        default:
            return null
    }
}

export const editActions = (type, name) => {
    switch (type) {
        case 'Property Master':
            return propertyEditActions[name];
        case 'Business Master':
            return bussinesssEditActions[name];
        case 'Service Master':
            return serviceEditActions[name];
        default:
            return null
    }
}