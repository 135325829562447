import { makeStyles } from "@mui/styles";
import { SemiBold } from "../../utils";

export const ListTableStyle = makeStyles((theme) => ({
    tableCell: {
        border: `1px solid ${theme.palette.border.primary}`,
        padding: "0px 6px",
        height:40,
        width:232
    },
    title: {
        color: theme.typography.color.primary,
        fontWeight: "bold",
        fontSize: 10
    },
    content: {
        color: theme.typography.color.secondary,
        fontWeight: SemiBold,
        fontSize: 10
    },
    autogenerate:{
        fontSize:10,
        fontWeight:"italic",
        color: theme.typography.color.tertiary,
    },
    edit:{
        cursor:"pointer"
    }
}))