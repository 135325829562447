import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, SemiBold } from "../../../utils";
export const ScreensStylesParent = makeStyles((theme) => ({
      section: {
            padding: '20px',
            borderBottom: '1px solid #E0E0E0'
      },
      section1: {
            padding: '20px',
      },
      borderLeft: {
            borderLeft: '1px solid #E0E0E0',
            position: 'relative'
      },
      screenTitle: {
            color: '#101010',
            fontSize: "16px",
            fontFamily: SemiBold,
      },
      screenRepository: {
            color: '#4E5A6B',
            fontSize: "14px",
            fontFamily: SemiBold,
      },
      permissionlist: {
            backgroundColor: '#F0F0F0',
            borderRadius: '4px',
            color: '#091B29',
            display: 'flex',
            alignItems: 'center',
            height: '36px',
            justifyContent: 'center',
            padding: '0 !important',
      },
      access: {
            padding: '8px 0px'
      },
      dialog: {
            width: "100%",
            maxWidth: "auto",
            padding: "0 !important",
            "& .MuiPaper-root": {
                  width: "316px !important",
                  borderRadius: "4px",
            },
            "& .MuiDialogContent-root": {
                  padding: "0px !important",
                  position: 'relative'
            },
            '& .MuiDialog-container': {
                  padding: (props) => props?.padding ?? '',
            }
      },
      header: {
            border: "1px solid #E4E8EE",
            fontSize: "16px",
            fontFamily: ExtraBold,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
      },
      dialogParent: {
            padding: '24px',
            position: 'relative',
            height: '100%'
      },
      addRoleButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
      },
      cancelBtn: {
            borderRadius: '4px',
            border: '1px solid #E4E8EE',
            color: '#1C1C1C',
            fontSize: '14px',
            fontFamily: Bold,
            backgroundColor: '#fff',
            marginRight: '10px',
            '&:hover': {
                  backgroundColor: '#fff',
                  color: '#1C1C1C',
            }
      },
      saveBtn: {
            borderRadius: '4px',
            border: '1px solid #5078E1',
            color: '#fff',
            fontSize: '14px',
            fontFamily: Bold,
            backgroundColor: '#5078E1',
            '&:hover': {
                  backgroundColor: '#5078E1',
                  color: '#fff',
            }
      },
      checkBoxDesign: {
            minWidth: 60,
            maxWidth: 62,
            display: "flex",
            justifyContent: "center",
      },
      searchField: {
            marginTop: '16px',
            [`& fieldset`]: {
                  border: '1px solid #1010100D',
                  borderRadius: "4px",
                  backgroundColor: '#00000008',
            },
            '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                        border: '1px solid #5078E1',
                  },

            },
      },
      tabelsearchField: {
            [`& fieldset`]: {
                  border: '1px solid #1010100D',
                  borderRadius: "4px",
                  backgroundColor: '#00000008',
            },
            '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                        border: '1px solid #5078E1',
                  },

            },
      },
      img: {
            border: `1px solid #E0E0E0`,
            borderRadius: "8px",
            padding: "10px",
      },
      tags: {
            paddingTop: '8px !important'
      },
      paddingTop: {
            padding: '0px 20px 20px',
      },
      bottomButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            padding: '14px',
            position: 'absolute',
            bottom: '0',
            width: '100%',
            backgroundColor: '#fff',
            zIndex: 1
      },
      fixedStyle: {
            height: 'calc(100vh - 360px)',
            overflow: 'auto',
            paddingBottom: '70px'
      },
      fixedStylePermission: {
            height: 'calc(100vh - 330px)',
            overflow: 'auto',
      },
      fixedStyleRole: {
            height: 'calc(100vh - 295px)',
            overflow: 'auto',
            paddingBottom: '70px'
      },
      addnewdialog: {
            backgroundColor: 'transparent',
            width: '100% !important',
            "& .MuiPaper-root": {
                  width: '100% !important',
                  borderRadius: "0px",
                  backgroundColor: 'transparent',
                  boxShadow: 'none !important'
            },
            "& .MuiDialogContent-root": {
                  padding: "0px !important",
                  position: 'relative',

            },
      },
      newUserBox: {
            background: '#fff',
            maxWidth: '600px',
            width: '100%',
            borderRadius: '4px',
            margin: '0 auto',
            height: '100%',
      },
      newUserBoxAfter: {
            background: '#fff',
            maxWidth: '600px',
            width: '100%',
            borderRadius: '4px',
            margin: '0 auto',
            height: '100%',
            marginRight:"20px"
      },
      addUserBtn: {
            width: '100% !important',
            marginTop: '24px',
            borderRadius: '4px',
            border: '1px solid #5078E1',
            color: '#fff',
            fontSize: '14px',
            fontFamily: Bold,
            backgroundColor: '#5078E1',
            '&:hover': {
                  backgroundColor: '#5078E1',
                  color: '#fff',
            }
      },
      loaderSection: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
      },
      tableMain: {
            paddingTop: 0
      }
}))