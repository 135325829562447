import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { useStyles } from "./style";

export const AllocatedCard = (props) => {
  // styles
  const classes = useStyles();
  return (
    <div className={classes.allocatedroot}>
      <div
        style={{ padding: "8px 6px", display: "flex", alignItems: "center" }}
      >
        <div style={{ marginRight: "10px" }}>
          {props?.isImageType ? (
            <Avatar
              src={props?.viewDetails?.member?.image_url ?? ""}
              style={{ borderRadius: "4px" }}
            />
          ) : (
            props?.viewDetails?.member?.image_url
          )}
        </div>
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <Typography className={classes.profileTilte} noWrap>
              {props?.viewDetails?.member?.first_name ?? ""}{" "}
              {props?.viewDetails?.member?.last_name ?? ""}
            </Typography>
          </div>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box>
              {" "}
              <Typography className={classes.leadcontact} noWrap>
                {/* <Box className={classes.dot} /> */}
                {props?.viewDetails?.member?.mobile_no_country_code ?? ""}{" "}
                {props?.viewDetails?.member?.mobile_no ?? ""}
              </Typography>
            </Box>
            <Box>
              {" "}
              {props?.viewDetails?.member?.email_id &&
                <Typography className={classes.leadcontact}>
                  <Box className={classes.dot} />
                  <Typography className={classes.leadcontact} noWrap>
                    {" "}
                    {props?.viewDetails?.member?.email_id ?? ""}
                  </Typography>
                </Typography>
              }
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};
