import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { CalendarHeader } from './components/calendarHeader'
import { ParkingBookingTable } from './parkingBookingTable'
import styled from '@mui/material/styles/styled';
import { DailyCalendar, LoadingSection } from '../../components'
import { ParkingBookingCard, MonthlyCalendar, WeeklyCalendar } from './components';
import { parkingBookParentStyle } from './style';
const CustomPaper = styled('div')(({ theme }) => ({
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 202px)`,
    overflow: "hidden",
    margin: "2px 14px 0px 14px"
}))
export const Calendars = (
    {
        openPopup = () => false,
        openDeclinePopup = () => false,
        parkingGroupData = [],
        selectedParkingDropdown = () => false,
        selectedParking = () => false,
        parkingSlotData = {},
        loading = false,
        daily = {},
        nextdate = () => false,
        prevdate = () => false,
        weekprev = () => false,
        weeknext = () => false,
        monthnext = () => false,
        monthprev = () => false,
        month = "",
        weekdates = {},
        dates = [],
        monthdays = [],
        calendarData = [],
        setSearchValueFunc = () => false,
        searchValue = ''

    }
) => {

    // Daily, weekly, monthly calendar states and functions
    const classes = parkingBookParentStyle()
    const [open, setOpen] = React.useState(false)
    const [selected, setSelected] = React.useState("Weekly")
    const [select, setSelect] = React.useState("nonlist")

    const showAll = () => {
        open ? setOpen(false) : setOpen(true)
    }

    const setDropdownValue = (value) => {
        setSelected(value)
        setOpen(false)
    }

    // Layout switch ( list or calendar )
    const setselect = (e) => {
        setSelect(e)
        setSelected("Weekly")
    }



    React.useEffect(() => {
        selectedParking({ select, selected })
        // eslint-disable-next-line
    }, [select, selected])

    return (
        <Box>
            <CalendarHeader
                selected={selected}
                weekprev={weekprev}
                weeknext={weeknext}
                monthnext={monthnext}
                monthprev={monthprev}
                month={month}
                weekdates={weekdates}
                setDropdownValue={setDropdownValue}
                setselect={setselect}
                select={select}
                showAll={showAll}
                open={open}
                daily={daily.date}
                nextdate={nextdate}
                prevdate={prevdate}
                parkingGroupData={parkingGroupData}
                selectedParkingDropdown={selectedParkingDropdown}
                searchValue={searchValue}
                setSearchValueFunc={setSearchValueFunc}

            />
            <CustomPaper>
                {
                    select === "nonlist" &&
                    <Box>
                        <Box>
                            {
                                selected === "Weekly" &&
                                <WeeklyCalendar weekdates={dates} data={calendarData} openDeclinePopup={openDeclinePopup} loading={loading} />
                            }
                        </Box>
                        <Box>
                            {
                                selected === "Monthly" &&
                                <MonthlyCalendar dates={monthdays} month={month} data={calendarData} openDeclinePopup={openDeclinePopup} loading={loading} />
                            }
                        </Box>
                        <Box>
                            {
                                selected === "Daily" &&
                                <DailyCalendar progress="0" list={[]} />
                            }
                        </Box>
                    </Box>}
                {
                    select === "list" &&
                    <Box>
                        <ParkingBookingTable openPopup={openPopup} openDeclinePopup={openDeclinePopup} />
                    </Box>
                }
                {
                    select === "P" &&
                    <Grid container className={classes.root}>
                        <Grid item xs={12} className={classes.subRoot}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Box className={classes.boxRoot}>
                                    <Box className={classes.dot} bgcolor={"#5AC782"} />
                                    <Typography
                                        className={classes.availableSlots}
                                    >
                                        {"Available Slots :"}&nbsp;
                                        <span className={classes.availableSlotsDark}>{parkingSlotData?.availableCount}</span>
                                    </Typography>
                                </Box>
                                <Box className={classes.boxRoot}>
                                    <Box className={classes.dot} bgcolor={"#99B5FF"} />
                                    <Typography
                                        className={classes.availableSlots}
                                    >
                                        {"Booked Slots :"}&nbsp;
                                        <span className={classes.availableSlotsDark}>{parkingSlotData?.bookedCount}</span>
                                    </Typography>

                                </Box>
                                <Box className={classes.boxRoot}>
                                    <Box className={classes.dot} bgcolor={"#D999FF"} />
                                    <Typography
                                        className={classes.availableSlots}
                                    >
                                        {"Reserved for Tenants :"}&nbsp;
                                        <span className={classes.availableSlotsDark}>{parkingSlotData?.reservedCount}</span>
                                    </Typography>
                                </Box>
                                <Box className={classes.boxRoot}>
                                    <Box className={classes.dot} bgcolor={"#FFDD99"} />
                                    <Typography
                                        className={classes.availableSlots}
                                    >
                                        {"Reserved for Visitors :"}&nbsp;
                                        <span className={classes.availableSlotsDark}>{parkingSlotData?.visitorCount}</span>
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} className={classes.cardRoot}>
                            {loading ?
                                <LoadingSection />
                                :
                                <Grid container>
                                    {parkingSlotData?.data?.length > 0 ?
                                        parkingSlotData?.data?.map((val, index) => {
                                            let v = (index + 1)
                                            return (
                                                <>
                                                    <Grid item xs={1.5}
                                                        borderRight={(v % 8) !== 0 && "2px dashed #E4E8EE"}
                                                        className={classes.cardChild}
                                                    >
                                                        <ParkingBookingCard val={val}
                                                            openDeclinePopup={openDeclinePopup}
                                                            openPopup={openPopup}
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                        })
                                        :
                                        <Grid item xs={12} textAlign={"center"} marginTop={"30vh"}>
                                            <Typography className={classes.availableSlotsDark}>{"No data found"}</Typography>
                                        </Grid>
                                    }

                                </Grid>
                            }
                        </Grid>

                    </Grid>


                }
            </CustomPaper>
        </Box>
    )
}