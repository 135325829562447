import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Badge, Box, Button, Dialog, Divider, Drawer, Grid, Hidden, IconButton, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
import { LoadingSection, SearchFilter, Subheader, TableWithPagination, TextBox, VisiterCard } from '../../../components';
import { VisitorSecurityFilter } from "../../../components/visitorSecurityFilter";
import { config } from '../../../config';
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts";
import { UPDATE_SECURITY_REQUEST } from '../../../graphql/securityManager/mutations';
// import { GET_PROPERTY_ID_BY_USER_PROFILE_ID_AND_ROLE_ID } from '../../../graphql/securityManager/queries';
import { NetworkCall } from '../../../networkcall';
import { Routes } from "../../../router/routes";
import ImageViewer from "react-simple-image-viewer";
import { AlertProps, getCompanyOption, LocalStorageKeys, NetWorkCallMethods, convertTimeUtcToZoneCalander, timeZoneConverter, constructPropertyList, SemiBold, ExtraBold, Regular } from '../../../utils';
import {
    ApprovedHeading,
    ApprovedPath,
    ApprovedType,
    DeclinedHeading,
    DeclinedPath,
    DeclinedType,
    EntriesHeading,
    EntriesPath, EntriesType, WaitingForApprovalHeading,
    WaitingForApprovalPath,
    WaitingForApprovalType
} from '../../../utils/securityManager/visitorAndWorkedGatePassDataPoints';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import { lightFormat } from "date-fns";
import FilterIMG from "../../../assets/filter";
import { Bold } from "../../../utils";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    innerWindow: {
        borderRadius: theme.palette.borderRadius,
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        // padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
        margin: "14px"
    },
    searchFilter: {
        padding: "16px 0px 0px 0px",
        width: "30%"
    },
    table: {
        padding: "0px 16px 0px 16px"
    },
    rootCard: {
        backgroundColor: "white",
        borderRadius: "16px",
        boxShadow: "0px 3px 16px #00000014",
    },
    img: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        padding: "2px 8px",
    },
    bottomBarTitle: {
        fontSize: "18px",
        fontFamily: ExtraBold,
        color: theme.typography.color.primary,
    },
    title: {
        fontSize: "16px",
        fontFamily: Bold,
        color: theme.typography.color.secondary,
    },
    sub: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: theme.typography.color.Tertiary,
    },
    approved: {
        fontSize: "12px",
        color: "#FFFFFF",
        fontFamily: Bold,
        background: "#5AC782",
        padding: "1px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
    },
    progress: {
        fontSize: "12px",
        color: "#FFFFFF",
        fontFamily: Bold,
        background: "#78B1FE",
        padding: "1px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
    },
    rejected: {
        fontSize: "12px",
        color: "#FFFFFF",
        fontFamily: Bold,
        background: "#EC903F",
        padding: "1px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
    },
    delete: {
        fontSize: "12px",
        color: "#FFFFFF",
        fontFamily: Bold,
        background: "red",
        padding: "1px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
    },
    round: {
        color: "#00000014",
        fontSize: "40px",
    },
    border: {
        border: "1px solid #E4E8EE",
        marginTop: "-4px",
    },
    heading: {
        fontSize: "14px",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
        marginTop: "10px",
    },
    name: {
        fontSize: "16px",
        color: theme.typography.color.primary,
        fontFamily: SemiBold,
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
        },
    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        padding: "4px 8px 8px 8px",
    },
    qr: {
        fontSize: "16px",
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
    },
    expire: {
        fontSize: "12px",
        color: theme.typography.color.Tertiary,
        fontFamily: Regular,
    },
    cancelbtn: {
        padding: "12px",
        border: "1px solid #5078E1",
        backgroundColor: "white",
        borderRadius: "10px",
    },
    submitbtn: {
        borderRadius: "10px",
        padding: "12px 8px",
    },
    drawerHeaderStyle: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
    },
    drawerHeaderTextStyle: {
        fontSize: "16px",
        fontWeight: "bolder",
        color: theme.typography.color.primary,
    },
    drawerFooterStyle: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
    },
    drawerButtonStyle: {
        "&:hover": "none",
        borderRadius: theme.palette.borderRadius,
        padding: "14px",
        background: theme.palette.primary.main,
        fontSize: "14px",
        fontWeight: "normal",
        color: "#FFF",
    },
    filterBox: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    popupimg: {
        '& .styles-module_close__2I1sI': {
            color: 'white',
            position: 'absolute',
            top: '67px',
            right: '100px',
            fontSize: '40px',
            fontWeight: 'bold',
            opacity: 0.2,
            cursor: 'pointer',
        }
    },
}))


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (children)}
        </div>
    );
}

export const VisitorAndWorkedGatePass = props => {
    const filterKeys1 = ["accessGate", "stayType"]
    const filterKeys2 = ["accessGate", "stayType", "inOutStatusVW"]
    const [isViewerOpen, setIsViewerOpen] = React.useState(false)

    const classes = useStyles();
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext)
    const [tabTitle, setTabTitle] = React.useState("Waiting For Approval")
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [selectedProperty, setSelectedProperty] = React.useState({})
    const [propertyData, setPropertyData] = React.useState([])
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [filterData, setFilterData] = React.useState({})
    const [initialLoad, setInitialLoad] = React.useState(false)
    const history = useHistory();
    const [value, setValue] = React.useState(0);
    const backdrop = React.useContext(BackdropContext);
    const size = useWindowDimensions();
    const [disable, setDiable] = React.useState(false);
    const exportRef = React.useRef();

    // const [propertyIDS, setPropertyIDS] = React.useState([]);
    const [tab, setTab] = React.useState({
        offset: 0,
        page: 1,
        limit: 10
    })

    const initialDeclineFormData = {
        remarkForDecline: "",
        error: {
            remarkForDecline: "",
        }
    }

    const [declineFormData, setDeclineFormData] = React.useState({ ...initialDeclineFormData });

    const [searchText, setSearchText] = React.useState("");

    const [isGatePassDialogOpen, setIsGatePassDialogOpen] = React.useState(false);

    const [isVisitorDetailsDialogOpen, setIsVisitorDetailsDialogOpen] = React.useState(false);

    const [isEntries, setIsEntries] = React.useState(false);

    const [isDialogActionButton, setIsDialogActionButton] = React.useState(true);

    const [isAcceptActionButton, setIsAcceptActionButton] = React.useState(true);

    const [dialogTitle, setDialogTitle] = React.useState("");

    const [gatePassDetails, setGatePassDetails] = React.useState([]);


    const [visitorDetails, setVisitorDetails] = React.useState([]);

    const [waitingForApprovalData, setWaitingForApprovalData] = React.useState({
        heading: WaitingForApprovalHeading,
        row: [],
        path: WaitingForApprovalPath,
        type: WaitingForApprovalType,
    });
    const [approvedData, setApprovedData] = React.useState({
        heading: ApprovedHeading,
        row: [],
        path: ApprovedPath,
        type: ApprovedType,
    });
    const [declinedData, setDeclinedData] = React.useState({
        heading: DeclinedHeading,
        row: [],
        path: DeclinedPath,
        type: DeclinedType,
    });
    const [entriesData, setEntriesData] = React.useState({
        heading: EntriesHeading,
        row: [],
        path: EntriesPath,
        type: EntriesType,
    });

    const updateDeclineFormData = (key, value) => {
        let error = declineFormData?.error;
        error[key] = "";
        setDeclineFormData({ ...declineFormData, [key]: value, error });
    };

    const handlePagination = (value) => {
        const offset = (value - 1) * tab.limit;
        setTab({ ...tab, page: value, offset: offset })
    }

    const handleChangeLimit = (value) => {
        setTab({ ...tab, limit: value, page: 1, offset: 0 })
    }

    const isFormValid = () => {
        let isValid = true;
        let error = declineFormData.error;
        if (declineFormData?.remarkForDecline?.length === 0) {
            isValid = false;
            error.remarkForDecline = "Please Enter Remarks";
        } else {
            error.remarkForDecline = "";
        }
        setDeclineFormData({ ...declineFormData, error });
        return isValid;
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            setIsDialogActionButton(true);
            setIsEntries(false)
            setTab({ ...tab, page: 1, offset: 0 })
            setTabTitle("Waiting For Approval")
        }
        if (newValue === 1) {
            setIsDialogActionButton(false);
            setIsEntries(false)
            setTab({ ...tab, page: 1, offset: 0 })
            setTabTitle("Approved")
        }
        if (newValue === 2) {
            setIsDialogActionButton(false);
            setIsEntries(false)
            setTab({ ...tab, page: 1, offset: 0 })
            setTabTitle("Declined")
        }
        if (newValue === 3) {
            setIsDialogActionButton(false);
            setIsEntries(true)
            setTab({ ...tab, page: 1, offset: 0 })
            setTabTitle("Entries")
        }
    };

    // Function to show logo or initial
    const returnValue = (value = "", delimiter = "") => value ? value : delimiter

    React.useEffect(() => {
        if (propertyData !== null && propertyData?.length > 0 && selectedProperty?.value) {
            setInitialLoad(true)
            let created_start_date = filterData?.createdDate?.startDate ? lightFormat(filterData?.createdDate?.startDate, 'yyyy-MM-dd') : "";
            let created_end_date = filterData?.createdDate?.endDate ? lightFormat(filterData?.createdDate?.endDate, 'yyyy-MM-dd') : "";
            let request_from_start_date = filterData?.requestedFromDate?.startDate ? lightFormat(filterData?.requestedFromDate?.startDate, 'yyyy-MM-dd') : "";
            let request_from_end_date = filterData?.requestedFromDate?.endDate ? lightFormat(filterData?.requestedFromDate?.endDate, 'yyyy-MM-dd') : "";
            let request_to_start_date = filterData?.requestedToDate?.startDate ? lightFormat(filterData?.requestedToDate?.startDate, 'yyyy-MM-dd') : "";
            let request_to_end_date = filterData?.requestedToDate?.endDate ? lightFormat(filterData?.requestedToDate?.endDate, 'yyyy-MM-dd') : "";
            let check_in_start_date = filterData?.checkInDate?.startDate ? lightFormat(filterData?.checkInDate?.startDate, 'yyyy-MM-dd') : "";
            let check_in_end_date = filterData?.checkInDate?.endDate ? lightFormat(filterData?.checkInDate?.endDate, 'yyyy-MM-dd') : "";
            let check_out_start_date = filterData?.checkOutDate?.startDate ? lightFormat(filterData?.checkOutDate?.startDate, 'yyyy-MM-dd') : "";
            let check_out_end_date = filterData?.checkOutDate?.endDate ? lightFormat(filterData?.checkOutDate?.endDate, 'yyyy-MM-dd') : "";
            let access_gate_ids = filterData?.gate?.map((i) => i?.value);
            let stay_type = filterData?.stayType?.map((i) => i?.value);
            let in_out_status = filterData?.inOutStatus?.map((i) => i?.value);
            const payload = {
                tenantId: `${config.tenantId}`,
                request_type: ["WGR", "VGR"],
                status: [tabTitle ?
                    (tabTitle === "Waiting For Approval" ?
                        "Pending" :
                        tabTitle === "Approved" ?
                            "Approved" :
                            tabTitle === "Declined" ?
                                "Rejected" :
                                tabTitle === "Entries" ?
                                    "Approved" : "Pending") :
                    "Pending"],
                offset: tab.offset,
                limit: tab.limit,
                property_id: selectedProperty?.label === "All Properties" ? selectedProperty?.value : [selectedProperty?.value],
                reference_id: searchText.length > 0 ? searchText.toUpperCase() : undefined,
                company_id: selectedCompany?.value ?? undefined,
                created_start_date, created_end_date,
                request_from_start_date, request_from_end_date,
                request_to_start_date, request_to_end_date,
                check_in_start_date, check_in_end_date,
                check_out_start_date, check_out_end_date,
                access_gate_ids, stay_type, in_out_status,
            };
            if (tabTitle === "Entries") {
                setIsEntries(true);
                payload.entries = true;
            } else {
                setIsEntries(false);
            }
            NetworkCall(
                `${config.api_url}${tabTitle === "Entries" ? `/security_request_visitors` : `/security/visitors`}`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false).then((response) => {

                    if (!tabTitle || tabTitle === "Waiting For Approval") {
                        setValue(0);
                        // setIsDialogActionButton(true);
                        let tempPending = response?.data?.data?.map((item, index) => {
                            let _item = {
                                id: item?.id ? item?.id : "",
                                visitor_worker_image: {
                                    logo: returnValue(item?.visitor_image, "-"),
                                    name: item?.visitors_name ? returnValue(item?.visitors_name?.charAt(0)) : returnValue(item?.request_type?.charAt(0))
                                },
                                request_type: item?.request_type === "WGR" ? "Worker Gate Pass" : "Visitor Gate Pass",
                                date: (item?.request_on !== null && item?.request_on !== "") ? timeZoneConverter(item?.request_on, "DD MMM YY, hh:mm") : "-",
                                reference_id: (item?.request_no !== null && item?.request_no !== "") ? item?.request_no : "-",
                                visitor_worker_name: (item?.visitors_name !== null && item?.visitors_name !== "") ? item?.visitors_name : "-",
                                unit_id: (item?.unit?.[0]?.name ? (item?.unit?.[0]?.name + (item?.unit?.[0]?.unit_no ? ", " : "")) : "") + (item?.unit?.[0]?.unit_no ? item?.unit?.[0]?.unit_no : "-"),
                                visitors_workers: (item?.visitors_count !== null && item?.visitors_count !== "") ? item?.visitors_count : "-",
                                stay_type: (item?.stay_type !== null && item?.stay_type !== "") ? item?.stay_type : "-",
                                entry_gate: item?.gate ?? "Any",
                                start_date: (item?.request_from !== null && item?.request_from !== "") ? timeZoneConverter(item?.request_from, "DD MMM YY, hh:mm") : "-",
                                end_date: (item?.request_to !== null && item?.request_to !== "") ? timeZoneConverter(item?.request_to, "DD MMM YY, hh:mm") : "-",
                                status: "Waiting for Approval",
                            };
                            return _item;
                        });
                        setWaitingForApprovalData({ ...waitingForApprovalData, row: tempPending, totalRowsCount: response?.data?.totalRecords });
                    }
                    if (tabTitle && tabTitle === "Approved") {
                        setIsDialogActionButton(false);
                        let tempData = response?.data?.data?.map((item, index) => {
                            let _item = {
                                id: item?.id ? item?.id : "",
                                visitor_worker_image: {
                                    logo: returnValue(item?.visitor_image, "-"),
                                    name: item?.visitors_name ? returnValue(item?.visitors_name?.charAt(0)) : returnValue(item?.request_type?.charAt(0))
                                },
                                request_type: item?.request_type === "WGR" ? "Worker Gate Pass" : "Visitor Gate Pass",
                                date: (item?.request_on !== null && item?.request_on !== "") ? timeZoneConverter(item?.request_on, "DD MMM YY, hh:mm") : "-",
                                reference_id: (item?.request_no !== null && item?.request_no !== "") ? item?.request_no : "-",
                                visitor_worker_name: ((item?.visitors_name !== null && item?.visitors_name !== "") ? item?.visitors_name : "-"),
                                unit_id: (item?.unit?.[0]?.name ? (item?.unit?.[0]?.name + (item?.unit?.[0]?.unit_no ? ", " : "")) : "") + (item?.unit?.[0]?.unit_no ? item?.unit?.[0]?.unit_no : "-"),
                                visitors_workers: (item?.visitors_count !== null && item?.visitors_count !== "") ? item?.visitors_count : "-",
                                stay_type: (item?.stay_type !== null && item?.stay_type !== "") ? item?.stay_type : "-",
                                entry_gate: item?.gate ?? "Any",
                                start_date: ((item?.request_from !== null && item?.request_from !== "") ? timeZoneConverter(item?.request_from, "DD MMM YY, hh:mm") : "-"),
                                end_date: ((item?.request_to !== null && item?.request_to !== "") ? timeZoneConverter(item?.request_to, "DD MMM YY, hh:mm") : "-"),
                                status: "Approved",
                            };
                            return _item;
                        });
                        setValue(1);
                        setApprovedData({ ...approvedData, row: tempData, totalRowsCount: response?.data?.totalRecords });
                    }
                    if (tabTitle && tabTitle === "Declined") {
                        setValue(2);
                        setIsDialogActionButton(false);
                        let tempRejected = response?.data?.data?.map((item, index) => {
                            let _item = {
                                id: item?.id ? item?.id : "",
                                visitor_worker_image: {
                                    logo: returnValue(item?.visitor_image, "-"),
                                    name: item?.visitors_name ? returnValue(item?.visitors_name?.charAt(0)) : returnValue(item?.request_type?.charAt(0))
                                },
                                request_type: item?.request_type === "WGR" ? "Worker Gate Pass" : "Visitor Gate Pass",
                                date: (item?.request_on !== null && item?.request_on !== "") ? timeZoneConverter(item?.request_on, "DD MMM YY, hh:mm") : "-",
                                reference_id: (item?.request_no !== null && item?.request_no !== "") ? item?.request_no : "-",
                                visitor_worker_name: (item?.visitors_name !== null && item?.visitors_name !== "") ? item?.visitors_name : "-",
                                unit_id: (item?.unit?.[0]?.name ? (item?.unit?.[0]?.name + (item?.unit?.[0]?.unit_no ? ", " : "")) : "") + (item?.unit?.[0]?.unit_no ? item?.unit?.[0]?.unit_no : "-"),
                                visitors_workers: (item?.visitors_count !== null && item?.visitors_count !== "") ? item?.visitors_count : "-",
                                stay_type: (item?.stay_type !== null && item?.stay_type !== "") ? item?.stay_type : "-",
                                entry_gate: item?.gate ?? "Any",
                                start_date: (item?.request_from !== null && item?.request_from !== "") ? timeZoneConverter(item?.request_from, "DD MMM YY, hh:mm") : "-",
                                end_date: (item?.request_to !== null && item?.request_to !== "") ? timeZoneConverter(item?.request_to, "DD MMM YY, hh:mm") : "-",
                                status: "Declined",
                            };
                            return _item;
                        });
                        setDeclinedData({ ...declinedData, row: tempRejected, totalRowsCount: response?.data?.totalRecords });
                    }
                    if (tabTitle && tabTitle === "Entries") {
                        setIsDialogActionButton(false);
                        let tempData = response?.data?.data?.map((item, index) => {
                            let _item = {
                                id: item?.id ? item?.id : "",
                                visitor_worker_image: {
                                    logo: returnValue(item?.visitor_image, "-"),
                                    name: item?.visitor_name ? returnValue(item?.visitor_name?.charAt(0)) : returnValue(item?.request_type?.charAt(0))
                                },
                                request_type: item?.request_type === "WGR" ? "Worker Gate Pass" : "Visitor Gate Pass",
                                date: (item?.created_at !== null && item?.created_at !== "") ? timeZoneConverter(item?.created_at, "DD MMM YY, hh:mm") : "-",
                                reference_id: (item?.request_no !== null && item?.request_no !== "") ? item?.request_no : "-",
                                visitor_id: item?.visitor_id,
                                visitor_worker_name: (item?.visitor_name !== null && item?.visitor_name !== "") ? item?.visitor_name : "-",
                                unit_id: (item?.unit_name ? (item?.unit_name + (item?.unit_no ? ", " : "")) : "") + (item?.unit_no ? item?.unit_no : "-"),
                                stay_type: (item?.stay_type !== null && item?.stay_type !== "") ? item?.stay_type : "-",
                                entry_gate: item?.gate_name ?? "Any",
                                check_in: ((item?.check_in !== null && item?.check_in !== "") ? timeZoneConverter(item?.check_in, "DD MMM YY, hh:mm") : "-"),
                                check_out: ((item?.check_out !== null && item?.check_out !== "") ? timeZoneConverter(item?.check_out, "DD MMM YY, hh:mm") : "-"),
                                status: (((item?.check_in !== null && item?.check_in !== "") && (item?.check_out !== null && item?.check_out !== "")) ? "Yet To Checkout" : "Checked Out"),
                            };
                            return _item;
                        });
                        setValue(3);
                        setEntriesData({ ...entriesData, row: tempData, totalRowsCount: response?.data?.count });
                    }
                    setInitialLoad(false)
                }).catch((error) => {
                    setInitialLoad(false)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                    })
                });
        } else if (selectedCompany?.property?.length === 0) {
            if (!tabTitle || tabTitle === "Waiting For Approval") {
                setValue(0);
                setWaitingForApprovalData({ ...waitingForApprovalData, row: [], totalRowsCount: 0 });
            }
            if (tabTitle && tabTitle === "Approved") {
                setIsDialogActionButton(false);
                setValue(1);
                setApprovedData({ ...approvedData, row: [], totalRowsCount: 0 });
            }
            if (tabTitle && tabTitle === "Declined") {
                setValue(2);
                setIsDialogActionButton(false);
                setDeclinedData({ ...declinedData, row: [], totalRowsCount: 0 });
            }
            if (tabTitle && tabTitle === "Entries") {
                setIsDialogActionButton(false);
                setValue(3)
                setEntriesData({ ...entriesData, row: [], totalRowsCount: 0 })
            }
            setInitialLoad(false)
        }
        // eslint-disable-next-line
    }, [searchText, tab, tabTitle, selectedCompany, selectedProperty, filterData]);

    const handleViewVisitorDetails = (data) => {
        setVisitorDetails(data);
        setIsGatePassDialogOpen(false);
        setIsVisitorDetailsDialogOpen(true);
    };

    const getGatePassDetails = (data) => {

        const payload = {
            tenantId: `${config.tenantId}`,
            request_id: data?.id
        };
        NetworkCall(
            `${config.api_url}/security/request`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                setGatePassDetails({ ...response?.data?.data?.[0], visitor_id: data?.visitor_id })
                setIsGatePassDialogOpen(true);
            }).catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            });
    };

    const handleWaitingForApprovalTableRowView = (type, data) => {
        setIsDialogActionButton(true);
        setDialogTitle("Request View");
        getGatePassDetails(data);
    };

    const handleApprovedTableRowView = (type, data) => {
        setIsDialogActionButton(false);
        setDialogTitle("Approved");
        getGatePassDetails(data);
    };

    const handleDeclinedTableRowView = (type, data) => {
        setIsDialogActionButton(false);
        setDialogTitle("Declined");
        getGatePassDetails(data);
    };

    const handleEntriesTableRowView = (type, data) => {
        setIsDialogActionButton(false);
        setDialogTitle("View");
        getGatePassDetails(data);
    };

    const closeGatePassDialog = () => {
        setIsGatePassDialogOpen(false);
    }

    const closeVisitorDetailsDialog = () => {
        setIsVisitorDetailsDialogOpen(false);
        setIsGatePassDialogOpen(true);
    }

    const gatePassView = () => {
        return (<Grid
            container
            className={classes.screenScrollEffect}
            style={{
                height:
                    gatePassDetails?.status === "Approved"
                        ? size?.height - 140
                        : size?.height - 60,
                overflowX: "hidden",
                overflowY: "scroll",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                    display: "none",
                },
            }}
        >
            <Grid item xs={12} sx={{ padding: "12px" }} ref={exportRef} id="ticket">
                <div className={classes.rootCard} style={{ border: "1px solid #E4E8EE", boxShadow: "none" }}>
                    {(!isEntries && gatePassDetails?.status === "Approved") && (
                        <>
                            <br />
                            <center>
                                <QRCode value={`${gatePassDetails?.qr_no}`} size={150} />
                                <Typography className={classes.qr}>
                                    {gatePassDetails?.qr_no}
                                </Typography>

                                <Typography variant="subtitle2" className={classes.expire}>
                                    Valid Till&nbsp;
                                    {convertTimeUtcToZoneCalander(gatePassDetails?.request_to)}
                                </Typography>
                            </center>
                        </>
                    )}
                    {(gatePassDetails?.status === "Pending" || gatePassDetails?.status === "Rejected" || (isEntries && gatePassDetails?.status === "Approved")) &&
                        (<Box display="flex" alignItems="center" p={3}>
                            <Box className={classes.img}>
                                <img
                                    src="/images/workergate.svg"
                                    alt=""
                                    style={{ objectFit: "contain", marginTop: "4px" }}
                                />
                            </Box>
                            <Box flexGrow={1} marginLeft="14px">
                                <Typography variant="subtitle2" className={classes.title}>
                                    {gatePassDetails?.request_type === "VGR" && "Visitor Gate Pass"}
                                    {gatePassDetails?.request_type === "WGR" && "Worker Gate Pass"}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.sub}>
                                    Requested on{" "}
                                    {convertTimeUtcToZoneCalander(gatePassDetails?.request_from)}
                                </Typography>
                                {gatePassDetails?.status === "Pending" && (
                                    <Typography
                                        variant="subtitle2"
                                        noWrap
                                        className={classes.progress}
                                    >
                                        In Progress
                                    </Typography>
                                )}
                                {gatePassDetails?.status === "Approved" && (
                                    <Typography variant="subtitle2" className={classes.approved}>
                                        Approved
                                    </Typography>
                                )}
                                {gatePassDetails?.status === "Rejected" && (
                                    <Typography variant="subtitle2" className={classes.rejected}>
                                        Rejected
                                    </Typography>
                                )}
                                {gatePassDetails?.status === "Cancelled" && (
                                    <Typography variant="subtitle2" className={classes.delete}>
                                        Cancelled
                                    </Typography>
                                )}
                            </Box>

                        </Box>)}
                    {(gatePassDetails?.status === "Rejected" && gatePassDetails?.description !== null) &&
                        (<Box display="flex" justifyContent={"center"} alignItems="center" textAlign={"center"} margin={"0px 0px 40px 0px"}
                            style={{
                                backgroundColor: "#FFECEC",
                                padding: "8px 16px"
                            }}>
                            <Typography
                                style={{
                                    textAlign: "center",
                                    color: "#FF4B4B",
                                    fontFamily: SemiBold,
                                    wordWrap: "break-word",
                                    wordBreak: "break-word",
                                    fontSize: "12px"
                                }}>
                                {gatePassDetails?.description !== "" ? gatePassDetails?.description : "Reason Not Mentioned"}
                            </Typography>
                        </Box>)}
                    <Box display="flex" alignItems="center" marginTop="-20px">
                        <Box>
                            <CircleIcon
                                className={classes.round}
                                style={{ marginLeft: "-20px", color: "white", borderRight: "2px solid #E4E8EE", borderRadius: "100%" }}
                            />
                        </Box>
                        <Box flexGrow={1}>
                            <Box className={classes.border} />
                        </Box>
                        <Box>
                            <CircleIcon
                                className={classes.round}
                                style={{ marginRight: "-20px", color: "white", borderLeft: "2px solid #E4E8EE", borderRadius: "100%" }}
                            />
                        </Box>
                    </Box>
                    {(!isEntries && gatePassDetails?.status === "Approved") && (<Box display="flex" alignItems="center" p={3}>
                        <Box className={classes.img}>
                            <img
                                src="/images/workergate.svg"
                                alt=""
                                style={{ objectFit: "contain", marginTop: "4px" }}
                            />
                        </Box>
                        <Box flexGrow={1} marginLeft="14px">
                            <Typography variant="subtitle2" className={classes.title}>
                                {gatePassDetails?.request_type === "VGR" && "Visitor Gate Pass"}
                                {gatePassDetails?.request_type === "WGR" && "Worker Gate Pass"}
                            </Typography>
                            <Typography variant="subtitle2" className={classes.sub}>
                                Requested on{" "}
                                {convertTimeUtcToZoneCalander(gatePassDetails?.request_from)}
                            </Typography>
                            {gatePassDetails?.status === "Pending" && (
                                <Typography
                                    variant="subtitle2"
                                    noWrap
                                    className={classes.progress}
                                >
                                    In Progress
                                </Typography>
                            )}
                            {gatePassDetails?.status === "Approved" && (
                                <Typography variant="subtitle2" className={classes.approved}>
                                    Approved
                                </Typography>
                            )}
                            {gatePassDetails?.status === "Rejected" && (
                                <Typography variant="subtitle2" className={classes.rejected}>
                                    Rejected
                                </Typography>
                            )}
                            {gatePassDetails?.status === "Cancelled" && (
                                <Typography variant="subtitle2" className={classes.delete}>
                                    Cancelled
                                </Typography>
                            )}
                        </Box>
                        <Box>
                            {gatePassDetails?.status !== "Cancelled" && (
                                <>
                                    {gatePassDetails?.status === "Approved" ? (
                                        <IconButton>
                                            <img
                                                src="/images/share_icon.svg"
                                                alt=""
                                                style={{ objectFit: "contain", marginTop: "4px" }}
                                            />
                                        </IconButton>
                                    ) : (
                                        <IconButton>
                                            <img
                                                src="/images/Outline.svg"
                                                alt=""
                                                style={{ objectFit: "contain", marginTop: "4px" }}
                                            />
                                        </IconButton>
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>)}
                    <Box p={3} marginTop="-24px">
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" className={classes.heading}>
                                    REFERENCE ID
                                </Typography>
                                <Typography variant="subtitle2" className={classes.title}>
                                    {gatePassDetails?.request_no}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.heading}>
                                    {gatePassDetails?.request_type === "VGR" && "VISITOR"}
                                    {gatePassDetails?.request_type === "WGR" && "WORKER"} STAY TYPE
                                </Typography>
                                <Typography variant="subtitle2" className={classes.title}>
                                    {gatePassDetails?.stay_type}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.heading}>
                                    CHOOSE GATE ENTRY
                                </Typography>
                                <Typography variant="subtitle2" className={classes.title}>
                                    {gatePassDetails?.gate}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" className={classes.heading}>
                                    REQUESTED BY
                                </Typography>
                                <Typography variant="subtitle2" className={classes.title}>
                                    {gatePassDetails?.requested_by}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.heading}>
                                    UNIT
                                </Typography>
                                <Typography variant="subtitle2" className={classes.title}>
                                    {(gatePassDetails?.unit?.[0]?.name ? (gatePassDetails?.unit?.[0]?.name + (gatePassDetails?.unit?.[0]?.unit_no ? ", " : "")) : "") + (gatePassDetails?.unit?.[0]?.unit_no ? gatePassDetails?.unit?.[0]?.unit_no : "-")}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.heading}>
                                    VISIT START & END DATE
                                </Typography>
                                <Typography variant="subtitle2" className={classes.title}>
                                    {convertTimeUtcToZoneCalander(gatePassDetails?.request_from)}
                                    &nbsp;-&nbsp;
                                    {convertTimeUtcToZoneCalander(gatePassDetails?.request_to)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <br />
                    </Box>
                    <Box p={2} marginTop="-24px">
                        <Box className={classes.border} />
                        {tabTitle === "Entries" ?
                            gatePassDetails?.visitors?.map((val) => {
                                return gatePassDetails?.visitor_id === val?.id ? <VisiterCard data={val} isEntries={isEntries} onClick={handleViewVisitorDetails} /> : <></>;
                            }) :
                            gatePassDetails?.visitors?.map((val) => {
                                return <VisiterCard data={val} isEntries={isEntries} onClick={handleViewVisitorDetails} />;
                            })}
                    </Box>
                </div>
            </Grid>
        </Grid >);
    }

    const acceptGatePass = () => {
        setDiable(true)
        const payload = {
            query: UPDATE_SECURITY_REQUEST,
            variables: {
                securityRequestID: gatePassDetails?.id,
                updatePayload: {
                    status: "Approved",
                    updated_at: new Date().toISOString(),
                    updated_by: localStorage.getItem(LocalStorageKeys.userProfileID),
                }
            },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                setDiable(false)
                setIsEntries(false)
                setIsDialogActionButton(false);
                setDialogTitle("Approved");
                getGatePassDetails(gatePassDetails);
                setTabTitle("Waiting For Approval")
            }).catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Internal error. Please try again later.",
                });
            });
    }

    const declineGatePassForm = () => {
        return (<Grid p={2}>
            <TextBox
                multiline
                label='Remark For Decline'
                placeholder='Enter Remarks'
                value={declineFormData?.remarkForDecline}
                onChange={(e) => {
                    updateDeclineFormData("remarkForDecline", e.target.value);
                }}
                isError={declineFormData?.error?.remarkForDecline?.length > 0}
                errorMessage={declineFormData?.error?.remarkForDecline} />
        </Grid>);
    }

    const declineGatePass = () => {
        if (isFormValid()) {
            setDiable(true)
            const payload = {
                query: UPDATE_SECURITY_REQUEST,
                variables: {
                    securityRequestID: gatePassDetails?.id,
                    updatePayload: {
                        description: declineFormData?.remarkForDecline,
                        status: "Rejected",
                        updated_at: new Date().toISOString(),
                        updated_by: localStorage.getItem(LocalStorageKeys.userProfileID),
                    }
                },
            };
            NetworkCall(
                `${config.graphql_url}`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false).then((response) => {
                    setDiable(false)
                    setIsEntries(false)
                    setIsDialogActionButton(false);
                    setIsAcceptActionButton(true);
                    setDialogTitle("Declined");
                    getGatePassDetails(gatePassDetails);
                    setDeclineFormData({ ...initialDeclineFormData });
                    setTabTitle("Waiting For Approval")
                }).catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Internal error. Please try again later.",
                    });
                });
        } else { return false; }
    }

    const visitorDetailView = () => {
        return (<div style={{ padding: "12px" }}>
            <Grid container>
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center" p={2}>
                        <Box>
                            <Avatar src={visitorDetails?.visitor_image} onClick={() => visitorDetails?.visitor_image ? setIsViewerOpen(true) : false} style={{ cursor: "pointer" }} />
                            {isViewerOpen && (
                                <ImageViewer
                                    src={[visitorDetails?.visitor_image]}
                                    currentIndex={0}
                                    onClose={() => setIsViewerOpen(false)}
                                    disableScroll={false}
                                    backgroundStyle={{
                                        backgroundColor: "rgba(0,0,0,0.9)",
                                    }}
                                    className={classes.popupimg}
                                    closeOnClickOutside={true}
                                />
                            )}
                        </Box>
                        <Box marginLeft="10px">
                            <Typography variant="subtitle2" className={classes.name}>
                                {visitorDetails?.name}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                        ID PROOF
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "#091B29" }}
                    >
                        {visitorDetails?.id_type}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.heading}>
                        PHONE NUMBER
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "#091B29" }}
                    >
                        {visitorDetails?.mobile_country_code}&nbsp;
                        {visitorDetails?.mobile_no}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.heading}>
                        ID NUMBER
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "#091B29" }}
                    >
                        {visitorDetails?.identification_no?.slice(0, 10)}...
                    </Typography>
                    <Typography variant="subtitle2" className={classes.heading}>
                        MAIL ID
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "#091B29" }}
                    >
                        {visitorDetails?.email_id}
                    </Typography>
                </Grid>
            </Grid>
        </div>);
    }

    // useEffect to get company list for company switcher when loading the screen
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getPropertyList(company?.selected)
        }
        // eslint-disable-next-line
    }, [auth])

    // Function to change property list
    const getPropertyList = (company) => {
        let property = constructPropertyList(company)
        setPropertyData(property?.list);
        setSelectedProperty(property?.list[0])
    }

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getPropertyList(value)
    }

    //handlePropertyChange
    const onPropertyChange = (val) => {
        setSelectedProperty(val)
    }

    // Filter Component Functions
    const apply = (values) => {
        setFilterData(values)
    }


    return <div className={classes.root}>
        <Subheader hideBackButton={true}
            title="Visitors/Workers Request"
            select
            options={companyList}
            value={selectedCompany}
            goBack={() => history.push(Routes.home)}
            onchange={(e) => handleCompanyChange(e)}
            onPropertyChange={(e) => {
                onPropertyChange(e)
            }}
            propertyValue={selectedProperty}
            selectProperty
            propertyOptions={propertyData} />
        <Grid direction={"column"} className={classes.innerWindow}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Waiting for Approval" />
                        <Tab label="Approved" />
                        <Tab label="Declined" />
                        <Tab label="Entries" />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {waitingForApprovalData.heading.length > 0 && (
                        <Grid item xs={12} className={classes.table}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <div className={classes.searchFilter}>
                                        <SearchFilter placeholder="Search Reference ID" value={searchText} handleChange={(value) => setSearchText(value)} />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={"flex"} justifyContent={"end"} p={2}>
                                        <IconButton onClick={() => setFilterDrawer(!filterDrawer)} className={classes.filterBox}>
                                            <Badge variant="dot" color="primary"
                                                invisible={!(Object.keys(filterData).length > 0)}>
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                            {initialLoad ? <LoadingSection message={"feteching data..."} /> :
                                <TableWithPagination
                                    heading={waitingForApprovalData.heading}
                                    rows={waitingForApprovalData.row}
                                    path={waitingForApprovalData.path}
                                    showpagination={waitingForApprovalData.row.length > 0 ? true : false}
                                    onClick={() => false}
                                    handleIcon={handleWaitingForApprovalTableRowView}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    dataType={waitingForApprovalData.type}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={waitingForApprovalData?.totalRowsCount}
                                    page={tab.page}
                                    limit={tab.limit}
                                    height={'calc(100vh - 330px)'}
                                    tableType="no-side"
                                    view={true}
                                    edit={true}
                                    delete={true} />
                            }
                        </Grid>
                    )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {approvedData.heading.length > 0 && (
                        <Grid item xs={12} className={classes.table}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <div className={classes.searchFilter}>
                                        <SearchFilter placeholder="Search Reference ID" value={searchText} handleChange={(value) => setSearchText(value)} />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={"flex"} justifyContent={"end"} p={2}>
                                        <IconButton onClick={() => setFilterDrawer(!filterDrawer)} className={classes.filterBox}>
                                            <Badge variant="dot" color="primary"
                                                invisible={!(Object.keys(filterData).length > 0)}>
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                            {initialLoad ? <LoadingSection message={"feteching data..."} /> :

                                <TableWithPagination
                                    heading={approvedData.heading}
                                    rows={approvedData.row}
                                    path={approvedData.path}
                                    showpagination={approvedData.row.length > 0 ? true : false}
                                    onClick={() => false}
                                    handleIcon={handleApprovedTableRowView}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    dataType={approvedData.type}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={approvedData?.totalRowsCount}
                                    page={tab.page}
                                    limit={tab.limit}
                                    height={'calc(100vh - 330px)'}
                                    tableType="no-side"
                                    view={true}
                                    edit={true}
                                    delete={true} />
                            }
                        </Grid>
                    )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {declinedData.heading.length > 0 && (
                        <Grid item xs={12} className={classes.table}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <div className={classes.searchFilter}>
                                        <SearchFilter placeholder="Search Reference ID" value={searchText} handleChange={(value) => setSearchText(value)} />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={"flex"} justifyContent={"end"} p={2}>
                                        <IconButton onClick={() => setFilterDrawer(!filterDrawer)} className={classes.filterBox}>
                                            <Badge variant="dot" color="primary"
                                                invisible={!(Object.keys(filterData).length > 0)}>
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                            {initialLoad ? <LoadingSection message={"feteching data..."} /> :

                                <TableWithPagination
                                    heading={declinedData.heading}
                                    rows={declinedData.row}
                                    path={declinedData.path}
                                    showpagination={declinedData.row.length > 0 ? true : false}
                                    onClick={() => false}
                                    handleIcon={handleDeclinedTableRowView}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    dataType={declinedData.type}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={declinedData?.totalRowsCount}
                                    page={tab.page}
                                    limit={tab.limit}
                                    height={'calc(100vh - 330px)'}
                                    tableType="no-side"
                                    view={true}
                                    edit={true}
                                    delete={true} />
                            }
                        </Grid>
                    )}
                </TabPanel>
                <TabPanel value={value} index={3}>
                    {entriesData.heading.length > 0 && (
                        <Grid item xs={12} className={classes.table}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <div className={classes.searchFilter}>
                                        <SearchFilter placeholder="Search Reference ID" value={searchText} handleChange={(value) => setSearchText(value)} />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={"flex"} justifyContent={"end"} p={2}>
                                        <IconButton onClick={() => setFilterDrawer(!filterDrawer)} className={classes.filterBox}>
                                            <Badge variant="dot" color="primary"
                                                invisible={!(Object.keys(filterData).length > 0)}>
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                            {initialLoad ? <LoadingSection message={"feteching data..."} /> :

                                <TableWithPagination
                                    heading={entriesData.heading}
                                    rows={entriesData.row}
                                    path={entriesData.path}
                                    showpagination={entriesData.row.length > 0 ? true : false}
                                    onClick={() => false}
                                    handleIcon={handleEntriesTableRowView}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    dataType={entriesData.type}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={entriesData?.totalRowsCount}
                                    page={tab.page}
                                    limit={tab.limit}
                                    height={'calc(100vh - 330px)'}
                                    tableType="no-side"
                                    view={true}
                                    edit={true}
                                    delete={true} />
                            }
                        </Grid>
                    )}
                </TabPanel>
            </Box>
        </Grid>
        <Hidden smUp>
            <Drawer
                className={classes.drawer}
                anchor="bottom"
                open={isGatePassDialogOpen}
                onClose={closeGatePassDialog}>
                <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}>
                    <Grid>
                        <Typography className={classes.drawerHeaderTextStyle}>
                            {dialogTitle}
                        </Typography>
                    </Grid>
                    <Grid>
                        <IconButton
                            style={{
                                padding: "0px",
                            }}
                            onClick={closeGatePassDialog}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                {isAcceptActionButton ? gatePassView() : declineGatePassForm()}
                {isDialogActionButton && (<><Divider />
                    {isAcceptActionButton ? (<Grid className={classes.drawerFooterStyle}>
                        <Box height={"6px"} />
                        <Grid container direction={"row"} >
                            <Grid container xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginRight: "8px", backgroundColor: "#FF4B4B" }}
                                    variant="contained"
                                    onClick={() => setIsAcceptActionButton(false)}>
                                    Decline
                                </Button>
                            </Grid>
                            <Grid container xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginLeft: "8px", backgroundColor: "#5AC782" }}
                                    variant="contained"
                                    onClick={acceptGatePass}>
                                    Accept
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>) : (<Grid className={classes.drawerFooterStyle}>
                        <Box height={"6px"} />
                        <Grid container direction={"row"} >
                            <Grid container xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginRight: "8px", backgroundColor: "#FFFFFF", color: "#091B29" }}
                                    variant="contained"
                                    onClick={() => setIsAcceptActionButton(true)}>
                                    Back
                                </Button>
                            </Grid>
                            <Grid container xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginLeft: "8px", backgroundColor: "#FF4B4B" }}
                                    variant="contained"
                                    onClick={declineGatePass}>
                                    Decline
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>)}</>)}
            </Drawer>
        </Hidden>
        <Hidden smDown>
            <Dialog
                className={classes.dialog}
                fullWidth
                open={isGatePassDialogOpen}
                onClose={closeGatePassDialog}>
                <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}>
                    <Grid>
                        <Typography className={classes.drawerHeaderTextStyle}>
                            {dialogTitle}
                        </Typography>
                    </Grid>
                    <Grid>
                        <IconButton
                            style={{
                                padding: "0px",
                            }}
                            onClick={closeGatePassDialog}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                {isAcceptActionButton ? gatePassView() : declineGatePassForm()}
                {isDialogActionButton && (<><Divider />
                    {isAcceptActionButton ? (<Grid className={classes.drawerFooterStyle}>
                        <Box height={"6px"} />
                        <Grid container direction={"row"} >
                            <Grid container xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    disabled={disable}
                                    style={{ marginRight: "8px", backgroundColor: "#FF4B4B" }}
                                    variant="contained"

                                    onClick={() => setIsAcceptActionButton(false)}>
                                    Decline
                                </Button>
                            </Grid>
                            <Grid container xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginLeft: "8px", backgroundColor: "#5AC782" }}
                                    variant="contained"
                                    disabled={disable}
                                    onClick={acceptGatePass}>
                                    Accept
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>) : (<Grid className={classes.drawerFooterStyle}>
                        <Box height={"6px"} />
                        <Grid container direction={"row"} >
                            <Grid container xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginRight: "8px", backgroundColor: "#FFFFFF", color: "#091B29" }}
                                    variant="contained"
                                    onClick={() => setIsAcceptActionButton(true)}>
                                    Back
                                </Button>
                            </Grid>
                            <Grid container xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginLeft: "8px", backgroundColor: "#FF4B4B" }}
                                    variant="contained"
                                    disabled={disable}
                                    onClick={declineGatePass}>
                                    Decline
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>)}</>)}
            </Dialog>
        </Hidden>
        <Hidden smUp>
            <Drawer
                className={classes.drawer}
                anchor="bottom"
                open={isVisitorDetailsDialogOpen}
                onClose={closeVisitorDetailsDialog}>
                <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}>
                    <Grid>
                        <Typography className={classes.drawerHeaderTextStyle}>
                            View {gatePassDetails?.request_type === "VGR" && "Visitor"}
                            {gatePassDetails?.request_type === "WGR" && "Worker"} Detail
                        </Typography>
                    </Grid>
                    <Grid>
                        <IconButton
                            style={{
                                padding: "0px",
                            }}
                            onClick={closeVisitorDetailsDialog}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                {visitorDetailView()}
            </Drawer>
        </Hidden>
        <Hidden smDown>
            <Dialog
                className={classes.dialog}
                fullWidth
                open={isVisitorDetailsDialogOpen}
                onClose={closeVisitorDetailsDialog}>
                <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}>
                    <Grid>
                        <Typography className={classes.drawerHeaderTextStyle}>
                            View {gatePassDetails?.request_type === "VGR" && "Visitor"}
                            {gatePassDetails?.request_type === "WGR" && "Worker"} Detail
                        </Typography>
                    </Grid>
                    <Grid>
                        <IconButton
                            style={{
                                padding: "0px",
                            }}
                            onClick={closeVisitorDetailsDialog}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                {visitorDetailView()}
            </Dialog>
        </Hidden>
        <VisitorSecurityFilter
            open={filterDrawer}
            close={() => setFilterDrawer(!filterDrawer)}
            apply={apply}
            filterData={filterData}
            filterKeys={value === 3 ? filterKeys2 : filterKeys1}
            clientId={localStorage.getItem(LocalStorageKeys?.clinetID)}
            property={selectedProperty} />
    </div>
}