import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from "react-router-dom";
import CityProperty from '../../assets/cityproperty';
import ContractProperty from '../../assets/contractproperty';
import ImagesProperty from '../../assets/imagesproperty';
import { LoadingSection, PropertyTabs, Subheader } from "../../components";
import { Routes } from "../../router/routes";
import { PropertyStylesParent } from "../propertyviewing/style";
import Skeleton from '@mui/material/Skeleton';
import {
    VendorBasicDetailsView,
    PaymentAndTaxDetails,
    RegistrationDetails,
    VendorAssetsView
} from './views';
import { useApolloClient } from '@apollo/client';
import { constructVendorDetails, GET_VENDOR_DETAILS } from './utils';
import { AlertContext, AuthContext } from '../../contexts';
import { accessCheckRender, AlertProps, getRoutePermissionNew } from '../../utils';

export const VendorDetails = (props) => {

    const classes = PropertyStylesParent();
    const history = useHistory();
    const client = useApolloClient();
    const alert = useContext(AlertContext);
    const [loading, setLoading] = useState(null);
    const [value, setValue] = useState(1);
    const [data, setData] = useState({});
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getVendor()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getVendor = () => {
        if (props?.match?.params?.id) {
            getVendorDetails(props?.match?.params?.id)
        }
    }

    const getVendorDetails = (id) => {
        setLoading('screen')
        client.query({
            query: GET_VENDOR_DETAILS,
            fetchPolicy: "network-only",
            variables: {
                id
            }
        }).then(res => {
            constructVendorDetailsData(res?.data?.vendor_master?.[0])
        }).catch(err => {
            console.log(err);
            setLoading(null);
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something went wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        })
    }

    const constructVendorDetailsData = (val) => {
        let vendorDetails = constructVendorDetails(val);
        setData(vendorDetails)
        setLoading(null);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabTittle = useMemo(() => (
        [
            {
                label: 'Company Basic Details',
                imgSelect: <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
                value: 1,
                className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                label: 'Payment & Tax Details',
                imgSelect: <ImagesProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
                value: 2,
                className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                label: 'Registration details',
                imgSelect: <ContractProperty color={value === 3 ? '#5078E1' : '#98A0AC'} />,
                value: 3,
                className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                label: 'Assets',
                imgSelect: <ImagesProperty color={value === 4 ? '#5078E1' : '#98A0AC'} />,
                value: 4,
                className: value === 4 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 4 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 4 ? classes.subtabtitle1 : classes.subtabtitle
            },
        ]
        // eslint-disable-next-line
    ), [value])

    const handleedit = () => {
        history.push({
            pathname: `${Routes.editVendor?.split('/:')[0]}/${props?.match?.params?.id}`,
            state: {
                from: 'edit',
                companyID: data?.vendorProfile?.profileDetails?.vendorCompany
                // tabIndex: index
            },
        });
    }


    const render = () => {
        return <>
            <Subheader
                title={(
                    loading === "screen" ? <Skeleton variant="text" width={"100px"} /> : data?.vendorProfile?.profileDetails?.vendorName
                )}
                goBack={() => history.goBack(-1)}
            />
            {
                loading === "screen" ? (
                    <LoadingSection
                        bottom={"30vh"}
                        message="Loading Vendor Details..."
                    />
                ) : (
                    <PropertyTabs
                        value={value}
                        handleChange={handleChange}
                        isEdit={permission.update}
                        handleedit={handleedit}
                        style={{
                            height: "calc(100vh - 161px)",
                            overflow: "auto"
                        }}
                        tab1={(
                            <VendorBasicDetailsView data={data?.vendorProfile} />
                        )}
                        tab2={(
                            <PaymentAndTaxDetails data={data?.paymentTaxDetails} />
                        )}
                        tab3={(
                            <RegistrationDetails data={data?.registrationDetails} />
                        )}
                        tab4={(
                            <VendorAssetsView data={data?.assets} />
                        )}
                        tabTittle={tabTittle}
                        showBtns={true}

                    />
                )
            }
        </>
    }

    return (
        <div >
            {accessCheckRender(render, permission)}
        </div >
    )
}