import { Typography , Box , Grid} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";
import { Regular, SemiBold } from "../../utils";
import { Label } from "../label";
import { Bold } from "../../utils";

const useStyles = makeStyles((theme) => ({
  createBtn: {
    display: "inline-flex",
    cursor: "pointer",
  },
  selects: {
    "& .css-1rhbuit-multiValue": {
      backgroundColor: window.location.pathname === "/properties" ? "#E4E8EE" : "#DBEDFE",
    },
  },
  label: {
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold,
    fontSize: "14px",
    marginBottom: "5px",
  },
  labelb: {
    color: theme.typography.color.primary,
    fontSize: "16px",
    fontFamily: Bold,
    marginBottom: "5px",
  },
  selectRoot:{
    border: (props)=>props?.style || props?.noBorder ? "none" : "1.5px solid #E2E2E2",
    boxShadow: "none",
    outline: "none",
    display:'flex',
    alignItems:"center",
    borderRadius: (props)=>props?.borderRadius ? 4 : 0,
    "&:hover": {
      border: (props)=>props?.style || props?.noBorder ? "none" : "1.5px solid #5078E1",
    },
  }
}));


export const SelectBox = ({
  parentID = "",
  label = "",
  isRequired = false,
  isPaginate = false,
  isReadOnly = false,
  createSelect = false,
  value = "",
  placeholder = "",
  options = [],
  loading = false,
  loadOptions = () => null,
  onChange = () => null,
  isError = false,
  errorMessage = "",
  isMulti = false,
  reduceOptions = undefined,
  debounceTimeout = 0,
  key,
  bold = false,
  isSelectAll,
  color,
  padding,
  isSearchable = true,
  width = "auto",
  style = false,
  isClearable = false,
  menuPlacement = "bottom",
  zIndex = 0,
  borderRadius = true,
  noBorder = false,
  prefix = false,
  selectHeight = "",
  fontFamily = Regular,
  left="0px"
}) => {
  const classes = useStyles({
    bold,
    isSelectAll,
    style,
    noBorder,
    borderRadius
  });
  const customStyles = {

    control: (base) => ({
      ...base,
      borderRadius: borderRadius ? 4 : 0,
      backgroundColor: color ?? "none",
      zIndex: zIndex ?? 1,
      height: selectHeight ? selectHeight : "45px !important",
      fontSize: "14px",
      padding: padding ? padding : "auto",
      minHeight: selectHeight ? selectHeight : "45px !important",
      fontFamily: fontFamily,
      minWidth: width,
      border:  "none" ,
      boxShadow: "none",
      outline: "none",
      "&:hover": {
        border: "none"
      },
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        fontFamily: fontFamily,
        fontSize: 14,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "100%",
        overflow: "hidden"

      };
    },
    menu: defaultStyles => {
      return {
        ...defaultStyles,
        zIndex: 3,
        borderRadius: "4px",
        fontSize: "14px",
        fontFamily: fontFamily,
      };
    },
  };

  return (

    <div className={classes.root}>
      {label && <Label label={label} isRequired={isRequired} />}

      <Box className={classes.selectRoot}>
        <Grid container spacing={1} alignItems="center">
          {
            prefix &&
            <Grid item xs={1} mt={0.5}>
              <Box style={{paddingLeft:left ?? "0px"}}>
              {
                prefix
              }
              </Box>
            </Grid>
          }
          <Grid item xs={prefix ? 11 : 12}>
          {isPaginate ? (
          <AsyncPaginate
            key={key}
            isClearable={isClearable}
            isSearchable={isSearchable}
            components={{
              IndicatorSeparator: () => null,
              // Control
            }}
            value={value}
            placeholder={placeholder}
            loadOptions={loadOptions}
            onChange={onChange}
            options={options}
            isLoading={loading}
            defaultOptions={options}
            styles={customStyles}
            isDisabled={isReadOnly}
            isMulti={isMulti}
            debounceTimeout={debounceTimeout}
            reduceOptions={reduceOptions}
            menuPlacement={menuPlacement}
            menuPortalTarget={document.getElementById(parentID)}
          />
        ) : createSelect ? (
          <CreatableSelect
            isClearable={false}
            options={options}
            isLoading={loading}
            placeholder={placeholder}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={value}
            onChange={onChange}
            styles={customStyles}
            isDisabled={isReadOnly}
            menuPlacement={menuPlacement}
            menuPortalTarget={document.getElementById(parentID)}
          />
        ) : (
          <Select
            isClearable={false}
            isSearchable
            components={{
              IndicatorSeparator: () => null,
            }}
            value={value}
            autoComplete="off"
            placeholder={placeholder}
            options={options}
            isLoading={loading}
            menuPlacement={menuPlacement}
            onChange={onChange}
            styles={customStyles}
            className={classes.selects}
            isMulti={isMulti}
            isDisabled={isReadOnly}
            isSelectAll
            menuPortalTarget={document.getElementById(parentID)}
          />
        )}
          </Grid>
        </Grid>
      </Box>

      {isError && (
        <Typography variant={"caption"} color={"error"}>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};