import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { userListStyles } from './style'

export const UserList = ({ userData = {}, }) => {
    const classes = userListStyles()
    return (
        <Box>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Avatar className={classes.avatar} variant='square' src={userData?.image} />
                <Stack alignItems={"left"}>
                    <Typography className={classes.name}> {userData?.name} </Typography>
                    {/* <Typography className={classes.role}> {userData?.sub_title} </Typography> */}
                    <Typography className={classes.role}> {userData?.sub_title1} </Typography>
                </Stack>
            </Stack>
        </Box>
    )
}