import React, { useState, useEffect } from "react";
import { AlertContext } from "../../contexts";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import moment from "moment";
import { RequestCard } from "./components";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";

export const RequestsComponent = (props) => {
  const [value, setValue] = useState("General");
  const [searchText, setSearchText] = useState("");
  const [generalAndMaintenanceData, setgeneralAndMaintenanceData] = useState(
    []
  );
  const [reqtype,setReqType] = React.useState("")
  // context
  const alert = React.useContext(AlertContext);

  const history = useHistory()
  // tab change function
  const handleChange = (event, newValue) => {
    setValue(newValue);
    GeneralAndMaintenance(newValue, searchText);
  };
  // request search
  const handleSearch = (e) => {
    setSearchText(e);
    GeneralAndMaintenance(value, e);
  };
  //General and maintenance api call
  const GeneralAndMaintenance = (type, search) => {
    // backdrop.setBackDrop({
    //   ...backdrop,
    //   open: true,
    //   message: "Getting Requests ...",
    // });
    setReqType(type)
    let teamsId = props?.selectedTeams?.value;

    let companyId = [props?.selectedcompany?.value];
    const payload = {
      company_id: companyId,
      request_type: type ? [type] : ["General"],
      search: search ?? "",
      offset: 0,
      limit: 10,
    };
    if (teamsId !== "noteams") {
      payload["team"] = teamsId
      payload["team_all_property"] = true

    }
    NetworkCall(
      `${config.api_url}/request`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (response?.data?.data) {
          let result = response?.data?.data?.map((val, index) => {
            return {
              id: index,
              title: val?.subject ?? " - ",
              type: val?.request_type ?? " - ",
              date: val?.create_date
                ? moment(val?.create_date).format("DD MMM")
                : " - ",
              unitNo: val?.units?.unit_no ?? "-",
              reqId: val?.id
            };
          });
          setgeneralAndMaintenanceData({
            data: result,
            generalCount: response?.data?.generalCount,
            maintanceCount: response?.data?.maintenanceCount,
          });
        }
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
      })
      .catch((error) => {
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  //   useEffect
  useEffect(() => {
    if (props?.selectedcompany?.label.length > 0) {
      GeneralAndMaintenance(value, searchText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedTeams]);

  const navigate = (data) => {
    if(data?.type === "General"){
      history.push({
        pathname: Routes.generalRequestView,
        state: {
          main: {
            request: reqtype,
            type: "view",
            value: data?.reqId
          }
        }
      })
    }else{
      history.push({
        pathname: Routes.maintanceRequestView,
        state: {
          main: {
            request: reqtype,
            type: "view",
            value: data?.reqId
          }
        }
      })
    }

  }
  return (
    <>
      <RequestCard
        generalAndMaintenanceData={generalAndMaintenanceData}
        handleChange={handleChange}
        handleSearch={handleSearch}
        value={value}
        height={"100%"}
        searchText={searchText}
        matches={props?.matches}
        navigate={navigate}
      />
    </>
  );
};
