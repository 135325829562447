import React from "react"
import { withNavBars } from "../../HOCs"
import { UnitVacancyPropertyList } from "./unitVacancyPropertyList"

class UnitVacancyPropertyListParent extends React.Component {
    render() {
        return <UnitVacancyPropertyList />
    }
}
const props = { boxShadow: false }

export default withNavBars(UnitVacancyPropertyListParent, props)