import { Box, Typography } from "@mui/material";
import React from "react";
import DeleteIcon from "../../assets/delete";
import { useStyles } from "./styles";

export const AssetCard = ({
    x = {},
    onDelete = () => false
}) => {
    const classes = useStyles();
    const [showText, setShowtext] = React.useState(false);
    return (
        <Box sx={{ cursor: "pointer" }}>

            <Box onMouseOver={() => setShowtext(true)}
                onMouseLeave={() => setShowtext(false)} position={"relative"}>
                <img src={x?.url} className={`${classes.selectedImg}`} alt="" />
                {showText === true && (
                    <Box className={classes.hoverCard}>
                        <Box sx={{ float: "right", marginRight: "12px", cursor: "pointer", marginTop: "12px" }} onClick={onDelete}>
                            <DeleteIcon />
                        </Box>
                        <Box className={`${classes.imgtext}`}>
                            <Typography className={`${classes.imgName}`}>
                                {x?.file_meta?.name}
                            </Typography>
                            <Box display={"flex"}>
                                <Typography className={`${classes.textName}`}>
                                    {x?.file_meta?.size}
                                </Typography>
                                <div className={`${classes.dot}`}></div>
                                <Typography className={`${classes.textName}`}>{x?.file_meta?.type}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    )
}