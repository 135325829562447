import React from 'react';
import { Box, Grid, Typography } from "@mui/material";
import { useStylesCreation } from "../style";
import { TextBox, NewDatePicker } from "../../../components";
import styled from '@mui/material/styles/styled';
import { Bold } from '../../../utils';
export const NationalIDDetails = (props) => {
      const CustomTypography = styled(Typography)(({ theme }) => ({
            fontSize: 12,
            fontFamily: Bold,
            color: theme.typography.color.secondary,
            marginBottom: theme.spacing(1)
      }))
      const textBoxStyle = {
            padding: "8px 14px",
            borderRadius: 8,
            height: 47
      }
      const classes = useStylesCreation();
      return (
            <Box className={classes.imagebox1}>
                  <CustomTypography>ID DETAILS</CustomTypography>
                  <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={4}>
                              <TextBox
                                    {...textBoxStyle}
                                    label="Nationality"
                                    placeholder="Enter Nationality"
                                    value={props?.data?.nationality ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("nationality", e.target.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              <TextBox
                                    {...textBoxStyle}
                                    label="National ID"
                                    placeholder="Enter National ID"
                                    value={props?.data?.nationalID ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("nationalID", e.target.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              <NewDatePicker
                                    label="National ID Expiry"
                                    placeholder="National ID Expiry"
                                    value={props?.data?.nationalIDExpiry ?? ""}
                                    onChange={(value) => props?.updateState("nationalIDExpiry", value)} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              <TextBox
                                    {...textBoxStyle}
                                    label="Passport ID"
                                    placeholder="Enter Passport ID"
                                    value={props?.data?.passportID ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("passportID", e.target.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              <NewDatePicker
                                    label="Passport Expiry"
                                    placeholder="Passport Expiry"
                                    value={props?.data?.passportIDExpiry ?? ""}
                                    onChange={(value) => props?.updateState("passportIDExpiry", value)} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              <TextBox
                                    {...textBoxStyle}
                                    label="Resident ID"
                                    placeholder="Enter Resident ID"
                                    value={props?.data?.residentID ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("residentID", e.target.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              <NewDatePicker
                                    label="Resident ID Expiry"
                                    placeholder="Resident ID Expiry"
                                    value={props?.data?.residentIDExpiry ?? ""}
                                    onChange={(value) => props?.updateState("residentIDExpiry", value)} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              <TextBox
                                    {...textBoxStyle}
                                    label="Visa Number"
                                    placeholder="Enter Visa Numbers"
                                    value={props?.data?.visaNumber ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("visaNumber", e.target.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              <NewDatePicker
                                    label="Visa Expiry"
                                    placeholder="Visa Expiry"
                                    value={props?.data?.visaNumberExpiry ?? ""}
                                    onChange={(value) => props?.updateState("visaNumberExpiry", value)} />
                        </Grid>
                  </Grid>
            </Box>
      )
}