/* eslint-disable no-unreachable */
import React from "react";
import { SelectBox } from "../../components";
import { Grid, Button, Box } from "@mui/material";
import { ScreensStylesParent } from "../style";
import { NetworkCall } from "../../../../networkcall";
import { NetWorkCallMethods, useWindowDimensions } from "../../../../utils";
import { config } from "../../../../config";
import { GET_COMPANY, GET_PROPERTY, GET_ROLES_BY_ID ,GET_PROPERTY_SELECT_ALL} from "../../queries";

export const AddRole = (props) => {
  // style
  const classes = ScreensStylesParent();
  const [loading, setLoading] = React.useState(false);
  const size = useWindowDimensions();
  const [companyState, setCompanyState] = React.useState([]);
  const loadOptions = async (search = "", array, type) => {
    setLoading(type);
    let result,
      query,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }
    const client = localStorage.getItem("clinetID");

    switch (type) {
      case "role":
        query = GET_ROLES_BY_ID(
          offset,
          10,
          search,
          client,
          props?.addRole?.module?.value
        ).loc.source.body;
        result = await networkCallBack(query);
        return {
          options: [...result?.client_roles],
          hasMore:
            array?.length + result?.client_roles?.length <
            result?.count[0]?.count,
        };
      case "company":
        query = GET_COMPANY(offset, 10, search, client).loc.source.body;
        result = await networkCallBack(query);
        setCompanyState(result?.all_company_master);
        return {
          options: [{ value: 0, label: "Select All" }, ...result?.company_master],
          hasMore:
            array?.length + result?.company_master?.length <
            result?.count[0]?.count,
        };
      case "property":
        if (props?.addRole?.company?.length === 0) {
          setLoading(null);
          return { options: [] };
        }
        query =
          props?.addRole?.company?.[0]?.value === 0
            ? GET_PROPERTY_SELECT_ALL
            : GET_PROPERTY;
        const company = {
          company_id:
            props?.addRole?.company?.[0]?.value === 0
              ? companyState?.map((x) => x?.value)
              : props?.addRole?.company?.map((x) => x?.value),
          offset,
          limit: 10,
          search,
          client,
        };
        result = await networkCallBack(query, company);
        if(result?.property?.length > 0 ){
          return {
            options: [{ value: 0, label: "Select All" }, ...result?.property],
            hasMore:
              array?.length + result?.property?.length < result?.count[0]?.count,
          };
        }
        else{
          return {
            options: [{ value: 0, label: "Select All" }],
            hasMore:
              array?.length + result?.property?.length < result?.count[0]?.count,
          };
        }
      break;
      default:
        return { options: [] };
    }
  };
  const networkCallBack = async (query, variable = {}) => {
    let payload = {
      query,
      variables: variable,
    };

    const options = await NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        setLoading(null);
        return main;
      })
      .catch((error) => {
        setLoading(null);
        // alert.setSnack({
        //       ...alert,
        //       open: true,
        //       severity: AlertProps.severity.error,
        //       msg: "Some Thing Went Wrong",
        //       vertical: AlertProps.vertical.top,
        //       horizontal: AlertProps.horizontal.center,
        // });
        return null;
      });

    return options;
  };

  return (
    <div className={classes.dialogParent}>
      <div
        style={{
          height: size?.height - 230,
          overflow: "auto",
          padding: "0px 5px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SelectBox
              isRequired
              placeholder="Select Module"
              label="Module"
              options={props?.moduleOption ?? []}
              onChange={(value) => props?.updateState("module", value)}
              value={props?.addRole?.module}
              isError={props?.addRole?.error?.module?.length > 0}
              errorMessage={props?.addRole?.error?.module}
            />
          </Grid>
          {props?.addRole?.module?.label?.length > 0 && (
            <>
              <Grid item xs={12}>
                <SelectBox
                  isRequired
                  placeholder="Select Role"
                  label="Role"
                  options={[]}
                  onChange={(value) => props?.updateState("role", value)}
                  value={props?.addRole?.role}
                  key={JSON.stringify(props?.addRole?.module)}
                  isError={props?.addRole?.error?.role?.length > 0}
                  errorMessage={props?.addRole?.error?.role}
                  loading={loading === "role"}
                  isPaginate
                  debounceTimeout={800}
                  loadOptions={(search, array) =>
                    loadOptions(search, array, "role")
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SelectBox
                  isRequired
                  placeholder="Select Company"
                  label="Company"
                  options={[]}
                  onChange={(value) => props?.updateState("company", value)}
                  isMulti
                  value={props?.addRole?.company}
                  isError={props?.addRole?.error?.company?.length > 0}
                  errorMessage={props?.addRole?.error?.company}
                  loading={loading === "company"}
                  isPaginate
                  debounceTimeout={800}
                  loadOptions={(search, array) =>
                    loadOptions(search, array, "company")
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SelectBox
                  isRequired
                  placeholder="Select Property"
                  label="Property"
                  isMulti
                  options={[]}
                  onChange={(value) => props?.updateState("property", value)}
                  value={props?.addRole?.property}
                  key={JSON.stringify(props?.addRole?.company)}
                  isError={props?.addRole?.error?.property?.length > 0}
                  errorMessage={props?.addRole?.error?.property}
                  loading={loading === "property"}
                  isPaginate
                  debounceTimeout={800}
                  loadOptions={(search, array) =>
                    loadOptions(search, array, "property")
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
        <br />
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box className={classes.addRoleButton}>
            <Box>
              <Button
                className={classes.cancelBtn}
                onClick={props?.addrolePopFunction}
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                className={classes.saveBtn}
                onClick={props?.addroleFunction}
              >
                {props?.addRole?.isEdit ? "Update" : "Save"}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
