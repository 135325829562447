import React from 'react';
import { Box, Grid, Typography } from "@mui/material";
import { AlertDialog } from "../../../components";
import { DocumentViewer } from "../../../components/fileViewer";
import { ProofView } from "../../../components/proofComponent/section/proofView";
import { useStyles } from "../styles";
import { convertTimeUtcToZoneCalander } from '../../../utils'
export const KYCDetails = ({ data = {} }) => {
    const classes = useStyles()
    const [openPdf, setOpenPdf] = React.useState(false)
    const [currentImage, setCurrentImage] = React.useState("");
    const [currentImageType, setCurrentImageType] = React.useState("");
    const setOpenPdfBtn = (data) => {
        setOpenPdf(true)
        setCurrentImage(data?.[0])
        setCurrentImageType(data?.[1])
    }
    return (
        <Box className={classes.box} marginTop="16px">
            <Typography className={classes.title} marginBottom={"14px"}>ID Details</Typography>
            {
                data?.know_your_client?.length > 0 ? data?.know_your_client?.map((val) => {
                    return (
                        <>
                            <Grid container rowSpacing={1} columnSpacing={3}>
                                <Grid item xs={12}>
                                    <Typography className={classes.title2}>{val?.identification_masterByID?.label}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box marginTop="16px">
                                        <Typography className={classes.heading}>Name As In Proof</Typography>
                                        <Typography className={classes.sub}>{val?.name}</Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={2}>
                                    <Box marginTop="16px">
                                        <Typography className={classes.heading}>ID Proof Number</Typography>
                                        <Typography className={classes.sub}>{val?.number}</Typography>
                                    </Box>

                                </Grid>

                                <Grid item xs={2}>
                                    <Box marginTop="16px">
                                        <Typography className={classes.heading}>Valid From</Typography>
                                        <Typography className={classes.sub}>
                                            {(val?.valid_from && val?.valid_from !== "") ? convertTimeUtcToZoneCalander(val?.valid_from) : "-"}

                                        </Typography>
                                    </Box>


                                </Grid>
                                <Grid item xs={2}>
                                    <Box marginTop="16px">
                                        <Typography className={classes.heading}>Valid To</Typography>
                                        <Typography className={classes.sub}>
                                            {(val?.valid_to && val?.valid_to !== "") ? convertTimeUtcToZoneCalander(val?.valid_to) : "-"}
                                        </Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={2}>
                                    <Box marginTop="16px">
                                        <Typography className={classes.heading}>Issuing Country</Typography>
                                        <Typography className={classes.sub}>{val?.country_master?.label}</Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={2}>
                                    <Box marginTop="16px">
                                        <Typography className={classes.heading}>Mandatory Verify</Typography>
                                        <Typography className={classes.sub}>{val?.is_mandatory ? "Yes" : "No"}</Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={12}>
                                    <Box marginTop="16px">
                                        <Typography className={classes.heading}>Document</Typography>
                                        <Grid container spacing={2}>
                                            {
                                                val?.url?.map((val) => {
                                                    return (
                                                        <Grid item xs={2}>
                                                            <ProofView
                                                                name={val?.name}
                                                                src={val?.src}
                                                                fileType={val?.type}
                                                                setOpenPdfBtn={setOpenPdfBtn}
                                                                disable={true} />
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Box>

                                </Grid>
                                <AlertDialog isNormal isnotTitle component={
                                    currentImageType === "img" ?
                                        <div style={{ display: 'flex' }}>
                                            <img src={currentImage} alt='' style={{ objectFit: 'cover', width: '444px' }} />
                                        </div>
                                        :
                                        <div>
                                            <DocumentViewer url={currentImage} />
                                        </div>

                                } open={openPdf} onClose={() => setOpenPdf(false)} />
                            </Grid>
                        </>
                    )
                })
                    :
                    <Typography className={classes.nodataText}>No data Found</Typography>
            }

        </Box>
    )
}