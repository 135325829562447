import { useApolloClient } from '@apollo/client';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import Tab from '@mui/material/Tab';
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import NoInspectionData from '../../../assets/NoInspectionData';
import { CartItemCard, GeneralCard, LoadingSection, SearchFilter } from "../../../components";
import { config } from '../../../config';
import { AlertContext } from "../../../contexts";
import { GET_TEMPLATE } from '../../../graphql/inspectionMapping';
import { NetworkCall } from '../../../networkcall';
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from '../../../utils';
import { TabTitleList } from "../../../utils/inspectionItemMapping";
import BulkTemplate from './bulkTemplate';
import { InspectionMappingComponentStyles } from "./styles";


const AddInspectionItem = ({ t, details = {}, onClose = () => false, reload = () => false, property_id = "" }) => {
    const classes = InspectionMappingComponentStyles()
    const client = useApolloClient();
    const [value, setValue] = useState('1');
    const [inspectionItems, setInspectionItems] = React.useState([]);
    const [bulkItems, setBulkItems] = React.useState([]);
    const [templateView, setTemplateView] = useState(false)
    const [BulkData, setBulkData] = useState(false);
    const [offset, setOffset] = useState(0);
    const [offsett, setOffsett] = useState(0);
    const alert = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(true);
    const [search, setSearch] = React.useState("");
    const [searchTemp, setSearchTemp] = React.useState("")

    //get inspection items
    const getInspectionItems = (search, offset, reload) => {
        const payload = {
            company_id: details?.company_id,
            search: search,
            offset: offset,
            limit: 10,
        }
        NetworkCall(
            `${config.api_url}/inspection_items/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {

                const data = response.data.data?.map((val) => {
                    return {
                        id: val?.id,
                        Name: val?.name,
                        image: "/images/Group 98362.svg",
                        count: 0,
                        is_active: false,
                        name: val?.name
                    }
                })

                setInspectionItems(reload ? data : inspectionItems.concat(data));
                setLoading(false)

            }).catch((err) => {
                console.log(err)

            })

    }
    //get template
    const getTemplates = (search, offset, reload) => {
        client.query({
            query: GET_TEMPLATE,
            fetchPolicy: 'network-only',
            variables: {
                client: localStorage.getItem(LocalStorageKeys.clinetID),
                property_id: property_id,
                search: search,
                offset: offset,
                limit: 10
            }
        }).then((response) => {

            const data = response.data.inspection_template?.map((val) => {
                return {
                    ...val,
                    totalItems: val?.count?.[0]?.count_id,
                    is_active: false,
                    count: 0,
                    sub_items: val?.sub_items?.map((i) => {
                        return {
                            ...i,
                            name: i?.inspection_item.is_inventory ? i?.inspection_item?.inventory?.name : i?.inspection_item?.name,
                            Name: i?.inspection_item.is_inventory ? i?.inspection_item?.inventory?.name : i?.inspection_item?.name,
                            image: "/images/Group 98362.svg",
                            count: i?.count ? parseInt(i?.count) : 0,
                            is_active: true,
                            tempName: val?.Name
                        }
                    })
                }
            })

            setBulkItems(reload ? data : bulkItems.concat(data));
            setLoading(false)

        }).catch((err) => {
            console.log(err)


        })
    }
    //initial load
    React.useEffect(() => {
        getInspectionItems("", 0, true)
        getTemplates("", 0, true)
        // eslint-disable-next-line
    }, [])
    //To view Bulk Template
    const handletemplateView = (item) => {
        setBulkData(item)
        setTemplateView(true)
    }
    //HandleChane for tab
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //onclick inspection item
    const changeInspactionItems = (id, bool) => {
        const edited = inspectionItems.map((val) =>
            val.id === id
                ? {
                    ...val,
                    is_active: bool,
                    open: bool ? true : false,
                    count: bool ? 1 : 0
                }
                : val
        );
        setInspectionItems(edited)
    }
    //add inspectionitem
    const addInspectionItem = (id, type) => {

        const edited = inspectionItems.map((val) => {
            let count = type ? val?.count + 1 : val?.count - 1;

            let selected = val.id === id
                ? {
                    ...val,
                    count: count > 0 ? count : 0,
                    is_active: count > 0 ? true : false,
                    open: count > 0 ? true : false,
                }
                : val

            return selected;
        });

        setInspectionItems(edited)
    }
    //update subitems
    const updateSubItemsStatus = (val, bool, id) => {
        return val.map((i) => {

            let selected = i.id === id
                ? {
                    ...i,
                    is_active: bool,
                }
                : i
            return selected;
        })

    }
    //removetemplate
    const removeTemplate = (id, bool) => {
        const edited = bulkItems.map((val) => {
            return {
                ...val,
                sub_items: updateSubItemsStatus(val?.sub_items, bool, id)
            }
        });

        setBulkItems(edited)
    }
    //onclick inspection template
    const changeTemplate = (id, bool) => {
        const edited = bulkItems.map((val) =>
            val.id === id
                ? {
                    ...val,
                    is_active: bool,
                    sub_items: val?.sub_items?.map((val) => {
                        return {
                            ...val,
                            is_active: true
                        }
                    })
                }
                : val
        );
        setBulkItems(edited)
        setTemplateView(false)
    }
    //update subitems
    const updateSubItems = (val, type, id) => {
        return val.map((i) => {
            let count = type ? i?.count + 1 : i?.count - 1;
            let selected = i.id === id
                ? {
                    ...i,
                    count: count > 0 ? count : 0,
                    is_active: count > 0 ? true : false,
                    open: count > 0 ? true : false,
                }
                : i
            return selected;
        })
    }
    //add inspectionitem
    const addTemplet = (id, type) => {

        const edited = bulkItems.map((val) => {
            return {
                ...val,
                sub_items: updateSubItems(val.sub_items, type, id)
            }
        });

        setBulkItems(edited)

    }
    //more
    const fetchMoreData = () => {
        setOffset(offset + 10, search);
        getInspectionItems("", offset + 10, search);
    }
    //more template
    const fetchMoreDataTemplate = () => {
        setOffsett(offsett + 10, searchTemp);
        getTemplates("", offsett + 10, searchTemp);
    }
    //delete all
    const deleteAll = () => {
        getInspectionItems("", 0, true)
        getTemplates("", 0, true)
    }

    //onSubmit
    const onSubmit = () => {

        const inspection_items = inspectionItems.filter((val) => val?.is_active).map((val) => {
            return {
                name: val?.name,
                inspection_item: val?.id,
                count: val?.count
            }
        });

        const template = bulkItems?.filter((val, index) => val?.is_active).flatMap((p) => (p?.sub_items)).filter((val) => val?.is_active).map((r) => {
            return {
                name: r?.Name,
                inspection_item: r?.inspection_item?.id,
                count: r?.count
            }
        })




        const payload = {
            company_id: details?.company_id,
            unit_id: details?.unit_id,
            property_id: details?.property_id,
            offset: offset,
            inspection_items: [...inspection_items, ...template],
            limit: 10,
        }
        NetworkCall(
            `${config.api_url}/inspection_item_mapping/add`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Item Successfully Added",
                });
                onClose();
                reload()
            }).catch((err) => {
                console.log(err)
            })
    }



    return (
        <Box>
            {
                loading ?
                    <LoadingSection top="30vh" bottom='30vh' message={"Fetching ..."} />
                    :
                    <Grid container>
                        <Grid item xs={12} md={6} className={`${classes.inspectionItemList}`}>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            {TabTitleList.map((x) => {
                                                return (
                                                    <Tab label={x.title} value={x.value} className={`${classes.tabTitle}`} />
                                                )
                                            })}

                                        </TabList>
                                    </Box>
                                    <TabPanel value="1" className={classes.tab}>
                                        <Box p={1}>
                                            <SearchFilter
                                                value={search}
                                                handleChange={(val) => {
                                                    setSearch(val)
                                                    getInspectionItems(val, 0, true)
                                                }}
                                                placeholder={t("searchInspectionItem")} />
                                        </Box>
                                        <InfiniteScroll
                                            dataLength={inspectionItems?.length ?? ""}
                                            next={fetchMoreData}
                                            hasMore={true}
                                            height={380}
                                        >
                                            <Box p={1}>
                                                {inspectionItems.map((val) => {
                                                    return (
                                                        <GeneralCard
                                                            list={val}
                                                            buttonText="Add"
                                                            handleChange={() => changeInspactionItems(val?.id, true)}
                                                            increaseQty={() => addInspectionItem(val?.id, true)}
                                                            decreaseQty={() => addInspectionItem(val?.id, false)}
                                                            component={"increment"}
                                                        />

                                                    )

                                                })}
                                            </Box>
                                        </InfiniteScroll>

                                    </TabPanel>
                                    <TabPanel value="2" className={classes.tab}>
                                        <Box p={1}>
                                            <SearchFilter
                                                value={searchTemp}
                                                handleChange={(val) => {
                                                    setSearchTemp(val)
                                                    getTemplates(val, 0, true)
                                                }}
                                                placeholder={t("searchTemplateBaseItem")} />
                                        </Box>
                                        <InfiniteScroll
                                            dataLength={bulkItems?.length ?? ""}
                                            next={fetchMoreDataTemplate}
                                            hasMore={true}
                                            height={380}
                                        >
                                            <Box p={1}>
                                                {bulkItems.map((item) => {
                                                    return (
                                                        <GeneralCard
                                                            list={item}
                                                            handleChange={() => changeTemplate(item?.id)}
                                                            increaseQty={() => addTemplet(item?.id)}
                                                            decreaseQty={() => addTemplet(item?.id)}
                                                            view={true}
                                                            totalItems={item?.totalItems}
                                                            buttonText='Remove'
                                                            type="template"
                                                            hideBtn={true}
                                                            component={"increment"}
                                                            isDot
                                                            handleView={() => handletemplateView(item)}
                                                        />

                                                    )

                                                })}
                                            </Box>
                                        </InfiniteScroll>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>

                            {
                                templateView ?

                                    <Box>
                                        <BulkTemplate
                                            BulkData={[BulkData]}
                                            crossicon={true}
                                            buttonName={"Add"}
                                            handleClick={() => changeTemplate(BulkData?.id, true)}
                                            handleClose={() => setTemplateView(false)}
                                        />


                                    </Box> :

                                    <Box p={1}>



                                        {
                                            (inspectionItems?.filter((val) => val?.is_active)?.length > 0 ||
                                                bulkItems?.filter((val, index) => val?.is_active).flatMap((p) => (p?.sub_items))?.length > 0)

                                                ?
                                                <Stack direction="row" justifyContent="space-between" padding="0px 12px 12px 12px">
                                                    <Typography className={classes.cartTitle}>Inspection Items Preview</Typography>
                                                    <Typography className={classes.delete} onClick={deleteAll}>Delete All</Typography>
                                                </Stack>
                                                :
                                                // <Stack alignItems="center" direction="row" justifyContent="center">
                                                <Box height="380px" justifyContent="center" display={"flex"} alignItems="center">
                                                    <center>
                                                        <NoInspectionData />
                                                        <Typography className={classes.cartTitle}>No Inspection Item Added</Typography>
                                                        <Typography className={classes.propertyTitle}>Add Inspection Items From The Master</Typography>
                                                    </center>
                                                </Box>
                                            // </Stack>
                                        }
                                        {
                                            (inspectionItems?.filter((val) => val?.is_active)?.length > 0 ||
                                                bulkItems?.filter((val, index) => val?.is_active).flatMap((p) => (p?.sub_items))?.length > 0) &&
                                            <Box  >
                                                <Box height="380px" overflow="auto" >
                                                    {
                                                        inspectionItems?.filter((val) => val?.is_active)?.length > 0 &&
                                                        <Typography className={classes.propertyTitle}>GENERAL ITEMS</Typography>
                                                    }


                                                    {
                                                        inspectionItems?.filter((val) => val?.is_active).map((val) => {
                                                            return (
                                                                <CartItemCard
                                                                    list={val}
                                                                    increaseQty={() => addInspectionItem(val?.id, true)}
                                                                    decreaseQty={() => addInspectionItem(val?.id, false)}
                                                                    removeitem={() => changeInspactionItems(val?.id, false)}
                                                                />
                                                            )
                                                        })
                                                    }
                                                    {/* {bulkItems?.filter((val, index) => val?.is_active).flatMap((p) => (p?.sub_items))?.length > 0 &&
                                                        <Typography className={classes.propertyTitle} sx={{ marginTop: "12px", marginBottom: "12px" }}>TEMPLATE NAME</Typography>
                                                    } */}

                                                    {
                                                        bulkItems?.filter((val, index) => val?.is_active).map((val) => {
                                                            return (
                                                                <Box>
                                                                    <Typography sx={{ marginTop: "12px", textTransform: "uppercase" }} className={classes.propertyTitle}>{val?.Name}</Typography>
                                                                    {
                                                                        val?.sub_items?.filter((val) => val?.is_active)?.filter((p) => p.is_active).map((r) => {

                                                                            return (

                                                                                <CartItemCard
                                                                                    list={r}
                                                                                    increaseQty={() => addTemplet(r?.id, true)}
                                                                                    decreaseQty={() => addTemplet(r?.id, false)}
                                                                                    removeitem={() => removeTemplate(r?.id, false)}
                                                                                />
                                                                            )
                                                                        })
                                                                    }

                                                                </Box>
                                                            )
                                                        })
                                                    }


                                                </Box>
                                                <Box p={1}>
                                                    <Button fullWidth
                                                        disabled={(inspectionItems?.filter((val) => val?.is_active)?.length > 0 ||
                                                            bulkItems?.filter((val, index) => val?.is_active).flatMap((p) => (p?.sub_items))?.length > 0) ? false : true}
                                                        variant="contained" onClick={onSubmit}>Map New Item</Button>
                                                </Box>
                                            </Box>
                                        }


                                    </Box>

                            }



                        </Grid>
                    </Grid>
            }
        </Box>
    )
}
export default withNamespaces("inspection")(AddInspectionItem)