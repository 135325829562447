import { addDays, subDays } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { config } from "../config";
import { NetworkCall } from "../networkcall";
/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
  message: {
    some_thing_went_wrong: "Some Thing Went Wrong",
    please_fill_all_mandatory_field: "Please fill all mandatory field"
  }
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "authToken",
  version: "version",
  profileID: "profileID",
  roleID: "roleID",
  activeRole: "activeRole",
  activeRoleModuleId: "activeRoleModuleId",
  userProfileID: "profileID",
  role: "role",
  slug: "slug",
  clinetID: "clinetID",
  role_switch_token: "role_switch_token",
  permission: "permission",
  modules: "modules",
  deviceToken: "device_token",
  selectedCompany: "selectedCompany",
  prev_history_key: "prev_history_key",
  refreshed: "refreshed"
};
export let RolesData = {
  manager: "Manager",
  securityManager: "Security Manager",
  communityManager: "Community Manager",
  salesManager: "Sales Manager",
  leaseManager: "Lease Manager",
  clientAdmin: "Client Admin",
  propertyLeaseSales: "Property Lease & Sales",
  propertyManagement: "Property Management",
  visitorsAndSecurity: "Visitors And Security",
  communityAndHOA: "Community and HOA",
  inspectionsManagement: "Inspections Management",
  clientManagement: "Client Management",
  facilityManagement: "Facility Management",
  financeAndAccounting: "Finance And Accounting",
  vendorPortal: "Vendor Portal",
  brokerPortal: "Broker Portal",
  residentAndTenantPortal: "Resident and Tenant Portal",
  landlordAndOwnerPortal: "Landlord and Owner Portal",
  tenantsAndResidents: "Tenants and Residents",
  securityGuard: "Security Guard",
  salesAgent: "Sales Agent",
  Inspector: "Inspector",
  fieldService: "Field Service",
  serviceProvider: "Service Provider",
  Township: "Township",
  landlordAndOwner: "Landlord and Owner",
  gateWay: "GateWay"




  // agent: "Agent",
  // tenant: "Tenant",
  // guard:"Security Guard"
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */

export let AssetMasterType = {
  profile: 1,
  image: 2,
  video: 3,
  pdf: 4,
  threeimage: 5,
};
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export const findImageSize = (file) =>
  new Promise((resolve, reject) => {
    var _URL = window.URL || window.webkitURL;
    let img = new Image();
    img.src = _URL.createObjectURL(file);
    img.onload = (e) => {
      resolve({
        width: e.target.width,
        height: e.target.height,
      });
    };
    img.onerror = (e) => {
      reject(e);
    };
  });

export const checkWebsiteUrl = (url) => {
  // eslint-disable-next-line
  let validURL =
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  return validURL.test(url);
};

export const quotationStatus = {
  won: "Won",
  notIntrseted: "Not Interested",
  draft: "draft",
  active: "Active",
};
export let enumName = {
  unitCategory: "unit_category",
  userDefined: "User Defined",
  rentalValue: "Rental Value",
  percentage: "Percentage",
  perUOM: "Per UOM",
  daily: "Daily",
  residential: "Residential",
  commercial: "Commercial",
  storage: "Storage",
  mixed: "Mixed",
  advertisingSpace: "Advertising Space",
  commercialUnit: "Commercial Unit",
  coWorking: "Co-working",
  communityUnit: "Community Unit",
  residentialUnit: "Residential Unit",
  parkingUnit: "Parking Unit",
  storageSpace: "Storage Space",
  revenue_base: "Revenue based lease",
  bedSpace: "Bed Space",
  sale: "Sale",
  land: "Land",
  plot: "Plot",
  singleUnit: "Single Unit",
  multiUnit: "Multi-Unit",
  multiUnitFloor: "Multi-Unit by Floors",
  multiUnitBlockFloor: "Multi-Unit by Blocks",
  storageUnitStorageBin: "Multi-Unit by Storage Bin",
  storageUnitStorageTypeStorageBin: "Multi-Unit by Storage Type",
  pending: "Pending",
  monthly: "Monthly",
  renewal: "Renewal",
  termination: "Termination",
  rejected: "Rejected",
  cancelled: "Cancelled",
  approved: "Approved",
  propertyOwner: "Property Owner",
  milestoneBased: "Milestone Based",
  onCompletion: "On Completion",
  weekly: "Weekly",
  prepaid: "Prepaid",
  hourly: "Hourly",
  yearly: "Yearly",
  halfYearly: "Half Yearly",
  quarterly: "Quarterly",
  manage: "Manage",
  lease: "Lease",
  facilities: "Facilities",
};

export let enumLabel = {
  /**
  Pending Agreement Request Table
  */
  pendingART: "Pending",
  /**
  Pending Agreement Request View
  */
  pendingARV: "Waiting For Approval",
  /**
  Rejected Agreement Request
  */
  rejectedAR: "Declined",
  /**
  Cancelled Agreement Request
  */
  cancelledAR: "Cancelled",
  /**
  Approved Agreement Termination Request
  */
  approvedATR: "Terminated",
  /**
  Approved Agreement Renewal Request
  */
  approvedARR: "Converted",
};

export const calculateDate = (date, value = 0) => {
  let result = new Date(
    moment(date, "DD-MM-YYYY").add(value, "days").toISOString()
  );
  return result;
};

export const subtractDate = (date, value = 0) => {
  let result = new Date(
    moment(date, "DD-MM-YYYY").subtract(value, "days").toISOString()
  );
  return result;
};

export let quotationGen = {
  start: "In the Beginning",
  end: "In the End",
};

export const TabContant = {
  Tabs: [
    {
      month: "max",
      value: "0",
    },
    {
      month: "5Y",
      value: "5y",
    },
    {
      month: "3Y",
      value: "3y",
    },
    {
      month: "1Y",
      value: "1y",
    },
    {
      month: "6M",
      value: "6m",
    },
    {
      month: "3M",
      value: "3m",
    },
    {
      month: "1M",
      value: "1m",
    },
  ],

  TabYearly: [
    {
      label: "2022",
      value: 2022,
    },
    {
      label: "2021",
      value: 2021,
    },
  ],
};
export let sourse = [
  {
    label: "Website Form",
    value: "Website Form",
  },
  {
    label: "Website Chat",
    value: "Website Chat",
  },
  {
    label: "Offline",
    value: "Offline",
  },
  {
    label: "Sales Agent",
    value: "Sales Agent",
  },
];

export const DURATION_PERIOD = [
  { label: "Day", value: "Day" },
  { label: "Month", value: "Month" },
  { label: "Year", value: "Year" },
];

/**
 * Function to get ENUM dynamically
 * @param enumTypes `[String]`
 * @returns `Object`
 */
export const enumSelect = async (enumTypes) => {
  let result;

  const payload = { enumName: enumTypes, };

  await NetworkCall(
    `${config.api_url}/enum`,
    NetWorkCallMethods.post,
    payload, null, true, false
  ).then((res) => {
    result = res?.data?.data;
  }).catch((err) => {
    console.log(err)
  })

  return result;
};

//networkcall back
export const networkCallback = async (
  givenPayload,
  url,
  handleLoading,
  returnFullResponse = false
) => {
  const payload = {
    ...givenPayload,
  };
  const options = await NetworkCall(
    `${config.api_url}/${url}`,
    NetWorkCallMethods.post,
    payload,
    true,
    true,
    false
  )
    .then((response) => {
      let main = response?.data?.data;
      handleLoading && handleLoading(null);
      return returnFullResponse ? response : main;
    })
    .catch(() => {
      handleLoading && handleLoading(null);
      return null;
    });
  return options;
};

export const enum_types = {
  utility_period_type: "utility_period_type",
  amenities_type: "amenities_type",
  notification_category_type: "notification_category_type",
  account_relationship: "account_relationship",
  purpose_global_type: "purpose_global_type",
  revenue_type: "revenue_type",
  apply_grace_period_type: "apply_grace_period_type",
  urgent_type: "urgent_type",
  l_period: "l_period",
  lead_source: "lead_source",
  unit_payment_period: "payment_value_type",
  furnishing_type: "furnishing_type",
  orientation_type: "orientation_type",
  unit_breakup_type: "unit_breakup_type",
  property_payment_period: "property_payment_period",
  general_maintenance_type: "general_maintenance_type",
  preferred_mode_contact_type: "preferred_mode_contact_type",
  delivery_status_type: "delivery_status_type",
  security_request_type: "security_request_type",
  swift_type: "swift_type",
  domestic_value_type: "domestic_value_type",
  parking_type: "parking_type",
  bank_routing_type: "bank_routing_type",
  area_metric_type: "area_metric_type",
  company_lease_period: "company_lease_period",
  pricing_type: "pricing_type",
  company_payment_period: "company_payment_period",
  company_payment_gateway: "company_payment_gateway",
  company_registration: "company_registration",
  rera_type: "rera_type",
  tax_code: "tax_code",
  vat_code_type: "vat_code_type",
  vat_item_code_type: "vat_item_code_type",
  vehicle_type: "vehicle_type",
  unit_category: "unit_category",
  unit_status_type: "unit_status_type",
  agreement_status: "agreement_status",
  unit_vacancy_status: "unit_vacancy_status",
  billing_cycle: "billing_cycle",
  billing_cycle_date: "billing_cycle_date",
  pricing_period_type: "pricing_period_type",
  pet_policy_type: "pet_policy_type",
  relationship_type: "relationship_type",
  emergency_relationship_type: "emergency_relationship_type",
  access_gate_type: "access_gate_type",
  parking_area_type: "parking_area_type",
  payment_terms: "payment_terms",
  bank_account_type: "bank_account_type",
  payment_value_type: "payment_value_type",
  utilties_provider: "utilties_provider",
  property_hierarchy: "property_hierarchy",
  quote_validity_type: "quote_validity_type",
  payment_mode: "payment_mode",
  turnover_type: "turnover_type",
  auto_renewal_escalation: "auto_renewal_escalation",
  follow_up_type: "follow_up_type",
  sex_type: "sex_type",
  amenity_period: "amenity_period",
  cheque_status: "cheque_status",
  notifications_content_type: "notifications_content_type",
  proof_type: "proof_type",
  agreement_request_action: "agreement_request_action",
  value_basis_type: "value_basis_type",
  resource_period_enum: "resource_period_enum",
  vehicle_master_type_enum: "vehicle_type",
  compensated_invoices_type_enum: "compensated_invoices_type_enum",
  reservation_status_type: "reservation_status_type",
  reservation_status_type_new: "reservation_status_type_new",
  listing_type: "listing_type",
  vehicle_master_period_enum: "amenity_period",
  invoice_charge_type: "invoice_charge_type",
  leave_request_type: "leave_request_type",
  half_day_type: "half_day_type",
  free_text_invoice_type: "free_text_invoice_type",
  unit_execution_status: "unit_execution_status",
  work_order_status: "work_order_status",
  inspection_item_type: "inspection_item_type",
  inspection_item_condition: "inspection_item_condition",
  quote_status: "quote_status",
  inspection_request_type_enum: "inspection_request_type_enum",
  resource_leave_type: "resource_leave_type",
  security_status_enum_type: "security_status_enum_type"
};

export let billGenENUM = {
  start_payment: "Start of the Payment Period",
  last_day: "Last Day of the Month",
  custom_date: "Custom Date",
  mid_date: "Mid of the Month",
  first: "1st Day of the Month",
};

export const tabelName = {
  parking_slot: "parking_slot",
  security_request: "security_request",
  access_control: "access_control",
  access_gates_master: "access_gates_master",
};

export const enumValueType = {
  manage: "Manage",
  total: "Total",
};
export const title = [
  {
    label: "unit category",
    value: "unit category",
  },
  {
    label: "unit type",
    value: "unit type",
  },
];

export const Gender = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const BackendRoutes = {
  communityDashboardStats: "/dashboard/community/stats",
  communityDashboardResidents: "/dashboard/community/residents",
  communityDashboardOwners: "/dashboard/community/owners",
  communityDashboardDiscussions: "/dashboard/community/disscussions",
  communityDashboardActivities: "/dashboard/community/activities",
};

export const CalendarOptions = {
  today: "Today",
  yesterday: "Yesterday",
  thisWeek: "This Week",
  lastWeek: "Last Week",
  thisMonth: "This Month",
  lastMonth: "Last Month",
  thisQuarter: "This Quarter",
  lastQuarter: "Last Quarter",
  thisYear: "This Year",
  lastYear: "Last Year",
  custom: "Custom",
};

export const QuarterCalc = {
  thisQuarter: [
    {
      months: [0, 1, 2],
      year: 0,
      dateStart: 1,
      dateEnd: 31,
      monthStart: 0,
      monthEnd: 2,
    },
    {
      months: [3, 4, 5],
      year: 0,
      dateStart: 1,
      dateEnd: 30,
      monthStart: 3,
      monthEnd: 5,
    },
    {
      months: [6, 7, 8],
      year: 0,
      dateStart: 1,
      dateEnd: 30,
      monthStart: 6,
      monthEnd: 8,
    },
    {
      months: [9, 10, 11],
      year: 0,
      dateStart: 1,
      dateEnd: 31,
      monthStart: 9,
      monthEnd: 11,
    },
  ],
  lastQuarter: [
    {
      months: [0, 1, 2],
      year: 1,
      dateStart: 1,
      dateEnd: 31,
      monthStart: 9,
      monthEnd: 11,
    },
    {
      months: [3, 4, 5],
      year: 0,
      dateStart: 1,
      dateEnd: 31,
      monthStart: 0,
      monthEnd: 2,
    },
    {
      months: [6, 7, 8],
      year: 0,
      dateStart: 1,
      dateEnd: 30,
      monthStart: 3,
      monthEnd: 5,
    },
    {
      months: [9, 10, 11],
      year: 0,
      dateStart: 1,
      dateEnd: 30,
      monthStart: 6,
      monthEnd: 8,
    },
  ],
};

export const activeOptions = [
  {
    label: "Active",
    value: true,
  },
  {
    label: "In Active",
    value: false,
  },
];

//fonts function
// export const Regular =
//   "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif";
// export const Bold = "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif";
// export const SemiBold =
//   "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif";
// export const ExtraBold =
//   "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif";

export const Regular = "'NunitoSans-Regular'";
export const Bold = "'NunitoSans-Bold'";
export const SemiBold = "'NunitoSans-SemiBold'";
export const ExtraBold = "'NunitoSans-ExtraBold'";
export const Italic = "'NunitoSans-Italic'"

export const EnableDisableOption = [
  { value: true, label: "Enable" },
  { value: false, label: "Disable" },
];

export const RequiredNotRequiredOption = [
  { value: true, label: "Required" },
  { value: false, label: "Not Required" },
];

export const YesNoOption = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const SalePaymentPeriod = [
  { value: enumName.prepaid, label: enumName.prepaid },
  { value: enumName.milestoneBased, label: enumName.milestoneBased },
  { value: enumName.onCompletion, label: enumName.onCompletion },
];

/**
 * Lease/Manage Payment Period
 */
export const LMPaymentPeriod = [
  { value: enumName.prepaid, label: enumName.prepaid },
  // { value: enumName.daily, label: enumName.daily },
  { value: enumName.weekly, label: enumName.weekly },
  { value: enumName.monthly, label: enumName.monthly },
  { value: enumName.quarterly, label: enumName.quarterly },
  { value: enumName.halfYearly, label: enumName.halfYearly },
  { value: enumName.yearly, label: enumName.yearly },
  { value: enumName.milestoneBased, label: enumName.milestoneBased },
  { value: enumName.onCompletion, label: enumName.onCompletion },
];

export const PaymentPeriod = {
  sale: SalePaymentPeriod,
  lease: LMPaymentPeriod,
  manage: LMPaymentPeriod,
};

const getIncreasedValue = (numA, numB) => 100;

const getDecresedValue = (numA, numB) => ((numA - numB) / numA) * 100;

const getRecommandedValue = (numA, numB) => ((numA - numB) / numA) * 100;

const type = {
  MAX: getIncreasedValue, // actual < input
  REC: getRecommandedValue, // actual < input
  MIN: getDecresedValue, // actual > input
};

export let max = type["MAX"];
export let rec = type["REC"];
export let min = type["MIN"];



export const ampmOptions = [
  { label: "AM", value: "AM" },
  { label: "PM", value: "PM" }
]
export const mobileApps =
  [
    {
      id: 0,
      src: "images/switch/c1.svg",
      name: "Tenant",
      link: null
    },
    {
      id: 1,
      src: "images/switch/c2.svg",
      name: "Guard",
      link: `${config.security_guard_url}`
    },
    {
      id: 2,
      src: "images/switch/c3.svg",
      name: "Agent",
      link: null
    },
    {
      id: 3,
      src: "images/switch/c4.svg",
      name: "Inspector",
      link: `${config.inspector_url}`
    },
    {
      id: 4,
      src: "images/switch/c5.svg",
      name: "Owner",
      link: `${config.owner_url}`
    },
    {
      id: 5,
      src: "images/switch/c6.svg",
      name: "Field",
      link: null
    },
    {
      id: 6,
      src: "images/switch/c7.svg",
      name: "Resident",
      link: `${config.resident_url}`
    },
    {
      id: 7,
      src: "images/switch/c8.svg",
      name: "Stay",
      link: null
    },
    {
      id: 8,
      src: "images/switch/c9.svg",
      name: "Resource",
      link: null
    }
  ]

export const unitCategoryMaster = {
  "1": {
    "id": 1,
    "ref_name": "Land",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  "2": {
    "id": 2,
    "ref_name": "Residential Unit",
    "field_permission": {
      "is_balcony_area": true, "is_terrace_area": true, "is_furnishing": true,
      "is_orientation": true, "is_total_rooms": true, "is_total_baths": true,
      "is_balconies": true, "is_pet_policy": true, "is_parent_unit_id": false,
      "is_carpet_area": true, "is_build_up_area": true, "is_total_area": true,
      "is_listing_status": true
    }
  },
  "3": {
    "id": 3,
    "ref_name": "Marketing Space",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": false
    }
  },
  "4": {
    "id": 4,
    "ref_name": "Commercial Unit",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  "5": {
    "id": 5,
    "ref_name": "Plot",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  "6": {
    "id": 6,
    "ref_name": "Bed Space",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": true,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": true, "is_parent_unit_id": true,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  "7": {
    "id": 7,
    "ref_name": "Storage Space",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  "8": {
    "id": 8,
    "ref_name": "Parking Unit",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  "9": {
    "id": 9,
    "ref_name": "Work Space",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": true,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": true,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  "10": {
    "id": 10,
    "ref_name": "Room Space",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": true,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": true, "is_parent_unit_id": true,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  "11": {
    "id": 11,
    "ref_name": "Facility Space",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": false
    }
  }
}

export const ListingTypeOptions = [
  { label: "None", value: "None", },
  { label: "Private", value: "Private", },
  { label: "Public", value: "Public", },
];

export const addDaysWithLess = (date, count) => {
  let dates = date;
  let addedDays = addDays(dates, count)
  let lessedDays = subDays(addedDays, 1)
  return lessedDays;
}

export const subUserTimeOffset = (date) => {
  console.log(date)
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffset).toISOString()
};

export const normalizeDate = (date) => {
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const app_env_1 = {
  "dev": {
    name: "Development", tag_color: "#FF4B4B"
  },
  "int": {
    name: "Internal", tag_color: "#78B1FE"
  },
  "sandbox": {
    name: "Sandbox", tag_color: "#FF9340"
  },
  "live": {
    name: "Live", tag_color: "#5AC782"
  }
}
export const app_env = (key) => {
  switch (key) {
    case "dev":
      return { name: "Development", tag_color: "#FF4B4B" }
    case "int":
      return { name: "Internal", tag_color: "#78B1FE" }
    case "sandbox":
      return { name: "Sandbox", tag_color: "#FF9340" }
    case "live":
      return { name: "Live", tag_color: "#5AC782" }

    default:
      return false
  }
}

export const InAndOutOption = [
  { label: "In", value: "In" },
  { label: "Out", value: "Out" }
]

export const StayTypetOption = [
  { label: "Short", value: "Short" },
  { label: "Long", value: "Long" }
]

export const InOutStatusVW = [
  { label: "Yet To Checkout", value: "Yet To Checkout" },
  { label: "Checked Out", value: "Checked Out" }
]

export const supporDropdown = [
  {
    name: "Write To Support Team",
    type: "email",
    link: `${config.support_team_email}`
  },
  {
    name: "Get Live Support",
    type: "link",
    link: `${config.support_live_support}`
  },
  {
    name: "View Help FAQs",
    type: "link",
    link: `${config.support_view_help_faq}`
  },
  {
    name: "Blogs",
    type: "link",
    link: `${config.support_blogs}`
  },
  {
    name: "Site Terms",
    type: "link",
    link: `${config.support_site_terms}`
  },
  {
    name: "Privacy Policy",
    type: "link",
    link: `${config.support_privacy_policy}`
  },
  {
    name: "About propGOTO",
    type: "link",
    link: `${config.support_about_propgoto}`
  }
]

export const CorrespondenceStatusColor = (id) => {
  switch (id) {
    case 1:
      return "#78B1FE"
    case 2:
      return "#5AC782"
    default:
      return "#78B1FE"
  }
}