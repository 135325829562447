import * as React from "react"

export const ActivePropertyIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18.597}
        height={23.246}
        {...props}
    >
        <path
            d="M2.615 0A2.629 2.629 0 0 0 0 2.615v18.016a2.629 2.629 0 0 0 2.615 2.615h13.367a2.629 2.629 0 0 0 2.618-2.615v-12.2a.872.872 0 0 0-.255-.616l-.009-.009-7.55-7.551A.872.872 0 0 0 10.17 0Zm8.427 2.976 4.579 4.579h-3.707a.859.859 0 0 1-.872-.872ZM9.3 11.623a.753.753 0 0 1 .471.163l2.724 2.146a.758.758 0 0 1 .289.6v3.248a.205.205 0 0 1-.218.218H6.028a.205.205 0 0 1-.218-.218v-3.248a.761.761 0 0 1 .291-.6l2.723-2.146a.758.758 0 0 1 .476-.163Zm-.581 2.906a.581.581 0 0 0-.581.581v1.162a.581.581 0 0 0 .581.581H9.88a.581.581 0 0 0 .581-.581V15.11a.581.581 0 0 0-.581-.581Z"
            fill="#50cbc7"
        />
    </svg>
)

