import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { AlertContext, BackdropContext } from "../../../contexts";
import { multiImageUpload, assestType } from "../../../utils/common";
import { AlertProps } from "../../../utils";
import { useStyles } from "./style";
import { ImageUpload } from "../../../assets/imageupload";
import { ImageCard } from "../../../components";

export const SingleImgUpload = (props) => {
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const updateState = async (data) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    try {
      let propsData = {
        companyId: props?.companyId,
        type: assestType?.General_Images,
      };
      const image = await multiImageUpload(data, propsData);
      props?.selectedImageURL?.push(image);
      props?.updateImageURL(props?.selectedImageURL?.map((val) => val));
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
    } catch (err) {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Not Edited!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  const deleteImg = (e) => {
    props?.handleFile(e);
  };

  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={1.8}>
          <label className="btn label">
            <div className={classes.fileroot}>
              <input
                accept={props?.accept}
                type="file"
                name="myImage"
                style={{ display: "none" }}
                onChange={(e) => {
                  updateState(e.target.files);
                }}
                multiple
              />
              <center>
                <ImageUpload />
                <Typography className={classes.uploadTittle}>
                  Upload image here
                </Typography>
              </center>
            </div>
          </label>
        </Grid>
        <Grid item xs={10.2} style={{ display: "flex" }} className={classes.imagleBox}>
          {props?.selectedImageURL?.map((val, index) => {
            return (
              <>
                <Box className={classes.imgContainer}>
             
                    <ImageCard
                    image={val?.url ?? ""}
                    onClick={() => deleteImg(val)}
                    imageName={val?.name ?? ""}
                    storage={val?.size ?? ""}
                    type={val?.type ?? ""}
                  />                 
                </Box>
              </>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};
