import { Avatar, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import React from "react";
import { withNamespaces } from "react-i18next";
import { AggrementComponentStyles } from "./styles";

const CustomerInfoCard = ({ data = {}, t }) => {
  // classes
  const classes = AggrementComponentStyles();
  return (
    <Card className={`${classes.cardHeader}`}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        {/* image */}
        <Grid item xs={1.5}>
          <div className={`${classes.BorderRight}`}>
            <Avatar
              src={data?.image}
              alt="Profile Pic"
              className={`${classes.image}`}
            />
          </div>
        </Grid>
        {/* title */}
        <Grid item xs={10.5} className={classes.customerDetails}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={`${classes.title}`}>
                {data?.title ?? ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {/* name */}
            <Grid item xs={3}>
              <Typography className={`${classes.label}`}>{t("name")}</Typography>
              <Typography className={`${classes.text}`} noWrap>
                {data?.name ?? "-"}
              </Typography>
            </Grid>
            {/* account */}
            {data?.account_number &&
              <Grid item xs={3}>
                <Typography className={`${classes.label}`}>
                  {t("accountNumber")}
                </Typography>
                <Typography className={`${classes.text}`} noWrap>
                  {data?.account_number ?? "-"}
                </Typography>
              </Grid>
            }
            {/* Email Id */}
            <Grid item xs={3}>
              <Typography className={`${classes.label}`}>  {t("email_id")}</Typography>
              <Typography className={`${classes.text}`} noWrap>
                {data?.email ?? "-"}
              </Typography>
            </Grid>
            {/* Mobile Number */}
            <Grid item xs={3}>
              <Typography className={`${classes.label}`}>
                {t("mobile_no")}
              </Typography>
              <Typography className={`${classes.text}`} noWrap>
                {data?.phone ?? "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card >
  );
};
export default withNamespaces("agreement")(CustomerInfoCard);
