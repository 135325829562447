import { Avatar, Box, Button, Typography } from "@mui/material";
import { useStyles } from "../styles";

export const SelectedListCard = ({ data = {}, onRemove = () => false }) => {
    const classes = useStyles();
    return (
        <Box className={classes.selectedListCardRoot} display="flex" alignItems="center">
            <Box>
                <Avatar sx={{ borderRadius: "4px" }} src={data?.url} variant="square" />

            </Box>
            <Box flexGrow={1} marginLeft="12px">
                <Typography className={classes.btnTitle}>{data?.label}</Typography>
                <Typography className={classes.email}>{data?.label1}</Typography>
            </Box>
            <Box>
                <Button
                    sx={{
                        '&:hover': {
                            background: "white"
                        }
                    }}
                    color="error" onClick={onRemove}>Remove</Button>
            </Box>
        </Box>
    )

}