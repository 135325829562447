/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient } from '@apollo/client';
import { Badge, Box, Button, Divider, Grid, IconButton, Stack } from '@mui/material';
import React from 'react';
import FilterIMG from '../../assets/filter';
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { GET_DOMESTIC_HELPER_LIST_WITH_FUNCTION, GET_PROFESSION_MASTER_LIST } from '../../graphql/queries';
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, LocalStorageKeys } from '../../utils';
import { useStyles } from "./style";
import { UPDATE_DOMESTIC_HELPER } from '../../graphql/mutations';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';
import { Heading, Path, Type, StatusOptionList } from '../../utils/domesticHelper/domesticHelperUtils';
import { loadOptions } from '../../utils/asyncPaginateLoadOptions';
import { withNamespaces } from 'react-i18next';

const DomesticHelperList = ({ t }) => {

    const classes = useStyles()
    const debounce = UseDebounce()
    const client = useApolloClient()
    const history = useHistory()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)


    // useState
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [list, setList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState({ status: [true] })
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [propertyData, setPropertyData] = React.useState([])
    const [selectedProperty, setSelectedProperty] = React.useState({})
    const [permissions, setPermission] = React.useState({})

    // useEffect to get company list for company switcher when loading the screen
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
                let properties =
                    company?.selected?.property?.map((x) => {
                        return {
                            value: x.id,
                            label: x.name
                        }


                    })
                setPropertyData([
                    // allProperties,
                    ...properties
                ]);
                setSelectedProperty(properties?.[0])
            }
        }
        // eslint-disable-next-line
    }, [auth])

    // useEffect to get list using selected company and filter data when loading the screen
    React.useEffect(() => {
        setPage(1)
        if (selectedProperty?.value) { getList() }
    }, [selectedProperty, filterData])

    // Function to get list based on the input data
    const getList = (offset = 0, limit = 10, search = "") => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        client.query({
            query: GET_DOMESTIC_HELPER_LIST_WITH_FUNCTION(filterData?.profession?.value), fetchPolicy: "network-only",
            variables: {
                clientID: localStorage.getItem(LocalStorageKeys.clinetID) ?? "",
                status: (!filterData?.status || filterData?.status?.length === 0) ?
                    [true, false] : filterData?.status,
                companyID: selectedCompany?.value,
                property_id: selectedProperty?.label === "All Properties" ? selectedProperty?.value : [selectedProperty?.value],
                search,
                offset,
                limit,
                profession: filterData?.profession?.value
            }
        }).then((r) => {
            setList({
                data: r?.data?.domestic_helper_master,
                totalRowsCount: r?.data?.count?.[0]?.count
            })
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((e) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("SomeThingWentWrong")
            })
        })
    }

    // Function to show logo or initial
    const returnValue = (value = "", delimiter = "") => value ? value : delimiter

    // Set row data for table
    const Rows = React.useCallback(list?.data?.map((_) => {
        let j
        try {
            j = {
                id: _?.id,
                imageUrl: {
                    logo: returnValue(_?.helper_image, "-"),
                    name: _?.name ? returnValue(_?.name?.charAt(0)) : ""
                },
                name: _?.name ?? "-",
                gender: _?.gender ?? "-",
                mobileNo: _?.domestic_helper_addressByID?.[0]?.mobile_no ?
                    ((_?.domestic_helper_addressByID?.[0]?.mobile_code &&
                        (_?.domestic_helper_addressByID?.[0]?.mobile_code + " ")) +
                        _?.domestic_helper_addressByID?.[0]?.mobile_no) : "-",
                profession: _?.profession_master?.name,
                idType: _?.identification_masterByID?.type ?? "-",
                idNumber: _?.identification_no ?? "-",
                status: _?.is_active ? "Active" : "Inactive",
                barred: _?.is_barred ? "Yes" : "No",
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("SomeThingWentWrong")
            })
        }
        return j
    }), [list])

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        let properties =
            value?.property?.map((x) => {
                return {
                    value: x.id,
                    label: x.name
                }


            })
        setPropertyData([
            // allProperties,
            ...properties
        ]);
        setSelectedProperty(properties?.[0])
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getList(0, limit, e)
    }

    // Function to open add form
    const handleAdd = () => {
        history.push({
            pathname: Routes.domesticHelperCreateEdit,
            state: {
                main: {
                    company: selectedCompany,
                    companyID: selectedCompany?.value,
                    selectedProperty: selectedProperty

                }
            }
        })
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        if (type === "view") { history.push(Routes.domesticHelperView + "?domesticHelperID=" + data?.id) }
        else if (type === "edit") {
            history.push({
                pathname: Routes.domesticHelperCreateEdit,
                state: {
                    main: {
                        company: selectedCompany,
                        companyID: selectedCompany?.value,
                        domesticHelperID: data?.id,
                        selectedProperty: selectedProperty
                    }
                }
            })
        }
        else if (type === "active" || type === "delete") { handleStatusDelete(type, data) }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getList(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getList(0, value, searchText)
    }

    // Function to toggle status and delete domestic helper
    const handleStatusDelete = async (type, data) => {
        const currentDate = new Date().toISOString()
        const profileID = localStorage.getItem(LocalStorageKeys.profileID)

        const payload = {
            is_active: type === "active" ? !data?.data?.is_active : undefined,
            is_delete: type === "delete" ? true : undefined,
            updated_at: currentDate ?? undefined,
            updated_by: profileID ?? undefined
        }

        client.mutate({
            mutation: UPDATE_DOMESTIC_HELPER, fetchPolicy: 'network-only',
            variables: {
                domesticHelperID: data?.id,
                payload: payload
            }
        }).then((r) => {
            getList()
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: `Registered Workers ${type === "delete" ? `Deleted` : `Updated`} Successfully.!!!`,
            })
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("SomeThingWentWrong")
            })
        })
    }
    //property change function
    const onPropertyChange = (val) => {
        setSelectedProperty(val)
    }
    const render = () => {
        return (
            <div >
                <Subheader hideBackButton={true} title={t("RegisteredWorkers")}
                    select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)}
                    onPropertyChange={(e) => {
                        onPropertyChange(e)
                    }}
                    propertyValue={selectedProperty}
                    selectProperty
                    propertyOptions={propertyData}
                />
                <div className={classes.root}>
                    <Grid container className={classes.content} spacing={1}>
                        <Grid item xs={4}>
                            <SearchFilter value={searchText} placeholder={t("SearchDomesticHelper")}
                                handleChange={(value) => handleSearch(value)} />
                        </Grid>
                        <Grid item xs={8}>
                            <Box display={"flex"} justifyContent={"end"}>
                                <Stack direction="row" spacing={2}
                                    divider={<Divider orientation="vertical" flexItem />}>
                                    <IconButton onClick={() => setFilterDrawer(!filterDrawer)}
                                        className={classes.filterButton}>
                                        <Badge variant="dot" color="primary"
                                            invisible={!(filterData.status?.length > 0)}>
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                    </IconButton>
                                    <Button variant="contained" className={classes.button}
                                        onClick={handleAdd}>
                                        {t("AddRegisteredWorkers")}
                                    </Button>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TableWithPagination
                                heading={Heading}
                                rows={Rows}
                                path={Path}
                                showpagination={true}
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleTableIcon}
                                onClick={() => console.log("")}
                                tableType="no-side"
                                dataType={Type}
                                handlePagination={handleTablePagination}
                                handleChangeLimit={handleTablePageLimit}
                                totalRowsCount={list?.totalRowsCount}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 290px)'}
                                view={permissions?.read}
                                edit={permissions?.update}
                                delete={permissions?.delete} />
                        </Grid>
                    </Grid>
                    <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                        onApply={(value) => setFilterData(value)}
                        components={[
                            {
                                component: "toggleButton",
                                value: filterData?.status,
                                options: StatusOptionList,
                                isMulti: true,
                                state_name: "status",
                                label: t("Status")
                            },
                            {
                                component: "select",
                                value: filterData?.profession,
                                options: [],
                                isMulti: false,
                                label: t("Profession"),
                                state_name: "profession",
                                loadOptions: (search, array, handleLoading) => loadOptions(
                                    search, array, handleLoading,
                                    GET_PROFESSION_MASTER_LIST, 'profession_master',
                                ),
                                debounceTimeout: 800,
                                isPaginate: true,
                            }
                        ]} />
                </div>
            </div>
        )
    }
    return (
        <Box>
            {accessCheckRender(render, permissions)}
        </Box>
    )
}

export default withNamespaces("domesticHelper")(DomesticHelperList)
