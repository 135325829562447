import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useStyles } from './style';
import { ParkingCalenderIcon } from '../../assets';

export const LayoutSwitch = ({ select = "", setselect = () => false, box = false }) => {
    const classes = useStyles(box)

    return (
        <Box className={!box ? classes.box : false}>
            <Stack spacing={1} direction={"row"} className={classes.switch} m={1} alignItems={"center"} justifyContent="center">
                <Box className={select === "nonlist" ? classes.selectedBtn : classes.notSelectedBtn}
                    onClick={() => setselect("nonlist")} >
                    <ParkingCalenderIcon fill={select === "nonlist" ? "#FFFFFF" : "#98A0AC"} />
                </Box>
                {box ?
                    <Box className={select === "P" ? classes.selectedBtn : classes.notSelectedBtn}
                        onClick={() => setselect("P")}>
                        <Box className={select === "P" ? classes.iconSelectBorder : classes.iconUnSelectBorder}>
                            <Typography className={select === "P" ? classes.iconSelect : classes.iconUnSelect}>
                                {"P"}
                            </Typography>
                        </Box>
                    </Box>
                    :
                    <Box className={select === "list" ? classes.selectedBtn : classes.notSelectedBtn}>
                        <MenuIcon onClick={() => setselect("list")} className={classes.icon} />
                    </Box>
                }
            </Stack>
        </Box>

    )
}