import React from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";
import { useStyles } from "./style";
import { CustomDatePicker } from "../../../dashboard/components"
import { useWindowDimensions } from "../../../../utils";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
export const CalendarDetail = (props) => {
  //   classes
  const classes = useStyles(props);
  const size = useWindowDimensions();
  return (
    <div className={classes.card}>
      <Box className={classes.expensesHeader} display="flex">
        <Box>
          <Typography variant="subtitle2" className={classes.Expensestitle}>
            {props?.title}
          </Typography>
        </Box>
        <Box flexGrow={1}>
          <div style={{ float: "right" }}>
            <CustomDatePicker getDate={props?.getDate} />
          </div>
        </Box>
      </Box>
      <Divider />
      <Box style={{ height: size?.height - 380, marginTop: "4px", overflow: "overlay" }}>
        <Grid
          container
          className={classes.grid}
        >
          {props?.data?.length > 0 ? (
            props?.data?.map((_) => {
              return (
                <Grid item xs={12}>
                  <Grid container spacing={3} className={classes.cardHandover}>
                    <Grid item xs={2} alignItems="center">
                      <Typography className={classes.num}>
                        {12 + " AM"}
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <div className={classes.activityCard}>
                        <div className={classes.details}>
                          <Typography className={classes.cardTitle}>{_?.title}</Typography>
                          <Typography className={classes.cardSubTitle}>{_?.description}</Typography>
                        </div>
                        <div className={classes.comment}>
                          <ChevronRightIcon htmlColor="#4E5A6B" />
                        </div>
                      </div>
                    </Grid>
                    <Box style={{ margin: "4px 0px", backgroundColor: "#E4E8EE", height: "1px", width: "100%" }} />
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12} className={classes.noData}>
              <Typography className={classes.title}>{"No data found"}</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
};
