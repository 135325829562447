import dotenv from "dotenv";
dotenv.config();

let config = {};
config.api_url = `${process.env.REACT_APP_BACKEND_API}`;
config.graphql_url = `${process.env.REACT_APP_GRAPHQL_API}`;
config.graphql_web_socket_url = `${process.env.REACT_APP_SOCKET}`;
config.socket = `${process.env.REACT_APP_SOCKET}`;
// config.graphql_web_socket_url = `${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_PROTOCAL}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_HOST}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_PORT}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_BASE_URL}`;
config.slug = `${process.env.REACT_APP_SLUG}`;
config.tenantId = `${process.env.REACT_APP_TENANT_ID}`;
config.alertKey = `${process.env.REACT_APP_ALERT_KEY}`;
config.googleMapApiKey = `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;
config.weather = `${process.env.REACT_APP_WEATHER_API}`;
config.weatherId = `${process.env.REACT_APP_WEATHER_API_KEY}`;
config.authapi = `${process.env.REACT_APP_AUTH_API}`;
// config.websocket = ``;
config.app_x_build = `${process.env.REACT_APP_X_BUILD}`;
config.app_env = `${process.env.REACT_APP_ENV}`;
config.resident_url = `${process.env.REACT_APP_RESIDENT}`;
config.security_guard_url = `${process.env.REACT_APP_SECURITY_GUARD}`;
config.owner_url = `${process.env.REACT_APP_OWNER}`;
config.inspector_url = `${process.env.REACT_APP_INSPECTOR}`;
config.support_team_email = `${process.env.REACT_APP_SUPPORT_SUPPORT_TEAM_EMAIL}`
config.support_live_support = `${process.env.REACT_APP_SUPPORT_LIVE_SUPPORT}`
config.support_view_help_faq = `${process.env.REACT_APP_SUPPORT_VIEW_HELP_FAQ}`
config.support_blogs = `${process.env.REACT_APP_SUPPORT_BLOGS}`
config.support_site_terms = `${process.env.REACT_APP_SUPPORT_SITE_TERMS}`
config.support_privacy_policy = `${process.env.REACT_APP_SUPPORT_PRIVACY_POLICY}`
config.support_about_propgoto = `${process.env.REACT_APP_SUPPORT_ABOUT_PROPGOTO}`

export { config };
