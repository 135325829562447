import { Box, Stack, Typography, Dialog, DialogContent, Divider } from '@mui/material'
import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { calendarDropDownStyle } from './style'

export const CalendarDropDown = ({ open = {}, selected = {}, showAll = () => false, setDropdownValue = () => false, right = "", select = "" }) => {
    const calendarType = right ? ["Weekly", "Monthly"] : ["Daily", "Weekly", "Monthly"]
    const classes = calendarDropDownStyle(right)
    React.useEffect(() => {
        if (select === "P") {
            setDropdownValue("Daily")
        }
        // eslint-disable-next-line
    }, [select])
    return (
        <Box
            className={right ? classes.dropdown2 : classes.dropdown}
        >

            <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"space-between"} onClick={showAll} >
                <Typography className={classes.value}> {select === "P" ? "Daily" : selected} </Typography>
                <Box pl={2}>

                    {
                        select === "P" ? <KeyboardArrowDownIcon /> :
                            open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                    }
                </Box>
            </Stack>

            {select === "P" ? "" : <Dialog
                open={open}
                onClose={showAll}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={right ? classes.customDialogForParking : classes.customDialog}>
                <DialogContent>
                    <Box className={classes.content}>
                        <Stack spacing={1} alignItems={"left"}>
                            {
                                calendarType.map((e, index, length) => {
                                    return (
                                        <>
                                            <Typography className={selected === e ? classes.selected : classes.listText}
                                                onClick={() => setDropdownValue(e)}> {e} </Typography>
                                            {length.length - 1 !== index && <Divider />}
                                        </>
                                    )
                                })
                            }
                        </Stack>
                        {/* <Box mt={1} mb={1}>
                          
                        </Box> */}
                    </Box>
                </DialogContent>
            </Dialog>}
        </Box>
    )
}