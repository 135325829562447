import React, { useMemo, useState } from 'react';
import { LoadingSection, PropertyTabs, Subheader } from '../../components';
import { useStylesCreation } from "../propertcreation/createproperty/style";
import { useHistory } from 'react-router-dom';
import {
    VendorBasicDetails,
    PaymentAndTaxDetails,
    RegistrationDetails,
    VendorAssets
} from './tabs';
import CityProperty from '../../assets/cityproperty';
import ImagesProperty from '../../assets/imagesproperty';
import ContractProperty from '../../assets/contractproperty';
import {
    listErrorState,
    constructPayload,
    GET_VENDOR_DETAILS,
    constructVendorDetails
} from './utils';
import { accessCheckRender, AlertProps, getRoutePermissionNew } from '../../utils';
import { AlertContext, AuthContext } from '../../contexts';
import { useApolloClient } from '@apollo/client';
import { networkCallback } from '../companyCreation/utils';
import { Routes } from '../../router/routes';

export const VendorCreation = (props) => {

    const { isEdit } = props;
    const VENDOR_ID = props?.match?.params?.id;
    const history = useHistory()
    const COMPANY_ID = history?.location?.state?.companyID;
    const client = useApolloClient();

    const classes = useStylesCreation();
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(null);
    const [mapLoad, setMapLoad] = useState(!Boolean(isEdit))
    const [value, setValue] = React.useState(1);
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getVendor()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getVendor = () => {
        if (isEdit) {
            getVendorDetails(VENDOR_ID)
        }
    }

    const getVendorDetails = (id) => {
        setLoading('screen')
        client.query({
            query: GET_VENDOR_DETAILS,
            fetchPolicy: "network-only",
            variables: {
                id
            }
        }).then(res => {
            constructVendorDetailsData(res?.data?.vendor_master?.[0])
        }).catch(err => {
            console.log(err);
            setLoading(null);
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something went wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        })
    }

    const constructVendorDetailsData = (val) => {
        let vendorDetails = constructVendorDetails(val);
        setData(vendorDetails)
        setLoading(null);
    }

    const tabTittle = useMemo(() => (
        [
            {
                label: 'Company Basic Details',
                sub: 'Enter Profile, Address & Contact Details',
                imgSelect: value > 1 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
                value: 1,
                className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                label: 'Payment & Tax Details',
                sub: 'Upload assets like Image, 360 Image, Vid..',
                imgSelect: value > 2 ? <img src="/images/correct_icon.svg" alt='icon' /> : <ImagesProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
                value: 2,
                className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                label: 'Registration details',
                sub: 'Enter Authority, Utilities Details',
                imgSelect: value > 3 ? <img src="/images/correct_icon.svg" alt='icon' /> : <ContractProperty color={value === 3 ? '#5078E1' : '#98A0AC'} />,
                value: 3,
                className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                label: 'Assets',
                sub: 'Upload assets like Image, Videos',
                imgSelect: <ImagesProperty color={value === 4 ? '#5078E1' : '#98A0AC'} />,
                value: 4,
                className: value === 4 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 4 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 4 ? classes.subtabtitle1 : classes.subtabtitle
            },
        ]
        // eslint-disable-next-line
    ), [value])

    const updateState = (stateKey, parentKey, key, value) => {
        let updateData;

        if (parentKey) {
            updateData = {
                ...data?.[stateKey], [parentKey]: {
                    ...data?.[stateKey]?.[parentKey], [key]: value
                }
            }
        }
        else {
            updateData = {
                ...data?.[stateKey], [key]: value
            }
        }

        setData({
            ...data, [stateKey]: updateData
        })

    }

    const onChangeParticularState = (key, value) => {
        setData({
            ...data,
            [key]: {
                ...data?.[key],
                ...value
            }
        })
    }

    const validation = (value) => {
        if (value === 4) return true
        let listCheck = listErrorState[value - 1];
        let stateKey = ['vendorProfile', 'paymentTaxDetails', 'registrationDetails'];
        let returnCheckError = checkError(listCheck, stateKey[value - 1]);
        return !Object.keys(returnCheckError)?.length
        // return true

    }

    const checkError = (object, stateKey) => {
        let value, errorState = {};

        Object.keys(object)?.map(parentKey => {
            object?.[parentKey]?.map(key => {
                value = data?.[stateKey]?.[parentKey]?.[key?.key];
                let errorMsg = key?.errorMsg ?? "This field is required";
                checkComponentError(value, errorState, stateKey, parentKey, key?.key, errorMsg)
                return key
            })
            return parentKey
        })
        //lat lng throws error is null
        if (errorState?.vendorProfile?.address?.latitude || errorState?.vendorProfile?.address?.longitude) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please choose location in map and proceed",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        }
        setError(errorState)
        return errorState
    }

    const checkComponentError = (value, errorState, stateKey, parentKey, key, errorMsg) => {

        if (
            !value ||
            //to check Object
            (value?.constructor === Object && !Object?.keys(value)?.length) ||
            //to check Date
            (value?.constructor === Date && !value) ||
            //to check Array
            (value?.constructor === Array && !value?.length) ||
            //to check string
            (value?.constructor === String && !value?.trim()?.length)
        ) {
            constructErrorState(errorState, stateKey, parentKey, key, errorMsg);
        }

    }

    const constructErrorState = (errorState, stateKey, parentKey, key, errorMsg) => {
        if (!errorState[stateKey]) {
            errorState[stateKey] = {}
        }
        if (!errorState[stateKey][parentKey]) {
            errorState[stateKey][parentKey] = {}
        }
        if (!errorState[stateKey][parentKey][key]) {
            errorState[stateKey][parentKey][key] = {}
        }
        errorState[stateKey][parentKey][key]['error'] = true;
        errorState[stateKey][parentKey][key]['errorMsg'] = errorMsg;
    }

    const previous = () => {
        setValue(prevValue => prevValue - 1);
        if (!isEdit) {
            setMapLoad(false)
        }
    };

    const next = () => {
        if (validation(value)) {
            setValue(prevValue => prevValue + 1)
        }
    }

    const save = async () => {
        setLoading('save')
        const { vendorProfile, paymentTaxDetails, registrationDetails, assets } = data;
        let payload = constructPayload(vendorProfile, paymentTaxDetails, registrationDetails, assets, COMPANY_ID?.value);
        if (isEdit) {
            payload.id = VENDOR_ID;
            payload.deleteAssets = assets?.deletedImages?.length ? assets?.deletedImages : [];
        }

        let result = await networkCallback(payload, '/vendor/upsert', setLoading, true);

        if (result?.data?.type === "success") {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: `${isEdit ? result?.data?.data : result?.data?.data?.message}`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        }
        else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: `Unable to create`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        }
        history.push({
            pathname: Routes.vendorListing
        })
    }

    const checkSave = (type) => {
        if (isEdit) {
            if (permission.update) {
                type === "previous" && previous()
                type === "next" && next()
                type === "save" && save()
            } else {
                alert.setSnack({
                    ...alert, open: true, msg: "Access denied. Contact your administrator.",
                    severity: AlertProps.severity.error,
                })
            }
        } else if (permission.create) {
            type === "previous" && previous()
            type === "next" && next()
            type === "save" && save()
        } else {
            alert.setSnack({
                ...alert, open: true, msg: "Access denied. Contact your administrator.",
                severity: AlertProps.severity.error,
            })
        }
    }

    const render = () => {
        return <>
            <Subheader
                title={isEdit ? data?.vendorProfile?.profileDetails?.vendorName : "Create New Vendor"}
                goBack={() => history.goBack(-1)}
                previousAndNextBtn={true}
                previousBtn={() => checkSave("previous")}
                nextBtn={() => checkSave("next")}
                previousBtnDisabled={value <= 1 && true}
                nextBtnDisabled={value === 4 ? true : false}
            />
            {
                loading === "screen" ? (
                    <LoadingSection
                        bottom={"30vh"}
                        message="Loading Vendor Details..."
                    />
                ) : (
                    <>
                        <PropertyTabs value={value} isHeigth
                            tab1={(
                                <VendorBasicDetails
                                    data={data?.vendorProfile}
                                    error={error?.vendorProfile}
                                    updateState={(parentKey, key, value) => updateState('vendorProfile', parentKey, key, value)}
                                    mapLoad={mapLoad}
                                    companyID={COMPANY_ID}
                                />
                            )}
                            tab2={(
                                <PaymentAndTaxDetails
                                    data={data?.paymentTaxDetails}
                                    error={error?.paymentTaxDetails}
                                    updateState={(parentKey, key, value) => updateState('paymentTaxDetails', parentKey, key, value)}
                                // countryID={data?.vendorProfile?.profileDetails?.vendorCountry?.value}
                                />
                            )}
                            tab3={(
                                <RegistrationDetails
                                    data={data?.registrationDetails}
                                    error={error?.registrationDetails}
                                    updateState={(parentKey, key, value) => updateState('registrationDetails', parentKey, key, value)}
                                />
                            )}
                            tab4={(
                                <VendorAssets
                                    isEdit={isEdit}
                                    data={data?.assets}
                                    error={error?.assets}
                                    updateState={(parentKey, key, value) => updateState('assets', parentKey, key, value)}
                                    onChangeParticularState={onChangeParticularState}
                                />
                            )}
                            tabTittle={tabTittle}
                            showBtns={true}
                            isSaveDisable={value === 4 ? false : true}
                            saveBtnText={isEdit ? "Update" : "Submit"}
                            save={() => checkSave("save")}
                        />


                    </>
                )
            }
        </>
    }



    return (
        <div style={{ position: 'relative' }}>
            {accessCheckRender(render, permission)}
        </div>
    )
}