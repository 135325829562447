import { useApolloClient } from "@apollo/client";
import { Box, Grid } from "@mui/material";
import React, { useContext } from "react";
import { FormGenerator } from "../../../components";
import { getMailstoneTemplate } from "../../../graphql/quotationQueries";
import { enumSelect, enum_types, LocalStorageKeys } from "../../../utils";
import {
  saledataType,
  saleheading,
  salepath,
} from "../../../utils/createQuotationUtils";
import { MileStoneTemplate } from "../../createquotation/component/mileStoneTemplate";
import { LeadDetailsCard } from "./index";
import { useStyles } from "./styles";
import { CreateOppertunityContext } from "../../../contexts/createOppertunityContext";

export const SaleForm = ({ data, updateState, type = "", purpose = "" }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const { dataNew, setDataNew } = useContext(CreateOppertunityContext);
  const [enumValue, setEnum] = React.useState({
    priority: [],
    source: [],
    payment_period: [],
    payment_mode: [],
  });


  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.lead_source,
      enum_types.unit_payment_period,
      enum_types?.urgent_type,
      enum_types?.payment_mode,
    ]);
    setEnum({
      payment_period: result?.payment_value_type
        ?.filter((val) => val?.value !== "Weekly")
        .filter((val) => val?.value !== "Half Yearly")
        .filter((val) => val?.value !== "Yearly")
        .filter((val) => val?.value !== "Quarterly")
        .filter((val) => val?.value !== "Prepaid")
        .filter((val) => val?.value !== "Hourly")
        .filter((val) => val?.value !== "Monthly"),
      source: result?.lead_source,
      priority: result?.urgent_type,
      payment_mode: result?.payment_mode,
    });
  };
  React.useEffect(() => {
    if (dataNew?.delivery_timestone_template?.value) {
      getTemplate(dataNew?.delivery_timestone_template);
    }
    getEnum();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //get template
  const getTemplate = async (value) => {
    client
      .query({
        query: getMailstoneTemplate,
        fetchPolicy: "network-only",
        variables: {
          client: localStorage.getItem(LocalStorageKeys.clinetID),
          template_id: value?.value,
        },
      })
      .then((response) => {
        const data = response?.data?.milestone_template_items?.map((val) => {
          return { ...val, milestone: val?.delivery_milestone_masters?.name };
        });
        // let error = data.error;
        // error.payment_period= "";
        setDataNew({
          ...dataNew,
          payment_period: "Milestone Based",
          delivery_timestone_template: value,
          mailstoneList: data,
          mileStoneName: value?.label,
          payment_period_value:value,
          // error
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const choosePaymentPeriod = (value, list) => {
    if (value?.label === "On Completion") {
      // let error = data.error;
      // error.payment_period = "";
      setDataNew({
        ...dataNew,
        payment_period: value?.value,
        mailstoneList: [],
        mileStoneName: value?.label,
        delivery_timestone_template:"",
        payment_period_value:value,
        // error
      });
    } else {
      if(value?.value?.length > 0){
        getTemplate(value);
      }
    }
  };

  const formSale = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 9,
        lg: 9,
      },
      isActive: true,
      component: "text",
      label: "Opportunity Subject",
      value: data?.oppertunity_subject,
      placeholder: "Opportunity Subject",
      onChange: (value) =>
        updateState("oppertunity_subject", value.target.value),
      error: data?.error?.oppertunity_subject,
      isRequired: true,
      options: [],
      // multiline: true
    },

    {
      size: {
        xs: 12,
        sm: 3,
        md: 3,
        lg: 3,
      },
      isActive: true,
      component: "priority",
      label: "Priority",
      value: data?.priority,
      placeholder: "Priority",
      onChange: (value) => updateState("priority", value),
      error: data?.error?.priority,
      isRequired: true,
      options: enumValue?.priority,
    },
    {
      size: {
        xs: 12,
        sm: 4,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "date",
      label: "Earliest Occupation Date",
      value: data?.earlist_occupation_date,
      placeholder: "Earliest Occupation Date",
      onChange: (value) => updateState("earlist_occupation_date", value),
      error: data?.error?.earlist_occupation_date,
      isRequired: true,
    },
    {
      size: {
        xs: 12,
        sm: 4,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "date",
      label: "Contract Start Date",
      value: data?.contract_start_date,
      placeholder: "Contact Start Date",
      onChange: (value) => updateState("contract_start_date", value),
      error: data?.error?.contract_start_date,
      isRequired: true,
      options: enumValue?.lease_type,
    },

    {
      size: {
        xs: 12,
        sm: 3,
        md: 3,
        lg: 3,
      },
      isActive: true,
      component: "payment",
      label: "Payment Period",
      value: data?.payment_period,
      companyId: data?.company,
      placeholder: "Payment Period",
      onChange: choosePaymentPeriod,
      mileStoneName: data?.mileStoneName,
      error: data?.error?.payment_period,
      isRequired: true,
      mileStoneId: {
        payment_period_value: data?.payment_period_value?.value,
      },
    },
    {
      size: {
        xs: 12,
        sm: 4,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "select",
      label: "Payment Options",
      value: data?.payment_option,
      placeholder: "Payment Options",
      onChange: (value) => updateState("payment_option", value),
      error: data?.error?.payment_option,
      isRequired: true,
      options: enumValue?.payment_mode,
    },
  ];

  return (
    <Box p={1}>
      <Grid container className={classes.leadDetailsRoot}>
        <Grid item xs={3} p={1}>
          <Box p={2} className={classes.leadDetailsRootDivider}>
            <LeadDetailsCard
              data={{
                name: data?.name,
                image:
                  data?.image_url?.length !== 0 ? data?.image_url?.src : null,
                email: data?.email_id,
                mobile: ` ${data.mobile?.mobile_code ?? ""} ${
                  data.mobile?.mobile
                }`,
                no: null,
                type: type,
                purpose: purpose,
                source: data?.source?.value,
              }}
              source_enum={enumValue?.source ?? ""}
            />
          </Box>
        </Grid>
        <Grid item xs={9} p={1}>
          <Box p={2} className={classes.leadDetailsRootDivider}>
            <FormGenerator components={formSale} />
            <Box height="18px" />
            {dataNew?.mailstoneList?.length > 0 && (
              <Grid item xs={6}>
                <MileStoneTemplate
                  dataType={saledataType}
                  rows={dataNew?.mailstoneList}
                  heading={saleheading}
                  path={salepath}
                  title={data?.mileStoneName}
                />
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
