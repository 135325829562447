import makeStyles from "@mui/styles/makeStyles";
export const DomesticContactListStyles = makeStyles((theme) => ({
    list:{
        padding:"15px",
    },
    contactList: {
        backgroundColor: theme.palette.background.tertiary1,
        borderRadius: theme.palette.borderRadius,
        boxShadow: " 0px 0px 16px #00000014",
        padding:"20px",
        height:"calc(100vh - 145px)"

    },
    propertyList:{
        backgroundColor: theme.palette.background.tertiary1,
        borderRadius: theme.palette.borderRadius,
        // boxShadow: " 0px 0px 16px #00000014", 
        height: 'calc(100vh - 145px)'
    }
}))