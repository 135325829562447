import React, { useState, useEffect, useContext } from "react";
import { Grid, Divider, Typography, Box } from "@mui/material";
import {
  FormGenerator,
  SelectBox,
  PricingApprialsComponent,
  TextBox,
  NewDatePicker,
} from "../../../components";
import { againstCompanyStyle } from "../style";
import { JSON1 } from "../common";
import { enumSelect, enum_types } from "../../../utils";
import { loadOptions } from "../../../utils/asyncPaginateLoadOptions";
import { GET_OPTIONS_FOR_PRICING_TABLE_FORM } from "../../../graphql/pricingAppraisal";
import { BackdropContext } from "../../../contexts";

export const ComponentDetails = (props) => {
  const { state, updateState } = props;
  // state
  const [enumValue, setEnumValue] = useState({
    revenue_type: [],
    value_basis_type: [],
  });
  // context
  const backdrop = useContext(BackdropContext);
  //   classes
  const classes = againstCompanyStyle();
  // get enum
  const getEnum = async () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading...",
    });
    const result = await enumSelect([
      enum_types?.revenue_type,
      enum_types?.unit_breakup_type,
    ]);
    setEnumValue({
      revenue_type: result?.revenue_type,
      value_basis_type: result?.unit_breakup_type?.filter(
        (x) => x?.value !== "Rental Value"
      ),
    });
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: "Loading...",
    });
  };
  // useEffect
  useEffect(() => {
    getEnum();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        {/* Choose Component Revenue Type */}
        <Grid item xs={6}>
          <FormGenerator
            components={[
              {
                size: {
                  lg: 12,
                },
                isActive: true,
                component: "toggle",
                label: "Choose Component Revenue Type",
                value: state?.revenueType,
                placeholder: "revenueType",
                onChange: (value) => updateState("revenueType", value),
                error: state?.error?.revenueType,
                isRequired: true,
                options: enumValue?.revenue_type ?? [],
              },
            ]}
          />
        </Grid>
        {/* Component Based On */}
        <Grid item xs={6}>
          <FormGenerator
            components={[
              {
                size: {
                  lg: 12,
                },
                isActive: true,
                component: "toggle",
                label: "Component Based On",
                value: state?.Based_On,
                placeholder: "Based_On",
                onChange: (value) => updateState("Based_On", value),
                error: state?.error?.Based_On,
                isRequired: true,
                options: enumValue?.value_basis_type ?? [],
              },
            ]}
          />
        </Grid>
        {/* Pricing Component Name */}
        <Grid item xs={12}>
          <SelectBox
            isRequired
            label="Pricing Component Name"
            placeholder="Select Pricing Component Name"
            value={state?.component_name}
            onChange={(value) => {
              props?.updateState("component_name", value);
            }}
            isError={state?.error?.component_name?.length > 0}
            errorMessage={state?.error?.component_name}
            key={JSON.stringify(state?.revenueType)}
            isPaginate={state?.revenueType?.length > 0 ? true : false}
            debounceTimeout={800}
            loadOptions={(search, array, handleLoading) =>
              loadOptions(
                search,
                array,
                handleLoading,
                GET_OPTIONS_FOR_PRICING_TABLE_FORM,
                "pricing_component",
                {
                  isActive: true,
                  componentType: state?.componentType,
                  revenueType: state?.revenueType,
                },
                { label: "label", value: "value", unit_count: "unit_count" },
                false,
                false,
                props?.company?.value,
                true
              )
            }
          />
        </Grid>
      </Grid>

      <Box className={classes.divider}>
        <Divider />
      </Box>

      {/* APPRAISAL VALUES */}
      <Typography className={classes.valueTitle}>APPRAISAL VALUES</Typography>
      <Grid container spacing={2} mt={0.4}>
        {JSON1?.map((item) => {
          return (
            <Grid item xs={6}>
              <PricingApprialsComponent
                component="ComponentType"
                data={item}
                marginLeft={"13px"}
                padding={"5px"}
                handleChange={(e, data) => updateState("amountType", data)}
                borderColor={
                  item?.name === state?.amountType?.name ? true : false
                }
                sub
              />
            </Grid>
          );
        })}
        {state?.amountType?.value === "Range" ? (
          <>
            {/* amount min range */}
            <Grid item xs={6}>
              <TextBox
                isrequired
                label="Present Min Amount Value (Range)"
                placeholder="Present Min Amount Value (Range)"
                value={state?.minAmount}
                onChange={(e) => {
                  props?.updateState("minAmount", e.target.value);
                }}
                isError={state?.error?.minAmount?.length > 0}
                errorMessage={state?.error?.minAmount}
                endAdornment={
                  <Typography className={classes.endorement}>
                    UMO / Month
                  </Typography>
                }
              />
            </Grid>
            {/* amount max range*/}
            <Grid item xs={6}>
              <TextBox
                isrequired
                label="Present Max Amount Value (Range)"
                placeholder="Present Max Amount Value (Range)"
                value={state?.maxAmount}
                onChange={(e) => {
                  props?.updateState("maxAmount", e.target.value);
                }}
                isError={state?.error?.maxAmount?.length > 0}
                errorMessage={state?.error?.maxAmount}
                endAdornment={
                  <Typography className={classes.endorement}>
                    UMO / Month
                  </Typography>
                }
              />
            </Grid>
          </>
        ) : (
          <>
            {/* Present Amount Value (Specific) */}
            <Grid item xs={6}>
              <TextBox
                isrequired
                label="Present Amount Value (Specific)"
                placeholder="Present Amount Value (Specific)"
                value={state?.amount}
                onChange={(e) => {
                  props?.updateState("amount", e.target.value);
                }}
                isError={state?.error?.amount?.length > 0}
                errorMessage={state?.error?.amount}
                endAdornment={
                  <Typography className={classes.endorement}>
                    UMO / Month
                  </Typography>
                }
              />
            </Grid>
          </>
        )}
        {/* New Appraisal Amount */}
        <Grid item xs={6}>
          <TextBox
            isrequired
            label="New Appraisal Amount"
            placeholder="New Appraisal Amount"
            value={state?.appraisal_amount}
            onChange={(e) => {
              props?.updateState("appraisal_amount", e.target.value);
            }}
            isError={state?.error?.appraisal_amount?.length > 0}
            errorMessage={state?.error?.appraisal_amount}
            endAdornment={
              <Typography className={classes.endorement}>
                UMO / Month
              </Typography>
            }
          />
        </Grid>
        {/* Effective Start Period */}
        <Grid item xs={6}>
          <NewDatePicker
            isRequired
            value={state?.StartDate ?? ""}
            label="Effective Start Period"
            placeholder="Effective Start Period"
            onChange={(value) => {
              props?.updateState("StartDate", value);
            }}
            minDate={new Date()}
            isError={state?.error?.StartDate?.length > 0}
            errorMessage={state?.error?.StartDate}
          />
        </Grid>
      </Grid>
    </div>
  );
};
