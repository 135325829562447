import * as React from "react"

export const AddExistingAccount = (props) => (
  <svg
    data-name="Group 102577"
    xmlns="http://www.w3.org/2000/svg"
    width={415.931}
    height={235.927}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 54097"
          fill="none"
          d="M0 0h415.931v235.927H0z"
        />
      </clipPath>
      <clipPath id="b">
        <path data-name="Rectangle 54063" fill="none" d="M0 0h8.813v4.009H0z" />
      </clipPath>
      <clipPath id="c">
        <path
          data-name="Rectangle 54064"
          fill="none"
          d="M0 0h27.926v8.049H0z"
        />
      </clipPath>
      <clipPath id="d">
        <path data-name="Rectangle 54065" fill="none" d="M0 0h1.845v2.599H0z" />
      </clipPath>
      <clipPath id="e">
        <path data-name="Rectangle 54066" fill="none" d="M0 0h1.643v3.65H0z" />
      </clipPath>
      <clipPath id="f">
        <path data-name="Rectangle 54067" fill="none" d="M0 0h2.432v2.782H0z" />
      </clipPath>
      <clipPath id="g">
        <path data-name="Rectangle 54068" fill="none" d="M0 0h2.352v2.002H0z" />
      </clipPath>
      <clipPath id="h">
        <path data-name="Rectangle 54069" fill="none" d="M0 0h1.628v2.23H0z" />
      </clipPath>
      <clipPath id="i">
        <path data-name="Rectangle 54070" fill="none" d="M0 0h3.209v2.267H0z" />
      </clipPath>
      <clipPath id="j">
        <path data-name="Rectangle 54071" fill="none" d="M0 0h2.259v2.094H0z" />
      </clipPath>
      <clipPath id="k">
        <path data-name="Rectangle 54072" fill="none" d="M0 0h1.21v2.881H0z" />
      </clipPath>
      <clipPath id="l">
        <path data-name="Rectangle 54073" fill="none" d="M0 0h2.059v2.386H0z" />
      </clipPath>
      <clipPath id="m">
        <path data-name="Rectangle 54074" fill="none" d="M0 0h2.422v2.362H0z" />
      </clipPath>
      <clipPath id="n">
        <path data-name="Rectangle 54075" fill="none" d="M0 0h2.882v2.334H0z" />
      </clipPath>
      <clipPath id="o">
        <path data-name="Rectangle 54076" fill="none" d="M0 0h2.939v2.279H0z" />
      </clipPath>
      <clipPath id="p">
        <path data-name="Rectangle 54077" fill="none" d="M0 0h.457v3.376H0z" />
      </clipPath>
      <clipPath id="q">
        <path data-name="Rectangle 54078" fill="none" d="M0 0h1.162v2.084H0z" />
      </clipPath>
      <clipPath id="r">
        <path data-name="Rectangle 54079" fill="none" d="M0 0h3.112v2.11H0z" />
      </clipPath>
      <clipPath id="s">
        <path data-name="Rectangle 54080" fill="none" d="M0 0h3.2v1.772H0z" />
      </clipPath>
      <clipPath id="t">
        <path data-name="Rectangle 54081" fill="none" d="M0 0h2.779v1.168H0z" />
      </clipPath>
      <clipPath id="u">
        <path data-name="Rectangle 54082" fill="none" d="M0 0h1.611v3.889H0z" />
      </clipPath>
      <clipPath id="v">
        <path data-name="Rectangle 54083" fill="none" d="M0 0h.507v3.467H0z" />
      </clipPath>
      <clipPath id="w">
        <path data-name="Rectangle 54084" fill="none" d="M0 0h.722v2.907H0z" />
      </clipPath>
      <clipPath id="x">
        <path data-name="Rectangle 54085" fill="none" d="M0 0h3.067v.984H0z" />
      </clipPath>
      <clipPath id="y">
        <path data-name="Rectangle 54086" fill="none" d="M0 0h2.182v1.303H0z" />
      </clipPath>
      <clipPath id="z">
        <path data-name="Rectangle 54087" fill="none" d="M0 0h2.684v.696H0z" />
      </clipPath>
      <clipPath id="A">
        <path data-name="Rectangle 54088" fill="none" d="M0 0h1.624v1.338H0z" />
      </clipPath>
      <clipPath id="B">
        <path data-name="Rectangle 54089" fill="none" d="M0 0h1.327v1.322H0z" />
      </clipPath>
      <clipPath id="C">
        <path data-name="Rectangle 54090" fill="none" d="M0 0h3.448v5.249H0z" />
      </clipPath>
      <clipPath id="D">
        <path
          data-name="Rectangle 54091"
          fill="none"
          d="M0 0h9.936v16.923H0z"
        />
      </clipPath>
      <clipPath id="E">
        <path data-name="Rectangle 54092" fill="none" d="M0 0h4.16v3.452H0z" />
      </clipPath>
      <clipPath id="F">
        <path
          data-name="Rectangle 54093"
          fill="none"
          d="M0 0h19.415v1.899H0z"
        />
      </clipPath>
      <clipPath id="G">
        <path
          data-name="Rectangle 54095"
          fill="none"
          d="M0 0h51.296v51.296H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 102576" clipPath="url(#a)">
      <path
        data-name="Path 97411"
        d="m393.762 22.653-31.188.378.493 40.67 31.2.4Z"
        fill="#ebebeb"
      />
      <path
        data-name="Path 97412"
        d="m391.538 24.128-27.291.33.447 36.886 27.3.591Z"
        fill="#fafafa"
      />
      <path
        data-name="Path 97413"
        d="m387.029 30.886-18.119-.471.312 25.782 18.11-.219Z"
        fill="#e0e0e0"
      />
      <path
        data-name="Path 97414"
        d="m369.222 56.197 17.95-13.465-18.262-12.317Z"
        fill="#f5f5f5"
      />
      <path
        data-name="Rectangle 54043"
        fill="#f5f5f5"
        d="M168.4 33.245h16.808v35.977H168.4z"
      />
      <path
        data-name="Rectangle 54044"
        fill="#f5f5f5"
        d="M185.905 71.737h20.653v34.762h-20.653z"
      />
      <path
        data-name="Path 97415"
        d="M209.071 0v32.623h19.611V.708h.7v31.915h18.151V0Z"
        fill="#f5f5f5"
      />
      <path
        data-name="Rectangle 54045"
        fill="#f5f5f5"
        d="M168.4 71.737h16.808v34.762H168.4z"
      />
      <path
        data-name="Rectangle 54046"
        fill="#f5f5f5"
        d="M229.38 71.737h18.15v34.762h-18.15z"
      />
      <path
        data-name="Rectangle 54047"
        fill="#f5f5f5"
        d="M185.905 33.245h20.653v35.977h-20.653z"
      />
      <path
        data-name="Rectangle 54048"
        fill="#f5f5f5"
        d="M229.38 33.245h18.15v35.977h-18.15z"
      />
      <path
        data-name="Rectangle 54049"
        fill="#f5f5f5"
        d="M168.4 107.121h16.808v31.325H168.4z"
      />
      <path
        data-name="Path 97416"
        d="M168.4 0v32.623h16.807V.708h.7v31.915h20.649V0Z"
        fill="#f5f5f5"
      />
      <path
        data-name="Rectangle 54050"
        fill="#f5f5f5"
        d="M185.905 107.121h20.653v31.325h-20.653z"
      />
      <path
        data-name="Rectangle 54051"
        fill="#f5f5f5"
        d="M229.38 107.121h18.15v31.325h-18.15z"
      />
      <path
        data-name="Rectangle 54052"
        fill="#f5f5f5"
        d="M209.072 33.245h19.611v35.977h-19.611z"
      />
      <path
        data-name="Rectangle 54053"
        fill="#f5f5f5"
        d="M209.072 107.121h19.611v31.325h-19.611z"
      />
      <path
        data-name="Rectangle 54054"
        fill="#f5f5f5"
        d="M209.071 71.737h19.611v34.762h-19.611z"
      />
      <path
        data-name="Path 97417"
        d="M50.785 173.946v61.714h92.5v-62.212Z"
        fill="#ebebeb"
      />
      <path
        data-name="Rectangle 54055"
        fill="#f5f5f5"
        d="M54.28 177.777h85.512v15.611H54.28z"
      />
      <path
        data-name="Rectangle 54056"
        fill="#f5f5f5"
        d="M54.28 197.048h85.512v15.612H54.28z"
      />
      <path
        data-name="Rectangle 54057"
        fill="#f5f5f5"
        d="M54.28 216.322h85.512v15.611H54.28z"
      />
      <path
        data-name="Rectangle 54058"
        fill="#e0e0e0"
        d="M54.28 177.777h26.508v15.611H54.28z"
      />
      <path
        data-name="Rectangle 54059"
        fill="#e0e0e0"
        d="M54.28 197.048h26.508v15.612H54.28z"
      />
      <path
        data-name="Rectangle 54060"
        fill="#e0e0e0"
        d="M54.161 216.214H80.67v15.611H54.161z"
      />
      <path
        data-name="Path 97418"
        d="m82.799 193.388 8.352-12.972 9.169 12.972Z"
        fill="#e0e0e0"
      />
      <path
        data-name="Path 97419"
        d="m91.151 180.416.408 12.972h-8.76Z"
        fill="#ebebeb"
      />
      <path
        data-name="Path 97420"
        d="m103.252 193.388 3.556-4.924 4.229 4.924Z"
        fill="#e0e0e0"
      />
      <path
        data-name="Path 97421"
        d="m106.809 188.462.336 4.924h-3.892Z"
        fill="#ebebeb"
      />
      <g data-name="Group 102472">
        <g data-name="Group 102471" clipPath="url(#a)">
          <path
            data-name="Path 97422"
            d="M70.084 209.556a2.845 2.845 0 0 1 2.777-2.742l22.6-.524v6.211H72.926a2.844 2.844 0 0 1-2.842-2.945"
            fill="#f5f5f5"
          />
          <path
            data-name="Path 97423"
            d="M72.376 208.028a3.338 3.338 0 0 0 0 3.406h23.087v-4.077Z"
            fill="#ebebeb"
          />
          <path
            data-name="Path 97424"
            d="M75.753 204.034a2.216 2.216 0 0 1 2.162-2.139l17.618-.421v4.842l-17.566.012a2.217 2.217 0 0 1-2.217-2.295"
            fill="#ebebeb"
          />
          <path
            data-name="Path 97425"
            d="M77.537 202.875a2.6 2.6 0 0 0 0 2.654l18-.011v-3.177Z"
            fill="#fafafa"
          />
          <path
            data-name="Path 97426"
            d="m95.569 198.374-.053 14.244 2.974.042v-14.286Z"
            fill="#fafafa"
          />
          <path
            data-name="Path 97427"
            d="m101.874 200.353-.082 12.272 4.608.035v-12.307Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 97428"
            d="m98.552 200.353-.027 12.272 1.487.035v-12.307Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 97429"
            d="m106.428 200.353-.027 12.272 1.487.035v-12.307Z"
            fill="#fafafa"
          />
          <path
            data-name="Path 97430"
            d="m99.791 198.374-.036 14.244 2.03.042v-14.286Z"
            fill="#ebebeb"
          />
          <path
            data-name="Path 97431"
            d="m107.986 198.374-.036 14.244 2.03.042v-14.286Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 97432"
            d="m121.181 212.384-4.965-4.925 4.965-4.973 5.064-.38 5.561 4.371-3.773 5.908Z"
            fill="none"
            stroke="#e0e0e0"
            strokeWidth={0.25}
          />
          <path
            data-name="Path 97433"
            d="m126.05 212.384 2.966-5.908-2.771-4.371-1.211 1.779h-2.427l-1.427-1.4-2.358 4.482 3.842 5.417"
            fill="none"
            stroke="#e0e0e0"
            strokeWidth={0.25}
          />
          <path
            data-name="Path 97434"
            d="m116.215 207.458 5.433-.753 5.7-.227h4.457"
            fill="none"
            stroke="#e0e0e0"
            strokeWidth={0.25}
          />
          <path
            data-name="Path 97435"
            d="m122.603 203.882-.96 2.822 1.739 5.679"
            fill="none"
            stroke="#e0e0e0"
            strokeWidth={0.25}
          />
          <path
            data-name="Path 97436"
            d="m125.035 203.882 1.455 2.593-1.719 5.908"
            fill="none"
            stroke="#e0e0e0"
            strokeWidth={0.25}
          />
          <path
            data-name="Path 97437"
            d="M372.997 139.959c-4.083.656-5.909 5.674-5.552 9.795s2.051 8.148 1.6 12.258c-.576 5.22-4.52 9.632-4.98 14.863-.542 6.17 3.82 11.643 5.123 17.7.634 2.946.545 6.061 1.642 8.868a11.087 11.087 0 0 0 21.411-3.824c.09-4.738-2.833-9.2-2.478-13.925.263-3.493 2.31-6.8 1.871-10.276-.522-4.134-4.352-7.07-5.7-11.012s-.053-8.288-.52-12.43c-.837-7.43-4.957-12.611-12.412-12.014"
            fill="#ebebeb"
          />
          <path
            data-name="Path 97438"
            d="M352.148 175.938c-.7 6.885 2.35 14.986 5.891 20.931a32.823 32.823 0 0 0 15.414 13.379 1.7 1.7 0 0 0 2.785-1.7 35.614 35.614 0 0 0-6.615-20.315c-3.939-5.679-7.654-9.038-17.475-12.292"
            fill="#f5f5f5"
          />
          <path
            data-name="Path 97439"
            d="M382.142 224.644a1.825 1.825 0 0 1-.2-.383l-.492-1.128a169.23 169.23 0 0 0-1.82-4.136 142.986 142.986 0 0 0-15.013-25.687 168.955 168.955 0 0 0-2.71-3.616l-.742-.981a1.826 1.826 0 0 1-.238-.359 1.738 1.738 0 0 1 .3.309l.8.936c.692.815 1.656 2.024 2.813 3.546a124.96 124.96 0 0 1 15.044 25.74c.758 1.755 1.338 3.189 1.708 4.193l.424 1.155a1.765 1.765 0 0 1 .123.413"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 97440"
            d="M377.71 159.007a4.113 4.113 0 0 1 .075.661l.138 1.9.449 6.976c.371 5.891.895 14.029 1.526 23.013s1.255 17.118 1.713 23c.223 2.915.4 5.287.533 6.97l.129 1.9a4.119 4.119 0 0 1 .018.664 4.3 4.3 0 0 1-.1-.657c-.05-.466-.118-1.1-.2-1.889l-.657-6.962c-.528-5.881-1.194-14.01-1.827-23s-1.111-17.128-1.412-23.024l-.325-6.984-.064-1.9a4.272 4.272 0 0 1 0-.665"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 97441"
            d="M371.376 214.826h17.243v12.659a8.278 8.278 0 0 1-16.543.442Z"
            fill="#e0e0e0"
          />
        </g>
      </g>
      <g data-name="Group 102474">
        <g data-name="Group 102473" clipPath="url(#a)">
          <path
            data-name="Path 97442"
            d="M415.931 235.762c0 .091-93.117.164-207.955.164C93.1 235.927 0 235.853 0 235.762s93.1-.164 207.976-.164c114.838 0 207.955.073 207.955.164"
            fill="#263238"
          />
        </g>
      </g>
      <g data-name="Group 102570">
        <g data-name="Group 102569" clipPath="url(#a)">
          <path
            data-name="Path 97443"
            d="M144.455 92.624a5.033 5.033 0 0 0 5.358-4.888c.074-2.741.134-5.512.134-5.512s4.147-.437 4.4-4.548.008-13.595.008-13.595a14.317 14.317 0 0 0-14.458 1.1l-.633.439.486 22.1a5.033 5.033 0 0 0 4.7 4.911"
            fill="#b78876"
          />
          <path
            data-name="Path 97444"
            d="M152.483 71.335a.6.6 0 1 1 .846 0 .6.6 0 0 1-.846 0"
            fill="#263238"
          />
          <path
            data-name="Path 97445"
            d="M154.077 70.623c-.068.068-.457-.263-1.023-.294s-.993.242-1.051.166c-.029-.034.041-.159.227-.287a1.4 1.4 0 0 1 1.656.1c.169.15.224.282.191.313"
            fill="#263238"
          />
          <path
            data-name="Path 97446"
            d="M147.106 70.622a.6.6 0 1 1-.831.155.6.6 0 0 1 .831-.155"
            fill="#263238"
          />
          <path
            data-name="Path 97447"
            d="M147.9 70.825c-.068.068-.457-.263-1.023-.293s-.993.241-1.051.166c-.029-.034.041-.159.227-.287a1.4 1.4 0 0 1 1.656.1c.169.15.224.282.191.313"
            fill="#263238"
          />
          <path
            data-name="Path 97448"
            d="M149.959 75.2a2.771 2.771 0 0 1 .926-.149c.15-.008.267-.037.282-.116a.8.8 0 0 0-.085-.43l-.392-1.174a15.753 15.753 0 0 1-.82-3.075 15.838 15.838 0 0 1 1.128 2.976l.367 1.181a.946.946 0 0 1 .051.62.389.389 0 0 1-.267.224.944.944 0 0 1-.255.02 2.746 2.746 0 0 1-.934-.079"
            fill="#263238"
          />
          <path
            data-name="Path 97449"
            d="m148.092 75.734 2.739.945a1.567 1.567 0 0 1-1.878.532c-1.209-.511-.861-1.477-.861-1.477"
            fill="#fff"
          />
          <path
            data-name="Path 97450"
            d="M149.956 82.167a9.577 9.577 0 0 1-5.289-1.789s1.121 3.024 5.246 2.856Z"
            fill="#a36957"
          />
          <path
            data-name="Path 97451"
            d="M153.466 63.005a18.716 18.716 0 0 0-10.546-.549s-3.5 1.271-3.654 3.162a32.472 32.472 0 0 0 .1 4.638l.3 3.185 1.273-.016.124-4.282a5.965 5.965 0 0 0 2.416-4.3c1.18-.174 6.96-.926 10.92.375l-.011-.919a1.385 1.385 0 0 0-.92-1.293"
            fill="#263238"
          />
          <path
            data-name="Path 97452"
            d="M139.834 70.586a.621.621 0 0 0-.6-.615c-.768-.012-2.019.308-2.11 2.427a1.973 1.973 0 0 0 2.777 2.149c.007-.068-.044-2.786-.066-3.961"
            fill="#b78876"
          />
          <path
            data-name="Path 97453"
            d="M139.222 73.366c-.013-.01-.054.035-.145.071a.528.528 0 0 1-.394-.009 1.306 1.306 0 0 1-.553-1.2 1.742 1.742 0 0 1 .18-.749.61.61 0 0 1 .414-.387.268.268 0 0 1 .3.158c.037.087.015.146.031.152s.067-.047.049-.174a.337.337 0 0 0-.111-.195.4.4 0 0 0-.288-.09.74.74 0 0 0-.571.445 1.783 1.783 0 0 0-.219.835 1.369 1.369 0 0 0 .712 1.341.566.566 0 0 0 .486-.059c.1-.071.116-.138.105-.143"
            fill="#a36957"
          />
          <path
            data-name="Path 97454"
            d="M199.778 73.961c-.823-.289-2.506 4.136-2.7 2.975s2.688-5.172 1.816-5.439c-1.136-.347-2.813 4.317-2.985 4.7s-.732.15-.559-.188c.248-.487 2.736-5.665 1.622-6.017-.617-.2-.864.945-.864.945s-1.97 5.015-2.489 4.832.529-3.17.957-4.151.133-1.564-.451-1.395c-.4.116-1.78 3.089-2.18 4.069s-1.45 3.687-2.018 1.931-1.667-2.891-2.177-2.992a.577.577 0 0 0-.449 1 6.738 6.738 0 0 1 .9 1.629 4.4 4.4 0 0 1 .348 1.451 15.264 15.264 0 0 1-.054 3.01l-10.162 18.256-16.277-6.156-2.237 9.3s21.38 9.423 22.718 8.695c3.019-1.641 12.506-28.2 12.506-28.2a63.223 63.223 0 0 0 3.921-5.625c1.334-2.4 1.12-2.524.814-2.631"
            fill="#b78876"
          />
          <path
            data-name="Path 97455"
            d="M172.134 95.633c-4.523-2.257-7.724-3.592-10.2-5.137-7.691-4.805-9.689-3.7-11.488-3.9-1.5.483-3.725 1.388-4.567 1.555-.922.183-3.948-.84-5.952-1.432-4.643.253-7.465 2.262-11.717 5.474l-8.635 6.851 10.221 10.436 3.137-2.4c.188 4.737.358 4.777.6 9.631l.2 18.686 28.9 1.284-1.823-18.675-.879-14.695 8.281 4.009Z"
            fill="#69f"
          />
          <path
            data-name="Path 97456"
            d="m139.53 87.271-.037-3s5.16.48 6.252 4.051Z"
            fill="#69f"
          />
          <path
            data-name="Path 97457"
            d="m150.493 87.048-.461-2.906a5.068 5.068 0 0 0-4.288 4.18Z"
            fill="#69f"
          />
          <path
            data-name="Path 97458"
            d="M151.003 97.668a6.115 6.115 0 0 1 .994-.08c.658-.034 1.623-.077 2.8-.106h.139v.142l.072 3.86v.086l-.071.05-.275.195-1.473 1.037-.088.062-.09-.057-2.035-1.284-.066-.042v-.075c0-1.169.02-2.132.038-2.789a6.062 6.062 0 0 1 .056-.994 9.2 9.2 0 0 1 .081 1.08c.034.665.077 1.592.1 2.7l-.066-.118 2.057 1.25-.177.006 1.468-1.044.275-.194-.07.136-.023-3.86.142.138c-1.111 0-2.041-.021-2.709-.039a9.281 9.281 0 0 1-1.084-.055"
            fill="#263238"
          />
          <path
            data-name="Path 97459"
            d="M154.711 98.563a10.887 10.887 0 0 1-3.772-.006 10.888 10.888 0 0 1 3.772.006"
            fill="#263238"
          />
          <path
            data-name="Path 97460"
            d="M145.851 93.92a23.9 23.9 0 0 1-.069-5.6 23.891 23.891 0 0 1 .069 5.6"
            fill="#263238"
          />
          <path
            data-name="Path 97461"
            d="M146.882 89.066c-.015-.04.133-.114.3.027a.393.393 0 0 1 .13.349.429.429 0 0 1-.784.14.4.4 0 0 1 0-.373c.109-.19.273-.171.273-.128s-.051.1-.054.208.044.181.162.164.135-.1.1-.209-.118-.131-.123-.177"
            fill="#263238"
          />
          <path
            data-name="Path 97462"
            d="M147.277 91.3c.006-.042.164-.046.265.145a.415.415 0 0 1 0 .369.453.453 0 0 1-.807-.035.415.415 0 0 1 .033-.366c.117-.181.275-.163.277-.121s-.052.105-.064.213.032.2.16.211.178-.078.178-.2-.062-.174-.045-.217"
            fill="#263238"
          />
          <path
            data-name="Path 97463"
            d="M144.52 86.726c-.009.024-.111-.02-.274.034a.727.727 0 0 0-.449.524 10.583 10.583 0 0 0-.2 1.166l-.236 1.464-.042.263-.213-.149-.378-.265a18.754 18.754 0 0 1-2.311-1.945 3.974 3.974 0 0 1-.812-.954c.069-.069 1.413 1.279 3.314 2.632l.372.263-.255.114.274-1.477a7.573 7.573 0 0 1 .282-1.188.829.829 0 0 1 .631-.566c.215-.023.31.072.3.083"
            fill="#263238"
          />
          <path
            data-name="Path 97464"
            d="M150.661 86.699a8.312 8.312 0 0 1-2.27 3.342l-.077.075-.235.227-.042-.325a10.936 10.936 0 0 0-1.327-3.6 2.7 2.7 0 0 1 .725.9 6.982 6.982 0 0 1 .927 2.656l-.276-.1.075-.073a24.042 24.042 0 0 0 2.5-3.105"
            fill="#263238"
          />
          <g data-name="Group 102478" opacity={0.3}>
            <g data-name="Group 102477">
              <g
                data-name="Group 102476"
                clipPath="url(#b)"
                transform="translate(159.929 103.311)"
              >
                <path
                  data-name="Path 97465"
                  d="m.001 0 2.708.138 6.1 2.1-.531 1.777Z"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102481" opacity={0.3}>
            <g data-name="Group 102480">
              <g
                data-name="Group 102479"
                clipPath="url(#c)"
                transform="translate(132.936 107.075)"
              >
                <path
                  data-name="Path 97466"
                  d="M27.926 6.147a108.335 108.335 0 0 1-17.358.3 15.864 15.864 0 0 1-5.791-1.159A9.383 9.383 0 0 1 .001.004l.514 8.049Z"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102484" opacity={0.7}>
            <g data-name="Group 102483">
              <g
                data-name="Group 102482"
                clipPath="url(#d)"
                transform="translate(132.129 92.298)"
              >
                <path
                  data-name="Path 97467"
                  d="M1.835 2.595c-.075.052-.543-.485-1.048-1.2A3.962 3.962 0 0 1 .009.005c.075-.052.543.485 1.047 1.2a3.956 3.956 0 0 1 .778 1.391"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102487" opacity={0.7}>
            <g data-name="Group 102486">
              <g
                data-name="Group 102485"
                clipPath="url(#e)"
                transform="translate(128.011 98.17)"
              >
                <path
                  data-name="Path 97468"
                  d="M.014 3.649A12.162 12.162 0 0 1 1.629 0 12.161 12.161 0 0 1 .014 3.649"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102490" opacity={0.7}>
            <g data-name="Group 102489">
              <g
                data-name="Group 102488"
                clipPath="url(#f)"
                transform="translate(133.189 101.546)"
              >
                <path
                  data-name="Path 97469"
                  d="M2.426 2.778c-.068.06-.666-.513-1.334-1.279A5.284 5.284 0 0 1 .007.004c.068-.06.665.512 1.334 1.279a5.3 5.3 0 0 1 1.085 1.495"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102493" opacity={0.7}>
            <g data-name="Group 102492">
              <g
                data-name="Group 102491"
                clipPath="url(#g)"
                transform="translate(137.329 97.112)"
              >
                <path
                  data-name="Path 97470"
                  d="M2.347.008c.059.069-.418.57-1.064 1.118s-1.219.937-1.277.867.418-.57 1.065-1.118S2.289-.062 2.348.008"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102496" opacity={0.7}>
            <g data-name="Group 102495">
              <g
                data-name="Group 102494"
                clipPath="url(#h)"
                transform="translate(139.376 91.7)"
              >
                <path
                  data-name="Path 97471"
                  d="M1.62 2.224c-.064.062-.548-.342-1-.968S-.064.044.015.003s.433.456.872 1.061.8 1.1.733 1.161"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102499" opacity={0.7}>
            <g data-name="Group 102498">
              <g
                data-name="Group 102497"
                clipPath="url(#i)"
                transform="translate(138.905 108.118)"
              >
                <path
                  data-name="Path 97472"
                  d="M3.206.01a23.436 23.436 0 0 1-3.2 2.25 7.878 7.878 0 0 1 3.2-2.25"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102502" opacity={0.7}>
            <g data-name="Group 102501">
              <g
                data-name="Group 102500"
                clipPath="url(#j)"
                transform="translate(141.23 101.988)"
              >
                <path
                  data-name="Path 97473"
                  d="M2.254 2.087c-.063.067-.615-.345-1.236-.919S-.055.074.006.007s.615.345 1.236.919S2.315 2.02 2.254 2.087"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102505" opacity={0.7}>
            <g data-name="Group 102504">
              <g
                data-name="Group 102503"
                clipPath="url(#k)"
                transform="translate(146.854 96.595)"
              >
                <path
                  data-name="Path 97474"
                  d="M.029 2.88A4.98 4.98 0 0 1 1.196.002 14.97 14.97 0 0 1 .029 2.88"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102508" opacity={0.7}>
            <g data-name="Group 102507">
              <g
                data-name="Group 102506"
                clipPath="url(#l)"
                transform="translate(146.347 108.901)"
              >
                <path
                  data-name="Path 97475"
                  d="M2.054 2.378c-.054.07-.663-.325-1.24-1a2.876 2.876 0 0 1-.8-1.376c.081-.047.494.516 1.053 1.161s1.049 1.142.99 1.214"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102511" opacity={0.7}>
            <g data-name="Group 102510">
              <g
                data-name="Group 102509"
                clipPath="url(#m)"
                transform="translate(148.325 103.289)"
              >
                <path
                  data-name="Path 97476"
                  d="M2.417 2.355c-.063.066-.655-.408-1.32-1.056S-.057.071.006.006a4.453 4.453 0 0 1 1.32 1.058c.666.648 1.154 1.227 1.091 1.292"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102514" opacity={0.7}>
            <g data-name="Group 102513">
              <g
                data-name="Group 102512"
                clipPath="url(#n)"
                transform="translate(153.951 109.941)"
              >
                <path
                  data-name="Path 97477"
                  d="M2.879.011A43.1 43.1 0 0 1 .006 2.33 6.025 6.025 0 0 1 2.879.011"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102517" opacity={0.7}>
            <g data-name="Group 102516">
              <g
                data-name="Group 102515"
                clipPath="url(#o)"
                transform="translate(153.718 105.005)"
              >
                <path
                  data-name="Path 97478"
                  d="M2.935.008A10.523 10.523 0 0 1 .003 2.273 10.516 10.516 0 0 1 2.935.008"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102520" opacity={0.7}>
            <g data-name="Group 102519">
              <g
                data-name="Group 102518"
                clipPath="url(#p)"
                transform="translate(157.367 97.568)"
              >
                <path
                  data-name="Path 97479"
                  d="M.419 3.375A5.65 5.65 0 0 1 .153 0a20.676 20.676 0 0 1 .266 3.375"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102523" opacity={0.7}>
            <g data-name="Group 102522">
              <g
                data-name="Group 102521"
                clipPath="url(#q)"
                transform="translate(152.481 99.556)"
              >
                <path
                  data-name="Path 97480"
                  d="M1.143.003c.08.044-.107.543-.418 1.117s-.628 1-.708.96.107-.543.418-1.117.628-1 .708-.96"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102526" opacity={0.7}>
            <g data-name="Group 102525">
              <g
                data-name="Group 102524"
                clipPath="url(#r)"
                transform="translate(150.785 91.124)"
              >
                <path
                  data-name="Path 97481"
                  d="M3.109 2.101A10.737 10.737 0 0 1 .002.01a10.737 10.737 0 0 1 3.107 2.091"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102529" opacity={0.7}>
            <g data-name="Group 102528">
              <g
                data-name="Group 102527"
                clipPath="url(#s)"
                transform="translate(154.837 89.159)"
              >
                <path
                  data-name="Path 97482"
                  d="M3.199 1.752A5.229 5.229 0 0 1-.001.008a827.03 827.03 0 0 1 3.2 1.744"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102532" opacity={0.7}>
            <g data-name="Group 102531">
              <g
                data-name="Group 102530"
                clipPath="url(#t)"
                transform="translate(158.55 93.375)"
              >
                <path
                  data-name="Path 97483"
                  d="M2.778.041A62.047 62.047 0 0 1 .003 1.156c-.052-.07.478-.529 1.272-.847a2.52 2.52 0 0 1 1.5-.269"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102535" opacity={0.7}>
            <g data-name="Group 102534">
              <g
                data-name="Group 102533"
                clipPath="url(#u)"
                transform="translate(163.459 96.186)"
              >
                <path
                  data-name="Path 97484"
                  d="M.021 3.889A7.942 7.942 0 0 1 1.6.002 46.493 46.493 0 0 1 .021 3.889"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102538" opacity={0.7}>
            <g data-name="Group 102537">
              <g
                data-name="Group 102536"
                clipPath="url(#v)"
                transform="translate(167.486 99.291)"
              >
                <path
                  data-name="Path 97485"
                  d="M.447 3.467A9.341 9.341 0 0 1 .06 0a9.341 9.341 0 0 1 .387 3.467"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102541" opacity={0.7}>
            <g data-name="Group 102540">
              <g
                data-name="Group 102539"
                clipPath="url(#w)"
                transform="translate(123.207 97.115)"
              >
                <path
                  data-name="Path 97486"
                  d="M.688 0a6.838 6.838 0 0 1-.646 2.906A6.806 6.806 0 0 1 .688 0"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102544" opacity={0.7}>
            <g data-name="Group 102543">
              <g
                data-name="Group 102542"
                clipPath="url(#x)"
                transform="translate(127.444 105.613)"
              >
                <path
                  data-name="Path 97487"
                  d="M3.067.943A5.067 5.067 0 0 1 .001.019a18.463 18.463 0 0 1 3.066.924"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102547" opacity={0.7}>
            <g data-name="Group 102546">
              <g
                data-name="Group 102545"
                clipPath="url(#y)"
                transform="translate(135.924 88.744)"
              >
                <path
                  data-name="Path 97488"
                  d="M2.18.026c.03.088-.472.336-1.06.681s-1.053.656-1.114.586.327-.51.949-.871 1.2-.481 1.226-.4"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102550" opacity={0.7}>
            <g data-name="Group 102549">
              <g
                data-name="Group 102548"
                clipPath="url(#z)"
                transform="translate(127.517 94.416)"
              >
                <path
                  data-name="Path 97489"
                  d="M2.683.658A5.825 5.825 0 0 1 0 .042a5.846 5.846 0 0 1 2.683.616"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102553" opacity={0.7}>
            <g data-name="Group 102552">
              <g
                data-name="Group 102551"
                clipPath="url(#A)"
                transform="translate(140.466 86.15)"
              >
                <path
                  data-name="Path 97490"
                  d="M1.621 1.314c-.032.081-.517-.047-.986-.43S-.059.054.014.006s.4.275.829.623.816.6.778.685"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102556" opacity={0.7}>
            <g data-name="Group 102555">
              <g
                data-name="Group 102554"
                clipPath="url(#B)"
                transform="translate(148.378 85.701)"
              >
                <path
                  data-name="Path 97491"
                  d="M1.317.011c.064.064-.176.408-.537.767s-.705.6-.77.533S.186.903.548.544s.705-.6.769-.533"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <path
            data-name="Path 97492"
            d="m109.313 218.882-3.994 2.316s2.942 9.594 1.142 7.892c-1.614-1.526-7.631-13.06-9.055-15.908l-.315-.643 6.794-3.692Z"
            fill="#69f"
          />
          <g data-name="Group 102559" opacity={0.5}>
            <g data-name="Group 102558">
              <g
                data-name="Group 102557"
                clipPath="url(#C)"
                transform="translate(97.243 210.873)"
              >
                <path
                  data-name="Path 97493"
                  d="M2.002 5.25a4 4 0 0 0 1.4-2.495A3.881 3.881 0 0 0 2.793 0l-2.8 1.6Z"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102562" opacity={0.5}>
            <g data-name="Group 102561">
              <g
                data-name="Group 102560"
                clipPath="url(#D)"
                transform="translate(97.095 212.428)"
              >
                <path
                  data-name="Path 97494"
                  d="M9.518 13.542s.843 3.47.14 3.379S2.436 5.403.002.112L.234 0l7.491 13.393s1.437-.419 1.794.149"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <path
            data-name="Path 97495"
            d="M97.244 212.268a1.048 1.048 0 0 0 .08.183l.252.511q.39.76.96 1.864a204.28 204.28 0 0 0 6.844 12.018l1.113 1.775.311.478a1.032 1.032 0 0 0 .117.162 1.144 1.144 0 0 0-.093-.176l-.289-.492-1.075-1.8c-.907-1.519-2.15-3.624-3.486-5.97s-2.512-4.488-3.355-6.044l-1-1.842-.275-.5a1.046 1.046 0 0 0-.1-.17"
            fill="#263238"
          />
          <path
            data-name="Path 97496"
            d="M104.66 225.907a3.048 3.048 0 0 1 2.112.067 1.862 1.862 0 0 0-2.112-.067"
            fill="#263238"
          />
          <path
            data-name="Path 97497"
            d="M104.474 222.099a2.343 2.343 0 1 0 .571-.043 1.22 1.22 0 0 0-.571.043"
            fill="#263238"
          />
          <path
            data-name="Path 97498"
            d="M104.44 221.045c-.012.037.183.135.437.219s.469.123.481.086-.183-.135-.437-.219-.469-.123-.481-.086"
            fill="#263238"
          />
          <path
            data-name="Path 97499"
            d="M105.092 219.993a1.345 1.345 0 0 0 .231.56c.162.291.32.51.354.492a1.362 1.362 0 0 0-.232-.56c-.161-.291-.32-.511-.353-.492"
            fill="#263238"
          />
          <path
            data-name="Path 97500"
            d="M105.658 219.438a1.167 1.167 0 0 0 .163.679c.167.354.4.581.423.556a3.714 3.714 0 0 0-.3-.615 3.768 3.768 0 0 0-.289-.62"
            fill="#263238"
          />
          <path
            data-name="Path 97501"
            d="M105.54 221.941a2.139 2.139 0 0 0 .371.641 4.391 4.391 0 0 0 .541.6 2.15 2.15 0 0 0 .385.3.4.4 0 0 0 .291.045.293.293 0 0 0 .2-.244 1.359 1.359 0 0 0-1.177-1.262 1.329 1.329 0 0 0-.549.035c-.124.037-.186.075-.183.082a2.115 2.115 0 0 1 .716-.02 1.334 1.334 0 0 1 .67.346 1.151 1.151 0 0 1 .385.8c-.018.145-.16.174-.293.1a2.233 2.233 0 0 1-.361-.274 5.008 5.008 0 0 1-.55-.564c-.288-.346-.429-.591-.449-.579"
            fill="#263238"
          />
          <path
            data-name="Path 97502"
            d="M105.604 222.126a.728.728 0 0 0 .489-.181 1.216 1.216 0 0 0 .37-.444.743.743 0 0 0 .022-.723.3.3 0 0 0-.41-.028.809.809 0 0 0-.225.273 2.259 2.259 0 0 0-.216.526 1.066 1.066 0 0 0-.05.517c.022 0 .042-.194.145-.488a2.582 2.582 0 0 1 .232-.489c.086-.166.3-.366.417-.221a.66.66 0 0 1-.032.579 1.219 1.219 0 0 1-.314.421 2.7 2.7 0 0 1-.428.258"
            fill="#263238"
          />
          <path
            data-name="Path 97503"
            d="M100.098 210.909c-.021.015.191.271.4.754a3.608 3.608 0 0 1-.594 3.813c-.342.4-.622.577-.606.6a.68.68 0 0 0 .2-.115 3.035 3.035 0 0 0 .477-.417 3.5 3.5 0 0 0 .611-3.92 3.032 3.032 0 0 0-.329-.542c-.092-.118-.151-.176-.157-.171"
            fill="#263238"
          />
          <path
            data-name="Path 97504"
            d="M100.939 216.825a9.477 9.477 0 0 0 1.641 2.94c.033-.023-.38-.654-.83-1.464s-.773-1.492-.811-1.476"
            fill="#263238"
          />
          <path
            data-name="Path 97505"
            d="M99.824 214.862c-.039-.01-.108.133-.22.285s-.224.265-.2.3.188-.039.315-.215.143-.359.107-.367"
            fill="#263238"
          />
          <path
            data-name="Path 97506"
            d="M100.322 213.598c-.037-.011-.1.114-.15.277s-.053.3-.016.315.1-.114.15-.277.053-.3.016-.315"
            fill="#263238"
          />
          <path
            data-name="Path 97507"
            d="M100.143 212.301c-.03.027.047.159.079.336s.015.327.052.34.128-.149.085-.367-.191-.336-.216-.308"
            fill="#263238"
          />
          <path
            data-name="Path 97508"
            d="M99.908 211.434c-.038.006-.057.092-.042.194s.059.179.1.173.057-.092.042-.194-.059-.179-.1-.173"
            fill="#263238"
          />
          <path
            data-name="Path 97509"
            d="m174.805 227.776-.145 4.614s9.7 2.573 7.3 3.2c-2.148.564-15.119-.424-18.29-.72l-.712-.073.457-7.719Z"
            fill="#69f"
          />
          <g data-name="Group 102565" opacity={0.5}>
            <g data-name="Group 102564">
              <g
                data-name="Group 102563"
                clipPath="url(#E)"
                transform="translate(162.98 231.413)"
              >
                <path
                  data-name="Path 97510"
                  d="M4.159 3.452A4 4 0 0 0 2.782.942 3.88 3.88 0 0 0 .123.003l-.124 3.218Z"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102568" opacity={0.5}>
            <g data-name="Group 102567">
              <g
                data-name="Group 102566"
                clipPath="url(#F)"
                transform="translate(162.961 233.817)"
              >
                <path
                  data-name="Path 97511"
                  d="M16.432 0s3.391 1.119 2.942 1.667S5.779 1.711 0 .981L.028.724l15.328.719s.4-1.441 1.075-1.444"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <path
            data-name="Path 97512"
            d="M162.814 234.528a1.063 1.063 0 0 0 .2.029l.567.056 2.089.171a204.721 204.721 0 0 0 13.815.541l2.1-.007.57-.011a1.162 1.162 0 0 0 .2-.013 1.024 1.024 0 0 0-.2-.015l-.569-.015-2.1-.038c-1.769-.032-4.213-.09-6.911-.195s-5.138-.239-6.9-.346l-2.092-.126-.569-.03a1.091 1.091 0 0 0-.2 0"
            fill="#263238"
          />
          <path
            data-name="Path 97513"
            d="M178.308 235.439a3.043 3.043 0 0 1 1.173-1.758 1.863 1.863 0 0 0-1.173 1.758"
            fill="#263238"
          />
          <path
            data-name="Path 97514"
            d="M174.977 233.586c.032.022.2-.17.38-.428s.3-.485.265-.507-.2.17-.38.428-.3.485-.265.507"
            fill="#263238"
          />
          <path
            data-name="Path 97515"
            d="M174.063 233.057c.025.03.211-.085.416-.255s.351-.333.327-.363-.211.085-.416.255-.351.333-.327.363"
            fill="#263238"
          />
          <path
            data-name="Path 97516"
            d="M173.516 231.946a2.638 2.638 0 1 0 .6-.04 1.356 1.356 0 0 0-.6.04"
            fill="#263238"
          />
          <path
            data-name="Path 97517"
            d="M173.344 231.177a1.694 1.694 0 0 0 1.358.155 3.754 3.754 0 0 0-.68-.073 3.681 3.681 0 0 0-.679-.082"
            fill="#263238"
          />
          <path
            data-name="Path 97518"
            d="M175.406 232.597a2.12 2.12 0 0 0 .74.023 4.321 4.321 0 0 0 .795-.143 2.2 2.2 0 0 0 .458-.169.4.4 0 0 0 .191-.223.291.291 0 0 0-.1-.3 1.358 1.358 0 0 0-1.693.332 1.327 1.327 0 0 0-.26.485.4.4 0 0 0-.027.2 2.075 2.075 0 0 1 .361-.618 1.34 1.34 0 0 1 .648-.387 1.149 1.149 0 0 1 .879.094c.114.092.063.228-.071.3a2.214 2.214 0 0 1-.423.162 5.068 5.068 0 0 1-.77.169c-.446.062-.728.052-.729.075"
            fill="#263238"
          />
          <path
            data-name="Path 97519"
            d="M175.599 232.64a.729.729 0 0 0 .1-.51 1.2 1.2 0 0 0-.181-.548.742.742 0 0 0-.6-.4.294.294 0 0 0-.241.333.808.808 0 0 0 .113.335 2.241 2.241 0 0 0 .332.461 1.064 1.064 0 0 0 .413.316c.013-.018-.143-.138-.338-.38a2.641 2.641 0 0 1-.293-.456c-.1-.16-.154-.444.033-.47.186-.011.368.181.475.333a1.218 1.218 0 0 1 .191.489 2.738 2.738 0 0 1-.007.5"
            fill="#263238"
          />
          <path
            data-name="Path 97520"
            d="M163.165 231.387c0 .026.331-.019.849.063a3.611 3.611 0 0 1 2.923 2.52c.157.5.161.833.187.83a.656.656 0 0 0 .009-.232 3.035 3.035 0 0 0-.1-.626 3.5 3.5 0 0 0-3-2.59 3.035 3.035 0 0 0-.634-.008.679.679 0 0 0-.229.044"
            fill="#263238"
          />
          <path
            data-name="Path 97521"
            d="M168.632 233.799a9.471 9.471 0 0 0 3.364.161c0-.04-.756-.023-1.682-.069s-1.675-.132-1.681-.092"
            fill="#263238"
          />
          <path
            data-name="Path 97522"
            d="M166.375 233.709c-.03.027.055.162.125.337s.106.33.147.329.066-.179-.018-.382-.229-.311-.254-.284"
            fill="#263238"
          />
          <path
            data-name="Path 97523"
            d="M165.565 232.618c-.028.026.041.148.155.274s.231.206.259.179-.041-.148-.155-.274-.231-.206-.259-.18"
            fill="#263238"
          />
          <path
            data-name="Path 97524"
            d="M164.367 232.088c.006.039.16.044.327.11s.285.16.316.136-.059-.188-.267-.266-.387-.016-.377.02"
            fill="#263238"
          />
          <path
            data-name="Path 97525"
            d="M163.507 231.826c-.015.035.049.1.143.138s.183.045.2.009-.049-.1-.143-.138-.183-.045-.2-.009"
            fill="#263238"
          />
          <path
            data-name="Path 97526"
            d="M140.848 146.434s-.833 22.016-4.206 33.429-12.432 14.195-18.291 17.868c-5.6 3.51-18.112 10.156-18.112 10.156l7.064 12.235s34.541-14.616 40.7-27.7c4.73-10.052 9.9-28.554 9.9-28.554Z"
            fill="#263238"
          />
          <path
            data-name="Path 97527"
            d="M160.247 136.583s14.568 30.515 18.46 38.8a5.836 5.836 0 0 1 .547 2.778l-2.567 51.435-14.778-.751 1.6-49.6-25.058-31.409a22.981 22.981 0 0 1-4.493-9.111 14.516 14.516 0 0 1-.418-3.326Z"
            fill="#263238"
          />
          <path
            data-name="Path 97528"
            d="m221.551 206.283-132.647 9.7-6.461-97.287 131.649-9.218Z"
            fill="#fff"
          />
          <path
            data-name="Path 97529"
            d="M221.553 206.279s-.056-.6-.15-1.765l-.411-5.132-1.539-19.533c-1.315-16.858-3.2-41.041-5.49-70.364l.143.124-131.653 9.25.153-.175.047.71c2.3 34.64 4.471 67.485 6.4 96.585l-.159-.138 95.718-6.929 27.219-1.951 7.223-.51 1.862-.128c.418-.028.636-.038.636-.038s-.205.021-.618.054l-1.844.144-7.191.544-27.18 2.028-95.8 7.083-.15.011-.01-.15-6.425-96.58-.047-.71-.011-.164.164-.011 131.651-9.188.133-.009.01.133 5.357 70.459 1.468 19.508.378 5.109c.085 1.159.116 1.733.116 1.733"
            fill="#263238"
          />
          <path
            data-name="Path 97530"
            d="M189.599 124.202c.272-.015 1.433 16.714 2.592 37.361s1.878 37.4 1.607 37.419-1.433-16.71-2.592-37.364-1.878-37.4-1.607-37.416"
            fill="#263238"
          />
          <path
            data-name="Path 97531"
            d="M177.819 123.997c.272-.015 1.433 16.714 2.592 37.361s1.878 37.4 1.606 37.419-1.432-16.71-2.592-37.364-1.879-37.4-1.607-37.416"
            fill="#263238"
          />
          <path
            data-name="Path 97532"
            d="M141.755 124.525c.272-.015 1.433 16.714 2.592 37.361s1.878 37.4 1.606 37.419-1.432-16.71-2.592-37.364-1.879-37.4-1.607-37.416"
            fill="#263238"
          />
          <path
            data-name="Path 97533"
            d="M153.15 124.043c.272-.015 1.433 16.714 2.592 37.361s1.878 37.4 1.607 37.419-1.433-16.71-2.592-37.364-1.878-37.4-1.607-37.416"
            fill="#263238"
          />
          <path
            data-name="Path 97534"
            d="M164.543 123.559c.272-.015 1.433 16.714 2.592 37.361s1.878 37.4 1.606 37.419-1.432-16.71-2.592-37.364-1.879-37.4-1.607-37.416"
            fill="#263238"
          />
          <path
            data-name="Path 97535"
            d="M129.084 126.199c.272-.015 1.433 16.714 2.592 37.361s1.878 37.4 1.606 37.419-1.432-16.71-2.592-37.364-1.879-37.4-1.607-37.416"
            fill="#263238"
          />
          <path
            data-name="Path 97536"
            d="M116.596 126.947c.272-.015 1.433 16.714 2.592 37.361s1.878 37.4 1.606 37.419-1.432-16.71-2.592-37.364-1.879-37.4-1.607-37.416"
            fill="#263238"
          />
          <path
            data-name="Path 97537"
            d="M104.108 127.695c.272-.015 1.433 16.714 2.592 37.361s1.878 37.4 1.606 37.419-1.432-16.71-2.592-37.364-1.879-37.4-1.607-37.416"
            fill="#263238"
          />
          <path
            data-name="Path 97538"
            d="M120.462 99.945s-14.724 10.807-11.935 19.441c3.347 10.357 26.886 18.653 26.889 18.654 1.844 1.538 3.871 3.167 5.059 3.931 2.525 1.623 2.618 1.376 2.684 1.02.178-.957-4.376-3.04-3.262-3.233s5.415 3.28 5.565 2.264c.2-1.324-4.592-3.406-4.992-3.616s-.241-.862.113-.651c.509.3 5.9 3.348 6.107 2.053.112-.718-1.037-1.037-1.037-1.037s-5.17-2.434-5.056-3.038 3.176.7 4.193 1.226 1.551.2 1.31-.494c-.165-.471-3.258-2.163-4.269-2.657s-3.8-1.792-2.152-2.414a4.04 4.04 0 0 0 2.655-2.477c.033-.6-.671-1-1.034-.551a5.282 5.282 0 0 1-1.482 1.013 3.374 3.374 0 0 1-1.2.353c-2.195-1.35-17.87-11.049-17.935-12.776-.069-1.857 7.9-8.717 7.9-8.717Z"
            fill="#b78876"
          />
          <path
            data-name="Path 97539"
            d="M147.695 68.614c-.02.092-.416.076-.883.143s-.844.185-.888.1.308-.355.843-.428.951.1.927.182"
            fill="#263238"
          />
          <path
            data-name="Path 97540"
            d="M153.447 68.323c-.06.073-.359-.083-.748-.149s-.722-.033-.753-.121.328-.291.811-.2.753.411.69.473"
            fill="#263238"
          />
        </g>
      </g>
      <g data-name="Group 102575">
        <g data-name="Group 102574" clipPath="url(#a)">
          <path
            data-name="Path 97541"
            d="M228.158 66.42c-4.9 24.33-22.734 115.811-27.49 140.223a6.179 6.179 0 0 0 4.843 7.238l100.142 20.292a6.184 6.184 0 0 0 7.3-4.884l13.706-70.724 8.546 1.319a3.929 3.929 0 0 0 4.464-3.176l10.438-57.1a3.928 3.928 0 0 0-3.141-4.568l-7.679-1.439 1-5.522a6.149 6.149 0 0 0-4.883-7.138l-100.053-19.34a6.146 6.146 0 0 0-7.194 4.819"
            fill="#263238"
          />
          <path
            data-name="Path 97542"
            d="m235.629 68.774-33.068 139.931 102.746 23.549 32.379-138.948Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 97543"
            d="M223.686 63.574 200.14 205.418l104.1 16.559L327.164 81.16Z"
            fill="#ebebeb"
          />
          <path
            data-name="Path 97544"
            d="m208.417 70.998-4.4 143.718 105.381 2.511 3.916-142.617Z"
            fill="#fff"
          />
          <path
            data-name="Path 97545"
            d="m208.418 70.998 104.906 3.477h.133v.134l-3.885 142.618v.164h-.164l-.768-.018-104.612-2.508h-.15l.005-.15c2.688-85 4.5-142.394 4.544-143.713l-4.246 143.723-.146-.154 104.612 2.477.768.018-.168.16 3.947-142.616.13.138-104.9-3.746"
            fill="#263238"
          />
          <path
            data-name="Path 97546"
            d="M298.539 101.741c0 .274-18.132.247-40.486-.057s-40.48-.773-40.476-1.046 18.128-.246 40.49.059 40.476.772 40.472 1.044"
            fill="#263238"
          />
          <path
            data-name="Path 97547"
            d="M299.3 114.455c0 .273-18.132.246-40.486-.058s-40.48-.773-40.476-1.046 18.128-.246 40.49.059 40.476.773 40.472 1.045"
            fill="#263238"
          />
          <path
            data-name="Path 97548"
            d="M300.383 153.432c0 .273-18.132.246-40.486-.058s-40.476-.773-40.476-1.042 18.128-.246 40.49.059 40.476.773 40.472 1.045"
            fill="#263238"
          />
          <path
            data-name="Path 97549"
            d="M300.383 141.103c0 .273-18.132.246-40.486-.058s-40.48-.773-40.476-1.046 18.128-.246 40.49.059 40.476.773 40.472 1.045"
            fill="#263238"
          />
          <path
            data-name="Path 97550"
            d="M300.383 128.774c0 .273-18.132.246-40.486-.058s-40.48-.773-40.476-1.046 18.128-.246 40.49.059 40.476.773 40.472 1.045"
            fill="#263238"
          />
          <path
            data-name="Path 97551"
            d="M299.159 167.194c0 .273-18.132.246-40.486-.058s-40.48-.773-40.476-1.046 18.128-.246 40.49.059 40.477.773 40.473 1.045"
            fill="#263238"
          />
          <path
            data-name="Path 97552"
            d="M298.924 180.717c0 .273-18.132.246-40.486-.058s-40.48-.773-40.476-1.046 18.128-.246 40.49.058 40.477.773 40.473 1.046"
            fill="#263238"
          />
          <path
            data-name="Path 97553"
            d="M298.688 194.24c0 .273-18.132.246-40.486-.058s-40.48-.773-40.476-1.046 18.128-.246 40.49.059 40.477.773 40.473 1.045"
            fill="#263238"
          />
          <path
            data-name="Path 97554"
            d="M337.629 79.004A25.627 25.627 0 1 1 312 53.356a25.637 25.637 0 0 1 25.629 25.648"
            fill="#69f"
          />
          <g data-name="Group 102573" opacity={0.5}>
            <g data-name="Group 102572">
              <g
                data-name="Group 102571"
                clipPath="url(#G)"
                transform="translate(286.376 53.355)"
              >
                <path
                  data-name="Path 97555"
                  d="M51.296 25.649A25.648 25.648 0 1 1 25.648.002a25.647 25.647 0 0 1 25.648 25.647"
                />
              </g>
            </g>
          </g>
          <path
            data-name="Path 97556"
            d="M334.814 79.004a25.627 25.627 0 1 1-25.627-25.647 25.637 25.637 0 0 1 25.627 25.647"
            fill="#69f"
          />
          <path
            data-name="Path 97557"
            d="M309.367 94.12h-.361a1.317 1.317 0 0 1-1.317-1.317v-27.6a1.317 1.317 0 0 1 1.317-1.317h.361a1.317 1.317 0 0 1 1.317 1.317v27.6a1.317 1.317 0 0 1-1.317 1.317"
            fill="#fafafa"
          />
          <path
            data-name="Path 97558"
            d="M324.292 78.822v.361a1.317 1.317 0 0 1-1.317 1.317h-27.576a1.317 1.317 0 0 1-1.317-1.317v-.361a1.317 1.317 0 0 1 1.317-1.317h27.575a1.317 1.317 0 0 1 1.317 1.317"
            fill="#fafafa"
          />
        </g>
      </g>
    </g>
  </svg>
)
