import { Box, Grid } from "@mui/material";
import React from "react";
import {
  TerminationDocument,
  SkipForm,
  DeclineForm,
} from "../../../assets";
import {
  Assigned,
  ExpiredIcon,
  Finace,
  KYC,
} from "../../../assets/agreementTimelineStatusIcon";
import { AlertDialog, DialogBox } from "../../../components";
import {
  StatusComponent,
  Form,
  ApprovedDialog
} from "../components";
import DetailCard from "../components/detailCard";
import TimelineForm from "../components/timelineForm";
import { useStyles } from "../styles";
import {
  renderFunctionTitle,
  renderFunctionSub,
  renderFunctionTitleType,
  renderFunctionSubType,
  getNextstatus
} from "../../../utils/agreementUtils";
import { Cards } from './cardComponents';
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";


export const Agreement = ({
  list = [],
  details = {},
  reload = () => false,
  t,
  i = 0,
  is_status_card = false,
  agreement_id = "",
  is_loaded = "",
  statusType = [],
  type = "",
  load = "",
  step = 0,
  showType = false,
  is_terminated = false,
  is_overall=false
}) => {
  const classes = useStyles();
  // context
  const alert = React.useContext(AlertContext);
  // state
  const [open, setOpen] = React.useState({
    bool: false,
    type: "",
    isType: false,
    isUnderOnboard: false,
    skipBool: false,
    Decline: false,
    cardShow: false,
  });
  const [remark, setRemark] = React.useState({
    skip: "",
    decline: "",
  });
  const [cancel, setCancel] = React.useState(false)
  const [approved, setApproved] = React.useState({
    data: "",
    bool: false
  })
  const onRemarkChange = (key, value) => {
    setRemark({ ...remark, [key]: value });
  };
  const onClick = (val, data, isType, isUnderOnboard, is_show) => {
    if (data?.type === "Approved" && data?.status === "Review") {
      return updateApproved("open", data, "Intiate")
    }
    if (data?.type === "Approved" && data?.status === "KYC Screening") {
      return updateApproved("open", data, "Create")
    }
    if (data?.type === "Approved" && data?.status === "Finance Review") {
      return updateApproved("open", data, "Create")
    }
    if (data?.status === "Finance Review" && data?.type === "Declined") {
      return updateApproved("open", data, "Finance_Create")
    }
    if (data?.status === "Onboarding" && data?.type === "Declined") {
      return updateApproved("open", data, "Create")
    }
    // updateStatus(data)
    setOpen({
      ...open,
      bool: !open?.bool,
      type: val ?? open?.type,
      data: data,
      isType,
      isUnderOnboard,
      cardShow: is_show,
    });
  };
  const onClickSkip = (val, data, isType, isUnderOnboard) => {
    setOpen({
      ...open,
      skipBool: !open?.skipBool,
      type: val ?? open?.type,
      data: data,
      isType,
      isUnderOnboard,
    });
  };
  const onClickDecline = (val, data) => {
    setOpen({
      ...open,
      Decline: !open?.Decline,
      type: val ?? open?.type,
      data: data,
    });
  };
  const renderFunction = (val, type) => {
    switch (val) {
      case "Live":
        return (
          <Box>
            {type?.length > 0 ? (
              <>
                {type === "Declined" ? (
                  <img src="/images/exicutedDeclined.svg" alt="Declined" />
                ) : (
                  <img src="/images/onBoard.svg" alt="onBoard" />
                )}
              </>
            ) : (
              <img src="/images/onBoard.svg" alt="onBoard" />
            )}
          </Box>
        );
      case "Approved":
        return (
          <>
            <Box>
              {type?.length > 0 ? (
                <>
                  {type === "Declined" ? (
                    <img src="/images/exicutedDeclined.svg" alt="Declined" />
                  ) : (
                    <img src="/images/onBoard.svg" alt="onBoard" />
                  )}
                </>
              ) : (
                <img src="/images/onBoard.svg" alt="onBoard" />
              )}
            </Box>
          </>
        );
      case "Review":
        return (
          <Box>
            {type?.length > 0 ? (
              <>
                {type === "Declined" ? (
                  <img src="/images/exicutedDeclined.svg" alt="Declined" />
                ) : (
                  <img src="/images/onBoard.svg" alt="onBoard" />
                )}
              </>
            ) : (
              <Assigned />
            )}
          </Box>
        );
      case "KYC Screening":
        return (
          <Box>
            {type?.length > 0 ? (
              <>
                {type === "Declined" ? (
                  <img src="/images/exicutedDeclined.svg" alt="Declined" />
                ) : (
                  <img src="/images/onBoard.svg" alt="onBoard" />
                )}
              </>
            ) : (
              <KYC />
            )}
          </Box>
        );
      case "Finance Review":
        return (
          <Box>
            {type?.length > 0 ? (
              <>
                {type === "Declined" ? (
                  <img src="/images/exicutedDeclined.svg" alt="Declined" />
                ) : (
                  <img src="/images/onBoard.svg" alt="onBoard" />
                )}
              </>
            ) : (
              <Finace />
            )}
          </Box>
        );
      case "Confirmed":
        return (
          <Box>
            {type?.length > 0 ? (
              <>
                {type === "Declined" ? (
                  <img src="/images/exicutedDeclined.svg" alt="Declined" />
                ) : (
                  <img src="/images/onBoard.svg" alt="onBoard" />
                )}
              </>
            ) : (
              <Assigned />
            )}
          </Box>
        );
      case "Onboarding":
        return (
          <Box>
            {type?.length > 0 ? (
              <>
                {type === "Declined" ? (
                  <img src="/images/exicutedDeclined.svg" alt="Declined" />
                ) : (
                  <img src="/images/onBoard.svg" alt="onBoard" />
                )}
              </>
            ) : (
              <KYC />
            )}
          </Box>
        );
      case "Terminated":
        return (
          <Box>
            {type?.length > 0 ? (
              <>
                {type === "Declined" ? (
                  <img src="/images/exicutedDeclined.svg" alt="Declined" />
                ) : (
                  <img src="/images/onBoard.svg" alt="onBoard" />
                )}
              </>
            ) : (
              <img src="/images/termintaed.svg" alt="Declined" />
            )}
          </Box>
        );
      case "Expired":
        return (
          <Box>
            {type?.length > 0 ? (
              <ExpiredIcon />
            ) : (
              <img src="/images/termintaed.svg" alt="Declined" />
            )}
          </Box>
        );
      case "Declined":
        return (
          <Box>
            {type?.length > 0 ? (
              <img src="/images/exicutedDeclined.svg" alt="Declined" />
            ) : (
              <img src="/images/termintaed.svg" alt="Declined" />
            )}
          </Box>
        );
      default:
        return (
          <Box>
            <img src="/images/onBoard.svg" alt="onBoard" />
          </Box>
        );
    }
  };
  const renderCompoent = (val) => {
    switch (val?.status) {
      case "Review":
        return (
          <>
            <DetailCard
              data={val}
              backgroundColor={"#fff"}
              backgroundColorCard={"#F5F7FA"}
            />
          </>
        );
      case "KYC Screening":
        return (
          <>
            <DetailCard
              data={val}
              backgroundColor={"#fff"}
              backgroundColorCard={"#F5F7FA"}
            />
          </>
        );
      case "Finance Review":
        return (
          <>
            <DetailCard
              data={val}
              backgroundColor={"#fff"}
              backgroundColorCard={"#F5F7FA"}
            />
          </>
        );
      case "Confirmed":
        return (
          <>
            <DetailCard
              data={val}
              backgroundColor={"#fff"}
              backgroundColorCard={"#F5F7FA"}
            />
          </>
        );
      case "Onboarding":
        return (
          <>
            <DetailCard
              data={val}
              backgroundColor={"#fff"}
              backgroundColorCard={"#F5F7FA"}
            />
          </>
        );
      case "Approved":
        return (
          <>
            <DetailCard data={val} />
          </>
        );
      default:
        return <DetailCard data={val} />;
    }
  };
  //move out 
  const moveOutStatus = () => {
    const variables = {
      agreement_id: details?.id,
    };

    NetworkCall(
      `${config.api_url}/move_out/convert_move_out`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Terminated Successfully",
        });
        reload();
        cancelDialog()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // TERMINATED AGREEMENT
  const termintation = () => {
    const variables = {
      agreement_id: details?.id,
      status: "Terminated",
    };

    NetworkCall(
      `${config.api_url}/agreement_tracker`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        moveOutStatus()
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const cancelDialog = () => {
    setCancel(!cancel)
  }
  //update status
  const updateStatus = (value) => {
    const variables = {
      agreement_id: details?.id,
      status: getNextstatus(approved?.data?.status),
      contact_id: details?.contact?.id,
      type: approved?.type === "Create" ? "Approved" : undefined
    };

    if ((approved?.data?.status === "KYC Screening" && approved?.data?.type === "Approved" )||( approved?.data?.type === "Finance Review" && approved?.data?.type === "Declined")) {
      variables["type"] = undefined
    }
    if (approved?.data?.status === "Finance Review" && approved?.data?.type === "Declined") {
      variables["status"] = "Finance Review"
    }
    NetworkCall(
      `${config.api_url}/agreement_tracker`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        updateApproved("close")
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Log Created Successfully",
        });
        reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateApproved = (key, data, type) => {
    if (key === "close") {
      setApproved({ ...approved, bool: !approved?.bool })
    }
    else {
      setApproved({ ...approved, data: data, bool: !approved?.bool, type: type })
    }
  }

  const popupTitle = {
    "Intiate": t("initiate"),
    "Create": t("create"),
    "Finance_Create": t("create")
  }
  return (
    <Box>
      <Grid container spacing={12}>
        <Grid item xs={9}>
          <Box>
            <Box className={classes.parent}>
              {Array.isArray(list) &&
                list?.map((val, index) => {
                  return (
                    <Box className={classes.treeBox}>
                      {index === 0 &&
                        is_status_card === false &&
                        val?.status !== "Live" &&
                        val?.status !== "Terminated" && (
                          <Box className={classes.border}>
                            <Box className={classes.avatars}>
                              <TerminationDocument />
                            </Box>
                            <StatusComponent
                              data={val}
                              t={t}
                              onClick={onClick}
                              title={
                                val?.type?.length > 0
                                  ? renderFunctionTitle(
                                    val?.status,
                                    val?.type
                                  )
                                  : renderFunctionTitleType(
                                    val?.status,
                                    val?.type
                                  )
                              }
                              sub={
                                val?.type?.length > 0
                                  ? renderFunctionSub(val?.status, val?.type)
                                  : renderFunctionSubType(
                                    val?.status,
                                    val?.type
                                  )
                              }
                              isOnboard={
                                val?.type?.length > 0
                                  ? val?.type !== "Declined"
                                    ? true
                                    : false
                                  : true
                              }
                              isButton={val?.type?.length > 0 ? false : true}
                              marginLeft={
                                val?.type?.length > 0 ? "0px" : "10px"
                              }
                              isStatus={
                                val?.type?.length > 0
                                  ? val?.type === "Declined"
                                    ? true
                                    : false
                                  : true
                              }
                              is_show={val?.type?.length > 0 ? false : true}
                              onClickSkip={onClickSkip}
                              onClickDecline={onClickDecline}
                              index={i}
                              status={ val?.status}
                            />
                          </Box>
                        )}
                      {val?.show !== "is_show" && (
                        <Box className={classes.border}>
                          <Box
                            className={
                              val?.assignee !== null
                                ? classes.avatars
                                : classes.avatars1
                            }
                          >
                            {renderFunction(val?.status, val?.type)}
                          </Box>
                          {renderCompoent(val)}
                        </Box>
                      )}
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          {
            !is_loaded && <Cards details={details} reload={reload} t={t} agreement_id={agreement_id} statusType={statusType} type={type} loadApi={load} step={step} showType={showType} termintation={termintation} cancel={cancel} cancelDialog={cancelDialog} is_terminated={is_terminated} is_overall={is_overall}/>
          }

        </Grid>
      </Grid>

      <AlertDialog
        isNormal={true}
        header={t(`${open?.type === "assign" ? "Assign Task" : open?.type}`)}
        onClose={() => onClick(null)}
        open={open?.bool}
        component={
          <TimelineForm
            reload={reload}
            data={open?.data}
            type={open?.type}
            isType={open?.isType}
            isUnderOnboard={open?.isUnderOnboard}
            company={details?.company?.id}
            details={details}
            onClose={() => onClick(null)}
            is_show={open?.cardShow}
          />
        }
      />

      <AlertDialog
        isNormal={true}
        header={t(`Skip This Process`)}
        onClose={() => {
          onClickSkip(null);
          onRemarkChange("skip", "");
        }}
        open={open?.skipBool}
        component={
          <>
            <Form
              image={<SkipForm />}
              title={t(`Skip This Process`)}
              descrition={t(`Skip_des`)}
              remarks={remark?.skip ?? ""}
              t={t}
              onChange={(e) => onRemarkChange("skip", e)}
              btnText={{
                btn1: t(`No`),
                btn2: t(`Yes_Skip`),
              }}
              no={() => {
                onClickSkip(null);
                onRemarkChange("skip", "");
              }}
              reload={reload}
              data={open?.data}
              type={open?.type}
              isType={open?.isType}
              isUnderOnboard={open?.isUnderOnboard}
              company={details?.company?.id}
              details={details}
            />
          </>
        }
      />

      <AlertDialog
        isNormal={true}
        header={t(`Decline`)}
        onClose={() => {
          onClickDecline(null);
          onRemarkChange("decline", "");
        }}
        open={open?.Decline}
        component={
          <>
            <Form
              image={<DeclineForm />}
              title={t(`Decline`)}
              descrition={t(`Declined_des`)}
              remarks={remark?.decline ?? ""}
              t={t}
              onChange={(e) => onRemarkChange("decline", e)}
              btnText={{
                btn1: t(`No`),
                btn2: t(`Yes`),
              }}
              no={() => {
                onClickDecline(null);
                onRemarkChange("decline", "");
              }}
            />
          </>
        }
      />

      {/*  */}
      <DialogBox
        isnotTitle
        onClose={() => updateApproved("close")}
        header={approved?.type}
        open={approved?.bool}
        maxWidth={"sm"}
        isNormal={true} component={
          <Box>
            <ApprovedDialog title={popupTitle[approved?.type]} sub={t("process")} t={t} termination={updateStatus} cancel={() => updateApproved('close')} />
          </Box>
        } />


    </Box>
  );
};
