import React from "react";
import { Box, Typography, Grid, Avatar } from "@mui/material";
import { useStyles } from "./style";
import { TickIcon } from "../../assets";
import { SearchFilter } from "../../components";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import InfiniteScroll from "react-infinite-scroll-component";

export const SelectAnnounsment = ({
  title = "",
  subtitle = "",
  selectAll = "",
  selectAllValue = false,
  count = "01",
  searchValue = "",
  placeHolder = "",
  searchFunction = () => false,
  data = [],
  chooseFuncion = () => false,
  selectAllFunction = () => false,
  fetchMoreData = () => false,
  height = "100%",
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <span className={classes.draft}>{count}</span>
          <Box ml={"14px"}>
            <Typography className={classes.title}>{title ?? ""}</Typography>
            <Typography className={classes.subtitle}>
              {subtitle ?? ""}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center"  onClick={selectAllFunction} className={classes.TickIcon}>
          <TickIcon
            color={selectAllValue ? "#5078E1" : "#e4e8ee"}
          />
          <Typography className={classes.selectAll}>{selectAll}</Typography>
        </Box>
      </Box>
      <Box height={"16px"} />
      {/* search box */}
      <SearchFilter
        value={searchValue}
        placeholder={placeHolder}
        handleChange={(value) => searchFunction(value)}
        marginBottom="0px !important"
      />
      <Box height={"16px"} />
      {/* options */}

      <Box className={classes.divRoot}>
        <InfiniteScroll
          dataLength={data?.length ?? ""}
          next={fetchMoreData}
          hasMore={true}
          height={"calc(100vh - 417px)"}
        >
          <Grid container spacing={2}>
            {data?.map((x, index) => {
              return (
                <Grid item xs={4}>
                  <Box className={classes.selectBox}>
                    <Box display="flex" alignItems="center">
                      {x?.logo && (
                        <img
                          src={x?.logo}
                          alt="images"
                          className={classes.images}
                        />
                      )}

                      <Box ml={x?.logo ? 2 : 0}>
                        <Typography className={classes.optionTitle}>
                          {x?.title?.length > 0
                            ? `${x?.title?.substring(0, 13)}${
                                x?.title?.length > 13 ? "..." : ""
                              } `
                            : "-"}
                        </Typography>
                        <Typography className={classes.optionSub}>
                          {x?.subTitle?.length > 0
                            ? `${x?.subTitle?.substring(0, 15)}${
                                x?.subTitle?.length > 15 ? "..." : ""
                              } `
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Avatar
                        className={
                          x?.is_active
                            ? classes.completedCheckboxStyle
                            : classes.checkboxStyle
                        }
                        variant="circular"
                        onClick={() => {
                          chooseFuncion(!x?.is_active, index, "is_active");
                        }}
                      >
                        <DoneOutlinedIcon
                          className={classes.checkboxIconStyle}
                        />
                      </Avatar>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
      </Box>
    </div>
  );
};
