import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold,
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
  text: {},
  required: {
    color: "red",
    fontFamily: SemiBold,
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
  textbox: {
    backgroundColor: (props) => props?.color ?? "auto",
    borderRadius: theme.palette.borderRadius,
    border: "none",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor: '#fff'
    },
    [`& fieldset`]: {
      borderRadius: theme.palette.borderRadius,
      height: (props) => (props.multiline ? "unset" : props?.height ?? 50),
      border: (props) => props?.border ?? "1.5px solid #E2E2E2",
      "&:hover": {
        border: "1px solid red",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => props?.padding ?? "11px 14px",
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#5078E1',
      },

    },
  },


}));