import { Box, Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { CalendarDateChange } from '../../../components';
import { AlertContext } from '../../../contexts';
import { AlertProps } from '../../../utils';
import { MonthlyCalendarStyles } from '../style';

export const CustomCalendar = ({ month = {}, dates = {}, prev = () => false, next = () => false, selectedDate = [], setSelectedDate = () => false }) => {
    const classes = MonthlyCalendarStyles()
    const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
    const alert = React.useContext(AlertContext)
    const dateRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());

        const dates = [];

        while (date <= endDate) {
            dates.push(moment(new Date(date)).format("DD-MM-YYYY"));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }
    const getSelDate = (val) => {
        const d1 = new Date(moment(selectedDate?.startDate?.[0]).format("YYYY-MM-DD"));
        const d2 = new Date(moment(val).format("YYYY-MM-DD"));
        let inbetweenDates=dateRange(d1, d2)

        if (moment(val).format("YYYY-MM-DD") > moment(selectedDate?.startDate?.[0]).format("YYYY-MM-DD")) {
            setSelectedDate({ ...selectedDate, endDate: [new Date(val).toISOString()],rangeDate:inbetweenDates })
        }
        else if(moment(val).format("MM-DD-YYYY") === moment(selectedDate?.startDate?.[0]).format("MM-DD-YYYY")){
            setSelectedDate({...selectedDate,endDate:[],rangeDate:[]})
        }
        else{
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please choose date greater than start date",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }
    }
    return (
        <Box p={1}>
            <Box className={classes.calheaderCustom}>
                <CalendarDateChange state={{"Date":month}} prev={prev} next={next} comp={"month"} />
            </Box>
            <Grid container>

                {days.map((i) => {
                    return (
                        <Grid item xs={1.7}>
                            <Typography className={classes.daysTextCustom}>{i}</Typography>
                        </Grid>
                    )
                })}

            </Grid>

            <Grid container mt={0} className={classes.calDatesCustom}>
                {
                    dates?.map((e) => {
                        return (
                            <Grid item xs={1.7} className={(moment(e).format("M") === moment(month).format("M"))&&(selectedDate?.startDate?.[0]?.includes(new Date(e).toISOString()) || selectedDate?.endDate?.[0]?.includes(new Date(e).toISOString())) ? classes.seldate_outlinedcustom:((moment(e).format("M") === moment(month).format("M"))&&selectedDate?.rangeDate?.includes(moment(new Date(e)).format("DD-MM-YYYY")))?classes.seldate_range : classes.date_outlinedcustom} onClick={() => getSelDate(e)}>
                                <Stack mt={1} justifyContent={"space-between"} height="100%" pb={2}>

                                    <Box className={(selectedDate?.startDate?.[0] === new Date(e).toISOString() || selectedDate?.endDate?.[0] === new Date(e).toISOString()) ? classes.seldateCustom : classes.dateCustom}><span className={moment(e).format("M") === moment(month).format("M")
                                        ? " " : classes.hidden_other_month_dates}>{moment(e).format("DD")}</span></Box>
                                </Stack>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}