import React, { useState, useEffect , useLayoutEffect} from "react";
import { MapComponent } from "./components";
import { AlertContext } from "../../contexts";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import Dialog from '@mui/material/Dialog';

import { UseDebounce } from "../../components";

const initial = {
  location: "",
  status: "Vacant",
  area: "Total Area",
};
export const Map = (props) => {
  const [mapLoading, setMapLoading] = useState(true);
  const [locationDetails, setLocationDetails] = useState("");
  const [state, setState] = useState({ ...initial });
  const [zoom, setZoom] = useState(false);

   //debounce hook
   const debounce = UseDebounce();

  // context
  const alert = React.useContext(AlertContext);
  //   update state
  const updateState = (key, value) => {
    if (key === "status") {
      getLocation(value, state?.location?.latitude, state?.location?.longitude);
    }
    setState({ ...state, [key]: value });
  };
  // get location
  const getLocation = (e, lat, long) => {
    // if (!zoom) {
    //   backdrop.setBackDrop({
    //     ...backdrop,
    //     open: true,
    //     message: "Getting Map View ...",
    //   });
    // }
    let teamsId = props?.selectedTeams?.value;

    setMapLoading(true);
    const payload = {
      company_id: [props?.selectedcompany?.value],
      status: e ?? undefined,
      lat: lat ?? undefined,
      long: long ?? undefined,
    };
    if (teamsId !== "noteams") {
      payload["team"] = teamsId
      payload["team_all_property"] = true

    }
    NetworkCall(
      `${config.api_url}/dashboard/property/location`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setLocationDetails({
          data: res?.data?.data,
          total: res?.data?.total,
        });
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
        setMapLoading(false);
      })
      .catch((error) => {
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
        setMapLoading(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  //
  const sumbit = () => {
    getLocation(
      state?.status,
      state?.location?.latitude,
      state?.location?.longitude
    );
  };
  // zoom function
  const zoomGraphFunction = (e) => {
    setZoom(true);
    setMapLoading(false);
  };
  const handleCloseModule = () => {
    setZoom(false);
  };

  //   useEffect
  useLayoutEffect(() => {
    if (props?.selectedcompany?.label?.length > 0) {
      getLocation(state?.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedTeams]);

  useEffect(() => {
    debounce(() =>  getLocation(state?.status), 800);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <MapComponent
        title={"Map View"}
        mapData={locationDetails?.data ?? []}
        total={locationDetails?.total}
        updateState={updateState}
        data={state}
        tabNameState={state?.status}
        tabList={["Vacant", "Occupied"]}
        keyType={"status"}
        mapLoading={mapLoading}
        sumbit={sumbit}
        height={"100%"}
        onZoomClick={zoomGraphFunction}
        isZoom
        flexFlow={"column"}
      />
      {/* zoom dialog for each graph */}
      <Dialog
        fullScreen
        open={zoom}
      >
        {
          zoom && 
          <MapComponent
          is_close
          title={"Map View"}
          mapData={locationDetails?.data ?? []}
          total={locationDetails?.total}
          updateState={updateState}
          data={state}
          tabNameState={state?.status}
          tabList={["Vacant", "Occupied"]}
          keyType={"status"}
          mapLoading={!zoom}
          sumbit={sumbit}
          height={"calc(100vh - 10px)"}
          onZoomClick={handleCloseModule}
        />
        }
        
      </Dialog>
    </>
  );
};
