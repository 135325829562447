export const TableName = {
    resident: "Resident",
    owner: "Owners",
    activeDiscussion: "Active Discussion",
    todayActivity: "Today Activity",
    amenitiesBookingCalendar:"Amenities Booking Calendar"
}

export const ResidentPath = ["saleRep", "unitName",]

export const ResidentHeading = [
    { title: "Sale Rep", field: "saleRep" },
    { title: "Unit Name", field: "unitName" },
]

export const ResidentType = [
    { type: ["avatarText"], name: "saleRep" },
    { type: ["text"], name: "unitName" },
]

export const OwnerPath = ["saleRep", "unitName",]

export const OwnerHeading = [
    { title: "Sale Rep", field: "saleRep" },
    { title: "Unit Name", field: "unitName" },
]

export const OwnerType = [
    { type: ["avatarText"], name: "saleRep" },
    { type: ["text"], name: "unitName" },
]