import { Grid } from '@mui/material';
import React from 'react';
import { CalendarDateChange } from '../../../components/calendarDateChange';
import { DropdownCard } from './dropdownCard';

export const ScheduleCalendarHeader = ({ state = {}, prev = () => false, next = () => false, resource = [], setSelectedType = () => false, selectedType = {}, type = "", setType = "" }) => {
    return (
        <Grid container alignItems={"center"} >
            <Grid item xs={2}>
                <DropdownCard setSelectedType={setSelectedType} selectedType={selectedType} options={resource} />
            </Grid>
            <Grid item xs={3} />
            <Grid xs={4}>
                <CalendarDateChange state={state} prev={prev} next={next} comp={type === "Monthly" ? "month" : type === "Daily" ? "daily" : "weekly"} />
            </Grid>
            {/* <Grid item xs={2}>
                <CustomDropDown type={type} setType={setType} />
            </Grid> */}
        </Grid>
    )
}