import React from "react";
import { withNavBars } from "../../../HOCs";
import { UtilitiesMappingUnitView } from "./utilitiesMappingUnitView";

class UtilitiesMappingUnitViewParent extends React.Component {
    render() {
        return <UtilitiesMappingUnitView />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(UtilitiesMappingUnitViewParent, props);
