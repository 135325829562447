/* eslint-disable react/jsx-no-target-blank */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Avatar,
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Popover,
  Select, Stack, TableRow,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import ReactQuill from "react-quill";
import EditIMG from "../../assets/edit";
import InfoIcon from "../../assets/infoIcon";
import { Bold, enumName, Regular, SemiBold, convertTimeUtcToZoneCalander, timeZoneConverter } from "../../utils";
import { CustomCheckBox } from "../customeCheckBox";
import { TextBox } from "../../components"

const StyledTableCell = styled(TableCell)(({ theme, headingColor }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.typography.color.secondary,
    fontSize: "14px",
    fontFamily: `${SemiBold}`,
    border: 0,
    backgroundColor: headingColor ?? "#F2F4F7",
    padding: "8px",
  },

  [`&.${tableCellClasses.body}`]: {
    color: theme.typography.color.primary,
    fontSize: "14px",
    fontFamily: `${Regular}`,
    // border: 0,
    whiteSpace: "normal",
    wordWrap: "break-word",
    backgroundColor: headingColor ?? "auto",
    padding: "0px 8px",

  },
}));

const useStyles = makeStyles((theme) => ({
  arrow: {
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    fontSize: "16px",
    color: "black",
  },
  button: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: 10,
  },
  paginate: {
    "& .MuiButtonBase-root.MuiPaginationItem-root": {
      borderRadius: theme.palette.borderRadius,
      border: "none",
      color: "#606060",
    },
    "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
      color: "white",
      borderRadius: theme.palette.borderRadius,
      border: "none",
      backgroundColor: "#5078E1",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  typo: {
    fontSize: "14px",
    color: "#4E5A6B",
    fontFamily: `${Bold}`,
    fontWeight: "bold",
    margin: 0,
    overflow: "hidden",
  },
  tabletopbtn: {
    fontWeight: "lighter",
    boxShadow: "none",
    marginLeft: "6px",
    backgroundColor: "#F8F8F8",
    border: "1px solid #E9E9E9",
    borderRadius: "6px",
    color: "black",

    "&:nth-child(1)": {
      textTransform: "capitalize",
    },
    "&:hover": {
      backgroundColor: "#F8F8F8",
      border: "1px solid #E9E9E9",
      borderRadius: "6px",
      boxShadow: "none",
    },
  },
  root: {
    whiteSpace: "noWrap",
    "& .MuiTableContainer-root": {
      marginTop: (props) => props?.marginTop,
      "&::-webkit-scrollbar": {
        height: 6,
      },
      "& table": {
        borderCollapse: "separate",
      },
    },
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  change: {
    textDecoration: "underline",
    color: "#4D7EFF",
    cursor: "pointer",
    marginLeft: 70,
  },
  thead: {
    backgroundColor: "#F2F4F7",
    "& th": {
      zIndex: 0,
    },
    "& th:first-child": {
      borderRadius: (props) =>
        props.tableType === "normal" ? "4px 0px 0px 4px" : "4px 0 0 0",
    },
    "& th:last-child": {
      borderRadius: (props) =>
        props.tableType === "normal" ? "0 4px 4px 0" : "0 4px 0 0",
    },
  },
  tbody: (props) => ({
    height: "48px",
    cursor: "pointer",
    "&.Mui-selected": {
      backgroundColor: "#F1F7FF",
    },
    "&:hover": {
      backgroundColor: "#F1F7FF",
    }, // border: (props.tableType === "normal" ? 0 : "1px solid #E4E8EE"),
    backgroundColor: props?.cellBackground
      ? props?.cellBackground
      : props.tableType === "normal"
        ? "#F2F4F7"
        : "white",
    "& th": {
      borderBlock: props.tableType === "normal" ? 0 : "0.5px solid #E4E8EE",
    },
    "& th:first-child": {
      borderRadius: props.tableType === "normal" ? "4px 0px 0px 4px" : 0,
      borderLeft:
        props.tableType === "normal"
          ? 0
          : props?.tableType === "no-side"
            ? 0
            : "1px solid #E4E8EE",
    },
    "& th:last-child": {
      borderRadius: props.tableType === "normal" ? "0 4px 4px 0" : 0,
      borderRight:
        props.tableType === "normal"
          ? 0
          : props?.tableType === "no-side"
            ? 0
            : "0.5px solid #E4E8EE",
    },
  }),
  select: {
    color: "red",
    "& .MuiSelect-select": {
      paddingBlock: 0,
      fontSize: "14px",
      color: "#4E5A6B",
      fontFamily: `${Bold}`,
      fontWeight: "bold",
      height: 20,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  noRecordDiv: {
    textAlign: "center",
    marginTop: 30,
  },
  menu: {
    "& .MuiPopover-paper": {
      boxShadow: "0 0px 8px -4px #f2f3f5",
      border: "1px solid #f2f3f5",
      borderRadius: "5px",
    },
  },
  active: {
    fontFamily: `${Regular}`,
    fontSize: "14px",
    color: "#5AC782",
  },
  inprogress: {
    padding: "4px 6px",
    backgroundColor: "#78B1FE",
    borderRadius: theme.palette.borderRadius,
    display: "inline",
    color: "white",
    fontSize: "10px",
    fontFamily: `${Regular}`,
  },

  activego: {
    padding: "4px 6px",
    backgroundColor: "#5AC782",
    borderRadius: theme.palette.borderRadius,
    display: "inline",
    color: "white",
    fontSize: "10px",
    fontFamily: `${Regular}`,
  },
  inactivego: {
    padding: "4px 6px",
    backgroundColor: "#CED3DD",
    borderRadius: theme.palette.borderRadius,
    display: "inline",
    color: "white",
    fontSize: "10px",
    fontFamily: `${Regular}`,
  },
  inactive: {
    fontFamily: `${Regular}`,
    fontSize: "14px",
    color: "red",
  },
  more: {
    fontSize: "20px",
    color: "gray",
    cursor: "pointer",
  },
  menuList: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 0px 6px #0717411F",
      borderRadius: theme.palette.borderRadius,
      backgroundColor: "white",
    },
  },
  menuItem: {
    borderBottom: "1px solid #E4E8EE",
    margin: "0px 4px",
    fontSize: "14px",
    color: "#071741",
    fontFamily: `${Regular}`,
    "&:last-child": {
      border: 0,
    },
  },
  text: {
    fontFamily: `${Regular}`,
    fontSize: "14px",
    color: "#091B29",
    "& .ql-editor": {
      // padding: "0px 0px 16px 0px",
      color: theme.typography.color.primary,
      fontSize: "14px",
      fontFamily: `${Regular}`,
      textTransform: "capitalize",
    },
  },
  tooltip: {
    "&:hover": {
      backgroundColor: theme.typography.color.white,
    },
  },
  qstatus: {
    fontFamily: `${Regular}`,
    fontSize: "14px",
    textTransform: "capitalize",
    textAlign: "center",
    borderRadius: "4px",
    padding: "4px",
    color: "white",
  },
  infoIcon: {
    color: "#4E5A6B",
    fontSize: "20px",
  },
  yet: {
    fontFamily: `${Regular}`,
    fontSize: "14px",
    color: "#FF9340",
    textTransform: "capitalize",
  },
  link: {
    textDecoration: "underline",
    color: "#5078E1",
    fontSize: "14px",
    fontFamily: `${Regular}`,
  },
  moreBtn: {
    // float: "right"
  },
  red: {
    color: "white",
    backgroundColor: "red",
    fontSize: "14px",
    fontFamily: `${Regular}`,
    textAlign: "center",
    borderRadius: "4px",
    padding: "4px",
  },
  lightBlue: {
    color: "white",
    backgroundColor: "#78B1FE",
    fontSize: "14px",
    fontFamily: `${Regular}`,
    textAlign: "center",
    borderRadius: "4px",
    padding: "4px",
  },
  green: {
    color: "white",
    backgroundColor: "#5AC782",
    fontSize: "14px",
    fontFamily: `${Regular}`,
    textAlign: "center",
    borderRadius: "4px",
    padding: "4px",
  },
  yellow: {
    color: "white",
    backgroundColor: "#FAD500",
    fontSize: "14px",
    fontFamily: `${Regular}`,
    textAlign: "center",
    borderRadius: "4px",
    padding: "4px",
  },
  subTotal: {
    color: theme.typography.color.primary,
    fontSize: "14px",
    fontFamily: `${SemiBold}`,
    // border: 0,
    whiteSpace: "normal",
    wordWrap: "break-word",
    padding: "0px 8px",
  },
  assignstatus: {
    fontFamily: `${Regular}`,
    fontSize: "14px",
    color: "#78B1FE",
    textTransform: "capitalize",
  },
  rejectedstatus: {
    fontFamily: `${Regular}`,
    fontSize: "14px",
    color: "#FF4B4B",
    textTransform: "capitalize",
  },
  completeStatus: {
    fontFamily: `${Regular}`,
    fontSize: "14px",
    color: "#5AC782",
    textTransform: "capitalize",
  },
  date: {
    color: "#98A0AC",
    fontSize: "14px",
    fontFamily: SemiBold,
  },
  role: {
    fontSize: "14px",
    marginBottom: "10px",
    // backgroundColor: "#EBFBFF",
    color: "#2CA2BF",
    borderRadius: theme.palette.borderRadius,
    padding: "5px",
    width: "fit-content"
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px",
    padding: "12px",
    backgroundColor: "#FFFFFF",
    // color: "#4E5A6B",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.04)",
    fontSize: "14px",
    fontFamily: SemiBold,
  },
}));

const LongTextTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "400px",
    borderRadius: "4px",
    padding: "8px 12px",
    backgroundColor: "#FFFFFF",
    color: "#1C1C1C",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    fontSize: 14,
    fontFamily: SemiBold
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#FFFFFF"
  },
}));


export const TableWithPagination = (props) => {
  const classes = useStyles(props);
  const totalCount = props?.totalRowsCount > -1 ? props?.totalRowsCount : "";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [index, setIndex] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const modules = {
    toolbar: false,
  };
  const getComponentType = (val, data) => {
    const main = data.main;
    switch (data.type) {
      case "status": {
        if (data.value === "Approved") {
          return <p style={{ color: "#267C24", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Rejected") {
          return <p style={{ color: "red", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Pending") {
          return <p style={{ color: "#78B1FE", margin: "0" }}>{data.value}</p>;
          // } else if (data.value === "Lost Lead") {
          //   return <p style={{ color: "red", margin: '0' }}>{data.value}</p>;
        } else if (data.value === "Lost Lead") {
          return (
            <p className={classes.red} style={{ margin: "0" }}>
              {data.value}
            </p>
          );
        } else if (data.value === "In Progress") {
          return (
            <Typography
              className={classes.green}
              style={{ margin: "0" }}
              width="max-content"
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "Converted") {
          return (
            <p className={classes.lightBlue} style={{ margin: "0" }}>
              {data.value}
            </p>
          );
        } else if (data.value === "Cancelled") {
          return <p style={{ color: "#98A0AC", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Yet To Activate") {
          return <p style={{ color: "#FF9340", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Active") {
          return (
            <span className={classes.green} style={{ margin: "0" }}>
              {data.value}
            </span>
          );
        } else if (data.value === "InActive") {
          return <p style={{ color: "#FF4B4B", margin: "0" }}>{data.value}</p>;
        }
        else if (data.value === "Inactive") {
          return <p style={{ color: "#FF4B4B", margin: "0" }}>{data.value}</p>;
        }
        else if (data.value === "Quote Accepted") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "close" || data?.value === "closed") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#CED3DD",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Closed") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "red",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "requested for visit") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#FF9340",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "requested for re-quote accepted") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#FF9340",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "inProgress") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#78B1FE",
                fontSize: "14px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "In Progress") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#78B1FE",
                fontSize: "14px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "Active") {
          return (
            <Typography className={classes.green} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "Paid") {
          return (
            <Typography className={classes.active} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "Vacant") {
          return (
            <span className={classes.green} sx={{ margin: "0" }}>
              {data.value}
            </span>
          );
        } else if (data.value === "Occupied") {
          return (
            <span className={classes.red} sx={{ margin: "0" }}>
              {data.value}
            </span>
          );
        } else if (data.value === "Inactive") {
          return (
            <Typography className={classes.red} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "Unpaid") {
          return (
            <Typography className={classes.red} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "delete") {
          return (
            <div>
              {props?.delete && (
                <IconButton onClick={() => props.handleIcon("delete", main)}>
                  <DeleteForeverIcon color="primary" />
                </IconButton>
              )}
            </div>
          );
        } else if (data.value === true) {
          return (
            <Typography className={classes.active} sx={{ margin: "0" }}>
              Active
            </Typography>
          );
        } else if (data.value === false) {
          return (
            <Typography className={classes.inactive} sx={{ margin: "0" }}>
              Inactive
            </Typography>
          );
        } else {
          return data.value ?? "-";
        }
      }
      case "status2": {
        if (data?.value === "Closed") {
          return (
            <Typography
              sx={{
                color: "#000",
                backgroundColor: "#F2F4F7",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else {
          return data.value;
        }
      }
      case "maintenanceRequestStatus": {
        if (data?.value === "Closed") {
          return (
            <Typography className={classes.green} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Open") {
          return (
            <Typography className={classes.red} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else {
          return data.value;
        }
      }

      case "object_status": {
        if (data?.value.value === enumName.approved) {
          return (
            <Typography
              sx={{
                color: "#5AC782",
                fontSize: "14px",
                fontFamily: `${SemiBold}`,
                margin: "0",
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === enumName.cancelled) {
          return (
            <Typography
              sx={{
                color: "#4E5A6B",
                fontSize: "14px",
                fontFamily: `${SemiBold}`,
                margin: "0",
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === enumName.pending) {
          return (
            <Typography
              sx={{
                color: "#FF9340",
                fontSize: "14px",
                fontFamily: `${SemiBold}`,
                margin: "0",
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === enumName.rejected) {
          return (
            <Typography
              sx={{
                color: "#FF4B4B",
                fontSize: "14px",
                fontFamily: `${SemiBold}`,
                margin: "0",
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else {
          return data.value;
        }
      }

      case "kyc_status": {
        if (data.value === "Approved" || data.value === 'Verified' || data.value === "approved") {
          return <p style={{ color: "#5AC782", margin: "0", background: "#EEF9EE", width: 90, padding: 4, textAlign: "center", borderRadius: 4, fontWeight: "bold" }}>{data.value}</p>;
        } else if (data.value === "Declined" || data.value === 'Declined') {
          return <p style={{ color: "#FF4B4B", margin: "0", background: "#FFECEC", width: 90, padding: 4, textAlign: "center", borderRadius: 4, fontWeight: "bold" }}>{data.value}</p>;
        } else if (data.value === "Pending" || data.value === 'Yet to be Verified' || data.value === "pending") {
          return <p style={{ color: "#FF9340", margin: "0", background: "#FF93401A", width: data.value === 'Yet to be Verified' ? 150 : 90, padding: 4, textAlign: "center", borderRadius: 4, fontWeight: "bold" }}>{data.value}</p>;
          // } else if (data.value === "Lost Lead") {
          //   return <p style={{ color: "red", margin: '0' }}>{data.value}</p>;
        } else if (data.value === "Lost Lead") {
          return (
            <p className={classes.red} style={{ margin: "0" }}>
              {data.value}
            </p>
          );
        } else if (data.value === "In Progress") {
          return (
            <Typography
              className={classes.green}
              style={{ margin: "0" }}
              width="max-content"
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "Converted") {
          return (
            <p className={classes.lightBlue} style={{ margin: "0" }}>
              {data.value}
            </p>
          );
        } else if (data.value === "Cancelled") {
          return <p style={{ color: "#98A0AC", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Yet To Activate") {
          return <p style={{ color: "#FF9340", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Active") {
          return (
            <span className={classes.green} style={{ margin: "0" }}>
              {data.value}
            </span>
          );
        } else if (data.value === "InActive") {
          return <p style={{ color: "#FF4B4B", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Quote Accepted") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "close" || data?.value === "closed") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#CED3DD",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Closed") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "red",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "requested for visit") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#FF9340",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "requested for re-quote accepted") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#FF9340",
                fontSize: "10px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "inProgress") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#78B1FE",
                fontSize: "14px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "In Progress") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#78B1FE",
                fontSize: "14px",
                fontFamily: `${Regular}`,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "Active") {
          return (
            <Typography className={classes.green} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "Paid") {
          return (
            <Typography className={classes.active} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "Vacant") {
          return (
            <span className={classes.green} sx={{ margin: "0" }}>
              {data.value}
            </span>
          );
        } else if (data.value === "Occupied") {
          return (
            <span className={classes.red} sx={{ margin: "0" }}>
              {data.value}
            </span>
          );
        } else if (data.value === "Inactive") {
          return (
            <Typography className={classes.red} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "Unpaid") {
          return (
            <Typography className={classes.red} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "delete") {
          return (
            <div>
              {props?.delete && (
                <IconButton onClick={() => props.handleIcon("delete", main)}>
                  <DeleteForeverIcon color="primary" />
                </IconButton>
              )}
            </div>
          );
        } else if (data.value === true) {
          return (
            <Typography className={classes.active} sx={{ margin: "0" }}>
              Active
            </Typography>
          );
        } else if (data.value === false) {
          return (
            <Typography className={classes.inactive} sx={{ margin: "0" }}>
              Inactive
            </Typography>
          );
        } else {
          return data.value;
        }
      }


      case "icon": {
        let editviewtoggle = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton onClick={() => props.handleIcon("edit", main)}>
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("view", main)}>
                <RemoveRedEyeOutlinedIcon
                  sx={{ fontSize: "16px", color: "#98A0AC" }}
                />
              </IconButton>
            )}

            {props?.edit && (
              <Switch
                checked={data.is_active}
                onChange={(e) =>
                  props.handleIcon("toggle", main, e.target.checked)
                }
              />
            )}
          </div>
        );
        let editview = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  props.handleIcon("edit", main);
                }}
              >
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("view", main)}>
                <RemoveRedEyeOutlinedIcon
                  sx={{ fontSize: "16px", color: "#98A0AC" }}
                />
              </IconButton>
            )}
          </div>
        );
        let deleteIcon = (
          <div style={{ display: "flex" }}>
            {props?.delete && (
              <IconButton onClick={() => props.handleIcon("delete", main)}>
                <DeleteOutlineIcon
                  sx={{ fontSize: "16px", color: "#98A0AC" }}
                />
              </IconButton>
            )}
          </div>
        );
        let viewdelete = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton onClick={() => props.handleIcon("edit", main)}>
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.delete && (
              <IconButton onClick={() => props.handleIcon("delete", main)}>
                <DeleteOutlineIcon
                  sx={{ fontSize: "16px", color: "#98A0AC" }}
                />
              </IconButton>
            )}
          </div>
        );
        let edittoggle = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton
                onClick={() => props.handleIcon("edit", main, props?.edit)}
              >
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.edit && (
              <Switch
                checked={main.is_active}
                onChange={(e) => props.handleIcon("toggle", main, props?.edit)}
              />
            )}
          </div>
        );
        let viewedittoggle = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton
                onClick={() => props.handleIcon("edit", main, props?.edit)}
              >
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.view && (
              <IconButton
                onClick={() => props.handleIcon("view", main, props?.view)}
              >
                <RemoveRedEyeOutlinedIcon
                  sx={{ fontSize: "16px", color: "#98A0AC" }}
                />
              </IconButton>
            )}
            {props?.edit && (
              <Switch
                checked={main.is_active}
                onChange={(e) => props.handleIcon("toggle", main, props?.edit)}
              />
            )}
          </div>
        );

        if (data.icon === "editviewtoggle") {
          return editviewtoggle;
        }
        if (data.icon === "deleteIcon") {
          return deleteIcon;
        }
        if (data.icon === "viewedittoggle") {
          return viewedittoggle;
        }
        if (data.icon === "edittoggle") {
          return edittoggle;
        }
        if (data.icon === "viewdelete") {
          return viewdelete;
        }
        if (data.icon === "editview") {
          return editview;
        } else if (data.icon === "edit") {
          return (
            <div>
              {props?.edit && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    props.handleIcon("edit", main, props?.edit);
                  }}
                >
                  <EditIMG color="#98a0ac" />
                </IconButton>
              )}
            </div>
          );
        } else if (data.icon === "view") {
          return (
            <div>
              {props?.view && (
                <IconButton onClick={() => props.handleIcon("view", main)}>
                  <RemoveRedEyeOutlinedIcon
                    sx={{ fontSize: "16px", color: "#98A0AC" }}
                  />
                </IconButton>
              )}
            </div>
          );
        } else if (data.icon === "arrow") {
          return (
            <div style={{ display: "flex" }}>
              <IconButton size="small" className={classes.arrow}>
                <ArrowForwardIosIcon
                  size="small"
                  style={{ fontSize: "16px" }}
                />
              </IconButton>
            </div>
          );
        }

        break;
      }
      case "leadEdit": {
        return (
          <>
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("view", main)}>
                <RemoveRedEyeOutlinedIcon
                  sx={{ fontSize: "16px", color: "#98A0AC" }}
                />
              </IconButton>
            )}
          </>
        );
      }
      case "propertyType": {
        return (
          <>
            {data.value && (
              <Typography
                sx={{
                  color: "white",
                  backgroundColor: "#78B1FE",
                  fontSize: "14px",
                  fontFamily: `${Regular}`,
                  textAlign: "center",
                  borderRadius: "4px",
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{data.value}&nbsp;&nbsp;&nbsp;
              </Typography>
            )}
          </>
        );
      }
      case "blockType": {
        return (
          <>
            {data.value && (
              <Typography
                sx={{
                  color: "white",
                  backgroundColor: "#78B1FE",
                  fontSize: "14px",
                  fontFamily: `${Regular}`,
                  textAlign: "center",
                  borderRadius: "4px",
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{data.value}&nbsp;&nbsp;&nbsp;
              </Typography>
            )}
          </>
        );
      }
      case "checkBox": {
        return (
          <div>
            {props?.edit && (
              <Checkbox
                sx={{ padding: "0px" }}
                onChange={(e) =>
                  props.handleIcon("checkBox", main, e?.target?.checked)
                }
                color="success"
                checked={data.value}
                checkedIcon={<CheckCircleIcon />}
                icon={<CircleOutlinedIcon style={{ color: "#c1c1c1" }} />}
              />
            )}
          </div>
        );
      }
      case "tooltip": {
        return (
          <>
            <Button
              aria-describedby={props?.id}
              className={classes.tooltip}
              onClick={(e) => {
                e.stopPropagation();
                props?.handleHover(e, main);
              }}
            // onMouseLeave={props?.handleClose}
            >
              {data.value}
            </Button>
            <Popover
              className={classes.menu}
              style={{ boxShadow: "none !important" }}
              id={props?.id}
              open={props?.open}
              anchorEl={props?.anchorEl}
              onClose={props?.handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {props?.roles?.map((val) => {
                return <Typography sx={{ p: 1 }}>{val?.name}</Typography>;
              })}
            </Popover>
          </>
        );
      }
      case "avatar": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={data.image} sx={{ width: 34, height: 34 }} />
            <Typography style={{ marginLeft: 10 }}>{data.value}</Typography>
          </div>
        );
      }
      case "avatarText": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={data.value.image}
              sx={{ width: 34, height: 34, lineHeight: 0, fontSize: 14 }}
            >
              {data?.value?.name ? data?.value?.name?.charAt(0) : ""}
            </Avatar>
            <Typography style={{ marginLeft: 10 }}>
              {data.value.name}
            </Typography>
          </div>
        );
      }
      case "avatarmanagement": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={data.value} sx={{ width: 34, height: 34 }} />
          </div>
        );
      }
      case "text": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data.value ?? "-"}
          </Typography>
        );
      }
      case "community_text": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data.value === true ? "True" : "False"}
          </Typography>
        );
      }


      case "long_text": {
        return (
          <div style={{ maxWidth: "200px", display: "flex" }}>
            <LongTextTooltip title={data.value ?? ""} placement="right" arrow>
              <Typography
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                className={classes.text}
              >
                {data.value ?? "-"}
              </Typography>
            </LongTextTooltip>
          </div>
        );
      }

      case "textObject": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data?.value?.label ?? "-"}
          </Typography>
        );
      }
      case "textLink": {
        return (
          <div
            onClick={() => props?.onUnitClick(main, data)}
            style={{ textDecorationLine: "underline", color: "blue" }}
          >
            {data.value}
          </div>
        );
      }
      case "increment": {
        return data.index + 1;
      }
      case "image": {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <img src={data.image} alt={data.name}></img>
            <Typography style={{ marginLeft: 10 }}>{data.value}</Typography>
            {/* <Typography className={classes.change}>Change</Typography> */}
          </div>
        );
      }
      case "PFImage": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={data.value !== "" ? data.value : "/images/pf.svg"}
              sx={{ width: 34, height: 34 }}
            />
          </div>
        );
      }
      case "logo": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={data?.value?.logo}
              sx={{ width: 34, height: 34, lineHeight: 0, fontSize: 14 }}
            >
              {data?.value?.name ? data?.value?.name?.charAt(0) : ""}
            </Avatar>
          </div>
        );
      }
      case "img": {
        return (
          // <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={data?.main?.img}
              sx={{ width: 32, height: 32, borderRadius:4 }}
            >
            </Avatar>
          // </div>
        );
      }
      case "link": {
        return (
          <Typography
            className={classes.link}
            onClick={() => props?.handleLink(data)}
          >
            {main.url}
          </Typography>
        );
      }
      case "date": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data?.value
              ? convertTimeUtcToZoneCalander(data?.value)
              : "-"}
          </Typography>
        );
      }
      case "date_time": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data.value
              ? convertTimeUtcToZoneCalander(data.value, 'yyyy-MMM-dd hh:mm aaaa')
              : "-"}
          </Typography>
        );
      }
      case "hour": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data.value
              ? timeZoneConverter(data.value, 'hh:mm')
              : "-"}
          </Typography>
        );
      }
      case "description": {
        return (
          <div>
            <Typography
              noWrap
              readOnly
              className={classes.text}
              style={{
                wordBreak: "break-all",
                overflowWrap: "anywhere",
              }}
            >
              {data?.value?.length > 0
                ? `${data?.value?.substring(0, 15)}${data?.value?.length > 15 ? "..." : ""
                } `
                : "-"}
            </Typography>
          </div>
        );
      }
      case "quill": {
        return (
          <div>
            <ReactQuill
              noWrap
              readOnly
              theme="bubble"
              // value={data?.value ?? ""}
              value={
                data?.value?.length > 0
                  ? `${data?.value?.substring(0, 100)}${data?.value?.length > 10 ? "..." : ""
                  } `
                  : "-"
              }
              modules={modules}
              className={classes.text}
              style={{
                // wordBreak: "break-all",
                overflowWrap: "anywhere",
                width: "max-content",
              }}
            />
          </div>
        );
      }
      case "redirect": {
        return (
          <a target="_blank" href={data.value}>
            {data.value}
          </a>
        );
      }
      case "object": {
        return data.value[data?.objectOption] ?? "-";
      }
      case "phone": {
        return (
          <>
            {main.code} {main.mobile}
          </>
        );
      }
      case "more": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    Edit
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" || main.is_active === true
                      ? "In-active"
                      : "Active"}
                  </MenuItem>
                )}
                {props?.history && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("history", main, main.delete);
                    }}
                    className={classes.menuItem}
                  >
                    History
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("delete", main, main.delete);
                    }}
                    className={classes.menuItem}
                  >
                    Delete
                  </MenuItem>
                )}

              </Menu>
            )}
          </div>
        );
      }
      case "more_2": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index &&

              (
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  className={classes.menuList}
                >
                  {props?.edit && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        props.handleIcon("edit", main);
                      }}
                      className={classes.menuItem}
                    >
                      Edit
                    </MenuItem>
                  )}
                  {props?.delete && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        props.handleIcon("delete", main);
                      }}
                      className={classes.menuItem}
                    >
                      Delete
                    </MenuItem>
                  )}
                </Menu>
              )}
          </div>
        );
      }
      case "more_3": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ? "In-active" : "Active"}
                  </MenuItem>
                )}

                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("delete", main, main.delete);
                    }}
                    className={classes.menuItem}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_delete": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ? "In-active" : "Active"}
                  </MenuItem>
                )}

                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("delete", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ? "Delete" : "Active"}

                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_4": {
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>
            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    Edit
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ? "In-active" : "Active"}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_5": {
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {" "}
                {props?.edit && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    Edit
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "file_manager_option": {
        return (
          <div style={{ display: "flex" }}>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    Edit
                  </MenuItem>
                )}
                {props?.detail && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("detail", main);
                    }}
                    className={classes.menuItem}
                  >
                    Detail
                  </MenuItem>
                )}
                {(props?.download && !main?.is_dir) && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("download", main);
                    }}
                    className={classes.menuItem}
                  >
                    Download
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("delete", main);
                    }}
                    className={classes.menuItem}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "view_more": {
        return (
          <div>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleIcon("active", main, main.status);
                  }}
                  className={classes.menuItem}
                >
                  {main.is_active ? "In-active" : "Active"}
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleIcon("view", main, main.delete);
                  }}
                  className={classes.menuItem}
                >
                  View
                </MenuItem>
              </Menu>
            )}
          </div>
        );
      }
      case "error_icon": {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              transform: "rotate(180deg)",
            }}
          >
            {main.iconToolTip && (
              <InfoIcon
                fill="#FF4B4B"
                hoverFill="#FF4B4B"
                info={main.iconToolTip}
              />
            )}
          </div>
          // {main?.iconToolTip && <LightTooltip title={main.iconToolTip} placement="top">
          //    {/* <ErrorOutlineIcon /> */}
          //    <InfoIcon fill="#FF4B4B" />
          //  </LightTooltip>}
        );
      }
      case "block": {
        return (
          <div
            onClick={() => props?.onUnitClick(main, "block")}
            style={{ textDecorationLine: "underline", color: "blue" }}
          >
            {data.value}
          </div>
        );
      }
      case "unit": {
        return (
          <div
            onClick={() => props?.onUnitClick(main, "unit")}
            style={{ textDecorationLine: "underline", color: "blue" }}
          >
            {data.value}
          </div>
        );
      }
      case "floor": {
        return (
          <div
            onClick={() => props?.onUnitClick(main, "floor")}
            style={{ textDecorationLine: "underline", color: "blue" }}
          >
            {data.value}
          </div>
        );
      }
      case "active": {
        return (
          <>
            {data.value ? (
              <Typography className={classes.activego}>Active</Typography>
            ) : (
              <Typography className={classes.inactivego}>Active</Typography>
            )}
          </>
        );
      }
      case "info": {
        return (
          <div style={{ display: props?.view ? "flex" : "none" }}>
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("info", main)}>
                <InfoOutlinedIcon className={classes.infoIcon} />
              </IconButton>
            )}
          </div>
        );
      }
      case "payment_status": {
        return (
          <>
            {data.value === "Close" ? (
              <Typography className={classes.inactivego} sx={{ margin: "0" }}>
                Close
              </Typography>
            ) : (
              <Typography className={classes.inprogress} sx={{ margin: "0" }}>
                In Progress
              </Typography>
            )}
          </>
        );
      }
      case "q_status": {
        return (
          <>
            {
              <Typography
                className={
                  (data.value === "Won" && classes.green) ||
                  (data.value === "Active" && classes.yellow) ||
                  (data.value === "InActive" && classes.red) ||
                  (data.value === "Inactive" && classes.red)
                }
                sx={{ margin: "0" }}
              >
                {data.value}
              </Typography>
            }
          </>
        );
      }

      // INSPECTION START

      case "inspection_status": {
        return (
          <Box>
            {data.value === "Yet To Verify" ? (
              <Box flexGrow={1} display="flex" alignItems="center">
                <Box>
                  <Typography className={classes.yet}>{data.value}</Typography>
                </Box>
                <Box marginLeft="30px">
                  {/* <Button
                    sx={{ boxShadow: "none" }}
                    onClick={() => props.handleIcon("assign", data)}
                    variant="contained"
                  >
                    Assign
                  </Button> */}
                </Box>
              </Box>
            ) : data.value === "Yet to assign" ? (
              <Box flexGrow={1} display="flex" alignItems="center">
                <Box>
                  <Typography className={classes.yet}>{data.value}</Typography>
                </Box>
                <Box marginLeft="30px">
                  <Button
                    sx={{ boxShadow: "none" }}
                    onClick={() => props.handleIcon("assign", data.main)}
                    variant="contained"
                  >
                    Assign
                  </Button>
                </Box>
              </Box>
            ) : (
              <Typography
                className={
                  data.value === "Assigned"
                    ? classes.assignstatus
                    : classes.completeStatus
                }
              >
                {data.value}
              </Typography>
            )}
          </Box>
        );
      }
      case "more_6": {
        return (
          <div style={{ display: props?.view ? "flex" : "none" }}>
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("info", main)}>
                <MoreVertIcon className={classes.more} />
              </IconButton>
            )}
          </div>
        );
      }
      case "more_6_ins_item": {
        return (
          <div style={{ float: "right" }}>
            <IconButton>
              <MoreVertIcon
                className={classes.more}
                handleIcon={props?.handleIcon}
              />
            </IconButton>
          </div>
        );
      }
      case "more_7": {
        return (
          <div
            style={{ display: props?.view || props?.edit ? "flex" : "none" }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>
            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.view && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("view", main);
                    }}
                    className={classes.menuItem}
                  >
                    View
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ? "In-active" : "Active"}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_free_text_invoice": {
        return (
          <div
            style={{ display: props?.view || props?.edit ? "flex" : "none" }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>
            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.publish_invoice && (
                  main?.status !== "Published" &&
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("publish_invoice", main);
                    }}
                    className={classes.menuItem}
                  >
                    Publish Invoice
                  </MenuItem>
                )}
                {props?.edit && (
                  main?.status !== "Published" &&
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    Edit
                  </MenuItem>
                )}
                {props?.view && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("view", main);
                    }}
                    className={classes.menuItem}
                  >
                    View
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("Delete", main);
                    }}
                    className={classes.menuItem}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "object_status_inspection": {
        if (
          data?.value.value === "Yet to be Assign" ||
          data?.value.value === "Pending"
        ) {
          return (
            <Typography
              sx={{
                color: "#FF9340",
                fontSize: "14px",
                fontFamily: `${SemiBold}`,
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === "Assigned") {
          return (
            <Typography
              sx={{
                color: "#78B1FE",
                fontSize: "14px",
                fontFamily: `${SemiBold}`,
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === "Completed") {
          return (
            <Typography
              sx={{
                color: "#5AC782",
                fontSize: "14px",
                fontFamily: `${SemiBold}`,
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else {
          return data.value;
        }
      }
      case "status_inspection": {
        if (
          data?.value === "Yet to assign" ||
          data?.value === "Pending"
        ) {
          return (
            <Typography
              sx={{
                color: "#FF9340",
                fontSize: "14px",
                fontFamily: `${SemiBold}`,
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Assigned") {
          return (
            <Typography
              sx={{
                color: "#78B1FE",
                fontSize: "14px",
                fontFamily: `${SemiBold}`,
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Completed") {
          return (
            <Typography
              sx={{
                color: "#5AC782",
                fontSize: "14px",
                fontFamily: `${SemiBold}`,
              }}
            >
              {data.value}
            </Typography>
          );
        } else {
          return data.value;
        }
      }
      case "inputBox": {
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            {props?.edit && (
              <Checkbox
                checked={val?.is_active}
                onChange={(e) =>
                  props.handleIcon("checkBox", data, e.target.checked)
                }
              />
            )}
          </div>
        );
      }
      case "switcher": {
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            {props?.edit && (
              <CustomCheckBox
                checked={val?.is_team_lead}
                onChange={(e) =>
                  props.handleIcon("customCheckBox", data?.main, data?.main?.is_team_lead)
                }
              />
            )}
          </div>
        );
      }
      case "unused_balance": {
        return (
          <Typography
            sx={{
              color: data.value > 0 ? "#5AC782" : "auto",
              fontSize: "14px",
              fontFamily: `${SemiBold}`,
            }}
          >
            {data.value > 0 ? data.value : 0}
          </Typography>
        );
      }
      /* falls through */
      case "more_appraisal": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit &&
                  new Date(main?.data?.effective_start_date) <= new Date() &&
                  main?.Status !== "Active" && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClose();
                        props.handleIcon("Activate", main);
                      }}
                      className={classes.menuItem}
                    >
                      Activate
                    </MenuItem>
                  )}

                {props?.delete && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("Delete", main);
                    }}
                    className={classes.menuItem}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "inventory": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data.value ? "Yes" : "No"}
          </Typography>
        );
      }
      /* falls through */
      case "aminities_booking_status": {
        if (data.value === "Pending") {
          return <p style={{ color: "#78B1FE", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Unpaid" || data.value === "Cancelled") {
          return <p style={{ color: "#FF9340", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Approved" || data.value === "Booked") {
          return <p style={{ color: "#5AC782", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Paid") {
          return <p style={{ color: "#5AC782", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Decline") {
          return <p style={{ color: "#FF4B4B", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Invoice conversion pending") {
          return <p style={{ color: "#FF9340", margin: "0" }}>{data.value}</p>;
        } else {
          return <p style={{ margin: "0" }}>{data.value}</p>;
        }
      }
      /* falls through */

      case "inputCheck": {
        return (
          <Checkbox
            checked={val?.is_active}
            onClick={(e) => {
              e.stopPropagation();
              props.handleCheck(
                main,
                e.target.checked,
                data?.index,
                "is_active"
              );
            }}
          />
        );
      }
      /* falls through */

      case "agreement_inspection_status": {
        return (
          <Box>
            {data.value === "Yet To Verify" ? (
              <Box flexGrow={1} display="flex" alignItems="center">
                <Box>
                  <Typography className={classes.yet}>{data.value}</Typography>
                </Box>
              </Box>
            ) : data.value === "Pending" ?
              <p style={{ color: "#78B1FE", margin: "0" }}>{data.value}</p>
              : data.value === "Yet to assign" || data.value === "Yet to Assign Inspector" ? (
                <Box flexGrow={1} display="flex" alignItems="center">
                  <Box>
                    <Typography className={classes.yet}>{data.value}</Typography>
                  </Box>
                </Box>
              ) : (
                <Typography
                  className={
                    data.value === "Assigned"
                      ? classes.assignstatus :
                      data.value === "Rejected" ?
                        classes.rejectedstatus
                        : classes.completeStatus
                  }
                >
                  {data.value}
                </Typography>
              )}
          </Box>
        );
      }
      case "text_with_date": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data?.value?.value ?? "-"} ,{" "}
            <span className={classes.date}>{data?.value?.date ? convertTimeUtcToZoneCalander(data?.value?.date) : '-'}</span>
          </Typography>
        );
      }
      case "role": {
        return (
          <Tooltip title={data?.value?.responsibiltes} placement="bottom" arrow>
            <Typography
              style={{
                whiteSpace: "nowrap",
              }}
              className={classes.text}
            >
              <span className={classes.role} style={{ backgroundColor: `${data?.value?.bgColor}`, color: `${data?.value?.bgColor === "#EBFBFF" ? "#2CA2BF" : data?.value?.bgColor === "#EBF0FF" ? "#5071CE" : data?.value?.bgColor === "#FFFAEB" ? "#ECC049" : "black"}` }}>{data?.value?.label}</span>
            </Typography>
          </Tooltip>
        );
      }
      case "tool_tip": {
        return (
          <>
            <LightTooltip title={main?.build?.map((x, i) => {
              return <span>{x?.build_name ?? ""}{i === main?.build?.length - 1 ? '.' : ','}</span>
            })} placement="right">
              <span style={{ color: '#5078E1' }}>&nbsp;{data.value}&nbsp;</span>
            </LightTooltip>
          </>
        );
      }
      case "parking": {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography sx={{ whiteSpace: "nowrap" }} className={classes.text}>
              {data.value ?? "-"}
            </Typography>
            {data.main?.parking &&
              <Typography className={classes.lightBlue}>
                {data.main?.parking ? "Parking" : ""}
              </Typography>
            }

          </Stack>
        );
      }
      case "image_icon": {
        return data?.value
      }
      case "milestone_text": {
        return (
          <Box p={1}>
            {
              (data?.main?.value_type !== "variable" && data?.index === 0) ?
                <TextBox
                  value={data?.value}
                  onChange={props?.milstoneOnchange}
                  type="number"
                  isError={val?.customerror?.length > 0}
                  errorMessage={val?.customerror}
                  label={null}
                  placeholder="Enter Amount"
                  endAdornment={
                    <Typography
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      className={classes.text}
                    >
                      {props?.currency ?? "-"}
                    </Typography>
                  }
                // isReadonly={true}
                /> :
                data?.main?.value_type === "variable" ?
                  <TextBox
                    value={data?.value}
                    onChange={(e) => props?.milstoneOnchange(e, data?.index)}
                    type="number"
                    isError={val?.customerror?.length > 0}
                    errorMessage={val?.customerror}
                    label={null}
                    placeholder="Enter Amount"
                    endAdornment={
                      <Typography
                        style={{
                          whiteSpace: "nowrap",
                        }}
                        className={classes.text}
                      >
                        {props?.currency ?? "-"}
                      </Typography>
                    }
                  // isReadonly={true}
                  />
                  :
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                    }}
                    className={classes.text}
                  >
                    {`${data?.value} (${data?.main?.percentage} %)` ?? "-"}
                  </Typography>
            }
          </Box>

        );
      }
      case "oppertunity_status": {
        return (
          <>
            {
              <Typography
                className={
                  (data.value === "Won" && classes.green) ||
                  (data.value === "Open" && classes.yellow) ||
                  (data.value === "Closed" && classes.red)
                }
                sx={{ margin: "0" }}
              >
                {data.value}
              </Typography>
            }
          </>
        );
      }
      case "object_status_color": {
        return (
          <Typography
            sx={{
              color: data?.value?.text_color,
              fontSize: "14px",
              fontFamily: `${SemiBold}`,
            }}
          >
            {data?.value?.label ?? "-"}
          </Typography>
        );
      }
      case "correspondence_option": {
        return (
          <div style={{ display: (props?.view || props?.edit || props?.delete) ? "flex" : "none" }}>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {" "}
                {props?.view && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("dialog_view", main);
                    }}
                    className={classes.menuItem}
                  >
                    View
                  </MenuItem>
                )}
                {(props?.edit && main?.status?.value === 1) && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("send", main);
                    }}
                    className={classes.menuItem}
                  >
                    Send
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("delete", main);
                    }}
                    className={classes.menuItem}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      default: {
        return data.value;
      }
    }
  };

  const returnPageNumber = () => {
    //check if props.rows have a index key
    const checkIndexKeyInType = props?.rows?.some((_) => _?.index);
    if (props?.rows?.length && checkIndexKeyInType) {
      if (props?.rows?.length === 1) {
        return `${props?.rows?.[0]?.index} of ${totalCount}`;
      } else {
        let lastCountIndex = props?.rows?.length - 1;
        return `${props?.rows?.[0]?.index} - ${props?.rows?.[lastCountIndex]?.index} of ${totalCount}`;
      }
    }
    return "";
  };
  // const ccyFormat = (num) => {
  //   return `${num.toFixed(2)}`;
  // }
  return (
    <div className={classes.root}>
      {props?.rows?.length === 0 ? (
        <div
          className={classes.noRecordDiv}
          style={{
            minHeight: props?.height && props?.height,
            maxHeight: props?.height && props?.height,
          }}
        >
          <p className={classes.typo}>No Data Found</p>
        </div>
      ) : (
        <>
          <TableContainer
            sx={{
              //zIndex: -1,
              minHeight: props?.height && props?.height,
              maxHeight: props?.height && props?.height,
              mt: 2,
              pr: 1
            }}>
            {/* <br /> */}
            {/* Table Component */}
            <Table stickyHeader aria-label="customized table">
              {/* heading */}
              <TableHead className={classes.thead}>
                {props?.heading?.length > 0 && (
                  <TableRow>
                    {props?.heading?.map((data, index) => {
                      return (
                        <StyledTableCell
                          headingColor={props?.headingColor}
                          size="small"
                          key={index}
                        >
                          {data.title}
                          {data?.Checkbox && (
                            <Checkbox
                              checked={props?.allSelect}
                              onClick={(e) => {
                                e.stopPropagation();
                                props.allhandleCheck(e.target.checked);
                              }}
                            />
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                )}
              </TableHead>
              {/* rows */}
              {props?.tableType === "normal" && <Box height={"8px"} />}
              <TableBody>
                {props?.rows?.length > 0 && (
                  <>
                    {props?.rows?.map((row, index) => {
                      return (
                        <>
                          <TableRow
                            selected={row?.selectedRow}
                            key={index + 1}
                            className={classes.tbody}
                            style={{
                              backgroundColor:
                                row?.status && props?.isColor === true ? "#F5F7FA" : "auto",
                            }}
                            onClick={() =>
                              props?.onClick && props?.onClick(row)
                            }
                          >
                            {props?.dataType?.map((val) => {
                              return (
                                <StyledTableCell
                                  stickyHeader={true}
                                  headingColor={props?.cellColor}
                                  component="th"
                                  scope="row"
                                  onClick={() => {
                                    if (
                                      val.type[0] === "more" ||
                                      val.type[0] === "more_2" ||
                                      val.type[0] === "unit" ||
                                      val.type[0] === "block" ||
                                      val.type[0] === "textLink" ||
                                      val.type[0] === "floor" ||
                                      val.type[0] === "more_3" ||
                                      val?.type[0] === "info" ||
                                      val?.type[0] === "checkBox" ||
                                      val.type[0] === "more_4" ||
                                      val?.type[0] === "inspection_status" ||
                                      val?.type[0] === "inputBox" ||
                                      val?.type[0] === "view_more" ||
                                      val?.type[0] === "more_appraisal" ||
                                      val?.type[0] === "more_5" ||
                                      val?.type[0] === "file_manager_option" ||
                                      val?.type[0] === "correspondence_option"
                                    ) {
                                    } else {
                                      (props?.view && props.handleIcon) &&
                                        props.handleIcon("view", row);
                                    }
                                  }}
                                >
                                  {val?.type?.map((type) => {
                                    return getComponentType(row, {
                                      index: index,
                                      type: type,
                                      main: row,
                                      value: row[val.name],
                                      image: row[val.imagekey],
                                      icon: row[val.icon],
                                      is_active: row[val.is_active],
                                      objectOption: val?.label ?? "label",
                                    });
                                  })}
                                </StyledTableCell>
                              );
                            })}
                          </TableRow>

                          {props.tableType === "normal" && (
                            <Box height={"8px"} />
                          )}
                        </>
                      );
                    })}
                  </>
                )}
                {props?.subTotal &&
                  <TableRow>
                    <TableCell colSpan={5}></TableCell>
                    <TableCell className={classes.subTotal}>{props?.subTotal}</TableCell>
                    <TableCell className={classes.subTotal}>{props.subTotalValue}</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination Section */}
          {props?.showpagination && (
            <>
              <div className={classes.pagination}>
                {/* <p className={classes.typo}>
              pages {1}/{Math.ceil(props.rows.length / pageSize)}
            </p> */}
                <div className={classes.flexRow}>
                  <p className={classes.typo}>Rows per page</p>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props?.limit}
                    onChange={(e) => props?.handleChangeLimit(e.target.value)}
                    // classes={{
                    //   root:classes.select
                    // }}
                    className={classes.select}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    {/* <MenuItem value={2}>2</MenuItem> */}
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </div>
                <div className={classes.flexRow}>
                  {props?.rows?.length > 0 && (
                    <p className={classes.typo}>{returnPageNumber()}</p>
                  )}
                  <Pagination
                    className={classes.paginate}
                    shape="rounded"
                    count={Math.ceil(totalCount / props?.limit)}
                    page={props?.page}
                    // onChange={handleChange}
                    onChange={(e, value) => props?.handlePagination(value)}
                    color="primary"
                  // showFirstButton
                  // showLastButton
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

TableWithPagination.defaultProps = {
  handleIcon: () => { },
  view: true,
  edit: true,
  delete: true,
  detail: true,
  download: true,
};
