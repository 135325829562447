export const ContractEmployees = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={32}
        height={32}
        {...props}
    >
        <defs>
            <radialGradient
                id="d"
                cx={0.5}
                cy={0.5}
                r={0.438}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#ffcf54" />
                <stop offset={0.261} stopColor="#fdcb4d" />
                <stop offset={0.639} stopColor="#f7c13a" />
                <stop offset={1} stopColor="#f0b421" />
            </radialGradient>
            <radialGradient
                id="b"
                cx={0.072}
                cy={0.077}
                r={1.764}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#c26715" />
                <stop offset={0.508} stopColor="#b85515" />
                <stop offset={1} stopColor="#ad3f16" />
            </radialGradient>
            <radialGradient id="e" cx={0.297} cy={0.562} r={4.693} xlinkHref="#b" />
            <linearGradient
                id="a"
                x1={0.25}
                y1={0.5}
                x2={0.882}
                y2={0.5}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#c48f0c" />
                <stop offset={0.251} stopColor="#d19b16" />
                <stop offset={0.619} stopColor="#dca51f" />
                <stop offset={1} stopColor="#e0a922" />
            </linearGradient>
            <linearGradient id="c" x1={-297.77} x2={-297.138} xlinkHref="#a" />
        </defs>
        <g data-name="Group 102438">
            <path data-name="Rectangle 53878" fill="none" d="M0 0h32v32H0z" />
            <path
                data-name="Path 96903"
                d="M16 19.736c-5.438 0-9.846 3.029-9.846 6.329v.7a1.23 1.23 0 0 0 1.231 1.231h17.231a1.23 1.23 0 0 0 1.23-1.231v-.7c0-3.3-4.408-6.329-9.846-6.329Z"
                fill="#e8e8e8"
            />
            <path
                data-name="Path 96904"
                d="M19.077 24.356 16 22.256l-3.077 2.1v-7.433h6.154Z"
                fill="#fff"
            />
            <path
                data-name="Path 96905"
                d="M19.077 20c0 1.167-3.077 1.846-3.077 1.846s-3.077-.678-3.077-1.846v-4.329h6.154Z"
                fill="#d6a121"
            />
            <path
                data-name="Path 96906"
                d="m14.769 23.077.615.615L14.769 28h2.462l-.615-4.308.615-.615L16 21.846Z"
                fill="#d32f2f"
            />
            <path
                data-name="Path 96907"
                d="M32.713 18h-.324v2.462h.433a.628.628 0 0 0 .641-.455l.212-1.048a.877.877 0 0 0-.962-.959Z"
                transform="translate(-11.226 -6)"
                fill="url(#a)"
            />
            <path
                data-name="Path 96908"
                d="M14.475 18h.325v2.462h-.433a.628.628 0 0 1-.641-.455l-.212-1.048a.877.877 0 0 1 .961-.959Z"
                transform="translate(-3.961 -6)"
                fill="url(#c)"
            />
            <path
                data-name="Path 96909"
                d="M20.538 6.4C17.806 6.4 15 6.815 15 12.723v4.122c0 2.109 3.323 4.694 5.538 4.694s5.538-2.585 5.538-4.694v-4.122c.001-5.908-2.805-6.323-5.538-6.323Z"
                transform="translate(-4.538 -1.538)"
                fill="url(#d)"
            />
            <path
                data-name="Path 96910"
                d="M20.154 5.545c-2.679 0-3.077 1.007-3.077 1.007A4.222 4.222 0 0 0 14 10.132a25.18 25.18 0 0 0 .615 4.308C14.951 10.412 16 8.9 17.077 8.9c.78 0 1.4.615 3.077.615 1.417 0 1.769-.615 3.077-.615 1.846 0 2.462 4.9 2.462 5.538a26.671 26.671 0 0 0 .615-4.308c0-2.108-2.333-4.585-6.154-4.585Z"
                transform="translate(-4.154 -1.21)"
                fill="url(#b)"
            />
            <path
                data-name="Path 96911"
                d="M22.212 5C19.65 5 19 6.343 19 6.343a2.909 2.909 0 0 0 .321 1.343s.468 1.007 3.138 1.007A3.716 3.716 0 0 0 26.385 5Z"
                transform="translate(-6.077 -1)"
                fill="url(#e)"
            />
        </g>
    </svg>
)