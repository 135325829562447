// import { useApolloClient } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import { Box, Grid } from "@mui/material";
import React from 'react';
import { DialogWithTable } from "../../components/dialogWithTable";
import { GET_ALL_OWNERS_COUNTS, GET_ALL_UNIT_AGREEMENT, GET_ALL_UNIT_INVOICE_OLD, } from '../../graphql/queries';

export const OtherInfoCard = (props) => {
    const unitId = props?.allUnitId
    const client = useApolloClient();
    const [count, setCount] = React.useState({
        agreement: 0,
        invoice: 0
    })
    React.useEffect(() => {
        if (unitId.length > 0) {
            client.query({
                query: GET_ALL_OWNERS_COUNTS,
                fetchPolicy: 'network-only',
                variables: {
                    unitId: unitId,
                }
            }).then((res) => {
                setCount({
                    owners: res.data?.owner_customer_count?.[0]?.count,
                    otherResidents: res.data?.owner_customer_count?.[0]?.count,
                    breakup: res.data?.pricing_table?.[0]?.count,
                    vacancy: res.data?.unit_vacancy_period?.[0]?.count,
                    amenities: res.data?.unit_amenities_breakup?.[0]?.count,
                    rooms: res.data?.unit_room?.[0]?.count,
                    request: res.data?.security_count?.[0]?.count,
                    agreement: res.data?.agreement_units_count?.[0]?.count,
                    invoice: res.data?.invoice_units_count?.[0]?.count,
                    accounts: res.data?.agreement_count?.[0]?.count
                })
            })
        }
        // eslint-disable-next-line
    }, [unitId])
    const tableData = [
        {
            id: 1,
            title: "Agreements",
            color: "#5078E1",
            count: count?.agreement,
            table: {
                heading: [
                    { title: "Agreement No ", field: 'agreementNo', },
                    { title: "Valid From", field: 'validfrom', },
                    { title: "Valid To", field: 'validto', },
                    { title: "Agreement primary contact name ", field: 'agreementuser', },
                    { title: "Contact No", field: 'contactNo', },
                    { title: "email Id", field: 'emailAddress', },


                ],
                path: ["sno", "agreementNo", "agreementuser", "contactNo", "emailAddress",],
                dataType: [
                    { type: ["text"], name: "agreementNo" },
                    { type: ["date"], name: "validfrom" },
                    { type: ["date"], name: "validto" },
                    { type: ["text"], name: "agreementuser" },
                    { type: ["text"], name: "contactNo" },
                    { type: ["text"], name: "emailAddress" },

                ],

            },
            query: GET_ALL_UNIT_AGREEMENT,
            unitId: unitId
        },
        {
            id: 2,
            title: "Unit",
            color: "#E29336",
            count: count?.invoice,
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Unit ID ", field: 'unitID', },
                    { title: "Tenant Name", field: 'tenantName', },
                    { title: "Primary Contact", field: 'primaryContact', },
                    { title: "Email Address", field: 'emailAddress', },
                    { title: " ", field: 'icon', },


                ],
                path: ["sno", "unitID", "tenantName", "primaryContact", "emailAddress",],
                dataType: [
                    { type: ["text"], name: "sno" },
                    { type: ["text"], name: "unitID" },
                    { type: ["text"], name: "tenantName" },
                    { type: ["text"], name: "primaryContact" },
                    { type: ["text"], name: "emailAddress" },
                    { type: ["icon"], name: "icon" },

                ],

            },
            query: GET_ALL_UNIT_INVOICE_OLD,
            unitId: unitId
        },
    ]

    return (

        <div>
            <Box height="8px" />
            <Grid container spacing={2}>
                {tableData.map((item) => (
                    <>
                        <Grid item xs={2}>
                            <Box p={0.5}>
                                <DialogWithTable data={item} />
                            </Box>
                        </Grid>
                    </>

                )
                )}
            </Grid>

        </div>


    )
}