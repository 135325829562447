import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, SemiBold } from "../../utils";
export const dashboardStyles = makeStyles((theme, props) => ({
  root: {
    padding: "24px",
    backgroundColor: theme.palette.background.secondary,
    height: `calc(100vh - 64px)`,
    overflow: "auto"
  },
  cardNew: {
    borderRadius: "12px !important",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  cardNew_2: {
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    boxShadow: "0px 3px 30px #5C86CB2E",
    minHeight: "263px",
    maxHeight: "263px",
    overflow: "auto",
  },
  Expensestitle: {
    fontSize: "14px",
    fontFamily: ExtraBold,
  },
  expensesHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "14px 0px 0px 14px",
  },
  expensesHeader1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "14px 20px",
  },
  datevise: {
    backgroundColor: "#F2F4F7",
    borderRadius: theme.palette.borderRadius,
    display: "flex",
  },
  select: {
    backgroundColor: theme.palette.primary.main,
    margin: "5px",
    color: "#fff",
    padding: "5px",
    fontSize: "10px",
    borderRadius: "6px",
    cursor: "pointer",
  },
  unselect: {
    margin: "5px",
    color: "#000",
    padding: "5px",
    fontSize: "10px",
    borderRadius: "6px",
    cursor: "pointer",
  },
  dot: {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    margin: "6px",
  },
  chartcontant: {
    fontSize: "16px",
    marginRight: "10px",
  },
  textDecoration: {
    color: theme.palette.primary.main,
    fontSize: "12px",
    fontFamily: SemiBold,
    textDecoration: "underline",
    margin: "10px 0px 0px 35px",
  },
  DrawerClass: {
    borderRadius: "12px 12px 0px 0px",
  },
  closebtn: {
    padding: "10px",
    "& button": {
      backgroundColor: theme.palette.primary.main,
      width: "100%",
      padding: "16px",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  amountCount: {
    fontSize: "14px",
    fontFamily: ExtraBold,
  },
  title: {
    fontSize: "14px",
    color: theme.typography.color.primary,
    fontFamily: SemiBold,
  },
  count: {
    fontSize: "20px",
    color: theme.typography.color.primary,
    fontFamily: Bold,
  },
  view: {
    fontSize: "14px",
    color: "#5078E1",
    fontFamily: SemiBold,
  },
  div: {
    backgroundColor: "#F5F7FA",
    borderRadius: "6px",
  },
  map: {
    borderRadius: theme.palette.borderRadius,
    overflow: "hidden",
    height: "410px",
  },
  graphDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0px 0px 10px",
  },
  graphTotal: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "10px 0px 0px 10px",
  },
  amount: {
    fontSize: "20px",
    fontFamily: ExtraBold,
    marginTop: "1px",
  },
  subtext: {
    fontSize: "12px",
    fontFamily: SemiBold,
  },
  grid: {
    display: "flex",
    padding: "0px 12px",
    minHeight: "375px",
    maxHeight: "375px",
    overflow: "auto",
  },

  num: {
    fontSize: "12px",
    color: "#4E5A6B",
  },
  noData: {
    textAlign: "center",
    alignSelf: "center",
  },
  revenueGraph: {
    minHeight: "189px",
    maxHeight: "189px",
  },
  generalRequest: {
    // minHeight: "180px",
    // maxHeight: "356px",
    overflow: "auto",
    height: `calc(100vh - 546px)`
  },
  card: {
    padding: '15px 0px',
    borderRadius: '4px'
  },
  Cardcontent: {
    margin: '15px 0px'
  }

}));
