import gql from "graphql-tag";

export const GET_ALL_BLOCK_OR_FLOOR_BY_PROPERTY_ID = (offset = 0, limit = 10, search = "", type, tableName, id) => gql`
query GET_ALL_BLOCK_BY_PROPERTY_ID{
    count:${tableName}(
        where:{
            ${type}:{eq:"${id}"}
            name:{iregex:"${search}"}
        }
    ){
        count:count_id
    }
    ${tableName}(
        where:{
            ${type}:{eq:"${id}"}
            name:{iregex:"${search}"}
        }
        offset:${offset}
        limit:${limit}
    ){
        value:id
        label:name
    }
}
`