import Close from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { config } from "../../../config";
import { GET_PROPERTY_UNITS } from "../../../graphql/lead";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import {
  loadOptions,
  loadOptionsApis,
} from "../../../utils/asyncPaginateLoadOptions";
import { UseDebounce } from "../../customHooks";
import { useStyles } from "../styles";
import { Details } from "./details";
import { SelectUnits } from "./ownerSelect";

export const Owner = ({
  onClose = () => false,
  value = [],
  onChange = () => false,
  type = {},
  company = null,
  isEdit = false,
  datas = {},
  onDeleteUnits = () => false,
  is_show = false,
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    value: null,
    offset: 0,
  });
  const [searchText, setSearchText] = React.useState("")
  const debounce = UseDebounce()
  const [loading, setLoading] = React.useState(false);
  const handleSearch = (e) => {
    setSearchText(e)
    debounce(() => onSearch(e), 800)
  }
  //update state
  const updateState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };
  //get list
  const getList = (property, offset, search, reload, is_fill) => {
    if (!reload) {
      setLoading(true);
    }

    const payload = {
      company_id: company,
      property_id: type?.value === 2 ? property : undefined,
      vacant: ["Vacant"],
      owner_id: type?.value === 1 ? property : undefined,
      offset,
      limit: 10,
      search,
    };
    NetworkCall(
      `${config.api_url}/property/units`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        if (!is_fill) {
          let editedUnits = res?.data?.data?.map((val) => {
            return {
              ...val,
              is_active:
                datas?.fixUnit?.filter((i) => i?.id === val?.id)?.length > 0
                  ? true
                  : false,
            };
          });

          let finalResult = reload ? [...value, ...editedUnits] : editedUnits;

          onChange(finalResult, type?.value === 1 ? property : null);
        } else {

          let editedUnits = res?.data?.data?.map((val) => {
            return {
              ...val,
              is_active: false,
            };
          });
          let finalResult = reload ? [...value, ...editedUnits] : editedUnits;
          onChange(finalResult, type?.value === 1 ? state?.value?.value : null);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (isEdit && (datas?.edit_owner?.value || datas?.property_id?.value)) {
      getList(datas?.edit_owner?.value ?? datas?.property_id?.value, 0, "");
      updateState("value", datas?.edit_owner ?? datas?.property_id);
    }
    // eslint-disable-next-line
  }, [datas?.edit_owner?.value, datas?.property_id?.value]);

  //const onCheck Unit
  const checkUnit = (data) => {
    const edited = value.map((item) =>
      item.id === data.id
        ? {
          ...item,
          is_active: !data?.is_active,
        }
        : item
    );
    onChange(edited, type?.value === 1 ? state?.value?.value : null);
  };

  //onchange select
  const onChangeSelect = (val) => {
    getList(val?.value, 0, "", false, true);
    updateState("value", val);
  };
  //onSearch
  const onSearch = (val) => {
    getList(state?.value?.value, 0, val, false);
  };
  //fetch more
  const fetchMoreData = () => {
    getList(state?.value?.value, state?.offset + 10, "",true, false);
    setState({
      ...state,
      offset: state?.offset + 10,
    });
  };

  return (
    <Box>
      {type?.value && !is_show && (
        <Box display="flex" alignItems="center" className={classes.header}>
          <Box marginTop="4px">{type?.icon}</Box>
          <Box flexGrow={1} marginLeft="12px">
            <Typography className={classes.btnTitle}>{type?.label}</Typography>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                onClose();
                updateState("value", null);
              }}
              size="small"
            >
              <Close sx={{ color: "red" }} />
            </IconButton>
          </Box>
        </Box>
      )}
      <Box marginTop="12px">
        {type?.value === 1 ? (
          <SelectUnits
            isPaginate={true}
            placeholder={type?.search}
            value={state?.value}
            onChange={(val) => onChangeSelect(val)}
            loadOptions={(search, array, handleLoading) =>
              loadOptionsApis(
                "lead_proxy/get_owner",
                { company_id: company },
                search,
                array,
                handleLoading,
                "data"
              )
            }
          />
        ) : (
          <SelectUnits
            isPaginate={true}
            placeholder={type?.search}
            value={state?.value}
            onChange={(val) => onChangeSelect(val)}
            loadOptions={(search, array, handleLoading) =>
              loadOptions(
                search,
                array,
                handleLoading,
                GET_PROPERTY_UNITS,
                "unit",
                { company_id: company }
              )
            }
          />
        )}

        {state?.value && (
          <Details
            loading={loading}
            checkUnit={checkUnit}
            onRemove={() => {
              updateState("value", null);
              onDeleteUnits([], null, true);
            }}
            list={value}
            selected={state?.value}
            company={company}
            onSearch={handleSearch}
            fetchMoreData={fetchMoreData}
            searchText={searchText}
          />
        )}
      </Box>
    </Box>
  );
};
