import { Avatar, Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { CountCardStyle } from "./style";

export const CountCard = ({ type = "", count = "", content = "", cardType = false, title = "", subTitle = "", subTitle1 = "", imgUrl = "" }) => {
    const classes = CountCardStyle()

    const imgList = {
        "mandatory": "../images/kycMandatory.svg",
        "accepted": "../images/kycAccepted.svg",
        "declined": "../images/kycDeclined.svg"
    }
    return (

        cardType ?
            <Box className={classes.box}>
                <Stack spacing={1} p={1.5}>
                    <Typography className={classes.uTitle}>{title}</Typography>
                    <Stack direction={"row"} spacing={2}>
                        <Avatar className={classes.avatar} src={imgUrl}></Avatar>
                        <Stack>
                            <Typography className={classes.uSubtitle1}>{subTitle}</Typography>
                            <Typography className={classes.uSubtitle2}> {subTitle1} </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
            :
            <Box className={classes.box}>
                <Stack spacing={1} p={1.5}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography className={classes.title}>{count}</Typography>
                        <img src={imgList[type]} alt="" />
                    </Stack>
                    <Typography className={classes.content}> {content} </Typography>
                </Stack>
            </Box>

    )
}