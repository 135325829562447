import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { LoadingSection, Subheader, UseDebounce } from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { GET_PROPERTY } from "../../graphql/inspectionMapping";
import { Routes } from "../../router/routes";
import { accessCheckRender, getCompanyOption, getRoutePermissionNew, InspectionMappingDataType, InspectionMappingHeading, InspectionMappingPath } from "../../utils";
import InspectionMappingTable from "./components/table";
import { InspectionMappingStyles } from "./styles";

const InspectionMapping = () => {
    const classes = InspectionMappingStyles()
    const history = useHistory();
    const debounce = UseDebounce();
    const client = useApolloClient();
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext);
    const [searchText, setSearchText] = React.useState("");
    const [compenyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [loading, setLoading] = React.useState(true)
    const [page, setPage] = React.useState(1);
    const [permissions, setPermission] = React.useState({})
    const [limit, setLimit] = React.useState(10);
    const [itemList, setItemList] = React.useState({
        list: [],
        count: 0,
    })


    //get list
    const getPropertyList = (company_id, searchText, offset, limits) => {
        setLoading(true)
        client.query({
            query: GET_PROPERTY,
            fetchPolicy: 'network-only',
            variables: {
                company_id: company_id,
                search: searchText,
                offset: offset,
                limit: limits,
            }
        }).then((response) => {
            setItemList({
                list: response?.data?.property?.map((val) => {
                    return { ...val, total_units: val?.unit?.[0]?.count_id }
                }),
                count: response.data.count?.[0]?.count
            })
            setLoading(false)

        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })


    }


    //initial load
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert);
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
                getPropertyList(company?.selected?.value, "", 0, 10, {})
            }
        }
        // eslint-disable-next-line
    }, [auth])



    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getPropertyList(selectedCompany?.value, "", offset, limit)
    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getPropertyList(selectedCompany?.value, "", 0, value)
    }

    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getPropertyList(selectedCompany?.value, e, 0, limit)
    }

    //handle change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getPropertyList(value?.value, "", 0, 10, {})
    }


    //switch to next page
    const handleIcon = (data) => {
        history.push({
            pathname: Routes.mapPropertyDetails,
            state: {
                company: selectedCompany,
                id: data?.id
            }
        })
    }


    const render = () => {
        return (
            <Box>
                <Subheader
                    hideBackButton={true}
                    title="Inspection Mapping"
                    select
                    options={compenyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        handleCompanyChange(e)
                    }}
                />
                {
                    loading ?
                        <LoadingSection top="20vh" message={"Fetching Details"} />
                        :
                        <Box className={`${classes.root}`}>
                            <InspectionMappingTable
                                searchText={searchText}
                                placeholder="searchProperty"
                                onClick={handleIcon}
                                heading={InspectionMappingHeading}
                                path={InspectionMappingPath}
                                dataType={InspectionMappingDataType}
                                handleChangeLimit={handleChangeLimit}
                                handlePagination={handlePagination}
                                page={page}
                                list={itemList}
                                limit={limit}
                                handleSearch={handleSearch}
                                height={`calc(100vh - 300px)`}
                            />
                        </Box>
                }

            </Box>
        )
    }

    return (
        <Box>
            {accessCheckRender(render, permissions)}

        </Box>
    )
}
export default InspectionMapping