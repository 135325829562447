import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../../utils";
export const useStyles = makeStyles((theme) => ({
    listRoot: {
        padding: "12px",
        backgroundColor: "white",
        borderRadius: "4px",
    },
    newListRoot: {
        padding: "12px",
        borderRadius: "4px"
    },
    lable: {
        fontSize: "14px",
        color: "#4E5A6B",
        padding: "12px",
        cursor: "pointer"
    },
    labelSelected: {
        fontSize: "14px",
        color: "white",
        fontFamily: Bold,
        borderRadius: "4px",
        padding: "12px",
        cursor: "pointer",
        backgroundColor: "#5078E1"
    },
    lableContact: {
        fontSize: "14px",
        color: "#1C1C1C",
        padding: "6px",
        cursor: "pointer",
        fontFamily: SemiBold,
        alignItems: "center"

    },
    labelSelectedContact: {
        fontSize: "14px",
        color: "white",
        fontFamily: Bold,
        borderRadius: "4px",
        padding: "6px",
        cursor: "pointer",
        backgroundColor: "#5078E1",
        alignItems: "center"

    },
    subTitle: {
        fontSize: "14px",
        color: "#98A0AC",
        fontFamily: Regular,
    },
    title: {
        fontSize: "16px",
        color: "#091B29",
        fontFamily: Bold,
    },
    root: {
        padding: "12px",
        backgroundColor: "white",
        borderBottom: "1px solid #E4E8EE"
    },
    btn: {
        boxShadow: "none"
    },
    btn1: {
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "white",
        },
    },
    paymetRoot: {
        backgroundColor: "white",
        padding: "12px",
        height: "80px",
        display: "flex",
        width: "180px",
        alignItems: "center",
        borderRadius: "4px",
        border: "1px solid #E4E8EE",
        margin: "12px",
        position: "relative",
        cursor: "pointer"
    },
    paymetRootSelected: {
        backgroundColor: "white",
        padding: "12px",
        height: "80px",
        display: "flex",
        width: "180px",
        alignItems: "center",
        borderRadius: "4px",
        borderBottom: `4px solid ${theme.palette.primary.main}`,
        margin: "12px",
        position: "relative",
        cursor: "pointer"
    },
    checked: {
        color: "#5078E1",
        position: "absolute",
        top: 4,
        right: 4,
        fontSize: "15px"
    },
    stepTitle: {
        fontSize: "12px",
        color: "#4E5A6B",
        fontFamily: Bold,
    },
    step: {
        backgroundColor: "#4E5A6B",
        color: "white",
        height: "20px",
        width: "20px",
        fontSize: "10px",
        fontFamily: Bold,
    },
    jobText: {
        fontFamily: SemiBold,
        fontSize: "12px",
        color: "#7C8594",
        alignItems: "center"


    },
    jobTextSelected: {
        fontFamily: SemiBold,
        fontSize: "12px",
        color: "white",
        alignItems: "center"

    },
    heading: {
        fontFamily: Bold,
        fontSize: "16px",
        color: theme.typography.color.primary,
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
        padding: "12px"
    },
    noData: {
        fontFamily: Bold,
        fontSize: "16px",
        color: theme.typography.color.primary,
        padding: "12px",
        textAlign: "center",
        marginTop: "50%"
    },
    remark: {
        fontSize: "14px",
        wordBreak: "break-all",
        fontFamily: SemiBold

    },
    seeMoreLessTextStyle: {
        fontSize: "14px",
        fontFamily: SemiBold,
        color: theme.palette.primary.main,
    },
}));