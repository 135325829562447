import { Box, Typography } from '@mui/material';
import { AggreementCardInfoStyles } from "./styles";
export const AggreementCardInfo = ({ data = {} }) => {
    const classes = AggreementCardInfoStyles(data)
    return (
        <Box className={classes.cardRoot}>
            {data?.url ? <img src={data?.url} alt="" /> : data?.image}
            
            <Typography className={classes.title}>{data?.symbol && <span className={classes.currency}>{data?.symbol ?? ''}</span>}{data?.title}</Typography>
            <Typography className={classes.subtitle}>{data?.subtitle}</Typography>
        </Box>
    )
}