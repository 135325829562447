import { InputAdornment, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from 'prop-types';
import React from 'react';
export const filterStyles = makeStyles((theme, props) => ({
    textField: {
        "& .MuiOutlinedInput-root": {
            height: (props) => props?.height ?? "auto",
            padding: "2px",
            borderTopLeftRadius: theme.palette.borderRadius,
            borderBottomLeftRadius: theme.palette.borderRadius,
            //border: "1px solid #E4E8EE",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
        },
        '& input': {
            padding: theme.spacing(1),
            borderRadius: "0px",
            border: "0px"
        }
    },
    textField1: {

        "& .MuiOutlinedInput-root": {
            height: (props) => props?.height ?? "auto",
            padding: "2px",
            borderTopRightRadius: theme.palette.borderRadius,
            borderBottomRightRadius: theme.palette.borderRadius,
            //border: "1px solid #E4E8EE",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
        },
        '& input': {
            padding: theme.spacing(1),
            borderRadius: "0px",
            border: "0px"
        }
    },
    placeholder: {
        fontSize: "14px",
        marginRight: "8px"
    }
}))
// const CustomTextField = styled(TextField)(({ theme, position }) => ({
//     "& .css-13qlpao-MuiInputBase-root-MuiOutlinedInput-root": {
//         borderRadius: (props) => position ? "0px" : "10px"
//     },
//     '& input': {
//         padding: theme.spacing(1),
//         borderRadius: "0px"
//     }
// }))

export const CustomTextFieldComponent = (props) => {
    const classes = filterStyles(props)
    const {
        value = "",
        onChange = null,
        placeholder = "",
        position,
    } = props;

    return (
        <TextField
            value={value}
            className={position ? classes.textField1 : classes.textField}
            onChange={(e) => onChange(e.target.value)}
            //placeholder={placeholder}
            type={"number"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <span className={classes.placeholder}>
                            {placeholder}&nbsp;
                        </span>
                    </InputAdornment>
                ),
            }}
            onKeyPress={(e) => {
                if (['e', 'E']?.includes(e.key)) {
                    e.preventDefault();
                }
            }}

        />
    )
}

CustomTextFieldComponent.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
}