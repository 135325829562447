import React from "react";
import { withNavBars } from "../../HOCs";
import { ResidentTableDetails } from "./residentTableDetails";

class ResidentTableDetailsParent extends React.Component {
    render() {
        return <ResidentTableDetails />
    }
}

export default withNavBars(ResidentTableDetailsParent);
