import { useApolloClient } from "@apollo/client";
import {
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import FilterIMG from "../../assets/filter";
import {
  FilterGenerator,
  LoadingSection,
  SearchFilter,
  Subheader,
  TableWithPagination,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { UPDATE_UNIT } from "../../graphql/mutations";
import { GET_UNIT_TYPE_LIST } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { accessCheckRender, getPropertyHierarchyUnitRule, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";
import { Unitheading, Unitpath } from "../../utils/block";
import { unitStyles } from "./style";
import { AccountTable, DetailsContainer } from "./component";
import { AuthContext } from "../../contexts";

export const UnitList = (props) => {
  const classes = unitStyles();
  const history = useHistory();
  const client = useApolloClient();
  const search = useLocation().search;
  const [unitlist, setUnitList] = React.useState({
    data: [],
    count: 0,
    property: {},
    breadCrumbs: {},
  });

  const [drawer, setDrawer] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const floorID = new URLSearchParams(search).get("floorID");
  const propertyID = new URLSearchParams(search).get("propertyID");
  const blockID = new URLSearchParams(search).get("blockID");
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [limit, setLimit] = React.useState(10);
  const debounce = UseDebounce();
  const [filterData, setFilterData] = React.useState({ status: [true] });
  const [stats, setStats] = React.useState(null);
  const auth = React.useContext(AuthContext)
  const [permission, setPermission] = React.useState({})

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        getunitlist(
          propertyID ? propertyID : blockID ? blockID : floorID,
          filterData,
          "",
          0,
          limit
        );
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const headerpage = [
    {
      id: "1",
      label: unitlist?.breadCrumbs?.block_name,
      type: "name",
    },
    {
      id: "2",
      label: unitlist?.breadCrumbs?.floor_name,
      type: "name",
    },
    {
      id: "3",
      label: "Unit",
    },
  ];
  const headerpage1 = [
    {
      id: "1",
      label: unitlist?.breadCrumbs?.block_name,
      type: "name",
    },
    {
      id: "3",
      label: "Unit",
    },
  ];
  const headerpage2 = [
    {
      id: "3",
      label: "Unit",
    },
  ];

  const getunitlist = (id, filterProp, searchText, offSet, limits) => {
    const variables = {
      tenantId: `${config.tenantId}`,
      property_id: propertyID ?? null,
      block_id: blockID ?? null,
      floor_id: floorID ?? null,
      offset: offSet,
      limit: limits,
      revenue_type: filterProp?.unit_type?.length
        ? filterProp?.unit_type?.map(({ value }) => value)
        : [],
      status: filterProp?.status?.length ? filterProp?.status : [],
      unit_category: filterProp?.unit_category?.length
        ? filterProp?.unit_category?.map(({ value }) => value)
        : [],
      search: searchText,
    };
    NetworkCall(
      `${config.api_url}/unit/list`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        const list = res.data.list?.map((val) => {
          let _d;
          try {
            _d = {
              unitNo: val?.unit_no,
              unitName: val?.unit_name,
              unitcategory: val?.unit_category,
              revenuetype: val?.revenue_type,
              propertyName: val?.property?.name,
              unitpurpose: val?.unit_purpose,
              // eslint-disable-next-line
              totalarea: `${val?.total_area}  ${val?.area_metric ?? ""}`,
              furnishing: val?.furnishing,
              baths: val?.total_baths ? val?.total_baths : " - ",
              id: val?.unit_id,
              bed: val?.total_bed_rooms ? val?.total_bed_rooms : " - ",
              icon: "editview",
              occupancyStatus: val?.current_status
                ? val?.current_status
                : " - ",
              status: val?.is_active ? "Active" : "Inactive",
              blockName: val?.block_name,
              floorName: val?.floor_name,
              companyName: val?.company_name,
              // eslint-disable-next-line
              location: val?.property?.address?.city
                ? val?.property?.address?.city
                : val?.property?.address?.district,
            };
          } catch (err) { }
          return _d;
        });
        if (propertyID) {
          setUnitList({
            count: res?.data?.count,
            data: list,
            property: {
              name: res.data?.property?.name,
              id: res.data?.property?.id,
              property_hierarchy: res.data?.property?.property_hierarchy
            },
            breadCrumbs: res?.data?.breadCrumbs,
          });
        } else if (blockID) {
          setUnitList({
            count: res?.data?.count,
            data: list,
            property: {
              name: res.data?.property?.name,
              id: res.data?.property?.id,
              property_hierarchy: res.data?.property?.property_hierarchy
            },
            breadCrumbs: res?.data?.breadCrumbs,
          });
        } else {
          setUnitList({
            count: res?.data?.count,
            data: list,
            property: {
              name: res.data?.property?.name,
              id: res.data?.property?.id,
              property_hierarchy: res.data?.property?.property_hierarchy
            },
            breadCrumbs: res?.data?.breadCrumbs,
          });
        }
        getUnitStats(res?.data?.list?.[0]?.unit_id)
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const changeactive = async (data, value) => {
    let result = await client.mutate({
      mutation: UPDATE_UNIT,
      variables: {
        ID: data,
        updatePayload: {
          is_active: value === "Inactive" ? true : false,
        },
      },
    });
    if (result) {
      getunitlist(
        propertyID ? propertyID : blockID ? blockID : floorID,
        filterData,
        "",
        0,
        limit
      );
    }
  };
  const handleIcon = (type, data) => {
    if (type === "active") {
      changeactive(data?.id, data?.status);
    }
    if (type === "view") {
      getUnitStats(data?.id);
    }
    if (type === "edit") {
      history.push({
        pathname: Routes.createUnitNew,
        state: {
          main: {
            unitID: data?.id,
            isEdit: true,
          },
        },
      });
    }
    if (type === "info") {
      history.push(`/unitDetails?id=${data?.id}`);
    }
  };
  const handleOnClickAddUnitButton = () => {
    history.push({
      pathname: Routes.createUnitNew,
      state: {
        main: {
          floorID,
          blockID,
          propertyID,
        },
      },
    });
  };

  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getunitlist(
      propertyID ? propertyID : blockID ? blockID : floorID,
      filterData,
      e,
      0,
      limit
    );
  };
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getunitlist(
      propertyID ? propertyID : blockID ? blockID : floorID,
      filterData,
      searchText,
      offset,
      limit
    );
  };
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getunitlist(
      propertyID ? propertyID : blockID ? blockID : floorID,
      filterData,
      searchText,
      0,
      value
    );
  };

  const onApplyFilter = (value) => {
    setFilterData(value);
    getunitlist(
      propertyID ? propertyID : blockID ? blockID : floorID,
      value,
      "",
      0,
      limit
    );
  };

  const loadUnitCategory = async (search, array, handleLoading) => {
    const variables = {
      tenantId: `${config.tenantId}`,
      enumName: ["unit_category"],
    };
    let result = NetworkCall(
      `${config.api_url}/enum`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        handleLoading();
        let optionss = res?.data?.data?.unit_category ?? []
        let array = optionss;
        return {
          options: [...array],
          hasMore: false,
        };
      })
      .catch((err) => {
        handleLoading();
        console.log(err);
        return {
          options: [],
          hasMore: false,
        };
      });

    return result;
  };

  const manualResponse = (array) =>
    array?.map((_) => ({
      label: _?.unit_type?.label,
      value: _?.unit_type?.value,
    }));

  const getUnitStats = (id) => {
    setLoading(true);
    const payload = {
      unit_id: id,
    };
    NetworkCall(
      `${config.api_url}/unit/stats`,
      NetWorkCallMethods.post,
      payload,
      true,
      true,
      false
    )
      .then((res) => {
        setStats({
          data: res?.data?.data,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };


  const render = () => {
    return <>
      <Subheader
        arrow
        pageoptions={
          propertyID ? headerpage2 : blockID ? headerpage1 : headerpage
        }
        title={unitlist?.breadCrumbs?.property_name}
        goBack={() => {
          history.goBack();
        }}
      />
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={8}>
            {/*table */}
            <Box p={2}>
              <AccountTable
                draweropen={() => setDrawer(true)}
                data={unitlist?.data ?? []}
                handleChangeLimit={handleChangeLimit}
                handlePagination={handlePagination}
                page={page}
                handleSearch={handleSearch}
                searchText={searchText}
                limit={limit}
                handleIcon={handleIcon}
                //   onUnitClick={onUnitClick}
                totalRowsCount={unitlist?.count}
                handleOnClickAddPropertyButton={handleOnClickAddUnitButton}
                handleChange={handleSearch}
                unitlist={unitlist}
                permission={permission}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            {/*details */}
            <Box p={2} className={classes.detailsContainer}>
              {unitlist?.data?.length > 0 ? (
                <DetailsContainer stats={stats} loading={loading} />
              ) : (
                <div className={classes.noFound}>
                  <Typography className={classes.noData}>No Data</Typography>
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {loading ? (
        <LoadingSection bottom={"45vh"} message="Loading Unit Listing..." />
      ) : (
        <div className={classes.root}>
          <Box p={2} className={classes.table} m={2}>
            <Grid container>
              <Grid item xs={4}>
                <SearchFilter
                  value={searchText}
                  placeholder="Search Properties"
                  handleChange={(value) => handleSearch(value)}
                />
              </Grid>
              <Grid item xs={8}>
                <Box display={"flex"} sx={{ float: "right" }}>
                  <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                  >
                    {filterData?.status?.length > 0 ||
                      filterData?.unit_type?.length > 0 ? (
                      <IconButton
                        onClick={() => setDrawer(true)}
                        className={classes.img}
                      >
                        <Badge variant="dot" color="primary">
                          <FilterIMG color="#091b29" />
                        </Badge>
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => setDrawer(true)}
                        className={classes.img}
                      >
                        <FilterIMG color="#091b29" />
                      </IconButton>
                    )}
                    <>
                      {getPropertyHierarchyUnitRule(
                        unitlist?.breadCrumbs?.property_hierarchy,
                        unitlist?.count
                      ) && (
                          <Button
                            variant="contained"
                            className={classes.btn}
                            onClick={handleOnClickAddUnitButton}
                          >
                            Add Unit
                          </Button>
                        )}
                    </>
                    {/* } */}
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <TableWithPagination
              heading={Unitheading}
              rows={unitlist?.data}
              path={Unitpath}
              showpagination={true}
              count="2"
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              handleIcon={handleIcon}
              tableType="no-side"
              onClick={() => console.log("")}
              dataType={[
                { type: ["more"], icon: "icon" },
                { type: ["text"], name: "unitNo" },
                { type: ["text"], name: "unitName" },
                { type: ["text"], name: "unitcategory" },
                { type: ["text"], name: "revenuetype" },
                { type: ["text"], name: "totalarea" },
                { type: ["text"], name: "occupancyStatus" },
              ]}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              totalRowsCount={unitlist?.count}
              page={page}
              limit={limit}
              height={`calc(100vh - 300px)`}
              view={true}
              edit={true}
              delete={true} />
          </Box>
        </div>
      )}
      {/* filter drawer */}
      {drawer && (
        <FilterGenerator
          open={drawer}
          onClose={() => setDrawer(false)}
          components={[
            {
              component: "select",
              value: filterData?.unit_category,
              options: [],
              isMulti: true,
              label: "Unit Category",
              state_name: "unit_category",
              loadOptions: (search, array, handleLoading) =>
                loadUnitCategory(search, array, handleLoading),
              debounceTimeout: 800,
              isPaginate: true,
            },
            {
              component: "select",
              value: filterData?.unit_type,
              options: [],
              isMulti: true,
              label: "Unit Type",
              state_name: "unit_type",
              loadOptions: (search, array, handleLoading) =>
                loadOptions(
                  search,
                  array,
                  handleLoading,
                  GET_UNIT_TYPE_LIST,
                  "unit",
                  {},
                  {},
                  {},
                  manualResponse
                ),
              debounceTimeout: 800,
              isPaginate: true,
            },
            {
              component: "toggleButton",
              value: filterData?.status,
              options: [
                { label: "Active", value: true },
                { label: "Inactive", value: false },
              ],
              isMulti: true,
              state_name: "status",
              label: "Status",
              // required:true
            },
          ]}
          onApply={(value) => onApplyFilter(value)}
        />
      )}
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>
  );
};
