import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../../utils";
export const useStyles = makeStyles((theme) => ({

    image: {
        height: "150px",
        width: "100%",
        objectFit: "fill",
        borderRadius: "50%"
    },
    priority: {
        backgroundColor: "#071741",
        color: "white",
        padding: "2px 12px",
        fontSize: "12px",
        borderRadius: "2px",
        display: "inline",
    },
    content: {
        borderLeft: "1px solid #E4E8EE",
        padding: "0px 20px",
        marginLeft: "8px"
    },
    title: {
        color: "#4E5A6B",
        fontSize: "12px",
        fontFamily: Bold
    },
    header: {
        color: "#98A0AC",
        fontSize: "12px",
        fontFamily: SemiBold,
        marginTop: "12px",
    },
    subHeader: {
        color: "#091B29",
        marginTop: "8px",
        fontSize: "12px",
        fontFamily: SemiBold
    },
    borderRoot: {
        border: "1px solid #E4E8EE",
        padding: "12px",
        borderRadius: "4px",
        height: "100px",
        overflow: "auto"
    },
    requirement: {
        color: "#98A0AC",
        fontSize: "10px",
        fontFamily: SemiBold,
        marginTop: "4px"
    },
    requirementDetails: {
        color: "#091B29",
        fontSize: "12px",
        fontFamily: Bold
    },
    notes: {
        color: "#4E5A6B",
        fontSize: "14px",
        marginTop: "8px"

    },
    borderRoot1: {
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        padding: "12px"

    },
    header1: {
        color: "#98A0AC",
        fontSize: "12px",
        fontFamily: SemiBold,
    },
    fab: {
        position: "fixed",
        bottom: 129,
        right: 30,
        display: "flex",
        alignItems: "center",
        background: '#5078E1',
        boxShadow: "none",
        "&:hover": {
            background: '#5078E1',
        },
        [theme.breakpoints.down("sm")]: {
            bottom: 70,
            right: 14,
        },
    },

    sumbitidea: {
        color: "#fff",
        marginLeft: "10px",
        fontSize: 14,
    },
}));