import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F5F7FA",
        border: "1px dashed #E4E8EE",
        height: "300px",
        cursor: "pointer",
        borderRadius: "4px",
        position: "relative",
    },

    label: {
        fontSize: "14px",
        fontFamily: SemiBold
    },
    uploadIcon: {
        fontSize: "50px",
        color: "#5078E1"
    },
    uploadedimg: {
        width: "100%",
        objectFit: "contain",
        height: "100%",
        borderRadius: "4px",
    },
    deleteBtn: {
        position: "absolute",
        top: 10,
        right: 10,
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "white",
        },
    },
    note: {
        fontSize: "10px",
        fontFamily: Bold,
        color: "#98A0AC",
        backgroundColor: "#F5F7FA",
        textAlign: "center",
        borderRadius: "4px",
        padding: "4px",
        marginTop: "4px"
    }
}));