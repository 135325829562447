import gql from "graphql-tag";

export const GET_CORRESPONDENCE_OPTIONS = gql`
query GET_CORRESPONDENCE_OPTIONS {
    correspondences_type_master(
      where: { is_active: { eq: true } }
      order_by: { id: asc }
    ) {
      value: id
      label: name
    }
    correspondences_level_master(
      where: { is_active: { eq: true } }
      order_by: { id: asc }
    ) {
      value: id
      label: name
    }
    correspondences_status_master(
      where: { is_active: { eq: true } }
      order_by: { id: asc }
    ) {
      value: id
      label: name
    }
  }`

  export const GET_CORRESPONDENCE_BY_AGREEMENT_UNIT = gql`
  query GET_CORRESPONDENCE_BY_AGREEMENT_UNIT(
    $agreementUnitID: String
    $offset: Int
    $limit: Int
  ) {
    count: correspondence_units(
      where: { agreement_unit_id: { eq: $agreementUnitID } }
    ) {
      count: count_id
    }
    correspondence_units(
      where: { agreement_unit_id: { eq: $agreementUnitID } }
      offset: $offset
      limit: $limit
    ) {
      id
      correspondence_id
      agreement_unit_id
      correspondenceByID {
        id
        reference_no
      }
    }
  }`
  
  export const GET_CORRESPONDENCE_BY_CONTACT_ID = gql`
  query GET_CORRESPONDENCE_BY_CONTACT_ID(
    $contactID: String
    $offset: Int
    $limit: Int
  ) {
    count: correspondence_units(where: { contact_id: { eq: $contactID } }) {
      count: count_id
    }
    correspondence_units(
      where: { contact_id: { eq: $contactID } }
      offset: $offset
      limit: $limit
    ) {
      id
      correspondence_id
      contact_id
      correspondenceByID {
        id
        reference_no
      }
    }
  }`
  
  export const GET_CONTACT_BY_COMPANY_ID = gql`
  query GET_CONTACT_BY_COMPANY_ID($companyID: Int, $offset: Int, $limit: Int) {
    count: contact(
      where: { is_active: { eq: true }, company_id: { eq: $companyID } }
    ) {
      count: count_id
    }
    contact(
      where: { is_active: { eq: true }, company_id: { eq: $companyID } }
      offset: $offset
      limit: $limit
    ) {
      id
      first_name
    }
  }`