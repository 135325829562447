import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular } from "../../utils";
export const AggrementViewStyles = makeStyles((theme) => ({
    table: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        //padding: "16px",
        //height: `calc(100vh - 147px)`,
        overflow: "hidden",

    },
    tabtitle: {
        fontSize: '12px',
        color: '#4E5A6B',
        fontFamily: Bold,
    },
    tabtitle1: {
        fontSize: '12px',
        color: '#5078E1',
        fontFamily: Bold,
    },
    subtabtitle: {
        fontSize: '10px',
        color: '#4E5A6B',
        fontFamily: Regular,
        "& .makeStyles-tabs-78.MuiTab-root": {
            width: "100%"
        }
    },
    subtabtitle1: {
        fontSize: '10px',
        color: '#5078E1',
        fontFamily: Regular,
    },
    selectBack1: {
        backgroundColor: '#F1F7FF',
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
    },
    selectBack: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',

    },
}));