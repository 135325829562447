import makeStyles from "@mui/styles/makeStyles";
import { Bold, Italic, Regular, SemiBold } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "8px",
  },
  leadDetailsRoot: {

  },
  typeBg: {
    position: "relative",
    textAlign: "center",
    color: "white",
    marginRight:"-10px"
  },
  centeredText: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  type: {
    fontSize: "12px",
    fontFamily: Bold,
    marginLeft: "3px",
    marginTop: "1px"
  },
  leadDetailsRootDivider: {
    borderRadius: "4px",
    backgroundColor: "white",
    height: `calc(100vh - 260px)`,
    overflow: 'auto',
    boxShadow: "0px 0px 16px #00000014"
  },
  srcText: {
    color: "#896DB3",
    fontSize: "14px",
    fontFamily: Bold,
  },
  arrowIcon: {
    color: "#896DB3",
  },
  typeText: {
    color: "#6D80B3",
    fontSize: "14px",
    fontFamily: Bold,
    // marginLeft: "3px"
  },
  sourceTypeIcon: {
    marginLeft: "3px"
  },
  homeIcon: {
    color: "#6D80B3",
  },
  purposebox: {
    backgroundColor: "#E4EDFF80",
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    marginTop: "-5px"
  },
  srctypebox: {
    backgroundColor: "#EDE4FE80",
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    marginTop: "-12px"
  },
  title: {
    fontSize: "14px",
    fontFamily: SemiBold,
  },
  userImg: {
    height: "48px",
    width: "48px",
    borderRadius: "4px",
    objectFit: "fill"
  },
  detailRoot: {
    // backgroundColor: "#F5F7FA",
    // padding: "6px 6px 6px 6px"
    display: "flex",
    justifyContent: "space-between"
  },
  leadName: {
    fontSize: "14px",
    fontFamily: Bold,
    textOverflow:"ellipsis",
    overflow:"hidden",
    whiteSpace:"nowrap",
    width:"100px"
  },
  leadNo: {
    fontSize: "10px",
    fontFamily: Bold,
    backgroundColor: theme.palette.background.secondary,
    color: theme.typography.color.tertiary,
    borderRadius: "4px",
    padding: "2px 4px",
    marginTop: "4px"
  },
  mobileNo: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: Bold,
  },
  mailIcon: {
    fontSize: "19px",
    color: "#98a0ac",
    marginLeft: "3px"
  },
  divider: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  dataBold: {
    fontSize: "12px",
    color: "#4E5A6B",
    fontFamily: Bold
  },
  tabContant: {
    padding: "12px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    marginTop: "53px",
  },
  tabTitle: {
    color: "#4E5A6B",
    fontSize: "16px",
    fontFamily: SemiBold
  },
  image: {
    borderRight: "1px solid #E4E8EE",
  },
  viewCard: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "16px",
    marginTop: '16px'
  },
  customTypography: {
    fontSize: 12,
    fontFamily: Bold,
    color: '#4E5A6B',
    marginBottom: 10
  },
  //unitCard Styles
  unitCard: {
    padding: "15px",
    backgroundColor: "#DBF0F180",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: theme.palette.borderRadius,
    marginTop: "15px"
  },
  selectionIcon: {
    color: "#6DAFB3"
  },
  selectionText: {
    color: "#6DAFB3"
  },
  selectarrowRightIcon: {
    color: "#6DAFB3",
    marginTop: "8px"
  },
  ownerSelection: {
    padding: "25px",
  },
  selectCard: {
    display: "flex",
    justifyContent: "space-between",
  },
  ownerText: {
    fontSize: "16px",
    color: theme.typography.color.primary,
    fontFamily: Bold
  },
  ownerSubText: {
    fontSize: "14px",
    color: theme.typography.color.tertiary,
    fontFamily: Regular
  },
  ownerTitle: {
    fontSize: "12px",
    fontFamily: SemiBold,
    color: theme.typography.color.tertiary,
    marginBottom: "5px"
  },
  ownerAgainstUnits:{
    border:"1px solid #E4E8EE",
    backgroundColor:"white",
    marginTop:"5px"
  },
  individualownerSelection:{
    padding: "25px",
    height:"400px"
  },
  unitName:{
    fontSize:14,
    color:theme.typography.color.primary,
    fontFamily:SemiBold
  },
  unitLocation:{
    fontSize:10,
    color:theme.typography.color.tertiary,
    fontFamily:SemiBold
  },
  vacant:{
    fontSize:12,
    color:"#5AC782",
    fontFamily:SemiBold,
    marginTop:"10px"
  },
  mileStoneBg:{
    backgroundColor:"#F2F4F7",
    border:"1px solid #E4E8EE",
    padding:"15px"
  },
  mileStoneName:{
    fontSize:14,
    color:theme.typography.color.primary,
    fontFamily:Bold,
  },
  interestunitcard:{
    backgroundColor:"#F5F7FA"
  },
  unitproperty:{
  padding:"12px"
  },
  unit_name:{
    fontSize:"14px",
    color:theme.typography.color.primary,
    fontFamily:SemiBold
  },
  property_name:{
    fontSize:"12px",
    color:theme.typography.color.tertiary,
    fontFamily:Italic
  },
  intTitle:{
    fontSize:"13px",
    color:theme.typography.color.secondary,
    fontFamily:Bold  
  },
  unitselectivemain:{
    border:"1px solid #E4E8EE",
    borderRadius:theme.palette.borderRadius
  },
  unitselective:{
    padding:"12px"
  },
  remove:{
    fontSize:12,
    color:"#FF4B4B",
    fontFamily:SemiBold,
    marginTop:"10px"
  }
}));
export const useStylesDetailsCard = makeStyles((theme) => ({
  root: {
    padding: "12px",
    borderRadius: "8px",
    backgroundColor: "#fff",
  },
  heading: {
    fontSize: "12px",
    fontFamily: SemiBold,
    color: " #98A0AC",
  },
  sub: {
    fontSize: "14px",
    fontFamily: SemiBold,
    color: "#091B29",
    marginTop: "6px",
  },
  status: {
    fontSize: "12px",
    fontFamily: Bold,
    padding: "2px 10px",
    borderRadius: "2px",
    display: "inline-block",
    marginTop: "6px",
  },
  btn: {
    padding: "0px",
    boxShadow: "none",
    marginTop: "6px",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  title: {
    fontSize: "12px",
    fontFamily: Bold,
    color: "#4E5A6B",
  },
  imageView: {
    height: "100px",
    width: "100px",
    margin: '0 auto'
  },
  templateTitle:{
    color:'#091B29',
    fontFamily:Bold,
    fontSize:"14px",
    marginBottom:"2px"
  },
  templateDesc:{
    color:'#98A0AC',
    fontFamily:Regular,
    fontSize:"12px"
  },
  templateUpdate:{
    color:'#5078E1',
    fontFamily:Bold,
    fontSize:"12px", 
    cursor:'pointer'
  },
  templateRoot:{
    padding:"12px",
    backgroundColor:'white',
    marginTop:"12px",
    border:`1px solid #E4E8EE`
  },
  templateAmountTilte:{
    color:'#578C8F',
    fontFamily:Bold,
    fontSize:"12px" ,
    marginLeft:"12px"
  },
  amountRoot:{
    padding:"14px",
    backgroundColor:'#EEF9EE',
    borderRadius:"4px",
    marginTop:"12px"
  },
  downBtn: {
    border: `1px solid ${theme?.typography?.status?.primary}`,
    color: theme?.typography?.color?.white,
    backgroundColor: theme?.typography?.status?.primary,
    width:"100%",
    "&:hover": {
      border: `1px solid ${theme?.typography?.status?.primary}`,
      color: theme?.typography?.color?.white,
      backgroundColor: theme?.typography?.status?.primary,
    }
},

}));