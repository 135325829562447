/* eslint-disable array-callback-return */
import {
    Grid
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useLocation } from "react-router-dom";
import { AccordionWithTable } from '../../components';
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { GET_ALL_UNIT_AGREEMENT, GET_ALL_UNIT_INVOICE } from '../../graphql/queries';
import { NetworkCall } from "../../networkcall";
import { AlertProps, ExtraBold, NetWorkCallMethods, SemiBold } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { OwnerDetails } from './ownerDetails';
import { Bold } from '../../utils';


const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        height: `calc(100vh - 88px)`,
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "0px",
        overflow: "hidden"
    },

    btn: {
        borderRadius: theme.palette.borderRadius,
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
        // borderColor: theme.palette.secondary.main,
    },
    text: {
        fontSize: "24px",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    subText: {
        fontSize: "16px",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    bottomTitle: {
        fontSize: "18px",
        fontFamily: ExtraBold

    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        marginBottom: "12px"
    },
    contentBottom: {
        // padding: "12px",

    },
    drawer: {
        "& .MuiDrawer-paper": {

            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px"
        },
    },
    grid: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    title: {
        fontSize: "14px",
        fontFamily: Bold,
        color: theme.typography.color.primary

    },
    subTitle: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: theme.palette.primary.main
    }
}))

const getAllids = (data) => {
    let list = [];
    if (data?.length > 0) {
        data?.map(id => {
            list = [...list, id?.unitId]
        })
    }
    return list;
}

export const OwnerTableDetails = props => {

    const classes = useStyles();
    const size = useWindowDimensions();
    const alert = React.useContext(AlertContext);
    const [ownerDetailsData, setOwnerDetailsData] = React.useState([])
    const search = useLocation().search;
    const userId = new URLSearchParams(search).get("userId");
    const companyId = new URLSearchParams(search).get("companyId");
    const getOwnerDetailsId = () => {
        const payload = {
            tenantId: `${config.tenantId}`,
            companyId: Number(companyId),
            userId: userId
        };
        NetworkCall(
            `${config.api_url}/owner-resident/owner`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setOwnerDetailsData(response?.data?.data);
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something went wrong please try again",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                });
            });

    };

    React.useEffect(() => {
        getOwnerDetailsId();
        // eslint-disable-next-line
    }, []);
    const allUnitId = getAllids(ownerDetailsData);
    // const unitId = ""
    const accordionData = [
        {
            id: 1,
            title: "Agreements",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Agreement No ", field: 'agreementNo', },
                    { title: "Agreement primary contact name ", field: 'agreementPrimaryContactName', },
                    { title: "Contact No", field: 'contact', },
                    { title: "email Id", field: 'emailAddress', },
                    { title: " ", field: 'icon', },


                ],
                path: ["sno", "agreementNo", "agreementPrimaryContactName", "contact", "emailAddress",],
                dataType: [
                    { type: ["text"], name: "sno" },
                    { type: ["text"], name: "agreementNo" },
                    { type: ["text"], name: "agreementPrimaryContactName" },
                    { type: ["text"], name: "contact" },
                    { type: ["text"], name: "emailAddress" },
                    { type: ["icon"], name: "icon" },

                ],

            },
            query: GET_ALL_UNIT_AGREEMENT,
            unitId: allUnitId
        },
        {
            id: 2,
            title: "Unit",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Unit ID ", field: 'unitID', },
                    { title: "Tenant Name", field: 'tenantName', },
                    { title: "Primary Contact", field: 'primaryContact', },
                    { title: "Email Address", field: 'emailAddress', },
                    { title: " ", field: 'icon', },


                ],
                path: ["sno", "unitID", "tenantName", "primaryContact", "emailAddress",],
                dataType: [
                    { type: ["text"], name: "sno" },
                    { type: ["text"], name: "unitID" },
                    { type: ["text"], name: "tenantName" },
                    { type: ["text"], name: "primaryContact" },
                    { type: ["text"], name: "emailAddress" },
                    { type: ["icon"], name: "icon" },

                ],

            },
            query: GET_ALL_UNIT_INVOICE,
            unitId: allUnitId
        },
    ]

    return <div className={classes.root}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3} >

                <>
                    <OwnerDetails data={ownerDetailsData[0]} />
                </>

            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9}>
                <div
                    style={{
                        height: size.height - 88,
                        overflow: "scroll",
                    }}
                >
                    <Grid conatiner>
                        {accordionData.map((item) => (
                            <>
                                <Grid item xs={12} marginTop={2}>
                                    <AccordionWithTable data={item} />
                                </Grid>
                            </>
                        )
                        )}
                    </Grid>
                </div>
            </Grid>
        </Grid>

    </div>
}