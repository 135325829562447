import makeStyles from "@mui/styles/makeStyles";
export const UtilityStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        // padding: "16px",
        //height: `calc(100vh - 147px)`,
        overflow: "hidden",

    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize: "14px"
    },
    drawerHeaderStyle: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
    },
    drawerHeaderTextStyle: {
        fontSize: "16px",
        fontWeight: "bolder",
        color: theme.typography.color.primary,
    },
    label: {
        color: "#98A0AC", fontSize: "12px", marginBottom: "4px"
    },
    required: {
        color: "red", marginLeft: 4
    }

}));