import gql from "graphql-tag";
export const getDepartment = gql`
    query {
        department_master(where: { company_id: $company_id }) {
          value: id
          label: name
        }
      }
`;
export const getRole = gql`
    query {
        job_master(where: { company_id: $company_id }) {
          value: id
          label: name
        }
      }
`;
export const getResourceType = gql`
    query{
        resource_type_master(where: { is_active:true }){
          value: id
          label: name
        }
      } 
`

// options for unit type
export let GET_DEPARTMENT_LIST = (
  offset,
  limit,
  searchText,
  obj,
) => gql`
        query GET_OPTIONS_FOR_UNIT_TYPE {
          count: department_master (
            where: {
              is_active: { eq: true }
              name: { iregex: "${searchText}" }
              company_id: { eq: ${obj?.company_id} }
            }
            offset:${offset}
            limit:${limit}
          ) {
            count: count_id
          }
          department_master(
            where: {
              is_active: { eq: true }
              name: { iregex: "${searchText}" }
              company_id: { eq: ${obj?.company_id} }
            }
            offset:${offset}
            limit:${limit}
          ) {
            value: id
            label: name
          }
        }`;


// options for unit type
export let GET_Role_LIST = (
  offset,
  limit,
  searchText,
  obj,
) => gql`
        query GET_OPTIONS_FOR_UNIT_TYPE {
          count: job_master (
            where: {
              is_active: { eq: true }
              name: { iregex: "${searchText}" }
              company_id: { eq: ${obj?.company_id} }
            }
            offset:${offset}
            limit:${limit}
          ) {
            count: count_id
          }
          job_master(
            where: {
              is_active: { eq: true }
              name: { iregex: "${searchText}" }
              company_id: { eq: ${obj?.company_id} }
            }
            offset:${offset}
            limit:${limit}
          ) {
            value: id
            label: name
          }
        }`;


export let GET_RESOURCE_LIST = (
  offset,
  limit,
  searchText,
  obj,
) => gql`
        query GET_OPTIONS_FOR_UNIT_TYPE {
          count: resource_type_master (
            where: {
              is_active: { eq: true }
              name: { iregex: "${searchText}" }
            }
            offset:${offset}
            limit:${limit}
          ) {
            count: count_id
          }
          resource_type_master(
            where: {
              is_active: { eq: true }
              name: { iregex: "${searchText}" }
            }
            offset:${offset}
            limit:${limit}
          ) {
            value: id
            label: name
          }
        }`;

