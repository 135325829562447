import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Avatar, Box, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Bold } from '../../../utils';

const useStyles = makeStyles((theme) => ({
    rootcard: {
        cursor: "pointer",
        //borderBottom: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        padding: "2px 12px",
        width: "400px"
    },
    periodStyle: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    name: {
        fontSize: "14px",
        fontFamily: Bold,
    },
    call: {
        fontSize: "10px",
        color: theme.typography.color.secondary,
    },
    mail: {
        fontSize: "10px",
        color: theme.typography.color.secondary,
    },
    // img: {
    //     objectFit: "cover",
    //     height: "54px",
    //     width: "54px",
    //     borderRadius: "50%"
    // },
    tagStyle: {
        backgroundColor: "#5078E11E",
        borderRadius: "5px",
        padding: "3px 10px 3px 10px",
        fontSize: "10px",
        fontFamily: Bold,
        color: theme.typography.color.primary,
        marginLeft: "8px"
    },

}));

export const ContactList = (props) => {
    const classes = useStyles();

    return (

        <Box display="flex" className={classes.rootcard} alignItems="center" onClick={() => props?.onClick(props?.data)}>
            <Box >
                <Avatar src={props?.data?.image_url?.length > 0 ? props?.data?.image_url : null} />
            </Box>
            <Box flexGrow={1} marginTop="6px" marginLeft="8px">
                <Typography className={classes.name}>
                    {props?.data?.name}
                </Typography>
                <Box height="4px" />
                <Stack direction="row" alignItems="center">
                    <Box>
                        <Typography className={classes.call}>
                            {props?.data?.mobile_no_country_code} {props?.data?.mobile_no}
                        </Typography>
                    </Box>
                    <Box className={classes.periodStyle} />
                    <Box>
                        <Typography className={classes.mail}>
                            {props?.data?.email_id}
                        </Typography>
                    </Box>
                </Stack>
            </Box>
            {
                props?.data?.id === props?.value?.id &&
                <Box marginLeft="8px">
                    <CheckBoxIcon sx={{ color: "#5078E1" }} />
                </Box>
            }

        </Box>
    );
};
