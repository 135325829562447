import makeStyles from "@mui/styles/makeStyles";
import { ExtraBold, Regular, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        backgroundColor: "white",
    },
    signupform: {
        overflow: "overlay",
        padding: "0px 70px",
        alignContent: "start",
        height: (size) => size?.height
    },
    image: {
        marginTop: "70px",
        marginBottom: (size) => size?.height > 508.13 ? ((size?.height - 468.13) / 2) : "20px"
    },
    grid: {
        alignItems: "start",
    },
    text: {
        fontSize: "24px",
        fontFamily: ExtraBold,
        color: theme.typography.color.primary,
        marginTop: "12px",
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 6px 10px #00000014",
        opacity: 1,
        padding: "8px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    verificationText: {
        fontSize: "14px",
        fontFamily: Regular,
        textAlign: "center",
        color: theme.typography.color.primary,
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "20px",
    },
    poweredby: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        fontSize: "8px",
        color: theme.typography.color.tertiary,
    },
    pa: {
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
        fontSize: "10px",
    },
    title: {
        fontFamily: ExtraBold,
        fontSize: "24px",
        color: "#091B29",
    },
    content: {
        width: "inherit",
    },
    backgroundImage: {
        backgroundColor: "#7EA1FF",
        height: "213px",
        backgroundImage: `url("/images/login_background_1-min.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100%"
    },
    img: {
        height: "8%",
        width: "28%",
    },
    background: {
        backgroundImage: `url(/images/login_background_1-min.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
    },
}));