import { useApolloClient } from "@apollo/client";
import { Box, Button, Grid } from "@mui/material";
import moment from "moment";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormGenerator, LoadingSection, TextBox, UploadComponent } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { GET_CONTACT, GET_CONTACT_ACCOUNT, GET_DEPORTMENT, GET_JOBS, GET_TOOLS, GET_VEHICLE, GET_WORKING_HOURES } from "../../../graphql/resourceMaster";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, enumSelect, enum_types, isEmptyObject, LocalStorageKeys, NetWorkCallMethods } from "../../../utils";
import { loadOptions } from "../../../utils/asyncPaginateLoadOptions";
import { initialState, resourceTypes, returnEditPayload, returnSavePayload } from "../../../utils/resourceMaster";
import { ResourceMasterComponentStyles } from "./styles";
import { UserCard } from "./userCard";

const Resources = ({
    type = "",
    setData = () => false,
    data = {},
    company = {},
    reload = () => false,
    onClose = () => false,
    setSelectedType = () => false
}) => {
    const classes = ResourceMasterComponentStyles();
    const client = useApolloClient();
    const alert = React.useContext(AlertContext);
    const [list, setList] = React.useState([]);
    const [offset, setOffset] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [assets, setAssets] = React.useState([]);
    const [module, setModule] = React.useState([]);
    const [period, setPeriod] = React.useState([]);
    const [loading, setLoading] = React.useState(true)

    //get Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.resource_period_enum])
        setPeriod(result?.resource_period_enum)
    }
    //get module
    const getModule = () => {
        const payload = {
            offset: 0,
            limit: 500
        }
        NetworkCall(
            `${config.api_url}/modules`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {

            const result = res?.data?.data?.map((val) => {
                return {
                    value: val?.id,
                    label: val?.name
                }
            })
            setModule(result)
        })
    }
    //get account
    const getList = (types, offset, search, fetch) => {

        client.query({
            query: types === `${resourceTypes?.account}` ? GET_CONTACT_ACCOUNT : GET_CONTACT,
            variables: {
                search: search,
                offset: offset,
                limit: 10,
                client: localStorage.getItem(LocalStorageKeys.clinetID)
            },
            fetchPolicy: 'network-only'

        }).then((response) => {
            if (fetch) {
                setList(response?.data?.list)
            } else {
                setList(list.concat(response?.data?.list))
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    //get details
    const getDetails = () => {
        const payload = {
            id: data?.id,
        }
        NetworkCall(
            `${config.api_url}/resources/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            let result = returnEditPayload(res?.data);
            setAssets(result?.assets)
            setData(result)
            setSelectedType(result?.type)
            getList(result?.type?.label, 0, "", false)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
        })
    }
    //initial load
    React.useEffect(() => {
        if (data?.isEdit) {
            getDetails()

        } else {
            getList(type?.label, 0, "", false)
            setLoading(false)
        }
        getModule()
        getEnum()
        // eslint-disable-next-line
    }, [])
    //onchange module
    const changeModule = (val) => {
        updateState("module", val)
    }
    //updateState
    const updateState = (key, value) => {

        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    };
    const manualResponse = (array) => array?.map(_ => ({
        label: `${_?.shift_name} ( ${moment(_?.from, ["HH:mm"]).format(
            "hh:mm a"
        )} - ${moment(_?.to, ["HH:mm"]).format(
            "hh:mm a"
        )})`, value: _?.id
    }));

    //form json
    const toolForm = [
        {
            size: {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 4
            },
            isActive: type?.label === `${resourceTypes?.tools}`,
            component: "select",
            label: `${resourceTypes?.tools}`,
            placeholder: "Select Tools",
            value: data?.tool,
            onChange: (value) => {
                setData({
                    ...data,
                    tool: value,
                    rate_hour: value?.rate,
                    period: {
                        label: value?.period,
                        value: value?.period,
                    }
                })
            },
            error: data?.error?.tool,
            isRequired: true,
            loadOptions: (search, array, handleLoading) => loadOptions(
                search,
                array,
                handleLoading,
                GET_TOOLS,
                'tools_master',
                { company_id: company?.value }
            ),
            debounceTimeout: 800,
            isPaginate: true,
        },
        {
            size: {
                xs: (type?.label === `${resourceTypes?.users}` || type?.label === `${resourceTypes?.account}`) ? 12 : 4,
                sm: (type?.label === `${resourceTypes?.users}` || type?.label === `${resourceTypes?.account}`) ? 12 : 4,
                md: (type?.label === `${resourceTypes?.users}` || type?.label === `${resourceTypes?.account}`) ? 12 : 4,
                lg: (type?.label === `${resourceTypes?.users}` || type?.label === `${resourceTypes?.account}`) ? 12 : 4
            },
            isActive: (type?.label === `${resourceTypes?.users}` || type?.label === `${resourceTypes?.account}`),
            component: "select",
            label: "Module",
            placeholder: "Select Module",
            value: data?.module,
            onChange: (value) => changeModule(value),
            error: data?.error?.module,
            isRequired: true,
            options: module
        },
        {
            size: {
                xs: type?.label === `${resourceTypes?.account}` ? 12 : 4,
                sm: type?.label === `${resourceTypes?.account}` ? 12 : 4,
                md: type?.label === `${resourceTypes?.account}` ? 12 : 4,
                lg: type?.label === `${resourceTypes?.account}` ? 12 : 4
            },
            isActive: type?.label !== `${resourceTypes?.users}`,
            component: "select",
            label: "Department",
            placeholder: "Select Department",
            value: data?.department,
            onChange: (value) => updateState("department", value),
            error: data?.error?.department,
            isRequired: true,
            loadOptions: (search, array, handleLoading) => loadOptions(
                search,
                array,
                handleLoading,
                GET_DEPORTMENT,
                'department_master',
                { company_id: company?.value }
            ),
            debounceTimeout: 800,
            isPaginate: true,
        },
        {
            size: {
                xs: type?.label === `${resourceTypes?.users}` ? 12 : 4,
                sm: type?.label === `${resourceTypes?.users}` ? 12 : 4,
                md: type?.label === `${resourceTypes?.users}` ? 12 : 4,
                lg: type?.label === `${resourceTypes?.users}` ? 12 : 4
            },
            isActive: type?.label === `${resourceTypes?.users}`,
            component: "select",
            label: "Jobs",
            placeholder: "Select Jobs",
            value: data?.job,
            onChange: (value) => updateState("job", value),
            error: data?.error?.job,
            isRequired: true,
            loadOptions: (search, array, handleLoading) => loadOptions(
                search,
                array,
                handleLoading,
                GET_JOBS,
                'job_master',
                { company_id: company?.value },

            ),
            debounceTimeout: 800,
            isPaginate: true,
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: type?.label === `${resourceTypes?.users}` || type?.label === `${resourceTypes?.account}`,
            component: "select",
            label: "Working Calender",
            placeholder: "Select Working Calender",
            value: data?.working_hour,
            onChange: (value) => updateState("working_hour", value),
            error: data?.error?.working_hour,
            isRequired: true,
            loadOptions: (search, array, handleLoading) => loadOptions(
                search,
                array,
                handleLoading,
                GET_WORKING_HOURES,
                'working_hours_master',
                { company_id: company?.value },
                {},
                {},
                manualResponse
            ),
            debounceTimeout: 800,
            isPaginate: true,
        },
        {
            size: {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 4
            },
            isActive: type?.label === `${resourceTypes?.generic}`,
            component: "text",
            label: "Rate",
            placeholder: "Enter Rate",
            value: data?.rate_hour,
            onChange: (value) => updateState("rate_hour", value.target.value),
            error: data?.error?.rate_hour,
            isRequired: true,
            type: "number",
            endAdornment: company?.currency_symbol
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: type?.label === `${resourceTypes?.users}` || type?.label === `${resourceTypes?.account}`,
            component: "text",
            label: "Hours Rate",
            placeholder: "Enter Hours Rate",
            value: data?.rate_hour,
            onChange: (value) => updateState("rate_hour", value.target.value),
            error: data?.error?.rate_hour,
            isRequired: true,
            type: "number",
            endAdornment: company?.currency_symbol
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: type?.label === `${resourceTypes?.users}` || type?.label === `${resourceTypes?.account}`,
            component: "text",
            label: "Daily Rate",
            placeholder: "Enter Daily Rate",
            value: data?.daily_rate,
            onChange: (value) => updateState("daily_rate", value.target.value),
            error: data?.error?.daily_rate,
            isRequired: true,
            type: "number",
            endAdornment: company?.currency_symbol
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: type?.label === `${resourceTypes?.users}` || type?.label === `${resourceTypes?.account}`,
            component: "user",
            label: "Reporting To",
            placeholder: "Select Reporting To",
            value: data?.reporting_to,
            onChange: (value) => updateState("reporting_to", value),
            error: data?.error?.reporting_to,
            isRequired: true,
        },
        {
            size: {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 4
            },
            isActive: type?.label === `${resourceTypes?.vehicle}`,
            component: "select",
            label: "Vehicle",
            placeholder: "Select Vehicle",
            value: data?.vehicles,
            type: "number",
            onChange: (value) => {
                setData({
                    ...data,
                    vehicles: value,
                    rate_hour: value?.rate,
                    period: {
                        label: value?.period,
                        value: value?.period,
                    }
                })
            },
            error: data?.error?.vehicles,
            isRequired: true,
            loadOptions: (search, array, handleLoading) => loadOptions(
                search,
                array,
                handleLoading,
                GET_VEHICLE,
                'vehicle_master',
                { company_id: company?.value },
            ),
            debounceTimeout: 800,
            isPaginate: true,
        },
        {
            size: {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 4
            },
            isActive: type?.label === `${resourceTypes?.vehicle}` || type?.label === `${resourceTypes?.tools}`,
            component: "text",
            label: type?.label === `${resourceTypes?.vehicle}` ? "Vehicle Number" : "Serial Number",
            placeholder: "Enter Vehicle Number",
            value: data?.vehicles_no,
            onChange: (value) => updateState("vehicles_no", value.target.value),
            error: data?.error?.vehicles_no,
            isRequired: true
        },
        {
            size: {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 4
            },
            isActive: type?.label === `${resourceTypes?.vehicle}` || type?.label === `${resourceTypes?.tools}`,
            component: "text",
            label: "Rate",
            placeholder: "Enter Rate",
            value: data?.rate_hour,
            onChange: (value) => updateState("rate_hour", value.target.value),
            isRequired: true,
            error: data?.error?.rate_hour,
            endAdornment: company?.currency_symbol
        },
        {
            size: {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 4
            },
            isActive: type?.label === `${resourceTypes?.generic}`,
            component: "text",
            label: `${resourceTypes?.generic}`,
            placeholder: "Enter Generic",
            value: data?.name,
            onChange: (value) => updateState("name", value.target.value),
            error: data?.error?.name,
            isRequired: true,
        },
        {
            size: {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 4
            },
            isActive: type?.label === `${resourceTypes?.vehicle}` || type?.label === `${resourceTypes?.tools}`,
            component: "select",
            label: "Period",
            placeholder: "Select Period",
            value: data?.period,
            onChange: (value) => updateState("period", value),
            isRequired: true,
            error: data?.error?.period,
            options: period
        },
    ]
    //more
    const fetchMoreData = () => {
        setOffset(offset + 10, search);
        getList(type?.label, offset + 10, "", false);
    }
    //handleSearch
    const handleSearch = (val) => {
        setSearch(val)
        getList(type?.label, 0, val, true);
    }
    const validateForm = () => {
        let isValid = true;
        let error = data.error;

        if (type?.value?.length === 0) {
            isValid = false;
            error.type = "Type is Required";
        }
        //account and users
        if (type?.label === `${resourceTypes?.account}` || type?.label === `${resourceTypes?.users}`) {

            if (data?.working_hour?.length === 0) {
                isValid = false;
                error.working_hour = "Working Calender is Required";
            }
            if (data?.rate_hour?.length === 0) {
                isValid = false;
                error.rate_hour = "Hours Rate is Required";
            }
            if (data?.daily_rate?.length === 0) {
                isValid = false;
                error.daily_rate = "Daily Rate is Required";
            }
            if (data?.reporting_to?.length === 0) {
                isValid = false;
                error.reporting_to = "Reporting To is Required";
            }
            if (data?.module?.length === 0) {
                isValid = false;
                error.module = "Module is Required";
            }
        }
        if (type?.label === `${resourceTypes?.tools}` || type?.label === `${resourceTypes?.vehicle}`) {
            if (data?.rate_hour?.length === 0) {
                isValid = false;
                error.rate_hour = "Rate is Required";
            }
            if (data?.period?.length === 0) {
                isValid = false;
                error.period = "Period is Required";
            }
        }
        if (type?.label === `${resourceTypes?.account}`) {
            if (data?.account?.length === 0) {
                isValid = false;
                error.account = "Account is Required";
            }
        }
        if (type?.label !== `${resourceTypes?.users}`) {
            if (data?.department?.length === 0) {
                isValid = false;
                error.department = "Department is Required";
            }

        }
        if (type?.label === `${resourceTypes?.users}`) {
            if (data?.user?.length === 0) {
                isValid = false;
                error.user = "User is Required";
            }
            if (data?.job?.length === 0) {
                isValid = false;
                error.job = "Jobs is Required";
            }
            if (data?.leave?.length > 0) {
                if (!isEmptyObject(data?.leave)) {
                    isValid = false;
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: `Please Fill All Fields of  Leave`,

                    });
                }
            }
        }
        if (type?.label === `${resourceTypes?.tools}`) {
            if (data?.tool?.length === 0) {
                isValid = false;
                error.tool = "Tools is Required";
            }
            if (data?.vehicles_no?.length === 0) {
                isValid = false;
                error.vehicles_no = "Serial Number is Required";
            }
        }
        if (type?.label === `${resourceTypes?.vehicle}`) {
            if (data?.vehicles?.length === 0) {
                isValid = false;
                error.vehicles = "Vehicles is Required";
            }
            if (data?.vehicles_no?.length === 0) {
                isValid = false;
                error.vehicles_no = "Vehicles Number is Required";
            }
        }
        if (type?.label === `${resourceTypes?.generic}`) {
            if (data?.name?.length === 0) {
                isValid = false;
                error.name = "Generic Name is Required";
            }
        }
        // if (assets?.length === 0) {
        //     isValid = false;
        //     error.assets = "Assets is Required";
        // }

        setData({ ...data, error });
        return isValid;
    }
    //onsubmit
    const onSubmit = () => {

        // const payload = returnSavePayload(data, type?.label, company, assets, type)

        if (validateForm()) {


            const payload = returnSavePayload(data, type?.label, company, assets, type)

            NetworkCall(
                `${config.api_url}/resources/upsert`,
                NetWorkCallMethods.post,
                {...payload ,  "size_type":"resource_size"},
                null,
                true,
                false
            ).then((res) => {
                reload();

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `Resource ${data?.id ? "Updated" : "Created"}`,

                });
                setData({ ...initialState })
                onClose()
            }).catch((err) => {
                console.log(err)
                if (err?.response?.status === 406)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Your  Resource  limit has been hit! Please upgrade your plan!",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",

                    });
                }
            })
        }

    }
    return (
        <Box className={`${classes.resources}`}>
            {
                loading ?

                    <LoadingSection message="Fetching Details..." top="10vh" />
                    :
                    <Box className={classes.formRoot} >
                        <Grid container >
                            <Grid item xs={(type?.label === `${resourceTypes?.users}` || type?.label === `${resourceTypes?.account}`) ? 6 : 12} p={2} className={(type?.label === `${resourceTypes?.users}` || type?.label === `${resourceTypes?.account}`) && classes.fromSection}>
                                {/* Add tool */}
                                <FormGenerator components={toolForm} />
                                {
                                    (type?.label === `${resourceTypes?.users}` || type?.label === `${resourceTypes?.account}`) &&

                                    <Box>
                                        {/* {type?.label === `${resourceTypes?.users}` && <LeaveComponent file={data?.leave} update={(val) => updateState("leave", val)} />} */}

                                        <UploadComponent
                                            handleChange={(val) => updateState("assets", val)}
                                            logo_title={"IMAGES"}
                                            errorMessage={data?.error?.assets}
                                            isError={data?.error?.assets?.length > 0}
                                            assets={assets}
                                            setAssets={setAssets}
                                            xs={4}
                                        //isrequired
                                        />
                                    </Box>
                                }

                            </Grid>
                            {
                                (type?.label === `${resourceTypes?.users}` || type?.label === `${resourceTypes?.account}`) &&
                                <Grid item xs={6} p={2}>
                                    {
                                        (type?.label === `${resourceTypes?.users}` || type?.label === `${resourceTypes?.account}`) &&
                                        <Box>
                                            <Box p={2} marginTop="-12px">
                                                <TextBox
                                                    placeholder={`Search ${type?.label} Name`}
                                                    label={`Search ${type?.label} Name`}
                                                    value={search}
                                                    isrequired
                                                    isError={data?.error?.account?.length > 0 || data?.error?.user?.length > 0}
                                                    errorMessage={data?.error?.account || data?.error?.user}
                                                    onChange={(val) => handleSearch(val.target.value)}

                                                />
                                            </Box>
                                            <InfiniteScroll
                                                dataLength={list?.length ?? ""}
                                                next={fetchMoreData}
                                                hasMore={true}
                                                height={350}
                                            >
                                                <Box p={1} >
                                                    {
                                                        list?.map((val) => {
                                                            return (
                                                                <Box p={1} >
                                                                    <UserCard list={val} value={type?.label === `${resourceTypes?.users}` ? data?.user : data?.account} clickCheck={(val) =>
                                                                        updateState(
                                                                            type?.label === `${resourceTypes?.users}` ? "user" : "account",
                                                                            val)} />
                                                                </Box>

                                                            )
                                                        })
                                                    }
                                                </Box>
                                            </InfiniteScroll>
                                        </Box>
                                    }

                                </Grid>
                            }

                            {
                                (type?.label === `${resourceTypes?.tools}` || type?.label === `${resourceTypes?.generic}` || type?.label === `${resourceTypes?.vehicle}`) &&
                                <Grid item xs={12} p={2} marginTop="-16px">
                                    <UploadComponent
                                        handleChange={(val) => updateState("assets", val)}
                                        logo_title={"IMAGES"}
                                        errorMessage={data?.error?.assets}
                                        isError={data?.error?.assets?.length > 0}
                                        assets={assets}
                                        setAssets={setAssets}
                                        xs={2}
                                    //isrequired
                                    />

                                </Grid>
                            }

                        </Grid>

                    </Box >
            }
            {/* buttons */}
            < Box display="flex" className={`${classes.btngrp}`} p={1} >
                <Button variant="outlined" className={`${classes.btn_outlined}`}>Cancel</Button>
                <Button variant="contained" className={`${classes.btn_contained}`} onClick={onSubmit}>{data?.isEdit === true ? "Update" : "Create"}</Button>
            </Box >
        </Box >
    )
}
export default Resources