import { Box, Grid, Stack, Divider, Button, Dialog, DialogContent, DialogContentText, Typography } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next';
import FilterIcon from '../../assets/filterIcon';
import { AlertDialog, FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { AuthContext, BackdropContext } from '../../contexts';
import { accessCheckRender, enum_types, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from '../../utils';
import { FreeTextInvoiceTableHeading, FreeTextInvoiceTablePath, createFreeText } from '../../utils/freeTextInvoice';
import styled from '@mui/material/styles/styled';
import { FreeTextInvoiceStyle } from './style'
import { CreatePopup } from './createPopup';
import StepsContent from '../createInvoiceFreeText/stepsContent';
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';
import { StatusOptionList } from '../../utils/accessGates/accessGatesListUtils';
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import uuid from "uuid";
import { useApolloClient } from '@apollo/client';
import { UPDATE_STATUS } from '../../graphql/freeTextInvoice';

const CustomPaper = styled('div')(({ theme }) => ({
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 152px)`,
    overflow: "hidden",
    margin: "14px"
}))

const FreeTextInvoice = ({ t }) => {
    const classes = FreeTextInvoiceStyle()
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const debounce = UseDebounce()
    const history = useHistory()
    const client = useApolloClient();

    const Heading = FreeTextInvoiceTableHeading(t)

    const [companyList, setCompanyList] = React.useState([]);
    const [permission, setPermission] = React.useState({})
    const [selectedCompany, setSelectedCompany] = React.useState({});

    const [freeTextData, setFreeTextData] = React.useState({
        tableRow: [],
        limit: 10,
        page: 1,
        createPopup: false,
        view: false,
        searchText: "",
        delete: false,
        invoiceDataList: {},
        viewData: {}
    })
    const [publish,setPublish] = React.useState(false)

    const [filterData, setFilterData] = React.useState({ status: [true] })
    const [filterDrawer, setFilterDrawer] = React.useState(false)

    React.useEffect(() => {
        const _ = getRoutePermissionNew(auth)
        if (_) {
            setPermission(_)
            if (_?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    getCustomerList(0, 10, "", [], [], company?.selected?.value)
                }
            }
        }
        // eslint-disable-next-line        
    }, [auth])

    const enummanualResponse = (array) => {
        const details = array?.free_text_invoice_type?.map((i) => {
            return {
                label: i,
                value: i
            }
        })
        return details
    };

    const getCustomerList = (offset, limit, searchText, invoiceType, status, companyId, accountNum) => {
        const payload = {
            company_id: companyId ?? selectedCompany?.value,
            type: invoiceType ?? [],
            is_active: status ?? [true],
            search: searchText,
            account_no: accountNum?.length > 0 ? [accountNum] : [],
            offset: offset,
            limit: limit
        }
        NetworkCall(
            `${config.api_url}/free_text_invoice/get_all`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setFreeTextData({
                    ...freeTextData,
                    tableRow: response?.data?.data?.data ?? [],
                    searchText: searchText ?? ""
                })

            })
            .catch((error) => {
                // console.log(error)
            });
    };


    // Get Invoice 

    const getInvoiceList = (id, type, edit) => {
        const payload = {
            id: id
        }
        NetworkCall(
            `${config.api_url}/free_text_invoice/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                const result = constructData(res, id, type)
                if (edit) {
                    history.push({
                        pathname: Routes.createInvoiceFreeText,
                        state: { type: "edit", invoiceDataList: result },
                    })
                }
                else {
                    setFreeTextData({
                        ...freeTextData,
                        view: !freeTextData?.view,
                        invoiceDataList: result
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    const constructData = (res, id, type) => {
        let subtotal_amount = 0
        let taxable_amount = 0
        let taxes = 0
        let tableRow = res?.data?.data?.payments?.map((data) => {
            subtotal_amount = subtotal_amount + data?.subtotal_amount
            taxable_amount = taxable_amount + data?.taxable_amount
            taxes = taxes + data?.taxes
            return {
                id: data?.id,
                rowid: uuid.v4(),
                component: data?.pricing_components?.label ?? "",
                desc: data?.description,
                qty: data?.quantity,
                amount: data?.amount,
                discount: data?.discount,
                taxableAmount: data?.taxable_amount,
                taxGroup: data?.vat_group?.label,
                taxes: data?.taxes,
                subtotal: data?.subtotal_amount,
                delete: type === "view" ? false : true,
                path: ["desc", "qty", "amount", "discount"],
                field: {
                    desc: "text",
                    qty: "number",
                    amount: "number",
                    discount: "number"
                }
            }
        })
        let vatGroup = res?.data?.data?.payments[0]?.vat_group?.vat_item?.map((d) => {
            return {
                group_name: d?.label,
                rate: d?.value
            }
        })
        const invoiceDataList = {
            id: id,
            invoiceType: res?.data?.data?.type,
            description: res?.data?.data?.description,
            selectedAccountDetails: {
                id: res?.data?.data?.account?.id,
                name: res?.data?.data?.account?.label,
                account_no: res?.data?.data?.account?.value,
                mobile_no_country_code: res?.data?.data?.account?.mobile_no_country_code,
                mobile_no: res?.data?.data?.account?.mobile_no,
                email_id: res?.data?.data?.account?.email_id
            },
            searchText: "",
            due_date: res?.data?.data?.due_date,
            showCustomerList: false,
            accountType: false,
            showRemark: true,
            selectedType: "",
            showVendorList: false,
            step1: true,
            step2: true,
            step3: true,
            agreementNo: {
                label: res?.data?.data?.agreement?.label,
                value: res?.data?.data?.agreement?.value
            },
            invoiceNo: {
                label: res?.data?.data?.invoice_type,
                value: res?.data?.data?.invoice_type,
            },
            taxGroup: {
                label: res?.data?.data?.payments[0]?.vat_group?.label,
                value: res?.data?.data?.payments[0]?.vat_group?.value,
                vat_group_item: vatGroup
            },
            selectedunitDetail: {
                id: res?.data?.data?.units[0]?.id,
                unit: {
                    id: res?.data?.data?.units[0]?.unit_id,
                    name: res?.data?.data?.units[0]?.unit?.name,
                    unit_no: res?.data?.data?.units[0]?.unit?.unit_no,
                    unit_type: res?.data?.data?.units[0]?.type?.label,
                    unit_category: res?.data?.data?.units[0]?.category?.label,
                    unit_type_master: {
                        name: res?.data?.data?.units[0]?.type?.label,
                        id: res?.data?.data?.units[0]?.type?.label
                    },
                    property: res?.data?.data?.units[0]?.property,
                    address: res?.data?.data?.units[0]?.address
                }
            },
            unitPopup: false,
            pricingPopup: false,
            tableRowData: tableRow,
            totalTaxableAmount: taxable_amount,
            totalTaxes: taxes,
            totalAmount: subtotal_amount,
            propertyUnitDetail: [
                {
                    title: t("PropertyUnitDetails"),
                    content: res?.data?.data?.units[0]?.unit?.unit_no + ", " + res?.data?.data?.units[0]?.unit?.label,
                    editIcon: type === "view" ? false : true
                },
                {
                    title: t("UnitID"),
                    content: res?.data?.data?.units[0]?.unit?.unit_no ?? "-"
                },
                {
                    title: t("UnitCategory"),
                    content: res?.data?.data?.units[0]?.unit?.category?.lable ?? "-"
                },
                {
                    title: t("UnitType"),
                    content: res?.data?.data?.units[0]?.unit?.type?.label ?? "-"
                },
                {
                    title: t("Location"),
                    content: res?.data?.data?.units[0]?.unit?.address?.latitude + ", " + res?.data?.data?.units[0]?.unit?.address?.longitude
                }
            ],
            invoiceNumber: res?.data?.data?.invoice_no ?? "-",
            invoiceAmount: res?.data?.data?.total_amount_due ?? "-",
            periodTo: res?.data?.data?.period_to ?? "-",
            periodFrom: res?.data?.data?.period_from ?? "-"
        }
        return invoiceDataList
    }



    //   company switcher
    const handleCompanyChange = (e) => {
        setSelectedCompany(e);
        getCustomerList(0, 10, "", [], [], e?.value)

    };

    // Function to handle page limit in table
    const handleChangeLimit = (e) => {
        setFreeTextData({ ...freeTextData, limit: e })
        setFreeTextData({ ...freeTextData, page: 1 })
        getCustomerList(0, freeTextData?.limit, "", [], [], null)
    }

    // Function to handle pagination in table
    const handlePagination = (e) => {
        setFreeTextData({ ...freeTextData, page: 1 })
        let offset = (e - 1) * freeTextData?.limit
        getCustomerList(offset, freeTextData?.limit, "", [], [], null)
    }

    //apply filter
    const onApplyFilter = (value) => {
        const constructedInvoiceType = value?.invoiceType?.map((data) => {
            return data?.value
        })
        setFilterData(value)
        getCustomerList(0, 10, "", constructedInvoiceType, value?.status, null, value?.accountNo?.label)
    }
    //Table handle row

    const handleTableIcon = (type, data) => {
        if (type === "view") {
            getInvoiceList(data?.id, type)
            setFreeTextData({ ...freeTextData, viewData: data })
        }
        else if (type === "edit") {
            getInvoiceList(data?.id, type, true)
        }
        else if (type === "publish_invoice") {
            publishInvoice(data?.id)
        }
    }

    const publishInvoice = (id) => {
        const payload = {
            id: id
        }
        NetworkCall(
            `${config.api_url}/free_text_invoice/published_to`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                updateProspect(id)
            })
            .catch((error) => {
                console.log(error)
            });

    }

    const updateProspect = (id) => {
        const payload = { status: "Published" }
        client
            .mutate({
                mutation: UPDATE_STATUS,
                variables: {
                    id: id,
                    payload: payload,
                },
            })
            .then((rs) => {
                setPublish(!publish)
                getCustomerList(0, 10, "", [], [], selectedCompany?.value)
            })
            .catch((er) => {
                console.log(er);
            });
    };



    const editDetailFromView = () => {
        history.push({
            pathname: Routes.createInvoiceFreeText,
            state: { type: "edit", invoiceDataList: freeTextData?.invoiceDataList },
        })
    }

    const closePublish = () => {
        setFreeTextData(!publish)
    }

    const handleSearch = (data) => {
        setFreeTextData({ ...freeTextData, searchText: data })
        debounce(() => getCustomerList(0, 10, data, [], [], null), 800)
    }

    const loadAccountNo = async (search, array, handleLoading) => {
        const payload = {
            company_id: selectedCompany?.value,
            type: [],
            offset: 0,
            limit: 100,
            search: search ?? ""
        };
        let result = NetworkCall(
            `${config.api_url}/account/get_all`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            handleLoading();
            let optionss = res?.data?.data?.list?.map((val) => {
                return {
                    value: val?.id,
                    label: val?.account_no
                }
            })
            let array = optionss
            return {
                options: [...array],
                hasMore: false
            }
        }).catch((err) => {
            handleLoading();
            console.log(err)
            return {
                options: [],
                hasMore: false
            }
        })
        return result
    }

    const render = () => {
        return (
            <Box>
                <Subheader
                    title={t("FreeTextInvoicing")}
                    hideBackButton
                    select={true}
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        handleCompanyChange(e);
                    }}
                />

                <CustomPaper>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={4}>
                            {/*search field*/}
                            <SearchFilter value={freeTextData?.searchText}
                                placeholder={t("SearchAccount")}
                                handleChange={(value) => handleSearch(value)} />
                        </Grid>
                        <Grid item xs={8}>
                            {/*Add Btn*/}
                            <Box display={"flex"} justifyContent={"end"}>
                                <Stack direction="row" spacing={2}
                                    divider={<Divider orientation="vertical" flexItem />}>
                                    <Box onClick={() => setFilterDrawer(!filterDrawer)}>
                                        <FilterIcon />
                                    </Box>
                                    {permission?.create &&
                                        <Button variant="contained" className={classes.createBtn}
                                            onClick={() => setFreeTextData({ ...freeTextData, createPopup: !freeTextData?.createPopup })}>{t("Create")}
                                        </Button>
                                    }
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>

                    <TableWithPagination
                        heading={Heading}
                        rows={freeTextData?.tableRow}
                        path={FreeTextInvoiceTablePath}
                        showpagination={true}
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        handleIcon={handleTableIcon}
                        onClick={() => false}
                        tableType="no-side"
                        dataType={[
                            { type: ["more_free_text_invoice"], name: "icon" },
                            { type: ["text"], name: "invoice_no" },
                            { type: ["text"], name: "account_no" },
                            { type: ["text"], name: "type" },
                            { type: ["text"], name: "total_amount_due" },
                            { type: ["text"], name: "created_by" },
                            { type: ["text"], name: "description" },
                        ]}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={freeTextData?.tableRow?.length}
                        page={freeTextData?.page}
                        limit={freeTextData?.limit}
                        height={'calc(100vh - 300px)'}
                        view={permission?.read}
                        edit={permission?.update}
                        delete={false}
                        publish_invoice={true}
                    />
                </CustomPaper>

                <AlertDialog open={freeTextData?.createPopup}
                    onClose={() => setFreeTextData({ ...freeTextData, createPopup: !freeTextData?.createPopup })}
                    header={t("CreateFreeText")}
                    component={<CreatePopup data={createFreeText} />}
                    isNormal
                />

                <AlertDialog open={freeTextData?.view}
                    onClose={() => setFreeTextData({ ...freeTextData, view: !freeTextData?.view })}
                    header={t("View")}
                    component={
                        <Box p={2}>
                            <StepsContent type="view" stateData={freeTextData?.invoiceDataList}
                                editDetailFromView={editDetailFromView} publishInvoice={() => publishInvoice}
                                viewData={freeTextData?.viewData} selectedCompany={selectedCompany} />
                        </Box>}
                    md
                />

                <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(!filterDrawer)}
                    onApply={(value) => onApplyFilter(value)}
                    components={[
                        {
                            component: "select",
                            value: filterData?.invoiceType,
                            options: [],
                            isMulti: true,
                            label: t("InvoiceType"),
                            state_name: "invoiceType",
                            loadOptions: (search, array, handleLoading) => loadOptionsApis(
                                "/enum",
                                { "enumName": [enum_types.free_text_invoice_type] },
                                search,
                                array,
                                handleLoading,
                                "data",
                                {},
                                enummanualResponse
                            ),
                            debounceTimeout: 800,
                            isPaginate: true,
                        },
                        {
                            component: "select",
                            value: filterData?.accountNo,
                            options: [],
                            isMulti: false,
                            label: t("AccountNo"),
                            state_name: "accountNo",
                            loadOptions: (search, array, handleLoading) => loadAccountNo(
                                search, array, handleLoading
                            ),
                            debounceTimeout: 800,
                            isPaginate: true,
                        },
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: StatusOptionList,
                            isMulti: true,
                            state_name: "status",
                            label: t("Status"),
                        },
                    ]} />

                <Dialog
                    open={publish}
                    onClose={closePublish}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.publishDialog}
                >
                    <DialogContent sx={{padding: 0}}>
                        <DialogContentText sx={{ background: "transparent" }}>
                            <div style={{
                                paddingRight: "60px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                backgroundImage: `url(${"images/success.svg"})`,
                                backgroundRepeat: "no-repeat",
                                height: 250,
                                width: 600
                            }}>
                                <Stack spacing={2} alignItems={"center"}>
                                    <Typography className={classes.publishPopupTitle}>{t("InvoicePublishedSuccessfully")}</Typography>
                                    <Box  className={classes.publishPopupBtn}
                                    onClick={closePublish}>
                                        <Typography className={classes.publishbtnText}>{t("Okay")}</Typography>
                                    </Box>
                                </Stack>

                            </div>

                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Box>
        )
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )
}

export default withNamespaces("freeTextInvoice")(FreeTextInvoice); 