import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import CalendarPicker from '@mui/lab/CalendarPicker';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Divider, Popover, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Regular, SemiBold } from '../../utils';
const styles = makeStyles((theme) => ({
    placeholder: {
        fontFamily: Regular,
        fontSize: 14,
        whiteSpace: "nowrap",
        color: "#98A0AC"
    },
    // div: {
    //     min: "300px"
    // },
    title: {
        fontFamily: SemiBold,
        fontSize: 12,
        color: "#4E5A6B",
        marginBottom: "8px"
    },
    type: {
        fontFamily: SemiBold,
        fontSize: 12,
        color: "#4E5A6B",
        backgroundColor: "#F2F4F7",
        borderRadius: theme.palette.borderRadius,
        padding: "4px 8px",
        textAlign: "center",
        marginRight: "4px",
        marginLeft: "4px",
        cursor: "pointer"

    },
    textField: {
        width: "70px",
        padding: "4px",
        [`& fieldset`]: {
            borderRadius: theme.palette.borderRadius,
            height: (props) => (props.multiline ? "unset" : 32),
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #5078E1",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: "11px 14px",
        },
    },
    dialog: {
        "& .MuiPopover-paper": {
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 8px 24px #0717411F"
        }
    },
    typea: {
        fontFamily: SemiBold,
        fontSize: 12,

        borderRadius: theme.palette.borderRadius,
        padding: "4px 8px",
        textAlign: "center",
        marginRight: "4px",
        marginLeft: "4px",
        cursor: "pointer",
        backgroundColor: "#5078E1",
        color: "white"
    },
    value: {
        fontFamily: SemiBold,
        fontSize: 14,
        color: "#091B29",
    },
}));
export const SinglePopoverDatePicker = ({ startIcon = "",
    placeholder = "",
    updateState = () => false,
    handover = "",
}) => {
    const classes = styles()
    const [anchorEl, setAnchorEl] = React.useState(null);

    const onChange = (dates) => {
        updateState("handover", dates)
        setAnchorEl(null)
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Box sx={{ cursor: "pointer" }} display="flex" alignItems="center" height="45px" onClick={handleClick}>
                <Box marginLeft="8px" marginTop="6px">
                    <img src={startIcon} alt="" />
                </Box>
                <Box flexGrow={1} marginLeft="4px">
                    {
                        handover ?
                            <>
                                <Typography className={classes.value}>{moment(handover)
                                    .tz(moment.tz.guess())
                                    .format("DD MMM YY")}</Typography>
                            </>
                            : <Typography className={classes.placeholder}>{placeholder}</Typography>
                    }
                </Box>
                <Box marginTop="4px" marginRight="8px">
                    <ArrowDropDownIcon style={{ fontSize: "26px", color: "#98A0AC" }} />
                </Box>

            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className={classes.dialog}
            >
                <div className={classes.div}>
                    <Box p={1.4}>


                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={2}
                        >
                            <div>
                                <Typography className={classes.title}>Handover Date</Typography>

                                <DatePicker
                                    selected={handover}
                                    onChange={onChange}
                                    selectsDisabledDaysInRange
                                    inline
                                //showMonthDropdown
                                />
                            </div>


                        </Stack>



                    </Box>


                </div>
            </Popover>
        </div>
    )
}