import { Box, Paper } from "@mui/material";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { moveoutHeading, moveOutReqTypePath, moveoutType, StatusOptionList } from "../.././utils/moveoutInspection";
import { FilterGenerator, LoadingSection, Subheader, TableWithPagination, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";
import MoveOutHead from './moveOutHead';
import { useStylesInspection } from "./style";

const MoveoutInspection = ({ t }) => {

    const classes = useStylesInspection()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [searchText, setSearchText] = useState("")
    const [companyList, setCompanyList] = useState("")
    const [selectedCompany, setSelectedCompany] = useState("")
    const [permissions, setPermission] = React.useState({})
    const [tableData, setTableData] = useState([])
    const [totalRows, setTotalRows] = useState([])
    const debounce = UseDebounce()
    const history = useHistory()
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext)
    const auth = React.useContext(AuthContext)
    const [loading, setLoading] = React.useState(true)
    const [filterData, setFilterData] = React.useState({
        status: null,
    });
    const [drawer, setDrawer] = useState(false)
    const moveoutHeader = moveoutHeading(t);
    //get tableData 
    const getAllAgreementRequestList = (company_id, offset = 0, limit = 10, searchText, filterData) => {

        const payload = {
            company_id: company_id,
            search: searchText,
            status: filterData?.status,
            offset: offset,
            limit: limit,
            type: "move-out"
        }

        NetworkCall(
            `${config.api_url}/agreement_inspection/list_request`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                // const result = response?.data?.data?.map((val) => {
                //     return {
                //         ...val,
                //         property_name: `${val?.property?.[0]?.name}`
                //     }
                // })
                setTableData(response?.data?.data)
                setTotalRows(response?.data?.count)
                setLoading(false)

            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "data not found",
                });
                setLoading(false)

            })
    }

    //initial load
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        const perm = getRoutePermissionNew(auth);
        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
                getAllAgreementRequestList(company?.selected?.value, 0, limit, "")
            }
        }
        // eslint-disable-next-line
    }, [auth])
    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getAllAgreementRequestList(selectedCompany?.value, 0, 10, e)
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getAllAgreementRequestList(selectedCompany?.value, offset, limit, "", filterData)
    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getAllAgreementRequestList(selectedCompany?.value, 0, limit, "", filterData)

    }
    //company handle change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getAllAgreementRequestList(value?.value, 0, 10, "")
    }
    //To filter data based on status
    const handleFilter = (data) => {
        getAllAgreementRequestList(selectedCompany?.value, 0, limit, "", data)
        setFilterData(data)
    }
    const handleIcon = (type, data) => {
        history.push({
            pathname: Routes?.moveOutInspectionDetails,
            state: {
                company: selectedCompany,
                id: data?.id,
                title: data?.reference_id,
                type: data?.request_type,
                agreement_id: data?.agreement_id
            }
        })
    }

    const render = () => {
        return (
            <Box>
                <Subheader title={t("MoveOutInspectionRequest")} hideBackButton={true} select value={selectedCompany} options={companyList} onchange={(e) => {
                    handleCompanyChange(e)
                }} />
                {
                    loading ?
                        <LoadingSection top="20vh" message={"Fetching Details"} />
                        :
                        <Paper className={classes.root}>
                            <MoveOutHead handleSearch={handleSearch} searchText={searchText} openFilter={() => setDrawer(true)} />
                            <TableWithPagination
                                heading={moveoutHeader}
                                rows={tableData}
                                dataType={moveoutType}
                                path={moveOutReqTypePath}
                                handleIcon={handleIcon}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                showpagination
                                tableType="no-side"
                                totalRowsCount={totalRows}
                                page={page}
                                height={`calc(100vh - 300px)`}
                                limit={limit}
                                className={classes.moveoutTable}

                            />
                            {/*filter component */}
                            {drawer && (
                                <FilterGenerator
                                    open={drawer}
                                    onClose={() => setDrawer(false)}
                                    components={[
                                        {
                                            component: "toggleButton",
                                            value: filterData?.status,
                                            state_name: "status",
                                            label: "Status",
                                            options: StatusOptionList,
                                            isMulti: true
                                        },


                                    ]}
                                    onApply={(value) => handleFilter(value)}
                                />
                            )}
                        </Paper>
                }
            </Box>
        )
    }
    return (

        <Box>
            {accessCheckRender(render, permissions)}
        </Box>
    )
}
export default withNamespaces("moveoutInspection")(MoveoutInspection);