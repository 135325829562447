import { useApolloClient } from "@apollo/client";
import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AlertDialog, Stepper, Subheader } from "../../components";
import { AuthContext } from "../../contexts";
import { CreateOppertunityContext } from "../../contexts/createOppertunityContext";
import {
  GETCOMPANYDETAILS,
  getLeadDetails
} from "../../graphql/quotationQueries";
import { Routes } from "../../router/routes";
import { accessCheckRender, getRoutePermissionNew } from "../../utils";
import {
  returnConvertOppertunityEditPayload, returnOppertunityEditPayload
} from "../../utils/createQuotationUtils";
import { RevenueTypeForm } from "./component";
import { AddContact, LeadNew, LeadPreview } from "./steps";
import { useStyles } from "./styles";

export const CreateOpportunity = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();
  const { state } = useLocation();
  const [open, setOpen] = React.useState(state?.id ? false : true);
  const [edit, setEdit] = React.useState(false)
  const auth = React.useContext(AuthContext)
  const [permission, setPermission] = React.useState({})

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        if (state) {
          if (state?.id) {
            getOppertunityDeatils();
          }
          if (state?.main?.is_convert) {
            console.log(state?.main?.id , "llll")
            let convertData = returnConvertOppertunityEditPayload(state?.main?.data, state?.main?.company , "" , state?.main?.id);
            console.log(convertData , 'convertData')
            setDataNew(convertData);
          }
        } else {
          history.push(Routes.leads);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //context state
  const { step, previous, next, setDataNew, setStep, dataNew, updateStateNew, setSelectedAccount, setShow1, disabledButton } =
    React.useContext(CreateOppertunityContext);
  //new lead
  const stepsNew = [
    {
      label: `${edit ? "Edit Prospective" : "Add Prospective"}`,
      step: 1,
      value: step,
    },
    {
      label: "Opportunity Details",
      step: 2,
      value: step,
    },
    {
      label: `${edit ? "Preview and Edit Lead" : "Preview and Create Lead"}`,
      step: 3,
      value: step,
    },
  ];
  //edit lead
  const editstepsNew = [
    {
      label: "Opportunity Details",
      step: 2,
      value: step,
      stepValue: "1",
    },
    {
      label: `${edit ? "Preview and Edit Lead" : "Preview and Create Lead"}`,
      step: 3,
      value: step,
      stepValue: "2",
    },
  ];
  //get company details
  const getCompanyDetails = (value) => {
    client
      .query({
        query: GETCOMPANYDETAILS,
        fetchPolicy: "network-only",
        variables: {
          id: state?.value || state?.main?.company?.value,
        },
      })
      .then((res) => {
        setDataNew({
          ...dataNew,
          custom_date: res?.data?.company_master[0]?.billing_day,
          bill_cycle_method: res?.data?.company_master[0]?.billing_cycle,
          bill_cycle_date:
            res?.data?.company_master[0]?.billing_cycle_date ===
              "As per the Agreement Date"
              ? res?.data?.company_master[0]?.billing_cycle_date
              : res?.data?.company_master[0]?.billing_day,
          payment_period:
            dataNew?.revenue_type === "sale"
              ? ""
              : {
                value: res?.data?.company_master[0]?.payment_period,
                label: res?.data?.company_master[0]?.payment_period,
              },
          required_area: {
            min: "",
            max: "",
            select: {
              value: res?.data?.company_master[0]?.uom,
              label: res?.data?.company_master[0]?.uom,
            },
          },
          budget: {
            min: "",
            max: "",
            select: {
              value: res?.data?.company_master[0]?.currencyByID?.id,
              label: res?.data?.company_master[0]?.currencyByID?.symbol,
            },
          },
          company: state?.value || state?.main?.company?.value,
          grace_peroid: res?.data?.company_master[0].grace_period !== null ? JSON.stringify(res?.data?.company_master[0].grace_period) : null,
        });
      })
      .catch((err) => console.log(err));
  };
  //get oppertunity details
  const getOppertunityDeatils = () => {
    client
      .query({
        query: getLeadDetails(),
        fetchPolicy: "network-only",
        variables: {
          id: state?.id,
        },
      })
      .then((res) => {
        let data = returnOppertunityEditPayload(res?.data?.lead[0]);
        setDataNew(data);
        setSelectedAccount(data?.contact_account)
        setShow1(true)
        setEdit(true)
        setStep(2)

      })
      .catch((err) => console.log(err));
  };

  const newLeadComponent = {
    1: <AddContact />,
    2: <LeadNew />,
    3: <Box className={classes.content}><LeadPreview /></Box>,
  };

  const render = () => {
    return <>
      {/*sub header*/}
      <Subheader
        goBack={() => history.goBack()}
        title={`${edit ? "Edit Opportunity" : "Create Opportunity"}`}
      />
      <Stepper step={step} steps={edit ? editstepsNew : stepsNew} />
      {/*conditional component*/}
      {newLeadComponent[step]}
      {/*btn container */}
      <Grid container className={classes.btnroot} spacing={1}>
        <Grid item xs={6}>
          <Button
            className={classes.previousbtn}
            variant="contained"
            onClick={previous}
            disabled={edit ? step === 2 : step === 1}
          >
            Previous
          </Button>
        </Grid>
        <Grid item xs={6} textAlign={"right"}>
          <Button
            className={classes.previousbtn}
            variant="contained"
            onClick={() => history.goBack(-1)}
          >
            Cancel
          </Button>

          {((state?.id && permission?.update) || (!Boolean(state?.id) && permission?.create)) &&
            <Button
              className={classes.submitbtn}
              variant="contained"
              disabled={disabledButton}
              onClick={next}
            >
              Next
            </Button>}
        </Grid>
      </Grid>

      <AlertDialog
        header={"Select Type"}
        open={open}
        close={true}
        onClose={() => history.goBack()}
        isNormal={true}
        component={
          <RevenueTypeForm
            updateState={updateStateNew}
            data={dataNew}
            onClose={() => {
              setOpen(false);
              getCompanyDetails(state?.main ? true : false);
            }}
          />
        }
      />
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}</>
  );
};
