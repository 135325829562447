import { Avatar, Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import { ResourceMasterComponentStyles } from "./styles";

export const UserCard = ({ list = "", clickCheck = () => false, value = {}, details = false, onChange = () => false }) => {

    const classes = ResourceMasterComponentStyles()
    return (
        <Box onClick={(e) => {
            onChange(list)
        }}
            className={details ? classes.userDetailsCard : classes.userCard} >
            <Box display={"flex"}>
                <Avatar src={list.image_url} />
                <Box className={`${classes.userDetails}`}>
                    <Typography className={`${classes.userName}`}>{list?.name}</Typography>
                    <Box display={"flex"}>
                        <Typography className={`${classes.mail}`}>{list?.mobile_no} {list?.mobile_no_country_code}</Typography>
                        <div className={`${classes.dot}`}></div>
                        <Typography className={`${classes.mail}`}>{list?.email_id}</Typography>

                    </Box>
                </Box>
            </Box>
            <Box>
                {
                    !details &&
                    <Checkbox
                        checked={value === list?.id}
                        onChange={(e) => {
                            clickCheck(list?.id)
                        }}
                    />
                }

            </Box>

        </Box>
    )
}