import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { propertyCardStyles } from "./style";
import {timeZoneConverter} from "../../utils"
export const PropertyCard = (props) => {
    const classes = propertyCardStyles()
    const handleClick = (event) => {
        props?.onClick(event.currentTarget, props?.data)
    };

    return (
        <div>
            <Grid container className={classes.root} onClick={handleClick}>
                <Grid item xs={3}>
                    <Box className={classes.imgDiv}>
                        <img src={props?.data?.logo ?? "/images/imagesproperty.svg"} alt="" className={classes.img} />
                        <Typography className={classes.type}>{props?.data?.type}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box marginLeft="12px">
                        <Typography className={classes.title}>{props?.data?.name}</Typography>
                        <Typography className={classes.address}>
                            {props?.data?.company?.name ?? "No Company"}
                        </Typography>
                        <Box display="flex" alignItems="center">
                            <Box>
                                <Stack direction="row" alignItems="flex-end">
                                    <img src="/images/build.svg" alt="" />
                                    <Typography className={classes.build}>
                                        {
                                            props?.data?.year_built &&
                                            <>
                                                {timeZoneConverter(props?.data?.year_built,"YYYY")
                                                   }
                                            </>
                                        }

                                        <span className={classes.build1}>&nbsp;Build</span></Typography>
                                </Stack>
                            </Box>
                            <Box marginLeft="12px">
                                <Stack direction="row" alignItems="flex-end">
                                    <img src="/images/locs.svg" alt="" />
                                    <Typography className={classes.build}>{props?.data?.address?.district}</Typography>
                                </Stack>
                            </Box>
                        </Box>
                        <Typography className={classes.availabe}>{props?.data?.units?.length}&nbsp;Units Available</Typography>
                    </Box>
                </Grid>
            </Grid>

        </div>
    )
}