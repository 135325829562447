import { Box, Divider, Stack, Typography, Grid } from '@mui/material'
import React from 'react'
import { addSecondFormat } from '../../utils'
import { AssetView } from '../resourceMaster/components/assetView'
import { ViewCourtStyle } from './style'

export const ViewCourt = (
    {
        type = "",
        selectedAmenityView = {},
    }) => {
    const classes = ViewCourtStyle()
    return (
        <Stack spacing={2} p={1}>
            <Box>
                <AssetView asset={selectedAmenityView?.assets} />
            </Box>
            {
                type === "court" &&
                <Box m={2}>
                    <Stack spacing={2} p={2}>
                        <Stack justifyContent={"space-between"} direction="row" alignItems={"center"}>
                            <Stack spacing={1}>
                                <Typography className={classes.court_name}>{selectedAmenityView?.label}</Typography>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Typography className={classes.subname}>{selectedAmenityView?.category}</Typography>
                                </Stack>
                            </Stack>
                            <Box>
                                <Typography className={classes.content}>
                                    {selectedAmenityView?.rate + selectedAmenityView?.symbol + "/" + selectedAmenityView?.period}
                                </Typography>
                            </Box>
                        </Stack>
                        <Divider />
                        <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                            <Typography className={classes.title}>Available Days</Typography>
                            <Stack direction={"row"} spacing={2}>
                                {selectedAmenityView?.applicable_days?.map((i) => {
                                    return <Typography className={classes.content}>{i}</Typography>
                                })}
                            </Stack>
                        </Stack>
                        <Divider />
                        <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                            <Typography className={classes.title}>Slot Sessions</Typography>
                            <Box float="right">
                                <Grid container spacing={1}>
                                    {selectedAmenityView?.selected_slots?.map((item, index, array) => {
                                        return ((index !== array.length - 1) && <Grid item md={6}>
                                            <Typography className={classes.content} textAlign="right">
                                                {addSecondFormat(item?.[0], 0, "hh:mm A") + " - " + addSecondFormat(array?.[index + 1]?.[0], 0, "hh:mm A")}
                                            </Typography>
                                        </Grid>)
                                    })}
                                </Grid>
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            }
            {
                type === "amenity" &&
                <Box m={2}>
                    <Stack>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <Typography className={classes.court_title}>Amenity Eligible For</Typography>
                                <Typography className={classes.content}>Units</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography className={classes.court_title}>Amenity Category</Typography>
                                <Typography className={classes.content}>Sports</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography className={classes.court_title}>Amenity Name</Typography>
                                <Typography className={classes.content}>Tennis</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography className={classes.court_title}>Status</Typography>
                                <Typography className={classes.content}>Active</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography className={classes.court_title}>Booking Required</Typography>
                                <Typography className={classes.content}>Yes</Typography>
                            </Grid>
                            <Grid item md={12}>
                                <Typography className={classes.court_title}>Description</Typography>
                                <Typography className={classes.content}>Tennis is a racket sport that is played either individually against a single opponent or between two teams of two players each</Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>}
        </Stack>
    )
}