import { CHECK_PRIMARY_COMPONENT } from "../../graphql/queries";

export const RevenueTypeList = [
    { value: "Lease", label: "Lease" },
    { value: "Sale", label: "Sale" },
    { value: "Maintenance", label: "Maintenance" }
];

export const FormQuestionSwitchList1 = [
    { question: "Enable Tax?", state: "isTaxable" },
    { question: "Enable Price Override Functionality?", state: "isOverride" },
    { question: "Enable Reservation Amount Calculation?", state: "isReserve" },
    { question: "Enable Auto Security Deposit Calculation?", state: "isSecurity" }
];

export const FormQuestionSwitchList2 = [
    { question: "Enable Tax?", state: "isTaxable" },
];

export const ChargeableList = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
];

export const ValueBasisList1 = [
    { value: "Amount", label: "Fixed Amount" },
    { value: "per UOM", label: "UOM" },
    { value: "percentage", label: "Percentage" }
];

export const ValueBasisList2 = [
    ValueBasisList1[0],
    { value: "Rental Value", label: "Rental Value" },
    ValueBasisList1[2]
];

export const PaymentPeriodList = [
    { value: "Monthly", label: "Monthly" },
    { value: "Daily", label: "Daily" },
    { value: "Total", label: "Total" }
];

export const AddQuestions = [
    {
        id: 1,
        serial: "01",
        question: "Primary",
        category: "Primary Pricing Component",
        categoryType: "Primary",
        info: "Base rent or monthly rental amount. you can have only one primary pricing component per property.",
        primaryColor: "#B3776D",
        secondaryColor: "#FEEAEA80",
        isPrimary: true,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: false,
    },
    {
        id: 2,
        serial: "02",
        question: "Secondary",
        category: "Secondary Components",
        categoryType: "Secondary",
        info: "Identify and configure pricing for maintenance and other services",
        primaryColor: "#896DB3",
        secondaryColor: "#EDE4FE80",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: true,
    },
    {
        id: 3,
        serial: "03",
        question: "One Time Charges",
        category: "One Time Charges",
        categoryType: "One Time",
        info: "Configure pricing which has to be calculated as one time charges",
        primaryColor: "#6DAFB3",
        secondaryColor: "#DBF0F180",
        isPrimary: false,
        isOneTime: true,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[2],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: true,
    },
    {
        id: 4,
        serial: "04",
        question: "Refundables",
        category: "Refundables Price Component",
        categoryType: "Refundable",
        info: "Configure pricing which has to be calculated as refundable amount",
        primaryColor: "#6D80B3",
        secondaryColor: "#E4EDFF80",
        isPrimary: false,
        isOneTime: false,
        isRefundable: true,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[2],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: false,
    },
    {
        id: 5,
        serial: "05",
        question: "Inventory Items",
        category: "Inventory Items Component",
        categoryType: "Item Based",
        info: "Configure pricing for items like services, materials etc.",
        primaryColor: "#B3A16D",
        secondaryColor: "#FFFAD880",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: true,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: false,
    },
    {
        id: 6,
        serial: "06",
        question: "Parking Slot",
        category: "Parking Slot Component",
        categoryType: "Parking Slot",
        info: "Configure pricing for items that has to be calculated for parking slot amount.",
        primaryColor: "#B3776D",
        secondaryColor: "#FEEAEA80",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: true,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: true,
    },
];

export const FormQuestionSwitchList2Category = [
    AddQuestions[2].category, AddQuestions[3].category
]

export const findPrimaryComponent = async ({ client = "", unit_id = "" }) => {
    let is_primary_component = false;
    await client.query({
        query: CHECK_PRIMARY_COMPONENT,
        fetchPolicy: "network-only",
        variables: { unit_id }
    }).then((res) => {
        is_primary_component = res?.data?.count?.[0]?.count > 0
    }).catch((err) => { console.log(err) });
    return is_primary_component
}