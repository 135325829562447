import React from "react"
import { Subheader, UseDebounce, SearchFilter, TableWithPagination, FilterGenerator } from "../../components"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { useApolloClient } from '@apollo/client';
import { GET_UNIT_VACANCY_BY_PROPERTY_ID, UNIT_CATEGORY_MASTER } from "../../graphql/queries"
import { UnitVacancyDiv, CardTitle, Desc, FilterButton, Image, ProfileDetailsStack, ProfileImageInnerDiv, ProfileImageOuterDiv, Title, UserID } from "./components/styledComponents"
import { accessCheckRender, AlertProps, getRoutePermissionNew, LocalStorageKeys } from "../../utils"
import { Badge, Divider, Grid, Stack } from '@mui/material'
import FilterIMG from "../../assets/filter"
import { ActiveStatus, UnitHeading2, UnitPath2, UnitType2 } from "../../utils/unitVacancy/tableUtils"
import { useHistory, useLocation } from "react-router-dom"
import moment from "moment"
import ReactQuill from "react-quill";
import { useStyles } from "./style";
import ImageViewer from "react-simple-image-viewer";
import { UnitVacancyList } from "../unitVacancyAllList/components/unitVacancyList";
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";

export const UnitVacancyPropertyView = () => {

    // constants
    const classes = useStyles()
    const history = useHistory()
    const debounce = UseDebounce()
    const client = useApolloClient()
    const search = useLocation().search;
    const propertyID = new URLSearchParams(search).get("propertyID")
    const modules = { toolbar: false }

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)

    // useState
    const [property, setProperty] = React.useState({})
    const [list, setList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState({ status: [true] })
    const [fiterDrawer, setFilterDrawer] = React.useState(false)
    // const [enumValue, setEnumValue] = React.useState({ unit_category: [] })
    const [isViewerOpen, setIsViewerOpen] = React.useState(false)
    const [currentImage, setCurrentImage] = React.useState("")
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [dialogState, setDialogState] = React.useState({})
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                setPage(1)
                if (propertyID) { getList() }
            }
        }
        // eslint-disable-next-line
    }, [auth, propertyID, filterData,]);



    // Function to get Enum value
    // const getEnum = async () => {
    //     const result = await enumSelect([enum_types.unit_category])
    //     setEnumValue({ unit_category: result?.unit_category })
    // }

    // Function to get property list based on the input data
    const getList = (offset = 0, limit = 10, search = "") => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        let today = new Date()
        let from = moment(today).format("YYYY-MM-DD")
        today.setDate(today.getDate() + 1)
        let to = moment(today).format("YYYY-MM-DD")
        let unitCategory = filterData?.unit_category ?
            filterData?.unit_category?.value : ""
        client.query({
            query: GET_UNIT_VACANCY_BY_PROPERTY_ID(unitCategory),
            fetchPolicy: "network-only",
            variables: {
                clientID: localStorage.getItem(LocalStorageKeys.clinetID) ?? "",
                activeStatus: (!filterData?.status || filterData?.status?.length === 0) ?
                    [true, false] : filterData?.status, unitCategory, propertyID,
                from, to, search, offset, limit,
            }
        }).then((r) => {
            setProperty({ ...r?.data?.property?.[0] })
            setList({
                data: r?.data?.unit,
                totalRowsCount: r?.data?.count?.[0]?.count
            })
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch(() => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: "Some Thing Went Wrong"
            })
        })
    }

    // eslint-disable-next-line
    const Row = React.useCallback(list?.data?.map((_) => {
        let p
        try {
            p = {
                id: _?.id,
                unitName: _?.name ?? "-",
                unitCategory: _?.unit_category ?? "-",
                floorName: _?.floorByID?.name ?? "-",
                blockName: _?.blockByID?.name ?? "-",
                unitCurrentStatus: _?.status ?? "-",
                status: _?.is_active ? "Active" : "Inactive",
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: "Some Thing Went Wrong"
            })
        }
        return p
        // eslint-disable-next-line
    }), [list])

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in property list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getList(0, limit, e)
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        if (type === "view") {
            setDialogState({ ...data })
            setDialogOpen(true)
        }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getList(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getList(0, value, searchText)
    }

    //openImageViewer
    const openImageViewer = (image) => {
        setCurrentImage(image)
        setIsViewerOpen(true)
    }

    //closeImageViewer
    const closeImageViewer = () => {
        setCurrentImage(0)
        setIsViewerOpen(false)
    }

    const render = () => {
        return <>
            <Subheader goBack={() => history.goBack(-1)} title={"All Unit Vacancy Period"} />
            <ProfileDetailsStack
                direction={{ xs: "column", sm: "row" }}
                gap={"16px"}
                divider={<Divider variant="middle" flexItem orientation={"vertical"} />}>
                <ProfileImageOuterDiv>
                    <ProfileImageInnerDiv>
                        <Image
                            src={property?.logo ?? ""}
                            alt={"profile_img"}
                            onClick={() => property?.logo && openImageViewer([property?.logo])} />
                        <UserID userNo={property?.property_no}>
                            {property?.property_no}
                        </UserID>
                    </ProfileImageInnerDiv>
                </ProfileImageOuterDiv>
                <Grid container spacing={"16px"}>
                    <Grid item xs={12}>
                        <CardTitle>PROPERTY DETAILS</CardTitle>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Title>Company Name</Title>
                        <Desc>{property?.companyByID?.name ?? " - "}</Desc>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Title>Property Name</Title>
                        <Desc>{property?.name ?? " - "}</Desc>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Title>Property Type</Title>
                        <Desc>{property?.property_group_masterByID?.group_name ?? " - "}</Desc>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Title>Property Purpose</Title>
                        <Desc>{property?.property_purpose ?? " - "}</Desc>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Title>Total Units</Title>
                        <Desc>{property?.totalUnits?.[0]?.count ?? " - "}</Desc>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Title>Status</Title>
                        <Desc>{property?.is_active ? "Active" : "Inactive"}</Desc>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Title>Description</Title>
                        <ReactQuill
                            readOnly
                            theme="bubble"
                            value={property?.description ?? ""}
                            modules={modules}
                            className={classes.description}
                        />
                    </Grid>
                </Grid>
            </ProfileDetailsStack>
            <UnitVacancyDiv>
                <Grid container spacing={"8px"}>
                    <Grid item xs={4}>
                        <SearchFilter value={searchText} placeholder="Search"
                            handleChange={(value) => handleSearch(value)} />
                    </Grid>
                    <Grid item xs={8} alignSelf={"center"}>
                        <Stack direction="row" spacing={"16px"} justifyContent={"end"}>
                            <FilterButton onClick={() => setFilterDrawer(!fiterDrawer)}>
                                <Badge variant="dot" color="primary"
                                    invisible={!(filterData.status?.length > 0)}>
                                    <FilterIMG color="#091b29" />
                                </Badge>
                            </FilterButton>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <TableWithPagination
                            heading={UnitHeading2}
                            rows={Row}
                            path={UnitPath2}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            handleIcon={handleTableIcon}
                            onClick={() => console.log("")}
                            tableType="no-side"
                            dataType={UnitType2}
                            handlePagination={handleTablePagination}
                            handleChangeLimit={handleTablePageLimit}
                            totalRowsCount={list?.totalRowsCount}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 290px)'}
                            view={true}
                            edit={true}
                            delete={true} />
                    </Grid>
                </Grid>
                <UnitVacancyList
                    dialogState={dialogState}
                    setDialogState={setDialogState}
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                />
                <FilterGenerator open={fiterDrawer} onClose={() => setFilterDrawer(false)}
                    onApply={(value) => setFilterData(value)}
                    components={[
                        {
                            component: "select",
                            value: filterData?.unit_category,
                            options: [],
                            isMulti: false,
                            label: "Unit Category",
                            state_name: "unit_category",
                            loadOptions: (search, array, handleLoading) => loadOptions(
                                search, array, handleLoading,
                                UNIT_CATEGORY_MASTER, 'unit_category_master',
                                {},
                                { label: "label", value: "value" }
                              ),
                            debounceTimeout: 800,
                            isPaginate: true
                        },
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: ActiveStatus,
                            isMulti: true,
                            state_name: "status",
                            label: "Status",
                        },
                    ]} />
            </UnitVacancyDiv>
            {isViewerOpen && (<ImageViewer
                src={currentImage}
                currentIndex={0}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                className={classes.imageViewer}
                closeOnClickOutside={true} />)}
        </>
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div>
}