import { useApolloClient } from "@apollo/client";
import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import CityProperty from '../../assets/cityproperty';
import ContractProperty from '../../assets/contractproperty';
import ImagesProperty from '../../assets/imagesproperty';
import {
      PropertyTabs, Subheader
} from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import {
      PROPERTY_DETAILS_BY_POPERTY_ID
} from "../../graphql/queries";
import { Routes } from "../../router/routes";
import { accessCheckRender, AlertProps, getRoutePermissionNew } from "../../utils";
import { Assets } from './propertyView/assets';
import { BusinessDetailsViewing } from './propertyView/businessDetailsViewing';
import { OtherInfoViewing } from './propertyView/otherInfoViewing';
import { PropertyDetailsViewing } from './propertyView/propertydetailsviewing';
import { PropertyStylesParent } from "./style";


export const PropertyView = () => {
      const classes = PropertyStylesParent()
      const history = useHistory()
      const [data, setData] = React.useState([])
      const search = useLocation().search;
      const client = useApolloClient();
      const alert = React.useContext(AlertContext);
      const auth = React.useContext(AuthContext)
      const [value, setValue] = React.useState(1);
      const propertyId = new URLSearchParams(search).get("propertyID");
      const backdrop = React.useContext(BackdropContext);
      const [isViewerOpen, setIsViewerOpen] = React.useState(false);
      const [currentImage, setCurrentImage] = React.useState("");
      const [permission, setPermission] = React.useState({})

      // use effect to get permission
      React.useEffect(() => {
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                  setPermission(perm)
                  if (perm?.read) {
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: true,
                              message: "",
                        });
                        getPropertyById()
                  }
            }
            // eslint-disable-next-line
      }, [auth]);

      //openImageViewer
      const openImageViewer = React.useCallback((index) => {
            setCurrentImage(index);
            setIsViewerOpen(true);
      }, []);
      //closeImageViewer
      const closeImageViewer = () => {
            setCurrentImage(0);
            setIsViewerOpen(false);
      };
      const handleChange = (event, newValue) => {
            setValue(newValue);
      };
      const tabTittle = [
            {
                  label: 'Property Details',
                  imgSelect: <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
                  value: 1,
                  className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                  label: 'Attachments',
                  imgSelect: <ImagesProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
                  value: 2,
                  className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                  label: 'Other fields',
                  imgSelect: <ContractProperty color={value === 3 ? '#5078E1' : '#98A0AC'} />,
                  value: 3,
                  className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                  label: 'Related',
                  imgSelect: <ContractProperty color={value === 4 ? '#5078E1' : '#98A0AC'} />,
                  value: 4,
                  className: value === 4 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 4 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 4 ? classes.subtabtitle1 : classes.subtabtitle
            }
      ]

      const getPropertyById = () => {
            client.query({
                  query: PROPERTY_DETAILS_BY_POPERTY_ID,
                  fetchPolicy: 'network-only',
                  variables: {
                        propertyID: propertyId,
                  }
            }).then((res) => {
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
                  setData(res?.data)
            }).catch((err) => {
                  console.log(err)
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Something went wrong please try again",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                  });
            });
      };

      const goBack = () => {
            history.push(Routes.properties)
      }
      const handleedit = () => {
            history.push({
                  pathname: Routes.propertycreation,
                  state: {
                        main: {
                              company: data?.property?.[0]?.company,
                              teams: data?.property?.[0]?.team_property_access?.[0]?.teams,
                              companyID: data?.property?.[0]?.company?.id,
                              propertyID: propertyId,
                        },
                  },
            });
      }

      const render = () => {
            return <>
                  <Subheader title={data?.property?.[0]?.name} goBack={goBack} />
                  <PropertyTabs value={value} handleChange={handleChange}
                        isEdit={permission?.update}
                        handleedit={handleedit}
                        tab1={<PropertyDetailsViewing
                              data={data?.property?.[0]}
                              assets={data?.asset}
                              closeImageViewer={closeImageViewer}
                              openImageViewer={openImageViewer}
                              isViewerOpen={isViewerOpen}
                              currentImage={currentImage}
                        />}
                        tab2={<Assets asset={data?.property_assets} />}
                        tab3={<BusinessDetailsViewing data={data?.property?.[0]} />}
                        tab4={<OtherInfoViewing data={data} />}
                        tabTittle={tabTittle}
                        showBtns={true}
                  />
            </>
      }

      return (
            <div >
                  {accessCheckRender(render, permission)}
            </div >
      )
}