import React from "react";
import { withNavBars } from "../../HOCs";
import { Invoice } from "./invoice";

class InvoiceParent extends React.Component {
    render() {
        return <Invoice  {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(InvoiceParent, props);
