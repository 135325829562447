import DateAdapter from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "12px",
    color: theme.typography.color.tertiary,
  },
  datepicker: {
    border: "none",
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: theme.palette.borderRadius,

      padding: "0px",
      boxShadow: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px",
    },
  },
}));

export const DateTime = (props) => {
  const classes = useStyles(props);
  const getTitle = (props) => {
    return (
      <Typography variant="body1" className={classes.text} gutterBottom>
        {props.title}{" "}
        {props?.required && (
          <Typography color="error" variant="caption">
            &nbsp;*
          </Typography>
        )}
      </Typography>
    );
  };

  return (
    <>
      {getTitle(props)}
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Stack spacing={3}>
          <DatePicker
            disableFuture={props.disableFuture}
            disablePast={props.disablePast}
            size="small"
            className={classes.datepicker}
            label={props.label}
            openTo="year"
            views={["year", "month", "day"]}
            value={props?.value ?? null}
            onChange={(newValue) => {
              props.onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={props?.error ?? false}
                className={classes.textbox}
                placeholder={props.placeholder}
                size="small"
              />
            )}
          />
        </Stack>
      </LocalizationProvider>
      {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
    </>
  );
};
