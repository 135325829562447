import React from "react";
import { withNavBars } from "../../HOCs";
import { QuotationsList } from "./quotations";

class QuotationParent extends React.Component {
    render() {
        return <QuotationsList />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(QuotationParent, props);
