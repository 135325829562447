import { Box, Icon, Stack, Typography } from '@mui/material'
import React from 'react'
import { PricingComponentCardStyle } from './style'

export const PricingComponentCard = ({ data = {}, selectedList = {}, click = () => false }) => {
    const classes = PricingComponentCardStyle()
    return (
        <Box p={1} m={1} onClick={click} sx={{cursor:"pointer"}}>
            <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                <Stack>
                    <Typography className={selectedList?.id === data?.id ? classes.selectedTitle : classes.title}> {data?.name} </Typography>
                    <Typography className={selectedList?.id === data?.id ? classes.selectedContent : classes.content}>{data?.content}</Typography>
                </Stack>
                {selectedList?.id === data?.id && <Icon color='primary'>check_circle</Icon>}
            </Stack>
        </Box>
    )
}