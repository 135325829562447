import React from "react";
import { withNavBars } from "../../HOCs";
import {InspectionManagerDashboard} from "./dashboard";

class InspectionDashboardParent extends React.Component {
    render() {
        return <InspectionManagerDashboard {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(InspectionDashboardParent, props);