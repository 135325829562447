import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Aggreement, AgreementInvoices, KycDetails, LeadAgreement, ManagedInvoices } from "../../assets";
import { LoadingSection, PropertyTabs, Subheader } from "../../components";
import { AuthContext } from "../../contexts";
import { GET_AGREEMENT_DETAILS } from "../../graphql/agreementQurery";
import { accessCheckRender, getRoutePermissionNew } from "../../utils";
import { AgreementPreview, Insepection, Invoicesection, KYC, ManagedInvoice, TimeLine } from "./sections";
import { AggrementDetailsStyles } from "./styles";
import jwtDecode from 'jwt-decode';
import moment from "moment";
const AgreementDetails = ({ t }) => {
  const classes = AggrementDetailsStyles()
  const client = useApolloClient();
  const history = useHistory()
  const search = useLocation().search;
  const jwt = new URLSearchParams(search).get("agreement_id");
  const id = jwt ? jwtDecode(jwt)?.agreement_id : new URLSearchParams(search).get("id");
  const [value, setValue] = React.useState(1);
  const [details, setDetails] = React.useState({});
  const [loading, setLoading] = React.useState(true)
  const auth = React.useContext(AuthContext)
  const [permission, setPermission] = React.useState({})

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        getAgreementDetails()
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const tabTittle = [
    {
      label: t('agreementPreview'),
      imgSelect: <Aggreement color={value === 1 ? '#5078E1' : '#98A0AC'} />,
      value: 1,
      className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t('timelineTrack'),
      imgSelect: <LeadAgreement color={value === 2 ? '#5078E1' : '#98A0AC'} />,
      value: 2,
      className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t('kycDetails'),
      imgSelect: <KycDetails color={value === 3 ? '#5078E1' : '#98A0AC'} />,
      value: 3,
      className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t('managedInvoice'),
      imgSelect: <ManagedInvoices color={value === 4 ? '#5078E1' : '#98A0AC'} />,
      value: 4,
      className: value === 4 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 4 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 4 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t('invoice'),
      imgSelect: <ManagedInvoices color={value === 5 ? '#5078E1' : '#98A0AC'} />,
      value: 5,
      className: value === 5 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 5 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 5 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t('inspection'),
      imgSelect: <AgreementInvoices color={value === 6 ? '#5078E1' : '#98A0AC'} />,
      value: 6,
      className: value === 6 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 6 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 6 ? classes.subtabtitle1 : classes.subtabtitle
    }
  ]
  //get agreement details
  const getAgreementDetails = () => {
    client.query({
      query: GET_AGREEMENT_DETAILS,
      fetchPolicy: 'network-only',
      variables: {
        id: id,
        to: `${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`,
      }
    }).then((response) => {
      setDetails(response?.data?.agreement?.[0])
      setLoading(false)
    }).catch((err) => {
      console.log(err)
      setLoading(false)
    })
  }

  //on change in tab
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const render = () => {
    return <>
      <Subheader title={t("agreement_detailslow")} goBack={() => history.goBack()} />
      {
        loading ?
          <LoadingSection top="20vh" message={t("loading")} />
          :
          <PropertyTabs value={value} handleChange={handleChange}
            tab1={<AgreementPreview reload={getAgreementDetails} id={id} details={details} />}
            tab2={<TimeLine details={details} reload={getAgreementDetails} agreement_id={id} />}
            tab3={<KYC details={details} reload={getAgreementDetails} />}
            tab4={<ManagedInvoice agree={details} id={id} quot_id={details?.quotation?.id} />}
            tab5={<Invoicesection id={id} details={details} />}
            tab6={<Insepection id={details?.id} details={details} />}

            tabTittle={tabTittle}
          />
      }</>
  }

  return (
    <Box className={classes.root}>
      {accessCheckRender(render, permission)}
    </Box>
  )
}

export default withNamespaces("agreement")(AgreementDetails);