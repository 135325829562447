import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { componantsStyles } from "./styles";
import {convertTimeUtcToZoneCalander} from '../../../utils'
export const QuotationDetail = ({ data }) => {
    const classes = componantsStyles()
    return (
        <>
            {
                data?.revenue_type === "Sale" ?
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className={classes.head}>HANDOVER DATE</Typography>
                            <Typography className={classes.sub}>{convertTimeUtcToZoneCalander(data?.occpation_date)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.head}>CONTRACT START DATE</Typography>
                            <Typography className={classes.sub}>{convertTimeUtcToZoneCalander(data?.contract_start_date)
                              }</Typography>
                        </Grid>

                    </Grid>
                    : <Grid container className={classes.detailRoot}>
                        <Grid item xs={4}>
                            <Typography className={classes.head}>LEASE START DATE</Typography>
                            <Typography className={classes.sub}>{data?.startDate
                              }</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.head}>LEASE END DATE</Typography>
                            <Typography className={classes.sub}>{convertTimeUtcToZoneCalander(data?.endDate)
                               }</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.head}>BILLING START DATE</Typography>
                            <Typography className={classes.sub}>{convertTimeUtcToZoneCalander(data?.billstartDate)
                                }</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box height="12px" />
                            <Typography className={classes.head}>GRACE PERIOD</Typography>
                            <Typography className={classes.sub}>{data?.gradeperiod} Days({data?.graceApplicable})</Typography>
                            <Box height="24px" />
                        </Grid>
                    </Grid>
            }

        </>
    )
}