import { Box, Button, Grid, Stack, Divider, IconButton } from "@mui/material";
import { SearchFilter, TableWithPagination } from "../../../components";
import { Unitheading, Unitpath } from "../../../utils/block";
import { unitStyles } from "../style";
import FilterIMG from "../../../assets/filter";
import { getPropertyHierarchyUnitRule } from "../../../utils";
export const AccountTable = ({
  handleSearch = () => false,
  searchText = "",
  handleIcon = () => false,
  data = {},
  handlePagination = () => false,
  handleChangeLimit = () => false,
  page = "",
  limit = "",
  selectedCompany = {},
  onUnitClick = () => false,
  handleOnClickAddPropertyButton = () => false,
  totalRowsCount = "",
  draweropen = () => false,
  unitlist = "",
  permission = {}
}) => {
  const classes = unitStyles();

  return (
    <Box>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={4}>
          {/*search field*/}
          <SearchFilter
            value={searchText}
            placeholder={"Search Units"}
            handleChange={(value) => handleSearch(value)}
          />
        </Grid>
        <Grid item xs={8}>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            justifyContent='right'
          >
            <IconButton onClick={draweropen} className={classes.filterimg}>
              <FilterIMG color="#091b29" />
            </IconButton>
            {(permission?.create &&
              getPropertyHierarchyUnitRule(unitlist?.breadCrumbs?.property_hierarchy, unitlist?.count)) &&
              <Button onClick={handleOnClickAddPropertyButton} variant="contained" className={classes.btn}>
                Add Unit
              </Button>}
          </Stack>
        </Grid>
      </Grid>
      <TableWithPagination
        heading={Unitheading}
        rows={data}
        path={Unitpath}
        showpagination={true}
        showpdfbtn={false}
        showexcelbtn={false}
        showSearch={false}
        tableType="no-side"
        count="2"
        handleIcon={handleIcon}
        dataType={[
          { type: ["info"], icon: "icon" },
          { type: ["text"], name: "unitNo" },
          { type: ["text"], name: "unitName" },
          { type: ["text"], name: "unitcategory" },
          { type: ["text"], name: "revenuetype" },
          { type: ["text"], name: "totalarea" },
          { type: ["text"], name: "occupancyStatus" },
        ]}
        handlePagination={handlePagination}
        handleChangeLimit={handleChangeLimit}
        onUnitClick={onUnitClick}
        totalRowsCount={totalRowsCount}
        page={page}
        limit={limit}
        height={`calc(100vh - 300px)`}
        view={permission?.read}
        edit={permission?.update}
        delete={permission?.delete} />
    </Box>
  );
};
