import { Box, Grid, Typography } from "@mui/material";
import { useStyles } from "../styles";
export const BankingDetails = ({ data = {} }) => {
    const classes = useStyles()
    return (
        <Box className={classes.box} marginTop="16px">
            <Typography className={classes.title} marginBottom={"14px"}>Banking Basic Details</Typography>
            <Grid container justifyContent="space-between" row rowSpacing={1} columnSpacing={3}>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>Bank Account Type</Typography>
                        <Typography className={classes.sub}>
                            {(data?.account_type !== null && data?.account_type.length > 0) ? data?.account_type : "-"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>Account Number</Typography>
                        <Typography className={classes.sub}>
                            {(data?.bank_account_no !== null && data?.bank_account_no.length > 0) ? data?.bank_account_no : "-"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>Bank Name</Typography>
                        <Typography className={classes.sub}>{(data?.bank_name !== null && data?.bank_name?.length > 0) ? data?.bank_name : "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>Bank Routing Type</Typography>
                        <Typography className={classes.sub}>{(data?.routing_type !== null && data?.routing_type?.length > 0) ? data?.routing_type : "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>Branch Name</Typography>
                        <Typography className={classes.sub}>{(data?.branch_name !== null && data?.branch_name?.length > 0) ? data?.branch_name : "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>Bank Routing Code</Typography>
                        <Typography className={classes.sub}>{(data?.routing_code !== null && data?.routing_code?.length > 0) ? data?.routing_code : "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>Address Line 1</Typography>
                        <Typography className={classes.sub}>
                            {(data?.address_1 !== null && data?.address_1.length > 0) ? data?.address_1 : "-"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box >
                        <Typography className={classes.heading}>Address Line 2</Typography>
                        <Typography className={classes.sub}>{(data?.address_2 !== null && data?.address_2?.length > 0) ? data?.address_2 : "-"}</Typography>
                    </Box>
                </Grid>

                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>City</Typography>
                        <Typography className={classes.sub}>{(data?.city !== null && data?.city?.length > 0) ? data?.city : "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>Country</Typography>
                        <Typography className={classes.sub}>
                            {(data?.country_master?.label !== null && data?.country_master?.label.length > 0) ? data?.country_master?.label : "-"}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>Currency</Typography>
                        <Typography className={classes.sub}>{(data?.bank_currency?.name !== null && data?.bank_currency?.name?.length > 0) ? data?.bank_currency?.name : "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>Preferred Cash Collection Office</Typography>
                        <Typography className={classes.sub}>
                            {(data?.cash_collection_office !== null && data?.cash_collection_office.length > 0) ? data?.cash_collection_office : "-"}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>Cheque Name</Typography>
                        <Typography className={classes.sub}>
                            {(data?.cheque_name !== null && data?.cheque_name.length > 0) ? data?.cheque_name : "-"}
                        </Typography>
                    </Box>

                </Grid>



            </Grid>
        </Box>
    )
}