import React, { useState, useEffect } from "react";
import { AlertContext } from "../../contexts";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import moment from "moment";
import { DatePicke } from "./components";

export const HandOverDateComponent = (props) => {
  const [handOverDate, setHandOverDate] = useState(new Date());
  const [handOverData, setHandOverData] = useState([]);
  // context;
  const alert = React.useContext(AlertContext);

  //get date
  const getDate = (val) => {
    let date = val?._d ? val?._d : val;
    setHandOverDate(date);
    HandoverDateFunction(date);
  };
  // property vs handover date api call
  const HandoverDateFunction = (company, date) => {
    // backdrop.setBackDrop({
    //   ...backdrop,
    //   open: true,
    //   message: "Getting Hand Over Dates...",
    // });
    let teamsId = props?.selectedTeams?.value;

    const payload = {
      company_id: [props?.selectedcompany?.value],
      handOverDate: moment(date).format("YYYY MM DD") ?? new Date(),
    };
    if (teamsId !== "noteams") {
      payload["team"] = teamsId
      payload["team_all_property"] = true

    }
    NetworkCall(
      `${config.api_url}/dashboard/property/get/all`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let result = response.data.data?.map((val, index) => {
          return {
            id: index,
            title: val?.name ?? " - ",
            location: val?.address ?? " - ",
            totalUnits: val?.total_units ?? " - ",
            color: "#DAE2F6",
          };
        });
        setHandOverData(result);

        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
      })
      .catch((error) => {
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  //   useEffect
  useEffect(() => {
    if (props?.selectedcompany?.label?.length > 0) {
      HandoverDateFunction(handOverDate?.date);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedTeams]);
  return (
    <>
      <DatePicke outStatndingData={handOverData ?? []} getDate={getDate} />
    </>
  );
};
