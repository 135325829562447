import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Property } from "../../assets";
import { Assets } from "../../screens/propertyFinder3/component/assets";
import {Regular, timeZoneConverter} from "../../utils"
import { Bold } from "../../utils";

const useStyles = makeStyles((theme) => ({
  box: {
    width: "450px",
    backgroundColor: "white",
    padding: "16px 16px 10px 16px",
    borderRadius: "16px",
    boxShadow: "0px 8px 24px #0717411F",
    cursor: "pointer",
  },
  img: {
    height: "100px",
    borderRadius: "12px",
    objectFit: "cover",
    width: "100%",
    cursor: "pointer",
  },
  tag1: {
    fontFamily: Bold,
    color: "#78B1FE",
    backgroundColor: "#F1F7FF",
    borderRadius: theme.palette.borderRadius,
    padding: "2px 8px",
    fontSize: "12px",
  },
  tag2: {
    fontFamily: Bold,
    color: "#FF9340",
    backgroundColor: "#FFF4EB",
    borderRadius: theme.palette.borderRadius,
    padding: "2px 8px",
    fontSize: "12px",
    display: "inline-block",
  },
  close: {
    fontSize: "20px",
  },
  title: {
    fontFamily: Bold,
    color: "#091B29",
    fontSize: "18px",
    marginLeft: "12px",
    marginTop: "10px",
    textTransform: "capitalize",
  },
  build: {
    fontSize: "12px",
    color: "#4E5A6B",
    fontFamily: Bold,
  },
  build1: {
    fontSize: "12px",
    color: "#98A0AC",
    fontFamily: Regular,
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: theme.palette.borderRadius,
      padding: "0px",
    },
  },
}));

export const InfoDetails = (props) => {
  const classes = useStyles(props);

  const {
    //title = "",
    //description = "",
    //logo = "",
    //showVideo = false,
    //subTitle = "",
    //info = [],
    //buttonName = "",
    onClose = null,
    //buttonAction = null,
    //status = "",
    data,
    onClick,
  } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Box display="flex" className={classes.box}>
        <Box flexGrow={1}>
          <Grid container>
            <Grid
              item
              xs={3}
              onClick={
                onClick ? (e) => onClick(e.currentTarget, data) : () => false
              }
            >
              {data?.logo?.length > 0 ? (
                <img className={classes.img} src={data?.logo} alt="" />
              ) : (
                <Property />
              )}
            </Grid>
            <Grid
              item
              xs={9}
              onClick={
                onClick ? (e) => onClick(e.currentTarget, data) : () => false
              }
            >
              <Box display="flex" alignItems="center">
                <Box marginLeft="12px">
                  <Typography className={classes.tag1}>
                    &nbsp;&nbsp;{data?.type?.group_name ?? data?.type}
                    &nbsp;&nbsp;
                  </Typography>
                </Box>
                <Box marginLeft="12px" flexGrow={1}>
                  <Typography className={classes.tag2}>
                    {props?.isDashBoard ? (
                      <>
                        &nbsp;&nbsp;{data?.total_units} {props?.statusDashBoard}
                        &nbsp;&nbsp;
                      </>
                    ) : (
                      <>
                        {" "}
                        &nbsp;&nbsp;{data?.units?.length} Vacant
                        Units&nbsp;&nbsp;
                      </>
                    )}
                  </Typography>
                </Box>
              </Box>
              <Typography className={classes.title}>{data?.name}</Typography>
              <Box
                display="flex"
                alignItems="center"
                marginTop="4px"
                marginLeft="12px"
                onClick={
                  onClick ? (e) => onClick(e.currentTarget, data) : () => false
                }
              >
                <Box>
                  {data?.year_built && (
                    <Stack direction="row" alignItems="flex-end">
                      <img src="/images/build.svg" alt="" />

                      <Typography className={classes.build}>
                        {timeZoneConverter(data?.year_built , "YYYY")
                          }
                        <span className={classes.build1}>&nbsp;Build</span>
                      </Typography>
                    </Stack>
                  )}
                </Box>
                <Box marginLeft="12px">
                  <Stack direction="row" alignItems="center">
                    <img src="/images/locs.svg" alt="" />
                    <Typography className={classes.build}>
                      {data?.address?.country},{data?.address?.district}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <IconButton
            size="small"
            onClick={onClose}
            style={{
              marginTop: "-14px",
              marginRight: "-10px",
            }}
          >
            <CloseIcon className={classes.close} />
          </IconButton>
        </Box>
      </Box>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => setOpen(false)}
        className={classes.dialog}
      >
        <Assets
          assets={data?.assets}
          title="Property View"
          onClose={() => setOpen(false)}
        />
      </Dialog>
    </>
  );
};
