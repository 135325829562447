import React from "react";
import { withNavBars } from "../../HOCs";
import { AgreementRenewal } from "./agreementRenewal";

class AgreementRenewalParent extends React.Component {
  render() {
    return <AgreementRenewal />;
  }
}

const props = { boxShadow: false }

export default withNavBars(AgreementRenewalParent, props);
