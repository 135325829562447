import { useApolloClient } from "@apollo/client";
import {
    Box, Grid
} from "@mui/material";
import React from "react";
import { FormGenerator } from "../../../components";
import { getMailstoneTemplate } from "../../../graphql/quotationQueries";
import { LocalStorageKeys } from "../../../utils";
import { saledataType, saleheading, salepath } from "../../../utils/createQuotationUtils";
import { LeadDetailsCard } from "./index";
import { MileStoneTemplate } from "./mileStoneTemplate";
import { useStyles } from "./styles";

export const SaleQuotationForm = ({ data, updateState, user = {}, type = "", purpose = "",setData=()=>false }) => {
    const classes = useStyles();
    const client = useApolloClient();

    //get template
    const getTemplate = async (value) => {
        client.query({
            query: getMailstoneTemplate,
            fetchPolicy: 'network-only',
            variables: {
                client: localStorage.getItem(LocalStorageKeys.clinetID),
                template_id: value?.value
            }
        }).then((response) => {
            const list = response?.data?.milestone_template_items?.map((val) => {
                return { ...val, milestone: val?.delivery_milestone_masters?.name }
            })
            let error = data.error;
            error["payment_period"] = "";
            setData({...data,quotation_payment_peroid:{value:"Milestone Based"},quotation_milestone:value,mailstoneList:list,mileStoneName:value?.label,mileStoneId:value?.value,payment_period_value:value,error})

        }).catch((err) => {
            console.log(err)
        })
    }
    const choosePaymentPeriod = (value) => {
        if (value.label === "On Completion") {
            let error = data.error;
            error["payment_period"] = "";
            setData({ ...data, payment_period: { value: "On Completion" }, "mailstoneList": [], mileStoneName: value?.label, quotation_payment_peroid: { value: value?.label },payment_period_value:value, error })
        }
        else {
            getTemplate(value)
        }

    }
    //form data


    const formLease1 = [
        {
            size: {
                xs: 8,
                sm: 8,
                md: 8,
                lg: 8
            },
            isActive: true,
            component: "text",
            label: "Quotation Description",
            value: data?.quotation_description,
            placeholder: "Quotation Description",
            onChange: (value) => updateState("quotation_description", value.target.value),
            error: data?.error?.quotation_description,
            isRequired: true,
            options: [],
            // multiline: true
        },
        {
            size: {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "date",
            label: "Handover Date",
            value: data?.quotation_handover_date,
            placeholder: "Handover Date",
            onChange: (value) => updateState("quotation_handover_date", value),
            error: data?.error?.quotation_handover_date,
            isRequired: true,
            options: [],
            isReadonly: true,
        },
        {
            size: {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "date",
            label: "Contact Start Date",
            value: data?.quotation_contract_start_date,
            placeholder: "Contact Start Date",
            onChange: (value) => updateState("quotation_contract_start_date", value),
            error: data?.error?.quotation_contract_start_date,
            isRequired: true,
            options: [],
            isReadonly: true,
        },
        {
            size: {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "payment",
            label: "Payment Period",
            value: data?.quotation_payment_peroid?.value,
            placeholder: "Payment Period",
            onChange: choosePaymentPeriod,
            mileStoneName:data?.mileStoneName,
            companyId:data?.company,
            error: data?.error?.quotation_payment_peroid?.value,
            isRequired: true,
            mileStoneId: {
                payment_period: data?.payment_period,
                payment_period_value:data?.payment_period_value?.value,
              },
        },
        {
            size: {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "select",
            label: "Payment Options",
            value: data?.quotation_payment_option,
            placeholder: "Payment Options",
            onChange: (value) => updateState("quotation_payment_option", value),
            error: data?.error?.quotation_payment_option,
            isRequired: true,
            options: [],
            isReadonly:true,
        },

    ]


    return (
        <Box p={1}>
            <Grid container className={classes.leadDetailsRoot}>
                <Grid item xs={3} p={1}>
                    <Box p={2} className={classes.leadDetailsRootDivider}>
                        <LeadDetailsCard
                            data={{
                                name: data?.name,
                                image: data?.image_url?.length !== 0 ? data?.image_url?.src : null,
                                email: data?.email_id,
                                mobile: ` ${data.mobile?.mobile_code ?? ""} ${data.mobile?.mobile}`,
                                no: null,
                                type: data?.revenue_type?.value,
                                purpose: data?.purpose?.value,
                                source: data?.source?.value,
                                units: data?.units
                            }}
                            hideDetails={true}
                            dataNew={data}
                            setDataNew={setData}
                            updateState={updateState}
                            isReadOnly={true}

                        />
                        <Box height="18px" />
                        {/* <FormGenerator components={formLease} /> */}
                    </Box>
                </Grid>
                <Grid item xs={9} p={1}>
                    <Box p={2} className={classes.leadDetailsRootDivider}>
                        <FormGenerator components={formLease1} />
                        <Box height="18px" />
                        {
                            data?.mailstoneList?.length > 0 &&
                            <Grid item xs={8}>
                                <MileStoneTemplate
                                    dataType={saledataType}
                                    rows={data?.mailstoneList}
                                    heading={saleheading}
                                    path={salepath}
                                    title={data?.mileStoneName}
                                />
                            </Grid>
                        }

                    </Box>
                </Grid>
            </Grid>
        </Box>


    );
};
