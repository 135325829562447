import { Grid } from '@mui/material';
import React from 'react';
import { StepCard } from '../createDeliveryMilestoneTemplate/components/stepCard';
import { DeliveryMilestoneTemplateStyles } from './style';

export const MilesStoneView = (props) => {
    const classes = DeliveryMilestoneTemplateStyles()
    return (
        <Grid container className={classes.bg} padding="16px">
            {props?.list?.map((val, index) => {
                return (
                    <>
                        <Grid item xs={3} />
                        <Grid item xs={6}>
                            <StepCard list={val} index={index} length={props?.list?.length} view />
                        </Grid>
                        <Grid item xs={3} />
                    </>
                )
            })}


        </Grid>
    )
}