import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Avatar, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { SemiBold } from "../../utils";
const useStyles = makeStyles((theme) => ({
  Avatar: {
    backgroundColor: theme.palette.primary.main,
    width: "22px",
    height: "22px",
    fontSize: "13px",
  },
  tittle: {
    color: theme.palette.primary.main,
  },
  next: {
    marginLeft: "10px",
    fontFamily: SemiBold,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  Cancel: {
    backgroundColor: "#E4E8EE ",
    color: "#091B29",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: SemiBold,
    "&:hover": {
      backgroundColor: "#E4E8EE ",
    },
  },
  outerCircle: {
    padding: "3px",
    border: "1px solid #5078E1",
    borderRadius: "50%",
  },
}));
export const CustomSeparator = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          margin: "9px 0px",
          padding: "0px 14px 7px",
          borderBottom: "1px solid #E4E8EE",
        }}
      >
        {props?.list.map((val) => {
          return (
            <Box style={{ display: "flex", alignItems: "center" }}>
              {val?.step === val.value ? (
                <div className={classes.outerCircle}>
                  <Avatar className={classes.Avatar}>1</Avatar>
                </div>
              ) : (
                ""
              )}

              <Typography
                className={
                  val?.step === val.value ? classes.tittle : classes.tittle1
                }
                style={{ marginLeft: "10px" }}
              >
                {val?.title}
              </Typography>
              {val.step !== 3 && (
                <ChevronRightIcon
                  className={
                    val?.step === val.value ? classes.tittle : classes.tittle1
                  }
                />
              )}
            </Box>
          );
        })}
      </Box>
    </div>
  );
};
