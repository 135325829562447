import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { withNamespaces } from "react-i18next";
import FilterIcon from "../../assets/filterIcon";
import { SearchFilter } from "../../components";
import { useStylesInspection } from "./style";



const MoveOutHead = ({ t, handleSearch = () => false, searchText = "", openFilter = () => false }) => {

    const classes = useStylesInspection()

    return (
        <Box>
            <Grid container className={classes.girdItem}>
                <Grid item md={4}>
                    <SearchFilter placeholder={t("SearchNewRequests")} handleChange={(value) => handleSearch(value)} value={searchText} />
                </Grid>
                <Grid item md={8}>
                    <Box className={classes.filterIconBox} onClick={() => openFilter()}>
                        <FilterIcon />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
export default withNamespaces("moveoutInspection")(MoveOutHead);
