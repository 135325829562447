import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { OutstandingCardStyles } from "./outstandingCardStyle";



export const OutStandingCard = (props) => {
    const classes = OutstandingCardStyles(props);
    return (
        <>
            <div className={classes.card} style={{backgroundColor:props?.data?.color ?? ""}} onClick={()=> props?.navigate(props?.data)}>
                <div className={classes.Cardcontent}>
                    <Box alignItems="center" display="flex">
                        {props?.type === 1 &&
                            <>
                                {props?.data?.logo && props?.data?.logo ?
                                    <Box >
                                        <Avatar src={props?.data?.logo} ></Avatar>
                                    </Box> :
                                    <Box >
                                        <Avatar ></Avatar>
                                    </Box>
                                }
                            </>
                        }
                        <Box flexGrow={1} marginLeft="10px">
                            <Box alignItems="center" display="flex">
                                <Box>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.title}
                                        noWrap
                                    >
                                        {props?.data?.title}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                marginTop="4px"
                                style={{ flexFlow: "wrap !important" }}
                                className={classes.boxes}
                            >
                                <div className={classes.div}>
                                    <Box>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.sub}
                                            noWrap
                                        >
                                            {props?.data?.location ? props?.data?.location : props?.data?.type}
                                        </Typography>
                                    </Box>
                                </div>
                                {props?.type === 2 &&
                                    <>
                                        <div className={classes.div}>
                                            <Box className={classes.dot} />

                                            <Box>
                                                <Typography
                                                    variant="subtitle2"
                                                    className={classes.sub}
                                                    noWrap
                                                >
                                                    {props?.data?.totalUnits}
                                                </Typography>
                                            </Box>
                                        </div>
                                    </>
                                }
                                {props?.type === 3 &&
                                    <>
                                        <div className={classes.div}>
                                            <Box className={classes.dot} />

                                            <Box>
                                                <Typography
                                                    variant="subtitle2"
                                                    className={classes.sub}
                                                    noWrap
                                                >
                                                    {props?.data?.date}
                                                </Typography>
                                            </Box>
                                        </div>
                                        <div className={classes.div}>
                                            <Box className={classes.dot} />

                                            <Box>
                                                <Typography
                                                    variant="subtitle2"
                                                    className={classes.sub}
                                                    noWrap
                                                >
                                                    {props?.data?.unitNo}
                                                </Typography>
                                            </Box>
                                        </div>
                                    </>
                                }
                            </Box>
                        </Box>
                        <Box>
                            {props?.type === 1 &&
                                <Typography className={classes.amount}>
                                    {props?.data?.sum}
                                </Typography>
                            }
                            {/* {props?.type === 3 &&
                                <>
                                    <IconButton>
                                        <EditIMG color="#98a0ac" />
                                    </IconButton>
                                    <IconButton>
                                        <RemoveRedEyeOutlinedIcon
                                            sx={{ fontSize: "16px", color: "#98A0AC" }}
                                        />
                                    </IconButton>
                                </>
                            } */}
                        </Box>
                    </Box>
                </div>
            </div>
        </>
    );
};
