import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular } from "../../utils";
export const AggrementDetailsStyles = makeStyles((theme) => ({

    tabtitle: {
        fontSize: '12px',
        color: '#4E5A6B',
        fontFamily: Bold,
    },
    tabtitle1: {
        fontSize: '12px',
        color: '#5078E1',
        fontFamily: Bold,
    },
    subtabtitle: {
        fontSize: '10px',
        color: '#4E5A6B',
        fontFamily: Regular,
    },
    subtabtitle1: {
        fontSize: '10px',
        color: '#5078E1',
        fontFamily: Regular,
    },
    selectBack1: {
        backgroundColor: '#F1F7FF',
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
    },
    selectBack: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
    },
    icon: {
        color: "#7D534C",
        backgroundColor: "#FEEAEA80",
        borderRadius: "6px",
        fontSize: "42px",
        padding: "10px"

    },

    invoicedetails: {
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "0px 0px 16px #00000014",

    }
}));