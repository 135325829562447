import gql from "graphql-tag";

export let GET_PROPERTY_UNITS = (offset, limit, searchText, params) => gql`
query GET_PROPERTY{
  count: property(
    where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
        company_id:"${params?.company_id}"
    }
  ) {
    count:count_id
  }
 unit:property(
      where:{
           name:{iregex:"${searchText}"}
           company_id:"${params?.company_id}"
           is_active:{eq:true}
         }
      offset:${offset}
      limit:${limit}
    ){
         label:name
         label1:property_no
         value:id
         url:logo

      }
}
`;