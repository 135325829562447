import { makeStyles } from "@mui/styles";

export const FreeTextInvoiceStyle = makeStyles((theme)=>({
    createBtn:{
        height:40,
        width:78
    },
    publishPopupTitle:{
        fontSize:16,
        color:"#fff",
        fontWeight:"bold",
        whiteSpace:"nowrap"
    },
    publishPopupBtn:{
        background:"#fff",
        borderRadius:4,
        width:150,
        height:40,
        justifyContent:"center",
        alignItems:"center",
        display:"flex",
        cursor:"pointer"
    },
    publishbtnText:{
        color:theme.palette.primary.main,
        fontSize:14,
        fontWeight:"bold",
    },
    publishDialog:{
        '& .MuiPaper-root':{
            background:"transparent !important"
        }
    }
}))