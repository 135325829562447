export const CardData = {

    InspectionDetail: [{
        status: "Yet To Assign",
        name: "RQINS-122143",
        description: "Requested on 23 Mar 22, by Shakakhan",
    }],

    ResponsiblePersonDetail: [

        {
            image: "https://cdn.pixabay.com/photo/2017/11/10/05/48/user-2935527_1280.png",
            name: "Bala",
            number: 9876543210,
        },
        {
            image: "https://cdn.pixabay.com/photo/2017/11/10/05/48/user-2935527_1280.png",
            name: "ganesh",
            number: 9876543210,
        },
    ],

    AgreementDetail: [{
        name: "AGR-12134",
        description: "22 Mar 22 - 22 Mar 23",
    }],

    PropertyDetail: [
        {
            name: "Casa Grande",
            address: "Anna Nagar, Chennai",
        },
        {
            name: "Casa Grande",
            address: "Anna Nagar1, Chennai",
        },
    ],

    GatePass: [{
        name: "Truck Gate-Pass",
        description: "Last link sent on 22 Jun 22"
    }],

    tenant: [{
        name: "Tenant Login Link",
        description: "Last link sent on 22 Jun 22"
    }],

    TrackUpdate: [{
        name: "Inspection completed",
        description: "Updated by Doris Hughes, 22 Jul 22"
    },
    {
        name: "Inspection completed",
        description: "Updated by Doris Hughes, 22 Jul 22"
    }
    ]
}


export const validateArrayContaines = (array,type) => {
    if(array?.length > 0 ) {
        let result = array.flatMap((val) => {
            if(type==="moveIn"){
                return [
                    val?.condition,
                    val?.cleanliness
                ]
            }
            else{
                return [
                    val?.out_condition,
                    val?.out_cleanliness
                ]
            }

        }).every((i) => i)
        return result
    }
    else{
        return []
    }
}