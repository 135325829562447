import { Box, Grid } from '@mui/material'
import { addDays, subDays } from 'date-fns'
import React from 'react'
// import { CustomDropDown } from './component/customDropdown'
import { CalendarDateChange, LoadingSection } from '../../components'
import { config } from '../../config'
import { NetworkCall } from '../../networkcall'
import { NetWorkCallMethods } from '../../utils'
import { DayCalendar } from './component/dayCalendar'
import { UserList } from './component/userList'

export const DailyCalendar = ({ details = {}, data = {}, selectedCompany = {} }) => {


    const [daycal, setDayCal] = React.useState({ Date: new Date(details?.execution) });
    const [list, setList] = React.useState([]);
    const [loading, setLoading] = React.useState(true)

    const getDailyList = (date) => {
        setLoading(true)
        const payload = {
            date: date,
            resource_id: data?.id
        }
        NetworkCall(
            `${config.api_url}/resources/resource_board_view`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setList(res?.data?.data)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    React.useEffect(() => {
        getDailyList(new Date(details?.execution), details?.execution)
        // eslint-disable-next-line
    }, [])

    const next = () => {
        setDayCal({
            Date: addDays(daycal.Date, 1)
        })
        getDailyList(addDays(daycal.Date, 1))
    }

    const prev = () => {
        setDayCal({
            Date: subDays(daycal.Date, 1)
        })
        getDailyList(subDays(daycal.Date, 1))
    }


    return (
        <Grid container p={1} alignItems="center">
            <Grid item xs={4}>
                <Box sx={{ border: "1px solid #E4E8EE", borderRadius: 2 }} p={1}>
                    <UserList userData={data} />
                </Box>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4} marginLeft="18px">
                <CalendarDateChange state={daycal?.Date} prev={prev} next={next} comp={"daily"} />
            </Grid>
            <Grid item xs={12} marginTop="12px">
                {
                    loading ?
                        <LoadingSection top="20vh" bottom="20vh" />
                        :
                        <DayCalendar selectedCompany={selectedCompany} daycal={daycal} datas={data} progress={parseInt(details?.percentage)} list={list} />
                }

            </Grid>
        </Grid>
    )
}