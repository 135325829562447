import gql from "graphql-tag";
export const getPropertyDetails = gql`
query {
    property(where: { id: $id }) {
      name
      id
      property_no
      is_active
      logo
      property_purpose
      unit{
        count_id
      }
      description
      company {
        id
        name
      }
      property_groupByID {
        group_name
      }
    }
  }
`;

export const getUnitBasedOnProperty = gql`
query {
  unit(
    where: {
      is_active: { eq: true }
      property:{id:$propertyId}
      unit_category_master:{id:{nin:$type}}
      name: { iregex: $search }
    }
    offset:$offset
    limit:$limit
  ) {
    id
    logo
    name
    unit_category{
      id
      name
    }
    block {
      id
      name
    }
    floor {
      id
      name
    }
    property {
      id
      name
    }
    unit_category {
      id
      name
    }
  }
}

`
export const getGeneralInspectionList = gql`
query GET_List($offset: Int, $limit: Int, $companyId: ID, $search: String) {
  count: agreement_inspection_request(
    where: {request_type: "service", company_id: $company_id, reference_id: {iregex: $search}}
  ) {
    count: count_id
  }
  agreement_inspection_request(
    where: {request_type: "service", company_id: $company_id, reference_id: {iregex: $search}}
    offset: $offset
    limit: $limit
    order_by: {created_at: desc}
  ) {
    id
    created_at
    status
    reference_id
    created_by {
      first_name
    }
    agreement {
      agreement_no
    }
    agreement_inspection {
      id
      unit_id
      status
      maintenance_request{
        maintenance_request_no
      }
      inspection_resources {
        id
        actual_hours
        other_hours
        eta
        resource_checkin_checkout(order_by: { end_date_time: desc}, limit:1 ){
          end_date_time
        }
        resource {
          contact_id
          contact {
            first_name
            last_name
          }
        }
      }
      unit {
        id
        unit_no
        name
        property {
          name
          address {
            city
            country
          }
        }
      }
      count_id
    }
  }
}
`

export const getSiteVisitRequestList = gql`
query GET_List(
  $offset: Int
  $limit: Int
  $companyId: ID
  $search: String
  $request_type:String
){
  count: agreement_inspection_request(
    where: {request_type: $request_type, company_id: $company_id,reference_id:{ iregex: $search }}
  ) {
    count: count_id
  }
  agreement_inspection_request(

    where: {request_type: $request_type, company_id: $company_id,reference_id:{ iregex: $search }}
    offset:$offset
    limit:$limit
    order_by: { created_at: desc }
  ) {
    id
    created_at
    status
    reference_id
    created_by {
      first_name
    }
    agreement {
      agreement_no
    }
    agreement_inspection {
      id
      unit_id
      status
      inspection_resources{
        id
        resource{
          contact_id
          contact{
            first_name
            last_name
          }
        }
      }
      unit{
        id
        unit_no
        property{
          name
          address{
            city
            country
          }
        }
      }
      count_id
    }
  }
}
`
export let GET_MAINTANANCE_REQUEST = (
  offset,
  limit,
  searchText,
  obj,
) => gql`
        query GET_MAINTANANCE_REQUEST {
          count: request (
            offset:${offset}
            limit:${limit}
            where: { request_type: "Maintenance",
             request_status: "Open",
             unit_id:"${obj?.unit_id}",
             subject: { iregex: "${searchText}" }
            }
          ) {
            count: count_id
          }
          request(
            where: { 
              request_type: "Maintenance",
               request_status: "Open",
               unit_id:"${obj?.unit_id}",
               subject: { iregex: "${searchText}" }
              }

            offset:${offset}
            limit:${limit}
          ) {
            value:id
            label:subject
            id
            subject
            description
            category
            sub_category
            request_no
            image_url
            unit_id
            created_at
          }
        }`;

export const updateAgreementInspections = gql`
mutation {
  agreement_inspection(update: $update, where: { id: { eq: $id } }) {
    id
  }
}

`