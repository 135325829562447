export const PricingComponentPath = [
    "company_name",
    "pricing_component",
    "revenue_type",
    "name",
    "taxes",
    "priceOverride",
    "autoReservation",
    "autoSecurityDeposit",
    "status",
    "icon",
];

export const PricingComponentHeading = [
    { title: "Company Name", field: "company_name" },
    { title: "Pricing Component", field: "pricing_component" },
    { title: "Revenue Type", field: "revenue_type" },
    { title: "Component Name", field: "name" },
    { title: "Taxes", field: "taxes" },
    { title: "Price Override", field: "priceOverride" },
    { title: "Auto Reservation ", field: "autoReservation" },
    { title: "Auto Security Deposit ", field: "autoSecurityDeposit" },
    { title: "Status", field: 'status', },
    { title: "", field: 'icon' },
];

export const PricingComponentType = [
    { type: ["text"], name: "company_name" },
    { type: ["text"], name: "pricing_component" },
    { type: ["text"], name: "revenue_type" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "taxes" },
    { type: ["text"], name: "priceOverride" },
    { type: ["text"], name: "autoReservation" },
    { type: ["text"], name: "autoSecurityDeposit" },
    { type: ["status"], name: "status" },
    { type: ["more"], icon: "icon" },
];