import { Box, Typography } from "@mui/material";
import React from "react";
import { CompanySettingsList, FormGenerator } from "../../../components";
import { AlertContext } from "../../../contexts";
import { AlertProps, LocalStorageKeys } from "../../../utils";
import { basicSettingState, returnSaveData } from "../../../utils/clientsettings";
import { reservation_feature } from "../../../utils/companySettings";
import { SettingSave } from "../../companyCreation/components";
import { useStyles } from "../styles";

export const AdvancedSettings = ({ selected = {}, settings = {}, onSubmitSettings = () => false }) => {
    const classes = useStyles();
    const alert = React.useContext(AlertContext);
    const [data, setData] = React.useState({ ...basicSettingState })

    //update data
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    }
    //validate
    const validateForm = () => {
        let isValid = true;
        let error = data.error;

        if (data?.reservation_feature?.is_active === true) {
            if (data?.reservation_feature?.value?.length === 0) {
                isValid = false;
                error.reservation_feature = "Reservation Feature value is Required";
            }
        }
        if (data?.grace_peroid?.is_active === true) {
            if (data?.grace_peroid?.value?.length === 0) {
                isValid = false;
                error.grace_peroid = "Grace Peroid value is Required";
            }
        }

        setData({ ...data, error });
        return isValid;
    }
    //on submit form
    const onSubmit = () => {

        if (validateForm()) {
            const hierarchy = []
            const payload = returnSaveData(data)

            if (data?.level1_block || data?.level1_floor || data?.level1_is_primary) {
                hierarchy.push({
                    block_label: data?.level1_block?.length > 0 ? data?.level1_block : null,
                    floor_label: data?.level1_floor?.length > 0 ? data?.level1_floor : null,
                    is_primary: data?.level1_is_primary !== "" ? data?.level1_is_primary : null,
                    hierarchy_id: 5,
                    client: localStorage.getItem(LocalStorageKeys.clinetID),
                    id: data?.level1_id ?? undefined
                })
            }
            if (data?.level2_block || data?.level2_floor || data?.level2_is_primary) {
                hierarchy.push({
                    block_label: data?.level2_block?.length > 0 ? data?.level2_block : null,
                    floor_label: data?.level2_floor?.length > 0 ? data?.level2_floor : null,
                    is_primary: data?.level2_is_primary !== "" ? data?.level2_is_primary : null,
                    hierarchy_id: 6,
                    client: localStorage.getItem(LocalStorageKeys.clinetID),
                    id: data?.level2_id ?? undefined
                })
            }
            if (hierarchy?.length > 0) {
                const result = { ...payload, hierarchy }
                return onSubmitSettings(result)
            }
            onSubmitSettings(payload)

        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please Fill All Mandatory Field",
            });
        }
    }
    //initial load
    React.useEffect(() => {
        setData(settings)
        // eslint-disable-next-line
    }, [settings])
    return (
        <Box>
            {/*setting save */}
            <SettingSave title={selected?.label} subTitle={selected?.subtitle} btn={true} onSave={onSubmit} />
            <Box className={classes.settingRoot}>
                {/*"Enable Reservation Feature"*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Enable Reservation Feature"
                        onChange={(value) => updateState("reservation_feature", value)}
                        value={data?.reservation_feature}
                        toggle={true}
                        custom={true}
                        customValue={0}
                        openCustom={
                            data?.reservation_feature?.value === null ? false : true}
                        isError={data?.error?.reservation_feature?.length > 0}
                        errorMessage={data?.error?.reservation_feature}
                        options={data?.reservation_feature?.is_active ? reservation_feature : []}
                    />
                </Box>
                {/*Enable Auto Deposite Amount Calculation Feature*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Enable Auto Deposite Amount Calculation Feature"
                        onChange={(value) => updateState("auto_deposite_cal", value)}
                        value={data?.auto_deposite_cal}
                        toggle={true}
                    />
                </Box>
                {/*Enable Auto Reservation Amount Calculation Feature*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Enable Auto Reservation Amount Calculation Feature"
                        onChange={(value) => updateState("auto_reservation_cal", value)}
                        value={data?.auto_reservation_cal}
                        toggle={true}
                    />
                </Box>
                {/*"Enable Grace Period"*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Enable Grace Period"
                        onChange={(value) => updateState("grace_peroid", value)}
                        value={data?.grace_peroid}
                        toggle={true}
                        custom={true}
                        isError={data?.error?.grace_peroid?.length > 0}
                        errorMessage={data?.error?.grace_peroid}
                        customValue={0}
                        openCustom={
                            data?.grace_peroid?.value === null ? false : true}
                        options={data?.grace_peroid?.is_active ? reservation_feature : []}
                    />
                </Box>
                {/*Enable Pricing Override*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Enable promotional pricing"
                        onChange={(value) => updateState("pricing_override", value)}
                        value={data?.pricing_override}
                        toggle={true}
                    />
                </Box>
                {/*Enable Short Term Retals*/}
                {/* <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Enable Short Term Retals"
                        onChange={(value) => updateState("short_term_retals", value)}
                        value={data?.short_term_retals}
                        toggle={true}
                    />
                </Box> */}
                {/*Enable Storage Offering*/}
                {/* <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Enable Storage Offering"
                        onChange={(value) => updateState("storage_offering", value)}
                        value={data?.storage_offering}
                        toggle={true}
                    />
                </Box> */}
                {/*Enable Refer and Win*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Enable Refer and Win"
                        onChange={(value) => updateState("refer_win", value)}
                        value={data?.refer_win}
                        toggle={true}
                    />
                </Box>
                {/*Enable Professional Banners*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Enable Professional Banners"
                        onChange={(value) => updateState("professoinal_banners", value)}
                        value={data?.professoinal_banners}
                        toggle={true}
                    />
                </Box>
                {/* level 1 */}
                <Box padding="8px 12px">
                    <Box className={classes.root}>
                        <Typography className={classes.title}>Level 1</Typography>
                        <Box height={'10px'} />
                        <FormGenerator components={[{
                            isActive: true,
                            component: "text",
                            label: "Block",
                            value: data?.level1_block,
                            placeholder: "Enter Block",
                            onChange: (e) => updateState("level1_block", e?.target?.value),
                            isRequired: true,
                            size: {
                                xs: 12,
                                sm: 12,
                                md: 6,
                                lg: 4,
                            },
                            isPaginate: true,
                        }, {
                            isActive: true,
                            component: "text",
                            label: "Floor",
                            value: data?.level1_floor,
                            placeholder: "Enter Floor",
                            onChange: (e) => updateState("level1_floor", e?.target?.value),
                            isRequired: true,
                            size: {
                                xs: 12,
                                sm: 12,
                                md: 6,
                                lg: 4,
                            },
                            isPaginate: true,
                        },
                        {
                            component: "check",
                            isActive: true,
                            label: "Primary",
                            value: data?.level1_is_primary,
                            placeholder: "Enter Floor",
                            onChange: (e) => updateState("level1_is_primary", e?.target?.checked),
                            isRequired: true,
                            size: {
                                xs: 12,
                                sm: 12,
                                md: 6,
                                lg: 4,
                            },
                        }
                        ]} />
                    </Box>
                </Box>
                {/* level 2 */}
                <Box padding="8px 12px">
                    <Box className={classes.root}>
                        <Typography className={classes.title}>Level 2</Typography>
                        <Box height={'10px'} />
                        <FormGenerator components={[{
                            isActive: true,
                            component: "text",
                            label: "Block",
                            value: data?.level2_block,
                            placeholder: "Enter Block",
                            onChange: (e) => updateState("level2_block", e?.target?.value),
                            isRequired: true,
                            size: {
                                xs: 12,
                                sm: 12,
                                md: 6,
                                lg: 4,
                            },
                            isPaginate: true,
                        }, {
                            isActive: true,
                            component: "text",
                            label: "Floor",
                            value: data?.level2_floor,
                            placeholder: "Enter Floor",
                            onChange: (e) => updateState("level2_floor", e?.target?.value),
                            isRequired: true,
                            size: {
                                xs: 12,
                                sm: 12,
                                md: 6,
                                lg: 4,
                            },
                            isPaginate: true,
                        },
                        {
                            component: "check",
                            isActive: true,
                            label: "Primary",
                            value: data?.level2_is_primary,
                            placeholder: "Enter Floor",
                            onChange: (e) => updateState("level2_is_primary", e?.target?.checked),
                            isRequired: true,
                            size: {
                                xs: 12,
                                sm: 12,
                                md: 6,
                                lg: 4,
                            },
                        }
                        ]} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}