import { Box, Stack } from '@mui/material'
import React from 'react'
import { AlertDialog, CalendarDateChange, LayoutSwitch, SearchFilter } from '../../../components'
import { CalendarDropDown } from '../../amenitiesBooking/components/calendarDropDown'
// import { CalendarDropdown } from '../../../components/calendarDropdown'
import { ViewCourt } from '../viewCourt'
import { ParkingGroupMasterDropdown } from './parkingDropdown'

export const CalendarHeader = (
    {
        selected = {},
        weekprev = () => false,
        weeknext = () => false,
        monthnext = () => false,
        monthprev = () => false,
        month = "",
        weekdates = {},
        setDropdownValue = () => false,
        setselect = () => false,
        select = {},
        showAll = () => false,
        open = {},
        daily = "",
        nextdate = () => false,
        prevdate = () => false,
        parkingGroupData = [],
        selectedParkingDropdown = () => false,
        setSearchValueFunc = () => false,
        searchValue = ''

    }
) => {


    // POP up functions

    const [parkingGroupDialog, setParkingGroupDialog] = React.useState(false)
    const openParkingGroup = () => {
        setParkingGroupDialog(true)
    }

    const [court, setCourt] = React.useState(false)

    return (
        <Box padding={"0px 14px"}>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box display={"flex"} alignItems={"center"}>
                    <ParkingGroupMasterDropdown
                        openParkingGroup={openParkingGroup}
                        parkingGroupData={parkingGroupData}
                        selectedParkingDropdown={selectedParkingDropdown}
                    />
                    {select === "P" &&
                        <Box ml={1}>
                            <SearchFilter value={searchValue} handleChange={(val) => setSearchValueFunc(val)} placeholder={"search by slot no"} />
                        </Box>
                    }
                </Box>
                <Box
                >
                    {
                        selected === "Weekly" &&
                        <CalendarDateChange state={weekdates} prev={weekprev} next={weeknext} comp={"weekly"} />
                    }
                    {
                        selected === "Monthly" &&
                        <CalendarDateChange state={month} prev={monthprev} next={monthnext} comp={"month"} />
                    }
                    {
                        selected === "Daily" &&
                        <CalendarDateChange state={daily} prev={prevdate} next={nextdate} comp={"daily"} noArrow />
                    }
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                    {/* <CalendarDropdown
                        open={open}
                        selected={selected}
                        showAll={showAll}
                        setDropdownValue={setDropdownValue}
                        right
                        select={select}
                    /> */}
                    <CalendarDropDown
                        open={open}
                        selected={selected}
                        showAll={showAll}
                        setDropdownValue={setDropdownValue}
                        select={select}
                        right />
                    <LayoutSwitch setselect={setselect} select={select} box />
                </Box>
            </Stack>

            {/* Amenity popup */}
            <AlertDialog open={parkingGroupDialog}
                onClose={() => setParkingGroupDialog(!parkingGroupDialog)}
                header={"Parking Group Master"}
                component={<ViewCourt type="amenity" />}
                isNormal
            />

            {/* Court popup */}
            <AlertDialog open={court}
                onClose={() => setCourt(!court)}
                header={"Court Name 1"}
                component={<ViewCourt type="court" />}
                isNormal
            />

        </Box>
    )
}