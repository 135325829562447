import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { LoadingSection, MobileNumberInputComponent, SelectBox, SelectUnitComponent, TextBox, ToggleButtonComponent } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, enumSelect, enum_types, NetWorkCallMethods, useWindowDimensions } from "../../utils";
import { intitalState, returnEditData } from "../../utils/lead";
import { LeadStylesParent } from "./style";
export const AddLead = (props) => {

  const classes = LeadStylesParent();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const [data, setData] = React.useState({ ...intitalState });
  const [loading, setLoading] = React.useState(props?.isEdit?.bool ?? false)
  const [disableButton, setDisableButton] = React.useState(false)
  const [enumValue, setEnumValue] = React.useState({
    source: [],
    purpose: [],
  });

  // update state function
  const updateState = (key, value) => {
    let error = data?.error;
    error[key] = "";
    setData({ ...data, [key]: value });
  };
  // validate transfer lead
  const validatemain = () => {
    let isValid = true;
    let error = data.error;

    if (data?.name?.length === 0) {
      isValid = false;
      error.name = "Lead Name is Required";
    }
    if (data?.email?.length === 0) {
      isValid = false;
      error.email = "Email is Required";
    }
    if (data?.mobile?.length === 0) {
      isValid = false;
      error.mobile = "Mobile Number is Required";
    }
    if (data?.leadsource?.length === 0) {
      isValid = false;
      error.leadsource = "Lead Source is Required";
    }
    if (data?.purpose?.length === 0) {
      isValid = false;
      error.purpose = "Purpose is Required";
    }

    // if (data?.selectedUnit?.filter(i => i?.is_active)?.length === 0 || data?.selectedUnit?.length === 0) {
    //   isValid = false;
    //   alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: `Select Units`,
    //   });

    // }

    setData({ ...data, error });

    return isValid;
  };
  // add and edit function
  const save = () => {

    if (validatemain()) {
      setDisableButton(true)
      const createdUnit = data?.selectedUnit?.filter((val) => val?.is_active)?.map((i) => { return { id: i?.id, property_id: data?.lead_owner ? null : i?.property_id } })

      const createdEditUnit = data?.selectedUnit?.filter((item) => !data?.fixUnit?.map((i) => i?.id)?.includes(item?.id))?.filter((item) => item?.is_active === true)?.map((x) => { return { id: x?.id, property_id: data?.lead_owner ? null : x?.property_id }; });
      const removeUnits = data?.selectedUnit?.filter((item) => data?.fixUnit?.map((i) => i?.id)?.includes(item?.id))?.filter((item) => item?.is_active === false)?.map((x) => x?.id);
      const removeDoialogUnit = data?.changeUnit?.filter((val) => val?.is_active === false)?.map(i => i?.id)


      let datas = {
        tenantId: `${config.tenantId}`,
        lead_name: data?.name ?? "",
        mobile_no: data?.mobile?.mobile,
        mobile_country_code: data?.mobile?.mobile_code ?? "",
        email: data?.email ?? "",
        lead_source: data?.leadsource?.value ?? "",
        company_id: props?.selectedCompany,
        description: data?.des?.length > 0 ? data?.des : undefined,
        purpose: data?.purpose ?? "",
        units: props?.isEdit?.bool ? createdEditUnit : createdUnit,
        unit_owner: data?.lead_owner ?? null,
        id: data?.id ?? null,
        remove_units: props?.isEdit?.bool ?
          removeUnits?.length > 0 ? removeUnits : removeDoialogUnit
          : undefined,
      };
      if (props?.isEdit?.bool === true) {
        datas["id"] = data?.id;
      } else {
        datas["status"] = "In Progress";
      }

      NetworkCall(
        `${config.api_url}/lead_proxy/upsert`,
        NetWorkCallMethods.post,
        datas,
        null,
        true,
        false
      )
        .then((response) => {
          props?.closeDrawer();
          props?.getDetails(props?.company);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `Lead ${props?.isEdit?.bool === true ? "Updated" : "Created"} successfully.`,
          });
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Internal error. Please try again later.",
          });
          setDisableButton(false)
        });
    } else {
      return false
    }
  };
  // get enum
  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.lead_source,
      enum_types?.purpose_global_type,
    ]);
    setEnumValue({
      source: result?.lead_source,
      purpose: result?.purpose_global_type,
    });
  };
  //get edit details
  const editData = () => {
    setLoading(true)
    NetworkCall(
      `${config.api_url}/lead_proxy/get`,
      NetWorkCallMethods.post,
      { id: props?.isEdit?.id },
      null,
      true,
      false
    )
      .then((res) => {
        const result = returnEditData(res?.data?.data)
        setData(result)
        setLoading(false)
      })
      .catch((err) => { });
  };
  // use effect
  React.useEffect(() => {
    if (props?.isEdit?.bool) {
      editData()
    }
    getEnum();
    // eslint-disable-next-line
  }, []);

  //onDelete
  const onDeleteUnits = (unit, owner, del) => {

    const deledUnits = data?.selectedUnit?.filter(i => i?.is_active).map((val) => {
      return {
        ...val,
        is_active: false
      }
    })
    const units = (props?.isEdit?.bool && del) ? deledUnits : deledUnits;
    setData({
      ...data,
      changeUnit: units,
      selectedUnit: unit,
      lead_owner: owner,
      property_id: null,
      edit_owner: null
    })
  }
  // onChange Unit
  const onChangeUnits = (unit, owner, del) => {
    setData({
      ...data,
      selectedUnit: unit,
      lead_owner: owner,
      property_id: null,
      edit_owner: null
    })
  }


  return (
    <Box className={classes.formRoot}>
      {/* lead Details */}
      {
        loading ?
          <Box
            sx={{
              height: size?.height - 190,
              overflow: "auto",
            }}
          >
            <LoadingSection message="Fetching Details ..." top="30vh" />
          </Box>
          :
          <Box>
            <Grid container>
              <Grid item xs={6}>
                <Box
                  p={2}
                  sx={{
                    height: size?.height - 300,
                    overflow: "auto",
                  }}
                >
                  {/* description */}

                  <TextBox
                    label="Description/Requirement"
                    placeholder="Enter Description/Requirement"
                    multiline
                    value={data?.des ?? ""}
                    onChange={(e) => {
                      updateState("des", e.target.value);
                    }}
                    isError={data?.error?.des?.length > 0}
                    errorMessage={data?.error?.des}
                  />
                  <Box height="14px" />

                  {/* lead name */}

                  <TextBox
                    isrequired
                    label="Prospect Contact Name"
                    placeholder="Enter Prospect Contact Name"
                    value={data?.name ?? ""}
                    onChange={(e) => {
                      updateState("name", e.target.value);
                    }}
                    isError={data?.error?.name?.length > 0}
                    errorMessage={data?.error?.name}
                  />
                  <Box height="14px" />

                  {/* Mobile Number */}

                  <MobileNumberInputComponent
                    xs={3}
                    label={"Mobile Number"}
                    placeholder={"Mobile Number"}
                    value={data?.mobile}
                    isRequired
                    handleChange={(value) => {
                      updateState("mobile", value);
                    }}
                    isError={data?.error?.mobile?.length > 0}
                    errorMessage={data?.error?.mobile}
                    onKeyPress={(e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    }}
                  />
                  <Box height="14px" />
                  {/* email */}

                  <TextBox
                    isrequired
                    label="Email"
                    placeholder="Enter Email"
                    value={data?.email ?? ""}
                    onChange={(e) => {
                      updateState("email", e.target.value);
                    }}
                    isError={data?.error?.email?.length > 0}
                    errorMessage={data?.error?.email}
                  />
                  <Box height="14px" />
                  {/* source */}

                  <SelectBox
                    menuPlacement="top"
                    isRequired
                    label="Lead Source"
                    placeholder="Select Lead Source"
                    options={enumValue?.source}
                    value={data?.leadsource ?? ""}
                    onChange={(value) => {
                      updateState("leadsource", value);
                    }}
                    isError={data?.error?.leadsource?.length > 0}
                    errorMessage={data?.error?.leadsource}
                  />
                  <Box height="14px" />

                  {/* purpose */}

                  <ToggleButtonComponent
                    options={enumValue?.purpose}
                    spacing={1}
                    isRequired
                    label="Purpose"
                    value={data?.purpose ?? ""}
                    onChange={(value) => {
                      updateState("purpose", value);
                    }}
                    isError={data?.error?.purpose?.length > 0}
                    errorMessage={data?.error?.purpose}
                  />

                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box
                  p={2}
                  sx={{
                    height: size?.height - 300,
                    overflow: "auto",
                  }}>
                  <SelectUnitComponent
                    datas={data}
                    onDeleteUnits={onDeleteUnits}
                    onChange={onChangeUnits}
                    value={data?.selectedUnit}
                    isEdit={props?.isEdit?.bool}
                    company={props?.selectedCompany}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
      }


      {/* end */}
      {/* button */}
      <Box className={classes.bottombtn}>

        <Grid container>
          <Grid item xs={6}>
            <Button
              disabled={disableButton}
              className={classes.next}
              onClick={save} fullWidth
            >
              {props?.isEdit?.bool === true ? "Edit" : "Create"} Lead
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
