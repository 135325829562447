import { makeStyles } from "@mui/styles";
import { Bold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    switch: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: 4,
        width: (box) => box ? "" : 75,
        color: theme.palette.border.primary,
        display: "flex",
        height: (box) => box ? "48px" : "",
        cursor: "pointer"
    },
    selectedBtn: {
        width: "32px",
        background: theme.palette.primary.main,
        borderRadius: theme.palette.borderRadius,
        color: "#fff",
        height: "32px",
        // padding: "2px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    notSelectedBtn: {
        width: "32px",
        borderRadius: theme.palette.borderRadius,
        color: theme.typography.color.tertiary,
        height: "32px",
        // padding: "2px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    icon: {
        marginTop: 2,
        marginLeft: 2
    },
    box: {
        position: "absolute",
        top: "125px",
        right: "5px"
    },
    iconSelect: {
        fontFamily: Bold,
        fontSize: "16px",
        color: "#FFFFFF",
    },
    iconUnSelect: {
        fontFamily: Bold,
        fontSize: "16px",
        color: "#98A0AC",
    },
    iconSelectBorder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "22px",
        width: "22px",
        borderRadius: "4px",
        border: "1px dashed #98B1F2"
    },
    iconUnSelectBorder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "22px",
        width: "22px",
        borderRadius: "4px",
        border: "1px dashed #CDCCCC"
    },

}))