import { FormControl, Grid, InputBase, Typography } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import React, { useState } from "react";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { GET_CURRENCY } from "../../graphql/propertyMasterQuery";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { SimpleSelect } from "./select";
const BootstrapInput = withStyles((theme) => ({
  root: {
    width: "100%",
  },
  input: {
    position: "relative",
    width: "100%",
    marginLeft: "4px",
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    marginBottom: 0,
  },
  wrapper: {
    border: `1.5px solid ${theme.palette.border.primary}`,
    borderRadius: 8,
    height: 48,

    alignItems: "center",
  },
  label: {
    color: theme.typography.color.tertiary,
    fontSize: "12px",
  },
}));

export const CurrencyInputComponent = (props) => {
  const classes = useStyles();


  const [currencyCode,setCurrency] = useState()

  React.useEffect(()=>{
    const payload = {
      query: GET_CURRENCY,
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        setCurrency(main?.currency_master?.filter(({symbol,id})=>((symbol && id)))?.map(({symbol,id})=>({label:symbol,value:id})))
      })
      .catch((error) => {
   alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please fill all mandatory field",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
             });
      });
     // eslint-disable-next-line
  },[])
  const alert = React.useContext(AlertContext);
  const [selectValue, setSelectValue] = React.useState({
    currency: props?.value?.currency ?? "",
    currency_code:props?.value?.currency_code ?? "$",
  });

  const handleChangeSelect = (currency_code={}) => {
    let newState = {
      currency: selectValue.currency,
      currency_code,
    };

    setSelectValue(newState);
    props?.onChange(newState);
  };

  const handleChange = (currency) => {
      let newState = {
        currency,
        currency_code: selectValue.currency_code,
      };
      setSelectValue(newState);
      props?.onChange(newState);
  };


  return (
    <div className={classes.root} noValidate>
      {/* Input Label */}
      <div style={{ display: "flex" }}>
        {
          <Typography
            variant="body1"
            color="textsecondary"
            className={classes.label}
            align="left"
            gutterBottom
          >
            {props?.label}
          </Typography>
        }
        {props?.isRequired && (
          <Typography color="error" variant="caption">
            &nbsp;*
          </Typography>
        )}
      </div>

      <FormControl>
        <div>
          <Grid container className={classes.wrapper}>
            {/* Mobile No Country Code Select */}
            <Grid item xs={3}>
              <div style={{ borderRight: "1px solid #c1c1c1" }}>
                <SimpleSelect
                  className={classes.select}
                  options={currencyCode}
                  handleChangeSelect={handleChangeSelect}
                  value={selectValue?.currency_code ?? "$"}
                  disabled={props.isReadonly || props.disabled}
                  errorValidation={props?.errorValidation}
                  isCurrency={props?.isCurrency}
                />
              </div>
            </Grid>

            {/* Mobile Number Input Field */}
            <Grid item xs={6}>
              <BootstrapInput
                type={"number"}
                autoFocus={props.autoFocus ? true : false}
                disabled={(props?.isReadonly || props?.disabled) ?? false}
                onInvalid={(e) => {
                  e.preventDefault();
                }}
                placeholder={props.placeholder}
                onChange={(e) => handleChange(e.target.value)}
                value={selectValue?.currency}
                error={
                  props?.errorValidation && props?.errorValidation?.error
                    ? props?.errorValidation?.error
                    : false
                }
                onKeyPress={(e) =>
                  props.enterKeyPress ? props.enterKeyPress(e) : ""
                }
              />
            </Grid>
          </Grid>
        </div>
      </FormControl>
    </div>
  );
};
