import React from "react";
import {
  BarChart,
  Bar,
  // CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../../utils";

const useStyles = makeStyles((theme, props) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-20px",
    "& svg": {
      // borderRadius: "50%",
      width: "100%",
      minWidth: (props) => props?.height ?? "165px !important",
      height: (props) => props?.height ?? "165px !important",
      position: "relative",
      cursor: "pointer",
      [theme.breakpoints.up("sm")]: {
        width: "100% !important",
        margin: "0 auto",
      },
      "& .recharts-wrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  center: {
    textAlign: "center",
    position: "absolute",
    left: "0px",
    right: "0px",
    top: "36%",
    zIndex: 1,
    padding: "12px",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      right: "0px",
      top: "36%",
    },
  },
  chart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "12px",
    color: "#4E5A6B",
    fontFamily: SemiBold,
  },
  count: {
    fontSize: "18px",
    color: theme.typography.color.primary,
    fontFamily: Bold,
  },
  tooltip: {
    backgroundColor: "#F2F4F7",
    border: "1px solid #E4E8EE",
    display: "flex",
    alignItems: "center",
    padding: "4px 7px",
    zIndex: 999,
    borderRadius: "4px",
    position: "relative",
  },
  name: {
    color: "#7C8594",
    fontSize: "12px",
    fontFamily: SemiBold,
  },
  value: {
    color: "#4E5A6B",
    fontSize: "16px",
    fontFamily: SemiBold,
    marginRight: "8px",
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginRight: "8px",
  },
  // arrow: {
  //   width: "0px",
  //   height: "0px",
  //   borderLeft:'10px solid transparent',
  //   borderRight:'10px solid transparent',
  //   borderTop:'15px solid #F2F4F7'
  // },
  // arrowParent: {
  //   position: "absolute",
  //   bottom: "-12px",
  //   textAlign:'center',
  //   left:0,
  //   display:'flex',
  //   justifyContent:'center',
  //   alignItems:'center',
  //   width:'100%'
  // },
}));

// tool tip
const Tip = ({ setShowTooltip, fill, ...rest }) => {
  const classes = useStyles();
  const [payload, setPayload] = React.useState(rest?.payload);

  React.useEffect(() => {
    rest?.payload?.length && setPayload(rest?.payload);
  }, [rest?.payload]);

  return payload?.length ? (
    <div
      onMouseLeave={() => setShowTooltip(false)}
      onMouseMove={(e) => e.stopPropagation()}
      className={classes.tooltip}
    >
      <Box
        className={classes.dot}
        style={{ backgroundColor: fill ?? "#FF9340" }}
      />
      <Typography className={classes.value}>
        {payload?.[0]?.payload?.value}
      </Typography>
      <Typography className={classes.name}>
        {payload?.[0]?.payload?.name}
      </Typography>
    </div>
  ) : null;
};

export const BarChartComponent = (props) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <ResponsiveContainer>
      {props?.isHorzontal ? (
        <BarChart
          data={props?.data}
          margin={{ top: 0, right: 45, left: 40, bottom: 20 }}
          layout="vertical"
          onMouseLeave={() => setShowTooltip(false)}
          barGap={10}
          barCategoryGap={16}
        >
          <XAxis type="number" allowDecimals={false} />
          <YAxis type="category" dataKey={"name"} width={40} minTickGap={0} allowDecimals={false} />
          {showTooltip && (
            <Tooltip
              // Anymation is a bit weird when the tooltip shows up after hidding
              isAnimationActive={false}
              // Send props down to get the payload
              content={
                <Tip
                  setShowTooltip={setShowTooltip}
                  fill={props?.fill ?? "#FF9340"}
                />
              }
              // We need this to manage visibility ourselves
              wrapperStyle={{ visibility: "visible", pointerEvents: "auto" }}
            />
          )}
          <Bar
            dataKey="value"
            animationDuration={false}
            label={{ position: "right", backgroundColor: "#fff" }}
            fill={props?.fill ?? "#FF9340"}
            onMouseEnter={() => setShowTooltip(true)}
            barSize={props?.barSize ?? 38}

          />
        </BarChart>
      ) : (
        <BarChart data={props?.data}>
          <Bar dataKey={props?.barKey ?? "bar"} fill={props?.fill ?? "#FF9340"} />
          <Tooltip />
          <XAxis dataKey={props?.XAxisKey ?? "x"} />
          <YAxis dataKey={props?.yAxisKey ?? "y"} />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};
