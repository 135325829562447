import { useApolloClient } from "@apollo/client";
import { Badge, Box, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import FilterIMG from "../../assets/filter";
import { FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../components";
import { UPDATE_FLOOR } from "../../graphql/mutations";
import { GET_FLOOR_LIST, GET_FLOOR_LIST_BY_PROPERTY } from "../../graphql/queries";
import { Routes } from "../../router/routes";
import { FloorHeadingNew, FloorPath } from "../../utils/block";
import { floorStyles } from "./style";
import { accessCheckRender, getCustomFloorName, getRoutePermissionNew, LocalStorageKeys } from '../../utils'
import { AuthContext } from "../../contexts";

export const FloorList = (props) => {
    const classes = floorStyles();
    const history = useHistory()
    const client = useApolloClient();
    const search = useLocation().search;
    const [floorlist, setFloorList] = React.useState({
        data: [],
        count: 0,
        property: {},
        block: {}
    })

    const [drawer, setDrawer] = React.useState(false);
    const [loading, setLoading] = React.useState(true)
    const [searchText, setSearchText] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [property, setProperty] = React.useState(null);
    const blockID = new URLSearchParams(search).get("blockID");
    const propertyID = new URLSearchParams(search).get("propertyID");
    const [filterData, setFilterData] = React.useState({ status: [true] });
    const debounce = UseDebounce();
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getfloorlist(propertyID ? propertyID : blockID, null, "", 0, limit)
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // eslint-disable-next-line
    const headerpage = [
        {
            id: "1",
            label: floorlist?.block?.name,
            type: "name"
        },
        {
            id: "2",
            label: getCustomFloorName(floorlist?.property?.property_hierarchyByID)
        }
    ]
    const headerpage1 = [
        {
            id: "2",
            label: getCustomFloorName(floorlist?.property?.property_hierarchyByID)
        }
    ]

    const getfloorlist = async (blockid, filterProp, searchText, offSet, limits) => {

        let active = filterProp?.status?.length ? filterProp?.status : [true, false];

        await client.query({
            query: propertyID ? GET_FLOOR_LIST_BY_PROPERTY : GET_FLOOR_LIST,
            fetchPolicy: 'network-only',
            variables: {
                id: blockid,
                active,
                searchText: searchText,
                offset: offSet,
                limit: limits,
                client: localStorage.getItem(LocalStorageKeys.clinetID)
            }
        }).then((res) => {
            setProperty(res?.data)
            const list = res?.data.floor?.map((val) => {
                let _d;
                try {
                    _d = {
                        main: val,
                        floorNo: val?.floor_no,
                        propertyName: val?.property?.name,
                        floorName: val?.name,
                        companyname: val?.property?.company?.name,
                        location: val?.property?.address?.city ? val?.property?.address?.city : val?.property?.address?.district,
                        blockname: val?.block?.name,
                        unit: val?.unit?.length,
                        id: val?.id,
                        propertytype: val?.property?.property_group_master?.group_name,
                        revenuetype: val?.property?.revenue_type,
                        status: val?.is_active ? "Active" : "Inactive",
                        icon: "editview",
                    };
                } catch (err) {

                }
                return _d;
            })
            if (propertyID) {
                setFloorList({
                    count: res.data.count[0]?.count,
                    data: list,
                    property: res.data.property[0]
                })
            } else {
                setFloorList({
                    count: res.data.count[0]?.count,
                    data: list,
                    property: res.data.block[0]?.property,
                    block: res?.data?.block?.[0]
                })
            }
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)

    }
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getfloorlist(propertyID ? propertyID : blockID, filterData, e, 0, limit)

    }
    const handleOnClickAddPropertyButton = () => {
        
        history.push({
            pathname: Routes.createFloorNew,
            state: {
                main: {
                    blockID: blockID,
                    propertyID: floorlist?.property?.id ? floorlist?.property?.id : propertyID,
                }
            }
        })
    }
    const changeactive = async (data, value) => {
        let result = await client.mutate({
            mutation: UPDATE_FLOOR,
            variables: {
                ID: data,
                updatePayload: {
                    is_active: value === "Inactive" ? true : false
                }
            }
        })
        if (result) {
            getfloorlist(propertyID ? propertyID : blockID, filterData, "", 0, limit)
        }

    }
    const handleIcon = (type, data) => {
        console.log(type, data)
        if (type === "active") {
            changeactive(data?.id, data?.status)
        }

        if (type === "edit") {
            history.push({
                pathname: Routes.createFloorNew,
                state: {
                    edit: {
                        blockID: property?.floor?.[0]?.block_id,
                        floorID: data?.id,
                        propertyID: floorlist?.property?.id,
                        isEdit: true
                    }
                }
            })
        }

        if (type === "view") {
            history.push(Routes.floorDetails + "?companyID=" + data?.companyID + "&floorID=" + data?.id + "&name=" + data?.floorName)
        }
    }
    const onUnitClick = (value, type) => {
        history.push(Routes.unit + "?floorID=" + value?.id)
    }
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getfloorlist(propertyID ? propertyID : blockID, filterData, searchText, offset, limit)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getfloorlist(propertyID ? propertyID : blockID, filterData, searchText, 0, value)
    }
    const goBack = () => {
        history.goBack()
    }

    const onApplyFilter = (value) => {
        setFilterData(value)
        getfloorlist(propertyID ? propertyID : blockID, value, "", 0, limit)
    }
    const render = () => {
        return <>
            {
                loading ? <LoadingSection bottom={"45vh"} message="Loading Floor Listing..." />
                    :
                    <div className={classes.root}>
                        <Subheader arrow pageoptions={propertyID ? headerpage1 : headerpage} title={floorlist?.property?.name} goBack={goBack} />
                        <Box p={2} m={2} className={classes.table}>
                            <Grid container >
                                <Grid item xs={4}>
                                    <SearchFilter value={searchText} placeholder="Search Properties" handleChange={(value) => handleSearch(value)} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Box
                                        display={"flex"}
                                        sx={{ float: "right" }}
                                    >
                                        <Stack
                                            direction="row"
                                            divider={<Divider orientation="vertical" flexItem />}
                                            spacing={2}
                                        >
                                            {filterData?.status?.length > 0 ? (
                                                <IconButton onClick={() => setDrawer(true)} className={classes.img}>
                                                    <Badge variant="dot" color="primary">
                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton>
                                            ) : (
                                                <IconButton onClick={() => setDrawer(true)} className={classes.img}>
                                                    <FilterIMG color="#091b29" />
                                                </IconButton>)}
                                            {(permission?.create &&
                                                floorlist?.property?.property_hierarchyByID.is_floor) &&
                                                <Button variant="contained" className={classes.btn} onClick={handleOnClickAddPropertyButton}>
                                                    {"Add " + getCustomFloorName(floorlist?.property?.property_hierarchyByID)}
                                                </Button>
                                            }

                                        </Stack>
                                    </Box>
                                </Grid>

                            </Grid>
                            <TableWithPagination
                                heading={FloorHeadingNew(floorlist?.property?.property_hierarchyByID)}
                                rows={floorlist?.data}
                                path={FloorPath}
                                showpagination={true}
                                count="2"
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleIcon}
                                tableType="no-side"
                                onClick={() => console.log("")}
                                onUnitClick={permission?.read ? onUnitClick : false}
                                dataType={[
                                    { type: ["text"], name: "floorNo" },
                                    { type: ["text"], name: "floorName" },
                                    { type: ["text"], name: "companyname" },
                                    { type: ["description"], name: "location" },
                                    { type: ["test"], name: "blockname" },
                                    { type: ["unit"], name: "unit" },
                                    { type: ["text"], name: "revenuetype" },
                                    { type: ["propertyType"], name: "propertytype" },
                                    { type: ["status"], name: "status" },
                                    { type: ["more"], icon: "icon" },
                                ]}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={floorlist?.count}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 300px)'}
                                view={permission?.read}
                                edit={permission?.update}
                                delete={permission?.delete} />
                        </Box>
                    </div>
            }
            {/* filter drawer */}
            {
                drawer &&
                <FilterGenerator
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: [
                                { label: 'Active', value: true },
                                { label: 'Inactive', value: false },
                            ],
                            isMulti: true,
                            state_name: "status",
                            label: "Status",
                            // required:true
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            }
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};
