
export const YetToReceivePath = ["requestor_image", "reference_id", "name", "unit_id", "entry_gate", "expected_date_time", "notes"];

export const YetToReceiveHeading = [
    { title: "Requestor", field: "requestor_image" },
    { title: "Reference ID", field: "reference_id" },
    { title: "Name", field: "name" },
    { title: "Unit ID", field: "unit_id" },
    { title: "Entry Gate", field: "entry_gate" },
    { title: "Expected Date And Time", field: "expected_date_time" },
    { title: "Notes", field: "notes" },
];
export const YetToReceiveType = [
    { type: ["logo"], name: "requestor_image" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "entry_gate" },
    { type: ["text"], name: "expected_date_time" },
    { type: ["text"], name: "notes" },
];


export const ReceivedAtGatePath = ["delivery_item_image", "delivery_person_image", "date", "reference_id", "name", "received_gate", "received_date_time", "registered"];

export const ReceivedAtGateHeading = [
    { title: "Delivery Item", field: "delivery_item_image" },
    { title: "Delivery Person", field: "delivery_person_image" },
    { title: "Date", field: "date" },
    { title: "Reference ID", field: "reference_id" },
    { title: "Name", field: "name" },
    { title: "Unit ID", field: "unit_id" },
    { title: "Received Gate", field: "received_gate" },
    { title: "Received Date And Time", field: "received_date_time" },
    // { title: "Registered", field: "registered" },
];
export const ReceivedAtGateType = [
    { type: ["logo"], name: "delivery_item_image" },
    { type: ["logo"], name: "delivery_person_image" },
    { type: ["text"], name: "date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "received_gate" },
    { type: ["text"], name: "received_date_time" },
    { type: ["text"], name: "registered" },
];

export const CollectedPath = ["collected_person_image", "date", "reference_id", "collected_by_name", "unit_id", "received_gate", "received_date_time", "collected_by"];

export const CollectedHeading = [
    { title: "Collected Person", field: "collected_person_image" },
    { title: "Date", field: "date" },
    { title: "Reference ID", field: "reference_id" },
    { title: "Collected By Name", field: "collected_by_name" },
    { title: "Unit ID", field: "unit_id" },
    { title: "Received Gate", field: "received_gate" },
    { title: "Received Date And Time", field: "received_date_time" },
    { title: "Collected By", field: "collected_by" },
];
export const CollectedType = [
    { type: ["logo"], name: "collected_person_image" },
    { type: ["text"], name: "date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "collected_by_name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "received_gate" },
    { type: ["text"], name: "received_date_time" },
    { type: ["text"], name: "collected_by" },
];
