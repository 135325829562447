import makeStyles from "@mui/styles/makeStyles";
export const LeadStylesParent = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: 99,
    right: 124,
    display: "flex",
    alignItems: "center",
    background: '#5078E1',
    boxShadow: "none",
    "&:hover": {
      background: '#5078E1',
    },
  },
  sumbitidea: {
    color: "#fff",
    marginLeft: "10px",
    fontSize: 14,
  },
}));
