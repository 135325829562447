export const JobsPath = ["name", "description", "status"]

export const JobsHeading = [
    { title: "Name", field: "name" },
    { title: "Notes", field: "description" },
    { title: "Status", field: "status", },
    { title: "", field: "icon" },
]

export const JobsType = [
    { type: ["text"], name: "name" },
    { type: ["text"], name: "description" },
    { type: ["status"], name: "status" },
    { type: ["more"], icon: "icon" },
]

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]