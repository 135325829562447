import { makeStyles } from "@mui/styles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.tertiary1,
        border: `1px solid ${theme.palette.border.secondary}`,
        boxShadow: "0px 0px 16px #00000014",
        minHeight: `calc(100vh - 270px)`,
    },
    content: {
        height: `calc(100vh - 100px)`,
        overflow: "auto"
    },
    accoCardTitle: {
        fontWeight: "bold",
        fontSize: 16
    },
    accoCardButton: {
        fontSize: 14,
        fontWeight: "normal",
        '&:hover': {
            color: "#fff"
        }
    },
    cancelButton: {
        fontSize: 14,
        backgroundColor: theme.typography.color.white,
        color: theme.palette.primary.main,
        marginRight: "10px",
        border: `1px solid ${theme.palette.border.secondary}`,
        '&:hover': {
            backgroundColor: theme.typography.color.white,

        }
    },
    noData: {
        textAlign: "center",
        padding: "20px",
        fontSize: "16px",
        fontFamily: SemiBold,
        color: theme.typography.color.tertiary
    },
    completeInsp: {
        padding: "30px"
    },
    nobtn: {
        '&:hover': {
            background: "#fff"
        }
    },
    msg: {
        fontSize: "16px",
        fontFamily: SemiBold
    },
    iconbtn: {
        border: `1px solid ${theme.palette.border.secondary}`,
        backgroundColor: theme.palette.border.secondary,
        padding: "8px",
        borderRadius: "50%",
        height: "24px",
        width: "24px",
        cursor: "pointer"
    },
    icon: {
        fontSize: "14px",
        marginLeft: "-2px",
    },
    dialoghdrtext:{
        fontSize:"16px",
        color:theme.typography.color.primary,
        fontFamily: Bold,
    }

}))