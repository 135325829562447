import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  srctypebox: {
    backgroundColor: theme?.palette?.background?.light,
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    display:"flex",
    justifyContent:"space-between",
    alignItems:'center'
  },
  arrowIcon: {
    color: theme?.typography?.status?.tertiary ?? "",
  },
  menu: {
    "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
      {
        borderRadius: theme.palette.borderRadius,
        padding: "0px !important",
        boxShadow: "0px 8px 24px #0717411F",
        color: theme?.typography?.status?.tertiary ?? "",
        height:'147px',
        overFlow:"auto"
      },
    "& .MuiMenu-list": {
      padding: "0 !important",
      color: theme?.typography?.status?.tertiary ?? "",
      display:"block !important"
    },
  },
  srcText: {
    color: theme?.typography?.status?.tertiary,
    fontSize: "14px",
    fontFamily: Bold,
  },
  flexBox:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:'center'
  },
  flexBox1:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:'center'
  }
}));
