import React from "react";
import { withNavBars } from "../../../HOCs";
import { UtilitiesMappingPropertyView } from "./utilitiesMappingPropertyView";

class UtilitiesMappingPropertyViewParent extends React.Component {
    render() {
        return <UtilitiesMappingPropertyView />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(UtilitiesMappingPropertyViewParent, props);
