import * as React from "react"

export const WelcomePageIcon = (props) => (
    <svg
        width={278}
        height={197.77}
        viewBox="0 0 290 220"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_52951" data-name="Rectangle 52951" width="278" height="197.772" fill="none" />
            </clipPath>
            <clipPath id="clip-path-2">
                <rect id="Rectangle_52950" data-name="Rectangle 52950" width="278" height="197.771" fill="none" />
            </clipPath>
            <clipPath id="clip-path-3">
                <rect id="Rectangle_52946" data-name="Rectangle 52946" width="274.321" height="197.441" fill="none" />
            </clipPath>
        </defs>
        <g id="Group_99526" data-name="Group 99526" clip-path="url(#clip-path)">
            <g id="Group_99525" data-name="Group 99525" transform="translate(0 0.001)">
                <g id="Group_99524" data-name="Group 99524" clip-path="url(#clip-path-2)">
                    <g id="Group_99523" data-name="Group 99523" transform="translate(1.84 -0.001)" opacity="0.12">
                        <g id="Group_99522" data-name="Group 99522" transform="translate(0 0)">
                            <g id="Group_99521" data-name="Group 99521" clip-path="url(#clip-path-3)">
                                <path id="Path_95425" data-name="Path 95425" d="M37.63,197.441C14.623,173.09,1.229,137.523.893,104.024c-.145-14.5,2.25-29.492,10.15-41.65S33.2,41.492,47.6,43.195c14.107,1.67,25.685,12.529,39.674,15,17.276,3.053,34.09-7.251,47.032-19.1s24.434-25.96,40.279-33.491C195.539-4.348,221.669-.538,240.505,13s30.515,35.735,33.711,58.709-1.572,46.595-10.9,67.833-23.051,40.283-38.139,57.9" transform="translate(-0.887 0)" fill="#5078e1" />
                            </g>
                        </g>
                    </g>
                    <path id="Path_95426" data-name="Path 95426" d="M67.646,25.523C69.2,31.455,65.921,37.8,61.186,41.692S50.48,47.576,44.693,49.607s-11.712,4.289-16.1,8.568c-7.359,7.164-8.8,19.265-4.345,28.518s14.06,15.495,24.21,17.055,20.676-1.217,29.49-6.487,16.028-12.9,21.982-21.269c4.77,10.329,13.2,20.593,24.469,22.155s23.395-3.681,29.36-13.371,4.961-23.36-2.881-31.6c-4.022-4.227-9.387-6.871-14.663-9.356s-10.692-4.965-14.939-8.964-7.193-9.885-6.118-15.619" transform="translate(23.328 20.656)" />
                    <path id="Path_95427" data-name="Path 95427" d="M99.919,26.28a8.812,8.812,0,1,1-8.812-8.812,8.812,8.812,0,0,1,8.812,8.812" transform="translate(88.384 18.76)" fill="#6987f3" />
                    <path id="Path_95428" data-name="Path 95428" d="M99.418,42.7a11.966,11.966,0,1,1,11.965-11.965A11.979,11.979,0,0,1,99.418,42.7m0-22.636a10.67,10.67,0,1,0,10.669,10.671A10.681,10.681,0,0,0,99.418,20.061" transform="translate(93.925 20.153)" />
                    <path id="Path_95429" data-name="Path 95429" d="M38.062,38.4l-18.4-9.941L44.191,13.153ZM22.244,28.38,37.2,36.463l4.982-20.529Z" transform="translate(21.121 14.126)" />
                    <path id="Path_95430" data-name="Path 95430" d="M81.829,78.158,47.137,76.215l9.516-44.263L80.641,33.3l.141,5.276.427,16.235Z" transform="translate(50.626 34.315)" fill="#b77548" />
                    <path id="Path_95431" data-name="Path 95431" d="M75.155,51.379A27.363,27.363,0,0,1,52.774,37.587c7.066-.705,14.095-1.674,21.161-2.377.261-.027.525-.052.792-.066Z" transform="translate(56.68 37.744)" />
                    <path id="Path_95432" data-name="Path 95432" d="M109.405,52.933A27.114,27.114,0,0,1,94.464,72.34,27.712,27.712,0,0,1,69.937,71.6a28.313,28.313,0,0,1-14.2-20.056c-1.86,3.246-5.5,4.208-9.144,3.366A11.177,11.177,0,0,1,38.8,47.528a11.452,11.452,0,0,1,.346-8.335,9.652,9.652,0,0,1,6.295-5.353c2.646-.66,5.76-.309,7.771,1.5l.272.261c.008-.7.039-1.4.1-2.1a31.682,31.682,0,0,1,7.205-17.942A27.653,27.653,0,0,1,79.525,5.8l.06,0a25.174,25.174,0,0,1,2.563-.044c.228.008.454.017.678.037.261.01.519.035.776.06.46.044.923.1,1.383.172.12.017.241.031.361.056a23.964,23.964,0,0,1,13.821,7.371,26.956,26.956,0,0,1,4.227,6.382c.232.481.452.966.666,1.456a58.113,58.113,0,0,1,2.725,8.186c.311,1.176.6,2.356.873,3.528,1.545,6.533,2.922,13.315,1.746,19.929" transform="translate(41.006 6.165)" fill="#b77548" />
                    <path id="Path_95433" data-name="Path 95433" d="M47.515,30.013a4.741,4.741,0,0,0-6.282-2.022l-.572-1.164a6.022,6.022,0,0,1,6.631.863l-.4-5.5,1.292-.093.55,7.562Z" transform="translate(43.669 23.734)" />
                    <path id="Path_95434" data-name="Path 95434" d="M63.092,35.166A2.383,2.383,0,0,1,62.219,35l.477-1.205a1.28,1.28,0,0,0,1.526-.608,4.017,4.017,0,0,0-.033-1.717L62.561,21.029l1.28-.2,1.63,10.443a5.167,5.167,0,0,1-.006,2.29A2.382,2.382,0,0,1,64.208,34.9a2.537,2.537,0,0,1-1.116.263" transform="translate(66.824 22.369)" />
                    <path id="Path_95435" data-name="Path 95435" d="M63.8,33.2a5.679,5.679,0,0,1-5.708-5.685h1.3a4.387,4.387,0,0,0,4.832,4.368l.133,1.29a5.5,5.5,0,0,1-.554.027" transform="translate(62.387 29.547)" />
                    <path id="Path_95436" data-name="Path 95436" d="M68.642,22.215c-.052.917-.589,1.632-1.2,1.6s-1.064-.807-1.014-1.724.589-1.632,1.2-1.6,1.066.807,1.014,1.723" transform="translate(71.339 22.006)" />
                    <path id="Path_95437" data-name="Path 95437" d="M59.483,22.739c-.052.917-.589,1.632-1.2,1.6s-1.064-.807-1.014-1.724.589-1.632,1.2-1.6,1.066.807,1.014,1.724" transform="translate(61.502 22.569)" />
                    <path id="Path_95438" data-name="Path 95438" d="M70.366,20.164a6.57,6.57,0,0,0,1.865-.037c1.022-.313.987-1.089.01-1.58a10.225,10.225,0,0,0-3.615-.722A5.594,5.594,0,0,0,66,18.071a.886.886,0,0,0-.29,1.466c.985,1.1,3.2.583,4.654.626" transform="translate(70.28 19.101)" />
                    <path id="Path_95439" data-name="Path 95439" d="M57.922,20.619a6.6,6.6,0,0,1-1.862.085c-1.041-.245-1.056-1.02-.114-1.574a10.172,10.172,0,0,1,3.559-.96,5.588,5.588,0,0,1,2.634.075.884.884,0,0,1,.386,1.444c-.91,1.159-3.157.792-4.6.931" transform="translate(59.348 19.385)" />
                    <path id="Path_95440" data-name="Path 95440" d="M35.5,40.43l-5.135.7L35.108,42.5c-.11.473-.23.935-.363,1.381a21.226,21.226,0,0,1-5.515,9.48,14.706,14.706,0,0,1-10.758,3.849,22.322,22.322,0,0,1,17.9-25.017c-.251,2.561-.425,5.5-.871,8.24" transform="translate(19.615 34.571)" fill="#6987f3" />
                    <path id="Path_95441" data-name="Path 95441" d="M42.974,46.478A37.27,37.27,0,0,1,35.2,60.042a16.463,16.463,0,0,1-4.264,3.366L28.526,59.13l.5,5.177a12.993,12.993,0,0,1-8.906.4A19.5,19.5,0,0,1,42.974,46.478" transform="translate(21.607 49.614)" fill="#ff8a5b" />
                    <path id="Path_95442" data-name="Path 95442" d="M17.191,86.393l-1.3-.037A48.463,48.463,0,0,1,34.771,49.534l.786,1.029A47.166,47.166,0,0,0,17.191,86.393" transform="translate(17.071 53.199)" />
                    <path id="Path_95443" data-name="Path 95443" d="M18.837,98.8C9.609,81.942,7.971,69.3,12.573,50.5l1.261.309c-4.281,17.478-3.059,29.526,4.785,44.813a103.967,103.967,0,0,1,15.7-58.344l1.1.689a102.588,102.588,0,0,0-15.366,60.48Z" transform="translate(10.838 40.038)" />
                    <path id="Path_95444" data-name="Path 95444" d="M64.5,96.2c0,15.547-14.439,28.151-32.249,28.151S0,111.749,0,96.2,14.439,68.052,32.249,68.052,64.5,80.656,64.5,96.2" transform="translate(0 73.088)" fill="#dacc3e" />
                    <rect id="Rectangle_52947" data-name="Rectangle 52947" width="13.666" height="12.299" transform="translate(25.417 135.128)" fill="#dacc3e" />
                    <path id="Path_95445" data-name="Path 95445" d="M77.423,6.081c0,.338-.017.67-.039,1.008A29.755,29.755,0,0,1,67.6,27.4C61,33.253,54.167,35.97,45.56,35.6c.008-.7.039-1.4.1-2.1a31.694,31.694,0,0,1,7.2-17.942A27.646,27.646,0,0,1,71.6,5.805l.06,0a25.226,25.226,0,0,1,2.566-.046c.228.008.452.017.678.037.261.012.519.035.774.06.463.044.923.1,1.385.172.12.017.241.031.361.056" transform="translate(48.932 6.165)" />
                    <path id="Path_95446" data-name="Path 95446" d="M79.823,21.145a20.971,20.971,0,0,1-11.546-4.113,20.985,20.985,0,0,1-7.207-10.09c-.116-.353-.224-.705-.321-1.064.12.017.241.033.363.058A23.965,23.965,0,0,1,74.93,13.307a26.955,26.955,0,0,1,4.227,6.382c.232.481.452.966.666,1.456" transform="translate(65.244 6.312)" />
                    <path id="Path_95447" data-name="Path 95447" d="M11.012,148.4s30.1-87.083,44.946-93.9,32.865-6.425,32.865-6.425S110,72.771,120.3,50.992c0,0,30.768-.913,36.789,2.7S177.553,77.77,177.553,77.77l24.48-32.106,36.117,14.448-36,88.274" transform="translate(11.827 49.043)" fill="#bee9e8" />
                    <path id="Path_95448" data-name="Path 95448" d="M157.67,84.655H62.324l38.139,21.866h95.346Z" transform="translate(66.936 90.919)" fill="#5078e1" />
                    <rect id="Rectangle_52948" data-name="Rectangle 52948" width="1.297" height="18.198" transform="translate(153.019 181.196) rotate(-60.174)" />
                    <path id="Path_95449" data-name="Path 95449" d="M191.314,62.22H95.968L80.713,130.615H176.06Z" transform="translate(86.687 66.824)" fill="#5078e1" />
                    <rect id="Rectangle_52949" data-name="Rectangle 52949" width="70.075" height="1.296" transform="translate(166.769 197.299) rotate(-77.431)" />
                    <path id="Path_95450" data-name="Path 95450" d="M120.619,83.226A8.724,8.724,0,1,1,111.9,74.5a8.723,8.723,0,0,1,8.723,8.723" transform="translate(110.805 80.016)" fill="#fff" />
                    <path id="Path_95451" data-name="Path 95451" d="M107.555,84.437c-.112,0-.226,0-.338-.01l.073-1.294a4.56,4.56,0,0,0,4.814-4.3l1.3.075a5.864,5.864,0,0,1-5.845,5.529" transform="translate(115.152 84.666)" />
                    <path id="Path_95452" data-name="Path 95452" d="M103.284,111.164a47.819,47.819,0,0,1-6.021,9.874H45.146c-3.213-4.077-6.448-8.313-7.817-13.315-1.406-5.139-.336-11.347,3.957-14.5a9.962,9.962,0,0,1,15.856,7.666c.7-8.188,3.723-16.376,9.516-22.208s14.566-8.956,22.528-6.911c7.695,1.977,13.763,8.568,16.1,16.161s1.24,15.984-2,23.237" transform="translate(39.465 76.403)" fill="#fff" />
                    <path id="Path_95453" data-name="Path 95453" d="M83.171,110.842c-.139-.151-14.047-15.02-23.557-12.349-3.959,1.112-6.678,5.2-8.078,12.145l-1.251.071c-.1-.3-9.995-30.038-30.878-28.41l-.1-1.292C28.4,80.3,36.864,85.165,43.781,95.079a66.1,66.1,0,0,1,6.977,13.17c1.628-6.176,4.482-9.874,8.506-11,4.353-1.222,9.874.564,16.414,5.312a64.965,64.965,0,0,1,8.445,7.406Z" transform="translate(20.737 86.929)" />
                    <path id="Path_95454" data-name="Path 95454" d="M139.23,66.345l4.413-20.869-8.024-5.616L111.54,42.268l-8.427,9.63Z" transform="translate(110.745 42.809)" fill="#b77548" />
                    <path id="Path_95455" data-name="Path 95455" d="M103.113,65.229S119.57,25.7,125.587,27.507c4.011,1.2-1.605,18.861-1.605,18.861s12.038-18.058,16.453-15.25S130.8,52.789,130.8,52.789s8.829-8.026,12.44.8,1.1,13.927-3.211,11.235C136.822,62.821,135.217,56,135.217,56" transform="translate(110.744 29.478)" fill="#b77548" />
                    <path id="Path_95456" data-name="Path 95456" d="M128.053,55.143a4.7,4.7,0,0,1-2.476-.823c-3.4-2.124-5.036-8.941-5.1-9.229l1.263-.3c.015.064,1.582,6.585,4.528,8.427.823.514,1.987.975,2.858.241,1.352-1.141,1.746-4.969-.591-10.681-.844-2.064-2.049-3.285-3.584-3.634-3.513-.8-7.777,3.026-7.821,3.065l-.871-.96c.195-.176,4.8-4.312,8.976-3.37,1.972.448,3.486,1.929,4.5,4.407,2.283,5.581,2.371,10.356.226,12.164a2.918,2.918,0,0,1-1.9.691" transform="translate(124.858 40.534)" />
                    <path id="Path_95457" data-name="Path 95457" d="M113.993,45.317l-1.078-.72a105.84,105.84,0,0,1,6.618-8.74c5.038-5.89,8.547-8.08,10.723-6.7l-.7,1.1c-.684-.438-2.941-.684-9.034,6.438a103.74,103.74,0,0,0-6.531,8.624" transform="translate(121.272 30.887)" />
                    <path id="Path_95458" data-name="Path 95458" d="M104.022,65.818l-1.2-.5c.041-.1,4.179-10.015,9-19.581,8.943-17.747,12.419-19.064,14.259-18.515l-.373,1.242c-.761-.228-3.924.388-12.724,17.849-4.8,9.526-8.922,19.4-8.964,19.5" transform="translate(110.435 29.137)" />
                    <path id="Path_95459" data-name="Path 95459" d="M117.988,55.944l-1.066-.738a3.7,3.7,0,0,0-.888-4.893l.251-1.17c4.447-.836,11.361-3.016,10.982-5.307a1.326,1.326,0,0,0-.674-.927c-2.966-1.883-13.647.143-20.1,1.966l-.353-1.246c.678-.193,16.671-4.658,21.151-1.815a2.609,2.609,0,0,1,1.259,1.811c.6,3.642-7.334,5.786-10.808,6.546a5.045,5.045,0,0,1,.249,5.774" transform="translate(113.992 43.893)" />
                </g>
            </g>
        </g>
    </svg>
)

