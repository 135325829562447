import { useApolloClient } from "@apollo/client";
import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import {
      Subheader
} from "../../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts";
import {
      PROPERTY_DETAILS_BY_POPERTY_ID
} from "../../../graphql/queries";
import { accessCheckRender, AlertProps, getRoutePermissionNew } from "../../../utils";
import { PropertyDetails } from './propertyView/propertydetails';


export const UtilitiesMappingPropertyView = () => {
      const history = useHistory()
      const [data, setData] = React.useState([])
      const search = useLocation().search;
      const client = useApolloClient();
      const alert = React.useContext(AlertContext);
      const auth = React.useContext(AuthContext)
      const propertyID = new URLSearchParams(search).get("propertyID");
      const backdrop = React.useContext(BackdropContext);
      const [permission, setPermission] = React.useState({})

      // use effect to get permission
      React.useEffect(() => {
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                  setPermission(perm)
                  if (perm?.read) {
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: true,
                              message: "",
                        });
                        getPropertyById()
                  }
            }
            // eslint-disable-next-line
      }, [auth]);


      const getPropertyById = () => {
            client.query({
                  query: PROPERTY_DETAILS_BY_POPERTY_ID,
                  fetchPolicy: 'network-only',
                  variables: {
                        propertyID: propertyID,
                  }
            }).then((res) => {
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
                  setData(res?.data)
            }).catch((err) => {
                  console.log(err)
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Something went wrong please try again",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                  });
            });
      };

      const goBack = () => {
            history.goBack(-1)
      }

      const render = () => {
            return <>
                  <Subheader title={data?.property?.[0]?.name} goBack={goBack} select isReadOnly={true} value={data?.property?.[0]?.company} />
                  <PropertyDetails data={data?.property?.[0]} assets={data?.asset} />
            </>
      }

      return (
            <div >
                  {accessCheckRender(render, permission)}
            </div >
      )
}