import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold } from "../../utils";

export const CheckAvailabilityStyles = makeStyles((theme) => ({
    grid_border: {
        border: `1px solid ${theme.palette.border.secondary}`,
        height:480
    },
    title: {
        fontSize: 12,
        color: theme.typography.color.tertiary,
        fontWeight: "bold",
        textTransform: "uppercase"
    },
    available_slot: {
        height: "100%"
    },
    venueSection:{
        height: `calc ( 100vh - 500px)`,
        overflow:"auto"
    }
}))

export const ViewCourtStyle = makeStyles((theme)=>({
    dot:{
        height:6,
        width:6,
        backgroundColor:theme.palette.border.secondary,
        borderRadius:50
    },
    court_name:{
        fontSize:16,
        color:theme.typography.color.primary,
        fontWeight:"bold"
    },
    subname:{
        fontSize:12,
        color:theme.typography.color.tertiary,
        fontWeight:"bold"
    },
    title:{
        fontSize:14,
        color:theme.typography.color.tertiary,
        fontWeight:"bold"
    },
    content:{
        fontSize:14,
        color:theme.typography.color.primary,
        fontWeight:"bold"
    },
    court_title:{
        fontSize:12,
        color:theme.typography.color.tertiary,
        fontWeight:"bold"
    }
}))

export const BookingResidentPopupStyle = makeStyles((theme)=>({
    name:{
        fontSize:14,
        color:theme.typography.color.primary,
        fontWeight:"bold"
    },
    content:{
        fontSize:12,
        color:theme.typography.color.tertiary
    },
    border:{
        border:`1px solid ${theme.palette.border.secondary}`,
        borderRadius:4
    },
    title:{
        fontSize:12,
        color:theme.typography.color.tertiary,
        fontWeight:"bold",
        textTransform:"uppercase"
    },
    grid_border:{
        border:`1px solid ${theme.palette.border.secondary}`,
    },
    invoiceTitle1:{
        fontSize:12,
        color:theme.typography.color.tertiary,
        fontWeight:"bold"
    },
    invoiceTitle2:{
        fontSize:12,
        color:theme.typography.color.tertiary,
        fontWeight:"italic"
    },
    editIcon:{
        cursor:"pointer"
    },
    bookingAvatar:{
        borderRadius:"50px",
        height:40,
        width:40,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        background:theme.palette.success.light
    },
    proceedDialog: {
        padding: "54px 50px 51px 228px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        backgroundImage: `url(${"images/announsment.svg"})`,
        backgroundRepeat: "no-repeat",
        backgroundColor:theme?.palette?.primary?.main
      },
      proceedDialog1: {
        padding: "78px 50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        backgroundImage: `url(${"images/success.svg"})`,
        backgroundRepeat: "no-repeat",
        backgroundColor:theme?.palette?.primary?.main
      },
      convert: {
        color: "white",
        fontSize: "16px",
        fontFamily: Bold,
      },
      convertProcess: {
        color: "white",
        fontSize: "16px",
        fontFamily: Regular,
        marginTop:'7px'
      },
      yes: {
        color: theme?.palette?.primary?.main,
        fontSize: "14px",
        fontFamily: Bold,
        border: `1px solid white`,
        backgroundColor: "white",
        padding: "7px 29px",
        width: "100%",
        "&:hover": {
          border: `1px solid white`,
          backgroundColor: "white",
        },
      },
      No: {
        color: "white",
        fontSize: "14px",
        fontFamily: SemiBold,
        border: `1px solid white`,
        padding: "7px 29px",
        backgroundColor: theme?.palette?.primary?.main,
        marginLeft: "10px",
        width: "100%",
        "&:hover": {
          border: `1px solid white`,
          backgroundColor: theme?.palette?.primary?.main,
        },
      },
      publishDialog:{
        '& .MuiPaper-root':{
            background:"transparent !important"
        }
    }
}))