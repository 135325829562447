import { Box } from "@mui/material";
import React from "react"
import { Editor } from "../../../components";


const ScopeOfWork = ({ updateState = () => false, value = "" }) => {
    console.log(value)
    return (
        <Box p={2} height={350}>
            <Editor
                label="Scope Of Work"
                handleChange={(e) => {
                    updateState("scopeOfWork",e);
                }}
                value={value}
                height={"150px"}
            />
        </Box>
    )
}
export default ScopeOfWork