export const FreeTextInvoiceTableHeading = (t) => {
    const Heading = [
        { title: "", field: "icon" },
        { title: t("InvoiceNo"), field: "invoice_no" },
        { title: t("AccountNo"), field: "account_no" },
        { title: t("Type"), field: "type" },
        { title: t("Amount"), field: "total_amount_due" },
        { title: t("CreatedBy"), field: "created_by" },
        { title: t("Reason"), field: "description" },
    ]
    return Heading
}


export const FreeTextInvoiceTablePath = [
    "invoice_no",
    "account_no",
    "type",
    "total_amount_due",
    "created_by",
    "description",
]


// export const FreeTextInvoiceTableRow = [
//     {
//         account_ID: "CST123521",
//         type: "Invoice type here",
//         amount: "2,000 USD",
//         created_by: "Bala Ganesh, 01 Jan 2022",
//         reason: "Reason will be displayed here",
//     },
//     {
//         account_ID: "CST123521",
//         type: "Invoice type here",
//         amount: "2,000 USD",
//         created_by: "Bala Ganesh, 01 Jan 2022",
//         reason: "Reason will be displayed here",
//     },
// ]


export const createFreeText = [
    {
        name: "Credit Note",
        key: "Credit_Note"
    },
    {
        name: "Debit Note",
        key: "Debit_Note"
    },
    {
        name: "Invoice",
        key: "Invoice"
    }
]

export const AccountType = [
    {
        name: "Customer",
        content: "Customer option is basically tenant details"
    },
    {
        name: "Vendor",
        content: "Customer option is Vendors, Service providers, Property owner details"
    }
]

export const ListTableData = [
    {
        title: "Customer Number",
        type: "label",
        content: "CST123521",
        edit: true
    },
    {
        title: "Details",
        type: "label",
        content: "Sudharsan N"
    },
    {
        title: "",
        type: "label",
        content: "Address line 2"
    },
    {
        title: "",
        type: "label",
        content: "Address line 3"
    },
    {
        title: "",
        type: "label",
        content: "India"
    },
    {
        title: "Mobile Number",
        type: "label",
        content: "+91 9999 999 999"
    },
    {
        title: "Email",
        type: "label",
        content: "Mailid@mail.com"
    },
    {
        title: "Tax Number",
        type: "label",
        content: "123-4516-1231"
    }
]

export const aggrementAndInvoiceType = [
    {
        title: "Agreement Number",
        type: "dropdown",
        list: [
            "123-4516-1231",
            "123-4516-1231",
            "123-4516-1231",
            "123-4516-1231",
            "123-4516-1231"
        ],
        placeholder: "Select Agreement"
    },
    {
        title: "Invoice Type",
        type: "dropdown",
        list: [
            "12DF-65TY-45GH",
            "12DF-65TY-45GH",
            "12DF-65TY-45GH",
            "12DF-65TY-45GH",
            "12DF-65TY-45GH"
        ],
        placeholder: "Select Invoice Type"
    },
    {
        title: "Tax Group",
        type: "dropdown",
        list: [
            "ASDF-DFDF-EIEU",
            "ASDF-DFDF-EIEU",
            "ASDF-DFDF-EIEU",
            "ASDF-DFDF-EIEU",
            "ASDF-DFDF-EIEU"
        ],
        placeholder: "Select Tax Group"
    },
    {
        title: "Due Date",
        type: "datepicker",
        content: [
            "123-4516-1231",
            "123-4516-1231",
            "123-4516-1231",
            "123-4516-1231",
            "123-4516-1231"
        ],
        placeholder: "DD MM YY"
    },
    {
        title: "Invoice Number",
        type: "label",
        content: "Autogenerate"
    },
    {
        title: "Invoice Amount",
        type: "label",
        content: "Autogenerate"
    },
    {
        title: "Period To",
        type: "label",
        content: "Autogenerate"
    },
    {
        title: "Period From",
        type: "label",
        content: "Autogenerate"
    },
]

export const propertyUnitDetail = [
    {
        title: "Property Unit Details",
        content: "UNIT-12201, Marvela, unique Compund"
    },
    {
        title: "Unit ID",
        content: "UNIT-12201"
    },
    {
        title: "Unit Type",
        content: "Residential"
    },
    {
        title: "Unit Type",
        content: "2BHK"
    },
    {
        title: "Location",
        content: "21231321, 21565165"
    }
]

export const propertyUnitListDetail = [
    {
        title: "Component"
    },
    {
        title: "Description"
    },
    {
        title: "Qty"
    },
    {
        title: "Amount"
    },
    {
        title: "Discount %"
    },
    {
        title: "Taxable Amount"
    },
    {
        title: "Tax Group"
    },
    {
        title: "Taxes"
    },
    {
        title: "Subtotal Amount"
    },
    {
        title: "Delete"
    },
]

export const propertyUnitListDetailView = [
    {
        title: "Component"
    },
    {
        title: "Description"
    },
    {
        title: "Qty"
    },
    {
        title: "Amount"
    },
    {
        title: "Discount %"
    },
    {
        title: "Taxable Amount"
    },
    {
        title: "Tax Group"
    },
    {
        title: "Taxes"
    },
    {
        title: "Subtotal Amount"
    },
]