import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, AvatarGroup, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from 'react';
import { validateArrayContaines } from "../../utils/inspectioncards";
import { AlertDialog } from "../dialog";
import { Slider } from '../slider';
import { AccordianStyle } from "./styles";
export const Card = ({ item = {}, MoveIn = true, MoveOut = false, utility = false }) => {
    const [expend, setExpend] = React.useState(false)

    const classes = AccordianStyle();
    const [open, setOpen] = React.useState({
        bool: false,
        data: []
    })

    //onclick images
    const onClickImage = (data) => {
        setOpen({
            bool: !open?.bool,
            data: data ?? []
        })
        setExpend(true)

    }

    const getColor = (status) => {
        switch (status) {
            case "Clean":
                return {
                    color: "#5AC782",
                }
            case "Dirty":
                return {
                    color: "#FF2453"
                }
            case "Good":
                return {
                    color: "#E29336"
                }
            case "Excellent":
                return {
                    color: "#5AC782",
                }
            case "Bad":
                return {
                    color: "#FF2453"
                }
            default:
                return {
                    color: "#FF2453"
                }
        }
    }

    return (
        <Box>
            {!utility &&
                <Grid sx={{ boxShadow: expend ? "0px 8px 24px #0717411F" : "auto" }} container className={classes.cardRoot} onClick={() => setExpend(!expend)} alignItems="center">
                    <Grid item sm={6} md={3} lg={4}>
                        <Stack direction="row" spacing={1} alignItems='center'>
                            <Typography className={classes.title}>{item?.item}</Typography>
                            {/* <Typography className={classes.noof}>({item?.noOfItems} Nos)</Typography> */}
                        </Stack>

                    </Grid>
                    <Grid item sm={3} md={3} lg={4}>
                        {
                            validateArrayContaines(item?.expand, "moveIn") ? <CheckCircleIcon color="success" /> : "-"
                        }
                    </Grid>
                    {MoveOut &&
                        <Grid item sm={3} md={3} lg={2}>
                            {
                                validateArrayContaines(item?.expand, "moveOut") ? <CheckCircleIcon color="success" /> : "-"
                            }
                        </Grid>
                    }
                    <Grid item sm={3} md={3} lg={MoveOut === true ? 2 : 4}>
                        <Stack direction="row" alignItems='center' justifyContent="space-between">
                            {/* <CheckCircleIcon color="success" /> */}
                            <Box width="100px" />
                            <KeyboardArrowDownIcon />
                        </Stack>
                    </Grid>
                    {
                        expend &&
                        <Grid item xs={12} >
                            {
                                item?.expand?.map((val, index) => {
                                    return (
                                        <Grid container marginTop="16px">
                                            <Grid item xs={4}>
                                                <Typography className={classes.title1}>{val?.name}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>

                                                <AvatarGroup onClick={() => onClickImage(val?.assets)} className={classes.avatarGroup} max={4} sx={{ float: "left" }}>
                                                    {
                                                        val?.assets?.map((src) => {

                                                            return <Avatar sx={{ width: 34, height: 34 }} alt="" src={src} />
                                                        })
                                                    }
                                                </AvatarGroup>
                                            </Grid>
                                            <Grid item xs={4}>
                                                {
                                                    MoveOut &&
                                                    <AvatarGroup onClick={() => onClickImage(val?.out_assets)} className={classes.avatarGroup} max={4} sx={{ float: "left" }}>
                                                        {
                                                            val?.out_assets?.map((src) => {
                                                                return <Avatar sx={{ width: 34, height: 34 }} alt="" src={src} />
                                                            })
                                                        }
                                                    </AvatarGroup>
                                                }
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography className={classes.subtitle1}>Serial Number / Product Id</Typography>
                                                <Typography className={classes.subtitle1}>Condition</Typography>
                                                <Typography className={classes.subtitle1}>Cleanliness</Typography>
                                                <Typography className={classes.subtitle1}>Notes</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography className={classes.subtitle2}>{val?.product_id ?? "-"}</Typography>
                                                <Typography className={classes.tag}
                                                    sx={{ backgroundColor: val?.condition ? getColor(val?.condition)?.color : "none" }}
                                                >{val?.condition ?? "-"}</Typography>
                                                <br />
                                                <Typography className={classes.tag} sx={{ backgroundColor: val?.cleanliness ? getColor(val?.cleanliness)?.color : "none" }}>{val?.cleanliness ?? "-"}</Typography>
                                                <Typography className={classes.subtitle2}>{val?.description ?? "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                {
                                                    MoveOut &&
                                                    <Box>
                                                        <Typography className={classes.subtitle2} >{val?.out_product_id ?? "-"}</Typography>
                                                        <Typography className={classes.tag} sx={{ backgroundColor: val?.out_condition ? getColor(val?.out_condition)?.color : "none" }}>{val?.out_condition ?? "-"}</Typography>
                                                        <br />
                                                        <Typography className={classes.tag} sx={{ backgroundColor: val?.out_cleanliness ? getColor(val?.out_cleanliness)?.color : "none" }}>{val?.out_cleanliness ?? "-"}</Typography>
                                                        <Typography className={classes.subtitle2}>{val?.out_description ?? "-"}</Typography>
                                                    </Box>
                                                }

                                            </Grid>
                                            {
                                                (item?.expand?.length - 1) !== index &&
                                                <Grid item marginTop="12px" xs={12}>
                                                    <Divider />
                                                </Grid>
                                            }

                                        </Grid>
                                    )
                                })
                            }

                        </Grid>
                    }
                </Grid>
            }
            {utility &&
                <Grid sx={{ boxShadow: expend ? "0px 8px 24px #0717411F" : "auto" }} container className={classes.cardRoot} onClick={() => setExpend(!expend)} alignItems="center">
                    <Grid item sm={6} md={3} lg={4}>
                        <Stack direction="row" spacing={1} alignItems='center'>
                            <Typography className={classes.title}>{item?.utility_name}</Typography>
                            {/* <Typography className={classes.noof}>({item?.noOfItems} Nos)</Typography> */}
                        </Stack>

                    </Grid>
                    <Grid item sm={3} md={3} lg={4}>
                        {
                            item?.meter_serial_no?.length > 0 ? <CheckCircleIcon color="success" /> : "-"
                        }
                    </Grid>
                    <Grid item sm={3} md={3} lg={MoveOut === true ? 2 : 4}>
                        <Stack direction="row" alignItems='center' justifyContent="space-between">
                            {/* <CheckCircleIcon color="success" /> */}
                            <Box width="100px" />
                            <KeyboardArrowDownIcon />
                        </Stack>
                    </Grid>
                    {
                        expend &&
                        <Grid item xs={12} >

                            <Grid container marginTop="16px">


                                <Grid item xs={4}>

                                </Grid>
                                <Grid item xs={4}>


                                    <AvatarGroup onClick={() => onClickImage(item?.assets)} className={classes.avatarGroup} max={4} sx={{ float: "left" }}>
                                        {
                                            item?.assets?.map((src) => {
                                                return <Avatar sx={{ width: 34, height: 34 }} alt="" src={src} />
                                            })
                                        }
                                    </AvatarGroup>
                                </Grid>


                            </Grid>
                            <Grid container marginTop="16px">


                                <Grid item xs={4}>
                                    <Typography className={classes.subtitle1}>Meter Serial Number</Typography>
                                    <Typography className={classes.subtitle1}>Reading</Typography>
                                </Grid>
                                <Grid item xs={4}>



                                    <Typography className={classes.subtitle2}>{item?.meter_serial_no ?? "-"}</Typography>

                                    <Typography className={classes.subtitle2}>{item?.present_reading ?? "-"}</Typography>
                                </Grid>


                            </Grid>

                        </Grid>
                    }
                </Grid>
            }


            <AlertDialog
                open={open?.bool}
                header="Assets"
                md={true}
                onClose={() => onClickImage()}
                component={
                    <Box p={1}>
                        <Slider backgroundColor="black" assets={open?.data} imageOnly={true} />
                    </Box>
                }
            />
        </Box>
    )
}