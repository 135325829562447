import { useApolloClient } from "@apollo/client";
import React, { createContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../contexts";
import { CHECK_PROSPECTIVE, CREATE_CONTACT, CREATE_LEAD, CREATE_SHORTLIST } from "../graphql/quotationMutations";
import { Routes } from "../router/routes";
import { AlertProps, ValidateEmail } from "../utils";
import { createState, returnContactCreatePayload, returnLeadCreatePayload } from "../utils/createQuotationUtils";

export const CreateQuotationListContext = createContext();

const CreateQuotationContextProvider = (props) => {

    const [step, setStep] = useState(1);
    const client = useApolloClient();
    const [dataNew, setDataNew] = React.useState({ ...createState });
    const [selectedAccount, setSelectedAccount] = React.useState({})
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [hideBtn, setHideBtn] = React.useState(false)
    const [hidenBtn1 , setHiddenBtn1]=React.useState(false)
    const alert = React.useContext(AlertContext);
    const history = useHistory()
    //validate sale oppertunity
    const validateSaleOppertunity = () => {
        let isValid = true;
        let error = dataNew.error;
        if (dataNew?.contract_start_date?.length === 0) {
            isValid = false;

            error.contract_start_date = "Contract Start Date is Required";
        }
        if (dataNew?.payment_period?.value === "Milestone Based") {
            if (dataNew?.delivery_timestone_template?.length === 0) {
                isValid = false;
                error.delivery_timestone_template = "Delivery Milstone is Required";
            }
        }

        if (dataNew?.payment_period?.length === 0) {
            isValid = false;
            error.payment_period = "Payment Period is Required";
        }
        if (dataNew?.payment_option?.length === 0) {
            isValid = false;
            error.payment_option = "Payment Options is Required";
        }
        if (dataNew?.oppertunity_subject?.length === 0) {
            isValid = false;
            error.oppertunity_subject = "Subject is Required";
        }
        if (dataNew?.source?.length === 0) {
            isValid = false;
            error.source = "Source is Required";
        }
        if (dataNew?.priority?.length === 0) {
            isValid = false;
            error.priority = "Priority is Required";
        }



        setDataNew({ ...dataNew, error });
        return isValid;
    }
    //update state
    const updateStateNew = (key, value, key1, value1) => {
        let error = dataNew.error;
        error[key] = "";
        setDataNew({ ...dataNew, [key]: value, [key1]: value1, error });
    };
    //validate contact
    const validataNewContact = () => {
        let isValid = true;
        let error = dataNew.error;
        if (dataNew?.name?.length === 0) {
            isValid = false;
            error.name = "Name is Required";
        }
        if (dataNew?.mobile?.length === 0) {
            isValid = false;
            error.mobile = "Mobile is Required";
        }
        if (dataNew?.email_id?.length === 0) {
            isValid = false;
            error.email_id = "Email is Required";
        }
        if (!ValidateEmail(dataNew?.email_id)) {
            isValid = false;
            error.email_id = "Invalid Email";
        }
        setDataNew({ ...dataNew, error });
        return isValid;
    };
    //validate sale quotation
    const validateSaleQuotationDetails = () => {
        let isValid = true;
        let error = dataNew.error;
        if (dataNew?.quotation_description?.length === 0) {
            isValid = false;
            error.quotation_description = "Description is Required";
        }

        if (dataNew?.quotation_billing_start_date?.length === 0) {
            isValid = false;
            error.quotation_billing_start_date = "Billing Start Date is Required";
        }


        setDataNew({ ...dataNew, error });
        return isValid;
    }
    //validate lease quotation
    const validateLeaseQuotationDetails = () => {
        let isValid = true;
        let error = dataNew.error;
        if (dataNew?.quotation_description?.length === 0) {
            isValid = false;
            error.quotation_description = "Description is Required";
        }

        if (dataNew?.quotation_billing_start_date?.length === 0) {
            isValid = false;
            error.quotation_billing_start_date = "Billing Start Date is Required";
        }

        if (dataNew?.quotation_payment_peroid?.value !== "Prepaid") {
            if (dataNew?.quotation_billing_cycle?.length === 0) {
                isValid = false;
                error.quotation_billing_cycle = "Billing Cycle Method is Required";
            }
            if (dataNew?.quotation_billing_cycle_date?.length === 0 && dataNew?.custom_date.length===0) {
                isValid = false;
                error.quotation_billing_cycle_date = "Billing Cycle Date is Required";
            }
        }

        setDataNew({ ...dataNew, error });
        return isValid;
    }
    //validate lead
    const validateLeadDetails = () => {

        let isValid = true;
        let error = dataNew.error;
        if (dataNew?.duration?.length === 0) {
            isValid = false;
            error.duration = "Duration is Required";
        }
        // if (dataNew?.unitUsage?.length === 0) {
        //     isValid = false;
        //     error.unitUsage = "Unit Usage is Required";
        // }
        if (dataNew?.lease_start_date?.length === 0) {
            isValid = false;
            error.lease_start_date = "Start Date & End Date is Required";
        }

        if (dataNew?.billing_start_date?.length === 0) {
            isValid = false;
            error.billing_start_date = "Billing Start Date is Required";
        }
        if (dataNew?.payment_period?.length === 0) {
            isValid = false;
            error.payment_period = "Payment Period is Required";
        }
        if (dataNew?.payment_option?.length === 0) {
            isValid = false;
            error.payment_option = "Payment Options is Required";
        }
        // if (dataNew?.grace_peroid?.length === 0) {
        //     isValid = false;
        //     error.grace_peroid = "Grace Peroid is Required";
        // }

        if (dataNew?.payment_period?.value !== "Prepaid") {

            if (dataNew?.bill_cycle_date?.length === 0 && dataNew?.custom_date.length===0) {
                isValid = false;
                error.bill_cycle_date = "Billing Cycle Date is Required";
            }
            if (dataNew?.bill_cycle_method?.length === 0) {
                isValid = false;
                error.bill_cycle_method = "Billing Cycle Method is Required";
            }
        }

        if (dataNew?.oppertunity_subject?.length === 0) {
            isValid = false;
            error.oppertunity_subject = "Subject is Required";
        }
        if (dataNew?.source?.length === 0) {
            isValid = false;
            error.source = "Source is Required";
        }
        if (dataNew?.priority?.length === 0) {
            isValid = false;
            error.priority = "Priority is Required";
        }
        if (dataNew?.renewal_based_on?.length === 0) {
            isValid = false;
            error.renewal_based_on = "Renewal Based on is Required";
        }
        if (dataNew?.renewal_based_on?.value === "Escalation %" || dataNew?.renewal_based_on?.value === "Increment %") {
            if (dataNew?.esclation_percentage?.length === 0) {
                isValid = false;
                error.esclation_percentage = "Increment/Escalation Percentage is Required";
            }
        }
        if (dataNew?.bill_cycle_date?.value === "Custom Date" && dataNew?.payment_period?.value !== "Prepaid") {
            if (dataNew?.custom_date?.length === 0) {
                isValid = false;
                error.custom_date = "Custom Date is Required";
            }
        }
        setDataNew({ ...dataNew, error });
        return isValid;
    }
    //previous btn function
    const previous = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };
    //create shortlist
    const createShortlist = (id, unitIds) => {

        client.mutate({
            mutation: CREATE_SHORTLIST,
            variables: {
                "insert": unitIds?.map((val) => {
                    return {
                        unit_id: val?.id,
                        lead_id: id,
                        is_active: true,
                    }
                })
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Shortlist Successfully Created",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }).catch(er => {
            console.log(er)
        })
    }
    //create lead
    const createLead = (prospective_id, contact = null) => {
        setHiddenBtn1(true)
        let payload = returnLeadCreatePayload(dataNew, prospective_id, selectedAccount)
        client.mutate({
            mutation: CREATE_LEAD,
            variables: {
                "insert": [payload]
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Lead Successfully Created",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });

            setDataNew({
              ...dataNew,
              lead_id: rs?.data?.lead?.[0]?.id,
              quotation_billing_start_date: dataNew?.billing_start_date,
              quotation_turn_over_rent: dataNew?.turn_over_rent_type,
              quotation_lease_duration: dataNew?.duration,
              quotation_payment_peroid: dataNew?.payment_period,
              quotation_payment_option: dataNew?.payment_option,
              quotation_billing_cycle: dataNew?.bill_cycle_method,
              quotation_billing_cycle_date: dataNew?.bill_cycle_date,
              quotation_renewal_based_on: dataNew?.renewal_based_on,
              quotation_auto_renewal: dataNew?.auto_renewal,
              quotation_escalculation_percent: dataNew?.esclation_percentage,
              quotation_include_market_place: dataNew?.market_places,
              quotation_custom_date: dataNew?.custom_date,
              quotation_revenue_based_lease: dataNew?.revenue_base_lease,
              quotation_grace_period: dataNew?.grace_peroid,
              quotation_handover_date: dataNew?.earlist_occupation_date,
              quotation_milestone: dataNew?.delivery_timestone_template,
              quotation_contract_start_date: dataNew?.contract_start_date,
              name: contact?.first_name ?? dataNew?.name,
              email_id: contact?.email_id ?? dataNew?.email_id,
              mobile: contact?.mobile_no
                ? {
                    mobile: contact?.mobile_no,
                    mobile_code: contact?.mobile_no_country_code,
                  }
                : dataNew?.mobile,
              grace_peroid: dataNew?.grace_peroid?.length > 0 ? dataNew?.grace_peroid : null,
              bill_cycle_date:
                dataNew?.bill_cycle_date?.length > 0
                  ? dataNew?.bill_cycle_date
                  : null,
              custom_date: dataNew?.custom_date ?? "",
              shortlist_convert:true
            });
            setStep(4)
            setHiddenBtn1(true)
        }).catch(er => {
            console.log(er)
            setHiddenBtn1(true)
        })
    }
    //create contact
    const createContact = () => {
        const payload = returnContactCreatePayload(dataNew)
        client.mutate({
            mutation: CREATE_CONTACT,
            variables: {
                "insert": [payload]
            }
        }).then(rs => {
            setDataNew({ ...dataNew, contact_id: rs?.data?.prospective?.[0]?.id })
            createLead(rs?.data?.prospective?.[0]?.id)
        }).catch(er => {
            console.log(er)
        })
    }
    //check exist prospective
    const checkProspectiveCheck = () => {
        client.mutate({
            mutation: CHECK_PROSPECTIVE,
            variables: {
                email_id: dataNew?.email_id
            }
        }).then(rs => {
            if (rs?.data?.prospective?.length > 0) {
                createLead(rs?.data?.prospective?.[0]?.id, rs?.data?.prospective?.[0]);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.info,
                    msg: "This Prospective is Already Exisit Create Oppertuntiy By That Prospect",
                });
            } else {
                createContact()
            }
        }).catch(er => {
            console.log(er)
        })

    }
    //next function if newlead
    const goNew = () => {

        if (step === 0) {
            setStep(1)
        } else if (step === 1) {
            if (validataNewContact()) {
                setStep(2)
            }
        } else if (step === 2) {
            if (dataNew?.revenue_type?.value === "Lease" || dataNew?.revenue_type?.value === "Manage") {
                if (validateLeadDetails()) {
                    setStep(3)
                }
            } else if (dataNew?.revenue_type?.value === "Sale") {
                if (validateSaleOppertunity()) {
                    setStep(3)
                }
            }
        } else if (step === 3) {
            if (dataNew?.lead_id?.length > 0) {
                setStep(4)
            } else {
                checkProspectiveCheck()
            }
        } else if (step === 4) {
            if (dataNew?.revenue_type?.value === "Lease" || dataNew?.revenue_type?.value === "Manage") {
                if (validateLeaseQuotationDetails()) {
                    setStep(5)
                }
            } else if (dataNew?.revenue_type?.value === "Sale") {
                if (validateSaleQuotationDetails()) {
                    setStep(5)
                }
            }
        } else if (step === 5) {
            setStep(6)
        } else if (step === 6) {
            history.push(Routes.quotation)
        }
    }
    //next function if existinglead
    const goExisting = () => {
        if (step === 1) {
            if (dataNew?.revenue_type?.value === "Lease" || dataNew?.revenue_type?.value === "Manage") {
                if (validateLeaseQuotationDetails()) {
                    setStep(2)
                }
            } else if (dataNew?.revenue_type?.value === "Sale") {
                if (validateSaleQuotationDetails()) {
                    setStep(2)
                }
            }
        }
        else if (step === 2) {
            setStep(3)
        } if (step === 3) {
            history.push(Routes.quotation)
        }
    }
    //next btn function
    const next = () => {

        if (dataNew.type === "new lead") {
            goNew()
        } else if (dataNew.type === "existing lead") {
            goExisting()

        }

    };

    return (
        <CreateQuotationListContext.Provider
            value={{
                step,
                previous,
                next,
                updateStateNew,
                dataNew,
                setDataNew,
                hideBtn,
                setHideBtn,
                setShow,
                show,
                setShow1,
                show1,
                setSelectedAccount,
                selectedAccount,
                createShortlist,
                hidenBtn1
            }}
        >
            {props.children}
        </CreateQuotationListContext.Provider>
    );
};

export default CreateQuotationContextProvider;
