import { makeStyles } from "@mui/styles";
import { Regular, SemiBold } from "../../utils";

export const VerticalStepperStyles = makeStyles((theme) => ({

    connector: {
        borderColor: theme.palette.border.secondary,
        borderLeftStyle: "dashed",
        borderLeftWidth: "1px",
        height: "30px",
        marginLeft: "7px",
        marginTop: "-5px"
    },
    customconnector: {
        borderColor: "#5078E1",
        borderLeftStyle: "dashed",
        borderLeftWidth: "1px",
        height: "30px",
        marginLeft: "18px",
        marginTop: "0px"
    },
    Name: {
        color: theme.typography.color.primary,
        fontSize: "14px",
        fontFamily: SemiBold
    },
    description: {
        color: theme.typography.color.tertiary,
        fontSize: "12px",
        fontFamily: Regular
    },
    detail: {
        marginLeft: "15px",
        marginTop: "-10px"
    },
    applybackground:{
        backgroundColor:"#F2F4F7",
        width:"40px",
        height:"40px",
        borderRadius:"50%",
        textAlign:"center"
    }
}))