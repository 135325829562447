import gql from "graphql-tag";

export const GET_CORRESPONDENCE_OPTIONS = gql`
query GET_CORRESPONDENCE_OPTIONS {
    correspondences_type_master(
      where: { is_active: { eq: true } }
      order_by: { id: asc }
    ) {
      value: id
      label: name
    }
    correspondences_level_master(
      where: { is_active: { eq: true } }
      order_by: { id: asc }
    ) {
      value: id
      label: name
    }
    correspondences_status_master(
      where: { is_active: { eq: true } }
      order_by: { id: asc }
    ) {
      value: id
      label: name
    }
  }`
