import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { ExtraBold } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",

  },

  rootmain: {
    '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
      margin: '10px',
    },
    width: "100%",
    maxWidth: "auto",
    padding: "0 !important",
    "& .MuiPaper-root": {
      //width: (props) => props?.width ?? "991px !important",
      borderRadius: (props) => props?.borderRadius ?? "4px",
      height: (props) => props?.height ?? 'auto',
    },
    "& .MuiDialogContent-root": {
      padding: "0px !important",
      position: 'relative',
      backgroundColor: (props) => props?.background ? "white" : "#F5F7FA",
      overflow: (props) => props?.overflow ?? 'auto',
      height: (props) => props?.height ?? 'auto',
    },
    '& .MuiDialog-container': {
      padding: (props) => props?.padding ?? '',


    }
  },
  header: {
    border: "1px solid #E4E8EE",
    fontSize: "16px",
    fontFamily: ExtraBold,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
export const DialogBox = (props) => {
  const classes = useStyles(props);
  return (
    <div >
      <Dialog
        open={props?.open}
        onClose={props?.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.rootmain}
        fullWidth
        fullScreen={props?.fullScreen?true:false}
        maxWidth={props?.maxWidth ?? "lg"}
      >
        {
          !props?.isnotTitle &&
          <DialogTitle className={classes.header}>
            <span>{props?.header}</span>{" "}
            <img
              src="/images/close.svg"
              alt="close"
              onClick={props?.onClose}
              style={{ cursor: 'pointer' }}
            />
          </DialogTitle>
        }
        <DialogContent className={classes.root} >
          <DialogContentText id="alert-dialog-description">
            {props?.component}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
