import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { filterStyles } from "./styles";

export const PromotionCard = (props) => {

    const classes = filterStyles();
    return (
        <Box className={classes.UnitCardroot} width="270px">
            <div className={classes.unitCardImgdiv}>
                <img src={props?.data?.logo ?? "/images/imagesproperty.svg"} alt="" className={classes.unitCardImg} />
                {
                    props?.data?.count > 0 && <Typography className={classes.type}>{props?.data?.count} Units Available</Typography>
                }
            </div>
            <Stack direction="row" justifyContent="space-between">
                <Typography className={classes.unitName}>{props?.data?.name}</Typography>
            </Stack>
            <Box height="2px" />

            <Stack direction="row" alignItems="flex-end" justifyContent="space-between">

                <Box display="flex" alignItems="flex-end">
                    <img src="/images/bed.svg" alt="" />
                    <Typography className={classes.build1} noWrap>{props?.data?.state} , {props?.data?.country} </Typography>
                </Box>


            </Stack>


        </Box>
    )
}