export const Accountpath = ["account_id", "acc_pic", "name", "email_id",  "mobile", "relationship"]


export const Accountheading = [
    { title: "", field: 'more', },
    { title: "Image", field: "acc_pic", },
    { title: "Account No", field: "account_id", },
    { title: "Name", field: "name", },
    // { title: "Location", field: "location", },
    { title: "Email", field: "email_id", },
    { title: "Mobile Number", field: "mobile", },
    { title: "Relationship", field: 'relationship', },
]

export const dataType =
    [
        { type: ["info"], name: "icon" },
        { type: ["avatarmanagement"], name: "acc_pic" },
        { type: ["text"], name: "account_id" },
        { type: ["text"], name: "name" },
        // { type: ["text"], name: "location" },
        { type: ["text"], name: "email_id" },
        { type: ["text"], name: "mobile" },
        { type: ["text"], name: "relationship" },
    ]


export const accountState = {
    acc_id: null,
    image_url: "",
    company: "",
    bank_currency_id: "",
    acc_name: "",
    relation_ship: "",
    description: "",
    address: "",
    primary_contact: "",
    title: "",
    primary_telephone: "",
    primary_email: "",
    bank_name: "",
    branch_name: "",
    doorNo: "",
    addressLineOne: "",
    addressLineTwo: "",
    landmark: "",
    area: "",
    district: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    account_type: "",
    account_no: "",
    bank_route_type: "",
    bank_route_code: "",
    collection_office: "",
    cheque_name: "",
    bank_addrees1: "",
    bank_addrees2: "",
    bank_city: "",
    bank_country: "",
    company_register_type: "",
    company_register_no: "",
    regularity_authority: "",
    pera: "",
    pera_broker: "",
    tax_group: "",
    tax_code: "",
    currency: "",
    tax_register_no: "",
    contact: "",
    lat: "",
    long: "",
    proofFile: "",
    error: {
        acc_name: "",
        relation_ship: "",
        primary_contact: "",
        title: "",
        primary_email: "",
        bank_name: "",
        branch_name: "",
        account_type: "",
        account_no: "",
        bank_route_type: "",
        bank_route_code: "",
        collection_office: "",
        cheque_name: "",
        bank_addrees1: "",
        bank_addrees2: "",
        bank_city: "",
        bank_country: "",
        company_register_type: "",
        company_register_no: "",
        regularity_authority: "",
        pera: "",
        pera_broker: "",
        tax_group: "",
        tax_code: "",
        tax_register_no: "",
        contact: "",
        bank_currency_id: "",

    }
}

export const returnPayload = (data) => {
    let payload = {
        id: data?.acc_id ?? undefined,
        contact_id: data?.relation_ship?.value === "Service Provider" || data?.relation_ship?.value === "Vendor" ? undefined : data?.contact?.value,
        vendor_id: data?.relation_ship?.value === "Vendor" ? data?.contact?.vendor_id : undefined,
        provider_id: data?.relation_ship?.value === "Service Provider" ? data?.contact?.provider_id : undefined,
        name: data?.acc_name,
        company_id: data?.company?.value,
        logo: data?.image_url?.src ?? null,
        mobile_no: data?.primary_contact?.mobile,
        mobile_no_country_code: data?.primary_contact?.mobile_code,
        email_id: data?.primary_email,
        currency_id: data?.currency?.value,
        vat_group_id: data?.tax_group?.value,
        relationship: data?.relation_ship?.value,
        description: data?.description,
        phone_code: data?.primary_telephone?.mobile_code?.length > 0 ? data?.primary_telephone?.mobile_code : null,
        telephone: data?.primary_telephone?.mobile > 0 ? data?.primary_telephone?.mobile : null,
        bank_name: data?.bank_name,
        branch_name: data?.branch_name,
        bank_currency_id: data?.bank_currency_id?.value,
        bank_account_no: data?.account_no,
        account_type: data?.account_type?.length > 0 ? data?.account_type : null,
        routing_code: data?.bank_route_code,
        routing_type: data?.bank_route_type?.value,
        address_1: data?.bank_addrees1 ?? null,
        address_2: data?.bank_addrees2 ?? null,
        bank_city: data?.bank_city,
        country: data?.country?.value ?? null,
        door_no: data?.doorNo?.length > 0 ? data?.doorNo : null,
        street_1: data?.addressLineOne?.length > 0 ? data?.addressLineOne : null,
        street_2: data?.addressLineTwo?.length > 0 ? data?.addressLineTwo : null,
        street_3: data?.addressLineThree?.length > 0 ? data?.addressLineThree : null,
        area: data?.area?.length > 0 ? data?.area : null,
        city: data?.district?.length > 0 ? data?.district : null,
        state: data?.state?.length > 0 ? data?.state : null,
        zipcode: data?.pincode?.length > 0 ? data?.pincode : null,
        longitude: data?.long ?? null,
        latitude: data?.lat ?? null,
        title: data?.title?.length > 0 ? data?.title : null,
        cash_collection_office: data?.collection_office,
        cheque_name: data?.cheque_name,
        proof: data?.proofFile?.filter((val) => val?.is_active)?.map(val => {
            return {
                id: val?.id ?? undefined,
                proof_id: val?.type?.value,
                proof_type: "Company and Account",
                kyc_type: "Both",
                name: val?.name,
                number: val?.id_no,
                valid_from: val?.valid_from,
                valid_to: val?.valid_to,
                url: val?.file,
                is_mandatory: val?.mandatry_verify,
                country_id: val?.issuing_country?.value,
                is_active: val?.is_active
            }
        })
    }
    return payload;
}

export const returnPayloadAccount = (data) => {
    let payload = {
        id: data?.acc_id ?? undefined,
        contact_id: data?.relation_ship?.value === "Service Provider" || data?.relation_ship?.value === "Vendor" ? undefined : data?.contact?.value,
        vendor_id: data?.relation_ship?.value === "Vendor" ? data?.contact?.vendor_id : undefined,
        provider_id: data?.relation_ship?.value === "Service Provider" ? data?.contact?.provider_id : undefined,
        name: data?.acc_name,
        company_id: data?.company?.value,
        logo: data?.image_url?.src ?? null,
        mobile_no: data?.primary_contact?.mobile,
        mobile_no_country_code: data?.primary_contact?.mobile_code,
        email_id: data?.primary_email,
        currency_id: data?.currency?.value,
        vat_group_id: data?.tax_group?.value,
        relationship: data?.relation_ship?.value,
        description: data?.description,
        phone_code: data?.primary_telephone?.mobile_code?.length > 0 ? data?.primary_telephone?.mobile_code : null,
        telephone: data?.primary_telephoNumberne?.mobile > 0 ? data?.primary_telephone?.mobile : null,
        bank_name: data?.bank_name,
        branch_name: data?.branch_name,
        bank_currency_id: data?.bank_currency_id?.value,
        bank_account_no: data?.account_no,
        account_type: data?.account_type?.length > 0 ? data?.account_type : null,
        routing_code: data?.bank_route_code,
        routing_type: data?.bank_route_type?.value,
        address_1: data?.bank_addrees1 ?? null,
        address_2: data?.bank_addrees2 ?? null,
        bank_city: data?.bank_city,
        country: data?.country?.label ?? null,
        door_no: data?.doorNo?.length > 0 ? data?.doorNo : null,
        street_1: data?.addressLineOne?.length > 0 ? data?.addressLineOne : null,
        street_2: data?.addressLineTwo?.length > 0 ? data?.addressLineTwo : null,
        landmark: data?.addressLineThree?.length > 0 ? data?.addressLineThree : null,
        city:data?.city?.length > 0 ? data?.city : null,
        area: data?.area?.length > 0 ? data?.area : null,
        // city: data?.city?.length > 0 ? data?.district : null,
        state: data?.state?.length > 0 ? data?.state : null,
        zipcode: data?.pincode?.length > 0 ? data?.pincode : null,
        longitude: data?.long ?? null,
        latitude: data?.lat ?? null,
        title: data?.title?.length > 0 ? data?.title : null,
        cash_collection_office: data?.collection_office,
        cheque_name: data?.cheque_name,
        proof: data?.proofFile?.filter((val) => val?.is_active)?.map(val => {
            return {
                id: val?.id ?? undefined,
                proof_id: val?.type?.value,
                proof_type: "Company and Account",
                kyc_type: "Both",
                name: val?.name,
                number: val?.id_no,
                valid_from: val?.valid_from,
                valid_to: val?.valid_to,
                url: val?.file,
                is_mandatory: val?.mandatry_verify,
                country_id: val?.issuing_country?.value,
                is_active: val?.is_active
            }
        })
    }
    return payload;
}

export const returnEditPayload = (data, acc_id) => {

    let result = {
        acc_id: acc_id,
        image_url: { src: data?.logo },
        company: {
            value: data?.company?.company_ids,
            label: data?.company?.company_name,
        },
        acc_name: data?.name,
        relation_ship: {
            value: data?.relationship,
            label: data?.relationship,
        },
        description: data?.description,
        address: "",
        primary_contact: {
            mobile: data?.mobile_no,
            mobile_code: data?.mobile_no_country_code
        },
        title: data?.title,
        primary_telephone: {
            mobile: data?.mobile_no,
            mobile_code: data?.mobile_no_country_code
        },
        primary_email: data?.email_id,
        bank_name: data?.bank_name,
        branch_name: data?.branch_name,
        doorNo: data?.address?.[0]?.door_no,
        addressLineOne: data?.address?.[0]?.street_1,
        addressLineTwo: data?.address?.[0]?.street_2,
        landmark: data?.address?.[0]?.street_3,
        area: data?.address?.[0]?.area,
        district: data?.address?.[0]?.district,
        city: data?.address?.[0]?.city,
        state: data?.address?.[0]?.state,
        country: data?.address?.[0]?.country,
        pincode: data?.address?.[0]?.zipcode,
        account_type: data?.account_type,
        account_no: data?.bank_account_no,
        bank_route_type: {
            value: data?.routing_type,
            label: data?.routing_type,
        },
        bank_currency_id: {
            value: data?.bank_currency?.id,
            label: data?.bank_currency?.symbol,
        },
        bank_route_code: data?.routing_code,
        collection_office: data?.cash_collection_office,
        cheque_name: data?.cheque_name,
        bank_addrees1: data?.address_1,
        bank_addrees2: data?.address_2,
        bank_city: data?.city,
        bank_country: data?.country_master,
        company_register_type: {
            value: data?.company_registration,
            label: data?.company_registration,
        },
        company_register_no: data?.company_registration_no,
        regularity_authority: {
            value: data?.rera_type,
            label: data?.rera_type,
        },
        pera: data?.rera_registration_no,
        pera_broker: data?.rera_broker_registration_no,
        tax_group: {
            value: data?.vat_group?.id,
            label: data?.vat_group?.name,
        },
        tax_code: {
            value: data?.tax_code,
            label: data?.tax_code,
        },
        currency: {
            value: data?.company?.currency?.id,
            label: data?.company?.currency?.symbol,
        },
        tax_register_no: data?.tax_registration_no,
        contact: data?.contact_id ? data?.contact : data?.vendor_id ? data?.vendor : data?.provider_id ? data?.provider : "",
        lat: data?.address?.[0]?.lat,
        long: data?.address?.[0]?.long,
        proofFile: data?.know_your_client?.map(val => {
            return {
                id: val?.id,
                type: val?.identification_masterByID,
                kyc_type: "Both",
                name: val?.name,
                id_no: val?.number,
                valid_from: new Date(val?.valid_from),
                valid_to: new Date(val?.valid_to),
                file: val?.url,
                mandatry_verify: val?.is_mandatory,
                issuing_country: val?.country_master,
                is_active: val?.is_active
            }
        }),
        error: {
            acc_name: "",
            relation_ship: "",
            primary_contact: "",
            title: "",
            primary_email: "",
            bank_name: "",
            branch_name: "",
            account_type: "",
            account_no: "",
            bank_route_type: "",
            bank_route_code: "",
            collection_office: "",
            cheque_name: "",
            bank_addrees1: "",
            bank_addrees2: "",
            bank_city: "",
            bank_country: "",
            company_register_type: "",
            company_register_no: "",
            regularity_authority: "",
            pera: "",
            pera_broker: "",
            tax_group: "",
            tax_code: "",
            tax_register_no: "",
            contact: "",
        }
    }
    return result
}

export const returnEditPayloadAccount = (data, acc_id) => {

    let result = {
        acc_id: acc_id,
        image_url: { src: data?.logo },
        company: {
            value: data?.company?.company_ids,
            label: data?.company?.company_name,
        },
        acc_name: data?.name,
        relation_ship: {
            value: data?.relationship,
            label: data?.relationship,
        },
        description: data?.description,
        address: "",
        primary_contact: {
            mobile: data?.mobile_no,
            mobile_code: data?.mobile_no_country_code
        },
        title: data?.title,
        primary_telephone: {
            mobile: data?.mobile_no,
            mobile_code: data?.mobile_no_country_code
        },
        primary_email: data?.email_id,
        bank_name: data?.bank_name,
        branch_name: data?.branch_name,
        doorNo: data?.address?.[0]?.door_no,
        addressLineOne: data?.address?.[0]?.street_1,
        addressLineTwo: data?.address?.[0]?.street_2,
        addressLineThree:data?.address?.[0]?.street_3 ?? null,
        landmark: data?.address?.[0]?.street_3,
        area: data?.address?.[0]?.area,
        district: data?.address?.[0]?.district,
        city: data?.address?.[0]?.city,
        state: data?.address?.[0]?.state,
        country: {
            value :data?.address?.[0]?.country ?? null ,
            label:data?.address?.[0]?.country ?? ""
        },
        pincode: data?.address?.[0]?.zipcode,
        account_type: data?.account_type,
        account_no: data?.bank_account_no,
        bank_route_type: {
            value: data?.routing_type,
            label: data?.routing_type,
        },
        bank_currency_id: {
            value: data?.bank_currency?.id,
            label: data?.bank_currency?.symbol,
        },
        bank_route_code: data?.routing_code,
        collection_office: data?.cash_collection_office,
        cheque_name: data?.cheque_name,
        bank_addrees1: data?.address_1,
        bank_addrees2: data?.address_2,
        bank_city: data?.city,
        bank_country: data?.country_master,
        company_register_type: {
            value: data?.company_registration,
            label: data?.company_registration,
        },
        company_register_no: data?.company_registration_no,
        regularity_authority: {
            value: data?.rera_type,
            label: data?.rera_type,
        },
        pera: data?.rera_registration_no,
        pera_broker: data?.rera_broker_registration_no,
        tax_group: {
            value: data?.vat_group?.id,
            label: data?.vat_group?.name,
        },
        tax_code: {
            value: data?.tax_code,
            label: data?.tax_code,
        },
        currency: {
            value: data?.company?.currency?.id,
            label: data?.company?.currency?.symbol,
        },
        tax_register_no: data?.tax_registration_no,
        contact: data?.contact_id ? data?.contact : data?.vendor_id ? data?.vendor : data?.provider_id ? data?.provider : "",
        lat: data?.address?.[0]?.lat,
        long: data?.address?.[0]?.long,
        proofFile: data?.know_your_client?.map(val => {
            return {
                id: val?.id,
                type: val?.identification_masterByID,
                kyc_type: "Both",
                name: val?.name,
                id_no: val?.number,
                valid_from: new Date(val?.valid_from),
                valid_to: new Date(val?.valid_to),
                file: val?.url,
                mandatry_verify: val?.is_mandatory,
                issuing_country: val?.country_master,
                is_active: val?.is_active
            }
        }),
        error: {
            acc_name: "",
            relation_ship: "",
            primary_contact: "",
            title: "",
            primary_email: "",
            bank_name: "",
            branch_name: "",
            account_type: "",
            account_no: "",
            bank_route_type: "",
            bank_route_code: "",
            collection_office: "",
            cheque_name: "",
            bank_addrees1: "",
            bank_addrees2: "",
            bank_city: "",
            bank_country: "",
            company_register_type: "",
            company_register_no: "",
            regularity_authority: "",
            pera: "",
            pera_broker: "",
            tax_group: "",
            tax_code: "",
            tax_register_no: "",
            contact: "",
        }
    }
    return result
}

export const AccordionJson = [
    {
        title: "Light",
        noOfItems: "2",
        moveInStatus: true,
        moveOutStatus: true,
        items: [
            {
                title: "Light",
                moveInAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveOutAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "E"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "G"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveInDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                },
                moveOutDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                }
            },
            {
                title: "Light",
                moveInAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveOutAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "E"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "G"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveInDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                },
                moveOutDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                }
            }
        ]
    },
    {
        title: "Light",
        noOfItems: "2",
        moveInStatus: true,
        moveOutStatus: true,
        items: [
            {
                title: "Light",
                moveInAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveOutAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "E"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "G"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveInDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                },
                moveOutDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                }
            }
        ]
    },
    {
        title: "Light",
        noOfItems: "2",
        moveInStatus: true,
        moveOutStatus: true,
        items: [
            {
                title: "Light",
                moveInAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveOutAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "E"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "G"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveInDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                },
                moveOutDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                }
            }
        ]
    },
]
export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]

export const accountType = [
    { label: 'A', value: "A" },
    { label: 'B', value: "B" },
]