import { useApolloClient } from "@apollo/client";
import { Avatar, Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchFilter } from "../../components";
import { GET_CONTACT } from "../../graphql/agreementQurery";
import { LocalStorageKeys } from "../../utils";
import { ContactList } from "../timeline/components/contactList";
import { InspectionAssigneeStyles } from "./styles";

export const ExisitingInspector = ({ onClose = () => false, CheckContact = () => false }) => {
    const classes = InspectionAssigneeStyles()
    const client = useApolloClient();
    const [datas, setData] = React.useState(null);
    const [open, setOpen] = React.useState(null);
    const [offset, setOffset] = React.useState(0)
    const [search, setSearch] = React.useState("");
    const [list, setList] = React.useState([])

    //get contact
    const getContact = (offset, search, reload) => {
        client.query({
            query: GET_CONTACT,
            fetchPolicy: 'network-only',
            variables: {
                offset: offset,
                limit: 10,
                search: search,
                client: localStorage.getItem(LocalStorageKeys.clinetID)
            }
        }).then((response) => {
            if (reload) {
                setList(response?.data?.contact)
            } else {
                setList([...list, ...response?.data?.contact])
            }

        }).catch((err) => {
            console.log(err)
        })
    }
    //initial load
    React.useEffect(() => {
        getContact(0, "", false)
        // eslint-disable-next-line
    }, [])
    const handleClick = (event) => {
        setOpen(!open);
    };
    //on change 
    const onChange = (val) => {
        setData(val)
        handleClick()
    }
    // infinity scroll
    const fetchMoreData = () => {
        setOffset(offset + 10);
        getContact(offset + 10, search, false);
    };
    //on search
    const onSearch = (value) => {
        setSearch(value)
        getContact(offset, value, true)
    }
    //onSubmit
    const onSubmit = () => {
        CheckContact(datas?.id)
    }
    return (
        <Box p={2}>
            <Box height="230px">
                <Box onClick={handleClick}>
                    <SearchFilter value={search} handleChange={(value) => onSearch(value)} placeholder="Search Member Details" />
                </Box>
                <Box position="relative">
                    {
                        open &&
                        <Box className={classes.contactList}>
                            <InfiniteScroll
                                dataLength={list?.length ?? ""}
                                next={fetchMoreData}
                                hasMore={true}
                                height={150}
                            >
                                {
                                    list?.map((val) => {
                                        return (
                                            <ContactList value={datas} onClick={onChange} data={val} />
                                        )
                                    })
                                }
                            </InfiniteScroll>
                        </Box>
                    }
                    {
                        datas ?
                            <Box display="flex" alignItems="center" className={classes.selectedList}>
                                <Box padding="4px">
                                    <Avatar sx={{ height: "34px", width: "34px" }} src={datas?.image_url ?? null} />
                                </Box>
                                <Box flexGrow={1} marginLeft="8px">
                                    <Typography className={classes.name}>{datas?.name}</Typography>
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <Typography className={classes.email}>{datas?.email_id}</Typography>
                                        <Box className={classes.dot} />
                                        <Typography className={classes.email}> {datas?.mobile_no_country_code} {datas?.mobile_no}</Typography>
                                    </Stack>
                                </Box>
                                <Box marginRight="8px">
                                    <IconButton size="small" onClick={() => setData(null)}>
                                        <img src="/images/delete.svg" alt="" />
                                    </IconButton>
                                </Box>
                            </Box>
                            :
                            <Box height="12px" />
                    }




                    <Box height="30px" />

                </Box>
            </Box>
            <Button className={classes.btn} onClick={onSubmit} variant="contained" fullWidth>Assign Person</Button>
        </Box>
    )
}