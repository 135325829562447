import makeStyles from '@mui/styles/makeStyles'
import { ExtraBold } from '../../utils'
export const useStyles = makeStyles((theme) => ({
    viewDialog: {
        "& .MuiPaper-root": {
            minWidth: "400px !important",
            maxWidth: "900px !important",
        },
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
        },
    },
    viewDialogHeader: {
        padding: "16px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E4E8EE"
    },
    viewDialogHeaderTitle: {
        fontFamily: ExtraBold,
        fontSize: "16px",
        color: "#091B29"
    },
    viewDialogCloseButton: {
        padding: "0px"
    },
    viewDialogBody: {
        padding: "0px 16px 16px",
        overflowY: "overlay"
    },
}))