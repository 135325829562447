import makeStyles from "@mui/styles/makeStyles";


export const useTableStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        padding: "14px 16px 16px 16px",
        backgroundColor: "white",
        margin: "14px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",

        overflow: "auto",
    },
    filerIcon: {
        border: "1px solid " + theme.palette.border.secondary,
        borderRadius: theme.palette.borderRadius,
    },
    reqTable: {
        height: 'calc(100vh - 285px)',
    },
    filterIconBox: {
        float: "right"
    },
}))


