import { Box } from "@mui/material";
import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays, subMonths } from "date-fns";
import moment from "moment";
import React from "react";
import { withNamespaces } from "react-i18next";
import { AlertDialog, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { ActivityCalendar } from "./calendar";
import { DailyCalendar } from "./components/dailyCalendar";
import { ViewActivity } from "./components/viewMore";

const ActivityBoard = ({ t }) => {
    const [companyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [month, setMonth] = React.useState(new Date())
    const [finalDays, setFinalDays] = React.useState([])
    const [calendarData, setCalendarData] = React.useState([])
    const [selectedTeams, setSelectedTeams] = React.useState({})
    const [tableData, setTableData] = React.useState("")
    const [selectedDate, setSelectedDate] = React.useState("")
    const [openDailyCalendar, setOpenDailyCalendar] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [select, setSelect] = React.useState("nonlist")
    const [selected, setSelected] = React.useState("Weekly")
    const [openPopup, setOpenPopup] = React.useState(false)
    const [weekdates, setWeekdates] = React.useState({ startDate: new Date(), endDate: addDays(new Date(), 6) })
    const [daily, setDaily] = React.useState({ date: new Date() })
    const [selectedViewData, setSelectedViewData] = React.useState({})
    const [permission, setPermission] = React.useState({})
    const debounce = UseDebounce()
    const auth = React.useContext(AuthContext)
    const backdrop = React.useContext(BackdropContext)
    const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
    const handleTeamsChange = (team) => {
        setSelectedTeams(team)
    }

    const activityLogo = {
        "Phone call": "/images/phonecalls.svg",
        "Follow up": "/images/followup.svg",
        "Event": "/images/event.svg",
        "Send Email": "/images/emaisl.svg",
        "Send Quotation": "/images/quotation.svg",
        "Arrange Site Visit": "/images/sitevisits.svg",
        "Appointment": "/images/appoinment.svg",
        "Meeting": "/images/meet.svg"
    }

    // table functions
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const handlePagination = (e) => {
        setPage(e)
        let offset = (e - 1) * limit

        if (selected === "Weekly") {
            if (select !== 'nonlist') {
                getTableData(weekdates?.startDate, weekdates?.endDate, 1, offset, "")
            }
        } else if (selected === "Monthly") {
            if (select !== 'nonlist') {
                getTableData(addDays(startOfMonth(month), 1), endOfMonth(month), 24, offset, "")
            }
        } else if (selected === "Daily") {
            if (select !== 'nonlist') {
                getTableData(moment(daily?.date).format("YYYY-MM-DD 00:00:00"), moment(daily?.date).format("YYYY-MM-DD 23:59:59"), 1, offset, "")
            }
        }
    }
    const handleChangeLimit = (e) => {
        setLimit(e)
        setPage(1)

        if (selected === "Weekly") {
            if (select !== 'nonlist') {
                getTableData(weekdates?.startDate, weekdates?.endDate, 1, 0, "")
            } else {
                getCalendarData(weekdates?.startDate, weekdates?.endDate, 1)
            }
        } else if (selected === "Monthly") {
            if (select !== 'nonlist') {
                getTableData(addDays(startOfMonth(month), 1), endOfMonth(month), 24, 0, "")
            } else {
                getCalendarData(addDays(startOfMonth(month), 1), endOfMonth(month), 24)
            }
        } else if (selected === "Daily") {
            if (select !== 'nonlist') {
                getTableData(moment(daily?.date).format("YYYY-MM-DD 00:00:00"), moment(daily?.date).format("YYYY-MM-DD 23:59:59"), 1, 0, "")
            } else {
                getCalendarData(moment(daily?.date).format("YYYY-MM-DD 00:00:00"), moment(daily?.date).format("YYYY-MM-DD 23:59:59"), 1)
            }
        }
    }
    const handleSearch = (value) => {
        setSearchText(value)
        debounce(() => {
            if (page > 1) { setPage(1) }

            if (selected === "Weekly") {
                if (select !== 'nonlist') {
                    getTableData(weekdates?.startDate, weekdates?.endDate, 1, 0, value)
                }
            } else if (selected === "Monthly") {
                if (select !== 'nonlist') {
                    getTableData(addDays(startOfMonth(month), 1), endOfMonth(month), 24, 0, value)
                }
            } else if (selected === "Daily") {
                if (select !== 'nonlist') {
                    getTableData(moment(daily?.date).format("YYYY-MM-DD 00:00:00"), moment(daily?.date).format("YYYY-MM-DD 23:59:59"), 1, 0, value)
                }
            }
        }, 800)
    }

    const handleIcon = (type, data) => {
        if (type === "view") {
            setOpenPopup(true)
            setSelectedViewData(data)
        }
    }

    //dateRange
    const dateRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());

        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }
    // Daily Calendar Functions
    //nextdate
    const nextdate = () => {
        setDaily({ ...daily, date: addDays(daily?.date, 1) })
        getCalendarData(moment(addDays(daily?.date, 1)).format("YYYY-MM-DD 00:00:00"), moment(addDays(daily?.date, 1)).format("YYYY-MM-DD 23:59:59"), 1)
        getTableData(moment(addDays(daily?.date, 1)).format("YYYY-MM-DD 00:00:00"), moment(addDays(daily?.date, 1)).format("YYYY-MM-DD 23:59:59"), 1, 0, "")
    }
    //prevdate
    const prevdate = () => {
        setDaily({ ...daily, date: subDays(daily?.date, 1) })
        getCalendarData(moment(subDays(daily?.date, 1)).format("YYYY-MM-DD 00:00:00"), moment(subDays(daily?.date, 1)).format("YYYY-MM-DD 23:59:59"), 1)
        getTableData(moment(subDays(daily?.date, 1)).format("YYYY-MM-DD 00:00:00"), moment(subDays(daily?.date, 1)).format("YYYY-MM-DD 23:59:59"), 1, 0, "")
    }
    // const calendarData = [
    //     {
    //         date: "2023-01-25",
    //         calendarData: [{

    //             "start": "2023-01-25T00:00:00.000Z",
    //             "end": "2023-01-25T01:00:00.000Z",
    //             activity: [
    //                 {
    //                     activity_type: "Phone call",
    //                     date: "2023-01-25T01:00:00.000Z",
    //                     createdBy: "Naruto Uzhmaki",
    //                     mobile_no: "+91 9999 999 999"
    //                 },
    //             ]

    //         },

    //         ]
    //     },
    //     {
    //         date: "2023-01-27",
    //         calendarData: [{
    //             "start": "2023-01-27T04:00:00.000Z",
    //             "end": "2023-01-27T05:00:00.000Z",
    //             activity: [
    //                 {
    //                     activity_type: "Appointment",
    //                     date: "2023-01-27T01:00:00.000Z",
    //                     createdBy: "Naruto Uzhmaki",
    //                     mobile_no: "+91 9999 999 999"
    //                 },

    //             ]

    //         },


    //         ]
    //     },
    //     {
    //         date: "2023-01-29",
    //         calendarData: [{

    //             "start": "2023-01-29T05:00:00.000Z",
    //             "end": "2023-01-29T06:00:00.000Z",
    //             activity: [
    //                 {
    //                     activity_type: "Site Visit",
    //                     date: "2023-01-29T05:00:00.000Z",
    //                     createdBy: "Naruto Uzhmaki",
    //                     mobile_no: "+91 9999 999 999"
    //                 },
    //             ]

    //         },

    //         ]
    //     },
    //     {
    //         date: "2023-01-28",
    //         calendarData: [{

    //             "start": "2023-01-28T03:00:00.000Z",
    //             "end": "2023-01-28T04:00:00.000Z",
    //             activity: [
    //                 {
    //                     activity_type: "Quotation",
    //                     date: "2023-01-28T01:00:00.000Z",
    //                     createdBy: "Naruto Uzhmaki",
    //                     mobile_no: "+91 9999 999 999"
    //                 },
    //             ]

    //         },


    //         ]
    //     }
    // ]


    // weekly calendar functions


    let weekstartDate = weekdates.startDate
    const weekendDate = weekdates.endDate

    var dates = []
    while (weekstartDate <= weekendDate) {
        dates.push(weekstartDate)
        weekstartDate = addDays(weekstartDate, 1)
    }
    //nextweek
    const weeknext = () => {
        setWeekdates({
            startDate: addDays(weekdates.endDate, 1), endDate: addDays(weekdates.endDate, 7)
        })
        getCalendarData(addDays(weekdates.endDate, 1), addDays(weekdates.endDate, 7), 1)
        getTableData(addDays(weekdates.endDate, 1), addDays(weekdates.endDate, 7), 1, 0, "")

    }
    //prevweek
    const weekprev = () => {
        setWeekdates({
            startDate: subDays(weekdates.startDate, 7), endDate: subDays(weekdates.startDate, 1)
        })
        getCalendarData(subDays(weekdates.startDate, 7), subDays(weekdates.startDate, 1), 1)
        getTableData(subDays(weekdates.startDate, 7), subDays(weekdates.startDate, 1), 1, 0, "")
    }
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
                // Month rendar funtion and state
                const monthStart = startOfMonth(month);
                const monthEnd = endOfMonth(monthStart);
                const monthstartDate = startOfWeek(monthStart);
                const monthendDate = endOfWeek(monthEnd);
                let monthdays = dateRange(monthstartDate, monthendDate)
                setFinalDays(monthdays)
            }
        }
        //eslint-disable-next-line
    }, [auth])

    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
        }
    }

    React.useEffect(() => {
        if (selectedTeams?.value) {
            getCalendarData(weekdates?.startDate, weekdates?.endDate, 1, selectedCompany?.value, selectedTeams?.value)
            getTableData(weekdates?.startDate, weekdates?.endDate, 1, 0, "", selectedCompany?.value, selectedTeams?.value)
        }
        // eslint-disable-next-line
    }, [selectedTeams])
    //next month
    const monthnext = () => {
        const datebyMonth = addMonths(month, 1)
        setMonth(addMonths(month, 1))
        const monthStart = startOfMonth(datebyMonth);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        let monthdays = dateRange(monthstartDate, monthendDate)
        setFinalDays(monthdays)
        getCalendarData(startOfMonth(addMonths(month, 1)), endOfMonth(addMonths(month, 1)), 24)
        getTableData(startOfMonth(addMonths(month, 1)), endOfMonth(addMonths(month, 1)), 24, 0, "")
    }
    //prevmonth
    const monthprev = () => {
        const datebyMonth = subMonths(month, 1)
        setMonth(subMonths(month, 1))
        const monthStart = startOfMonth(datebyMonth);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        let monthdays = dateRange(monthstartDate, monthendDate)
        setFinalDays(monthdays)
        getCalendarData(startOfMonth(subMonths(month, 1)), endOfMonth(subMonths(month, 1)), 24)
        getTableData(startOfMonth(subMonths(month, 1)), endOfMonth(subMonths(month, 1)), 24, 0, "")
    }

    // header functions

    const showAll = () => {
        setOpen(!open)
    }
    const setDropdownValue = (value) => {
        setSelected(value)
        setOpen(false)
        if (value === "Weekly") {
            if (select !== 'nonlist') {
                getTableData(weekdates?.startDate, weekdates?.endDate, 1, 0, "")
            } else {
                getCalendarData(weekdates?.startDate, weekdates?.endDate, 1)
            }
        } else if (value === "Monthly") {
            if (select !== 'nonlist') {
                getTableData(addDays(startOfMonth(month), 1), endOfMonth(month), 24, 0, "")
            } else {
                getCalendarData(addDays(startOfMonth(month), 1), endOfMonth(month), 24)
            }
        } else if (value === "Daily") {
            if (select !== 'nonlist') {
                getTableData(moment(daily?.date).format("YYYY-MM-DD 00:00:00"), moment(daily?.date).format("YYYY-MM-DD 23:59:59"), 1, 0, "")
            } else {
                getCalendarData(moment(daily?.date).format("YYYY-MM-DD 00:00:00"), moment(daily?.date).format("YYYY-MM-DD 23:59:59"), 1)
            }
        }
    }
    // Layout switch ( list or calendar )
    const setselect = (e) => {
        setSelect(e)
        setSelected("Weekly")
        if (e !== 'nonlist') {
            getTableData(weekdates?.startDate, weekdates?.endDate, 1, 0, "")
        } else {
            getCalendarData(weekdates?.startDate, weekdates?.endDate, 1)
        }
    }

    const getCalendarData = (startDate, endDate, duration, companyId = selectedCompany?.value, teamId = selectedTeams?.value) => {
        const payload = {
            company_id: companyId,
            team_id: teamId === "noteams" ? undefined : teamId,
            from: startDate,
            to: endDate,
            batchDuration: duration
        }
        NetworkCall(
            `${config.api_url}/activity_board/get_all_activities`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let myObject = response?.data?.data
                let result = []
                for (var key in myObject) {
                    if (myObject.hasOwnProperty(key)) {
                        result.push({
                            date: key,
                            calendarData: myObject[key]
                        })
                    }
                }
                setCalendarData(result)
            }).catch((err) => {
                console.log(err)
            })
    }

    const getTableData = (startDate, endDate, batchValue, offset, search = "", companyId = selectedCompany?.value, teamId = selectedTeams?.value) => {
        const payload = {
            company_id: companyId,
            team_id: teamId === "noteams" ? undefined : teamId,
            from: startDate,
            to: endDate,
            batchDuration: batchValue,
            offset: offset,
            limit: limit,
            search: search
        }
        NetworkCall(
            `${config.api_url}/activity_board/get_all_activities_table`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let listdata = response?.data?.data?.data?.map((e) => {
                    return {
                        img: activityLogo?.[e?.activity_name] ?? "-",
                        name: e?.activity_name ?? "-",
                        action_info: e?.type ? e?.type : e?.notes ?? "",
                        date: moment(e?.date_time).format("DD MMM YY") ?? "-",
                        createdBy: e?.first_name ?? "-",
                        priority: e?.priority ?? "-",
                        status: e?.status ?? "-",
                        ...e
                    }
                })
                setTableData({
                    list: listdata,
                    count: response?.data?.data?.count
                })
            }).catch((err) => {
                console.log(err)
            })
    }
    const handleSelected = (date) => {
        setSelectedDate(date)
        getCalendarData(moment(date).format("YYYY-MM-DD 00:00:00"), moment(date).format("YYYY-MM-DD 23:59:59"), 1)
        setOpenDailyCalendar(true)
    }

    const calendarFunctions = () => {
        if (selected === "Weekly") {
            getCalendarData(weekdates?.startDate, weekdates?.endDate, 1)
        } else if (selected === "Monthly") {
            getCalendarData(addDays(startOfMonth(month), 1), endOfMonth(month), 24)
        } else if (selected === "Daily") {
            getCalendarData(moment(daily?.date).format("YYYY-MM-DD 00:00:00"), moment(daily?.date).format("YYYY-MM-DD 23:59:59"), 1)

        }
    }

    const closePopup = () => {
        setOpenDailyCalendar(false)
        calendarFunctions()
    }
    const render = () => {
        return (
            <>
                <Box>
                    <Subheader
                        hideBackButton
                        title={t("Activity")}
                        select
                        options={companyList}
                        value={selectedCompany}
                        onchange={(e) =>
                            setSelectedCompany(e)
                        }
                        selectTeamsOptions
                        handleTeamsChange={handleTeamsChange}
                        companyId={selectedCompany?.value}
                        moduleId={moduleId}
                        selectedTeams={selectedTeams}
                    />
                </Box>
                <Box>
                    <ActivityCalendar
                        setDaily={setDaily}
                        daily={daily}
                        prevdate={prevdate}
                        nextdate={nextdate}
                        weekprev={weekprev}
                        weeknext={weeknext}
                        monthnext={monthnext}
                        monthprev={monthprev}
                        month={month}
                        weekdates={weekdates}
                        dates={dates}
                        monthdays={finalDays}
                        calendarData={calendarData}
                        searchText={searchText}
                        handleSearch={handleSearch}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        page={page}
                        limit={limit}
                        open={open}
                        select={select}
                        selected={selected}
                        setselect={setselect}
                        showAll={showAll}
                        setDropdownValue={setDropdownValue}
                        tableData={tableData}
                        handleSelected={handleSelected}
                        handleIcon={handleIcon}
                        calendarFunctions={calendarFunctions}
                        selectedCompany={selectedCompany}
                    />
                </Box>

                <AlertDialog
                    lg
                    open={openDailyCalendar}
                    onClose={() => closePopup()}
                    header={moment(selectedDate).format("DD-MM-YYYY")}
                    component={<DailyCalendar progress="0" list={calendarData} />}

                />
                <AlertDialog
                    md
                    open={openPopup}
                    onClose={() => setOpenPopup(false)}
                    header={"Activity"}
                    component={<ViewActivity data={selectedViewData} handleClose={() => setOpenPopup(false)} />}

                />
            </>
        )
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div>
}
export default withNamespaces("activityBoard")(ActivityBoard)