import React, { useState } from "react";
import {
  Subheader,
  FileManagerList,
  SearchFilter,
  FolderComponent,
  AlertDialog,
  TextBox,
  UseDebounce,
} from "../../components";
import { withNamespaces } from "react-i18next";
import {
  Typography,
  Box,
  Grid,
  Stack,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Checkbox,
  Divider,
} from "@mui/material";
import { useStyles } from "./style";
import {
  GridView,
  ListView,
  AscendingDescending,
  NoFileImage,
  TableIcon,
  TableDoc,
  Folder,
  Document
} from "../../assets";
import { TableView } from "./tableView";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { accessCheckRender, AlertProps, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { AlertContext, AuthContext } from "../../contexts";
import moment from "moment";
import { ChevronRightRounded } from "@mui/icons-material";
import { saveAs } from 'file-saver';
import { useApolloClient } from "@apollo/client";
import { UPDATE_DIRECTORY, UPDATE_DIRECTORY_ASSETS } from "../../graphql/mutations";
import { GET_DIRECTORY_ASSETS } from "../../graphql/queries";
import { DocumentViewer } from "../../components/fileViewer";
import { NewLoader } from "../../components/newLoader";

const viewType = (value) => [
  {
    icon: <GridView color={value === 0 ? "#5078E1" : "#98A0AC"} />,
  },
  {
    icon: <ListView color={value === 1 ? "#5078E1" : "#98A0AC"} />,
  },
];

const FileManager = ({ t, loading, handleLoading }) => {
  const client = useApolloClient()
  const auth = React.useContext(AuthContext)
  const alert = React.useContext(AlertContext)
  const profileID = localStorage.getItem(LocalStorageKeys.profileID)
  const classes = useStyles();
  const debounce = UseDebounce()
  const [loader, setLoader] = React.useState(true)
  const [buttonDisable, setButtonDisable] = React.useState(false)
  const [state, setState] = useState({
    id: "",
    type: "",
    searchText: "",
    viewType: 0,
    anchorEl: null,
    createDialog: false,
    editDialog: false,
    viewDialog: false,
    deleteDialog: false,
    fileDialog: false,
    fileName: "",
    fileExt: "",
    filetype: "",
    url: "",
    isPrivate: false,
    orderBy: "desc",
    updatedAt: "",
    createdAt: "",
    createdBy: "",
    noOfFiles: "",
    error: {
      fileName: "",
    },
  });
  const [listLoading, setListLoading] = React.useState(true)
  const [parentFolderList, setParentFolderList] = React.useState([])
  const [selectedFolder, setSelectedFolder] = React.useState([])
  const [subFolder, setSubFolder] = React.useState([])
  const [folderHierarchy, setFolderHierarchy] = React.useState([])
  const uploadFileRef = React.useRef(null);
  const [searchText, setSearchText] = React.useState("")
  const [permission, setPermission] = React.useState({})

  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const handleSearch = (e) => {
    setSearchText(e)
    debounce(() => searchTableFunction(e), 800)
  }

  const searchTableFunction = (e) => {
    switch (selectedFolder?.id) {
      case 0:
        getParentFolder(state?.orderBy, e)
        break;
      case 1:
        break;
      default:
        getSubFolder(selectedFolder, state?.orderBy, e)
        break;
    }
  }

  const handleClose = () => {
    updateState("anchorEl", null);
  };

  const handleCreateFolderMenu = () => {
    setState({
      ...state,
      anchorEl: null,
      createDialog: !state?.createDialog,
      fileName: "",
      isPrivate: false,
      error: {
        fileName: ""
      }
    });
  };

  const open = Boolean(state?.anchorEl);

  const handleFileDialog = (data) => {
    setState({
      ...state,
      fileDialog: true,
      fileName: data?.data?.file_meta?.file_name,
      fileExt: data?.data?.file_meta?.file_ext,
      filetype: data?.data?.file_meta?.file_type,
      url: data?.data?.url,
    })
  }

  const handleTableIcon = (type, data) => {
    switch (type) {
      case "view":
        if (data?.is_dir) {
          getSubFolder(data, state?.orderBy, searchText)
        } else {
          handleFileDialog(data)
        }
        break;
      case "edit":
        handleEdit(data)
        break;
      case "detail":
        handleDetail(data)
        break;
      case "download":
        handleDownload(data)
        break;
      case "delete":
        handleDelete(data)
        break;
      default:
        break;
    }
  }

  const handleIconView = (data) => {
    if (data?.is_dir) {
      getSubFolder(data, state?.orderBy, searchText)
    } else {
      handleFileDialog(data)
    }
  }

  const handleEdit = (data) => {
    setState({
      ...state,
      id: data?.id,
      type: data?.is_dir ? "Folder" : "File",
      editDialog: true,
      fileName: data?.Folder_Name,
      isPrivate: data?.is_private,
      error: {
        fileName: "",
      },
    })
  }

  const editFolder = () => {
    setButtonDisable(true)
    client.mutate({
      mutation: UPDATE_DIRECTORY,
      variables: {
        ID: state.id,
        updatePayload: {
          name: state?.fileName,
          is_private: state?.isPrivate,
          updated_by: profileID,
          updated_at: new Date().toISOString()
        }
      }
    }).then((res) => {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.success,
        msg: "Folder Edited Successfully.",
      })
      closeEditDialog()
      setButtonDisable(false)
      getSubFolder(selectedFolder, state?.orderBy, searchText)
    }).catch((err) => {
      console.log(err)
      setButtonDisable(false)
    })
  }

  const editFile = () => {
    setButtonDisable(true)
    client.query({
      query: GET_DIRECTORY_ASSETS,
      fetchPolicy: 'network-only',
      variables: { directory_asset_ID: state?.id }
    }).then((res) => {
      let tempFileMeta = res?.data?.directory_assets?.[0]?.file_meta
      if (tempFileMeta?.file_name) {
        client.mutate({
          mutation: UPDATE_DIRECTORY_ASSETS,
          variables: {
            ID: state.id,
            updatePayload: {
              is_private: state?.isPrivate,
              file_meta: { ...tempFileMeta, file_name: state?.fileName },
              updated_by: profileID,
              updated_at: new Date().toISOString()
            }
          }
        }).then((res) => {
          alert.setSnack({
            ...alert, open: true,
            severity: AlertProps.severity.success,
            msg: "File Edited Successfully.",
          })
          closeEditDialog()
          setButtonDisable(false)
          getSubFolder(selectedFolder, state?.orderBy, searchText)
        }).catch((err) => {
          console.log(err)
          setButtonDisable(false)
        })
      } else {
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong.",
        })
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleEditButton = () => {
    switch (state?.type) {
      case "Folder":
        editFolder()
        break;
      case "File":
        editFile()
        break;
      default:
        break;
    }
  }

  const handleDetail = (data) => {
    setState({
      ...state,
      id: data?.id,
      type: data?.is_dir ? "Folder" : "File",
      viewDialog: true,
      fileName: data?.Folder_Name,
      updatedAt: data?.Date_Modified,
      createdAt: data?.createdAt,
      createdBy: data?.Created_By,
      noOfFiles: data?.Number_Files,
    })
  }

  const handleDownload = (data) => {
    saveAs(data?.data?.url, `${data?.data?.file_meta?.file_name}.${data?.data?.file_meta?.file_ext}`);
  }

  const handleDelete = (data) => {
    setState({
      ...state,
      id: data?.id,
      type: data?.is_dir ? "Folder" : "File",
      deleteDialog: true,
      fileName: data?.Folder_Name,
    })
  }

  const deleteFolderFile = () => {
    setButtonDisable(true)
    client.mutate({
      mutation: state?.type === "Folder" ? UPDATE_DIRECTORY : UPDATE_DIRECTORY_ASSETS,
      variables: {
        ID: state.id,
        updatePayload: {
          is_active: false,
          is_delete: true,
          updated_by: profileID,
          updated_at: new Date().toISOString()
        }
      }
    }).then((res) => {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.success,
        msg: `${state?.type} Deleted Successfully.`,
      })
      closeDeleteDialog()
      setButtonDisable(false)
      getSubFolder(selectedFolder, state?.orderBy, searchText)
    }).catch((err) => {
      console.log(err)
      setButtonDisable(false)
    })
  }

  const viewComponents = {
    image: <img
      style={{
        objectFit: "contain",
        height: "600px",
        width: "100%",
      }}
      alt={`${state?.fileName}.${state?.fileExt}`}
      src={state.url} />,
    application: <DocumentViewer url={state.url} />,
    video: <video
      style={{
        objectFit: "contain",
        height: "600px",
        width: "100%",
      }}
      controls>
      <source src={state.url} type={`video/${state?.fileExt}`} />
    </video>,
  };

  const components = {
    0: (
      <Grid alignContent={"start"} container spacing={3} className={classes.iconViewParent}>
        {subFolder?.list?.map((item) => {
          return <Grid item xs={2}>
            <FolderComponent
              t={t}
              data={item}
              handleIconView={handleIconView}
              selectedFolder={selectedFolder}
              handleEdit={handleEdit}
              handleDetail={handleDetail}
              handleDownload={handleDownload}
              handleDelete={handleDelete} />
          </Grid>
        })}
      </Grid>
    ),
    1: <TableView t={t} selectedFolder={selectedFolder} data={subFolder} handleTableIcon={handleTableIcon} />,
  };

  const detailsFile = [
    {
      title: t("DATE_CREATED_ON"),
      sub: state?.createdAt,
      is_active: true
    },
    {
      title: t("CREATED_BY"),
      sub: state?.createdBy,
      is_active: true
    },
    {
      title: t("NUMBER_OF_FILES"),
      sub: state?.noOfFiles,
      is_active: state?.type === "Folder" ? true : false
    },
  ];

  const getParentFolder = (orderBy = state?.orderBy, search = searchText) => {
    setListLoading(true)
    let module = Object.keys(auth?.auth?.auth?.access)?.[0]
    let property_ids = auth?.auth?.auth?.access?.[module]?.property_ids
    const payload = {
      property_ids: property_ids,
      is_private: [false, true],
      search: search,
      order_by: orderBy,
      offset: 0,
      limit: 1000
    }
    NetworkCall(
      `${config.api_url}/asset_management/property_access_directories`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      const tempParentList = [
        {
          id: 0,
          name: "Properties",
          children: res?.data?.data?.data?.map((i) => {
            return {
              id: i?.id,
              is_dir: true,
              name: i?.name ?? "-",
              icon: <TableIcon />,
              Folder_Name: i?.name,
              Created_By: i?.created_by ?? "-",
              createdAt: i?.created_at ? moment(i?.created_at).format("DD MMM YYYY") : "-",
              Date_Modified: i?.updated_at ? moment(i?.updated_at).format("DD MMM YYYY") : "-",
              Number_Files: i?.no_of_files ?? "-",
              data: i
            }
          })
        },
        {
          id: 1,
          name: "My Documents",
          children: [],
        }
      ]
      const tempFolderHierarchy = [
        {
          id: 0,
          name: "Properties"
        },
      ]
      setSelectedFolder(tempFolderHierarchy?.[0])
      setFolderHierarchy(tempFolderHierarchy)
      setParentFolderList(tempParentList)
      setSubFolder({
        count: res?.data?.data?.count,
        list: tempParentList?.[0]?.children
      })
      setLoader(false)
      setListLoading(false)
      handleLoading(false)
    }).catch((err) => {
      console.log(err)
    })
  }

  const getSubFolder = (directory, orderBy = state?.orderBy, search = searchText) => {
    setListLoading(true)
    const payload = {
      directory_id: directory?.id,
      is_private: [false, true],
      search: search,
      order_by: orderBy,
      offset: 0,
      limit: 1000
    }
    NetworkCall(
      `${config.api_url}/asset_management/fetch`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      const tempRes = res?.data?.data
      const tempList = tempRes?.data?.map((i) => {
        return {
          id: i?.id,
          is_dir: i?.is_dir,
          is_private: i?.is_private,
          icon: i?.is_dir ? <TableIcon /> : <TableDoc />,
          Folder_Name: i?.is_dir ? i?.name : i?.file_meta?.file_name,
          Created_By: i?.created_by ?? "-",
          createdAt: i?.created_at ? moment(i?.created_at).format("DD MMM YYYY") : "-",
          Date_Modified: i?.updated_at ? moment(i?.updated_at).format("DD MMM YYYY") : "-",
          Number_Files: i?.no_of_files ?? "-",
          data: i
        }
      })
      const tempBreadCrumbs = tempRes?.bread_crumbs.reverse()
      const tempFolderHierarchy = [
        {
          id: 0,
          name: "Properties"
        },
        ...tempBreadCrumbs
      ]
      setSelectedFolder(directory)
      setFolderHierarchy(tempFolderHierarchy)
      setSubFolder({
        count: res?.data?.data?.count,
        list: tempList
      })
      setLoader(false)
      setListLoading(false)
    }).catch((err) => {
      console.log(err)
    })
  }

  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        if (auth?.auth?.auth?.access) {
          getParentFolder(state?.orderBy, searchText)
        }
      }
    }
    // eslint-disable-next-line
  }, [auth])

  const handleOnFolderClick = (directory) => {
    if (![0, 1, selectedFolder?.id].includes(directory?.id)) {
      getSubFolder(directory, state?.orderBy, searchText)
    }
  }

  const handleHierarchyClick = (directory) => {
    if (selectedFolder?.id !== directory?.id) {
      switch (directory?.id) {
        case 0:
          getParentFolder(state?.orderBy, searchText)
          break;
        case 1:
          break;
        default:
          getSubFolder(directory, state?.orderBy, searchText)
          break;
      }
    }
  }

  const handleFileUploadMenu = () => {
    uploadFileRef.current.click()
    handleClose()
  };

  const uploadFile = (upload_file) => {
    setListLoading(true)
    const formData = new FormData();
    formData.append("directory_id", selectedFolder?.id);
    formData.append("is_private", state?.isPrivate);
    formData.append("created_by", profileID);
    formData.append("files", upload_file);
    NetworkCall(
      `${config.api_url}/asset_management/create_file`,
      NetWorkCallMethods.post,
      formData, null, true, false
    ).then((res) => {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.success,
        msg: "File Uploaded Successfully.",
      })
      getSubFolder(selectedFolder, state?.orderBy, searchText)
      setListLoading(false)
    }).catch((error) => {
      console.log(error);
      setListLoading(false)
    });
  }

  const closeCreateDialog = () => {
    setState({
      ...state,
      createDialog: false,
      fileName: "",
      isPrivate: false,
      error: {
        fileName: "",
      },
    })
  }

  const closeEditDialog = () => {
    setState({
      ...state,
      id: "",
      type: "",
      editDialog: false,
      fileName: "",
      isPrivate: false,
      error: {
        fileName: "",
      },
    })
  }

  const closeViewDialog = () => {
    setState({
      ...state,
      id: "",
      type: "",
      viewDialog: false,
      fileName: "",
      updatedAt: "",
      createdAt: "",
      createdBy: "",
      noOfFiles: ""
    })
  }

  const closeDeleteDialog = () => {
    setState({
      ...state,
      id: "",
      type: "",
      deleteDialog: false,
      fileName: ""
    })
  }

  const closeFileDialog = () => {
    setState({
      ...state,
      fileDialog: false,
      fileName: "",
      fileExt: "",
      filetype: "",
      url: "",
    })
  }

  const validate = () => {
    let isValid = true;
    let error = state.error;
    if (state?.fileName?.length === 0) { isValid = false; error.fileName = "Folder Name is Required"; }
    setState({ ...state, error });
    return isValid;
  };

  const createFolder = () => {
    if (validate()) {
      setButtonDisable(true)
      const payload = {
        parent_id: selectedFolder?.id,
        is_private: state?.isPrivate,
        name: state?.fileName
      }
      NetworkCall(
        `${config.api_url}/asset_management/create_folder`,
        NetWorkCallMethods.post,
        payload, null, true, false
      ).then((res) => {
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.success,
          msg: "Folder Created Successfully.",
        })
        getSubFolder(selectedFolder, state?.orderBy, searchText)
        closeCreateDialog()
        setButtonDisable(false)
      }).catch((err) => {
        console.log(err)
        setButtonDisable(false)
      })
    } else {
      return false
    }
  }

  const render = () => {
    return (
      <Box>
        <Subheader title={`${t("File_Manager")}`} hideBackButton={true} />
        {/* file body */}
        {
          loader ? (
            <NewLoader minusHeight="100px" />
          ) : (
            <div className={classes.root}>
              <Box className={classes.catagoryTilte}>
                <Stack direction={"row"} flexWrap={"wrap"}
                  divider={<ChevronRightRounded htmlColor="#98A0AC" />}>
                  {folderHierarchy?.map((item, index, array) => {
                    return <Typography
                      className={(index === array?.length - 1) ?
                        classes.active_hierarchy :
                        classes.inactive_hierarchy}
                      onClick={() => handleHierarchyClick(item)}>
                      {item?.name}
                    </Typography>
                  })}
                </Stack>
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Box>
                      <FileManagerList
                        data={parentFolderList}
                        handleOnFolderClick={handleOnFolderClick}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={9}>
                    <Box className={classes.paddingRoot}>
                      {/* filter header */}
                      <Grid container spacing={3}>
                        <Grid item xs={5}>
                          <Box display="flex" alignItems="center">
                            <SearchFilter
                              value={searchText}
                              placeholder={t("Search")}
                              handleChange={(value) => handleSearch(value)}
                            />
                            <Box width={"14px"} />
                            {![0, 1].includes(selectedFolder?.id) && <Button
                              className={classes.btn}
                              variant="contained"
                              onClick={(e) => {
                                updateState("anchorEl", e.currentTarget);
                              }}
                            >
                              {t("Add_New")}
                            </Button>}
                            <Menu
                              anchorEl={state?.anchorEl}
                              open={open}
                              onClose={handleClose}
                              className={classes.menuList}
                            >
                              <MenuItem
                                onClick={handleFileUploadMenu}
                                className={classes.menuItem}>
                                {t("Upload")}
                              </MenuItem>
                              <MenuItem
                                onClick={handleCreateFolderMenu}
                                className={classes.menuItem}
                              >
                                {t("Create")}
                              </MenuItem>
                            </Menu>
                            <input type='file' id='file_upload'
                              ref={uploadFileRef} style={{ display: 'none' }}
                              onChange={(e) => {
                                uploadFile(e?.target?.files?.[0])
                              }}
                              onClick={(event) => { event.target.value = null }} />
                          </Box>
                        </Grid>
                        <Grid item xs={7}>
                          <Box
                            display={"flex"}
                            alignItems="center"
                            sx={{ float: "right" }}
                          >
                            <Box>
                              <IconButton size="small" className={classes.img}
                                onClick={() => {
                                  let tempOrderBy = state?.orderBy === "asc" ? "desc" : "asc"
                                  setState({ ...state, orderBy: tempOrderBy })
                                  switch (selectedFolder?.id) {
                                    case 0:
                                      getParentFolder(tempOrderBy, searchText)
                                      break;
                                    case 1:
                                      break;
                                    default:
                                      getSubFolder(selectedFolder, tempOrderBy, searchText)
                                      break;
                                  }
                                }}>
                                <AscendingDescending fill={state?.orderBy === "desc" && "#5078e1"} />
                              </IconButton>
                            </Box>
                            <Box width={"8px"} />
                            <Box className={classes.viewType}>
                              <Stack direction="row" spacing={1}>
                                {viewType(state?.viewType)?.map((x, i) => {
                                  return (
                                    <IconButton
                                      size="small"
                                      className={classes.viewIcon}
                                      onClick={() => updateState("viewType", i)}
                                      style={{
                                        backgroundColor:
                                          i === state?.viewType ? "#F1F7FF" : "#fff",
                                      }}
                                    >
                                      {x?.icon}
                                    </IconButton>
                                  );
                                })}
                              </Stack>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      {/*  */}
                      <Typography className={classes.fileManager}>
                        {t("Files")}
                      </Typography>
                      {state?.viewType === 0 && subFolder?.count > 0 && <Box height={"12px"} />}
                      {listLoading ? (<NewLoader minusHeight="450px" />) :
                        (subFolder?.count > 0 ? (components[state?.viewType]) : (
                          <Box
                            style={{
                              height: `calc(100vh - 400px)`,
                              textAlign: "center",
                            }}
                          >
                            <Box
                              display="flex"
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <NoFileImage />
                            </Box>
                            {![0, 1].includes(selectedFolder?.id) && <Button className={classes.btn}
                              onClick={(e) => {
                                updateState("anchorEl", e.currentTarget);
                              }}>
                              {t("Add_New")}
                            </Button>}
                          </Box>
                        ))}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </div>)}
        <AlertDialog
          isNormal
          header={t("Create")}
          onClose={closeCreateDialog}
          open={state?.createDialog}
          component={
            <Box p={2}>
              <TextBox
                isrequired
                label={t("Folder_Name")}
                placeholder={t("Folder_Name")}
                value={state?.fileName ?? ""}
                onChange={(e) => {
                  updateState("fileName", e.target.value);
                }}
                isError={state?.error?.fileName?.length > 0}
                errorMessage={state?.error?.fileName}
              />
              <Box height={"20px"} />
              <Stack direction={"row"} spacing={"10px"} alignItems={"center"}
                onClick={() => updateState("isPrivate", !state?.isPrivate)}>
                <Checkbox
                  checked={state?.isPrivate}
                  onChange={(e) => updateState("isPrivate", e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography className={classes.isPrivateCheckBox}>Private</Typography>
              </Stack>
              <Box height={"20px"} />
              <Box
                display="flex"
                alignItems="center"
                justifyContant="space-between"
              >
                <Button
                  variant="outlined"
                  className={classes.outLine}
                  onClick={closeCreateDialog}
                >
                  {t("Cancel")}
                </Button>
                <Box width={"12px"} />
                <Button variant="contained" className={classes.create}
                  disabled={buttonDisable}
                  onClick={createFolder}>
                  {t("folder_create")}
                </Button>
              </Box>
            </Box>
          }
        />
        <AlertDialog
          isNormal
          header={"Edit"}
          onClose={closeEditDialog}
          open={state?.editDialog}
          component={
            <Box p={2}>
              <TextBox
                isrequired
                label={`${state?.type} Name`}
                placeholder={`Enter ${state?.type} Name`}
                value={state?.fileName ?? ""}
                onChange={(e) => {
                  updateState("fileName", e.target.value);
                }}
                isError={state?.error?.fileName?.length > 0}
                errorMessage={state?.error?.fileName}
              />
              <Box height={"20px"} />
              <Stack direction={"row"} spacing={"10px"} alignItems={"center"}
                onClick={() => updateState("isPrivate", !state?.isPrivate)}>
                <Checkbox
                  checked={state?.isPrivate}
                  onChange={(e) => updateState("isPrivate", e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography className={classes.isPrivateCheckBox}>Private</Typography>
              </Stack>
              <Box height={"20px"} />
              <Box
                display="flex"
                alignItems="center"
                justifyContant="space-between"
              >
                <Button
                  variant="outlined"
                  className={classes.outLine}
                  onClick={closeEditDialog}
                >
                  {t("Cancel")}
                </Button>
                <Box width={"12px"} />
                <Button variant="contained" className={classes.create}
                  disabled={buttonDisable}
                  onClick={handleEditButton}>
                  {"Edit"}
                </Button>
              </Box>
            </Box>
          }
        />
        <AlertDialog
          isNormal
          header={"Delete"}
          onClose={closeDeleteDialog}
          open={state?.deleteDialog}
          component={
            <Box p={2}>
              <Typography className={classes.deleteQuestion}>
                {`Are you sure you want to delete ${state?.fileName} ${state?.type}?`}
              </Typography>
              <Box height={"40px"} />
              <Box
                display="flex"
                alignItems="center"
                justifyContant="space-between"
              >
                <Button
                  variant="outlined"
                  className={classes.outLine}
                  onClick={closeDeleteDialog}
                >
                  {t("Cancel")}
                </Button>
                <Box width={"12px"} />
                <Button variant="contained" className={classes.create}
                  disabled={buttonDisable}
                  onClick={deleteFolderFile}>
                  {"Delete"}
                </Button>
              </Box>
            </Box>
          }
        />
        <AlertDialog
          isNormal
          header={`${state?.type} Details`}
          onClose={closeViewDialog}
          open={state?.viewDialog}
          component={
            <Box>
              <Box display="flex" alignItems="center" className={classes.detailsBox}>
                {
                  state?.type === "Folder" ? <Folder /> : <Document />
                }
                <Box width={"16px"} />
                <Box>
                  <Typography className={classes.viewFileTilte} noWrap>
                    {state?.fileName}
                  </Typography>
                  <Box height={"8px"} />
                  <Typography className={classes.viewFileSub}>
                    Last Modified on {state?.updatedAt}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Grid container spacing={1}>
                  {detailsFile?.map((x) => {
                    return (
                      <>
                        {
                          x?.is_active &&
                          <Grid item xs={4}>
                            <Box p={2}>
                              <Typography className={classes.viewDetailsTilte} noWrap>
                                {x?.title}
                              </Typography>
                              <Box height={"8px"} />
                              <Typography className={classes.viewDetailsSub} noWrap>
                                {x?.sub}
                              </Typography>
                            </Box>
                          </Grid>
                        }
                      </>
                    )
                  })}
                </Grid>
                <Divider />
              </Box>
            </Box>
          }
        />
        <AlertDialog
          isNormal
          header={`${state?.fileName}.${state?.fileExt}`}
          onClose={closeFileDialog}
          open={state?.fileDialog}
          component={viewComponents[state?.filetype]}
        />
      </Box>
    );
  }

  return (
    <>
      {accessCheckRender(render, permission, "", loading)}
    </>
  )

};
export default withNamespaces("fileManager")(FileManager);
