import { Box, Grid } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { Subheader } from "../../../components";
import { getSettingList } from "../../../utils/agreementUtils";
import { SettingList } from "../../companyCreation/components";
import { AgreementDocument, AgreementInformation, BillingInformation, CustomerInformation, OppertunityInformation, QuotationInformation, QuotationPreview, RenewalInformation, ReservationInfo, RevenueSummery } from "../agreementPreviewTabs";
import { AggrementTabStyles } from "./styles";

const AgreementPreview = ({ details = {}, id = "", reload = () => false, t }) => {
    const classes = AggrementTabStyles();
    //get preview list
    const list = getSettingList(t);
    const [selected, setSelected] = React.useState(list[0]);

    const sections = {
        1: <CustomerInformation details={details} />,
        2: <OppertunityInformation details={details?.quotation?.lead} />,
        3: <QuotationInformation details={details?.quotation} />,
        4: <ReservationInfo details={details?.quotation?.reservation?.[0]} />,
        5: <AgreementInformation details={details} />,
        6: <BillingInformation reload={reload} id={id} details={details?.quotation} agree={details} />,
        7: <RenewalInformation reload={reload} details={details?.quotation} />,
        8: <RevenueSummery details={details} />,
        9: <QuotationPreview details={details?.quotation} />,
        10: <AgreementDocument details={details} />
    }
    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={2.5}>
                    <Box className={classes.box}>
                        {/*tab list */}
                        <SettingList searchBool={false} list={list} selected={selected?.value} setSelected={setSelected} />
                    </Box>

                </Grid>
                <Grid item xs={9.5}>
                    <Box className={classes.box}>
                        {/*sub header */}
                        <Subheader title={selected?.label} hideBackButton={true} />
                        <Box className={classes.box1}>
                            {sections[selected?.value]}
                        </Box>
                    </Box>
                </Grid>

            </Grid>

        </Box>
    )
}
export default withNamespaces("agreement")(AgreementPreview); 