import gql from "graphql-tag";
export const CREATE_CONTACT = gql`
  mutation($insert: [prospectiveInput!]) {
    prospective(insert: $insert ) {
      id
    }
  }`

export const CREATE_LEAD = gql`
  mutation($insert: [prospectiveInput!]) {
    lead(insert: $insert ) {
      id
    }
  }`

export const CREATE_SHORTLIST = gql`
  mutation {
    lead_units(insert: $insert ) {
      id
    }
  }`


export const UPDATE_LEAD = gql`
mutation {
    lead(where: { id: { eq: $id } }update: $updatePayload) {
      id
    }
}`;

export const UPDATE_PROSPECT = gql`
mutation {
    prospective(where: { id: { eq: $id } } update: $updatePayload) {
      id
    }
}`;

export const ADD_COMMAND_PROXY_LEAD = gql`
  mutation($id: String, $data: proxy_leadInput) {
    proxy_lead(where: { id: { eq: $id } }, update: $data) {
      id
    }
  }
  `

export const CHECK_PROSPECTIVE = gql`
query{
  prospective(where:{email_id:$email_id}){
    id
    first_name
    last_name
    email_id
    facebook
    twitter
    linkedin
    mobile_no
    mobile_no_country_code
    phone
    phone_code
    alternative_mobile
    alternative_mobile_country_code
    fax_no
    addressLineOne:street_1
    addressLineTwo: street_2
    street_3
    landmark
    district
    country
    latitude
    longitude
  }
}
`
export const UPDATE_LEAD_UNIT = gql`
mutation {
  lead_units(where: { unit_id: { in: $id } , lead_id : {eq:$lead_id}} update: $updatePayload) {
      id
    }
}`;