import { useApolloClient } from "@apollo/client";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Box, Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import React from 'react';
import { withNamespaces } from "react-i18next";
import { AlertDialog, DetailsCardCoponent, FormGenerator, LoadingSection, Subheader, UseDebounce } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { CHEQUE_INSERT, GET_MANAGED_INVOICES, UPDATE_CHEQUE } from "../../../graphql/agreementQurery";
import { NetworkCall } from "../../../networkcall/index";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { ManagedInvoiceTable, PostCard } from "../components";
import { AggrementTabStyles } from "./styles";
const initialState = {
  id: null,
  cheque_name: "",
  bank_name: "",
  error: {
    cheque_name: "",
    bank_name: "",
  }
}

const ManagedInvoice = ({ quot_id = "", agree = {}, id, t }) => {
  const classes = AggrementTabStyles();
  const client = useApolloClient();
  const alert = React.useContext(AlertContext);
  const debounce = UseDebounce();
  const [searchText, setSearchText] = React.useState("");
  const [openPost, setOpenPost] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [data, setData] = React.useState({ ...initialState });
  const [loading, setLoading] = React.useState(true)
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);


  const [list, setList] = React.useState({
    list: [],
    count: 0
  })
  const [selectedInvoices, setSelectedInvoices] = React.useState(null)
  const card = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("referenceNo"),
      subtitle: `${selectedInvoices?.schedule_no}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("name"),
      subtitle: `${selectedInvoices?.name}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "date",
      heading: t("dueDate"),
      subtitle: `${selectedInvoices?.due_date}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("billingAmount"),
      subtitle: `${selectedInvoices?.currency?.symbol} ${selectedInvoices?.total}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "tag",
      heading: t("status"),
      status: `${selectedInvoices?.status}`,
      color: "#FF9340",
    },
  ];
  const validateForm = () => {
    let isValid = true;
    let error = data.error;
    if (data?.bank_name?.length === 0) {
      isValid = false;
      error.bank_name = t("bank_name_required");
    }
    if (data?.cheque_name?.length === 0) {
      isValid = false;
      error.cheque_name = t("chque_name_required");
    }
    setData({ ...data, error });
    return isValid;
  };
  //update state
  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  };
  //get managed invoices
  const getManagedInvoices = (offset = 0, limit = 10, search = "") => {
    client.query({
      query: GET_MANAGED_INVOICES,
      fetchPolicy: 'network-only',
      variables: {
        id: id,
        offset: offset,
        limit: limit,
        search: search
      }
    }).then((response) => {
      const datas = response?.data?.quotation_payment_schedule?.[0]?.payment_schedule_cheques?.[0];
      setList({
        list: response?.data?.quotation_payment_schedule,
        count: response?.data?.count?.[0]?.count
      })
      setSelectedInvoices(response?.data?.quotation_payment_schedule?.[0])
      setData({
        ...data,
        cheque_name: datas?.cheque_name ?? "",
        bank_name: datas?.bank_name ?? "",
        id: datas?.id ?? null,
      })
      setLoading(false)
    }).catch((err) => {
      console.log(err)
    })
  }
  //insert cheque
  const insertCheque = () => {
    client.mutate({
      mutation: CHEQUE_INSERT,
      variables: {
        "insert": [
          {
            payment_schedule: selectedInvoices?.id,
            cheque_name: data?.cheque_name,
            bank_name: data?.bank_name,
          }
        ]
      }
    }).then((response) => {
      setOpen(false)
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: "Cheque Created",
      });
    }).catch((err) => {
      console.log(err)
    })
  }
  //update cheque
  const updateCheque = () => {
    client.mutate({
      mutation: UPDATE_CHEQUE,
      variables: {
        id: data?.id,
        "payload": {
          cheque_name: data?.cheque_name,
          bank_name: data?.bank_name,
        }
      }
    }).then((response) => {
      setOpen(false)
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: "Cheque Updated",
      });
    }).catch((err) => {
      console.log(err)
    })
  }
  //sumbit form
  const onSubmit = () => {
    if (validateForm()) {
      if (data?.id) {
        updateCheque()
      } else {
        insertCheque()
      }

    } else {
      return false
    }
  }
  const formJson = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive: true,
      component: "text",
      label: t("cheque_no"),
      placeholder: t("enter_cheque_no"),
      value: data?.cheque_name,
      onChange: (value) => updateState("cheque_name", value?.target?.value),
      error: data?.error?.cheque_name,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive: true,
      component: "text",
      label: t("bank_name"),
      placeholder: t("enter_bank_name"),
      value: data?.bank_name,
      onChange: (value) => updateState("bank_name", value?.target?.value),
      error: data?.error?.bank_name,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive: true,
      component: "button",
      label: t("update_cheque_details"),
      onClick: () => onSubmit()
    },
  ]
  //initial table load
  React.useEffect(() => {
    getManagedInvoices();
    // eslint-disable-next-line
  }, [])
  //handle pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getManagedInvoices(offset, limit, "")
  }
  //on change limit
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getManagedInvoices(0, value, "")
  }
  //on search
  const handleSearch = (e) => {
    setSearchText(e)
    debounce(() => searchTableFunction(e), 800)
  }
  //search function
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getManagedInvoices(0, 10, e)
  }
  //post to invoice
  const postToinvoice = () => {
    const variables = {
      "agreement_id": agree?.id,
      "payment_schedule": {
        "id": selectedInvoices?.id,
        "name": selectedInvoices?.name,
        "payment_due_date": selectedInvoices?.due_date,
        "total_amount": selectedInvoices?.total
      }
    }
    NetworkCall(
      `${config.api_url}/invoice/post_to_invoice`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    ).then((res) => {
      setOpenPost(false)

      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: "Invoice Converted Successfully",
      });
      getManagedInvoices();
    }).catch((err) => {
      console.log(err)
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Invoice Cannot be Converted",
      });
    })
  }

  return (
    <Box className={classes.kycbox}>
      {
        loading ?
          <LoadingSection top="20vh" message={t("managedLoading")} />
          :

          <Grid container>
            <Grid item xs={7} borderRight="1px solid #E4E8EE">
              <ManagedInvoiceTable
                handleChangeLimit={handleChangeLimit}
                handlePagination={handlePagination}
                page={page}
                list={list}
                limit={limit}
                handleSearch={handleSearch}
                searchText={searchText}
                setSelectedInvoices={setSelectedInvoices}
                setData={setData}
                data={data}
              />
            </Grid>
            <Grid item xs={5}>
              {
                selectedInvoices &&

                <Box>
                  <Subheader
                    hideBackButton={true}
                    title={selectedInvoices?.schedule_no}
                  />
                  <Box className={classes.managedInvoiceRoot}>
                    <DetailsCardCoponent
                      border={false}
                      components={card}
                      title={t("PERFORMADETAILS")}
                    />
                    <Box height="12px" />
                    {
                      agree?.payment_mode === "Cheque" &&
                      <Box>
                        {
                          data?.cheque_name?.length > 0 && data?.cheque_name?.length > 0 ?
                            <PostCard
                              icon={<ContactMailIcon sx={{ color: "#7D534C" }} />}
                              title={data?.cheque_name}
                              description={data?.bank_name}
                              status={t("edit")}
                              bgColor="#FEEAEA80"
                              onClick={() => setOpen(!open)}
                            />
                            :
                            <PostCard
                              icon={<ContactMailIcon sx={{ color: "#7D534C" }} />}
                              title={t("postDatedCheque")}
                              description={t("payment_by_post_dated_cheque")}
                              status={t("update_cheque_details")}
                              bgColor="#FEEAEA80"
                              onClick={() => setOpen(!open)}
                            />
                        }



                      </Box>
                    }

                  </Box>
                  <Box p={2}>
                    <Button disabled={selectedInvoices?.status === "Posted"} onClick={() => setOpenPost(true)} variant="contained" fullWidth className={classes.submit}>
                      {t("post_to_invoice")}
                    </Button>
                  </Box>
                </Box>
              }
            </Grid>
          </Grid>
      }
      {/*Cheque Details */}
      <AlertDialog
        onClose={() => setOpen(!open)}
        open={open}
        header={t("cheque_details_updataion")}
        medium={true}
        component={
          <Box p={2}>
            <FormGenerator components={formJson} />
          </Box>
        }
      />
      {/*post to invoice */}
      <AlertDialog
        onClose={() => setOpenPost(false)}
        open={openPost}
        header="Post To Invoice"
        isNormal={true}
        component={
          <Box p={2}>
            <Typography className={classes.title}>
              {
                moment(selectedInvoices?.due_date).isAfter(new Date()) ?
                  t("cheque_sub") :
                  t("cheque_sub1")
              }

            </Typography>
            <Grid container spacing={2} marginTop="12px">
              <Grid item xs={6}>
                <Button onClick={postToinvoice} className={classes.btn} variant="contained" fullWidth color="success">{t("yes")}</Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() => setOpenPost(false)} className={classes.btn} variant="contained" fullWidth color="error">{t("no")}</Button>
              </Grid>
            </Grid>
          </Box>
        }
      />

    </Box>


  );
};
export default withNamespaces("agreement")(ManagedInvoice); 