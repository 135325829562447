import React, { useEffect, useState } from "react";
import { dashboardStyles } from "./style";
import { Grid } from "@mui/material";
import {
  RevenueCard,
  GraphComponentRevenue,
} from "../leaseandsalesmanagerdashboard/component";
import { NetWorkCallMethods, AlertProps } from "../../utils";
import { AlertContext } from "../../contexts";
import {
  CardDetails,
  GraphComponent,
} from "../propertymanagerdashboard/components";
import moment from "moment-timezone";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { stratureData } from "./common";
import { DialogBox } from "../../components";
import { GateInCharge } from "./gateInCharge";
import { GateInSecurity } from "./gateInSecurity";
import { NewLoader } from "../../components/newLoader";

const scale = {
  x: {
    stacked: true,
  },
  y: {
    stacked: true,
  },
  xAxes: [
    {
      gridLines: { display: false },
    },
  ],
  yAxes: [
    {
      gridLines: {
        drawBorder: false,
      },
      ticks: {
        min: 30,
      },
    },
  ],
};
export const Dashboard = (props) => {
  // classes
  const classes = dashboardStyles();
  const [details, setDetails] = useState({});
  const [zoom, setZoom] = useState(false);
  const [zoomGraph, setZoomGraph] = useState("");
  const [loader, setLoader] = useState(false);
  // context
  const alert = React.useContext(AlertContext);

  // get
  const getDetails = (company) => {
    setLoader(true);
    const payload = {
      company_id: company,
      from_date: `${moment(props?.calandervalue?.value?.from_date).format(
        "YYYY-MM-DD"
      )} 00:00:00`,
      to_date: `${moment(props?.calandervalue?.value?.to_date).format(
        "YYYY-MM-DD"
      )} 23:59:59`,
    };
    NetworkCall(
      `${config.api_url}/dashboard/security/stats`,
      NetWorkCallMethods.post,
      payload,
      { "x-build-code": config.app_x_build },
      true,
      false
    )
      .then((res) => {
        const resut = stratureData(res?.data?.data);
        setDetails(resut);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  const zoomGraphFunction = (e) => {
    setZoomGraph(e);
    setZoom(true);
  };

  // close popup
  const handleCloseModule = () => {
    setZoom(false);
  };

  useEffect(() => {
    if (props?.company?.label?.length > 0) {
      getDetails(props?.company?.value);
    }
    //eslint-disable-next-line
  }, [props?.company, props?.calandervalue]);
  return (loader ?
    (
      <NewLoader minusHeight="100px" />
    ) :
    (<div className={classes.root}>
      {/* section1 */}
      <Grid container spacing={2}>
        <Grid item lg={1.5}>
          <Grid container spacing={2}>
            {details?.visitorCard?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} maxWidth={"100%"} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {/* Entries With Check-In Only */}
        <Grid item sm={4} lg={4.5}>
          <GraphComponent
            title={"Entries With Check-In Only"}
            graphData={details?.checkInEntiries ?? []}
            margin={"20px"}
            isBar
            isZoom
            isLegend
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        {/* Entries With Check-In And Check -Out */}
        <Grid item sm={4} lg={4.5}>
          <GraphComponent
            title={"Entries With Check-In And Check -Out"}
            graphData={details?.checkInAndOutEntiries ?? []}
            margin={"20px"}
            isBar
            isZoom
            isLegend
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        <Grid item lg={1.5}>
          <Grid container spacing={2}>
            {details?.agreementCard?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} maxHeight={"117px"} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      {/* section 2 */}
      <Grid container spacing={2} mt={0.3}>
        <Grid item sm={6} lg={3}>
          {/* Requests */}
          <Grid container spacing={2}>
            {details?.Requests?.map((x) => {
              return (
                <Grid item xs={12}>
                  <RevenueCard
                    height={"140px"}
                    sub={x?.sub}
                    sub1={x?.sub1}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item sm={6} lg={3}>
          <GraphComponent
            title={"Visitor Requests"}
            graphData={details?.visitor_requests ?? []}
            isPie
            margin={"20px"}
            onZoomClick={zoomGraphFunction}
            innerRadius={50}
            isZoom
            isTotal
            total={details?.visitor_requestsTotal ?? 0}
            maxWidthGraph={"288px"}
            gridGraphParentPadding={"10px"}
          />
        </Grid>
        <Grid item sm={6} lg={3}>
          <GraphComponentRevenue
            title={"Worker Requests"}
            type={"bar"}
            height={details?.workersRequest?.length > 0 ? "357px" : "100%"}
            XAxisKey={"name"}
            yAxisKey={"value"}
            data={details?.workersRequest ?? []}
            revenue
            margin={"20px 0px 0px 0px"}
            cardGraphPadding={"0px 0px 22px"}
            padding={"19px 19px 0px"}
            isHorzontal
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        <Grid item sm={6} lg={3}>
          <GraphComponent
            title={"Collection Requests"}
            graphData={details?.collectionRequest ?? []}
            isPie
            margin={"20px"}
            onZoomClick={zoomGraphFunction}
            innerRadius={50}
            isZoom
            isTotal
            total={details?.collectionRequestTotal ?? 0}
          />
        </Grid>
      </Grid>
      {/* section 3 */}
      <Grid container spacing={2} mt={0.3}>
        <Grid item sm={6} lg={3}>
          <GraphComponent
            title={"Domestic Help Requests"}
            graphData={details?.domesticHelperRequest ?? []}
            isPie
            margin={"20px"}
            onZoomClick={zoomGraphFunction}
            innerRadius={50}
            isZoom
            isTotal
            total={details?.domesticRequestTotal ?? 0}
          />
        </Grid>
        <Grid item lg={1.5}>
          <Grid container spacing={2}>
            {details?.visitors?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} maxWidth={"100%"} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item sm={6} lg={3}>
          <GraphComponent
            title={"Parking Requests"}
            graphData={details?.parkingSlotRequest ?? []}
            isPie
            margin={"20px"}
            onZoomClick={zoomGraphFunction}
            innerRadius={50}
            isZoom
            isTotal
            total={details?.parkingSlotRequestToltal}
          />
        </Grid>
        <Grid item sm={6} lg={3}>
          <GraphComponent
            title={"Parking Slots Occupancy"}
            graphData={details?.parkingSlotOccupancy ?? []}
            isPie
            margin={"20px"}
            onZoomClick={zoomGraphFunction}
            isZoom
          />
        </Grid>
        <Grid item lg={1.5}>
          <Grid container spacing={2}>
            {details?.vicle?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      {/* section 4 */}
      <Grid container spacing={2} mt={0.3}>
        {/* Gates And Parking Area */}
        <Grid item lg={4.5}>
          <GraphComponentRevenue
            title={"Gates And Parking Area"}
            type={"bar"}
            height={"324px"}
            XAxisKey={"name"}
            yAxisKey={"value"}
            data={details?.gatesAndParkingArea ?? []}
            revenue
            margin={"20px 0px 0px 0px"}
            cardGraphPadding={"0px 0px 17px"}
            padding={"19px 19px 0px"}
            isHorzontal
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        <Grid item xs={6}>
          {/* Gates In Change */}
          <GateInCharge
            company={props?.company}
            calandervalue={props?.calandervalue}
          />
        </Grid>
        <Grid item lg={1.5}>
          <Grid container spacing={2}>
            {details?.security?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails
                    data={x}
                    maxWidth={"100%"}
                    height={"127px"}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      {/* section 5 */}
      <Grid container spacing={2} mt={0.3}>
        <Grid item sm={6} lg={3}>
          {/* Entries By Gates */}
          <GraphComponent
            title={"Entries By Gates"}
            graphData={details?.entriesByGates ?? []}
            isPie
            margin={"20px"}
            onZoomClick={zoomGraphFunction}
            isZoom
          />
        </Grid>
        <Grid item xs={6}>
          {/* Entries By Gates */}
          <GateInSecurity
            company={props?.company}
            calandervalue={props?.calandervalue}
          />
        </Grid>
        <Grid item sm={6} lg={3}>
          {/* Entries By Gates */}
          <GraphComponent
            title={"Delivery Collection Summary"}
            graphData={details?.deliveryCollectionSummary ?? []}
            indexAxis={"y"}
            isBar
            scale={scale}
            margin={"15px 0px 0px"}
            isZoom
            onZoomClick={zoomGraphFunction}
            height={"auto"}
            maxHeight={"220px"}
          />
        </Grid>
      </Grid>
      {/* zoom dialog for each graph */}
      <DialogBox
        isnotTitle
        maxWidth={zoomGraph === "Map View" ? "lg" : "md"}
        handleClose={handleCloseModule}
        open={zoom}
        height={"400px"}
        borderRadius={"12px"}
        component={
          <>
            {zoomGraph === "Entries With Check-In Only" && (
              <GraphComponent
                title={"Entries With Check-In Only"}
                graphData={details?.checkInEntiries ?? []}
                margin={"40px"}
                isBar
              />
            )}
            {zoomGraph === "Entries With Check-In And Check -Out" && (
              <GraphComponent
                title={"Entries With Check-In And Check -Out"}
                graphData={details?.checkInAndOutEntiries ?? []}
                margin={"40px"}
                isBar
                padding={"24px 24px 47px"}
              />
            )}
            {zoomGraph === "Visitor Requests" && (
              <GraphComponent
                title={"Visitor Requests"}
                graphData={details?.visitor_requests ?? []}
                isPie
                margin={"80px"}
                innerRadius={50}
                isTotal
                divider={6}
                divider1={6}
                total={details?.visitor_requestsTotal ?? 0}
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
                height={"400px"}
              />
            )}
            {zoomGraph === "Worker Requests" && (
              <GraphComponentRevenue
                title={"Worker Requests"}
                type={"bar"}
                height={"357px"}
                XAxisKey={"name"}
                yAxisKey={"value"}
                data={details?.workersRequest ?? []}
                revenue
                margin={"20px 0px 0px"}
                isHorzontal
                cardGraphPadding={"24px 24px 47px"}
              />
            )}
            {zoomGraph === "Collection Requests" && (
              <GraphComponent
                title={"Collection Requests"}
                graphData={details?.collectionRequest ?? []}
                isPie
                margin={"80px"}
                innerRadius={50}
                isTotal
                divider={6}
                divider1={6}
                total={details?.collectionRequestTotal ?? 0}
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
                height={"400px"}
              />
            )}
            {zoomGraph === "Domestic Help Requests" && (
              <GraphComponent
                title={"Domestic Help Requests"}
                graphData={details?.domesticHelperRequest ?? []}
                isPie
                margin={"80px"}
                innerRadius={50}
                divider={6}
                divider1={6}
                total={details?.domesticRequestTotal ?? 0}
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
                isTotal
                height={"400px"}
              />
            )}
            {zoomGraph === "Parking Requests" && (
              <GraphComponent
                title={"Parking Requests"}
                graphData={details?.parkingSlotRequest ?? []}
                isPie
                margin={"80px"}
                divider={6}
                divider1={6}
                innerRadius={50}
                isTotal
                total={details?.parkingSlotRequestToltal}
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
                height={"400px"}
              />
            )}
            {zoomGraph === "Parking Slots Occupancy" && (
              <GraphComponent
                title={"Parking Slots Occupancy"}
                graphData={details?.parkingSlotOccupancy ?? []}
                isPie
                margin={"80px"}
                divider={6}
                divider1={6}
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
                height={"400px"}
              />
            )}
            {zoomGraph === "Gates And Parking Area" && (
              <GraphComponentRevenue
                title={"Gates And Parking Area"}
                type={"bar"}
                height={"324px"}
                XAxisKey={"name"}
                yAxisKey={"value"}
                data={details?.gatesAndParkingArea ?? []}
                revenue
                margin={"20px 0px 0px"}
                isHorzontal
                cardGraphPadding={"24px 24px 47px"}
              />
            )}
            {zoomGraph === "Entries By Gates" && (
              <GraphComponent
                title={"Entries By Gates"}
                graphData={details?.entriesByGates ?? []}
                isPie
                margin={"80px"}
                divider={6}
                divider1={6}
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
                height={"400px"}
              />
            )}
            {zoomGraph === "Delivery Collection Summary" && (
              <GraphComponent
                title={"Delivery Collection Summary"}
                graphData={details?.deliveryCollectionSummary ?? []}
                indexAxis={"y"}
                isBar
                scale={scale}
                margin={"15px 0px 0px"}
                height={"100%"}
                padding={"24px 24px 47px"}
              />
            )}
          </>
        }
      />
    </div>)
  );
};
