import jwt_decode from "jwt-decode";
import { Switch } from "react-router-dom";
import styled from "styled-components";
import { config } from "../config";
import { NetworkCall } from "../networkcall";
import { Routes } from "../router/routes";
import { CalendarOptions, enumLabel, enumName, LocalStorageKeys, NetWorkCallMethods, QuarterCalc, RolesData, AlertProps } from "../utils";
import { AccessDenied } from "../components";
import dayjs from 'dayjs';
import { NewLoader } from "../components/newLoader";
import { formatInTimeZone } from "date-fns-tz";
import moment from "moment-timezone";


export const mapResult = (map) => {
  let updateData = {
    latitude: map?.lat,
    longitude: map?.lng,
  };
  if (map) {
    let keys = [
      "doorNo",
      "addressLineOne",
      "addressLineTwo",
      "landmark",
      "area",
      "district",
      "city",
      "state",
      "country",
      "pincode",
      "zipcode",
    ];
    let findAddress = map?.result?.address_components;
    keys?.map((_) => {
      switch (_) {
        case "doorNo":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.some((type) => ["premise"]?.includes(type))
            )?.long_name ?? "";
          break;
        case "addressLineOne":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.some((type) =>
                ["establishment", "street_number"]?.includes(type)
              )
            )?.long_name ?? "";
          break;
        case "addressLineTwo":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.some((type) =>
                ["neighborhood", "route"]?.includes(type)
              )
            )?.long_name ?? "";
          break;
        case "landmark":
          updateData[_] =
            findAddress?.find((_) => _?.types?.includes("landmark"))
              ?.long_name ?? "";
          break;
        case "area":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.includes("political", "locality")
            )?.long_name ?? "";
          break;
        case "district":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.includes("administrative_area_level_2")
            )?.long_name ?? "";
          break;
        case "city":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.includes("administrative_area_level_2")
            )?.long_name ?? "";
          break;
        case "state":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.includes("administrative_area_level_1")
            )?.long_name ?? "";
          break;
        case "country":
          updateData[_] =
            findAddress?.find((_) => _?.types?.includes("country"))
              ?.long_name ?? "";
          break;
        case "pincode":
          updateData[_] =
            findAddress?.find((_) => _?.types?.includes("postal_code"))
              ?.long_name ?? "";
          break;
        case "zipcode":
          updateData[_] =
            findAddress?.find((_) => _?.types?.includes("postal_code"))
              ?.long_name ?? "";
          break;
        default:
          return null;
      }
      return _;
    });
  }

  return updateData;
};

// sigle images
export const singleImageUpload = async (data, companyId, type) => {
  const formData = new FormData();
  data && formData.append("files", data);
  companyId && formData.append("companyId", `${companyId}`);
  type && formData.append("type", `${type}`);

  let image = await NetworkCall(
    `${config.api_url}/file-upload`,
    NetWorkCallMethods.post,
    formData,
    null,
    true,
    false
  );

  return image.data.fileUrls[0].url;
};

// multiple images
export const multiImageUpload = async (data, { companyId, type }, priority = true) => {

  for (let i = 0; i < data.length; i++) {
    console.log(data[i], "data[i]")
    const formData = new FormData();
    formData.append("files", data[i]);
    formData.append("tenantId", `${config.tenantId}`);
    if (companyId) formData.append("companyId", `${companyId}`);
    formData.append("type", `${type}`);

    let image = await NetworkCall(
      `${config.api_url}/file-upload`,
      NetWorkCallMethods.post,
      formData,
      null,
      true,
      false
    );
    if (!priority) {
      return {
        url: image.data.fileUrls[0].url,
        asset_type: type,
      }
    }
    else {
      return {
        priority: i + 1,
        url: image.data.fileUrls[0].url,
        asset_type: type,
        file_meta: {
          name: data[i]?.name?.split(".")?.[0],
          size: formatSizeUnits(data[i]?.size ?? 0),
          type: data[i]?.name?.split(".")?.[1],
        }
      };
    }
  }

};

// const images type
export const assestType = {
  Facility_Images: 1,
  General_Images: 2,
  Videos: 3,
  Documents: 4,
  Three_sixty_Images: 5,
  Thumbnails: 6,
  Floor_Plan: 7,
};
// company details
export const companyDetails = JSON.parse(
  localStorage.getItem(LocalStorageKeys?.role)
)
  ?.filter((_) => _?.name === RolesData?.manager)
  ?.map((x) => x);

export const verifyToken = (token) => {
  if (token !== null && token !== undefined && token !== "") {
    jwt_decode(token);
  }
};
// company drop down option
export const companyIDManager = JSON.parse(
  localStorage.getItem(LocalStorageKeys?.role)
)
  ?.filter((x) => x?.name === RolesData?.manager)
  ?.map((x) => {
    return { value: x?.company_id, label: x?.company_name };
  });
// lease manager
export const companyIDLeaseManager = JSON.parse(
  localStorage.getItem(LocalStorageKeys?.role)
)
  ?.filter((x) => x?.name === RolesData?.leaseManager)
  ?.map((x) => {
    return { value: x?.company_id, label: x?.company_name };
  });
// security manager
export const companyIDSecurityManager = JSON.parse(
  localStorage.getItem(LocalStorageKeys?.role)
)
  ?.filter((x) => x?.name === RolesData?.securityManager)
  ?.map((x) => {
    return { value: x?.company_id, label: x?.company_name };
  });

// export const SocketSlug = new WebSocket(
//   `${config?.websocket}${localStorage.getItem(
//     LocalStorageKeys?.slug
//   )}/${localStorage.getItem(LocalStorageKeys?.profileID)}`
// );

// get set
export const keyMapSource = ({ enumType }) => {
  return enumType
    .flatMap((item, index) => {
      return {
        [`type${index + 1}`]: item?.value,
      };
    })
    .reduce((old, item) => ({ ...old, ...item }), {});
};

export const startAndEndOfWeek = (date) => {
  const now = date ? new Date(date) : new Date().setHours(0, 0, 0, 0);
  const monday = new Date(now);
  monday.setDate(monday.getDate() - monday.getDay() + 1);
  const sunday = new Date(now);
  sunday.setDate(sunday.getDate() - sunday.getDay() + 7);
  return {
    startDate: monday,
    endDate: sunday,
  };
};

export const startAndEndOfMonth = () => {
  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return { startDate: firstDay, endDate: lastDay };
};

export const getTotal = (data) => {
  // get total count
  let totalCountofParkingslot = data.reduce(function (prev, current) {
    return prev + +current.value;
  }, 0);

  return totalCountofParkingslot;
};

export const getMenuList = (_) => {
  let AccessList = []
  if (_?.length > 0) {
    let tempL1MenuList = _
    for (let i = 0; i < tempL1MenuList?.length; i++) {
      if (tempL1MenuList[i]?.active_status && tempL1MenuList[i]?.type === "menu") {
        let tempL1Menu = {
          id: tempL1MenuList[i].id,
          name: tempL1MenuList[i].name,
          nav_icon: tempL1MenuList[i].nav_icon,
          router: Routes[tempL1MenuList[i].router],
          link: tempL1MenuList[i].link?.map((_) => Routes[_]),
          children: []
        }
        AccessList = [...AccessList, tempL1Menu]
        if (tempL1MenuList[i]?.children?.length > 0) {
          let tempL2MenuList = tempL1MenuList[i]?.children
          for (let j = 0; j < tempL2MenuList?.length; j++) {
            if (tempL2MenuList[j]?.active_status && tempL2MenuList[j]?.type === "menu") {
              let tempL2Menu = {
                id: tempL2MenuList[j].id,
                name: tempL2MenuList[j].name,
                nav_icon: tempL2MenuList[j].nav_icon,
                router: Routes[tempL2MenuList[j].router],
                link: tempL2MenuList[j].link?.map((_) => Routes[_]),
                children: []
              }
              let l1LastIndex = AccessList?.length - 1
              AccessList[l1LastIndex].children =
                [...AccessList[l1LastIndex]?.children, tempL2Menu]
              if (tempL2MenuList[j]?.children?.length > 0) {
                let tempL3MenuList = tempL2MenuList[j]?.children
                for (let k = 0; k < tempL3MenuList?.length; k++) {
                  if (tempL3MenuList[k]?.active_status && tempL3MenuList[k]?.type === "menu") {
                    let tempL3Menu = {
                      id: tempL3MenuList[k].id,
                      name: tempL3MenuList[k].name,
                      router: Routes[tempL3MenuList[k].router],
                      link: tempL3MenuList[k].link?.map((_) => Routes[_])
                    }
                    let l2LastIndex = AccessList[l1LastIndex]?.children?.length - 1
                    AccessList[l1LastIndex].children[l2LastIndex].children =
                      [...AccessList[l1LastIndex]?.children[l2LastIndex]?.children, tempL3Menu]
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return AccessList
}
//IDM get access routes
let access = []

export const getAccessRoutes = (routes) => {
  routes?.forEach(route => {
    if (route?.active_status) {
      access.push({ route: route?.router, permission: route?.permission })
      if (route?.children && route?.children.length > 0) {
        getAccessRoutes(route?.children)
      }
    }

  })
  let key = "route"
  return [...new Map(access.map(item => [item[key], item])).values()].filter(item => item?.route)
}
export const clearAccess = () => access.length = 0
//get page level permission
export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const getPageLevelPermission = (routes, routeName, permissionList) => {
  let value = permissionList.filter(
    (item) => item?.route === getKeyByValue(routes, routeName)
  );
  return value?.[0]?.permission;
};

export const getCreateRules = (property_hierarchy = "") => {
  let _ = enumName;
  return {
    block: [_.multiUnitBlockFloor, _.storageUnitStorageTypeStorageBin].includes(
      property_hierarchy
    ),
    floor: [
      _.multiUnitFloor,
      _.multiUnitBlockFloor,
      _.storageUnitStorageBin,
      _.storageUnitStorageTypeStorageBin,
    ].includes(property_hierarchy),
    unit: [
      _.singleUnit,
      _.multiUnit,
      _.multiUnitFloor,
      _.multiUnitBlockFloor,
      _.storageUnitStorageBin,
      _.storageUnitStorageTypeStorageBin,
    ].includes(property_hierarchy),
  };
};

export const getCreateUnitRule = (property_hierarchy = "", unitCount = 0) => {
  let _ = enumName
  return (
    [
      _.multiUnit, _.multiUnitFloor,
      _.multiUnitBlockFloor, _.storageUnitStorageBin,
      _.storageUnitStorageTypeStorageBin
    ].includes(property_hierarchy) ||
    ([
      _.singleUnit
    ].includes(property_hierarchy) && unitCount === 0)
  )
}

export const getToday = (date = new Date()) => {
  let today = moment(date).format("YYYY-MM-DD")
  return today
}

export const getYesterday = (date = new Date()) => {
  date.setDate(date.getDate() - 1)
  let yesterday = moment(date).format("YYYY-MM-DD")
  return yesterday
}

export const getThisWeek = (date = new Date()) => {
  date.setDate(date.getDate() - date.getDay())
  let from = moment(date).format("YYYY-MM-DD")
  date.setDate(date.getDate() + 6)
  let to = moment(date).format("YYYY-MM-DD")
  return { from, to }
}

export const getLastWeek = (date = new Date()) => {
  date.setDate(date.getDate() - date.getDay() - 7)
  let from = moment(date).format("YYYY-MM-DD")
  date.setDate(date.getDate() + 6)
  let to = moment(date).format("YYYY-MM-DD")
  return { from, to }
}

export const getThisMonth = (date = new Date()) => {
  date.setDate(1)
  let from = moment(date).format("YYYY-MM-DD")
  date.setMonth(date.getMonth() + 1)
  date.setDate(date.getDate() - 1)
  let to = moment(date).format("YYYY-MM-DD")
  return { from, to }
}

export const getLastMonth = (date = new Date()) => {
  date.setDate(1)
  date.setDate(date.getDate() - 1)
  let to = moment(date).format("YYYY-MM-DD")
  date.setDate(1)
  let from = moment(date).format("YYYY-MM-DD")
  return { from, to }
}

export const getQuarter = (date = new Date(), quarter = []) => {
  const month = date.getMonth()
  let from = ""
  let to = ""
  for (let i = 0; i < quarter.length; i++) {
    if (quarter[i].months.includes(month)) {
      date.setFullYear(date.getFullYear() - quarter[i].year, quarter[i].monthStart, quarter[i].dateStart)
      from = moment(date).format("YYYY-MM-DD")
      date.setFullYear(date.getFullYear(), quarter[i].monthEnd, quarter[i].dateEnd)
      to = moment(date).format("YYYY-MM-DD")
    }
  }
  return { from, to }
}

export const getThisYear = (date = new Date()) => {
  date.setDate(1)
  date.setMonth(0)
  let from = moment(date).format("YYYY-MM-DD")
  date.setFullYear(date.getFullYear() + 1)
  date.setDate(date.getDate() - 1)
  let to = moment(date).format("YYYY-MM-DD")
  return { from, to }
}

export const getLastYear = (date = new Date()) => {
  date.setFullYear(date.getFullYear() - 1, 0, 1)
  let from = moment(date).format("YYYY-MM-DD")
  date.setFullYear(date.getFullYear(), 11, 31)
  let to = moment(date).format("YYYY-MM-DD")
  return { from, to }
}

export const getCalendarOptions = () => {
  let today = getToday(new Date())
  let yesterday = getYesterday(new Date())
  let thisWeek = getThisWeek(new Date())
  let lastWeek = getLastWeek(new Date())
  let thisMonth = getThisMonth(new Date())
  let lastMonth = getLastMonth(new Date())
  let thisQuarter = getQuarter(new Date(), QuarterCalc.thisQuarter)
  let lastQuarter = getQuarter(new Date(), QuarterCalc.lastQuarter)
  let thisYear = getThisYear(new Date())
  let lastYear = getLastYear(new Date())
  let result = [
    { label: CalendarOptions.today, value: { from_date: today, to_date: today }, load: true },
    { label: CalendarOptions.yesterday, value: { from_date: yesterday, to_date: today }, load: true },
    { label: CalendarOptions.thisWeek, value: { from_date: thisWeek.from, to_date: thisWeek.to }, load: true },
    { label: CalendarOptions.lastWeek, value: { from_date: lastWeek.from, to_date: lastWeek.to }, load: true },
    { label: CalendarOptions.thisMonth, value: { from_date: thisMonth.from, to_date: thisMonth.to }, load: true },
    { label: CalendarOptions.lastMonth, value: { from_date: lastMonth.from, to_date: lastMonth.to }, load: true },
    { label: CalendarOptions.thisQuarter, value: { from_date: thisQuarter.from, to_date: thisQuarter.to }, load: true },
    { label: CalendarOptions.lastQuarter, value: { from_date: lastQuarter.from, to_date: lastQuarter.to }, load: true },
    { label: CalendarOptions.thisYear, value: { from_date: thisYear.from, to_date: thisYear.to }, load: true },
    { label: CalendarOptions.lastYear, value: { from_date: lastYear.from, to_date: lastYear.to }, load: true },
    { label: CalendarOptions.custom, value: { from_date: today, to_date: today }, load: false }
  ]
  return result
}


/**
Get agreement request status object
*/
export const getARS = (_, type = "", view = "") => {
  switch (_) {
    case enumName.pending:
      return {
        value: _,
        label: view === "table" ? enumLabel.pendingART :
          view === "view" ? enumLabel.pendingARV : _
      }
    case enumName.approved:
      return {
        value: _,
        label: type === "termination" ? enumLabel.approvedATR :
          type === "renewal" ? enumLabel.approvedARR : _
      }
    case enumName.rejected:
      return { value: _, label: enumLabel.rejectedAR }
    case enumName.cancelled:
      return { value: _, label: enumLabel.cancelledAR }
    default:
      return {
        value: _,
        label: view === "table" ? enumLabel.pendingART :
          view === "view" ? enumLabel.pendingARV : _
      }
  }
}

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));


/**
Get company list from auth
*/
export const getCompanyOption = (backdrop, auth, alert) => {

  let access = auth?.auth?.auth?.access
  let activeRole = auth?.auth?.auth?.activeRole

  if (access && activeRole) {
    let tempCompanyList = access?.[activeRole]?.company?.map((_) => {
      let _c
      try {
        _c = {
          value: _?.value,
          label: _?.label,
          company_no: _?.company_no,
          propertyByID: _?.properties?.[0]?.id,
          currency: {
            value: _?.currency?.id,
            label: _?.currency?.name
          },
          currency_id: _.currency?.id,
          currency_symbol: _.currency?.symbol,
          code: _.currency?.code,
          property: _?.properties,
          uom: _?.uom_master ?
            { value: _?.uom_master?.id, label: _?.uom_master?.name } : "",
          data: _,
          timezone: _?.timezone,
          dial_code: _?.country?.dial_code
        }
      } catch (err) {
        alert.setSnack({
          ...alert, open: true, msg: "Some Thing Went Wrong",
          severity: AlertProps.severity.error,
        })

      }
      return _c
    })
    let selectedCompany = localStorage.getItem(LocalStorageKeys.selectedCompany)
    let localCompany
    if (selectedCompany && selectedCompany !== "undefined") {
      localCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
    }
    let tempCompany = localCompany?.value ? localCompany : tempCompanyList?.[0]
    localStorage.setItem(LocalStorageKeys.selectedCompany, JSON.stringify(tempCompany))
    backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
    return {
      selected: tempCompany,
      list: tempCompanyList
    }
  } else {
    backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
    return
  }

}

export const getRoutePermission = async () => {
  let _ = await NetworkCall(
    `${config.authapi}/auth/refresh`,
    NetWorkCallMethods.get,
    null, null, true, false
  )
  return constructRoutePermission(_)
}

export const constructRoutePermission = (_) => {
  let permission = {} //read: true, create: true, delete: true, update: true
  let access = _?.data?.access
  let activeRole = Object.keys(access)?.[0]
  let structure = access?.[activeRole]?.role?.permissions?.[0]?.structure
  let routeLevelPermissions = getAccessRoutes(structure)
  let tempPermission = routeLevelPermissions?.filter(i => Routes[i?.route] === window.location.pathname)?.[0]?.permission
  if (tempPermission) {
    permission = {
      read: tempPermission?.read,
      create: tempPermission?.create,
      delete: tempPermission?.delete,
      update: tempPermission?.update,
    }
  }
  return permission
}

export const accessCheckRender = (render = () => false, routePermission, screen, loading) => {
  return (
    routePermission?.read ?
      (loading ? <NewLoader /> : render()) :
      (routePermission?.read === false ? <AccessDenied screen={screen} /> : <NewLoader />)
  )
}

export const pageName = {
  dashboard: "Dashboard"
}

export const constructRoutePermissionNew = (_) => {
  let private_route = JSON.parse(localStorage.getItem("private_route"));
  let permission = {} //read: true, create: true, delete: true, update: true
  let access = _?.auth?.auth?.access
  if (access) {
    let activeRole = Object.keys(access)?.[0]
    let structure = access?.[activeRole]?.role?.permissions?.[0]?.structure
    let routeLevelPermissions = getAccessRoutes(structure)
    let tempPermission = routeLevelPermissions?.filter(i => [window.location.pathname, private_route?.match?.path].includes(Routes[i?.route]))?.[0]?.permission
    if (tempPermission) {
      permission = {
        read: tempPermission?.read,
        create: tempPermission?.create,
        delete: tempPermission?.delete,
        update: tempPermission?.update,
      }
    }
  }
  return permission
}

export const getRoutePermissionNew = (auth) => {
  let tempPermission = null
  if (auth?.auth?.auth?.access) {
    let _ = auth?.auth?.auth
    if (_?.prevHistoryKey === window?.history?.state?.key) {
      tempPermission = constructRoutePermissionNew(auth)
    }
  }
  return tempPermission
}

export const convertDateFromTime = (t) => {
  let tArr = t.split(":")
  let cdt = new Date()
  return dayjs(new Date(cdt.getFullYear(), cdt.getMonth(), cdt.getDate(), tArr[0], tArr[1], tArr[2]))
}


/**
 * Get Custom Block Name
 * @param  property_hierarchy  Property Hierarchy in Property
 * @param  default_name  Default name to be displayed if not customisable
 */
export const getCustomBlockName = (property_hierarchy = {}, default_name = "Block") =>
  property_hierarchy?.is_customisable ? property_hierarchy?.property_hierarchy_labelByID?.[0]?.block_label : default_name

/**
 * Get Custom Floor Name
 * @param  property_hierarchy  Property Hierarchy in Property
 * @param  default_name  Default name to be displayed if not customisable
 */
export const getCustomFloorName = (property_hierarchy = {}, default_name = "Floor") =>
  property_hierarchy?.is_customisable ? property_hierarchy?.property_hierarchy_labelByID?.[0]?.floor_label : default_name

/**
 * Get Custom Floor Name
 * @param  property_hierarchy  Property Hierarchy in Property
 * @param  unitCount  No. of units
 */
export const getPropertyHierarchyUnitRule = (property_hierarchy = {}, unitCount = 0) =>
  property_hierarchy?.is_multi_unit || (!property_hierarchy?.is_multi_unit && unitCount === 0)



export const getTotalMangeInvoice = (data) => {
  // get total count
  let total = data.reduce(function (prev, current) {
    return prev + +current.Total_Amount;
  }, 0);

  return total;
};

export const constructPropertyList = (company) => {
  // As per CR commented

  // let allProperties = {
  //   label: "All Properties",
  //   value: company?.property?.map((x) => x?.id) ?? []
  // }

  let individualProperties =
    company?.property?.map((x) => {
      return {
        value: x.id,
        label: x.name
      }
    })

  // let propertyList = [allProperties, ...individualProperties]
  let propertyList = [...individualProperties]
  return {
    list: propertyList,
    // all: allProperties
  }
}

/**
 * 
 * @param {string} time 
 * @param {int} seconds 
 * @returns HH:mm format eg., 08:59:59 -> 09:00
 */
export const addSecondFormat = (time, seconds, format) => {
  let tempDate_1 = moment(new Date()).format("YYYY-MM-DD " + time)
  let tempDate_2 = moment(new Date(tempDate_1)).add(seconds, 'seconds').format(format)
  return tempDate_2
}

export function formatSizeUnits(bytes) {
  if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
  else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
  else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
  else if (bytes > 1) { bytes = bytes + " bytes"; }
  else if (bytes === 1) { bytes = bytes + " byte"; }
  else { bytes = "0 bytes"; }
  return bytes;
}

export const getDirectRoutePermission = (_, route) => {
  let permission = {}
  let access = _?.auth?.auth?.access
  if (access) {
    let activeRole = Object.keys(access)?.[0]
    let structure = access?.[activeRole]?.role?.permissions?.[0]?.structure
    let routeLevelPermissions = getAccessRoutes(structure)
    let tempPermission = routeLevelPermissions?.filter(i => Routes[i?.route] === route)?.[0]?.permission
    if (tempPermission) {
      permission = {
        read: tempPermission?.read,
        create: tempPermission?.create,
        delete: tempPermission?.delete,
        update: tempPermission?.update,
      }
    }
  }
  return permission
}

export const checkNumber = (value) => {
  let tempValue = value?.toString()
  return tempValue?.length > 0 ? value : 0
}

export const showAddress = (address, connector = ",", for_empty_address = " - ") => {
  let address_key = [
    "door_no", "street_1", "street_2", "street_3",
    "landmark", "area", "city", "district", "state",
    "country", "zipcode", "latitude", "longitude"
  ]
  let address_array = []
  for (let i = 0; i < address_key.length; i++) {
    if (address[address_key[i]]?.length > 0) {
      address_array.push(address[address_key[i]])
    }
  }
  let temp_final_address = address_array.join(`${connector} `)
  let final_address = temp_final_address?.length > 0 ? temp_final_address : for_empty_address
  return final_address
}
//property options for data getting from refresh token data
export const getPropertyOptions = (company) => {
  let individualProperties =
    company?.selected?.property?.map((x) => {
      return {
        value: x.id,
        label: x.name
      }
    })
  return individualProperties
}

export const convertTimeUtcToZoneCalander = (date, format) => {
  // time zone for company
  let timezone = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))

  if (!date) {
    return '-'
  }
  if (timezone?.timezone) {
    return formatInTimeZone(new Date(date), timezone?.timezone, format ?? 'dd-MM-yyyy');
  }
  else {
    return format === "yyyy-MMM-dd hh:mm aaaa" ? moment(new Date(date))
      .tz(moment.tz.guess())
      .format("DD MMM YY ,hh:mm A") : moment(new Date(date))
        .tz(moment.tz.guess())
        .format("DD MMM YY")
  }

};

export const timeZoneConverter = (date, format = "DD MMM YYYY", now) => {
  // time zone for company
  let company = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))

  if (!date) {
    return '-'
  }
  if (now) {
    if (company?.[0]?.timezone) {
      return moment(date).tz(company?.[0]?.timezone).fromNow();
    }
    else {
      return moment(date).tz(moment.tz.guess()).fromNow();
    }
  }
  if (company?.[0]?.timezone) {
    return moment(date).tz(company?.[0]?.timezone).format(format ?? "DD MMM YYYY");
  }
  else {
    return moment(new Date(date))
      .tz(moment.tz.guess())
      .format(format ?? "DD MMM YYYY")
  }

};


export const currencyFormater = (currency_code, amount) => {
  const result = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency_code,
    minimumFractionDigits: 0,
  }).format(amount);
  return result;
};

export const JobType = (val) => {
  switch (val) {
    case "move-in":
      return "Move In"
    case "move-out":
      return "Move Out"
    case "service":
      return "Service"
    case "site_visit":
      return "Site Visit"
    case "unit_readiness":
      return "Unit Readiness"
    case "unit_handover":
      return "Unit Handover"
    case "general":
      return "General"
    default:
      return "-"
  }
}

export const findImageSizes = (file) =>
  new Promise((resolve, reject) => {
    var _URL = window.URL || window.webkitURL;
    let img = new Image();
    img.src = _URL.createObjectURL(file);
    img.onload = (e) => {
      resolve({
        width: e.target.width,
        height: e.target.height,
      });
    };
    img.onerror = (e) => {
      reject(e);
    };
  });

export const convertUrlToFileObject = async (url) => {
  // Here is the code for converting "image source"(url) to "Base64".***

  let result = null;
  //let url = 'https://cdn.shopify.com/s/files/1/0234/8017/2591/products/young-man-in-bright-fashion_925x_f7029e2b-80f0-4a40-a87b-834b9a283c39.jpg'
  const toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))


  /// Here is code for converting "Base64" to javascript "File Object".***

  const dataURLtoFile = async (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  /// Calling both function ***

  await toDataURL(url)
    .then(async (dataUrl) => {
      //console.log('Here is Base64 Url', dataUrl)
      var fileData = await dataURLtoFile(dataUrl, "imageName.jpg");
      //console.log("Here is JavaScript File Object", fileData)
      result = fileData
    })
  return result;

}

export const image_size = {
  height: 738,
  width: 1426
}

export const SwitchInspectionRoutes = (type) => {
  switch (type) {
    case "service":
      return Routes?.viewServiceInspection
    case "move-in":
      return Routes?.moveInInspectionItemDetails
    case "move-out":
      return Routes?.moveOutInspectionItemDetails
    case "general":
      return Routes?.viewGeneralInspection
    case "site_visit":
      return Routes?.viewSiteVisitDetails
    case "unit_readiness":
      return Routes?.viewUnitReadinessDetails
    case "unit_handover":
      return Routes?.unitHandOverInspectionItemDetails
    default:
      return null
  }
}


export const addOffset = (date, offset) => {
  return new Date(date.getTime() + offset * 1000);
}

export const convertUtczone = (date, zone) => {
  var utcCutoff = moment.utc(date, 'YYYY-MM-DDTHH:mm:ss');
  return utcCutoff.clone().tz(zone);

}

// export const timeZone=(date,offset)=>{

//   let currenct_zone=(new Date().getTimezoneOffset())*60;

//   if(Math.abs(currenct_zone) !== offset){

//   }

// }

export const concat_string = (object = {}, object_key = [], connector = ",", if_empty = " - ") => {
  let array = []
  for (let i = 0; i < object_key.length; i++) {
    if (object[object_key[i]]?.length > 0) {
      array.push(object[object_key[i]])
    }
  }
  let temp_string = array.join(`${connector} `)
  let final_string = temp_string?.length > 0 ? temp_string : if_empty
  return final_string
}