
import makeStyles from "@mui/styles/makeStyles";
import { ExtraBold, Regular, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
      root: {
            backgroundImage: `url(/images/mainbackgroundimage.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100vh",
            backgroundColor: theme.palette.primary.main,
            position: 'relative'
      },
      grid: {
            justifyContent: "center",
      },
      child: {
            margin: "5% 0% 0% 50%",
            [theme.breakpoints.down('sm')]: {
                  margin: 0,
                  padding: "12px 0px 0px 12px"
            },
      },
      signupform: {
            padding: "0px 12px 0px 12px",
            backgroundColor: "white",
            boxShadow: "0px 8px 69px #0000001A",
            borderRadius: theme.palette.borderRadius,
            marginTop: "20%",
            display: 'block',
            [theme.breakpoints.down("sm")]: {
                  marginTop: "6%",
            },
      },
      text: {
            fontSize: "24px",
            fontFamily: ExtraBold,
            color: theme.typography.color.primary,
            marginTop: "0",
      },
      btn: {
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            opacity: 1,
            padding: "8px",
            "&:hover": {
                  backgroundColor: theme.palette.primary.main,
            },
      },
      img: {
            width: "auto",
            height: "21.9px",
      },
      verificationText: {
            fontSize: "14px",
            fontFamily: Regular,
            textAlign: "center",
            color: theme.typography.color.primary,
            backgroundColor: theme.palette.background.secondary,
            borderRadius: theme.palette.borderRadius,
            padding: "20px",
      },
      poweredby: {
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            fontSize: "8px",
            color: theme.typography.color.tertiary,
      },
      pa: {
            color: theme.typography.color.secondary,
            fontFamily: SemiBold,
            fontSize: "10px",
      },

      list: {
            overflow: 'auto',
            padding: '0px 20px',
            "&::-webkit-scrollbar": {
                  display: "none",
            },
      },
      content: {
            padding: '22px 20px'
      },
      logoIcon: {
            position: 'fixed',
            top: '36px',
            left: '36px'
      },
      placeHolder: {
            textAlign: 'center'
      },
      inputParent: {
            marginTop: '10px'
      }
}));