import { Box, IconButton } from "@mui/material";
import { saveAs } from "file-saver";
import FileViewer from "react-file-viewer";
import { useWindowDimensions } from "../../../utils";
import { Step5Styles } from "./styles";
export const Pdfpreview = ({ url = "", height = "" }) => {
    const classes = Step5Styles()
    const size = useWindowDimensions()

    const download = () => {
        saveAs(`${url}`, `Quotation.pdf`);
    }
    return (
        <>
            <Box display="flex" >
                <Box flexGrow={1}>
                    <Box height={size?.height - (height ? height : 320)} overflow="scroll">
                        <center>
                            {
                                url ?
                                    <FileViewer fileType={"pdf"} filePath={url} />
                                    : "No Pdf Found"
                            }

                        </center>
                    </Box>
                </Box>
                <Box>
                    <IconButton className={classes.cancelbtn} variant="contained" onClick={download}>
                        <img src="/images/download.svg" alt="" />

                    </IconButton>
                </Box>
            </Box>
        </>
    )
}