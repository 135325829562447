import makeStyles from "@mui/styles/makeStyles";

export const ProductUpdateStyles = makeStyles((theme) => ({
  productUpdate: {
    margin: "0px 20px",
  },

  label: {
    marginBottom: "6px",
    "& p": {
      fontSize: "12px",
      fontWeight: 700,
      color: theme.typography.color.tertiary,
    },
  },

  textBox: {
    "& MuiTextField-root": {
      width: "100%",
    },
  },

  updateBtn: {
    "& button": {
      width: "100%",
    },
  },
  fixbtn:{
    // position:"absolute",
    // bottom:40,
    width:"100%",
  },
  formBlock:{
    // position:"relative"
    height:"500px",
    overflow:"scroll"
  }
}));
