import { Grid, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { CreateQuotationListContext } from "../../../contexts/createQuotationContext";
import { LeadPreviewStyles as useStyles } from "./styles";
export const LeadPreview = () => {
  const classes = useStyles();
  const { dataNew } = React.useContext(CreateQuotationListContext);
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" className={classes.card}>
        <Grid item xs={12} md={6} lg={1.5} className={classes.rightBorder}>
          <div className={classes.imgdiv}>
            <img
              src={dataNew?.image_url?.length !== 0 ? dataNew?.image_url?.src : "/images/profile_image.png"}
              className={classes.image}
              alt=""
            />
            <div className={classes.prority}>
              <center>
                <span className={classes.proritydata}>
                  {dataNew.priority}

                </span>
              </center>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={10.5} p={1}>
          <Typography className={classes.title}>
            OPPORTUNITY DETAILS
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>Contact Name</Typography>
              <Typography className={classes.sub}> {dataNew.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>Mobile</Typography>
              <Typography className={classes.sub}>
                {dataNew.mobile?.mobile_code}&nbsp; {dataNew.mobile?.mobile ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>Alternative Mobile</Typography>
              <Typography className={classes.sub}>
                {dataNew.alter_mobile?.mobile_code}&nbsp; {dataNew.alter_mobile?.mobile ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>Telephone</Typography>
              <Typography className={classes.sub}>
                {dataNew.telephone?.mobile_code}&nbsp; {dataNew.telephone?.mobile ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>Fax</Typography>
              <Typography className={classes.sub}>
                {dataNew.fax?.mobile_code}&nbsp; {dataNew.fax?.mobile ?? "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>Email Id</Typography>
              <Typography className={classes.sub}>
                {dataNew?.email_id}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {dataNew?.revenue_type?.value !== "Sale" &&
        <Grid container className={classes.card} marginTop="12px" p={1}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Lease Duration</Typography>
              <Typography className={classes.sub}>{dataNew?.duration?.value} {dataNew?.duration?.select}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Lease Start And End Date</Typography>
              <Typography className={classes.sub}>{moment(dataNew?.lease_start_date).format("DD MMM YY")} to {moment(dataNew?.lease_end_date).format("DD MMM YY")}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Source Type</Typography>
              <Typography className={classes.sub}>{dataNew?.source?.label ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Priority</Typography>
              <Typography className={classes.sub}>{dataNew?.priority ?? "-"}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography className={classes.heading}>Billing Cycle Method</Typography>
              <Typography className={classes.sub}>{dataNew?.bill_cycle_method ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Billing Cycle Date</Typography>
              <Typography className={classes.sub}>{ dataNew?.bill_cycle_date === "As per the Agreement Date"  ? "As per the Agreement Date" : dataNew?.custom_date}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Grace Period</Typography>
              <Typography className={classes.sub}>{dataNew?.grace_peroid ?? "-"} Days</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Apply Grace Period</Typography>
              <Typography className={classes.sub}>{dataNew?.apply_grace_peroid ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Payment Period</Typography>
              <Typography className={classes.sub}>{dataNew?.payment_period?.value ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Prefered Payment Options</Typography>
              <Typography className={classes.sub}>{dataNew?.payment_option?.label ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Renewal Based On</Typography>
              <Typography className={classes.sub}>{dataNew?.renewal_based_on?.label ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Auto-renewal</Typography>
              <Typography className={classes.sub}>{dataNew?.auto_renewal ? "True" : "False"}</Typography>
            </Grid>
          </Grid>
        </Grid >
      }
      {dataNew?.revenue_type?.value === "Sale" &&
        <Grid container className={classes.card} marginTop="12px" p={1}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Earliest Occupation Date</Typography>
              <Typography className={classes.sub}>{moment(dataNew?.earlist_occupation_date).format("DD MMM YY")}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Contract Start Date</Typography>
              <Typography className={classes.sub}>{moment(dataNew?.contract_start_date).format("DD MMM YY")}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Source Type</Typography>
              <Typography className={classes.sub}>{dataNew?.source?.label ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Priority</Typography>
              <Typography className={classes.sub}>{dataNew?.priority ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Payment Period</Typography>
              <Typography className={classes.sub}>{dataNew?.payment_period?.value ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Payment Options</Typography>
              <Typography className={classes.sub}>{dataNew?.payment_option?.value ?? "-"}</Typography>
            </Grid>
            {dataNew?.payment_period?.value === "Milestone Based" &&
              <Grid item xs={3}>

                <Typography className={classes.heading}>Delivery Mailstone Template</Typography>
                <Typography className={classes.sub}>{dataNew?.mileStoneName}</Typography>

              </Grid>
            }
          </Grid>
        </Grid>
      }

    </div >
  );
};
