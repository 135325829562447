import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { SearchFilter, TextBox } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
} from "../../../utils";
import { getNextstatus } from "../../../utils/agreementUtils";
import { useStyles, useStyleTimeLine } from "../styles";
import { ContactList } from "./contactList";
import { Complete, DeclineForm } from "../../../assets";

const TimelineForm = ({
  onClose = () => false,
  company = "",
  data = {},
  type = "",
  details = {},
  reload = () => false,
  t,
  isType = false,
  isUnderOnboard = false,
  is_show=false
}) => {
  const classes = useStyles();
  const classes1 = useStyleTimeLine();
  const alert = React.useContext(AlertContext);
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(null);
  const [datas, setData] = React.useState(null);
  const [list, setList] = React.useState([]);
  const [remarks, setRemarks] = React.useState("");

  const handleClick = (event) => {
    setOpen(!open);
  };
  //on change
  const onChange = (val) => {
    setData(val);
    handleClick();
  };
  //get user list
  const getUserList = (search) => {
    setSearch(search);
    const variables = {
      company_id: [company],
      search: search,
      client: localStorage.getItem(LocalStorageKeys.clinetID),
    };
    NetworkCall(
      `${config.api_url}/client_user_roles/get_users`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        setList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //initial load
  React.useEffect(() => {
    getUserList("");
    // eslint-disable-next-line
  }, []);
  //update status
  const updateStatus = () => {
    const variables = {
      agreement_id: details?.id,
      remarks: remarks,
      assigned_id: datas?.id ?? data?.assignee?.id,
      status: isType ? data?.status : getNextstatus(data?.status),
    };

    if (isType && isUnderOnboard) {
      variables["type"] = type === "Incomplete" ? "Declined" : "Approved";
    }
    // if(data?.status === "Finance Review"){
    //   variables["type"] = "Approved"
    // }

    NetworkCall(
      `${config.api_url}/agreement_tracker`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        setOpen(false);
        onClose();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Log Created Successfully",
        });
        reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //verify kyc
  const verifyKyc = () => {
    const variables = {
      contact_id: details?.contact?.id,
    };
    NetworkCall(
      `${config.api_url}/kyc/check_kyc`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        if (res?.data?.check) {
          updateStatus();
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please Verify All Mandatory KYC Items",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //on submit
  const onSubmit = () => {
    if (
      isType &&
      isUnderOnboard &&
      data?.status === "KYC Screening" &&
      type === "Complete"
    ) {
      verifyKyc();
    } else {
      updateStatus();
    }
  };

  return (
    <Box p={2}>
      {type === "assign" && (
        <Box onClick={handleClick}>
          <SearchFilter
            label={t("assignRepresentative")}
            value={search}
            handleChange={(value) => getUserList(value)}
            placeholder={t("searchMember")}
          />
        </Box>
      )}

      <Box position="relative">
        {open && (
          <Box className={classes.contactList}>
            {list?.map((val) => {
              return (
                <ContactList value={datas} onClick={onChange} data={val} />
              );
            })}
          </Box>
        )}
        {datas ? (
          <Box
            display="flex"
            alignItems="center"
            className={classes.selectedList}
          >
            <Box padding="4px">
              <Avatar
                sx={{ height: "34px", width: "34px" }}
                src={datas?.image_url ?? null}
              />
            </Box>
            <Box flexGrow={1} marginLeft="8px">
              <Typography className={classes.name}>{datas?.name}</Typography>
            </Box>
            <Box marginRight="8px">
              <IconButton size="small" onClick={() => setData(null)}>
                <img src="/images/delete.svg" alt="" />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <>{type === "assign" && <Box height="12px" />}</>
        )}
        <>{type === "assign" && <Box height="8px" />}</>
        {is_show && (
          <>
            <Box display="flex" alignItems={"center"}>
              <Box>
                {type === "Incomplete" ? <DeclineForm /> : <Complete />}
              </Box>
              <Box width={"16px"} />
              <Box>
                <Typography className={classes1.skipFormTitle}>
                  {" "}
                  {type === "Incomplete" ? t("Decline") : t("Complete")}
                </Typography>
                <Box height={"4px"} />
                <Typography className={classes1.skipFormsubTitle}>
                  {type === "Incomplete"
                    ? t("Declined_des")
                    : t("Complete_des")}
                </Typography>
              </Box>
            </Box>
            <Box height={"12px"} />
          </>
        )}
        <TextBox
          label={t("remarks")}
          multiline
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          placeholder={t("Type_here")}
        />
        <Box height="24px" />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              className={classes.btn}
              variant="contained"
              onClick={onClose}
              fullWidth
            >
              {t("close")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.btn1}
              disabled={remarks?.length > 0 ? false : true}
              variant="outlined"
              onClick={onSubmit}
              fullWidth
            >
              {isType ? (type === "Incomplete" ? t("Yes") : t("Complete")) : "Assign"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default withNamespaces("agreement")(TimelineForm);
