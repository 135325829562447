import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";
export const AggreementChartStyles = makeStyles((theme) => ({
    cardRoot: {
        padding: '12px',
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        width: "100%"
    },
    title: {
        fontSize: "14px",
        color: "#091B29",
        fontFamily: Bold,

    },
    name: {
        fontSize: "12px",
        color: "#091B29",
        display: "flex",
        alignItems: "center",
        marginTop: "4px"
    },
    value: {
        fontSize: "12px",
        color: "#4E5A6B",
        fontFamily: Bold,
        textAlign: "right",
        marginTop: "4px"
    }

}));