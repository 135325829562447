import { Box, Button } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { FormGenerator } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { StatusOptionList } from "../../../utils/insepectionMaster";

const Form = ({ company_id = "", data = {}, setData = () => false, reload = () => false, onClose = () => false, t, itemTypeOptions = [] }) => {

    const alert = React.useContext(AlertContext);


    //update state of master
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    };
    //validate form
    const validateForm = () => {
        let isValid = true;
        let error = data.error;

        if (data?.name?.length === 0) {
            isValid = false;
            error.name = `${t("itemName")} ${t("isRequired")}`;
        }
        if (data?.item_type?.length === 0) {
            isValid = false;
            error.item_type = `${t("itemType")} ${t("isRequired")}`;
        }
        if (data?.status?.length === 0) {
            isValid = false;
            error.status = `${t("status")} ${t("isRequired")}`;
        }
        setData({ ...data, error });
        return isValid;
    };
    //on submit
    const onSubmit = () => {
        if (validateForm()) {
            const payload = {
                name: data?.item ? null : data?.name,
                inventory_id: data?.item ? data?.name?.value : undefined,
                is_inventory: data?.item,
                is_active: data?.status,
                item: data?.item_type?.value,
                company_id: data?.isEdit ? undefined : company_id,
                id: data?.id ?? undefined,
                "size_type":"instruction_item_mapping_size"
            }
            NetworkCall(
                `${config.api_url}/inspection_items/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                reload()
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `Item Successfully ${data?.isEdit ? "Edited" : "Created"}`,
                });
                onClose()



            }).catch((err) => {
                if (err?.response?.status === 406)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Your  Resource  limit has been hit! Please upgrade your plan!",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
        
                });
            }


            })
        } else {
            return false
        }
    }
    //form data
    const changeInput = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "select",
            label: t("itemType"),
            placeholder: t("selectItemType"),
            value: data?.item_type,
            onChange: (value) => updateState("item_type", value),
            error: data?.error?.item_type,
            isRequired: true,
            options: itemTypeOptions,
            debounceTimeout: 800,
            isPaginate: true,
        },

        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("itemName"),
            placeholder: t("enterItemName"),
            value: data?.name,
            onChange: (value) => updateState("name", value.target.value),
            error: data?.error?.name,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "toggle",
            label: t("status"),
            placeholder: t("status"),
            value: data?.status,
            onChange: (value) => updateState("status", value),
            error: data?.error?.status,
            isRequired: true,
            options: StatusOptionList
        },
    ]


    return (

        <Box p={2} >
            {/*form generator */}
            <Box height="300px" >
                <FormGenerator components={changeInput} />
            </Box>
            <Box mt={4}>
                <Button variant="contained" fullWidth onClick={onSubmit}>{data?.isEdit ? t("edit") : t("create")}</Button>
            </Box>
        </Box >
    )
}
export default withNamespaces("inspectionItemmaster")(Form); 