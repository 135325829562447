import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from 'react';
import { ExisitingAssignee, NewAssignee } from "../../assets";
import { config } from '../../config';
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { AlertDialog } from "../index";
import { ExisitingInspector } from "./existingInspectoer";
import { NewUser } from "./newUser";
import { InspectionAssigneeStyles } from "./styles";

export const InspectionAssginee = ({ open = false, onClose = () => false, lead_id, company_id, revenue_type, selected, reload = () => false }) => {
    const classes = InspectionAssigneeStyles();
    const alert = React.useContext(AlertContext);
    const [type, setType] = React.useState({
        bool: false,
        type: ""
    })
    const list = [
        {
            "title": "Assign Person Against Exisiting Contact",
            img: <ExisitingAssignee />,
            types: "exisiting",
        },
        {
            "title": "Create New Contact And Assign Person",
            img: <NewAssignee />,
            types: "new",
        }
    ]
    const handleClick = (types) => {
        setType({
            bool: !type?.bool,
            type: types
        })
    }
    const renderType = () => {
        return (
            <Grid container spacing={2} >
                {
                    list?.map((val) => {
                        return (
                            <Grid item xs={6} >
                                <Box className={classes.card} onClick={() => handleClick(val?.types)}>
                                    {val?.img}
                                    <Typography className={classes.title}>{val?.title}</Typography>
                                    <center>
                                        <IconButton className={classes.iconBtn} >
                                            <ArrowForwardIosIcon sx={{ fontSize: "14px" }} />
                                        </IconButton>
                                    </center>
                                </Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
        )
    }
    //check contact
    const CheckContact = (contact_id) => {
        console.log(selected)

        const payload = {
            contact_id: contact_id,
            agreement_unit_id: selected?.agreement_units_id
        }

        NetworkCall(
            `${config.api_url}/agreement_inspection/assigning_tenant`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Tenant Assigned Successfully",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                reload()
                onClose()
                handleClick()
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Cannot Assigned",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            });
    }
    return (
        <Box className={classes.box}>
            <AlertDialog
                component={renderType()}
                isnotTitle={true}
                open={open}
                onClose={onClose}
                medium={true}
                noBg={true}
            />
            <AlertDialog
                component={type?.type === "new" ? <NewUser
                    lead_id={lead_id}
                    company_id={company_id}
                    onClose={handleClick}
                    revenue_type={revenue_type}
                    CheckContact={CheckContact}
                /> : <ExisitingInspector
                    CheckContact={CheckContact}
                />}
                open={type?.bool}
                onClose={handleClick}
                isNormal={type?.type === "new" ? false : true}
                header={type?.type === "new" ? "Create New Contact" : "Assign Exisiting Contact"}
            />


        </Box >
    )
}