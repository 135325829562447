import React, { useState, useCallback } from 'react';
import { useStyles } from '../../maintenancerequest/style'
import {
    Avatar,
    Box,
    Grid,
    Typography,
    Button
} from "@mui/material";
import {
    assestType,
    convertTimeUtcToZoneCalander
} from "../../../utils";
// import { AssetView } from "../../propertyFinder3/component/assetView";
import { AllocatedCard } from "../../maintenancerequest/components/profilecard";
import { useLocation } from 'react-router-dom';
import ImageViewer from "react-simple-image-viewer";
import { CustomTypography, useStyles as useStyles1 } from './style'
import { Subheader, TextBox, DialogBox } from "../../../components";
import { AssetView } from '../components'

export const RequestView = ({ t, datas = {}, notesUpdate = () => false, dialogClose = () => false, load = false ,noteChange=()=>false , notes="" , type , request=false}) => {
    // classes
    const classes = useStyles()
    const classes1 = useStyles1({type})
    const { state } = useLocation();
    // state
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    // open image viwer
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    // close image viwer
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    // sumbit notes
    const sumbit = () => {
        notesUpdate()
    }

    return (
        <div>
            <Subheader
                title={datas?.data?.table?.[0]?.subject ?? "-"}
                hideBackButton
            />
            <div>
                <Grid container spacing={3}>
                    <Grid
                         item
                        xs={12}
                        md={9}
                        sm={9}
                        lg={8.5}
                    >
                        <Box className={classes1.boxHeight}>
                            <Grid container spacing={3}>

                                {datas?.data?.images?.length > 0 && (
                                    <Grid item xs={6} md={6} sm={6} lg={7}>
                                        <Box p={2}>
                                            <AssetView
                                                asset={datas?.data?.images
                                                    ?.filter((x) => x?.asset_type !== assestType?.Videos)
                                                    ?.filter((x) => x?.asset_type !== assestType?.Documents)
                                                    ?.map((x) => x)}
                                                assetAll={datas?.data?.images}
                                                openImageViewer={openImageViewer}
                                                isNormal
                                                maxWidth={"sm"}
                                            />
                                        </Box>
                                        {isViewerOpen && (
                                            <ImageViewer
                                                src={datas?.imagesListForView}
                                                currentIndex={currentImage}
                                                onClose={closeImageViewer}
                                                disableScroll={false}
                                                backgroundStyle={{
                                                    backgroundColor: "rgba(0,0,0,0.9)",
                                                }}
                                                closeOnClickOutside={true}
                                            />
                                        )}
                                    </Grid>
                                )}

                                {/* contaent */}
                                <Grid item xs={6} md={6} sm={6} lg={5} className={classes1.paddingLeft}>
                                    <Box pt={2}>
                                        {/* <Typography className={classes.title}>
                                            {datas?.data?.table?.[0]?.subject}
                                        </Typography> */}
                                        <Box className={classes.catagory}>
                                            <Box className={classes.service}>
                                                <Typography className={classes.servicetittle}>
                                                    {datas?.data?.table?.[0]?.catagory?.type}
                                                </Typography>
                                            </Box>
                                            <Box
                                                className={classes.service}
                                                style={{ marginLeft: "10px" }}
                                            >
                                                <Typography className={classes.servicetittle}>
                                                    {datas?.data?.table?.[0]?.subcatagory?.type}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Typography className={classes.title2}>
                                            {datas?.data?.table?.[0]?.description}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* details */}
                            <Box className={classes1.detailsBox}>
                                <Grid container spacing={3}>
                                    {/* PROBLEM SINCE */}

                                    {datas?.data?.table?.[0]?.problemsince?.length > 0 && (
                                        <Grid item xs={3}>
                                            <Typography className={classes.quotationsummaryans}>
                                                {t("PROBLEM_SINCE")}
                                            </Typography>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <Box className={classes.waterLeakageIcons}>
                                                    <img
                                                        src="images/icons8-calendar (5).svg"
                                                        alt="no_img"
                                                    ></img>
                                                </Box>
                                                <Box className={classes.quotationsummaryquest}>
                                                    {datas?.data?.table?.[0]?.problemsince ? convertTimeUtcToZoneCalander(datas?.data?.table?.[0]?.problemsince) : "-"}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )}

                                    {/* UNIT*/}

                                    {datas?.data?.table?.[0]?.unit?.[0]?.unit?.name?.length >
                                        0 && (
                                            <Grid item xs={2.5}>
                                                <Typography className={classes.quotationsummaryans}>
                                                    {t("UNIT")}
                                                </Typography>
                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                    <Box className={classes.waterLeakageIcons}>
                                                        <img src="images/Group 619.svg" alt="no_img"></img>
                                                    </Box>
                                                    <Box className={classes.quotationsummaryquest}>
                                                        {datas?.data?.table?.[0]?.unit?.[0]?.unit?.name}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        )}

                                    {/* RAISED ON */}

                                    {datas?.data?.table?.[0]?.raisedon?.length > 0 && (
                                        <Grid item xs={2.5}>
                                            <Typography className={classes.quotationsummaryans}>
                                                {t("RAISED_ON")}
                                            </Typography>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <Box className={classes.waterLeakageIcons}>
                                                    <img
                                                        src="images/icons8-calendar (5).svg"
                                                        alt="no_img"
                                                    ></img>
                                                </Box>
                                                <Box className={classes.quotationsummaryquest}>
                                                    {datas?.data?.table?.[0]?.raisedon ? convertTimeUtcToZoneCalander(datas?.data?.table?.[0]?.raisedon)
                                                        :"-"}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )}

                                    {/* PREFERRED VISIT DATE AND TIME */}

                                    {datas?.data?.table?.[0]?.prefferedtime?.length > 0 && (
                                        <Grid item xs={4}>
                                            <Typography
                                                className={classes.quotationsummaryans}
                                                noWrap
                                            >
                                                {t("PREFERRED_VISIT")}
                                            </Typography>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <Box className={classes.waterLeakageIcons}>
                                                    <img
                                                        src="images/icons8-schedule (1).svg"
                                                        alt="no_img"
                                                    ></img>
                                                </Box>
                                                <Box className={classes.quotationsummaryquest}>
                                                    {datas?.data?.table?.[0]?.prefferedtime ? convertTimeUtcToZoneCalander(datas?.data?.table?.[0]?.prefferedtime , "yyyy-MMM-dd hh:mm aaaa") : "-"
                                                        }
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                            {/* allocated details */}
                            <Box className={classes.profileBox}>
                                <Grid container spacing={2}>
                                    {datas?.data?.table?.[0]?.name?.length > 0 &&
                                        <Grid item xs={6}>
                                            <CustomTypography>
                                                {t("Contact_Details")}
                                            </CustomTypography>
                                            <AllocatedCard
                                                isImageType
                                                viewDetails={{
                                                    member: {
                                                        image_url: "images/phonecall.svg",
                                                        first_name: datas?.data?.table?.[0]?.name ?? "-",
                                                        last_name: "",
                                                        reference_no: "",
                                                        mobile_no_country_code:
                                                            datas?.data?.table?.[0]?.mobile_country_code ?? "-",
                                                        mobile_no: datas?.data?.table?.[0]?.mobile ?? "-",
                                                        email_id: `${datas?.data?.table?.[0]?.mobile_country_code} ${datas?.data?.table?.[0]?.alternative ?? ""}`,
                                                    },
                                                }}
                                            />
                                        </Grid>} 

                                    {/* <Grid item xs={6}>
                                        <CustomTypography>{t("Assigned_To")}</CustomTypography>
                                        <AllocatedCard
                                            isImageType
                                            viewDetails={{
                                                member: {
                                                    image_url:
                                                        "https://mui.com/static/images/avatar/1.jpg",
                                                    first_name: "Dummy ",
                                                    last_name: "Text",
                                                    reference_no: "",
                                                    mobile_country_code: "+91",
                                                    mobile_no: "9090808012",
                                                    email_id: "dummy@mail.com",
                                                },
                                            }}
                                        />
                                    </Grid> */}
                                </Grid>
                            </Box>
                            {
                               request && 
                               <Box className={classes1.detailsBox}>
                            <CustomTypography>
                            3<sup>rd</sup> {t("PARTY")} {t("WORK ORDER")}
                                            </CustomTypography>
                                <Grid container spacing={3}>
                                    {/* PROBLEM SINCE */}

                                    {/* {datas?.data?.table?.[0]?.work_order_ref_no?.length > 0 && ( */}
                                        <Grid item xs={3}>
                                            <Typography className={classes.quotationsummaryans}>
                                                {t("Work Order Reference")}
                                            </Typography>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                {/* <Box className={classes.waterLeakageIcons}>
                                                    <img
                                                        src="images/icons8-calendar (5).svg"
                                                        alt="no_img"
                                                    ></img>
                                                </Box> */}
                                                <Box className={classes.quotationsummaryquest}>
                                                    {datas?.data?.table?.[0]?.work_order_ref_no ?? "-"}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    {/* )} */}

                                    {/* UNIT*/}

                                    {/* {datas?.data?.table?.[0]?.work_order_date 
                                         && ( */}
                                            <Grid item xs={2.5}>
                                                <Typography className={classes.quotationsummaryans}>
                                                    {t("Work Order Date")}
                                                </Typography>
                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                    {/* <Box className={classes.waterLeakageIcons}>
                                                        <img src="images/Group 619.svg" alt="no_img"></img>
                                                    </Box> */}
                                                <Box className={classes.quotationsummaryquest}>
                                                    {datas?.data?.table?.[0]?.work_order_date ? convertTimeUtcToZoneCalander(datas?.data?.table?.[0]?.work_order_date) : "-"}
                                                </Box>
                                                </Box>
                                            </Grid>
                                        {/* )} */}

                                    {/* RAISED ON */}

                                    {/* {datas?.data?.table?.[0]?.vendor_ref?.label?.length > 0 && ( */}
                                        <Grid item xs={2.5}>
                                            <Typography className={classes.quotationsummaryans}>
                                                {t("Vendor")}
                                            </Typography>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                {/* <Box className={classes.waterLeakageIcons}>
                                                    <img
                                                        src="images/icons8-calendar (5).svg"
                                                        alt="no_img"
                                                    ></img>
                                                </Box> */}
                                                <Box className={classes.quotationsummaryquest}>
                                                    {datas?.data?.table?.[0]?.vendor_ref?.value ? datas?.data?.table?.[0]?.vendor_ref?.label
                                                        :"-"}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    {/* )} */}

                                    {/* PREFERRED VISIT DATE AND TIME */}

                                    {datas?.data?.table?.[0]?.work_order_status?.length > 0 && (
                                        <Grid item xs={4}>
                                            <Typography
                                                className={classes.quotationsummaryans}
                                                noWrap
                                            >
                                                {t("Work Order Status")}
                                            </Typography>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                {/* <Box className={classes.waterLeakageIcons}>
                                                    <img
                                                        src="images/icons8-schedule (1).svg"
                                                        alt="no_img"
                                                    ></img>
                                                </Box> */}
                                                <Box className={classes.quotationsummaryquest}>
                                                    {datas?.data?.table?.[0]?.work_order_status ?? "-"
                                                        }
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                            }
                            
                        </Box>
                    </Grid>

                    {/* track */}
                    <Grid
                        item
                        xs={12}
                        md={3}
                        sm={3}
                        lg={3.5}
                    >
                        <div className={classes.createnewsectionlog}>
                            <Typography
                                className={classes.title}
                            >
                                {t("Track")}
                            </Typography>
                            <Box height={'8px'} />
                            <Grid container direction={"column"} spacing={1}>
                                <div className={classes1.createnewsectionlog1}>
                                    {datas?.data?.table?.[0]?.status
                                        .map((item, index, array) => {
                                            return (
                                                <Box className={classes1.treeBox}>
                                                    <Box className={classes1.border}>
                                                        <Avatar
                                                            variant="circular"
                                                            className={classes1.avatars1}
                                                        >
                                                            <Avatar
                                                                variant="circular"
                                                                style={{
                                                                    height: "100%",
                                                                    width: "100%",
                                                                    backgroundColor: "#5078E1",
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        state?.main?.request === "Maintance"
                                                                            ? item?.type === "Open"
                                                                                ? "images/icons8-clock.svg"
                                                                                : "images/Path 953.svg"
                                                                            : item?.type === "Open"
                                                                                ? "images/icons8-clock.svg"
                                                                                : "images/Path 953.svg"
                                                                    }
                                                                    alt="document"
                                                                />
                                                            </Avatar>
                                                        </Avatar>
                                                        <Typography
                                                            className={classes1.title}
                                                        >
                                                            {item?.type}
                                                        </Typography>
                                                        <Box height={'4px'} />
                                                        {
                                                            item?.notes?.length > 0  ? <>
                                                                {
                                                                    item?.notes?.map((x) => {
                                                                        return (
                                                                            <>
                                                                                <Typography
                                                                                    className={classes1.notes}
                                                                                >
                                                                                    Notes: {x?.notes ?? "-"}
                                                                                </Typography>
                                                                                <Box height={'4px'} />
                                                                                <Typography
                                                                                    className={classes1.dateTime}
                                                                                >
                                                                                    {x?.created_at ? convertTimeUtcToZoneCalander(x?.created_at) : "-"
                                                                                    }
                                                                                </Typography>
                                                                                <Box height={'15px'} />
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </> :
                                                                <>
                                                                    <Box height={'4px'} />
                                                                    <Typography
                                                                  className={classes1.dateTime}
                                                                    >
                                                                        {item?.created_at ? convertTimeUtcToZoneCalander(item?.created_at) : "-"
                                                                        }
                                                                    </Typography></>
                                                        }
                                                        {
                                                            datas?.data?.table?.[0]?.status?.length !== index + 1 && <Box height={'34px'} />
                                                        }
                                                    </Box>
                                                </Box>
                                            )
                                        })}
                                </div>
                            </Grid>
                        </div>
                        <Box className={classes1.btnParent} >
                            <Button className={classes1.btn} variant="contained" onClick={() => dialogClose("open", true)}>{t("Add_Notes")}</Button>
                        </Box>
                    </Grid>
                </Grid>

                {/* add notes */}
                <DialogBox
                    header={t('Add_Note')}
                    maxWidth={"xs"}
                    handleClose={() => dialogClose("open", false)}
                    onClose={() => dialogClose("open", false)}
                    open={load}
                    component={
                        <Box p={2} backgroundColor="white">
                            <Box className={classes1.statusBox}>
                                <Typography className={classes1.convayTitle}>{t("What_convay")}</Typography>
                                <Box height={'10px'} />
                                <Typography className={classes1.status}><span className={classes1.subStatus}>{t("Status")}</span>&nbsp;{datas?.data?.table?.[0]?.status?.some(x => x.type === "Closed") ? "Closed" : "Open"}</Typography>
                            </Box>
                            <Box height={'14px'} />
                            <TextBox
                                isrequired
                                label={t("Notes")}
                                placeholder={t("Notes")}
                                value={notes ?? ""}
                                onChange={(e) =>
                                    noteChange(e.target.value)
                                }
                                multiline
                            />
                            <Box height={'30px'} />
                            <Button className={classes1.btn} variant="contained" disabled={notes?.length > 0 ? false : true} onClick={sumbit}>{t("Save")}</Button>
                        </Box>
                    }
                />
            </div>
        </div>
    )
}