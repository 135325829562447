export const VenueList = [
    {
        court_name: "Court Name 1",
        slot: "22",
        image: "https://fermi.gsfc.nasa.gov/science/constellations/pages/inc/img/hulk/Hulk_Artwork.jpg"
    },
    {
        court_name: "Court Name 2",
        slot: "33",
        image: "https://fermi.gsfc.nasa.gov/science/constellations/pages/inc/img/hulk/Hulk_Artwork.jpg"
    },
    {
        court_name: "Court Name 3",
        slot: "4",
        image: "https://fermi.gsfc.nasa.gov/science/constellations/pages/inc/img/hulk/Hulk_Artwork.jpg"
    },
    {
        court_name: "Court Name 4",
        slot: "7",
        image: "https://fermi.gsfc.nasa.gov/science/constellations/pages/inc/img/hulk/Hulk_Artwork.jpg"
    },
    {
        court_name: "Court Name 5",
        slot: "5",
        image: "https://fermi.gsfc.nasa.gov/science/constellations/pages/inc/img/hulk/Hulk_Artwork.jpg"
    },
    {
        court_name: "Court Name 6",
        slot: "4",
        image: "https://fermi.gsfc.nasa.gov/science/constellations/pages/inc/img/hulk/Hulk_Artwork.jpg"
    },
]

export const AvailableSlot = [
    {
        start_time: "08:00 AM",
        end_time: "09:00 AM",
        person_or_slot: "4"
    },
    {
        start_time: "08:00 AM",
        end_time: "09:00 AM",
        person_or_slot: "4"
    },
    {
        start_time: "08:00 AM",
        end_time: "09:00 AM",
        person_or_slot: "4"
    },
    {
        start_time: "08:00 AM",
        end_time: "09:00 AM",
        person_or_slot: "4"
    },
    {
        start_time: "08:00 AM",
        end_time: "09:00 AM",
        person_or_slot: "4"
    },
    {
        start_time: "08:00 AM",
        end_time: "09:00 AM",
        person_or_slot: "4"
    },

]

export const AmenitiesBookingTableHeading = (t) =>{
    const Heading = [
        { title: "", field: "icon" },
        { title: t("BookingId"), field: "booking_id" },
        { title: t("Amenity"), field: "amenity" },
        { title: t("BookingFrom"), field: "booking_from" },
        { title: t("BookingTo"), field: "booking_to" },
        { title: t("Source"), field: "source" },
        { title: t("Duration"), field: "time_range" },
        { title: t("BookedBy"), field: "booked_by" },
        { title: t("PaymentStatus"), field: "payment_status" },
        { title: t("SlotStatus"), field: "slot_status" },
    ]
    return Heading
} 

export const AmenitiesBookingTablePath = [
    "booking_id",
    "amenity",
    "booking_from",
    "booking_to",
    "source",
    "time_range",
    "booked_by",
    "payment_status",
    "slot_status"
]

export const AmenitiesBookingTableDataType = [
    { type: ["more_6_ins_item"], name: "icon" },
    { type: ["text"], name: "booking_id" },
    { type: ["text"], name: "amenity" },
    { type: ["text"], name: "booking_from" },
    { type: ["text"], name: "booking_to" },
    { type: ["text"], name: "source" },
    { type: ["text"], name: "time_range" },
    { type: ["text"], name: "booked_by" },
    { type: ["aminities_booking_status"], name: "payment_status" },
    { type: ["aminities_booking_status"], name: "slot_status" },
]