import makeStyles from "@mui/styles/makeStyles/makeStyles";

export const TableStyles = makeStyles((theme) => ({
    content: {
        display: "flex",
        flexDirection: "column"
    },
    filter:{
        border:`1px solid ${theme.palette.border.secondary}`,
        padding:2,
        borderRadius:4     
    },
    tablegrid:{
        paddingTop:"0px !important"
    }
}))

export const RequestPopupStyle = makeStyles((theme) => ({
    titleText: {
        fontSize: 12,
        color: theme.typography.color.tertiary
    },
    contentText: {
        fontSize: 14,
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    profileSection: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6
    },
    declineBtn: {
        background: theme.palette.error.main,
        color: "#fff",
        fontSize: 14,
        borderRadius: 4,
        width: "99%",
        '&:hover': {
            background: theme.palette.error.main,
            color: "#fff",
            fontSize: 14,
            borderRadius: 4,
            width: "99%",
        }
    },
    reconcile: {
        background: theme.palette.success.main,
        color: "#fff",
        fontSize: 14,
        borderRadius: 4,
        width: "99%",
        '&:hover': {
            background: theme.palette.success.main,
            color: "#fff",
            fontSize: 14,
            borderRadius: 4,
            width: "99%",
        }
    },
    content_box:{
        overflowY:"auto",
        height:"350px",
    }
}))

export const ProfileCardStyle = makeStyles((theme) => ({
    dot: {
        height: 6,
        width: 6,
        background: theme.palette.border.secondary,
        borderRadius: 50
    },
    name: {
        fontSize: 14,
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    mobno_email: {
        color: theme.typography.color.secondary,
        fontSize: 12,
        fontWeight: "bold"
    },
    titleText: {
        fontSize: 12,
        color: theme.typography.color.tertiary
    },
    contentText: {
        fontSize: 14,
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
}))


export const UpdatePopupStyle = makeStyles((theme)=>({
    decline_alert:{
        borderRadius:4,
        backgroundColor:theme.palette.error.light
    },
    cancel_alert:{
        borderRadius:4,
        backgroundColor:theme.palette.border.secondary
    },
    reconcile_alert:{
        borderRadius:4,
        backgroundColor:theme.palette.success.light
    },
    decline_icon:{
        height:52,
        width:52, 
    },
    alert_title:{
        fontSize:16,
        color:theme.typography.color.secondary,
        fontWeight:"bold"
    },
    alert_content:{
        fontSize:12,
        color:theme.typography.color.tertiary,
        wordBreak: "break-all"
    },
    label:{
        fontSize:12,
        fontWeight:"bold",
        color:theme.typography.color.tertiary
    },
    remarks:{
        fontSize:12,
        color:theme.typography.color.secondary,
        whiteSpace:"nowrap",
        marginTop:"10px",
        marginLeft:"40px"
    }
}))