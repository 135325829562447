import React from 'react'
import { DailyCalendarStyle, eventListStyle } from './style'
import { Grid, Box } from '@mui/material'
import moment from 'moment'
import { Stack, Typography } from '@mui/material'
import { SwitchActivityIcons, SwitchColors } from './weeklyCalendar'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { ViewActivity } from './viewMore'
import { AlertDialog } from '../../../components'
export const DailyCalendar = ({ progress = 0, list = [], handleEvent = () => false, openDeclinePopup = () => false }) => {
    const classes = DailyCalendarStyle()
    const [viewMore, setViewMore] = React.useState(false)
    const [selected, setSelected] = React.useState({})
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            maxWidth: "100%",
            border: '1px solid #dadde9',
        },
    }));
    
    const hours = [
        { label: "00:00-01:00", start: "00", end: "01" },
        { label: "01:00-02:00", start: "01", end: "02" },
        { label: "02:00-03:00", start: "02", end: "03" },
        { label: "03:00-04:00", start: "03", end: "04" },
        { label: "04:00-05:00", start: "04", end: "05" },
        { label: "05:00-06:00", start: "05", end: "06" },
        { label: "06:00-07:00", start: "06", end: "07" },
        { label: "07:00-08:00", start: "07", end: "08" },
        { label: "08:00-09:00", start: "08", end: "09" },
        { label: "09:00-10:00", start: "09", end: "10" },
        { label: "10:00-11:00", start: "10", end: "11" },
        { label: "11:00-12:00", start: "11", end: "12" },
        { label: "12:00-13:00", start: "12", end: "13" },
        { label: "13:00-14:00", start: "13", end: "14" },
        { label: "14:00-15:00", start: "14", end: "15" },
        { label: "15:00-16:00", start: "15", end: "16" },
        { label: "16:00-17:00", start: "16", end: "17" },
        { label: "17:00-18:00", start: "17", end: "18" },
        { label: "18:00-19:00", start: "18", end: "19" },
        { label: "19:00-20:00", start: "19", end: "20" },
        { label: "20:00-21:00", start: "20", end: "21" },
        { label: "21:00-22:00", start: "21", end: "22" },
        { label: "22:00-23:00", start: "22", end: "23" },
        { label: "23:00-00:00", start: "23", end: "00" },

    ]
    return (
        <Box className={classes.calRow}>
            {
                hours?.map(e => {
                    return (
                        <>
                            <Grid container>
                                <Grid item md={1} sm={1} className={classes.timeCell}>
                                    <Box marginTop="12px">
                                        {e?.label}
                                    </Box>
                                </Grid>
                                <Grid item md={11} sm={11} className={classes.eventCell} p={1} sx={{ overflow: "auto" }}>
                                    {
                                        list?.[0]?.calendarData?.map((data) => {
                                            return (
                                                data?.activity?.map((x) => {
                                                    return (
                                                        moment.utc(x?.date_time).format("HH") === e?.start &&
                                                        <>
                                                            {
                                                                data?.activity?.length === 1 ?

                                                                    <HtmlTooltip
                                                                        title={
                                                                            <React.Fragment>
                                                                                <CustomToolTip data={x} openDeclinePopup={openDeclinePopup} setViewMore={setViewMore} setSelected={setSelected} />
                                                                            </React.Fragment>
                                                                        }
                                                                        arrow className={classes.customTooltip}
                                                                    >
                                                                        <Box className={classes.contentbox} style={{ backgroundColor: SwitchColors(x?.activity_name) }}>
                                                                            <Stack direction="row" spacing={1} alignItems="center" p={1}>
                                                                                <img src={SwitchActivityIcons(x?.activity_name)} alt="icon" />
                                                                                <Typography className={classes.mobileNo}>{x?.activity_name}</Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <Typography className={classes.mobileNo}>{x?.first_name}</Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <Typography className={classes.mobileNo}> {x?.created_by_mobile_no} </Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <Typography className={classes.mobileNo}> {x?.lead_email_id} </Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <div className={classes.statusbox}><Typography className={classes.statushdr}>{x?.status}</Typography></div>
                                                                            </Stack>
                                                                        </Box>

                                                                    </HtmlTooltip> :


                                                                    < HtmlTooltip
                                                                        title={
                                                                            <React.Fragment>
                                                                                <CustomToolTip data={x} openDeclinePopup={openDeclinePopup} setViewMore={setViewMore} setSelected={setSelected} />
                                                                            </React.Fragment>
                                                                        }
                                                                        arrow className={classes.customTooltip}
                                                                    >
                                                                        <Box className={classes.contentbox} style={{ backgroundColor: SwitchColors(x?.activity_name) }} mt={1} mb={1}>
                                                                            <Stack direction="row" spacing={1} alignItems="center" p={1}>
                                                                                <img src={SwitchActivityIcons(x?.activity_name)} alt="icon" />
                                                                                <Typography className={classes.mobileNo}>{x?.activity_name}</Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <Typography className={classes.mobileNo}>{x?.first_name}</Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <Typography className={classes.mobileNo}> {x?.created_by_mobile_no} </Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <Typography className={classes.mobileNo}> {x?.lead_email_id} </Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <div className={classes.statusbox}><Typography className={classes.statushdr}>{x?.status}</Typography></div>
                                                                            </Stack>
                                                                        </Box>

                                                                    </HtmlTooltip>



                                                            }
                                                        </>
                                                    )
                                                })
                                            )
                                        })}
                                </Grid>
                            </Grid>
                        </>
                    )
                })
            }

            <AlertDialog
                md
                open={viewMore}
                onClose={() => setViewMore(false)}
                header={"Activity"}
                component={<ViewActivity data={selected} handleClose={() => setViewMore(false)} />}

            />
        </Box >
    )
}
const CustomToolTip = ({ data = {}, startTime = null, endTime = null, openDeclinePopup = () => false, setViewMore = () => false, setSelected = () => false }) => {
    const classes = eventListStyle()
    return (
        <Stack p={1}>
            <Stack direction={"column"} spacing={1}>
                <Stack direction="row" spacing={1}>
                    <img src={SwitchActivityIcons(data?.activity_name)} width="24px" height="24px" alt="icon" />
                    <Typography className={classes.ttTitle}> {data?.activity_name} </Typography>
                </Stack>
                <Stack pl={3.5}>
                    <Typography className={classes.ttsecTitle}> {moment(data?.date_time).format("DD-MM-YYYY hh:mm A")} </Typography>
                </Stack>
                <Stack pl={3.5}>
                    <Typography className={classes.ttassigned_by}>Created By : {data?.first_name}, {`${data?.mobile_no_country_code ?? ""} ${data?.mobile_no ?? ""}`} </Typography>
                </Stack>
                <Stack pl={3.5}>
                    <Typography className={classes.moreLabel} onClick={() => {
                        setSelected(data)
                        setViewMore(true)
                    }}> {"View more"}</Typography>
                </Stack>
            </Stack>

        </Stack>
    )
}