import { Box, Grid } from "@mui/material";
import React from "react";
import { Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";
import { basicSettingState, returnSeetingData, settinglist } from "../../utils/clientsettings";
import { SettingList } from "../companyCreation/components";
import { GET_ALL_CURRENCIES } from '../companyCreation/utils';
import { useStyles } from "./styles";
import { AdvancedSettings, BasicSettings, CommunityBanner, Country, Currency, DashboardBanner, LogoApperance, ServiceBanner, Tax, TaxGroupItem, WelcomeScreen } from "./tabs";

export const ClientSettings = () => {
    const [selected, setSelected] = React.useState(1);
    const alert = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);
    const [settingDetails, setDetails] = React.useState({ ...basicSettingState });

    const classes = useStyles()
    //get settingDetails
    const getSettingDetails = () => {
        NetworkCall(
            `${config.api_url}/client/settings/get`,
            NetWorkCallMethods.get,
            null,
            null,
            true,
            false
        )
            .then((response) => {
                let result = returnSeetingData(response?.data?.settings)
                setDetails(result)
            }).catch((err) => {
                console.log(err)
            })
    }
    //Initial load
    React.useEffect(() => {
        setSelected(settinglist[0])
        getSettingDetails()
        // eslint-disable-next-line
    }, [])
    //load option
    const loadOptionData = async (search, array, type) => {

        setLoading(type);

        if (type === "currency") {
            return loadOptions(search, array, setLoading, GET_ALL_CURRENCIES, 'currency_master');
        }
        else {
            return {
                options: [],
                hasMore: false
            }
        }
    }
    //on submit SeetingForm
    const onSubmit = (data) => {
        NetworkCall(
            `${config.api_url}/client/settings/upsert`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Setting Successfully Saved",
                });
                getSettingDetails()
            }).catch((err) => {
                console.log(err)
            })
    }
    //component list
    const components = {
        1: <BasicSettings
            selected={selected}
            loadOptionData={loadOptionData}
            loading={loading}
            settings={settingDetails}
            onSubmitSettings={onSubmit}
        />,
        2: <AdvancedSettings settings={settingDetails} selected={selected} data={settingDetails} onSubmitSettings={onSubmit} />,
        3: <LogoApperance selected={selected} />,
        // 4: <Integration selected={selected} />,
        5: <WelcomeScreen selected={selected} />,
        6: <DashboardBanner selected={selected} type="Dashboard" />,
        7: <ServiceBanner selected={selected} type="Services" />,
        8: <CommunityBanner selected={selected} type="Community" />,
        9: <Country selected={selected} />,
        10: <Currency selected={selected} />,
        11: <Tax selected={selected} />,
        12: <TaxGroupItem selected={selected} />
    }
    return (
        <Box>
            <Subheader hideBackButton title="Client settings" />
            <Grid container spacing={2} p={2}>
                <Grid item xs={3}>
                    <Box className={classes.box}>
                        {/*setting list */}
                        <SettingList list={settinglist} selected={selected?.value} setSelected={setSelected} />
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box className={classes.box}>
                        {/*component render*/}
                        {components[selected?.value]}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}