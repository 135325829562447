import React, { useState, useContext } from "react";
import {
  Subheader,
  PropertyTabs,
  AlertDialog,
  SearchFilter,
  SelectBox,
  TextBox,
} from "../../components";
import { LeadStylesParent } from "./style";
import { Grid, Box, Typography, Button } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { ProfileCard, AllocatedCard } from "../leadnew/components";
import { useApolloClient } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Command, UnitTable, ToDoList, Activity } from "./components";
import { Routes } from "../../router/routes";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  AlertProps,
  NetWorkCallMethods,
  useWindowDimensions,
  LocalStorageKeys,
  accessCheckRender,
  getRoutePermissionNew,
} from "../../utils";
import {
  GET_PROXY_LEAD_VIEW,
  GET_ROLES_NEW,
  GET_PROXY_LEAD_UNIT,
  GET_PROXY_CHECKLIST,
  GET_USERS_BY_ROLE_ID_NEW
} from "../../graphql/queries";
import {
  ADD_COMMAND_PROXY_LEAD,
  ADD_TODO,
  EDIT_TODO,
} from "../../graphql/mutations";
import { Card } from "../leadnew/addmembercard";
import { AlertContext, AuthContext } from "../../contexts";
import { Bold } from "../../utils";


// custom styles
// start
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontFamily: Bold,
  color: theme.typography.color.secondary,
  marginBottom: theme.spacing(1),
}));

const initial = {
  job: "",
  isShowcard: false,
  member: {},
  isEdit: false,
  isView: false,
  checkbox: false,
  purpose: "",
  name: "",
  des: "",
  id: "",
  error: {
    des: "",
    job: "",
    member: "",
    name: "",
  },
};
// INITIAL STATES OF TRANSFER LEAD & LOST LEAD
const initial1 = {
  job: "",
  remark: "",
  error: {
    job: "",
    remark: "",
  },
};

export const LeadView = (props) => {
  const { state } = useLocation();
  // styles
  const classes = LeadStylesParent();
  //   states
  const [details, setDetails] = useState([]);
  // apolo clint
  const client = useApolloClient();
  // histry
  const history = useHistory();
  // size
  const size = useWindowDimensions();
  // states
  const [data, setData] = useState({ ...initial });
  const auth = React.useContext(AuthContext)
  const [datatransfer, setDatatransfer] = useState({ ...initial1 });
  const [viewdialog, setviewDialog] = useState(false);
  const [transferLead, setTransferLead] = useState(false);
  const [lostLead, setlostLead] = useState(false);
  const [searchdata, setSearchdata] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [offset, setOffset] = useState(0);
  const [unitList, setUnitList] = useState({
    data: [],
    count: 0,
  });
  const [toDoList, setToDoList] = useState([]);
  const [toDodialog, setToDodialog] = useState(false);
  const [unitPage, setUnitPage] = useState(1);
  const [unitLimit, setUnitLimit] = useState(10);
  const [todoPage, setTodoPage] = useState(1);
  const [todoLimit, setTodoLimit] = useState(10);
  const [tabValue, setTabValue] = useState(1);
  const [permission, setPermission] = React.useState({})

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        viewLeadDeatils();
        getUnits(0, 10);
        getToDo(0, 10);
      }
    }
    // eslint-disable-next-line
  }, [auth]);
  // context

  const alert = useContext(AlertContext);
  const tabTittle = [
    {
      label: "Activity Comments",
      value: 1,
      className: data?.tabValue === 1 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: classes.selectBack,
    },
    {
      label: `Number of Units (${unitList?.count ?? 0})`,
      value: 2,
      className: data?.tabValue === 2 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: classes.selectBack,
    },
    {
      label: "To Do List",
      value: 3,
      className: data?.tabValue === 3 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: classes.selectBack,
    },
    {
      label: "Activity",
      value: 4,
      className: data?.tabValue === 4 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: classes.selectBack,
    },
  ];
  const leadtransfer = [
    {
      title: "Convert To Opportunity",
      image: "/images/convertopertunity.svg",
      is_active: details?.status === "Converted" ? false : true,
      permission: permission?.create
    },
    {
      title: "Transfer Lead",
      image: "/images/transferlead.svg",
      is_active: true,
      permission: permission?.update
    },
    {
      title: "Lost Lead",
      image: "/images/lostlead.svg",
      is_active: details?.status === "Lost Lead" ? false : true,
      permission: permission?.update
    },
  ];

  // view data
  const viewLeadDeatils = () => {
    client
      .query({
        query: GET_PROXY_LEAD_VIEW,
        fetchPolicy: "network-only",
        variables: {
          id: state?.main?.id ?? "",
        },
      })
      .then((res) => {
        let viewData = res?.data?.proxy_lead?.[0];
        setDetails(viewData);
      })
      .catch((err) => { });
  };
  // handleChange
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // lead view option clicks function (transfer lead , close lead)
  const leadClick = (index, data) => {
    if (index === 0) {
      history.push({
        pathname: Routes.createOpportunity,
        state: {
          main: {
            company: state?.main?.company,
            id: state?.main?.id,
            is_convert: true,
            data: data
          },
        },
      });
    }
    if (index === 1) {
      setTransferLead(true);
      setviewDialog(!viewdialog);
    }
    if (index === 2) {
      setlostLead(!lostLead);
      setviewDialog(!viewdialog);
    }
  };
  // close transfer dialog
  const closeTransfer = () => {
    setTransferLead(!transferLead);
    setDatatransfer({ ...initial1 });
    setSelectedID(null);
    setUserDetails([]);
  };
  // close lead dialog
  const closeLost = () => {
    setlostLead(!lostLead);
    setDatatransfer({ ...initial1 });
  };
  // add commands
  const addLost = (key) => {
    let datas = {};
    if (key === "transfer") {
      datas = {
        lead_owner: selectedID?.user_profileByID?.id,
      };
    }
    if (key === "close") {
      datas = {
        status: "Lost Lead",
        remarks: datatransfer?.remark ?? "",
      };
    }
    if (key === "delete") {
      datas = {
        is_active: false,
      };
    }
    const payload = {
      query: ADD_COMMAND_PROXY_LEAD,
      variables: {
        id: state?.main?.id ?? "",
        data: datas,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (key === "transfer") {
          closeTransfer();
        }
        if (key === "close") {
          closeLost();
        }
        if (key === "delete") {
          closeLost();
        }
        viewLeadDeatils();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: `${key === "transfer" ? "Transfer Lead" : "Lost Lead"
            } Created Successfully`,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong please try again",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  // update state function
  const updateState = (k, v) => {
    if (k === "job") {
      getUserProfile(v, 0, "", "search");
    }
    let error = data?.error;
    error[k] = "";
    setData({ ...data, [k]: v });
  };
  // update state function
  const updateState1 = (k, v) => {
    if (k === "job") {
      getUserProfile(v, 0, "", "search");
    }
    let error = datatransfer?.error;
    error[k] = "";
    setDatatransfer({ ...datatransfer, [k]: v });
  };
  // transfert leada functions
  const [loading, setLoading] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  // drop down option
  const loadOptions = async (search = "", array, type) => {
    setLoading(type);
    let result,
      query,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "job":
        query = GET_ROLES_NEW(offset, 10, search, localStorage.getItem(LocalStorageKeys?.clinetID)).loc.source.body;
        result = await networkCallBack(query);
        return {
          options: [...result?.client_roles],
          hasMore:
            array?.length + result?.client_roles?.length < result?.count[0]?.count,
        };
      default:
        return { options: [] };
    }
  };
  const networkCallBack = async (query, variable = {}) => {
    let payload = {
      query,
      variable: variable,
    };

    const options = await NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        setLoading(null);
        return main;
      })
      .catch((error) => {
        setLoading(null);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return null;
      });

    return options;
  };
  const handleSearchTransfer = (v) => {
    setSearchdata(v);
    getUserProfile(datatransfer?.job, 0, v, "search");
  };
  // get user profiles in pop up
  const getUserProfile = (v, offset, search, k) => {
    client
      .query({
        query: GET_USERS_BY_ROLE_ID_NEW,
        fetchPolicy: "network-only",
        variables: {
          id: v?.value,
          offset: offset,
          limit: 10,
          search: search ?? "",
        },
      })
      .then((res) => {
        if (k === "search") {
          setUserDetails(res?.data?.client_user_role_access);
        } else {
          setUserDetails(userDetails?.concat(res?.data?.client_user_role_access));
        }
      })
      .catch((err) => { });
  };
  // infinity scroll transfer lead
  const fetchMoreDataTransfer = () => {
    setOffset(offset + 10);
    getUserProfile(datatransfer?.job, offset + 10, "", "normal");
  };
  // selected users
  const selectUser = (x) => {
    setSelectedID(x);
    updateState("member", x?.user_profileByID);
  };
  // cancel transfer lead
  const previous1 = () => {
    setTransferLead(false);
  };
  // cancel close lead
  const previous2 = () => {
    setlostLead(false);
  };
  // validate transfer lead
  const validatemain = () => {
    let isValid = true;
    let error = data.error;

    if (data?.name?.length === 0) {
      isValid = false;
      error.name = "Name is Required";
    }

    setData({ ...data, error });

    return isValid;
  };
  // validate transfer lead
  const validate = () => {
    let isValid = true;
    let error = datatransfer.error;

    if (datatransfer?.job?.length === 0) {
      isValid = false;
      error.job = "Roles is Required";
    }

    setDatatransfer({ ...datatransfer, error });

    return isValid;
  };
  // sumbit transfer lead
  const TransferLead = (key) => {
    if (validate()) {
      if (selectedID?.user_profileByID?.id?.length > 0) {
        addLost(key);
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please Select One Member",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    }
  };
  // sumbit lost lead
  const LostLeadFunction = (key) => {
    if (validate1()) {
      addLost(key);
    }
  };
  // validate close lead
  const validate1 = () => {
    let isValid = true;
    let error = datatransfer.error;
    if (datatransfer?.remark?.length === 0) {
      isValid = false;
      error.remark = "Remark is Required";
    }

    setDatatransfer({ ...datatransfer, error });

    return isValid;
  };
  // get units
  const getUnits = (offset, limit) => {
    client
      .query({
        query: GET_PROXY_LEAD_UNIT,
        fetchPolicy: "network-only",
        variables: {
          id: state?.main?.id,
          offset: offset ?? 0,
          limit: limit ?? 10,
          clientID: localStorage.getItem(LocalStorageKeys?.clinetID),
        },
      })
      .then((res) => {
        let result = res?.data?.proxy_lead_units?.map((x) => {
          return {
            id: x?.unit?.unit_no,
            image: x?.unit?.logo,
            name: x?.unit?.name,
            propertyname: x?.unit?.property?.name,
          };
        });
        setUnitList({
          data: result ?? [],
          count: res?.data?.count?.[0]?.count,
        });
      })
      .catch((err) => { });
  };
  // add todolist
  const addTodo = () => {
    if (validatemain()) {
      const payload = {
        query: ADD_TODO,
        variables: {
          insertPayload: [
            {
              name: data?.name ?? "",
              description: data?.des ?? "",
              proxy_lead_id: state?.main?.id ?? "",
              client: localStorage.getItem(LocalStorageKeys.clinetID),
              company_id: state?.main?.company?.value ?? null
            },
          ],
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          closeTodo();
          getToDo(0, 10);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `Todo Created Successfully`,
          });
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something went wrong please try again",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    }
  };
  // close todo
  const closeTodo = () => {
    setToDodialog(!toDodialog);
    setData({ ...initial });
  };
  // GET TODO
  const getToDo = (offset, limit) => {
    client
      .query({
        query: GET_PROXY_CHECKLIST,
        fetchPolicy: "network-only",
        variables: {
          id: state?.main?.id,
          offset: offset,
          limit: limit,
          clientID: localStorage.getItem(LocalStorageKeys?.clinetID),
        },
      })
      .then((res) => {
        let result = res?.data?.proxy_lead_checklist?.map((x) => {
          return {
            id: x?.id,
            Name: x?.name,
            Description: x?.description,
            checkbox: x?.is_done ?? false,
            icon: x?.is_done === false ? "edit" : "",
            status: x?.is_done ?? false,
          };
        });
        setToDoList({
          data: result ?? [],
          count: res?.data?.count?.[0]?.count,
        });
      })
      .catch((err) => { });
  };

  // pagination function
  const handlePagination = (value) => {
    setUnitPage(value);
    let offset = (value - 1) * unitLimit;
    getUnits(offset, unitLimit);
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setUnitLimit(value);
    setUnitPage(1);
    getUnits(0, value);
  };

  // pagination function
  const toDohandlePagination = (value) => {
    setTodoPage(value);
    let offset = (value - 1) * unitLimit;
    getToDo(offset, unitLimit);
  };
  // pagination limit change function
  const toDohandleChangeLimit = (value) => {
    setTodoLimit(value);
    setTodoPage(1);
    getToDo(0, value);
  };
  // handle icon todo
  const handleIconToDo = (type, value, e) => {
    console.log(value);
    if (type === "edit") {
      setToDodialog(true);
      setData({
        ...data,
        name: value?.Name,
        des: value?.Description,
        id: value?.id,
        checkbox: value?.checkbox,
        isEdit: true,
      });
    }
    if (type === "checkBox") {
      updateTodo(true, e, value?.id, true);
    }
    if (type === "view") {
      if (!value?.checkbox) {
        setToDodialog(true);
        setData({
          ...data,
          name: value?.Name,
          des: value?.Description,
          id: value?.id,
          isEdit: false,
          isView: true,
          checkbox: value?.checkbox,
        });
      }
    }
  };
  const updateTodo = (is_done, value, id, is_popup) => {
    const updatePayload = {};
    if (is_done === true) {
      updatePayload["is_done"] = value;
    } else {
      updatePayload["name"] = data?.name ?? "";
      updatePayload["description"] = data?.des ?? "";
    }
    const payload = {
      query: EDIT_TODO,
      variables: {
        id: data?.id || id,
        updatePayload,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (is_popup !== true) {
          closeTodo();
        }
        getToDo(0, 10);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: `Todo Update Successfully`,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  const markTodo = () => {
    updateTodo(true, data?.checkbox ? false : true, data?.id, false);
  };

  const render = () => {
    return <>
      <Subheader
        title={`View Lead`}
        select
        isReadOnly
        value={state?.main?.company}
        goBack={() => history.goBack(-1)}
      />
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={4} className={classes.padding}>
            {/* LEFT SIDE SECTION */}
            <div className={classes.detailsParent}>
              <div
                style={{
                  textAlign: "left",
                  overflow: "auto",
                  height: size?.height - 420,
                }}
              >
                {/*   LEAD DETAILS */}
                <CustomTypography>LEAD DETAILS</CustomTypography>
                <ProfileCard viewDetails={details} />
                {/*    ALLOCATED DETAILS */}
                {details?.member?.first_name?.length > 0 && (
                  <div style={{ marginTop: "16px" }}>
                    <CustomTypography>ALLOCATED DETAILS</CustomTypography>
                    <AllocatedCard viewDetails={details} />
                  </div>
                )}
              </div>
              {/* lead option (TRANSFER LEAD , LOSE LEAD) */}

              {<div className={classes.transferParent}>
                {details?.status !== "Converted" && (
                  <>
                    {leadtransfer?.map((x, index) => {
                      return (
                        <>
                          {(x?.permission && x?.is_active) && (
                            <Box
                              className={classes.leadtransfersbox}
                              onClick={() => leadClick(index, details)}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img src={x?.image} alt={x?.title} />
                                <Typography
                                  className={classes.options}
                                  style={{ marginLeft: "10px" }}
                                >
                                  {x?.title}
                                </Typography>
                              </Box>
                              <Box>
                                <img src="/images/leadarrow.svg" alt="" />
                              </Box>
                            </Box>
                          )}
                        </>
                      );
                    })}
                  </>
                )}
              </div>}
            </div>
          </Grid>
          <Grid item xs={8} className={classes.paddingTab}>
            <PropertyTabs
              padding={"15px 11px"}
              value={tabValue}
              isHeigth
              isAfter
              handleChange={handleChange}
              tabTittle={tabTittle}
              tab1={
                <Command
                  viewDetails={details}
                  viewLeadDeatils={viewLeadDeatils}
                  permission={permission}
                />
              }
              tab2={
                <UnitTable
                  unitList={unitList}
                  page={unitPage}
                  limit={unitLimit}
                  handleChangeLimit={handleChangeLimit}
                  handlePagination={handlePagination}
                />
              }
              tab3={
                <ToDoList
                  updateState={updateState}
                  state={data}
                  dialogTittle={`${data?.isEdit
                    ? "Edit"
                    : `${data?.isView ? "View" : "Create"}`
                    } To Do`}
                  btnTitle={`${data?.isEdit
                    ? "Update"
                    : `${data?.isView ? "Mark as Complete" : "Create"}`
                    }`}
                  sumbit={
                    data?.isEdit
                      ? updateTodo
                      : data?.isView
                        ? markTodo
                        : addTodo
                  }
                  closeTodo={closeTodo}
                  toDodialog={toDodialog}
                  toDoList={toDoList}
                  page={todoPage}
                  limit={todoLimit}
                  handleChangeLimit={toDohandleChangeLimit}
                  handlePagination={toDohandlePagination}
                  handleIconToDo={handleIconToDo}
                  details={details}
                />
              }
              tab4={<Activity proxy_lead_id={state?.main?.id} details={details} selectedCompany={data?.main?.selectedCompany} />}
            />
          </Grid>
        </Grid>
      </div>
      {/* transfer lead */}
      <AlertDialog
        open={transferLead}
        onClose={closeTransfer}
        isNormal
        header={"Transfer Lead"}
        component={
          <>
            <div style={{ padding: "24px 24px 0px" }}>
              <Grid container>
                <Grid item xs={12}>
                  {/* Job */}
                  <SelectBox
                    isRequired
                    label="Job"
                    placeholder="Select Job"
                    value={datatransfer?.job ?? ""}
                    onChange={(value) => {
                      updateState1("job", value);
                    }}
                    loading={loading === "job"}
                    isPaginate
                    debounceTimeout={800}
                    loadOptions={(search, array) =>
                      loadOptions(search, array, "job")
                    }
                    isError={datatransfer?.error?.job?.length > 0}
                    errorMessage={datatransfer?.error?.job}
                  />
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                textAlign: "center",
              }}
            >
              <div id={"infinity"}>
                {/* search box */}

                <div style={{ padding: "24px" }}>
                  <SearchFilter
                    value={searchdata}
                    handleChange={(value) => handleSearchTransfer(value)}
                    placeholder="Search Member Details"
                  />
                </div>
                <div style={{ padding: "24px" }}>
                  <InfiniteScroll
                    dataLength={userDetails?.length ?? ""}
                    next={fetchMoreDataTransfer}
                    hasMore={true}
                    height={size?.height - 500}
                  >
                    <Card
                      data={userDetails}
                      selectUser={selectUser}
                      select={selectedID}
                    />
                  </InfiniteScroll>
                </div>
                {/* button */}
                <Box className={classes.bottombtn}>
                  <Box>
                    <Button className={classes.Cancel} onClick={previous1}>
                      Back
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      className={classes.next}
                      onClick={() => TransferLead("transfer")}
                    >
                      Transfer Lead
                    </Button>{" "}
                  </Box>
                </Box>
              </div>
            </div>
          </>
        }
      />
      {/* LOST LEAD */}
      <AlertDialog
        open={lostLead}
        onClose={closeLost}
        isNormal
        header={"Lost Lead"}
        component={
          <div>
            <div
              style={{
                padding: "24px",
                height: size?.height - 300,
                textAlign: "left",
                overflow: "auto",
              }}
            >
              <Grid item xs={12}>
                <TextBox
                  isrequired
                  label="Remark"
                  placeholder="Type Remarks"
                  value={datatransfer?.remark ?? ""}
                  multiline
                  padding={9}
                  onChange={(e) => {
                    updateState1("remark", e.target.value);
                  }}
                  isError={datatransfer?.error?.remark?.length > 0}
                  errorMessage={datatransfer?.error?.remark}
                />
              </Grid>
            </div>
            {/* button */}
            <Box className={classes.bottombtn}>
              <Box>
                <Button className={classes.Cancel} onClick={previous2}>
                  Back
                </Button>
              </Box>
              <Box>
                <Button
                  className={classes.next}
                  onClick={() => LostLeadFunction("close")}
                >
                  Update And Close Lead
                </Button>{" "}
              </Box>
            </Box>
          </div>
        }
      />
    </>
  }

  return (
    <div>
      {accessCheckRender(render, permission)}
    </div>
  );
};
