import { useApolloClient } from "@apollo/client";
import { Badge, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import React from "react";
import { useHistory } from "react-router-dom";
import FilterIMG from "../../assets/filter";
import { FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { UPDATE_AMENITIES_MASTER } from "../../graphql/mutations";
import { GET_INVOICE_TABLE_LIST } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, InvoiceHeading, InvoicePath, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { InvoiceStyles } from "./style";





const CustomPaper = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#ffffff",
    padding: theme.spacing(2),
    margin: theme.spacing(3)
}))


export const Invoice = () => {
    const classes = InvoiceStyles()
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const auth = React.useContext(AuthContext);
    const history = useHistory()
    const [companyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [InvoiceList, setInvoiceList] = React.useState({});
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [searchText, setSearchText] = React.useState("");
    const [drawer, setDrawer] = React.useState(null);
    const [filterData, setFilterData] = React.useState({ status: [true] });
    const [loading, setLoading] = React.useState(true)
    const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);


    const draweropen = () => {
        setDrawer(true);
    };
    const drawerclose = () => {
        setDrawer(false);
    };
    const debounce = UseDebounce();

    const client = useApolloClient();
    //more options
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        InvoiceTableData(offset, limit, searchText)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        InvoiceTableData(0, value, searchText)
    }

    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        InvoiceTableData(0, limit, e)
    }







    //table listing function
    const InvoiceTableData = async (offset = 0, limit = 10, search = "") => {
        let companyID = [selectedCompany?.value];
        let is_active = filterData?.status ?? [true];
        const payload = {
            query: GET_INVOICE_TABLE_LIST(clientId, offset, limit, search, companyID, is_active).loc.source.body,

        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {

                setInvoiceList({
                    data: response?.data?.data?.invoice,
                    totalRowsCount: response?.data?.data?.count?.[0]?.count,

                })
                setLoading(false)
                backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            }).catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                });
                setLoading(false)

            });
    }
    // is active changing function
    const changeactive = async (data, value) => {
        client.mutate({
            mutation: UPDATE_AMENITIES_MASTER,
            variables: {
                ID: data,
                updatePayload: {
                    is_active: value === "Inactive" ? true : false
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Status Updated",
            });
            InvoiceTableData()
            console.log(rs)
        }).catch(er => {
            console.log(er)
        })
    }
    //delete function
    const deleteTableData = async (data) => {
        client.mutate({
            mutation: UPDATE_AMENITIES_MASTER,
            variables: {
                ID: data,
                updatePayload: {
                    is_delete: true
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Deleted",
            });
            InvoiceTableData()
            console.log(rs)
        }).catch(er => {
            console.log(er)
        })
    }
    //table row constructions
    const InvoiceRow = InvoiceList?.data?.map((val, index) => {
        let _d;
        try {
            _d = {
                index: ((page - 1) * limit) + index + 1,
                invoiceNumber: val?.invoice_no ?? " - ",
                accountID: val?.account_no ?? " - ",
                invoiceAmount: `${selectedCompany?.currency_symbol} ${val?.invoice_total_amount}` ?? " - ",
                invoiceType: val?.invoice_type ?? " - ",
                generatedDate: val?.created_at ?? " - ",
                dueDate: val?.payment_due_date ?? " - ",
                balance: `${selectedCompany?.currency_symbol} ${val?.invoice_due_amount}` ?? " - ",
                status: val?.payment_status === "no-due" ? "Paid" : "Unpaid",
                agreementNumber: val?.agreement?.agreement_no ?? "-",
                icon: "more",
                id: val?.id,

            };
        } catch (err) {

        }
        return _d;
    });

    React.useEffect(() => {
        if (selectedCompany?.value) {
            InvoiceTableData()
        }
        //eslint-disable-next-line
    }, [selectedCompany, filterData]);

    const handleIcon = (type, data, status) => {
        if (type === "view") {
            history.push({
                pathname: Routes.invoiceView,
                state: data?.id
            })
        }
        else if (type === "edit") {

        }
        else if (type === "active") {
            changeactive(data.id, status)
        }
        else if (type === "delete") {
            deleteTableData(data.id, data.IsDelete)
        }

    }



    //apply filter
    const onApplyFilter = (value) => {

        setFilterData(value)
    }

    const render = () => {
        return <>
            {loading ? <LoadingSection bottom={"45vh"} message="Loading Invoice Listing..." />
                :
                <>
                    <div>

                        <Subheader title={"Invoice"} count={false} hideBackButton select options={companyList} value={selectedCompany}
                            onchange={(e) =>
                                setSelectedCompany(e)
                                // getProperty(e)
                            }
                        />

                        <CustomPaper>
                            <Grid container className={classes.content} spacing={2}>
                                <Grid item xs={4}>
                                    <SearchFilter placeholder={"Search by invoice number"} value={searchText} handleChange={(value) => handleSearch(value)} />
                                </Grid>
                                <Grid item xs={8} textAlign={"right"}>
                                    <Box
                                        display={"flex"}
                                        sx={{ float: "right" }}
                                    >
                                        <Box marginRight={"12px"}>
                                            {filterData.status?.length > 0 || filterData.property_type?.length > 0 ? (
                                                <IconButton onClick={draweropen} className={classes.img}>
                                                    <Badge variant="dot" color="primary">
                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton>

                                            ) : (
                                                <IconButton onClick={draweropen} className={classes.img}>
                                                    <FilterIMG color="#091b29" />
                                                </IconButton>)}

                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TableWithPagination
                                        heading={InvoiceHeading}
                                        rows={InvoiceRow?.length ? InvoiceRow : []}
                                        path={InvoicePath}
                                        showpagination={true}
                                        tableType="no-side"
                                        handleIcon={handleIcon}
                                        onClick={() => null}
                                        showpdfbtn={false}
                                        showexcelbtn={false}
                                        showSearch={false}
                                        dataType={[
                                            { type: ["text"], name: "invoiceNumber" },
                                            { type: ["text"], name: "invoiceType" },
                                            { type: ["text"], name: "agreementNumber" },
                                            { type: ["text"], name: "accountID" },
                                            { type: ["text"], name: "invoiceAmount" },
                                            { type: ["date"], name: "generatedDate" },
                                            { type: ["date"], name: "dueDate" },
                                            { type: ["status"], name: "status" },
                                            { type: ["text"], name: "balance" },
                                        ]}
                                        handlePagination={handlePagination}
                                        handleChangeLimit={handleChangeLimit}
                                        totalRowsCount={InvoiceList?.totalRowsCount}
                                        page={page}
                                        limit={limit}
                                        height={`calc(100vh -  290px)`}
                                        view={permission?.read}
                                        edit={permission?.update}
                                        delete={permission?.delete} />
                                </Grid>
                            </Grid>
                        </CustomPaper>

                    </div>



                    {/* filter drawer */}
                    {
                        drawer &&
                        <FilterGenerator
                            open={drawer}
                            onClose={drawerclose}
                            components={[
                                {
                                    component: "toggleButton",
                                    value: filterData?.status,
                                    options: [
                                        { label: 'Active', value: true },
                                        { label: 'Inactive', value: false },
                                    ],
                                    isMulti: true,
                                    state_name: "status",
                                    label: "Status",
                                    // required:true
                                },
                            ]}
                            onApply={(value) => onApplyFilter(value)}
                        />
                    }
                </>
            }
        </>
    }


    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};

