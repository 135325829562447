import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";

export const PriorityStyles = makeStyles((theme) => ({
    priorityBox:{
        padding:"10px",
        borderRadius:theme.palette.borderRadius,
        display:"flex",
        justifyContent:"space-between",
        backgroundColor:"#06D6A0",
        marginTop:"5px"
    },
    priorityLabel: {
        fontSize: "12px",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
        marginBottom: "5px"
      },
      initialText:{
        color:"white",
        textTransform:"capitalize"

      },
      arrowIcon:{
        color:"white"
      },
      menuOptions:{
        border:"1px solid #E4E8EE",
        backgroundColor:"white",
        borderRadius:"4px",
        marginTop:"2px",
        padding:"12px"
      },
      dot:{
        width:"8px",
        height:"8px",
        borderRadius:"50%",
        backgroundColor:"black",
        marginTop:"8px"
      },
      priorityDivider:{
        // marginBottom:"5px"
      },
      priorityName:{
        fontSize:"14px",
        color:theme.typography.color.primary,
        fontFamily: SemiBold,
        textTransform:"capitalize"
      },
      menu: {
        "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
          {
            borderRadius: theme.palette.borderRadius,
            padding: "0px !important",
            boxShadow: "0px 8px 24px #0717411F",

          },
        "& .MuiMenu-list": {
          padding: "0 !important",
        },
      },
      required: {
        color: "red",
        fontFamily: SemiBold,
        fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
        marginBottom: (props) => props?.labelMarginBottom ?? "5px",
      },
}))