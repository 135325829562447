import { Box, Typography } from "@mui/material"
import React from "react"
import NoInspectionData from "../../assets/NoInspectionData"
import { NoInspectionStyles } from "./styles"
export const NoInspectionImage = ({ message = "", note = "" }) => {
    const classes = NoInspectionStyles();
    return (
        <Box className={`${classes.noInspection}`}>
            <Box>
                <center>
                    <NoInspectionData />
                    <Typography className={`${classes.message}`}>{message}</Typography>
                    <Typography className={`${classes.note}`}>{note}</Typography>
                </center>
            </Box>
        </Box>
    )
}