import { Grid } from "@mui/material";
import React from "react";
import { Subheader } from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { accessCheckRender, AlertProps, BackendRoutes, getCalendarOptions, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods, pageName } from "../../utils";
import { useStyles } from "./style";
import { CalendarDetail, CardDetail, DiscussonDetail, TableDetail } from "./components"
import { OwnerHeading, OwnerPath, OwnerType, ResidentHeading, ResidentPath, ResidentType, TableName } from "./utils/tableUtils";
import { StatDataFunc } from "./utils/data";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import moment from "moment";
import { NewLoader } from "../../components/newLoader";

export const CommunityDashboard = (props) => {
    const { loading, handleLoading } = props;

    const classes = useStyles()
    const currentDate = moment(new Date()).format("YYYY-MM-DD")

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)

    // useState
    const [companyList, setCompanyList] = React.useState([])
    const [calendarOptions, setCalendarOptions] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [selectedCalendar, setSelectedCalendar] = React.useState({})
    // As per CR commented
    // const [generalAndMaintenanceData, setgeneralAndMaintenanceData] = React.useState([])
    // const [value, setValue] = React.useState("General")
    // const [searchText, setSearchText] = React.useState("")
    const [stat, setStat] = React.useState({})
    const [residentList, setResidentList] = React.useState({ count: 0, list: [] })
    const [ownerList, setOwnerList] = React.useState({ count: 0, list: [] })
    const [discussionList, setDiscussionList] = React.useState({ count: 0, list: [] })
    const [activityList, setActivityList] = React.useState([])
    const [loader, setLoader] = React.useState(true)
    const [permission, setPermission] = React.useState({});
    // const [zoomGraph, setZoomGraph] = React.useState("");
    // const [zoom, setZoom] = React.useState(false);

    // useEffect to get company list for company switcher when loading the screen
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    let tempCalendarOptions = getCalendarOptions()
                    setCalendarOptions(tempCalendarOptions)
                    setSelectedCalendar(tempCalendarOptions[0])
                    getStats(company?.selected, tempCalendarOptions[0].value.from_date, tempCalendarOptions[0].value.to_date)
                    getResidents(company?.selected, 0, true)
                    getOwners(company?.selected, 0, true)
                    getDiscussion(company?.selected, tempCalendarOptions[0].value.from_date, tempCalendarOptions[0].value.to_date)
                    getActivities(company?.selected)
                    // As per CR commented
                    // GeneralAndMaintenance(company?.selected?.value, value, searchText)
                }
            }
        }

        // eslint-disable-next-line
    }, [auth])

    // Function to change the company
    const handleCompanyChange = (company) => {
        setLoader(true)
        setSelectedCompany(company)
        getStats(company, selectedCalendar.value.from_date, selectedCalendar.value.to_date)
        getResidents(company, 0, true)
        getOwners(company, 0, true)
        getDiscussion(company, selectedCalendar.value.from_date, selectedCalendar.value.to_date)
        getActivities(company)
        // As per CR commented
        // GeneralAndMaintenance(company?.value, value, searchText)
    }
    // Function to change the Calendar
    const handleCalendarChange = (date) => {
        if (date?.load) {
            setLoader(true)
            setSelectedCalendar(date)
            getStats(selectedCompany, date.value.from_date, date.value.to_date)
            getDiscussion(selectedCompany, date.value.from_date, date.value.to_date)
        }
    }

    const getActivityDate = (val) => {
        let tempDate = val?._d ? val?._d : val;
        let _tempDate = moment(tempDate).format("YYYY-MM-DD")
        getActivities(selectedCompany, _tempDate)
    }

    // As per CR commented

    //General and maintenance api call
    // const GeneralAndMaintenance = (company, type, search) => {
    //     let companyId = [company];
    //     const payload = {
    //         company_id: companyId,
    //         request_type: type ? [type] : ["General"],
    //         search: search ?? "",
    //         offset: 0, limit: 10,
    //     }
    //     NetworkCall(
    //         `${config.api_url}/request`,
    //         NetWorkCallMethods.post, payload, null, true, false
    //     ).then((_) => {
    //         if (_?.data?.data) {
    //             let result = _?.data?.data?.map((_, i) => {
    //                 return {
    //                     id: i,
    //                     title: _?.subject ?? " - ",
    //                     type: _?.request_type ?? " - ",
    //                     date: _?.create_date
    //                         ? moment(_?.create_date).format("DD MMM")
    //                         : " - ",
    //                     unitNo: _?.units?.unit_no ?? "-",
    //                 };
    //             });
    //             setgeneralAndMaintenanceData({
    //                 data: result,
    //                 generalCount: _?.data?.generalCount,
    //                 maintanceCount: _?.data?.maintenanceCount,
    //             })
    //         }
    //         handleLoading(false)
    //         setLoader(false)
    //     }).catch((e) => {
    //         backdrop.setBackDrop({ ...backdrop, open: false, message: "", })
    //         alert.setSnack({
    //             ...alert, severity: AlertProps.severity.error,
    //             open: true, msg: "Some Thing Went Wrong",
    //         })
    //     })
    // }
    //Function to get STATS API Call
    const getStats = (company = selectedCompany, from_date = currentDate, to_date = currentDate) => {
        const payload = {
            company_id: company?.value,
            from_date, to_date
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes.communityDashboardStats}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((_) => {
            let tempStat = StatDataFunc(_?.data?.data)
            setStat(tempStat)
            handleLoading(false)
            setLoader(false)
        }).catch((e) => {
            alert.setSnack({
                ...alert, severity: AlertProps.severity.error,
                open: true, msg: "Some Thing Went Wrong",
            })
        })
    }
    //Function to get residents List API Call
    const getResidents = (company = selectedCompany, offsetValue, reload) => {
        const payload = {
            company_id: company?.value,
            offset: offsetValue,
            limit: 10
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes.communityDashboardResidents}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((_) => {
            let tempData = _?.data?.data ?? []
            let tempList = tempData?.map((r) => {
                return {
                    saleRep: { image: r?.image_url ?? "-", name: r?.name ?? "" },
                    unitName: r?.unit_no + (r?.unit_name ? (" - " + r?.unit_name) : "")
                }
            })
            let tempResidentList = { count: tempData?.count ?? 0, list: tempList ?? [] }
            if (reload) {
                setResidentList(tempResidentList)
            } else {
                setResidentList({ list: [...residentList?.list, ...tempResidentList?.list] })
            }
            handleLoading(false)
            setLoader(false)
        }).catch((e) => {
            alert.setSnack({
                ...alert, severity: AlertProps.severity.error,
                open: true, msg: "Some Thing Went Wrong",
            })
        })
    }
    //Function to get owners List API Call
    const getOwners = (company = selectedCompany, offsetValue, reload) => {
        const payload = {
            company_id: company?.value,
            offset: offsetValue,
            limit: 10
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes.communityDashboardOwners}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((_) => {
            let tempData = _?.data?.data ?? []
            let tempList = tempData?.map((r) => {
                return {
                    saleRep: { image: r?.image_url ?? "-", name: r?.name ?? "" },
                    unitName: r?.unit_no + (r?.unit_name ? (" - " + r?.unit_name) : "")
                }
            })
            let tempOwnerList = { count: tempData?.count ?? 0, list: tempList ?? [] }
            if (reload) {
                setOwnerList(tempOwnerList)
            } else {
                setOwnerList({ list: [...ownerList?.list, ...tempOwnerList?.list] })
            }
            handleLoading(false)
            setLoader(false)
        }).catch((e) => {
            alert.setSnack({
                ...alert, severity: AlertProps.severity.error,
                open: true, msg: "Some Thing Went Wrong",
            })
        })
    }
    //Function to get discussion List API Call
    const getDiscussion = (company = selectedCompany, from_date = currentDate, to_date = currentDate) => {
        const payload = {
            company_id: company?.value,
            from_date, to_date,
            offset: 0, limit: 10000
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes.communityDashboardDiscussions}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((_) => {
            let tempDiscussionList = { count: _?.data?.count ?? 0, list: _?.data?.data ?? [] }
            setDiscussionList(tempDiscussionList)
            handleLoading(false)
            setLoader(false)
        }).catch((e) => {
            alert.setSnack({
                ...alert, severity: AlertProps.severity.error,
                open: true, msg: "Some Thing Went Wrong",
            })
        })
    }
    //Function to get activities List API Call
    const getActivities = (company = selectedCompany, date = currentDate) => {
        const payload = {
            company_id: company?.value,
            from_date: date,
            to_date: date,
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes.communityDashboardActivities}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((_) => {
            let tempActivityList = _?.data?.data ?? []
            setActivityList(tempActivityList)
            handleLoading(false)
            setLoader(false)
        }).catch((e) => {
            alert.setSnack({
                ...alert, severity: AlertProps.severity.error,
                open: true, msg: "Some Thing Went Wrong",
            })
        })
    }

    // As per CR commented

    // tab change function
    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    //     GeneralAndMaintenance(selectedCompany?.value, newValue, searchText);
    // }
    // // request search
    // const handleSearch = (e) => {
    //     setSearchText(e);
    //     GeneralAndMaintenance(selectedCompany?.value, value, e);
    // }

    const [offset, setOffset] = React.useState(0);
    const [ownerOffset, setOwnerOffset] = React.useState(0)


    const fetchMoreData = () => {
        setOffset(offset + 10);
        getResidents(selectedCompany, offset + 10, false)
    };

    const fetchMoreOwnerData = () => {
        setOwnerOffset(ownerOffset + 10);
        getOwners(selectedCompany, ownerOffset + 10, false)
    };

    // zoom function
    // const zoomGraphFunction = (e) => {
    //     setZoomGraph(e);
    //     setZoom(true);
    // };

    // close popup
    // const handleCloseModule = () => {
    //     setZoom(false);
    // };

    const render = () => {
        return <div className={classes.root}>
            <Subheader hideBackButton={true} title="Community Dashboard" calanderselect
                calendarOptions={calendarOptions} calanderValue={selectedCalendar}
                onChangeCalendar={(e) => handleCalendarChange(e)} select options={companyList}
                value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
            {loader ? (<NewLoader minusHeight="100px" />) :
                (<div className={classes.body}>
                    <Grid container>
                        <Grid container spacing={"16px"}>
                            <Grid xs={12} sm={12} md={2} lg={1.5} item alignContent={"start"}
                                justifyContent={"space-between"}>
                                <Grid container spacing={"16px"}>
                                    {stat?.cardDetails1?.map((_) => {
                                        return <Grid item xs={12} sm={4} md={12} lg={12}>
                                            <CardDetail data={_} />
                                        </Grid>
                                    })}
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={12} md={10} lg={3.75} item alignContent={"start"}>
                                <div className={classes.table}>
                                    <TableDetail
                                        title={TableName.resident}
                                        tableData={residentList?.list ?? []}
                                        tabelheight={350}
                                        heading={ResidentHeading}
                                        path={ResidentPath}
                                        dataType={ResidentType}
                                        fetchMoreData={fetchMoreData} />
                                </div>
                            </Grid>
                            <Grid xs={12} sm={12} md={2} lg={1.5} item alignContent={"start"}
                                justifyContent={"space-between"}>
                                <Grid container spacing={"16px"}>
                                    {stat?.cardDetails2?.map((_) => {
                                        return <Grid item xs={12} sm={6} md={12} lg={12}>
                                            <CardDetail data={_} />
                                        </Grid>
                                    })}
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={12} md={10} lg={3.75} item alignContent={"start"}>
                                <div className={classes.table}>
                                    <TableDetail
                                        title={TableName.owner}
                                        tableData={ownerList?.list ?? []}
                                        tabelheight={296}
                                        heading={OwnerHeading}
                                        path={OwnerPath}
                                        dataType={OwnerType}
                                        fetchMoreData={fetchMoreOwnerData} />
                                </div>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={1.5} item alignContent={"start"}
                                justifyContent={"space-between"}>
                                <Grid container spacing={"16px"}>
                                    {stat?.cardDetails3?.map((_) => {
                                        return <Grid item xs={12} sm={4} md={4} lg={12}>
                                            <CardDetail data={_} />
                                        </Grid>
                                    })}
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={12} md={2} lg={1.5} item alignContent={"start"}
                                justifyContent={"space-between"}>
                                <Grid container spacing={"16px"}>
                                    {stat?.cardDetails4?.map((_) => {
                                        return <Grid item xs={12} sm={4} md={12} lg={12}>
                                            <CardDetail data={_} />
                                        </Grid>
                                    })}
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={12} md={10} lg={3.75} item alignContent={"start"}>
                                <div className={classes.table}>
                                    <DiscussonDetail
                                        title={TableName.activeDiscussion}
                                        data={discussionList?.list ?? []}
                                        height={"300px"} />
                                </div>
                            </Grid>
                            <Grid xs={12} sm={12} md={2} lg={1.5} item alignContent={"start"}
                                justifyContent={"space-between"}>
                                <Grid container spacing={"16px"}>
                                    {stat?.cardDetails5?.map((_) => {
                                        return <Grid item xs={12} sm={4} md={12} lg={12}>
                                            <CardDetail data={_} />
                                        </Grid>
                                    })}
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={12} md={10} lg={5.25} item alignContent={"start"}>
                                <div className={classes.table}>
                                    <CalendarDetail
                                        title={TableName.todayActivity}
                                        data={activityList ?? []}
                                        getDate={getActivityDate}
                                    />
                                </div>
                            </Grid>
                            {/* <Grid xs={12} sm={6} md={6} lg={3} item alignContent={"start"}>
                                <div className={classes.table}>
                                    <GraphComponent
                                        title={"Violation By Type"}
                                        graphData={GraphDetails1 ?? []}
                                        isPie
                                        margin={"20px"}
                                        onZoomClick={zoomGraphFunction}
                                        isZoom />
                                </div>
                            </Grid>
                            <Grid xs={12} sm={6} md={6} lg={3} item alignContent={"start"}>
                                <div className={classes.table}>
                                    <GraphComponent
                                        title={"Violation Status"}
                                        graphData={GraphDetails2.graphData ?? []}
                                        innerRadius={50}
                                        margin={"30px"}
                                        isPie
                                        isTotal
                                        total={GraphDetails2.total ?? 0}
                                        onZoomClick={zoomGraphFunction}
                                        isZoom />
                                </div>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={6} item alignContent={"start"}>
                                <div className={classes.table}>
                                    <TableDetail
                                        title={"Top 10 Violations"}
                                        tableData={TableData ?? []}
                                        tabelheight={350}
                                        heading={OwnerHeading}
                                        path={OwnerPath}
                                        dataType={OwnerType} />
                                </div>
                            </Grid> */}
                            {/* As per CR commented */}

                            {/* <Grid xs={12} sm={12} md={4} lg={2} item alignContent={"start"}
                        justifyContent={"space-between"}>
                        <Grid container spacing={"16px"}>
                            {stat?.cardDetails6?.map((_) => {
                                return <Grid item xs={12} sm={4} md={12} lg={12}>
                                    <CardDetail data={_} />
                                </Grid>
                            })}
                        </Grid>
                    </Grid> */}
                            {/* <Grid xs={12} sm={12} md={6} lg={6} item alignContent={"start"}>
                        <div className={classes.table}>
                            <CalendarDetail
                                title={TableName.amenitiesBookingCalendar}
                                data={activityList ?? []}
                                getDate={getActivityDate}
                                height={"419px"}
                            />
                        </div>
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={6} item alignContent={"start"}>
                        <div className={classes.table}>
                            <RequestCard
                                generalAndMaintenanceData={generalAndMaintenanceData}
                                handleChange={handleChange}
                                handleSearch={handleSearch}
                                value={value}
                                height={"100%"}
                                searchText={searchText} />
                        </div>
                    </Grid> */}
                        </Grid>
                    </Grid>
                </div>)}
            {/* zoom dialog for each graph */}
            {/* <DialogBox
                isnotTitle
                maxWidth={zoomGraph === "Map View" ? "lg" : "md"}
                handleClose={handleCloseModule}
                open={zoom}
                height={"400px"}
                borderRadius={"12px"}
                component={
                    <div>
                        {zoomGraph === "Violation By Type" && (
                            <GraphComponent
                                title={"Violation By Type"}
                                graphData={GraphDetails1 ?? []}
                                isPie
                                margin={"80px"}
                                divider={6}
                                divider1={6}
                                padding={"24px 24px 47px"}
                                maxHeightGraph={"250px"}
                                minWidth={false}
                                height={"400px"}
                            />
                        )}
                        {zoomGraph === "Violation Status" && (
                            <GraphComponent
                                title={"Violation Status"}
                                graphData={GraphDetails2.graphData ?? []}
                                isPie
                                isTotal
                                total={GraphDetails2.total ?? 0}
                                innerRadius={50}
                                margin={"80px"}
                                divider={6}
                                divider1={6}
                                padding={"24px 24px 47px"}
                                maxHeightGraph={"250px"}
                                minWidth={false}
                                height={"400px"}
                            />
                        )}
                    </div>
                }
            /> */}
        </div>


    }

    return <div>{accessCheckRender(render, permission, pageName.dashboard, loading)}</div>;
}