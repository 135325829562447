export const ParkingAreaPath = ["propertyName", "vehicleParkingArea", "description", "status"]

export const ParkingAreaHeading = [
    { title: "Property Name", field: "propertyName" },
    { title: "Parking Area Name", field: "vehicleParkingArea" },
    { title: "Notes", field: "description" },
    { title: "Status", field: "status", },
    { title: "", field: "icon" },
]

export const ParkingAreaType = [
    { type: ["text"], name: "propertyName" },
    { type: ["text"], name: "vehicleParkingArea" },
    { type: ["text"], name: "description" },
    { type: ["status"], name: "status" },
    { type: ["more"], icon: "icon" },
]

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]