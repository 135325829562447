import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { componantsStyles } from "./styles";
import {timeZoneConverter} from "../../../utils";

export const LeadUnitDetails = (props) => {
    const classes = componantsStyles()
    return (
        <div className={classes.rootcard}>
            {
                props?.lead &&
                <Box display="flex" className={classes.leadContainer}>
                    <Box marginBottom="-4px">
                        <img
                            src={props.data.img?.length > 0 ? props.data.img : "/images/profile_image.png"}
                            alt={props.data.name}
                            className={classes.img}
                        />

                    </Box>
                    <Box marginTop="6px" marginLeft="8px">
                        <Stack direction="row">
                            <Typography className={classes.name}>
                                {props.data.name}
                            </Typography>
                            {
                                props?.isNew &&
                                <Typography className={classes.new}>
                                    New
                                </Typography>
                            }
                            {
                                props?.isNew &&
                                <Typography className={classes.prospect}>
                                    Prospect
                                </Typography>
                            }

                        </Stack>

                        <Box height="4px" />
                        <Stack direction="row">
                            <Box>
                                <Typography className={classes.call}>
                                    {props.data.phone}
                                </Typography>
                            </Box>
                            <Box className={classes.periodStyle} />
                            <Box>
                                <Typography className={classes.mail}>
                                    {props.data.mail}
                                </Typography>
                            </Box>
                            {props.data.nationality &&
                                <>
                                    <Box className={classes.periodStyle} />
                                    <Box>
                                        <Typography className={classes.mail}>
                                            {props.data.nationality}
                                        </Typography>
                                    </Box>
                                </>
                            }

                        </Stack>
                    </Box>
                </Box>
            }
            {
                props?.unit &&
                <Box display="flex" alignItems="center" marginTop="8px" padding="0px 4px 4px 4px">
                    <Box className={classes.unitImg}>
                        <center>
                            <img
                                src="/images/unitAgreement.svg"
                                alt=""

                            />
                        </center>
                    </Box>
                    <Box flexGrow={1} marginLeft="12px">
                        <Typography className={classes.name}>
                                {props?.quott?.unit > 1 ? `${props?.quott?.unit} Units` : props?.quott?.unit_details?.[0]?.name}
                        </Typography>
                        <Typography className={classes.call}>
                            {timeZoneConverter(props?.quott?.startDate)
                                } - {timeZoneConverter(props?.quott?.endDate)
                                    }
                        </Typography>
                    </Box>
                    <Box>
                        <Typography className={classes.name} style={{ color: "#4E5A6B" }}>
                            {props?.quott?.symbol}&nbsp;{props?.total}
                        </Typography>
                    </Box>
                </Box>
            }

        </div>
    )
}