import { makeStyles } from "@mui/styles";

export const CreateCardStyle = makeStyles((theme) => ({
    title: {
        fontSize: 16,
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    content: {
        fontSize: 14,
        color: theme.typography.color.tertiary,
    }
}))

export const PublishedPopupStyle = makeStyles((theme) => ({
    content: {
        fontSize: 16,
        color: "white",
        fontWeight: "bold"
    },
    btn: {
        height: 40,
        width: 150,
        color: theme.palette.primary.main,
        background: "#fff",
        borderRadius: 4,
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "center",
        '&.hover': {
            color: theme.palette.primary.main,
            background: "#fff",
        }
    },
    nobtn: {
        height: 40,
        width: 150,
        color: "#fff",
        border: "1px solid #fff",
        background: theme.palette.primary.main,
        borderRadius: 4,
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "center",
        '&.hover': {
            color: "#fff",
            border: "1px solid #fff",
            background: theme.palette.primary.main,
        }
    },
    dialogConten:{
        padding:"0 !important"
    }
}))