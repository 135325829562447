import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { reservationStyle } from "./style";
import { ReservationCard } from "../component";
import { ContactList, AlertDialog } from "../../../components";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { saveAs } from "file-saver";
import { Download } from "../../../assets";
import { DocumentViewer } from "../../../components/fileViewer";
import { CancelBooking } from "../../quotationDetails/components";
import {timeZoneConverter} from "../../../utils"

export const DetailsSection = ({
  t,
  details = {},
  id = "",
  permission = {},
}) => {
  const classes = reservationStyle();

  const [openpdf, setOpenpdf] = React.useState(false);
  const [cancelBooking, setCancel] = React.useState({
    bool: false,
    data: "",
  });

  //download pdf
  const downloadPdf = () => {
    saveAs(details?.url, `${details?.quotation_no}.pdf`);
  };
  //render btn
  const renderButton = () => {
    return (
      <Button
        onClick={downloadPdf}
        className={classes.downBtn}
        variant="outlined"
        startIcon={<Download />}
      >
        {t("Download")}
      </Button>
    );
  };
  const detailsCard = [
    {
      title: t("GENERATED_ON"),
      sub: timeZoneConverter(details?.quott?.created_at),
    },
    {
      title: t("LEASE_START_DATE"),
      sub: timeZoneConverter(details?.quott?.lease_start_date),
    },
    {
      title: t("LEASE_END_DATE"),
      sub: timeZoneConverter(details?.quott?.lease_end_date),
    },
    {
      title: t("RENT_START_DATE"),
      sub: timeZoneConverter(details?.quott?.billing_start_date),
    },
    {
      title: t("GRACE_PERIOD"),
      sub: "Days(In the Beginning)",
    },
  ];

  //on cancel Booking
  const onCancelBooking = (datas) => {
    setCancel({
      bool: !cancelBooking?.bool,
      data: datas,
    });
  };

  return (
    <Box className={classes.container}>
      {/*  */}
      <Box className={classes.main}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={classes.title}>
          {" "}
          {t("Reservation_Details")}
        </Typography>
        {/* <Typography className={classes.viewHistory}>
          {t("View_History")}
        </Typography> */}
      </Box>
      <Box height={"14px"} />
      {/*ReservationCard  */}
      <ReservationCard
        onCancel={onCancelBooking}
        details={{
          reservation_no: details?.reservation?.reservation_no,
          symbol: details?.reservation?.currency?.symbol,
          reserved_amount: details?.reservation?.reserved_amount,
          date: `${timeZoneConverter(details?.reservation?.start_date)} - ${timeZoneConverter(details?.reservation?.end_date)}`,
          is_active: details?.reservation?.is_active,
          paid_on_date:
            details?.reservation?.invoice?.paid_on?.length > 0
              ? timeZoneConverter(details?.reservation?.invoice?.paid_on)
              : "",
        }}
        permission={permission}
        t={t}
      />
      <Box height={"14px"} />
      {/* Quotation Details */}
      <Box display="flex" alignItems="center">
        <Typography className={classes.quationName}>
          {t("Quotation_Details")} ({details?.quott?.quotation_no})
        </Typography>
        <Box className={classes.badge}>
          {details?.quott?.is_active ? "Active" : "In Active"}
        </Box>
      </Box>
      <Box height={"14px"} />
      <Box className={classes.quatationCard}>
        <Grid container spacing={3}>
          {detailsCard?.map((x) => {
            return (
              <Grid item xs={4}>
                <Typography className={classes.subject}>{x?.title}</Typography>
                <Typography className={classes.contant}>{x?.sub}</Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box height={"14px"} />
      <Typography className={classes.quationName}>
        {t("Lead_Details")}
      </Typography>
      <Box height={"14px"} />
      <ContactList
        data={{
          name: `${details?.quott?.first_name ?? ""} ${
            details?.quott?.last_name ?? ""
          }`,
          img:
            details?.quott?.image_url?.length > 0
              ? details?.quott?.image_url
              : null,
          arg: false,
          phone: `${details?.quott?.mobile_no_country_code ?? ""} ${
            details?.quott?.mobile_no ?? ""
          }`,
          mail: details?.quott?.email_id,
        }}
        leadtype={true}
      />
      </Box>
      {permission?.read && (
        <Button
          startIcon={<FileDownloadOutlinedIcon />}
          onClick={() => setOpenpdf(true)}
          variant="outlined"
          className={classes.reserve}
          fullWidth
        >
          {t("View_Quotation")}
        </Button>
      )}

      {/* quotation pdf */}
      <AlertDialog
        header={details?.quott?.quotation_no}
        onClose={() => setOpenpdf(false)}
        open={openpdf}
        render={renderButton()}
        component={
          <Box p={1} bgcolor={"#F5F7FA"}>
            <center>
              {details?.quott?.url ? (
                <DocumentViewer url={details?.quott?.url} />
              ) : (
                "No Data Found"
              )}
            </center>
          </Box>
        }
      />
      {/*cancel booking*/}
      <AlertDialog
        component={
          <CancelBooking
            company_id={details?.quott?.company_id}
            quotation_id={id}
            onClose={onCancelBooking}
            // reload={getQuotDetail}
            type={
              details?.reservation?.reservation_status === "Booked"
                ? "Booking"
                : "Reserved"
            }
            data={cancelBooking?.data}
          />
        }
        header="Cancel Booking"
        open={cancelBooking?.bool}
        onClose={onCancelBooking}
        isNormal={true}
      />
    </Box>
  );
};
