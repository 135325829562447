import gql from "graphql-tag"

export const userPath = [
  "img", "userNo", "userName", "emailId", "phone", "rights", "department"
]


export const userHeading = [
  { title: " ", field: 'icon' },
  { title: "Image", field: 'img' },
  { title: "User UID", field: "userNo", },
  { title: "User Name", field: "userName", },
  { title: "Department", field: "department", },
  { title: "Email ID", field: "emailId", },
  { title: "Phone", field: 'phone', },
  { title: "Rights", field: "rights", },
  { title: " ", field: "is_team_lead", },

]

export const propertyPath = [
  "propertyNo", "propertyName", "location", "propertyGroup", "propertyPurpose", "propertyHierachy"
]


export const propertyHeading = [
  { title: " ", field: "icon", },
  { title: "Property No", field: "propertyNo", },
  { title: "Property Name", field: "propertyName", },
  { title: "Location", field: "location", },
  { title: "Property Purpose", field: "propertyPurpose", },
  { title: "Type", field: 'propertyGroup', },
  { title: "Hierachy", field: "propertyHierachy", },

]
export const GET_PROPERTIES_BY_COMPANY_ID = (offset, limit, searchText, params) => gql`
query {
  count:property(
    where:{
      name:{iregex:"${searchText}"}
      company_id:{eq:"${params?.company_id}"}
    }
  ){
    count:count_id
  }
  property(
    where:{
      name:{iregex:"${searchText}"}
            company_id:{eq:"${params?.company_id}"}

    }
    offset:${offset}
    limit:${limit}
  ) {
    value: id
    label: name
  }
}
`
