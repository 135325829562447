import { Box, Table, TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { TotalTableStyle } from './style'

const TotalTable = ({ invoiceData = {}, t, selectedCompany={} }) => {
    const classes = TotalTableStyle()
    return (
        <Box>
            <Table>
                <TableRow>
                    <TableCell className={classes.normalCell}>
                        <Typography className={classes.font10}>{t("TotalTaxableAmount")}</Typography>
                    </TableCell>
                    <TableCell className={classes.normalCell}>
                        <Typography className={classes.font11} sx={{ textAlign: "right" }}> {selectedCompany?.currency_symbol ?? '-'} {invoiceData?.totalTaxableAmount} </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.normalCell}>
                        <Typography className={classes.font10}>{t("TotalTaxes")}</Typography>
                    </TableCell>
                    <TableCell className={classes.normalCell}>
                        <Typography className={classes.font10sec} sx={{ textAlign: "right" }}>{selectedCompany?.currency_symbol ?? '-'} {invoiceData?.totalTaxes}</Typography></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.totalCell}>
                        <Typography className={classes.font10}>{t("TotalAmountDue")}</Typography>
                    </TableCell>
                    <TableCell className={classes.totalCell}>
                        <Typography className={classes.font10} sx={{ textAlign: "right" }}>{selectedCompany?.currency_symbol ?? '-'} {invoiceData?.totalAmount}</Typography>
                    </TableCell>
                </TableRow>
            </Table>
        </Box>
    )
}
export default withNamespaces("freeTextInvoice")(TotalTable)