import React from "react"
import { withNavBars } from "../../HOCs"
import { UnitVacancyPropertyView } from "./unitVacancyPropertyView"

class UnitVacancyPropertyViewParent extends React.Component {
    render() {
        return <UnitVacancyPropertyView />
    }
}
const props = { boxShadow: false }

export default withNavBars(UnitVacancyPropertyViewParent, props)