import { Grid, Typography } from "@mui/material";
import React from 'react';
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { contactViewStyles } from "../style";
import moment from "moment";


export const NationalIDDetails = (props) => {
      const classes = contactViewStyles()
      const size = useWindowDimensions()
      return (
            <div style={{ height: size?.height - 210, overflow: "auto", padding: "4px", margin: "-4px" }}>
                  <div className={classes.card}>
                        <Typography className={classes.title} >ID DETAILS</Typography>
                        <Grid container rowSpacing={2} marginTop={1}>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Nationality</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.nationality && props?.data?.nationality !== "") ? props?.data?.nationality : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>National ID</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.national_id && props?.data?.national_id !== "") ? props?.data?.national_id : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>National ID Expiry</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.national_id_expiry && props?.data?.national_id_expiry !== "") ?
                                                moment(props?.data?.national_id_expiry).format("yyyy-MM-DD") : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Passport ID</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.passport_id && props?.data?.passport_id !== "") ? props?.data?.passport_id : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Passport Expiry</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.passport_expiry && props?.data?.passport_expiry !== "") ?
                                                moment(props?.data?.passport_expiry).format("yyyy-MM-DD") : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Resident ID</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.resident_id && props?.data?.resident_id !== "") ? props?.data?.resident_id : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Resident ID Expiry</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.resident_id_expiry && props?.data?.resident_id_expiry !== "") ?
                                                moment(props?.data?.resident_id_expiry).format("yyyy-MM-DD") : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Visa Number</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.visa_no && props?.data?.visa_no !== "") ? props?.data?.visa_no : "-"}
                                    </Typography>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Typography className={classes.heading}>Visa Expiry</Typography>
                                    <Typography className={classes.sub} noWrap>
                                          {(props?.data?.visa_expiry && props?.data?.visa_expiry !== "") ?
                                                moment(props?.data?.visa_expiry).format("yyyy-MM-DD") : "-"}
                                    </Typography>
                              </Grid>
                        </Grid>
                  </div>
            </div>
      )
}