import { Box } from '@mui/material'
import React from 'react'
import { AlertDialog, DailyCalendar, Subheader, UseDebounce } from '../../components'
import { Calendars } from './calendars'
import DeclinePopup from './declinePopup'
import CheckAvailability from './checkAvailability'
import { accessCheckRender, addSecondFormat, AlertProps, constructPropertyList, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from '../../utils'
import { AlertContext, AuthContext, BackdropContext } from '../../contexts'
import { NetworkCall } from '../../networkcall'
import { config } from '../../config'
import moment from 'moment'
import { withNamespaces } from 'react-i18next'
import { addDays } from 'date-fns'

const AmenitiesBooking = ({ t }) => {
    // const
    const debounce = UseDebounce()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)

    // useState
    const [openCA, setOpenCA] = React.useState(false)
    const [openDecline, setOpenDecline] = React.useState(false)
    const [permission, setPermission] = React.useState({})
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [propertyList, setPropertyList] = React.useState([])
    const [selectedProperty, setSelectedProperty] = React.useState({})
    const [amenityCategoryList, setAmenityCategoryList] = React.useState([])
    const [selectedAmenityCategory, setSelectedAmenityCategory] = React.useState({})
    const [amenityList, setAmenityList] = React.useState([])
    const [selectedAmenity, setSelectedAmenity] = React.useState({})
    const [selectedAmenityView, setSelectedAmenityView] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [amenityBooking, setAmenityBooking] = React.useState({})
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [amenityBookingDetail, setAmenityBookingDetail] = React.useState({})
    const [dateRange, setDateRange] = React.useState([])
    const [calendarData, setCalendarData] = React.useState([])
    const [calendarEvent, setCalendarEvent] = React.useState("")
    const [openDailyCalendar, setOpenDailyCalendar] = React.useState(false)
    const [weekdates, setWeekdates] = React.useState({ startDate: new Date(), endDate: addDays(new Date(), 6) })
    const [selected, setSelected] = React.useState("Weekly")
    const [initialLoad,setInitialLoad] = React.useState(false)
    // const [dailyInterval, setDailyInterval] = React.useState([])
    // Check Availability popup function
    const openPopup = () => {
        setOpenCA(true)
    }

    // Decline pop up function
    const handleTableIcon = (type, data) => {
        let viewData = data?.data
        if (type === "view") {
            getBookingDetail(viewData)
        }
    }


    const getBookingDetail = (value) => {
        const payload =
        {
            property_facility_id: value?.property_facility_id,
            booking_id: value?.booking_id
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_booked_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setCalendarEvent("")
                setAmenityBookingDetail(response?.data?.data?.[0] ?? [])
                setOpenDecline(true)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };

    // calender event click function

    const handleEvent = (value) => {
        let payload = {
            property_facility_id: value?.data?.facility_id,
            booking_id: value?.data?.booking_id
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_booked_details`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            setCalendarEvent(r?.data?.data[0])
            setOpenDecline(true)
        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true, severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            })
        })
    }

    const handleMonthEvent = (date) => {
        
        const customDateRange = [moment(date).format("YYYY-MM-DD 00:00:00"), moment(date).format("YYYY-MM-DD 23:59:59")]
        getAmenityBookingCalandarList(
            selectedAmenity, customDateRange,
            selectedCompany, "Daily"
        )
        setOpenDailyCalendar({ status: !openDailyCalendar, selectedDate: date })


    }

    const closeDailyCalendarPopup = () => {
        setOpenDailyCalendar(!openDailyCalendar)
        
        getAmenityBookingCalandarList(
            selectedAmenity, dateRange,
            selectedCompany, selected
        )
    }

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
                // const result = eachHourOfInterval({
                //     start: new Date(2022, 7, 17, 0),
                //     end: new Date(2022, 7, 17, 23)
                // })
                // setDailyInterval(result)
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getPropertyList(company?.selected)
        }
    }

    // Function to change the company
    const handleCompanyChange = (e) => {
        setSelectedCompany(e)
        getPropertyList(e)
    }

    // Function to change property list
    const getPropertyList = (company) => {
        let property = constructPropertyList(company)
        setPropertyList(property?.list);
        setSelectedProperty(property?.list[0])
        getAmenityCategories(company, property?.list[0])
    }

    // Function to change property
    const handlePropertyChange = (e) => {
        setSelectedProperty(e)
        getAmenityCategories(selectedCompany, e)
    }

    // Funtion to get amenity categories
    const getAmenityCategories = (company = selectedCompany, property = selectedProperty) => {
        let payload = {}
        if (property?.label === "All Properties") {
            payload["company_id"] = company?.value
        } else {
            payload["property_id"] = property?.value
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_amenity_categories`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            let tempList = r?.data?.data?.amenities_categories?.map((i) => {
                return {
                    label: i?.amenity_category,
                    value: i?.amenity_category_id
                }
            })
            setAmenityCategoryList(tempList)
            setSelectedAmenityCategory(tempList?.[0])
            getAmenitiesForAmenityCategory(company, property, tempList?.[0])
        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true, severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            })
        })
    }

    // Function to change property
    const handleAmenityCategoryChange = (e) => {
        setSelectedAmenityCategory(e)
        getAmenitiesForAmenityCategory(selectedCompany, selectedProperty, e)
    }

    // Funtion to get amenity categories
    const getAmenitiesForAmenityCategory = (
        company = selectedCompany,
        property = selectedProperty,
        amenityCategory = selectedAmenityCategory
    ) => {
        let payload = { "amenity_category_id": amenityCategory?.value }
        if (property?.label === "All Properties") {
            payload["company_id"] = company?.value
        } else {
            payload["property_id"] = property?.value
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_amenities_for_category`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            let tempList = r?.data?.data?.amenityByDate?.map((i) => {
                return {
                    ...i,
                    label: i?.amenities_name,
                    value: i?.facility_id,
                    assets: i?.assets?.map((j) => {
                        return {
                            url: j
                        }
                    })
                }
            })
            setAmenityList(tempList)
            setSelectedAmenity(tempList?.[0])
            
            if (dateRange.length > 0) {
                getAmenityBookingList(
                    tempList?.[0], dateRange,
                    company, searchText, 0, limit
                )
                getAmenityBookingCalandarList(
                    tempList?.[0], dateRange,
                    company, selected
                )
            }
        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true, severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            })
        })
    }

    React.useEffect(()=>{
        if(dateRange?.length >0 && selectedAmenity?.value && selectedCompany?.value &&!initialLoad){
            getAmenityBookingList(
                selectedAmenity, dateRange,
                selectedCompany, searchText, 0, limit
            )
            getAmenityBookingCalandarList(
                selectedAmenity, dateRange,
                selectedCompany, selected
            )
            setInitialLoad(true)
        }
         // eslint-disable-next-line
    },[dateRange,selectedAmenity,selectedCompany])

    // Function to change property
    const handleAmenityChange = (e) => {
        setSelectedAmenity(e)
        if (dateRange.length > 0) {
            getAmenityBookingList(
                e, dateRange,
                selectedCompany, searchText, 0, limit
            )
            getAmenityBookingCalandarList(
                e, dateRange,
                selectedCompany, selected
            )
        }
    }

    // Function to change property
    const handleAmenityViewChange = (e) => {
        setSelectedAmenityView(e)
    }


    // Function to get date range
    const getDateRange = (date_range = [], type = "") => {
        let tempDateRange = [
            moment(date_range?.[0]).format("YYYY-MM-DD 00:00:00"),
            moment(date_range?.[1]).format("YYYY-MM-DD 23:59:59")
        ]
        setDateRange(tempDateRange)
        if (selectedAmenity?.value) {
            getAmenityBookingList(
                selectedAmenity, tempDateRange,
                selectedCompany, searchText, 0, limit
            )
            getAmenityBookingCalandarList(
                selectedAmenity, tempDateRange,
                selectedCompany, type
            )
        }
    }

    const updateAmenityBookingList = () => {
        getAmenityBookingList(
            selectedAmenity, dateRange,
            selectedCompany, "", 0, limit
        )
    }
    // Funtion to get amenity booking
    const getAmenityBookingList = (
        amenity = selectedAmenity,
        date_range = dateRange,
        company = selectedCompany,
        search = searchText,
        offset = 0,
        limit = 10
    ) => {
        let payload = {
            property_facility_id: amenity?.value,
            date_range: date_range,
            company: company?.value,
            search, offset, limit
        }

        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_my_bookings`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            let totalCount = r?.data?.data?.count
            let list = r?.data?.data?.bookings?.map((i) => {
                return {
                    booking_id: i?.booking_id,
                    amenity: i?.amenities_name,
                    booking_from: moment(i?.booking_start_time).format("DD MMM YY"),
                    booking_to: moment(i?.booking_end_time).format("DD MMM YY"),
                    source: i?.source ?? "-",
                    time_range: i?.period === "Daily" ?
                        i?.booking_start_time + " - " + i?.booking_end_time
                        :
                        addSecondFormat(i?.booked_slots?.[0]?.[0], 0, "HH:mm") + " - " +
                        addSecondFormat(i?.booked_slots?.[i?.booked_slots?.length - 1]?.[1], 1, "HH:mm"),
                    booked_by: i?.booked_by,
                    payment_status: i?.payment_status,
                    slot_status: i?.booking_status,
                    data: i
                }
            })
            setAmenityBooking({ list, totalCount })
        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true, severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            })
        })
    }

    // calander view data
    const getAmenityBookingCalandarList = (amenity = selectedAmenity, date_range, company, type) => {
        const customRange = [date_range[0], `${moment(new Date(date_range[1])).format("YYYY-MM-DD")} 00:00:00`]
        let payload = {
            property_facility_id: amenity?.value,
            date_range: customRange,
            company: company?.value
        }
        let weeklyList = []
        let dailyList = []
        let monthlyList = []
        
        if (type === "Monthly") {
            NetworkCall(
                `${config.api_url}/amenities_booking_v2/get_monthly_booking`,
                NetWorkCallMethods.post,
                payload, null, true, false
            ).then((res) => {
                monthlyList.push(...res?.data?.data)
                setCalendarData({ weeklyList, dailyList, monthlyList: res?.data?.data })
            }).catch((err) => {
                alert.setSnack({
                    ...alert, open: true, severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                })
            })
        } else if (type === "Daily") {
            NetworkCall(
                `${config.api_url}/amenities_booking_v2/get_daily_booking`,
                NetWorkCallMethods.post,
                payload, null, true, false
            ).then((res) => {
                dailyList.push(...res?.data?.data)
                setCalendarData({ weeklyList, dailyList: res?.data?.data, monthlyList })
            }).catch((err) => {
                alert.setSnack({
                    ...alert, open: true, severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                })
            })
        } else if (type === "Weekly") {
            NetworkCall(
                `${config.api_url}/amenities_booking_v2/get_weekly_booking`,
                NetWorkCallMethods.post,
                payload, null, true, false
            ).then((res) => {

                weeklyList.push(...res?.data?.data)
                setCalendarData({ weeklyList, dailyList, monthlyList: res?.data?.data })
            }).catch((err) => {
                alert.setSnack({
                    ...alert, open: true, severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                })
            })
        }
    }

    // Function to change search text
    const handleSearchTextChange = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in amenity booking
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        
        if (dateRange.length > 0) {
            getAmenityBookingList(
                selectedAmenity, dateRange,
                selectedCompany, e, 0, limit
            )
            getAmenityBookingCalandarList(
                selectedAmenity, dateRange,
                selectedCompany, selected
            )
        }
    }

    // Function to handle pagination in table
    const handlePagination = (e) => {
        setPage(e)
        let offset = (e - 1) * limit
        
        if (dateRange.length > 0) {
            getAmenityBookingList(
                selectedAmenity, dateRange,
                selectedCompany, searchText, offset, limit
            )
            getAmenityBookingCalandarList(
                selectedAmenity, dateRange,
                selectedCompany, selected
            )
        }
    }

    // Function to handle page limit in table
    const handleChangeLimit = (e) => {
        setLimit(e)
        setPage(1)
        
        if (dateRange.length > 0) {
            getAmenityBookingList(
                selectedAmenity, dateRange,
                selectedCompany, searchText, 0, e
            )
            getAmenityBookingCalandarList(
                selectedAmenity, dateRange,
                selectedCompany, selected
            )
        }
    }

    const closeCheckAvailability = () => {
        setOpenCA(!openCA)
    }

    const render = () => {
        return (
            <Box>
                <Subheader
                    hideBackButton={true}
                    title={t("AmenitiesBooking")}
                    select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => handleCompanyChange(e)}
                    selectProperty
                    propertyOptions={propertyList}
                    propertyValue={selectedProperty}
                    onPropertyChange={(e) => handlePropertyChange(e)} />

                <Calendars
                    permission={permission}
                    openPopup={openPopup}
                    handleTableIcon={handleTableIcon}
                    amenityCategoryList={amenityCategoryList}
                    selectedAmenityCategory={selectedAmenityCategory}
                    handleAmenityCategoryChange={handleAmenityCategoryChange}
                    amenityList={amenityList}
                    selectedAmenity={selectedAmenity}
                    selectedAmenityView={selectedAmenityView}
                    handleAmenityChange={handleAmenityChange}
                    handleAmenityViewChange={handleAmenityViewChange}
                    searchText={searchText}
                    handleSearchTextChange={handleSearchTextChange}
                    amenityBooking={amenityBooking}
                    calendarData={calendarData}
                    page={page}
                    limit={limit}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    getDateRange={getDateRange}
                    handleEvent={handleEvent}
                    handleMonthEvent={handleMonthEvent}
                    setWeekdates={setWeekdates}
                    weekdates={weekdates}
                    selected={selected}
                    setSelected={setSelected}
                />

                {/* Check Availability */}
                <AlertDialog xl open={openCA}
                    onClose={closeCheckAvailability}
                    header={t("CheckAvailability")}
                    component={<CheckAvailability
                        getAmenityBookingList={getAmenityBookingList}
                        closeCheckAvailability={closeCheckAvailability}
                        selectedCompany={selectedCompany}
                        selectedProperty={selectedProperty}
                        selectedAmenityCategory={selectedAmenityCategory} />} />
                {/* Decline popup */}
                <AlertDialog md open={openDecline}
                    onClose={() => setOpenDecline(!openDecline)}
                    header={calendarEvent !== "" ? calendarEvent?.booking_id : amenityBookingDetail?.booking_id}
                    component={
                        <DeclinePopup
                            amenityBookingDetail={calendarEvent !== "" ? calendarEvent : amenityBookingDetail}
                            type="decline" closeDecline={() => setOpenDecline(!openDecline)}
                            updateAmenityBookingList={updateAmenityBookingList} />
                    } />

                {/* Daily calender for month calendar */}

                <AlertDialog md open={openDailyCalendar}
                    onClose={closeDailyCalendarPopup}
                    header={`Daily ${moment(openDailyCalendar?.selectedDate).format("DD-MM-YYYY")}`}
                    component={
                        <DailyCalendar progress="0" list={calendarData?.dailyList} handleEvent={handleEvent} />
                    } />

            </Box>
        )
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div>
}

export default withNamespaces("amenityBooking")(AmenitiesBooking)
