import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Regular, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(/images/mainbackgroundimage.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    backgroundColor: theme.palette.primary.main,
    position: "relative",
  },
  grid: {
    justifyContent: "center",
  },
  child: {
    margin: "5% 0% 0% 50%",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: "12px 0px 0px 12px",
    },
  },
  signupform: {
    padding: "0px 12px 0px 12px",
    backgroundColor: "white",
    boxShadow: "0px 8px 69px #0000001A",
    borderRadius: theme.palette.borderRadius,
    marginTop: "20%",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      marginTop: "6%",
    },
  },
  text: {
    fontSize: "24px",
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
    marginTop: "0",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    opacity: 1,
    padding: "8px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  img: {
    width: "auto",
    height: "21.9px",
  },
  verificationText: {
    fontSize: "14px",
    fontFamily: Regular,
    textAlign: "center",
    color: theme.typography.color.primary,
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.palette.borderRadius,
    padding: "20px",
  },
  poweredby: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    fontSize: "8px",
    color: theme.typography.color.tertiary,
  },
  pa: {
    color: theme.typography.color.secondary,
    fontFamily: SemiBold,
    fontSize: "10px",
  },
  companyName: {
    color: theme.typography.color.secondary,
    fontFamily: Bold,
    fontSize: "14px",
    marginLeft: "12px",
  },
  companySelect: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  comapnyImage: {
    width: 50,
    height: 50,
    borderRadius: 0,
  },
  selectCompany: {
    padding: "14px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    margin: "8px 0px",
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    width: "100%",
  },
  selectCompanyBorder: {
    padding: "14px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    margin: "8px 0px",
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: "pointer",
    width: "100%",
  },
  list: {
    overflow: "auto",
    padding: "0px 20px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  content: {
    padding: "22px 20px",
  },
  logoIcon: {
    position: "fixed",
    top: "36px",
    left: "36px",
  },
  filterimg: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    //marginTop: "4px",
    padding: "12px",
  },
  propertyDeatils: {
    color: "#091B29",
    fontSize: "16px",
    fontFamily: Bold,
    marginTop: "10px",
    marginLeft: "8px"
  },
  yearStyle: {
    color: "#4E5A6B",
    fontSize: "12px",
    fontFamily: Bold,
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
  },
  yearFontStyle: {
    color: "#98A0AC",
    fontSize: "12px",
    fontFamily: Bold,
    marginLeft: "10px",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
  },
  marginLeft: {
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
  },
  propertyDeatilsBox: {
    display: "flex",
    alignItems: "center",
    padding: "16px 0px",
    borderBottom: "1px solid #E4E8EE",
  },
  addressDeatils: {
    color: "#4E5A6B",
    fontSize: "14px",
    fontFamily: Regular,
    marginLeft: "7px",
  },
  addressDetailsBox: {
    padding: "12px",
    borderBottom: "1px solid #E4E8EE",
  },
  addressBoxFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  detailsContainer: {
    padding: "12px",
  },
  detailsText: {
    color: "#4E5A6B",
    fontSize: "14px",
    fontFamily: Regular,
    marginLeft: "7px",
  },
  count: {
    color: "#000",
    fontSize: "14px",
    fontFamily: Bold,
  },
  detailsFlexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px",
    borderBottom: "1px solid #E4E8EE",
  },
  parentDetailsFlexBox: {
    backgroundColor: "#f5f7fa",
    padding: "16px",
    marginTop: "15px",
  },
  graftParent: {
    border: "1px solid #E4E8EE",
    borderRadius: 4,
    padding: "10px",
    height: "100%",
  },
  dot: {
    borderRadius: "50%",
    width: "6px",
    height: "6px",
  },
  amenitieslist: {
    border: "4px",
    backgroundColor: "#F5F7FA",
    color: "#000",
    fontFamily: Bold,
    fontSize: "14px",
    padding: "10px",
  },
  aminitiesParent: {
    borderTop: "1px solid #E4E8EE",
    marginTop: "15px",
  },
  aminitiesDeatils: {
    color: "#091B29",
    fontSize: "16px",
    fontFamily: Bold,
    margin: "10px 0px",
  },
  revenueParent: {
    borderRadius: "4px",
    backgroundColor: "#F5F7FA",
    padding: "10px 15px",
    marginTop: "15px",
  },
  revenueBorder: {
    borderTop: "1px solid #E4E8EE",
    marginTop: "16px",
  },
  textCenter: {
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
  totalCount: {
    color: "#091B29",
    fontSize: "16px",
    fontFamily: Bold,
    marginLeft: "10px",
  },
  graphPadding: {
    padding: "20px 0px",
  },
  paddingTop: {
    paddingTop: "16px",
  },
  marginTop: {
    marginTop: "16px",
    borderTop: "1px solid #E4E8EE",
  },
  addressflexBox: {
    display: "flex",
    paddingBottom: "3px",
  },

}));
