import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Avatar, Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { MobileNumberInputComponent, ProofComponent, TextBox } from "../../../components";
import { useWindowDimensions } from "../../../utils";
import { componantsStyles } from "./styles";
export const AccountForm = ({ onClose = () => false, disableAcc = false, updateStateAcc = () => false, onSubmitAccount = () => false, account = {} }) => {
    const classes = componantsStyles()
    const size = useWindowDimensions()

    return (
        <div>
            <Box p={2} height={size?.height - 256} overflow="auto">

                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Typography className={classes.title} textAlign="center">Account Details</Typography>
                        <center>

                            {
                                account?.url ?
                                    <div className={classes.imgDiv}>
                                        <img src={URL.createObjectURL(account?.url?.[0])} alt="" style={{ objectFit: "cover", height: "94px", width: "94px", borderRadius: "50%" }} />
                                        <IconButton onClick={() => updateStateAcc("url", "")} className={classes.close} size="small">
                                            <CloseIcon style={{ fontSize: "18px", color: "white" }} />
                                        </IconButton>
                                    </div>
                                    :
                                    <IconButton className={classes.profileimg}>
                                        <PersonOutlineIcon className={classes.profileicon} />
                                        <label htmlFor="uplodebtn">
                                            <Avatar
                                                sx={{ width: 24, height: 24 }}
                                                className={classes.profileimgs}
                                                type="file"
                                            >
                                                <img
                                                    src="/images/craeateprofilequtation.svg"
                                                    alt="-"
                                                    className={classes.profileicons}
                                                />
                                            </Avatar>
                                        </label>
                                        <input
                                            type="file"
                                            name="img"
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            id="uplodebtn"
                                            onChange={(value) => {
                                                updateStateAcc("url", value.target.files)
                                            }

                                            }
                                        />
                                    </IconButton>
                            }
                        </center>
                    </Grid>
                    <Grid item xs={10}>
                        <Box height="20px" />
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <TextBox
                                    value={account?.name}
                                    onChange={(e) => updateStateAcc("name", e.target.value)}
                                    label="Account Name"
                                    placeholder="Enter Account Name"
                                    isError={account?.error?.name?.length > 0}
                                    errorMessage={account?.error?.name}
                                    isrequired
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <MobileNumberInputComponent
                                    label="Mobile Number"
                                    xs={4}
                                    value={account?.mobile}
                                    handleChange={(value) => updateStateAcc("mobile", value)}
                                    isError={account?.error?.mobile?.length > 0}
                                    errorMessage={account?.error?.mobile}
                                    isRequired
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextBox
                                    value={account?.email}
                                    onChange={(e) => updateStateAcc("email", e.target.value)}
                                    label="Email ID" placeholder="Enter Email ID"
                                    isError={account?.error?.email?.length > 0}
                                    errorMessage={account?.error?.email}
                                    isrequired
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {/*proof name */}
                        <ProofComponent update={(data) => updateStateAcc("proofFile", data)} />
                    </Grid>
                </Grid>
            </Box>
            <Box p={2} display="flex" justifyContent="space-between">
                <Button onClick={onClose} className={classes.btn} variant="outlined">Back</Button>
                <Button disabled={disableAcc} onClick={onSubmitAccount} className={classes.btn} variant="contained">Create Account</Button>
            </Box>
        </div>
    )
}