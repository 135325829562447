/* eslint-disable react-hooks/rules-of-hooks */
import { useApolloClient } from "@apollo/client";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Avatar, Box, Button, Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import "../../../App.css";
import AccountPerson from "../../../assets/accountPerson";
import EditIMG from "../../../assets/editimage";
import OwnerIcon from "../../../assets/owner";
import PhoneAccount from "../../../assets/phoneAccount";
import { AlertDialog, Editor, Label, MapWithFields, MobileNumberInputComponent, NewDatePicker, SearchFilter, SelectBox, TextBox } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import {
      GET_CONTACT_ACCOUNT_USER,
      GET_PARENT_UNIT_BY_PROPERTY_ID,
      GET_PARENT_UNIT_BY_FLOOR_ID,
      GET_UNIT_FORM_MASTER_NEW as queryOne,
      GET_UNIT_TYPE,
      UOM_MASTER
} from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import { activeOptions, AlertProps, enumName, getCustomBlockName, getCustomFloorName, ListingTypeOptions, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions, YesNoOption } from "../../../utils";
import { GET_ALL_BLOCK_OR_FLOOR_BY_PROPERTY_ID } from "../../createFloorNew/utils";
import { OwnerCard } from "../../propertcreation/createproperty/addOwnerCard";
import { useStylesCreation } from "./style";
import { Bold } from "../../../utils";
const CustomTypography = styled(Typography)(({ theme }) => ({
      fontSize: 12,
      fontFamily: Bold,
      color: theme.typography.color.secondary,
      marginBottom: theme.spacing(1)
}))
const CustomtoolTip = styled(({ className, ...props }) => (
      <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
            color: "white",
      },
      [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "white",
            padding: "12px",
            color: "#4E5A6B",
            border: "1px solid #E4E8EE"
      },
}));

export const UnitDetails = (props) => {
      const {
            mapLoad,
            showBlock,
            showFloor,
            propertyID,
            blockID,
            floorID,
            onChangeFloor,
            main
      } = props;
      const buildupAreades = "The area of an apartment that can be covered by a carpet or the net usable area is known as the carpet area. It is the distance between the inner walls. The carpet area would include the areas of the bedroom, living room, kitchen, bathrooms, balconies & staircases within the house/flat. It does not include the external and internal walls, terraces, common areas, lifts, corridors, utility ducts, etc"
      const superbuilup = "Super Built-up area is referred to as the ‘saleable area’ and homebuyers are usually charged based on this value. It is the sum of the exclusive built-up area, and common areas including the lift, corridors, staircase, clubhouse, and other amenities."

      const [fullScreenMap, setFullScreenMap] = React.useState(false)
      const classes = useStylesCreation();
      const client = useApolloClient()
      const size = useWindowDimensions()
      const [loading, setLoading] = React.useState(false)
      const alert = React.useContext(AlertContext);
      const [deleteUser, setdeleteUser] = React.useState(false)
      const [selectedID, setSelectedID] = React.useState(null)
      const [open, setOpen] = React.useState(false)
      const [searchdata, setSearchdata] = React.useState("")
      const [offset, setOffset] = React.useState(0)
      const [ownerDetails, setOwnerDetails] = React.useState([])
      const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
      // const [enumValue, setEnum] = React.useState({
      //       payment_period: [],
      //       furnish: [],
      //       orientation: [],
      //       pet_policy_type: [],
      //       purpose_type: [],
      //       area_metric_type: [],
      //       listing_type: [],
      //       unit_execution_status: [],
      // })

      const loadOptions = async (search = "", array, type) => {
            setLoading(type);
            let result, query, variable, offset, unitCategoryIDs = 0;
            let limit = 10

            if (search && !Boolean(array?.length)) {
                  offset = 0;
            }
            else {
                  offset = array?.length;
            }
            if (props?.data?.unitPurpose?.value === "Residential") {
                  if (props?.data?.unitCategory?.value === 10) {
                        unitCategoryIDs = [2]
                  } else {
                        unitCategoryIDs = [2, 10]
                  }
            } else if (props?.data?.unitPurpose?.value === "Commercial") {
                  if (props?.data?.unitCategory?.value === 10) {
                        unitCategoryIDs = [4]
                  } else {
                        unitCategoryIDs = [4, 10]
                  }
            }

            switch (type) {
                  case 'property_group_master':
                        query = queryOne(offset, 10, search, null, type).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.property_group_master],
                              hasMore: (array?.length + result?.property_group_master?.length) < result?.count[0]?.count
                        }
                  // case 'unit_type_master':
                  //       query = queryOne(offset, 10, search, null, type).loc.source.body;
                  //       result = await networkCallBack(query);
                  //       return {
                  //             options: [...result?.unit_type_master],
                  //             hasMore: (array?.length + result?.unit_type_master?.length) < result?.count[0]?.count
                  //       }
                  case 'unit_type_master':
                        if (!props?.data?.unitPurpose?.value?.length > 0) {
                              setLoading(null);
                              return { options: [] }
                        }
                        query = GET_UNIT_TYPE(offset, 10, search, props?.data?.unitPurpose?.value, props?.data?.company?.value).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.unit_type_master],
                              hasMore: (array?.length + result?.unit_type_master?.length) < result?.count[0]?.count
                        }
                  case 'parent_unit':
                        query = props?.data?.showAllUnits ?
                              GET_PARENT_UNIT_BY_PROPERTY_ID(
                                    propertyID, search, offset,
                                    limit, unitCategoryIDs,
                                    props?.data?.unitPurpose?.value).loc.source.body :
                              GET_PARENT_UNIT_BY_FLOOR_ID(
                                    props?.data?.floorID?.value,
                                    search, unitCategoryIDs,
                                    props?.data?.unitPurpose?.value,
                                    offset, limit).loc.source.body;

                        let options = []
                        if (props?.data?.showAllUnits) {
                              result = await networkCallBack(query)
                              options = result?.property[0].unitByID?.map((item) => {
                                    let _i = item;
                                    _i.label = item?.label + " - " + item?.unit_no;
                                    return _i;
                              });
                        } else {
                              result = await networkCallBack(query)
                              options = result?.floor[0].unit?.map((item) => {
                                    let _i = item;
                                    _i.label = item?.label + " - " + item?.unit_no;
                                    return _i;
                              });
                        }
                        return {
                              options: [...options],
                              hasMore: (array?.length + options?.length) < result?.count[0]?.count[0]?.count
                        }
                  case 'property':
                        query = GET_PARENT_UNIT_BY_PROPERTY_ID;
                        variable = { propertyID, search, offset, limit }
                        result = await networkCallBackG(query, variable);
                        // let options2 = result?.property[0].unitByID?.map((item) => {
                        //       let _i = item;
                        //       _i['value'] = item?.id;
                        //       _i['label'] = item?.name + " - " + item?.unit_no;
                        //       return _i;
                        // });
                        let options2 = result?.property[0].unitByID;
                        return {
                              options: [...options2],
                              hasMore: (array?.length + options2?.length) < result?.count[0]?.count[0]?.count
                        }
                  case 'block_details':
                        if (!propertyID) {
                              setLoading(null);
                              return { options: [] }
                        }
                        else {
                              query = GET_ALL_BLOCK_OR_FLOOR_BY_PROPERTY_ID(
                                    offset, 10, search, "property_id", "block", propertyID).loc.source.body;
                              result = await networkCallBack(query);
                              return {
                                    options: [...result?.block],
                                    hasMore: (array?.length + result?.block?.length) < result?.count[0]?.count
                              }
                        }
                  case 'floor_details':
                        if (props?.data?.blockID?.value || blockID || propertyID) {
                              let chooseBlockID = props?.data?.blockID?.value ? props?.data?.blockID?.value : blockID;
                              query = GET_ALL_BLOCK_OR_FLOOR_BY_PROPERTY_ID(
                                    offset, 10, search,
                                    chooseBlockID ? "block_id" : "property_id",
                                    "floor", chooseBlockID ?? propertyID).loc.source.body;
                              result = await networkCallBack(query);
                              return {
                                    options: [...result?.floor],
                                    hasMore: (array?.length + result?.floor?.length) < result?.count[0]?.count
                              }
                        }
                        else {
                              setLoading(null);
                              return { options: [] }
                        }
                  case 'uom_master':
                        query = UOM_MASTER(search, offset, limit).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.uom_master],
                              hasMore: (array?.length + result?.uom_master?.length) < result?.count[0]?.count
                        }
                  default:
                        return { options: [] }
            }
      }
      const networkCallBack = async (query, variable = {}) => {
            let payload = {
                  query,
                  variable: variable
            };

            const options = await NetworkCall(
                  `${config.graphql_url}`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        let main = response.data.data;
                        setLoading(null);
                        return main
                  })
                  .catch(() => {
                        setLoading(null);
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Some Thing Went Wrong",
                              vertical: AlertProps.vertical.top,
                              horizontal: AlertProps.horizontal.center,
                        });
                        return null
                  });

            return options
      }



      // Function for network callback for Async Select Options using Graphql
      const networkCallBackG = async (query, variables = {}) => {
            const options = await client.query({
                  query, fetchPolicy: "network-only", variables
            }).then((r) => {
                  let main = r?.data
                  setLoading(null)
                  return main
            }).catch((e) => {
                  setLoading(null)
                  alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error, msg: "Some Thing Went Wrong"
                  })
                  return null
            })
            return options
      }
      // const getEnum = async () => {
      //       const result = await enumSelect([
      //             enum_types.unit_payment_period, enum_types.furnishing_type,
      //             enum_types.orientation_type, enum_types.pet_policy_type,
      //             enum_types.unit_category, enum_types.purpose_global_type,
      //             enum_types.revenue_type, enum_types.area_metric_type,
      //             enum_types.listing_type, enum_types.unit_execution_status
      //       ])

      //       const unit_category_master = await client.query({
      //             query: GET_UNIT_CATEGORY_MASTER,
      //             variables: {}
      //       }).then(res => res?.data?.unit_category_master).catch(err => console.log(err));

      //       let purpose_global_type = [], residential_unit_category = [];
      //       let commercial_unit_category = [], facilities_unit_category = [];

      //       // // Include Residential Unit, Room Space, Bed Space, Plot, Land, Parking Unit, Storage Space
      //       // let incRUC = [2, 10, 6, 5, 1, 8, 7]

      //       // // Include Commercial Unit, Work Space, Room Space, Bed Space, Plot, Land, Parking Unit, Marketing Space, Storage Space
      //       // let incCUC = [4, 9, 10, 6, 5, 1, 8, 3, 7, 11]

      //       // // Include Facility Space
      //       // let incFUC = [11]

      //       for (let i = 0; i < result.purpose_global_type.length; i++) {
      //             if (!([enumName.mixed].includes(result.purpose_global_type[i].value))) {
      //                   purpose_global_type = [...purpose_global_type, result.purpose_global_type[i]]
      //             }
      //       }

      //       for (let i = 0; i < unit_category_master.length; i++) {
      //             // if ((incRUC.includes(unit_category_master[i].value))) {
      //                   residential_unit_category = [
      //                         ...residential_unit_category,
      //                         {
      //                               ...unit_category_master[i],
      //                               ...unitCategoryMaster[unit_category_master[i].value]
      //                         }
      //                   ]
      //             // }
      //             // if ((incCUC.includes(unit_category_master[i].value))) {
      //                   commercial_unit_category = [
      //                         ...commercial_unit_category,
      //                         {
      //                               ...unit_category_master[i],
      //                               ...unitCategoryMaster[unit_category_master[i].value]
      //                         }
      //                   ]
      //             // }
      //             // if ((incFUC.includes(unit_category_master[i].value))) {
      //                   facilities_unit_category = [
      //                         ...facilities_unit_category,
      //                         {
      //                               ...unit_category_master[i],
      //                               ...unitCategoryMaster[unit_category_master[i].value]
      //                         }
      //                   ]
      //             // }
      //       }

      //       for (let i = 0; i < purpose_global_type.length; i++) {
      //             purpose_global_type[i].revenueTypeList = result.revenue_type
      //             purpose_global_type[i].value === enumName.residential &&
      //                   (purpose_global_type[i].unitCategoryList = residential_unit_category)
      //             purpose_global_type[i].value === enumName.commercial &&
      //                   (purpose_global_type[i].unitCategoryList = commercial_unit_category)
      //             purpose_global_type[i].value === enumName.facilities &&
      //                   (purpose_global_type[i].unitCategoryList = facilities_unit_category)
      //       }

      //       setEnum({
      //             payment_period: result?.payment_value_type,
      //             furnish: result?.furnishing_type,
      //             orientation: result?.orientation_type,
      //             pet_policy_type: result?.pet_policy_type,
      //             purpose_type: purpose_global_type,
      //             area_metric_type: result?.area_metric_type,
      //             listing_type: result?.listing_type,
      //             unit_execution_status: result?.unit_execution_status
      //       })
      // }

      // React.useEffect(() => {
      //       getEnum()
      //       // eslint-disable-next-line
      // }, [])

      React.useEffect(() => {
            document.addEventListener("fullscreenchange", existFullscreen)
            return () => {
                  document.removeEventListener("fullscreenchange", existFullscreen)

            }

      }, [])
      //exist full screen
      const existFullscreen = () => {
            if (document.fullscreenElement === null) {
                  setFullScreenMap(false)
            }

      }
      //open full screen
      const fullScreenControl = () => {
            setFullScreenMap(true)
            document.getElementsByClassName('map-wrapper')[0]?.requestFullscreen()

      }
      // selected users
      const selectUser = (x) => {
            setSelectedID(x)
      }

      // close Drawer
      const closeDrawer = (v, k) => {
            if (k === 'delete') {
                  setdeleteUser(true)
                  setOpen(!open)
            }
            else if (k === 'add') {
                  setdeleteUser(false)
                  setOpen(!open)
                  props?.updateState('ownerAccount', {})
            }
            else if (k === 'close') {
                  props?.updateState('ownerAccount', {})
                  setSelectedID(null)
                  setOpen(false)
            }
            else if (k === 'deleteclose') {
                  setOpen(!open)
            }
            else {
                  props?.updateState('ownerAccount', selectedID)
                  setOpen(!open)
            }

      }
      // get user profiles in pop up
      const getOwnerDetails = (offset, search, k) => {
            client.query({
                  query: GET_CONTACT_ACCOUNT_USER,
                  fetchPolicy: 'network-only',
                  variables: {
                        company_id: props?.data?.company?.value,
                        offset: offset,
                        limit: 10,
                        search: search ?? "",
                        clientID: clientId,
                        relationship: enumName.propertyOwner
                  }
            }).then((res) => {
                  if (k === 'search') {
                        setOwnerDetails(res?.data?.contact_account)
                  }
                  else {
                        setOwnerDetails(ownerDetails?.concat(res?.data?.contact_account));
                  }
            }).catch((err) => {

            })
      }


      // infinity scroll
      const fetchMoreData = () => {
            setOffset(offset + 10);
            getOwnerDetails(offset + 10, "", "normal");
      };
      // const search user data
      const handleSearch = (v) => {
            setSearchdata(v)
            getOwnerDetails(0, v, "search")
      }
      //open modal
      const openAccountModal = () => {
            setOpen(true)
            getOwnerDetails(0, "", "search")

      }
      return (
            <div>
                  <Grid container spacing={3}>
                        {
                              (showBlock || showFloor) &&
                              <Grid item xs={12}>
                                    <Box className={classes.imagebox1}>
                                          <Grid container spacing={3}>
                                                {
                                                      showBlock &&
                                                      <Grid item xs={3}>
                                                            <SelectBox
                                                                  isRequired
                                                                  isReadOnly={blockID || floorID || main?.isEdit}
                                                                  label={getCustomBlockName(props?.data?.property_hierarchy)}
                                                                  placeholder={"Select " + getCustomBlockName(props?.data?.property_hierarchy)}
                                                                  loading={loading === "block_details"}
                                                                  isPaginate
                                                                  debounceTimeout={800}
                                                                  loadOptions={(search, array) => loadOptions(search, array, 'block_details')}
                                                                  // options={companyIDs ?? []}
                                                                  value={props?.data?.blockID ?? ""}
                                                                  onChange={(value) => {
                                                                        props?.updateState("blockID", value);
                                                                  }}
                                                                  isError={props?.data?.error?.blockID?.length > 0}
                                                                  errorMessage={props?.data?.error?.blockID}
                                                            />
                                                      </Grid>
                                                }
                                                {
                                                      showFloor &&
                                                      <Grid item xs={3}>
                                                            <SelectBox
                                                                  key={showBlock ? props?.data?.blockID?.value : ""}
                                                                  isRequired
                                                                  isReadOnly={floorID || main?.isEdit}
                                                                  label={getCustomFloorName(props?.data?.property_hierarchy)}
                                                                  placeholder={"Select " + getCustomFloorName(props?.data?.property_hierarchy)}
                                                                  loading={loading === "floor_details"}
                                                                  isPaginate
                                                                  debounceTimeout={800}
                                                                  loadOptions={(search, array) => loadOptions(search, array, 'floor_details')}
                                                                  // options={companyIDs ?? []}
                                                                  value={props?.data?.floorID ?? ""}
                                                                  onChange={(value) => {
                                                                        onChangeFloor(value);
                                                                  }}
                                                                  isError={props?.data?.error?.floorID?.length > 0}
                                                                  errorMessage={props?.data?.error?.floorID}
                                                            />
                                                      </Grid>
                                                }
                                          </Grid>
                                    </Box>
                              </Grid>
                        }
                        <Grid item xs={12} md={2} lg={2}>
                              <Box className={classes.imagebox}>
                                    <Typography className={classes.title}>
                                          UNIT IMAGE
                                    </Typography>
                                    <Avatar src={props?.data?.image?.length > 0 ? props?.data?.image : "/images/citynew.svg"} style={{ margin: '0 auto' }} className={props?.data?.image?.length > 0 ? classes.avatar : classes.avatar1} />
                                    <div>
                                          <label> <Box className={classes.button} variant='contained' style={{ marginTop: '10px' }}>    Upload image</Box> <input type='file' style={{ display: 'none' }} accept="image/*" onChange={(e) => props?.updateimg(e?.target?.files?.[0])}
                                                onClick={(event) => {
                                                      event.target.value = null
                                                }} /> </label>
                                    </div>
                                    {props?.data?.image?.length > 0 ? <Typography className={classes.removeimg} onClick={props?.removeimg}>Remove Image</Typography> : props?.data?.error?.image?.length > 0 ? (
                                          <span style={{ fontSize: "12px", color: "red" }}>
                                                Unit Image is required
                                          </span>
                                    ) : <span style={{ fontSize: "12px" }}>
                                          <Box height={"18px"} />
                                    </span>}

                              </Box>
                        </Grid>
                        <Grid item xs={12} md={8} lg={8}>
                              <Box className={classes.imagebox1} height={"292px"}>
                                    <Typography className={classes.title}>
                                          UNIT DETAILS
                                    </Typography>

                                    <Grid container rowSpacing={1} columnSpacing={3}>
                                          <Grid item xs={12} md={3} lg={3}>
                                                <TextBox
                                                      isrequired
                                                      isReadonly
                                                      label="Company"
                                                      placeholder="Select Company"
                                                      value={props?.data?.company?.label ?? ""}
                                                      isError={props?.data?.error?.company?.length > 0}
                                                      errorMessage={props?.data?.error?.company} />
                                          </Grid>
                                          <Grid item xs={12} md={3} lg={3}>
                                                <TextBox
                                                      isrequired
                                                      label="Name"
                                                      placeholder="Enter Name"
                                                      value={props?.data?.unitName ?? ""}
                                                      onChange={(e) => {
                                                            props?.updateState("unitName", e.target.value);
                                                      }}
                                                      isError={props?.data?.error?.unitName?.length > 0}
                                                      errorMessage={props?.data?.error?.unitName} />
                                          </Grid>
                                          <Grid item xs={12} md={3} lg={3}>
                                                <SelectBox
                                                      isRequired
                                                      label="Execution Status"
                                                      placeholder="Select Execution Status"
                                                      options={props?.options?.unit_execution_status}
                                                      value={props?.data?.execution_status}
                                                      onChange={(value) => {
                                                            props?.updateState("execution_status", value);
                                                      }}
                                                      isError={props?.data?.error?.execution_status?.length > 0}
                                                      errorMessage={props?.data?.error?.execution_status} />
                                          </Grid>
                                          <Grid item xs={12} md={3} lg={3}>
                                                <Grid item xs={12}>
                                                      <Label label={"Status"} isRequired />
                                                </Grid>
                                                <Grid container rowGap={"2px"} columnGap={"2px"}>
                                                      {activeOptions.map((value) => {
                                                            return <Button className={
                                                                  value?.value === props?.data?.status?.value ?
                                                                        classes.buttonSelected :
                                                                        classes.buttonUnSelected}
                                                                  onClick={() => { props?.updateState("status", value) }}>
                                                                  <Typography className={
                                                                        value?.value === props?.data?.status?.value ?
                                                                              classes.buttonTextSelected :
                                                                              classes.buttonTextUnSelected}>
                                                                        {value?.label}</Typography>
                                                            </Button>
                                                      })}
                                                </Grid>
                                                {props?.data?.error?.status?.length > 0 && (
                                                      <Grid item xs={12}>
                                                            <Typography variant={"caption"} color={"error"}>
                                                                  {props?.data?.error?.status}
                                                            </Typography>
                                                      </Grid>
                                                )}
                                          </Grid>
                                    </Grid>
                                    <Grid container style={{ marginTop: '8px' }}>
                                          <Grid item xs={12}>
                                                <Editor
                                                      value={props?.data?.description}
                                                      label="Description"
                                                      handleChange={(e) => {
                                                            props?.updateState("description", e);
                                                      }}
                                                      id={props?.id}
                                                      height={"80px"} />
                                          </Grid>
                                    </Grid>
                              </Box>
                        </Grid>
                        {props?.data?.ownerAccount && Object.keys(props?.data?.ownerAccount).length > 0 ?
                              <Grid item xs={12} md={2} lg={2}>
                                    <Box className={classes.imagebox} textAlign={"left !important"}>
                                          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                                <Typography className={classes.title}>
                                                      OWNER DETAILS
                                                </Typography>
                                                <Box  >
                                                      <IconButton className={classes.icon}
                                                            onClick={openAccountModal}
                                                      >
                                                            <EditIMG color={"#5078E1"} />
                                                      </IconButton>
                                                </Box>
                                          </Box>
                                          <Box >
                                                {props?.data?.ownerAccount?.contact?.image_url ?
                                                      <Avatar className={classes.avatar} src={props?.data?.ownerAccount?.contact?.image_url} sx={{ margin: "auto" }} /> :
                                                      <Avatar className={classes.avatar} sx={{ margin: "auto" }} />}
                                          </Box>
                                          <Box>
                                                <Typography className={classes.ownerTitle}>
                                                      {props?.data?.ownerAccount?.contact?.first_name}
                                                </Typography>
                                          </Box>
                                          <Box display={"flex"} alignItems={"center"} marginTop={"10px"}>
                                                <AccountPerson />
                                                <Typography className={classes.ownerSubtitle}>
                                                      &nbsp;{props?.data?.ownerAccount?.contact?.email_id}
                                                </Typography>
                                          </Box>
                                          <Box display={"flex"} alignItems={"center"} marginTop={"10px"}>
                                                <PhoneAccount />
                                                <Typography className={classes.ownerSubtitle}>
                                                      &nbsp;
                                                      {props?.data?.ownerAccount?.contact?.mobile_no_country_code ? props?.data?.ownerAccount?.contact?.mobile_no_country_code + " - " : "- "}  {props?.data?.ownerAccount?.contact?.mobile_no ? props?.data?.ownerAccount?.contact?.mobile_no : "- "}

                                                </Typography>
                                          </Box>


                                    </Box>
                              </Grid> :
                              <Grid item xs={12} md={2} lg={2}>
                                    <Box className={classes.imagebox}>
                                          <Typography className={classes.title}>
                                                Select  Owner
                                          </Typography>
                                          <Avatar className={classes.avatar2}>
                                                <OwnerIcon />
                                          </Avatar>
                                          <Box className={classes.button2}
                                                variant='outlined'
                                                style={{ bottom: '10px' }}
                                                onClick={openAccountModal}
                                          >    Add Owner
                                          </Box>
                                          {props?.data?.error?.ownerAccount?.length > 0 &&
                                                <span style={{ fontSize: "12px", color: "red" }}>
                                                      Owner is required
                                                </span>
                                          }

                                    </Box>
                              </Grid>}
                        {/* add member dialog */}
                        <AlertDialog
                              isNormal
                              header={deleteUser === true ? 'Delete Owner' : 'Add Owner'}
                              onClose={() => closeDrawer({}, deleteUser === true ? 'deleteclose' : 'close')}
                              open={open}
                              component={
                                    <>

                                          <div style={{
                                                textAlign: "center",
                                                padding: "24px",
                                          }}>


                                                <div id={'infinity'}>
                                                      {/* search box */}

                                                      {
                                                            deleteUser === false &&
                                                            <div style={{ marginBottom: '24px' }}>
                                                                  <SearchFilter
                                                                        value={searchdata}
                                                                        handleChange={(value) => handleSearch(value)}
                                                                        placeholder="Search Owner Details"
                                                                  />
                                                            </div>
                                                      }


                                                      <InfiniteScroll
                                                            dataLength={ownerDetails?.length ?? null}
                                                            next={fetchMoreData}
                                                            hasMore={true}
                                                            height={size?.height - 500}
                                                      >
                                                            {
                                                                  <OwnerCard data={ownerDetails} selectUser={selectUser} select={selectedID} />
                                                            }

                                                      </InfiniteScroll>



                                                </div>
                                                {/* add member button */}
                                                {
                                                      deleteUser === false && <Button variant='contained' fullWidth className={classes.buttoncard} onClick={closeDrawer}> Add Owner</Button>
                                                }
                                          </div>
                                    </>
                              }
                        />
                  </Grid>

                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              <Grid item xs={12} md={3} lg={2}>
                                    <TextBox
                                          isrequired
                                          isReadonly
                                          label="Property Type"
                                          placeholder="Select Property Type"
                                          value={props?.data?.propertyType?.label ?? ""}
                                          isError={props?.data?.error?.propertyType?.length > 0}
                                          errorMessage={props?.data?.error?.propertyType} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label="Purpose"
                                          placeholder="Select Purpose"
                                          options={props?.options?.purpose_global_type ?? []}
                                          value={props?.data?.unitPurpose ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("unitPurpose", value);
                                          }}
                                          isError={props?.data?.error?.unitPurpose?.length > 0}
                                          errorMessage={props?.data?.error?.unitPurpose} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label="Revenue Type"
                                          placeholder="Select Type"
                                          options={props?.options?.revenue_type ?? []}
                                          value={props?.data?.revenueType ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("revenueType", value);
                                          }}
                                          isError={props?.data?.error?.revenueType?.length > 0}
                                          errorMessage={props?.data?.error?.revenueType} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label="Payment Period"
                                          placeholder="Select Payment Period"
                                          options={props?.options?.payment_period}
                                          value={props?.data?.paymentPeriod ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("paymentPeriod", value);
                                          }}
                                          isError={props?.data?.error?.paymentPeriod?.length > 0}
                                          errorMessage={props?.data?.error?.paymentPeriod} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label="Category"
                                          placeholder="Select Category"
                                          options={props?.options?.unit_category_master ?? []}
                                          value={props?.data?.unitCategory ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("unitCategory", value);
                                          }}
                                          isError={props?.data?.error?.unitCategory?.length > 0}
                                          errorMessage={props?.data?.error?.unitCategory} />
                              </Grid>
                              {props?.data?.unitCategory?.field_permission?.is_parent_unit_id &&
                                    <>
                                          <Grid item xs={12} md={3} lg={2}>
                                                <SelectBox
                                                      key={props?.data?.showAllUnits || props?.data?.floorID?.value}
                                                      isRequired
                                                      isReadOnly={props?.data?.showAllUnits ? false :
                                                            !(props?.data?.floorID?.value)}
                                                      label="Parent ID"
                                                      placeholder="Select ID"
                                                      value={props?.data?.parentUnitID}
                                                      onChange={(value) => props?.updateState("parentUnitID", value)}
                                                      loading={loading === "parent_unit"}
                                                      isPaginate
                                                      debounceTimeout={800}
                                                      loadOptions={(search, array) => loadOptions(search, array, 'parent_unit')}
                                                      isError={props?.data?.error?.parentUnitID?.length > 0}
                                                      errorMessage={props?.data?.error?.parentUnitID} />
                                          </Grid>
                                          <Grid item xs={12} md={3} lg={2}>
                                                <Grid item xs={12}>
                                                      <Label label={"Show All Units"} />
                                                </Grid>
                                                <Grid container height={"45px"} alignItems={"center"}>
                                                      <Avatar
                                                            className={
                                                                  props?.data?.showAllUnits
                                                                        ? classes.completedCheckboxStyle
                                                                        : classes.checkboxStyle
                                                            }
                                                            variant="rounded"
                                                            onClick={() => {
                                                                  props?.updateState(
                                                                        "showAllUnits",
                                                                        !props?.data?.showAllUnits
                                                                  );
                                                            }}
                                                      >
                                                            <DoneOutlinedIcon className={classes.checkboxIconStyle} />
                                                      </Avatar>
                                                </Grid>
                                          </Grid>
                                    </>}
                              <Grid item xs={12} md={3} lg={2}>
                                    <NewDatePicker
                                          label="Year Built"
                                          placeholder="Year Built"
                                          value={props?.data?.yearBuilt ?? ""}
                                          isRequired
                                          onChange={(value) => props?.updateState("yearBuilt", value)}
                                          maxDate={new Date()}
                                          isError={props?.data?.error?.yearBuilt?.length > 0}
                                          errorMessage={props?.data?.error?.yearBuilt}
                                          newDate />
                              </Grid>
                              {props?.data?.revenueType?.value === enumName.sale &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <NewDatePicker
                                                label="Handover Date"
                                                placeholder="Handover Date"
                                                value={props?.data?.handoverDate ?? ""}
                                                isRequired
                                                onChange={(value) => props?.updateState("handoverDate", value)}
                                                minDate={props?.data?.yearBuilt}
                                                isError={props?.data?.error?.handoverDate?.length > 0}
                                                errorMessage={props?.data?.error?.handoverDate} />
                                    </Grid>}
                        </Grid>
                  </Box>
                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label="Unit Type"
                                          placeholder="Select Type"
                                          value={props?.data?.unitType}
                                          onChange={(value) => {
                                                props?.updateState("unitType", value);
                                          }}
                                          loading={loading === "unit_type_master"}
                                          isPaginate
                                          key={JSON.stringify(props?.data?.unitPurpose?.value)}
                                          debounceTimeout={800}
                                          loadOptions={(search, array) => loadOptions(search, array, 'unit_type_master')}
                                          isError={props?.data?.error?.unitType?.length > 0}
                                          errorMessage={props?.data?.error?.unitType} />
                              </Grid>
                              {props?.data?.unitCategory?.field_permission?.is_total_rooms &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                isrequired
                                                label="Number Of Room"
                                                placeholder="Enter Count"
                                                value={props?.data?.numberOfRoom ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("numberOfRoom", e.target.value);
                                                }}
                                                isError={props?.data?.error?.numberOfRoom?.length > 0}
                                                errorMessage={props?.data?.error?.numberOfRoom} />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_total_baths &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                isrequired
                                                label="Number Of Baths"
                                                placeholder="Enter Count"
                                                value={props?.data?.numberOfBaths ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("numberOfBaths", e.target.value);
                                                }}
                                                isError={props?.data?.error?.numberOfBaths?.length > 0}
                                                errorMessage={props?.data?.error?.numberOfBaths} />
                                    </Grid>}
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label="Unit of Measure"
                                          placeholder="Select Unit of Measure"
                                          value={props?.data?.measurementUnit}
                                          onChange={(value) => {
                                                props?.updateState("measurementUnit", value);
                                          }}
                                          loading={loading === "uom_master"}
                                          isPaginate
                                          debounceTimeout={800}
                                          loadOptions={(search, array) => loadOptions(search, array, 'uom_master')}
                                          isError={props?.data?.error?.measurementUnit?.length > 0}
                                          errorMessage={props?.data?.error?.measurementUnit} />
                              </Grid>
                              {props?.data?.unitCategory?.field_permission?.is_carpet_area &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <CustomtoolTip title={`${buildupAreades}`}>
                                                <InfoOutlinedIcon sx={{ fontSize: "16px", float: "right", color: "#4E5A6B", marginLeft: "4px" }} />
                                          </CustomtoolTip>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                isrequired
                                                label="Carpet Area"
                                                placeholder="Enter Carpet Area"
                                                value={props?.data?.carpetArea ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("carpetArea", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end">
                                                      <Typography className={classes.fontSize}>
                                                            {props?.data?.measurementUnit?.label}
                                                      </Typography>
                                                </InputAdornment>}
                                                isError={props?.data?.error?.carpetArea?.length > 0}
                                                errorMessage={props?.data?.error?.carpetArea} />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_build_up_area &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                isrequired
                                                label="Buildup Area"
                                                placeholder="Enter Buildup Area"
                                                value={props?.data?.buildupArea ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("buildupArea", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end">
                                                      <Typography className={classes.fontSize}>
                                                            {props?.data?.measurementUnit?.label}
                                                      </Typography>
                                                </InputAdornment>}
                                                isError={props?.data?.error?.buildupArea?.length > 0}
                                                errorMessage={props?.data?.error?.buildupArea} />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_total_area &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <CustomtoolTip title={`${superbuilup}`}>
                                                <InfoOutlinedIcon sx={{ fontSize: "16px", float: "right", color: "#4E5A6B", marginLeft: "4px" }} />
                                          </CustomtoolTip>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                isrequired
                                                label="Super Built-Up Area"
                                                placeholder="Enter Super Built-Up Area"
                                                value={props?.data?.totalArea ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("totalArea", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end">
                                                      <Typography className={classes.fontSize}>
                                                            {props?.data?.measurementUnit?.label}
                                                      </Typography>
                                                </InputAdornment>}
                                                isError={props?.data?.error?.totalArea?.length > 0}
                                                errorMessage={props?.data?.error?.totalArea} />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_balconies &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                isrequired
                                                label="Number Of Balcony"
                                                placeholder="Enter Count"
                                                value={props?.data?.noOfBalcony ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("noOfBalcony", e.target.value);
                                                }}
                                                isError={props?.data?.error?.noOfBalcony?.length > 0}
                                                errorMessage={props?.data?.error?.noOfBalcony} />
                                    </Grid>}
                              {(props?.data?.unitCategory?.field_permission?.is_balcony_area && props?.data?.noOfBalcony > 0) &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                isrequired
                                                label="Balcony Area"
                                                placeholder="Enter Area"
                                                value={props?.data?.balconyArea ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("balconyArea", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end">
                                                      <Typography className={classes.fontSize}>
                                                            {props?.data?.measurementUnit?.label}
                                                      </Typography>
                                                </InputAdornment>}
                                                isError={props?.data?.error?.balconyArea?.length > 0}
                                                errorMessage={props?.data?.error?.balconyArea} />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_terrace_area &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                isrequired
                                                label="Terrace Area"
                                                placeholder="Enter Terrace Area"
                                                value={props?.data?.terraceArea ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("terraceArea", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end">
                                                      <Typography className={classes.fontSize}>
                                                            {props?.data?.measurementUnit?.label}
                                                      </Typography>
                                                </InputAdornment>}
                                                isError={props?.data?.error?.terraceArea?.length > 0}
                                                errorMessage={props?.data?.error?.terraceArea} />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_furnishing &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <SelectBox
                                                isRequired
                                                label="Furnishing Type"
                                                placeholder="Select Type"
                                                options={props?.options?.furnish}
                                                value={props?.data?.unitFurnishingType ?? ""}
                                                onChange={(value) => {
                                                      props?.updateState("unitFurnishingType", value);
                                                }}
                                                isError={props?.data?.error?.unitFurnishingType?.length > 0}
                                                errorMessage={props?.data?.error?.unitFurnishingType} />
                                    </Grid>}
                              {props?.data?.unitCategory?.field_permission?.is_orientation &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <SelectBox
                                                label="Orientation"
                                                placeholder="Select Orientation"
                                                options={props?.options?.orientation}
                                                value={props?.data?.orientation ?? ""}
                                                onChange={(value) => {
                                                      props?.updateState("orientation", value);
                                                }}
                                                isError={props?.data?.error?.orientation?.length > 0}
                                                errorMessage={props?.data?.error?.orientation} />
                                    </Grid>}
                              {(props?.data?.unitPurpose?.value === enumName?.residential &&
                                    props?.data?.unitCategory?.field_permission?.is_pet_policy) &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <SelectBox
                                                isRequired
                                                label="Pet Policy"
                                                placeholder="Select Pet Policy"
                                                options={props?.options?.pet_policy_type}
                                                value={props?.data?.pet_policy}
                                                onChange={(value) => {
                                                      props?.updateState("pet_policy", value);
                                                }}
                                          />
                                    </Grid>}
                              {/* <Grid item xs={12} md={3} lg={2}>
                                    <Grid item xs={12}>
                                          <Label label={"Asset Functional Location"} isRequired />
                                    </Grid>
                                    <Grid container rowGap={"2px"} columnGap={"2px"}>
                                          {YesNoOption.map((value) => {
                                                return <Button className={
                                                      value?.value === props?.data?.assetFunc?.value ?
                                                            classes.buttonSelected :
                                                            classes.buttonUnSelected}
                                                      onClick={() => { props?.updateState("assetFunc", value) }}>
                                                      <Typography className={
                                                            value?.value === props?.data?.assetFunc?.value ?
                                                                  classes.buttonTextSelected :
                                                                  classes.buttonTextUnSelected}>
                                                            {value?.label}</Typography>
                                                </Button>
                                          })}
                                    </Grid>
                                    {props?.data?.error?.assetFunc?.length > 0 && (
                                          <Grid item xs={12}>
                                                <Typography variant={"caption"} color={"error"}>
                                                      {props?.data?.error?.assetFunc}
                                                </Typography>
                                          </Grid>
                                    )}
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Grid item xs={12}>
                                          <Label label={"Asset Property"} isRequired />
                                    </Grid>
                                    <Grid container rowGap={"2px"} columnGap={"2px"}>
                                          {YesNoOption.map((value) => {
                                                return <Button className={
                                                      value?.value === props?.data?.assetProperty?.value ?
                                                            classes.buttonSelected :
                                                            classes.buttonUnSelected}
                                                      onClick={() => { props?.updateState("assetProperty", value) }}>
                                                      <Typography className={
                                                            value?.value === props?.data?.assetProperty?.value ?
                                                                  classes.buttonTextSelected :
                                                                  classes.buttonTextUnSelected}>
                                                            {value?.label}</Typography>
                                                </Button>
                                          })}
                                    </Grid>
                                    {props?.data?.error?.assetProperty?.length > 0 && (
                                          <Grid item xs={12}>
                                                <Typography variant={"caption"} color={"error"}>
                                                      {props?.data?.error?.assetProperty}
                                                </Typography>
                                          </Grid>
                                    )}
                              </Grid> */}
                              {props?.data?.revenueType?.value === enumName.lease &&
                                    <Grid item xs={12} md={3} lg={2}>
                                          <Grid item xs={12}>
                                                <Label label={"Short Term Rental"} isRequired />
                                          </Grid>
                                          <Grid container rowGap={"2px"} columnGap={"2px"}>
                                                {YesNoOption.map((value) => {
                                                      return <Button className={
                                                            value?.value === props?.data?.shortTermRental?.value ?
                                                                  classes.buttonSelected :
                                                                  classes.buttonUnSelected}
                                                            onClick={() => { props?.updateState("shortTermRental", value) }}>
                                                            <Typography className={
                                                                  value?.value === props?.data?.shortTermRental?.value ?
                                                                        classes.buttonTextSelected :
                                                                        classes.buttonTextUnSelected}>
                                                                  {value?.label}</Typography>
                                                      </Button>
                                                })}
                                          </Grid>
                                          {props?.data?.error?.shortTermRental?.length > 0 && (
                                                <Grid item xs={12}>
                                                      <Typography variant={"caption"} color={"error"}>
                                                            {props?.data?.error?.shortTermRental}
                                                      </Typography>
                                                </Grid>
                                          )}
                                    </Grid>}
                              <Grid item xs={12} md={3} lg={2}>
                                    <Grid item xs={12}>
                                          <Label label={"Under Promotion"} isRequired />
                                    </Grid>
                                    <Grid container rowGap={"2px"} columnGap={"2px"}>
                                          {YesNoOption.map((value) => {
                                                return <Button className={
                                                      value?.value === props?.data?.underPromotion?.value ?
                                                            classes.buttonSelected :
                                                            classes.buttonUnSelected}
                                                      onClick={() => { props?.updateState("underPromotion", value) }}>
                                                      <Typography className={
                                                            value?.value === props?.data?.underPromotion?.value ?
                                                                  classes.buttonTextSelected :
                                                                  classes.buttonTextUnSelected}>
                                                            {value?.label}</Typography>
                                                </Button>
                                          })}
                                    </Grid>
                                    {props?.data?.error?.underPromotion?.length > 0 && (
                                          <Grid item xs={12}>
                                                <Typography variant={"caption"} color={"error"}>
                                                      {props?.data?.error?.underPromotion}
                                                </Typography>
                                          </Grid>
                                    )}
                              </Grid>
                              {props?.data?.unitCategory?.field_permission?.is_listing_status &&
                                    <Grid item xs={12} md={4} lg={4}>
                                          <Grid item xs={12}>
                                                <Label label={"Public Listing"} isRequired />
                                          </Grid>
                                          <Grid container rowGap={"2px"} columnGap={"2px"}>
                                                {ListingTypeOptions?.map((value) => {
                                                      return <Button className={
                                                            value?.value === props?.data?.publicListing?.value ?
                                                                  classes.buttonSelected :
                                                                  classes.buttonUnSelected}
                                                            onClick={() => { props?.updateState("publicListing", value) }}>
                                                            <Typography className={
                                                                  value?.value === props?.data?.publicListing?.value ?
                                                                        classes.buttonTextSelected :
                                                                        classes.buttonTextUnSelected}>
                                                                  {value?.label}</Typography>
                                                      </Button>
                                                })}
                                          </Grid>
                                          {props?.data?.error?.publicListing?.length > 0 && (
                                                <Grid item xs={12}>
                                                      <Typography variant={"caption"} color={"error"}>
                                                            {props?.data?.error?.publicListing}
                                                      </Typography>
                                                </Grid>
                                          )}
                                    </Grid>}
                        </Grid>
                  </Box>


                  <div className='map-wrapper'>
                        <Box className={classes.imagebox1} style={{ marginTop: '24px', position: 'relative' }}>
                              <CustomTypography>Address</CustomTypography>
                              {
                                    props?.load &&
                                    <MapWithFields
                                          fullScreenMap={fullScreenMap}
                                          fullScreenControl={fullScreenControl}
                                          mapOptions={{
                                                isInput: true,
                                                center: {
                                                      lat: props?.data?.latitude,
                                                      lng: props?.data?.longitude
                                                },
                                                lat: props?.data?.latitude,
                                                lng: props?.data?.longitude,
                                                mapLoad
                                          }}
                                          mapHeight={"370px"}
                                          breakpoints={{
                                                map: {
                                                      xs: 12,
                                                      sm: 12,
                                                      md: 5,
                                                      lg: 6,
                                                      xl: 6
                                                },
                                                components: {
                                                      xs: 12,
                                                      sm: 12,
                                                      md: 7,
                                                      lg: 6,
                                                      xl: 6
                                                }
                                          }}
                                          mapResult={props?.mapResult}
                                          autoCompletePlacement={{
                                                top: 14,
                                          }}
                                          fields={[
                                                {
                                                      label: "Door Number",
                                                      component: "TextField",
                                                      value: props?.data?.doorNo,
                                                      state_name: 'doorNo',
                                                      isrequired: false,
                                                      placeholder: "Enter Door Number",
                                                      error: props?.data?.error?.doorNo,
                                                      errorMessage: props?.data?.error?.doorNo?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "Address Line 1",
                                                      component: "TextField",
                                                      value: props?.data?.addressLineOne,
                                                      state_name: 'addressLineOne',
                                                      isrequired: false,
                                                      placeholder: "Enter Address Line 1",
                                                      errorMessage: props?.data?.error?.addressLineOne,
                                                      error: props?.data?.error?.addressLineOne?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 4.5
                                                      }
                                                },
                                                {
                                                      label: "Address Line 2",
                                                      component: "TextField",
                                                      value: props?.data?.addressLineTwo,
                                                      state_name: 'addressLineTwo',
                                                      isrequired: false,
                                                      placeholder: "Enter Address Line 2",
                                                      errorMessage: props?.data?.error?.addressLineTwo,
                                                      error: props?.data?.error?.addressLineTwo?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 4.5
                                                      }
                                                },
                                                {
                                                      label: "Landmark",
                                                      component: "TextField",
                                                      value: props?.data?.landmark,
                                                      state_name: 'landmark',
                                                      isrequired: false,
                                                      placeholder: "Enter Landmark",
                                                      errorMessage: props?.data?.error?.landmark,
                                                      error: props?.data?.error?.landmark?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "Area",
                                                      component: "TextField",
                                                      value: props?.data?.area,
                                                      state_name: 'area',
                                                      isrequired: false,
                                                      placeholder: "Enter Area",
                                                      errorMessage: props?.data?.error?.area,
                                                      error: props?.data?.error?.area?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "City",
                                                      component: "TextField",
                                                      value: props?.data?.city,
                                                      state_name: 'city',
                                                      isrequired: false,
                                                      placeholder: "Enter City",
                                                      errorMessage: props?.data?.error?.city,
                                                      error: props?.data?.error?.city?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "State",
                                                      component: "TextField",
                                                      state_name: 'state',
                                                      value: props?.data?.state,
                                                      isrequired: false,
                                                      placeholder: "Enter State",
                                                      errorMessage: props?.data?.error?.state,
                                                      error: props?.data?.error?.state?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "Country",
                                                      component: "TextField",
                                                      value: props?.data?.country,
                                                      state_name: 'country',
                                                      isrequired: false,
                                                      placeholder: "Enter Country",
                                                      errorMessage: props?.data?.error?.country,
                                                      error: props?.data?.error?.country?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "Pincode",
                                                      component: "TextField",
                                                      state_name: 'zipcode',
                                                      value: props?.data?.zipcode,
                                                      isrequired: false,
                                                      placeholder: "Enter Pincode",
                                                      errorMessage: props?.data?.error?.zipcode,
                                                      error: props?.data?.error?.zipcode?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "Latitude",
                                                      component: "TextField",
                                                      state_name: 'latitude',
                                                      value: props?.data?.latitude,
                                                      isrequired: false,
                                                      placeholder: "Enter Latitude",
                                                      errorMessage: props?.data?.error?.latitude,
                                                      error: props?.data?.error?.latitude?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "Longitude ",
                                                      component: "TextField",
                                                      state_name: 'longitude',
                                                      value: props?.data?.longitude,
                                                      isrequired: false,
                                                      placeholder: "Enter Longitude ",
                                                      errorMessage: props?.data?.error?.longitude,
                                                      error: props?.data?.error?.longitude?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                          ]}
                                          onChangeFields={(key, value) => props?.updateState(key, value)}
                                    />
                              }
                        </Box>
                  </div>
                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              <Grid item xs={12} md={3} lg={3}>
                                    <MobileNumberInputComponent
                                          label={"Business Phone"}
                                          placeholder={"Business Phone"}
                                          value={props?.data?.businessPhone}
                                          isRequired
                                          handleChange={(value) => {
                                                props?.updateState("businessPhone", value);
                                          }}
                                          isError={props?.data?.error?.businessPhone?.length > 0}
                                          errorMessage={props?.data?.error?.businessPhone}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                    />
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <MobileNumberInputComponent
                                          label={"Mobile Phone"}
                                          placeholder={"Mobile Phone"}
                                          value={props?.data?.mobilePhone}
                                          isRequired
                                          handleChange={(value) => {
                                                props?.updateState("mobilePhone", value);
                                          }}
                                          isError={props?.data?.error?.mobilePhone?.length > 0}
                                          errorMessage={props?.data?.error?.mobilePhone}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                    />
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <TextBox
                                          isrequired
                                          isReadonly
                                          label="Website"
                                          placeholder="Enter Website"
                                          value={props?.data?.website ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("website", e.target.value);
                                          }}
                                          isError={props?.data?.error?.website?.length > 0}
                                          errorMessage={props?.data?.error?.website} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <TextBox
                                          isrequired
                                          label="Email Address"
                                          placeholder="Enter Email Address"
                                          value={props?.data?.emailAddress ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("emailAddress", e.target.value);
                                          }}
                                          isError={props?.data?.error?.emailAddress?.length > 0}
                                          errorMessage={props?.data?.error?.emailAddress} />
                              </Grid>
                        </Grid>
                  </Box>



            </div>


      )
}