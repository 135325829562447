import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold } from "../../utils";
export const dashboardStyles = makeStyles((theme) => ({
      totalBox: {
            position: 'absolute',
            top: '40%',
            width: '100%',
            textAlign: 'center'

      },
      root: {
            position: 'relative'
      },
      title: {
            fontSize: "12px",
            color: theme.typography.color.primary,
            fontFamily: Bold,
      },
      count: {
            fontSize: "24px",
            color: theme.typography.color.primary,
            fontFamily: ExtraBold,
      },
}))