import { Box, Button, Stack, Divider } from '@mui/material'
import React from 'react'
import { AlertDialog, FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components'
import { AlertProps, LocalStorageKeys, managementCommitteRoleMasterHeading, managementCommitteRoleMasterPath } from '../../utils'
import { ManagementCommitteRoleStyle } from './style'
import FilterIcon from "../../assets/filterIcon";
import styled from '@mui/material/styles/styled';
import AddRolePopup from './addRolePopup'
import { InsertManagementCommitte, getManagementRole, updateManagementRole } from '../../graphql/managementCommittee'
import { useApolloClient } from "@apollo/client";
import { getCompanyOption, getRoutePermissionNew, accessCheckRender } from "../../utils";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import moment from 'moment'
import { withNamespaces } from 'react-i18next'
import { StatusOptionList } from '../../utils/inventory'
const CustomPaper = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 16px #00000008',
    borderRadius: '12px',
    backgroundColor: "#FFF",
    padding: "18px",
    height: `calc(100vh - 130px)`
}))

const ManagementCommitteRoleMaster = ({ t }) => {
    const popupinitialState = () => {
        return {
            id: null,
            company_name: selectedCompany?.label,
            role_name: "",
            responsibilities: "",
            status: true,
            edit: false,
            view: false,
            error: {
                role_name: "",
                responsibilities: "",
                status: "",
            }
        }
    }
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [popup, setPopup] = React.useState(false)
    const [filterData, setFilterData] = React.useState({ status: [true] })
    const [companyList, setCompanyList] = React.useState([]);
    const [permission, setPermission] = React.useState({})
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [roleState, setRoleState] = React.useState({ ...popupinitialState() })
    const [tableData, setTableData] = React.useState([])
    const [fiterDrawer, setFilterDrawer] = React.useState(false)
    const [fetch, setFetch] = React.useState(false)

    const profileID = localStorage.getItem(LocalStorageKeys.profileID)
    const classes = ManagementCommitteRoleStyle()
    const client = useApolloClient();
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext);
    const debounce = UseDebounce();
    const Heading = managementCommitteRoleMasterHeading(t)

    React.useEffect(() => {
        const _ = getRoutePermissionNew(auth)
        if (_) {
            setPermission(_)
            if (_?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    getManagementRoles(0, limit, "", company?.selected?.value)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth, filterData]);

    //   company switcher
    const handleCompanyChange = (e) => {
        setSelectedCompany(e);
        getManagementRoles(0, limit, searchText, e?.value)
    };

    const handleSearch = (e) => {
        setSearchText(e);
        debounce(() => getManagementRoles(0, limit, e, selectedCompany?.value), 800)
    };

    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getManagementRoles(offset, limit, "", selectedCompany?.value)
    };

    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getManagementRoles(0, limit, "", selectedCompany?.value)
    };

    const updateState = (key, value) => {
        let error = roleState?.error
        error[key] = ""
        setRoleState({ ...roleState, [key]: value, error })
    }

    const handleChange = (type, data) => {
        if (type === "edit") {
            setRoleState({
                ...roleState,
                id: data?.id,
                company_name: data?.company_name,
                role_name: data?.role_name,
                responsibilities: data?.description,
                status: data?.status === "Active" ? true : false,
                created_by: profileID,
                edit: true,
                view: false
            })
            setPopup(true)

        } else if (type === "view") {
            setRoleState({
                ...roleState,
                company_name: data?.company_name,
                role_name: data?.role_name,
                responsibilities: data?.description,
                status: data?.status === "Active" ? true : false,
                edit: false,
                view: true
            })
            setPopup(true)
        }
        else if (type === "active") {
            updateManagementCommittee(data?.status, data?.id)
        }
    }
    const closePopup = () => {
        setPopup(false)
        setTimeout(() => {
            setRoleState({ ...popupinitialState() })
        }, 1000)


    }

    const getManagementRoles = (offset, limit, searchText, companyID) => {
        setFetch(true)
        let isactive = filterData?.status.length > 0 ? filterData?.status : []
        client.query({
            query: getManagementRole,
            fetchPolicy: 'network-only',
            variables: {
                offset: offset,
                limit: limit,
                searchText: searchText,
                companyId: companyID,
                status: isactive
            }
        }).then((response) => {
            setTableData({
                ...tableData, "tableRow":
                    response?.data?.committee_roles?.map(e => {
                        return {
                            id: e?.id,
                            role_name: e?.role_name ?? "-",
                            company_name: e?.company?.name,
                            created_on: moment(e?.created_at).format("YYYY-MM-DD"),
                            description: e?.description,
                            created_by: e?.created_by?.first_name,
                            status: e?.is_active ? "Active" : "Inactive"
                        }
                    }), "count": response?.data?.count[0]?.count
            })
            setFetch(false)

        }).catch((err) => {
            console.log(err)
            setFetch(false)
        })
    }

    const validate = () => {
        let isValid = true
        let error = roleState?.error
        if (roleState?.role_name.length === 0) {
            isValid = false
            error.role_name = t("RoleNameisRequired")
        }
        if (roleState?.responsibilities.length === 0) {
            isValid = false
            error.responsibilities = t("ResponsibilitiesisRequired")
        }
        if (roleState?.status === null) {
            isValid = false
            error.status = t("StatusisRequired")
        }
        if (
            roleState?.role_name.length === 0 ||
            roleState?.responsibilities.length === 0 ||
            roleState?.status === null
        ) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Pleasefillallmandatoryfield"),
            });
        }
        else (
            createManagementCommittee(roleState)
        )
        setRoleState({ ...roleState, error })
        return isValid
    }

    const createManagementCommittee = (data) => {
        let payload = {
            role_name: data?.role_name,
            description: data?.responsibilities,
            company_id: selectedCompany?.value,
            is_active: data?.status,
            created_by: profileID
        }
        client
            .mutate({
                mutation: InsertManagementCommitte,
                variables: {
                    insert: [payload],
                },
            })
            .then((res) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("RoleAddedSuccessfully"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                setRoleState({ ...popupinitialState() })
                closePopup()
                getManagementRoles(0, limit, "", selectedCompany?.value)
            })
            .catch((er) => {
                console.log(er);
            });
    };

    const updateManagementCommittee = (type, id) => {
        let payload = {
            id: roleState?.id?.length > 0 ? roleState?.id : id,
            data: {
                role_name: roleState?.role_name?.length > 0 ? roleState?.role_name : undefined,
                description: roleState?.responsibilities?.length > 0 ? roleState?.responsibilities : undefined,
                company_id: selectedCompany?.value.length > 0 ? selectedCompany?.value : undefined,
                is_active: !type?.length > 0 ? roleState?.status : type === "Inactive" ? true : false,
                updated_by: profileID
            }
        }
        client
            .mutate({
                mutation: updateManagementRole,
                variables: payload,
            })
            .then((res) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("RoleUpdatedSuccessfully"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                setRoleState({ ...popupinitialState() })
                closePopup()
                getManagementRoles(0, limit, "", selectedCompany?.value)
            })
            .catch((er) => {
                console.log(er);
            });
    };

    const render = () => {
        return (
            <>
                <Subheader
                    title={t("ManagementCommittee").concat(` (${tableData?.count ?? 0}) `)}
                    hideBackButton
                    select={true}
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        handleCompanyChange(e);
                    }}
                />
                <Stack spacing={1} m={2}>
                    <CustomPaper>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Box className={classes.searchBox}>
                                <SearchFilter
                                    placeholder={t("SearchMasterRole")}
                                    value={searchText}
                                    handleChange={handleSearch}
                                />
                            </Box>
                            <Stack direction={"row"} spacing={1}
                                divider={<Divider orientation="vertical" flexItem />}
                                className={classes.secondStack}>
                                <Box className={classes.filterIconBox} onClick={() => setFilterDrawer(!fiterDrawer)}>
                                    <FilterIcon />
                                </Box>
                                {permission.create && <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.addbtn}
                                    onClick={() => setPopup(true)}
                                >
                                    {t("Addnew")}
                                </Button>}
                            </Stack>
                        </Stack>
                        {
                            fetch ?
                                <LoadingSection bottom={"45vh"} message="Fetching Data..." /> :
                                <TableWithPagination
                                    heading={Heading}
                                    rows={tableData?.tableRow}
                                    path={managementCommitteRoleMasterPath}
                                    showpagination={true}
                                    handleIcon={handleChange}
                                    tableType="no-side"
                                    onClick={() => false}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    dataType={[
                                        { type: ["more_4"], name: "" },
                                        { type: ["text"], name: "role_name" },
                                        { type: ["text"], name: "company_name" },
                                        { type: ["text"], name: "created_on" },
                                        { type: ["text"], name: "created_by" },
                                        { type: ["status"], name: "status" },
                                    ]}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={tableData?.count}
                                    page={page}
                                    limit={limit}
                                    height={'calc(100vh - 280px)'}
                                    view={true}
                                    edit={true}
                                    delete={true} />
                        }
                    </CustomPaper>

                    <FilterGenerator open={fiterDrawer} onClose={() => setFilterDrawer(false)}
                        onApply={(value) => setFilterData(value)}
                        components={[
                            {
                                component: "toggleButton",
                                value: filterData?.status,
                                options:StatusOptionList,
                                isMulti: true,
                                state_name: "status",
                                label: "Status",
                            },
                        ]} />

                    <AlertDialog
                        open={popup}
                        header={!roleState?.edit ? "Add Role" : "Edit Role"}
                        isNormal
                        onClose={closePopup}
                        component={
                            <AddRolePopup closePopup={closePopup}
                                create={validate}
                                updateState={updateState} roleState={roleState}
                                update={updateManagementCommittee} />
                        }
                    />
                </Stack>
            </>
        )
    }

    return (
        <Box>

            {accessCheckRender(render, permission)}
        </Box>

    )
}

export default withNamespaces("managementCommitte")(ManagementCommitteRoleMaster)