import { Box, Button, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import PowerdBy from "../../assets/powerdby";
import PropGoToComponent from '../../assets/propgoto';
import { useStyles } from "./style";
import { WeatherComponent, Password, TextBox } from "../../components";
import { useHistory ,useLocation} from "react-router-dom";
import { Routes } from "../../router/routes";
import jwt_decode from "jwt-decode";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { AlertContext} from "../../contexts";
import { config } from "../../config";
import { PasswordInfo } from "../../components/passwordInfo";


const initial = {
      password: '',
      conformpassword: "",
      email: '',
      error: {
            password: '',
            conformpassword: "",
            email: '',
      }
}
export const SetPasswordPage = () => {

      // classes
      const classes = useStyles();
      const history = useHistory();
      const [data, setData] = useState({ ...initial })
      const [decoded, setDecoded] = React.useState(null);
      const search = useLocation().search;
      const alert = React.useContext(AlertContext);
      const welcomePageAuthToken = new URLSearchParams(search).get(
            "welcomeAuthToken"
          );

      // sumbit function
      const handleSubmit = (e) => {
            e.preventDefault()
            if (validate()) {
                  const payload = {
                        password: data?.conformpassword??"",
                      };
                      NetworkCall(
                        `${config?.authapi}/auth/updatepassword/?token=${welcomePageAuthToken}`,
                        NetWorkCallMethods.post,
                        payload,
                        null,
                        false,
                        false
                      )
                        .then((response) => {
                          if (response?.status === 201) {
                            alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.success,
                              msg: "Password created successfully",
                            });
                            localStorage.clear();
                            history.push(
                              Routes.login + "?welcomeAuthToken=" + welcomePageAuthToken
                            );
                          }
                        })
                        .catch((err) => {
                          alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: "Some thing went wrong",
                          });
                        });
            }
      }

      // set state
      const updateState = (key, value) => {
            let error = data?.error;
            error[key] = "";
            setData({ ...data, [key]: value })
      }

      // validation
      const validate = () => {
            let isValid = true;
            let error = data.error;

            const is_password_strong_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
            const is_password_strong = is_password_strong_regex.test(data.password)

            if (data?.password?.length === 0) {
                  isValid = false;
                  error.password = "Password is Required";
            } else if (!Boolean(is_password_strong)) {
                  isValid = false;
                  error.password = "Password is not stong";
            }
            if (data?.conformpassword?.length === 0) {
                  isValid = false;
                  error.conformpassword = "Conform Password is Required";
            }
            if (data?.conformpassword !== data?.password) {
                  isValid = false;
                  error.conformpassword = "Password and Conform Password must be same";
            }

            setData({ ...data, error });

            return isValid;
      }


      React.useEffect(() => {
            if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
              setDecoded(jwt_decode(welcomePageAuthToken));
            }
            // eslint-disable-next-line
          }, []);

      return (
            <div>
                  {/* logo */}
                  <Box className={classes.logoIcon}>
                        <PropGoToComponent />
                  </Box>
                  {/* body */}
                  <Container maxWidth="sm" style={{ padding: 0 }}>
                        <form onSubmit={handleSubmit} className={classes.signupform}>
                              <Box className={classes.content}>
                                    <Box >
                                          {/* title */}
                                          <Typography className={classes.text}>Set up your account details below</Typography>
                                    </Box>
                                    {/*  form*/}
                                    <div>
                                          <Box className={classes.inputParent}>
                                                <TextBox
                                                      value={decoded?.email_id?.length > 0  ? decoded?.email_id : data?.email}
                                                      onChange={(e) => updateState("email", e.target.value)}
                                                      label={'Email Id'}
                                                      placeholder={'Enter Email Id'}
                                                      isError={data?.error?.email?.length > 0}
                                                      errorMessage={data?.error?.email}
                                                      isrequired
                                                      type="email"
                                                />
                                          </Box>
                                          <Box className={classes.inputParent}>
                                                <Password
                                                      value={data?.password ?? ""}
                                                      onChange={(e) => updateState("password", e.target.value)}
                                                      label={'Password'}
                                                      placeholder={'Enter Password'}
                                                      isError={data?.error?.password?.length > 0}
                                                      errorMessage={data?.error?.password}
                                                      isrequired
                                                />
                                                <PasswordInfo />
                                          </Box>
                                          <Box className={classes.inputParent}>
                                                <Password
                                                      value={data?.conformpassword ?? ""}
                                                      onChange={(e) => updateState("conformpassword", e.target.value)}
                                                      label={'Confirm Password'}
                                                      placeholder={'Enter Confirm Password'}
                                                      isError={data?.error?.conformpassword?.length > 0}
                                                      errorMessage={data?.error?.conformpassword}
                                                      isrequired
                                                />
                                          </Box>
                                    </div>

                              </Box>
                              {/* button */}
                              <Box className={classes.content}>
                                    <Button
                                          fullWidth
                                          variant="contained"
                                          className={classes.btn}
                                          type="submit"
                                    >
                                          <Typography className={classes.buttonTextStyle}>
                                                Set Password
                                          </Typography>
                                    </Button>
                                    {/* powered by logo */}
                                    <center>
                                          <PowerdBy height="32px" top="8px" />
                                    </center>
                              </Box>
                        </form>
                  </Container>
                  <WeatherComponent />
            </div >
      );
};
