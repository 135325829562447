import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Regular, SemiBold } from "../../../utils";
export const ExistingLeadStyles = makeStyles((theme) => ({
    root: {
        padding: "8px",
    },

    grid: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius
    },
    textbox: {
        marginBottom: "15px",
        [`& fieldset`]: {
            borderRadius: "10px",

        },

        borderRadius: "10px",
        backgroundColor: "#F5F7FA",
    },
    nodata: {
        fontFamily: Bold,
        fontSize: "12px",
        textAlign: "center",
        marginTop: "12px"
    }
}));
export const NewLeadStyles = makeStyles((theme) => ({
    root: {
        marginTop: "14px",
    },
    uploadImage: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: "#071741",
        cursor: "pointer",
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        padding: "4px 10px",
        display: "inline-block",
        marginTop: "12px"
    },
    profileTitle: {
        fontSize: "12px",
        fontFamily: Bold,
        color: "#4E5A6B",
        marginBottom: "12px"
    },

    profileimg: {
        backgroundColor: theme.palette.background.tertiary,
        color: "#E4E8EE",
        position: "relative",
        display: "block"
    },
    profileicon: {
        fontSize: "90px",
        padding: "14px",
    },
    profileimgs: {
        backgroundColor: theme.palette.background.tertiary,
        color: "#E4E8EE",
        position: "absolute",
        bottom: "0px",
        right: "0px",
        border: "2px solid white",
        borderRadius: "50%",
    },

    profilesection: {
        marginTop: "14px",
        borderRight: "3px solid #F5F7FA"
    },
    field: {
        // marginTop: "10px",
    },
    address: {
        marginTop: "24px",
        borderBottom: "1px solid #F5F7FA",
        borderTop: "1px solid #F5F7FA",
        marginBottom: "10px",
        padding: "8px 0px",
    },
    addAddress: {
        fontSize: "14px",
        fontFamily: Bold,
        color: "#5078E1",
        cursor: "pointer",
    },
    addresstitle: {
        fontSize: "16px",
        fontFamily: Bold,
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
        },
    },
    previousbtn: {
        backgroundColor: "white",
        color: theme.typography.color.primary,
        border: "1px solid #CED3DD",
        borderRadius: theme.palette.borderRadius,
        padding: "8px 12px",
        boxShadow: "none",
        '&:hover': {
            backgroundColor: "white",
        }
    },
    submitbtn: {
        borderRadius: theme.palette.borderRadius,
        marginLeft: "8px",
        padding: "8px 12px",
        boxShadow: "none"
    },
    Label: {
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
        fontSize: "14px",
        marginBottom: "5px",
    },
    addressList: {
        fontSize: "12px",
        color: "#98A0AC",
        fontFamily: SemiBold,
        marginTop: "12px",
    },
    addresstitles: {
        fontSize: "14px",
        color: "#091B29",
        fontFamily: Bold,
    },
    primary: {
        fontSize: "8px",
        padding: "2px 4px 1px 4px",
        backgroundColor: "#071741",
        borderRadius: theme.palette.borderRadius,
        color: "white",
        fontFamily: ExtraBold,
    },
    addressbox: {
        padding: "8px",
        border: "1px solid #071741",
        borderRadius: theme.palette.borderRadius,
        position: "relative",
        display: "inline-block"
    },
    imgdiv: {
        position: "relative",
        display: "inline-block",
        borderRadius: "50%",
    },
    close: {
        position: "absolute",
        right: "0px",
        top: "0px",
        backgroundColor: "red",
        borderRadius: "50%",
        color: "white",
        fontSize: "20px",
    },
}));
export const LeadPreviewStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: "white",
        padding: "12px",
        borderRadius: "4px",
        boxShadow: "0px 0px 16px #00000014"
    },
    imgdiv: {
        position: "relative",
    },
    prority: {
        position: "absolute",
        bottom: "4px",
        left: "0px",
        right: "0px",
    },
    proritydata: {
        fontFamily: ExtraBold,
        fontSize: "10px",
        backgroundColor: "#071741",
        borderRadius: theme.palette.borderRadius,
        color: "white",
        padding: "2px 12px",
    },
    image: {
        height: "120px",
        objectFit: "cover",
        borderRadius: "50%",
        width: "100%"
    },
    rightBorder: {
        borderRight: "3px solid #F5F7FA",
        padding: "12px"
    },
    title: {
        fontSize: "12px",
        fontFamily: Bold,
        color: "#4E5A6B",
        marginBottom: "12px"
    },
    heading: {
        color: theme.typography.color.tertiary,
        fontSize: "14px",
        fontFamily: SemiBold,
        textTransform: "capitalize"
    },
    sub: {
        color: theme.typography.color.primary,
        fontSize: "14px",
        fontFamily: SemiBold,
        textTransform: "capitalize",
        "& .ql-editor": {
            padding: "6px 0px",
        }

    },
    requirmentdata: {
        fontSize: "8px",
    },
    sqftdata: {
        fontSize: "12px",
        fontFamily: Bold,
    },
    secdule: {
        fontSize: "12px",
        fontFamily: Bold,
        marginLeft: "4px",
    },
    subDiv: {
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        height: "120px",
        overflow: "scroll"
    }
}));
export const QuotationPreviewStyles = makeStyles((theme) => ({

    title: {
        fontSize: "14px",
        fontFamily: Bold,
        marginBottom: "12px",
        color: "#4E5A6B"
    },
    head: {
        fontSize: "10px",
        fontFamily: SemiBold,
        color: "#98A0AC"
    },
    sub: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: "#091B29"
    },
    root: {
        padding: "20px",
        marginTop: "10px",
        height: `calc(100vh - 290px)`,
        overflow: 'auto',
        backgroundColor: "white",
        borderRadius: "4px",
        boxShadow: "0px 10px 25px #0000000A"
    },
    table: {
        backgroundColor: "#F5F7FA"
    },
    detailTitle: {
        fontFamily: Bold,
        color: "#091B29",
        fontSize: "16px",
        marginTop: "4px"
    },
    titleBar: {
        borderBottom: "1px solid #E4E8EE"
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
        },
    },
    container: {
        height: `calc(100vh-400px)`,
        overflow: "auto"
    },
    btnroot: {
        backgroundColor: "white",
        padding: "14px",
    },
    previousbtn: {
        backgroundColor: "white",
        color: theme.typography.color.primary,
        border: "1px solid #CED3DD",
        borderRadius: theme.palette.borderRadius,
        padding: "8px 12px",
        boxShadow: "none",
        '&:hover': {
            backgroundColor: "white",
        }
    },
    submitbtn: {
        borderRadius: theme.palette.borderRadius,
        marginLeft: "8px",
        padding: "8px 12px",
        boxShadow: "none"
    },

}));
export const QuotationDetailsStyles = makeStyles((theme) => ({
    textbox: {
        width: '100%',
        borderRadius: theme.palette.borderRadius,
        border: '1.5px solid #E4E8EE',
        cursor: 'pointer',
        [`& fieldset`]: {
            borderRadius: theme.palette.borderRadius,
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #5078E1",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: (props) => (props.multiline ? "0px" : "0px"),
        },
        ":-webkit-autofill": {
            WebkitBoxShadow: "none !important",
            backgroundColor: "red !important",
        },
        '&:focusVisible': {
            border: '1.5px solid #E2E2E2',
        },

        padding: '14px 14px'
    },
    profileimg: {
        backgroundColor: theme.palette.background.tertiary,
        color: "#E4E8EE",
        position: "relative",
    },
    profileicon: {
        fontSize: "80px",
        padding: "14px",
    },
    profileimgs: {
        backgroundColor: theme.palette.background.tertiary,
        color: "#E4E8EE",
        position: "absolute",
        bottom: "0px",
        right: "0px",
        border: "2px solid white",
        borderRadius: "50%",
    },

    profilesection: {
        marginTop: "14px",
    },
    field: {
        // marginTop: "10px",
    },
    fieldparent: {
        marginTop: "10px",
    },
    address: {
        marginTop: "10px",
        borderBottom: "1px solid #F5F7FA",
        borderTop: "1px solid #F5F7FA",
        marginBottom: "10px",
        padding: "8px 0px",
    },
    addAddress: {
        fontSize: "14px",
        fontFamily: Bold,
        color: "#5078E1",
        cursor: "pointer",
    },
    addresstitle: {
        fontSize: "16px",
        fontFamily: Bold,
    },
    UnitStatusactive: {
        padding: "12px 16px",

        textAlign: "center",
        borderRadius: "10px",
        color: "white",
        marginTop: "-4px",
        fontSize: "14px",
        backgroundColor: "#5078E1",
        cursor: "pointer",
    },
    UnitStatus: {
        padding: "12px 16px",
        textAlign: "center",
        borderRadius: "10px",
        fontSize: "14px",
        cursor: "pointer",
        marginTop: "-4px",
        backgroundColor: "white",
        border: "1px solid #E4E8EE",
    },
    Label: {
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
        fontSize: "14px",
        marginBottom: "5px",
        marginTop: "6px",
    },
    div: {
        marginTop: "12px"
    },
    details: {
        backgroundColor: "#FFF4EB",
        borderRadius: "10px",
    },
    paymentTitle: {
        color: theme.typography.color.primary,
        fontFamily: Bold,
        fontSize: "18px"
    },
    paymentSub: {
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
        fontSize: "14px",
        marginTop: "4px"
    },
    paymentSub1: {
        color: "#FF9340",
        fontFamily: Regular,
        fontSize: "14px",
        marginTop: "12px"
    },
    info: {
        color: "#98A0AC",
        border: "1px solid #98A0AC",
        borderRadius: "50%",
        padding: "1px",
        cursor: "pointer",
        fontSize: "6px",
        marginTop: "-12px"
    },
    popover: {
        "& .MuiPopover-paper": {
            boxShadow: "0px 6px 8px #006E2921",
            backgroundColor: "#EEF9EE",
            borderRadius: "16px"
        }
    },
    billTitle: {
        color: "#1A9146",
        fontFamily: ExtraBold,
        fontSize: "18px",
        margin: "4px 0px 8px 0px"
    },
    billSub: {
        color: "#98A0AC",
        fontFamily: Regular,
        fontSize: "14px",
        margin: "4px 0px"
    },
    billSubTitle: {
        color: "#4E5A6B",
        fontFamily: Bold,
        fontSize: "14px",
    },
    confirmBtn: {
        backgroundColor: "#4E9F3D",
        color: "white"
    },
    cancelBtn: {
        backgroundColor: "#FF0000",
        color: "white"
    }
}));

export const Step5Styles = makeStyles((theme) => ({
    root: {
        marginTop: "8px",
    },
    leadDetailsRoot: {

    },
    leadDetailsRootDivider: {
        borderRadius: "4px",
        backgroundColor: "white",
        height: `calc(100vh - 258px)`,
        overflow: 'auto',
        boxShadow: "0px 0px 16px #00000014"
    }
}));
export const AddContactStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: "white",
        borderRadius: "4px",
        boxShadow: "0px 0px 16px #00000014",
        height: `calc(100vh - 270px)`,
        overflow: "auto",
    },
    root: {
        padding: "14px 12px 20px",
    },
}));