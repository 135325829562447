import { Badge, Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import FilterIMG from '../../assets/filter';
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { accessCheckRender, AlertProps, concat_string, CorrespondenceStatusColor, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods, timeZoneConverter } from '../../utils';
import { Heading, Path, Type } from './utils/tableUtils';
import { correspondencesStyles } from "./style";
import CloseIcon from '@mui/icons-material/Close';
import { withNamespaces } from 'react-i18next';
import { ChatIcon, ContactLevel, RightChevron, UnitLevel } from '../../assets';
import { config } from '../../config';
import { BackendRoutes, Routes } from '../../router/routes';
import { NetworkCall } from '../../networkcall';
import { DocumentViewer } from '../../components/fileViewer';
import { useApolloClient } from '@apollo/client';
import { GET_CORRESPONDENCE_OPTIONS } from './utils/query';
import { useHistory } from 'react-router';

const Correspondences = (props) => {
    const { t, loading, handleLoading } = props

    const classes = correspondencesStyles()
    const debounce = UseDebounce()
    const client = useApolloClient()
    const history = useHistory()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)

    // useState
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [list, setList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState({
        priority: [],
        correspondence_type: [],
        status: [],
        level: []
    })
    const [options, setOptions] = React.useState({
        urgent_type: [],
        correspondences_type_master: [],
        correspondences_status_master: [],
        correspondences_level_master: []
    })
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [disableButton, setDisableButton] = React.useState(false)
    const [isAddDialogOpen, setIsAddDialogOpen] = React.useState(false)
    const [isViewDialogOpen, setIsViewDialogOpen] = React.useState(false)
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false)
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = React.useState(false)
    const [permission, setPermission] = React.useState({})
    const [viewDialogData, setViewDialogData] = React.useState({})
    const [confirmDialogData, setConfirmDialogData] = React.useState({})
    const [successDialogData, setSuccessDialogData] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // Function to get company list
    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getOptions()
            getList(0, limit, searchText, filterData, company?.selected?.value)
        }
    }

    // Function to get list based on the input data
    const getList = (offset = 0, limit = 10, search = searchText, filter_data = filterData, company_id = selectedCompany?.value) => {
        let payload = {
            offset, limit, search, company_id,
            filters: {
                priority: filter_data?.priority?.map?.(i => i?.value),
                type: filter_data?.correspondence_type?.map?.(i => i?.value),
                status: filter_data?.status?.map?.(i => i?.value),
            }
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes?.correspondences_list}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            let temp_res = res?.data?.data
            let temp_data = temp_res?.list?.map((_) => {
                let j
                try {
                    j = {
                        correspondences_no: _?.reference_no ?? "-",
                        subject: _?.subject ?? "-",
                        to: concat_string(
                            {
                                name: _?.contact_name,
                                door_no: _?.level_id === 1 ? _?.unit_address?.door_no : _?.contact_address?.door_no,
                                street_1: _?.level_id === 1 ? _?.unit_address?.street_1 : _?.contact_address?.street_1,
                                street_2: _?.level_id === 1 ? _?.unit_address?.street_2 : _?.contact_address?.street_2,
                                landmark: _?.level_id === 1 ? _?.unit_address?.landmark : _?.contact_address?.landmark,
                                area: _?.level_id === 1 ? _?.unit_address?.area : _?.contact_address?.area,
                                city: _?.level_id === 1 ? _?.unit_address?.city : _?.contact_address?.city,
                                district: _?.level_id === 1 ? _?.unit_address?.district : _?.contact_address?.district,
                                state: _?.level_id === 1 ? _?.unit_address?.state : _?.contact_address?.state,
                                country: _?.level_id === 1 ? _?.unit_address?.country : _?.contact_address?.country,
                                zipcode: _?.level_id === 1 ? _?.unit_address?.zipcode : _?.contact_address?.zipcode,
                            },
                            [
                                "name", "door_no", "street_1", "street_2", "street_3",
                                "landmark", "area", "city", "district", "state",
                                "country", "zipcode"
                            ]
                        ),
                        correspondence_type: _?.type_name ?? "-",
                        created_on: _?.created_at ? timeZoneConverter(_?.created_at, "DD MMM YY, hh:mm a") : "-",
                        status: _?.status_id ?
                            {
                                value: _?.status_id,
                                label: _?.status,
                                text_color: CorrespondenceStatusColor(_?.status_id)
                            } : "",
                        data: _
                    }
                } catch (err) {
                    alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps.severity.error
                    })
                }
                return j
            })
            setList({
                data: temp_data ?? [],
                totalRowsCount: temp_res?.count
            })
            handleLoading(false)
        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        });
    }

    //Function to get options for filter
    const getOptions = async () => {
        const enum_result = await enumSelect([enum_types?.urgent_type])
        const master_result = await client.query({
            query: GET_CORRESPONDENCE_OPTIONS,
            fetchPolicy: 'network-only',
        }).catch((err) => console.log(err))
        setOptions({
            urgent_type: enum_result?.urgent_type,
            correspondences_type_master: master_result?.data?.correspondences_type_master,
            correspondences_status_master: master_result?.data?.correspondences_status_master,
            correspondences_level_master: master_result?.data?.correspondences_level_master,
        })
    }

    // Function to change the company
    const handleCompanyChange = (value) => {
        setPage(1)
        setSelectedCompany(value)
        getList(0, limit, searchText, filterData, value?.value)
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getList(0, limit, e)
    }

    // Function to open add form
    const handleAddCorrespondences = () => {
        setIsAddDialogOpen(true)
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        let main_data = data?.data
        switch (type) {
            case "dialog_view":
                setViewDialogData(main_data)
                setIsViewDialogOpen(true)
                break;
            case "send":
                handleSend(main_data?.correspondence_id)
                break;
            case "delete":
                handleDelete(main_data?.correspondence_id)
                break;
            default:
                break;
        }
    }

    //Function to handle send
    const handleSend = (id) => {
        setConfirmDialogData({
            question: t("Are you sure you want to send this correspondences?"),
            yes_action: () => sendCorrespondence(id, 2),
            no_action: () => setIsConfirmDialogOpen(false),
        })
        setIsConfirmDialogOpen(true)
    }

    //Function to send correspondence
    const sendCorrespondence = (correspondence_id, status) => {
        setDisableButton(true)
        let payload = { correspondence_id, status }
        NetworkCall(
            `${config.api_url}${BackendRoutes?.correspondences_publish}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            getList(0, limit)
            setSuccessDialogData({
                success_message: t("Correspondences sent successfully!"),
                okay_action: () => setIsSuccessDialogOpen(false)
            })
            setIsConfirmDialogOpen(false)
            setDisableButton(false)
            setIsSuccessDialogOpen(true)
        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
            setDisableButton(false)
        });
    }

    //Function to handle delete
    const handleDelete = (id) => {
        setConfirmDialogData({
            question: t("Are you sure you want to delete this correspondences?"),
            // yes_action: () => deleteCorrespondence(id),
            yes_action: () => deleteCorrespondence(id),
            no_action: () => setIsConfirmDialogOpen(false),
        })
        setIsConfirmDialogOpen(true)
    }

    //Function to delete correspondence
    const deleteCorrespondence = (correspondence_id) => {
        setDisableButton(true)
        let payload = { correspondence_id }
        NetworkCall(
            `${config.api_url}${BackendRoutes?.correspondences_delete}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            getList(0, limit)
            setSuccessDialogData({
                success_message: t("Deleted successfully!"),
                okay_action: () => setIsSuccessDialogOpen(false)
            })
            setIsConfirmDialogOpen(false)
            setDisableButton(false)
            setIsSuccessDialogOpen(true)
        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
            setDisableButton(false)
        });
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getList(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getList(0, value, searchText)
    }

    //Funtion to apply filter
    const applyFilter = (filter_data) => {
        setPage(1)
        setFilterData(filter_data)
        getList(0, limit, searchText, filter_data, selectedCompany?.value)

    }

    const render = () => {
        return <>
            <Subheader hideBackButton={true} title={t("Correspondences")}
                select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
            <div className={classes.root}>
                <Grid container className={classes.content} spacing={1}>
                    <Grid item xs={4}>
                        <SearchFilter value={searchText} placeholder={t("Search")}
                            handleChange={(value) => handleSearch(value)} />
                    </Grid>
                    <Grid item xs={8}>
                        <Box display={"flex"} justifyContent={"end"}>
                            <Stack direction="row" spacing={2}
                                divider={<Divider orientation="vertical" flexItem />}>
                                <IconButton onClick={() => setFilterDrawer(!filterDrawer)}
                                    className={classes.filterButton}>
                                    <Badge variant="dot" color="primary"
                                        invisible={!(filterData?.priority?.length > 0 ||
                                            filterData?.correspondence_type?.length > 0 ||
                                            filterData?.status?.length > 0 ||
                                            filterData?.level?.length > 0)}>
                                        <FilterIMG color="#091b29" />
                                    </Badge>
                                </IconButton>
                                {permission.create && <Button variant="contained" className={classes.button}
                                    onClick={handleAddCorrespondences}>
                                    {t("Create New")}
                                </Button>}
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TableWithPagination
                            heading={Heading(t)}
                            rows={list?.data}
                            path={Path}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            handleIcon={handleTableIcon}
                            onClick={() => console.log("")}
                            tableType="no-side"
                            dataType={Type}
                            handlePagination={handleTablePagination}
                            handleChangeLimit={handleTablePageLimit}
                            totalRowsCount={list?.totalRowsCount}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 290px)'}
                            view={permission.read}
                            edit={permission.update}
                            delete={permission.delete} />
                    </Grid>
                </Grid>
                <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                    onApply={(value) => applyFilter(value)}
                    components={[
                        {
                            component: "select",
                            value: filterData?.priority,
                            options: options?.urgent_type,
                            isMulti: true,
                            state_name: "priority",
                            label: t("Priority"),
                        },
                        {
                            component: "select",
                            value: filterData?.correspondence_type,
                            options: options?.correspondences_type_master,
                            isMulti: true,
                            state_name: "correspondence_type",
                            label: t("Correspondence Type"),
                        },
                        {
                            component: "select",
                            value: filterData?.status,
                            options: options?.correspondences_status_master,
                            isMulti: true,
                            state_name: "status",
                            label: t("Status"),
                        },
                    ]} />
                <Dialog
                    className={classes.dialog}
                    open={isAddDialogOpen}
                    onClose={() => setIsAddDialogOpen(false)}>
                    <div className={classes.addDialogHeader}>
                        <Typography className={classes.dialogHeaderTitle}>
                            {t("Create Correspondence")}
                        </Typography>
                        <IconButton onClick={() => setIsAddDialogOpen(false)}
                            className={classes.dialogCloseButton}>
                            <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                        </IconButton>
                    </div>
                    <div className={classes.addDialogBody}>
                        <Stack spacing={"24px"} divider={<Divider classes={{ root: classes.divider }} />}>
                            <Stack className={classes.level_card_div} direction={"row"} spacing={"48px"}
                                alignItems={"center"} justifyContent={"space-between"}
                                onClick={() => history.push({
                                    pathname: Routes.create_correspondences,
                                    state: {
                                        title: t("Create Unit Level Correspondence"),
                                        level: 1
                                    }
                                })}>
                                <Stack direction={"row"} spacing={"14px"} alignItems={"center"}>
                                    <UnitLevel />
                                    <Typography className={classes.level_card_text}>
                                        {t("Create Unit Level Correspondence")}
                                    </Typography>
                                </Stack>
                                <RightChevron />
                            </Stack>
                            <Stack className={classes.level_card_div} direction={"row"} spacing={"48px"}
                                alignItems={"center"} justifyContent={"space-between"}
                                onClick={() => history.push({
                                    pathname: Routes.create_correspondences,
                                    state: {
                                        title: t("Create Contact Level Correspondence"),
                                        level: 2
                                    }
                                })}>
                                <Stack direction={"row"} spacing={"14px"} alignItems={"center"}>
                                    <ContactLevel />
                                    <Typography className={classes.level_card_text}>
                                        {t("Create Contact Level Correspondence")}
                                    </Typography>
                                </Stack>
                                <RightChevron />
                            </Stack>
                        </Stack>
                    </div>
                </Dialog>
                <Dialog
                    className={classes.dialog}
                    open={isViewDialogOpen}
                    onClose={() => setIsViewDialogOpen(false)}>
                    <div className={classes.viewDialogHeader}>
                        <Typography className={classes.dialogHeaderTitle}>
                            {t("View Correspondence")}
                        </Typography>
                        <IconButton onClick={() => setIsViewDialogOpen(false)}
                            className={classes.dialogCloseButton}>
                            <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                        </IconButton>
                    </div>
                    <div className={classes.viewDialogBody}>
                        <Stack spacing={"20px"} divider={<Divider classes={{ root: classes.divider }} />}>
                            <Stack direction={"row"} spacing={"8px"} alignItems={"center"} justifyContent={"space-between"}>
                                <Stack spacing={"4px"}>
                                    <Typography className={classes.view_dialog_reference_no}>
                                        {viewDialogData?.reference_no}
                                    </Typography>
                                    <Typography className={classes.view_dialog_created}>
                                        {t("Created by") + " " +
                                            concat_string(
                                                {
                                                    name: viewDialogData?.created_person ?? "-",
                                                    date: viewDialogData?.created_at ?
                                                        timeZoneConverter(viewDialogData?.created_at, "DD MMM YY") : "-",
                                                },
                                                ["name", "date"],
                                                ", " + t("on") + " "
                                            )
                                        }
                                    </Typography>
                                </Stack>
                                {viewDialogData?.status_id === 1 &&
                                    <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
                                        <div className={classes.edit_button}
                                            onClick={() => history.push({
                                                pathname: Routes.edit_correspondences,
                                                state: {
                                                    title: t("Edit") + " " + viewDialogData?.reference_no,
                                                    level: viewDialogData?.level_id,
                                                    id: viewDialogData?.correspondence_id
                                                }
                                            })}>
                                            <Typography className={classes.edit_button_text}>
                                                {t("Edit")}
                                            </Typography>
                                        </div>
                                        <div className={classes.send_button}
                                            onClick={() => handleSend(viewDialogData?.correspondence_id)}>
                                            <Typography className={classes.send_button_text}>
                                                {t("Send")}
                                            </Typography>
                                        </div>
                                    </Stack>}
                            </Stack>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
                                    <ChatIcon />
                                    <Typography className={classes.replied_on_text}>
                                        {t("REPLIED ON") + " " +
                                            (viewDialogData?.replied_at ?
                                                timeZoneConverter(viewDialogData?.replied_at, "DD MMM YY") : "-")}
                                    </Typography>
                                </Stack>
                                <Typography className={classes.reply_text}>
                                    {viewDialogData?.comment ?? "-"}
                                </Typography>
                            </Stack>
                            {viewDialogData?.file_url && <div className={classes.pdf_viewer_div}>
                                <DocumentViewer url={viewDialogData?.file_url} />
                            </div>}
                        </Stack>
                    </div>
                </Dialog>
                <Dialog
                    className={classes.confirmSuccessDialog}
                    open={isConfirmDialogOpen}
                    onClose={() => setIsConfirmDialogOpen(false)}>
                    <div className={classes.confirmDialogComponent}>
                        <div className={classes.confirmSuccessDialogHeader}>
                            <IconButton onClick={() => setIsConfirmDialogOpen(false)}
                                className={classes.dialogCloseButton}>
                                <CloseIcon htmlColor="#FFFFFF" height="14px" width="14px" />
                            </IconButton>
                        </div>
                        <div className={classes.confirmDialogBody}>
                            <Stack spacing={"40px"} width={"300px"}>
                                <Typography className={classes.confirm_question_success_message_text}>
                                    {confirmDialogData?.question}
                                </Typography>
                                <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
                                    <div className={classes.yes_button}
                                        onClick={() => { !disableButton && confirmDialogData?.yes_action() }}>
                                        <Typography className={classes.yes_okay_button_text}>
                                            {t("Yes")}
                                        </Typography>
                                    </div>
                                    <div className={classes.no_button}
                                        onClick={() => { !disableButton && confirmDialogData?.no_action() }}>
                                        <Typography className={classes.no_button_text}>
                                            {t("No")}
                                        </Typography>
                                    </div>
                                </Stack>
                            </Stack>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    className={classes.confirmSuccessDialog}
                    open={isSuccessDialogOpen}
                    onClose={() => setIsSuccessDialogOpen(false)}>
                    <div className={classes.successDialogComponent}>
                        <div className={classes.confirmSuccessDialogHeader}>
                            <IconButton onClick={() => setIsSuccessDialogOpen(false)}
                                className={classes.dialogCloseButton}>
                                <CloseIcon htmlColor="#FFFFFF" height="14px" width="14px" />
                            </IconButton>
                        </div>
                        <div className={classes.successDialogBody}>
                            <Stack spacing={"40px"} width={"300px"} alignItems={"center"}>
                                <Typography className={classes.confirm_question_success_message_text}>
                                    {successDialogData?.success_message}
                                </Typography>
                                <div className={classes.okay_button}
                                    onClick={() => { !disableButton && successDialogData?.okay_action() }}>
                                    <Typography className={classes.yes_okay_button_text}>
                                        {t("Okay")}
                                    </Typography>
                                </div>
                            </Stack>
                        </div>
                    </div>
                </Dialog>
            </div>
        </>
    }

    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}
export default withNamespaces("correspondences")(Correspondences);