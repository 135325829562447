import {
    Avatar,
    Box,
    Grid,
    Typography
} from "@mui/material";
import React from 'react';
// import { useStyles } from '../../maintenancerequest/style'
import moment from "moment-timezone";
import { CasaGrandIcon } from '../../../assets';
import { Subheader } from "../../../components";
import { useStyles as useStyles1 } from './style';


export const MoveTo = ({ t, datas = {}, serviceDetails = "" }) => {
    // classes
    // const classes = useStyles()
    const classes1 = useStyles1()
    // track
    const track = [
        {
            date: datas?.data?.table?.[0]?.status?.[0]?.created_at,
            label: "Open",
            is_active: true,
        },
        {
            date: datas?.data?.table?.[0]?.status?.[0]?.closed_on,
            label: "Closed",
            is_active:
                datas?.data?.table?.[0]?.status?.[0]?.type === "Closed" ? true : false,
        },
    ];
    const card = ({ title = "", name = "", image = "", icon = "", subTitle = "", subTitle2 = "" }) => {
        return (

            <Box className={classes1.card}>
                <Typography className={classes1.cardDetails}>{title}</Typography>
                <Box height={'8px'} />
                <Box display={'flex'} alignItems="center">
                    {icon ?
                        <Box>{icon}</Box>
                        :
                        <Avatar src={image} alt="profile" width="40px" height="40px" variant="square" className={classes1.profilePic} />
                    }
                    <Box width={'11px'} />
                    <Box>
                        <Typography className={classes1.cardTitle}>{name}</Typography>
                        {subTitle2 ?
                            <Box display="flex" alignItems={"center"} justifyContent={'space-between'}>
                                <Typography className={classes1.cardSubTitle} noWrap>{subTitle}</Typography>
                                <Box className={classes1.dot} />
                                <Typography className={classes1.cardSubTitle} noWrap>{subTitle2}</Typography>

                            </Box>
                            :
                            <Typography className={classes1.cardSubTitle} noWrap>{subTitle}</Typography>
                        }
                    </Box>
                </Box>
            </Box>
        )
    }
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    {serviceDetails?.inspection?.manager_name &&
                        <Box>
                            {card({ title: "Manager Details", name: serviceDetails?.inspection?.manager_name, image: serviceDetails?.inspection?.manager_image, subTitle: serviceDetails?.inspection?.manager_mobile_no_country_code.concat(" ", serviceDetails?.inspection?.manager_mobile), subTitle2: serviceDetails?.inspection?.manager_email })}
                        </Box>
                    }
                    {serviceDetails?.resource?.length > 0 &&
                        <Box mt={2}>
                            {card({ title: "Assigned To", name: serviceDetails?.resource?.[0]?.first_name, image: serviceDetails?.resource?.[0]?.image_url, subTitle: serviceDetails?.resource?.[0]?.mobile_no_country_code.concat(" ", serviceDetails?.resource?.[0]?.mobile_no), subTitle2: serviceDetails?.resource?.[0]?.email_id })}
                        </Box>
                    }
                    {serviceDetails?.property?.name &&
                        <Box mt={2}>
                            {card({ title: "Property Details", name: serviceDetails?.property?.name, icon: <CasaGrandIcon />, subTitle: serviceDetails?.property?.property_no })}
                        </Box>
                    }
                </Grid>
                <Grid item xs={9}>
                    <Box className={classes1.timeLineBox}>
                        <Subheader
                            title={t("Track")}
                            hideBackButton
                        />
                        <div className={classes1.createnewsectionlog}>
                            <Grid container direction={"column"} spacing={1}>
                                {track
                                    ?.filter((i) => i?.is_active === true)
                                    .map((item, index, array) => {
                                        return (
                                            <Box className={classes1.treeBox}>
                                                {
                                                    item?.is_active &&
                                                    <Box className={classes1.border}>
                                                        <Avatar
                                                            variant="circular"
                                                            className={
                                                                classes1.avatars1
                                                            }
                                                        >
                                                            <Box className={classes1.image}>
                                                                <img
                                                                    src="images/Path 953.svg"
                                                                    alt="document"
                                                                />
                                                            </Box>

                                                        </Avatar>
                                                        <Box>
                                                            <Typography
                                                                className={classes1.title}
                                                            >
                                                                {item?.label}
                                                            </Typography>
                                                            <Typography
                                                                className={classes1.notesTimeLine}
                                                            >
                                                                Notes
                                                            </Typography>
                                                            <Box height={'4px'} />
                                                            <Typography
                                                                className={classes1.noteTimeLine}
                                                            >
                                                                -
                                                            </Typography>
                                                            <Box height={'4px'} />
                                                            <Typography
                                                                className={classes1.dateTime}
                                                            >
                                                                {moment(item.date)
                                                                    .tz(moment.tz.guess())
                                                                    .format("YYYY-MM-DD hh:mm A")}
                                                            </Typography>
                                                        </Box>
                                                        {
                                                            track?.length !== index + 1 && <Box height={'34px'} />
                                                        }
                                                    </Box>

                                                }


                                            </Box>
                                        )
                                    })}
                            </Grid>
                        </div>
                    </Box>
                </Grid>

            </Grid>

        </div>
    )
}

