/* eslint-disable array-callback-return */
import {
    Grid
} from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Subheader } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { accessCheckRender, AlertProps, getRoutePermissionNew, NetWorkCallMethods } from '../../utils';
import useWindowDimensions from "../../utils/useWindowDimensions";
import { OtherInfoCard } from './otherInfo';
import { ResidnetDetail } from './residnetDetails';
const getAllUnitids = (data) => {
    let list = [];
    if (data?.length > 0) {
        data.map(id => {
            list = [...list, ...id.units]
        })
    }
    return list;
}
export const ResidentTableView = props => {
    const size = useWindowDimensions();
    const history = useHistory()
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext)
    const [residentDetailsData, setResidentDetailsData] = React.useState([])
    const search = useLocation().search;
    const userId = new URLSearchParams(search).get("userId");
    const companyId = new URLSearchParams(search).get("companyId");
    const [permission, setPermission] = React.useState({})
    const allUnitid = getAllUnitids(residentDetailsData[0]?.agreementUnits);
    const agreementId = residentDetailsData[0]?.agreementUnits?.map((val) => val.id)

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getResidentDetailsById();
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getResidentDetailsById = () => {
        const payload = {
            tenantId: `${config.tenantId}`,
            companyId: Number(companyId),
            user_profile_id: userId
        };
        NetworkCall(
            `${config.api_url}/owner-resident/resident`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setResidentDetailsData(response?.data?.data);
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong. Please try again",
                });
            });

    };

    const render = () => {
        return <>
            <Subheader title={residentDetailsData?.[0]?.first_name} goBack={() => history.goBack()} />
            <br />
            <div style={{ height: size?.height - 200, overflow: "auto", padding: "4px 20px", margin: "-4px" }} >
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <ResidnetDetail data={residentDetailsData?.[0]} />
                    </Grid>
                    <Grid item xs={12}>
                        <OtherInfoCard allUnitid={allUnitid} agreementId={agreementId} unitId={residentDetailsData?.[0]?.unitId} />
                    </Grid>
                </Grid>

            </div>
        </>
    }

    return <>
        {accessCheckRender(render, permission)}
    </>

}