import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import React from 'react';
import { Bold } from '../../../utils';
import { CustomPaper, CustomTypography, TitleDesc } from '../components';
import { returnValue } from '../utils';

export const BankingDetailsView = (props) => {

    const theme = useTheme();

    const {
        bankingBasicDetails = null,
    } = props?.data ?? {};

    return (
        <div>
            <CustomPaper>
                <Grid container spacing={2} rowSpacing={3}>

                    <Grid item xs={12}>
                        <CustomTypography
                            fontSize={12}
                            fontFamily={Bold}
                            // marginBottom={theme?.spacing(2)}
                            color={theme?.typography?.color?.secondary}
                        >
                            Banking Basic Details
                        </CustomTypography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Account Type"}
                            desc={returnValue(bankingBasicDetails?.bankAccountType, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Account Number"}
                            desc={returnValue(bankingBasicDetails?.accountNumber, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Bank Name"}
                            desc={returnValue(bankingBasicDetails?.bankName, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Branch Name"}
                            desc={returnValue(bankingBasicDetails?.branchName, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Address 1"}
                            desc={returnValue(bankingBasicDetails?.addressLineOne, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Address 2"}
                            desc={returnValue(bankingBasicDetails?.addressLineTwo, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"City"}
                            desc={returnValue(bankingBasicDetails?.city, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Country"}
                            desc={returnValue(bankingBasicDetails?.country?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Bank Routing Type"}
                            desc={returnValue(bankingBasicDetails?.bankRoutingType?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Bank Routing Code"}
                            desc={returnValue(bankingBasicDetails?.bankRoutingCode, '-')}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Account Currency"}
                            desc={returnValue(bankingBasicDetails?.currency?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Preferred Cash Collection Office"}
                            desc={returnValue(bankingBasicDetails?.preferredCashCollectionOffice, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Cheque Name"}
                            desc={returnValue(bankingBasicDetails?.chequeName, '-')}
                        />
                    </Grid>


                </Grid>
            </CustomPaper>
        </div >
    )
}