import { Badge, Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { withNamespaces } from "react-i18next";
import { InspectionMappingComponentStyles } from './styles';


const PropertyDetailsCard = ({ t, title = "", data = [] }) => {
    const classes = InspectionMappingComponentStyles()
    return (
        <Grid container spacing={1}>
            <Grid item xs={1.5} className={classes.border}>
                <Box justifyContent="center">
                    <center>
                        <Badge className={classes.imageBadge}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            badgeContent={`${data.property_no ?? data?.unit_no}`}
                        >
                            <img className={classes.pdImage} src={data.image ?? data?.logo} alt='' />
                        </Badge>
                    </center>
                </Box>
            </Grid>
            <Grid item xs={10} >
                <Box marginLeft="8px">

                    <Typography className={classes.contentTitle}>{t(title)}</Typography>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} md={2}>
                            <Typography className={classes.propertyTitleCard}>Company Name</Typography>
                            <Typography className={classes.propertyContent}>{data?.company?.name ?? data?.company}</Typography>

                        </Grid>
                        {data?.propertyName &&
                            <Grid item xs={12} md={2}>
                                <Typography className={classes.propertyTitleCard}>Property Name</Typography>
                                <Typography className={classes.propertyContent}>{data?.propertyName}</Typography>

                            </Grid>
                        }

                        {
                            data?.property_groupByID?.group_name &&
                            <Grid item xs={12} md={2}>
                                <Typography className={classes.propertyTitleCard}>Property Type</Typography>
                                <Typography className={classes.propertyContent}>{data?.property_groupByID?.group_name}</Typography>

                            </Grid>
                        }

                        {
                            data?.property_purpose &&

                            <Grid item xs={12} md={2}>
                                <Typography className={classes.propertyTitleCard}>Property Purpose</Typography>
                                <Typography className={classes.propertyContent}>{data?.property_purpose}</Typography>

                            </Grid>
                        }



                        {data?.total_units?.[0]?.count_id &&
                            <Grid item xs={12} md={2}>
                                <Typography className={classes.propertyTitleCard}>Total Units</Typography>
                                <Typography className={classes.propertyContent}>{data?.total_units?.[0]?.count_id}</Typography>

                            </Grid>
                        }
                        {data?.unitName &&
                            <Grid item xs={12} md={2}>
                                <Typography className={classes.propertyTitleCard}>Unit Name</Typography>
                                <Typography className={classes.propertyContent}>{data?.unitName}</Typography>

                            </Grid>
                        }

                        <Grid item xs={12} md={2}>
                            <Typography className={classes.propertyTitleCard}>Status</Typography>
                            <Typography className={classes.propertyContent}>{data.is_active ? "Active" : "In Active"}</Typography>

                        </Grid>

                    </Grid>
                    {data?.description &&
                        <Grid spacing={1}>
                            <Typography className={classes.propertyTitleCard}>{title === "propertyDetails" ? "Property Description" : title === "unitDetails" ? "Unit Description" : ""}</Typography>
                            <span
                                className={classes.propertyContent}
                                dangerouslySetInnerHTML={{ __html: data?.description }}
                            />
                        </Grid>
                    }

                </Box>
            </Grid>
        </Grid>


    )
}

export default withNamespaces("inspection")(PropertyDetailsCard)