import React, { useLayoutEffect, useState } from "react";

import {
  staffServicePath,
  staffServiceHead,
  NetWorkCallMethods,
  AlertProps,
} from "../../utils";
import { AlertContext } from "../../contexts";
import { GraphComponent } from "../propertymanagerdashboard/components";

import { NetworkCall } from "../../networkcall";
import { config } from "../../config";

export const GateInSecurity = (props) => {
  // classes
  const [security, setSecurity] = useState([]);
  const [page1, setPage1] = useState(1);
  const [limit1, setLimit1] = useState(10);
  // context
  const alert = React.useContext(AlertContext);

  //Gates In Change
  const getSecurity = () => {
    const payload = {
      company_id: props?.company?.value,
      offset: 0,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/dashboard/security/staff`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        let result = res?.data?.data?.map((x) => {
          return {
            Image: x?.image_url ?? "-",
            Name: x?.name ?? "-",
            Number: `${x?.mobile_no_country_code} ${x?.mobile_no}`,
            Type: x?.role ?? "",
            // App:""
          };
        });
        setSecurity({
          data: result ?? [],
          count: res?.data?.count ?? 0,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  //more options
  const handlePagination1 = (value) => {
    setPage1(value);
    let offset = (value - 1) * limit1;
    getSecurity(offset, limit1);
  };
  const handleChangeLimit1 = (value) => {
    setLimit1(value);
    setPage1(1);
    getSecurity(0, value);
  };

  useLayoutEffect(() => {
    if (props?.company?.label?.length > 0) {
      getSecurity(0, 10);
    }
    //eslint-disable-next-line
  }, [props?.company, props?.calandervalue]);
  return (
    <>
      {/* Entries By Gates */}
      <GraphComponent
        isTable
        title={"Security Staff In Service"}
        tableData={security?.data ?? []}
        tabelheight={"calc(100vh - 400px)"}
        heading={staffServiceHead}
        path={staffServicePath}
        showpagination
        page={page1}
        limit={limit1}
        handlePagination={handlePagination1}
        handleChangeLimit={handleChangeLimit1}
        totalRowsCount={security?.count ?? 0}
        dataType={[
          { type: ["avatarmanagement"], name: "Image" },
          { type: ["text"], name: "Name" },
          { type: ["text"], name: "Number" },
          { type: ["text"], name: "Type" },
          // { type: ["text"], name: "App" },
        ]}
      />
    </>
  );
};
