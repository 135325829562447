import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { AlertDialog, DetailsCardCoponent } from "../../../components";
import { UPDATE_PAYMENT_PEROID } from "../../../graphql/agreementQurery";
import { enumSelect, enum_types } from "../../../utils";
import { getPaymentScheduleHeading, paymentmethoddataType, paymentmethodPath } from "../../../utils/agreementUtils";
import { PaymentMethodCard, PaymentScheduleAccordion } from "../components";
const BillingInformation = ({ details = {}, id = "", reload = () => false, agree = {}, t }) => {

    const client = useApolloClient();
    const paymentHeading = getPaymentScheduleHeading(t)
    const [open, setOpen] = React.useState(false)
    const [enumValue, setEnumValue] = React.useState({
        payment_method: []
    })
    //get Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.payment_mode])
        setEnumValue({
            payment_method: result?.payment_mode
        })
    }
    React.useEffect(() => {
        getEnum()
    }, [])
    const card = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("billingCycle"),
            subtitle: `${details?.bill_generation}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("billingCycleDate"),
            subtitle: `${details?.billing_cycle_date}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("billingStartDate"),
            subtitle: `${details?.billing_start_date}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("agreementPaymentTerms"),
            subtitle: `${details?.company?.payment_terms}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "button",
            heading: `${t("methodOfPayment")}  (${agree?.payment_mode})`,
            subtitle: `+ ${t("paymentMethod")}`,
            onClick: () => setOpen(true)
        },

    ]
    //on select payment method
    const onSelectPayment = (value) => {
        client.mutate({
            mutation: UPDATE_PAYMENT_PEROID,
            variables: {
                id: agree?.id,
                payload: {
                    payment_mode: value
                }
            }
        }).then((response) => {
            reload()
            setOpen(false)
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <Box p={2}>
            <DetailsCardCoponent components={card} />
            <Box height="12px" />
            <PaymentScheduleAccordion
                type={paymentmethoddataType}
                heading={paymentHeading}
                row={details?.quotation_payment_schedule}
                path={paymentmethodPath}
                title={`${t("paymentSchedule")} (${details?.quotation_payment_schedule?.length})`} />
            <AlertDialog
                isNormal={true}
                open={open}
                onClose={() => setOpen(false)}
                header={t("paymentMethod")}
                component={
                    <Box p={2}>
                        {
                            enumValue?.payment_method?.map((val) => {
                                return (
                                    <PaymentMethodCard onClick={onSelectPayment} value={agree?.payment_mode} paymentMethod={val} />
                                )
                            })
                        }
                    </Box>
                }
            />

        </Box>
    )
}
export default withNamespaces("agreement")(BillingInformation); 