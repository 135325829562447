import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../../utils";
import styled from "@mui/material/styles/styled";
import { Typography } from "@mui/material";

export const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: `0px 0px 16px #00000014`,
    borderRadius: '4px',
    backgroundColor: "white"
  },
  paddingLeft: {
    paddingLeft: (props) => props?.type ? '4px !important' : '41px !important',
    paddingTop: "19px"
  },
  detailsBox: {
    borderTop: `1px solid ${theme?.palette?.border?.secondary}`,
    borderBottom: `1px solid ${theme?.palette?.border?.secondary}`,
    padding: '12px'
  },
  boxHeight: {
    height: 'calc(100vh - 263px)',
    overflow: "auto",
    borderRight: `1px solid ${theme?.palette?.border?.secondary}`
  },
  notes: {
    fontSize: "12px",
    fontFamily: SemiBold,
    color: theme?.typography?.color?.secondary,
  },
  title: {
    fontSize: "16px",
    fontFamily: Bold,
    color: theme?.typography?.color?.primary,
  },
  des: {
    fontSize: "10px",
    fontFamily: SemiBold,
    color: theme?.palette?.background?.announsment,
  },
  btn: {
    fontFamily: SemiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    width: '100%',
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
      color: theme?.palette?.background?.tertiary1,
    },
  },
  btnParent: {
    padding: "20px 20px 20px 0px",
  },
  convayTitle: {
    color: theme?.palette?.border?.status,
    fontSize: "15px",
    fontFamily: Bold,
  },
  status: {
    color: theme?.typography?.color?.tertiary,
    fontSize: "14px",
    fontFamily: SemiBold,
  },
  subStatus: {
    color: theme?.palette?.background?.reservation,
    fontSize: "14px",
    fontFamily: SemiBold,
  },
  statusBox: {
    borderRadius: "4px",
    backgroundColor: theme?.palette?.info?.light,
    padding: "16px"
  },
  card: {
    padding: "10px",
    borderRadius: "4px",
    boxShadow: `0px 0px 16px #00000014`,
    backgroundColor: "white"
  },
  cardDetails: {
    color: theme?.palette?.background?.primary,
    fontSize: "10px",
    fontFamily: Bold
  },
  cardTitle: {
    color: theme?.typography?.color?.primary,
    fontSize: "14px",
    fontFamily: SemiBold
  },
  cardSubTitle: {
    color: theme?.typography?.color?.tertiary,
    fontSize: "12px",
    fontFamily: Regular
  },
  dot: {
    backgroundColor: theme?.typography?.color?.tertiary,
    borderRadius: "50%",
    height: "7px",
    width: "7px",
    margin: "0 7px"
  },
  timeLineBox: {
    boxShadow: `0px 0px 16px #00000014`,
    borderRadius: "4px",
    marginTop: "5px"
  },
  profilePic: {
    borderRadius: theme.palette.borderRadius,
  },
  createnewsectionlog: {
    padding: "30px 20px 30px 41px",
    height: `calc(100vh - 310px)`,
    overflow: "auto",
    backgroundColor: "white",
  },
  notesTimeLine: {
    color: theme?.palette?.background?.primary,
    fontSize: "12px",
    fontFamily: SemiBold
  },
  noteTimeLine: {
    color: theme?.typography?.color?.secondary,
    fontSize: "14px",
    fontFamily: SemiBold,
    maxWidth: "624px",
    width: '100%',
    textAlign: "left"
  },
  border: {
    borderLeft: `2px dashed ${theme?.palette?.primary?.main}`,
    padding: "0px 40px",
    position: "relative",
    // "&:last-child": {
    //     borderLeft: "none",
    // },
  },
  avatars1: {
    position: "absolute",
    top: 0,
    left: '-20px',
    height: "45px",
    width: "45px",
    backgroundColor: "#F2F4F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px"
  },
  image: {
    backgroundColor: theme?.palette?.primary?.main,
    borderRadius: "50%",
    padding: "10px 9px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  createnewsectionlog1: {
    padding: "14px 20px 30px 26px",
    height: `calc(100vh - 394px)`,
    overflow: "auto",
    backgroundColor: "white",
  },
  dateTime: {
    fontSize: "12px",
    fontFamily: SemiBold,
    color: "#7C8594",
  },
  innerImage: {
    height: "100%",
    width: "100%",
    backgroundColor: theme?.typography?.status?.primary,
  }
}));
export const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontFamily: Bold,
  color: "#4E5A6B",
  marginBottom: theme.spacing(1),
}));

