import { Box, Grid } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { SearchFilter, TableWithPagination } from "../../../components";
import { getPaymentScheduleHeading, paymentmethoddataType, paymentmethodPath } from "../../../utils/agreementUtils";

const ManagedInvoiceTable = ({
    handleSearch = () => false,
    searchText = "",
    list = [],
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    setSelectedInvoices = () => false,
    setData = () => false,
    data = {},
    t
}) => {
    const onSelectInvoice = (val) => {
        setSelectedInvoices(val)
        setData({
            ...data,
            cheque_name: val?.payment_schedule_cheques?.[0]?.cheque_name ?? "",
            bank_name: val?.payment_schedule_cheques?.[0]?.bank_name ?? "",
            id: val?.payment_schedule_cheques?.[0]?.id
        })
    }
    const paymentHeading = getPaymentScheduleHeading(t)
    return (
        <Box p={2}>
            <Grid container>
                <Grid item xs={6}>
                    <SearchFilter value={searchText} handleChange={(e) => handleSearch(e)} placeholder="Search Managed Invoices" />
                </Grid>
            </Grid>
            <TableWithPagination
                heading={paymentHeading}
                rows={list?.list}
                path={paymentmethodPath}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                showpagination={true}
                onClick={onSelectInvoice}
                dataType={paymentmethoddataType}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={list?.count}
                page={page}
                limit={limit}
                height={`calc(100vh - 400px)`}
                view={true}
                edit={true}
                delete={true} />

        </Box>
    )
}
export default withNamespaces("agreement")(ManagedInvoiceTable); 