import CloseIcon from '@mui/icons-material/Close';
import TopicSharpIcon from '@mui/icons-material/TopicSharp';
import {
    Box, Dialog,
    Drawer, Grid, Hidden, IconButton, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { UploadReports } from '../../components';
import { config } from "../../config";
import { AlertContext } from '../../contexts';
import { NetworkCall } from "../../networkcall";
import { AlertProps, ExtraBold, LocalStorageKeys, NetWorkCallMethods, SemiBold, Template } from '../../utils';
import { Bold } from '../../utils';
const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        height: `calc(100vh - 88px)`,
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px"
    },
    content: {
        // height: `calc(100vh - 74px)`,
        // border: "2px solid white",
        // borderRadius: "12px",
        // boxShadow: "0px 0px 16px #00000014",
        // backgroundColor: "white",
        // padding: "16px"
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
        // borderColor: theme.palette.secondary.main,
    },
    text: {
        fontSize: "24px",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    subText: {
        fontSize: "16px",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    bottomTitle: {
        fontSize: "18px",
        fontFamily: ExtraBold

    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        marginBottom: "12px"
    },
    contentBottom: {
        // padding: "12px",

    },
    drawer: {
        "& .MuiDrawer-paper": {

            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px"
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
        },
    },
    grid: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    title: {
        fontSize: "14px",
        fontFamily: Bold,
        color: theme.typography.color.primary

    },
    subTitle: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: theme.palette.primary.main,
        cursor: "pointer",
        display: "inline-flex",
        padding: "0px 4px",
        borderRadius: theme.palette.borderRadius,
        "&:hover": {
            background: "rgb(0 0 0 / 4%)",
        }
    }
}))

export const MasterUploadModal = (props) => {
    const alert = React.useContext(AlertContext);
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);

    const [uploadEndPoint, setUploadEndPoint] = React.useState("");

    React.useEffect(() => {
        if (props.docsType === "Property" || props.docsType === "Block" || props.docsType === "Floor" || props.docsType === "Unit") {
            setUploadEndPoint("/masters/bulk/upload");
        } else {
            setUploadEndPoint("/masters/bulk/upsert");
        }
        // eslint-disable-next-line
    }, []);

    const setShowDrawer = (val) => {
        props.closeModal(val);
    }

    const xlDownload = () => {

        let type = "";

        if (props.docsType === "Property" || props.docsType === "Block" || props.docsType === "Floor" || props.docsType === "Unit") {
            type = props.docsType.toLocaleLowerCase();
        } else if (props.docsType === "General") {
            type = "general_category_master";
        } else if (props.docsType === "General Sub-Category") {
            type = "general_sub_category_master";
        } else if (props.docsType === "Maintenance") {
            type = "maintenance_category_master";
        } else if (props.docsType === "Maintenance Sub-Category") {
            type = "maintenance_sub_category_master";
        } else if (props.docsType === "Tax Group") {
            type = "vat_group_master";
        } else if (props.docsType === "Tax Item") {
            type = "vat_group_item_master";
        } else if (props.docsType === "Locality") {
            type = "cities_area_master";
        } else if (props.docsType === "City") {
            type = "cities_master";
        } else if (props.docsType === "Activity") {
            type = "lead_activity_master";
        } else if (props.docsType === "Activity Category") {
            type = "activity_category_master";
        } else if (props.docsType === "Activity Sub-Category") {
            type = "activity_sub_category_master";
        } else if (props.docsType === "Emergency Numbers") {
            type = "emergency_master";
        } else if (props.docsType === "Profession Masters") {
            type = "profession_master";
        } else if (props.docsType === "Property Type") {
            type = "property_group_master";
        } else if (props.docsType === "Ameneties") {
            type = "amenities_type_master";
        } else {
            type = props.docsType?.replaceAll("-", " ")?.replaceAll(" ", "_")?.toLocaleLowerCase() + "_master";
        }

        let xlDoc = Template[type];
        if (xlDoc) {
            const a = document.createElement('a')
            a.href = xlDoc
            a.download = xlDoc.split('/').pop()
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "No templates is available",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        }
    }

    const getUploadedXl = (data) => {
        JSON.stringify("dfghjkl.----------", data);
    }

    const uploadfile = async (files) => {
        setLoading(true)
        let getFiles = files;

        let type = "";

        if (props.docsType === "Property" || props.docsType === "Block" || props.docsType === "Floor" || props.docsType === "Unit") {
            type = props.docsType.toLocaleLowerCase();
        } else if (props.docsType === "General") {
            type = "general_category_master";
        } else if (props.docsType === "General Sub-Category") {
            type = "general_sub_category_master";
        } else if (props.docsType === "Maintenance") {
            type = "maintenance_category_master";
        } else if (props.docsType === "Maintenance Sub-Category") {
            type = "maintenance_sub_category_master";
        } else if (props.docsType === "Tax Group") {
            type = "vat_group_master";
        } else if (props.docsType === "Tax Item") {
            type = "vat_group_item_master";
        } else if (props.docsType === "Locality") {
            type = "cities_area_master";
        } else if (props.docsType === "City") {
            type = "cities_master";
        } else if (props.docsType === "Activity") {
            type = "lead_activity_master";
        } else if (props.docsType === "Activity Category") {
            type = "activity_category_master";
        } else if (props.docsType === "Activity Sub-Category") {
            type = "activity_sub_category_master";
        } else if (props.docsType === "Emergency Numbers") {
            type = "emergency_master";
        } else if (props.docsType === "Profession Masters") {
            type = "profession_master";
        } else if (props.docsType === "Property Type") {
            type = "property_group_master";
        } else if (props.docsType === "Ameneties") {
            type = "amenities_type_master";
        } else {
            type = props.docsType?.replaceAll("-", " ")?.replaceAll(" ", "_")?.toLocaleLowerCase() + "_master";
        }
        const formData = new FormData();
        formData.append("files", getFiles[0].data);
        formData.append("tenantId", `${config.tenantId}`);
        formData.append("userId", localStorage.getItem(LocalStorageKeys.profileID));
        formData.append("masterType", type);
        await NetworkCall(
            `${config.api_url}${uploadEndPoint}`,
            NetWorkCallMethods.post,
            formData,
            { Accept: "*/*" },
            true,
            false
        )
            .then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "New " + props.docsType + "s Created!",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
                props.closeModal(false, "refresh");
                setLoading(false)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: error?.response?.data?.errors?.errors?.[0]?.msg ? error?.response?.data?.errors?.errors?.[0]?.msg : "Something went wrong try again",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
                setLoading(false)

            });

    }

    return (
        <>
            <Hidden smUp>
                <Drawer
                    className={classes.drawer}
                    open={props?.open}
                    anchor="bottom"
                    onClose={() => setShowDrawer(false)}>
                    <div className={classes.contentBottom}>
                        <Box display="flex" p={1} alignItems="center" className={classes.titleroot}>
                            <Box flexGrow={1}>
                                <Typography className={classes.bottomTitle}>
                                    {"Add Bulk " + props?.docsType}
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton size="small" onClick={() => setShowDrawer(false)}>
                                    <CloseIcon color="secondary" />
                                </IconButton>
                            </Box>
                        </Box>
                        <Grid container>
                            <Grid item xs={6} sx={{ padding: "16px", borderRight: "1px solid #c1c1c1" }}>
                                <Box display={"flex"}
                                    alignItems={"center"}
                                    className={classes.grid}
                                >
                                    <Box >
                                        <TopicSharpIcon />
                                    </Box>

                                    <Box flexGrow={1} marginLeft={"6px"}>
                                        <Typography className={classes.title}>
                                            Bulk Upload Template
                                        </Typography>
                                        <Typography className={classes.subTitle} onClick={() => xlDownload()}>
                                            Download
                                        </Typography>
                                    </Box>

                                </Box>
                                <br />
                                {/* <Box display={"flex"}
                                alignItems={"center"}
                                className={classes.grid}
                            >
                                <Box >
                                    <TopicSharpIcon />
                                </Box>

                                <Box flexGrow={1} marginLeft={"6px"}>
                                    <Typography className={classes.title}>
                                        Bulk Asset Manager
                                    </Typography>
                                    <Typography className={classes.subTitle}>
                                        Go to Link
                                    </Typography>
                                </Box>

                            </Box> */}

                            </Grid>
                            <Grid item xs={6} sx={{ padding: "12px" }}>
                                <UploadReports loading={loading} onChange={getUploadedXl} uploadfile={uploadfile} />
                            </Grid>
                        </Grid>
                    </div>
                </Drawer>
            </Hidden>

            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    fullWidth={true}
                    maxWidth="sm"
                    open={props?.open}>
                    <div className={classes.contentBottom}>
                        <Box display="flex" p={1} alignItems="center" className={classes.titleroot}>
                            <Box flexGrow={1}>
                                <Typography className={classes.bottomTitle}>
                                    {"Add Bulk " + props?.docsType}
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton size="small" onClick={() => setShowDrawer(false)}>
                                    <CloseIcon color="secondary" />
                                </IconButton>
                            </Box>
                        </Box>
                        <Grid container>
                            <Grid item xs={6} sx={{ padding: "16px", borderRight: "1px solid #c1c1c1" }}>
                                <Box display={"flex"}
                                    alignItems={"center"}
                                    className={classes.grid}
                                >
                                    <Box >
                                        <TopicSharpIcon />
                                    </Box>

                                    <Box flexGrow={1} marginLeft={"6px"}>
                                        <Typography className={classes.title}>
                                            Bulk Upload Template
                                        </Typography>
                                        <Typography className={classes.subTitle} onClick={() => xlDownload()}>
                                            Download
                                        </Typography>
                                    </Box>

                                </Box>
                                <br />
                                {/* <Box display={"flex"}
                                alignItems={"center"}
                                className={classes.grid}
                            >
                                <Box >
                                    <TopicSharpIcon />
                                </Box>

                                <Box flexGrow={1} marginLeft={"6px"}>
                                    <Typography className={classes.title}>
                                        Bulk Asset Manager
                                    </Typography>
                                    <Typography className={classes.subTitle}>
                                        Go to Link
                                    </Typography>
                                </Box>

                            </Box> */}

                            </Grid>
                            <Grid item xs={6} sx={{ padding: "12px" }}>
                                <UploadReports loading={loading} onChange={getUploadedXl} uploadfile={uploadfile} />
                            </Grid>
                        </Grid>
                    </div>

                </Dialog>
            </Hidden>
        </>
    )
}