import moment from "moment"
import { Account, General, Tools, Transport, User } from "../assets/resource_type_images"
export const CalendarWeekData = [
    {
        name: "Debra Ortiz",
        role: "Role Here",
        image: "https://thumbs.dreamstime.com/b/closeup-photo-funny-excited-lady-raise-fists-screaming-loudly-celebrating-money-lottery-winning-wealthy-rich-person-wear-casual-172563278.jpg",
        progress: [
            {
                date: "2022-08-16",
                progress_value: "30",
                id: 1
            },
            {
                date: "2022-08-17",
                progress_value: "60",
                id: 2
            },
            {
                date: "2022-08-18",
                progress_value: "35",
                id: 3
            },
            {
                date: "2022-08-19",
                progress_value: "90",
                id: 4
            },
            {
                date: "2022-08-20",
                progress_value: "70",
                id: 5
            },
            {
                date: "2022-08-21",
                progress_value: "21",
                id: 6
            },
            {
                date: "2022-08-22",
                progress_value: "21",
                id: 7
            }
        ]
    },
    {
        name: "Debra Ortiz",
        role: "Role Here",
        image: "https://thumbs.dreamstime.com/b/closeup-photo-funny-excited-lady-raise-fists-screaming-loudly-celebrating-money-lottery-winning-wealthy-rich-person-wear-casual-172563278.jpg",
        progress: [
            {
                date: "2022-08-16",
                progress_value: "30",
                id: 8
            },
            {
                date: "2022-08-17",
                progress_value: "60",
                id: 9
            },
            {
                date: "2022-08-18",
                progress_value: "35",
                id: 10
            },
            {
                date: "2022-08-19",
                progress_value: "90",
                id: 11
            },
            {
                date: "2022-08-20",
                progress_value: "70",
                id: 12
            },
            {
                date: "2022-08-21",
                progress_value: "21"
            },
            {
                date: "2022-08-22",
                progress_value: "21",
                id: 13
            }
        ]
    }

]

export const CalendarDayData = {
    name: "Debra Ortiz",
    role: "Role Here",
    image: "https://thumbs.dreamstime.com/b/closeup-photo-funny-excited-lady-raise-fists-screaming-loudly-celebrating-money-lottery-winning-wealthy-rich-person-wear-casual-172563278.jpg",
    progress: "60",
    data: [
        {
            date: "01:00",
            events: [
                {
                    ins: "INS-431564",
                    status: "Move In",
                    module: "Inspection Module"
                },
                {
                    ins: "INS-431564",
                    status: "Move Out",
                    module: "General Inspection"
                },
                {
                    ins: "INS-431564",
                    status: "Move Out",
                    module: "General Inspection",
                }
            ]
        },
        {
            date: "04:00",
            events: [
                {
                    ins: "INS-431564",
                    status: "Meter Reading",
                    module: "General Inspection",
                    assigned_by: "Doris Hughes",
                    empname: "Bianca Porter",
                    role: "Employee role here",
                    image: "https://thumbs.dreamstime.com/b/closeup-photo-funny-excited-lady-raise-fists-screaming-loudly-celebrating-money-lottery-winning-wealthy-rich-person-wear-casual-172563278.jpg"
                }
            ]
        },
    ]
}


export const returnCalendarDate = (type, data) => {

    switch (type) {
        case "Tools":
            let result1 = data?.map((val) => {
                return {
                    id: val?.id,
                    name: val?.tool_name,
                    image: val?.url ?? null,
                    sub_title: val?.department,
                    sub_title1: val?.reference_id,
                    progress: val?.progress,
                    week_off_days:[]

                }
            })
            return result1

        case "Vehicles":
            let result2 = data?.map((val) => {
                return {
                    id: val?.id,
                    name: val?.vehicles_name,
                    image: val?.url ?? null,
                    sub_title: val?.department,
                    sub_title1: val?.reference_id,
                    progress: val?.progress,
                    week_off_days:[]


                }
            })
            return result2

        case "Generic":
            let result3 = data?.map((val) => {
                return {
                    id: val?.id,
                    name: val?.name,
                    image: val?.url ?? null,
                    sub_title: val?.department,
                    sub_title1: val?.reference_id,
                    progress: val?.progress,
                    week_off_days:[]


                }
            })
            return result3

        case "Users":
            let result4 = data?.map((val) => {
                return {
                    id: val?.id,
                    name: val?.contact_name,
                    image: val?.url ?? null,
                    sub_title: val?.department,
                    sub_title1: val?.reference_id,
                    progress: val?.progress,
                    week_off_days:val?.week_off_days
                }
            })
            return result4;

        case "Account":
            let result5 = data?.map((val) => {
                return {
                    id: val?.id,
                    name: val?.account_name,
                    image: val?.account_image ?? null,
                    sub_title: val?.department,
                    sub_title1: val?.reference_id,
                    progress: val?.progress,
                    week_off_days:[]
                }
            })
            return result5;

        default:
            return []
    }
}

export const filterByReference = (arr1, arr2) => {
    const result = arr1.filter(o1 => arr2.some(o2 => moment(o1).format("HH") === moment(o2.date).format("HH")));
    const hours = result?.map((val) => val)
    return [...new Set(hours)]
}

export const returnTypeImage = (type) => {

    switch (type) {
        case "Tools":
            return {
                image: <Tools />,
                color: "#EDE4FE80"
            }

        case "Vehicles":

            return {
                image: <Transport />,
                color: "#FFF8EB"
            }

        case "Generic":
            return {
                color: "#E4FEE680",
                image: <General />
            }
        case "Users":

            return {
                color: "#F1F7FF",
                image: <User />
            }

        case "Account":
            return {
                color: "#FFECEC",
                image: <Account />
            }

        default:
            return []
    }
}