import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import React from 'react';
import { Bold, enumName } from '../../../utils';
import { CustomPaper, CustomTypography, TitleDesc } from '../components';
import { returnValue } from '../utils';

export const CompanyConfigurationView = (props) => {

    const theme = useTheme();

    const {
        initialConfig = null,
        pricing = null,
        paymentGatewayConfiguration = null,
    } = props?.data ?? {};

    const typeCheck = (val) => typeof val === "boolean"
    return (
        <div>
            <CustomPaper marginBottom={theme.spacing(3)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTypography
                            fontSize={12}
                            fontFamily={Bold}
                            // marginBottom={theme?.spacing(2)}
                            color={theme?.typography?.color?.secondary}
                        >
                            INITIAL CONFIG
                        </CustomTypography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Base Currency"}
                            desc={returnValue(initialConfig?.currency?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Time Zone"}
                            desc={initialConfig?.timezone ?? "-"}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Default UOM"}
                            desc={returnValue(initialConfig?.uom?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Lease Period"}
                            desc={returnValue(initialConfig?.leasePeriod?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Show Refer & Win"}
                            desc={typeCheck(initialConfig?.referAndWin) ? initialConfig?.referAndWin ? 'Yes' : 'No' : '-'}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Show Promotional Banners"}
                            desc={typeCheck(initialConfig?.promotionalBanners) ? initialConfig?.promotionalBanners ? 'Yes' : 'No' : '-'}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Grace Period"}
                            desc={initialConfig?.gracePeriod >= 0 ? initialConfig?.gracePeriod : "-"}
                        />
                    </Grid>
                </Grid>
            </CustomPaper>
            <CustomPaper marginBottom={theme.spacing(3)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTypography
                            fontSize={12}
                            fontFamily={Bold}
                            // marginBottom={theme?.spacing(2)}
                            color={theme?.typography?.color?.secondary}
                        >
                            PRICING
                        </CustomTypography>
                    </Grid>
                    {/* <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Pricing Method"}
                            desc={returnValue(pricing?.pricingMethod?.label,'-')}
                        />
                    </Grid> */}
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Default Payment Period"}
                            desc={returnValue(pricing?.paymentPeriod?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Enable Promotional Pricing Options"}
                            desc={typeCheck(pricing?.priceOverride) ? pricing?.priceOverride ? 'Yes' : 'No' : '-'}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Short Term Pricing"}
                            desc={typeCheck(pricing?.shortTermPricing) ? pricing?.shortTermPricing ? 'Yes' : 'No' : '-'}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Payment Terms"}
                            desc={returnValue(pricing?.paymentTerm?.label, '-')}
                        />
                    </Grid>
                    {pricing?.paymentTerm?.value === enumName.userDefined && <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Payment Terms Value"}
                            desc={returnValue(pricing?.paymentTermValue, '-')}
                        />
                    </Grid>}
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Billing Cycle"}
                            desc={returnValue(pricing?.billing_cycle, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Billing Cycle Generation Date"}
                            desc={returnValue(pricing?.billing_cycle_date, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Quotation Validity"}
                            desc={pricing?.quote_validity_value ? returnValue(pricing?.quote_validity_value, '-') + " Days" : "None"}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Reservation Validity"}
                            desc={pricing?.reservation_validity_value ? returnValue(pricing?.reservation_validity_value, '-') + " Days" : "None"}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Auto Reservation Calculation"}
                            desc={pricing?.auto_reservation_calc ? "True" : "false"}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Auto Deposit Calculation"}
                            desc={pricing?.auto_deposit_calc ? "True" : "false"}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Auto Deposit Calculation"}
                            desc={pricing?.is_storage_offering ? "True" : "false"}
                        />
                    </Grid>
                </Grid>
            </CustomPaper>
            <CustomPaper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTypography
                            fontSize={12}
                            fontFamily={Bold}
                            // marginBottom={theme?.spacing(2)}
                            color={theme?.typography?.color?.secondary}
                        >
                            PAYMENT GATEWAY
                        </CustomTypography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Payment Gateway"}
                            desc={returnValue(paymentGatewayConfiguration?.paymentGateway?.label, '-')}
                        />
                    </Grid>
                    {
                        paymentGatewayConfiguration?.paymentGateway?.value === "Solution Provider" &&
                        <>
                            <Grid item xs={6} md={3}>
                                <TitleDesc
                                    title={"Login Name"}
                                    desc={returnValue(paymentGatewayConfiguration?.loginName, '-')}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TitleDesc
                                    title={"Login Password"}
                                    desc={returnValue(paymentGatewayConfiguration?.loginPassword, '-')}
                                    index={'1'}
                                    password
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TitleDesc
                                    title={"Login Token"}
                                    desc={returnValue(paymentGatewayConfiguration?.loginToken, '-')}
                                    index={'2'}
                                    password
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TitleDesc
                                    title={"Payment Provider Name"}
                                    desc={returnValue(paymentGatewayConfiguration?.paymentProviderName, '-')}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TitleDesc
                                    title={"Url"}
                                    desc={returnValue(paymentGatewayConfiguration?.url, '-')}
                                />
                            </Grid>
                        </>
                    }
                </Grid>
            </CustomPaper>


        </div >
    )
}