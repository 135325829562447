import gql from "graphql-tag";
export let GET_PROPERTY = gql`
query {
    property(where: { company_id: $company_id ,is_active: true, id:{in:$property}}) {
      value: id
      label: name
    }
  }
`;

export let GET_PROPERTY_BYCOMPANY =gql`
query {
  property(where: { company_id:{in:$company_id} ,is_active: true}) {
    value: id
    label: name
  }
}
`