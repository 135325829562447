export const Routes = {
  propertycreation: "/propertycreation",
  propertyview: "/propertyview",
  blockView: "/blockview",
  home: "/",
  login: "/login",
  otp: "/otp",
  forgotPassword: "/forgotpassword",
  properties: "/properties",
  addProperty: "/addproperty",
  addBlock: "/addblock",
  addFloor: "/addfloor",
  addUnit: "/addunit",
  createdFloor: "/createdfloor",
  propertyDetails: "/propertydetails",
  blockDetails: "/blockdetails",
  propertyFinder: "/propertyfinder",
  propertyFinderDetails: "/propertyfinderdetails",
  shortListedUnits: "/shortlistedunits",
  createQuotation: "/createquotation",
  leads: "/leads",
  leadsDetails: "/leadsdetails",
  businessMaster: "/businessmaster",
  servicesMaster: "/servicesmaster",
  quotation: "/quotation",
  quotation_view: "/quotationDetails",
  resetPassword: "/resetpassword",
  unitTable: "/unittable",
  unitTableDetails: "/unittabledetails",
  upload: "/upload",
  agreementUnitAccess: "/agreementUnitAccess",
  request: "/request",
  residentTable: "/residenttable",
  residentTableDetails: "/residenttabledetails",
  ownerTable: "/ownertable",
  ownerTableDetails: "/ownertabledetails",
  announcement: "/announcement",
  createannounsment: "/createannounsment",
  block: "/block",
  floor: "/floor",
  unit: "/unit",
  unitDetails: "/unitDetails",
  propertyResult: "/result",
  floorDetails: "/floorDetails",
  residentTableDetail: "/residentdetails",
  ownerTableDetail: "/ownerdetails",
  unitrentallbreakup: "/unitrentallbreakup",
  unitrentallbreakupcreate: "/unitrentallbreakupcreate",
  unitrentallbreakupuntit: "/unitrentallbreakupuntit",
  shortlisted: "/shortlistedunits",
  shortlistLead: "/shortlistLead",
  utilities: "/utilities",
  quotationsList: "/quotationsList",
  utilitiesMapping: "/utilitiesMapping",
  utilitiesMappingPropertyView: "/utilitiesMapping/propertyView",
  utilitiesMappingUnitView: "/utilitiesMapping/unitView",
  amenities: "/amenities",
  amenitiesMapping: "/amenitiesMapping",
  amenitiesMappingPropertyView: "/amenitiesMapping/propertyView",
  amenitiesMappingUnitView: "/amenitiesMapping/unitView",
  dasdboard: "/dashboard",
  clientDashboard: "/clientDashboard",
  salesDashboard: "/salesDashboard",
  inspectionDashboard: "/inspectionDashboard",
  operationsDashboard: "/operationsDashboard",
  facilityDashboard: "/facilityDashboard",
  financeDashboard: "/financeDashboard",
  //community manager
  communitycard: "/communitycard",
  managementcommittee: "/managementcommittee",
  managementcommitteeView: "/managementcommitteeView",
  master: "/masters",
  communication: "/communication",
  configuration: "/configuration",
  configuration_details: "/configuration/:id",
  configuration_add: "/configuration/add",
  //security manager
  masterData: "/masterdata",
  parkingSlot: "/parkingSlot",
  parkingSlotMaster: "/parkingSlotMaster",
  visitorAndWorkedGatePass: "/visitorAndWorkedGatePass",
  deliveryOrderCollection: "/deliveryOrderCollection",
  domesticHelpRequest: "/domesticHelpRequest",
  vendorEntry: "/vendorEntry",
  serviceProvider: "/serviceProvider",
  sample1: "/sample1",
  sample2: "/sample2",
  sample3: "/sample3",
  createBlockNew: "/createBlockNew",
  createFloorNew: "/createFloorNew",
  createUnitNew: "/createUnitNew",
  companyMaster: "/masters/business_master/Company",
  pricingComponent: "/pricing_component",
  propertyType: "/masters/property_master/property_type",
  blockType: "/masters/property_master/block_tType",
  unitType: "/masters/property_master/unit_type",
  taxGroup: "/masters/business_master/tax_group",
  taxItem: "/masters/business_master/tax_item",
  masters: "/masters/:type/:title",
  propertyMaster: "/propertymaster",
  companyCreation: "/company_creation",
  editcompany: "/company/:id",

  sample4: "/sample4",
  configure: "/configure",
  pricingTable: "/pricingTable",
  pricingTablePropertyView: "/pricingTable/propertyView",
  pricingTableUnitView: "/pricingTable/unitView",
  pricingTableAll: "/pricingTable/all",

  vendorListing: "/vendors",
  vendorCreation: "/vendor_creation",
  editVendor: "/vendor/:id",

  salesagent: "/salesagent",
  salesview: "/salesview",
  salesagentlist: "/salesagentlist",
  countryMaster: "/masters/business_master/country",
  currencyMaster: "/masters/business_master/currency",
  generalCategory: "/masters/service_master/general",
  generalSubCategory: "/masters/service_master/general_sub_category",
  maintenanceCategory: "/masters/service_master/maintenance",
  maintenanceSubCategory: "/masters/service_master/maintenance_sub_category",
  activityCategory: "/activity_category",
  activitySubCategory: "/activity_sub_category",
  leadnew: "/leadnew",
  contactsList: "/contactsList",
  contactView: "/contactView",
  createContact: "/createContact",
  aggreements: "/aggreements",
  agreementView: "/aggreementView",
  companyselect: "/companyselect",
  welcome: "/welcome",
  setpassword: "/setpassword",
  dashboardSample: "/dashboardSample",
  jobs: "/jobs",
  department: "/department",
  parkingArea: "/parkingArea",
  accessGates: "/accessGates",
  accessGatesPropertyView: "/accessGates/propertyView",
  unit_vacancy: "/unit_vacancy",
  vacancy_details_id: "/vacancy_details/:id",
  vacancy_details: "/vacancy_details",

  leasemanagerdashboard: "/leasemanagerdashboard",
  securitydashboard: "/securitydashboard",
  serviceProviderListing: "/serviceProviderListing",
  serviceProviderView: "/serviceProviderView",
  serviceProviderCreateEdit: "/serviceProviderCreateEdit",
  createOpportunity: "/create_opportunity",
  profession: "/profession",
  emergencyContact: "/emergencyContact",
  domesticHelperList: "/domesticHelperList",
  domesticHelperView: "/domesticHelperView",
  domesticHelperCreateEdit: "/domesticHelperCreateEdit",
  accounts: "/accounts",
  createAccount: "/create_account",
  accountDetails: "/account_details",
  agreementTermination: "/agreementTermination",
  userManagement: "/userManagement",
  invoice: "/invoice",
  invoiceView: "/invoiceView",
  clientSettings: "/clientSettings",
  propertydashboard: "/propertydashboard",
  leadview: "/leadview",
  shortTermPricing: "/shortTermPricing",
  shortTermPricingPropertyView: "/shortTermPricing/propertyView",
  shortTermPricingUnitView: "/shortTermPricing/unitView",
  deliveryMilestone: "/deliveryMilestone",
  deliveryMilestoneTemplate: "/deliveryMilestoneTemplate",
  createDeliveryMilestoneTemplate: "/createDeliveryMilestoneTemplate",
  communityDashboard: "/communityDashboard",
  dbTask: "/dbTask",
  mapPropertyDetails: "/mapPropertyDetails",
  mapUnitDetails: "/mapUnitDetails",
  propspect: "/propspect",
  profileView: "/profileView",
  profileEdit: "/profileEdit",
  agreementRenewal: "/agreementRenewal",
  maintancerequest: "/maintancerequest",
  createmaintanceRequest: "/createmaintanceRequest",
  generalRequest: "/generalRequest",
  creategeneralrequest: "/creategeneralrequest",
  unitVacancyPropertyList: "/unitVacancy/propertyList",
  unitVacancyAll: "/unitVacancy/all",
  unitVacancyPropertyView: "/unitVacancy/propertyView",
  receipt: "/receipt",
  createReceipt: "/createReceipt",
  receiptView: "/receiptView",
  kyclist: "/kyclist",
  pricingAppraisal: "/pricingAppraisal",

  // INSPECTION
  //move-in
  moveInInspectionOrders: "/moveInInspectionOrders",
  moveInInspectionDetails: "/moveInInspectionDetails",
  moveInInspectionItemDetails: "/moveInInspectionItemDetails",
  //move-out
  moveOutInspectionOrders: "/moveoutInspectionOrders",
  moveOutInspectionDetails: "/moveOutInspectionDetails",
  moveOutInspectionItemDetails: "/moveOutInspectionItemDetails",
  //unit-handOver
  unitHandOverInspectionOrders: "/unitHandOverInspectionOrders",
  unitHandOverInspectionDetails: "/inspectionDetails",
  unitHandOverInspectionItemDetails: "/inspectionItemDetails",
  //others
  inspectionItems: "/inspectionItems",
  insItemPropertyDetails: "/insItemPropertyDetails",
  inspectionMapping: "/inspectionMapping",
  inventoryMaster: "/inventoryMaster",
  resourceMaster: "/resourceMaster",
  inspectionMaster: "/inspectionMaster",
  resourceBoard: "/resourceBoard",
  scheduleBoard: "/scheduleBoard",
  employeesBooking: "/employeesBooking",
  rentalIndex: "/rentalIndex",
  vehicleMaster: "/vehicleMaster",
  workingHoursMaster: "/workingHoursMaster",
  toolMaster: "/toolMaster",
  alreadypaid: '/alreadypaid',
  reservation: "/reservation",
  reservationDetails: "/reservationDetails",
  amenitiesBooking: "/amenitiesBooking",
  manageInvoice: '/manageInvoice',
  domesticContactsList: "/domesticContactsList",
  statementOfAccount: "/statementOfAccount",
  customerAccountList: "/customerAccountList",
  vendorAccountCreate: "/vendorAccountCreate",
  vendorAccountList: "/vendorAccountList",
  reports: '/reports',
  report: '/report',
  fileManager: '/fileManager',
  ownerAccountCreate: "/ownerAccountCreate",
  ownerAccountList: "/ownerAccountList",
  managementCommitteRoleMaster: '/managementCommitteRoleMaster',
  vendorAccountDetail: "/vendorAccountDetail",
  ownerAccountDetails: "/ownerAccountDetails",
  customerAccountDetails: '/customerAccountDetails',
  parkingBooking: "/parkingBooking",
  parkingGroupingMaster: "/parkingGroupingMaster",
  announcement_details: '/announcement_details',
  maintanceRequestView: "/maintanceRequestView",
  inviteTrigger: "/inviteTrigger",
  freeTextInvoice: "/freeTextInvoice",
  createInvoiceFreeText: "/createInvoiceFreeText",
  teams: "/teams",
  timeOffRequest: "/timeOffRequest",
  resourceBookingDetails: "/resourceBookingDetails",
  serviceInspection: "/service_inspection",
  viewServiceInspection: "/view_service_inspection",
  settlements: "/settlements",
  generalRequestView: "/generalRequestView",
  kycDetails: "/kycDetails",
  kycDetailView: "/kycDetailView",
  siteVisit: "/siteVisit",
  viewSiteVisitDetails: "/viewSiteVisitDetails",
  generalInspection: "/general_inspection",
  viewGeneralInspection: "/view_general_inspection",
  unitReadiness: "/unitReadiness",
  viewUnitReadinessDetails: "/viewUnitReadinessDetails",
  timeOffResources: "/timeOffRequestDetails",
  activityBoard:"/activityBoard",
  resetyourpassword:"/resetyourpassword",
  correspondences: "/correspondences",
  create_correspondences: "/create_correspondences",
  edit_correspondences: "/edit_correspondences"
};

export const BackendRoutes = {
  working_hours_master_list: "/working_hours_master/list",
  correspondences_list: "/correspondences/list",
  correspondences_delete: "/correspondences/delete",
  correspondences_publish: "/correspondences/publish",
  agreement_unit_info_agreement_unit_for_property: "/agreement-unit-info/agreement_unit_for_property",
  correspondences_create: "/correspondences/create"
}