import React from "react";
import { PricingApprialsComponent } from "../../../components";
import { Typography, Box } from "@mui/material";
import { againstCompanyStyle } from "../style";
import moment from "moment-timezone";

export const CompanyPreview = (props) => {
  //   classes
  const classes = againstCompanyStyle(props);
  const { isProperty, state, unitDetails } = props;

  return (
    <div>
      {/* COMPONENT DETAILS */}
      <Typography className={classes.valueTitle}>COMPONENT DETAILS</Typography>
      <Box height={"12px"} />
      <PricingApprialsComponent
        component="ViewCard"
        data={{
          title: state?.component_name?.label ?? "",
          amount: [
            {
              total: isProperty
                ? state?.amountType?.value === "Range"
                  ? `${state?.minAmount} - ${state?.maxAmount}` ?? ""
                  : state?.amount ?? ""
                : unitDetails,
              units: isProperty ? "Present Amount" : "Units",
            },
          ],
          subCatagory: [
            {
              title: state?.componentType?.type ?? "",
            },
            {
              title: state?.revenueType ?? "",
            },
            {
              title: state?.Based_On ?? "",
            },
          ],
        }}
        backgroundColor={"#FEEAEA80"}
        color={"#B3776D"}
      />

      <Box height={"20px"} />
      {/* APPRAISAL VALUE DETAILS */}
      <Typography className={classes.valueTitle}>
        APPRAISAL VALUE DETAILS
      </Typography>
      <Box height={"12px"} />
      <PricingApprialsComponent
        component="ViewCard"
        data={{
          title: state?.amountType?.name ?? "",
          subCatagory: [
            {
              title: `${moment(new Date(state?.StartDate))
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD")}`,
            },
            {
              title: "UMO / Month",
            },
          ],
          amount: [
            {
              total: !isProperty
                ? state?.amountType?.value === "Range"
                  ? `${state?.minAmount} - ${state?.maxAmount}` ?? ""
                  : state?.amount ?? ""
                : "",
              units: !isProperty ? "Present Amount" : "",
            },
            {
              total: state?.appraisal_amount ?? "",
              units: "Appraisal Amount",
            },
          ],
        }}
        backgroundColor={"#DBF0F180"}
        color={"#6DAFB3"}
      />

      {isProperty && unitDetails?.length > 0 && (
        <>
          <Box height={"20px"} />
          {/* PROPERTY & IMPACT DETAILS */}
          <Typography className={classes.valueTitle}>
            PROPERTY & IMPACT DETAILS
          </Typography>
          <Box height={"12px"} />
          <PricingApprialsComponent
            component="ViewCardMuti"
            subTitle="Units"
            data={unitDetails ?? []}
            backgroundColor={"#E4EDFF80"}
            color={"#6D80B3"}
          />
        </>
      )}
    </div>
  );
};
