import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, SemiBold } from "../../../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: "16px",
        alignItems: "center",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "white",
        boxShadow: "0px 3px 30px #5C86CB2E",
        width: "100%",
        height: "100%",
    },
    title: {
        color: "#091B29",
        fontSize: "16px",
        fontFamily: ExtraBold,
    },
    body: {
        overflow: "overlay",
        minHeight: (props) => props?.height ?? "300px",
        maxHeight: (props) => props?.height ?? "300px",
        marginTop: "16px",
    },
    card: {
        marginBottom: "6px",
        padding: "12px",
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        backgroundColor: "#FFFFFF",
        display: "flex",
        alignItems: "center"
    },
    details: {
        width: "90%",
        display: "table-row",
    },
    cardTitle: {
        fontSize: "14px",
        fontFamily: Bold,
        color: "#091B29",
        marginBottom: "4px",
        wordWrap: "break-word"
    },
    cardSubTitle: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: "#4E5A6B",
        wordWrap: "break-word",
    },
    comment: {
        marginLeft: "8px",
        height: "20px",
        width: "20px",
        padding: "4px",
        borderRadius: "50%",
        border: "1px solid #98A0AC",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    commentText: {
        fontSize: "10px",
        fontFamily: Bold,
        color: "#98A0AC",
    }
}));