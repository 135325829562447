import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
} from "@mui/material";
import { ScreensStylesParent } from "./style";
import { Search, PermissionCard, TextBox } from "../components";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { config } from "../../../config";
import { CustomSelect } from "../components/filterGenerator/components/select";
// alert message context
import { AlertContext, BackdropContext } from "../../../contexts";
import { AlertProps } from "../utilities";

// add role
const addRoles = {
  role: "",
  des: "",
  permissionListState: "",
  isEdit: false,
  id: "",
  deletedList: [],
  module: "",
  error: {
    role: "",
    module: "",
    permissionListState: "",
  },
};
export const Roles = (props) => {
  // style
  const classes = ScreensStylesParent();
  // state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addRole, setaddRole] = useState({ ...addRoles });
  const [permissionID, setPermissionID] = useState({
    id: "",
    structure: [],
  });
  const [permissions, setPermissions] = useState([]);
  const [permissionSearch, setPermissionSearch] = useState("");
  const [permissionOption, setPermissionOption] = useState([]);
  // const [deletedPermission, setDeletedPermission] = useState([]);
  const [toggledisable, setToggleDisable] = useState(true);
  // hidden and show state
  const [hiddenPermissionList, setHiddenPermissionList] = useState(false);

  // context
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);

  // dialog close function
  const handleClose = (key) => {
    setDialogOpen(!dialogOpen);
    if (key === "close") {
      setaddRole({
        ...addRole,
        role: "",
        des: "",
        isEdit: false,
        id: "",
        module: "",
        error: {
          role: "",
          module: "",
          permissionListState: "",
        },
      });
    }
  };

  const handleAddRole = () => {
    setDialogOpen(true);
    setaddRole(addRoles);
    setPermissionOption([])
    setHiddenPermissionList(false)
  }

  // onChange role
  const roleAddedFunction = (key, value) => {
    // let error = addRole?.error;
    // error[key] = "";
    setaddRole({ ...addRole, [key]: value });
  };

  // select permission
  const seclectPermission = (e) => {
    setPermissionID({
      id: e?.id,
    });
    setaddRole({
      role: e?.name,
      des: e?.description,
      id: e?.id,
      isEdit: true,
      module: e?.module,
      permissionListState: {
        value: e?.attached_permissions?.[0]?.id,
        label: e?.attached_permissions?.[0]?.name,
      },
    });
    getpermission(e?.module?.value);
    setToggleDisable(false);
  };

  // is acive permission
  const activePermission = (e) => {
    updateFunction(true, e);
  };

  // search list
  const searchPermission = (e) => {
    setPermissionSearch(e);
    getRoles(e);
  };
  // get roles list
  const getRoles = (searchvalue, infinity) => {
    if (infinity) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Loading",
      });
    }
    axios
      .get(
        `${config?.api_url}/iam/get_roles${searchvalue?.length > 0 ? `?search=${searchvalue}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((res) => {
        setPermissions(res?.data?.data);
        if (infinity) {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "Loading",
          });
        }
      })
      .catch((err) => {
        if (infinity) {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "Loading",
          });
        }
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong.",
        });
      });
  };
  //   get permission
  const getpermission = (e) => {
    axios
      .get(`${config?.api_url}/iam/get_permissions?module_id=${e}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        let option = res?.data?.data?.map((value) => {
          return {
            value: value?.id,
            label: value?.name,
          };
        });
        setPermissionOption(option);
        setHiddenPermissionList(true);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong.",
        });
      });
  };

  //   get repository
  const getRepository = (val) => {
    axios
      .get(
        `${config?.api_url}/iam/get_repository`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((res) => {
        if (val) {
          setaddRole({ ...addRole, permissionListState: [] });
        }
      })
      .catch((err) => { });
  };

  // add all roles
  const saveRole = () => {
    if (validate()) {
      const data = {
        name: addRole?.role,
        description: addRole?.des,
        module_id: addRole?.module?.value,
        permissions: [{ id: addRole?.permissionListState?.value }]
      };
      if (addRole?.isEdit) {
        data["id"] = permissionID?.id;
      }
      axios
        .post(
          `${config?.api_url}/iam/${addRole?.isEdit ? "update_roles" : "create_roles"
          }`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then((res) => {
          getRoles("");
          handleClose("close");
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `Role ${addRole?.isEdit ? "Updated" : "created"
              } successfully.`,
          });
        })
        .catch((err) => {
          handleClose("close");
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong.",
          });
        });
    }
  };

  // update
  const updateFunction = (key, value) => {
    const data = {
      id: permissionID?.id,
    };
    if (addRole?.isEdit) {
      data["id"] = permissionID?.id;
    }

    if (key) {
      data["is_active"] = value;
    }
    if (key === "is_delete") {
      data["is_delete"] = true;
    }
    if (key === "permission") {
      data["permissions"] = [{ id: addRole?.permissionListState?.value }];
    }

    axios
      .post(`${config?.api_url}/iam/update_roles`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        getRoles("");
        setHiddenPermissionList(true);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Role Updated successfully.",
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong.",
        });
      });
  };

  const updatePermission = () => {
    if (validatePermissionList()) {
      updateFunction("permission");
    }
  };
  // cancel
  const cancelFunction = () => {
    setaddRole({ ...addRoles });
    setPermissionID({
      id: "",
      structure: [],
    });
  };

  // valdation
  //   add role validation
  const validate = () => {
    let isValid = true;
    let error = addRole.error;
    if (addRole?.role?.length === 0) {
      isValid = false;
      error.role = "Name is Required";
    }
    if (addRole?.module?.length === 0) {
      isValid = false;
      error.module = "Module is Required";
    }
    if (addRole?.permissionListState?.length === 0) {
      isValid = false;
      error.permissionListState = "Permission is Required";
    }
    setaddRole({ ...addRole, error });

    return isValid;
  };
  const validatePermissionList = () => {
    let isValid = true;
    let error = addRole.error;
    if (addRole?.permissionListState?.length === 0) {
      isValid = false;
      error.permissionListState = "Permission is Required";
    }
    setaddRole({ ...addRole, error });

    return isValid;
  };
  useEffect(() => {
    getRepository();
    getRoles("", true);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item xs={4}>
          {/* search field */}
          <div className={classes.section}>
            <Search
              title={`${permissions?.length ?? 0} Permissions`}
              handleAdd={handleAddRole}
              searchFumction={searchPermission}
              search={permissionSearch}
            />
          </div>
          {/* permission card */}
          <div className={classes.fixedStylePermission}>
            <div className={classes.section1}>
              {permissions?.map((x, index) => {
                return (
                  <PermissionCard
                    title={x?.name}
                    data={x}
                    permissionID={permissionID?.id}
                    id={x?.id}
                    seclectPermission={seclectPermission}
                    editFunction={handleClose}
                    index={index}
                    activePermission={activePermission}
                    deletePermission={() => updateFunction("is_delete")}
                    disabled={toggledisable}
                  />
                );
              })}
            </div>
          </div>
        </Grid>
        <Grid item xs={8} className={classes.borderLeft}>
          {/* title name */}
          <div className={classes.section}>
            <Typography className={classes.screenTitle}>
              {hiddenPermissionList ? "Edit Role" : "Roles"}
            </Typography>
          </div>
          {permissionID?.id?.length > 0 && (
            <div className={classes.fixedStyleRole}>
              <div className={classes.section1}>
                {hiddenPermissionList ? (
                  // select box
                  <>
                    <CustomSelect
                      color={"#98A0AC"}
                      fontSize={12}
                      required
                      fontWeight={400}
                      placeholder="Select Permission List"
                      label="Permission List"
                      options={permissionOption ?? []}
                      onChange={(value) =>
                        roleAddedFunction("permissionListState", value)
                      }
                      value={addRole?.permissionListState}
                      error={addRole?.error?.permissionListState?.length > 0}
                      errorText={addRole?.error?.permissionListState}
                    />
                  </>
                ) : (
                  // permission list
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography className={classes.screenRepository}>
                        Permission List
                      </Typography>
                    </Grid>
                    <Grid item xs={2.3} className={classes.tags}>
                      <Typography className={classes.permissionlist}>
                        {addRole?.permissionListState?.label}
                      </Typography>
                    </Grid>
                    {/* {addRole?.permissionListState?.map((x) => {
                      return (
                        <Grid item xs={2.3} className={classes.tags}>
                          <Typography className={classes.permissionlist}>
                            {x?.label}
                          </Typography>
                        </Grid>
                      );
                    })} */}
                  </Grid>
                )}
              </div>
              {hiddenPermissionList && (
                <Box className={classes.bottomButton}>
                  <Button
                    className={classes.cancelBtn}
                    onClick={() => cancelFunction()}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.saveBtn}
                    onClick={() => updatePermission()}
                    variant="contained"
                    disabled={
                      addRole?.permissionListState?.label?.length > 0
                        ? false
                        : true
                    }
                  >
                    {addRole?.isEdit ? "Update" : "Save"}
                  </Button>
                </Box>
              )}
            </div>
          )}
        </Grid>
      </Grid>

      {/* add pop up */}
      <Dialog
        className={classes.dialog}
        open={dialogOpen}
        onClose={() => handleClose("close")}
        maxWidth="xs"
      >
        <DialogTitle className={classes.header}>
          <span> {addRole?.isEdit ? "Edit" : "Add New"} Role</span>{" "}
          <CloseIcon
            onClick={() => handleClose("close")}
            style={{ cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent>
          <div
            className={classes.dialogParent}>
            <CustomSelect
              marginBottom={4}
              color={"#98A0AC"}
              fontSize={12}
              required
              fontWeight={400}
              label={"Module"}
              placeholder="Select Module"
              options={props?.moduleOption ?? []}
              onChange={(e) => {
                roleAddedFunction("module", e)
                e ? getpermission(e?.value) : setHiddenPermissionList(false)
              }}
              value={addRole?.module}
              loading={props?.loading === "module"}
              isPaginate
              debounceTimeout={800}
              loadOptions={props?.loadOptions}
              error={addRole?.error?.module?.length > 0}
              errorText={addRole?.error?.module}
              isReadOnly={addRole?.isEdit ? true : false}

            />
            <Box style={{ height: "10px" }} />
            <TextBox
              placeholder={"Role Name"}
              value={addRole?.role}
              onChange={(e) => roleAddedFunction("role", e?.target?.value)}
              label={"Role Name"}
              isrequired
              isError={addRole?.error?.role?.length > 0}
              errorMessage={addRole?.error?.role}
            />
            <Box style={{ height: "10px" }} />
            <CustomSelect
              isReadOnly={!hiddenPermissionList}
              color={"#98A0AC"}
              fontSize={12}
              required
              fontWeight={400}
              placeholder="Select Permission List"
              label="Permission List"
              options={permissionOption ?? []}
              onChange={(value) => roleAddedFunction("permissionListState", value)}
              value={addRole?.permissionListState}
              error={addRole?.error?.permissionListState?.length > 0}
              errorText={addRole?.error?.permissionListState} />
            <Box style={{ height: "10px" }} />
            <TextBox
              placeholder={"Description"}
              multiline={true}
              value={addRole?.des}
              onChange={(e) => roleAddedFunction("des", e?.target?.value)}
              label={"Description"}
            />
            <Box style={{ height: "10px" }} />
            <Box className={classes.addRoleButton}>
              <Box>
                <Button className={classes.cancelBtn} onClick={handleClose}>
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button className={classes.saveBtn} onClick={saveRole}>
                  {addRole?.isEdit ? "Update" : "Save"}
                </Button>
              </Box>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
