import { Box, Grid } from "@mui/material";
import moment from "moment";
import React from "react";
import { FormGenerator } from "../../../components";
import {
  addDaysWithLess,
  billGenENUM,
  enumSelect,
  enum_types,
} from "../../../utils";
import {
  Billing_cycle_date,
  Billing_cycle_method,
  quotation_validity,
  validateCustom,
} from "../../../utils/companySettings";
import { LeadDetailsCard } from "./index";
import { useStyles } from "./styles";
export const LeaseQuotationForm = ({
  data,
  updateState,
  user = {},
  type = "",
  purpose = "",
  setData = () => false,
}) => {
  const classes = useStyles();
  const [enumValue, setEnum] = React.useState({
    unit_usage: [],
    priority: [],
    source: [],
    lease_type: [],
    billGenCycle: [],
    billing_cycle_date: [],
    apply_grace_period_type: [],
    payment_mode: [],
    turnover_type: [],
    auto_renewal_escalation: [],
    payment_period: [],
  });
  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.urgent_type,
      enum_types.l_period,
      enum_types.lead_source,
      enum_types.billing_cycle,
      enum_types.billing_cycle_date,
      enum_types?.apply_grace_period_type,
      enum_types.payment_mode,
      enum_types?.turnover_type,
      enum_types?.auto_renewal_escalation,
      enum_types.unit_payment_period,
    ]);
    setEnum({
      priority: result?.urgent_type,
      lease_type: result?.l_period
        ?.filter((val) => val?.value !== "Quarterly")
        .filter((val) => val?.value !== "Daily"),
      source: result?.lead_source,
      billGenCycle: result?.billing_cycle,
      billing_cycle_date: result?.billing_cycle_date
        ?.filter((i) => i?.value !== billGenENUM.last_day)
        .filter((i) => i?.value !== billGenENUM.mid_date)
        .filter((i) => i?.value !== billGenENUM.first),
      apply_grace_period_type: result?.apply_grace_period_type,
      payment_mode: result?.payment_mode,
      turnover_type: result?.turnover_type,
      auto_renewal_escalation: result?.auto_renewal_escalation,
      payment_period: result?.payment_value_type
        .filter((val) => val?.value !== "On Completion")
        .filter((val) => val?.value !== "Milestone Based")
        .filter((val) => val?.value !== "Hourly")
        .filter((val) => val?.value !== "Weekly"),
    });
  };
  React.useEffect(() => {
    getEnum();
  }, []);
  //on change grace peroid
  const onChangeGracePeroid = (value) => {
    setData({
      ...data,
      quotation_billing_start_date: value
        ? moment(data?.quotation_billing_start_date).add("days", value)
        : data?.lease_start_date,
      quotation_grace_period: value,
    });
  };
  //on change grace peroid value
  const onChangeGracePeroidValue = (value) => {
    setData({
      ...data,
      billing_start_date:
        data?.apply_grace_peroid === "In the Beginning"
          ? addDaysWithLess(data?.lease_start_date, value)
          : data?.lease_start_date,
      grace_peroid: value,
    });
  };

  const formLease1 = [
    {
      size: {
        xs: 12,
        sm: 9,
        md: 9,
        lg: 9,
      },
      isActive: true,
      component: "text",
      label: "Quotation Description",
      value: data?.quotation_description,
      placeholder: "Quotation Description",
      onChange: (value) =>
        updateState("quotation_description", value.target.value),
      error: data?.error?.quotation_description,
      isRequired: true,
      options: [],
      height: "60px",
      // multiline: true
    },

    {
      size: {
        xs: 12,
        sm: 3,
        md: 3,
        lg: 3,
      },
      isActive: true,
      component: "priority",
      label: "Priority",
      value: data?.priority,
      placeholder: "Priority",
      onChange: (value) => updateState("priority", value),
      error: data?.error?.priority,
      isRequired: true,
      options: enumValue?.priority,
    },
    {
      size: {
        xs: 12,
        sm: 3,
        md: 3,
        lg: 3,
      },
      isActive: true,
      component: "date",
      label:
        data?.revenue_type?.value === "Lease"
          ? "Lease Start Date"
          : "Manage Start Date",
      value: data?.lease_start_date,
      placeholder:
        data?.revenue_type?.value === "Lease"
          ? "Lease Start Date"
          : "Manage Start Date",
      isReadonly: true,
      isRequired: true,
    },
    {
      size: {
        xs: 12,
        sm: 3,
        md: 3,
        lg: 3,
      },
      isActive: true,
      component: "duration",
      label:
        data?.revenue_type?.value === "Lease"
          ? "Lease Duration"
          : "Manage Duartion",
      value: data?.duration,
      durationValue: data?.duration.select,
      placeholder:
        data?.revenue_type?.value === "Lease"
          ? "Lease Duartion"
          : "Manage Duration",
      isReadonly: data?.shortlist_convert ? true : false,
      isRequired: true,
    },
    {
      size: {
        xs: 12,
        sm: 3,
        md: 3,
        lg: 3,
      },
      isActive: true,
      component: "toggle",
      label: "Grace Period",
      value: data?.grace_peroid,
      placeholder: "Grace Period",
      onChange: (value) => onChangeGracePeroidValue(value),
      error: data?.error?.grace_peroid,
      isRequired: true,
      options: quotation_validity,
      isMulti: true,
      openCustom: validateCustom([null], data?.grace_peroid),
      customValue: 0,
      custom: true,
      applyGracePeriod: data?.grace_peroid && true,
      applyGracePeriodValue:
        data?.apply_grace_peroid === "In the Beginning" ? false : true,
      OnApplyGracePeriod: (value) => onChangeGracePeroid(value),
      isReadonly: data?.shortlist_convert ? true : false,
    },
    {
      size: {
        xs: 12,
        sm: 3,
        md: 3,
        lg: 3,
      },
      isActive: true,
      component: "date",
      label:
        data?.revenue_type?.value === "Lease"
          ? "Lease End Date"
          : "Manage End Date",
      value: data?.lease_end_date,
      placeholder:
        data?.revenue_type?.value === "Lease"
          ? "Lease End Date"
          : "Manage End Date",
      isReadonly: true,
      isRequired: true,
    },
    {
      size: {
        xs: 12,
        sm: 3,
        md: 3,
        lg: 3,
      },
      isActive: true,
      component: "date",
      label: "Billing Start Date",
      value: data?.billing_start_date,
      placeholder: "Billing Start Date",
      onChange: (value) => updateState("billing_start_date", value),
      error: data?.error?.billing_start_date,
      isRequired: true,
      isReadonly: true,
    },
    {
      size: {
        xs: 4,
        sm: 3,
        md: 3,
        lg: 3,
      },
      isActive: data?.payment_period?.value === "Prepaid" ? false : true,
      component: "toggle",
      label: "Billing Cycle Method",
      value: data?.bill_cycle_method,
      placeholder: "Billing Cycle Method",
      onChange: (value) => updateState("bill_cycle_method", value),
      error: data?.error?.bill_cycle_method,
      isRequired: true,
      options: Billing_cycle_method,
      isMulti: true,
      isReadonly: data?.shortlist_convert ? true : false,
    },
    {
      size: {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
      },
      isActive: data?.payment_period?.value === "Prepaid" ? false : true,
      component: "toggle",
      label: "Billing Cycle Date",
      value: data?.bill_cycle_date,
      placeholder: "Billing Cycle Date",
      onChange: (value) => updateState("bill_cycle_date", value),
      error: data?.error?.bill_cycle_date,
      isRequired: true,
      options: Billing_cycle_date,
      isMulti: true,
      billingCycleDate: data?.custom_date,
      onDateChange: (e) => updateState("custom_date", e),
      custom: true,
      selectBillingCycle: true,
      openCustom:
        data?.bill_cycle_date === "As per the Agreement Date" ? false : true,
      isReadonly: data?.shortlist_convert ? true : false,
    },
    {
      size: {
        xs: 12,
        sm: 3,
        md: 3,
        lg: 3,
      },
      isActive: true,
      component: "select",
      label: "Renewal Based on",
      value: data?.renewal_based_on,
      placeholder: "Renewal Based on",
      onChange: (value) => updateState("renewal_based_on", value),
      error: data?.error?.renewal_based_on,
      isRequired: true,
      options: enumValue?.auto_renewal_escalation,
      aysnc: true,
      isReadonly:true
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 6,
        lg: 3,
      },
      isActive:
        data?.renewal_based_on?.value === "Escalation %" ||
        data?.renewal_based_on?.value === "Increment %"
          ? true
          : false,
      component: "text",
      label: "Increment/Escalation Percentage",
      value: data?.esclation_percentage ?? "",
      placeholder: "Increment/Escalation Percentage",
      onChange: (value) =>
        updateState("esclation_percentage", value.target.value),
      error: data?.error?.esclation_percentage,
      isRequired: true,
      isReadonly: true,
    },
    {
      size: {
        xs: 12,
        sm: 3,
        md: 3,
        lg: 3,
      },
      isActive: true,
      component: "select",
      label: "Payment Period",
      value: data?.payment_period,
      placeholder: "Payment Period",
      onChange: (value) => updateState("payment_period", value),
      error: data?.error?.payment_period,
      isRequired: true,
      options: enumValue?.payment_period,
    },
    {
      size: {
        xs: 12,
        sm: 3,
        md: 3,
        lg: 3,
      },
      isActive: true,
      component: "select",
      label: "Payment Options",
      value: data?.payment_option,
      placeholder: "Payment Options",
      onChange: (value) => updateState("payment_option", value),
      error: data?.error?.payment_option,
      isRequired: true,
      options: enumValue?.payment_mode,
    },
  ];

  return (
    <Box p={1}>
      <Grid container className={classes.leadDetailsRoot}>
        <Grid item xs={3} p={1}>
          <Box p={2} className={classes.leadDetailsRootDivider}>
            <LeadDetailsCard
              data={{
                name: data?.name,
                image:
                  data?.image_url?.length !== 0 ? data?.image_url?.src : null,
                email: data?.email_id,
                mobile: ` ${data.mobile?.mobile_code ?? ""} ${
                  data.mobile?.mobile
                }`,
                no: null,
                type: data?.revenue_type?.value,
                purpose: data?.purpose?.value,
                source: data?.source?.value,
                units: data?.units,
              }}
              hideDetails={true}
              source_enum={enumValue?.source ?? ""}
              dataNew={data}
              setDataNew={setData}
              updateState={updateState}
            />
            <Box height="18px" />
            {/* <FormGenerator components={formLease} /> */}
          </Box>
        </Grid>
        <Grid item xs={9} p={1}>
          <Box p={2} className={classes.leadDetailsRootDivider}>
            <FormGenerator components={formLease1} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
