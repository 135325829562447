/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient } from "@apollo/client";
import { Avatar, Badge, Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from "../../assets/closeIcon";
import FilterIMG from "../../assets/filter";
import InfoIcon from "../../assets/infoIcon";
import RightCheveronIcon from "../../assets/rightCheveronIcon";
import { FilterGenerator, SearchFilter, SelectBox, Subheader, TableWithPagination, TextBox, UseDebounce } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { INSERT_PRICING_COMPONENT, UPDATE_PRICING_COMPONENT } from "../../graphql/mutations";
import {
    GET_INSPECTION_ITEMS,
    GET_PRICING_COMPONENT,
    CHECK_PRICING_COMPONENT
} from '../../graphql/queries';
import { NetworkCall } from '../../networkcall';
import { accessCheckRender, AlertProps, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import { AddQuestions, FormQuestionSwitchList1, FormQuestionSwitchList2, FormQuestionSwitchList2Category, PricingComponentHeading, PricingComponentPath, PricingComponentType } from "../../utils/pricingComponent";
import { LightTooltip, useStyles } from "./style";

export const PricingComponent = () => {

    const defaultAddNewState = {
        formType: "question",
        question: "",
        category: "",
        info: "",
        primaryColor: "",
        secondaryColor: "",
        id: "",
        name: "",
        services: "",
        revenueType: { value: "Lease", label: "Lease" },
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        error: {
            name: "",
            services: "",
        }
    }
    const classes = useStyles();
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const auth = React.useContext(AuthContext);
    const [pricingComponentList, setPricingComponentList] = React.useState({});
    const [searchText, setSearchText] = React.useState("");
    const client = useApolloClient();
    const [companyList, setCompanyList] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [filterData, setFilterData] = React.useState({ status: [true] });
    const [filterDrawer, setFilterDrawer] = React.useState(null);
    const [addNewDialog, setAddNewDialog] = React.useState(null);
    const [addNewState, setAddNewState] = React.useState({ ...defaultAddNewState });
    const debounce = UseDebounce();
    const [asyncOptionloader, setAsyncOptionloader] = React.useState("")
    const [buttonDisable, setButtonDisable] = React.useState(false);
    const [enumValue, setEnumValue] = React.useState({
        revenue_type: []
    })
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getEnum()
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // Function for updating addNewState
    const updateAddNewState = (k, v) => {
        let error = addNewState?.error;
        error[k] = "";
        if (k === "services") {
            setAddNewState({ ...addNewState, [k]: v, name: v?.label, error });
        } else {
            setAddNewState({ ...addNewState, [k]: v, error });
        }
        if(k === "name"){
            searchCoponent(v)
        }
    }

    // Function for updating addNewState
    const validate = () => {
        let isValid = true;
        let error = addNewState.error;
        if (addNewState?.name?.length === 0) { isValid = false; error.name = "Name is Required"; }
        if (addNewState?.question === AddQuestions[4].question && addNewState?.services?.length === 0) {
            isValid = false; error.services = "Service is Required";
        }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Please fill all mandatory field",
            });
        }
        setAddNewState({ ...addNewState, error });
        return isValid;
    };

    // Changing Page Number
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getPricingComponent(offset, limit, searchText)
    };

    // Changing Page Number
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getPricingComponent(0, value, searchText)
    };

    // Function for changing Status of Pricing Component
    const updateStatusPricingComponent = async (data, value) => {
        client.mutate({
            mutation: UPDATE_PRICING_COMPONENT,
            variables: {
                pricingComponentID: data,
                payload: {
                    is_active: value === "Inactive" ? true : false,
                    updated_by: localStorage.getItem(LocalStorageKeys.profileID),
                    updated_at: new Date().toISOString()
                }
            }
        }).then(() => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: "Pricing Component Status Updated Successfully",
            });
            getPricingComponent()
        }).catch(() => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Some Thing Went Wrong",
            });
        });
    };

    // Function for deleting Pricing Component
    const deletePricingComponent = async (data) => {
        client.mutate({
            mutation: UPDATE_PRICING_COMPONENT,
            variables: {
                pricingComponentID: data,
                payload: {
                    is_active: false,
                    is_delete: true,
                    updated_by: localStorage.getItem(LocalStorageKeys.profileID),
                    updated_at: new Date().toISOString()
                }
            }
        }).then(() => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: "Pricing Component Deleted Successfully",
            });
            getPricingComponent()
        }).catch(() => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Some Thing Went Wrong",
            });
        })
    };

    // Changing Status of Pricing Component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    };

    // Search function for table
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1); }
        getPricingComponent(0, limit, e)
    };

    // More Icon function in table
    const handleIcon = (type, data, status) => {
        setButtonDisable(false);

        let question = data?.data?.is_onetime ? AddQuestions?.[2]?.question :
            data?.data?.refundable ? AddQuestions?.[3]?.question :
                data?.data?.is_quantity ? AddQuestions?.[4]?.question :
                    data?.data?.is_parking ? AddQuestions?.[5]?.question :
                        data?.data?.primary ? AddQuestions?.[0]?.question :
                            AddQuestions?.[1]?.question;

        let category = data?.data?.is_onetime ? AddQuestions?.[2]?.category :
            data?.data?.refundable ? AddQuestions?.[3]?.category :
                data?.data?.is_quantity ? AddQuestions?.[4]?.category :
                    data?.data?.is_parking ? AddQuestions?.[5]?.category :
                        data?.data?.primary ? AddQuestions?.[0]?.category :
                            AddQuestions?.[1]?.category;

        let info = data?.data?.is_onetime ? AddQuestions?.[2]?.info :
            data?.data?.refundable ? AddQuestions?.[3]?.info :
                data?.data?.is_quantity ? AddQuestions?.[4]?.info :
                    data?.data?.is_parking ? AddQuestions?.[5]?.info :
                        data?.data?.primary ? AddQuestions?.[0]?.info :
                            AddQuestions?.[1]?.info;

        let primaryColor = data?.data?.is_onetime ? AddQuestions?.[2]?.primaryColor :
            data?.data?.refundable ? AddQuestions?.[3]?.primaryColor :
                data?.data?.is_quantity ? AddQuestions?.[4]?.primaryColor :
                    data?.data?.is_parking ? AddQuestions?.[5]?.primaryColor :
                        data?.data?.primary ? AddQuestions?.[0]?.primaryColor :
                            AddQuestions?.[1]?.primaryColor;

        let secondaryColor = data?.data?.is_onetime ? AddQuestions?.[2]?.secondaryColor :
            data?.data?.refundable ? AddQuestions?.[3]?.secondaryColor :
                data?.data?.is_quantity ? AddQuestions?.[4]?.secondaryColor :
                    data?.data?.is_parking ? AddQuestions?.[5]?.secondaryColor :
                        data?.data?.primary ? AddQuestions?.[0]?.secondaryColor :
                            AddQuestions?.[1]?.secondaryColor;

        const tempAddNewState = {
            formType: type,
            question: question,
            category: category,
            info: info,
            primaryColor: primaryColor,
            secondaryColor: secondaryColor,
            id: data?.id,
            name: data?.data?.name ?? "",
            services: data?.data?.inspection_itemsByID?.value ? data?.data?.inspection_itemsByID : "",
            revenueType: (data?.data?.revenue_type && data?.data?.revenue_type !== "") ?
                { value: data?.data?.revenue_type, label: data?.data?.revenue_type } :
                { value: "Lease", label: "Lease" },
            isPrimary: data?.data?.primary ?? false,
            isOneTime: data?.data?.is_onetime ?? false,
            isRefundable: data?.data?.refundable ?? false,
            isQuantity: data?.data?.is_quantity ?? false,
            isParking: data?.data?.is_parking ?? false,
            isTaxable: data?.data?.taxtable ?? false,
            isOverride: data?.data?.is_override ?? false,
            isReserve: data?.data?.is_reserve_quality ?? false,
            isSecurity: data?.data?.is_security ?? false,
            error: defaultAddNewState?.error
        };

        if (type === "edit") {
            setAddNewState({ ...tempAddNewState });
            addNewDialogopen();
        }
        else if (type === "view") {
            setAddNewState({ ...tempAddNewState });
            addNewDialogopen();
        }
        else if (type === "active") { updateStatusPricingComponent(data.id, status); }
        else if (type === "delete") { deletePricingComponent(data.id); }
    };

    // Pricing Component Row Data
    const PricingComponentRow = React.useCallback(pricingComponentList?.data?.map((val, index) => {
        let categoryType = val.is_onetime ? AddQuestions?.[2]?.categoryType :
            val.refundable ? AddQuestions?.[3]?.categoryType :
                val.is_quantity ? AddQuestions?.[4]?.categoryType :
                    val.is_parking ? AddQuestions?.[5]?.categoryType :
                        val.primary ? AddQuestions?.[0]?.categoryType :
                            AddQuestions?.[1]?.categoryType;
        let _d;
        try {
            _d = {
                id: val?.id,
                company_name: val?.companyByID?.name,
                pricing_component: categoryType,
                revenue_type: val?.revenue_type,
                name: val?.name,
                taxes: val?.taxtable ? "Yes" : "No",
                priceOverride: val?.is_override ? "Yes" : "No",
                autoReservation: val?.is_reserve_quality ? "Yes" : "No",
                autoSecurityDeposit: val?.is_security ? "Yes" : "No",
                status: val?.is_active ? "Active" : "Inactive",
                data: val
            };
        } catch (err) { }
        return _d;
    }), [pricingComponentList]);

    // Function to get Pricing Component data from Graphql
    const getPricingComponent = (offset = 0, limit = 10, search = "") => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading...", });
        let companyID = [selectedCompany?.value];
        let status = (filterData?.status && filterData?.status?.length > 0) ? filterData?.status : [true, false];
        const payload = {
            query: GET_PRICING_COMPONENT(offset, limit, search, { status, companyID },
                localStorage.getItem(LocalStorageKeys?.clinetID)).loc.source.body,
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((response) => {
            setPricingComponentList({
                data: response?.data?.data?.pricing_component,
                totalRowsCount: response?.data?.data?.count?.[0]?.count,
            });
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
        }).catch(() => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Some Thing Went Wrong",
            });
        });
    };
    //get Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.revenue_type])
        setEnumValue({
            revenue_type: result?.revenue_type
        })
    }
    // useEffect for the screen with company and filter data as dependency
    React.useEffect(() => {
        if (selectedCompany?.value) {
            getPricingComponent();
        }
    }, [selectedCompany, filterData]);

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    };

    // Function to open filter drawer
    const filterDraweropen = () => {
        setFilterDrawer(true);
    };

    // Function to close filter drawer
    const filterDrawerclose = () => {
        setFilterDrawer(false);
    };

    // Function to open filter drawer
    const addNewDialogopen = () => {
        setAddNewDialog(true);
    };

    // Function to close filter drawer
    const addNewDialogclose = () => {
        setAddNewDialog(false);
    };

    // Function for Add New Button
    const handleAddNewButton = () => {
        setButtonDisable(false);
        setAddNewState({ ...defaultAddNewState });
        addNewDialogopen();
    };

    // Function to apply filter
    const onApplyFilter = (value) => {
        setFilterData(value);
    };

    // Function for clicking question while adding
    const handleQuestionCard = (value) => {
        setAddNewState({
            ...addNewState,
            formType: "add",
            question: value?.question,
            category: value?.category,
            info: value?.info,
            primaryColor: value?.primaryColor,
            secondaryColor: value?.secondaryColor,
            isPrimary: value?.isPrimary,
            isOneTime: value?.isOneTime,
            isRefundable: value?.isRefundable,
            isQuantity: value?.isQuantity,
            isParking: value?.isParking,
            isTaxable: value?.isTaxable,
            isOverride: value?.isOverride,
            isReserve: value?.isReserve,
            isSecurity: value?.isSecurity,
            id: "",
            name: "",
            revenueType: { value: "Lease", label: "Lease" },
            error: defaultAddNewState?.error,
        })
    };

    // Function to save Pricing Table
    const savePricingComponent = () => {
        setButtonDisable(true);
        if (validate()) {

            let currentDate = new Date().toISOString();
            let profileID = localStorage.getItem(LocalStorageKeys.profileID);

            let payload = {
                company_id: selectedCompany?.value,
                primary: addNewState?.isPrimary,
                is_onetime: addNewState?.isOneTime,
                refundable: addNewState?.isRefundable,
                is_quantity: addNewState?.isQuantity,
                is_parking: addNewState?.isParking,
                name: addNewState?.name,
                revenue_type: addNewState?.revenueType?.value,
                taxtable: addNewState?.isTaxable,
                is_override: addNewState?.isOverride,
                is_reserve_quality: addNewState?.isReserve,
                is_security: addNewState?.isSecurity,
                inspection_items_id: addNewState?.services?.id,
                updated_at: currentDate,
                updated_by: profileID,
                client: localStorage.getItem(LocalStorageKeys.clinetID)
            }

            if (addNewState?.formType === "add") {
                payload.is_active = true;
                payload.is_delete = false;
                payload.created_at = currentDate;
                payload.created_by = profileID;
            }

            client.mutate({
                mutation: addNewState?.formType === "add" ? INSERT_PRICING_COMPONENT : UPDATE_PRICING_COMPONENT,
                variables: {
                    pricingComponentID: addNewState?.id ?? undefined,
                    payload: addNewState?.formType === "add" ? [payload] : payload
                }
            }).then(() => {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: `Pricing Component Status ${addNewState?.formType === "add" ? `Added` : `Updated`} Successfully`,
                });
                addNewDialogclose();
                getPricingComponent()
            }).catch(() => {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                });
                setButtonDisable(false);
            });
        } else {
            setButtonDisable(false);
        }
    }

    // Function to load Async Select Options
    const loadOptions = async (search = "", array, type) => {
        setAsyncOptionloader(type)
        let result, query, offset, limit, companyID, clientID, variables = 0
        limit = 10
        clientID = localStorage.getItem(LocalStorageKeys?.clinetID)
        companyID = selectedCompany?.value
        if (search && !Boolean(array?.length)) { offset = 0 }
        else { offset = array?.length }

        switch (type) {
            case "inspection_items":
                query = GET_INSPECTION_ITEMS
                variables = { companyID, clientID, offset, limit, search }
                result = await networkCallBack(query, variables)
                return {
                    options: [...result?.inspection_items],
                    hasMore: (array?.length + result?.inspection_items?.length) < result?.count[0]?.count
                }
            default:
                return { options: [] }
        }
    }

    // Function for network callback for Async Select Options
    const networkCallBack = async (query, variables = {}) => {
        const options = await client.query({
            query, fetchPolicy: "network-only", variables
        }).then((r) => {
            let main = r?.data
            setAsyncOptionloader(null)
            return main
        }).catch((e) => {
            setAsyncOptionloader(null)
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: "Some Thing Went Wrong"
            })
            return null
        })
        return options
    }

    const getPricingComponentCheck = (search = "" , typeValue) => {
        let companyID = [selectedCompany?.value];
        const payload = {
            query: CHECK_PRICING_COMPONENT( search, { companyID , type:addNewState?.revenueType?.value , component_type:typeValue},
                localStorage.getItem(LocalStorageKeys?.clinetID)).loc.source.body,
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((response) => {
          if(response?.data?.data?.pricing_component?.length > 0){
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Pricing Component Already Exists",
            });
            return  setButtonDisable(true);
          }
          setButtonDisable(false);
            
        }).catch(() => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Some Thing Went Wrong",
            });
        });
    };

    const getType = (val)=>{
        return{
            primary: val?.isPrimary,
            is_onetime: val?.isOneTime,
            refundable: val?.isRefundable,
            is_quantity: val?.isQuantity,
            is_parking: val?.isParking,
        }
    }

    const searchCoponent=(value)=>{
        if(addNewState?.revenueType?.value){
            const type = getType(addNewState)
            debounce(() =>  getPricingComponentCheck(value , type) , 800)
        }
    }
    const render = () => {
        return <>
            {/* Header */}
            <Subheader hideBackButton={true} title="Pricing Component"
                select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />

            {/* Content */}
            <div className={classes.root}>
                <Grid container className={classes.content} spacing={2}>

                    {/* Search TextBox */}
                    <Grid item xs={4}>
                        <SearchFilter value={searchText} placeholder="Search Pricing Component" handleChange={(value) => handleSearch(value)} />
                    </Grid>

                    {/* Filter and Add New Button */}
                    <Grid item xs={8} textAlign={"right"}>
                        <Box display={"flex"} sx={{ float: "right" }}>
                            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                                {filterData.status?.length > 0 ?
                                    (<IconButton onClick={filterDraweropen} className={classes.filterButton}>
                                        <Badge variant="dot" color="primary">
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                    </IconButton>) :
                                    (<IconButton onClick={filterDraweropen} className={classes.filterButton}>
                                        <FilterIMG color="#091b29" />
                                    </IconButton>)}
                                {permission?.create &&
                                    <Button variant="contained" className={classes.addNewButton}
                                        onClick={handleAddNewButton}>
                                        {"Add New"}
                                    </Button>}
                            </Stack>
                        </Box>
                    </Grid>

                    {/* Pricing Component Table */}
                    <Grid item xs={12} marginTop="-14px">
                        <TableWithPagination
                            heading={PricingComponentHeading}
                            rows={PricingComponentRow}
                            path={PricingComponentPath}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            handleIcon={handleIcon}
                            onClick={() => console.log("")}
                            tableType="no-side"
                            dataType={PricingComponentType}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={pricingComponentList?.totalRowsCount}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 290px)'}
                            view={permission?.read}
                            edit={permission?.update}
                            delete={permission?.delete} />
                    </Grid>
                </Grid>

                {/* Filter Drawer */}
                <FilterGenerator
                    open={filterDrawer}
                    onClose={filterDrawerclose}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: [
                                { label: 'Active', value: true },
                                { label: 'Inactive', value: false },
                            ],
                            isMulti: true,
                            state_name: "status",
                            label: "Status",
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)} />

                {/* Add Edit View Drawer */}
                <Dialog
                    open={addNewDialog}
                    onClose={addNewDialogclose}
                    className={classes.addNewDialog}>
                    <div className={classes.addNewDialogHeader}>
                        <Typography className={classes.addNewDialogHeaderTitle}>Pricing Component</Typography>
                        <IconButton className={classes.addNewDialogHeaderCloseButton} onClick={addNewDialogclose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {addNewState?.formType === "question" ? <div className={classes.addNewDialogQuestionBody}>
                        {AddQuestions?.map((item, index, array) => {
                            return <>
                                <div className={classes.addQuestionCard} onClick={() => { handleQuestionCard(item) }}
                                    style={{ backgroundColor: item?.secondaryColor }}>
                                    <Box className={classes.addQuestionContent1}>
                                        <Avatar className={classes.addQuestionSerialAvatar} style={{ backgroundColor: item?.primaryColor }}>
                                            <Typography className={classes.addQuestionSerialText} >
                                                {item?.serial}
                                            </Typography>
                                        </Avatar>
                                        <Typography className={classes.addNewDialogQuestionText} style={{ color: item?.primaryColor }}>
                                            {item?.question}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.addQuestionContent2}>
                                        <LightTooltip title={item?.info} placement="top">
                                            <Box className={classes.addNewDialogInfoIcon1}>
                                                <InfoIcon />
                                            </Box>
                                        </LightTooltip>
                                        <RightCheveronIcon fill={item?.primaryColor} />
                                    </Box>
                                </div>
                                {(index !== array?.length - 1) && <Box height={"12px"} />}
                            </>
                        })}
                    </div> : <>
                        <div className={classes.addNewDialogFormBody}>
                            <div className={classes.addNewDialogFormContent1}>
                                <div className={classes.addQuestionCard}
                                    style={{ backgroundColor: addNewState?.secondaryColor, cursor: "auto" }}>
                                    <Box className={classes.addQuestionContent1}>
                                        <Typography className={classes.addNewDialogQuestionText} style={{ color: addNewState?.primaryColor }}>
                                            {addNewState?.category}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.addQuestionContent2}>
                                        <LightTooltip title={addNewState?.info} placement="top">
                                            <Box className={classes.addNewDialogInfoIcon2}>
                                                <InfoIcon />
                                            </Box>
                                        </LightTooltip>
                                    </Box>
                                </div>
                                <Box height={"24px"} />
                                <Grid container justifyContent={"space-between"} spacing={"16px"}>
                                    <Grid item xs={12} md={6}>
                                        {addNewState?.question === AddQuestions[4].question ?
                                            <SelectBox
                                                isRequired
                                                isReadonly={addNewState?.formType === "view"}
                                                label="Services"
                                                placeholder="Select Services"
                                                value={addNewState?.services ?? ""}
                                                onChange={(value) => updateAddNewState("services", value)}
                                                loading={asyncOptionloader === "inspection_items"}
                                                isPaginate
                                                debounceTimeout={800}
                                                loadOptions={(search, array) => loadOptions(search, array, 'inspection_items')}
                                                isError={addNewState?.error?.inspection_items?.length > 0}
                                                errorMessage={addNewState?.error?.inspection_items} /> :
                                            <TextBox
                                                isRequired
                                                isReadonly={addNewState?.formType === "view"}
                                                color={"white"}
                                                label="Pricing Component Name"
                                                placeholder="Enter Name"
                                                value={addNewState?.name ?? ""}
                                                onChange={(e) => updateAddNewState("name", e.target.value)}
                                                isError={addNewState?.error?.name?.length > 0}
                                                errorMessage={addNewState?.error?.name} />}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography className={classes.addNewDialogFormFieldLabel} noWrap>What Is The Revenue Type?</Typography>
                                        <Grid container rowGap={"8px"} columnGap={"8px"}>
                                            {enumValue?.revenue_type?.map((value) => {
                                                return <Button className={value?.value === addNewState?.revenueType?.value ?
                                                    classes.addNewDialogFormButtonSelected : classes.addNewDialogFormButtonUnSelected}
                                                    onClick={() => (addNewState?.formType === "add" || addNewState?.formType === "edit") ?
                                                        updateAddNewState("revenueType", value) : false}>
                                                    <Typography className={value?.value === addNewState?.revenueType?.value ?
                                                        classes.addNewDialogFormButtonTextSelected : classes.addNewDialogFormButtonTextUnSelected}>
                                                        {value?.label}</Typography>
                                                </Button>
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={classes.addNewDialogFormContent2}>
                                {(FormQuestionSwitchList2Category.includes(addNewState?.category) ?
                                    FormQuestionSwitchList2 :
                                    FormQuestionSwitchList1)?.map((item, index, array) => {
                                        return <>
                                            <div className={addNewState?.[item?.state] ?
                                                classes.addNewDialogCustomSwitchOn : classes.addNewDialogCustomSwitchOff}
                                                onClick={() => (addNewState?.formType === "add" || addNewState?.formType === "edit") ?
                                                    updateAddNewState(item?.state, !addNewState?.[item?.state]) : false}>
                                                <Box className={classes.addNewDialogCustomSwitchContent1}>
                                                    <Typography className={classes.addNewDialogCustomSwitchText}>
                                                        {item?.question}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.addNewDialogCustomSwitchContent2}>
                                                    {addNewState?.[item?.state] ?
                                                        <div className={classes.switchContainerOn}>
                                                            <div className={classes.switchToggleOn} />
                                                        </div> :
                                                        <div className={classes.switchContainerOff}>
                                                            <div className={classes.switchToggleOff} />
                                                        </div>}
                                                </Box>
                                            </div>
                                            {(index !== array?.length - 1) && <Box height={"12px"} />}
                                        </>
                                    })}
                            </div>
                        </div>
                        {permission.update && <div className={addNewState?.formType === "add" ?
                            classes.addNewDialogButtonContentAdd : classes.addNewDialogButtonContentViewEdit}>
                            {addNewState?.formType === "add" ?
                                <><Button className={classes.addNewDialogBackButton}
                                    onClick={() => updateAddNewState("formType", "question")}>
                                    <Typography className={classes.addNewDialogBackButtonText}>Back</Typography>
                                </Button>
                                    <Button className={classes.addNewDialogAddButton} disabled={buttonDisable}
                                        onClick={savePricingComponent}>
                                        <Typography className={classes.addNewDialogAddButtonText}>Create Pricing Component</Typography>
                                    </Button></> :
                                <><Button className={classes.addNewDialogViewEditButton} fullWidth disabled={buttonDisable}
                                    onClick={() => addNewState?.formType === "view" ? updateAddNewState("formType", "edit") : savePricingComponent()}>
                                    <Typography className={classes.addNewDialogViewEditButtonText}>
                                        {addNewState?.formType === "view" ? "Edit" : "Save"}
                                    </Typography>
                                </Button></>}
                        </div>}
                    </>}
                </Dialog>
            </div>
        </>
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div >
}