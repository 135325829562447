import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { ListTable } from "../../components/listTable";
import { ListTableData, propertyUnitListDetail, propertyUnitListDetailView } from "../../utils/freeTextInvoice";
import PropertyUnitDetails from "./components/propertyUnitDetails";
import { StepCard } from "./components";
import { CreateInvoiceFreeTextStyle } from './style'
import InvoiceSvg from "./utils/invoice";
import DebitSvg from "./utils/debit"
import CreditSvg from './utils/credit'
import { ChoosePopup } from "./choosePopup";
import { AccountType } from "../../utils/freeTextInvoice";
import CustomerPopup from "./customerPopup";
import { AlertDialog, EditIcon } from '../../components';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';
import TotalTable from './components/totalTable';
import { AGREEMENT_NUMBER, TAX_GROUP } from '../../graphql/freeTextInvoice';
import { AlertProps, enum_types, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import { loadOptions, loadOptionsApis } from '../../utils/asyncPaginateLoadOptions';
import uuid from "uuid";
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';
import { checkNumber } from '../../utils';
import { withNamespaces } from 'react-i18next';
import { AlertContext } from '../../contexts';
import Step1IconSvg from '../../assets/step1Icon';
import Step2IconSvg from '../../assets/step2Icon';
import Step3IconSvg from '../../assets/step3Icon';
import Step3IconDisabledSvg from '../../assets/step3IconDisabled';


const StepsContent = (
    {
        t,
        state = {},
        selectedCompany = {},
        type = "",
        stateData = {},
        editDetailFromView = () => false,
        publishInvoice = () => false,
        viewData = {}
    }
) => {
    const classes = CreateInvoiceFreeTextStyle()
    const history = useHistory()
    const alert = React.useContext(AlertContext);
    const [invoiceData, setInvoiceData] = React.useState({
        invoiceType: state?.selected?.name,
        description: "",
        customerList: [],
        selectedAccountDetails: {},
        searchText: "",
        due_date: new Date(),
        showCustomerList: false,
        accountType: false,
        showRemark: false,
        selectedType: "",
        showVendorList: false,
        step1: false,
        step2: false,
        step3: false,
        agreementNo: "",
        invoiceNo: "",
        taxGroup: "",
        selectedunitDetail: {},
        unitPopup: false,
        pricingPopup: false,
        tableRowData: [],
        totalTaxableAmount: 0,
        totalTaxes: 0,
        totalAmount: 0,
        invoiceNumber: "Autogenerate",
        invoiceAmount: "Autogenerate",
        periodTo: "Autogenerate",
        periodFrom: "Autogenerate"
    })

    const initialRowData = (id, data) => {
        return {
            rowid: id,
            pricing_component_id: data?.id,
            component: data?.name ?? "",
            desc: "",
            qty: 0,
            amount: 0,
            discount: 0,
            taxableAmount: 0,
            taxGroup: invoiceData?.taxGroup?.label ?? "",
            taxes: 0,
            subtotal: 0,
            delete: true,
            path: ["desc", "qty", "amount", "discount"],
            field: {
                desc: "text",
                qty: "number",
                amount: "number",
                discount: "number"
            }
        }
    }

    const icons = {
        "Invoice": <InvoiceSvg />,
        "Debit": <DebitSvg />,
        "Credit": <CreditSvg />
    }

    React.useEffect(() => {
        if (type === "view") {
            setInvoiceData({ ...stateData })
        } else if (state?.type === "edit") {
            setInvoiceData({ ...state?.invoiceDataList })
        }
        // eslint-disable-next-line
    }, [])

    const openStep2 = () => {
        if (invoiceData?.step1) {
            setInvoiceData({ ...invoiceData, step2: !invoiceData?.step2 })
        }
    }

    const openStep3 = () => {
        if (invoiceData?.step1 && invoiceData?.step2) {
            setInvoiceData({ ...invoiceData, unitPopup: !invoiceData?.unitPopup })
        }
    }

    const manualResponse = (array) => array?.map(_ => {
        return {
            ..._,
            label: _?.label,
            value: _?.value,
        }
    });

    const enummanualResponse = (array) => {
        const details = array?.invoice_charge_type?.map((i) => {
            return {
                label: i,
                value: i
            }
        })
        return details
    };

    const selectType = (type) => {
        setInvoiceData({
            ...invoiceData,
            showCustomerList: type === "Customer" && !invoiceData?.showCustomerList,
            selectedType: type,
            showVendorList: type === "Vendor" && !invoiceData?.showVendorList,
        })
    }
    const selectCustomer = (data, type) => {
        if(invoiceData?.editAccount){
            setInvoiceData({
                ...invoiceData,
                selectedAccountDetails: data,
                step2: false,
                step3: false,
                agreementNo: "",
                invoiceNo: "",
                taxGroup: "",
                selectedunitDetail: {},
                unitPopup: false,
                pricingPopup: false,
                tableRowData: [],
                totalTaxableAmount: 0,
                totalTaxes: 0,
                totalAmount: 0,
                showCustomerList: type === "Customer" && !invoiceData?.showCustomerList,
                accountType: !invoiceData?.accountType,
                showVendorList: type === "Vendor" && !invoiceData?.showVendorList,
                editAccount:false
            })
        }else{
            setInvoiceData({
                ...invoiceData,
                selectedAccountDetails: data,
                showCustomerList: type === "Customer" && !invoiceData?.showCustomerList,
                accountType: !invoiceData?.accountType,
                showVendorList: type === "Vendor" && !invoiceData?.showVendorList,
                step1: !invoiceData?.step1,
                agreementNo: ""
            })
        }
    }

    const selectUnit = (data, type) => {
        setInvoiceData({
            ...invoiceData,
            selectedunitDetail: data,
            step3: true,
            unitPopup: !invoiceData?.unitPopup,
            propertyUnitDetail: [
                {
                    title: t("PropertyUnitDetails"),
                    content: data?.unit?.unit_no + ", " + data?.unit?.name,
                    editIcon: true
                },
                {
                    title: t("UnitID"),
                    content: data?.unit?.unit_no ?? "-"
                },
                {
                    title: t("UnitCategory"),
                    content: data?.unit?.unit_category ?? "-"
                },
                {
                    title: t("UnitType"),
                    content: data?.unit?.unit_type ?? "-"
                },
                {
                    title: t("Location"),
                    content: data?.unit?.address?.latitude + ", " + data?.unit?.address?.longitude
                }
            ]
        })
    }
    const editAccountDetail = () => {
        setInvoiceData({
            ...invoiceData,
            accountType: !invoiceData?.accountType,
            editAccount:true
        })
    }

    const createInoice = () => {
        const payload = {
            insert_fields: {
                company_id: selectedCompany?.value,
                id: invoiceData?.id ?? undefined,
                description: invoiceData?.description,
                account_no: invoiceData?.selectedAccountDetails?.account_no,
                agreement_id: invoiceData?.agreementNo?.value,
                type: state?.selected?.name,
                invoice_amount: invoiceData?.totalAmount,
                due_date: invoiceData?.due_date,
                vat_group_master_id: invoiceData?.taxGroup?.value,
                invoice_type: invoiceData?.invoiceNo?.value,
                total_taxable_amount: invoiceData?.totalTaxableAmount,
                total_taxable_taxes: invoiceData?.totalTaxes,
                total_amount_due: invoiceData?.totalAmount,
                created_by: localStorage.getItem(LocalStorageKeys.profileID),
                updated_by: localStorage.getItem(LocalStorageKeys.profileID),
                units: [
                    {
                        id: invoiceData?.selectedunitDetail?.id ?? undefined,
                        created_by: localStorage.getItem(LocalStorageKeys.profileID),
                        unit_id: invoiceData?.selectedunitDetail?.unit?.id,
                        agreement_unit_id: invoiceData?.selectedunitDetail?.unit_id,
                        updated_by: invoiceData?.id && localStorage.getItem(LocalStorageKeys.profileID)
                    }
                ],
                payments: invoiceData?.tableRowData?.map((data) => {
                    return {
                        id: data?.id ?? undefined,
                        pricing_component_id: data?.pricing_component_id,
                        vat_group_master_id: invoiceData?.taxGroup?.value,
                        description: data?.desc,
                        quantity: data?.qty,
                        amount: data?.amount,
                        discount: data?.discount,
                        taxable_amount: data?.taxableAmount,
                        taxes: data?.taxes,
                        subtotal_amount: data?.subtotal,
                        updated_by: localStorage.getItem(LocalStorageKeys.profileID),
                        created_by: localStorage.getItem(LocalStorageKeys.profileID),
                        currency_id:selectedCompany?.currency_id
                    }
                })
            }
        }

        NetworkCall(
            `${config.api_url}/free_text_invoice/upsert`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                history.push(Routes.freeTextInvoice)
            })
            .catch((error) => {
                console.log(error)
            });


    }

    const updateState = (key, value) => {
        setInvoiceData({
            ...invoiceData,
            [key]: value
        })
    }

    const setDueDate = (value) => {
        updateState("due_date", value)
    }
    // add New bill line functions
    const selectPricing = (data) => {
        const pricingData = invoiceData?.tableRowData?.map((data) => {
            return data?.pricing_component_id
        })
        if (pricingData.includes(data?.id)) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("AlreadyAdded"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            });
        }
        else {
            setInvoiceData(
                {
                    ...invoiceData,
                    pricingPopup: !invoiceData?.pricingPopup,
                    tableRowData: [...invoiceData?.tableRowData, initialRowData(uuid.v4(), data)]
                })
        }
    }
    const addNewBillLine = () => {
        setInvoiceData({ ...invoiceData, pricingPopup: !invoiceData?.pricingPopup })
    }

    const removeObjectFromArray = (item) => {
        let tempTotalTaxableAmount = 0
        let tempTotalTaxes = 0
        let tempTotalAmount = 0

        tempTotalTaxableAmount = Number(invoiceData?.totalTaxableAmount) - Number(item?.taxableAmount)

        tempTotalTaxes = Number(invoiceData?.totalTaxes) - Number(item?.taxes)

        tempTotalAmount = Number(invoiceData?.totalAmount) - Number(item?.subtotal)

        setInvoiceData({
            ...invoiceData,
            tableRowData: invoiceData?.tableRowData?.filter(obj => {
                return obj.rowid !== item?.rowid;
            }),
            totalTaxableAmount: tempTotalTaxableAmount.toFixed(2),
            totalTaxes: tempTotalTaxes.toFixed(2),
            totalAmount: tempTotalAmount.toFixed(2)
        }
        )
    };

    const setRowData = (numb, item, e) => {
        let value = e === "desc" ? numb : (numb.length < 0 ? "" : parseFloat(numb))

        let i = 0
        invoiceData?.taxGroup?.vat_group_item.map((e) => {
            i = i + (e?.rate ?? 0)
            return false
        })


        let tempTableRowData = invoiceData?.tableRowData?.map(obj => {
            let totalBeforeDiscount = 0
            let discount = 0
            let tempTaxableAmount = 0
            let tempTaxes = 0
            let tempSubtotal = 0
            if (obj.rowid === item.rowid) {

                switch (e) {
                    case "qty":
                        totalBeforeDiscount = checkNumber(value) * checkNumber(item?.amount)

                        discount = totalBeforeDiscount * (checkNumber(item?.discount) / 100)

                        tempTaxableAmount = totalBeforeDiscount - discount

                        tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)

                        tempSubtotal = tempTaxableAmount + tempTaxes

                        return {
                            ...obj,
                            [e]: value,
                            taxableAmount: tempTaxableAmount.toFixed(2),
                            taxes: tempTaxes.toFixed(2),
                            subtotal: tempSubtotal.toFixed(2),
                        };

                    case "amount":
                        totalBeforeDiscount = checkNumber(value) * checkNumber(item?.qty)

                        discount = totalBeforeDiscount * (checkNumber(item?.discount) / 100)

                        tempTaxableAmount = totalBeforeDiscount - discount

                        tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)

                        tempSubtotal = tempTaxableAmount + tempTaxes

                        return {
                            ...obj,
                            [e]: value,
                            taxableAmount: tempTaxableAmount.toFixed(2),
                            taxes: tempTaxes.toFixed(2),
                            subtotal: tempSubtotal.toFixed(2),
                        };
                    case "discount":
                        totalBeforeDiscount = checkNumber(item?.qty) * checkNumber(item?.amount)

                        discount = totalBeforeDiscount * (checkNumber(value) / 100)

                        tempTaxableAmount = totalBeforeDiscount - discount

                        tempTaxes = tempTaxableAmount * (checkNumber(i) / 100)

                        tempSubtotal = tempTaxableAmount + tempTaxes

                        return {
                            ...obj,
                            [e]: value,
                            taxableAmount: tempTaxableAmount.toFixed(2),
                            taxes: tempTaxes.toFixed(2),
                            subtotal: tempSubtotal.toFixed(2),
                        };

                    default:
                        return { ...obj, [e]: value };
                }
            }
            return obj
            // eslint-disable-next-line
        })

        let tempTotalTaxableAmount = 0
        let tempTotalTaxes = 0
        let tempTotalAmount = 0

        tempTableRowData?.map((row) => {
            tempTotalTaxableAmount = Number(tempTotalTaxableAmount) + Number(row?.taxableAmount)

            tempTotalTaxes = Number(tempTotalTaxes) + Number(row?.taxes)

            tempTotalAmount = Number(tempTotalAmount) + Number(row?.subtotal)
            return false
        })

        setInvoiceData({
            ...invoiceData,
            tableRowData: tempTableRowData,
            totalTaxableAmount: tempTotalTaxableAmount,
            totalTaxes: tempTotalTaxes,
            totalAmount: tempTotalAmount
        });
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item md={12} sm={12}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack spacing={1}>
                            <Stack direction={"row"} spacing={1}
                                className={invoiceData?.invoiceType === "Credit" ? classes.creditType : invoiceData?.invoiceType === "Invoice" ? classes.invoiceType : classes.debitType}>
                                {
                                    icons[invoiceData?.invoiceType]
                                }
                                <Typography className={invoiceData?.invoiceType === "Credit" ? classes.creditText : invoiceData?.invoiceType === "Invoice" ? classes.invoiceText : classes.debitText}>
                                    {invoiceData?.invoiceType} Free Text Invoice Creation
                                </Typography>
                            </Stack>
                            <Box>
                                {!invoiceData.showRemark && <Typography className={classes.addreason} onClick={() => setInvoiceData({ ...invoiceData, showRemark: !invoiceData?.showRemark })}> + Add Reason </Typography>}
                                {invoiceData.showRemark && <TextField variant="standard" color="primary" fullWidth focused className={classes.remarktxt}
                                    disabled={type === "view" ? true : false}
                                    value={invoiceData?.description}
                                    onChange={(value) => setInvoiceData({ ...invoiceData, description: value.target.value })} />}
                            </Box>
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                            {type === "" && <Button variant="contained" className={state?.type === "edit" ? classes.noneBtn : classes.topBtn} disabled={!invoiceData?.step3} onClick={createInoice}>
                                {t("CreateInvoice")}
                            </Button>}
                            {(type === "view" && viewData?.status === "Published") && <Box className={classes.editIcon} onClick={editDetailFromView}>
                                <EditIcon />
                            </Box>}
                            {(type === "view" && viewData?.status === "Published") && <Button variant="contained" className={classes.topBtn} onClick={() => publishInvoice(invoiceData?.id)}>
                                {t("PublishInvoice")}
                            </Button>}
                            {state?.type === "edit" && <Button variant="contained" className={classes.topBtn} onClick={createInoice}>
                                {t("Save")}
                            </Button>}
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item md={12} sm={12} className={classes.bodyContent}>
                    <Grid container spacing={2}>
                        <Grid item md={6} sm={6}>
                            {
                                !invoiceData?.step1 ?
                                    <StepCard
                                        step={t("step1")}
                                        content={t("AccountDetails")}
                                        btntext={t("ChooseAccountType")}
                                        svgIcon={<Step1IconSvg />}
                                        click={() => setInvoiceData({ ...invoiceData, accountType: !invoiceData.accountType })} />
                                    :
                                    <ListTable data={ListTableData}
                                        components={[
                                            {
                                                tableLabel: t("CustomerNumber"),
                                                component: "label",
                                                content: invoiceData?.selectedAccountDetails?.account_no ?? "-",
                                                editIcon: state?.type === "create" && true,
                                                editDetail: editAccountDetail
                                            },
                                            {
                                                tableLabel: t("Details"),
                                                component: "label",
                                                content: invoiceData?.selectedAccountDetails?.name ?? "-"
                                            },
                                            {
                                                tableLabel: "",
                                                component: "label",
                                                content: invoiceData?.selectedAccountDetails?.door_no +
                                                    invoiceData?.selectedAccountDetails?.door_no !== null ? ", " : "-" +
                                                        invoiceData?.selectedAccountDetails?.street_1 ?? "-" +
                                                        invoiceData?.selectedAccountDetails?.street_1 !== null ? ", " : "-"
                                            },
                                            {
                                                tableLabel: "",
                                                component: "label",
                                                content: invoiceData?.selectedAccountDetails?.street_2 ?? "-" +
                                                    invoiceData?.selectedAccountDetails?.street_2 !== null ? ", " : "-" +
                                                        invoiceData?.selectedAccountDetails?.city ?? "-" +
                                                        invoiceData?.selectedAccountDetails?.city !== null ? ", " : "-"
                                            },
                                            {
                                                tableLabel: "",
                                                component: "label",
                                                content: invoiceData?.selectedAccountDetails?.state ?? "-"
                                            },
                                            {
                                                tableLabel: t("MobileNumber"),
                                                component: "label",
                                                content: `${invoiceData?.selectedAccountDetails?.mobile_no_country_code ?? ""} ${invoiceData?.selectedAccountDetails?.mobile_no ?? ""}`
                                            },
                                            {
                                                tableLabel: t("Email"),
                                                component: "label",
                                                content: invoiceData?.selectedAccountDetails?.email_id ?? "-"
                                            },
                                            {
                                                tableLabel: t("TaxNumber"),
                                                component: "label",
                                                content: invoiceData?.selectedAccountDetails?.tax_number ?? "-"
                                            }
                                        ]} />
                            }
                        </Grid>
                        <Grid item md={6} sm={6}>
                            {!invoiceData?.step2 ?
                                <StepCard
                                    step={t("step2")}
                                    content={t("AgreementAndInvoiceType")}
                                    btntext={t("AddAgreementAndInvoicetype")}
                                    svgIcon={<Step2IconSvg />}
                                    click={openStep2} />
                                : <ListTable
                                    components={[
                                        {
                                            component: "dropdown",
                                            loadOptions: (search, array, handleLoading) => loadOptions(
                                                search,
                                                array,
                                                handleLoading,
                                                AGREEMENT_NUMBER,
                                                "agreement",
                                                { agreement_id: invoiceData?.selectedAccountDetails?.account_no },
                                                {},
                                                {},
                                                manualResponse
                                            ),
                                            isPaginate: true,
                                            noBorder: true,
                                            invoiceData: invoiceData,
                                            borderRadius: false,
                                            placeholder: t("AgreementNo"),
                                            label: "",
                                            onChange: (value) => { updateState("agreementNo", value) },
                                            isReadOnly: type === "view" ? true : false,
                                            tableLabel: "Agreement Number",
                                            value: invoiceData?.agreementNo
                                        },
                                        {
                                            component: "dropdown",
                                            loadOptions: (search, array, handleLoading) =>
                                                loadOptionsApis(
                                                    "/enum",
                                                    { "enumName": [enum_types.invoice_charge_type] },
                                                    search,
                                                    array,
                                                    handleLoading,
                                                    "data",
                                                    {},
                                                    enummanualResponse
                                                ),
                                            isPaginate: true,
                                            noBorder: true,
                                            invoiceData: invoiceData,
                                            borderRadius: false,
                                            placeholder: t("InvoiceType"),
                                            label: "",
                                            onChange: (value) => { updateState("invoiceNo", value) },
                                            isReadOnly: type === "view" ? true : false,
                                            tableLabel: t("InvoiceType"),
                                            value: invoiceData?.invoiceNo
                                        },
                                        {
                                            component: "dropdown",
                                            loadOptions: (search, array, handleLoading) => loadOptions(
                                                search,
                                                array,
                                                handleLoading,
                                                TAX_GROUP,
                                                "vat_group_master"
                                            ),
                                            isPaginate: true,
                                            noBorder: true,
                                            invoiceData: invoiceData,
                                            borderRadius: false,
                                            placeholder: t("TaxGroup"),
                                            label: "",
                                            onChange: (value) => { updateState("taxGroup", value) },
                                            isReadOnly: type === "view" ? true : false,
                                            tableLabel: t("TaxGroup"),
                                            value: invoiceData?.taxGroup
                                        },
                                        {
                                            component: "datepicker",
                                            selectedDate: setDueDate,
                                            tableLabel: t("DueDate"),
                                            value: invoiceData?.due_date,
                                            isReadOnly: type === "view" ? true : false
                                        },
                                        {
                                            tableLabel: t("InvoiceNumber"),
                                            component: "label",
                                            content: invoiceData?.invoiceNumber
                                        },
                                        {
                                            tableLabel: t("InvoiceAmount"),
                                            component: "label",
                                            content: invoiceData?.invoiceAmount
                                        },
                                        {
                                            tableLabel: t("PeriodTo"),
                                            component: "label",
                                            content: invoiceData?.periodTo
                                        },
                                        {
                                            tableLabel: t("PeriodFrom"),
                                            component: "label",
                                            content: invoiceData?.periodFrom
                                        }

                                    ]} />
                            }
                        </Grid>
                        <Grid item md={12} sm={12} className='Mui-disabled'>
                            {!invoiceData?.step3 ?
                                <StepCard
                                    invoiceData={invoiceData}
                                    step={t("step3")}
                                    content={t("PropertyUnitSelection")}
                                    btntext={t("AddPropertyORUnit")}
                                    svgIcon={ !invoiceData?.step2 ? <Step3IconDisabledSvg /> : <Step3IconSvg />}
                                    stepNumber="3"
                                    click={openStep3} />
                                : <PropertyUnitDetails
                                    data={invoiceData?.propertyUnitDetail}
                                    edit={() => setInvoiceData({ ...invoiceData, unitPopup: !invoiceData?.unitPopup })}
                                />}
                        </Grid>
                        {invoiceData?.step3 && <Grid item md={12} sm={12}>
                            <PropertyUnitDetails
                                data={type === "view" ? propertyUnitListDetailView : propertyUnitListDetail}
                                type={type === "view" ? "view" : "addLine"}
                                addClick={addNewBillLine}
                                setRowData={setRowData}
                                removeObjectFromArray={removeObjectFromArray}
                                tableRowData={invoiceData?.tableRowData} />
                        </Grid>}
                        {invoiceData?.step3 && <Grid item sm={12} xs={12} md={12} display="flex" justifyContent={"right"} alignItems="right">
                            <TotalTable invoiceData={invoiceData} selectedCompany={selectedCompany} />
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>


            <AlertDialog open={invoiceData?.accountType}
                onClose={() => setInvoiceData({ ...invoiceData, accountType: !invoiceData.accountType })}
                header={t("CreateFreeText")}
                component={<ChoosePopup data={AccountType}
                    click={selectType} />}
                isNormal
            />
            <AlertDialog open={invoiceData?.showCustomerList}
                onClose={() => setInvoiceData({ ...invoiceData, showCustomerList: !invoiceData?.showCustomerList })}
                header={t("AddCustomer")}
                component={<CustomerPopup
                    selectCustomer={selectCustomer}
                    type="Customer"
                    btntext={t("Add")}
                    placeholder={t("SearchByCustomernameAccountnoMobileno")}
                    selectedCompany={selectedCompany}
                    click={selectType} />}
                isNormal
            />

            <AlertDialog open={invoiceData?.showVendorList}
                onClose={() => setInvoiceData({ ...invoiceData, showVendorList: !invoiceData?.showVendorList })}
                header={t("AddVendor")}
                component={<CustomerPopup
                    selectCustomer={selectCustomer}
                    type="Vendor"
                    btntext={t("Add")}
                    placeholder={t("SearchByCustomernameAccountnoMobileno")}
                    selectedCompany={selectedCompany} />}
                isNormal
            />

            <AlertDialog open={invoiceData?.unitPopup}
                onClose={() => setInvoiceData({ ...invoiceData, unitPopup: !invoiceData?.unitPopup })}
                header={t("AddPropertyORUnit")}
                component={<CustomerPopup
                    selectCustomer={selectUnit}
                    type="Unit"
                    selectedCompany={selectedCompany}
                    btntext={t("AddPropertyORUnitDetails")}
                    placeholder={t("SelectUnit")}
                    agreementDetail={invoiceData?.agreementNo} />}
                isNormal
            />

            <AlertDialog open={invoiceData?.pricingPopup}
                onClose={() => setInvoiceData({ ...invoiceData, pricingPopup: !invoiceData?.pricingPopup })}
                header={t("AddNewBillLine")}
                component={<CustomerPopup
                    selectCustomer={selectPricing}
                    type="Pricing"
                    selectedCompany={selectedCompany}
                    btntext={t("AddNewBillLine")}
                    placeholder={t("PriceComponent")}
                    unitDetail={invoiceData?.selectedunitDetail} />}
                isNormal
            />
        </Box>
    )
}

export default withNamespaces("freeTextInvoice")(StepsContent)