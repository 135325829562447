import { makeStyles } from "@mui/styles";
import { SemiBold } from "../../utils";

export const CheckAvailabilityStyles = makeStyles((theme) => ({
    grid_border: {
        border: `1px solid ${theme.palette.border.secondary}`,
        height: 480
    },
    title: {
        fontSize: 12,
        color: theme.typography.color.tertiary,
        fontWeight: "bold",
        textTransform: "uppercase"
    },
    available_slot: {
        height: "100%"
    },
    venueSection: {
        height: `calc ( 100vh - 500px)`,
        overflow: "auto"
    }
}))

export const ViewCourtStyle = makeStyles((theme) => ({
    dot: {
        height: 6,
        width: 6,
        backgroundColor: theme.palette.border.secondary,
        borderRadius: 50
    },
    court_name: {
        fontSize: 16,
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    subname: {
        fontSize: 12,
        color: theme.typography.color.tertiary,
        fontWeight: "bold"
    },
    title: {
        fontSize: 14,
        color: theme.typography.color.tertiary,
        fontWeight: "bold"
    },
    content: {
        fontSize: 14,
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    court_title: {
        fontSize: 12,
        color: theme.typography.color.tertiary,
        fontWeight: "bold"
    }
}))

export const BookingRecidentPopupStyle = makeStyles((theme) => ({
    name: {
        fontSize: 14,
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    content: {
        fontSize: 12,
        color: theme.typography.color.tertiary
    },
    border: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4
    },
    title: {
        fontSize: 12,
        color: theme.typography.color.tertiary,
        fontWeight: "bold",
        textTransform: "uppercase"
    },
    grid_border: {
        border: `1px solid ${theme.palette.border.secondary}`,
    }
}))

export const parkingBookParentStyle = makeStyles((theme) => ({
    root: {
        border: "1px solid #E4E8EE"
    },
    subRoot: {
        borderBottom: "1px solid #E4E8EE",
        padding: "8px"
    },
    cardRoot: {
        height: 'calc(100vh - 270px)',
        overflow: "auto"
    },
    cardChild: {
        borderBottom: "1px solid #E4E8EE",
        padding: "16px"
    },
    availableSlots: {
        color: theme.typography.color.tertiary,
        fontSize: "14px",
        fontFamily: `${SemiBold}`
    },
    availableSlotsDark: {
        color: theme.typography.color.primary,
        fontSize: "14px",
        fontFamily: `${SemiBold}`
    },
    dot: {
        height: 10,
        width: 10,
        borderRadius: 50,
        marginRight: "6px"
    },
    boxRoot: {
        display: "flex",
        alignItems: "center"
    }

}))