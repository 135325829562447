import { useApolloClient } from "@apollo/client";
import { Badge, Box, Button, Divider, Grid, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import React from "react";
import FilterIMG from "../../../../../assets/filter";
import { AlertDialog, FilterGenerator, LoadingSection, SearchFilter, SelectBox, TableWithPagination, TextBox, ToggleButtonComponent, UseDebounce } from "../../../../../components";
import { config } from "../../../../../config";
import { AlertContext, BackdropContext } from "../../../../../contexts";
import { UPDATE_UTILITIY_UTILITIES } from "../../../../../graphql/mutations";
import { GET_UNIT_UTILITIES_TABLE_LIST, GET_UTILITIES_MASTER_BY_COMPANY } from "../../../../../graphql/queries";
import { NetworkCall } from "../../../../../networkcall/index";
import { AlertProps, enumSelect, enum_types, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions, Utilityheading, Utilitypath } from "../../../../../utils";
import { UtilityStyles } from "./style";


const initialState = () => {
    return {
        id: null,
        utilityName: "",
        serialNumber: "",
        price: null,
        period: "",
        status: "Active",
        utilityProvider: "Internal",
        description: "",
        edit: false,
        view: false,
        error: {
            utilityName: "",
            serialNumber: "",
            price: "",
            period: "",
            status: "",
            description: "",
            utilityProvider: ""

        }
    }
}

const STATUS_OPTIONS = [
    { label: "Active", value: "Active" },
    { label: "In-Active", value: "Inactive" }

]
export const UtilityList = (props) => {
    const classes = UtilityStyles();
    const size = useWindowDimensions();
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const [UnitUtility, setUnitUtility] = React.useState({ ...initialState() })
    const [unitUtilitylist, setUnitUtilityList] = React.useState({
        data: [],
        count: 0,
    })
    const [openDialog, setOpenDialog] = React.useState(false)
    const [drawer, setDrawer] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = React.useState(true)
    const [limit, setLimit] = React.useState(10);
    const debounce = UseDebounce();
    const [filterData, setFilterData] = React.useState({ status: [true] });
    const userProfileId = localStorage.getItem(LocalStorageKeys.userProfileID)
    const companyId = props?.companyId
    const currencyId = props?.currency?.id
    const unitId = props?.unitID
    const currencySymbol = props?.currency?.symbol
    const client = useApolloClient()
    const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
    const [enumValue, setEnumValue] = React.useState({
        period: [],
        utilityProvider: []
    })



    // state updation
    const updateState = (key, value) => {
        let error = UnitUtility?.error;
        error[key] = "";
        setUnitUtility({ ...UnitUtility, [key]: value })
    }
    //validation
    const validate = () => {
        let isValid = true;
        let error = UnitUtility.error;
        if (UnitUtility?.utilityName?.length === 0) {
            isValid = false;
            error.utilityName = "Utility Name  is Required";
        }

        if (UnitUtility?.price?.length === 0) {
            isValid = false;
            error.price = "Price is Required";
        }
        if (UnitUtility?.period?.length === 0) {
            isValid = false;
            error.period = "Period is Required";
        }
        if (UnitUtility?.serialNumber?.length === 0) {
            isValid = false;
            error.serialNumber = "SerialNumber is Required";
        }
        if (UnitUtility?.status?.length === 0) {
            isValid = false;
            error.status = "status is Required";
        }
        if (UnitUtility?.utilityProvider?.length === 0) {
            isValid = false;
            error.utilityProvider = "utility provider is Required";
        }


        if (UnitUtility?.utilityName?.length === 0
            || UnitUtility?.serialNumber?.length === 0
            || UnitUtility?.period?.length === 0 || UnitUtility?.price?.length === 0
            || UnitUtility?.status === null || UnitUtility?.utilityProvider === null
        ) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please fill all mandatory field",
            });
        }
        setUnitUtility({ ...UnitUtility, error });

        return isValid;
    };
    //pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        UnitUtilitiesTableData(offset, limit, searchText)
    }
    //page limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        UnitUtilitiesTableData(0, value, searchText)
    }
    //search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        UnitUtilitiesTableData(0, limit, e)
    }
    //table listing function
    const UnitUtilitiesTableData = async (offset = 0, limit = 10, search = "") => {
        let is_active = filterData?.status ?? [];
        const payload = {
            query: GET_UNIT_UTILITIES_TABLE_LIST(clientId, offset, limit, search, unitId, is_active).loc.source.body,
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                const list = response?.data?.data?.unit_utilties?.map((val) => {
                    let _d;
                    try {
                        _d = {
                            main: val?.price,
                            utilityName: val?.utilities_master?.label ?? "-",
                            serialNumber: val?.meter_serial_no ?? "-",
                            price: val?.price ? `${currencySymbol}  ${val?.price} ` : "-",
                            period: val?.period ?? "-",
                            status: val?.is_active ? "Active" : "Inactive",
                            description: val?.description ?? "-",
                            icon: "more",
                            id: val?.id,
                            IsDelete: val?.is_delete,
                            utility: val?.utilities_master ?? "-",
                            periodd: { value: val?.period, label: val?.period },
                            utilityProvider: val?.utilties_provider,

                        };
                    } catch (err) {

                    }
                    return _d;

                })
                setUnitUtilityList({
                    data: list,
                    count: response?.data?.data?.count?.[0]?.count,

                })
                setLoading(false)
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            }).catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                });
            });
    }

    // is active changing function
    const changeactive = async (data, value) => {
        client.mutate({
            mutation: UPDATE_UTILITIY_UTILITIES,
            variables: {
                ID: data,
                updatePayload: {
                    is_active: value === "Inactive" ? true : false
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Status Updated",
            });
            UnitUtilitiesTableData()
            console.log(rs)
        }).catch(er => {
            console.log(er)
        })
    }
    //delete function
    const deleteTableData = async (data) => {
        client.mutate({
            mutation: UPDATE_UTILITIY_UTILITIES,
            variables: {
                ID: data,
                updatePayload: {
                    is_delete: true
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Deleted",
            });
            UnitUtilitiesTableData()
            console.log(rs)
        }).catch(er => {
            console.log(er)
        })
    }
    //get Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.utility_period_type, enum_types.utilties_provider])
        setEnumValue({
            period: result?.utility_period_type,
            utilityProvider: result?.utilties_provider
        })
    }
    React.useEffect(() => {
        if (props?.permission?.read) {
            if (companyId) {
                UnitUtilitiesTableData()
                getEnum()
            }
        }
        //eslint-disable-next-line
    }, [companyId, filterData, props?.permission]);
    //icon function for table
    const handleIcon = (type, data, status) => {

        if (type === "view") {
            setOpenDialog(true)
            setUnitUtility({
                ...UnitUtility,
                utilityName: data?.utility,
                serialNumber: data?.serialNumber,
                price: data?.main,
                period: data?.periodd,
                description: data?.description,
                edit: false,
                view: true,
                id: data?.id,
                status: data?.status,
                utilityProvider: data?.utilityProvider

            })
        }
        if (type === "edit") {
            setOpenDialog(true)
            setUnitUtility({
                ...UnitUtility,
                utilityName: data?.utility,
                serialNumber: data?.serialNumber,
                description: data?.description,
                price: data?.main,
                period: data?.periodd,
                edit: true,
                id: data?.id,
                status: data?.status,
                utilityProvider: data?.utilityProvider


            })
        }
        else if (type === "active") {
            changeactive(data.id, status)
        }
        else if (type === "delete") {
            deleteTableData(data.id, data.IsDelete)
        }

    }
    //apply filter
    const onApplyFilter = (value) => {
        setFilterData(value)
    }
    const [loadingDropDown, setLoadingDropDown] = React.useState(false)
    // drop down option
    const loadOptionsDropDown = async (search = "", array, type) => {
        setLoadingDropDown(type);
        let result, payload, query, offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        }
        else {
            offset = array?.length;
        }

        switch (type) {
            case 'utilities':
                if (!companyId > 0) {
                    setLoadingDropDown(null);
                    return { options: [] }
                }
                query = GET_UTILITIES_MASTER_BY_COMPANY(offset, 10, search, companyId).loc.source.body;
                payload = {
                    query,
                };
                result = await networkCallBack(payload);
                return {
                    options: [...result?.utilities_master],
                    hasMore: (array?.length + result?.utilities_master?.length) < result?.count[0]?.count
                }
            default:
                return { options: [] }
        }
    }
    const networkCallBack = async (payload, url = config.graphql_url) => {

        const options = await NetworkCall(
            `${url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {

                let main = response.data.data;
                setLoadingDropDown(null);
                return main
            })
            .catch((error) => {
                setLoadingDropDown(null);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                return null
            });

        return options
    }


    // upsert api call for utility
    const AddAndEditUnitUtility = () => {
        if (validate()) {
            let payload;
            if (UnitUtility?.id) {
                payload = {
                    tenantId: `${config.tenantId}`,
                    utility_id: UnitUtility?.utilityName?.value,
                    updated_by: userProfileId,
                    description: UnitUtility?.description.length > 0 ? UnitUtility?.description : null,
                    company_id: companyId,
                    currency_id: currencyId,
                    unit_id: unitId,
                    price: UnitUtility?.price,
                    period: UnitUtility?.period?.value,
                    meter_serial_no: UnitUtility?.serialNumber,
                    id: UnitUtility?.id,
                    is_active: UnitUtility?.status === "Active" ? true : false,
                    utilties_provider: UnitUtility?.utilityProvider


                }
            } else {
                payload = {
                    tenantId: `${config.tenantId}`,
                    utility_id: UnitUtility?.utilityName?.value,
                    description: UnitUtility?.description.length > 0 ? UnitUtility?.description : null,
                    company_id: companyId,
                    currency_id: currencyId,
                    unit_id: props?.unitID,
                    price: UnitUtility?.price,
                    period: UnitUtility?.period?.value,
                    meter_serial_no: UnitUtility?.serialNumber,
                    created_by: userProfileId,
                    is_active: UnitUtility?.status === "Active" ? true : false,
                    utilties_provider: UnitUtility?.utilityProvider


                }
            }
            NetworkCall(
                `${config.api_url}/unit-utils/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: "Unit utility  Added successfully.",
                    });
                    setUnitUtility({ ...initialState() })
                    setOpenDialog(false)
                    UnitUtilitiesTableData()

                })
                .catch((error) => {
                    console.log(error?.errors?.errors?.map((x) => {
                        return x?.msg
                    }))
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Internal error. Please try again later.",
                    });

                });

        }
    }

    // add and edit modal
    const addAndEdit = () => {
        return (
            <>
                <div style={{
                    height: size.height - 250,
                    overflow: "scroll",
                    padding: "24px"
                }}>
                    <Grid container spacing={2}
                    >
                        <Grid item xs={12}>
                            <SelectBox
                                label="Utility Name"
                                placeholder="Select Utility name"
                                value={UnitUtility?.utilityName}
                                onChange={(value) => {
                                    updateState("utilityName", value);
                                }}
                                isError={UnitUtility?.error?.utilityName?.length > 0}
                                errorMessage={UnitUtility?.error?.utilityName}
                                loading={loadingDropDown === "utilities"}
                                isPaginate
                                debounceTimeout={800}
                                loadOptions={(search, array) => loadOptionsDropDown(search, array, 'utilities')}
                                isReadOnly={UnitUtility?.view}
                                isRequired
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom className={classes.label} >
                                Utility Provider
                                <Typography className={classes.required} variant="caption" >
                                    *
                                </Typography>
                            </Typography>
                            <ToggleButtonComponent
                                options={enumValue?.utilityProvider}
                                value={UnitUtility?.utilityProvider}
                                onChange={(value) => updateState('utilityProvider', value)}
                                isMulti={false}
                                fullWidth={false}
                                isError={UnitUtility?.error?.utilityProvider?.length > 0}
                                errorMessage={UnitUtility?.error?.utilityProvider}
                                isReadOnly={UnitUtility?.view}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextBox
                                label="Serial number"
                                placeholder="enter serial number"
                                value={UnitUtility?.serialNumber}
                                isrequired
                                onChange={(e) => {
                                    updateState("serialNumber", e.target.value);
                                }}
                                isError={UnitUtility?.error?.serialNumber?.length > 0}
                                errorMessage={UnitUtility?.error?.serialNumber}
                                isReadonly={UnitUtility?.view}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextBox
                                label="Price"
                                placeholder="enter price"
                                endAdornment={<InputAdornment position="end">{"/" + currencySymbol}</InputAdornment>}
                                value={UnitUtility?.price}
                                isrequired
                                onChange={(e) => {
                                    updateState("price", e.target.value);
                                }}
                                isError={UnitUtility?.error?.price?.length > 0}
                                errorMessage={UnitUtility?.error?.price}
                                isReadonly={UnitUtility?.view}
                                type={"number"}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectBox
                                label="Period"
                                placeholder="select Period"
                                options={enumValue?.period}
                                value={UnitUtility?.period}
                                onChange={(value) => {
                                    updateState("period", value);
                                }}
                                isError={UnitUtility?.error?.period?.length > 0}
                                errorMessage={UnitUtility?.error?.period}
                                isReadOnly={UnitUtility?.view}
                                isRequired

                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextBox multiline
                                value={UnitUtility?.description ?? ""}
                                label="Description"
                                placeholder="Enter Description"
                                onChange={(e) => {
                                    updateState("description", e.target.value);
                                }}
                                isError={UnitUtility?.error?.description?.length > 0}
                                errorMessage={UnitUtility?.error?.description}
                                isReadonly={UnitUtility?.view}
                                padding={"12px"}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom className={classes.label}>
                                Status
                                <Typography variant="caption" className={classes.required}>
                                    *
                                </Typography>
                            </Typography>
                            <ToggleButtonComponent
                                options={STATUS_OPTIONS}
                                value={UnitUtility?.status}
                                onChange={(value) => updateState('status', value)}
                                isMulti={false}
                                fullWidth={false}
                                isError={UnitUtility?.error?.status?.length > 0}
                                errorMessage={UnitUtility?.error?.status}
                                isReadOnly={UnitUtility?.view}
                            />
                        </Grid>



                    </Grid>
                </div>
                {(((UnitUtility?.edit || UnitUtility?.view) && props?.permission?.update) ||
                    (!UnitUtility?.view && !UnitUtility?.edit && props?.permission?.create)) &&
                    <Grid container padding={"24px"}>
                        <Grid item xs={12}>
                            <Button fullWidth variant="contained" className={classes.btn}
                                onClick={
                                    UnitUtility?.view ? () =>
                                        setUnitUtility({
                                            ...UnitUtility,
                                            view: false,
                                            edit: true
                                        })
                                        : AddAndEditUnitUtility}
                            >
                                {UnitUtility?.view ? "Edit" : UnitUtility?.edit ? "Update" : "Create"}
                            </Button>
                        </Grid>
                    </Grid>}
            </>
        )
    }

    return (
        <>
            {
                loading ? <LoadingSection bottom={"45vh"} message="Loading Unit Listing..." /> :
                    <div>
                        <Box p={2} className={classes.root} marginTop={2}>
                            <Grid container >
                                <Grid item xs={4}>
                                    <SearchFilter value={searchText} placeholder="Search Units" handleChange={(value) => handleSearch(value)} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Box
                                        display={"flex"}
                                        sx={{ float: "right" }}>
                                        <Stack
                                            direction="row"
                                            divider={<Divider orientation="vertical" flexItem />}
                                            spacing={2}>
                                            {filterData.status?.length > 0 || filterData.property_type?.length > 0 ? (
                                                <IconButton onClick={() => setDrawer(true)} className={classes.img}>
                                                    <Badge variant="dot" color="primary">
                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton>

                                            ) : (
                                                <IconButton onClick={() => setDrawer(true)} className={classes.img}>
                                                    <FilterIMG color="#091b29" />
                                                </IconButton>)}
                                            {props?.permission?.create &&
                                                <Button
                                                    variant="contained"
                                                    // sx={{ borderRadius: theme.palette.borderRadius }}
                                                    className={classes.btn}
                                                    onClick={() => setOpenDialog(true)}
                                                >
                                                    Add Utility
                                                </Button>}

                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                            <TableWithPagination
                                heading={Utilityheading}
                                rows={unitUtilitylist?.data}
                                path={Utilitypath}
                                showpagination={true}
                                count="2"
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleIcon}
                                tableType="no-side"
                                onClick={() => console.log("")}
                                dataType={[
                                    { type: ["text"], name: "utilityName" },
                                    { type: ["text"], name: "utilityProvider" },
                                    { type: ["text"], name: "serialNumber" },
                                    { type: ["text"], name: "price" },
                                    { type: ["text"], name: "period" },
                                    { type: ["status"], name: "status" },
                                    { type: ["more"], icon: "icon" },

                                ]}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={unitUtilitylist?.count}
                                page={page}
                                limit={limit}
                                height={`calc(100vh - 395px)`}
                                view={props?.permission?.read}
                                edit={props?.permission?.update}
                                delete={props?.permission?.delete} />
                        </Box>
                    </div>
            }
            {/* filter drawer */}
            {
                drawer &&
                <FilterGenerator
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    components={[

                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: [
                                { label: 'Active', value: true },
                                { label: 'Inactive', value: false },
                            ],
                            isMulti: true,
                            state_name: "status",
                            label: "Status",
                            // required:true
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            }
            {/* add and edit  and view */}
            <AlertDialog
                isNormal
                header={UnitUtility?.view ? "View Utility" : UnitUtility?.edit ? "Edit Utility" : "Add Utility"}
                onClose={() => {
                    setOpenDialog(false)
                    setUnitUtility({ ...initialState() })
                }}
                open={openDialog}
                component={
                    <>
                        <div>
                            {
                                addAndEdit()
                            }
                        </div>
                    </>
                }
            />
        </>
    );
};
