import { Box, Button } from "@mui/material";
import React from "react";
import { DetailsCardCoponent, LoadingSection } from "../../../components";
import { AssetView } from "./assetView";
import { VehicleMasterComponentStyles } from "./styles";

export const ViewVehicles = ({ data = "", onEdit = () => false, name = "", selected = {}, changeActive = () => false, permissions = {} }) => {
    const classes = VehicleMasterComponentStyles();
    const [loading, setLoading] = React.useState(true)

    setTimeout(() => {
        setLoading(false)
    }, 400);


    const card = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "text",
            heading: "Vehicle Type",
            subtitle: `${selected?.vehicle_type}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "text",
            heading: "Vehicle Name",
            subtitle: `${selected?.name}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "text",
            heading: "Vehicle ID",
            subtitle: `${selected?.vehicle_id}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "text",
            heading: "cost",
            subtitle: `${selected?.cost}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 4
            },
            isActive: selected?.description,
            component: "text",
            heading: "Description",
            subtitle: `${selected?.description}`
        },

    ]

    return (
        <Box>
            {
                loading ?
                    <LoadingSection top="30vh" bottom="30vh" />
                    :
                    <Box>
                        {selected?.vehicle_assets?.length > 0 &&
                            <Box p={2}>
                                <AssetView
                                    asset={selected?.vehicle_assets}
                                />
                            </Box>
                        }
                        <DetailsCardCoponent components={card} />
                        <Box display="flex" className={`${classes.btngrp}`} p={2}>
                            {
                                permissions?.delete && <Button variant="outlined" className={`${classes.btn_outlined}`} onClick={() => changeActive(selected)}>{selected?.is_active ? "Delete" : "Active"}</Button>
                            }
                            {
                                permissions?.update && <Button variant="contained" className={`${classes.btn_contained}`} onClick={() => onEdit(selected)}>Edit</Button>
                            }



                        </Box>
                    </Box>
            }

        </Box>
    )
}