import useTheme from "@mui/material/styles/useTheme";
import Select, { components } from "react-select";
import { Regular, SemiBold } from "../../../utils";
import React from 'react'
import { Box, Stack } from "@mui/material";


const Option = ({ children, ...props }) => {
    return (
        <components.Option {...props}>
            <Stack
                direction={"row"}
                alignItems={"center"}
            >
                <Box display="flex" alignItems="center">
                    <Box>
                        <input type="checkbox" checked={props?.isSelected} />
                    </Box>
                    <Box marginLeft={"10px"}>
                        {children}
                    </Box>
                </Box>
            </Stack>
        </components.Option>
    );
};

export const ReportCheckBox = React.memo((props) => {
    const { options, onChange, value, styles, placeholder } = props
    const theme = useTheme();

    const customStyles = (props) => {
        return {
            control: (base) => ({
                ...base,
                borderColor:
                    props?.noBorder ? "white" : "#E4E8EE",
                borderRadius: 4,
                paddingLeft: props?.noBorder ? 0 : 8,
                '&:not(:focus)': {
                    boxShadow: "none"
                },
                '&:hover': {
                    borderColor:
                        props?.noBorder ? "white" : theme.palette.primary.main,
                },
                ...styles?.control ?? {},
            }),
            valueContainer: (base) => ({
                ...base,
                paddingBlock: 3
            }),
            placeholder: defaultStyles => {
                return {
                    ...defaultStyles,
                    fontFamily: `${Regular}`,
                    fontSize: 14,
                    whiteSpace: "nowrap",
                    color: "#98A0AC"
                };
            },
            menu: defaultStyles => {
                return {
                    ...defaultStyles,
                    zIndex: 2,
                    boxShadow: "0px 10px 25px #0000000A",
                    border: "1px solid #E4E8EE",
                    borderRadius: 4,
                    marginTop: "10px",
                    width: "100%",

                };
            },
            menuList: defaultStyles => {
                return {
                    ...defaultStyles,
                    maxHeight: "342.601868px !important",
                    height: "342.601868px !important"
                };
            },
            option: (defaultStyles, prop) => {
                return {
                    ...defaultStyles,
                    fontFamily: `${SemiBold}`,
                    fontSize: 14,
                    cursor: "pointer",
                    ...isSingleStyle(prop),
                };
            },
        }
    };

    const isSingleStyle = (prop) => {
        return {
            color: prop?.isSelected ? "#5078E1" : "#091B29",
            backgroundColor: "transparent",
            '&:hover,&:active': {
                backgroundColor: "transparent",
            },
        }
    }
    return (
        <div className="App">
            <Select
                defaultValue={[]}
                isMulti
                menuIsOpen={true}
                placeholder={placeholder}
                value={value}
                isClearable={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={onChange}
                options={options}
                controlShouldRenderValue={false}
                styles={customStyles(props)}
                components={{
                    Option: Option,
                    MultiValueContainer: () => null,
                }}
            />

        </div>
    );
})
