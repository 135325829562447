import { Box } from "@mui/material";
import styled from '@mui/material/styles/styled';
import React from "react";
import { MonthlyCalendar } from "./components/monthlyCalendar";
import { WeeklyCalendar } from "./components/weeklyCalendar";
import { CalendarHeader } from "./components/calendarHeader";
import TableView from "./components/tableView";
import { DailyCalendar } from "./components/dailyCalendar";
const CustomPaper = styled('div')(({ theme }) => ({
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 202px)`,
    overflow: "hidden",
    marginTop: "10px"
}))
export const ActivityCalendar = (
    {
        openPopup = () => false,
        openDeclinePopup = () => false,
        parkingGroupData = [],
        selectedParkingDropdown = () => false,
        selectedParking = () => false,
        parkingSlotData = {},
        loading = false,
        daily = {},
        nextdate = () => false,
        prevdate = () => false,
        weekprev = () => false,
        weeknext = () => false,
        monthnext = () => false,
        monthprev = () => false,
        month = "",
        weekdates = {},
        dates = [],
        monthdays = [],
        calendarData = [],
        setSearchValueFunc = () => false,
        searchValue = '',
        searchText = "",
        handleSearch = () => false,
        handlePagination = () => false,
        handleChangeLimit = () => false,
        page = "",
        limit = "",
        open = "",
        select = "",
        selected = "",
        setselect = () => false,
        showAll = () => false,
        setDropdownValue = () => false,
        tableData = {},
        handleSelected = () => false,
        handleIcon = () => false,
        calendarFunctions = () => false,
        selectedCompany={}
    }
) => {


    return (
        <Box p={2}>
            <CalendarHeader
                selected={selected}
                weekprev={weekprev}
                weeknext={weeknext}
                monthnext={monthnext}
                monthprev={monthprev}
                month={month}
                weekdates={weekdates}
                setDropdownValue={setDropdownValue}
                setselect={setselect}
                select={select}
                showAll={showAll}
                open={open}
                daily={daily.date}
                nextdate={nextdate}
                prevdate={prevdate}
                calendarFunctions={calendarFunctions}
                selectedCompany={selectedCompany}
            // parkingGroupData={parkingGroupData}
            // selectedParkingDropdown={selectedParkingDropdown}
            // searchValue={searchValue}
            // setSearchValueFunc={setSearchValueFunc}

            />
            <CustomPaper>
                {
                    select === "nonlist" &&
                    <Box>
                        <Box>
                            {
                                selected === "Weekly" &&
                                <WeeklyCalendar weekdates={dates}
                                    data={calendarData}
                                    openDeclinePopup={openDeclinePopup}
                                    loading={loading}
                                    handleSelected={handleSelected} />
                            }
                        </Box>
                        <Box>
                            {
                                selected === "Monthly" &&
                                <MonthlyCalendar dates={monthdays} month={month}
                                    data={calendarData}
                                    openDeclinePopup={openDeclinePopup}
                                    loading={loading}
                                    handleSelected={handleSelected} />
                            }
                        </Box>
                        <Box>
                            {
                                selected === "Daily" &&
                                <DailyCalendar progress="0" list={calendarData} openDeclinePopup={openDeclinePopup} />
                            }
                        </Box>
                    </Box>}
                {select === "list" &&
                    <Box>
                        <TableView searchText={searchText}
                            handleSearch={handleSearch}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            page={page}
                            limit={limit}
                            tableData={tableData}
                            handleIcon={handleIcon} />
                    </Box>
                }
            </CustomPaper>
        </Box>
    )
}