import { Stack, Box, Avatar, Typography, Icon } from '@mui/material'
import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { UserListStyles } from './style'
export const UserList = ({ handleClick = {}, userAlert = {}, empList = {} }) => {
    const classes = UserListStyles()
    return (

        <Box className={userAlert ? classes.card_alert : classes.card} onClick={handleClick}>
            {
                empList.map(data => {
                    return (
                        <Stack p={1} direction={"row"} spacing={4} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack direction={"row"} spacing={1}>
                                {
                                    userAlert ?
                                        <Avatar className={classes.avatar} src={data.image} />
                                        :
                                        <Avatar className={classes.avatar} src={data.image} variant='square' />
                                }

                                <Stack>
                                    <Typography className={classes.name}> {data.name} </Typography>
                                    <Typography className={classes.role}> {data.role} </Typography>
                                </Stack>
                            </Stack>
                            <Stack>
                                {
                                    userAlert ? <Icon className={userAlert ? classes.check_circle : classes.circle_icon_hidden}>check_circle</Icon>
                                        : <KeyboardArrowRightIcon className={classes.arrow} />
                                }

                            </Stack>
                        </Stack>
                    )
                })
            }
        </Box>
    )
}