import { Box, Divider, Typography,Button } from "@mui/material";
import { useStyles, DayCalendarStyles } from "./style";
import { CalProgressbar } from "./calProgressbar";
import { Stack } from "@mui/system";
import { SwitchIcons } from "../../../utils/timeOffRequestList";
import React from "react";
import { HtmlTooltip } from "./dayCalendar";
import ArrowLeftTwoToneIcon from '@mui/icons-material/ArrowLeftTwoTone'
import moment from "moment";



export const ReturnDetail = ({ val = {}, open_id = '', onClick = () => false }) => {

    const classes = useStyles();
    

    const switchComponents = () => {
        if (val?.is_booked_leave) {

            return(
                <HtmlTooltip
                title={<React.Fragment><CustomToolTipBookedLeaves onClick={() => onClick()} data={val} /></React.Fragment>}
                placement='right-start'

            >
                <Box className={open_id === val?.id ? classes.leaveRootHover : classes.leaveRoot}>
                <CalProgressbar value={val?.percentage} />

            </Box>
            </HtmlTooltip>
            )
        } else if (val?.is_booked) {
            return (
                open_id === val?.id ?
                    <Box className={classes.cell_hover} onClick={() => onClick()}>
                        View Schedule
                    </Box>
                    :
                    <Box className={classes.cells}>
                        <CalProgressbar value={val?.percentage} />
                    </Box>
            )
        } else if (val?.is_leave) {
            return (
                
                <HtmlTooltip

                    title={
                        <React.Fragment>
                            <CustomToolTip data={val} />
                        </React.Fragment>
                    }
                    placement='right-start'

                >
                    <Box className={open_id === val?.id ? classes.leaveRootHover : classes.leaveRoot}>

                        <Stack direction="row" alignItems="center" spacing={1}>
                            {SwitchIcons(val?.name)}
                            <Typography className={classes.leave_name}>{val?.name}</Typography>
                        </Stack>

                    </Box>
                </HtmlTooltip>
            )
        }


    }

    return (
        <Box>
            {switchComponents()}
        </Box>
    )
}


export const CustomToolTip = ({ data }) => {
    const classes = DayCalendarStyles();
    const classes1 = useStyles();
    return (
        <Box className={classes.toolRoot} p={1}>
            <ArrowLeftTwoToneIcon className={classes.arrow} />
            <Stack direction="row" spacing={1} padding={1} alignItems="center">
                {SwitchIcons(data?.name)}
                <Typography className={classes1.leave_name} noWrap>{data?.name}</Typography>
            </Stack>
            <Box className={classes1.dateRoot}>
                {data?.name === "Short Leave" ?
                    <>
                        <Typography className={classes1.leave_name}>Hourly Time Off on {moment(data?.date).format("DD MMM YY")} </Typography>
                        <Typography className={classes1.leave_name}>from {moment(data?.start_date_time).format("HH:mm a")} to {moment(data?.end_date_time).format("HH:mm a")}</Typography>
                    </>
                    :
                    <>
                        <Typography className={classes1.leave_name}>Leave Applied for {moment(data?.date).format("DD MMM YY")} </Typography>
                        <Typography className={classes1.leave_name}> {data?.half_day_type !== null ? `Half Day (${(data?.half_day_type === "first_half" ? "First Half" : "Second Half")})` : "Full Day"}</Typography>
                    </>
                }

            </Box>
        </Box>

    )
}

export const CustomToolTipBookedLeaves = ({ data,onClick=()=>false }) => {
    const classes = DayCalendarStyles();
    const classes1 = useStyles();
    const {leave,...rest}=data;
  
    return (
        <Box className={classes.toolRoot} p={1}>
            <ArrowLeftTwoToneIcon className={classes.arrow} />
            <Stack direction="row" spacing={1} padding={1} alignItems="center">
                {SwitchIcons(leave?.[0]?.name)}
                <Typography className={classes1.leave_name} noWrap>{leave?.[0]?.name}</Typography>
            </Stack>
            <Box className={classes1.dateRoot}>
                {leave?.[0]?.name?.name === "Short Leave" ?
                    <>
                        <Typography className={classes1.leave_name}>Hourly Time Off on {moment(leave?.[0]?.name?.date).format("DD MMM YY")} </Typography>
                        <Typography className={classes1.leave_name}>from {moment(leave?.[0]?.name?.start_date_time).format("HH:mm a")} to {moment(leave?.[0]?.name?.end_date_time).format("HH:mm a")}</Typography>
                    </>
                    :
                    <>
                        <Typography className={classes1.leave_name}>Leave Applied for {moment(leave?.[0]?.name?.date).format("DD MMM YY")} </Typography>
                        <Typography className={classes1.leave_name}> {leave?.[0]?.name?.half_day_type !== null ? `Half Day (${(leave?.[0]?.name?.half_day_type === "first_half" ? "First Half" : "Second Half")})` : "Full Day"}</Typography>
                    </>
                }

            </Box>

            <Divider sx={{margin:"8px 0px"}}/>
            <Stack justifyContent="space-between" p={1} direction="row">
            <CalProgressbar value={rest?.percentage} />
            <Button className={classes1.viewScheduleBtn} onClick={onClick}>View Schedule</Button>
            </Stack>
        </Box>

    )
}