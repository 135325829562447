/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
import moment from "moment-timezone";
import React, { createContext } from "react";
import { useHistory } from "react-router-dom";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { LIST_COMPANY } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";


export const CreateLeadContext = createContext();

moment.defaultFormat = "DD MMM YY";
const CreateLeadProvider = (props) => {
  // histry
  const history = useHistory();
  // create pop up open function
  const [open, setOpen] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [leadList, setLeadList] = React.useState([]);
  const [searchdata, setSearchdata] = React.useState("");
  const [total, setTotal] = React.useState("");
  // filter
  const [openFilter, setOpenFilter] = React.useState(false);
  const [leadOwnerFilter, setleadOwnerFilter] = React.useState([]);
  const [leadNameFilter, setleadNameFilter] = React.useState([]);
  const [leadOption, setleadOption] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState(null);

  const [filterData, setFilterData] = React.useState(
    {
      comapnyname: selectedCompany,
      priority: "",
      requirmentType: "",
      Status: [],
      startDate: "",
      endData: "",
      contactName: [],
      leadOwner: [],
      error: {
        comapnyname: "",
        priority: "",
        requirmentType: "",
        Status: "",
        startDate: "",
        endData: "",
        contactName: "",
        leadOwner: "",
      },
    }
  );

  const upDateFilter = (key, value) => {
    if (key === "Status") {
      let status = [];
      status.push(value);
      setFilterData({ ...filterData, [key]: status });
    } else if (key === "priority") {
      let periorty = [];
      periorty.push(value);
      setFilterData({ ...filterData, [key]: periorty });
    } else if (key === "requirmentType") {
      let requirmentType = [];
      requirmentType.push(value);
      setFilterData({ ...filterData, [key]: requirmentType });
    } else {
      setFilterData({ ...filterData, [key]: value });
    }
  };
  const upDateOwner = (value) => {
    setleadOwnerFilter([...leadOwnerFilter, value]);
  };

  const upDateName = (value) => {
    setleadNameFilter([...leadNameFilter, value]);
  };
  const OpenDrawer = () => {
    setOpenFilter(!openFilter);
  };

  const closeDrawernewLead = () => {
    history.push(Routes.leadnew)
  }
  const CompanyLead = (val) => {
    const variables = {
      query: LIST_COMPANY,
      variables: {
        ID: val?.value,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        setleadOption(response?.data?.data?.lead);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  };

  const clearAll = () => {
    setFilterData({
      comapnyname: "",
      priority: "",
      requirmentType: "",
      Status: [],
      startDate: "",
      endData: "",
      contactName: [],
      leadOwner: [],
      error: {
        comapnyname: "",
        priority: "",
        requirmentType: "",
        Status: "",
        startDate: "",
        endData: "",
        contactName: "",
        leadOwner: "",
      },
    });
    setOpenFilter(false);
    getListData(0, 10, "", "key", selectedCompany);
  };

  const FilterInvaild = () => {
    let isValid = true;
    let error = filterData.error;
    if (filterData?.comapnyname?.length === 0) {
      isValid = false;
      error.comapnyname = "Company Name is Required";
    }
    setFilterData({ ...filterData, error });

    return isValid;
  };

  // get list data
  const getListData = (offset, limit, search, type, company, team , value) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    if (type === "filter") {
      console.log(value , 'value')
      if (FilterInvaild()) {
        setSearchdata(search);
        const payload = {
          leadOwner:
          value?.lead?.length > 0
              ? value?.lead?.map((x) => x?.value)
              : undefined,
          offset: offset,
          recordsPerPage: limit,
          companyId: company?.value,
          query: search,
          status:
          value?.status?.length > 0 ? value?.status : undefined,
          priority:
            value?.periorty?.length > 0 ? value?.periorty : undefined,
          leadStartDate:
            filterData?.startDate === "" ? undefined : filterData?.startDate,
          leadEndDate:
            filterData?.endData === "" ? undefined : filterData?.endData,
          contactId:
            filterData?.contactName.map((x) => x.value)?.length > 0
              ? filterData?.contactName.map((x) => x.value)
              : undefined,
          valueType:
            filterData.requirmentType?.length > 0
              ? filterData.requirmentType
              : undefined,

        };
        if (team?.value !== "noteams") {
          payload["team"] = team?.value
        }
        NetworkCall(
          `${config.api_url}/get-all-leads`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        )
          .then((response) => {
            let arry = [];
            if (response?.data?.data && response?.data?.data?.length > 0) {
              response.data.data.map((val, index) => {
                let obj = {
                  sno: (offset ?? 0) + index + 1,
                  index: offset + index + 1,
                  leadNo: val?.lead_no ?? "-",
                  date: val?.created_at ?? "-",
                  statusDate: val?.updated_at ?? "-",
                  description: val?.subject ?? "-",
                  purpose: val?.purpose ?? "-",
                  source: val?.lead_source ?? "-",
                  activeQuote: val?.quotation_no ?? "-",
                  value: val?.total_quote_amount ? ((val?.currency_symbol ?? "") + val?.total_quote_amount) : "-",
                  leadName: val?.name ?? "-",
                  owner: val?.leadOwner ?? "-",
                  dateOfOpen: val?.openedDate ?? "-",
                  dateOfClosed: val?.closedDate,
                  status: val?.status ?? "-",
                  priority: val?.urgent ?? "-",
                  opportunity_type: val?.revenue_type ?? "-",
                  icon: "more_5",
                  ...val,
                };
                arry.push(obj);
              });
            }
            setLeadList(arry);
            setTotal(response.data.totalLeads);
            setOpenFilter(false);
            // clearAll();
            backdrop.setBackDrop({
              ...backdrop,
              open: false,
              message: "",
            });
          })
          .catch((error) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Some Thing Went Wrong",
            });
            backdrop.setBackDrop({
              ...backdrop,
              open: false,
              message: "",
            });
          });
      }
    } else {
      setSearchdata(search);
      const payload = {
        tenantId: `${config.tenantId}`,
        leadOwner: [localStorage.getItem(LocalStorageKeys.profileID)],
        offset: offset,
        recordsPerPage: limit,
        companyId: company?.value,
        query: search,
      };
      if (team?.value !== "noteams") {
        payload["team"] = team?.value
      }
      NetworkCall(
        `${config.api_url}/get-all-leads`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          let arry = [];
          if (response?.data?.data && response?.data?.data?.length > 0) {
            response.data.data.map((val, index) => {


              let obj = {
                sno: (offset ?? 0) + index + 1,
                index: offset + index + 1,
                leadNo: val?.lead_no ?? "-",
                date: val?.created_at ?? "-",
                statusDate: val?.updated_at ?? null,
                status: val?.status ?? "-",
                description: val?.subject ?? "-",
                purpose: val?.purpose ?? "-",
                source: val?.lead_source ?? "-",
                activeQuote: val?.quotation_no ?? "-",
                value: val?.total_quote_amount ? ((val?.currency_symbol ?? "") + val?.total_quote_amount) : "-",
                leadName: val?.name ?? "-",
                owner: val?.leadOwner ?? "-",
                dateOfOpen: val?.openedDate ?? "-",
                dateOfClosed: val?.closedDate,
                priority: val?.urgent ?? "-",
                opportunity_type: val?.revenue_type ?? "-",
                icon: "more_5",
                ...val,
              };
              arry.push(obj);
            });
          }
          setLeadList(arry);
          setTotal(response.data.totalLeads);
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong",
          });
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
        });
    }
  };

  const filter = () => {

  };

  const companySelectFunction = (e) => {
    setSelectedCompany(e)
  }


  return (
    <CreateLeadContext.Provider
      value={{
        upDateFilter,
        OpenDrawer,
        openFilter,
        filterData,
        openCreate,
        getListData,
        leadList,
        closeDrawernewLead,
        filter,
        searchdata,
        total,
        setSearchdata,
        upDateOwner,
        leadOwnerFilter,
        setFilterData,
        setleadOwnerFilter,
        leadNameFilter,
        setleadNameFilter,
        upDateName,
        CompanyLead,
        leadOption,
        clearAll,
        setOpen,
        open,
        setOpenCreate,
        companySelectFunction,
        selectedCompany,
        setSelectedCompany
      }}
    >
      {props.children}
    </CreateLeadContext.Provider>
  );
};

export default CreateLeadProvider;
