import React, { useState } from "react";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useStyles } from "./style";
import { TickIcon } from "../svg";

export const SourceMenuOption = ({
  value = "",
  option = [],
  onChange = () => false,
  isReadOnly=false
}) => {
  const classes = useStyles();
  // state
  const [anchorEl, setAnchorEl] = useState(null);

  // functions
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (key, value) => {
    if (key === "save") {
      onChange(value);
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={!isReadOnly&&handleClick}
        className={classes.srctypebox}
      >
        <Typography className={classes.srcText} noWrap>{value ?? ""}</Typography>
        <KeyboardArrowDownIcon className={classes.arrowIcon} />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose("close", "x")}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className={classes.menu}
      >
        {option?.map((x) => {
          return (
            <MenuItem
              onClick={() => handleClose("save", x)}
              className={classes.flexBox1}
            >
              <span className={classes.srcText}>
                {" "}
                {x?.value?.length > 0
                  ? `${x?.value?.substring(0, 25)}${
                      x?.value?.length > 25 ? "..." : ""
                    } `
                  : "-"}
              </span>
              <Box>{value === x?.value && <TickIcon color={"#896DB3"}/>}</Box>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
