import React from "react";
import {
  LineChart,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

export const LineChartComponent = (props) => {
  return (
    <ResponsiveContainer width="100%" aspect={3}>
      <LineChart data={props?.data}>
        <CartesianGrid />
        <XAxis dataKey="name" interval={"preserveStartEnd"} />
        <YAxis></YAxis>
        <Legend />
        <Tooltip />
        <Line dataKey="student" stroke="black" activeDot={{ r: 8 }} />
        <Line dataKey="fees" stroke="red" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};
