import Grid from "@mui/material/Grid";
import React, { useState } from 'react';
import { Location } from '../../../components';
import { loadOptions } from '../utils';
import { BedBathSelect } from './bedBathSelect';
import { CustomDatePicker } from './date';
import { LocationTextField } from './locationTextFieldComponent';
import { CustomRangePicker } from './rangeDatePicker';
import { RequiredArea } from './requiredArea';
import { CustomAsyncSelect } from './select';

export const GenerateSearch = (props) => {

    const {
        components = [],
        onChangeState = null
    } = props;

    const [loading, setLoading] = useState(null);


    const switchComponents = (val) => {

        switch (val.component) {
            case "select":

                const handleLoadOptions = (search, array, val) => {
                    setLoading(val?.state_name)
                    return loadOptions(search, array, val?.type, val, setLoading, val?.company)
                }

                return (
                    <>
                        <CustomAsyncSelect
                            label={val?.label ?? ''}
                            options={val?.options?.length ? val?.options : []}
                            value={val?.value ?? null}
                            onChange={(value) => onChangeState(val?.state_name, value, val)}
                            isPaginate={val?.isPaginate}
                            debounceTimeout={800}
                            loadOptions={(search, prevArray) => handleLoadOptions(search, prevArray, val)}
                            loading={loading === val?.state_name}
                            placeholder={val?.placeholder}
                            icon={val?.icon}
                            styles={{
                                control: {
                                    border: '1px solid #E4E8EE',
                                    '&:hover': {
                                        borderColor: '#E4E8EE'
                                    }
                                }
                            }
                            }

                        />
                    </>
                )
            case "date":
                return (
                    <CustomDatePicker
                        placeholder={val?.placeholder ?? ''}
                        label={val?.label ?? ''}
                        value={val?.value ?? ''}
                        data={val?.data ?? ''}
                        disabled={val?.disabled}
                        onChange={(value) => onChangeState(val?.state_name, value, val)}
                        title={val?.title}
                        showEndDate={val?.showEndDate}
                    />
                )
            case "bedBath":
                return (
                    <BedBathSelect
                        bedOptions={val?.bedOptions ?? []}
                        bathOptions={val?.bathOptions ?? []}
                        value={val?.value ?? ''}
                        label={val?.label ?? ''}
                        onChange={(value) => onChangeState(val?.state_name, value, val)}
                    />
                )
            case "location":
                return (
                    <Location
                        value={val?.value}
                        handleChange={(value) => onChangeState(val?.state_name, value, val)}
                        textFieldComponent={(ref, value, handleChange) => (
                            <LocationTextField
                                label={"Location"}
                                inputRef={ref}
                                handleChange={handleChange}
                                value={value}
                                placeholder={'Select City'}
                            />
                        )}
                    />
                )
            case "area":
                return (
                    <RequiredArea
                        label={val?.label ?? ''}
                        data={val?.value}
                        onChange={(value) => onChangeState(val?.state_name, value, val)}
                        options={val?.options ?? []}
                        value={val?.data ?? ''}
                    />
                )
            case "rangePicker":
                return (
                    <CustomRangePicker
                        label={val?.label ?? ''}
                        data={val?.data ?? ''}
                        value={val?.value ?? ''}
                        onChange={(value) => onChangeState(val?.state_name, value, val)}
                        startDateTitle={val?.startDateTitle ?? ''}
                        endDateTitle={val?.endDateTitle ?? ''}
                        placeholder={val?.placeholder ?? ''}
                    />
                )
            default:
                return null;
        }
    }

    return (
        <div>
            {
                <Grid container spacing={1}>
                    {
                        components?.length && components.map(_ => (
                            _?.component &&
                            <>
                                <Grid
                                    item
                                    xs={_?.breakpoints?.xs ?? 12}
                                    sm={_?.breakpoints?.sm ?? 4}
                                    md={_?.breakpoints?.md ?? 3}
                                >
                                    {switchComponents(_)}
                                </Grid>
                            </>
                        ))
                    }
                </Grid>
            }
        </div>
    )
}