import { Box, Button, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TextBox } from "../../../components";
import { Bold, Regular } from "../../../utils";
export const useStyles = makeStyles((theme) => ({
    subtitle: {
        color: "#98A0AC",
        fontFamily: Regular,
        fontSize: "14px",
        marginBottom: "8px"
    },
    title: {
        color: "#091B29",
        fontFamily: Bold,
        fontSize: "14px",
        marginBottom: "8px"
    },

}));
export const PaymentInput = ({
    placeholder = "",
    title = null,
    subtitle = "",
    btnname = "",
    onClick = () => false,
    value = "",
    onChange = () => false,
    diable = false
}) => {
    const classes = useStyles()
    return (
        <Box>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.subtitle}>{subtitle}</Typography>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                    <TextBox
                        color={diable ? "#F5F7FA" : "auto"}
                        label={null}
                        placeholder={placeholder}
                        isReadonly={diable}
                        onChange={onChange}
                        value={value}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        onClick={onClick}
                        sx={{ height: "46px", marginTop: "4px" }}
                        variant="outlined">
                        {btnname}
                    </Button>
                </Grid>
            </Grid>

        </Box>
    )
}