import React from "react";
import { withNavBars } from "../../../HOCs";
import { PricingTableUnitView } from "./pricingTableUnitView";

class PricingTableUnitViewParent extends React.Component {
    render() {
        return <PricingTableUnitView />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(PricingTableUnitViewParent, props);
