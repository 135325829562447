import { Typography, Box, Stack, Divider, Button } from "@mui/material";
import React, { useState, useContext } from "react";
import { managrmentStyles } from "./style";
import {
  DialogBox,
  TableWithPagination,
  RangeDatePicker,
} from "../../components";
import {
  manageInvoicePreviewPath,
  manageInvoicePreviewHeading,
  manageInvoicePreviewType,
  getTotalMangeInvoice,
  AlertProps,
  NetWorkCallMethods,
  getToday,
} from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertContext } from "../../contexts";

export const BottomCard = (props) => {
  const { t } = props;
  // classes
  const classes = managrmentStyles();
  // context
  const alert = useContext(AlertContext);
  // ------------------------STATE-------------------------
  const data = [
    {
      lable: "Total Invoice",
      value: props?.list?.length ?? 0,
    },
    {
      lable: "Total Amount",
      value: `${props?.company?.currency_symbol ?? ""} ${
        props?.list?.length > 0 ? getTotalMangeInvoice(props?.list) : 0
      }`,
    },
  ];
  const [open, setOpen] = useState({
    bool: false,
    type: "",
  });
  const [sucessDialog, setSucessDialog] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [alterDate, setAlterDate] = useState(getToday());

  // --------------------------FUNCTION-----------------------------
  const openClosePopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const openClose = () => {
    setAnchorEl(null);
  };
  const onSumbit = (data) => {
    setAlterDate(data);
    closePopup("posting");
  };

  const closePopup = (type) => {
    if (type === "Proceed") {
      setOpen({
        ...open,
        type: type,
      });
    } else {
      setOpen({
        bool: !open?.bool,
        type: type ?? "",
      });
    }
  };

  const Proceed = (props) => {
    return (
      <div className={classes.proceedDialog}>
        <Box>
          <Typography className={classes.convert}>{props?.title}</Typography>
          <Typography className={classes.convertProcess}>
            {props?.sub}
          </Typography>
          <Box display="flex" alignItems="center" className={classes.marginTop}>
            <Button className={classes.yes} onClick={props?.yesClick}>
              {t("Yes")}
            </Button>
            <Button className={classes.No} onClick={props?.noClick}>
              {t("No")}
            </Button>
          </Box>
        </Box>
      </div>
    );
  };

  const Posting = (props) => {
    return (
      <div className={classes.proceedDialog1}>
        <Box>
          <Typography className={classes.convert}>{props?.title}</Typography>
          <Box display="flex" alignItems="center" className={classes.marginTop}>
            <Button className={classes.yes} onClick={props?.onClose}>
              {t("Okay")}
            </Button>
          </Box>
        </Box>
      </div>
    );
  };

  // updateAlterDate(data);
  const ProceedTable = () => {
    return (
      <div className={classes.parentDialog}>
        <TableWithPagination
          heading={manageInvoicePreviewHeading(t)}
          rows={props?.list ?? []}
          path={manageInvoicePreviewPath}
          showpagination={false}
          showpdfbtn={false}
          showexcelbtn={false}
          showSearch={false}
          totalRowsCount={props?.list?.length ?? 0}
          tableType="no-side"
          dataType={manageInvoicePreviewType}
          height={"calc(100vh - 400px)"}
        />

        {/*  */}
        <Box className={classes?.cardBox}>
          <Typography className={classes.amoutTilte}>
            {t("Total_Amount")}
          </Typography>
          <Typography className={classes.amount}>
            {props?.company?.currency_symbol ?? ""}{" "}
            {props?.list?.length > 0 ? getTotalMangeInvoice(props?.list) : 0}
          </Typography>
        </Box>

        <Box className={classes.btnParent}>
          <Button className={classes.Cancel} onClick={closePopup}>
            {t("Cancel")}
          </Button>
          <Button
            className={classes.next}
            onClick={() => closePopup("Proceed")}
          >
            {t("Proceed")}
          </Button>
        </Box>
      </div>
    );
  };

  // components
  const component = {
    Preview: <ProceedTable />,
    posting: (
      <Proceed
        title={t("Alter")}
        type={"alter"}
        yesClick={() => {
          updateAlterDate();
        }}
        noClick={() => closePopup("")}
      />
    ),
    Proceed: (
      <Proceed
        title={t("convert")}
        sub={t("process")}
        yesClick={() => {
          updateProceed();
        }}
        noClick={() => closePopup("")}
      />
    ),
  };

  // update alter date
  const updateAlterDate = () => {
    const datas = {
      company_id: props?.company?.value,
      managed_invoice_ids:
        props?.allList?.length === props?.list
          ? []
          : props?.list?.map((x) => x?.id) ?? [],
      payment_due_date: alterDate ?? undefined,
      is_all: props?.allList?.length === props?.list ? true : false,
      start_date: props?.date?.from ?? undefined,
      end_date: props?.date?.to ?? undefined,
    };

    NetworkCall(
      `${config.api_url}/invoice/alter_managed_invoices`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {
        setSucessDialog(true);
        closePopup("");
        props?.reloadFunction();
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Internal error. Please try again later.",
        });
      });
  };

  // update Proceed
  const updateProceed = () => {
    const datas = {
      company_id: props?.company?.value,
      managed_invoice_ids:
        props?.allList?.length === props?.list
          ? []
          : props?.list?.map((x) => x?.id) ?? [],
      is_all: props?.allList?.length === props?.list ? true : false,
      start_date: props?.date?.from ?? undefined,
      end_date: props?.date?.to ?? undefined,
    };

    NetworkCall(
      `${config.api_url}/invoice/convert_managed_invoice`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {
        setSucessDialog(true);
        closePopup("");
        props?.reloadFunction();
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Internal error. Please try again later.",
        });
      });
  };
  return (
    <>
      <Box className={classes.buttomCard}>
        {/*  */}
        <Box>
          <Stack
            direction="row"
            spacing={2}
            display={"flex"}
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
          >
            {data?.map((x) => {
              return (
                <Box>
                  <Typography className={classes.title}>{x?.lable}</Typography>
                  <Typography className={classes.totalAmount}>
                    {x?.value}
                  </Typography>
                </Box>
              );
            })}
          </Stack>
        </Box>

        <Box display="flex" alignItems="center">
          <Button
            className={classes.outLine}
            onClick={openClosePopOver}
            variant="outlined"
            disabled={props?.list?.length > 0 ? false : true}
          >
            {t("Alter_Posting")}
          </Button>
          <Button className={classes.outLine}>{t("Send_Payment_Link")}</Button>
          <Button
            className={classes.btn}
            onClick={() => closePopup("Preview")}
            disabled={props?.list?.length > 0 ? false : true}
            variant="contained"
          >
            {t("Post_to_Invoice")}
          </Button>
        </Box>
      </Box>
      {/* date picker */}

      <RangeDatePicker
        outline="Cancel"
        container="Change"
        openClosePopOver={openClosePopOver}
        anchorEl={anchorEl}
        onSumbit={onSumbit}
        openClose={openClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      />
      {/* proceed dialog */}
      <DialogBox
        open={open?.bool}
        isnotTitle={open?.type === "Preview" ? false : true}
        handleClose={() =>  closePopup()}
        onClose={() => closePopup()}
        header={"Preview"}
        maxWidth={"sm"}
        component={component[props?.component ?? open?.type]}
      />

      {/* sucess dialog */}
      <DialogBox
        open={sucessDialog}
        isnotTitle
        handleClose={()=> setSucessDialog(false)}
        onClose={()=>setSucessDialog(false)}
        maxWidth={"sm"}
        component={
          <Posting
            title={t("Posting_date")}
            onClose={()=> setSucessDialog(false)}
          />
        }
      />
    </>
  );
};
