import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";
export const dashboardStyles = makeStyles((theme) => ({
  root: {
    padding: "24px 24px 80px",
    backgroundColor: theme.palette.background.secondary,
    height: `calc(100vh - 64px)`,
    overflow: "auto",
  },
  tabtitle: {
    fontSize: "12px",
    color: "#4E5A6B",
    fontFamily: Bold,
  },
  tabtitle1: {
    fontSize: "12px",
    color: "#5078E1",
    fontFamily: Bold,
  },
  selectBack1: {
    backgroundColor: "#F1F7FF",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px"
  },
  selectBack: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  loader:{
    height:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  }
}));
