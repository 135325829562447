import { Box, Button, Grid, Stack } from '@mui/material'
import React from 'react'
import { SelectBox } from '../../../components'
import { CustomSelect } from '../../../components/filterGenerator/components'
import { config } from '../../../config'
import { NetworkCall } from '../../../networkcall'
import { NetWorkCallMethods } from '../../../utils'
import { GET_COMPANY, GET_PROPERTY_SELECT_ALL, GET_PROPERTY } from '../../usermanagement/queries'

const initialState = {
    company: "",
    property: "",
    error: {
        module: "",
        role: "",
        company: "",
        property: "",
        personname: "",
        email: "",
        phone: "",
        employeeID: "",
    },
}
export const InvitePopup = ({ selectedInvite = {}, inviteClick = () => false }) => {

    const [companyState, setCompanyState] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [stateData, setStateData] = React.useState({ ...initialState });

    const loadOptions = async (search = "", array, type, type1) => {
        setLoading(type);
        let result,
            query,
            offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        } else {
            offset = array?.length;
        }
        const client = localStorage.getItem("clinetID");

        switch (type) {
            case "company":
                query = GET_COMPANY(offset, 10, search, client).loc.source.body;
                result = await networkCallBack(query);
                setCompanyState(result?.all_company_master);
                return {
                    options: [...result?.company_master],
                    hasMore:
                        array?.length + result?.company_master?.length <
                        result?.count[0]?.count,
                };
            case "property":
                if (stateData?.company?.length === 0) {
                    setLoading(null);
                    return { options: [] };
                }
                query =
                    stateData?.company?.[0]?.value === 0
                        ? GET_PROPERTY_SELECT_ALL
                        : GET_PROPERTY;
                const company = {
                    company_id:
                        stateData?.company?.[0]?.value === 0
                            ? companyState?.map((x) => x?.value)
                            : stateData?.company?.map((x) => x?.value),
                    offset,
                    limit: 10,
                    search,
                    client,
                };
                result = await networkCallBack(query, company);
                return {
                    options: [...result?.property],
                    hasMore:
                        array?.length + result?.property?.length < result?.count[0]?.count,
                };
            default:
                return { options: [] };
        }
    };

    const networkCallBack = async (query, variable = {}) => {
        let payload = {
            query,
            variables: variable,
        };

        const options = await NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let main = response.data.data;
                setLoading(null);
                return main;
            })
            .catch((error) => {
                setLoading(null);
                // alert.setSnack({
                //       ...alert,
                //       open: true,
                //       severity: AlertProps.severity.error,
                //       msg: "Some Thing Went Wrong",
                //       vertical: AlertProps.vertical.top,
                //       horizontal: AlertProps.horizontal.center,
                // });
                return null;
            });

        return options;
    };

    const updateState = (key, value, type) => {
        let error = stateData?.error;
        error[key] = "";
        if (key === "module") {
            setStateData({ ...stateData, role: "", module: value, error });
        } else if (key === "company") {
            let Al = [];
            let ALL = [];
            value.map((val) => {
                if (val?.value === 0) {
                    setStateData({ ...stateData, company: ALL, property: [], error });
                } else {
                    Al.push(val);
                    setStateData({ ...stateData, company: Al, property: [], error });
                }
                return 0;
            });
        } else if (key === "property") {
            let Al = [];
            let ALL = [];
            value.map((val) => {
                if (val?.value === 0) {
                    setStateData({ ...stateData, property: ALL, error });
                } else {
                    Al.push(val);
                    setStateData({ ...stateData, property: Al, error });
                }
                return 0;
            });
        } else {
            setStateData({ ...stateData, [key]: value, error });
        }
    };

    return (
        <Box sx={{ height: "400px" }} p={2}>
            <Stack justifyContent={"space-between"} sx={{height:"100%"}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {
                            selectedInvite?.name === "Security Guard" ?

                                <CustomSelect
                                    loadOptions={(search, array) =>
                                        loadOptions(search, array, "company", "custom")
                                    }
                                    isPaginate={true}
                                    borderRadius={false}
                                    noBorder={false}
                                    placeholder={"Select Company"}
                                    label="Company"
                                    value={stateData?.company[0] ?? ""}
                                    isError={stateData?.error?.company?.length > 0}
                                    errorMessage={stateData?.error?.company}
                                    onChange={(value) => updateState("company", [value], "custom")}
                                />

                                :
                                <SelectBox
                                    placeholder="Select Company"
                                    label="Company"
                                    options={[]}
                                    onChange={(value) => updateState("company", value, "")}
                                    isMulti
                                    value={stateData?.company}
                                    isError={stateData?.error?.company?.length > 0}
                                    errorMessage={stateData?.error?.company}
                                    loading={loading === "company"}
                                    isPaginate
                                    debounceTimeout={800}
                                    loadOptions={(search, array) =>
                                        loadOptions(search, array, "company", "")
                                    }
                                />
                        }



                    </Grid>
                    <Grid item xs={12}>
                        {
                            selectedInvite?.name === "Security Guard" ?

                                <CustomSelect
                                    loadOptions={(search, array) =>
                                        loadOptions(search, array, "property", "custom")
                                    }
                                    isPaginate={true}
                                    borderRadius={false}
                                    noBorder={false}
                                    placeholder={"Select Property"}
                                    key={JSON.stringify(stateData?.company)}
                                    label="Property"
                                    value={stateData?.property[0] ?? ""}
                                    isError={stateData?.error?.company?.length > 0}
                                    errorMessage={stateData?.error?.company}
                                    onChange={(value) => updateState("property", [value], "custom")}
                                />
                                :
                                <SelectBox
                                    placeholder="Select Property"
                                    label="Property"
                                    isMulti
                                    options={[]}
                                    onChange={(value) => updateState("property", value, "")}
                                    value={stateData?.property}
                                    key={JSON.stringify(stateData?.company)}
                                    isError={stateData?.error?.property?.length > 0}
                                    errorMessage={stateData?.error?.property}
                                    loading={loading === "property"}
                                    isPaginate
                                    debounceTimeout={800}
                                    loadOptions={(search, array) =>
                                        loadOptions(search, array, "property", "")
                                    }
                                />
                        }
                    </Grid>
                </Grid>
                <Button variant='contained' onClick={() => inviteClick({ selectedInvite, stateData })}
                    fullWidth>Invite
                </Button>
            </Stack>
        </Box>
    )
}