import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const AccordianStyle = makeStyles((theme) => ({
    cardRoot: {
        padding: "18px",
        border: `1px solid ${theme.palette.border.secondary}`,
        marginTop: "10px",
        borderRadius: "4px",
        cursor: "pointer",
    },
    title: {
        color: theme.typography.color.primary,
        fontSize: "16px",
        fontFamily: Bold,
    },
    title1: {
        color: theme.typography.color.primary,
        fontSize: "14px",
        fontFamily: Bold,
    },
    noof: {
        color: theme.typography.color.tertiary,
        fontSize: "14px",
        fontFamily: SemiBold,
    },
    avatarGroup: {
        "& .MuiAvatar-root": {
            height: "34px",
            width: "34px",
            fontSize: "12px"
        }
    },
    subtitle1: {
        color: theme.typography.color.tertiary,
        fontSize: "14px",
        fontFamily: SemiBold,
        marginTop: "12px"
    },
    subtitle2: {
        color: theme.typography.color.primary,
        fontSize: "14px",
        fontFamily: SemiBold,
        marginTop: "12px"
    },
    subtitle3: {
        color: theme.typography.color.tertiary,
        fontSize: "14px",
        fontFamily: SemiBold,
        marginTop: "22px"
    },
    heading: {
        color: theme.typography.color.tertiary,
        fontSize: "14px",
        fontFamily: Bold,
        marginTop: "12px",
        textTransform:"upperCase"
    },
    tag: {
        padding: "2px 4px",
        borderRadius: "4px",
        fontSize: "14px",
        fontFamily: SemiBold,
        marginTop: "12px",
        color: "white",
        display: "inline-block"
    },
    subtitle4: {
        color: theme.typography.color.primary,
        fontSize: "14px",
        fontFamily: SemiBold,
        marginTop: "22px",
    },
    tenant_name: {
        color: theme.palette.primary.main,
        textDecoration: "underline",
        fontSize: "14px",
        fontFamily: SemiBold,
        marginTop: "22px",
        cursor: "pointer"
    },
    image: {
        height: "240px",
        objectFit: "cover",
        width: "100%",
        borderRadius: "4px"
    },
    borderNone:{
        border:"none"
    },
    textAreaBox:{
        border:"1px solid #E4E8EE",
        borderRadius:theme.palette.borderRadius,
        padding:"8px",
    }
}));