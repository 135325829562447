import { useApolloClient } from "@apollo/client"
import { Box } from "@mui/material"
import React from "react"
import { AlertDialog, FilterGenerator, LoadingSection, Subheader, UseDebounce } from "../../components"
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { GET_TYPE_MASTER, UPDATE_RESOURCES } from "../../graphql/resourceMaster"
import { NetworkCall } from "../../networkcall"
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from "../../utils"
import { StatusOptionList } from "../../utils/insepectionMaster"
import { initialState } from "../../utils/resourceMaster"
import Resources from "./components/resources"
import ResourceMasterTable from "./components/table"
import { ViewResources } from "./components/viewResources"
import { ResourceMasterStyles } from "./styles"
const ResourceMaster = () => {
    const classes = ResourceMasterStyles()
    const debounce = UseDebounce();
    const client = useApolloClient();
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext);
    const [searchText, setSearchText] = React.useState("");
    const [compenyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [drawer, setDrawer] = React.useState(false);
    const [type, setTypes] = React.useState([])
    const [loading, setLoading] = React.useState(true);
    const [seletedtype, setSelectedType] = React.useState("")
    const [itemList, setItemList] = React.useState({
        list: [],
        count: 0
    })
    const [form, setForm] = React.useState(false);
    const [filterData, setFilterData] = React.useState({
        type: [],
        is_active: null
    });
    const [data, setData] = React.useState({ ...initialState });
    const [permissions, setPermissions] = React.useState({})
    const [selected, setSelected] = React.useState({
        bool: false,
        id: null
    })


    //get list
    const getResourceList = (company_id, searchText, offset, limits, filterDatas) => {
        setLoading(true)
        const payload = {
            company_id: company_id,
            search: searchText,
            offset: offset,
            limit: limits,
            active: filterDatas?.is_active ?? undefined,
            type: filterDatas?.type?.length > 0 ? filterDatas?.type?.map((val) => val?.value) : undefined
        }
        NetworkCall(
            `${config.api_url}/resources/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setItemList({
                    list: response?.data?.result,
                    count: response.data.count
                })
                setLoading(false)
            }).catch((err) => {
                console.log(err)
                setLoading(false)

            })
    }
    //get type
    const getType = () => {
        client.query({
            query: GET_TYPE_MASTER,
            fetchPolicy: 'network-only',
        }).then((res) => {
            setTypes(res?.data?.resource_type_master)
        })
    }

    //initial load
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)


        if (perm) {
            setPermissions(perm)
            if (perm?.read) {
                getCompanyList()
            }
        }
        // eslint-disable-next-line
    }, [auth])


    //get company list
    const getCompanyList = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getResourceList(company?.selected?.value, "", 0, 10, {})
            getType()
        }
    }

    //handle change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getResourceList(value?.value, "", 0, 10, {})
    }

    //apply filter
    const onApplyFilter = (data) => {
        getResourceList(selectedCompany?.value, "", 0, 10, data)
        setFilterData(data)
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getResourceList(selectedCompany?.value, "", offset, limit, filterData)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getResourceList(selectedCompany?.value, "", 0, value, filterData)
    }
    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getResourceList(selectedCompany?.value, e, 0, limit, filterData)
    }
    //onadd
    const onAdd = (data) => {
        setForm(!form)
        setSelectedType(data)
    }
    //on close
    const onClose = () => {
        setForm(!form)
        setData({
            period: "",
            tool: "",
            module: "",
            vehicles: "",
            working_hour: "",
            user: "",
            reporting_to: "",
            rate_hour: "",
            daily_rate: "",
            currency: "",
            name: "",
            account: "",
            isEdit: false,
            id: "",
            assets: [],
            type: "",
            department: "",
            job: "",
            vehicles_no: "",

            error: {
                period: "",
                tool: "",
                module: "",
                vehicles: "",
                working_hour: "",
                user: "",
                reporting_to: "",
                rate_hour: "",
                daily_rate: "",
                currency: "",
                name: "",
                account: "",
                department: "",
                job: "",
                vehicles_no: ""
            }

        });
    }
    //onview
    const onView = (val) => {
        setSelected({
            bool: !selected?.bool,
            id: val
        })
    }
    //onEdit
    const onEdit = (id) => {
        setForm(true);
        setData({ ...data, id: id, isEdit: true })
        onView()
    }
    //on isActive
    const onIsActive = (id, status) => {
        const payload = {
            is_active: status
        }
        client.mutate({
            mutation: UPDATE_RESOURCES,
            variables: {
                id: id,
                updatePayload: payload
            }
        }).then(rs => {
            reload();
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Updated",
            });
        }).catch(er => {
            console.log(er)
        })


    }
    //handleIcon
    const handleIcon = (type, data) => {

        if (type === "view") {
            onView(data)
        } if (type === "edit" && permissions?.view) {
            onAdd(data?.id)
        } else if (type === "active") {
            onIsActive(data?.id, !data?.is_active);
        }
    }
    //reload
    const reload = () => {
        getResourceList(selectedCompany?.value, "", 0, 10, {})
    }

    const render = () => {
        return (
            <Box>
                {/*sub header */}
                <Subheader
                    hideBackButton={true}
                    title="Resource Master"
                    select
                    options={compenyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        handleCompanyChange(e)
                    }}
                />
                {
                    loading ?
                        <LoadingSection top="20vh" message="Fetching ..." />
                        :
                        <Box className={`${classes.root}`}>
                            <ResourceMasterTable
                                onFilter={() => setDrawer(true)}
                                placeholder="Search Resource"
                                options={type}
                                searchText={searchText}
                                handleSearch={handleSearch}
                                handleChangeLimit={handleChangeLimit}
                                handlePagination={handlePagination}
                                page={page}
                                permissions={permissions}
                                list={itemList}
                                limit={limit}
                                onAdd={onAdd}
                                handleIcon={handleIcon}
                                height={`calc(100vh - 320px)`}
                            />

                            {drawer && (
                                <FilterGenerator
                                    open={drawer}
                                    onClose={() => setDrawer(false)}

                                    components={[
                                        {
                                            component: "toggleButton",
                                            value: filterData?.is_active,
                                            state_name: "is_active",
                                            label: "Active",
                                            options: StatusOptionList,
                                            isMulti: true
                                        },
                                        {
                                            component: "select",
                                            value: filterData?.type,
                                            state_name: "type",
                                            label: "Type",
                                            options: type,
                                            isMulti: true
                                        },
                                    ]}
                                    onApply={(value) => onApplyFilter(value)}
                                />
                            )}


                            {/*add form */}
                            <AlertDialog
                                open={form}
                                md={true}
                                component={
                                    <Resources

                                        data={data}
                                        setData={setData}
                                        value={seletedtype?.label}
                                        type={seletedtype}
                                        company={selectedCompany}
                                        reload={reload}
                                        onClose={onClose}
                                        setSelectedType={setSelectedType}
                                    />
                                }
                                header={`${data?.isEdit ? "Edit" : "Add"} ${seletedtype?.label ?? ""}`}
                                onClose={onClose}
                            />


                            {/*view form */}
                            <AlertDialog
                                open={selected?.bool}
                                medium={true}
                                component={
                                    <ViewResources
                                        permissions={permissions}
                                        selected={selected?.id}
                                        name={selected?.id?.name}
                                        onEdit={onEdit}
                                    />
                                }
                                header={selected?.id?.name}
                                onClose={onView}

                            />
                        </Box>
                }

            </Box>
        )
    }

    return (
        <Box>

            {accessCheckRender(render, permissions)}
        </Box>

    )
}
export default ResourceMaster