import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Grid, IconButton, InputAdornment } from "@mui/material";
import React from "react";
import { CompanySettingsList, TextBox } from "../../../components";
import { config } from '../../../config';
import { AlertContext } from '../../../contexts';
import { UOM_MASTER } from '../../../graphql/queries';
import { NetworkCall } from '../../../networkcall';
import { AlertProps, billGenENUM, enumName, enumSelect, enum_types, NetWorkCallMethods } from "../../../utils";
import { quotation_validity, validateCustom } from "../../../utils/companySettings";
import {loadOptionsApis} from '../../../utils/asyncPaginateLoadOptions';

export const BasicSettings = ({
    data = {},
    updateState = () => false,
    loadOptionData = () => false,
    loading = false
}) => {
    const [toggleView, setToggleView] = React.useState([]);
    const alert = React.useContext(AlertContext)
    const [UOMValue, setUOMValue] = React.useState()
    const [enumValue, setEnumValue] = React.useState({
        uom: [],
        company_payment_period: [],
        payment_terms: [],
        billing_cycle_date: [],
        bill_gen: [],
        payment_gateway: [],
        company_lease_period: []
    })
    //get enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.area_metric_type, enum_types.company_payment_period, enum_types.payment_terms, enum_types.billing_cycle_date, enum_types.billing_cycle, enum_types.company_payment_gateway, enum_types.company_lease_period])
        setEnumValue({
            // uom: result?.area_metric_type,
            company_payment_period: result?.company_payment_period,
            payment_terms: result?.payment_terms,
            billing_cycle_date: result?.billing_cycle_date?.filter(i => i?.value !== billGenENUM.last_day).filter(i => i?.value !== billGenENUM.mid_date).filter(i => i?.value !== billGenENUM.first),
            bill_gen: result?.billing_cycle,
            payment_gateway: result?.company_payment_gateway,
            company_lease_period: result?.company_lease_period
        })
    }
    const onChangeToggleView = (index) => {
        if (toggleView.includes(index)) {
            setToggleView(toggleView?.filter(_ => _ !== index));
            return
        }
        setToggleView([...toggleView, index])
    }

    //uom api call
    const getUOMMaster = async () => {
        const payload = {
            query: UOM_MASTER().loc.source.body,
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                setUOMValue(response?.data?.data?.uom_master)

            }).catch((error) => {

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                });
            });
    }


    //initial load
    React.useEffect(() => {
        getEnum()
        getUOMMaster()
        // eslint-disable-next-line
    }, [])
    const renderGatway = () => {
        return (

            data?.default_payment_gateway?.value === "Solution Provider" &&
            <Grid container spacing={1} marginTop="8px">

                <Grid item xs={12} sm={6} md={3}>
                    <TextBox
                        isrequired
                        autocomplete={"new-name"}
                        label={'Login name'}
                        placeholder={'Enter name'}
                        value={data?.loginName ?? ''}
                        onChange={(e) => updateState('loginName', e.target.value)}
                        isError={data?.error?.error?.loginName?.length > 0}
                        errorMessage={data?.error?.loginName}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextBox
                        // {...textBoxStyle}
                        isrequired
                        autocomplete={"new-password"}
                        label={'Login password'}
                        type={toggleView?.includes('1') ? "text" : "password"}
                        placeholder={'Enter password'}
                        value={data?.loginPassword ?? ''}
                        endAdornment={(
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => onChangeToggleView('1')}
                                    edge="end"
                                >
                                    {toggleView?.includes('1') ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )}
                        isError={data?.error?.loginPassword?.length > 0}
                        errorMessage={data?.error?.loginPassword}
                        onChange={(e) => updateState('loginPassword', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextBox
                        // {...textBoxStyle}
                        isrequired
                        label={'Login token'}
                        type={toggleView?.includes('2') ? "text" : "password"}
                        placeholder={'Enter login token'}
                        value={data?.loginToken ?? ''}
                        endAdornment={(
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => onChangeToggleView('2')}
                                    edge="end"
                                >
                                    {toggleView?.includes('2') ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )}
                        isError={data?.error?.loginToken?.length > 0}
                        errorMessage={data?.loginToken}
                        onChange={(e) => updateState('loginToken', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextBox
                        // {...textBoxStyle}
                        isrequired
                        label={'Payment Provider Name'}
                        placeholder={'Enter Payment Provider Name'}
                        value={data?.paymentProviderName ?? ''}
                        onChange={(e) => updateState('paymentProviderName', e.target.value)}
                        isError={data?.error?.error?.paymentProviderName?.length > 0}
                        errorMessage={data?.error?.paymentProviderName}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextBox
                        // {...textBoxStyle}
                        isrequired
                        label={'Url'}
                        placeholder={'Enter Url'}
                        value={data?.provider_url ?? ''}
                        isError={data?.error?.error?.provider_url?.length > 0}
                        errorMessage={data?.error?.provider_url}
                        onChange={(e) => updateState('provider_url', e.target.value)}
                    />
                </Grid>
            </Grid>
        )
    }

    return (
        <Box>
            {/*"Default Base Currency" */}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title="Default Base Currency"
                    onChange={(value) => updateState("default_base_currency", value)}
                    value={data?.default_base_currency}
                    bgColor="#F2F4F7"
                    type="select"
                    border={false}
                    aysnc={true}
                    loading={loading}
                    loadOptionData={loadOptionData}
                    isError={data?.error?.default_base_currency?.length > 0}
                    errorMessage={data?.error?.default_base_currency}
                />

            </Box>
             {/* default time zone */}
             <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Default Time Zone"
                        onChange={(value) => updateState("default_time_zone", value)}
                        value={data?.default_time_zone}
                        bgColor="#F2F4F7"
                        type="currency_select"
                        border={false}
                        aysnc={true}
                        // loadOptionData={loadOptionData}
                        isError={data?.error?.default_time_zone?.length > 0}
                        errorMessage={data?.error?.default_time_zone}
                        loadOptionData={(search, array, handleLoading) =>
                        loadOptionsApis(
                            "/timezone",
                            {},
                            search,
                            array,
                            handleLoading, 
                            "data"
                        )
                        }
                    />

                </Box>
            {/*Default Unit Measurement*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title="Default Unit Measurement"
                    onChange={(value) => updateState("default_uom", value)}
                    value={data?.default_uom}
                    options={UOMValue}
                    isError={data?.error?.default_uom?.length > 0}
                    errorMessage={data?.error?.default_uom}
                />
            </Box>
            {/*Default Quotation Validity*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title="Default Quotation Validity"
                    onChange={(value) => updateState("quotation_validity", value)}
                    value={data?.quotation_validity}
                    options={quotation_validity}
                    custom={true}
                    customValue={0}
                    openCustom={validateCustom([0], data?.quotation_validity?.value)}
                    isError={data?.error?.quotation_validity?.length > 0}
                    errorMessage={data?.error?.quotation_validity}
                />
            </Box>
            {/*Default Billing Payment Period*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title="Default Billing Payment Period"
                    onChange={(value) => updateState("billing_payment_peroid", value)}
                    value={data?.billing_payment_peroid}
                    options={enumValue?.company_payment_period}
                    isError={data?.error?.billing_payment_peroid?.length > 0}
                    errorMessage={data?.error?.billing_payment_peroid}
                />
            </Box>
            {/*Default Billing lease Period*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title="Default Lease Period"
                    onChange={(value) => updateState("lease_period", value)}
                    value={data?.lease_period}
                    options={enumValue?.company_lease_period}
                    isError={data?.error?.lease_period?.length > 0}
                    errorMessage={data?.error?.lease_period}
                />
            </Box>
            {/*Default Billing Payment Terms*/}
            <Box padding="8px 12px">

                <CompanySettingsList
                    title="Default Billing Payment Terms"
                    onChange={(value) => updateState("billing_payment_terms", value)}
                    value={data?.billing_payment_terms}
                    options={enumValue?.payment_terms}
                    onChangeText={(type, value) => updateState("billing_payment_terms_value", value)}
                    textValue={data?.billing_payment_terms_value}
                    type={data?.billing_payment_terms?.value === enumName.userDefined ? "textbox" : null}
                    isError={data?.error?.billing_payment_terms?.length > 0}
                    errorMessage={data?.error?.billing_payment_terms}
                />
            </Box>

            {/*Default Billing Cycle Methods*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title="Default Billing Cycle Methods"
                    onChange={(value) => updateState("billing_cycle_method", value)}
                    value={data?.billing_cycle_method}
                    options={enumValue?.bill_gen}
                    isError={data?.error?.billing_cycle_method?.length > 0}
                    errorMessage={data?.error?.billing_cycle_method}
                />
            </Box>
            {/*Default Billing Generate Date*/}
            <Box padding="8px 12px">

                {data?.billing_cycle_date?.value === "Custom Date" ? "textbox" : null}
                <CompanySettingsList
                    title="Default Billing Generate Date"
                    onChange={(value) => updateState("billing_generate_date", value)}
                    value={data?.billing_generate_date}
                    options={enumValue?.billing_cycle_date}
                    isError={data?.error?.billing_generate_date?.length > 0}
                    errorMessage={data?.error?.billing_generate_date}
                    type={data?.billing_generate_date?.value === "Custom Date" ? "textbox" : null}
                    onChangeText={(state, value) => updateState("bill_custom_date", value)}
                    textValue={data?.bill_custom_date}
                />
            </Box>
            {/*"Default Payment Gateway"*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title="Default Payment Gateway"
                    onChange={(value) => updateState("default_payment_gateway", value)}
                    value={data?.default_payment_gateway}
                    options={enumValue.payment_gateway}
                    render={renderGatway}
                    isError={data?.error?.default_payment_gateway?.length > 0}
                    errorMessage={data?.error?.default_payment_gateway}
                />
            </Box>
        </Box>
    )
}