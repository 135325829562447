import React from "react";
import {
  SearchFilter,
  TableWithPagination,
  SelectBox,
  RangeDatePicker,
} from "../../components";
import { Box, IconButton, Grid, Button } from "@mui/material";
import { managrmentStyles } from "./style";
import {
  manageInvoiceType,
  manageInvoiceHeading,
  manageInvoicePath,
  getThisWeek,
  getToday,
} from "../../utils";
import { DatePickerManangeInvoice } from "../../assets";
const dateOption = [
  {
    label: "Today",
    value: {
      from: getToday(),
      to: getToday(),
    },
  },
  {
    label: "This Week",
    value: getThisWeek(),
  },
];

export const TableComponent = (props) => {
  const { t, date, dateChange , enumValue , setSelectType } = props;
  const classes = managrmentStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [type , setType]=React.useState({
    value:"All",
    label:"All"
  })

  const openClosePopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openClose = (event) => {
    setAnchorEl(null);
  };

  const onSumbit = (data) => {
    let result = {
      from: data?.startDate,
      to: data?.endDate ?? data?.startDate,
    };
    dateChange(result);
    setAnchorEl(!anchorEl);
  };

  const allhandleCheck = (e) => {
    props?.allSelectBox(e);
  };

  const typeChange =(e)=>{
    setType(e);
    setSelectType(e)
    props?.typeChage(e)
  }
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box>
            <SearchFilter
              value={props?.company?.searchText}
              handleChange={(value) => props?.searchFunction(value)}
              placeholder={t("search")}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
<Box/>
        </Grid>
        <Grid item xs={4}>
          <Box
            display={"flex"}
            pr={2}
            alignItems="center"
            // sx={{ float: "right" }}
          >
            {/* <Stack
              direction="row"
              spacing={0.6}
              display={"flex"}
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
            > */}
              {/* select type */}
              <Grid container spacing={1}>
                <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                {/* date toggle */}
                <Box>
                  <Box className={classes.toggle}>
                    {dateOption?.map((x) => {
                      return (
                        <Button
                          className={
                            x?.value?.from === date?.from
                              ? classes.select
                              : classes.unSelect
                          }
                          onClick={() => dateChange(x?.value)}
                        >
                          {x?.label}
                        </Button>
                      );
                    })}
                  </Box>
                </Box>
                {/* date picker */}
                <Box ml={1}>
                  <IconButton
                    size="small"
                    className={classes.img}
                    onClick={openClosePopOver}
                  >
                    <DatePickerManangeInvoice />
                  </IconButton>
                  <RangeDatePicker
                    outline="Reset"
                    container="Change"
                    openClosePopOver={openClosePopOver}
                    anchorEl={anchorEl}
                    onSumbit={onSumbit}
                    isRange
                    openClose={openClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  />
                </Box>
              </Box>
                </Grid>
                <Grid item xs={6}>
                <Box mt={1}>
                <SelectBox
                  isRequired
                  placeholder="Select Status"
                  options={[{
                    value:"All",
                    label:"All"
                  } , ...enumValue?.type]}
                  value={type}
                  onChange={(value) => {
                    typeChange(value)
                  }}
                />
              </Box>
                </Grid>
              </Grid>
            {/* </Stack> */}
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.tableMain}>
          <TableWithPagination
            heading={manageInvoiceHeading(t)}
            rows={props?.list?.data ?? []}
            path={manageInvoicePath}
            showpdfbtn={false}
            showexcelbtn={false}
            showSearch={false}
            tableType="no-side"
            handleCheck={props?.onCheckBox}
            page={props?.page ?? 1}
            limit={props?.limit ?? 10}
            handlePagination={props?.handlePagination}
            handleChangeLimit={props?.handleChangeLimit}
            totalRowsCount={props?.list?.count}
            dataType={manageInvoiceType ?? []}
            height={props?.list?.data?.length > 0 ? "calc(100vh - 395px)" : "calc(100vh - 356px)"}
            showpagination={true}
            onCheckBox={props?.onCheckBox}
            checkboxKey="is_active"
            allSelect={props?.selected ?? ""}
            allhandleCheck={allhandleCheck}
          />
        </Grid>
      </Grid>
    </div>
  );
};
