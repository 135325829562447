export const PropertyFinderPath = [
  "id",
  "image",
  "property_name",
  "units",
  "location",
];

export const PropertyFinderHeading = [
  { title: "S.No", field: "id", type: "numeric" },
  { title: "Image", field: "image" },
  { title: "Property Name", field: "property_name" },
  { title: "Units", field: "units" },
  { title: "Location", field: "location" },
  { title: "", field: "icon" },
];
export const PropertyFinderType = [
  { type: ["increment"], name: "id" },
  { type: ["PFImage"], name: "image" },
  { type: ["text"], name: "property_name" },
  { type: ["text"], name: "units" },
  { type: ["text"], name: "location" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const Propertypath = [
  "propertyName",
  "propertyNo",
  "loction",
  "blocks",
  "floors",
  "units",
  "propertyPurpose",
  "propertyHierarchy",
  "active",
  "icon",
];

export const SignatureReportrow = [
  {
    sno: "",
    propertyName: "Rubix Appartment",
    location: "T.Nagar,Chennai",
    blocks: "8 ",
    floors: "96",
    units: "768",
    propertyType: "Appartment",
    icon: "edit",
  },
];
export const Propertyheading = [
  { title: "", field: "icon" },
  { title: "Property ID", field: "propertyNo" },
  { title: "Name", field: "propertyName" },
  { title: "Location", field: "loction" },
  { title: "Purpose", field: "propertyPurpose" },
  { title: "Hierarchy", field: "propertyHierarchy" },
];

export const PricingtablePropertypath = [
  "propertyNo",
  "propertyName",
  "companyName",
  "location",
  "units",
  "propertyType",
  "status",
];

export const PricingTablePropertyheading = [
  { title: "Property No.", field: "propertyNo" },
  { title: "Property Name", field: "propertyName" },
  { title: "Company Name", field: "companyName" },
  { title: "Location", field: "location" },
  { title: "Units", field: "units" },
  { title: "Property Type", field: "propertyType" },
  { title: "Status", field: "status" },
];

export const Signaturepath = ["sno", "propertyName", "units", "location"];

export const Signaturerow = [
  {
    sno: "",
    propertyName: "Rubix Appartment",
    units: "768",
    location: "T.Nagar,Chennai",
    icon: "arrow",
  },
];
export const Signatureheading = [
  { title: "S.NO", field: "sno", type: "numeric" },
  { title: "Property Name", field: "date" },
  { title: "Units", field: "units" },
  { title: "Location", field: "location" },
  { title: "", field: "icon" },
];

export const Blockpath = [
  "sno",
  "propertyName",
  "units",
  "leasableArea",
  "totalArea",
  "contact",
];

export const Blockrow = [
  {
    propertyName: "Rubix Appartment",
    units: "768",
    location: "T.Nagar,Chennai",
    icon: "arrow",
  },
  {
    sno: "",
    propertyName: "Rubix Appartment",
    units: "2400",
    LeasableArea: "10000 Sq.Ft",
    TotalArea: "165480 Sq.Ft",
    Contact: "+91-9900990099",
    BusinessType: "Residential",
  },
  {
    sno: "",
    propertyName: "Rubix Appartment",
    units: "2400",
    LeasableArea: "10000 Sq.Ft",
    TotalArea: "165480 Sq.Ft",
    Contact: "+91-9900990099",
    BusinessType: "Residential",
  },
];
export const Blockheading = [
  { title: "S.NO", field: "sno", type: "numeric" },
  { title: "Property Name", field: "propertyName" },
  { title: "Units", field: "units" },
  { title: "Location", field: "location" },
  { title: "", field: "icon" },
];
export const Floorpath = [
  "sno",
  "propertyName",
  "units",
  "leasableArea",
  "totalArea",
  "contact",
];

export const Floorrow = [
  {
    propertyName: "Rubix Appartment",
    units: "768",
    location: "T.Nagar,Chennai",
    icon: "arrow",
  },
];
export const Floorheading = [
  { title: "S.NO", field: "sno", type: "numeric" },
  { title: "Property Name", field: "date" },
  { title: "Units", field: "units" },
  { title: "Location", field: "location" },
  { title: "", field: "icon" },
];

export const Unitpath = [
  "unitNo",
  "unitName",
  "parentUnitName",
  "unitType",
  "carpetArea",
  "furnishType",
  "unitPurpose",
  "currentStatus",
];

export const Unitheading = [
  { title: "Unit NO.", field: "unitNo" },
  { title: "Unit Name", field: "unitName" },
  { title: "Parent Unit Name", field: "parentUnitName" },
  { title: "Unit Type", field: "unitType" },
  { title: "Carpet Area", field: "carpetArea" },
  { title: "Furnish Type", field: "furnishType" },
  { title: "Unit Purpose", field: "unitPurpose" },
  { title: "Current Status", field: "currentStatus" },
  { title: "", field: "icon" },
];

export const Leadpath = [
  "sno",
  "leadNo",
  "owner",
  "description",
  "purpose",
  "source",
  "activeQuote",
  "value",
  "date",
  "statusDate",
  "priority",
  "status",
];

export const Leadrow = [
  {
    sno: "",
    leadName: "Gokul",
    requirement: "2400 sq ft",
    leadOwner: "vishnu",
    dateOfOpen: "22 nov 21",
    dateOfClosed: "24 dec 22",
    priority: "high",
    status: "open",
    icon: "editview",
  },
  {
    sno: "",
    leadName: "Gokul",
    requirement: "2400 sq ft",
    leadOwner: "vishnu",
    dateOfOpen: "22 nov 21",
    dateOfClosed: "24 dec 22",
    priority: "high",
    status: "open",
    icon: "editview",
  },
  {
    sno: "",
    leadName: "Gokul",
    requirement: "2400 sq ft",
    leadOwner: "vishnu",
    dateOfOpen: "22 nov 21",
    dateOfClosed: "24 dec 22",
    priority: "high",
    status: "open",
    icon: "editview",
  },
  {
    sno: "",
    leadName: "Gokul",
    requirement: "2400 sq ft",
    leadOwner: "vishnu",
    dateOfOpen: "22 nov 21",
    dateOfClosed: "24 dec 22",
    priority: "high",
    status: "open",
    icon: "editview",
  },
];
export const Leadheading = [
  { title: "", field: "icon" },
  { title: "Opportunity ID", field: "leadNo" },
  { title: "Date", field: "date" },
  { title: "Description", field: "description" },
  { title: "Purpose", field: "purpose" },
  { title: "Type", field: "opportunity_type" },
  { title: "Quote ID", field: "activeQuote" },
  { title: "Revenue", field: "value" },
  { title: "Owner", field: "owner" },
  { title: "Status", field: "status" },
  { title: "Status Date", field: "statusDate" },
  { title: "Closed On", field: "dateOfClosed" },
  { title: "Lead ID", field: "leadNo" },
];

export const announsment = [
  { title: "S.No", field: "sno", type: "numeric" },
  { title: "Category", field: "Category" },
  { title: "Announcement Title", field: "Announcement_Title" },
  { title: "Assigned Property", field: "Assigned_Property" },
  { title: "Assigned Roles", field: "Assigned_Roles" },
  { title: "Created By", field: "Created_By" },
  { title: "", field: "icon" },
];
export const announsmentRow = [
  {
    numeric: "",
    Category: "Category Name here",
    Announcement_Title: "COVID Vaccination camp for senior citi….  ",
    Assigned_Property: "Rukon Al Salim Real Estate D....",
    Assigned_Roles: "4",
    Created_By: "22 nov 21",
    icon: "viewdelete",
  },
];

//Activity
export const ActivityPath = [
  "avatar",
  "action info",
  "category",
  "date",
  "createdBy",
  "priority",
  "status",
];

export const ActivityRow = [
  {
    img: "/images/phonecall.svg",
    name: "gokul",
    actionInfo: "2400 sq ft",
    date: "vishnu",
    createdBy: "22 nov 21",
    priority: "high",
    status: "open",
    icon: "editview",
  },
];
export const ActivityHeading = [
  { title: "Activity Name", field: "img" },
  { title: "Action Info", field: "action info" },
  // { title: "Purpose", field: 'purpose' },
  { title: "Date", field: "date" },
  { title: "Created By", field: "createdBy" },
  { title: "Priority", field: "priority" },
  { title: "Status", field: "status" },
  { title: "", field: "icon" },
];

//quotation
export const QuotationPath = [
  "quotationNo",
  "units",
  "value",
  "period",
  "quoteExpireOn",
  "status",
];

export const QuotationRow = [
  {
    quotationNo: "2344",
    units: "2400 sq ft",
    value: "$ 2,400",
    period: "high",
    quoteExpireOn: " 22 nov 21",
    status: "requested for visit",
  },
  {
    quotationNo: "2344",
    units: "2400 sq ft",
    value: "$ 2,400",
    period: "high",
    quoteExpireOn: " 22 nov 21",
    status: "Quote Accepted",
  },
  {
    quotationNo: "2344",
    units: "2400 sq ft",
    value: "$ 2,400",
    period: "high",
    quoteExpireOn: " 22 nov 21",
    status: "Quote Accepted",
  },
];
export const QuotationHeading = [
  { title: "Quotation  No", field: "quotationNo" },
  { title: "Units", field: "units" },
  { title: "Value", field: "value" },
  { title: "Period", field: "period" },
  { title: "Quote Expire On", field: "quoteExpireOn" },
  { title: "status", field: "status" },
];

//to-do
export const TodoPath = ["name", "description", "date", "priority", "status"];

export const TodoRow = [
  {
    checkBox: "checkbox",
    Name: "Gokul",
    description: "Fusce at nisi eget dolor rhoncus",
    icon: "editview",
  },
];
export const TodoHeading = [
  { title: "", field: "checkBox" },
  { title: "Name", field: "Name" },
  { title: "Description", field: "description" },
  { title: "", field: "icon" },
];
export const Managementheading = [
  { title: "Image", field: "Image" },
  { title: "Property Name", field: "PropertyName" },
  // { title: "Revenue Type", field: "revenueType" },
  { title: "Location", field: "location" },
  { title: "Roles Assigned", field: "Roles" },
];

export const Managementpath = ["Image", "PropertyName", "location", "Roles"];

export const UnitRentallHeading = [
  { title: "Property Num", field: "PropertyNum" },
  { title: "Property Name", field: "PropertyName" },
  { title: "Company Name", field: "CompanyName" },
  { title: "Location", field: "Location" },
  { title: "Units", field: "Units" },
  { title: "Property Type", field: "PropertyType" },
  { title: "Status", field: "Status" },
];

export const UnitRentallPath = [
  "PropertyNum",
  "PropertyName",
  "CompanyName",
  "Location",
  "Units",
  "PropertyType",
  "Status",
];

export const UnitRentallCreateHeading = [
  { title: "Unit Number", field: "UnitNumber" },
  { title: "Unit Name", field: "UnitName" },
  { title: "Unit Category", field: "UnitCategory" },
  { title: "Revenue Type", field: "RevenueType" },
  { title: "Unit Purpose", field: "UnitPurpose" },
];

export const UnitRentallCreatePath = [
  "UnitNumber",
  "UnitName",
  "UnitCategory",
  "RevenueType",
  "UnitPurpose",
];

export const Salesheading = [
  { title: "Image", field: "Image" },
  { title: "Name", field: "Name" },
  { title: "UCID of the profile", field: "UCID" },
  { title: "Email id", field: "email" },
  { title: "Phone number", field: "number" },
  { title: "Reporting to", field: "reporting" },
  { title: "", field: "icon" },
];

export const Salespath = [
  "Image",
  "Name",
  "UCID",
  "email",
  "number",
  "reporting",
];

export const LeadNewheading = [
  { title: "", field: "icon" },
  { title: "Lead ID", field: "LeadNumber" },
  { title: "Date", field: "date" },
  { title: "Description", field: "description" },
  { title: "Contact Name", field: "LeadName" },
  { title: "Contact Email", field: "Email" },
  { title: "Contact Mobile", field: "MobileNumber" },
  { title: "Purpose", field: "purpose" },
  { title: "Type", field: "type" },
  { title: "Source", field: "Source" },
  { title: "Owner", field: "LeadOwner" },
  { title: "Status", field: "Status" },
];

export const LeadNewpath = [
  "LeadNumber",
  "LeadName",
  "date",
  "description",
  "MobileNumber",
  "Email",
  "purpose",
  "Source",
  "LeadOwner",
  "Status",
  "statusDate",
];
export const UtilitiesMasterPath = ["utilityName", "description", "status"];
export const UtilitiesMasterHeading = [
  { title: "Utility Name", field: "utilityName" },
  { title: "Description", field: "description" },
  { title: "Status", field: "status" },
  { title: "", field: "icon" },
];
export const Utilitypath = [
  "utilityName",
  "utilityProvider",
  "serialNumber",
  "price",
  "period",
  "status",
];

export const Utilityheading = [
  { title: "Utility Name", field: "utilityName" },
  { title: "Utility Provider", field: "utilityProvider" },
  { title: "Serial Number", field: "serialNumber" },
  { title: "Amount", field: "price" },
  { title: "Period", field: "period" },
  { title: "Status", field: "status" },
  { title: "", field: "icon" },
];

export const AmenitiesMasterPath = [
  "amenityName",
  "amenityType",
  "amenityCategory",
  "booking",
  "is_active",
  "images",
];
export const AmenitiesMasterHeading = [
  { title: "Amenity Name", field: "amenityName" },
  { title: "Amenity Type", field: "amenityType" },
  { title: "Amenity Category", field: "amenityCategory" },
  { title: "Booking", field: "booking" },
  { title: "Status", field: "is_active" },
  { title: "Images", field: "images" },
  { title: "", field: "icon" },
];
export const AmenitiesPath = [
  "amenityId",
  "booking",
  "chargeable",
  "amenityName",
  "period",
  "amount",
  "description",
  "status",
];
export const AmenitiesHeadingUnit = [
  { title: "Amenity Name", field: "amenityName" },
  { title: "Description", field: "description" },
  { title: "Chargeable", field: "chargeable" },
  { title: "Amount", field: "amount" },
  { title: "Period", field: "period" },
  { title: "Booking", field: "booking" },
  { title: "Status", field: "status" },
  { title: "", field: "icon" },
];

export const SecurityDashBoardsPath = ["Image", "gate", "name"];
export const SecurityDashBoardsHeading = [
  { title: "Image", field: "Image" },
  { title: "Access Gate", field: "gate" },
  { title: "Person Names", field: "name" },
];

export const InvoicePath = [
  "invoiceNumber",
  "invoiceType",
  "agreementNumber",
  "accountID",
  "invoiceAmount",
  "generatedDate",
  "dueDate",
  "balance",
  "status",
];
export const InvoiceHeading = [
  { title: "Invoice Number", field: "invoiceNumber" },
  { title: "Invoice Type", field: "invoiceType" },
  { title: "Agreement Number", field: "agreementNumber" },
  { title: "Account ID", field: "accountID" },
  { title: "Invoice Amount", field: "invoiceAmount" },
  { title: "Generated Date", field: "generatedDate" },
  { title: "Due Date", field: "dueDate" },
  { title: "Status", field: "status" },
  { title: "Balance", field: "balance" },
];
export const occupencyDashboradPath = [
  "property_no",
  "name",
  "total_units",
  "total_occupied_units",
  "occupancy",
];
export const occupencyDashboradHead = [
  { title: "Property Number", field: "property_no" },
  { title: "Property Name", field: "name" },
  { title: "Total Units", field: "total_units" },
  { title: "Occupied Units", field: "total_occupied_units" },
  { title: "Occupancy", field: "occupancy" },
];

export const teamPerformPath = ["Image", "rep", "Amount", "Target"];
export const teamPerformHead = [
  { title: "Image", field: "Image" },
  { title: "Sales Rep", field: "rep" },
  { title: "Deals Won Amount", field: "Amount" },
  { title: "Quarterly Target", field: "Target" },
];

export const openLeadPath = ["Lead", "Email", "Phone", "Company", 'Created At'];
export const openLeadHead = [
  { title: "Lead", field: "Lead" },
  { title: "Email", field: "Email" },
  { title: "Phone", field: "Phone" },
  { title: "Company", field: "Company" },
  { title: "Created At", field: "Created At" },
];

export const myTaskPath = ["to-do", "Lead", "Email", "Phone", "Company"];
export const myTaskHead = [
  { title: "To Do", field: "to-do" },
  { title: "Lead", field: "Lead" },
  { title: "Email", field: "Email" },
  { title: "Phone", field: "Phone" },
  { title: "Company", field: "Company" },
];

export const top10PropertyPath = ["name", "amount"];
export const top10PropertyHead = [
  { title: "Property Name", field: "name" },
  { title: "Amount", field: "amount" },
];

export const top10AgreeMentPath = ["Number", "amount"];
export const top10AgreeMentHead = [
  { title: "Agreement Number", field: "Number" },
  { title: "Amount", field: "amount" },
];

export const top10AccountPath = ["number", "name", "amount"];
export const top10AccountHead = [
  { title: "Account Number", field: "number" },
  { title: "Name", field: "name" },
  { title: "Amount", field: "amount" },
];

export const gateChangePath = ["Image", "Name", "Number"];
export const gateChangetHead = [
  { title: "Image", field: "Image" },
  { title: "Name", field: "Name" },
  { title: "Contact Number", field: "Number" },
];

export const staffServicePath = ["Image", "Name", "Number", "Type", "App"];
export const staffServiceHead = [
  { title: "Image", field: "Image" },
  { title: "Name", field: "Name" },
  { title: "Contact Number", field: "Number" },
  { title: "Employee Type", field: "Type" },
  // { title: "App", field: "App" },
];
export const DeliveryMilestoneMasterHeading = [
  { title: "", field: "icon" },
  { title: "Delivery Milestone Name", field: "name" },
  { title: "Description", field: "description" },
  { title: "Status", field: "status" },
];
export const DeliveryMilestoneMasterPath = ["name", "description", "status"];
export const DeliveryMilestoneTemplateHeading = [
  { title: "Delivery Milestone Name", field: "name" },
  { title: "Template Based On", field: "templateBasedOn" },
  { title: "Created By", field: "createdBy" },
  { title: "Updated On", field: "updatedOn" },
  { title: "Status", field: "status" },
  { title: "", field: "icon" },
];
export const DeliveryMilestoneTemplatePath = [
  "name",
  "templateBasedOn",
  "createdBy",
  "updatedOn",
  "status",
];

// INSPECTION START

export const InspectionListHeading = (t) =>
  [
    { title: t("jobId"), field: "reference_id" },
    { title: t("type"), field: "type" },
    { title: t("inspector_name"), field: "assigned_to" },
    { title: t("projectedhours"), field: "hours" },
    { title: t("actual"), field: "actual_hours" },
    { title: t("others"), field: "other_hours" },
    { title: t("assignedBy"), field: "assigned_by" },
    { title: t("assignedOn"), field: "assigned_on" },
    { title: t("completedOn"), field: "completed_on" },
    { title: "KPI(Delayed/Ontime)", field: "KPI" },
    { title: t("job_status"), field: "status" },
  ]

export const InspectionListDataType = [
  { type: ["text"], name: "reference_id" },
  { type: ["text"], name: "type" },
  { type: ["text"], name: "assigned_to" },
  { type: ["text"], name: "hours" },
  { type: ["text"], name: "actual_hours" },
  { type: ["text"], name: "other_hours" },
  { type: ["text"], name: "assigned_by" },
  { type: ["date"], name: "assigned_on" },
  { type: ["date"], name: "completed_on" },
  { type: ["text"], name: "KPI" },
  { type: ["inspection_status"], name: "status" },
]
export const InspectionListPath = [
  "reference_id",
  "type",
  "created_at",
  "assigned_to",
  // "execution",
  "hours",
  "actual_hours",
  "other_hours",
  "assigned_by",
  "assigned_on",
  "completed_on",
  "KPI",
  "status",
  "info",
];

export const InspectionMappingHeading = [
  { title: "Image", field: "image" },
  { title: "Property Name", field: "propertyName" },
  { title: "Property No", field: "property_no" },
  { title: "Total Units", field: "total_units" },
];
export const InspectionMappingPath = [
  "image",
  "propertyName",
  "property_no",
  "total_units",
  "icon",
];

export const InspectionMappingDataType = [
  { type: ["avatarmanagement"], name: "image" },
  { type: ["text"], name: "propertyName" },
  { type: ["text"], name: "property_no" },
  { type: ["text"], name: "total_units" },
];
export const InspectionUnitHeading = [
  { title: "Unit Number", field: "unitNumber" },
  { title: "Unit Name", field: "unitName" },
  { title: "Unit Category", field: "unitCategory" },
  { title: "Revenue Type", field: "revenueType" },
  { title: "Unit Purpose", field: "unitPurpose" },
];
export const InspectionUnitPath = [
  "unitNumber",
  "unitName",
  "unitCategory",
  "revenueType",
  "unitPurpose",
];

export const InspectionUnitDataType = [
  { type: ["text"], name: "unitNumber" },
  { type: ["text"], name: "unitName" },
  { type: ["text"], name: "unitCategory" },
  { type: ["text"], name: "revenueType" },
  { type: ["text"], name: "unitPurpose" },
];
export const InspectionUnitItemHeading = [
  { title: "Item Id", field: "reference_no" },
  { title: "Item Type", field: "item" },
  { title: "Item Name", field: "name" },
  // { title: "Product Id", field: "product_id" },
  { title: "Model Number", field: "model_number" },
  { title: "Manufacturer Name", field: "manufaturer_name" },
  { title: "Serial Number", field: "serial_number" },
  { title: "Item Condition", field: "item_condition" },
  { title: "Status", field: "is_active" },
  { title: "", field: "icon" },
];
export const InspectionUnitItemPath = [
  "reference_no",
  "item",
  "name",
  // "product_id",
  "model_number",
  "manufaturer_name",
  "serial_number",
  "item_condition",
  "is_active",
  "icon",
];
export const InspectionUnitItemRow = [
  {
    inspectionName: "Inspection Name Here",
    productId: "PDI-12345",
    status: "Active",
  },
];
export const InspectionUnitItemDataType = [
  { type: ["text"], name: "reference_no" },
  { type: ["text"], name: "item" },
  { type: ["text"], name: "name" },
  // { type: ["text"], name: "product_id" },
  { type: ["text"], name: "model_number" },
  { type: ["text"], name: "manufaturer_name" },
  { type: ["text"], name: "serial_number" },
  { type: ["text"], name: "item_condition" },
  { type: ["status"], name: "is_active" },
  { type: ["view_more"], name: "icon" },
];


export const InspectionItemsData = [
  {
    GeneralItems: [
      {
        id: 1,
        Name: "Inspection Name Here",
        image:
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 1,
      },
      {
        id: 2,
        Name: "Inspection Name Here1",
        image:
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 1,
      },
    ],
    TemplateItems: [
      {
        id: 3,
        Name: "Inspection Name Here",
        image:
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 1,
        sub_items: [
          {
            id: 11,
            image: "",
            Name: "Template Name Here",
            count: "5",
          },
          {
            id: 12,
            image: "",
            Name: "Template Name Here",
            count: "4",
          },
          {
            id: 13,
            image: "",
            Name: "Template Name Here",
            count: "3",
          },
        ],
      },
      {
        id: 4,
        Name: "Inspection Name Here1",
        image:
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 1,
        sub_items: [
          {
            id: 21,
            image: "",
            Name: "Template Name Here",
            count: "5",
          },
          {
            id: 22,
            image: "",
            Name: "Template Name Here",
            count: "5",
          },
          {
            id: 23,
            image: "",
            Name: "Template Name Here",
            count: "5",
          },
          {
            id: 24,
            image: "",
            Name: "Template Name Here",
            count: "5",
          },
        ],
      },
    ],
  },
];
export const CartData = [
  {
    GeneralItems: [
      {
        title: "General Items",
        Items: [
          {
            id: 1,
            Name: "Inspection Name Here",
            image:
              "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            count: 1,
          },
          {
            id: 2,
            Name: "Inspection Name Here1",
            image:
              "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            count: 1,
          },
        ],
      },
    ],
    TemplateItems: [
      {
        title: "Template Items",
        Items: [
          {
            id: 3,
            Name: "Inspection Name Here",
            image:
              "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            count: 1,
            sub_items: [
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "5",
              },
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "2",
              },
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "1",
              },
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "1",
              },
            ],
          },
          {
            id: 4,
            Name: "Inspection Name Here1",
            image:
              "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            count: 1,
            sub_items: [
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "5",
              },
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "2",
              },
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "1",
              },
              {
                img: "",
                inspection_name: "Template Name Here",
                qty: "1",
              },
            ],
          },
        ],
      },
    ],
  },
];


export const PaymentScheduleHeading = [
  {
    title: "Schedule Number", field: "scheduleNumber"
  },
  {
    title: "Start Date", field: "start_date"
  },
  {
    title: "To Date", field: "to_date"
  },
  {
    title: "Payment Period", field: "payment_period"
  },
  {
    title: "Total Amount", field: "total_amount"
  },
  {
    title: "Due Date", field: "due_date"
  },
]

export const PaymentSchedulePath = [
  "scheduleNumber",
  "start_date",
  "to_date",
  "payment_period",
  "total_amount",
  "due_date"
];
export const KycListheading = (t) => [
  { title: `${t("Contact Name")}`, field: "Name" },
  { title: `${t("ID Type")}`, field: "ID" },
  { title: `${t("Name As Per ID")}`, field: "Name_per_id" },
  { title: `${t("ID Number")}`, field: "ID_Number" },
  { title: `${t("Manadatory")}`, field: "Verify" },
  { title: `${t("Valid From")}`, field: "Valid_from" },
  { title: `${t("Valid To")}`, field: "Valid_to" },
  { title: `${t("Verified")}`, field: "verified" },
  { title: `${t("Source")}`, field: "source" }
];

export const KycListpath = [
  "Name",
  "ID",
  "Name_per_id",
  "ID_Number",
  "Verify",
  "Valid_from",
  "Valid_to",
  "verified",
  "source"
];

export const kycdataType = [
  { type: ["text"], name: "Name" },
  { type: ["text"], name: "ID" },
  { type: ["text"], name: "Name_per_id" },
  { type: ["text"], name: "ID_Number" },
  { type: ["text"], name: "Verify" },
  { type: ["date"], name: "Valid_from" },
  { type: ["date"], name: "Valid_to" },
  { type: ["text"], name: "verified" },
  { type: ["text"], name: "source" },
]

export const KycListheading_account = (t) => [
  { title: `${t("Account Number")}`, field: "account_no" },
  { title: `${t("Account Name")}`, field: "account_number" },
  { title: `${t("ID Title")}`, field: "ID" },
  { title: `${t("Name As Per ID")}`, field: "Name_per_id" },
  { title: `${t("ID Number")}`, field: "ID_Number" },
  { title: `${t("Manadatory Verify")}`, field: "Verify" },
  { title: `${t("Valid From")}`, field: "Valid_from" },
  { title: `${t("Valid To")}`, field: "Valid_to" },
  { title: `${t("Issuing Country")}`, field: "Issuing_Country" },
];

export const KycListpath_account = [
  "account_no",
  "account_number",
  "ID",
  "Name_per_id",
  "ID_Number",
  "Verify",
  "Valid_from",
  "Valid_to",
  "Issuing_Country"
];

export const kycdataType_account = [
  { type: ["text"], name: "account_no" },
  { type: ["text"], name: "account_number" },
  { type: ["text"], name: "ID" },
  { type: ["text"], name: "Name_per_id" },
  { type: ["text"], name: "ID_Number" },
  { type: ["text"], name: "Verify" },
  { type: ["date"], name: "Valid_from" },
  { type: ["date"], name: "Valid_to" },
  { type: ["text"], name: "Issuing_Country" },
]


export const pricingApprialsdataType = [
  { type: ["text"], name: "Appraisal_Reference_ID" },
  { type: ["text"], name: "Component_Type" },
  { type: ["text"], name: "Pricing_Component_Name" },
  { type: ["text"], name: "Revenue_Type" },
  { type: ["text"], name: "Component_Based_On" },
  { type: ["text"], name: "Previous_Value" },
  { type: ["text"], name: "Present_Value" },
  { type: ["status"], name: "Status" },
  { type: ["more_appraisal"], name: "icon" },
]

export const pricingApprialsHeader = (t) => [
  { title: `${t("Appraisal Reference ID")}`, field: "Appraisal_Reference_ID" },
  { title: `${t("Component Type")}`, field: "Component_Type" },
  { title: `${t("Pricing Component Name")}`, field: "Pricing_Component_Name" },
  { title: `${t("Revenue Type")}`, field: "Revenue_Type" },
  { title: `${t("Component Based On")}`, field: "Component_Based_On" },
  { title: `${t("Previous Value")}`, field: "Previous_Value" },
  { title: `${t("Present Value")}`, field: "Present_Value" },
  { title: `${t("Status")}`, field: "Status" },
  { title: ``, field: "" },
];

export const pricingApprialsPath = [
  "Appraisal_Reference_ID",
  "Component_Type",
  "Pricing_Component_Name",
  "Revenue_Type",
  "Component_Based_On",
  "Previous_Value",
  "Present_Value",
  "Status",
  "icon"
];

export const rentalIndexType = [
  { type: ["info"], name: "icon" },
  { type: ["text"], name: "PropertyName" },
  { type: ["text"], name: "Location" },
  { type: ["text"], name: "UnitNumber" },
  { type: ["text"], name: "UnitType" },
  { type: ["text"], name: "UnitPurpose" },
  { type: ["text"], name: "Revenuetype" },
  { type: ["text"], name: "Bedrooms" },
]

export const rentalIndexHeading = (t) => [
  { title: ``, field: "" },
  { title: `${t("PropertyName")}`, field: "PropertyName" },
  { title: `${t("Location")}`, field: "Location" },
  { title: `${t("UnitNumber")}`, field: "UnitNumber" },
  { title: `${t("UnitType")}`, field: "UnitType" },
  { title: `${t("UnitPurpose")}`, field: "UnitPurpose" },
  { title: `${t("Revenuetype")}`, field: "Revenuetype" },
  { title: `${t("Bedrooms")}`, field: "Bedrooms" },
  // { title: `${t("Status")}`, field: "Status" },
];

export const rentalIndexPath = [
  "PropertyName",
  "Location",
  "UnitNumber",
  "UnitType",
  "UnitPurpose",
  "Revenuetype",
  "Bedrooms"
];


export const reservationType = [
  { type: ["text"], name: "Reservation_ID" },
  { type: ["text"], name: "Reservation_Type" },
  { type: ["text"], name: "Quotation_ID" },
  { type: ["text"], name: "Purpose" },
  { type: ["text"], name: "No.Of_Units" },
  { type: ["text"], name: "Revenue" },
  { type: ["date"], name: "Reservation_Valid_Till" },
  { type: ["status"], name: "Status" },
]

export const reservationHeading = (t) => [
  { title: `${t("Reservation_ID")}`, field: "Reservation_ID" },
  { title: `${t("Reservation_Type")}`, field: "Reservation_Type" },
  { title: `${t("Quotation_ID")}`, field: "Quotation_ID" },
  { title: `${t("Purpose")}`, field: "Purpose" },
  { title: `${t("No.Of_Units")}`, field: "No.Of_Units" },
  { title: `${t("Revenue")}`, field: "Revenue" },
  { title: `${t("Reservation_Valid_Till")}`, field: "Reservation_Valid_Till" },
  { title: `${t("Status")}`, field: "Status" }
];

export const reservationPath = [
  "Reservation_ID",
  "Reservation_Type",
  "Quotation_ID",
  "Purpose",
  "No.Of_Units",
  "Revenue",
  "Reservation_Valid_Till",
  "Status"
];


// manage invoice
export const manageInvoiceType = [
  { type: ["inputCheck"], name: "selected" },
  { type: ["text"], name: "Agreement_Number" },
  { type: ["text"], name: "Account_Number" },
  { type: ["text"], name: "Account_Name" },
  { type: ["text"], name: "Managed_Invoice_Number" },
  { type: ["text"], name: "Description" },
  { type: ["date"], name: "Posting_Date" },
  { type: ["text"], name: "Invoice_Type" },
  { type: ["text"], name: "Total_Amount" },
  { type: ["text"], name: "Payment_Method" },
]

export const manageInvoiceHeading = (t, selected = 0) => [
  // { title: `${selected} ${t("selected")}`, field: "invoice_id", },
  { Checkbox: ["checkbox"], field: "invoice_id" },
  { title: `${t("Agreement_Number")}`, field: "Agreement_Number" },
  { title: `${t("Account_Number")}`, field: "Account_Number" },
  { title: `${t("Account_Name")}`, field: "Account_Name" },
  { title: `${t("Managed_Invoice_Number")}`, field: "Managed_Invoice_Number" },
  { title: `${t("Description")}`, field: "Description" },
  { title: `${t("Posting_Date")}`, field: "Posting_Date" },
  { title: `${t("Invoice_Type")}`, field: "Invoice_Type" },
  { title: `${t("Total_Amount")}`, field: "Total_Amount" },
  { title: `${t("Payment_Method")}`, field: "Payment_Method" },
];

export const manageInvoicePath = [
  "selected",
  "Agreement_Number",
  "Account_Number",
  "Account_Name",
  "Managed_Invoice_Number",
  "Description",
  "Posting_Date",
  "Invoice_Type",
  "Total_Amount",
  "Payment_Method"
];

// manage invoice preview
export const manageInvoicePreviewType = [
  { type: ["text"], name: "Managed_Invoice_Number" },
  { type: ["text"], name: "Account_Number" },
  { type: ["text"], name: "Total_Amount" },

]

export const manageInvoicePreviewHeading = (t, selected = 0) => [
  { title: `${t("Agreement_Number")}`, field: "Managed_Invoice_Number" },
  { title: `${t("Account_Number")}`, field: "Account_Number" },
  { title: `${t("Account_Number")}`, field: "Total_Amount" },
];

export const manageInvoicePreviewPath = [
  "Managed_Invoice_Number",
  "Account_Number",
  "Total_Amount"
];

// announsment
export const Announcement_Type = (icon, type) => [
  { type: [icon ? "more_5" : ""], name: "icon" },
  { type: ["text"], name: "category" },
  { type: ["description"], name: "announcement_title" },
  { type: ["text"], name: "properties" },
  { type: ["text"], name: "apps" },
  { type: ["text"], name: "attachments" },
  { type: ["text"], name: type === "publish" ? "published_by" : "created_by" },
  { type: ["text"], name: type === "publish" ? "published_date" : "created_date" }
]

export const Announcement_Heading = (t, type) => [
  { title: ``, field: "" },
  { title: `${t("Category")}`, field: "category" },
  { title: `${t("Announcement Title")}`, field: "announcement_title" },
  { title: `${t("Properties")}`, field: "properties" },
  { title: `${t("Apps")}`, field: "apps" },
  { title: `${t("Attachments")}`, field: "attachments" },
  {
    title: `${type === "publish" ? t("Published By") : t("Created By")}`,
    field: type === "publish" ? "published_by" : "created_by"
  },
  {
    title: `${type === "publish" ? t("Published Date") : t("Created Date")}`,
    field: type === "publish" ? "published_date" : "created_date"
  }
];

export const Announcement_Path = (type) => [
  "category",
  "announcement_title",
  "properties",
  "apps",
  "attachments",
  [...type === "publish" ?
    ["published_by", "published_date"] :
    ["created_by", "created_date"]]

];

export const domesticContactListHeading = (t) => [
  { title: `${t("postedBy")}`, field: "postedBy" },
  { title: `${t("contactName")}`, field: "name" },
  { title: `${t("contactNumber")}`, field: "contact_number" },
  { title: `${t("units")}`, field: "unit" },
  { title: `${t("unitNo")}`, field: "unitNo" },
  { title: `${t("profession")}`, field: "profession" },
];
export const domesticContactListPath = [
  "postedBy",
  "name",
  "contact_number",
  "unit",
  "unitNo",
  "profession",
];
export const domesticContactListDataType = [
  { type: ["text"], name: "postedBy" },
  { type: ["text"], name: "name" },
  { type: ["text"], name: "contact_number" },
  { type: ["text"], name: "unit" },
  { type: ["text"], name: "unitNo" },
  { type: ["text"], name: "profession" },
]

export const reportType = [
  { type: ["text"], name: "property_id" },
  { type: ["text"], name: "Property_Name" },
  { type: ["text"], name: "Unit_id" },
  { type: ["text"], name: "Unit_name" },
  { type: ["text"], name: "Unit_category" },
  { type: ["text"], name: "unit_purpose" },
  { type: ["text"], name: "status" }
];

export const reportHeading = (t) => [
  { title: `${t("property_id")}`, field: "property_id" },
  { title: `${t("Property_Name")}`, field: "Property_Name" },
  { title: `${t("Unit_id")}`, field: "Unit_id" },
  { title: `${t("Unit_name")}`, field: "Unit_name" },
  { title: `${t("Unit_category")}`, field: "Unit_category" },
  { title: `${t("unit_purpose")}`, field: "unit_purpose" },
  { title: `${t("status")}`, field: "status" }
];

export const reportPath = [
  "property_id",
  "Property_Name",
  "Unit_id",
  "Unit_name",
  "Unit_category",
  "unit_purpose",
  "status",
];
export const ScheduleHeading = [
  { title: "Inspection ID", field: "inspection_id" },
  // { title: "Unit ID", field: "unit_id" },
  { title: "Property Name", field: "property_name" },
  { title: "Inspector Name", field: "resource_name" },
  { title: "Eta", field: "eta_minutes" },
  { title: "Assigned On", field: "assigned_on" },
  // { title: "Assigned By", field: "assigned_By" },
  { title: "Status", field: "agreement_inspection_status" },

];
export const ScheduleDataType = [
  { type: ["text"], name: "inspection_id" },
  // { type: ["text"], name: "unit_id" },
  { type: ["text"], name: "property_name" },
  { type: ["text"], name: "resource_name" },
  { type: ["text"], name: "eta_minutes" },
  { type: ["date"], name: "assigned_on" },
  // { type: ["text"], name: "assigned_By" },
  { type: ["agreement_inspection_status"], name: "agreement_inspection_status" },
];
export const SchedulePath = [
  "inspection_id",
  // "unit_id",
  "property_name",
  "resource_name",
  "assigned_By",
  "assigned_on",
  "agreement_inspection_status"
];
export const committeeTableHeading = (t) => [
  { title: `${t("Image")}`, field: "image" },
  { title: `${t("Name")}`, field: "member_name" },
  { title: "Unit No", field: "unit_no" },
  { title: "Type", field: "is_owner" },
  { title: `${t("Role")}`, field: "role" },
  { title: `${t("Start Period")}`, field: "assigned_on" },
  { title: `${t("Status")}`, field: "is_active" },
  { title: "", field: "more" },
];
export const committeeTableDatatype = [
  { type: ["avatarmanagement"], name: "image" },
  { type: ["text"], name: "member_name" },
  { type: ["text"], name: "unit_no" },
  { type: ["text"], name: "is_owner" },
  { type: ["role"], name: "role" },
  { type: ["date"], name: "assigned_on" },
  { type: ["status"], name: "is_active" },
  { type: ["more"], name: "icon" },
];
export const committeeTablePath = [
  "image",
  "member_name",
  "unit_no",
  "is_owner",
  "role",
  "assigned_on",
  "is_active",
  "more"
]


export const fileManagerType = [
  { type: ["image_icon"], name: "icon" },
  { type: ["text"], name: "Folder_Name" },
  { type: ["text"], name: "Created_By" },
  { type: ["text"], name: "Date_Modified" },
  { type: ["text"], name: "Number_Files" },
  { type: ["file_manager_option"], name: "icon" },
]

export const fileManagerHeading = (t) => [
  { title: ``, field: "" },
  { title: `${t("Folder_Name")}`, field: "Folder_Name" },
  { title: `${t("Created_By")}`, field: "Created_By" },
  { title: `${t("Date_Modified")}`, field: "Date_Modified" },
  { title: `${t("Number_Files")}`, field: "Number_Files" },
  { title: ``, field: "" },
];

export const fileManagerPath = [
  "icon",
  "Folder_Name",
  "Created_By",
  "Date_Modified",
  "Number_Files",
];
export const TimeOffResourceBookingHeading = (t) => [
  { title: `${t("resource_name")}`, field: "contact_name" },
  { title: `${t("type")}`, field: "leave_type" },
  { title: `${t("timeOff_leaveType")}`, field: "leave_name" },
  { title: `${t("startDateAndTime")}`, field: "start_date_time" },
  { title: `${t("endDateAndTime")}`, field: "end_date_time" },
  { title: `${t("durationType")}`, field: "duration_type" },
  // {title:`${t("days")}`,field:"no_of_days"},
  // {title:`${t("hours")}`,field:"hours"},
  // {title:`${t("created_by")}`,field:"created_by"},
]
export const TimeOffResourceBookingDataType = [
  { type: ["text"], name: "contact_name" },
  { type: ["text"], name: "leave_type" },
  { type: ["text"], name: "leave_name" },
  { type: ["text"], name: "start_date_time" },
  { type: ["text"], name: "end_date_time" },
  { type: ["text"], name: "duration_type" },
  // { type: ["text"], name: "no_of_days" },
  // { type: ["text"], name: "hours" },
  // { type: ["text"], name: "created_by" },

]
export const TimeOffResourceBookingPath = [
  "contact_name",
  "leave_type",
  "leave_name",
  "start_date_time",
  "end_date_time",
  "duration_type",
  // "no_of_days",
  // "hours",
  // "created_by"
];
export const resourceBookingHeading = (t) => [
  { title: `${t("job_id")}`, field: "job_reference_id" },
  { title: `${t("date")}`, field: "created_at" },
  { title: `${t("name")}`, field: "name" },
  { title: `${t("job")}`, field: "job" },
  { title: `${t("job_type")}`, field: "type" },
  { title: `${t("start_date_time")}`, field: "start_date_time" },
  { title: `${t("end_date_time")}`, field: "end_date_time" },
  { title: `${t("eta")}`, field: "eta" },
];
export const resourceBookingDataType = [
  { type: ["text"], name: "job_reference_id" },
  { type: ["text"], name: "created_at" },
  { type: ["text"], name: "name" },
  { type: ["text"], name: "job" },
  { type: ["text"], name: "type" },
  { type: ["text"], name: "start_date_time" },
  { type: ["text"], name: "end_date_time" },
  { type: ["text"], name: "eta" },
]
export const resourceBookingPath = [
  "job_reference_id",
  "created_at",
  "name",
  "job",
  "type",
  "start_date_time",
  "end_date_time",
  "eta"
];
export const serviceInspectionHeading = (t) => [

  { title: `${t("serviceOrderId")}`, field: "reference_id" },
  { title: `${t("maintenanceRequestId")}`, field: "maintenance_request_no" },
  { title: `${t("propertyName")}`, field: "property_name" },
  { title: `${t("unitName")}`, field: "unit_name" },
  { title: `${t("job_date")}`, field: "created_at" },
  { title: `${t("inspector_name")}`, field: "assigned_to" },
  { title: `${t("projected_hours")}`, field: "hours" },
  { title: `${t("actual")}`, field: "actual_hours" },
  { title: `${t("others")}`, field: "other_hours" },
  { title: `${t("job_status")}`, field: "status" },
  { title: `${t("completed_on")}`, field: "completed_on" },
  { title: "", field: "icon" }

]
export const serviceInspectionDataType = [
  { type: ["text"], name: "reference_id" },
  { type: ["text"], name: "maintenance_request_no" },
  { type: ["text"], name: "property_name" },
  { type: ["text"], name: "unit_name" },
  { type: ["date"], name: "created_at" },
  { type: ["text"], name: "assigned_to" },
  { type: ["text"], name: "hours" },
  { type: ["text"], name: "actual_hours" },
  { type: ["text"], name: "other_hours" },
  { type: ["agreement_inspection_status"], name: "status" },
  { type: ["date"], name: "completed_on" },
  { type: ["more_6_ins_item"], field: "icon" }
]
export const serviceInspectionPath = [
  "reference_id",
  "maintenance_request_no",
  "property_name",
  "unit_name",
  "created_at",
  "assigned_to",
  "hours",
  "actual_hours",
  "other_hours",
  "status",
  "completed_on",
  "icon"
];
export const generalInspectionHeading = (t) => [
  { title: `${t("requestno")}`, field: "reference_id" },
  { title: `${t("requestedOn")}`, field: "created_at" },
  { title: `${t("unitId")}`, field: "unit_no" },
  // { title: `${t("requestedSource")}`, field: "requested_source" },
  { title: `${t("propertyDetails")}`, field: "property_details" },
  { title: `${t("inspector")}`, field: "assigned_to" },
  { title: `${t("status")}`, field: "status" },
  { title: "", field: "icon" }
]
export const generalInspectionDataType = [
  { type: ["text"], name: "reference_id" },
  { type: ["date"], name: "created_at" },
  { type: ["text"], name: "unit_no" },
  // { type: ["text"], name: "requested_source" },
  { type: ["text"], name: "property_details" },
  { type: ["text"], name: "assigned_to" },
  { type: ["agreement_inspection_status"], name: "status" },
  { type: ["more_6_ins_item"], field: "icon" }

]
export const generalInspectionPath = [
  "reference_id",
  "created_at",
  "unit_no",
  // "requested_source",
  "property_details",
  "assigned_to",
  "status",
  "icon"
];
export const CompletedJobHdr = [
  { title: "Resource Name", field: "name" },
  { title: "Jobs", field: "job" },
  { title: "Projected", field: "projected_hour" },
  { title: "Actual", field: "actual_hour" },
  { title: "Others", field: "other_hours" },
  { title: "Ontime %", field: "on_time" },
  { title: "Delayed %", field: "delayed" },
];
export const CompletedJobDataType = [
  { type: ["text"], name: "name" },
  { type: ["text"], name: "job" },
  { type: ["text"], name: "projected_hour" },
  { type: ["text"], name: "actual_hour" },
  { type: ["text"], name: "other_hours" },
  { type: ["text"], name: "on_time" },
  { type: ["text"], name: "delayed" }
]
export const CompletedJobPath = [
  "name",
  "job",
  "projected_hour",
  "actual_hour",
  "other_hours",
  "on_time",
  "delayed"
];
export const OngoingJobHdr = [
  { title: "Resource Name", field: "name" },
  { title: "Job Id", field: "job_id" },
  { title: "Job Type", field: "job_type" },
  { title: "Job Date", field: "assigned_date" },
  { title: "Completion %", field: "completion" },
  { title: "Job Status", field: "status" },
];
export const OngoingJobDataType = [
  { type: ["text"], name: "name" },
  { type: ["text"], name: "job_id" },
  { type: ["text"], name: "job_type" },
  { type: ["date"], name: "assigned_date" },
  { type: ["text"], name: "completion" },
  { type: ["text"], name: "status" },
]
export const OngoingJobPath = [
  "name",
  "job_id",
  "job_type",
  "assigned_date",
  "completion",
  "status",
];
export const ResourceHdr = [
  { title: "Resource Name", field: "name" },
  { title: "Total Jobs", field: "job" },
  { title: "Assigned", field: "assigned" },
  { title: "Ongoing", field: "ongoing" },
  { title: "Completed", field: "completed" },
];
export const ResourceDataType = [
  { type: ["text"], name: "name" },
  { type: ["text"], name: "job" },
  { type: ["text"], name: "assigned" },
  { type: ["date"], name: "ongoing" },
  { type: ["text"], name: "completed" },
]
export const ResourcePath = [
  "name",
  "job",
  "assigned",
  "ongoing",
  "completed",
]
export const getSiteVisitRequestHeading = (t) => [
  { title: `${t("requestno")}`, field: "reference_id" },
  { title: `${t("requestedOn")}`, field: "created_at" },
  { title: `${t("unitId")}`, field: "unit_no" },
  // { title: `${t("requestedSource")}`, field: "requested_source" },
  { title: `${t("propertyDetails")}`, field: "property_details" },
  { title: `${t("inspector")}`, field: "assigned_to" },
  { title: `${t("status")}`, field: "status" },
  { title: "", field: "icon" }
]
export const siteVisitRequestDataType = [
  { type: ["text"], name: "reference_id" },
  { type: ["date"], name: "created_at" },
  { type: ["text"], name: "unit_no" },
  // { type: ["text"], name: "requested_source" },
  { type: ["text"], name: "property_details" },
  { type: ["text"], name: "assigned_to" },
  { type: ["agreement_inspection_status"], name: "status" },
  { type: ["more_6_ins_item"], field: "icon" }

]
export const siteVisitRequestPath = [
  "reference_id",
  "created_at",
  "unit_no",
  // "requested_source",
  "property_details",
  "assigned_to",
  "status",
  "icon"
];

export const ActivityBoardHeading = [
  { title: "", field: "img" },
  { title: "Activity Name", field: "name" },
  { title: "Action Info", field: "action_info" },
  // { title: "Purpose", field: 'purpose' },
  { title: "Date", field: "date" },
  { title: "Created By", field: "createdBy" },
  { title: "Priority", field: "priority" },
  { title: "Status", field: "status" },
];

export const ActivityBoardPath = [
  "img",
  "name",
  "action_info",
  "date",
  "createdBy",
  "priority",
  "status"
]