import { Box, Button, Grid, Typography, Stack, Divider } from '@mui/material'
import React from 'react'
import { AlertDialog, MenuList, Subheader, UseDebounce } from '../../components'
import { withNamespaces } from "react-i18next";
import { InviteTriggerStyle } from './style'
import { ProfileCard, InviteCard, InvitePopup } from './component';
import { GET_CONTACT_DETAILS as GET_CONTACT } from '../../graphql/resourceMaster';
import { useApolloClient } from "@apollo/client";
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';

const InviteTriggerComp = ({ t }) => {

    const classes = InviteTriggerStyle()
    const client = useApolloClient();
    const debounce = UseDebounce()
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const [selected, setSelected] = React.useState("")
    const [search, setSearch] = React.useState("");
    const [offset, setOffset] = React.useState(0);
    const [menuList, setMenuList] = React.useState([])
    const [companyList, setCompanyList] = React.useState([]);
    const [permission, setPermission] = React.useState({})
    const [selectedCompany, setSelectedCompany] = React.useState({});

    const [invitePopup, setInvitePopup] = React.useState(false)
    const [access, setAccess] = React.useState([])
    const [selectedInvite, setSelectedInvite] = React.useState("")

    const [show , setShow] =React.useState(true)

    const selectList = (e) => {
        setSelected(e)
        getUserAccess(e)
    }

    React.useEffect(() => {
        const _ = getRoutePermissionNew(auth)
        if (_) {
            setPermission(_)
            if (_?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                // const moduleList = JSON.parse(localStorage.getItem(LocalStorageKeys.modules))
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    getMenuList(0, "", true , company?.selected)
                    // setModuleData({
                    //     modules: moduleList
                    // })
                }
            }
        }


        // eslint-disable-next-line        
    }, [auth])

    //   company switcher
    const handleCompanyChange = (e) => {
        setSelectedCompany(e);
        getMenuList(0, "", true , e )
    };

    // infinity scroll
    const fetchMoreData = () => {
        setOffset(offset + 10);
        getMenuList(offset + 10, search, false ,  selectedCompany);
    };
    //handle search
    const handleSearch = (value) => {
        setSearch(value);
        getMenuList(0, value, true ,  selectedCompany)
    }
    //Get MenuList
    const getMenuList = (offset, search, reload , company) => {
        client.query({
            query: GET_CONTACT,
            fetchPolicy: 'network-only',
            variables: {
                offset: offset ?? 0,
                limit: 10,
                search: search ?? "",
                client: localStorage.getItem(LocalStorageKeys.clinetID),
                company:company?.value
            }
        }).then((response) => {
            if (reload) {
                setMenuList(response?.data?.list)
                setSelected(response?.data?.list?.[0])
                if(response?.data?.list?.[0]){
                    getUserAccess(response?.data?.list?.[0])
                    setShow(true)
                }
                if(!response?.data?.list?.[0]){
                    setShow(false)
                }
            } else {
                setMenuList([...menuList, ...response?.data?.list])
            }

        }).catch((err) => {
            console.log(err)
        })
    }

    //Get access Information
    const getUserAccess = (e) => {
        let payload = {
            contact_id: e?.id
        }
        NetworkCall(
            `${config.api_url}/invite/get_user_access`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            setAccess(r?.data?.data)
        }).catch((error) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            })
        })
    }

    const invite = (data) => {
        setSelectedInvite(data)
        setInvitePopup(!invitePopup)
    }

    const clickResentInvite = (value) => {
        debounce(() => resendInvite(value?.id), 400)
    }

    const resendInvite = (id) => {
        let payload = {
            contact_id: id
        }
        NetworkCall(
            `${config.api_url}/invite/resend`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: r?.data?.data,
            })
        }).catch((error) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            })
        })
    }

    const inviteClick = (inviteData) => {
        let selectedCompany = inviteData?.stateData?.company?.map((c) => {
            return c?.value
        })
        let selectedProperty = inviteData?.stateData?.property?.map((p) => {
            return p?.value
        })
        let payload = {
            contact_id: selected?.id,
            role_id: inviteData?.selectedInvite?.id,
            access: {
                company_ids: selectedCompany,
                properties: selectedProperty
            }
        }
        NetworkCall(
            selectedInvite?.is_invite ? `${config.api_url}/invite/app` : `${config.api_url}/invite/app_access_update`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: r?.data?.data,
            })
            setInvitePopup(!invitePopup)
            getUserAccess(selected)
        }).catch((error) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            })
        })
    }

    const render = () => {
        return (
            <Box>
                <Subheader
                    title={"Invite"}
                    hideBackButton
                    select={true}
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        handleCompanyChange(e);
                    }}
                />
                <Box p={2}>
                    <Grid container>
                        <Grid item md={2.5} sm={2.5} className={classes.listGrid}>
                            <Box p={2}>
                                <Typography className={classes.memberList}>
                                    Members list
                                </Typography>
                            </Box>
                            <Divider></Divider>
                            <Box p={2}>
                                <MenuList
                                    list={menuList}
                                    selected={selected?.id}
                                    setSelected={selectList}
                                    searchLabel={t("search")}
                                    fetchMoreData={fetchMoreData}
                                    handleSearch={handleSearch}
                                    searchValue={search}
                                    boxPadding={4}
                                    listHeight={270}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={9.5} sm={9.5} pl={2}>
                        <Box className={classes.contentBox}>
                            {
                                show ? 
                                <>
                                                                <Box p={2}>
                                    <Typography className={classes.nameTitle}> {selected?.name} </Typography>
                                </Box>
                                <ProfileCard data={selected} />
                                <Box className={classes.inviteBox}>
                                    <Stack direction={"row"} justifyContent={"space-between"} p={2}>
                                        <Stack>
                                            <Typography className={classes.title}>Invite to modules</Typography>
                                            <Typography className={classes.subtitle}>Send an invite to the member for the module and role.</Typography>
                                        </Stack>
                                        <Button variant='contained' className={classes.inviteBtn} onClick={() => clickResentInvite(selected)}>Resend Invite</Button>
                                    </Stack>
                                    <Divider></Divider>
                                    <Grid container spacing={2} p={2}>
                                        {
                                            access?.modules?.map(e => {
                                                return (
                                                    <Grid item md={6}>
                                                        <InviteCard data={e} click={invite} />
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                    <Divider></Divider>
                                    <Stack p={2}>
                                        <Typography className={classes.title}>Invite to Apps</Typography>
                                        <Typography className={classes.subtitle}>Send an invite to the member for the app.</Typography>
                                    </Stack>
                                    <Divider></Divider>
                                    <Grid container spacing={2} p={2}>
                                        {
                                            access?.apps?.map(e => {
                                                return (
                                                    <Grid item md={6}>
                                                        <InviteCard data={e} click={invite} type="app" />
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Box></>:""
                            }

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <AlertDialog open={invitePopup}
                    onClose={() => setInvitePopup(!invitePopup)}
                    header={"INVITE"}
                    component={<InvitePopup selectedInvite={selectedInvite} inviteClick={inviteClick} />}
                    isNormal
                />
            </Box>
        )
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )
}
export default withNamespaces("inviteTrigger")(InviteTriggerComp); 