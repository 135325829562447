import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { CircularProgress, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { config } from '../../config';
import { AlertContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { AlertProps, findImageSize, NetWorkCallMethods, SemiBold ,convertUrlToFileObject, image_size,findImageSizes} from '../../utils';
import { returnFileSize } from './utils';


const CustomBox = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 4,
    overflow: 'hidden',
    border: '2px dashed #E4E8EE',
    backgroundColor: '#F5F7FA',
    // borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3)
}));

const CustomInput = styled('input')({
    position: 'absolute',
    opacity: 0,
    inset: 0,
    zIndex: 1,
    cursor: 'pointer'
})

const CustomTypography = styled(Typography)(({ theme }) => ({
    fontFamily: SemiBold,
    fontSize: 12,
    color: '#000',
    marginTop: theme.spacing(1)
}))

const FlexColumn = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})



export const UploadImage = (props) => {

    const {
        accept = "image/*",
        conditions = {},
        fileUploadUrl = true,
        handleChange = null,
        height = "auto",
        width = "auto"
        // value = ""
    } = props;

    // const theme = useTheme();
    const alert = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);

    const handleUpload = async (data) => {
        //only allow if file selected
        let fileDetails = {
            name: data?.[0]?.name,
            size: returnFileSize(data?.[0]?.size),
            type: data?.[0]?.type?.split('/')?.[1]
        }
        if (data?.length) {
            //condition check
            if (conditions?.width && conditions?.height) {
                let { width = null, height = null } = await findImageSize(data[0]);
                if (width === conditions?.width && height === conditions?.height) {
                    if (fileUploadUrl) {
                        executeImageData(data, fileDetails)
                    }
                    else {
                        handleChange && handleChange({ ...fileDetails, data })
                    }
                }
                else {
                    alert.setSnack({
                        open: true,
                        horizontal: AlertProps.horizontal.center,
                        vertical: AlertProps.vertical.top,
                        msg: `Please select the image size in ("${conditions?.width}x${conditions?.height}")`,
                        severity: AlertProps.severity.error,
                    });
                }
            }
            else {
                if (fileUploadUrl) {
                    executeImageData(data, fileDetails)
                }
                else {
                    handleChange && handleChange({ ...fileDetails, data })
                }
            }
        }
    }

    const executeImageData = async (data, fileDetails) => {

        setLoading(true);
        props?.setLoading && props?.setLoading(true);
        const formData = new FormData();
        formData.append("files", data?.[0]);
        formData.append("tenantId", `${config.tenantId}`);

        await NetworkCall(
            `${config.api_url}/file-upload`,
            NetWorkCallMethods.post,
            formData,
            null,
            true,
            false
        )
            .then(async(response) => {
                const result =  await findImageSizes(await convertUrlToFileObject(response.data.fileUrls[0].url))

                if(result?.height === image_size?.height && result?.width === image_size?.width){
                    handleChange && handleChange({ ...fileDetails, src: response.data.fileUrls[0].url });
                    setLoading(false);
                    props?.setLoading && props?.setLoading(false);
                }
                else{
                    alert.setSnack({
                        open: true,
                        horizontal: AlertProps.horizontal.center,
                        vertical: AlertProps.vertical.top,
                        msg: `Image width must be ${image_size?.width}px and height ${image_size?.height}px`,
                        severity: AlertProps.severity.error,
                    });   
                    setLoading(false);
                    props?.setLoading && props?.setLoading(false);
                }
            })
            .catch((err) => {
                handleChange(null);
                setLoading(false);
                props?.setLoading && props?.setLoading(false);
                alert.setSnack({
                    open: true,
                    horizontal: AlertProps.horizontal.center,
                    vertical: AlertProps.vertical.top,
                    msg: `Unable to upload`,
                    severity: AlertProps.severity.error,
                });

            });
    }

    return (
        <div>
            <CustomBox style={{ height: height, width: width }}>
                {
                    loading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <CustomInput
                                type="file"
                                onChange={(e) => handleUpload(e.target.files)}
                                accept={accept}
                            />
                            <FlexColumn>
                                <FileUploadOutlinedIcon color='primary' />
                                <CustomTypography>Upload mages here</CustomTypography>
                            </FlexColumn>
                        </>
                    )
                }
            </CustomBox>
        </div>
    )
}

UploadImage.propTypes = {
    accept: PropTypes.string,
    conditions: PropTypes.object,
    fileUploadUrl: PropTypes.bool,
    handleChange: PropTypes.func,
}
