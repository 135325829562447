import { makeStyles } from "@mui/styles";
import { Regular, SemiBold } from "../../../utils";

export const VenueCardStyle = makeStyles((theme) => ({
    root: {
        overflow: "auto",
        height: "430px"
    },
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        cursor: "pointer"
    },
    name: {
        fontSize: 14,
        fontWeight: "bold",
        color: theme.typography.color.primary
    },
    slot: {
        fontSize: 12,
        color: theme.typography.color.secondary
    },
    selectedbox: {
        border: `1px solid ${theme.palette.info.main}`,
        borderRadius: 4,
        backgroundColor: theme.palette.info.light,
        cursor: "pointer"
    }
}))

export const CalendarStyle = makeStyles((theme) => ({
    iconbutton: {
        border: `1px solid ${theme.palette.border.secondary}`
    },
    day: {
        fontSize: 12,
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        border: `1px solid ${theme.palette.border.secondary}`,
        padding: 4
    },
    date_curr_month: {
        border: `1px solid ${theme.palette.border.secondary}`,
        color: theme.typography.color.primary,
        fontSize: 14,
        fontWeight: "bold",
        cursor: "pointer"
    },
    date_not_curr_month: {
        border: `1px solid ${theme.palette.border.secondary}`,
        color: theme.palette.border.secondary,
        fontSize: 14,
        fontWeight: "bold",
        cursor: "not-allowed"
    },
    today: {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.info.light,
        color: theme.palette.primary.main,
        borderRadius: 4,
        fontSize: 14,
        fontWeight: "bold"
    }
}))

export const AvailableSlotsStyle = makeStyles((theme) => ({
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        cursor: "pointer"
    },
    selectedBox: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 4,
        cursor: "pointer",
        backgroundColor: "#5078E10D"
    },
    dot: {
        height: 4,
        width: 4,
        borderRadius: 50,
        backgroundColor: theme.palette.border.secondary
    },
    time: {
        fontSize: 12,
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    },
    selectedTime: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    content: {
        fontSize: 10,
        color: theme.typography.color.tertiary,
        fontWeight: "bold"
    },
    selectedContent: {
        fontSize: 10,
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    noneTitle: {
        fontSize: 14,
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    },
    noneContent: {
        fontSize: 12,
        color: theme.typography.color.secondary
    },
    none: {
        justifyContent: "center",
        alignItems: "center",
        height: "400px !important",
        display: "flex"
    },
    root_none: {
        // height: "100%"
    },
    slot_not_select: {
        height: 400,
        overflow: "auto"
    },
    slot_section: {
        height: 370,
        overflow: "auto"
    },
    walkinBtn: {
        height: 48,
        '&:hover': {
            backgroundColor: "#fff"
        }
    },
    bookBtn: {
        height: 48
    },
    btnBox: {
        padding: 4,
        borderRadius: 4,
        border: `1px solid ${theme.palette.border.secondary}`,
        boxShadow: "0px 0px 16px #00000014"
    }
}))

export const AmenityDropdownStyle = makeStyles((theme) => ({
    avatar: {
        height: 25,
        width: 25
    },
    dropdown: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6,
        cursor: "pointer",
        zIndex: 1,
        position: "absolute",
        left: "77px",
        backgroundColor: props => props?.listLength > 0 ? "#fff" : "#f1f1f1",
        top: "130px",
        width:200
    },
    amenity_content: {
        // border: `1px solid ${theme.palette.border.secondary}`,
        // borderRadius: 6,
        // cursor: "pointer",
        // zIndex: 3,
        // position: "absolute",
        // left: "7%",
        // backgroundColor: "#fff",
        // top: "190px",
        width: 850,
        margin: 6,
        padding: "12px 12px 0px 12px"
    },
    name: {
        fontSize: 14,
        color: theme.typography.color.primary,
        fontWeight: "bold",
        width:108
    },
    view: {
        fontSize: 12,
        color: theme.palette.info.main
    },
    card: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid transparent",
        backgroundColor: "transparent",
        padding:4,
        width:"100%"
    },
    selected_card: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #5078E1",
        backgroundColor: "#F1F7FF",
        padding:4,
        width:"100%"
    },
    customDialog: {
        // '& .MuiBackdrop-root': {
        //     backgroundColor: "transparent"
        // },
        // '& .MuiDialog-container': {
        //     position: "absolute",
        //     height: 300,
        //     top: 80,
        //     left: 55,
        //     width:"700px"
        // },
        // '& .MuiDialogContent-root': {
        //     position: "relative",
        //     width: "100%",
        //     padding: "8px 8px 4px"
        // }

        '& .MuiBackdrop-root': {
            backgroundColor: "transparent"
        },
        '& .MuiDialog-container': {
            position: "absolute",
            height: 300,
            top: 100,
            left: 55,
            width: "700px",
        },
        '& .MuiDialogContent-root': {
            position: "relative",
            width: "100%",
            padding: "8px",
            height:130,
        }
    },
    value: {
        fontSize: 14,
        fontWeight: "bold",
        width:110
    },
}))

export const CourtDropdownStyle = makeStyles((theme) => ({
    avatar: {
        height: 25,
        width: 25
    },
    dropdown: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6,
        cursor: "pointer",
        zIndex: 3,
        position: "absolute",
        left: "285px",
        backgroundColor: props => props?.listLength > 0 ? "#fff" : "#f1f1f1",
        top: "130px",
        width:200
    },
    name: {
        fontSize: 14,
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    view: {
        fontSize: 12,
        color: theme.palette.info.main
    },
    court_content: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6,
        cursor: "pointer",
        zIndex: 1,
        position: "absolute",
        left: "20%",
        backgroundColor: "#fff",
        top: "190px",
        width: 850,
        margin: 6,
        padding: 12
    },
    card: {
        padding: "4px",
        cursor: "pointer",
        border: "1px solid #fff",
    },
    selected_card: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #5078E1",
        backgroundColor: "#F1F7FF",
        padding: "4px",
        marginRight: "4px",
        cursor: "pointer"
    },
    customDialog: {
        '& .MuiBackdrop-root': {
            backgroundColor: "transparent"
        },
        '& .MuiDialog-container': {
            position: "absolute",
            height: 300,
            top: 100,
            left: 260,
            width: "700px",
        },
        '& .MuiDialogContent-root': {
            position: "relative",
            width: "100%",
            padding: "8px",
            height:130,
        }
    },
    value: {
        fontSize: 14,
        fontWeight: "bold",
        width:150
    },
    gridContainer:{
        height:200,
        overflow:"overlay"
    }
}))

export const CalendarHeaderStyle = makeStyles((theme) => ({
    header: {
        height: 50,
        padding: 6,
    }
}))


export const AmenityPaymentDetailsStyle = makeStyles((theme) => ({
    name: {
        fontSize: 14,
        fontWeight: "bold",
        color: theme.typography.color.primary,
        width:"150px"
    },
    rightname:{
        fontSize: 14,
        fontWeight: "bold",
        color: theme.typography.color.primary,
        textAlign:"right"
    },
    id: {
        fontSize: 12,
        color: theme.typography.color.secondary
    },
    paid: {
        color: theme.palette.success.main,
        fontSize: 12,
        textAlign: "right"
    },
    unpaid:{
        color: theme.palette.warning.main,
        fontSize: 12,
        textAlign: "right",
        width:"150"
    },
    avatar: {
        height: 48,
        width: 48,
        borderRadius: 4
    },
    title: {
        fontSize: 12,
        color: theme.typography.color.secondary,
        textAlign: "left",
        fontWeight: "bold"
    },
    content: {
        fontSize: 12,
        color: theme.typography.color.secondary,
        textAlign: "right",
        fontWeight: "bold"
    },
    totaltitle: {
        fontSize: 12,
        color: theme.typography.color.primary,
        textAlign: "left",
        fontWeight: "bold"
    },
    totalcontent: {
        fontSize: 12,
        color: theme.typography.color.primary,
        textAlign: "right",
        fontWeight: "bold"
    },
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
    }
}))

export const AmenityDetailsStyle = makeStyles((theme) => ({
    avatar: {
        height: 42,
        width: 42,
        borderRadius: 4
    },
    name: {
        fontSize: 14,
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    content: {
        fontSize: 12,
        color: theme.typography.color.secondary,
        fontWeight: SemiBold
    },
    title: {
        fontSize: 10,
        color: theme.typography.color.tertiary,
        fontWeight: "bold",
        textTransform: "uppercase"
    },
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
    }
}))

export const DeclineQRcodeStyle = makeStyles((theme) => ({
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        width: "96.7%",
        marginLeft: 8
    },
    declinebtn: {
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.error.main,
        borderRadius: 4,
        '&:hover': {
            border: `1px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
            borderRadius: 4,
            backgroundColor: "transparent"
        }
    },
    shareIcon: {
        display: "flex",
        justifyContent: "right"
    },
    qr: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
    },
    cancelled_qr: {
        fontSize: "12px",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
    },
    expire: {
        fontSize: "10px",
        color: theme.typography.color.tertiary,
        fontFamily: Regular,
    },
    qrTime: {
        color: theme.palette.success.main,
        fontSize: 12,
        fontWeight: "bold"
    },
    qrDetail: {
        color: theme.palette.success.main,
        fontSize: 10,
    },
    company_logo: {
        height: 20,
        width: 105
    },
    cancelBtn: {
        height: 48,
        '&:hover': {
            backgroundColor: "#fff"
        }
    }
}))


export const FabButtonStyle = makeStyles((theme) => ({
    fabbtn: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        height: 50,
        width: 50,
        zIndex: 1,
        position: "absolute",
        bottom: "10%",
        right: "3%",
        textAlign: "center",
        color: "#fff",
        '&:hover': {
            width: 160,
            cursor: "pointer",
            '& p': {
                visibility: "visible !important",
                fontSize: 14,
            }
        }
    },
    // content:{
    //     position:"relative",
    //     top:"50%",
    //     left:"50%",
    //     transform:"translate(-50%,-50%)"
    // },
    btntext: {
        visibility: "hidden",
        whiteSpace: "nowrap"
    }
}))


export const InvoiceDetailStyle = makeStyles((theme) => ({
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        height: "calc(100vh - 450px)"
    },
    title: {
        fontSize: 12,
        color: theme.typography.color.secondary,
        fontWeight: SemiBold
    },
    content: {
        fontSize: 12,
        color: theme.typography.color.primary,
        fontWeight: SemiBold,
        float: "right"
    },
    total: {
        fontSize: 12,
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    invoiceBox: {
        height: "calc(100vh - 508px)",
        overflow: "scroll"
    }
}))

export const PaymentCardStyle = makeStyles((theme) => ({
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        cursor: "pointer"
    },
    iconbtn: {
        border: `1px solid ${theme.palette.border.secondary}`,
        height: 24,
        width: 24
    },
    title: {
        fontSize: 14,
        fontWeight: "bold",
        color: theme.typography.color.primary
    },
    content: {
        fontSize: 12,
        color: theme.typography.color.secondary
    }
}))

export const UnitListCardStyle = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32
    },
    title: {
        fontSize: 12,
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    content: {
        fontSize: 12,
        color: theme.typography.color.secondary,
    }
}))

export const CancelBookingStyle = makeStyles((theme) => ({
    header: {
        fontSize: 12,
        color: theme.typography.color.Tertiary,
        fontWeight: "semibold"
    },
    submitBtn: {
        textTransform: "capitalize",
        height: 42,
        borderRadius: 8
    }
}))

export const calendarDropDownStyle = makeStyles((theme) => ({

    dropdown2: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
        zIndex: 1,
        position: "relative",
        backgroundColor: "#fff",
        boxShadow: "0px 0px 16px  #00000014",
        padding: 8,
        width: 120
    },
    dropdown: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
        zIndex: 3,
        position: "absolute",
        backgroundColor: "#fff",
        top: "130px",
        // boxShadow: "0px 0px 16px  #00000014",
        float: "right",
        right: "130px",
        padding: 8,
        width: 120
    },
    customDialogForParking: {
        '& .MuiBackdrop-root': {
            backgroundColor: "transparent"
        },
        '& .MuiDialog-container': {
            position: "absolute",
            height: 300,
            top: 74,
            right: 71,
            width: "200px",

        },
        '& .MuiDialogContent-root': {
            position: "relative",
            width: "120px",
            padding: "8px",
            // boxShadow: "0px 0px 16px  #00000014",
        }
    },
    customDialog: {
        '& .MuiBackdrop-root': {
            backgroundColor: "transparent"
        },
        '& .MuiDialog-container': {
            position: "absolute",
            height: 300,
            top: (props) => props?.right ? 74 : 90,
            right: (props) => props?.right ? 71 : 90,
            width: "200px",

        },
        '& .MuiDialogContent-root': {
            position: "relative",
            width: "120px",
            padding: "8px",
            // boxShadow: "0px 0px 16px  #00000014",
        }
    },
    selected: {
        color: theme.palette.primary.main,
        fontWeight: SemiBold,
        fontSize: 14,
        cursor: "pointer"
    },
    value: {
        fontSize: 14,
        fontWeight: "bold"
    },
    listText: {
        fontSize: 14,
        fontWeight: SemiBold,
        cursor: "pointer"
    }
}))


export const CustomDialogStyle = makeStyles((theme) => ({
    customDialog: {
        '& .MuiBackdrop-root': {
            backgroundColor: "transparent"
        },
        '& .MuiDialog-container': {
            position: "absolute",
            height: 300,
            top: 80,
            left: 55
        },
        '& .MuiDialogContent-root': {
            position: "relative",
            width: "100%",
            padding: "8px 8px 4px"
        }
    }
}))