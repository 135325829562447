import React from "react";
import { withNavBars } from "../../HOCs";
import { ScheduleBoard } from "./scheduleBoard";

class ScheduleBoardParent extends React.Component {
    render() {
        return <ScheduleBoard {...this.props} />
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ScheduleBoardParent, props);