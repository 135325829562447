import { Switch, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    Label: {
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
        fontSize: "14px",
        marginBottom: "5px",
    },
}))
export const CustomCheckBox = ({ label = null, onChange = () => false, checked = false, isrequired = false }) => {
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#5AC782',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#EEF9EE',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: theme.transitions.create(['width'], {
                duration: 200,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
            boxSizing: 'border-box',
        },
    }));
    const classes = useStyles()
    return (
        <>
            {
                label &&
                <Typography className={classes.Label} noWrap>
                    {label}
                    {isrequired && (
                        <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                            *
                        </Typography>
                    )}
                </Typography>
            }

            <AntSwitch
                onChange={onChange}
                checked={checked}
                label={label}
            />
        </>
    )
}