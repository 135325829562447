import { Checkbox, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Select, { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";
import { SemiBold } from "../../utils";

const useStyles = makeStyles((theme) => ({
  createBtn: {
    display: "inline-flex",
    cursor: "pointer",
  },

  graphSelect: {
    "& .css-6laie6-control": {},
  },
  selects: {
    "& .css-1rhbuit-multiValue": {
      backgroundColor: "#DBEDFE",
    },
    '& .css-1byr645-control': {
      minHeight: 47
    }

    // "& .css-6laie6-control": {
    //   height: "auto",
    // },
    // "& .css-66l8ul-control": {
    //   height: "auto",
    // },
  },
  Label: {
    color: theme.typography.color.tertiary,
    fontSize: "12px",
    marginBottom: "5px",
    fontFamily: SemiBold
  },

  container: {
    minWidth: "fit-content",
    color: "#4E9F3D",
  },
}));

const customStyles = {
  control: (base) => ({
    ...base,
    borderRadius: 4,
    height: 'auto',
    minHeight: 50,
    fontSize: "16px",
    border: "1.5px solid #E2E2E2",
    boxShadow: "none",
    "&:hover": {
      border: "1.5px solid #E2E2E2",
    },
  }),
};

const selectStyles = {
  control: (base) => ({
    ...base,
    borderRadius: 4,
    fontSize: "16px",
    fontFamily: "adda_ccc_regular",
    border: "1.5px solid #E2E2E2",
    boxShadow: "none",
    "&:hover": {
      border: "1.5px solid #E2E2E2",
    },
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "white" : "",
    color: state.isSelected ? "black" : "",
  }),
};
const graphStyles = {
  control: (base) => ({
    ...base,
    fontSize: 12,
    borderRadius: 4,
    height: "auto",
    minHeight: 50,
    boxShadow: "none",
    border: "1px solid #4E9F3D",
    "&:hover": {
      border: "1px solid #4E9F3D",
    },
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "white" : "",
    color: state.isSelected ? "black" : "",
  }),
};

export const SelectBoxWithselectAll = ({
  label = "",
  isRequired = false,
  isPaginate = false,
  isCheckbox = false,
  createSelect = false,
  disable = false,
  value = "",
  placeholder = "",
  options = [],
  loading = false,
  loadOptions = () => null,
  onChange = () => null,
  isError = false,
  errorMessage = "",
  closeMenuOnSelect = true,
  hideSelectedOptions = true,
  allowSelectAll = false,
  isMulti = false,
  isCheckboxGraph = false,
  selectValue,
  customChecked
}) => {
  const classes = useStyles();

  // On Selected Design
  const MoreSelectedBadge = ({ items }) => {
    const style = {
      padding: "3px",
      color: "#4E9F3D",
    };

    var valueLabel = "";
    if (items.some((val) => val === "ALL Properties")) {
      valueLabel += `All Properties`;
    } else if (items.length === 1) valueLabel += ` ${items.length} Property`;
    else if (items.length > 1) valueLabel += ` ${items.length} Properties`;

    return <div style={style}>{valueLabel}</div>;
  };

  // Multi Value with Multi Selection
  const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 0;
    const overflow = getValue()
      .slice(maxToShow)
      .map((x) => x.label);
    return index < maxToShow ? (
      ""
    ) : // <components.ValueContainer {...props} className={classes.container}/>
      index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
      ) : null;
  };

  //  Normal Option with Checkbox
  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <Checkbox
          checked={customChecked ? customChecked(props) : props.isSelected}
          onChange={() => null}
          sx={{ "& .MuiSvgIcon-root": {} }}
        />{" "}
        <label>{children}</label>
      </components.Option>
    );
  };

  // Common Option
  const CommonOption = ({ children, ...props }) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = Object.assign(props, { innerProps: rest });
    return <components.Option {...newProps}>{children}</components.Option>;
  };

  return (
    <div className={classes.root}>
      <div style={{ display: "flex" }}>
        {
          <Typography
            variant="body1"
            color="textsecondary"
            style={{ fontSize: "12px", color: '#98A0A' }}
            align="left"
            gutterBottom
            className={classes.Label}
          >
            {label}
          </Typography>
        }
        {isRequired && (
          <Typography color="error" variant="caption">
            &nbsp;*
          </Typography>
        )}
      </div>

      {isPaginate ? (
        <AsyncPaginate
          isClearable
          isSearchable
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          placeholder={placeholder}
          loadOptions={loadOptions}
          onChange={onChange}
          options={options}
          isLoading={loading}
          defaultOptions={options}
          styles={customStyles}
        />
      ) : createSelect ? (
        <CreatableSelect
          isClearable={false}
          options={options}
          isLoading={loading}
          placeholder={placeholder}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          onChange={onChange}
          styles={customStyles}
        />
      ) : isCheckbox ? (
        <>
          <Select
            isClearable={false}
            isSearchable
            components={{ Option }}
            selectProps={selectValue}
            value={value}
            size="small"
            fullWidth
            placeholder={placeholder}
            options={options}
            isLoading={loading}
            onChange={onChange}
            styles={selectStyles}
            className={classes.selects}
            isMulti={isMulti}
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={hideSelectedOptions}
            allowSelectAll={allowSelectAll}
          />

        </>
      ) : isCheckboxGraph ? (
        <Select
          isClearable={false}
          isSearchable
          components={{
            Option,
            MultiValue,
          }}
          value={value}
          placeholder={placeholder}
          options={options}
          isLoading={loading}
          onChange={onChange}
          styles={graphStyles}
          className={classes.graphSelect}
          isMulti={isMulti}
          size='small'
          closeMenuOnSelect={closeMenuOnSelect}
          hideSelectedOptions={hideSelectedOptions}
        />
      ) : (
        <Select
          isClearable={false}
          components={{
            CommonOption,
          }}
          value={value}
          placeholder={placeholder}
          options={options}
          isLoading={loading}
          onChange={onChange}
          styles={customStyles}
          className={classes.selects}
          isMulti={isMulti}
          isDisabled={disable}
        />
      )}
      {isError && (
        <Typography variant={"caption"} color={"error"}>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
