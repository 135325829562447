import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const InvoiceViewStyles = makeStyles((theme) => ({
    img: {
        borderRadius: theme.palette.borderRadius,
        objectFit: "cover",
        height: "54px",
        width: "54px",

    },
    btn: {
        padding: "12px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 6px 10px #00000014"

    },
    card: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#EEF2FC",
        padding: "12px",
    },
    recBox: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#85A1EA",
        padding: "12px",
        textAlign: "center"

    },
    textSmall: {
        fontSize: "9px",
        fontFamily: SemiBold,
        color: "#98A0AC"
    },
    textRight: {
        fontSize: "14px",
        fontFamily: SemiBold,
        color: "#091B29",
        marginBottom: "4px"
    },
    textBottom: {
        fontSize: "10px",
        color: "#98A0AC",
        // padding: "8px 36px"
    },
    textLeft: {
        fontSize: "14px",
        fontFamily: SemiBold,
        color: "#98A0AC",
        padding: "8px 16px"
    },
    text2: {
        fontSize: "12px",
        fontFamily: Bold,
        color: "#FF4B4B",
        backgroundColor: "#FFECEC",
        padding: "4px",
        borderRadius: theme.palette.borderRadius,


    },
    text1: {
        fontSize: "16px",
        fontFamily: Bold,
        color: "#091B29",
        // marginLeft: "12px",
        // marginBottom: "4px"

    },
    text3: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: "#4E5A6B",

    },
    text33: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: "#78B1FE",

    },
    text333: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: "#091B29",

    },
    text4: {
        fontSize: "10px",
        color: "#98A0AC",

    },
    span: {
        backgroundColor: "#4E5A6B",
        borderRadius: "5px",
        color: "white",
        padding: "2px",
        fontSize: "12px",
        marginLeft: "6px"
    },
    periodStyle: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    hour: {
        backgroundColor: "#F5F7FA",
        padding: "12px 8px 8px 8px",
        borderRadius: theme.palette.borderRadius,
        fontSize: "14px",
        fontFamily: SemiBold,
        minHeight: 45,
        overflow: "auto"
    },
    TextField: {
        padding: "0px 14px",
    },
    unselect: {
        padding: "10px",
        textAlign: "center",
        borderRadius: theme.palette.borderRadius,
        color: "white",
        fontSize: "14px",
        backgroundColor: "#5078E1",
        cursor: "pointer",
    },
    select: {
        padding: "10px",
        textAlign: "center",
        borderRadius: theme.palette.borderRadius,
        fontSize: "14px",
        cursor: "pointer",
        border: "1px solid #E4E8EE",
        backgroundColor: "white"
    },
    div: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        padding: "12px",

    },
    box: {
        display: "flex", justifyContent: "space-between", padding: "6px",

    },
    box1: {
        display: "flex", justifyContent: "space-between", backgroundColor: "#F5F7FA", padding: "6px",
        borderRadius: theme.palette.borderRadius,

    },
    box2: {
        display: "flex", justifyContent: "space-between", backgroundColor: "#F1F7FF", padding: "6px",
        borderRadius: theme.palette.borderRadius,
        color: "red"

    },
    dropdownBox: {
        display: "flex", justifyContent: "space-between", padding: "6px"
    },
    doc: {
        height: "calc(100vh - 180px)",
        boxShadow: "0px 3px 16px #00000029",
        margin: "0px 2%",
        overflow: "auto",
        border: `1px solid ${theme.palette.border.secondary}`,
        background: "#fff",
        width: "100%"
    },
    tabledoc: {
        width: "100%", overflow: "hidden",
        border: `1px solid ${theme.palette.border.secondary}`,
        background: "#fff",
        height: "calc(100vh - 180px)"
    },
    left_content: {
        height: `calc( 100vh - 230px )`,
        overflow: "auto"
    }
}));