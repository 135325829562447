import { IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import React from "react";
const useStyles = makeStyles((theme) => ({

    cancelbtn: {
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
        position: "absolute",
        top: "10px",
        right: "22px",
        boxShadow: "none"
    },
}));
export const Residential = (props) => {
    const classes = useStyles()
    const headingReturn = (imageX, imageY, headingX, headingY) => {
        doc.setDrawColor("#E4E8EE");
        doc.setFillColor(242, 244, 247);
        doc.rect(0, 0, 1000, 25, 'FD');
        doc.setFontSize(14);
        doc.addImage("https://protomate.objectstore.e2enetworks.net/1650353547715-DNT%20Logo%20White-04.png", "PNG", imageX, imageY, null, null);
        doc.setFont("helvetica", "bold");
        doc.setTextColor("#091B29");
        doc.text("Dynamic Netsoft Technologies FZC", headingX, headingY, null, null, "right");
        doc.setTextColor("#98A0AC");
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text("sales@dnetsoft.com, +966 50 2345789", headingX, (headingY + 8), null, null, "right");
    }
    const getFooter = () => {
        doc.rect(8, 280, 195, 0.1, 'FD');
        doc.setTextColor("#98A0AC");
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text("Sharjah, United Arab Emirates, SAIF Zone, P O Box 123695 | VAT # 100294865900003", 24, 290);
    }
    const doc = new jsPDF();
    headingReturn(8, 6, 200, 10)

    // ----------------------->
    doc.setTextColor("#091B29");
    doc.setFontSize(20);
    doc.setFont("helvetica", "bold");
    doc.text("QUOTATION", 8, 40);
    // ----------------------->
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 48, 195, 8, 'FD');
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("QUOTATION DETAILS", 10, 53);

    //---------------->Quote Number
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Quote Number", 8, 65);
    //---------------->Quote Number Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("20220117-1050-W9K9", 200, 65, null, null, "right");
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 70, 195, 0.1, 'FD');
    //---------------->Quote date
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Quote date", 8, 78);
    //---------------->Quote date Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("17/01/2022", 200, 78, null, null, "right");
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 83, 195, 0.1, 'FD');
    //---------------->Quote Valid Till
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Quote Valid Till", 8, 91);
    //---------------->Quote Valid Till Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("17/01/2022", 200, 91, null, null, "right");
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 96, 195, 0.1, 'FD');
    //---------------->Lease Period / Duration
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Lease Period / Duration", 8, 104);
    //---------------->Lease Period / Duration Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Yearly / 1", 200, 104, null, null, "right");
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 110, 195, 0.1, 'FD');
    //---------------->Lease Start & End Date
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Lease Start & End Date", 8, 118);
    //---------------->Lease Start & End Date Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("1-Jan-2022 to 1-Jan-2023", 200, 118, null, null, "right");
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 124, 195, 0.1, 'FD');
    //---------------->Grace Period
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Grace Period", 8, 132);
    //---------------->Grace Period Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("30", 200, 132, null, null, "right");
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 138, 195, 0.1, 'FD');
    //---------------->Rent Start Date
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Rent Start Date", 8, 146);
    //---------------->Rent Start Date Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("1-Jan-2022", 200, 146, null, null, "right");
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 152, 195, 0.1, 'FD');
    //---------------->Billing Period
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Billing Period", 8, 161);
    //---------------->Billing Period Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Quarterly", 200, 161, null, null, "right");
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 167, 195, 0.1, 'FD');
    // ----------------------->CUSTOMER DETAILS Heading
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 174, 97, 8, 'FD');
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("CUSTOMER DETAILS", 10, 179);
    // ----------------------->AGENT DETAILS Heading
    doc.setFillColor(228, 232, 238);
    doc.rect(110, 174, 95, 8, 'FD');
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("AGENT DETAILS", 112, 179);


    //------------- tabel section
    //customer Name

    //---------------->Customer Name
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Customer Name", 8, 192);
    //---------------->Customer Name Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Mr. Aiman Amer", 105, 192, null, null, "right");
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 197, 97, 0.1, 'FD');

    //---------------->Agent Name
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Agent Name", 114, 191);
    //---------------->Agent Name Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Mr. Ibrahim Jaleel", 204, 191, null, null, "right");
    doc.setFillColor(228, 232, 238);
    doc.rect(112, 197, 94, 0.1, 'FD');
    //============================================
    //---------------->Customer ID
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Customer ID", 8, 204);
    //---------------->Customer ID Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("1054892301", 105, 204, null, null, "right");
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 209, 97, 0.1, 'FD');

    //---------------->Agent ID
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Agent ID", 114, 204);
    //---------------->Agent ID Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("10254268", 204, 204, null, null, "right");
    doc.setFillColor(228, 232, 238);
    doc.rect(112, 209, 94, 0.1, 'FD');
    //============================================3
    //---------------->Address
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Address", 8, 217);
    //---------------->Address Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Shaarait General Trading", 105, 217, null, null, "right");
    doc.text(" Company W.L.L Qibla Block 9", 105, 222, null, null, "right");
    doc.text("Abdulaziz El-Sakr St ,", 105, 227, null, null, "right");
    doc.text("Mohamed, Kuwait", 105, 232, null, null, "right");
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 236, 97, 0.1, 'FD');

    //---------------->Contact
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Contact", 114, 217);
    //---------------->Contact Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("+966 5042178943 ,", 204, 217, null, null, "right");
    doc.text("aiman@sharrait.com.sa", 204, 222, null, null, "right");
    doc.setFillColor(228, 232, 238);
    //============================================3
    //---------------->Contact customer
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Contact", 8, 244);
    //---------------->Contact customer Value
    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("+966 5042178943 ,", 105, 244, null, null, "right");
    doc.text("aiman@sharrait.com.sa", 105, 249, null, null, "right");
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 254, 97, 0.1, 'FD');
    doc.rect(112, 254, 92, 0.1, 'FD');
    //====================>page 1 of 3
    doc.setTextColor("#98A0AC");
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("Page 1 of 3", 8, 270);

    getFooter()
    doc.addPage()
    // -----------------------> 2 page
    doc.setDrawColor("#E4E8EE");
    doc.setFillColor(242, 244, 247);
    doc.rect(0, 0, 1000, 25, 'FD');
    //------------> 2 page heading
    headingReturn(8, 6, 200, 10)
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 33, 195, 8, 'FD');
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("UNIT DETAILS", 10, 38);



    doc.autoTable(

        {
            margin: { top: 41, right: 7, bottom: 0, left: 8 },
            tableLineColor: [245, 247, 250],
            columnStyles: {
                0: { fillColor: [255, 255, 255], textColor: "#091B29", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250], fontStyle: "bold" },
                1: { fillColor: [255, 255, 255], textColor: "#4E5A6B", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250] },
                2: { fillColor: [255, 255, 255], textColor: "#4E5A6B", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250] },
                3: { fillColor: [255, 255, 255], textColor: "#4E5A6B", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250] },
                4: { fillColor: [255, 255, 255], textColor: "#091B29", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250], fontStyle: "bold" },
                5: { fillColor: [255, 255, 255], textColor: "#091B29", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250], fontStyle: "bold" },
                6: { fillColor: [255, 255, 255], textColor: "#091B29", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250], fontStyle: "bold" },
                // etc
            },
            headStyles: { fillColor: [245, 247, 250], textColor: "#091B29", minCellHeight: 14, valign: "middle" },
            columns: props.heading.map((col) => ({
                ...col,
                dataKey: col.field
            })),
            bodyStyles: { lineColor: [245, 247, 250] },
            body: props.rows,
            theme: "striped",
            styles: {
                fontSize: 10,
                marginTop: 100


            }
        });

    // -----------------------> 3 page
    //alert(doc.lastAutoTable.finalY + 30)
    doc.setFillColor(228, 232, 238);
    doc.rect(8, (doc.lastAutoTable.finalY + 10), 195, 8, 'FD');
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("RENT BREAK-UP DETAILS", 10, (doc.lastAutoTable.finalY + 15));
    doc.autoTable(

        {
            margin: { top: 41, right: 7, bottom: 0, left: 8 },
            startY: doc.lastAutoTable.finalY + 18,
            tableLineColor: [245, 247, 250],
            columnStyles: {
                0: { fillColor: [255, 255, 255], textColor: "#091B29", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250], fontStyle: "bold" },
                1: { fillColor: [255, 255, 255], textColor: "#4E5A6B", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250] },
                2: { fillColor: [255, 255, 255], textColor: "#4E5A6B", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250] },
                3: { fillColor: [255, 255, 255], textColor: "#4E5A6B", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250] },
                4: { fillColor: [255, 255, 255], textColor: "#091B29", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250], fontStyle: "bold" },
                5: { fillColor: [255, 255, 255], textColor: "#091B29", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250], fontStyle: "bold" },
                6: { fillColor: [255, 255, 255], textColor: "#091B29", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250], fontStyle: "bold" },
                7: { fillColor: [255, 255, 255], textColor: "#091B29", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250], fontStyle: "bold" },
                // etc
            },
            headStyles: { fillColor: [245, 247, 250], textColor: "#091B29", minCellHeight: 14, valign: "middle" },
            columns: props.breakupheading.map((col) => ({
                ...col,
                dataKey: col.field
            })),
            bodyStyles: { lineColor: [245, 247, 250] },
            body: props.breakuprowrows,
            theme: "striped",
            styles: {
                fontSize: 10,
                marginTop: 180


            }
        });
    doc.autoTable(

        {
            margin: { top: 41, right: 7, bottom: 0, left: 130 },
            startY: doc.lastAutoTable.finalY,
            tableLineColor: [245, 247, 250],
            footStyles: {
                fillColor: [228, 232, 238], textColor: "#091B29", minCellHeight: 14, halign: "center", valign: "middle"
            },
            columnStyles: {
                0: { fillColor: [245, 247, 250], textColor: "#091B29", minCellHeight: 14, valign: "middle", halign: "center", lineColor: [245, 247, 250], fontStyle: "normal" },
                1: { fillColor: [245, 247, 250], textColor: "#091B29", minCellHeight: 14, valign: "middle", halign: "center", lineColor: [245, 247, 250], fontStyle: "bold" },
                2: { fillColor: [228, 232, 238], textColor: "#4E5A6B", minCellHeight: 14, valign: "middle", halign: "center", lineColor: [245, 247, 250], fontStyle: "bold" },

            },
            foot: [{
                title: "Quote Total",
                value: "10,850.00"
            },],
            headStyles: { fillColor: [245, 247, 250], textColor: "#091B29", minCellHeight: 14, valign: "middle", halign: "center" },
            // columns: props.breakupheading.map((col) => ({
            //     ...col,
            //     dataKey: col.field
            // })),
            bodyStyles: { lineColor: [245, 247, 250] },
            body: props.breakupTotal,
            theme: "striped",
            styles: {
                fontSize: 10,
                marginTop: 180


            },

        });
    getFooter()
    doc.addPage()
    headingReturn(8, 6, 200, 10)
    doc.setFillColor(228, 232, 238);
    doc.rect(8, 33, 195, 8, 'FD');
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("PAYMENT SCHEDULE", 10, 38);
    doc.autoTable(

        {
            margin: { top: 40, right: 7, bottom: 0, left: 8 },
            startY: 40,
            tableLineColor: [245, 247, 250],
            columnStyles: {
                0: { fillColor: [255, 255, 255], textColor: "#091B29", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250], fontStyle: "bold" },
                1: { fillColor: [255, 255, 255], textColor: "#4E5A6B", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250] },
                2: { fillColor: [255, 255, 255], textColor: "#4E5A6B", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250] },
                3: { fillColor: [255, 255, 255], textColor: "#4E5A6B", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250] },
                4: { fillColor: [255, 255, 255], textColor: "#091B29", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250], fontStyle: "bold" },
                5: { fillColor: [255, 255, 255], textColor: "#091B29", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250], fontStyle: "bold" },
                6: { fillColor: [255, 255, 255], textColor: "#091B29", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250], fontStyle: "bold" },
                7: { fillColor: [255, 255, 255], textColor: "#091B29", minCellHeight: 14, valign: "middle", lineColor: [245, 247, 250], fontStyle: "bold" },
                // etc
            },
            headStyles: { fillColor: [245, 247, 250], textColor: "#091B29", minCellHeight: 14, valign: "middle" },
            columns: props.breakupheading.map((col) => ({
                ...col,
                dataKey: col.field
            })),
            bodyStyles: { lineColor: [245, 247, 250] },
            body: props.breakuprowrows,
            theme: "striped",
            styles: {
                fontSize: 10,
                marginTop: 180


            }
        });
    getFooter()
    const download = () => {
        doc.save("QUOTATION.pdf")
    }
    // var string = doc.output('datauristring');

    return (
        <div>
            <div style={{ position: "relative" }}>
                <IconButton className={classes.cancelbtn} variant="contained" onClick={download}>
                    <img src="/images/download.svg" alt="" />

                </IconButton>

            </div>
        </div>
    )
}