import HomeIcon from '@mui/icons-material/Home';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import BackgroundType from "../../../assets/leaseBackground";
import MobileIcon from "../../../assets/mobileIcon";
import QuotationLeaseIcon from "../../../assets/quotationLeaseIcon";
import SourceTypeIcon from "../../../assets/sourceIcon";
import { SourceMenuOption } from "../../../components";
import { InterestedUnitsCard } from './interestedUnitsCard';
import { useStyles } from "./styles";

export const LeadDetailsCard = ({ data = {}, hideDetails = false, source_enum = [], dataNew=[],setDataNew=()=>false,isReadOnly=false}) => {
    const classes = useStyles()


    return (
        <Box>
            <Box className={classes.detailRoot}>
                <Stack direction="row" spacing={1.5}>
                    <div>
                        <img className={classes.userImg} src={data?.image ?? "images/profile_image.png"} alt="" />
                    </div>
                    <div>
                        <Typography className={classes.leadName}>{data?.name}</Typography>
                    </div>
                </Stack>
                <Box className={classes.typeBg}>
                    <BackgroundType color={data?.type === "Lease" ? "#6d6db3" : data?.type === "Sale" ? "#f4a261" : "#690375"} />
                    <Box className={classes.centeredText} display={"flex"}>
                        <QuotationLeaseIcon />
                        <Typography className={classes.type}>{data?.type}</Typography>

                    </Box>
                </Box>
            </Box>
            <Stack direction={"row"} spacing={1}>
                <MobileIcon />
                <Typography className={classes.mobileNo}>{data?.mobile}</Typography>
            </Stack>
            <Stack direction={"row"} spacing={1} mt={1.5}>
                <MailOutlinedIcon className={classes.mailIcon} />
                <Typography className={classes.mobileNo}>{data?.email}</Typography>
            </Stack>
            <Grid container mt={2.5}>
                <Grid item xs={1}>
                    <Box className={classes.sourceTypeIcon}>
                        <SourceTypeIcon />
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.srcText}>Source</Typography>
                </Grid>
                <Grid item xs={7}>
                    <SourceMenuOption
                       value={dataNew?.source?.value ?? ""}
                        onChange={(value) => setDataNew({...dataNew,source:value})}
                        option={source_enum ?? []}
                        isReadOnly={isReadOnly}
                    />
                </Grid>
            </Grid>
            <Grid container mt={2}>
                <Grid item xs={1}>
                    <HomeIcon className={classes.homeIcon} />
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.typeText}>Type</Typography>
                </Grid>
                <Grid item xs={7}>
                    <Box display={"flex"} justifyContent={"space-between"} className={classes.purposebox}>
                        <Typography className={classes.typeText}>{data.purpose}</Typography>
                        <KeyboardArrowDownIcon className={classes.homeIcon} />
                    </Box>
                </Grid>
            </Grid>
            <Box mt={2}>
                <InterestedUnitsCard list={data?.units} />
            </Box>
        </Box>
    )
}