export const JobsPath = ["reference_no", "item", "name", "is_active"]

export const JobsType = [
    { type: ["text"], name: "reference_no" },
    { type: ["text"], name: "item" },
    { type: ["text"], name: "name" },
    { type: ["status"], name: "is_active" },
    { type: ["more_4"], icon: "icon" },
]

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]

export const ItemOptionList = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
]

export const initialState = {
    name: "",
    item: false,
    item_type:"",
    status: true,
    isEdit: false,
    id: null,
    error: {

        name: "",
        item_type: "",
        item: "",
        status: "",
    }
}


export const getInspectionMasterHeading = (t) => {

    const heading = [
        { title: t("itemId"), field: "reference_no" },
        { title: t("itemType"), field: "item" },
        { title: t("itemName"), field: "name" },
        // { title: t("inventoryItems"), field: "is_inventory" },
        { title: t("status"), field: "is_active", },
        { title: "", field: "", },
    ]

    return heading
}