import { makeStyles } from "@mui/styles";

export const weeklyCalendarStyle = makeStyles((theme) => ({
    cells: {
        display: "flex",
        justifyContent: "center",
        height: 80,
        border: `1px solid ${theme.palette.border.secondary}`,
        alignItems: "center",
    },
    hovercells: {
        display: "flex",
        justifyContent: "center",
        height: 80,
        border: `1px solid ${theme.palette.border.secondary}`,
    },
    dayLabel: {
        fontSize: 12,
        textTransform: "uppercase",
        color: theme.typography.color.tertiary
    },
    dateLabel: {
        fontSize: 16,
        fontWeight: "bold",
        textTransform: "uppercase",
        color: theme.typography.color.secondary
    },
    listTitle: {
        color: theme.typography.color.tertiary,
        fontSize: 16,
    },
    calender: {
        zIndex: "0"
    },
    calenderRows: {
        height: `calc(100vh - 320px)`,
        overflow: "auto",
        width: "100%",
    },
    cell_hover: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.main,
        height: 80,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        cursor: "pointer"
    },
    header_timecell: {
        height: 40,
        fontSize: 12,
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        alignItems: 'end',
        fontSize: 12,
        color: theme.typography.color.tertiary,
    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        height: 100,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%"
    },
    event_label: {
        padding: 2,
        fontSize: 12,
        fontWeight: "bold"
    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
    },
    calRow: {
        height: "calc(100vh - 250px)",
        overflow: "auto",
        width: "100%",
    },
    moreLabel: {
        fontSize: 12,
        color: theme.typography.color.primary,
        fontWeight: "bold",
        marginLeft: 8,
        cursor: "pointer"
    },
    customTooltip: {
        // '& .MuiTooltip-arrow':{
        //     backgroundColor:"#fff",
        //     color:"#fff",
        //     border: '1px solid #dadde9'
        // }
    },
    ttavatar: {
        height: 24,
        width: 24,
    },
    ttTitle: {
        fontSize: 18,
        fontWeight: "bold",
        color: theme.typography.color.primary
    },
    ttsecTitle: {
        fontSize: 14,
        color: theme.typography.color.secondary,
    },
    ttassigned_by: {
        fontSize: 12,
        color: theme.typography.color.tertiary
    },
    ttname: {
        fontSize: 16,
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    }
}))

export const eventListStyle = makeStyles((theme) => ({
    header_timecell: {
        height: 40,
        fontSize: 12,
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        alignItems: 'end',
        fontSize: 12,
        color: theme.typography.color.tertiary,
    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        height: 100,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%"
    },
    event_label: {
        padding: 2,
        fontSize: 12,
        fontWeight: "bold"
    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
    },
    calRow: {
        height: "calc(100vh - 250px)",
        overflow: "auto",
        width: "100%",
    },
    moreLabel: {
        fontSize: 12,
        color: theme.palette.info.main,
        fontWeight: "bold",
        marginLeft: 8,
        cursor: "pointer"
    },
    customTooltip: {
        // '& .MuiTooltip-arrow':{
        //     backgroundColor:"#fff",
        //     color:"#fff",
        //     border: '1px solid #dadde9'
        // }
    },
    ttavatar: {
        height: 24,
        width: 24,
    },
    ttTitle: {
        fontSize: 18,
        fontWeight: "bold",
        color: theme.typography.color.primary
    },
    ttsecTitle: {
        fontSize: 14,
        color: theme.typography.color.secondary,
    },
    ttassigned_by: {
        fontSize: 12,
        color: theme.typography.color.tertiary
    },
    ttname: {
        fontSize: 16,
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    }
}))