import { Box, Grid, Typography } from "@mui/material";
import { SelectBox, TextBox, ToggleButtonComponent } from "../../../components";
import { useStyles } from "../styles";
export const BankDetails = ({ data = {}, updateState = {}, enumValue = {}, loadOptions = () => false, loading = false }) => {
    const classes = useStyles()

    return (
        <Box marginTop="16px" className={classes.box}>
            <Typography className={classes.title}>Banking Basic Details</Typography>
            <Grid container rowSpacing={1} columnSpacing={3}>
                <Grid item xs={12} sm={4} md={3} lg={2.7}>
                    <Typography className={classes.label}>Bank Account Type
                    </Typography>
                    <ToggleButtonComponent
                        options={enumValue?.bank_account_type}
                        value={data?.account_type}
                        onChange={(value) => updateState("account_type", value)}

                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <TextBox
                        label="Account Number"
                        placeholder="Enter Account Number"
                        value={data?.account_no}
                        onChange={(e) => updateState("account_no", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <TextBox
                        label="Bank Name"
                        placeholder="Enter Bank Name"
                        value={data?.bank_name}
                        onChange={(e) => updateState("bank_name", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <TextBox
                        label="Branch Name"
                        placeholder="Enter Branch Name"
                        value={data?.branch_name}
                        onChange={(e) => updateState("branch_name", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <SelectBox
                        label="Currency"
                        placeholder="Select Currency"
                        value={data?.bank_currency_id}
                        onChange={(value) => updateState("bank_currency_id", value)}
                        isPaginate
                        loadOptions={(search, array) => loadOptions(search, array, 'currency')}
                        loading={loading === "currency"}
                        debounceTimeout={800}

                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={2.7}>
                    <SelectBox
                        label="Bank Routing Type"
                        placeholder="Select Bank Routing Type"
                        value={data?.bank_route_type}
                        options={enumValue?.bank_routing_type}
                        onChange={(e) => updateState("bank_route_type", e)}
                        menuPlacement={"top"}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <TextBox
                        label="Bank Routing Code"
                        placeholder="Enter Bank Routing Code"
                        value={data?.bank_route_code}
                        onChange={(e) => updateState("bank_route_code", e.target.value)}

                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <TextBox
                        label="Address Line 1"
                        placeholder="Select Address Line 1"
                        value={data?.bank_addrees1}
                        onChange={(e) => updateState("bank_addrees1", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <TextBox
                        label="Address Line 2"
                        placeholder="Enter Address Line 2"
                        value={data?.bank_addrees2}
                        onChange={(e) => updateState("bank_addrees2", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <TextBox
                        label="City"
                        placeholder="Enter City"
                        value={data?.bank_city}
                        onChange={(e) => updateState("bank_city", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2.7}>
                    <SelectBox
                        label="Country"
                        placeholder="Select Country"
                        value={data?.bank_country}
                        onChange={(value) => updateState("bank_country", value)}
                        isPaginate
                        loadOptions={(search, array) => loadOptions(search, array, 'country')}
                        loading={loading === "country"}
                        debounceTimeout={800}
                        menuPlacement={"top"}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <TextBox
                        label="Preferred Cash Collection Office"
                        placeholder="Enter Preferred Cash Collection Office"
                        value={data?.collection_office ?? ""}
                        onChange={(e) => updateState("collection_office", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <TextBox
                        label="Cheque Payment beneficiary Name"
                        placeholder="Enter Cheque Name"
                        value={data?.cheque_name ?? ""}
                        onChange={(e) => updateState("cheque_name", e.target.value)}
                    />
                </Grid>
            </Grid>

        </Box>

    )
}