import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useStyles } from "./style";

export const Slider = (props) => {
    const classes = useStyles(props);
    const [selected, setSelected] = React.useState(0)

    const next = () => {
        if ((props?.assets?.length - 1) > selected) {
            setSelected(selected + 1)
        }
    }

    const previous = () => {
        if (selected !== 0) {
            setSelected(selected - 1)
        }
    }

    React.useEffect(() => {
        setSelected(0)
    }, [props?.assets])

    return (
        <div className={classes.caroselRoot}>
            {/* carosel */}
            <Grid container alignItems="center" spacing={3}>
                <Grid item xs={1} justifySelf="flex-end">

                    <IconButton sx={{ float: "right" }} disabled={selected === 0} onClick={previous} size="small" className={classes.arrowBtn}>
                        <ArrowBackIosIcon sx={{ fontSize: "12px" }} />
                    </IconButton>


                </Grid>
                <Grid item xs={10}>
                    {props?.assets[selected] &&
                        < Box >
                            {
                                props?.assets[selected]?.file_meta?.file_type === "mp4" ? <video
                                    height="500px"
                                    width="100%"
                                    style={{ borderRadius: "4px !important", objectFit: "contain" }}
                                    controls
                                >
                                    <source src={props?.imageOnly ? props?.assets[selected] : props?.assets[selected]?.url} type="video/mp4" />
                                </video> : <img
                                    src={props?.imageOnly ? props?.assets[selected] : props?.assets[selected]?.url}
                                    alt=""
                                    height="500px"
                                    width="100%"
                                    style={{ borderRadius: "4px !important", objectFit: "contain" }}
                                />
                            }

                        </Box>
                    }
                </Grid>
                <Grid item xs={1}>

                    <IconButton disabled={(props?.assets?.length - 1) > selected ? false : true} onClick={next} size="small" className={classes.arrowBtn}>
                        <ArrowForwardIosIcon sx={{ fontSize: "12px" }} />
                    </IconButton>


                </Grid>
            </Grid>
        </div>
    );
};
