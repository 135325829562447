import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import moment from "moment-timezone";
import React, { useContext, useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Subheader, UseDebounce } from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { MANAGE_INVOICE_LIST } from "../../graphql/queries";
import {
  AlertProps, getCompanyOption,
  getRoutePermission, getToday, LocalStorageKeys, enumSelect, enum_types
} from "../../utils";
import { BottomCard } from "./bottomCard";
import { managrmentStyles } from "./style";
import { TableComponent } from "./tableComponent";

const ManageInvoice = ({ t }) => {
  // useRef
  const permission = useRef([]);
  const classes = managrmentStyles();
  //   context
  const auth = useContext(AuthContext);
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  // apolo clint
  const client = useApolloClient();
  //debounce hook
  const debounce = UseDebounce();
  // ----------------------------------------STATES----------------------------------------
  const [company, setCompany] = useState({
    companyList: [],
    SelectedCompany: {},
    searchText: "",
    drawer: false,
    typeSelect: {
      value: "All",
      labe: "All"
    }
  });
  const [list, setList] = useState({
    data: [],
    count: 0,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [date, setDate] = useState({
    from: getToday(),
    to: getToday(),
  });
  const [selected, setSelected] = useState(false);
  const [enumValue, setValue] = useState({
    type: [],
  });
  const [selectType, setSelectType] = useState("")
  //   --------------------------------------FUNCTIONS--------------------------------------
  //   initial company update
  const updateState = (k, v) => {
    setCompany({ ...company, [k]: v });
  };
  // date change
  const dateChange = (value) => {
    setDate(value);
    GetList(company?.selected, company?.searchText, 0, limit, value);
  };
  //   company selection
  const companySelect = (e) => {
    updateState("SelectedCompany", e);
    GetList(e, "", 0, 10, date);
  };
  // search
  const searchFunction = (value) => {
    updateState("searchText", value);
    debounce(() => GetList(company?.SelectedCompany, value, 0, limit, date, selectType), 800);
  };
  // pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    GetList(company?.SelectedCompany, company?.searchText, offset, limit, date, selectType);
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    GetList(company?.SelectedCompany, company?.searchText, 0, value, date, selectType);
  };
  // reservation get
  const GetList = (v, searchText, offSet, limit, date, type) => {
    const typeSelet = type?.value === 'All' ? "" : type?.value
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    let clientID = localStorage.getItem(LocalStorageKeys.clinetID);
    client
      .query({
        query: MANAGE_INVOICE_LIST(
          v?.value,
          offSet,
          limit,
          searchText,
          clientID,
          JSON.stringify(date?.from)?.length > 0
            ? `${moment(date?.from).format("YYYY-MM-DD")} 00:00:00`
            : "",
          JSON.stringify(date?.to)?.length > 0
            ? `${moment(date?.to).format("YYYY-MM-DD")} 23:59:59`
            : "",
          typeSelet
        ),
        fetchPolicy: "network-only",
      })
      .then((res) => {
        setSelected(false);
        let result = res?.data?.quotation_payment_schedule?.map((x) => {
          return {
            id: x?.id,
            Agreement_Number: x?.agreement?.agreement_no ?? "",
            Account_Number: x?.agreement?.account?.account_no ?? "",
            Account_Name: x?.agreement?.account?.name ?? "",
            Managed_Invoice_Number: x?.schedule_no ?? "",
            Description: x?.schedule_no ?? "",
            Posting_Date: x?.payment_due_date ?? "",
            Invoice_Type: x?.invoice_type,
            Total_Amount: x?.total_amount ?? "",
            Payment_Method: x?.agreement?.payment_mode ?? "",
            is_active: false,
          };
        });
        setList({
          data: result ?? [],
          count: res?.data?.count?.[0]?.count ?? 0,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Internal error. Please try again later.",
        });
      });
  };
  //   get permission function
  const getPermission = async () => {
    const tempPermission = await getRoutePermission();
    permission.current = tempPermission;
    if (tempPermission?.read) {
      let company = getCompanyOption(backdrop, auth, alert);
      if (company) {
        GetList(company?.selected, "", 0, 10, date);
        setCompany({
          ...company,
          companyList: company?.list,
          SelectedCompany: company?.selected,
        });
        getEnum();
      }
    }
  };
  //select checkbox
  const onCheckBox = (datas, value, index, checkboxKey) => {
    if (value !== undefined) {
      let result = [...list?.data];
      result[index][checkboxKey] = value;
      setList({
        ...list,
        data: result,
      });
    }
  };
  // select all checkbox
  const allSelectBox = (e) => {
    setSelected(e);
    const result = list?.data?.map((x) => {
      return {
        id: x?.id,
        Agreement_Number: x?.Agreement_Number ?? "",
        Account_Number: x?.Account_Number ?? "",
        Account_Name: x?.Account_Name ?? "",
        Managed_Invoice_Number: x?.Managed_Invoice_Number ?? "",
        Description: x?.Description ?? "",
        Posting_Date: x?.Posting_Date ?? "",
        Invoice_Type: "-",
        Total_Amount: x?.Total_Amount ?? "",
        Payment_Method: x?.Payment_Method ?? "",
        is_active: e,
      };
    });
    setList({
      ...list,
      data: result ?? [],
    });
  };
  // reload
  const reloadFunction = () => {
    GetList(company?.SelectedCompany, "", 0, 10, date, selectType);
  };
  // enum get
  const getEnum = async () => {
    const result = await enumSelect([enum_types.invoice_charge_type]);
    setValue({
      type: result?.invoice_charge_type,
    });
  };
  // get list by type
  const typeChage = (value) => {
    setCompany({
      ...company,
      searchText: ""
    })
    GetList(company?.SelectedCompany, "", 0, 10, date, value);
  };
  useEffect(() => {
    getPermission();
    // eslint-disable-next-line
  }, [auth]);
  return (
    <div>
      {/* sub nav bar */}
      <Subheader
        title={`${t("Managed_Invoices")} (${list?.count ?? 0})`}
        select
        hideBackButton={true}
        options={company?.companyList}
        value={company?.SelectedCompany}
        placeholder={t("Search")}
        onchange={(e) => {
          companySelect(e);
        }}
      />

      {/* table contant */}
      <Box className={classes.root}>
        {/* <RangeDatePicker /> */}
        <TableComponent
          t={t}
          list={list}
          page={page}
          limit={limit}
          handlePagination={handlePagination}
          handleChangeLimit={handleChangeLimit}
          searchFunction={searchFunction}
          company={company}
          date={date}
          dateChange={dateChange}
          onCheckBox={onCheckBox}
          allSelectBox={allSelectBox}
          selected={selected}
          enumValue={enumValue}
          typeChage={typeChage}
          setSelectType={setSelectType}
        />
        <Box pt={2}>
          <BottomCard
            t={t}
            company={company?.SelectedCompany}
            list={list?.data?.filter((x) => x?.is_active === true)}
            date={date}
            allList={list?.data}
            reloadFunction={reloadFunction}
          />
        </Box>
      </Box>
    </div>
  );
};
export default withNamespaces("manageinvoice")(ManageInvoice);
