import { Box, Grid, Typography } from "@mui/material";
import { MobileNumberInputComponent, TextBox } from "../../../components";
import { useStyles } from "../styles";
export const Contact = ({ data = {}, updateState = {} }) => {
    const classes = useStyles()
    return (
        <Box className={classes.box} marginTop="16px">
            <Typography className={classes.title}>Contact & Other Information</Typography>
            <Grid container rowSpacing={1} columnSpacing={3}>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                    <MobileNumberInputComponent
                        xs={4}
                        label="Contact"
                        handleChange={(value) => updateState("primary_contact", value)}
                        value={data?.primary_contact}

                    />
                </Grid>
                {/* <Grid item xs={12} sm={4} md={3} lg={3}>
                    <TextBox
                        label="Title"
                        placeholder="Enter Title"
                        onChange={(value) => updateState("title", value.target.value)}
                        value={data?.title}


                    />
                </Grid> */}
                <Grid item xs={12} sm={4} md={3} lg={3}>
                    <MobileNumberInputComponent
                        xs={4}
                        label="Telephone"
                        handleChange={(value) => updateState("primary_telephone", value)}
                        value={data?.primary_telephone}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                    <TextBox
                        label="Email Address"
                        placeholder="Enter Email Address"
                        onChange={(value) => updateState("primary_email", value.target.value)}
                        value={data?.primary_email}


                    />
                </Grid>
            </Grid>

        </Box>

    )
}