import React from "react";
import { Grid, Box, Typography, Divider, Stack, Tab } from "@mui/material";
import { dashboardStyles } from "../../dashboard/style";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { OutStandingCard } from "../../dashboard/components";
import { SearchFilter } from "../../../components";

export const RequestCard = (props) => {
  const classes = dashboardStyles();

  return (
    <div sx={{ width: "100%" }}>
      <Box sx={{ width: "100%" }} className={classes.cardNew}>
        <Box className={classes.expensesHeader}>
          <div className={classes.div}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              justifyContent={"space-evenly"}
              padding={1}
            >
              <Box>
                <Typography className={classes.title}>
                  {"General Requests"}
                </Typography>
                <Typography className={classes.count}>
                  {props?.generalAndMaintenanceData?.generalCount ?? 0}
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.title}>
                  {"Maintenance Requests"}
                </Typography>
                <Typography className={classes.count}>
                  {props?.generalAndMaintenanceData?.maintanceCount ?? 0}
                </Typography>
              </Box>
            </Stack>
          </div>
        </Box>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={props?.value} className={classes.requestTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={props?.handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="General" value="General" />
                <Tab label="Maintenance" value="Maintenance" />
              </TabList>
            </Box>
            <TabPanel value="General" sx={{ paddingTop: "4px" , height:props?.matches ? `calc(100vh - 438px) !important`:"100% !important"}}>
              <Grid container spacing={1}>
                <Grid item xs={9}>
                  <Typography className={classes.title}>
                    {"General Request"}
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign={"right"}>
                  <Typography className={classes.view}>{"View all"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <SearchFilter
                    value={props?.searchText}
                    placeholder="Search "
                    handleChange={(value) => props?.handleSearch(value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container className={classes.generalRequest}>
                    {props?.generalAndMaintenanceData?.data?.length > 0 ? (
                      props?.generalAndMaintenanceData?.data?.map((item) => {
                        return (
                          <>
                            <Grid item xs={12}>
                              <OutStandingCard data={item} type={3} navigate={props?.navigate} />
                            </Grid>
                          </>
                        );
                      })
                    ) : (
                      <Grid item xs={12} className={classes.noData}>
                        <Typography className={classes.title}>
                          {"No data found"}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="Maintenance" sx={{ paddingTop: "4px" , height:props?.matches ? `calc(100vh - 438px) !important`:"100% !important"}}>
              <Grid container spacing={1}>
                <Grid item xs={9}>
                  <Typography className={classes.title}>
                    {"Maintenance"}
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign={"right"}>
                  <Typography className={classes.view}>{"View all"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <SearchFilter
                    value={props?.searchText}
                    placeholder="Search "
                    handleChange={(value) => props?.handleSearch(value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.generalRequest}>
                    {props?.generalAndMaintenanceData?.data?.length > 0 ? (
                      props?.generalAndMaintenanceData?.data?.map((item) => {
                        return (
                          <>
                            <Box>
                              <OutStandingCard data={item} type={3}  navigate={props?.navigate} />
                            </Box>
                          </>
                        );
                      })
                    ) : (
                      <Box className={classes.noData}>
                        <Typography className={classes.title}>
                          {"No data found"}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </div>
  );
};
