export const PreviewTabData = ["Drafting", "Preview"]

export const initial_data = {
    id: "",
    title: "",
    level: "",
    property: "",
    agreement_unit: "",
    contact: "",
    correspondences_type: "",
    date: "",
    acknowledge_before: "",
    reference_letter: "",
    subject: "",
    priority: "low",
    notes: "",
    error: {
        property: "",
        agreement_unit: "",
        contact: "",
        correspondences_type: "",
        date: "",
        acknowledge_before: "",
        subject: "",
        priority: "",
        notes: "",
    }
}