import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";
export const DbTaskStyle = makeStyles((theme) => ({
  cardBox: {
    padding: "16px",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 0%), 1px 1px 6px 2px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 20%)",
    borderRadius: "8px",
  },

  margin: {
    margin: "10px",
  },

  cardBoxes: {
    padding: "16px",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 0%), 1px 1px 6px 2px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 20%)",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },

  text: {
    marginBottom: "5px",
    color: "#98A0AC",
    fontSize: "14px",
    fontFamily: SemiBold,
  },

  avatar: {
    width: "90px",
    height: "90px",
    marginBottom: "13px",
  },

  upload: {
    border: "1px solid #98A0AC",
    borderRadius: "5px",
    padding: "3px 13px",
    color: "#98A0AC",
    fontSize: "14px",
    fontFamily: SemiBold,
  },

  align: {
    display: "flex",
    justifyContent: "end",
    marginTop: "20px",
  },

  tableData: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },

  tableRow: {
    "& .MuiTableCell-head": {
      fontWeight: "600",
      padding: "10px",
      textAlign: "center",
    },
  },

  bodyTableRow: {
    "& .MuiTableCell-body": {
      textAlign: "center",
    },
  },

  buttons: {
    display: "flex",
    justifyContent: "space-around",
  },

  //   create: {
  //     // marginRight: "20px",
  //   },

  //   update: {
  //     display: "none",
  //   },

  editBtn: {
    color: "#000 !important",
    backgroundColor: "#ded7d7",
    marginRight: "10px",
  },

  deleteBtn: {
    color: "red !important",
    backgroundColor: "#ffcfcf",
  },
}));
