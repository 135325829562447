import React, { useState } from "react";
import { FormGenerator } from "../../../../components";
import { Button, Box } from "@mui/material";
import { useStyleTimeLine } from "../../styles";
export const TermisionRequestForm = ({ t }) => {
  const classes = useStyleTimeLine();
  const [state, setState] = useState({
    StartDate: null,
    EndDate: null,
    Description: "",
    duration: "",
  });

  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
  };
  return (
    <Box p={2}>
      <FormGenerator
        components={[
          {
            size: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
            },
            isActive: true,
            component: "textWithToogle",
            label: "Lease Duration",
            value: state?.duration,
            placeholder: "Lease Duration",
            onChange: (value) => updateState("duration", value),
            error: state?.error?.duration,
            isRequired: true,
            options: [
              {
                value: "Month",
                label: "Month",
              },
              {
                value: "Yearly",
                label: "Yearly",
              },
            ],
            is_toogle: true,
          },
          {
            isActive: true,
            component: "datepicker",
            label: t("Start_Date"),
            value: state?.StartDate,
            placeholder: t("Start_Date"),
            onChange: (value) => updateState("StartDate", value),
            error: state?.error?.StartDate,
            maxDate: new Date(),
            isRequired: true,
            isNot: true,
            size: {
              xs: 6,
            },
          },
          {
            isActive: true,
            component: "datepicker",
            label: t("End_Date"),
            value: state?.EndDate,
            placeholder: t("End_Date"),
            onChange: (value) => updateState("EndDate", value),
            error: state?.error?.EndDate,
            maxDate: new Date(),
            isRequired: true,
            isNot: true,
            size: {
              xs: 6,
            },
          },
          {
            isActive: true,
            component: "text",
            label: t("Remarks_optional"),
            value: state?.Description,
            placeholder: t("Remarks_optional"),
            onChange: (e) => updateState("Description", e?.target?.value),
            error: state?.error?.Description,
            multiline:true,
            size: {
              xs: 12,
            },
          },
        ]}
      />
      <Box height={"24px"} />
      <Button className={classes.Yes}>{t("Submit")}</Button>
    </Box>
  );
};
