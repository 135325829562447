import React from "react";
import { withNavBars } from "../../HOCs";
import { AccessGates } from "./accessGates";

class AccessGatesParent extends React.Component {
  render() { return <AccessGates {...this.props} /> }
}

const props = { boxShadow: false }

export default withNavBars(AccessGatesParent, props);
