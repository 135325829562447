import React from "react";
import { ComponentType } from "./componentType";
import { ViewCard } from "./viewCard";
import { ViewCardMuti } from "./viewCardMulti";
export const PricingApprialsComponent = ({
  component = "",
  data = {},
  handleChange,
  info = false,
  marginLeft = "16px",
  padding = "12px",
  sub = false,
  backgroundColor = "#fff",
  color = "#000",
  index = 0,
  selectedComponent = "",
  borderColor = false,
  subTitle="",
  backgroundcolor="",
  bordercolor=""
}) => {
  const componentSelect = (component) => {
    switch (component) {
      case "ComponentType":
        return (
          <ComponentType
            data={data}
            handleChange={handleChange}
            info={info}
            marginLeft={marginLeft}
            padding={padding}
            sub={sub}
            index={index}
            borderColor={borderColor}
            backgroundcolor={backgroundcolor}
            bordercolor={bordercolor}
          />
        );
      case "ViewCard":
        return (
          <ViewCard
            data={data}
            backgroundColor={backgroundColor}
            color={color}
          />
        );
      case "ViewCardMuti":
        return (
          <ViewCardMuti
            data={data}
            backgroundColor={backgroundColor}
            color={color}
            subTitle={subTitle}
          />
        );

      default:
        return null;
    }
  };
  return <div>{componentSelect(component)}</div>;
};
