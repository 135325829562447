import React, { useState, useEffect } from "react";
import { Subheader } from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import {
  accessCheckRender,
  getCompanyOption,
  getRoutePermissionNew,
  pageName,
} from "../../utils";

import { DashBoard } from "./dashboard";

export const SalesManagerDashboard = () => {
  // states
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companyList, setCompanyList] = useState([]);

  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [permission, setPermission] = React.useState({});

  // use effect to get permission
  useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //   company change
  const handleCompanyChange = (e) => {
    setSelectedCompany(e);
  };

  const render = () => {
    return (
      <>
        {/* sub header */}
        <Subheader
          hideBackButton={true}
          title="Sales Manager Dashboard"
          select
          options={companyList ?? []}
          value={selectedCompany}
          onchange={(e) => {
            handleCompanyChange(e);
          }}
        />
        <DashBoard selectedcompany={selectedCompany} />
      </>
    );
  };

  return (
    <div>
      {accessCheckRender(render, permission, pageName.dashboard)}
    </div>
  );
};
