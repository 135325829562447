import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { dashboardStyles } from "./style";
import // AlertContext,  // BackdropContext
  "../../contexts";
import {
  CardDetails,
  GraphComponent,
} from "../propertymanagerdashboard/components";
// import { ActivePropertyIcon } from "../../assets";
import {
  teamPerformHead,
  teamPerformPath,
  myTaskPath,
  myTaskHead,
  openLeadHead,
  openLeadPath,
  NetWorkCallMethods,
  AlertProps,
} from "../../utils";
import { RevenueCard } from "./component";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { constractData } from "./common";
import { AlertContext } from "../../contexts";
import moment from "moment-timezone";
import { DialogBox } from "../../components";
import { NewLoader } from "../../components/newLoader";

const graph = Array.from(Array(9).keys())?.map((v, i) => {
  return {
    name: `Vacant${i * 1}` ?? "",
    count: 10 ?? "",
    fill: `#${Math.random().toString(16).substring(2, 8)}`,
  };
});

const teamPerform = Array.from(Array(9).keys())?.map((v, i) => {
  return {
    Image: `https://mui.com/static/images/avatar/2.jpg` ?? "",
    rep: `Prasath${i * 1}` ?? "",
    Amount: `${i * 10} k`,
    Target: `${i * 100} k`,
  };
});
// end

const barData2 = {
  labels: [""],
  datasets: [
    {
      label: "My First dataset",
      backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
      barThickness: 50,
      borderWidth: 1,
      //stack: 1,
      data: [65],
    },

    {
      label: "My second dataset",
      backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
      borderWidth: 1,
      barThickness: 50,
      data: [45],
    },
  ],
};

const scaleLine = {
  y: {
    ticks: {
      // Include a dollar sign in the ticks
      callback: function (value, index, ticks) {
        return value;
      },
    },
    stacked: true,
  },
  x: {
    stacked: true,
  },
  xAxes: [
    {
      gridLines: { display: false },
    },
  ],
  yAxes: [
    {
      gridLines: {
        drawBorder: false,
      },
      ticks: {
        min: 30,
      },
    },
  ],
};
const scaleLineWon = {
  x: {
    ticks: {
      // Include a dollar sign in the ticks
      callback: function (value, index, ticks) {
        return value + "k";
      },
    },
    stacked: true,
  },
  y: {
    stacked: true,
  },
  xAxes: [
    {
      barThickness: 6, // number (pixels) or 'flex'
      maxBarThickness: 8, // number (pixels)
    },
  ],
  yAxes: [
    {
      barThickness: 6, // number (pixels) or 'flex'
      maxBarThickness: 8, // number (pixels)
    },
  ],
};

export const LeaseManagerDashboard = (props) => {
  // classes
  const classes = dashboardStyles();
  const [details, setDetails] = useState({});
  // context
  const alert = React.useContext(AlertContext);
  const [zoom, setZoom] = useState(false);
  const [zoomGraph, setZoomGraph] = useState("");
  const [loader, setLoader] = useState(false);
  const zoomGraphFunction = (e) => {
    setZoomGraph(e);
    setZoom(true);
  };

  // close popup
  const handleCloseModule = () => {
    setZoom(false);
  };
  // getDetails
  const getDetails = () => {
    setLoader(true);
    let teamsId = props?.selectedTeams?.value;
    const payload = {
      company_id: props?.selectedcompany?.value ?? "",
      start_date: `${moment(props?.calandervalue?.value?.from_date).format(
        "YYYY-MM-DD"
      )} 00:00:00`,
      end_date: `${moment(props?.calandervalue?.value?.to_date).format(
        "YYYY-MM-DD"
      )} 23:59:59`,
    };
    if (teamsId !== "noteams") {
      payload["team"] = teamsId
      // payload["team_all_property"] = true
    }
    NetworkCall(
      `${config.api_url}/dashboard/lead/sale_dashboard`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const resut = constractData(res?.data?.data);
        setDetails(resut);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  useEffect(() => {
    if (props?.selectedcompany?.label?.length > 0) {
      getDetails();
    }
    // eslint-disable-next-line
  }, [props?.calandervalue, props?.selectedTeams]);

  return (loader ?
    (
      <NewLoader minusHeight="100px" />
    ) :
    (<div className={classes.root}>
      {" "}
      {/* section1 */}
      <Grid container spacing={2}>
        {/* lead card */}
        <Grid item xs={12} sm={6} lg={1.5}>
          <Grid container spacing={2}>
            {details?.leads?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {/* Lead Count by Status */}
        <Grid item xs={12} sm={6} lg={3}>
          <GraphComponent
            title={"Lead Count by Status"}
            graphData={details?.lead_count_by_status ?? []}
            isPie
            margin={"20px"}
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        {/* Opportunity Count By Status */}
        <Grid item xs={12} sm={6} lg={3}>
          <GraphComponent
            title={"Opportunity Count By Lead Source"}
            graphData={details?.opportunity_count_by_lead_source ?? []}
            isPie
            margin={"20px"}
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        {/* Opportunity Count By Status */}
        <Grid item xs={12} sm={6} lg={4.5}>
          <GraphComponent
            title={"Opportunity Count By Status"}
            graphData={details?.opportunity_count_by_status ?? []}
            margin={"20px"}
            isBar
            isZoom
            onZoomClick={zoomGraphFunction}
          // scale={scaleLine}
          />
        </Grid>
      </Grid>
      {/* section 2 */}
      <Grid container spacing={2} mt={0.3}>
        {/* qutation card */}
        <Grid item xs={12} sm={6} lg={1.5}>
          <Grid container spacing={2}>
            {details?.qutation?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {/* Opportunity Reveune By Status */}
        <Grid item xs={12} sm={6} lg={5.25}>
          <GraphComponent
            title={"Opportunity Reveune By Status"}
            graphData={details?.opportunity_reveune_by_status ?? []}
            isBar
            // scale={scale}
            margin={"20px"}
            isZoom
            onZoomClick={zoomGraphFunction}
            scale={scaleLine ?? false}
          />
        </Grid>
        {/* Opportunity Count By Status */}
        <Grid item xs={12} sm={12} lg={5.25}>
          <GraphComponent
            title={"Opportunity Count By Period"}
            graphData={details?.opportunity_count_by_period ?? []}
            indexAxis={"y"}
            isBar
            isDatalabels
            margin={"20px"}
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
      </Grid>
      {/* section 3 */}
      <Grid container spacing={2} mt={0.3}>
        {/* Reservation & Booking Status */}
        <Grid item xs={12} sm={6} lg={3}>
          <GraphComponent
            title={"Reservation & Booking Status"}
            graphData={graph ?? []}
            isPie
            total={10}
            isTotal
            margin={"20px"}
            innerRadius={50}
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        {/* Inspection Request Status*/}
        <Grid item xs={12} sm={6} lg={3}>
          <GraphComponent
            title={"Inspection Request Status"}
            graphData={graph ?? []}
            margin={"20px"}
            total={10}
            isPie
            isTotal
            innerRadius={50}
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        {/* Inspection Request Status*/}
        <Grid item xs={12} sm={12} lg={6}>
          <GraphComponent
            title={"Team Performance"}
            isTable
            tableData={teamPerform}
            heading={teamPerformHead}
            path={teamPerformPath}
            dataType={[
              { type: ["avatarmanagement"], name: "Image" },
              { type: ["text"], name: "rep" },
              { type: ["text"], name: "Amount" },
              { type: ["text"], name: "Target" },
            ]}
            tabelheight={"calc(100vh - 450px)"}
          />
        </Grid>
      </Grid>
      {/* section4 */}
      <Grid container spacing={2} mt={0.3}>
        {/*My Open Leads*/}
        <Grid item xs={12} sm={6} lg={4}>
          <GraphComponent
            title={"My Open Leads"}
            isTable
            heading={openLeadHead}
            path={openLeadPath}
            tableData={details?.my_open_leads ?? []}
            dataType={[
              { type: ["text"], name: "Lead" },
              { type: ["text"], name: "Email" },
              { type: ["text"], name: "Phone" },
              { type: ["text"], name: "Company" },
              { type: ["date"], name: "Created At" },
            ]}
            tabelheight={"calc(100vh - 350px)"}
          />
        </Grid>
        {/* My Tasks*/}
        <Grid item xs={12} sm={6} lg={4}>
          <GraphComponent
            title={"My Tasks"}
            isTable
            tableData={details?.my_tasks ?? []}
            heading={myTaskHead}
            path={myTaskPath}
            dataType={[
              { type: ["text"], name: "to-do" },
              { type: ["text"], name: "Lead" },
              { type: ["text"], name: "Email" },
              { type: ["text"], name: "Phone" },
              { type: ["text"], name: "Company" },
            ]}
            tabelheight={"calc(100vh - 350px)"}
          />
        </Grid>
        {/* Opportunity Revenue */}
        <Grid item xs={12} sm={12} lg={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={12}>
              <RevenueCard
                height={"138px"}
                title={"Opportunity Revenue"}
                sub={`${details?.currency ?? 0} ${details?.revenue ?? 0}`}
                sub1={"For the Period"}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={12}>
              <GraphComponent
                title={"Opportunities Won"}
                graphData={barData2 ?? []}
                indexAxis={"y"}
                isBar
                // scale={scale}
                margin={"15px 0px 0px"}
                // data={state}
                // tabNameState={state?.won}
                // tabList={["This Month", "This Quarter"]}
                // keyType={"won"}
                // updateState={updateState}
                // titleTab
                isZoom
                onZoomClick={zoomGraphFunction}
                scale={scaleLineWon ?? false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* zoom dialog for each graph */}
      <DialogBox
        isnotTitle
        maxWidth={"sm"}
        handleClose={handleCloseModule}
        open={zoom}
        component={
          <>
            {zoomGraph === "Lead Count by Status" && (
              <GraphComponent
                title={"Lead Count by Status"}
                graphData={details?.lead_count_by_status ?? []}
                isPie
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
                margin={"40px"}
                divider={6}
                divider1={6}
              />
            )}
            {zoomGraph === "Opportunity Count By Lead Source" && (
              <GraphComponent
                title={"Opportunity Count By Lead Source"}
                graphData={details?.opportunity_count_by_lead_source ?? []}
                isPie
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
                margin={"40px"}
                divider={6}
                divider1={6}
              />
            )}
            {zoomGraph === "Opportunity Count By Status" && (
              <GraphComponent
                title={"Opportunity Count By Status"}
                graphData={details?.opportunity_count_by_status ?? []}
                margin={"40px"}
                isBar
                padding={"24px 24px 47px"}
              />
            )}
            {zoomGraph === "Opportunity Reveune By Status" && (
              <GraphComponent
                title={"Opportunity Reveune By Status"}
                graphData={details?.opportunity_reveune_by_status ?? []}
                isBar
                padding={"24px 24px 47px"}
                margin={"40px"}
                scale={scaleLine ?? false}
              />
            )}
            {zoomGraph === "Opportunity Count By Period" && (
              <GraphComponent
                title={"Opportunity Count By Period"}
                indexAxis={"y"}
                isBar
                isDatalabels
                padding={"24px 24px 47px"}
                margin={"40px"}
                graphData={details?.opportunity_count_by_period ?? []}
              />
            )}
            {zoomGraph === "Reservation & Booking Status" && (
              <GraphComponent
                title={"Reservation & Booking Status"}
                graphData={graph ?? []}
                isPie
                total={10}
                isTotal
                innerRadius={50}
                padding={"24px 24px 47px"}
                margin={"40px"}
                divider={6}
                divider1={6}
              />
            )}
            {zoomGraph === "Inspection Request Status" && (
              <GraphComponent
                title={"Inspection Request Status"}
                graphData={graph ?? []}
                total={10}
                isPie
                isTotal
                innerRadius={50}
                padding={"24px 24px 47px"}
                margin={"40px"}
                divider={6}
                divider1={6}
              />
            )}
            {zoomGraph === "Opportunities Won" && (
              <GraphComponent
                title={"Opportunities Won"}
                graphData={barData2 ?? []}
                indexAxis={"y"}
                isBar
                // scale={scale}
                padding={"24px 24px 47px"}
                margin={"40px"}
                scale={scaleLineWon ?? false}
              />
            )}
          </>
        }
      />
    </div>)
  );
};
