import React from "react";

export const PlusIcon = (props) => {
    const width = props?.width ? props?.width : 9.5;
    const height = props?.height ? props?.height : 9.5;
    const color = props?.color ? props?.color : '#6f6f6f';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 9.5 9.5"
            {...props}
        >
            <path id="Path_98088" data-name="Path 98088" d="M60.349,38.895H56.787V35.332a.594.594,0,1,0-1.187,0v3.563H52.037a.594.594,0,0,0,0,1.188H55.6v3.563a.594.594,0,0,0,1.188,0V40.082h3.563a.594.594,0,1,0,0-1.187Z" transform="translate(-51.443 -34.738)" fill={color} />
        </svg>
    );
};

