import React from "react";
import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import { TreeStylesParent } from "./style";
import { styled } from "@mui/material/styles";
import EditIMG from "../assests/edit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#5078E1",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const PermissionCard = (props) => {
  const classes = TreeStylesParent();
  return (
    <Box
      className={
        props?.id === props?.permissionID
          ? classes.selectedpermissionBox
          : classes.permissionBox
      }
      onClick={() => props?.seclectPermission(props?.data)}
    >
      <Box>
        <Stack>
          <Typography className={classes.permissionTitle}>
            {props?.title}
          </Typography>
          <Typography className={classes.permissionSubTitle}>
            {props?.data?.module.label}
          </Typography>
        </Stack>
      </Box>
      <Box className={classes.permissionEditSection}>
        <Box>
          <EditIMG onClick={() => props?.editFunction(props?.data)} />
        </Box>
        <Box className={classes.marginLeft}>
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                disabled={props?.disabled}
                checked={props?.data?.is_active}
                onClick={(e) =>
                  props?.activePermission(e?.target?.checked, props?.index)
                }
              />
            }
          />
        </Box>
        <Box>
          <IconButton disabled={props?.disabled}>
            <DeleteForeverOutlinedIcon
              className={classes.deleteIcon}
              onClick={props?.deletePermission}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
