import {
    Box, Grid
} from "@mui/material";
import React from "react";
import { FormGenerator } from "../../../components";
import { enumSelect, enum_types } from "../../../utils";
import { LeadDetailsCard } from "./index";
import { useStyles } from "./styles";
export const ShortTermQuotationForm = ({ data, updateState, user = {}, type = "", purpose = "" }) => {
    const classes = useStyles();
    const [enumValue, setEnum] = React.useState({
        unit_usage: [],
        priority: [],
        source: [],
        lease_type: [],
        billGenCycle: [],
        billing_cycle_date: [],
    })

    const getEnum = async () => {
        const result = await enumSelect([enum_types.urgent_type, enum_types.l_period, enum_types.lead_source, enum_types.billing_cycle, enum_types.billing_cycle_date])
        setEnum({
            priority: result?.urgent_type,
            lease_type: result?.l_period?.filter(val => val?.value !== "Quarterly").filter(val => val?.value !== "Daily"),
            lead_source: result?.lead_source,
            billGenCycle: result?.billing_cycle,
            billing_cycle_date: result?.billing_cycle_date,
        })
    }
    React.useEffect(() => {
        getEnum()
    }, [])
    //form data
    const formLease = [
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "select",
            label: "Purpose",
            value: data?.subject,
            placeholder: "Purpose",
            onChange: (value) => updateState("purpose", value),
            error: data?.error?.subject,
            isRequired: true,
            options: []
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "select",
            label: "Revenue Type",
            value: data?.revenue_type,
            placeholder: "Revenue Type",
            onChange: (value) => updateState("revenue_type", value),
            error: data?.error?.revenue_type,
            isRequired: true,
            options: []
        },

        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "textWithToogle",
            label: "Lease Duration",
            value: data?.quotation_lease_duration,
            placeholder: "Lease Duration",
            onChange: (value) => updateState("quotation_lease_duration", value),
            error: data?.error?.quotation_lease_duration,
            isRequired: true,
            options: enumValue?.lease_type
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "popoverDate",
            label: "Lease Start & End Date",
            value: data?.subject,
            placeholder: "Purpose",
            startDate: data?.lease_start_date,
            endDate: data?.lease_end_date,
            isRequired: true,
            disabled: true
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "date",
            label: "Billing Start Date",
            value: data?.quotation_billing_start_date,
            placeholder: "Billing Start Date",
            onChange: (value) => updateState("quotation_billing_start_date", value),
            error: data?.error?.quotation_billing_start_date,
            isRequired: true
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "select",
            label: "Payment Period",
            value: data?.quotation_payment_peroid,
            placeholder: "Payment Period",
            onChange: (value) => updateState("quotation_payment_peroid", value),
            error: data?.error?.quotation_payment_peroid,
            isRequired: true,
            options: []
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "select",
            label: "Payment Options",
            value: data?.quotation_payment_option,
            placeholder: "Payment Options",
            onChange: (value) => updateState("quotation_payment_option", value),
            error: data?.error?.quotation_payment_option,
            isRequired: true,
            options: []
        },
    ]

    const formLease1 = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "textEditer",
            label: "Quotation Description",
            value: data?.quotation_description,
            placeholder: "Quotation Description",
            onChange: (value) => updateState("quotation_description", value),
            error: data?.error?.quotation_description,
            isRequired: true,
            options: [],
            height: "60px"
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "toggleCheck",
            label: "Short Term Rentals",
            value: data?.short_term,
            placeholder: "Short Term Rentals",
            onChange: (value) => updateState("short_term", value),
            error: data?.error?.short_term,
            isRequired: true,
            toggle: true,

        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "toggle",
            label: "Billing Cycle Method",
            value: data?.subject,
            placeholder: "Billing Cycle Method",
            onChange: (value) => updateState("quotation_billing_cycle", value),
            error: data?.error?.quotation_billing_cycle,
            isRequired: true,
            options: enumValue?.quotation_billing_cycle
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "toggle",
            label: "Billing Cycle Date",
            value: data?.subject,
            placeholder: "Billing Cycle Date",
            onChange: (value) => updateState("quotation_billing_cycle_date", value),
            error: data?.error?.subject,
            isRequired: true,
            options: enumValue?.quotation_billing_cycle_date
        },
    ]


    return (
        <Box p={1}>
            <Grid container className={classes.leadDetailsRoot}>
                <Grid item xs={6} p={1}>
                    <Box p={2} className={classes.leadDetailsRootDivider}>
                        <LeadDetailsCard
                            data={{
                                name: data?.name,
                                image: data?.image_url?.length !== 0 ? data?.image_url?.src : null,
                                email: data?.email_id,
                                mobile: ` ${data.mobile?.mobile_code ?? ""} ${data.mobile?.mobile}`,
                                no: null,
                            }}
                            hideDetails={true}
                        />
                        <FormGenerator components={formLease} />
                        <Box height="18px" />
                    </Box>
                </Grid>
                <Grid item xs={6} p={1}>
                    <Box p={2} className={classes.leadDetailsRootDivider}>
                        <FormGenerator components={formLease1} />
                    </Box>
                </Grid>
            </Grid>
        </Box>


    );
};
