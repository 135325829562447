/* eslint-disable react-hooks/rules-of-hooks */
import { useApolloClient } from "@apollo/client";
import { Avatar, Box, Button, Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import "../../../App.css";
import AccountPerson from "../../../assets/accountPerson";
import EditIMG from "../../../assets/editimage";
import OwnerIcon from "../../../assets/owner";
import PhoneAccount from "../../../assets/phoneAccount";
import { AlertDialog, Editor, MapWithFields, MobileNumberInputComponent, NewDatePicker, SearchFilter, SelectBox, TextBox } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import {
      GET_CONTACT_ACCOUNT_USER,
      GET_PROPERTY_FORM_MASTER as queryOne,
      GET_PROPERTY_GROUP_PURPOSE,
      PROPERTY_HIERARCHY_MASTER,
      UOM_MASTER
} from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, Bold, EnableDisableOption, enumName, LocalStorageKeys, NetWorkCallMethods, SemiBold, useWindowDimensions, YesNoOption } from "../../../utils";
import { OwnerCard } from "./addOwnerCard";
import { useStylesCreation } from "./style";



const CustomTypography = styled(Typography)(({ theme }) => ({
      fontSize: 12,
      fontFamily: `${SemiBold}`,
      color: theme.typography.color.secondary,
      marginBottom: theme.spacing(1)
}))

const propertyStates = [
      {
            label: "Active",
            value: true,
      },
      {
            label: "In-Active",
            value: false,
      },
];
export const PropertyDetails = (props) => {
      const { mapLoad } = props;
      const size = useWindowDimensions()
      const alert = React.useContext(AlertContext);
      const classes = useStylesCreation();
      const [loading, setLoading] = React.useState(false);
      const [fullScreenMap, setFullScreenMap] = React.useState(false)
      const [deleteUser, setdeleteUser] = React.useState(false)
      const [selectedID, setSelectedID] = React.useState(null)
      const [open, setOpen] = React.useState(false)
      const [searchdata, setSearchdata] = React.useState("")
      const [offset, setOffset] = React.useState(0)
      const [ownerDetails, setOwnerDetails] = React.useState([])
      const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
      const client = useApolloClient();

      const loadOptions = async (search = "", array, type) => {

            setLoading(type);
            let result, query, offset = 0;
            let limit = 10

            if (search && !Boolean(array?.length)) {
                  offset = 0;
            }
            else {
                  offset = array?.length;
            }
            let userProfileID = localStorage.getItem("profileID")

            switch (type) {
                  case 'property_type':
                        // if (!props?.data?.purpose?.value?.length > 0) {
                        //       setLoading(null);
                        //       return { options: [] }
                        // }
                        query = GET_PROPERTY_GROUP_PURPOSE(offset, 10, search, null, props?.data?.Company?.value).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.property_group_master],
                              hasMore: (array?.length + result?.property_group_master?.length) < result?.count[0]?.count
                        }
                  case 'country_master':
                        query = queryOne(offset, 10, search, userProfileID, type).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.country_master],
                              hasMore: (array?.length + result?.country_master?.length) < result?.count[0]?.count
                        }
                  case 'client_user_roles':
                        query = queryOne(offset, 10, search, userProfileID, type).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.client_user_roles?.company],
                              hasMore: (array?.length + result?.client_user_roles?.length) < result?.count[0]?.count
                        }
                  case 'uom_master':
                        query = UOM_MASTER(search, offset, limit).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.uom_master],
                              hasMore: (array?.length + result?.uom_master?.length) < result?.count[0]?.count
                        }
                  case 'property_hierarchy_master':
                        query = PROPERTY_HIERARCHY_MASTER(search, offset, limit).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.property_hierarchy_master],
                              hasMore: (array?.length + result?.property_hierarchy_master?.length) < result?.count[0]?.count
                        }
                  default:
                        return { options: [] }
            }
      }
      const networkCallBack = async (query, variable = {}) => {
            let payload = {
                  query,
                  variable: variable
            };

            const options = await NetworkCall(
                  `${config.graphql_url}`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        let main = response.data.data;
                        setLoading(null);
                        return main
                  })
                  .catch((error) => {
                        setLoading(null);
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Some Thing Went Wrong",
                              vertical: AlertProps.vertical.top,
                              horizontal: AlertProps.horizontal.center,
                        });
                        return null
                  });

            return options
      }

      React.useEffect(() => {
            document.addEventListener("fullscreenchange", existFullscreen)
            return () => {
                  document.removeEventListener("fullscreenchange", existFullscreen)

            }

      }, [])
      //exist full screen
      const existFullscreen = () => {
            if (document.fullscreenElement === null) {
                  setFullScreenMap(false)
            }

      }
      //open full screen
      const fullScreenControl = () => {
            setFullScreenMap(true)
            document.getElementsByClassName('map-wrapper')[0]?.requestFullscreen()

      }

      // selected users
      const selectUser = (x) => {
            setSelectedID(x)
      }

      // close Drawer
      const closeDrawer = (v, k) => {
            if (k === 'delete') {
                  setdeleteUser(true)
                  setOpen(!open)
            }
            else if (k === 'add') {
                  setdeleteUser(false)
                  setOpen(!open)
                  props?.updateState('ownerAccount', {})
            }
            else if (k === 'close') {
                  props?.updateState('ownerAccount', {})
                  setSelectedID(null)
                  setOpen(false)
            }
            else if (k === 'deleteclose') {
                  setOpen(!open)
            }
            else {
                  props?.updateState('ownerAccount', selectedID)
                  setOpen(!open)
            }

      }
      // get user profiles in pop up
      const getOwnerDetails = (offset, search, k) => {
            client.query({
                  query: GET_CONTACT_ACCOUNT_USER,
                  fetchPolicy: 'network-only',
                  variables: {
                        company_id: props?.data?.Company?.value,
                        offset: offset,
                        limit: 10,
                        search: search ?? "",
                        clientID: clientId,
                        relationship: enumName.propertyOwner
                  }
            }).then((res) => {
                  if (k === 'search') {
                        setOwnerDetails(res?.data?.contact_account)
                  }
                  else {
                        setOwnerDetails(ownerDetails?.concat(res?.data?.contact_account));
                  }
            }).catch((err) => {

            })
      }


      // infinity scroll
      const fetchMoreData = () => {
            setOffset(offset + 10);
            getOwnerDetails(offset + 10, "", "normal");
      };
      // const search user data
      const handleSearch = (v) => {
            setSearchdata(v)
            getOwnerDetails(0, v, "search")
      }
      //open modal
      const openAccountModal = () => {
            setOpen(true)
            getOwnerDetails(0, "", "search")

      }

      return (
            <div>
                  <Grid container spacing={3}>

                        <Grid item xs={12} md={2} lg={2}>
                              <Box className={classes.imagebox} height={{ md: "294px" }}>
                                    <Typography className={classes.title}>
                                          Property Image
                                    </Typography>
                                    <br />
                                    <Avatar src={props?.data?.img?.length > 0 ? props?.data?.img : "/images/citynew.svg"} style={{ margin: '0 auto' }} className={props?.data?.img?.length > 0 ? classes.avatar : classes.avatar1} />
                                    <br />
                                    <label>   <Box className={classes.button} variant='contained' style={{ marginTop: '10px' }}>    Upload image</Box>
                                          <input type='file' style={{ display: 'none' }} accept="image/*"
                                                onClick={(event) => {
                                                      event.target.value = null
                                                }}
                                                onChange={(e) => props?.updateimg(e?.target?.files?.[0])} /> </label>
                                    {props?.data?.img?.length > 0 ? <Typography className={classes.removeimg} onClick={props?.removeimg}>Remove Image</Typography> : props?.data?.error?.img?.length > 0 ? (
                                          <span style={{ fontSize: "12px", color: "red" }}>
                                                {"Image is Required"}
                                          </span>
                                    ) : <span style={{ fontSize: "12px" }}>
                                          <Box height={"18px"} />
                                    </span>}

                              </Box>
                        </Grid>
                        <Grid item xs={12} md={8} lg={8}>
                              <Box className={classes.imagebox1} height={{ md: "294px" }}>
                                    <Typography className={classes.title}>
                                          Property Details
                                    </Typography>
                                    <Grid container rowSpacing={1} columnSpacing={3}>
                                          <Grid item xs={12} md={4} lg={3}>
                                                <TextBox
                                                      isrequired
                                                      isReadonly
                                                      label="Company"
                                                      value={props?.data?.Company?.label ?? ""} />
                                          </Grid>
                                          <Grid item xs={12} md={4} lg={4}>
                                                <TextBox
                                                      isrequired
                                                      label="Property Name"
                                                      placeholder="Select Property Name"
                                                      value={props?.data?.property ?? ""}
                                                      onChange={(e) => {
                                                            props?.handlePropertyName(e.target.value);
                                                      }}
                                                      isError={props?.data?.error?.property?.length > 0}
                                                      errorMessage={props?.data?.error?.property} />
                                          </Grid>
                                          <Grid item xs={12} md={4} lg={2.5}>
                                                <SelectBox
                                                      isRequired
                                                      label="Status"
                                                      placeholder="Select Status"
                                                      options={propertyStates ?? []}
                                                      value={props?.data?.status ?? ""}
                                                      onChange={(value) => {
                                                            props?.updateState("status", value);
                                                      }}
                                                      isError={props?.data?.error?.status?.length > 0}
                                                      errorMessage={props?.data?.error?.status} />
                                          </Grid>
                                          <Grid item xs={12} md={4} lg={2.5}>
                                                <Grid item xs={12}>
                                                      <Typography variant="body1" className={classes.label}>
                                                            Community<sup style={{ color: "red" }}>*</sup>
                                                      </Typography>
                                                </Grid>
                                                <Grid container rowGap={"2px"} columnGap={"2px"}>
                                                      {YesNoOption.map((value) => {
                                                            return <Button className={
                                                                  value?.value === props?.data?.is_community ?
                                                                        classes.buttonSelected :
                                                                        classes.buttonUnSelected}
                                                                  onClick={() => { props?.updateState("is_community", value?.value) }}>
                                                                  <Typography className={
                                                                        value?.value === props?.data?.is_community ?
                                                                              classes.buttonTextSelected :
                                                                              classes.buttonTextUnSelected}>
                                                                        {value?.label}</Typography>
                                                            </Button>
                                                      })}
                                                </Grid>
                                          </Grid>
                                    </Grid>
                                    <Grid container style={{ marginTop: '8px' }}>
                                          <Grid item xs={12}>
                                                <Editor
                                                      value={props?.data?.des ?? ""}
                                                      label="Property Description"
                                                      handleChange={(e, delta, source, editor) => {
                                                            props?.updateState("des", e);
                                                      }}
                                                      id={props?.id}
                                                      height={"80px"} />
                                          </Grid>
                                    </Grid>
                              </Box>
                        </Grid>
                        {props?.data?.ownerAccount && Object.keys(props?.data?.ownerAccount).length > 0 ?
                              <Grid item xs={12} md={2} lg={2}>
                                    <Box className={classes.imagebox} height={{ md: "294px" }}
                                          textAlign={"left !important"}>
                                          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                                <Typography className={classes.title}>
                                                      OWNER DETAILS
                                                </Typography>
                                                <Box  >
                                                      <IconButton className={classes.icon}
                                                            onClick={openAccountModal}
                                                      >
                                                            <EditIMG color={"#5078E1"} />
                                                      </IconButton>
                                                </Box>
                                          </Box>
                                          <Box >
                                                {props?.data?.ownerAccount?.contact?.image_url ?
                                                      <Avatar className={classes.avatar} src={props?.data?.ownerAccount?.contact?.image_url} sx={{ margin: "auto" }} /> :
                                                      <Avatar className={classes.avatar} sx={{ margin: "auto" }} />}
                                          </Box>
                                          <Box>
                                                <Typography className={classes.ownerTitle}>
                                                      {props?.data?.ownerAccount?.contact?.first_name}
                                                </Typography>
                                          </Box>
                                          <Box display={"flex"} alignItems={"center"} marginTop={"10px"}>
                                                <AccountPerson />
                                                <Typography className={classes.ownerSubtitle}>
                                                      &nbsp;{props?.data?.ownerAccount?.contact?.email_id}
                                                </Typography>
                                          </Box>
                                          <Box display={"flex"} alignItems={"center"} marginTop={"10px"}>
                                                <PhoneAccount />
                                                <Typography className={classes.ownerSubtitle}>
                                                      &nbsp;
                                                      {props?.data?.ownerAccount?.contact?.mobile_no_country_code ? props?.data?.ownerAccount?.contact?.mobile_no_country_code + " - " : "- "}  {props?.data?.ownerAccount?.contact?.mobile_no ? props?.data?.ownerAccount?.contact?.mobile_no : "- "}

                                                </Typography>
                                          </Box>


                                    </Box>
                              </Grid> :
                              <Grid item xs={12} md={2} lg={2} >
                                    <Box className={classes.imagebox} height={{ md: "294px" }} >
                                          <Typography className={classes.title}>
                                                Select  Owner
                                          </Typography>
                                          <br />
                                          <Avatar className={classes.avatar2}>
                                                <OwnerIcon />
                                          </Avatar>
                                          <br />
                                          <Box className={classes.button2}
                                                variant='outlined'
                                                style={{ bottom: '10px' }}
                                                onClick={openAccountModal}
                                          >    Add Owner
                                          </Box>
                                          {props?.data?.error?.ownerAccount?.length > 0 &&
                                                <span style={{ fontSize: "12px", color: "red" }}>
                                                      Owner is required
                                                </span>
                                          }

                                    </Box>
                              </Grid>}
                        {/* add member dialog */}
                        <AlertDialog
                              isNormal
                              header={deleteUser === true ? 'Delete Owner' : 'Add Owner'}
                              onClose={() => closeDrawer({}, deleteUser === true ? 'deleteclose' : 'close')}
                              open={open}
                              component={
                                    <>

                                          <div style={{
                                                textAlign: "center",
                                                padding: "24px",
                                          }}>


                                                <div id={'infinity'}>
                                                      {/* search box */}

                                                      {
                                                            deleteUser === false &&
                                                            <div style={{ marginBottom: '24px' }}>
                                                                  <SearchFilter
                                                                        value={searchdata}
                                                                        handleChange={(value) => handleSearch(value)}
                                                                        placeholder="Search Owner Details"
                                                                  />
                                                            </div>
                                                      }


                                                      {ownerDetails?.length > 0 ?
                                                            <InfiniteScroll
                                                                  dataLength={ownerDetails?.length ?? null}
                                                                  next={fetchMoreData}
                                                                  hasMore={true}
                                                                  height={size?.height - 500}
                                                            >
                                                                  {
                                                                        <OwnerCard data={ownerDetails} selectUser={selectUser} select={selectedID} />
                                                                  }

                                                            </InfiniteScroll> :
                                                            <div style={{ height: size?.height - 500 }}>
                                                                  <Typography style={{
                                                                        fontSize: "12px",
                                                                        fontFamily: Bold
                                                                  }}>
                                                                        No Owner Found
                                                                  </Typography>
                                                            </div>}
                                                </div>
                                                {/* add member button */}
                                                {
                                                      deleteUser === false && <Button variant='contained' fullWidth className={classes.buttoncard} onClick={closeDrawer}> Add Owner</Button>
                                                }
                                          </div>
                                    </>
                              }
                        />

                  </Grid>


                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label="Hierarchy"
                                          placeholder="Select Hierarchy"
                                          value={props?.data?.propertyHierarchy}
                                          onChange={(value) => {
                                                props?.updateState("propertyHierarchy", value);
                                          }}
                                          loading={loading === "property_hierarchy_master"}
                                          isPaginate
                                          debounceTimeout={800}
                                          loadOptions={(search, array) => loadOptions(search, array, 'property_hierarchy_master')}
                                          isError={props?.data?.error?.propertyHierarchy?.length > 0}
                                          errorMessage={props?.data?.error?.propertyHierarchy} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2} >
                                    <SelectBox
                                          isRequired
                                          label="Purpose"
                                          placeholder="Select Purpose"
                                          options={props?.enumValue.property_purpose}
                                          value={props?.data?.purpose ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("purpose", value);
                                          }}
                                          isError={props?.data?.error?.purpose?.length > 0}
                                          errorMessage={props?.data?.error?.purpose} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2} >
                                    <SelectBox
                                          isRequired
                                          label="Type"
                                          placeholder="Select Type"
                                          value={props?.data?.type}
                                          onChange={(value) => {
                                                props?.updateState("type", value);
                                          }}
                                          // key={JSON.stringify(props?.data?.purpose?.value)}
                                          loading={loading === "property_type"}
                                          isPaginate
                                          debounceTimeout={800}
                                          loadOptions={(search, array) => loadOptions(search, array, 'property_type')}
                                          isError={props?.data?.error?.type?.length > 0}
                                          errorMessage={props?.data?.error?.type} />
                              </Grid>

                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label="Revenue Type"
                                          placeholder="Select Revenue Type"
                                          options={props?.enumValue.revenue_type}
                                          value={props?.data?.revenue ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("revenue", value);
                                          }}
                                          isError={props?.data?.error?.revenue?.length > 0}
                                          errorMessage={props?.data?.error?.revenue} />
                              </Grid>
                              {props?.data?.revenue?.payment_period &&
                                    <Grid item xs={12} md={3} lg={2} >
                                          <SelectBox
                                                isRequired
                                                label="Payment Period"
                                                placeholder="Select Payment Period"
                                                options={props?.data?.revenue?.payment_period ?? []}
                                                value={props?.data?.period ?? ""}
                                                onChange={(value) => {
                                                      props?.updateState("period", value);
                                                }}
                                                isError={props?.data?.error?.period?.length > 0}
                                                errorMessage={props?.data?.error?.period} />
                                    </Grid>}
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label="Unit of Measure"
                                          placeholder="Select Unit of Measure"
                                          value={props?.data?.unit}
                                          onChange={(value) => {
                                                props?.updateState("unit", value);
                                          }}
                                          loading={loading === "uom_master"}
                                          isPaginate
                                          debounceTimeout={800}
                                          loadOptions={(search, array) => loadOptions(search, array, 'uom_master')}
                                          isError={props?.data?.error?.unit?.length > 0}
                                          errorMessage={props?.data?.error?.unit} />
                              </Grid>
                              {props?.data?.unit?.value && <>
                                    <Grid item xs={12} md={3} lg={2} >
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                isrequired
                                                label="Total Property Area"
                                                placeholder="Enter Total area"
                                                value={props?.data?.total ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("total", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end"><Typography className={classes.fontSize}>
                                                      {props?.data?.unit?.label}</Typography></InputAdornment>}
                                                isError={props?.data?.error?.total?.length > 0}
                                                errorMessage={props?.data?.error?.total} />
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={2}>
                                          <TextBox
                                                type='number'
                                                onKeyPress={(e) => {
                                                      if (e.key === 'e') {
                                                            e.preventDefault();
                                                      }
                                                }}
                                                // isrequired
                                                label="Set Back Area"
                                                placeholder="Enter Set Back Area"
                                                value={props?.data?.setBackArea ?? ""}
                                                onChange={(e) => {
                                                      props?.updateState("setBackArea", e.target.value);
                                                }}
                                                endAdornment={<InputAdornment position="end" ><Typography className={classes.fontSize}>
                                                      {props?.data?.unit?.label}</Typography></InputAdornment>}
                                                // isError={props?.data?.error?.setBackArea?.length > 0}
                                                // errorMessage={props?.data?.error?.setBackArea}
                                                 />
                                    </Grid>
                              </>}
                              <Grid item xs={12} md={3} lg={2}>
                                    <NewDatePicker
                                          label="Year Built"
                                          placeholder="Year Built"
                                          value={props?.data?.built ?? ""}
                                          isRequired={false}
                                          onChange={(value) => props?.updateState("built", value)}
                                          isError={props?.data?.error?.built?.length > 0}
                                          errorMessage={props?.data?.error?.built}
                                          maxDate={new Date()}
                                          newDate />
                              </Grid>
                              {props?.data?.revenue?.value === "Sale" && <Grid item xs={12} md={3} lg={2}>
                                    <NewDatePicker
                                          label="Handover Date"
                                          placeholder="Handover Date"
                                          value={props?.data?.handover ?? ""}
                                          isRequired
                                          onChange={(value) => props?.updateState("handover", value)}
                                          isError={props?.data?.error?.handover?.length > 0}
                                          errorMessage={props?.data?.error?.handover} />
                              </Grid>}

                              <Grid item xs={12} md={3} lg={2}>
                                    <TextBox
                                          type='number'
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                          label="No. of Swimming Pool"
                                          placeholder="Enter Count"
                                          value={props?.data?.swimmingPool ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("swimmingPool", e.target.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2} >
                                    <TextBox
                                          type='number'
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                          label="No. of Elevators"
                                          placeholder="Enter Count"
                                          value={props?.data?.elevator ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("elevator", e.target.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          label="Pet Policy"
                                          placeholder="Select Pet Policy"
                                          options={props?.enumValue?.pet_policy_type}
                                          value={props?.data?.pet ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("pet", value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Grid item xs={12}>
                                          <Typography variant="body1" className={classes.label}>
                                                Discussion Forum
                                          </Typography>
                                    </Grid>
                                    <Grid container rowGap={"2px"} columnGap={"2px"}>
                                          {EnableDisableOption.map((value) => {
                                                return <Button className={
                                                      value?.value === props?.data?.discussionForum ?
                                                            classes.buttonSelected :
                                                            classes.buttonUnSelected}
                                                      onClick={() => { props?.updateState("discussionForum", value?.value) }}>
                                                      <Typography className={
                                                            value?.value === props?.data?.discussionForum ?
                                                                  classes.buttonTextSelected :
                                                                  classes.buttonTextUnSelected}>
                                                            {value?.label}</Typography>
                                                </Button>
                                          })}
                                    </Grid>
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <Grid item xs={12}>
                                          <Typography variant="body1" className={classes.label}>
                                                Amenities Booking
                                          </Typography>
                                    </Grid>
                                    <Grid container rowGap={"2px"} columnGap={"2px"}>
                                          {EnableDisableOption.map((value) => {
                                                return <Button className={
                                                      value?.value === props?.data?.amenitiesBooking ?
                                                            classes.buttonSelected :
                                                            classes.buttonUnSelected}
                                                      onClick={() => { props?.updateState("amenitiesBooking", value?.value) }}>
                                                      <Typography className={
                                                            value?.value === props?.data?.amenitiesBooking ?
                                                                  classes.buttonTextSelected :
                                                                  classes.buttonTextUnSelected}>
                                                            {value?.label}</Typography>
                                                </Button>
                                          })}
                                    </Grid>
                              </Grid>
                              {/* <Grid item xs={12} md={3} lg={2}>
                                    <Grid item xs={12}>
                                          <Typography variant="body1" className={classes.label}>
                                                Asset Functional Location
                                          </Typography>
                                    </Grid>
                                    <Grid container rowGap={"2px"} columnGap={"2px"}>
                                          {YesNoOption.map((value) => {
                                                return <Button className={
                                                      value?.value === props?.data?.assetFunc ?
                                                            classes.buttonSelected :
                                                            classes.buttonUnSelected}
                                                      onClick={() => { props?.updateState("assetFunc", value?.value) }}>
                                                      <Typography className={
                                                            value?.value === props?.data?.assetFunc ?
                                                                  classes.buttonTextSelected :
                                                                  classes.buttonTextUnSelected}>
                                                            {value?.label}</Typography>
                                                </Button>
                                          })}
                                    </Grid>
                              </Grid> */}
                              {/* <Grid item xs={12} md={3} lg={2}>
                                    <Grid item xs={12}>
                                          <Typography variant="body1" className={classes.label}>
                                                Asset Property
                                          </Typography>
                                    </Grid>
                                    <Grid container rowGap={"2px"} columnGap={"2px"}>
                                          {YesNoOption.map((value) => {
                                                return <Button className={
                                                      value?.value === props?.data?.assetProperty ?
                                                            classes.buttonSelected :
                                                            classes.buttonUnSelected}
                                                      onClick={() => { props?.updateState("assetProperty", value?.value) }}>
                                                      <Typography className={
                                                            value?.value === props?.data?.assetProperty ?
                                                                  classes.buttonTextSelected :
                                                                  classes.buttonTextUnSelected}>
                                                            {value?.label}</Typography>
                                                </Button>
                                          })}
                                    </Grid>
                              </Grid> */}
                              {/* <Grid item xs={12} md={3} lg={2}>
                                    <Typography variant="body1" className={classes.label}>
                                          Public Listing<sup style={{ color: "red" }}>*</sup>
                                    </Typography>
                                    <Grid container spacing={"2px"}>
                                          <Grid item xs={4}>
                                                <Typography
                                                      onClick={() => {
                                                            props?.updateState("publiclist", "None");
                                                      }}
                                                      className={
                                                            props?.data?.publiclist === "None"
                                                                  ? classes.Requirements
                                                                  : classes.Requirementsqfts
                                                      }
                                                >
                                                      None
                                                </Typography>
                                          </Grid>
                                          <Grid item xs={4}>
                                                <Typography
                                                      onClick={() => {
                                                            props?.updateState("publiclist", "Private");
                                                      }}
                                                      className={
                                                            props?.data?.publiclist === "Private"
                                                                  ? classes.Requirements
                                                                  : classes.Requirementsqfts
                                                      }
                                                >
                                                      Private
                                                </Typography>
                                          </Grid>
                                          <Grid item xs={4}>
                                                <Typography
                                                      onClick={() => {
                                                            props?.updateState("publiclist", "Public");
                                                      }}
                                                      className={
                                                            props?.data?.publiclist === "Public"
                                                                  ? classes.Requirements
                                                                  : classes.Requirementsqfts
                                                      }
                                                >
                                                      Public
                                                </Typography>
                                          </Grid>
                                    </Grid>
                                    {props?.data?.error?.publiclist?.length > 0 && (
                                          <span style={{ fontSize: "12px", color: "red" }}>
                                                Public Listing is required
                                          </span>
                                    )}
                              </Grid> */}
                        </Grid>
                  </Box>


                  <div className='map-wrapper'>
                        <Box className={classes.imagebox1} style={{ marginTop: '24px', position: 'relative' }}>
                              <CustomTypography>Address</CustomTypography>
                              {
                                    props?.load &&
                                    <MapWithFields
                                          fullScreenMap={fullScreenMap}
                                          fullScreenControl={fullScreenControl}
                                          mapOptions={{
                                                isInput: true,
                                                center: {
                                                      lat: props?.data?.latitude,
                                                      lng: props?.data?.longitude
                                                },
                                                lat: props?.data?.latitude,
                                                lng: props?.data?.longitude,
                                                mapLoad
                                          }}
                                          mapHeight={"370px"}
                                          breakpoints={{
                                                map: {
                                                      xs: 12,
                                                      sm: 12,
                                                      md: 5,
                                                      lg: 6,
                                                      xl: 6
                                                },
                                                components: {
                                                      xs: 12,
                                                      sm: 12,
                                                      md: 7,
                                                      lg: 6,
                                                      xl: 6
                                                }
                                          }}
                                          mapResult={props?.mapResult}
                                          autoCompletePlacement={{
                                                top: 14,
                                          }}
                                          fields={[
                                                {
                                                      label: "Door Number",
                                                      component: "TextField",
                                                      value: props?.data?.doorNo,
                                                      state_name: 'doorNo',
                                                      isrequired: false,
                                                      placeholder: "Enter Door Number",
                                                      error: props?.data?.error?.doorNo,
                                                      errorMessage: props?.data?.error?.doorNo?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "Address Line 1",
                                                      component: "TextField",
                                                      value: props?.data?.addressLineOne,
                                                      state_name: 'addressLineOne',
                                                      isrequired: false,
                                                      placeholder: "Enter Address Line 1",
                                                      errorMessage: props?.data?.error?.addressLineOne,
                                                      error: props?.data?.error?.addressLineOne?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 4.5
                                                      }
                                                },
                                                {
                                                      label: "Address Line 2",
                                                      component: "TextField",
                                                      value: props?.data?.addressLineTwo,
                                                      state_name: 'addressLineTwo',
                                                      isrequired: false,
                                                      placeholder: "Enter Address Line 2",
                                                      errorMessage: props?.data?.error?.addressLineTwo,
                                                      error: props?.data?.error?.addressLineTwo?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 4.5
                                                      }
                                                },
                                                {
                                                      label: "Landmark",
                                                      component: "TextField",
                                                      value: props?.data?.landmark,
                                                      state_name: 'landmark',
                                                      isrequired: false,
                                                      placeholder: "Enter Landmark",
                                                      errorMessage: props?.data?.error?.landmark,
                                                      error: props?.data?.error?.landmark?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "Area",
                                                      component: "TextField",
                                                      value: props?.data?.area,
                                                      state_name: 'area',
                                                      isrequired: false,
                                                      placeholder: "Enter Area",
                                                      errorMessage: props?.data?.error?.area,
                                                      error: props?.data?.error?.area?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "City",
                                                      component: "TextField",
                                                      value: props?.data?.city,
                                                      state_name: 'city',
                                                      isrequired: false,
                                                      placeholder: "Enter City",
                                                      errorMessage: props?.data?.error?.city,
                                                      error: props?.data?.error?.city?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "State",
                                                      component: "TextField",
                                                      state_name: 'state',
                                                      value: props?.data?.state,
                                                      isrequired: false,
                                                      placeholder: "Enter State",
                                                      errorMessage: props?.data?.error?.state,
                                                      error: props?.data?.error?.state?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "Country",
                                                      component: "TextField",
                                                      value: props?.data?.country,
                                                      state_name: 'country',
                                                      isrequired: false,
                                                      placeholder: "Enter Country",
                                                      errorMessage: props?.data?.error?.country,
                                                      error: props?.data?.error?.country?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "Pincode",
                                                      component: "TextField",
                                                      state_name: 'zipcode',
                                                      value: props?.data?.zipcode,
                                                      isrequired: false,
                                                      placeholder: "Enter Pincode",
                                                      errorMessage: props?.data?.error?.zipcode,
                                                      error: props?.data?.error?.zipcode?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "Latitude",
                                                      component: "TextField",
                                                      state_name: 'latitude',
                                                      value: props?.data?.latitude,
                                                      isrequired: false,
                                                      placeholder: "Enter Latitude",
                                                      errorMessage: props?.data?.error?.latitude,
                                                      error: props?.data?.error?.latitude?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: "Longitude ",
                                                      component: "TextField",
                                                      state_name: 'longitude',
                                                      value: props?.data?.longitude,
                                                      isrequired: false,
                                                      placeholder: "Enter Longitude ",
                                                      errorMessage: props?.data?.error?.longitude,
                                                      error: props?.data?.error?.longitude?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                          ]}
                                          onChangeFields={(key, value) => props?.updateState(key, value)}
                                    />
                              }

                        </Box>
                  </div>

                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              <Grid item xs={12} md={3} lg={3}>
                                    <MobileNumberInputComponent
                                          label={"Business Phone"}
                                          placeholder={"Business Phone"}
                                          value={props?.data?.bussinessmobile}
                                          isRequired
                                          handleChange={(value) => {
                                                props?.updateState("bussinessmobile", value);
                                          }}
                                          isError={props?.data?.error?.bussinessmobile?.length > 0}
                                          errorMessage={props?.data?.error?.bussinessmobile}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                    />
                                    {/* <Typography variant={"caption"} color={"error"}>
                                          {props?.exists?.Bussiness}
                                    </Typography> */}
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <MobileNumberInputComponent
                                          label={"Mobile Phone"}
                                          placeholder={"Mobile Phone"}
                                          value={props?.data?.mobile}
                                          isRequired
                                          handleChange={(value) => {
                                                props?.updateState("mobile", value);
                                          }}
                                          isError={props?.data?.error?.mobile?.length > 0}
                                          errorMessage={props?.data?.error?.mobile}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                    />
                                    {/* <Typography variant={"caption"} color={"error"}>
                                          {props?.exists?.mobile}
                                    </Typography> */}
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <TextBox
                                          label="Website"
                                          placeholder="Enter Website"
                                          value={props?.data?.website ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("website", e.target.value);
                                          }}
                                          isError={props?.data?.error?.website?.length > 0}
                                          errorMessage={props?.data?.error?.website} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <TextBox
                                          label="Email Address"
                                          placeholder="Enter Email Address"
                                          value={props?.data?.email ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("email", e.target.value);
                                          }}
                                          isError={props?.data?.error?.email?.length > 0}
                                          errorMessage={props?.data?.error?.email} />
                              </Grid>
                        </Grid>
                  </Box>
            </div >
      )
}