import React, { useContext, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Subheader, SearchFilter, UseDebounce } from "../../components";
import {
  NetWorkCallMethods,
  AlertProps,
  getRoutePermissionNew,
  accessCheckRender,
  LocalStorageKeys,
} from "../../utils";
import { AuthContext, AlertContext } from "../../contexts";
import { useStyles } from "./style";
import { Box, Grid, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { NewLoader } from "../../components/newLoader";

const Reports = (props) => {
  const { t, loading, handleLoading } = props
  //   context
  const auth = useContext(AuthContext);
  const alert = useContext(AlertContext);
  // styles
  const classes = useStyles();
  const history = useHistory();
  //debounce hook
  const debounce = UseDebounce();
  const [permission, setPermission] = React.useState({})
  const [loader, setLoader] = React.useState(true)
  // -----------------------------------STATES------------------------------------------------------------
  const [company, setCompany] = useState({
    companyList: [],
    SelectedCompany: {},
    searchText: "",
    drawer: false,
    offsetRole: 0,
  });
  const [list, setList] = useState([]);
  // -----------------------------------FUNCTION------------------------------------------------------------
  //   initial company update
  const updateState = (k, v) => {
    setCompany({ ...company, [k]: v });
  };
  //   Search function
  const searchFunction = (e) => {
    updateState("searchText", e);
    debounce(() => getList(0, e), 800);
  };
  const changeRouter = (v) => {
    history.push({
      pathname: Routes.report,
      state: {
        schema: v.schema,
        reportName: v.name,
        reportId: v.id,
        end_point: v.end_point,
        main: {
          company: company?.SelectedCompany,
          category_id: v ?? "",

        },
      },
    });
  };

  // useEffect to get company list for company switcher when loading the screen
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        getList(0);
      }
    }
    // eslint-disable-next-line
  }, [auth])
  //reports get
  const getList = (offSet, search, reload = true) => {
    setLoader(true);
    const payload = {
      offset: offSet,
      limit: 30,
      search: search,
      module_id: Number(localStorage.getItem(LocalStorageKeys.activeRoleModuleId))
    };
    NetworkCall(
      `${config.api_url}/reports/get_module_reports`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        if (reload) {
          setList(res?.data?.data)
        } else {
          setList([...list, ...res?.data?.data])
        }
        handleLoading(false)
        setLoader(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  };
  // const fetchMoreData = () => {
  //   getList(company?.offsetRole + 10, company?.searchText, false);
  //   updateState("offsetRole", company?.offsetRole + 10);
  // };
  const render = () => {
    return (
      <div>
        {" "}
        {/* sub Navbar */}
        <Subheader title={`${t("Reports")}`} hideBackButton={true} />
        {
          loader ? (
            <NewLoader minusHeight="100px" />
          ) : (
            <div className={classes.root}>
              <Grid container className={classes.content} spacing={2}>
                <Grid item xs={3}>
                  <Box>
                    {/* search box */}
                    <SearchFilter
                      value={company?.searchText}
                      placeholder="Search"
                      handleChange={(value) => searchFunction(value)}
                      marginBottom="0px !important"
                    />
                  </Box>
                </Grid>
                {list?.length > 0 ?
                  <Grid item xs={12}>
                    {/* <InfiniteScroll
                      dataLength={list?.length ?? 0}
                      next={false}
                      hasMore={true}
                      height={`calc(100vh - 147px)`}
                    > */}
                    <Grid container spacing={2}>
                      {list?.map((x) => {
                        return (
                          <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Box className={classes.card}>
                              <Box display="flex">
                                <img
                                  src="images/reports_image.svg"
                                  alt={x}
                                  className={classes.imgCard}
                                />
                              </Box>
                              <Box className={classes.displayBox}>
                                <Typography className={classes.catagory}>
                                  {x?.name ?? ""}
                                </Typography>
                                <Typography
                                  className={classes.view}
                                  onClick={() => changeRouter(x)}
                                >
                                  View
                                  <ArrowForwardIosIcon
                                    className={classes.arrowIcon}
                                  />
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                    {/* </InfiniteScroll> */}
                  </Grid>
                  :
                  <Grid item xs={12} className={classes.noData}>
                    <Typography className={classes.titleFont}>
                      {"No Data Found"}
                    </Typography>
                  </Grid>
                }
              </Grid>
            </div>)}
      </div>
    );
  }
  return <div>
    {accessCheckRender(render, permission, "", loading)}
  </div>

};
export default withNamespaces("reports")(Reports);
