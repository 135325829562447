import React, { useLayoutEffect, useState } from "react";
import {
  gateChangetHead,
  gateChangePath,
  NetWorkCallMethods,
  AlertProps,
} from "../../utils";
import { AlertContext } from "../../contexts";
import { GraphComponent } from "../propertymanagerdashboard/components";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";

export const GateInCharge = (props) => {
  // classes
  const [gate, setGate] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  // context
  const alert = React.useContext(AlertContext);

  //Gates In Change
  const getGateinChange = (offset, limit) => {
    const payload = {
      company_id: props?.company?.value,
      offset: offset ?? 0,
      limit: limit ?? 10,
    };
    NetworkCall(
      `${config.api_url}/dashboard/security/gates`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        let result = res?.data?.data?.map((x) => {
          return {
            Image: x?.image_url ?? "-",
            Name: x?.name ?? "-",
            Number: `${x?.mobile_no_country_code} ${x?.mobile_no}`,
          };
        });
        setGate({
          data: result ?? [],
          count: res?.data?.count ?? 0,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  //more options
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getGateinChange(props?.company?.value, offset, limit);
  };
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getGateinChange(props?.company?.value, 0, value);
  };
  useLayoutEffect(() => {
    if (props?.company?.label?.length > 0) {
      getGateinChange(0, 10);
    }
    //eslint-disable-next-line
  }, [props?.company, props?.calandervalue]);
  return (
    <>
      <GraphComponent
        isTable
        title={"Gates In Change"}
        tableData={gate?.data ?? []}
        tabelheight={"calc(100vh - 450px)"}
        heading={gateChangetHead}
        path={gateChangePath}
        handlePagination={handlePagination}
        handleChangeLimit={handleChangeLimit}
        page={page}
        limit={limit}
        showpagination
        totalRowsCount={gate?.count ?? 0}
        dataType={[
          { type: ["avatarmanagement"], name: "Image" },
          { type: ["text"], name: "Name" },
          { type: ["text"], name: "Number" },
        ]}
      />
    </>
  );
};
