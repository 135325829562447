import makeStyles from "@mui/styles/makeStyles";
import {  Regular , SemiBold } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
  tabRoot: {
    padding: "0px",
    background: "white",
    display: "flex",
    alignItems: "center"
  },
  bottom:{
    position:'absolute',
    width:"100%",
    height:"2px",
    bottom:"0",
    left:"0",
    right:"0",
    backgroundColor: theme?.typography?.status?.primary
  },
  root:{
    padding: "0px",
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent:"space-between"
  },
  closeRequest:{
    fontFamily: SemiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
      color: theme?.palette?.background?.tertiary1,
    },
  },
  outline:{
    fontFamily: SemiBold,
    backgroundColor: theme?.palette?.background?.tertiary1,
    color: theme?.palette?.primary?.main,
    border:`1px solid ${theme?.palette?.primary?.main}`,
    "&:hover": {
      backgroundColor: theme?.palette?.background?.tertiary1,
    color: theme?.palette?.primary?.main,
    border:`1px solid ${theme?.palette?.primary?.main}`,
    }, 
  },
  menu: {
    "& .MuiPopover-paper": {
      boxShadow: "0 0px 8px -4px #f2f3f5",
      border: "1px solid #f2f3f5",
      borderRadius: "5px",
    },
  },
  menuItem:{
  borderBottom: "1px solid #E4E8EE",
  margin: "0px 4px",
  fontSize: "14px",
  color: "#071741",
  fontFamily: `${Regular}`,
  padding:"10px",
  cursor:"pointer",
  "&:last-child": {
    border: 0,
  },
},
image: {
  width: "188px",
  height: "188px",
  borderRadius: "4px",
  position: "relative",
  "&:hover": {
    opacity: "16",
    backgroundColor: 'red'
  },
},
caroselRoot: {
  borderRadius: "4px",
  backgroundColor: (props) => props?.backgroundColor ?? "",
  padding: (props) => props?.padding ?? "",
  cursor: "pointer",
},
arrowBtn: {
  backgroundColor: "#fff !important",
  border: "1px solid #CED3DD",
},
arrow: {
  backgroundColor: "#fff",
  width: "24px",
  height: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
  borderRadius: "50%",
  border: "1px solid #CED3DD",
},
prev: {
  position: "absolute",
  zIndex: 1,
  top: "41%",
  left: "12px",
  cursor: "pointer",
},
next: {
  position: "absolute",
  zIndex: 1,
  top: "41%",
  right: "12px !important",
  cursor: "pointer",
},
}));
