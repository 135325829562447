import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
} from "@mui/material";
import { ScreensStylesParent } from "./style";
import { Search, PermissionCard, TextBox } from "../components";
import { TreeComponent } from "../components";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { config } from "../../../config";
import { CustomSelect } from "../components/filterGenerator/components/select";

// alert message context
import { AlertContext, BackdropContext } from "../../../contexts";
import { AlertProps, useWindowDimensions } from "../../../utils";
import InfiniteScroll from "react-infinite-scroll-component";

// add role
const addRoles = {
  Permission: "",
  des: "",
  id: "",
  module: "",
  isEdit: false,
  error: {
    Permission: "",
    des: "",
    id: "",
    module: ""
  },
};
export const PermisionList = (props) => {
  const classes = ScreensStylesParent();
  const size = useWindowDimensions();
  // state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addRole, setaddRole] = useState({ ...addRoles });
  const [permissionID, setPermissionID] = useState({
    id: "",
    data: "",
    structure: [],
  });
  const [permissions, setPermissions] = useState([]);
  const [permissionSearch, setPermissionSearch] = useState("");
  const [disable, setDisable] = useState(false);
  const [repository, setRepository] = useState([]);
  const [toggledisable, setToggleDisable] = useState(true);
  const [offset, setOffset] = useState(0);

  // context
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);

  // dialog close function
  const handleClose = (e) => {
    setDialogOpen(!dialogOpen);
    if (e === "close") {
      setaddRole({ ...addRoles });
    }
  };

  // onChange role
  const roleAddedFunction = (key, value) => {
    if (key === 'module') {
      getRepository(value?.value);
    }
    let error = addRole?.error;
    error[key] = "";
    setaddRole({ ...addRole, [key]: value, error });
  };
  //   add role validation
  const validations = () => {
    let isValid = true;
    let error = addRole.error;
    if (addRole?.Permission?.length === 0) {
      isValid = false;
      error.Permission = "Name is Required";
    }
    if (addRole?.module?.length === 0) {
      isValid = false;
      error.module = "Module is Required";
    }
    setaddRole({ ...addRole, error });

    return isValid;
  };

  // add role
  const addRoleSave = () => {
    if (validations()) {
      const data = {
        name: addRole?.Permission,
        description: addRole?.des,
        module_id: addRole?.module?.value
      };
      if (addRole?.isEdit) {
        data["id"] = addRole?.id;
      } else {
        data["structure"] = repository ?? [];
        data["is_active"] = true;
      }
      axios
        .post(
          `${config?.api_url}/iam/${addRole?.isEdit ? "update_permission" : "create_permission"
          }`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then((res) => {
          getpermission(permissionSearch, offset);
          handleClose();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `Permission ${addRole?.isEdit ? "Updated" : "created"
              } successfully.`,
          });
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong.",
          });
        });
    }
  };

  // click checkbox
  const handleChange = (e) => {
    setPermissionID({ ...permissionID, structure: e });
  };

  // select permission
  const seclectPermission = (e) => {
    setToggleDisable(false);
    setPermissionID({
      id: e?.id,
      data: e,
      structure: e?.structure,
    });
  };

  // edit permission
  const editFunction = (data) => {
    setaddRole({
      ...addRole,
      Permission: data?.name,
      des: data?.description,
      id: data?.id,
      isEdit: true,
      module: data?.module
    });
    handleClose();
  };

  // acive permission
  const activePermission = (e, index) => {
    updateFunction("is_active", e);
  };

  // search list
  const searchPermission = (e) => {
    setPermissionSearch(e);
    getpermission(e, offset);
  };
  //   get permission
  const getpermission = (searchvalue, offset, key, initial) => {
    if (initial) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Loading",
      });
    }
    axios
      .get(
        `${config?.api_url}/iam/get_permissions${searchvalue?.length > 0 ? `?search=${searchvalue}&` : ""
        }?offset=${offset}&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((res) => {
        if (initial) {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "Loading",
          });
        }
        if (key === "scroll") {
          setPermissions(permissions?.concat(res?.data?.data));
        } else {
          setPermissions(res?.data?.data ?? []);
        }
      })
      .catch((err) => {
        if (initial) {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "Loading",
          });
        }
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong.",
        });
      });
  };

  //   get repository
  const getRepository = (e) => {
    axios
      .get(
        `${config?.api_url}/iam/get_repository?module_id=${e ?? 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((res) => {
        setRepository(res?.data?.data);
      })
      .catch((err) => { });
  };

  // update JSON
  const updateFunction = (key, value) => {
    setDisable(true);
    const data = {
      id: permissionID?.id,
    };
    if (key === "structure") {
      data["structure"] = permissionID?.structure ?? [];
    } else if (key === "is_active") {
      data["is_active"] = value ?? true;
    } else {
      data["is_delete"] = true;
    }
    axios
      .post(`${config?.api_url}/iam/update_permission`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        setDisable(false);
        if (key === "is_active" || key === "is_delete") {
          getpermission("", 0, "", false);
        }
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Permission Updated successfully.",
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong.",
        });
        setDisable(false);
      });
  };

  // cancel function
  const cancelFunction = () => {
    setPermissionID({
      id: "",
      data: "",
      structure: [],
    });
    setToggleDisable(false);
  };

  // const deletePermission
  const deletePermission = () => {
    updateFunction("is_delete");
  };

  // infinity scroll
  const fetchMoreData = () => {
    setOffset(offset + 1);
    getpermission(permissionSearch, offset + 1, "scroll", false);
  };

  useEffect(() => {
    getpermission("", 0, "", true);

    // eslint-disable-next-line
  }, []);


  return (
    <div>
      <Grid container>
        <Grid item xs={4}>
          {/* search field */}
          <div className={classes.section}>
            <Search
              title={`${permissions?.length} Permissions`}
              handleAdd={() => handleClose("close")}
              searchFumction={searchPermission}
              search={permissionSearch}
            />
          </div>
        </Grid>
        {/* header bar */}
        <Grid item xs={8} className={classes.borderLeft}>
          <div className={classes.section}>
            <Typography className={classes.screenTitle}>
              {toggledisable ? "Permission" : " Edit Permission"}
            </Typography>
          </div>
          {!toggledisable && (
            <div className={classes.section}>
              <div className={classes.addRoleButton}>
                <Typography className={classes.screenRepository}>
                  Repository
                </Typography>
                <div style={{ display: "flex" }}>
                  {["Create", "Read", "Update", "Delete"]?.map((x) => {
                    return (
                      <div className={classes?.checkBoxDesign}>
                        <Typography className={classes.screenRepository}>
                          {x}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          {/* permission card */}
          <InfiniteScroll
            dataLength={permissions?.length ?? ""}
            next={fetchMoreData}
            hasMore={true}
            height={size?.height - 330}
          >
            <div className={classes.section1}>
              {permissions?.map((x, index) => {
                return (
                  <PermissionCard
                    title={x?.name}
                    data={x}
                    value={false}
                    seclectPermission={seclectPermission}
                    permissionID={permissionID?.id}
                    id={x?.id}
                    editFunction={editFunction}
                    activePermission={activePermission}
                    index={index}
                    deletePermission={deletePermission}
                    disabled={toggledisable}
                  />
                );
              })}
            </div>
          </InfiniteScroll>
        </Grid>
        {/* tree component */}
        <Grid item xs={8} className={classes.borderLeft}>
          <div className={classes.fixedStyle}>
            <div className={classes.section1}>
              <TreeComponent
                TreeJson={permissionID?.structure ?? []}
                handleChange={handleChange}
                previousCheckbox
                isCheckbox
                disabled={toggledisable}
              />
            </div>
          </div>
          {permissionID?.id?.length > 0 && (
            <Box className={classes.bottomButton}>
              <Button
                className={classes.cancelBtn}
                variant="outlined"
                onClick={cancelFunction}
              >
                Cancel
              </Button>
              <Button
                className={classes.saveBtn}
                variant="contained"
                onClick={() => updateFunction("structure")}
                disabled={disable}
              >
                Update
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>

      {/* add pop up */}
      <Dialog
        className={classes.dialog}
        open={dialogOpen}
        onClose={() => handleClose("close")}
        maxWidth="xs"
      >
        <DialogTitle className={classes.header}>
          <span>{addRole?.isEdit ? "Edit" : "Add New"} Permission</span>{" "}
          <CloseIcon
            onClick={() => handleClose("close")}
            style={{ cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogParent}>
            <CustomSelect
              marginBottom={4}
              color={"#98A0AC"}
              fontSize={12}
              required
              fontWeight={400}
              label={"Module"}
              placeholder="Select Module"
              options={props?.moduleOption ?? []}
              onChange={(e) => roleAddedFunction("module", e)}
              value={addRole?.module}
              loading={props?.loading === "module"}
              isPaginate
              debounceTimeout={800}
              loadOptions={props?.loadOptions}
              error={addRole?.error?.module?.length > 0}
              errorText={addRole?.error?.module}
              isReadOnly={addRole?.isEdit ? true : false}
            />
            <Box style={{ height: "10px" }} />
            <TextBox
              placeholder={"Permission Name"}
              value={addRole?.Permission}
              onChange={(e) =>
                roleAddedFunction("Permission", e?.target?.value)
              }
              label={"Permission Name"}
              isrequired
              isError={addRole?.error?.Permission?.length > 0}
              errorMessage={addRole?.error?.Permission}
            />
            <Box style={{ height: "10px" }} />
            <TextBox
              placeholder={"Description"}
              multiline={true}
              value={addRole?.des}
              onChange={(e) => roleAddedFunction("des", e?.target?.value)}
              label={"Description"}
            />
            <Box style={{ height: "10px" }} />
            <Box className={classes.addRoleButton}>
              <Box>
                <Button
                  className={classes.cancelBtn}
                  onClick={() => handleClose("close")}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button className={classes.saveBtn} onClick={addRoleSave}>
                  {addRole?.isEdit ? "Update" : "Save"}
                </Button>
              </Box>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
