import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { ServicesMaster } from "../../components";

const useStyles = makeStyles((theme) => ({
    root: {
      
    },
}));

export const Master = (props) => {
    const classes = useStyles();
  
    return (
        <div className={classes.root}>
           <ServicesMaster/>
        </div>
    );
};
