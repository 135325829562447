import { useApolloClient } from "@apollo/client";
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { CompanyLogoUpload, SelectBox } from "../../../components";
import { AlertContext } from "../../../contexts";
import { UPDATE_COMPANY } from "../../../graphql/clientSettingsQueries";
import { AlertProps } from "../../../utils";
import { logoState } from "../../../utils/clientsettings";
import { useStyles } from "../styles";
export const LogoUploadForm = ({ company = {}, reload = () => false, onClose = () => false }) => {
    const [data, setData] = React.useState({ ...logoState });
    const alert = React.useContext(AlertContext);
    const client = useApolloClient();
    const classes = useStyles()
    //validate
    const validateForm = () => {
        let isValid = true;
        let error = data.error;
        if (data?.logo?.length === 0 || !data?.logo?.src) {
            isValid = false;
            error.logo = "Logo is Required";
        }
        setData({ ...data, error });
        return isValid;
    }
    React.useEffect(() => {
        setData({ ...data, logo: { src: company?.logo } })
        // eslint-disable-next-line
    }, [])
    //update data
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    }
    //on submit form
    const onSubmit = () => {

        if (validateForm()) {
            client.mutate({
                mutation: UPDATE_COMPANY,
                variables: {
                    id: company?.id,
                    data: {
                        logo: data?.logo?.src
                    },
                }
            }).then(rs => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Logo Successfully Updated",
                });
                reload(10, 0)
                onClose()
            }).catch(er => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                });
            })


        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please Fill All Mandatory Field",
            });
        }
    }
    return (
        <Box>
            <Grid container>
                <Grid item xs={6}>
                    <Box p={2}>
                        <CompanyLogoUpload
                            label="Upload Image here"
                            data={data?.logo}
                            handleChange={(value) => updateState("logo", value)}
                        />
                        {
                            data?.error?.logo?.length > 0 &&
                            <Typography variant={"caption"} color={"error"}>
                                {data?.error?.logo}
                            </Typography>
                        }

                        <Box height="10px" />
                        <SelectBox label="Company Name"
                            isReadOnly
                            value={{ value: company?.id, label: company?.name }}
                        />
                        <Box height="34px" />
                        <Button variant="contained" fullWidth onClick={onSubmit}>
                            Save
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box p={2}>
                        <Typography className={classes.labellogo}>WEB APP</Typography>
                        <Box className={classes.imagepreviewRoot}>
                            {
                                data?.logo?.src &&
                                <img className={classes.logo} src={data?.logo?.src} alt="" />
                            }
                            <img className={classes.previewImg} src="/images/Group 99604.svg" alt="" />
                        </Box>
                        <Box height="10px" />
                        <Typography className={classes.labellogo}>MOBILE APP</Typography>
                        <Box className={classes.imagepreviewRoot}>
                            {
                                data?.logo?.src &&
                                <img className={classes.logo1} src={data?.logo?.src} alt="" />
                            }
                            <img className={classes.previewImg} src="/images/Group 99609.svg" alt="" />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}