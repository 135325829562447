import { useApolloClient } from "@apollo/client";
import moment from "moment";
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// import { Assests } from './tabs/assests'
import CityProperty from '../../assets/cityproperty';
import ImagesProperty from '../../assets/imagesproperty';
import { PropertyTabs, Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { ADD_VACANCY_PERIOD_FOR_UNIT } from '../../graphql/mutations';
import { GET_PROPERTY_BY_FLOOR_ID, GET_UNIT, GET_UNIT_CATEGORY_MASTER, PROPERTY_DETAILS_BY_BLOCK_ID, PROPERTY_DETAILS_BY_POPERTY_ID } from '../../graphql/queries';
import { NetworkCall } from '../../networkcall';
import { accessCheckRender, AlertProps, enumName, enumSelect, enum_types, ListingTypeOptions, LocalStorageKeys, NetWorkCallMethods, unitCategoryMaster } from "../../utils";
import { assestType, getCustomBlockName, getCustomFloorName, getPropertyHierarchyUnitRule, getRoutePermissionNew, mapResult, singleImageUpload } from '../../utils/common';
import { Assests } from '../propertcreation/createproperty/assests';
import { useStylesCreation } from "./tabs/style";
import { UnitDetails } from './tabs/unitDetails';

const initial = {
      propertyID: "",
      blockID: "",
      pet_policy: { value: "Not Allowed", label: "Not Allowed" },
      execution_status: { value: "Plan", label: "Plan" },
      floorID: "",
      unitID: "",
      image: "",
      company: "",
      unitName: "",
      status: { value: true, label: "Active" },
      description: "",
      propertyType: "",
      unitPurpose: "",
      revenueType: "",
      unitCategory: "",
      parentUnitID: "",
      unitFurnishingType: { label: "Unfurnished", value: "Unfurnished" },
      unitType: "",
      unitCurrentStatus: { label: "Vacant", value: "Vacant" },
      measurementUnit: "",
      carpetArea: "",
      totalArea: "",
      govtUtilityNumbers: "",
      yearBuilt: "",
      handoverDate: "",
      orientation: { label: "None", value: "None" },
      annualRentOrSalesAmount: "",
      numberOfRoom: "",
      numberOfBed: "",
      numberOfBaths: "",
      paymentPeriod: "",
      publicListing: ListingTypeOptions[0],
      petsAllowed: false,
      doorNo: "",
      addressLineOne: "",
      addressLineTwo: "",
      landmark: "",
      area: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      latitude: "",
      longitude: "",
      businessPhone: "",
      mobilePhone: "",
      website: "",
      emailAddress: "",
      isBlock: "",
      isFloor: "",
      showAllUnits: false,
      noOfBalcony: "",
      balconyArea: "",
      ownerAccount: "",
      buildupArea: "",
      terraceArea: "",
      assetFunc: { value: true, label: "Yes" },
      assetProperty: { value: true, label: "Yes" },
      shortTermRental: { value: true, label: "Yes" },
      underPromotion: { value: true, label: "Yes" },
      error: {
            image: "",
            company: "",
            unitName: "",
            description: "",
            propertyType: "",
            unitPurpose: "",
            revenueType: "",
            unitCategory: "",
            parentUnitID: "",
            unitFurnishingType: "",
            unitType: "",
            measurementUnit: "",
            carpetArea: "",
            totalArea: "",
            govtUtilityNumbers: "",
            yearBuilt: "",
            handoverDate: "",
            orientation: "",
            annualRentOrSalesAmount: "",
            numberOfRoom: "",
            numberOfBed: "",
            numberOfBaths: "",
            paymentPeriod: "",
            businessPhone: "",
            mobilePhone: "",
            website: "",
            emailAddress: "",
            buildupArea: "",
            terraceArea: "",
            assetFunc: "",
            assetProperty: "",
            shortTermRental: "",
            underPromotion: "",
      }
}

export const CreateUnit = () => {
      const location = useLocation();
      const client = useApolloClient();
      const main = location?.state?.main ?? null;
      const backdrop = React.useContext(BackdropContext);
      const [data, setData] = React.useState(JSON.parse(JSON.stringify(initial)))
      const [value, setValue] = React.useState(1);
      const [images, setImages] = React.useState([]);
      const [deletedAssetURL, setDeletedAssetURL] = React.useState([]);
      const [mapLoad, setMapLoad] = React.useState(false)
      const alert = React.useContext(AlertContext);
      const auth = React.useContext(AuthContext)
      const history = useHistory();
      const classes = useStylesCreation();
      const [load, setLoad] = React.useState(false)
      const [initialLoad, setInitialLoad] = React.useState(false)
      const [permission, setPermission] = React.useState({})
      const [disable, setDisable] = React.useState(false)
      const [options, setOptions] = React.useState({})
      // use effect to get permission
      React.useEffect(() => {
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                  setPermission(perm)
                  if (perm?.read) {
                        if (initialLoad) {
                              if (main?.unitID) {
                                    getUnitDetails();
                              } else if (main?.floorID) {
                                    getPropertyDetailsByFloorID(main?.floorID)
                              } else if (main?.blockID) {
                                    getPropertyByBlockID(main?.blockID)
                              } else if (main?.propertyID) {
                                    getPropertyById(main?.propertyID)
                              }
                        }
                  }
            }
            // eslint-disable-next-line
      }, [auth, initialLoad,options]);

      React.useEffect(() => {
            getEnum()
            // eslint-disable-next-line
      }, [])

      const getUnitDetails = () => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "",
            });
            client.query({
                  query: GET_UNIT,
                  fetchPolicy: 'network-only',
                  variables: {
                        unitID: main?.unitID
                  }
            }).then((response) => {
                  let tempUnit = response?.data?.unit?.[0] ?? null;
                  setImages(response?.data?.assets);
                  let tempUnitCategory = ""
                  tempUnitCategory = tempUnit?.unit_categoryByID?.value ?
                        { ...tempUnit?.unit_categoryByID, ...unitCategoryMaster[tempUnit?.unit_categoryByID?.value] } : ""
                  setData({
                        ...data,
                        propertyID: tempUnit?.property_id,
                        // blockID: tempUnit?.block_id,
                        // floorID: tempUnit?.floor_id,
                        blockID: {
                              label: tempUnit?.block?.label,
                              value: tempUnit?.block?.value,
                        },
                        floorID: {
                              label: tempUnit?.floor?.label,
                              value: tempUnit?.floor?.value,
                        },
                        unitID: tempUnit?.id,
                        image: tempUnit?.logo,
                        property_hierarchy: tempUnit?.propertyByID?.property_hierarchyByID,
                        company: tempUnit?.propertyByID?.companyByID ?? "",
                        unitName: tempUnit?.name ?? "",
                        status: { value: tempUnit?.is_active ? true : false, label: tempUnit?.is_active ? "Active" : "In-Active" },
                        description: tempUnit?.description ?? "",
                        propertyType: tempUnit?.propertyByID?.property_groupByID ?? "",
                        unitPurpose: {label:tempUnit?.unit_purpose,value:tempUnit?.unit_purpose},
                        revenueType: tempUnit?.revenue_type ?
                              { value: tempUnit?.revenue_type, label: tempUnit?.revenue_type } : "",
                        unitCategory: tempUnitCategory,
                        unitFurnishingType: tempUnit?.furnishing ?
                              { value: tempUnit?.furnishing, label: tempUnit?.furnishing } : "",
                        unitType: tempUnit?.unit_type ?? "",
                        unitCurrentStatus: tempUnit?.status ?
                              { value: tempUnit?.status, label: tempUnit?.status } : "",
                        measurementUnit: tempUnit?.uomByID ? {
                              label: tempUnit?.uomByID?.label,
                              value: tempUnit?.uomByID?.value
                        } : "",
                        carpetArea: tempUnit?.carpet_area ?? "",
                        totalArea: tempUnit?.total_area ?? "",
                        govtUtilityNumbers: tempUnit?.govt_utility_no ?? "",
                        yearBuilt: new Date(tempUnit?.year_built) ?? "",
                        handoverDate: new Date(tempUnit?.planned_hand_over_date) ?? "",
                        orientation: tempUnit?.orientation ?
                              { value: tempUnit?.orientation ?? "", label: tempUnit?.orientation ?? "" } : "",
                        annualRentOrSalesAmount: tempUnit?.lease_or_sale_value ?? "",
                        numberOfRoom: tempUnit?.total_rooms ?? "",
                        numberOfBed: tempUnit?.total_bed_rooms ?? "",
                        numberOfBaths: tempUnit?.total_baths ?? "",
                        paymentPeriod: tempUnit?.payment_period ?
                              { value: tempUnit?.payment_period ?? "", label: tempUnit?.payment_period ?? "" } : "",
                        publicListing: tempUnit?.listing_status ?
                              { value: tempUnit?.listing_status ?? "", label: tempUnit?.listing_status ?? "" } : "",
                        pet_policy: tempUnit?.pet_policy ?
                              { value: tempUnit?.pet_policy ?? "", label: tempUnit?.pet_policy ?? "" } : "",
                        execution_status: tempUnit?.execution_status ?
                              { value: tempUnit?.execution_status ?? "", label: tempUnit?.execution_status ?? "" } : "",
                        petsAllowed: tempUnit?.is_pet_allowed ?? "",
                        parentUnitID: (tempUnit?.parentUnit && tempUnit?.parentUnit?.length > 0) ?
                              { value: tempUnit?.parentUnit?.[0]?.id, label: tempUnit?.parentUnit?.[0]?.name + " - " + tempUnit?.parentUnit?.[0]?.unit_no } : "",
                        doorNo: tempUnit?.addressByID?.door_no ?? "",
                        addressLineOne: tempUnit?.addressByID?.street_1 ?? "",
                        addressLineTwo: tempUnit?.addressByID?.street_2 ?? "",
                        landmark: tempUnit?.addressByID?.landmark ?? "",
                        area: tempUnit?.addressByID?.area ?? "",
                        city: tempUnit?.addressByID?.city ?? "",
                        state: tempUnit?.addressByID?.state ?? "",
                        country: tempUnit?.addressByID?.country ?? "",
                        zipcode: tempUnit?.addressByID?.zipcode ?? "",
                        latitude: parseFloat(tempUnit?.addressByID?.latitude) ?? "",
                        longitude: parseFloat(tempUnit?.addressByID?.longitude) ?? "",
                        businessPhone: {
                              mobile: tempUnit?.addressByID?.mobile_no ?? "",
                              mobile_code: tempUnit?.addressByID?.mobile_no_country_code ?? "",
                        },
                        mobilePhone: {
                              mobile: tempUnit?.addressByID?.alternative_mobile_no ?? "",
                              mobile_code: tempUnit?.addressByID?.alternative_mobile_no_country_code ?? "",
                        },
                        website: tempUnit?.propertyByID?.website ?? "",
                        emailAddress: tempUnit?.addressByID?.email ?? "",
                        noOfBalcony: tempUnit?.balconies,
                        balconyArea: tempUnit?.balcony_area,
                        ownerAccount: tempUnit?.ownerByID ?? tempUnit?.propertyByID?.ownerByID ?? "",
                        buildupArea: tempUnit?.build_up_area ?? "",
                        terraceArea: tempUnit?.terrace_area ?? "",
                        assetFunc: {
                              value: tempUnit?.is_asset_functional_location ? true : false,
                              label: tempUnit?.is_asset_functional_location ? "Yes" : "No"
                        },
                        assetProperty: {
                              value: tempUnit?.is_asset_property ? true : false,
                              label: tempUnit?.is_asset_property ? "Yes" : "No"
                        },
                        shortTermRental: {
                              value: tempUnit?.is_short_term_rental ? true : false,
                              label: tempUnit?.is_short_term_rental ? "Yes" : "No"
                        },
                        underPromotion: {
                              value: tempUnit?.is_under_promotion ? true : false,
                              label: tempUnit?.is_under_promotion ? "Yes" : "No"
                        },
                        error: initial?.error,
                  })
                  setLoad(true)
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch((err) => {


                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Something went wrong please try again",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                  });

                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            });
      }

      const getPropertyDetailsByFloorID = (floor) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "",
            });

            let floorValue = typeof floor === "string" ? floor : floor?.value

            client.query({
                  query: GET_PROPERTY_BY_FLOOR_ID,
                  fetchPolicy: 'network-only',
                  variables: {
                        floorID: floorValue
                  }
            }).then((response) => {
                  let tempFloor = response?.data?.floor?.[0] ?? null;
                  if (getPropertyHierarchyUnitRule(tempFloor?.propertyByID?.property_hierarchyByID, tempFloor?.unit?.[0]?.count)) {
                        setData({
                              ...data,
                              isBlock: tempFloor?.propertyByID?.is_block,
                              isFloor: tempFloor?.propertyByID?.is_floor,
                              company: tempFloor?.propertyByID?.companyByID,
                              propertyID: tempFloor?.property_id,
                              blockID: {
                                    label: tempFloor?.block?.label,
                                    value: tempFloor?.block?.value,
                              },
                              floorID: {
                                    value: tempFloor?.id,
                                    label: tempFloor?.name,
                              },
                              unitID: null,
                              image: tempFloor?.logo,
                              propertyHierarchy: tempFloor?.propertyByID?.property_hierarchy,
                              property_hierarchy: tempFloor?.propertyByID?.property_hierarchyByID,
                              propertyType: tempFloor?.propertyByID?.property_groupByID,
                              unitPurpose: tempFloor?.floor_purpose ? {label:tempFloor?.floor_purpose,value:tempFloor?.floor_purpose} :"",
                              paymentPeriod: {
                                    label: tempFloor?.propertyByID?.payment_period,
                                    value: tempFloor?.propertyByID?.payment_period,
                              },
                              revenueType: {
                                    label: tempFloor?.revenue_type,
                                    value: tempFloor?.revenue_type,
                              },
                              measurementUnit: tempFloor?.propertyByID?.uomByID ? {
                                    label: tempFloor?.propertyByID?.uomByID?.label,
                                    value: tempFloor?.propertyByID?.uomByID?.value
                              } : "",
                              doorNo: tempFloor?.addressByID?.door_no ?? "",
                              addressLineOne: tempFloor?.addressByID?.street_1 ?? "",
                              addressLineTwo: tempFloor?.addressByID?.street_2 ?? "",
                              landmark: tempFloor?.addressByID?.landmark ?? "",
                              area: tempFloor?.addressByID?.area ?? "",
                              city: tempFloor?.addressByID?.city ?? "",
                              yearBuilt: new Date(tempFloor?.year_built) ?? "",
                              handoverDate: new Date(tempFloor?.planned_hand_over_date) ?? "",
                              state: tempFloor?.addressByID?.state ?? "",
                              country: tempFloor?.addressByID?.country ?? "",
                              zipcode: tempFloor?.addressByID?.zipcode ?? "",
                              latitude: parseFloat(tempFloor?.addressByID?.latitude) ?? "",
                              longitude: parseFloat(tempFloor?.addressByID?.longitude) ?? "",
                              businessPhone: {
                                    mobile: tempFloor?.addressByID?.mobile_no ?? "",
                                    mobile_code: tempFloor?.addressByID?.mobile_no_country_code ?? "",
                              },
                              mobilePhone: {
                                    mobile: tempFloor?.addressByID?.alternative_mobile_no ?? "",
                                    mobile_code: tempFloor?.addressByID?.alternative_mobile_no_country_code ?? "",
                              },
                              website: tempFloor?.propertyByID?.website ?? "",
                              emailAddress: tempFloor?.propertyByID?.email ?? "",
                              error: initial?.error,
                        })
                        setLoad(true)
                  } else {
                        alert.setSnack({
                              ...alert, open: true, severity: AlertProps.severity.info,
                              msg: "Unit creation is restricted.",
                        });
                        history.goBack(-1)
                  }
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch((err) => {


                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Something went wrong please try again1",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                  });

                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            });
      }

      const getPropertyByBlockID = (block) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "",
            });

            let blockValue = typeof block === "string" ? block : block?.value

            client.query({
                  query: PROPERTY_DETAILS_BY_BLOCK_ID,
                  fetchPolicy: 'network-only',
                  variables: {
                        blockID: blockValue
                  }
            }).then((response) => {
                  let tempBlock = response?.data?.block?.[0] ?? null;
                  if (getPropertyHierarchyUnitRule(tempBlock?.propertyByID?.property_hierarchyByID, tempBlock?.unit?.[0]?.count)) {
                        setData({
                              ...data,
                              isBlock: tempBlock?.propertyByID?.is_block,
                              isFloor: tempBlock?.propertyByID?.is_floor,
                              company: tempBlock?.propertyByID?.companyByID,
                              propertyID: tempBlock?.property_id,
                              blockID: {
                                    value: tempBlock?.id,
                                    label: tempBlock?.name,
                              },
                              floorID: null,
                              unitID: null,
                              image: tempBlock?.logo,
                              propertyHierarchy: tempBlock?.propertyByID?.property_hierarchy,
                              property_hierarchy: tempBlock?.propertyByID?.property_hierarchyByID,
                              propertyType: tempBlock?.propertyByID?.property_groupByID,
                              unitPurpose: tempBlock?.block_purpose ? {label:tempBlock?.block_purpose,value:tempBlock?.block_purpose} : "",
                              paymentPeriod: {
                                    label: tempBlock?.propertyByID?.payment_period,
                                    value: tempBlock?.propertyByID?.payment_period,
                              },
                              revenueType: {
                                    label: tempBlock?.revenue_type,
                                    value: tempBlock?.revenue_type,
                              },
                              measurementUnit: tempBlock?.propertyByID?.uomByID ? {
                                    label: tempBlock?.propertyByID?.uomByID?.label,
                                    value: tempBlock?.propertyByID?.uomByID?.value
                              } : "",
                              doorNo: tempBlock?.addressByID?.door_no ?? "",
                              addressLineOne: tempBlock?.addressByID?.street_1 ?? "",
                              addressLineTwo: tempBlock?.addressByID?.street_2 ?? "",
                              landmark: tempBlock?.addressByID?.landmark ?? "",
                              area: tempBlock?.addressByID?.area ?? "",
                              city: tempBlock?.addressByID?.city ?? "",
                              yearBuilt: new Date(tempBlock?.year_built) ?? "",
                              handoverDate: new Date(tempBlock?.planned_hand_over_date) ?? "",
                              state: tempBlock?.addressByID?.state ?? "",
                              country: tempBlock?.addressByID?.country ?? "",
                              zipcode: tempBlock?.addressByID?.zipcode ?? "",
                              latitude: parseFloat(tempBlock?.addressByID?.latitude) ?? "",
                              longitude: parseFloat(tempBlock?.addressByID?.longitude) ?? "",
                              businessPhone: {
                                    mobile: tempBlock?.addressByID?.mobile_no ?? "",
                                    mobile_code: tempBlock?.addressByID?.mobile_no_country_code ?? "",
                              },
                              mobilePhone: {
                                    mobile: tempBlock?.addressByID?.alternative_mobile_no ?? "",
                                    mobile_code: tempBlock?.addressByID?.alternative_mobile_no_country_code ?? "",
                              },
                              website: tempBlock?.propertyByID?.website ?? "",
                              emailAddress: tempBlock?.propertyByID?.email ?? "",
                              error: initial?.error,
                        })
                        setLoad(true)
                  } else {
                        alert.setSnack({
                              ...alert, open: true, severity: AlertProps.severity.info,
                              msg: "Unit creation is restricted.",
                        });
                        history.goBack(-1)
                  }
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch((err) => {


                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Something went wrong please try again1",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                  });

                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            });
      }

      const getPropertyById = (property) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "",
            });
            client.query({
                  query: PROPERTY_DETAILS_BY_POPERTY_ID,
                  fetchPolicy: 'network-only',
                  variables: {
                        propertyID: property,
                  }
            }).then((response) => {
                  let tempProperty = response?.data?.property?.[0] ?? null;
                  if (getPropertyHierarchyUnitRule(tempProperty?.property_hierarchyByID, tempProperty?.unit_count?.[0]?.count_id)) {
                        setData({
                              ...data,
                              isBlock: tempProperty?.is_block,
                              isFloor: tempProperty?.is_floor,
                              company: tempProperty?.company,
                              image: tempProperty?.logo,
                              propertyID: tempProperty?.id,
                              propertyType: tempProperty?.property_groupByID ?
                                    {
                                          value: tempProperty?.property_groupByID?.id,
                                          label: tempProperty?.property_groupByID?.group_name
                                    } : "",
                              unitPurpose: tempProperty?.property_purpose ? {
                                    value: tempProperty?.property_purpose ?? "",
                                    label: tempProperty?.property_purpose ?? ""
                              } : "",
                              propertyHierarchy: tempProperty?.property_hierarchy,
                              property_hierarchy: tempProperty?.property_hierarchyByID,
                              paymentPeriod: {
                                    label: tempProperty?.payment_period,
                                    value: tempProperty?.payment_period,
                              },
                              revenueType: {
                                    label: tempProperty?.revenue_type,
                                    value: tempProperty?.revenue_type,
                              },
                              measurementUnit: tempProperty?.uomByID ? {
                                    label: tempProperty?.uomByID?.label,
                                    value: tempProperty?.uomByID?.value
                              } : "",
                              doorNo: tempProperty?.address?.door_no ?? "",
                              addressLineOne: tempProperty?.address?.street_1 ?? "",
                              addressLineTwo: tempProperty?.address?.street_2 ?? "",
                              landmark: tempProperty?.address?.landmark ?? "",
                              area: tempProperty?.address?.area ?? "",
                              city: tempProperty?.address?.city ?? "",
                              yearBuilt: new Date(tempProperty?.year_built) ?? "",
                              handoverDate: new Date(tempProperty?.planned_hand_over_date) ?? "",
                              state: tempProperty?.address?.state ?? "",
                              country: tempProperty?.address?.country ?? "",
                              zipcode: tempProperty?.address?.zipcode ?? "",
                              latitude: parseFloat(tempProperty?.address?.latitude) ?? "",
                              longitude: parseFloat(tempProperty?.address?.longitude) ?? "",
                              businessPhone: {
                                    mobile: tempProperty?.business_phone ?? "",
                                    mobile_code: tempProperty?.business_phone_country_code ?? "",
                              },
                              mobilePhone: {
                                    mobile: tempProperty?.mobile_phone ?? "",
                                    mobile_code: tempProperty?.mobile_country_code ?? "",
                              },
                              website: tempProperty?.website ?? "",
                              emailAddress: tempProperty?.email ?? "",
                              ownerAccount: tempProperty?.ownerByID ?? "",
                              error: initial?.error,
                        })
                        setLoad(true)
                  } else {
                        alert.setSnack({
                              ...alert, open: true, severity: AlertProps.severity.info,
                              msg: "Unit creation is restricted.",
                        });
                        history.goBack(-1)
                  }
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Something went wrong please try again",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                  });
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            });
      };

      const handleAssetFile = (data) => {
            if (data.asset_type === 3) {
                  setDeletedAssetURL([...deletedAssetURL, data.id].filter(Boolean));
                  setImages(images?.filter((val) => val?.file_meta?.id !== data?.file_meta?.id));
            } else {
                  setDeletedAssetURL([...deletedAssetURL, data.id].filter(Boolean));
                  setImages(images?.filter((val) => val?.url !== data?.url));
            }
      };
      const updateState = (k, v) => {

            let error = data?.error;

            error[k] = "";

            if (k === "unitPurpose") {
                  setData({
                        ...data, [k]: v, unitCategory: "", parentUnitID: "", numberOfRoom: "", numberOfBed: "",
                        numberOfBaths: "", carpetArea: "", buildupArea: "", totalArea: "", noOfBalcony: "",
                        balconyArea: "", terraceArea: "", unitFurnishingType: initial.unitFurnishingType,
                        orientation: initial.orientation, pet_policy: initial.pet_policy,
                        publicListing: initial?.publicListing, error
                  });
            } else if (k === "revenueType") {
                  setData({ ...data, [k]: v, handoverDate: "", error });
            } else if (k === "unitCategory") {
                  let tempData = {
                        ...data, [k]: v, parentUnitID: "", numberOfRoom: "", numberOfBed: "",
                        numberOfBaths: "", carpetArea: "", buildupArea: "", totalArea: "", noOfBalcony: "",
                        balconyArea: "", terraceArea: "", unitFurnishingType: initial.unitFurnishingType,
                        orientation: initial.orientation, pet_policy: initial.pet_policy,
                        publicListing: initial?.publicListing, error
                  }
                  switch (v.value) {
                        case 6: // Bed Space
                              setData({ ...tempData, numberOfRoom: "1", numberOfBed: "1", numberOfBaths: "1" });
                              break;
                        default: // Default
                              setData({ ...tempData });
                              break;
                  }
            } else if (k === "numberOfRoom") {
                  if (data?.unitCategory.value === 2) { // Residential Unit
                        setData({ ...data, [k]: v, numberOfBed: v, error });
                  } else { setData({ ...data, [k]: v, error }); }
            } else { setData({ ...data, [k]: v, error }); }
      }

      const validate = () => {
            let isValid = true;
            let error = data.error;

            if (data?.property_hierarchy?.is_block) {
                  if (!main?.unitID && (Boolean(main?.propertyID) && !(!!data?.blockID?.value))) {
                        isValid = false;
                        error.blockID = getCustomBlockName(data?.property_hierarchy) + " is Required";
                  }
            }

            if (data?.property_hierarchy?.is_floor) {
                  if (!main?.unitID && ((Boolean(main?.propertyID) || Boolean(main?.blockID)) && !(!!data?.floorID?.value))) {
                        isValid = false;
                        error.floorID = getCustomFloorName(data?.property_hierarchy) + " is Required";
                  }
            }

            if (data?.image?.length === 0) { isValid = false; error.image = "Unit Image is Required"; }

            if (data?.company?.length === 0) { isValid = false; error.company = "Company Name is Required"; }

            if (data?.unitName?.length === 0) { isValid = false; error.unitName = "Unit Name is Required"; }

            if (data?.status?.length === 0) { isValid = false; error.status = "Status is Required"; }

            if (data?.unitCurrentStatus?.length === 0) { isValid = false; error.unitCurrentStatus = "Occupancy Status is Required"; }

            if (data?.propertyType?.length === 0) { isValid = false; error.propertyType = "Property Type is Required"; }

            if (data?.unitPurpose?.length === 0) { isValid = false; error.unitPurpose = "Unit Purpose is Required"; }

            if (data?.revenueType?.length === 0) { isValid = false; error.revenueType = "Revenue Type is Required"; }

            if (data?.paymentPeriod?.length === 0) { isValid = false; error.paymentPeriod = "Payment Period is Required"; }

            if (data?.unitCategory?.length === 0) { isValid = false; error.unitCategory = "Unit Category is Required"; }

            if (data?.unitCategory?.field_permission?.is_parent_unit_id && data?.parentUnitID?.length === 0) {
                  isValid = false; error.parentUnitID = "Parent Unit ID is Required";
            }

            if (data?.yearBuilt?.length === 0) { isValid = false; error.yearBuilt = "Year Built is Required"; }

            if (data?.revenueType?.value === enumName.sale && data?.handoverDate?.length === 0) {
                  isValid = false; error.handoverDate = "Handover Date is Required";
            }

            if (data?.unitType?.length === 0) { isValid = false; error.unitType = "Unit Type ID is Required"; }

            if (data?.unitCategory?.field_permission?.is_total_rooms) {
                  if (data?.numberOfRoom?.length === 0) {
                        isValid = false; error.numberOfRoom = "No. of Room is Required";
                  } else if ((parseFloat(data?.numberOfRoom) - parseInt(data?.numberOfRoom) > 0)) {
                        isValid = false; error.numberOfRoom = "Enter Valid no. of Room";
                  } else if (parseInt(data?.numberOfRoom) < 1) {
                        isValid = false; error.numberOfRoom = "Should not be less than 1";
                  }
            }

            if (data?.unitCategory?.field_permission?.is_total_baths) {
                  if (data?.numberOfBaths?.length === 0) {
                        isValid = false; error.numberOfBaths = "No. of Baths is Required";
                  } else if ((parseFloat(data?.numberOfBaths) - parseInt(data?.numberOfBaths) > 0)) {
                        isValid = false; error.numberOfBaths = "Enter Valid no. of Baths";
                  } else if (parseInt(data?.numberOfBaths) < 1) {
                        isValid = false; error.numberOfBaths = "Should not be less than 1";
                  }
            }

            if (data?.measurementUnit?.length === 0) { isValid = false; error.measurementUnit = "Measurement Unit is Required"; }

            if (data?.unitCategory?.field_permission?.is_carpet_area) {
                  if (data?.carpetArea?.length === 0) {
                  } else if (parseFloat(data?.carpetArea) <= 0) {
                        isValid = false; error.carpetArea = "Should not be less than or equal 0";
                  } else if (parseFloat(data?.carpetArea) > parseFloat(data?.buildupArea)) {
                        isValid = false; error.carpetArea = "Should not be greater than Buildup Area";
                  } else { error.carpetArea = ""; }
            }

            if (data?.unitCategory?.field_permission?.is_build_up_area) {
                  if (data?.buildupArea?.length === 0) {
                  } else if (parseFloat(data?.buildupArea) <= 0) {
                        isValid = false; error.buildupArea = "Should not be less than or equal 0";
                  } else if (parseFloat(data?.buildupArea) > parseFloat(data?.totalArea)) {
                        isValid = false; error.buildupArea = "Should not be greater than Super Buildup Area";
                  } else { error.buildupArea = ""; }
            }

            if (data?.unitCategory?.field_permission?.is_total_area) {
                  if (data?.totalArea?.length === 0) {
                        isValid = false; error.totalArea = "Super Buildup Area is Required";
                  } else if (parseFloat(data?.totalArea) <= 0) {
                        isValid = false; error.totalArea = "Should not be less than or equal 0";
                  } else if (parseFloat(data?.totalArea) < parseFloat(data?.buildupArea)) {
                        isValid = false; error.totalArea = "Should not be less than Buildup Area";
                  } else { error.totalArea = ""; }
            }

            if (data?.unitCategory?.field_permission?.is_balconies) {
                  if (data?.noOfBalcony?.length === 0) {
                  } else if ((parseFloat(data?.noOfBalcony) - parseInt(data?.noOfBalcony) > 0)) {
                        isValid = false; error.noOfBalcony = "Enter Valid no. of Balcony";
                  } else if (parseInt(data?.noOfBalcony) < 0) {
                        isValid = false; error.noOfBalcony = "Should not be less than 0";
                  }
            }

            if (data?.unitCategory?.field_permission?.is_balcony_area && data?.noOfBalcony > 0) {
                  if (data?.balconyArea?.length === 0) {
                        isValid = false; error.balconyArea = "Balcony Area is Required";
                  } else if (parseFloat(data?.balconyArea) <= 0) {
                        isValid = false; error.balconyArea = "Should not be less than or equal 0";
                  }
            }

            if (data?.unitCategory?.field_permission?.is_terrace_area) {
                  if (data?.terraceArea?.length === 0) {
                  } else if (parseFloat(data?.terraceArea) <= 0) {
                        isValid = false; error.terraceArea = "Should not be less than or equal 0";
                  }
            }

            if (data?.unitCategory?.field_permission?.is_furnishing && data?.unitFurnishingType?.length === 0) {
                  isValid = false; error.unitFurnishingType = "Unit Furnishing Type ID is Required";
            }

            if (data?.execution_status?.length === 0) { isValid = false; error.execution_status = "Execution Status is Required"; }
            if (data?.pet_policy?.length === 0) { isValid = false; error.pet_policy = "Pet Policy is Required"; }

            // if (data?.assetFunc?.length === 0) { isValid = false; error.assetFunc = "Asset Functional Location is Required"; }

            // if (data?.assetProperty?.length === 0) { isValid = false; error.assetProperty = "Asset Property is Required"; }

            if (data?.revenueType?.value === enumName.lease && data?.shortTermRental?.length === 0) {
                  isValid = false; error.shortTermRental = "Short Term Rental is Required";
            }

            if (data?.underPromotion?.length === 0) { isValid = false; error.underPromotion = "Under Promotion is Required"; }

            if (data?.unitCategory?.field_permission?.is_listing_status && data?.publicListing?.length === 0) {
                  isValid = false; error.publicListing = "Public Listing is Required";
            }

            if (data?.businessPhone?.length === 0) { isValid = false; error.businessPhone = "Business Phone is Required"; }

            if (data?.mobilePhone?.length === 0) { isValid = false; error.mobilePhone = "Mobile Phone is Required"; }

            if (data?.website?.length === 0) { isValid = false; error.website = "Website is Required"; }

            if (data?.emailAddress?.length === 0) { isValid = false; error.emailAddress = "Email Address is Required"; }

            if (!isValid) {
                  alert.setSnack({
                        ...alert, open: true, severity: AlertProps.severity.error,
                        msg: "Please fill all mandatory field",
                  });
            }

            setData({ ...data, error });

            return isValid;
      };

      const mapResultData = (map) => {
            let result = mapResult(map);
            setData({ ...data, ...result })
      }
      const next = () => {
            if (value === 1) {
                  if (validate()) {
                        setValue(2)
                  } else {
                        return false;
                  }
            }
            if (value === 2) {
                  setValue(2);
            }

      };
      const previous = () => {
            setValue(prevValue => prevValue - 1);
            if (!main?.unitID) {
                  setMapLoad(false)
            }
      };
      const updateimg = async (image) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Loading",
            });
            try {
                  const tumbil = await singleImageUpload(image, data?.company?.value, assestType?.Thumbnails)
                  updateState("image", tumbil)
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }
            catch (err) {
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "Some Thing Went Wrong",
                  });
            }

      }
      const removeimg = () => {
            setData({ ...data, image: "" })
      }
      const tabTittle = [
            {
                  label: 'Unit Details',
                  sub: 'Enter Unit, Address & Contact Details',
                  imgSelect: value === 2 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
                  value: 1,
                  className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle

            },
            {
                  label: 'Attachments',
                  sub: 'Upload assets like Image, 360 Image, Vid..',
                  imgSelect: <ImagesProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
                  value: 2,
                  className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
            },
      ]

      const save = () => {
            setDisable(true)
            if (validate()) {
                  const payload = {
                        tenantId: `${config.tenantId}`,
                        company_id: data?.company?.value ?? null,
                        property_id: data?.propertyID ?? null,
                        block_id: data?.blockID?.value ?? null,
                        floor_id: data?.floorID?.value ?? null,
                        unit_id: data?.unitID ?? null,
                        name: data?.unitName ?? null,
                        logo: data?.image ?? null,
                        carpet_area: parseFloat(data?.carpetArea) ?? null,
                        total_area: parseFloat(data?.totalArea) ?? null,
                        total_bed_rooms: parseInt(data?.numberOfBed) ?? null,
                        is_active: data?.status?.value ? true : false,
                        is_room: data?.parentUnitID?.value ?? false,
                        unit_type: data?.unitType?.value ?? null,
                        parent_unit_id: data?.parentUnitID?.value ?? null,
                        unit_purpose: data?.unitPurpose?.value ?? null,
                        furnishing: data?.unitFurnishingType?.value ?? null,
                        status: data?.unitCurrentStatus?.value ?? null,
                        revenue_type: data?.revenueType?.value ?? null,
                        unit_category_id: data?.unitCategory?.value ?? null,
                        listing_status: data?.publicListing?.value ?? null,
                        year_built: data?.yearBuilt ? moment(data?.yearBuilt).format("yyyy-MM-DD") : null,
                        planned_hand_over_date: (data?.revenueType?.value === enumName.sale && data?.handoverDate) ?
                              moment(data?.handoverDate).format("yyyy-MM-DD") : null,
                        description: data?.description ?? null,
                        orientation: data?.orientation.value ?? null,
                        pet_policy: data?.pet_policy?.value ?? null,
                        execution_status: data?.execution_status?.value ?? null,
                        total_rooms: parseInt(data?.numberOfRoom) ?? null,
                        total_baths: parseInt(data?.numberOfBaths) ?? null,
                        payment_period: data?.paymentPeriod?.value ?? null,
                        latitude: parseFloat(data?.latitude) ?? null,
                        longitude: parseFloat(data?.longitude) ?? null,
                        mobile_phone: data?.mobilePhone?.mobile,
                        business_phone_country_code: data?.businessPhone?.mobile_code ?? "+91",
                        business_phone: data?.businessPhone?.mobile,
                        mobile_country_code: data?.mobilePhone?.mobile_code,
                        website: data?.website,
                        email: data?.emailAddress ?? null,
                        assetsData: main?.isEdit
                              ? images?.filter((val) => !val?.id).map(del => {
                                    delete del.__typename
                                    return {
                                          id: del?.id,
                                          url: del?.url,
                                          priority: del?.priority,
                                          asset_type: del?.asset_type
                                    }
                              })
                              : images,
                        deleteAssets: deletedAssetURL,
                        door_no: data.doorNo,
                        street_1: data.addressLineOne,
                        street_2: data.addressLineTwo,
                        landmark: data.landmark,
                        area: data.area,
                        locality: data?.area,
                        district: data?.city,
                        city: data.city,
                        state: data.state,
                        country: data.country,
                        zipcode: data.zipcode,
                        balconies: parseInt(data.noOfBalcony) ?? null,
                        balcony_area: parseFloat(data.balconyArea) ?? null,
                        owner_id: data?.ownerAccount?.id ?? null,
                        uom_id: data?.measurementUnit?.value,
                        build_up_area: parseFloat(data?.buildupArea) ?? null,
                        terrace_area: parseFloat(data?.terraceArea) ?? null,
                        is_asset_functional_location: data?.assetFunc?.value ? true : false,
                        is_asset_property: data?.assetProperty?.value ? true : false,
                        is_short_term_rental: data?.shortTermRental?.value ? true : false,
                        is_under_promotion: data?.underPromotion?.value ? true : false,
                        "size_type":"unit_size"
                  };
                  NetworkCall(
                        `${config.api_url}/unit/${data?.unitID ? `update` : `create`}`,
                        NetWorkCallMethods.post,
                        payload,
                        null,
                        true,
                        false).then((response) => {
                              alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.success,
                                    msg: `Unit ${main?.unitID ? `Updated` : `Created`} Successfully`,
                                    vertical: AlertProps.vertical.top,
                                    horizontal: AlertProps.horizontal.center,
                              });
                              setDisable(false)
                              if (main?.unitID) { history.goBack(-1); }
                              else if (response?.data?.data?.unit_id) {
                                    const currentDate = new Date().toISOString();
                                    const availableFrom = moment(currentDate).format("1972-MM-DD");
                                    const availableTo = moment(currentDate).format("2999-MM-DD");
                                    client.mutate({
                                          mutation: ADD_VACANCY_PERIOD_FOR_UNIT,
                                          fetchPolicy: 'network-only',
                                          variables: {
                                                "insertPayload": [
                                                      {
                                                            "unit_id": response?.data?.data?.unit_id,
                                                            "available_from": availableFrom,
                                                            "available_to": availableTo,
                                                            "is_active": data?.status?.value,
                                                            "status": "Vacant",
                                                            "created_by": localStorage.getItem(LocalStorageKeys.profileID),
                                                            "updated_by": localStorage.getItem(LocalStorageKeys.profileID),
                                                            "created_at": currentDate,
                                                            "updated_at": currentDate,
                                                            "client": localStorage.getItem(LocalStorageKeys.clinetID)
                                                      }
                                                ]
                                          }
                                    }).then((response) => { history.goBack(-1); }).catch((err) => { console.log(err) });
                              } else { history.goBack(-1); }
                        }).catch((error) => {
                              if (error?.response?.status === 406)
                                    alert.setSnack({
                                          ...alert,
                                          open: true,
                                          severity: AlertProps.severity.error,
                                          msg: "Your  Unit  limit has been hit! Please upgrade your plan!",
                                          vertical: AlertProps.vertical.top,
                                          horizontal: AlertProps.horizontal.center,
                                    });
                              setDisable(true)
                              console.log(error)
                        });
            } else { return false; }
      };

      const checkSave = (type) => {
            if (main?.unitID) {
                  if (permission?.update) {
                        type === "previous" && previous()
                        type === "next" && next()
                        type === "save" && save()
                  } else {
                        alert.setSnack({
                              ...alert, open: true, msg: "Access denied. Contact your administrator.",
                              severity: AlertProps.severity.error,
                        })
                  }
            } else if (permission?.create) {
                  type === "previous" && previous()
                  type === "next" && next()
                  type === "save" && save()
            } else {
                  alert.setSnack({
                        ...alert, open: true, msg: "Access denied. Contact your administrator.",
                        severity: AlertProps.severity.error,
                  })
            }
      }

      const onChangeFloor = (value) => {
            getPropertyDetailsByFloorID(value)
      }
      const getEnum = async () => {
            const result = await enumSelect([
                  enum_types.purpose_global_type,
                  enum_types.revenue_type,enum_types.unit_payment_period,
                  enum_types.furnishing_type,
                  enum_types.orientation_type, enum_types.pet_policy_type, 
                  enum_types.area_metric_type,
                  enum_types.listing_type, enum_types.unit_execution_status
            ])

            const unit_category_master = await client.query({
                  query: GET_UNIT_CATEGORY_MASTER,
                  variables: {}
            }).then(res => res?.data?.unit_category_master).catch(err => console.log(err));

            let tempUnitCategoryMaster =[]
            for (let i = 0; i < unit_category_master.length; i++){
                  tempUnitCategoryMaster = [
                        ...tempUnitCategoryMaster,
                        {
                              ...unit_category_master[i],
                              ...unitCategoryMaster[unit_category_master[i].value]
                        }
                  ]
            }

            setOptions({
                  ...options,
                  revenue_type: result?.revenue_type,
                  purpose_global_type: result.purpose_global_type,
                  unit_category_master: tempUnitCategoryMaster,
                  payment_period: result?.payment_value_type,
                  furnish: result?.furnishing_type,
                  orientation: result?.orientation_type,
                  pet_policy_type: result?.pet_policy_type,
                  area_metric_type: result?.area_metric_type,
                  listing_type: result?.listing_type,
                  unit_execution_status: result?.unit_execution_status
            })

            setInitialLoad(true)
      }

      const render = () => {
            return <>
                  <Subheader
                        title={"Unit Add/Edit"}
                        goBack={() => { history.goBack(-1) }}
                        previousAndNextBtn={true}
                        previousBtn={() => checkSave("previous")}
                        nextBtn={() => checkSave("next")}
                        previousBtnDisabled={value <= 1 && true}
                        nextBtnDisabled={value === 2 ? true : false}
                  />
                  <PropertyTabs value={value} isHeigth
                        tab1={(
                              <UnitDetails
                                    id='pushTitle'
                                    data={data}
                                    main={main}
                                    load={load}
                                    updateState={updateState}
                                    updateimg={updateimg}
                                    mapResult={mapResultData}
                                    removeimg={removeimg}
                                    onChangeFloor={onChangeFloor}
                                    mapLoad={mapLoad}
                                    propertyID={main?.propertyID ?? data?.propertyID}
                                    blockID={main?.blockID}
                                    floorID={main?.floorID}
                                    showBlock={data?.property_hierarchy?.is_block}
                                    showFloor={data?.property_hierarchy?.is_floor}
                                    options={options}
                              />
                        )}
                        tab2={<Assests images={images} setImages={setImages} handleAssetFile={handleAssetFile} companyId={data?.company?.value ?? ""} />}
                        tabTittle={tabTittle}
                        showBtns={true}
                        isSaveDisable={disable === true ? true : value === 2 ? false : true}
                        saveBtnText={main?.unitID ? "Update" : "Submit"}
                        save={() => checkSave("save")}
                  />
            </>
      }

      return (
            <div style={{ position: 'relative' }}>
                  {accessCheckRender(render, permission)}
            </div>
      )
}