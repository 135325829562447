import { useApolloClient } from "@apollo/client";
import { Box, Button, Grid, Paper, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AlertDialog, SearchFilter, Subheader, TableWithPagination } from "../../components";
import { NewLoader } from "../../components/newLoader";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { getSiteVisitRequestList } from "../../graphql/inspectionTemplate";
import { Routes } from "../../router/routes";
import { accessCheckRender, generalInspectionDataType, generalInspectionHeading, generalInspectionPath, getCompanyOption, getRoutePermissionNew } from "../../utils";
import { CreateInspection } from "./components/createInspection";
import { useStyles } from "./style";
const GeneralInspection = ({ t }) => {
    const classes = useStyles()
    const client = useApolloClient()
    const alert = useContext(AlertContext)
    const backdrop = React.useContext(BackdropContext)
    const auth = React.useContext(AuthContext)
    const [companyList, setCompanyList] = useState([])
    const [selectedCompany, setSelectedCompany] = useState({})
    const [permission, setPermission] = useState({})
    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState("")
    // const [filterData, setFilterData] = React.useState({
    //     status: [],
    // });
    // const [openFilter, setOpenFilter] = useState(false)
    const [isCreate, setIsCreate] = useState(false)
    const history = useHistory()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [tableData, setTableData] = useState([])
    // const StatusOptionList = [
    //     { label: 'Pending', value: 'Pending' },
    //     { label: 'Completed', value: 'Completed' },
    // ]
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getGeneralInspectionData(0, limit, value?.value, "")
    }
    const handleSearch = (value) => {
        setSearchText(value)
        getGeneralInspectionData(0, 10, selectedCompany?.value, value)
    }
    const handleIcon = (type, data) => {
        history.push({
            pathname: Routes?.viewGeneralInspection,
            state: {
                request_id: data?.id,
                reference_id: data?.reference_id,
                unit_id: data?.agreement_inspection?.[0]?.unit_id,
                agreement_inspection_id: data?.agreement_inspection?.[0]?.id,
                status: data?.agreement_inspection?.[0]?.status
            }
        })
    }
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getGeneralInspectionData(offset, 10, selectedCompany?.value, "")
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getGeneralInspectionData(0, limit, selectedCompany?.value, "")
    }
    const onClose = () => {
        setIsCreate(false)
    }
    //get tableData 
    const getGeneralInspectionData = (offset = 0, limit = 10, company_id, search = "") => {
        client.query({
            query: getSiteVisitRequestList,
            fetchPolicy: 'network-only',
            variables: {
                offset: offset,
                limit: limit,
                company_id: company_id,
                search: search,
                request_type:"general"
            }
        }).then((response) => {
            setLoading(false)
            const data = response?.data?.agreement_inspection_request?.map((val) => {
                return ({
                    ...val,
                    unit_no: val?.agreement_inspection?.[0]?.unit?.unit_no,
                    property_details: `${val?.agreement_inspection?.[0]?.unit?.property?.name} , ${val?.agreement_inspection?.[0]?.unit?.property?.address?.city}`,
                    assigned_to: val?.agreement_inspection?.[0]?.inspection_resources[val?.agreement_inspection?.[0]?.inspection_resources?.length - 1]?.resource?.contact?.first_name,
                    status: val?.agreement_inspection?.[0]?.status
                })
            })
            setTableData({ list: data, count: response?.data?.count?.[0]?.count })

        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }
    //To filter data based on status
    // const handleFilter = (data) => {
    //     getGeneralInspectionData(selectedCompany?.value, 0, limit, "", data)
    //     setFilterData(data)
    // }
    React.useEffect(() => {

        let company = getCompanyOption(backdrop, auth, alert)
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
                getGeneralInspectionData(0, 10, company?.selected?.value, "")
            }
        }
        // eslint-disable-next-line
    }, [auth])
    const reload = () => {
        getGeneralInspectionData(0, 10, selectedCompany?.value, "")
    }
    const render = () => {
        return (
            <Box>
                <Subheader
                    hideBackButton={true}
                    title={t("generalInspection")}
                    select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        handleCompanyChange(e)
                    }}
                />
                {
                    loading ?
                    <NewLoader minusHeight="158px" />
                        :
                        <Paper className={classes.root}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={4}>
                                    {/*search */}
                                    <SearchFilter
                                        handleChange={handleSearch}
                                        value={searchText}
                                        placeholder={t("searchInspection")}
                                    />
                                </Grid>
                                <Grid item xs={8} alignContent="flex-end">
                                    <Stack
                                        // divider={<Divider orientation="vertical" flexItem />}
                                        spacing={2}
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        direction="row">

                                        {/* <FilterIcon/> */}


                                        {/*add btn */}
                                        {permission?.create &&
                                            <Button onClick={() => setIsCreate(true)} className={classes.addIcon} variant="contained">{t("createNewInspection")}</Button>
                                         }

                                    </Stack>
                                </Grid>
                            </Grid>
                            <TableWithPagination
                                heading={generalInspectionHeading(t)}
                                rows={tableData?.list}
                                dataType={generalInspectionDataType}
                                path={generalInspectionPath}
                                showpagination
                                tableType="no-side"
                                handleIcon={handleIcon}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={tableData?.count}
                                page={page}
                                limit={limit}
                                className={classes.reqTable}
                                view={true}
                                height={`calc(100vh - 300px)`}
                                edit={true}
                                delete={true} />
                        </Paper>
                }
                <AlertDialog
                    open={isCreate}
                    header="Create New Inspection"
                    medium
                    onClose={() => onClose()}
                    component={
                        <CreateInspection companyId={selectedCompany?.value} onClose={onClose} reload={reload} />
                    }
                />
                {/* filter component
                {openFilter && (
                    <FilterGenerator
                        open={openFilter}
                        onClose={() => setOpenFilter(false)}
                        components={[
                            {
                                component: "toggleButton",
                                value: filterData?.status,
                                state_name: "status",
                                label: "Status",
                                options: StatusOptionList,
                                isMulti: true
                            },


                        ]}
                        onApply={(value) => handleFilter(value)}
                    />
                )} */}
            </Box>
        )
    }
    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
}

export default withNamespaces("inspection")(GeneralInspection) 