import { Box, Grid, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import { DocumentViewer } from "../../../components/fileViewer";
import { QuotationDetailCard } from "../components";
import { AggrementTabStyles } from "./styles";
import {convertTimeUtcToZoneCalander} from '../../../utils';

export const AgreementDocument = ({ details = {} }) => {
    const classes = AggrementTabStyles()
    const QuotationCardHeader = {
        quotationNumber: details?.agreement_no,
        type: null,
        Date: convertTimeUtcToZoneCalander(details?.lease_start_date)
    }
    const QuotationCardTable = []
    //download pdf
    const downloadPdf = () => {
        if (details?.agreement_assets?.[0]?.url) {
            saveAs(details?.agreement_assets?.[0]?.url, `${details?.agreement_no}.pdf`)
        }
    }
    return (
        <Box >
            <Grid container >
                <Grid item xs={4} p={2}>
                    <QuotationDetailCard onClick={downloadPdf} value={QuotationCardTable} headerDet={QuotationCardHeader} />
                </Grid>
                <Grid item xs={8} className={classes.pdfSection}>
                    {
                        details?.agreement_assets?.length > 0
                            ?
                            <center>

                                <DocumentViewer url={details?.agreement_assets?.[0]?.url} />
                            </center>
                            :
                            <Typography className={classes.total}>No Data Found</Typography>

                    }

                </Grid>
            </Grid>


        </Box>
    )
}