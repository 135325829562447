import { Box, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Bold } from "../../utils";

const useStyles = makeStyles((theme) => ({
  rootcard: {
    cursor: "pointer",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    padding: "4px"
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  name: {
    fontSize: "14px",
    fontFamily: Bold,
  },
  call: {
    fontSize: "10px",
    color: theme.typography.color.secondary,
  },
  mail: {
    fontSize: "10px",
    color: theme.typography.color.secondary,
  },
  img: {
    objectFit: "cover",
    height: "54px",
    width: "54px",
    borderRadius: "4px"
  },
  tagStyle: {
    backgroundColor: "#5078E11E",
    borderRadius: "5px",
    padding: "3px 10px 3px 10px",
    fontSize: "10px",
    fontFamily: Bold,
    color: theme.typography.color.primary,
    marginLeft: "8px"
  },

}));

export const ContactList = (props) => {
  const classes = useStyles();

  return (

    <Box display="flex" className={classes.rootcard} onClick={props?.onClick}>
      <Box marginBottom="-4px">
        <img
          src={props.data.img?.length > 0 ? props.data.img : "/images/profile_image.png"}
          alt={props.data.name}
          className={classes.img}
        />

      </Box>
      <Box marginTop="6px" marginLeft="8px">
        <Typography className={classes.name}>
          {props.data.name}
          {
            props.data.arg && <span className={classes.tagStyle} >{props.data.arg}</span>
          }

        </Typography>
        <Box height="4px" />
        <Stack direction="row">
          <Box>
            <Typography className={classes.call}>
              {props.data.phone}
            </Typography>
          </Box>
          <Box className={classes.periodStyle} />
          <Box>
            <Typography className={classes.mail}>
              {props.data.mail}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
