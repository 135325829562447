import { Avatar, Grid } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { AggreeIcon, CasaGrandIcon, InspectionIcon } from "../../../assets";
import { Cards } from "../../../components/detailsCard/card";
import {convertTimeUtcToZoneCalander} from '../../../utils'
const InspectionDetails = (props) => {
    const { t } = props
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <Cards
                    title={t("inspectionDetail")}
                    subTitle={props?.details?.inspection_detail?.reference_id}
                    subTitle1={`Requested on ${convertTimeUtcToZoneCalander(props?.details?.inspection_detail?.created_at)}, by ${props?.details?.inspection_detail?.first_name}`}
                    icon={<InspectionIcon />}
                />
            </Grid>
            {
                props?.details?.responsible_person_detail?.name &&
                <Grid item xs={12} md={3}>
                    {/* <DetailsCard
                        title={t("responsiblePersonDetail")}
                        data={props?.details?.responsible_person_detail?.length > 0 ? props?.details?.responsible_person_detail?.[0] : []}
                        AllData={props?.details?.responsible_person_detail?.length > 1 ? props?.details?.responsible_person_detail : []}
                        viewAll={props?.details?.responsible_person_detail?.length > 0 ? true : false}
                    /> */}
                    <Cards
                        title={t("agreementManager")}
                        subTitle={props?.details?.responsible_person_detail?.name}
                        subTitle1={`${props?.details?.responsible_person_detail?.mobile_no_country_code} ${props?.details?.responsible_person_detail?.mobile_no}`}
                        icon={<Avatar sx={{ height: 40, width: 40, marginBottom: "6px" }} variant="rounded" src={props?.details?.responsible_person_detail?.image_url} />}
                    />
                </Grid>
            }
            <Grid item xs={12} md={3}>
                {/* <DetailsCard
                    title={t("agreementDetail")}
                    data={props?.details?.agreement_detail}
                    icon={<AggreeIcon />}
                /> */}
                <Cards
                    title={t("agreementDetail")}
                    subTitle={props?.details?.agreement_detail?.agreement_no}
                    subTitle1={`${convertTimeUtcToZoneCalander(props?.details?.agreement_detail?.lease_start_date)} - ${convertTimeUtcToZoneCalander(props?.details?.agreement_detail?.lease_end_date)}`}
                    icon={<AggreeIcon />}
                />
            </Grid>
            {
                props?.details?.property_detail?.length > 0 &&
                <Grid item xs={12} md={3}>
                    {/* <DetailsCard
                        title={t("propertyDetail")}
                        data={props?.details?.property_detail?.length > 0 ? props?.details?.property_detail?.[0] : []}
                        AllData={props?.details?.property_detail?.length > 1 ? props?.details?.property_detail : []}
                        viewAll={props?.details?.property_detail?.length > 0 ? true : false}
                        icon={<CasaGrandIcon />}
                    /> */}

                    <Cards
                        title={t("propertyDetail")}
                        subTitle={props?.details?.property_detail?.[0]?.name}
                        subTitle1={`${props?.details?.property_detail?.[0]?.city ?? ""} , ${props?.details?.property_detail?.[0]?.country ?? ""}`}
                        icon={<CasaGrandIcon />}
                    />
                </Grid>
            }
        </Grid >

    )
}
export default withNamespaces("inspection")(InspectionDetails)