import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { ProfileCard, RequestMessage } from './../components'
import { RequestPopupStyle } from './style'
import {convertTimeUtcToZoneCalander} from '../../../utils'

export const RequestPopup = ({ openDecline = () => false, openReconcile = () => false, templateData = {}, enumData = {} }) => {

    const classes = RequestPopupStyle()

    return (
        <Stack m={2} spacing={2}>
            <Box className={classes.content_box}>
                {
                    templateData?.compensated_type !== null && <>
                        {
                            templateData?.compensated_type === "Rejected" ?
                                <RequestMessage type="Rejected" title={"Declined by " + templateData?.created_by?.first_name} message={templateData?.remarks ? templateData?.remarks : "-"} remarks=""/> :
                                <RequestMessage type="Decline" title={"Reconciled by " + templateData?.created_by?.first_name} message={templateData?.remarks ? templateData?.remarks : "-"} remarks="" />
                        }
                    </>
                }

                <Stack className={classes.profileSection} spacing={2} p={2}>
                    <ProfileCard data={templateData} />
                    <Divider></Divider>
                    <Stack direction={"row"} spacing={2}
                        divider={<Divider orientation="vertical" flexItem />}
                        alignItems={"center"} justifyContent={"space-around"}>
                        <Stack direction={"row"} spacing={5}>
                            <Stack>
                                <Typography className={classes.titleText}>Invoice Number</Typography>
                                <Typography className={classes.contentText}> {templateData?.invoice?.invoice_no} </Typography>
                            </Stack>
                            <Stack>
                                <Typography className={classes.titleText}>Invoice Raised On</Typography>
                                <Typography className={classes.contentText}> {convertTimeUtcToZoneCalander(templateData?.invoice?.invoice_date)} </Typography>
                            </Stack>
                        </Stack>
                        <Stack>
                            <Stack>
                                <Typography className={classes.contentText}>{templateData?.invoice?.company?.currency?.symbol} {templateData?.invoice?.invoice_total_amount}</Typography>
                                <Typography className={classes.titleText} sx={{ color: "#FF9340 !important" }}>Unpaid</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack >
                <Box ml={1} p={2} pt={1} pb={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.titleText}>Date Of Request</Typography>
                                <Typography className={classes.contentText}>{convertTimeUtcToZoneCalander(templateData?.data?.updated_at)}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.titleText}>Payment Method</Typography>
                                <Typography className={classes.contentText}> {templateData?.payment_mode} </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.titleText}>Amount Paid</Typography>
                                <Typography className={classes.contentText}>{templateData?.invoice?.company?.currency?.symbol} {templateData?.amount_paid}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.titleText}>Payment Date</Typography>
                                <Typography className={classes.contentText}>{convertTimeUtcToZoneCalander(templateData?.data?.updated_at)}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.titleText}>Bank Name</Typography>
                                <Typography className={classes.contentText}> {templateData?.bank} </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.titleText}>Transaction/Reference Id</Typography>
                                <Typography className={classes.contentText}> {templateData?.trx_no} </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.titleText}>Notes</Typography>
                                <Typography className={classes.contentText}>{templateData?.notes ?? "-"}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {
                templateData?.compensated_type === null &&
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Button className={classes.declineBtn}
                                onClick={openDecline}>Decline</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button className={classes.reconcile}
                                onClick={openReconcile}>Reconcile</Button>
                        </Grid>
                    </Grid>
                </Box>
            }
        </Stack >
    )
}