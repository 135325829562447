import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from 'react';
import { TextBox } from '../textbox';
import { AccordianStyle } from "./styles";
export const NotesCard = ({ item = {}, MoveOut = false, setNotesData = () => false, notesData = "", onUpdateNotes = () => false, status = "" }) => {
    const [expend, setExpend] = React.useState(false)
    const [notes, setNotes] = React.useState(false)
    const [info, setInfo] = React.useState("")
    const classes = AccordianStyle();
    //handleChangeNotes
    const handleChangeNotes = (e) => {
        setInfo(e.target.value)
    }

    //onclickupdate
    const updateNotes = () => {
        setNotesData([...notesData?.managerNotes, { notes: info }])
        onUpdateNotes(info)
    }

    return (
        <Box>
            <Grid sx={{ boxShadow: expend ? "0px 8px 24px #0717411F" : "auto" }} container className={classes.cardRoot} alignItems="center" onClick={() => { return (setNotes(!notes), setExpend(!expend)) }}>
                <Grid item sm={6} md={3} lg={4}>
                    <Stack direction="row" spacing={1} alignItems='center'>
                        <Typography className={classes.title}>{"Notes"}</Typography>

                    </Stack>

                </Grid>
                <Grid item sm={3} md={3} lg={4}>
                    {
                        notesData?.managerNotes?.length > 0 ? <CheckCircleIcon color="success" /> : "-"
                    }
                </Grid>
                <Grid item sm={3} md={3} lg={MoveOut === true ? 2 : 4}>
                    <Stack direction="row" alignItems='center' justifyContent="space-between">

                        <Box width="100px" />
                        <KeyboardArrowDownIcon />
                    </Stack>
                </Grid>
                {
                    notes &&
                    <Grid item xs={12} >
                        <Grid container marginTop="16px">
                            <Grid item xs={4}>
                                <Typography className={classes.subtitle1}>Manager Note</Typography>
                            </Grid>
                            <Grid item xs={8} onClick={(e) => notesData?.managerNotes?.length === 0 && e.stopPropagation()}>
                                {notesData?.managerNotes?.length > 0 ?
                                    notesData?.managerNotes?.map((val) => {
                                        return (
                                            <Typography className={classes.subtitle2}>{val?.notes}</Typography>
                                        )
                                    })
                                    : status === "Completed" && notesData?.managerNotes?.length === 0 ?
                                        <Typography className={classes.subtitle2}>{"-"}</Typography>

                                        :
                                        (status !== "Completed" &&
                                            <Box className={classes.textAreaBox}>
                                                    <TextBox label="" placeholder='Type Notes here' rowheight={6}
                                                        border={0} color="white" multiline onChange={(e) => handleChangeNotes(e)} value={info} />
                                                <Box mt={2}>
                                                    <Button variant="contained" fullWidth onClick={updateNotes}>Update Note</Button>
                                                </Box>
                                            </Box>
                                        )
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={classes.subtitle1}>Inspector Note</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={classes.subtitle2}>{notesData?.inspectorNotes ?? "-"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Box>
    )
}