import { Avatar, Box, Button, Dialog, Grid, IconButton, Stack, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { enumName } from "../../../utils"
import { useStyles } from "../style"
import { Bed } from "./bed"
import { Bath } from "./bath"
import { UnitType } from "./unitType"
import AcceptIcon from "../../../assets/agreementDetails/acceptIcon";

export const AcceptConfirm = (props) => {

    const classes = useStyles(props)

    return <Dialog
        className={classes.acceptConfirmDialog}
        open={props?.acceptDialogOpen}
        onClose={() => props?.setAcceptDialogOpen(false)}>
        <div className={classes.viewDialogHeader}>
            <Typography className={classes.viewDialogHeaderTitle}>
                {"Accept Confirmation"}
            </Typography>
            <IconButton onClick={() => props?.setAcceptDialogOpen(false)}
                className={classes.viewDialogCloseButton}>
                <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
            </IconButton>
        </div>
        <div className={classes.acceptDialogBody}>
            <Grid container>
                <Grid item xs={4} padding={"16px"}>
                    <Box className={classes.box}>
                        <Box>
                            <AcceptIcon />
                        </Box>
                        <Box flexGrow={1} marginLeft={"10px"} >
                            <Typography className={classes.viewDialogText4}>
                                {props?.requestState?.status?.label}
                            </Typography>
                            <Typography className={classes.italicText}>
                                By {props?.requestState?.approvedBY} on {props?.requestState?.updatedOn}
                            </Typography>
                            <Typography className={classes.semiBoldText}>
                                Remarks : <span className={classes.regularText}>{props?.requestState?.managerRemarks}</span>
                            </Typography>

                        </Box>

                    </Box>
                    <Grid container padding={"12px 0px"}>
                        <Typography className={classes.heading}>
                            {"Renewal Agreement Details"}
                        </Typography>
                    </Grid>
                    <Grid container className={classes.box}
                        justifyContent={"space-between"}>
                        <Grid item xs={6} paddingRight={"6px"} paddingBottom={"6px"}>
                            <Typography className={classes.viewDialogText1}>
                                LEASE START DATE
                            </Typography>
                        </Grid>
                        <Grid item xs={6} paddingLeft={"6px"} paddingBottom={"6px"}>
                            <Typography className={classes.viewDialogText1}>
                                LEASE END DATE
                            </Typography>
                        </Grid>
                        <Grid item xs={6} paddingRight={"6px"}>
                            <Typography className={classes.viewDialogText2}>
                                {props?.requestState?.leaseStart}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} paddingLeft={"6px"}>
                            <Typography className={classes.viewDialogText2}>
                                {props?.requestState?.leaseEnd}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container padding={"12px 0px"}>
                        <Typography className={classes.heading}>
                            {"ACCOUNT DETAILS"}
                        </Typography>
                    </Grid>
                    <Grid container className={classes.box} justifyContent={"space-between"}>
                        <Grid item xs={6} paddingRight={"6px"} paddingBottom={"6px"}>
                            <Typography className={classes.viewDialogText1}>
                                ACCOUNT NUMBER
                            </Typography>
                        </Grid>
                        <Grid item xs={6} paddingLeft={"6px"} paddingBottom={"6px"}>
                            <Typography className={classes.viewDialogText1}>
                                ACCOUNT NAME
                            </Typography>
                        </Grid>
                        <Grid item xs={6} paddingRight={"6px"}>
                            <Typography className={classes.viewDialogText2}>
                                {props?.requestState?.accountNo}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} paddingLeft={"6px"}>
                            <Typography className={classes.viewDialogText2}>
                                {props?.requestState?.accountName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container padding={"12px 0px"}>
                        <Typography className={classes.heading}>
                            {"Primary Contact"}
                        </Typography>
                    </Grid>
                    <Box display="flex" alignItems="center" className={classes.viewDialogContactCard}>
                        <Avatar src={props?.requestState?.contactImg} variant={"rounded"}
                            className={classes.contactAvatar} />
                        <Box flexGrow={1}>
                            <Grid container justifyContent={"start"} alignItems={"center"}
                                direction={"row"} paddingBottom={"6px"}>
                                <Typography className={classes.viewDialogText4}>
                                    {props?.requestState?.contactName}
                                </Typography>
                            </Grid>
                            <Grid container justifyContent={"start"} alignItems={"center"} direction={"row"}>
                                <Typography className={classes.viewDialogText5}>
                                    {props?.requestState?.contactNo}
                                </Typography>
                                <Box className={classes.period} />
                                <Typography className={classes.viewDialogText5}>
                                    {props?.requestState?.contactEmail}
                                </Typography>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={4} padding={"16px"} className={classes.unitListContainer}>
                    <Grid container paddingBottom={"12px"}>
                        <Typography className={classes.viewDialogText6}>
                            {"Unit Details"}
                        </Typography>
                    </Grid>
                    <Grid container alignContent={"start"} className={classes.unitList}>
                        <Grid container spacing={"16px"}>
                            {props?.requestState?.unitPrice?.units?.map((_) => {
                                return <Grid item xs={6}>
                                    <div style={{
                                        border: "1px solid #E4E8EE",
                                        borderRadius: "4px",
                                        padding: "16px",
                                        backgroundColor: "white",
                                    }}>
                                        <img className={classes.unitImage}
                                            src={_?.logo ?? ""} alt={_?.name ?? "Unit_Image"} />
                                        <Stack direction={"row"} justifyContent={"space-between"}
                                            alignItems={"center"} padding={"4px 0px"}>
                                            <Typography className={classes.viewDialogText4}>
                                                {_?.name ?? ""}
                                            </Typography>
                                            <Typography className={classes.viewDialogText4}>
                                                {_?.total ?? ""}
                                            </Typography>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"start"}
                                            alignItems={"center"} padding={"4px 0px"}
                                            divider={<div style={{
                                                backgroundColor: "#CED3DD", margin: "0px 8px",
                                                borderRadius: "50%", height: "6px", width: "6px"
                                            }} />}>
                                            <Typography className={classes.viewDialogText7}>
                                                {_?.property?.property_name ?? ""}
                                            </Typography>
                                            <Typography className={classes.viewDialogText7}>
                                                {_?.totalarea ? (_?.totalarea + (_?.areaMetric ?? "")) : ""}
                                            </Typography>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"space-between"}
                                            alignItems={"center"} padding={"4px 0px"}
                                            divider={<div style={{
                                                backgroundColor: "#CED3DD", margin: "0px 8px",
                                                borderRadius: "50%", height: "6px", width: "6px"
                                            }} />}>
                                            <Stack direction={"row"} justifyContent={"center"}
                                                alignItems={"center"}>
                                                <Bed />
                                                <Typography className={classes.viewDialogText7}>
                                                    {_?.totalbedroom}
                                                </Typography>
                                            </Stack>
                                            <Stack direction={"row"} justifyContent={"center"}
                                                alignItems={"center"}>
                                                <Bath />
                                                <Typography className={classes.viewDialogText7}>
                                                    {_?.baths}
                                                </Typography>
                                            </Stack>
                                            <Stack direction={"row"} justifyContent={"center"}
                                                alignItems={"center"}>
                                                <UnitType />
                                                <Typography className={classes.viewDialogText7}>
                                                    {_?.unitType}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Typography textAlign={"center"} className={classes.clickableText}
                                            onClick={() => {
                                                props?.setRequestState({
                                                    ...props?.requestState,
                                                    unitView: _
                                                })
                                                props?.setUnitViewDialogOpen(true)
                                                props?.setAcceptDialogOpen(false)
                                                props?.setRequestDialogOpen(false)
                                                props?.setAcceptDeclineDialogOpen(false)
                                            }}>
                                            {"View Details"}
                                        </Typography>
                                    </div>
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} padding={"16px"}>
                    <Grid container paddingBottom={"12px"}>
                        <Typography className={classes.viewDialogText6}>
                            {"Agreement Summary"}
                        </Typography>
                    </Grid>
                    <Grid container className={classes.agreementSummary}>
                        <Grid item xs={5}>
                            <Typography className={classes.tableHeaderText}>
                                {"DESCRIPTION"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.tableHeaderText}>
                                {"QTY"}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography textAlign={"right"} className={classes.tableHeaderText}>
                                {"AMOUNT"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.tableDivider} />
                        <Grid container alignContent={"start"} className={classes.tableBody}>
                            <Grid item xs={5}>
                                <Typography className={classes.tableDescriptionText}>
                                    {"Total Amount"}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.tableQtyText}>
                                    {"-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography textAlign={"right"} className={classes.tableTotalText}>
                                    {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                        (props?.requestState?.unitPrice?.totalAmount?.toFixed(2) ?? "0.00")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} margin={"0px 12px"} className={classes.tableDivider} />
                            <Grid item xs={5}>
                                <Typography className={classes.tableDescriptionText}>
                                    {"Total Refundable"}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.tableQtyText}>
                                    {"-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography textAlign={"right"} className={classes.tableTotalText}>
                                    {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                        (props?.requestState?.unitPrice?.totalrefundableTax?.toFixed(2) ?? "0.00")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} margin={"0px 12px"} className={classes.tableDivider} />
                            <Grid item xs={5}>
                                <Typography className={classes.tableDescriptionText}>
                                    {"Total Tax"}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.tableQtyText}>
                                    {"-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography textAlign={"right"} className={classes.tableTotalText}>
                                    {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                        (props?.requestState?.unitPrice?.totalTax?.toFixed(2) ?? "0.00")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} margin={"0px 12px"} className={classes.tableDivider} />
                            <Grid item xs={5}>
                                <Typography className={classes.tableDescriptionText}>
                                    {"Discount"}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.tableQtyText}>
                                    {"-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography textAlign={"right"} className={classes.tableTotalText}>
                                    {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                        "0.00"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} margin={"0px 12px"} className={classes.tableDivider} />
                            <Grid item xs={5}>
                                <Typography className={classes.tableDescriptionText}>
                                    {"Reservation Amount"}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.tableQtyText}>
                                    {"-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography textAlign={"right"} className={classes.tableTotalText}>
                                    {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                        "0.00"}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.tableDivider} />
                        <Grid item xs={5}>
                            <Typography className={classes.tableTotalText}>
                                {"Total"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={5}>
                            <Typography textAlign={"right"} className={classes.tableTotalText}>
                                {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                    (((props?.requestState?.unitPrice?.totalAmount ?? 0.00) -
                                        (props?.requestState?.unitPrice?.totalrefundableTax ?? 0.00) +
                                        (props?.requestState?.unitPrice?.totalTax ?? 0.00))?.toFixed(2) ?? "0.00")}
                            </Typography>
                        </Grid>
                    </Grid>
                    {props?.requestState?.status?.value === enumName.pending &&
                        <div className={classes.viewDialogButtonContainer}>
                            <Button fullWidth className={classes.cancelButton}
                                onClick={() => {
                                    props?.setRequestState({
                                        ...props?.requestState,
                                        formType: "",
                                        acceptDeclineRemarks: ""
                                    })
                                    props?.setAcceptDeclineDialogOpen(false)
                                    props?.setAcceptDialogOpen(false)
                                    props?.setRequestDialogOpen(true)
                                }}>
                                Cancel
                            </Button>
                            {props?.requestState?.occupiedUnits?.length === 0 &&
                                <Button fullWidth className={classes.acceptDeclineButton}
                                    onClick={props?.handleAcceptDecline}>
                                    Create
                                </Button>}
                        </div>}
                </Grid>
            </Grid>
        </div>
    </Dialog >
}