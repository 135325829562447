import { Box, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { InvoiceDetailStyle } from './style'
import moment from 'moment'

const InvoiceDetail = ({ slots = [], amenityData = {},t }) => {
    const classes = InvoiceDetailStyle()
    return (
        <Box className={classes.box} m={2}>
            <Stack spacing={1.5} p={2} sx={{ width: "100%" }}>
                <Stack spacing={1.5} className={classes.invoiceBox}>
                    {
                        slots?.map((e) => {
                            return (
                                <Stack spacing={1}>
                                    <Stack direction={"row"} justifyContent={"space-between"}>

                                        <Typography className={classes.title}>
                                            {e?.value?.check_in_time ?? moment(e?.value?.check_in_date).format("DD-MMM-YY") } - {e?.value?.check_out_time ?? moment(e?.value?.check_out_date).format("DD-MMM-YY")}
                                        </Typography>

                                        <Typography className={classes.content}> {amenityData?.symbol} {amenityData?.rate} </Typography>
                                    </Stack>
                                    <Divider></Divider>
                                </Stack>
                            )
                        })
                    }
                </Stack>


                <Stack direction={"row"} justifyContent={"space-between"} >
                    <Typography className={classes.total}>{t("Total")}</Typography>
                    <Typography className={classes.total}>{amenityData?.symbol} {amenityData?.rate * slots?.length} </Typography>
                </Stack>
            </Stack>
        </Box >
    )
}

export default withNamespaces("amenityBooking")(InvoiceDetail)