import { Avatar, Box, Grid } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { CircleIcon, InspectionIcon } from "../../../assets";
import { AccordianWithDetails, DetailsCard, LoadingSection, Subheader } from "../../../components";
import { Cards } from "../../../components/detailsCard/card";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods ,convertTimeUtcToZoneCalander} from "../../../utils";
import { AggrementComponentStyles } from "./styles";

const InspectionDetails = ({ onClose = () => false, t, selected = {}, agreement_id }) => {
    const classes = AggrementComponentStyles();
    const [details, setDetails] = React.useState({
        data: {},
        loading: true
    });


    const getDetails = () => {
        const payload = {
            agreement_inspection_id: selected?.id,
            id: selected?.requset_id,
            agreement_id: agreement_id
        }
        NetworkCall(
            `${config.api_url}/agreement_inspection/get_agreement_inspection_detail`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setDetails({
                data: res?.data?.data,
                loading: false
            })
        }).catch((err) => {
            console.log(err)
        })
    }
    React.useEffect(() => {
        getDetails()
        // eslint-disable-next-line
    }, [])

    return (
        <Box p={1}>
            <Box className={classes.inspectionDetails}>
                <Subheader goBack={() => onClose()} title="INSRQ-122143" />

                {
                    details?.loading ?
                        <Box className={classes.right}>
                            <LoadingSection top="10vh" />
                        </Box>
                        :

                        <Box >
                            <Grid container >
                                <Grid item xs={3.5} p={1} className={classes.right}>
                                    <Cards
                                        title={t("inspectionDetail")}
                                        subTitle={details?.data?.detail?.inspection_detail?.reference_id}
                                        subTitle1={`Requested on ${convertTimeUtcToZoneCalander(details?.data?.detail?.inspection_detail?.created_at)}, by ${details?.data?.detail?.inspection_detail?.first_name}`}
                                        icon={<InspectionIcon />}
                                    />

                                    {
                                        details?.data?.detail?.responsible_person_detail?.name &&
                                        <Box marginTop="12px">
                                            <Cards
                                                title={t("responsiblePersonDetail")}
                                                subTitle={details?.data?.detail?.responsible_person_detail?.name}
                                                subTitle1={`${details?.data?.detail?.responsible_person_detail?.mobile_no_country_code} ${details?.data?.detail?.responsible_person_detail?.mobile_no}`}
                                                icon={<Avatar sx={{ height: 40, width: 40, marginBottom: "6px" }} variant="rounded" src={details?.data?.detail?.responsible_person_detail?.image_url} />}
                                            />
                                        </Box>
                                    }
                                    {
                                        details?.data?.detail?.resource_details?.length > 0 &&
                                        <Box marginTop="12px">
                                            <DetailsCard
                                                title={t("inspectorDetails")}
                                                data={details?.data?.detail?.resource_details?.length > 0 ? details?.data?.detail?.resource_details?.[0] : []}
                                                AllData={details?.data?.detail?.resource_details?.length > 1 ? details?.data?.detail?.resource_details : []}
                                                viewAll={details?.data?.detail?.resource_details?.length > 0 ? true : false}
                                                icon={<InspectionIcon />}
                                                handleStatusClick={() => false}
                                            />
                                        </Box>
                                    }
                                    {
                                        details?.data?.detail?.track_update?.length > 0 &&
                                        <Box marginTop="12px">
                                            <DetailsCard
                                                title={t("trackUpdate")}
                                                data={details?.data?.detail?.track_update?.length > 0 ? details?.data?.detail?.track_update?.[0] : []}
                                                AllData={details?.data?.detail?.track_update?.length > 1 ? details?.data?.detail?.track_update : []}
                                                viewAll={details?.data?.detail?.track_update?.length > 0 ? true : false}
                                                icon={<CircleIcon />}
                                            />
                                        </Box>

                                    }
                                </Grid>
                                <Grid item xs={8.5} p={1} className={classes.inspectionDetail1}>
                                    <AccordianWithDetails state={selected} data={details?.data?.list} />
                                </Grid>
                            </Grid>
                        </Box>
                }

            </Box>
        </Box>
    )
}
export default withNamespaces("agreement")(InspectionDetails); 