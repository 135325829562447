import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import { mapResult } from '../../../utils/common';
import { TextBox, Editor, LogoUpload, MobileNumberInputComponent, MapFieldsOnly } from '../../../components';
import { CustomPaper } from '../../companyCreation/components';
import { Bold } from '../../../utils';
const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    fontFamily: Bold,
    color: theme.typography.color.secondary,
    marginBottom: theme.spacing(1)
}))

export const VendorBasicDetails = (props) => {

    const {
        data = {},
        error = {},
        updateState = null,
        mapLoad,
        companyID
    } = props;

    const theme = useTheme();

    const mapResultData = (map) => {
        let result = mapResult(map);
        updateState('', "address", result)
    }

    const {
        profileDetails = null,
        logo = null,
        address = null,
        contactInformation = null,
    } = data;
    const [fullScreenMap, setFullScreenMap] = React.useState(false)

    React.useEffect(() => {
        document.addEventListener("fullscreenchange", existFullscreen)
        return () => {
            document.removeEventListener("fullscreenchange", existFullscreen)

        }

    }, [])
    //exist full screen
    const existFullscreen = () => {
        if (document.fullscreenElement === null) {
            setFullScreenMap(false)
        }

    }
    //open full screen
    const fullScreenControl = () => {
        setFullScreenMap(true)
        document.getElementsByClassName('map-wrapper')[0]?.requestFullscreen()

    }
    return (
        <div>
            <Stack
                gap={2}
                direction={{ sm: "row", xs: "column" }}
                marginBottom={3}
            >
                <CustomPaper>
                    <LogoUpload
                        logo_title={"LOGO IMAGE"}
                        handleChange={(value) => updateState('', "logo", value)}
                        data={logo}
                        company={companyID}

                    />
                </CustomPaper>
                <Grid container>
                    <Grid item xs={12}>
                        <CustomPaper height={"100%"}>
                            <CustomTypography>Profile Details</CustomTypography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextBox
                                        // {...textBoxStyle}
                                        label="Company"
                                        value={companyID?.label ?? ""}
                                        isReadonly

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextBox
                                        // {...textBoxStyle}
                                        isrequired
                                        label="Vendor Code"
                                        placeholder="Select Vendor Code"
                                        value={profileDetails?.vendorCode ?? ""}
                                        onChange={(e) => updateState("profileDetails", "vendorCode", e.target.value)}
                                        isError={error?.profileDetails?.vendorCode?.error ?? false}
                                        errorMessage={error?.profileDetails?.vendorCode?.errorMsg}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextBox
                                        // {...textBoxStyle}
                                        isrequired
                                        label="Vendor Name"
                                        placeholder="Select Vendor Name"
                                        value={profileDetails?.vendorName ?? ""}
                                        onChange={(e) => updateState("profileDetails", "vendorName", e.target.value)}
                                        isError={error?.profileDetails?.vendorName?.error ?? false}
                                        errorMessage={error?.profileDetails?.vendorName?.errorMsg}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} sm={6} md={3}>
                                    <SelectBox
                                        isRequired={true}
                                        label={"Company's Country"}
                                        placeholder={'Select Country'}
                                        // options={[]}
                                        loadOptions={(search, array) => loadOptionData(search, array, "vendorCountry")}
                                        isPaginate
                                        debounceTimeout={800}
                                        loading={loading === "vendorCountry"}
                                        // isSearchable={false}
                                        value={profileDetails?.vendorCountry ?? null}
                                        onChange={(value) => updateState('profileDetails', 'vendorCountry', value)}
                                        isError={error?.profileDetails?.vendorCountry?.error ?? false}
                                        errorMessage={error?.profileDetails?.vendorCountry?.errorMsg}
                                    />
                                </Grid> */}
                                <Grid item xs={12}>
                                    <Editor
                                        value={profileDetails?.vendorDescription ?? ""}
                                        label="Vendor Description"
                                        handleChange={(e) => updateState("profileDetails", "vendorDescription", e)}
                                        id='pushTitle'
                                    />
                                </Grid>
                            </Grid>
                        </CustomPaper>
                    </Grid>
                </Grid>
            </Stack>
            <div className='map-wrapper'>
                <CustomPaper marginBottom={theme.spacing(3)}>
                    <CustomTypography>Address</CustomTypography>
                    <MapFieldsOnly
                        fullScreenMap={fullScreenMap}
                        fullScreenControl={fullScreenControl}
                        mapOptions={{
                            isInput: true,
                            center: {
                                lat: address?.latitude,
                                lng: address?.longitude
                            },
                            lat: address?.latitude,
                            lng: address?.longitude,
                            mapLoad
                        }}
                        mapHeight={"300px"}
                        breakpoints={{

                            components: {
                                xs: 12,
                                sm: 12,
                                md: 12
                            }
                        }}
                        mapResult={mapResultData}
                        fields={[
                            {
                                label: "Door Number",
                                component: "TextField",
                                value: address?.doorNumber,
                                state_name: 'doorNumber',
                                isrequired: false,
                                placeholder: "Enter Door Number",
                                error: error?.address?.doorNumber?.error,
                                errorMessage: error?.address?.doorNumber?.errorMsg,
                                breakpoints: {
                                    sm: 6,
                                    md: 3
                                }
                            },
                            {
                                label: "Address Line 1",
                                component: "TextField",
                                value: address?.addressLineOne,
                                state_name: 'addressLineOne',
                                isrequired: false,
                                placeholder: "Enter Address Line 1",
                                errorMessage: error?.address?.addressLineOne?.errorMsg,
                                error: error?.address?.addressLineOne?.error,
                                breakpoints: {
                                    sm: 6,
                                    md: 3
                                }
                            },
                            {
                                label: "Address Line 2",
                                component: "TextField",
                                value: address?.addressLineTwo,
                                state_name: 'addressLineTwo',
                                isrequired: false,
                                placeholder: "Enter Address Line 2",
                                errorMessage: error?.address?.addressLineTwo?.errorMsg,
                                error: error?.address?.addressLineTwo?.error,
                                breakpoints: {
                                    sm: 6,
                                    md: 3
                                }
                            },
                            {
                                label: "Landmark",
                                component: "TextField",
                                value: address?.landmark,
                                state_name: 'landmark',
                                isrequired: false,
                                placeholder: "Enter Landmark",
                                errorMessage: error?.address?.landmark?.errorMsg,
                                error: error?.address?.landmark?.error,
                                breakpoints: {
                                    sm: 6,
                                    md: 3
                                }
                            },
                            {
                                label: "Area",
                                component: "TextField",
                                value: address?.area,
                                state_name: 'area',
                                isrequired: false,
                                placeholder: "Enter Area",
                                errorMessage: error?.address?.area?.errorMsg,
                                error: error?.address?.area?.error,
                                breakpoints: {
                                    sm: 6,
                                    md: 3
                                }
                            },
                            {
                                label: "City / District",
                                component: "TextField",
                                value: address?.city,
                                state_name: 'city',
                                isrequired: false,
                                placeholder: "Enter City",
                                errorMessage: error?.address?.city?.errorMsg,
                                error: error?.address?.city?.error,
                                breakpoints: {
                                    sm: 6,
                                    md: 3
                                }
                            },
                            {
                                label: "State",
                                component: "TextField",
                                state_name: 'state',
                                value: address?.state,
                                isrequired: false,
                                placeholder: "Enter State",
                                errorMessage: error?.address?.state?.errorMsg,
                                error: error?.address?.state?.error,
                                breakpoints: {
                                    sm: 6,
                                    md: 3
                                }
                            },
                            {
                                label: "Country",
                                component: "TextField",
                                value: address?.country,
                                state_name: 'country',
                                isrequired: false,
                                placeholder: "Enter Country",
                                errorMessage: error?.address?.country?.errorMsg,
                                error: error?.address?.country?.error,
                                breakpoints: {
                                    sm: 6,
                                    md: 3
                                }
                            },
                            {
                                label: "Pincode",
                                component: "TextField",
                                state_name: 'pincode',
                                value: address?.pincode,
                                isrequired: false,
                                placeholder: "Enter Pincode",
                                errorMessage: error?.address?.pincode?.errorMsg,
                                error: error?.address?.pincode?.error,
                                breakpoints: {
                                    sm: 6,
                                    md: 3
                                }
                            },
                        ]}
                        onChangeFields={(key, value) => updateState("address", key, value)}
                    />
                </CustomPaper>
            </div>
            <CustomPaper>
                <CustomTypography>Contact & Other Information</CustomTypography>
                <Grid container spacing={2}>
                    {/* <Grid item xs={12} sm={3} md={3}>
                        <MobileNumberInputComponent
                            label={"Primary Contact"}
                            placeholder={"Enter Primary Contact"}
                            value={contactInformation?.primaryContact}
                            isRequired
                            handleChange={(value) => updateState("contactInformation", "primaryContact", value)}
                            isError={error?.contactInformation?.primaryContact?.error ?? false}
                            errorMessage={error?.contactInformation?.primaryContact?.errorMsg ?? ''}
                            onKeyPress={(e) => {
                                if (e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <TextBox
                            isrequired
                            label="Title"
                            placeholder="Title here"
                            value={contactInformation?.title ?? ""}
                            onChange={(e) => updateState("contactInformation", "title", e.target.value)}
                            isError={error?.contactInformation?.title?.error ?? false}
                            errorMessage={error?.contactInformation?.title?.errorMsg ?? false}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={3} md={3}>
                        <MobileNumberInputComponent
                            label={"Primary Telephone"}
                            placeholder={"Enter Primary Telephone"}
                            value={contactInformation?.primaryTelephone}
                            // isRequired
                            handleChange={(value) => updateState("contactInformation", "primaryTelephone", value)}
                            isError={error?.contactInformation?.primaryTelephone?.error ?? false}
                            errorMessage={error?.contactInformation?.primaryTelephone?.errorMsg ?? ''}
                            onKeyPress={(e) => {
                                if (e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <MobileNumberInputComponent
                            label={"Primary Mobile"}
                            placeholder={"Enter Primary Mobile"}
                            value={contactInformation?.primaryMobile}
                            // isRequired
                            handleChange={(value) => updateState("contactInformation", "primaryMobile", value)}
                            isError={error?.contactInformation?.primaryMobile?.error ?? false}
                            errorMessage={error?.contactInformation?.primaryMobile?.errorMsg ?? ''}
                            onKeyPress={(e) => {
                                if (e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <TextBox
                            // isrequired
                            label="Primary Email Address"
                            placeholder="Enter Primary Email Address"
                            value={contactInformation?.primaryEmailAddress ?? ""}
                            onChange={(e) => updateState("contactInformation", "primaryEmailAddress", e.target.value)}
                            isError={error?.contactInformation?.primaryEmailAddress?.error ?? false}
                            errorMessage={error?.contactInformation?.primaryEmailAddress?.errorMsg ?? false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <TextBox
                            // isrequired
                            label="Website"
                            placeholder="Enter Website"
                            value={contactInformation?.website ?? ""}
                            onChange={(e) => updateState("contactInformation", "website", e.target.value)}
                            isError={error?.contactInformation?.website?.error ?? false}
                            errorMessage={error?.contactInformation?.website?.errorMsg ?? false}
                        />
                    </Grid>
                </Grid>
            </CustomPaper>
        </div>
    )
}