import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../../utils";
export const useStylesMaster = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflow: "hidden",
  },
  grid: {
    borderRadius: "12px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 16px #00000014",
    height: `calc(100vh - 96px)`,
    padding: 10,
    margin: 5,
    overflow: "auto",
    width: '100%'
  },
  gridList: {
    borderRadius: "12px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 16px #00000014",
    height: `calc(100vh - 96px)`,
    padding: 10,
    margin: 5,
  },
  listText: {
    backgroundColor: "#071741",
    padding: 10,
    borderRadius: 10,
    color: "white",
    opacity: 1,
    cursor: "pointer",
  },
  list: {
    cursor: "pointer",
  },
  close: {
    position: "fixed",
    right: "310px",
    top: 10,
    backgroundColor: "white",
    padding: 5,
    display: "flex",
    borderRadius: 5,
    cursor: "pointer",
  },
  drawerwidth: {
    width: "350px",
    [theme.breakpoints.down("sm")]: {
      width: "318px",
    },
  },
  Header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "14px",
    borderBottom: "1px solid #00000012",
    position: "absolute",
    width: "100%",
    top: "0",
    height: "60px",
    padding: "16px 16px 16px",
    justifyContent: "left",
    backgroundColor: theme.palette.primary.contrastText,
    zIndex: "1",
    "& .MuiTypography-root": {
      fontSize: "16px",
      fontWeight: "bold",
      fontFamily: Bold,
    },
  },
  closeIcondrawermap: {
    right: "360px",
    position: "fixed",
    display: "flex",
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
    fontSize: 28,
    marginTop: 8,
    "& .MuiSvgIcon-root": {
      fontSize: "35px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      right: "11px",
      color: theme.palette.primary.dark,
      top: "0px",
      fontSize: "14px",
    },
  },
  createnewsection: {
    padding: "10px 10px 65px",
    height: "100vh",
    overflow: "auto",
    paddingTop: "80px",
  },
  input: {
    marginTop: '10px',
  },
  btnparent: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    padding: '10px 16px',
    backgroundColor: '#fff',
    borderTop: "1px solid #00000012",
    '& .MuiButton-root': {
      width: '100%'
    }
  },
  parent: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));
