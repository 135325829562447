import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Login1 } from "./login11";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "white",
  },
  grid: {
    alignItems: "start",
  },
  background: {
    backgroundImage: `url(/images/login_background_1-min.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
  },
}));

export const Login = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container className={classes.grid}>
        <Grid item xs={12} sm={5} md={6} lg={7} xl={8} className={classes.background} />
        <Grid item xs={12} sm={7} md={6} lg={5} xl={4}>
          <Login1 />
        </Grid>
      </Grid>
    </div>
  );
};
