import { useApolloClient } from "@apollo/client";
import { Box, Button, Dialog, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingSection, Subheader } from "../../components";
import { AlertContext, AuthContext } from "../../contexts";
import { GET_SHORTLIST_UNIT } from "../../graphql/shortlistedqueries";
import { Routes } from '../../router/routes';
import { accessCheckRender, AlertProps, AssetMasterType, getRoutePermissionNew } from "../../utils";
import { UnitSingleDetails } from "../propertyFinder3/component/singleUnitDetail";
import { UnitCard } from "../propertyFinder3/component/unitCard";
import { useStyles } from "./styles";

export const ShortListUnits = props => {
    const client = useApolloClient();
    const history = useHistory();
    const classes = useStyles();
    const [list, setList] = React.useState([])
    const [selectedUnits, setSelectedunit] = React.useState("")
    const [open, setOpen] = React.useState(false)
    const [openshort, setOpenshort] = React.useState(false)
    const [openshortlead, setOpenshortlead] = React.useState(false)
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext)
    const [loading, setLoading] = React.useState(true)
    const { state } = useLocation()
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                //initial load
                if (state) {
                    getUnitList(state?.unitId)
                } else {
                    history.push(Routes.propertyFinder)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    //short list unit json
    const shortlistType = [
        {
            img: "/images/newlead.svg",
            title: "New Opportunity",
            route: Routes.createQuotation,
            data: {
                type: "new lead",
                unit: list,
                date: {
                    startDate: state?.date?.startDate,
                    endDate: state?.date?.endDate,

                },
                data: state?.data,
                duration: state?.duration,
                companyID: state?.companyID
            }
        }
    ]
    //quotation json
    const shortlistlead = [
        {

            img: "/images/newlead.svg",
            title: "New Opportunity",
            route: Routes.shortlistLead,
            data: {
                type: "new lead",
                unit: list,
                date: {
                    startDate: state?.date?.startDate,
                    endDate: state?.date?.endDate,

                },
                data: state?.data,
                duration: state?.duration,
                companyID: state?.companyID
            }
        }
    ]
    //get unit list
    const getUnitList = (ids) => {
        client.query({
            query: GET_SHORTLIST_UNIT,
            fetchPolicy: 'network-only',
            variables: {
                unit_id: ids,
                assetType: AssetMasterType?.profile
            }
        }).then((res) => {

            setList(res.data?.unit)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    //on delete unit
    const onDelete = (id) => {
        if (list?.length > 1) {
            // const deleted = list.filter((ids) => ids.id !== id)[0]
            // getUnit([deleted?.id]);
            const deleted = list.filter((ids, index) => index !== id)
            setList(deleted);
        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.info,
                msg: "Cannot Delete",
            });
        }
    }
    //on open unit details
    const openDetails = (value) => {
        setOpen(true)
        setSelectedunit(value)
    }
    //on click short list
    const handleShortlist = () => {
        if (state?.duration?.lead) {
            history.push({
                pathname: Routes.shortlistLead,
                state: {
                    type: "existing lead",
                    unit: list,
                    date: {
                        fromDate: state?.data?.duration?.fromDate,
                        toDate: state?.data?.duration?.toDate
                    },
                    data: state?.data,
                    duration: state?.duration,
                    companyID: state?.companyID
                }
            })
        } else {
            history.push({
                pathname: Routes.shortlistLead,
                state: {
                    type: "new lead",
                    unit: list,
                    date: {
                        fromDate: state?.data?.duration?.fromDate,
                        toDate: state?.data?.duration?.toDate
                    },
                    data: state?.data,
                    duration: state?.duration,
                    companyID: state?.companyID
                }
            })
        }
    }
    //on click create quotation
    const handleQuotation = () => {

        if (state?.duration?.lead) {

            history.push(
                {
                    pathname: Routes.createQuotation,
                    state: {
                        type: "existing lead",
                        unit: list,
                        date: {
                            fromDate: state?.data?.duration?.fromDate,
                            toDate: state?.data?.duration?.toDate
                        },
                        data: state?.data,
                        duration: state?.duration,
                        companyID: state?.companyID
                    }
                })

        } else {
            history.push(
                {
                    pathname: Routes.createQuotation,
                    state: {
                        type: "new lead",
                        unit: list,
                        date: {
                            fromDate: state?.data?.duration?.fromDate,
                            toDate: state?.data?.duration?.toDate
                        },
                        data: state?.data,
                        duration: state?.duration,
                        companyID: state?.companyID
                    }
                })
        }
    }

    const render = () => {
        return <>
            {/*subheader*/}
            <Subheader goBack={() => history.goBack()} title="Shortlisted Units" />
            <div className={classes.root}>
                {
                    loading ? <LoadingSection bottom="40vh" />
                        :
                        <div className={classes.content1}>
                            <Grid container spacing={2}>
                                {/*unit list*/}
                                {list.map((val, index) => {
                                    return (
                                        <Grid item lg={2.5} md={4} sm={6} xs={12} >
                                            <UnitCard currency={val?.currencyByID?.symbol}
                                                onDelete={() => onDelete(index)}
                                                data={{ ...val, unit_type: val?.unit_type?.name }}
                                                type={val?.type}
                                                delete={true}
                                                onClick={openDetails} />
                                        </Grid>

                                    )
                                })}
                            </Grid>
                        </div>
                }

                <Divider />

                {/*button container*/}
                <div className={classes.content2}>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                            <Typography className={classes.shortTitle}>
                                {list?.length}&nbsp;Shortlisted
                            </Typography>
                        </Box>
                        {permission?.create && <>
                            <Box>
                                {!state?.disable &&
                                    <Button onClick={handleShortlist} variant="contained"
                                        className={classes.btn1}>
                                        Shortlist To Opportunity
                                    </Button>}
                            </Box>
                            <Box marginLeft="8px">
                                <Button onClick={handleQuotation} variant="contained"
                                    className={classes.btn2}>
                                    Create Quotation
                                </Button>
                            </Box>
                        </>}
                    </Box>
                </div>


            </div>
            {/*Dialog for unit Details*/}
            <Dialog maxWidth="xs" className={classes.dialog} fullWidth open={open} onClose={() => setOpen(false)}>
                <UnitSingleDetails
                    revenue_type={state?.data?.revenue_type}
                    grace={state.data?.stateFilter?.facilitesbool}
                    startDate={state?.data?.duration?.fromDate}
                    endDate={state?.data?.duration?.toDate}
                    shortlist={true} selectedUnits={selectedUnits} onClose={() => setOpen(false)} />
            </Dialog>
            {/*Dialog for shortlist*/}
            <Dialog maxWidth="sm" className={classes.dialog1} fullWidth open={openshort} onClose={() => setOpenshort(false)}>
                <Grid container spacing={2} justifyContent="center">
                    {
                        shortlistType?.map((val) => {
                            return (
                                <Grid item xs={6}>
                                    <div className={classes.type} onClick={() => history.push({
                                        pathname: val?.route,
                                        state: val?.data
                                    })}>
                                        <img src={val?.img} alt="" />
                                        <center>
                                            <Typography className={classes.typename}>{val?.title}</Typography>
                                            <img src="/images/nextarrow.svg" alt="" />
                                        </center>
                                    </div>
                                </Grid>
                            )
                        })
                    }

                </Grid>
            </Dialog>
            {/*Dialog for quotation*/}
            <Dialog maxWidth="sm" className={classes.dialog1} fullWidth open={openshortlead} onClose={() => setOpenshortlead(false)}>
                <Grid container spacing={2} justifyContent="center">
                    {
                        shortlistlead?.map((val) => {
                            return (
                                <Grid item xs={6}>
                                    <div className={classes.type} onClick={() => history.push({
                                        pathname: val?.route,
                                        state: val?.data
                                    })}>
                                        <img src={val?.img} alt="" />
                                        <center>
                                            <Typography className={classes.typename}>{val?.title}</Typography>
                                            <img src="/images/nextarrow.svg" alt="" />
                                        </center>
                                    </div>
                                </Grid>
                            )
                        })
                    }

                </Grid>
            </Dialog>
        </>
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div >
}