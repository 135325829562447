import { Avatar, Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import DeleteIcon from '../../../assets/delete'
import { LeadCardStyle } from './style'

export const ListCard = ({
    name = "",
    url = "",
    leadNo="",
    handleDelete = () => false }) => {
        const classes = LeadCardStyle()
    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"} spacing={2}>
                    <Avatar className={classes.avatar} src={url}></Avatar>
                    <Stack>
                        <Typography className={classes.title}>{name}</Typography>
                        <Typography className={classes.subTitle}>{leadNo}</Typography>
                    </Stack>
                </Stack>
                <Box onClick={() => handleDelete()}>
                    <DeleteIcon />
                </Box>
            </Stack>
        </>
    )
}
