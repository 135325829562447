import { Grid, Typography } from "@mui/material";
import ReactQuill from "react-quill";
import { useStyles } from "../styles";
export const Profile = ({ data = {} }) => {
    const classes = useStyles()
    const modules = { toolbar: false };

    return (

        <Grid container className={classes.box}>
            <Grid item xs={12} sm={12} md={2} lg={1.5} >
                <div className={classes.imgDiv}>
                    <img src={data?.logo ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg} />
                    <Typography className={classes.imgTag}>{data?.account_no}</Typography>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10.5} className={classes.imgDiv2} >
                <Typography className={classes.title} marginBottom={"14px"}>Profile Details</Typography>

                <Grid container >
                    <Grid item md={12} lg={12}>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                            <Grid item xs={3}>
                                <Typography className={classes.heading}>Company Name</Typography>
                                <Typography className={classes.sub}>{data?.company?.company_name}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={classes.heading}>Account Name</Typography>
                                <Typography className={classes.sub}>{data?.name}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={classes.heading}>Relationship</Typography>
                                <Typography className={classes.sub}>
                                    {data?.relationship}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={classes.heading}>{data?.relationship}</Typography>
                                <Typography className={classes.sub}>
                                    {data?.contact?.label}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.heading}> Business Description</Typography>
                                <ReactQuill
                                    readOnly
                                    theme="bubble"
                                    value={data?.description}
                                    modules={modules}
                                    className={classes.sub}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    )
}