import React, { useContext, useState } from "react";
import { useStyles } from "./style";
import {
  FilterGenerator,
  SearchFilter,
  Subheader,
  TableWithPagination,
  FormGenerator,
} from "../../components";
import { AuthContext, BackdropContext, AlertContext } from "../../contexts";
import { Box, Divider, Grid, IconButton, Stack } from "@mui/material";
import {
  KycListheading,
  KycListpath,
  KycListheading_account,
  KycListpath_account,
  kycdataType_account,
  kycdataType,
  NetWorkCallMethods,
  AlertProps,
  enumSelect,
  enum_types,
  keyMapSource,
  getCompanyOption,
  accessCheckRender,
  getRoutePermissionNew,
} from "../../utils";
import ViewCard from "./viewCard";
import { GET_DETAILS_KYC_DETAILS } from "../../graphql/queries";
import { useApolloClient } from "@apollo/client";
import moment from "moment-timezone";
import { withNamespaces } from "react-i18next";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";

const KycScreen = (props) => {
  // language
  const { t } = props;
  // class
  const classes = useStyles();
  // context
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  // apolo clint
  const client = useApolloClient();
  //   state
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [searchText, setSearchText] = useState("");
  const [list, setList] = useState({
    list: [],
  });
  const [drawer, setDrawer] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filterData, setFilterData] = useState({
    status: "",
    date: {
      startDate: "",
      endDate: "",
    },
  });
  const [status, setStatus] = useState("Contact");
  const [details, setDetails] = useState({
    data: {},
    bool: false,
  });
  const [enumValue, setEnumValue] = React.useState([]);
  // company drop down option
  const auth = useContext(AuthContext);
  const [permission, setPermission] = React.useState({})

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
          setCompanyList(company?.list)
          setSelectedCompany(company?.selected)
          if (company?.list?.length > 0) {
            getEnum(company?.selected);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);
  // filter drawer
  const OpenDrawer = () => {
    setDrawer(!drawer);
  };
  //   filter apply
  const onApplyFilter = (value) => {
    if (value?.date?.startDate > value?.date?.endDate) {
      setDrawer(true);
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.info,
        msg: "Valid To is Must Be Greater than Valid From",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    } else {
      setFilterData(value);
      GetList(
        selectedCompany?.value,
        status,
        0,
        limit,
        value?.status,
        value?.date?.startDate,
        value?.date?.endDate
      );
    }
  };
  //   handle pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    GetList(
      selectedCompany?.value,
      status,
      offset,
      limit,
      filterData?.status ?? "",
      filterData?.date?.startDate ?? "",
      filterData?.date?.endDate ?? "",
      searchText
    );
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    GetList(
      selectedCompany?.value,
      status,
      0,
      value,
      filterData?.status ?? "",
      filterData?.date?.startDate ?? "",
      filterData?.date?.endDate ?? "",
      searchText
    );
  };
  const GetList = (
    v,
    proof_type,
    offset,
    limit,
    status,
    vaild_from,
    valid_to,
    searchText
  ) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading ...",
    });

    const payload = {
      company_id: v,
      proof_type: [proof_type],
      offset: offset,
      limit: limit,
      search: searchText?.length > 0 ? searchText : undefined,
      is_mandatory: status !== "" ? status : undefined,
      from_date:
        JSON.stringify(vaild_from)?.length > 2
          ? `${moment(vaild_from).format("YYYY-MM-DD")}`
          : undefined,
      to_date:
        JSON.stringify(valid_to)?.length > 2
          ? `${moment(valid_to).format("YYYY-MM-DD")}`
          : undefined,
    };
    NetworkCall(
      `${config.api_url}/kyc/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        let list = res?.data?.data?.map((val) => {
          return {
            Name: val?.contact_name ?? "",
            ID: val?.type ?? "",
            Name_per_id: val?.name ?? "",
            ID_Number: val?.number ?? "",
            Verify: val?.is_mandatory ? "Yes" : "No" ?? "",
            Valid_from: val?.valid_from ?? "",
            Valid_to: val?.valid_to ?? "",
            Issuing_Country: val?.country_name ?? "",
            id: val?.id,
          };
        });
        let account_list = res?.data?.data?.map((val) => {
          return {
            account_no: `${val?.account_no ?? ""}`,
            account_number: `${val?.account_name ?? ""}`,
            ID: val?.type ?? "",
            Name_per_id: val?.name ?? "",
            ID_Number: val?.number ?? "",
            Verify: val?.is_mandatory ? "Yes" : "No" ?? "",
            Valid_from: val?.valid_from ?? "",
            Valid_to: val?.valid_to ?? "",
            Issuing_Country: val?.country_name ?? "",
            id: val?.id,
          };
        });
        setList({
          count: res?.data?.total_records ?? 0,
          list: proof_type === "Contact" ? list ?? [] : account_list ?? [],
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading ...",
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  // handleIcon
  const handleIcon = (type, data) => {
    getData(data?.id);
  };
  // get kyc details
  const getData = (v) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    client
      .query({
        query: GET_DETAILS_KYC_DETAILS,
        fetchPolicy: "network-only",
        variables: {
          id: v ?? "",
        },
      })
      .then((res) => {
        setDetails({
          data: res?.data?.know_your_client?.[0],
          bool: true,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading",
        });
      });
  };
  // get enum
  const getEnum = async (company) => {
    const result = await enumSelect([enum_types?.proof_type]);
    const filterData = await keyMapSource({ enumType: result?.proof_type });
    setEnumValue(filterData);
    GetList(
      company?.value,
      filterData?.type1,
      0,
      limit,
      filterData?.status ?? "",
      filterData?.date?.startDate ?? "",
      filterData?.date?.endDate ?? "",
      ""
    );
  };

  const render = () => {
    return <>
      {/* sub nav bar */}
      <Subheader
        hideBackButton={true}
        title={`${status === enumValue?.type2 ? "Contact's KYC" : "Account's KYC"}(${list?.count ?? 0
          })`}
        select
        options={companyList}
        value={selectedCompany}
        onchange={(e) => {
          setSelectedCompany(e);
          GetList(
            e?.value,
            enumValue?.type2 ?? "Contact",
            0,
            limit,
            filterData?.status ?? "",
            filterData?.date?.startDate ?? "",
            filterData?.date?.endDate ?? "",
            searchText
          );
        }}
      />
      {/* table */}
      <div className={classes.main}>
        <Grid container className={classes.content} spacing={1}>
          {/* tabel */}
          <Grid item xs={details?.bool ? 8 : 12}>
            <div className={classes.root}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Box>
                    {/* search box */}
                    <SearchFilter
                      value={searchText}
                      placeholder="Search"
                      handleChange={(value) => {
                        setSearchText(value);
                        GetList(
                          selectedCompany?.value,
                          status,
                          0,
                          limit,
                          filterData?.status ?? "",
                          filterData?.date?.startDate ?? "",
                          filterData?.date?.endDate ?? "",
                          value
                        );
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={8}>
                  <Box
                    display={"flex"}
                    sx={{ float: "right" }}
                    alignItems={"center"}
                  >
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={2}
                      alignItems={"center"}
                    >
                      <Box>
                        <IconButton
                          size="small"
                          className={classes.img}
                          onClick={OpenDrawer}
                        >
                          <img src="/images/filter.svg" alt="filter" />
                        </IconButton>
                      </Box>
                      <Box display={"flex"}>
                        <FormGenerator
                          components={[
                            {
                              size: {
                                xs: 12,
                              },
                              isActive: true,
                              component: "toggle",
                              value: status,
                              onChange: (value) => {
                                if (value !== null) {
                                  setStatus(value);
                                  setDetails({ ...details, bool: false });
                                  GetList(
                                    selectedCompany?.value,
                                    value,
                                    0,
                                    limit
                                  );
                                }
                              },
                              options: [
                                { label: "Contact", value: enumValue?.type1 },
                                {
                                  label: "Account",
                                  value: enumValue?.type2,
                                },
                              ],
                            },
                          ]}
                        />
                      </Box>
                    </Stack>
                  </Box>
                </Grid>
                {/* Main tableData */}
                <Grid item xs={12} className={classes.tableMain}>
                  {/* tabel */}
                  <TableWithPagination
                    heading={
                      status === enumValue?.type1
                        ? KycListheading(t)
                        : KycListheading_account(t)
                    }
                    rows={list?.list ?? ""}
                    path={
                      status === enumValue?.type1
                        ? KycListpath
                        : KycListpath_account
                    }
                    showpagination={true}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    totalRowsCount={list?.count}
                    handleIcon={handleIcon}
                    page={page}
                    limit={limit}
                    dataType={
                      status === enumValue?.type1
                        ? kycdataType
                        : kycdataType_account
                    }
                    height={"calc(100vh - 315px)"}
                    view={permission?.read}
                    edit={permission?.update}
                    delete={permission?.delete} />
                </Grid>
                {/* end Main tableData */}
              </Grid>
            </div>
          </Grid>
          {/* view card */}
          {details?.bool && (
            <Grid item xs={4}>
              <div className={classes.root1}>
                <ViewCard details={details?.data} />
              </div>
            </Grid>
          )}
        </Grid>

        {/* filter drawer */}
        {drawer && (
          <FilterGenerator
            open={drawer}
            onClose={() => setDrawer(false)}
            components={[
              {
                component: "toggleButton",
                value: filterData?.status,
                options: [
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ],
                isMulti: false,
                state_name: "status",
                label: "Mandatory Verify",
              },
              {
                component: "minAndmaxDate",
                label: {
                  startLabel: "Valid From",
                  endLabel: "Valid To",
                },
                placeholder: {
                  startLabel: "Valid From",
                  endLabel: "Valid To",
                },
                state_name: "date",
                value: filterData?.date,
              },
            ]}
            onApply={(value) => onApplyFilter(value)}
          />
        )}
      </div>
    </>
  }

  return (
    <div>
      {accessCheckRender(render, permission)}
    </div>
  );
};
export default withNamespaces("profileswitcher")(KycScreen);
