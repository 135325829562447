import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { VerticalLinearStepper } from "../verticalStepper";
import { DetailsCardStyle } from "./styles";
import {timeZoneConverter} from "../../utils"

export const DetailsCard = ({
  title = "",
  data = [],
  viewAll = false,
  AllData = [],
  buttonName = "",
  icon = "",
  handleStatusClick = () => false,
  reassign = false,
  onClick = () => false,
  is_active = false,
  onButtonClick = () => false
}) => {
  const [showAllData, setshowAllData] = useState(false);
  const classes = DetailsCardStyle();
  const returnDateFormat = (date, format = "DD MMM YY", delimiter = "") => date ? timeZoneConverter(date , format) : delimiter;
  return (
    <Box className={`${classes.root}`} position="relative">
      {/* header */}
      <Box className={`${classes.cardHeader}`}>
        {/* title */}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography className={`${classes.title}`}>{title}</Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
          {
            reassign &&
            <Typography onClick={onClick} className={classes.Reassign}>Re Assign</Typography>
          }
          {/* view More */}
          {viewAll && AllData?.length > 1 && (
            <div onClick={() => setshowAllData(!showAllData)}>
              <Typography className={`${classes.viewMore}`}>
                {showAllData === true ? "View less" : "View more"}
              </Typography>
            </div>
          )}
          </Stack>
        </Stack>

        {/* status */}
        {data?.status && (
          <Typography
            className={
              data?.status === "Assigned"
                ? `${classes.Reassign}`
                : `${classes.status}`
            }
            onClick={data?.status === "Assigned" && handleStatusClick}
          >
            {data?.status}
          </Typography>
        )}
      </Box>
      {showAllData === true ? (
        <>
          {title === "Track Update" ? (
            // stepper
            <Box className={`${classes.stepperdiv}`}>
              <VerticalLinearStepper data={AllData} icon={icon} />
            </Box>
          ) : (
            <Box>
              <Box position="absolute" className={classes.detailsDrop} backgroundColor="white">
                {AllData?.map((x, index) => (
                  // view more data
                  <Stack direction="column" spacing={1}>
                    <Box className={`${classes.subHeader}`}>
                      <Box className={`${classes.info}`}>
                        {icon !== "" ? (
                          <div
                            className={
                              title === "Gate Pass"
                                ? `${classes.gatePass}`
                                : title === "Tenant Login"
                                  ? `${classes.tenant}`
                                  : title === "Track Update"
                                    ? `${classes.track}`
                                    : ""
                            }
                          >
                            {icon}
                          </div>
                        ) : (
                          <img
                            src={x.image.length > 0 ? x.image : "https://eitrawmaterials.eu/wp-content/uploads/2016/09/person-icon.png"}
                            alt="icon"
                            className={`${classes.image}`}
                          />
                        )}
                        <Box className={`${classes.infodata}`}>
                          <Typography className={`${classes.Name}`}>
                            {x?.name}
                          </Typography>
                          {x?.sub_title &&
                            <Typography className={`${classes.description}`}>
                              {x?.sub_title} | {x?.resource_type}
                            </Typography>
                          }

                          {/* {x.street_1 &&

                            <Typography className={`${classes.description}`}>
                              {x.street1 ? (x.street1 + ", ") : " "}
                              {x.street2 ? (x.street2 + ", ") : " "}
                              {x.street3 ? (x.street3 + ", ") : " "}
                              {x.district ? (x.district + ", ") : " "}
                              {x.city ? (x.city + ", ") : " "}
                              {x.state ? (x.state + ", ") : " "}
                              {x.zipcode ? (x.zipcode + ", ") : " "}
                            </Typography>
                          } */}
                        </Box>
                      </Box>
                    </Box>
                    <Divider
                      className={
                        index === AllData?.length - 1
                          ? `${classes.alt_divider}`
                          : `${classes.divider}`
                      }
                    />
                  </Stack>
                ))}
              </Box>
            </Box>
          )}
        </>
      ) : (
        <>
          <Box className={`${classes.subHeader}`}>
            <Box className={`${classes.info}`} marginTop="-4px">
              {/* icon */}
              {icon !== "" ? (
                <div
                  className={
                    title === "Gate Pass"
                      ? `${classes.gatePass}`
                      : title === "Tenant Login"
                        ? `${classes.tenant}`
                        : title === "Track Update"
                          ? `${classes.track}`
                          : ""
                  }
                >
                  {icon}
                </div>
              ) : (
                <img
                  src={data?.image?.length > 0 ? data.image : "https://eitrawmaterials.eu/wp-content/uploads/2016/09/person-icon.png"}
                  alt="icon"
                  className={`${classes.image}`}
                />
              )}

              <Box className={`${classes.infodata}`}>
                {/* name */}
                <Typography className={`${classes.Name}`}>
                  {title === "Tenant Login" ?
                    <Box mt={0}>Tenant Login Link</Box>
                    : title === "Gate Pass" ?
                      <Box mt={0}>Truck Gate-Pass</Box> :
                      data?.name ?? data?.first_name ?? data?.agreement_no ?? "undefined"
                  }
                </Typography>
                {/* description */}
                {data?.description && (
                  <Typography
                    className={
                      title === "Gate Pass" || title === "Tenant Login"
                        ? `${classes.newdescription}`
                        : `${classes.description}`
                    }
                  >
                    {data?.description}
                  </Typography>
                )}
                {data?.reference_id && (
                  <Typography
                    className={
                      title === "Gate Pass" || title === "Tenant Login"
                        ? `${classes.newdescription}`
                        : `${classes.description}`
                    }
                  >
                    {data?.reference_id}
                  </Typography>
                )}
                {data?.lease_start_date && (
                  <Typography
                    className={
                      title === "Gate Pass" || title === "Tenant Login"
                        ? `${classes.newdescription}`
                        : `${classes.description}`
                    }
                  >
                    {returnDateFormat(data?.lease_start_date, "", "-") + " - " + returnDateFormat(data?.lease_end_date, "", "-")}
                  </Typography>
                )}
                {data?.created_at && (
                  <Typography
                    className={
                      title === "Gate Pass" || title === "Tenant Login"
                        ? `${classes.newdescription}`
                        : `${classes.description}`
                    }
                  >
                    Last Link sent on {returnDateFormat(data?.created_at)}
                  </Typography>
                )}
                {/* number */}
                {data?.mobile_no && (
                  <Typography className={`${classes.description}`}>
                    {`${data?.mobile_no_country_code} ${data?.mobile_no}`}
                  </Typography>
                )}
                {/* address */}
                {data?.street_1 && (
                  <Typography className={`${classes.description}`}>
                    {data?.street1 ? (data?.street1 + ", ") : " "}
                    {data.street2 ? (data.street2 + ", ") : " "}
                    {data.street3 ? (data.street3 + ", ") : " "}
                    {data.district ? (data.district + ", ") : " "}
                    {data?.city ? (data?.city + ", ") : " "}
                    {data.state ? (data.state + ", ") : " "}
                    {data.country ? (data.country + ", ") : " "}
                  </Typography>
                )}
              </Box>

            </Box>

            {/* count */}
            {AllData?.length > 1 && title !== "Track Update" && (

              <Typography className={`${classes.count}`}>
                {" "}
                +{AllData?.length - 1}
              </Typography>

            )}
            {/* button */}
            {(title === "Gate Pass" || title === "Tenant Login") && (
              <Box>
                <Button className={!is_active ? `${classes.btnsend}` : classes.activeText} onClick={() => onButtonClick(title)}>
                  {buttonName}
                </Button>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
