import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        order: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        margin: "16px"
    },
    detailsContainer: {
        borderLeft: "1px solid #E4E8EE",
        height: `calc(100vh - 150px)`,
        overflow: "auto"
    },
    btn: {
        padding: "8px 12px",
        boxShadow: "none"
    },
    title: {
        fontSize: "16px",
        color: "#4E5A6B",
        fontFamily: Bold,
        marginBottom: "8px"

    },
}))