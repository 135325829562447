import { makeStyles } from "@mui/styles";
import { Bold, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        padding: "16px",
        backgroundColor: "white",
        margin: "14px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
    },
    hdrtitle: {
        fontSize: "16px",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    rootblock: {
        backgroundColor: "#FFFFFF",
        borderRadius: theme.palette.borderRadius,
        padding: "10px",
        position: "relative"
        // height:"560px"
        // margin: "15px"
    },
    cmpbtn: {
        position: "absolute",
        bottom: "0%",
        left: "0%",
        width: "100%",
        padding: "10px"
    },
    completeInsp: {
        padding: "20px"
    },
    nobtn: {
        '&:hover': {
            background: "#fff"
        }
    },
    msg: {
        fontSize: "16px",
        fontFamily: SemiBold
    },
    successbtn:{
        "&:hover": {
            backgroundColor: "#5AC782"
          }, 
    },
    cancelbtn:{
        "&:hover": {
            backgroundColor: "#FF4B4B"
          }, 
    },
    accoCardButton: {
        fontSize: 14,
        fontWeight: "normal",
        '&:hover': {
            color: "#fff"
        }
    },
    cancelButton: {
        fontSize: 14,
        backgroundColor: theme.typography.color.white,
        color: theme.palette.primary.main,
        marginRight: "10px",
        border: `1px solid ${theme.palette.border.secondary}`,
        '&:hover': {
            backgroundColor: theme.typography.color.white,

        }
    },
}))