import React from "react";
import { withNavBars } from "../../HOCs";
import { QuotationDetails } from './quotationDetails';
class QuotationDetailsParent extends React.Component {
    render() {
        return <QuotationDetails />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(QuotationDetailsParent, props);
