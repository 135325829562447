import React, { useContext } from "react";
import HomeIcon from "@mui/icons-material/Home";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import BackgroundType from "../../../assets/leaseBackground";
import MobileIcon from "../../../assets/mobileIcon";
import QuotationLeaseIcon from "../../../assets/quotationLeaseIcon";
import SourceTypeIcon from "../../../assets/sourceIcon";
import { useStyles } from "./styles";
import { UnitSelection } from "./unitSelection";
import { SourceMenuOption } from "../../../components";
import { CreateOppertunityContext } from "../../../contexts/createOppertunityContext";
import { InterestedUnitsCard } from "./interstedUnitsCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const LeadDetailsCard = ({
  data = {},
  hideDetails = false,
  source_enum = [],
}) => {
  const { dataNew, updateStateNew, setDataNew } = useContext(
    CreateOppertunityContext
  );
  const classes = useStyles();
  // onChange Unit
  const onChangeUnits = (unit, owner, type) => {
    setDataNew({
      ...dataNew,
      selectedUnit: unit ?? [],
      lead_owner: type === 1 ? owner : null,
      property_id: type === 2 ? owner : null,
      edit_owner: null,
      // units: unit?.filter((val) => val?.is_active === true) ?? [],
    });
  };

  return (
    <Box>
      <Box className={classes.detailRoot}>
        <Stack direction="row" spacing={1.5}>
          <div>
            <img
              className={classes.userImg}
              src={data?.image ?? "images/profile_image.png"}
              alt=""
            />
          </div>
          <div>
            <Typography className={classes.leadName}>{data?.name}</Typography>
            {/* <Typography className={classes.leadNo}>LD-4325425</Typography> */}
          </div>
        </Stack>
        <Box className={classes.typeBg}>
          <BackgroundType
            color={
              data?.type === "Lease"
                ? "#6d6db3"
                : data?.type === "Sale"
                ? "#f4a261"
                : "#690375"
            }
          />
          <Box className={classes.centeredText} display={"flex"}>
            <QuotationLeaseIcon />
            <Typography className={classes.type}>{data?.type}</Typography>
          </Box>
        </Box>
      </Box>
      <Stack direction={"row"} spacing={1}>
        <MobileIcon />
        <Typography className={classes.mobileNo}>{data?.mobile}</Typography>
      </Stack>
      <Stack direction={"row"} spacing={1} mt={1.5}>
        <MailOutlinedIcon className={classes.mailIcon} />
        <Typography className={classes.mobileNo}>{data?.email}</Typography>
      </Stack>
      <Box height={"12px"} />
      <Grid container spacing={3}>
        <Grid item xs={6} className={classes.gridFlex}>
          <Box display="flex" alignItems={"center"}>
            <SourceTypeIcon />
            <Typography className={classes.srcText} ml={1}>
              Source
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <SourceMenuOption
            value={dataNew?.source?.value ?? ""}
            onChange={(value) => updateStateNew("source", value)}
            option={source_enum ?? []}
          />
        </Grid>
      </Grid>
      <Box height={"12px"} />
      <Grid container spacing={3}>
        <Grid item xs={6} className={classes.gridFlex}>
          <Box display="flex" alignItems={"center"}>
            <HomeIcon className={classes.homeIcon} />
            <Typography className={classes.typeText}>Type</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            className={classes.purposebox}
          >
            <Typography className={classes.typeText} noWrap>
              {data?.purpose ?? "-"}
            </Typography>
            <KeyboardArrowDownIcon className={classes.arrowIcons} />
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Divider />
      </Box>
      {dataNew?.revenue_type?.value === "Sale"
        ? dataNew?.earlist_occupation_date !== "" && (
            <UnitSelection
              datas={dataNew}
              onChange={onChangeUnits}
              typeData={data?.type}
              purpose={data?.purpose}
            />
          )
        : dataNew?.lease_start_date !== null &&
          dataNew?.lease_start_date !== "" && (
            <UnitSelection
              datas={dataNew}
              onChange={onChangeUnits}
              typeData={data?.type}
              purpose={data?.purpose}
            />
          )}
      <Box height={"14px"} />
      {dataNew?.units?.length > 0 && (
        <InterestedUnitsCard list={dataNew?.units ?? []} />
      )}
    </Box>
  );
};
