import React from "react";
import { withNavBars } from "../../HOCs";
import { ParkingBooking } from "./parkingBooking";

class ParkingBookingParent extends React.Component {
    render() {
        return <ParkingBooking />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ParkingBookingParent, props);
