import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import FilterIcon from "../../../assets/filterIcon";
import { SearchFilter, TableWithPagination } from "../../../components";
import { Routes } from "../../../router/routes";
import { Accountheading, Accountpath, dataType } from "../../../utils/accountUtiles";
import { useStyles } from "../styles";

export const AccountTable = ({
    handleSearch = () => false,
    searchText = "",
    handleIcon = () => false,
    data = {},
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    selectedCompany = {},
    permission = {},
    setIsFilterData = "",
    type = null
}) => {

    const typeSelect = {
        "Vendor": Routes.vendorAccountCreate,
        "Property Owner": Routes.ownerAccountCreate
    }

    const classes = useStyles()
    const history = useHistory()
    const goCreate = () => {
        history.push({
            pathname: type?.length > 0 ? typeSelect[type] : Routes.createAccount,
            state: selectedCompany
        })
    }
    const openFilter = () => {
        setIsFilterData(true)
    }
    return (
        <Box>
            <Grid container justifyContent="space-between">
                <Grid item xs={4}>
                    {/*search field*/}
                    <SearchFilter value={searchText} placeholder={"Search Account"} handleChange={(value) => handleSearch(value)} />
                </Grid>
                <Grid item xs={8}>
                    {/*Add Btn*/}
                    <Box display={"flex"} justifyContent={"end"}>
                        <Stack direction="row" spacing={2}
                            divider={<Divider orientation="vertical" flexItem />}>
                            <Box>
                                <FilterIcon
                                    onClick={() => openFilter()} />
                            </Box>
                            {
                                type !== "Customer" &&
                                <Button
                                    onClick={goCreate}
                                    variant="contained" className={classes.btn}>New Account</Button>
                            }


                        </Stack>
                    </Box>
                    {/* {permission?.create && <Button
                        onClick={goCreate}
                        variant="contained" className={classes.btn}>Add An Account</Button>} */}
                </Grid>
            </Grid>
            <TableWithPagination
                heading={Accountheading}
                rows={data?.list}
                path={Accountpath}
                showpagination={true}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                count="2"
                handleIcon={handleIcon}
                dataType={dataType}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={data?.count}
                page={page}
                limit={limit}
                height={`calc(100vh - 300px)`}
                view={permission?.read}
                edit={permission?.update}
                delete={permission?.delete}
            />


        </Box>
    )
}