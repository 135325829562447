import * as React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { Stack, Typography } from '@mui/material';
import { useStyles } from './style';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import { ampmOptions } from '../../utils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popover from '@mui/material/Popover';

export const TimePickerNew = (props) => {
    const classes = useStyles(props);
    const [ampm, setampm] = React.useState({})
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined;

    React.useEffect(() => {
        updateAmPm(props?.value)
        // eslint-disable-next-line
    }, [])

    const getLabel = (props) => {
        return (
            <Typography className={classes.Label} noWrap>
                {props.label}
                {props.isRequired && (
                    <Typography variant="caption" className={classes.required}>
                        *
                    </Typography>
                )}
            </Typography>
        );
    };

    const onAmPMChange = (value) => {
        let tempValue = dayjs()
        if (value?.value === "AM") {
            tempValue = props?.value?.subtract(12, 'hour')
            setampm(ampmOptions[0])
        } else {
            tempValue = props?.value?.add(12, 'hour')
            setampm(ampmOptions[1])
        }
        props?.onChange(tempValue)
        handleClose()
    }

    const onTimePick = (value) => {
        updateAmPm(value)
        props?.onChange(value)
    }

    const updateAmPm = (value) => {
        if (value?.hour() < 12) {
            setampm(ampmOptions[0])
        } else {
            setampm(ampmOptions[1])
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction={"column"} className={classes.root}>
                {getLabel(props)}
                <Stack className={classes.content}
                    direction={"row"} spacing={"8px"}
                    divider={<Box className={classes.divider} />}>
                    <MobileTimePicker
                        closeOnSelect
                        toolbarTitle={"Select " + props?.label}
                        views={['hours', 'minutes']}
                        inputFormat="hh:mm"
                        disabled={props?.isReadonly}
                        value={props?.value}
                        onChange={onTimePick}
                        renderInput={(params) => <TextField
                            {...params} className={classes.textbox}
                            variant={props.variant ?? "outlined"}
                            InputProps={{
                                style: {
                                    fontSize: "14px",
                                }
                            }}
                        />}
                    />
                    <Stack className={classes.ampmContainer}
                        aria-describedby={id} justifyContent={"space-between"}
                        alignItems={"center"} direction={"row"}
                        spacing={"8px"} onClick={handleClick}>
                        <Typography className={classes.ampm}>{ampm?.value}</Typography>
                        <ArrowDropDownIcon fontSize='medium' htmlColor='#98A0AC' />
                    </Stack>
                </Stack>
                {props.isError && (
                    <Typography variant={"caption"} color={"error"}>
                        {props.errorMessage}
                    </Typography>
                )}
            </Stack>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}>
                {ampmOptions?.map((_) => {
                    return <Typography
                        className={ampm?.value === _?.value ?
                            classes.selectedAmPm : classes.unSelectedAmPm}
                        onClick={() => onAmPMChange(_)}>
                        {_?.label}
                    </Typography>
                })}
            </Popover>
        </LocalizationProvider>
    );
}
