import { Box } from "@mui/material";
import React from "react";
import { CreateQuotationListContext } from "../../../contexts/createQuotationContext";
import { LeaseQuotationForm, SaleQuotationForm, ShortTermQuotationForm } from "../component";
export const QuotationDetailesNew = () => {
    const { dataNew, updateStateNew, setDataNew } = React.useContext(CreateQuotationListContext);
    return (
        <Box>
            {
                (dataNew?.searchdata?.revenue_type === "Lease" || dataNew?.searchdata?.revenue_type === "Manage") &&
                <LeaseQuotationForm
                    data={dataNew}
                    setData={setDataNew}
                    updateState={updateStateNew}
                />
            }
            {
                dataNew?.searchdata?.revenue_type === "Sale" &&
                <SaleQuotationForm
                    data={dataNew}
                    setData={setDataNew}
                    updateState={updateStateNew}
                />

            }
            {
                dataNew?.searchdata?.property_purpose === "Short Term Rental" &&
                <ShortTermQuotationForm
                    data={dataNew}
                    setData={setDataNew}
                    updateState={updateStateNew}
                />
            }
        </Box>
    )
}