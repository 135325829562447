import { useApolloClient } from "@apollo/client";
import { Box, Grid } from "@mui/material";
import React from 'react';
import { DialogWithTable } from "../../components/dialogWithTable";
import { GET_ALL_SECURITY_REQUEST, GET_ALL_UNIT_AGREEMENT, GET_ALL_UNIT_INVOICE_OLD, GET_RESIDNET_ACCOUNTS, GET_OTHER_RESIDENTS, GET_ALL_COUNTS } from '../../graphql/queries';

export const OtherInfoCard = (props) => {
    const allUnitid = props?.allUnitid
    const agreementId = props?.agreementId
    const client = useApolloClient();
    const [count, setCount] = React.useState({
        accounts: 0,
        otherResidents: 0,
        request: 0,
        agreement: 0,
        invoice: 0
    })

    const tableData = [
        {
            id: 1,
            title: "Security Requests",
            color: "#5078E1",
            count: count?.request,
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Request Id ", field: 'requestId', },
                    { title: "Parent Name", field: 'requestName', },
                    { title: "Category", field: 'category', },
                    { title: "Raised Date", field: 'raisedDate', },
                    { title: "Status", field: 'status', },
                    { title: "Description", field: 'description', },

                ],
                path: ["sno", "requestId", "requestName", "category", "raisedDate", "status", "description",],
                dataType: [
                    { type: ["sno"], name: "sno" },
                    { type: ["text"], name: "requestId" },
                    { type: ["text"], name: "requestName" },
                    { type: ["text"], name: "category" },
                    { type: ["text"], name: "raisedDate" },
                    { type: ["status"], name: "status" },
                    { type: ["text"], name: "description" },

                ],
            },
            query: GET_ALL_SECURITY_REQUEST,
            // unitId: unitId,
            unitId: allUnitid
        },
        {
            id: 2,
            title: "Unit Agreements",
            color: "#E29336",
            count: count?.agreement,
            table: {
                heading: [
                    { title: "Agreement No ", field: 'agreementNo', },
                    { title: "Valid From", field: 'validfrom', },
                    { title: "Valid To", field: 'validto', },
                    { title: "Agreement primary contact name ", field: 'agreementuser', },
                    { title: "Contact No", field: 'contactNo', },
                    { title: "email Id", field: 'emailAddress', },


                ],
                path: ["sno", "agreementNo", "agreementuser", "contactNo", "emailAddress",],
                dataType: [
                    { type: ["text"], name: "agreementNo" },
                    { type: ["date"], name: "validfrom" },
                    { type: ["date"], name: "validto" },
                    { type: ["text"], name: "agreementuser" },
                    { type: ["text"], name: "contactNo" },
                    { type: ["text"], name: "emailAddress" },

                ],

            },
            query: GET_ALL_UNIT_AGREEMENT,
            // unitId: unitId,
            unitId: allUnitid
        },
        {
            id: 3,
            title: "Unit Invoices",
            color: "#339E89",
            count: count?.invoice,
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Invoice Id ", field: 'invoiceId', },
                    { title: "Invoice Type ", field: 'invoiceType', },
                    { title: "Invoice Total", field: 'invoiceTotal', },
                    { title: "Invoice Status", field: 'invoiceStatus', },
                    { title: " ", field: 'icon', },


                ],
                path: ["sno", "invoiceId", "invoiceType", "invoiceTotal", "invoiceStatus",],
                dataType: [
                    { type: ["sno"], name: "sno" },
                    { type: ["text"], name: "invoiceId" },
                    { type: ["text"], name: "invoiceType" },
                    { type: ["text"], name: "invoiceTotal" },
                    { type: ["status"], name: "invoiceStatus" },
                    { type: ["icon"], name: "icon" },

                ],

            },
            query: GET_ALL_UNIT_INVOICE_OLD,
            unitId: allUnitid
        },
        {
            id: 4,
            title: "Accounts",
            color: "#30AFCE",
            count: count?.accounts,
            table: {
                heading: [
                    { title: "Account Id", field: "accountId", },
                    { title: "Account Name ", field: 'accountName', },
                    { title: "Account Number ", field: 'accountNumber', },
                    { title: "Company Name", field: 'companyName', },
                ],
                path: ["accountId", "accountName", "accountNumber", "companyName",],
                dataType: [
                    { type: ["text"], name: "accountId" },
                    { type: ["text"], name: "accountName" },
                    { type: ["text"], name: "accountNumber" },
                    { type: ["text"], name: "companyName" },
                ],
            },
            query: GET_RESIDNET_ACCOUNTS,
            agreementId: agreementId
        },
        {
            id: 5,
            title: "Other Residents",
            color: "#8887D4",
            count: count?.otherResidents,
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Name ", field: 'name', },
                    { title: "Contact Number ", field: 'contactNumber', },
                    { title: "Email Id", field: 'email', },

                ],
                path: ["sno", "name", "contactNumber", "email"],
                dataType: [
                    { type: ["text"], name: "sno" },
                    { type: ["text"], name: "name" },
                    { type: ["text"], name: "contactNumber" },
                    { type: ["text"], name: "email" },

                ],

            },
            query: GET_OTHER_RESIDENTS,
            unitId: allUnitid
        },
    ]
    React.useEffect(() => {
        if (allUnitid.length > 0 && agreementId.length > 0) {
            client.query({
                query: GET_ALL_COUNTS,
                fetchPolicy: 'network-only',
                variables: {
                    unitId: allUnitid,
                    agreementId: agreementId
                }
            }).then((res) => {
                console.log(res.data)
                setCount({
                    owners: res.data?.owner_customer_count?.[0]?.count,
                    otherResidents: res.data?.other_residnets_count?.[0]?.count ?? 0,
                    breakup: res.data?.pricing_table?.[0]?.count,
                    vacancy: res.data?.unit_vacancy_period?.[0]?.count,
                    amenities: res.data?.unit_amenities_breakup?.[0]?.count,
                    rooms: res.data?.unit_room?.[0]?.count,
                    request: res.data?.security_count?.[0]?.count,
                    agreement: res.data?.agreement_units_count?.[0]?.count,
                    invoice: res.data?.invoice_units_count?.[0]?.count,
                    accounts: res.data?.agreement_count?.[0]?.count
                })
            })
        }
        // eslint-disable-next-line
    }, [allUnitid, agreementId])
    return (

        <div>
            <Box height="8px" />
            <Grid container spacing={2}>
                {tableData.map((item) => (
                    <>
                        <Grid item xs={2}>
                            <Box p={0.5}>
                                <DialogWithTable data={item} />
                            </Box>
                        </Grid>
                    </>

                )
                )}
            </Grid>

        </div>


    )
}