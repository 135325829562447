import { useApolloClient } from "@apollo/client";
import { Box, Grid } from "@mui/material";
import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { FilterGenerator, Subheader, UseDebounce } from "../../components";
import { NewLoader } from "../../components/newLoader";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { GET_ACCOUNTS } from '../../graphql/accountQuery';
import { Routes } from "../../router/routes";
import { accessCheckRender, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, LocalStorageKeys } from "../../utils";
import { StatusOptionList } from "../../utils/accountUtiles";
import { AccountTable, DetailsContainer } from "./component";
import { useStyles } from "./styles";

export const Accounts = (props) => {
    const { type = null, subtitle = null, loading, handleLoading } = props
    const client = useApolloClient();
    const classes = useStyles()
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const history = useHistory()
    const { state } = useLocation()
    const debounce = UseDebounce();
    const [accountList, setAccountList] = React.useState({
        list: [],
        count: 0
    })
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState({});
    const [search, setSearch] = React.useState("");
    const [limits, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [loader, setLoader] = React.useState(true)
    const [selectedAccount, setSelectedAccount] = React.useState("")
    const [permission, setPermission] = React.useState({})
    const [isFilterData, setIsFilterData] = useState(false)
    const [filterData, setFilterData] = React.useState({
        account_type: [],
        is_active: []
    });
    const [enumValue, setEnumValue] = React.useState({ account_relationship: [] });
    const typeSelect = {
        "Vendor": Routes.vendorAccountDetail,
        "Property Owner": Routes.ownerAccountDetails,
        "Customer": Routes.customerAccountDetails
    }
    // get enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types?.account_relationship]);
        setEnumValue({ account_relationship: result?.account_relationship });
    };
    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getEnum()
                getCompanyList()
            }
        }
        // eslint-disable-next-line
    }, [auth]);
    //get accounts
    const getAccounts = (company_id, limit, offset, searchText, filteredData) => {
        setLoader(true)
        client.query({
            query: GET_ACCOUNTS(filteredData?.is_active, type ? [type] : filteredData?.account_type),
            fetchPolicy: 'network-only',
            variables: {
                company: company_id,
                limit: limit,
                offset: offset,
                searchText: searchText,
                client: localStorage.getItem(LocalStorageKeys.clinetID),
                is_active: filteredData?.is_active,
                account_type: type ? [type] : filteredData?.account_type?.map((_) => _?.value)
            },
        }).then((response) => {
            setAccountList({
                list: response.data.contact_account?.map((val) => {
                    // let city = val?.contact_account_address?.[0]?.city ?? ""
                    // let country = val?.contact_account_address?.[0]?.country ?? ""
                    return (
                        {
                            ...val,
                            // location: val?.contact_account_address?.length > 0 ? (city.concat(" ", country)) : "-",
                            status: val?.is_active ? "Active" : "Inactive",
                            mobile: `${val?.mobile_no_country_code ?? ''} ${val?.mobile ?? "-"}`

                        }
                    )
                }),
                count: response.data.count[0]?.count
            })
            setSelectedAccount(response.data.contact_account?.[0]?.account_id)
            setLoader(false)
            handleLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
    }
    //get company list
    const getCompanyList = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getAccounts(state?.value ?? company?.selected?.value, limits, 0, "", filterData)
        }
    }
    //change company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getAccounts(value?.value, limits, 0, search, filterData)
    }
    //on search
    const handleSearch = (e) => {
        setSearch(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getAccounts(selectedCompany?.value, 10, 0, e, filterData)
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limits;
        getAccounts(selectedCompany?.value, limits, offset, "", filterData)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getAccounts(selectedCompany?.value, value, 0, "", filterData)
    }
    //handle icon
    const handleIcon = (types, data) => {
        if (types === "view") {
            setSelectedAccount(data?.account_id)
        }
        if (types === "info") {
            history.push({
                pathname: type?.length > 0 ? typeSelect[type] : Routes.accountDetails,
                state: { types: types, id: data?.id, type: type }
            })
        }
    }
    //to filter data based on status and account_type
    const handleFilter = (value) => {
        setFilterData(value)
        getAccounts(selectedCompany?.value, 10, 0, "", value)
    }
    const render = () => {
        return <>
            {/*sub header */}
            <Subheader
                title={subtitle === null ? "Accounts" : subtitle}
                select
                hideBackButton
                options={companyList}
                value={selectedCompany}
                onchange={(e) => {
                    handleCompanyChange(e)
                }}
            />
            {
                loader ?
                    <NewLoader minusHeight="100px" />
                    :

                    <Box className={classes.root}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12} lg={9}>
                                {/*table */}
                                <Box p={2}>
                                    <AccountTable
                                        type={type}
                                        data={accountList}
                                        selectedCompany={selectedCompany}
                                        handleChangeLimit={handleChangeLimit}
                                        handlePagination={handlePagination}
                                        page={page}
                                        handleSearch={handleSearch}
                                        searchText={search}
                                        limit={limits}
                                        handleIcon={handleIcon}
                                        permission={permission}
                                        setIsFilterData={setIsFilterData}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12} lg={3} className={classes.detailsContainer}>
                                {/*details */}
                                <Box p={2}>
                                    <DetailsContainer
                                        selectedAccount={selectedAccount}
                                        symbol={selectedCompany?.currency_symbol ?? "-"}
                                        code={selectedCompany?.code ?? "-"}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        {isFilterData &&
                            <FilterGenerator
                                open={isFilterData}
                                onClose={() => setIsFilterData(false)}
                                components={[
                                    {
                                        component: "toggleButton",
                                        value: filterData?.is_active,
                                        state_name: "is_active",
                                        label: "Status",
                                        options: StatusOptionList,
                                        isMulti: true
                                    },
                                    type ? "" : {
                                        component: "select",
                                        value: filterData?.account_type,
                                        options: enumValue?.account_relationship,
                                        isMulti: true,
                                        state_name: "account_type",
                                        label: "Account Type",
                                    },
                                ]}
                                onApply={(value) => handleFilter(value)}
                            />}
                    </Box>
            }
        </>
    }
    return (
        <Box>
            {accessCheckRender(render, permission, "", loading)}
        </Box>
    )
}