import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../utils";

export const useStylesCreation = makeStyles((size) => ({
      tabtitle: {
            fontSize: '12px',
            color: '#4E5A6B',
            fontFamily: Bold
      },
      tabtitle1: {
            fontSize: '12px',
            color: '#5078E1',
            fontFamily: Bold
      },
      tabsub: {
            fontSize: '10px',
            color: '#98A0AC',
            fontFamily: Regular
      },
      tabs: {
            '&.MuiTab-root': {
                  padding: '0px !important',
            },


      },
      after: {
            position: 'absolute',
            right: '0',
            fontSize: 'larger',
            color: '#e3e3e3',
            fontWeight: 100
      },
      tabPanel: {
            padding: "12px",

      },
      tabBox: {
            borderBottom: (props) => props?.backgroundColor ? "1.5px solid #E4E8EE" : 1,
            borderColor: 'divider',
            backgroundColor: (props) => props?.backgroundColor ? "#FFFFFF" : '#F5F7FA',
            display: "flex",
            justifyContent: "space-between"
      },
      Cancel: {
            backgroundColor: "#FFFFFF",
            color: "#091B29",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: SemiBold,
            border: '1px solid #E4E8EE',
            "&:hover": {
                  backgroundColor: "#FFFFFF ",
            },
      },
      next: {
            marginLeft: "10px",
            fontFamily: SemiBold,
            color: "#fff",
            // backgroundColor: '#5078E1',
            "&:hover": {
                  backgroundColor: '#5078E1',
            },
      },
}));