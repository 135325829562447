import { makeStyles } from "@mui/styles";

export const InviteTriggerStyle = makeStyles((theme)=>({
    contentBox:{
        background:"#fff",
        width:"100%",
        borderRadius:4,
        height:`calc(100vh - 150px)`,
        boxShadow:"0px 0px 16px #00000014"
    },
    nameTitle:{
        fontSize:16,
        color:theme.typography.color.primary,
        fontWeight:"bold"
    },
    title:{
        fontSize:14,
        fontWeight:"bold",
        color:theme.typography.color.primary
    },
    subtitle:{
        fontSize:12,
        color:theme.typography.color.tertiary
    },
    inviteBox:{
        height:`calc(100vh - 320px)`,
        overflow:"auto"
    },
    listGrid:{
        height:`calc(100vh - 150px)`,
        background:"#fff",
        borderRadius:4,
        boxShadow:"0px 0px 16px #00000014"
    },
    memberList:{
        fontSize:16,
        color:theme.typography.color.primary,
        fontWeight:"bold"
    },
    inviteBtn:{
        height:42,
        width:"auto",
        whiteSpace:"no-wrap"
    },
}))