import React, { useState } from 'react';
import { CustomTypography } from '.';
import useTheme from '@mui/material/styles/useTheme';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import ReactShowMoreText from 'react-show-more-text';
import { useStyles } from './style';
import { SemiBold } from '../../../utils';

export const TitleDesc = (props) => {

    const {
        title = "",
        desc = "",
        dangerouslySetInnerHTML = "",
        password = false,
        index = null
    } = props;

    const theme = useTheme();
    const classes = useStyles()
    const [toggleView, setToggleView] = useState([]);

    const onChangeToggleView = (index) => {
        if (toggleView.includes(index)) {
            setToggleView(toggleView?.filter(_ => _ !== index));
            return
        }
        setToggleView([...toggleView, index])
    }

    return (
        <div>
            {
                title &&
                <CustomTypography
                    fontSize={14}
                    fontFamily={SemiBold}
                    marginBottom={theme.spacing(1)}
                    color={theme?.typography?.color?.tertiary}
                >
                    {title}
                </CustomTypography>
            }
            {
                desc &&
                <CustomTypography
                    fontFamily={SemiBold}
                    color={theme?.typography?.color?.primary}
                >
                    {(!password || toggleView?.includes(index)) ? desc : desc?.split('')?.map(_ => '*')?.join('')}
                    {
                        password &&
                        <IconButton
                            size='small'
                            aria-label="toggle password visibility"
                            onClick={() => onChangeToggleView(index)}
                            edge="end"
                        >
                            {toggleView?.includes(index) ? <Visibility fontSize='small' /> : <VisibilityOff fontSize='small' />}
                        </IconButton>
                    }
                </CustomTypography>
            }
            {
                dangerouslySetInnerHTML &&
                <ReactShowMoreText
                    lines={2}
                    more="Show More"
                    less="Show Less"
                    className={classes.remark}
                    anchorClass={classes.seeMoreLessTextStyle}
                    expanded={false}
                    truncatedEndingComponent={"... "}
                >
                    <CustomTypography
                        fontFamily={SemiBold}
                        color={theme?.typography?.color?.primary}
                        fontSize={14}
                        dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }}
                        sx={[{
                            '& p': {
                                m: 0
                            }
                        }]}
                    />
                </ReactShowMoreText>
            }
        </div>
    )
}