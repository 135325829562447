import React from "react";
import { LeadStylesParent } from "../../leadnew/style";
import { Button, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { ADD_COMMAND_PROXY_LEAD } from "../../../graphql/mutations";
import { GET_USERPROFILE_BY_ID } from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import {
  AlertProps,
  NetWorkCallMethods,
  useWindowDimensions,
} from "../../../utils";
import { AlertContext } from "../../../contexts";
import { useApolloClient } from "@apollo/client";
import { TimeLine } from "../../leadnew/components";
import { Bold } from "../../../utils";
// custom styles
// start
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontFamily: Bold,
  color: theme.typography.color.secondary,
  marginBottom: theme.spacing(1),
}));
export const Command = (props) => {
  // styles
  const classes = LeadStylesParent();
  // size
  const size = useWindowDimensions();
  // alert
  const alert = React.useContext(AlertContext);
  // apolo clint
  const client = useApolloClient();
  // states
  const [command, setCommand] = React.useState("");
  // get user profile name by userprofile id
  const getuserProfile = (e) => {
    e.preventDefault();
    if (command?.length > 0) {
      client
        .query({
          query: GET_USERPROFILE_BY_ID,
          fetchPolicy: "network-only",
          variables: {
            id: localStorage.getItem("profileID"),
          },
        })
        .then((res) => {
          addCommand(res?.data?.user_profiles?.[0]);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something went wrong please try again12",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    }
  };
  // add commands
  const addCommand = (name) => {
    let currentData = {};
    // existing array
    let existsData = JSON.parse(JSON.stringify(props?.viewDetails?.comments));
    if (existsData?.length > 0) {
      // new obj
      let currentData = {
        comments: command,
        created_at: new Date().toISOString(),
        created_by: `${name?.first_name ?? ""} ${name?.last_name ?? ""}`,
        image_url: name?.image_url ?? "",
      };
      // add comment
      existsData.push(currentData);
    } else {
      // new obj
      currentData = {
        comments: command,
        created_at: new Date().toISOString(),
        created_by: `${name?.first_name ?? ""} ${name?.last_name ?? ""}`,
        image_url: name?.image_url ?? "",
      };
    }

    const payload = {
      query: ADD_COMMAND_PROXY_LEAD,
      variables: {
        id: props?.viewDetails?.id,
        data: {
          comments:
            props?.viewDetails?.comments === null ? [currentData] : existsData,
        },
      },
    };

    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Comment created successfully",
        });
        setCommand("");
        props?.viewLeadDeatils();
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong please try again",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  return (
    <div>
      <div
        style={{
          height: size?.height - 370,
          textAlign: "left",
          overflow: "auto",
        }}
      >
        <CustomTypography>ACTIVITY COMMENTS</CustomTypography>
        <TimeLine datas={props?.viewDetails?.comments} />
      </div>
      {/* command */}
      {props?.permission?.update && <div className={classes.activityBorder}>
        <div className={classes.commandbox}>
          <form onSubmit={getuserProfile}>
            <input
              className={classes.commandinput}
              placeholder="Type Comments here"
              value={command}
              onChange={(e) => setCommand(e.target.value)}
            />
            <Button
              className={classes.commandbtn}
              variant="contained"
              disabled={command?.length > 0 ? false : true}
              type="submit"
            >
              Comment
            </Button>
          </form>
        </div>
      </div>}
    </div>
  );
};
