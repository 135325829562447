import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { componantsStyles } from "./styles";
export const ReservationCard = ({
    title = null,
    onHistory = () => false,
    data = {
        reservation_no: "",
        is_active: false,
        date: "",
        amount: "",
        paid: ""
    },
    view = true,
    onCancel = () => false,
    onSend = () => false,
    type

}) => {
    const classes = componantsStyles();
    const [open, setOpen] = React.useState(false);
    //handle open
    const handleOpen = () => {
        if (view) {
            setOpen(true)
        }
    }
    //handleClose
    const handleClose = () => {
        if (view) {
            setOpen(false)
        }
    }
    return (
        <Box marginTop={view ? "auto" : "8px"} onMouseEnter={handleOpen} onMouseLeave={handleClose}>
            {
                view &&
                <Stack direction="row" justifyContent="space-between">
                    <Typography className={classes.title1}>{type === "Booked" ? "Booking" : "Reservation"} Details</Typography>
                    {/* <Typography className={classes.add} onClick={onHistory}>View History</Typography> */}
                </Stack>
            }
            <Box sx={{ boxShadow: open ? "0px 0px 16px #00000014" : "none" }} className={classes.reservationCard}>
                <Box display="flex" >
                    <Box flexGrow={1}>
                        <Stack direction="row" marginBottom="4px" spacing={1}>
                            <Typography className={classes.accountNamelist}>{data?.reservation_no}</Typography>
                            <Typography className={data?.is_active ? classes.is_active : classes.in_active}>{data?.is_active ? "Active" : "In-Active"}</Typography>

                        </Stack>
                        <Typography className={classes.date}>{data?.date}</Typography>
                    </Box>
                    {
                        (view && type !== "Booked") &&
                        <Box>
                            <Typography marginBottom="2px" textAlign="right" className={classes.name}>{data?.amount}</Typography>
                            {
                                data?.paid && <Typography textAlign="right" className={classes.paid}>Paid on {data?.paid}</Typography>
                            }

                        </Box>
                    }



                </Box>
                {
                    open &&
                    <Grid container spacing={2} marginTop="2px">
                        <Grid item xs={6}>
                            <Button onClick={() => onCancel(data)} fullWidth className={classes.cancelReserveBtn}>Cancel {type === "Booked" ? "Booking" : "Reservation"}</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth onClick={() => onSend(data)} className={classes.paymentLinkBtn}>{type === "Booked" ? "Reserve Booking" : "Send Payment Link"}</Button>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    )
}