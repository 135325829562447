import React from "react";
import { withNavBars } from "../../HOCs";
import { UnitList } from "./unit";

class UnitParent extends React.Component {
    render() {
        return <UnitList />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(UnitParent, props);
