import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Editor, LogoUpload, SelectBox, TextBox } from "../../../components";
import { networkCallback } from "../../../utils";
import { useStyles } from "../styles";
export const Profile = ({ data = {}, updateState = {}, enumValue = {}, setData = () => false, reltype = null }) => {
    const classes = useStyles()
    //load option api
    const loadOptionData = async (search, array, handleLoading, type) => {
        let result, offset = 0;
        if (search && !Boolean(array?.length)) {
            offset = 0;
        }
        else {
            offset = array?.length;
        }
        result = await networkCallback(
            {
                relationship: data?.relation_ship?.value,
                offset: offset,
                limit: 10,
                search: search
            }, "account/contact");

        return {
            options: result?.data,
            hasMore: (array?.length + result?.data?.length) < result?.count
        }

    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={2}>
                    <Box className={classes.profile_box}>
                        <LogoUpload
                            logo_title={"PROFILE IMAGE"}
                            handleChange={(value) => updateState("image_url", value)}
                            data={data?.image_url}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={10}>
                    <Box className={classes.secondBox}>
                        <Typography className={classes.title}>Profile Details</Typography>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                            <Grid item xs={12} md={12} lg={3}>
                                <SelectBox
                                    isReadOnly
                                    label="Company Name"
                                    placeholder="Enter Company Name"
                                    onChange={(value) => updateState("company", value)}
                                    value={data?.company}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={3}>
                                <TextBox
                                    label="Account Name"
                                    placeholder="Enter Account Name"
                                    onChange={(value) => updateState("acc_name", value.target.value)}
                                    value={data?.acc_name}
                                    isrequired
                                    errorMessage={data?.error?.acc_name}
                                    isError={data?.error?.acc_name?.length > 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={3}>
                                <SelectBox
                                    label="Relationship"
                                    placeholder="Select Relationship"
                                    options={enumValue?.account_relationship}
                                    onChange={(value) => updateState("relation_ship", value)}
                                    value={data?.relation_ship}
                                    isRequired
                                    errorMessage={data?.error?.relation_ship}
                                    isError={data?.error?.relation_ship?.length > 0}
                                // isReadOnly={data?.relation_ship.value}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={3}>
                                {
                                    data?.relation_ship?.value !== null &&
                                    <SelectBox
                                        label={data?.relation_ship ? data?.relation_ship.label : "Contact"}
                                        key={JSON.stringify(data?.relation_ship?.value)}
                                        placeholder={`Select ${data?.relation_ship ? data?.relation_ship.label : "Contact"}`}
                                        onChange={(value) => updateState("contact", value)}
                                        value={data?.contact}
                                        isRequired
                                        isPaginate
                                        loadOptions={(search, array, handleLoading) => loadOptionData(search, array, handleLoading)}
                                        debounceTimeout={800}
                                        errorMessage={data?.error?.contact}
                                        isError={data?.error?.contact?.length > 0}
                                    />
                                }

                            </Grid>
                            <Grid item xs={12}>
                                <Editor
                                    label="Business Description"
                                    handleChange={(e) => {
                                        updateState("description", e);
                                    }}
                                    value={data?.description}
                                    height={"80px"}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}