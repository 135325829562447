import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const AggreementCardInfoStyles = makeStyles((theme) => ({
    cardRoot: {
        padding: '12px',
        borderRadius: theme.palette.borderRadius,
        backgroundColor: (props) => props?.color,
        minHeight: "150px",
        cursor: "pointer"
    },
    title: {
        fontSize: "18px",
        color: "#091B29",
        fontFamily: `${Bold}`
    },
    subtitle: {
        fontSize: "12px",
        color: "#4E5A6B",
        fontFamily: `${SemiBold}`,
        marginTop: "4px"
    },
    currency:{
        marginRight:"10px"
    }
}));