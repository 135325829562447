import makeStyles from '@mui/styles/makeStyles';
import { Bold, ExtraBold, SemiBold } from '../../utils';
export const UtilitiesMappingStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
        margin: "14px"
    },
    content: {
        textAlign: "center"
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize: "14px"
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
    },
    text: {
        fontSize: "24px",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px"
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
        },
    },
    drawerHeaderStyle: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
    },
    drawerHeaderTextStyle: {
        fontSize: "16px",
        fontWeight: "bolder",
        color: theme.typography.color.primary,
    },
    subText: {
        fontSize: "16px",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        //marginTop: "4px",
        padding: "12px"
    },
    tableHead: {
        backgroundColor: "#F2F4F7",
        color: theme.typography.color.secondary,
        borderRadius: theme.palette.borderRadius,
        fontSize: "12px",
        fontFamily: SemiBold
    },
    thead: {
        backgroundColor: '#F2F4F7',
        '& th:first-child': {
            borderRadius: '1em 0 0 1em'
        },
        '& th:last-child': {
            borderRadius: '0 1em 1em 0'
        }
    },
    tbody: {
        backgroundColor: '#F2F4F7',
        '& th:first-child': {
            borderRadius: '1em 0 0 1em'
        },
        '& th:last-child': {
            borderRadius: '0 1em 1em 0'
        }
    },
    businessType: {
        backgroundColor: "#78B1FE",
        color: "white",
        padding: "1px",
        borderRadius: theme.palette.borderRadius,
        textAlign: "center"
    },
    bottomTitle: {
        fontSize: "18px",
        fontFamily: ExtraBold

    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        marginBottom: "12px"
    },
    contentBottom: {},
    grid: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    title: {
        fontSize: "14px",
        fontFamily: Bold,
        color: theme.typography.color.primary

    },
    subTitle: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: theme.palette.primary.main
    },
    divider: {
        height: "12px",
        width: "2px",
        backgroundColor: "black"
    }
}))