export const Path = ["requestNo", "agreementNo", "requestedBy", "requestedOn", "noOfUnits", "moveOutDate", "status"]

export const Heading = [
    { title: "Request Number", field: "requestNo" },
    { title: "Agreement Number", field: "agreementNo" },
    { title: "Requested By", field: "requestedBy", },
    { title: "Requested On", field: "requestedOn", },
    { title: "Number Of Units", field: "noOfUnits", },
    { title: "Expected Move Out Date", field: "moveOutDate", },
    { title: "Status", field: "status", },
]

export const Type = [
    { type: ["text"], name: "requestNo" },
    { type: ["text"], name: "agreementNo" },
    { type: ["text"], name: "requestedBy" },
    { type: ["text"], name: "requestedOn" },
    { type: ["text"], name: "noOfUnits" },
    { type: ["text"], name: "moveOutDate" },
    { type: ["object_status"], name: "status" },
]

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]

export const UnitPath = ["logo", "unit_name", "unit_no", "property_name"]

export const UnitHeading = [
    { title: "Image", field: "logo" },
    { title: "Name", field: "unit_name" },
    { title: "Unit ID", field: "unit_no", },
    { title: "Property Name", field: "property_name", }
]

export const UnitType = [
    { type: ["avatarmanagement"], name: "logo" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "unit_no" },
    { type: ["text"], name: "property_name" }
]