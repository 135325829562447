import { Box, Button } from "@mui/material";
import React from "react";
import { MapWithFields } from "../../../components/index";
import { mapResult, useWindowDimensions } from "../../../utils";

export const Address = ({ data = {}, onCancel = () => false, onSubmit = () => false }) => {
    const [address, setAddress] = React.useState(data ?? null)
    const size = useWindowDimensions()
    const mapResultData = (map) => {
        let result = mapResult(map);
        setAddress(result)
    }
    const updateAddress = (key, value,) => {
        let newAddress = {
            ...address, [key]: value
        }
        setAddress(newAddress)

    }

    const onSubmitAddress = () => {
        onSubmit(address)
    }
    return (
        <Box p={2}>
            <Box height={size?.height - 300} overflow="auto">
                <MapWithFields
                    mapHeight={"300px"}
                    breakpoints={{
                        map: {
                            xs: 12,
                            sm: 12,
                            lg: 12,
                            md: 12
                        },
                        components: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12
                        }
                    }}
                    mapOptions={{
                        isInput: true,
                        center: {
                            lat: address?.latitude,
                            lng: address?.longitude,
                        },
                        lat: address?.latitude,
                        lng: address?.longitude,
                        mapLoad: !!address ? false : true
                    }}
                    mapResult={mapResultData}
                    fields={[
                        {
                            label: "Door No",
                            component: "TextField",
                            value: address?.description,
                            state_name: 'description',
                            isrequired: false,
                            placeholder: "Door No",
                            //error: error?.address?.doorNumber?.error,
                            //errorMessage: error?.address?.doorNumber?.errorMsg,
                            breakpoints: {
                                sm: 4,
                                md: 4
                            }
                        },
                        {
                            label: "Address Line 1",
                            component: "TextField",
                            value: address?.addressLineOne,
                            //value: address?.addressLineOne,
                            state_name: 'addressLineOne',
                            isrequired: true,
                            placeholder: "Enter Address Line 1",
                            //errorMessage: error?.address?.addressLineOne?.errorMsg,
                            //error: error?.address?.addressLineOne?.error,
                            breakpoints: {
                                sm: 4,
                                md: 4
                            }
                        },
                        {
                            label: "Address Line 2",
                            component: "TextField",
                            value: address?.addressLineTwo,
                            //value: address?.addressLineTwo,
                            state_name: 'addressLineTwo',
                            isrequired: true,
                            placeholder: "Enter Address Line 2",
                            //errorMessage: error?.address?.addressLineTwo?.errorMsg,
                            //error: error?.address?.addressLineTwo?.error,
                            breakpoints: {
                                sm: 4,
                                md: 4
                            }
                        },
                        {
                            label: "Landmark",
                            component: "TextField",
                            value: address?.landmark,
                            //value: address?.landmark,
                            state_name: 'landmark',
                            isrequired: true,
                            placeholder: "Enter Landmark",
                            // errorMessage: error?.address?.landmark?.errorMsg,
                            // error: error?.address?.landmark?.error,
                            breakpoints: {
                                sm: 4,
                                md: 4
                            }
                        },
                        {
                            label: "Area",
                            component: "TextField",
                            //value: address?.area,
                            value: address?.area,
                            state_name: 'area',
                            isrequired: true,
                            placeholder: "Enter Area",
                            // errorMessage: error?.address?.area?.errorMsg,
                            // error: error?.address?.area?.error,
                            breakpoints: {
                                sm: 4,
                                md: 4
                            }
                        },
                        {
                            label: "City",
                            component: "TextField",
                            //value: address?.city,
                            value: address?.city,
                            state_name: 'city',
                            isrequired: true,
                            placeholder: "Enter City",
                            //errorMessage: error?.address?.city?.errorMsg,
                            //error: error?.address?.city?.error,
                            breakpoints: {
                                sm: 4,
                                md: 4
                            }
                        },
                        {
                            label: "State",
                            component: "TextField",
                            state_name: 'state',
                            //value: address?.state,
                            isrequired: true,
                            placeholder: "Enter State",
                            value: address?.state,
                            //errorMessage: error?.address?.state?.errorMsg,
                            //error: error?.address?.state?.error,
                            breakpoints: {
                                sm: 4,
                                md: 4
                            }
                        },
                        {
                            label: "Country",
                            component: "TextField",
                            //value: address?.country,
                            state_name: 'country',
                            isrequired: true,
                            placeholder: "Enter Country",
                            value: address?.country,
                            //errorMessage: error?.address?.country?.errorMsg,
                            //error: error?.address?.country?.error,
                            breakpoints: {
                                sm: 4,
                                md: 4
                            }
                        },
                        {
                            label: "Pincode",
                            component: "TextField",
                            state_name: 'pincode',
                            //value: address?.pincode,
                            isrequired: true,
                            value: address?.pincode,
                            placeholder: "Enter Pincode",
                            //errorMessage: error?.address?.pincode?.errorMsg,
                            //error: error?.address?.pincode?.error,
                            breakpoints: {
                                sm: 4,
                                md: 4
                            }
                        },
                    ]}
                    onChangeFields={(key, value) => updateAddress(key, value)}

                />
            </Box>
            <Box p={1} sx={{ float: "right" }}>
                <Button onClick={onCancel} variant="outlined" sx={{ padding: "8px 12px", boxShadow: "none" }}>
                    Cancel
                </Button>
                <Button onClick={onSubmitAddress} variant="contained" sx={{ padding: "8px 12px", boxShadow: "none", marginLeft: "4px" }}>
                    Submit
                </Button>
            </Box>
        </Box>
    )
}