import React from "react";
import { Button, Typography, Box, Stack } from "@mui/material";
import { Decline, Skip, OwnerAssign } from "../../../assets";
import { useStyles } from "../styles";

export const StatusComponent = ({
  data = {},
  onClick = () => false,
  title = "",
  sub = "",
  btnText = {},
  isButton = false,
  marginLeft = "0px",
  isOnboard = false,
  isStatus = false,
  t,
  onClickSkip = () => false,
  onClickDecline = () => false,
  index = 0,
  is_show=false,
  status=""
}) => {
  const classes = useStyles({ marginLeft });

  const btnComponent = {
    2: t("Assign_Owner"),
    3: t("Initiate"),
    4: t("Create"),
    5: t("Create"),
    // 3: t("Create_Request"),
    // 4: t("Send_payment"),
    // 5: t("Create_Request"),
  };

  return (
    <div className={classes.statusComponentPraeant}>
      <Box display="flex" alignItems="center">
        <Box>
          <img src="images/agreementReview.svg" alt="agreementReview" />
        </Box>
        <Box width={"12px"} />
        <Box textAlign="left">
          <Typography className={classes.statusComponentTitle}>
            {title}
          </Typography>
          <Box height={"4px"} />
          <Typography className={classes.statusComponentSubtitle}>
            {sub}
          </Typography>
        </Box>
      </Box>
      <Box height={"20px"} />
      <Box>
        {isButton ? (
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              className={classes.next}
              onClick={() => onClick("Complete", data, isStatus, isOnboard , is_show)}
            >
              <Box display="flex" alignItems={"center"}>
                <OwnerAssign />
                <Box width={"8px"} />
                {t("Complete")}{" "}
              </Box>
              {/* <Box>
                <Box width={"8px"} />
                <ArrowIcon />{" "}
              </Box> */}
            </Button>
            <Button
              variant="outlined"
              className={classes.decline}
              onClick={() => onClick("Incomplete", data, isStatus, isOnboard , is_show)}
            >
              <Box display="flex" alignItems={"center"}>
                <Decline />
                <Box width={"8px"} />
                {t("Decline")}{" "}
              </Box>
              {/* <Box>
                {" "}
                <ArrowIcon color={"#FF4B4B"} />{" "}
              </Box> */}
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              className={classes.next}
              onClick={() => onClick("assign", data, isStatus, isOnboard , is_show)}
            >
              <Box display="flex" alignItems={"center"}>
                <OwnerAssign />
                <Box width={"8px"} />
                {btnComponent[index]}
              </Box>
              {/* <Box>
                <Box width={"8px"} />
                <ArrowIcon />{" "}
              </Box> */}
            </Button>
            {
              status !== "KYC Screening" && 
              <Button
              variant="outlined"
              className={classes.skip}
              onClick={() => onClickSkip("skip", data, isStatus, isOnboard , is_show)}
            >
              <Box display="flex" alignItems={"center"}>
                <Skip />
                <Box width={"8px"} />
                {t("Skip_This_process")}{" "}
              </Box>
              {/* <Box>
                {" "}
                <ArrowIcon color={"#4e5a6b"} />{" "}
              </Box> */}
            </Button>
            }
 
            <Button
              variant="outlined"
              className={classes.decline}
              onClick={() =>
                onClickDecline("decline", data, isStatus, isOnboard , is_show)
              }
            >
              <Box display="flex" alignItems={"center"}>
                <Decline />
                <Box width={"8px"} />
                {t("Decline")}{" "}
              </Box>
              {/* <Box>
                {" "}
                <ArrowIcon color={"#FF4B4B"} />{" "}
              </Box> */}
            </Button>
          </Stack>
        )}
      </Box>
    </div>
  );
};
