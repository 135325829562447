import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../utils";
export const contactsViewStyles = makeStyles((theme) => ({
    card: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "0px 16px"

    },
    card3: {
        border: "2px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "8px 16px"

    },
    cards: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",

    },
    unitImg: {
        height: "120px",
        width: "120px",
        borderRadius: "50%",
        margin: "2px",
        objectFit: "fill"
    },
    imgTag: {
        padding: "2px 6px",
        borderRadius: "5px",
        backgroundColor: "#071741",
        color: "white",
        fontSize: "11px",
        fontFamily: SemiBold,
        position: "absolute",
        left: "0px",
        bottom: "10px",
        display: "inline",

    },
    imgDiv: {
        position: "relative",
        borderRight: "1px solid #00000014",
        padding: "4px",
        // marginLeft: "8px"

    },
    title: {
        color: theme.typography.color.secondary,
        fontSize: "12px",
        fontFamily: Bold
    },
    imgDiv2: {
        padding: "0px 12px"
    },
    heading: {
        color: theme.typography.color.tertiary,
        fontSize: "14px",
        fontFamily: SemiBold,
        textTransform: "capitalize"
    },
    sub: {
        color: theme.typography.color.primary,
        fontSize: "14px",
        fontFamily: SemiBold,
        textTransform: "capitalize"
    },
    sub1: {
        color: theme.typography.color.primary,
        fontSize: "12px",
        fontFamily: SemiBold,
        textTransform: "capitalize"
    },
    sub2: {
        color: theme.typography.color.primary,
        fontSize: "14px",
        fontFamily: SemiBold
    },
    matric: {
        color: theme.typography.color.tertiary,
        fontSize: "11px",
        fontFamily: Regular,
        textTransform: "capitalize"
    },
    map: {
        borderRadius: theme.palette.borderRadius,
        overflow: "hidden",
        height: "200px"

    },
    addressRoot: {
        padding: "12px",
        minHeight: "200px",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius
    },
    list: {
        '&.MuiListItem-root': {
            width: '135px !important',
            padding: '0px!important',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            border: '1px solid #E4E8EE',
            cursor: 'pointer',
            marginTop: "-4px"
        }
    },
    tabname: {
        fontSize: '12px',
        fontFamily: Bold,
        cursor: 'pointer'
    },
    imagebox1: {
        padding: '16px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 16px #00000014',
        borderRadius: '12px',
    },
    imagebox: {
        textAlign: 'center',
        padding: '23px 16px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 16px #00000014',
        borderRadius: '12px',
    },
    listText: {
        backgroundColor: '#5078E1',
        width: '120px !important',
        color: '#fff',
        borderRadius: '6px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: "-4px"
    },
    tabtitle: {
        fontSize: '12px',
        color: '#4E5A6B',
        fontFamily: Bold,
    },
    tabtitle1: {
        fontSize: '12px',
        color: '#5078E1',
        fontFamily: Bold,
    },
    subtabtitle: {
        fontSize: '10px',
        color: '#4E5A6B',
        fontFamily: Regular,
    },
    subtabtitle1: {
        fontSize: '10px',
        color: '#5078E1',
        fontFamily: Regular,
    },
    selectBack1: {
        backgroundColor: '#F1F7FF',
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
    },
    selectBack: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
    },
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        // height: `calc(100vh - 88px)`,
        margin: '24px'
    },
    button: {
        '&:hover': {
            backgroundColor: "#5078E1",
            color: '#fff'
        }
    },
    img: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        padding: "8px",
    },

    roles: {
        fontSize: '16px',
        color: '#091B29',
        fontFamily: SemiBold,
        marginBottom: '0 !important',
        marginLeft: '10px'

    },
    selectBox: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
        backgroundColor: '#F5F7FA',
        borderRadius: '20px',
        marginTop: '12px',
        height: '40px',
        cursor: 'pointer'
    },
    avatarcard: {
        height: '30px',
        width: '30px'
    },
    select: {
        color: '#091B29',
        fontSize: '12px',
        fontFamily: Bold,
        marginLeft: '10px'
    },
    managericon: {
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#E29336',
        justifyContent: 'center',
        padding: '10px 15px'

    },
    margin: {
        margin: "16px 0px"
    },

    boxTab: {
        height: 'calc(100vh - 348px)',
        overflow: "auto",
    },
    noData: {
        fontFamily: Bold,
        fontSize: "16px",
        color: theme.typography.color.primary,
        padding: "12px",
        textAlign: "center",
        marginTop: "20%"
    },
}));