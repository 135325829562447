import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import moment from 'moment';
import { MonthlyCalendarStyles, eventListStyle } from './style';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { AlertDialog, LoadingSection } from '../../../components';
import { SwitchActivityIcons, SwitchColors } from './weeklyCalendar';
import { ViewActivity } from './viewMore';


export const MonthlyCalendar = ({ dates = {},
    month = {}, data = [],
    openDeclinePopup = () => false, loading = false,
    handleSelected = () => false }) => {
    const classes = MonthlyCalendarStyles()
    const [viewMore, setViewMore] = React.useState(false)
    const [selected, setSelected] = React.useState({})
    const days = [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",

    ]
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            maxWidth: "100%",
            border: '1px solid #dadde9',
        },
    }));
    const handleClick = (e) => {
        handleSelected(e)
    }
    return (
        <Box>
            <Grid container >
                {
                    days.map(e => {
                        return (
                            <Grid item xs={1.7} textAlign={"center"} p={1}>
                                <span className={classes.days}> {e} </span>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid container mt={0} className={classes.calDates}>

                {
                    loading ?
                        <Grid item xs={12}>
                            <LoadingSection />
                        </Grid>
                        :
                        dates.map(e => {
                            return (
                                <Grid item xs={1.7} className={classes.date_outline}>
                                    <Stack mt={1} justifyContent={"space-between"} height="100%" pb={2}>
                                        <Box className={classes.date}><span className={moment(e).format("M") === moment(month).format("M")
                                            ? " " : classes.hidden_other_month_dates}>{moment(e).format("DD")}</span></Box>
                                        <Box sx={{ textAlign: "right" }}>

                                            <Box m={1}>
                                                {
                                                    data?.map(evnt => {
                                                        return (
                                                            evnt?.calendarData?.map(x => {
                                                                return (

                                                                    moment(e).format("DD") === moment(evnt.date).format("DD")
                                                                        && moment(evnt.date).format("MM") === moment(e).format("MM") ?
                                                                        x?.activity?.map((val, i) => {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        x?.activity?.length === 1 ?
                                                                                            <HtmlTooltip
                                                                                                title={
                                                                                                    <React.Fragment>
                                                                                                        <CustomToolTip data={val} openDeclinePopup={openDeclinePopup} setViewMore={setViewMore} setSelected={setSelected} />
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                                arrow className={classes.customTooltip}
                                                                                            >
                                                                                                <Stack direction={"row"} spacing={1} alignItems={"center"}
                                                                                                    sx={{
                                                                                                        backgroundColor: SwitchColors(val?.activity_name),

                                                                                                    }} p={0.7} onClick={() => setSelected(val)}>
                                                                                                    <img src={SwitchActivityIcons(val?.activity_name)} alt="icon" />
                                                                                                    <Typography className={classes.event_label}> {val?.activity_name} , {val?.first_name} </Typography>
                                                                                                </Stack>
                                                                                            </HtmlTooltip>
                                                                                            :
                                                                                            i === 0 &&
                                                                                            
                                                                                            <>
                                                                                               
                                                                                                    <HtmlTooltip
                                                                                                        title={
                                                                                                            <React.Fragment>
                                                                                                                <CustomToolTip data={x?.activity?.[0]} openDeclinePopup={openDeclinePopup} setViewMore={setViewMore} setSelected={setSelected} />
                                                                                                            </React.Fragment>
                                                                                                        }
                                                                                                        arrow className={classes.customTooltip}
                                                                                                    >

                                                                                                        <>
                                                                                                            <Stack direction={"row"} spacing={1} alignItems={"center"}
                                                                                                                sx={{
                                                                                                                    backgroundColor: SwitchColors(x?.activity?.[0]?.activity_name),

                                                                                                                }} p={0.7}>
                                                                                                                <img src={SwitchActivityIcons(x?.activity?.[0]?.activity_name)} alt="icon" />
                                                                                                                <Typography className={classes.event_label}> {x?.activity?.[0]?.activity_name}, {x?.activity?.[0]?.first_name}  </Typography>
                                                                                                            </Stack>
                                                                                                        </>

                                                                                                    </HtmlTooltip>
                                                                                                
                                                                                                <Typography className={classes.moreLabel} onClick={() => handleClick(e)} > +{x?.activity?.length - 1} more </Typography>
                                                                                            </>
                                                                                                            
                                                                                    }
                                                                                </>
                                                                            )
                                                                        })
                                                                        : ""
                                                                )
                                                            })

                                                        )
                                                    })
                                                }
                                            </Box>
                                        </Box>
                                    </Stack>
                                </Grid>
                            )
                        })
                }
            </Grid>
            <AlertDialog
                md
                open={viewMore}
                onClose={() => setViewMore(false)}
                header={"Activity"}
                component={<ViewActivity data={selected} handleClose={() => setViewMore(false)} />}

            />
        </Box>
    )
}
const CustomToolTip = ({ data = {}, startTime = null, endTime = null, openDeclinePopup = () => false, setViewMore = () => false, setSelected = () => false }) => {
    const classes = eventListStyle()
    return (
        <Stack p={1}>
            <Stack direction={"column"} spacing={1}>
                <Stack direction="row" spacing={1}>
                    <img src={SwitchActivityIcons(data?.activity_name)} width="24px" height="24px" alt="icon" />
                    <Typography className={classes.ttTitle}> {data?.activity_name} </Typography>
                </Stack>
                <Stack pl={3.5}>
                    <Typography className={classes.ttsecTitle}> {moment(data?.date).format("DD-MM-YYYY hh:mm A")} </Typography>
                </Stack>
                <Stack pl={3.5}>
                    <Typography className={classes.ttassigned_by}>Created By : {data?.first_name}, {`${data?.mobile_no_country_code ?? ""} ${data?.mobile_no ?? ""}`} </Typography>
                </Stack>
                <Stack pl={3.5}>
                    <Typography className={classes.moreLabel} onClick={() => {
                        setSelected(data)
                        setViewMore(true)
                    }}> {"View more"}</Typography>
                </Stack>
            </Stack>

        </Stack>
    )
}