import React from "react";
import ContextProvider from "../../contexts/createQuotationContext";
import { withNavBars } from "../../HOCs";
import { CreateQuotation } from "./createquotation";
const CreateQuotationParent = () => {
  return (
    <ContextProvider>
      <CreateQuotation />
    </ContextProvider>
  );
};
const props = {
  boxShadow: false
}
export default withNavBars(CreateQuotationParent, props);
