import React from 'react'
import { Box, Typography, Stack, Divider } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { InvoiceDropDownStyles } from './style';

export const InvoiceDropdown = ({ partialAmount = "", invoiceData = {}, symbol = "" }) => {
    // classes
    const classes = InvoiceDropDownStyles()

    const [open, setOpen] = React.useState(false)

    const showAll = () => {
        open ? setOpen(false) : setOpen(true)
    }
    return (
        <Box className={classes.dropdown}>
            <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"space-between"} onClick={showAll} className={classes.selected} >
                <Stack>
                    <Stack>
                        <Typography className={classes.selectedAmount}> {partialAmount ?? 0} {symbol ?? "-"} </Typography>
                    </Stack>
                    <Stack>
                        <Typography className={classes.selectedType}> Partial Amount Received </Typography>
                    </Stack>
                </Stack>

                <Box pl={2}>
                    {
                        invoiceData.length !== 0 && open && partialAmount !== 0 ? <KeyboardArrowUpIcon /> : " "
                    }
                </Box>
            </Stack>
            {
                invoiceData.length !== 0 && open && partialAmount !== 0 ?
                    <Stack mt={2}>
                        {
                            invoiceData.map((e, i) => {
                                return (
                                    <Stack>
                                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Stack>
                                                <Stack direction={"row"}>
                                                    <Typography className={classes.firstRowText}> {e?.reciepts_no} </Typography>
                                                    <Typography className={classes.firstRowText}> (Receipt No)</Typography>
                                                </Stack>
                                                <Stack direction={"row"} alignItems={"center"} className={classes.second}>
                                                    <Typography className={classes.secondRowText}> {e?.created_at} </Typography>
                                                    <Box className={classes.dot} m={1}></Box>
                                                    <Typography className={classes.secondRowText}> by {e?.tagged_by === null ? "" : e?.tagged_by} </Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack>
                                                <Typography className={classes.listAmount}>{e?.amount_tagged} {e?.symbol}</Typography>
                                            </Stack>
                                        </Stack>

                                        {
                                            (invoiceData?.length - 1) !== i &&
                                            <Box mt={1} mb={1}>
                                                <Divider />
                                            </Box>
                                        }



                                    </Stack>
                                )
                            })
                        }
                    </Stack>
                    : " "
            }
        </Box>
    )

}