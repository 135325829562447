import { Divider, Grid, Stack } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Subheader } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { accessCheckRender, AlertProps, getRoutePermissionNew, NetWorkCallMethods } from '../../utils';
import { useStyles } from "./style";
import {
    BodyDiv, ProfileImageInnerDiv, Image, UserID, ProfileDetailsStack,
    CardTitle, Title, Desc, CompanyRoleListDiv, AssignedDiv, PropertyListDiv,
    FooterDiv, MapAddressDiv, AddressDiv, EditButton,
    ProfileImageOuterDiv
} from "./component/styledComponent";
import ImageViewer from "react-simple-image-viewer";
// import MyGoogleMap from '../../components/interactivemap/myGoogleMaps';
import { LocationPin } from './component/locationPin';
import { Routes } from '../../router/routes';
import { NewLoader } from '../../components/newLoader';

export const ProfileView = (props) => {
    const { loading, handleLoading } = props;

    // constants
    const classes = useStyles()
    const history = useHistory()

    // useContext
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)

    // useState
    const [data, setData] = React.useState(null)
    const [isViewerOpen, setIsViewerOpen] = React.useState(false)
    const [currentImage, setCurrentImage] = React.useState("")
    const [permission, setPermission] = React.useState({})
    const [loader, setLoader] = React.useState(true)

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getProfileData()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getProfileData = () => {
        NetworkCall(
            `${config.api_url}/user-info?is_manager=true`,
            NetWorkCallMethods.get, null, null, true, false
        ).then((_) => {
            let tempData = _?.data?.data

            console.log(tempData)
            setData({
                ...tempData,
                userProfile: tempData?.user_profiles?.[0] ?? null,
                contact: tempData?.user_profiles?.[0]?.contact ?? null,
            })
            setLoader(false)
            handleLoading(false)
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: "Some Thing Went Wrong",
                severity: AlertProps.severity.error,
            })
        })
    }

    // Go Back
    const goBack = () => { history.goBack(-1) }

    //openImageViewer
    const openImageViewer = (image) => {
        setCurrentImage(image)
        setIsViewerOpen(true)
    }

    //closeImageViewer
    const closeImageViewer = () => {
        setCurrentImage(0)
        setIsViewerOpen(false)
    }

    const constructAddress = (_) => {
        let address = ""
        if (!_?.door_no && !_?.street_1 && !_?.street_2 &&
            !_?.landmark && !_?.area && !_?.city &&
            !_?.state && !_?.country && !_?.zipcode) {
            address = "Address Not Available"
        } else {
            address = ((_?.door_no && (_?.door_no + ", ")) ?? "") +
                ((_?.street_1 && (_?.street_1 + ", ")) ?? "") +
                ((_?.street_2 && (_?.street_2 + ", ")) ?? "") +
                ((_?.landmark && (_?.landmark + ", ")) ?? "") +
                ((_?.area && (_?.area + ", ")) ?? "") +
                ((_?.city && (_?.city + ", ")) ?? "") +
                ((_?.state && (_?.state + ", ")) ?? "") +
                ((_?.country && (_?.country + ", ")) ?? "") +
                ((_?.zipcode && (_?.zipcode)) ?? "")
        }

        return address
    }


    console.log(data?.userProfile,"spoispip9siposi")
    const render = () => {
        return <>
            <Subheader hideBackButton={false} goBack={goBack} title="Manager Profile" />
            {
                loader ? (
                    <NewLoader minusHeight="100px" />
                ) : (
                    <BodyDiv>
                        <Grid container>
                            <Grid container spacing={"16px"}>
                                <Grid xs={12} sm={12} md={12} lg={7} item>
                                    <ProfileDetailsStack
                                        direction={{ xs: "column", sm: "row" }}
                                        gap={"16px"}
                                        divider={<Divider variant="middle" flexItem orientation={"vertical"} />}>
                                        <ProfileImageOuterDiv>
                                            <ProfileImageInnerDiv>
                                                <Image
                                                    src={data?.userProfile?.image_url ?? ""}
                                                    alt={"profile_img"}
                                                    onClick={() => data?.userProfile?.image_url &&
                                                        openImageViewer([data?.userProfile?.image_url])} />
                                                <UserID userNo={data?.userProfile?.user_no}>
                                                    {data?.userProfile?.user_no}
                                                </UserID>
                                            </ProfileImageInnerDiv>
                                        </ProfileImageOuterDiv>
                                        <Grid container spacing={"16px"}>
                                            <Grid item xs={12}>
                                                <CardTitle>Profile Details</CardTitle>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Title>Contact Name</Title>
                                                <Desc>{data?.userProfile?.first_name ?? " - "}</Desc>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Title>Email Address</Title>
                                                <Desc>{data?.userProfile?.email_id ?? " - "}</Desc>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Title>Primary Mobile</Title>
                                                <Desc>
                                                    {data?.userProfile?.mobile_no ?
                                                        ((data?.userProfile?.mobile_no_country_code ?? "") +
                                                            (data?.userProfile?.mobile_no_country_code && " ") +
                                                            data?.userProfile?.mobile_no) : " - "}
                                                </Desc>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Title>Alternate Mobile</Title>
                                                <Desc>
                                                    {data?.userProfile?.alternative_mobile_no ?
                                                        (data?.userProfile?.alternative_mobile_no_country_code ?? "" +
                                                            (data?.userProfile?.alternative_mobile_no_country_code && " ") +
                                                            data?.userProfile?.alternative_mobile_no) : " - "}
                                                </Desc>
                                            </Grid>
                                            {/* <Grid item xs={12} sm={6}>
                                                <Title>Reporting To</Title>
                                                <Desc>{data?.contact?.reporting_to ?? " - "}</Desc>
                                            </Grid> */}
                                        </Grid>
                                    </ProfileDetailsStack>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={5} item>
                                    <Grid container>
                                        <Grid container spacing={"16px"}>
                                            <Grid xs={12} sm={6} md={6} lg={6} item>
                                                <Grid container>
                                                    <Grid container spacing={"16px"}>
                                                        <Grid xs={12} item>
                                                            <AssignedDiv>
                                                                <Title>Assigned Companies</Title>
                                                                <CompanyRoleListDiv testProps={"hello"}>
                                                                    {data?.company_names?.map((_) =>
                                                                        <Desc marginBottom={"8px"}>{_ ?? " - "}</Desc>)}
                                                                </CompanyRoleListDiv>
                                                            </AssignedDiv>
                                                        </Grid>
                                                        <Grid xs={12} item>
                                                            <AssignedDiv>
                                                                <Title>Associated Roles</Title>
                                                                <CompanyRoleListDiv>
                                                                    {data?.roles?.map((_) =>
                                                                        <Desc marginBottom={"8px"}>{_ ?? " - "}</Desc>)}
                                                                </CompanyRoleListDiv>
                                                            </AssignedDiv>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid xs={12} sm={6} md={6} lg={6} item>
                                                <AssignedDiv>
                                                    <Title>Associated Roles</Title>
                                                    <PropertyListDiv>
                                                        {data?.property_names?.map((_) =>
                                                            <Desc marginBottom={"8px"}>{_ ?? " - "}</Desc>)}
                                                    </PropertyListDiv>
                                                </AssignedDiv>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} item>
                                    <MapAddressDiv>
                                        <Grid container>
                                            <Grid container spacing={"16px"}>
                                                {/* <Grid xs={12} sm={6} md={6} lg={8} item>
                                        <MapOuterDiv>
                                            <MapInnerDiv>
                                                {(data?.contact?.latitude && data?.contact?.longitude) &&
                                                    <MyGoogleMap readonly
                                                        lat={data?.contact?.latitude}
                                                        lng={data?.contact?.longitude}
                                                        center={{
                                                            lat: data?.contact?.latitude,
                                                            lng: data?.contact?.longitude
                                                        }} />}
                                            </MapInnerDiv>
                                        </MapOuterDiv>
                                    </Grid> */}
                                                <Grid xs={12} item>
                                                    <AddressDiv>
                                                        <Grid container>
                                                            <Grid container spacing={"16px"}>
                                                                <Grid xs={12} item>
                                                                    <Stack direction={"row"}
                                                                        gap={"4px"}>
                                                                        <LocationPin />
                                                                        <CardTitle color={"#091B29"}>Address</CardTitle>
                                                                    </Stack>
                                                                </Grid>
                                                                <Grid xs={12} item>
                                                                    <Desc>
                                                                        {constructAddress(data?.contact)}
                                                                    </Desc>
                                                                </Grid>
                                                                <Grid xs={12} item>
                                                                    <Stack direction={"row"} gap={"4px"}
                                                                        alignItems={"baseline"}>
                                                                        <Title marginBottom={"0px"}>Latitude : </Title>
                                                                        <Desc>{data?.contact?.latitude ?? " - "}</Desc>
                                                                    </Stack>
                                                                </Grid>
                                                                <Grid xs={12} item>
                                                                    <Stack direction={"row"} gap={"4px"}
                                                                        alignItems={"baseline"}>
                                                                        <Title marginBottom={"0px"}>Longitude : </Title>
                                                                        <Desc>{data?.contact?.longitude ?? " - "}</Desc>
                                                                    </Stack>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </AddressDiv>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </MapAddressDiv>
                                </Grid>
                            </Grid>
                        </Grid>
                    </BodyDiv>)}
            {permission?.update &&
                <FooterDiv>
                    <EditButton
                        onClick={() => history.push(Routes.profileEdit)}>
                        Edit Profile
                    </EditButton>
                </FooterDiv>}
            {isViewerOpen && (<ImageViewer
                src={currentImage}
                currentIndex={0}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                className={classes.imageViewer}
                closeOnClickOutside={true} />)}
        </>
    }
    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}