import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../../utils";
export const AggrementTabStyles = makeStyles((theme) => ({
  box: {
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: "4px",
    height: "calc(100vh - 200px)",
    overflow: "auto",
    backgroundColor: "white",
  },
  box1: {
    height: "calc(100vh - 250px)",
    overflow: "auto",
    backgroundColor: "white",
  },
  timelineRoot: {
    backgroundColor: "white",
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: "4px",
    height: "calc(100vh - 210px)",
    overflow: "hidden"
  },
  kycbox: {
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: "4px",
    height: "calc(100vh - 220px)",
    overflow: "auto",
    backgroundColor: "white",
  },
  detailsKYC: {
    height: "calc(100vh - 220px)",
    backgroundColor: "#F5F7FA",
    padding: "16px",
    overflow: "auto",
    cursor: "pointer"
  },
  buttomBtn: {
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    padding: "16px",
  },
  decline: {
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "#FF4B4B",
    color: "#fff",
    fontFamily: Bold,
    "&:hover": {
      backgroundColor: "#FF4B4B",
      color: "#fff",
    },
  },
  approve: {
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "#5AC782",
    color: "#fff",
    marginLeft: '10px',
    fontFamily: Bold,
    "&:hover": {
      backgroundColor: "#5AC782",
      color: "#fff",
    },
  },
  managedInvoiceRoot: {
    padding: "12px",
    height: "calc(100vh - 344px)",
    overflow: "auto",
    backgroundColor: "#F5F7FA",
  },
  managedInvoiceRoot1: {
    padding: "12px",
    height: "calc(100vh - 272px)",
    overflow: "auto",
    backgroundColor: "#F5F7FA",
  },
  submit: {
    borderRadius: "4px",
    fontFamily: Bold,
    boxShadow: "none",
    padding: "8px",
    "&:hover": {
      color: "#fff",
    },
  },
  kycForm: {
    position: "absolute",
    backgroundColor: "white",
    zIndex: "1",
    bottom: "0px",
    left: "0px",
    right: "0px",
    width: "100%",
    padding: "12px",
    boxShadow: "0px -10px 10px #00000014"
  },
  title: {
    color: "#4E5A6B",
    fontSize: "16px",
    fontFamily: Bold,
    textTransform: "capitalize"
  },
  btn: {
    boxShadow: "none",
    padding: "8px 12px",
    color: "white"
  }
}));
