import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        padding: "16px",
        alignItems: "center",
        borderRadius: "12px",
        backgroundColor: "white",
        boxShadow: "0px 3px 30px #5C86CB2E",
        height: "116px",
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    count: {
        color: "#091B29",
        fontSize: "24px",
        fontFamily: Bold
    },
    label: {
        color: "#091B29",
        fontSize: "14px",
        fontFamily: Bold
    },
}));