
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Divider, Grid, Stack, Tab } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { TransferLead } from '../../assets';
import ShortListIMG from '../../assets/shortlistImg';
import { AlertDialog, Subheader } from "../../components";
import { AuthContext } from '../../contexts';
import { Routes } from '../../router/routes';
import { accessCheckRender, getRoutePermissionNew } from '../../utils';
import { returnShortlistGoData } from '../../utils/oppertunityUtils';
import { CloseOppertunity, TransferOppertunity } from "./component";
import { useStyles } from "./styles";
import { Activity, Details, Quotation, Todo } from "./tab";
export const OppertunityView = () => {
    const classes = useStyles()
    const history = useHistory()
    const { state } = useLocation();    
    const oppertunity_id = state?.id

    const [details, setDetails] = React.useState({})
    const [openTransfer, setOpenTransfer] = React.useState(false)
    const [openClose, setOpenClose] = React.useState(false)
    const [value, setValue] = React.useState("1")
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
        }
        // eslint-disable-next-line
    }, [auth]);

    //onChange tab
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //go shortlist
    const goShortlisted = () => {
        history.push({
            pathname: Routes.shortlisted,
            state: returnShortlistGoData(details)
        })
    }
    //close oppertunity
    const onCloseoppertunity = () => {
        setOpenClose(false)
        history.push(Routes.leads)
    }
    //close transfer
    const onCloseTransferoppertunity = () => {
        setOpenTransfer(false)
        history.push(Routes.leads)
    }
    const isRead = (details?.status === 3 || details?.status === 1) ? true : false
    // console.log("state",state?.value)
    const render = () => {
        return <>
            <Subheader title="View Opportunity" goBack={() => history.goBack()} />
            <Box className={classes.root}>
                <TabContext value={value}>
                    <TabList onChange={handleChange}>
                        <Tab label="Opportunity" value="1" />
                        <Tab label="Activity" value="2" />
                        <Tab label="To do List" value="3" />
                        <Tab label="Quotation" value="4" />
                    </TabList>
                    <Divider />
                    <TabPanel value="1" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            <Details permission={permission} oppertunity_id={oppertunity_id} setDetails={setDetails} details={details} />
                        </Box>
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            <Activity permission={permission} oppertunity_id={oppertunity_id} selectedCompany={state?.value} />
                        </Box>
                    </TabPanel>
                    <TabPanel value="3" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            <Todo permission={permission} oppertunity_id={oppertunity_id} />
                        </Box>
                    </TabPanel>
                    <TabPanel value="4" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            <Quotation permission={permission} oppertunity_id={oppertunity_id} />
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
            {/*transfer oppertunity */}
            <AlertDialog
                onClose={() => setOpenTransfer(false)}
                open={openTransfer}
                header="Transfer Oppertunity"
                component={<TransferOppertunity oppertunity_id={oppertunity_id} onClose={onCloseTransferoppertunity} />}
                isNormal={true}
            />

            {/*close oppertunity */}
            <AlertDialog
                onClose={() => setOpenClose(false)}
                open={openClose}
                header="Close Oppertunity"
                component={<CloseOppertunity oppertunity_id={oppertunity_id} onClose={onCloseoppertunity} />}
                isNormal={true}
            />


    <Grid container className={classes.btnRoot} justifyContent="space-between">
                <Grid item xs={6}>
                    <Stack direction="row" spacing={2}>
                        {permission?.update &&
                            <Button startIcon={<TransferLead />} className={classes.iconBtn} variant='contained' onClick={() => setOpenTransfer(true)} disabled={isRead}> Transfer Opportunity</Button>}
                        {permission?.create &&
                            <Button startIcon={<ShortListIMG color="#8887D4" />} onClick={goShortlisted} disabled={isRead ? true : details?.shortlisted?.length > 0 ? false : true} className={classes.iconBtn} variant='contained'>&nbsp;&nbsp;{details?.shortlisted?.length} Units Shortlisted</Button>}
                    </Stack>


                </Grid>
                <Grid item xs={6}>
                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        {permission?.update &&
                            <Button className={classes.closeBtn} variant='outlined' onClick={() => setOpenClose(true)} disabled={isRead}>Close The Opportunity</Button>}
                        {/* <Button className={classes.requsetBtn} variant='contained'>Request For Reserve</Button> */}
                    </Stack>
                </Grid>
            </Grid>

            
        </>
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )
}