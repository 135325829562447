import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AlertDialog, FilterGenerator, GeneralCard, LoadingSection, ProductUpdate, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AuthContext } from "../../contexts";
import { UPDATE_INSPECTION_MAPPING } from "../../graphql/inspectionMapping";
import { withNavBars } from "../../HOCs";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, enumSelect, enum_types, getRoutePermissionNew, InspectionUnitItemDataType, InspectionUnitItemHeading, InspectionUnitItemPath, InspectionUnitItemRow, NetWorkCallMethods } from "../../utils";
import { StatusOptionList } from "../../utils/insepectionMaster";
import AddInspectionItem from "./components/addInspectionItem";
import PropertyDetailsCard from "./components/propertyDetailsCard";
import InspectionMappingTable from "./components/table";
import { InspectionMappingStyles } from "./styles";

const MapUnitDetails = () => {
    const classes = InspectionMappingStyles()
    const history = useHistory();
    const client = useApolloClient();
    const { state } = useLocation();
    const debounce = UseDebounce();
    const [searchText, setSearchText] = React.useState("");
    const [loading, setLoading] = React.useState(true)
    const [page, setPage] = React.useState(1);
    const auth = React.useContext(AuthContext);
    const [limit, setLimit] = React.useState(10);
    const [permissions, setPermission] = React.useState({})
    const [itemList, setItemList] = React.useState({
        details: {},
        list: [],
        count: 0,
    })
    const [addInspection, setAddInspection] = useState(false);
    const [viewInspection, setViewInspection] = useState(false);
    const [drawer, setDrawer] = React.useState(false);
    const [filterData, setFilterData] = React.useState({
        is_active: null
    });
    const [selectedItem, setSelectedItem] = React.useState({})
    const [itemConditionOptions, setItemConditionOptions] = React.useState([])


    //get list
    const getUnitDetails = (searchText, offset, limits, filterData) => {
        setLoading(true)

        const payload = {
            unit_id: state?.id,
            search: searchText,
            offset: offset,
            limit: limits,
            active: filterData?.is_active ?? null
        }

        NetworkCall(
            `${config.api_url}/inspection_item_mapping/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const data = response?.data;
            setItemList({
                details: data?.unit,
                list: data?.data,
                count: data?.count
            })
            setLoading(false)

        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })



    }
    //update
    const updateMapping = (type, details, key, value, id) => {
        client.mutate({
            mutation: UPDATE_INSPECTION_MAPPING,
            variables: {
                id: selectedItem?.id ?? id,
                updatePayload: type === "status" ? { [key]: value } : details
            }
        }).then(rs => {
            getUnitDetails("", 0, 10, {});
            setFilterData({
                is_active: null
            })
            setViewInspection(false);
        }).catch(er => {
            console.log(er)
        })
    }
    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types.inspection_item_condition])
        setItemConditionOptions(result?.inspection_item_condition)
    }
    //initial load
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)

            getUnitDetails("", 0, 10)
            getEnum()
        }
        // eslint-disable-next-line
    }, [auth])
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getUnitDetails("", offset, limit)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getUnitDetails("", 0, value)
    }
    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getUnitDetails(e, 0, limit)
    }
    //Add inspection Item
    const handleAddInspection = () => {
        setAddInspection(true)
    }
    const handleIcon = (type, data) => {

        if (type === "view") {
            setSelectedItem(data)
            setViewInspection(true)
        } else if (type === "active") {
            updateMapping("status", [], "is_active", data?.is_active ? false : true, data?.id)
        }
    }
    //apply filter
    const onApplyFilter = (data) => {
        getUnitDetails("", 0, 10, data)
        setFilterData(data)
        setDrawer(false)
    }
    //reload
    const reload = () => {
        getUnitDetails("", 0, 10, {})
        setFilterData({
            is_active: null
        })
    }

    const render = () => {
        return (
            <Box>
                {/* subheader */}
                <Subheader goBack={() => history.goBack()} title="Property Automate 102" />
                {/* unitdetails card */}
                {
                    loading ?
                        <LoadingSection top="20vh" message={"Fetching Details"} />
                        :
                        <Box className={`${classes.content}`}>
                            <Box className={`${classes.root}`}>
                                <PropertyDetailsCard title="unitDetails"
                                    data={itemList?.details}
                                />
                            </Box>
                            {/* items table */}
                            <Box className={`${classes.root}`}>

                                <InspectionMappingTable
                                    permissions={permissions}
                                    dot={filterData?.is_active?.length > 0}
                                    onFilter={() => setDrawer(true)}
                                    placeholder="searchInspectionItem"
                                    handleAddInspection={() => handleAddInspection()}
                                    handleIcon={handleIcon}
                                    heading={InspectionUnitItemHeading}
                                    path={InspectionUnitItemPath}
                                    row={InspectionUnitItemRow}
                                    dataType={InspectionUnitItemDataType}
                                    add="Map New Item"
                                    searchText={searchText}
                                    handleSearch={handleSearch}
                                    handleChangeLimit={handleChangeLimit}
                                    handlePagination={handlePagination}
                                    page={page}
                                    list={itemList}
                                    limit={limit}
                                    height={`calc(100vh - 500px)`}
                                />
                            </Box>
                        </Box>
                }

                {/*Add Inspection Item */}
                <AlertDialog open={addInspection}
                    onClose={() => setAddInspection(!addInspection)}
                    header="Add Inspection Item"
                    component={<AddInspectionItem details={itemList?.details} property_id={state?.property_id}
                        onClose={() => setAddInspection(false)}
                        reload={reload}
                    />}
                    md
                />
                {/*view Inspection Item */}
                <AlertDialog open={viewInspection}
                    onClose={() => setViewInspection(!viewInspection)}
                    header="Inspection Item Name"
                    component={
                        <Box className={`${classes.viewCard}`} p={2}>
                            <GeneralCard
                                list={{
                                    image: "/images/Group 98362.svg",
                                    Name: selectedItem?.name,
                                    product_id: selectedItem?.product_id,
                                    date: selectedItem?.created_at,
                                    is_active: selectedItem?.is_active,
                                    item_type: selectedItem?.item
                                    // condition: "Condition",
                                    // clean: "Cleaness",
                                    // description: "Description",
                                    //mandatory: true
                                }}
                                component={"toggleButton"}
                                imageAvatar
                                productId={true}
                                handleCheck={(val) => updateMapping("status", [], "is_active", val)}
                            />
                            {selectedItem?.item === "Inventory" &&
                                <Box mt={2}>
                                    <ProductUpdate inspection_item_map={selectedItem} onUpdate={updateMapping} itemConditionOptions={itemConditionOptions} />
                                </Box>
                            }
                        </Box>
                    }
                    isNormal
                />
                {/*Product Update */}
                {/* <AlertDialog
                    open={productUpdate}
                    onClose={() => setProductUpdate(false)}
                    header="Update"
                    component={<ProductUpdate inspection_item_map={selectedItem} onUpdate={updateMapping} itemConditionOptions={itemConditionOptions} />}
                    isNormal

                /> */}

                {/*filter component */}
                {drawer && (
                    <FilterGenerator
                        open={drawer}
                        onClose={() => setDrawer(false)}

                        components={[
                            {
                                component: "toggleButton",
                                value: filterData?.is_active,
                                state_name: "is_active",
                                label: "Active",
                                options: StatusOptionList,
                                isMulti: true
                            },
                        ]}
                        onApply={(value) => onApplyFilter(value)}
                    />
                )}

            </Box>
        )
    }
    return (
        <>

            {accessCheckRender(render, permissions)}

        </>
    )
}
const props = {
    boxShadow: false
}
export default withNavBars(MapUnitDetails, props)