export const Path = [
    "imageUrl",
    "name",
    "gender",
    "mobileNo",
    "profession",
    "idType",
    "idNumber",
    "barred",
    "status"
]

export const Heading = [
    { title: "", field: "icon" },
    { title: "Image", field: "imageUrl" },
    { title: "Name", field: "name" },
    { title: "Gender Code", field: "gender" },
    { title: "Mobile Number", field: "mobileNo" },
    { title: "Profession", field: "profession" },
    // { title: "ID Type", field: "idType" },
    // { title: "ID Number", field: "idNumber" },
    { title: "Barred", field: "barred" },
    { title: "Status", field: "status", },
]

export const Type = [
    { type: ["more"], icon: "icon" },
    { type: ["logo"], name: "imageUrl" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "gender" },
    { type: ["text"], name: "mobileNo" },
    { type: ["text"], name: "profession" },
    // { type: ["text"], name: "idType" },
    // { type: ["text"], name: "idNumber" },
    { type: ["text"], name: "barred" },
    { type: ["status"], name: "status" },
]

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]

export const GenderOptionList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
]