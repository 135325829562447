import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const pricingAppraisalStyle = makeStyles((theme) => ({
  root: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 0px 16px ${theme?.palette?.boxShadow}`,
    backgroundColor: theme?.palette?.background?.tertiary1,
    padding: "16px",
    height: `calc(100vh - 147px)`,
    overflow: "hidden",
    margin: "14px",
  },
  img: {
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    borderRadius: theme?.palette?.borderRadius,
    padding: "10px",
  },
  menu: {
    fontSize: "14px",
    fontFamily: SemiBold,
    color: theme?.palette?.secondary?.main,
  },
  button: {
    fontSize: "14px",
    fontFamily: SemiBold,
  },
  popUpParent: {
    padding: "16px",
    overflow: "auto",
  },
  tableMain: {
    paddingTop: "0 !important"
  }
}));

export const againstCompanyStyle = makeStyles((theme) => ({
  popUpParent: {
    padding: "7px",
    overflow: "auto",
  },
  btnParent: {
    padding: "16px",
    backgroundColor: theme?.palette?.background?.tertiary1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  next: {
    marginLeft: "10px",
    fontFamily: SemiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  Cancel: {
    backgroundColor: theme?.palette?.background?.tertiary1,
    color: "#091B29",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: SemiBold,
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    "&:hover": {
      backgroundColor: theme?.palette?.background?.tertiary1,
    },
  },
  valueTitle: {
    color: theme?.palette?.background?.primary,
    fontFamily: Bold,
    fontSize: "12px",
  },
  endorement: {
    color: theme?.palette?.background?.primary,
    fontFamily: SemiBold,
    fontSize: "12px",
  },
  divider: {
    margin: "20px 0px",
  },
}));

export const againstPropertyStyle = makeStyles((theme) => ({
  popUpParent: {
    padding: "16px",
    overflow: "auto",
  },
  btnParent: {
    padding: "16px",
    backgroundColor: theme?.palette?.background?.tertiary1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  next: {
    marginLeft: "10px",
    fontFamily: SemiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  Cancel: {
    backgroundColor: theme?.palette?.background?.tertiary1,
    color: "#091B29",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: SemiBold,
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    "&:hover": {
      backgroundColor: theme?.palette?.background?.tertiary1,
    },
  },
  valueTitle: {
    color: theme?.palette?.background?.primary,
    fontFamily: Bold,
    fontSize: "12px",
  },
  endorement: {
    color: theme?.palette?.background?.primary,
    fontFamily: SemiBold,
    fontSize: "12px",
  },
  divider: {
    margin: "20px 0px",
  },
}));
