import { makeStyles } from "@mui/styles";

export const UserListStyles = makeStyles((theme)=>({
    card:{
        border:`1px solid ${theme.palette.border.secondary}`,
        borderRadius:4,
        width:"100%",
        cursor:"pointer",
        '&:hover':{
            boxShadow:`0 0 3px 1px rgba(0,0,0,0.1)`
        },
        '& .MuiIcon':{
            visibility:"hidden"
        }
    },
    card_alert:{
        border:`1px solid ${theme.palette.border.secondary}`,
        borderRadius:4,
        width:"100%",
        cursor:"pointer",
        '&:hover':{
            border:`1px solid ${theme.palette.primary.main}`,
            "& .MuiIcon-root":{
                visibility:"visible"
            }
        },
        "& .MuiIcon-root":{
            visibility:"hidden"
        }
    },
    avatar:{
        borderRadius:4,
    },
    name:{
        fontSize:14,
        color:theme.typography.color.primary,
        fontWeight:"bold",
        whiteSpace:"nowrap"
    },
    role:{
        fontSize:12,
        color:theme.typography.color.tertiary,
        whiteSpace:"nowrap"
    },
    arrow:{
        color:theme.typography.color.tertiary,
        '&:hover':{
            color:theme.typography.color.primary
        }
    },
    check_circle:{
        color:theme.palette.primary.main,
    },
    circle_icon_hidden:{
        visibility:"hidden"
    }
}))