import React from "react";
import { withNavBars } from "../../HOCs";
import AmenitiesBooking  from "./amenitiesBooking";

class AmenitiesBookingParent extends React.Component {
    render() {
        return <AmenitiesBooking />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AmenitiesBookingParent, props);
