import React from "react";
import { withNavBars } from "../../HOCs";
import { CommunityDashboard } from "./communityDashboard";
class CommunityDashboardParent extends React.Component {
  render() {
    return (
      <CommunityDashboard {...this.props} />
    )
  }
}
const props = {
  boxShadow: false
}
export default withNavBars(CommunityDashboardParent, props)