import { Box, Typography } from "@mui/material";
import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { ToggleButtonComponent } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import { PromotionCard } from "../../propertyFinder3/component/promotionCard";
import { useStyles } from ".././styles";

export const Promotion = ({ company_id }) => {

    const classes = useStyles()
    const [data, setData] = React.useState({
        property: [],
        unit: [],
        offset: 0,
        type: "property",
        loading: true
    })

    const types = [
        {
            label: "Recently Added Properties",
            value: "property"
        },
        {
            label: "Units Under Promotion",
            value: "unit"
        }
    ]
    const getData = (offsets) => {
        const payload = {
            company_id,
            offset: offsets,
            limit: 10
        }
        NetworkCall(
            `${config.api_url}/search-property-new/get_feature_unit`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setData({
                ...data,
                property: res?.data?.data?.property,
                unit: [...data?.unit, ...res?.data?.data?.unit],
                offset: offsets,
                loading: false
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    React.useEffect(() => {
        if (company_id) {
            getData(0)
            setData({ ...data, loading: true })
        }
        // eslint-disable-next-line
    }, [company_id])


    //on change type
    const changeTypes = (val) => {
        setData({
            ...data,
            type: val
        })
    }

    //on horizontial scroll
    const onEndScroll = () => {
        if (data?.type === "unit") {
            getData(data?.offset + 10)
        }
    }

    return (
        <Box className={classes.promotionRoot}>
            <ToggleButtonComponent
                options={types}
                label={false}
                value={data?.type}
                onChange={(val) => changeTypes(val ?? data?.type)}

            />
            <Box height="12px" />
            {
                data?.[data?.type]?.length > 0 ?
                    <ScrollContainer style={{ display: "flex" }} className="scroll-container" onEndScroll={onEndScroll}>

                        {
                            data?.[data?.type]?.map((val, index) => {
                                return (
                                    <Box key={val?.id} marginLeft={index !== 0 ? "12px" : "0px"}>
                                        <PromotionCard data={val} />
                                    </Box>
                                )
                            })
                        }

                    </ScrollContainer>
                    :
                    <Typography className={classes.title}>{data?.loading ? "Loading..." : "No Result Found"}</Typography>
            }

        </Box>
    )
}