import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab, Typography } from "@mui/material";
import React from 'react';
import ReactQuill from "react-quill";
import "../../../../App.css";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import { PropertyAmenities } from "./propertyAmenities/propertyAmenities";
import { propertyStyles } from "./style";
// import { UnitList } from "./unitList/unitList";

export const PropertyDetails = (props) => {
      const classes = propertyStyles()
      const size = useWindowDimensions()
      const modules = {
            toolbar: false,
      };
      const [value, setValue] = React.useState('1');

      const handleChange = (event, newValue) => {
            setValue(newValue);
      };
      return (

            <div style={{ height: size?.height - (64 + 49), overflow: "auto", padding: "24px" }}>
                  {/*property detials first card */}
                  <Grid container className={classes.card}>
                        <Grid item xs={12} sm={12} md={2} lg={2} >
                              <div className={classes.imgDiv}>
                                    <img src={(props?.data?.logo && props?.data?.logo !== "") ? props?.data?.logo : "/images/imagesproperty.svg"} alt="" className={classes.unitImg} />
                                    <Typography className={classes.imgTag}>&nbsp;&nbsp;{props?.data?.property_no}&nbsp;&nbsp;</Typography>
                              </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10} className={classes.imgDiv2}>
                              <Typography className={classes.title} marginBottom={"14px"}>PROPERTY DETAILS</Typography>
                              <Grid container>
                                    <Grid container spacing={1}>
                                          <Grid item xs={3}>
                                                <Typography className={classes.heading}>Company Name</Typography>
                                                <Typography className={classes.sub}>{props?.data?.company?.name ? props?.data?.company?.name : " - "}</Typography>
                                          </Grid>
                                          <Grid item xs={2}>
                                                <Typography className={classes.heading}>Property Name</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.name ? props?.data?.name : " - "}
                                                </Typography>
                                          </Grid>
                                          <Grid item xs={2}>
                                                <Typography className={classes.heading}>Property Type</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.property_groupByID?.group_name ? props?.data?.property_groupByID?.group_name : "- "}
                                                </Typography>
                                          </Grid>
                                          <Grid item xs={2}>
                                                <Typography className={classes.heading}>Property Purpose</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.property_purpose ? props?.data?.property_purpose : "-"}
                                                </Typography>
                                          </Grid>
                                          <Grid item xs={2}>
                                                <Typography className={classes.heading}> Payment Period</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.payment_period ? props?.data?.payment_period : "-"}
                                                </Typography>
                                          </Grid>

                                          <Grid item xs={1}>
                                                <Typography className={classes.heading}>Status</Typography>
                                                <Typography className={classes.sub}>{props?.data?.is_active ? "Active" : "In active"}</Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                                <Typography className={classes.heading}> Property Description</Typography>
                                                {props?.data?.description &&
                                                      <ReactQuill
                                                            readOnly
                                                            theme="bubble"
                                                            value={props?.data?.description ?? ""}
                                                            modules={modules}
                                                            className={classes.sub}
                                                      />
                                                }
                                          </Grid>
                                    </Grid>
                              </Grid>


                        </Grid>
                  </Grid>
                  <br />
                  <Grid container className={classes.card} p={"0px !important"}>
                        <Grid item xs={12}>
                              <Box sx={{ width: '100%', typography: 'body1' }}>
                                    <TabContext value={value}>
                                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                      <Tab label="Property Amenities" value="1" />
                                                      {/* As per CR commented */}
                                                      {/* <Tab label="Unit Amenities" value="2" /> */}
                                                </TabList>
                                          </Box>
                                          <TabPanel value="1" sx={{ p: 0 }}>
                                                <PropertyAmenities
                                                      permission={props?.permission}
                                                      currency={props?.data?.company?.currency}
                                                      propertyId={props?.data?.id}
                                                      companyId={props?.data?.company?.id}
                                                />
                                          </TabPanel>
                                          {/* As per CR commented */}
                                          {/* <TabPanel value="2" sx={{ p: 0 }}>
                                                <UnitList />
                                          </TabPanel> */}
                                    </TabContext>
                              </Box>
                        </Grid>
                  </Grid>

            </div>
      )
}