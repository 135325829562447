import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Regular, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
        margin: "14px"
    },
    content: {
        textAlign: "center"
    },
    button: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize: "14px"
    },
    filterButton: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    viewDialog: {
        "& .MuiPaper-root": {
            maxWidth: "900px !important",
        },
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
        },
    },
    viewDialogHeader: {
        padding: "16px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E4E8EE"
    },
    viewDialogHeaderContainer: {
        display: "flex",
        columnGap: "12px"
    },
    statusTag: {
        borderRadius: "4px",
        backgroundColor: "#FFF4EB",
        padding: "3px 6px"
    },
    statusTagText: {
        fontSize: "12px",
        fontFamily: Bold,
        color: "#FF9340"
    },
    viewDialogHeaderTitle: {
        fontFamily: ExtraBold,
        fontSize: "16px",
        color: "#091B29"
    },
    viewDialogCloseButton: {
        padding: "0px"
    },
    viewDialogBody: {
        padding: "16px",
        overflowY: "overlay"
    },
    viewDialogText1: {
        display: "flex",
        fontFamily: SemiBold,
        fontSize: "10px",
        color: "#98A0AC",
        wordBreak: "break-all",
    },
    viewDialogText2: {
        display: "flex",
        fontFamily: SemiBold,
        fontSize: "14px",
        color: "#091B29",
        wordBreak: "break-all"
    },
    viewDialogText3: {
        fontFamily: Bold,
        fontSize: "12px",
        color: "#091B29"
    },
    viewDialogText4: {
        fontFamily: Bold,
        fontSize: "14px",
        color: "#091B29"
    },
    viewDialogText5: {
        fontFamily: Regular,
        fontSize: "12px",
        color: "#4E5A6B"
    },
    viewDialogContactContainer: {
        padding: "14px 12px",
        backgroundColor: "#F5F7FA",
        borderRadius: "4px",
        border: "1px solid #E4E8EE"
    },
    viewDialogContactDivider: {
        backgroundColor: "#E4E8EE",
        height: "2px"
    },
    viewDialogContactCard: {
        width: "100%",
        display: "flex",
        backgroundColor: "white",
        borderRadius: "4px",
    },
    period: {
        height: "6px",
        width: "6px",
        margin: "0px 8px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD"
    },
    viewDialogTableContainer: {
        marginBottom: "8px",
        padding: "16px 16px 0px 16px",
        backgroundColor: "rgba(242, 244, 247, 0.5)",
        borderRadius: "4px",
    },
    viewDialogTable: {
        width: "100%"
    },
    contactAvatar: {
        borderRadius: "4px",
        boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.12)",
        marginRight: "12px"
    },
    viewDialogButtonContainer: {
        display: "flex",
        columnGap: "8px",
    },
    acceptButton: {
        borderRadius: theme.palette.borderRadius,
        height: "40px",
        boxShadow: "none",
        fontSize: "14px",
        fontFamily: Bold,
        color: "white",
        backgroundColor: "#5AC782",
        "&:hover": {
            backgroundColor: "#5AC782 ",
        },
    },
    declineButton: {
        borderRadius: theme.palette.borderRadius,
        height: "40px",
        boxShadow: "none",
        fontSize: "14px",
        fontFamily: Bold,
        color: "white",
        backgroundColor: "#FF4B4B",
        "&:hover": {
            backgroundColor: "#FF4B4B ",
        },
    },
    acceptDeclineDialog: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
            width: "475px"
        },
    },
    acceptDeclineDialogBody: {
        padding: "24px",
        overflowY: "overlay"
    },
    acceptDeclineDialogFooter: {
        display: "flex",
        columnGap: "12px",
        padding: "24px",
    },
    acceptDeclineButton: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        boxShadow: "none",
        border: "1px solid #5078E1",
        fontSize: "14px",
        fontFamily: Bold,
        color: "white",
        backgroundColor: "#5078E1",
        "&:hover": {
            backgroundColor: "#5078E1 ",
        },
    },
    cancelButton: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        boxShadow: "none",
        border: "1px solid #5078E1",
        fontSize: "14px",
        fontFamily: Bold,
        color: "#5078E1",
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "#E4E8EE ",
        },
    },
    box: {
        display: "flex",
        padding: "12px",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 8px 24px #0717411F"

    },
    boxWithOutFlex: {
        padding: "12px",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 8px 24px #0717411F"

    },
    boxWithOutBorder: {
        display: "flex",
        borderRadius: theme.palette.borderRadius,
    },
    boxWithOutBorderColor: {
        display: "flex",
        alignItems: "center",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#F1F7FF",
        padding: "8px",
        marginTop: "8px",
        cursor: "pointer"
    },
    italicText: {
        fontSize: "12px",
        fontStyle: "italic",
        color: theme.typography.color.secondary
    },
    semiBoldText: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
        fontFamily: `${SemiBold}`

    },
    regularText: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
        fontFamily: `${Regular}`
    },
    conform: {
        fontSize: "12px",
        color: "#78B1FE",
        fontFamily: `${Bold}`,
        backgroundColor: "#F1F7FF",
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    decline: {
        fontSize: "12px",
        color: "#FF4B4B",
        fontFamily: `${Bold}`,
        backgroundColor: "#FFECEC",
        borderRadius: theme.palette.borderRadius,
        padding: "4px"

    },
    viewMore: {
        fontSize: "12px",
        color: "#78B1FE",
        fontFamily: `${Bold}`,
        cursor: "pointer"
    },
    inProgress: {
        fontSize: "12px",
        color: "#FF9340",
        fontFamily: `${Bold}`,
        backgroundColor: "#FFF4EB",
        borderRadius: theme.palette.borderRadius,
        padding: "4px"

    },
    approved: {
        fontSize: "12px",
        color: "#5AC782",
        fontFamily: `${Bold}`,
        backgroundColor: "#EEF9EE",
        borderRadius: theme.palette.borderRadius,
        padding: "4px"

    },
    heading: {
        fontSize: "12px",
        fontFamily: `${Bold}`,
        color: theme.typography.color.tertiary
    },
    boxFlex: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    moveOut: {
        flexGrow: 1,
        marginLeft: "10px",
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",

    }
}));