import { useApolloClient } from "@apollo/client";
import { Badge, Box, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import FilterIMG from "../../../../../assets/filter";
import { AlertDialog, FilterGenerator, LoadingSection, SearchFilter, TableWithPagination, UseDebounce } from "../../../../../components";
import { config } from "../../../../../config";
import { AlertContext, BackdropContext } from "../../../../../contexts";
import { UPDATE_UNIT_AMENITIES } from "../../../../../graphql/mutations";
import { GET_UNIT_AMENITIES_TABLE_LIST } from "../../../../../graphql/queries";
import { NetworkCall } from "../../../../../networkcall/index";
import { AlertProps, AmenitiesHeadingUnit, AmenitiesPath, LocalStorageKeys, NetWorkCallMethods } from "../../../../../utils";
import { AddAmenitiesPopup } from "../../../addAmenitiesPopup";
import { UnitAmenitiesStyles } from "./style";
import moment from "moment";
import { addHours, subSeconds } from 'date-fns'
const initialState = () => {
    return {
        id: null,
        amenity: "",
        chargeable: "Yes",
        amount: "",
        period: "",
        status: "Active",
        notes: "",
        edit: false,
        view: false,
        error: {
            amenity: "",
            chargeable: "",
            amount: "",
            period: "",
            status: "",
        },
    }
}

export const UnitAmenities = (props) => {
    const classes = UnitAmenitiesStyles();
    const theme = useTheme()
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const [unitAmenities, setUnitAmenities] = React.useState({ ...initialState() })
    const [unitAmenitieslist, setUnitAmenitiesList] = React.useState({
        data: [],
        count: 0,
    })
    const [openDialog, setOpenDialog] = React.useState(false)
    const [drawer, setDrawer] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = React.useState(true)
    const [limit, setLimit] = React.useState(10);
    const debounce = UseDebounce();
    const [filterData, setFilterData] = React.useState({ status: [true] });
    const companyId = props?.companyId
    const currencyId = props?.currency?.id
    const unitId = props?.unitId
    const client = useApolloClient()
    const clientId = localStorage.getItem(LocalStorageKeys.clinetID)

    //pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        UnitAmenitiesTableData(offset, limit, searchText)
    }
    //page change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        UnitAmenitiesTableData(0, value, searchText)
    }
    //search in table
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        UnitAmenitiesTableData(0, limit, e)
    }
    //table listing function
    const UnitAmenitiesTableData = async (offset = 0, limit = 10, search = "") => {
        let is_active = filterData?.status ?? [];
        let chargeable = filterData?.chargeable ?? [];

        const payload = {
            query: GET_UNIT_AMENITIES_TABLE_LIST(clientId, offset, limit, search, unitId, is_active, chargeable).loc.source.body,

        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                const list = response?.data?.data?.unit_amenities_breakup?.map((val) => {
                    let _d;
                    try {
                        _d = {
                            amenityName: val?.amenities_type_master?.amenities_name ?? "-",
                            chargeable: val?.is_chargeable ? "Yes" : "No",
                            amount: val?.rate ? `${val?.currencyByID?.symbol ?? ""} ${val?.rate ?? "-"}` : " - ",
                            period: val?.period ?? "-",
                            status: val?.is_active ? "Active" : "Inactive",
                            notes: val?.notes ?? " - ",
                            icon: "more",
                            id: val?.id,
                            amenityId: val?.amenities_type_master?.id ?? "-",
                            booking: val?.amenities_type_master?.is_booking ? "Yes" : "No",
                            periodd: { value: val?.period, label: val?.period },
                            IsDelete: val?.is_delete,
                            amountt: val?.rate ?? " - "


                        };
                    } catch (err) {

                    }
                    return _d;

                })
                setUnitAmenitiesList({
                    data: list,
                    count: response?.data?.data?.count?.[0]?.count,

                })
                setLoading(false)
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            }).catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                });
            });
    }

    // is active changing function
    const changeactive = async (data, value) => {
        client.mutate({
            mutation: UPDATE_UNIT_AMENITIES,
            variables: {
                ID: data,
                updatePayload: {
                    is_active: value === "Inactive" ? true : false
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Status Updated",
            });
            UnitAmenitiesTableData()
        }).catch(er => {
            console.log(er)
        })
    }
    //delete function
    const deleteTableData = async (data) => {
        client.mutate({
            mutation: UPDATE_UNIT_AMENITIES,
            variables: {
                ID: data,
                updatePayload: {
                    is_delete: true
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Deleted",
            });
            UnitAmenitiesTableData()
            console.log(rs)
        }).catch(er => {
            console.log(er)
        })
    }

    React.useEffect(() => {
        if (props?.permission?.read) {
            if (unitId) {
                UnitAmenitiesTableData()
            }
        }
        //eslint-disable-next-line
    }, [unitId, filterData, props?.permission]);
    //handle icon function
    const handleIcon = (type, data, status) => {
        if (type === "view") {
            setOpenDialog(true)
            setUnitAmenities({
                ...unitAmenities,
                amenityName: data?.amenityName,
                chargeable: data?.chargeable,
                amount: data?.amountt,
                period: data?.periodd,
                notes: data?.notes,
                booking: data?.booking === "yes" ? { label: "Required", value: "Required" } : { label: "Not Required", value: "Not Required" },
                amenity: { label: data?.amenityName, value: data?.amenityId },
                edit: false,
                view: true,
                id: data?.id,
                status: data?.status,

            })
        }
        if (type === "edit") {
            setOpenDialog(true)
            setUnitAmenities({
                ...unitAmenities,
                amenityName: data?.amenityName,
                chargeable: data?.chargeable,
                amount: data?.amountt,
                period: data?.periodd,
                notes: data?.notes,
                booking: data?.booking === "yes" ? { label: "Required", value: "Required" } : { label: "Not Required", value: "Not Required" },
                amenity: { label: data?.amenityName, value: data?.amenityId },
                edit: true,
                id: data?.id,
                status: data?.status,

            })
        }
        else if (type === "active") {
            changeactive(data.id, status)
        }
        else if (type === "delete") {
            deleteTableData(data.id, data.IsDelete)
        }

    }
    //apply filter
    const onApplyFilter = (value) => {
        setFilterData(value)
    }

    //onclose modal
    const OncloseModal = () => {
        setOpenDialog(false)
        setUnitAmenities({ ...initialState() })
    }

    //Pop up netwoerk call

    const getUnitAmenities = (e) => {
         // eslint-disable-next-line
         let const_arr = e?.slot_time.map((arr)=>{
            if(arr.length !== 0)
                return [
                    moment(arr[0]).format("HH:mm:ss"),
                    moment(subSeconds(addHours(new Date(arr[arr.length - 1]), 1),1)).format("HH:mm:ss")
                ]
            
        })

        const result = const_arr.filter(element => {
            return element !== undefined
        })
        const variables = {
            "id": e?.id ?? undefined,
            "is_active": true,
            "unit_id": unitId,
            "amenities_type": e?.amenity_reference?.value,
            "is_chargeable": e?.chargeable,
            "total_slots": e?.slot_time.length === 0 ? 1 : e?.slot_time.length,
            "applicable_days": e?.days,
            "slot_partition": e?.slot,
            "selected_slots": result,
            "currency_id": currencyId,
            "rate": e?.chargeable_value,
            "period": e?.slot_type,
            "amenity_category_id": e?.amenity_category?.id
        }
        NetworkCall(
            `${config.api_url}/unit_amenities_breakup/upsert`,
            NetWorkCallMethods.post,
            variables,
            null,
            true,
            false
        )
            .then((response) => {
                if (response?.data?.type === "success") {
                    OncloseModal()
                    UnitAmenitiesTableData()
                }
            })
            .catch((error) => {

            });
    }
    return (
        <>
            {
                loading ? <LoadingSection bottom={"45vh"} message="Loading Amenities Listing..." /> :
                    <div>
                        <Box p={2} className={classes.root}  >
                            <Grid container >
                                <Grid item xs={4}>
                                    <SearchFilter value={searchText} placeholder="Search Amenities" handleChange={(value) => handleSearch(value)} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Box
                                        display={"flex"}
                                        sx={{ float: "right" }}>
                                        <Stack
                                            direction="row"
                                            divider={<Divider orientation="vertical" flexItem />}
                                            spacing={2}>
                                            {filterData.status?.length > 0 || filterData.property_type?.length > 0 ? (
                                                <IconButton onClick={() => setDrawer(true)} className={classes.img}>
                                                    <Badge variant="dot" color="primary">
                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton>

                                            ) : (
                                                <IconButton onClick={() => setDrawer(true)} className={classes.img}>
                                                    <FilterIMG color="#091b29" />
                                                </IconButton>)}
                                            {props?.permission?.create &&
                                                <Button
                                                    variant="contained"
                                                    sx={{ borderRadius: theme.palette.borderRadius }}
                                                    onClick={() => setOpenDialog(true)}
                                                >
                                                    Add Amenities
                                                </Button>}

                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                            <TableWithPagination
                                heading={AmenitiesHeadingUnit}
                                rows={unitAmenitieslist?.data}
                                path={AmenitiesPath}
                                showpagination={true}
                                count="2"
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleIcon}
                                tableType="no-side"
                                onClick={() => console.log("")}
                                dataType={[
                                    { type: ["text"], name: "amenityName" },
                                    { type: ["description"], name: "notes" },
                                    { type: ["text"], name: "chargeable" },
                                    { type: ["text"], name: "amount" },
                                    { type: ["text"], name: "period" },
                                    { type: ["text"], name: "booking" },
                                    { type: ["status"], name: "status" },
                                    { type: ["more"], icon: "icon" },

                                ]}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={unitAmenitieslist?.count}
                                page={page}
                                limit={limit}
                                height={`calc(100vh - 395px)`}
                                view={props?.permission?.read}
                                edit={props?.permission?.update}
                                delete={props?.permission?.delete} />
                        </Box>
                    </div>
            }
            {/* filter drawer */}
            {
                drawer &&
                <FilterGenerator
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    components={[

                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: [
                                { label: 'Active', value: true },
                                { label: 'Inactive', value: false },
                            ],
                            isMulti: true,
                            state_name: "status",
                            label: "Status",
                            // required:true
                        },
                        {
                            component: "toggleButton",
                            value: filterData?.chargeable,
                            options: [
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ],
                            isMulti: true,
                            state_name: "chargeable",
                            label: "Chargeable",
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            }
            {/* add and edit  and view */}
            <AlertDialog
                md
                header={unitAmenities?.view ? "View Amenities" : unitAmenities?.edit ? "Edit Amenities" : "Add Amenities"}
                onClose={OncloseModal}
                open={openDialog}
                component={
                    <>
                        <div>
                            <AddAmenitiesPopup click={getUnitAmenities}
                                currency={props?.currency} companyId={companyId}
                                data={unitAmenities} closeModel={OncloseModal} type={"unit"} />
                        </div>
                    </>
                }
            />
        </>
    );
};
