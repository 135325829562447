import React from "react";
import { withNavBars } from "../../../HOCs";
import { DomesticHelpRequest } from './domesticHelpRequest';

class DomesticHelpRequestParent extends React.Component {
  render() {
    return <DomesticHelpRequest {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(DomesticHelpRequestParent, props);
