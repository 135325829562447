import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { FormGenerator } from "../../../components";
import { AlertContext } from "../../../contexts";
import { UPDATE_TRANSFER_LEAD } from "../../../graphql/oppertunityQueries";
import { AlertProps, LocalStorageKeys } from "../../../utils/constants";
const initialState = {
    status: "",
    date: "",
    remark: "",
    error: {
        status: "",
        date: "",
        remark: "",
    }

}
const status = [
    {
        label: "Postpone",
        value: "Postpone",
    },
    {
        label: "Disqualify",
        value: "Disqualify",
    }
]

export const CloseOppertunity = ({ oppertunity_id = "", onClose = () => false }) => {
    const client = useApolloClient();
    const [data, setData] = React.useState({ ...initialState })
    const alert = React.useContext(AlertContext);
    //update state
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    };
    //validate 
    const validateForm = () => {
        let isValid = true;
        let error = data.error;
        if (data?.role?.length === 0) {
            isValid = false;
            error.role = "Role is Required";
        }
        if (data?.name?.length === 0) {
            isValid = false;
            error.name = "Name is Required";
        }
        setData({ ...data, error });
        return isValid;
    };
    //on submit
    const onSubmit = () => {
        if (validateForm()) {
            client.mutate({
                mutation: UPDATE_TRANSFER_LEAD,
                variables: {
                    leadID: oppertunity_id,
                    updatePayload: {
                        lead_closure_status: data?.status,
                        reason: data?.remark,
                        closed_by: localStorage.getItem(LocalStorageKeys.userProfileID),
                        closed_on: data?.date,
                        is_active: false,
                        status: 1
                    },
                }
            }).then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Close Oppertunity Successfully",
                });
                onClose()

            }).catch((err) => {
                console.log(err)
            })

        } else {
            return false
        }
    }

    const formData = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "toggle",
            label: "Lead Closing Status",
            value: data?.status,
            placeholder: " Lead Closing Status",
            onChange: (value) => updateState("status", value),
            error: data?.error?.role,
            isRequired: true,
            options: status
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "date",
            label: "Closing Date",
            value: data?.date,
            placeholder: "Closing Date",
            onChange: (value) => updateState("date", value),
            error: data?.error?.date,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: "Description",
            value: data?.description,
            placeholder: "Enter Description",
            onChange: (value) => updateState("description", value?.target.value),
            error: data?.error?.description,
            isRequired: true,
            multiline: true
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "button",
            label: "Submit",
            onClick: (value) => onSubmit(),

        }
    ]

    return (
        <Box p={3}>
            <FormGenerator components={formData} />
        </Box>
    )
}