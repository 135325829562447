import { Box, Typography } from "@mui/material";
import React from "react";
import { MapFieldsOnly } from "../../../components";
import { mapResult } from '../../../utils/common';
import { useStyles } from "../styles";
export const Address = ({ data = {}, updateState = {}, setData = () => false, mapLoad = true, loadOptions = () => false, loading = false }) => {
    const classes = useStyles()
    const [fullScreenMap, setFullScreenMap] = React.useState(false)
    //open full screen
    const fullScreenControl = () => {
        setFullScreenMap(true)
        document.getElementsByClassName('map-wrapper')[0]?.requestFullscreen()

    }
    //map result 
    const mapResults = (map) => {
        let result = mapResult(map);
        setData({
            ...data,
            doorNo: result?.doorNo,
            addressLineOne: result?.addressLineOne,
            addressLineTwo: result?.addressLineTwo,
            landmark: result?.landmark,
            area: result?.area,
            district: result?.district,
            city: result?.city,
            state: result?.state,
            country: result?.country,
            pincode: result?.pincode,
            lat: result?.latitude,
            long: result?.longitude
        })

    }
    return (
        <Box className={classes.box} marginTop="16px">
            <Typography className={classes.title}>Address</Typography>
            <MapFieldsOnly
                fullScreenMap={fullScreenMap}
                fullScreenControl={fullScreenControl}
                mapOptions={{
                    isInput: true,
                    center: {
                        lat: data?.latitude,
                        lng: data?.longitude
                    },
                    lat: data?.latitude,
                    lng: data?.longitude,
                    mapLoad: mapLoad
                }}
                mapResult={mapResults}
                autoCompletePlacement={{
                    top: 14,
                }}
                fields={[
                    // Door Number
                    {
                        label: "Door Number",
                        component: "TextField",
                        value: data?.doorNo,
                        state_name: 'doorNo',
                        isrequired: false,
                        placeholder: "Enter Door Number",
                        error: data?.error?.doorNo,
                        errorMessage: data?.error?.doorNo?.length > 0,
                        breakpoints: {
                            sm: 6,
                            md: 3
                        }
                    },
                    // Address Line 1
                    {
                        label: "Address Line 1",
                        component: "TextField",
                        value: data?.addressLineOne,
                        state_name: 'addressLineOne',
                        isrequired: false,
                        placeholder: "Enter Address Line 1",
                        errorMessage: data?.error?.addressLineOne,
                        error: data?.error?.addressLineOne?.length > 0,
                        breakpoints: {
                            sm: 6,
                            md: 3
                        }
                    },
                    // Address Line 2
                    {
                        label: "Address Line 2",
                        component: "TextField",
                        value: data?.addressLineTwo,
                        state_name: 'addressLineTwo',
                        isrequired: false,
                        placeholder: "Enter Address Line 2",
                        errorMessage: data?.error?.addressLineTwo,
                        error: data?.error?.addressLineTwo?.length > 0,
                        breakpoints: {
                            sm: 6,
                            md: 3
                        }
                    },
                    // Landmark
                    {
                        label: "Landmark",
                        component: "TextField",
                        value: data?.addressLineThree,
                        state_name: 'addressLineThree',
                        isrequired: false,
                        placeholder: "Enter Landmark",
                        errorMessage: data?.error?.addressLineThree,
                        error: data?.error?.addressLineThree?.length > 0,
                        breakpoints: {
                            sm: 6,
                            md: 3
                        }
                    },
                    // City
                    {
                        label: "City",
                        component: "TextField",
                        value: data?.city,
                        state_name: 'city',
                        isrequired: false,
                        placeholder: "Enter City",
                        errorMessage: data?.error?.district,
                        error: data?.error?.district?.length > 0,
                        breakpoints: {
                            sm: 6,
                            md: 3
                        }
                    },
                    // State
                    {
                        label: "State",
                        component: "TextField",
                        state_name: 'state',
                        value: data?.state,
                        isrequired: false,
                        placeholder: "Enter State",
                        errorMessage: data?.error?.state,
                        error: data?.error?.state?.length > 0,
                        breakpoints: {
                            sm: 6,
                            md: 3
                        }
                    },
                    // Country
                    {
                        label: "Country",
                        component: "Select",
                        value: data?.country,
                        state_name: 'country',
                        isrequired: false,
                        placeholder: "Enter Country",
                        errorMessage: data?.error?.country,
                        error: data?.error?.country?.length > 0,
                        breakpoints: {
                            sm: 6,
                            md: 3
                        }
                    },
                    // Pincode
                    {
                        label: "Pincode",
                        component: "TextField",
                        state_name: 'pincode',
                        value: data?.pincode,
                        isrequired: false,
                        placeholder: "Enter Pincode",
                        errorMessage: data?.error?.pincode,
                        error: data?.error?.pincode?.length > 0,
                        breakpoints: {
                            sm: 6,
                            md: 3
                        }
                    },
                ]}
                onChangeFields={(key, value) => updateState(key, value)}
                isPaginate={true}
                loadOptions={(search, array) => loadOptions(search, array, 'country')}
                loading={loading === "country"}
                debounceTimeout={800}
            />
        </Box>

    )
}