import { useApolloClient } from "@apollo/client";
import { Box, } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import CityProperty from '../../assets/cityproperty';
import { LoadingSection, PropertyTabs, Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { GET_CURRENCY, GET_VAT_GROUP } from '../../graphql/accountQueries';
import { GET_ACCOUNT_DETAILS } from '../../graphql/accountQuery';
import { GET_COUNTRY_MASTER_DROPDOWN } from "../../graphql/bussinessMasterQuery";
import { NetworkCall } from '../../networkcall';
import { Routes } from "../../router/routes";
import { accessCheckRender, AlertProps, enumSelect, enum_types, getRoutePermissionNew, isEmptyObject, NetWorkCallMethods } from "../../utils";
import { accountState, returnEditPayloadAccount, returnPayloadAccount } from "../../utils/accountUtiles";
import { Address, BankDetails, Contact, Profile, Proof } from "./sections";
import { useStyles } from "./styles";
export const CreateAccount = ({ reltype = null }) => {
    const classes = useStyles()
    const client = useApolloClient();
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)
    const history = useHistory()
    const { state } = useLocation()
    // const searchURL = useLocation().search;
    // const acc_id = new URLSearchParams(searchURL).get("id");
    const acc_id = state?.id
    const [data, setData] = React.useState({ ...accountState });
    const [loading, setLoading] = React.useState(false)
    const [fetch, setFetch] = React.useState(false)
    const [disable, setDisable] = React.useState(false)
    const [value, setValue] = React.useState(1);
    const [enumValue, setEnumValue] = React.useState({
        account_relationship: [],
        bank_routing_type: [],
        bank_account_type: [],
        tax_code: [],
        rera_type: [],
        company_registration: []
    })
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getAccount()
            }
        }
        // if(reltype){
        //     updateState()
        // }
        // eslint-disable-next-line
    }, [auth]);

    const typeSelect = {
        "Vendor": Routes.vendorAccountList,
        "Property Owner": Routes.ownerAccountList,
        "Customer": Routes.customerAccountList
    }

    const tabTittle = [
        {
            label: 'Account Details',
            sub: 'Account Details',
            imgSelect: value > 1 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
            value: 1,
            className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: 'KYC Details',
            sub: 'KYC Details',
            imgSelect: value > 2 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
            value: 2,
            className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
        },
    ]
    //update state
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        if (key === "relation_ship") {
            setData({
                ...data,
                relation_ship: value,
                contact: ""
            })
        }
        else {
            setData({ ...data, [key]: value, error });
        }
    };
    //get Enum
    const type_arr = [
        {
            label: reltype,
            value: reltype
        }
    ]
    const getEnum = async () => {
        const result = await enumSelect([enum_types.account_relationship, enum_types.bank_routing_type, enum_types.bank_account_type, enum_types?.tax_code, enum_types?.rera_type, enum_types?.company_registration])
        setEnumValue({
            account_relationship: reltype ? type_arr : result?.account_relationship?.filter((val) => val?.value !== "Customer"),
            bank_routing_type: result?.bank_routing_type,
            bank_account_type: result?.bank_account_type,
            tax_code: result?.tax_code,
            rera_type: result?.rera_type,
            company_registration: result?.company_registration
        })
    }
    //get account details
    const getAccountDetails = () => {
        setFetch(true)
        client.query({
            query: GET_ACCOUNT_DETAILS,
            fetchPolicy: 'network-only',
            variables: {
                id: acc_id
            },
        }).then((response) => {
            let result = returnEditPayloadAccount(response?.data?.contact_account?.[0], acc_id)
            setData(result)
            setFetch(false)
        }).catch((err) => {
            console.log(err)
            setFetch(false)
        })
    }

    const getAccount = () => {
        if (acc_id) {
            getAccountDetails()
        } else {
            setData({ ...data, company: state, currency: state?.currency, relation_ship: { label: reltype, value: reltype } })
        }
        getEnum()
    }
    //onclose
    const goBack = () => {
        history.goBack(-1)
    }
    //load options
    const loadOptions = async (search = "", array, type) => {

        setLoading(type);
        let result, query, offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        }
        else {
            offset = array?.length;
        }

        let companyID = data?.company?.value;

        switch (type) {
            case 'tax_group':
                if (!companyID) {
                    setLoading(null);
                    return { options: [] }
                }
                query = GET_VAT_GROUP(offset, 10, search, companyID).loc.source.body;
                result = await networkCallBack(query);
                return {
                    options: [...result?.vat_group_master],
                    hasMore: (array?.length + result?.vat_group_master?.length) < result?.count[0]?.count
                }
            case 'currency':
                query = GET_CURRENCY(offset, 10, search).loc.source.body;
                result = await networkCallBack(query);
                return {
                    options: [...result?.currency_master],
                    hasMore: (array?.length + result?.currency_master?.length) < result?.count[0]?.count
                }
            case "country":
                query = GET_COUNTRY_MASTER_DROPDOWN(offset, 10, search, { isActive: true }).loc.source.body;
                result = await networkCallBack(query);
                return {
                    options: [...result?.country_master],
                    hasMore: (array?.length + result?.country_master?.length) < result?.count[0]?.count
                }
            default:
                return { options: [] }
        }
    }
    const networkCallBack = async (query, variable = {}) => {
        let payload = {
            query,
            variable: variable
        };

        const options = await NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let main = response.data.data;
                setLoading(null);
                return main
            })
            .catch((error) => {
                setLoading(null);
                return null
            });

        return options
    }
    //validate 
    const validateAccount = () => {
        let isValid = true;
        let error = data.error;
        if (data?.acc_name?.length === 0) {
            isValid = false;
            error.acc_name = "Account Name  is Required";
        }
        if (data?.relation_ship?.label === null) {
            isValid = false;
            error.relation_ship = "Relationship  is Required";
        }
        if (data?.contact?.length === 0) {
            isValid = false;
            error.contact = "Contact is Required";
        }

        setData({ ...data, error });
        return isValid;
    }
    //onsubmit
    const createAccount = () => {
        setDisable(true)
        const payload = returnPayloadAccount(data)


        NetworkCall(
            `${config.api_url}/account/upsert`,
            NetWorkCallMethods.post,
            payload,
            true,
            true,
            false
        ).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: `Account Successfully ${acc_id ? "Updated" : "Created"}`,
            });
            setDisable(false)
            history.push({
                pathname: state?.type?.length > 0 ? typeSelect[state?.type] : Routes.accounts,
                state: {
                    ...data?.company,
                    currency: data?.currency
                }
            })
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
            });
            setDisable(false)
        })

    }

    const checkSave = () => {
        if (data?.acc_id) {
            if (permission.update) {
                next()
            } else {
                alert.setSnack({
                    ...alert, open: true, msg: "Access denied. Contact your administrator.",
                    severity: AlertProps.severity.error,
                })
            }
        } else if (permission.create) {
            next()
        } else {
            alert.setSnack({
                ...alert, open: true, msg: "Access denied. Contact your administrator.",
                severity: AlertProps.severity.error,
            })
        }
    }

    //goNext
    const next = () => {
        if (value === 1) {
            if (validateAccount()) {
                setValue(2)
            } else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Please fill all mandatory field",
                });
            }
        } else {
            if (isEmptyObject(data?.proofFile)) {
                createAccount()
            } else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Please fill all Proof field",
                });
            }

        }

    }
    //goPreviuos
    const previous = () => {
        setValue(1)
    }

    const render = () => {
        return <>
            <Subheader
                title={`${acc_id ? "Update" : "Create New"} Account`}
                goBack={goBack}
                previousAndNextBtn={true}
                previousBtn={previous}
                nextBtn={checkSave}
                previousBtnDisabled={value <= 1 && true}
                nextBtnDisabled={value === 2 ? true : false || disable}
            />
            {
                fetch ?
                    <LoadingSection bottom={"45vh"} message="Fetching Data..." />
                    :
                    <Box>
                        <PropertyTabs value={value} padding
                            tab1={(
                                <Box className={classes.root}>
                                    {/*profile section */}
                                    <Profile updateState={updateState} data={data} enumValue={enumValue} loadOptions={loadOptions} loading={loading} setData={setData} reltype={reltype} />
                                    {/*address section */}
                                    <Address mapLoad={acc_id ? false : true} updateState={updateState} setData={setData} data={data} loadOptions={loadOptions} loading={loading} />
                                    {/*contact section */}
                                    <Contact updateState={updateState} data={data} loadOptions={loadOptions} loading={loading} />
                                    {/*bank section */}
                                    <BankDetails updateState={updateState} data={data} enumValue={enumValue} loadOptions={loadOptions} loading={loading} />
                                </Box>
                            )}
                            tab2={(
                                <Box className={classes.root}>
                                    {/*proof*/}
                                    < Proof data={data?.proofFile} update={(data) => updateState("proofFile", data)} />
                                </Box>
                            )}
                            tabTittle={tabTittle}
                            showBtns={true}
                            isSaveDisable={value === 2 ? false : true || disable === true ? true : false}
                            saveBtnText={acc_id ? "Update" : "Create"}
                            goBack={goBack}
                            save={checkSave}
                        />
                    </Box>
            }
        </>
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>

    )
}