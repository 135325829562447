import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import {
  CardDetails,
  GraphComponent,
} from "../propertymanagerdashboard/components";
import { dashboardStyles } from "./style";
import { RevenueCard, GraphComponentRevenue } from "./component";
import {
  top10PropertyHead,
  top10PropertyPath,
  top10AgreeMentPath,
  top10AgreeMentHead,
  top10AccountPath,
  top10AccountHead,
  NetWorkCallMethods,
  AlertProps,
} from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { constractData1 } from "./common";
import { AlertContext } from "../../contexts";
import moment from "moment-timezone";
import { DialogBox } from "../../components";
import { NewLoader } from "../../components/newLoader";

export const RevenueDashboard = (props) => {
  // classes
  const classes = dashboardStyles(props);
  const [details, setDetails] = useState(null);
  // context
  const alert = React.useContext(AlertContext);

  const [zoom, setZoom] = useState(false);
  const [zoomGraph, setZoomGraph] = useState("");
  const [loader, setLoader] = useState(false);
  const zoomGraphFunction = (e) => {
    setZoomGraph(e);
    setZoom(true);
  };
  // close popup
  const handleCloseModule = () => {
    setZoom(false);
  };

  // getDetails
  const getDetails = () => {
    setLoader(true);
    let teamsId = props?.selectedTeams?.value;
    const payload = {
      company_id: props?.selectedcompany?.value ?? "",
      start_date: `${moment(props?.calandervalue?.value?.from_date).format(
        "YYYY-MM-DD"
      )} 00:00:00`,
      end_date: `${moment(props?.calandervalue?.value?.to_date).format(
        "YYYY-MM-DD"
      )} 23:59:59`,
    };
    if (teamsId !== "noteams") {
      payload["team"] = teamsId
      payload["team_all_property"] = true

    }
    NetworkCall(
      `${config.api_url}/dashboard/lead/revenue_dashboard  `,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const resut = constractData1(res?.data?.data ?? {});
        setDetails(resut);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  useEffect(() => {
    if (props?.selectedcompany?.label?.length > 0) {
      getDetails();
    }
    // eslint-disable-next-line
  }, [props?.selectedTeams, props?.calandervalue]);

  return (loader ?
    (
      <NewLoader minusHeight="100px" />
    ) :
    (<div className={classes.root}>
      {" "}
      {/* section 1 */}
      <Grid container spacing={2}>
        <Grid item sm={4} lg={1.5}>
          {/* property cards */}
          <Grid container spacing={2}>
            {details?.agreement?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} maxHeight={"111px"} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} lg={3}>
          {/* property graph */}
          <GraphComponent
            title={"Agreement Count by Status"}
            graphData={details?.agreement_count_by_status ?? []}
            isPie
            margin={"20px"}
            isZoom
            onZoomClick={zoomGraphFunction}
            maxWidthGraph={"244px"}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={1.5}>
          {/* unit card details */}
          <Grid container spacing={2}>
            {details?.agreement2?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} maxHeight={"123px"} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} lg={3}>
          {/* unit graph */}
          <GraphComponent
            title={"Agreements"}
            graphData={details?.active_agreement_by_agreement_type ?? []}
            isPie
            margin={"20px"}
            isZoom
            onZoomClick={zoomGraphFunction}
            maxWidthGraph={"288px"}
          />
        </Grid>
        {/* Agreement Revenue For 3 Years */}
        <Grid item xs={12} sm={12} lg={3}>
          <GraphComponentRevenue
            title={"Agreement Revenue For 3 Years"}
            type={"barline"}
            height={"310px"}
            LineKey={"line"}
            barKey={"bar"}
            data={details?.agreement_revenue_for_three_years ?? []}
            revenue
            margin={"20px 10px 0px 0px"}
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
      </Grid>
      {/* section2 */}
      <Grid container spacing={2} mt={0.3}>
        <Grid item xs={12} sm={6} lg={3}>
          {/* Agreement Revenue Month */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RevenueCard
                height={"138px"}
                sub={`${details?.currency ?? ""} ${details?.agreement_revenue ?? 0
                  }`}
                sub1={"Agreement Revenue Month"}
              />
            </Grid>
            <Grid item xs={12}>
              {/* Invoice Revenue */}
              <RevenueCard
                height={"138px"}
                sub={`${details?.currency ?? ""} ${details?.payment_total}`}
                sub1={"Invoice Revenue"}
              />
            </Grid>
            <Grid item xs={12}>
              {/* Invoice card */}
              <RevenueCard
                height={"138px"}
                isStacked
                data={details?.inVoice ?? []}
                marginTop={0}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Invoices Total By Invoice Type */}
        <Grid item xs={12} sm={6} lg={3}>
          <GraphComponent
            title={"Invoices Total By Invoice Type"}
            graphData={details?.invoices_total_by_invoice_type ?? []}
            isPie
            margin={"20px 0px 0px"}
            isZoom
            onZoomClick={zoomGraphFunction}
            graphParentPadding={"0px 0px 19px"}
          />
        </Grid>
        {/* card3 */}
        <Grid item xs={12} sm={6} lg={1.5}>
          <Grid container spacing={2}>
            {details?.account?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} maxHeight={"140px"} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {/* Invoices Revenue By Rental Component */}
        <Grid item xs={12} sm={6} lg={4.5}>
          <GraphComponent
            title={"Invoices Revenue By Rental Component"}
            iamgeData={details?.iamgeData ?? []}
            isImageList
            margin={"20px"}
            imageheight={"355px"}
            padding={"19px 19px 4px"}
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
      </Grid>
      {/* section 3 */}
      <Grid container spacing={2} mt={0.3}>
        <Grid item xs={12} sm={6} lg={3}>
          <Grid container spacing={2}>
            {details?.inVoice1?.map((item) => {
              return (
                <Grid item xs={12} sm={12} lg={12}>
                  <RevenueCard
                    height={"80px"}
                    isStacked
                    isCustomers
                    sub={item?.sub}
                    sub1={item?.sub1}
                    marginTop={0}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {/* Invoiced Revenue For 3 Years */}
        <Grid item xs={12} sm={6} lg={3}>
          <GraphComponentRevenue
            title={"Invoiced Revenue For 3 Years"}
            type={"barline"}
            height={"360px"}
            LineKey={"line"}
            barKey={"bar"}
            data={details?.invoiced_revenue_For_tree_years ?? []}
            revenue
            margin={"20px 0px 0px 0px"}
            cardGraphPadding={0}
            padding={"19px 19px 0px"}
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        {/* Expiring Agreements */}
        <Grid item xs={12} sm={6} lg={3}>
          <GraphComponentRevenue
            title={"Expiring Agreements"}
            type={"bar"}
            height={
              details?.expiring_agreements?.length > 0 ? "361px" : "434px"
            }
            XAxisKey={"name"}
            yAxisKey={"value"}
            data={details?.expiring_agreements ?? []}
            revenue
            margin={"20px 0px 0px 0px"}
            cardGraphPadding={0}
            padding={"19px 19px 0px"}
            isHorzontal
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        {/* Tenant Mix By Activity */}
        <Grid item xs={12} sm={6} lg={3}>
          <GraphComponent
            title={"Tenant Mix By Activity"}
            graphData={details?.Tenant ?? []}
            isPie
            margin={"20px"}
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
      </Grid>
      {/* section4 */}
      <Grid container spacing={2} mt={0.3}>
        {/* Top 10 Outstanding By Properties */}
        <Grid item xs={12} sm={12} lg={4}>
          <GraphComponent
            title={"Top 10 Outstanding By Properties"}
            tableData={details?.topproperties ?? []}
            heading={top10PropertyHead}
            path={top10PropertyPath}
            dataType={[
              { type: ["text"], name: "name" },
              { type: ["text"], name: "amount" },
            ]}
            tabelheight={"calc(100vh - 450px)"}
            isTable
            revenue
          />
        </Grid>
        {/* Top 10 Outstanding By Agreement */}
        <Grid item xs={12} sm={6} lg={4}>
          <GraphComponent
            title={"Top 10 Outstanding By Agreement"}
            tableData={details?.topagreement ?? []}
            heading={top10AgreeMentHead}
            path={top10AgreeMentPath}
            dataType={[
              { type: ["text"], name: "name" },
              { type: ["text"], name: "amount" },
            ]}
            tabelheight={"calc(100vh - 450px)"}
            isTable
            revenue
          />
        </Grid>
        {/* Top 10 Outstanding By Account */}
        <Grid item xs={12} sm={6} lg={4}>
          <GraphComponent
            title={"Top 10 Outstanding By Account"}
            tableData={details?.topaccount ?? []}
            heading={top10AccountHead}
            path={top10AccountPath}
            dataType={[
              { type: ["text"], name: "number" },
              { type: ["text"], name: "name" },
              { type: ["text"], name: "amount" },
            ]}
            tabelheight={"calc(100vh - 450px)"}
            isTable
            revenue
          />
        </Grid>
      </Grid>
      {/* section 5 */}
      <Grid container spacing={2} mt={0.3}>
        {/* Active Agreement By Unit Type */}
        <Grid item xs={12} sm={6} lg={3}>
          <GraphComponent
            title={"Active Agreement By Unit Type"}
            graphData={details?.active_agreement_by_unit_type ?? []}
            isPie
            margin={"20px"}
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        {/* Active Agreement By Activity */}
        <Grid item xs={12} sm={6} lg={3}>
          <GraphComponent
            title={"Active Agreement By Activity"}
            graphData={details?.Tenant ?? []}
            isPie
            margin={"20px"}
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        {/* Top 10 Properties With Highest Agreement */}
        <Grid item xs={12} sm={12} lg={6}>
          <GraphComponent
            title={"Top 10 Properties With Highest Agreement"}
            graphData={
              details?.top_ten_properties_with_highest_agreement ?? []
            }
            indexAxis={"y"}
            isBar
            margin={"20px"}
            isDatalabels
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
      </Grid>
      {/* zoom dialog for each graph */}
      <DialogBox
        isnotTitle
        maxWidth={"sm"}
        handleClose={handleCloseModule}
        open={zoom}
        component={
          <>
            {zoomGraph === "Agreement Count by Status" && (
              <GraphComponent
                title={"Agreement Count by Status"}
                graphData={details?.agreement_count_by_status ?? []}
                isPie
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
                margin={"40px"}
                divider={6}
                divider1={6}
              />
            )}
            {zoomGraph === "Agreements" && (
              <GraphComponent
                title={"Agreements"}
                graphData={details?.active_agreement_by_agreement_type ?? []}
                isPie
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
                margin={"40px"}
                divider={6}
                divider1={6}
              />
            )}
            {zoomGraph === "Agreement Revenue For 3 Years" && (
              <GraphComponentRevenue
                title={"Agreement Revenue For 3 Years"}
                type={"barline"}
                height={"310px"}
                LineKey={"line"}
                barKey={"bar"}
                data={details?.agreement_revenue_for_three_years ?? []}
                revenue
                cardGraphPadding={"24px 24px 47px"}
                margin={"40px"}
              />
            )}
            {zoomGraph === "Invoices Total By Invoice Type" && (
              <GraphComponent
                title={"Invoices Total By Invoice Type"}
                graphData={details?.invoices_total_by_invoice_type ?? []}
                isPie
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
                margin={"40px"}
                divider={6}
                divider1={6}
              />
            )}
            {zoomGraph === "Invoiced Revenue For 3 Years" && (
              <GraphComponentRevenue
                title={"Invoiced Revenue For 3 Years"}
                type={"barline"}
                height={"360px"}
                LineKey={"line"}
                barKey={"bar"}
                data={details?.invoiced_revenue_For_tree_years ?? []}
                revenue
                cardGraphPadding={"24px 24px 47px"}
                margin={"40px"}
              />
            )}
            {zoomGraph === "Expiring Agreements" && (
              <GraphComponentRevenue
                title={"Expiring Agreements"}
                type={"bar"}
                height={"357px"}
                XAxisKey={"name"}
                yAxisKey={"value"}
                data={details?.expiring_agreements ?? []}
                revenue
                isHorzontal
                cardGraphPadding={"24px 24px 47px"}
                margin={"40px"}
              />
            )}
            {zoomGraph === "Tenant Mix By Activity" && (
              <GraphComponent
                title={"Tenant Mix By Activity"}
                graphData={details?.Tenant ?? []}
                isPie
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
                margin={"40px"}
                divider={6}
                divider1={6}
              />
            )}
            {zoomGraph === "Active Agreement By Unit Type" && (
              <GraphComponent
                title={"Active Agreement By Unit Type"}
                graphData={details?.active_agreement_by_unit_type ?? []}
                isPie
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
                margin={"40px"}
                divider={6}
                divider1={6}
              />
            )}
            {zoomGraph === "Active Agreement By Activity" && (
              <GraphComponent
                title={"Active Agreement By Activity"}
                graphData={details?.Tenant ?? []}
                isPie
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
                margin={"40px"}
                divider={6}
                divider1={6}
              />
            )}
            {zoomGraph === "Top 10 Properties With Highest Agreement" && (
              <GraphComponent
                title={"Top 10 Properties With Highest Agreement"}
                graphData={
                  details?.top_ten_properties_with_highest_agreement ?? []
                }
                indexAxis={"y"}
                isBar
                isDatalabels
                padding={"24px 24px 47px"}
                margin={"40px"}
              />
            )}
            {zoomGraph === "Invoices Revenue By Rental Component" && (
              <GraphComponent
                title={"Invoices Revenue By Rental Component"}
                iamgeData={details?.iamgeData ?? []}
                isImageList
                margin={"20px"}
                imageheight={"355px"}
                padding={"19px 19px 4px"}
              />
            )}
          </>
        }
      />
    </div>)
  );
};
