import { Box, Grid, IconButton } from "@mui/material";
import { withNamespaces } from "react-i18next";
import FilterIcon from "../../assets/filterIcon";
import { SearchFilter } from "../../components";
import { useTableStyles } from "./style";

const SearchFilterSec = ({ t, handleSearch = () => false, searchText = "", openfilter = () => false }) => {
    // style
    const classes = useTableStyles()

    return (
        <Box>
            <Grid container className={classes.girdItem} alignItems="center" >
                <Grid item md={4}>
                    <SearchFilter label={false} placeholder={t("searchNewRequest")} handleChange={(value) => handleSearch(value)} value={searchText} />
                </Grid>
                <Grid item md={8} alignItems="flex-end">
                    <IconButton size="small" className={classes.filterIconBox} onClick={() => openfilter()} >
                        <FilterIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    )
}
export default withNamespaces("inspection")(SearchFilterSec)

