import { ActivePropertyIcon } from "../../assets";
import { startAndEndOfMonth, startAndEndOfWeek } from "../../utils";

export const Lease_Manager = {
  active_leads: 10,
  active_opportunities: 10,
  active_reservations: 10,
  active_quotations: 10,
  won_quotations: 10,
  onboarded_residents_for_this_month: 10,
  opportunity_revenue: 300,
  currency: "INR",
  lead_count_by_status: [
    {
      name: `Vacant`,
      count: 10,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    },
  ],
  opportunity_count_by_lead_source: [
    {
      name: `Vacant`,
      count: 10,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    },
  ],
  opportunity_count_by_status: {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
        borderWidth: 1,
        //stack: 1,
        data: [65, 59, 80, 81, 56, 55, 40],
      },

      {
        label: "My second dataset",
        backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
        borderWidth: 1,
        //stack: 1,
        data: [45, 79, 50, 41, 16, 85, 20],
      },
    ],
  },
  opportunity_reveune_by_status: {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
        borderWidth: 1,
        //stack: 1,
        data: [65, 59, 80, 81, 56, 55, 40],
      },

      {
        label: "My second dataset",
        backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
        borderWidth: 1,
        //stack: 1,
        data: [45, 79, 50, 41, 16, 85, 20],
      },
    ],
  },
  opportunity_count_by_period: {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
        borderWidth: 1,
        //stack: 1,
        data: [65, 59, 80, 81, 56, 55, 40],
      },
    ],
  },
  reservation_and_Booking_status: [
    {
      name: `Vacant`,
      count: 10,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    },
  ],
  inspection_request_status: [
    {
      name: `Vacant`,
      count: 10,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    },
  ],
  team_performance: [
    {
      Image: "https://mui.com/static/images/avatar/2.jpg",
      rep: "test",
      Amount: "12000 k",
      Target: "12000 l",
    },
  ],
  my_open_leads: [
    {
      Lead: "test",
      Email: "test",
      Phone: "test",
      Company: "test",
    },
  ],
  my_tasks: [
    {
      to_do: "Dashboard",
      Lead: "test",
      Email: "test",
      Phone: "test",
      Company: "test",
    },
  ],
};

export const won_opportunities = {
  won: 1000,
  target: 1200,
};

export const revenue_dashboard = {
  new_agreement: 10,
  live_agreement: 10,
  agreement_expiring_this_month: 10,
  agreement_revenue_month: 12,
  invoice_revenue: 12,
  declined_agreement: 10,
  under_onboarding: 10,
  overdue_accounts: 10,
  overdue_total: 10,
  total_outstanding_for_this_month: 10,
  agreement_count_by_status: [
    {
      name: `Vacant`,
      count: 10,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    },
  ],
  new_agreements: [
    {
      name: `Vacant`,
      count: 10,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    },
  ],
  agreement_revenue_for_three_years: [
    {
      name: "Jan",
      sale: 1000,
      target: 2400,
    },
    {
      name: "Feb",
      sale: 3000,
      target: 1398,
    },
  ],
  invoices_total_by_invoice_type: [
    {
      name: `Vacant`,
      count: 10,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    },
  ],
  invoices_revenue_by_rental_component: [
    {
      name: "Rubix",
      meter: "2M",
      color: `#${Math.random().toString(16).substring(2, 8)}`,
    },
    {
      name: "Vetrox",
      meter: "2M",
      color: `#${Math.random().toString(16).substring(2, 8)}`,
    },
  ],
  posted_invoice_revenue_month: 10,
  planned_invoices_for_this_month: 10,
  customers: [
    {
      sub: "03",
      sub1: "Active Customers",
    },
    {
      sub: "52",
      sub1: "New Customers for this month",
    },
    {
      sub: "03",
      sub1: "Agreement expiring this quarter",
    },
    {
      sub: "52",
      sub1: "Agreement expiring this month",
    },
  ],
  invoiced_revenue_For_tree_years: [
    {
      name: "Jan",
      sales: 1000,
      target: 2400,
    },
    {
      name: "Feb",
      sales: 3000,
      target: 1398,
    },
  ],
  expiring_agreements: [
    {
      name: "PageA",
      value: 1000,
    },
    {
      name: "PageB",
      value: 3000,
    },
  ],
  tenant_mix_by_activity: [
    {
      name: `Vacant`,
      count: 10,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    },
  ],
  top_ten_properties: [
    {
      name: `Rubix Apartment`,
      amount: `INR 23,1000`,
    },
  ],
  top_ten_agreement: [
    {
      name: `Rubix Apartment`,
      amount: `INR 23,1000`,
    },
  ],
  top_ten_account: [
    {
      number: 1,
      name: `Rubix Apartment`,
      amount: `INR 23,1000`,
    },
  ],
  active_agreement_by_agreement_type: [
    {
      name: `Vacant`,
      count: 10,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    },
  ],
  active_agreement_by_activity: [
    {
      name: `Vacant`,
      count: 10,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    },
  ],
  top_ten_properties_with_highest_agreement: {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
        borderWidth: 1,
        //stack: 1,
        data: [65, 59, 80, 81, 56, 55, 40],
      },
    ],
  },
};

export const constractData = (data) => {
  let leads = [
    {
      count: data?.active_leads ?? 0,
      image: <ActivePropertyIcon />,
      sub: "Active Leads",
    },
    {
      count: data?.active_opportunities ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: "Active Opportunities",
      image_type: true,
    },
    {
      count: data?.active_reservations ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: "Active Reservations",
      image_type: true,
    },
  ];
  let qutation = [
    {
      count: data?.active_quotations ?? 0,
      image: <ActivePropertyIcon />,
      sub: "Active Quotations",
    },
    {
      count: data?.won_quotations ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: "Won Quotations",
      image_type: true,
    },
    {
      count: data?.onboarded_residents_for_this_month ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: "Onboarded residents for this month",
      image_type: true,
    },
  ];
  let lead_count_by_status = data?.lead_count_by_status;
  let opportunity_count_by_lead_source = data?.opportunity_count_by_lead_source;
  let opportunity_count_by_status = data?.opportunity_count_by_status ?? {};
  let opportunity_reveune_by_status = data?.opportunity_reveune_by_status;
  let opportunity_count_by_period = data?.opportunity_count_by_period;
  let reservation_and_Booking_status = data?.reservation_and_Booking_status;
  let inspection_request_status = data?.inspection_request_status;
  let team_performance = data?.team_performance;
  let my_open_leads = data?.my_open_leads;
  let my_tasks = data?.my_tasks;
  let currency = data?.currency;
  let revenue = data?.opportunity_revenue;
  const final = {
    leads,
    qutation,
    lead_count_by_status,
    opportunity_count_by_lead_source,
    opportunity_count_by_status,
    opportunity_reveune_by_status,
    opportunity_count_by_period,
    reservation_and_Booking_status,
    inspection_request_status,
    team_performance,
    my_open_leads,
    my_tasks,
    revenue,
    currency,
  };
  return final;
};
export const constractOppertunityWon = (data) => {
  const final = {
    labels: [""],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
        barThickness: 50,
        borderWidth: 1,
        //stack: 1,
        data: [65, 59, 80, 81, 56, 55, 40],
      },

      {
        label: "My second dataset",
        backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
        borderWidth: 1,
        barThickness: 50,
        data: [45, 79, 50, 41, 16, 85, 20],
      },
    ],
  };
  return final;
};

export const getMonth = [
  {
    label: "Today",
    value: {
      startDate: new Date(),
      endDate: new Date(),
    },
  },
  {
    label: "YesterDay",
    value: {
      startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      endDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
    },
  },
  {
    label: "This Week",
    value: startAndEndOfWeek(new Date()),
  },
  {
    label: "This Month",
    value: startAndEndOfMonth(),
  },
];

export const constractData1 = (data) => {
  let agreement = [
    {
      count: data?.new_agreement ?? 0,
      image: <ActivePropertyIcon />,
      sub: "New Agreement",
    },
    {
      count: data?.live_agreement ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: "Live Agreements",
      image_type: true,
    },
    {
      count: data?.agreement_expiring_this_month ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: "Agreement Expiring this month",
      image_type: true,
    },
  ];
  const agreement2 = [
    {
      count: 0,
      image: <ActivePropertyIcon />,
      sub: "xxxx",
    },
    {
      count: 0,
      image: "/images/propertdashboard/vacant.png",
      sub: "Declined Agreement",
      image_type: true,
    },
    {
      count: 0,
      image: "/images/propertdashboard/occupied.png",
      sub: "Under Onboarding",
      image_type: true,
    },
  ];
  let agreement_count_by_status = data?.agreement_count_by_status ?? [];
  let active_agreement_by_agreement_type =
    data?.active_agreement_by_agreement_type ?? [];
  let agreement_revenue_for_three_years =
    data?.agreement_revenue_for_three_years?.map((x) => {
      return {
        name: x?.name,
        // uv: 0,
        bar: x?.sum,
      };
    });
  let invoices_total_by_invoice_type =
    data?.invoices_total_by_invoice_type ?? [];
  let account = [
    {
      count: data?.overdue_accounts ?? 0,
      image: <ActivePropertyIcon />,
      sub: "Overdue Accounts",
    },
    {
      count: data?.overdue_total ?? 0,
      image: "/images/propertdashboard/vacant.png",
      sub: "Overdue Total",
      image_type: true,
    },
    {
      count: data?.total_outstanding_for_this_month ?? 0,
      image: "/images/propertdashboard/occupied.png",
      sub: "Total Outstanding for this month",
      image_type: true,
    },
  ];
  const iamgeData = Array.from(Array(20).keys())?.map((v, i) => {
    return {
      name: `Rubix${i}`,
      meter: "2M",
      color: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  const inVoice1 = [
    {
      sub: data?.active_customers ?? 0,
      sub1: "Active Customers",
      icon: <ActivePropertyIcon />,
    },
    {
      sub: data?.new_customers_for_this_month ?? 0,
      sub1: "New Customers for this month",
      icon: <ActivePropertyIcon />,
    },
  ];
  let invoiced_revenue_For_tree_years =
    data?.invoiced_revenue_For_tree_years?.map((x) => {
      return {
        name: x?.name,
        // uv: 0,
        bar: x?.sum,
      };
    });
  let expiring_agreements = data?.expiring_agreements?.map((x) => {
    return {
      name: x?.name,
      value: x?.count,
    };
  });
  let Tenant = Array.from(Array(9).keys())?.map((v, i) => {
    return {
      name: `Vacant${i * 1}` ?? "",
      count: 10 ?? "",
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  const topproperties = Array.from(Array(9).keys())?.map((v, i) => {
    return {
      name: `Rubix Apartment${i * 1}` ?? "",
      amount: `INR${i * 100}` ?? "",
    };
  });
  const topagreement = data?.top_agreements?.map((item) => {
    return {
      name: `${item?.agreement_no}` ?? "",
      amount: `${data?.currency}-${item?.total ?? 0}` ?? "",
    };
  });
  const topaccount = data?.top_invoiced_accounts?.map((x) => {
    return {
      number: `${x?.account_no}`,
      name: `${x?.name}`,
      amount: `${data?.currency}-${x?.total}`,
    };
  });
  const inVoice = [
    {
      sub: data?.invoice_total ?? 0,
      sub1: "Posted Invoice This Month",
      icon: <ActivePropertyIcon />,
    },
    {
      sub: data?.quotation_payment_schedule_count ?? 0,
      sub1: "Planned Invoices for this Month",
      icon: <ActivePropertyIcon />,
    },
  ];
  let top_ten_properties_with_highest_agreement =
    data?.top_ten_properties_with_highest_agreement ?? [];
  const final = {
    agreement,
    agreement_count_by_status,
    agreement2,
    active_agreement_by_agreement_type,
    agreement_revenue_for_three_years,
    invoices_total_by_invoice_type,
    account,
    iamgeData,
    inVoice1,
    invoiced_revenue_For_tree_years,
    expiring_agreements,
    Tenant,
    topproperties,
    topagreement,
    topaccount,
    top_ten_properties_with_highest_agreement,
    inVoice,
    agreement_revenue: data?.agreement_revenue ?? 0,
    currency: data?.currency ?? 0,
    invoice_total: data?.invoice_total ?? 0,
    payment_total: data?.payment_total ?? 0,
    active_agreement_by_unit_type: data?.active_agreement_by_unit_type ?? [],
  };
  return final;
};
