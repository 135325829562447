import { Avatar, Box, Button, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import InspectionTemplateImg from '../../assets/inspectionTemplate'
import { useStyles } from "./style";

export const InspectionTemplateList = ({templateList={},handleEdit=()=>false}) => {
    //Classes
    const classes = useStyles()
    return (
        <Box>
            <Box m={2}>
                <Stack direction={"row"} spacing={2}>
                    {/* <img src={InspectionTemplateImg} alt="Inspection Template Image" /> */}
                    <InspectionTemplateImg />
                    <Stack>
                        <Typography className={classes.templateName}>{templateList.name}</Typography>
                        <Typography className={classes.templateItem}>{templateList?.inspection_template_items?.length} Items</Typography>
                    </Stack>
                </Stack>
                <Typography className={classes.itemList}>item list</Typography>
            </Box>
            <Box className={classes.list} m={2}>
                {
                    templateList?.inspection_template_items?.filter((x)=>{return x.item_count>0}).map(val => {
                        return (
                            <>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={2}>
                                    <Stack spacing={3} direction={"row"} alignItems={"center"}>
                                        <Avatar variant="square" className={classes.avatar}> <InspectionTemplateImg /> </Avatar>
                                        <Typography className={classes.inspectionName}> {val.name} </Typography>
                                    </Stack>
                                    <Typography className={classes.inspectionQty}>{val.item_count} QTY</Typography>
                                </Stack>
                                <Divider />
                            </>
                        )
                    })
                }
            </Box>
            <Box m={2}><Button variant="outlined" className={classes.editbtn} onClick={()=>{handleEdit("edit",templateList)}}>Edit</Button></Box>
        </Box>
    )
}