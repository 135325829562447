import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment";
import React from "react";
// import Slider from "react-slick";
import { Slider } from "../../components";
import { ApprovalPopupStyle } from "./style";

export const ApprovalPopup = ({ data = {}, handleRemarksPopup = () => false }) => {
    const classes = ApprovalPopupStyle()

    return (
        <Box>
            <Box className={classes.innerContent}>
                <Grid container>
                    <Grid item md={12}>
                        <Slider uploadImage={false}
                            assets={
                                data?.url?.map((e) => {
                                    return (
                                        {
                                            url: e?.src,
                                            type: e?.type,
                                            name: e?.name,
                                            asset_type: data?.url?.[0]?.type === "application/pdf" ? 4 : 1
                                        }
                                    )
                                })} />
                    </Grid>
                    <Grid item md={12}>
                        <Box className={classes.bottomContent} ml={4} mt={2} mb={2} mr={4}>
                            <Grid container p={2} spacing={2}>
                                <Grid item md={4}>
                                    <Stack spacing={1}>
                                        <Typography className={classes.title}>Proof</Typography>
                                        <Typography className={classes.content}>{data?.identification_master?.type}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item md={4}>
                                    <Stack spacing={1}>
                                        <Typography className={classes.title}>Name As In Proof</Typography>
                                        <Typography className={classes.content}>{data?.name}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item md={4}>
                                    <Stack spacing={1}>
                                        <Typography className={classes.title}>Id Proof Number</Typography>
                                        <Typography className={classes.content}>{data?.proof_no}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item md={4}>
                                    <Stack spacing={1}>
                                        <Typography className={classes.title}>Vaild From</Typography>
                                        <Typography className={classes.content}> {moment(data?.valid_from).format("DD MMM YY")} </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item md={4}>
                                    <Stack spacing={1}>
                                        <Typography className={classes.title}>Vaild To</Typography>
                                        <Typography className={classes.content}>{moment(data?.valid_to).format("DD MMM YY")} </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item md={4}>
                                    <Stack spacing={1}>
                                        <Typography className={classes.title}>Issuing Country</Typography>
                                        <Typography className={classes.content}>{data?.country_master?.country_name}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {
                data?.status === "Yet to be Verified" ?
                    <Box p={2}>
                        <Stack direction={"row"} spacing={2}>
                            <Button fullWidth variant="contained" className={classes.decline} onClick={() => handleRemarksPopup("Decline Proof", "popup")}>Decline</Button>
                            <Button fullWidth variant="contained" className={classes.approve} onClick={() => handleRemarksPopup("Approve Proof", "popup")}>Approve</Button>
                        </Stack>
                    </Box>
                    :
                    <Box p={2}>
                        <Stack spacing={1}>
                            <Typography className={classes[`${data?.status}`]}>{data?.status}</Typography>
                            <Stack direction={"row"} spacing={1} textAlign={"center"}>
                                <Typography className={classes.remarkTitle}>Remarks:</Typography>
                                <Typography className={classes.remarkContent}>{data?.remarks}</Typography>
                            </Stack>
                        </Stack>
                    </Box>
            }
        </Box>
    )
}