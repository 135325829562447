import { Box, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogBox, FilterGenerator, SearchFilter, Subheader, TableWithPagination } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { accessCheckRender, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, LeadNewheading, LeadNewpath, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { AddLead } from "./createlead";
import { LeadStylesParent } from "./style";

export const LeadNew = (props) => {
  const classes = LeadStylesParent();
  const history = useHistory();
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext)
  const [list, setList] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [selectedTeams, setSelectedTeams] = React.useState('')
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)

  const [isEdit, setEdit] = React.useState({
    id: null,
    bool: false
  })
  const [filterData, setFilterData] = React.useState({
    status: "",
    lead_source: [],
  });
  const [drawer, setDrawer] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [enumValue, setEnumValue] = React.useState({
    source: [],
  });
  const [permission, setPermission] = React.useState({})
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
          setCompanyList(company?.list)
          setSelectedCompany(company?.selected)
          if (company?.list?.length > 0) {
            // getDetails(company?.selected);
            getEnum();
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);


  const GetList = (company, team, searchText, offSet, limit, source, status) => {
    setSearchText(searchText);
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    let clientID = localStorage.getItem(LocalStorageKeys.clinetID);
    const payload = {
      offset: offSet,
      company_id: company?.value,
      limit: limit,
      client: clientID,
      is_active: true,
      search: searchText ?? undefined,
      leadSource: source?.map((x) => x) ?? undefined,
      status: status
    }
    if (team?.value !== "noteams") {
      payload["team"] = team?.value
    }
    NetworkCall(
      `${config.api_url}/lead_proxy/get_proxy_lead_details`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        let list = res?.data?.data?.proxy_lead?.map((val) => {
          return {
            id: val?.id ?? "",
            LeadNumber: val?.reference_no ?? "",
            LeadName: val?.lead_name ?? "",
            MobileNumber: `${val?.mobile_country_code ?? ""}  ${val?.mobile_no ?? ""
              }`,
            Email: val?.email ?? "-",
            Source: val?.lead_source ?? "-",
            LeadOwner: `${val?.lead_owner?.first_name ?? ""} ${val?.lead_owner?.last_name ?? ""
              }`,
            Status: val?.status,
            description: val?.description ?? "-",
            date: val?.created_at ?? "-",
            purpose: val?.purpose ?? "-",
            statusDate: val?.updated_at ?? "-",
            icon: "more_2",
          };
        });
        setList({
          count: res?.data?.data?.count ?? 0,
          list: list ?? [],
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => { });
  };
  // pagination function
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    GetList(
      selectedCompany,
      selectedTeams,
      searchText,
      offset,
      limit,
      filterData?.lead_source?.map((x) => x?.value),
      filterData?.status
    );
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    GetList(
      selectedCompany,
      selectedTeams,
      searchText,
      0,
      value,
      filterData?.lead_source?.map((x) => x?.value),
      filterData?.status
    );
  };
  // dialog open
  const OpenDrawer = () => {
    setDrawer(!drawer);
  };
  // icon click function
  const handleIcon = (type, data, status) => {
    if (type === "edit") {
      setEdit({
        bool: true,
        id: data?.id
      })
      setDialog(true)
      // view click function
    } else if (type === "view") {
      history.push({
        pathname: Routes.leadview,
        state: {
          main: {
            company: selectedCompany,
            id: data?.id,
            selectedCompany:selectedCompany
          },
        },
      });
    }
    // delete click function
    else if (type === "delete") {
      // deleteLost(data);
    }
  };
  // close dialog and clear all states
  const closeDrawer = () => {
    setDialog(!dialog);
    setEdit({
      id: null,
      bool: false
    })
  };
  // add laed function
  const addlead = () => {
    setDialog(!dialog);
  };
  // filter apply function
  const onApplyFilter = (value) => {

    setFilterData(value);
    GetList(
      selectedCompany,
      selectedTeams,
      searchText,
      0,
      limit,
      value?.lead_source?.map((x) => x?.value) ?? [],
      value?.status ?? []
    );
  };
  // get details of tabel data
  const getDetails = (team) => {
    GetList(selectedCompany, team, searchText, 0, limit, filterData?.lead_source?.map((x) => x?.value), filterData?.status);
  };
  // get enum
  const getEnum = async () => {
    const result = await enumSelect([enum_types.lead_source]);
    setEnumValue({
      source: result?.lead_source,
    });
  };
  // Function to change property
  const handleTeamsChange = (team) => {
    setSelectedTeams(team)
    getDetails(team)

  }
  const render = () => {
    return <>
      {/* sub nav bar */}
      <Subheader hideBackButton={true}
        title={`Lead(${list?.count ?? 0})`}
        select options={companyList}
        value={selectedCompany}
        placeholder={"Search By Lead Name"}
        goBack={() => {
          history.push(Routes.home);
        }}
        onchange={(e) => {
          setSelectedCompany(e);
        }}
        selectTeamsOptions
        handleTeamsChange={handleTeamsChange}
        companyId={selectedCompany?.value}
        moduleId={moduleId}
        selectedTeams={selectedTeams}
      />
      {/* body contant */}
      <div className={classes.root}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={4}>
            <Box>
              {/* search box */}
              <SearchFilter value={searchText} placeholder="Search"
                handleChange={(value) => GetList(selectedCompany, selectedTeams, value, 0, 10, filterData?.lead_source?.map((x) => x?.value), filterData?.status)}
              />
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box display={"flex"} sx={{ float: "right" }}>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
              >
                <Box>
                  <IconButton size="small" className={classes.img} onClick={OpenDrawer} >
                    <img src="/images/filter.svg" alt="filter" />
                  </IconButton>
                </Box>
                {permission?.create && <Box>
                  {/* add agent button */}
                  <Button variant="contained" className={classes.button} onClick={addlead}>
                    Create Lead
                  </Button>
                </Box>}
              </Stack>
            </Box>
          </Grid>

          {/* Main tableData */}
          <Grid item xs={12} className={classes.tableMain}>
            <TableWithPagination
              heading={LeadNewheading}
              rows={list?.list ?? ""}
              path={LeadNewpath}
              showpagination={true}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              handleIcon={handleIcon}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              totalRowsCount={list?.count}
              page={page}
              limit={limit}
              tableType="no-side"
              dataType={[
                { type: ["more_2"], icon: "icon" },
                { type: ["text"], name: "LeadNumber" },
                { type: ["date"], name: "date" },
                { type: ["quill"], name: "description" },
                { type: ["text"], name: "LeadName" },
                { type: ["text"], name: "Email" },
                { type: ["text"], name: "MobileNumber" },
                { type: ["text"], name: "purpose" },
                { type: ["text"], name: "type" },
                { type: ["text"], name: "Source" },
                { type: ["text"], name: "LeadOwner" },
                { type: ["status"], name: "Status" },
              ]}
              height={"calc(100vh - 315px)"}
              view={permission?.read}
              edit={permission?.update}
              delete={permission?.delete} />
          </Grid>
          {/* end Main tableData */}
        </Grid>

        {/* filter drawer */}
        {drawer && (
          <FilterGenerator
            open={drawer}
            onClose={() => setDrawer(false)}
            components={[
              {
                component: "select",
                value: filterData?.lead_source,
                options: enumValue?.source ?? [],
                isMulti: true,
                label: "Lead Source",
                state_name: "lead_source",
              },
              {
                component: "toggleButton",
                value: filterData?.status,
                options: [
                  { label: "In Progress", value: "In Progress" },
                  { label: "Converted", value: "Converted" },
                  { label: "Lost Lead", value: "Lost Lead" },
                ],
                isMulti: false,
                state_name: "status",
                label: "Status",
              },
            ]}
            onApply={(value) => onApplyFilter(value)}
          />
        )}
      </div>
      {/* create lead */}
      <DialogBox
        id={"id_123"}
        open={dialog}
        onClose={closeDrawer}
        header={`${isEdit?.bool ? "Edit" : "Create"} lead`}
        maxWidth="md"
        component={
          <AddLead
            permission={permission}
            isEdit={isEdit}
            parentID={"id_123"}
            closeDrawer={closeDrawer}
            selectedCompany={selectedCompany?.value}
            getDetails={getDetails}
            company={selectedCompany}
          />
        }
      />
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>
  );
};
