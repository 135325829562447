import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { componantsStyles } from "./styles";
import {timeZoneConverter} from "../../../utils";

export const QuotationVaersionCard = ({ data }) => {
    const classes = componantsStyles()
    return (
        <div>
            <Box display="flex" p={1.5} alignItems="center" className={classes.cardRoot}>
                <Box flexGrow={1}>
                    <Stack direction="row">
                        <Typography className={classes.quottNo}>{data?.quott_no}</Typography>
                        {data?.active && <Typography className={classes.active}>Active</Typography>
                        }

                    </Stack>
                    <Typography className={classes.quottCardsub}>Generated on {timeZoneConverter(data?.startDate)}</Typography>
                </Box>
                <Box>
                    <IconButton className={classes.arrowIcon} size="small">
                        <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
                    </IconButton>
                </Box>

            </Box>

        </div>
    )
}