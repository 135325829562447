/* eslint-disable no-unused-vars */
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  DrawerComponent,
  FilterComponent,
  SearchFilter, Subheader, TableWithPagination,FilterGenerator
} from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { Routes } from "../../router/routes";
import { accessCheckRender, getCompanyOption, getRoutePermissionNew, LocalStorageKeys ,NetWorkCallMethods , AlertProps , enumSelect , enum_types} from "../../utils";
import { Leadheading, Leadpath } from "../../utils/tableData";
import { CreateLeadContext } from "./leadcontext";
import { useStyles } from "./styles";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import {LIST_COMPANY_CONTACT , GET_LEAD_STATUS_MASTER, LIST_COMPANY_OWNER} from '../../graphql/queries'
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";


export const Leads = (props) => {
  const {
    leadList,
    getListData,
    openFilter,
    OpenDrawer,
    searchdata,
    total,
    clearAll,
    companySelectFunction,
    selectedCompany,
    setSelectedCompany
  } = React.useContext(CreateLeadContext);
console.log("selectedCompany",selectedCompany)
  const auth = React.useContext(AuthContext);
  const history = useHistory();
  const [listTab, setTab] = React.useState("");
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const { state } = useLocation();
  const [companyList, setCompanyList] = React.useState([]);
  const backdrop = React.useContext(BackdropContext)
  const alert = React.useContext(AlertContext)
  const [permission, setPermission] = React.useState({})
  const [selectedTeams, setSelectedTeams] = React.useState('')
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
  const clientId = localStorage.getItem(LocalStorageKeys.clinetID)

  const [status , setStatus]=React.useState({
    value:[],
    enum:[]
  })
  const [filterData , setFilterData]=React.useState({
    periorty:"",
    status:"",
    name:"",
    lead:""
  })
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        if (localStorage.getItem("authToken")) {
          let company = getCompanyOption(backdrop, auth, alert)
          if (company) {
            setCompanyList(company?.list)
            getStatusMaster()
            if (state?.main?.leadNew) {
              setSelectedCompany(state?.main?.selectedCompany ?? company?.selected)
            }
            else {
              setSelectedCompany(company?.selected)
            }
          }
        } else {
          history.push(Routes.login);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);


  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getListData(offset, limit, listTab, "key", selectedCompany, selectedTeams);
  };

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getListData(0, value, listTab, "key", selectedCompany, selectedTeams);
  };

  const handleIcon = (type, data) => {
    if (type === "edit") {

      history.push({
        pathname: Routes.createOpportunity,
        state: {
          id: data?.id,
          company: {
            value: data?.company_id,
            label: data?.company_name
          }
        }
      });
    } else {
      history.push({
        pathname:Routes.leadsDetails,
        state:{
          company:selectedCompany,
          id:data?.id
        }
      });
    }
  };


  const goCreateOppertunity = () => {
    history.push({
      pathname: Routes.createOpportunity,
      state: selectedCompany
    })
  }
  // Function to change property
  const handleTeamsChange = (team) => {
    setSelectedTeams(team)
    getListData(0, 10, listTab, "key", selectedCompany, team);

  }
  // get status master
  const getStatusMaster=()=>{
    const payload = {
      query: GET_LEAD_STATUS_MASTER,
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        getEnum(response?.data?.data?.lead_status_master)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  }

  const getEnum =async(value)=>{
    const result = await enumSelect([enum_types.urgent_type])
    setStatus({
      value:result?.urgent_type ?? [],
      enum:value ?? []
    })
  }

  const onApplyFilter =(value)=>{
    setFilterData(value)
    getListData(0, 10, listTab, "filter", selectedCompany, selectedTeams , value)

  }
  const render = () => {
    return <>
      <Subheader hideBackButton={true} title={`Opportunity (${total ?? 0
        })`} select options={companyList} value={selectedCompany}
        onchange={(e) => {
          companySelectFunction(e)
        }

        }
        selectTeamsOptions
        handleTeamsChange={handleTeamsChange}
        companyId={selectedCompany?.value}
        moduleId={moduleId}
        selectedTeams={selectedTeams} />
      <div className={classes.root}>
        {
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="primary" />
          </Backdrop>
        }
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={4}>
            <SearchFilter
              value={searchdata}
              handleChange={(value) => getListData(0, 10, value, "key", selectedCompany, selectedTeams)}
              placeholder="Search by Contact Name"
            />
          </Grid>

          <Grid item xs={8} textAlign={"right"}>
            <Box display={"flex"} sx={{ float: "right" }}>
              <Box>
                <IconButton
                  size="small"
                  className={classes.img}
                  onClick={OpenDrawer}
                >
                  <img src="/images/filter.svg" alt="filter" />
                </IconButton>
              </Box>
              &nbsp; &nbsp; &nbsp;
              {permission?.create && <Box>
                <Button
                  variant="contained"
                  onClick={goCreateOppertunity}
                >
                  Add opportunity
                </Button>
              </Box>}
            </Box>
          </Grid>

          {/* Main tableData */}
          <Grid item xs={12} className={classes.tableMain}>
            {/* <TableWithPagination
            heading={Leadheading}
            rows={leadList}
            path={Leadpath}
            showpagination={true}
            onClick={() => (false)}
            showpdfbtn={false}
            handleIcon={handleIcon}
            showexcelbtn={false}
            showSearch={false}
            handlePagination={handlePagination}
            handleChangeLimit={handleChangeLimit}
            totalRowsCount={total}
            page={page}
            limit={limit}
            dataType={[
              { type: ["text"], name: "leadNo" },
              { type: ["date"], name: "date" },
              { type: ["quill"], name: "description" },
              { type: ["text"], name: "purpose" },
              { type: ["text"], name: "source" },
              { type: ["text"], name: "activeQuote" },
              { type: ["text"], name: "value" },
              { type: ["text"], name: "priority" },
              { type: ["text"], name: "owner" },
              { type: ["date"], name: "statusDate" },
              { type: ["status"], name: "status" },
              { type: ["more_5"], icon: "icon" },
            ]}
            height={'calc(100vh - 300px)'}
            view={true}
            edit={true}
            delete={true} /> */}
            <TableWithPagination
              heading={Leadheading}
              rows={leadList}
              path={Leadpath}
              showpagination={true}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              handleIcon={handleIcon}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              totalRowsCount={total}
              page={page}
              limit={limit}
              tableType="no-side"
              dataType={[
                { type: ["more_5"], icon: "icon" },
                { type: ["text"], name: "leadNo" },
                { type: ["date"], name: "date" },
                { type: ["quill"], name: "description" },
                { type: ["text"], name: "purpose" },
                { type: ["text"], name: "opportunity_type" },
                { type: ["text"], name: "activeQuote" },
                { type: ["text"], name: "value" },
                { type: ["text"], name: "owner" },
                { type: ["oppertunity_status"], name: "status" },
                { type: ["date"], name: "statusDate" },
                { type: ["date"], name: "dateOfClosed" },
                { type: ["text"], name: "leadNo" },
              ]}
              height={"calc(100vh - 315px)"}
              view={permission?.read}
              edit={permission?.update}
              delete={permission?.delete} />
          </Grid>


          {/* drawer filter component  */}
          {/* <DrawerComponent
            open={openFilter}
            onClose={OpenDrawer}
            toggleDrawer={OpenDrawer}
            component={
              <>
                <div className={classes.drawerwidth}>
                  <Box className={classes.Header}>
                    <Box className={classes.closeIcondrawermap}>
                      <Typography variant="h6" onClick={clearAll}>
                        <img src="\images\closebutton.svg" alt="close" />
                      </Typography>
                    </Box>
                    <Typography variant="h6" className={classes.title} noWrap>
                      Filter
                    </Typography>
                  </Box>
                  <div className={classes.createnewsection}>
                    <FilterComponent />
                  </div>
                  <Box className={classes.btnparent}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={clearAll}
                          className={classes.clearall}
                        >
                          Clear All
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={apply}
                          className={classes.Apply}
                        >
                          Apply
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </>
            }
          /> */}
          {
            openFilter && 
            <FilterGenerator 
            open={openFilter}
            onClose={OpenDrawer}
            components={[
              {
                component: "toggleButton",
                value: filterData?.periorty,
                options: status?.value,
                isMulti: true,
                state_name: "periorty",
                label: "Priority Type",
                // required:true
              },
              {
                component: "toggleButton",
                value: filterData?.status,
                options: status?.enum ?? [],
                isMulti: true,
                state_name: "status",
                label: "Status",
                // required:true
              },
              {
                component: "select",
                value: filterData?.name,
                isMulti: true,
                label: "Contact Name",
                state_name: "name",
                loadOptions: (search, array, handleLoading) =>
                  loadOptions(
                    search,
                    array,
                    handleLoading,
                    LIST_COMPANY_CONTACT,
                    "contact",
                    { isActive: true, company_id: selectedCompany?.value , clientId:clientId},
                    { label: "label", value: "value" },
                    false,
                    false,
                    selectedCompany?.value
                  ),
                debounceTimeout: 800,
                isPaginate: true,
              },
              {
                component: "select",
                value: filterData?.lead,
                isMulti: true,
                label: "Lead Owner",
                state_name: "lead",
                loadOptions: (search, array, handleLoading) =>
                  loadOptions(
                    search,
                    array,
                    handleLoading,
                    LIST_COMPANY_OWNER,
                    "lead_owners",
                    { isActive: true, company_id: selectedCompany?.value , clientId:clientId},
                    { label: "label", value: "value" },
                    false,
                    false,
                    selectedCompany?.value
                  ),
                debounceTimeout: 800,
                isPaginate: true,
              },
            ]}
            onApply={(value) => onApplyFilter(value)}
            />
          }
        </Grid>
      </div>
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>
  );
};
