import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Skeleton from '@mui/lab/Skeleton';
import { TextField, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import PropTypes from 'prop-types';
import React from 'react';
import { Regular, SemiBold } from '../../utils';

const CustomBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'height'
})(({ theme, height }) => ({
    width: 'inherit',
    height,
    position: 'relative',
    display: 'flex',
    borderRadius: 4,
    overflow: 'hidden',
    '&:hover': {
        transition: 'all 0.5s',
        '& .img': {
            filter: 'brightness(0.6)',
            transform: 'scale3d(1.15, 1.15, 1.15)',
        },
        '& .delete': {
            // display: 'block'
            opacity: 1
        }
    }
}));

const WidthBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'width'
})(({ theme, width }) => ({
    width,
    [theme.breakpoints.down('md')]: {
        width: '100%',
    }
}));

const CircularDiv = styled('div')(({ theme }) => ({
    width: 24,
    height: 24,
    backgroundColor: "#FFFFFF",
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
}));

const AbsoluteDiv = styled('div', {
    shouldForwardProp: (prop) => prop !== 'top' && prop !== 'right' && prop !== 'left' && prop !== 'bottom'
})(({ top, right, left, bottom }) => ({
    position: 'absolute',
    right,
    top,
    left,
    bottom,
    zIndex: 1,
    // display: 'none'
    opacity: 0
}))

const CustomTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'fontFamily' && prop !== "fontSize" && prop !== 'color' && prop !== "marginBottom"
})(({ theme, fontFamily, fontSize, color, marginBottom }) => ({
    fontFamily,
    fontSize: fontSize ? fontSize : 10,
    color: color ? color : theme.typography.color.white,
    marginBottom
}))

const CustomImg = styled('img')({
    maxWidth: '100%',
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: 4,
    transition: 'all 0.5s',
})

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E4E8EE',
        borderRadius: 4,

    },
    '& .MuiOutlinedInput-root': {
        height: 40,
    },
    '& input': {
        padding: 10,
        color: theme.typography.color.primary,
        fontFamily: SemiBold,
        fontSize: 14,
        lineHeight: 1
    },
    marginBottom: "12px"
}))

export const UploadImageURL = (props) => {

    const {
        data = {},
        handledelete = null,
        handleChange = null,
        parent_id = "",
        loading = false,
        isReadOnly = false,
        size = {},
        showTextField = true,
    } = props;

    const theme = useTheme();

    const returnSizeAndType = React.useCallback(() => {
        const { size = "", type = "" } = data;
        return `${size} ${(size && type) && ' • '} ${type}`
    }, [data])

    const onChange = (e) => {
        handleChange({
            ...data,
            url: e.target.value,
        })
    }

    return (
        <WidthBox
            width={size?.width ?? 254}

        >
            {
                loading ? (
                    <Skeleton
                        variant='rectangular'
                        width={'100%'}
                        height={size?.height ?? 160}
                        sx={{ borderRadius: '4px' }}
                    />
                ) : (
                    <>
                        <CustomBox
                            // width={size?.width ?? 254}
                            height={size?.height ?? 160}
                        >
                            <CustomImg
                                className='img'
                                src={data?.src}
                                alt={`${parent_id}__img`}
                            />
                            {!isReadOnly && <AbsoluteDiv className='delete' right={16} top={16}>
                                <CircularDiv onClick={handledelete}>
                                    <DeleteOutlineOutlinedIcon
                                        sx={{
                                            fontSize: 14,
                                            color: theme.palette.error.main
                                        }}
                                    />
                                </CircularDiv>
                            </AbsoluteDiv>}
                            <AbsoluteDiv className='delete' left={16} bottom={16}>
                                {data?.name && <CustomTypography
                                    fontFamily={SemiBold}
                                    fontSize={12}
                                    marginBottom={theme.spacing(1 / 2)}
                                >
                                    {data?.name}
                                </CustomTypography>}
                                {(data?.size || data?.type) && <CustomTypography fontFamily={Regular}>{returnSizeAndType()}</CustomTypography>}
                            </AbsoluteDiv>
                        </CustomBox>
                        {showTextField &&
                            <div style={{ width: '100%', marginTop: 8 }}>
                                <CustomTypography
                                    fontSize={12}
                                    fontFamily={SemiBold}
                                    color={theme.typography.color.tertiary}
                                    marginBottom={8}
                                >
                                    URL
                                </CustomTypography>
                                <CustomTextField
                                    id={`${parent_id}__input`}
                                    value={data?.url}
                                    onChange={onChange}
                                    fullWidth
                                    disabled={isReadOnly}
                                />
                            </div>}
                    </>
                )
            }
        </WidthBox>
    )
}

UploadImageURL.propTypes = {
    data: PropTypes.object,
    handledelete: PropTypes.func,
    handleChange: PropTypes.func,
    parent_id: PropTypes.string,
    loading: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    size: PropTypes.object,
    showTextField: PropTypes.bool,
}