import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 0px 16px #00000014`,
    backgroundColor: "white",
    padding: "16px 0px 16px 16px",
    margin: "14px",
    height: `calc(100vh - 147px)`,
    overflow: "auto"
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "13px",
  },
  outerCircle: {
    padding: "2px",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
  },
  tableMain: {
    padding: "0px 16px !important",
  },
  arrowIcon: {
    color: "#5078E1",
    fontSize: "12px",
  },
  view: {
    display: "flex",
    alignItems: "center",
    color: "#5078E1",
    fontSize: "12px",
    fontFamily: Bold,
    cursor: "pointer",
  },
  displayBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px",
  },
  catagory: {
    color: "#071741",
    fontSize: "16px",
    fontFamily: ExtraBold,
  },
  imgCard: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  card: {
    boxShadow: "0px 0px 16px #00000014",
  },
  noData: {
    textAlign: "center",
    marginTop: "30%",
    [theme.breakpoints.down("md")]: {
      marginTop: "10%",
    },
  },
  titleFont: {
    color: "#091B29",
    fontSize: "16px",
    fontFamily: ExtraBold,
  },
}));
