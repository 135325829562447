import makeStyles from "@mui/styles/makeStyles";
import { SemiBold, Bold, Regular, } from "../../../utils";
import { app_env } from "../../../utils";

export const useStyles = makeStyles((theme, data) => ({
  notificationFlex: {
    display: "flex",
    alignItems: "center",
    backgroundColor: data?.is_read === true ? '#fff' : theme?.palette?.notification?.secondary,
    padding: "12px",
    borderBottom: `1px solid ${theme?.palette?.background?.secondary}`,
    position: "relative",
    cursor: 'pointer',
  },
  bellBackGroundColor: {
    backgroundColor: theme?.palette?.notification?.primary,
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    marginRight: "10px",
  },
  tabs: {
    "&.MuiTab-root": {
      padding: "0px !important",
    },
  },
  notification: {
    cursor: "pointer"
  },
  title: {
    color: theme?.typography?.color?.primary,
    fontFamily: Bold,
    fontSize: "12px",
    marginBottom: '1px',
    maxWidth: '223px',
    width: '100%',
    '& p': {
      margin: '0px 0px 4px !important'
    },
    '& .ql-editor': {
      padding: '0px 0px 0px 0px',
      fontSize: "14px",
      maxWidth: '223px',
      width: '100%',
      cursor: 'pointer'
    },
    '& .ql-editor > *': {
      fontSize: "14px",
    }
  },
  date: {
    color: theme?.typography?.color?.tertiary,
    fontFamily: Regular,
    fontSize: "10px",
  },
  des: {
    color: theme?.typography?.color?.tertiary,
    fontFamily: Regular,
    fontSize: "12px",
    marginBottom: '1px',
    cursor: 'pointer',
    '& p': {
      margin: '0px 0px 4px !important'
    },
    '& .ql-editor': {
      padding: '0px 0px 0px 0px',
      maxWidth: '223px',
      width: '100%',
      color: theme?.typography?.color?.tertiary,
      fontFamily: Regular,
      fontSize: "12px !important",
      marginBottom: '1px',
    },
    '& .ql-editor > *': {
      fontSize: "12px !important",
    }
  },
  chip: {
    borderRadius: theme?.palette?.borderRadius,
    color: theme?.palette?.notification?.chip,
    backgroundColor: theme?.palette?.background?.chip,
    position: "absolute",
    padding: "0px",
    width: "71px",
    height: "21px",
    fontSize: '8px',
    fontFamily: Regular,
    top: '7px',
    right: '6px',
    "&:hover": {
      color: theme?.palette?.notification?.chip,
      backgroundColor: theme?.palette?.background?.chip,
    },
  },
  IconBackGroundColor: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    marginRight: "10px",
  },
}));
export const useStylesTopNavBar = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  switcher: {
    borderRadius: theme.palette.borderRadius,
    padding: "4px 16px",
  },
  menu: {
    marginTop: "14px",
    left: "14px",
    "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
    {
      borderRadius: theme.palette.borderRadius,
      padding: "0px !important",
      boxShadow: "0px 8px 24px #0717411F",
    },
    "& .MuiMenu-list": {
      padding: "0 !important",
    },
  },
  menu2: {
    marginTop: "4px",

    "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
    {
      borderRadius: theme.palette.borderRadius,
      padding: "0px !important",
      boxShadow: "0px 8px 24px #0717411F",
    },
    "& .MuiMenu-list": {
      padding: "0 !important",
    },
  },
  borders: {
    padding: "8px 0px",
    //borderBottom: "1px solid #E4E8EE",
  },
  appBar: {
    backgroundColor: "#232323",
    width: "100%",
    borderBottom: "1px solid #666666",
    boxShadow: "none",
    justifyContent: "space-between",
    // marginLeft: `${drawerWidth}px`,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 0,
    },
    // eslint-disable-next-line
    boxShadow: (props) => (props?.boxShadow === false ? "none" : "auto"),
  },
  tag: {
    border: "1px solid #F17360",
    color: "#F17360",
    padding: "2px 4px",
    borderRadius: theme.palette.borderRadius,
    display: "inline",
    fontSize: "12px",
    marginTop: "4px",
  },
  title: {
    display: "block",
    color: "#FFFFFF",
    fontSize: "16px",
    fontFamily: Bold,
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
  },
  userimg: {
    borderRadius: "50%",
    height: "65px",
    width: "65px",
  },
  titleLogo: {
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  border1: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    display: "flex",
    alignItems: "center",
    backgroundColor: "#E4E8EE",
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  gotocomm: {
    marginLeft: "8px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    // padding:'11px 11px 0px 0px'
  },
  language: {
    border: "1px solid #707070",
    color: "#FFFFFF",
    padding: "0px 6px!important",
    "&:hover": {
      backgroundColor: "inherit"
    }
    // padding:'11px 11px 0px 0px'
  },
  username: {
    color: "#FFFFFF",
    // marginLeft: "8px",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    cursor: "pointer",
    fontSize: "12px",
    fontFamily: Bold,
  },
  username1: {
    color: theme.typography.color.primary,
    fontSize: "14px",
    fontFamily: Bold,
  },
  email: {
    color: theme.typography.color.tertiary,
    fontSize: "12px",
    fontFamily: Regular,
    margin: "6px 0px"
  },
  username2: {
    color: "#98A0AC",
    marginLeft: "8px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "10px",
  },
  arrow: {
    marginLeft: "8px",
  },
  titleContainer: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    alignItems: "center",
  },
  user: {
    display: "flex",
    alignItems: "center",
  },
  border: {
    display: "flex",
    alignItems: "center",
    color: "#FFF",
  },
  titlerole: {
    color: theme.typography.color.tertiary,
    fontSize: "12px",
    fontFamily: Bold,
    margin: "8px 8px 4px 8px",
  },
  roleData: {
    borderRadius: "12px",
    border: "1px solid #E4E8EE",
    padding: "8px",
    minHeight: "110px",
    cursor: "pointer",
  },
  avatarrole: {
    marginTop: "4px",
  },
  labelrole: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: SemiBold,
    marginTop: "4px",
  },
  label: {
    fontSize: "13px",
    color: theme.typography.color.secondary,
    fontFamily: SemiBold,
    padding: "6px 12px",
    cursor: "pointer",
  },
  label2: {
    fontSize: "13px",
    color: "#FF4B4B",
    fontFamily: SemiBold,
    padding: "6px 12px",
    cursor: "pointer",
  },
  signout: {
    color: "#FF4B4B",
    fontSize: "14px",
    padding: "12px 12px 8px 20px",
    marginTop: "12px",
    cursor: "pointer",
    borderTop: "1px solid #E4E8EE",
  },
  versionCss: {
    color: "#aeaeaebd",
    fontSize: "10px",
    position: "absolute",
    bottom: "5px",
    left: "70px",
  },
  switchBtn: {
    backgroundColor: "#5078E1",
    color: "#fff",
    width: "100%",
    marginTop: "4px",
    "&:hover": {
      backgroundColor: "#5078E1",
      color: "#fff",
    },
  },
  switchParent: {
    backgroundColor: "#F2F3F5",
    borderRadius: "4px",
    padding: "12px",
  },
  switchName: {
    color: "#4E5A6B",
    fontSize: "10px",
    fontFamily: SemiBold,
    marginLeft: "10px",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "12px",
  },
  lostLogin: {
    color: "#98A0AC",
    fontSize: "10px",
    fontFamily: Regular,
  },
  profileCard: {
    borderTop: "1px solid #F2F3F5",
    borderBottom: "1px solid #F2F3F5",
    padding: "7px 16px",
  },
  anchor: {
    color: "#98A0AC",
    fontSize: "12px",
    fontFamily: Regular,
    textDecoration: "underline",
    cursor: "pointer",
  },
  dot: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "#E4E8EE",
    margin: "0px 10px",
    padding: "3px",
  },
  Reference: {
    padding: "14px 16px",
  },
  popupBoady: {
    padding: "0px 24px",
    marginBottom: "10px"
  },
  notification: {
    color: "#fff",
  },
  notificationBox: {
    width: "400px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  accordion: {
    "&.MuiAccordion-root:before": {
      backgroundColor: theme.palette.background.tertiary
    },
    backgroundColor: theme.palette.background.tertiary,
    "&.MuiAccordion-root:last-of-type": {
      // borderBottomRightRadius: "12px",
      // borderBottomLeftRadius: "12px"
    },
    "&.MuiAccordion-root:first-of-type": {
      // borderTopRightRadius: "12px",
      // borderTopLeftRadius: "12px"
    },
    "&.Mui-expanded": {
      backgroundColor: theme.palette.background.tertiary,
    }
  },
  text: {
    fontFamily: `${Bold}`,
    fontSize: "16px",
    color: theme.typography.color.primary,
    margin: "10px"

  },
  helpText: {
    fontFamily: `${SemiBold}`,
    fontSize: "12px",
    color: theme.typography.color.secondary,
    textDecoration:"none"
  },
  accordionBox: {
    backgroundColor: theme.palette.background.tertiary,

  },
  loader: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px'
  },
  textButton: {
    cursor: "pointer",
    color: "white",
    fontSize: "14px",
    fontFamily: `${SemiBold}`
  },
  divider: {
    backgroundColor: "white"
  },
  env_tag: {
    borderRadius: "2px",
    padding: "1px 6px",
    backgroundColor: (props) => app_env(props?.app_Env)?.tag_color,
    marginLeft: "12px",
    fontSize: "12px",
    fontFamily: Bold,
    color: "white",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  nodataFound:{
    fontSize:"14px",
    color:theme.typography.color.secondary,
    fontFamily: SemiBold
  }
}));
