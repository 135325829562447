import React from "react";
import { dashboardStyles } from "./style";
import { Typography, Box, Stack, Divider } from "@mui/material";
import { ActivePropertyIcon } from "../../../assets";

export const RevenueCard = (props) => {
  // classes
  const classes = dashboardStyles(props);
  return (
    <div className={classes.card}>
      {props?.isStacked ? (
        <>
          {props?.isCustomers ? (
            <Box className={classes.flexBox}>
              <Box>
                <Typography className={classes.inrFont}>
                  {props?.sub}
                </Typography>
                <Typography className={classes.inrFontPeriod}>
                  {props?.sub1}
                </Typography>
              </Box>
              <Box className={classes.flexBox}>
                <ActivePropertyIcon />
              </Box>
            </Box>
          ) : (
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              {props?.data?.map((x) => {
                return (
                  <Box>
                    <Box className={classes.flexBox}>
                      <Typography className={classes.inrFont}>
                        {x?.sub}
                      </Typography>
                      <Typography className={classes.inrFont}>
                        {x?.icon}
                      </Typography>
                    </Box>

                    <Typography className={classes.inrFontPeriod}>
                      {x?.sub1}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>
          )}
        </>
      ) : (
        <>
          <Box className={classes.flexBox}>
            {props?.title?.length > 0 && (
              <Typography className={classes.titleFont}>
                {props?.title}
              </Typography>
            )}

            <ActivePropertyIcon />
          </Box>
          <Box>
            <Typography className={classes.inrFont}>{props?.sub}</Typography>
            <Typography className={classes.inrFontPeriod}>
              {props?.sub1}
            </Typography>
          </Box>
        </>
      )}
    </div>
  );
};
