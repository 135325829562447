import { Avatar, Box, Divider, Grid, Stack, TextField, Typography, Button, Dialog, DialogContent, DialogContentText } from '@mui/material'
import React from 'react'
import { BookingResidentPopupStyle } from './style'
import EditIconSvg from '../../assets/editIcon'
import moment from 'moment'
import { InvoiceDetail, PaymentCard } from './components'
import { UnitListCard } from './components/unitListCard'
import { NetworkCall } from '../../networkcall'
import { config } from '../../config'
import { NetWorkCallMethods, AlertProps, timeZoneConverter, SemiBold } from '../../utils'
import { AlertContext } from '../../contexts'
import styled from "@mui/material/styles/styled";
import GreenCalendarSvg from '../../assets/calendarGreen'
import { withNamespaces } from 'react-i18next'
import { CustomSelect } from '../../components/filterGenerator/components'
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions'

const BookingRecidentPopup = (
    {
        getAmenityBookingList = () => false,
        closeCheckAvailability = () => false,
        closeBookingResidentPopup = () => false,
        amenityData = {},
        bookingDate = "",
        slots = [],
        selectedCompany = {},
        selectedProperty = {},
        t
    }
) => {
    const classes = BookingResidentPopupStyle()
    const [openSearch, setOpenSearch] = React.useState(false)
    const [selectedUnit, setSelectedUnit] = React.useState({})
    const [addUnit, setAddUnit] = React.useState(false)
    const [confirmation, setComfirmation] = React.useState(false)
    const alert = React.useContext(AlertContext)

    const setUnit = (e) => {
        setSelectedUnit(e)
        setOpenSearch(false)
    }
    
    const manualResponse = (array) => {
        const details = array?.agreementUnits?.map((i) => {
            return {
                ...i,
                label: i?.unit_name,
                value: i?.agreement_unit_id
            }
        })
        return details
    };

    const bookingSlots = slots?.map((slot) => {
        return [
            slot?.value?.check_in_value,
            slot?.value?.check_out_value
        ]
    })

    // Paynow function (book amenities)
    const bookAmenities = () => {
        const payload = {
            property_id: selectedProperty?.value,
            property_facility_id: amenityData?.facility_id,
            period: amenityData?.period,
            start_date: amenityData?.period === "Daily" ? moment(slots[0]?.value?.check_in_date).format("YYYY-MM-DD") + " 00:00:00" : moment(bookingDate).format("YYYY-MM-DD") + " 00:00:00",
            end_date: amenityData?.period === "Daily" ? moment(slots[slots?.length - 1]?.value?.check_out_date).format("YYYY-MM-DD") + " 23:59:59" : moment(bookingDate).format("YYYY-MM-DD") + " 23:59:59",
            booked_slots: amenityData?.period === "Hourly" ? bookingSlots : [],
            rate: amenityData?.rate,
            total_rate: amenityData?.rate * bookingSlots.length,
            no_of_slots: bookingSlots.length ?? null,
            total_days: amenityData?.period === "Hourly" ? null : 1,
            agreement_unit_id: selectedUnit?.agreement_unit_id,
            currency: amenityData?.symbol,
            paid_on: new Date(),
            paid_amount: amenityData?.rate * bookingSlots.length,
            company_id: selectedCompany?.value,
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/book_amenity`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                getAmenityBookingList()
                closeBookingResidentPopup()
                closeCheckAvailability()
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("BookingDoneSuccessfully"),
                });
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                });
            });
    };

    const bookSlot = () => {
        bookAmenities()
    }

    const handleUnselectUnit = () => {
        setSelectedUnit({})
        setOpenSearch(false)
    }

    return (
        <Box>
            <Grid container>
                <Grid item md={6} className={classes.grid_border} p={1}>
                    <Box p={1}>
                        <Stack spacing={1} mt={1}>
                            <Typography className={classes.title}>{t("AMENITYDETAILS")}</Typography>
                            <Stack className={classes.border} direction={"row"} p={2} spacing={2}>
                                <Avatar src={amenityData?.assets[0]}></Avatar>
                                <Stack>
                                    <Typography className={classes.name}>{amenityData?.amenities_name}</Typography>
                                    <Typography className={classes.content}> {selectedProperty?.label} </Typography>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Stack spacing={1} mt={1.5}>
                            <Typography className={classes.title}>{t("BOOKINGDETAILS")}</Typography>
                            <Stack className={classes.border} p={2} spacing={2}>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Stack direction={"row"} spacing={2} flexGrow={1}>
                                        <Box className={classes.bookingAvatar}>
                                            <GreenCalendarSvg />
                                        </Box>
                                        <Stack>
                                            <Typography className={classes.name}> {timeZoneConverter(bookingDate)}</Typography>
                                            <Typography className={classes.content}>{t("Bookingdate")}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Box onClick={closeBookingResidentPopup} className={classes.editIcon}>
                                        <EditIconSvg />
                                    </Box>
                                </Stack>
                                <Divider></Divider>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Stack direction={"row"} spacing={2} flexGrow={1}>
                                        <Avatar src={amenityData?.assets[0]}></Avatar>
                                        <Stack>
                                            {
                                                slots?.[0]?.value?.check_in_date ?
                                                    <Typography className={classes.name}>
                                                        {moment(slots[0]?.value?.check_in_date).format("DD MMM YY")} - {moment(slots[0]?.value?.check_out_date).format("DD MMM YY")}
                                                    </Typography>
                                                    :
                                                    <Typography className={classes.name}>
                                                        {moment(slots[0]?.value?.check_in_time, "HH:mm").format("hh:mm A")} - {moment(slots[slots.length - 1]?.value?.check_out_time, "HH:mm").format("hh:mm A")}
                                                    </Typography>
                                            }
                                            <Typography className={classes.content}>{amenityData?.amenities_name}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Box onClick={closeBookingResidentPopup} className={classes.editIcon}>
                                        <EditIconSvg />
                                    </Box>
                                </Stack>

                                {addUnit && <Divider></Divider>}
                                {
                                    addUnit &&
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Stack direction={"row"} spacing={2} flexGrow={1}>
                                            <Avatar src={selectedUnit?.unit_logo}></Avatar>
                                            <Stack>
                                                <Typography className={classes.name}> {selectedUnit?.unit_name} </Typography>
                                                <Typography className={classes.content}>{selectedUnit?.property_name}, {selectedUnit?.amenity_user}</Typography>
                                            </Stack>
                                        </Stack>
                                        <Box className={classes.editIcon} onClick={() => setAddUnit(!addUnit)}>
                                            <EditIconSvg />
                                        </Box>
                                    </Stack>
                                }

                            </Stack>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item md={6} className={classes.grid_border} p={1}>
                    {
                        !addUnit &&
                        <Box p={1}>
                            <Box sx={{ height: "calc(100vh - 450px)", overflow: "auto" }}>
                                {
                                    <CustomSelect
                                        loadOptions={(search, array, handleLoading) =>
                                            loadOptionsApis(
                                                "amenities_booking_v2/get_agreementunits_and_units",
                                                {
                                                    property_id: selectedProperty?.value,
                                                    date: moment(new Date()).format("YYYY-MM-DD"),
                                                },
                                                search,
                                                array,
                                                handleLoading,
                                                "data",
                                                {},
                                                manualResponse
                                            )
                                        }
                                        isPaginate={true}
                                        borderRadius={false}
                                        noBorder={false}
                                        placeholder={"Select Unit"}
                                        label={false}
                                        value={selectedUnit}
                                        onChange={(value) => {
                                            setUnit(value)
                                        }}
                                    />
                                }
                                {
                                    !openSearch && selectedUnit?.agreement_unit_id &&
                                    <UnitListCard data={selectedUnit} removeUnit={() => handleUnselectUnit()} close={true} />
                                }
                            </Box>
                            <Button variant="contained" disabled={selectedUnit?.agreement_unit_id ? false : true} fullWidth
                                onClick={() => setAddUnit(!addUnit)}>
                                {t("AddUnitAndProceedBilling")}
                            </Button>
                        </Box>
                    }
                    {
                        addUnit &&
                        <Box pt={2}>
                            <center>
                                <Stack spacing={2} alignItems={"center"}>
                                    <Typography className={classes.invoiceTitle1}>{t("INVOICEDETAILS")}</Typography>
                                    <Typography className={classes.invoiceTitle2}>{t("InvoiceNo")}: -</Typography>
                                </Stack>
                            </center>
                            <InvoiceDetail slots={slots} amenityData={amenityData} />
                            <PaymentCard type="offline" />
                            <PaymentCard type="online" click={() => setComfirmation(!confirmation)} />
                        </Box>
                    }
                </Grid>
            </Grid>

            <Dialog
                open={confirmation}
                onClose={() => setComfirmation(!confirmation)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.publishDialog}
            >
                <DialogContent sx={{ padding: 0 }}>
                    <DialogContentText sx={{ background: "transparent" }}>
                        <div style={{
                            paddingRight: "60px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            backgroundImage: `url(${"images/success.svg"})`,
                            backgroundRepeat: "no-repeat",
                            height: 250,
                            width: 600
                        }}>
                            {/* <Stack spacing={2} alignItems={"center"}>
                                <Typography className={classes.publishPopupTitle}>{t("InvoicePublishedSuccessfully")}</Typography>
                                <Box className={classes.publishPopupBtn}
                                    onClick={closePublish}>
                                    <Typography className={classes.publishbtnText}>{t("Okay")}</Typography>
                                </Box>
                            </Stack> */}

                            <div className={classes.proceedDialog}>
                                <Stack spacing={3}>
                                    <Typography className={classes.convert}>
                                        {t("Areyousureyouwanttoraiseamanagedinvoice")}
                                    </Typography>
                                    <Box display="flex" alignItems="center" className={classes.marginTop}>
                                        <Button className={classes.yes} onClick={bookSlot}>
                                            {t("Yes")}
                                        </Button>
                                        <Button className={classes.No} onClick={() => setComfirmation(!confirmation)}>
                                            {t("No")}
                                        </Button>
                                    </Box>
                                </Stack>
                            </div>

                        </div>

                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default withNamespaces("amenityBooking")(BookingRecidentPopup)
