import { Stack, TextField, Typography, Button } from '@mui/material'
import React from 'react'
import { CustomSelect } from '../../../components/filterGenerator/components'
import { GET_RECEIPTS, DECLINE_RECONCILE_MUTATION } from '../../../graphql/alreadyPaidQueries'
import { loadOptions } from '../../../utils/asyncPaginateLoadOptions'
import { UpdatePopupStyle } from './style'
import { useApolloClient } from "@apollo/client";
import { RequestMessage } from '../components'

export const UpdatePopup = (
    {
        type = "",
        close = () => false,
        invoice_data = {},
        title = "", message = "",
        enumData = { },
        statusUpdate = () => false,
        closeReqpopup =() => false
    }
) => {
    const initialState = {
        receipt_id: "",
        remarks: ""
    }
    const client = useApolloClient();
    const [data, setData] = React.useState({ ...initialState })

    const mutaionUpdate = () => {
        let invoice_id = invoice_data?.id

        client.mutate({
            mutation: DECLINE_RECONCILE_MUTATION,
            variables: {
                id: invoice_id,
                payload: {
                    compensated_type: enumData[1]?.value,
                    reciepts_id:  type === "Reconcile" ? data?.receipt_id : null,
                    remarks: data?.remarks,
                    updated_at:new Date()
                }
            }
        }).then(rs => {
            statusUpdate()
            close()
            closeReqpopup()
        }).catch(er => {
            console.log(er)
        })
    }
    const classes = UpdatePopupStyle()
    return (
        <Stack spacing={2} m={1}>
            <RequestMessage type={type} title={title} message={message} />
            {
                type !== "Rejected" &&
                <CustomSelect
                    label={"Receipt ID"}
                    isPaginate={true}
                    onChange={(val) => {
                        setData({ ...data, receipt_id: val.value })
                    }}
                    loadOptions={(search, array, handleLoading) =>
                        loadOptions(
                            search,
                            array,
                            handleLoading,
                            GET_RECEIPTS,
                            'reciepts',
                            { account_no: "taxhvswep" },
                            { label: "label", value: "value" }
                        )
                    }
                />
            }
            <Stack spacing={1}>
                <Typography className={classes.label}>Remarks</Typography>
                <TextField id="outlined-basic" variant="outlined" multiline="true" minRows={4}
                    onChange={(val) => setData({ ...data, remarks: val.target.value })} />
            </Stack>

            <Stack direction={"row"} spacing={2}>
                <Button variant="outlined" fullWidth disableElevation onClick={close}> Cancel </Button>
                <Button variant="contained" fullWidth disableElevation onClick={mutaionUpdate}> Update </Button>
            </Stack>
        </Stack>
    )
}