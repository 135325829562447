import { Grid, Box, Stack, Typography, Avatar } from '@mui/material';
import moment from 'moment';
import React from 'react'
import { eachHourOfInterval } from 'date-fns'
import { weeklyCalendarStyle, eventListStyle } from './style'
import { CalProgressbar } from '../../screens/resourceBoard/component/calProgressbar';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import TaskSVG from '../../assets/task';
export const WeeklyCalendar = ({ weekdates = [], data = [], handleEvent = () => false, }) => {
    const classes = weeklyCalendarStyle()

    const hours = eachHourOfInterval({
        start: new Date(2022, 7, 25, 0),
        end: new Date(2022, 7, 25, 23)
    })

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            maxWidth: "100%",
            border: '1px solid #dadde9',
        },
    }));


    return (
        <Box>
            <Grid container sx={{ width: "99.4%" }}>
                <Grid item md={1} xs={1} sm={1} className={classes.cells}>
                    <Stack spacing={1} alignItems={"center"}>
                        <Typography className={classes.listTitle}> Load </Typography>
                    </Stack>
                </Grid>
                {weekdates?.map((e) => {
                    return (
                        <Grid item md={1.57} xs={1.57} sm={1.57} className={classes.cells}>
                            <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
                                <Stack>
                                    <Typography className={classes.dayLabel}>
                                        {moment(e).format("ddd")}
                                    </Typography>
                                    <Typography className={classes.dateLabel}>
                                        {moment(e).format("DD")}
                                    </Typography>
                                </Stack>
                                {
                                    data?.map(d => {
                                        return (
                                            d?.date === moment(e).format("DD-MM-YYYY") ?
                                                (d?.process && <CalProgressbar value={d?.process} />) : ""
                                        )
                                    })
                                }
                            </Stack>
                        </Grid>
                    );
                })}
            </Grid>
            <Grid container className={classes.calenderRows}>
                {hours?.map((val) => {
                    return (
                        <>
                            <Grid item md={1} xs={1} sm={1} className={classes.cells}>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Typography>{moment(val).format("HH:mm")}</Typography>
                                </Stack>
                            </Grid>

                            {
                                weekdates.map(date => {
                                    return (
                                        <Grid item md={1.57} sm={1.57} xs={1.57} className={classes.cells}>
                                            {
                                                data?.map(wd => {
                                                    return (
                                                        wd?.date === moment(date).format("YYYY-MM-DD") &&
                                                        <div>
                                                            {
                                                                wd?.data?.filter(item => item.time === moment(val).format("HH:mm:ss")).map(evnt => {
                                                                    return (
                                                                        <Box>
                                                                            {
                                                                                evnt?.event?.map((d) => {
                                                                                    return (
                                                                                        <Box>
                                                                                            {
                                                                                                evnt?.event?.length < 2 &&
                                                                                                <Box sx={{ cursor: "pointer" }}>
                                                                                                    {/* <HtmlTooltip
                                                                                                        title={evnt.module === "General Inspection" ?
                                                                                                            <React.Fragment>
                                                                                                                <CustomToolTip data={evnt} />
                                                                                                            </React.Fragment> : ""
                                                                                                        }
                                                                                                        arrow className={classes.customTooltip}
                                                                                                    > */}

                                                                                                    <Stack direction={"row"} spacing={2} alignItems={"center"}
                                                                                                        sx={{
                                                                                                            backgroundColor: d.status === "Meter Reading" ? "#FFFAD880" : d?.status === "Move In" ? "#FEEAEA80" : "#DBF0F180",
                                                                                                            color: d?.status === "Meter Reading" ? "#B3A16D" : d?.status === "Move In" ? "#B3776D" : "#6DAFB3"
                                                                                                        }} p={0.7} >
                                                                                                        <Typography className={classes.event_label} onClick={() => handleEvent(date)}> {d?.title} </Typography>
                                                                                                    </Stack>
                                                                                                    {/* </HtmlTooltip> */}
                                                                                                </Box>
                                                                                            }

                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }
                                                                            {
                                                                                evnt?.event?.length > 1 &&
                                                                                <Box sx={{ cursor: "pointer" }}>
                                                                                    <HtmlTooltip
                                                                                        title={evnt?.event[0].status === "Meter Reading" ?
                                                                                            <React.Fragment>
                                                                                                <CustomToolTip data={evnt?.event[0]} />
                                                                                            </React.Fragment> : ""
                                                                                        }
                                                                                        arrow className={classes.customTooltip}
                                                                                    >
                                                                                        <Stack direction={"row"} spacing={2} alignItems={"center"}
                                                                                            sx={{
                                                                                                backgroundColor: evnt?.event[0]?.status === "Meter Reading" ? "#FFFAD880" : evnt?.event[0]?.status === "Move In" ? "#FEEAEA80" : "#DBF0F180",
                                                                                                color: evnt?.event[0]?.status === "Meter Reading" ? "#B3A16D" : evnt?.event[0]?.status === "Move In" ? "#B3776D" : "#6DAFB3"
                                                                                            }} p={0.7}>
                                                                                            <Typography className={classes.event_label}> {evnt?.event[0]?.title} , {evnt?.event[0]?.status} </Typography>
                                                                                        </Stack>
                                                                                    </HtmlTooltip>
                                                                                    <Typography className={classes.moreLabel} onClick={() => handleEvent(date)}> +{evnt?.event?.length - 1} more </Typography>
                                                                                </Box>
                                                                            }
                                                                        </Box>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </>
                    );
                })}
            </Grid>
        </Box>
    )
}

const CustomToolTip = ({ data = {} }) => {
    const classes = eventListStyle()
    return (
        <Stack spacing={2}>
            <Stack direction={"row"} spacing={2}>
                <TaskSVG className={classes.ttavatar} />
                <Stack>
                    <Typography className={classes.ttTitle}> {data.status} </Typography>
                    <Typography className={classes.ttsecTitle}> {data.module} </Typography>
                    <Typography className={classes.ttassigned_by}> {data.assigned_by} </Typography>
                </Stack>
            </Stack>
            <Stack direction={"row"} spacing={2}>
                <Avatar className={classes.ttavatar} src={data.image}></Avatar>
                <Stack>
                    <Typography className={classes.ttname}> {data.empname} </Typography>
                    <Typography className={classes.ttassigned_by}> {data.role} </Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}