import React, { useState } from "react";
import { Subheader, FormGenerator } from "../../components";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Grid, Button } from "@mui/material";
import { initial, CustomTypography } from "./common";
import { useStyles } from "./style";
import { SingleImgUpload } from "./components";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { NetWorkCallMethods, AlertProps, accessCheckRender, getRoutePermissionNew, getCompanyOption, getPropertyOptions ,LocalStorageKeys, enumSelect, enum_types} from "../../utils";
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";
import {
  GET_MAINTENANCE_CATEGORY_NEW as GET_MAINTENANCE_CATEGORY,
  GET_MAINTENANCE_SUB_CATEGORY_NEW as GET_MAINTENANCE_SUB_CATEGORY,
  SELECTUNITS_NEW as SELECTUNITS,
  GET_MAINTANCE_REQUEST,
  SELECT_VENDOR
} from "../../graphql/queries";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { Routes } from "../../router/routes";
export const Request = () => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = useState({ ...initial });
  const [images, setImages] = React.useState([]);
  const [uuid, setUUID] = React.useState(1);
  const [deletedAssetURL, setDeletedAssetURL] = React.useState([]);
  const { data } = useLocation();
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext)
  const [permission, setPermission] = React.useState({})
  const [properties, setProperties] = React.useState([])
  const [enumValue, setValue] = useState({
    type: [],
  });

   // enum get
   const getEnum = async () => {
    const result = await enumSelect([enum_types.work_order_status]);
    setValue({
      type: result?.work_order_status,
    });
  };

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        let propertiesArray = getPropertyOptions(company)
        setProperties(propertiesArray ?? [])
        getEnum()
        if (data?.main?.isEdit) getDetails();
      }
    }
    // eslint-disable-next-line
  }, [auth, data?.main?.id]);

  // validation
  const validateContact = () => {
    let isValid = true;
    let error = state.error;
    // if (state?.contactName?.length === 0) {
    //   isValid = false;
    //   error.contactName = "Contact Name is Required";
    // }
    // if (state?.PrimaryContact?.mobile?.length === 0) {
    //   isValid = false;
    //   error.PrimaryContact = "Mobile is Required";
    // }
    // if (state?.alternativeContact?.mobile?.length === 0) {
    //   isValid = false;
    //   error.alternativeContact = "AlterNative Mobile is Required";
    // }
    if (state?.Category?.length === 0) {
      isValid = false;
      error.Category = "Category is Required";
    }
    if (state?.Unit?.length === 0) {
      isValid = false;
      error.Unit = "Unit is Required";
    }
    if (state?.visitorDate?.length === 0) {
      isValid = false;
      error.visitorDate = "Prefered date is Required";
    }
    if (state?.subCategory?.length === 0) {
      isValid = false;
      error.subCategory = "subCategory is Required";
    }
    if (state?.Title?.length === 0) {
      isValid = false;
      error.Title = "Problem Title is Required";
    }
    if (state?.problemSince?.length === 0) {
      isValid = false;
      error.problemSince = "Problem Since is Required";
    }

    if (state?.Property?.length === 0) {
      isValid = false;
      error.Property = "Property is Required";
    }
    if (state?.company?.length === 0) {
      isValid = false;
      error.company = "Company is Required";
    }
    if (
      state?.Category?.length === 0 ||
      state?.subCategory?.length === 0 ||
      state?.Unit?.length === 0 ||
      state?.Title?.length === 0 ||
      state?.visitorDate?.length === 0 ||
      // state?.alternativeContact?.mobile?.length === 0 ||
      // state?.PrimaryContact?.mobile?.length === 0 ||
      state?.Property?.length === 0 ||
      state?.problemSince?.length === 0 
      // state?.contactName?.length === 0
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all mandatory field",
      });
    }

    setState({ ...state, error });

    return isValid;
  };
  //   updateState
  const updateState = (key, value) => {
    let error = state?.error;
    error[key] = "";
    if (key === "Category") {
      setState({ ...state, subCategory: "", [key]: value, error });
    } else if (key === "Property") {
      setState({ ...state, Unit: "", [key]: value, error });
    } else {
      setState({ ...state, [key]: value, error });
    }
  };
  //   handleAssetFile
  const handleAssetFile = (data) => {
    setDeletedAssetURL([...deletedAssetURL, data?.id]);
    setImages(images?.filter((val) => val?.url !== data.url));
  };
  // request creation
  const saveMaintenanceRequest = () => {
    if (validateContact()) {
      const payload = {
        type: 2,
        subject: state?.Title ?? null,
        description: state?.Description ?? null,
        category: state?.Category?.value ?? null,
        sub_category: state?.subCategory?.value ?? null,
        problem_since: state?.problemSince ?? null,
        preferred_time:
        state?.visitorDate ?? null,
        contact_name: state?.contactName?.length > 0 ? state?.contactName : undefined,
        contact_mobile: state?.alternativeContact?.mobile?.length > 0 ?  state?.alternativeContact?.mobile :  undefined,
        contact_alternative_mobile: state?.PrimaryContact?.mobile?.length > 0 ? state?.PrimaryContact?.mobile : undefined,
        work_order_ref_no:state?.workNo ? state?.workNo : null,
        work_order_date:state?.workdate ? state?.workdate : null,
        vendor_ref:state?.vendor ? state?.vendor?.value : null,
        work_order_status:state?.workerStatus ? state?.workerStatus?.value : null,
        image_urls: images?.map((x) => {
          return {
            url: x.url,
            "file_meta": {
              "file_name": x?.name ?? null,
              "file_size": x?.size ?? null,
              "file_type": x?.type ?? null
            }
          }
        }),
        units: state?.Unit?.label?.length > 0 ? [state?.Unit?.value] : null,
        userProfileId: localStorage.getItem("profileID"),
        propertyId: state?.Property?.value ?? null,
        companyId: data?.main?.company?.value ?? null,
        mobile_country_code: state?.PrimaryContact?.mobile_code?.length > 0 ? state?.PrimaryContact?.mobile_code : undefined,
        is_active: state?.status
      };
      if (data?.main?.isEdit) {
        payload["id"] = data?.main?.id;
        payload["deleteAssets"] = deletedAssetURL;
      }
      NetworkCall(
        `${config.api_url}/request/${data?.main?.isEdit === true ? "update" : "create"
        }`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          history.push(Routes?.maintancerequest);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `Maintenance Request ${data?.main?.isEdit === true ? "Updated" : "Created"
              } successfully.`,
          });
        })
        .catch((error) => {
          history.push(Routes?.maintancerequest);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `Maintenance Request ${data?.main?.isEdit === true ? "Updated" : "Created"
              } successfully.`,
          });
        });
    }
  };
  // get details
  const getDetails = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    const payload = {
      query: GET_MAINTANCE_REQUEST,
      variables: {
        generalRequestNo: data?.main?.id,
        maintenanceRequestNo: data?.main?.id,
        referenceId: data?.main?.id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let result = response?.data?.data;
        let image = result?.images?.map((x) => {
          return {
            url: x?.url,
            size: x?.file_meta?.file_size ?? "-",
            name: x?.file_meta?.file_name ?? '-',
            type: x?.file_meta?.file_type ?? "-",
            id: x?.id ?? ""
          }
        })
        setImages(image ?? []);
        setState({
          Category: {
            value: result?.table?.[0]?.catagory?.id ?? "",
            label: result?.table?.[0]?.catagory?.type ?? "",
          },
          subCategory: {
            value: result?.table?.[0]?.subcatagory?.id ?? "",
            label: result?.table?.[0]?.subcatagory?.type ?? "",
          },
          Unit: {
            value: result?.table?.[0]?.unit?.[0].unit?.id ?? "",
            label: result?.table?.[0]?.unit?.[0].unit?.name ?? "",
          },
          Title: result?.table?.[0]?.subject ?? "",
          visitorDate: new Date(result?.table?.[0]?.prefferedtime) ?? "",
          Description: result?.table?.[0]?.description ?? "",
          alternativeContact: {
            mobile: result?.table?.[0]?.alternative ?? "",
            mobile_code: result?.table?.[0]?.mobile_country_code ?? "",
          },
          PrimaryContact: {
            mobile: result?.table?.[0]?.mobile ?? "",
            mobile_code: result?.table?.[0]?.mobile_country_code ?? "",
          },
          Property: {
            value: result?.table?.[0]?.unit?.[0].property?.id ?? "",
            label: result?.table?.[0]?.unit?.[0].property?.name ?? "",
          },
          problemSince: new Date(result?.table?.[0]?.problemsince),
          contactName: result?.table?.[0]?.name ?? "",
          status: result?.table?.[0]?.is_active ?? "",
          workdate:result?.table?.[0]?.work_order_date ? new Date(result?.table?.[0]?.work_order_date) : null,
          vendor:result?.table?.[0]?.vendor_ref ?? "",
          workerStatus:result?.table?.[0]?.work_order_status ? {
            value:result?.table?.[0]?.work_order_status,
            label:result?.table?.[0]?.work_order_status
          } : "",
          workNo: result?.table?.[0]?.work_order_ref_no ?? "",
          error: {
            Category: "",
            subCategory: "",
            Unit: "",
            Title: "",
            visitorDate: "",
            Description: "",
            workNo: "",
            workId: "",
            status: "",
            alternativeContact: "",
            PrimaryContact: "",
            Property: "",
            problemSince: "",
            contactName: "",
            member: "",
          },
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong please try again",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  

  const render = () => {
    return <>
      <Subheader
        title={`${data?.main?.isEdit ? `Edit` : `Create`} Maintenance Request`}
        goBack={() => {
          history.goBack(-1);
        }}
      />
      <Box className={classes.root1}>
        <Grid container spacing={3}>
          {/*  SERVICE DETAILS*/}
          <Grid item xs={12}>
            <div className={classes.card}>
              <CustomTypography>SERVICE DETAILS</CustomTypography>
              <FormGenerator
                components={[
                  {
                    isActive: true,
                    component: "select",
                    label: "Category",
                    value: state?.Category,
                    placeholder: "Category",
                    onChange: (value) => updateState("Category", value),
                    error: state?.error?.Category,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptions(
                        search,
                        array,
                        handleLoading,
                        GET_MAINTENANCE_CATEGORY,
                        "maintenance_category_master",
                        { isActive: true },
                        { label: "label", value: "value" },
                        false,
                        false
                      ),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: "Sub-Category",
                    value: state?.subCategory,
                    placeholder: "Sub-Category",
                    onChange: (value) => updateState("subCategory", value),
                    error: state?.error?.subCategory,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptions(
                        search,
                        array,
                        handleLoading,
                        GET_MAINTENANCE_SUB_CATEGORY,
                        "maintenance_sub_category_master",
                        { isActive: true },
                        { label: "label", value: "value" },
                        false,
                        false,
                        state?.Category?.value ?? ""
                      ),
                    key: JSON.stringify(state?.Category),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: "Property",
                    value: state?.Property,
                    placeholder: "Property",
                    onChange: (value) => updateState("Property", value),
                    error: state?.error?.Property,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    options: properties
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: "Unit",
                    value: state?.Unit,
                    placeholder: "Unit",
                    onChange: (value) => updateState("Unit", value),
                    error: state?.error?.Unit,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptions(
                        search,
                        array,
                        handleLoading,
                        SELECTUNITS,
                        "unit",
                        { isActive: true },
                        { label: "label", value: "value" },
                        false,
                        false,
                        state?.Property?.value
                      ),
                    key: JSON.stringify(state?.Property),
                  },
                  {
                    isActive: true,
                    component: "text",
                    label: "Problem Title",
                    value: state?.Title,
                    placeholder: "Problem Title",
                    onChange: (e) => updateState("Title", e?.target?.value),
                    error: state?.error?.Title,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                  },
                  {
                    isActive: true,
                    component: "datepicker",
                    label: "Problem Since",
                    value: state?.problemSince,
                    placeholder: "Problem Since",
                    onChange: (value) => updateState("problemSince", value),
                    error: state?.error?.problemSince,
                    maxDate: new Date(),
                    isRequired: true,
                    isNot: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                  },
                  {
                    isActive: true,
                    component: "datePickerWithTime",
                    label: "Preferred Visit Date & Time",
                    value: state?.visitorDate,
                    placeholder: "Preferred Visit Date & Time",
                    onChange: (value) => updateState("visitorDate", value),
                    error: state?.error?.visitorDate,
                    minDate: new Date(),
                    isRequired: true,
                    border: '1.5px solid #E4E8EE !important',
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                  },
                  {
                    size: {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 3,
                    },
                    isActive: true,
                    component: "toggle",
                    label: "Status",
                    value: state?.status,
                    placeholder: "Status",
                    onChange: (value) => updateState("status", value),
                    error: state?.error?.status,
                    isRequired: true,
                    options: [
                      { label: "Active", value: true },
                      { label: "Inactive", value: false },
                    ],
                  },
                  {
                    isActive: true,
                    component: "text",
                    label: "Problem Description",
                    value: state?.Description,
                    placeholder: "Problem Description",
                    onChange: (e) =>
                      updateState("Description", e?.target?.value),
                    error: state?.error?.Description,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 6,
                    },
                    isPaginate: true,
                  },
                ]}
              />
            </div>
          </Grid>
                    {/* CONTACT*/}
                    <Grid item xs={12}>
            <div className={classes.card}>
              <CustomTypography>CONTACT</CustomTypography>
              <FormGenerator
                components={[
                  {
                    isActive: true,
                    component: "text",
                    label: "Contact Name",
                    value: state?.contactName,
                    placeholder: "Contact Name",
                    onChange: (e) =>
                      updateState("contactName", e?.target?.value),
                    error: state?.error?.contactName,
                    // isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                  },
                  {
                    isActive: true,
                    component: "mobile",
                    label: "Primary Contact",
                    value: state?.PrimaryContact,
                    placeholder: "Primary Contact",
                    onChange: (value) => updateState("PrimaryContact", value),
                    errorMessage: state?.error?.PrimaryContact,
                    // isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                  },
                  {
                    isActive: true,
                    component: "mobile",
                    label: "Alternate Contact Number",
                    value: state?.alternativeContact,
                    placeholder: "Alternate Contact Number",
                    onChange: (value) =>
                      updateState("alternativeContact", value),
                    errorMessage: state?.error?.alternativeContact,
                    // isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                  },
                ]}
              />
            </div>
          </Grid>
          {/* WORK ORDER (OPTIONAL) */}
          <Grid item xs={12}>
            <div className={classes.card}>
              <CustomTypography>3<sup>rd</sup> PARTY WORK ORDER (OPTIONAL)</CustomTypography>
              <FormGenerator
                components={[
                  {
                    isActive: true,
                    component: "text",
                    label: "Work Order Reference",
                    value: state?.workNo,
                    placeholder: "Work Order Reference",
                    onChange: (e) => updateState("workNo", e?.target?.value),
                    error: state?.error?.workNo,
                    // isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                  },
                  {
                    isActive: true,
                    component: "datepicker",
                    label: "Work Order Date",
                    value: state?.workdate,
                    placeholder: "Work Order Date",
                    onChange: (value) => updateState("workdate", value),
                    error: state?.error?.workdate,
                    // maxDate: new Date(),
                    // isRequired: true,
                    isNot: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: "Vendor",
                    value: state?.vendor,
                    placeholder: "Vendor",
                    onChange: (value) => updateState("vendor", value),
                    error: state?.error?.vendor,
                    // isRequired: true,
                    zIndex: 0,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptions(
                        search,
                        array,
                        handleLoading,
                        SELECT_VENDOR,
                        "vendor_master",
                        { isActive: true ,  client: localStorage.getItem(LocalStorageKeys.clinetID)},
                        { label: "label", value: "value" },
                        false,
                        false
                      ),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: "Work Order Status",
                    value: state?.workerStatus,
                    placeholder: "Work Order Status",
                    onChange: (value) => updateState("workerStatus", value),
                    error: state?.error?.workerStatus,
                    // isRequired: true,
                    options:enumValue?.type ?? [],
                    zIndex: 0,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    }
                  },
                ]}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.card}>
              <CustomTypography>UPLOAD IMAGES</CustomTypography>
              <SingleImgUpload
                setUUID={setUUID}
                uuid={uuid}
                updateImageURL={setImages}
                handleFile={handleAssetFile}
                selectedImageURL={images}
                companyId={1}
              />
            </div>
          </Grid>

          {/* AddMemberCard */}
          {/* <Grid item xs={12}>
            <AddMemberCard
              data={{
                name: "Assign To",
                btnText: "Add Member",
              }}
              selectedMember={state?.member ?? ""}
              updateState={updateState}
            />
          </Grid> */}
        </Grid>
      </Box>

      {/* buttom btn */}
      <Box className={classes.bottombtn}>
        <Box>
          <Button
            className={classes.Cancel}
            onClick={() => {
              history.goBack(-1);
            }}
          >
            Cancel
          </Button>
        </Box>
        {((data?.main?.isEdit && permission?.update) || (!Boolean(data?.main?.isEdit) && permission?.create)) &&
          <Box>
            <Button className={classes.next} onClick={saveMaintenanceRequest}>
              {data?.main?.isEdit ? "Update" : "Submit"}
            </Button>
          </Box>}
      </Box>
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>
  );
};
