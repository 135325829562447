import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import "../../../App.css";
import { AlertDialog, MapFieldsOnly, MobileNumberInputComponent, SearchFilter, SelectBox, TextBox } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { GET_DEPARTMENT_BY_COMPANY_ID, GET_PROPERTY_BY_COMPANY, GET_ROLES_SALESAGENT, GET_USERDETAILS_BY_DEPARTMENT_ID } from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, enumSelect, enum_types, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions } from "../../../utils";
import { useStylesCreation } from "../style";
import { AddMemberCard, Card1, Card } from "./addmembercard";
import { useApolloClient } from "@apollo/client";
import { loadOptions } from "../../../utils/asyncPaginateLoadOptions";
import { GET_ALL_CUNTRIES, GET_VENDOR_LIST } from "../../../graphql/quotationQueries";
import { Bold } from "../../../utils";

const CustomTypography = styled(Typography)(({ theme }) => ({
      fontSize: 12,
      fontFamily: Bold,
      color: theme.typography.color.secondary,
      marginBottom: theme.spacing(1)
}))

// const textBoxStyle = {
//       padding: "8px 14px",
//       borderRadius: 8,
//       height: 47
// }
const list = [
      {
            name: "Reporting To"
      }
]
export const GeneralDetails = (props) => {
      // const { mapLoad } = props;
      const size = useWindowDimensions()
      // const [fullScreenMap, setFullScreenMap] = React.useState(false)
      const classes = useStylesCreation();
      const alert = React.useContext(AlertContext);
      const [loading, setLoading] = React.useState(false)
      const [loading2, setLoading2] = React.useState(false)
      const [imageViwer, setImageViwer] = React.useState(false)
      const [deleteUser, setdeleteUser] = React.useState(false)
      const [selectedID, setSelectedID] = React.useState(null)
      const [open, setOpen] = React.useState(false)
      const [searchdata, setSearchdata] = React.useState("")
      const [offset, setOffset] = React.useState(0)
      const [userDetails, setUserDetails] = React.useState([])
      const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
      const client = useApolloClient();

      const [enumValue, setEnumValue] = React.useState({
            relationship: [],
            method: [],
            emergencyContactRelationship: [],
            sex: []
      })
      const loadOptionss = async (search = "", array, type) => {
            setLoading(type);
            let result, query, offset = 0;

            if (search && !Boolean(array?.length)) {
                  offset = 0;
            }
            else {
                  offset = array?.length;
            }

            switch (type) {
                  case 'properties':
                        if (!props?.data?.company?.value > 0) {
                              setLoading(null);
                              return { options: [] }
                        }
                        query = GET_PROPERTY_BY_COMPANY(offset, 10, search, props?.data?.company?.value).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.property],
                              hasMore: (array?.length + result?.property?.length) < result?.count[0]?.count
                        }
                  case 'otherDepartment':
                        if (!props?.data?.company?.value > 0) {
                              setLoading(null);
                              return { options: [] }
                        }
                        query = GET_DEPARTMENT_BY_COMPANY_ID(offset, 10, search, props?.data?.company?.value, clientId).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.department_master],
                              hasMore: (array?.length + result?.department_master?.length) < result?.count[0]?.count
                        }
                  case 'Department':
                        if (!props?.data?.company?.value > 0) {
                              setLoading(null);
                              return { options: [] }
                        }
                        query = GET_DEPARTMENT_BY_COMPANY_ID(offset, 10, search, props?.data?.company?.value, clientId).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.department_master],
                              hasMore: (array?.length + result?.department_master?.length) < result?.count[0]?.count
                        }
                  case 'job':
                        query = GET_ROLES_SALESAGENT(offset, 10, search).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.roles],
                              hasMore: (array?.length + result?.roles?.length) < result?.count[0]?.count
                        }
                  default:
                        return { options: [] }
            }
      }
      const networkCallBack = async (query, variable = {}) => {

            let payload = {
                  query,
                  variable: variable
            };

            const options = await NetworkCall(
                  `${config.graphql_url}`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        let main = response.data.data;
                        setLoading(null);
                        return main
                  })
                  .catch(() => {
                        setLoading(null);
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Some Thing Went Wrong",
                              vertical: AlertProps.vertical.top,
                              horizontal: AlertProps.horizontal.center,
                        });
                        return null
                  });

            return options
      }
      const getEnum = async () => {
            const result = await enumSelect([
                  enum_types.relationship_type, enum_types.preferred_mode_contact_type,
                  enum_types.emergency_relationship_type, enum_types.sex_type
            ])
            setEnumValue({
                  // relationship: result?.relationship_type.filter(i => ["Company Employee", "Other"]?.includes(i.value)), // old code with filter
                  relationship: result?.relationship_type,
                  method: result?.preferred_mode_contact_type,
                  emergencyContactRelationship: result?.emergency_relationship_type,
                  sex: result?.sex_type
            })
      }
      React.useEffect(() => {
            getEnum()
      }, [])
      // React.useEffect(() => {
      //       document.addEventListener("fullscreenchange", existFullscreen)
      //       return () => {
      //             document.removeEventListener("fullscreenchange", existFullscreen)

      //       }

      // }, [])
      // //exist full screen
      // const existFullscreen = () => {
      //       if (document.fullscreenElement === null) {
      //             setFullScreenMap(false)
      //       }

      // }
      // //open full screen
      // const fullScreenControl = () => {
      //       setFullScreenMap(true)
      //       document.getElementsByClassName('map-wrapper')[0]?.requestFullscreen()

      // }
      // selected users
      const selectUser = (x) => {
            setSelectedID(x)
      }
      // delete member
      const deleteMember = () => {
            props?.updateState('member', {})
            setSelectedID(null)
            setOpen(!open)
      }
      // close Drawer
      const closeDrawer = (v, k) => {

            if (k === 'delete') {
                  setdeleteUser(true)
                  setOpen(!open)
            }
            else if (k === 'add') {
                  setdeleteUser(false)
                  setOpen(!open)
                  props?.updateState('member', {})
            }
            else if (k === 'close') {
                  props?.updateState('member', {})
                  setSelectedID(null)
                  setOpen(!open)
            }
            else if (k === 'deleteclose') {
                  setOpen(!open)
            }
            else {
                  props?.updateState('member', selectedID)
                  setOpen(!open)
            }

      }
      // get user profiles in pop up
      const getUserProfile = (v, offset, search, k) => {
            client.query({
                  query: GET_USERDETAILS_BY_DEPARTMENT_ID,
                  fetchPolicy: 'network-only',
                  variables: {
                        company_id: props?.data?.company?.value,
                        id: v?.value,
                        offset: offset,
                        limit: 10,
                        search: search ?? "",
                        clientID: clientId

                  }
            }).then((res) => {
                  if (k === 'search') {
                        setUserDetails(res?.data?.contact)
                  }
                  else {
                        setUserDetails(userDetails?.concat(res?.data?.contact));
                  }
            }).catch((err) => {

            })
      }
      // // update state of selet job
      const updateState = (k, v) => {
            props?.updateState(k, v);
            if (k === 'otherDepartment') {
                  getUserProfile(v, 0, "", "search")
            }
      }
      // infinity scroll
      const fetchMoreData = () => {
            setOffset(offset + 10);
            getUserProfile(props?.data?.otherDepartment, offset + 10, "", "normal");
      };
      // const search user data
      const handleSearch = (v) => {
            setSearchdata(v)
            getUserProfile(props?.data?.otherDepartment, 0, v, "search")
      }

      const loadOptionData = async (search, array, type) => {
            setLoading2(type);
            if (type === "country") {
                  return loadOptions(search, array, setLoading2, GET_ALL_CUNTRIES, 'country_master');
            } else if (type === "vendor") {
                  return loadOptions(search, array, setLoading2, GET_VENDOR_LIST, 'vendor_master', {companyId:props?.data?.company?.value})
            }

            else {
                  return {
                        options: [],
                        hasMore: false
                  }
            }
      }
      return (
            <div>
                  <Grid container spacing={2}>
                        <Grid item xs={12} md={4} lg={2}>
                              <Box className={classes.imagebox}>
                                    <CustomTypography>
                                          Profile Picture
                                    </CustomTypography>
                                    <Avatar src={props?.data?.profileImage?.length > 0 ? props?.data?.profileImage : "/images/citynew.svg"}
                                          className={props?.data?.profileImage?.length > 0 ? classes.avatar : classes.avatar1} onClick={() => setImageViwer(true)} />
                                    <div>
                                          <label> <Box className={classes.button} variant='contained'>Upload image</Box> <input type='file' className={classes.inputFileStyle} accept="image/*"
                                                onClick={(event) => {
                                                      event.target.value = null;

                                                }}
                                                onChange={(e) => props?.updateimg(e?.target?.files?.[0])} /> </label>
                                    </div>
                                    {props?.data?.profileImage?.length > 0 ? <Typography className={classes.removeimg} onClick={props?.removeimg}>Remove Image</Typography> : props?.data?.error?.profileImage?.length > 0 ? (
                                          <span className={classes.profileImageErrorTextStyle}>
                                                Profile Image is required
                                          </span>
                                    ) : <Box height={"18px"} />}

                              </Box>
                        </Grid>
                        <Grid item xs={12} md={8} lg={10}>
                              <Box className={classes.profileDetailCardStyle}>
                                    <CustomTypography>
                                          Profile Details
                                    </CustomTypography>
                                    <Grid container spacing={3}>
                                          <Grid item xs={12} sm={4} md={3} lg={3}>
                                                {props?.data?.isAllCompany ?
                                                      <SelectBox
                                                            isRequired
                                                            label="Company"
                                                            options={props?.data?.companyList}
                                                            placeholder="Select Company"
                                                            value={props?.data?.company}
                                                            onChange={(value) => {
                                                                  props?.updateState("company", value);
                                                            }}
                                                            isError={props?.data?.error?.company?.length > 0}
                                                            errorMessage={props?.data?.error?.company} /> :
                                                      <TextBox
                                                            // {...textBoxStyle}
                                                            isReadonly
                                                            label="Company Name"
                                                            value={props?.data?.company?.label ?? null} />
                                                }
                                          </Grid>
                                          <Grid item xs={12} sm={4} md={3} lg={props?.type === "Agent" ? 3 : 6}>
                                                <TextBox
                                                      // {...textBoxStyle}
                                                      isrequired
                                                      label="Name"
                                                      placeholder="Enter Name"
                                                      value={props?.data?.name ?? null}
                                                      onChange={(e) => {
                                                            props?.updateState("name", e.target.value);
                                                      }}
                                                      isError={props?.data?.error?.name?.length > 0}
                                                      errorMessage={props?.data?.error?.name} />
                                          </Grid>
                                          <Grid item xs={12} sm={4} md={3} lg={3}>
                                                <TextBox
                                                      // {...textBoxStyle}
                                                      label="Job Title"
                                                      placeholder="Enter Job Title"
                                                      value={props?.data?.jobTitle ?? null}
                                                      onChange={(e) => {
                                                            props?.updateState("jobTitle", e.target.value);
                                                      }} />
                                          </Grid>
                                          <Grid item xs={12} sm={4} md={3} lg={3}>
                                                <TextBox
                                                      // {...textBoxStyle}
                                                      isrequired
                                                      placeholder="Company ID"
                                                      label="Company ID"
                                                      value={props?.data?.employee_id ?? null}
                                                      onChange={(e) => {
                                                            props?.updateState("employee_id", e.target.value);
                                                      }}
                                                      isError={props?.data?.error?.employee_id?.length > 0}
                                                      errorMessage={props?.data?.error?.employee_id} />

                                          </Grid>
                                          <Grid item xs={12} sm={4} md={3} lg={3}>
                                                <SelectBox
                                                      isRequired
                                                      label="Gender"
                                                      options={enumValue?.sex}
                                                      placeholder={"Select Gender"}
                                                      value={props?.data?.sex}
                                                      onChange={(value) => {
                                                            props?.updateState("sex", value);
                                                      }}
                                                      isError={props?.data?.error?.sex?.length > 0}
                                                      errorMessage={props?.data?.error?.sex} />
                                          </Grid>
                                          <Grid item xs={12} sm={4} md={3} lg={3}>
                                                <SelectBox
                                                      isRequired
                                                      label="Relationship"
                                                      options={enumValue?.relationship}
                                                      placeholder={"Select Relationship"}
                                                      value={props?.data?.relationship}
                                                      isReadOnly={props?.type === "Agent"}
                                                      onChange={(value) => {
                                                            props?.updateState("relationship", value);
                                                      }}
                                                      isError={props?.data?.error?.relationship?.length > 0}
                                                      errorMessage={props?.data?.error?.relationship} />
                                          </Grid>

                                          {props?.data?.relationship?.value === "Other" &&
                                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                                      <TextBox
                                                            // {...textBoxStyle}
                                                            isrequired
                                                            label="Enter Relationship"
                                                            placeholder="Enter  Relationship"
                                                            value={props?.data?.otherRelationship ?? null}
                                                            onChange={(e) => {
                                                                  props?.updateState("otherRelationship", e.target.value);
                                                            }}
                                                            isError={props?.data?.error?.otherRelationship?.length > 0}
                                                            errorMessage={props?.data?.error?.otherRelationship} />
                                                </Grid>
                                          }
                                          <Grid item xs={12} sm={4} md={3} lg={3}>
                                                <SelectBox
                                                      label="Nationality"
                                                      placeholder="Enter Nationality"
                                                      value={props?.data?.nationality ?? null}
                                                      isPaginate
                                                      debounceTimeout={800}
                                                      loading={loading2 === "country"}
                                                      loadOptions={(search, array) => loadOptionData(search, array, "country")}
                                                      onChange={(value) => props?.updateState("nationality", value)}
                                                />

                                          </Grid>
                                          {
                                                props?.type === "Agent" &&
                                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                                      <SelectBox
                                                            label="Vendor"
                                                            placeholder="Select Vendor"
                                                            value={props?.data?.vendorlist ?? null}
                                                            isPaginate
                                                            isRequired
                                                            debounceTimeout={800}
                                                            loading={loading2 === "vendor"}
                                                            loadOptions={(search, array) => loadOptionData(search, array, "vendor")}
                                                            onChange={(value) => props?.updateState("vendorlist", value)}
                                                      />
                                                </Grid>
                                          }
                                    </Grid>
                              </Box>
                        </Grid>
                  </Grid>
                  {(props?.data?.relationship?.value === "Company Employee" || props?.data?.relationship?.value === "External Sales Broker")  &&
                        <Box className={classes.socialProfileDetailCardStyle} >
                              <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4} md={3} lg={2}>
                                          <SelectBox
                                                isRequired
                                                loading={loading === "Department"}
                                                isPaginate
                                                debounceTimeout={800}
                                                loadOptions={(search, array) => loadOptionss(search, array, 'Department')}
                                                label="Department"
                                                placeholder="Select Department"
                                                value={props?.data?.Department ?? null}
                                                onChange={(value) => {
                                                      props?.updateState("Department", value);
                                                }}
                                                isError={props?.data?.error?.Department?.length > 0}
                                                errorMessage={props?.data?.error?.Department} />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3} lg={2}>
                                          <div style={{ marginTop: '4px', marginLeft: "18px" }}>
                                                {/* add member card */}
                                                <AddMemberCard isNormal data={list} closeDrawer={closeDrawer} member={props?.data?.member} deleteUser={deleteUser} />

                                                {/* add member dialog */}
                                                <AlertDialog
                                                      isNormal
                                                      header={deleteUser === true ? 'Delete Member' : 'Add Member'}
                                                      onClose={() => closeDrawer({}, deleteUser === true ? 'deleteclose' : 'close')}
                                                      open={open}
                                                      component={
                                                            <>
                                                                  {
                                                                        deleteUser === false &&
                                                                        <div style={{ padding: "24px", }}>
                                                                              <Grid container>
                                                                                    <Grid item xs={12}>
                                                                                          {/* Job */}
                                                                                          <SelectBox

                                                                                                loading={loading === "otherDepartment"}
                                                                                                isPaginate
                                                                                                debounceTimeout={800}
                                                                                                loadOptions={(search, array) => loadOptionss(search, array, 'otherDepartment')}
                                                                                                label="Department"
                                                                                                placeholder="Select Department"
                                                                                                value={props?.data?.otherDepartment ?? null}
                                                                                                onChange={(value) => {
                                                                                                      updateState("otherDepartment", value);
                                                                                                }}
                                                                                                isError={props?.data?.error?.otherDepartment?.length > 0}
                                                                                                errorMessage={props?.data?.error?.otherDepartment} />
                                                                                    </Grid>
                                                                              </Grid>
                                                                        </div>
                                                                  }

                                                                  <div style={{
                                                                        textAlign: "center",
                                                                        padding: "24px",
                                                                  }}>


                                                                        <div id={'infinity'}>
                                                                              {/* search box */}

                                                                              {
                                                                                    deleteUser === false &&
                                                                                    <div style={{ marginBottom: '24px' }}>
                                                                                          <SearchFilter
                                                                                                value={searchdata}
                                                                                                handleChange={(value) => handleSearch(value)}
                                                                                                placeholder="Search Member Details"
                                                                                          />
                                                                                    </div>
                                                                              }


                                                                              <InfiniteScroll
                                                                                    dataLength={userDetails?.length ?? null}
                                                                                    next={fetchMoreData}
                                                                                    hasMore={true}
                                                                                    height={size?.height - 500}
                                                                              >
                                                                                    {
                                                                                          deleteUser === true ? <Card1 member={props?.data?.member} deleteMember={deleteMember} /> : <Card data={userDetails} selectUser={selectUser} select={selectedID} />
                                                                                    }

                                                                              </InfiniteScroll>



                                                                        </div>
                                                                        {/* add member button */}
                                                                        {
                                                                              deleteUser === false && <Button variant='contained' className={classes.buttoncard} onClick={closeDrawer}> Add Member</Button>
                                                                        }
                                                                  </div>
                                                            </>
                                                      }
                                                />
                                          </div>
                                    </Grid>
                              </Grid>
                        </Box>
                  }
                  <div className='map-wrapper'>
                        <Box className={classes.addressDetailCardStyle} >
                              <CustomTypography>Address</CustomTypography>
                              {
                                    <MapFieldsOnly

                                          fields={[
                                                {
                                                      label: "Door Number",
                                                      component: "TextField",
                                                      value: props?.data?.doorNo,
                                                      state_name: 'doorNo',
                                                      isrequired: false,
                                                      placeholder: "Enter Door Number",
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: "Address Line 1",
                                                      component: "TextField",
                                                      value: props?.data?.addressLineOne,
                                                      state_name: 'addressLineOne',
                                                      isrequired: false,
                                                      placeholder: "Enter Address Line 1",
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: "Address Line 2",
                                                      component: "TextField",
                                                      value: props?.data?.addressLineTwo,
                                                      state_name: 'addressLineTwo',
                                                      isrequired: false,
                                                      placeholder: "Enter Address Line 2",
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: "Landmark",
                                                      component: "TextField",
                                                      value: props?.data?.landmark,
                                                      state_name: 'landmark',
                                                      isrequired: false,
                                                      placeholder: "Enter Landmark",
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: "Area",
                                                      component: "TextField",
                                                      value: props?.data?.area,
                                                      state_name: 'area',
                                                      isrequired: false,
                                                      placeholder: "Enter Area",
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: "City",
                                                      component: "TextField",
                                                      value: props?.data?.city,
                                                      state_name: 'city',
                                                      isrequired: false,
                                                      placeholder: "Enter City",
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: "State",
                                                      component: "TextField",
                                                      state_name: 'state',
                                                      value: props?.data?.state,
                                                      isrequired: false,
                                                      placeholder: "Enter State",
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: "Country",
                                                      component: "TextField",
                                                      value: props?.data?.country,
                                                      state_name: 'country',
                                                      isrequired: false,
                                                      placeholder: "Enter Country",
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                                {
                                                      label: "Pincode",
                                                      component: "TextField",
                                                      state_name: 'zipcode',
                                                      value: props?.data?.zipcode,
                                                      isrequired: false,
                                                      placeholder: "Enter Pincode",
                                                      breakpoints: { sm: 6, md: 3, lg: 2 }
                                                },
                                          ]}
                                          onChangeFields={(key, value) => props?.updateState(key, value)}
                                    />
                              }

                        </Box>
                  </div>
                  <Box className={classes.contactAndOtherDetailCardStyle} >
                        <CustomTypography>
                              Contact & Other Information
                        </CustomTypography>
                        <Grid container spacing={3}>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <SelectBox
                                          label="Preferred Method Of Contact"
                                          placeholder="Select Method"
                                          options={enumValue?.method}
                                          value={props?.data?.preferredModeContact ?? null}
                                          onChange={(value) => {
                                                props?.updateState("preferredModeContact", value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <MobileNumberInputComponent
                                          isRequired
                                          label={"Telephone Number"}
                                          placeholder={"Enter Telephone Number"}
                                          value={props?.data?.primaryTelephone}
                                          handleChange={(value) => {
                                                props?.updateState("primaryTelephone", value);
                                          }}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                          isError={props?.data?.error?.primaryTelephone?.length > 0}
                                          errorMessage={props?.data?.error?.primaryTelephone} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <MobileNumberInputComponent
                                          isRequired
                                          label={"Mobile Number"}
                                          placeholder={"Enter Mobile Number"}
                                          value={props?.data?.primaryMobile}
                                          handleChange={(value) => {
                                                props?.updateState("primaryMobile", value);
                                          }}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                          isError={props?.data?.error?.primaryMobile?.length > 0}
                                          errorMessage={props?.data?.error?.primaryMobile} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <TextBox
                                          // {...textBoxStyle}
                                          label="Email Address"
                                          isrequired
                                          placeholder="Enter Email Address"
                                          value={props?.data?.primaryEmail ?? null}
                                          onChange={(value) => {
                                                props?.updateState("primaryEmail", value?.target?.value);
                                          }}
                                          isError={props?.data?.error?.primaryEmail?.length > 0}
                                          errorMessage={props?.data?.error?.primaryEmail} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <TextBox
                                          // {...textBoxStyle}
                                          label="Emergency Contact Name"
                                          placeholder="Enter Name"
                                          value={props?.data?.emergencyContactName ?? null}
                                          onChange={(value) => {
                                                props?.updateState("emergencyContactName", value?.target?.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    {/* <TextBox
                                          // {...textBoxStyle}
                                          label="Emergency Contact Relationship"
                                          placeholder="Enter Relationship"
                                          value={props?.data?.emergencyContactRelationship ?? null}
                                          onChange={(value) => {
                                                props?.updateState("emergencyContactRelationship", value?.target?.value);
                                          }} /> */}
                                    <SelectBox
                                          label="Emergency Contact Relationship"
                                          placeholder="Select Relationship"
                                          options={enumValue?.emergencyContactRelationship}
                                          value={props?.data?.emergencyContactRelationship ?? null}
                                          onChange={(value) => {
                                                props?.updateState("emergencyContactRelationship", value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <MobileNumberInputComponent
                                          label={"Emergency Contact Number"}
                                          placeholder={"Enter Number"}
                                          value={props?.data?.emergencyContactNumber}
                                          handleChange={(value) => {
                                                props?.updateState("emergencyContactNumber", value);
                                          }}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }} />
                              </Grid>
                        </Grid>
                  </Box>
                  <Box className={classes.socialProfileDetailCardStyle} >
                        <CustomTypography>Social Profile</CustomTypography>
                        <Grid container spacing={3}>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <TextBox
                                          label="Whatsapp"
                                          placeholder="Enter Whatsapp Number"
                                          type={"number"}
                                          value={props?.data?.whatsApp ?? null}
                                          onChange={(e) => {
                                                props?.updateState("whatsApp", e.target.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <TextBox
                                          label="Facebook"
                                          placeholder="Enter Facebook"
                                          value={props?.data?.facebook ?? null}
                                          onChange={(e) => {
                                                props?.updateState("facebook", e.target.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <TextBox
                                          // {...textBoxStyle}
                                          label="Twitter"
                                          placeholder="Enter Twitter"
                                          value={props?.data?.twitter ?? null}
                                          onChange={(e) => {
                                                props?.updateState("twitter", e.target.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <TextBox
                                          // {...textBoxStyle}
                                          label="Linkedin"
                                          placeholder="Enter Linkedin"
                                          value={props?.data?.linkedin ?? null}
                                          onChange={(e) => {
                                                props?.updateState("linkedin", e.target.value);
                                          }} />
                              </Grid>
                        </Grid>
                  </Box>

                  {/* image viewer */}
                  <AlertDialog isNormal isnotTitle component={
                        <div className={classes.imageViewerWrapperStyle}>
                              <img src={props?.data?.profileImage} alt='' className={classes.imageViewerImageStyle} />
                        </div>
                  } open={imageViwer} onClose={() => setImageViwer(false)} />
            </div>
      )
}
