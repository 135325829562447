import { Box, Grid } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { SearchFilter, TableWithPagination } from '../../components'
import { AmenitiesBookingTableDataType, AmenitiesBookingTableHeading, AmenitiesBookingTablePath } from '../../utils'
const AmenitiesBookingTable = (
    {
        handleTableIcon = () => false,
        searchText = "",
        handleSearchTextChange = () => false,
        amenityBooking = {},
        permission = {},
        page = {},
        limit = {},
        handlePagination = () => false,
        handleChangeLimit = () => false,
        t
    }
) => {
    const Heading = AmenitiesBookingTableHeading(t)
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <SearchFilter
                        placeholder={t("SearchBooking")}
                        value={searchText}
                        handleChange={(value) => handleSearchTextChange(value)} />
                </Grid>
            </Grid>
            <TableWithPagination
                heading={Heading}
                rows={amenityBooking?.list}
                path={AmenitiesBookingTablePath}
                showpagination={true}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                handleIcon={handleTableIcon}
                onClick={() => false}
                tableType="no-side"
                dataType={AmenitiesBookingTableDataType}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={amenityBooking?.totalCount}
                page={page}
                limit={limit}
                height={'calc(100vh - 340px)'}
                view={permission.read}
                edit={permission.update}
                delete={permission.delete} />
        </Box>
    )
}

export default withNamespaces("amenityBooking")(AmenitiesBookingTable)
