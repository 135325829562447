import makeStyles from "@mui/styles/makeStyles";
export const UserManageMentStylesParent = makeStyles((theme) => ({
      tab: {
            background: '#fff',
            '&.css-13xfq8m-MuiTabPanel-root': {
                  padding: '0px !important',
            },
      },
      root: {
            margin: '14px',
            borderRadius: '4px',
            background: '#fff',
            height: `calc(100vh - 200px)`,
            overflow:'auto',
            padding: '0px',
      },
      rootSection:{
            padding: '20px'
      }
}))