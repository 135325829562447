import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, SemiBold } from "../../../../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        overflow: "hidden",
        marginTop: "14px"
    },
    content: {
        textAlign: "center"
    },
    filterButton: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    button: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize: "14px"
    },
    addAccessGateDialog: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
            width: "320px",
        },
    },
    addAccessGateDialogHeader: {
        padding: "16px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E4E8EE"
    },
    addAccessGateDialogHeaderTitle: {
        fontFamily: ExtraBold,
        fontSize: "16px",
        color: "#091B29"
    },
    addAccessGateDialogCloseButton: {
        padding: "0px"
    },
    addAccessGateDialogBody: {
        padding: "24px",
        overflowY: "overlay"
    },
    addAccessGateDialogFooter: {
        display: "flex",
        columnGap: "12px",
        padding: "24px",
    },
    addAccessGateDialogFooterButton: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
        fontSize: "14px",
        fontFamily: Bold,
        color: "white"
    },
    addAccessGateDialogFooterCloseButton: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        fontSize: "14px",
        fontFamily: Bold,
        color: "#5078E1",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#E4E8EE ",
        },
    },
    addAccessGateDialogFieldLabel: {
        color: "#98A0AC",
        fontFamily: SemiBold,
        fontSize: "14px",
        marginBottom: "5px",
    },
    addAccessGateDialogButtonContainer: {
        display: "flex",
        rowGap: "8px",
        columnGap: "8px",
    },
    addAccessGateDialogButtonSelected: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        backgroundColor: "#5078E1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#5078E1 ",
        },
        fontSize: "14px",
        fontFamily: Bold,
        color: "white",
    },
    addAccessGateDialogButtonUnSelected: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#E4E8EE ",
        },
        fontSize: "12px",
        fontFamily: SemiBold,
        color: "#98A0AC",
    },
}));