import { Box } from "@mui/material";
import { ProofComponent } from "../../../components";
import { useStyles } from "../styles";
export const Proof = ({ update = () => false, data = [] }) => {
    const classes = useStyles()

    return (
        <Box marginTop="16px" className={classes.box}>
            <ProofComponent file={data} update={update} />
        </Box>
    )
}