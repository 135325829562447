/* eslint-disable array-callback-return */
import {
    Grid
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory } from "react-router-dom";
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from '../../router/routes';
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";
import { Bold } from '../../utils';
const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
        margin: "14px"
    },
    content: {
        textAlign: "center",
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
    },
    text: {
        fontSize: "16px",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    subText: {
        fontSize: "12px",
        fontFamily: Bold,
        color: theme.typography.color.secondary
    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "10px"
    },
    searchdrop: {
        borderRadius: "10px",
        border: "1px solid #E4E8EE",
        cursor: "pointer",
    },
    chip: {
        backgroundColor: "#F2F4F7",
        marginLeft: "6px",
        marginTop: "10px",
        borderRadius: theme.palette.borderRadius,
        fontSize: "14px",
        cursor: "pointer",
        border: "none",
        boxShadow: "none",
        color: theme.typography.color.secondary,
        "&:hover": {
            border: "none",
            boxShadow: "none",
        },
        display: "flex",
        alignItems: "center",
    },
    Label: {
        color: theme.typography.color.secondary,
        fontSize: "12px",
        marginTop: "6px",
    },
    UnitStatusactive: {
        padding: "10px 16px",
        textAlign: "center",
        borderRadius: theme.palette.borderRadius,
        color: "white",
        fontSize: "14px",
        backgroundColor: "#5078E1",
        cursor: "pointer",
    },
    UnitStatus: {
        padding: "10px 16px",
        textAlign: "center",
        borderRadius: theme.palette.borderRadius,
        fontSize: "14px",
        cursor: "pointer",
        backgroundColor: "#F2F4F7",
    },
    previousbtn: {
        backgroundColor: "white",
        color: "#091B29",
        border: "1px solid #CED3DD",
        borderRadius: "10px",
        padding: "12px 8px",
        boxShadow: "none",
    },
    submitbtn: {
        borderRadius: "10px",
        padding: "12px 8px",
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
            maxHeight: `calc(100% - 22px)`
        },
    },
    drawerHeaderStyle: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
    },
    drawerHeaderTextStyle: {
        fontSize: "16px",
        fontWeight: "bolder",
        color: theme.typography.color.primary,
    },
}))

export const ResidentTable = props => {
    const history = useHistory();
    const classes = useStyles();
    const [drawer, setDrawer] = React.useState(false);
    const [residentListTable, setResidentListTable] = React.useState([]);
    const [residentSearch, setResidentSearch] = React.useState("");
    const [offset] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const backdrop = React.useContext(BackdropContext);
    const [count, setCount] = React.useState("");
    const [selectedCompany, setSelectedCompany] = React.useState({})


    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);

    const [filterData, setFilterData] = React.useState({ company: [] });
    const [companyList, setCompanyList] = React.useState([]);
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);
    //open drawer
    // const draweropen = () => {
    //     setDrawer(true);
    // };
    //close drawer
    const drawerclose = () => {
        setDrawer(false);
    };

    //residents
    const getResidents = (offset, limit, v) => {
        let filterid = []
        let companyId = [selectedCompany?.value]
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "",
        });
        if (filterid?.length > 0) {

            setResidentSearch(v)

            const payload = {
                tenantId: `${config.tenantId}`,
                type: "Resident",
                company_id: companyId,
                offset: offset,
                limit: limit,
                search: v
            };
            NetworkCall(
                `${config.api_url}/owner-resident/getAllResidents`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {

                    let arry = [];
                    if (response?.data?.data?.list && response?.data?.data?.list?.length > 0) {
                        response.data.data?.list?.map((val, index) => {

                            let obj = {
                                sno: (offset ?? 0) + index + 1,
                                index: offset + index + 1,
                                residentName: `${val?.first_name
                                    } ${""}${val?.last_name ? val?.last_name : " "}`,
                                userNo: val?.user_no ? val?.user_no : " - ",
                                phoneNumber: val?.mobile_no_country_code + val?.mobile_no ? val?.mobile_no_country_code + " - " + val?.mobile_no : " -",
                                emailId: val?.email_id ? val?.email_id : " - ",
                                totalAgreements: val?.agreement_count ? val?.agreement_count : "-",
                                companyName: val?.company_name ? val?.company_name : " - ",
                                icon: "",
                                ...val,
                            };
                            arry.push(obj);
                        });
                    }

                    setResidentListTable(arry)
                    setCount(response?.data?.data?.count)
                    // selectedFilter.company = filterid?.company;
                    // setSelectedFilter({ ...selectedFilter });
                    backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                    });
                })
                .catch((error) => {
                    backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                    });
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong Please try again",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                    })
                });

        } else {
            setResidentSearch(v)


            const payload = {
                tenantId: `${config.tenantId}`,
                type: "Resident",
                company_id: companyId,
                offset: offset,
                limit: limit,
                search: v
            };
            NetworkCall(
                `${config.api_url}/owner-resident/getAllResidents`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {

                    let arry = [];
                    if (response?.data?.data?.list && response?.data?.data?.list?.length > 0) {
                        response.data.data?.list?.map((val, index) => {

                            let obj = {
                                sno: (offset ?? 0) + index + 1,
                                index: offset + index + 1,
                                residentName: `${val?.first_name
                                    } ${""}${val?.last_name ? val?.last_name : " "}`,
                                userNo: val?.user_no ? val?.user_no : " - ",
                                phoneNumber: val?.mobile_no_country_code + val?.mobile_no ? val?.mobile_no_country_code + " - " + val?.mobile_no : " -",
                                emailId: val?.email_id ? val?.email_id : " - ",
                                totalAgreements: val?.agreement_count ? val?.agreement_count : "-",
                                companyName: val?.company_name ? val?.company_name : " - ",
                                icon: "",
                                ...val,
                            };
                            arry.push(obj);
                        });
                    }
                    setResidentListTable(arry)
                    setCount(response?.data?.data?.count)
                    // selectedFilter.company = tempCompany?.map((item) => { return item?.value });
                    // setSelectedFilter({ ...selectedFilter });
                    backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                    });
                })
                .catch((error) => {
                    backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                    });
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                    })
                });
        }
    };

    //residents


    React.useEffect(() => {
        if (selectedCompany?.value) {
            getResidents(offset, limit, residentSearch);
        }
        // eslint-disable-next-line
    }, [selectedCompany]);
    const ResidentTablepath = ["sno", "residentName", "userNo", "phoneNumber", "emailId", "totalAgreements", "companyName"]

    const ResidentTableheading = [
        { title: "S No", field: "sno", },
        { title: "Resident Name", field: 'residentName', },
        { title: "User No", field: "userNo", },
        { title: "Phone Number", field: 'phoneNumber', },
        { title: "Email Id", field: 'emailId', },
        { title: "Total Agreements", field: 'totalAgreements', },
        { title: "Company Name", field: 'companyName', },
    ]

    //pagination

    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getResidents(offset, limit, residentSearch);
    };
    //page change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getResidents(0, value, residentSearch);
    };

    const handleIcon = (type, data) => {
        if (type === "view") {
            history.push(Routes.residentTableDetail + "?userId=" + data?.id + "&companyId=" + data?.company_id + "&topNavBarTitle=" + data?.first_name)
        }
    }

    //apply filter
    const onApplyFilter = (value) => {
        setFilterData(value)
        getResidents(offset, limit, residentSearch)

    }
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }

    const render = () => {
        return <>
            <Subheader hideBackButton={true} title="Residents" select options={companyList} value={selectedCompany}
                goBack={() => {
                    history.push(Routes.home)
                }}
                onchange={(e) => {
                    handleCompanyChange(e)
                }

                } />
            <div className={classes.root}>
                <Grid container className={classes.content} spacing={2}>
                    <Grid item xs={4}>
                        <SearchFilter placeholder={"Search by resident name"} value={residentSearch} handleChange={(value) => getResidents(offset, limit, value, "key")} />
                    </Grid>
                    <Grid item xs={12} marginTop="-14px">
                        <TableWithPagination
                            heading={ResidentTableheading}
                            rows={residentListTable}
                            path={ResidentTablepath}
                            showpagination={true}
                            onClick={() => false}
                            showpdfbtn={false}
                            handleIcon={handleIcon}
                            showexcelbtn={false}
                            showSearch={false}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={count}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 290px)'}
                            dataType={[
                                { type: ["text"], name: "sno" },
                                { type: ["text"], name: "residentName" },
                                { type: ["text"], name: "userNo" },
                                { type: ["text"], name: "phoneNumber" },
                                { type: ["text"], name: "emailId" },
                                { type: ["text"], name: "totalAgreements" },
                                { type: ["text"], name: "companyName" },
                            ]}
                            view={permission?.read}
                            edit={permission?.update}
                            delete={permission?.delete} />
                    </Grid>
                </Grid>
                {/* filter drawer */}
                {
                    drawer &&
                    <FilterGenerator
                        open={drawer}
                        onClose={drawerclose}
                        components={[
                            {
                                component: "select",
                                value: filterData?.company,
                                options: companyList ?? [],
                                isMulti: true,
                                label: "Company ",
                                state_name: "company",
                            },
                        ]}
                        onApply={(value) => onApplyFilter(value)}
                    />
                }
            </div>
        </>
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div>
}