import { Box, Button } from "@mui/material";
import React from "react";
import { SelectBox } from "../select";
import { TextBox } from "../textbox";
import { ProductUpdateStyles } from "./style";
export const ProductUpdate = ({ onUpdate = () => false, onClose = () => false, inspection_item_map = "", itemConditionOptions = [] }) => {
  const classes = ProductUpdateStyles()
  console.log(inspection_item_map)
  const [details, setDetails] = React.useState({
    modalNumber: "",
    manfacturerName: "",
    serialNumber: "",
    itemCondition: "",
    error: {
      modalNumber: "",
      manfacturerName: "",
      serialNumber: "",
      itemCondition: "",
    }
  });
  React.useEffect(() => {
    setDetails(
      {
        ...details,
        modalNumber: inspection_item_map?.model_number ?? "",
        manfacturerName: inspection_item_map?.manufaturer_name ?? "",
        serialNumber: inspection_item_map?.serial_number ?? "",
        itemCondition: inspection_item_map?.item_condition?.length > 0 ? { value: inspection_item_map?.item_condition, label: inspection_item_map?.item_condition } : ""
      }
    )
    //eslint-disable-next-line
  }, [])
  const validateForm = () => {
    let isValid = true;
    let error = details.error;

    if (details?.modalNumber?.length === 0) {
      isValid = false;
      error.modalNumber = "Modal Number is required"
    }
    if (details?.manfacturerName?.length === 0) {
      isValid = false;
      error.manfacturerName = "Manufacturer Name is required";
    }
    if (details?.serialNumber?.length === 0) {
      isValid = false;
      error.serialNumber = "Serial Number is required";
    }
    if (details?.itemCondition?.length === 0) {
      isValid = false;
      error.itemCondition = "Item Condition is required";
    }
    setDetails({ ...details, error });
    return isValid;
  };
  const onSubmit = () => {
    if (validateForm()) {
      let payload = {
        model_number: details?.modalNumber,
        manufaturer_name: details?.manfacturerName,
        serial_number: details?.serialNumber,
        item_condition: details?.itemCondition?.value
      }
      onUpdate("update", payload)
    }
  }

  const updateDetails = (k, v) => {
    let error = details.error;
    error[k] = "";
    setDetails({ ...details, [k]: v, error })
  }
  return (
    <>
      <Box >
        <Box>

          <TextBox
            label="Modal Number"
            placeholder="Enter Modal Number"
            onChange={(val) => updateDetails("modalNumber", val.target.value)}
            value={details?.modalNumber ?? ""}
            isrequired={true}
            isError={details?.modalNumber.length < 0 ? false : true}
            errorMessage={details?.error?.modalNumber}

          />


        </Box>
        <Box mt={2}>

          <TextBox
            label="Manufacturer Name"
            placeholder="Enter Manufacturer Name"
            onChange={(val) => updateDetails("manfacturerName", val.target.value)}
            value={details?.manfacturerName ?? ""}
            isrequired={true}
            isError={details?.manfacturerName.length > 0 ? false : true}
            errorMessage={details?.error?.manfacturerName}
          />


        </Box>
        <Box mt={2}>

          <TextBox
            label="Serial Number"
            placeholder="Enter Serial Number"
            onChange={(val) => updateDetails("serialNumber", val.target.value)}
            value={details?.serialNumber ?? ""}
            isrequired={true}
            isError={details?.serialNumber.length > 0 ? false : true}
            errorMessage={details?.error?.serialNumber}
          />


        </Box>
        <Box mt={2}>
          <SelectBox
            label={"Item Condition"}
            placeholder={"Select Item Condition"}
            onChange={(val) => updateDetails("itemCondition", val)}
            options={itemConditionOptions}
            value={details?.itemCondition ?? ""}
            isRequired={true}
            isError={details?.itemCondition.length > 0 ? false : true}
            errorMessage={details?.error?.itemCondition}
            menuPlacement={"top"}
          />
        </Box>
      </Box>
      <Box className={classes.fixbtn} mt={4}>
        <Button fullWidth onClick={onSubmit} disabled={details?.length === 0} variant="contained">Update</Button>
      </Box>

    </>
  );
};
