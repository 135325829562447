import CloseIcon from "@mui/icons-material/Close";
import TopicSharpIcon from "@mui/icons-material/TopicSharp";
import {
  Box,
  Button,
  Dialog, Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  MasterUploadModal,
  SearchFilter,
  TableWithPagination,
  UploadReports
} from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import {
  GET_BLOCK_BY_PROPERTY_ID as queryTwo,
  GET_PROPERTY_DETAILS_BY_ID as queryOne
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, ExtraBold, NetWorkCallMethods, SemiBold } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Bold } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    height: `calc(100vh - 88px)`,
    border: "2px solid white",
    borderRadius: "12px",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "4px",
    overflow: "hidden",
  },

  btn: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px 12px",
  },
  btn2: {
    borderRadius: theme.palette.borderRadius,
    color: theme.palette.secondary.main,
    padding: "8px 12px",
  },
  filter: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "10px",
  },
  text: {
    fontSize: "24px",
    fontFamily: Bold,
    color: theme.typography.color.primary,
  },
  subText: {
    fontSize: "16px",
    fontFamily: SemiBold,
    color: theme.typography.color.secondary,
  },
  bottomTitle: {
    fontSize: "18px",
    fontFamily: ExtraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
  },
  contentBottom: {
    // padding: "12px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  grid: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.palette.borderRadius,
    padding: "4px",
  },
  title: {
    fontSize: "14px",
    fontFamily: Bold,
    color: theme.typography.color.primary,
  },
  subTitle: {
    fontSize: "12px",
    fontFamily: SemiBold,
    color: theme.palette.primary.main,
  },
}));

export const PropertyDetails = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const alert = React.useContext(AlertContext);
  const [showDrawer, setShowDrawer] = React.useState(false);

  const [blockList, setBlockList] = React.useState([]);
  const search = useLocation().search;
  const size = useWindowDimensions();
  const [searchText, setSearchText] = React.useState("");
  const [isFilter, setIsFilter] = React.useState(false);
  const companyID = new URLSearchParams(search).get("companyID");
  const propertyId = new URLSearchParams(search).get("propertyID");
  const [uploadModal, setUploadModal] = React.useState(false);
  const getPropertyById = () => {
    const payload = { query: queryOne, variables: { propertyID: propertyId } };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false).then((response) => {
        setBlockList(response.data.data.property[0].block);
        setIsFilter(true);
      }).catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong please try again",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        });
      });
  };
  const searchFilter = () => {
    const payload = { query: queryTwo, variables: { propertyID: propertyId, searchText: searchText } };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false).then((response) => {
        setBlockList(response.data.data.block);
      }).catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong please try again",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        });
      });
  };
  React.useEffect(() => {
    getPropertyById();
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    if (isFilter) {
      searchFilter();
    }
    // eslint-disable-next-line
  }, [searchText]);
  const handleIcon = (type, data) => {

    if (type === "edit") {
      history.push(Routes.addBlock + "?companyID=" + companyID + "&propertyId=" + propertyId + "&blockId=" + data?.id + "&topNavBarTitle=" + data?.blockName);
    } else if (type === "view") {
      history.push(Routes.blockDetails + "?companyID=" + companyID + "&propertyId=" + propertyId + "&blockId=" + data?.id + "&topNavBarTitle=" + data?.blockName);
    }
  };
  const BlockRow = blockList?.map((val, index) => {
    let _d;
    try {
      _d = {
        blockNo: val.block_no,
        blockName: val.name,
        leasableArea: val.carpet_area,
        totalArea: val.total_area,
        floorhierarchy: val.use_floor_hierarchy === true ? "Yes" : "No",
        icon: "editview",
        id: val.id,
      };
    } catch (err) {

    }
    return _d;
  });
  const BlockPath = [
    "blockNo",
    "blockname",
    "leasablearea",
    "totalarea",
    "floorhierarchy",
  ];

  const BlockHeading = [
    { title: "Block No", field: "blockNo", type: "numeric" },
    { title: "Block Name", field: "date" },
    { title: "Leasable Area", field: "location" },
    { title: "Total Area", field: "blocks" },
    { title: "Floor Hierarchy", field: "floors" },
    { title: "", field: "icon" },
  ];
  const handleAddABlockClick = () => {
    history.push(Routes.addBlock + "?companyID=" + companyID + "&propertyId=" + propertyId);
  };

  const handleUploadModal = (val, type) => {
    // selected tab master name
    if (type === "refresh") {
      getPropertyById();
      setUploadModal(!uploadModal)
    } else {
      setUploadModal(!uploadModal)
    }
  }
  return (
    <div className={classes.root}>
      <Grid container>

        <Grid item xs={12}>
          {BlockRow.length > 0 ? (
            <>
              <div
                style={{
                  padding: "12px",
                  height: size.height - 115,
                  overflow: "scroll",
                }}
              >
                <Grid container className={classes.content} spacing={1}>
                  <Grid item xs={4}>
                    <SearchFilter
                      value={searchText}
                      handleChange={(value) => setSearchText(value)}
                    />
                  </Grid>
                  <Grid item xs={8} textAlign={"right"}>
                    <Box
                      display={"flex"}
                      sx={{ float: "right" }}
                      alignItems="center"
                    >
                      &nbsp; &nbsp; &nbsp;
                      <Box>
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary"
                          className={classes.btn2}
                          onClick={handleUploadModal}>
                          Add Bulk Blocks
                        </Button>
                      </Box>
                      &nbsp; &nbsp; &nbsp;
                      <Box>
                        <Button
                          size="small"
                          variant="contained"
                          className={classes.btn}
                          onClick={handleAddABlockClick}
                        >
                          Add a Blocks
                        </Button>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} marginTop="-4px">
                    <TableWithPagination
                      heading={BlockHeading}
                      rows={BlockRow}
                      path={BlockPath}
                      showpagination={BlockRow.length > 11 ? true : false}
                      count="2"
                      showpdfbtn={false}
                      showexcelbtn={false}
                      showSearch={false}
                      handleIcon={handleIcon}
                      tableType="no-side"
                      onClick={() => console.log("")}
                      dataType={[
                        { type: ["text"], name: "blockNo" },
                        { type: ["text"], name: "blockName" },
                        { type: ["text"], name: "leasableArea" },
                        { type: ["text"], name: "totalArea" },
                        { type: ["text"], name: "floorhierarchy" },
                        { type: ["icon"], icon: "icon" },
                      ]}
                      view={true}
                      edit={true}
                      delete={true} />
                  </Grid>
                </Grid>
              </div>
            </>
          ) : (
            <Grid conatiner>
              <Grid item xs={12}>
                <img src="/images/propertybg.svg" alt="pp" />
                <Typography className={classes.text}>No Block Found</Typography>
                <Typography className={classes.subText}>
                  You Can Just, Create New Block
                </Typography>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  className={classes.btn}
                  onClick={handleAddABlockClick}
                >
                  Add A Block
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.btn2}
                  onClick={handleUploadModal}>
                  Bulk Upload The Data With Our Excel Template
                </Button>
                <br />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* Drawer */}
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          open={showDrawer}
          anchor="bottom"
          onClose={() => setShowDrawer(false)}
        >
          <div className={classes.contentBottom}>
            <Box
              display="flex"
              p={2}
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>
                  Reset Password
                </Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setShowDrawer(false)}>
                  <CloseIcon color="secondary" />
                </IconButton>
              </Box>
            </Box>
            <Grid container>
              <Grid
                item
                xs={6}
                sx={{ padding: "16px", borderRight: "1px solid #c1c1c1" }}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  className={classes.grid}
                >
                  <Box>
                    <TopicSharpIcon />
                  </Box>

                  <Box flexGrow={1} marginLeft={"6px"}>
                    <Typography className={classes.title}>
                      Bulk Upload Template
                    </Typography>
                    <Typography className={classes.subTitle}>
                      Download
                    </Typography>
                  </Box>
                </Box>
                <br />
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  className={classes.grid}
                >
                  <Box>
                    <TopicSharpIcon />
                  </Box>

                  <Typography className={classes.title}>
                    Bulk Asset Manager
                  </Typography>
                  <Typography className={classes.subTitle}>
                    Go to Link
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sx={{ padding: "12px" }}>
                <UploadReports />
              </Grid>
            </Grid>
          </div>
        </Drawer>
      </Hidden>
      {/* Dialog */}
      <Hidden smDown>
        <Dialog fullWidth={true} maxWidth="sm" open={showDrawer}>
          <div className={classes.contentBottom}>
            <Box
              display="flex"
              p={1}
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>
                  Add Bulk Property
                </Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setShowDrawer(false)}>
                  <CloseIcon color="secondary" />
                </IconButton>
              </Box>
            </Box>
            <Grid container>
              <Grid
                item
                xs={6}
                sx={{ padding: "16px", borderRight: "1px solid #c1c1c1" }}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  className={classes.grid}
                >
                  <Box>
                    <TopicSharpIcon />
                  </Box>

                  <Box flexGrow={1} marginLeft={"6px"}>
                    <Typography className={classes.title}>
                      Bulk Upload Template
                    </Typography>
                    <Typography className={classes.subTitle}>
                      Download
                    </Typography>
                  </Box>
                </Box>
                <br />
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  className={classes.grid}
                >
                  <Box>
                    <TopicSharpIcon />
                  </Box>

                  <Box flexGrow={1} marginLeft={"6px"}>
                    <Typography className={classes.title}>
                      Bulk Asset Manager
                    </Typography>
                    <Typography className={classes.subTitle}>
                      Go to Link
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sx={{ padding: "12px" }}>
                <UploadReports />
              </Grid>
            </Grid>
          </div>
        </Dialog>
      </Hidden>
      {/* Upload Modal */}
      {uploadModal &&
        <MasterUploadModal
          open={uploadModal}
          closeModal={handleUploadModal}
          docsType={"Block"}
        />
      }
    </div>
  );
};
