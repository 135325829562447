import { Avatar, Box, Button, Dialog, Divider, Grid, IconButton, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { enumName } from "../../../utils"
import { UnitHeading, UnitPath, UnitType } from "../../../utils/agreementRenewal/agreementRenewalUtils"
import { useStyles } from "../style"
import { TableWithPagination } from "../../../components";
import InfoIcon from "../../../assets/infoIcon";
import DeclineIcon from "../../../assets/agreementDetails/declineIcon";
import RequestDeatilsIcon from "../../../assets/agreementDetails/requestDetailsIcon";
import AGRIcon from "../../../assets/agreementDetails/agrIcon";
import AcceptIcon from "../../../assets/agreementDetails/acceptIcon";

export const RequestView = (props) => {
    console.log(props, "///");
    const classes = useStyles()

    return <Dialog
        className={classes.viewDialog}
        open={props?.requestDialogOpen}
        onClose={() => props?.setRequestDialogOpen(false)}>
        <div className={classes.viewDialogHeader}>
            <div className={classes.viewDialogHeaderContainer}>
                <Typography className={classes.viewDialogHeaderTitle}>
                    {"Request View"}
                </Typography>
            </div>
            <IconButton onClick={() => props?.setRequestDialogOpen(false)}
                className={classes.viewDialogCloseButton}>
                <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
            </IconButton>
        </div>
        <div className={classes.viewDialogBody}>
            <Grid container>
                <Grid item xs={6} paddingRight={"8px"}>
                    {(props?.requestState?.status?.value !== enumName?.approved &&
                        props?.requestState?.occupiedUnits?.length > 0) &&
                        <Grid container direction={"row"} justifyContent={"space-between"}>
                            <Grid item xs={12} paddingBottom={"12px"}>

                                <div className={classes.occupiedErrorCard}>
                                    <div className={classes.occupiedErrortextDiv}>
                                        <Typography className={classes.occupiedErrorText}>
                                            {props?.requestState?.occupiedUnits?.length > 1 ?
                                                "Few Units Are Occupied" :
                                                (props?.requestState?.occupiedUnits?.[0]?.unit_name + " - " +
                                                    props?.requestState?.occupiedUnits?.[0]?.unit_no + " Is Occupied")}
                                        </Typography>
                                    </div>
                                    <div className={classes.occupiedErrorIconDiv}>
                                        <InfoIcon fill="#FF4B4B" hoverFill="#FF4B4B" />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>}
                    {(props?.requestState?.status?.label !== "Waiting For Approval") &&
                        <>
                            <Box className={classes.box}>
                                <Box>
                                    {props?.requestState?.status?.label === "Declined" ? <DeclineIcon /> : <AcceptIcon />}
                                </Box>
                                <Box flexGrow={1} marginLeft={"10px"} >
                                    <Typography className={classes.viewDialogText4}>
                                        {props?.requestState?.status?.label}
                                    </Typography>
                                    <Typography className={classes.italicText}>
                                        By {props?.requestState?.approvedBY} on {props?.requestState?.updatedOn}
                                    </Typography>
                                    <Typography className={classes.semiBoldText}>
                                        Remarks : <span className={classes.regularText}>{props?.requestState?.managerRemarks}</span>
                                    </Typography>

                                </Box>

                            </Box>
                        </>
                    }
                    <Box margin={"12px 0px"}>
                        <Typography className={classes.heading}>
                            PRIMARY CONTACT
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" className={classes.viewDialogContactCard}>
                        <Avatar src={props?.requestState?.contactImg} variant={"rounded"}
                            className={classes.contactAvatar} />
                        <Box flexGrow={1}>
                            <Grid container justifyContent={"start"} alignItems={"center"}
                                direction={"row"} paddingBottom={"6px"}>
                                <Typography className={classes.viewDialogText4}>
                                    {props?.requestState?.contactName}
                                </Typography>
                            </Grid>
                            <Grid container justifyContent={"start"} alignItems={"center"} direction={"row"}>
                                <Typography className={classes.viewDialogText5}>
                                    {props?.requestState?.contactNo}
                                </Typography>
                                <Box className={classes.period} />
                                <Typography className={classes.viewDialogText5}>
                                    {props?.requestState?.contactEmail}
                                </Typography>
                            </Grid>
                        </Box>
                    </Box>

                    <Box margin={"12px 0px"}>
                        <Typography className={classes.heading}>
                            REQUEST DETAILS
                        </Typography>
                    </Box>
                    <Box className={classes.boxWithOutFlex}>
                        <Box className={classes.boxWithOutBorder}>
                            <Box>
                                <RequestDeatilsIcon />

                            </Box>
                            <Box flexGrow={1} marginLeft={"10px"} >
                                <Box className={classes.boxFlex}>
                                    <Box>
                                        <Typography className={classes.viewDialogText4}>
                                            {props?.requestState?.requestedBy}
                                        </Typography>
                                    </Box>
                                    {props?.requestState?.status?.label === "Waiting For Approval" &&
                                        <Box className={classes.inProgress}>
                                            {props?.requestState?.status?.label}
                                        </Box>
                                    }
                                </Box>

                                <Typography className={classes.italicText}>
                                    {props?.requestState?.requestNo} on {props?.requestState?.requestedOn}
                                </Typography>
                                <Typography className={classes.semiBoldText}>
                                    Remarks : <span className={classes.regularText}>{props?.requestState?.acceptDeclineRemarks}</span>
                                </Typography>

                            </Box>
                        </Box>
                        <Box margin={"8px 0px"}>
                            <Divider />
                        </Box>
                        <Box className={classes.boxWithOutBorder}>
                            <Box>
                                <AGRIcon />
                            </Box>
                            <Box flexGrow={1} marginLeft={"10px"} >
                                <Box className={classes.boxFlex}>
                                    <Box>
                                        <Typography className={classes.viewDialogText4}>
                                            {props?.requestState?.agreementNo}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.conform}>
                                        {"Current Agreement"}
                                    </Box>
                                </Box>
                                <Typography className={classes.semiBoldText}>
                                    {props?.requestState?.leaseStart} - {props?.requestState?.leaseEnd}
                                </Typography>

                            </Box>
                        </Box>

                    </Box>
                    <Box margin={"12px 0px"}>
                        <Typography className={classes.heading}>
                            NEW AGREEMENT DETAILS
                        </Typography>
                    </Box>
                    <Box className={classes.box}>
                        <Box>
                            <AGRIcon />
                        </Box>
                        <Box flexGrow={1} marginLeft={"10px"} >

                            <Box className={classes.boxFlex}>
                                <Box>
                                    <Typography className={classes.viewDialogText4}>
                                        {props?.requestState?.agreementNo}
                                    </Typography>
                                </Box>
                                {props?.requestState?.status?.label === "Declined" &&
                                    <Box className={classes.decline}>
                                        {props?.requestState?.status?.label}
                                    </Box>
                                }
                            </Box>
                            <Typography className={classes.semiBoldText}>

                                {props?.requestState?.leaseStart} - {props?.requestState?.leaseEnd}
                            </Typography>

                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6} paddingLeft={"8px"}>
                    <Grid container className={classes.viewDialogTableContainer}>
                        <Grid container paddingBottom={"12px"}>
                            <Typography className={classes.viewDialogText4}>
                                {"NUMBER OF UNITS (" + props?.requestState?.units?.length + ")"}
                            </Typography>
                            <Grid className={classes.viewDialogTable}>
                                <TableWithPagination
                                    heading={UnitHeading}
                                    rows={props?.requestState?.units}
                                    path={UnitPath}
                                    showpagination={false}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    onClick={() => false}
                                    cellBackground={"transparent"}
                                    tableType="no-side"
                                    dataType={UnitType}
                                    totalRowsCount={props?.requestState?.units?.length}
                                    height={'calc(100vh - 320px)'}
                                    view={props?.permission?.read}
                                    edit={props?.permission?.update}
                                    delete={props?.permission?.delete} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {(props?.permission?.update &&
                        props?.requestState?.status?.value === enumName.pending) &&
                        <div className={classes.viewDialogButtonContainer}>
                            <Button fullWidth className={classes.declineButton}
                                onClick={() => {
                                    props?.setRequestState({
                                        ...props?.requestState,
                                        formType: "Decline",
                                        acceptDeclineRemarks: props?.requestState?.occupiedUnits?.length > 0 ?
                                            (props?.requestState?.occupiedUnits?.length > 1 ?
                                                "Few Units Are Occupied" :
                                                (props?.requestState?.occupiedUnits?.[0]?.unit_name + " - " +
                                                    props?.requestState?.occupiedUnits?.[0]?.unit_no + " Is Occupied")) : ""
                                    })
                                    props?.setRequestDialogOpen(false)
                                    props?.setAcceptDeclineDialogOpen(true)
                                }}>
                                Decline
                            </Button>
                            {props?.requestState?.occupiedUnits?.length === 0 &&
                                <Button fullWidth className={classes.acceptButton}
                                    onClick={props?.getUnitPrice}>
                                    Accept
                                </Button>}
                        </div>}
                </Grid>
            </Grid>
        </div>
    </Dialog >
}