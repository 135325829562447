import { Box, Divider, Stack, Typography, Grid } from '@mui/material'
import React from 'react'
import { AssetView } from '../resourceMaster/components/assetView'
import { ViewCourtStyle } from './style'
const assets = [
    {
        url: "https://akm-img-a-in.tosshub.com/aajtak/images/story/202208/saina_nehwal-sixteen_nine.jpg?size=948:533"
    },
    {
        url: "https://sportsmatik.com/uploads/matik-sports-corner/matik-know-how/badminton1_1562313127.jpg"
    },
    {
        url: "https://img.freepik.com/premium-vector/badminton-player-smash-match-competition-sport-stadium-cartoon-illustration-vector_201904-955.jpg?w=2000"
    },
]

export const ViewCourt = ({ type = "" }) => {
    const classes = ViewCourtStyle()
    return (
        <Stack spacing={2} p={1}>
            <Box>
                <AssetView asset={assets} />
            </Box>
            {
                type === "court" &&
                <Box m={2}>
                    <Stack spacing={2} p={2}>
                        <Stack justifyContent={"space-between"} direction="row" alignItems={"center"}>
                            <Stack spacing={1}>
                                <Typography className={classes.court_name}>Court Name 1</Typography>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Typography className={classes.subname}>Tennies</Typography>
                                    <Box className={classes.dot}></Box>
                                    <Typography className={classes.subname}>Sports</Typography>
                                    <Box className={classes.dot}></Box>
                                    <Typography className={classes.subname}>2 Hr/Slot</Typography>
                                    <Box className={classes.dot}></Box>
                                    <Typography className={classes.subname}>Active</Typography>
                                </Stack>
                            </Stack>
                            <Box>
                                <Typography className={classes.content}>100 USD/Hr</Typography>
                            </Box>
                        </Stack>
                        <Divider />
                        <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                            <Typography className={classes.title}>Available Days</Typography>
                            <Stack direction={"row"} spacing={2}>
                                <Typography className={classes.content}>Mon</Typography>
                                <Typography className={classes.content}>Wed</Typography>
                                <Typography className={classes.content}>Sun</Typography>
                            </Stack>
                        </Stack>
                        <Divider />
                        <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                            <Typography className={classes.title}>Slot Sessions</Typography>
                            <Box float="right">
                                <Grid container spacing={1}>
                                    <Grid item md={6}>
                                        <Typography className={classes.content} textAlign="right">12:00 AM - 01:00 AM</Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography className={classes.content} textAlign="right">12:00 AM - 01:00 AM</Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography className={classes.content} textAlign="right">12:00 AM - 01:00 AM</Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography className={classes.content} textAlign="right">12:00 AM - 01:00 AM</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            }
            {
                type === "amenity" &&
                <Box m={2}>
                    <Stack>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <Typography className={classes.court_title}>Amenity Eligible For</Typography>
                                <Typography className={classes.content}>Units</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography className={classes.court_title}>Amenity Category</Typography>
                                <Typography className={classes.content}>Sports</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography className={classes.court_title}>Amenity Name</Typography>
                                <Typography className={classes.content}>Tennis</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography className={classes.court_title}>Status</Typography>
                                <Typography className={classes.content}>Active</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography className={classes.court_title}>Booking Required</Typography>
                                <Typography className={classes.content}>Yes</Typography>
                            </Grid>
                            <Grid item md={12}>
                                <Typography className={classes.court_title}>Description</Typography>
                                <Typography className={classes.content}>Tennis is a racket sport that is played either individually against a single opponent or between two teams of two players each</Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>}
        </Stack>
    )
}