import React, { useState } from "react";
import { Box, Divider, Grid, IconButton, Stack } from "@mui/material";
import { useStyles } from "../style";
import {
  rentalIndexType,
  rentalIndexHeading,
  rentalIndexPath,
  useWindowDimensions,
} from "../../../utils";
import {
  SearchFilter,
  TableWithPagination,
  DialogBox,
} from "../../../components";
import { AscendingDescending } from "../../../assets";
import { Form } from "./form";

export const TabelComponents = ({
  company = {},
  searchFunction = () => false,
  permission = {},
  t,
  openDrawer = () => false,
  list = {},
  handlePagination = () => false,
  handleChangeLimit = () => false,
  pagination = {},
  handleIcon = () => false,
  open = false,
  details = {},
  closePopup = () => false,
  ascendingOrder = () => false,
  page = 1,
  limit = 10,
  getListFunction=()=>false
}) => {
  const size = useWindowDimensions();
  // styles
  const classes = useStyles();

  const [orderBy, setOrderBy] = useState("desc");

  // ------------------------------------FUNCTIONS----------------------------------------------

  const ascending = () => {
    if (orderBy === "asc") {
      ascendingOrder("desc");
      setOrderBy("desc");
    } else {
      ascendingOrder("asc");
      setOrderBy("asc");
    }
  };

  return (
    <Grid container className={classes.content} spacing={2}>
      <Grid item xs={4}>
        <Box>
          {/* search box */}
          <SearchFilter
            value={company?.searchText}
            placeholder="Search"
            handleChange={(value) => searchFunction(value)}
            marginBottom="0px !important"
          />
        </Box>
      </Grid>

      <Grid item xs={8}>
        <Box display={"flex"} alignItems="center" sx={{ float: "right" }}>
          <Stack
            direction="row"
            spacing={0.6}
            display={"flex"}
            alignItems="center"
          >
            <Box>
              <IconButton
                size="small"
                className={classes.img}
                onClick={ascending}
              >
                <AscendingDescending />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                size="small"
                className={classes.img}
                onClick={openDrawer}
              >
                <img src="/images/filter.svg" alt="filter" />
              </IconButton>
            </Box>
          </Stack>
          <Box width={"15px"} />
        </Box>
      </Grid>

      <Grid item xs={12} className={classes.noPadding}>
        <Divider />
      </Grid>

      {/* Main tableData */}
      <Grid item xs={12} className={classes.tableMain}>
        <TableWithPagination
          heading={rentalIndexHeading(t)}
          rows={list?.data ?? []}
          path={rentalIndexPath}
          showpagination={true}
          showpdfbtn={false}
          showexcelbtn={false}
          totalRowsCount={list?.count}
          page={page ?? 1}
          limit={limit ?? 10}
          tableType="no-side"
          dataType={rentalIndexType}
          height={"calc(100vh - 315px)"}
          view={permission?.read}
          handleIcon={handleIcon}
          handlePagination={handlePagination}
          handleChangeLimit={handleChangeLimit}
        />
      </Grid>
      {/* end Main tableData */}

      {/* dialog */}
      <DialogBox
        open={open}
        handleClose={() => closePopup(false)}
        onClose={() => closePopup(false)}
        header={"Pricing Table"}
        maxWidth={"sm"}
        component={
          <>
            <Form
              permission={permission}
              company={company?.SelectedCompany}
              details={details}
              onClose={() => closePopup(false)}
              size={size?.height - 300}
              getListFunction={getListFunction}
            />
          </>
        }
      />
    </Grid>
  );
};
