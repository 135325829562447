import { useApolloClient } from "@apollo/client";
import { Box, Button, Grid } from "@mui/material";
import moment from "moment";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Stepper, Subheader } from "../../components";
import { AuthContext } from "../../contexts";
import { CreateQuotationListContext } from "../../contexts/createQuotationContext";
import { GETCOMPANYDETAILS } from "../../graphql/quotationQueries";
import { Routes } from "../../router/routes";
import { accessCheckRender, getRoutePermissionNew } from "../../utils";
import { AddQutation, LeadDetails, LeadPreview, Pdfpreview, Preview, QuotationDetailesNew } from "./steps";
import { useStyles } from "./styles";
export const CreateQuotation = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();
  const { state } = useLocation();
  const auth = React.useContext(AuthContext)
  const [permission, setPermission] = React.useState({})

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        if (state) {
          getCompanyDetails()
        }
        else {
          history.push(Routes.propertyFinder)
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //context state
  const { step, previous, next, hideBtn, setDataNew, dataNew , hidenBtn1 } = React.useContext(CreateQuotationListContext);
  //new lead
  const stepsNew = [
    {
      label: "Add Prospective",
      step: 1,
      value: step
    },
    {
      label: "Opportunity Details",
      step: 2,
      value: step
    },
    {
      label: "Preview and Create Lead",
      step: 3,
      value: step
    },
    {
      label: "Quotation Details ",
      step: 4,
      value: step
    },
    {
      label: "Preview and Submit",
      step: 5,
      value: step
    },
  ]
  const ExisitNew = [
    {
      label: "Quotation Details",
      step: 1,
      value: step
    },
    {
      label: "Preview and Submit ",
      step: 2,
      value: step
    },
  ]


  //get company details
  const getCompanyDetails = () => {
    client.query({
      query: GETCOMPANYDETAILS,
      fetchPolicy: 'network-only',
      variables: {
        id: state?.companyID?.value,
      }
    }).then((res) => {
      if (state?.duration?.lead) {
        setDataNew({
          ...dataNew,
          purpose: {
            label: state?.duration?.lead?.unit_usage,
            value: state?.duration?.lead?.unit_usage
          },
          source: {
            label: state?.duration?.lead?.lead_source,
            value: state?.duration?.lead?.lead_source
          },
          revenue_type: {
            label: state?.duration?.lead?.revenue_type,
            value: state?.duration?.lead?.revenue_type
          },
          duration: {
            value: state?.duration?.lead?.values,
            select: state?.duration?.lead?.lease_period,
          },
          quotation_revenue_based_lease: state?.duration?.lead?.revenue_based_lease,
          type: state?.type,
          lease_start_date: state?.duration?.lead?.lease_start_date,
          lease_end_date: state?.duration?.lead?.lease_end_date,
          priority: state?.duration?.lead?.urgent,
          quotation_lease_duration: {
            value: state?.duration?.lead?.values,
            select: state?.duration?.lead?.lease_period,
          },
          lead_details: state?.duration?.lead,
          units: state?.unit,
          company: state?.companyID?.value,
          searchdata: state?.data,
          lead_id: state?.duration?.lead?.id,
          name: state?.duration?.lead?.contact?.first_name,
          mobile: {
            mobile: state?.duration?.lead?.contact?.mobile_no,
            mobile_code: "",
          },
          email_id: state?.duration?.lead?.contact?.email,
          image_url: {
            src: state?.duration?.lead?.contact?.get_assets_url_id
          },
          quotation_billing_start_date: state?.duration?.lead?.lease_start_date,
          quotation_payment_peroid: state?.data?.revenue_type === "Sale" ? { value: state?.duration?.lead?.payment_period } : {
            value: state?.duration?.lead?.payment_period,
            label: state?.duration?.lead?.payment_period
          },
          quotation_payment_option: {
            value: state?.duration?.lead?.payment_mode,
            label: state?.duration?.lead?.payment_mode
          },
          quotation_grace_period: state?.duration?.lead?.grace_period_value,
          apply_grace_peroid: state?.duration?.lead?.apply_grace_period,
          quotation_billing_cycle: state?.duration?.lead?.bill_generation,
          bill_cycle_date: state?.duration?.lead?.billing_cycle_date === "As per the Agreement Date" ? state?.duration?.lead?.billing_cycle_date : null,
          quotation_billing_cycle_date: state?.duration?.lead?.billing_cycle_date === "As per the Agreement Date" ? state?.duration?.lead?.billing_cycle_date : null,
          quotation_renewal_based_on: {
            value: state?.duration?.lead?.auto_renewal_escalation,
            label: state?.duration?.lead?.auto_renewal_escalation
          },
          quotation_auto_renewal: state?.duration?.lead?.auto_renewal,
          quotation_escalculation_percent: state?.duration?.lead?.increment_escalation,
          quotation_include_market_place: state?.duration?.lead?.include_market,
          quotation_handover_date: state?.duration?.lead?.occpation_date ? new Date(state?.duration?.lead?.occpation_date) : null,
          quotation_contract_start_date: state?.duration?.lead?.contract_start_date ? new Date(state?.duration?.lead?.contract_start_date) : null,
          quotation_custom_date: state?.duration?.lead?.billing_day,
          grace_peroid: state?.duration?.lead?.grace_period_value,
          billing_start_date: new Date(state?.duration?.lead?.lease_start_date) ?? null,
          shortlist_convert: true,
          custom_date: state?.duration?.lead?.billing_day ?? "",
          payment_period: state?.data?.revenue_type === "Sale" ? { value: state?.duration?.lead?.payment_period } : {
            value: state?.duration?.lead?.payment_period ?? "",
            label: state?.duration?.lead?.payment_period ?? ""
          },
          bill_cycle_method: state?.duration?.lead?.bill_generation,
          earlist_occupation_date: state?.duration?.lead?.occpation_date ? new Date(state?.duration?.lead?.occpation_date) : null,
          contract_start_date: state?.duration?.lead?.contract_start_date ?? null,
          mileStoneName: state?.duration?.lead?.milestone_templateByID?.name ?? state?.duration?.lead?.payment_period,
          payment_period_value: {
            value: state?.duration?.lead?.milestone_templateByID?.id ?? state?.duration?.lead?.payment_period,
            label: state?.duration?.lead?.milestone_templateByID?.name ?? state?.duration?.lead?.payment_period
          },
          payment_option: {
            value: state?.duration?.lead?.payment_mode,
            label: state?.duration?.lead?.payment_mode,
          },
          renewal_based_on: {
            value: state?.duration?.lead?.auto_renewal_escalation,
            label: state?.duration?.lead?.auto_renewal_escalation
          },
          esclation_percentage: state?.duration?.lead?.increment_escalation !== null ? JSON.stringify(state?.duration?.lead?.increment_escalation) : null,
          milestoneTemplate:"",
          typeValue:"",
          reload:false,
          isValid:true,
          mailstoneLists:[]
        })

      } else {

        setDataNew({
          ...dataNew,
          custom_date: res?.data?.company_master[0]?.billing_day,
          bill_cycle_method: res?.data?.company_master[0]?.billing_cycle,
          bill_cycle_date: res?.data?.company_master[0]?.billing_cycle_date,
          purpose: {
            label: state?.data?.property_purpose,
            value: state?.data?.property_purpose
          },
          revenue_type: {
            label: state?.data?.revenue_type,
            value: state?.data?.revenue_type
          },
          payment_period: state?.data?.revenue_type === "Sale" ? "" : {
            value: res?.data?.company_master[0]?.payment_period,
            label: res?.data?.company_master[0]?.payment_period,
          },
          type: state?.type,
          units: state?.unit,
          company: state?.companyID?.value,
          searchdata: state?.data,
          lease_start_date: state?.data?.duration?.fromDate,
          grace_peroid: state?.data?.stateFilter?.facilitesbool ?? null,
          billing_start_date: moment(state?.data?.duration?.fromDate).add(state?.data?.stateFilter?.facilitesbool ?? 0, 'days')?._d,
          lease_end_date: moment(state?.data?.duration?.toDate).subtract(1, 'days'),
          duration: {
            value: state?.duration?.duration?.input,
            select: state?.duration?.duration?.period === "Month" ? "Monthly" : "Yearly"
          },
          quotation_lease_duration: {
            value: state?.duration?.duration?.input,
            select: state?.duration?.duration?.period === "Month" ? "Monthly" : "Yearly"
          },
          required_area: {
            min: "",
            max: "",
            select: {
              value: res?.data?.company_master[0]?.uom,
              label: res?.data?.company_master[0]?.uom
            },
          },
          budget: {
            min: "",
            max: "",
            select: {
              value: res?.data?.company_master[0]?.currencyByID?.id,
              label: res?.data?.company_master[0]?.currencyByID?.symbol
            },
          },
          earlist_occupation_date: state?.duration?.handOverDate?.date,
          contract_start_date: state?.duration?.handOverDate?.date,
          milestoneTemplate:"",
          typeValue:"",
          reload:false,
          isValid:true,
          mailstoneLists:[]
        })
      }

    }).catch((err) => console.log(err))
  }

  const newLeadComponent = {
    1: <AddQutation />,
    2: <LeadDetails />,
    3: <Box className={classes.contentNew}><LeadPreview /></Box>,
    4: <QuotationDetailesNew />,
    5: <Box className={classes.preview}><Preview /></Box>,
    6: <Box className={classes.content}><Pdfpreview url={dataNew?.pdf} /></Box>
  }
  const existComponent = {
    1: <QuotationDetailesNew />,
    2: <Box className={classes.preview}><Preview /></Box>,
    3: <Box className={classes.content}><Pdfpreview url={dataNew?.pdf} /></Box>
  }

  const render = () => {
    return <>
      {/*sub header*/}
      <Subheader goBack={() => history.goBack()} title={dataNew.type === "existing lead" ? "Create Quotation To Existing Opportunity" : "Create Quotation To New Opportunity"} />
      <Stepper step={step} steps={dataNew.type === "existing lead" ? ExisitNew : stepsNew} />
      {/*conditional component*/}
      {dataNew.type === "existing lead" ?
        existComponent[step]
        :
        newLeadComponent[step]
      }
      {/*btn container */}


      {
        !hideBtn &&
        <Grid container className={classes.btnroot} spacing={1}>
          <Grid item xs={6}>
            <Button className={classes.previousbtn} variant="contained" onClick={previous}
              disabled={dataNew.type === "existing lead" ? step === 3 : (step === 1 || step === 4 || step === 6)}>
              Previous
            </Button>
          </Grid>
          <Grid item xs={6} textAlign={"right"} >
            <Button className={classes.previousbtn} variant="contained" onClick={() => history.goBack()}>
              Cancel
            </Button>

            {permission?.create &&
                <Button className={classes.submitbtn} variant="contained" onClick={next}
                  disabled={dataNew.type === "existing lead" ?
                    false
                    : step === 3 ? hidenBtn1 : false}
              >
                
                {dataNew.type === "existing lead" ?
                  step === 3 ? "Done" : "Next"
                  : step === 6 ? "Done" : "Next"}

              </Button>}
          </Grid>
        </Grid>
      }
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>
  );
};
