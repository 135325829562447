import { Box, Grid } from '@mui/material';
import React from 'react';
import { withNamespaces } from "react-i18next";
import { AggreementCardBottom, AggreementCardInfo, AggreementChart, LoadingSection } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
const ChartContainer = ({ selectedAgreement = "", t = () => false }) => {
    const [agreementInfo, setAgreementInfo] = React.useState({
        cardList: [],
        data: []
    })
    const [loading, setLoading] = React.useState(false)
    //get agreement info
    const getAgreementInfo = () => {
        setLoading(true)
        const payload = {
            tenantId: `${config.tenantId}`,
            agreement_id: selectedAgreement
        }
        NetworkCall(
            `${config.api_url}/agreement/getInfo`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let data = response?.data?.data?.agreementInfo[0];
                let carddata = [
                    {
                        url: "/images/aggreementValue.svg",
                        title: `${data?.symbol ?? ""} ${data?.total_amount ? Math.round(data?.total_amount) : ""}`,
                        subtitle: t("AgreementSummary"),
                        color: "#E0FFED"
                    },
                    {
                        url: "/images/noresident.svg",
                        title: data?.user_count,
                        subtitle: t("RevenueTenantsandResidents"),
                        color: "#E0E9FF"
                    },
                    {
                        url: "/images/noproperty.svg",
                        title: data?.property_count,
                        subtitle: t("Properties"),
                        color: "#FFF1E0"
                    },
                    {
                        url: "/images/nounits.svg",
                        title: data?.unit_count,
                        subtitle: t("Units"),
                        color: "#E0F3FF"
                    }
                ]
                setAgreementInfo({
                    cardList: carddata,
                    data: response?.data?.data?.rentbreakup
                })
                setLoading(false)

            }).catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }
    //initial load
    React.useEffect(() => {
        getAgreementInfo()
        // eslint-disable-next-line
    }, [selectedAgreement])

    const cardBottom = [
        {
            title: "₹0",
            subtitle: t("overDueAmount"),
        },
    ]

    return (
        <div>
            {
                loading ? <LoadingSection bottom={"45vh"} message={t("loadMsgInfo")} /> :
                    <Box>
                        {/*Top card list */}
                        <Grid container spacing={2.5}>

                            {
                                agreementInfo?.cardList?.map((val) => {
                                    return (
                                        <Grid item xs={6}>
                                            <AggreementCardInfo data={val} />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        <Box paddingTop="16px">
                            {/*chart container */}
                            <AggreementChart label={t("agreementBreakDown")} rentalbreakUp={agreementInfo?.data} />
                        </Box>
                        <Box paddingTop="16px">
                            <Grid container spacing={2.5}>
                                {/*info bottom card */}
                                {
                                    cardBottom?.map((val) => {
                                        return (
                                            <Grid item xs={6}>
                                                <AggreementCardBottom data={val} />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                    </Box>
            }




        </div>
    )
}
export default withNamespaces("agreement")(ChartContainer);