import CancelIcon from '@mui/icons-material/Cancel';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { Badge, Box, Grid, Typography } from "@mui/material";
import React from "react";
import { ToolsMasterStyles } from "./style";


export const FIleUpload = ({ img = [], updateImgState = () => false, handleImgClose = () => false }) => {

    const classes = ToolsMasterStyles()

    return (
        <>
            <Box mt={2}><Typography className={classes.assetHead}>Assets</Typography></Box>
            <Grid container spacing={1} mt={0.5}>
                <Grid item xs={4}>
                    <Box className={classes.dragBox} p={5} >
                        <Box><UploadFileRoundedIcon className={classes.fileIcon} /></Box>


                        <center>
                            <label id="myImage">
                                <input
                                    type="file"
                                    name="myImage"
                                    style={{ display: "none" }}
                                    multiple
                                    onChange={(e) => {
                                        updateImgState(e.target.files);
                                    }}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                                <Box variant="outlined" p={1} className={classes.BrowseBtn}>Browse&nbsp;Files</Box>
                            </label>
                        </center>
                    </Box>
                </Grid>
                {img.map((_, i) => {
                    return (
                        <Grid item xs={4}>
                            <Badge key={i} badgeContent={<CancelIcon sx={{ color: "red" }} onClick={() => handleImgClose(_)} />}
                                className={classes.imgUpload} color="primary">
                                <img className={classes.img} src={_?.url} alt="img" />
                            </Badge>

                        </Grid>
                    )
                })}

            </Grid>

            <Box>



            </Box>
        </>

    )
}