export const PropertyPath = ["name", "location", "unitCounts", "propertyType", "status"]

export const PropertyHeading = [
    { title: "Property Name", field: "name" },
    { title: "Location", field: "location" },
    { title: "Unit Counts", field: "unitCounts" },
    { title: "Property Type", field: "propertyType" },
    { title: "Status", field: "status" },
]

export const PropertyType = [
    { type: ["text"], name: "name" },
    { type: ["text"], name: "location" },
    { type: ["text"], name: "unitCounts" },
    { type: ["propertyType"], name: "propertyType" },
    { type: ["status"], name: "status" },
]

export const ActiveStatus = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]

export const UnitPath1 = ["propertyName", "unitNo", "unitName", "unitCategory", "unitType", "unitCurrentStatus", "status"]

export const UnitHeading1 = [
    { title: "Property Name", field: "propertyName" },
    { title: "Unit Number", field: "unitNo" },
    { title: "Unit Name", field: "unitName" },
    { title: "Unit Category", field: "unitCategory" },
    { title: "Unit Type", field: "unitType" },
    { title: "Occupancy Status", field: "unitCurrentStatus" },
    { title: "Status", field: "status" },
]

export const UnitType1 = [
    { type: ["text"], name: "propertyName" },
    { type: ["text"], name: "unitNo" },
    { type: ["text"], name: "unitName" },
    { type: ["text"], name: "unitCategory" },
    { type: ["text"], name: "unitType" },
    { type: ["status"], name: "unitCurrentStatus" },
    { type: ["status"], name: "status" },
]

export const UnitVacancyPath = ["startDate", "endDate", "currentStatus", "agreementNumber", "image", "companyName", "primaryContactName", "primaryContactNumber", "reservationNumber"]


export const UnitVacancyHeading = [
    { title: "Start Date", field: "startDate", },
    { title: "End Date", field: "endDate", },
    { title: "Occupancy Status", field: "currentStatus", },
    { title: "Agreement Number", field: "agreementNumber", },
    { title: "Company Name", field: "companyName", },
    { title: " ", field: "image", },
    { title: "Primary Contact Name", field: "primaryContactName", },
    { title: "Primary Contact Number", field: "primaryContactNumber", },
    { title: "Reservation Number", field: "reservationNumber", },
]

export const UnitVacancyType = [
    { type: ["date"], name: "startDate" },
    { type: ["date"], name: "endDate" },
    { type: ["status"], name: "currentStatus" },
    { type: ["text"], name: "agreementNumber" },
    { type: ["text"], name: "companyName" },
    { type: ["avatarmanagement"], name: "image" },
    { type: ["text"], name: "primaryContactName" },
    { type: ["text"], name: "primaryContactNumber" },
    { type: ["text"], name: "reservationNumber" },
]

export const UnitPath2 = ["unitName", "unitCategory", "floorName", "blockName", "unitCurrentStatus", "status"]

export const UnitHeading2 = [
    { title: "Unit Name", field: "unitName" },
    { title: "Unit Category", field: "unitCategory" },
    { title: "Floor Name", field: "floorName" },
    { title: "Block Name", field: "blockName" },
    { title: "Unit Current Status", field: "unitCurrentStatus" },
    { title: "Status", field: "status" },
]

export const UnitType2 = [
    { type: ["text"], name: "unitName" },
    { type: ["text"], name: "unitCategory" },
    { type: ["text"], name: "floorName" },
    { type: ["text"], name: "blockName" },
    { type: ["text"], name: "unitCurrentStatus" },
    { type: ["status"], name: "status" },
]