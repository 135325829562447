import MenuIcon from '@mui/icons-material/Menu'
import { Box, Button, Grid, Stack } from "@mui/material"
import React from "react"
import { ParkingCalenderIcon } from "../../../assets"
import { AlertDialog, CalendarDateChange } from "../../../components"
import { CustomDropDown } from "../../scheduleBoard/components/customDropDown"
import { CreateActivity } from "./createActivity"
import { useStyles } from "./style"

export const CalendarHeader = ({ selected = "", weekdates = () => false, weekprev = () => false, weeknext = () => false, month = "", monthprev = () => false, monthnext = () => false, open = "", daily = "", prevdate = () => false, nextdate = () => false, showAll = false, setDropdownValue = () => false, select = "", setselect = "", calendarFunctions = () => false, selectedCompany={} }) => {
    const classes = useStyles()
    const [chooseActivity, setChooseActivity] = React.useState(false)
    const [size, setSize] = React.useState({ isMedium: true, isNormal: false })
    return (
        <Box>
            <Grid container alignItems={"center"} justifyContent="space-between" >
                <Stack direction={"row"} spacing={2} width="250px">
                    {/* <Grid item>
                        <CustomSelect
                            noSearch
                            options={[]}
                            // isReadOnly={props?.isReadOnly}
                            color="white"
                            placeholder={"My Team"}
                        // value={assignData?.company}
                        // onChange={(value) => {
                        //     updateState("company", value, true)
                        // }} 
                        />
                    </Grid>
                    <Grid item>
                        <CustomSelect
                            noSearch
                            options={[]}
                            // isReadOnly={props?.isReadOnly}
                            color="white"
                            placeholder={"Team 01"}
                        // value={assignData?.company}
                        // onChange={(value) => {
                        //     updateState("company", value, true)
                        // }} 
                        />
                    </Grid> */}
                </Stack>
                <Grid item>
                    <Box
                    >
                        {
                            selected === "Weekly" &&
                            <CalendarDateChange state={weekdates} prev={weekprev} next={weeknext} comp={"weekly"} />
                        }
                        {
                            selected === "Monthly" &&
                            <CalendarDateChange state={month} prev={monthprev} next={monthnext} comp={"month"} />
                        }
                        {
                            selected === "Daily" &&
                            <CalendarDateChange state={daily} prev={prevdate} next={nextdate} comp={"daily"} />
                        }
                    </Box>
                </Grid>
                <Grid item >
                    <Stack direction="row" spacing={2}>
                        {/* <CalendarDropDown
                            open={open}
                            selected={selected}
                            showAll={showAll}
                            setDropdownValue={setDropdownValue}
                            select={select}
                            right

                        /> */}
                        <CustomDropDown type={selected} setType={setDropdownValue} />
                        <Button variant="contained" fullWidth onClick={() => setChooseActivity(true)}>Create Activity</Button>
                        <Box className={classes.layoutSwitch}>
                            <Stack direction={"row"} spacing={1} alignItems="center">
                                <Box className={select === "nonlist" ? classes.selectedBtn : classes.notSelectedBtn}>
                                    <ParkingCalenderIcon onClick={() => setselect("nonlist")} fill={select === "nonlist" ? "#FFFFFF" : "#98A0AC"} />
                                </Box>
                                <Box className={select === "list" ? classes.selectedBtn : classes.notSelectedBtn}>
                                    <MenuIcon onClick={() => setselect("list")} />
                                </Box>
                            </Stack>
                        </Box>

                    </Stack>
                </Grid>

            </Grid>
            <AlertDialog
                isNormal={size?.isNormal}
                medium={size?.isMedium}
                open={chooseActivity}
                onClose={() => setChooseActivity(false)}
                header={"Create Activity"}
                component={<CreateActivity setSize={setSize} calendarFunctions={calendarFunctions} handleClose={() => setChooseActivity(false)} selectedCompany={selectedCompany} />}

            />
        </Box>
    )
}