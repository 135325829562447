import { TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Label } from "../label";
import { useStyles } from "./style";

export const TextBox = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <>
        {props.label && <Label label={props.label} isRequired={props.isrequired} />}
        <div className={classes.text}>
          <TextField
            className={classes.textbox}
            type={props.type}
            onKeyPress={(e) => {
              if (props.type === "number") {
                if (e.key === "e") {
                  e.preventDefault();
                }
              }
            }}
            // autoComplete={false}
            id={props.id}
            placeholder={props.placeholder}
            variant={props.variant ?? "outlined"}
            fullWidth
            InputLabelProps={{
              shrink: false,
            }}
            inputProps={{
              readOnly: props?.isReadonly ?? false,
              autocomplete: props?.autocomplete,
            }}
            InputProps={{
              endAdornment: props?.endAdornment,
              startAdornment: props?.startAdornment,
              style: {
                fontSize: props?.fontSize ?? "14px",
                padding: props.multiline ? 0 : "none",
              },
              
            }}
            disabled={props?.isReadonly ?? false}
            size="small"
            multiline={props.multiline}
            rows={props?.rowheight??3}
            autoFocus={props?.autoFocus}
            rowsMax={10}
            onChange={props.onChange}
            value={props.value ?? ""}
            error={props.error}
            helperText={props.helperText}
            defaultValue={props?.defaultValue ?? ""}
            onBlur={props?.onBlur}
          // onKeyPress={props?.onKeyPress}
            // onKeyPress={props?.onKeyPress}
            onWheel={(e) => e.target.blur()}
          />
        </div>
      </>
      {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
    </div>
  );
};

TextBox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  isReadonly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
TextBox.defaultProps = {
  label: "Textbox",
  multiline: false,
  type: "text",
  placeholder: "Type Here...",
};
