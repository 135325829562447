import { Avatar, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
import { TickImageComponent } from "../../assets/tickImage";
import { Bold, RolesData, SemiBold } from "../../utils/constants";
const useStyles = makeStyles((theme) => ({
  titlerole: {
    color: theme.typography.color.tertiary,
    fontSize: "12px",
    fontFamily: `${Bold}`,
    margin: "8px 0px",
  },
  roleData: {
    // width: "193px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "8px 8px 8px 0px",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    height: "70px"
  },
  roleDataActive: {
    // width: "193px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "8px 8px 8px 0px",
    border: "1px solid #5078E1",
    borderRadius: theme.palette.borderRadius,
    height: "70px"

  },
  avatarrole: {
    marginLeft: "25px",
    textTransform: "uppercase",
  },
  avatarApp: {
    marginLeft: "25px",
  },
  avatarroleNotSelected: {
    // marginLeft: "6px",
    textTransform: "uppercase",
  },
  avatarAppNotSelected: {
    // marginLeft: "6px",
  },
  avatarroleActive: {
    // marginTop: "14px",
    marginLeft: "30px",
    color: (props) =>
      props?.label === RolesData.communityManager
        ? "#119DA4"
        : props?.label === RolesData.manager
          ? "#4991F2"
          : props?.label === RolesData.agent
            ? "#D49200"
            : props?.label === RolesData.tenant
              ? "#11A442"
              : props?.label === RolesData.securityManager
                ? "#D47100"
                : "#F17360",
    textTransform: "uppercase",
  },
  labelroleActive: {
    fontSize: "12px",
    color: theme.palette.primary.main,
    fontFamily: `${SemiBold}`,
    marginTop: "8px",
    textTransform: 'capitalize',
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  labelrole: {
    fontSize: "14px",
    color: theme.typography.color.primary,
    fontFamily: `${Bold}`,
    marginLeft: "10px",
    textTransform: 'capitalize',
    // whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"



  },
  name: {
    fontSize: "12px",
  },
  // roleDataActive: {
  //   border: "2.5px solid #5078E1",
  //   backgroundColor: "#F8FAFF",
  //   borderRadius: theme.palette.borderRadius,
  //   padding: "8px",
  //   height: "170px",
  //   // width: "193px",
  //   cursor: "pointer",
  // },
  heightColor: {
    height: "95px",
    backgroundColor: "#666D74",
    borderTopLeftRadius: theme.palette.borderRadius,
    borderTopRightRadius: theme.palette.borderRadius,
    position: "relative",
    padding: "18px 8px",
    borderLeft: "2px solid black",
    borderRight: "2px solid black",
    borderTop: "2px solid black",


  },
  heightColor2: {
    marginTop: "-4px",
    height: "78px",
    borderLeft: "2px solid black",
    borderRight: "2px solid black",
    borderBottom: "2px solid black",
    borderBottomLeftRadius: theme.palette.borderRadius,
    borderBottomRightRadius: theme.palette.borderRadius,
    backgroundColor: "#FFFFFF"



  },
  centerTag: {
    position: "absolute",
    backgroundColor: theme.palette.success.main,
    color: "white",
    padding: "4px",
    borderRadius: theme.palette.borderRadius,
    fontSize: "10px",
    fontFamily: `${SemiBold}`,
    bottom: "-11px",
    marginLeft: "-24px"
  },
  mobCenterTag: {
    position: "absolute",
    backgroundColor: theme.palette.warning.main,
    color: "white",
    padding: "4px",
    borderRadius: theme.palette.borderRadius,
    fontSize: "10px",
    fontFamily: `${SemiBold}`,
    bottom: "-11px",
    marginLeft: "-28px"
  },
  centerTagActive: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "4px",
    borderRadius: theme.palette.borderRadius,
    fontSize: "10px",
    fontFamily: `${SemiBold}`,
  },
  mobCenterTagActive: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "4px",
    borderRadius: theme.palette.borderRadius,
    fontSize: "10px",
    fontFamily: `${SemiBold}`,
  },
  tick: {
    float: "right",
  },
  avatar: {
    backgroundColor: "#F5F7FA",
    borderTopRightRadius: theme.palette.borderRadius,
    borderBottomRightRadius: theme.palette.borderRadius,
    padding: "6px"
  }
}));

export const UserCard = ({ val, active = false, onClick = () => false, mobApp = false }) => {
  const classes = useStyles(val, active);
  moment.defaultFormat = "DD MMM YY";
  return (
    <Box
      className={active ? classes.roleDataActive : classes.roleData}
      onClick={() => onClick(val)}
    >
      <Box>
        {val?.module_id === "1" && <Avatar className={classes.avatar} variant="square" src="images/switch/lease.svg" />}
        {val?.module_id === "2" && <Avatar className={classes.avatar} variant="square" src="images/switch/PropertyMangement.svg" />}
        {val?.module_id === "3" && <Avatar className={classes.avatar} variant="square" src="images/switch/VisitorAndSecurityIcon.svg" />}
        {val?.module_id === "4" && <Avatar className={classes.avatar} variant="square" src="images/switch/CommunityManagementIcon.svg" />}
        {val?.module_id === "5" && <Avatar className={classes.avatar} variant="square" src="images/switch/ClientmanagementIcon.svg" />}
        {val?.module_id === "6" && <Avatar className={classes.avatar} variant="square" src="images/switch/InspectionIcon.svg" />}
        {val?.module_id === "7" && <Avatar className={classes.avatar} variant="square" src="images/switch/FacilityManagementIcon.svg" />}
        {val?.module_id === "8" && <Avatar className={classes.avatar} variant="square" src="images/switch/FinanceAndAccoutingModule.svg" />}
        {val?.module_id === "9" && <Avatar className={classes.avatar} variant="square" src="images/switch/VendorPortal.svg" />}
        {val?.module_id === "10" && <Avatar className={classes.avatar} variant="square" src="images/switch/BrokerPortal.svg" />}
        {val?.module_id === "11" && <Avatar className={classes.avatar} variant="square" src="images/switch/tenantPortal.svg" />}
        {val?.module_id === "12" && <Avatar className={classes.avatar} variant="square" src="images/switch/OwnerPortal.svg" />}
        {val?.module_id === "13" && <Avatar className={classes.avatar} variant="square" src="images/switch/BrokerManagement.svg" />}
        {val?.module_id === "14" && <Avatar className={classes.avatar} variant="square" src="images/switch/OperationsManagement.svg" />}
        {val?.module_id === "15" && <Avatar className={classes.avatar} variant="square" src="images/switch/ResourcePortal.svg" />}
        {val?.module_id === "17" && <Avatar className={classes.avatar} variant="square" src="images/switch/you.svg" />}
        {val?.module_id === "18" && <Avatar className={classes.avatar} variant="square" src="images/switch/stay.svg" />}
        {val?.module_id === "19" && <Avatar className={classes.avatar} variant="square" src="images/switch/easy.svg" />}
        {mobApp &&
          <Avatar className={classes.avatar} variant="square" src={val?.src} />
        }
      </Box>
      <Box flexGrow={1}>
        <Typography className={classes.labelrole}>
          {val?.name}
        </Typography>
      </Box>
      {active &&
        <Box>
          <TickImageComponent white />
        </Box>
      }

      {/* <center>
        <Box className={classes.heightColor}>
          {active ?
            <span className={classes.tick}>
              <TickImageComponent white />
            </span> :
            <span className={classes.tick}>
              {" "}
            </span>
          }
          {mobApp &&
            // <Avatar className={active?classes.avatarApp:classes.avatarAppNotSelected}>
            //   <Typography className={classes.name}>
            //     {val?.name?.slice(0, 2)}
            //   </Typography>
            // </Avatar>
            <>
              {val?.name === RolesData.tenantsAndResidents &&
                <Avatar variant="rounded" className={active ? classes.avatarApp : classes.avatarAppNotSelected} src="images/switch/TenantApp.svg" />
              }
              {val?.name === RolesData.securityGuard &&
                <Avatar variant="rounded" className={active ? classes.avatarApp : classes.avatarAppNotSelected} src="images/switch/SecurityGuardApp.svg" />
              }
              {val?.name === RolesData.serviceProvider &&
                <Avatar variant="rounded" className={active ? classes.avatarApp : classes.avatarAppNotSelected} src="images/switch/ServiceProviderWebApp.svg" />
              }
              {val?.name === RolesData.fieldService &&
                <Avatar variant="rounded" className={active ? classes.avatarApp : classes.avatarAppNotSelected} src="images/switch/FieldServiceApp.svg" />
              }
              {val?.name === RolesData.Inspector &&
                <Avatar variant="rounded" className={active ? classes.avatarApp : classes.avatarAppNotSelected} src="images/switch/InspectorApp.svg" />
              }
              {val?.name === RolesData.salesAgent &&
                <Avatar variant="rounded" className={active ? classes.avatarApp : classes.avatarAppNotSelected} src="images/switch/AgentApp.svg" />
              }
              {val?.name === RolesData.Township &&
                <Avatar variant="rounded" className={active ? classes.avatarApp : classes.avatarAppNotSelected} src="images/switch/TownshipWebApp.svg" />
              }
              {val?.name === RolesData.landlordAndOwner &&
                <Avatar variant="rounded" className={active ? classes.avatarApp : classes.avatarAppNotSelected} src="images/switch/OwnerApp.svg" />
              }
              {val?.name === RolesData.gateWay &&
                <Avatar className={active ? classes.avatarApp : classes.avatarAppNotSelected}>
                  <Typography className={classes.name}>
                    {val?.name?.slice(0, 2)}
                  </Typography>
                </Avatar>}
            </>
          }
          <Box className={active ? classes.avatarrole : classes.avatarroleNotSelected}>
            {val?.module_id === "2" && <Avatar variant="rounded" src="images/switch/PropertyMangement.svg" />}
            {val?.module_id === "1" && <PropertyLeaseAndSaleIcon />}
            {val?.module_id === "5" && <ClientmanagementIcon />}
            {val?.module_id === "4" && <CommunityManagementIcon />}
            {val?.module_id === "3" && <VisitorAndSecurityIcon />}
            {val?.module_id === "6" && <InspectionIcon />}
            {val?.module_id === "7" && <FacilityManagementIcon />}
            {val?.module_id === "8" && <Avatar variant="rounded" src="images/switch/FinanceAndAccoutingModule.svg" />}
            {val?.module_id === "9" && <Avatar variant="rounded" src="images/switch/VendorPortal.svg" />}
            {val?.module_id === "11" && <Avatar variant="rounded" src="images/switch/tenantPortal.svg" />}
            {val?.module_id === "12" && <Avatar variant="rounded" src="images/switch/OwnerPortal.svg" />}
            {val?.module_id === "10" && <Avatar variant="rounded" src="images/switch/BrokerPortal.svg" />}

          </Box>
          {active && <Box height={"16px"} />}
          <span className={mobApp ? classes.mobCenterTag : classes.centerTag}>
            {mobApp ? "Mobile App" : "Web App"}

          </span>
        </Box>
        <Box className={classes.heightColor2}>
          <Box height={"29px"} />
          <Typography className={classes.labelrole}>
            {val?.name}
            {val?.label === RolesData.communityManager && (
              <>
                Community
                <br />
                Manager
              </>
            )}
            {val?.label === RolesData.manager && (
              <>
                Property
                <br />
                Manager
              </>
            )}
            {val?.label === RolesData.securityManager && (
              <>
                Visitor & Security
                <br />
                Manager
              </>
            )}
            {val?.label === RolesData.salesManager && (
              <>
                Sales
                <br />
                Manager
              </>
            )}
            {val?.label === RolesData.leaseManager && (
              <>
                Lease & Sales
                <br />
                Manager
              </>
            )}
            {val?.label === RolesData.clientAdmin && (
              <>
                Client
                <br />
                Admin
              </>
            )}

          </Typography>

        </Box>
      </center> */}

    </Box>
  );
};
