import React from 'react';
import "../../../../App.css";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import { useStyles } from "./style";
import { AccessGatesList } from "./accessGatesList/accessGatesList";
import { MiniPropertyDetail } from "../../../../components";

export const PropertyDetails = (props) => {

      const size = useWindowDimensions()
      const classes = useStyles(size)

      return <div className={classes.root}>
            <MiniPropertyDetail
                  logo={props?.data?.logo}
                  property_no={props?.data?.property_no}
                  company_name={props?.data?.company?.name}
                  property_name={props?.data?.name}
                  address={props?.data?.address} />
            <AccessGatesList permission={props?.permission} data={props?.data} />
      </div>
}