import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MasterUploadModal, SearchFilter, TableWithPagination } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { GET_BLOCK_DETAILS_BY_ID as queryOne, GET_FLOOR_BY_BLOCK_ID as queryTwo } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, ExtraBold, NetWorkCallMethods, SemiBold } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { BlockDetailCard } from "./blockDetailcard";
import { Bold } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    border: "2px solid white",
    borderRadius: "12px",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    overflow: "hidden",
  },

  btn: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px 12px",
  },
  btn2: {
    borderRadius: theme.palette.borderRadius,
    color: theme.palette.secondary.main,
    padding: "8px 12px",
    // borderColor: theme.palette.secondary.main,
  },
  text: {
    fontSize: "24px",
    fontFamily: Bold,
    color: theme.typography.color.primary,
  },
  subText: {
    fontSize: "16px",
    fontFamily: SemiBold,
    color: theme.typography.color.secondary,
  },
  tableDiv: {
    padding: "12px",
    overflow: "scroll",
  },
  bottomTitle: {
    fontSize: "18px",
    fontFamily: ExtraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
  },
  contentBottom: {
    // padding: "12px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  grid: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.palette.borderRadius,
    padding: "4px",
  },
  title: {
    fontSize: "14px",
    fontFamily: Bold,
    color: theme.typography.color.primary,
  },
  subTitle: {
    fontSize: "12px",
    fontFamily: SemiBold,
    color: theme.palette.primary.main,
  },
}));

export const BlockDetails = (props) => {
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const classes = useStyles();
  const search = useLocation().search;
  const size = useWindowDimensions();
  const blockId = new URLSearchParams(search).get("blockId");
  const propertyId = new URLSearchParams(search).get("propertyId");
  const companyID = new URLSearchParams(search).get("companyID");
  const [isFilter, setIsFilter] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [blockData, setBlockData] = React.useState({});
  const [floorData, setFloorData] = React.useState([]);
  const [uploadModal, setUploadModal] = React.useState(false);
  const getBlockById = () => {
    const payload = {
      query: queryOne,
      variables: { blockId: blockId },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {

        setBlockData(response.data.data);
        setFloorData(response.data.data.floor);
        setIsFilter(true);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  };

  const searchFilter = () => {
    const payload = { query: queryTwo, variables: { blockId: blockId, searchText: searchText } };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false).then((response) => {
        setFloorData(response.data.data.floor);
      }).catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  };

  React.useEffect(() => {
    getBlockById();
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    if (isFilter) {
      searchFilter();
    }
    // eslint-disable-next-line
  }, [searchText]);
  const BlockPath = ["floorNo", "floorname", "description"];

  const BlockHeading = [
    { title: "Floor NO.", field: "floorNo", },
    { title: "Floor Name", field: "floorname" },
    { title: "Description", field: "description" },
    { title: "", field: "icon" },
  ];
  const BlockRow = floorData?.map((val, index) => {
    let _d;
    try {
      _d = {
        floorNo: val?.floor_no,
        floorname: val.name,
        description: val.description,
        icon: "editview",
        id: val.id,
      };
    } catch (err) {

    }
    return _d;
  });
  const handleIcon = (type, data) => {

    if (type === "edit") {
      history.push(Routes.addFloor + "?companyID=" + companyID + "&propertyId=" + propertyId + "&blockId=" + blockId + "&floorId=" + data?.id + "&topNavBarTitle=" + data?.floorname);
    } else if (type === "view") {
      history.push(Routes.floorDetails + "?companyID=" + companyID + "&propertyId=" + propertyId + "&blockId=" + blockId + "&floorId=" + data?.id + "&topNavBarTitle=" + data?.floorname);
    }
  };
  const handleUploadModal = (val, type) => {
    // selected tab master name
    if (type === "refresh") {
      getBlockById();
      setUploadModal(!uploadModal)
    } else {
      setUploadModal(!uploadModal)
    }
  }

  return (
    <div className={classes.root} style={{ height: size.height - 100 }}>
      <Grid container>
        <Grid item xs={4}>
          {blockData?.block?.map((val) => {
            return (
              <BlockDetailCard
                propertyId={propertyId}
                data={val}
                color={true}
                assets={blockData?.assets}
              />
            );
          })}
        </Grid>
        <Grid item xs={8} className={classes.tableDiv}>
          {floorData.length > 0 ? (
            <>
              <div style={{ height: size.height - 115 }}>
                <Grid container className={classes.content} spacing={1}>
                  <Grid item xs={6}>
                    <SearchFilter value={searchText} handleChange={(value) => setSearchText(value)} />
                  </Grid>
                  <Grid item xs={6} textAlign={"right"}>
                    <Box
                      display={"flex"}
                      sx={{ float: "right" }}
                      alignItems="center"
                    >
                      {/* <Box>
                        <IconButton size="small" className={classes.filter}>
                          <img src="/images/filter.svg" alt="filter" />
                        </IconButton>
                      </Box> */}
                      &nbsp; &nbsp; &nbsp;
                      <Box>
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary"
                          className={classes.btn2}
                          onClick={handleUploadModal}>
                          Add Bulk Floor
                        </Button>
                      </Box>
                      &nbsp; &nbsp; &nbsp;
                      <Box>
                        <Button
                          size="small"
                          variant="contained"
                          className={classes.btn}
                          onClick={() =>
                            history.push(
                              Routes.addFloor +
                              "?companyID=" + companyID + "&propertyId=" +
                              propertyId +
                              "&blockId=" +
                              blockId
                            )
                          }
                        >
                          Add a Floor
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} marginTop="10px">
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ padding: "12px" }}>
                      <TableWithPagination
                        heading={BlockHeading}
                        rows={BlockRow}
                        path={BlockPath}
                        showpagination={floorData.length > 11 ? true : false}
                        count="2"
                        tableType="normal"
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        handleIcon={handleIcon}
                        onClick={() => console.log("")}
                        dataType={[
                          { type: ["text"], name: "floorNo" },
                          { type: ["text"], name: "floorname" },
                          { type: ["text"], name: "description" },
                          { type: ["icon"], icon: "icon" },
                        ]}
                        view={true}
                        edit={true}
                        delete={true} />
                      <br />
                      <br />
                    </div>
                  </Grid>
                </Grid>

                <br />
              </div>
            </>
          ) : (
            <>
              <Grid conatiner>
                <Grid item xs={12}>
                  <img src="/images/propertybg.svg" alt="pp" />
                  <Typography className={classes.text}>
                    No Floor Found On This {blockData?.block?.[0]?.name}
                  </Typography>
                  <Typography className={classes.subText}>
                    You Can Just, Create New Floor
                  </Typography>
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    className={classes.btn}
                    onClick={() =>
                      history.push(
                        Routes.addFloor +
                        "?companyID=" + companyID + "&propertyId=" +
                        propertyId +
                        "&blockId=" +
                        blockId
                      )
                    }
                  >
                    Add A Floor
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.btn2}
                    onClick={handleUploadModal}>
                    Bulk Upload The Data With Our Excel Template
                  </Button>
                  <br />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {/* Upload Modal */}
      {uploadModal &&
        <MasterUploadModal
          open={uploadModal}
          closeModal={handleUploadModal}
          docsType={"Floor"}
        />
      }
    </div>
  );
};
