import { Box, Grid, Stack, Typography } from "@mui/material";
import React from 'react';
import ReactQuill from "react-quill";
import "../../../App.css";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { blockStyles } from "./style";
import ImageViewer from "react-simple-image-viewer";
import { getCustomBlockName, convertTimeUtcToZoneCalander } from "../../../utils";

// import MyGoogleMap from '../../../components/interactivemap/myGoogleMaps';


export const BlockDetailsViewing = (props) => {
      const classes = blockStyles();
      const size = useWindowDimensions()
      const modules = {
            toolbar: false,
      };
      // const { data } = props;
      // const { address } = data ?? {};


      // const GoogleMap = React.useCallback(() => {
      //       return (
      //             <MyGoogleMap
      //                   lat={address?.latitude}
      //                   lng={address?.longitude}
      //                   center={{
      //                         lat: address?.latitude,
      //                         lng: address?.longitude
      //                   }}
      //                   zoom={13}
      //                   readonly

      //             />
      //       )
      // }, [address])
      return (
            <div style={{ height: size?.height - 200, overflow: "auto", padding: "4px", margin: "-4px" }}>
                  {/*block detials first card */}
                  <Grid container className={classes.card} style={{ padding: "16px 16px 16px 0px", }}>
                        <Grid item xs={12} sm={12} md={2} lg={2} >
                              <div className={classes.imgDiv}>
                                    <img src={props?.data?.logo ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg}
                                          onClick={() => props?.openImageViewer([props?.data?.logo])}
                                    />
                                    <Typography className={classes.imgTag}>&nbsp;&nbsp;{props?.data?.block_no}&nbsp;&nbsp;</Typography>
                              </div>
                              {props?.isViewerOpen && (
                                    <ImageViewer
                                          src={props?.currentImage}
                                          currentIndex={0}
                                          onClose={props?.closeImageViewer}
                                          disableScroll={false}
                                          backgroundStyle={{
                                                backgroundColor: "rgba(0,0,0,0.9)",
                                          }}
                                          closeOnClickOutside={true}
                                    />
                              )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10} className={classes.imgDiv2}>
                              <Typography className={classes.title}>
                                    {
                                          getCustomBlockName(props?.data?.property?.property_hierarchyByID) +
                                          " Details"
                                    }
                              </Typography>
                              <Grid container>
                                    <Grid item md={12} lg={9}>
                                          <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="8px">
                                                <Box>
                                                      <Typography className={classes.heading}>Company Name</Typography>
                                                      <Typography className={classes.sub}>{props?.data?.property?.company_masterByID?.name ? props?.data?.property?.company_masterByID?.name : " - "}</Typography>
                                                </Box>
                                                <Box>
                                                      <Typography className={classes.heading}>
                                                            {
                                                                  getCustomBlockName(props?.data?.property?.property_hierarchyByID) +
                                                                  " Name"
                                                            }
                                                      </Typography>
                                                      <Typography className={classes.sub}>
                                                            {props?.data?.name ? props?.data?.name : " - "}
                                                      </Typography>
                                                </Box>
                                                <Box>
                                                      <Typography className={classes.heading}>
                                                            {
                                                                  getCustomBlockName(props?.data?.property?.property_hierarchyByID) +
                                                                  " Type"
                                                            }
                                                      </Typography>
                                                      <Typography className={classes.sub}>
                                                            {props?.data?.block_type_masterByID?.name ? props?.data?.block_type_masterByID?.name : "- "}
                                                      </Typography>
                                                </Box>
                                                <Box>
                                                      <Typography className={classes.heading}>Property Type</Typography>
                                                      <Typography className={classes.sub}>
                                                            {props?.data?.property?.property_groupByID?.group_name ? props?.data?.property?.property_groupByID?.group_name : "- "}
                                                      </Typography>
                                                </Box>
                                                <Box>
                                                      <Typography className={classes.heading}>Status</Typography>
                                                      <Typography className={classes.sub}>{props?.data?.is_active ? "Active" : "In active"}</Typography>
                                                </Box>
                                          </Box>
                                    </Grid>
                              </Grid>

                              <Box marginTop="14px">
                                    <Typography className={classes.heading}>
                                          {
                                                getCustomBlockName(props?.data?.property?.property_hierarchyByID) +
                                                " Description"
                                          }
                                    </Typography>
                                    {props?.data?.description &&
                                          <ReactQuill
                                                readOnly
                                                theme="bubble"
                                                value={props?.data?.description ?? ""}
                                                modules={modules}
                                                className={classes.sub}
                                          />
                                    }
                              </Box>
                        </Grid>
                        <Grid item md={0} lg={4.5} />
                  </Grid>
                  <br />
                  {/*block detials second card */}
                  <Grid container className={classes.card}>
                        <Grid item md={12}>
                              <Grid container>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>
                                                      {
                                                            getCustomBlockName(props?.data?.property?.property_hierarchyByID) +
                                                            " Purpose"
                                                      }
                                                </Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.block_purpose ? props?.data?.block_purpose : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>Revenue Type</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.property?.revenue_type ? props?.data?.property.revenue_type : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>Measurement Unit</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.uom_masterByID?.name ? props?.data?.uom_masterByID?.name : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>Total Built Area</Typography>
                                                <Typography className={classes.sub}>{props?.data?.carpet_area}
                                                      <span className={classes.matric}>{props?.data?.uom_masterByID?.name}</span></Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>
                                                      {
                                                            "Total " +
                                                            getCustomBlockName(props?.data?.property?.property_hierarchyByID) +
                                                            " Area"
                                                      }
                                                </Typography>
                                                <Typography className={classes.sub}>{props?.data?.total_area}
                                                      <span className={classes.matric}>{props?.data?.uom_masterByID?.name}</span></Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>Year Built</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.year_built ? convertTimeUtcToZoneCalander(props?.data?.year_built) : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>Handover Date</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.planned_hand_over_date ? convertTimeUtcToZoneCalander(props?.data?.planned_hand_over_date) : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                              </Grid>
                        </Grid>
                  </Grid>
                  <br />
                  {/*Block detials third card */}
                  <div className={classes.card}>
                        <Grid container spacing={2}>
                              {/* <Grid item xs={5}>
                                    <div className={classes.map}>
                                          <GoogleMap />
                                    </div>
                              </Grid> */}
                              {/* when map component is needed plz use below commented grid item */}
                              {/* <Grid item xs={3}> */}
                              <Grid item xs={6}>
                                    <div className={classes.addressRoot}>
                                          <Stack direction="row" >
                                                <img src='/images/loc.svg' alt='' />
                                                <Typography className={classes.title}>&nbsp;Address</Typography>
                                          </Stack>
                                          <Box height="12px" />
                                          <Typography className={classes.sub}>
                                                {props?.data?.address?.street_1 + "," ?? ""}
                                                {props?.data?.address?.street_2 + "," ?? ""}
                                                {props?.data?.address?.street_3 + "," ?? ""}
                                                {props?.data?.address?.district + "," ?? ""}
                                                {props?.data?.address?.country + "," ?? ""}
                                                {props?.data?.address?.zipcode ?? ""}
                                          </Typography>
                                          <Box height="16px" />
                                          <Stack direction="row" >
                                                <Typography className={classes.heading}>Latitude : </Typography>
                                                <Typography className={classes.sub1} >&nbsp;{props?.data?.address?.latitude}</Typography>
                                          </Stack>
                                          <Box height="12px" />
                                          <Stack direction="row" >
                                                <Typography className={classes.heading}>Longitude : </Typography>
                                                <Typography className={classes.sub1} >&nbsp;{props?.data?.address?.longitude}</Typography>
                                          </Stack>
                                    </div>
                              </Grid>
                              {/* when map component is needed plz use below commented grid item */}
                              {/* <Grid item xs={4}> */}
                              <Grid item xs={6}>
                                    <div className={classes.addressRoot}>
                                          <Stack direction="row" >
                                                <img src='/images/Group 7015.svg' alt='' />
                                                <Typography className={classes.title}>&nbsp;Contact & Other Information</Typography>
                                          </Stack>
                                          <Grid container>
                                                <Grid item xs={6}>
                                                      <Box marginTop="18px">
                                                            <Typography className={classes.heading}>Business Phone :</Typography>
                                                            <Typography className={classes.sub}>{props?.data?.address?.alternative_mobile_no}</Typography>
                                                      </Box>
                                                      <Box marginTop="12px">
                                                            <Typography className={classes.heading}>Website :</Typography>
                                                            <Typography className={classes.sub} noWrap>{props?.data?.property?.website}</Typography>
                                                      </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                      <Box marginTop="18px">
                                                            <Typography className={classes.heading}>Mobile Phone :</Typography>
                                                            <Typography className={classes.sub}>{props?.data?.address?.mobile_no}</Typography>
                                                      </Box>
                                                      <Box marginTop="12px">
                                                            <Typography className={classes.heading}>Email Address :</Typography>
                                                            <Typography className={classes.sub} noWrap>{props?.data?.address?.email_id}</Typography>
                                                      </Box>
                                                </Grid>
                                          </Grid>
                                    </div>
                              </Grid>
                        </Grid>
                  </div>
            </div>
      )
}