import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ShowMoreText from "react-show-more-text";
import { FormGenerator, Stepper } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { GET_ACTIVITY_CATAGORY_MASTER } from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, enumSelect, enum_types, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions } from "../../../utils";
import { loadOptions } from "../../../utils/asyncPaginateLoadOptions";
import { MapSource } from "./commom";
import { LeadStylesParent } from "./style";

export const AddActivity = (props) => {
  // classes
  // debugger
  const classes = LeadStylesParent(props);
  const alert = React.useContext(AlertContext);
  const company = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
  // state
  const [step, setStep] = React.useState(1);

  const [enumValue, setEnumValue] = useState({
    priority: [],
    // purpose: [],
    followUp: [],
  });

  const size = useWindowDimensions();

  const steps = [
    {
      label: "Activity Details",
      step: 1,
      value: step,
    },
    {
      label: "Preview and Submit",
      step: 2,
      value: step,
    },
  ];

  //on previous
  const previous = () => {
    setStep(step - 1);
  };
  //on next
  const next = () => {
    if (props?.validate()) {
      setStep(step + 1);
    }
  };

  const getEnum = async () => {
    const result = await enumSelect([
      // enum_types?.purpose_global_type,
      enum_types?.urgent_type,
      enum_types?.follow_up_type,
    ]);
    setEnumValue({
      priority: result?.urgent_type ?? [],
      // purpose: result?.purpose_global_type ?? [],
      followUp: result?.follow_up_type,
    });
  };
  const save = () => {
    const activity = {
      activity_category: props?.state?.Category?.value,
      notes: props?.state?.Notes,
      priority: props?.state?.Priority,
      email_id: props?.state?.email ?? undefined,
      type:
        props?.state?.Followup?.value?.length > 0
          ? props?.state?.Followup?.value
          : undefined,
      lead_id: props?.proxy_lead_id ?? undefined,
      phone_number: props?.state?.mobile?.mobile ?? undefined,
      location: props?.state?.location ?? undefined,
      date_time: moment(props?.state?.visitorDate).format("YYYY-MM-DD HH:mm:ss") ?? undefined,
      phone_code: props?.state?.mobile?.mobile_code ?? undefined,
      // purpose: props?.state?.Purpose?.value,
      lead_activitiy_master: props?.type?.id,
      company_id:company?.value ?? undefined
    };

    if (props?.state?.isEdit) {
      activity["id"] = props?.state?.id;
      activity["updated_by"] = localStorage.getItem("profileID");
    } else {
      activity["created_by"] = localStorage.getItem("profileID");
      activity["status"] = "Open";
    }

    NetworkCall(
      `${config.api_url}/lead/activity`,
      NetWorkCallMethods.post,
      {
        activity,
      },
      null,
      true,
      false
    )
      .then((response) => {
        props?.cancel();
        props?.getActivityList(0, 10);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: `Lead ${props?.data?.isEdit === true ? "Updated" : "Created"
            } successfully.`,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Internal error. Please try again later.",
        });
      });
  };

  useEffect(() => {
    getEnum();
  }, []);

  const components = {
    1: (
      <Step1
        state={props?.state}
        enumValue={enumValue}
        updateState={props?.updateState}
        type={props?.type}
      />
    ),
    2: <Step2 type={props?.type} state={props?.state} />,
  };

  return (
    <div>
      <div
        style={{
          height: size?.height - 370,
          textAlign: "left",
          overflow: "auto",
        }}
      >
        <Stepper step={step} steps={steps} type={props?.type} />
        {components[step]}
      </div>
      <Box className={classes.bottomButton}>
        <Box>
          <Button
            className={classes.cancel}
            onClick={previous}
            disabled={step < 1 ? true : false}
          >
            previous
          </Button>
          {step === 2 ? (
            <Button className={classes.next} onClick={save}>
              {props?.state?.isEdit ? "Update" : "Sumbit"}
            </Button>
          ) : (
            <Button className={classes.next} onClick={next}>
              Next
            </Button>
          )}
        </Box>
      </Box>
    </div>
  );
};

const Step1 = (props) => {
  return (
    <div style={{ padding: "24px" }}>
      <FormGenerator
        components={[
          {
            isActive: true,
            component: "select",
            label: "Category",
            value: props?.state?.Category,
            placeholder: "Category",
            onChange: (value) => props?.updateState("Category", value),
            error: props?.state?.error?.Category,
            isRequired: true,
            size: {
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            },
            isPaginate: true,
            loadOptions: (search, array, handleLoading) =>
              loadOptions(
                search,
                array,
                handleLoading,
                GET_ACTIVITY_CATAGORY_MASTER,
                "activity_category_master",
                { isActive: true },
                { label: "label", value: "value" },
                false,
                false
              ),
          },
          // {
          //   isActive: true,
          //   component: "select",
          //   label: "Purpose",
          //   value: props?.state?.Purpose,
          //   placeholder: "Purpose",
          //   onChange: (value) => props?.updateState("Purpose", value),
          //   error: props?.state?.error?.Purpose,
          //   isRequired: true,
          //   size: {
          //     xs: 12,
          //     sm: 12,
          //     md: 6,
          //     lg: 6,
          //   },
          //   options: props?.enumValue?.purpose ?? [],
          // },
          {
            isActive: props?.type?.name === "Phone call" ? true : false,
            component: "mobile",
            label: "Phone Number",
            value: props?.state?.mobile,
            placeholder: "Phone Number",
            onChange: (value) => props?.updateState("mobile", value),
            error: props?.state?.error?.mobile,
            isRequired: true,
            size: {
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            },
          },
          {
            isActive:
              props?.type?.name === "Follow up" ||
                props?.type?.name === "Meeting" ||
                props?.type?.name === "Appointment"
                ? true
                : false,
            component: "select",
            label: "Followup Type",
            value: props?.state?.Followup,
            placeholder: "Followup",
            onChange: (value) => props?.updateState("Followup", value),
            error: props?.state?.error?.Followup,
            isRequired: true,
            size: {
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            },
            options: props?.enumValue?.followUp ?? [],
          },
          {
            isActive:
              props?.state?.Followup?.value === "In-Person" ? true : false,
            component: "text",
            label: "Location",
            value: props?.state?.location,
            placeholder: "Location",
            onChange: (value) =>
              props?.updateState("location", value?.target?.value),
            error: props?.state?.error?.location,
            isRequired: true,
            size: {
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            },
          },
          {
            isActive:
              props?.type?.name === "Send Email" ||
                props?.type?.name === "Send Quotation"
                ? true
                : false,
            component: "text",
            label: "Email",
            value: props?.state?.email,
            placeholder: "Email",
            onChange: (value) =>
              props?.updateState("email", value?.target?.value),
            error: props?.state?.error?.email,
            isRequired: true,
            size: {
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            },
          },
          {
            isActive: true,
            component: "newDatePickerAutoTime",
            label: "Call Date & Time",
            value: props?.state?.visitorDate,
            placeholder: "Select Visit Date & Time",
            onChange: (value) => props?.updateState("visitorDate", value),
            error: props?.state?.error?.visitorDate,
            minDate: new Date(),
            isRequired: true,
            size: {
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            },
          },
          {
            size: {
              xs: 12,
              sm: 6,
              md: 6,
              lg: 6,
            },
            isActive: true,
            component: "toggle",
            label: "Priority",
            value: props?.state?.Priority,
            placeholder: "Priority",
            onChange: (value) => props?.updateState("Priority", value),
            error: props?.state?.error?.Priority,
            isRequired: true,
            options: props?.enumValue?.priority ?? [],
          },
          {
            isActive: true,
            component: "text",
            label: "Notes",
            value: props?.state?.Notes,
            placeholder: "Notes",
            onChange: (value) =>
              props?.updateState("Notes", value?.target?.value),
            error: props?.state?.error?.Notes,
            isRequired: true,
            multiline: true,
            size: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
            },
          },
        ]}
      />
    </div>
  );
};

const Step2 = (props) => {
  // classes
  const previewType = MapSource[props?.type?.name];
  const classes = LeadStylesParent();

  return (
    <div style={{ padding: "24px" }}>
      <Box
        className={classes.typeCard}
        style={{ backgroundImage: `url(${previewType?.background})` }}
      >
        <img src={previewType?.image} alt={previewType?.text} />
        <Typography className={classes.typeName}>
          {previewType?.text}
        </Typography>
      </Box>

      <Box className={classes.flexBox}>
        <Box className={classes.flexBox1}>
          <Typography className={classes.title}>CATEGORY</Typography>
          <Typography className={classes.sub}>
            {props?.state?.Category?.label}
          </Typography>
        </Box>
        <Box className={classes.flexBox1}>
          <Typography className={classes.title}>PURPOSE</Typography>
          <Typography className={classes.sub}>
            {props?.state?.Purpose?.value}
          </Typography>
        </Box>
        {props?.state?.visitorDate?.length > 0 && (
          <Box className={classes.flexBox1}>
            <Typography className={classes.title}>START DATE</Typography>
            <Typography className={classes.sub}>
              {moment(props?.state?.visitorDate).format("DD MMM YY")}
            </Typography>
          </Box>
        )}
        {props?.state?.mobile?.mobile_code?.length > 0 && (
          <Box className={classes.flexBox1}>
            <Typography className={classes.title}>PHONE NUMBER</Typography>
            <Typography className={classes.sub}>
              {props?.state?.mobile?.mobile_code} -{" "}
              {props?.state?.mobile?.mobile}
            </Typography>
          </Box>
        )}
        {props?.state?.email?.length > 0 && (
          <Box className={classes.flexBox1}>
            <Typography className={classes.title}>EMAIL ID</Typography>
            <Typography className={classes.sub}>
              {props?.state?.email}
            </Typography>
          </Box>
        )}
        {props?.state?.location?.length > 0 && (
          <Box className={classes.flexBox1}>
            <Typography className={classes.title}>LOCATION</Typography>
            <Typography className={classes.sub}>
              {props?.state?.location}
            </Typography>
          </Box>
        )}
        {props?.state?.Followup?.length > 0 && (
          <Box className={classes.flexBox1}>
            <Typography className={classes.title}>FOLLOW UP</Typography>
            <Typography className={classes.sub}>
              {props?.state?.Followup}
            </Typography>
          </Box>
        )}

        <Box className={classes.flexBox1}>
          <Typography className={classes.title}>PRIORITY</Typography>
          <Typography className={classes.sub}>
            {props?.state?.Priority}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.margin}>
        <Divider />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.title}>NOTE</Typography>
          <ShowMoreText
            lines={2}
            more="Show More"
            less="Show Less"
            className={classes.sub}
            anchorClass={classes.seeMoreLessTextStyle}
            expanded={false}
            truncatedEndingComponent={"... "}
          >
            <Typography className={classes.sub}>
              {props?.state?.Notes ?? ""}
            </Typography>
          </ShowMoreText>
        </Grid>
      </Grid>
    </div>
  );
};
