import { Box, Typography } from "@mui/material";
import { AccountList } from "../../../screens/quotationDetails/components/accountList";
import { LeadUnitDetails } from "../../../screens/quotationDetails/components/leadUnitDetails";
import { useStyles } from "./styles";
export const Preview = ({ total = "", selectedAcc = {}, data = {}, contact = {}, disable = false }) => {

    const classes = useStyles()
    return (
        <Box>
            <Typography className={classes.titleMidle}>AGREEMENT DETAILS</Typography>
            <Box className={classes.address1}>
                <LeadUnitDetails
                    unit
                    quott={{
                        unit: data?.units?.length,
                        unit_details: data?.units,
                        startDate: data?.quott?.lease_start_date,
                        endDate: data?.quott?.lease_end_date,
                        symbol: data?.quott?.symbol
                    }}
                    total={total} />
            </Box>
            <Box height="12px" />
            <Typography className={classes.titleMidle}>BILLING PRIMARY CONTACT DETAILS</Typography>
            <Box className={classes.address1}>
                <LeadUnitDetails data={{
                    name: contact?.name,
                    img: contact?.image_url,
                    phone: `${contact?.mobile?.mobile_code} ${contact?.mobile?.mobile}`,
                    mail: contact?.email,
                    nationality: contact?.nationality
                }}
                    lead
                    isNew={!disable}
                />
            </Box>
            <Box height="12px" />
            <Typography className={classes.titleMidle}>BILLING ACCOUNT DETAILS</Typography>
            <Box height="12px" />
            <AccountList disable value={selectedAcc} />
        </Box>
    )
}