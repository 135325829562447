import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../../utils";
export const tableStyles = makeStyles((theme) => ({
    btn: {
        fontFamily: `${SemiBold}`,
        color: theme?.palette?.background?.tertiary1,
        backgroundColor: theme?.palette?.primary?.main,
        padding: "5px 11px",
        marginLeft: "10px",
        fontSize: "12px",
        "&:hover": {
            backgroundColor: theme?.palette?.primary?.main,
            color: theme?.palette?.background?.tertiary1,
        },
    },
    btn2: {
        fontFamily: `${SemiBold}`,
        color: theme?.palette?.background?.tertiary1,
        backgroundColor: theme?.palette?.primary?.main,
        padding: "5px 11px",
        fontSize: "12px",
        "&:hover": {
            backgroundColor: theme?.palette?.primary?.main,
            color: theme?.palette?.background?.tertiary1,
        },
    },
    btn4: {
        fontFamily: `${SemiBold}`,
        color: theme?.palette?.background?.tertiary1,
        backgroundColor: theme?.palette?.primary?.main,
        padding: "11px",
        fontSize: "12px",
        "&:hover": {
            backgroundColor: theme?.palette?.primary?.main,
            color: theme?.palette?.background?.tertiary1,
        },
    },
    btn3: {
        fontFamily: `${SemiBold}`,
        color: theme?.typography.color.secondary,
        backgroundColor: theme?.palette?.background?.tertiary1,
        padding: "5px 11px",
        fontSize: "12px",
        border: "1px solid #CED3DD",
        "&:hover": {
            backgroundColor: theme?.palette?.background?.tertiary1,
            color: theme?.typography.color.secondary,
        },
    },

    filterimg: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        //marginTop: "4px",
        padding: "12px",
    },
}));