import React from 'react';
import { Avatar, Typography } from "@mui/material";
import { LeadStylesParent } from '../style';
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { convertTimeUtcToZoneCalander } from "../../../utils";

export const TimeLine = (props) => {
      // styles
      const classes = LeadStylesParent();
      return (
            <div>
                  <div>
                        {
                              props?.datas?.map((v, i) => {
                                    return (
                                          <Timeline style={{ padding: 0, color: "#fff", margin: 0 }} className={classes.menuItem}>
                                                <TimelineItem >
                                                      <TimelineSeparator>
                                                            <TimelineDot style={{ backgroundColor: '#fff', boxShadow: 'none', padding: 0, margin: 0 }}>
                                                                  <div className={classes.timedotborder}>
                                                                        {/* <Box className={classes.timedot} /> */}
                                                                        <Avatar src={v?.image_url ?? ""} />
                                                                  </div>
                                                            </TimelineDot>
                                                            {props?.datas?.length - 1 !== i && (
                                                                  <TimelineConnector
                                                                        variant="TimelineDot"
                                                                        className={classes.dot_line}
                                                                  />
                                                            )}
                                                      </TimelineSeparator>
                                                      <TimelineContent>
                                                            <div className={classes.profileroot2} style={{ padding: '12px' }}>
                                                                  <Typography className={classes.message}>{v?.comments ?? ""}</Typography>
                                                                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '14px' }}><Typography className={classes.timelinecreated}>{v?.created_by ?? ""} ,</Typography>&nbsp;<Typography className={classes.timelinecreatedat}>{v?.created_at  ? convertTimeUtcToZoneCalander(v?.created_at , 'yyyy-MMM-dd hh:mm aaaa') : '-'}</Typography></div>
                                                            </div>
                                                      </TimelineContent>
                                                </TimelineItem>
                                          </Timeline>

                                    );
                              })}
                  </div>
            </div>
      )
}
