import React from "react";
import { withNavBars } from "../../HOCs";
import { Contacts } from "../contacts/contacts";

const SalesAgentViewNew = () =>{
    return(
        <Contacts type="Agent" />
    )
}

export default withNavBars(SalesAgentViewNew)