import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React from 'react'
import DeleteIcon from '../../../assets/delete'
import { PropertyUnitDetailsStyle } from './style'
import { EditIcon } from '../../../components';
import { withNamespaces } from 'react-i18next';

const PropertyUnitDetails = (
    {
        t,
        data = [],
        type = "",
        edit = () => false,
        addClick = () => false,
        setRowData = () => false,
        removeObjectFromArray = () => false,
        tableRowData = []
    }) => {

    // const [rowCount, setRowCount] = React.useState(0)

    const classes = PropertyUnitDetailsStyle()

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            data?.map((e) => {
                                return (
                                    <TableCell className={classes.headCell}>
                                        <Typography className={classes.headText}> {e?.title} </Typography>
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                {
                    type.length === 0 ?
                        <TableBody>
                            {
                                data?.map((e) => {
                                    return (
                                        <TableCell className={classes.bodyCell}>
                                            <Stack direction={"row"} justifyContent={"space-between"} alignItems="center">
                                                <Typography className={classes.bodyText}> {e?.content} </Typography>
                                                {e?.editIcon && <Typography sx={{ cursor: "pointer" }} onClick={edit} ><EditIcon /></Typography>}
                                            </Stack>
                                        </TableCell>
                                    )
                                })
                            }
                        </TableBody>
                        :
                        <TableBody>
                            {
                                tableRowData?.map((item) => {
                                    return (
                                        <TableRow>
                                            <TableCell className={classes.bodyCell}>
                                                <Typography className={classes.headText}> {item?.component} </Typography>
                                            </TableCell>
                                            {
                                                item?.path?.map((e, ind, arr) => {
                                                    return (
                                                        <>
                                                            <TableCell className={classes.bodyCell}>
                                                                <TextField value={item[e] ?? ""}
                                                                    variant="filled"
                                                                    color="primary" size="small" focused
                                                                    disabled={type === "view" }
                                                                    className={classes.customText}
                                                                    type={item?.field[e]}
                                                                    onChange={(val) => {
                                                                        setRowData(val.target.value, item, e)
                                                                    }} />
                                                            </TableCell>
                                                            {/* {ind === arr.length - 1 && <DeleteIcon onClick={() => removeObjectFromArray(item.id)} />} */}
                                                        </>
                                                    )
                                                })
                                            }
                                            <TableCell className={classes.bodyCell}>
                                                <Typography className={classes.headText}> {item?.taxableAmount} </Typography>
                                            </TableCell>
                                            <TableCell className={classes.bodyCell}>
                                                <Typography className={classes.headText}> {item?.taxGroup} </Typography>
                                            </TableCell>
                                            <TableCell className={classes.bodyCell}>
                                                <Typography className={classes.headText}> {item?.taxes} </Typography>
                                            </TableCell>
                                            <TableCell className={classes.bodyCell}>
                                                <Typography className={classes.headText}> {item?.subtotal} </Typography>
                                            </TableCell>
                                            {type !== "view" && <TableCell className={classes.bodyCell} sx={{textAlign: "center" }}>
                                                <Box sx={{ cursor: "pointer" }}>
                                                    <DeleteIcon onClick={() => removeObjectFromArray(item)} />
                                                </Box>
                                            </TableCell>}
                                        </TableRow>
                                    )
                                })
                            }
                            {
                                type !== "view" &&
                                <TableRow className={classes.billLineCell}>
                                    <Typography className={classes.addBillLine} onClick={addClick}>+ {t("AddNewBillLine")}</Typography>
                                </TableRow>
                            }
                        </TableBody>
                }
            </Table>
        </Box>
    )
}

export default withNamespaces("freeTextInvoice")(PropertyUnitDetails)