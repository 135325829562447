import { Box, Grid } from '@mui/material'
import React from 'react'
import { SearchFilter, TableWithPagination } from '../../components'
import { AmenitiesBookingTableHeading, AmenitiesBookingTablePath } from '../../utils'
// import { FabButton } from './components'
export const ParkingBookingTable = (
    {
        openPopup = () => false,
        openDeclinePopup = () => false
    }
) => {


    const dummyJson = [
        {
            booking_id: "BKID-123431",
            amenity: "Shuttle Court",
            booking_from: "22 Jul 22",
            booking_to: "22 Jul 22",
            source: "Resident",
            time_range: "08:00 - 09:00",
            booked_by: "Doris Hughes",
            payment_status: "Unpaid",
            slot_status: "Unpaid"
        },
        {
            booking_id: "BKID-123431",
            amenity: "Shuttle Court",
            booking_from: "22 Jul 22",
            booking_to: "22 Jul 22",
            source: "Resident",
            time_range: "08:00 - 09:00",
            booked_by: "Doris Hughes",
            payment_status: "Unpaid",
            slot_status: "Decline"
        },
    ]

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <SearchFilter placeholder={"Search Booking"} value={""} handleChange={() => false} />
                </Grid>
            </Grid>
            <TableWithPagination
                rows={dummyJson ?? []}
                heading={AmenitiesBookingTableHeading}
                path={AmenitiesBookingTablePath}
                onClick={() => false}
                handleIcon={openDeclinePopup}
                dataType={[
                    { type: ["more_6_ins_item"], name: "icon" },
                    { type: ["text"], name: "booking_id" },
                    { type: ["text"], name: "amenity" },
                    { type: ["text"], name: "booking_from" },
                    { type: ["text"], name: "booking_to" },
                    { type: ["text"], name: "source" },
                    { type: ["text"], name: "time_range" },
                    { type: ["text"], name: "booked_by" },
                    { type: ["aminities_booking_status"], name: "payment_status" },
                    { type: ["aminities_booking_status"], name: "slot_status" },
                ]}
                tableType="no-side"
                showpagination={true}
                height={'calc(100vh - 320px)'}
            />
            {/* <FabButton openPopup={openPopup} /> */}
        </Box>
    )
}