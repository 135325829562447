import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const CardStyle = makeStyles((theme) => ({
  card: {
    border: "2px solid white",
    borderRadius: "4px",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    width: "100%",
    maxWidth: (props) => props?.maxWidth ?? "187px",
  },
  managericon: {
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#E29336",
    justifyContent: "center",
    padding: "10px 15px",
  },
  roles: {
    fontSize: "16px",
    color: "#091B29",
    fontFamily: SemiBold,
    marginBottom: "0 !important",
    marginLeft: "10px",
  },
  selectBox: {
    display: "flex",
    alignItems: "center",
    padding: "5px",
    backgroundColor: "#F5F7FA",
    borderRadius: "20px",
    marginTop: "12px",
    height: "40px",
    cursor: "pointer",
  },
  avatarcard: {
    height: "40px",
    width: "40px",
    cursor: "pointer",
  },
  avatarcard1: {
    height: "30px",
    width: "30px",
    cursor: "pointer",
  },
  select: {
    color: "#091B29",
    fontSize: "12px",
    fontFamily: Bold,
    marginLeft: "10px",
  },
  addmember: {
    width: "100%",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  card1: {
    cursor: "pointer",
    borderBottom: "1px solid #E4E8EE",
  },
  Cardcontent: {
    [theme.breakpoints.up("sm")]: {
      padding: "8px 12px",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "8px 0px",
    },
  },
  title1: {
    fontSize: "14px",
    color: "#091B29",
    fontFamily: Bold,
  },
  boxes: {
    flexFlow: "wrap !important",
  },
  sub: {
    color: "#4E5A6B",
    fontSize: "12px",
    fontFamily: SemiBold,
  },
  dialogBox: {
    padding: "16px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sumbit: {
    fontFamily: SemiBold,
    color: "#fff",
    backgroundColor: "#5078E1",
    width: "100%",
    height: "40px",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
  },
  tick: {
    width: "20px",
    height: "20px",
  },
}));
