import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Regular, SemiBold } from "../../../utils";
export const dashboardStyles = makeStyles((theme) => ({
  root: {
    padding: "24px 24px 80px",
    backgroundColor: theme.palette.background.secondary,
    height: `calc(100vh - 64px)`,
    overflow: "auto",
  },
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    minHeight: (props) => (props?.height ? props?.height : "100%"),
    padding: "19px",
    width: "100%",
    overflow: "auto",
  },
  titleFont: {
    color: "#091B29",
    fontSize: "16px",
    fontFamily: ExtraBold,
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
  },
  inrFont: {
    color: "#091B29",
    fontSize: "24px",
    fontFamily: Bold,
    marginTop: (props) => props?.marginTop ?? "13px",
  },
  inrFontPeriod: {
    color: "#091B29",
    fontSize: "14px",
    fontFamily: SemiBold,
    marginTop: "4px",
  },
  tabtitle: {
    fontSize: "12px",
    color: "#4E5A6B",
    fontFamily: Bold,
  },
  tabtitle1: {
    fontSize: "12px",
    color: "#5078E1",
    fontFamily: Bold,
  },
  tabsub: {
    fontSize: "10px",
    color: "#98A0AC",
    fontFamily: Regular,
  },
  tabs: {
    "&.MuiTab-root": {
      padding: "0px !important",
    },
  },
  after: {
    position: "absolute",
    right: "0",
    fontSize: "larger",
    color: "#e3e3e3",
    fontWeight: 100,
  },
  rootTab: {
    backgroundColor: theme.palette.background.paper,
    padding: "0px",
    marginTop: "-4px",
    borderBottom: "1px solid #00000014",
    minHeight: "54px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      marginTop: "-8px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginTop: "-8px",
    },
  },
  cardGraph: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    minHeight: (props) => (props?.height ?? "100%"),
    padding: (props) => props?.cardGraphPadding ?? "19px",
    width: "100%",
    // overflow: "auto",
  },
  flexBoxWithSpace: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: (props) => props?.padding ?? 0
  },
  selectBack1: {
    backgroundColor: "#F1F7FF",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px"
  },
  selectBack: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  title: {
    color: theme.typography.color.secondary.main,
    fontFamily: Bold,
    fontSize: "16px"
  },
  box: {
    width: "250px",
    marginRight: "14px",
    zIndex: 999
  },
  companyGroupDiv: {
    border: "1px solid #CED3DD",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
  }
}));
