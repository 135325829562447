import { useApolloClient } from "@apollo/client";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Typography } from "@mui/material";
import React from "react";
import { Account as AccountIMG } from "../../assets";
import { SearchFilter } from "../../components";
import { GET_ACCOUNT_BY_COMPANY } from "../../graphql/quotationQueries";
import { AccountList } from "../../screens/quotationDetails/components/accountList";
import { LocalStorageKeys } from "../../utils";
import { useStyles } from "./styles";

export const AccountListComponet = ({
  company_id = "",
  selectedAcc = "",
  chooseAccount = () => false,
}) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [account, setAccout] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getAccount = (searchText) => {
    setSearch(searchText);
    client
      .query({
        query: GET_ACCOUNT_BY_COMPANY,
        fetchPolicy: "network-only",
        variables: {
          client: localStorage.getItem(LocalStorageKeys.clinetID),
          id: company_id,
          searchText,
        },
      })
      .then((response) => {
        setAccout(response.data.contact_account);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(true);
  };
  const choose = (val) => {
    chooseAccount(val);
    setAnchorEl(false);
  };

  React.useEffect(() => {
    getAccount("");
    // eslint-disable-next-line
  }, []);

  return (
    <Box >
      <Box className={classes.parent}>
      <Box onClick={handleClick} aria-describedby={id}>
        <SearchFilter
          value={search}
          handleChange={(e) => getAccount(e)}
          placeholder="Search Existing Account"
        />
      </Box>
      {anchorEl && (
        <Box className={classes.accountListRoot}>
          {account?.map((val) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                p={1}
                onClick={() => choose(val)}
              >
                <Box className={classes.AccountImg1}>
                  <AccountIMG color="#98A0AC" />
                </Box>
                <Box flexGrow={1} marginLeft="8px">
                  <Typography className={classes.accountNamelist}>
                    {val?.name}
                  </Typography>
                  <Typography className={classes.accountNo}>
                    {val?.account_no}
                  </Typography>
                </Box>
                {val?.id === selectedAcc?.id && (
                  <Box>
                    <DoneIcon fontSize="small" color="primary" />
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      )}
      </Box>

      <Box height="12px" />

      {selectedAcc?.id && (
        <AccountList
          iconColor="#5AC782"
          color="#EEF9EE"
          disable
          value={selectedAcc}
        />
      )}
    </Box>
  );
};
