export const EntriesPath = [
  "image",
  "vendor_id",
  "vendor_type",
  "vendor_person_name",
  "reference_id",
  "requested_by",
  "unit",
  "checked_in_gate",
  "checked_in_by",
  "checked_in",
  "checked_out_by",
  "checked_out_gate",
  "checked_out",
  "status"

];

export const EntriesHeading = [
  { title: " ", field: "image" },
  { title: "Vendor ID", field: "vendor_id" },
  { title: "Vendor Name", field: "vendor_type" },
  { title: "Vendor Person Name", field: "vendor_person_name" },
  { title: "Reference ID", field: "reference_id" },
  // { title: "Requested By", field: "requested_by" },
  { title: "Unit No", field: "unit" },
  { title: "Entry Gate", field: "checked_in_gate" },
  // { title: "Checked In By", field: "check_in_by" },
  { title: "Checked In", field: "checked_in" },
  { title: "Exit Gate", field: "checked_out_gate" },
  // { title: "Checked Out By", field: "checked_out_by" },
  { title: "Checked Out", field: "checked_out" },
  { title: "status", field: "status" },

];
export const EntriesType = [
  { type: ["avatarmanagement"], name: "image" },
  { type: ["text"], name: "vendor_id" },
  { type: ["text"], name: "vendor_type" },
  { type: ["text"], name: "vendor_person_name" },
  { type: ["text"], name: "reference_id" },
  // { type: ["text"], name: "requested_by" },
  { type: ["text"], name: "unit" },
  { type: ["text"], name: "checked_in_gate" },
  // { type: ["text"], name: "checked_in_by" },
  { type: ["text"], name: "checked_in" },
  { type: ["text"], name: "checked_out_gate" },
  // { type: ["text"], name: "checked_out_by" },
  { type: ["text"], name: "checked_out" },
  { type: ["text"], name: "status" },

];
