/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Container, Typography } from "@mui/material";
import React ,{useLayoutEffect} from "react";
import PowerdBy from "../../assets/powerdby";
import PropGoToComponent from '../../assets/propgoto';
import { useStyles } from "./style";
import { WeatherComponent } from "../../components";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../router/routes";
import jwt_decode from "jwt-decode";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { WelcomePageIcon } from "../../assets";


export const WelcomePage = () => {

      // classes
      const classes = useStyles();
      const history = useHistory();
      const search = useLocation().search;
      const welcomePageAuthToken = new URLSearchParams(search).get("token");
      const [decoded, setDecoded] = React.useState({
        data: null,
        token: "",
      });
      const alert = React.useContext(AlertContext);

      useLayoutEffect(() => {
        if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
          const tokenData = jwt_decode(welcomePageAuthToken);
          setDecoded({
            data: tokenData,
            token: welcomePageAuthToken,
          });
        }
      }, []);

      const getStarted = () => {
            NetworkCall(
                  `${config.authapi}/auth/verifyUser/?token=${decoded?.token}`,
                  NetWorkCallMethods.post,
                  {},
                  null,
                  false,
                  false
            )
                  .then((response) => {

                        if (response.status === 200) {
                              if (response?.data?.is_allowed) {
                                    history.push(
                                          Routes.setpassword + "?welcomeAuthToken=" + welcomePageAuthToken
                                    );
                              } else {
                                    history.push(Routes.login);
                              }
                        }
                  })
                  .catch((err) => {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Some thing went wrong",
                        });
                  });
      };
      return (
            <div>
                  {/* logo */}
                  <Box className={classes.logoIcon}>
                        <PropGoToComponent />
                  </Box>
                  {/* body */}
                  <Container maxWidth="sm" style={{ padding: 0 }}>
                        <div className={classes.signupform}>
                              <Box>
                                    <Box className={classes.content}>
                                          {/* title */}
                                          <Typography className={classes.text}>Hi <span className={classes.text1}>{decoded && decoded?.data?.username ? decoded?.data?.username : ""},</span>
                                          </Typography>
                                    </Box>
                                    {/* company list */}
                                    <div className={classes.placeHolder}>
                                          <WelcomePageIcon />
                                    </div>

                              </Box>
                              {/* button */}
                              <Box className={classes.content}>
                                    <Button
                                          fullWidth
                                          variant="contained"
                                          className={classes.btn}
                                          onClick={getStarted}
                                    >
                                          <Typography className={classes.buttonTextStyle}>
                                                Get Started
                                          </Typography>
                                    </Button>
                                    {/* powered by logo */}
                                    <center>
                                          <PowerdBy height="32px" top="8px" />
                                    </center>
                              </Box>
                        </div>
                  </Container>
                  <WeatherComponent />
            </div >
      );
};
