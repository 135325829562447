import {
  Box
} from "@mui/material";
import React from "react";
import { CreateQuotationListContext } from "../../../contexts/createQuotationContext";
import { LeaseForm, SaleForm } from "../component";
export const LeadDetails = (props) => {
  const { dataNew, updateStateNew, setDataNew } = React.useContext(CreateQuotationListContext);
  return (
    <Box >

      {
        (dataNew?.searchdata?.revenue_type === "Lease" || dataNew?.searchdata?.revenue_type === "Manage") &&
        <LeaseForm
          data={dataNew}
          updateState={updateStateNew}
          purpose={dataNew?.searchdata?.property_purpose}
          type={dataNew?.searchdata?.revenue_type}
          setData={setDataNew}
        />
      }

      {
        dataNew?.searchdata?.revenue_type === "Sale" &&
        <SaleForm
          data={dataNew}
          updateState={updateStateNew}
          purpose={dataNew?.searchdata?.property_purpose}
          type={dataNew?.searchdata?.revenue_type}
          setData={setDataNew}
        />
      }

      {/* Short Term Rental */}
      {
        dataNew?.searchdata?.property_purpose === "Short Term Rental" &&
        <LeaseForm
          data={dataNew}
          purpose={dataNew?.searchdata?.property_purpose}
          type={dataNew?.searchdata?.revenue_type}
          updateState={updateStateNew}
          setData={setDataNew}
        />
      }


    </Box>


  );
};
