// import { LeadStylesParent } from "./style";
// // styles
// const classes = LeadStylesParent();
// export const TabList = (data) => [
//   {
//     label: "Activity Comments",
//     value: 1,
//     className: data?.tabValue === 1 ? classes.tabtitle1 : classes.tabtitle,
//     selectBack: data?.tabValue === 1 ? classes.selectBack1 : classes.selectBack,
//   },
//   {
//     label: "Number of Units (03)",
//     value: 2,
//     className: data?.tabValue === 2 ? classes.tabtitle1 : classes.tabtitle,
//     selectBack: data?.tabValue === 2 ? classes.selectBack1 : classes.selectBack,
//   },
//   {
//     label: "To Do List",
//     value: 3,
//     className: data?.tabValue === 3 ? classes.tabtitle1 : classes.tabtitle,
//     selectBack: data?.tabValue === 3 ? classes.selectBack1 : classes.selectBack,
//   },
//   {
//     label: "Activity",
//     value: 4,
//     className: data?.tabValue === 4 ? classes.tabtitle1 : classes.tabtitle,
//     selectBack: data?.tabValue === 4 ? classes.selectBack1 : classes.selectBack,
//   },
// ];

export const UnitPath = ["image", "name", "id", "propertyname"];
export const UnitHeading = [
  { title: "Image", field: "image" },
  { title: "Name", field: "name" },
  { title: "Unit Id", field: "id" },
  { title: "Property Name", field: "propertyname" },
];

export const ToDoPath = ["", "Name", "Description"];
export const ToDoHeading = [
  { title: "", field: "checkbox" },
  { title: "Name", field: "Name" },
  { title: "Description", field: "Description" },
  { title: "Edit", field: "icon" },
];

export const ActivityPath = [
  "image",
  "name",
  "info",
  "Date",
  "CreatedBy",
  "Priority",
  "Status",
];
export const ActivityHeading = [
  { title: "", field: "image" },
  { title: "Activity Name", field: "name" },
  { title: "Action Info", field: "info" },
  { title: "Date", field: "Date" },
  { title: "Created By", field: "CreatedBy" },
  { title: "Priority", field: "Priority" },
  { title: "Status", field: "Status" },
  { title: "Edit", field: "icon" },
];
