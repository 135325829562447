
import { Routes } from "./routes";

/**
 * The below function define redirection for a particular route based on the role
 */
export const Redirections = (userRole) => {
  switch (userRole) {
    default:
      return "";
  }
};

export const LoginSuccess = (routesList) => {
  if (routesList?.length > 0) {
    let path = Routes?.[routesList?.[0]]
    return path ?? null
  }
};

export const Access = (userRole, path, routes) => {
  let array = [];
  let routeList = Routes;
  array = routes?.map(val => routeList?.[val]);
  return array?.indexOf(path)
};
