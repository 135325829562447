import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import Chip from '@mui/material/Chip';
import React from "react";
import { filterStyles } from "./styles";
export const ClearFilter = ({ state = {}, clear = () => false, filter = false }) => {
    const classes = filterStyles();
    return (
        <div>
            {
                filter ?
                    <>
                        {
                            state?.pet || state?.facilitesbool || state?.unittype || state?.propertyType || state?.range1 || state?.range2 || state?.rangetype || state?.budget1 || state?.budget2 || state?.budgettype || state?.aminities || state?.facilites?.length > 0 ?
                                <Box display="flex" alignItems="center" marginTop="18px">
                                    {
                                        state?.aminities?.length > 0 &&
                                        <Box marginLeft="12px">
                                            {
                                                state?.aminities?.length === 1 ?
                                                    <Chip
                                                        className={classes.cancelBtn}
                                                        deleteIcon={<CloseIcon style={{ color: "#98A0AC", fontSize: "18px" }} />}
                                                        label={`${state?.aminities[0]?.label}`}
                                                        onDelete={() => clear("aminities", "amenities")}
                                                    /> :
                                                    <Chip
                                                        className={classes.cancelBtn}
                                                        deleteIcon={<CloseIcon style={{ color: "#98A0AC", fontSize: "18px" }} />}
                                                        label={`${state?.aminities[0]?.label} +${state?.aminities?.length - 1}`}
                                                        onDelete={() => clear("aminities", "amenities")}
                                                    />
                                            }


                                        </Box>
                                    }

                                    {
                                        state?.range1 && state?.range2 && state?.rangetype?.label ?
                                            <Box marginLeft="12px">
                                                <Chip
                                                    className={classes.cancelBtn}
                                                    deleteIcon={<CloseIcon style={{ color: "#98A0AC", fontSize: "18px" }} />}
                                                    label={`${state?.range1} -  ${state?.range2}  ${state?.rangetype?.label}`}
                                                    onDelete={() => clear("range", "areaRequirements")}
                                                />

                                            </Box>
                                            : null
                                    }

                                    {
                                        state?.facilitesbool ?
                                            <Box marginLeft="12px">
                                                <Chip
                                                    className={classes.cancelBtn}
                                                    deleteIcon={<CloseIcon style={{ color: "#98A0AC", fontSize: "18px" }} />}
                                                    label={`${state?.facilitesbool} Days`}
                                                    onDelete={() => clear("facilitesbool")}
                                                />

                                            </Box>
                                            : null
                                    }
                                    {
                                        state?.unittype?.length > 0 &&
                                        <Box marginLeft="12px">

                                            {
                                                state?.unittype?.length === 1 ?
                                                    <Chip
                                                        className={classes.cancelBtn}
                                                        deleteIcon={<CloseIcon style={{ color: "#98A0AC", fontSize: "18px" }} />}
                                                        label={`${state?.unittype[0]?.label}`}
                                                        onDelete={() => clear("unittype", "unit_type")}
                                                    /> :
                                                    <Chip
                                                        className={classes.cancelBtn}
                                                        deleteIcon={<CloseIcon style={{ color: "#98A0AC", fontSize: "18px" }} />}
                                                        label={`${state?.unittype[0]?.label} +${state?.unittype?.length - 1}`}
                                                        onDelete={() => clear("unittype", "unit_type")}
                                                    />
                                            }


                                        </Box>
                                    }

                                    {
                                        state?.furnish ?
                                            <Box marginLeft="12px">
                                                <Chip
                                                    className={classes.cancelBtn}
                                                    deleteIcon={<CloseIcon style={{ color: "#98A0AC", fontSize: "18px" }} />}
                                                    label={state?.furnish}
                                                    onDelete={() => clear("furnish", "furnishing")}
                                                />

                                            </Box>
                                            : null
                                    }
                                    {
                                        state?.budget1 && state?.budget2 && state?.budgettype?.label ?
                                            <Box marginLeft="12px">
                                                <Chip
                                                    className={classes.cancelBtn}
                                                    deleteIcon={<CloseIcon style={{ color: "#98A0AC", fontSize: "18px" }} />}
                                                    label={`${state?.budget1} -  ${state?.budget2}  ${state?.budgettype?.label}`}
                                                    onDelete={() => clear("budget", "budget")}
                                                />

                                            </Box>
                                            : null
                                    }


                                    {/* {
                            state?.facilites && state?.facilitestype ?
                                <Box marginLeft="12px">
                                    <Button onClick={() => clear("facilites")} variant="contained" className={classes.cancelBtn} endIcon={<CloseIcon style={{ fontSize: "16px", marginTop: "-4px" }} />}>
                                        {state?.facilites}&nbsp;{state?.facilitestype}
                                    </Button>
                                </Box>
                                : ""
                        } */}
                                    {
                                        state?.facilites?.length > 0 &&
                                        <Box marginLeft="12px">
                                            {
                                                state?.facilites?.length === 1 ?
                                                    <Chip
                                                        className={classes.cancelBtn}
                                                        deleteIcon={<CloseIcon style={{ color: "#98A0AC", fontSize: "18px" }} />}
                                                        label={`${state?.facilites[0]?.label}`}
                                                        onDelete={() => clear("facilites", "facilities")}
                                                    /> :
                                                    <Chip
                                                        className={classes.cancelBtn}
                                                        deleteIcon={<CloseIcon style={{ color: "#98A0AC", fontSize: "18px" }} />}
                                                        label={`${state?.facilites[0]?.label} +${state?.facilites?.length - 1}`}
                                                        onDelete={() => clear("facilites", "facilities")}
                                                    />
                                            }


                                        </Box>
                                    }
                                    {
                                        state?.pet &&
                                        <Box marginLeft="12px">
                                            <Chip
                                                className={classes.cancelBtn}
                                                deleteIcon={<CloseIcon style={{ color: "#98A0AC", fontSize: "18px" }} />}
                                                label={state?.pet}
                                                onDelete={() => clear("pet", "isPetAllowed")}
                                            />

                                        </Box>

                                    }

                                </Box>
                                : window.location.pathname !== "/result" && <Box height="40px" />
                        }
                    </>
                    : window.location.pathname !== "/result" && <Box height="40px" />


            }


        </div>
    )
}