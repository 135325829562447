import React from 'react';
import { MilstoneTemplate, TemplateAmount } from '../../../assets'
import {
    Box, Stack, Typography, Button
} from "@mui/material";
import { useStylesDetailsCard } from "./styles";
import { DialogBox, TemplateDrawer } from '../../../components';
import { CreateQuotationListContext } from "../../../contexts/createQuotationContext";
import { useApolloClient } from "@apollo/client";
import { getMailstoneTemplate } from "../../../graphql/quotationQueries";
import { LocalStorageKeys, AlertProps } from "../../../utils";
import { MileStoneTemplate } from "../../createquotation/component/mileStoneTemplate";
import {
    saledataTypequtation,
    saleheadingqutation,
    salepathqutation,
} from "../../../utils/createQuotationUtils";
import { AlertContext } from '../../../contexts/index';


export const TemplateComponent = ({
    total = 0,
    symbol = "$"
}) => {
    const client = useApolloClient();
    const classes = useStylesDetailsCard();
    const alert = React.useContext(AlertContext);

    const { dataNew, setDataNew } = React.useContext(CreateQuotationListContext);

    // states
    const [openDialog, setOpenDialog] = React.useState(false)
    const [valuetType, setValuetType] = React.useState("")

    console.log(dataNew?.payment_period_value, "lll")
    // function
    const updateDialog = (open, close) => {
        if (close) {
            return setOpenDialog(!openDialog)
        }
        if (open && !dataNew?.reload) {
            getTemplate(dataNew?.payment_period_value, true)
        }
        else {
            if (dataNew?.mailstoneLists?.some((x) => x?.value_type === "percentage")) {
                setValuetType("percentage")
            }
            else if (dataNew?.mailstoneLists?.some((x) => x?.value_type === "amount")) {
                setValuetType("amount")
            }
            else {
                setValuetType("variable")
            }
        }
        setOpenDialog(!openDialog)
    }
    const getTotal = (data) => {
        // get total count
        let totalCount = data.reduce(function (prev, current) {
            return prev + +current.percentage_value;
        }, 0);

        return totalCount;
    };
    const getCalculation = (amount = 0, percentage) => {
        return (((Number(total) - Number(amount)) * percentage) / 100)
    }
    const getTemplate = async (value, newSelect) => {
        client
            .query({
                query: getMailstoneTemplate,
                fetchPolicy: "network-only",
                variables: {
                    client: localStorage.getItem(LocalStorageKeys.clinetID),
                    template_id: value?.value,
                },
            })
            .then((response) => {
                if (value?.template_type === "percentage") {
                    setValuetType("percentage")
                }
                else if (value?.template_type === "amount") {
                    setValuetType("amount")
                }
                else {
                    setValuetType("variable")
                }
                const data = response?.data?.milestone_template_items?.map((val) => {
                    return { ...val, milestone: `${val?.delivery_milestone_masters?.name}`, percentage_value: `${val?.percentage} ${value?.template_type === "percentage" ? '%' : ''}` };
                });
                if (value?.template_type === "amount") {
                    return setDataNew({
                        ...dataNew,
                        payment_period: "Milestone Based",
                        delivery_timestone_template: value,
                        milestoneTemplate: value,
                        mailstoneLists: response?.data?.milestone_template_items?.map((val, index) => {
                            if (index === 0) {
                                return { ...val, milestone: `${val?.delivery_milestone_masters?.name}`, amount_text_value: "", percentage_value: val?.percentage };
                            }
                            else {
                                return { ...val, milestone: `${val?.delivery_milestone_masters?.name}`, percentage_value: getCalculation(0, val?.percentage ?? 0) };
                            }
                        }),
                        mileStoneName: value?.label,
                        payment_period_value: value,
                        typeValue: value?.template_type ?? "",

                        // error
                    });
                }
                if (value?.template_type === "variable") {
                    return setDataNew({
                        ...dataNew,
                        payment_period: "Milestone Based",
                        delivery_timestone_template: value,
                        milestoneTemplate: value,
                        mailstoneLists: response?.data?.milestone_template_items?.map((val) => {
                            return { ...val, milestone: `${val?.delivery_milestone_masters?.name}`, amount_text_value: "", percentage_value: "" };
                        }),
                        mileStoneName: value?.label,
                        payment_period_value: value,
                        typeValue: value?.template_type ?? "",
                        // error
                    });
                }
                // let error = data.error;
                // error.payment_period= "";
                setDataNew({
                    ...dataNew,
                    payment_period: "Milestone Based",
                    delivery_timestone_template: value,
                    mailstoneLists: data,
                    mileStoneName: value?.label,
                    payment_period_value: value,
                    milestoneTemplate: value,
                    typeValue: value?.template_type ?? "",
                    // error
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const mistoneUpdate = (value) => {
        getTemplate(value)
    }
    const amountOnchange = (value, index) => {

        const key = 'percentage_value'

        let result = []

        if (valuetType === "variable") {
            let allProperty = [...dataNew?.mailstoneLists];
            allProperty[index] = {
                ...allProperty[index],
                [key]: value?.target?.value,
            };
            result = [...allProperty]
        }
        else {
            result = dataNew?.mailstoneLists?.map((val, i) => {
                return {
                    ...val,
                    percentage_value: i === 0 ? value?.target?.value?.length > 0 ? value?.target?.value : "" : getCalculation(value?.target?.value?.length > 0 ? value?.target?.value : 0, val?.percentage)
                }
            })
        }

        setDataNew({
            ...dataNew,
            mailstoneLists: result,
        });


    }
    const sumbit = () => {
        if (valuetType === "percentage") {
            setDataNew({
                ...dataNew,
                reload: true,
                isValid: false
            })
            return setOpenDialog(!openDialog)
        }
        if (valuetType === "amount" && (dataNew?.mailstoneLists?.[0]?.percentage_value?.length === 0 || dataNew?.mailstoneLists?.[0]?.percentage_value === "0" || dataNew?.mailstoneLists?.[0]?.percentage_value === 0 || !dataNew?.mailstoneLists?.[0]?.percentage_value.length > 0)) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please Enter Amount",
            });
            setDataNew({
                ...dataNew,
                // reload: true,
                isValid: false
            })
            return false
        }
        else {
            const totalCount = getTotal(dataNew?.mailstoneLists)
            if (total !== totalCount) {

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Amount must be grater than Total Amount",
                });
                setDataNew({
                    ...dataNew,
                    isValid: false,
                    reload: true,
                })
                return false
            }
        }
        setOpenDialog(!openDialog)
        setDataNew({
            ...dataNew,
            reload: true,
            isValid: true
        })
    }
    // React.useEffect(() => {
    //     if (!dataNew?.reload && openDialog) {
    //         getTemplate(dataNew?.payment_period_value)
    //     }
    //     // eslint-disable-next-line
    // }, [openDialog])
    return (
        <div> <Stack direction="row" spacing={1} alignItems='center' justifyContent={'space-between'} className={classes.templateRoot}>
            <Box display='flex' alignItems={'center'}>
                <MilstoneTemplate />
                <Box ml={1}>
                    <Typography className={classes.templateTitle}>{dataNew?.quotation_milestone?.label ?? "-"}</Typography>
                    <Typography className={classes.templateDesc}>Milestone Configuration</Typography>
                </Box>
            </Box>
            <Typography className={classes.templateUpdate} onClick={() => updateDialog(true)}>Update</Typography>
        </Stack>

            {/* dialog */}
            <DialogBox
                // height={300}
                open={openDialog}
                onClose={() => updateDialog(false, true)}
                handleClose={() => updateDialog(false, true)}
                maxWidth="sm"
                background
                header="Milestone Configuration"
                component={<TemplateConfiguration mistoneUpdate={mistoneUpdate} milestoneTemplate={dataNew?.delivery_timestone_template} dataNew={dataNew} valuetType={valuetType} amountOnchange={amountOnchange} total={total} symbol={symbol} sumbit={sumbit} />}
            />
        </div>
    )
}


const TemplateConfiguration = ({
    mistoneUpdate = () => false,
    milestoneTemplate = {},
    dataNew = {},
    valuetType = "",
    amountOnchange = () => false,
    total = "0",
    symbol = "-",
    sumbit = () => false
}) => {
    const classes = useStylesDetailsCard();
    console.log(dataNew, "dataNew")
    return (
        <Box>
            <Stack direction="row" spacing={1} alignItems='center' justifyContent={'space-between'} className={classes.amountRoot}>
                <Box display='flex' alignItems={'center'}>
                    <TemplateAmount />
                    <Typography className={classes.templateAmountTilte}>AVAILABLE AMOUNT TO APPLY</Typography>
                </Box>
                <Typography className={classes.templateAmountTilte} >{symbol ?? "-"} {total ?? 0}</Typography>
            </Stack>
            <Box p={2}>
                <TemplateDrawer milestoneTemplate={milestoneTemplate} mistoneUpdate={mistoneUpdate} company_id={dataNew?.company} is_selected={!dataNew?.mailstoneLists?.length > 0} />
                <Box height="18px" />
                {dataNew?.mailstoneLists?.length > 0 && (

                    <MileStoneTemplate
                        dataType={saledataTypequtation(valuetType)}
                        rows={dataNew?.mailstoneLists}
                        heading={saleheadingqutation(valuetType)}
                        path={salepathqutation(valuetType)}
                        title={dataNew?.mileStoneName}
                        amountOnchange={amountOnchange}
                        currency={symbol}
                    />

                )}
                <Box height="18px" />
                <Button className={classes.downBtn} onClick={sumbit}>Update & Save</Button>
            </Box>
        </Box>
    )
}