import React from "react";
import { withNavBars } from "../../HOCs";
import { ManagementCommittee } from "./managementcommittee";
class ManagementCommitteeParent extends React.Component {
  render() {
    return (
        <ManagementCommittee />

    );
  }
}
const props = {
  boxShadow: false
}
export default withNavBars(ManagementCommitteeParent , props);