import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    Cardcontent: {
        [theme.breakpoints.up("sm")]: {
            padding: "8px 12px",
        },

        [theme.breakpoints.down("sm")]: {
            padding: "8px 0px",
        },
    },
    title1: {
        fontSize: "16px",
        color: theme.typography.color.secondary,
        fontFamily: Bold,
    },
    boxes: {
        flexFlow: 'wrap !important'
    },
    sub: {
        color: theme.typography.color.primary,
        fontSize: "14px",
        fontFamily: SemiBold,
        textTransform: "capitalize",
        '& .ql-editor': {
            padding: '0px 0px 16px 0px',
            color: theme.typography.color.primary,
            fontSize: "14px",
            fontFamily: SemiBold,
            textTransform: "capitalize",
        }

    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    role_name:{
        color:theme.typography.color.primary,
        fontSize: "14px",
        fontFamily: SemiBold,
    }
}))