import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Stack, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { CustomDropDownStyles } from './style';
const calendarType = ["Daily", "Weekly", "Monthly"]

export const CustomDropDown = ({ type = "", setType = "" }) => {
    const classes = CustomDropDownStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const setDropdownValue = (value) => {
        setType(value)
        setAnchorEl(null);
    }

    return (
        <Box p={1} ml={1} className={classes.customdrp}>
            <Stack direction={"row"}
                spacing={1}
                alignItems={"center"}
                onClick={handleClick}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >

                <Typography > {type} </Typography>

                <Box pl={2}>
                    {
                        open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                    }
                </Box>
            </Stack>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{  
                    style: {  
                      width: 120,  
                      marginTop:"6px",
                      marginLeft:"-8px"

                    },  
                 }} 
            >
                <MenuItem onClick={handleClose} style={{backgroundColor:"white"}}>
                    <Stack spacing={1}>
                        {
                            calendarType.map(e => {
                                return (
                                    <>
                                        <Stack direction={"row"} spacing={1} alignItems={"center"} onClick={() => setDropdownValue(e)}>
                                            <Typography className={classes.selected}> {e} </Typography>
                                        </Stack>
                                    </>
                                )
                            })
                        }
                    </Stack>
                </MenuItem>
            </Menu>
        </Box >
    )
}