import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles((theme) => ({
    box: {
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px",
        height: `calc(100vh - 217px)`,
        overflow: "auto",
        backgroundColor: "white"
    },
    box2: {
        height: `calc(100vh - 288px)`,
        overflow: "auto",
        backgroundColor: "white"
    },
}));