import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { AlertContext } from '../../contexts';
import { AlertProps } from '../../utils';
import { PricingDetailsTable } from "../pricingComponent";
import { useStyles } from "./styles";
export const ApplyDiscount = ({ data, unit, applyDiscountFun }) => {
    const classes = useStyles()
    const [applyDiscount, setApplyDiscount] = React.useState(false)
    const [list, setList] = React.useState(data)
    const alert = React.useContext(AlertContext);

    const [totals, setTotals] = React.useState({
        total: unit?.totalAmount,
        value: unit?.totalAmount
    });
    const getDiscount = (amount, value) => {

        return amount - (Number(amount) * (Number(value) / 100))
    }
    const getTax = (amount, percentage) => {
        return amount * (percentage / 100)
    }

    const onChangeDiscountValue = (value, index, amount, tax, taxPercent, taxable) => {

        if (value > 100) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Discount Less then 100",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        } else {

            let newList = [...list]
            newList[index].discountValue = parseInt(value);
            newList[index].discountPrice = getDiscount(amount, value);

            newList[index].discountedPrice = (Number(amount) - getDiscount(Number(amount), Number(value))) + (newList[index]?.fixTax - getTax(getDiscount(amount, value), taxPercent ?? 0))            // let temp = (newList[index]?.fixTax - getTax(getDiscount(amount, value), taxPercent)) + (amount - getDiscount(amount, value));

            if (taxable && taxPercent) {
                newList[index].appliedTaxAmount = getTax(getDiscount(amount, value), taxPercent);
            }
            setList(newList);
            let totaldiscount = 0;
            // eslint-disable-next-line
            list?.map((val) => {
                if (val?.discountPrice) {
                    totaldiscount = parseInt(totaldiscount) + parseInt(val?.discountPrice)
                } else {
                    totaldiscount = parseInt(totaldiscount) + parseInt(val?.componentvalue)
                }
            })
            let total = list.map(i => i?.discountPrice ?? 0).reduce((a, b) => a + b, 0)
            let totaltax = list.map(i => i?.appliedTaxAmount ?? 0).reduce((a, b) => a + b, 0)
            let finaltotal = total + totaltax
            setTotals({
                total: list?.[0]?.currencySymbol + " " + finaltotal,
                value: finaltotal
            })


        }

    }

    const submitDiscount = () => {
        applyDiscountFun(list, totals?.value, unit?.id, totals?.value)

    }
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Box sx={{ padding: "12px 12px 4px 12px" }} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography className={classes.heading}>PRICING DETAILS</Typography>
                    <Typography className={classes.heading1} onClick={() => setApplyDiscount(!applyDiscount)}>
                        {
                            applyDiscount ? "Apply Discounts" : "+ Add Discounts"
                        }
                    </Typography>
                </Box>
                <div className={classes.pricingContainer}>
                    {
                        list?.map((val, index) => {
                            return (
                                <PricingDetailsTable
                                    applyDiscount={applyDiscount}
                                    val={val}
                                    onChangeDiscountValue={(value) => onChangeDiscountValue(value, index, val?.componentvalue, val?.appliedTaxAmount, val?.taxPercentage, val?.taxable)}
                                />
                            )
                        })
                    }
                </div>
                <Box className={classes.final} p={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography className={classes.heading}>Final Total</Typography>
                    <Typography className={classes.heading}>
                        {totals?.total}

                    </Typography>
                </Box>

            </div>
            <Button variant="contained" className={classes.btn}
                onClick={submitDiscount}
                fullWidth>Update & Save</Button>
        </div>
    )
}