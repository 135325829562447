import Close from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { config } from "../../../config";
import { GET_PROPERTY_UNITS } from "../../../graphql/lead";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import {
  loadOptions,
  loadOptionsApis,
} from "../../../utils/asyncPaginateLoadOptions";
import { useStyles } from "../styles";
import { Details } from "../../../components/selectUnitComponent/component/details";
import { SelectUnits } from "../../../components/selectUnitComponent/component/ownerSelect";
import moment from "moment-timezone";

export const Owner = ({
  onClose = () => false,
  value = [],
  onChange = () => false,
  type = {},
  company = null,
  isEdit = false,
  datas = {},
  onDeleteUnits = () => false,
  is_show = false,
  purpose = "",
  typeData = "",
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    value: null,
    offset: 0,
    searchText: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [listing, setListing] = React.useState([]);

  //update state
  const updateState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  //get list
  const getList = (property, offset, search, reload) => {
    if (!reload) {
      setLoading(true);
    }
    const payload = {
      company_id: company,
      properties: type?.value === 2 ? [property] : undefined,
      vacant: ["Vacant"],
      owners: type?.value === 1 ? [property] : undefined,
      offset,
      limit: 10,
      search,
      lease_start_date:
        datas?.revenue_type?.value !== "Sale"
          ? `${moment(datas?.lease_start_date).format("YYYY-MM-DD")} 00:00:00` ?? undefined
          : undefined,
      lease_end_date:
        datas?.revenue_type?.value !== "Sale"
          ? `${moment(datas?.lease_end_date).format("YYYY-MM-DD")} 23:59:59`?? undefined
          : undefined,
      revenue_type: typeData ?? undefined,
      purpose: purpose ?? undefined,
      hand_over_date:
        datas?.revenue_type?.value === "Sale"
          ? `${moment(datas?.earlist_occupation_date).format("YYYY-MM-DD")}`?? null
          : undefined,
    };
    NetworkCall(
      `${config.api_url}/opportunity/units`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        let editedUnits = res?.data?.data?.map((val) => {
          return {
            ...val,
            name: val?.unit_name ?? "",
            is_active:
              datas?.units?.length > 0 &&
                datas?.units?.filter((i) => i?.id === val?.id)?.length > 0
                ? true
                : false,
          };
        });
        if (reload) {
          setListing(listing.concat(editedUnits));
        } else {
          setListing(editedUnits);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  //const onCheck Unit
  const checkUnit = (data) => {
    const edited = listing.map((item) =>
      item.id === data.id
        ? {
          ...item,
          is_active: !data?.is_active,
        }
        : item
    );
    setListing(edited);
    if (!data?.is_active === false) {
      onChange(
        datas?.units?.filter((x) => x?.id !== data?.id),
        state?.value?.value,
        type?.value,
        data?.id
      );
    } else {
      onChange(edited, state?.value?.value, type?.value);
    }
  };

  //onchange select
  const onChangeSelect = (val) => {
    getList(val?.value, 0, "", false);
    updateState("value", val);
  };
  //onSearch
  const onSearch = (val) => {
    getList(state?.value?.value, 0, val, false);
    updateState("searchText", val);
  };
  //fetch more
  const fetchMoreData = () => {
    getList(state?.value?.value, state?.offset + 10, "", true);
    setState({
      ...state,
      offset: state?.offset + 10,
    });
  };

  return (
    <Box>
      {type?.value && !is_show && (
        <Box display="flex" alignItems="center" className={classes.header}>
          <Box marginTop="4px">{type?.icon}</Box>
          <Box flexGrow={1} marginLeft="12px">
            <Typography className={classes.btnTitle}>{type?.label}</Typography>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                onClose();
                updateState("value", null);
              }}
              size="small"
            >
              <Close sx={{ color: "red" }} />
            </IconButton>
          </Box>
        </Box>
      )}
      <Box>
        {type?.value === 1 ? (
          <SelectUnits
            isPaginate={true}
            placeholder={type?.search}
            value={state?.value}
            onChange={(val) => onChangeSelect(val)}
            loadOptions={(search, array, handleLoading) =>
              loadOptionsApis(
                "lead_proxy/get_owner",
                { company_id: company },
                search,
                array,
                handleLoading,
                "data"
              )
            }
          />
        ) : (
          <SelectUnits
            isPaginate={true}
            placeholder={type?.search}
            value={state?.value}
            onChange={(val) => onChangeSelect(val)}
            loadOptions={(search, array, handleLoading) =>
              loadOptions(
                search,
                array,
                handleLoading,
                GET_PROPERTY_UNITS,
                "unit",
                { company_id: company }
              )
            }
          />
        )}

        {state?.value ? (
          <Details
            loading={loading}
            checkUnit={checkUnit}
            onRemove={() => {
              updateState("value", null);
              onDeleteUnits([], null, true);
            }}
            list={listing}
            selected={state?.value}
            company={company}
            onSearch={onSearch}
            fetchMoreData={fetchMoreData}
            searchText={state?.searchText ?? ""}
            height="calc(100vh - 480px)"
          />
        ) : (
          <>
            <Box height="calc(100vh - 480px)" />
          </>
        )}
      </Box>
    </Box>
  );
};
