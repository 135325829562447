import { Avatar, Box, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { AmenityPaymentDetailsStyle } from './style'
import {timeZoneConverter} from "../../../utils"

const AmenityPaymentDetails = (
    {
        amenityBookingDetail = {},
        t
    }
) => {
    const classes = AmenityPaymentDetailsStyle()
    return (
        <Box className={classes.box} p={2} ml={2} mt={1}>
            <Stack spacing={1}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <Avatar className={classes.avatar} src={amenityBookingDetail?.amenity_assets}></Avatar>
                        <Stack>
                            <Typography className={classes.name} noWrap>{amenityBookingDetail?.amenities_name}</Typography>
                            <Typography className={classes.id}>{amenityBookingDetail?.booking_id}</Typography>
                        </Stack>
                    </Stack>
                    <Stack>
                        <Typography className={classes.rightname}>{amenityBookingDetail?.currency + " " + amenityBookingDetail?.paid_amount}</Typography>
                        <Typography className={amenityBookingDetail?.payment_status === "Paid" ? classes.paid : classes.unpaid} noWrap>{amenityBookingDetail?.payment_status}</Typography>
                    </Stack>
                </Stack>
                {amenityBookingDetail?.paid_on && <Divider />}
                <Stack spacing={1}>
                    {amenityBookingDetail?.paid_on &&
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Typography className={classes.title}>{t("Paidon")}</Typography>
                            <Typography className={classes.content}>{timeZoneConverter(amenityBookingDetail?.paid_on)}</Typography>
                        </Stack>}
                </Stack>
            </Stack>
        </Box>
    )
}

export default withNamespaces("amenityBooking")(AmenityPaymentDetails)
