import gql from 'graphql-tag'
export const InvoiceDropdownQuery = gql`
query {
    invoice_receipt_settlement(where: { invoice_id: $invoice_id 
      settlement_no: {iregex:$search}
    }) {
      settlement_no
      id
      amount_tagged
      created_at
      invoice{
        company{
          currency{
          symbol
          }
        }
      }
      tagged_by: tagged_byByID {
        first_name
        image_url
        email_id
      }
      reciepts{
       reciepts_no
       url
       payment_mode
      }
    }
  }  
`;