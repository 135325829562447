import { Box, Button } from "@mui/material";
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, NetWorkCallMethods, useWindowDimensions, ValidateEmail } from "../../utils";
import { ServiceProviderDetails } from './serviceProviderDetails';
import { useStyles } from "./style";
import { mapResult, singleImageUpload, assestType, getRoutePermissionNew } from '../../utils/common';
import { GET_SERVICE_PROVIDER } from "../../graphql/queries";
import { useApolloClient } from "@apollo/client";

export const ServiceProviderCreateEdit = () => {
      const size = useWindowDimensions()
      const client = useApolloClient()
      const classes = useStyles(size)
      const { state } = useLocation();
      const mapLoad = !Boolean(state?.main?.serviceProviderID)
      const initial = {
            image: "",
            company: state?.main?.company,
            category: "",
            name: "",
            code: "",
            description: "",
            doorNo: '',
            addressLineOne: "",
            addressLineTwo: "",
            landmark: "",
            area: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            latitude: "",
            longitude: "",
            telephone: "",
            mobile: "",
            email: "",
            registration: "",
            registrationNo: "",
            bankName: "",
            branchName: "",
            currency: "",
            accountType: "",
            accountNo: "",
            routingType: "",
            routingCode: "",
            cashCollection: "",
            cheque: "",
            bankAddress1: "",
            bankAddress2: "",
            bankCity: "",
            bankCountry: "",
            facebook: "",
            twitter: "",
            linkedin: "",
            whatsapp: "",
            error: {
                  image: "",
                  company: "",
                  name: "",
                  telephone: "",
                  mobile: "",
                  email: "",
            }
      }

      const backdrop = React.useContext(BackdropContext);
      const [data, setData] = React.useState({ ...initial })
      const [disable, setDisable] = React.useState(false)
      const [load, setLoad] = React.useState(false)
      const alert = React.useContext(AlertContext);
      const auth = React.useContext(AuthContext)
      const history = useHistory();
      const [permission, setPermission] = React.useState({})

      // use effect to get permission
      React.useEffect(() => {
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                  setPermission(perm)
                  if (perm?.read) {
                        getInitialData()
                  }
            }
            // eslint-disable-next-line
      }, [auth, state?.main?.serviceProviderID]);

      const updateState = (k, v) => {
            let error = data?.error;
            error[k] = "";
            setData({ ...data, [k]: v, error })
      }

      const removeimg = () => {
            setData({ ...data, image: "" })
      }

      const getServiceProvider = () => {
            backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
            client.query({
                  query: GET_SERVICE_PROVIDER, fetchPolicy: 'network-only',
                  variables: { serviceProviderID: state?.main?.serviceProviderID }
            }).then((r) => {
                  const tempServiceProvider = r?.data?.service_providers_master?.[0]
                  setData({
                        image: tempServiceProvider?.image_url ?? "",
                        company: state?.main?.company ?? "",
                        category: tempServiceProvider?.profession_masterByID ?? "",
                        name: tempServiceProvider?.name ?? "",
                        code: tempServiceProvider?.service_provider_code ?? "",
                        description: tempServiceProvider?.description ?? "",
                        doorNo: tempServiceProvider?.service_providers_addressByID?.[0]?.door_no ?? "",
                        addressLineOne: tempServiceProvider?.service_providers_addressByID?.[0]?.street_1 ?? "",
                        addressLineTwo: tempServiceProvider?.service_providers_addressByID?.[0]?.street_2 ?? "",
                        landmark: tempServiceProvider?.service_providers_addressByID?.[0]?.landmark ?? "",
                        area: tempServiceProvider?.service_providers_addressByID?.[0]?.area ?? "",
                        city: tempServiceProvider?.service_providers_addressByID?.[0]?.city ?? "",
                        state: tempServiceProvider?.service_providers_addressByID?.[0]?.state ?? "",
                        country: tempServiceProvider?.service_providers_addressByID?.[0]?.country ?? "",
                        zipcode: tempServiceProvider?.service_providers_addressByID?.[0]?.pincode ?? "",
                        latitude: tempServiceProvider?.service_providers_addressByID?.[0]?.latitude ?? "",
                        longitude: tempServiceProvider?.service_providers_addressByID?.[0]?.longitude ?? "",
                        telephone: tempServiceProvider?.service_providers_addressByID?.[0]?.telephone_no ?
                              {
                                    mobile: tempServiceProvider?.service_providers_addressByID?.[0]?.telephone_no,
                                    mobile_code: tempServiceProvider?.service_providers_addressByID?.[0]?.telephone_code,
                              } : "",
                        mobile: tempServiceProvider?.service_providers_addressByID?.[0]?.mobile_no ?
                              {
                                    mobile: tempServiceProvider?.service_providers_addressByID?.[0]?.mobile_no,
                                    mobile_code: tempServiceProvider?.service_providers_addressByID?.[0]?.mobile_code,
                              } : "",
                        email: tempServiceProvider?.service_providers_addressByID?.[0]?.email_id ?? "",
                        registration: tempServiceProvider?.company_registration ?
                              {
                                    value: tempServiceProvider?.company_registration,
                                    label: tempServiceProvider?.company_registration,
                              } : "",
                        registrationNo: tempServiceProvider?.company_registration_no ?? "",
                        bankName: tempServiceProvider?.bank_name ?? "",
                        branchName: tempServiceProvider?.branch_name ?? "",
                        currency: tempServiceProvider?.currency_masterByID ?? "",
                        accountType: tempServiceProvider?.account_type ?
                              {
                                    value: tempServiceProvider?.account_type,
                                    label: tempServiceProvider?.account_type,
                              } : "",
                        accountNo: tempServiceProvider?.account_no ?? "",
                        routingType: tempServiceProvider?.routing_type ?
                              {
                                    value: tempServiceProvider?.routing_type,
                                    label: tempServiceProvider?.routing_type,
                              } : "",
                        routingCode: tempServiceProvider?.routing_code ?? "",
                        cashCollection: tempServiceProvider?.cash_collection_office ?? "",
                        cheque: tempServiceProvider?.cheque_name ?? "",
                        bankAddress1: tempServiceProvider?.address_1 ?? "",
                        bankAddress2: tempServiceProvider?.address_2 ?? "",
                        bankCity: tempServiceProvider?.bank_city ?? "",
                        bankCountry: tempServiceProvider?.bank_country ?? "",
                        facebook: tempServiceProvider?.facebook ?? "",
                        twitter: tempServiceProvider?.twitter ?? "",
                        linkedin: tempServiceProvider?.linkedin ?? "",
                        whatsapp: tempServiceProvider?.whatsapp ?? "",
                        error: initial?.error
                  })
                  setLoad(true)
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            })
      }

      const save = () => {
            if (validate()) {
                  setDisable(true)
                  const datas = {
                        name: data?.name ?? undefined,
                        is_active: true ?? undefined,
                        image_url: data?.image ?? undefined,
                        service_provider_code: data?.code ?? undefined,
                        company_id: state?.main?.companyID ?? undefined,
                        profession_id: data?.category?.value ?? undefined,
                        description: data?.description ?? undefined,
                        account_type: data?.accountType?.value ?? undefined,
                        routing_type: data?.routingType?.value ?? undefined,
                        routing_code: data?.routingCode ?? undefined,
                        cash_collection_office: data?.cashCollection ?? undefined,
                        bank_name: data?.bankName ?? undefined,
                        currency_id: data?.currency?.value ?? undefined,
                        account_no: data?.accountNo ?? undefined,
                        company_registration: data?.registration?.value ?? undefined,
                        branch_name: data?.branchName ?? undefined,
                        cheque_name: data?.cheque ?? undefined,
                        address_1: data?.bankAddress1 ?? undefined,
                        address_2: data?.bankAddress2 ?? undefined,
                        bank_city: data?.city ?? undefined,
                        bank_country: data?.country ?? undefined,
                        company_registration_no: data?.registrationNo ?? undefined,
                        facebook: data?.facebook ?? undefined,
                        twitter: data?.twitter ?? undefined,
                        linkedin: data?.linkedin ?? undefined,
                        whatsapp: data?.whatsapp ?? undefined,
                        telephone_code: data?.telephone?.mobile_code ?? undefined,
                        telephone_no: data?.telephone?.mobile ?? undefined,
                        mobile_code: data?.mobile?.mobile_code ?? undefined,
                        mobile_no: data?.mobile?.mobile ?? undefined,
                        door_no: data?.doorNo ?? undefined,
                        landmark: data?.landmark ?? undefined,
                        street_1: data?.addressLineOne ?? undefined,
                        street_2: data?.addressLineTwo ?? undefined,
                        area: data?.area ?? undefined,
                        city: data?.city ?? undefined,
                        state: data?.state ?? undefined,
                        zipcode: data?.zipcode ?? undefined,
                        country: data?.country ?? undefined,
                        email_id: data?.email ?? undefined,
                        latitude: parseFloat(data?.latitude) ?? undefined,
                        longitude: parseFloat(data?.longitude) ?? undefined,
                  };
                  if (state?.main?.serviceProviderID) {
                        datas["id"] = state?.main?.serviceProviderID;
                  }
                  NetworkCall(
                        `${config.api_url}/service-provider/upsert`,
                        NetWorkCallMethods.post,
                        datas, null, true, false
                  ).then((response) => {
                        history.goBack(-1)
                        alert.setSnack({
                              ...alert, open: true, severity: AlertProps.severity.success,
                              msg: `Service Provider ${state?.main?.serviceProviderID ? `Updated` : `Created`} Successfully.!!!`,
                        });
                        setDisable(false)
                  }).catch((error) => {
                        alert.setSnack({
                              ...alert, open: true, severity: AlertProps.severity.error,
                              msg: "Internal error. Please try again later.",
                        });
                        setDisable(false)
                  });
            } else { return false }
      }

      const checkSave = () => {
            if (state?.main?.serviceProviderID) {
                  if (permission.update) {
                        save()
                  } else {
                        alert.setSnack({
                              ...alert, open: true, msg: "Access denied. Contact your administrator.",
                              severity: AlertProps.severity.error,
                        })
                  }
            } else if (permission.create) {
                  save()
            } else {
                  alert.setSnack({
                        ...alert, open: true, msg: "Access denied. Contact your administrator.",
                        severity: AlertProps.severity.error,
                  })
            }
      }

      const getInitialData = () => {
            if (state?.main?.serviceProviderID) {
                  getServiceProvider()
            }
            else { setLoad(true) }
      }

      const validate = () => {
            let isValid = true;
            let error = data.error;
            if (data?.image?.length === 0) {
                  isValid = false;
                  error.image = "Profile Image is Required";
            }
            if (data?.company?.length === 0) {
                  isValid = false;
                  error.company = "Company Name is Required";
            }
            if (data?.name?.length === 0) {
                  isValid = false;
                  error.name = "Property Name is Required";
            }
            if (state?.main?.serviceProviderID) {
                  if (data?.telephone?.mobile?.length === 0) {
                        isValid = false;
                        error.telephone = "Telephone is Required";
                  }
                  if (data?.mobile?.mobile.length === 0) {
                        isValid = false;
                        error.mobile = "Mobile Phone is Required";
                  }
            }
            else {
                  if (data?.telephone?.length === 0) {
                        isValid = false;
                        error.telephone = "Business Phone is Required";
                  }
                  if (data?.mobile?.length === 0) {
                        isValid = false;
                        error.mobile = "Mobile Phone is Required";
                  }
            }
            // eslint-disable-next-line
            if (data?.email?.length !== 0 && !ValidateEmail(data?.email)) {
                  isValid = false;
                  error.email = "Enter valid Email";
            }

            if (!isValid) {
                  alert.setSnack({
                        ...alert, open: true, severity: AlertProps.severity.error,
                        msg: "Please fill all mandatory field",
                  })
            }
            setData({ ...data, error });
            return isValid;
      };

      const mapResultData = (map) => {
            let result = mapResult(map);
            setData({ ...data, ...result })
      }
      const updateimg = async (data) => {
            backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading...", });
            try {
                  const tumbil = await singleImageUpload(data, state?.main?.company?.value, assestType?.Thumbnails)
                  updateState("image", tumbil)
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            }
            catch (err) {
                  backdrop.setBackDrop({
                        ...backdrop, open: false,
                        message: "Some Thing Went Wrong"
                  });
            }
      }


      const render = () => {
            return <>
                  <Subheader title={"SERVICE PROVIDER ADD/EDIT"} goBack={() => history.goBack(-1)} />
                  <div className={classes.content}>
                        <ServiceProviderDetails id='pushTitle' data={data} updateState={updateState} mapResult={mapResultData}
                              removeimg={removeimg} updateimg={updateimg} mapLoad={mapLoad} load={load} />
                  </div>
                  <Box className={classes.bottombtn}>
                        <Button className={classes.Cancel}
                              onClick={() => history.goBack(-1)}>
                              Cancel
                        </Button>
                        <Button className={classes.next} onClick={checkSave}
                              disabled={disable === true ? true : false}>
                              {state?.main?.propertyID ? "Update" : "Submit"}
                        </Button>
                  </Box>
            </>
      }

      return <div className={classes.root}>
            {accessCheckRender(render, permission)}
      </div>
}
