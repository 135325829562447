import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";

export const managrmentStyles = makeStyles((theme) => ({
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "13px",
  },
  btnParent: {
    display: "flex",
    alignItems: "center",
    background: "white",
    position: "absolute",
    bottom: "0",
    width: "100%",
    padding: "7px 11px",
    borderTop: "1px solid #E4E8EE",
    justifyContent: "flex-end",
  },
  btn: {
    fontFamily: SemiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    padding: "5px 11px",
    marginLeft: "10px",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
      color: theme?.palette?.background?.tertiary1,
    },
  },
  outLine: {
    fontFamily: SemiBold,
    color: theme?.typography?.color?.primary,
    backgroundColor: "white",
    padding: "5px 11px",
    border: `1px solid ${theme?.palette?.primary?.main}`,
    fontSize: "12px",
    "&:hover": {
      color: theme?.typography?.color?.primary,
      backgroundColor: "white",
      border: `1px solid ${theme?.palette?.primary?.main}`,
    },
  },
  datePicker: {
    "& div": {
      "&  .react-datepicker__month": {
        padding: "1rem 0 33px !important",
      },
    },
  },
}));
