import { Box, Typography, IconButton, Slide ,Skeleton , Grid} from "@mui/material";
import React, { useEffect } from "react";
import { SkipForm, DeclineForm, ArrowIcon } from "../../../assets";
import { AlertDialog } from "../../../components";
import {
  Form,
  CardComponent,
  TermisionRequestForm,
  RenewalRequest,
  CancelDialog
} from "../components";
import TimelineForm from "../components/timelineForm";
import { useStyleTimeLine } from "../styles";
import {
  owner,
  ownerDay,
  ownerStatus,
  request,
  countuinity,
} from "../../../utils/agreementUtils";
import CloseIcon from "@mui/icons-material/Close";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import { config } from "../../../config";
export const Cards = ({
  list = [],
  details = {},
  reload = () => false,
  t,
  agreement_id = "",
  is_loaded = "",
  statusType = [],
  type = "",
  loadApi="",
  step=0,
  showType=false,
  termintation=()=>false,
  cancelDialog=()=>false,
  cancel=false,
  is_terminated=false,
  is_overall=false
}) => {
  const classes1 = useStyleTimeLine();
  // state
  const [open, setOpen] = React.useState({
    bool: false,
    type: "",
    isType: false,
    isUnderOnboard: false,
    skipBool: false,
    Decline: false,
    cardShow: false,
  });
  const [remark, setRemark] = React.useState({
    skip: "",
    decline: "",
  });
  const [show, setShow] = React.useState(false);
  const containerRef = React.useRef(null);
  const [checked, setChecked] = React.useState(false);
  const [dialog, setDialog] = React.useState({
    type: "",
    bool: false,
  });
  const [cardDetails, setCardDetails] = React.useState({});
  const [load, setLoad] = React.useState(true);


  const onRemarkChange = (key, value) => {
    setRemark({ ...remark, [key]: value });
  };
  const onClick = (val, data, isType, isUnderOnboard, is_show) => {
    setOpen({
      ...open,
      bool: !open?.bool,
      type: val ?? open?.type,
      data: data,
      isType,
      isUnderOnboard,
      cardShow: is_show,
    });
  };
  const onClickSkip = (val, data, isType, isUnderOnboard) => {
    setOpen({
      ...open,
      skipBool: !open?.skipBool,
      type: val ?? open?.type,
      data: data,
      isType,
      isUnderOnboard,
    });
  };
  const onClickDecline = (val, data) => {
    setOpen({
      ...open,
      Decline: !open?.Decline,
      type: val ?? open?.type,
      data: data,
    });
  };

  const handleChange = () => {
    setChecked(!checked);
  };
  const openDialog = (type, value) => {
    setDialog({
      type: type,
      bool: !dialog?.bool,
    });
  };



  const slideComponent = (
    <div className={classes1.slideCard}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography className={classes1.sliderTitle}>
          {t("AGREEMENT_REQUESTS")}
        </Typography>
        <IconButton onClick={handleChange}>
          <CloseIcon className={classes1.closeIcon} />
        </IconButton>
      </Box>
      <Box height={"12px"} />
      {request(t, openDialog , cancelDialog , showType).map((x, i) => {
        return (
          <>
          {
            x?.is_active && 
            <Box
              className={classes1.cardSlide}
              style={{ backgroundColor: x?.color }}
              onClick={x?.onClick}
            >
              <Box display="flex" alignItems="center">
                {x?.icon}
                <Box width={"8px"} />
                <Typography className={classes1.requestTitle}>
                  {x?.title}
                </Typography>
              </Box>
              <ArrowIcon color={"#98A0AC"} />
            </Box>
          }
            {(i === 0 || i === 1) && <Box height={"6px"} />}
          </>
        );
      })}
    </div>
  );
  // get lead card details
  const getcardDetials = () => {
    setLoad(false);
    const requestPayload = {
      id: agreement_id,
      status: statusType ?? [],
      type: type,
    };
    NetworkCall(
      `${config.api_url}/agreement/time_duration`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((response) => {
        setCardDetails(response?.data?.data);
        setLoad(true);
      })
      .catch((error) => {
        console.log(error);
        setLoad(true);
      });
  };
  useEffect(() => {
    if (statusType?.length > 0) {
      getcardDetials();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadApi , step]);
  return (
    <Box>
      {!load ? (
        <>
       {Array.apply(null, Array(3)).map((value, index) => (
            <Grid key={index} item style={{ padding: "12px" }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Skeleton
                    variant="rectangular"
                    height={'95px'}
                    style={{ borderRadius: "4px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </>
      ) : (
        <>
          <Box className={classes1.relativeCard}>
            <Box className={classes1.parentCard}>
              <Box>
                {owner(cardDetails?.lead_details).map((x) => {
                  return (
                    <CardComponent
                      title={x?.title}
                      subTitle={x?.subtitle}
                      image={x?.image}
                      color={x?.color}
                      backgroundColor={x?.backgroundColor}
                    />
                  );
                })}
              </Box>
              {cardDetails?.duration?.length > 0 && (
                <>
                  <Box height={"8px"} />
                  <Box>
                    {ownerDay(cardDetails).map((x) => {
                      return (
                        <CardComponent
                          title={x?.title}
                          subTitle={x?.subtitle}
                          image={x?.image}
                          color={x?.color}
                          backgroundColor={x?.backgroundColor}
                        />
                      );
                    })}
                  </Box>
                </>
              )}
              {(cardDetails?.over_all_time_taken?.length > 0 && is_overall) &&  (
                <>
                  <Box height={"8px"} />
                  {ownerStatus(cardDetails, show)?.map((x) => {
                    return (
                      <Box onClick={() => setShow(!show)}>
                        {x?.is_active && (
                          <CardComponent
                            title={x?.title}
                            subTitle={x?.subtitle}
                            image={x?.image}
                            color={x?.color}
                            backgroundColor={x?.backgroundColor}
                            is_divider={x?.is_divider}
                          />
                        )}
                      </Box>
                    );
                  })}
                </>
              )}
              {
                showType && 
                <>
                 <Box height={"8px"} />
              <Box>
                {countuinity(cardDetails).map((x) => {
                  return (
                    <CardComponent
                      title={x?.title}
                      subTitle={x?.subtitle}
                      image={x?.image}
                      color={x?.color}
                      backgroundColor={x?.backgroundColor}
                      border={x?.border}
                      boxShadow={x?.boxShadow}
                    />
                  );
                })}
              </Box></>
              }

             
              {
                !is_terminated && 
                <Box ref={containerRef} className={classes1.absoluteCard}>
                {!checked ? (
                  <Box
                    className={classes1.agreementRequestCard}
                    onClick={handleChange}
                  >
                    <Typography className={classes1.agreementRequest}>
                      {t("Agreement_Requests")}
                    </Typography>
                    <ArrowIcon color={"#78B1FE"} />
                  </Box>
                ) : (
                  <Slide
                    direction="up"
                    in={checked}
                    container={containerRef.current}
                  >
                    {slideComponent}
                  </Slide>
                )}
              </Box>
              }
            
            </Box>
          </Box>
          {
            !is_terminated && 
            dialog?.type === "Termination_Request" ? (
              <TermisionRequestForm open={dialog?.bool} setOpen={setOpen} t={t} />
            ) : (
              <RenewalRequest open={dialog?.bool} setOpen={setOpen} t={t} />
            )
          }
      
        </>
      )}

      <AlertDialog
        isNormal={true}
        header={t(`${open?.type === "assign" ? "Assign Task" : open?.type}`)}
        onClose={() => onClick(null)}
        open={open?.bool}
        component={
          <TimelineForm
            reload={reload}
            data={open?.data}
            type={open?.type}
            isType={open?.isType}
            isUnderOnboard={open?.isUnderOnboard}
            company={details?.company?.id}
            details={details}
            onClose={() => onClick(null)}
            is_show={open?.cardShow}
          />
        }
      />

      <AlertDialog
        isNormal={true}
        header={t(`Skip This Process`)}
        onClose={() => {
          onClickSkip(null);
          onRemarkChange("skip", "");
        }}
        open={open?.skipBool}
        component={
          <>
            <Form
              image={<SkipForm />}
              title={t(`Skip This Process`)}
              descrition={t(`Skip_des`)}
              remarks={remark?.skip ?? ""}
              t={t}
              onChange={(e) => onRemarkChange("skip", e)}
              btnText={{
                btn1: t(`No`),
                btn2: t(`Yes_Skip`),
              }}
              no={() => {
                onClickSkip(null);
                onRemarkChange("skip", "");
              }}
              reload={reload}
              data={open?.data}
              type={open?.type}
              isType={open?.isType}
              isUnderOnboard={open?.isUnderOnboard}
              company={details?.company?.id}
              details={details}
            />
          </>
        }
      />

      <AlertDialog
        isNormal={true}
        header={t(`Decline`)}
        onClose={() => {
          onClickDecline(null);
          onRemarkChange("decline", "");
        }}
        open={open?.Decline}
        component={
          <>
            <Form
              image={<DeclineForm />}
              title={t(`Decline`)}
              descrition={t(`Declined_des`)}
              remarks={remark?.decline ?? ""}
              t={t}
              onChange={(e) => onRemarkChange("decline", e)}
              btnText={{
                btn1: t(`No`),
                btn2: t(`Yes`),
              }}
              no={() => {
                onClickDecline(null);
                onRemarkChange("decline", "");
              }}
            />
          </>
        }
      />

      <AlertDialog
        isNormal={true}
        header={t(`Emergency Cancellation`)}
        onClose={cancelDialog}
        open={cancel}
        component={
          <Box p={2}>
            <CancelDialog t={t} cancel={cancelDialog} termination={termintation}/>
          </Box>
        }
      />
    </Box>
  );
};
