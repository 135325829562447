import { Avatar, Box, Stack, Typography, Divider,Grid } from '@mui/material'
import React from 'react'
import { ProfileCardStyle } from './style'
import { withNamespaces } from "react-i18next";

const ProfileCard = ({ t , data}) => {
    const classes = ProfileCardStyle()
    return (
        <Box className={classes.box} p={2}>
            <Stack direction={"row"} spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
                className={classes.stack}>
                <Avatar className={classes.avatar} alt='profileImg' src={data?.image_url} ></Avatar>
                <Stack spacing={1}  sx={{width:"100%"}}>
                    <Typography className={classes.title}>{t("profiledetail")}</Typography>
                    <Grid container>
                        <Grid item md={3}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography className={classes.subtitle}>{t("Name")}</Typography>
                                <Typography className={classes.content}>{data?.name}</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography className={classes.subtitle}>{t("EmailId")}</Typography>
                                <Typography className={classes.content}>{data?.email_id}</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography className={classes.subtitle}>{t("MobileNumber")}</Typography>
                                <Typography className={classes.content}>{data?.mobile_no_country_code} {data?.mobile_no}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </Box >
    )
}

export default withNamespaces("inviteTrigger")(ProfileCard) 