import makeStyles from "@mui/styles/makeStyles";
import { Regular, SemiBold,Bold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    labelRoot: { display: "flex" },
    Label: {
        color: (props) => props?.labelColor ?? theme.typography.color.tertiary,
        fontFamily: (props) => (props?.fontFamily === "bold" ? Bold : SemiBold),
        fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
        marginBottom: (props) => props?.labelMarginBottom ?? "5px",
        textAlign: (props) => props?.textAlign ?? "left",
    },
    text: {},
    datePickerRoot: { position: 'relative' },
    textbox: {
        fontSize: "14px",
        fontFamily: `${Regular}`,
        height: (props) => (props.multiline ? "unset" : 45),
        width: '100%',
        borderRadius: theme.palette.borderRadius,
        border: '1.5px solid #E4E8EE !important',
        cursor: 'pointer',
        [`& fieldset`]: {
            borderRadius: theme.palette.borderRadius,
            height: (props) => (props.multiline ? "unset" : 45),
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #5078E1",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: (props) => (props.multiline ? "0px" : "0px"),
        },
        ":-webkit-autofill": {
            WebkitBoxShadow: "none !important",
            backgroundColor: "red !important",
        },
        '&:focusVisible': {
            border: '1.5px solid #E2E2E2',
        },

        padding: '20px 18px'
    },
    calander: {
        position: 'absolute',
        top: "55%",
        right: '15px',

    }
}));