import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Divider, Stack, Typography, Menu, MenuItem } from "@mui/material";
import React from "react";
import { PriorityStyles } from "./styles";
const PrioritySelect = ({
  value = "",
  onChange = () => false,
  options = [],
  isRequired = false,
}) => {
  // const [initialvalue, setInitialValue] = useState("low")
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (key, value) => {
    if (key === "save") {
      onChange(value);
    }
    setAnchorEl(null);
  };

  const colorObject = {
    "low":"#06D6A0",
    "medium":"#FFD166",
    "high":"#EFB93C",
    "urgent":"#EF476F"
  }

  const classes = PriorityStyles();
  return (
    <Box>
      <Typography className={classes.priorityLabel}>
        Priority
        {isRequired ? (
          <Typography variant="caption" className={classes.required}>
            *
          </Typography>
        ) : (
          <Typography
            variant="caption"
            className={classes.required}
          ></Typography>
        )}
      </Typography>
      <Box
        className={classes.priorityBox}
        onClick={handleClick}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        style={{
          backgroundColor: colorObject[value],
        }}
      >
        <Typography className={classes.initialText}>{value}</Typography>
        <KeyboardArrowDown className={classes.arrowIcon} />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className={classes.menu}
      >
        {options.map((x, index) => {
          return (
            <MenuItem onClick={() => handleClose("save", x?.value)}>
              <Box sx={{ width: 155 }}>
                <Stack direction={"row"} spacing={1} mb={1}>
                  <Box
                    className={classes.dot}
                    style={{
                      backgroundColor: colorObject[x?.value],
                    }}
                  ></Box>
                  <Typography className={classes.priorityName}>
                    {x.label}
                  </Typography>
                </Stack>
                {index !== options.length - 1 && (
                  <Divider className={classes.priorityDivider} />
                )}
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
export default PrioritySelect;
