import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import { useStyles } from "./style"

export const ViewActivity = ({ data = {}, handleClose = () => false }) => {
    const classes = useStyles()
    return (
        <Box p={2}>
            <Box display={"flex"} justifyContent={"space-between"} className={classes.viewbox} alignItems="center">
                <Stack direction="row" spacing={1} alignItems={"center"}>
                    <img src="/images/activitycall.svg" alt="icon" />
                    <Typography className={classes.viewTitle}>{data?.activity_name ?? "-"}</Typography>
                </Stack>
                {/* <Typography className={classes.viewTitle}>20 days left</Typography> */}
            </Box>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                    <Box p={1.5} className={classes.borderBox}>
                        <Typography className={classes.headingact}>Created By</Typography>
                        <Stack direction="row" spacing={1} alignItems={"center"} mt={1}>
                            <Avatar src={data?.image_url ?? ""} />
                            <Stack direction="column">
                                <Typography className={classes.name}>{data?.first_name}</Typography>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography className={classes.mobileNo}>{data?.phone ? data?.phone : data?.created_by_mobile_no ?? "-"}</Typography>
                                    <div className={classes.dot}></div>
                                    <Typography className={classes.mobileNo}>{data?.email ? data?.email : data?.email_id ?? "-"}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box p={1.5} className={classes.borderBox}>
                        <Typography className={classes.headingact}>Lead Details</Typography>
                        <Stack direction="row" spacing={1} alignItems={"center"} mt={1}>
                            <Avatar />
                            <Stack direction="column">
                                <Typography className={classes.name}>{data?.lead_name}</Typography>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography className={classes.mobileNo}>{data?.lead_mobile_no}</Typography>
                                    <div className={classes.dot}></div>
                                    <Typography className={classes.mobileNo}>{data?.lead_email_id}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={3}>
                    <Typography className={classes.headingact}>Category</Typography>
                    <Typography className={classes.mobileNo} mt={1}>{data?.activity_name}</Typography>

                </Grid>
                {/* <Grid item xs={3}>
                <Typography className={classes.headingact}>Purpose</Typography>
                <Typography className={classes.mobileNo} mt={1}> {data?.purpose ?? "-"} </Typography>

            </Grid> */}
                <Grid item xs={3}>
                    <Typography className={classes.headingact}>Start Date</Typography>
                    <Typography className={classes.mobileNo} mt={1}> {moment(data?.date_time).format("DD-MM-YYYY") ?? "-"} </Typography>

                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.headingact}>Phone Number</Typography>
                    <Typography className={classes.mobileNo} mt={1}>{data?.phone ?? "-"}</Typography>

                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.headingact}>Priority</Typography>
                    <Typography className={classes.mobileNo} mt={1}>{data?.priority}</Typography>

                </Grid>
            </Grid>
            <Box mt={2}><Divider /></Box>
            <Box mt={2}>
                <Typography className={classes.headingact}>Note</Typography>
                <Typography className={classes.mobileNo} mt={1}>{data?.notes ?? "-"}</Typography>

            </Box>
            <Box mt={4} display="flex" justifyContent={"end"} alignItems="end">
                <Button variant="outlined" className={classes.closebtn} onClick={handleClose}>Close</Button>
            </Box>
        </Box>
    )
}