import { Status, Wrapper } from "@googlemaps/react-wrapper";
import Skeleton from "@mui/lab/Skeleton";
import styled from "@mui/material/styles/styled";
import React, { useContext, useEffect, useState } from "react";
import { config } from "../../config";
import { PopoverContext } from "../../contexts";
import { Circle } from "./circle";
import { HoverDetails } from "./hoverDetails";
import "./index.css";
// import MenMarker from '../../../../assets/menMarker.svg';
// import Marker2 from '../../../../assets/marker2.svg';
import { InfoDetails } from "./infoDetails";
import { MapComponent } from "./mapComponent";
import mapStyles from "./mapStyles.json";
import { Marker } from "./marker";
const ErrorComponent = styled(Skeleton)({
  minHeight: "100%",
  minWidth: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transform: "unset",
});

let initialZoom = 10;

export const CustomMap = (props) => {
  const [toggleView, setToggleView] = useState(false);
  const [map, setMap] = React.useState();
  const dialog = useContext(PopoverContext);
  const [zoom, setZoom] = useState(props?.zoom ?? 12);
  const [list, setList] = React.useState([]);

  const [center, setCenter] = useState({
    lat: props?.lat,
    lng: props?.lang,
  });
  const [infoWindow, setInfoWindow] = useState();

  useEffect(() => {
    if (center?.lat !== props?.lat && map) {
      map.panTo({
        lat: props?.lat,
        lng: props?.lang,
      });
    }

    console.log(props)
    // eslint-disable-next-line
  }, [props?.lat, props?.lang, map]);

  useEffect(() => {
    setList(props?.list);
  }, [props?.list]);
  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter()?.toJSON());
  };

  const onClickMap = (e) => {
    let findPopup = document?.getElementsByClassName("gm-style-iw-t");
    if (findPopup?.length) {
      //google info window popup
      findPopup[0].style.display = "none";
      if (e.placeId) {
        findPopup[0].style.display = "block";
      }
      // //custom info window popup
      closeInfoWindow();
    }
  };

  const zoom_changed = (e, map) => {
    if (initialZoom < map.getZoom()) {
      !toggleView && setToggleView(true);
    } else {
      toggleView && setToggleView(false);
    }
  };

  const closeInfoWindow = () => {
    if (infoWindow?.getMap()) {
      infoWindow.close();
    }
  };

  const closeDialog = () => {
    dialog.setDialog({
      open: false,
    });
  };

  const renderStatus = (status) => {
    if (status === Status.FAILURE)
      return (
        <ErrorComponent component="div">Something went wrong!</ErrorComponent>
      );
    return <Skeleton variant="rectangular" height={"100%"} />;
  };

  const markerIcon = {
    url: props?.isDashBoard ? props?.mapIcon : "/images/markerMap.svg",
    // anchor: new window.google.maps.Point(0,0),
    // origin: new window.google.maps.Point(0,0),
    // scaledSize: new window.google.maps.Size(80, 80)
  };
  const changeRadius = (r) => {
    let z = r === 15 ? 10 : r === 10 ? 11 : r === 5 ? 14 : 10;
    map.setZoom(z);
    props?.changeKms(r);
  };



  useEffect(()=>{
    if(props?.state?.zoom !== null  && map){
       if(props?.state?.zoom === true){
        map?.setZoom(map.getZoom() + 1);
      }else{
        map?.setZoom(map.getZoom() - 1);
      }
    }
    // eslint-disable-next-line
  },[props?.state])



  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Wrapper apiKey={`${config.googleMapApiKey}`} render={renderStatus}>
        <MapComponent
          center={center}
          zoom={zoom}
          map_id={props?.map_id??"map"}
          setRadius={changeRadius}
          radius={props?.radius}
          onIdle={onIdle}
          onClick={onClickMap}
          zoom_changed={zoom_changed}
          disableDefaultUI={true}
          keyboardShortcuts={false}
          infoWindow={infoWindow}
          styles={[...mapStyles]}
          map={map}
          hideControl={props?.hideControl}
          setMap={setMap}
          is_zoom={props?.is_zoom}
        >
          {list?.length > 0 &&
            list?.map((val) => (
              <Marker
                position={{
                  lat: parseFloat(val?.address?.latitude),
                  lng: parseFloat(val?.address?.longitude),
                }}
                icon={markerIcon}
                label={{
                  text: `${val?.units?.length ?? val?.total_units}`,
                  className: "marker-label",
                }}
                component={
                  <>
                    <InfoDetails
                      isDashBoard={props?.isDashBoard}
                      statusDashBoard={props?.status}
                      data={val}
                      title="Richards Tree"
                      description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                      subTitle="Tree info"
                      info={[1, 2, 3, 4, 5]}
                      width={"304px"}
                      onClose={closeDialog}
                      onClick={props?.handleDetails}
                      status="#New born babe"
                    />
                  </>
                }
                infoWindow={infoWindow}
                setInfoWindow={setInfoWindow}
                hideDialog={props?.hideDialog}
              />
            ))}

          {list?.length > 0 && (
            <Circle
              options={{
                strokeColor: "#78B1FE99",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#78B1FE99",
                fillOpacity: 0.15,

                center: {
                  lat: props?.lat,
                  lng: props?.lang,
                },
                radius: props?.radius * 1000,
              }}
              mouseover={{
                strokeColor: "#78B1FE99",
                strokeOpacity: 1,
                strokeWeight: 4,
              }}
              mouseout={{
                strokeColor: "#78B1FE99",
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
              hoverComponent={<HoverDetails />}
              infoWindow={infoWindow}
              setInfoWindow={setInfoWindow}
            />
          )}
        </MapComponent>        
      </Wrapper>
    </div>
  );
};
