import { useApolloClient } from "@apollo/client";
import React, { useEffect } from "react";
import { Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { GET_PROPERTY_BYCOMPANY } from "../../graphql/scheduleBoardQuery";
import { NetworkCall } from "../../networkcall";
import {
  accessCheckRender,
  AlertProps,
  getCalendarOptions,
  getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods,
  pageName
} from "../../utils";
import DashboardComponent from "./dashboardComponent";

// import { DashBoard } from "./dashboard";

export const InspectionManagerDashboard = (props) => {
  const { loading, handleLoading } = props;
  // states
  const client = useApolloClient();
  // const [selectedCompany, setSelectedCompany] = useState("");
  // const [companyList, setCompanyList] = useState([]);
  const [calendarOptions, setCalendarOptions] = React.useState([])
  const [selectedCalendar, setSelectedCalendar] = React.useState({})
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [permission, setPermission] = React.useState({});
  const [propertyData, setPropertyData] = React.useState([])
  const [propertyValue, setPropertyValue] = React.useState([])
  const [data, setData] = React.useState({
    details:[],
    inspection_job_status:[]
})
  // use effect to get permission
  useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          getPropertyDropdown(company?.list?.map(i=>i?.value))
          handleLoading(false)
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);
  const getPropertyDropdown = (company=[]) => {
    client
        .query({
            query: GET_PROPERTY_BYCOMPANY,
            fetchPolicy: "network-only",
            variables: {
                company_id: company,
            }
        }).then((res) => {
            setPropertyData(res.data.property)
            setPropertyValue(res.data.property[0])
            let tempCalendarOptions = getCalendarOptions()
            setCalendarOptions(tempCalendarOptions)
            setSelectedCalendar(tempCalendarOptions[0])
            getDashboardData(res.data.property[0]?.value,tempCalendarOptions[0].value.from_date, tempCalendarOptions[0].value.to_date)
        })
}
  const getDashboardData = (property_id,from_date,to_date) => {
    const payload = {
        "property_id": property_id,
        "start_date": from_date,
        "end_date": to_date
    };
    NetworkCall(
        `${config.api_url}/inspection_manager`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
    )
        .then((res) => {
            const inspection_job_status={
                "labels": res?.data?.data?.inspection_jobs_status_graph.map((x)=>{return x.type}),
                "datasets": [
                    {
                        "label": "",
                        "data": res?.data?.data?.inspection_jobs_status_graph.map((x)=>{return x.count}),
                        "backgroundColor": res?.data?.data?.inspection_jobs_status_graph.map((x)=>{return x.fill}),
                        "borderWidth": 1,
                        "barThickness": 40
                    },
                ]
            }
            setData({...data,details:res?.data?.data,inspection_job_status:inspection_job_status})
        })
        .catch((error) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Some Thing Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        });
}
    //handlePropertyChange
    const onPropertyChange = (val) => {
      setPropertyValue(val)
      getDashboardData(val?.value,selectedCalendar.value.from_date, selectedCalendar.value.to_date)
  }

    // Function to change the Calendar
    const handleCalendarChange = (date) => {
      if (date?.load) {
          setSelectedCalendar(date)
          getDashboardData(propertyValue?.value,date.value.from_date, date.value.to_date)
      }
  }
  const render = () => {
    return (
      <>
        {/* sub header */}
        <Subheader
          hideBackButton={true}
          title="Inspection Manager Dashboard"
          calanderselect
          calendarOptions={calendarOptions} calanderValue={selectedCalendar}
          onChangeCalendar={(e) => handleCalendarChange(e)}
          onPropertyChange={(e) => {
            onPropertyChange(e)
        }}
        selectProperty
        propertyOptions={propertyData}
        propertyValue={propertyValue}
        />
        <DashboardComponent data={data}/>
      </>
    );
  };

  return (
    <div>
      {accessCheckRender(render, permission, pageName.dashboard, loading)}
    </div>
  );
};
