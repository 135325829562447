import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, Button, Dialog, Divider, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ApprovedWhite, ConvertToBooking, ConvertToResrvation, Download, Send } from '../../assets';
import { AlertDialog, ContactList, LoadingSection, QuotationContactCreate, QuotationSummery, Subheader } from "../../components";
import { DocumentViewer } from '../../components/fileViewer';
import { config } from "../../config";
import { AuthContext, BackdropContext, AlertContext } from '../../contexts';
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, getRoutePermissionNew, NetWorkCallMethods, quotationStatus, timeZoneConverter, AlertProps } from "../../utils";
import { Booking, CancelBooking, QuotationDetail, QuotationVaersionCard, ReservationCard, ReservationHistory, SendPaymentLink, UnitList, UnitSingleDetails } from "./components";
import { quotationsStyles } from "./styles";
import moment from "moment-timezone";



export const QuotationDetails = () => {
    const history = useHistory()
    const classes = quotationsStyles()
    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id");
    const [open, setOpen] = React.useState(false);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [openUnitDetails, setOpenUnitDetails] = React.useState(false);
    const [selectedUnit, setSelectedUnit] = React.useState("")
    const [loading, setLoading] = React.useState(true);
    const [selectedAcc, setSelectedAcc] = React.useState("");
    const [openReserve, setOpenReserve] = React.useState(false);
    const [sendPayment, setSendPayment] = React.useState(false);
    const [openHistory, setHistory] = React.useState(false);
    const [openpdf, setOpenpdf] = React.useState(false);
    const auth = React.useContext(AuthContext)
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext);

    const [cancelBooking, setCancel] = React.useState({
        bool: false,
        data: ""
    })
    const [details, setDetails] = React.useState({
        quott: {},
        units: [],
        reservation: {}
    });
    const [type, setType] = React.useState({
        bool: false,
        type: ""
    });
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getQuotDetail()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    //get quoation details
    const getQuotDetail = () => {
        const payload = {
            tenantId: `${config.tenantId}`,
            quotation_id: id,
        }
        NetworkCall(
            `${config.api_url}/quotation/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setDetails({
                    quott: response.data.quotationDetails[0],
                    units: response.data.units,
                    reservation: response.data.reservation,
                })
                setLoading(false)
            }).catch((err) => {
                console.log(err)
            })
    }

    //on click unit card
    const onClickUnit = (unit_id) => {
        setSelectedUnit(unit_id)
        setOpenUnitDetails(true)
    }
    //reservation json
    const ReservationData = [
        {
            "title": "Convert to Booking",
            img: <ConvertToBooking />,
            types: "Booked",
            btn: "Booking"
        },
        {
            "title": "Convert to Reservation",
            img: <ConvertToResrvation />,
            types: "Reserved",
            btn: "Reserve & Send Payment Link"
        }
    ]
    //download pdf
    const downloadPdf = () => {
        saveAs(details?.quott?.url, `${details?.quott?.quotation_no}.pdf`)
    }
    //send quotation
    const sendQuotation = () => {
        const payload = {
            quotation_id: id,
        }
        NetworkCall(
            `${config.api_url}/quotation/send_invite`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setOpen(true);
                getQuotDetail()
            }).catch((err) => {
                console.log(err)
            })
    }
    //on click types
    const handleClick = (types) => {
        setType({
            bool: !type?.bool,
            type: types
        })
        setOpenReserve(false)
    }
    //onclose
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const isRead = (details?.quott?.status === "Won") ? true : false
    //render type of popup
    const renderType = () => {
        return (
            <Grid container spacing={2} justifyContent="center">
                {
                    ReservationData?.map((val) => {
                        return (
                            <Grid item xs={5} >
                                <Box className={classes.card} onClick={() => handleClick(val)}>
                                    <Box className={classes.reservationType}>
                                        {val?.img}
                                    </Box>
                                    <Typography className={classes.titleReservation}>{val?.title}</Typography>
                                    <center>
                                        <IconButton className={classes.iconBtn} >
                                            <ArrowForwardIosIcon sx={{ fontSize: "14px" }} />
                                        </IconButton>
                                    </center>
                                </Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
        )
    }
    //on send link
    const onSendLink = () => {
        setSendPayment(true)
    }
    //on cancel Booking
    const onCancelBooking = (datas) => {
        setCancel({
            bool: !cancelBooking?.bool,
            data: datas
        })
    }
    //on click btn
    const onReserve = () => {
        if (details?.reservation?.reservation_status === "Booked") {
            handleClick(ReservationData[1])
        } else {
            onSendLink()
        }

    }
    //render btn
    const renderButton = () => {
        return (
            <Button onClick={downloadPdf} className={classes.downBtn} variant="outlined" startIcon={<Download />}>
                Download
            </Button>
        )
    }

    //get list
    const checkDetails = () => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "",
        });
        const payload = {
            vacant: ["Vacant"],
            offset: 0,
            limit: 10,
            revenue_type: details?.quott?.revenue_type,
            lease_start_date:
                details?.quott?.revenue_type !== "Sale"
                    ? `${moment(details?.quott?.lease_start_date).format("YYYY-MM-DD")} 00:00:00` ?? undefined
                    : undefined,
            lease_end_date:
                details?.quott?.revenue_type !== "Sale"
                    ? `${moment(details?.quott?.lease_end_date).format("YYYY-MM-DD")} 23:59:59` ?? undefined
                    : undefined,
            hand_over_date:
                details?.quott?.revenue_type === "Sale"
                    ? `${moment(details?.quott?.lease_start_date).format("YYYY-MM-DD")}` ?? null
                    : undefined,
            units: details?.units?.map((x) => x?.unit_id)
        };
        NetworkCall(
            `${config.api_url}/opportunity/units`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                if (res?.data?.count > 0) {
                    setOpenDrawer(true)
                }
                else{
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "The unit(s) are already occupied",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                }


            })
            .catch((err) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            });
    };


    const render = () => {
        return <>

            {
                //loading section
                loading ? <LoadingSection bottom={"45vh"} message="Loading Quotation Details..." />
                    :
                    <Box>
                        {/*sub header*/}
                        <Subheader title={`${details?.quott?.first_name ?? ""} ${details?.quott?.last_name ?? ""} ${details?.quott?.lead_no ? `(${details?.quott?.lead_no})` : ""}`} goBack={() => { history.goBack() }} />
                        <Box className={classes.container}>
                            <Grid container >
                                <Grid item xs={3.5} p={1}>
                                    <Box p={1} className={classes.detailRoot}>
                                        <Typography className={classes.title}>Quotation Details</Typography>
                                        {/*Quotation version card*/}
                                        <QuotationVaersionCard data={{
                                            quott_no: details?.quott?.quotation_no ?? "",
                                            active: details?.quott?.is_active ?? "",
                                            startDate: details?.quott?.created_at ?? ""
                                        }} />
                                        <Box height="24px" />
                                        {/*Quotation Detail*/}
                                        <QuotationDetail data={{
                                            startDate: details?.quott?.lease_start_date,
                                            endDate: details?.quott?.lease_end_date,
                                            billstartDate: details?.quott?.billing_start_date,
                                            gradeperiod: details?.quott?.grace_period,
                                            graceApplicable: details?.quott?.apply_grace_period,
                                            revenue_type: details?.quott?.revenue_type,
                                            occpation_date: details?.quott?.occpation_date,
                                            contract_start_date: details?.quott?.contract_start_date,
                                        }} />

                                        {
                                            details?.reservation?.reservation_status &&
                                            <>
                                                <Divider />
                                                <Box marginTop="18px">

                                                    {/*reservation details */}
                                                    <ReservationCard
                                                        data={
                                                            {
                                                                reservation_no: details?.reservation?.reservation_no ?? "-",
                                                                is_active: details?.reservation?.is_active,
                                                                date: `${timeZoneConverter(details?.reservation?.start_date)} - ${timeZoneConverter(details?.reservation?.end_date)}`,
                                                                amount: `${details?.reservation?.currency?.symbol} ${details?.reservation?.reserved_amount}`,
                                                                // paid: "20 Feb 22"
                                                            }
                                                        }
                                                        onHistory={() => setHistory(true)}
                                                        onSend={onReserve}
                                                        onCancel={onCancelBooking}
                                                        type={details?.reservation?.reservation_status}

                                                    />
                                                </Box>
                                            </>
                                        }


                                        <Box height="18px" />
                                        <Divider />
                                        <Box height="18px" />
                                        <Typography className={classes.title}>Lead Details</Typography>
                                        {/*lead card*/}
                                        <ContactList data={{
                                            name: `${details?.quott?.first_name ?? ""} ${details?.quott?.last_name ?? ""}`,
                                            img: details?.quott?.image_url?.length > 0 ? details?.quott?.image_url : null,
                                            arg: false,
                                            phone: `${details?.quott?.mobile_no_country_code ?? ""} ${details?.quott?.mobile_no ?? ""}`,
                                            mail: details?.quott?.email_id
                                        }}

                                            leadtype={true} />

                                    </Box>
                                    <Grid container spacing={1}>
                                        {permission?.create &&
                                            <Grid item xs={5.5}>
                                                {/*send quotation btn*/}
                                                <Button
                                                    disabled={isRead}
                                                    startIcon={<Send />}
                                                    onClick={sendQuotation}
                                                    variant="outlined" className={classes.reserve} fullWidth>{details?.quott?.is_resend ? "Resend" : "Send"} Quotation</Button>
                                            </Grid>}

                                        {permission?.read &&
                                            <Grid item xs={6.5}>
                                                {/*pdf download btn*/}
                                                <Button
                                                    startIcon={<FileDownloadOutlinedIcon />}
                                                    onClick={() => setOpenpdf(true)}
                                                    variant="outlined" className={classes.reserve} fullWidth>View Quotation</Button>
                                            </Grid>}

                                    </Grid>

                                </Grid>
                                <Grid item xs={4.5}>
                                    {/*unit list*/}
                                    <div className={classes.unitList}>
                                        <Typography className={classes.title}>Unit Details</Typography>
                                        <UnitList onOpen={onClickUnit} list={details?.units} noBtn />
                                    </div>
                                </Grid>
                                <Grid item xs={4} padding="16px 16px 0px 16px">
                                    {/*Quotation Summary*/}
                                    <Typography className={classes.title}>Quotation Summary</Typography>
                                    <div className={classes.summeryRoot}>
                                        <QuotationSummery
                                            symbol={details?.quott?.symbol}
                                            datas={{
                                                totalAmount: details?.quott?.total_amount,
                                                totalrefundableTax: details?.quott?.total_refundable,
                                                totalTax: details?.quott?.total_tax,
                                                total: details?.quott?.total_amount,
                                                totalDiscount: details?.quott?.total_discount,
                                            }}
                                            total={details?.total_quote_amount ?? (details?.quott?.total_amount + details?.quott?.total_tax) - details?.quott?.total_discount}
                                            height="310px"

                                        />
                                    </div>
                                    {/*button container shown if status equal to draft*/}
                                    {
                                        (details?.quott?.status !== quotationStatus?.won || details?.quott?.status !== quotationStatus?.notIntrseted) &&
                                        <Grid container spacing={1} >
                                            {permission?.create &&
                                                <Grid item xs={6}>
                                                    {/*reservation  btn*/}
                                                    <Button
                                                        disabled={isRead ? true : details?.reservation?.reservation_status?.length > 0}
                                                        variant="outlined" className={classes.reserve} onClick={() => setOpenReserve(true)} fullWidth>Reservation / Booking</Button>

                                                </Grid>}
                                            {permission?.create &&
                                                <Grid item xs={6}>
                                                    {/*Convert to Agreement  btn*/}
                                                    <Button
                                                        disabled={isRead}
                                                        onClick={() => checkDetails(true)}
                                                        variant="contained" className={classes.btn} fullWidth>Convert to Agreement</Button>

                                                </Grid>}
                                        </Grid>
                                    }

                                </Grid>
                            </Grid>
                        </Box>
                        {/*create Aggreement dialog*/}
                        <Dialog className={classes.dialog} maxWidth="lg" fullWidth open={openDrawer} onClose={() => setOpenDrawer(false)}>
                            <QuotationContactCreate
                                onClose={() => setOpenDrawer(false)}
                                quotation_id={id}
                                data={details}
                                email_id={details?.quott?.email_id}
                                contactId={details?.quott?.contact_id}
                                company_id={details?.quott?.company_id}
                                setSelectedAcc={setSelectedAcc}
                                selectedAcc={selectedAcc}
                                reload={getQuotDetail}
                                total={(details?.quott?.total_amount + details?.quott?.total_tax) - details?.quott?.total_discount}
                                is_prospect={true}
                            />
                        </Dialog>
                        {/*unit details dialog*/}
                        <Dialog className={classes.dialog} maxWidth="xs" fullWidth open={openUnitDetails} onClose={() => setOpenUnitDetails(false)}>
                            <UnitSingleDetails shortlist selectedUnits={selectedUnit} onClose={() => setOpenUnitDetails(false)} />
                        </Dialog>
                        {/* snack bar for send quotation */}
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                            <Box display="flex" className={classes.snackBarRoot} p={2}>
                                <Box>
                                    <ApprovedWhite />
                                </Box>
                                <Box marginLeft="10px" flexGrow={1}>
                                    <Typography className={classes.alertTitle}>
                                        Quotation Link Sent Successfully
                                    </Typography>
                                    <Typography className={classes.alertSub}>
                                        {details?.quott?.is_resend ? "Resend" : "Send"} to {details?.quott?.email_id}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon fontSize='small' sx={{ color: "white" }} />
                                    </IconButton>

                                </Box>
                            </Box>
                        </Snackbar>
                        {/*pop up for reservation flow */}
                        <AlertDialog
                            component={renderType()}
                            isnotTitle={true}
                            open={openReserve}
                            onClose={() => setOpenReserve(false)}
                            md={true}
                            noBg={true}
                        />
                        {/*reservation*/}
                        <AlertDialog
                            component={

                                <Booking currency={{
                                    label: details?.quott?.symbol,
                                    value: details?.quott?.currency_id
                                }}
                                    is_reservation={details?.reservation?.reservation_status === "Booked" ? true : false}
                                    reload={getQuotDetail}
                                    company_id={details?.quott?.company_id}
                                    quotation_id={id}
                                    setSendPayment={setSendPayment} type={type?.type} onClose={handleClick} />}
                            header={type?.type?.title}
                            open={type?.bool}
                            onClose={handleClick}
                            isNormal={true}

                        />
                        {/*send payment link*/}
                        <AlertDialog
                            component={<SendPaymentLink
                                mobile={`${details?.quott?.mobile_no_country_code} ${details?.quott?.mobile_no}`}
                                email_id={details?.quott?.email_id} onClose={() => setSendPayment(false)} />}
                            header="Send Payment Link"
                            open={sendPayment}
                            onClose={() => setSendPayment(false)}
                            isNormal={true}
                        />
                        {/*reservation history*/}
                        <AlertDialog
                            component={<ReservationHistory />}
                            header="Booking History"
                            open={openHistory}
                            onClose={() => setHistory(false)}
                            isNormal={true}
                        />
                        {/*cancel booking*/}
                        <AlertDialog
                            component={
                                <CancelBooking
                                    company_id={details?.quott?.company_id}
                                    quotation_id={id}
                                    onClose={onCancelBooking}
                                    reload={getQuotDetail}
                                    type={details?.reservation?.reservation_status === "Booked" ? "Booking" : "Reserved"}
                                    data={cancelBooking?.data} />}
                            header="Cancel Booking"
                            open={cancelBooking?.bool}
                            onClose={onCancelBooking}
                            isNormal={true}
                        />
                        {/* quotation pdf */}
                        <AlertDialog
                            header={details?.quott?.quotation_no}
                            onClose={() => setOpenpdf(false)}
                            open={openpdf}
                            render={renderButton()}
                            component={
                                <Box p={1} bgcolor={"#F5F7FA"}>
                                    <center>
                                        {
                                            details?.quott?.url ?
                                                <DocumentViewer url={details?.quott?.url} />
                                                :
                                                "No Data Found"
                                        }

                                    </center>
                                </Box>
                            }
                        />
                    </Box >


            }
        </>
    }

    return (
        <div>
            {accessCheckRender(render, permission)}
        </div>
    )
}