import React from "react";
import { withNavBars } from "../../HOCs";
import { ClientSettings } from "./clientSettings";

class ClientSettingsParent extends React.Component {
    render() {
        return <ClientSettings />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ClientSettingsParent, props);
