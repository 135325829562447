import { makeStyles } from "@mui/styles";
import { Bold, ExtraBold, Regular, SemiBold } from "../../utils";

export const SingleAccountListStyles = makeStyles((theme) => ({
    AccountListBody: {
        backgroundColor: "white",
        border: "1px solid #E4E8EE",
        boxShadow: "0px 4px 16px #0000001F",
        borderRadius: "4px",
        padding: "15px",
        margin: "20px",
    },
    AccountListContent: {
        display: "flex",
        marginBottom: "18px"
    },
    accountIcon: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F2F4F7",
        borderRadius: "8px",
        padding: "12px"
    },
    accountDetails: {
        marginLeft: "15px"
    },
    AccountTitle: {
        letterSpacing: "-0.11px",
        color: "#4E5A6B",
        textTransform: "capitalize",
        fontSize: "14px",
        fontFamily: SemiBold
    },
    accountType: {
        display: "flex",
        marginTop: "3px"
    },
    dot: {
        width: "6px",
        height: "6px",
        backgroundColor: "#E4E8EE",
        borderRadius: "50%",
        marginLeft: "6px",
        marginRight: "6px",
        marginTop: "5px"
    },
    accountTypeDetails: {
        color: "#4E5A6B",
        fontFamily: Regular,
        fontSize: "12px"
    },
    titleRoot: {
        borderBottom: "1px solid #c1c1c1",

    },
    title: {
        fontSize: "18px",
        fontFamily: ExtraBold,
    },
    //singleaccountcard Styles
    SingleAccountCardBody: {
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "4px",
        marginTop: "10px"
    },
    attachment: {
        backgroundColor: "#F5F7FA",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "space-between",
        padding: "7px"
    },
    attachmentContent1: {
        display: "flex"
    },
    attachfileicon: {
        padding: "6px",
        backgroundColor: "#E4E8EE",
        borderRadius: "4px",
        fontSize: "25px"
    },
    attachmentName: {
        fontSize: "12px",
        color: "#4E5A6B",
        fontFamily: Bold,
        marginLeft: "10px",
        marginTop: "4px"
    },
    attachmentstorage: {
        fontSize: "12px",
        color: "#98A0AC",
        fontFamily: Regular,

    }

}))