import React from "react";
import { withNavBars } from "../../HOCs";
import { ClientManagerDashboard } from "./clientManagerDashboard";

class ClientManagerDashboardParent extends React.Component {
    render() {
        return <ClientManagerDashboard {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ClientManagerDashboardParent, props);