import { useApolloClient } from "@apollo/client";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { Budget } from '../../../assets';
import { TextBox } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { UPDATE_CHEQUE } from "../../../graphql/agreementQurery";
import { NetworkCall } from "../../../networkcall/index";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { PostCard } from "./index";
import { AggrementComponentStyles } from "./styles";

const initialState = {
    remarks: "",
    amount: "",
    error: {
        remarks: "",
        amount: "",
    }
}

export const InvoiceForm = ({ invoice = {}, type = null, data = null, onClose = () => false, reload = () => false, details = {} }) => {

    const classes = AggrementComponentStyles()
    const alert = React.useContext(AlertContext);
    const client = useApolloClient();
    const [datas, setData] = React.useState({ ...initialState })

    //update state of Account
    const updateState = (key, value) => {
        let error = datas?.error;
        error[key] = "";
        setData({ ...datas, [key]: value, error });
    };
    //validate invoive form
    const validateForm = () => {
        let isValid = true;
        let error = datas.error;
        if (datas?.remarks?.length === 0) {
            isValid = false;
            error.remarks = "Remarks is Required";
        }

        if (datas?.amount?.length === 0) {
            isValid = false;
            error.amount = "Amount is Required";
        }

        setData({ ...datas, error });
        return isValid;
    }
    //settle cheuque
    const settleCheque = () => {
        client.mutate({
            mutation: UPDATE_CHEQUE,
            variables: {
                id: data?.id,
                "payload": {
                    remarks: datas?.remarks
                }
            }
        }).then((response) => {
            reload()
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Updated",
            });
            onClose()

        }).catch((err) => {
            console.log(err)
        })
    }
    //settle offline
    const settleOffline = () => {
        if (validateForm()) {
            const variables = {
                "invoice_id": invoice?.id,
                "payment_date": new Date(),
                "payment_amount": parseInt(datas?.amount),
                "payment_mode": details?.payment_mode,
                "remarks": datas?.remarks
            }
            NetworkCall(
                `${config.api_url}/invoice/settel_offline`,
                NetWorkCallMethods.post,
                variables,
                null,
                true,
                false
            ).then((res) => {
                reload()
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Settle Payment Successfull",
                });
                onClose()
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Unable to Settle Payment",
                });
            })
        }
    }
    //on submit
    const submit = () => {
        if (type === "Settle Cheque") {
            settleCheque()
        } else {
            settleOffline()
        }
    }
    return (
        <Box p={1}>
            {
                type === "Settle Cheque" ?
                    <PostCard
                        icon={<ContactMailIcon sx={{ color: "#7D534C" }} />}
                        title={data?.cheque_name}
                        description={data?.bank_name}
                        bgColor="#FEEAEA80"
                    />
                    :
                    <PostCard
                        icon={<Budget color="#54A9E0" />}
                        title="Settling Payment Offline"
                        description="Payment made through offline channel"
                        bgColor="#E0F3FF"
                    />
            }

            <Box padding="0px 12px 12px 12px" marginTop="-10px">
                {
                    type === "Settle Cheque" ? "" :
                        <TextBox
                            label="Amount"
                            placeholder="Enter Amount"
                            value={datas?.amount}
                            isError={datas?.error?.amount?.length > 0}
                            errorMessage={datas?.error?.amount}
                            onChange={(e) => updateState("amount", e.target.value)}
                        />
                }
                <Box height="8px" />
                <TextBox
                    label="Remarks"
                    multiline
                    placeholder="Enter Remarks"
                    value={datas?.remarks}
                    isError={datas?.error?.remarks?.length > 0}
                    errorMessage={datas?.error?.remarks}
                    onChange={(e) => updateState("remarks", e.target.value)}
                />
            </Box>
            <Grid container spacing={2} p={2}>
                <Grid item xs={6}>
                    <Button onClick={onClose} color={type === "Settle Cheque" ? "error" : "primary"} className={classes.decline} fullWidth variant={type === "Settle Cheque" ? "contained" : "outlined"}>
                        {type === "Settle Cheque" ? "Decline Payment" : "Close"}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={submit} color={type === "Settle Cheque" ? "success" : "primary"} className={classes.approved} fullWidth variant="contained">
                        {type === "Settle Cheque" ? "Approve Payment" : "Settle Payment"}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}