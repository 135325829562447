import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../../utils";
export const componantsStyles = makeStyles((theme) => ({
    img: {
        objectFit: "cover",
        height: "54px",
        width: "54px",
        borderRadius: "10px"
    },
    profileimg: {
        backgroundColor: theme.palette.background.tertiary,
        color: "#E4E8EE",
        position: "relative",
    },
    profileicon: {
        fontSize: "80px",
        padding: "14px",
    },
    profileimgs: {
        backgroundColor: theme.palette.background.tertiary,
        color: "#E4E8EE",
        position: "absolute",
        bottom: "0px",
        right: "0px",
        border: "2px solid white",
        borderRadius: "50%",
    },

    profilesection: {
        marginTop: "14px",

    },
    imgDiv: {
        position: "relative",
        width: "90px"
    },
    close: {
        position: "absolute",
        top: "0px",
        right: "0px",
        backgroundColor: "red",
        "&:hover": {
            backgroundColor: "red",
        },
    },
    title: {
        fontSize: "12px",
        fontFamily: Bold,
        textTransform: "uppercase",
        marginBottom: "12px"
    },
}));