import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { AvailableSlots, Calendar, VenueCard } from "./components";
import { CheckAvailabilityStyles } from './style'
import { NetWorkCallMethods, AlertProps } from "../../utils";
import { AlertDialog } from "../../components";
import BookingRecidentPopup from "./bookingRecidentPopup";
import { NetworkCall } from "../../networkcall";
import { AlertContext } from "../../contexts";
import moment from "moment";
import { config } from "../../config";
import { addSeconds } from "date-fns";
import { withNamespaces } from "react-i18next";
import { addDays,subDays } from "date-fns";
const CheckAvailability = (
    {
        getAmenityBookingList = () => false,
        closeCheckAvailability = () => false,
        selectedCompany = {},
        selectedProperty = {},
        selectedAmenityCategory = {},
        t
    }
) => {
    const classes = CheckAvailabilityStyles()
    const alert = React.useContext(AlertContext)
    const [data, setData] = React.useState({
        venue: [],
        selectedVenue: {},
        selectedDate: new Date(),
        availableSlot: [],
        selected_slot: [],
        slotIndex: null
    })

    const showAvailableSlot = (e) => {
        getAmenitiesBooking(e)
    }
    const [popup, setPopup] = React.useState(false)
    const openPopup = () => {
        setPopup(true)
    }

    React.useEffect(() => {
        getViewAmenities(new Date())
        // eslint-disable-next-line
    }, [])

    const getViewAmenities = (date) => {
        const data = {
            property_id: selectedProperty?.value,
            amenity_category_id: selectedAmenityCategory?.value,
            selected_date: `${moment(date).format("YYYY-MM-DD")} 00:00:00`
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_amenities_for_category`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                setData({
                    ...data,
                    venue: response?.data?.data?.amenityByDate,
                    selectedVenue: {},
                    selectedDate: date
                })
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                });
            });
    };

    const getAmenitiesSelectedDate = (date) => {
        getViewAmenities(date)
    }

    // Get amenities Booking 
    const getAmenitiesBooking = (value) => {
        const payload = {
            property_id: selectedProperty?.value,
            facility_id: value?.facility_id,
            amenities_type: value?.amenities_type,
            selected_date: moment(data?.selectedDate).format("YYYY-MM-DD"),
            period: value?.period
        };
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_amenities_for_booking`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let constAvailableDates = response?.data?.data?.available_slots.map((e) => {
                    const add = new Date(moment(data?.selected_Date).format("YYYY-MM-DD") + " " + e[1])
                    return response?.data?.data?.amenity[0].period === "Hourly" ?
                        {
                            "check_in_time": e[0],
                            "check_out_time": moment(addSeconds(add, 1)).format("HH:mm:ss"),
                            "check_in_value": e[0],
                            "check_out_value": e[1],
                        } :
                        {
                            "check_in_date": e,
                            "check_out_date": e
                        }
                })
                setData({
                    ...data,
                    availableSlot: constAvailableDates ?? [],
                    selectedVenue: value,
                    slotIndex: null,
                    selected_slot: []
                })
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("NoslotsavailableforBooking"),
                });
            });
    };

    //  select slots
    const selectSlot = (value, index) => {
        let selectedIndex = data?.selected_slot?.map(e => {
            return e.index
        })

        if (selectedIndex?.includes(index)) {
            setData({
                ...data,
                selected_slot: [],
                slotIndex: null
            })
        }
        else {
            if (data?.selected_slot?.length === 0) {
                setData({
                    ...data,
                    selected_slot: [{ value, index }],
                    slotIndex: index
                })
            } else {
                if (value?.check_in_date) {
                    if (moment(addDays(new Date(data?.selected_slot[0]?.value?.check_out_date), 1)).format("YYYY-MM-DD") === moment(value?.check_in_date).format("YYYY-MM-DD")) {
                        setData({
                            ...data,
                            selected_slot: [{ value, index }, ...data?.selected_slot],
                            slotIndex: index
                        })
                    }
                    else if (moment(subDays(new Date(data?.selected_slot[data?.selected_slot?.length - 1]?.value?.check_out_date), 1)).format("YYYY-MM-DD") === moment(value?.check_in_date).format("YYYY-MM-DD")) {
                        setData({
                            ...data,
                            selected_slot: [...data?.selected_slot, { value, index }],
                            slotIndex: index
                        })
                    }
                    else {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("Onlyconsecutiveslotscanbebooked"),
                        });
                    }
                }
                else {
                    if ((Number((data?.selected_slot[0]?.value?.check_in_time).slice(0, 2))) === (Number((value?.check_out_time).slice(0, 2)))) {
                        setData({
                            ...data,
                            selected_slot: [{ value, index }, ...data?.selected_slot],
                            slotIndex: index
                        })
                    } else if ((Number((data?.selected_slot[data?.selected_slot?.length - 1]?.value?.check_out_time).slice(0, 2))) === Number((value?.check_in_time).slice(0, 2))) {
                        setData({
                            ...data,
                            selected_slot: [...data?.selected_slot, { value, index }],
                            slotIndex: index
                        })
                    }
                    else {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("Onlyconsecutiveslotscanbebooked"),
                        });
                    }
                }
            }
        }
    }

    const closeBookingResidentPopup = () => {
        setPopup(!popup)
    }

    return (
        <div>
            <Grid container>
                <Grid item md={4} sm={12} className={classes.grid_border}>
                    <Calendar getAmenities={getAmenitiesSelectedDate} selected_date={data?.selectedDate} />
                </Grid>
                <Grid item md={4} sm={12} className={classes.grid_border} p={2}>
                    <Stack spacing={1} className={classes.venueSection}>
                        <Typography className={classes.title}>{t("Venue")}</Typography>
                        <Box>
                            <VenueCard list={data?.venue} showAvailableSlot={showAvailableSlot} selectedVenue={data?.selectedVenue} />
                        </Box>
                    </Stack>
                </Grid>
                <Grid item md={4} sm={12} className={classes.grid_border} p={2}>
                    <Stack spacing={1} className={classes.available_slot}>
                        <Typography className={classes.title}>{t("AvailableSlot")}</Typography>
                        <AvailableSlots
                            selectedVenue={data?.selectedVenue}
                            availableSlot={data?.availableSlot}
                            openPopup={openPopup}
                            selectSlot={selectSlot}
                            selectedSlot={data?.selected_slot} />
                    </Stack>
                </Grid>
            </Grid>
            <AlertDialog open={popup}
                onClose={closeBookingResidentPopup}
                header={t("BookingAmenityForResident")}
                component={<BookingRecidentPopup
                    getAmenityBookingList={getAmenityBookingList}
                    closeCheckAvailability={closeCheckAvailability}
                    closeBookingResidentPopup={closeBookingResidentPopup}
                    amenityData={data?.selectedVenue}
                    bookingDate={data?.selectedDate}
                    slots={data?.selected_slot}
                    selectedCompany={selectedCompany} selectedProperty={selectedProperty} />}
                md
            />
        </div>
    )
}

export default withNamespaces("amenityBooking")(CheckAvailability)
