import makeStyles from "@mui/styles/makeStyles";
import { ExtraBold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    signupform: {
        overflow: "overlay",
        padding: "0px 70px",
        alignContent: "start",
        height: (size) => size?.height
    },
    image: {
        marginTop: "70px",
        marginBottom: (size) => size?.height > 573.89 ? ((size?.height - 533.89) / 2) : "20px"
    },
    error: {
        color: "red",
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 6px 10px #00000014",
        opacity: 1,
        padding: "8px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    title: {
        fontFamily: ExtraBold,
        fontSize: "24px",
        color: "#091B29",
    },
    subContent: {
        fontFamily: ExtraBold,
        fontSize: "16px",
        color: "#4E5A6B"
    },
    subtitle: {
        fontFamily: SemiBold,
        fontSize: "12px",
        color: "#848484",
    },
    dont: {
        fontFamily: SemiBold,
        fontSize: "12px",
    },
    signin: {
        color: theme.palette.primary.main,
        fontFamily: SemiBold,
        fontSize: "12px",
        textDecoration: "none",
    },

    forgetPassword: {
        float: "right",
        fontFamily: SemiBold,
        fontSize: "12px",
        // color: theme.palette.primary.main,
        marginTop: "10px",
    },
    poweredby: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "12px",
        color: theme.typography.color.tertiary,
        marginTop: "10px",
    },
    pa: {
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
    },
    logoIcon: {
        position: 'absolute',
        top: '60px',
        left: '70px'
    },
    content: {
        width: "inherit",
    },
    backgroundImage: {
        backgroundColor: "#7EA1FF",
        height: "213px",
        backgroundImage: `url("/images/login_background_1-min.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100%"
    },
    img: {
        height: "8%",
        width: "28%",
    },
}));