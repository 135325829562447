import gql from "graphql-tag";
import { config } from "../../config";
import { LocalStorageKeys } from "../../utils";
import { returnValue } from "../companyCreation/utils";

export const listErrorState = [
    {
        profileDetails: [
            { key: "vendorCode" },
            { key: "vendorName" },
            // { key: "vendorCountry" },
        ],
        // address: [
        //     { key: 'latitude' },
        //     { key: 'longitude' },
        // ],
    },
    {
        tax: [
            { key: 'taxGroup' },
            { key: 'taxCode' },
            { key: 'taxRegistrationNumber' },
        ],
        bankingBasicDetails: [
            { key: "bankName" },
            { key: "branchName" },
            { key: "currency" },
            { key: "bankAccountType" },
            { key: "accountNumber" },
            { key: "bankRoutingType" },
            { key: "bankRoutingCode" },
            { key: "chequeName" },
            { key: "addressLineOne" },
            { key: "city" },
            { key: "country" },
        ]
    },
    {
        companyEstateRegistration: [
            { key: "companyRegistrationType" },
            { key: "companyRegistrationNumber" },
        ],

    },
]

export const vendorTableDetails = {
    header: [
        { title: "Image" },
        { title: "Vendor Name" },
        { title: "Vendor Code" },
        { title: "Primary Mobile Number" },
        { title: "Primary Email Id" },
        { title: "Status" },
        { title: "", field: 'icon' },
    ],
    dataType: [
        { type: ["logo"], name: "image" },
        { type: ["text"], name: "venderName" },
        { type: ["text"], name: "venderCode" },
        { type: ["text"], name: "primaryMobileNumber" },
        { type: ["text"], name: "primaryEmailID" },
        { type: ["status"], name: "status" },
        { type: ["more"], icon: "icon" },
    ]
}

export const constructVendorListing = (data, offset) => {

    let constructData = data?.length ? data?.map((val, index) => ({
        id: val?.id,
        index: offset + (index + 1),
        image: {
            logo: returnValue(val?.image_url, '-'),
            name: val?.name ? returnValue(val?.name?.charAt(0)) : ''
        },
        venderCode: returnValue(val?.vendor_code, '-'),
        venderName: returnValue(val?.name, '-'),
        primaryMobileNumber: returnValue((`${returnValue(val?.vendor_address?.[0]?.mobile_code)} ${returnValue(val?.vendor_address?.[0]?.mobile_no)}`)?.trim(), "-"),
        primaryEmailID: returnValue(val?.vendor_address?.[0]?.email_id, '-'),
        status: val?.is_active ? "Active" : "Inactive",
    })) : []

    return constructData
}

export const constructPayload = (vendorProfile, paymentTaxDetails, registrationDetails, assets, companyID) => {

    const {
        profileDetails = null,
        logo = null,
        address = null,
        contactInformation = null
    } = vendorProfile;

    const {
        tax = null,
        bankingBasicDetails = null,
    } = paymentTaxDetails;

    const {
        companyEstateRegistration = null,
    } = registrationDetails;

    let payload = {
        "tenantId": config.tenantId,
        "user_profile_id": localStorage.getItem(LocalStorageKeys?.profileID),
        "image_url": logo?.src,
        "vendor_code": profileDetails?.vendorCode,
        "name": profileDetails?.vendorName,
        "country_id": profileDetails?.vendorCountry?.value,
        "company_id": companyID,
        "description": profileDetails?.vendorDescription,
        "is_active": true,

        "latitude": address?.latitude,
        "longitude": address?.longitude,
        "door_no": address?.doorNumber,
        "street_1": address?.addressLineOne,
        "street_2": address?.addressLineTwo,
        "landmark": address?.landmark,
        "city": address?.city,
        "state": address?.state,
        "country": address?.country,
        "pincode": address?.zipCode,


        "telephone_code": contactInformation?.primaryTelephone?.mobile_code,
        "telephone_no": contactInformation?.primaryTelephone?.mobile,
        "mobile_code": contactInformation?.primaryMobile?.mobile_code,
        "mobile_no": contactInformation?.primaryMobile?.mobile,
        "email_id": contactInformation?.primaryEmailAddress,
        "website": contactInformation?.website,

        "tax_code": tax?.taxCode?.value,
        "vat_group_id": tax?.taxGroup?.value,
        "tax_no": tax?.taxRegistrationNumber,

        "bank_name": bankingBasicDetails?.bankName,
        "branch_name": bankingBasicDetails?.branchName,
        "currency_id": bankingBasicDetails?.currency?.value,
        "account_type": bankingBasicDetails?.bankAccountType,
        "account_no": bankingBasicDetails?.accountNumber,
        "routing_type": bankingBasicDetails?.bankRoutingType?.value,
        "routing_code": bankingBasicDetails?.bankRoutingCode,
        "cheque_name": bankingBasicDetails?.chequeName,
        "address_1": bankingBasicDetails?.addressLineOne,
        "address_2": bankingBasicDetails?.addressLineTwo,
        "bank_city": bankingBasicDetails?.city,
        "bank_country": bankingBasicDetails?.country,

        "company_registration": companyEstateRegistration?.companyRegistrationType?.value,
        "company_registration_no": companyEstateRegistration?.companyRegistrationNumber,

        "assetsData": assets?.uploadedImages?.filter(({ id }) => !id)?.map(({ priority, url }) => ({ priority, url })),
    }

    return payload
}

export const constructVendorDetails = (val) => {

    const returnAssests = (array) => array?.map(({ id = "", url = "", priority = null }) => ({ id, url, priority, asset_type: 2 }));

    let constructState = {};
    let information = val?.vendor_address?.[0] ?? null;

    constructState['vendorProfile'] = {
        logo: val?.image_url ? {
            src: returnValue(val?.image_url, null)
        } : null,
        profileDetails: {
            vendorCode: returnValue(val?.vendor_code),
            vendorName: returnValue(val?.name),
            vendorCountry: val?.country_master ? {
                label: returnValue(val?.country_master?.country_name),
                value: returnValue(val?.country_master?.id),
            } : null,
            vendorCompany: val?.company_master ? {
                label: returnValue(val?.company_master?.name),
                value: returnValue(val?.company_master?.id),
            } : null,
            vendorDescription: returnValue(val?.description),
        },
        address: {
            latitude: returnValue(information?.latitude, null),
            longitude: returnValue(information?.longitude, null),
            doorNumber: returnValue(information?.door_no),
            addressLineOne: returnValue(information?.street_1),
            addressLineTwo: returnValue(information?.street_2),
            landmark: returnValue(information?.landmark),
            city: returnValue(information?.city),
            state: returnValue(information?.state),
            country: returnValue(information?.country),
            pincode: returnValue(information?.pincode),
        },
        contactInformation: {
            primaryTelephone: {
                mobile: returnValue(information?.telephone_no, null),
                mobile_code: returnValue(information?.telephone_code, null)
            },
            primaryMobile: {
                mobile: returnValue(information?.mobile_no, null),
                mobile_code: returnValue(information?.mobile_code, null)
            },
            primaryEmailAddress: returnValue(information?.email_id),
            website: returnValue(information?.website)
        }
    }
    constructState['paymentTaxDetails'] = {
        tax: {
            taxGroup: val?.vat_group_master?.id ? {
                label: returnValue(val?.vat_group_master?.group_name),
                value: returnValue(val?.vat_group_master?.id),
            } : null,
            taxCode: val?.tax_code ? {
                label: returnValue(val?.tax_code),
                value: returnValue(val?.tax_code),
            } : null,
            taxRegistrationNumber: returnValue(val?.tax_no)
        },
        bankingBasicDetails: {
            bankName: returnValue(val?.bank_name),
            branchName: returnValue(val?.branch_name),
            currency: {
                label: returnValue(val?.currency?.name),
                value: returnValue(val?.currency?.id),
            },
            bankAccountType: returnValue(val?.account_type),
            accountNumber: returnValue(val?.account_no),
            bankRoutingType: {
                label: returnValue(val?.routing_type),
                value: returnValue(val?.routing_type)
            },
            bankRoutingCode: returnValue(val?.routing_code),
            chequeName: returnValue(val?.cheque_name),
            addressLineOne: returnValue(val?.address_1),
            addressLineTwo: returnValue(val?.address_2),
            city: returnValue(val?.bank_city),
            country: returnValue(val?.bank_country),
        }
    }
    constructState['registrationDetails'] = {
        companyEstateRegistration: {
            companyRegistrationType: val?.company_registration ? {
                label: returnValue(val?.company_registration),
                value: returnValue(val?.company_registration),
            } : null,
            companyRegistrationNumber: returnValue(val?.company_registration_no),

        }
    }
    constructState['assets'] = {
        uploadedImages: val?.vendor_assets?.length ? returnAssests(val?.vendor_assets) : [],
        deletedImages: [],
    }

    return { ...constructState }

}

export const GET_VENDOR_DETAILS = gql`
query GET_VENDOR_DETAILS{
    vendor_master(
        where:{id:{eq:$id}}
    ){
        id
        image_url
        vendor_code
        name
        description
        country_master {
            id
            country_name
          }
          company_master{
            id
            name
        }
        vendor_address{
            latitude
            longitude
            door_no
            street_1
            street_2
            landmark
            city
            state
            country
            pincode
            telephone_code
            telephone_no
            mobile_code
            mobile_no
            email_id
            website
        }
        tax_code
        vat_group_master{
            id
            group_name
        }
        tax_no
        bank_name
        branch_name
        currency{
            name
            id
        }
        account_type
        account_no
        routing_type
        routing_code
        cheque_name
        address_1
        address_2
        bank_city
        bank_country
        company_registration
        company_registration_no
        vendor_assets(
            where:{
                is_active:{eq:true}
            }
        ){
            id
            url
            priority
        }

    }
}
`;

export const GET_ALL_VENDORS = gql`
query GET_ALL_VENDORS($offset:Int,$limit:Int,$searchText:String,$companyId:ID,$client:String){
    count:vendor_master(
        where:{
            or:{
                name:{iregex:$searchText}
                vendor_code:{iregex:$searchText}
            }
            company_id:{eq:$companyId}
            is_delete:{eq:false}
            client:{eq:$client}
        }
    ){
        count:count_id
    }
    vendor_master(
        where:{
            or:{
                name:{iregex:$searchText}
                vendor_code:{iregex:$searchText}
            }
            company_id:{eq:$companyId}
            is_delete:{eq:false}
            client:{eq:$client}
        }
        offset:$offset
        limit:$limit
        order_by: { created_at: desc , name:asc }
    ){
        id
        image_url
        vendor_code
        name
        vendor_address{
            mobile_code
            mobile_no
            email_id
        }
        is_active
    }
}
`;

export const UPDATE_VENDOR_MASTER = gql`
mutation UPDATE_VENDOR_MASTER($ID: ID,$updatePayload:Input){
    vendor_master(
      where: {
          id: { eq: $ID }
        }
        update:$updatePayload
    ) {
      id
      is_active
    }
  }`