import { Box, Typography } from "@mui/material"
import React from "react"
import { Cards } from "./card"
import { useStyles } from "./style"
export const Preview = ({ selectedUnit = {}, selectedInspectionItem = {}, setStep = () => false, maintenanceRequest = [], updateState = () => false, setData = () => false, data = {} }) => {
    const classes = useStyles()
    React.useEffect(() => {
        setData({ ...data, disable: false })
        //eslint-disable-next-line
    }, [])
    return (
        <Box p={2} height={350}>
            <Typography className={classes.previewHdr}>UNIT DETAILS</Typography>
            <Box mt={1}><Cards details={selectedUnit} edit={true} setStep={setStep} unit={true} updateState={updateState} /></Box>
            <Box height="30px" />
        </Box>
    )
}