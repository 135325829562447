import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../../utils";
export const AggrementTabStyles = makeStyles((theme) => ({

    pdfSection: {
        padding: "12px",
        height: "calc(100vh - 250px)",
        overflow: "auto",
        backgroundColor: "#F5F7FA",
    },
    title: {
        color: "#4E5A6B",
        fontSize: "16px",
        fontFamily: Bold,
        textTransform: "capitalize"
    },
}));