import { Typography } from "@mui/material";
import { useStyles } from "./style";

export const DiscussonDetail = (props) => {

    const classes = useStyles(props);

    return <div className={classes.root}>
        <Typography className={classes.title}>
            {props?.title}
        </Typography>
        <div className={classes.body}>
            {props?.data?.length > 0 ? props?.data?.map?.((_) => {
                return <div className={classes.card}>
                    <div className={classes.details}>
                        <Typography className={classes.cardTitle}>{_?.title}</Typography>
                        <Typography className={classes.cardSubTitle}>{_?.description}</Typography>
                    </div>
                    <div className={classes.comment}>
                        <Typography className={classes.commentText}>{_?.comments}</Typography>
                    </div>
                </div>
            }) :
                <Typography textAlign={"center"}>No data found</Typography>}
        </div>
    </div>
}