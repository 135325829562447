import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomPaper, CustomTypography, TitleDesc } from '../../companyCreation/components';
import useTheme from '@mui/material/styles/useTheme';
import { returnValue } from '../../companyCreation/utils';
import { Bold } from '../../../utils';
export const RegistrationDetails = (props) => {

    const theme = useTheme();

    const {
        companyEstateRegistration = null,
    } = props?.data ?? {};

    return (
        <div>
            <CustomPaper marginBottom={theme.spacing(3)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTypography
                            fontSize={12}
                            fontFamily={Bold}
                            // marginBottom={theme?.spacing(2)}
                            color={theme?.typography?.color?.secondary}
                        >
                            Company & Real Estate Registration
                        </CustomTypography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Company Registration Type"}
                            desc={returnValue(companyEstateRegistration?.companyRegistrationType?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Company Registration Number"}
                            desc={returnValue(companyEstateRegistration?.companyRegistrationNumber, '-')}
                        />
                    </Grid>
                </Grid>
            </CustomPaper>
        </div >
    )
}