export const AccessGatesPropertyPath = ["name", "location", "gates", "propertyType", "status"]

export const AccessGatesPropertyHeading = [
    { title: "Name", field: "name" },
    { title: "Location", field: "location" },
    { title: "Gates", field: "gates" },
    { title: "Property Type", field: "propertyType" },
    { title: "Status", field: "status" },
]

export const AccessGatesPropertyType = [
    { type: ["text"], name: "name" },
    { type: ["text"], name: "location" },
    { type: ["text"], name: "gates" },
    { type: ["propertyType"], name: "propertyType" },
    { type: ["status"], name: "status" },
]

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]

export const AccessGatesPath = ["name", "gateNo", "description", "status"]

export const AccessGatesHeading = [
    { title: "Gate Name", field: "name" },
    { title: "Gate Number", field: "gateNo" },
    { title: "Notes", field: "description" },
    { title: "Status", field: "status" },
    { title: "", field: "icon" },
]

export const AccessGatesType = [
    { type: ["text"], name: "name" },
    { type: ["text"], name: "gateNo" },
    { type: ["text"], name: "description" },
    { type: ["status"], name: "status" },
    { type: ["more"], icon: "icon" },
]