import React from 'react';
import "../../../../App.css";
import { MiniPropertyDetail } from "../../../../components";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import { UnitList } from "./unitList/unitList";

export const PropertyDetails = (props) => {

      const size = useWindowDimensions()

      return (

            <div style={{ height: size?.height - (64 + 49), overflow: "auto", padding: "24px" }}>
                  <MiniPropertyDetail
                        logo={props?.data?.logo}
                        property_no={props?.data?.property_no}
                        company_name={props?.data?.company?.name}
                        property_name={props?.data?.name}
                        address={props?.data?.address} />
                  <UnitList />
            </div>
      )
}