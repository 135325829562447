import { makeStyles } from "@mui/styles"

export const MonthlyCalendarStyles = makeStyles((theme) => ({
    // calendar dates styles
    calDates: {
        textAlign: "center",
        overflow: "auto",
        height: `calc(100vh - 280px)`,

    },
    date: {
        color: theme.typography.color.secondary,
        fontSize: 18,
        fontWeight: "bold"
    },
    date_outline: {
        color: "black",
        border: `1px solid ${theme.palette.border.secondary}`,
        height: 120,
    },
    hidden_other_month_dates: {
        visibility: "hidden"
    },
    eventlabel: {
        overflow: "hidden",
        width: "98%",
        cursor:"pointer",
        background:"#8FAEFF"
    },
    eventtxt: {
        whiteSpace: "nowrap",
        width: "99%",
        fontSize: 12,
        cursor:"pointer"
    },
    moreLabel: {
        float: "left",
        fontSize: 12,
        fontWeight: "bold",
        color: theme.palette.info.main,
    },
    days: {
        fontSize: 12,
        textTransform: "uppercase",
        color: theme.typography.color.tertiary,
        textAlign: "center"
    }
}))