import makeStyles from "@mui/styles/makeStyles";
export const dashboardStyles = makeStyles((theme) => ({
  caroselRoot: {
    borderRadius: "4px",
    backgroundColor: (props) => props?.backgroundColor ?? "",
    padding: (props) => props?.padding ?? "",
    cursor: "pointer",
  },
  arrowBtn: {
    backgroundColor: "#fff !important",
    border: "1px solid #CED3DD",
  },
  arrow: {
    backgroundColor: "#fff",
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    borderRadius: "50%",
    border: "1px solid #CED3DD",
  },
  prev: {
    position: "absolute",
    zIndex: 1,
    top: "41%",
    left: "12px",
    cursor: "pointer",
  },
  next: {
    position: "absolute",
    zIndex: 1,
    top: "41%",
    right: "12px !important",
    cursor: "pointer",
  },
}));
