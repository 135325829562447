import * as React from "react"

const ReconcileSvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32.093} height={32} {...props}>
    <g data-name="icons8-approval (1)">
      <path
        data-name="Path 97461"
        d="m17.9.848.577.666.761-.443a2.456 2.456 0 0 1 3.512 1.205l.329.817.863-.171a2.456 2.456 0 0 1 2.93 2.28l.046.879.873.119a2.456 2.456 0 0 1 2.031 3.108l-.242.846.787.4a2.456 2.456 0 0 1 .911 3.6l-.5.722.616.63a2.455 2.455 0 0 1-.306 3.7l-.711.52.378.8a2.456 2.456 0 0 1-1.491 3.4l-.842.261.1.875a2.454 2.454 0 0 1-2.514 2.731l-.88-.026-.19.86a2.455 2.455 0 0 1-3.265 1.767l-.824-.311-.459.752a2.456 2.456 0 0 1-3.662.611l-.679-.562-.678.562a2.455 2.455 0 0 1-3.662-.611l-.459-.752-.824.311a2.456 2.456 0 0 1-3.265-1.767l-.19-.86-.88.026a2.455 2.455 0 0 1-2.514-2.731l.1-.875-.842-.261a2.456 2.456 0 0 1-1.491-3.4l.378-.8-.711-.52a2.455 2.455 0 0 1-.306-3.7l.616-.63-.5-.722a2.456 2.456 0 0 1 .911-3.6l.787-.4-.242-.846A2.455 2.455 0 0 1 4.3 6.2l.873-.119.046-.879a2.455 2.455 0 0 1 2.93-2.28l.864.172.329-.817a2.456 2.456 0 0 1 3.511-1.205l.761.443.577-.666A2.453 2.453 0 0 1 17.9.848Z"
        fill="#5ac782"
      />
      <path
        data-name="Path 97462"
        d="m22.602 9.719-8.161 8.161-4.15-4.15a1.6 1.6 0 0 0-2.269 0l-1.134 1.133a1.6 1.6 0 0 0 0 2.269l6.418 6.418a1.6 1.6 0 0 0 2.269 0l10.43-10.43a1.6 1.6 0 0 0 0-2.269l-1.134-1.134a1.6 1.6 0 0 0-2.269.002Z"
        opacity={0.05}
      />
      <path
        data-name="Path 97463"
        d="m13.591 23.269-6.418-6.418a1.2 1.2 0 0 1 0-1.7l1.134-1.134a1.2 1.2 0 0 1 1.7 0l4.434 4.433 8.444-8.444a1.2 1.2 0 0 1 1.7 0l1.135 1.132a1.2 1.2 0 0 1 0 1.7l-10.43 10.43a1.2 1.2 0 0 1-1.699.001Z"
        opacity={0.07}
      />
      <path
        data-name="Path 97464"
        d="m13.874 22.985-6.418-6.42a.8.8 0 0 1 0-1.134l1.134-1.134a.8.8 0 0 1 1.134 0l4.717 4.717 8.728-8.728a.8.8 0 0 1 1.134 0l1.134 1.134a.8.8 0 0 1 0 1.134L15.009 22.985a.8.8 0 0 1-1.135 0Z"
        fill="#f0f026"
      />
    </g>
  </svg>
)

export default ReconcileSvgComponent
