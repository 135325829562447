import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { CustomerInfoCard } from "../components";


const CustomerInformation = ({ details = {}, t }) => {
  return (
    <Box p={2} pt={1}>
      <CustomerInfoCard data={{
        image: details?.customer_account?.logo ?? null,
        name: details?.customer_account?.name,
        email: details?.customer_account?.email_id,
        phone: `${details?.customer_account?.mobile_no_country_code} ${details?.customer_account?.mobile_no}`,
        title: t("customerAccount"),
        account_number: details?.customer_account?.account_no,
      }} />

      {/* <CustomerInfoCard data={{
        image: details?.invoice_account?.logo ?? null,
        name: details?.invoice_account?.name,
        email: details?.invoice_account?.email_id,
        phone: `${details?.invoice_account?.mobile_no_country_code} ${details?.invoice_account?.mobile_no}`,
        title: t("invoiceAccount"),
        account_number: details?.invoice_account?.account_no,
      }} /> */}

      <CustomerInfoCard data={{
        image: details?.contact?.image_url ?? null,
        name: `${details?.contact?.first_name} ${details?.contact?.last_name?.length > 0 ? details?.contact?.last_name : ""}`,
        email: details?.contact?.email_id,
        phone: `${details?.contact?.mobile_no_country_code ?? "+91"} ${details?.contact?.mobile_no}`,
        title: t("primaryContact"),
      }} />

      <CustomerInfoCard data={{
        image: details?.sale_man?.image_url ?? null,
        name: `${details?.sale_man?.first_name} ${details?.sale_man?.last_name ?? ""}`,
        email: details?.sale_man?.email_id,
        phone: `${details?.sale_man?.mobile_no_country_code ?? "+91"} ${details?.sale_man?.mobile_no}`,
        title: t("Sales Person"),
      }} />
    </Box>
  );
};
export default withNamespaces("agreement")(CustomerInformation); 