import * as React from "react"

const PowerdBy = (props) => (
  <svg
    id="a"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256.34 73.24"
        {...props}
        style={{height:props?.height,objectFit:"contain",marginTop:props?.top}}
  >
    <defs>
      <style>
        {
          ".b{letter-spacing:0}.c{fill:#f15a29}.e{letter-spacing:0}.g{fill:#414042}"
        }
      </style>
    </defs>
    <text
      transform="matrix(.96 0 0 1 7.55 23)"
      style={{
        fill: "#414042",
        fontFamily: "MyriadPro-Regular,Myriad Pro",
        fontSize: 18,
      }}
    >
      <tspan
        x={0}
        y={0}
        style={{
          letterSpacing: "-.02em",
        }}
      >
        {"P"}
      </tspan>
      <tspan className="e" x={9.13} y={0}>
        {"o"}
      </tspan>
      <tspan className="b" x={18.88} y={0}>
        {"w"}
      </tspan>
      <tspan x={31.95} y={0}>
        {"e"}
      </tspan>
      <tspan className="b" x={40.97} y={0}>
        {"r"}
      </tspan>
      <tspan x={46.67} y={0}>
        {"ed "}
      </tspan>
      <tspan className="e" x={69.66} y={0}>
        {"b"}
      </tspan>
      <tspan x={79.78} y={0}>
        {"y"}
      </tspan>
    </text>
    <path
      className="c"
      d="M8.14 41.45c0-3.12-.1-5.65-.2-7.9h5.26l.29 3.66h.1c1.75-2.68 4.53-4.19 8.14-4.19 5.46 0 9.99 4.68 9.99 12.09 0 8.58-5.41 12.72-10.82 12.72-2.97 0-5.46-1.27-6.68-3.17h-.1v12.28H8.13V41.45Zm5.99 5.9c0 .58.05 1.12.2 1.66.58 2.44 2.73 4.19 5.26 4.19 3.8 0 6.04-3.17 6.04-7.85 0-4.19-2.05-7.6-5.9-7.6-2.49 0-4.78 1.8-5.36 4.43-.15.49-.24 1.02-.24 1.51v3.66ZM36.65 41.2c0-3.22-.05-5.51-.2-7.65h5.21l.2 4.53h.19c1.17-3.36 3.95-5.07 6.48-5.07.58 0 .93.05 1.41.15v5.65c-.49-.1-1.02-.2-1.75-.2-2.88 0-4.83 1.85-5.36 4.53-.1.54-.2 1.17-.2 1.85v12.28h-5.99V41.19ZM76.13 45.2c0 8.72-6.14 12.62-12.18 12.62-6.73 0-11.89-4.63-11.89-12.23s5.12-12.57 12.28-12.57 11.79 4.97 11.79 12.18Zm-17.89.24c0 4.58 2.24 8.04 5.9 8.04 3.41 0 5.8-3.36 5.8-8.14 0-3.7-1.66-7.94-5.75-7.94s-5.95 4.09-5.95 8.04ZM81.05 41.45c0-3.12-.1-5.65-.2-7.9h5.26l.29 3.66h.1c1.75-2.68 4.53-4.19 8.14-4.19 5.46 0 9.99 4.68 9.99 12.09 0 8.58-5.41 12.72-10.82 12.72-2.97 0-5.46-1.27-6.68-3.17h-.1v12.28h-5.99V41.45Zm5.99 5.9c0 .58.05 1.12.2 1.66.58 2.44 2.73 4.19 5.26 4.19 3.8 0 6.04-3.17 6.04-7.85 0-4.19-2.05-7.6-5.9-7.6-2.49 0-4.78 1.8-5.36 4.43-.15.49-.24 1.02-.24 1.51v3.66Z"
    />
    <path
      className="g"
      d="M123.35 39.08h9.21c1.98 2.46 2.97 5.05 2.97 7.78 0 3.17-1.16 5.84-3.49 8-2.32 2.16-5.18 3.24-8.58 3.24-4.41 0-8.15-1.67-11.23-5.02-3.08-3.35-4.62-7.42-4.62-12.23s1.57-8.84 4.7-12.04S119.36 24 124.05 24c1.6 0 3.38.33 5.33.98v9.66c-1.52-.71-2.91-1.07-4.16-1.07-2.27 0-4.12.71-5.56 2.12s-2.15 3.24-2.15 5.5.67 4.12 2 5.47c1.33 1.35 3.13 2.02 5.4 2.02 1.44 0 2.17-.42 2.17-1.26 0-.63-.48-.95-1.43-.95h-2.31v-7.38ZM154.86 24c4.6 0 8.55 1.67 11.84 5.02 3.29 3.35 4.94 7.35 4.94 12.02s-1.66 8.77-4.97 12.09c-3.32 3.32-7.34 4.97-12.09 4.97s-8.73-1.67-12.06-5-5-7.35-5-12.06 1.68-8.79 5.03-12.09c3.35-3.3 7.46-4.95 12.31-4.95Zm-.29 9.57c-1.98 0-3.67.73-5.07 2.19-1.4 1.46-2.09 3.22-2.09 5.28s.7 3.82 2.11 5.28c1.4 1.46 3.09 2.19 5.06 2.19s3.69-.73 5.08-2.18c1.39-1.45 2.08-3.22 2.08-5.29s-.69-3.84-2.08-5.29c-1.39-1.45-3.08-2.18-5.08-2.18ZM190.31 34.13v23.15h-9.66V34.13h-7.4V24.8h24.44v9.33h-7.38ZM216.65 24c4.6 0 8.55 1.67 11.84 5.02 3.29 3.35 4.94 7.35 4.94 12.02s-1.66 8.77-4.97 12.09c-3.32 3.32-7.34 4.97-12.09 4.97s-8.73-1.67-12.06-5-5-7.35-5-12.06 1.68-8.79 5.03-12.09c3.35-3.3 7.46-4.95 12.31-4.95Zm-.29 9.57c-1.98 0-3.67.73-5.07 2.19-1.4 1.46-2.09 3.22-2.09 5.28s.7 3.82 2.11 5.28c1.4 1.46 3.09 2.19 5.06 2.19s3.69-.73 5.08-2.18c1.39-1.45 2.08-3.22 2.08-5.29s-.69-3.84-2.08-5.29c-1.39-1.45-3.08-2.18-5.08-2.18Z"
    />
    <path
      className="c"
      d="M243.9 46.63c1.55 0 2.89.56 4.01 1.69 1.12 1.13 1.68 2.47 1.68 4.02s-.56 2.88-1.67 3.96c-1.11 1.09-2.45 1.63-4.02 1.63s-2.98-.53-4.03-1.58-1.58-2.4-1.58-4.03.55-2.93 1.64-4.03 2.42-1.65 3.97-1.65Z"
    />
  </svg>
)

export default PowerdBy
