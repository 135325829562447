import React from "react";
import { withNavBars } from "../../HOCs";
import { CreateAccount } from '../addAccount/createAccount'

const VendorAccountCreateParent = () =>{
    return <CreateAccount reltype="Vendor"/>
}
const props = {
    boxShadow: false
}
export default withNavBars(VendorAccountCreateParent, props);
