import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";
export const correspondencesStyles = makeStyles((theme) => ({
    draft_root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 202px)`,
        overflow: "overlay",
        margin: "6px 12px 12px 12px"
    },
    preview_root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 202px)`,
        overflow: "overlay",
        margin: "6px 12px 12px 12px"
    },
    tab_div: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: theme.palette.background.tab_background_1,
        padding: "4px",
    },
    selected_tab: {
        cursor: "pointer",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: theme.palette.primary.main,
        padding: "7px 10px",
        minWidth: "62px",
    },
    unselected_tab: {
        cursor: "pointer",
        borderRadius: theme.palette.borderRadius,
        padding: "7px 10px",
        minWidth: "62px",
    },
    selected_tab_text: {
        textAlign: "center",
        fontSize: "12px",
        fontFamily: Bold,
        color: theme.typography.color.white,
    },
    unselected_tab_text: {
        textAlign: "center",
        fontSize: "12px",
        fontFamily: Bold,
        color: theme.typography.color.secondary,
    },
    button: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize: "14px",
        height: "40px",
    },
}));