import { useApolloClient } from "@apollo/client";
import { Badge, Box, Button, Divider, Grid, IconButton, Stack, } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import FilterIMG from "../../assets/filter";
import { FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../components";
import { config } from "../../config";
import { AuthContext, BackdropContext } from "../../contexts";
import { UPDATE_BLOCK } from "../../graphql/mutations";
import { GET_BLOCK_LIST_FILTER_BY_PROPERTY, GET_BLOCK_TYPE } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { NetWorkCallMethods, LocalStorageKeys, accessCheckRender, getRoutePermissionNew, getCustomBlockName } from "../../utils";
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";
import { BlockHeadingNew, BlockPath } from "../../utils/block";
import { blockStyles } from "./style";
export const BlockList = (props) => {
    const classes = blockStyles();
    const history = useHistory()
    const client = useApolloClient();
    const search = useLocation().search;
    const [loading, setLoading] = React.useState(true)
    const [blocklist, setBlockList] = React.useState({
        count: 0,
        data: [],
        property: {}
    })
    const [searchText, setSearchText] = React.useState("");
    const backdrop = React.useContext(BackdropContext);
    const auth = React.useContext(AuthContext)
    const propertyId = new URLSearchParams(search).get("propertyID");
    const [page, setPage] = React.useState(1);
    const [drawer, setDrawer] = React.useState(false);
    const [limit, setLimit] = React.useState(10);
    const debounce = UseDebounce();
    const headerpage = [
        {
            id: "1",
            label: getCustomBlockName(blocklist?.property?.property_hierarchyByID)
        }
    ]

    const [filterData, setFilterData] = React.useState({ status: [true] });
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            console.log(perm)
            setPermission(perm)
            if (perm?.read) {
                getblocklist(propertyId, null, searchText, 0, limit, "initial")
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getblocklist = (propertyId, filterProp, searchText, offset, limit, from) => {

        if (from !== "initial") {
            backdrop.setBackDrop({
                ...backdrop,
                open: true,
                message: "Fetching Data",
            });
        }

        let block_type = filterProp?.block_type?.length ? filterProp?.block_type?.map(_ => _?.value) : [];
        let active = filterProp?.status ?? [true]

        const payload = {
            query: GET_BLOCK_LIST_FILTER_BY_PROPERTY(propertyId, searchText, block_type, active, offset, limit, localStorage.getItem(LocalStorageKeys.clinetID)).loc.source.body,
        };

        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            const list = res.data.data.block?.map((val) => {

                let _d;
                try {
                    _d = {
                        main: val,
                        blockNo: val?.block_no,
                        blockName: val?.name,
                        propertyID: val?.property_id,
                        propertyName: val?.property?.name,
                        companyID: val?.property?.company?.id,
                        companyname: val?.property?.company?.name,
                        location: val?.property?.address?.city ? val?.property?.address?.city : val?.property?.address?.district,
                        floors: val?.floor?.length,
                        units: val?.unit?.length,
                        id: val?.id,
                        blocktype: val?.block_type_master?.name,
                        revenuetype: val?.property?.revenue_type,
                        icon: "editview",
                        status: val?.is_active ? "Active" : "Inactive",
                    };
                } catch (err) {

                }
                return _d;
            })

            setBlockList({
                count: res.data.data.count[0]?.count,
                data: list,
                property: res.data.data.property?.[0]
            })
            if (from !== "initial") {
                backdrop.setBackDrop({
                    open: false,
                });
            }

            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            if (from !== "initial") {
                backdrop.setBackDrop({
                    open: false,
                });
            }
            console.log(err)
        });
    }

    const changeactive = async (data, value) => {
        let result = await client.mutate({
            mutation: UPDATE_BLOCK,
            variables: {
                ID: data,
                updatePayload: {
                    is_active: value === "Inactive" ? true : false
                }
            }
        })
        if (result) {

            console.log(result)
            getblocklist(propertyId, filterData, searchText, 0, limit)
        }
    }
    const handleIcon = (type, data) => {
        console.log(type, data)
        if (type === "active") {
            changeactive(data?.id, data?.status)
        }

        if (type === "edit") {
            history.push({ pathname: Routes.createBlockNew, state: { main: { blockID: data?.id } } })
        }
        if (type === "view") {
            history.push(Routes.blockView + "?companyID=" + data?.companyID + "&blockID=" + data?.id + "&name=" + data?.blockName)
        }
    }
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getblocklist(propertyId, filterData, e, 0, limit)

    }

    const handleOnClickAddPropertyButton = () => {
        history.push({ pathname: Routes.createBlockNew, state: { main: { propertyID: propertyId } } })
    }

    const onUnitClick = (value, type) => {
        if (type === "floor") {
            history.push(Routes.floor + "?blockID=" + value?.id)
        } else if (type === "unit") {
            history.push(Routes.unit + "?blockID=" + value?.id)
        }
    }
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getblocklist(propertyId, filterData, searchText, offset, limit)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getblocklist(propertyId, filterData, searchText, 0, value)
    }
    const goBack = () => {
        history.push(Routes.properties)
    }

    const onApplyFilter = (value) => {
        setFilterData(value)
        getblocklist(propertyId, value, searchText, 0, limit)
    }

    const render = () => {
        return <>
            {
                loading ?
                    <LoadingSection bottom={"45vh"} message={"Loading " + getCustomBlockName(blocklist?.property?.property_hierarchyByID) + " Listing..."} />
                    :
                    <div className={classes.root}>
                        <Subheader arrow pageoptions={headerpage} title={blocklist?.property?.name} goBack={goBack} />
                        <Box p={2} m={2} className={classes.table}>
                            <Grid container >
                                <Grid item xs={4}>
                                    <SearchFilter value={searchText}
                                        placeholder={"Search " + getCustomBlockName(blocklist?.property?.property_hierarchyByID)}
                                        handleChange={(value) => handleSearch(value)} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Box
                                        display={"flex"}
                                        sx={{ float: "right" }}
                                    >
                                        <Stack
                                            direction="row"
                                            divider={<Divider orientation="vertical" flexItem />}
                                            spacing={2}
                                        >
                                            {filterData?.status?.length > 0 || filterData?.block_type?.length > 0 ? (
                                                <IconButton onClick={() => setDrawer(true)} className={classes.img}>
                                                    <Badge variant="dot" color="primary">
                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton>
                                            ) : (
                                                <IconButton onClick={() => setDrawer(true)} className={classes.img}>
                                                    <FilterIMG color="#091b29" />
                                                </IconButton>)}
                                            {(permission?.create &&
                                                blocklist?.property?.property_hierarchyByID?.is_block) &&
                                                <Button variant="contained" className={classes.btn}
                                                    onClick={handleOnClickAddPropertyButton}>
                                                    {"Add " + getCustomBlockName(blocklist?.property?.property_hierarchyByID)}
                                                </Button>}
                                        </Stack>
                                    </Box>
                                </Grid>

                            </Grid>
                            <TableWithPagination
                                heading={BlockHeadingNew(blocklist?.property?.property_hierarchyByID)}
                                rows={blocklist?.data}
                                path={BlockPath}
                                showpagination={true}
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleIcon}
                                tableType="no-side"
                                onClick={() => console.log("")}
                                onUnitClick={permission?.read ? onUnitClick : false}
                                dataType={[
                                    { type: ["text"], name: "blockNo" },
                                    { type: ["text"], name: "blockName" },
                                    { type: ["text"], name: "companyname" },
                                    { type: ["description"], name: "location" },
                                    { type: ["floor"], name: "floors" },
                                    { type: ["unit"], name: "units" },
                                    { type: ["text"], name: "revenuetype" },
                                    { type: ["blockType"], name: "blocktype" },
                                    { type: ["status"], name: "status" },
                                    { type: ["more"], icon: "icon" },
                                ]}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={blocklist?.count}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 300px)'}
                                view={permission?.read}
                                edit={permission?.update}
                                delete={permission?.delete} />
                        </Box>
                    </div>
            }
            {/* filter drawer */}
            {
                drawer &&
                <FilterGenerator
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    components={[
                        {
                            component: "select",
                            value: filterData?.block_type,
                            options: [],
                            isMulti: true,
                            label: "Block Type",
                            state_name: "block_type",
                            loadOptions: (search, array, handleLoading) => loadOptions(
                                search,
                                array,
                                handleLoading,
                                GET_BLOCK_TYPE,
                                'block_type_master',
                            ),
                            debounceTimeout: 800,
                            isPaginate: true,
                        },
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: [
                                { label: 'Active', value: true },
                                { label: 'Inactive', value: false },
                            ],
                            isMulti: true,
                            state_name: "status",
                            label: "Status",
                            // required:true
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            }
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};
