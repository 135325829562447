import React, { useState } from "react";
import { Typography, Box, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { useStyles } from "./style";
import { Folder, Document } from "../../assets";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export const FolderComponent = (
  {
    t,
    data = {},
    handleIconView = () => false,
    selectedFolder = {},
    handleEdit = () => false,
    handleDetail = () => false,
    handleDownload = () => false,
    handleDelete = () => false,
  }
) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box className={classes.folderParent}>
      <Box className={classes.folderRoot}
        onClick={() => handleIconView(data)}>
        {!data?.is_dir ? (
          <>
            <div style={{ display: "flex", height: "50px", justifyContent: "center", alignItems: "center" }}><Document /></div>
            <Box className={classes.chip}>{data?.data?.file_meta?.file_ext}</Box>
          </>
        ) : (
          <div style={{ display: "flex", height: "50px", justifyContent: "center", alignItems: "center" }}><Folder /></div>
        )}
      </Box>
      <Box className={classes.subRoot}>
        <Stack container direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Stack direction={"column"}>
            <Typography className={classes.folderName}>{data?.Folder_Name}</Typography>
            <Typography className={classes.folderSub}>
              {"Created On " + data?.createdAt}
            </Typography>
          </Stack>
          <Box>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className={classes.menuList}
            >
              {(selectedFolder?.id !== 0) && <MenuItem
                onClick={() => {
                  handleClose();
                  handleEdit(data)
                }}
                className={classes.menuItem}>
                {t("Edit")}
              </MenuItem>}
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleDetail(data)
                }}
                className={classes.menuItem}>
                {t("Details")}
              </MenuItem>
              {!data?.is_dir && <MenuItem
                onClick={() => {
                  handleClose();
                  handleDownload(data)
                }}
                className={classes.menuItem}>
                {t("Download")}
              </MenuItem>}
              {(selectedFolder?.id !== 0) && <MenuItem
                onClick={() => {
                  handleClose();
                  handleDelete(data)
                }}
                className={classes.menuItem}>
                {t("Delete")}
              </MenuItem>}
            </Menu>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
