import React from "react";
import { withNavBars } from "../../HOCs";
import { Properties } from "./properties";

class PropertyParent extends React.Component {
  render() {
    return <Properties {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(PropertyParent, props);
