import { Box, Button, Grid, Typography,Avatar } from "@mui/material";
import { useStylesDetailsCard } from "./styles";
export const DetailsCardCoponent = ({
  components = [],
  title = null,
  border = true,
}) => {
  const classes = useStylesDetailsCard(border);
  const switchComponents = (val) => {
    if (val?.isActive) {
      switch (val.component) {
        case "text":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <Typography className={classes.heading}>
                {val?.heading}
              </Typography>
              <Typography className={classes.sub}>{val?.subtitle}</Typography>
            </Grid>
          );
        case "status":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <Typography className={classes.heading}>
                {val?.heading}
              </Typography>
              <Typography
                sx={{ color: val?.color, backgroundColor: val?.bgColor }}
                className={classes.status}
              >
                {val?.status}
              </Typography>
            </Grid>
          );
        case "button":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <Typography className={classes.heading}>
                {val?.heading}
              </Typography>
              <Button
                variant={val?.variant}
                className={classes.btn}
                onClick={val?.onClick}
              >
                {val?.subtitle}
              </Button>
            </Grid>
          );
        case "tag":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <Typography className={classes.heading}>
                {val?.heading}
              </Typography>
              <Typography sx={{ color: val?.color }} className={classes.status}>
                {val?.status}
              </Typography>
            </Grid>
          );
        case "description":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <Typography className={classes.heading}>
                {val?.heading}
              </Typography>
              {/* <Typography className={classes.sub}>{val?.subtitle}</Typography> */}
              <span
                className={classes.sub}
                dangerouslySetInnerHTML={{ __html: val?.subtitle }}
              />
            </Grid>
          );
        case "image":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <Avatar src={val?.image} className={classes.imageView}/>
            </Grid>
          );
        default:
          return null;
      }
    }
  };
  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        {title && (
          <Grid item xs={12}>
            <Typography className={classes.title}>{title}</Typography>
          </Grid>
        )}

        {components?.length &&
          components?.map((_) => <>{switchComponents(_)}</>)}
      </Grid>
    </Box>
  );
};
