export const TabTitleList = [
    {
        title: "General Inspection Items",
        value: '1'
    },
    {
        title: "Template Base Items",
        value: '2'
    },
]

export const InspectionItemsDatas = [
    {
        id: 1,
        Name: "Inspection Name Here",
        image: "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 0,
        is_active: false,
    },
    {
        id: 2,
        Name: "Inspection Name Here1",
        image:
            "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 0,
        is_active: false
    },
];

export const templateItems = [
    {
        id: 3,
        Name: "Inspection Name Here",
        image:
            "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 1,
        sub_items: [

            {
                id: 11,
                image: "",
                Name: "Template Name Here",
                count: 1,
            },
            {
                id: 12,
                image: "",
                Name: "Template Name Here",
                count: 1,
            },
            {
                id: 13,
                image: "",
                Name: "Template Name Here",
                count: 1,
            },
        ],
    },
    {
        id: 4,
        Name: "Inspection Name Here1",
        image:
            "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        count: 1,
        sub_items: [
            {
                id: 14,
                image: "",
                Name: "Template Name Here",
                count: 1,
            },
            {
                id: 15,
                image: "",
                Name: "Template Name Here",
                count: 1,
            },
            {
                id: 16,
                image: "",
                Name: "Template Name Here",
                count: 1,
            },
        ],
    },
]