import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import {ProfileCardStyle} from './style'
export const ProfileCard = ({data={}}) =>{
    const classes = ProfileCardStyle()
    return(
        <Box>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Avatar src={data?.created_by?.image_url}></Avatar>
                <Stack>
                    <Stack>
                        <Typography className={classes.name}> {data?.created_by?.first_name} </Typography>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Typography className={classes.mobno_email}>{data?.created_by?.mobile_no_country_code} {data?.created_by?.mobile_no}</Typography>
                            <Box className={classes.dot}></Box>
                            <Typography className={classes.mobno_email}>{data?.created_by?.email_id}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}