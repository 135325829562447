import React from 'react';
import { Box, Grid, Typography } from "@mui/material";
import { useStylesCreation } from "../style";
import { TextBox, NewDatePicker } from "../../../components";
import styled from '@mui/material/styles/styled';
import { Bold } from '../../../utils';
export const NationalIDDetails = (props) => {
      const CustomTypography = styled(Typography)(({ theme }) => ({
            fontSize: 12,
            fontFamily: Bold,
            color: theme.typography.color.secondary,
            marginBottom: theme.spacing(1)
      }))
      const textBoxStyle = {
            padding: "8px 14px",
            borderRadius: 8,
            height: 47
      }
      const classes = useStylesCreation();
      return (
            <Box className={classes.imagebox1}>
                  <CustomTypography>ID Details</CustomTypography>
                  <Grid container spacing={2}>

                        <Grid item xs={12} sm={4} md={3} lg={2}>
                              <TextBox
                                    {...textBoxStyle}
                                    label="National ID"
                                    placeholder="Enter National ID"
                                    value={props?.data?.nationalID ?? null}
                                    onChange={(e) => {
                                          props?.updateState("nationalID", e.target.value);
                                    }} 
                                    isError={props?.data?.error?.nationalID?.length > 0}
                                    errorMessage={props?.data?.error?.nationalID}
                                    />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                              <NewDatePicker
                                    label="National ID Expiry"
                                    placeholder="National ID Expiry"
                                    minDate={new Date()}
                                    value={props?.data?.nationalIDExpiry ?? null}
                                    onChange={(value) => props?.updateState("nationalIDExpiry", value)} 
                                    isError={props?.data?.error?.nationalIDExpiry?.length > 0}
                                    errorMessage={props?.data?.error?.nationalIDExpiry}
                                    />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                              <TextBox
                                    {...textBoxStyle}
                                    label="Passport ID"
                                    placeholder="Enter Passport ID"
                                    value={props?.data?.passportID ?? null}
                                    onChange={(e) => {
                                          props?.updateState("passportID", e.target.value);
                                    }} 
                                    isError={props?.data?.error?.passportID?.length > 0}
                                    errorMessage={props?.data?.error?.passportID}
                                    />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                              <NewDatePicker
                                    label="Passport Expiry"
                                    placeholder="Passport Expiry"
                                    minDate={new Date()}
                                    value={props?.data?.passportIDExpiry ?? null}
                                    onChange={(value) => props?.updateState("passportIDExpiry", value)} 
                                    isError={props?.data?.error?.passportIDExpiry?.length > 0}
                                    errorMessage={props?.data?.error?.passportIDExpiry}
                                    />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                              <TextBox
                                    {...textBoxStyle}
                                    label="Resident ID"
                                    placeholder="Enter Resident ID"
                                    value={props?.data?.residentID ?? null}
                                    onChange={(e) => {
                                          props?.updateState("residentID", e.target.value);
                                    }} 
                                    isError={props?.data?.error?.residentID?.length > 0}
                                    errorMessage={props?.data?.error?.residentID}
                                    />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                              <NewDatePicker
                                    label="Resident ID Expiry"
                                    placeholder="Resident ID Expiry"
                                    minDate={new Date()}
                                    value={props?.data?.residentIDExpiry ?? null}
                                    onChange={(value) => props?.updateState("residentIDExpiry", value)} 
                                    isError={props?.data?.error?.residentIDExpiry?.length > 0}
                                    errorMessage={props?.data?.error?.residentIDExpiry}
                                    />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                              <TextBox
                                    {...textBoxStyle}
                                    label="Visa Number"
                                    placeholder="Enter Visa Numbers"
                                    value={props?.data?.visaNumber ?? null}
                                    onChange={(e) => {
                                          props?.updateState("visaNumber", e.target.value);
                                    }} 
                                    isError={props?.data?.error?.visaNumber?.length > 0}
                                    errorMessage={props?.data?.error?.visaNumber}
                                    />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                              <NewDatePicker
                                    label="Visa Expiry"
                                    placeholder="Visa Expiry"
                                    minDate={new Date()}
                                    value={props?.data?.visaNumberExpiry ?? null}
                                    onChange={(value) => props?.updateState("visaNumberExpiry", value)} 
                                    isError={props?.data?.error?.visaNumberExpiry?.length > 0}
                                    errorMessage={props?.data?.error?.visaNumberExpiry}
                                    />
                        </Grid>
                  </Grid>
            </Box>
      )
}