import React from "react";
import { withNavBars } from "../../HOCs";
import { DomesticHelperView } from "./domesticHelperView";

class DomesticHelperViewParent extends React.Component {
  render() {
    return <DomesticHelperView />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(DomesticHelperViewParent, props);
