import { Box, Grid } from "@mui/material";
import moment from "moment";
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CityProperty from "../../assets/cityproperty";
import { PropertyTabs, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { getRoutePermissionNew, mapResult } from '../../utils/common';
import { useStylesCreation } from "./style";
import { BankingDetails } from "./tabs/bankingDetails";
import { GeneralDetails } from './tabs/generalDetails';
import { NationalIDDetails } from './tabs/nationalIDDetails';
import { ContactProof } from "./tabs/proof";

export const CreateContactNew = ({type =""}) => {
      const { state } = useLocation();
      const initial = {
            id: state?.main?.id,
            isActive: true,
            company: type === "Agent" ? JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany)) : state?.main?.company?.value ? state?.main?.company : "",
            companyList: state?.main?.companyList ?? [],
            isAllCompany: state?.main?.isAllCompany,
            relationship: type === "Agent" ? {
                  label: "Sales Broker",
                  value: "External Sales Broker"
              } : state?.main?.relationship ?? [],
            role: state?.main?.role,
            vendor: state?.main?.vendor,
            userID: "",
            userProfileID: "",
            profileImage: "",
            name: "",
            jobTitle: "",
            properties: "",
            initialProperties: [],
            deletedProperties: [],
            doorNo: "",
            addressLineOne: "",
            addressLineTwo: "",
            landmark: "",
            area: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            latitude: "",
            longitude: "",
            preferredModeContact: "",
            primaryTelephone: "",
            primaryMobile: "",
            primaryEmail: "",
            emergencyContactName: "",
            emergencyContactRelationship: "",
            emergencyContactNumber: "",
            facebook: "",
            twitter: "",
            linkedin: "",
            nationality: "",
            nationalID: "",
            nationalIDExpiry: "",
            passportID: "",
            passportIDExpiry: "",
            residentID: "",
            residentIDExpiry: "",
            visaNumber: "",
            visaNumberExpiry: "",
            bankName: "",
            bankBranchName: "",
            bankCurrency: "",
            bankAccountType: "",
            bankAccountNumber: "",
            bankRoutingType: "",
            bankRoutingCode: "",
            bankAddressLineOne: "",
            bankAddressLineTwo: "",
            bankCity: "",
            bankCountry: "",
            chequeName: null,
            preferredCashCollectionOffice: "",
            otherRelationship: "",
            otherDepartment: [],
            Department: [],
            member: {},
            proofFile: [],
            sex: [],
            whatsApp: "",
            employee_id: "",
            vendorlist:"",
            error: {
                  company: "",
                  profileImage: "",
                  name: "",
                  jobTitle: "",
                  properties: "",
                  primaryTelephone: "",
                  primaryMobile: "",
                  primaryEmail: "",
                  relationship: "",
                  otherRelationship: "",
                  Department: "",
                  member: "",
                  nationalID: "",
                  nationalIDExpiry: "",
                  passportID: "",
                  passportIDExpiry: "",
                  residentID: "",
                  residentIDExpiry: "",
                  visaNumber: "",
                  visaNumberExpiry: "",
                  vendorlist:""

            }
      }
      const history = useHistory();
      const classes = useStylesCreation();
      const [data, setData] = React.useState({ ...initial })
      const [mapLoad, setMapLoad] = React.useState(!Boolean(state?.main?.isEdit))
      const [disable, setDisable] = React.useState(false)
      const backdrop = React.useContext(BackdropContext);
      const alert = React.useContext(AlertContext);
      const auth = React.useContext(AuthContext)
      const debounce = UseDebounce();
      const [load, setLoad] = React.useState(false)
      const [value, setValue] = React.useState(1);
      const [permission, setPermission] = React.useState({})

      // use effect to get permission
      React.useEffect(() => {
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                  setPermission(perm)
                  if (perm?.read) {
                        if (state?.main?.isEdit) { getContact() }
                  }
            }
            // eslint-disable-next-line
      }, [auth, state?.main?.isEdit]);

      const tabTittle = [
            {
                  label: 'Contact Details',
                  sub: 'Contact Details',
                  imgSelect: value > 1 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
                  value: 1,
                  className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                  label: 'KYC Details',
                  sub: 'KYC Details',
                  imgSelect: value > 2 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
                  value: 2,
                  className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
            },
      ]

      //update image
      const updateimg = (data) => {
            backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading", });
            const formData = new FormData();
            formData.append("files", data);
            formData.append("tenantId", `${config.tenantId}`);

            NetworkCall(
                  `${config.api_url}/file-upload`,
                  NetWorkCallMethods.post,
                  formData, null, true, false
            ).then((res) => {
                  updateState("profileImage", res?.data?.fileUrls?.[0]?.url)
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            }).catch((err) => {
                  alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                  });
            });
      };
      //map result
      const mapResultData = (map) => {
            let result = mapResult(map);
            setData({ ...data, ...result })
      }

      //validate
      const validate = () => {
            let isValid = true;
            let error = data.error;
            if (data?.isAllCompany && data?.company?.length === 0) { isValid = false; error.company = "Company is Required"; }
            if (data?.profileImage?.length === 0) { isValid = false; error.profileImage = "Profile Image is Required"; }
            if (data?.name?.length === 0) { isValid = false; error.name = "Name is Required"; }
            if (data?.relationship?.value === "Other" && data?.otherRelationship?.length === 0) {
                  isValid = false; error.otherRelationship = "Relationship is Required";
            }
            if (data?.primaryEmail?.length === 0) { isValid = false; error.primaryEmail = "Email ID is Required"; }
            if (data?.primaryMobile?.length === 0) { isValid = false; error.primaryMobile = "Mobile Number is Required"; }
            if (data?.primaryTelephone?.length === 0) { isValid = false; error.primaryTelephone = " Telephone Number is Required"; }
            if (data?.relationship?.length === 0) { isValid = false; error.relationship = "Relationship is Required"; }
            if(type !== "Agent"){
                  if(data?.relationship?.label === "Employee"){
                        if (data?.Department?.length === 0) { isValid = false; error.Department = "OtherDepartment is Required"; }
                  }
            }
            if (data?.sex?.length === 0) { isValid = false; error.sex = "Gender is Required"; }
            if (data?.employee_id?.length === 0) { isValid = false; error.employee_id = "company id is Required"; }

            if (data?.nationalID?.length === 0) { isValid = false; error.nationalID = "National ID is Required"; }
            if (!data?.nationalIDExpiry) { isValid = false; error.nationalIDExpiry = "National ID Expiry is Required"; }
            if (data?.passportID?.length === 0) { isValid = false; error.passportID = "Passport ID is Required"; }
            if (!data?.passportIDExpiry) { isValid = false; error.passportIDExpiry = "Passport Expiry"; }
            if (data?.residentID?.length === 0) { isValid = false; error.residentID = "Resident ID"; }
            if (!data?.residentIDExpiry) { isValid = false; error.residentIDExpiry = "Resident ID Expiry"; }
            if (data?.visaNumber?.length === 0) { isValid = false; error.visaNumber = "Visa Number"; }
            if (!data?.visaNumberExpiry) { isValid = false; error.visaNumberExpiry = "Visa Expiry"; }
            if(type === "Agent"){if(data?.vendorlist?.length === 0 ){isValid = false; error.vendorlist = "Vendor is Required"; }}
            if (!isValid) {
                  alert.setSnack({
                        ...alert, open: true, 
                        severity: AlertProps.severity.error,
                        msg: "Please fill all mandatory field",
                  });
            }
            setData({ ...data, error });
            return isValid;
      };
      //save
      const save = () => {
            let currentDate = new Date().toISOString();
            if (ValidateProof(data?.proofFile)) {
                  setDisable(true)
                  let tempProperties = [];
                  let tempDeletedProperties = [];
                  let datas = {
                        tenantId: `${config.tenantId}`,
                        id: data?.id ?? null,
                        company_id: data?.company?.value ?? null,
                        relationship: data?.relationship?.value ?? null,
                        role: data?.role?.value ?? null,
                        // vendor_id: data?.vendor?.value ?? null,
                        user_id: data?.userID ?? null,
                        user_profile_id: data?.userProfileID ?? null,
                        properties: tempProperties ?? null,
                        deleteProperties: tempDeletedProperties ?? null,
                        image_url: data?.profileImage ?? null,
                        first_name: data?.name ?? null,
                        job_title: data?.jobTitle ?? null,
                        door_no: data?.doorNo ?? null,
                        street_1: data?.addressLineOne ?? null,
                        street_2: data?.addressLineTwo ?? null,
                        landmark: data?.landmark ?? null,
                        area: data?.area ?? null,
                        city: data?.city ?? null,
                        state: data?.state ?? null,
                        zipcode: data?.zipcode ?? null,
                        country: data?.country ?? null,
                        latitude: data?.latitude ? parseFloat(data?.latitude) : null,
                        longitude: data?.longitude ? parseFloat(data?.longitude) : null,
                        preferred_mode_contact: data?.preferredModeContact?.value ?? null,
                        phone_code: data?.primaryTelephone?.mobile_code ?? null,
                        phone: data?.primaryTelephone?.mobile ?? null,
                        mobile_no_country_code: data?.primaryMobile?.mobile_code ?? null,
                        mobile_no: data?.primaryMobile?.mobile ?? null,
                        email_id: data?.primaryEmail ?? null,
                        emergency_contact_name: data?.emergencyContactName ?? null,
                        emergency_contact_relationship: data?.emergencyContactRelationship?.value ?? null,
                        emergency_contact_code: data?.emergencyContactNumber?.mobile_code ?? null,
                        emergency_contact_number: data?.emergencyContactNumber?.mobile ?? null,
                        facebook: data?.facebook ?? null,
                        twitter: data?.twitter ?? null,
                        linkedin: data?.linkedin ?? null,
                        nationality: data?.nationality?.label ?? null,
                        national_id: data?.nationalID ?? null,
                        national_id_expiry: data?.nationalIDExpiry ? moment(data?.nationalIDExpiry).format("yyyy-MM-DD") : null,
                        passport_id: data?.passportID ?? null,
                        passport_expiry: data?.nationalIDExpiry ? moment(data?.nationalIDExpiry).format("yyyy-MM-DD") : null,
                        visa_no: data?.visaNumber ?? null,
                        visa_expiry: data?.nationalIDExpiry ? moment(data?.nationalIDExpiry).format("yyyy-MM-DD") : null,
                        resident_id: data?.residentID ?? null,
                        resident_id_expiry: data?.nationalIDExpiry ? moment(data?.nationalIDExpiry).format("yyyy-MM-DD") : null,
                        bank_name: data?.bankName ?? null,
                        branch_name: data?.bankBranchName ?? null,
                        bank_currency_id: data?.bankCurrency?.value ? data?.bankCurrency?.value : null,
                        account_type: (data?.bankAccountType || data?.bankAccountType !== "") ? data?.bankAccountType : null,
                        account_no: data?.bankAccountNumber ?? null,
                        routing_type: data?.bankRoutingType?.value ? data?.bankRoutingType?.value : null,
                        routing_code: data?.bankRoutingCode ?? null,
                        address_1: data?.bankAddressLineOne ?? null,
                        address_2: data?.bankAddressLineTwo ?? null,
                        bank_city: data?.bankCity ?? null,
                        bank_country: data?.bankCountry?.value ?? null,
                        cheque_name: data?.chequeName ?? null,
                        cash_collection_office: data?.preferredCashCollectionOffice ?? null,
                        is_active: data?.isActive,
                        created_by: data?.id ? localStorage.getItem(LocalStorageKeys.profileID) : null,
                        updated_by: localStorage.getItem(LocalStorageKeys.profileID),
                        created_at: data?.id ? currentDate : null,
                        updated_at: currentDate,
                        department_id: data?.Department?.value ?? null,
                        department: data?.Department?.label ?? null,
                        other_relationship: data?.otherRelationship ?? null,
                        reporting_to: data?.member?.id ?? null,
                        vendor_id:data?.vendorlist?.value ?? null,
                        proof: data?.proofFile?.filter(val => val?.is_active).map(val => {
                              return {
                                    id: val?.id ?? undefined,
                                    proof_id: val?.type?.value,
                                    proof_type: val?.type?.proof_type,
                                    kyc_type: "Both",
                                    name: val?.name,
                                    number: val?.id_no,
                                    valid_from: val?.valid_from,
                                    valid_to: val?.valid_to,
                                    url: val?.file,
                                    is_mandatory: val?.mandatry_verify,
                                    country_id: val?.issuing_country?.value,
                                    is_active: val?.is_active
                              }
                        }),
                        whatsapp: data?.whatsApp ?? null,
                        sex_type: data?.sex?.value ?? null,
                        employee_id: data?.employee_id ?? null
                  }
                  NetworkCall(
                        `${config.api_url}/contact/upsert`,
                        NetWorkCallMethods.post,
                        datas, null, true, false).then((response) => {
                              history.goBack(-1)
                              alert.setSnack({
                                    ...alert, open: true,
                                    severity: AlertProps.severity.success,
                                    msg: `${type === "Agent" ? "Sales Agent" :"Contact"} ${state?.main?.isEdit ? "Updated" : "Created"}  successfully.`,
                              });
                        }).catch((error) => {
                              alert.setSnack({
                                    ...alert, open: true,
                                    severity: AlertProps.severity.error,
                                    msg: "Internal error. Please try again later.",
                              });
                              setDisable(false)
                        });
            }
            else {
                  alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error,
                        msg: "Please fill all mandatory field",
                  });
            }
      }

      const checkSave = (type) => {
            if (data?.id) {
                  if (permission.update) {
                        type === "previous" && previous()
                        type === "next" && next()
                        type === "save" && save()
                  } else {
                        alert.setSnack({
                              ...alert, open: true, msg: "Access denied. Contact your administrator.",
                              severity: AlertProps.severity.error,
                        })
                  }
            } else if (permission.create) {
                  type === "previous" && previous()
                  type === "next" && next()
                  type === "save" && save()
            } else {
                  alert.setSnack({
                        ...alert, open: true, msg: "Access denied. Contact your administrator.",
                        severity: AlertProps.severity.error,
                  })
            }
      }
      //checkexists
      const checkExsits = (k, v, m) => {
            let datas = {
                  tenantId: `${config.tenantId}`,
                  [k]: v
            }
            NetworkCall(
                  `${config.api_url}/contact/${k === "user_name" ? `findUserExisting` : `findContactExisting`}`,
                  NetWorkCallMethods.post,
                  datas, null, true, false).then((response) => {
                        if (response?.data?.is_Exist && v?.length > 0) {
                              alert.setSnack({
                                    ...alert, open: true,
                                    severity: AlertProps.severity.warning,
                                    msg: `${m} is already exists`,
                              });
                        }
                  }).catch((error) => {
                        alert.setSnack({
                              ...alert, open: true,
                              severity: AlertProps.severity.error,
                              msg: "Internal error. Please try again later.",
                        });
                  });
      }
      //updatestate
      const updateState = (k, v) => {

            if (data?.relationship?.value === "Company Employee" && k === 'name') {
                  debounce(() => checkExsits("user_name", v, 'Name'), 1700);
            }
            if (k === "primaryEmail") {
                  debounce(() => checkExsits("email_id", v, 'Email Id'), 1700);
            }
            if (k === 'primaryMobile') {
                  debounce(() => checkExsits("mobile_no", v?.mobile, 'Primary Mobile Number'), 1700);
            }
            let error = data?.error;
            error[k] = "";
            setData({ ...data, [k]: v, error })
      }
      //contactDetails
      const getContactDetailsByID = () => {
            backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading", });
            let datas = { tenantId: `${config.tenantId}`, id: state?.main?.id }
            NetworkCall(
                  `${config.api_url}/contact/get`,
                  NetWorkCallMethods.post,
                  datas, null, true, false
            ).then((res) => {
                  let tempContactDetails = res?.data?.data
                  let properties = tempContactDetails?.properties?.map((val) => {
                        return { value: val?.id ?? "", label: val?.name ?? "" }
                  })
                  let initialProperties = properties?.map((x) => x?.value)
                  setData({
                        id: tempContactDetails?.id,
                        isActive: tempContactDetails?.is_active ?? false,
                        company: tempContactDetails?.company_id ?
                              {
                                    value: tempContactDetails?.company_id,
                                    label: tempContactDetails?.company_name,
                                    company_no: tempContactDetails?.company_no,
                              } : null,
                        relationship: tempContactDetails?.relationship ?
                              {
                                    value: tempContactDetails?.relationship,
                                    label: tempContactDetails?.relationship,
                              } : null,
                        role: tempContactDetails?.role_id ?
                              {
                                    value: tempContactDetails?.role_id,
                                    label: tempContactDetails?.role_name,
                              } : null,
                        vendor: tempContactDetails?.vendor_id ?
                              {
                                    value: tempContactDetails?.vendor_id,
                                    label: tempContactDetails?.vendor_name,
                              } : null,
                        userID: tempContactDetails?.user_id ?? null,
                        userProfileID: tempContactDetails?.user_profile_id ?? null,
                        profileImage: tempContactDetails?.image_url ?? null,
                        name: tempContactDetails?.first_name ?? null,
                        jobTitle: tempContactDetails?.job_title ?? null,
                        properties: properties ?? null,
                        initialProperties: initialProperties ?? null,
                        deletedProperties: [],
                        doorNo: tempContactDetails?.door_no ?? null,
                        addressLineOne: tempContactDetails?.street_1 ?? null,
                        addressLineTwo: tempContactDetails?.street_2 ?? null,
                        landmark: tempContactDetails?.landmark ?? null,
                        area: tempContactDetails?.area ?? null,
                        city: tempContactDetails?.city ?? null,
                        state: tempContactDetails?.state ?? null,
                        country: tempContactDetails?.country ?? null,
                        zipcode: tempContactDetails?.zipcode ?? null,
                        latitude: tempContactDetails?.latitude ?? null,
                        longitude: tempContactDetails?.longitude ?? null,
                        preferredModeContact: tempContactDetails?.preferred_mode_contact ?
                              {
                                    value: tempContactDetails?.preferred_mode_contact,
                                    label: tempContactDetails?.preferred_mode_contact,
                              } : null,
                        primaryTelephone: tempContactDetails?.phone ? {
                              mobile: tempContactDetails?.phone,
                              mobile_code: tempContactDetails?.phone_code
                        } : null,
                        primaryMobile: tempContactDetails?.mobile_no ? {
                              mobile: tempContactDetails?.mobile_no,
                              mobile_code: tempContactDetails?.mobile_no_country_code
                        } : null,
                        primaryEmail: tempContactDetails?.email_id ?? null,
                        emergencyContactName: tempContactDetails?.emergency_contact_name ?? null,
                        emergencyContactRelationship: tempContactDetails?.emergency_contact_relationship ?
                              {
                                    value: tempContactDetails?.emergency_contact_relationship,
                                    label: tempContactDetails?.emergency_contact_relationship,
                              } : null,
                        emergencyContactNumber: tempContactDetails?.emergency_contact_number ? {
                              mobile: tempContactDetails?.emergency_contact_number,
                              mobile_code: tempContactDetails?.emergency_contact_code
                        } : null,
                        facebook: tempContactDetails?.facebook ?? null,
                        twitter: tempContactDetails?.twitter ?? null,
                        linkedin: tempContactDetails?.linkedin ?? null,
                        nationality: {label:tempContactDetails?.nationality, value: tempContactDetails?.nationality} ?? null,
                        nationalID: tempContactDetails?.national_id ?? null,
                        nationalIDExpiry: tempContactDetails?.national_id_expiry ? new Date(tempContactDetails?.national_id_expiry) : null,
                        passportID: tempContactDetails?.passport_id ?? null,
                        passportIDExpiry: tempContactDetails?.passport_expiry ? new Date(tempContactDetails?.passport_expiry) : null,
                        residentID: tempContactDetails?.resident_id ?? null,
                        residentIDExpiry: tempContactDetails?.resident_id_expiry ? new Date(tempContactDetails?.resident_id_expiry) : null,
                        visaNumber: tempContactDetails?.visa_no ?? null,
                        visaNumberExpiry: tempContactDetails?.visa_expiry ? new Date(tempContactDetails?.visa_expiry) : null,
                        bankName: tempContactDetails?.bank_name ?? null,
                        bankBranchName: tempContactDetails?.branch_name ?? null,
                        bankCurrency: tempContactDetails?.bank_currency_id ? {
                              value: tempContactDetails?.bank_currency_id,
                              label: tempContactDetails?.bank_currency_code
                        } : null,
                        bankAccountType: tempContactDetails?.account_type ?? null,
                        bankAccountNumber: tempContactDetails?.account_no ?? null,
                        bankRoutingType: tempContactDetails?.routing_type ?
                              {
                                    value: tempContactDetails?.routing_type,
                                    label: tempContactDetails?.routing_type,
                              } : null,
                        bankRoutingCode: tempContactDetails?.routing_code ?? null,
                        bankAddressLineOne: tempContactDetails?.address_1 ?? null,
                        bankAddressLineTwo: tempContactDetails?.address_2 ?? null,
                        bankCity: tempContactDetails?.bank_city ?? null,
                        bankCountry: tempContactDetails?.bank_country ?
                              {
                                    value: tempContactDetails?.bank_country,
                                    label: tempContactDetails?.bank_country_name,
                              } : null,
                        chequeName: tempContactDetails?.cheque_name ?? null,
                        preferredCashCollectionOffice: tempContactDetails?.cash_collection_office ?? null,
                        reporting_id: tempContactDetails?.reporting_to ?? null,
                        Department: tempContactDetails?.department ? { label: tempContactDetails?.department, value: tempContactDetails?.department_id } : null,
                        otherRelationship: tempContactDetails?.other_relationship ?? null,
                        member: {
                              id: tempContactDetails?.reporting_to,
                              first_name: tempContactDetails?.first_name,
                              last_name: tempContactDetails?.last_name,
                              email_id: tempContactDetails?.email_id,
                              mobile_no: tempContactDetails?.mobile_no,
                              mobile_no_country_code: tempContactDetails?.mobile_no_country_code,
                              image_url: tempContactDetails?.image_url,

                        },
                        proofFile: tempContactDetails?.proof?.map(val => {
                              return {
                                    id: val?.id,
                                    type: val?.identification_masterByID,
                                    kyc_type: "Both",
                                    name: val?.name,
                                    id_no: val?.number,
                                    valid_from: new Date(val?.valid_from),
                                    valid_to: new Date(val?.valid_to),
                                    file: val?.url,
                                    mandatry_verify: val?.is_mandatory,
                                    issuing_country: val?.country,
                                    is_active: val?.is_active
                              }
                        }),
                        error: initial?.error,
                        sex: tempContactDetails?.sex_type ?
                              {
                                    value: tempContactDetails?.sex_type,
                                    label: tempContactDetails?.sex_type,
                              } : null,
                        whatsApp: tempContactDetails?.whatsapp ?? null,
                        employee_id: tempContactDetails?.employee_id ?? "",
                        vendorlist:{label:tempContactDetails?.vendor_name, value:tempContactDetails?.vendor_id}
                  })
                  setLoad(true)
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            }).catch((error) => {
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                  alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error,
                        msg: "Internal error. Please try again later.",
                  });
            });
      }

      const getContact = () => {
            if (state?.main?.isEdit) { getContactDetailsByID() } else { setLoad(true) }
      }
      //delete value
      const deletedValue = (value, updatedvalue) => {
            setData({ ...data, deletedProperties: [...data?.deletedProperties, value], properties: updatedvalue })
      }
      //remove img
      const removeimg = () => {
            setData({ ...data, profileImage: "" })
      }
      const ValidateProof = (object) => {
            let newObj = object?.filter((val) => val?.is_active)?.filter(val => val?.type !== undefined)
            if (newObj?.length > 0) {
                  for (let obj of newObj) {
                        for (let key in obj) {
                              if (!obj[key].toString()) return false
                        }
                  }
            }
            return true
      }
      //goNext
      const next = () => {
            if (value === 1) {
                  if (validate()) {
                        setValue(2)
                  } else {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Please fill all mandatory field",
                        });
                        return false
                  }
            }

      }
      //goPreviuos
      const previous = () => {
            setValue(prevValue => prevValue - 1);
            if (!state?.main?.isEdit) {
                  setMapLoad(false)
            }
      };
      const editTitle = {
            "Agent":"Edit Sales Agent",
            "Contact":"Edit Contact"
      }
      const createTitle = {
            "Agent":"Create New Sales Agent",
            "Contact":"Create New Contact"
      }
      const render = () => {
            return <>
                  <Subheader
                        title={state?.main?.isEdit ? editTitle?.[type] : createTitle?.[type]}
                        goBack={() => history.goBack(-1)}
                        previousAndNextBtn={true}
                        previousBtn={() => checkSave("previous")}
                        nextBtn={() => checkSave("next")}
                        previousBtnDisabled={value <= 1 && true}
                        nextBtnDisabled={value === 2 ? true : false}
                  />
                  <PropertyTabs value={value}
                        tab1={(
                              <Box className={classes.root2}>
                                    <Grid container spacing={2} >
                                          <Grid item xs={12}>
                                                <GeneralDetails id='pushTitle' data={data} updateState={updateState} updateimg={updateimg} mapResult={mapResultData} mapLoad={mapLoad} load={load} deletedValue={deletedValue} removeimg={removeimg} type={type} />
                                          </Grid>
                                          <Grid item xs={12}>
                                                <NationalIDDetails data={data} updateState={updateState} />
                                          </Grid>
                                          {/* <Grid item xs={12}>
                        <Assests data={data} updateState={updateState} />
                  </Grid> */}
                                          <Grid item xs={12}>
                                                <BankingDetails data={data} updateState={updateState} />
                                          </Grid>
                                    </Grid>
                              </Box>
                        )}
                        tab2={(
                              <Box className={classes.root2}>
                                    {/*proof*/}
                                    < ContactProof data={data?.proofFile?.filter((val) => val?.is_active)} update={(data) => updateState("proofFile", data)} />
                              </Box>
                        )}
                        isHeigth
                        tabTittle={tabTittle}
                        showBtns={true}
                        isSaveDisable={disable === true ? true : value === 2 ? false : true}
                        saveBtnText={state?.main?.isEdit ? "Update" : "Submit"}
                        goBack={() => history.goBack(-1)}
                        save={() => checkSave("save")}
                  />
            </>
      }
      return (
            <>
                  {accessCheckRender(render, permission)}
            </>
      )
}