import { Avatar, Box, Stack, Typography, Grid, Dialog, DialogContent, Icon } from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { AmenityDropdownStyle } from './style'

export const AmenityDropdown = (
    {
        // openAmenity = () => false,
        amenityCategoryList = [],
        selectedAmenityCategory = {},
        handleAmenityCategoryChange = () => false,
    }
) => {
    const classes = AmenityDropdownStyle({ listLength: amenityCategoryList?.length })
    const [open, setOpen] = React.useState(false)
    const showAll = () => setOpen(!open)
    const select = (e) => {
        handleAmenityCategoryChange(e)
        showAll()
    }

    return (
        <Box>
            <Box p={1} ml={1} className={classes.dropdown}>
                <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent="space-between"
                    onClick={() => selectedAmenityCategory?.label ? showAll() : false}>
                    <Stack direction={"row"} spacing={2}>
                        <Avatar className={classes.avatar}>{selectedAmenityCategory?.label ? selectedAmenityCategory?.label?.charAt(0) : "!"}</Avatar>
                        <Typography className={classes.value} noWrap>{selectedAmenityCategory?.label ?? "Not Available"}</Typography>
                    </Stack>
                    <span>
                        {
                            open ? <ExpandLessIcon /> : <ExpandMoreIcon />
                        }
                    </span>
                </Stack>
            </Box>


            <Dialog
                open={open}
                onClose={() => setOpen(!open)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.customDialog}
                fullWidth={true}
                maxWidth={500}
            >
                <DialogContent>
                    <Grid container>
                        {amenityCategoryList?.map((i) => {
                            return (
                                <Grid item md={4} onClick={() => select(i)}>
                                    <Stack spacing={1} direction={"row"} alignItems={"center"} justifyContent={"space-between"} className={selectedAmenityCategory?.value === i?.value ? classes.selected_card : classes.card}>
                                        <Stack direction="row" spacing={2} alignItems={"center"}>
                                            <Avatar>{i?.label ? i?.label?.charAt(0) : ""}</Avatar>
                                            <Typography className={classes.name} noWrap>{i?.label}</Typography>
                                        </Stack>
                                        {selectedAmenityCategory?.value === i?.value && <Icon color="primary">check_circle</Icon>}
                                    </Stack>
                                </Grid>
                            )
                        })}
                    </Grid>
                </DialogContent>
            </Dialog>
        </Box>
    )
}