import React  from "react";
import { useStyles } from "./styles";
import { Box, Typography } from "@mui/material";
import { Subheader, AccountListComponet } from "../../../components";
import { AddExistingAccount } from "../../../assets/addexisitingaccount";
import { CreateOppertunityContext } from "../../../contexts/createOppertunityContext";
export const AddExistAccount = () => {
  const classes = useStyles();


  const { selectedAccount, setSelectedAccount,show1, setShow1 } = React.useContext(
    CreateOppertunityContext
  );
  //onChoosing Account
  const chooseAccount = (val) => {
    setSelectedAccount(val);
    setShow1(true);
  };

  return (
    <>
      <Subheader hideBackButton title={"Add Existing Account"} />
      <Box className={classes.tabContant}>
        {/* seacrh box */}
        <AccountListComponet
          company_id={10}
          chooseAccount={chooseAccount}
          selectedAcc={selectedAccount}
        />
        {!show1 ? (
          <Box className={classes.center}>
            <Box textAlign={"center"}>
              <AddExistingAccount />
              <Typography className={classes.tabTitle}>
                Search and select existing account
              </Typography>
            </Box>
          </Box>
        ) : (
          // <SingleAccountCard data={SingleData} />
          ""
        )}
      </Box>
      {/* Existing Account */}
    </>
  );
};
