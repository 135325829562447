/* eslint-disable array-callback-return */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    CircularProgress
} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import * as React from 'react';
import { TableWithPagination } from '..';
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods, SemiBold } from "../../utils";
const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.typography.color.primary,
        fontFamily: SemiBold,
        fontSize: "16px"
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            backgroundColor: "white"
        },
        backgroundColor: theme.palette.background.tertiary,
        "&.MuiAccordion-root:last-of-type": {
            borderBottomRightRadius: "12px",
            borderBottomLeftRadius: "12px"
        },
        "&.MuiAccordion-root:first-of-type": {
            borderTopRightRadius: "12px",
            borderTopLeftRadius: "12px"
        },
        "&.Mui-expanded": {
            backgroundColor: "white",
            border: "1px solid #CED3DD",
        }
    }
}))
export const AccordionWithTable = (props) => {
    const classes = useStyles(props);
    const [loading, setLoading] = React.useState(true);
    const [offset] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [count, setCount] = React.useState("");
    const [expand, setExpand] = React.useState(false);
    const toggleAcordion = () => {
        setExpand(!expand);
    };
    const [list, setList] = React.useState([]);
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getTable(offset, limit);
    };

    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getTable(0, value);
    };

    const getTable = (offset, limit) => {
        if (expand === true) {

            //query
            if (props?.data?.query) {
                const payload = {
                    query: props?.data?.query,
                    variables: {
                        unitId: props?.data?.unitId && props?.data?.unitId,
                        agreementId: props?.data?.agreementId && props?.data?.agreementId,
                        offset: offset,
                        limit: limit
                    },

                };
                NetworkCall(
                    `${config.graphql_url}`,
                    NetWorkCallMethods.post,
                    payload,
                    null,
                    true,
                    false
                )
                    .then((response) => {
                        let main = response?.data?.data;

                        const aData = [];

                        if (main?.unit_rental_breakup) {
                            main?.unit_rental_breakup.map(data => {
                                const aObj = {}
                                aObj.component = data?.rental_breakup_master?.description ? data?.rental_breakup_master?.description : " - "
                                aObj.value = data?.value ? data?.value : " - "
                                aObj.currency = data?.currency?.symbol ? data?.currency?.symbol : " - "
                                aObj.valueType = data?.value_basis_type ? data?.value_basis_type : " - "
                                aObj.display = data?.display_percentage ? data?.display_percentage : " - "
                                aObj.taxGroup = data?.vat_group_master?.group_name ? data?.vat_group_master?.group_name : " - "
                                aObj.taxGroup2 = data?.period_type ? data?.period_type : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.unit_vacancy_period) {
                            main?.unit_vacancy_period.map(data => {
                                const aObj = {}
                                aObj.availableFrom = moment(data?.available_from).format("DD MMM YY") ? moment(data?.available_from).format("DD MMM YY") : " - "
                                aObj.availableTo = moment(data?.available_to).format("DD MMM YY") ? moment(data?.available_to).format("DD MMM YY") : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.unit_amenities_breakup) {
                            main?.unit_amenities_breakup.map(data => {
                                const aObj = {}
                                aObj.amenitiesType = data?.amenities_type ? data?.amenities_type : " - "
                                aObj.name = data?.name ? data?.name : " - "
                                aObj.notes = data?.notes ? data?.notes : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.unit_utilties) {
                            main?.unit_utilties.map(data => {
                                const aObj = {}
                                aObj.utilites = data?.utilites ? data?.utilites : " - "
                                aObj.meterSerialNo = data?.meter_serial_no ? data?.meter_serial_no : " - "
                                aObj.meterPointReference = data?.meter_point_reference ? data?.meter_point_reference : " - "
                                aObj.updatedDate = moment(data?.updated_at).format("DD MMM YY") ? moment(data?.updated_at).format("DD MMM YY") : " -"
                                aObj.description = data?.description ? data?.description : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.unit) {
                            main?.unit.map(data => {
                                const aObj = {}
                                aObj.unitName = data?.name ? data?.name : " - "
                                aObj.parentName = data?.parent_unit[0]?.name ? data?.parent_unit[0]?.name : " - "
                                aObj.unitType = data?.unit_type_master?.type ? data?.unit_type_master?.type : " - "
                                aObj.carpetArea = `${data?.carpet_area}${","}${data?.area_metric}` ? `${data?.carpet_area}${","}${data?.area_metric}` : " - "
                                aObj.furnishType = data?.furnishing_master?.type ? data?.furnishing_master?.type : " - "
                                aObj.unitPurpose = data?.unit_purpose ? data?.unit_purpose : " - "
                                aObj.currentStatus = data?.unit_status_master?.type ? data?.unit_status_master?.type : " - "
                                aObj.status = data?.is_active ? "Active" : "Inactive"
                                aData.push(aObj)
                            })
                        }
                        if (main?.security_request) {
                            main?.security_request.map((data, index) => {
                                const aObj = {}
                                aObj.sno = (offset ?? 0) + index + 1
                                aObj.index = offset + index + 1
                                aObj.requestId = data?.request_no ? data?.request_no : ' - '
                                aObj.requestName = data?.name ? data?.name : " - "
                                aObj.category = data?.request_type ? data?.request_type : " - "
                                aObj.raisedDate = moment(data?.raised_no).format("DD MMM YY") ? moment(data?.raised_no).format("DD MMM YY") : " - "
                                aObj.status = data?.status ? data?.status : " - "
                                aObj.description = data?.description ? data?.description : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.owner_customer?.[0]?.user_profile) {
                            main?.owner_customer.map((data, index) => {
                                const aObj = {}
                                aObj.ownerName = `${data?.user_profile?.first_name} ${data?.user_profile?.last_name ? data?.user_profile?.last_name : " "}` ? `${data?.user_profile?.first_name} ${data?.user_profile?.last_name ? data?.user_profile?.last_name : " "}` : " -"
                                aObj.periodFrom = moment(data?.from).format("DD MMM YY") ? moment(data?.from).format("DD MMM YY") : " - "
                                aObj.periodTo = moment(data?.to).format("DD MMM YY") ? moment(data?.to).format("DD MMM YY") : " - "
                                aObj.contact = data?.user_profile?.mobile_no ? data?.user_profile?.mobile_no : " - "
                                aObj.emailAddress = data?.user_profile?.email_id ? data?.user_profile?.email_id : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.agreement_units) {
                            const unique = [...new Map(main?.agreement_units.map(item => [item.agreement['id'], item])).values()];
                            unique.map((data, index) => {
                                const aObj = {}
                                aObj.sno = (offset ?? 0) + index + 1
                                aObj.index = offset + index + 1
                                aObj.agreementNo = data?.agreement?.agreement_no ? data?.agreement?.agreement_no : " - "
                                aObj.agreementPrimaryContactName = `${data?.agreement?.contact?.first_name} ${data?.agreement?.contact?.last_name ? data?.agreement?.contact?.last_name : " "}` ? `${data?.agreement?.contact?.first_name} ${data?.agreement?.contact?.last_name ? data?.agreement?.contact?.last_name : " "}` : " - "
                                aObj.contact = data?.agreement?.contact?.mobile_no ? data?.agreement?.contact?.mobile_no : " - "
                                aObj.emailAddress = data?.agreement?.contact?.email_id ? data?.agreement?.contact?.email_id : " - "
                                aObj.icon = "view"
                                aData.push(aObj)
                            })
                        }
                        if (main?.invoice_units && props?.data?.title === "Unit") {
                            main?.invoice_units.map((data, index) => {
                                const aObj = {}
                                aObj.sno = (offset ?? 0) + index + 1
                                aObj.index = offset + index + 1
                                aObj.unitID = data?.unit?.unit_no ?? " - "
                                aObj.tenantName = data?.tenant_profileByID?.first_name ?? " - "
                                aObj.primaryContact = data?.tenant_profileByID?.mobile_no_country_code + " " + data?.tenant_profileByID?.mobile_no ?? " - "
                                aObj.emailAddress = data?.tenant_profileByID?.email_id ?? " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.invoice_units && props?.data?.title === "Unit Invoices") {
                            main?.invoice_units.map((data, index) => {
                                const aObj = {}
                                aObj.sno = (offset ?? 0) + index + 1
                                aObj.index = offset + index + 1
                                aObj.invoiceId = data?.invoice?.invoice_no ? data?.invoice?.invoice_no : " - "
                                aObj.invoiceType = data?.invoice?.invoice_master?.type ? data?.invoice?.invoice_master?.type : " - "
                                aObj.invoiceTotal = data?.invoice?.invoice_total_amount ? data?.invoice?.invoice_total_amount : " - "
                                aObj.invoiceStatus = data?.invoice?.is_active ? "Active" : "Inactive"
                                aObj.icon = "view"
                                aData.push(aObj)
                            })
                        }
                        if (main?.owner_customer?.[0]?.tenant_profileByID) {
                            main?.owner_customer.map((data, index) => {
                                const aObj = {}
                                aObj.sno = (offset ?? 0) + index + 1
                                aObj.index = offset + index + 1
                                aObj.name = `${data?.tenant_profileByID?.first_name} ${data?.tenant_profileByID?.last_name ? data?.tenant_profileByID?.last_name : " "}` ? `${data?.tenant_profileByID?.first_name} ${data?.tenant_profileByID?.last_name ? data?.tenant_profileByID?.last_name : " "}` : " -"
                                aObj.contactNumber = data?.tenant_profileByID?.mobile_no ? data?.tenant_profileByID?.mobile_no_country_code + " - " + data?.tenant_profileByID?.mobile_no : " - "
                                aObj.email = data?.tenant_profileByID?.email_id ? data?.tenant_profileByID?.email_id : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.agreement) {
                            main?.agreement.map((data, index) => {
                                const aObj = {}
                                aObj.sno = (offset ?? 0) + index + 1
                                aObj.index = offset + index + 1
                                aObj.accountName = data?.account?.name ? data?.account?.name : " - "
                                aObj.accountNumber = data?.account?.account_no ? data?.account?.account_no : " - "
                                aObj.companyName = data?.company?.name ? data?.company?.name : " - "
                                aData.push(aObj)
                            })
                        }
                        setList(aData)
                        setLoading(false)
                        setCount(main?.count?.[0]?.count)

                    })
                    .catch((error) => {
                        console.log(error)
                        //             alert.setSnack({
                        // ...alert,
                        // open: true,
                        // severity: AlertProps.severity.error,
                        // msg: "Please fill all mandatory field",
                        // vertical: AlertProps.vertical.top,
                        // horizontal: AlertProps.horizontal.center,
                        //             });
                    });
            }

            //api
            if (props?.data?.api) {
                const payload = {
                    tenantId: `${config.tenantId}`,
                    unitId: props?.data?.unitId,
                    offset: offset,
                    limit: limit
                };
                NetworkCall(
                    `${config.api_url}${props?.data?.api}`,
                    NetWorkCallMethods.post,
                    payload,
                    null,
                    true,
                    false
                )
                    .then((response) => {
                        let main = response?.data?.data;

                        const aData = [];
                        if (main) {
                            main.map(data => {
                                const aObj = {}
                                aObj.requestId = data?.request_no
                                aObj.requestName = data?.request_name
                                aObj.raisedDate = moment(data?.raised_on).format("DD MMM YY")
                                aObj.category = data?.category
                                aObj.description = data?.description
                                aObj.status = data?.invoice?.is_active ? "Active" : "Inactive"
                                aData.push(aObj)
                            })
                        }
                        setList(aData)
                        setLoading(false)

                    })
                    .catch((error) => {
                        //             alert.setSnack({
                        // ...alert,
                        // open: true,
                        // severity: AlertProps.severity.error,
                        // msg: "Please fill all mandatory field",
                        // vertical: AlertProps.vertical.top,
                        // horizontal: AlertProps.horizontal.center,
                        //  });
                        console.log(error)
                    });

            }
        }
    }
    React.useEffect(() => {
        getTable(offset, limit)
        // eslint-disable-next-line
    }, [expand, offset, limit])
    return (
        <div>
            <Accordion className={classes.accordion} elevation={0} expanded={expand} onChange={toggleAcordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#091B29" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.text}>{props?.data?.title}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: "white" }}>
                    {loading ? (
                        <CircularProgress color="secondary" />
                    ) : (
                        <>
                            {list?.length > 0 ?
                                <TableWithPagination
                                    heading={list?.length > 0 ? props?.data?.table?.heading : []}
                                    rows={list}
                                    path={list?.length > 0 ? props?.data?.table?.path : []}
                                    showpagination={true}
                                    onClick={() => false}
                                    dataType={props?.data?.table?.dataType}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={count}
                                    page={page}
                                    limit={limit}
                                    accordian={true}
                                    height={'calc(100vh - 351px)'}
                                    view={true}
                                    edit={true}
                                    delete={true} />
                                :
                                <Typography color={"error"} textAlign="center">
                                    {"No Records Found"}
                                </Typography>
                            }
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
