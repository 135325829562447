import { Box, Button, Divider, Grid, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import { withNamespaces } from "react-i18next"
import ChooseResourceIcon from "../../assets/chooseResource"
import OtherResourcesIcon from "../../assets/otherResourcesIcon"
import { config } from "../../config"
import { GET_DEPARTMENT_LIST, GET_RESOURCE_LIST, GET_Role_LIST } from "../../graphql/moveInAssign"
import { NetworkCall } from "../../networkcall"
import { NetWorkCallMethods, useWindowDimensions } from "../../utils"
import { loadOptions } from "../../utils/asyncPaginateLoadOptions"
import { CustomSelect } from "../filterGenerator/components"
import { PopupCard } from "./card"
import { ResourceDropDown } from "./resourceDropdown"
import { addOtherResourcesStyles } from "./styles"


const AddOtherResourcesAssign = (props) => {
    const size=useWindowDimensions()
    const { t } = props
    const classes = addOtherResourcesStyles()
    const [open, setOpen] = React.useState(false);
    const [list, setList] = React.useState([]);


     const getHour=(date)=>{
        const dates=new Date(date);
        const hour=dates.getHours();
        const mins=dates.getMinutes();
        const result=`${hour < 10 ?`0${hour}`:hour}:${mins<10?`0${mins}`:mins}`
        return result
    }
    

    const getOtherResources = () => {

        const payload = {
            "module_id": parseInt(props?.assignData?.moduleId),
            "company_id": props?.assignData?.company?.value,
            "start_date_time": moment(props?.assignData?.dateTime).format("YYYY-MM-DDTHH:MM:SS"),
            "hours": parseInt(props?.assignData?.hours),
            "resource_type_name": props?.assignData?.resource_type?.label,
            "department_id": props?.assignData?.department?.value,
            "job_id": props?.assignData?.role?.value,
            // "search": search,
            "parent_resource_id": props?.assignData?.resource?.id,
            hours_time:getHour(props?.assignData?.dateTime)

        }
        NetworkCall(
            `${config.api_url}/agreement_inspection/get_resources`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            const data = res?.data?.data?.filter(i => i?.resource_id !== props?.assignData?.resource?.resource_id);
            const final_data=data?.map((val)=>{
                return{
                    ...val,
                    value:val?.resource_id,
                    labal:val?.name
                }
            })
            setList(final_data)
        }).catch((err) => {
            console.log(err)
        })
    }

    React.useEffect(() => {

        if (props?.assignData?.resource?.resource_id && props?.assignData?.hours?.length > 0) {
            getOtherResources()
        }

        // eslint-disable-next-line
    }, [props?.assignData])


    return (
        <Box>
            {(open || props?.assignData?.otherResources?.length > 0) ?
                <Box m={2}>
                    <Box display={"flex"} onClick={() => props?.handleChange("otherResources", false)} className={classes.otherres}>
                        <ChooseResourceIcon className={classes.icon} />
                        <Box ml={2}>
                            <Typography className={classes.otherTitle}>{t("chooseOptionalResource")}</Typography>
                            <Typography className={classes.otherSubText}>{t("otherResources")}</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box className={classes.searchBlock}>
                            {/* <Stack
                                direction="row"
                                divider={<Divider orientation="vertical" flexItem />}
                                spacing={1}
                            > */}
                            <Grid container>
                                <Grid item xs={12} md={12} borderBottom={props?.assignData?.resource_type?.label === "Users" ? "1px solid #E4E8EE" : "auto"}>
                                    <Typography className={classes.selecttitle}>{t("resourceType")}</Typography>
                                    <CustomSelect
                                        loadOptions={(search, array, handleLoading) => loadOptions(search, array, handleLoading, GET_RESOURCE_LIST, "resource_type_master", { company_id: props?.assignData?.company?.value, all: true })}
                                        isPaginate={true}
                                        borderRadius={false}
                                        noBorder={true}
                                        label={""}
                                        value={props?.assignData?.resource_type}
                                        onChange={(value) => {
                                            props?.handleChange("resource_type", value, false)
                                        }} />
                                </Grid>
                                {props?.assignData?.resource_type?.label === "Users" &&
                                    <>
                                        <Grid item xs={6} borderRight="1px solid #E4E8EE">
                                            <Typography className={classes.selecttitle}>{t("department")}</Typography>
                                            <CustomSelect
                                                loadOptions={(search, array, handleLoading) => loadOptions(search, array, handleLoading, GET_DEPARTMENT_LIST, "department_master", { company_id: props?.assignData?.company?.value, all: true })}
                                                isPaginate={true}
                                                borderRadius={false}
                                                noBorder={true}
                                                isReadOnly={props?.isReadOnly}
                                                placeholder={"All"}
                                                label={""}
                                                value={props?.assignData?.department}
                                                onChange={(value) => {
                                                    props?.handleChange("department", value, false)
                                                }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.selecttitle}>{t("role")}</Typography>
                                            <CustomSelect
                                                loadOptions={(search, array, handleLoading) => loadOptions(search, array, handleLoading, GET_Role_LIST, "job_master", { company_id: props?.assignData?.company?.value, all: true })}
                                                isPaginate={true}
                                                borderRadius={false}
                                                noBorder={true}
                                                isReadOnly={props?.isReadOnly}
                                                placeholder={"All"}
                                                label={""}
                                                value={props?.assignData?.role}
                                                onChange={(value) => {
                                                    props?.handleChange("role", value, true)
                                                }} />
                                        </Grid>

                                    </>
                                }
                            </Grid>
                            {/* </Stack> */}
                            <Divider className={classes.dividerstyle1} />
                            <Box padding="8px 0px" >
                                <ResourceDropDown
                                    options={list}
                                    placeholder={t("search_resource")}
                                    // onChange={(value) => props?.handleChange("otherResources", [...props?.assignData?.otherResources,value])}
                                    onChange={(value) => props?.handleChange("otherResources", value)}
                                    value={props?.assignData?.otherResources}
                                    color={true}
                                    borderRadius={false}
                                    noBorder={true}
                                    isMulti={true}
                                    isPaginate={false}
                                    resource={true}
                                    key={JSON.stringify(props?.assignData)}

                                />
                            </Box>
                        </Box>
                    </Box>

                    {
                        props?.assignData?.otherResources?.length > 0 &&
                        props?.assignData?.otherResources?.map((item) => (
                            <PopupCard
                                Resources={true}
                                list={item}
                                hours={props?.assignData?.hours}
                                deleteSvg={true}
                                removeData={props?.removeData}
                                selected={true}
                            />
                        ))

                    }
                </Box>
                :
                <Box className={classes.otherresources}>
                    <Box height={size?.height-190} justifyContent="center" display={"flex"} alignItems="center">
                        <center>
                            <OtherResourcesIcon />
                            <Typography className={classes.otherTitle}>{t("chooseOptionalResource")}</Typography>
                            <Typography className={classes.otherSubText}>{t("addOptionalResource")}</Typography>
                            <Button variant="outlined" onClick={() => setOpen(true)} className={classes.addresourcebtn}>{t("addResource")}</Button>
                        </center>
                    </Box>
                </Box>
            }

        </Box>
    )
}
export default withNamespaces("assignModal")(AddOtherResourcesAssign)