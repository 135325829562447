import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold } from "../../../utils";

export const CountCardStyle = makeStyles((theme)=>({
    box:{
        background:"#fff",
        boxShadow:"0px 0px 16px #00000014",
        height:94,
        width:"100%"
    },
    title:{
        fontSize:24,
        fontWeight:Bold
    },
    content:{
        fontSize:14,
        fontWeight:SemiBold
    },
    uTitle:{
        fontSize:10,
        color:theme.typography.color.tertiary,
        fontWeight:Bold
    },
    uSubtitle1:{
        fontSize:14,
        fontWeight:SemiBold,
        color:theme.typography.color.primary,
    },
    uSubtitle2:{
        fontSize:14,
        fontWeight:Regular,
        color:theme.typography.color.tertiary
    },
    avatar:{
        height:40,
        width:40,
        borderRadius:4
    }
}))