import { Box, Popover, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";
import React, { useState } from "react";
import {
  DatePickerNew,
  NewDatePicker,
  Label,
  TextBox,
} from "../../components";
import { DateRangePicker } from "materialui-daterange-picker";
import {
  CustomSelect,
  MinMaxTextFieldWithSelect,
  ToggleButton,
} from "./components";
import { useWindowDimensions, timeZoneConverter, SemiBold } from "../../utils";

const Div = styled("div")(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  overflow: "auto",
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
  marginBlock: theme.spacing(1),
  borderColor: "#E4E8EE",
  width: "100%",
}));

export const Body = (props) => {
  const {
    data = {},
    error = {},
    components = [],
    onChangeState = null,
  } = props;

  const size = useWindowDimensions();

  const [loading, setLoading] = useState(null);
  const [calenderOpen, setCalendarOpen] = React.useState(false);
  const openClosePopOver = () => {
    setCalendarOpen(!calenderOpen);
  };

  const switchComponents = (val) => {
    switch (val.component) {
      case "select":
        const loadOptions = (search, prevArray, val) => {
          setLoading(val?.state_name);
          return val?.loadOptions(search, prevArray, setLoading, data);
        };

        return (
          <CustomSelect
            label={val?.label ?? ""}
            options={val?.options?.length ? val?.options : []}
            isMulti={val?.isMulti}
            value={data?.[val?.state_name] ?? null}
            onChange={(value) => onChangeState(val?.state_name, value, val)}
            error={error?.[val?.state_name] ?? false}
            errorText={"This field is required"}
            required={val?.required ?? false}
            isPaginate={val?.isPaginate}
            debounceTimeout={val?.debounceTimeout}
            loadOptions={(search, prevArray) =>
              loadOptions(search, prevArray, val)
            }
            loading={loading === val?.state_name}
            labelSize={val?.labelSize ?? ""}
            labelColor={val?.labelColor ?? ""}
            fontFamily={val?.fontFamily}
          />
        );
      case "toggleButton":
        return (
          <ToggleButton
            options={val?.options?.length ? val?.options : []}
            value={data?.[val?.state_name]}
            onChange={(value) => onChangeState(val?.state_name, value, val)}
            isMulti={val?.isMulti}
            fullWidth={val?.fullWidth}
            buttonStyle={{
              borderColor: "#E4E8EE",
              borderRadius: 1,
              backgroundColor: "#fff",
              ...val?.buttonStyle,
            }}
            buttonGroupStyle={{ ...val?.buttonGroupStyle }}
            label={val?.label ?? ""}
            error={error?.[val?.state_name] ?? false}
            errorText={"This field is required"}
            required={val?.required ?? false}
            labelColor={val?.labelColor ?? ""}
            labelSize={val?.labelSize ?? ""}
            fontFamily={val?.fontFamily ?? ""}
          />
        );
      case "minMaxTextFieldWithSelect":
        return (
          <MinMaxTextFieldWithSelect
            value={data?.[val?.state_name] ?? null}
            onChange={(value) => onChangeState(val?.state_name, value, val)}
            label={val?.label ?? ""}
            error={error?.[val?.state_name] ?? false}
            errorText={"This field is required"}
            required={val?.required ?? false}
            placeholders={val?.placeholders ?? null}
            endAdornmentLabels={val?.endAdornmentLabels ?? null}
            options={val?.options}
            select={val?.select}
          />
        );
      case "minAndmaxDate":
        return (
          <>
            <Label label={val?.minMaxlabel} isRequired={val?.required} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <NewDatePicker
                  isRequired={val?.isRequired}
                  disableFuture={val?.disableFuture}
                  disablePast={val?.disablePast}
                  minDate={val?.minDate}
                  maxDate={val?.maxDate}
                  label={val?.label?.startLabel}
                  placeholder={val?.placeholder?.startLabel}
                  value={data?.[val?.state_name]?.["startDate"]}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        startDate: value,
                      },
                      val
                    )
                  }
                  labelColor={val?.labelColor ?? ""}
                  labelSize={val?.labelSize ?? ""}
                  fontFamily={val?.fontFamily ?? ""}
                />
              </Grid>
              <Grid item xs={6}>
                <NewDatePicker
                  isRequired={val?.isRequired}
                  disableFuture={val?.disableFuture}
                  disablePast={val?.disablePast}
                  minDate={val?.minDate}
                  maxDate={val?.maxDate}
                  label={val?.label?.endLabel}
                  placeholder={val?.placeholder?.endLabel}
                  value={data?.[val?.state_name]?.["endDate"]}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        endDate: value,
                      },
                      val
                    )
                  }
                  labelColor={val?.labelColor ?? ""}
                  labelSize={val?.labelSize ?? ""}
                  fontFamily={val?.fontFamily ?? ""}
                />
              </Grid>
            </Grid>
          </>
        );
      case "date":
        return (
          <>
            <Label style={{ fontFamily: SemiBold, fontSize: "1rem", marginBottom: "6px" }} label={val?.label} isRequired={val?.required} />
            <DatePickerNew
              isRequired={val?.isRequired}
              disableFuture={val?.disableFuture}
              disablePast={val?.disablePast}
              minDate={val?.minDate}
              maxDate={val?.maxDate}
              label={val?.label?.startLabel}
              placeholder={val?.placeholder}
              value={data?.[val?.state_name] ?? null}
              handleChange={(value) =>
                onChangeState(val?.state_name, value, val)
              }
            />
          </>
        );
      case "date_range":
        return (
          <Grid container>
            <Label label={val?.label} isRequired={val?.required} />
            <Grid container spacing={"16px"}>
              <Grid item xs={6}>
                <Label label={"From"} textAlign={"right"} />
                <Box
                  style={{
                    border: "1px solid #e4e8ee",
                    borderRadius: "4px",
                    height: "45px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                  onClick={openClosePopOver}
                >
                  <Typography style={{ fontSize: "16px", color: "#333333" }}>
                    {timeZoneConverter(data?.[val?.state_name]?.startDate) ?? "From"}
                  </Typography>
                  <img src={"/images/icons8-calendar (5).svg"} alt="" />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Label label={"Till"} textAlign={"right"} />
                <Box
                  style={{
                    border: "1px solid #e4e8ee",
                    borderRadius: "4px",
                    height: "45px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                  onClick={openClosePopOver}
                >
                  <Typography style={{ fontSize: "16px", color: "#333333" }}>
                    {timeZoneConverter(data?.[val?.state_name]?.endDate) ?? "Till"}
                  </Typography>
                  <img src={"/images/icons8-calendar (5).svg"} alt="" />
                </Box>
              </Grid>
            </Grid>
            <Popover
              open={calenderOpen}
              onClose={openClosePopOver}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 250, left: size?.width }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <DateRangePicker
                open={calenderOpen}
                toggle={openClosePopOver}
                onChange={(range) => {
                  onChangeState(val?.state_name, range, val);
                  openClosePopOver();
                }}
                initialDateRange={data?.[val?.state_name]}
                definedRanges={[]}
              />
            </Popover>
          </Grid>
        );
      case "amount_minAndmax":
        return (
          <Grid container>
            <Label label={val?.label} isRequired={val?.required} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextBox
                  label="From"
                  type={"number"}
                  value={data?.[val?.state_name]?.["fromAmount"]}
                  endAdornment={val?.amount_symbol}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        fromAmount: value.target.value,
                      },
                      val
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextBox
                  label="To"
                  type={"number"}
                  value={data?.[val?.state_name]?.["toAmount"]}
                  endAdornment={val?.amount_symbol}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        toAmount: value.target.value,
                      },
                      val
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        );
      case "select_box":
        return (
          <>
            {val?.is_active && (
              <CustomSelect
                label={val?.label ?? ""}
                options={val?.options?.length ? val?.options : []}
                isMulti={val?.isMulti}
                value={val.value}
                onChange={val?.onChange}
                error={val?.error?.length > 0}
                errorText={val?.error}
                required={val?.isReadonly ?? false}
                isPaginate={val?.isPaginate}
                loadOptions={val?.loadOptions}
                loading={val?.loading}
                debounceTimeout={800}
                key={val?.key}
                labelSize={val?.labelSize ?? ""}
                labelColor={val?.labelColor ?? ""}
                fontFamily={val?.fontFamily}
              />
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Div>
      {
        <Grid container>
          {components?.length &&
            components.map((_) => {
              return (
                <>
                  <Grid item xs={12}>
                    {switchComponents(_)}
                  </Grid>
                  {
                    _?.is_divider ? "" : <CustomDivider />
                  }
                </>
              )
            })}
        </Grid>
      }
    </Div>
  );
};
