import { Box, Stack, Typography } from "@mui/material"
import React, { useState } from "react"
import { withNamespaces } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import { DialogBox, LoadingSection, Subheader, UseDebounce } from "../../components"
import AssignModal from "../../components/assignModal/reAssignModal"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { Routes } from "../../router/routes"
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from "../../utils"
import InspectionDetails from "./components/cards"
import InspectionTable from "./components/table"
import { InspectionStyles } from "./styles"
const InspectionList = ({ t }) => {
    const { state } = useLocation();
    const classes = InspectionStyles()
    const history = useHistory()
    const [assignInspector, setAssignInspector] = useState({
        bool: false,
        data: null
    });
    const [page, setPage] = useState(1)
    const [permissions, setPermission] = React.useState({})
    const [limit, setLimit] = useState(10)
    const [tableData, setTableData] = useState([])
    const [totalRows, setTotalRows] = useState([])
    const [searchText, setSearchText] = React.useState("")
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [details, setDetails] = useState([])
    const debounce = UseDebounce()
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext)
    const auth = React.useContext(AuthContext)
    const [loading, setLoading] = React.useState(true)

    // handleAdd function
    const AssignInspector = async (type, data) => {
        const routeType=state.type==="move-in"?Routes?.moveInInspectionItemDetails:state.type==="move-out"?Routes?.moveOutInspectionItemDetails:Routes?.unitHandOverInspectionItemDetails
        if (type === "view") {
            // if(data.status !== "Yet to assign"){
            history.push({
                pathname: routeType,
                state: {
                    id: state.id,
                    agreement_inspection_id: data.id,
                    title: data.reference_id,
                    status: data.status,
                    type: state.type,
                    agreement_id: state?.agreement_id,
                    data: data
                }
            })
        // }

        } else {
            setAssignInspector({
                bool: true,
                data: data?.id
            })
        }
    }
    //goback
    const goBack = () => {
        history.goBack()
    }
    //get tableData 
    const getAllInspectionList = (company_id, offset = 0, limit = 10, searchText) => {
        // setLoading(true)
        const payload = {
            id: state.id,
            offset: offset,
            limit: limit,
            search: searchText,
            type: state.type
        }

        NetworkCall(
            `${config.api_url}/agreement_inspection/get_all_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setDetails(response?.data?.data?.detail)
                const result = response?.data?.data?.list?.data?.map((val) => {
                    return {
                        ...val,
                        unit_reference_id: `${val?.unit_reference_id}`,
                        other_hours:val?.other_hours,
                        KPI:val?.overbooking_count>0?"Delayed":val?.ontime_count>0?"Ontime":"-",
                        type:val?.is_parking_component?"Parking Component":val?.is_parking_unit?"Parking Unit":"Residential",
                        parking: (val?.is_parking_unit || val?.is_parking_component) ? true : false,
                        actual_hours:val?.status==="Completed"?(val?.actual_hours>0?val.actual_hours:"< 1 hr"):"-"
                    }
                })
                setTableData(result)
                setTotalRows(response?.data?.data?.list?.count)
                setLoading(false)

            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "data not found",
                });
                setLoading(false)

            })
    }

    //initial load
    React.useEffect(() => {
        if (auth) {
            let company = getCompanyOption(backdrop, auth, alert)
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                setPermission(perm)
                if (company && perm?.read) {
                    setSelectedCompany(company?.selected)
                    getAllInspectionList(company?.selected?.value, 0, limit, "")
                }
            }
        }
        // eslint-disable-next-line
    }, [auth])
    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getAllInspectionList(selectedCompany?.value, 0, 10, e)
    }

    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getAllInspectionList(selectedCompany?.value, offset, limit, "")
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getAllInspectionList(selectedCompany?.value, 0, limit, "")
    }
    //for assigning
    const handleAssign = (payload) => {
        NetworkCall(
            `${config.api_url}/agreement_inspection/assign_resource`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Assigned Successfully",
                });
                getAllInspectionList(selectedCompany?.value, 0, limit, "")
                setAssignInspector({
                    bool: false,
                    data: null
                })
                handleClose()
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "data not found",
                });
            })
    }
    //handleClose Assign Modal
    const handleClose = () => {
        setAssignInspector({
            bool: !assignInspector?.bool,
            data: null
        })
    }

    const dialogHeader = () =>{
        return(
            <Stack direction="row" spacing={1}>
                <Box onClick={handleClose} className={classes.iconbtn} display="flex" alignItems="center">
                        <ArrowBackIosIcon className={classes.icon} htmlColor="black" />
                    </Box>
                <Typography className={classes.dialoghdrtext}>{t("assignInspector")}</Typography>
            </Stack>
        )
    }
    const render = () => {
        return (
            <Box>
                <Subheader goBack={goBack}
                    title={state.title}
                />
                <Box className={classes.content}>

                    {/* cards */}
                    {
                        loading ?
                            <LoadingSection top="20vh" message={"Fetching Details"} />
                            :
                            <>
                                <Box className={`${classes.inspection}`}>
                                    <InspectionDetails details={details} />


                                    <Box className={`${classes.inspectionTable}`}>
                                        <InspectionTable
                                            handleIcon={AssignInspector}
                                            handlePagination={handlePagination}
                                            handleChangeLimit={handleChangeLimit}
                                            tableData={tableData}
                                            totalRows={totalRows}
                                            page={page}
                                            limit={limit}
                                            searchText={searchText}
                                            handleSearch={handleSearch}
                                            height={`calc(100vh - 330px)`}
                                        />
                                    </Box>
                                </Box>
                            </>
                    }
                    <DialogBox open={assignInspector?.bool}
                        onClose={() => handleClose()}
                        header={dialogHeader()}
                        maxWidth="md"
                        fullScreen
                        component={
                            <AssignModal
                                agreement_inspection_id={assignInspector?.data}
                                moduleName={t("moveInInspectionRequest")}
                                handleClose={handleClose}
                                onSubmit={handleAssign}
                                buttonName={"assign"}
                            />}
                    />
                </Box>
            </Box>
        )
    }
    return (
        <Box>
            {accessCheckRender(render, permissions)}
        </Box>

    )
}
export default withNamespaces("inspection")(InspectionList);
