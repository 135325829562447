import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    cardblock: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        padding: "12px",
        marginBottom: "10px",
        display: "flex",
        justifyContent: "space-between",
        cursor:'pointer'
    },
    selcardblock: {
        border: "1px solid #5078E1",
        borderRadius: theme.palette.borderRadius,
        padding: "12px",
        marginBottom: "10px",
        display: "flex",
        cursor:'pointer',
        justifyContent: "space-between",
        backgroundColor: "#F1F7FF"
    },
    name: {
        fontSize: "14px",
        color: theme.typography.color.primary,
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    },
    location: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    },
    address: {
        fontSize: "12px",
        color: theme.typography.color.tertiary,
        fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
    },
    checkIcon: {
        marginTop: "6px",
        color: "#5078E1",
        cursor:"pointer"
    },
    unitblock: {
        borderRadius: theme.palette.borderRadius,
        padding: "12px",
        marginBottom: "10px",
        display: "flex",
        justifyContent: "space-between"
    },
    templateIcon: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#F9E0E0",
        width: "40px",
        height: "40px",
        textAlign: "center",
        paddingTop: "3px"
    },
    listText: {
        fontSize: "12px",
        color: theme.typography.color.tertiary,
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        textTransform: "capitalize"
    },
    listsubText: {
        fontSize: "14px",
        color: theme.typography.color.secondary,
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    },
    inspectionName: {
        fontSize: "14px",
        color: theme.typography.color.secondary,
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        marginTop: "3px"
    },
    divStyle: {
        border: "1px solid #E4E8EE"
    },
    previewHdr: {
        fontSize: "12px",
        color: theme.typography.color.tertiary,
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        textTransform: "uppercase"
    },
    previousbtn: {
        backgroundColor: "white",
        border: "1px solid #E4E8EE",
        color: theme.typography.color.primary,
        "&:hover": {
            backgroundColor: "white",
            border: "1px solid #E4E8EE",
        }
    },
    reqCardBlock: {
        backgroundColor: theme.palette.background.tertiary1,
        border: `1px solid ${theme.palette.border.secondary}`,
        // boxShadow: "0px 0px 16px #00000014",
        borderRadius: theme.palette.borderRadius,
        padding: "8px 12px 8px 12px"
    },
    title: {
        color: theme.typography.color.tertiary,
        fontSize: "12px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        // letterSpacing: "0.24px",
        textTransform: "capitalize"
    },
    viewMore: {
        color: theme.typography.status.primary,
        fontSize: "10px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        textTransform: "capitalize",
        // letterSpacing: "0.24px",
        cursor: "pointer"
    },
    Name: {
        color: theme.typography.color.primary,
        fontSize: "14px",
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    },
    subTitle: {
        color: theme.typography.color.tertiary,
        fontSize: "12px",
        fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
    },
    description: {
        color: theme.typography.color.tertiary,
        fontSize: "12px",
        fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        // overflow: "hidden",
        // width: "90%",
    },
    status: {
        backgroundColor: theme.palette.warning.light,
        borderRadius: theme.palette.borderRadius,
        border: `1px solid ${theme.palette.border.tertiary}`,
        color: theme.typography.status.secondary,
        fontSize: "10px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        padding: "2px 8px",
        textAlign: "center",
        marginTop: "-6px",
        textTransform: "capitalize"
    },
    Reassign: {
        textTransform: "upperCase",
        color: theme.typography.status.primary,
        fontSize: "10px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        cursor: "pointer"
    },
    closeIcon:{
        cursor:"pointer",
    },
    iconbtn: {
        border: `1px solid ${theme.palette.border.secondary}`,
        backgroundColor: theme.palette.border.secondary,
        padding: "8px",
        borderRadius: "50%",
        height: "24px",
        width: "24px",
        cursor: "pointer"
    },
    icon: {
        fontSize: "14px",
        marginLeft: "-2px",
    },
    resimg:{
        borderRadius:theme.palette.borderRadius,
    },
    hdrtitle: {
        fontSize: "16px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        color: theme.typography.color.primary
    },
    hdrdescription:{
        fontSize: "14px",
        fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
        color: theme.typography.color.tertiary  
    },
    cmpbox:{
        border:"1px solid #E4E8EE",
        borderRadius:"10px",
        padding:"10px",
    },
    cmpblock:{
        backgroundColor:"#FFFFFF"
    },
    projectedTime:{
        borderRadius:theme.palette.borderRadius,
        backgroundColor:"#F5F7FA",
        padding:"12px"
    },
    actualworkinghr:{
        borderRadius:theme.palette.borderRadius,
        backgroundColor:"#B2060614",
        padding:"10px"  
    },
    siteText:{
        fontSize:"10px",
        color:theme.typography.color.tertiary,
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        textTransform:"capitalize"
    },
    resinspection:{
        display:"flex",
        justifyContent:"space-between",
        backgroundColor:'#EEF9EE',
        borderRadius:theme.palette.borderRadius
    },
    completedDateTime:{
        display:"flex",
        justifyContent:"center",
        backgroundColor:'#EEF9EE',
        borderRadius:"14px",
        padding:"2px 10px",
        width:"fit-content"
    },
    insdateTime:{
        fontSize:"12px",
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        color:"#568656"
    },
    tracksection:{
        borderColor: theme.palette.border.secondary,
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
    },
    
}))