import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import ReactQuill from 'react-quill';
import { useHistory, useLocation } from "react-router-dom"
import { Subheader } from "../../components"
import { useStyles } from './style';
import { GET_SERVICE_PROVIDER } from '../../graphql/queries';
import { useApolloClient } from '@apollo/client';
import { AuthContext, BackdropContext } from '../../contexts';
import ImageViewer from "react-simple-image-viewer";
import { accessCheckRender, getRoutePermissionNew, useWindowDimensions } from '../../utils';
import { Routes } from "../../router/routes";

export const ServiceProviderView = (props) => {

    const modules = { toolbar: false }

    const history = useHistory()
    const size = useWindowDimensions()
    const classes = useStyles(size)
    const client = useApolloClient()
    const search = useLocation().search
    const serviceProviderID = new URLSearchParams(search).get("serviceProviderID")

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const auth = React.useContext(AuthContext)

    // useState
    const [data, setData] = React.useState({})
    const [isViewerOpen, setIsViewerOpen] = React.useState(false)
    const [currentImage, setCurrentImage] = React.useState("")
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getInitialData()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // Function to open ImageViewer
    const openImageViewer = React.useCallback((index) => {
        setCurrentImage(index)
        setIsViewerOpen(true)
    }, [])
    // Function to close ImageViewer
    const closeImageViewer = () => {
        setCurrentImage(0)
        setIsViewerOpen(false)
    }
    // Function to get service provider details
    const getServiceProvider = () => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        client.query({
            query: GET_SERVICE_PROVIDER, fetchPolicy: 'network-only',
            variables: { serviceProviderID }
        }).then((r) => {
            setData(r?.data?.service_providers_master?.[0])
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        })
    }

    const getInitialData = () => {
        if (serviceProviderID) { getServiceProvider() } else { history.goBack(-1) }
    }

    // Google Map
    // const GoogleMap = React.useCallback(() => {
    //     return (<MyGoogleMap
    //         lat={data?.service_providers_addressByID?.[0]?.latitude}
    //         lng={data?.service_providers_addressByID?.[0]?.longitude}
    //         center={{
    //             lat: data?.service_providers_addressByID?.[0]?.latitude,
    //             lng: data?.service_providers_addressByID?.[0]?.longitude
    //         }}
    //         zoom={13}
    //         readonly />)
    // }, [data])

    // Function to handle edit button
    const handleEdit = () => {
        history.push({
            pathname: Routes.serviceProviderCreateEdit,
            state: {
                main: {
                    company: data?.companyByID,
                    companyID: data?.companyByID?.id,
                    serviceProviderID: serviceProviderID,
                }
            }
        })
    }

    const render = () => {
        return <>
            <Subheader title="Service Provider" goBack={() => history.goBack(-1)}
                isEdit={permission.update} handleEdit={handleEdit} />
            <Grid container className={classes.content}>
                <Grid container className={classes.profileDetailCard}>
                    <Grid item md={2} lg={2} className={classes.profileImageDiv}>
                        <img src={data?.image_url ?? "/images/imagesproperty.svg"} alt="" className={classes.image}
                            onClick={() => openImageViewer([data?.image_url])} />
                        {isViewerOpen && (<ImageViewer
                            src={currentImage}
                            currentIndex={0}
                            onClose={closeImageViewer}
                            disableScroll={false}
                            backgroundStyle={{
                                backgroundColor: "rgba(0,0,0,0.9)",
                            }}
                            closeOnClickOutside={true} />)}
                    </Grid>
                    <Grid item md={10} lg={10} className={classes.profileDetailDiv}>
                        <Typography className={classes.title}>Profile Details</Typography>
                        <Grid container>
                            <Grid item md={12} lg={9}>
                                <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="8px">
                                    <Box>
                                        <Typography className={classes.heading}>Company Name</Typography>
                                        <Typography className={classes.sub}>{data?.companyByID?.name}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className={classes.heading}>Service Provider Category</Typography>
                                        <Typography className={classes.sub}>{data?.profession_masterByID?.name}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className={classes.heading}>Service Provider Name</Typography>
                                        <Typography className={classes.sub}>{data?.name}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className={classes.heading}>Service Provider Code</Typography>
                                        <Typography className={classes.sub}>{data?.service_provider_code}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box marginTop="14px">
                            <Typography className={classes.heading}>Description</Typography>
                            {data?.description &&
                                <ReactQuill
                                    readOnly
                                    theme="bubble"
                                    value={data?.description ?? ""}
                                    modules={modules}
                                    className={classes.sub} />}
                        </Box>
                    </Grid>
                    <Grid item md={0} lg={4.5} />
                </Grid>
                <Grid container className={classes.card}>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={5}>
                    <div className={classes.mapDiv}>
                        <GoogleMap />
                    </div>
                </Grid> */}
                        {/* when map component is needed plz use below commented grid item */}
                        {/* <Grid item xs={3}> */}
                        <Grid item xs={6}>
                            <div className={classes.addressDiv}>
                                <Stack direction="row" >
                                    <img src='/images/loc.svg' alt='' />
                                    <Typography className={classes.title}>&nbsp;Address</Typography>
                                </Stack>
                                <Box height="12px" />
                                <Typography className={classes.sub}>
                                    {data?.service_providers_addressByID?.[0]?.door_no + "," ?? ""}
                                    {data?.service_providers_addressByID?.[0]?.street_1 + "," ?? ""}
                                    {data?.service_providers_addressByID?.[0]?.street_2 + "," ?? ""}
                                    {data?.service_providers_addressByID?.[0]?.landmark + "," ?? ""}
                                    {data?.service_providers_addressByID?.[0]?.area + "," ?? ""}
                                    {data?.service_providers_addressByID?.[0]?.city + "," ?? ""}
                                    {data?.service_providers_addressByID?.[0]?.state + "," ?? ""}
                                    {data?.service_providers_addressByID?.[0]?.country + "," ?? ""}
                                    {data?.service_providers_addressByID?.[0]?.zipcode ?? ""}
                                </Typography>
                                <Box height="16px" />
                                <Stack direction="row" >
                                    <Typography className={classes.heading}>Latitude : </Typography>
                                    <Typography className={classes.sub1}>
                                        &nbsp;{data?.service_providers_addressByID?.[0]?.latitude}
                                    </Typography>
                                </Stack>
                                <Box height="12px" />
                                <Stack direction="row" >
                                    <Typography className={classes.heading}>Longitude : </Typography>
                                    <Typography className={classes.sub1}>
                                        &nbsp;{data?.service_providers_addressByID?.[0]?.longitude}
                                    </Typography>
                                </Stack>
                            </div>
                        </Grid>
                        {/* when map component is needed plz use below commented grid item */}
                        {/* <Grid item xs={4}> */}
                        <Grid item xs={6}>
                            <div className={classes.contactDiv}>
                                <Stack direction="row" >
                                    <img src='/images/Group 7015.svg' alt='' />
                                    <Typography className={classes.title}>&nbsp;Contact & Other Information</Typography>
                                </Stack>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Box marginTop="18px">
                                            <Typography className={classes.heading}>Primary Mobile : </Typography>
                                            <Typography className={classes.sub}>
                                                {data?.service_providers_addressByID?.[0]?.mobile_no ?
                                                    (data?.service_providers_addressByID?.[0]?.mobile_code +
                                                        (data?.service_providers_addressByID?.[0]?.mobile_code ? " " : "") +
                                                        data?.service_providers_addressByID?.[0]?.mobile_no) : ""}
                                            </Typography>
                                        </Box>
                                        <Box marginTop="12px">
                                            <Typography className={classes.heading}>Email Address : </Typography>
                                            <Typography className={classes.sub}>
                                                {data?.service_providers_addressByID?.[0]?.email_id}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box marginTop="18px">
                                            <Typography className={classes.heading}>Primary Telephone : </Typography>
                                            <Typography className={classes.sub}>
                                                {data?.service_providers_addressByID?.[0]?.telephone_no ?
                                                    (data?.service_providers_addressByID?.[0]?.telephone_code +
                                                        (data?.service_providers_addressByID?.[0]?.telephone_code ? " " : "") +
                                                        data?.service_providers_addressByID?.[0]?.telephone_no) : ""}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.card}>
                    <Typography className={classes.title}>Banking Basic Details</Typography>
                    <Grid item md={12}>
                        <Grid container marginTop={"8px"}>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Bank Name</Typography>
                                <Typography className={classes.sub}>{data?.bank_name ? data?.bank_name : "-"}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Branch Name</Typography>
                                <Typography className={classes.sub}>{data?.branch_name ? data?.branch_name : "-"}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Currency</Typography>
                                <Typography className={classes.sub}>
                                    {data?.currency_masterByID?.code ? data?.currency_masterByID?.code : "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Bank Account Type</Typography>
                                <Typography className={classes.sub}>{data?.account_type ? data?.account_type : "-"}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Account Number</Typography>
                                <Typography className={classes.sub}>{data?.account_no ? data?.account_no : "-"}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Bank Routing Type</Typography>
                                <Typography className={classes.sub}>{data?.routing_type ? data?.routing_type : "-"}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container marginTop={"8px"}>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Bank Routing Code</Typography>
                                <Typography className={classes.sub}>{data?.routing_code ? data?.routing_code : "-"}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Preferred Cash Collection Office</Typography>
                                <Typography className={classes.sub}>
                                    {data?.cash_collection_office ? data?.cash_collection_office : "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Cheque Name</Typography>
                                <Typography className={classes.sub}>{data?.cheque_name ? data?.cheque_name : "-"}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Address Line 1</Typography>
                                <Typography className={classes.sub}>{data?.address_1 ? data?.address_1 : "-"}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Address Line 2</Typography>
                                <Typography className={classes.sub}>{data?.address_2 ? data?.address_2 : "-"}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>City</Typography>
                                <Typography className={classes.sub}>{data?.bank_city ? data?.bank_city : "-"}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container marginTop={"8px"}>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Country</Typography>
                                <Typography className={classes.sub}>{data?.bank_country ? data?.bank_country : "-"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.card}>
                    <Grid item md={12}>
                        <Grid container>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Company Registration</Typography>
                                <Typography className={classes.sub}>
                                    {data?.company_registration ? data?.company_registration : "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Company Registration Number</Typography>
                                <Typography className={classes.sub}>
                                    {data?.company_registration_no ? data?.company_registration_no : "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Facebook</Typography>
                                <Typography className={classes.sub}>{data?.facebook ? data?.facebook : "-"}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Twitter</Typography>
                                <Typography className={classes.sub}>{data?.twitter ? data?.twitter : "-"}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Linkedin</Typography>
                                <Typography className={classes.sub}>{data?.linkedin ? data?.linkedin : "-"}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>Whatsapp</Typography>
                                <Typography className={classes.sub}>{data?.whatsapp ? data?.whatsapp : "-"}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container marginTop={"8px"}>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>No. Of Visits/Entries For the Month</Typography>
                                <Typography className={classes.sub}>
                                    {data?.swift_in_out_logByID?.[0]?.count ? data?.swift_in_out_logByID?.[0]?.count : "-"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div>
}