import { makeStyles } from "@mui/styles";
import { Regular, SemiBold } from "../../utils";

export const ImageCardStyles = makeStyles((theme) => ({
  image: {
    width: "188px",
    height: "188px",
    borderRadius: "4px",
    position: "relative",
    "&:hover": {
      opacity: "16",
      backgroundColor: 'red'
    },
  },
  imgcard: {
    position: "relative",
  },
  imgtext: {
    position: "absolute",
    bottom: 0,
    color: "white",
    top: "70%",
    marginLeft: "6px",
  },
  deleteicon: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    top: "5%",
    color: "#FF4B4B",
    backgroundColor: "white",
    borderRadius: "50%",
    padding: "5px",
    fontSize: "25px",
    right: "9px",
  },
  dot: {
    width: "4px",
    height: "4px",
    backgroundColor: "white",
    borderRadius: "50%",
    marginTop: "5px",
    marginLeft: "4px",
    marginRight: "4px",
  },
  imgName: {
    fontFamily: SemiBold,
    color: "#FFFFFF",
    fontSize: "12px",
  },
  textName: {
    fontFamily: Regular,
    color: "#FFFFFF",
    fontSize: "10px",
  },
  hoverCard: {
    opacity: "12",
    backgroundColor: '#0000009c',
    position: 'absolute',
    width: "188px",
    height: "188px",
    top: 0,
    bottom: 0,
    borderRadius: 4
  }
}));
