import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  MenuItem,
  Button,
  Menu,
  Divider,
  Hidden,
} from "@mui/material";
import { useStyles } from "../style";
import { ImageUpload } from "../../announsment/imageupload";
import { Editor, SelectBox, DialogBox } from "../../../components";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReactQuill from "react-quill";
import { SingleImgUpload } from "./attachmentUpload";
import { enumSelect, enum_types } from "../../../utils";
import moment from "moment";
import { AuthContext } from "../../../contexts";

const menuItemOptions = (t, showAttachment) => [
  {
    title: t("Image"),
    value: "image",
    isDivider: true,
    images: (
      <img
        src="/images/imageadd.svg"
        alt="imageAdd"
        style={{ marginRigth: "10px" }}
      />
    ),
    is_active: true,
  },
  {
    title: t("TitleDescription"),
    value: "title",
    isDivider: showAttachment?.length > 0 ? false : true,
    images: (
      <img
        src="/images/textadd.svg"
        alt="imageAdd"
        style={{ marginRigth: "10px" }}
      />
    ),
    is_active: true,
  },
  {
    title: t("Attachments"),
    value: "Attachments",
    isDivider: false,
    images: (
      <img
        src="/images/attachment.svg"
        alt="imageAdd"
        style={{ marginRigth: "10px" }}
      />
    ),
    is_active: showAttachment?.length > 0 ? false : true,
  },
];

export const AnnounsmentDetails = ({
  t,
  data = [],
  setUUID = "",
  uuid = "",
  setData = "",
  updateState1 = () => false,
  handleRemoveFields = () => false,
  editImage = () => false,
  handleAddFields = () => false,
  updateState2 = () => false,
  data1 = "",
  pushTitle = "pushTitle",
  company = {},
  body = "body",
  show = false,
}) => {
  // classes
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const modules = {
    toolbar: false,
  };
  // state
  const [format, setFormat] = useState("App");
  const [anchorEl, setAnchorEl] = useState(null);
  const [enumValue, setEnumValue] = useState({
    announcement_type: [],
  });
  const [dialog, setDialog] = useState(false);
  const open = Boolean(anchorEl);
  // funtions
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const showAttachment = data
    .map((x) => x?.attachment)
    .filter((x) => x !== undefined);

  // select add
  const handleClose = (key) => {
    if (key === "key") {
      setAnchorEl(null);
    } else {
      setAnchorEl(null);
      handleAddFields(key);
    }
  };
  //get Enum
  const getEnum = async () => {
    const result = await enumSelect([enum_types.notification_category_type]);
    setEnumValue({
      announcement_type: result?.notification_category_type,
    });
  };
  useEffect(() => {
    getEnum();
  }, []);
  return (
    <Box className={classes.announsmentDetailsroot}>
      <Grid container mt={"14px"}>
        <Grid item xs={8} className={classes.noPadding}>
          <div className={classes.announsmentDetailsParent}>
            <Box>
              <Grid container justifyContent={"space-between"} alignItems={"center"} spacing={"14px"}>
                <Grid item xs={4}>
                  <SelectBox
                    label="Category"
                    placeholder="Select Category"
                    options={enumValue?.announcement_type}
                    value={data1?.Category}
                    onChange={(value) => {
                      updateState2("Category", value);
                    }}
                    isError={data1?.error?.Category?.length > 0}
                    errorMessage={data1?.error?.Category}
                  />
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <Box ml={2} mr={2}>
                        <Typography className={classes.title}>
                          {t("step3")}
                        </Typography>
                        <Typography className={classes.subtitle}>
                          {t("step3Sub")}
                        </Typography>
                      </Box>
                      <span className={classes.draft}>03</span>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box height={"20px"} />
            {/* image upload */}
            <Box className={classes.content}>
              {data?.map((value, index) => (
                <>
                  {value?.hasOwnProperty("images") && (
                    <>
                      <Grid container style={{ position: "relative" }}>
                        <Grid item xs={12}>
                          <Typography className={classes.step2Title}>
                            {t("imageUplaod")}
                          </Typography>
                          <ImageUpload
                            accept="image/*"
                            setUUID={setUUID}
                            uuid={uuid}
                            data1={data}
                            updateImageURL={setData}
                            selectedImageURL={value}
                            editImage={editImage}
                            index={index}
                            companyID={1}
                            key="images"
                            handleRemoveFields={handleRemoveFields}
                            height={"192px"}
                          />
                        </Grid>
                      </Grid>
                      <Box height={"20px"} />
                    </>
                  )}

                  {value?.hasOwnProperty("title") && (
                    <>
                      <Grid container className={classes.input}>
                        <Grid item xs={12}>
                          <Typography className={classes.step2Title}>
                            {t("announsment_title")}
                          </Typography>
                          <Box height={"11px"} />
                          <Editor
                            emoji
                            value={value?.title}
                            label=""
                            handleChange={(e, delta, source, editor) => {
                              updateState1("title", e, index, editor);
                            }}
                            id={index === 0 ? pushTitle : ""}
                            height={"85px"}
                          />
                        </Grid>
                      </Grid>
                      <Box height={"20px"} />
                    </>
                  )}
                  {value?.hasOwnProperty("Description") && (
                    <>
                      <Grid container className={classes.input}>
                        <Grid item xs={12}>
                          <Typography className={classes.step2Title}>
                            {t("announsment_description")}
                          </Typography>
                          <Box height={"11px"} />
                          <Editor
                            emoji
                            value={value?.Description}
                            label=""
                            handleChange={(e) => {
                              updateState1("Description", e, index);
                            }}
                            height={"150px"}
                          />
                        </Grid>
                      </Grid>
                      <Box height={"20px"} />
                    </>
                  )}
                  {value?.hasOwnProperty("attachment") && (
                    <>
                      <Grid container className={classes.input}>
                        <Grid item xs={12}>
                          <Typography className={classes.step2Title}>
                            {t("ATTACHMENTS")}
                          </Typography>
                          <Box height={"11px"} />
                          <SingleImgUpload
                            handleChange={(e) => {
                              updateState1("attachment", e, index);
                            }}
                            handleFile={""}
                            selectedImageURL={value?.attachment}
                            companyId={1}
                            size={{
                              xs: 3,
                              sm: 3,
                              md: 3,
                              lg: 3,
                              // xlg:1.5
                            }}
                            size1={{
                              xs: 9,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Box height={"20px"} />
                    </>
                  )}
                </>
              ))}

              {/* options */}
              <div className={classes.flexBox}>
                <Button
                  variant="outlined"
                  className={classes.addbutton}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  + {t("Add")}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  className={classes.menu}
                  open={open}
                  onClose={() => handleClose("key")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  {menuItemOptions(t, showAttachment)?.map((x) => {
                    return (
                      <>
                        {x?.is_active && (
                          <div>
                            <MenuItem
                              onClick={() => handleClose(x?.value)}
                              className={classes.menuItems}
                            >
                              {x?.images ?? ""}
                              <span className={classes.menuContant}>
                                {x?.title ?? ""}
                              </span>
                            </MenuItem>
                            {x?.isDivider && <Divider />}
                          </div>
                        )}
                      </>
                    );
                  })}
                </Menu>
              </div>
            </Box>
          </div>
        </Grid>
        {/* view section */}
        <Grid item xs={4} className={classes.noPadding}>
          <div className={classes.detailsPadding}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Box>
                <Typography className={classes.typeName}>
                  {format === "App" ? t("Mobile_Preview") : t("Email_Preview")}
                </Typography>
                <Box height={"8px"} />
                <Box className={classes.toggle}>
                  {["App", "E-mail"]?.map((x) => {
                    return (
                      <Button
                        className={
                          x === format ? classes.select : classes.unSelect
                        }
                        onClick={() => setFormat(x)}
                      >
                        {x}
                      </Button>
                    );
                  })}
                </Box>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  className={classes.emailPreview}
                  onClick={() => setDialog(true)}
                >
                  {t("Email_Preview")}
                </Button>
              </Box>
            </Box>
            <Box height={"19px"} />
            {/* preview section */}
            <div>
              {format === "App" ? (
                <>
                  <Box className={classes.titleBar}>
                    <Typography className={classes.announsmentTitle}>
                      {t("announcement")}
                    </Typography>
                    <ArrowBackIosIcon className={classes.arrowIcon} />
                  </Box>
                  <Box height={"12px"} className={classes.headerBox} />
                </>
              ) : (
                <Box className={classes.headerBox1}>
                  <img
                    src={company?.data?.logo}
                    alt={company?.label}
                    width="141px"
                    height={"30px"}
                  />
                </Box>
              )}

              <div className={classes.detailscontent}>
                <Box className={classes.detailsBox}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={"space-between"}
                    p={1}
                    mb={1.5}
                  >
                    {data1?.Category?.label?.length > 0 && (
                      <span className={classes.chip}>
                        {data1?.Category?.label}
                      </span>
                    )}

                    <Typography className={classes.dateAndTime}>
                      Posted at{" "}
                      {moment(new Date()).format("DD-MM-YYYY,hh:mm A")}, by{" "}
                      {auth?.auth?.auth?.first_name ?? " "}&nbsp;
                      {auth?.auth?.auth?.last_name ?? " "}
                    </Typography>
                  </Box>
                  {data?.map((val) => {
                    return (
                      <>
                        <Grid container>
                          {val?.images && (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  mb: 2,
                                }}
                              >
                                <img
                                  src={val?.images}
                                  style={{
                                    width: "100%",
                                    height: "250px",
                                  }}
                                  alt="images"
                                />
                              </Grid>
                            </>
                          )}
                          {val?.title && (
                            <>
                              <Grid item xs={12} style={{ textAlign: "left" }}>
                                <ReactQuill
                                  readOnly
                                  theme="bubble"
                                  value={val?.title}
                                  modules={modules}
                                  className={classes.reactQuil}
                                />
                              </Grid>
                              <Box className={classes.contant}>
                                <ReactQuill
                                  readOnly
                                  theme="bubble"
                                  value={val?.Description}
                                  modules={modules}
                                  className={classes.reactQuil}
                                />
                              </Box>
                            </>
                          )}
                          {val?.hasOwnProperty("attachment") && (
                            <Grid item xs={12}>
                              <Grid container spacing={1} mt={1}>
                                {val?.attachment?.map((x) => {
                                  return (
                                    <Grid
                                      item
                                      xs={3}
                                      sm={3}
                                      md={3}
                                      lg={3}
                                      xxl={1.5}
                                    >
                                      <Box className={classes.detailsBox1}>
                                        <Typography
                                          className={classes.fileName}
                                        >
                                          {x?.name ?? ""}
                                        </Typography>
                                        <Box height={"10px"} />
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          className={classes.section}
                                        >
                                          <Typography className={classes.type}>
                                            {x?.size ?? ""}
                                          </Typography>
                                          <Box className={classes.dot} />
                                          <Typography className={classes.type}>
                                            PDF
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </>
                    );
                  })}
                </Box>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <DialogBox
        id={"id_123"}
        open={dialog}
        onClose={() => setDialog(false)}
        handleClose={() => setDialog(false)}
        header={`Email Web Preview`}
        isNormal
        width={"710px !important"}
        maxWidth={"md"}
        component={
          <Box>
            <EmailPreview
              data={data}
              data1={data1}
              auth={auth}
              classes={classes}
              is_show
              body={body}
            />
          </Box>
        }
      />
      <Hidden smDown>
        <EmailPreview data={data} data1={data1} auth={auth} body={body} />
      </Hidden>
    </Box>
  );
};

const EmailPreview = ({
  data = {},
  auth = {},
  data1 = {},
  classes = {},
  is_show = false,
  body = "body",
}) => {
  return (
    <>
      <body width="100%" id={body ?? "body"}>
        <div
          style={{ maxWidth: "810px", margin: "0 auto" }}
          class="email-container"
        >
          <div
            style={{
              // height: "calc(100vh - 300px)",
              overflow: "auto",
              backgroundColor: "white",
              padding: "16px",
            }}
          >
            <Box style={{ backgroundColor: "white", padding: "16px" }}>
              <Box
                p={1}
                style={{
                  marginBottom: "12px",
                }}
              >
                {data1?.Category?.label?.length > 0 && (
                  <span
                    style={{
                      borderRadius: "4px",
                      padding: "4px",
                      color: "#98A0AC",
                      fontSize: "10px",
                      backgroundColor: "#E4E8EE",
                    }}
                  >
                    {data1?.Category?.label}
                  </span>
                )}

                <span
                  style={{
                    borderRadius: "4px",
                    padding: "4px",
                    color: "#98A0AC",
                    fontSize: "10px",
                    float: "right",
                  }}
                >
                  Posted at {moment(new Date()).format("DD-MM-YYYY,hh:mm A")},
                  by {auth?.auth?.auth?.first_name ?? " "}&nbsp;
                  {auth?.auth?.auth?.last_name ?? " "}
                </span>
              </Box>
              <div>
                {data?.map((val) => {
                  return (
                    <>
                      <Grid container>
                        {val?.images && (
                          <>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                mb: 2,
                              }}
                            >
                              <img
                                src={val?.images}
                                style={{
                                  width: "100%",
                                  height: "250px",
                                }}
                                alt="images"
                                crossorigin="anonymous"
                              />
                            </Grid>
                          </>
                        )}
                        {val?.title && (
                          <>
                            <Grid item xs={12} style={{ textAlign: "left" }}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: val?.title,
                                }}
                                style={{
                                  padding: "0px 0px 0px 0px",
                                  marginBottom: "0!important",
                                }}
                              />
                            </Grid>
                            <Box>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: val?.Description,
                                }}
                                style={{ padding: "0px 0px 0px 0px" }}
                              />
                            </Box>
                          </>
                        )}
                        {val?.attachment && is_show && (
                          <Grid item xs={12}>
                            <Grid container spacing={1} mt={1}>
                              {val?.attachment?.map((x) => {
                                return (
                                  <Grid
                                    item
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    xl={1.5}
                                  >
                                    <Box className={classes.detailsBox1}>
                                      <Typography className={classes.fileName}>
                                        {x?.name ?? ""}
                                      </Typography>
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        className={classes.section}
                                      >
                                        <Typography className={classes.type}>
                                          {x?.size ?? ""}
                                        </Typography>
                                        <Box className={classes.dot} />
                                        <Typography className={classes.type}>
                                          PDF
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  );
                })}
              </div>
            </Box>
          </div>
        </div>
      </body>
    </>
  );
};
