import { Divider, Grid, Stack, Typography } from "@mui/material";
import { showAddress, useWindowDimensions } from "../../utils";
import ImageViewer from "react-simple-image-viewer";
import { useStyles } from "./style";
import React from "react";
export const MiniPropertyDetail = ({
    logo = "",
    property_no = "",
    company_name = "",
    property_name = "",
    address = {}
}) => {

    const size = useWindowDimensions()
    const classes = useStyles()

    const [is_logo_viewer_open, set_is_logo_viewer_open] = React.useState(false)

    return (
        <Stack className={classes.root} direction={size?.width >= 600 ? "row" : "column"} spacing={"8px"}
            divider={size?.width >= 600 ? <Divider orientation="vertical" variant="middle" flexItem /> : <></>}>
            <div className={classes.logo_div}>
                <img src={logo ?? "/images/imagesproperty.svg"} alt="" className={classes.logo}
                    onClick={() => logo ? set_is_logo_viewer_open(true) : false} />
            </div>
            <Grid container>
                <div className={classes.title_div}>
                    <Typography className={classes.title}>PROPERTY DETAILS</Typography>
                    {property_no && <Typography className={classes.title_tag}>{property_no}</Typography>}
                </div>
                <Grid container spacing={"8px"}>
                    <Grid item xs={size?.width >= 1000 ? 2 : size?.width >= 450 ? 6 : 12}>
                        <Typography className={classes.heading}>Company Name</Typography>
                        <Typography className={classes.content}>{company_name ?? " - "}</Typography>
                    </Grid>
                    <Grid item xs={size?.width >= 1000 ? 2 : size?.width >= 450 ? 6 : 12}>
                        <Typography className={classes.heading}>Property Name</Typography>
                        <Typography className={classes.content}>{property_name ?? " - "}</Typography>
                    </Grid>
                    <Grid item xs={size?.width >= 1000 ? 8 : 12}>
                        <Typography className={classes.heading}>Location</Typography>
                        <Typography className={classes.content}>
                            {address?.id ? showAddress(address) : " - "}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {is_logo_viewer_open && (
                <ImageViewer
                    src={[logo]}
                    currentIndex={0}
                    onClose={() => set_is_logo_viewer_open(false)}
                    disableScroll={false}
                    backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                    className={classes.image_viewer}
                    closeOnClickOutside={true} />
            )}
        </Stack>
    )
}