import React from "react";
import { withNavBars } from "../../HOCs";
import { UtilitiesMaster } from "./utilitiesMaster";

class UtilitiesMasterParent extends React.Component {
    render() {
        return <UtilitiesMaster  {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(UtilitiesMasterParent, props);
