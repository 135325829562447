import { Box, Grid, IconButton, Badge, Stack } from '@mui/material'
import React from 'react'
import { AlertDialog, FilterGenerator, SearchFilter, TableWithPagination } from '../../../components'
import { RequestPopup } from './requestPopup'
import { TableStyles } from './style'
import { UpdatePopup } from './updatePopup'
import FilterIMG from '../../../assets/filter';
import { GET_ACCOUNT, GET_SELECTED_USER_INVOICE } from '../../../graphql/alreadyPaidQueries'
import { loadOptions } from '../../../utils/asyncPaginateLoadOptions'
import { config } from '../../../config'
import { NetworkCall } from '../../../networkcall'
import { NetWorkCallMethods,enumSelect, enum_types  } from '../../../utils'

export const AlreadyPaidTable = (
    { row = {},
        heading = {},
        handleSearch = () => false,
        searchText = "",
        getFilterData = () => false,
        selectedCompany = {},
        handlePagination = () => false,
        handleChangeLimit = () => false,
        page = "",
        limit = "",
        rowcount = ""
    }
) => {
    const classes = TableStyles()
    const [reqPopup, setreqPopup] = React.useState(false)
    const [decline, setDecline] = React.useState(false)
    const [reconcile, setReconcile] = React.useState(false)
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [reqpopupTemplateData, setreqpopupTemplateData] = React.useState("")
    const [enumData, setEnumData] = React.useState("")
    const [updateStatus, setUpdateStatus] = React.useState(false)
    const openDecline = () => {
        setDecline(true)
    }
    const closeDecline = () => {
        setDecline(false)
    }
    const openReconcile = () => {
        setReconcile(true)
    }
    const closeReconcile = () => {
        setReconcile(false)
    }
    const closeReqpopup = () =>{
        setreqPopup(false)
    }
    const getSelectedInvoice = (data) => {
        const params = {
            query: GET_SELECTED_USER_INVOICE(data?.invoice_number, data?.reference_num).loc.source.body,
            variables: {},
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            params,
            null,
            true,
            false
        )
            .then((response) => {
                setreqpopupTemplateData(response?.data?.data?.compensated_invoices[0])
                if (response) {
                    setreqPopup(true)
                }
            })
            .catch((error) => {

            });
    }
    const handleIcon = (type, data) => {
        if (type === "view") {
            getSelectedInvoice(data)
        }
    }

    
    const statusUpdate = () =>{
        setUpdateStatus(true)
    }
    //get compenstated type Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.compensated_invoices_type_enum])
        setEnumData(result?.compensated_invoices_type_enum)
    }

    React.useEffect(() => {
        getEnum()
    }, [updateStatus])



    return (
        <Box m={2}>
            <Grid container spacing={2} className={classes.content}>
                <Grid container alignItems={"center"} p={1} pl={2} pt={2}>
                    <Grid item xs={4}>
                        <SearchFilter placeholder={"Search"} value={searchText} handleChange={(value) => handleSearch(value)} />
                    </Grid>
                    <Grid item xs={8}>
                        <Box display={"flex"} justifyContent={"end"}>
                            <Stack direction="row" spacing={2} className={classes.filter}>
                                <IconButton onClick={() => setFilterDrawer(true)}
                                    className={classes.filterButton}>
                                    <Badge variant="dot" color="primary"
                                        invisible={false}>
                                        <FilterIMG color="#091b29" />
                                    </Badge>
                                </IconButton>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item className={classes.tablegrid}>
                    <TableWithPagination
                        rows={row}
                        heading={heading}
                        handleIcon={handleIcon}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        page={page}
                        limit={limit}
                        showpagination={true}
                        dataType={[
                            { type: ["more_6_ins_item"], name: "icon" },
                            { type: ["text"], name: "request_id" },
                            { type: ["text"], name: "requested_by" },
                            { type: ["text"], name: "invoice_number" },
                            { type: ["text"], name: "payment_method" },
                            { type: ["text"], name: "reference_num" },
                        ]}
                        tableType="no-side"
                        height={'calc(100vh - 320px)'}
                        totalRowsCount={rowcount}
                    />
                </Grid>
            </Grid>
            <AlertDialog open={reqPopup}
                onClose={() => setreqPopup(!reqPopup)}
                header={reqpopupTemplateData?.reference_id}
                component={<RequestPopup openDecline={openDecline} openReconcile={openReconcile} 
                templateData={reqpopupTemplateData} enumData={enumData}/>}
                isNormal
            />
            <AlertDialog open={decline}
                onClose={closeDecline}
                header="Decline"
                component={<UpdatePopup type={"Rejected"} close={closeDecline}
                    invoice_data={reqpopupTemplateData} title={"Declining the request"}
                    message={"This request will be closed and mark as declined"} enumData={enumData}
                    statusUpdate={statusUpdate}
                    closeReqpopup={closeReqpopup} />}
                isNormal
            />
            <AlertDialog open={reconcile}
                onClose={closeReconcile}
                header="Reconcile"
                component={<UpdatePopup type={"Reconcile"} close={closeReconcile}
                    invoice_data={reqpopupTemplateData} title={"Reconciling the request"}
                    message={"This request will be reconciled"} enumData={enumData}
                    statusUpdate={statusUpdate}
                    closeReqpopup={closeReqpopup} />}
                isNormal
            />
            <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                onApply={(value) => getFilterData(value)}
                components={[
                    {
                        component: "date_range",
                        value: "",
                        state_name: "dateRange",
                        label: "Requested Date"
                    },
                    {
                        component: "amount_minAndmax",
                        value: "",
                        label: "Amount",
                        amount_symbol: selectedCompany?.currency_symbol,
                        state_name: "amountMinMax"
                    },
                    {
                        component: "select",
                        label: "Account Number",
                        state_name: "accountNumber",
                        isPaginate: true,
                        loadOptions: (search, array, handleLoading) => loadOptions(
                            search,
                            array,
                            handleLoading,
                            GET_ACCOUNT,
                            'contact_account',
                            { company_id: selectedCompany?.value },
                            { label: "label", value: "value" }
                        ),
                    }
                ]} />
        </Box>
    )
}