
export const KycDetailsTableHeading = (t) => {
    const Heading = [
        { title: t("RequestId"), field: "booking_id" },
        { title: t("AgreementId"), field: "agreement_no" },
        { title: t("NoofProof"), field: "no_of_proof" },
        { title: t("ApprovedProofCount"), field: "no_of_approved_count" },
        { title: t("Status"), field: "status" }
    ]
    return Heading
}

export const KycDetailsTablePath = [
    "booking_id",
    "agreement_no",
    "no_of_proof",
    "no_of_approved_count",
    "status"
]

export const KycDetailsTableDataType = [
    { type: ["text"], name: "booking_id" },
    { type: ["text"], name: "agreement_no" },
    { type: ["text"], name: "no_of_proof" },
    { type: ["text"], name: "no_of_approved_count" },
    { type: ["kyc_status"], name: "status" }
]



export const KycDetailViewTableHeading = (t) => {
    const Heading = [
        { title: t("contactName"), field: "contact_name" },
        { title: t("IDType"), field: "id_type" },
        { title: t("NameAsPerID"), field: "name_as_per_id" },
        { title: t("IDNumber"), field: "id_number" },
        { title: t("Mandatory"), field: "mandatory" },
        { title: t("Validatity"), field: "validatity" },
        { title: t("Verified"), field: "verified" },
        { title: t("source"), field: "source" },
        { title: t("status"), field: "status" }
    ]
    return Heading
}
export const KycDetailViewTablePath = [
    "contact_name",
    "id_type",
    "name_as_per_id",
    "id_number",
    "mandatory",
    "validatity",
    "verified",
    "source",
    "status"
]

export const KycDetailViewTableDataType = [
    { type: ["text"], name: "contact_name" },
    { type: ["text"], name: "id_type" },
    { type: ["text"], name: "name_as_per_id" },
    { type: ["text"], name: "id_number" },
    { type: ["text"], name: "mandatory" },
    { type: ["text"], name: "validatity" },
    { type: ["text"], name: "verified" },
    { type: ["text"], name: "source" },
    { type: ["kyc_status"], name: "status" }
]

export const Mandatory = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
]