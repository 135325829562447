import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";
export const finderStyles = makeStyles((theme) => ({
    root: {
        display: "grid",
        alignItems: "center",
        backgroundImage: `url("/images/Mask Group 6350.png")`,
        marginTop: "-20px",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    },
    card: {
        border: "2px solid white",
        borderRadius: "16px",
        boxShadow: "0px 0px 16px #E4E8EE",
        backgroundColor: "white",
        padding: "16px",
        margin: "12px",

    },
    selector: {
        borderRight: "1px solid #ececec",
        //height: "45px"
    },
    selectorRoot: {
        marginTop: "8px",
        border: "1px solid #ececec",
        borderRadius: "10px",

    },
    search: {
        backgroundColor: "#5078E1",
        height: "47px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px"
    },
    filter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        height: "45px",
    },

    clearAll: {
        fontSize: "12px",
        color: "#FF4B4B",
        fontFamily: Bold,
        textAlign: "right",
        marginTop: "-30px",
        marginBottom: "12px",
        cursor: "pointer",
        zindex: 999
    },
    filterDialog: {
        "& .MuiDrawer-paper": {

            boxShadow: "none",
            border: 0,
            backgroundColor: "transparent",
        }
    }
}));