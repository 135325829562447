import { Box, Button, Typography } from "@mui/material";
import { useStyles } from "./style";
export const SettingSave = ({ title = "", subTitle = "", onSave = () => false, btn = false, cancel = false, btnName = "Save", onCancel = () => false, cancelName = "Cancel" }) => {
    const classes = useStyles()
    return (
        <Box display="flex" alignItems="center" className={classes.root}>
            <Box flexGrow={1}>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.subTitle}>{subTitle}</Typography>
            </Box>
            {
                cancel &&
                <Box marginRight="10px">
                    <Button className={classes.btn1} variant="outlined" onClick={onCancel}>{cancelName}</Button>
                </Box>
            }
            {
                btn &&
                <Box>
                    <Button className={classes.btn} variant="contained" onClick={onSave}>{btnName}</Button>
                </Box>
            }


        </Box>
    )
}