import React from "react";
import {AccountDetails} from "../accountDetails/accountDetails";
import { useLocation } from "react-router-dom";
import { withNavBars } from "../../HOCs";

const CustomerAccountDetailsParent = () => {
    const state = useLocation()?.state
    return <AccountDetails accid={state?.id} is_edit={false}/>;
}
const props = {
    boxShadow: false
}

export default withNavBars(CustomerAccountDetailsParent,props)