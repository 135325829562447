import { Box, Grid } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";
import { getSettlementHeading, settlementdataType, settlementPath } from "../../utils/settlement";
import { SettlementStyles } from "./style";

const Settlements = ({ t }) => {
    const classes = SettlementStyles()
    const auth = React.useContext(AuthContext);
    const settlementHeading = getSettlementHeading(t)
    const debounce = UseDebounce();
    const [searchText, setSearchText] = React.useState("");
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [list, setList] = React.useState({
        list: [],
        count: 0
    })
    const [permission, setPermission] = React.useState({})

    const alert = React.useContext(AlertContext)

    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getSettlements(0, 10, "")
            }
        }
        // eslint-disable-next-line
    }, [auth])

    const getSettlements = (offset = 0, limit = 10, searchText = "") => {
        let payload = {
            search: searchText,
            offset: offset,
            limit: limit,
        }

        NetworkCall(
            `${config.api_url}/settlement/history`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((response) => {
            setList({
                list: response?.data?.data?.map((val) => {
                    return {
                        ...val,
                        status: val?.status === "no-due" ? "Paid" : "Unpaid",
                        datetime: val?.settlement_date,
                        settlement_no: val?.settlement_no ?? "-"
                    }
                }),
                count: response?.data?.count
            })
        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true, severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            })
        })
    }

    //handlesearch
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getSettlements(0, 10, e)
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getSettlements(offset, limit, "")
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getSettlements(0, value, "")
    }

    const render = () => {
        return (
            <>
                <Subheader
                    hideBackButton={true}
                    title={t("Settlements")} />

                <Box className={classes.settlementRoot} p={2} m={2}>
                    <Grid container>
                        <Grid item xs={4}>
                            <SearchFilter handleChange={handleSearch} value={searchText} placeholder={t("search")} />
                        </Grid>
                        <Grid item xs={12}>
                            <TableWithPagination
                                heading={settlementHeading}
                                rows={list?.list}
                                path={settlementPath}
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                tableType="no-side"
                                showpagination={true}
                                dataType={settlementdataType}
                                handleChangeLimit={handleChangeLimit}
                                handlePagination={handlePagination}
                                page={page}
                                totalRowsCount={list?.count}
                                limit={limit}
                                height={`calc(100vh - 300px)`}
                                view={true}
                                edit={true}
                                delete={true} />
                        </Grid>
                    </Grid>
                </Box>
            </>
        )
    }


    return <div>
        {accessCheckRender(render, permission)}
    </div>
}

export default withNamespaces("settlement")(Settlements)