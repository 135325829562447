import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../../utils";
export const useStyles = makeStyles((theme) => ({
    label: {
        color: "#98A0AC",
        fontFamily: SemiBold,
        fontSize: "14px",
        marginBottom: "5px"
    },
    title: {
        color: "#091B29",
        fontFamily: Bold,
        fontSize: "12px",
    },
    labellogo: {
        color: "#98A0AC",
        fontFamily: SemiBold,
        fontSize: "10px",
        marginBottom: "5px"
    },
    previewRoot: {
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        padding: "12px"
    },
    gatWayRoot: {
        backgroundColor: "#F5F7FA",
        borderBottom: "1px solid #E4E8EE"
    },
    previewImg: {
        height: "200px",
        objectFit: "cover"
    },
    imagepreviewRoot: {
        position: "relative"
    },
    logo: {
        height: "36px",
        width: "100px",
        position: "absolute",
        top: "8px",
        left: "0px",
        objectFit: "contain"
    },
    logo1: {
        height: "32px",
        width: "100px",
        position: "absolute",
        top: "12px",
        left: "0px",
        objectFit: "contain"
    },
}));