import {
    Grid
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useLocation } from "react-router-dom";
import { AccordionWithTable } from '../../components';
import { config } from "../../config";
import { GET_ASSESTS, GET_ROOMS, GET_SECURITY_REQUEST, GET_UNIT_AGREEMENT, GET_UNIT_AMENITIES_BREAKUP, GET_UNIT_DETAILS, GET_UNIT_INVOICE, GET_UNIT_OWNER, GET_UNIT_RENT_BREAKUP, GET_UNIT_UTILITIES, GET_UNIT_VACANCY_PERIOD } from '../../graphql/queries';
import { NetworkCall } from "../../networkcall";
import { ExtraBold, NetWorkCallMethods, SemiBold } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Details } from '../details';
import { Bold } from '../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        height: `calc(100vh - 88px)`,
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "0px",
        overflow: "auto"
    },

    btn: {
        borderRadius: theme.palette.borderRadius,
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
        // borderColor: theme.palette.secondary.main,
    },
    text: {
        fontSize: "24px",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    subText: {
        fontSize: "16px",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    bottomTitle: {
        fontSize: "18px",
        fontFamily: ExtraBold

    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        marginBottom: "12px"
    },
    contentBottom: {
        // padding: "12px",

    },
    drawer: {
        "& .MuiDrawer-paper": {

            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px"
        },
    },
    grid: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    title: {
        fontSize: "14px",
        fontFamily: Bold,
        color: theme.typography.color.primary

    },
    subTitle: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: theme.palette.primary.main
    }
}))

export const UnitTableDetails = props => {

    const classes = useStyles();
    const size = useWindowDimensions();
    //const alert = React.useContext(AlertContext);
    const [unitDetailsData, setUnitDetailsData] = React.useState([])
    const [assestData, setAssestData] = React.useState([])
    const search = useLocation().search;
    const unitId = new URLSearchParams(search).get("unitId");
    const getUnitDetailsId = () => {
        const payload = {
            query: GET_UNIT_DETAILS,
            variables: { unitId: unitId },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setUnitDetailsData(response?.data?.data?.unit);
            })
            .catch((error) => {
                //         alert.setSnack({
                //   ...alert,
                //   open: true,
                //   severity: AlertProps.severity.error,
                //   msg: "Some Thing Went Wrong Please try Again",
                //   vertical: AlertProps.vertical.top,
                //   horizontal: AlertProps.horizontal.center
                // })           
                console.log(error)
            });

    };
    const getAssestsId = () => {
        const payload = {
            query: GET_ASSESTS,
            variables: { id: unitId },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setAssestData(response?.data?.data);

            })
            .catch((error) => {
                console.log(error)
            });

    };

    React.useEffect(() => {
        getUnitDetailsId();
        getAssestsId();
        // eslint-disable-next-line
    }, []);

    const accordionData = [
        {
            id: 0,
            title: "Unit Rent Breakup",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Component", field: 'component', },
                    { title: "Value", field: 'value', },
                    { title: "Currency", field: "currency", },
                    { title: "valueType", field: 'valueType', },
                    { title: "Display %", field: 'display', },
                    { title: "TaxGroup", field: 'taxGroup', },
                    { title: "TaxGroup", field: 'taxGroup2' }
                ],
                path: ["sno", "component", "value", "currency", "valueType", "display%", "taxGroup", "taxGroup"],
                dataType: [
                    { type: ["increment"], name: "sno" },
                    { type: ["text"], name: "component" },
                    { type: ["text"], name: "value" },
                    { type: ["text"], name: "currency" },
                    { type: ["text"], name: "valueType" },
                    { type: ["text"], name: "display" },
                    { type: ["text"], name: "taxGroup" },
                    { type: ["text"], name: "taxGroup" },
                ],
            },
            query: GET_UNIT_RENT_BREAKUP,
            unitId: unitId
        },
        {
            id: 1,
            title: "Unit Vacancy Period",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Available From", field: 'availableFrom', },
                    { title: "Available To", field: 'availableTo', },

                ],
                path: ["sno", "availableFrom", "availableTo"],
                dataType: [
                    { type: ["increment"], name: "sno" },
                    { type: ["text"], name: "availableFrom" },
                    { type: ["text"], name: "availableTo" },
                ],
            },
            query: GET_UNIT_VACANCY_PERIOD,
            unitId: unitId
        },
        {
            id: 2,
            title: "Unit Amenities",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Amenities Type", field: 'amenitiesType', },
                    { title: "Name", field: 'name', },
                    { title: "Notes", field: 'notes', },

                ],
                path: ["sno", "amenitiesType", "name", "notes"],
                dataType: [
                    { type: ["increment"], name: "sno" },
                    { type: ["text"], name: "amenitiesType" },
                    { type: ["text"], name: "name" },
                    { type: ["text"], name: "notes" },
                ],
            },
            query: GET_UNIT_AMENITIES_BREAKUP,
            unitId: unitId
        },
        {
            id: 3,
            title: "Unit Utilities",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Utilities", field: 'utilites', },
                    { title: "Meter Serial No", field: 'meterSerialNo', },
                    { title: "Meter Point Reference", field: 'meterPointReference', },
                    { title: "Updated Date", field: 'updatedDate', },
                    { title: "Description", field: 'description', },

                ],
                path: ["sno", "utilites", "meterSerialNo", "meterPointReference", "updatedDate", "description"],
                dataType: [
                    { type: ["increment"], name: "sno" },
                    { type: ["text"], name: "utilites" },
                    { type: ["text"], name: "meterSerialNo" },
                    { type: ["text"], name: "meterPointReference" },
                    { type: ["text"], name: "updatedDate" },
                    { type: ["text"], name: "description" },
                ],
            },
            query: GET_UNIT_UTILITIES,
            unitId: unitId
        },
        {
            id: 4,
            title: "Rooms",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Unit Name ", field: 'unitName', },
                    { title: "Parent Name", field: 'parentName', },
                    { title: "Unit Type", field: 'unitType', },
                    { title: "Carpet Area", field: 'carpetArea', },
                    { title: "Furnish Type", field: 'furnishType', },
                    { title: "Unit Purpose ", field: 'unitPurpose', },
                    { title: "Current Status", field: 'currentStatus', },
                    { title: "", field: 'status', },

                ],
                path: ["sno", "unitName", "parentName", "unitType", "carpetArea", "furnishType", "unitPurpose", "currentStatus", "status"],
                dataType: [
                    { type: ["increment"], name: "sno" },
                    { type: ["text"], name: "unitName" },
                    { type: ["text"], name: "parentName" },
                    { type: ["text"], name: "unitType" },
                    { type: ["text"], name: "carpetArea" },
                    { type: ["text"], name: "furnishType" },
                    { type: ["text"], name: "unitPurpose" },
                    { type: ["text"], name: "currentStatus" },
                    { type: ["status"], name: "status" },
                ],
            },
            query: GET_ROOMS,
            unitId: unitId
        },
        {
            id: 5,
            title: "Unit Tenants",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Unit Name ", field: 'unitName', },
                    { title: "Parent Name", field: 'parentName', },
                    { title: "Unit Type", field: 'unitType', },
                    { title: "Carpet Area", field: 'carpetArea', },
                    { title: "Furnish Type", field: 'furnishType', },
                    { title: "Unit Purpose ", field: 'unitPurpose', },
                    { title: "Current Status", field: 'currentStatus', },
                    { title: "", field: 'status', },

                ],
                path: ["sno", "unitName", "parentName", "unitType", "carpetArea", "furnishType", "unitPurpose", "currentStatus", "status"],
                dataType: [
                    { type: ["increment"], name: "sno" },
                    { type: ["text"], name: "unitName" },
                    { type: ["text"], name: "parentName" },
                    { type: ["text"], name: "unitType" },
                    { type: ["text"], name: "carpetArea" },
                    { type: ["text"], name: "furnishType" },
                    { type: ["text"], name: "unitPurpose" },
                    { type: ["text"], name: "currentStatus" },
                    { type: ["status"], name: "status" },
                ],
            },
            query: GET_ROOMS,
            unitId: unitId
        },
        {
            id: 6,
            title: "Unit General And Maintenance Requests",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Request Id ", field: 'requestId', },
                    { title: "Request Name", field: 'requestName', },
                    { title: "Raised Date", field: 'raisedDate', },
                    { title: "Category", field: 'category', },
                    { title: "Status", field: 'status', },
                    { title: "description ", field: 'description', },

                ],
                path: ["sno", "requestId", "requestName", "raisedDate", "category", "status", "description",],
                dataType: [
                    { type: ["increment"], name: "sno" },
                    { type: ["text"], name: "requestId" },
                    { type: ["text"], name: "requestName" },
                    { type: ["text"], name: "raisedDate" },
                    { type: ["text"], name: "category" },
                    { type: ["status"], name: "status" },
                    { type: ["text"], name: "description" },

                ],
            },
            api: "/unit-masters/request-units",
            unitId: unitId
        },
        {
            id: 7,
            title: "Security Requests",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Request Id ", field: 'requestId', },
                    { title: "Parent Name", field: 'requestName', },
                    { title: "Category", field: 'category', },
                    { title: "Raised Date", field: 'raisedDate', },
                    { title: "Status", field: 'status', },
                    { title: "Description", field: 'description', },

                ],
                path: ["sno", "requestId", "requestName", "category", "raisedDate", "status", "description",],
                dataType: [
                    { type: ["increment"], name: "sno" },
                    { type: ["text"], name: "requestId" },
                    { type: ["text"], name: "requestName" },
                    { type: ["text"], name: "category" },
                    { type: ["text"], name: "raisedDate" },
                    { type: ["status"], name: "status" },
                    { type: ["text"], name: "description" },

                ],
            },
            query: GET_SECURITY_REQUEST,
            unitId: unitId
        },
        {
            id: 8,
            title: "Unit Owners",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Owner Name ", field: 'ownerName', },
                    { title: "Period From", field: 'periodFrom', },
                    { title: "Period To", field: 'periodTo', },
                    { title: "Contact", field: 'contact', },
                    { title: "emailAddress", field: 'emailAddress', },


                ],
                path: ["sno", "ownerName", "periodFrom", "periodTo", "contact", "emailAddress"],
                dataType: [
                    { type: ["increment"], name: "sno" },
                    { type: ["text"], name: "ownerName" },
                    { type: ["text"], name: "periodFrom" },
                    { type: ["text"], name: "periodTo" },
                    { type: ["text"], name: "contact" },
                    { type: ["text"], name: "emailAddress" },

                ],
            },
            query: GET_UNIT_OWNER,
            unitId: unitId
        },
        {
            id: 9,
            title: "Unit Agreements",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Agreement No ", field: 'agreementNo', },
                    { title: "Agreement primary contact name ", field: 'agreementPrimaryContactName', },
                    { title: "Contact No", field: 'contact', },
                    { title: "email Id", field: 'emailAddress', },
                    { title: " ", field: 'icon', },


                ],
                path: ["sno", "agreementNo", "agreementPrimaryContactName", "contact", "emailAddress",],
                dataType: [
                    { type: ["increment"], name: "sno" },
                    { type: ["text"], name: "agreementNo" },
                    { type: ["text"], name: "agreementPrimaryContactName" },
                    { type: ["text"], name: "contact" },
                    { type: ["text"], name: "emailAddress" },
                    { type: ["icon"], name: "icon" },

                ],

            },
            query: GET_UNIT_AGREEMENT,
            unitId: unitId
        },
        {
            id: 10,
            title: "Unit Invoices",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Invoice Id ", field: 'invoiceId', },
                    { title: "Invoice Type ", field: 'invoiceType', },
                    { title: "Invoice Total", field: 'invoiceTotal', },
                    { title: "Invoice Status", field: 'invoiceStatus', },
                    { title: " ", field: 'icon', },


                ],
                path: ["sno", "invoiceId", "invoiceType", "invoiceTotal", "invoiceStatus",],
                dataType: [
                    { type: ["increment"], name: "sno" },
                    { type: ["text"], name: "invoiceId" },
                    { type: ["text"], name: "invoiceType" },
                    { type: ["text"], name: "invoiceTotal" },
                    { type: ["status"], name: "invoiceStatus" },
                    { type: ["icon"], name: "icon" },

                ],

            },
            query: GET_UNIT_INVOICE,
            unitId: unitId
        },
    ]

    return <div className={classes.root}>
        <Grid container spacing={2}>
            <Grid item xs={4} >
                {unitDetailsData.map((item) => (
                    <>
                        <Details data={item} color={true} unitTable={true} assets={assestData?.assets} />
                    </>
                )
                )}
            </Grid>
            <Grid item xs={8} >
                <div
                    style={{
                        height: size.height - 88,
                        overflow: "scroll",
                    }}
                >
                    <Grid conatiner>
                        {accordionData.map((item) => (
                            <>
                                <Grid item xs={12} marginTop={2}>
                                    <AccordionWithTable data={item} />
                                </Grid>
                            </>
                        )
                        )}
                    </Grid>
                </div>
            </Grid>
        </Grid>

    </div>
}