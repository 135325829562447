import { Grid, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Bold, SemiBold } from "../../utils";

const useStyles = makeStyles((theme) => ({
    sliderStyle: {
        margin: "0px 10px 0px 10px",
        // "&.css-1x4aylg-MuiSlider-root": {
        //     "padding": "0px",
        //     "border-radius": "155px",
        //     "height": "8px",
        // },
        // "&.css-14pt78w-MuiSlider-rail": {
        //     "background-color": "#f2f4f7",
        //     "opacity": "1",
        // },
        // "&.css-1gv0vcd-MuiSlider-track": {
        //     "background-color": "#5078E1",
        //     "opacity": "1",
        // },
        // "&.css-1ru8k1j-MuiSlider-thumb": {
        //     "border": "2px solid white"
        // },
        title: {
            fontFamily: Bold,
            fontSize: "12px",
            color: theme.typography.color.secondary,
        },
        subTitle: {
            fontFamily: SemiBold,
            fontSize: "16px",
            color: theme.typography.color.primary,
        },
        sliderStyle: {
            "& .MuiSlider-thumb": {
                border: "2px solid white",
            },
            "& .MuiSlider-track": {
                // backgroundColor: "white"
            },
        },
    },
}));

export const RangeSlider = (props) => {
    const classes = useStyles(props);

    const handleChange = (event, newValue, activeThumb) => {
        props.onChange(newValue);
    };

    return (
        <Grid container direction={"column"}>
            <Typography>{props.title}</Typography>
            <Slider
                className={classes.sliderStyle}
                getAriaLabel={() => "Minimum distance shift"}
                value={props?.value}
                valueLabelDisplay="auto"
                onChange={handleChange}
                disableSwap
                max={props?.maxValue}
            />
        </Grid>
    );
};
