import React, { useState } from "react";
import { AddContactStyles } from "./styles";
import { Grid, Box } from "@mui/material";
import { SettingList } from "../../companyCreation/components";
import { AddExistContacts, AddExistAccount } from "../component";
import { LeadDetailsnew } from "./newContact";
import { Subheader } from "../../../components";
import { CreateQuotationListContext } from "../../../contexts/createQuotationContext";
export const AddQutation = () => {
  const classes = AddContactStyles();
  const { dataNew } = React.useContext(CreateQuotationListContext);
  //   states
  const [selected, setSelected] = useState(1);
  //   tab list
  const tablist = [
    {
      label: "Add Existing Contact",
      value: 1,
    },
    {
      label: "Add New Contact",
      value: 2,
    },
    {
      label: "Add Existing Account",
      value: 3,
    },
  ];
  const selectedFunction = (val) => {
    if (dataNew?.existing && val?.value === 2) {
    } else {
      setSelected(val);
    }
  };
  //Initial load
  React.useEffect(() => {
    setSelected(tablist[0]);
    // eslint-disable-next-line
  }, []);
  const component = {
    1: <AddExistContacts />,
    2: (
      <>
        <Subheader hideBackButton title={"Add New Contact"} />
        <Box className={classes.root}>
          <LeadDetailsnew />
        </Box>
      </>
    ),
    3: <AddExistAccount />,
  };
  return (
    <Box className={classes.root}>
      <Grid container spacing={1.5}>
        {/* tab */}
        <Grid item xs={2}>
          <Box className={classes.content}>
            <SettingList
              list={tablist}
              selected={selected?.value}
              setSelected={selectedFunction}
            />
          </Box>
        </Grid>
        {/* contant */}
        <Grid item xs={10}>
          <Box className={classes.content}>{component[selected?.value]}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};
