import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Label, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { useStyles } from "../style";
import { Bold } from "../../../utils";

// tool tip
const Tip = ({ setShowTooltip, ...rest }) => {
    const classes = useStyles();
    const [payload, setPayload] = React.useState(rest?.payload);

    React.useEffect(() => {
        rest?.payload?.length && setPayload(rest?.payload);
    }, [rest?.payload]);

    return payload?.length ? (
        <div
            onMouseLeave={() => setShowTooltip(false)}
            onMouseMove={(e) => e.stopPropagation()}
            className={classes.tooltip}
        >
            <Box
                className={classes.dot}
                style={{ backgroundColor: payload?.[0]?.payload?.fill }}
            />
            <Typography className={classes.value}>{payload?.[0]?.value}</Typography>
            <Typography className={classes.name}>{payload?.[0]?.name}</Typography>
        </div>
    ) : null;
};
export const TimeOffPieChart = ({ t,resourceData = {}, consumedLeave = {}, result = [], type = "PTO", onChangeType = () => false }) => {
    const classes = useStyles()
    const [showTooltip, setShowTooltip] = React.useState(false);
    const CustomLabel = ({ viewBox, labelText, value ,labelText1}) => {
        const { cx, cy } = viewBox;
        return (
            <g>
                <text
                    x={cx}
                    // y={cy}
                    className="recharts-text recharts-label"
                    textAnchor="middle"
                    y={cy -15}
                    dominantBaseline="central"
                    alignmentBaseline="middle"
                    fontSize="13px"
                    fill="#4E5A6B"
                    fontFamily={Bold}

                >
                    {value}
                </text>
          
                <text
                    x={cx}
                    y={cy}
                    className="recharts-text recharts-label"
                    textAnchor="middle"
                    dominantBaseline="central"
                    alignmentBaseline="middle"
                    fill="#4E5A6B"
                    fontSize="13"
                    fontFamily={Bold}

                >
                    {labelText}
                </text>
                <text
                    x={cx}
                    y={cy + 15}
                    className="recharts-text recharts-label"
                    textAnchor="middle"
                    dominantBaseline="central"
                    alignmentBaseline="middle"
                    fill="#4E5A6B"
                    fontSize="13"
                    fontFamily={Bold}

                >
                    {labelText1}
                </text>
        
            </g>
        );
    };

    return (
        <Box>
            <Box mt={1}>
                <Typography className={classes.statsTitle}>{t("currentYearStatistics")}</Typography>
            </Box>

            <Box className={classes.chartSection} mt={1}>
                <center>
                    <Box className={classes.type} mt={1}>
                        <Stack direction="row" spacing={1}>
                            <Box className={type === "PTO" ? classes.typebtn : classes.nobtn} onClick={() => onChangeType("PTO")}>
                                <Typography className={type === "PTO" ? classes.textcontd : classes.textcont}>Paid Time Off</Typography>
                            </Box>
                            <Box className={type === "LOP" ? classes.typebtn : classes.nobtn2} onClick={() => onChangeType("LOP")}>
                                <Typography className={type === "LOP" ? classes.textcontd : classes.textcont}>Loss of Pay</Typography>
                            </Box>
                        </Stack>
                    </Box>
                </center>
                <Box display="flex" justifyContent={"center"} position={"relative"}>
                    <ResponsiveContainer
                        width={200}
                        height={170}
                    >
                        <PieChart width={50} height={50} className={classes.chart} onMouseLeave={() => setShowTooltip(false)}>
                            <Pie
                                onMouseEnter={() => setShowTooltip(result[0]?.nodata?false:true)}
                                data={result}
                                cx="50%"
                                cy="50%"
                                outerRadius={80}
                                innerRadius={60}
                                dataKey="value"
                            >
                                <Label
                                    content={
                                        <CustomLabel
                                            labelText={"Consumed"}
                                            labelText1={"Leave"}
                                            value={
                                                consumedLeave
                                            }
                                        />}
                                    position="center"
                                />
                            </Pie>
                            {showTooltip && (
                                <Tooltip
                                    // Anymation is a bit weird when the tooltip shows up after hidding
                                    isAnimationActive={false}
                                    // Send props down to get the payload
                                    content={<Tip setShowTooltip={setShowTooltip} />}
                                    // We need this to manage visibility ourselves
                                    wrapperStyle={{ visibility: "visible", pointerEvents: "auto" }}
                                />
                            )}

                        </PieChart>
                    </ResponsiveContainer>
                </Box>
            </Box>
        </Box>
    )
}