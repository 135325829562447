import makeStyles from '@mui/styles/makeStyles';
import { SemiBold } from '../../utils';

export const useStyles = makeStyles((theme) => ({
    Label: {
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
        fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
        marginBottom: (props) => props?.labelMarginBottom ?? "5px",
    },
    text: {

    },
    required: {
      color: "red",
      fontFamily: SemiBold,
      fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
      marginBottom: (props) => props?.labelMarginBottom ?? "5px",
    },

    textbox: {

        [`& fieldset`]: {
            borderRadius: theme.palette.borderRadius,
            height: (props) => props.multiline ? "unset" : 50,
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #5078E1",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: (props) => props?.padding ?? "11px 14px",
        },

    },

}));