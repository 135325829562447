import React from 'react';
import { ToggleButtonComponent } from '../../toggleButton';
import { CustomTypography } from '../customTypography';
import useTheme from '@mui/styles/useTheme';
import { Bold } from '../../../utils';

export const ToggleButton = (props) => {

    const {
        label = "",
        options = [],
        value,
        onChange = null,
        isMulti,
        fullWidth = false,
        buttonStyle = {},
        buttonGroupStyle = {},
        error = false,
        errorText = "",
        required = false,
        labelColor="",
        labelSize="",
        fontFamily="Bold"
    } = props;

    const theme = useTheme();

    return (
        <div>
            <ToggleButtonComponent
                label={label}
                isrequired={required}
                options={options}
                value={value}
                onChange={(value) => onChange(value)}
                isMulti={isMulti}
                fullWidth={fullWidth}
                buttonStyle={{ ...buttonStyle }}
                buttonGroupStyle={{ ...buttonGroupStyle }}
                labelColor={labelColor}
                labelSize={labelSize}
                fontFamily={fontFamily}
            />
            {error &&
                <CustomTypography fontFamily={Bold} marginTop={theme.spacing(1)} color={theme?.palette?.error?.main} fontSize={12}>{errorText}</CustomTypography>
            }
        </div>
    )
}