import React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Select, { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import SerachIMG from "../../../assests/search";
import { CustomTypography } from '../customTypography';
import { TickIcon } from '../../../assests';
import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";
import CloseIcon from '@mui/icons-material/Close';
import { Bold, Regular, SemiBold } from "../../../../../utils";

const Wrapper = styled(Stack)(({ theme }) => ({
    padding: 3,
    paddingLeft: theme.spacing(1),
    backgroundColor: "#F5F7FA",
    borderRadius: 4,
    minWidth: 100,
}));

const Control = ({ children, ...props }) => {
    return (
        <components.Control {...props}>
            <SerachIMG color={"#98A0AC"} />
            {children}
        </components.Control>
    );
};

const Option = ({ children, ...props }) => {
    return (
        <components.Option {...props}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                {children}
                {props?.isSelected && <TickIcon />}
            </Stack>
        </components.Option>
    );
};

export const CustomSelect = React.memo((props) => {

    const {
        key,
        value,
        placeholder,
        loadOptions,
        onChange,
        options,
        loading,
        isReadOnly,
        isMulti,
        debounceTimeout,
        reduceOptions,
        isClearable = true,
        styles = {},
        isPaginate = false,
        label = "",
        error = false,
        errorText = "",
        required = false
    } = props;

    const theme = useTheme();

    const customStyles = (props) => {
        return {
            control: (base) => ({
                ...base,
                borderColor: "#E4E8EE",
                borderRadius: 4,
                paddingLeft: 8,
                '&:not(:focus)': {
                    boxShadow: "none"
                },
                '&:hover': {
                    borderColor: theme.palette.primary.main,
                },
                ...styles?.control ?? {},
            }),
            valueContainer: (base) => ({
                ...base,
                paddingBlock: 3
            }),
            placeholder: defaultStyles => {
                return {
                    ...defaultStyles,
                    fontFamily: Regular,
                    fontSize: 14,
                    whiteSpace: "nowrap",
                    color: "#98A0AC"
                };
            },
            menu: defaultStyles => {
                return {
                    ...defaultStyles,
                    zIndex: 2,
                    boxShadow: "0px 10px 25px #0000000A",
                    border: "1px solid #E4E8EE",
                    borderRadius: 4
                };
            },
            option: (defaultStyles, prop) => {
                return {
                    ...defaultStyles,
                    fontFamily: SemiBold,
                    fontSize: 14,
                    cursor: "pointer",
                    ...isSingleStyle(prop),
                };
            },
        }
    };

    const isSingleStyle = (prop) => {
        if (prop.isMulti) return {}
        return {
            color: prop?.isSelected ? "#5078E1" : "#091B29",
            backgroundColor: "transparent",
            '&:hover,&:active': {
                backgroundColor: "transparent",
                color: "#5078E1"
            },
        }
    }

    const onClear = (val) => {
        let updateValue = value?.filter(({ value }) => value !== val.value);
        if (props?.isDeletedValue) {
            props?.deletedValue(val, updateValue)
        }
        else {
            props?.onChange(updateValue)
        }
    }


    return (
        <>
            {label &&
                <CustomTypography color={props?.color ?? ""} fontSize={props?.fontSize ?? ""} fontWeight={props?.fontWeight} fontFamily={Bold} marginBottom={props?.marginBottom ?? 16}>
                    {label}
                    {required && <CustomTypography component={"span"} marginLeft={theme.spacing(1)} color={theme.palette.error.main} fontFamily={Bold}>*</CustomTypography>}
                </CustomTypography>
            }
            {
                isPaginate ? (
                    <AsyncPaginate
                        key={key}
                        isClearable={isClearable}
                        isSearchable
                        components={{
                            IndicatorSeparator: () => null,
                            Control,
                            Option,
                            MultiValueContainer: () => null,
                        }}
                        value={value}
                        placeholder={placeholder}
                        loadOptions={loadOptions}
                        onChange={onChange}
                        options={options}
                        isLoading={loading}
                        defaultOptions={options}
                        styles={customStyles(props)}
                        isDisabled={isReadOnly}
                        isMulti={isMulti}
                        debounceTimeout={debounceTimeout}
                        reduceOptions={reduceOptions}
                    />
                ) : (
                    <Select
                        //isClearable={isClearable}
                        isSearchable
                        components={{
                            IndicatorSeparator: () => null,
                            Control,
                            Option,
                            MultiValueContainer: () => null,
                        }}
                        value={value}
                        placeholder={placeholder}
                        options={options}
                        isLoading={loading}
                        onChange={onChange}
                        styles={customStyles(props)}
                        isMulti={isMulti}
                        isDisabled={isReadOnly}

                    />
                )
            }
            {error &&
                <CustomTypography fontFamily={SemiBold} marginTop={theme.spacing(1)} color={theme?.palette?.error?.main} fontSize={13} fontWeight={500}>{errorText}</CustomTypography>
            }
            {
                (isMulti && value?.length > 0) &&
                <Stack
                    direction="row"
                    alignItems={"center"}
                    gap={1}
                    flexWrap={"wrap"}
                    marginTop={2}
                >
                    {
                        value?.map((_) => (
                            <Wrapper
                                direction={"row"}
                                alignItems={"center"}
                                key={_?.value}
                                gap={1}
                            >
                                <CustomTypography
                                    color={"#4E5A6B"}
                                    fontSize={12}
                                    fontFamily={SemiBold}
                                    sx={{ flex: 1 }}
                                >
                                    {_?.label}
                                </CustomTypography>
                                <div
                                    onClick={() => onClear(_)}
                                    style={{ display: 'flex', cursor: "pointer" }}
                                >
                                    <IconButton size="small">
                                        <CloseIcon htmlColor="#7C8594" fontSize={"12px"} />
                                    </IconButton>
                                </div>
                            </Wrapper>
                        ))
                    }
                </Stack>
            }
        </>

    )
})
