
export const WaitingForApprovalPath = ["visitor_worker_image", "request_type", "date", "reference_id", "visitor_worker_name", "unit_id", "visitors_workers", "stay_type", "entry_gate", "start_date", "end_date"];

export const WaitingForApprovalHeading = [
    { title: "Image", field: "visitor_worker_image" },
    { title: "Request Type", field: "request_type" },
    { title: "Date", field: "date" },
    { title: "Reference ID", field: "reference_id" },
    { title: "Visitor/Worker Name", field: "visitor_worker_name" },
    { title: "Unit ID", field: "unit_id" },
    { title: "Visitors/Workers", field: "visitors_workers" },
    { title: "Stay Type", field: "stay_type" },
    { title: "Entry Gate", field: "entry_gate" },
    { title: "Start Date", field: "start_date" },
    { title: "End Date", field: "end_date" },
];
export const WaitingForApprovalType = [
    { type: ["logo"], name: "visitor_worker_image" },
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "visitor_worker_name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "visitors_workers" },
    { type: ["text"], name: "stay_type" },
    { type: ["text"], name: "entry_gate" },
    { type: ["text"], name: "start_date" },
    { type: ["text"], name: "end_date" },
];


export const ApprovedPath = ["visitor_worker_image", "request_type", "date", "reference_id", "visitor_worker_name", "unit_id", "visitors_workers", "stay_type", "entry_gate", "start_date", "end_date"];

export const ApprovedHeading = [
    { title: "Image", field: "visitor_worker_image" },
    { title: "Request Type", field: "request_type" },
    { title: "Date", field: "date" },
    { title: "Reference ID", field: "reference_id" },
    { title: "Visitor/Worker Name", field: "visitor_worker_name" },
    { title: "Unit ID", field: "unit_id" },
    { title: "Visitors/Workers", field: "visitors_workers" },
    { title: "Stay Type", field: "stay_type" },
    { title: "Entry Gate", field: "entry_gate" },
    { title: "Start Date", field: "start_date" },
    { title: "End Date", field: "end_date" },
];
export const ApprovedType = [
    { type: ["logo"], name: "visitor_worker_image" },
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "visitor_worker_name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "visitors_workers" },
    { type: ["text"], name: "stay_type" },
    { type: ["text"], name: "entry_gate" },
    { type: ["text"], name: "start_date" },
    { type: ["text"], name: "end_date" },
];

export const DeclinedPath = ["visitor_worker_image", "request_type", "date", "reference_id", "visitor_worker_name", "unit_id", "visitors_workers", "stay_type", "entry_gate", "start_date", "end_date"];

export const DeclinedHeading = [
    { title: "Image", field: "visitor_worker_image" },
    { title: "Request Type", field: "request_type" },
    { title: "Date", field: "date" },
    { title: "Reference ID", field: "reference_id" },
    { title: "Visitor/Worker Name", field: "visitor_worker_name" },
    { title: "Unit ID", field: "unit_id" },
    { title: "Visitors/Workers", field: "visitors_workers" },
    { title: "Stay Type", field: "stay_type" },
    { title: "Entry Gate", field: "entry_gate" },
    { title: "Start Date", field: "start_date" },
    { title: "End Date", field: "end_date" },
];
export const DeclinedType = [
    { type: ["logo"], name: "visitor_worker_image" },
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "visitor_worker_name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "visitors_workers" },
    { type: ["text"], name: "stay_type" },
    { type: ["text"], name: "entry_gate" },
    { type: ["text"], name: "start_date" },
    { type: ["text"], name: "end_date" },
];

export const EntriesPath = ["visitor_worker_image", "request_type", "date", "reference_id", "visitor_worker_name", "unit_id", "stay_type", "entry_gate", "check_in", "check_out", "status"];

export const EntriesHeading = [
    { title: "Image", field: "visitor_worker_image" },
    { title: "Request Type", field: "request_type" },
    { title: "Date", field: "date" },
    { title: "Reference ID", field: "reference_id" },
    { title: "Visitor/Worker Name", field: "visitor_worker_name" },
    { title: "Unit ID", field: "unit_id" },
    { title: "Stay Type", field: "stay_type" },
    { title: "Entry Gate", field: "entry_gate" },
    { title: "Check In", field: "check_in" },
    { title: "Check Out", field: "check_out" },
    { title: "Status", field: "status" },
];
export const EntriesType = [
    { type: ["logo"], name: "visitor_worker_image" },
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "visitor_worker_name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "stay_type" },
    { type: ["text"], name: "entry_gate" },
    { type: ["text"], name: "check_in" },
    { type: ["text"], name: "check_out" },
    { type: ["text"], name: "status" },
];