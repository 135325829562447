export const resourceTypes = {
    tools: "Tools",
    vehicle: "Vehicles",
    account: "Account",
    users: "Users",
    generic: "Generic"
}

//Resource Master
export const ResourceMasterHeading = [
    { title: "Picture", field: "url" },
    { title: "Resource Id", field: "reference_id" },
    { title: "Department", field: "department" },
    // { title: "Resource Type", field: "type" },
    { title: "Resource Name", field: "name" },
    { title: "Module", field: "module" },
    // { title: "Contact Id", field: "user_no" },
    // { title: "Account Id", field: "account_no" },
    // { title: "Tool Id", field: "tool_id" },
    // { title: "Vehicle Id", field: "vehicle_id" },
    { title: "Working Calendar", field: "working_hour" },
    { title: "Reporting To", field: "reporting_to" },
    { title: "", field: "icon" },
];
export const ResourceMasterListPath = [
    "reference_id",
    "type",
    "name",
    "module",
    "account_no",
    "user_no",
    "tool_id",
    "vehicle_id",
    "working_hour",
    "reporting_to",
    "icon"
];
export const ResourceMasterDataType = [
    { type: ["avatarmanagement"], name: "url" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "department" },
    //{ type: ["text"], name: "type" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "module" },
    //{ type: ["text"], name: "user_no" },
    //{ type: ["text"], name: "account_no" },
    //{ type: ["text"], name: "tool_id" },
    //{ type: ["text"], name: "vehicle_id" },
    { type: ["text"], name: "working_hour" },
    { type: ["text"], name: "reporting_to" },
    { type: ["view_more"], name: "icon" },
];
export const initialState = {
    period: "",
    tool: "",
    module: "",
    vehicles: "",
    working_hour: "",
    user: "",
    reporting_to: "",
    rate_hour: "",
    daily_rate: "",
    currency: "",
    name: "",
    account: "",
    isEdit: false,
    id: "",
    assets: [],
    type: "",
    department: "",
    job: "",
    vehicles_no: "",
    vehicle_inventory_id: null,
    tool_inventory_id: null,
    leave: [],
    error: {
        period: "",
        tool: "",
        module: "",
        vehicles: "",
        working_hour: "",
        user: "",
        reporting_to: "",
        rate_hour: "",
        daily_rate: "",
        currency: "",
        name: "",
        account: "",
        department: "",
        job: "",
        vehicles_no: ""
    }

}
export const returnEditPayload = (data) => {


    const result = {
        vehicle_inventory_id: data?.vehicle_inventories?.id,
        tool_inventory_id: data?.tool_inventories?.id,
        period: {
            label: data?.period,
            value: data?.period
        },
        tool: data?.tool_inventories?.tools ?? "",
        module: data?.module,
        vehicles: data?.vehicle_inventories?.vehicles,
        working_hour: data?.hours ?
            {
                label: data?.hours?.lable,
                value: data?.hours?.value,
            } : "",
        user: data?.contact?.value,
        reporting_to: data?.reporting,
        rate_hour: data?.rate_per_hour,
        daily_rate: data?.daily_rate ?? "",
        currency: data?.currency ?? "",
        name: data?.resource_name ?? "",
        account: data?.account?.value,
        isEdit: true,
        id: data?.id,
        assets: data?.assets ?? [],
        type: {
            label: data?.type_master?.name,
            value: data?.type_master?.id,
        },
        department: data?.departments ?? "",
        job: data?.jobs ?? "",
        vehicles_no: data?.vehicle_inventories?.serial_number ? data?.vehicle_inventories?.serial_number : data?.tool_inventories?.serial_number ? data?.tool_inventories?.serial_number : "",
        leave: data?.leaves?.map((val) => {
            return {
                is_active: val?.is_active,
                id: val?.id,
                type: {
                    value: val?.leave_master_id,
                    label: val?.name
                },
                days: val?.days_count
            }
        }),
        error: {
            period: "",
            tool: "",
            module: "",
            vehicles: "",
            working_hour: "",
            user: "",
            reporting_to: "",
            rate_hour: "",
            daily_rate: "",
            currency: "",
            name: "",
            account: "",
            department: "",
            job: "",
            assets: "",
            vehicles_no: ""
        }

    }
    return result;
}

export const returnSavePayload = (data, types, company, assets, type) => {

    switch (types) {
        case "Tools":
            let result1 = {
                primary_rate: data?.tool?.rate ? parseInt(data?.tool?.rate) : undefined,
                currency_id: company?.currency_id,
                company_id: company?.value,
                resource_type: data?.isEdit ? undefined : type?.value,
                assets: assets,
                tool: data?.tool?.length !== 0 ? data?.tool?.value : undefined,
                id: data?.id?.length > 0 ? data?.id : undefined,
                department_id: data?.department?.value ?? undefined,
                period: data?.period?.value ?? undefined,
                serial_number: data?.vehicles_no?.length !== 0 ? data?.vehicles_no : undefined,
                tool_inventory_id: data?.tool_inventory_id ?? undefined
            }
            return result1

        case "Vehicles":
            let result2 = {
                primary_rate: data?.vehicles?.rate ? parseInt(data?.vehicles?.rate) : undefined,
                currency_id: company?.currency_id,
                company_id: company?.value,
                resource_type: data?.isEdit ? undefined : type?.value,
                assets: assets,
                vehicle: data?.vehicles?.length !== 0 ? data?.vehicles?.value : undefined,
                department_id: data?.department?.value ?? undefined,
                vehicle_no: data?.vehicles_no?.length !== 0 ? data?.vehicles_no : undefined,
                period: data?.period?.value ?? undefined,
                id: data?.id?.length > 0 ? data?.id : undefined,
                vehicle_inventory_id: data?.vehicle_inventory_id ?? undefined
            }
            return result2

        case "Generic":
            let result3 = {
                currency_id: company?.currency_id,
                company_id: company?.value,
                resource_type: data?.isEdit ? undefined : type?.value,
                name: data?.name,
                assets: assets,
                department_id: data?.department?.value ?? undefined,
                id: data?.id?.length > 0 ? data?.id : undefined,
                primary_rate: data?.rate_hour?.length !== 0 ? parseInt(data?.rate_hour) : undefined,
            }
            return result3

        case "Users":
            let result4 = {
                module_id: data?.module?.length !== 0 ? data?.module?.value : undefined,
                contact_id: data?.user?.length !== 0 ? data?.user : undefined,
                working_hours: data?.working_hour?.length !== 0 ? data?.working_hour?.value : undefined,
                reporting_to: data?.reporting_to?.length !== 0 ? data?.reporting_to?.value : undefined,
                primary_rate: data?.rate_hour?.length !== 0 ? parseInt(data?.rate_hour) : undefined,
                currency_id: company?.currency_id,
                company_id: company?.value,
                secondary_rate: data?.daily_rate?.length !== 0 ? parseInt(data?.daily_rate) : undefined,
                resource_type: data?.isEdit ? undefined : type?.value,
                assets: assets,
                id: data?.id?.length > 0 ? data?.id : undefined,
                job_id: data?.job?.value ?? undefined,
                leaves: data?.id?.length > 0 ? data?.leave?.map((val) => {
                    return {
                        leave_master_id: val?.type?.value,
                        days_count: val?.days,
                        id: val?.id ?? undefined,
                        is_active: val?.is_active
                    }
                }) : data?.leave?.filter(i => i?.is_active).map((val) => {
                    return {
                        leave_master_id: val?.type?.value,
                        days_count: val?.days,
                        id: val?.id ?? undefined
                    }
                })
            }
            return result4;

        case "Account":
            let result5 = {
                module_id: data?.module?.length !== 0 ? data?.module?.value : undefined,
                working_hours: data?.working_hour?.length !== 0 ? data?.working_hour?.value : undefined,
                reporting_to: data?.reporting_to?.length !== 0 ? data?.reporting_to?.value : undefined,
                primary_rate: data?.rate_hour?.length !== 0 ? parseInt(data?.rate_hour) : undefined,
                currency_id: company?.currency_id,
                company_id: company?.value,
                secondary_rate: data?.daily_rate?.length !== 0 ? parseInt(data?.daily_rate) : undefined,
                resource_type: data?.isEdit ? undefined : type?.value,
                assets: assets,
                account_id: data?.account?.length !== 0 ? data?.account : undefined,
                id: data?.id?.length > 0 ? data?.id : undefined,
                department_id: data?.department?.value ?? undefined,
            }
            return result5;

        default:
            return null
    }
}

