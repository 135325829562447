import React from "react";
import { PricingApprialsComponent } from "../../../components";
import { Grid } from "@mui/material";

export const ComponentType = (props) => {
  const { list, handleChange,selectedComponent} = props;
  return (
    <div>
      <Grid container spacing={2}>
        {list?.map((item , index) => {
          return (
            <Grid item xs={12}>
              <PricingApprialsComponent
                component="ComponentType"
                data={item}
                handleChange={handleChange}
                info={true}
                index={index}
                selectedComponent={selectedComponent}
                borderColor={item?.id === selectedComponent ? true : false}
                backgroundcolor={item?.secondaryColor}
                bordercolor={item?.primaryColor}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
