import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const useStyles = makeStyles((backgroundColor, props) => ({
    root: {
        padding: "12px",
        border: (props) => props?.border ? "none" : "1px solid #E4E8EE",
        borderRadius: "4px",
        backgroundColor: "#fff"
    },
    heading: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: " #98A0AC"
    },
    sub: {
        fontSize: "14px",
        fontFamily: SemiBold,
        color: "#091B29",
        marginTop: "6px"
    },
    status: {
        fontSize: "12px",
        fontFamily: Bold,
        padding: "2px 10px",
        borderRadius: "2px",
        display: "inline-block",
        marginTop: "6px",
        marginLeft: "-8px"
    },
    btn: {
        padding: "0px",
        boxShadow: "none",
        marginTop: "6px",
        '&:hover': {
            backgroundColor: "white",
        }
    },
    title: {
        fontSize: "12px",
        fontFamily: Bold,
        color: "#4E5A6B",
    },
    primary_button: {
        padding: "12px",
        boxShadow: "none",
        marginTop: "6px",
    }
}));