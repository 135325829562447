import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  title: {
    color: "#091B29",
    fontSize: "14px",
    fontFamily: Bold,
  },
  subtitle: {
    color: "#98A0AC",
    fontSize: "12px",
    fontFamily: Regular,
    marginTop: "6px",
  },
  selectAll: {
    color: "#4E5A6B",
    fontFamily: Bold,
    fontSize: "12px",
    marginLeft: "8px",
  },
  draft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    backgroundColor: "#98A0AC",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    fontSize: "12px",
    fontFamily: Bold,
  },
  root: {
    boxShadow: "0px 0px 16px #00000014",
    padding: "14px",
    borderRadius: "4px",
    backgroundColor: "white",
  },
  optionTitle: {
    color: "#091B29",
    fontFamily: Regular,
    fontSize: "14px",
  },
  optionSub: {
    color: "#98A0AC",
    fontFamily: Regular,
    fontSize: "12px",
    marginTop: "6px",
  },
  selectBox: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  images: {
    width: "42px",
    height: "42px",
    borderRadius: "4px",
  },
  completedCheckboxStyle: {
    height: "20px",
    width: "20px",
    backgroundColor: "#5078E1",
    border: "1px solid #5078E1",
    cursor: "pointer",
  },
  checkboxIconStyle: {
    fontSize: "small",
    color: "white",
    cursor: "pointer",
  },
  checkboxStyle: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    border: "1px solid #98A0AC",
    cursor: "pointer",
  },
  divRoot: {
    height: `calc(100vh - 417px)`,
    overflow: "auto",
  },
  TickIcon:{
    cursor:'pointer'
  }
}));
