import { useApolloClient } from "@apollo/client";
import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import React from 'react';
import { DocumentViewer } from "../../../components/fileViewer/index";
import { GET_ASSET_MASTER } from "../../../graphql/queries";
import { propertyStyles } from "./style";
import { assestType } from '../../../utils/common'
import { AlertDialog, Slider } from "../../../components";
export const Assets = (props) => {
    const classes = propertyStyles()
    const [value, setValue] = React.useState(0);
    const [listing, setlisting] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [selected, setSelected] = React.useState("")
    const client = useApolloClient();
    React.useEffect(() => {
        client.query({
            query: GET_ASSET_MASTER,
            fetchPolicy: 'network-only',

        }).then((res) => {
            let tempAssetsMaster = res.data?.assets_master
            let _ = tempAssetsMaster.filter(x => x?.id !== assestType?.Thumbnails).map((y) => y)
            setlisting(_)
            setValue(_?.[0]?.id)
        })
        // eslint-disable-next-line
    }, [])

    const handleList = (val) => {
        setValue(val);
    };
    const getVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url?.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }
    const handleClick = (val) => {
        setOpen(!open)
        setSelected(val)
    }
    return (
        <>
            <Box className={classes.imagebox3}>
                <List style={{ display: 'flex', marginBottom: "14px" }} className={classes.list}>

                    {
                        listing?.map((val) => {
                            return (
                                <ListItem className={val.id === value ? classes.listText : classes.list}
                                    onClick={() => handleList(val.id)}
                                >
                                    <Typography className={classes.tabname}>{val.type}</Typography>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Box>
            <br />
            <Box className={classes.imagebox1} >
                <div style={{ marginTop: "8px" }}>
                    <Grid container >
                        {
                            props?.asset?.map((val) => {


                                const url = getVideoId(val.url)
                                return (
                                    <>
                                        {
                                            val?.asset_type === value &&

                                            <Grid item xs={2} style={{ margin: "4px", borderRadius: "12px", overflow: "hidden" }}>

                                                {
                                                    val?.asset_type === assestType?.Documents ?
                                                        <div style={{ height: "150px", overflow: "auto" }}>
                                                            <DocumentViewer url={val?.url} />
                                                        </div>
                                                        :
                                                        <>
                                                            {
                                                                val?.asset_type === assestType?.Videos ?
                                                                    <iframe
                                                                        title={val?.file_meta?.name}
                                                                        className={classes.video}
                                                                        width="100%"
                                                                        height={"150px"}
                                                                        src={"https://www.youtube.com/embed/" + url}>
                                                                    </iframe>
                                                                    // <video
                                                                    //     style={{
                                                                    //         objectFit: "cover",
                                                                    //         height: "150px",
                                                                    //         width: "100%",
                                                                    //         borderRadius: "12px",
                                                                    //     }}
                                                                    //     controls
                                                                    // >
                                                                    //     <source src={val.url} type="video/mp4" />
                                                                    // </video>
                                                                    : <img
                                                                        src={val.url}
                                                                        alt=""
                                                                        onClick={() => handleClick(val)}
                                                                        style={{
                                                                            objectFit: "cover",
                                                                            height: "150px",
                                                                            width: "100%",

                                                                        }}
                                                                    />
                                                            }
                                                        </>

                                                }

                                            </Grid>
                                        }
                                    </>
                                )
                            })
                        }

                    </Grid>
                </div>
            </Box>


            <AlertDialog
                medium={selected?.asset_type === 4 ? false : true}
                header="Asset View"
                open={open}
                onClose={() => setOpen(!open)}
                component={
                    <Box height="450px" alignItems="center">
                        <Slider assets={selected} uploadImage />
                    </Box>
                }
            />
        </>
    )
}