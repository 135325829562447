import * as React from "react"

export const SQFT = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 45366"
                    transform="translate(151.538 324.538)"
                    fill="#5078e1"
                    d="M0 0h20v20H0z"
                />
            </clipPath>
        </defs>
        <g
            data-name="Sq ft icvon"
            transform="translate(-151.538 -324.538)"
            clipPath="url(#a)"
        >
            <g data-name="SQ FT">
                <path
                    data-name="Path 3643"
                    d="M155.845 341.839h-2.385v-7.156h2.385v2.385h2.383v2.385h3.578v2.386Zm5.961-7.156v-2.385h2.385v-2.385h3.578v-2.386h2.385v7.156Z"
                    fill="#e4e8ee"
                />
                <path
                    d="M152.693 326.335a.736.736 0 0 0-.725.747v14.722a1.238 1.238 0 0 0 1.227 1.227h7.852a1.238 1.238 0 0 0 1.227-1.227v-1.963a1.238 1.238 0 0 0-1.227-1.227h-1.718v-1.226a1.238 1.238 0 0 0-1.227-1.231h-1.717v-1.223a1.238 1.238 0 0 0-1.227-1.227h-1.718v-6.625a.736.736 0 0 0-.748-.747Zm2.956.011a.736.736 0 1 0 .736.736.736.736 0 0 0-.736-.736Zm2.944 0a.736.736 0 1 0 .736.736.736.736 0 0 0-.736-.736Zm2.944 0a.736.736 0 1 0 .736.736.736.736 0 0 0-.736-.736Zm2.944 0a.736.736 0 1 0 .736.736.736.736 0 0 0-.735-.736Zm3.435 0a1.238 1.238 0 0 0-1.227 1.227v1.227h-1.721a1.238 1.238 0 0 0-1.227 1.227v1.23h-1.713a1.238 1.238 0 0 0-1.227 1.227v1.963a1.238 1.238 0 0 0 1.227 1.227h7.607v6.625a.736.736 0 1 0 1.472 0v-7.365a.736.736 0 0 0-.032-.224 1.188 1.188 0 0 0 .032-.266v-6.871a1.238 1.238 0 0 0-1.227-1.227Zm.245 1.472h1.472v6.38h-7.359v-1.472h2.208a.736.736 0 0 0 .736-.736v-1.718h2.208a.736.736 0 0 0 .736-.736Zm-14.721 7.361h1.472v1.718a.736.736 0 0 0 .736.736h2.208v1.718a.736.736 0 0 0 .736.736h2.208v1.47h-7.36Zm11.042 6.378a.736.736 0 1 0 .736.736.736.736 0 0 0-.736-.736Zm2.944 0a.736.736 0 1 0 .736.736.736.736 0 0 0-.736-.736Z"
                    fill="#4e5a6b"
                />
            </g>
        </g>
    </svg>
)
