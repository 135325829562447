export const moveoutHeading = (t) => {
    const moveoutheading = [
        { title: t("orderId"), field: "reference_id" },
        { title: t("agreementId"), field: "agreement_no" },
        { title: t("RequestedOn"), field: "created_at" },
        { title: t("requested_by"), field: "requested_by" },
        { title: t("requests"), field: "no_of_units", },
        // { title: t("PropertyDetails"), field: "property_name" },
        // { title: t("TotalUnits"), field: "no_of_units", },
        // { title: t("AgreementManager"), field: "agreement_manager", },
        // { title: t("ResponsiblePerson"), field: "inspection_manager", },
        { title: t("Status"), field: "status" },
        { title: "", field: "" },
    ]
    return moveoutheading
}





export const moveoutRow = [
    {
        number: "RQINS-122143",
        date: "23 Mar 22",
        place: "Casa Grande - Chennai",
        units: "3",
        manager: "Richard Bailey",
        person: "Bala Ganesh",
        status: {
            label: "Yet to be Assign",
            value: "Yet to be Assign"

        }
    },
    {
        number: "RQINS-122143",
        date: "23 Mar 22",
        place: "Casa Grande - Chennai",
        units: "5",
        manager: "Marie Williamson",
        person: "Nick Alvarado",
        status: {
            label: "Assigned",
            value: "Assigned"

        }
    },
    {
        number: "RQINS-122143",
        date: "23 Mar 22",
        place: "Casa Grande - Chennai",
        units: "3",
        manager: "Alice Weber",
        person: "Joan Sullivan",
        status: {
            label: "Completed",
            value: "Completed"

        }
    },
    {
        number: "RQINS-122143",
        date: "23 Mar 22",
        place: "Casa Grande - Chennai",
        units: "5",
        manager: "Rose Hughes",
        person: "Beatrice James",
        status: {
            label: "Yet to be Assign",
            value: "Yet to be Assign"

        }
    },
    {
        number: "RQINS-122143",
        date: "23 Mar 22",
        place: "Casa Grande - Chennai",
        units: "3",
        manager: "Tom Dean",
        person: "Pamela Estrada",
        status: {
            label: "Yet to be Assign",
            value: "Yet to be Assign"

        }
    },
    {
        number: "RQINS-122143",
        date: "23 Mar 22",
        place: "Casa Grande - Chennai",
        units: "5",
        manager: "Doris Mendoza",
        person: "Patrick Mendez",
        status: {
            label: "Yet to be Assign",
            value: "Yet to be Assign"
        }
    },
]

export const moveoutType = [
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "agreement_no" },
    { type: ["date"], name: "created_at" },
    { type: ["text"], name: "requested_by" },
    { type: ["text"], name: "no_of_units" },
    // { type: ["date"], name: "execution" },
    // { type: ["text"], name: "agreement_manager" },
    // { type: ["text"], name: "inspection_manager" },
    { type: ["agreement_inspection_status"], name: "status" },
    // { type: ["more_6"], icon: "icon" },
]
export const moveOutReqTypePath = ["reference_id",
    //  "date", 
    "property_name", "no_of_units", "created_at", "agreement_manager", "inspection_manager", "status"]
export const StatusOptionList = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Completed', value: 'Completed' },
]

export const initialState = {
    dateTime: new Date(),
    company: "",
    hours: "",
    department: {
        label: "All",
        value: null
    },
    role: {
        label: "All",
        value: null
    },
    resource_type: {
        label: "Users"
    },
    otherResources: [],
    resource: {},
    moduleId: "",
    agreement_inspection_id: "",
    disable: false,
    schedulingType:"flexible",
    totalHours:0,
    estimatedAmount:0,
    error: {
        hours: ""
    }
}