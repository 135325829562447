import jwt_decode from "jwt-decode";
import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { config } from "../config";
import { AlertContext, AuthContext } from "../contexts";
import { GET_USER_PROFILE_ID_BY_USER_ID as queryOne } from "../graphql/queries";
import { NetworkCall } from "../networkcall";
import { AlertProps, clearAccess, getAccessRoutes, LocalStorageKeys, NetWorkCallMethods } from "../utils";
import { Access, LoginSuccess } from "./access";
import { Routes } from "./routes";
import axios from "axios";

const PrivateRoute = ({ children, ...rest }) => {
  const history = useHistory();
  const auth = React.useContext(AuthContext);
  const token = localStorage.getItem(LocalStorageKeys.authToken)
  const alert = React.useContext(AlertContext);
  // const [prevPath, setPrevPath] = React.useState("");

  const setUserDetails = (response, access, permissionRoutes) => {
    const tempDecoded = jwt_decode(token);
    const payload = {
      query: queryOne,
      variables: { userID: tempDecoded?.userProfile?.[0]?.id },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        // setPrevPath(window?.location?.pathname);
        localStorage.setItem(
          LocalStorageKeys.profileID,
          res?.data?.data?.user_profiles?.[0]?.id
        );
        auth.setAuth({
          ...auth,
          auth: {
            first_name: res?.data?.data?.user_profiles?.[0]?.first_name,
            last_name: res?.data?.data?.user_profiles?.[0]?.last_name,
            profile_img: res?.data?.data?.user_profiles?.[0]?.get_assets_url_id,
            email_id: res?.data?.data?.user_profiles?.[0]?.email_id,
            roles: response.data.roles,
            refresh: false,
            activeRole: localStorage.getItem(LocalStorageKeys.activeRole),
            access: access,
            routes: permissionRoutes,
            last_logged_in: response?.data?.userProfile?.[0]?.last_logged_in,
            prevHistoryKey: window?.history?.state?.key
          }
        })

      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  }
  const getModualList = (modulesData, modules) => {
    let data = [];
    //eslint-disable-next-line
    modulesData.map((key) => {
      let obj = {
        name: key,
        ...modules[key]
      }
      data.push(obj)
    })
    return data;
  }

  const authenticate = async (router) => {
    localStorage.setItem(LocalStorageKeys?.refreshed, "refreshed")
    if (window?.history?.state?.key) {
      localStorage.setItem(LocalStorageKeys.prev_history_key, window?.history?.state?.key)
    }
    if (localStorage.getItem(LocalStorageKeys.authToken)) {
      const response = await axios.get(
        `${config.authapi}/auth/refresh`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        }).catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong",
          });
          localStorage.clear();
          history.push(Routes.login);
        });
      if (response?.status === 200) {
        if (response?.data?.token && response?.data?.token !== "") {
          localStorage.setItem(LocalStorageKeys.authToken, response?.data?.token)
        }
        let access = response?.data?.access
        let permissionData = Object.keys(access)
        let permissionJson = access?.[permissionData?.[0]]?.role?.permissions?.[0]?.structure
        let modules = response?.data?.userProfile?.filter(i=>Object.keys(i?.module_access_roles)?.length>0)?.[0]?.module_access_roles;
        let modulesData = Object.keys(modules)
        let modulesArray = getModualList(modulesData, modules);
         localStorage.setItem(LocalStorageKeys.modules, JSON.stringify(modulesArray));
        clearAccess()
        let permisionlist = getAccessRoutes(permissionJson)
        let permissionRoutes = permisionlist?.map((val) => val?.route)
        localStorage.setItem(LocalStorageKeys.permission, JSON.stringify(permissionRoutes))

        const _ = Access(
          JSON.parse(localStorage.getItem(LocalStorageKeys.role))?.filter(
            (v) => v.name === localStorage.getItem(LocalStorageKeys.activeRole)
          )?.[0]?.name,
          router?.match?.path,
          permissionRoutes

        );
        if (_ >= 0) {
          setUserDetails(response, access, permissionRoutes);
          return true;
          // if (auth?.auth?.refresh || auth?.auth?.auth?.refresh) {
          // }
        } else {
          let path = LoginSuccess(permissionRoutes);
          let access_get = Access("", path, permissionRoutes);
          if (localStorage.getItem(LocalStorageKeys.activeRole) && path && (access_get >= 0)) {
            setUserDetails(response, access, permissionRoutes);
            history.push(path);
            return true;
          } else {

            history.push("*");
            return false;
          }
        }
      } else {
        localStorage.clear();
        history.push(Routes.login);
      }
    } else {
      localStorage.clear();
      history.push(Routes.login);
    }
  }

  const isAuthenticated = async (router) => {
    localStorage.setItem('private_route', JSON.stringify(router));
    if (window?.history?.state?.key) {
      if ((localStorage.getItem(LocalStorageKeys.prev_history_key) !== window?.history?.state?.key)) {
        authenticate(router)

      } else if (auth?.auth?.refresh) {
        if (!Boolean(localStorage.getItem(LocalStorageKeys?.refreshed))) {
          authenticate(router)
        }else{
          localStorage.removeItem(LocalStorageKeys?.refreshed)
        }
      } else if (!Boolean(auth?.auth?.refresh)) {
        localStorage.removeItem(LocalStorageKeys?.refreshed)
      }else{
        console.log("else")
      }
    } else {
      history.replace(router?.location?.pathname + (router?.location?.search ?? ""))
      return true;
    }
  };

  return (
    <Route
      {...rest}
      render={(_) =>
        isAuthenticated(_) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: Routes.login,
              state: { from: _?.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
