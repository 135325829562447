import React, { useEffect } from "react";
import { TreeComponent } from "./components";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Grid } from "@mui/material";
import { UserManageMentStylesParent } from "./style";
import { Subheader } from "./components";
import { PermisionList, Roles, Person } from "./screens";
import axios from "axios";
import { CustomSelect } from "./components/filterGenerator/components/select";
import { config } from "../../config";
// tab title
const tabTitle = [
  {
    title: "Repository",
    id: "1",
  },
  {
    title: "Permission List",
    id: "2",
  },
  {
    title: "Roles",
    id: "3",
  },
  {
    title: "Person",
    id: "4",
  },
];

export const UserManageMent = () => {
  // style
  const classes = UserManageMentStylesParent();
  // state
  const [value, setValue] = React.useState("1");
  const [repository, setRepository] = React.useState([]);
  const [modules, setModule] = React.useState("");
  const [moduleOption, setmoduleOption] = React.useState([]);
  // check box click
  const handleChange = (e, data) => {
    e.stopPropagation();
  };
  const selectModule = (e) => {
    setModule(e);
    getRepository(e?.value);
  };

  const getModule = () => {
    axios
      .get(`${config?.api_url}/iam/get_modules`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        let result = res?.data?.data?.map((x) => {
          return {
            value: x?.id,
            label: x?.name,
          };
        });
        setmoduleOption(result);
        setModule(result?.[0])
        getRepository(result?.[0]?.value);
      })
      .catch((err) => {});
  };

  const getRepository = (e) => {
    axios
      .get(
        `${config?.api_url}/iam/get_repository?module_id=${
          e ?? 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((res) => {
        setRepository(res?.data?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (value === "1") {
      getModule();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      {/* sub header */}
      <Subheader title="User Management" hideBackButton={true} />
      <TabContext value={value}>
        {/* tab title */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            className={classes.tab}
            onChange={(event, newValue) => setValue(newValue)}
            aria-label="lab API tabs example"
          >
            {tabTitle?.map((x) => {
              return <Tab label={x?.title} value={x?.id} />;
            })}
          </TabList>
        </Box>
        {/* tab body */}
        <div className={classes.root}>
          <TabPanel value="1" className={classes.tab}>
            <div className={classes.rootSection}>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <TreeComponent
                    TreeJson={repository}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomSelect
                    color={"#98A0AC"}
                    fontSize={12}
                    required
                    fontWeight={400}
                    placeholder="Select Module"
                    options={moduleOption}
                    onChange={(value) => selectModule(value)}
                    value={modules}
                  />
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value="2" className={classes.tab}>
            <PermisionList moduleOption={moduleOption} />
          </TabPanel>
          <TabPanel value="3" className={classes.tab}>
            <Roles moduleOption={moduleOption} />
          </TabPanel>
          <TabPanel value="4" className={classes.tab}>
            <div className={classes.rootSection}>
              <Person moduleOption={moduleOption} />
            </div>
          </TabPanel>
        </div>
      </TabContext>
    </Box>
  );
};
