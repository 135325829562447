import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { reservationStyle } from "./style";

export const ReservationCard = ({
  onCancel = () => false,
  details = {},
  permission = {},
  t,
}) => {
  const classes = reservationStyle();
  return (
    <div className={classes.parentBox}>
      <Box className={classes.detailsBox}>
        {/*  */}
        <Box>
          <Box display="flex" alignItems="center">
            <Typography className={classes.reservationNo}>
              {details?.reservation_no ?? ""}
            </Typography>
            <Box className={classes.badge}>
              {details?.is_active ? "Active" : "In Active"}
            </Box>
          </Box>
          <Typography className={classes.date}>{details?.date}</Typography>
        </Box>
        {/*  */}
        <Box>
          <Typography className={classes.reservationNo}>
            {details?.symbol} {details?.reserved_amount ?? ""}
          </Typography>
          <Typography className={classes.date}>Paid on {details?.paid_on_date ?? ""}</Typography>
        </Box>
      </Box>

      {/* button */}
      {permission?.create && (
        <Box display="flex" alignItems="center" className={classes.btnParent}>
          <Button
            className={classes.cancelBtn}
            variant="outlined"
            onClick={() => onCancel(details)}
          >
            {t("Cancel_Reservation")}
          </Button>
          <Button className={classes.saveBtn} variant="outlined">
            {t("Send_Payment_Link")}{" "}
          </Button>
        </Box>
      )}
    </div>
  );
};
