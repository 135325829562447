import React from "react";
import { LeadView } from './leadnewview';
import { withNavBars } from "./../../HOCs";

class LeadViewNewParent extends React.Component {
  render() {
    return <LeadView />;
  }
}
const props = {
      boxShadow: false
  }
export default withNavBars(LeadViewNewParent , props);