import { Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { SelectBox } from "../../components";
import { enumSelect, enum_types } from "../../utils";


const useStyles = makeStyles((theme) => ({
  Label: {
    color: "#98A0AC",
    fontSize: "12px",
    marginTop: "10px",
  },
  Requirements: {
    padding: "10px",
    width: "100%",
    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqfts: {
    padding: "10px",
    width: "100%",
    textAlign: "center",
    borderRadius: "10px",

    fontSize: "14px",

    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  Filter: {
    marginBottom: "20px",
  },
}));


export const FilterComponent = (props) => {
  const classes = useStyles();
  const [enumValue, setEnumValue] = React.useState({
    category: []
  })
  const getEnum = async () => {
    const result = await enumSelect([enum_types.notification_category_type])
    setEnumValue({
      category: result?.notification_category_type
    })
  }
  React.useEffect(() => {
    getEnum()
  }, [])

  return (
    <div>
      {/*Priority Type */}
      <div className={classes.Filter} style={{ marginTop: "10px" }}>
        <SelectBox
          options={[]}
          isMulti
          isRequired
          label="Property"
          placeholder="Select Property"
          key={JSON.stringify(props?.filterdata?.company)}
          value={props?.filterdata?.propperty ?? []}
          onChange={(value) => {
            props?.upDateFilter("propperty", value);
          }}
          isPaginate
          loadOptions={(search, array) =>
            props?.loadOptionsfilter(search, array, "propperty")
          }
          loading={props.loading === "propperty"}
          debounceTimeout={800}
        />
      </div>
      <Divider />
      <div className={classes.Filter} style={{ marginTop: "10px" }}>
        <SelectBox
          isRequired
          label="Catagory"
          isMulti
          options={enumValue?.category}
          placeholder="Select Catagory"
          value={props?.filterdata?.catagory}
          onChange={(value) => {
            props?.upDateFilter("catagory", value);
          }}
          isError={props?.filterdata?.error?.catagory?.length > 0}
          errorMessage={props?.filterdata?.error?.catagory}
        />
      </div>
    </div>
  );
};
