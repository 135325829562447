import React, { useEffect, useRef } from 'react';
import Carousel, { consts } from "react-elastic-carousel";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';

export const CarouselSlider = (props) => {
    const { data = [], activeIndex } = props;

    const carouselRef = useRef(null);
    const breakPoints = [{ width: 1, itemsToShow: 1 }];

    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />
        return (
            <IconButton size="small" style={{ fontSize: "20px" }} onClick={onClick} disabled={isEdge}>{pointer}</IconButton>
        )
    }

    useEffect(() => {
        if(activeIndex >= 0 && carouselRef?.current){
            carouselRef?.current?.goTo(activeIndex)
        }
    }, [activeIndex])

    return (
        <Carousel ref={carouselRef} renderArrow={myArrow} showArrows={true} breakPoints={breakPoints}>
            {
                data?.map((val) => {
                    return (
                        <img src={val?.url} alt="" height="500px" width="100%" />
                    )
                })
            }
        </Carousel>
    )
}