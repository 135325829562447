import { useStylesManagement } from "./style";
import { Grid, Box, Typography, Button, Divider, } from "@mui/material";
import React, { useMemo, useState } from 'react';
import { Subheader, AlertDialog, SelectBox, TextBox, TableWithPagination, SearchFilter, UseDebounce } from "../../components";
import { useHistory } from 'react-router-dom';
import { useWindowDimensions, AlertProps, LocalStorageKeys } from "../../utils";
import { useApolloClient } from "@apollo/client";
import { BackdropContext, AlertContext } from "../../contexts";
import { GET_CONFIGURE_DATA } from "../../graphql/queries";
import { ADD_CONFIGURATION } from "../../graphql/mutations";
import moment from "moment";
import { NumberSequenceHeading, NumberSequencePath, NumberSequenceType } from "../../utils/numberSequence";
import styled from '@mui/material/styles/styled';

const CustomPaper = styled('div')(({ theme }) => ({
      boxShadow: '0px 0px 16px #00000008',
      borderRadius: theme.palette.borderRadius,
      backgroundColor: "#FFF",
      margin: theme.spacing(3),
      padding: "0px",
      height: `calc(100vh - 147px)`,
}))


const preference = [
      {
            value: 'String preferred number',
            label: "String preferred number",

      },
      {
            value: 'Date preferred number',
            label: "Date preferred number",
            format: [
                  {
                        value: 'YYYY',
                        label: "YYYY"
                  },
                  {
                        value: 'YYYYMM',
                        label: "YYYYMM"
                  },
                  {
                        value: 'YYYYMMDD',
                        label: "YYYYMMDD"
                  },
            ]
      },
      {
            value: 'String and date preferred number',
            label: "String and date preferred number",
            format: [
                  {
                        value: 'YYYY',
                        label: "YYYY"
                  },
                  {
                        value: 'YYYYMM',
                        label: "YYYYMM"
                  },
                  {
                        value: 'YYYYMMDD',
                        label: "YYYYMMDD"
                  },
            ]
      },
]

const initial = {
      open: false,
      view: false,
      seedvaluepreview: "",
      preference: "",
      fromat: "",
      number: "",
      seed: "",
      String: "",
      list: [],
      listID: {},
      isShow: false,
      isEdit: false,
      error: {
            preference: "",
            fromat: "",
            number: "",
            seed: "",
            String: "",
      }
}
export const Configure = () => {
      const client = useApolloClient();
      const history = useHistory();
      const backdrop = React.useContext(BackdropContext);
      const alert = React.useContext(AlertContext);
      const classes = useStylesManagement();
      const size = useWindowDimensions();
      const [state, setState] = useState({ ...initial })
      const [list, setList] = useState({
            count: null,
            list: [],
      })
      const [view, setView] = useState({})
      // const [loading, setLoading] = React.useState(true);
      const [page, setPage] = React.useState(1);
      const [limit, setLimit] = React.useState(10);
      const [searchText, setSearchText] = React.useState("")
      const debounce = UseDebounce();

      const closeDrawer = () => {
            setState({ ...initial })
      }

      const openDrawer = (v, x, detail) => {
            if (detail === 'edit') {
                  let maxDigit = Number(x?.starts_with?.toString());
                  let addZero = "";
                  if (maxDigit > x?.starts_with?.length) {
                        let appendDigit = maxDigit - x?.starts_with?.length;
                        let i = appendDigit;
                        while (i > 0) {
                              addZero += '0';
                              i--;
                        }
                  }
                  setState({
                        open: true,
                        view: v,
                        seedvaluepreview: addZero + x?.starts_with?.toString(),
                        preference: {
                              value: x?.preference ?? "",
                              label: x?.preference ?? ""
                        },
                        fromat: {
                              value: x?.date_format ?? "",
                              label: x?.date_format ?? "",
                        },
                        number: x?.starts_with?.toString()?.length,
                        seed: x?.starts_with ?? "",
                        String: x?.prefix ?? "",
                        list: [],
                        listID: x,
                        isShow: true,
                        isEdit: true,
                        error: {
                              preference: "",
                              fromat: "",
                              number: "",
                              seed: "",
                              String: "",
                        }
                  })
            }
            else if (detail === 'view') {
                  setView(x)
                  setState({ ...state, open: true, view: v, listID: x })
            }
            else {
                  setState({ ...state, open: true, view: v, listID: x })
            }

      }
      // const updateState = (k, v) => {
      //       let error = state?.error;
      //       error[k] = "";
      //       if (k === "seed") {
      //             let maxDigit = Number(state?.number);
      //             let addZero = "";
      //             if (maxDigit > v.length) {
      //                   let appendDigit = maxDigit - v.length;
      //                   let i = appendDigit;
      //                   while (i > 0) {
      //                         addZero += '0';
      //                         i--;
      //                   }
      //             }
      //             setState({ ...state, seedvaluepreview: addZero + v, seed: v, isShow: true, error })
      //       }
      //       else {

      //             if (k === 'preference') {
      //                   setState({ ...state, String: "", seed: "", number: "", fromat: "", isShow: false, [k]: v })
      //             }
      //             else if (k === 'number') {
      //                   setState({ ...state, seed: "", [k]: v })
      //             }
      //             else {
      //                   setState({ ...state, [k]: v })
      //             }

      //       }
      // }
      const updateState = (k, v) => {
            let error = state?.error;
            error[k] = "";
            if (k === "seed") {
                  let maxDigit = Number(state?.number);
                  let addZero = "";
                  if (maxDigit > v.length) {
                        let appendDigit = maxDigit - v.length;
                        let i = appendDigit;
                        while (i > 0) {
                              addZero += '0';
                              i--;
                        }
                  }
                  setState({ ...state, seedvaluepreview: addZero + v, seed: v, isShow: true, error })
            }
            else {

                  if (k === 'preference') {
                        setState({ ...state, String: "", seed: "", number: "", fromat: "", isShow: false, [k]: v })
                  }
                  else if (k === 'number') {
                        setState({ ...state, seed: "", [k]: v })
                  }
                  else {
                        setState({ ...state, [k]: v })
                  }

            }
      }
      const getRoles = (offset, limit, searchText) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Loading",
            });
            client.query({
                  query: GET_CONFIGURE_DATA,
                  fetchPolicy: 'network-only',
                  variables: {
                        offset,
                        limit,
                        client: localStorage.getItem(LocalStorageKeys.clinetID),
                        searchText: searchText
                  }
            }).then((res) => {
                  let listData = res?.data?.sequence_config?.map((data) => {
                        return {
                              configuration_name: data?.table_name ?? '-',
                              configuration_id: data?.id ?? '-',
                              no_of_preference: data?.preference ?? '-',
                              prefix: data?.prefix ?? '-',
                              min_num_of_digit: data?.starts_with ?? '-',
                              seed_value: data?.seed_value ?? '-',
                              date_format:data?.date_format ?? '-'
                        }
                  })
                  setList({
                        count: res?.data?.count ?? null,
                        list: listData ?? []
                  })
                  // setLoading(false);
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch((err) => {
                  // setLoading(false);
            })
      }
      const save = async () => {
            if (validate()) {
                  closeDrawer()
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: true,
                        message: "Loading",
                  });
                  let result = await client.mutate({
                        mutation: ADD_CONFIGURATION,
                        variables: {
                              ID: state?.listID?.id ?? undefined,
                              updatePayload: {
                                    preference: state?.preference?.value,
                                    prefix: state?.String ?? null,
                                    date_format: state?.fromat?.value ?? null,
                                    starts_with: state?.seedvaluepreview ?? null,
                                    is_active: true,
                                    seed_value: state?.number ?? null,
                                    updated_at: new Date().toISOString(),
                                    client: localStorage.getItem(LocalStorageKeys.clinetID)
                              }
                        }
                  })
                  if (result) {
                        getRoles(0, limit, '')
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: false,
                              message: "",
                        });
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.success,
                              msg: `${state?.isEdit ? "Updated Successfully" : "Created Successfully"}`,
                        });
                        setState({ ...initial })
                  }
                  else {
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: false,
                              message: "",
                        });
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Some Thing Went Wrong",
                        });
                  }
            }


      }

      const DateToday = useMemo(() => (
            [
                  {
                        date: state?.fromat?.label?.length > 0 ? moment(new Date()).format(state?.fromat?.label) : null,
                        name: Number(state?.seedvaluepreview) ?? "-",
                        value: state?.String ?? null
                  },
                  {
                        date: state?.fromat?.label?.length > 0 ? moment(new Date()).format(state?.fromat?.label) : null,
                        name: Number(state?.seedvaluepreview) + 1 ?? "-",
                        value: state?.String ?? null

                  },
                  {
                        date: state?.fromat?.label?.length > 0 ? moment(new Date()).format(state?.fromat?.label) : null,
                        name: Number(state?.seedvaluepreview) + 2 ?? "-",
                        value: state?.String ?? null

                  },
            ]
            // eslint-disable-next-line
      ), [state?.seed])

      const validate = () => {
            let isValid = true;
            let error = state.error;
            if (state?.preference?.length === 0) {
                  isValid = false;
                  error.preference = "Preference is Required";
            }
            if (state?.preference?.value === "String and date preferred number" || state?.preference?.value === "Date preferred number" || state?.preference?.length === 0) {
                  if (state?.fromat?.length === 0) {
                        isValid = false;
                        error.fromat = "Date Format is Required";
                  }
            }
            if (state?.seed?.length === 0) {
                  isValid = false;
                  error.seed = "Seed Value is Required";
            }
            if (state?.preference?.value === "String and date preferred number" || state?.preference?.value === "String preferred number" || state?.preference?.length === 0) {
                  if (state?.String?.length === 0) {
                        isValid = false;
                        error.String = "Prefix is Required";
                  }
            }

            setState({ ...state, error });

            return isValid;
      };



      React.useEffect(() => {
            getRoles(0, limit, '')
            // eslint-disable-next-line
      }, [])

      // As per CR Commented
      // const handleInfiniteScroll = () => {
      //       if (offset + limit <= list?.count[0]?.count) {
      //             setOffset((prevOffset) => prevOffset + limit);
      //             setLoading(true);
      //             getRoles(offset + limit, 'scroll');
      //       }
      // }

      const handlePagination = (value) => {
            setPage(value);
            let offset = (value - 1) * limit;
            getRoles(offset, limit, "");
      };

      const handleChangeLimit = (value) => {
            setLimit(value);
            setPage(1);
            getRoles(0, value, "");
      };

      const handleSearch = (e) => {
            setSearchText(e)
            debounce(() => searchTableFunction(e), 800)
      }
      const searchTableFunction = (e) => {
            if (page > 1) {
                  setPage(1);
            }
            getRoles(0, limit, e)
      }

      //handle icon
      const handleIcon = (type, value) => {
            let viewData = {
                  id: value?.configuration_id ? value?.configuration_id : undefined,
                  slug: value?.configuration_name,
                  table_name: value?.configuration_name,
                  date_format: value?.date_format,
                  starts_with: value?.min_num_of_digit,
                  prefix:  value?.prefix,
                  preference: value?.no_of_preference
              }

            if (type === "view") {
                  setState({ ...state, view: true, open: true })
                  setView(viewData)
                  openDrawer(true, viewData, "view")
            } else if (type === "edit") {
                  openDrawer(false, view, "edit")
            }
      }

      return (
            <div>
                  <Subheader title={'Configuration'} goBack={() => { history.goBack(-1) }} />
                  {/* As per CR Commented */}
                  {/* <div style={{ padding: "24px", paddingBottom: '150px', margin: "4px", height: '100vh', overflow: 'auto' }}>
                        <AddMemberCard
                              closeDrawer={closeDrawer}
                              data={list?.list}
                              openDrawer={openDrawer}
                              handleInfiniteScroll={handleInfiniteScroll}
                              loading={loading}
                        />
                  </div> */}

                  <CustomPaper m={1}>

                        <Grid container spacing={2} className={classes.content}>
                              <Grid item md={12}>
                                    <Grid container alignItems={"center"} p={1} pl={2} pt={2}>
                                          <Grid item xs={4}>
                                                <SearchFilter placeholder={"Search Configuration Name"} value={searchText} handleChange={(value) => handleSearch(value)} />
                                          </Grid>
                                          <Grid item xs={8}>
                                          </Grid>

                                    </Grid>
                              </Grid>
                              <Grid item md={12} ml={1} className={classes.tablegrid}>
                                    <TableWithPagination
                                          heading={NumberSequenceHeading}
                                          rows={list?.list}
                                          path={NumberSequencePath}
                                          showpagination={true}
                                          onClick={() => false}
                                          dataType={NumberSequenceType}
                                          handlePagination={handlePagination}
                                          handleChangeLimit={handleChangeLimit}
                                          handleIcon={handleIcon}
                                          totalRowsCount={list?.count?.[0]?.count}
                                          page={page}
                                          limit={limit}
                                          tableType="no-side"
                                          height={'calc(100vh - 351px)'}
                                          view={true}
                                          edit={true}
                                          delete={true} />
                              </Grid>

                        </Grid>
                  </CustomPaper>
                  <AlertDialog
                        md
                        header={state?.view ? view?.table_name : state?.isEdit ? 'Edit Configuration' : 'Add Configuration'}
                        onClose={closeDrawer}
                        open={state?.open}
                        component={
                              <>
                                    {
                                          state?.view ?
                                                <div>
                                                      <div style={{ textAlign: "left", padding: "24px" }}>

                                                            <Box className={classes.detailsBox}>
                                                                  {
                                                                        view?.preference &&
                                                                        <>
                                                                              <Box className={classes.box}>
                                                                                    <Typography className={classes.title}>Number Preference</Typography>
                                                                                    <Typography className={classes.sub1}>{view?.preference ?? "-"}</Typography>
                                                                              </Box>
                                                                        </>
                                                                  }
                                                                  {
                                                                        view?.prefix &&
                                                                        <>
                                                                              <Box className={classes.box}>
                                                                                    <Typography className={classes.title}>Prefix</Typography>
                                                                                    <Typography className={classes.sub1}>{view?.prefix ?? "-"}</Typography>
                                                                              </Box>
                                                                        </>
                                                                  }
                                                                  {
                                                                        view?.starts_with &&
                                                                        <>
                                                                              <Box className={classes.box}>
                                                                                    <Typography className={classes.title}>Minimum Number Of Digits</Typography>
                                                                                    <Typography className={classes.sub1}>{view?.starts_with?.toString?.length ?? "-"}</Typography>
                                                                              </Box>
                                                                        </>
                                                                  }
                                                                  {
                                                                        view?.seed_value &&

                                                                        <Box className={classes.box}>
                                                                              <Typography className={classes.title}>Seed Value</Typography>
                                                                              <Typography className={classes.sub1}>{view?.seed_value ?? "-"}</Typography>
                                                                        </Box>
                                                                  }

                                                            </Box>

                                                      </div>
                                                      <Divider />
                                                      <div style={{ textAlign: "left", padding: "24px" }}>
                                                            <Box className={classes.detailsBox}>
                                                                  <Box className={classes.box}>
                                                                        <Typography className={classes.title}>Preview</Typography>
                                                                        <Typography className={classes.sub1}>{view?.prefix ?? "-"}{view?.prefix && "-"}{view?.starts_with ?? "-"}</Typography>
                                                                  </Box>
                                                            </Box>
                                                      </div>
                                                      <div style={{ padding: "24px" }}>
                                                            <Button className={classes.button} variant="contained" onClick={(e) => {
                                                                  e.stopPropagation()
                                                                  openDrawer(false, view, "edit")
                                                            }} >Edit</Button>
                                                      </div>
                                                </div>
                                                :
                                                <div>
                                                      <div style={{
                                                            height: size?.height - 300, textAlign: "left",
                                                            margin: "9px 0px", overflow: 'auto', padding: "24px"
                                                      }}
                                                      >

                                                            <Grid container spacing={3}>
                                                                  <Grid item xs={12}>
                                                                        <SelectBox
                                                                              isRequired
                                                                              label="Number Preference"
                                                                              placeholder="Select Number Preference"
                                                                              options={preference ?? []}
                                                                              value={state?.preference ?? ""}
                                                                              onChange={(value) => {
                                                                                    updateState("preference", value);
                                                                              }}
                                                                              isError={state?.error?.preference?.length > 0}
                                                                              errorMessage={state?.error?.preference} />
                                                                  </Grid>
                                                                  {
                                                                        state?.preference?.value === "String and date preferred number" &&
                                                                        <Grid item xs={12}>
                                                                              <TextBox
                                                                                    isrequired
                                                                                    label="Prefix"
                                                                                    placeholder="Prefix"
                                                                                    value={state?.String ?? ""}
                                                                                    onChange={(e) => {
                                                                                          updateState("String", e.target.value);
                                                                                    }}
                                                                                    isError={state?.error?.String?.length > 0}
                                                                                    errorMessage={state?.error?.String} />
                                                                        </Grid>
                                                                  }

                                                                  <Grid item xs={12}>
                                                                        {
                                                                              state?.preference?.value === "String preferred number" ?
                                                                                    <TextBox
                                                                                          isrequired
                                                                                          label="Prefix"
                                                                                          placeholder="prefix"
                                                                                          value={state?.String ?? ""}
                                                                                          onChange={(e) => {
                                                                                                updateState("String", e.target.value);
                                                                                          }}
                                                                                          isError={state?.error?.String?.length > 0}
                                                                                          errorMessage={state?.error?.String} />
                                                                                    :
                                                                                    <SelectBox
                                                                                          isRequired
                                                                                          label="Fromat"
                                                                                          placeholder="Select Fromat"
                                                                                          options={state?.preference?.format ?? []}
                                                                                          value={state?.fromat ?? ""}
                                                                                          onChange={(value) => {
                                                                                                updateState("fromat", value);
                                                                                          }}
                                                                                          isError={state?.error?.fromat?.length > 0}
                                                                                          errorMessage={state?.error?.fromat} />
                                                                        }

                                                                  </Grid>
                                                                  <Grid item xs={12}>
                                                                        <TextBox
                                                                              isrequired
                                                                              type="number"
                                                                              label="Min Number"
                                                                              placeholder="Min Number"
                                                                              value={state?.number ?? ""}
                                                                              onChange={(e) => {
                                                                                    updateState("number", e.target.value);
                                                                              }}
                                                                              isError={state?.error?.number?.length > 0}
                                                                              errorMessage={state?.error?.number} />
                                                                  </Grid>
                                                                  <Grid item xs={12}>
                                                                        <TextBox
                                                                              isrequired
                                                                              type="number"
                                                                              label="Seed Value"
                                                                              placeholder="Seed Value"
                                                                              value={state?.seed ?? ""}
                                                                              onChange={(e) => {
                                                                                    updateState("seed", e.target.value);
                                                                              }}
                                                                              isError={state?.error?.seed?.length > 0}
                                                                              errorMessage={state?.error?.seed} />
                                                                  </Grid>
                                                                  {
                                                                        state?.isShow && state?.seedvaluepreview?.length > 0 &&
                                                                        <Grid item xs={12}>
                                                                              <Typography className={classes.preview}>Preview</Typography>
                                                                              <Box style={{ marginTop: '6px' }}>
                                                                                    {
                                                                                          DateToday?.map((x) => {
                                                                                                return (
                                                                                                      <Typography className={classes.previewdetails}>
                                                                                                            {x?.value?.length > 0 && x?.value}
                                                                                                            {x?.value?.length > 0 && '-'}
                                                                                                            {x?.date !== null && x?.date}
                                                                                                            {x?.date !== null && '-'}
                                                                                                            {x?.name}</Typography>
                                                                                                )
                                                                                          })
                                                                                    }

                                                                              </Box>

                                                                        </Grid>

                                                                  }

                                                            </Grid>
                                                      </div>
                                                      <div style={{ padding: "20px 24px" }}>
                                                            <Button className={classes.button} variant="contained" onClick={save}>{state?.isEdit ? "Save Configuration" : "Add Configuration"}</Button>
                                                      </div>
                                                </div>

                                    }
                              </>

                        }
                  />
            </div>
      )
}