import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomPaper, CustomTypography, TitleDesc } from '../../companyCreation/components';
import useTheme from '@mui/material/styles/useTheme';
import { returnValue } from '../../companyCreation/utils';
import { Bold } from '../../../utils';
export const PaymentAndTaxDetails = (props) => {

    const theme = useTheme();

    const {
        tax = null,
        bankingBasicDetails = null,
    } = props?.data ?? {};

    return (
        <div>
            <CustomPaper marginBottom={theme.spacing(3)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTypography
                            fontSize={12}
                            fontFamily={Bold}
                            // marginBottom={theme?.spacing(2)}
                            color={theme?.typography?.color?.secondary}
                        >
                            TAX
                        </CustomTypography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Tax Group"}
                            desc={returnValue(tax?.taxGroup?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Tax Code"}
                            desc={returnValue(tax?.taxCode?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Tax Registration Number"}
                            desc={returnValue(tax?.taxRegistrationNumber, '-')}
                        />
                    </Grid>
                </Grid>
            </CustomPaper>
            <CustomPaper>
                <Grid container spacing={2} rowSpacing={3}>
                    <Grid item xs={12}>
                        <CustomTypography
                            fontSize={12}
                            fontFamily={Bold}
                            // marginBottom={theme?.spacing(2)}
                            color={theme?.typography?.color?.secondary}
                        >
                            Banking Basic Details
                        </CustomTypography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Bank Name"}
                            desc={returnValue(bankingBasicDetails?.bankName, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Branch Name"}
                            desc={returnValue(bankingBasicDetails?.branchName, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Currency"}
                            desc={returnValue(bankingBasicDetails?.currency?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Bank Account Type"}
                            desc={returnValue(bankingBasicDetails?.bankAccountType, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Account Number"}
                            desc={returnValue(bankingBasicDetails?.accountNumber, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Bank Routing Type"}
                            desc={returnValue(bankingBasicDetails?.bankRoutingType?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Bank Routing Code"}
                            desc={returnValue(bankingBasicDetails?.bankRoutingCode, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Cheque Name"}
                            desc={returnValue(bankingBasicDetails?.chequeName, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Address Line 1"}
                            desc={returnValue(bankingBasicDetails?.addressLineOne, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Address Line 2"}
                            desc={returnValue(bankingBasicDetails?.addressLineTwo, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"City"}
                            desc={returnValue(bankingBasicDetails?.city, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Country"}
                            desc={returnValue(bankingBasicDetails?.country, '-')}
                        />
                    </Grid>
                </Grid>
            </CustomPaper>
        </div >
    )
}