import {
    Grid
} from "@mui/material";
import React from "react";
import { ComapnyPage } from "./companypage";
import { useStyles } from "./style";

export const Company = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container className={classes.grid}>
                <Grid item xs={12} sm={5} md={6} lg={7} xl={8} className={classes.background} />
                <Grid item xs={12} sm={7} md={6} lg={5} xl={4}>
                    <ComapnyPage />
                </Grid>
            </Grid>
        </div>
    );
};
