import React from "react";
import { AuthContext } from "./contexts";
// import { LinearProgress } from "@mui/material";
import { NetworkCall } from "./networkcall";
import { LocalStorageKeys, NetWorkCallMethods, refreshCacheAndReload, semverGreaterThan } from "./utils";

class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      auth: { roles: null, device: "web", refresh: true, activeRole: null, first_name: null, last_name: null, profile_img: null, emailId: null, routes: [] },

      setAuth: this.setAuth
    };
  }

  componentDidMount() {
    this.checkForLatestBuild();
    this.refreshAPI();
  }

  refreshAPI = () => {

  }

  checkForLatestBuild = () => {
    NetworkCall(
      `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ''}/meta.json`,
      NetWorkCallMethods.get,
      null,
      null,
      false,
      true).then((_) => {
        const isVersion = semverGreaterThan(_.data.version, localStorage.getItem(LocalStorageKeys.version));
        localStorage.setItem(LocalStorageKeys.version, _.data.version)
        if (isVersion) {
          refreshCacheAndReload();
        }
      }).catch(err => {
        console.log('err:', err);
      })
  }

  setAuth = (auth) => {
    this.setState({ auth });
  };


  render() {

    // let {loading} = this.state;

    return (
      <>
        {/* {loading ?
          <LinearProgress />
          : this.props.children
        } */}
        <AuthContext.Provider value={this.state}>
          {this.props.children}
        </AuthContext.Provider>
      </>
    );
  }
}

export default AppAuth;
