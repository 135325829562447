import { LinearProgress, linearProgressClasses } from "@mui/material";
import React from "react";
import { ProgressBarStyles } from "./style";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

export const CalProgressbar = ({ value = {} }) => {
    const classes = ProgressBarStyles()


    const returnColor = (val) => {
        if (val <= 80) {
            return 'linear-gradient(to right, #5AC782 ,80%, #CF8927)'
        }
        else if (val <= 60) {
            return 'linear-gradient(to right, #5AC782, #5AC782)'
        }
        else if (val < 100) {
            return 'linear-gradient(to right, #5AC782 ,50%, #FF6F00)'
        } else if (val >= 100) {
            return 'red'
        }
    }

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        borderRadius: 5,
        padding: 4,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: "transparent",
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            background: `${returnColor(parseFloat(value))}`,
        },
    }));


    return (
        <div>
            <Stack direction={"row"} spacing={1} alignItems={"center"} >
                <Box className={classes.outerline}>
                    <BorderLinearProgress value={value} variant="determinate" />
                </Box>
                <Typography className={classes.progress_level_label}>{value}%</Typography>
            </Stack>
        </div>
    )
}