import gql from "graphql-tag";
import { LocalStorageKeys } from "../utils";
export let GET_VAT_GROUP = (offset, limit, searchText, company) => gql`
query GET_CURRENCY{
  count: vat_group_master(
    where:{
      group_name:{iregex:"${searchText}"}
      is_active:{eq:true}
      client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
    }
  ) {
    count:count_id
  }
  vat_group_master(
      where:{
        group_name:{iregex:"${searchText}"}
        is_active:{eq:true}
  
         }
         client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
      offset:${offset}
      limit:${limit}
    ){
         label:group_name
        value:id
        
      }
}
`;

export let GET_CURRENCY = (offset, limit, searchText) => gql`
query GET_CURRENCY{
  count: currency_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  currency_master(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
         }
      offset:${offset}
      limit:${limit}
    ){
         label:symbol
      	value:id
        
      }
}
`;