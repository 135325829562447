import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from 'react';
import { Regular, SemiBold } from '../../utils';
import { TextBox } from '../textbox';
export const btnStyles = makeStyles((theme) => ({
    input: {
        width: "60px",
        border: "none",
        outline: "none"
    },
    custom: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,

    },
    check: {
        color: "#5078E1",
        marginTop: "8px",
        marginRight: "4px",
        cursor: "pointer",
        borderRadius: theme.palette.borderRadius
    },
    chip: {
        backgroundColor: "#5078E1",
        boxShadow: 'none',
        fontSize: "12px",
        color: "white",
        borderRadius: "4px",
        fontFamily: SemiBold,
        height: "45px"
    },
    selectCycle: {
        border: "1px solid #E4E8EE",
        padding: "10px",
        borderRadius: theme.palette.borderRadius
    },
    text: {
        fontsize: "12px",
        fontFamily: "'OpenSans-Italic', 'Roboto-Italic', Arial, sans-serif",
        color: theme.typography.color.tertiary
    },
    selectTitle: {
        fontSize: "12px",
        color: theme.typography.color.tertiary,
        fontFamily: Regular,
    },
    icon: {
        marginLeft: "15px"
    }
}))
export const CustomBtn = ({
    handleDelete = () => false,
    onChange = () => false,
    value = "",
    setShow = () => false,
    height = false,
    noOfdays = "",
    customValue = null,
    selectBillingCycle = false,
    date = "",
    onDateChange = () => false,
    onCustomChange = () => false,
    disabled=false
}) => {
    const classes = btnStyles()
    const [show1, setShow1] = React.useState(value ? parseInt(value) <= customValue ?? 31 ? false : true : false)
    // const [day, setDay] = React.useState("")
    // const [date, setDate] = React.useState(new Date())

    const deleteFunction =()=>{
        if(!disabled){
            handleDelete()
            setShow1(false)
            setShow(true)
        }
    }
    return (
        show1 ?
            <Chip deleteIcon={<CloseRoundedIcon style={{ color: "white", fontSize: "16px" }} />} label={`${value} Days`} className={classes.chip}
                onDelete={() => {
                    deleteFunction()
                }} /> :
            selectBillingCycle === true ?
                <Box sx={{ width: "100px" }}>
                    <TextBox
                        value={date ?? ""}
                        label=""
                        onChange={(e) => onDateChange(e.target.value)}
                        isReadonly={disabled}

                    />
                </Box>
                :
                <Box display='flex' alignItems="center" className={classes.custom}>
                    <Box marginLeft="8px">
                        <input type="number"
                            value={noOfdays ?? ""} onChange={(e) => onCustomChange(e.target.value)} placeholder="40 Days" className={classes.input} disabled={disabled}/>
                    </Box>
                    <Box marginLeft="4px"
                        onClick={() => {
                            if (noOfdays) {
                                onChange(noOfdays)
                                setShow1(true)
                            }
                            // setShow(true)
                        }}>
                        <CheckBoxRoundedIcon className={classes.check} />
                    </Box>
                </Box>
    )
}