import { Box, Typography, Grid, Stack, Button } from '@mui/material'
import React from 'react'
import NoneSlot from '../../../assets/noneslot'
import { AvailableSlotsStyle } from './style'
import moment from 'moment'
import { withNamespaces } from 'react-i18next'
import { useWindowDimensions } from '../../../utils'

const AvailableSlots = (
    {
        availableSlot = [],
        openPopup = () => false,
        selectSlot = () => false,
        selectedSlot = [],
        t,
        selectedVenue={}
    }
) => {
    const size = useWindowDimensions()
    const classes = AvailableSlotsStyle(size)
    var selectedIndex = []
    if (selectedSlot?.length > 0) {
        selectedIndex = selectedSlot?.map(e => {
            return e.index
        })
    }

    return (
        <Box>
            <Box className={classes.root_none}>
                {
                    availableSlot?.length === 0 ?
                        <Box className={classes.none}>
                            <Stack alignItems={"center"} spacing={2}>
                                <NoneSlot />
                                <Stack alignItems={"center"} spacing={1}>
                                    <Typography className={classes.noneTitle}>{t("SelectVenuetoviewslots")}</Typography>
                                    <Typography className={classes.noneContent}>{t("Slotswillbeviewableonceyouselectthevenue")}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                        :
                        <Box className={selectedSlot?.length === 0 ? classes.slot_not_select : classes.slot_section} >
                            <Grid container>
                                {
                                    availableSlot?.map((list, index) => {
                                        return (
                                            <Grid item sm={5.7} md={5.7} className={selectedIndex.includes(index) ? classes.selectedBox : classes.box} p={2} m={0.5} onClick={() => selectSlot(list, index)}>
                                                <Box>
                                                    <Typography className={selectedIndex.includes(index) ? classes.selectedTime : classes.time}>
                                                        {list?.check_in_date ? moment(list?.check_in_date).format("DD MMM YY") : moment(list?.check_in_time, "HH:mm").format("hh:mm A")} - {list?.check_out_date ? moment(list?.check_out_date).format("DD MMM YY") : moment(list?.check_out_time, "HH:mm").format("hh:mm A")}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                        <Typography className={selectedIndex.includes(index) ? classes.selectedContent : classes.content}>
                                                            {list?.check_in_date ? "24 hrs" : "1 hr"}
                                                        </Typography>
                                                        <Box className={classes.dot}></Box>
                                                        <Typography className={selectedIndex.includes(index) ? classes.selectedContent : classes.content}>
                                                            {`${selectedVenue?.participants_count} Persons / Slot`}
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                }
            </Box>
            <Box >
                {
                    selectedSlot?.length !== 0 &&
                    <Box className={classes.btnBox}>
                        <Stack direction={"row"} spacing={1}>
                            <Button variant="outlined" fullWidth disableElevation className={classes.walkinBtn} >{t("Bookforwalkin")}</Button>
                            <Button variant="contained" fullWidth disableElevation className={classes.bookBtn}
                                onClick={openPopup}>{t("BookforResident")}</Button>
                        </Stack>
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default withNamespaces("amenityBooking")(AvailableSlots)
