import { withNamespaces } from "react-i18next";
import { TableWithPagination } from "../../../components";
import { getInspectionMasterHeading, JobsPath, JobsType } from "../../../utils/insepectionMaster";

const Table = ({
    handleIcon = () => false,
    list = [],
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    t
}) => {

    const heading = getInspectionMasterHeading(t)

    return (

        <TableWithPagination
            heading={heading}
            rows={list?.list}
            path={JobsPath}
            dataType={JobsType}
            showpagination={true}
            count="2"
            showpdfbtn={false}
            showexcelbtn={false}
            showSearch={false}
            tableType="no-side"
            handleIcon={handleIcon}
            view={true}
            handlePagination={handlePagination}
            handleChangeLimit={handleChangeLimit}
            totalRowsCount={list?.count}
            page={page}
            limit={limit}
            height={`calc(100vh - 300px)`}
        />
    )
}
export default withNamespaces("inspectionItemmaster")(Table); 