import { useApolloClient } from "@apollo/client";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingSection } from "../../../components";
import { GET_PROPRTY_AND_UNIT } from "../../../graphql/queries";
import { AssetMasterType } from "../../../utils";
import { UnitSingleDetails } from "./singleUnitDetail";
import { filterStyles } from "./styles";
import { UnitCard } from "./unitCard";
import { Subheader } from '../../../components'

export const ViewAllDetails = (props) => {
    const classes = filterStyles();
    const [open, setOpen] = React.useState(false)
    const client = useApolloClient();
    const [details, setDetails] = React.useState({
        property: {},
        assets: {},
        asset: []
    })
    const [selectedUnits, setSelectedunit] = React.useState("")
    const [loading, setLoading] = React.useState(true)
    const [offset, setOffset] = React.useState(0);
    const [unit, setUnit] = React.useState([])

    const openDetails = (value) => {
        setOpen(true)
        setSelectedunit(value)
    }
    const getUnitDetails = (offset) => {
        client.query({
            query: GET_PROPRTY_AND_UNIT,
            fetchPolicy: 'network-only',
            variables: {
                masterType: AssetMasterType.image,
                id: props?.selectedProperty?.id,
                offset: offset,
                limit: 10,
                unit_id: props?.selectedProperty?.units?.map((val) => val?.id),
            }
        }).then((res) => {
            setDetails({
                property: res.data?.property?.[0],
                assets: res.data?.assets,
                asset: res.data?.asset
            })
            setUnit(unit.concat(res.data?.property?.[0]?.unit))

            setLoading(false)
        })
    }
    React.useEffect(() => {
        if (props?.selectedProperty?.id) {
            getUnitDetails(0)
        }

        // eslint-disable-next-line
    }, [])
    const handleShortlist = (id) => {
        if (props?.shortlistid?.some((val) => val === id)) {
            props?.setShortlistId(props?.shortlistid.filter((val) => val !== id));
        } else {
            props?.setShortlistId(props?.shortlistid.concat(id))
        }
    }
    const fetchMoreData = () => {
        setOffset(offset + 10)
        getUnitDetails(offset + 10);
    }
    return (
        <>
            <Subheader
                title={details?.property?.name ?? ""}
                isClose
                close={props?.onClose}
                hideBackButton={true}
            />
            {
                loading ? <LoadingSection /> :
                    <>
                        <InfiniteScroll
                            dataLength={details?.property?.unit}
                            next={fetchMoreData}
                            hasMore={true}
                            height={`calc(100vh - 170px)`}
                        >
                            <div className={classes.Detailroot1}>
                                <Box display="flex" alignItems="center">
                                    <Box flexGrow={1}>
                                        <Typography className={classes.detailTitle}>{details?.property?.unitCount?.[0]?.count_id} Vacant Units</Typography>
                                    </Box>
                                    <Box>

                                    </Box>
                                </Box>
                                <Box height="14px" />

                                <Grid container spacing={1.3}>
                                    {
                                        unit?.map((val) => {
                                            return (
                                                <Grid item xs={2}>
                                                    <UnitCard
                                                        type={val?.unit_purpose}
                                                        currency={val?.currencyByID?.symbol}
                                                        shortlistid={props?.shortlistid}
                                                        data={{ ...val, unit_type: val?.unit_type?.name }} handleShortlist={handleShortlist} onClick={openDetails} />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </div>
                        </InfiniteScroll>


                        <Dialog maxWidth="xs" className={classes.dialog} fullWidth open={open} onClose={() => setOpen(false)}>
                            <UnitSingleDetails
                                revenue_type={props?.revenue_type}
                                grace={props?.grace}
                                startDate={props?.startDate}
                                endDate={props?.endDate}
                                shortlistid={props?.shortlistid} handleShortlist={handleShortlist} selectedUnits={selectedUnits} onClose={() => setOpen(false)} />
                        </Dialog>
                    </>


            }
            {props?.btn}

        </>
    )
}