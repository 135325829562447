import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Label } from "../../../components";
import { AlertContext } from "../../../contexts";
import { AlertProps, enumSelect, enum_types } from "../../../utils";
import { useStyles } from "./styles";

export const RevenueTypeForm = ({ updateState = () => false, setDataNew = () => false, data = {}, onClose = () => false }) => {
    const classes = useStyles()
    const alert = React.useContext(AlertContext);
    const [enumValue, setEnumValue] = React.useState({
        revenue_type: [],
        purpose: []
    })
    //get Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.revenue_type, enum_types.purpose_global_type])
        setEnumValue({
            revenue_type: result?.revenue_type,
            purpose: [...result?.purpose_global_type?.filter(val => !Boolean(["Mixed", "Facilities"].includes(val?.value)))],

        })
    }
    React.useEffect(() => {
        getEnum()
        //eslint-disable-next-line
    }, []);
    //onChange purpose
    const onChangePurpose = (value) => {
        if (data?.revenue_type?.value === "Revenue based lease") {
            updateState("purpose", value, "revenue_type", { label: "Lease", value: "Lease" });
        } else {
            updateState("purpose", value);
        }
    }
    //onChange revenue type
    const onChangeRevenue = (value) => {
        updateState("revenue_type", value)
    }
    //onSubmit
    const onSubmit = () => {
        if (data?.revenue_type?.length === 0 || data?.purpose?.length === 0) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please Fill All Fields",
            })
        } else {
            onClose()
        }
    }
    return (
        <Box p={1} >
            <Box p={2} height="300px" >
                <Label label={"Purpose"} isRequired />
                {enumValue?.purpose?.map((value) => {
                    return <Button className={value?.value === data?.purpose?.value ? classes.buttonSelected : classes.buttonUnSelected}
                        onClick={() => onChangePurpose(value)}>
                        <Typography className={value?.value === data?.purpose?.value ? classes.buttonTextSelected : classes.buttonTextUnSelected}>
                            {value?.label}
                        </Typography>
                    </Button>
                })}
                <Box height="10px" />
                <Label label={"Revenue type"} isRequired />
                {(data?.purpose?.value === "Commercial" ?
                    [...enumValue?.revenue_type, { label: "Revenue based lease", value: "Revenue based lease" }] :
                    enumValue?.revenue_type)?.map((value) => {
                        return <Button className={value?.value === data?.revenue_type?.value ? classes.buttonSelected : classes.buttonUnSelected}
                            onClick={() => onChangeRevenue(value)}>
                            <Typography className={value?.value === data?.revenue_type?.value ? classes.buttonTextSelected : classes.buttonTextUnSelected}>
                                {value?.label}
                            </Typography>
                        </Button>
                    })}
                <Box height="80px" />
                <Button onClick={onSubmit} className={classes.btn} variant="contained" fullWidth>Submit</Button>
            </Box>

        </Box>
    )
}