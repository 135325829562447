import { Box, Button, Divider, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { config } from '../../../config'
import { AlertContext } from '../../../contexts'
import { NetworkCall } from '../../../networkcall'
import { AlertProps, NetWorkCallMethods } from '../../../utils'
import { CancelBookingStyle } from './style'

const CancelBooking = (
    {
        amenityBookingDetail = {},
        t,
        closeDecline = () => false,
        closeCancel = () => false,
        updateAmenityBookingList = () => false
    }
) => {
    const classes = CancelBookingStyle()
    const alert = React.useContext(AlertContext)
    const [remark, setRemark] = React.useState("")

    const cancelBooking = () => {
        const data = {
            booking_id: amenityBookingDetail?.booking_id,
            remarks: remark
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/cancel_booking`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                if (response?.data?.type === "success") {
                    closeDecline()
                    closeCancel()
                    updateAmenityBookingList()
                }
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                });
            });
    };

    return (
        <Box>
            <Stack p={2}>
                <Typography className={classes.header}>{t("ReasonforCancellation")}</Typography>
                <TextField
                    multiline
                    minRows={4}
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                />
            </Stack>
            <Divider></Divider>
            <Box p={1}>
                <Button variant='contained' fullWidth onClick={cancelBooking} className={classes.submitBtn}>Submit</Button>
            </Box>
        </Box>
    )
}

export default withNamespaces("amenityBooking")(CancelBooking)