
export const WaitingForApprovalPath = ["request_type", "requested_date", "request_against_detail", "existing_access", "requested_by", "name", "mobile_no", "email_id"];

export const WaitingForApprovalRow = [
    {
        request_type: "Agreement Access",
        requested_date: "02 Dec 22",
        request_against_detail: "AG12345",
        existing_access: "1",
        requested_by: "Bianca Andrews",
        name: "Roy Fox",
        mobile_no: "+919840012345",
        email_id: "rajesh@gmail.com",
    },
    {
        request_type: "Unit Access",
        requested_date: "02 Dec 22",
        request_against_detail: "Rubix Apartment, D Block, K-F01-U25",
        existing_access: "1",
        requested_by: "Dorthy Holland",
        name: "Diana Wallace",
        mobile_no: "+919840012345",
        email_id: "dinesh@yahoo.com",
    },
    {
        request_type: "Unit Access",
        requested_date: "02 Dec 22",
        request_against_detail: "Rubix Apartment, D Block, K-F01-U25",
        existing_access: "4",
        requested_by: "Louise Wills",
        name: "Chad Lee",
        mobile_no: "+919840012345",
        email_id: "raam@gmail.com",
    },
];

export const WaitingForApprovalHeading = [
    { title: "Request Type", field: "request_type" },
    { title: "Requested Date", field: "requested_date" },
    { title: "Request Against Detail", field: "request_against_detail" },
    { title: "Existing Access", field: "existing_access" },
    { title: "Requested By", field: "requested_by" },
    { title: "Name", field: "name" },
    { title: "Mobile No", field: "mobile_no" },
    { title: "Email ID", field: "email_id" },
];
export const WaitingForApprovalType = [
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "requested_date" },
    { type: ["description"], name: "request_against_detail" },
    { type: ["text"], name: "existing_access" },
    { type: ["text"], name: "requested_by" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "mobile_no" },
    { type: ["text"], name: "email_id" },
];


export const ApprovedPath = ["request_type", "requested_date", "request_against_detail", "existing_access", "requested_by", "name", "mobile_no", "email_id"];

export const ApprovedRow = [
    {
        request_type: "Agreement Access",
        requested_date: "02 Dec 22",
        request_against_detail: "AG12345",
        existing_access: "1",
        requested_by: "Bianca Andrews",
        name: "Roy Fox",
        mobile_no: "+919840012345",
        email_id: "rajesh@gmail.com",
    },
    {
        request_type: "Unit Access",
        requested_date: "02 Dec 22",
        request_against_detail: "Rubix Apartment, D Block, K-F01-U25",
        existing_access: "1",
        requested_by: "Dorthy Holland",
        name: "Diana Wallace",
        mobile_no: "+919840012345",
        email_id: "dinesh@yahoo.com",
    },
    {
        request_type: "Unit Access",
        requested_date: "02 Dec 22",
        request_against_detail: "Rubix Apartment, D Block, K-F01-U25",
        existing_access: "4",
        requested_by: "Louise Wills",
        name: "Chad Lee",
        mobile_no: "+919840012345",
        email_id: "raam@gmail.com",
    },
];

export const ApprovedHeading = [
    { title: "Request Type", field: "request_type" },
    { title: "Requested Date", field: "requested_date" },
    { title: "Request Against Detail", field: "request_against_detail" },
    { title: "Existing Access", field: "existing_access" },
    { title: "Requested By", field: "requested_by" },
    { title: "Name", field: "name" },
    { title: "Mobile No", field: "mobile_no" },
    { title: "Email ID", field: "email_id" },
];

export const ApprovedType = [
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "requested_date" },
    { type: ["text"], name: "request_against_detail" },
    { type: ["text"], name: "existing_access" },
    { type: ["text"], name: "requested_by" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "mobile_no" },
    { type: ["text"], name: "email_id" },
];

export const DeclinedPath = ["request_type", "requested_date", "request_against_detail", "existing_access", "requested_by", "name", "mobile_no", "email_id"];

export const DeclinedRow = [
    {
        request_type: "Agreement Access",
        requested_date: "02 Dec 22",
        request_against_detail: "AG12345",
        existing_access: "1",
        requested_by: "Bianca Andrews",
        name: "Roy Fox",
        mobile_no: "+919840012345",
        email_id: "rajesh@gmail.com",
    },
    {
        request_type: "Unit Access",
        requested_date: "02 Dec 22",
        request_against_detail: "Rubix Apartment, D Block, K-F01-U25",
        existing_access: "1",
        requested_by: "Dorthy Holland",
        name: "Diana Wallace",
        mobile_no: "+919840012345",
        email_id: "dinesh@yahoo.com",
    },
    {
        request_type: "Unit Access",
        requested_date: "02 Dec 22",
        request_against_detail: "Rubix Apartment, D Block, K-F01-U25",
        existing_access: "4",
        requested_by: "Louise Wills",
        name: "Chad Lee",
        mobile_no: "+919840012345",
        email_id: "raam@gmail.com",
    },
];

export const DeclinedHeading = [
    { title: "Request Type", field: "request_type" },
    { title: "Requested Date", field: "requested_date" },
    { title: "Request Against Detail", field: "request_against_detail" },
    { title: "Existing Access", field: "existing_access" },
    { title: "Requested By", field: "requested_by" },
    { title: "Name", field: "name" },
    { title: "Mobile No", field: "mobile_no" },
    { title: "Email ID", field: "email_id" },
];

export const DeclinedType = [
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "requested_date" },
    { type: ["text"], name: "request_against_detail" },
    { type: ["text"], name: "existing_access" },
    { type: ["text"], name: "requested_by" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "mobile_no" },
    { type: ["text"], name: "email_id" },
];