import { makeStyles } from "@mui/styles"

export const InvoiceDropDownStyles = makeStyles((theme)=>({
    dropdown: {
        borderRadius: 6,
        cursor: "pointer",
        width:"100%",
    },
    selected:{
        background:theme.palette.info.light,
        color:theme.palette.info.main,
        padding:6,
    },
    selectedAmount:{
        fontSize:16,
        fontWeight:"bold"
    },
    selectedType:{
        fontSize:12,
        fontWeight:400
    },
    list:{
        background:"#fff",
        color:theme.typography.color.primary
    },
    firstRowText:{
        color:theme.typography.color.primary,
        fontSize:11,
        fontWeight:"bold",
        whiteSpace:"pre"
    },
    secondRowText:{
        fontSize:10,
        color:theme.typography.color.tertiary
    },
    second:{
        marginLeft:4
    },
    dot:{
        height:6,
        width:6,
        borderRadius:50,
        background:theme.palette.border.secondary
    },
    listAmount:{
        fontSize:11,
        fontWeight:"bold"
    },
}))