import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { LoadingSection, UseDebounce } from "../../../components";
import { GET_INVOICES } from "../../../graphql/agreementQurery";
import { InvoiceDetails, InvoiceTable } from "../components";
import { InvoiceDropdownQuery } from "../../../graphql/invoice";
import {timeZoneConverter} from "../../../utils";

const Invoicesection = ({ id = "", details = {}, t }) => {
    const [open, setOpen] = React.useState({ id: null, bool: false })
    const client = useApolloClient();
    const [loading, setLoading] = React.useState(true)
    const [tabledetails, setTableDetails] = React.useState()
    const [data, setData] = React.useState({
        list: [],
        count: 0
    })
    //get  invoices
    const getInvoice = (offset = 0, limit = 10, search = "") => {
        client.query({
            query: GET_INVOICES,
            fetchPolicy: 'network-only',
            variables: {
                id: id,
                offset: offset,
                limit: limit,
                search: search
            }
        }).then((response) => {

            setData({
                list: response?.data?.invoice?.map((val) => {
                    return {
                        ...val,
                        tax_group: val?.vat_group?.group_name,
                        payment_status: val?.payment_status === "no-due" ? "Paid" : "Unpaid",
                    }
                }),
                count: response?.data?.count?.[0]?.count
            })
            setLoading(false)
        }).catch((err) => {
            console.log(err)
        })
    }
    //initial load
    React.useEffect(() => {
        getInvoice()
        getInvoiceDropdown("")
        // eslint-disable-next-line
    }, [])

    // get Receipt data

    console.log("id =>", id, "open =>", open)
    const getInvoiceDropdown = (e) => {
        client
            .query({
                query: InvoiceDropdownQuery,
                fetchPolicy: "network-only",
                variables: {
                    invoice_id: id,
                    search: e ?? ""
                },
            })
            .then((response) => {
                let agreementDataArray = []
                response.data.invoice_receipt_settlement.map((i) => {
                    agreementDataArray.push(
                        {
                            amount_tagged: i.amount_tagged,
                            created_at: timeZoneConverter(i.created_at),
                            tagged_by: i.tagged_by,
                            reciepts_no: i.reciepts.reciepts_no,
                            source: "-",
                            url: i.reciepts.url,
                            settlement_no:i.settlement_no,
                            symbol:i?.invoice.company.currency.symbol
                        }                    
                    )
                    return 0
                }
                )
                setTableDetails(agreementDataArray)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // Search function

    const [searchText, setSearchText] = React.useState("");
    const debounce = UseDebounce();

    const handleSearch = (e) => { 
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        getInvoiceDropdown(e)
    }

    const handleClick = (val) => {
        setOpen({
            bool: !open?.bool,
            id: val
        })
    }


    return (
        <Box >
            {
                loading ?
                    <LoadingSection message={t("loading")} top="20vh" />
                    :
                    <>
                        {
                            open?.bool ?
                                <InvoiceDetails setLoading={setLoading} goBack={handleClick} details={details} inVoice_id={open?.id} tabledetails={tabledetails} 
                                searchText={searchText} handleSearch={handleSearch} />
                                :
                                <InvoiceTable handleClick={handleClick} getInvoice={getInvoice} id={id} setData={setData} data={data} />

                        }
                    </>
            }


        </Box>
    )
}
export default withNamespaces("agreement")(Invoicesection); 