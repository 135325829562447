import { enumSelect, NetWorkCallMethods } from ".";
import { config } from "../config";
import { NetworkCall } from "../networkcall";


export const loadOptions = async (
  search = "",
  array = [],
  handleLoading = null,
  queryFn,
  extractLabel,
  functionParams = {},
  mappingVariable = {},
  queryVariables = {},
  manualResponse,
  company_id = "",
  appraisal = false
) => {
  let result,
    query,
    offset = 0;

  if (search && !Boolean(array?.length)) {
    offset = 0;
  } else if (functionParams?.all) {
    offset = (offset === 0 && array?.length > 0) ? (array?.length - 1) : array?.length;
  } else {
    offset = array?.length;
  }
  query = queryFn(offset, 10, search, { ...functionParams }, company_id).loc.source.body;
  result = await networkCallBack(query, queryVariables, handleLoading, { ...functionParams });
  //Select options should be label,value pairs like ({label:'',value:''})
  if (mappingVariable?.label || mappingVariable?.value) {
    if (appraisal) {
      result[extractLabel] = result?.[extractLabel]?.map((_) => ({
        label: _?.[mappingVariable?.label ?? "label"],
        value: _?.[mappingVariable?.value ?? "value"],
        unit_count: appraisal
          ? _?.[mappingVariable?.unit_count ?? "unit_count"]
          : undefined,
        address: appraisal
          ? _?.[mappingVariable?.address ?? "address"]
          : undefined,
      }));
    } else {
      result[extractLabel] = result?.[extractLabel]?.map((_) => ({
        label: _?.[mappingVariable?.label ?? "label"],
        value: _?.[mappingVariable?.value ?? "value"],
      }));
    }
  }

  //manually construct array
  if (manualResponse) {
    result[extractLabel] = manualResponse(result[extractLabel]);
  }

  return {
    options: functionParams?.all ?
      (offset === 0 ? [{ value: null, label: "All" }, ...result?.[extractLabel]] :
        [...result?.[extractLabel]]) : [...result?.[extractLabel]],
    hasMore:
      array?.length + result?.[extractLabel]?.length < result?.count[0]?.count,
  };
};

const networkCallBack = async (query, variable = {}, handleLoading, functionParams = {}) => {
  let payload = {
    query,
    variable,
  };

  const options = await NetworkCall(
    `${functionParams?.url ? functionParams?.api_url : config.graphql_url}`,
    NetWorkCallMethods.post,
    payload,
    null,
    true,
    false
  )
    .then((response) => {
      let main = response.data.data;
      handleLoading && handleLoading();
      return main;
    })
    .catch((error) => {
      handleLoading && handleLoading();
      return null;
    });

  return options;
};



export const loadOptionsApis = async (
  endpoint,
  queryVariables,
  search = "",
  array = [],
  handleLoading = null,
  extractLabel,
  mappingVariable = {},
  manualResponse,
) => {


  let result,
    offset = 0;

  if (search && !Boolean(array?.length)) {
    offset = 0;
  } else {
    offset = array?.length;
  }
  if (endpoint === "/enum") {
    result = await enumSelect(queryVariables);
  } else {
    result = await networkCallBackApi(endpoint, { ...queryVariables, search }, handleLoading, offset);
  }
  //Select options should be label,value pairs like ({label:'',value:''})
  if (mappingVariable?.label || mappingVariable?.value) {
    result[extractLabel] = result?.[extractLabel]?.map((_) => ({
      label: _?.[mappingVariable?.label ?? "label"],
      value: _?.[mappingVariable?.value ?? "value"],
    }));
  }

  //manually construct array
  if (manualResponse) {
    result[extractLabel] = manualResponse(result[extractLabel]);
  }
  return {
    options: [...result?.[extractLabel]],
    hasMore:
      array?.length + result?.[extractLabel]?.length < result?.count,
  };
};

const networkCallBackApi = async (endpoint, variable = {}, handleLoading, offset) => {

  let payload = {
    ...variable,
    offset,
    limit: 10
  };

  const options = await NetworkCall(
    `${config.api_url}/${endpoint}`,
    NetWorkCallMethods.post,
    payload,
    null,
    true,
    false
  )
    .then((response) => {
      let main = response.data;
      handleLoading && handleLoading();
      return main;
    })
    .catch((error) => {
      handleLoading && handleLoading();
      return null;
    });

  return options;
};

