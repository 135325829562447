import React, { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { ArrowIcon } from "../../../../assets";
import { renewalRequest } from "../../../../utils/agreementUtils";
import { AlertDialog } from "../../../../components";
import { useStyleTimeLine } from "../../styles";
import { TermisionRequestForm } from "./customRequest";
import { AsPerAgreement } from "./asPerAgreement";

export const RenewalRequest = ({ t, open = false, setOpen = () => false }) => {
  const classes = useStyleTimeLine();
  const [dialog, setDialog] = useState({
    bool: false,
    type: "",
  });
  const onCloseDialog = (type) => {
    console.log(type, "ss");
    setDialog({
      bool: !dialog.bool,
      type: type,
    });
    setOpen(false);
  };
  return (
    <div>
      <AlertDialog
        isNormal={true}
        header={t("Termination_Request")}
        onClose={() => setOpen(false)}
        open={open}
        component={
          <>
            {renewalRequest(t, onCloseDialog)?.map((x, i) => {
              return (
                <>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    justifyContent="space-between"
                    p={2}
                    onClick={x?.onClick}
                  >
                    <Box display="flex" alignItems={"center"}>
                      {x?.icon}
                      <Box width={"14px"} />
                      <Box>
                        <Typography className={classes.renewalTitle}>
                          {x?.title}
                        </Typography>
                        <Typography className={classes.renewalDes}>
                          {x?.des}
                        </Typography>
                        <Box height={"4px"} />
                        <Typography></Typography>
                      </Box>
                    </Box>
                    <ArrowIcon />
                  </Box>
                  {!i > 0 && <Divider />}
                </>
              );
            })}
          </>
        }
      />
      <AlertDialog
        isNormal={true}
        header={dialog?.custom === "custom " ? t("Custom") : t("As_Per_Agreement")}
        onClose={() => onCloseDialog(false)}
        open={dialog.bool}
        component={
          <>
            {dialog?.custom === "custom" ? (
              <TermisionRequestForm t={t} />
            ) : (
              <AsPerAgreement t={t} />
            )}
          </>
        }
      />
    </div>
  );
};
