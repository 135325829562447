import { Grid,Stack, Typography,Box } from '@mui/material';
import React from 'react';
import { CalendarDateChange } from '../../../components/calendarDateChange';
import { DropdownCard } from './dropdownCard';
import {useStyles} from "./style";

export const CalHeader = ({ state = {}, prev = () => false, next = () => false, resource = [], setSeletedType = () => false, selectedType = {} }) => {

   const classes=useStyles()
    return (
        <Grid container alignItems={"center"} justifyContent="space-between">
            <Grid item >
                <Box width="200px">
                <DropdownCard setSeletedType={setSeletedType} selectedType={selectedType} options={resource} />
                </Box>
            </Grid>
            <Grid item>
                <CalendarDateChange state={state} prev={prev} next={next} />
            </Grid>
            <Grid item >
                <Stack direction="row" spacing={2} alignItems="center" marginRight="12px">
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Box className={classes.box}/>
                        <Typography className={classes.desc}>Week Off Days</Typography>
                    </Stack >

                    <Stack direction="row" spacing={1} alignItems="center">
                        <Box className={classes.box1}/>
                        <Typography className={classes.desc}>Time Off Day</Typography>
                    </Stack>

                </Stack>
            </Grid>
        </Grid>
    )
}