import React from "react";
import { withNavBars } from "../../HOCs";
import { PropertyDashBoard } from "./propertymanagerdashboard";

class PropertyDashboardParent extends React.Component {
    render() {
        return <PropertyDashBoard {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(PropertyDashboardParent, props);