import React from "react";
import { withNavBars } from "../../HOCs";
import { ShortListUnits } from "./shortlistedUnits";

class ShortListedUnitsParent extends React.Component {
    render() {
        return <ShortListUnits btns={true} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ShortListedUnitsParent, props);
