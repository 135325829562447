import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { DialogBox, LogoUpload, MobileNumberInputComponent, TextBox ,AddressCard} from "../../../components";
import { Address } from '../../../components/quotationContact/component';
import { CreateQuotationListContext } from "../../../contexts/createQuotationContext";
import { NewLeadStyles as useStyles } from "./styles";
export const LeadDetailsnew = (props) => {
  const classes = useStyles();
  const { dataNew, updateStateNew } = React.useContext(CreateQuotationListContext);
  const [open, setOpen] = React.useState(false)


  //submit address
  const submitAddress = (value) => {
    updateStateNew("address", value)
    setOpen(false)
  }
  return (
    <>
      <Grid container spacing={1} className={classes.contactRoot}>
        <Grid item xs={12} md={12} lg={2} className={classes.profilesection}>
          <LogoUpload
            logo_title={"PROFILE IMAGE"}
            handleChange={(value) => updateStateNew("image_url", value)}
            data={dataNew?.image_url}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={10}>
          <Grid container spacing={3} p={2}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextBox
                isrequired
                label="Name"
                placeholder=""
                isReadonly={dataNew?.disableForm}
                onChange={(value) => updateStateNew("name", value.target.value)}
                isError={dataNew.error.name.length > 0}
                errorMessage={dataNew.error.name}
                value={dataNew.name}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4.5} className={classes.field}>
              <MobileNumberInputComponent
                isRequired
                label="Mobile"
                placeholder=""
                isReadonly={dataNew?.disableForm}
                handleChange={(value) => updateStateNew("mobile", value)}
                isError={dataNew.error.mobile.length > 0}
                errorMessage={dataNew.error.mobile}
                value={dataNew.mobile}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4.5} className={classes.field}>
              <MobileNumberInputComponent
                label="Alternative Mobile"
                placeholder=""
                handleChange={(value) => updateStateNew("alter_mobile", value)}
                value={dataNew.alter_mobile}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextBox
                label="Email Address"
                placeholder=""
                isrequired
                isReadonly={dataNew?.disableForm}
                onChange={(value) =>
                  updateStateNew("email_id", value.target.value)
                }
                isError={dataNew.error.email_id.length > 0}
                errorMessage={dataNew.error.email_id}
                value={dataNew.email_id}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4.5} className={classes.field}>
              <MobileNumberInputComponent
                label="Telephone"
                placeholder=""
                handleChange={(value) => updateStateNew("telephone", value)}
                value={dataNew.telephone}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4.5} className={classes.field}>
              <MobileNumberInputComponent
                label="Fax"
                placeholder=""
                handleChange={(value) => updateStateNew("fax", value)}
                value={dataNew.fax}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.address}>
          <Box display="flex" alignItems="center" p={1}>
            <Box flexGrow={1}>
              <Typography className={classes.addresstitle}>Address</Typography>
            </Box>
            <Box>
              <Typography
                className={classes.addAddress}
                onClick={() => setOpen(true)}
              >
                Add Address
              </Typography>
            </Box>
          </Box>
          <Box className={classes.addressBox}>
            {dataNew?.address?.country && (
              <AddressCard
                data={dataNew?.address}
                name={dataNew.name}
                buttontext="primary"
                editClick={() => setOpen(true)}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.field}>
          <Typography className={classes.addresstitle}>
            Social Profile
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={3} className={classes.field}>
              <TextBox
                label="Facebook"
                placeholder="Enter facebook id"
                onChange={(value) =>
                  updateStateNew("facebook", value.target.value)
                }
                value={dataNew.facebook}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} className={classes.field}>
              <TextBox
                label="Twitter"
                placeholder="Enter Twitter id"
                onChange={(value) =>
                  updateStateNew("twitter", value.target.value)
                }
                value={dataNew.twitter}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} className={classes.field}>
              <TextBox
                label="Linkdedin"
                placeholder="Enter Linkdedin id"
                onChange={(value) =>
                  updateStateNew("linkedIn", value.target.value)
                }
                value={dataNew.linkedIn}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogBox
        open={open}
        maxWidth={"sm"}
        onClose={() => setOpen(false)}
        header={"Add Address"}
        component={
          <Address
            data={dataNew?.address}
            onCancel={() => setOpen(false)}
            onSubmit={submitAddress}
          />
        }
      />
    </>
  );
};
