import gql from "graphql-tag";
export const GET_WELCOME_BANNER = gql`
query {
  company_master(where: { id: { in: $id } }) {
    id
    name
    welcome_screen_logo
  }
}

`