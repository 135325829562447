import { Box, Grid, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import { withNamespaces } from "react-i18next";
import { DocumentViewer } from "../../../components/fileViewer";
import { QuotationDetailCard } from "../components";
import { AggrementTabStyles } from "./styles";

const QuotationPreview = ({ details = {}, t }) => {
    const classes = AggrementTabStyles()
    const QuotationCardHeader = {
        quotationNumber: details?.quotation_no,
        type: t("quotation"),
        sub1: details?.lease_period,
        sub2: details?.currency?.symbol
    }
    const QuotationCardTable = [
        {
            tableHeader: t("totalAmount"),
            amount: `${details?.currency?.symbol} ${details?.total_amount}`
        },
        {
            tableHeader: t("tax"),
            amount: `${details?.currency?.symbol} ${details?.total_tax}`
        },
        {
            tableHeader: t("totalQuoteAmount"),
            amount: `${details?.currency?.symbol} ${details?.total_amount + details?.total_tax}`,
            type: "total"
        }
    ]
    //download pdf
    const downloadPdf = () => {
        if (details?.quotation_assets?.[0]?.url) {
            saveAs(details?.quotation_assets?.[0]?.url, `${details?.quotation_no}.pdf`)
        }
    }

    return (
        <Box >
            <Grid container >
                <Grid item xs={4} p={2}>
                    <QuotationDetailCard onClick={downloadPdf} value={QuotationCardTable} headerDet={QuotationCardHeader} />
                </Grid>
                <Grid item xs={8} className={classes.pdfSection}>
                    <center>
                        {
                            details?.quotation_assets?.[0]?.url ?
                                <DocumentViewer url={details?.quotation_assets?.[0]?.url} />
                                :
                                <Typography className={classes.title}>No Date Found</Typography>
                        }

                    </center>
                </Grid>
            </Grid>


        </Box>
    )
}
export default withNamespaces("agreement")(QuotationPreview); 