import React from "react";
import { TableWithPagination } from "../../../components";
import { UnitHeading, UnitPath } from "../common";

export const UnitTable = (props) => {
  return (
    <div>
      <TableWithPagination
        heading={UnitHeading}
        marginTop={'1px'}
        rows={props?.unitList?.data ?? []}
        path={UnitPath}
        showpagination={true}
        showpdfbtn={false}
        showexcelbtn={false}
        showSearch={false}
        handlePagination={props?.handlePagination}
        handleChangeLimit={props?.handleChangeLimit}
        page={props?.page}
        limit={props?.limit}
        totalRowsCount={props?.unitList?.count}
        tableType="no-side"
        count="2"
        dataType={[
          { type: ["avatarmanagement"], name: "image" },
          { type: ["text"], name: "name" },
          { type: ["text"], name: "id" },
          { type: ["text"], name: "propertyname" },
        ]}
        height={"calc(100vh - 350px)"}
        view={true}
        edit={true}
        delete={true} />
    </div>
  );
};
