import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold, Regular } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  cardRoot: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 10px 16px #0000000A",
    marginLeft: "12px",
  },

  borderBottom: {
    padding: "12px",
    borderBottom: "1px solid #E4E8EE",
  },
  title: {
    color: "#091B29",
    fontFamily: Bold,
    fontSize: "16px",
    borderRadius: "4px",
  },
  red: {
    backgroundColor: "#FF4B4B",
    color: "white",
    "&:hover": {
      backgroundColor: "#FF4B4B",
    },
  },
  green: {
    backgroundColor: "#5AC782",
    color: "white",
    "&:hover": {
      backgroundColor: "#5AC782",
    },
  },
  border: {
    borderLeft: "2px dashed #E4E8EE",
    padding: "0px 20px",
    position: "relative",
    // "&:last-child": {
    //     borderLeft: "none",
    // },
  },
  border1: {
    borderLeft: "2px dashed #5078E1",
    padding: "0px 20px",
    position: "relative",
  },

  subTitle: {
    color: "#98A0AC",
    fontSize: "12px",
  },
  tag: {
    color: "#5AC782",
    backgroundColor: "#EEF9EE",
    fontSize: "12px",
    fontFamily: Bold,
    padding: "2px 10px",
    borderRadius: "2px",
    display: "inline-block",
  },
  avatar: {
    position: "absolute",
    top: 20,
    left: -16,
  },
  avatar0: {
    position: "absolute",
    top: 20,
    left: -18,
    backgroundColor: "#FFF4EB",
  },
  avatars: {
    position: "absolute",
    top: 20,
    left: -18,
  },
  avatar1: {
    position: "absolute",
    top: 20,
    left: -18,
    height: "34px",
    width: "34px",
    backgroundColor: "#FFECEC",
  },
  assigned: {
    color: "#4E5A6B",
    fontFamily: SemiBold,
    fontSize: "12px",
    marginTop: "6px",
  },
  link: {
    textDecoration: "underline",
    color: "#78B1FE",
    cursor: "pointer",
  },
  popover: {
    "& .MuiPopover-paper": {
      padding: "0px",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    marginTop: "-20px",
    marginLeft: "10px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  personRoot: {
    padding: "8px 12px",
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
  },
  name: {
    color: "#091B29",
    fontFamily: Bold,
    fontSize: "14px",
    borderRadius: "4px",
  },
  remark: {
    fontSize: "12px",
    wordBreak: "break-all",
  },
  remarkBold: {
    fontSize: "12px",
  },
  remarkRoot: {
    // backgroundColor: (props) => props?.backgroundColorCard ?? "#F5F7FA",
    padding: "5px 1px",
    borderRadius: "4px",
    marginTop: "6px",
    textAlign:"left"
  },
  seeMoreLessTextStyle: {
    fontSize: "12px",
    fontFamily: SemiBold,
    color: theme.palette.primary.main,
  },
  accountListRoot: {
    height: "200px",
    overflow: "auto",
    boxShadow: "0px 0px 16px #00000014",
    marginTop: "10px",
  },
  btn: {
    padding: "8px 12px",
    boxShadow: "none",
  },
  btn1: {
    padding: "8px 12px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  contactList: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    width: "100%",
    backgroundColor: "white",
    borderRadius: "4px",
    zIndex: "1",
    boxShadow: "0px 0px 16px #00000014",
    height: "220px",
    overflow: "auto",
  },
  selectedList: {
    backgroundColor: "#F5F7FA",
    borderRadius: "16px",
    marginTop: "12px",
  },
  tag1: {
    color: "#FF4B4B",
    backgroundColor: "#FFECEC",
    fontSize: "12px",
    fontFamily: Bold,
    padding: "2px 10px",
    borderRadius: "2px",
    display: "inline-block",
  },
  terminationCardRoot: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "23px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  treeBox: {
    maxWidth: "630px",
    width: "100%",
    marginLeft: "16px",
  },
  tag2: {
    color: "#FF4B4B",
    backgroundColor: "#FFECEC",
    fontSize: "12px",
    fontFamily: Bold,
    padding: "2px 10px",
    borderRadius: "2px",
    display: "inline-block",
  },
  timeLineMain: {
    backgroundColor: (props) => props?.backgroundColor ?? "#fff",
    padding: "10px",
    borderRadius: "4px",
  },
  TerminationTitle: {
    color: "#FF4B4B",
    fontSize: "16px",
    fontFamily: Bold,
  },
  TerminationDescription: {
    color: "#98A0AC",
    fontSize: "12px",
    fontFamily: Regular,
  },
  arrow: {
    color: "#7C8594",
    fontSize: "16px",
  },
  terminationBox: {
    marginLeft: "15px",
    maxWidth: "500px",
    width: "100%",
    margin: "0 auto",
  },
  statusComponentTitle: {
    color: "#091B29",
    fontSize: "16px",
    fontFamily: Bold,
  },
  statusComponentSubtitle:{
    color: "#98A0AC",
    fontSize: "12px",
    fontFamily: Regular,
  },
  statusComponentPraeant: {
    border: "1px solid #E4E8EE",
    borderRadius: "8px",
    padding: "16px",
    textAlign: "center",
    // width: "340px",
    position: "relative",
    marginLeft: "12px",
    boxShadow:'0px 0px 16px #00000014',
  },
  statusMain: {
    display: "flex",
    justifyContent: "flex-end",
  },
  next: {
    marginLeft: (props) => props?.marginLeft ?? "0px",
    fontFamily: SemiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    width: "100%",
    display:'flex',
    alignItems:'center',
    justifyContent:"space-between",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  Cancel: {
    border: `1px solid ${theme?.palette?.primary?.main}`,
    color: theme?.palette?.primary?.main,
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: SemiBold,
    backgroundColor: theme?.palette?.background?.tertiary1,
    width: "100%",
    "&:hover": {
      backgroundColor: theme?.palette?.background?.tertiary1,
    },
  },
  absoluteIcon: {
    position: "absolute",
    top: 0,
    left: "-48px",
  },
  statusTitle: {
    color: "#CED3DD",
    fontSize: "14px",
    fontFamily: Bold,
    marginBottom: "13px",
  },
  parent: {
    height: "calc(100vh - 300px)",
    overflow: "auto",
    paddingBottom: "35px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  parent1: {
    height: "calc(100vh - 228px)",
    overflow: "auto",
    paddingBottom: "20px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  avatars1: {
    position: "absolute",
    top: 30,
    left: -18,
  },
  subTitleTimeLine: {
    color: theme?.palette?.background?.primary,
    fontSize: "12px",
    fontFamily: Regular,
  },
  downBtn: {
    border: `1px solid ${theme?.typography?.color?.primary}`,
    color: theme?.typography?.color?.primary,
    backgroundColor: "white",
    "&:hover": {
        backgroundColor: "white",
        border: `1px solid ${theme?.typography?.color?.primary}`,
    }
},
skip: {
  fontFamily: SemiBold,
  color: theme?.palette?.primary?.main,
  border: `1px solid ${theme?.palette?.primary?.main}`,
  width: "100%",
  display:'flex',
    alignItems:'center',
    justifyContent:"space-between",
  "&:hover": {
    border: `1px solid ${theme?.palette?.primary?.main}`,
    backgroundColor:"white"
  },
},
decline:{
  fontFamily: SemiBold,
  color: theme?.palette?.error?.main,
  border: `1px solid ${theme?.palette?.error?.main}`,
  width: "100%",
  display:'flex',
    alignItems:'center',
    justifyContent:"space-between",
  "&:hover": {
    border: `1px solid ${theme?.palette?.error?.main}`,
    backgroundColor:"white"
  },
},
countinuitytreeBox:{
  width: "100%",
  marginLeft: "16px",
maxWidth:"522px"
},
countinuityRemarkRoot: {
  backgroundColor: (props) => props?.backgroundColorCard ?? "#F5F7FA",
  padding: "8px 12px",
  borderRadius: "4px",
  marginTop: "6px",
  width: "fit-content",
},
loader:{
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  height:"calc(100vh - 351px)"
},
  modaltitle: {
  fontSize: "16px",
  color: theme?.typography?.color?.primary,
  fontFamily: SemiBold
},
createnewsection:{
  display:"flex",
  alignItems:"center",
  justifyContent: "space-between",
},
btnSelction:{
  marginLeft: (props) => props?.marginLeft ?? "0px",
  fontFamily: SemiBold,
  color: theme?.palette?.background?.tertiary1,
  backgroundColor: theme?.palette?.primary?.main,
  width: "100%",
  display:'flex',
  alignItems:'center',
  justifyContent:"space-center",
  "&:hover": {
    backgroundColor: theme?.palette?.primary?.main,
  },
},
proceedDialog: {
  padding: "54px 50px 51px 228px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  backgroundImage: `url(${"images/announsment.svg"})`,
  backgroundRepeat: "no-repeat",
  backgroundColor:theme?.palette?.primary?.main
},
  convert: {
  color: "white",
  fontSize: "16px",
  fontFamily: Bold,
},
convertProcess: {
  color: "white",
  fontSize: "16px",
  fontFamily: Regular,
  marginTop:'7px'
},
yes: {
  color: theme?.palette?.primary?.main,
  fontSize: "14px",
  fontFamily: Bold,
  border: `1px solid white`,
  backgroundColor: "white",
  padding: "7px 29px",
  width: "100%",
  "&:hover": {
    border: `1px solid white`,
    backgroundColor: "white",
  },
},
No: {
  color: "white",
  fontSize: "14px",
  fontFamily: SemiBold,
  border: `1px solid white`,
  padding: "7px 29px",
  backgroundColor: theme?.palette?.primary?.main,
  marginLeft: "10px",
  width: "100%",
  "&:hover": {
    border: `1px solid white`,
    backgroundColor: theme?.palette?.primary?.main,
  },
},
  marginTop:{
  marginTop:"24px"
}
}));


export const useStyleTimeLine = makeStyles((theme, props) => ({
  selected: {
    padding: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  displayBox: {
    display: "flex",
    alignItems: "center",
    padding: "6px 10px",
    // backgroundColor: theme?.palette?.info?.light,
    borderRadius: "246px",
    position: "relative",
    height: "100%",
    cursor:"pointer"
  },
  tabRoot: {
    display: "flex",
    padding: "16px",
    borderBottom: `1px solid ${theme?.palette?.border?.secondary}`,
  },
  subTitleTab: {
    fontSize: "12px",
    fontFamily: Regular,
    marginTop: "3px",
  },
  title: {
    fontSize: "12px",
    fontFamily: Bold,
  },
  absolute: {
    borderTop: `2px dashed #E4E8EE`,
    position: "absolute",
    width: "26px",
    right: "-27px",
  },
  componentTitle: {
    fontSize: "14px",
    fontFamily: Bold,
    color: (props) => props?.color ?? "",
  },
  componentsubTitle: {
    fontSize: "12px",
    fontFamily: SemiBold,
    color: (props) => props?.color ?? "",
  },
  cardBox: {
    backgroundColor: (props) => props?.backgroundColor ?? "",
    borderRadius: "4px",
    border: (props) => props?.border ?? "none",
    boxShadow: (props) => props?.boxShadow ?? "none",
    padding: "16px",
    "@media (hover: none)": {
      backgroundColor: "transparent",
    },
  },
  skipFormTitle: {
    color: theme?.typography?.color?.primary,
    fontFamily: Bold,
    fontSize: "14px",
  },
  skipFormsubTitle: {
    color: theme?.typography?.color?.tertiary,
    fontFamily: Regular,
    fontSize: "12px",
  },
  Yes: {
    fontFamily: Bold,
    color: "white",
    backgroundColor: theme?.palette?.primary?.main,
    width: "100%",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
      color: "white",
    },
    padding: "8px 12px",
  },
  // No: {
  //   padding: "8px 12px",
  //   backgroundColor: "white",
  //   color: theme?.typography?.color?.primary,
  //   fontSize: "14px",
  //   fontWeight: 600,
  //   fontFamily: SemiBold,
  //   width: "100%",
  //   border: `1px solid ${theme?.palette?.border?.secondary}`,
  //   "&:hover": {
  //     backgroundColor: "white",
  //   },
  // },
  countinuityCard: {
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    borderRadius: "4px",
    padding: "21px",
    textAlign: "center",
    width: "100%",
  },
  countinuityTitle: {
    fontFamily: Bold,
    fontSize: "14px",
    color: theme?.typography?.color?.primary,
  },
  agreementRequest: {
    color: "#78B1FE",
    fontFamily: Bold,
    fontSize: "14px",
  },
  agreementRequestCard: {
    border: `1px solid #78B1FE`,
    borderRadius: "4px",
    padding: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
  },
  slideCard: {
    border: "1px solid #E4E8EE",
    boxShadow: "0px 0px 16px #00000014",
    padding: "16px",
    borderRadius: "4px",
  },
  sliderTitle: {
    fontSize: "12px",
    fontFamily: Bold,
    color: theme?.palette?.background?.primary,
  },
  closeIcon: {
    color: theme?.palette?.background?.primary,
    fontSize: "14px",
    fontFamily: Bold,
    cursor: "pointer",
  },
  requestTitle: {
    color: theme?.typography?.color?.tertiary,
    fontSize: "14px",
    fontFamily: SemiBold,
    cursor: "pointer",
  },
  cardSlide: {
    padding: "12px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  absoluteCard: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "white",
    width: "100%",
  },
  relativeCard: {
    height: `calc(100vh - 323px)`,
    overflow: "auto",
    position: "relative",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  renewalTitle: {
    fontSize: "16px",
    fontFamily: Bold,
    color: theme?.typography?.color?.primary,
  },
  renewalDes: {
    fontSize: "14px",
    fontFamily: Regular,
    color: theme?.typography?.color?.tertiary,
    marginTop:"4px"
  },
  customerIcon:{
borderRadius:"4px"
  },
  customerTitle:{
    fontSize: "14px",
    fontFamily: Bold,
    color: theme?.typography?.color?.primary,
  },
  customerSub:{
    fontSize: "12px",
    fontFamily: SemiBold,
    color: theme?.typography?.color?.secondary,
  },
  dot:{
    backgroundColor:theme?.palette?.border?.primary,
    width:"6px",
    height:"6px",
    borderRadius:"50%",
    margin:"0px 6px"
  },
  customerCard:{
  border:`1px solid #E4E8EE`,
  padding:"16px",
  borderRadius:"4px"
  },
  parentCard:{
    height:`calc(100vh - 390px)`,
    overflow:"auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  loader:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    height:"100%"
  },
  proceedDialog: {
    padding: "54px 50px 51px 228px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundImage: `url(${"images/announsment.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:theme?.palette?.primary?.main
  },
    convert: {
    color: "white",
    fontSize: "16px",
    fontFamily: Bold,
  },
  convertProcess: {
    color: "white",
    fontSize: "16px",
    fontFamily: Regular,
    marginTop:'7px'
  },
  yes: {
    color: theme?.palette?.primary?.main,
    fontSize: "14px",
    fontFamily: Bold,
    border: `1px solid white`,
    backgroundColor: "white",
    padding: "7px 29px",
    width: "100%",
    "&:hover": {
      border: `1px solid white`,
      backgroundColor: "white",
    },
  },
  No: {
    color: "white",
    fontSize: "14px",
    fontFamily: SemiBold,
    border: `1px solid white`,
    padding: "7px 29px",
    backgroundColor: theme?.palette?.primary?.main,
    marginLeft: "10px",
    width: "100%",
    "&:hover": {
      border: `1px solid white`,
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
    marginTop:{
    marginTop:"24px"
  }
}));
