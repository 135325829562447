export const Quotationpath = ["quotation_no", "created_at", "description", "purpose", "owner", "quotation_type", "units", "value","tax", "quotation_expiry_date", "status", "lead_no"]


export const Quotationheading = [
    { title: "Quotation ID", field: "quotation_no", },
    { title: "Date", field: "created_at", },
    { title: "Description", field: "description", },
    { title: "Purpose", field: "purpose", },
    { title: "Type", field: "quotation_type", },
    { title: "Units", field: "units", },
    { title: "Revenue", field: 'value', },
    { title: "Tax", field: "tax" },
    { title: "Valid Till", field: "quotation_expiry_date" },
    { title: "Owner", field: "owner", },
    { title: "Status", field: "status" },
    { title: "Opportunity ID", field: "lead_no" },
]

export const Aggreementpath = ["agreement_no", "account_no", "account_name", "generate_date", "start_date", "end_state", "status", "info"]


export const Aggreementheading = [
    { title: "Agreement Number", field: "agreement_no", },
    { title: "Account number", field: "account_no", },
    { title: "Account name", field: "account_name", },
    { title: "Generated date", field: "generate_date", },
    { title: "Start date", field: "start_date", },
    { title: "End date", field: 'end_state', },
    { title: "Status", field: "status" },
    { title: "", field: 'icon' },

]



export const revenueType = [
    {
        label: "Residential",
        value: "Residential",
    },
    {
        label: "Commercial",
        value: "Commercial",
    },
    {
        label: "Mixed",
        value: "Mixed",
    },

    {
        label: "Lease",
        value: "Lease",
    },
]

export const ContactPath = ["unit_type", "unit_no", "unit_name", "property_name"]


export const Contactheading = [
    { title: "Unit Type", field: "unit_type", },
    { title: "Unit Number", field: "unit_no", },
    { title: "Unit Name", field: "unit_name", },
    { title: "Property Name", field: "property_name", },
]



export const SchedulePath = ["id", "name", "from", "to", "due_date", "amount"]


export const Scheduleheading = [
    { title: "Id", field: "id", },
    { title: "Name", field: "name", },
    { title: "From", field: "from", },
    { title: "To", field: "to", },
    { title: "Due Date", field: "due_date", },
    { title: "Amount", field: "amount", },
]
export const ScheduleRows = [
    {
        id: "1",
        name: "2022-04-27T12:27:50.012132+05:30",
        from: "PG24AG22-3455",
        to: "2022-04-27T12:27:50.012132+05:30",
        due_date: "2022-04-27T12:27:50.012132+05:30",
        amount: "Amount"
    },
    {
        id: "2",
        name: "2022-04-27T12:27:50.012132+05:30",
        from: "PG24AG22-3455",
        to: "2022-04-27T12:27:50.012132+05:30",
        due_date: "2022-04-27T12:27:50.012132+05:30",
        amount: "Amount"
    },
    {
        id: "3",
        name: "2022-04-27T12:27:50.012132+05:30",
        from: "PG24AG22-3455",
        to: "2022-04-27T12:27:50.012132+05:30",
        due_date: "2022-04-27T12:27:50.012132+05:30",
        amount: "Amount"
    }

]

export const genInvoicePath = ["invoice_no", "raised", "due_date", "status", "pending_amount", "total_amount"]


export const genInvoiceheading = [
    { title: "Invoice Number", field: "invoice_no", },
    { title: "Invoiced Raised on", field: "raised", },
    { title: "Due Date", field: "due_date", },
    { title: "Payment status", field: "status", },
    { title: "Invoice pending amount", field: "pending_amount", },
    { title: "Invoice Total amount", field: "total_amount", },

]
export const genInvoiceRows = [
    {
        id: "1",
        invoice_no: "PG24AG22-3455",
        due_date: "2022-04-27T12:27:50.012132+05:30",
        raised: "2022-04-27T12:27:50.012132+05:30",
        status: "In Progress",
        pending_amount: "256",
        total_amount: "500"
    },
    {
        id: "2",
        invoice_no: "PG24AG22-3455",
        due_date: "2022-04-27T12:27:50.012132+05:30",
        raised: "2022-04-27T12:27:50.012132+05:30",
        status: "Close",
        pending_amount: "256",
        total_amount: "500"
    }, {
        id: "3",
        invoice_no: "PG24AG22-3455",
        due_date: "2022-04-27T12:27:50.012132+05:30",
        raised: "2022-04-27T12:27:50.012132+05:30",
        status: "In Progress",
        pending_amount: "256",
        total_amount: "500"
    },
    {
        id: "4",
        invoice_no: "PG24AG22-3455",
        due_date: "2022-04-27T12:27:50.012132+05:30",
        raised: "2022-04-27T12:27:50.012132+05:30",
        status: "Close",
        pending_amount: "256",
        total_amount: "500"
    },

]



export const VacancyPath = ["property_name", "location", "unit_count", "property_type", "status"]


export const Vacancyheading = [
    { title: "Property Name", field: "property_name", },
    { title: "Locaion", field: "location", },
    { title: "Unit Count", field: "unit_count", },
    { title: "Property Type", field: "property_type", },
    { title: "Status", field: "status", },
    { title: "", field: "icon", },
]
export const VacancyRows = [
    {
        id: "1",
        property_name: "Rubix Appartment",
        location: "T.Nagar,Chennai",
        unit_count: "210",
        property_type: "Appartment",
        status: true,
    },
    {
        id: "2",
        property_name: "Rubix Appartment",
        location: "T.Nagar,Chennai",
        unit_count: "210",
        property_type: "Appartment",
        status: false,
    },
    {
        id: "3",
        property_name: "Rubix Appartment",
        location: "T.Nagar,Chennai",
        unit_count: "210",
        property_type: "Appartment",
        status: false,
    },
    {
        id: "4",
        property_name: "Rubix Appartment",
        location: "T.Nagar,Chennai",
        unit_count: "210",
        property_type: "Appartment",
        status: true,
    },

]
