import React, { useState } from "react";
import { FormGenerator } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../../utils";

const initial = {
  endDate: new Date(),
  Notes: "",
};
export const CloseActivity = (props) => {
  const [state, setState] = useState({ ...initial });
  const alert = React.useContext(AlertContext);

  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const sumbit = () => {
    const activity = {
      closed_on: state?.endDate,
      notes: state?.Notes ?? "",
      status: "Closed",
      id: props?.id
    };

    NetworkCall(
      `${config.api_url}/lead/activity`,
      NetWorkCallMethods.post,
      {
        activity,
      },
      null,
      true,
      false
    )
      .then((response) => {
        props?.closeDialogFunction("close");
        props?.getActivityList(0, 10);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: `Activity Closed successfully.`,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Internal error. Please try again later.",
        });
      });
  };

  return (
    <div style={{ padding: "24px" }}>
      <FormGenerator
        components={[
          {
            isActive: true,
            component: "datePickerWithTime",
            label: "End Date & Time",
            value: state?.endDate,
            placeholder: "Select Visit Date & Time",
            onChange: (value) => updateState("endDate", value),
            error: state?.error?.endDate,
            minDate: new Date(),
            isRequired: true,
            size: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
            },
          },
          {
            isActive: true,
            component: "text",
            label: "Notes",
            value: state?.Notes,
            placeholder: "Notes",
            onChange: (value) => updateState("Notes", value?.target?.value),
            error: state?.error?.Notes,
            isRequired: true,
            multiline: true,
            size: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
            },
          },
          {
            isActive: true,
            component: "button",
            onClick: sumbit,
            label: "Close",
            size: {
              xs: 12,
            },
          },
        ]}
      />{" "}
    </div>
  );
};
