import { Box, Button, Divider, Tab, Typography, Stack } from '@mui/material'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { SearchFilter, UseDebounce } from '../../components';
import { NetWorkCallMethods, useWindowDimensions } from '../../utils';
import { CustomerPopupStyle } from './style'
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';
import { PRICING_COMPONENT, UNIT_DETAILS } from '../../graphql/freeTextInvoice';
import { useApolloClient } from "@apollo/client";
import { CustomerVendorCard, PricingComponentCard, UnitCard } from './components';
import { withNamespaces } from 'react-i18next';

const CustomerPopup = (
    {
        t,
        selectCustomer = () => false,
        type = "",
        agreementDetail = {},
        btntext = "",
        unitDetail = {},
        placeholder = "",
        selectedCompany={}
    }) => {
    const debounce = UseDebounce()
    const classes = CustomerPopupStyle()
    const client = useApolloClient()

    const size = useWindowDimensions();
    const [searchText, setSearchText] = React.useState("")
    const [value, setValue] = React.useState(type);
    const [listData, setListData] = React.useState({
        list: [],
        offset: 0,
        searchText: "",
        selectedList: {},
        selected: false
    })

    React.useEffect(() => {
        setValue(type)
        type === "Unit" || type === "Pricing" ?
            getUnitDetails(0, 10, true, "", agreementDetail?.value ?? "", unitDetail?.unit?.id ?? "")
            :
            getCustomerList(0, 10, true, "", value)
        // eslint-disable-next-line
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        type === "Unit" || type === "Pricing" ?
            getUnitDetails(0, 10, true, "", agreementDetail?.value ?? "", unitDetail?.unit?.id ?? "")
            :
            getCustomerList(0, 10, true, "", newValue)
    };

    const loadmore = () => {
        setListData({ ...listData, offset: listData?.offset + 10 });
        let off = listData?.offset + 10
        type === "Unit" || type === "Pricing" ?
            getUnitDetails(off, 10, false, "", agreementDetail?.value ?? "", unitDetail?.unit?.id ?? "")
            :
            getCustomerList(off, 10, false, "", value)
    }

    const searchHandle = (text) => {
        setSearchText(text)
        debounce(() =>
            type === "Unit" || type === "Pricing" ?
                getUnitDetails(0, 10, true, text, agreementDetail?.value ?? "", unitDetail?.unit?.id ?? "")
                :
                getCustomerList(0, 10, true, text, value)
            , 800)
    }

    const getCustomerList = (offset, limit, searchBool, searchText, selectedType) => {
        const payload = {
            company_id: selectedCompany?.value,
            type: [selectedType],
            offset: offset,
            limit: limit,
            search: searchText
        };
        NetworkCall(
            `${config.api_url}/account/get_all`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                if (searchBool) {
                    setListData({
                        ...listData,
                        list: response?.data?.data?.list ?? [],
                        searchText: searchText
                    })
                }
                else {
                    setListData({
                        ...listData,
                        list: listData?.list.concat(...response?.data?.data?.list)
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    const getUnitDetails = (offset, limit, searchBool, searchText, agreement_id, unit_id) => {
        client
            .query({
                query: type === "Unit" ? UNIT_DETAILS(offset, limit, searchText, agreement_id) : PRICING_COMPONENT(offset, limit, searchText, unit_id),
                fetchPolicy: "network-only",
            })
            .then((response) => {
                if (searchBool) {
                    setListData({
                        ...listData,
                        list: type === "Unit" ? response?.data?.agreement_units : response?.data?.pricing_table ?? [],
                        searchText: searchText
                    })
                }
                else {
                    setListData({
                        ...listData,
                        list: type === "Unit" ? listData?.list.concat(...response?.data?.agreement_units) : listData?.list.concat(...response?.data?.pricing_table)
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    return (
        <Box p={2}>
            {type === "Vendor" &&
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%", justifyContent: "space-between" }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" className={classes.tabs}>
                                <Tab label={t("Vendors")} value="Vendor" />
                                <Tab label={t("ServiceProvider")} value="Service Provider" />
                                <Tab label={t("PropertyOwner")} value="Property Owner" />
                            </TabList>
                        </Box>
                    </TabContext>
                </Box>
            }
            <Stack spacing={2} pt={2} pb={1}>
                {type === "Pricing" && <Typography className={classes.pricingLabel}>{t("Selectthebilllinepricecomponent")}</Typography>}
                <SearchFilter value={searchText}
                    placeholder={placeholder}
                    handleChange={(value) => searchHandle(value)} />
            </Stack>
            <InfiniteScroll
                dataLength={10}
                next={loadmore}
                hasMore={true}
                height={size?.height - 320}
            >
                {
                    listData?.list?.map((c) => {
                        const datalist = {
                            id: c?.id,
                            name: c?.name,
                            content: c?.account_no + ", " + c?.mobile_no_country_code + " " + c?.mobile_no,
                            logo: c?.logo
                        }
                        const unitDatalist = c?.unit !== null ? {
                            id: c?.unit?.id,
                            name: c?.unit?.name,
                            content: c?.unit?.property?.name,
                            logo: c?.unit?.logo
                        } : {}
                        const pricingCompList = {
                            id: c?.pricing_component?.id,
                            name: c?.pricing_component?.name,
                            content: c?.pricing_component?.revenue_type
                        }
                        return (
                            type === "Pricing" ?
                                <>
                                    <PricingComponentCard data={pricingCompList} selectedList={listData?.selectedList} click={() => setListData({ ...listData, selectedList: c?.pricing_component, selected: true })} />
                                    {<Divider></Divider>}
                                    {/* length.length - 1 !== index &&  */}
                                </>
                                :
                                type === "Unit" ?
                                    <UnitCard data={unitDatalist} selectedList={listData?.selectedList?.unit} click={() => setListData({ ...listData, selectedList: c, selected: true })} />
                                    :
                                    <CustomerVendorCard data={datalist} selectedList={listData?.selectedList} click={() => setListData({ ...listData, selectedList: c, selected: true })} />
                        )
                    })
                }
            </InfiniteScroll>
            <Button variant='contained' fullWidth className={classes.addbtn} onClick={() => selectCustomer(listData?.selectedList, type)}
                disabled={!listData?.selected} >{btntext}</Button>
        </Box >
    )
}

export default withNamespaces("freeTextInvoice")(CustomerPopup)