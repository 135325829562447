import React from 'react';
import { useApolloClient } from "@apollo/client"
import { Grid } from "@mui/material"
import { withNamespaces } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Loader, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../../components"
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts"
import { GET_PRICING_TABLE } from "../../../graphql/queries"
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, LocalStorageKeys } from "../../../utils"
import { ConstructTableRow, PricingTableViewAllHeading, PricingTableViewAllPath, PricingTableViewAllType } from "../../../utils/pricingTable/tableUtils"
import { useStyles } from "./style"

const PricingTableViewAll = (props) => {

    // Props
    const {
        t = {}
    } = props

    // Constants
    const classes = useStyles()
    const history = useHistory()
    const client = useApolloClient()
    const debounce = UseDebounce()

    // UseContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)

    // UseState
    const [permission, setPermission] = React.useState({})
    const [loading, setLoading] = React.useState(true)
    const [disable, setDisable] = React.useState(true)
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [list, setList] = React.useState({})
    const [search, setSearch] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
            }
        }
        // eslint-disable-next-line
    }, [auth])

    // Function to get company list
    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getList(company?.selected, 0, limit, search)
        }
    }

    // Function to get working hours master list based on the input data
    const getList = (company, offset, limit, search) => {
        setLoading(true)
        setDisable(true)
        client.query({
            query: GET_PRICING_TABLE,
            fetchPolicy: "network-only",
            variables: {
                clientID: localStorage.getItem(LocalStorageKeys?.clinetID),
                companyID: company?.value, offset, limit, search
            },
        }).then((res) => {
            setList({
                data: res?.data?.pricing_table,
                totalRowsCount: res?.data?.count?.[0]?.count,
            })
            setLoading(false)
            setDisable(false)
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, severity: AlertProps?.severity?.error,
                msg: AlertProps?.message?.some_thing_went_wrong,
            })
            setLoading(false)
            setDisable(false)
        })
    }

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        if (page > 1) { setPage(1) }
        getList(value, 0, limit, search)
    }

    // Set row data for table
    // eslint-disable-next-line
    const Rows = React.useCallback(list?.data?.map((_) => {
        let j
        try {
            j = ConstructTableRow(_)
        } catch (err) {
            alert.setSnack({
                ...alert, open: true, severity: AlertProps?.severity?.error,
                msg: AlertProps?.message?.some_thing_went_wrong
            })
        }
        return j
    }), [list])

    // Function to go back to previous screen
    const goBack = () => {
        history.goBack(-1)
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearch(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in working hours master list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getList(selectedCompany, 0, limit, e)
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        if (type === "view") { }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getList(selectedCompany, offset, limit, search)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        if (page > 1) { setPage(1) }
        getList(selectedCompany, 0, value, search)
    }

    const render = () => {
        return (
            <>
                <Subheader goBack={goBack} title={t("all_pricing_table")} isReadOnly={loading || disable}
                    select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
                <div className={classes.root}>
                    <Grid container className={classes.content} spacing={1}>
                        <Grid item xs={4}>
                            <SearchFilter value={search} placeholder={t("search_pricing_table")}
                                handleChange={(value) => handleSearch(value)} />
                        </Grid>
                        <Grid item xs={12}>
                            {loading ?
                                <Loader message={"Loading..."}
                                    height={'calc(100vh - 290px)'} /> :
                                <TableWithPagination
                                    heading={PricingTableViewAllHeading(t)}
                                    rows={Rows}
                                    path={PricingTableViewAllPath}
                                    showpagination={true}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    handleIcon={handleTableIcon}
                                    onClick={() => console.log("")}
                                    tableType="no-side"
                                    dataType={PricingTableViewAllType}
                                    handlePagination={handleTablePagination}
                                    handleChangeLimit={handleTablePageLimit}
                                    totalRowsCount={list?.totalRowsCount}
                                    page={page}
                                    limit={limit}
                                    height={'calc(100vh - 290px)'}
                                    view={permission.read}
                                    edit={permission.update}
                                    delete={permission.delete} />}
                        </Grid>
                    </Grid>
                </div>
            </>
        )
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div>
}

export default withNamespaces("pricingTableViewAll")(PricingTableViewAll)