import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import React from 'react';
import { withNamespaces } from "react-i18next";
import { AlertDialog } from "../dialog";
import ParkingDetails from "./parkindDetails";
import { AccordianStyle } from "./styles";
import {timeZoneConverter} from '../../utils'
const ParkingCard = ({ item = {}, t }) => {
    const [expend, setExpend] = React.useState(false)

    const classes = AccordianStyle();
    const { number_plate, vehicle_type, vehicle_image, location, unit_no, parking_no, first_name, allocated_from, allocated_to } = item;
    const [open, setOpen] = React.useState({
        bool: false,
        data: []
    })

    //onclick images
    const onClickImage = (data) => {
        setOpen({
            bool: !open?.bool,
            data: data ?? []
        })
    }

    return (
        <Box>
            <Grid sx={{ boxShadow: expend ? "0px 8px 24px #0717411F" : "auto" }} container className={classes.cardRoot} onClick={() => setExpend(!expend)} alignItems="center">
                <Grid item sm={6} md={3} lg={4}>
                    <Stack direction="row" spacing={1} alignItems='center'>
                        <Typography className={classes.title}>{t("parkingUnit")}</Typography>
                    </Stack>

                </Grid>
                <Grid item sm={3} md={3} lg={4}>
                    {
                        (number_plate && vehicle_type) ? <CheckCircleIcon color="success" /> : "-"
                    }
                </Grid>

                <Grid item sm={3} md={3} lg={4}>
                    <Stack direction="row" alignItems='center' justifyContent="space-between">
                        <Box width="100px" />
                        <KeyboardArrowDownIcon />
                    </Stack>
                </Grid>
                {
                    expend &&
                    <Grid item xs={12} >
                        <Grid container marginTop="16px">

                            <Grid item xs={4}>
                                <Typography className={classes.subtitle3}>{t("image")}</Typography>
                                <Box height="12px" />
                                <Typography className={classes.subtitle3}>{t("parking_tagged_unit_tenant")}</Typography>
                                <Typography className={classes.subtitle3}>{t("alloted_parking_slot")}</Typography>
                                <Typography className={classes.subtitle3}>{t("parking_location")}</Typography>
                                <Typography className={classes.subtitle3}>{t("vehicle_details")}</Typography>
                                <Typography className={classes.subtitle3}>{t("parking_validity_from_to")}</Typography>

                            </Grid>
                            <Grid item xs={4}>
                                <Avatar sx={{ width: 34, height: 34 }} alt="" src={vehicle_image} />
                                <Box height="12px" />
                                <Typography className={classes.tenant_name} onClick={onClickImage}>{`${unit_no ?? ""} & ${first_name ?? ""}`}</Typography>
                                <Typography className={classes.subtitle4}>{parking_no ? `${t("slot")} ${parking_no}` : "-"}</Typography>
                                <Typography className={classes.subtitle4}>{location ?? "-"}</Typography>
                                <Typography className={classes.subtitle4}>{number_plate},{vehicle_type}</Typography>
                                <Typography className={classes.subtitle4}>{(allocated_from && allocated_to) ? `${timeZoneConverter(allocated_from , "DD MMM YYYY")} - ${timeZoneConverter(allocated_to , "DD MMM YYYY")}` : "-"}</Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                }
            </Grid>
            <AlertDialog
                medium={true}
                open={open?.bool}
                onClose={onClickImage}
                header={t("tenant_details")}
                component={
                    <ParkingDetails onClose={onClickImage} details={item} />
                }
            />


        </Box>
    )
}
export default withNamespaces("inspection")(ParkingCard)