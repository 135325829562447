import { makeStyles } from "@mui/styles";

export const ManagementCommitteRoleStyle = makeStyles((theme) => ({
    filerIcon: {
        border: "1px solid " + theme.palette.border.secondary,
        borderRadius: theme.palette.borderRadius,
    },
    addbtn: {
        height: 40,
    },
    searchBox: {
        height: 40,
        width: 340
    },
    secondStack:{
        height:40
    }
}))

export const AddRoleStyle = makeStyles((theme) => ({
    textBox: {
        height: 40,
        fontSize: 14
    },
    apartmentName: {
        fontSize: 12,
        color: theme.typography.color.tertiary,
        fontWeight: "semibold"
    },
    cancelBtn: {
        fontSize: 14,
        color: theme.typography.color.primary,
        fontWeight: "bold",
        border: `1px solid ${theme.typography.color.primary}`,
        '&:hover': {
            backgroundColor:"transparent"
        }
    }
}))