import React from "react";
import { withNavBars } from "../../HOCs";
import FreeTextInvoice  from "./freeTextInvoice";

const FreeTextInvoiceParent = () => {
    return <FreeTextInvoice />;
}
const props = {
    boxShadow: false
}
export default withNavBars(FreeTextInvoiceParent, props);