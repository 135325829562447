import { Box, Button, Container, Grid, Hidden, Typography } from "@mui/material";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import PropGoToComponent from '../../assets/propgoto';
import { Password, TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext, FirebaseContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, Message, ValidateEmail } from "../../utils";
import { NetWorkCallMethods, LocalStorageKeys } from "../../utils/constants";
import jwt_decode from "jwt-decode";
import { useStyles } from "./style";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { silentOTP } from "./silentOTP";

export const Login1 = (props) => {
  const size = useWindowDimensions()
  const classes = useStyles(size);
  const alert = React.useContext(AlertContext);
  const firebaseContext = React.useContext(FirebaseContext);
  const [disable, setDisable] = React.useState(false)
  const history = useHistory();
  const InitialState = {
    email: "",
    password: "",
    error: {
      email: "",
      password: "",
    },
  };

  const [login, setLogin] = React.useState({ ...InitialState });
  const search = useLocation().search;

  const welcomePageAuthToken = new URLSearchParams(search).get(
    "welcomeAuthToken"
  );

  const updateState = (key, value) => {
    if (key === "email") {
      ValidateEmail(value);
    }
    let error = login.error;
    error[key] = "";
    setLogin({ ...login, [key]: value, error });
  };

  const isIamValideToLogin = () => {
    let isValid = true;
    let error = login.error;

    if (login.email.length === 0) {
      isValid = false;
      error.email = Message.English.requiredMessage(" Enter your email/mobile/UCID");
    }
    if (login.password.length === 0) {
      isValid = false;
      error.password = Message.English.requiredMessage("Password");
    }
    if (login.email !== "" && login.password !== "") {
      isValid = true;
    }
    setLogin({ ...login, error });
    return isValid;
  };
  // eslint-disable-next-line
  const onSendLoginBtnClicked = () => {
    let data = {
      password: login.password
    };
    if (isIamValideToLogin()) {
      // Check phone number
      // eslint-disable-next-line
      var isphone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(login.email);
      // Check email id
      // eslint-disable-next-line
      // Old regex validation
      // var email = /^\w+([\.-]?\ w+)*@\w+([\.-]?\ w+)*(\.\w{2,3})+$/.test(
      //   login.email
      // );
      // eslint-disable-next-line
      var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        login.email
      );
      if (isphone) {
        data["mobile_no"] = login.email;
      } else if (email) {
        data["email_id"] = login.email;
      } else {
        data["ucid"] = login.email;
      }
      NetworkCall(
        `${config.authapi}/auth/login`,
        NetWorkCallMethods.post,
        data,
        null,
        false,
        false
      )
        .then((res) => {
          localStorage.setItem(
            LocalStorageKeys.authToken,
            res?.data?.token
          )
          const email_ID = jwt_decode(res?.data?.token)

          history.push(Routes.otp + "?data=" + email_ID?.email_id);
          return false
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Invalid Credientials",
          });
        });
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("device_token")) {
    } else {
      firebaseContext.requestPermission();
    }
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      const email_id = jwt_decode(welcomePageAuthToken)
      setLogin({ ...login, email: email_id?.email_id })
    }
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <Hidden smDown>
        <div className={classes.root}>
          <Grid container className={classes.signupform}>
            <Grid item xs={12} className={classes.image}>
              <PropGoToComponent />
            </Grid>
            <Grid item xs={12} marginBottom={"16px"}>
              <Typography className={classes.title}>Sign In</Typography>
            </Grid>
            <Grid item xs={12} marginBottom={"45px"}>
              <Typography className={classes.subContent}>Property and facility management solution.</Typography>
            </Grid>
            <Grid item xs={12} marginBottom={"30px"}>
              <TextBox
                value={login.email}
                onChange={(e) => updateState("email", e.target.value)}
                label={Message.English.login.email.label}
                placeholder={Message.English.login.email.placeholder}
                isError={login.error.email.length > 0}
                errorMessage={login.error.email}
                isRequired />
            </Grid>
            <Grid item xs={12} marginBottom={"60px"}>
              <Password
                value={login.password}
                onChange={(e) => updateState("password", e.target.value)}
                label={Message.English.login.password.label}
                placeholder={Message.English.login.password.placeholder}
                isError={login.error.password.length > 0}
                errorMessage={login.error.password}
                isRequired />
              <span
                className={classes.forgetPassword}>
                <Typography variant="subtitle2" className={classes.dont}>
                  Did you forget your password?
                  <Link to={Routes.forgotPassword} className={classes.signin}>
                    &nbsp;Click Here
                  </Link>
                </Typography>
              </span>
            </Grid>
            <Grid item xs={12} marginBottom={"10px"}>
              <Button
                fullWidth
                variant="contained"
                className={classes.btn}
                disabled={disable}
                // onClick={onSendLoginBtnClicked}
                onClick={() => {
                  setDisable(true)
                  silentOTP(login, alert, history, isIamValideToLogin, setDisable)
                }}>
                Log in
              </Button>
            </Grid>
          </Grid>
        </div>
      </Hidden>
      <Hidden smUp>
        <div>
          <Container maxWidth="sm" style={{ padding: 0 }}>
            <Grid container style={{ height: "100vh", backgroundColor: "white" }}>
              <Grid
                item
                className={classes.content}
                style={{ height: size.height > 730 ? size.height - 82 : 648 }}
              >
                <Grid item xs={12} className={classes.backgroundImage} />
                <Grid style={{ padding: "0px 16px 0px 16px" }}>
                  <Box height={"24px"} />
                  <Grid
                    container
                    xs={12}
                    direction="row"
                    justifyContent={"space-between"}
                    alignContent={"center"}
                  >
                    <img
                      src="/images/PA Logo_1.svg"
                      alt="logo"
                      className={classes.img}
                    />
                  </Grid>
                  <Box height={"16px"} />
                  <Grid item xs={12} marginBottom={"16px"}>
                    <Typography className={classes.title}>Sign In</Typography>
                  </Grid>
                  <Grid item xs={12} marginBottom={"45px"}>
                    <Typography className={classes.subContent}>Property and facility management solution.</Typography>
                  </Grid>
                  <Grid item xs={12} marginBottom={"30px"}>
                    <TextBox
                      value={login.email}
                      onChange={(e) => updateState("email", e.target.value)}
                      label={Message.English.login.email.label}
                      placeholder={Message.English.login.email.placeholder}
                      isError={login.error.email.length > 0}
                      errorMessage={login.error.email}
                      isRequired />
                  </Grid>
                  <Grid item xs={12}>
                    <Password
                      value={login.password}
                      onChange={(e) => updateState("password", e.target.value)}
                      label={Message.English.login.password.label}
                      placeholder={Message.English.login.password.placeholder}
                      isError={login.error.password.length > 0}
                      errorMessage={login.error.password}
                      isRequired />
                    <span
                      className={classes.forgetPassword}>
                      <Typography variant="subtitle2" className={classes.dont}>
                        Did you forget your password?
                        <Link to={Routes.forgotPassword} className={classes.signin}>
                          &nbsp;Click Here
                        </Link>
                      </Typography>
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} alignSelf={"self-end"} style={{ padding: "16px 16px 25px 16px" }}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.btn}
                  // onClick={onSendLoginBtnClicked}
                  onClick={() => {
                    setDisable(true)
                    silentOTP(login, alert, history, isIamValideToLogin, setDisable)
                  }}>
                  Log in
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Hidden>
    </>
  );
};
