import { useApolloClient } from "@apollo/client";
import { Box, Grid } from "@mui/material";
import React from "react";
import { TimeLineList } from "../../../components";
import { GET_TIME_LINE } from "../../../graphql/agreementQurery";
import { AggrementTabStyles } from "./styles";
export const TimeLine = ({ details = {}, reload = () => false, agreement_id="" }) => {
    const classes = AggrementTabStyles();
    const client = useApolloClient();
    const [data, setData] = React.useState([])
    const [load , setLoad]=React.useState(false)

    //get time line list
    const getTimeline = () => {
        client.query({
            query: GET_TIME_LINE,
            fetchPolicy: 'network-only',
            variables: {
                id: details?.id,
            }
        }).then((response) => {
            setData(response?.data?.agreement_trackers)
            // reload()
            setLoad(true)
        }).catch((err) => {
            console.log(err)
        })
    }
    //initial load
    React.useEffect(() => {
        getTimeline()
        // eslint-disable-next-line
    }, [])
    return (
        <Box className={classes.timelineRoot}>
            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    <Box>
                        <TimeLineList reload={getTimeline} list={data} details={details} agreement_id={agreement_id} load={load} setLoad={setLoad}/>
                    </Box>
                </Grid>
            </Grid>

        </Box>
    )
}