import React from 'react';
import { PropertyTabs, Subheader } from "../../components";
import { useHistory, useLocation } from 'react-router-dom';
import { useStylesCreation } from "./saleagent/style";
import { Details } from './saleagent/details';
import { Goverment } from './saleagent/govermentaldetails';
import { BankingDetails } from './saleagent/backingdetails';
import CityProperty from '../../assets/cityproperty';
import ContractProperty from '../../assets/contractproperty';
import ImagesProperty from '../../assets/imagesproperty';
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, NetWorkCallMethods, ValidateEmail } from "../../utils";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { config } from "../../config";
import { getRoutePermissionNew, mapResult } from '../../utils/common';
import { UseDebounce } from "../../components";


// initial state
const initial = {
      img: "",
      name: "",
      properties: "",
      identitynumber: "",
      period: "",
      des: "",
      doorno: "",
      addressLineOne: "",
      addressLineTwo: "",
      addressLineThree: "",
      district: "",
      state: "",
      country: "",
      pincode: "",
      contact: "",
      primarytelephone: "",
      primarymobile: "",
      email: "",
      emergencyrelationship: "",
      Facebook: "",
      twitter: "",
      linkdedin: "",
      job: "",
      department: "",
      section: "",
      nationality: "",
      nationalid: "",
      expiry: "",
      passportid: "",
      passportexpirt: "",
      residentalid: "",
      residentalexpiry: "",
      vianumber: "",
      visaexpiry: "",
      bankName: "",
      branchName: "",
      currency: "",
      bankAccountType: "",
      accountNumber: "",
      bankRoutingType: "",
      bankRoutingCode: "",
      bankaddressLineOne: "",
      bankaddressLineTwo: "",
      bankcity: "",
      bankcountry: "",
      preferredCashCollectionOffice: "",
      chequeName: "",
      otherjob: "",
      reportingto: "",
      otherdepartment: "",
      othersection: "",
      contact1: "",
      relationship: "",
      member: {},
      latitude: "",
      longitude: "",
      deletedproperty: [],
      reporting_id: "",
      error: {
            img: "",
            name: "",
            properties: "",
            identitynumber: "",
            period: "",
            doorno: "",
            addressLineOne: "",
            district: "",
            state: "",
            country: "",
            pincode: "",
            contact: "",
            primarytelephone: "",
            primarymobile: "",
            email: "",
            emergencyrelationship: "",
            job: "",
            department: "",
            section: "",
            nationality: "",
            nationalid: "",
            expiry: "",
            passportid: "",
            passportexpirt: "",
            residentalid: "",
            residentalexpiry: "",
            vianumber: "",
            visaexpiry: "",
            bankName: "",
            branchName: "",
            currency: "",
            bankAccountType: "",
            accountNumber: "",
            bankRoutingType: "",
            bankRoutingCode: "",
            bankaddressLineOne: "",
            bankaddressLineTwo: "",
            bankcity: "",
            bankcountry: "",
            preferredCashCollectionOffice: "",
            chequeName: "",
            otherjob: "",
            reportingto: "",
            otherdepartment: "",
            othersection: "",
            contact1: "",
            relationship: "",
            member: ""
      }
}

export const SalesCreation = () => {
      const { state } = useLocation();
      // history
      const history = useHistory();
      // states
      const [value, setValue] = React.useState(1);
      // classes
      const classes = useStylesCreation();
      // state
      const [data, setData] = React.useState({ ...initial })
      const [mapLoad, setMapLoad] = React.useState(!Boolean(state?.main?.isEdit))
      const [disable, setDisable] = React.useState(false)

      // context
      const backdrop = React.useContext(BackdropContext);
      const alert = React.useContext(AlertContext);
      const auth = React.useContext(AuthContext)
      const debounce = UseDebounce();
      const [load, setLoad] = React.useState(false)
      const [permission, setPermission] = React.useState({})

      // use effect to get permission
      React.useEffect(() => {
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                  setPermission(perm)
                  if (perm?.read) {
                        if (state?.main?.isEdit) {
                              GetEditData()
                        }
                        else {
                              setLoad(true)
                        }
                  }
            }
            // eslint-disable-next-line
      }, [auth, state?.main?.isEdit]);

      // tab JSON content
      const tabTittle = [
            {
                  label: 'Sales Agent Basic Details',
                  sub: 'Enter Property, Address & Contact Details',
                  imgSelect: value === 2 || value === 3 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
                  value: 1,
                  className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                  label: 'Governmental Details',
                  sub: 'Upload assets like Image, 360 Image, Video',
                  imgSelect: value === 3 ? <img src="/images/correct_icon.svg" alt='icon' /> : <ImagesProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
                  value: 2,
                  className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                  label: 'Banking Details',
                  sub: 'Enter Authority, Utilities Details',
                  imgSelect: <ContractProperty color={value === 3 ? '#5078E1' : '#98A0AC'} />,
                  value: 3,
                  className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
            },
      ]

      // update img function
      const updateimg = (data) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Loading",
            });
            const formData = new FormData();
            formData.append("files", data);
            formData.append("tenantId", `${config.tenantId}`);

            NetworkCall(
                  `${config.api_url}/file-upload`,
                  NetWorkCallMethods.post,
                  formData,
                  null,
                  true,
                  false
            )
                  .then((res) => {
                        updateState("img", res?.data?.fileUrls?.[0]?.url)
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: false,
                              message: "",
                        });
                  })
                  .catch((err) => {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Some Thing Went Wrong",
                              vertical: AlertProps.vertical.top,
                              horizontal: AlertProps.horizontal.center,
                        });
                  });

      };

      // go next function
      const next = () => {
            if (value === 1) {
                  if (validate()) {
                        setValue(2)
                  }
                  else {
                        return false
                  }
            }
            if (value === 2) {
                  if (validate1()) {
                        setValue(3);
                  }
                  else {
                        return false
                  }

            }
            if (value === 3) {
                  setValue(3);
            }


      };

      // go previous
      const previous = () => {
            setValue(prevValue => prevValue - 1);
            if (!state?.main?.isEdit) {
                  setMapLoad(false)
            }
      };

      // google map function
      const mapResultData = (map) => {
            let result = mapResult(map);
            setData({ ...data, ...result })
      }

      // personal details valuidation
      const validate = () => {
            let isValid = true;
            let error = data.error;
            if (data?.img?.length === 0) {
                  isValid = false;
                  error.img = "Profile Image is Required";
            }
            if (data?.name?.length === 0) {
                  isValid = false;
                  error.name = "Name is Required";
            }
            if (data?.identitynumber?.length === 0) {
                  isValid = false;
                  error.identitynumber = "Company Identification Number is Required";
            }
            if (data?.relationship?.length === 0) {
                  isValid = false;
                  error.relationship = "RelationShip is Required";
            }
            if (data?.addressLineOne?.length === 0) {
                  isValid = false;
                  error.addressLineOne = "Address Line 1 is Required";
            }
            if (data?.district?.length === 0) {
                  isValid = false;
                  error.district = "District is Required";
            }
            if (data?.state?.length === 0) {
                  isValid = false;
                  error.state = "State is Required";
            }
            if (data?.country?.length === 0) {
                  isValid = false;
                  error.country = "Country is Required";
            }
            if (data?.pincode?.length === 0) {
                  isValid = false;
                  error.zipCode = "ZipCode is Required";
            }
            if (data?.contact?.length === 0) {
                  isValid = false;
                  error.contact = "Preferred Method Of Contact is Required";
            }
            if (data?.primarytelephone?.length === 0) {
                  isValid = false;
                  error.primarytelephone = "Primary Telephone is Required";
            }
            if (data?.primarymobile?.length === 0) {
                  isValid = false;
                  error.primarymobile = "Primary Mobile is Required";
            }
            if (data?.email?.length === 0) {
                  isValid = false;
                  error.email = "Primary Email Address is Required";
            }
            if (data?.properties?.length === 0) {
                  isValid = false;
                  error.properties = "Properties is Required";
            }
            if (data?.emergencyrelationship?.length === 0) {
                  isValid = false;
                  error.emergencyrelationship = "Emergency Contact Relationship is Required";
            }
            // eslint-disable-next-line
            if (!ValidateEmail(email)) {
                  isValid = false;
                  error.email = "Enter valid Email";
            }

            if (data?.img?.length === 0 || data?.name?.length === 0 ||
                  data?.identitynumber?.length === 0 || data?.addressLineOne?.length === 0 ||
                  data?.primarymobile?.length === 0 ||
                  data?.district?.length === 0 || data?.state?.length === 0 ||
                  data?.country?.length === 0 || data?.pincode?.length === 0 ||
                  data?.contact?.length === 0 || data?.primarytelephone?.length === 0 ||
                  data?.email?.length === 0 ||
                  data?.emergencyrelationship?.length === 0 ||
                  data?.relationship?.length === 0 || data?.properties?.length === 0
            ) {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Please fill all mandatory field",
                  });
            }
            setData({ ...data, error });

            return isValid;
      };
      // goverment details validation
      const validate1 = () => {
            let isValid = true;
            let error = data.error;
            if (data?.nationality?.length === 0) {
                  isValid = false;
                  error.nationality = "Nationality is Required";
            }
            if (data?.nationalid?.length === 0) {
                  isValid = false;
                  error.nationalid = "National ID is Required";
            }
            if (data?.expiry?.length === 0) {
                  isValid = false;
                  error.expiry = "National ID Expiry is Required";
            }
            if (data?.passportid?.length === 0) {
                  isValid = false;
                  error.passportid = "Passport ID is Required";
            }
            if (data?.passportexpirt?.length === 0) {
                  isValid = false;
                  error.passportexpirt = "Passport Expiry is Required";
            }
            if (data?.residentalid?.length === 0) {
                  isValid = false;
                  error.residentalid = "Resident ID is Required";
            }
            if (data?.residentalexpiry?.length === 0) {
                  isValid = false;
                  error.residentalexpiry = "Resident ID Expiry is Required";
            }
            if (data?.vianumber?.length === 0) {
                  isValid = false;
                  error.vianumber = "Visa Number is Required";
            }
            if (data?.visaexpiry?.length === 0) {
                  isValid = false;
                  error.visaexpiry = "Visa Expiry is Required";
            }
            setData({ ...data, error });

            return isValid;
      }
      // bankig details validation
      const validate2 = () => {
            let isValid = true;
            let error = data.error;

            if (data?.bankName?.length === 0) {
                  isValid = false;
                  error.bankName = "Bank Name is Required";
            }
            if (data?.branchName?.length === 0) {
                  isValid = false;
                  error.branchName = "Branch Name is Required";
            }
            if (data?.currency?.length === 0) {
                  isValid = false;
                  error.currency = "Currency is Required";
            }
            if (data?.bankAccountType?.length === 0) {
                  isValid = false;
                  error.bankAccountType = "Bank Account Type is Required";
            }
            if (data?.accountNumber?.length === 0) {
                  isValid = false;
                  error.accountNumber = "Account Number is Required";
            }
            if (data?.bankRoutingType?.length === 0) {
                  isValid = false;
                  error.bankRoutingType = "Bank Routing Type is Required";
            }
            if (data?.bankRoutingCode?.length === 0) {
                  isValid = false;
                  error.bankRoutingCode = "Cheque Name is Required";
            }
            if (data?.bankaddressLineOne?.length === 0) {
                  isValid = false;
                  error.bankaddressLineOne = "Address Line 1 is Required";
            }
            if (data?.bankcity?.length === 0) {
                  isValid = false;
                  error.bankcity = "City is Required";
            }
            if (data?.bankcountry?.length === 0) {
                  isValid = false;
                  error.bankcountry = "Country is Required";
            }
            if (data?.chequeName?.length === 0) {
                  isValid = false;
                  error.chequeName = "Cheque Name is Required";
            }
            if (data?.preferredCashCollectionOffice?.length === 0) {
                  isValid = false;
                  error.preferredCashCollectionOffice = "preferredCashCollectionOffice is Required";
            }
            setData({ ...data, error });

            return isValid;
      }

      const save = () => {
            if (validate2()) {
                  setDisable(true)
                  let datas = {
                        tenantId: `${config.tenantId}`,
                        image_url: data?.img,
                        mobile_no: data?.primarymobile?.mobile,
                        company_registration_no: data?.identitynumber,
                        mobile_no_country_code: data?.primarymobile?.mobile_code,
                        email_id: data?.email,
                        user_name: data?.name,
                        door_no: data?.doorno,
                        street_1: data?.addressLineOne,
                        street_2: data?.addressLineTwo,
                        city: data?.addressLineThree,
                        district: data?.district,
                        state: data?.state,
                        zipcode: data?.pincode,
                        country: data?.country,
                        phone: data?.primarytelephone?.mobile,
                        facebook: data?.Facebook,
                        twitter: data?.twitter,
                        linkedin: data?.linkdedin,
                        nationality: data?.nationality?.value,
                        national_id: data?.nationalid,
                        national_id_expiry: data?.expiry,
                        passport_id: data?.passportid,
                        passport_expiry: new Date(data?.passportexpirt),
                        resident_id: data?.residentalid,
                        resident_id_expiry: new Date(data?.residentalexpiry),
                        preferred_mode_contact_emergency: data?.emergencyrelationship?.mobile,
                        preferred_mode_contact_emergency_code: data?.emergencyrelationship?.mobile_code,
                        preferred_mode_contact: data?.contact?.value,
                        visa_no: data?.vianumber,
                        visa_expiry: data?.visaexpiry,
                        bank_name: data?.bankName,
                        branch_name: data?.branchName,
                        bank_currency_id: data?.currency?.value,
                        account_type: data?.bankAccountType,
                        account_no: data?.accountNumber,
                        relationship: data?.relationship?.value,
                        routing_type: data?.bankRoutingType?.value,
                        latitude: parseFloat(data?.latitude),
                        longitude: parseFloat(data?.longitude),
                        routing_code: data?.bankRoutingCode,
                        cheque_name: data?.chequeName,
                        address_1: data?.bankaddressLineOne,
                        address_2: data?.bankaddressLineTwo,
                        bank_city: data?.bankcity,
                        bank_country: data?.bankcountry?.value,
                        cash_collection_office: data?.preferredCashCollectionOffice,
                        reporting_to: data?.member?.user_profileByID?.id,
                        company_id: state?.main?.companyID?.value,
                        property: data?.properties?.map((x) => x?.value) ?? undefined,
                        user_profile_id: localStorage.getItem('profileID'),
                        job_title: data?.job?.value,
                        phone_code: data?.primarytelephone?.mobile_no,

                  }
                  if (state?.main?.isEdit === true) {
                        datas["id"] = state?.main?.EditData;
                        datas['deleteProperty'] = data?.deletedproperty?.map((x) => x?.value) ?? undefined;
                        datas['reporting_id'] = data?.reporting_id ?? undefined;
                  }
                  NetworkCall(
                        `${config.api_url}/sales-agent/upsert`,
                        NetWorkCallMethods.post,
                        datas,
                        null,
                        true,
                        false
                  )
                        .then((response) => {
                              history.goBack(-1)
                              setDisable(false)
                              alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.success,
                                    msg: `Sales Agent${state?.main?.isEdit ? "Updated" : "Created"}  successfully.`,
                              });
                        })
                        .catch((error) => {
                              setDisable(false)
                              alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.error,
                                    msg: "Internal error. Please try again later.",
                              });
                        });
            }

      }

      const checkSave = (type) => {
            if (state?.main?.isEdit) {
                  if (permission?.update) {
                        type === "previous" && previous()
                        type === "next" && next()
                        type === "save" && save()
                  } else {
                        alert.setSnack({
                              ...alert, open: true, msg: "Access denied. Contact your administrator.",
                              severity: AlertProps.severity.error,
                        })
                  }
            } else if (permission?.create) {
                  type === "previous" && previous()
                  type === "next" && next()
                  type === "save" && save()
            } else {
                  alert.setSnack({
                        ...alert, open: true, msg: "Access denied. Contact your administrator.",
                        severity: AlertProps.severity.error,
                  })
            }
      }
      const checkExsits = (k, v, m) => {
            let datas = {
                  tenantId: `${config.tenantId}`,
                  [k]: v
            }
            NetworkCall(
                  `${config.api_url}/sales-agent/find`,
                  NetWorkCallMethods.post,
                  datas,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        if (response?.data?.is_Exist && v?.length > 0) {
                              alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.info,
                                    msg: `${m} is already exists`,
                              });
                        }
                  })
                  .catch((error) => {

                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Internal error. Please try again later.",
                        });
                  });
      }

      // update state function
      const updateState = (k, v) => {
            if (k === 'name') {
                  debounce(() => checkExsits("user_name", v, 'Name'), 1700);
            }
            if (k === "email") {
                  debounce(() => checkExsits("email_id", v, 'Email Id'), 1700);
            }
            if (k === 'primarymobile') {
                  debounce(() => checkExsits("mobile_no", v, 'Primary Mobile Number'), 1700);
            }
            let error = data?.error;
            error[k] = "";
            setData({ ...data, [k]: v })
      }
      // get details api
      const GetEditData = () => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Loading",
            });
            let datas = {
                  tenantId: `${config.tenantId}`,
                  company_id: state?.main?.companyID?.value ?? undefined,
                  user_id: state?.main?.EditData ?? ""

            }
            NetworkCall(
                  `${config.api_url}/sales-agent/get`,
                  NetWorkCallMethods.post,
                  datas,
                  null,
                  true,
                  false
            )
                  .then((res) => {
                        let editData = res?.data?.data
                        let properties = editData?.property?.map((val) => {
                              return {
                                    value: val?.id ?? "",
                                    label: val?.name ?? ""
                              }
                        })
                        setData({
                              img: editData?.url ?? "",
                              name: editData?.first_name ?? "",
                              properties: properties ?? [],
                              identitynumber: editData?.company_registration_no ?? "",
                              period: "",
                              des: "",
                              doorno: editData?.door_no ?? "",
                              addressLineOne: editData?.street_1 ?? "",
                              addressLineTwo: editData?.street_2 ?? "",
                              addressLineThree: editData?.city ?? "",
                              district: editData?.district ?? "",
                              state: editData?.state ?? "",
                              country: editData?.country ?? "",
                              pincode: editData?.zipcode ?? "838383838",
                              contact: {
                                    value: editData?.preferred_mode_contact ?? "",
                                    label: editData?.preferred_mode_contact ?? "",
                              },
                              primarytelephone: {
                                    mobile: editData?.phone ?? "",
                                    mobile_code: editData?.mobile_no_country_code ?? ""
                              },
                              primarymobile: {
                                    mobile: editData?.mobile_no ?? "",
                                    mobile_code: editData?.mobile_no_country_code ?? ""
                              },
                              email: editData?.email_id ?? "",
                              emergencyrelationship: {
                                    mobile: editData?.preferred_mode_contact_emergency ?? "",
                                    mobile_code: editData?.mobile_no_country_code ?? ""
                              },
                              Facebook: editData?.facebook ?? "",
                              twitter: editData?.twitter ?? "",
                              linkdedin: editData?.linkedin ?? "",
                              job: "",
                              department: "",
                              section: "",
                              nationality: {label:editData?.nationality,value:editData?.nationality} ?? "",
                              nationalid: editData?.national_id ?? "",
                              expiry: new Date(editData?.national_id_expiry),
                              passportid: editData?.passport_id ?? "",
                              passportexpirt: new Date(editData?.passport_expiry),
                              residentalid: editData?.resident_id ?? "",
                              residentalexpiry: new Date(editData?.resident_id_expiry),
                              vianumber: editData?.visa_no ?? "",
                              visaexpiry: new Date(editData?.visa_expiry),
                              bankName: editData?.bank_name ?? "",
                              branchName: editData?.branch_name ?? "",
                              currency: {
                                    value: editData?.bank_currency_id ?? "",
                                    label: editData?.bank_currency_name ?? ""
                              },
                              bankAccountType: editData?.account_type ?? "",
                              accountNumber: editData?.account_no ?? "",
                              bankRoutingType: {
                                    value: editData?.routing_type ?? "",
                                    label: editData?.routing_type ?? "",
                              },
                              bankRoutingCode: editData?.routing_code,
                              bankaddressLineOne: editData?.address_1 ?? "",
                              bankaddressLineTwo: editData?.address_2 ?? "",
                              bankcity: editData?.bank_city ?? "",
                              bankcountry: {label:editData?.bank_country,value:editData?.bank_country} ?? "",
                              chequeName: editData?.cheque_name ?? "",
                              otherjob: "",
                              reportingto: "",
                              otherdepartment: "",
                              othersection: "",
                              contact1: "",
                              preferredCashCollectionOffice: editData?.cash_collection_office ?? "",
                              relationship: {
                                    value: editData?.relationship,
                                    label: editData?.relationship,
                              },
                              deletedproperty: [],
                              latitude: editData?.latitude ?? "",
                              longitude: editData?.longitude ?? "",
                              reporting_id: editData?.reporting_details?.[0]?.reporting_id,
                              member: {
                                    roles_id: editData?.id,
                                    user_profileByID: {
                                          id: editData?.reporting_details?.[0]?.id,
                                          first_name: editData?.reporting_details?.[0]?.first_name,
                                          last_name: editData?.reporting_details?.[0]?.last_name,
                                          email_id: editData?.reporting_details?.[0]?.email_id,
                                          mobile_no: editData?.reporting_details?.[0]?.mobile_no,
                                          mobile_no_country_code: editData?.reporting_details?.[0]?.mobile_no_country_code,
                                          image_url: editData?.reporting_details?.[0]?.image_url,
                                    }
                              },
                              error: {
                                    img: "",
                                    name: "",
                                    properties: "",
                                    identitynumber: "",
                                    period: "",
                                    doorno: "",
                                    addressLineOne: "",
                                    district: "",
                                    state: "",
                                    country: "",
                                    pincode: "",
                                    contact: "",
                                    primarytelephone: "",
                                    primarymobile: "",
                                    email: "",
                                    emergencyrelationship: "",
                                    job: "",
                                    department: "",
                                    section: "",
                                    nationality: "",
                                    nationalid: "",
                                    expiry: "",
                                    passportid: "",
                                    passportexpirt: "",
                                    residentalid: "",
                                    residentalexpiry: "",
                                    vianumber: "",
                                    visaexpiry: "",
                                    bankName: "",
                                    branchName: "",
                                    currency: "",
                                    bankAccountType: "",
                                    accountNumber: "",
                                    bankRoutingType: "",
                                    bankRoutingCode: "",
                                    bankaddressLineOne: "",
                                    bankaddressLineTwo: "",
                                    bankcity: "",
                                    bankcountry: "",
                                    preferredCashCollectionOffice: "",
                                    chequeName: "",
                                    otherjob: "",
                                    reportingto: "",
                                    otherdepartment: "",
                                    othersection: "",
                                    contact1: "",
                                    relationship: "",
                              }
                        })
                        setLoad(true)
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: false,
                              message: "",
                        });
                  })
                  .catch((error) => {
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: false,
                              message: "",
                        });
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Internal error. Please try again later.",
                        });
                  });
      }

      // deleted property
      const deletedValue = (value, updatedvalue) => {
            setData({ ...data, deletedproperty: [...data?.deletedproperty, value], properties: updatedvalue })
      }
      // remove image function

      const removeimg = () => {
            setData({ ...data, img: "" })
      }

      const render = () => {
            return <>
                  {/* sub navbar */}
                  <Subheader
                        title={state?.main?.isEdit ? "Edit Sales Agent" : "Create New Sales Agent"}
                        goBack={() => { history.goBack(-1) }}
                        previousAndNextBtn={true}
                        previousBtn={() => checkSave("previous")}
                        nextBtn={() => checkSave("next")}
                        previousBtnDisabled={value <= 1 && true}
                        nextBtnDisabled={value === 3 ? true : false}
                  />
                  {/* tabs */}
                  <PropertyTabs value={value} isHeigth
                        // tab1
                        tab1={<Details id='pushTitle' data={data} updateState={updateState} updateimg={updateimg} mapResult={mapResultData} mapLoad={mapLoad} deletedValue={deletedValue} removeimg={removeimg} load={load}
                        />}
                        // tab2
                        tab2={<Goverment data={data} updateState={updateState} />}
                        // tab3
                        tab3={<BankingDetails data={data} updateState={updateState} />}
                        // tab titles
                        tabTittle={tabTittle}
                        showBtns={true}
                        isSaveDisable={value === 3 ? false : true || disable === true ? true : false}
                        saveBtnText={state?.main?.isEdit ? "Update" : "Save"}
                        save={() => checkSave("save")}

                  />
            </>
      }

      return (
            <div style={{ position: 'relative' }}>
                  {accessCheckRender(render, permission)}
            </div>
      )

}