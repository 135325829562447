import makeStyles from "@mui/styles/makeStyles";

export const generalCardStyles = makeStyles((theme) => ({
  generalCard: {
    borderRadius: "6px",
    display: "flex",
    padding: "8px",
    border: "1px solid" + theme.palette.border.secondary,
    margin: "12px 0px 0px 0px",
    justifyContent: "space-between",
    alignItems: "center",
  },

  avatar: {
    borderRadius: "4px",
    marginRight: "12px",
    backgroundColor: "#F2F4F7"
  },

  imageText: {
    display: "flex",
    alignItems: "center",
  },

  image: {
    "& img": {
      width: "40px",
      height: "40px",
      borderRadius: "4px",
      marginRight: "16px",
    },
  },

  text: {
    fontSize: "14px",
    fontWeight: 800,
    color: theme.typography.color.primary,
  },

  addBtn: {
    width: "76px",
    height: "28px",
    fontSize: "10px",
    fontWeight: 700,
    color: theme.typography.status.primary,
    border: "1px solid" + theme.palette.border.primary,
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  cart: {
    width: "76px",
    height: "28px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid" + theme.palette.border.primary,
    borderRadius: "4px",
    cursor: "pointer",
    "& button": {
      minWidth: 0,
      padding: "0px 8px !important",
      "&:hover": {
        backgroundColor: "#ffffff !important",
      },
    },
  },

  cartText: {
    fontSize: "12px !important",
    fontWeight: 700,
  },
  title: {
    display: "flex",
  },
  clipIcon: {
    marginLeft: "8px",
    "& svg": {
      width: "15.8px",
      height: "16px",
      color: "#CED3DD",
    },
  },
  toggle: {
    marginRight: "0px !important",
  },
  tooltipTitle: {
    fontSize: "14px",
    fontWeight: 800,
    color: theme.typography.color.primary,
  },
  tooltipSubTitle: {
    fontSize: "11px",
    fontWeight: 600,
    color: "#CED3DD",
  },
  subText: {
    display: "flex",
    alignItems: "center",
  },
  items: {
    "& p": {
      fontSize: "12px",
      color: theme.typography.color.tertiary,
      fontWeight: 700,
    },
  },
  dot: {
    width: "4px",
    height: "4px",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.primary,
    marginLeft: "6px",
  },
  viewText: {
    marginLeft: "6px",
    cursor: "pointer",
    fontSize: "12px",
    color: theme.typography.status.primary,
    fontWeight: 700,

  },
  inspectionItemCard: {
    margin: "20px 20px",
  },
  product: {
    "& p": {
      fontSize: "12px",
      fontWeight: 700,
      color: theme.typography.color.tertiary,
    },
  },
  AvatarGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "12px",
  },
  AvatarImg: {
    justifyContent: "flex-end",
    "& .MuiAvatar-root": {
      height: "32px",
      width: "32px",
      fontSize: "12px",
    },
  },
  upload: {
    "& p": {
      fontSize: "12px",
      fontStyle: "italic",
      fontWeight: 500,
      color: theme.typography.color.tertiary,
    },
  },
  textGroup: {
    marginTop: "30px",
  },
  subTexts: {
    fontSize: "12px",
    fontStyle: "italic",
    fontWeight: 700,
    color: theme.typography.color.tertiary,
  },
  hypen: {
    fontSize: "14px",
    fontWeight: 900,
    color: theme.typography.color.primary + "!impotant",
  },
  tab: {
    padding: "12px"
  }
}));



