import React, { useState } from "react";
import { Button, Box, Divider, Avatar, Typography } from "@mui/material";
import { useStyleTimeLine } from "../../styles";
import { TextBox } from "../../../../components";
export const AsPerAgreement = ({ t }) => {
  const classes = useStyleTimeLine();
  const [remark, setRemark] = useState("");
  return (
    <Box p={2}>
      {/* customer card */}
      <Box className={classes.customerCard}>
        <Box display="flex" alignItems={"center"}>
          <Avatar
            src={"https://mui.com/static/images/avatar/2.jpg"}
            className={classes.customerIcon}
          />
          <Box width={"10px"} />
          <Box>
            <Typography className={classes.customerTitle}>
              Tom Cruise Jackson
            </Typography>
            <Box height={"8px"} />
            <Box display="flex" alignItems={"center"}>
              <Typography className={classes.customerSub}>
                Tom Cruise Jackson
              </Typography>
              <Box className={classes.dot} />
              <Typography className={classes.customerSub}>
                Tom Cruise Jackson
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box height={"16px"} />
        <Divider />
        <Box height={"16px"} />
        <Box display="flex" alignItems={"center"}>
          <Avatar
            src={"https://mui.com/static/images/avatar/2.jpg"}
            className={classes.customerIcon}
          />
          <Box width={"10px"} />
          <Box>
            <Typography className={classes.customerTitle}>
              New Agreement (AGR-12134)
            </Typography>
            <Box height={"8px"} />
            <Typography className={classes.customerSub}>
              12 Jun 2022 - 12 Jun 2023
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box height={"24px"} />
      <TextBox
        label={t("Remarks_optional")}
        placeholder={t("Remarks_optional")}
        value={remark ?? ""}
        onChange={(e) => {
            setRemark(e.target.value);
        }}
        multiline
      />
      <Box height={"24px"} />
      <Button className={classes.Yes}>{t("Submit")}</Button>
    </Box>
  );
};
