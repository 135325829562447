/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient } from '@apollo/client';
import { Badge, Box, Button, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import FilterIMG from "../../assets/filter";
import { FilterGenerator, PropertyTabs, SearchFilter, Subheader, UseDebounce } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { UPDATE_CONTACT } from '../../graphql/mutations';
import { NetworkCall } from '../../networkcall';
import { Routes } from '../../router/routes';
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from '../../utils';
import { SettingList } from '../companyCreation/components';
import { ContactsView } from './contactsView';
import { KycView } from './kyc';
import { contactsStyles } from "./style";

export const Contacts = ({type=""}) => {

    const classes = contactsStyles();
    const client = useApolloClient();
    const history = useHistory();
    const debounce = UseDebounce();
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const [fiterDrawer, setFilterDrawer] = React.useState(null);
    const [contactList, setContactList] = React.useState([]);
    const [searchText, setSearchText] = React.useState("");
    const [companyList, setCompanyList] = React.useState([]);
    const [offset, setOffset] = React.useState(0)
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [filterData, setFilterData] = React.useState({ status: [true] });
    const [selected, setSelected] = React.useState();
    const [status, setStatus] = React.useState();
    const [contactDetails, setContactDetails] = React.useState(null)
    const [value, setValue] = React.useState(1);
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const tabTittle = [
        {
            label: 'Contact Details',
            value: 1,
            className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: 'KYC Details',
            value: 2,
            className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
        },
    ]


    const statusOptionList = [
        { label: 'Active', value: true },
        { label: 'Inactive', value: false },
    ];

    const fetchMoreData = () => {
        setOffset(offset + 20)
        getContactByCompanyID(searchText, offset + 20, true)
    }
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        getContactByCompanyID(e, 0, false)
    }


    const draweropen = () => {
        setFilterDrawer(true);
    };
    const drawerclose = () => {
        setFilterDrawer(false);
    };


    const createContact = () => {
        history.push({
            pathname: type === "Agent" ? Routes?.salesagent : Routes.createContact,
            state: {
                main: {
                    company: selectedCompany,
                    companyList: companyList.filter?.(i => i?.value != null),
                    isAllCompany: selectedCompany?.value ? false : true
                },
            },
        });

    }
    const editContact = () => {
        history.push({
            pathname: type === "Agent" ? Routes.salesagent : Routes.createContact,
            state: {
                main: {
                    id: selected?.id,
                    isEdit: true
                },
            },
        });

    }

    const deleteContact = async () => {
        client.mutate({
            mutation: UPDATE_CONTACT, fetchPolicy: 'network-only',
            variables: { contactID: selected?.id, payload: { is_active: status === false ? true : false } }
        }).then((response) => {
            getContactByCompanyID();
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: "Contact Updated Successfully.!!!",
            });
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong. Internal Error.!!!",
            });
        });
    }


    const getContactDetailsByID = (contactId) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading", });
        let datas = { tenantId: `${config.tenantId}`, id: contactId }
        NetworkCall(
            `${config.api_url}/contact/get`,
            NetWorkCallMethods.post,
            datas, null, true, false
        ).then((res) => {

            let tempContactDetails = res?.data?.data
            setContactDetails(tempContactDetails)
            setStatus(tempContactDetails?.is_active)
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
        }).catch((error) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            setContactDetails(false)
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Internal error. Please try again later.",
            });
        });
    }

    const getContactByCompanyID = (search, offset, searchBool) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "", });
        const payload = {
            "tenantId": `${config.tenantId}`,
            "company_id": selectedCompany?.value,
            "search": search,
            "filter": {
                relationship: type === "Agent" ? ["External Sales Broker"] : filterData?.relationship ? filterData?.relationship?.map((val) => val?.value) : undefined,
                status: (!filterData?.status || filterData?.status?.length === 0) ? [true, false] : filterData?.status
            },
            "offset": offset,
            "limit": 20
        }
        NetworkCall(
            `${config.api_url}/contact/list`,
            NetWorkCallMethods.post,
            payload, null, true, false).then((response) => {
                if (searchBool) {
                    console.log(contactList.concat(response?.data?.data))
                    setContactList(
                        contactList.concat(response?.data?.data)
                    );
                } else {
                    setContactList(response?.data?.data);
                    setSelected(response?.data?.data?.[0])
                }
                backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            }).catch(() => {
                backdrop.setBackDrop({ ...backdrop, open: false, message: "", });

            });
    };
    React.useEffect(() => {
        if (companyList?.length > 0) {
            if ((!Boolean(companyList?.[0]?.value) && !Boolean(selectedCompany?.value)) || selectedCompany?.value) {
                getContactByCompanyID(searchText, 0, false);
                setOffset(0)
            }
        }
    }, [selectedCompany, filterData, companyList]);

    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList([{ value: null, label: "All Company" }, ...company?.list])
            setSelectedCompany(company?.selected ?? { value: null, label: "All Company" })
        }
    }

    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }
    const onApplyFilter = (value) => {
        setFilterData(value)
    }
    //tab cbange
    const tabChange = (e, newValue) => {
        setValue(newValue)
    }

    React.useEffect(() => {
        if (selected) {
            getContactDetailsByID(selected?.id)
        }
    }, [selected])

    const render = () => {
        return <>
            <Subheader hideBackButton={true} title="Contacts" isAllCompany
                select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
            <Grid container className={classes.content} spacing={1}>
                <Grid item xs={4}>
                    <SearchFilter value={searchText} placeholder="Search Contacts" handleChange={(value) => handleSearch(value)} />
                </Grid>
                <Grid item xs={8} textAlign={"right"}>
                    <Box display={"flex"} sx={{ float: "right" }}>
                        <Stack direction="row" spacing={2}
                            divider={<Divider orientation="vertical" flexItem />}>
                            {(filterData.relationship?.length > 0 || filterData.status?.length > 0 ?
                                (<IconButton onClick={draweropen} className={classes.img}>
                                    <Badge variant="dot" color="primary">
                                        <FilterIMG color="#091b29" />
                                    </Badge>
                                </IconButton>) :
                                (<IconButton onClick={draweropen} className={classes.img}>
                                    <FilterIMG color="#091b29" />
                                </IconButton>))}
                            {permission.create && <Button variant="contained" className={classes.btn}
                                onClick={createContact}>
                                {type === "Agent" ? "Create Agent" : "Create Contact"}
                            </Button>}

                        </Stack>
                    </Box>
                </Grid>
            </Grid>

            <Box>
            <Grid container spacing={2} p={2}>
                <Grid item xs={3}>
                    <Box className={contactList?.length > 0 ? classes.box2 : classes.box}>
                        <SettingList
                            list={contactList}
                            selected={selected?.id}
                            setSelected={setSelected}
                            fetchMoreData={fetchMoreData}
                            contactSetting
                        />
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box className={contactList?.length > 0 ? classes.box2 : classes.box}>
                        {contactList?.length > 0 ?
                            <>
                                {contactDetails &&
                                    <>
                                        <Grid container p={2} alignItems="center" spacing={1}>
                                            <Grid item xs={4}>
                                                <Typography className={classes.title}>{contactDetails?.first_name}</Typography>
                                            </Grid>
                                            <Grid item xs={8} textAlign={"right"}>
                                                <Box display={"flex"} sx={{ float: "right" }}>
                                                    <Stack direction="row" spacing={2}
                                                        divider={<Divider orientation="vertical" flexItem />}>
                                                        {(status && permission.delete) &&
                                                            <Button variant="outlined" className={classes.btn3}
                                                                onClick={deleteContact}
                                                            >
                                                                {"Delete"}
                                                            </Button>}
                                                        {(!status && permission.update) &&
                                                            <Button variant="outlined" className={classes.btn3}
                                                                onClick={deleteContact}
                                                            >
                                                                {"Active"}
                                                            </Button>}
                                                        {permission.update && <Button variant="contained" className={classes.btn}
                                                            onClick={editContact}
                                                        >
                                                            Edit
                                                        </Button>}

                                                    </Stack>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <PropertyTabs value={value} padding handleChange={tabChange}
                                            tab1={(
                                                <ContactsView data={contactDetails} />
                                            )}
                                            tab2={(
                                                <KycView data={contactDetails} />
                                            )}
                                            tabTittle={tabTittle}
                                        />
                                    </>
                                }
                            </>
                            :
                            <Typography className={classes.noData} >{"No contacts found"}</Typography>

                        }
                    </Box>
                </Grid>
            </Grid>
            </Box>
        

            <FilterGenerator open={fiterDrawer} onClose={drawerclose} onApply={(value) => onApplyFilter(value)}
                components={[
                    {
                        component: "toggleButton",
                        value: filterData?.status,
                        options: statusOptionList,
                        isMulti: true,
                        state_name: "status",
                        label: "Status",
                    },
                ]} />
        </>
    }
    return <div >
        {accessCheckRender(render, permission)}
    </div >
}