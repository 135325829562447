import { useApolloClient } from "@apollo/client";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Carousel, { consts } from "react-elastic-carousel";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { GET_WELCOME_BANNER } from "../../graphql/welcomescreenqueries";
import { ExtraBold } from "../../utils";

const useStyles = makeStyles((theme) => ({

    closeIcon: {
        position: 'absolute',
        top: '24px',
        right: '64px',
        cursor: 'pointer'
    },
    backgroundImage: {
        objectFit: 'cover',
        height: '500px',
        width: '100%',
    },
    rootmain: {

        '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            margin: '10px',
        },
        width: "100%",
        maxWidth: "auto",
        padding: "0 !important",
        "& .MuiPaper-root": {
            width: "991px !important",
            borderRadius: "12px",
        },
        "& .MuiDialogContent-root": {
            padding: "0px !important",
            position: 'relative',
            display: 'flex'
        },
        '& .MuiDialog-container': {
            padding: (props) => props?.padding ?? '',
        }
    },
    welcome: {
        color: '#091B29',
        fontSize: '24px',
        fontFamily: ExtraBold,
        position: 'absolute',
        top: '62px',
        left: '64px'
    },
    name: {
        color: '#5078E1 !important',
    }
}));
export const WelcomeBanner = ({ open = false, closeDrawer = () => false, name = "", company = {} }) => {

    const classes = useStyles()
    const hour = new Date().getHours();
    const client = useApolloClient();
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const breakPoints = [{ width: 1, itemsToShow: 1 }];
    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />
        return (
            <IconButton size="small" style={{ fontSize: "20px" }} onClick={onClick} disabled={isEdge}>{pointer}</IconButton>
        )
    }
    //get welcome screen banner
    const getWelcomeScreenBanner = () => {

        client.query({
            query: GET_WELCOME_BANNER,
            fetchPolicy: 'network-only',
            variables: {
                id: company,
            }
        }).then((res) => {
            setData(res.data?.company_master)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }
    React.useEffect(() => {
        getWelcomeScreenBanner()
        // eslint-disable-next-line 
    }, [])


    return (
        <Box>
            <Dialog
                open={open}
                onClose={closeDrawer}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.rootmain}
                fullWidth
                maxWidth={"md"}
            >
                <DialogContent sx={{ minHeight: 300 }}>
                    {
                        loading ? "" :
                            <>
                                <Box style={{ zIndex: 1 }}>
                                    <Typography className={classes.welcome}>Welcome <span className={classes.name}>{name}</span>,<br /> {"Good " +
                                        (hour < 12
                                            ? "Morning"
                                            : hour >= 12 && hour < 18
                                                ? "Afternoon"
                                                : "Evening !")}</Typography>
                                    <CloseIcon className={classes.closeIcon} onClick={() => closeDrawer()} />
                                </Box>

                                <Carousel pagination={false} renderArrow={myArrow} disableArrowsOnEnd showArrows={true} breakPoints={breakPoints} >
                                    {
                                        data?.map((val) => {
                                            return (
                                                <Box >

                                                    <img src={val?.welcome_screen_logo ?? '/images/landingImage.png'} alt='landing_page' className={classes.backgroundImage} />
                                                </Box>
                                            )
                                        })
                                    }
                                </Carousel>
                            </>
                    }

                </DialogContent>
            </Dialog>
        </Box>
    )
}