import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Button,
  Grid,
  Drawer,
  Typography,
  Divider,
  Toolbar,
  Switch,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  TextBox,
  SelectBox,
  CurrencyInputComponent,
} from "..";
import { Bold } from "../../utils";

const useStyles = makeStyles((theme, props) => ({
  close: {
    position: "fixed",
    right: "360px",
    top: 10,
    backgroundColor: "white",
    padding: 5,
    display: "flex",
    borderRadius: 5,
    cursor: "pointer",
  },
  bigClose: {
    position: "fixed",
    right: "590px",
    top: 10,
    backgroundColor: "white",
    padding: 5,
    display: "flex",
    borderRadius: 5,
    cursor: "pointer",
  },
  bottomPush: {
    position: "fixed",
    bottom: 0,
    width: '100%',
  },
  drawerHeader: {
    color: '#091B29',
    fontSize: '16px',
    fontFamily: Bold,
  }
}));

export const RightDrawer = (props) => {
  const classes = useStyles();

  const getComponentType = (data) => {
    switch (data.type) {
      case "text": {
        return (
          <Grid xs={12} style={{ margin: 17 }}>
            <TextBox
              label={data.title}
              isrequired={data.isRequired}
              multiline={data.multiline}
              isReadonly={props.readOnly}
            />
          </Grid>
        );
      }
      case "switchbox": {
        return (
          <Grid
            style={{
              display: "flex",
              // justifyContent: "space-between",
              margin: 10,
              paddingLeft: 10,
              alignItems: "center",
            }}
            xs={6}
          >
            <Typography>{data.title}</Typography>
            <Switch></Switch>
          </Grid>
        );
      }
      case "dropdown": {
        return (
          <Grid xs={12} style={{ margin: 17 }}>
            <SelectBox
              label={data.title}
              isrequired={data.isRequired}
              options={data.options}
              isReadonly={props.readOnly}
            />
          </Grid>
        );
      }

      default:
        break;
    }
  };
  const getBigComponentType = (data) => {
    switch (data.type) {
      case "text": {
        return (
          <Grid item xs={12} ls={6} style={{ margin: 15 }}>
            <TextBox
              label={data.title}
              isrequired={data.isRequired}
              multiline={data.multiline}
              isReadonly={props.readOnly}
            />
          </Grid>
        );
      }
      case "switchbox": {
        return (
          <Grid
            item
            style={{
              display: "flex",
              margin: 5,
              paddingLeft: 10,
              alignItems: "center",
            }}
          >
            <Typography>{data.title}</Typography>
            <Switch></Switch>
          </Grid>
        );
      }
      case "dropdown": {
        return (
          <Grid item xs={12} ls={6} style={{ margin: 15 }}>
            <SelectBox
              label={data.title}
              isrequired={data.isRequired}
              options={data.options}
              isReadonly={props.readOnly}
            />
          </Grid>
        );
      }
      case "period": {
        return (
          <Grid
            item
            container
            xs={12}
            ls={6}
            style={{ margin: 10, }}
            alignItems="center"
          >
            <Grid item xs={3}>
              <TextBox
                label={data.title}
                isrequired={data.isRequired}
                multiline={data.multiline}
                type="button"
              />
            </Grid>
            <Grid item xs={2} style={{ marginTop: "18px", marginLeft: "5px" }}>
              <TextBox label="" type="button" />
            </Grid>
          </Grid>
        );
      }
      case "currency": {
        return (
          <Grid item xs={12} ls={6} style={{ margin: 15 }}>
            <CurrencyInputComponent
              label={data.title}
              isReadonly={props.readOnly}
              isCurrency={true}
            />
          </Grid>
        );
      }
      default:
        break;
    }
  };
  return (
    <Drawer anchor="right" open={props?.open} onClose={props?.onClose}>
      <div style={{ width: props?.isBig ? "580px" : "350px", overflow: "hidden" }}>
        <div
          aria-label="clear"
          className={props?.isBig ? classes.bigClose : classes.close}
          onClick={props?.onClose}
        >
          <ClearIcon />
        </div>
        <Toolbar className={classes.drawerHeader}>{props?.title}</Toolbar>
        <Divider />
        {props?.isBig ? (
          <Grid container direction="row" display="flex">
            {props?.content?.map((val, index) => {
              return (
                <Grid item xs={6}>
                  {getBigComponentType(val)}
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <div>
            {props?.content?.length > 0 &&
              props?.content?.map((val, index) => {
                return (
                  <Grid item xs={12}>
                    {getComponentType(val)}
                  </Grid>
                )
              })}
          </div>
        )}

        <div className={classes.bottomPush}>
          <Divider />
          <div style={{ margin: 10 }}>
            <Button variant="contained" size="medium" sx={{ minWidth: props?.isBig ? "44%" : "22%" }}>Create</Button>
          </div>

        </div>
      </div>
    </Drawer>
  );
};

