import React from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import { AlertDialog, SearchFilter, FilterGenerator, Subheader, TableWithPagination, TextBox, UseDebounce } from "../../components";
import Box from "@mui/material/Box";
import { useWindowDimensions, AlertProps, NetWorkCallMethods, UtilitiesMasterHeading, UtilitiesMasterPath, LocalStorageKeys, getCompanyOption, accessCheckRender, getRoutePermissionNew } from "../../utils";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { useApolloClient } from "@apollo/client";
import { UPDATE_UTILITIES_MASTER } from "../../graphql/mutations";
import { UtilitiesMasterStyles } from "./style";
import { GET_UTILITIES_MASTER_TABLE_LIST } from "../../graphql/queries";
import { Badge, IconButton } from "@mui/material";
import FilterIMG from "../../assets/filter";



const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.palette.borderRadius
}))

const CustomPaper = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#ffffff",
    padding: theme.spacing(2),
    margin: theme.spacing(3)
}))


const initialState = () => {
    return {
        id: null,
        utilityName: "",
        description: "",
        edit: false,
        view: false,

        error: {
            description: "",
            utilityName: ""

        }
    }

}


export const UtilitiesMaster = () => {
    const classes = UtilitiesMasterStyles()
    const [openDialog, setOpenDialog] = React.useState(false)
    const size = useWindowDimensions();
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const [utilitiesMasterData, setUtilitiesMasterData] = React.useState({ ...initialState() })
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [companyList, setCompanyList] = React.useState([]);
    const [utilitiesMasterList, setUtilitiesMasterList] = React.useState({});
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [searchText, setSearchText] = React.useState("");
    const userProfileId = localStorage.getItem(LocalStorageKeys.userProfileID)
    const [drawer, setDrawer] = React.useState(null);
    const [filterData, setFilterData] = React.useState({ status: [true] });
    const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const draweropen = () => {
        setDrawer(true);
    };
    const drawerclose = () => {
        setDrawer(false);
    };
    const debounce = UseDebounce();

    const client = useApolloClient();
    //more options
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        UtilitiesMasterTableData(offset, limit, searchText)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        UtilitiesMasterTableData(0, value, searchText)
    }

    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        UtilitiesMasterTableData(0, limit, e)
    }


    //update state
    const updateState = (key, value) => {

        let error = utilitiesMasterData?.error;
        error[key] = "";
        setUtilitiesMasterData({ ...utilitiesMasterData, [key]: value, error })


    }
    //validation
    const validate = () => {
        let isValid = true;
        let error = utilitiesMasterData.error;
        if (utilitiesMasterData?.utilityName?.length === 0) {
            isValid = false;
            error.utilityName = "Utility Name  is Required";
        }

        if (utilitiesMasterData?.utilityName?.length === 0) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please fill all mandatory field",
            });
        }
        setUtilitiesMasterData({ ...utilitiesMasterData, error });

        return isValid;
    };


    //Upsert API FUNCTION
    const UpsertUtilitiesMaster = () => {
        if (validate()) {
            let payload;
            if (utilitiesMasterData?.id) {
                payload = {
                    tenantId: `${config.tenantId}`,
                    utility_name: utilitiesMasterData?.utilityName,
                    updated_by: userProfileId,
                    description: utilitiesMasterData?.description.length > 0 ? utilitiesMasterData?.description : null,
                    company_id: selectedCompany?.value,
                    id: utilitiesMasterData?.id

                }
            } else {
                payload = {
                    tenantId: `${config.tenantId}`,
                    utility_name: utilitiesMasterData?.utilityName,
                    description: utilitiesMasterData?.description.length > 0 ? utilitiesMasterData?.description : null,
                    company_id: selectedCompany?.value,
                    created_by: userProfileId,

                }
            }
            NetworkCall(
                `${config.api_url}/utilities/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: utilitiesMasterData?.id ? "Utility Updated successfully" : "Utility Added successfully.",
                    });
                    setUtilitiesMasterData({ ...initialState() })
                    setOpenDialog(false)
                    UtilitiesMasterTableData()
                })
                .catch((error) => {
                    console.log(error?.errors?.errors?.map((x) => {
                        return x?.msg
                    }))
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Internal error. Please try again later.",
                    });

                });

        }

    }

    //table listing function
    const UtilitiesMasterTableData = async (offset = 0, limit = 10, search = "") => {
        let companyID = [selectedCompany?.value];
        let is_active = filterData?.status ?? [true];
        const payload = {
            query: GET_UTILITIES_MASTER_TABLE_LIST(clientId, offset, limit, search, companyID, is_active).loc.source.body,

        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                setUtilitiesMasterList({
                    data: response?.data?.data?.utilities_master,
                    totalRowsCount: response?.data?.data?.count?.[0]?.count,

                })
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            }).catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                });
            });
    }

    // is active changing function
    const changeactive = async (data, value) => {
        client.mutate({
            mutation: UPDATE_UTILITIES_MASTER,
            variables: {
                ID: data,
                updatePayload: {
                    is_active: value === "Inactive" ? true : false
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Status Updated",
            });
            UtilitiesMasterTableData()
            console.log(rs)
        }).catch(er => {
            console.log(er)
        })
    }
    const deleteTableData = async (data) => {
        client.mutate({
            mutation: UPDATE_UTILITIES_MASTER,
            variables: {
                ID: data,
                updatePayload: {
                    is_delete: true
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Deleted",
            });
            UtilitiesMasterTableData()
            console.log(rs)
        }).catch(er => {
            console.log(er)
        })
    }
    //table row constructions
    const UtilitiesMasterRow = utilitiesMasterList?.data?.map((val, index) => {
        let _d;
        try {
            _d = {
                index: ((page - 1) * limit) + index + 1,
                utilityName: val?.utility_name ?? " - ",
                description: val?.description ?? " - ",
                status: val?.is_active ? "Active" : "Inactive",
                IsDelete: val?.is_delete,
                icon: "more",
                id: val?.id,

            };
        } catch (err) {

        }
        return _d;
    });


    React.useEffect(() => {
        if (selectedCompany?.value) {
            UtilitiesMasterTableData()
        }
        //eslint-disable-next-line
    }, [selectedCompany, filterData]);

    const handleIcon = (type, data, status) => {

        if (type === "view") {
            setOpenDialog(true)
            setUtilitiesMasterData({
                ...utilitiesMasterData,
                utilityName: data?.utilityName,
                description: data?.description,
                view: true,
                id: data?.id
            })
        }
        else if (type === "edit") {
            setOpenDialog(true)
            setUtilitiesMasterData({
                ...utilitiesMasterData,
                utilityName: data?.utilityName,
                description: data?.description,
                edit: true,
                id: data?.id
            })
        }
        else if (type === "active") {
            changeactive(data.id, status)
        }
        else if (type === "delete") {
            deleteTableData(data.id, data.IsDelete)
        }

    }

    //apply filter
    const onApplyFilter = (value) => {
        setFilterData(value)
    }

    // add and edit modal
    const addAndEdit = () => {
        return (
            <>
                <div style={{
                    height: size.height - 250,
                    overflow: "scroll",
                    padding: "24px"
                }}>
                    <Grid container spacing={2}
                    >
                        <Grid item xs={12}>
                            <TextBox
                                value={utilitiesMasterData?.utilityName}
                                isrequired
                                label="Utility Name"
                                placeholder="Enter Utility Name"
                                onChange={(e) => {
                                    updateState("utilityName", e.target.value);
                                }}
                                isError={utilitiesMasterData?.error?.utilityName?.length > 0}
                                errorMessage={utilitiesMasterData?.error?.utilityName}
                                isReadonly={utilitiesMasterData?.view}

                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextBox multiline
                                value={utilitiesMasterData?.description ?? ""}
                                padding={0}
                                label="Description"
                                placeholder="Enter Description"
                                onChange={(e) => {
                                    updateState("description", e.target.value);
                                }}
                                isError={utilitiesMasterData?.error?.description?.length > 0}
                                errorMessage={utilitiesMasterData?.error?.description}
                                isReadonly={utilitiesMasterData?.view}
                            />

                        </Grid>


                    </Grid>
                </div>
                {(((utilitiesMasterData?.edit || utilitiesMasterData?.view) && permission?.update) ||
                    (!utilitiesMasterData?.view && !utilitiesMasterData?.edit && permission?.create)) &&
                    <Grid container padding={"24px"}>
                        <Grid item xs={12}>
                            <Button fullWidth variant="contained" className={classes.btn}
                                onClick={
                                    utilitiesMasterData?.view ? () =>
                                        setUtilitiesMasterData({
                                            ...utilitiesMasterData,
                                            view: false,
                                            edit: true
                                        })
                                        : UpsertUtilitiesMaster}
                            >
                                {utilitiesMasterData?.view ? "Edit" : utilitiesMasterData?.edit ? "Update" : "Create"}

                            </Button>
                        </Grid>
                    </Grid>}
            </>
        )
    }

    const render = () => {
        return <>
            <div>
                <Subheader title={"Utilties Master"} hideBackButton select options={companyList} value={selectedCompany}
                    onchange={(e) =>
                        setSelectedCompany(e)
                        // getProperty(e)
                    }
                />
                <CustomPaper>
                    <Grid container className={classes.content} spacing={2}>
                        <Grid item xs={4}>
                            <SearchFilter placeholder={"Search by utility name"} value={searchText} handleChange={(value) => handleSearch(value)} />
                        </Grid>
                        <Grid item xs={8} textAlign={"right"}>
                            <Box
                                display={"flex"}
                                sx={{ float: "right" }}
                            >
                                <Box marginRight={"12px"}>
                                    {filterData.status?.length > 0 || filterData.property_type?.length > 0 ? (
                                        <IconButton onClick={draweropen} className={classes.img}>
                                            <Badge variant="dot" color="primary">
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton>

                                    ) : (
                                        <IconButton onClick={draweropen} className={classes.img}>
                                            <FilterIMG color="#091b29" />
                                        </IconButton>)}

                                </Box>

                                {permission?.create &&
                                    <Box>
                                        <CustomButton
                                            color="primary"
                                            variant="contained"
                                            onClick={() => setOpenDialog(true)}
                                        >
                                            Add utility
                                        </CustomButton>
                                    </Box>}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <TableWithPagination
                                heading={UtilitiesMasterHeading}
                                rows={UtilitiesMasterRow?.length ? UtilitiesMasterRow : []}
                                path={UtilitiesMasterPath}
                                showpagination={true}
                                tableType="no-side"
                                handleIcon={handleIcon}
                                onClick={() => null}
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                dataType={[
                                    { type: ["text"], name: "utilityName" },
                                    { type: ["text"], name: "description" },
                                    { type: ["status"], name: "status" },
                                    { type: ["more"], icon: "icon" },
                                ]}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={utilitiesMasterList?.totalRowsCount}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 290px)'}
                                view={permission?.read}
                                edit={permission?.update}
                                delete={permission?.delete} />
                        </Grid>
                    </Grid>
                </CustomPaper>

            </div>
            {/* add and edit  and view */}
            <AlertDialog
                isNormal
                header={utilitiesMasterData?.view ? "View Utility" : utilitiesMasterData?.edit ? "Edit Utility" : "Add Utility"}
                onClose={() => {
                    setOpenDialog(false)
                    setUtilitiesMasterData({ ...initialState() })
                }}
                open={openDialog}
                component={
                    <>
                        <div>
                            {

                                addAndEdit()}
                        </div>
                    </>
                }
            />

            {/* filter drawer */}
            {
                drawer &&
                <FilterGenerator
                    open={drawer}
                    onClose={drawerclose}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: [
                                { label: 'Active', value: true },
                                { label: 'Inactive', value: false },
                            ],
                            isMulti: true,
                            state_name: "status",
                            label: "Status",
                            // required:true
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            }
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};

