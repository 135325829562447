import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { DocumentViewer } from "../../../components/fileViewer";
import { AggrementComponentStyles } from "./styles";

export const Slider = (props) => {
  const classes = AggrementComponentStyles(props);
  const [selected, setSelected] = React.useState(0)

  const next = () => {
    if ((props?.assets?.length - 1) > selected) {
      setSelected(selected + 1)
    }
  }

  const previous = () => {
    if (selected !== 0) {
      setSelected(selected - 1)
    }
  }

  React.useEffect(() => {
    setSelected(0)
  }, [props?.assets])

  return (
    <div className={classes.caroselRoot}>
      {/* carosel */}
      <Grid container alignItems="center">
        <Grid item xs={1}>

          <IconButton disabled={selected === 0} onClick={previous} size="small" className={classes.arrowBtn}>
            <ArrowBackIosIcon style={{ fontSize: "12px" }} />
          </IconButton>


        </Grid>
        <Grid item xs={10} onClick={() => props?.onClick(props?.assets[selected])}>
          {props?.assets[selected] &&
            < Box >
              {props?.assets[selected]?.type === "application/pdf" ?
                <Box height="220px">
                  {props?.assets[selected]?.src &&
                    <DocumentViewer url={props?.assets[selected]?.src} />}
                </Box>
                :
                <img
                  src={props.isInventory ? props?.assets[selected]?.url : props?.assets[selected]?.src}
                  alt=""
                  height="220px"
                  width="100%"
                  style={{ borderRadius: "4px !important", objectFit: "contain" }}
                />
              }
            </Box>
          }
          {
            !props?.pageNumber &&
            <Typography sx={{ marginTop: "4px" }} textAlign="center" className={classes.title}>{selected + 1}/{props?.assets?.length}</Typography>
          }

        </Grid>
        <Grid item xs={1}>

          <IconButton disabled={(props?.assets?.length - 1) > selected ? false : true} onClick={next} size="small" className={classes.arrowBtn}>
            <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
          </IconButton>


        </Grid>
      </Grid>
    </div>
  );
};
