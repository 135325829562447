import { Box, InputAdornment, Menu, MenuItem, Typography } from "@mui/material";
import React from 'react';
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarWithTime } from "../calendarWithTime";
import { TextBox } from "../textbox";
import { useStyles } from './style';
import './style.css';
import moment from "moment";

export const NewDatePicker = (props) => {
      const classes = useStyles(props);
      const [anchorEl, setAnchorEl] = React.useState(false)
      const datePickerOpen = Boolean(anchorEl)

      const handleClose = () => {
            setAnchorEl(null)
      }

      const getLabel = (props) => {
            return (
                  <div className={classes.labelRoot}>
                        {
                              <Typography
                                    variant="body1"
                                    color="textsecondary"
                                    className={classes.Label}
                                    align="left"
                              >
                                    {props?.label}
                              </Typography>
                        }
                        {props?.isRequired && (
                              <Typography color="error" variant="caption">
                                    &nbsp;*
                              </Typography>
                        )}
                  </div>
            );
      };

      return (
            <>
                  {
                        !props?.newDate ?
                              <div className={classes.datePickerRoot}>
                                    {getLabel(props)}
                                    <Datepicker
                                          selected={props?.value}
                                          onChange={date => props?.onChange(date)}
                                          peekNextMonth
                                          disabled={props?.disabled}
                                          showMonthDropdown
                                          showYearDropdown

                                          dropdownMode="select"
                                          className={classes.textbox}
                                          placeholderText={props?.placeholder}
                                          minDate={props?.minDate ?? false}
                                          maxDate={props?.maxDate ?? false}
                                    />
                                    <div className={classes.calander}>
                                          <img src={'/images/icons8-calendar (5).svg'} alt="" />
                                    </div>
                              </div>
                              :
                              <div>
                                    <Box>
                                          <Box onClick={(event) => setAnchorEl(event.currentTarget)}>
                                                <TextBox
                                                      isReadonly={props?.disabled}
                                                      isrequired = {props?.isRequired}
                                                      label={props?.label}
                                                      placeholder={props?.placeholder}
                                                      endAdornment={
                                                            <InputAdornment position="end">
                                                                  <img src={'/images/icons8-calendar (5).svg'} alt="" />
                                                            </InputAdornment>
                                                          }
                                                      value={props?.value ? moment(props?.value).format("DD MMM YY") : ""}/>
                                          </Box>

                                          <Menu
                                                anchorEl={anchorEl}
                                                open={datePickerOpen}
                                                className={classes.menublock}
                                                onClose={handleClose}
                                                PaperProps={{ sx: { width: "360px", backgroundColor: "white", border: "1px solid #E4E8EE", boxShadow: "0px 0px 16px #00000014" } }}
                                          >
                                                <MenuItem className={classes.menuList} disableRipple>
                                                      <CalendarWithTime
                                                            handleDateChange={date => props?.onChange(date)}
                                                            dateTime={props?.value ? props?.value : new Date()}
                                                            setAnchorEl={setAnchorEl}
                                                            noTime
                                                            hideCondition={props?.hideCondition ?? true}
                                                      />
                                                </MenuItem> 
                                          </Menu>
                                    </Box>
                              </div>}
                  {props.isError && (
                        <Typography variant={"caption"} color={"error"}>
                              {props.errorMessage}
                        </Typography>
                  )}
            </>
      )
}