import React, { useState, useContext } from "react";
import { useStyles } from "./style";
import { Grid, Box, Typography, Button } from "@mui/material";
import ReactQuill from "react-quill";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment";
import { DialogBox } from "../../components";
import InfiniteScroll from "react-infinite-scroll-component";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import { useEffect } from "react";
import { AlertContext } from "../../contexts";

export const ViewAnnounsment = ({
  t,
  company = {},
  details = {},
  builds = [],
  property = [],
}) => {
  //   context
  const alert = useContext(AlertContext);
  // classes
  const classes = useStyles();
  const [dialog, setDialog] = useState(false);
  const [properties, setProperties] = useState({
    data: [],
    count: 0,
    created_at: ""
  });
  const [offset, setOffset] = useState(0);
  // get builds list
  const getBuilds = (offsets, filter) => {
    const requestPayload = {
      offset: offsets ?? 0,
      limit: 10,
      company_id: company?.value,
      id: details?.id ?? "",
    };
    NetworkCall(
      `${config.api_url}/notifications/view`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((res) => {
        const listData = res?.data?.data?.property_res?.data?.map((x) => {
          return {
            title: x?.name,
            subTitle: `${x?.state} , ${x?.country}`,
            logo: x?.logo,
          };
        });
        if (filter) {
          setProperties({
            data: properties?.data?.concat(listData),
            count: res?.data?.data?.property_res?.count,
            created_by: res?.data?.data?.created_user
          });
        } else {
          setProperties({
            data: listData,
            count: res?.data?.data?.property_res?.count,
            created_by: res?.data?.data?.created_user
          });
        }
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong. Please try again",
        });
      });
  };
  // infinity scroll function for roles
  const fetchMoreData = () => {
    setOffset(offset + 10);
    getBuilds(offset + 10, true);
  };
  useEffect(() => {
    if (details?.id) {
      getBuilds(0, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.id]);

  const modules = {
    toolbar: false,
  };
  const [format, setFormat] = useState("App");

  return (
    <div className={classes.viewRoot}>
      <Grid container spacing={1}>
        <Grid item xs={3.5}>
          <Box pr={1}>
            <Typography className={classes.title}>
              {t("ASSIGNED_PROPERTIES")} {"(" + (properties?.count ?? 0) + ")"}
            </Typography>
            <Box height={"11px"} />
            <InfiniteScroll
              dataLength={properties?.data?.length ?? 0}
              next={fetchMoreData}
              hasMore={true}
              height={400}
            >
              <div>
                {properties?.data?.map((x) => {
                  return (
                    <>
                      <Box className={classes.selectBox}>
                        <Box display="flex" alignItems="center">
                          <img
                            src={x?.logo ?? ""}
                            alt="images"
                            className={classes.images}
                          />
                          <Box ml={2}>
                            <Typography className={classes.optionTitle}>
                              {x?.title?.length > 0
                                ? `${x?.title?.substring(0, 13)}${x?.title?.length > 13 ? "..." : ""
                                } `
                                : "-"}
                            </Typography>
                            <Typography className={classes.optionSub}>
                              {x?.subTitle?.length > 0
                                ? `${x?.subTitle?.substring(0, 15)}${x?.subTitle?.length > 15 ? "..." : ""
                                } `
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box height={"11px"} />
                    </>
                  );
                })}
              </div>
            </InfiniteScroll>
          </Box>
        </Grid>
        <Grid item xs={3.5} className={classes.border}>
          <Box pr={1}>
            <Typography className={classes.title}>
              {t("ASSIGNED_MODULE")} {"(" + (builds?.length ?? 0) + ")"}
            </Typography>
            <Box height={"11px"} />
            <div className={classes.contant}>
              {builds?.map((x) => {
                return (
                  <>
                    <Box className={classes.selectBox}>
                      <Box display="flex" alignItems="center">
                        <Box>
                          <Typography className={classes.optionTitle}>
                            {x?.build_name ?? ""}
                          </Typography>
                          <Typography className={classes.optionSub}>
                            {" "}
                            {x?.build_code ?? ""}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box height={"11px"} />
                  </>
                );
              })}
            </div>
          </Box>
        </Grid>
        <Grid item xs={5} className={classes.border}>
          <Box>
            <Typography className={classes.title}>{t("Mobile_Preview")}</Typography>
            <div className={classes.detailsPadding}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Box>
                  <Box height={"8px"} />
                  <Box className={classes.toggle}>
                    {["App", "E-mail"]?.map((x) => {
                      return (
                        <Button
                          className={
                            x === format ? classes.select : classes.unSelect
                          }
                          onClick={() => setFormat(x)}
                        >
                          {x}
                        </Button>
                      );
                    })}
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    className={classes.emailPreview}
                    onClick={() => setDialog(true)}
                  >
                    {t("Email_Preview")}
                  </Button>
                </Box>
              </Box>
              <Box height={"19px"} />
              {/* preview section */}
              <div>
                {format === "App" ? (
                  <>
                    <Box className={classes.titleBar}>
                      <Typography className={classes.announsmentTitle}>
                        {t("announcement")}
                      </Typography>
                      <ArrowBackIosIcon className={classes.arrowIcon} />
                    </Box>
                    {/* <Box height={"14px"} className={classes.headerBox} /> */}
                  </>
                ) : (
                  <Box className={classes.headerBox1}>
                    <img
                      src={company?.data?.logo}
                      alt={company?.label}
                      width="141px"
                      height={"30px"}
                    />
                  </Box>
                )}

                <div className={classes.detailscontent}>
                  <Box className={classes.detailsBox}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={"space-between"}
                      p={1}
                      mb={1.5}
                    >
                      {details?.category?.length > 0 && (
                        <Typography className={classes.chip}>
                          {details?.category ?? ""}
                        </Typography>
                      )}

                      <Typography className={classes.dateAndTime}>
                        Posted at{" "}
                        {moment(details?.created_at).format(
                          "DD-MM-YYYY,hh:mm A"
                        )}
                        , by {property?.created_by?.first_name ?? " "}&nbsp;
                        {property?.created_by?.last_name ?? " "}
                      </Typography>
                    </Box>
                    {details?.description?.map((val) => {
                      return (
                        <>
                          <Grid container>
                            {val?.images && (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    mb: 2,
                                  }}
                                >
                                  <img
                                    src={val?.images}
                                    style={{
                                      width: "100%",
                                      height: "250px",
                                    }}
                                    alt="images"
                                  />
                                </Grid>
                              </>
                            )}
                            {val?.title && (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ textAlign: "left" }}
                                >
                                  <ReactQuill
                                    readOnly
                                    theme="bubble"
                                    value={val?.title}
                                    modules={modules}
                                    className={classes.reactQuil}
                                  />
                                </Grid>
                                <Box>
                                  <ReactQuill
                                    readOnly
                                    theme="bubble"
                                    value={val?.Description}
                                    modules={modules}
                                    className={classes.reactQuil}
                                  />
                                </Box>
                              </>
                            )}
                            {val?.hasOwnProperty("attachment") && (
                              <Grid item xs={12}>
                                <Grid container spacing={1} mt={1}>
                                  {val?.attachment?.map((x) => {
                                    return (
                                      <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Box className={classes.detailsBox1}>
                                          <Typography
                                            className={classes.fileName}
                                          >
                                            {x?.name ?? ""}
                                          </Typography>
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                          >
                                            <Typography
                                              className={classes.type}
                                            >
                                              {x?.size ?? ""}
                                            </Typography>
                                            <Box className={classes.dot} />
                                            <Typography
                                              className={classes.type}
                                            >
                                              PDF
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </>
                      );
                    })}
                  </Box>
                </div>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
      <DialogBox
        id={"id_123"}
        open={dialog}
        onClose={() => setDialog(false)}
        handleClose={() => setDialog(false)}
        header={`Email Web Preview`}
        isNormal
        // width={"710px !important"}
        maxWidth={"md"}
        component={
          <Box>
            <EmailPreview details={details} classes={classes} is_show user={property?.created_by} />
          </Box>
        }
      />
    </div>
  );
};

export const EmailPreview = ({
  details = {},
  classes = {},
  is_show = false,
  openPdf = () => false,
  user = ""
}) => {
  return (
    <>
      <body width="100%" id="body">
        <div
          class="email-container"
        >
          <div
            style={{
              overflow: "auto",
              backgroundColor: "white",
              padding: "16px",
            }}
          >
            <Box style={{ backgroundColor: "white", padding: "16px" }}>
              <Box
                p={1}
                style={{
                  display: "flex",
                  webkitBoxAlign: "center",
                  msFlexAlign: "center",
                  alignItems: "center",
                  webkitBoxPack: "space-between",
                  msFlexPack: "space-between",
                  justifyContent: "space-between",
                  marginBottom: "12px",
                }}
              >
                {details?.category?.length > 0 && (
                  <Typography
                    style={{
                      borderRadius: "4px",
                      padding: "4px",
                      color: "#98A0AC",
                      fontSize: "10px",
                      backgroundColor: "#E4E8EE",
                    }}
                  >
                    {details?.category}
                  </Typography>
                )}

                <Typography
                  style={{
                    borderRadius: "4px",
                    padding: "4px",
                    color: "#98A0AC",
                    fontSize: "10px",
                  }}
                >
                  Posted at {moment(new Date()).format("DD-MM-YYYY,hh:mm A")},
                  by {user?.first_name ?? " "}&nbsp;
                  {user?.last_name ?? " "}
                </Typography>
              </Box>
              {details?.description?.map((val) => {
                return (
                  <>
                    <Grid container>
                      {val?.images && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              mb: 2,
                            }}
                          >
                            <img
                              src={val?.images}
                              style={{
                                width: "100%",
                                height: "250px",
                              }}
                              alt="images"
                              crossorigin="anonymous"
                            />
                          </Grid>
                        </>
                      )}
                      {val?.title && (
                        <>
                          <Grid item xs={12} style={{ textAlign: "left" }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: val?.title,
                              }}
                              style={{
                                padding: "0px 0px 0px 0px",
                                marginBottom: "0!important",
                              }}
                            />
                          </Grid>
                          <Box>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: val?.Description,
                              }}
                              style={{ padding: "0px 0px 0px 0px" }}
                            />
                          </Box>
                        </>
                      )}
                      {val?.attachment && is_show && (
                        <Grid item xs={12}>
                          <Grid container spacing={1} mt={1}>
                            {val?.attachment?.map((x) => {
                              return (
                                <Grid item xs={is_show ? 2 : 4} sm={3} md={1.5} lg={2}>
                                  <Box className={classes.detailsBox1} onClick={() => openPdf(x)}>
                                    <Typography className={classes.fileName}>
                                      {x?.name ?? ""}
                                    </Typography>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography className={classes.type}>
                                        {x?.size ?? ""}
                                      </Typography>
                                      <Box className={classes.dot} />
                                      <Typography className={classes.type}>
                                        PDF
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </>
                );
              })}
            </Box>
          </div>
        </div>
      </body>
    </>
  );
};
