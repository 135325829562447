import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../utils";
export const propertyCardStyles = makeStyles((theme) => ({
    root: {
        borderBottom: "1px solid #E4E8EE",
        backgroundColor: "white",
        padding: "8px 12px",
        cursor: "pointer"
    },
    img: {
        height: "100px",
        width: "100%",
        borderRadius: theme.palette.borderRadius,
        objectFit: "cover"
    },
    title: {
        fontSize: "16px",
        color: "#091B29",
        fontFamily: Bold,
        textTransform: "capitalize"
    },
    address: {
        fontSize: "14px",
        color: "#98A0AC",
        fontFamily: Regular,
        textTransform: "capitalize",
        marginTop: "2px"
    },
    build: {
        fontSize: "12px",
        color: "#4E5A6B",
        fontFamily: Bold
    },
    build1: {
        fontSize: "12px",
        color: "#98A0AC",
        fontFamily: Regular
    },
    availabe: {
        fontSize: "12px",
        color: "#5AC782",
        fontFamily: Bold,
        backgroundColor: "#EEF9EE",
        padding: "2px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline-block",
        marginTop: "10px"
    },
    imgDiv: {
        position: "relative",
    },
    type: {
        position: "absolute",
        bottom: "7px",
        left: "0px",
        right: "0px",
        textAlign: "center",
        backgroundColor: "#071741",
        color: "white",
        fontSize: "12px",
        fontFamily: SemiBold,
        padding: "4px 0px",
        borderRadius: `0px 0px ${theme.palette.borderRadius} ${theme.palette.borderRadius}`,
    }
}));