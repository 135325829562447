import { useApolloClient } from "@apollo/client";
import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { CustomTabs } from '../../components';
import { CustomSelect } from "../../components/filterGenerator/components";
import { TeamsSelect } from "../../components/teamsSelect";
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { GETLEADBYOWNER } from "../../graphql/quotationQueries";
import { Routes } from '../../router/routes';
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, Regular } from '../../utils';
import { Filter as FilterComponent } from "../propertyFinder3/component/filter";
import { CustomPaper, CustomTypography, FilterList } from './components';
import { Commercial, Promotion, Residential } from './tabPanel';
import { commercialValidation, filterType, getCoords, leaseValidation, residentialValidation, returnFilterValue, typeCaps } from './utils';
import { Bold } from "../../utils";
const BackgroundImage = styled(Grid)(({ theme }) => ({
    backgroundImage: `url(/images/propertySearchBanner.svg)`,
    backgroundSize: 'contain',
    paddingBottom: 0,
    paddingLeft: 0,
    backgroundRepeat: 'no-repeat',
    height: `100vh`,
    overflow: "auto",
    display: "flex",
    alignItems: "start",
    alignContent: "start",
    borderRadius: theme.palette.borderRadius,
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
        paddingBottom: 0
    }
}));

const Wrapper = styled('div')(({ theme }) => ({
    padding: "30px 0px 30px 0px",
    // maxWidth: '90%',
    //margin: 'auto',
}));

const FilterButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: "#F1F7FF",
    borderRadius: theme.palette.borderRadius,
    width: 42,
    height: 42,
}));

const tabList = [
    { title: 'Residential', },
    { title: 'Commercial' },
    // { title: 'Short Term Rental' },
]

export const PropertyFinder = (props) => {
    const { loading, handleLoading } = props;
    const auth = React.useContext(AuthContext);
    const theme = useTheme();
    const alert = useContext(AlertContext);
    const history = useHistory();
    const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
    const { state: LocationParams } = useLocation()
    const [buttonDisable, setButtonDisable] = useState(false);
    const tabStyles = {
        tabs: {
            minHeight: 32,
            '& .MuiTabs-flexContainer': {
                gap: '4px',
                '& .MuiButtonBase-root': {
                    backgroundColor: theme.palette.background.tertiary,
                    borderRadius: '4px',
                    padding: '8px 12px',
                    minHeight: '32px',
                    '& p': {
                        fontFamily: Regular,
                        fontSize: '12px',
                        color: theme.typography.color.tertiary,
                    }
                },
                '& .Mui-selected': {
                    '& p': {
                        color: '#fff !important',
                        fontFamily: Bold
                    },
                    backgroundColor: theme.palette.primary.main,
                },
            },
            '& .MuiTabs-indicator': {
                height: 0
            }
        }
    }

    const [activeTab, setActiveTab] = useState(0);
    const [location, setLocation] = useState({});

    let initialState = useMemo(() => ({
        propertyPurpose: { label: "Lease", value: "Lease", },
        propertyType: { value: null, label: "All" },
        unitCategory: { value: null, label: "All" },
        duration: {
            date: new Date(),
            input: 12,
            period: 'Month'
        }
        // eslint-disable-next-line
    }), [activeTab])

    const [state, setState] = useState({
        residential: {
            ...initialState,
            bedBath: {
                bed: 1,
                bath: 1,
            },
            lead: null
        },
        commercial: {
            ...initialState,
            lead: null,
        },
        storage: {
            ...initialState,
            lead: null,
        },
        lease: {
            propertyType: { value: null, label: "All" },
            unitCategory: { value: null, label: "All" },
        }
    });

    const [statefilter, setStateFilter] = React.useState({})
    const [filterData, setFilterData] = React.useState({})
    const [drawer, setDrawer] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState({});
    const [teamNumber, setTeamNumber] = useState({})
    const backdrop = useContext(BackdropContext);
    const [selectedLead, setSelectedLead] = React.useState(null)
    const [permission, setPermission] = React.useState({})
    const [leadList, setLeadlist] = useState([]);
    const client = useApolloClient();
    // use effect to get permission
    React.useEffect(() => {
        getLeadList("", 10, true)
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCurrentLocation()
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setFilterData({
                        rangetype: company?.selected?.uom,
                        budgettype: company?.selected?.currency,
                    })
                    if (LocationParams) {
                        setSelectedCompany(LocationParams?.company ?? company?.selected)
                    } else {
                        setSelectedCompany(company?.selected)
                    }
                    handleLoading(false)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getCurrentLocation = async () => {
        const location = await getCoords();
        if (location?.latitude && location?.longitude) {
            setLocation(location)
        }
    }

    const updateStateFilter = (key, value) => {
        setStateFilter({ ...statefilter, [key]: value });
    };

    const onChangeState = (parentKey, key, value) => {
        if (key === "location") {
            setLocation(value)
        }
        else {
            let updateState = {
                ...state, [parentKey]: {
                    ...state?.[parentKey], [key]: value
                }
            }
            // if (key === "propertyPurpose") {
            //     if (value?.value === "Sale") {
            //         updateState['residential']['handOverDate'] = {
            //             date:new Date()
            //         }
            //     }
            //     else {
            //         updateState['residential']['duration'] = {
            //             date: new Date(),
            //             input: 12,
            //             period: 'Month'
            //         }
            //     }
            // }
            setState(updateState)
        }
    }

    //get lead
    const getLeadList = (search, offset, searchBool) => {
        client.query({
            query: GETLEADBYOWNER,
            fetchPolicy: 'network-only',
            variables: {
                companyId: parseInt(selectedCompany?.value),
                searchText: search,
                offset: offset,
                clinetID: localStorage.getItem(LocalStorageKeys.clinetID),
                limit: 10
            }
        }).then((response) => {
            if (searchBool) {
                setLeadlist(response.data.lead);
            } else {
                setLeadlist(leadList.concat(response.data.lead));
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleLead = (leadDetail) => {
        setSelectedLead(leadDetail)
        let type = switchType(activeTab)

        setState({ ...state, [type]: { ...state[type], lead: leadDetail } })
        setFilterData({ ...filterData, facilitesbool: leadDetail?.grace_period_value })
        setActiveTab(switchTypeByvalue(leadDetail?.unit_usage))
    }

    const switchTab = useCallback(() => {

        switch (activeTab) {
            case 0:
                return (
                    <Residential
                        data={state?.residential}
                        onChangeState={(key, value) => onChangeState('residential', key, value)}
                        location={location}
                        company={selectedCompany?.value}
                        filterData={filterData}
                        handleLead={handleLead}
                        selectedLead={selectedLead}
                    />
                );
            case 1:
                return (
                    <Commercial
                        data={state?.commercial}
                        onChangeState={(key, value) => onChangeState('commercial', key, value)}
                        location={location}
                        filterData={filterData}
                        company={selectedCompany?.value}
                        hideRevenue={true}
                        handleLead={handleLead}
                        selectedLead={selectedLead}
                    />
                );
            // case 2:
            //     return (
            //         <Storage
            //             data={state?.storage}
            //             onChangeState={(key, value) => onChangeState('storage', key, value)}
            //             location={location}
            //             filterData={filterData}
            //             company={selectedCompany?.value}
            //         />
            //     );
            // case 2:
            //     return (
            //         <ShortTermRental
            //             data={state?.lease}
            //             onChangeState={(key, value) => onChangeState('lease', key, value)}
            //             location={location}
            //             company={selectedCompany?.value}
            //             handleLead={handleLead}
            //             selectedLead={selectedLead}
            //         />
            //     );
            default:
                return <div />
        }
        // eslint-disable-next-line
    }, [activeTab, state, location, filterData, selectedCompany])

    const switchType = (type) => {
        if (type === 0) {
            return 'residential'
        }
        else if (type === 1) {
            return 'commercial'
        }
        else if (type === 2) {
            return 'lease'
        }
    }

    const switchTypeByvalue = (type) => {
        if (type === "Residential") {
            return 0
        }
        else if (type === 'Commercial') {
            return 1
        }
        // else if (type === 'Storage') {
        //     return 2
        // }
        else if (type === 'Lease') {
            return 2
        }
        else {
            return 1
        }
    }

    const validate = () => {
        let result;
        let type = switchType(activeTab);
        if (type === "residential") {
            result = residentialValidation({ ...state?.[type], location });
        }
        else if (type === "commercial") {
            result = commercialValidation({ ...state?.[type], location });
        }
        else if (type === "lease") {
            result = leaseValidation({ ...state?.[type], location });
        }

        if (!result) return true
        showAlert('Please fill all the fields')
        return false
    }

    const showAlert = (msg = "") => {
        alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
        })
    }

    const search = () => {
        if (validate()) {
            setButtonDisable(true)
            let type = switchType(activeTab)
            history.push({
                pathname: Routes.propertyResult,
                state: {
                    data: { ...state?.[type], location },
                    type,
                    location,
                    purpose: type === "lease" ? "Commercial" : typeCaps(switchType(activeTab)),
                    filterData,
                    filterType: returnFilterValue(activeTab),
                    companyID: selectedCompany,
                    teamNumber: teamNumber,
                    moduleId: moduleId
                }
            })
        }
    }

    const clearAll = () => {
        setState({})
        setLocation()
    }

    const onClear = () => {
        setStateFilter({})
    }

    const openFilter = () => {
        setStateFilter(filterData)
        setDrawer(true)
    }

    const onClearChips = (key, value) => {
        let copyFilterData = { ...filterData }
        setFilterData(() => {
            if (key === "range") {
                delete copyFilterData['range1']
                delete copyFilterData['range2']
            }
            else if (key === "budget") {
                delete copyFilterData['budget1']
                delete copyFilterData['budget2']
            }
            else {
                delete copyFilterData[key]
            }
            return {
                ...copyFilterData
            }
        })
    }

    const handleCompanyChange = (value) => {
        setTeamNumber({})
        setFilterData({
            rangetype: {
                value: value?.uom,
                label: value?.uom,
            },
            budgettype: value?.currency,
        })
        setSelectedCompany(value)
    }

    const handleTeamNumber = (value) => {
        setTeamNumber(value)
    }



    const render = () => {
        return <>

            <Box marginTop="-10px">
                <BackgroundImage container justifyContent="center">

                    <Grid
                        item
                        xs={12} sm={12} md={9}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Wrapper>
                            <CustomTypography
                                color={"#fff"}
                                fontSize={12}
                                fontFamily={Bold}
                            >
                                PROPERTY FINDER
                            </CustomTypography>
                            <CustomTypography
                                color={theme.typography.color.primary}
                                fontSize={30}
                                fontFamily={Bold}
                                sx={(theme) => ({
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: '25px'
                                    }
                                })}
                            >
                                Find
                                <CustomTypography
                                    component={"span"}
                                    color={"#fff"}
                                    fontFamily={"inherit"}
                                    fontSize={"inherit"}
                                >
                                    {' '}Your Real Estate{' '}
                                </CustomTypography>
                                {'& Get'}
                                <CustomTypography
                                    component={"span"}
                                    color={"#fff"}
                                    fontFamily={"inherit"}
                                    fontSize={"inherit"}
                                >
                                    {' '}Your Dream Space{' '}
                                </CustomTypography>
                            </CustomTypography>
                        </Wrapper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <CustomPaper
                            backgroundColor={'#FFFFFF'}
                            borderRadius={theme.palette.borderRadius}
                            padding={theme.spacing(2.5)}
                            sx={(theme) => ({
                                maxWidth: '100%',
                                marginLeft: 'auto',
                                marginBottom: 4,
                                [theme.breakpoints.down('510')]: {
                                    maxWidth: '100%'
                                }
                            })}
                        >
                            <CustomTabs
                                tabList={tabList}
                                active={activeTab}
                                onChangeTab={(value) => {
                                    setSelectedLead(null)
                                    setActiveTab(value)
                                }}
                                tabPanel={<div style={{ paddingTop: 16 }}>{switchTab()}</div>}
                                sx={tabStyles}
                                rightSideComponent={
                                    <Stack direction="row" spacing={2} alignItems={"center"}>

                                        <Typography sx={{ color: "#FF4B4B", fontSize: 12, cursor: "pointer" }} onClick={clearAll}> <u>Clear All</u> </Typography>
                                        <Box width="220px">
                                            <CustomSelect
                                                options={companyList}
                                                value={selectedCompany}
                                                noSearch={true}
                                                onChange={(e) => {
                                                    handleCompanyChange(e)
                                                }}
                                            />
                                        </Box>
                                        {
                                            teamNumber?.value !== "noteams" &&
                                            <Box width="220px">
                                                <TeamsSelect
                                                    companyId={selectedCompany?.value}
                                                    handleTeamsChange={handleTeamNumber}
                                                    moduleId={moduleId}
                                                    customSelect
                                                />
                                            </Box>
                                        }
                                        <FilterButton
                                            sx={{
                                                backgroundColor: '#fff',
                                                border: '1px solid #E4E8EE',
                                                borderRadius: "4px",
                                                '& img':
                                                    { width: 14, height: 14 }
                                            }}
                                            onClick={openFilter}
                                        >
                                            <img src="/images/filterWhite.svg" alt="" />
                                        </FilterButton>
                                    </Stack>
                                }
                            />

                            {permission?.read && <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    {
                                        (Object.keys(filterData)?.length > 0) &&
                                        <div style={{ marginTop: 16 }}>
                                            <FilterList
                                                filterData={filterData}
                                                onClearChips={onClearChips}
                                            />
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        disabled={buttonDisable}
                                        color={"primary"}
                                        variant={"contained"}
                                        fullWidth
                                        sx={{
                                            borderRadius: "4px",
                                            marginTop: "12px",
                                            p: '10px'
                                        }}
                                        onClick={search}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>}


                        </CustomPaper>
                        <Promotion company_id={selectedCompany?.value} />
                        <Box height="100px" />
                    </Grid>

                </BackgroundImage>
                <Drawer
                    anchor='right'
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    sx={{
                        "& .MuiDrawer-paper": {
                            boxShadow: "none",
                            border: 0,
                            backgroundColor: "transparent",
                        }
                    }}
                >
                    <FilterComponent
                        filter={returnFilterValue(activeTab)}
                        type={filterType(switchType(activeTab))}
                        applyfilter={() => {
                            setDrawer(false)
                            setFilterData(statefilter);
                        }}
                        onClear={onClear}
                        state={statefilter}
                        company={selectedCompany?.value}
                        updateState={updateStateFilter}
                        onClose={() => {
                            setDrawer(false)

                        }} />

                </Drawer>

            </Box>
        </>
    }

    return (
        <div>
            {accessCheckRender(render, permission, "", loading)}
        </div>
    )
}