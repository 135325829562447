import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { AlertContext, BackdropContext } from "../../../contexts";
import { multiImageUpload, assestType } from "../../../utils/common";
import { AlertProps } from "../../../utils";
import { useStyles } from "../style";
import { ImageUpload } from "../../../assets/imageupload";
import { ImageCard } from "../../../components";
import { formatSizeUnits } from "../common";

export const SingleImgUpload = (props) => {
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  // const types = ["image/pdf"];

  const updateState = async (data) => {
    // if (data['.type'] === ".pdf") {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "...UpLoading",
    });
    try {
      let propsData = {
        companyId: props?.companyId,
        type: assestType?.General_Images,
      };
      const image = await multiImageUpload(data, propsData);
      console.log(image,"image")
      const images = {
        url: image?.url,
        name: data[0]?.name,
        size: formatSizeUnits(data[0]?.size ?? 0),
      };
      if (
        Array?.isArray(props?.selectedImageURL)
        // images?.url[".type"] === ".pdf"
      ) {
        props?.handleChange([...props?.selectedImageURL, images]);
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Pdf Only Allowed",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
    } catch (err) {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Some Thing Went Wrong!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
    // } else {
    //   alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "Pdf Only Allowed",
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center,
    //   });
    // }
  };

  const deleteImg = (e, index) => {
    const result = props?.selectedImageURL?.filter((x, i) => i !== index);
    props?.handleChange(result);
  };
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={2}>
        <Grid
          item
          xs={props?.size?.xs ?? 1.8}
          sm={props?.size?.sm ?? 3}
          md={props?.size?.md ?? 3}
          lg={props?.size?.lg ?? 3}
          // xl={props?.size?.xlg ?? 2}
        >
          <label className="btn label">
            <div className={classes.fileroot}>
              <input
                accept=".pdf"
                type="file"
                name="myImage"
                style={{ display: "none" }}
                onChange={(e) => {
                  updateState(e.target.files);
                }}
                multiple
              />
              <center>
                <ImageUpload />
                <Typography className={classes.uploadTittle}>
                  Upload image here
                </Typography>
              </center>
            </div>
          </label>
        </Grid>
        <Grid item xs={props?.size1?.xs ?? 10.2} style={{ display: "flex" }}>
          {props?.selectedImageURL?.map((val, index) => {
            return (
              <>
                <Box className={classes.imgContainer}>
                  <ImageCard
                    image={val?.url ?? ""}
                    onClick={() => deleteImg(val, index)}
                    imageName={val?.name}
                    storage={val?.size}
                    type="pdf"
                    is_pdf
                  />
                </Box>
              </>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};
