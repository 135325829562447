import { Avatar, Grid } from "@mui/material"
import React from "react"
import { withNamespaces } from "react-i18next"
import { CircleIcon, GatepassIcon, InspectionIcon, KeyIcon } from "../../../assets"
import { DetailsCard } from "../../../components"
import { Cards } from "../../../components/detailsCard/card"
import { convertTimeUtcToZoneCalander } from '../../../utils'

const InspectionRequestCard = (props) => {
    const { t } = props;

    // const [data, setData] = React.useState({
    //     inspector: []
    // });


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                {/* <DetailsCard
                    title={t("inspectionDetail")}
                    data={props?.details?.inspection_detail}
                    icon={<InspectionIcon />}
                /> */}
                <Cards
                    title={t("inspectionDetail")}
                    subTitle={props?.details?.inspection_detail?.reference_id}
                    subTitle1={`Requested on ${convertTimeUtcToZoneCalander(props?.details?.inspection_detail?.created_at)}, by ${props?.details?.inspection_detail?.first_name}`}
                    icon={<InspectionIcon />}
                />
            </Grid>
            {
                props?.details?.responsible_person_detail?.name &&
                <Grid item xs={12} md={3}>
                   
                    {/* <Cards
                        title={t("responsiblePersonDetail")}
                        subTitle={props?.details?.responsible_person_detail?.name}
                        subTitle1={`${props?.details?.responsible_person_detail?.mobile_no_country_code} ${props?.details?.responsible_person_detail?.mobile_no}`}
                        icon={<Avatar sx={{ height: 40, width: 40, marginBottom: "6px" }} variant="rounded" src={props?.details?.responsible_person_detail?.image_url} />}
                    /> */}
                    <Cards
                        title={t("propertyDetail")}
                        subTitle={props?.details?.inspection?.property_name}
                        subTitle1={`${props?.details?.inspection?.unit_no ?? ""} , ${props?.details?.inspection?.unit_name ?? ""}`}
                        icon={<Avatar sx={{ height: 40, width: 40, marginBottom: "6px" }} variant="rounded" src={props?.details?.inspection?.property_logo} />}
                    />
                </Grid>
            }

            {
                props?.details?.resource_details?.length > 0 &&
                <Grid item xs={12} md={3}>
                    <DetailsCard
                        reassign={props?.inspectionStatus !== "Completed"}
                        title={t("resourceDetails")}
                        data={props?.details?.resource_details?.length > 0 ? props?.details?.resource_details?.[0] : []}
                        AllData={props?.details?.resource_details?.length > 1 ? props?.details?.resource_details : []}
                        viewAll={props?.details?.resource_details?.length > 0 ? true : false}
                        icon={<InspectionIcon />}
                        handleStatusClick={props?.handleStatusClick}
                        onClick={() => props?.setAssignModal(true)}
                    />
                </Grid>
            }

            {
                props?.inspectionStatus === "Completed" ?

                    <Grid item xs={12} md={3}>
                        <Cards
                            title={t("completion_date")}
                            subTitle={`Start Date & End Date`}
                            subTitle1={`${convertTimeUtcToZoneCalander(props?.details?.track?.start, 'dd-MMM-yy hh:mm aaaa')} to ${convertTimeUtcToZoneCalander(props?.details?.track?.end, 'dd-MMM-yy hh:mm aaaa')}`}
                            icon={<InspectionIcon />}
                        />
                    </Grid> :
                    props?.inspectionStatus !== "Yet to assign" &&
                    props?.details?.inspection?.execution &&
                    <Grid item xs={12} md={3}>
                        <Cards
                            title={t("assigned_date")}
                            subTitle={`Start Date `}
                            subTitle1={`${convertTimeUtcToZoneCalander(props?.details?.inspection_detail?.created_at, 'dd-MMM-yy hh:mm aaaa')} `}
                            icon={<InspectionIcon />}
                        />
                    </Grid>

            }


            {
                props?.details?.track_update?.length > 0 &&
                <Grid item xs={12} md={3}>
                    <DetailsCard
                        title={t("trackUpdate")}
                        data={props?.details?.track_update?.length > 0 ? props?.details?.track_update?.[0] : []}
                        AllData={props?.details?.track_update?.length > 1 ? props?.details?.track_update : []}
                        viewAll={props?.details?.track_update?.length > 0 ? true : false}
                        icon={<CircleIcon />}
                    />
                </Grid>
            }
            {props?.details?.security_request?.length > 0 &&
                <Grid item xs={12} md={6}>
                    <DetailsCard
                        title={"Gate Pass"}
                        data={{ "created_at": props?.details?.security_request[0]?.created_at }}
                        onButtonClick={props?.onButtonClick}
                        icon={<GatepassIcon />}
                        buttonName="Send Link"
                    />
                </Grid>
            }
            {props?.inspectionStatus === "Completed" &&
                <Grid item xs={12} md={6}>
                    <DetailsCard
                        title={"Tenant Login"}
                        is_active={props?.details?.user?.is_active}
                        icon={<KeyIcon />}
                        buttonName={props?.details?.user?.is_active ? "Active" : "Send Link"}
                        onButtonClick={props?.onButtonClick}
                    />
                </Grid>
            }
        </Grid>

    )
}
export default withNamespaces("inspection")(InspectionRequestCard)