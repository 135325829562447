import { Grid, Typography } from "@mui/material";
import React from 'react';
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import { unitStyles } from "../style";
import ReactQuill from "react-quill";
import { UtilityList } from "./utilityList/utilityList";


export const UnitDetails = (props) => {
    const classes = unitStyles()
    const size = useWindowDimensions()
    const modules = {
        toolbar: false,
    };
    return (
        <div style={{ height: size?.height - (64 + 49), overflow: "auto", padding: "24px" }}>
            <Grid container className={classes.card}>
                <Grid item md={2} lg={1.5} className={classes.imgDiv}>
                    <img src={(props?.data?.logo && props?.data?.logo !== "") ? props?.data?.logo : "/images/imagesproperty.svg"} alt="" className={classes.unitImg} />
                    <Typography className={classes.imgTag}>&nbsp;&nbsp;{props?.data?.unit_no}&nbsp;&nbsp;</Typography>
                </Grid>
                <Grid item md={10} lg={10.5} className={classes.imgDiv2}>
                    <Typography className={classes.title} marginBottom={"14px"}>UNIT DETAILS</Typography>
                    <Grid container>
                        <Grid item md={12} lg={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography className={classes.heading}>Company Name</Typography>
                                    <Typography className={classes.sub}>{props?.data?.property?.company?.name}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography className={classes.heading}>Unit Name</Typography>
                                    <Typography className={classes.sub}>{props?.data?.name}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography className={classes.heading}>Status</Typography>
                                    <Typography className={classes.sub}>{props?.data?.is_active ? "Active" : "In active"}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography className={classes.heading}>Unit Description</Typography>
                                    {props?.data?.description &&
                                        <ReactQuill
                                            readOnly
                                            theme="bubble"
                                            value={props?.data?.description ?? ""}
                                            modules={modules}
                                            className={classes.sub}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <UtilityList
                permission={props?.permission}
                companyId={props?.data?.property?.company?.value}
                currency={props?.data?.property?.company?.currencyByID}
                unitID={props?.unitID} />
        </div>
    )
}