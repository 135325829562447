import { Avatar, Icon, Stack, Typography } from '@mui/material'
import React from 'react'
import { UnitListCardStyle } from './style'
import CloseIcon from '@mui/icons-material/Close';
import EditIconSvg from '../../../assets/editIcon';

export const UnitListCard = ({ data = {}, click = () => false, selected = {}, removeUnit = () => false, close = "", edit = "" }) => {
    const classes = UnitListCardStyle()
    return (
        <Stack p={edit ? 0 : 1} direction={"row"} justifyContent={"space-between"} alignItems={"center"} onClick={() => !close && click(data)}
            sx={{ cursor: "pointer" }}>
            <Stack direction={"row"} spacing={1}>
                <Avatar className={classes.avatar} src={data?.unit_logo}></Avatar>
                <Stack>
                    <Typography className={classes.title}> {data?.unit_name} </Typography>
                    <Typography className={classes.content}>{data?.property_name}, {data?.amenity_user}</Typography>
                </Stack>
            </Stack>
            {selected?.agreement_unit_id === data?.agreement_unit_id && <Icon color="primary">check_circle</Icon>}
            {close && <CloseIcon sx={{ color: "red", cursor: "pointer" }} onClick={removeUnit} ></CloseIcon>}
            {edit && <EditIconSvg />}
        </Stack>
    )
}