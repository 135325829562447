import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomPaper, TitleDesc, } from '../components';
import useTheme from '@mui/material/styles/useTheme';
// import MyGoogleMap from '../../../components/interactivemap/myGoogleMaps';
import { returnValue } from '../utils';



export const TermsAndConditionView = (props) => {

    const theme = useTheme();

    const {
        termsAndConditionDescription = null
    } = props?.data ?? {};

    return (
        <div>
            <CustomPaper height={'100%'} marginBottom={theme.spacing(3)}>
                <Grid container spacing={2}>

                    <Grid item xs={6} md={12}>
                        <TitleDesc
                            title={"Terms and Conditions"}
                            dangerouslySetInnerHTML={returnValue(termsAndConditionDescription, "-")}
                        />
                    </Grid>
                </Grid>
            </CustomPaper>

        </div >
    )
}