import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import moment from 'moment';
import { MonthlyCalendarStyles } from './style';


export const MonthlyCalendar = ({ dates = [], month = {}, events = [], handleEvent = () => false }) => {
    const classes = MonthlyCalendarStyles()
    const days = [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
    ]
    return (
        <Box>
            <Grid container>
                <Grid item sm={12} md={12} lg={12}>
                    <Grid container >
                        {
                            days.map(e => {
                                return (
                                    <Grid item xs={1.7} textAlign={"center"} p={1}>
                                        <span className={classes.days}> {e} </span>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid container mt={0} className={classes.calDates}>
                        <Grid item sm={12} md={12} lg={12}>
                            <Grid container>
                                {
                                    dates.map(e => {
                                        return (
                                            <Grid item xs={1.7} className={classes.date_outline}>
                                                <Stack mt={1} justifyContent={"space-between"} height="100%" pb={2}>
                                                    <Box className={classes.date}><span className={moment(e).format("M") === moment(month).format("M")
                                                        ? " " : classes.hidden_other_month_dates}>{moment(e).format("DD")}</span></Box>
                                                    <Box sx={{ textAlign: "right" }}>
                                                        <Box m={1} sx={{ cursor: "pointer" }}>
                                                            {
                                                                events?.map((evnt) => {
                                                                    return (
                                                                        evnt?.event?.length === 1 &&
                                                                            moment(e).format("DD") === moment(evnt.date).format("DD")
                                                                            && moment(evnt.date).format("MM") === moment(e).format("MM") ?
                                                                            evnt.event.map(val => {
                                                                                return (
                                                                                    <Grid container spacing={0} className={classes.eventlabel}
                                                                                        onClick={() => handleEvent(e)}>
                                                                                        <Grid item xs={9.7} pl={0.5}>
                                                                                            <Typography className={classes.eventtxt}> {val.title} </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )
                                                                            })
                                                                            : ""
                                                                    )
                                                                })
                                                            }
                                                            {

                                                                <Box>
                                                                    {events?.filter(i => i.date === moment(e).format("YYYY-MM-DD")).map(evnt => {
                                                                        return (
                                                                            evnt?.event?.length > 1 &&
                                                                                moment(e).format("DD") === moment(evnt.date).format("DD")
                                                                                && moment(evnt.date).format("MM") === moment(e).format("MM") ?
                                                                                <div onClick={() => handleEvent(e)}>
                                                                                    <Grid container spacing={0} sx={{ backgroundColor: `${evnt?.event[0].background_color}` }} className={classes.eventlabel}>
                                                                                        <Grid item xs={9.7} pl={0.5}>
                                                                                            <Typography className={classes.eventtxt}> {evnt?.event[0]?.title} </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Typography className={classes.moreLabel}> +{evnt?.event?.length - 1} more </Typography>
                                                                                </div>
                                                                                : ""
                                                                        )
                                                                    })}

                                                                </Box>
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Stack>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}