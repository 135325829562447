import { useApolloClient } from "@apollo/client";
import { Box, Grid } from "@mui/material";
import React from 'react';
import { DialogWithTable } from "../../../components/dialogWithTable";
import { GET_OTHER_INFO_UNIT_COUNT, GET_OWNERS_BY_UNIT, GET_RESIDENT_BY_UNIT, GET_ROOMS_BEDSPACE, GET_UNIT_AGREEMENT, GET_UNIT_AMENITIES_BREAKUP, GET_UNIT_INVOICE, GET_UNIT_RENT_BREAKUP, GET_UNIT_REQUSET, UNIT_VACANCY_DETAILS } from '../../../graphql/queries';
import { LocalStorageKeys } from "../../../utils";

export const OtherInfo = (props) => {
    const unitId = props?.unitID
    const client = useApolloClient();
    const [count, setCount] = React.useState({
        owners: 0,
        resident: 0,
        breakup: 0,
        vacancy: 0,
        amenities: 0,
        rooms: 0,
        requset: 0,
        agreement: 0,
        invoice: 0
    })
    React.useEffect(() => {
        client.query({
            query: GET_OTHER_INFO_UNIT_COUNT,
            fetchPolicy: 'network-only',
            variables: {
                unitID: props?.unitID,
                client: localStorage.getItem(LocalStorageKeys.clinetID)
            }
        }).then((res) => {

            setCount({
                owners: res.data?.totalOwners?.[0]?.count_owner_profile_id,
                resident: res.data?.totalResident?.[0]?.count_tenant_profile_id,
                breakup: res.data?.pricing_table?.[0]?.count_id,
                vacancy: res.data?.unit_vacancy_period?.[0]?.count_id,
                amenities: res.data?.unit_amenities_breakup?.[0]?.count_id,
                rooms: res.data?.unit?.[0]?.count_id,
                requset: res.data?.general_request_units?.[0]?.count_id + res.data?.maintenance_request_units?.[0]?.count_id,
                agreement: res.data?.agreement_units?.[0]?.count_id,
                invoice: res.data?.invoice_units?.[0]?.count_id
            })
        })
        // eslint-disable-next-line
    }, [])
    const tableData = [
        {
            id: 8,
            title: "Owners & Landlords",
            color: "#E29336",
            count: count?.owners,
            table: {
                heading: [
                    { title: "Owner Name ", field: 'ownerName', },
                    { title: "Owner Phone Number", field: 'ownerphoneno', },
                    { title: "from", field: 'from', },
                    { title: "to", field: 'to', },


                ],
                path: ["ownerName", "rownerphoneno", "from", "to"],
                dataType: [
                    { type: ["text"], name: "ownerName" },
                    { type: ["text"], name: "rownerphoneno" },
                    { type: ["text"], name: "from" },
                    { type: ["text"], name: "to" },

                ],
            },
            query: GET_OWNERS_BY_UNIT,
            unitId: unitId
        },
        {
            id: 5,
            title: "Resident & Tenants",
            color: "#E53468",
            count: count?.resident,
            table: {
                heading: [
                    { title: "Resident Name ", field: 'residentName', },
                    { title: "Resident Phone Number", field: 'residentphoneno', },
                    { title: "from", field: 'from', },
                    { title: "to", field: 'to', },
                ],
                path: ["residentName", "residentphoneno", "from", "to"],
                dataType: [
                    { type: ["text"], name: "residentName" },
                    { type: ["text"], name: "residentphoneno" },
                    { type: ["text"], name: "from" },
                    { type: ["text"], name: "to" },
                ],
            },
            query: GET_RESIDENT_BY_UNIT,
            unitId: unitId
        },
        {
            id: 0,
            title: "Pricing Table",
            count: count?.breakup,
            table: {
                heading: [
                    { title: "Revenue Type", field: 'revenue', },
                    { title: "Component", field: 'component', },
                    { title: "Pricing Value", field: 'value', },
                    { title: "Pricing Currency", field: "currency", },
                    { title: "isChargable", field: 'chargeable', },

                    { title: "Taxable", field: 'display', },
                    { title: "TaxGroup", field: 'taxGroup', },

                ],
                path: ["revenue", "component", "value", "currency", "chargeable", "display%", "taxGroup"],
                dataType: [
                    { type: ["text"], name: "revenue" },
                    { type: ["text"], name: "component" },
                    { type: ["text"], name: "value" },
                    { type: ["text"], name: "currency" },
                    { type: ["text"], name: "chargeable" },
                    { type: ["text"], name: "display" },
                    { type: ["text"], name: "taxGroup" },
                ],
            },
            query: GET_UNIT_RENT_BREAKUP,
            unitId: unitId,
            color: "#5078E1"
        },
        {
            id: 1,
            title: "Occupancy View",
            count: count?.vacancy,
            table: {
                heading: [

                    { title: "Start Date", field: "startDate", },
                    { title: "End Date", field: "endDate", },
                    { title: "Occupancy Status", field: "currentStatus", },
                    { title: "Agreement Number", field: "agreementNumber", },
                    { title: "Company Name", field: "companyName", },
                    { title: " ", field: "image", },
                    { title: "Primary Contact Name", field: "primaryContactName", },
                    { title: "Primary Contact Number", field: "primaryContactNumber", },
                    { title: "Reservation Number", field: "reservationNumber", },

                ],
                path: ["startDate", "endDate", "currentStatus", "agreementNumber", "image", "companyName", "primaryContactName", "primaryContactNumber", "reservationNumber"],
                dataType: [

                    { type: ["date"], name: "startDate" },
                    { type: ["date"], name: "endDate" },
                    { type: ["status"], name: "currentStatus" },
                    { type: ["text"], name: "agreementNumber" },
                    { type: ["text"], name: "companyName" },
                    { type: ["avatarmanagement"], name: "image" },
                    { type: ["text"], name: "primaryContactName" },
                    { type: ["text"], name: "primaryContactNumber" },
                    { type: ["text"], name: "reservationNumber" },
                ],
            },
            query: UNIT_VACANCY_DETAILS().loc.source.body,
            unitId: unitId,
            color: "#E29336"
        },
        {
            id: 2,
            title: "Amenities",
            count: count?.amenities,
            table: {
                heading: [
                    { title: "Amenities Name", field: 'name', },
                    { title: "Chargeable", field: 'chargable', },

                ],
                path: ["name", "chargable"],
                dataType: [

                    { type: ["text"], name: "name" },
                    { type: ["text"], name: "chargable" },
                ],
            },
            query: GET_UNIT_AMENITIES_BREAKUP,
            unitId: unitId,
            color: "#339E89"
        },
        {
            id: 4,
            title: "Rooms & Bed Space",
            color: "#8887D4",
            count: count?.rooms,
            table: {
                heading: [
                    { title: "Room Name ", field: 'roomName', },
                    { title: "Description", field: 'description', },
                    { title: "Room Number", field: 'roomno', },
                    { title: "Room Status", field: 'roomSttaus', },
                ],
                path: ["roomName", "description", "roomno", "roomSttaus"],
                dataType: [
                    { type: ["text"], name: "roomName" },
                    { type: ["text"], name: "description" },
                    { type: ["text"], name: "roomno" },
                    { type: ["status"], name: "roomSttaus" },
                ],
            },
            query: GET_ROOMS_BEDSPACE,
            unitId: unitId
        },
        // {
        //     id: 3,
        //     title: "Unit Utilities",
        //     color: "#30AFCE",
        //     table: {
        //         heading: [
        //             { title: "S.no", field: "sno", },
        //             { title: "Utilities", field: 'utilites', },
        //             { title: "Meter Serial No", field: 'meterSerialNo', },
        //             { title: "Meter Point Reference", field: 'meterPointReference', },
        //             { title: "Updated Date", field: 'updatedDate', },
        //             { title: "Description", field: 'description', },

        //         ],
        //         path: ["sno", "utilites", "meterSerialNo", "meterPointReference", "updatedDate", "description"],
        //         dataType: [
        //             { type: ["increment"], name: "sno" },
        //             { type: ["text"], name: "utilites" },
        //             { type: ["text"], name: "meterSerialNo" },
        //             { type: ["text"], name: "meterPointReference" },
        //             { type: ["text"], name: "updatedDate" },
        //             { type: ["text"], name: "description" },
        //         ],
        //     },
        //     query: GET_UNIT_UTILITIES,
        //     unitId: unitId
        // },
        {
            id: 6,
            title: "Requests",
            color: "#30AFCE",
            count: count?.requset,
            table: {
                heading: [

                    { title: "Request Id ", field: 'requestId', },
                    { title: "Request Name", field: 'requestName', },
                    { title: "Raised Date", field: 'raisedDate', },
                    { title: "Category", field: 'category', },
                    { title: "Status", field: 'status', },

                ],
                path: ["requestId", "requestName", "raisedDate", "category", "status"],
                dataType: [

                    { type: ["text"], name: "requestId" },
                    { type: ["text"], name: "requestName" },
                    { type: ["text"], name: "raisedDate" },
                    { type: ["text"], name: "category" },
                    { type: ["text"], name: "status" },

                ],
            },
            query: GET_UNIT_REQUSET,
            unitId: unitId
        },
        {
            id: 9,
            title: "Agreements",
            color: "#8887D4",
            count: count?.agreement,
            table: {
                heading: [

                    { title: "Agreement Number ", field: 'agreementNo', },
                    { title: "Valid From", field: 'validfrom', },
                    { title: "Valid To", field: 'validto', },
                    { title: "Agreement User", field: 'agreementuser', },



                ],
                path: ["agreementNo", "validfrom", "validto", "agreementuser",],
                dataType: [

                    { type: ["text"], name: "agreementNo" },
                    { type: ["date"], name: "validfrom" },
                    { type: ["date"], name: "validto" },
                    { type: ["text"], name: "agreementuser" },


                ],

            },
            query: GET_UNIT_AGREEMENT,
            unitId: unitId
        },
        {
            id: 10,
            title: "Invoices",
            count: count?.invoice,
            table: {
                heading: [

                    { title: "Invoice Number ", field: 'invoiceNo', },
                    { title: "Invoice Raised On", field: 'raiseon', },
                    { title: "Invoice Due On", field: 'raisedueon', },
                    { title: "Invoice payment status", field: 'status', },
                    { title: "Invoice pending amount", field: 'amount', },

                ],
                path: ["invoiceNo", "raiseon", "raisedueon", "status", "amount",],
                dataType: [

                    { type: ["text"], name: "invoiceNo" },
                    { type: ["date"], name: "raiseon" },
                    { type: ["date"], name: "raisedueon" },
                    { type: ["text"], name: "status" },
                    { type: ["text"], name: "amount" },

                ],

            },
            query: GET_UNIT_INVOICE,
            unitId: unitId
        },
    ]

    return (

        <div>
            <Box height="8px" />
            <Grid container spacing={2}>
                {tableData.map((item) => (
                    <>
                        <Grid item xs={2}>
                            <Box p={0.5}>
                                <DialogWithTable data={item} />
                            </Box>
                        </Grid>
                    </>

                )
                )}
            </Grid>

        </div>


    )
}