import React from "react";
import { PricingApprialsComponent } from "../../../components";
import { Typography, Box } from "@mui/material";
import { againstCompanyStyle } from "../style";
import {timeZoneConverter} from "../../../utils"

export const PricingView = (props) => {
  //   classes
  const classes = againstCompanyStyle(props);
  //   props
  const { state, property, unit_no } = props;

  return (
    <div>
      {/* COMPONENT DETAILS */}
      <Typography className={classes.valueTitle}>COMPONENT DETAILS</Typography>
      <Box height={"12px"} />
      <PricingApprialsComponent
        component="ViewCard"
        data={{
          title: state?.pricing_component_name ?? "",
          amount: [
            {
              total:
                property?.length > 0
                  ? state?.amountType?.value === "Range"
                    ? `${state?.present_range_value?.start} - ${state?.present_range_value?.end}` ??
                      ""
                    : state?.present_specific_value ?? ""
                  : unit_no ?? 0,
              units: property?.length > 0 ? "Present Amount" : "Units",
            },
          ],
          subCatagory: [
            {
              title: state?.component_name ?? "",
            },
            {
              title: state?.component_revenue_type ?? "",
            },
            {
              title: state?.component_based ?? "",
            },
          ],
        }}
        backgroundColor={"#FEEAEA80"}
        color={"#B3776D"}
      />

      <Box height={"20px"} />
      {/* APPRAISAL VALUE DETAILS */}
      <Typography className={classes.valueTitle}>
        APPRAISAL VALUE DETAILS
      </Typography>
      <Box height={"12px"} />
      <PricingApprialsComponent
        component="ViewCard"
        data={{
          title:
            state?.appraisal_type === "Specific"
              ? "Specific Amount Value"
              : "Amount Value Range",
          subCatagory: [
            {
              title: `${timeZoneConverter(state?.effective_start_date)
                }`,
            },
            {
              title: "UMO / Month",
            },
          ],
          amount: [
            {
              total:
                !property?.length > 0
                  ? state?.amountType?.value === "Range"
                    ? `${state?.present_range_value?.start ?? 0} - ${
                        state?.present_range_value?.end ?? 0
                      }`
                    : state?.present_specific_value ?? 0
                  : "",
              units: !property?.length > 0 ? "Present Amount" : "",
            },
            {
              total: state?.new_appraisal_amount ?? "-",
              units: "Appraisal Amount",
            },
          ],
        }}
        backgroundColor={"#DBF0F180"}
        color={"#6DAFB3"}
      />

      {property?.length > 0 && (
        <>
          <Box height={"20px"} />
          {/* PROPERTY & IMPACT DETAILS */}
          <Typography className={classes.valueTitle}>
            PROPERTY & IMPACT DETAILS
          </Typography>
          <Box height={"12px"} />
          <PricingApprialsComponent
            component="ViewCardMuti"
            subTitle="Units"
            data={property ?? []}
            backgroundColor={"#E4EDFF80"}
            color={"#6D80B3"}
          />
        </>
      )}
    </div>
  );
};
