import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material'
import React from 'react'
import { AlertDialog } from '../../../components'
import { ScheduleCardStyle } from './style'
import Table from "./tableView"

export const ScheduleCard = ({ datas = {}, background_color = "", color = "", card_color = "", companyId = "", propertyId = "", resourceType = "", scheduleData = "", type = "", count = "" }) => {
    const classes = ScheduleCardStyle()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isView, setIsView] = React.useState(false)
    const open = Boolean(anchorEl);
    const [requestType,setRequestType]=React.useState("")
    //handle clik
    const handleClick = (event,reqtype) => {
        setAnchorEl(event.currentTarget);
        setRequestType(reqtype)
    };
    //handle close menu
    const handleClose = (type) => {
        setAnchorEl(null);
    };
    return (
        <>
            {datas.resources?.hasOwnProperty("move-in") &&
                <Stack  alignItems="center" className={classes.card} sx={{ backgroundColor: "#FEEAEA80" }} direction={"row"} spacing={2}
                    onMouseOver={(event)=>handleClick(event,"move-in")}
                >
                    <Box className={classes.avatar} sx={{ backgroundColor: "#EFD3D3" }}></Box>
                    <Typography className={classes.status} sx={{ color: "#B3776D" }}> {"Move In"}  {datas?.resources?.["move-in"]?.length > 1 ? `+ ${datas?.resources?.["move-in"]?.length-1}` : ""}</Typography>
                    
                </Stack>
            }
            {datas.resources?.hasOwnProperty("move-out") &&
                <Stack  alignItems="center" className={classes.card} sx={{ backgroundColor: "#DBF0F180" }} direction={"row"} spacing={2}
                    onMouseOver={(event)=>handleClick(event,"move-out")}
                >
                    <Box className={classes.avatar} sx={{ backgroundColor: "#C7EEF0" }}></Box>
                    <Typography className={classes.status} sx={{ color: "#6DAFB3" }}> {"Move Out"} {datas?.resources?.["move-out"]?.length > 1 ? `+ ${datas?.resources?.["move-out"]?.length-1}` : ""}</Typography>
                </Stack>
            }
              {datas.resources?.hasOwnProperty("service") &&
                <Stack className={classes.card} sx={{ backgroundColor: "#FFFAD880" }} direction={"row"} spacing={2}
                    onMouseOver={(event)=>handleClick(event,"service")}
                >
                    <Box className={classes.avatar} sx={{ backgroundColor: "#F1EDCE" }}></Box>
                    <Typography className={classes.status} sx={{ color: "#B3A16D" }}> {"Service"} {datas?.resources?.["service"]?.length > 1 ? `+ ${datas?.resources?.["service"]?.length-1}` : ""}</Typography>
                </Stack>
            }
            <Menu
                className={classes.menuList}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                MenuListProps={{ onMouseLeave: handleClose }}
                PaperProps={{backgroundColor:"#FFFFFF"}}
            >
                {requestType==="move-in" &&
                    <MenuItem className={classes.menuItem}>
                        <Box>
                            <Stack direction="row" spacing={2} >
                                <Box className={classes.avatar} sx={{ backgroundColor: "#EFD3D3" }}></Box>
                                <Box>
                                    <Typography className={classes.countNo}> {datas?.resources?.["move-in"]?.length} {datas?.resources?.["move-in"]?.length > 1 ? "Requests" : "Request"}</Typography>
                                    <Typography className={classes.status} sx={{ color: "#B3776D" }}> MoveIn </Typography>
                                </Box>
                            </Stack>
                            <Typography className={classes.viewAll} onClick={() => { return (setIsView(true), setAnchorEl(null)) }} >View All</Typography>
                        </Box>
                    </MenuItem>
                }
                {requestType==="move-out"  &&
                    <MenuItem className={classes.menuItem}>
                        <Box>
                            <Stack direction="row" spacing={2} >
                                <Box className={classes.avatar} sx={{  backgroundColor: "#C7EEF0" }}></Box>
                                <Box>
                                    <Typography className={classes.countNo}>{datas?.resources?.["move-out"]?.length} {datas?.resources?.["move-out"]?.length > 1 ? "Requests" : "Request"}</Typography>
                                    <Typography className={classes.status} sx={{ color: "#6DAFB3" }}> MoveOut </Typography>
                                </Box>
                            </Stack>
                            <Typography className={classes.viewAll} onClick={() => { return (setIsView(true), setAnchorEl(null)) }} >View All</Typography>
                        </Box>
                    </MenuItem>
                }
                  {requestType==="service"  &&
                    <MenuItem className={classes.menuItem}>
                        <Box>
                            <Stack direction="row" spacing={2} >
                                <Box className={classes.avatar} sx={{  backgroundColor: "#F1EDCE" }}></Box>
                                <Box>
                                    <Typography className={classes.countNo}>{datas?.resources?.["service"]?.length} {datas?.resources?.["service"]?.length > 1 ? "Requests" : "Request"}</Typography>
                                    <Typography className={classes.status} sx={{ color: "#B3A16D" }}> Service </Typography>
                                </Box>
                            </Stack>
                            <Typography className={classes.viewAll} onClick={() => { return (setIsView(true), setAnchorEl(null)) }} >View All</Typography>
                        </Box>
                    </MenuItem>
                }
                 {/* {requestType==="site_visit"  &&
                    <MenuItem className={classes.menuItem}>
                        <Box>
                            <Stack direction="row" spacing={2} >
                                <Box className={classes.avatar} sx={{  backgroundColor: "#F1EDCE" }}></Box>
                                <Box>
                                    <Typography className={classes.countNo}>{datas?.resources?.["service"]?.length} {datas?.resources?.["service"]?.length > 1 ? "Requests" : "Request"}</Typography>
                                    <Typography className={classes.status} sx={{ color: "#B3A16D" }}> Site Visit </Typography>
                                </Box>
                            </Stack>
                            <Typography className={classes.viewAll} onClick={() => { return (setIsView(true), setAnchorEl(null)) }} >View All</Typography>
                        </Box>
                    </MenuItem>
                } */}
            </Menu>

            <AlertDialog open={isView}
                onClose={() => setIsView(false)}
                header={requestType==="move-in"? "Move In" : requestType === "move-out" ? "Move Out" : requestType === "service" ? "Service":"site_visit"}
                component={<Table data={datas} type={requestType} companyId={companyId} propertyId={propertyId} resourceType={resourceType} />}

            />
        </>
    )
}