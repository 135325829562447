import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { AlertDialog, LoadingSection, TableWithPagination } from "../../../components";
import { GET_COMPANY_MASTER } from "../../../graphql/clientSettingsQueries";
import { LocalStorageKeys } from "../../../utils";
import { logodataType1, logoheading1, logopath1 } from "../../../utils/clientsettings";
import { SettingSave } from "../../companyCreation/components";
import { LogoUploadForm } from "../components";
export const LogoApperance = ({ selected = {} }) => {

    const [open, setOpen] = React.useState(false)
    const client = useApolloClient();
    const [loading, setLoading] = React.useState(true)
    const [limits, setLimit] = React.useState(10);
    const [selectData, setSelectData] = React.useState({})
    const [page, setPage] = React.useState(1);
    const [logoList, setLogoList] = React.useState({
        list: [],
        count: 0
    })
    //get Bannerlist
    const getBannerlist = (limit, offset) => {
        setLoading(true)
        client.query({
            query: GET_COMPANY_MASTER,
            fetchPolicy: 'network-only',
            variables: {
                limit: limit,
                offset: offset,
                client: localStorage.getItem(LocalStorageKeys.clinetID),
                search:""
            },
        }).then((response) => {
            setLogoList({
                list: response.data.company_master,
                count: response.data.count[0]?.count
            })
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limits;
        getBannerlist(limits, offset)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getBannerlist(value, 0)
    }
    React.useEffect(() => {
        getBannerlist(limits, 0)
        // eslint-disable-next-line
    }, [])
    //onclick row
    const onClickRow = (data) => {
        setOpen(true)
        setSelectData(data)
    }
    return (
        <Box>
            {/*setting save */}
            <SettingSave
                btnName="Create Logo & Appearance"
                title={selected?.label} subTitle={selected?.subtitle} />
            {/*table */}
            {
                loading ? <LoadingSection bottom={"45vh"} message="Fetching ..." /> :
                    <Box padding="8px 12px">
                        <TableWithPagination
                            heading={logoheading1}
                            rows={logoList?.list}
                            path={logopath1}
                            showpagination={true}
                            onClick={onClickRow}
                            dataType={logodataType1}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={logoList?.count}
                            page={page}
                            limit={limits}
                            height={'calc(100vh - 351px)'}
                            view={true}
                            edit={true}
                            delete={true} />
                    </Box>
            }
            {/*upload dialog*/}
            <AlertDialog
                medium
                header={"Logo & Appearance"}
                onClose={() => setOpen(false)}
                open={open}
                component={
                    <>
                        <LogoUploadForm
                            reload={getBannerlist}
                            company={selectData}
                            onClose={() => setOpen(false)}
                        />
                    </>
                }
            />
        </Box>
    )
}
