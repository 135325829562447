import * as React from "react"

const GreenCalendarSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} {...props}>
    <path
      d="M15 3.333v-.625A2.711 2.711 0 0 0 12.292 0H2.708A2.711 2.711 0 0 0 0 2.708v.625ZM0 4.583v7.708A2.711 2.711 0 0 0 2.708 15h9.583A2.711 2.711 0 0 0 15 12.292V4.583ZM3.958 12.5A1.042 1.042 0 1 1 5 11.458 1.042 1.042 0 0 1 3.958 12.5Zm0-3.75A1.042 1.042 0 1 1 5 7.708 1.042 1.042 0 0 1 3.958 8.75ZM7.5 12.5a1.042 1.042 0 1 1 1.042-1.042A1.042 1.042 0 0 1 7.5 12.5Zm0-3.75a1.042 1.042 0 1 1 1.042-1.042A1.042 1.042 0 0 1 7.5 8.75Zm3.542 0a1.042 1.042 0 1 1 1.042-1.042 1.042 1.042 0 0 1-1.042 1.042Z"
      fill="#5ac782"
    />
  </svg>
)

export default GreenCalendarSvg