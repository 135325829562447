import React from 'react';
import { Editor, NewDatePicker, SelectBox, Subheader, TextBox } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { accessCheckRender, AlertProps, constructPropertyList, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from '../../utils';
import { correspondencesStyles } from "./style";
import { withNamespaces } from 'react-i18next';
import { config } from '../../config';
import { BackendRoutes } from '../../router/routes';
import { NetworkCall } from '../../networkcall';
import { useApolloClient } from '@apollo/client';
import { GET_CONTACT_BY_COMPANY_ID, GET_CORRESPONDENCE_BY_AGREEMENT_UNIT, GET_CORRESPONDENCE_BY_CONTACT_ID, GET_CORRESPONDENCE_OPTIONS } from './utils/query';
import { useHistory, useLocation } from 'react-router';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { initial_data, PreviewTabData } from './utils/utils';
import PrioritySelect from '../../components/prioritySelect/prioritySelect';

const CreateEditCorrespondences = (props) => {
    const { t, loading, handleLoading } = props

    const classes = correspondencesStyles()
    const client = useApolloClient()
    const history = useHistory()
    const { state } = useLocation()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)

    // useState
    const [data, setData] = React.useState({
        ...initial_data,
        id: state?.id,
        title: state?.title,
        level: state?.level,
    })
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [selectedPreviewTab, setSelectedPreviewTab] = React.useState(PreviewTabData[0])
    const [options, setOptions] = React.useState({
        property: [],
        correspondences_type_master: [],
        urgent_type: [],
    })
    const [disableButton, setDisableButton] = React.useState(false)
    const [permission, setPermission] = React.useState({})
    const [optionLoading, setOptionLoading] = React.useState(false)

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // Function to get company list
    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setSelectedCompany(company?.selected)
            getPropertyList(company?.selected)
        }
    }

    // Function to change property list
    const getPropertyList = (company) => {
        let property = constructPropertyList(company)
        getOptions(property?.list)
    }

    // Function to get list based on the input data
    const getList = (correspondence_id = "") => {
        let payload = { correspondence_id }
        NetworkCall(
            `${config.api_url}${BackendRoutes?.correspondences_list}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            let temp_res = res?.data?.data?.list?.[0]
            let temp_data = {
                ...data,
                level: temp_res?.level_id,
                property: "",
                agreement_unit: "",
                contact: "",
                correspondences_type: temp_res?.type_id ? { value: temp_res?.type_id, label: temp_res?.type_name } : "",
                date: temp_res?.incident_date ? new Date(temp_res?.incident_date) : new Date(),
                acknowledge_before: temp_res?.dead_line_date ? new Date(temp_res?.dead_line_date) : new Date(),
                reference_letter: temp_res?.["previous case_id"] ? { value: temp_res?.previous_case_id, label: temp_res?.previous_case_no } : "",
                subject: temp_res?.subject,
                priority: temp_res?.priority,
                notes: temp_res?.notes,
                error: {
                    property: "",
                    agreement_unit: "",
                    contact: "",
                    correspondences_type: "",
                    date: "",
                    acknowledge_before: "",
                    subject: "",
                    priority: "",
                    notes: "",
                }
            }
            setData(temp_data)
            handleLoading(false)
        }).catch(() => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        });
    }

    //Function to get options for filter
    const getOptions = async (property_list = []) => {
        const enum_result = await enumSelect([enum_types?.urgent_type])
        const master_result = await client.query({
            query: GET_CORRESPONDENCE_OPTIONS,
            fetchPolicy: 'network-only',
        }).catch((err) => console.log(err))
        setOptions({
            property: property_list,
            correspondences_type_master: master_result?.data?.correspondences_type_master,
            urgent_type: enum_result?.urgent_type,
        })
        if (data?.id) {
            getList(data?.id)
        } else {
            handleLoading(false)
        }
    }

    // Function to update addState
    const updateData = (k, v) => {
        let error = data?.error
        error[k] = ""
        setData({ ...data, [k]: v, error })
    }

    const loadOptions = async (search = "", array, type) => {
        setOptionLoading(type)
        let result, payload, offset = 0;
        let limit = 10
        if (search && !Boolean(array?.length)) {
            offset = 0;
        } else {
            offset = array?.length;
        }

        switch (type) {
            case 'agreement_unit':
                payload = { property_id: data?.property?.value, offset, limit }
                result = await NetworkCall(
                    `${config.api_url}${BackendRoutes?.agreement_unit_info_agreement_unit_for_property}`,
                    NetWorkCallMethods.post, payload, null, true, false
                ).catch(() => {
                    alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps.severity.error
                    })
                });
                setOptionLoading(null);
                return {
                    options: [...result?.data?.data?.map((i) => {
                        return {
                            label: i?.unit_name,
                            value: i?.agreement_unit_id,
                        }
                    })],
                    hasMore: (array?.length + result?.data?.data?.length) < result?.data?.total_count
                }
            case 'reference_letter_by_agreement_unit':
                result = await client.query({
                    query: GET_CORRESPONDENCE_BY_AGREEMENT_UNIT,
                    fetchPolicy: 'network-only',
                    variables: { agreementUnitID: data?.agreement_unit?.value, offset, limit }
                }).catch(() => {
                    alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps.severity.error
                    })
                });
                setOptionLoading(null);
                return {
                    options: [...result?.data?.correspondence_units?.map((i) => {
                        return {
                            label: i?.correspondenceByID?.reference_no,
                            value: i?.correspondenceByID?.id,
                        }
                    })],
                    hasMore: (array?.length + result?.data?.correspondence_units?.length) < result?.data?.count?.[0]?.count
                }
            case 'reference_letter_by_contact':
                result = await client.query({
                    query: GET_CORRESPONDENCE_BY_CONTACT_ID,
                    fetchPolicy: 'network-only',
                    variables: { contactID: data?.contact?.value, offset, limit }
                }).catch(() => {
                    alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps.severity.error
                    })
                });
                setOptionLoading(null);
                return {
                    options: [...result?.data?.correspondence_units?.map((i) => {
                        return {
                            label: i?.correspondenceByID?.reference_no,
                            value: i?.correspondenceByID?.id,
                        }
                    })],
                    hasMore: (array?.length + result?.data?.correspondence_units?.length) < result?.data?.count?.[0]?.count
                }
            case 'contact_by_company':
                result = await client.query({
                    query: GET_CONTACT_BY_COMPANY_ID,
                    fetchPolicy: 'network-only',
                    variables: { companyID: selectedCompany?.value, offset, limit }
                }).catch(() => {
                    alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps.severity.error
                    })
                });
                setOptionLoading(null);
                return {
                    options: [...result?.data?.contact?.map((i) => {
                        return {
                            label: i?.first_name,
                            value: i?.id,
                        }
                    })],
                    hasMore: (array?.length + result?.data?.contact?.length) < result?.data?.count?.[0]?.count
                }
            default:
                return { options: [] }
        }
    }

    const validate = () => {
        let isValid = true;
        let error = data.error;
        if (data?.level === 1 && data?.property?.length === 0) {
            isValid = false;
            error.property = t("Property is Required");
        }
        if (data?.level === 1 && data?.agreement_unit?.length === 0) {
            isValid = false;
            error.agreement_unit = t("Unit is Required");
        }
        if (data?.level === 2 && data?.contact?.length === 0) {
            isValid = false;
            error.contact = t("Contact is Required");
        }
        if (data?.correspondences_type?.length === 0) {
            isValid = false;
            error.correspondences_type = t("Correspondence Type is Required");
        }
        if (data?.date?.length === 0) {
            isValid = false;
            error.date = t("Date is Required");
        }
        if (data?.acknowledge_before?.length === 0) {
            isValid = false;
            error.acknowledge_before = t("Acknowledge Before is Required");
        }
        if (data?.subject?.length === 0) {
            isValid = false;
            error.subject = t("Subject is Required");
        }
        if (data?.priority?.length === 0) {
            isValid = false;
            error.priority = t("Priority is Required");
        }
        if (data?.notes?.length === 0) {
            isValid = false;
            error.notes = t("Notes is Required");
        }

        if (!isValid) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            });
        }
        setData({ ...data, error });

        return isValid;
    };

    const handleCreateEditButton = () => {
        if (validate()) {
            setDisableButton(true)
            let payload = {
                subject: data?.subject,
                notes: data?.notes,
                type_id: data?.correspondences_type?.value,
                dead_line_date: data?.acknowledge_before ? new Date(data?.acknowledge_before) : null,
                incident_date: data?.date ? new Date(data?.date) : null,
                priority: data?.priority,
                company_id: selectedCompany?.value,
                status: 1,
                level: data?.level
            }
            if (data?.level === 1) {
                payload["agreement_unit_id"] = data?.agreement_unit?.value
            } else if (data?.level === 2) {
                payload["contact_id"] = data?.contact?.value
            }
            NetworkCall(
                `${config.api_url}${BackendRoutes?.correspondences_create}`,
                NetWorkCallMethods.post, payload, null, true, false
            ).then((res) => {
                alert.setSnack({
                    ...alert, open: true, msg: t("Correspondence created successfully!"),
                    severity: AlertProps.severity.success
                })
                setDisableButton(false)
                setData({ ...initial_data })
                history.goBack(-1)
            }).catch(() => {
                alert.setSnack({
                    ...alert, open: true, msg: t("Some Thing Went Wrong"),
                    severity: AlertProps.severity.error
                })
                setDisableButton(false)
            });
        }
    }

    const render = () => {
        return <>
            <Subheader goBack={() => history.goBack(-1)} isReadOnly select value={selectedCompany}
                title={data?.title ?? t("Create Unit Level Correspondence")} />
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} spacing={"16px"} padding={"12px"}>
                <Stack direction={"row"} className={classes.tab_div}>
                    {PreviewTabData?.map?.((i) => {
                        return <div
                            className={
                                selectedPreviewTab === i ?
                                    classes.selected_tab :
                                    classes.unselected_tab
                            }
                            onClick={() => setSelectedPreviewTab(i)}>
                            <Typography
                                className={
                                    selectedPreviewTab === i ?
                                        classes.selected_tab_text :
                                        classes.unselected_tab_text
                                }>
                                {t(i)}
                            </Typography>
                        </div>
                    })}
                </Stack>
                <Button variant="contained" className={classes.button} disabled={disableButton}
                    onClick={handleCreateEditButton}>
                    {data?.id ? t("Update & Save") : t("Create Correspondence")}
                </Button>
            </Stack>
            {selectedPreviewTab === "Drafting" &&
                <div className={classes.draft_root}>
                    <Grid container spacing={"16px"}>
                        {data?.level === 1 &&
                            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                <SelectBox
                                    key={selectedCompany?.value}
                                    isRequired
                                    label={t("Choose Property")}
                                    placeholder={t("Search Property")}
                                    value={data?.property}
                                    options={options?.property}
                                    onChange={(value) => updateData("property", value)}
                                    isError={data?.error?.property?.length > 0}
                                    errorMessage={data?.error?.property} />
                            </Grid>}
                        {data?.level === 1 &&
                            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                <SelectBox
                                    key={data?.property?.value}
                                    isReadOnly={!Boolean(data?.property?.value)}
                                    isRequired
                                    label={t("Choose Unit")}
                                    placeholder={t("Search Unit")}
                                    value={data?.agreement_unit}
                                    onChange={(value) => updateData("agreement_unit", value)}
                                    loading={optionLoading === "agreement_unit"}
                                    isPaginate
                                    debounceTimeout={800}
                                    loadOptions={(search, array) => loadOptions(search, array, 'agreement_unit')}
                                    isError={data?.error?.agreement_unit?.length > 0}
                                    errorMessage={data?.error?.agreement_unit} />
                            </Grid>}
                        {data?.level === 2 &&
                            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                <SelectBox
                                    key={selectedCompany?.value}
                                    isReadOnly={!Boolean(selectedCompany?.value)}
                                    isRequired
                                    label={t("Choose Contact")}
                                    placeholder={t("Search Contact")}
                                    value={data?.contact}
                                    onChange={(value) => updateData("contact", value)}
                                    loading={optionLoading === "contact_by_company"}
                                    isPaginate
                                    debounceTimeout={800}
                                    loadOptions={(search, array) => loadOptions(search, array, 'contact_by_company')}
                                    isError={data?.error?.contact?.length > 0}
                                    errorMessage={data?.error?.contact} />
                            </Grid>}
                        <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                            <SelectBox
                                isRequired
                                label={t("Correspondence Type")}
                                placeholder={t("Correspondence Type")}
                                value={data?.correspondences_type}
                                options={options?.correspondences_type_master}
                                onChange={(value) => updateData("correspondences_type", value)}
                                isError={data?.error?.correspondences_type?.length > 0}
                                errorMessage={data?.error?.correspondences_type} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                            <NewDatePicker
                                label={t("Date")}
                                placeholder={("Select Date")}
                                value={data?.date ?? new Date()}
                                isRequired
                                onChange={(value) => updateData("date", value)}
                                isError={data?.error?.date?.length > 0}
                                errorMessage={data?.error?.date} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                            <NewDatePicker
                                label={t("Acknowledge Before")}
                                placeholder={("Select Date")}
                                value={data?.acknowledge_before ?? new Date()}
                                isRequired
                                onChange={(value) => updateData("acknowledge_before", value)}
                                isError={data?.error?.acknowledge_before?.length > 0}
                                errorMessage={data?.error?.acknowledge_before} />
                        </Grid>
                        {data?.level === 1 &&
                            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                <SelectBox
                                    key={data?.agreement_unit?.value}
                                    isReadOnly={!Boolean(data?.agreement_unit?.value)}
                                    label={t("Previous Letter Reference (If Any)")}
                                    placeholder={t("Search Letter")}
                                    value={data?.reference_letter}
                                    onChange={(value) => updateData("reference_letter", value)}
                                    loading={optionLoading === "reference_letter_by_agreement_unit"}
                                    isPaginate
                                    debounceTimeout={800}
                                    loadOptions={(search, array) => loadOptions(search, array, 'reference_letter_by_agreement_unit')}
                                    isError={data?.error?.reference_letter?.length > 0}
                                    errorMessage={data?.error?.reference_letter} />
                            </Grid>}
                        {data?.level === 2 &&
                            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                <SelectBox
                                    key={data?.contact?.value}
                                    isReadOnly={!Boolean(data?.contact?.value)}
                                    label={t("Previous Letter Reference (If Any)")}
                                    placeholder={t("Search Letter")}
                                    value={data?.reference_letter}
                                    onChange={(value) => updateData("reference_letter", value)}
                                    loading={optionLoading === "reference_letter_by_contact"}
                                    isPaginate
                                    debounceTimeout={800}
                                    loadOptions={(search, array) => loadOptions(search, array, 'reference_letter_by_contact')}
                                    isError={data?.error?.reference_letter?.length > 0}
                                    errorMessage={data?.error?.reference_letter} />
                            </Grid>}
                        <Grid item xs={12}>
                            <Grid container spacing={"8px"}>
                                <Grid item xs={12} sm={6} md={8} lg={10} xl={10}>
                                    <TextBox
                                        isrequired
                                        type={"text"}
                                        label={t("Subject")}
                                        placeholder={t("Enter Subject")}
                                        value={data?.subject}
                                        onChange={(e) => updateData("subject", e.target.value)}
                                        isError={data?.error?.subject?.length > 0}
                                        errorMessage={data?.error?.subject} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                    <PrioritySelect
                                        options={options?.urgent_type}
                                        value={data?.priority}
                                        onChange={(value) => updateData("priority", value)}
                                        isRequired />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Editor
                                value={data?.notes ?? ""} label=""
                                handleChange={(e, delta, source, editor) => updateData("notes", e)}
                                id={data?.id} height={data?.error?.notes?.length > 0 ? `calc(100vh - 635px)` : `calc(100vh - 550px)`} />
                            {data?.error?.notes?.length > 0 && (
                                <Typography variant={"caption"} color={"error"}>
                                    {data?.error?.notes}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </div>}
            {selectedPreviewTab === "Preview" &&
                <div className={classes.preview_root}>
                </div>}
        </>
    }

    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}
export default withNamespaces("correspondences")(CreateEditCorrespondences);