import { useApolloClient } from "@apollo/client";
import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingSection, SearchFilter, TableWithPagination } from "../../../components";
import { UPDATE_FLOOR } from "../../../graphql/mutations";
import { GET_FLOOR_LIST, GET_FLOOR_LIST_BY_PROPERTY } from "../../../graphql/queries";
import { Routes } from "../../../router/routes";
import { FloorHeadingViewNew, FloorPathView } from "../../../utils/block";
import makeStyles from '@mui/styles/makeStyles';
import { getCustomFloorName, LocalStorageKeys } from '../../../utils'
const useStyles = makeStyles((theme) => ({
    table: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        // height: `calc(100vh - 147px)`,
        overflow: "hidden",


    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize: "14px"
    },
    loading: {
        marginTop: "20%"
    }
}))
export const Floors = (props) => {
    const classes = useStyles();
    const history = useHistory()
    const client = useApolloClient();
    const search = useLocation().search;
    const [floorlist, setFloorList] = React.useState({
        data: [],
        count: 0,
        property: {},
    })
    const [loading, setLoading] = React.useState(true)
    const [searchText, setSearchText] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const blockID = new URLSearchParams(search).get("blockID");
    const propertyID = new URLSearchParams(search).get("propertyID");
    // eslint-disable-next-line
    const getfloorlist = async (blockid, active, searchText, offSet, limits) => {
        await client.query({
            query: propertyID ? GET_FLOOR_LIST_BY_PROPERTY : GET_FLOOR_LIST,
            fetchPolicy: 'network-only',
            variables: {
                id: blockid,
                active: [true, false],
                searchText: searchText,
                offset: offSet,
                limit: limits,
                client: localStorage.getItem(LocalStorageKeys.clinetID)
            }
        }).then((res) => {
            const list = res?.data.floor?.map((val) => {

                let _d;
                try {
                    _d = {
                        floorNo: val?.floor_no,
                        propertyName: val?.property?.name,
                        floorName: val?.name,
                        description: val?.description,
                        companyname: val?.property?.company?.name,
                        location: val?.property?.city_area_master?.area_name,
                        blockname: val?.block?.name,
                        unit: val?.unit?.length,
                        id: val?.id,
                        image: val?.assets?.[0]?.url
                    };
                } catch (err) {

                }
                return _d;
            })
            if (propertyID) {
                setFloorList({
                    count: res.data.count[0]?.count,
                    data: list,
                    property: res.data.property[0]
                })
            } else {
                setFloorList({
                    count: res.data.count[0]?.count,
                    data: list,
                    property: res.data.block[0]?.property,
                })
            }
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
        })
    }
    React.useEffect(() => {
        getfloorlist(propertyID ? propertyID : blockID, [true], "", 0, limit)
        // eslint-disable-next-line
    }, [])

    const handleSearch = (e) => {
        setSearchText(e)
        getfloorlist(blockID, [true], searchText, limit, 10)
    }
    const handleOnClickAddFloorButton = () => {
        history.push({
            pathname: Routes.createFloorNew,
            state: {
                main: {
                    blockID: blockID,
                    propertyID: floorlist?.property?.id ? floorlist?.property?.id : propertyID,
                }
            }
        })
    }
    const changeactive = async (data, value) => {
        let result = await client.mutate({
            mutation: UPDATE_FLOOR,
            variables: {
                ID: data,
                updatePayload: {
                    is_active: value === "Inactive" ? true : false
                }
            }
        })
        if (result) {
            getfloorlist(propertyID ? propertyID : blockID, [true], "", 0, limit)
        }

    }
    const handleIcon = (type, data) => {
        if (type === "active") {
            changeactive(data?.id, data?.status)
        }
        if (type === "view") {
            history.push(Routes.floorDetails + "?companyID=" + data?.companyID + "&floorID=" + data?.id + "&name=" + data?.floorName)
        }
    }
    const onUnitClick = (value, type) => {
        history.push(Routes.unit + "?floorID=" + value?.id)
    }
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getfloorlist(propertyID ? propertyID : blockID, [true], searchText, offset, limit)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getfloorlist(blockID, [true], searchText, 0, value)
    }

    return (
        <>
            {
                loading ? <LoadingSection bottom={"45vh"}
                    message={"Loading " + getCustomFloorName(floorlist?.property?.property_hierarchyByID) + " Listing..."} />
                    :
                    <div className={classes.root}>
                        <Box p={2} m={2} className={classes.table}>
                            <Grid container >
                                <Grid item xs={4}>
                                    <SearchFilter value={searchText}
                                        placeholder={"Search " + getCustomFloorName(floorlist?.property?.property_hierarchyByID)}
                                        handleChange={(value) => handleSearch(value)} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Box
                                        display={"flex"}
                                        sx={{ float: "right" }}
                                    >
                                        <Stack
                                            direction="row"
                                            divider={<Divider orientation="vertical" flexItem />}
                                            spacing={2}
                                        >

                                            {floorlist?.property?.property_hierarchyByID?.is_floor &&
                                                <Button variant="contained" className={classes.btn} onClick={handleOnClickAddFloorButton}>
                                                    {"Add " + getCustomFloorName(floorlist?.property?.property_hierarchyByID)}
                                                </Button>}
                                        </Stack>
                                    </Box>
                                </Grid>

                            </Grid>
                            <TableWithPagination
                                heading={FloorHeadingViewNew(floorlist?.property?.property_hierarchyByID)}
                                rows={floorlist?.data}
                                path={FloorPathView}
                                showpagination={true}
                                count="2"
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleIcon}
                                tableType="no-side"
                                onClick={() => console.log("")}
                                onUnitClick={onUnitClick}
                                dataType={[
                                    { type: ["avatar"], name: "image" },
                                    { type: ["text"], name: "floorName" },
                                    { type: ["text"], name: "blockname" },
                                    { type: ["quill"], name: "description" },
                                    { type: ["text"], name: "floorNo" },
                                    { type: ["unit"], name: "unit" },

                                ]}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={floorlist?.count}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 300px)'}
                                view={true}
                                edit={true}
                                delete={true} />
                        </Box>
                    </div>
            }
        </>
    );
};
