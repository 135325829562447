import React from "react";
import { withNavBars } from "../../HOCs";
import { PricingTable } from "./pricingTable";

class PricingTableParent extends React.Component {
  render() {
    return <PricingTable />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(PricingTableParent, props);
