import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { PaymentInput } from "../../screens/companyCreation/components";
import { SelectBox, TextBox } from "../index";
import { useStyles } from "./styles";
import { timeZoneConverter } from '../../utils'
export const DetailsCardCoponent = ({ render = () => false, components = [], title = null, border = true, justify = false }) => {
    const classes = useStyles({ border })
    const switchComponents = (val) => {

        if (val?.isActive) {

            switch (val.component) {
                case "text":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading} noWrap>{val?.heading}</Typography>
                            <Typography className={classes.sub} noWrap>{val?.subtitle}</Typography>

                        </Grid>
                    );
                case "date":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading}>{val?.heading}</Typography>
                            <Typography className={classes.sub}>{timeZoneConverter(val?.subtitle)}</Typography>

                        </Grid>
                    );
                case "inputCopy":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading}>{val?.heading}</Typography>
                            <PaymentInput
                                title={null}
                                diable={true}
                                subtitle={null}
                                btnname="Copy"
                                value={val?.value}
                                onChange={val?.onChange}
                            />
                        </Grid>
                    );
                case "status":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading}>{val?.heading}</Typography>
                            <Typography sx={{ color: val?.color, backgroundColor: val?.bgColor }} className={classes.status}>{val?.status}</Typography>

                        </Grid>
                    );
                case "button":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            {val?.heading && <Typography className={classes.heading}>{val?.heading}</Typography>}

                            <Button variant={val?.variant} fullWidth={val?.fullWidth} className={classes.btn} onClick={val?.onClick}>
                                {val?.subtitle}
                            </Button>
                        </Grid>
                    );
                case "tag":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading}>{val?.heading}</Typography>
                            <Typography sx={{ color: val?.color }} className={classes.status}>{val?.status}</Typography>

                        </Grid>
                    );
                case "select":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading}>{val?.heading}</Typography>
                            <Box height="4px" />
                            <TextBox
                                isRequired={val?.isRequired}
                                label={val?.label}
                                placeholder={val?.placeholder}
                                value={val.value}
                                onChange={val?.onChange}
                                isError={val?.error?.length > 0}
                                errorMessage={val?.error}
                                isReadOnly={val?.isReadonly}
                                isPaginate={val?.isPaginate}
                                loadOptions={val?.loadOptions}
                                loading={val?.loading}
                                debounceTimeout={800}
                                options={val?.options ?? []}
                            />
                        </Grid>
                    );
                case "description":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading}>{val?.heading}</Typography>
                            {/* <Typography className={classes.sub}>{val?.subtitle}</Typography> */}
                            <span className={classes.sub} dangerouslySetInnerHTML={{ __html: val?.subtitle }} />
                        </Grid>
                    );
                case "divider":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Divider />
                        </Grid>
                    );
                case "statusTypo":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography sx={{ color: val?.color, backgroundColor: val?.bgColor }} className={classes.status}>{val?.status}</Typography>
                            <Typography className={classes.sub} sx={{ marginLeft: "4px" }}>{val?.value}</Typography>

                        </Grid>
                    );
                case "textBox":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading}>{val?.heading}</Typography>
                            <Box height="4px" />
                            <SelectBox
                                isRequired={val?.isRequired}
                                label={val?.label}
                                placeholder={val?.placeholder}
                                value={val.value}
                                onChange={val?.onChange}
                                isError={val?.error?.length > 0}
                                errorMessage={val?.error}
                                isReadOnly={val?.isReadonly}
                                isPaginate={val?.isPaginate}
                                loadOptions={val?.loadOptions}
                                loading={val?.loading}
                                debounceTimeout={800}
                                options={val?.options ?? []}
                            />
                        </Grid>
                    );

                case "primary_button":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            {val?.heading && <Typography className={classes.heading}>{val?.heading}</Typography>}

                            <Button variant={val?.variant} fullWidth={val?.fullWidth} className={classes.primary_button} onClick={val?.onClick}>
                                {val?.subtitle}
                            </Button>
                        </Grid>
                    );
                default:
                    return null;
            }
        }
    }
    return (
        <Box className={classes.root} >
            <Grid container spacing={2} justifyContent={justify ? "space-between" : "auto"}>
                {
                    title && <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography className={classes.title}>{title}</Typography>
                            {render}
                        </Stack>

                    </Grid>
                }

                {
                    components?.length && components?.map(_ => (
                        <>
                            {switchComponents(_)}

                        </>
                    ))
                }
            </Grid>
        </Box>
    )
}