import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    weatherTilte: {
        color: theme.typography.color.white,
        fontSize: '12px',
        fontFamily: SemiBold,
        marginBottom: 0
    },
    weatherReport: {
        color: theme.typography.color.white,
        fontSize: '18px',
        fontFamily: Bold,
        marginBottom: '3px'
    },
    weatherBox: {
        maxWidth: '233px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: theme.palette.weather.main,
        padding: '16px',
        position: 'fixed',
        bottom: 16,
        left: 16
    },
    weatherTimeDate: {
        color: theme.typography.color.white,
        fontSize: '8px',
        fontFamily: Regular,
        marginBottom: '5px'
    },
    weatherInnerBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flagImage: {
        height: 50,
        width: 50
    }
}));