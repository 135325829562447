import React from "react";
import { withNavBars } from "../../HOCs";
import { Configure } from "./configure";
class ConfigureParent extends React.Component {
  render() {
    return (
        <Configure />

    );
  }
}
const props = {
  boxShadow: false
}
export default withNavBars(ConfigureParent , props);