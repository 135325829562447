import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { AggreementCardInfo, LoadingSection } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from '../../../networkcall';
import { NetWorkCallMethods , currencyFormater  } from "../../../utils";
import { useStyles } from "../styles";
export const DetailsContainer = ({ selectedAccount = "" , symbol="" ,code=""}) => {
    const classes = useStyles()
    const [details, setDetails] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    const getAccountDetails = () => {
        setLoading(true)
        const payload = {
            account_no: selectedAccount
        }
        NetworkCall(
            `${config.api_url}/account/stats`,
            NetWorkCallMethods.post,
            payload,
            true,
            true,
            false
        ).then((res) => {
            setDetails(
                [
                    {
                        url: "/images/account/revenue.svg",
                        title: code ? currencyFormater(code , res?.data?.data?.[0]?.total_amount ?? 0):res?.data?.data?.[0]?.total_amount ?? 0,
                        // title:res?.data?.data?.[0]?.total_amount ?? 0,
                        subtitle: "Total Revenue",
                        color: "#F5F7FA",
                        // symbol:symbol ?? "-"
                    },
                    {
                        url: "/images/account/unbilled.svg",
                        title: res?.data?.data?.[0]?.total_schedule_amount ?? 0,
                        subtitle: "Unbilled",
                        color: "#F5F7FA"
                    },
                    {
                        url: "/images/account/recived.svg",
                        title: (parseInt(res?.data?.data?.[0]?.total_amount ?? 0) - parseInt(res?.data?.data?.[0]?.total_due_amount ?? 0)) ?? 0,
                        subtitle: "Received",
                        color: "#F5F7FA"
                    },
                    {
                        url: "/images/account/pending.svg",
                        title: res?.data?.data?.[0]?.total_due_amount ?? 0,
                        subtitle: "Pending",
                        color: "#F5F7FA"
                    },
                    {
                        url: "/images/account/agreemnts.svg",
                        title: res?.data?.data?.[0]?.total_agreement ?? 0,
                        subtitle: "Agreements",
                        color: "#F5F7FA"
                    },
                    {
                        url: "/images/account/nousers.svg",
                        title: res?.data?.data?.[0]?.total_users ?? 0,
                        subtitle: "Contacts",
                        color: "#F5F7FA"
                    }
                ])
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }
    React.useEffect(() => {
        getAccountDetails()
        // eslint-disable-next-line
    }, [selectedAccount])

    return (
        <Box>
            <Typography className={classes.title}>Account Screen info</Typography>
            {
                loading ?
                    <LoadingSection bottom={"45vh"} message="Fetching ..." />
                    :
                    <Grid container spacing={2.5}>
                        {
                            details?.map((val) => {
                                return (
                                    <Grid item xs={6}>
                                        <AggreementCardInfo data={val} />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
            }

        </Box>
    )
}