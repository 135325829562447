import React from 'react'
import { UpdatePopupStyle } from './style'
import ReconcileSvgComponent from '../../../assets/reconcileSvg'
import { Box, Stack, Typography } from '@mui/material'
import ShowMoreText from "react-show-more-text";
import CancelIcon from '@mui/icons-material/Cancel';

export const RequestMessage = ({ type = "", title = "", message = "", remarks = "" }) => {
    const classes = UpdatePopupStyle()
    return (
        <Box m={1} className={type === "Cancelled" ? classes.cancel_alert : type === "Rejected" ? classes.decline_alert : classes.reconcile_alert} p={2}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Box>
                    {
                        type === "Cancelled" ?
                            <CancelIcon sx={{fontSize:36, color:"#4E5A6B"}} />
                            :
                            type === "Rejected" ?
                                <img src="../images/exicutedDeclined.svg" alt="decline" className={classes.decline_icon} />
                                :
                                <ReconcileSvgComponent />
                    }
                </Box>

                <Stack pl={1}>
                    <Typography className={classes.alert_title}> {title} </Typography>
                    {/* <ShowMoreText
                        lines={1}
                        more="Show More"
                        less="Show Less"
                        className={classes.alert_content}
                        anchorClass={classes.alert_content}
                        expanded={false}
                        truncatedEndingComponent={"... "}
                    > */}
                    <Typography className={classes.alert_content} noWrap> {message} </Typography>
                    {/* </ShowMoreText> */}
                </Stack>
            </Stack>
            {
                remarks !== "" &&
                <ShowMoreText
                    lines={2}
                    more="Show More"
                    less="Show Less"
                    className={classes.remarks}
                    anchorClass={classes.remarks}
                    expanded={false}
                    truncatedEndingComponent={"... "}
                >
                    <Typography className={classes.remarks}> Remarks: {remarks} </Typography>
                </ShowMoreText>
            }
        </Box>
    )
}