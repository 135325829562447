import React from "react";
import { withNavBars } from "../../HOCs";
import { Accounts } from '../accounts/account'

class CustomerAccountListParent extends React.Component {
    render() {
        return <Accounts {...this.props} type="Customer" subtitle="Customer Account" /> ;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(CustomerAccountListParent, props);

 
