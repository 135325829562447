
import styled from '@mui/material/styles/styled';
import { Avatar, Button, Stack, Typography } from "@mui/material";
import { Bold, SemiBold } from '../../../utils';
export const BodyDiv = styled('div')({
    padding: "16px",
    height: `calc(100vh - 184px)`,
    overflow: "overlay"
})

export const ProfileDetailsStack = styled(Stack)((props) => ({
    backgroundColor: "white",
    borderRadius: props.theme.palette.borderRadius,
    padding: "16px"
}))

export const ProfileImageOuterDiv = styled('div')({
    alignSelf: "center"
})

export const ProfileImageInnerDiv = styled('div')({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    margin: "8px 8px 19px"
})

export const Image = styled(Avatar)((props) => ({
    cursor: "pointer",
    width: "141px",
    height: "141px",
    borderRadius: "71px",
    backgroundColor: "#F2F4F7",
    border: props?.src ? 0 : '1px solid #e3e5ec'
}))

export const UserID = styled(Typography)((props) => ({
    padding: "2px 6px",
    borderRadius: props?.theme.palette.borderRadius,
    backgroundColor: "#071741",
    color: "white",
    fontSize: "12px",
    fontFamily: SemiBold,
    position: "absolute",
    bottom: "-11px",
    textAlign: "center",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    display: props?.userNo ? "inline" : "none",
}))

export const CardTitle = styled(Typography)((props) => ({
    color: props?.color ?? "#4E5A6B",
    fontSize: "12px",
    fontFamily: Bold,
}))

export const Title = styled(Typography)((props) => ({
    marginBottom: props?.marginBottom ?? "8px",
    color: "#98A0AC",
    fontSize: "12px",
    fontFamily: SemiBold,
}))

export const Desc = styled(Typography)((props) => ({
    color: "#091B29",
    fontSize: "14px",
    fontFamily: SemiBold,
}))

export const AssignedDiv = styled('div')((props) => ({
    backgroundColor: "white",
    padding: "16px 20px",
    borderRadius: props?.theme.palette.borderRadius,
}))

export const CompanyRoleListDiv = styled('div')((props) => ({
    height: "53.5px",
    [props?.theme?.breakpoints?.down?.("lg")]: {
        height: "80px",
    },
    overflow: "overlay"
}))

export const PropertyListDiv = styled('div')((props) => ({
    height: "181px",
    [props?.theme?.breakpoints?.down?.("lg")]: {
        height: "234px",
    },
    overflow: "overlay"
}))

export const MapAddressDiv = styled('div')((props) => ({
    backgroundColor: "white",
    borderRadius: props?.theme.palette.borderRadius,
    padding: "16px",
}))

export const MapOuterDiv = styled('div')((props) => ({
    height: "250px"
}))

export const MapInnerDiv = styled('div')((props) => ({
    borderRadius: props?.theme.palette.borderRadius,
    overflow: "hidden",
    width: "100%",
    height: "100%"
}))

export const AddressDiv = styled('div')((props) => ({
    backgroundColor: "white",
    borderRadius: props?.theme.palette.borderRadius,
    border: "1px solid #E4E8EE",
    padding: "16px",
    height: "250px"
}))

export const FooterDiv = styled('div')({
    padding: "0px 16px",
    backgroundColor: "white",
    height: "64px",
    display: "flex",
    justifyContent: "end",
    alignItems: "center"
})

export const EditButton = styled(Button)({
    padding: "10px 16px",
    backgroundColor: "#5078E1",
    border: "1px solid #5078E1",
    color: "white",
    height: "40px",
    fontSize: "14px",
    fontFamily: Bold
})