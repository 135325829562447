import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { AlertDialog, FilterGenerator, LoadingSection, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { UPDATE_INVENTARY } from "../../graphql/inspectionMaster";
import { NetworkCall } from "../../networkcall";
import { AlertProps, enumSelect, enum_types, getCompanyOption, NetWorkCallMethods } from "../../utils";
import { initialState, ItemOptionList, StatusOptionList } from "../../utils/insepectionMaster";
import { Form, SearchFilters, Table } from "./components";
import { useStyles } from "./style";

const InspectionMaster = ({ t }) => {
    const classes = useStyles();
    const client = useApolloClient();
    const debounce = UseDebounce();
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext);
    const [data, setData] = React.useState({ ...initialState });
    const [searchText, setSearchText] = React.useState("");
    const [compenyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [drawer, setDrawer] = React.useState(false);
    const [loading, setLoading] = React.useState(true)
    const [itemList, setItemList] = React.useState({
        list: [],
        count: 0,
    })
    const [open, setOpen] = React.useState(false);
    const [filterData, setFilterData] = React.useState({
        is_inventory: null,
        is_active: null
    });
    const [itemTypeOptions, setItemTypeOptions] = React.useState([])
    
    //get list
    const getInventoryItemList = (company_id, searchText, offset, limits, filterDatas) => {
        setLoading(true)
        const payload = {
            company_id: company_id,
            search: searchText,
            offset: offset,
            limit: limits,
            active: filterDatas?.is_active ?? undefined,
            is_inventory: filterDatas?.is_inventory ?? undefined,

        }
        NetworkCall(
            `${config.api_url}/inspection_items/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setItemList({
                    list: response?.data?.data?.map((val) => {
                        return { ...val, status: val?.is_active ? "Active" : "In-Active" }
                    }),
                    count: response.data.count
                })
                setLoading(false)
            }).catch((err) => {
                console.log(err)
                setLoading(false)

            })
    }

    //initial load
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getInventoryItemList(company?.selected?.value, "", 0, 10, {})
            getEnum()
        }
        // eslint-disable-next-line
    }, [auth])

    //reload
    const reload = () => {
        getInventoryItemList(selectedCompany?.value, "", 0, 10, {})
    }
    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types.inspection_item_type])
        setItemTypeOptions(result?.inspection_item_type)
    }
    //on isActive
    const onIsActive = (id, status) => {

        const payload = {
            is_active: status
        }
        client.mutate({
            mutation: UPDATE_INVENTARY,
            variables: {
                id: id,
                updatePayload: payload
            }
        }).then(rs => {
            reload();
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Updated",
            });
        }).catch(er => {
            console.log(er)
        })


    }

    //handle icon
    const handleClickHandler = (type, val) => {
        if (type === "edit") {
            setOpen(true);
            setData({
                ...data,
                isEdit: true,
                id: val?.id,
                item: val?.is_inventory,
                status: val?.is_active,
                item_type:{label:val?.item,value:val?.item},
                name: val?.is_inventory ?
                    {
                        value: val?.inventory_id,
                        label: val?.name
                    } : val?.name
            });
        } else if (type === "active") {
            onIsActive(val?.id, !val?.is_active);
        }

    }

    //handle change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getInventoryItemList(value?.value, "", 0, 10, {})
    }

    //apply filter
    const onApplyFilter = (data) => {
        getInventoryItemList(selectedCompany?.value, "", 0, 10, data)
        setFilterData(data)
    }

    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getInventoryItemList(selectedCompany?.value, "", offset, limit, filterData)
    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getInventoryItemList(selectedCompany?.value, "", 0, value, filterData)
    }

    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getInventoryItemList(selectedCompany?.value, e, 0, limit, filterData)
    }

    //onClose form
    const closeForm = () => {
        setOpen(false)
        setData({ ...initialState })
    }

    return (
        <Box>
            {/*sub header */}
            <Subheader
                hideBackButton={true}
                title="Inspection"
                select
                options={compenyList}
                value={selectedCompany}
                onchange={(e) => {
                    handleCompanyChange(e)
                }}
            />
            {
                loading ?
                    <LoadingSection top="20vh" message={t("loading")} /> :

                    <Box container className={classes.root}>
                        {/*search btn component */}
                        <SearchFilters
                            onClick={() => setOpen(true)}
                            searchText={searchText}
                            handleSearch={handleSearch}
                            openfilter={() => setDrawer(true)}
                            filterData={filterData}

                        />
                        {/*table component */}
                        <Table
                            handleIcon={handleClickHandler}
                            handleChangeLimit={handleChangeLimit}
                            handlePagination={handlePagination}
                            page={page}
                            list={itemList}
                            limit={limit}
                            handleSearch={handleSearch}
                            searchText={searchText}
                            view={true}
                            edit={true}
                            delete={true} />
                    </Box>
            }
            {/*form component */}
            <AlertDialog
                open={open}
                onClose={closeForm}
                header={data?.isEdit ? `${t("edit")}` : `${t("create")}`}
                component={<Form
                    company={selectedCompany}
                    company_id={selectedCompany?.value}
                    data={data}
                    itemTypeOptions={itemTypeOptions}
                    setData={setData}
                    reload={reload}
                    onClose={closeForm}

                />}
                isNormal
            />

            {/*filter component */}
            {drawer && (
                <FilterGenerator
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.is_active,
                            state_name: "is_active",
                            label: "Active",
                            options: StatusOptionList,
                            isMulti: true
                        },
                        {
                            component: "toggleButton",
                            value: filterData?.is_inventory,
                            state_name: "is_inventory",
                            label: "Inventory",
                            options: ItemOptionList,
                            isMulti: true
                        },

                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            )}

        </Box>

    )

}
export default withNamespaces("inspectionItemmaster")(InspectionMaster); 