import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold } from "../../../utils";
export const OutstandingCardStyles = makeStyles((theme) => ({
    card: {
        cursor: "pointer",
        borderRadius: '4px',
        backgroundColor: theme.palette.background.paper,
        margin: "4px 0px",
        overflow: "hidden",
        borderBottom: (props) => props?.data?.color ? `1px solid ${theme.palette.border.secondary}` : 0,
        borderTop: (props) => props?.data?.color ? `1px solid ${theme.palette.border.secondary}` : 0,
        padding: (props) => props?.data?.color ? "4px" : 0,
        '&:last-child': {
            borderBottom: 'none'
        },
        '&:first-child': {
            borderTop: 'none'
        }
    },
    Cardcontent: {
        [theme.breakpoints.up("sm")]: {
            padding: "8px 12px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "8px 7px",
        },
        borderBottom: (props) => props?.data?.color ? 0 : `1px solid ${theme.palette.border.secondary}`,
        borderTop: (props) => props?.data?.color ? 0 : `1px solid ${theme.palette.border.secondary}`,
        backgroundColor: (props) => props?.data?.color,
        borderRadius: (props) => props?.data?.color ? "8px" : 0,
        '&:last-child': {
            borderBottom: 'none'
        },
        // '&:first-child': {
        //     borderTop: 'none'
        // }

    },
    title: {
        fontSize: "12px",
        color: theme.typography.color.primary,
        fontFamily: Bold,
    },


    amount: {
        fontSize: "16px",
        color: theme.typography.color.primary,
        fontFamily: ExtraBold,
    },
    sub: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
    },
    img: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        padding: "2px 8px",
    },

    boxes: {
        flexFlow: 'wrap !important'
    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    div: {
        display: 'flex', alignItems: 'center'
    }

}));
