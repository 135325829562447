import * as React from "react"

export const AddExisitingContact = (props) => (
  <svg
    data-name="Group 102304"
    xmlns="http://www.w3.org/2000/svg"
    width={362.896}
    height={200.676}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 53953"
          fill="none"
          d="M0 0h362.896v200.676H0z"
        />
      </clipPath>
      <clipPath id="b">
        <path
          data-name="Rectangle 53946"
          fill="none"
          d="M0 0h30.008v6.221H0z"
        />
      </clipPath>
      <clipPath id="c">
        <path
          data-name="Rectangle 53947"
          fill="none"
          d="M0 0h28.553v2.999H0z"
        />
      </clipPath>
      <clipPath id="d">
        <path
          data-name="Rectangle 53949"
          fill="none"
          d="M0 0h14.901v6.472H0z"
        />
      </clipPath>
      <clipPath id="e">
        <path
          data-name="Rectangle 53950"
          fill="none"
          d="M0 0h17.42v10.061H0z"
        />
      </clipPath>
      <clipPath id="f">
        <path
          data-name="Rectangle 53951"
          fill="none"
          d="M0 0h16.919v21.125H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 102303" clipPath="url(#a)">
      <g data-name="Group 102279">
        <g data-name="Group 102278" clipPath="url(#a)">
          <path
            data-name="Path 96971"
            d="M1.109 153.147s-2.757 1.117.423 6.97a77.1 77.1 0 0 0 5.353 8.736s.573 1.363-.581 1.607-3.717-.1-4.2 1.335c-.5 1.485.607 2.845 2.805 4.006s6.921 3.882 7.347 4.13.848.873.089 1.165-6.485 1.04-5.968 3.736 7.173 4.925 7.9 5.182.9.557.854.951-2 .464-3.616.744-3.449.888-3.02 2.418 4.418 4.665 13.926 5.349l4.426.028 2.31-3.8c4.448-8.431 3.9-13.474 2.825-14.648s-2.555.058-3.649 1.284-2.184 2.843-2.543 2.676-.519-.478-.356-1.228 1.792-7.577-.222-9.442-5.677 2.6-6.326 3.089-.958-.208-.942-.691.2-5.94.38-8.42-.394-4.137-1.917-4.5c-1.469-.35-2.536 2.005-3.352 2.856s-1.672-.357-1.672-.357a77.122 77.122 0 0 0-4.583-9.159c-3.286-5.795-5.744-3.989-5.744-3.989"
            fill="#ebebeb"
          />
          <path
            data-name="Path 96972"
            d="M27.103 200.539c-2.4-4.265-6.529-11.918-10.11-18.469s-6.827-12.476-9.2-16.753l-2.818-5.062-.767-1.376-.2-.36c-.043-.081-.062-.125-.062-.125s.027.039.075.118l.208.353.788 1.364a585.79 585.79 0 0 1 2.856 5.041c2.4 4.265 5.658 10.185 9.238 16.734s7.7 14.211 10.075 18.488"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96973"
            d="M5.674 172.416a1.426 1.426 0 0 1 .311.078c.2.058.481.15.829.267.7.235 1.651.585 2.7.99l2.674 1.046.812.312a1.449 1.449 0 0 1 .294.126 1.454 1.454 0 0 1-.311-.076c-.2-.058-.481-.15-.828-.271-.695-.243-1.644-.6-2.689-1.009l-2.68-1.026-.814-.308a1.42 1.42 0 0 1-.293-.129"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96974"
            d="M13.585 174.944c-.036-.012.6-2.011 1.416-4.464s1.511-4.433 1.547-4.421-.6 2.011-1.417 4.465-1.51 4.433-1.546 4.42"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96975"
            d="M18.63 185.25a1.525 1.525 0 0 1 .172-.278l.506-.731c.425-.617 1.01-1.472 1.62-2.439s1.137-1.859 1.511-2.509l.444-.769a1.5 1.5 0 0 1 .177-.275 1.478 1.478 0 0 1-.13.3c-.092.189-.229.46-.406.791a40.379 40.379 0 0 1-3.137 4.96c-.225.3-.409.543-.542.706a1.474 1.474 0 0 1-.215.246"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96976"
            d="M10.972 184.539a1.265 1.265 0 0 1 .3 0l.82.044a209.012 209.012 0 0 1 5.4.436l.816.087a1.292 1.292 0 0 1 .3.051 1.269 1.269 0 0 1-.3 0l-.82-.043a209.012 209.012 0 0 1-5.4-.436l-.817-.087a1.284 1.284 0 0 1-.3-.051"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96977"
            d="M14.27 194.202a2.055 2.055 0 0 1 .387 0l1.051.052c.887.05 2.112.129 3.465.224s2.577.189 3.462.264l1.048.1a2.109 2.109 0 0 1 .383.054 2.053 2.053 0 0 1-.387 0l-1.051-.052c-.887-.05-2.112-.129-3.466-.224s-2.576-.189-3.462-.263l-1.048-.1a2.1 2.1 0 0 1-.383-.054"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96978"
            d="M23.836 194.811a2.381 2.381 0 0 1 .209-.357l.607-.95c.512-.8 1.215-1.912 1.956-3.16s1.387-2.393 1.846-3.226l.545-.987a2.366 2.366 0 0 1 .214-.354 2.389 2.389 0 0 1-.167.379c-.115.24-.287.585-.507 1.008a66.181 66.181 0 0 1-3.806 6.393c-.268.394-.488.71-.644.926a2.39 2.39 0 0 1-.254.327"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96979"
            d="M109.732 44.181H73.147a3.125 3.125 0 0 1-3.125-3.125V8.312a3.125 3.125 0 0 1 3.125-3.125h36.584a3.125 3.125 0 0 1 3.125 3.125v32.746a3.125 3.125 0 0 1-3.125 3.125"
            fill="#fff"
          />
          <path
            data-name="Path 96980"
            d="M112.857 11.823H70.022V8.398a3.211 3.211 0 0 1 3.211-3.211h36.666a2.958 2.958 0 0 1 2.958 2.958Z"
            fill="#ebebeb"
          />
          <path
            data-name="Path 96981"
            d="M76.191 9.154a1.052 1.052 0 1 1-1.05-.988 1.021 1.021 0 0 1 1.05.988"
            fill="#fff"
          />
          <path
            data-name="Path 96982"
            d="M79.052 9.154a1.052 1.052 0 1 1-1.05-.988 1.021 1.021 0 0 1 1.05.988"
            fill="#fff"
          />
          <path
            data-name="Path 96983"
            d="M81.913 9.154a1.052 1.052 0 1 1-1.05-.988 1.021 1.021 0 0 1 1.05.988"
            fill="#fff"
          />
          <path
            data-name="Path 96984"
            d="M109.732 44.184c0-.009.139-.007.405-.043a3.015 3.015 0 0 0 1.119-.387 3.091 3.091 0 0 0 1.549-2.591c.008-.441 0-.916 0-1.416l-.044-16.962-.017-11.713V9.48c-.013-.529.029-1.09-.037-1.6a3.009 3.009 0 0 0-2.687-2.555c-.267-.02-.555-.013-.838-.014h-5.224l-30.8.011a3 3 0 0 0-2.882 2.2 3.225 3.225 0 0 0-.109.963v10.867c0 5.153-.005 10.123-.008 14.858v6.916a3.016 3.016 0 0 0 1.594 2.588 3.092 3.092 0 0 0 1.5.352h1.592l11.713.017 16.961.044 4.611.026 1.2.013c.27 0 .408.014.408.014s-.139.01-.408.014l-1.2.013-4.611.026-16.961.044-11.713.017h-1.592a3.324 3.324 0 0 1-1.615-.376 3.255 3.255 0 0 1-1.726-2.79q0-3.366-.01-6.922c0-4.735-.005-9.706-.008-14.858V8.485a3.51 3.51 0 0 1 .12-1.036 3.275 3.275 0 0 1 3.147-2.4l30.8.011h5.224c.285 0 .564-.005.861.016a3.251 3.251 0 0 1 2.9 2.765 16.069 16.069 0 0 1 .035 1.634v1.592l-.017 11.713-.044 16.962c0 .5 0 .972-.012 1.419a3.152 3.152 0 0 1-1.628 2.643 2.948 2.948 0 0 1-1.142.367 2.5 2.5 0 0 1-.409.015"
            fill="#ebebeb"
          />
          <path
            data-name="Path 96985"
            d="M107.276 40.766h-31.77a2.357 2.357 0 0 1-2.357-2.357V17.171a2.357 2.357 0 0 1 2.355-2.361h31.772a2.357 2.357 0 0 1 2.357 2.357V38.41a2.357 2.357 0 0 1-2.357 2.357"
            fill="#f5f5f5"
          />
          <path
            data-name="Path 96986"
            d="m76.213 39.143 8.9-12.675a1.008 1.008 0 0 1 1.61-.053l6.12 7.589a1.009 1.009 0 0 0 1.484.094l3.585-3.442a1.009 1.009 0 0 1 1.47.078l7.027 8.34a1.008 1.008 0 0 1-.771 1.658h-28.6a1.008 1.008 0 0 1-.825-1.588"
            fill="#ebebeb"
          />
          <path
            data-name="Path 96987"
            d="M96.522 22.716a3.1 3.1 0 1 1-2.572-2.573 3.1 3.1 0 0 1 2.572 2.573"
            fill="#ebebeb"
          />
          <path
            data-name="Path 96988"
            d="M359.652 114.618h-23.495a3.125 3.125 0 0 1-3.125-3.125v-20.83a3.125 3.125 0 0 1 3.125-3.125h23.495a3.125 3.125 0 0 1 3.125 3.125v20.83a3.125 3.125 0 0 1-3.125 3.125"
            fill="#fff"
          />
          <path
            data-name="Path 96989"
            d="M362.778 92.147h-29.746v-1.4a3.211 3.211 0 0 1 3.211-3.211h23.577a2.958 2.958 0 0 1 2.958 2.958Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96990"
            d="M337.314 90.292a.731.731 0 1 1-.729-.686.709.709 0 0 1 .729.686"
            fill="#fff"
          />
          <path
            data-name="Path 96991"
            d="M339.303 90.292a.73.73 0 1 1-.729-.686.709.709 0 0 1 .729.686"
            fill="#fff"
          />
          <path
            data-name="Path 96992"
            d="M341.29 90.292a.731.731 0 1 1-.729-.686.709.709 0 0 1 .729.686"
            fill="#fff"
          />
          <path
            data-name="Path 96993"
            d="M359.653 114.618a3.761 3.761 0 0 0 1.067-.221 3.094 3.094 0 0 0 1.938-2.267 5.073 5.073 0 0 0 .061-1.069q0-.571-.006-1.2l-.012-2.712-.02-6.611c-.007-2.448-.011-5.134-.017-8.012-.009-.715.014-1.465-.021-2.184a3.02 3.02 0 0 0-.968-1.9c-1.114-1.12-2.858-.706-4.444-.782l-21.072.011a2.983 2.983 0 0 0-2.9 2.283 5.422 5.422 0 0 0-.088 1.33v5.376c0 3.525-.005 6.925-.008 10.164v4.73a2.971 2.971 0 0 0 .8 1.976 3 3 0 0 0 1.843.945c2.851.052 5.555.023 8 .04l11.6.044 3.154.026.821.013c.185 0 .279.014.279.014s-.095.01-.279.014l-.821.013-3.154.026-11.6.044c-2.455 0-5.12.037-8.025-.008a3.244 3.244 0 0 1-2.856-3.142q0-2.3-.01-4.736c0-3.239 0-6.639-.008-10.164v-5.376a5.658 5.658 0 0 1 .095-1.395 3.258 3.258 0 0 1 3.17-2.494l21.072.011h2.4a3.255 3.255 0 0 1 3.248 2.909c.035.768.008 1.483.015 2.209l-.017 8.012c-.005 2.45-.014 4.663-.02 6.611l-.012 2.712q0 .625-.006 1.2a5.161 5.161 0 0 1-.076 1.091 3.142 3.142 0 0 1-2.023 2.3 2.936 2.936 0 0 1-.806.168h-.28"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96994"
            d="M358.18 112.245h-20.617a2.357 2.357 0 0 1-2.357-2.357V96.58a2.357 2.357 0 0 1 2.357-2.357h20.621a2.357 2.357 0 0 1 2.357 2.357v13.307a2.357 2.357 0 0 1-2.357 2.357"
            fill="#f5f5f5"
          />
          <path
            data-name="Path 96995"
            d="m337.33 111.117 6.184-8.8a.7.7 0 0 1 1.118-.037l4.25 5.269a.7.7 0 0 0 1.03.066l2.49-2.391a.7.7 0 0 1 1.02.054l4.88 5.792a.7.7 0 0 1-.535 1.152h-19.864a.7.7 0 0 1-.573-1.1"
            fill="#ebebeb"
          />
          <path
            data-name="Path 96996"
            d="M351.435 99.71a2.154 2.154 0 1 1-1.786-1.786 2.155 2.155 0 0 1 1.786 1.786"
            fill="#ebebeb"
          />
          <path
            data-name="Path 96997"
            d="M259.861 48.577h-23.185a3.767 3.767 0 0 1-3.767-3.767V21.625a3.767 3.767 0 0 1 3.767-3.767h23.185a3.767 3.767 0 0 1 3.767 3.767V44.81a3.767 3.767 0 0 1-3.767 3.767"
            fill="#f5f5f5"
          />
          <path
            data-name="Path 96998"
            d="m237.662 33.659 3.094-3.094 5.968 5.3 9.966-10 2.873 3.094-13.281 12.878Z"
            fill="#fff"
          />
        </g>
      </g>
      <g data-name="Group 102281">
        <g data-name="Group 102280" clipPath="url(#a)">
          <path
            data-name="Path 96999"
            d="M354.34 200.539c0 .076-77.96.138-174.107.138-96.18 0-174.123-.061-174.123-.138s77.943-.138 174.123-.138c96.147 0 174.107.061 174.107.138"
            fill="#263238"
          />
        </g>
      </g>
      <g data-name="Group 102283">
        <g data-name="Group 102282" clipPath="url(#a)">
          <path
            data-name="Path 97000"
            d="m28.394 78.301.117-4.821c4.187-4.81 5.632-12.239 1.388-20.233-14.815-15.692-37.126.285-27.046 19.366 6.766 7.189 15.064 7.756 21.157 4.465l-.006.006Z"
            fill="#fff"
          />
          <path
            data-name="Path 97001"
            d="M28.394 78.301s-.1-.036-.295-.093l-.846-.25-3.233-.936.027.108.006-.006a.064.064 0 0 0 0-.09.065.065 0 0 0-.077-.013 15.9 15.9 0 0 1-9.963 1.693c-.423-.059-.843-.177-1.276-.265a8.8 8.8 0 0 1-1.3-.367 15.8 15.8 0 0 1-5.074-2.767 15.876 15.876 0 0 1-4.505-18.787 16.183 16.183 0 0 1 1.943-3.3 16.488 16.488 0 0 1 6.064-4.782 16.029 16.029 0 0 1 3.624-1.123 15.746 15.746 0 0 1 7.259.328 15.979 15.979 0 0 1 7.7 25.8L28.4 77.04c0 .4-.006.708-.007.932v.326s.01-.1.019-.3l.037-.914.126-3.607-.016.041a15.984 15.984 0 0 0 3.58-6.969A16.2 16.2 0 0 0 20.83 47.366a16.016 16.016 0 0 0-7.4-.344 16.33 16.33 0 0 0-3.7 1.142 16 16 0 0 0-8.162 8.239A16.2 16.2 0 0 0 6.2 75.519a15.991 15.991 0 0 0 5.167 2.787 8.994 8.994 0 0 0 1.317.365c.44.087.867.2 1.3.261a16.341 16.341 0 0 0 2.492.177 16.1 16.1 0 0 0 7.564-1.975l-.075-.1-.006.006a.064.064 0 0 0 0 .09.067.067 0 0 0 .03.019l3.294.873.838.217a2.64 2.64 0 0 0 .276.065"
            fill="#263238"
          />
          <path
            data-name="Path 97002"
            d="M21.745 61.757a2.026 2.026 0 0 0 2.861 2.864 2.026 2.026 0 0 0-2.864-2.864"
            fill="#263238"
          />
          <path
            data-name="Path 97003"
            d="M14.941 61.757a2.026 2.026 0 0 0 2.864 2.864 2.026 2.026 0 0 0-2.864-2.864"
            fill="#263238"
          />
          <path
            data-name="Path 97004"
            d="M8.137 61.757a2.027 2.027 0 0 0 2.864 2.865 2.027 2.027 0 0 0-2.864-2.865"
            fill="#263238"
          />
        </g>
      </g>
      <g data-name="Group 102291">
        <g data-name="Group 102290" clipPath="url(#a)">
          <path
            data-name="Path 97005"
            d="m101.316 120.571-10.35-2.791-2.059 9.367-5.168-14.127c-2.409-6.754-5.705-9.08-7.979-9.71l-.822 1.07 2.178 37.367 6.9 8.583c3.226 4.014 9.737 3.224 11.6-1.575 3.875-9.972 5.191-25.147 5.7-28.183"
            fill="#69f"
          />
          <path
            data-name="Path 97006"
            d="M28.412 200.389h51.784l-5.428-37.177-33-2.363s-5.693 5.58-7.579 12.132-5.777 27.408-5.777 27.408"
            fill="#263238"
          />
          <path
            data-name="Path 97007"
            d="M37.12 77.065c1.223-11.029 3.627-23.168 19.349-22.568 0 0 11.841.413 15.61 8.829 2.04 4.556 1.466 21.867 2.4 36.138l2.3 18.313-47.544-7.346s7.128-26.511 7.888-33.369"
            fill="#263238"
          />
          <path
            data-name="Path 97008"
            d="m58.701 58.867-1.012-.9-10.463 4.622a2.961 2.961 0 0 0-2.7 2.957l2.393 35.093c.063 2.344-.565 3.81.911 5.631l10.139 12.128c3.543 4.372 2.9-5.488 5.431-10.516.176-.351-.679-1.611-.631-1.858a7.01 7.01 0 0 0 .132-.778c.224-2.79.163-5.2.163-5.2.089-3.058.11-4.312.112-4.284 0 0 6.47-.96 7.952-9.354.737-4.175.385-9.873-.045-15.479-.6-7.758-6.138-12.274-12.383-12.061"
            fill="#ffc09d"
          />
          <path
            data-name="Path 97009"
            d="M58.778 77.32a1.08 1.08 0 0 1-1.06 1.071 1.033 1.033 0 0 1-1.1-.993 1.08 1.08 0 0 1 1.059-1.071 1.033 1.033 0 0 1 1.1.993"
            fill="#263238"
          />
          <path
            data-name="Path 97010"
            d="M69.181 77.215a1.08 1.08 0 0 1-1.06 1.071 1.033 1.033 0 0 1-1.1-.992 1.08 1.08 0 0 1 1.059-1.072 1.033 1.033 0 0 1 1.1.993"
            fill="#263238"
          />
          <path
            data-name="Path 97011"
            d="M70.047 73.184c-.136.139-.944-.481-2.105-.5-1.16-.034-2.023.549-2.146.4-.061-.064.08-.316.455-.58a2.97 2.97 0 0 1 1.721-.5 2.846 2.846 0 0 1 1.673.572c.352.28.469.537.4.6"
            fill="#263238"
          />
          <path
            data-name="Path 97012"
            d="M58.282 73.693c-.15.124-.891-.576-2.044-.712-1.151-.153-2.069.339-2.177.183-.053-.07.111-.306.513-.53a2.966 2.966 0 0 1 1.763-.319 2.849 2.849 0 0 1 1.606.741c.321.315.411.582.339.637"
            fill="#263238"
          />
          <path
            data-name="Path 97013"
            d="M62.612 82.002a7.7 7.7 0 0 1 1.892-.308c.3-.025.579-.079.633-.281a1.509 1.509 0 0 0-.183-.883l-.838-2.274a38.852 38.852 0 0 1-1.868-5.942 38.942 38.942 0 0 1 2.345 5.778l.8 2.287a1.717 1.717 0 0 1 .134 1.167.747.747 0 0 1-.5.425 2.032 2.032 0 0 1-.505.061 7.555 7.555 0 0 1-1.912-.031"
            fill="#263238"
          />
          <path
            data-name="Path 97014"
            d="M63.177 95.762a21.423 21.423 0 0 1-11.222-3.2s2.734 5.876 11.173 5.234Z"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97015"
            d="M59.244 69.135c-.151.3-1.285.013-2.658.014-1.373-.027-2.512.239-2.658-.064-.064-.144.161-.416.64-.668a4.622 4.622 0 0 1 4.05.038c.473.26.694.536.627.679"
            fill="#263238"
          />
          <path
            data-name="Path 97016"
            d="M69.717 68.208c-.209.26-1-.028-1.966-.057-.961-.063-1.781.147-1.963-.132-.08-.135.053-.4.413-.641a2.849 2.849 0 0 1 3.166.152c.335.277.443.551.35.678"
            fill="#263238"
          />
          <path
            data-name="Path 97017"
            d="M45.221 80.281s6 1.654 8.88-13.117c.984-5.051 5.5-9.263 5.5-9.263l-1.9-.5s-11.605 3.685-11.8 3.839-2.756 6.156-2.756 6.156Z"
            fill="#263238"
          />
          <path
            data-name="Path 97018"
            d="M46.667 79.687c.01-.627-.69-2.117-1.307-2.225-1.649-.286-4.631-.2-4.731 3.993-.137 5.739 5.652 4.692 5.667 4.528.011-.128.339-4.132.371-6.3"
            fill="#ffc09d"
          />
          <path
            data-name="Path 97019"
            d="M44.594 83.768c-.025-.019-.1.068-.272.141a1 1 0 0 1-.747 0 2.474 2.474 0 0 1-1.092-2.247 3.3 3.3 0 0 1 .314-1.425 1.153 1.153 0 0 1 .77-.749.509.509 0 0 1 .583.288c.073.162.034.276.064.286s.125-.093.085-.331a.637.637 0 0 0-.217-.366.756.756 0 0 0-.549-.159 1.407 1.407 0 0 0-1.065.865 3.393 3.393 0 0 0-.385 1.591 2.594 2.594 0 0 0 1.4 2.513 1.07 1.07 0 0 0 .918-.13c.181-.137.214-.265.195-.276"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97020"
            d="M49.158 77.348a8.4 8.4 0 0 0 .852-.2 5.175 5.175 0 0 0 2-1.251 7.077 7.077 0 0 0 1.023-1.281 9.581 9.581 0 0 0 .81-1.7 35.849 35.849 0 0 0 1.1-4.206c.353-1.508.743-2.933 1.181-4.2a23.97 23.97 0 0 1 1.387-3.285 17.17 17.17 0 0 1 1.246-2.055c.161-.234.3-.4.394-.522a1.322 1.322 0 0 1 .147-.174 1.272 1.272 0 0 1-.122.192c-.082.125-.216.3-.37.537a19.632 19.632 0 0 0-1.2 2.071 25.129 25.129 0 0 0-1.349 3.281c-.428 1.267-.813 2.688-1.165 4.192a34.453 34.453 0 0 1-1.114 4.22 9.573 9.573 0 0 1-.835 1.721 7.1 7.1 0 0 1-1.055 1.291 5.083 5.083 0 0 1-2.058 1.23 3.187 3.187 0 0 1-.87.144"
            fill="#263238"
          />
          <path
            data-name="Path 97021"
            d="M42.132 102.041a1.614 1.614 0 0 1-.071-.275l-.171-.81-.617-3.093.138-.024.116.673-.007-.021.128.253-.142.057a55.245 55.245 0 0 1-2.14-9.16 56 56 0 0 1-.547-5.949 27.318 27.318 0 0 1 .352-6.557 10.3 10.3 0 0 1 .956-2.634 14.2 14.2 0 0 1 1.511-2.258c1.12-1.384 2.337-2.582 3.445-3.769l5.951-6.252 4.028-4.2 1.11-1.13.292-.29a.558.558 0 0 1 .106-.093.593.593 0 0 1-.087.111l-.273.308-1.07 1.166-3.968 4.253-5.91 6.29c-1.1 1.191-2.318 2.4-3.421 3.763a11.31 11.31 0 0 0-2.413 4.789 27.38 27.38 0 0 0-.353 6.491 56.957 56.957 0 0 0 .522 5.929 57.729 57.729 0 0 0 2.056 9.147l-.142.058-.124-.255-.006-.011v-.011l-.106-.675.138-.024.553 3.1c.057.344.1.615.136.816a1.6 1.6 0 0 1 .034.282"
            fill="#455a64"
          />
          <path
            data-name="Path 97022"
            d="M42.199 87.405a2.36 2.36 0 1 0 2.281-2.436 2.36 2.36 0 0 0-2.281 2.436"
            fill="#455a64"
          />
          <path
            data-name="Path 97023"
            d="M76.845 112.404a2.71 2.71 0 0 1 .087.542l.167 1.562c.149 1.356.285 3.326.413 5.762.25 4.873.244 11.618 0 19.052s-.441 14.163-.375 19.034c.026 2.435.072 4.406.135 5.766l.05 1.569a2.654 2.654 0 0 1-.011.549 2.64 2.64 0 0 1-.074-.543l-.13-1.565c-.113-1.359-.205-3.332-.27-5.77-.144-4.877.009-11.618.251-19.051s.3-14.16.124-19.027c-.089-2.433-.179-4.4-.277-5.761l-.088-1.567a2.62 2.62 0 0 1 0-.549"
            fill="#263238"
          />
          <path
            data-name="Path 97024"
            d="m37.98 115.105 5.788 45.748 31.307 2.244 2.027 1.033-2.066-37.8-10.871-25.844-1.092-.665s-.911 7.639-3.853 7.511c-2-.088-9.58-3.685-11.246-4.8l-1.672-4.865Z"
            fill="#fff"
          />
          <path
            data-name="Path 97025"
            d="m31.322 169.616 6.658-15.269-6.449-48.871 11.227-4.706 3.678-3.968s3.413 12.607 4.479 17.023 4.113 14.125 5.473 22.828-.4 25.183-1.753 30.752-6.822 33.135-6.822 33.135H22.681s4.061-21.261 8.641-30.925"
            fill="#69f"
          />
          <path
            data-name="Path 97026"
            d="m63.177 97.108 2.663 2.714 9.921 3.488s2.922 11.444 3.038 14.969-.054 42.661.006 44.5 7.032 36.059 6.469 37.612h-6.8s-3.653-28.12-3.9-35.478-1.143-25.439-2.968-34.281-8.544-30.588-8.544-30.588Z"
            fill="#69f"
          />
          <path
            data-name="Path 97027"
            d="M74.877 169.018a.654.654 0 0 1-.02-.183l-.02-.537-.039-2.066-.077-7.6c-.06-6.414-.093-15.276-.254-25.059-.029-1.223-.051-2.431-.1-3.618-.032-1.178-.2-2.34-.327-3.49-.26-2.293-.516-4.5-.8-6.6s-.59-4.089-.966-5.93a25.419 25.419 0 0 0-1.594-4.956 64.885 64.885 0 0 0-3.438-6.753l-1.046-1.779-.273-.463a.681.681 0 0 1-.083-.164.629.629 0 0 1 .117.142l.306.442c.263.393.644.977 1.11 1.744a58.119 58.119 0 0 1 3.561 6.721 25.291 25.291 0 0 1 1.657 5c.394 1.852.713 3.846 1.006 5.948s.558 4.315.824 6.607c.128 1.144.3 2.309.335 3.516.051 1.195.075 2.4.1 3.628.162 9.789.15 18.65.14 25.066l-.048 7.6-.035 2.066-.019.537a.654.654 0 0 1-.02.183"
            fill="#263238"
          />
          <path
            data-name="Path 97028"
            d="M56.218 154.9a2.747 2.747 0 0 1-.1-.548 39.35 39.35 0 0 1-.187-1.585 71.676 71.676 0 0 1-.327-5.86c-.021-1.239-.012-2.6.039-4.055a17.624 17.624 0 0 0-.724-4.529 74.729 74.729 0 0 0-3.858-9.934c-1.59-3.448-3.226-6.68-4.74-9.608a39.532 39.532 0 0 1-2.028-4.232 36.192 36.192 0 0 1-1.129-3.913c-.572-2.418-.9-4.4-1.124-5.766a49.236 49.236 0 0 1-.225-1.58 2.816 2.816 0 0 1-.045-.555 3.084 3.084 0 0 1 .124.544l.3 1.567c.266 1.358.639 3.329 1.243 5.729a37.606 37.606 0 0 0 1.166 3.869 40.6 40.6 0 0 0 2.046 4.182c1.532 2.922 3.181 6.155 4.776 9.611a72.13 72.13 0 0 1 3.847 10 17.658 17.658 0 0 1 .7 4.63 88.505 88.505 0 0 0 .113 9.892l.114 1.589a3.26 3.26 0 0 1 .025.557"
            fill="#263238"
          />
          <path
            data-name="Path 97029"
            d="M77.411 110.256a2.717 2.717 0 0 1 .274.466c.176.308.4.78.678 1.394a28.067 28.067 0 0 1 1.7 5.441 41.4 41.4 0 0 1 .819 8.414c-.012 3.186-.26 6.675-.578 10.326-.677 7.3-1.1 13.915-1.246 18.714-.085 2.4-.133 4.341-.161 5.684l-.047 1.546a2.727 2.727 0 0 1-.043.539 2.806 2.806 0 0 1-.036-.54l-.028-1.547c-.019-1.343-.013-3.288.035-5.69.072-4.8.455-11.441 1.132-18.74.318-3.648.577-7.128.608-10.294a42.878 42.878 0 0 0-.729-8.358 30.732 30.732 0 0 0-1.565-5.438c-.255-.619-.455-1.1-.608-1.418a2.79 2.79 0 0 1-.2-.5"
            fill="#263238"
          />
          <path
            data-name="Path 97030"
            d="M58.673 84.053a3.378 3.378 0 0 0-.359 1.958 1.611 1.611 0 0 0 .394 1.06 1.37 1.37 0 0 0 1 .37 2.742 2.742 0 0 0 1.056-.273 3.741 3.741 0 0 0 1.427-1.826c-.923-.32-2.549-.982-3.521-1.29"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97031"
            d="M60.945 87.07a1.476 1.476 0 0 1-1.287.459 1.858 1.858 0 0 1-.7-.195 1.356 1.356 0 0 1-.59-.607 1.864 1.864 0 0 1-.193-.9 5.14 5.14 0 0 1 .106-.952 3.849 3.849 0 0 1 .269-.88l.052-.11.11.041c1 .373 1.879.713 2.5.966a4.748 4.748 0 0 1 .979.453 4.71 4.71 0 0 1-1.036-.286 94.528 94.528 0 0 1-2.525-.876l.162-.068a3.827 3.827 0 0 0-.241.814 2.789 2.789 0 0 0 .044 1.687 1.33 1.33 0 0 0 1.08.722 2.019 2.019 0 0 0 .935-.124 3.127 3.127 0 0 1 .332-.142"
            fill="#263238"
          />
          <path
            data-name="Path 97032"
            d="M260.322 200.539H96.56a8.093 8.093 0 0 1-8.093-8.093V72.286a8.093 8.093 0 0 1 8.093-8.093h163.762a8.093 8.093 0 0 1 8.093 8.093v120.162a8.093 8.093 0 0 1-8.093 8.093"
            fill="#fff"
          />
          <path
            data-name="Path 97033"
            d="M268.485 82.599H88.468V72.167a7.974 7.974 0 0 1 7.975-7.974h163.7a8.338 8.338 0 0 1 8.339 8.338Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 97034"
            d="M260.323 200.539s.135-.012.4-.027c.133-.011.3-.01.5-.036l.686-.108c.257-.06.543-.135.858-.224a8.724 8.724 0 0 0 1-.4 7.832 7.832 0 0 0 2.213-1.545 7.913 7.913 0 0 0 1.864-2.86l.165-.451c.048-.154.083-.315.125-.475a7.4 7.4 0 0 0 .186-1 19.522 19.522 0 0 0 .06-2.165c0-3.013-.008-6.489-.013-10.4l-.044-66.3q0-10.5-.009-21.967V74.885c-.011-1.008.022-2.033-.023-3.039a7.957 7.957 0 0 0-5.1-7 8.3 8.3 0 0 0-3.041-.536H244.021l-27.039.006-120.4.011a7.946 7.946 0 0 0-6.643 3.559 8.1 8.1 0 0 0-1.289 3.646c-.046.644-.039 1.308-.038 1.968v48.411c0 20.14-.005 39.567-.008 58.073V190.299a26.306 26.306 0 0 0 .1 3.368 7.932 7.932 0 0 0 6.47 6.621 20.315 20.315 0 0 0 3.27.131l25.506.007 45.782.017 66.292.044 18.023.025 4.687.014c1.056 0 1.6.014 1.6.014s-.541.01-1.6.014l-4.687.013-18.023.026-66.292.044-45.782.017-25.506.007a20.458 20.458 0 0 1-3.313-.133 8.178 8.178 0 0 1-6.673-6.823 26.492 26.492 0 0 1-.107-3.405V179.985c0-18.506-.005-37.933-.008-58.073V73.501c0-.66-.009-1.318.039-1.994a8.373 8.373 0 0 1 1.334-3.773 8.225 8.225 0 0 1 6.872-3.682l120.4.011 27.039.006H260.156a8.541 8.541 0 0 1 3.125.553 8.188 8.188 0 0 1 5.241 7.206c.044 1.031.012 2.038.021 3.051V92.575q0 11.458-.009 21.967l-.043 66.3c0 3.914-.009 7.39-.013 10.4a19.585 19.585 0 0 1-.067 2.174 7.591 7.591 0 0 1-.191 1.006c-.044.161-.079.323-.129.479l-.168.454a7.944 7.944 0 0 1-1.889 2.875 7.825 7.825 0 0 1-2.233 1.543 8.638 8.638 0 0 1-1 .4c-.316.087-.6.16-.862.218l-.689.1c-.2.024-.366.02-.5.029-.268.011-.4.013-.4.013"
            fill="#263238"
          />
          <path
            data-name="Path 97035"
            d="M100.269 74.5a2.108 2.108 0 1 1-2.108-2.108 2.108 2.108 0 0 1 2.108 2.108"
            fill="#fff"
          />
          <path
            data-name="Path 97036"
            d="M106.592 74.5a2.108 2.108 0 1 1-2.108-2.108 2.108 2.108 0 0 1 2.108 2.108"
            fill="#fff"
          />
          <path
            data-name="Path 97037"
            d="M112.916 74.5a2.108 2.108 0 1 1-2.108-2.108 2.108 2.108 0 0 1 2.108 2.108"
            fill="#fff"
          />
          <path
            data-name="Path 97038"
            d="M90.48 144.285s1.578-1.735-.978-2.231-15.528-.267-20.139 1.227l-6.258 1.063 1.1 10.282 3.908-.9 9.324 1.411c1.072.162 4.348-1.206 1.757-2.913 0 0 3.078-1.508.75-3.5 0 0 2.264-1.192.428-3.553Z"
            fill="#ffc09d"
          />
          <path
            data-name="Path 97039"
            d="m62.037 143.971 2.427-.2-.27 11.03-2.365.489Z"
            fill="#ebebeb"
          />
          <path
            data-name="Path 97040"
            d="M31.712 105.223s-5.922 2.037-7.151 10.16c-1.339 8.847 2.152 24.274 2.3 29.869.108 4.132 2.82 13.656 11.639 13.327a138.057 138.057 0 0 0 23.682-3.162l-.04-11.582-20.975.014-2.082-19.656"
            fill="#69f"
          />
          <path
            data-name="Path 97041"
            d="M39.081 124.198s.022.115.05.342l.121 1.008c.1.9.249 2.2.439 3.89l1.571 14.405-.1-.088 20.974-.044h.127v.127l.048 11.582v.109l-.105.023a141.562 141.562 0 0 1-16.958 2.706c-2.393.225-4.757.392-7.1.461a10.28 10.28 0 0 1-3.465-.544 9.549 9.549 0 0 1-3-1.719 13.563 13.563 0 0 1-3.743-5.507 18.367 18.367 0 0 1-1.252-6.271c-.126-2.064-.424-4.073-.686-6.023l-.785-5.677c-.48-3.667-.867-7.1-1-10.242a34.665 34.665 0 0 1 .472-8.471 7.249 7.249 0 0 1 .227-.9c.087-.29.16-.578.255-.854a15.173 15.173 0 0 1 .649-1.55 12.714 12.714 0 0 1 1.56-2.483 11.52 11.52 0 0 1 3.048-2.655 8 8 0 0 1 .938-.482 2.623 2.623 0 0 1 .337-.125s-.111.049-.325.151a9.2 9.2 0 0 0-.92.505 11.739 11.739 0 0 0-2.993 2.675 12.705 12.705 0 0 0-1.525 2.473 15.165 15.165 0 0 0-.631 1.539c-.092.273-.162.56-.248.847a7.334 7.334 0 0 0-.219.888 34.8 34.8 0 0 0-.427 8.429c.146 3.137.543 6.558 1.033 10.22l.8 5.673c.266 1.952.569 3.958.7 6.044a18.114 18.114 0 0 0 1.244 6.186 13.31 13.31 0 0 0 3.67 5.394 9.286 9.286 0 0 0 2.919 1.677 10.028 10.028 0 0 0 3.372.527 112.04 112.04 0 0 0 7.078-.461 142.742 142.742 0 0 0 16.923-2.691l-.1.133-.032-11.582.128.127-20.974-.016h-.095l-.009-.087-1.483-14.415c-.168-1.688-.3-3-.387-3.9l-.094-1.011c-.023-.237-.022-.345-.022-.345"
            fill="#263238"
          />
          <path
            data-name="Path 97042"
            d="M38.982 149.4a6.589 6.589 0 0 1 2.186-5.546 10.516 10.516 0 0 0-2.186 5.546"
            fill="#263238"
          />
          <path
            data-name="Path 97043"
            d="M36.822 118.164a11.192 11.192 0 0 1-.378-1.4 22.267 22.267 0 0 0-2.756-6.337 11.085 11.085 0 0 1-.763-1.228 5.578 5.578 0 0 1 .928 1.118 17.345 17.345 0 0 1 2.784 6.405 5.572 5.572 0 0 1 .184 1.442"
            fill="#263238"
          />
          <path
            data-name="Path 97044"
            d="M87.148 110.448c-.485-.171-.769-.116-1.088-.517a1.636 1.636 0 0 1-.331-1.445 1.172 1.172 0 0 1 1.144-.848 1.926 1.926 0 0 1 .651-3.477.768.768 0 0 1 .266-.041.3.3 0 0 1 .115.043c.168.095.316.216.493.3.066.032.133.06.2.087a3.551 3.551 0 0 0 .974.22 3.326 3.326 0 0 1 .471.032 1.764 1.764 0 0 1 .274.136 4.744 4.744 0 0 0 1.944.214 3.722 3.722 0 0 1 2.077.4 1.375 1.375 0 0 1 .526 1.844.937.937 0 0 1 1.008.723 1.357 1.357 0 0 1-.414 1.241 2.527 2.527 0 0 1-1.21.6 1.034 1.034 0 0 1 .978 1.052 1.588 1.588 0 0 1-.831 1.291 3.646 3.646 0 0 1-1.537.418c-.533.05-2.22.293-2.74.421l-1.965.026a1.586 1.586 0 0 1-1.513-2.195 1.01 1.01 0 0 1 .506-.529"
            fill="#ffc09d"
          />
          <path
            data-name="Path 97045"
            d="M87.148 102.296a1.536 1.536 0 0 1 .431-1.208 1.316 1.316 0 0 1 1.183-.357 10.175 10.175 0 0 0 .579 1.594 1.861 1.861 0 0 0 .214.309c.194.286.87.255 1.195.376a1.177 1.177 0 0 1 .6 1.5 1.083 1.083 0 0 1-1.146.769 3.611 3.611 0 0 1-2.04-.69 2.9 2.9 0 0 1-1.019-2.293"
            fill="#ffc09d"
          />
          <path
            data-name="Path 97046"
            d="M86.698 107.643c0 .051 1.831.038 4.086-.029s4.082-.162 4.081-.213-1.83-.038-4.086.029-4.082.162-4.08.213"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97047"
            d="M87.02 110.463a5.508 5.508 0 0 0 1.082.089c.67.023 1.6.02 2.62-.046s1.943-.182 2.6-.291a5.519 5.519 0 0 0 1.062-.228 7.5 7.5 0 0 0-1.079.1c-.664.076-1.582.17-2.6.236s-1.939.089-2.607.1a7.459 7.459 0 0 0-1.083.041"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97048"
            d="M87.983 113.269a5.634 5.634 0 0 0 .8-.018c.494-.027 1.178-.084 1.93-.164s1.432-.163 1.923-.224a6.955 6.955 0 0 0 .794-.116 6.219 6.219 0 0 0-.8.049c-.494.044-1.174.116-1.926.2s-1.429.147-1.922.192a7.829 7.829 0 0 0-.8.086"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97049"
            d="M87.416 103.183a1 1 0 0 0 .135.679 2.327 2.327 0 0 0 1.263 1.085 4.887 4.887 0 0 0 .886.251 3.586 3.586 0 0 0 .749.09.794.794 0 0 0 .491-.174c.1-.083.116-.153.107-.158a.886.886 0 0 1-.6.2 4.378 4.378 0 0 1-.713-.126 5.712 5.712 0 0 1-.852-.256 2.4 2.4 0 0 1-1.217-.976 4 4 0 0 1-.25-.617"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97050"
            d="M176.664 144.971c5.351 0 6.629-4.365 6.629-8.025a6.63 6.63 0 1 0-13.259 0c0 3.66 1.76 8.025 6.631 8.025"
            fill="#f5f5f5"
          />
          <path
            data-name="Path 97051"
            d="M177.587 145.893h-1.848c-3.633 0-9.083 3.329-9.083 7.438s-1.232 7.439 2.4 7.439h15.214c3.633 0 2.4-3.332 2.4-7.439s-5.45-7.438-9.084-7.438"
            fill="#f5f5f5"
          />
          <path
            data-name="Path 97052"
            d="M178.475 101.925a41.927 41.927 0 1 0 41.928 41.929 41.927 41.927 0 0 0-41.928-41.926m17.038 60.691v2.9a2.9 2.9 0 0 1-2.9 2.9h-29a5.8 5.8 0 0 1-5.8-5.8v-36.492a5.8 5.8 0 0 1 5.8-5.8h29a2.9 2.9 0 0 1 2.9 2.9Zm5.8-36.488v39.389a2.9 2.9 0 0 1-2.9 2.9h-.786a5.773 5.773 0 0 0 .785-2.9v-42.292a5.764 5.764 0 0 0-.785-2.9h.785a2.9 2.9 0 0 1 2.9 2.9Z"
            fill="#f5f5f5"
          />
          <path
            data-name="Path 97053"
            d="m195.006 48.509-8.651-31.688c-1.9-7.953-8.1-13.478-16-11.363l-.718.238a14.614 14.614 0 0 0-9.6 17.339c1.681 6.884 3.626 14.468 4.864 18.05 2.559 7.416 10.391 6.106 10.391 6.106.016 0 1.359 4.571 1.988 6.786l-.292 5.677 5.769-3.285Z"
            fill="#ae7461"
          />
          <path
            data-name="Path 97054"
            d="M175.287 47.19a18.952 18.952 0 0 0 8.746-6.079s-.477 6.371-8.13 8.163Z"
            fill="#6f4439"
          />
          <path
            data-name="Path 97055"
            d="M165.027 29.474a1.12 1.12 0 0 0 1.381.733 1.071 1.071 0 0 0 .778-1.322 1.12 1.12 0 0 0-1.38-.734 1.072 1.072 0 0 0-.779 1.323"
            fill="#263238"
          />
          <path
            data-name="Path 97056"
            d="M162.706 25.798c.178.095.786-.768 1.931-1.144 1.138-.393 2.173-.082 2.251-.263.04-.082-.177-.288-.631-.433a3.079 3.079 0 0 0-1.859.039 2.956 2.956 0 0 0-1.48 1.084c-.262.386-.3.677-.213.717"
            fill="#263238"
          />
          <path
            data-name="Path 97057"
            d="M174.626 26.374a1.12 1.12 0 0 0 1.381.733 1.071 1.071 0 0 0 .779-1.321 1.121 1.121 0 0 0-1.38-.734 1.072 1.072 0 0 0-.779 1.323"
            fill="#263238"
          />
          <path
            data-name="Path 97058"
            d="M174.011 22.346c.178.1.787-.768 1.931-1.144 1.138-.392 2.174-.082 2.251-.263.04-.082-.176-.288-.631-.433a3.073 3.073 0 0 0-1.859.039 2.958 2.958 0 0 0-1.48 1.084c-.261.386-.3.677-.213.717"
            fill="#263238"
          />
          <path
            data-name="Path 97059"
            d="M172.819 32.499a7.993 7.993 0 0 0-1.97.28c-.3.067-.6.1-.714-.083a1.572 1.572 0 0 1-.092-.931l.127-2.511a40.314 40.314 0 0 0 .012-6.463 40.35 40.35 0 0 0-.532 6.447c-.03.878-.06 1.713-.086 2.513a1.786 1.786 0 0 0 .229 1.2.772.772 0 0 0 .622.268 2.09 2.09 0 0 0 .52-.1 7.826 7.826 0 0 0 1.884-.622"
            fill="#263238"
          />
          <path
            data-name="Path 97060"
            d="M172.453 18.885c.252.241 1.261-.435 2.6-.911 1.333-.5 2.539-.641 2.576-.988.012-.163-.3-.351-.857-.43a4.795 4.795 0 0 0-3.945 1.447c-.372.418-.491.765-.376.882"
            fill="#263238"
          />
          <path
            data-name="Path 97061"
            d="M161.539 22.451c.321.186 1.172-.38 2.294-.747 1.111-.4 2.135-.479 2.268-.822.054-.162-.178-.377-.672-.493a3.8 3.8 0 0 0-2.005.161 3.734 3.734 0 0 0-1.67 1.1c-.313.394-.359.7-.214.8"
            fill="#263238"
          />
          <path
            data-name="Path 97062"
            d="M187.574 22.535c.108-.1 4.615-3.208 6.139 1.936s-3.955 5.614-4.008 5.469-2.131-7.405-2.131-7.405"
            fill="#ae7461"
          />
          <path
            data-name="Path 97063"
            d="M190.699 27.526c.017-.023.108.036.28.061a.937.937 0 0 0 .679-.182 2.314 2.314 0 0 0 .436-2.294 3.081 3.081 0 0 0-.631-1.209 1.078 1.078 0 0 0-.878-.488.475.475 0 0 0-.457.4c-.026.164.037.257.012.274s-.136-.053-.157-.278a.594.594 0 0 1 .107-.382.7.7 0 0 1 .457-.278 1.314 1.314 0 0 1 1.173.52 3.175 3.175 0 0 1 .735 1.342 2.426 2.426 0 0 1-.649 2.609 1 1 0 0 1-.86.107c-.2-.08-.258-.187-.243-.2"
            fill="#6f4439"
          />
          <path
            data-name="Path 97064"
            d="M176.662 32.176c-.18.04.15 1.226-.628 2.354s-2.077 1.352-2.04 1.521c0 .079.346.151.884.016a3.072 3.072 0 0 0 1.722-1.2 2.672 2.672 0 0 0 .45-1.952c-.094-.509-.308-.765-.388-.736"
            fill="#263238"
          />
          <path
            data-name="Path 97065"
            d="M147.679 19.227a1.273 1.273 0 0 1 .36-.882 3.918 3.918 0 0 1 1.83-.886 11.3 11.3 0 0 0 4.352-2.586 5.6 5.6 0 0 1-1.673-.558 3.367 3.367 0 0 1-1.886-2.932.746.746 0 0 1 .256-.612c.3-.2.694.039 1 .229a2.9 2.9 0 0 0 2.945.327 4.759 4.759 0 0 0 1.782-1.162c.015-.03.027-.061.043-.091.185-.355.368-.7.549-1.029a17.757 17.757 0 0 1 4.135-5.522A18.235 18.235 0 0 1 170.484 0a7.366 7.366 0 0 1 6.971 3.9 5.665 5.665 0 0 1 5.307-2.141l.039-.033c2.2.115 3.961 1.881 5.155 3.736.065.1.126.206.19.308a8.246 8.246 0 0 0 3.461 1.817c.226.054.487.126.577.34a.671.671 0 0 1-.184.632 4.145 4.145 0 0 1-1.411 1.15 9.274 9.274 0 0 0 1.078 1.2c.339.314.742.69.663 1.144a.876.876 0 0 1-.765.645 2.563 2.563 0 0 1-1.052-.143l1.142 1.4a4.919 4.919 0 0 1-2.209.5c.143 2.025.876 5.636.77 7.069a8.161 8.161 0 0 0-2.354.816c-.8.561.008 2.036-1.284 1.934-.658-.052-2.23-2.017-2.607-2.559a30.644 30.644 0 0 1-4.981-12.048 9.857 9.857 0 0 0-3.541-1.227l.113.054c-2.32-.1-4.912-.112-6.613 1.471-1.319 1.227-1.749 3.1-2.523 4.731a11.231 11.231 0 0 1-6.789 6.235c.382 2.358 1.4 6.1 1.925 8.247l-3.075-3.7v.018c-.22-.2-.415-.42-.607-.635l-.061-.061v-.01a13.828 13.828 0 0 0-.955-1.027l-.015.024a1.381 1.381 0 0 1-2.461-1.192 3.121 3.121 0 0 1 .448-.834 2.184 2.184 0 0 1-1.84 1.113 1.713 1.713 0 0 1-1.646-1.278 1.458 1.458 0 0 1 .5-1.369 4.923 4.923 0 0 1-2.86.209c-.619-.144-1.288-.486-1.307-1.206"
            fill="#263238"
          />
          <path
            data-name="Path 97066"
            d="M175.345 5.475a6.836 6.836 0 0 1 .961.465 5.632 5.632 0 0 1 1.9 2.154 9.891 9.891 0 0 1 .763 1.855c.21.692.376 1.451.556 2.249a20.708 20.708 0 0 0 .68 2.484 8.993 8.993 0 0 0 .558 1.251 2.828 2.828 0 0 0 .908 1.1 1.185 1.185 0 0 0 1.169.04 1.761 1.761 0 0 0 .795-.825 4.068 4.068 0 0 0 .152-2.123l-.152.066a6.585 6.585 0 0 0 1.386 1.3 2.342 2.342 0 0 0 1.7.36 1.51 1.51 0 0 0 .735-.368 1.308 1.308 0 0 0 .372-.677 1.34 1.34 0 0 0-.04-.715.977.977 0 0 0-.416-.513l-.059.1a5.056 5.056 0 0 0 1.978.73 4.6 4.6 0 0 0 .559.045.855.855 0 0 0 .193-.01 1 1 0 0 0-.192-.022c-.125-.013-.312-.03-.551-.074a5.215 5.215 0 0 1-1.928-.765l-.058.1a1.007 1.007 0 0 1 .384 1.1 1.175 1.175 0 0 1-.34.6 1.369 1.369 0 0 1-.669.324 2.19 2.19 0 0 1-1.585-.352 6.474 6.474 0 0 1-1.342-1.28l-.18-.208.028.275a3.933 3.933 0 0 1-.145 2.034 1.583 1.583 0 0 1-.711.738 1 1 0 0 1-.993-.033 2.679 2.679 0 0 1-.843-1.035 8.977 8.977 0 0 1-.549-1.225 21.029 21.029 0 0 1-.684-2.46c-.185-.8-.357-1.557-.576-2.256a9.852 9.852 0 0 0-.8-1.873 5.552 5.552 0 0 0-1.977-2.152 3.19 3.19 0 0 0-1-.4"
            fill="#455a64"
          />
          <path
            data-name="Path 97067"
            d="M154.252 19.746a.284.284 0 0 0 .076.033l.229.081a2.619 2.619 0 0 0 .38.11 4.247 4.247 0 0 0 .53.1 6.968 6.968 0 0 0 3.394-.382 8.9 8.9 0 0 0 4.041-3.033 15.417 15.417 0 0 0 2.559-5.661c.275-1.074.442-2.142.677-3.151a10.4 10.4 0 0 1 1-2.793 5.364 5.364 0 0 1 1.772-1.962 3.846 3.846 0 0 1 1.1-.473 4.231 4.231 0 0 1 1.107-.111 4.546 4.546 0 0 1 3.029 1.324 5.393 5.393 0 0 1 .725 1.013.248.248 0 0 0-.03-.077l-.1-.22a3.929 3.929 0 0 0-.54-.764 4.471 4.471 0 0 0-1.183-.892 4.6 4.6 0 0 0-1.892-.515 4.344 4.344 0 0 0-1.146.1 3.958 3.958 0 0 0-1.151.482 5.484 5.484 0 0 0-1.843 2.009 10.49 10.49 0 0 0-1.025 2.835c-.239 1.017-.406 2.083-.68 3.147a15.427 15.427 0 0 1-2.515 5.6 8.843 8.843 0 0 1-3.951 3.013 7.074 7.074 0 0 1-3.34.433 11.057 11.057 0 0 1-1.224-.257"
            fill="#455a64"
          />
          <path
            data-name="Path 97068"
            d="M152.58 16.644a1.192 1.192 0 0 0 .326.107 6.041 6.041 0 0 0 .92.167 8.1 8.1 0 0 0 1.389.038 8.54 8.54 0 0 0 3.278-.865 8.139 8.139 0 0 0 1.19-.719 6.027 6.027 0 0 0 .717-.6c.157-.156.24-.245.231-.254-.023-.025-.375.313-1.023.747a9.244 9.244 0 0 1-1.189.672 9.152 9.152 0 0 1-3.216.849 9.319 9.319 0 0 1-1.366 0c-.777-.059-1.25-.179-1.258-.145"
            fill="#455a64"
          />
          <path
            data-name="Path 97069"
            d="M155.375 64.222s-.765-2.164 1.833-5.078 18.938-9.064 18.938-9.064 6.061 5.9 12.92 2.171c5.338-2.9 4.925-7.457 4.925-7.457l20.764 3.476.706 15.689-7.439.043Z"
            fill="#69f"
          />
          <path
            data-name="Path 97070"
            d="m191.52 90.852 25.612-8.031-8.366-20.267 16.15.309s11.654 22.738 7.246 29.375c-4.015 6.045-37.07 7.264-37.07 7.264Z"
            fill="#ae7461"
          />
          <path
            data-name="Path 97071"
            d="m198.759 104.032-.108 1.382a2.366 2.366 0 0 1-3 1.748l-26.228-2.656.482-4.152 26.481.913c1.76-.014 2.5 1.179 2.375 2.766"
            fill="#455a64"
          />
          <path
            data-name="Path 97072"
            d="M159.663 115.92a16.108 16.108 0 1 1 9.745-11.047 16.023 16.023 0 0 1-9.745 11.047m-11.36-28.993a15.022 15.022 0 1 0 9.171-.582 15.027 15.027 0 0 0-9.171.582"
            fill="#263238"
          />
          <path
            data-name="Path 97073"
            d="M159.571 114.961a16.107 16.107 0 1 1 9.746-11.047 16.022 16.022 0 0 1-9.746 11.047m-11.36-28.993a15.023 15.023 0 1 0 9.171-.582 15.026 15.026 0 0 0-9.171.582"
            fill="#455a64"
          />
          <path
            data-name="Path 97074"
            d="M162.32 89.702a1.542 1.542 0 0 1-.216-.154c-.142-.1-.328-.276-.606-.452a11.55 11.55 0 0 0-2.448-1.3 14.4 14.4 0 0 0-1.827-.575 15.566 15.566 0 0 0-2.171-.344 14.928 14.928 0 0 0-2.428-.022 14.279 14.279 0 0 0-2.573.4 13.254 13.254 0 0 0-2.446.887 14.329 14.329 0 0 0-2.074 1.257 13.66 13.66 0 0 0-2.894 2.906 13.085 13.085 0 0 0-1.4 2.4c-.147.295-.222.539-.295.7a1.618 1.618 0 0 1-.109.242 1.455 1.455 0 0 1 .077-.254c.061-.162.128-.412.265-.712a12.227 12.227 0 0 1 1.361-2.441 13.47 13.47 0 0 1 2.9-2.962 14.2 14.2 0 0 1 2.095-1.282 13.269 13.269 0 0 1 2.478-.9 14.26 14.26 0 0 1 2.6-.4 14.739 14.739 0 0 1 2.454.033 15.357 15.357 0 0 1 2.191.363 14.133 14.133 0 0 1 1.839.6 10.862 10.862 0 0 1 2.441 1.36 6.9 6.9 0 0 1 .589.48 1.521 1.521 0 0 1 .194.181"
            fill="#fff"
          />
          <path
            data-name="Path 97075"
            d="M161.899 111.025c-.024-.034.653-.432 1.567-1.289l.353-.341c.122-.12.234-.258.359-.393.254-.266.487-.583.741-.909a12.508 12.508 0 0 0 2.171-4.873c.072-.407.152-.792.18-1.159.017-.183.044-.359.052-.53s.012-.333.018-.49c.025-1.253-.131-2.021-.09-2.027a3.338 3.338 0 0 1 .1.537 4.754 4.754 0 0 1 .074.639 7.766 7.766 0 0 1 .034.852q0 .238-.006.5c0 .173-.029.351-.043.536a11.5 11.5 0 0 1-.166 1.177 12.124 12.124 0 0 1-2.2 4.936 11.59 11.59 0 0 1-.763.91c-.129.135-.245.272-.37.39l-.364.337a7.861 7.861 0 0 1-.656.545 4.764 4.764 0 0 1-.524.372 3.3 3.3 0 0 1-.47.282"
            fill="#fff"
          />
          <path
            data-name="Path 97076"
            d="M160.879 95.913c0 .048-2.952.352-6.6.68s-6.614.556-6.618.508 2.952-.352 6.6-.68 6.614-.556 6.618-.508"
            fill="#fff"
          />
          <path
            data-name="Path 97077"
            d="M162.592 100.498a1.015 1.015 0 0 1-.212.025l-.611.036-2.244.112c-1.895.093-4.512.227-7.4.408s-5.5.374-7.4.519l-2.241.169-.61.04a1.028 1.028 0 0 1-.214 0 1 1 0 0 1 .211-.037l.607-.071c.528-.059 1.292-.136 2.237-.224 1.89-.176 4.5-.388 7.4-.57s5.51-.3 7.407-.358c.949-.031 1.717-.05 2.247-.057h.611a.981.981 0 0 1 .214.01"
            fill="#fff"
          />
          <g data-name="Group 102286" opacity={0.3}>
            <g data-name="Group 102285">
              <g
                data-name="Group 102284"
                clipPath="url(#b)"
                transform="translate(138.723 96.447)"
              >
                <path
                  data-name="Path 97078"
                  d="M.268 1.806 29.425 0a15.612 15.612 0 0 1 .56 4.409L.168 6.222a11.548 11.548 0 0 1 .093-4.416"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102289" opacity={0.3}>
            <g data-name="Group 102288">
              <g
                data-name="Group 102287"
                clipPath="url(#c)"
                transform="translate(139.747 103.649)"
              >
                <path
                  data-name="Path 97079"
                  d="m0 1.901 28.523-1.9s.19-.078-.392 1.177L.478 2.999A2.677 2.677 0 0 1 0 1.899"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <path
            data-name="Path 97080"
            d="M191.521 90.853s-14.688 4.263-15.107 4.5-6.2 4.359-6.313 5.847 1.841 8.145 1.841 8.145 1.982.284 2.128-3.273l.147-3.557 2.438-2.183s-.9 4.115-.672 5.277 2.159 4.1 3.241 3.87a3.146 3.146 0 0 0 1.674-.967s.946 2.621 3.706 1.079a4.379 4.379 0 0 0 3.227.039 8.881 8.881 0 0 0 2.7-3.014 1.232 1.232 0 0 0-1.383-1.35 5.648 5.648 0 0 0-2.834 1.108l-.139-1.492a17.183 17.183 0 0 1 3.618-3.269c1.16-.7 5.969-.809 7.463-2.328 1.266-1.288-1.125-4.127-2.712-4.988a2.741 2.741 0 0 0-.763-.277Z"
            fill="#ae7461"
          />
          <path
            data-name="Path 97081"
            d="M183.184 102.703a1.037 1.037 0 0 0-.161.261 4.759 4.759 0 0 0-.312.775 5.376 5.376 0 0 0 1.138 5.179 4.713 4.713 0 0 0 .609.573 1.054 1.054 0 0 0 .256.169c.018-.022-.337-.278-.782-.812a5.589 5.589 0 0 1-1.117-5.08c.18-.671.4-1.053.37-1.065"
            fill="#6f4439"
          />
          <path
            data-name="Path 97082"
            d="M180.421 100.602a1.361 1.361 0 0 0-.168.281 2.994 2.994 0 0 0-.167.346 5.064 5.064 0 0 0-.188.474 7.464 7.464 0 0 0-.329 1.286 7.871 7.871 0 0 0 .148 3.233 7.476 7.476 0 0 0 .444 1.251c.072.175.16.322.23.454a3.118 3.118 0 0 0 .2.33 1.349 1.349 0 0 0 .194.265c.026-.016-.233-.4-.522-1.092a8.322 8.322 0 0 1-.407-1.242 9.1 9.1 0 0 1-.226-1.582 10.734 10.734 0 0 1 .025-1.7 6.508 6.508 0 0 1 .348-1.166c.226-.711.448-1.122.421-1.135"
            fill="#6f4439"
          />
          <path
            data-name="Path 97083"
            d="M177.492 97.841c-.011-.013-.154.083-.328.3a2.385 2.385 0 0 0-.451.973 2 2 0 0 0 .017 1.073.874.874 0 0 0 .178.406 3.031 3.031 0 0 1 .583-2.751"
            fill="#6f4439"
          />
          <path
            data-name="Path 97084"
            d="M225.896 86.792c-.045.023-.3-.58-.951-1.392a7.974 7.974 0 0 0-3.186-2.4 6.8 6.8 0 0 0-3.951-.3c-1.017.209-1.618.468-1.635.423a1.37 1.37 0 0 1 .406-.215 7.67 7.67 0 0 1 1.187-.4 6.559 6.559 0 0 1 4.1.24 7.564 7.564 0 0 1 3.236 2.533 6.436 6.436 0 0 1 .641 1.077 1.343 1.343 0 0 1 .156.433"
            fill="#6f4439"
          />
          <path
            data-name="Path 97085"
            d="M205.133 57.278c-.254-8.556 10.9-12.362 15.9-5.415l.085.12c4.713 6.713 10.409 22.586 10.409 22.586l-20.178 3.133-5.036-13.776a25.666 25.666 0 0 1-1.18-6.649"
            fill="#69f"
          />
          <path
            data-name="Path 97086"
            d="M142.234 63.277c.032.072.062.145.089.22a3.86 3.86 0 0 1 .214 1.052 3.518 3.518 0 0 0 .023.507 1.938 1.938 0 0 0 .14.3 5.1 5.1 0 0 1 .185 2.095 4.013 4.013 0 0 0 .387 2.245 1.48 1.48 0 0 0 1.972.608 1.008 1.008 0 0 0 .754 1.1 1.457 1.457 0 0 0 1.343-.417 2.714 2.714 0 0 0 .673-1.287 1.113 1.113 0 0 0 1.109 1.076 1.715 1.715 0 0 0 1.409-.863 3.942 3.942 0 0 0 .485-1.643 19.563 19.563 0 0 1 .348-2.2s.723.116 1.052.148l.1.01a13.408 13.408 0 0 0 .692-1.2c.461-.948.812-1.549 1.164-2.41.264-.645.435-1.289.036-1.708-.96-1.01-2.837 1.044-2.837 1.044-.265-.715-2.447-.832-2.871.121-.172-.524-.107-.828-.532-1.181a1.758 1.758 0 0 0-1.546-.389 1.259 1.259 0 0 0-.938 1.211 2.071 2.071 0 0 0-3.755.619.825.825 0 0 0-.051.285.345.345 0 0 0 .044.125c.1.183.225.345.311.536"
            fill="#ae7461"
          />
          <path
            data-name="Path 97087"
            d="M139.931 61.886a1.651 1.651 0 0 0-1.309.435 2.705 2.705 0 0 0-.409 1.814c.658.124 1 .149 1.657.273.139.026.255-.009.37.073.3.215.254.942.377 1.293a1.267 1.267 0 0 0 1.6.684 1.7 1.7 0 0 0 1.026-1.514 2.625 2.625 0 0 0-.669-1.771 3.668 3.668 0 0 0-2.642-1.289"
            fill="#ae7461"
          />
          <path
            data-name="Path 97088"
            d="M145.694 61.524a21.8 21.8 0 0 1 0 4.4 21.783 21.783 0 0 1-.448 4.378 21.811 21.811 0 0 1 0-4.4 21.791 21.791 0 0 1 .448-4.377"
            fill="#6f4439"
          />
          <path
            data-name="Path 97089"
            d="M148.719 61.941a12.388 12.388 0 0 1 .085 3.993 12.4 12.4 0 0 1-.767 3.92 32.382 32.382 0 0 1 .322-3.959 32.559 32.559 0 0 1 .36-3.955"
            fill="#6f4439"
          />
          <path
            data-name="Path 97090"
            d="M151.676 62.347a9.276 9.276 0 0 1-.053 3.3 12.926 12.926 0 0 1-.625 3.233 11.654 11.654 0 0 1 .181-3.287 18.23 18.23 0 0 1 .5-3.245"
            fill="#6f4439"
          />
          <path
            data-name="Path 97091"
            d="M140.88 62.194c0-.053.316-.124.771.085a2.471 2.471 0 0 1 1.21 1.416 4.954 4.954 0 0 1 .3 1.812c-.054.545-.45.7-.459.633-.035-.05.179-.238.143-.643a8.9 8.9 0 0 0-.4-1.645 2.691 2.691 0 0 0-.949-1.3c-.343-.241-.621-.29-.615-.359"
            fill="#6f4439"
          />
          <path
            data-name="Path 97092"
            d="M214.938 48.857a22.977 22.977 0 0 0-2.168.907 10.821 10.821 0 0 0-5.88 9.035 22.97 22.97 0 0 0 .049 2.349 2.454 2.454 0 0 1-.143-.621 9.372 9.372 0 0 1-.1-1.737 10.52 10.52 0 0 1 5.987-9.2 9.356 9.356 0 0 1 1.629-.612 2.457 2.457 0 0 1 .626-.121"
            fill="#263238"
          />
        </g>
      </g>
      <path
        data-name="Path 97093"
        d="M286.651 200.413h58.008l-6.258-21.743-52.663-.21Z"
        fill="#263238"
      />
      <g data-name="Group 102302">
        <g data-name="Group 102301" clipPath="url(#a)">
          <path
            data-name="Path 97094"
            d="M299.777 200.581a2.042 2.042 0 0 1-.2-.507 28.133 28.133 0 0 1-.4-1.424c-.31-1.209-.677-2.892-1.062-4.754l-.982-4.765-.312-1.44a2.084 2.084 0 0 1-.093-.535 2.043 2.043 0 0 1 .2.507c.106.333.244.819.4 1.424.31 1.209.677 2.892 1.062 4.754l.982 4.765.312 1.44a2.1 2.1 0 0 1 .093.535"
            fill="#455a64"
          />
          <path
            data-name="Path 97095"
            d="M338.03 188.505c0 .008-.054.043-.168.1a5.472 5.472 0 0 1-.51.23 9.647 9.647 0 0 1-1.983.551 15.012 15.012 0 0 1-6.729-.539 37.52 37.52 0 0 1-6.229-2.648l-1.816-.946a3.991 3.991 0 0 1-.651-.377 3.945 3.945 0 0 1 .7.28c.442.2 1.074.5 1.855.868a43.909 43.909 0 0 0 6.221 2.558 15.712 15.712 0 0 0 6.621.611 16.693 16.693 0 0 0 2.692-.689"
            fill="#455a64"
          />
          <path
            data-name="Path 97096"
            d="M303.212 187.72a1.717 1.717 0 1 1-1.717-1.717 1.717 1.717 0 0 1 1.717 1.717"
            fill="#455a64"
          />
          <path
            data-name="Path 97097"
            d="M320.591 78.447a59.427 59.427 0 0 0 3.268-9.713 20.75 20.75 0 0 0-.1-10.155c-.146-.5-1.456-3.858-1.974-3.842-.37.011.365 3.111.156 3.417a17.592 17.592 0 0 0-2.69 10.095 46.334 46.334 0 0 0 1.345 10.2"
            fill="#263238"
          />
          <path
            data-name="Path 97098"
            d="M300.129 96.02c.143-2.412.249-4.316.23-4.317 0 0-8.211-1.414-8.18-9.645.015-3.975.713-12.158 1.43-19.557a15.339 15.339 0 0 1 15.517-13.858l.794.013c8.555.639 12.791 8.261 11.916 16.8l-2.111 29.947-6.292 6.516a7.747 7.747 0 0 1-13.3-5.894"
            fill="#ffc09d"
          />
          <path
            data-name="Path 97099"
            d="M300.36 91.704a19.885 19.885 0 0 0 10.768-2.995s-2.71 5.943-10.91 5.07Z"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97100"
            d="M295.56 69.758a1.175 1.175 0 0 0 1.114 1.2 1.125 1.125 0 0 0 1.229-1.041 1.176 1.176 0 0 0-1.113-1.2 1.125 1.125 0 0 0-1.23 1.041"
            fill="#263238"
          />
          <path
            data-name="Path 97101"
            d="M295.315 65.939c.143.156 1.044-.489 2.309-.465 1.264.006 2.182.671 2.321.518.069-.067-.075-.346-.475-.648a3.226 3.226 0 0 0-1.855-.6 3.1 3.1 0 0 0-1.841.562c-.394.292-.53.567-.459.637"
            fill="#263238"
          />
          <path
            data-name="Path 97102"
            d="M306.832 70.457a1.175 1.175 0 0 0 1.114 1.2 1.124 1.124 0 0 0 1.229-1.04 1.177 1.177 0 0 0-1.113-1.205 1.125 1.125 0 0 0-1.23 1.041"
            fill="#263238"
          />
          <path
            data-name="Path 97103"
            d="M307.083 66.576c.143.156 1.046-.489 2.31-.465 1.263.006 2.181.671 2.321.519.069-.068-.074-.346-.475-.648a3.23 3.23 0 0 0-1.855-.605 3.1 3.1 0 0 0-1.841.562c-.393.292-.53.567-.46.637"
            fill="#263238"
          />
          <path
            data-name="Path 97104"
            d="M303 76.297a8.363 8.363 0 0 0-2.048-.4c-.322-.038-.627-.107-.678-.329a1.642 1.642 0 0 1 .231-.954l.995-2.443a42.364 42.364 0 0 0 2.25-6.4 42.226 42.226 0 0 0-2.759 6.2c-.333.859-.652 1.676-.955 2.459a1.87 1.87 0 0 0-.188 1.265.808.808 0 0 0 .523.481 2.192 2.192 0 0 0 .548.085 8.24 8.24 0 0 0 2.082.037"
            fill="#263238"
          />
          <path
            data-name="Path 97105"
            d="M307.26 62.317c.176.321 1.4-.032 2.893-.08 1.493-.079 2.742.168 2.889-.166.065-.159-.191-.448-.72-.7a5.032 5.032 0 0 0-4.405.188c-.505.3-.735.609-.658.762"
            fill="#263238"
          />
          <path
            data-name="Path 97106"
            d="M295.762 61.521c.218.291 1.094.006 2.142.01 1.048-.034 1.931.224 2.141-.073.091-.144-.043-.434-.426-.713a3.1 3.1 0 0 0-3.451.051c-.375.289-.5.583-.406.725"
            fill="#263238"
          />
          <path
            data-name="Path 97107"
            d="M321.066 71.534c.141-.059 5.682-1.581 5.411 4.043s-5.861 4.192-5.863 4.03.452-8.073.452-8.073"
            fill="#ffc09d"
          />
          <path
            data-name="Path 97108"
            d="M322.435 77.564c.025-.017.095.073.257.157a.981.981 0 0 0 .732.05 2.426 2.426 0 0 0 1.226-2.121 3.245 3.245 0 0 0-.206-1.416 1.135 1.135 0 0 0-.7-.788.5.5 0 0 0-.591.241c-.084.153-.053.268-.084.276s-.116-.1-.059-.33a.626.626 0 0 1 .238-.342.742.742 0 0 1 .549-.117 1.379 1.379 0 0 1 .982.921 3.332 3.332 0 0 1 .264 1.584 2.546 2.546 0 0 1-1.546 2.36 1.051 1.051 0 0 1-.889-.192c-.167-.147-.191-.274-.171-.284"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97109"
            d="M297.779 53.39c-.881-.554 2.017-3.344 4.31-4.232 3.467-1.34 8.68-1.99 11.745-.929 3.691 1.278 6.7 3.321 8.388 6.843a22.137 22.137 0 0 1 1.838 11.43 6.726 6.726 0 0 1-.988 3.389c-.856 1.2-2.407 1.895-2.439 3.613a2.355 2.355 0 0 1-.682 1.682c-1.091 1.036-2.29-.064-2.81-1.128-1.11-2.272-1.1-4.909-1.063-7.437a16.463 16.463 0 0 1 .66-5.326c.329-.921.821-1.781 1.1-2.72a3.38 3.38 0 0 0-.238-2.829 3.527 3.527 0 0 0-.43-.488 5.872 5.872 0 0 0-5.753-1.438c-3.229.94-8.731 2.3-10.081 1.149a8.676 8.676 0 0 1-2.371-2.7Z"
            fill="#263238"
          />
          <path
            data-name="Path 97110"
            d="M303.229 80.669c-.006.1.278.225.766.211a3.35 3.35 0 0 0 2.9-2.143c.157-.462.119-.771.023-.795-.215-.053-.608.942-1.62 1.666-.988.755-2.055.84-2.067 1.061"
            fill="#263238"
          />
          <path
            data-name="Path 97111"
            d="M251.442 122.328c-1.152-1.893-2.519-3.958-2.519-3.958l-1.116-1.9a2.421 2.421 0 0 0-.254.362 1.653 1.653 0 0 1-.534.631 1.315 1.315 0 0 1-1.449-.351 3.214 3.214 0 0 1-.97-3.028 3.174 3.174 0 0 1 2.046-2.261 2.32 2.32 0 0 1 .581-.7c1.605-1.3 4.478-.417 4.478-.417l-.3-.719s-1.713-1.693-5.5-.368a2.464 2.464 0 0 1-1.749-.023 1.256 1.256 0 0 1-.77-.742c-.3-.928.28-1.376 2.631-2.236s4.9-.911 6.314.01a16.677 16.677 0 0 1 1.9 1.372s-2.311-6.642-2.44-7.068c-.374-1.233-.136-2.429.663-2.753.513-.208 1.314-.092 1.793 1.331.416 1.23 6.275 13.308 6.275 13.308l2.279 5.493 1.144 4.836-8.23 2.319-.6-.2a6.884 6.884 0 0 1-3.683-2.942"
            fill="#ffc09d"
          />
          <path
            data-name="Path 97112"
            d="M261.556 118.218a1.842 1.842 0 0 1-2.11-.347c-.587-.494-.99-1.169-1.539-1.7-1.644-1.6-4.353-1.724-5.919-3.4-.219-.233-.415-.589-.234-.849.168-.241.529-.213.819-.158l7.453 1.4"
            fill="#ffc09d"
          />
          <path
            data-name="Path 97113"
            d="M260.027 113.165a3.064 3.064 0 0 0-.791-.234 83.704 83.704 0 0 0-2.186-.48l-3.251-.658-.941-.184a1.5 1.5 0 0 0-1.134.048.63.63 0 0 0-.231.674 1.311 1.311 0 0 0 .3.543 5 5 0 0 0 .849.73 29.344 29.344 0 0 0 3.7 1.736 5.773 5.773 0 0 1 1.426.946c.389.365.7.783 1.02 1.138a2.691 2.691 0 0 0 1.986 1.083c.57-.016.8-.285.78-.289a2.2 2.2 0 0 1-.775.122 2.607 2.607 0 0 1-1.761-1.114c-.3-.354-.592-.787-1-1.2a5.948 5.948 0 0 0-1.5-1.044 34.132 34.132 0 0 1-3.636-1.735 4.709 4.709 0 0 1-.77-.656c-.212-.234-.29-.516-.141-.6.148-.112.491-.048.81.015l.943.17 3.268.567c.932.156 1.686.273 2.21.345a3.047 3.047 0 0 0 .821.069"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97114"
            d="M251.805 110.715a3.144 3.144 0 0 0-.872-1.137 2.434 2.434 0 0 0-1.443-.458 6.176 6.176 0 0 0-1.889.335 9.83 9.83 0 0 1-1.937.457 3.2 3.2 0 0 1-1.636-.236 1.248 1.248 0 0 1-.709-.86.724.724 0 0 1 0-.407c.027 0 .028.144.1.379a1.179 1.179 0 0 0 .687.71 3.177 3.177 0 0 0 1.527.16 10.9 10.9 0 0 0 1.882-.468 6 6 0 0 1 1.976-.324 2.555 2.555 0 0 1 1.559.559 2.273 2.273 0 0 1 .661.9.779.779 0 0 1 .089.4"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97115"
            d="M251.659 100.46a7.89 7.89 0 0 1 .468 1.318l1.046 3.2 1.094 3.183a7.854 7.854 0 0 1 .421 1.333 6.27 6.27 0 0 1-.6-1.264c-.333-.8-.755-1.916-1.174-3.165s-.755-2.395-.971-3.231a6.246 6.246 0 0 1-.279-1.371"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97116"
            d="M250.811 112.712a1.7 1.7 0 0 1-.438-.022 1.3 1.3 0 0 0-1.041.289.686.686 0 0 0-.157.667 3.658 3.658 0 0 0 .354.84 1.571 1.571 0 0 1 .2 1.095 1.322 1.322 0 0 1-.837.862 1.526 1.526 0 0 1-1.2-.107 1.922 1.922 0 0 1-.807-.763 6.154 6.154 0 0 1-.551-1.712 3.581 3.581 0 0 1-.032-1.208.8.8 0 0 1 .16-.413 3.94 3.94 0 0 1-.053.427 4.212 4.212 0 0 0 .119 1.157 6.552 6.552 0 0 0 .581 1.625 1.513 1.513 0 0 0 1.682.733 1.06 1.06 0 0 0 .669-.674 1.337 1.337 0 0 0-.164-.913 3.688 3.688 0 0 1-.349-.908.892.892 0 0 1 .246-.847 1.355 1.355 0 0 1 1.192-.256 1.151 1.151 0 0 1 .425.13"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97117"
            d="M247.732 116.096c.012-.006.1.123.09.388a1.2 1.2 0 0 1-.542.938 1.627 1.627 0 0 1-1.612.031 2.68 2.68 0 0 1-1.245-1.537 3.194 3.194 0 0 1-.033-1.965 3.037 3.037 0 0 1 .866-1.345 2.883 2.883 0 0 1 .915-.563.826.826 0 0 1 .384-.086 5 5 0 0 0-1.162.791 2.986 2.986 0 0 0-.756 1.277 3.041 3.041 0 0 0 .051 1.8 2.49 2.49 0 0 0 1.1 1.4 1.447 1.447 0 0 0 1.389.028 1.14 1.14 0 0 0 .539-.783c.038-.234-.006-.371.017-.377"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97118"
            d="M253.829 119.033a1.137 1.137 0 0 1 .64-.2 2.656 2.656 0 0 1 1.672.381l-.2.139a7.786 7.786 0 0 1-.153-.936 5.949 5.949 0 0 1 .432-3.232 15.1 15.1 0 0 0-.154 3.205 9.5 9.5 0 0 0 .138.908l.062.311-.264-.172a2.789 2.789 0 0 0-1.518-.438c-.4 0-.64.07-.65.034"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97119"
            d="M249.861 107.742c.074.011-.022.573-.523 1.016s-1.069.482-1.071.408c-.018-.079.453-.223.883-.616s.633-.835.71-.809"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97120"
            d="M298.033 97.738s-4.188.506-4.645.534c-3.533.217-9.3 2.3-12 7.36-1.975 3.688-5.736 18.7-6.744 22.761l-1.458 4.78-8.824-14-12.545 7.155s13.034 31.535 21.47 31.532 12.349-8.422 12.349-8.422l-.13-18.966Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 97121"
            d="M282.204 110.918a1.679 1.679 0 0 0-.036.425l-.023 1.218c-.015 1.058 0 2.589.032 4.48.069 3.782.3 9 .621 14.763s.6 10.975.741 14.751l.162 4.473.053 1.217a1.694 1.694 0 0 0 .046.424 1.642 1.642 0 0 0 .036-.425l.024-1.218c.015-1.057 0-2.588-.032-4.479-.069-3.782-.3-9-.621-14.765s-.6-10.972-.741-14.749l-.162-4.473-.053-1.217a1.675 1.675 0 0 0-.047-.424"
            fill="#263238"
          />
          <path
            data-name="Path 97122"
            d="M347.551 113.31c-2.142-9.583-12.734-12.244-12.734-12.244l-3.6 34.59 2.23 5.614 18.952-4.092s-2.162-11.851-4.849-23.869"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 97123"
            d="m330.141 132.837 7.082 6.825-2.289-13.054 14.375-4.547s3.817 17.915 4.482 22.153c1.191 7.6-.271 18.411-7.357 19.171-7.665.823-13.453-6.737-17.136-11.7-2.832-3.814-7.4-10.482-7.4-10.482Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 97124"
            d="m337.225 155.958-.558-16.25.847-37.655-15.1-6.865-2-5.908-16.882 7.522-3.386-3.548-1.955 3.8-6.014 1.36-4.909 13.891-4.165 10.938s1.2 37.934 2.238 51.848c.136 1.825-2.238 2.619.395 4.654 0 0 3.952 2.32 12.518 2.543 24.375.636 39.349-3.256 40.145-3.613a5.018 5.018 0 0 0 1.483-4.2c-.073-.575-.193-1.2-.3-1.98Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 97125"
            d="M297.442 183.419a.707.707 0 0 1-.031-.208 148.85 148.85 0 0 1-.046-.614l-.133-2.362-.385-8.691c-.273-7.341-.621-17.49-.506-28.71.068-5.611.257-10.963.794-15.809.559-4.841 1.391-9.145 2.141-12.742s1.458-6.485 1.944-8.479l.576-2.293.161-.593a.73.73 0 0 1 .07-.2.7.7 0 0 1-.025.209l-.117.6-.492 2.313c-.432 2.007-1.081 4.907-1.8 8.505s-1.506 7.9-2.039 12.722c-.511 4.819-.687 10.162-.755 15.763-.115 11.2.181 21.351.372 28.694l.24 8.7.047 2.366v.616a.743.743 0 0 1-.015.211"
            fill="#263238"
          />
          <path
            data-name="Path 97126"
            d="M308.552 120.291a7.683 7.683 0 0 1 1 .03l2.918.175 10.632.727.2.014-.015.2-1.283 17-.008.113-.09.066c-.5.361-1.036.726-1.589 1.085a31.13 31.13 0 0 1-2.989 1.733 8.8 8.8 0 0 1-3.241 1.043 6.455 6.455 0 0 1-3.214-.744 14.079 14.079 0 0 1-2.6-1.738c-.39-.323-.767-.652-1.126-.989a2.186 2.186 0 0 1-.5-.609 3.228 3.228 0 0 1-.283-.714 10.937 10.937 0 0 1-.261-2.8 40.451 40.451 0 0 1 .449-4.965 90.374 90.374 0 0 1 1.335-7.071c.19-.827.35-1.467.466-1.9a3.361 3.361 0 0 1 .2-.652 3.725 3.725 0 0 1-.107.674c-.1.486-.22 1.123-.375 1.917-.316 1.665-.786 4.077-1.178 7.078a41.491 41.491 0 0 0-.386 4.92 10.649 10.649 0 0 0 .269 2.685 2.081 2.081 0 0 0 .671 1.114q.535.494 1.112.964a13.711 13.711 0 0 0 2.521 1.668 6 6 0 0 0 2.978.685 8.4 8.4 0 0 0 3.051-1 31.32 31.32 0 0 0 2.94-1.707 37.49 37.49 0 0 0 1.565-1.069l-.1.179 1.368-16.991.186.217-10.5-.921-2.95-.283-.786-.086a1.478 1.478 0 0 1-.281-.046"
            fill="#263238"
          />
          <path
            data-name="Path 97127"
            d="M322.973 125.832a121.119 121.119 0 0 1-15.373-1.2 121.089 121.089 0 0 1 15.373 1.2"
            fill="#263238"
          />
          <g data-name="Group 102294" opacity={0.3}>
            <g data-name="Group 102293">
              <g
                data-name="Group 102292"
                clipPath="url(#d)"
                transform="translate(306.865 138.351)"
              >
                <path
                  data-name="Path 97128"
                  d="M0 0c.831 1.367 1.6 3.245 2.737 4.373a6.7 6.7 0 0 0 4.2 2.091 6.892 6.892 0 0 0 3.99-1.185A10.533 10.533 0 0 0 14.899.067c.121-.154-2.758 1.768-4.322 2.6a7.047 7.047 0 0 1-4.426.95C3.835 3.429 1.759 1.517.002 0"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 102297" opacity={0.3}>
            <g data-name="Group 102296">
              <g
                data-name="Group 102295"
                clipPath="url(#e)"
                transform="translate(303.82 95.748)"
              >
                <path
                  data-name="Path 97129"
                  d="m0 2.146.617 7.915L17.42.005 1.211 8.558Z"
                />
              </g>
            </g>
          </g>
          <path
            data-name="Path 97130"
            d="M300.286 107.426a270.9 270.9 0 0 0-6.15 40.149c-.709 9.38-1.235 21.811-1.235 21.811l5.443 6.742 6.506-6.458s-1.324-26.982-1.3-36.024c.023-7.28-.272-26.069-.272-26.069Z"
            fill="#69f"
          />
          <path
            data-name="Path 97131"
            d="M332.794 119.169a4.464 4.464 0 0 1 .171.752l.373 2.064 1.172 6.82c.464 2.663.86 5.08 1.119 6.832.131.876.232 1.586.295 2.077a4.453 4.453 0 0 1 .073.768 4.427 4.427 0 0 1-.171-.752c-.1-.532-.223-1.227-.375-2.064l-1.193-6.817c-.46-2.664-.838-5.082-1.1-6.834-.119-.843-.217-1.542-.294-2.077a4.479 4.479 0 0 1-.073-.768"
            fill="#263238"
          />
          <path
            data-name="Path 97132"
            d="M338.261 103.144c.028.036-.5.446-1.243 1.213a15.037 15.037 0 0 0-3.9 7.251c-.229 1.044-.28 1.709-.326 1.706a1.636 1.636 0 0 1 0-.47 9.662 9.662 0 0 1 .153-1.271 13.557 13.557 0 0 1 3.947-7.344 9.678 9.678 0 0 1 .976-.829 1.658 1.658 0 0 1 .394-.256"
            fill="#263238"
          />
          <path
            data-name="Path 97133"
            d="m303.68 100.46-1.687-.373-1.639 2.952-.069 4.387 3 .151 1.857-3.478Z"
            fill="#69f"
          />
          <path
            data-name="Path 97134"
            d="M302.264 108.323c-.038.06-.455-.136-.932-.437s-.833-.594-.8-.653.455.136.932.438.833.593.8.653"
            fill="#263238"
          />
          <path
            data-name="Path 97135"
            d="M302.654 106.99a24.187 24.187 0 0 1-2-4.544 24.144 24.144 0 0 1 2 4.544"
            fill="#263238"
          />
          <g data-name="Group 102300" opacity={0.3}>
            <g data-name="Group 102299">
              <g
                data-name="Group 102298"
                clipPath="url(#f)"
                transform="translate(321.626 144.097)"
              >
                <path
                  data-name="Path 97136"
                  d="M1.839 2.41a40.336 40.336 0 0 1 5.867 6.942c1.225 2.138 4.691 4.993 8.6 7.493l.609 4.281c-2.168-.431-5.972-3.249-7.44-4.9a31.527 31.527 0 0 1-4.095-5.309C3.404 7.384.69 3.989-.004.001Z"
                />
              </g>
            </g>
          </g>
          <path
            data-name="Path 97137"
            d="M337.387 160.508a2.184 2.184 0 0 0-.365-.279c-.241-.179-.621-.408-1.075-.757a40.158 40.158 0 0 1-3.69-3.216 108.486 108.486 0 0 1-10.794-12.72l.023.194 2.4-5.805 2.873-6.993-.311.076 16.9 14.3a1.527 1.527 0 0 0-.28-.326c-.2-.2-.5-.487-.9-.85-.8-.73-1.969-1.774-3.451-3.071-2.964-2.6-7.157-6.21-12.015-10.351l-.207-.176-.1.252-2.886 6.987-2.388 5.809-.043.1.066.089a100.334 100.334 0 0 0 10.939 12.672 34.891 34.891 0 0 0 3.787 3.142c.47.334.865.545 1.119.705a2.206 2.206 0 0 0 .4.217"
            fill="#263238"
          />
          <path
            data-name="Path 97138"
            d="m280.137 118.581 5.911 22.33a2.777 2.777 0 0 0 2.739 2.066l30.609-.6a2.777 2.777 0 0 0 2.667-3.352l-4.508-21.386a2.777 2.777 0 0 0-2.717-2.2h-32.281a2.5 2.5 0 0 0-2.421 3.145"
            fill="#263238"
          />
          <path
            data-name="Path 97139"
            d="M320.427 140.997a.61.61 0 0 1-.045-.145l-.106-.432-.383-1.666-1.356-6.137-1.977-9.109-1.159-5.381a1.245 1.245 0 0 0-.789-.967 1.7 1.7 0 0 0-.673-.077h-3.734l-20.737-.041-6.285-.04-1.709-.026-.444-.013a.076.076 0 1 1 0-.029l.444-.013 1.709-.026 6.284-.044 20.737-.04h3.734a1.957 1.957 0 0 1 .768.1 1.52 1.52 0 0 1 .958 1.165l1.143 5.384 1.919 9.121 1.271 6.155.332 1.676.079.437a.619.619 0 0 1 .018.151"
            fill="#455a64"
          />
          <path
            data-name="Path 97140"
            d="M301.048 123.013a1.591 1.591 0 1 1-2.081-1.065 1.656 1.656 0 0 1 2.081 1.065"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 97141"
            d="m327.605 132.521-1.454-1.358s-.964-3.206-1.572-3.813a6.722 6.722 0 0 0-2.237-1.06l-3.655-3.357.666 2.994-5.3-.039a1.508 1.508 0 0 0-1.444.926.6.6 0 0 0-.045.29 1.938 1.938 0 0 0 1.06 1.389l.762.359a1.8 1.8 0 0 0-1.25 1.3 1.66 1.66 0 0 0 1.1 1.788 1.468 1.468 0 0 0-.554 2.146s-1.213 1.438-.512 2.412 4.724 2.6 4.724 2.6l2.23 1.013a3.776 3.776 0 0 0 1.672.094l.871.122.93.771s5.321-2.906 4.007-8.582"
            fill="#ffc09d"
          />
          <path
            data-name="Path 97142"
            d="M321.891 130.222a4.017 4.017 0 0 1-1.205.011 22.588 22.588 0 0 1-5.68-1.147 4.021 4.021 0 0 1-1.106-.477c.031-.09 1.768.481 3.972.921s4.024.6 4.018.692"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97143"
            d="M323.348 134.402a70.093 70.093 0 0 1-9.109-2.459 69.866 69.866 0 0 1 9.109 2.459"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97144"
            d="M322.92 137.526a5.668 5.668 0 0 1-1.47-.19 23.574 23.574 0 0 1-6.687-2.479 5.657 5.657 0 0 1-1.238-.814 8.566 8.566 0 0 1 1.343.614c.817.4 1.959.913 3.256 1.395s2.5.832 3.378 1.064a8.622 8.622 0 0 1 1.418.41"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97145"
            d="M323.912 133.485c-.088-.031.083-.623-.044-1.34s-.466-1.225-.394-1.282c.053-.065.575.411.708 1.23a1.574 1.574 0 0 1-.27 1.392"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97146"
            d="M323.972 129.637c-.06-.068.215-.395.257-.888.06-.491-.143-.868-.071-.921.033-.025.139.042.24.212a1.284 1.284 0 0 1-.148 1.438c-.133.145-.252.189-.278.159"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97147"
            d="M323.611 137.34c-.068-.061.14-.389.266-.833s.136-.829.226-.844.234.413.079.934-.514.805-.571.743"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97148"
            d="M315.417 125.918a133.194 133.194 0 0 0 6.208.437 13.163 13.163 0 0 0-6.208-.437"
            fill="#ff9b6c"
          />
          <path
            data-name="Path 97149"
            d="M298.501 97.78a2.1 2.1 0 0 1 .15.369c.1.271.222.628.38 1.075l1.353 3.981-.179-.016 3.151-5.94.162-.306.077.339 1.491 6.575.081.358-.2-.092 11.816-6.188 3.583-1.841.979-.488a2.043 2.043 0 0 1 .35-.154 2.009 2.009 0 0 1-.325.2l-.955.533-3.543 1.919-11.777 6.261-.159.084-.04-.176-.081-.358-1.474-6.579.24.033-3.205 5.911-.111.2-.069-.219-1.269-4.008-.331-1.091a2.174 2.174 0 0 1-.1-.386"
            fill="#263238"
          />
        </g>
      </g>
    </g>
  </svg>
)
