import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../../utils";
export const useStyles = makeStyles((theme) => ({
    viewbox: {
        backgroundColor: "#27AFCF",
        padding: "10px",
        borderRadius: theme.palette.borderRadius
    },
    viewTitle: {
        fontSize: "14px",
        color: "white",
        fontFamily: SemiBold
    },
    borderBox:{
        border:"1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius

    },
    headingact:{
        fontSize:"10px",
        fontFamily:SemiBold,
        color:theme.typography.color.tertiary,
        textTransform:"uppercase"
    },
    dot:{
        width:"5px",
        height:"5px",
        backgroundColor:"#E4E8EE",
        borderRadius:"50%",
    },
    closebtn:{
        "&:hover": {
           backgroundColor:"white"
        },
    },
    name:{
        fontSize:"14px",
        fontFamily:Bold,
        color:theme.typography.color.primary,
    },
    mobileNo:{
        fontSize:"12px",
        fontFamily:SemiBold,
        color:theme.typography.color.secondary,
    },
    layoutSwitch: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px"
    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "13px",
    },
    cardblock: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #E4E8EE",
        padding: "10px"
    },
    selectedBtn: {
        width: "32px",
        background: theme.palette.primary.main,
        borderRadius: theme.palette.borderRadius,
        color: "#fff",
        height: "32px",
        margin: "2px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    notSelectedBtn: {
        width: "32px",
        borderRadius: theme.palette.borderRadius,
        color: theme.typography.color.tertiary,
        height: "32px",
        margin: "2px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    categoryText: {
        fontSize: "12px",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold
    },
    togglebtntype: {
        border: "1px solid #E4E8EE",
        fontSize: "12px",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        padding: "10px"
    },
    selectedtoggle: {
        fontSize: "12px",
        color: "#FFFFFF",
        fontFamily: SemiBold,
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        padding: "10px",
        backgroundColor: "#5078E1"
    },
    content:{
        height:'calc(100vh - 450px)',
        overflow:"auto"
    }
}))
export const MonthlyCalendarStyles = makeStyles((theme) => ({
    // calendar dates styles
    event_label: {
        padding: 2,
        fontSize: 12,
        fontWeight: "bold",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "150px"

    },
    calDates: {
        textAlign: "center",
        overflow: "auto",
        height: `calc(100vh - 280px)`,

    },
    date: {
        color: theme.typography.color.secondary,
        fontSize: 18,
        fontWeight: "bold"
    },
    date_outline: {
        color: "black",
        border: `1px solid ${theme.palette.border.secondary}`,
        height: 130,
    },
    hidden_other_month_dates: {
        visibility: "hidden"
    },
    eventlabel: {
        overflow: "hidden",
        width: "98%",
    },
    eventtxt: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontSize: 12,
        // fontFamily: `${SemiBold}`,
        color: "#6DAFB3",
        width: "150px"
    },
    moreLabel: {
        float: "left",
        fontSize: 12,
        fontFamily:Bold,
        color: theme.palette.info.main,
        cursor:"pointer"
    },
    days: {
        fontSize: 12,
        textTransform: "uppercase",
        color: theme.typography.color.tertiary,
        // fontFamily: `${SemiBold}`,
        textAlign: "center"
    }
}))
export const weeklyCalendarStyle = makeStyles((theme) => ({
    cells: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        border: `1px solid ${theme.palette.border.secondary}`,
        alignItems: "center",
    },
    hovercells: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        border: `1px solid ${theme.palette.border.secondary}`,
    },
    dayLabel: {
        fontSize: 14,
        textTransform: "uppercase",
        color: theme.typography.color.tertiary,
        // fontFamily: `${SemiBold}`,

    },
    dateLabel: {
        fontSize: 16,
        fontWeight: "bold",
        textTransform: "uppercase",
        color: theme.typography.color.secondary
    },
    listTitle: {
        color: theme.typography.color.tertiary,
        fontSize: 26,
    },
    calender: {
        zIndex: "0"
    },
    calenderRows: {
        height: `calc(100vh - 320px)`,
        overflow: "auto",
        width: "100%",
    },
    cell_hover: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.main,
        height: 80,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        cursor: "pointer"
    },
    header_timecell: {
        height: 40,
        fontSize: 12,
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        alignItems: 'end',
        fontSize: 12,
        color: theme.typography.color.tertiary,
    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        height: 100,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%"
    },
    event_title: {
        padding: 2,
        fontSize: 12,
        fontWeight: "bold",
    },
    event_label: {
        padding: 2,
        fontSize: 12,
        fontWeight: "bold",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "120px"

    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
        color: "#E4E8EE"
    },
    calRow: {
        height: "calc(100vh - 250px)",
        overflow: "auto",
        width: "100%",
    },
    moreLabel: {
        fontSize: 12,
        color: theme.palette.info.main,
        fontWeight: "bold",
        marginLeft: 8,
        cursor: "pointer"
    },
    customTooltip: {
        // '& .MuiTooltip-arrow':{
        //     backgroundColor:"#fff",
        //     color:"#fff",
        //     border: '1px solid #dadde9'
        // }
    },
    ttavatar: {
        height: 24,
        width: 24,
    },
    ttTitle: {
        fontSize: 18,
        fontWeight: "bold",
        color: theme.typography.color.primary
    },
    ttsecTitle: {
        fontSize: 14,
        color: theme.typography.color.secondary,
    },
    ttassigned_by: {
        fontSize: 12,
        color: theme.typography.color.tertiary
    },
    ttname: {
        fontSize: 16,
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    }
}))
export const eventListStyle = makeStyles((theme) => ({
    header_timecell: {
        height: 40,
        fontSize: 12,
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        alignItems: 'end',
        fontSize: 12,
        color: theme.typography.color.tertiary,
    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        height: 100,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%"
    },
    event_title: {
        padding: 2,
        fontSize: 12,
        fontWeight: "bold",
    },
    event_label: {
        padding: 2,
        fontSize: 12,
        fontWeight: "bold",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "150px"
    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
    },
    calRow: {
        height: "calc(100vh - 250px)",
        overflow: "auto",
        width: "100%",
    },
    moreLabel: {
        fontSize: 12,
        color: theme.palette.info.main,
        fontWeight: "bold",
        cursor: "pointer"
    },
    customTooltip: {
        // '& .MuiTooltip-arrow':{
        //     backgroundColor:"#fff",
        //     color:"#fff",
        //     border: '1px solid #dadde9'
        // }
    },
    ttavatar: {
        height: 24,
        width: 24,
    },
    ttTitle: {
        fontSize: 18,
        fontWeight: "bold",
        color: theme.typography.color.primary
    },
    ttsecTitle: {
        fontSize: 14,
        color: theme.typography.color.secondary,
    },
    ttassigned_by: {
        fontSize: 12,
        color: theme.typography.color.tertiary
    },
    ttname: {
        fontSize: 16,
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    }
}))
export const DailyCalendarStyle = makeStyles((theme) => ({
    header_timecell: {
        height: 40,
        fontSize: 12,
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        borderLeft: `0.5px solid ${theme.palette.border.secondary}`,
        borderBottom: `0.5px solid ${theme.palette.border.secondary}`,
        borderTop: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        fontSize: 12,
        color: theme.typography.color.tertiary,
        borderLeft: `0.5px solid ${theme.palette.border.secondary}`,
        borderBottom: `0.5px solid ${theme.palette.border.secondary}`,

    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        height: 100,
        borderLeft: `1px solid ${theme.palette.border.secondary}`,
        borderRight: `1px solid ${theme.palette.border.secondary}`,
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        //overflow: "auto"
    },
    event_label: {
        padding: 2,
        fontSize: 12,
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
    },
    calRow: {
        height: "calc(100vh - 200px)",
        overflow: "auto",
        width: "100%",

    },
    moreLabel: {
        fontSize: 12,
        color: theme.palette.info.main,
        fontWeight: "bold",
        marginLeft: 8,
        cursor: "pointer"
    },
    customTooltip: {
        // '& .MuiTooltip-arrow':{
        //     backgroundColor:"#fff",
        //     color:"#fff",
        //     border: '1px solid #dadde9'
        // }
        '& .MuiTooltip-arrow': {
            background: 'transparent',
        },
        "&:before": {
            border: "1px solid #E6E8ED"
        },

    },
    ttavatar: {
        height: 24,
        width: 24,
    },
    ttTitle: {
        fontSize: 14,
        fontWeight: "bold",
        color: theme.typography.color.primary,
        textTransform: "capitalize"
    },
    ttsecTitle: {
        fontSize: 12,
        textTransform: "capitalize",
        color: theme.typography.color.secondary,
    },
    ttassigned_by: {
        fontSize: 10,
        color: theme.typography.color.tertiary
    },
    ttname: {
        fontSize: 16,
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    },
    arrow: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: "white",
        position: "absolute",
        top: "0px",
        left: "0px",
        fontSize: "40px"
    },
    toolRoot: {
        border: '1px solid #E4E8EE',
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        borderRadius: "4px",

    },
    contentbox:{
        position:"relative",
        borderRadius:theme.palette.borderRadius
    },
    rowOverflow:{
        height:"85px",
        width:"100%",
        // position:"relative",
        overflow:"auto",
        top:"0",
        left:"0",
        right:"0"
    },
    closebtn:{
        "&:hover": {
           backgroundColor:"white"
        },
    },
    name:{
        fontSize:"14px",
        fontFamily:Bold,
        color:theme.typography.color.primary,
    },
    mobileNo:{
        fontSize:"12px",
        fontFamily:SemiBold,
        color:theme.typography.color.secondary,
    },
    statushdr:{
        fontSize:"12px",
        fontFamily:SemiBold,
        color:"white",
    },
    statusbox:{
        backgroundColor:"#5AC782",
        padding:"5px",
        width:"50px",
        borderRadius:theme.palette.borderRadius,
        textAlign:"center"
    },
    overflow:{
        height:"100%",
        overflow:"auto"
    }
}))

export const LeadCardStyle = makeStyles((theme)=>({
    title:{
        fontSize:14,
        fontFamily:Regular
    },
    subTitle:{
        fontSize:12,
        fontFamily:Regular,
        color:theme.typography.color.tertiary
    },
    avatar:{
        height:35,
        width:35,
        borderRadius:4
    }
}))