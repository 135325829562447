import {
    Box, Grid
} from "@mui/material";
import React from "react";
import { FormGenerator } from "../../../components";
import { addDaysWithLess, billGenENUM, enumSelect, enum_types } from "../../../utils";
import { Billing_cycle_date, Billing_cycle_method, quotation_validity, validateCustom, validatePaymentperoid } from "../../../utils/companySettings";
import { LeadDetailsCard } from "./index";
import { useStyles } from "./styles";
import { getDateValue } from "../../propertyFinder4/utils";

export const LeaseForm = ({ data, updateState, type = "", purpose = "", setData = () => false, hideUnit = false, hidepopover = true, hidedate = false }) => {
    const classes = useStyles();
    const [enumValue, setEnum] = React.useState({
        unit_usage: [],
        priority: [],
        source: [],
        lease_type: [],
        billGenCycle: [],
        billing_cycle_date: [],
        payment_period: [],
        apply_grace_period_type: [],
        payment_mode: [],
        turnover_type: [],
        auto_renewal_escalation: []
    })

    const marketplace = [
        {
            label: "Yes",
            value: true,
        },
        {
            label: "No",
            value: false,
        }
    ]

    const getEnum = async () => {
        const result = await enumSelect([enum_types.urgent_type, enum_types.l_period, enum_types.lead_source, enum_types.billing_cycle, enum_types.billing_cycle_date, enum_types.unit_payment_period, enum_types.apply_grace_period_type, enum_types.payment_mode, enum_types?.turnover_type, enum_types?.auto_renewal_escalation])
        setEnum({
            priority: result?.urgent_type,
            lease_type: result?.l_period?.filter(val => val?.value !== "Quarterly").filter(val => val?.value !== "Daily"),
            billGenCycle: result?.billing_cycle,
            billing_cycle_date: result?.billing_cycle_date?.filter(i => i?.value !== billGenENUM.last_day).filter(i => i?.value !== billGenENUM.mid_date).filter(i => i?.value !== billGenENUM.first),
            payment_period:
                validatePaymentperoid(result?.payment_value_type.filter(val => val?.value !== "On Completion").filter(val => val?.value !== "Milestone Based").filter(val => val?.value !== "Hourly").filter(val => val?.value !== "Weekly"), data?.duration?.value, data?.duration?.select),
            source: result?.lead_source,
            apply_grace_period_type: result?.apply_grace_period_type,
            payment_mode: result?.payment_mode,
            turnover_type: result?.turnover_type,
            auto_renewal_escalation: result?.auto_renewal_escalation
        })

    }
    React.useEffect(() => {
        getEnum()
        // eslint-disable-next-line
    }, [])
    
  //on change grace peroid value
  const onChangeGracePeroidValue = (value) => {
    let error = data.error;
    error.grace_peroid = "";
    setData({
      ...data,
      grace_peroid: value,
      error,
    });
  };

    //on change start end date
    const onChangestartendDate = async (value) => {
        let error = data?.error;
        error.lease_start_date = "";
        setData({
          ...data,
          lease_start_date: value,
          billing_start_date: value,
          error,
        });
      };

      //on change lease duration
  const onChangeDuration = (value) => {
    console.log(value,"value")
    let error = data?.error;
    error["duration"] = "";
    setData({
      ...data,
      duration: value,
      payment_period: validatePaymentperoid(
        enumValue?.payment_period,
        value?.value,
        value?.select
      )?.[0],
      error,
    });
  };

  const onChangeGracePeroid = (value) => {
    let apply_grace_period = value === true ? "In the End" : "In the Beginning";
    setData({
      ...data,
      apply_grace_peroid: apply_grace_period,
    });
  };


  React.useMemo(() => {
    if (data?.lease_start_date !== null && data?.duration?.value) {
      let end_date = getDateValue(
        {
          date: data?.lease_start_date ?? "",
          input: data?.duration?.value ?? "",
          period: data?.duration?.select ?? "",
        },
        true,
        data?.grace_peroid ?? 0,
        true
      );
      setData({
        ...data,
        lease_end_date: end_date ?? null,
        billing_start_date:
          data?.apply_grace_peroid === "In the Beginning"
            ? (data?.grace_peroid !== null && data?.grace_peroid?.length > 0)
              ? addDaysWithLess(data?.lease_start_date, data?.grace_peroid ?? 0)
              : data?.lease_start_date
            : data?.lease_start_date ?? null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.lease_start_date,
    data?.duration,
    data?.grace_peroid,
    data?.apply_grace_peroid,
  ]);

    const formLease1 = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 9,
                lg: 9
            },
            isActive: true,
            component: "text",
            label: "Opportunity Subject",
            value: data?.oppertunity_subject,
            placeholder: "Opportunity Subject",
            onChange: (value) => updateState("oppertunity_subject", value.target.value),
            error: data?.error?.oppertunity_subject,
            isRequired: true,
            options: [],
            // multiline: true
        },

        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: true,
            component: "priority",
            label: "Priority",
            value: data?.priority,
            placeholder: "Priority",
            onChange: (value) => updateState("priority", value),
            error: data?.error?.priority,
            isRequired: true,
            options: enumValue?.priority
        },
        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: true,
            component: "date",
            label: data?.revenue_type?.value === "Lease" ? "Lease Start Date" : "Manage Start Date",
            value: data?.lease_start_date,
            placeholder: data?.revenue_type?.value === "Lease" ? "Lease Start Date" : "Manage Start Date",
            isReadonly: true,
            onChange: (value) => onChangestartendDate(value),
        },
        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: true,
            component: "duration",
            label: data?.revenue_type?.value === "Lease" ? "Lease Duration" : "Manage Duartion",
            value: data?.duration,
            durationValue: data?.duration.select,
            placeholder: data?.revenue_type?.value === "Lease" ? "Lease Duartion" : "Manage Duration",
            isRequired: true,
            onChange: (value) => onChangeDuration(value),
            options: enumValue?.lease_type?.filter((x)=>x?.value !== "Weekly")
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 3,
                lg: 2.55,
            },
            isActive: true,
            component: "toggle",
            label: "Grace Period",
            value: data?.grace_peroid,
            placeholder: "Grace Period",
            onChange: (value) => onChangeGracePeroidValue(value),
            error: data?.error?.grace_peroid,
            isRequired: true,
            options: quotation_validity,
            isMulti: true,
            openCustom: validateCustom([null], data?.grace_peroid),
            customValue: 0,
            custom: true,
            applyGracePeriod: data?.grace_peroid ? true : false,
            applyGracePeriodValue: data?.apply_grace_peroid === "In the Beginning" ? false : true,
            OnApplyGracePeriod: (value) => onChangeGracePeroid(value),
            // isReadonly:false,
        },
        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: true,
            component: "date",
            label: data?.revenue_type?.value === "Lease" ? "Lease End Date" : "Manage End Date",
            value: data?.lease_end_date,
            placeholder: data?.revenue_type?.value === "Lease" ? "Lease End Date" : "Manage End Date",
            isReadonly: true,
            isRequired: true
        },
        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: true,
            component: "date",
            label: "Billing Start Date",
            value: data?.billing_start_date,
            placeholder: "Billing Start Date",
            onChange: (value) => updateState("billing_start_date", value),
            error: data?.error?.billing_start_date,
            isRequired: true,
            // isReadonly: data?.shortlist_convert ? true : false,
            isReadonly: true,
        },
        {
            size: {
                xs: 4,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: data?.payment_period?.value === "Prepaid" ? false : true,
            component: "toggle",
            label: "Billing Cycle Method",
            value: data?.bill_cycle_method,
            placeholder: "Billing Cycle Method",
            onChange: (value) => updateState("bill_cycle_method", value),
            error: data?.error?.bill_cycle_method,
            isRequired: true,
            options: Billing_cycle_method,
            isMulti: true,
            isReadonly:false

        },
        {
            size: {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 4
            },
            isActive: data?.payment_period?.value === "Prepaid" ? false : true,
            component: "toggle",
            label: "Billing Cycle Date",
            value: data?.bill_cycle_date,
            placeholder: "Billing Cycle Date",
            onChange: (value) => updateState("bill_cycle_date", value),
            error: data?.error?.bill_cycle_date,
            isRequired: true,
            options: Billing_cycle_date,
            isMulti: true,
            billingCycleDate: data?.custom_date,
            onDateChange: (e) => updateState("custom_date", e),
            custom: true,
            selectBillingCycle: true,

        },
        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: true,
            component: "select",
            label: "Renewal Based on",
            value: data?.renewal_based_on,
            placeholder: "Renewal Based on",
            onChange: (value) => updateState("renewal_based_on", value),
            error: data?.error?.renewal_based_on,
            isRequired: true,
            options: enumValue?.auto_renewal_escalation,
            aysnc: true,
            menuPlacement: "top"

        },
        {
            size: {
              xs: 12,
              sm: 6,
              md: 6,
              lg: 3,
            },
            isActive:
              data?.renewal_based_on?.value === "Escalation %" ||
                data?.renewal_based_on?.value === "Increment %"
                ? true
                : false,
            component: "text",
            label: "Increment/Escalation Percentage",
            value: data?.esclation_percentage ?? "",
            placeholder: "Increment/Escalation Percentage",
            onChange: (value) =>
              updateState("esclation_percentage", value.target.value),
            error: data?.error?.esclation_percentage,
            isRequired: true,
            options: marketplace,
          },
        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: true,
            component: "select",
            label: "Payment Period",
            value: data?.payment_period,
            placeholder: "Payment Period",
            onChange: (value) => updateState("payment_period", value),
            error: data?.error?.payment_period,
            isRequired: true,
            options: enumValue?.payment_period,
            menuPlacement: "top"
        },
        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: true,
            component: "select",
            label: "Payment Options",
            value: data?.payment_option,
            placeholder: "Payment Options",
            onChange: (value) => updateState("payment_option", value),
            error: data?.error?.payment_option,
            isRequired: true,
            options: enumValue?.payment_mode,
            menuPlacement: "top"
        },


        {
            size: {
                xs: 12,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: true,
            component: "toggle",
            label: "Auto Renewals",
            value: data?.auto_renewal,
            placeholder: "Auto Renewals",
            onChange: (value) => updateState("auto_renewal", value),
            error: data?.error?.auto_renewal,
            isRequired: true,
            options: marketplace
        },
    ]
    return (
        <Box p={1}>
            <Grid container className={classes.leadDetailsRoot}>
                <Grid item xs={3} p={1} >
                    <Box p={2} className={classes.leadDetailsRootDivider}>
                        <LeadDetailsCard
                            data={{
                                name: data?.name,
                                image: data?.image_url?.length !== 0 ? data?.image_url?.src : null,
                                email: data?.email_id,
                                mobile: ` ${data.mobile?.mobile_code ?? ""} ${data.mobile?.mobile}`,
                                no: null,
                                type: type,
                                purpose: purpose,
                                source: data?.source?.value,
                                units: data?.units
                            }}
                            source_enum={enumValue?.source ?? ""}
                            dataNew={data}
                            setDataNew={setData}
                            updateState={updateState}
                        />
                        <Box height="18px" />
                    </Box>
                </Grid>
                <Grid item xs={9} p={1}>
                    <Box p={2} className={classes.leadDetailsRootDivider}>
                        <FormGenerator components={formLease1} />
                    </Box>
                </Grid>
            </Grid>
        </Box>


    );
};
