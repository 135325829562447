import React from 'react';
import { Avatar, Box, Typography } from "@mui/material";
import { LeadStylesParent } from '../style';

export const AllocatedCard = (props) => {
      // styles
      const classes = LeadStylesParent();
      return (
            <div className={classes.allocatedroot}>
                  <div style={{ padding: '16px', display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '10px' }}>
                              <Avatar src={props?.viewDetails?.member?.image_url ?? ""} />
                        </div>
                        <div>
                              <div style={{flexFlow:'wrap', display: 'flex', alignItems: 'center'  }}> <Typography className={classes.profileTilte}>{props?.viewDetails?.member?.first_name ?? ""} {props?.viewDetails?.member?.last_name ?? ""}</Typography></div>
                              <Box style={{ flexFlow:'wrap',display: 'flex', alignItems: 'center' }}>
                                    <Box> <Typography className={classes.leadcontact}>Agent</Typography></Box>
                                    <Box> <Typography className={classes.leadcontact} noWrap><Box className={classes.dot} />{props?.viewDetails?.member?.mobile_no_country_code ?? ""} {props?.viewDetails?.member?.mobile_no ?? ""}</Typography></Box>
                                    <Box> <Typography className={classes.leadcontact} noWrap><Box className={classes.dot} />{props?.viewDetails?.member?.email_id ?? ""}</Typography></Box>
                              </Box>
                        </div>
                  </div>
            </div>
      )
}
