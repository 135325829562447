import React, { useState } from "react";
import { Subheader } from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { Dashboard } from "./dashboard";
import { accessCheckRender, getCalendarOptions, getCompanyOption, getRoutePermissionNew, pageName } from "../../utils";

export const VisitorandSecurityDashboard = (props) => {
  const { loading, handleLoading } = props;
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [calandervalue, setCalanderValue] = useState({
    value: {
      from_date: new Date(),
      to_date: new Date(),
    },
    label: "Today",
  });

  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [permission, setPermission] = React.useState({});

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
          setCompanyList(company?.list)
          setSelectedCompany(company?.selected)
          handleLoading(false)
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);
  // tab title

  // company switcher
  const handleCompanyChange = (e) => {
    setSelectedCompany(e);
  };
  // company switcher
  const handleCalander = (e) => {
    setCalanderValue(e);
  };

  const render = () => {
    return (
      <>
        {/* sub header */}
        <Subheader
          hideBackButton={true}
          title="Visitor and Security Dashboard"
          select
          calanderselect
          calendarOptions={getCalendarOptions()}
          options={companyList ?? []}
          value={selectedCompany}
          calanderValue={calandervalue}
          onchange={(e) => {
            handleCompanyChange(e);
          }}
          onChangeCalendar={(e) => {
            handleCalander(e);
          }}
        />
        <Dashboard company={selectedCompany} calandervalue={calandervalue} />
      </>
    );
  };

  return <div>{accessCheckRender(render, permission, pageName.dashboard, loading)}</div>;
};
