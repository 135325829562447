import { Box, Stack } from '@mui/material'
import React from 'react'
import { AlertDialog, CalendarDateChange, LayoutSwitch } from '../../../components'
import { AmenityDropdown, CourtDropdown } from '../components'
import { CalendarHeaderStyle } from './style'
import { ViewCourt } from '../viewCourt'
import { CalendarDropDown } from '../components'

export const CalendarHeader = (
    {
        selected = {},
        weekprev = () => false,
        weeknext = () => false,
        monthnext = () => false,
        monthprev = () => false,
        month = "",
        weekdates = {},
        setDropdownValue = () => false,
        setselect = () => false,
        select = {},
        showAll = () => false,
        open = {},
        daily = "",
        nextdate = () => false,
        prevdate = () => false,
        amenityCategoryList = [],
        selectedAmenityCategory = {},
        handleAmenityCategoryChange = () => false,
        amenityList = [],
        selectedAmenity = {},
        selectedAmenityView = {},
        handleAmenityChange = () => false,
        handleAmenityViewChange = () => false,
    }
) => {
    const classes = CalendarHeaderStyle()


    // POP up functions

    const [amenity, setAmenity] = React.useState(false)
    const openAmenity = () => {
        setAmenity(true)
    }

    const [court, setCourt] = React.useState(false)
    const openCourt = () => {
        setCourt(true)
    }
    return (
        <Box>
            <Stack className={classes.header} direction={"row"} spacing={5}>
                <AmenityDropdown
                    openAmenity={openAmenity}
                    amenityCategoryList={amenityCategoryList}
                    selectedAmenityCategory={selectedAmenityCategory}
                    handleAmenityCategoryChange={handleAmenityCategoryChange} />
                {amenityList?.length > 0 &&
                    <CourtDropdown
                        openCourt={openCourt}
                        amenityList={amenityList}
                        selectedAmenity={selectedAmenity}
                        selectedAmenityView={selectedAmenityView}
                        handleAmenityChange={handleAmenityChange}
                        handleAmenityViewChange={handleAmenityViewChange} />
                }
                <Box sx={{ position: "relative", top: "50%", left: "45%", transform: "translate(-21%,-27%)" }}>
                    {
                        selected === "Weekly" &&
                        <CalendarDateChange state={weekdates} prev={weekprev} next={weeknext} comp={"weekly"} />
                    }
                    {
                        selected === "Monthly" &&
                        <CalendarDateChange state={{Date:month}} prev={monthprev} next={monthnext} comp={"month"} />
                    }
                    {
                        selected === "Daily" &&
                        <CalendarDateChange state={{Date:daily}} prev={prevdate} next={nextdate} comp={"daily"} />
                    }
                </Box>
                {/* <CalendarDropdown open={open} selected={selected} showAll={showAll} setDropdownValue={setDropdownValue}
                    rightalign="100px" /> */}
                <CalendarDropDown  open={open} selected={selected} showAll={showAll} setDropdownValue={setDropdownValue} />
                <LayoutSwitch setselect={setselect} select={select} />
            </Stack>

            {/* Amenity popup */}
            <AlertDialog open={amenity}
                onClose={() => setAmenity(!amenity)}
                header={"Badminton"}
                component={<ViewCourt type="amenity" />}
                isNormal
            />

            {/* Court popup */}
            <AlertDialog open={court}
                onClose={() => setCourt(!court)}
                header={selectedAmenityView?.label}
                component={
                    <ViewCourt
                        type="court"
                        selectedAmenityView={selectedAmenityView} />
                }
                isNormal
            />

        </Box>
    )
}