import {
    Avatar,
    Box,
    Button,
    Grid, Typography
} from "@mui/material";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { LoadingSection } from "../../../components";
import { config } from "../../../config";
import { AlertContext, BackdropContext } from "../../../contexts";
import {
    GET_GENERAL_REQUEST,
    GET_MAINTANCE_REQUEST
} from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import {
    AlertProps,
    assestType, NetWorkCallMethods, SemiBold, useWindowDimensions
} from "../../../utils";
import { CustomTypographyWorker } from "../../maintenancerequest/common";
import { AllocatedCard } from "../../maintenancerequest/components/profilecard";
import { useStyles } from "../../maintenancerequest/style";
import { AssetView } from "../../propertyFinder3/component/assetView";
import { Bold } from "../../../utils";

export const ViewServiceRequest = (props) => {
    const classes = useStyles();
    const size = useWindowDimensions();
    // context
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    // props
    const { request, CloseDialog, value, permission = {}} = props;
    // state
    const [loading, setLoading] = React.useState(true)
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [datas, setDatas] = useState({
        data: {},
        imagesListForView: [],
    });
    // get details
    const getDetails = () => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        const data = {
            query:
                request === "Maintenance" ? GET_MAINTANCE_REQUEST : GET_GENERAL_REQUEST,
            variables: {
                generalRequestNo: value?.id,
                maintenanceRequestNo: value?.id,
                referenceId: value?.id,
            },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                setLoading(false)
                const tempImageList = response?.data?.data?.images?.map(
                    ({ url }) => url
                );
                setDatas({
                    data: response.data.data ?? {},
                    imagesListForView: tempImageList,
                });
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            })
            .catch((err) => {
                setLoading(false)
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something went wrong please try again",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            });
    };
    // open image viwer
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    // close image viwer
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    // track
    const track = [
        {
            date: datas?.data?.table?.[0]?.status?.[0]?.created_at,
            label: "Open",
            is_active: true,
        },
        {
            date: datas?.data?.table?.[0]?.status?.[0]?.closed_on,
            label: "Closed",
            is_active:
                datas?.data?.table?.[0]?.status?.[0]?.type === "Closed" ? true : false,
        },
    ];

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        getDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    // edit click
    const editFunction = () => {
        props?.handleIcon("edit", datas?.data?.table?.[0]);
        CloseDialog("close");
    };
    return (
        <>
            {/* body */}
            {
                loading ?
                    <LoadingSection top="20vh" message={"Fetching Details"} />
                    :
                    <div>
                        <div style={{ padding: "20px" }}>
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={7}
                                    borderRight="1px solid #E4E8EE"
                                >
                                    <Grid container spacing={3} p={1}>
                                        {datas?.data?.images?.length > 0 && (
                                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                                <AssetView
                                                    asset={datas?.data?.images
                                                        ?.filter((x) => x?.asset_type !== assestType?.Videos)
                                                        ?.filter((x) => x?.asset_type !== assestType?.Documents)
                                                        ?.map((x) => x)}
                                                    assetAll={datas?.data?.images}
                                                    openImageViewer={openImageViewer}
                                                    isNormal
                                                    maxWidth={"sm"}
                                                />

                                                {isViewerOpen && (
                                                    <ImageViewer
                                                        src={datas?.imagesListForView}
                                                        currentIndex={currentImage}
                                                        onClose={closeImageViewer}
                                                        disableScroll={false}
                                                        backgroundStyle={{
                                                            backgroundColor: "rgba(0,0,0,0.9)",
                                                        }}
                                                        closeOnClickOutside={true}
                                                    />
                                                )}
                                            </Grid>
                                        )}
                                        {/* contaent */}

                                    </Grid>
                                    {/* details */}
                                    <Box className={classes.detailsBox}>
                                        <Grid item xs={12} md={12} sm={12} lg={12}>
                                            <Typography className={classes.title}>
                                                {datas?.data?.table?.[0]?.subject}
                                            </Typography>
                                            <Box className={classes.catagory}>
                                                <Box className={classes.service}>
                                                    <Typography className={classes.servicetittle}>
                                                        {datas?.data?.table?.[0]?.catagory?.type}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    className={classes.service}
                                                    style={{ marginLeft: "10px" }}
                                                >
                                                    <Typography className={classes.servicetittle}>
                                                        {datas?.data?.table?.[0]?.subcatagory?.type}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Typography className={classes.title2}>
                                                {datas?.data?.table?.[0]?.description}
                                            </Typography>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            {/* PROBLEM SINCE */}

                                            {datas?.data?.table?.[0]?.problemsince?.length > 0 && (
                                                <Grid item xs={3}>
                                                    <Typography className={classes.quotationsummaryans}>
                                                        PROBLEM SINCE
                                                    </Typography>
                                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                                        <Box className={classes.waterLeakageIcons}>
                                                            <img
                                                                src="images/icons8-calendar (5).svg"
                                                                alt="no_img"
                                                            ></img>
                                                        </Box>
                                                       
                                                        <Box className={classes.quotationsummaryquest}>
                                                            {moment(datas?.data?.table?.[0]?.problemsince)
                                                                .tz(moment.tz.guess())
                                                                .format("YYYY-MM-DD")}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            )}

                                            {/* UNIT*/}

                                            {datas?.data?.table?.[0]?.unit?.[0]?.unit?.name?.length >
                                                0 && (
                                                    <Grid item xs={2.5}>
                                                        <Typography className={classes.quotationsummaryans}>
                                                            UNIT
                                                        </Typography>
                                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                                            <Box className={classes.waterLeakageIcons}>
                                                                <img src="images/Group 619.svg" alt="no_img"></img>
                                                            </Box>
                                                            <Box className={classes.quotationsummaryquest}>
                                                                {datas?.data?.table?.[0]?.unit?.[0]?.unit?.name}
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                )}

                                            {/* RAISED ON */}

                                            {datas?.data?.table?.[0]?.raisedon?.length > 0 && (
                                                <Grid item xs={2.5}>
                                                    <Typography className={classes.quotationsummaryans}>
                                                        RAISED ON
                                                    </Typography>
                                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                                        <Box className={classes.waterLeakageIcons}>
                                                            <img
                                                                src="images/icons8-calendar (5).svg"
                                                                alt="no_img"
                                                            ></img>
                                                        </Box>
                                                        <Box className={classes.quotationsummaryquest}>
                                                            {moment(datas?.data?.table?.[0]?.raisedon)
                                                                .tz(moment.tz.guess())
                                                                .format("YYYY-MM-DD")}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            )}

                                            {/* PREFERRED VISIT DATE AND TIME */}

                                            {datas?.data?.table?.[0]?.prefferedtime?.length > 0 && (
                                                <Grid item xs={3}>
                                                    <Typography
                                                        className={classes.quotationsummaryans}
                                                        noWrap
                                                    >
                                                        PREFERRED VISIT DATE AND TIME
                                                    </Typography>
                                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                                        <Box className={classes.waterLeakageIcons}>
                                                            <img
                                                                src="images/icons8-schedule (1).svg"
                                                                alt="no_img"
                                                            ></img>
                                                        </Box>
                                                        <Box className={classes.quotationsummaryquest}>
                                                            {moment(datas?.data?.table?.[0]?.prefferedtime)
                                                                .tz(moment.tz.guess())
                                                                .format("YYYY-MM-DD hh:mm")}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                    {/* worker details */}
                                    <Box className={classes.workerBox}>
                                        <CustomTypographyWorker>Work Order</CustomTypographyWorker>
                                        <Grid container spacing={3}>
                                            {/* WORK ORDER NUMBER*/}
                                            <Grid item xs={4}>
                                                <Typography className={classes.quotationsummaryans}>
                                                    WORK ORDER NUMBER
                                                </Typography>
                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                    <Box className={classes.waterLeakageIcons}>
                                                        <img src="images/Group 619.svg" alt="no_img"></img>
                                                    </Box>
                                                    <Box className={classes.quotationsummaryquest}>
                                                        1345464
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            {/*  WORK ORDER STATUS */}
                                            <Grid item xs={4}>
                                                <Typography className={classes.quotationsummaryans}>
                                                    WORK ORDER STATUS
                                                </Typography>
                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                    <Box className={classes.waterLeakageIcons}>
                                                        <img
                                                            src="images/icons8-calendar (5).svg"
                                                            alt="no_img"
                                                        ></img>
                                                    </Box>
                                                    <Box className={classes.quotationsummaryquest}>
                                                        Status
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            {/* WORK ORDER ID */}
                                            <Grid item xs={4}>
                                                <Typography className={classes.quotationsummaryans} noWrap>
                                                    WORK ORDER ID
                                                </Typography>
                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                    <Box className={classes.waterLeakageIcons}>
                                                        <img
                                                            src="images/icons8-schedule (1).svg"
                                                            alt="no_img"
                                                        ></img>
                                                    </Box>
                                                    <Box className={classes.quotationsummaryquest}>
                                                        WO-1315512
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {/* allocated details */}
                                    <Box mt={1}>
                                        <Grid container spacing={1}>
                                            {datas?.data?.table?.[0]?.name?.length > 0 && (
                                                <Grid item xs={6}>
                                                    <CustomTypographyWorker>
                                                        Contact Details
                                                    </CustomTypographyWorker>
                                                    <AllocatedCard
                                                        isImageType
                                                        viewDetails={{
                                                            member: {
                                                                image_url: "images/phonecall.svg",
                                                                first_name: datas?.data?.table?.[0]?.name ?? "",
                                                                last_name: "",
                                                                reference_no: "",
                                                                mobile_country_code:
                                                                    datas?.data?.table?.[0]?.alternative,
                                                                mobile_no: datas?.data?.table?.[0]?.mobile ?? "",

                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                            {/* 
                  <Grid item xs={6}>
                    <CustomTypographyWorker>Assigned To</CustomTypographyWorker>
                    <AllocatedCard
                      isImageType
                      viewDetails={{
                        member: {
                          image_url:
                            "https://mui.com/static/images/avatar/1.jpg",
                          first_name: "Dummy ",
                          last_name: "Text",
                          reference_no: "",
                          mobile_country_code: "+91",
                          mobile_no: "9090808012",
                          email_id: "dummy@mail.com",
                        },
                      }}
                    />
                  </Grid> */}
                                        </Grid>
                                    </Box>
                                </Grid>

                                {/* track */}
                                <Grid
                                    item
                                    xs={5}
                                    style={{ padding: "4px", position: "relative" }}
                                >
                                    <div style={{ height: size?.height - 400 }}>
                                        <div className={classes.createnewsectionlog}>
                                            <Typography
                                                className={classes.title}
                                                style={{ marginBottom: "20px" }}
                                            >
                                                Status
                                            </Typography>
                                            <Grid container direction={"column"} spacing={1}>
                                                {track
                                                    ?.filter((i) => i?.is_active === true)
                                                    .map((item, index, array) => {
                                                        return (
                                                            <Grid
                                                                container
                                                                direction={"row"}
                                                                justifyContent={"center"}
                                                                alignItems="center"
                                                            >
                                                                <Grid
                                                                    container
                                                                    direction={"column"}
                                                                    xs={2}
                                                                    justifyContent={"start"}
                                                                    alignItems="center"
                                                                >
                                                                    {index !== 0 && (
                                                                        <Typography
                                                                            style={{
                                                                                color: "#5078E1",
                                                                                fontWeight: "bolder",
                                                                            }}
                                                                        >
                                                                            |
                                                                        </Typography>
                                                                    )}
                                                                    <Avatar
                                                                        variant="circular"
                                                                        style={{
                                                                            height: "40px",
                                                                            width: "40px",
                                                                            border: "8px solid #F2F4F7",
                                                                        }}
                                                                    >
                                                                        <Avatar
                                                                            variant="circular"
                                                                            style={{
                                                                                height: "100%",
                                                                                width: "100%",
                                                                                backgroundColor: "#5078E1",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    request === "Maintance"
                                                                                        ? item?.label === "Open"
                                                                                            ? "images/icons8-clock.svg"
                                                                                            : "images/Path 953.svg"
                                                                                        : item?.label === "Open"
                                                                                            ? "images/icons8-clock.svg"
                                                                                            : "images/Path 953.svg"
                                                                                }
                                                                                alt="document"
                                                                            />
                                                                        </Avatar>
                                                                    </Avatar>
                                                                    {index + 1 !== array.length && (
                                                                        <Typography
                                                                            style={{
                                                                                color: "#5078E1",
                                                                                fontWeight: "bolder",
                                                                            }}
                                                                        >
                                                                            |
                                                                        </Typography>
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    direction={"column"}
                                                                    xs={10}
                                                                    justifyContent={"center"}
                                                                    alignItems="start"
                                                                    style={{ padding: "0px 0px 0px 10px" }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            marginBottom: index === 0 ? "16px" : "0px",
                                                                            marginTop:
                                                                                index + 1 === array.length ? "22px" : "0px",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            style={{
                                                                                fontSize: "16px",
                                                                                fontFamily: Bold,
                                                                                color: "#091B29",
                                                                            }}
                                                                        >
                                                                            {item?.label}
                                                                        </Typography>
                                                                        <Typography
                                                                            style={{
                                                                                fontSize: "12px",
                                                                                fontFamily: SemiBold,
                                                                                color: "#7C8594",
                                                                            }}
                                                                        >
                                                                            {moment(item.date)
                                                                                .tz(moment.tz.guess())
                                                                                .format("YYYY-MM-DD hh:mm A")}
                                                                        </Typography>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                            </Grid>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
            }
            {/* bottom btn */}
            {
                ((permission?.update && (datas?.data?.table?.[0]?.status?.some(
                    (item) => item?.type === "Open"
                ))) && (
                        <div className={classes.buttonParent}>
                            <Button
                                className={classes.Editbutton}
                                variant="contained"
                                onClick={editFunction}
                            >
                                Edit
                            </Button>

                            <Button
                                className={classes.button}
                                variant="contained"
                                onClick={() => CloseDialog("open")}
                            >
                                Close Request
                            </Button>
                        </div>
                    ))}
            {/* <div className={classes.buttonParent}>
        {datas?.data?.table?.[0]?.status?.[0]?.type !== "Closed" && (
          <Button
            className={classes.Editbutton}
            variant="contained"
            onClick={editFunction}
          >
            Edit
          </Button>
        )}

        {datas?.data?.table?.[0]?.status?.some(
          (item) => item?.type === "Open"
        ) && (
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => CloseDialog("open")}
          >
            Close Request
          </Button>
        )}
      </div> */}
        </>
    );
};
