import gql from "graphql-tag";
import { LocalStorageKeys } from '../utils'

export const GET_COUNTRY_MASTER = (offset, limit, searchText, isActive = false) => gql`
query GET_COUNTRY_MASTER{
  count: country_master(
    where: {
      is_delete:{eq:false}
      country_name: { iregex: "${searchText}" }
      ${isActive ? `is_active:{eq:true}` : ''}
    }) {
    count:count_id
  }
  country_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc,country_name:asc }
    where: {
      is_delete:{eq:false}
      country_name: { iregex: "${searchText}" }
      ${isActive ? `is_active:{eq:true}` : ''}
    }
    ){
    id
    country_name
    is_active
    is_delete
    is_external
  }
}`;

export const GET_COUNTRY_MASTER_DROPDOWN = (offset, limit, searchText, extraParams = {}) => gql`
  query GET_COUNTRY_MASTER_DROPDOWN{
    count:country_master(
      where:{
        ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
        country_name:{iregex:"${searchText}"}
      }){
      count:count_id
    }
    country_master(
      where:{
        ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
        country_name:{iregex:"${searchText}"}
      }
      offset:${offset}
      limit:${limit}
      order_by:{created_at:desc,country_name:asc }
    ){
      value:id
      label:country_name
    }
  }`

export const GET_CURRENCY_MASTER = (offset, limit, searchText) => gql`
query GET_CURRENCY_MASTER{
  count: currency_master(where: { is_delete:{eq:false} name: { iregex: "${searchText}" } } ) {
    count:count_id
  }
  currency_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc , name:asc }
    where: { is_delete:{eq:false} name: { iregex: "${searchText}" } }
    ){
    id
    name
    code
    country_master{
      country_name
      id
    }
    symbol
    is_active
    is_delete
    is_external
  }
}`;

export const GET_CURRENCY_MASTER_DROPDOWN = (offset, limit, searchText, extraParams = {}) => gql`
query GET_CURRENCY_MASTER_DROPDOWN{
  count: currency_master(where: {
    ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
    code: { iregex: "${searchText}" } } ) {
    count:count_id
  }
  currency_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc , name:asc }
    where: {
      ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
      code: { iregex: "${searchText}" } }
    ){
    id
    name
    code
    country_master{
      country_name
      id
    }
    symbol
    is_active
    is_external
  }
}`;

export const GET_VAT_GROUP = (offset, limit, searchText, isActive = false) => gql`
query GET_VAT_GROUP{
  count: vat_group_master(
    where: {
      is_delete:{eq:false}
      group_name: { iregex: "${searchText}" }
      ${isActive ? `is_active:{eq:true}` : ''}
      client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
    } ) {
    count:count_id
  }
  vat_group_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc,group_name:asc }
    where: {
      is_delete:{eq:false}
      group_name: { iregex: "${searchText}" }
      ${isActive ? `is_active:{eq:true}` : ''}
      client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
    }
    ){
    id
    group_name
    country_master{
      country_name
      id
    }
    vat_group_code
    total_rate: vat_group_item(where: { is_active: { eq: true } }) {
      total_rate: sum_rate
    }
    is_active
    is_external
  }
}`;

export const GET_VAT_GROUP_DROPDOWN = (offset, limit, searchText, extraParams = {}) => gql`
query GET_VAT_GROUP_DROPDOWN{
  count: vat_group_master(
    where: {
      group_name: { iregex: "${searchText}" }
      ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
      client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
    } ) {
    count:count_id
  }
  vat_group_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc,group_name:asc }
    where: {
      group_name: { iregex: "${searchText}" }
      ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
      client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
    }
    ){
    id
    group_name
    country_master{
      country_name
      id
    }
    vat_group_code
    total_rate: vat_group_item(where: { is_active: { eq: true } }) {
      total_rate: sum_rate
    }
    is_active
    is_external
  }
}`;

export const GET_VAT_GROUP_ITEM = (offset, limit, searchText) => gql`
query GET_VAT_GROUP_ITEM{
  count: vat_group_item(where: { is_delete:{eq:false}, group_name: { iregex: "${searchText}" } client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"} } ) {
    count:count_id
  }
  vat_group_item(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc ,group_name:asc }
    where: { is_delete:{eq:false} group_name: { iregex: "${searchText}" } client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"} }
    ){
    id
    group_name
    vat_group_item_code
    vat_group_master{
      group_name
      id
      country_masterByID{
        id
        country_name
      }
    }
    rate
    is_active
    is_external
  }
}`;

export const GET_COMPANY = (offset, limit, searchText, client) => gql`
query GET_COMPANY{
  count: company_master(where: {
    is_delete:{eq:false}
    client:{eq:"${client}"}
    or:{
      name: { iregex: "${searchText}" },
      company_no:{ iregex: "${searchText}" },
    }
   } ) {
    count:count_id
  }
  company_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc , name:asc }
    where: {
      is_delete:{eq:false}
      client:{eq:"${client}"}
      or:{
        name: { iregex: "${searchText}" },
        company_no:{ iregex: "${searchText}" },
      }
     }
    ){
      id
      name
      description
      tax_number
      company_address {
        city
        country
      }
      vat_group {
        id
        group_name
         vat_group_item(where: { is_active: {eq: true}}) {
    sum_rate
  }
      }
      tax_registration_number
      currency {
        id
        code
        symbol
        name
      }
      company_no
      company_registration_number
      cities_master {
        id
        city:city_name
        state_master {
          id
          state_name
          country_master {
            id
            country_name
          }
        }
      }
      vat_group_master {
        id
        group_name

      }
      is_active
      is_external
      routing_type
      logo
      account_type
      is_gateway
      cash_collection_office
      cheque_name
      primary_bank_name
      primary_account_no
      financial_system_code
      bank_routing_code
      iban
      company_type_master{
        id
        name
      }
  }
}`;

export const CHECK_COMPANY_NO = `
query CHECK_COMPANY_NO {
  company_master(where: { company_no: { eq: $companyNo } }) {
    id
    company_no
  }
}`;

export const CHECK_EXIST_COMPANY_NO = `
query CHECK_EXIST_COMPANY_NO {
  existing: company_master(
    where: { id: { eq: $companyID }, company_no: { eq: $companyNo } }
  ) {
    id
    company_no
  }
  new: company_master(where: { company_no: { eq: $companyNo } }) {
    id
    company_no
  }
}`;

export const GET_STATE = (offset, limit, searchText, isActive = false) => gql`
query GET_STATE{
  count: state_master(
    where: {
       state_name: { iregex: "${searchText}" }
       ${isActive ? `is_active:{eq:true}` : ''}
      } ) {
    count:count_id
  }
  state_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc,state_name:asc  }
    where: {
      state_name: { iregex: "${searchText}" }
      ${isActive ? `is_active:{eq:true}` : ''}
    }
    ){
    id
    state_name
    country_master{
      country_name
      id
    }
    is_active
    is_external
  }
}`;

export const GET_STATE_DROPDOWN = (offset, limit, searchText, extraParams = {}) => gql`
query GET_STATE_DROPDOWN{
  count:state_master(
  where:{
    ${extraParams?.country_id ? `country_id:{eq:"${extraParams?.country_id}"}` : ''}
    state_name:{iregex:"${searchText}"}
    ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
  }
  ){
    count:count_id
  }
  state_master(
  where:{
    ${extraParams?.country_id ? `country_id:{eq:"${extraParams?.country_id}"}` : ''}
    state_name:{iregex:"${searchText}"}
    ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
  }
  offset:${offset}
  limit:${limit}
  order_by:{created_at:desc, state_name:asc }
){
  value:id
  label:state_name
}
}`;

export const GET_CITY = (offset, limit, searchText, isActive = false) => gql`
query GET_CITY{
  count: cities_master
  ${isActive ? `(
  where: {
      ${isActive ? `is_active:{eq:true}` : ''}
    } )`: ``}
     {
    count:count_id
  }
  cities_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc, city:asc  }
    ${isActive ? `
      where: {
          ${isActive ? `is_active:{eq:true}` : ''}
        } `: ``}
    ){
    id
    city
   state_master{
      state_name
      id
      country_master {
        country_name
        id
      }
    }
    country_master{
      country_name
      id
    }
    is_active
    is_external
  }
}`;


export const GET_CITY_DROPDOWN = (offset, limit, searchText, extraParams = {}) => gql`
query GET_CITY_DROPDOWN{
  count:cities_master
  ${extraParams ? `(
  where:{
    ${extraParams?.state_id ? `state:{id:{eq:"${extraParams?.state_id}"}}` : ''}
    ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
  }
  )`: ``}
  {
    count:count_id
  }
  cities_master(
    ${extraParams ? `
      where:{
        ${extraParams?.state_id ? `state:{id:{eq:"${extraParams?.state_id}"}}` : ''}
        ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
      }
      `: ``}
  offset:${offset}
  limit:${limit}
  order_by:{created_at:desc, city:asc }
){
  value:id
  label:city
}
}`;

export const GET_CITY_AREA = (offset, limit, searchText) => gql`
query GET_CITY_AREA{
  count: city_area_master(where: { area_name: { iregex: "${searchText}" } } ) {
    count:count_id
  }
  city_area_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc , area_name:asc }
    where: { area_name: { iregex: "${searchText}" } }
    ){
    id
    area_name
    description
    country_master{
      country_name
      id
    }
    state_master{
      state_name
      id
    }
    cities_master {
      city
      id
      state_master {
        state_name
        id
        country_master {
          country_name
          id
        }
      }
    }
    is_active
    is_external
  }
}`;

export const GET_LEAD_TYPE = (offset, limit, searchText) => gql`
query GET_LEAD_TYPE{
  count: lead_type_master(where: { name: { iregex: "${searchText}" } } ) {
    count:count_id
  }
  lead_type_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc , name:asc }
    where: { name: { iregex: "${searchText}" } }
    ){
    id
    name
    is_active
    is_external
  }
}
`;

export const GET_ACTIVITY_MASTER = (offset, limit, searchText, isActive = false) => gql`
query GET_ACTIVITY_MASTER{
  count: lead_activity_master(
    where: {
      name: {iregex: "${searchText}"}
      ${isActive ? `is_active:{eq:true}` : ''}
    }){
    count:count_id
  }
  lead_activity_master(
    offset: ${offset}
    limit: ${limit}
    where: {
      name: {iregex: "${searchText}"}
      ${isActive ? `is_active:{eq:true}` : ''}
    }
    order_by: { created_at: desc,name:asc }
    ){
    id
    type: name
    is_active
    is_external
  }
}
`;

export const GET_ACTIVITY_MASTER_DROPDOWN = (offset, limit, searchText, extraParams = {}) => gql`
query GET_ACTIVITY_MASTER_DROPDOWN{
  count:lead_activity_master(
  where:{
    name:{iregex:"${searchText}"}
    ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
  }
  ){
    count:count_id
  }
  lead_activity_master(
  where:{
    name:{iregex:"${searchText}"}
    ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
  }
  offset:${offset}
  limit:${limit}
  order_by:{created_at:desc, name:asc }
){
  value:id
  label:name
}
}`;

export const GET_ACTIVITY_CATEGORY = (offset, limit, searchText, isActive = false) => gql`
query GET_ACTIVITY_CATEGORY{
  count: activity_category_master(
    where: {
      is_delete:{eq:false}
      name: {iregex: "${searchText}"}
      ${isActive ? `is_active:{eq:true}` : ''}
      client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
    }
    ){
    count:count_id
  }
  activity_category_master(
    offset: ${offset}
    limit: ${limit}
    where: {
      is_delete:{eq:false}
      name: {iregex: "${searchText}"}
      ${isActive ? `is_active:{eq:true}` : ''}
      client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
    }
    order_by: { created_at: desc,name:asc }
    ){
    id
    activity_category: name
    lead_activity_master{
      type: name
      id
    }
    is_active
    is_external
  }
}
`;

export const GET_ACTIVITY_CATEGORY_DROPDOWN = (offset, limit, searchText, extraParams = {}) => {
  return gql`
query GET_ACTIVITY_CATEGORY_DROPDOWN{
  count:activity_category_master(
  where:{
    name:{iregex:"${searchText}"}
    ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
    ${extraParams?.lead_activity_master_id ? `lead_activity_master_id:{eq:"${extraParams?.lead_activity_master_id}"}` : ''}
    client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
  }
  ){
    count:count_id
  }
  activity_category_master(
  where:{
    name:{iregex:"${searchText}"}
    ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
    ${extraParams?.lead_activity_master_id ? `lead_activity_master_id:{eq:"${extraParams?.lead_activity_master_id}"}` : ''}
    client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
  }
  offset:${offset}
  limit:${limit}
  order_by:{created_at:desc, name:asc }
){
  value:id
  label:name
}
}`};
export const GET_ACTIVITY_SUB_CATEGORY = (offset, limit, searchText) => gql`
query GET_ACTIVITY_SUB_CATEGORY{
  count: activity_sub_category_master(
    where: { is_delete:{eq:false} name: {iregex: "${searchText}"}, client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}}
    ){
    count:count_id
  }
  activity_sub_category_master(
    offset: ${offset}
    limit: ${limit}
    where: { is_delete:{eq:false} name: {iregex: "${searchText}"}, client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}}
    order_by: { created_at: desc , name:asc }
    ){
    id
    sub_category: name
    activity_category_master{
      lead_activity_master{
        type: name
        id
      }
      activity_category: name
      id
    }
    is_active
    is_external
  }
}
`;

export const UPDATE_COUNTRY_MASTER = `
mutation($insert: [country_masterOutput!]) {
  country_master(
    insert: $insert
  ) {
   id
   country_name
   is_active
  }
}
`;

export const EDIT_COUNTRY_MASTER = `mutation($Id: ID, $demo: country_masterInput) {
  country_master(
    where: { id: { eq: $Id } }
    update: $demo
  ) {
     id
  }
}`;

export const UPDATE_CURRENCY_MASTER = `
mutation($insert: [currency_masterOutput!]) {
  currency_master(
    insert: $insert
  ) {
   id
   name
   symbol
   code
   is_active
  }
}
`;

export const EDIT_CURRENCY_MASTER = `mutation($Id: ID, $demo: currency_masterInput) {
  currency_master(
    where: { id: { eq: $Id } }
    update: $demo
  ) {
     id
  }
}`;

export const UPDATE_VAT_GROUP = `
mutation($insert: [vat_group_masterOutput!]) {
  vat_group_master(
    insert: $insert
  ) {
   id
  }
}
`;

export const EDIT_VAT_GROUP = `mutation($Id: ID, $demo: vat_group_masterInput) {
  vat_group_master(
    where: { id: { eq: $Id } }
    update: $demo
  ) {
     id
  }
}`;

export const UPDATE_VAT_GROUP_ITEM = `
mutation($insert: [vat_group_itemOutput!]) {
  vat_group_item(
    insert: $insert
  ) {
   id
  }
}
`;

export const EDIT_VAT_GROUP_ITEM = `mutation($Id: ID, $demo: vat_group_itemInput) {
  vat_group_item(
    where: { id: { eq: $Id } }
    update: $demo
  ) {
     id
  }
}`;

export const UPDATE_COMPANY = `
mutation($insert: [company_masterOutput!]) {
  company_master(
    insert: $insert
  ) {
   id
  }
}
`;

export const EDIT_COMPANY = `mutation($Id: ID, $demo: company_masterInput) {
  company_master(
    where: { id: { eq: $Id } }
    update: $demo

  ) {
     id
  }
}`;

export const UPDATE_STATE = `
mutation($insert: [state_masterOutput!]) {
  state_master(
    insert: $insert
  ) {
   id
  }
}
`;

export const EDIT_STATE = `mutation($Id: ID, $demo: state_masterInput) {
  state_master(
    where: { id: { eq: $Id } }
    update: $demo
  ) {
     id
  }
}`;

export const UPDATE_CITY = `
mutation($insert: [cities_masterOutput!]) {
  cities_master(
    insert: $insert
  ) {
   id
  }
}
`;

export const EDIT_CITY = `mutation($Id: ID, $demo: cities_masterInput) {
  cities_master(
    where: { id: { eq: $Id } }
    update: $demo
  ) {
     id
  }
}`;

export const UPDATE_CITY_AREA = `
mutation($insert: [city_area_masterOutput!]) {
  city_area_master(
    insert: $insert
  ) {
   id
  }
}
`;

export const EDIT_CITY_AREA = `mutation($Id: ID, $demo: city_area_masterInput) {
  city_area_master(
    where: { id: { eq: $Id } }
    update: $demo
  ) {
     id
  }
}`;

export const UPDATE_LEAD_TYPE = `
mutation($insert: [lead_type_masterOutput!]) {
  lead_type_master(
    insert: $insert
  ) {
   id
  }
}
`;

export const EDIT_LEAD_TYPE = `mutation($Id: ID, $demo: lead_type_masterInput) {
  lead_type_master(
    where: { id: { eq: $Id } }
    update: $demo
  ) {
     id
  }
}`;

export const UPDATE_ACTIVITY_MASTER = `
mutation($insert: [lead_activity_masterOutput!]) {
  lead_activity_master(
    insert: $insert
  ) {
   id
  }
}
`;

export const EDIT_ACTIVITY_MASTER = `mutation($Id: ID, $demo: lead_activity_masterInput) {
  lead_activity_master(
    where: { id: { eq: $Id } }
    update: $demo
  ) {
     id
  }
}`;

export const UPDATE_ACTIVITY_CATEGORY = `
mutation($insert: [activity_category_masterOutput!]) {
  activity_category_master(
    insert: $insert
  ) {
   id
  }
}
`;

export const EDIT_ACTIVITY_CATEGORY = `mutation($Id: ID, $demo: activity_category_masterInput) {
  activity_category_master(
    where: { id: { eq: $Id } }
    update: $demo
  ) {
     id
  }
}`;

export const UPDATE_ACTIVITY_SUB_CATEGORY = `
mutation($insert: [activity_sub_category_masterOutput!]) {
  activity_sub_category_master(
    insert: $insert
  ) {
   id
  }
}
`;

export const EDIT_ACTIVITY_SUB_CATEGORY = `mutation($Id: ID, $demo: activity_sub_category_masterInput) {
  activity_sub_category_master(
    where: { id: { eq: $Id } }
    update: $demo
  ) {
     id
  }
}`;

// export const UPDATE_ACTIVITY_SUB_CATEGORY = `
// mutation($insert: [activity_category_masterOutput!]) {
//   activity_category_master(
//     insert: $insert
//   ) {
//    id
//   }
// }
// `;


export const GET_LEAD_SUB_ACTIVITY_DROPDOWN = `
query GET_LEAD_SUB_ACTIVITY_DROPDOWN{
  activity_sub_category_master(where:{is_active:{eq:true}}){
    id
    sub_category
  }
}`;

export const COUNTRY_MASTER_SEARCH = `
query($search: String){
  country_master(where:{
    country_name:{ilike:$search}
  }){
    id
    country_name
    is_active
    is_external
  }
}`;

export const CURRENCY_MASTER_SEARCH = `
query($search: String){
  currency_master(where:{
    name:{ilike:$search}
  }){
    id
    name
    code
    symbol
    country_name
    is_active
    is_external
  }
}`;

export const VAT_GROUP_SEARCH = `
query($search: String){
  vat_group_master(where:{
    name:{ilike:$search}
  }){
    id
    name
    country_master{
      country_name
    }
    description
    is_active
    is_external
  }
}`;

export const VAT_GROUP_ITEM_SEARCH = `
query($search: String){
  vat_group_item(where:{
    group_name:{ilike:$search}
  }){
    id
    group_name
    description
    vat_group_master{
      group_name
    }
    rate
    is_active
    is_external
  }
}`;

export const COMPANY_SEARCH_SEARCH = `
query($search: String){
  company_master(where:{
    name:{ilike:$search}
  }){
    id
    name
    description
    is_active
    is_external
  }
}`;

export const STATE_SEARCH = `
query($search: String){
  state_master(where:{state_name:{ilike:$search}}){
    id
    state_name
    country_master{
      country_name
    }
    is_active
    is_external
  }
}`;

export const CITY_SEARCH = `
query($search: String){
  cities_master(where:{
    city:{ilike:$search}
  }){
    id
    city
   state_master{
      state_name
    }
    country_master{
      country_name
    }
    is_active
    is_external
  }
}`;

export const CITY_AREA_SEARCH = `
query($search: String){
  city_area_master(where:{
    area_name:{ilike:$search}
  }){
    id
    area_name
    description
    country{
      country_name
    }
    cities_master{
      city
    }
    is_active
    is_external
  }
}`;

export const LEAD_TYPE_SEARCH = `
query($search: String){
  lead_type_master(where:{
    name:{ilike:$search}
  }){
    id
    name
    is_active
    is_external
  }
}
`;

export const ACTIVITY_MASTER_SEARCH = `
query($search: String){
  lead_activity_master(where:{
    type:{ilike:$search}
  }){
    id
    type
    is_active
    is_external
  }
}
`;

export const ACTIVITY_CATEGORY_SEARCH = `
query($search: String){
  activity_category_master(where:{activity_category:{ilike:$search})
  {
    id
    activity_category
    lead_activity_master{
      type
    }
    is_active
    is_external
  }
}
`;
export const ACTIVITY_SUB_CATEGORY_SEARCH = `
query ($search: String){
  activity_sub_category_master(where: {
    sub_category:{ilike:$search}
  }){
    id
    sub_category
    lead_activity_master{
      type
    }
    activity_category{
      activity_category
    }
    is_active
    is_external
  }
}
`;
export const GET_VAT_GROUP_1 = (offset, limit, searchText, extraParams = {}) => gql`
query GET_VAT_GROUP{
  count: vat_group_master(
    where: {
      is_delete:{eq:false}
      group_name: { iregex: "${searchText}" }
      ${extraParams?.isActive ? `is_active:{eq:${extraParams?.isActive}}` : ''}
      ${extraParams?.country_id ? `country_id:{eq:${extraParams?.country_id}}` : ''}
      client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
    } ) {
    count:count_id
  }
  vat_group_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc,group_name:asc }
    where: {
      is_delete:{eq:false}
      group_name: { iregex: "${searchText}" }
      ${extraParams?.isActive ? `is_active:{eq:${extraParams?.isActive}}` : ''}
      ${extraParams?.country_id ? `country_id:{eq:${extraParams?.country_id}}` : ''}
      client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
    }
    ){
    id
    group_name
    country_master{
      country_name
      id
    }
    vat_group_code
    total_rate: vat_group_item(where: { is_active: { eq: true } }) {
      total_rate: sum_rate
    }
    is_active
    is_external
  }
}`;

export const GET_VAT_GROUP_ITEM_BY_COMPANY = (offset, limit, searchText, company_id) => gql`
query GET_VAT_GROUP_ITEM{
  count: vat_group_item(where: {
    is_delete:{eq:false},
    group_name: { iregex: "${searchText}" }
    ${company_id ? `company_id:{eq:${company_id}}` : ``}
    client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"} } ) {
    count:count_id
  }
  vat_group_item(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc ,group_name:asc }
    where: {
      is_delete:{eq:false}
      group_name: { iregex: "${searchText}" }
      ${company_id ? `company_id:{eq:${company_id}}` : ``}
      client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"} }
    ){
    id
    group_name
    vat_group_item_code
    company_masterByID{
      id
      name
    }
    vat_group_master{
      group_name
      id
      country_masterByID{
        id
        country_name
      }
    }
    rate
    is_active
    is_external
  }
}`;

export const GET_COUNTRY_MASTER_DROPDOWN_REGION = (offset, limit, searchText, extraParams = {}) => gql`
  query GET_COUNTRY_MASTER_DROPDOWN($regionIds:[Integer]){
    count:country_master(
      where:{
        ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
        country_name:{iregex:"${searchText}"}
        region_id:{ in:$regionIds}
      }){
      count:count_id
    }
    country_master(
      where:{
        ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
        country_name:{iregex:"${searchText}"}
        region_id:{ in:$regionIds}
      }
      offset:${offset}
      limit:${limit}
      order_by:{created_at:desc,country_name:asc }
    ){
      value:id
      label:country_name
    }
  }`


  export const GET_COUNTRY_MASTER_REGION = (offset, limit, searchText, isActive = false) => gql`
query GET_COUNTRY_MASTER($regionIds:[Integer]){
  count: country_master(
    where: {
      is_delete:{eq:false}
      country_name: { iregex: "${searchText}" }
      ${isActive ? `is_active:{eq:true}` : ''}
      region_id:{ in:$regionIds}
    }) {
    count:count_id
  }
  country_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc,country_name:asc }
    where: {
      is_delete:{eq:false}
      country_name: { iregex: "${searchText}" }
      ${isActive ? `is_active:{eq:true}` : ''}
      region_id:{ in:$regionIds}
    }
    ){
    id
    country_name
    is_active
    is_delete
    is_external
  }
}`;