import React from 'react';
import { AssetsView } from '../../companyCreation/views';

export const VendorAssetsView = (props) => {

    const { 
        data=[]
    } = props;

    return (
        <AssetsView
            data={data}
        />
    )
}