import { Button, Divider, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Profile } from "../../assets";
import AccountIcon from "../../assets/accountIcon";
import AgreementIcon from "../../assets/agreementIcon";
import { InvoiceDropdown, LoadingSection, Subheader, ReceiptHistoryTable, UseDebounce } from "../../components";
import { DocumentViewer } from "../../components/fileViewer";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, getRoutePermissionNew, NetWorkCallMethods, convertTimeUtcToZoneCalander } from "../../utils";
import { InvoiceViewStyles } from "./style";
import { InvoiceDropdownQuery } from "../../graphql/invoice";
import { useApolloClient } from '@apollo/client'




const CustomPaper = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#F2F4F7",
    // padding: theme.spacing(2),
    margin: theme.spacing(3),
    padding: "0px",
    height: `calc(100vh - 147px)`,
}))





export const InvoiceView = ({ id = null, agreement = false, goBackFun = () => false }) => {
    const classes = InvoiceViewStyles()
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const auth = React.useContext(AuthContext)
    const { state } = useLocation()
    const [status, setStatus] = React.useState("invoice")
    const [loading, setLoading] = React.useState(true)
    const [invoiceData, setInvoiceData] = React.useState();
    const history = useHistory()
    const [permission, setPermission] = React.useState({})
    const client = useApolloClient()
    const [details, setDetails] = React.useState()
    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                InvoiceTableData()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const InvoiceTableData = () => {

        const payload = {
            id: state || id
        };
        NetworkCall(
            `${config.api_url}/invoice/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                setInvoiceData(response?.data?.data)
                setLoading(false)
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            }).catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                });
                setLoading(false)

            });
    }

    const goBack = () => {
        if (agreement) {
            goBackFun()
        } else {
            history.goBack()
        }
    }

    React.useEffect(() => {
        if (permission?.read) {
            InvoiceTableData()
        }
        //eslint-disable-next-line
    }, [permission])

    React.useEffect(() => {
        // if(state?.id){
        getInvoiceDropdown()
        // }
        // eslint-disable-next-line
    }, [])
    const getInvoiceDropdown = (e) => {
        client
            .query({
                query: InvoiceDropdownQuery,
                fetchPolicy: "network-only",
                variables: {
                    invoice_id: state || id,
                    search: e ?? ""
                },
            })
            .then((response) => {
                // setDetails(response.data.invoice_receipt_settlement[0]);
                let invoiceDataArray = []
                response.data.invoice_receipt_settlement.map((i) => {
                    invoiceDataArray.push(
                        {
                            amount_tagged: i?.amount_tagged,
                            created_at: convertTimeUtcToZoneCalander(i?.created_at),
                            tagged_by: i?.tagged_by?.first_name,
                            reciepts_no: i?.reciepts?.reciepts_no,
                            source: i?.reciepts?.payment_mode ?? "-",
                            url: i?.reciepts.url,
                            settlement_no: i?.settlement_no,
                            symbol: i?.invoice.company.currency.symbol
                        }
                    )
                    return 0;
                }
                )
                setDetails(invoiceDataArray)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // Search function

    const [searchText, setSearchText] = React.useState("");
    const debounce = UseDebounce();

    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        getInvoiceDropdown(e)
    }

    const render = () => {
        return <>
            {loading ? <LoadingSection bottom={"45vh"} message="Loading Invoice Details" />
                :
                <>
                    <div>
                        <Subheader title={"Invoice"} count={false}
                            select={false}
                            isReadOnly
                            goBack={goBack}
                        />
                        <CustomPaper >
                            <Grid container>
                                <Grid item xs={12} sm={12} md={3} lg={3} bgcolor="white">
                                    <Grid container spacing={1} padding="12px 0px 12px 12px" display="block">
                                        <Box className={classes.left_content}>
                                            <Grid item xs={12} >
                                                <div className={classes.div} >
                                                    <Box>
                                                        <Box>
                                                            <Typography className={classes.textSmall}>INVOICE DETAILS</Typography>
                                                        </Box>
                                                        <Box height={"8px"} />
                                                        <Box display={"flex"} justifyContent={"space-between"}>
                                                            <Box>
                                                                <Typography className={classes.text1}>{invoiceData?.invoice_no ?? " -"}</Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography className={classes.text2}>{invoiceData?.payment_status === "due" ? "unpaid" : "paid"}</Typography>

                                                            </Box>
                                                        </Box>
                                                        <Box height={"4px"} />

                                                        <Box>
                                                            <Typography className={classes.text3}>{invoiceData?.invoice_type ?? "-"}</Typography>
                                                        </Box>
                                                        <Box height={"4px"} />

                                                        <Stack direction={"row"} alignItems="center">
                                                            <Box >
                                                                <Typography className={classes.text4}>{`${"Generated on"} ${convertTimeUtcToZoneCalander(invoiceData?.created_at)}`}</Typography>
                                                            </Box>
                                                            <Box className={classes.periodStyle} />
                                                            <Box >
                                                                <Typography className={classes.text4}>{`${"Due on"} ${convertTimeUtcToZoneCalander(invoiceData?.payment_due_date)}`}</Typography>
                                                            </Box>
                                                        </Stack>
                                                    </Box>
                                                    <Box height={"12px"} />

                                                    <Divider />
                                                    <Box height={"12px"} />

                                                    <Box className={classes.box}>
                                                        <Box>
                                                            <Typography className={classes.text3}>Invoice Amount</Typography>

                                                        </Box>
                                                        <Box>
                                                            <Typography className={classes.text3}>{`${invoiceData?.invoice_total_amount ?? ""} ${invoiceData?.symbol ?? ""}`}</Typography>

                                                        </Box>
                                                    </Box>
                                                    <Box height={"12px"} />

                                                    <Box className={classes.box}>
                                                        <Box>
                                                            <Typography className={classes.text3}>{`${"Tax"} ${invoiceData?.tax} ${"%"}`}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography className={classes.text3}>{`${invoiceData?.invoice_total_tax_amount ?? ""} ${invoiceData?.symbol ?? ""}`}</Typography>

                                                        </Box>
                                                    </Box>
                                                    <Box height={"12px"} />

                                                    <Box className={classes.box1}>
                                                        <Box>
                                                            <Typography className={classes.text333}>Total Invoice Amount</Typography>

                                                        </Box>
                                                        <Box>
                                                            <Typography className={classes.text333}>{`${invoiceData?.total_amount ?? "-"} ${invoiceData?.symbol ?? ""}`}</Typography>

                                                        </Box>
                                                    </Box>
                                                    <Box height={"12px"} />
                                                    <Box className={classes.dropdownBox}>
                                                        {/* <Box>
                                                        <Typography className={classes.text33} >Partial Amount Received</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography className={classes.text33}>{`${invoiceData?.partial_amount ?? ""} ${invoiceData?.symbol ?? ""}`}</Typography>
                                                    </Box> */}
                                                        <InvoiceDropdown partialAmount={invoiceData?.partial_amount} invoiceData={details} symbol={invoiceData?.symbol} />
                                                    </Box>

                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className={classes.div}>
                                                    <Box>
                                                        <Typography className={classes.textSmall}>CUSTOMER DETAILS</Typography>
                                                    </Box>
                                                    <Box height={"4px"} />
                                                    <Box display={"flex"}>
                                                        <Box>
                                                            {invoiceData?.image_url ?
                                                                <img src={invoiceData?.image_url} alt="userimg" className={classes.img} />
                                                                : <Profile />
                                                            }
                                                        </Box>
                                                        <Box marginLeft={"12px"} alignItems="center">
                                                            <Typography className={classes.textRight}>{invoiceData?.user_name ?? "-"}</Typography>
                                                            <Typography className={classes.textBottom}>{invoiceData?.ucid ?? "-"}</Typography>
                                                        </Box>

                                                    </Box>
                                                    <Box height={"12px"} />
                                                    <Divider />
                                                    <Box height={"12px"} />
                                                    <Box>
                                                        <Typography className={classes.textSmall}>ACCOUNT DETAILS</Typography>
                                                    </Box>
                                                    <Box height={"4px"} />

                                                    <Box display={"flex"}>
                                                        <Box>
                                                            <AccountIcon />
                                                        </Box>
                                                        <Box marginLeft={"12px"} alignItems="center">
                                                            <Typography className={classes.textRight}>{invoiceData?.account_name ?? "-"}</Typography>
                                                            <Typography className={classes.textBottom}>{invoiceData?.account_no ?? "-"}</Typography>
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className={classes.div}>

                                                    <Box display={"flex"}>
                                                        <Box>
                                                            <AgreementIcon />
                                                        </Box>
                                                        <Box marginLeft={"12px"} alignItems="center">
                                                            <Typography className={classes.textRight}>{invoiceData?.agreement_no ?? "-"}</Typography>
                                                            <Typography className={classes.textBottom}>{invoiceData?.company_name ?? "-"}</Typography>
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </Grid>
                                        </Box>

                                        <Grid item xs={12}>
                                            <br />
                                            <Button variant="contained" fullWidth>
                                                Send Payment Link
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={9} >
                                    <Grid container rowSpacing={3} justifyContent={"center"} pt={0.8} >
                                        <Grid item xs={2} >
                                            <Typography

                                                onClick={() => {
                                                    setStatus("invoice");
                                                }}
                                                className={
                                                    status === "invoice"
                                                        ? classes.unselect
                                                        : classes.select
                                                }
                                            >
                                                invoice
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} >
                                            <Typography

                                                onClick={() => {
                                                    setStatus("receipt");
                                                }}
                                                className={
                                                    status === "receipt"
                                                        ? classes.unselect
                                                        : classes.select
                                                }
                                            >
                                                Receipt
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} >
                                            <Typography

                                                onClick={() => {
                                                    setStatus("receipthistory");
                                                }}
                                                className={
                                                    status === "receipthistory"
                                                        ? classes.unselect
                                                        : classes.select
                                                }
                                            >
                                                Receipt History
                                            </Typography>
                                        </Grid>
                                        {
                                            status === "receipthistory" ?
                                                <Grid item xs={12} m={2} p={2} className={classes.tabledoc}>
                                                    <ReceiptHistoryTable ReceiptTableData={details} searchText={searchText} handleSearch={handleSearch} />
                                                </Grid>
                                                :
                                                <Grid item xs={12} >
                                                    <center className={classes.doc}>
                                                        {
                                                            status === "receipt" ?
                                                                <div>
                                                                    {/* {details?.url ?
                                                                        <DocumentViewer url={details?.url} /> :
                                                                        "No Invoice found"
                                                                    } */}
                                                                    {
                                                                        details.map(dtl => {
                                                                            return (
                                                                                <div>
                                                                                    {
                                                                                        dtl?.url !== null ?
                                                                                            <DocumentViewer url={dtl?.url} />
                                                                                            :
                                                                                            "No Receipt Found"
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                :
                                                                <div>
                                                                    {
                                                                        invoiceData?.asset_url !== null ?
                                                                            <DocumentViewer url={invoiceData?.asset_url} />
                                                                            : "No Invoice found"
                                                                    }
                                                                </div>
                                                        }
                                                    </center>
                                                </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CustomPaper>
                    </div>




                </>
            }
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};

