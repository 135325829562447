/* eslint-disable array-callback-return */
import {
    Grid
} from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Subheader } from '../../components';
import { NewLoader } from '../../components/newLoader';
import { config } from '../../config';
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { accessCheckRender, AlertProps, getRoutePermissionNew, NetWorkCallMethods } from '../../utils';
import useWindowDimensions from "../../utils/useWindowDimensions";
import { OtherInfoCard } from './otherInfo';
import { OwnerDetail } from './ownerDetails';
const getAllids = (data) => {
    let list = [];
    if (data?.length > 0) {
        data?.map(id => {
            list = [...list, id?.unitId]
        })
    }
    return list;
}

export const OwnerTableView = (props) => {
    const { loading, handleLoading } = props;
    const history = useHistory()
    const size = useWindowDimensions();
    const alert = React.useContext(AlertContext);
    const search = useLocation().search;
    const auth = React.useContext(AuthContext)
    const [ownerDetailsData, setOwnerDetailsData] = React.useState([])
    const userId = new URLSearchParams(search).get("userId");
    const companyId = new URLSearchParams(search).get("companyId");
    const [permission, setPermission] = React.useState({})
    const [loader, setLoader] = React.useState(true)

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getOwnerDetailsId()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getOwnerDetailsId = () => {
        setLoader(true)
        const payload = {
            tenantId: `${config.tenantId}`,
            companyId: Number(companyId),
            userId: userId
        };
        NetworkCall(
            `${config.api_url}/owner-resident/owner`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setOwnerDetailsData(response?.data?.data);
                setLoader(false)
                handleLoading(false);
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something went wrong please try again",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                });
            });

    };

    const allUnitId = getAllids(ownerDetailsData);

    const render = () => {
        return <>
            <Subheader title={ownerDetailsData?.[0]?.firstName} goBack={() => history.goBack()} />
            {
                loader ? (
                    <NewLoader minusHeight="100px" />
                ) : (
                    <div style={{ height: size?.height - 200, overflow: "auto", padding: "4px 20px", }} >
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <OwnerDetail data={ownerDetailsData?.[0]} />
                            </Grid>
                            <Grid item xs={12}>
                                <OtherInfoCard allUnitId={allUnitId} />
                            </Grid>
                        </Grid>
                    </div>)}
        </>
    }

    return <>
        {accessCheckRender(render, permission, "", loading)}
    </>

}