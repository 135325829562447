import React from "react";
import { useStyles } from "./styles";
import {
  Subheader,
  AddressCard,
  ContactListComponet,
} from "../../../components";
import { AddExisitingContact } from "../../../assets/addexisitingcontact";
import { Box, Grid, Typography } from "@mui/material";
import { DetailsCardCoponent } from "./detailsCard";
import { CreateShortListContext } from "../../../contexts/shortlistLedContext";

export const AddExistContacts = () => {
  const classes = useStyles();
  const { dataNew, setDataNew, show, setShow } = React.useContext(CreateShortListContext);

  //on clicking search contact
  const chooseCustomer = (val) => {
    setDataNew({
      ...dataNew,
      disableForm: true,
      name: `${val?.first_name ?? ""} ${val?.last_name ?? ""}`,
      email_id: val?.email_id,
      mobile: {
        mobile: val?.mobile_no ?? "",
        mobile_code: val?.mobile_no_country_code ?? null,
      },
      alter_mobile: {
        mobile: val?.alternative_mobile ?? "",
        mobile_code: val?.alternative_mobile_country_code ?? null,
      },
      telephone: {
        mobile: val?.phone ?? "",
        mobile_code: val?.phone_code ?? null,
      },
      fax: {
        mobile: val?.fax_no ?? "",
        mobile_code: val?.phone_code ?? null,
      },
      image_url: val?.image_url ?? "",
      existing: true,
      address: {
        street_1: val?.street_1 ?? "",
        street_2: val?.street_2 ?? "",
        street_3: val?.street_3 ?? "",
        city: val?.city ?? "",
        district: val?.district ?? "",
        state: val?.state ?? "",

        zipcode: val?.zipcode ?? "",
      },
    });
    setShow(true);
  };
  const card = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: "Contact Name",
      subtitle: dataNew?.name ?? "-",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: "Mobile",
      subtitle: `${dataNew?.mobile?.mobile_code ?? "-"} ${dataNew?.mobile?.mobile ?? ""
        }`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: "Alternative Mobile",
      subtitle: `${dataNew?.alter_mobile?.mobile_code ?? "-"} ${dataNew?.alter_mobile?.mobile ?? ""
        }`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: "Telephone",
      subtitle: `${dataNew?.telephone?.mobile_code ?? "-"} ${dataNew?.telephone?.mobile ?? ""
        }`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: "Fax",
      subtitle: `${dataNew?.fax?.mobile_code ?? "-"} ${dataNew?.fax?.mobile ?? ""
        }`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: "Email ID",
      subtitle: dataNew?.email_id ?? "-",
    },
  ];
  const card1 = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 3,
      },
      isActive: true,
      component: "text",
      heading: "Facebook",
      subtitle: dataNew?.facebook ?? "-",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 3,
      },
      isActive: true,
      component: "text",
      heading: "Twitter",
      subtitle: dataNew?.twitter ?? "-",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 3,
      },
      isActive: true,
      component: "text",
      heading: "LinkedIn",
      subtitle: dataNew?.linkedIn ?? "-",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 3,
      },
      isActive: true,
      component: "text",
      heading: "Whatsapp",
      subtitle: dataNew?.Whatsapp ?? "-",
    },
  ];

  return (
    <>
      <Subheader hideBackButton title={"Add Existing Contact"} />
      <Box className={classes.tabContant}>
        <ContactListComponet
          chooseAccount={chooseCustomer}
          selectedAcc={dataNew}
        />
        {!show ? (
          <Box className={classes.center}>
            <Box textAlign={"center"}>
              <AddExisitingContact />
              <Typography className={classes.tabTitle}>
                Search Existing Contact Here
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            {/*property detials first card */}
            <Box className={classes.viewCard}>
              <Grid container spacing={2}>
                <Grid item xs={1.5} style={{ padding: "0px !important" }}>
                  <Box className={classes.image}>
                    <Typography className={classes.customTypography}>
                      PROFILE IMAGE
                    </Typography>
                    <DetailsCardCoponent
                      components={[
                        {
                          size: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                          },
                          isActive: true,
                          component: "image",
                          image: dataNew?.image_url ?? "",
                        },
                      ]}
                    />
                  </Box>
                </Grid>
                <Grid item xs={10.5}>
                  <Typography className={classes.customTypography}>
                    OPPORTUNITY DETAILS
                  </Typography>
                  <DetailsCardCoponent components={card} />
                </Grid>
              </Grid>
            </Box>
            {/* address card */}
            {dataNew?.address?.length > 0 && (
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                  <Typography className={classes.customTypography}>
                    Address
                  </Typography>
                  <AddressCard
                    data={dataNew?.address ?? ""}
                    name={dataNew?.name ?? ""}
                    buttontext="primary"
                    isView
                  />
                </Grid>
              </Grid>
            )}

            {/* social media card */}
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12}>
                <Typography className={classes.customTypography}>
                  Social Profile
                </Typography>
                <DetailsCardCoponent components={card1} />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};
