import React from "react";
import { withNavBars } from "../../HOCs";
import { OperationsManagerDashboard } from "./operationsManagerDashboard";

class OperationsManagerDashboardParent extends React.Component {
    render() {
        return <OperationsManagerDashboard {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(OperationsManagerDashboardParent, props);