import { Box, Grid, Stack, Typography } from "@mui/material";
import { TextBox } from "../index";
import {CompanyToggle} from "../../components";
export const TextWithToogle = ({
    onChange = () => onChange,
    value = "",
    isError = false,
    errorMessage = "",
    options = [],
    isReadonlySelect = false,
    isReadonly = false,
    custom = false,
    openCustom = false,
    isrequired = false,
    is_toogle=false
}) => {

    const onChangeValue = (key, val) => {
        let updateValue = {
            ...value,
            [key]: val
        }
        onChange && onChange(updateValue)
    }

    return (
        <Grid container spacing={1} >
            <Grid item xs={12}>
                <Stack spacing={1} direction="row" alignItems="flex-end">
                    <Box width="60px">
                        <TextBox
                            isrequired={isrequired}
                            label="Duration"
                            placeholder=""
                            isReadonly={isReadonly}
                            onChange={(value) => onChangeValue("value", value.target.value)}
                            value={value?.value ?? ""}
                        />
                    </Box>
                    {
                        is_toogle&& <CompanyToggle
                        onChange={(value) => onChangeValue("select", value)}
                        value={value?.select}
                        options={options}
                        disabled={isReadonly}
                        isMulti
                        custom={custom}
                        openCustom={openCustom}
                        height="46px"
                    />
                    }

                </Stack>

            </Grid>

            <Grid item xs={12}>
                {isError && (
                    <Typography variant={"caption"} color={"error"}>
                        {errorMessage}
                    </Typography>
                )}
            </Grid>

        </Grid>
    )
}