import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useStyles } from "../styles";
export const Person = ({ data = {} }) => {
    const classes = useStyles()
    return (
        <Box className={classes.personRoot} display="flex" alignItems="center">
            <Box>
                <Avatar src={data?.image_url ?? null} />
            </Box>
            <Box marginLeft="8px">
                <Typography className={classes.name}>{data?.first_name}&nbsp;{data?.last_name ?? null}</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography className={classes.subTitle}>{data?.email_id}</Typography>
                    <Box className={classes.dot} />
                    <Typography className={classes.subTitle}>{data?.mobile_no_country_code} {data?.mobile_no}</Typography>
                </Stack>
            </Box>
        </Box>
    )
} 