import { Box, Grid, Typography, Stack, Button } from "@mui/material"
import moment from "moment"
import React, { useState } from "react"
import { ActivityCard, CustomSelectOptions, FormGenerator, NewDatePicker, TextBox } from "../../../components"
import { config } from "../../../config"
import { AlertContext } from "../../../contexts"
import { GET_ACTIVITY_CATAGORY_MASTER, LEAD_ACTIVITY_MASTER } from "../../../graphql/queries"
import { NetworkCall } from "../../../networkcall"
import { AlertProps, enumSelect, enum_types, NetWorkCallMethods } from "../../../utils"
import { loadOptions, loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions"
import { ListCard } from "./leadCard"
import { useStyles } from "./style"

export const CreateActivity = ({ setSize = () => false, calendarFunctions = () => false, handleClose = () => false, selectedCompany = {} }) => {
    const classes = useStyles()
    const Categories = ["Lead", "Opportunity"]
    const [showActivityType, setShowActivityType] = useState(true)
    const [activityData, setActivityData] = React.useState([])
    const alert = React.useContext(AlertContext)
    const [data, setData] = useState({
        type: "",
        title: "",
        category: "Lead",
        dateTime: new Date(),
        notes: "",
        selectedProperty: "",
        lead_list: "",
        Priority: "",
        categoryData: "",
        email: "",
        followup: "",
        error: {
            title: "",
            dateTime: "",
            notes: "",
            Priority: "",
            lead_list: "",
            categoryData: "",
            email: "",
            followup: ""
        }
    })
    const [enumValue, setEnumValue] = React.useState({
        priority: [],
        followUp: []
    })
    const getLeadActivityMaster = () => {
        const payload = {
            query: LEAD_ACTIVITY_MASTER().loc.source.body,
            variable: {}
        };

        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setActivityData(res?.data?.data?.lead_activity_master)
        }).catch((err) => {
            console.log(err)
        });
    }
    React.useEffect(() => {
        setSize({ isMedium: true, isNormal: false })
        getEnum()
        getLeadActivityMaster()
        //eslint-disable-next-line
    }, [])
    const handleClick = (val) => {
        setShowActivityType(false)
        updateState("type", val)
        setSize({ isMedium: false, isNormal: true })
    }
    const updateState = (k, v) => {
        setData({ ...data, [k]: v })
    }

    const getEnum = async () => {
        const result = await enumSelect([
            enum_types?.urgent_type,
            enum_types?.follow_up_type,
        ]);
        setEnumValue({
            priority: result?.urgent_type ?? [],
            followUp: result?.follow_up_type,
        });
    };

    const validate = () => {
        let isValid = true;
        let error = data.error
        if (data?.notes === "") {
            isValid = false; error.type = "Notes is Required"
        }
        if (data?.lead_list === "") {
            isValid = false; error.type = "Lead List is Required"
        }
        if (data?.Priority === "") {
            isValid = false; error.type = "Priority is Required"
        }
        if (data?.categoryData === "") {
            isValid = false; error.type = "category is Required"
        }
        if (data?.type?.title === "Send Quotation" || data?.type?.title === "Send Email") {
            if (data?.email?.length === 0) {
                isValid = false; error.email = "Email is Required"
            }
        }
        if (data?.type?.title === "Follow up" ||
            data?.type?.title === "Meeting" ||
            data?.type?.title === "Appointment") {
            if (data?.followup?.value?.length === 0) {
                isValid = false; error.followup = "Followup is Required"
            }
        }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Please fill all mandatory field",
            })
        }
        setData({ ...data, error })
        return isValid
    }
    const save = () => {
        if (validate()) {
            const activity = {
                activity_category: data?.categoryData?.value,
                notes: data?.notes,
                priority: data?.Priority ?? undefined,
                proxy_lead_id: data?.category === "Lead" ? data?.lead_list?.proxy_lead_id : undefined ?? undefined,
                lead_id: data?.category !== "Lead" ? data?.lead_list?.lead_id : undefined ?? undefined,
                phone_number: data?.lead_list?.mobile_no ?? undefined,
                date_time: `${moment(data?.dateTime).format("YYYY-MM-DD HH:mm:ss")}` ?? undefined,
                phone_code: data?.lead_list?.mobile_country_code ?? undefined,
                lead_activity_master: data?.category === "Lead" ? data?.type?.id : undefined,
                lead_activitiy_master: data?.category !== "Lead" ? data?.type?.id : undefined,
                created_by: localStorage.getItem("profileID"),
                status: "Open",
                company_id: selectedCompany?.value,
                email_id: data?.email ?? undefined,
                type: data?.followup?.value ?? undefined
            };

            NetworkCall(
                data?.category === "Lead" ? `${config.api_url}/lead_proxy/activity` : `${config.api_url}/lead/activity`,
                NetWorkCallMethods.post,
                {
                    activity,
                },
                null,
                true,
                false
            )
                .then((response) => {
                    calendarFunctions()
                    handleClose()
                })
                .catch((error) => {
                    // alert.setSnack({
                    //     ...alert,
                    //     open: true,
                    //     severity: AlertProps.severity.error,
                    //     msg: "Internal error. Please try again later.",
                    // });
                    console.log(error)
                });
        }
    };

    const manualResponse = (array) => array?.map(_ => {
        return {
            ..._,
            label: `${_?.lead_name} - ${_?.lead_no}`,
            value: _?.lead_id,
        }
    });

    return (
        <Box>
            {showActivityType &&
                <Grid container style={{ padding: "12px" }}>
                    {activityData.map((val) => {
                        return (
                            <Grid item xs={6} md={4}>
                                <ActivityCard data={val} activityBoard onClick={() => handleClick(val)} />
                            </Grid>
                        );
                    })}
                </Grid>
            }
            {
                !showActivityType &&
                <>
                    <Box p={2} className={classes.content}>
                        <Typography className={classes.categoryText}>Select Category</Typography>
                        <Stack direction="row" spacing={1} mt={1}>
                            {Categories?.map((x, i) => {
                                return (
                                    <Stack className={data?.category === x ? classes.selectedtoggle : classes.togglebtntype} onClick={() => {
                                        updateState("category", x)
                                        setData({ ...data, category: x, lead_list: "" })
                                    }}>{x}</Stack>
                                )
                            })}
                        </Stack>
                        <Box mt={1}>
                            <Typography className={classes.categoryText}>{data?.category}</Typography>
                            <Box mt={2}>
                                {
                                    data?.lead_list?.label ?
                                        <ListCard name={data?.lead_list?.lead_name}
                                            leadNo={data?.lead_list?.lead_no}
                                            url={data?.lead_list?.lead_image_url}
                                            handleDelete={() => updateState("lead_list", "")} />
                                        :
                                        <CustomSelectOptions
                                            menuPlacement="auto"
                                            key={data?.category}
                                            loadOptions={(search, array, handleLoading) =>
                                                loadOptionsApis(
                                                    "activity_board/get_all_leads",
                                                    {
                                                        company_id: 1,
                                                        team_id: 1,
                                                        is_proxy_lead: data?.category === "Lead" ? true : false
                                                    },
                                                    search,
                                                    array,
                                                    handleLoading,
                                                    "data",
                                                    {},
                                                    manualResponse
                                                )
                                            }
                                            isPaginate={true}
                                            isRequired
                                            onChange={(val) => updateState("lead_list", val)}

                                        />
                                }
                            </Box>
                        </Box>
                        <Box mt={2}>
                            <TextBox
                                label="Title"
                                value={data?.title}
                                placeholder="Enter Title"
                                onChange={(e) => updateState("title", e.target.value)}
                                isRequired
                            />
                        </Box>
                        {(data?.type?.title === "Send Quotation" || data?.type?.title === "Send Email") && <Box mt={2}>
                            <TextBox
                                label="Email"
                                value={data?.email}
                                placeholder="Enter Email"
                                onChange={(e) => updateState("email", e.target.value)}
                                isRequired
                            />
                        </Box>}
                        <Box mt={2}>
                            <FormGenerator
                                components={[
                                    {
                                        isActive: true,
                                        component: "select",
                                        label: "Category",
                                        value: data?.categoryData,
                                        placeholder: "Category",
                                        onChange: (value) => updateState("categoryData", value),
                                        error: data?.error?.categoryData,
                                        isRequired: true,
                                        size: {
                                            xs: 12,
                                            sm: 12,
                                            md: 12,
                                            lg: 12,
                                        },
                                        isPaginate: true,
                                        loadOptions: (search, array, handleLoading) =>
                                            loadOptions(
                                                search,
                                                array,
                                                handleLoading,
                                                GET_ACTIVITY_CATAGORY_MASTER,
                                                "activity_category_master",
                                                { isActive: true },
                                                { label: "label", value: "value" },
                                                false,
                                                false,
                                                data?.type?.id
                                            ),
                                    },
                                    {
                                        isActive:
                                            data?.type?.title === "Follow up" ||
                                                data?.type?.title === "Meeting" ||
                                                data?.type?.title === "Appointment"
                                                ? true
                                                : false,
                                        component: "select",
                                        label: "Followup Type",
                                        value: data?.Followup,
                                        placeholder: "Followup",
                                        onChange: (value) => updateState("followup", value),
                                        error: data?.state?.error?.Followup,
                                        isRequired: true,
                                        size: {
                                            xs: 12,
                                            sm: 12,
                                            md: 12,
                                            lg: 12,
                                        },
                                        options: enumValue?.followUp ?? [],
                                    },
                                    {
                                        size: {
                                            xs: 12,
                                            sm: 12,
                                            md: 12,
                                            lg: 12,
                                        },
                                        isActive: true,
                                        component: "toggle",
                                        label: "Priority",
                                        value: data?.Priority,
                                        placeholder: "Priority",
                                        onChange: (value) => updateState("Priority", value),
                                        error: data?.error?.Priority,
                                        isRequired: true,
                                        options: enumValue?.priority ?? [],
                                    }]} />
                        </Box>
                        <Box mt={2}>
                            <NewDatePicker
                                label="Date & Time"
                                placeholder="Select Visit Date & Time"
                                value={data?.dateTime ?? ""}
                                isRequired
                                onChange={(value) => {
                                    updateState("dateTime", value)
                                }}
                                // maxDate={new Date()}
                                isError={data?.error?.dateTime?.length > 0}
                                errorMessage={data?.error?.dateTime}
                                newDate
                                hideCondition={false}
                            />
                        </Box>
                        <Box mt={2}>
                            <TextBox
                                label="Notes"
                                value={data?.notes}
                                placeholder="Enter Notes"
                                height={"80px"}
                                onChange={(e) => updateState("notes", e.target.value)}
                                isRequired
                            />
                        </Box>

                    </Box>
                    <Box p={2} display={"flex"} justifyContent={"end"} mt={4}>
                        <Stack direction="row" spacing={1} alignItems="end">
                            <Button variant="outlined">Cancel</Button>
                            <Button variant="contained" onClick={() => save()}>Save</Button>

                        </Stack>
                    </Box>
                </>
            }
        </Box>
    )
}