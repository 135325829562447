import { Box, Grid } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { SearchFilter, TableWithPagination } from "../../../components";
import { getInspectionHeading, inspectiondataType, inspectionPath } from "../../../utils/agreementUtils";

const InspectionTable = ({
    handleSearch = () => false,
    searchText = "",
    list = [],
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    handleIcon = () => false,
    t

}) => {
    const heading = getInspectionHeading(t)
    return (
        <Box p={1}>
            <Grid container>
                <Grid item xs={4}>
                    <SearchFilter value={searchText} handleChange={(e) => handleSearch(e)} placeholder={t("search_inspection")} />
                </Grid>
            </Grid>
            <TableWithPagination
                heading={heading}
                rows={list?.list}
                path={inspectionPath}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                showpagination={true}
                handleIcon={handleIcon}
                dataType={inspectiondataType}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={list?.count}
                page={page}
                limit={limit}
                height={`calc(100vh - 390px)`}
                view={true}
                edit={true}
                delete={true} />

        </Box>
    )
}
export default withNamespaces("agreement")(InspectionTable); 