import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const InspectionCardStyles = makeStyles((theme) => ({
    box: {
        padding: "8px 16px 16px 16px",
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
        borderRadius: "4px",
        border: `1px solid ${theme.palette.border.secondary}`
    },
    title: {
        fontFamily: Bold,
        color: theme.typography.color.primary,
        fontSize: "14px"
    },
    heading: {
        fontFamily: Bold,
        color: theme.typography.color.tertiary,
        fontSize: "10px"
    },
    subTitle: {
        color: theme.typography.color.tertiary,
        fontSize: "12px"
    },
    status: {
        fontFamily: Bold,
        fontSize: "10px",
        padding: "2px 8px",
        borderRadius: "4px"
    },
    avatar: {
        borderRadius: "4px"
    },
    resendHeading: {
        color: theme.typography.color.secondary,
        fontSize: "14px",
        fontFamily: SemiBold,
    },
    resend: {
        color: theme.palette.primary.main,
        fontSize: "12px",
        fontFamily: Bold,
    },
    sendLink: {
        fontFamily: Bold,
        fontSize: "12px",
        padding: "2px 8px",
        borderRadius: "4px",
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.info.light
    }

}));