import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { config } from '../../../config';
import { AlertContext } from '../../../contexts';
import { NetworkCall } from '../../../networkcall';
import { AlertProps, NetWorkCallMethods } from '../../../utils';
import { proofComponentStyles } from "../styles";


export const UploadFile = (props) => {

    const {
        label = "",
        handleChange = null,
        id = "",
    } = props;



    const alert = React.useContext(AlertContext);

    const [loading, setLoading] = React.useState(false);
    const classes = proofComponentStyles()
    const types = ["application/pdf", "image/png", "image/jpg", "image/jpeg"]
    const handleUpload = async (data, e) => {
        if (data?.length) {
            if (types.includes(data?.[0]?.type)) {
                executeImageData(data)
            } else {
                alert.setSnack({
                    open: true,
                    horizontal: AlertProps.horizontal.center,
                    vertical: AlertProps.vertical.top,
                    msg: `Invalid file`,
                    severity: AlertProps.severity.error,
                });
            }
        }

    }

    const executeImageData = async (data) => {

        setLoading(true);

        const formData = new FormData();
        formData.append("files", data?.[0]);
        formData.append("tenantId", `${config.tenantId}`);

        await NetworkCall(
            `${config.api_url}/file-upload`,
            NetWorkCallMethods.post,
            formData,
            null,
            true,
            false
        )
            .then((response) => {
                handleChange && handleChange(
                    {
                        src: response.data.fileUrls[0].url,
                        name: data[0]?.name,
                        type: data[0]?.type
                    },
                    id
                );
                setLoading(false);
            })
            .catch((err) => {
                handleChange(null);
                setLoading(false);
                alert.setSnack({
                    open: true,
                    horizontal: AlertProps.horizontal.center,
                    vertical: AlertProps.vertical.top,
                    msg: `Unable to upload`,
                    severity: AlertProps.severity.error,
                });
            });
    }

    return (
        <Box>

            <label htmlFor={`uplodebtn${id}`}>
                <Box className={classes.fileroot}>
                    <FileUploadOutlinedIcon className={classes.uploadIcon} />
                    <Typography className={classes.uploadlabel}>{loading ? "Uploading..." : label}</Typography>

                </Box>
            </label>
            <input
                type="file"
                name="img"
                style={{ display: "none" }}
                id={`uplodebtn${id}`}
                onChange={(value) => handleUpload(value.target.files, value)}

            />
        </Box>
    )
}