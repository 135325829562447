import { Grid } from "@mui/material";
import React from 'react';
import CityProperty from '../../../assets/cityproperty';
import { AlertDialog, OtherInfoCard } from '../../../components';
import { getCustomBlockName, getCustomFloorName } from "../../../utils";
import { Blocks } from './blocks';
import { Floors } from './floors';
import { Units } from './units';

export const OtherInfoViewing = (props) => {
    const property_hierarchy = props?.data?.property?.[0]?.property_hierarchyByID ?? {}
    const data = [
        {
            id: 1,
            name: "Total " + getCustomBlockName(property_hierarchy, "Blocks"),
            view: "View " + getCustomBlockName(property_hierarchy, "Blocks"),
            count: props?.data?.property?.[0]?.block_count?.[0]?.count_id,
            image: <CityProperty color={"#F5FFFB"} />,
            color: "#46B9DA",
            show: property_hierarchy?.is_block,
        },
        {
            id: 2,
            name: "Total " + getCustomFloorName(property_hierarchy, "Floors"),
            view: "View " + getCustomFloorName(property_hierarchy, "Floors"),
            count: props?.data?.property?.[0]?.floor_count?.[0]?.count_id,
            image: <CityProperty color={"#F5FFFB"} />,
            color: "#E29336",
            show: property_hierarchy?.is_floor,
        },
        {
            id: 3,
            name: "Total Units",
            view: "View Units",
            count: props?.data?.property?.[0]?.unit_count?.[0]?.count_id,
            image: <CityProperty color={"#F5FFFB"} />,
            color: "#6248E2",
            show: property_hierarchy?.is_unit,
        },
    ]
    const [view, setView] = React.useState(false)
    const [index, setIndex] = React.useState(0)
    const open = () => {
        setView(true)
    }

    const close = () => {
        setView(false)
    }
    return (

        <div>
            <Grid container spacing={2}>
                {data.map((item, index) => {
                    return (
                        item?.show && <Grid item xs={2.4} height={"250px"}>
                            <OtherInfoCard data={item} index={index} open={open} setIndex={setIndex} />
                        </Grid>
                    )
                })}

            </Grid>
            {/* General request popup */}
            <AlertDialog
                open={view}
                onClose={close}
                header={<> {
                    index === 0 && getCustomBlockName(property_hierarchy, "Blocks")
                }
                    {
                        index === 1 && getCustomFloorName(property_hierarchy, "Floors")
                    }
                    {
                        index === 2 && "Units"
                    }

                </>
                }
                component={
                    <> {
                        index === 0 && <Blocks />
                    }
                        {
                            index === 1 && <Floors />
                        }
                        {
                            index === 2 && <Units />
                        }

                    </>
                }
            />
        </div >


    )
}