import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import { VerticalStepperStyles } from './styles';



export const VerticalLinearStepper = ({ data = [],icon="",customTrack=false }) => {

    const classes = VerticalStepperStyles()


    return (
        <Box>
            {data?.map((x, index) => (
                <Box display={"flex"}>
                    <Box>
                        {/* circleIcon */}
                        <Box className={customTrack?classes.applybackground:""}>
                            <Box pt={customTrack?1:0}>{icon}</Box>
                            </Box>
                        {/* connector */}
                        {index !== data.length - 1 &&
                            <div className={customTrack?`${classes.customconnector}`:`${classes.connector}`}></div>
                        }
                    </Box>
                    <Box className={`${classes.detail}`}>
                        {/* name */}
                        <Typography className={`${classes.Name}`}>{x.name}</Typography>
                        {/* description */}
                        <Typography className={`${classes.description}`}>{x.description}</Typography>
                    </Box>

                </Box>
            ))}
        </Box>
    );
}
