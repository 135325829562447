import React from "react";
import { withNavBars } from "../../HOCs";
import { CompanyCreation } from "./companyCreation";
import { CompanyDetails } from "./companyDetails";
import { withRouter } from "react-router-dom";
import { Routes } from "../../router/routes";
class CompanyCreationParent extends React.Component {


    render() {
        const { match, history } = this.props;
        return (
            <>
                {match.path === Routes.companyCreation && <CompanyCreation {...this.props} />}
                {
                    (match.path === Routes.editcompany && history?.location?.state?.from === "edit") ? (
                        <CompanyCreation isEdit={true} {...this.props} />
                    ) : (
                        <>
                            {
                                (match.path === Routes.editcompany) && <CompanyDetails {...this.props} />
                            }
                        </>
                    )
                }
            </>
        );
    }
}
const props = {
    boxShadow: false
}

export default withRouter(withNavBars(CompanyCreationParent, props));
