import React from "react";
import { withNavBars } from "../../HOCs";
import { AmenitiesMapping } from "./amenitiesMapping";

class AmenitiesMappingMasterParent extends React.Component {
    render() {
        return <AmenitiesMapping  {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AmenitiesMappingMasterParent, props);
