import React from "react";
import { Otp } from "./otp";
import { LoginSuccess } from "../../router/access";
import { LocalStorageKeys } from "../../utils";
import { withRouter } from 'react-router-dom';

class OtpPagetParent extends React.Component {
    componentDidMount(){
        if(localStorage.getItem(LocalStorageKeys.authToken)){
            if(localStorage.getItem(LocalStorageKeys.permission)){
                this.props.history.push(LoginSuccess(localStorage.getItem(LocalStorageKeys.permission)))
            }
        }
    }
    render() {
        return <Otp />;
    }
}

export default withRouter(OtpPagetParent);
