import { Box, Button, Popover, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useStyles } from "../styles";
import { Person } from "./personDetails";
import { AlertDialog } from "../../../components";
import { saveAs } from "file-saver";
import { Download } from "../../../assets";
import { DocumentViewer } from "../../../components/fileViewer";
import {timeZoneConverter} from '../../../utils'

const CardDetails = ({ data, t }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialog, setDialog] = React.useState({
    bool: false,
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const openDialog = (details) => {
    if (!details) {
      setDialog({
        bool: !dialog?.bool,
      });
    } else {
      setDialog({
        data: details,
        bool: !dialog?.bool,
      });
    }
  };
  //render btn
  const renderButton = () => {
    return (
      <Button
        onClick={downloadPdf}
        className={classes.downBtn}
        variant="outlined"
        startIcon={<Download />}
      >
        Download
      </Button>
    );
  };
  //download pdf
  const downloadPdf = () => {
    saveAs(data?.qutation?.url, `${data?.qutation?.quotation_no}.pdf`);
  };

  return (
    <Box paddingTop="7px" marginLeft="12px">
      <Box className={classes.timeLineMain}>
        <Typography className={classes.title}>{data?.title ?? ""}</Typography>

        {data?.is_qutation && (
          <Typography className={classes.subTitleTimeLine}>
            <span
              className={classes.link}
              onClick={() => openDialog(data?.qutation)}
            >
              {data?.qutation?.quotation_no ?? ""}
            </span>
            ,
            <span>
              {timeZoneConverter(data?.details?.updated_at)}
            </span>
          </Typography>
        )}
        {data?.is_owner && (
          <Typography className={classes.assigned}>
            {t("owner")} :{" "}
            <span onMouseEnter={handleClick} className={classes.link}>
              {data?.assignee?.user_first_name ?? ''}&nbsp;
              {data?.assignee?.user_last_name ?? ''}
            </span>
          </Typography>
        )}
        {data?.sub?.length > 0 && (
          <Typography className={classes.subTitleTimeLine}>
            {data?.sub ?? ''}
          </Typography>
        )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className={classes.popover}
      >
        <Person data={{
          first_name:data?.assignee?.user_first_name ?? '-',
          last_name:data?.assignee?.user_last_name ?? '',
          email_id:data?.assignee?.email_id ?? '-',
          mobile_no_country_code:data?.assignee?.mobile_no_country_code ?? '-',
          mobile_no:data?.assignee?.mobile_no ?? "-",
          image_url:data?.assignee?.image_url ?? ""
        }} />
      </Popover>

      {/* quotation pdf */}
      <AlertDialog
        header={`Quotation - ${data?.qutation?.quotation_no}`}
        onClose={() => openDialog(false)}
        open={dialog?.bool}
        render={renderButton()}
        component={
          <Box p={1} bgcolor={"#F5F7FA"}>
            <center>
              {dialog?.data?.url ? (
                <DocumentViewer url={data?.qutation?.url} />
              ) : (
                "No Data Found"
              )}
            </center>
          </Box>
        }
      />
    </Box>
  );
};
export default withNamespaces("agreement")(CardDetails);
