import { Stack, Button } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { TextBox, ToggleButtonComponent } from '../../components'
import { AddRoleStyle } from './style'
import { StatusOptionList } from '../../utils/inventory'

const AddRolePopup = (
    {
        t,
        closePopup = () => false,
        state = {},
        create = () => false,
        roleState = {},
        updateState = () => false,
        update = () => false
    }
) => {
    const classes = AddRoleStyle()

    const buttonStyle = {
        borderColor: "#E4E8EE",
        borderRadius: 1,
        backgroundColor: "#fff",
    }

    return (
        <Stack spacing={2} p={2}>
            <TextBox
                label={t("RoleName")}
                onChange={(e) => updateState('role_name', e.target.value)}
                value={roleState?.role_name}
                placeholder=""
                isReadonly={roleState?.view ? true : false}
                isError={roleState?.error?.role_name.length > 0}
                errorMessage={roleState?.error?.role_name}
                isrequired
            />
            <TextBox
                label={t("Responsibilities")}
                onChange={(e) => updateState('responsibilities', e.target.value)}
                value={roleState?.responsibilities}
                placeholder=""
                multiline={true}
                isReadonly={roleState?.view ? true : false}
                isError={roleState?.error?.responsibilities.length > 0}
                errorMessage={roleState?.error?.responsibilities}
                isrequired
            />
            <ToggleButtonComponent
                label={t("Status")}
                options={StatusOptionList}
                value={roleState?.status}
                onChange={(value) => updateState('status', value)}
                isMulti={false}
                fullWidth={false}
                buttonStyle={buttonStyle}
                height="33px"
                isReadOnly={roleState?.view ? true : false}
                isError={roleState?.error?.status !== null}
                errorMessage={roleState?.error?.status}
                isrequired />
            <Stack direction={'row'} justifyContent={"right"}>
                {
                    !roleState?.view &&
                    <Stack direction={"row"} spacing={1}>
                        <Button variant="outlined" className={classes.cancelBtn} onClick={closePopup}>{t("Cancel")}</Button>
                        <Button variant="contained" className={classes.addBtn} onClick={roleState?.edit ? update : create}>{roleState?.edit ? t("Update") : t("Add")}</Button>
                    </Stack>
                }
            </Stack>
        </Stack>
    )
}
export default withNamespaces("managementCommitte")(AddRolePopup)