import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    cells: {
        display: "flex",
        justifyContent: "center",
        height: "auto",
        border: `1px solid ${theme.palette.border.secondary}`,
        alignItems: "center",
        padding: 8
    },
    content_cells: {
        // display: "flex",
        // height: "auto",
        border: `1px solid ${theme.palette.border.secondary}`,
        padding: 6
    },
    dayLabel: {
        fontSize: 12,
        textTransform: "uppercase",
        color: theme.typography.color.tertiary
    },
    dateLabel: {
        fontSize: 16,
        fontWeight: "bold",
        textTransform: "uppercase",
        color: theme.typography.color.secondary
    },
    time_label: {
        fontSize: 12,
        color: theme.typography.color.tertiary,
        textAlign: "center"
    },
    content: {
        height: "100%",
        // overflow: "auto"
    },

}))

export const ScheduleCardStyle = makeStyles((theme) => ({
    card: {
        // height: 50,
        padding: 4,
        width: "100%",
        cursor:"pointer"
    },
    avatar: {
        height: 22,
        width: 22,
        // marginTop: 4
    },
    status: {
        fontSize: 12,
        fontWeight: "bold",
        whiteSpace: "nowrap"
    },
    request: {
        fontSize: 12,
    },
    countNo: {
        fontSize: "16px",
        fontFamily: Bold,
        color: theme.typography.color.secondary
    },
    type: {
        fontSize: "14px",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    viewAll: {
        fontSize: "12px",
        fontFamily: Bold,
        color: "#5078E1",
        textAlign: "center",
        marginTop: "20px"
    }
}))
export const HeaderStyles = makeStyles((theme) => ({
    dateLabel: {
        fontSize: 14,
        fontWeight: "bold",
        color: theme.typography.color.primary
    },
    button: {
        border: `1px solid ${theme.palette.border.secondary}`,
        height: 35,
        width: 35,
        textAlign: "center"
    },
    button_icon: {
        fontSize: 16,
        color: theme.typography.color.tertiary
    },
    dropdown: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6,
        cursor: "pointer",
        zIndex: 1,
        position: "relative",
        backgroundColor: "#fff",

    },
    list: {
        position: "absolute",
        zIndex: 1,
        backgroundColor: "#fff",
        width: "100%",
        left: 0,
        padding: "12px",
        borderRight: `1px solid ${theme.palette.border.secondary}`,
        borderLeft: `1px solid ${theme.palette.border.secondary}`,
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
    },
    dropdown_name: {
        fontSize: 14,
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    dropdown_user_type: {
        fontSize: 12,
        color: theme.typography.color.tertiary
    },
    dropdown_icon: {
        fontSize: 20,
        color: theme.typography.color.tertiary
    },

}))
export const CustomDropDownStyles = makeStyles((theme) => ({
    customdrp: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6,
        cursor: "pointer",
        right: "3%",
        backgroundColor: "#fff",
        top: "23%",
        width:"125px"
    },
    selected: {
        '&:hover': {
            color: theme.palette.info.main
        }
    }
}))
export const MonthlyCalendarStyles = makeStyles((theme) => ({
    // calendar dates styles
    calDates: {
        textAlign: "center",

    },
    date: {
        color: theme.typography.color.secondary,
        fontSize: 18,
        fontWeight: "bold"
    },
    date_outline: {
        color: "black",
        border: `1px solid ${theme.palette.border.secondary}`,
        height: 90,
    },
    hidden_other_month_dates: {
        visibility: "hidden"
    },
    eventlabel: {
        overflow: "hidden",
        width: "98%",
    },
    eventtxt: {
        whiteSpace: "nowrap",
        width: "99%",
        fontSize: 12,
    },
    moreLabel: {
        float: "left",
        fontSize: 12,
        fontWeight: "bold",
        color: theme.palette.info.main,
    },
    daysText: {
        textAlign: "center",
        marginBottom: "8px",
        fontSize: 12,
        color: theme.typography.color.tertiary,
        fontFamily: Regular
    }
}))
export const DayCalendarStyles = makeStyles((theme) => ({
    header_timecell: {
        height: 40,
        fontSize: 12,
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        borderLeft: `0.5px solid ${theme.palette.border.secondary}`,
        borderBottom: `0.5px solid ${theme.palette.border.secondary}`,
        borderTop: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        fontSize: 12,
        color: theme.typography.color.tertiary,
        borderLeft: `0.5px solid ${theme.palette.border.secondary}`,
        borderBottom: `0.5px solid ${theme.palette.border.secondary}`,

    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        //height: 100,
        borderLeft: `1px solid ${theme.palette.border.secondary}`,
        borderRight: `1px solid ${theme.palette.border.secondary}`,
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        //overflow: "auto"
    },
    event_label: {
        padding: 2,
        fontSize: 12,
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
    },
    calRow: {
        height: "calc(100vh - 300px)",
        overflow: "auto",
        width: "100%",

    },
    moreLabel: {
        fontSize: 12,
        color: theme.palette.info.main,
        fontWeight: "bold",
        marginLeft: 8,
        cursor: "pointer"
    },
    customTooltip: {
        // '& .MuiTooltip-arrow':{
        //     backgroundColor:"#fff",
        //     color:"#fff",
        //     border: '1px solid #dadde9'
        // }
        '& .MuiTooltip-arrow': {
            background: 'red',
        },
        "&:before": {
            border: "1px solid #E6E8ED"
        },

    },
    ttavatar: {
        height: 24,
        width: 24,
    },
    ttTitle: {
        fontSize: 14,
        fontWeight: "bold",
        color: theme.typography.color.primary,
        textTransform: "capitalize"
    },
    ttsecTitle: {
        fontSize: 12,
        textTransform: "capitalize",
        color: theme.typography.color.secondary,
    },
    ttassigned_by: {
        fontSize: 10,
        color: theme.typography.color.tertiary
    },
    ttname: {
        fontSize: 16,
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    },
    arrow: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: "white",
        position: "absolute",
        top: "0px",
        left: "0px",
        fontSize: "40px"
    },
    toolRoot: {
        border: '1px solid #E4E8EE',
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        borderRadius: "4px",

    },
    menuItem: {
        backgroundColor: "#FFFFFF !important",
        "&:hover": {
            backgroundColor: "#FFFFFF !important",
        }
    },
    menuList: {
        backgroundColor: "#FFFFFF !important",
        "&:hover": {
            backgroundColor: "#FFFFFF !important",
        },

    },
    MuiListItemButton: {
        styleOverrides: {
          root: {
              backgroundColor: '#FFFFFF !important',
            }
        },
      },
}))
