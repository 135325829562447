import styled from '@mui/material/styles/styled';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import makeStyles from "@mui/styles/makeStyles";
import React from 'react';
import { Regular, SemiBold } from '../../utils';
import { CustomBtn } from "./fieldbtn";
export const btnStyles = makeStyles((theme) => ({
    input: {
        width: "60px",
        border: "none",
        outline: "none"
    },
    custom: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        height: "38px"
    },
    check: {
        color: "#5078E1",
        marginTop: "4px",
        marginRight: "4px",
        cursor: "pointer",
        borderRadius: theme.palette.borderRadius
    },
    chip: {
        backgroundColor: "#5078E1",
        boxShadow: 'none',
        fontSize: "12px",
        color: "white",
        borderRadius: "6px",
        fontFamily: SemiBold
    }
}))
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    borderRadius: theme.palette.borderRadius,
    width: "max-content",
    fontSize: "14px",

    fontFamily: Regular,
    textTransform: 'capitalize',
    whiteSpace: "nowrap",
    backgroundColor: "white",
    color: "#4E5A6B",
    '&.Mui-selected': {
        fontFamily: SemiBold,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    '&.MuiToggleButtonGroup-grouped': {
        '&:not(:last-of-type)': {
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
        },
        '&:not(:first-of-type)': {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            marginLeft: 0,
            borderColor: '#E4E8EE'
        },
    }
}))

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
    gap: 10,
    flexWrap: "wrap",

})

export const CompanyToggle = ({
    value = "", // 'string' => single selection ['string1','string2'] for multiple selection
    isMulti = false,
    onChange = null,
    buttonGroupStyle = {},
    buttonStyle = {},
    options = [],
    color = "primary",
    fullWidth = true,
    custom = false,
    handleDelete = false,
    height = false,
    disabled = false,
    showOnly = false,
    customValue = null,
    openCustom = false,
    selectBillingCycle = false,
    date = "",
    onDateChange = () => false,
    day = "",
    onCustomChange = () => false,
    isReadonly=false
}) => {
    const [show, setShow] = React.useState(openCustom ? false : true)

    return (

        <div>
            <StyledToggleButtonGroup
                value={value}
                exclusive={isMulti}
                onChange={(e, newValue) => {
                    onChange(newValue)
                    setShow(true)
                }}
                disabled={disabled}
                aria-label="toggle button"
                sx={{ ...buttonGroupStyle }}
                fullWidth={fullWidth}
            >
                {options?.length && options?.map(_ => (
                    <StyledToggleButton
                        value={_?.value}
                        aria-label={_?.value}
                        key={_?.value}
                        color={color}
                        sx={{ ...buttonStyle, height: height ? height : "38px" }}
                    >
                        {_?.label}
                    </StyledToggleButton>
                ))
                }
                {
                    custom &&
                    <>
                        {
                            show ?
                                <StyledToggleButton
                                    value="Custom"
                                    color={color}
                                    disabled={disabled}
                                    onClick={() => {
                                        setShow(false)
                                        onChange("")
                                    }}
                                    sx={{ ...buttonStyle, height: height ? height : "38px" }}
                                >
                                    Custom
                                </StyledToggleButton>
                                :
                                <CustomBtn
                                    height={height}
                                    value={value}
                                    setShow={setShow}
                                    customValue={customValue}
                                    onChange={(e) => {
                                        onChange(e)

                                    }}
                                    selectBillingCycle={selectBillingCycle}
                                    date={date}
                                    onDateChange={(e) => onDateChange(e)}
                                    noOfdays={value}
                                    onCustomChange={onCustomChange}
                                    disabled={disabled}
                                />
                        }
                    </>
                }


            </StyledToggleButtonGroup>
        </div>
    )
}
