import React from "react";
import { withNavBars } from "../../HOCs";
import { SalesDetails } from "./saleagentview";

class SalesDetailsParent extends React.Component {
    render() {
        return <SalesDetails />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(SalesDetailsParent, props);
