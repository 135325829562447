//Vehicle Master
export const VehicleMasterHeading = [
    { title: "", field: "icon" },
    { title: "Vehicle Id", field: "vehicle_id" },
    { title: "Vehicle Type", field: "vehicle_type" },
    { title: "Vehicle Name", field: "name" },
    { title: "Vehicle Number", field: "number" },
    { title: "Cost", field: "cost" },
    { title: "status", field: "status" },
];
export const VehicleMasterListPath = [
    "icon",
    "vehicle_id",
    "name",
    "number",
    "cost",
    "vehicle_type",
    "status",
];
export const VehicleMasterDataType = [
    { type: ["view_more"], icon: "icon" },
    { type: ["text"], name: "vehicle_id" },
    { type: ["text"], name: "vehicle_type" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "number" },
    { type: ["text"], name: "cost" },
    { type: ["status"], name: "status" },
];
export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]
export const initialState = {
    description: "",
    number: "",
    period: "",
    rate: "",
    name: "",
    isEdit: false,
    id: "",
    assets: [],
    vehicle_type: "",
    error: {
        description: "",
        number: "",
        period: "",
        rate: "",
        name: "",
        vehicle_type: "",
    }

}
export const returnEditPayload = (data) => {
    const result = {
        name: data?.data?.name ?? "",
        number: data?.data?.number ?? "",
        vehicle_type: data?.data?.vehicle_type ? {
            label: data?.data?.vehicle_type,
            value: data?.data?.vehicle_type,
        } : "",
        period: data?.data?.period ?
            {
                label: data?.data?.period,
                value: data?.data?.period,
            } : "",
        rate: data?.data?.rate ?? "",
        currency: data?.data?.company?.currency?.symbol ?? "",
        description: data?.data?.description ?? "",
        isEdit: true,
        id: data?.data?.id,
        assets: data?.data?.vehicle_assets ?? [],
        error: {
            description: "",
            number: "",
            period: "",
            rate: "",
            name: "",
            vehicle_type: "",
        }

    }
    return result;
}

export const returnSavePayload = (data, company, assets, userProfileId) => {
    let result2 = data?.id?.length > 0 ? {
        rate: data?.rate ? parseInt(data?.rate) : null,
        currency_id: company?.currency_id,
        company_id: company?.value,
        assets: assets,
        name: data?.name ?? null,
        number: data?.number ?? null,
        description: data?.description ?? null,
        vehicle_type: data?.vehicle_type?.value ?? null,
        period: data?.period?.value ?? null,
        id: data?.id?.length > 0 ? data?.id : null,
        user_profile_id: userProfileId ?? null
    }
        : {
            rate: data?.rate ? parseInt(data?.rate) : null,
            currency_id: company?.currency_id,
            company_id: company?.value,
            assets: assets,
            name: data?.name ?? null,
            number: data?.number ?? null,
            description: data?.description ?? null,
            vehicle_type: data?.vehicle_type?.value ?? null,
            period: data?.period?.value ?? null,
            user_profile_id: userProfileId ?? null
        }
    return result2
}