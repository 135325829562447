import { makeStyles } from "@mui/styles";
import { alpha } from '@mui/material/styles';
const drawerWidth = 360;
const styles = makeStyles((theme) => ({
  editButton: {
    marginLeft: "10px",
    backgroundColor: "#0071F2",
    borderRadius: "0px",
    color: "#fff",
  },
  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#5AC782",
      '&:hover': {
        backgroundColor: alpha("#5AC782", theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#5AC782",
    },
  },
  drawerHead: {
    display: "flex",
    padding: "19px 20px",
    borderBottom: "1px solid #eee",
  },
  drawerSubHead: {
    color: theme.typography.color.tertiary,
    fontSize: "12px",
  },
  addDrawerBtn: {
    backgroundColor: "#0071F2",
    color: "#fff",

    textTransform: "none",
    padding: "8px 60px",
    marginTop: "20px",
  },
  textTotalArea: {
    padding: "20px 30px",
  },
  headerMain: {

  },
  btnPosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  helperText: {
    color: "#ff0000",
  },
  root: {
    borderRadius: "0px",
    width: "100%",
    "& .MuiTableCell-sizeSmall": {
      paddingBottom: "0px",
      paddingTop: "0px",
    },
  },
  firstSec: {
    padding: "15px 45px",
    borderBottom: "3px solid #9b9999",
  },
  firstSecRepository: {
    padding: "15px 45px",
  },
  btn: {
    backgroundColor: "#0071F2",
    color: "#fff",
    padding: "6px 16px",

    fontSize: "12px",
    textTransform: "none",
  },
  rolesDisplay: {
    width: "99%",
    border: "1px solid #DCDCDC",
    display: "flex",
    padding: "14px 30px",
    borderRadius: theme.palette.borderRadius,
    "&:hover": {
      cursor: "pointer",
    },
  },
  lowerDisplay: {
    width: "99%",
    border: "1px solid #DCDCDC",
    display: "flex",
    padding: "14px 16px",
    borderRadius: theme.palette.borderRadius,
    alignItems: "center",
  },
  secondSec: {
    padding: "20px 45px",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      background: "#0071F2",
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "2px solid #f4f4f5",
    },
  },
  tabEdit: {
    textTransform: "none",
  },
  Section2: {
    height: `calc(100vh - 452px)`,
    overflow: "auto",
    paddingBottom: "25px",
  },
  Section2NoMain: {
    height: `calc(100vh - 192px)`,
    overflow: "auto",
    paddingBottom: "25px",
  },
  numbersEdit: {
    marginTop: "8px",
  },
  mainAreaRepository: {
    height: `calc(100vh - 216px)`,
    overflow: "auto",
    margin: "10px 0px",
    paddingBottom: "20px",
  },
  mainArea: {
    height: "250px",
    overflow: "auto",
    marginTop: "10px",
  },
  mainAreaNoData: {
    height: "0px",
  },
  selectedCell: {
    cursor: "pointer",
    border: "1px solid #0071F2",
    width: "99%",
    display: "flex",
    padding: "14px 30px",
    borderRadius: theme.palette.borderRadius,
  },

  paperEdit: {
    borderRadius: "0px",
  },
  innerText: {
    fontSize: "12px",

  },
  descriptionTab: {
    display: "flex",
    border: "1px solid #DCDCDC",
    padding: "14px 60px 14px 30px",
    borderRadius: theme.palette.borderRadius,
    marginTop: "15px",
    backgroundColor: "#f6f6f6",
  },
  contentBox: {
    position: "fixed",
    height: `calc(100% - 140px)`,
    width: `calc(100% - 105px)`,
  },
  table: {
    minWidth: 650,
    marginLeft: "18px",
    width: "97%",
    border: "1px solid lightgrey",
    borderRadius: "2px",
  },
  tableHeader: {
    paddingTop: "10px",
    paddingBottom: "10px",

    fontSize: "12px",
    color: "gray",
    backgroundColor: "#f9f9f9",
  },
  tableBody: {
    width: "120px",
    padding: "12px",

    fontSize: "12px",
  },
  tableContainer: {
    borderRadius: theme.palette.borderRadius,
    marginTop: "20px",
    boxShadow: "none",
    border: "1px solid #E0E0E0",
    height: `calc(100vh - 218px)`,
  },
  repoLeftSide: {
    height: `calc(100vh - 143px)`,
    borderRadius: "10px",
    backgroundColor: "#fff",
    border: "1px solid #DCDCDC",
  },
  active: {
    backgroundColor: "#E0EBF9 !important",
    color: "#0071F2 !important",
  },
  groupedBtnAlign: {
    padding: "5px",
    margin: "auto",
    display: "flex",
    justifyContent: "flex-end",
  },
  ml20: {
    marginLeft: "20px",
  },
  trash: {
    marginTop: "33px",
    marginLeft: "30px",
  },

  bgcolor: {
    backgroundColor: "#F9F9F9",
    paddingTop: "10px",
  },

  appBarShift: (props) => ({
    width: `calc(100% - 360px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: props?.drawerWidth ?? drawerWidth,
  }),
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: (props) => ({
    "& .MuiDialog-paper": {
      borderRadius: theme.palette.borderRadius,
      padding: "0px",
      maxWidth: "320px"
    },
  }),
  drawerPaper: (props) => ({
    width: props?.drawerWidth ?? drawerWidth,
  }),
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: (props) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: - (props?.drawerWidth ?? drawerWidth),
  }),
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  large: (props) => ({
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    position: "fixed",
    // right: "376px",
    right: props?.drawerWidth ? props?.drawerWidth + 16 : drawerWidth + 16,
    cursor: "pointer",
    top: 10,
    "& img": {
      objectFit: "scale-down",
      backgroundColor: "#fff"
    },
    [theme.breakpoints.down("xs")]: {
      right: "313px",
    },
  }),
}));

export default styles;
