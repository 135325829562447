import { Avatar, Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import moment from "moment";
import { AlertDialog } from "../dialog";
import { Slider } from "../slider";
import { Bold, Regular, SemiBold, timeZoneConverter } from '../../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "12px 14px",
    background: ' #F8F9FB 0% 0% no-repeat padding-box',
    borderRadius: theme.palette.borderRadius,
    margin: '10px 0px',
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  name: {
    color: theme.typography.color.primary,
    fontFamily: `${Bold}`,
    fontSize: "14px",
  },
  filter: {
    padding: "10px 13px 5px",
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #E4E8EE",
    backgroundColor: "#fff",
    cursor: "pointer",
  },
  unit: {
    fontSize: "12px",
    color: theme.palette.background.primary,
    fontFamily: `${Regular}`,
  },
  time: {
    fontSize: "12px",
    color: theme.palette.background.primary,
    fontFamily: `${Regular}`,
    marginTop: "-10px"
  },
  comment: {
    color: theme.typography.color.primary,
    fontFamily: `${SemiBold}`,
    fontSize: "14px",
    marginTop: '12px',
  },
  detailImg2: {
    borderRadius: theme.palette.borderRadius,
    height: "80px",
    width: "100%",
    objectFit: "cover"
  },
}));

export const CommontsCard = (props) => {
  const classes = useStyles();
  moment.defaultFormat = "DD MMM YY";
  const [open, setOpen] = React.useState(false);
  const onClose = () => {
    setOpen(false)
  }
  console.log(props?.data?.assets , 'props?.data?.assets')
  return (
    <div className={classes.root}>
      <Box alignItems="center" display="flex">
        <Box>
          <Avatar src={props?.data?.image_url} />
        </Box>
        <Box flexGrow={1} marginLeft="10px">
          <Box alignItems="center" display="flex" justifyContent='space-between'>
            <Box display={"flex"} alignItems="center">
              <Typography variant="subtitle2" className={classes.name}>
                {props.data.first_name}&nbsp;{props.data.last_name}
              </Typography>
              <Box className={classes.dot} />
              <Typography
                variant="subtitle2"
                className={classes.unit}
              >
                {props?.comment?.unit_name}-{props?.comment?.unit_no}
              </Typography>

            </Box>
            <Box marginLeft="4px">
              <Box className={classes.filter}
                onClick={() => {
                  props?.deleteComment(props?.data?.id)
                }}>
                <img src="/images/deletetrash.svg" alt="delete" />
              </Box>
            </Box>
          </Box>
          <Typography variant="subtitle2" className={classes.time}>
            {timeZoneConverter(new Date(props?.data.created_at), "", true)}
          </Typography>
        </Box>
      </Box>
      <Typography className={classes.comment}>
        {props?.data?.comment}
      </Typography>
      <Grid container spacing={1}>
        {
          props?.data?.assets?.length > 0 &&
          props?.data?.assets?.map((item) => {
            return (
              <Grid item xs={2} onClick={() => setOpen(true)}>
                <img
                  src={item?.url}
                  alt=""
                  className={classes.detailImg2}
                />
              </Grid>
            )
          }
          )

        }
      </Grid>
      <AlertDialog
        header="Attachments"
        open={open}
        onClose={onClose}
        component={
          <Box alignItems="center">
            <Slider assets={ props?.data?.assets?.length > 0 ? props?.data?.assets : []} />
          </Box>
        }
        medium={true}

      />
    </div>
  );
};
