import React, { useState } from 'react';
import { MinusIcon, PlusIcon } from '../../assets';
import './index.css';

const MAPTYPEOPTIONS = [
    { label: 'Default', value: 'roadmap', url: "/images/default_map.png" },
    { label: 'Satellite', value: 'satellite', url: "/images/satellite_map.png" },
    { label: 'Hybrid', value: 'hybrid', url: "/images/hybrid_map.png" }
]

export const CustomControls = (props) => {
    const [state, setState] = useState({
        mapType: MAPTYPEOPTIONS[0],
        toggle: false
    })

    const onChangeState = (key, value) => {
        setState({
            ...state, [key]: value
        })
    }

    const onChangeMapType = (val) => {
        setState({
            ...state,
            mapType: val,
            toggle: false
        })
        props?.changeMapTypes && props?.changeMapTypes(val.value)
    }
    const data = [
        {
            label: "5KMS",
            value: 5
        },
        {
            label: "10KMS",
            value: 10
        },
        {
            label: "15KMS",
            value: 15
        }

    ]
    return (
      <div>
        {/* <div className="fullscreen-control">
                <div className='icon-wrapper' onClick={props?.fullScreenControl}>
                    <FullScreenIcon />
                </div>
            </div> */}
        {/* <div className="controls">

                <div className='zoom-control'>
                    <div className='icon-wrapper mb-20' onClick={props?.zoomIn}>
                        <PlusIcon />
                    </div>
                    <div className='icon-wrapper' onClick={props?.zoomOut}>
                        <MinusIcon />
                    </div>
                </div>
            </div> */}

        <div className="map-types">
          <div className="mapTypeflex">
            <div
              className="mapTypeCard"
              alt=""
              style={{ border: "1px solid #22DACE" }}
              onClick={() => onChangeState("toggle", !state?.toggle)}
            >
              <img src={state?.mapType.url} alt="" />
              <div className="absoluteDiv">{state?.mapType.label}</div>
            </div>

            <>
              {state?.toggle &&
                MAPTYPEOPTIONS?.filter(
                  ({ label }) => label !== state?.mapType?.label
                )?.map((_) => (
                  <div
                    className="mapTypeCard"
                    key={_.label}
                    onClick={() => onChangeMapType(_)}
                  >
                    <img src={_.url} alt="" />
                    <div className="absoluteDiv">{_.label}</div>
                  </div>
                ))}
            </>
          </div>
        </div>
        <div className="map-controls">
          {!props?.hideControl && (
            <div className="radius">
              {data?.map((val) => {
                return (
                  <div
                    className={
                      val?.value === props?.radius ? "km-active" : "km"
                    }
                    onClick={() => props?.setRadius(val?.value)}
                  >
                    <span className="kmf">{val?.label}</span>
                  </div>
                );
              })}
            </div>
          )}
{
  !props?.is_zoom && 
  <div className="zoom-control">
            <div className="plus" onClick={props?.zoomIn}>
              <PlusIcon />
            </div>
            <div className="minus" onClick={props?.zoomOut}>
              <MinusIcon />
            </div>
          </div>
}
          
        </div>
      </div>
    );
}