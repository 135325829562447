import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { DeclineQRcodeStyle } from './style'
import ShareSvg from '../../../assets/shareSvg'
import QRCode from "react-qr-code";
import moment from 'moment';
import { addSecondFormat } from '../../../utils';
import CircleIcon from '@mui/icons-material/Circle';
import { withNamespaces } from 'react-i18next';

const DeclineQRcode = (
    {
        amenityBookingDetail = {},
        type = "",
        cancelClick = () => false,
        t
    }
) => {
    const classes = DeclineQRcodeStyle()
    return (
        <Box p={2} className={classes.box}>
            <Box className={classes.shareIcon}>
                <ShareSvg />
            </Box>
            <Stack alignItems={"center"} spacing={2} sx={{ height: 285 }}>
                <img className={classes.company_logo} src={amenityBookingDetail?.company_logo} alt='logo' />
                <QRCode value={amenityBookingDetail?.booking_id} size={150} />

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "14px" }} >
                    <Box display="flex" alignItems="center" bgcolor={amenityBookingDetail?.booking_status === "Booked" ? "#E8F9E8" : "#E4E8EE"} style={{ borderRadius: "4px" }}>
                        <Box style={{ overflow: "hidden" }}>
                            <CircleIcon
                                style={{ marginLeft: "-7px", marginTop: "8px", fontSize: "16px", color: "white" }}
                            />
                        </Box>
                        <Box flexGrow={1} padding={"8px 36px"}>
                            <Stack alignItems={"center"}>
                                <Typography className={amenityBookingDetail?.booking_status === "Booked" ? classes.qr : classes.cancelled_qr}>
                                    {

                                        // amenityBookingDetail?.period === "Daily" ? 
                                        // `${moment(amenityBookingDetail?.booking_start_time).format("DD MMM") - moment(amenityBookingDetail?.booking_end_time).format("DD MMM") - moment(amenityBookingDetail?.booking_start_time).format("DD MMM YYYY")}`
                                         ((amenityBookingDetail?.booked_slots?.[0]?.[0]) === "00:00:00" ? "12:00" : addSecondFormat(amenityBookingDetail?.booked_slots?.[0]?.[0], 0, "HH:mm")) + " - " +//"08:59:59"
                                            (amenityBookingDetail?.booked_slots?.[amenityBookingDetail?.booked_slots?.length - 1]?.[1] === "23:59:59" ? "24:00" :addSecondFormat(amenityBookingDetail?.booked_slots?.[amenityBookingDetail?.booked_slots?.length - 1]?.[1], 1, "HH:mm")) + " - " +
                                            moment(amenityBookingDetail?.booking_start_time).format("DD MMM YYYY")
                                    }
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    className={classes.expire}
                                >
                                    {t("SlotDetails")}
                                </Typography>
                            </Stack>
                        </Box>
                        <Box style={{ overflow: "hidden" }}>
                            <CircleIcon
                                style={{ marginRight: "-7px", marginTop: "8px", fontSize: "16px", color: "white" }}
                            />
                        </Box>
                    </Box>
                </div>

            </Stack>
            {/* {
                type !== "decline" &&
                <Button variant='outlined' className={classes.declinebtn} fullWidth>Decline Request</Button>
            } */}
            {
                amenityBookingDetail?.booking_status === "Booked" &&
                <Button variant='outlined' fullWidth disabledElevation
                    className={classes.cancelBtn}
                    onClick={cancelClick}>{t("CancelRequest")}</Button>
            }
        </Box>
    )
}

export default withNamespaces("amenityBooking")(DeclineQRcode)
