import { Box, Button, Grid } from "@mui/material"
import React from "react"
import { DatePickerNew, SelectBox, Subheader, UseDebounce } from "../../components"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { AlertProps, getCompanyOption, NetWorkCallMethods } from "../../utils"
import StatementOfAccountTable from "./components/table"
import { StatementOfAccountStyles } from "./styles"
import { GET_ALL_ACCOUNTS_FOR_ACCOUNT_STATEMENT, initialState, } from "./utils"
import { config } from "../../config"
import { NetworkCall } from "../../networkcall"
import { lightFormat } from "date-fns"
const StatementOfAccount = () => {
    const classes = StatementOfAccountStyles()
    const debounce = UseDebounce();
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext);
    const [searchText, setSearchText] = React.useState("");
    const [compenyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState({ ...initialState });
    const [tableData, setTableData] = React.useState({
        row: [],
        count: 0,
        balanceDue: null
    });

    // update state
    const updateState = (key, value) => {
        let error = data?.error;
        error[key] = "";
        setData({ ...data, [key]: value, error })
    }


    //validation
    const validation = () => {
        let isValid = true;
        let error = data.error;
        if (data?.accountNo?.length === 0) {
            isValid = false;
            error.accountNo = "Account number is Required";
        }
        if (data?.startDate?.length === 0) {
            isValid = false;
            error.startDate = "Start Date is Required";
        }
        if (data?.endDate?.length === 0) {
            isValid = false;
            error.endDate = "End Date is Required";
        }
        setData({ ...data, error });

        return isValid;
    };

    //initial load
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
        }
        // eslint-disable-next-line
    }, [])

    //handle change
    const handleCompanyChange = (value) => {
        setData(initialState)
        setTableData({
            row: [],
            count: 0,
            balanceDue: null
        })
        setPage(1)
        setLimit(10)
        setSelectedCompany(value)

    }



    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getAccountStatementList(offset, limit, "")

    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getAccountStatementList(0, value, "")

    }

    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getAccountStatementList(0, limit, e)

    }




    //table list
    const getAccountStatementList = (offSet = 0, limit = 10, searchText = "") => {
        if (validation()) {
            setLoading(true)
            const payLoad = {
                account_no: data?.accountNo?.account_no,
                start_date: lightFormat(data?.startDate, 'yyyy-MM-dd'),
                end_date: lightFormat(data?.endDate, 'yyyy-MM-dd'),
                search: searchText,
                offset: 0,
                limit: limit
            };
            NetworkCall(
                `${config.api_url}/account-statement/get-account-statement`,
                NetWorkCallMethods.post,
                payLoad,
                null,
                true,
                false
            ).then((response) => {
                // table row constructions
                const List = response?.data?.data?.map((val, index) => {
                    let _d;
                    try {
                        _d = {
                            index: ((page - 1) * limit) + index + 1,
                            date: val?.created_at ?? " - ",
                            transaction: val?.transaction ?? " - ",
                            details: val?.reference_no ?? " - ",
                            dueOn: val?.type === "reciepts" ? null : val?.due_on ?? " - ",
                            payments: val?.type === "invoices" ? "-" : val?.payments ?? " - ",
                            balance: val?.balance ?? "-",
                            amount: val?.type === "reciepts" ? "-" : val?.amount ?? "-",
                        };
                    } catch (err) {

                    }
                    return _d;
                });
                setTableData({
                    row: List,
                    count: response?.data?.count,
                    balanceDue: response?.data?.balance_due
                })
                setLoading(false)
            }).catch((error) => {
                setLoading(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something went wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            })
        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "please fill the mandatory fields",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        }
    }
    const [loading2, setLoading2] = React.useState(null)
    // drop down option
    const loadOptionsDropDown = async (search = "", array, type) => {
        setLoading2(type);
        let result, payload, query, offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        }
        else {
            offset = array?.length;
        }
        switch (type) {
            case 'account':
                if (!selectedCompany?.value > 0) {
                    setLoading2(null);
                    return { options: [] }
                }

                query = GET_ALL_ACCOUNTS_FOR_ACCOUNT_STATEMENT(search, selectedCompany?.value, offset).loc.source.body;
                payload = {
                    query,
                };
                result = await networkCallBack(payload);
                let finalResult = result?.contact_account?.map((val) => {
                    return (
                        {
                            label: `${val?.account_no} - (${val?.name})`,
                            value: val?.value,
                            account_no: val?.account_no
                        }
                    )
                })
                return {
                    options: [...finalResult],
                    hasMore: (array?.length + result?.contact_account?.length) < result?.count[0]?.count
                }

            default:
                return { options: [] }
        }
    }
    const networkCallBack = async (payload, url = config.graphql_url) => {

        const options = await NetworkCall(
            `${url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {

                let main = response?.data?.data;
                setLoading2(null);
                return main
            })
            .catch((error) => {
                setLoading2(null);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                return null
            });

        return options
    }
    //download function

    //download pdf function
    const DownloadPdf = () => {
        if (data.accountNo && data.startDate && data.endDate) {
            const payLoad = {
                account_no: data?.accountNo?.account_no,
                start_date: lightFormat(data?.startDate, 'yyyy-MM-dd'),
                end_date: lightFormat(data?.endDate, 'yyyy-MM-dd'),
                search: searchText,

            };
            NetworkCall(
                `${config.api_url}/account-statement/generate_pdf`,
                NetWorkCallMethods.post,
                payLoad,
                null,
                true,
                false,
                { responseType: "arraybuffer" }
            ).then((response) => {
                var blob = new Blob([response.data], { type: "application/pdf" });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                var date = lightFormat(new Date(), 'yyyy-MM-dd')
                var fileName = `Account-Statement_${date}.pdf`;
                link.download = fileName;
                link.click();
            }).catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something went wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            })
        }
    }
    return (
        <Box>
            {/*sub header */}
            <Subheader
                hideBackButton={false}
                title="Statement Of Account"
                select
                options={compenyList}
                value={selectedCompany}
                onchange={(e) => {
                    handleCompanyChange(e)
                }}
            />

            <>
                <Box className={`${classes.root}`}>

                    <Grid container spacing={3}>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                            <SelectBox
                                label="Account"
                                placeholder="Select Account"
                                value={data?.accountNo}
                                onChange={(value) => updateState("accountNo", value)}
                                isError={data?.error?.accountNo.length > 0}
                                isRequired
                                loading={loading2 === "account"}
                                loadOptions={(search, array) => loadOptionsDropDown(search, array, "account")}
                                debounceTimeout={800}
                                isPaginate={true}
                                errorMessage={data?.error?.accountNo}
                                key={JSON.stringify(selectedCompany?.value)}
                                selectHeight={"46px"}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                            <DatePickerNew
                                isrequired
                                value={data?.startDate}
                                label={"Start Date"}
                                handleChange={(value) => updateState("startDate", value)}
                                isError={data?.error?.startDate?.length > 0}
                                errorMessage={data?.error?.startDate}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                            <DatePickerNew
                                isrequired
                                value={data?.endDate}
                                label={"End Date"}
                                handleChange={(value) => updateState("endDate", value)}
                                isError={data?.error?.endDate.length > 0}
                                errorMessage={data?.error?.endDate}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={6} textAlign={"end"}>
                            <Button
                                variant="contained"
                                className={classes.btn}
                                size="large"
                                onClick={getAccountStatementList}
                            >
                                Generate
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box className={`${classes.root2}`}>
                    <StatementOfAccountTable
                        placeholder="Search Vehicle"
                        searchText={searchText}
                        handleSearch={handleSearch}
                        handleChangeLimit={handleChangeLimit}
                        handlePagination={handlePagination}
                        page={page}
                        limit={limit}
                        handleIcon={false}
                        height={`calc(100vh - 442px)`}
                        list={tableData}
                        loading={loading}
                        DownloadPdf={DownloadPdf}
                    />

                </Box>
            </>



        </Box>
    )
}
export default StatementOfAccount