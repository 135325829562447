import gql from "graphql-tag";

export const CommuinityManager = `query($ID:String) {
      user_profiles(where:{user_id:{eq:$ID}}) {
        id
      }
    }`;

export const SECURITY_MAIN = `
   query($id:String){
    user_profiles(where:{user_id:{eq:$id}}){
      id
    }
  }`;


export const SECURITY_ACCESS = `
query($user_profile_id: String, $role_id: String) {
  access_control(
    where: {
      user_profile_id: { eq: $user_profile_id }
      roles: { id: { eq: $role_id } }
      is_active: { eq: true }
    }
  ) {
    property_id
    user_profile_id
    user_profile {
      id
      first_name
    }
    property {
      label:id
      value:name
    }
    roles {
      id
      name
    }
  }
}`;

export const GET_GATE_MASTER = (offset, limit, searchText) => gql`
query($property_id:[String]){
  count: access_gates_master(where: { property: { name: { iregex: "${searchText}" }}}){
    count:count_id
  }
  access_gates_master(
    where:{property_id:{in:$property_id},property:{name :{iregex :"${searchText}"}}},
    order_by: { created_at: desc }
    offset: ${offset}
    limit: ${limit}
    )
    {
        id
        name
        description
        gate_no
        is_active
        property{
          name
        }
        user_profile_id
        user_profile(where: { is_active: { eq: true } }) {
          value: id
          label: first_name
        }
        on_duty_date
      }
  }`;

// export const GET_GATE_MASTER = `
//   query($property_id:[String]){
//     access_gates_master(where:{property_id:{in:$property_id}},order_by: { updated_at: desc }){
//           id
//           name
//           description
//           gate_no
//           is_active
//           property{
//             name
//           }
//           user_profile_id
//         }
//     }`;

export const GET_SECURITY_MASTER = `
  query($property_id:String){
    access_gates_master(where:{property_id:$property_id},order_by: { created_at: desc },where:{is_active:true}){
          id
          user_profile_id
        }
    }`;

export const GET_SECURITY_MASTER2 = `
  query($user_profile_id:[String]){
    user_profiles(where:{id:{in:$user_profile_id}},order_by: { created_at: desc },where:{is_active:true}){
          id
         first_name
         last_name
         email_id
         account_id
         is_active
        }
    }`;

export const GET_PARKING_MASTER = (offset, limit, searchText) => gql`
    query GET_PARKING_MASTER{
      count: parking_master(where:{
        property:{name :{iregex:"${searchText}"}}
      }){
        count:count_id
      }
      parking_master(
        order_by: { created_at: desc }
        offset: ${offset}
        limit: ${limit}
        where:{
          property:{name :{iregex:"${searchText}"}}
        }
        ){
        id
        parking_no
        rate
        currency_master{
          symbol
          code
        }
        is_active
        property{
          name
        }
      }
    }`;

// export const GET_PARKING_MASTER = `
// query GET_PARKING_MASTER{
//   parking_master(order_by: { updated_at: desc }){
//     id
//     parking_no
//     is_active
//     property{
//       name
//     }
//   }
// }
// `;

export const SECURITY_PROPERTY = `
  query($property_id:[String]){
    property(where:{id:{in:$property_id},is_active:{eq:true}}){
      value:id
      label:name
      company_id
    }
  }
  `;

export const SecurityManager = `query($id:String){
      user_profiles(where:{user_id:{eq:$id}}){
        id
      }
    }
    {
      "id":""
    }
    query($id:String){
      access_control(where:{user_profile_id:{eq:$id}}){
        property_id
      }
    }
    query($property_id:[String]){
      property(where:{id:{in:$property_id}}){
        value:id
        label:name
      }
    }
    {
      "property_id":["03467ed2-08a8-4387-a02d-4a7a219746fb","e7a9f09a-5a24-4a23-968b-62e6c445fc55"]
    }
    `;
export const GET_PROPERTY_ID_BY_USER_PROFILE_ID_AND_ROLE_ID = `
query GET_PROPERTY_ID_BY_USER_PROFILE_ID_AND_ROLE_ID(
  $userProfileID: String
  $roleID: ID
  $companyID: Int
) {
  access_control(
    where: {
      user_profile_id: { eq: $userProfileID }
      roles: { id: { eq: $roleID } }
      company_id: { eq: $companyID }
      is_active: {eq: true}
    }
  ) {
    property_id
  }
}`;

export const GET_ROLE_ID_BY_ROLE_NAME = `
query GET_ROLE_ID_BY_ROLE_NAME($roleName: String) {
  roles(where: { name: { eq: $roleName } }) {
    id
    name
  }
}`;

export const GET_USER_PROFILE_ID_BY_USER_ID = `
query GET_USER_PROFILE_ID_BY_USER_ID($userID: String) {
  user_profiles(where: { user_id: { eq: $userID } }) {
    id
  }
}`;

export const Get_Gates = `
query($id: String) {
  access_gates_master(where: { property_id: { eq: $id },is_active:true }) {
    label: gate_no
    value: id
  }
}
`;
export const Search = `query($search: String) {
  access_control(
    where: {
      request_type:{ilike:$search}
    }
  ) {
    name
    request_type
    requested_by
    gate
    request_from
  }
}`;

export const GET_DOMESTIC_HELPER_REQUEST_BY_ID = gql`
query GET_DOMESTIC_HELPER_REQUEST_BY_ID($domesticHelperRequestID: ID) {
  domestic_helper_request(
    where: { id: { eq: $domesticHelperRequestID }, is_active: { eq: true } }
  ) {
    id
    helper(where: { is_active: { eq: true } }) {
      id
      name
      mobile_country_code
      mobile_no
      created_by(where: { is_active: { eq: true } }) {
        id
        first_name
        last_name
      }
    }
    created_at
    request_status
    unit(where: { is_active: { eq: true } }) {
      id
      unit_no
      name
    }
    created_by(where: { is_active: { eq: true } }) {
      id
      first_name
      last_name
    }
    approved_on
    approved_by(where: { is_active: { eq: true } }) {
      id
      first_name
      last_name
    }
    profession(where: { is_active: { eq: true } }) {
      id
      name
    }
    reference_id
    remarks
  }
}`;

export const GET_DOMESTIC_HELPER_UNITS_BY_HELPER_ID_AND_UNIT_ID = `
query GET_DOMESTIC_HELPER_UNITS_BY_HELPER_ID_AND_UNIT_ID(
  $helperID: String
  $unitID: String
) {
  domestic_helper_units(
    where: {
      helper_id: { eq: $helperID }
      unit_id: { eq: $unitID }
      is_active: { eq: true }
    }
  ) {
    id
  }
}`;

export const GET_ENTRIES_FOR_DOMESTICT_HELPER_VENDOR_SERVICE_PROVIDER_BY_VISITOR_TYPE = (
  created_start, created_end, check_in_start, check_in_end, check_out_start, check_out_end, accessGateID
) => gql`
  query GET_ENTRIES_FOR_DOMESTICT_HELPER_VENDOR_SERVICE_PROVIDER_BY_VISITOR_TYPE(
    $visitorType: String
    $propertyID: [String]
    $clientID: String
    ${(created_start && created_start?.length > 0) ? `$created_start: String` : ``}
    ${(created_end && created_end?.length > 0) ? `$created_end: String` : ``}
    ${(check_in_start && check_in_start?.length > 0) ? `$check_in_start: String` : ``}
    ${(check_in_end && check_in_end?.length > 0) ? `$check_in_end: String` : ``}
    ${(check_out_start && check_out_start?.length > 0) ? `$check_out_start: String` : ``}
    ${(check_out_end && check_out_end?.length > 0) ? `$check_out_end: String` : ``}
    ${(accessGateID && accessGateID?.length > 0) ? `$accessGateID: [String]` : ``}
    $search: String
    $offset: Int
    $limit: Int
  ) {
    totalCount: swift_in_out_log(
      where: {
        visitor_type: { eq: $visitorType }
        property_id: { in: $propertyID }
        client: { eq: $clientID }
        ${((created_start && created_start?.length > 0) && (created_end && created_end?.length > 0)) ?
    `created_at: { and: { gte: $created_start, lt: $created_end } }` :
    (created_start && created_start?.length > 0) ? `created_at: { gte: $created_start }` :
      (created_end && created_end?.length > 0) ? `created_at: { lt: $created_end }` : ``}
      ${((check_in_start && check_in_start?.length > 0) && (check_in_end && check_in_end?.length > 0)) ?
    `check_in_time: { and: { gte: $check_in_start, lt: $check_in_end } }` :
    (check_in_start && check_in_start?.length > 0) ? `check_in_time: { gte: $check_in_start }` :
      (check_in_end && check_in_end?.length > 0) ? `check_in_time: { lt: $check_in_end }` : ``}
      ${((check_out_start && check_out_start?.length > 0) && (check_out_end && check_out_end?.length > 0)) ?
    `check_out_time: { and: { gte: $check_out_start, lt: $check_out_end } }` :
    (check_out_start && check_out_start?.length > 0) ? `check_out_time: { gte: $check_out_start }` :
      (check_out_end && check_out_end?.length > 0) ? `check_out_time: { lt: $check_out_end }` : ``}
        ${(accessGateID && accessGateID?.length > 0) ? `access_gate_id: { in: $accessGateID }` : ``}
        or: {
          person_name: { iregex: "" }
          domestic_helper_request: { helper: { name: { iregex: $search } } }
          domestic_helper_request: { profession: { name: { iregex: $search } } }
          domestic_helper_request: {
            helper: { reference_id: { iregex: $search } }
          }
          vendor: { name: { iregex: $search } }
          service_providers_master: { name: { iregex: $search } }
          reference_id: { iregex: $search }
        }
        is_active: { eq: true }
      }
    ) {
      count_id
    }
    swift_in_out_log(
      where: {
        visitor_type: { eq: $visitorType }
        property_id: { in: $propertyID }
        client: { eq: $clientID }
        ${((created_start && created_start?.length > 0) && (created_end && created_end?.length > 0)) ?
    `created_at: { and: { gte: $created_start, lt: $created_end } }` :
    (created_start && created_start?.length > 0) ? `created_at: { gte: $created_start }` :
      (created_end && created_end?.length > 0) ? `created_at: { lt: $created_end }` : ``}
      ${((check_in_start && check_in_start?.length > 0) && (check_in_end && check_in_end?.length > 0)) ?
    `check_in_time: { and: { gte: $check_in_start, lt: $check_in_end } }` :
    (check_in_start && check_in_start?.length > 0) ? `check_in_time: { gte: $check_in_start }` :
      (check_in_end && check_in_end?.length > 0) ? `check_in_time: { lt: $check_in_end }` : ``}
      ${((check_out_start && check_out_start?.length > 0) && (check_out_end && check_out_end?.length > 0)) ?
    `check_out_time: { and: { gte: $check_out_start, lt: $check_out_end } }` :
    (check_out_start && check_out_start?.length > 0) ? `check_out_time: { gte: $check_out_start }` :
      (check_out_end && check_out_end?.length > 0) ? `check_out_time: { lt: $check_out_end }` : ``}
        ${(accessGateID && accessGateID?.length > 0) ? `access_gate_id: { in: $accessGateID }` : ``}
        or: {
          person_name: { iregex: "" }
          domestic_helper_request: { helper: { name: { iregex: $search } } }
          domestic_helper_request: { profession: { name: { iregex: $search } } }
          domestic_helper_request: {
            helper: { reference_id: { iregex: $search } }
          }
          vendor: { name: { iregex: $search } }
          service_providers_master: { name: { iregex: $search } }
          reference_id: { iregex: $search }
        }
        is_active: { eq: true }
      }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc }
    ) {
      id
      visitor_type
      person_name
      reference_id
      created_by(where: { is_active: { eq: true } }) {
        first_name
      }
      swift_in_out_log_units(where: { is_active: { eq: true } }) {
        unit(where: { is_active: { eq: true } }) {
          id
          unit_no
          name
        }
      }
      check_in_byByID(where: { is_active: { eq: true } }) {
        id
        first_name
        last_name
      }
      check_in_time
      check_out_byByID(where: { is_active: { eq: true } }) {
        id
        first_name
        last_name
      }
      check_out_time
      access_gates_master {
        name
      }
      domestic_helper_request(where: { is_active: { eq: true } }) {
        id
        from 
        to
        helper(where: { is_active: { eq: true } }) {
          id
          name
          mobile_country_code
          mobile_no
          reference_id
          created_by(where: { is_active: { eq: true } }) {
            id
            first_name
            last_name
          }
        }
        created_at
        request_status
        unit(where: { is_active: { eq: true } }) {
          id
          unit_no
        }
        created_by(where: { is_active: { eq: true } }) {
          id
          first_name
          last_name
        }
        profession(where: { is_active: { eq: true } }) {
          id
          name
        }
        reference_id
        remarks
      }
      property(where: { is_active: { eq: true } }) {
        id
        property_no
        name
        unit(where: { is_active: { eq: true } }) {
          id
          name
          unit_no
        }
      }
      vendor_master(where: { is_active: { eq: true } }) {
        id
        name
        image_url
      }
      service_providers_master(where: { is_active: { eq: true } }) {
        id
        name
      }
    }
  }`

export const GET_DOMESTIC_HELPER_MASTER = (offset, limit, searchText) => gql`
query GET_DOMESTIC_HELPER_MASTER($property_id: [String]) {
  count: domestic_helper_master(where: { name: {iregex:"${searchText}"} }){
    count:count_id
  }
  domestic_helper_master(
    where: { property_id: { in: $property_id },name: {iregex:"${searchText}"} }
    order_by: { created_at: desc }
    offset: ${offset}
    limit: ${limit}
  ) {
    id
    property(where: { is_active: { eq: true } }) {
      value: id
      label: name
    }
    is_active
    name
    gender
    mobile_country_code
    mobile_no
    qr_code
    id_type
    identification_master(where: { is_active: { eq: true } }) {
      value: id
      label: type
    }
    identification_no
    profession_master(where: { is_active: { eq: true } }) {
      id
      name
    }
  }
}`;

// export const GET_DOMESTIC_HELPER_MASTER = `
// query GET_DOMESTIC_HELPER_MASTER($property_id: [String]) {
//   domestic_helper_master(
//     where: { property_id: { in: $property_id } }
//     order_by: { created_at: desc }
//   ) {
//     id
//     property(where: { is_active: { eq: true } }) {
//       value: id
//       label: name
//     }
//     is_active
//     name
//     gender
//     mobile_country_code
//     mobile_no
//     id_type
//     identification_master(where: { is_active: { eq: true } }) {
//       value: id
//       label: type
//     }
//     identification_no
//     profession_master(where: { is_active: { eq: true } }) {
//       id
//       name
//     }
//   }
// }`;

export const GET_VENDOR_MASTER = (offset, limit, searchText) => gql`
query GET_VENDOR_MASTER {
  count: vendor_master(where: { name: {iregex:"${searchText}"} }){
    count:count_id
  }
  vendor_master(
    where: { property_id: { in: $property_id },name: {iregex:"${searchText}"}}
    order_by: { created_at: desc }
    offset: ${offset}
    limit: ${limit}
  ) {
    id
    property(where: { is_active: { eq: true } }) {
      value: id
      label: name
    }
    is_active
    name
    vendor_code
    property_id
  }
}`;

// export const GET_VENDOR_MASTER = `
// query GET_VENDOR_MASTER {
//   vendor_master(
//     where: { property_id: { in: $property_id } }
//     order_by: { created_at: desc }
//   ) {
//     id
//     property(where: { is_active: { eq: true } }) {
//       value: id
//       label: name
//     }
//     is_active
//     name
//     vendor_code
//     property_id
//   }
// }`;

export const GET_SERVICE_PROVIDERS_MASTER = (offset, limit, searchText) => gql`
query GET_SERVICE_PROVIDERS_MASTER {
  count: service_providers_master(where: { name: {iregex:"${searchText}"} }){
    count:count_id
  }
  service_providers_master(
    where: { property_id: { in: $property_id },name: {iregex:"${searchText}"} }
    order_by: { created_at: desc }
    offset: ${offset}
    limit: ${limit}
  ) {
    id
    property(where: { is_active: { eq: true } }) {
      value: id
      label: name
    }
    is_active
    name
    service_provider_code
    profession_master(where: { is_active: { eq: true } }) {
      id
      name
    }
    property_id
  }
}`;

// export const GET_SERVICE_PROVIDERS_MASTER = `
// query GET_SERVICE_PROVIDERS_MASTER {
//   service_providers_master(
//     where: { property_id: { in: $property_id } }
//     order_by: { created_at: desc }
//   ) {
//     id
//     property(where: { is_active: { eq: true } }) {
//       value: id
//       label: name
//     }
//     is_active
//     name
//     service_provider_code
//     profession_master(where: { is_active: { eq: true } }) {
//       id
//       name
//     }
//     property_id
//   }
// }`;

export const GET_PROFESSION_MASTER_OPTIONS = `
query GET_PROFESSION_MASTER_OPTIONS {
  profession_master(where: { is_active: { eq: true } }) {
    value: id
    label: name
  }
}`;

export const GET_IDENTIFICATION_MASTER_OPTION = `
query GET_IDENTIFICATION_MASTER_OPTION {
  Identification_master(where: { is_active: { eq: true } }) {
    value: id
    label: type
  }
}`;


export const GET_CURRENCY = `
query GET_CURRENCY{
  currency_master{
    symbol
    code
    id
  }
}`

export const GET_PROPERTY_BY_COMPANY = (offset, limit, searchText, id) => gql`
query GET_PROPERTY_BASED_ON_COMPANY{
  count: property(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      company_id:{eq:"${id}"}

    }
  ) {
    count:count_id
  }

     property(
      where: { is_active: { eq: true }
       name:{iregex:"${searchText}"}
       company_id:{eq:"${id}"}
    }
       offset:${offset}
       limit:${limit}
      ) {
        value: id
        label: name

  }
}
`
export const GET_PARKING_SLOT_MASTER_TABLE_LIST = (offset = 0, limit = 10, searchText = "", companyID = [], propertyGroudID = [], status = []) => {
  return gql`
query GET_PARKING_SLOT_MASTER_TABLE_LIST($id:ID){
  count:parking_master(
     where: {
        ${companyID?.length > 0 ? `company_id: { in: [${companyID}] }` : ''}
      ${propertyGroudID?.length > 0 ? `property_id: { in: $id }` : ''}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ''}
        parking_no: { iregex: "${searchText}" }
    }){
      count:count_id
    }

  parking_master(
     where: {
    ${companyID?.length > 0 ? `company_id: { in: [${companyID}] }` : ''}
      ${propertyGroudID?.length > 0 ? `property_id: { in: $id }` : ''}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ''}
       parking_no: { iregex: "${searchText}" }
    }
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
  ) {
    id
     currencyByID{
      id
      symbol
    }
    parking_no
    parking_type
    property{
      label: name
     value: id
    }
    unit{
     label: name
     value: id
      unit_no
    }
    parking_grouping_master{
        label: name
     value: id
    }
    location
    rate
    per_day_rate
    is_active
    description
  }
}

`
}

export const GET_PROPERTY_TYPE_FOR_PARKING_SLOT_MASTER = (offset, limit, searchText, extraparams = {}) => gql`
query GET_PROPERTY_TYPE{
  count: property(
    where: {
     company_id: { eq: ${extraparams?.id} }
      unit: { unit_category: { eq: "Parking Unit" } }
      name: { iregex: "${searchText}" }
    }
  ) {
    count: count_id
  }
  property(
    where: {
      company_id: { eq: ${extraparams?.id} }
      unit: { unit_category: { eq: "Parking Unit" } }
      name: { iregex: "${searchText}" }
    }
    offset: ${offset}
    limit: ${limit}
     ) {
    value: id
    label: name
  }
}
`

export const GET_PARKING_SLOT_AGREEMENT_DETAILS = (parking_id) => gql`
query PARKING_SLOT_DETAILS{
  parking_slot(
     where: {
         parking_id: {eq: "${parking_id}" }
         is_active: { eq: true }
    }){
    unit{
      id
      unit_no

    }
    agreementByID{
      id
      agreement_no
    }
    number_plate
    allocated_to
    allocated_from
  }
}

`
export const GET_PARKING_GROUPING_MASTER_BY_COMPANY = (offset, limit, searchText, id) => gql`
query GET_PARKING_GROUPING_MASTER_BY_COMPANY{
  count: parking_grouping_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      property_id:{eq:"${id}"}
    }
  ) {
    count:count_id
  }
     parking_grouping_master(
      where: { is_active: { eq: true }
       name:{iregex:"${searchText}"}
       property_id:{eq:"${id}"}
    }
       offset:${offset}
       limit:${limit}
      ) {
        value: id
        label: name

  }
}
`

export const GET_UNIT_BY_PROPERTY_ID = () => gql`
query GET_UNIT_BY_PROPERTY_ID($clientID: String, $propertyID: [String]) {
  unit(
    where: {
      is_active: { eq: true }
      client: { eq: $clientID }
      property_id: { in: $propertyID }
    }
  ) {
    id
    name
    unit_no
  }
}`


export const GET_ACCESS_GATES_BY_PROPERTY_ID = gql`
query GET_ACCESS_GATES_BY_PROPERTY_ID(
  $clientID: String
  $property: [String]
  $offset: Int
  $limit: Int
) {
  count: access_gates_master(
    where: {
      is_active: { eq: true }
      client: { eq: $clientID }
      property_id: { in: $property }
      or: { name: { iregex: $search }, gate_no: { iregex: $search } }
    }
  ) {
    count: count_id
  }

  access_gates_master(
    where: {
      is_active: { eq: true }
      client: { eq: $clientID }
      property_id: { in: $property }
      or: { name: { iregex: $search }, gate_no: { iregex: $search } }
    }
    offset: $offset
    limit: $limit
  ) {
    value: id
    label: name
    gate_no
  }
}`
