import { Grid } from "@mui/material";
import React from "react";
import { ImageCardComponent } from "../../components/imageCardComponent";

export const FullImageCard = () => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <ImageCardComponent
            list={[
              {
                src: "https://images.unsplash.com/photo-1659438667484-da505c2a1bd5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyN3x8fGVufDB8fHx8&auto=format&fit=crop&w=600&q=60",
              },
              {
                src: "https://images.unsplash.com/photo-1598084991540-50ea616becbd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGhkJTIwcGhvdG9zfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60",
              },
              {
                src: "https://images.unsplash.com/photo-1484950763426-56b5bf172dbb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8aGQlMjBwaG90b3N8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60",
              },
              {
                src: "https://images.unsplash.com/photo-1597283712405-819a6021326c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGhkJTIwcGhvdG9zfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60",
              },
              {
                src: "https://images.unsplash.com/photo-1476820865390-c52aeebb9891?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8aGQlMjBwaG90b3N8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60",
              },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
};
