import React from "react";
import { withNavBars } from "../../HOCs";
import { Accounts } from "./account";

class AccountsParent extends React.Component {
    render() {
        return <Accounts {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AccountsParent, props);
