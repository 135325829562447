import React from 'react';
import { Assets } from '../../companyCreation/tabs';
export const VendorAssets = (props) => {

    const { 
        isEdit=false,
        data,
        error,
        updateState,
        onChangeParticularState
    } = props;

    return (
        <Assets
            isEdit={isEdit}
            data={data}
            error={error}
            updateState={(parentKey, key, value) => updateState(parentKey, key, value)}
            onChangeParticularState={onChangeParticularState}
        />
    )
}