import { Box, Grid, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import React from "react";
import { Bold } from "../../utils";
import { AssetCard } from "./assetCard";
import { UploadBtn } from "./uploadBtn";
const CustomTypography = styled(Typography, {
    shouldForwardProp: prop => prop !== "fontFamily" && prop !== "fontSize" && prop !== "color"
})(({ fontFamily, fontSize, color }) => ({
    fontFamily,
    fontSize,
    color
}))

export const UploadComponent = ({
    logo_title,
    isrequired,
    isError,
    errorMessage,
    handleChange = () => false,
    value = [],
    assets = [],
    setAssets = () => false,
    xs = 2,
    amenities = false,
    marginTop = false
}) => {
    const theme = useTheme();
    //upload image
    const handleUpload = (value) => {
        setAssets([
            ...assets,
            value
        ])
    }
    //delete asset
    const onDelete = (i) => {
        const edited = assets.map((val, index) =>
            index === i
                ? {
                    ...val,
                    is_active: false
                }
                : val
        );
        setAssets(edited)

    }

    ///initial load

    return (
        <>
            {logo_title &&
                <CustomTypography
                    fontFamily={Bold}
                    fontSize={12}
                    margin="12px 0px 12px 0px"
                    color={theme.typography.color.secondary}
                >
                    {logo_title}
                    {isrequired && (
                        <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                            *
                        </Typography>
                    )}
                </CustomTypography>
            }


            <Grid container spacing={1} alignItems="center">

                <Grid item xs={xs ?? 12}>

                    <UploadBtn
                        handleChange={handleUpload}
                        assets={assets}
                        amenities={amenities}
                    />
                </Grid>

                {
                    assets?.filter(i => i?.is_active)?.map((val, index) => {
                        return (
                            <Grid item xs={xs} >
                                <Box marginTop={marginTop ? "auto" : null}>
                                    <AssetCard
                                        onDelete={() => onDelete(index)}
                                        x={val} />
                                </Box>

                            </Grid >
                        )
                    })
                }
                <Grid item xs={12}>
                    {isError && (
                        <Typography variant={"caption"} color={"error"}>
                            {errorMessage}
                        </Typography>
                    )}
                </Grid>

            </Grid >
        </>
    )
}