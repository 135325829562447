import React from 'react';
import { Box, Typography } from "@mui/material";
import { LeadStylesParent } from '../style';
import ShowMoreText from "react-show-more-text";

export const ProfileCard = (props) => {
      // styles
      const classes = LeadStylesParent();
      return (
            <div className={classes.profileroot}>
                  <div className={classes.cardBottomLine}>
                        <div className={classes.profilePadding}>
                              <div className={classes.profilecard}> <Typography className={classes.profileTilte}>{props?.viewDetails?.name ?? ""}</Typography> <div><Typography className={classes.leadnumber}>{props?.viewDetails?.reference_no ?? ""}</Typography></div></div>
                              <Box className={classes.profilecardContent}>
                                    {/* <Box> <Typography className={classes.leadcontact}>{props?.viewDetails?.name ?? ""}</Typography></Box> */}
                                    <Box> <Typography className={classes.leadcontact}>{props?.viewDetails?.mobile_country_code ?? ""}-{props?.viewDetails?.mobile_no ?? ""}</Typography></Box>
                                    <Box> <Typography className={classes.leadcontact}><Box className={classes.dot} />{props?.viewDetails?.email ?? ""}</Typography></Box>
                              </Box>
                        </div>
                  </div>
                  {/* des */}
                  <div className={classes.profilePadding}>

                        {
                              props?.viewDetails?.description?.length > 0 &&
                              <>
                                    <Typography className={classes.profileDescription}>DESCRIPTION / REQUIREMENT</Typography>

                                    <ShowMoreText
                                          lines={2}
                                          more="Show More"
                                          less="Show Less"
                                          className={classes.detailsub}
                                          anchorClass={classes.seeMoreLessTextStyle}
                                          expanded={false}
                                          truncatedEndingComponent={"... "}
                                    >
                                          <Typography className={classes.profileDescriptioncontant}>{props?.viewDetails?.description ?? ""}</Typography>
                                    </ShowMoreText>
                              </>
                        }

                  </div>
            </div>
      )
}
