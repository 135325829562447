import { useApolloClient } from "@apollo/client";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Budget, SQFT } from "../../../assets";
import { LoadingSection } from "../../../components";
import { GET_OPPERTUNITY_DETAILS } from "../../../graphql/oppertunityQueries";
import { useStyles } from "./styles";
import {timeZoneConverter} from '../../../utils'

export const Details = ({ oppertunity_id = "", setDetails = () => false, details = {}, permission = {} }) => {
    const classes = useStyles()
    const client = useApolloClient();
    const [loading, setLoading] = React.useState(true)
    //get opeertunity details
    const getOppertunityDetails = () => {
        client.query({
            query: GET_OPPERTUNITY_DETAILS,
            fetchPolicy: 'network-only',
            variables: {
                id: oppertunity_id
            }
        }).then((response) => {
            setDetails(response?.data?.lead?.[0])
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }
    //initial state
    React.useState(() => {
        if (permission?.read) {
            getOppertunityDetails()
        }
    }, [permission])

    return (

        <>
            {
                loading ? <LoadingSection top={"5vh"} message="Loading Details..." /> :

                    <Box className={classes.tabRoot}>
                        {/*profile section */}
                        <Grid container spacing={1}>
                            {/*profile image */}
                            <Grid item lg={1.5}>
                                <Box className={classes.imageRoot}>
                                    <img src={details?.contact?.get_assets_url_id ?? "/images/profile_image.png"} alt="" className={classes.image} />
                                    <Box display="flex" justifyContent="center" marginTop="-30px">
                                        <Typography className={classes.priority}>{details?.urgent}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            {/*profile details */}
                            <Grid item lg={10.5} >
                                <Box className={classes.content}>
                                    <Typography className={classes.title}>OPPORTUNITY DETAILS</Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <Typography className={classes.header}>Contact Name</Typography>
                                            <Typography className={classes.subHeader}>{details?.contact?.first_name}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.header}>Mobile</Typography>
                                            <Typography className={classes.subHeader}>{details?.contact?.mobile_no_country_code} {details?.contact?.mobile_no}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.header}>Email Id</Typography>
                                            <Typography className={classes.subHeader}>{details?.contact?.email}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} marginTop="4px">
                                        {/* <Grid item xs={2}>
                                <Typography className={classes.header}>Website</Typography>
                                <Typography className={classes.subHeader}>Tomcrujackson.com</Typography>
                            </Grid> */}
                                        <Grid item xs={2}>
                                            <Typography className={classes.header}>Location</Typography>
                                            <Typography className={classes.subHeader}>{details?.contact?.district} {details?.contact?.country}</Typography>
                                        </Grid>

                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>

                        {/*opperunity details */}
                        <Grid container spacing={2} marginTop="20px">
                            <Grid item xs={4}>
                                <Box className={classes.borderRoot}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-evenly" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                                        <Box>
                                            <SQFT />
                                            <Typography className={classes.requirement}>REQUIREMENT</Typography>
                                            <Typography className={classes.requirementDetails}>{details?.required_area?.[0] ?? 0}-{details?.required_area?.[1] ?? 0} {details?.company?.uom}</Typography>
                                        </Box>
                                        <Box>
                                            <Budget />
                                            <Typography className={classes.requirement}>BUDGET</Typography>
                                            <Typography className={classes.requirementDetails}>{details?.budget_range?.[0] ?? 0}-{details?.budget_range?.[1] ?? 0} {details?.currency?.symbol}</Typography>
                                        </Box>
                                        <Box>
                                            <img src="/images/offline.svg" alt="" />
                                            <Typography className={classes.requirement}>SOURCE TYPE</Typography>
                                            <Typography className={classes.requirementDetails}>{details?.lead_source}</Typography>
                                        </Box>
                                    </Stack>

                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <Box className={classes.borderRoot}>
                                    <Typography className={classes.title}>DESCRIPTION :</Typography>
                                    <Typography className={classes.notes}>
                                        <span dangerouslySetInnerHTML={{ __html: details?.subject }} />
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        {/*lease details */}
                        {
                            details?.revenue_type !== "Sale" &&
                            <Box className={classes.borderRoot1} marginTop="20px">
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header1}>Lease Duration</Typography>
                                        <Typography className={classes.subHeader}>{details?.value} {details?.lease_period}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header1}>Lease Start And End Date</Typography>
                                        <Typography className={classes.subHeader}>{timeZoneConverter(details?.lease_start_date)} to {timeZoneConverter(details?.lease_end_date)}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header1}>Preferrd Payment Options</Typography>
                                        <Typography className={classes.subHeader}>{details?.payment_mode}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header}>Billing Cycle Method</Typography>
                                        <Typography className={classes.subHeader}>{details?.bill_generation}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header}>Billing Cycle Date</Typography>
                                        <Typography className={classes.subHeader}>{details?.billing_cycle_date}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header}>Grace Period</Typography>
                                        <Typography className={classes.subHeader}>{details?.grace_period_value} {details?.grace_period_type}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header}>Apply Grace Period</Typography>
                                        <Typography className={classes.subHeader}>{details?.apply_grace_period}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header}>Revenue Based Lease</Typography>
                                        <Typography className={classes.subHeader}>{details?.revenue_based_lease ? "True" : "False"}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header}>Turn Over Type</Typography>
                                        <Typography className={classes.subHeader}>{details?.turnover_type ?? "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header}>Renewal Based</Typography>
                                        <Typography className={classes.subHeader}>{details?.auto_renewal_escalation}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header}>Auto-renewal</Typography>
                                        <Typography className={classes.subHeader}>{details?.auto_renewal ? "True" : "False"}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header}>Increment/Escasulation</Typography>
                                        <Typography className={classes.subHeader}>{details?.increment_escalation ?? 0}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        {/*sale details*/}
                        {
                            details?.revenue_type === "Sale" &&
                            <Box className={classes.borderRoot1} marginTop="20px">
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header1}>Earliest Occupation Date</Typography>
                                        <Typography className={classes.subHeader}>{timeZoneConverter(details?.occpation_date)}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header1}>Contract Start Date</Typography>
                                        <Typography className={classes.subHeader}>{timeZoneConverter(details?.contract_start_date)}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header1}>Payment Period</Typography>
                                        <Typography className={classes.subHeader}>{details?.payment_period}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.header1}>Payment Options</Typography>
                                        <Typography className={classes.subHeader}>{details?.payment_mode}</Typography>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography className={classes.header1}>Delivery Mailstone Template</Typography>
                                        <Typography className={classes.subHeader}>{details?.milestone_templateByID?.name}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </Box >


            }

        </>
    )
}