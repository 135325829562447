import { Dialog, Grid, IconButton, Stack, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from "../style"
import { Area } from "./area"
import { Bed } from "./bed"
import { Bath } from "./bath"
import { UnitType } from "./unitType"

export const UnitView = (props) => {

    const classes = useStyles(props)
    const unitView = props?.requestState?.unitView
    const total = (price) => {
        let _total = 0
        for (let i = 0; i < price?.length; i++) {
            if (price[i]?.componentvalue) {
                _total = _total + price[i]?.componentvalue
            }
            if (price[i]?.appliedTaxAmount) {
                _total = _total + price[i]?.appliedTaxAmount
            }
        }
        return _total
    }

    return <Dialog
        className={classes.unitViewDialog}
        open={props?.unitViewDialogOpen}
        onClose={() => props?.setUnitViewDialogOpen(false)}>
        <div className={classes.viewDialogHeader}>
            <Typography className={classes.viewDialogHeaderTitle}>
                {"Unit View"}
            </Typography>
            <IconButton onClick={() => props?.setUnitViewDialogOpen(false)}
                className={classes.viewDialogCloseButton}>
                <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
            </IconButton>
        </div>
        <Grid container>
            <Grid item xs={6} padding={"16px"}>
                <Grid container paddingBottom={"12px"}>
                    <Typography className={classes.viewDialogText6}>
                        {unitView?.name}
                    </Typography>
                </Grid>
                <Stack direction={"row"} justifyContent={"start"}
                    alignItems={"center"} padding={"4px 0px"}
                    divider={<div style={{
                        backgroundColor: "#CED3DD", margin: "0px 8px",
                        borderRadius: "50%", height: "6px", width: "6px"
                    }} />}>
                    <Typography className={classes.viewDialogText7}>
                        {unitView?.property?.property_name ?? ""}
                    </Typography>
                    <Stack direction={"row"} justifyContent={"center"}
                        alignItems={"center"}>
                        <Area />
                        <Typography className={classes.viewDialogText7}>
                            {unitView?.totalarea ?
                                (unitView?.totalarea +
                                    (unitView?.areaMetric ?? "")) : ""}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"center"}
                        alignItems={"center"}>
                        <Bed />
                        <Typography className={classes.viewDialogText7}>
                            {unitView?.totalbedroom}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"center"}
                        alignItems={"center"}>
                        <Bath />
                        <Typography className={classes.viewDialogText7}>
                            {unitView?.baths}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"center"}
                        alignItems={"center"}>
                        <UnitType />
                        <Typography className={classes.viewDialogText7}>
                            {unitView?.unitType}
                        </Typography>
                    </Stack>
                </Stack>
                <Grid container className={classes.viewDialogContactDivider} />
            </Grid>
            <Grid item xs={6} padding={"16px"}>
                <div className={classes.pricingDetailsDiv}>
                    <Grid container paddingBottom={"12px"}>
                        <Typography className={classes.viewDialogText6}>
                            {"PRICING DETAILS"}
                        </Typography>
                    </Grid>
                    <Grid container className={classes.agreementSummary}>
                        <Grid container alignContent={"start"} className={classes.priceDetailsBody}>
                            {unitView?.pricing?.map((_) => {
                                return <>
                                    <Grid item xs={6}>
                                        <Typography className={classes.priceDescriptionText}>
                                            {_?.PCname}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign={"right"} className={classes.priceTotalText}>
                                            {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                                (_?.componentvalue?.toFixed(2) ?? "0.00")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.viewDialogText7}>
                                            {"Tax Amount"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign={"right"} className={classes.viewDialogText7}>
                                            {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                                (_?.appliedTaxAmount?.toFixed(2) ?? "0.00")}
                                        </Typography>
                                    </Grid>
                                </>
                            })}
                        </Grid>
                        <Grid container className={classes.priceTotal}>
                            <Grid item xs={6}>
                                <Typography className={classes.priceTotalText}>
                                    {"Total"}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography textAlign={"right"} className={classes.priceTotalText}>
                                    {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                        (total(unitView?.pricing)?.toFixed(2) ?? "0.00")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    </Dialog >
}