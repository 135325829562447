import * as All from "../assets";

export const StatDataFunc = (_) => {
    let cardDetails1 = [
        {
            count: _?.activeAgreement ?? 0,
            label: "Active Agreement",
            icon: (All["ActiveAgreement"])
        },
        {
            count: _?.terminatedRequest ?? 0,
            label: "Termination Req",
            icon: (All["AgreementTerminationReq"])
        },
        // {
        //     count: _?.annoucements ?? 0,
        //     label: "Announcement",
        //     icon: (All["Announcements"])
        // }
        {
            count: _?.owners ?? 0,
            label: "Owners",
            icon: (All["Owners"])
        },
    ]

    let cardDetails2 = [
        {
            count: _?.residents ?? 0,
            label: "Tenants & Resident",
            icon: (All["Residents"])
        },
        // {
        //     count: _?.owners ?? 0,
        //     label: "Owners",
        //     icon: (All["Owners"])
        // },
        {
            count: _?.newResidents ?? 0,
            label: "New Residents",
            icon: (All["NewResidents"])
        },
        {
            count: _?.terminatedResidents ?? 0,
            label: "Residents Left",
            icon: (All["ResidentsLeft"])
        },
    ]

    let cardDetails3 = [
        {
            count: _?.managementTeams ?? 0,
            label: "Management Teams",
            icon: (All["NewResidents"])
        },
        {
            count: _?.companyEmployees ?? 0,
            label: "Employees",
            icon: (All["Employees"])
        },        
        {
            count: _?.securities ?? 0,
            label: "Security Personals",
            icon: (All["SecurityPersonals"])
        },
        // {
        //     count: _?.coResidents ?? 0,
        //     label: "Co-Residents",
        //     icon: (All["NewResidents"])
        // },
    ]

    let cardDetails4 = [
        {
            count: _?.disscussions ?? 0,
            label: "Discussion Forum Activity",
            icon: (All["Discussion"])
        },
        {
            count: _?.polls ?? 0,
            label: "Polls",
            icon: (All["Polls"])
        },
        {
            count: _?.surveys ?? 0,
            label: "Surveys",
            icon: (All["Surveys"])
        },
    ]

    let cardDetails5 = [
        // {
        //     count: _?.domesticContacts ?? 0,
        //     label: "Reg Domestic Contact",
        //     icon: (All["DomesticContact"])
        // },
        {
            count: _?.registerWorker ?? 0,
            label: "Registered Worker",
            icon: (All["DomesticContact"])
        },
        {
            count: _?.publicContacts ?? 0,
            label: "Reg Public Contact",
            icon: (All["PublicContact"])
        },
        // {
        //     count: _?.activeViolations ?? 0,
        //     label: "Active Violations",
        //     icon: (All["ActiveViolations"])
        // },
        {
            count: _?.contractEmployees ?? 0,
            label: "Contract Employees",
            icon: (All["ContractEmployees"])
        },
    ]

    // let cardDetails6 = [        
    //     {
    //         count: _?.securities ?? 0,
    //         label: "Security Personals",
    //         icon: (All["SecurityPersonals"])
    //     },
    // ]
    return { cardDetails1, cardDetails2, cardDetails3, cardDetails4, cardDetails5 }
}



export const TableData = [
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-001",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-002",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-003",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-004",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-005",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-006",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-007",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-008",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-009",
    },
]

export const GraphDetails1 = [
    {
        "name": "Violation 1",
        "count": 1,
        "fill": "#c55837"
    },
    {
        "name": "Violation 2",
        "count": 2,
        "fill": "#3943fc"
    },
    {
        "name": "Violation3",
        "count": 11,
        "fill": "#fb620e"
    }
]

export const GraphDetails2 = {
    "title": "Violation Status",
    "graphData": [
        {
            "name": "Open",
            "count": 10,
            "fill": "#F3E137"
        },
        {
            "name": "InProgress",
            "count": 4,
            "fill": "#5AC782"
        },
        {
            "name": "New",
            "count": 8,
            "fill": "#fb620e"
        }
    ],
    "total": 22,
}