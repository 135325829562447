import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { MenuList } from "../../../components";
import { GET_ACCOUNT } from "../../../graphql/reciept";
import { LocalStorageKeys } from "../../../utils";


const AccountList = ({ setSelected = () => false, selected = {}, t }) => {

    const client = useApolloClient();
    const [accountList, setAccountList] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const [offset, setOffset] = React.useState(0);
    const limit = 20
    //get all account
    const getAccountList = (offset, search, reload) => {
        client.query({
            query: GET_ACCOUNT,
            fetchPolicy: 'network-only',
            variables: {
                offset: offset,
                limit: limit,
                search: search,
                client: localStorage.getItem(LocalStorageKeys.clinetID)
            }
        }).then((response) => {
            if (reload) {
                setAccountList(response?.data?.contact_account)
                setSelected(response?.data?.contact_account?.[0])
            } else {
                setAccountList([...accountList, ...response?.data?.contact_account])
            }

        }).catch((err) => {
            console.log(err)
        })
    }
    //initial load
    React.useEffect(() => {
        getAccountList(0, "", true)
        // eslint-disable-next-line
    }, [])
    // infinity scroll
    const fetchMoreData = () => {
        setOffset(offset + limit);
        getAccountList(offset + limit, search, false);
    };
    //handle search
    const handleSearch = (value) => {
        setSearch(value);
        getAccountList(0, value, true)
    }

    return (
        <Box>
            <MenuList
                list={accountList}
                selected={selected?.id}
                setSelected={setSelected}
                searchLabel={t("search")}
                fetchMoreData={fetchMoreData}
                handleSearch={handleSearch}
                searchValue={search}
            />
        </Box>
    )
}
export default withNamespaces("receipt")(AccountList); 