import React from "react";
import { Welcome } from "./welcome";
import { LoginSuccess } from "../../router/access";
import { LocalStorageKeys } from "../../utils";
import { withRouter } from 'react-router-dom';

class WelcomePagetParent extends React.Component {
    componentDidMount(){
        if(localStorage.getItem(LocalStorageKeys.authToken)){
            if(localStorage.getItem(LocalStorageKeys.permission)){
                this.props.history.push(LoginSuccess(localStorage.getItem(LocalStorageKeys.permission)))
            }
        }
    }
    render() {
        return <Welcome />;
    }
}

export default withRouter(WelcomePagetParent);