import { Box } from "@mui/material";
import { ProofComponent } from "../../../components";
import { useStylesCreation } from "../style";
export const ContactProof = ({ update = () => false, data = [] }) => {
    const classes = useStylesCreation()

    return (
        <Box marginTop="16px" className={classes.box}>
            <ProofComponent file={data} update={update} />
        </Box>
    )
}