import { Box, Grid } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { Subheader } from "../../components";
import { configurationlisted } from "../../utils/configurationUtils";
import { CommunityBanner, DashboardBanner, ServiceBanner, WelcomeScreen } from "../clientSettings/tabs";
import { SettingList } from "../companyCreation/components";
import { useStyles } from "./style";
export const ConfigurartionNew = () => {
    const classes = useStyles()
    const [selected, setSelected] = React.useState(1);
    const history = useHistory()
    //Initial load
    React.useEffect(() => {
        setSelected(configurationlisted[0])
        // eslint-disable-next-line
    }, [])
    //component list
    const components = {
        1: <DashboardBanner selected={selected} type="Dashboard" />,
        2: <ServiceBanner selected={selected} type="Services" />,
        3: <CommunityBanner selected={selected} type="Community" />,
        4: <WelcomeScreen selected={selected} />,
    }

    return (
        <Box>
            <Subheader title="Configuration" goBack={() => history.goBack()} />
            <Grid container spacing={2} p={2}>
                <Grid item xs={3}>
                    <Box className={classes.box}>
                        {/*setting list */}
                        <SettingList list={configurationlisted} selected={selected?.value} setSelected={setSelected} />
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box className={classes.box}>
                        {/*component render*/}
                        {components[selected?.value]}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}