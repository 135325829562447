export const intitalState = {
    id: "",
    name: "",
    mobile: "",
    des: "",
    email: "",
    leadsource: "",
    purpose: "",
    selectedUnit: [],
    lead_owner: null,
    fixUnit: [],
    edit_owner: null,
    property_id: null,
    changeUnit: [],
    error: {
        name: "",
        mobile: "",
        email: "",
        leadsource: "",
        purpose: "",
        selectedUnit: ""
    },
};

export const returnEditData = (data) => {
    const result = {
        id: data?.id,
        name: data?.lead_name,
        des: data?.description,
        mobile: {
            mobile: data?.mobile_no,
            mobile_code: data?.mobile_country_code
        },
        email: data?.email,
        leadsource: {
            value: data?.lead_source,
            label: data?.lead_source
        },
        purpose: data?.purpose,
        selectedUnit: data?.proxy_units,
        fixUnit: data?.proxy_units,
        changeUnit: [],
        edit_owner: data?.owner_id ? {
            label: data?.owner_name,
            value: data?.owner_id,
            label1: data?.account_no
        } : null,
        property_id: data?.property?.value ? { ...data?.property, lable1: data?.property } : null,
        error: {
            name: "",
            mobile: "",
            email: "",
            leadsource: "",
            purpose: "",
            selectedUnit: ""
        },
    }
    return result;
}