import React from "react";
import { withNavBars } from "../../HOCs";
import { FloorList } from "./floor";

class FloorParent extends React.Component {
    render() {
        return <FloorList />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(FloorParent, props);
