import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { Slider } from "../agreementDetails/components";
import { ToolsMasterStyles } from "./style";


export const TableView = ({ handleTableClose, viewData = "" }) => {

    const classes = ToolsMasterStyles()

    return (
        <>
            <Box>
                <Box className={classes.viewImgBox}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center"
                        p={2} >
                        <Stack direction="row" alignItems="center" >
                            <Typography className={classes.viewHead}>{viewData.name}</Typography>
                            <Typography className={viewData?.status === "Active" ? classes.viewBadge : classes.viewBadge2}>{viewData?.status}</Typography>
                        </Stack>
                        <IconButton onClick={handleTableClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Divider />
                    <Box p={2} mt={0.5}>


                        {viewData?.tools_assets?.length > 0 ? (
                            <Slider
                                // onClick={onClickKyc}
                                isInventory
                                assets={viewData?.tools_assets ? viewData.tools_assets : []}
                                backgroundColor={"#000"}
                                pageNumber
                                padding={"0px 21px 0px 23px"}
                            />
                        ) :
                            <Box  >
                                <img className={classes.tempImage} src="/images/propertytumb.png" alt="" />
                            </Box>

                        }
                    </Box>
                </Box>
                <Box className={classes.viewImgBox1}>
                    <Box p={2} className={classes.productdetails}>
                        <Typography className={classes.productHead}>Product Description</Typography>
                        <Box>
                            <Typography className={classes.productDescr} mt={1}>
                                {viewData.description}
                            </Typography>

                        </Box>
                    </Box>
                </Box>
            </Box>

        </>
    )
}