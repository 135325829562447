import { Stack, Box, Typography, Divider, Button } from "@mui/material";
import React, { useContext } from "react";
import { useStyles } from "./styles";
import HomeIcon from "@mui/icons-material/Home";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AlertDialog } from "../../../components";
import { Owner } from "./ownerSelect";
import { OwnerUnit, PropertyUnit } from "../../../assets";
import { CreateOppertunityContext } from "../../../contexts/createOppertunityContext";

export const UnitSelection = ({
  onChange = () => false,
  value = [],
  company = null,
  datas = {},
  isEdit = false,
  onDeleteUnits = () => false,
  typeData = "",
  purpose = "",
}) => {
  const { dataNew, setDataNew } = useContext(CreateOppertunityContext);
  const classes = useStyles();
  const [showDialog, setShowDialog] = React.useState(false);
  const [chooseOwner, setChooseOwner] = React.useState(false);

  const types = [
    {
      value: 1,
      label: "Select against owner units",
      description: "Units will be listed against the selected owner",
      icon: <OwnerUnit />,
      search: "Search Owner",
      is_diver: true,
    },
    {
      value: 2,
      label: "Select against property units",
      description: "Units will be listed against the selected property",
      icon: <PropertyUnit />,
      search: "Search Property",
    },
  ];

  const [data, setData] = React.useState({
    type: null,
  });
  const [unitData, setUnitData] = React.useState({
    data: [],
    id: "",
  });

  const chooseOwnerforUnitSelection = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setChooseOwner(true);
    setShowDialog(false);
  };

  React.useEffect(() => {
    if (isEdit) {
      if (datas?.property_id) {
        setData({
          type: types[1],
        });
      } else {
        setData({
          type: types[0],
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  const addUnits = () => {
    if(unitData?.id?.length > 0){
      setDataNew({
        ...dataNew,
        units: unitData?.data ?? [],
      });
      setChooseOwner(false);
    }
    else{
      setDataNew({
        ...dataNew,
        units: [...new Set([...dataNew?.units, ...unitData?.data])] ?? [],
      });
      setChooseOwner(false);
    }
  };

  const onChangeState = (unit, owner, type, is_delete) => {
    setUnitData({
      data: unit?.filter((val) => val?.is_active === true) ?? [],
      id: is_delete ?? "",
    });
    onChange(unit, owner, type);
  };

  return (
    <>
      <Box className={classes.unitCard} onClick={() => setShowDialog(true)}>
        <Stack direction="row" spacing={1}>
          <HomeIcon className={classes.selectionIcon} />
          <Typography className={classes.selectionText}>
            Quick Unit Selection
          </Typography>
        </Stack>
        <Stack>
          <ChevronRightIcon className={classes.arrowRightIcon} />
        </Stack>
      </Box>
      <AlertDialog
        isNormal
        isnotTitle={true}
        onClose={() => {
          setShowDialog(false);
        }}
        open={showDialog}
        component={
          <>
            <Box>
              {types?.map((val) => {
                return (
                  <>
                    <Box
                      className={classes.selectCard}
                      onClick={() => chooseOwnerforUnitSelection("type", val)}
                    >
                      <Box display="flex" alignItems="center">
                        <Box mr={2}>{val?.icon}</Box>
                        <Box>
                          <Typography className={classes.ownerText}>
                            {val?.label}
                          </Typography>
                          <Typography className={classes.ownerSubText}>
                            {val?.description}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <ChevronRightIcon
                          className={classes.selectarrowRightIcon}
                        />
                      </Box>
                    </Box>
                    {val?.is_diver && <Divider />}
                  </>
                );
              })}
            </Box>
          </>
        }
      />
      <AlertDialog
        isNormal
        onClose={() => {
          setChooseOwner(false);
        }}
        header={
          data?.type?.value === 1
            ? "Select against Owner Units"
            : "Select against Property Units"
        }
        open={chooseOwner}
        component={
          <>
            <Box p={2}>
              <Owner
                datas={datas}
                isEdit={isEdit}
                company={datas?.company}
                onChange={onChangeState}
                type={data?.type}
                onDeleteUnits={onDeleteUnits}
                value={datas?.selectedUnit ?? []}
                onClose={() => {
                  chooseOwnerforUnitSelection("type", null);
                  onDeleteUnits([], null, true);
                }}
                is_show={true}
                typeData={typeData}
                purpose={purpose}
              />
            </Box>
            <Box p={1}>
              <Button
                variant="contained"
                className={classes.btnAdd}
                onClick={() => addUnits()}
                // disabled={
                //   datas?.units?.length >
                //   0
                //     ? false
                //     : true
                // }
              >
                Add Units
              </Button>
            </Box>
          </>
        }
      ></AlertDialog>
    </>
  );
};
