export const AlreadyPaidRows = [
    {
        request_id:"RC-2234234",
        requested_by:"Pradeep Kumar",
        invoice_number:"INV-0234523",
        payment_method:"Offline",
        reference_num:"42548657587876"
    },
    {
        request_id:"RC-2234234",
        requested_by:"Pradeep Kumar",
        invoice_number:"INV-0234523",
        payment_method:"Offline",
        reference_num:"42548657587876"
    },
    {
        request_id:"RC-2234234",
        requested_by:"Pradeep Kumar",
        invoice_number:"INV-0234523",
        payment_method:"Offline",
        reference_num:"42548657587876"
    }
]

export const AlreadyPaidHeading = [
    { title: "", field: "icon" },
    { title: "Request Id", field: "request_id" },
    { title: "Requested By", field: "requested_by" },
    { title: "Invoice Number", field: "invoice_number" },
    { title: "Payment Method", field: "payment_method" },
    { title: "Reference Number", field: "reference_num" }
]
