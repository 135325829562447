import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { TabCompoent } from "./components";
import { Agreement, Lead , Oppertunity } from "./stepComponent";
import {statusType1 , statusType} from '../../utils/agreementUtils'
const TimeLineList = ({
  list = [],
  details = {},
  reload = () => false,
  t,
  agreement_id = "",
  load="",
  setLoad=()=>false
}) => {
  // states
  const [Details, setDetails] = React.useState("data");
  const [step, setStep] = React.useState(0);

  // functions
  const onChange = (e) => {
    setLoad(false)
    setStep(e);
  };
  const is_terminated = list?.some((x) => x?.status === "Terminated")
  // component
  const components = {
    0: <Lead  t={t} agreement_id={agreement_id} setDetails={setDetails}  statusType={["Draft" , "Review" , list?.[0]?.status]}/>,
    1: <Oppertunity  reload={reload} t={t}  agreement_id={agreement_id} setDetails={setDetails}  statusType={["Draft" , "Review" , list?.[0]?.status]}/>,
    2: (
      <Agreement
        list={list?.filter(
          (x) => x.status === "Review" || x.status === "Draft"
        )}
        details={details}
        reload={reload}
        t={t}
        i={2}
        is_status_card={statusType(list)[7]}
        nestStep={3}
        type="Agreement"
        statusType={["Draft" , list?.[0]?.status === "Draft" ? "Draft" :  "Review" , list?.[0]?.status]}
        agreement_id={agreement_id}
        load={load}
        is_terminated={true}
        is_overall ={true}
        step={step}
      />
    ),
    3: (
      <Agreement
        list={list?.filter((x) => x.status === "KYC Screening")?.length > 0 ? list?.filter((x) => x.status === "KYC Screening"):[{show:"is_show",status:"Review",type:"Approved"}] }
        details={details}
        reload={reload}
        t={t}
        i={3}
        is_status_card={statusType(list)[3]}
        nestStep={4}
        type="Agreement"
        statusType={["Review" , list?.[0]?.status === "Review" ? "Review" :  "KYC Screening" , list?.[0]?.status]}
        agreement_id={agreement_id}
        load={load}
        is_terminated={true}
        is_overall ={true}
        step={step}
      />
    ),
    4: (
      <Agreement
        list={list?.filter((x) => x.status === "Finance Review")?.length > 0 ? list?.filter((x) => x.status === "Finance Review") : [{show:"is_show",status:"KYC Screening",type:"Approved"}]}
        details={details}
        reload={reload}
        t={t}
        i={4}
        is_status_card={statusType(list)[4]}
        nestStep={5}
        type=""
        statusType={["KYC Screening" , list?.[0]?.status === "KYC Screening" ? "KYC Screening" : "Finance Review" , list?.[0]?.status]}
        agreement_id={agreement_id}
        load={load}
        step={step}
        is_terminated={true}
        is_overall ={true}
      />
    ),
    5: (
      <Agreement
        list={list?.filter((x) => x.status === "Onboarding")?.length > 0 ? list?.filter((x) => x.status === "Onboarding") : [{show:"is_show",status:"Finance Review",type:"Approved"}]}
        details={details}
        reload={reload}
        t={t}
        i={5}
        is_status_card={statusType(list)[5]}
        nestStep={6}
        type=""
        statusType={["Finance Review" , list?.[0]?.status === "Confirmed" ? "Confirmed" : "Onboarding" , list?.[0]?.status]}
        agreement_id={agreement_id}
        load={load}
        step={step}
        is_terminated={true}
        is_overall ={true}
      />
    ),
    // 6: (
    //   <Countinuity list={list} details={details} reload={reload} t={t} i={6} />
    // ),
    6: (
      <Agreement
        list={list?.filter(
          (x) =>
            x.status !== "Onboarding" &&
            x.status !== "Review" &&
            x.status !== "Draft" &&
            x.status !== "KYC Screening" &&
            x.status !== "Finance Review" &&
            x.status !== "Confirmed"
        )}
        details={details}
        reload={reload}
        t={t}
        i={5}
        // is_loaded={true}
        statusType={["Onboarding" , "Live" , list?.[0]?.status]}
        showType={true}
        is_status_card={statusType(list)[6]}
        load={load}
        agreement_id={agreement_id}
        is_terminated={is_terminated}
        is_overall ={true}
      />
    ),
  };
  return (
    <Box>
      <TabCompoent t={t} onChange={onChange} step={step}  is_show={Details?.length > 0 ? statusType(list) :statusType1(list) } indexOfOne={Details?.length > 0 ? true : false}/>
      <Box p={2}>{Details?.length > 0 ? components[step] : step === 0 ? components[1] : components[step + 1] }</Box>
    </Box>
  );
};
export default withNamespaces("agreement")(TimeLineList);
