import React from "react";
import { withNavBars } from "../../HOCs";
import { Accounts } from '../accounts/account'

class OwnerAccountListParent extends React.Component {
    render() {
        return <Accounts type="Property Owner" subtitle="Property Owner Account" /> ;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(OwnerAccountListParent, props);
