import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import CalendarPicker from '@mui/lab/CalendarPicker';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Divider, Grid, Popover, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AlertContext } from "../../contexts";
import { AlertProps, Regular, SemiBold } from "../../utils";
const styles = makeStyles((theme) => ({
    placeholder: {
        fontFamily: Regular,
        fontSize: 14,
        whiteSpace: "nowrap",
        color: "#98A0AC"
    },
    div: {
        width: "300px"
    },
    title: {
        fontFamily: SemiBold,
        fontSize: 12,
        color: "#4E5A6B",
        marginBottom: "8px"
    },
    type: {
        fontFamily: SemiBold,
        fontSize: 12,
        color: "#4E5A6B",
        backgroundColor: "#F2F4F7",
        borderRadius: theme.palette.borderRadius,
        padding: "4px 8px",
        textAlign: "center",
        marginRight: "4px",
        marginLeft: "4px",
        cursor: "pointer"

    },
    textField: {
        width: "70px",
        padding: "4px",
        [`& fieldset`]: {
            borderRadius: theme.palette.borderRadius,
            height: (props) => (props.multiline ? "unset" : 32),
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #5078E1",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: "11px 14px",
        },
    },
    dialog: {
        "& .MuiPopover-paper": {
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 8px 24px #0717411F"
        }
    },
    typea: {
        fontFamily: SemiBold,
        fontSize: 12,

        borderRadius: theme.palette.borderRadius,
        padding: "4px 8px",
        textAlign: "center",
        marginRight: "4px",
        marginLeft: "4px",
        cursor: "pointer",
        backgroundColor: "#5078E1",
        color: "white"
    },
    value: {
        fontFamily: SemiBold,
        fontSize: 14,
        color: "#091B29",
    },
}));
export const PopoverDatePicker = ({ startIcon = "", placeholder = "", updateState = () => false,
    duration = "",
    day = new Date().toISOString(),
    type = "",
    gracePeriod = ""
}) => {
    const classes = styles()
    let tempEndDate = gracePeriod ? new Date(JSON.parse(JSON.stringify(type?.date))) : null;
    let grace = gracePeriod ? tempEndDate.setDate(tempEndDate.getDate() + parseInt(gracePeriod)) : null;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const alert = React.useContext(AlertContext);
    const onChange = (dates) => {
        updateState("durationday", dates)


    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const getDate = (type, date, value = 10) => {

        switch (type) {
            case "Day":
                return new Date(date.setDate(date.getDate() + value))
            case "Month":
                return new Date(date.setMonth(date.getMonth() + value))
            case "Year":
                return new Date(date.setYear(date.getYear() + value))
            default:
                return new Date()
        }


    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const dayvalue = [
        {
            label: "Day",
            value: "Day",
        },
        {
            label: "Month",
            value: "Month",
        },
        {
            label: "Year",
            value: "Year",
        }

    ]
    return (
        <div>
            <Box sx={{ cursor: "pointer" }} display="flex" alignItems="center" height="45px" onClick={handleClick}>
                <Box marginLeft="8px" marginTop="6px">
                    <img src={startIcon} alt="" />
                </Box>
                <Box flexGrow={1} marginLeft="4px">
                    {
                        duration && day && type ?
                            <>
                                <Typography className={classes.value} >
                                    {moment(day)
                                        .tz(moment.tz.guess())
                                        .format("DD/MM/YY")}-
                                    {
                                        gracePeriod ?
                                            <>
                                                {moment(grace)
                                                    .tz(moment.tz.guess())
                                                    .format("DD/MM/YY")}
                                            </>
                                            :
                                            <>
                                                {moment(type?.date)
                                                    .tz(moment.tz.guess())
                                                    .format("DD/MM/YY")}
                                            </>
                                    }

                                </Typography>

                            </>
                            : <Typography className={classes.placeholder}>{placeholder}</Typography>
                    }
                </Box>
                <Box marginTop="4px" marginRight="8px">
                    <ArrowDropDownIcon style={{ fontSize: "26px", color: "#98A0AC" }} />
                </Box>

            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className={classes.dialog}
            >
                <div className={classes.div}>
                    <Box p={1}>
                        <Typography className={classes.title}>Lease Duration</Typography>
                        <Divider />

                        <Grid container marginTop="8px">
                            <Grid item xs={3}>
                                <input
                                    onKeyPress={(e) => {
                                        if (['e', 'E']?.includes(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    type="number" value={duration} size='small' className={classes.textField} onChange={(e) => updateState("duration", e.target.value)} />
                            </Grid>
                            {
                                dayvalue?.map((item) =>
                                    <Grid item xs={3}> <Typography
                                        onClick={
                                            (e) => {
                                                if (day) {
                                                    updateState("durationtype", {
                                                        date: getDate(item?.value, new Date(day), parseInt(duration)),
                                                        type: item?.value
                                                    })
                                                    handleClose()
                                                } else {

                                                    alert.setSnack({
                                                        ...alert,
                                                        open: true,
                                                        severity: AlertProps.severity.error,
                                                        msg: "First Fill start Date",
                                                        vertical: AlertProps.vertical.top,
                                                        horizontal: AlertProps.horizontal.center
                                                    })
                                                }

                                            }
                                        }
                                        className={item?.value === type?.type ? classes.typea : classes.type}>{item?.label}</Typography></Grid>
                                )
                            }



                        </Grid>
                        <Box height="10px" />
                        <Typography className={classes.title}>Start Date</Typography>
                        <center>
                            <DatePicker
                                selected={day}
                                onChange={onChange}
                                selectsDisabledDaysInRange
                                inline
                            />
                        </center>
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <CalendarPicker date={date} onChange={(newDate) => setDate(newDate)} />
                        </LocalizationProvider> */}
                    </Box>


                </div>
            </Popover>
        </div>
    )
}