import React from "react";
import { withNavBars } from "../../../HOCs";
import { Master } from "./master";

class MasterParent extends React.Component {
  render() {
    return <Master {...this.props} />;
  }
}

export default withNavBars(MasterParent);