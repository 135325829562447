import ContactMailIcon from '@mui/icons-material/ContactMail'
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { AggrementComponentStyles } from './styles'
import { timeZoneConverter } from '../../../utils'
export const KycCard = ({ value = {}, onClick = () => false, selected = {} }) => {
    const classes = AggrementComponentStyles()
    const status = {
        "Yet to be Verified": <Typography className={classes.kycStatus} sx={{ bgcolor: "#FFF4EB", color: "#FF9340" }}> Yet to be Verified </Typography>,
        "Verified": <Typography className={classes.kycStatus} sx={{ bgcolor: "#EEF9EE", color: "#5AC782" }}> Verified </Typography>,
        "Declined": <Typography className={classes.kycStatus} sx={{ bgcolor: "#FFECEC", color: "#FF4B4B" }}> Declined </Typography>
    }
    const returnType = (type) => {
        switch (type) {
            case "Yet to be Verified": {
                const data = { bgcolor: "#FFF4EB", color: "#FF9340" }
                return data;
            };
            case "Verified": {
                const data = { bgcolor: "#EEF9EE", color: "#5AC782" }
                return data;
            };
            case "Declined": {
                const data = { bgcolor: "#FFECEC", color: "#FF4B4B" }
                return data;
            };
            default: {
                const data = { bgcolor: "#FFF4EB", color: "#FF9340" }
                return data;
            }
        }
    }


    return (
        <>
            <Box display={"flex"} alignItems={"center"} className={value?.id === selected?.id ? classes.kycCardSelected : classes.kycCard} onClick={() => onClick(value)} p={1}>
                <Box>
                    <Avatar
                        sx={{
                            bgcolor: returnType(value?.status)?.bgcolor,
                            borderRadius: "4px",
                            padding: "12px",
                            width: 52,
                            height: 50
                        }} >
                        <ContactMailIcon sx={{ color: returnType(value?.status)?.color }} />
                    </Avatar>

                </Box>
                <Box marginLeft={"10px"} flexGrow={1}>
                    <Stack direction="row" spacing={1}>
                        <Typography className={classes.titleCard}>
                            {value.proofByID?.type}
                            {value?.is_mandatory ?
                                <Typography color="error">&nbsp;*</Typography> : ""}</Typography>
                        {
                            moment(value?.valid_to).isBefore(new Date()) &&
                            <Typography className={classes.content}>( Expired )</Typography>
                        }
                    </Stack>


                    <Stack direction={"row"} alignItems={"center"} marginTop="4px">
                        <Typography className={classes.content}> {value?.name} </Typography>
                        <Box className={classes.dot}></Box>
                        <Typography className={classes.content}> {value?.number} </Typography>
                        <Box className={classes.leadDetDot}></Box>
                        <Typography className={classes.content}> {timeZoneConverter(value?.valid_from)} - {timeZoneConverter(value?.valid_to)} </Typography>
                    </Stack>

                </Box>
                <Box marginLeft={"4px"}>
                    {status[value?.status]}
                </Box>
            </Box>
            <Divider />
        </>
    )
}