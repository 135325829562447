import { Box, Typography } from "@mui/material";
import React from "react";
import { AddressCardStyles } from "./style";
export const AddressCard = ({
  buttontext = "",
  data = {},
  name,
  editClick = () => false,
  isView = false,
}) => {
  const classes = AddressCardStyles();
  return (
    <Box className={`${classes.addresscardBody}`}>
      <div className={`${classes.firstSection}`}>
        <div className={`${classes.subsection1}`}>
          {/* button */}
          <div className={`${classes.button}`}>
            <Typography className={`${classes.buttonText}`}>
              {buttontext}
            </Typography>
          </div>
          {/* Name */}
          <Typography className={`${classes.userInfo}`}>
            {name} - {data?.city ?? "-"}
          </Typography>
        </div>
        {/* edit */}
        {!isView && (
          <Typography className={`${classes.edit}`} onClick={editClick}>
            Edit
          </Typography>
        )}
      </div>
      {/* address */}

      <Typography className={classes.addressList}>
        {data?.addressLineOne?.length > 0 && data?.addressLineOne + ","}
        {data?.addressLineTwo?.length > 0 && data?.addressLineTwo + ","}
        {data?.landmark?.length > 0 && data?.landmark + ","}
        {data?.area?.length > 0 && data?.area + ","}
        {data?.city?.length > 0 && data?.city + ","}
        <br />
        {data?.state?.length > 0 && data?.state + ","}
        {data?.country?.length > 0 && data?.country + ","}
        {data?.pincode + "."}
      </Typography>
    </Box>
  );
};
