import { Avatar, Box, Stack, Typography, Grid } from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ParkingGroupMasterDropdownStyles } from './style'
import { TickIcon } from '../../../components';

export const ParkingGroupMasterDropdown = ({ openParkingGroup = () => false, parkingGroupData = [], selectedParkingDropdown = () => false
}) => {
    const [open, setOpen] = React.useState(false)
    const classes = ParkingGroupMasterDropdownStyles()
    const showAll = () => {
        open ? setOpen(false) : setOpen(true)
    }
    const [selected, setSelected] = React.useState({})
    const select = (e, index) => {
        setSelected({
            id: e.id,
            name: e.name,
            index: index
        })
        selectedParkingDropdown(e.id, "")
        setTimeout(() => {
            setOpen(false)
        }, 500);
    }
    React.useEffect(() => {
        if (parkingGroupData?.length > 0)
            setSelected({
                id: parkingGroupData?.[0]?.id,
                name: parkingGroupData?.[0]?.name,
                index: 0
            })
        // eslint-disable-next-line
    }, [parkingGroupData])
    return (
        <Box>
            <Box className={classes.dropdown}>
                {parkingGroupData?.length > 0 ?
                    <Stack direction={"row"} spacing={1} alignItems={"center"} onClick={showAll} justifyContent="center" >
                        <Avatar className={classes.notAvatarSelected}>{`# ${selected.index + 1}`}</Avatar>
                        <Typography className={classes.label}> {selected.name} </Typography>
                        <span>
                            {
                                open ? <ExpandLessIcon /> : <ExpandMoreIcon />
                            }
                        </span>
                    </Stack>
                    :
                    <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent="center" >
                        <Typography className={classes.label}> {"No parkings found"} </Typography>
                    </Stack>}
            </Box>
            {
                open &&
                <Box className={classes.amenity_content} width={{ sm: 600, xs: 400, md: 800, lg: 900 }}>
                    <Grid container spacing={2}>
                        {parkingGroupData?.map((item, index) => {
                            return (
                                <Grid item md={3} onClick={() => select(item, index)} >
                                    <Stack spacing={1} direction={"row"} className={selected.id === item.id ? classes.selectedBorder : classes.notSelectedBorder}>
                                        <Avatar className={selected.id === item.id ? classes.avatarSelected : classes.notAvatarSelected}>{`# ${index + 1}`}</Avatar>
                                        <Stack spacing={1}>
                                            <Typography className={classes.name}>{item.name}</Typography>

                                        </Stack>
                                        <Box marginLeft={"24px !important"}>
                                            {selected.id === item.id &&
                                                <TickIcon />
                                            }
                                        </Box>

                                    </Stack>
                                </Grid>
                            )
                        })}


                    </Grid>
                </Box>
            }
        </Box>
    )
}