
import { Box, Button, Container, Grid, Hidden, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropGoToComponent from '../../assets/propgoto';
import { WelcomeBanner } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { GET_USER_PROFILE_ID_BY_USER_ID as queryOne, updateDeviceToken } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { LoginSuccess } from "../../router/access";
import { Routes } from "../../router/routes";
import { AlertProps, clearAccess, getAccessRoutes, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions, verifyToken } from "../../utils";
import { CompanyList } from './component/companylist';
import { useStyles } from "./style";

export const ComapnyPage = () => {
      // useDimension
      const size = useWindowDimensions();
      const { state } = useLocation()
      // classes
      const classes = useStyles(size);
      // history
      const history = useHistory();
      // state
      const [client, setClient] = React.useState({
            id: state?.main?.clientData?.user?.clients?.[0]?.id,
            tenantsID: state?.main?.clientData?.user?.clients?.[0]?.tenants?.slug
      })

      //open welcome banner
      const [open, setOpen] = React.useState({
            permission: {},
            bool: false,
            company: [],
            name: ""
      })
      // context
      const backdrop = React.useContext(BackdropContext);
      const auth = React.useContext(AuthContext);
      const alert = React.useContext(AlertContext);

      React.useEffect(() => {
            backdrop.setBackDrop({ ...backdrop, open: true, message: "", });
            if (client?.id) {
                  if (state?.main?.clientData?.user?.clients?.length === 1) {
                        handleSubmit()
                  } else {
                        backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                  }
            } else {
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            }
            // eslint-disable-next-line
      }, [client?.id]);

      // get user details
      const getUserDetails = (roles, type, userId, access, permissionRoutes, last_login) => {
            let company = access?.[auth?.auth?.auth?.activeRole]?.company_ids;


            const payload = {
                  query: queryOne,
                  variables: { userID: userId },
            };
            NetworkCall(
                  `${config.graphql_url}`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        if (company?.length > 0) {
                              setOpen({
                                    bool: true,
                                    permission: permissionRoutes,
                                    company: company,
                                    name: `${response?.data?.data?.user_profiles?.[0]?.first_name ?? ""} ${response?.data?.data?.user_profiles?.[0]?.last_name ?? ""}`
                              })
                        } else {
                              let path = LoginSuccess(permissionRoutes);
                         
                              history.push({
                                    pathname: path,
                                    state: {
                                          main: {
                                                first_name: `${response?.data?.data?.user_profiles?.[0]?.first_name ?? ""} ${response?.data?.data?.user_profiles?.[0]?.last_name ?? ""}`,
                                                isBanner: true
                                          },
                                    },
                              });
                        }

                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.success,
                              msg: "Client Selected Successfully",
                        });
                        localStorage.setItem(
                              LocalStorageKeys.profileID,
                              response?.data?.data?.user_profiles?.[0]?.id
                        );
                  })
                  .catch((error) => {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Something went wrong please try again",
                              vertical: AlertProps.vertical.top,
                              horizontal: AlertProps.horizontal.center
                        })
                  });
      }
      const handleAccess = (role_id) => {
            console.log(role_id,'role_id')
            // select role api
            axios.get(`${config.authapi}/auth/select_role?role_id=${role_id}`, { headers: { Authorization: `Bearer ${localStorage.getItem(LocalStorageKeys.authToken)}` } }).then((res) => {
                  // token set
                  localStorage.setItem(
                        LocalStorageKeys.authToken,
                        res?.data?.token
                  )
                  // client ID
                  localStorage.setItem(
                        LocalStorageKeys.clinetID,
                        res?.data?.selected_client?.id
                  );
                  // role switch token

                  // localStorage.setItem(LocalStorageKeys.role_switch_token, res?.data?.role_switch_token);
                  // role id
                  localStorage.setItem(LocalStorageKeys.roleID, res?.data?.selected_role?.role?.role_id);
                  // role name
                  localStorage.setItem(LocalStorageKeys.activeRole, Object.keys(res?.data?.access)?.[0]);

                  //get user profile details
                  // getUserDetails(res?.data?.userProfile?.[0]?.roles ,res?.data?.selected_role?.role?.name ,res?.data?.selected_role?.profile_id)

                  let access = res?.data?.access
                  let permissionData = Object.keys(access)
                  let permissionJson = access?.[permissionData?.[0]]?.role?.permissions?.[0]?.structure
                  localStorage.setItem(LocalStorageKeys.activeRoleModuleId, access?.[permissionData?.[0]]?.module_id);

                  clearAccess()
                  let permisionlist = getAccessRoutes(permissionJson)
                  let permissionRoutes = permisionlist?.map((val) => val?.route)
                  localStorage.setItem(LocalStorageKeys.permission, JSON.stringify(permissionRoutes))
                  getUserDetails(res?.data?.userProfile?.[0]?.roles, Object.keys(res?.data?.access)?.[0], res?.data?.selected_role?.profile_id, access, permissionRoutes, res?.data?.userProfile?.[0]?.last_logged_in)

                  setDeviceToken(res?.data?.userProfile?.[0]?.id, res?.data?.selected_client?.id)
            }).catch(() => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some thing went wrong",
                  });
                  localStorage.clear();
                  history.push(Routes.login);
            })
      }

      const getModualList = (modulesData, modules) => {
            let data = [];
            //eslint-disable-next-line
            modulesData.map((key) => {
                  let obj = {
                        name: key,
                        ...modules[key]
                  }
                  data.push(obj)
            })
            return data;
      }
      // sumbit function
      const handleSubmit = (e) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Loading",
            });
            if (e) {
                  e.preventDefault()
            }
            // select client
            axios.get(`${config.authapi}/auth/select_client?client_id=${client?.id ?? ""}`, { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } })
                  .then((res) => {

                        let RolesID = res?.data?.userProfile?.filter(i=>Object.keys(i?.module_access_roles)?.length>0)?.[0]?.roles;
                        let modules = res?.data?.userProfile?.filter(i=>Object.keys(i?.module_access_roles)?.length>0)?.[0]?.module_access_roles;
                        let modulesData = Object.keys(modules)
                        let modulesArray1 = modulesData.map((key) => modules[key])
                        let modulesArray = getModualList(modulesData, modules);
                        localStorage.setItem(LocalStorageKeys.modules, JSON.stringify(modulesArray));
                        localStorage.setItem(LocalStorageKeys.role, JSON.stringify(RolesID));
                        localStorage.setItem(LocalStorageKeys.slug, (res?.data?.selected_client?.tenants?.slug));
                        localStorage.setItem(LocalStorageKeys.clinetID, res?.data?.selected_client?.id);
                        localStorage.setItem(LocalStorageKeys.authToken, res?.data?.token);


                        if (res.status === 200) {
                              window.auth = verifyToken(res?.data?.token)
                              handleAccess(modulesArray1?.[0]?.client_roles?.[0]?.id)
                        } else {
                              history.push(Routes.login)
                        }
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: false,
                              message: "",
                        });
                  }).catch((err) => {
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: false,
                              message: "",
                        });
                  });

      }
      // company select function
      const clientSelect = (client) => {
            setClient({
                  id: client?.id,
                  tenantsID: client?.tenants?.slug
            })
      }
      //onClose banner
      const closeBanner = () => {
            setOpen({
                  ...open, bool: false
            })
            let path = LoginSuccess(open?.permission);
            history.push({
                  pathname: path,
                  state: {
                        main: {
                              first_name: open?.name ?? "",
                              isBanner: true
                        },
                  },
            });
      }
      // set device token
      const setDeviceToken = (val, client) => {
            if (localStorage.getItem(LocalStorageKeys.deviceToken)) {
                  const variables = {
                        query: updateDeviceToken,
                        variables: {
                              insert: [
                                    {
                                          user_profile_id: val,
                                          token: localStorage.getItem(LocalStorageKeys.deviceToken),
                                          is_active: true,
                                          client: client,
                                          app_build: config.app_x_build
                                    },
                              ],
                        },
                  };

                  NetworkCall(
                        `${config.graphql_url}`,
                        NetWorkCallMethods.post,
                        variables,
                        null,
                        true,
                        false
                  )
                        .then((response) => { })
                        .catch((error) => {
                              console.log(error)
                        });
            }
      };

      return (
            <>
                  <Hidden smDown>
                        <div className={classes.root}>
                              <form onSubmit={handleSubmit}>
                                    <Grid container className={classes.signupform}>
                                          <Grid item xs={12} className={classes.image}>
                                                <PropGoToComponent />
                                          </Grid>
                                          <Grid item xs={12} marginBottom={"45px"}>
                                                <Typography className={classes.title}>Select Clients</Typography>
                                          </Grid>
                                          <Grid item xs={12} marginBottom={"60px"}>
                                                <div style={{ height: 300 }} className={classes.list}>
                                                      {
                                                            state?.main?.clientData?.user?.clients?.map((value) => {
                                                                  return (
                                                                        <CompanyList data={value} companySelect={clientSelect} companyID={client?.id} />
                                                                  )
                                                            })
                                                      }
                                                </div>
                                          </Grid>
                                          <Grid item xs={12} marginBottom={"10px"}>
                                                <Button
                                                      fullWidth
                                                      variant="contained"
                                                      className={classes.btn}
                                                      type="submit"
                                                      onClick={handleSubmit}>
                                                      Continue
                                                </Button>
                                          </Grid>
                                    </Grid>
                              </form>
                        </div>
                  </Hidden>
                  <Hidden smUp>
                        <div>
                              <form onSubmit={handleSubmit}>
                                    <Container maxWidth="sm" style={{ padding: 0 }}>
                                          <Grid container style={{ height: "100vh", backgroundColor: "white" }}>
                                                <Grid
                                                      item
                                                      className={classes.content}
                                                      style={{ height: size.height > 700 ? size.height - 82 : 618 }}>
                                                      <Grid item xs={12} className={classes.backgroundImage} />
                                                      <Grid style={{ padding: "0px 16px 0px 16px" }}>
                                                            <Box height={"24px"} />
                                                            <Grid
                                                                  container
                                                                  xs={12}
                                                                  direction="row"
                                                                  justifyContent={"space-between"}
                                                                  alignContent={"center"}
                                                            >
                                                                  <img
                                                                        src="/images/PA Logo_1.svg"
                                                                        alt="logo"
                                                                        className={classes.img}
                                                                  />
                                                            </Grid>
                                                            <Box height={"16px"} />
                                                            <Grid item xs={12} marginBottom={"45px"}>
                                                                  <Typography className={classes.title}>Select Clients</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} marginBottom={"60px"}>
                                                                  <div className={classes.list}
                                                                        style={{
                                                                              height: size.height > 700 ? size?.height - 441 : 200,
                                                                              overflow: "auto", padding: "16px"
                                                                        }}>
                                                                        {
                                                                              state?.main?.clientData?.user?.clients?.map((value) => {
                                                                                    return (
                                                                                          <CompanyList data={value} companySelect={clientSelect} companyID={client?.id} />
                                                                                    )
                                                                              })
                                                                        }
                                                                  </div>
                                                            </Grid>
                                                      </Grid>
                                                </Grid>
                                                <Grid item xs={12} alignSelf={"self-end"} style={{ padding: "16px 16px 25px 16px" }}>
                                                      <Button
                                                            fullWidth
                                                            variant="contained"
                                                            className={classes.btn}
                                                            type="submit"
                                                            onClick={handleSubmit}>
                                                            Continue
                                                      </Button>
                                                </Grid>
                                          </Grid>
                                    </Container>
                              </form>
                        </div>
                  </Hidden>
                  {
                        open?.bool &&
                        <WelcomeBanner open={open?.bool} company={open?.company} closeDrawer={closeBanner} name={open?.name} />
                  }
            </>
      );
};
