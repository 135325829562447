import { Box, Button } from "@mui/material";
import React from "react";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { ReservationCard } from "./reservationCard";

export const CancelBooking = ({ data = {}, reload = () => false, company_id = "", quotation_id = "", type, onClose = () => false }) => {
    const alert = React.useContext(AlertContext);

    const onCancel = () => {
        const payload = {
            company_id: company_id,
            quotation_id: quotation_id
        }
        NetworkCall(
            `${config.api_url}/quotation/reserve/cancel`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then(() => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: `${type} Cancel Successfully`,
            });

            reload();
            onClose()
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: `${type} Cannot be Cancel`,
            });
        })
    }

    return (
        <Box p={3}>
            <ReservationCard
                data={data}
                view={false}
            />
            <Box height="18px" />
            <Button onClick={onCancel} fullWidth variant="contained">Cancel Booking</Button>
        </Box>
    )
}