import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        margin: "16px"
    },

    btn: {
        borderRadius: "8px",
    },
    btn2: {
        borderRadius: "8px",
        color: theme.palette.secondary.main,
    },
    text: {
        fontSize: "16px",
        fontFamily: Bold,
        color: theme.typography.color.primary,
    },
    subText: {
        fontSize: "12px",
        fontFamily: Bold,
        color: theme.typography.color.secondary,
    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "4px",
        padding: "10px",
    },
    searchdrop: {
        borderRadius: "10px",
        border: "1px solid #E4E8EE",
        cursor: "pointer",
    },
    chip: {
        backgroundColor: "#F2F4F7",
        marginLeft: "6px",
        marginTop: "10px",
        borderRadius: "4px",
        fontSize: "14px",
        cursor: "pointer",
        border: "none",
        boxShadow: "none",
        color: theme.typography.color.secondary,
        "&:hover": {
            border: "none",
            boxShadow: "none",
        },
        display: "flex",
        alignItems: "center",
    },
    Label: {
        color: theme.typography.color.secondary,
        fontSize: "12px",
        marginTop: "6px",
    },
    UnitStatusactive: {
        padding: "10px 16px",

        textAlign: "center",
        borderRadius: "4px",
        color: "white",
        fontSize: "14px",
        backgroundColor: "#5078E1",
        cursor: "pointer",
    },
    UnitStatus: {
        padding: "10px 16px",
        textAlign: "center",
        borderRadius: "4px",
        fontSize: "14px",
        cursor: "pointer",

        backgroundColor: "#F2F4F7",
    },
    previousbtn: {
        backgroundColor: "white",
        color: "#091B29",
        border: "1px solid #CED3DD",
        borderRadius: "10px",
        padding: "12px 8px",
        boxShadow: "none",
    },
    submitbtn: {
        borderRadius: "10px",
        padding: "12px 8px",
    },
    tableMain: {

        paddingRight: "16px",
        paddingTop: 0,
    },
    drawerwidth: {
        width: "380px",
        [theme.breakpoints.down("sm")]: {
            width: "318px",
        },
    },
    Header: {
        display: "flex",
        alignItems: "center",
        paddingBottom: "14px",
        borderBottom: "1px solid #00000012",
        position: "absolute",
        width: "100%",
        top: "0",
        height: "60px",
        padding: "16px 16px 16px",
        justifyContent: "left",
        backgroundColor: theme.palette.primary.contrastText,
        zIndex: "1",
        "& .MuiTypography-root": {
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: Bold,
        },
    },
    closeIcondrawermap: {
        right: "400px",
        position: "fixed",
        display: "flex",
        color: theme.palette.primary.contrastText,
        cursor: "pointer",
        fontSize: 28,
        marginTop: 8,
        "& .MuiSvgIcon-root": {
            fontSize: "35px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "28px !important",
            },
        },
        [theme.breakpoints.down("sm")]: {
            position: "relative",
            right: "11px",
            color: theme.palette.primary.dark,
            top: "0px",
            fontSize: "14px",
        },
    },
    createnewsection: {
        padding: "10px 15px 65px",
        height: "100vh",
        overflow: "auto",
        paddingTop: "80px",
        zIndex: "-1"
    },
    btnparent: {
        position: "absolute",
        bottom: "0",
        width: "100%",
        padding: "10px 16px",
        backgroundColor: "#fff",
        borderTop: "1px solid #00000012",
        "& .MuiButton-root": {
            width: "100%",
        },
    },
    Apply: {
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    clearall: {
        backgroundColor: "#fff",
        border: "1px solid #E4E8EE",
        color: "#091B29",
        "&:hover": {
            backgroundColor: "#fff",
            border: "1px solid #E4E8EE",
        },
    },
}));