import { Box, Grid, CircularProgress, Skeleton } from "@mui/material";
import React, { useEffect, useContext } from "react";
import CardDetails from "../components/cardDetails";
import { useStyles } from "../styles";
import {
  owner,
  ownerDay,
  // ownerStatus,
  leadTimeLine,
} from "../../../utils/agreementUtils";
import { CardComponent } from "../components";
import { NetWorkCallMethods, AlertProps } from "../../../utils";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { AlertContext } from "../../../contexts";

export const Lead = ({
  t,
  agreement_id = "",
  setDetails = () => false,
  statusType = [],
}) => {
  const classes = useStyles();
  // context
  const alert = useContext(AlertContext);
  // states
  const [list, setList] = React.useState({
    data: "",
    list: [],
  });
  const [loader, setLoader] = React.useState(false);
  // const [show, setShow] = React.useState(false);

  const getLeadDetails = (id) => {
    setLoader(true);
    const requestPayload = {
      id: agreement_id ?? "",
      status: statusType,
      type: "Proxy_Lead",
    };
    NetworkCall(
      `${config.api_url}/agreement/time_duration`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((res) => {
        const result = leadTimeLine(res?.data?.data);
        setList({
          data: res?.data?.data,
          list: result ?? [],
        });
        setDetails(res?.data?.data?.over_all_time_taken);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong. Please try again",
        });
      });
  };

  useEffect(() => {
    if (agreement_id && statusType?.length > 0) {
      getLeadDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreement_id]);
  return (
    <Box>
      <Grid container spacing={12}>
        <Grid item xs={9}>
          {loader ? (
            <Box className={classes.loader}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box className={classes.parent}>
                    {list?.list?.map((val, index) => {
                      return (
                        <Box className={classes.treeBox}>
                          <Box className={classes.border}>
                            <Box
                              className={
                                val?.assignee !== null
                                  ? classes.avatars
                                  : classes.avatars1
                              }
                            >
                              {val?.image}
                            </Box>
                            <CardDetails data={val} t={t} />
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid item xs={3}>
          {loader ? (
            Array.apply(null, Array(3)).map((value, index) => (
              <Grid key={index} item style={{ padding: "12px" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Skeleton
                      variant="rectangular"
                      height={"95px"}
                      style={{ borderRadius: "4px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Box className={classes.relativeCard}>
              <Box className={classes.parentCard}>
                <Box>
                  {owner(list?.data).map((x) => {
                    return (
                      <CardComponent
                        title={x?.title}
                        subTitle={x?.subtitle}
                        image={x?.image}
                        color={x?.color}
                        backgroundColor={x?.backgroundColor}
                      />
                    );
                  })}
                </Box>
                <Box height={"8px"} />
                <Box>
                {
                  list?.data?.duration && 
                  <>
                    {ownerDay(list?.data).map((x) => {
                  return (
                    <CardComponent
                      title={x?.title}
                      subTitle={x?.subtitle}
                      image={x?.image}
                      color={x?.color}
                      backgroundColor={x?.backgroundColor}
                    />
                  );
                })}</>
                }
                </Box>
                <Box height={"8px"} />
                {/* {ownerStatus(list?.data, show)?.map((x) => {
                  return (
                    <Box onClick={() => setShow(!show)}>
                      {x?.is_active && (
                        <CardComponent
                          title={x?.title}
                          subTitle={x?.subtitle}
                          image={x?.image}
                          color={x?.color}
                          backgroundColor={x?.backgroundColor}
                          is_divider={x?.is_divider}
                        />
                      )}
                    </Box>
                  );
                })} */}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
