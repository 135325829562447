import React from "react"
import { Box, Stack, Typography } from "@mui/material"
import { useStyles } from "./styles"
import { MileStoneIcon } from "../../../assets/mileStoneIcon"
import { Table } from "./table"

export const MileStoneTemplate = ({ dataType = "", heading = "", path = "", rows = [],title="" ,amountOnchange=()=>false, currency=""}) => {
    const classes = useStyles()

    return (
        <Box className={classes.mileStoneBg}>
            <Stack direction="row" spacing={1} alignItems="center">
                <MileStoneIcon />
                <Typography className={classes.mileStoneName}>{title}</Typography>
            </Stack>
            <Table
                dataType={dataType}
                rows={rows}
                heading={heading}
                path={path}
                amountOnchange={amountOnchange}
                currency={currency}
            />
        </Box>
    )
}