import { Box, Divider } from '@mui/material'
import React from 'react'
import { CreateCard } from './components'
import { useHistory } from 'react-router-dom'
import { Routes } from '../../router/routes'
import { enum_types, NetWorkCallMethods } from '../../utils'
import { NetworkCall } from '../../networkcall'
import { config } from '../../config'

export const CreatePopup = ({ data = [] }) => {
    const [enumData, setEnumData] = React.useState([])
    const history = useHistory()
    const navigate = (selected) => {
        history.push({
            pathname: Routes.createInvoiceFreeText,
            state: {
                selected: selected,
                type: "create"
            }
        })
    }

    React.useEffect(() => {
        getFreeTextInvoiceEnum()
    }, [])

    const getFreeTextInvoiceEnum = () => {
        const payload = { "enumName": [enum_types.free_text_invoice_type] }
        NetworkCall(
            `${config.api_url}/enum`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setEnumData(
                    response?.data?.data?.free_text_invoice_type?.map((e) => {
                        return {
                            key: e?.value,
                            name: e?.label
                        }
                    })
                )
            })
            .catch((error) => {
                console.log(error)
            });
    }
    return (
        <Box>
            {
                enumData?.map((e, index, length) => {
                    return (
                        <>
                            <CreateCard data={e} click={() => navigate(e)} />
                            {length.length - 1 !== index && <Divider></Divider>}
                        </>
                    )
                })
            }
        </Box>
    )
}