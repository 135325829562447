import { Badge, Box, Divider, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import FilterIMG from "../../assets/filter";
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";
import { KycDetailsTableHeading, KycDetailsTablePath, KycDetailsTableDataType, Mandatory } from "../../utils/kycDetails";
import { KycDetailsStyle } from "./style";

const KycDetails = (props) => {
    const { loading, handleLoading, t } = props;
    const Heading = KycDetailsTableHeading(t)
    const debounce = UseDebounce()
    const classes = KycDetailsStyle()
    const history = useHistory()
    const auth = React.useContext(AuthContext)
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)

    //company
    const [isloading, setLoading] = React.useState(false)
    const [companyList, setCompanyList] = React.useState([])
    const [permission, setPermission] = React.useState({})
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [searchText, setSearchText] = React.useState('')
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState({ status: [true] })
    const [fiterDrawer, setFilterDrawer] = React.useState(false)
    const [data, setData] = React.useState({
        list: [],
        count: ""
    })

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    if (auth) {
                        getKydDetail(0, 10, "", company?.selected?.value)
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // Go Back
    const goBack = () => { history.goBack(-1) }

    // Function to change the company
    const handleCompanyChange = (value) => {
        setLoading(true)
        setSelectedCompany(value)
        getKydDetail(0, 10, "", value?.value)
    }

    // Function to change search text
    const handleSearchTextChange = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    const getKydDetail = (offset = 0, limit = 10, search = "", companyId) => {
        setLoading(true)
        const payload = {
            offset: offset,
            limit: limit,
            search: search,
            company_id: companyId
        }

        NetworkCall(
            `${config.api_url}/kyc_approval_requests`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setData({ ...data, list: response?.data?.data, count: response?.data?.count })
                setLoading(false)
                handleLoading(false)
            }).catch((err) => {
                console.log("error from api", err)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    // msg: t("somethingWentWrong"),
                    msg: "notheingpsljsdg"
                });
                setLoading(false)

            })
    }


    // Function to search data in amenity booking
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getKydDetail(0, limit, e, selectedCompany?.value)
    }

    // Function to handle pagination in table
    const handlePagination = (e) => {
        setPage(e)
        let offset = (e - 1) * limit
        getKydDetail(offset, limit, "", selectedCompany?.value)
    }

    // Function to handle page limit in table
    const handleChangeLimit = (e) => {
        setLimit(e)
        setPage(1)
        getKydDetail(0, e, "", selectedCompany?.value)
    }

    const handleTableIcon = (type, data) => {
        if (type === "view") {
            history.push({
                pathname: Routes.kycDetailView,
                state: { data: data, company: selectedCompany }
            })
        }
    }

    const handleFilter = (value) => {
        setFilterData(value)
    }

    const render = () => {
        return (
            <Box>
                <Subheader
                    hideBackButton={false}
                    title={t("kycDetails")}
                    select
                    options={companyList}
                    value={selectedCompany}
                    goBack={goBack}
                    onchange={(e) => { handleCompanyChange(e) }} />

                <div className={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <SearchFilter
                                placeholder={t("Search")}
                                value={searchText}
                                handleChange={(value) => handleSearchTextChange(value)} />
                        </Grid>
                        <Grid item xs={8}>
                            <Box display={"flex"} justifyContent={"end"}>
                                <Stack direction="row" spacing={2}
                                    divider={<Divider orientation="vertical" flexItem />}>
                                    <IconButton onClick={() => setFilterDrawer(!fiterDrawer)}
                                        className={classes.filterButton}>
                                        <Badge variant="dot" color="primary"
                                            invisible={!(filterData.is_mandatory)}>
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                    </IconButton>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    {isloading ? (
                        <NewLoader minusHeight="500px" />
                    ) : (
                        <TableWithPagination
                            heading={Heading}
                            rows={data?.list}
                            path={KycDetailsTablePath}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            handleIcon={handleTableIcon}
                            onClick={() => false}
                            tableType="no-side"
                            dataType={KycDetailsTableDataType}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={data?.count}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 340px)'}
                            view={permission?.view}
                            edit={permission?.edit}
                            delete={permission?.delete} />
                    )}
                </div>

                


                <FilterGenerator open={fiterDrawer} onClose={() => setFilterDrawer(false)}
                    onApply={(value) => handleFilter(value)}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.is_mandatory,
                            options: Mandatory,
                            isMulti: false,
                            state_name: "is_mandatory",
                            label: "Mandatory",
                        },
                    ]} />
            </Box>
        )
    }

    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}

export default withNamespaces('kycDetails')(KycDetails)