import { Box, Grid, Typography } from "@mui/material";
import React from 'react';
import { AlertDialog } from "../../components";
import { DocumentViewer } from "../../components/fileViewer";
import { ProofView } from "../../components/proofComponent/section/proofView";
import { contactsViewStyles } from "./contactsViewStyle";
import { convertTimeUtcToZoneCalander } from '../../utils'

export const KycView = (props) => {
    const classes = contactsViewStyles()
    const [openPdf, setOpenPdf] = React.useState(false)
    const [currentImage, setCurrentImage] = React.useState("");
    const [currentImageType, setCurrentImageType] = React.useState("");
    const setOpenPdfBtn = (data) => {
        setOpenPdf(true)
        setCurrentImage(data?.[0])
        setCurrentImageType(data?.[1])
    }
    return (
        <Box className={classes.boxTab}>
            {props?.data?.proof?.length > 0 ?
                props?.data?.proof?.map((value) => {
                    return (
                        <>
                            <div className={classes.card3}>
                                <Typography className={classes.title} marginTop={"4px"}>ID DETAILS</Typography>
                                <Typography className={classes.sub_title} marginTop={"4px"}>{value?.proof_type_2?.label ?? ""}</Typography>
                                <Grid container rowSpacing={2} marginTop={"4px"}>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <Typography className={classes.heading}>Name As In Proof</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {(value?.name && value?.name !== "") ? value?.name : "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <Typography className={classes.heading}>ID Proof Number</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {(value?.number && value?.number !== "") ? value?.number : "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <Typography className={classes.heading}>Valid From</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {(value?.valid_from && value?.valid_from !== "") ?
                                                convertTimeUtcToZoneCalander(value?.valid_from) : "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <Typography className={classes.heading}>Valid To</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {(value?.valid_to && value?.valid_to !== "") ?
                                                convertTimeUtcToZoneCalander(value?.valid_to) : "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <Typography className={classes.heading}>Issuing Country</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {(value?.country?.label && value?.country?.label !== "") ? value?.country?.label : "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <Typography className={classes.heading}>Mandatory Verify</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {(value?.is_mandatory && value?.is_mandatory !== "") ? "yes" : "false"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.heading}>Document</Typography>
                                        <Grid container spacing={2}>

                                            {
                                                value?.url?.length > 0 &&
                                                value?.url?.map((val) => {
                                                    return (
                                                        <Grid item xs={3}>
                                                            <ProofView
                                                                disable={true}
                                                                name={val?.name}
                                                                src={val?.src}
                                                                fileType={val?.type}
                                                                setOpenPdfBtn={setOpenPdfBtn}

                                                            />
                                                        </Grid>
                                                    )

                                                })
                                            }
                                        </Grid>

                                        <AlertDialog
                                            isNormal={currentImageType === "img" ? true : false}
                                            medium={currentImageType === "img" ? false : true}
                                            isnotTitle component={
                                                currentImageType === "img" ?
                                                    <div style={{ display: 'flex' }}>
                                                        <img src={currentImage} alt='' style={{ objectFit: 'cover', width: '444px' }} />
                                                    </div>
                                                    :
                                                    <div>
                                                        <DocumentViewer url={currentImage} />
                                                    </div>

                                            } open={openPdf} onClose={() => setOpenPdf(false)} />

                                    </Grid>
                                </Grid>
                            </div>
                            <br />
                        </>
                    )
                })
                :
                <Typography className={classes.noData} >{"No data found"}</Typography>
            }
        </Box>

    )

}