import { Box, Grid, Stack } from '@mui/material'
import React from 'react'
import { AlertDialog } from '../../components'
import { AmenityDetails, AmenityPaymentDetails, CancelBooking, DeclineQRcode } from './components'
import { RequestMessage } from '../alreadyPaid/components'
import moment from 'moment'
import { withNamespaces } from 'react-i18next'
const DeclinePopup = (
    {
        amenityBookingDetail = {},
        type = "",
        t,
        closeDecline = () => false,
        updateAmenityBookingList = () => false
    }
) => {
    const [cancelPopup, setCancelPopup] = React.useState(false)
    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item md={6} sm={6}>
                    <Stack>
                        <AmenityPaymentDetails amenityBookingDetail={amenityBookingDetail} />
                        <AmenityDetails amenityBookingDetail={amenityBookingDetail} />
                    </Stack>
                </Grid>
                <Grid item md={6} sm={6}>
                    <Stack p={1}>
                        {
                            amenityBookingDetail?.booking_status === "Cancelled" &&
                            <RequestMessage
                                type="Cancelled"
                                title={t("RequestCancelled")}
                                message={t("Declinedby") + amenityBookingDetail?.user_first_name + " " + moment(amenityBookingDetail?.booked_date).format("DD MMM")}
                                remarks={amenityBookingDetail?.remarks} />
                        }
                        <DeclineQRcode amenityBookingDetail={amenityBookingDetail} type={type} cancelClick={() => setCancelPopup(!cancelPopup)} />
                    </Stack>
                </Grid>
            </Grid>
            <AlertDialog isNormal open={cancelPopup}
                onClose={() => setCancelPopup(!cancelPopup)}
                header={t("CancelBooking")}
                component={
                    <CancelBooking 
                    amenityBookingDetail={amenityBookingDetail} 
                    closeCancel={() => setCancelPopup(!cancelPopup)} 
                    closeDecline={closeDecline}
                    updateAmenityBookingList={updateAmenityBookingList} />
                } />
        </Box>
    )
}

export default withNamespaces("amenityBooking")(DeclinePopup)
