import { useApolloClient } from "@apollo/client";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { GET_CONTACT_DETAILS,GET_PROSPECTIVE_DETAILS } from "../../../graphql/quotationQueries";
import { AlertDialog, LogoUpload, MobileNumberInputComponent, TextBox } from "../../index";
import { ProofComponent } from "../../proofComponent";
import { Address } from "../component";
import { useStyles } from "./styles";
export const AddContact = ({ data = {}, contact = {}, updateState = () => false, disable = false, contactId = null, email_id = null, setDisable = () => false, setContact = () => false, setContactID = () => false ,is_prospect=false}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false)
    const client = useApolloClient();

    const submitAddress = (value) => {
        updateState("address", value)
        setOpen(false)
    }
    const getContact = () => {
        client.query({
            query: GET_CONTACT_DETAILS,
            fetchPolicy: 'network-only',
            variables: {
                id: email_id
            }
        }).then((response) => {
            let res = response.data.contact[0]
            if (res) {
                setDisable(true)
                setContactID(res?.id)
                setContact({
                    ...contact,
                    profilePic: {
                        src: res?.image_url ?? ""
                    },
                    name: res?.first_name ?? "",
                    email: res?.email_id ?? "",
                    mobile: {
                        mobile: res?.mobile_no ?? "",
                        mobile_code: res?.mobile_no_country_code ?? ""
                    },
                    alternativemobile: {
                        mobile: res?.alternative_mobile?.length > 0 ? res?.alternative_mobile : '',
                        mobile_code: res?.alternative_mobile_country_code ?? null,
                    },
                    telephone: {
                        mobile: (res?.phone !== "undefined" && res?.phone?.length > 0)? res?.phone : "",
                        mobile_code: (res?.phone_code !== "undefined" && res?.phone_code?.length > 0) ? res?.phone_code : null
                    },
                    fax: {
                        mobile: (res?.fax_no !== "undefined" && res?.fax_no?.length > 0)? res?.fax_no : "",
                        mobile_code: (res?.phone_code !== "undefined" && res?.phone_code?.length > 0) ? res?.phone_code : null
                    },
                    nationality: res?.nationality,
                    facebook: res?.facebook,
                    twitter: res?.twitter,
                    linkedin: res?.linkedin,
                    idType: res?.identification_master,
                    idNo: res?.identification_no,
                    idExpire: res?.identification_expriy,
                    address: res?.address,
                    passportNo: res?.passport_id,
                    visaNo: res?.visa_no,
                    passportExipre: res?.passport_expiry,
                })
            }
            if(!response?.data?.contact?.length > 0 && is_prospect){
                getprospective()
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const getprospective=()=>{
        client.query({
            query: GET_PROSPECTIVE_DETAILS,
            fetchPolicy: 'network-only',
            variables: {
                id: email_id
            }
        }).then((response) => {
            let res = response?.data?.prospective[0]
            if (res) {
                setDisable(false)
                setContactID(res?.id)
                setContact({
                    ...contact,
                    profilePic: {
                        src: res?.image_url ?? ""
                    },
                    name: res?.first_name ?? "",
                    email: res?.email_id ?? "",
                    mobile: {
                        mobile: res?.mobile_no ?? "",
                        mobile_code: res?.mobile_no_country_code ?? ""
                    },
                    alternativemobile: {
                        mobile: res?.alternative_mobile?.length > 0 ? res?.alternative_mobile : '',
                        mobile_code: res?.alternative_mobile_country_code ?? null,
                    },
                    telephone: {
                        mobile: (res?.phone !== "undefined" && res?.phone?.length > 0)? res?.phone : "",
                        mobile_code: (res?.phone_code !== "undefined" && res?.phone_code?.length > 0) ? res?.phone_code : null
                    },
                    fax: {
                        mobile: (res?.fax_no !== "undefined" && res?.fax_no?.length > 0)? res?.fax_no : "",
                        mobile_code: (res?.phone_code !== "undefined" && res?.phone_code?.length > 0) ? res?.phone_code : null
                    },

                    facebook: res?.facebook,
                    twitter: res?.twitter,
                    linkedin: res?.linkedin,
                    address:  {
                        addressLineOne:res?.addressLineOne,
                        addressLineTwo:res?.addressLineTwo,
                        landmark:res?.landmark,
                        country:res?.country,
                        state:res?.state,
                        district:res?.district,
                        city:res?.city
                       }
                })
            }
        }).catch((err) => {
            console.log(err)
        }) 
    }


    React.useEffect(() => {
        if (email_id) {
            getContact()
        }
        // eslint-disable-next-line
    }, [])
    return (
        <div>
            <Grid container spacing={1} className={classes.addContactParent}>

                <Grid item xs={1.8} >
                    <Typography textAlign="center" className={classes.addresstitle} marginBottom="12px">CONTACT DETAILS</Typography>
                    <LogoUpload
                        isReadonly={disable}
                        logo_title={""} data={data?.profilePic} profile
                        handleChange={(value) => updateState("profilePic", value)}
                    />
                </Grid>
                <Grid item xs={10.2}>
                    <Grid container spacing={2}>
                        {/* name  */}
                        <Grid item xs={4}>
                            <TextBox label={"Name"} placeholder={"Name"}
                                isReadonly={disable}
                                value={data?.name} isrequired
                                onChange={(e) => {
                                    updateState("name", e.target.value);
                                }}
                                isError={data?.error?.name?.length > 0}
                                errorMessage={data?.error?.name}
                            />
                        </Grid>
                        {/*email  */}
                        <Grid item xs={4}>
                            <TextBox label={"Email Address"} placeholder={"Email Address"}
                                value={data?.email} isrequired
                                isReadonly={disable}
                                onChange={(e) => {
                                    updateState("email", e.target.value);
                                }}
                                isError={data?.error?.email?.length > 0}
                                errorMessage={data?.error?.email}
                            />
                        </Grid>
                        {/* mobile */}
                        <Grid item xs={4}>
                            <MobileNumberInputComponent label={"Mobile"} placeholder={"Mobile"}
                                value={data?.mobile} isRequired
                                handleChange={(value) => {
                                    updateState("mobile", value);
                                }}
                                isError={data?.error?.mobile?.length > 0}
                                errorMessage={data?.error?.mobile}
                                isReadonly={disable}

                            />
                        </Grid>
                        {/*alternative  mobile */}
                        <Grid item xs={4}>
                            <MobileNumberInputComponent
                                label={"Alternative Mobile"}
                                placeholder={"Alternative Mobile"}
                                value={data?.alternativemobile}
                                handleChange={(value) => {
                                    updateState("alternativemobile", value);
                                }}
                                isReadonly={disable}
                            />
                        </Grid>
                        {/*Telephone  */}
                        <Grid item xs={4}>
                            <MobileNumberInputComponent
                                label={"Telephone"}
                                placeholder={"Telephone"}
                                value={data?.telephone}
                                handleChange={(value) => {
                                    updateState("telephone", value);
                                }}
                                isReadonly={disable}
                            />
                        </Grid>
                        {/*fax */}
                        <Grid item xs={4}>
                            <MobileNumberInputComponent
                                label={"Fax"}
                                placeholder={"Fax"}
                                value={data?.fax}
                                handleChange={(value) => {
                                    updateState("fax", value);
                                }}
                                isReadonly={disable}
                            />
                        </Grid>
                        {/*Nationality  */}
                        <Grid item xs={4}>
                            <TextBox label={"Nationality"} placeholder={"Nationality"}
                                value={data?.nationality}
                                onChange={(e) => {
                                    updateState("nationality", e.target.value);
                                }}
                                isReadonly={disable}
                            />
                        </Grid>
                        {/*gender  */}
                        {/* <Grid item xs={4} className={classes.field}>
                            <SelectBox
                                isRequired={true}
                                label={"Gender"}
                                options={Gender}
                                value={data?.gender}
                                onChange={(value) => updateState('gender', value)}
                                isReadonly={disable}
                            />
                        </Grid> */}
                    </Grid>

                </Grid>
            </Grid>
            {/* address */}
            <Box className={classes.address}>
                <Box display="flex" alignItems="center" p={1}>
                    <Box flexGrow={1}>
                        <Typography className={classes.addresstitle}>Address</Typography>
                    </Box>
                    <Box>
                        {
                            !disable && <Typography className={classes.addAddress} onClick={() => setOpen(true)}>Add Address</Typography>
                        }

                    </Box>
                </Box>
                <Box className={classes.addressBox}>
                    {
                        data?.address?.country && <div className={classes.addressbox}>
                            <Box
                                className={classes.addressChid}
                                display="flex"
                                alignItems="center"
                            >
                                <Box>
                                    {
                                        data?.primaryAddress && <Typography className={classes.primary}>PRIMARY</Typography>
                                    }

                                </Box>
                                <Box flexGrow={1} marginLeft="14px">
                                    <Typography className={classes.addresstitles}>
                                        {data?.address?.description}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography className={classes.addressList}>
                                {data?.address?.addressLineOne + ","}
                                {data?.address?.addressLineTwo + ","}
                                {data?.address?.landmark + ","}
                                {data?.address?.area + ","}
                                <br />
                                {data?.address?.city + ","}
                                {data?.address?.state + ","}
                                {data?.address?.country + ","}
                                {data?.address?.pincode + "."}
                            </Typography>
                        </div>
                    }

                </Box>
            </Box>
            {/*social media*/}
            <Box className={classes.address1}>
                <Grid container spacing={2} >
                    {/* social media */}
                    <Grid item xs={12} className={classes.field}>
                        <Typography className={classes.addresstitle}>Social Profile</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.field}>
                        <TextBox
                            label="Facebook"
                            isReadonly={disable}
                            placeholder="Enter facebook"
                            onChange={(value) => updateState("facebook", value.target.value)}
                            value={data.facebook}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.field}>
                        <TextBox
                            isReadonly={disable}
                            label="Twitter"
                            placeholder="Enter Twitter"
                            onChange={(value) => updateState("twitter", value.target.value)}
                            value={data.twitter}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.field}>
                        <TextBox
                            isReadonly={disable}
                            label="Linkdedin"
                            placeholder="Enter Linkdedin"
                            onChange={(value) => updateState("linkedin", value.target.value)}
                            value={data.linkedin}
                        />
                    </Grid>

                </Grid>
            </Box>

            {/*proof name */}
            {
                disable ? "" :
                    <Box marginTop="10px">
                        <ProofComponent update={(data) => updateState("proofFile", data)} />
                        {
                            data?.error?.proofFile?.length > 0 &&
                            <Typography variant="caption" color="error">{data?.error?.proofFile}</Typography>
                        }

                    </Box>

            }

            {/*address dialog*/}
            <AlertDialog
                open={open}
                onClose={() => setOpen(false)}
                header={"Add Address"}
                component={<Address
                    data={data?.address}
                    onCancel={() => setOpen(false)}
                    onSubmit={submitAddress}
                />}
            />
        </div>
    );
};
