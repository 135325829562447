import { Dialog, IconButton, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from "../style"
import { TableWithPagination } from "../../../components";
import { UnitVacancyHeading, UnitVacancyPath, UnitVacancyType } from "../../../utils/unitVacancy/tableUtils";
import { GET_UNIT_VACANCY_PERIOD_LIST } from "../../../graphql/queries";
import React from "react";
import { AlertContext, BackdropContext } from "../../../contexts";
import { useApolloClient } from "@apollo/client";
import { AlertProps, LocalStorageKeys } from "../../../utils";

export const UnitVacancyList = (props) => {

    // constants
    const classes = useStyles()
    const client = useApolloClient()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)

    // useState
    const [list, setList] = React.useState({})
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)

    // useEffect to get property list using selected company and filter data when loading the screen
    React.useEffect(() => {
        if (props?.dialogState?.id) {
            setPage(1)
            getList()
        }
        // eslint-disable-next-line
    }, [props?.dialogState?.id])

    // Function to get property list based on the input data
    const getList = (offset = 0, limit = 10) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        client.query({
            query: GET_UNIT_VACANCY_PERIOD_LIST,
            fetchPolicy: "network-only",
            variables: {
                clientID: localStorage.getItem(LocalStorageKeys.clinetID) ?? "",
                unitID: props?.dialogState?.id, offset, limit,
            }
        }).then((r) => {
            setList({
                data: r?.data?.unit_vacancy,
                totalRowsCount: r?.data?.count?.[0]?.count
            })
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((e) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: "Some Thing Went Wrong"
            })
        })
    }

    // eslint-disable-next-line
    const Row = React.useCallback(list?.data?.map((_) => {
        let p
        try {
            p = {
                id: _?.id,
                // startDate: _?.available_from ? moment(_?.available_from).format("DD MMM YYYY") : "-",
                // endDate: _?.available_to ? moment(_?.available_to).format("DD MMM YYYY") : "-",
                vacantStatus: _?.status ?? "-",
                data: _,
                unitNo: _?.unit_no,
                unitName: _?.name,
                image: _?.logo,
                companyName: _?.company_name ?? "-",
                reservationNumber: _?.reservation_number ?? "-",
                primaryContactNumber: _?.mobile_no ?? "-",
                primaryContactName: _?.first_name ? `${_?.first_name} ${_?.last_name ?? ""}` : " - ",
                agreementNumber: _?.agreement_no ?? "-",
                endDate: _?.available_to,
                startDate: _?.available_from,
                currentStatus: _?.current_status,
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: "Some Thing Went Wrong"
            })
        }
        return p
        // eslint-disable-next-line
    }), [list])

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getList(offset, limit)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getList(0, value)
    }

    const onClose = () => {
        props?.setDialogOpen(false)
        props?.setDialogState(null)
        setList(null)
        setPage(1)
        setLimit(10)
    }

    return <Dialog
        className={classes.viewDialog}
        open={props?.dialogOpen}
        onClose={onClose}>
        <div className={classes.viewDialogHeader}>
            <Typography className={classes.viewDialogHeaderTitle}>
                {props?.dialogState?.unitName}
            </Typography>
            <IconButton onClick={onClose}
                className={classes.viewDialogCloseButton}>
                <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
            </IconButton>
        </div>
        <div className={classes.viewDialogBody}>
            <TableWithPagination
                heading={UnitVacancyHeading}
                rows={Row}
                path={UnitVacancyPath}
                showpagination={true}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                handleIcon={() => console.log("")}
                onClick={() => console.log("")}
                tableType="no-side"
                dataType={UnitVacancyType}
                handlePagination={handleTablePagination}
                handleChangeLimit={handleTablePageLimit}
                totalRowsCount={list?.totalRowsCount}
                page={page}
                limit={limit}
                height={'400px'}
                view={true}
                edit={true}
                delete={true} />
        </div>
    </Dialog >
}