import * as React from "react"

export const Decline = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 105387">
      <path data-name="Rectangle 55504" fill="none" d="M0 0h20v20H0z" />
      <path
        d="M10 3a7 7 0 1 0 7 7 7.011 7.011 0 0 0-7-7Zm0 1.4a5.555 5.555 0 0 1 3.421 1.189l-7.832 7.832A5.578 5.578 0 0 1 10 4.4Zm4.411 2.179A5.578 5.578 0 0 1 6.58 14.41Z"
        fill="#ff4b4b"
      />
    </g>
  </svg>
)
