import { Grid } from '@mui/material';
import React from 'react';
import { SearchFilter, TableWithPagination, UseDebounce } from '../../components';
import { config } from '../../config';
import { AlertContext, BackdropContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import { Heading, MOVE_OUT_DATA, Path, Type } from './utils';

export const MoveOutTable = (props) => {

    const debounce = UseDebounce()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const [list, setList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
    // Function to get agreement termination list based on the input data
    const getList = (offset = 0, limit = 10, search = "", agreement_request_id = "") => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        const payload = {
            query: MOVE_OUT_DATA(offset, limit, search, agreement_request_id, clientId).loc.source.body,
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            let row = r?.data?.data?.agreement_inspection_request?.[0]?.agreement_inspection?.map((val) => {
                return {
                    inspectionID: val?.reference_id ?? "-",
                    unitID: val?.unit?.unit_no ?? "-",
                    associatedUnit: "-",
                    propertyName: val?.unit?.property?.name ?? "-",
                    tenantName: val?.agreement_units?.agreement_unit_users?.[0]?.contact?.first_name ?? "-",
                    inspectorName: "-",
                    status: val?.status,
                }
            })

            setList({
                data: row ?? [],
                totalRowsCount: r?.data?.data?.count?.[0]?.count
            })
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((error) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true, msg: "Some Thing Went Wrong",
                severity: AlertProps.severity.error
            })
        })
    }
    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in agreement request list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getList(0, limit, e, props?.agreementRequestId)
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getList(offset, limit, searchText, props?.agreementRequestId)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getList(0, value, searchText, props?.agreementRequestId)
    }

    React.useEffect(() => {
        if (props?.agreementRequestId?.length > 0) {
            getList(0, limit, searchText, props?.agreementRequestId)
        }
        // eslint-disable-next-line
    }, [props?.agreementRequestId])

    return (
        <>
            <Grid container spacing={1} padding={"12px"}>
                <Grid item xs={4}>
                    <SearchFilter value={searchText} placeholder="Search Inspection"
                        handleChange={(value) => handleSearch(value)} />
                </Grid>
                <Grid item xs={12}>
                    <TableWithPagination
                        heading={Heading}
                        rows={list?.data}
                        path={Path}
                        showpagination={true}
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        onClick={() => console.log("")}
                        tableType="no-side"
                        dataType={Type}
                        handleIcon={false}
                        handlePagination={handleTablePagination}
                        handleChangeLimit={handleTablePageLimit}
                        totalRowsCount={list?.totalRowsCount}
                        page={page}
                        limit={limit}
                        height={'calc(100vh - 290px)'}
                        view={false}
                        edit={false}
                        delete={false} />
                </Grid>
            </Grid>
        </>
    )
}