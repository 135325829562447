import React, { useState } from "react";
import { FormGenerator, AlertDialog } from "../../../components";
import { Button, Box } from "@mui/material";
import { useStyleTimeLine } from "../styles";
export const TermisionRequestForm = ({
  open = false,
  setOpen = () => false,
  t
}) => {
  const classes = useStyleTimeLine();
  const [state, setState] = useState({
    termissionDate: null,
    tentativeDate: null,
    Description: "",
  });

  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
  };
  return (
    <Box>
      <AlertDialog
        isNormal={true}
        header={t("Termination_Request")}
        onClose={() => setOpen({...open , bool:false})}
        open={open}
        component={
          <Box p={2}>
            <FormGenerator
              components={[
                {
                  isActive: true,
                  component: "datepicker",
                  label: t("Agreement_Termination_Date"),
                  value: state?.termissionDate,
                  placeholder: t("Agreement_Termination_Date"),
                  onChange: (value) => updateState("termissionDate", value),
                  error: state?.error?.termissionDate,
                  maxDate: new Date(),
                  isRequired: true,
                  isNot: true,
                  size: {
                    xs: 12,
                  },
                },
                {
                  isActive: true,
                  component: "datepicker",
                  label: t("Tentative_Vacating_Date"),
                  value: state?.tentativeDate,
                  placeholder: t("Tentative_Vacating_Date"),
                  onChange: (value) => updateState("tentativeDate", value),
                  error: state?.error?.tentativeDate,
                  maxDate: new Date(),
                  isRequired: true,
                  isNot: true,
                  size: {
                    xs: 12,
                  },
                },
                {
                  isActive: true,
                  component: "text",
                  label: t("Reason_for_Termination"),
                  value: state?.Description,
                  placeholder: t("Reason_for_Termination"),
                  onChange: (e) => updateState("Description", e?.target?.value),
                  error: state?.error?.Description,
                  size: {
                    xs: 12,
                  },
                  multiline:true,
                },
              ]}
            />
            <Box height={"24px"} />
            <Button className={classes.Yes}>{t("Submit")}</Button>
          </Box>
        }
      />
    </Box>
  );
};
