import { Box, Typography } from "@mui/material"
import React from "react"
import { AggrementComponentStyles } from "./styles"

export const PaymentMethodCard = ({ paymentMethod = {}, value = "", onClick = () => false }) => {

    const classes = AggrementComponentStyles()
    return (
        <Box className={value === paymentMethod?.value ? classes.PaymentMethodCardSelect : classes.PaymentMethodCard} onClick={() => onClick(paymentMethod?.value)}>
            <Typography className={`${classes.PaymentMethodtitle}`}>{paymentMethod?.label}</Typography>
        </Box>
    )
}