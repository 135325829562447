export const LeadPath = ["lead_no", "created_at", "subject", "unit_usage", "lead_source", "urgent", "owner", "status"]

export const LeadHeading = [
    { title: "Opportunity No", field: "lead_no" },
    { title: "Date", field: "created_at" },
    { title: "Description", field: "subject" },
    { title: "Purpose", field: "unit_usage" },
    { title: "Source", field: "lead_source" },
    { title: "Priority", field: "urgent" },
    { title: "Owner", field: "owner" },
    { title: "Status", field: "status" },
]

export const LeadType = [
    { type: ["text"], name: "lead_no" },
    { type: ["text"], name: "created_at" },
    { type: ["quill"], name: "subject" },
    { type: ["text"], name: "unit_usage" },
    { type: ["text"], name: "lead_source" },
    { type: ["text"], name: "urgent" },
    { type: ["text"], name: "owner" },
    { type: ["status"], name: "status" },
]

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]