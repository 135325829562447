import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import moment from "moment"
import React, { useContext, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { CircleIcon, InspectionIcon } from "../../../assets"
import { AssignIcon } from "../../../assets/assignIcon"
import { ServiceInspectionIcon } from "../../../assets/serviceInspection"
import { AccordianWithDetails, AlertDialog, DialogBox, LoadingSection, Subheader } from "../../../components"
import AssignModal from "../../../components/assignModal/reAssignModal"
import { config } from "../../../config"
import { AlertContext } from "../../../contexts"
import { withNavBars } from "../../../HOCs"
import { NetworkCall } from "../../../networkcall"
import { AlertProps, NetWorkCallMethods, useWindowDimensions } from "../../../utils"
import { useStyles } from "../style"
import { RequestCard } from "./requestCard"
import { saveAs } from "file-saver";
import { updateAgreementInspections } from '../../../graphql/inspectionTemplate'
import { useApolloClient } from '@apollo/client'
import { EditInspectionItems } from './editInspectionItems'



const ViewServiceInspection = () => {
    const size = useWindowDimensions()
    const client = useApolloClient()
    const { state } = useLocation()
    const history = useHistory()
    const alert = useContext(AlertContext)
    const classes = useStyles()
    const [assignModal, setAssignModal] = useState(false)
    const [details, setDetails] = useState([])
    const [isComplete, setIsComplete] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reAssignModal, setReAssignModal] = useState(false)
    const [cancelModal, setCancelModal] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [offset, setOffset] = useState(0)
    const [notesData, setNotesData] = useState({
        managerNotes: [],
        inspectorNotes: []
    })
    const [isEdit, setIsEdit] = useState(false)
    const [data, setData] = useState({
        selectedInspectionItem: [],
        inspectionItemData: [],
        old_items: [],
        new_items: []
    })
    const getInspectionDetails = () => {
        setLoading(true)
        const payload = {
            "request_id": state?.request_id
        }
        NetworkCall(
            `${config.api_url}/general_inspection/details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setLoading(false)
                getInspectionItems(response?.data?.data?.details?.agreement_inspection?.unit_id)

                setDetails({ data: response?.data?.data?.details, list: response?.data?.data?.list })
                setNotesData({ managerNotes: response?.data?.data?.details?.agreement_inspection?.inspection_manager_notes ?? [], inspectorNotes: response?.data?.data?.details?.agreement_inspection?.inspector_notes ?? null })
            })
            .catch((error) => {
                setLoading(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                });
            });
    }
    React.useEffect(() => {
        getInspectionDetails()
        //eslint-disable-next-line
    }, [])
    const goBack = () => {
        history.goBack()
    }
    const openAssignModal = () => {
        setAssignModal(true)
    }
    const dialogHeader = () => {
        return (
            <Stack direction="row" spacing={1}>
                <Box onClick={() => setAssignModal(false)} className={classes.iconbtn} display="flex" alignItems="center">
                    <ArrowBackIosIcon className={classes.icon} htmlColor="black" />
                </Box>
                <Typography className={classes.dialoghdrtext}>{"Assign Inspector"}</Typography>
            </Stack>
        )
    }
    //for assigning
    const handleAssign = (payload) => {
        NetworkCall(
            `${config.api_url}/agreement_inspection/assign_resource`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Assigned Successfully",
                });
                getInspectionDetails()
                setAssignModal(false)
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "data not found",
                });
            })
    }
    //reassign submit
    const reAssign = (payload) => {
        NetworkCall(
            `${config.api_url}/agreement_inspection/re_assign_resource`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Re Assigned Successfully",
                });
                getInspectionDetails()
                setReAssignModal(false)
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "data not found",
                });
            })
    }
    const completeInspection = () => {
        const payload = {
            agreement_inspection_id: state?.agreement_inspection_id,
        }
        NetworkCall(
            `${config.api_url}/general_inspection/complete_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Inspection Completed Successfully",
            });
            setIsComplete(false)
            getInspectionDetails()
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something went wrong",
            });
        })
    }
    const updateState = (k, v, k1, v1, k2, v2) => {
        setData({ ...data, [k]: v, [k1]: v1, [k2]: v2 })
    }
    const handleSearch = (v) => {
        // setData({ ...data, [k]: v })
        getInspectionItems(details?.data?.agreement_inspection?.unit_id, 0, v)
        setSearchText(v)
    }
    const fetchMoreData = () => {
        setOffset(offset + 10);
        getInspectionItems(details?.data?.agreement_inspection?.unit_id, offset + 10, "")
    };
    const SwitchButtons = (val) => {
        switch (val) {
            case "Yet to assign":
                return <Button
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={() => setCancelModal(true)}
                >{"Cancel"}
                </Button>
            case "Assigned":
                return (
                    <>
                        <Button
                            variant="outlined"
                            className={classes.cancelButton}
                            onClick={() => setCancelModal(true)}
                        >{"Cancel"}
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.accoCardButton}
                            onClick={() => openEditModal()}
                        >{"Edit Inspection Items"}
                        </Button>
                    </>
                )
            case "Yet To Verify":
                return (<>
                    <Button
                        variant="outlined"
                        className={classes.cancelButton}
                        onClick={() => setCancelModal(true)}
                    >{"Cancel"}
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.accoCardButton}
                        onClick={() => setIsComplete(true)}>{"Complete Inspection"}
                    </Button>
                </>)
            case "Completed":
                return <Button
                    variant="contained"
                    onClick={downloadPdf}
                    className={classes.accoCardButton}
                >{"Download Inspection"}
                </Button>
            default:
                return null;
        }
    }
    const openEditModal = () => {
        setIsEdit(true)
        const result = details.list?.map((x, i) => {
            return x.expand[0].mapping_id
        })
        const old_items = details.list?.map((x, i) => {
            return ({
                name: x.expand[0].name,
                activity_id: x.expand[0].activity_id,
                mapping_id: x.expand[0].mapping_id,
                is_active: true
            })
        })
        updateState("selectedInspectionItem", result, "old_items", old_items, "new_items", [])
    }
    const getInspectionItems = (unitId, offset = 0, search = "", searchBool = true) => {

        const payload = {
            unit_id: unitId,
            search: search,
            offset: offset,
            limit: 10,
            active: [true]
        }

        NetworkCall(
            `${config.api_url}/inspection_item_mapping/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setLoading(false)
            if (searchBool) {
                if (response?.data?.data?.length > 0) {
                    setData({ ...data, inspectionItemData: response?.data?.data })
                }
                else {
                    setData({ ...data, inspectionItemData: response?.data?.data })
                }
            }
            else {
                setData({ ...data, inspectionItemData: data?.inspectionItemData.concat(response?.data?.data) })
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }
    const cancelInspection = () => {
        const payload = {
            id: state?.agreement_inspection_id,
            update: {
                status: "Rejected",
            }
        }
        client.mutate({
            mutation: updateAgreementInspections,
            variables: payload
        }).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Inspection Cancelled Successfully",
            });
            setCancelModal(false)
            getInspectionDetails()
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something went wrong",
            });
        })
    }
    //on download pdf
    const downloadPdf = () => {
        if (details?.data?.agreement_inspection?.asset_url) {
            saveAs(details?.data?.agreement_inspection?.asset_url, `${details?.data?.agreement_inspection?.reference_id}.pdf`)
        }
    }
    const onUpdateNotes = (val) => {
        const payload = {
            id: state?.agreement_inspection_id,
            update: {
                inspection_manager_notes: [...notesData?.managerNotes, { notes: val }],
            }
        }
        client.mutate({
            mutation: updateAgreementInspections,
            variables: payload
        }).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Notes Added Successfully",
            });
            setCancelModal(false)
            getInspectionDetails()
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something went wrong",
            });
        })
    }
    const onCloseEditModal = () => {
        setIsEdit(false)
        setSearchText("")
    }
    const constructPayload = (new_items = [], old_items = []) => {

        const construct_mapping_ids = new_items?.map((val) => {
            return {
                inspection_item_map: val,
                is_active: true,
                agreement_inspection: details?.data?.agreement_inspection?.id
            }
        })

        const constructed_exisit_item = old_items?.map((val) => {
            return {
                inspection_item_map: val?.mapping_id,
                id: val?.activity_id,
                is_active: val?.is_active,
                agreement_inspection: details?.data?.agreement_inspection?.id

            }
        })

        return [...construct_mapping_ids, ...constructed_exisit_item]



    }
    const onUpdateInspectionItems = (new_items, old_items) => {

        const payload = {
            "activity_items": constructPayload(new_items, old_items)

        }
        NetworkCall(
            `${config.api_url}/inspection_activity/upsert`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Inspection Item Updated Successfully",
            });
            setIsEdit(false)
            getInspectionDetails()
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something went wrong",
            });
        })
    }
    return (
        <Box>
            <Subheader
                title={state?.reference_id}
                goBack={goBack}
            />
            {
                loading ?
                    <LoadingSection top="20vh" message={"Fetching Details"} />
                    :
                    <Box p={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Box className={classes.rootblock} style={{ height: size?.height - 150 }}>
                                    {details?.data?.maintenance?.maintenance_request_no &&
                                        <Box>
                                            <RequestCard
                                                title="Service Inspection Details"
                                                subTitle={details?.data?.maintenance?.maintenance_request_no}
                                                subTitle1={details?.data?.maintenance?.subject}
                                                backgroundColor="#EEF9EE"
                                                icon={<ServiceInspectionIcon />}
                                                maintenanceData={details?.data?.maintenance}
                                            />
                                        </Box>
                                    }
                                    {/* <Box mt={1}>
                                <RequestCard
                                    title="Site Visit Request"
                                    subTitle="Suresh Kumar K"
                                    subTitle1="T.Nagar, Chennai, Tamilnadu"
                                    backgroundColor="#EDE4FE80"
                                    icon={<SiteVisitIcon />}
                                />
                            </Box>
                            <Box mt={1}>
                                <RequestCard
                                    title="PARKING UNIT INSPECTION REQUEST"
                                    subTitle="Parking Slot Number"
                                    subTitle1="Parking Slot Number"
                                    backgroundColor="#DBF0F180"
                                    icon={<ParkingSlotIcon />}
                                />
                            </Box> */}
                                    {details?.data?.inspection_detail &&
                                        <Box mt={1.5}>
                                            <RequestCard
                                                title="Inspection Detail"
                                                subTitle={details?.data?.inspection_detail?.reference_id}
                                                subTitle1={`Inspection on ${moment(details?.data.inspection_detail?.created_at).format("DD MMM YY")}`}
                                                icon={<InspectionIcon />}
                                                status={details?.data?.agreement_inspection?.status}
                                                unit_details={details?.data?.unit_details}
                                            />
                                        </Box>
                                    }
                                    {details?.data?.resource_details?.length > 0 ?
                                        <Box mt={1.5}>
                                            <RequestCard
                                                title="Resource Details"
                                                subTitle={details?.data?.resource_details?.[details?.data?.resource_details?.length-1]?.name}
                                                subTitle1={`${details?.data?.resource_details?.[details?.data?.resource_details?.length-1]?.mobile_no_country_code} ${details?.data?.resource_details?.[details?.data?.resource_details?.length-1]?.mobile_no}`}
                                                image={true}
                                                date={details?.data?.inspection_detail?.created_at}
                                                AllResources={details?.data?.resource_details}
                                                agreement_inspection_id={details?.data?.agreement_inspection?.id}
                                                reAssignModal={reAssignModal}
                                                setReAssignModal={setReAssignModal}
                                                reAssign={reAssign}
                                                status={state?.status}

                                            />
                                        </Box>
                                        :
                                        <Box mt={1.5}>
                                            <RequestCard
                                                assign={true}
                                                icon={<AssignIcon />}
                                                openAssignModal={openAssignModal}
                                            />
                                        </Box>
                                    }
                                    {details?.data?.track?.length > 0 &&
                                        <Box mt={2}>
                                            <RequestCard
                                                title="Track Update"
                                                subTitle="Inspection requested"
                                                subTitle1="by Bala Ganesh, 22 Jul 22"
                                                icon={<CircleIcon />}
                                            />
                                        </Box>
                                    }

                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <Box className={classes.rootblock} style={{ height: size?.height - 150, overflow: "auto" }}>
                                    <Stack direction={"row"} justifyContent={"space-between"}>
                                        <Typography className={classes.hdrtitle}>{"Inspection Details"}</Typography>
                                        <Box>{SwitchButtons(details?.data?.agreement_inspection?.status)}</Box>
                                    </Stack>
                                    <Box>
                                        <AccordianWithDetails generalInspection={true} data={details?.list} notesData={notesData} setNotesData={setNotesData} onUpdateNotes={onUpdateNotes} status={details?.data?.agreement_inspection?.status} />
                                    </Box>
                                </Box>

                            </Grid>
                        </Grid>
                    </Box>
            }
            <DialogBox open={assignModal}
                onClose={() => setAssignModal(false)}
                header={dialogHeader()}
                maxWidth="md"
                fullScreen
                component={
                    <AssignModal
                        agreement_inspection_id={details?.data?.agreement_inspection?.id}
                        // reassign={true}
                        moduleName={"Service Inspection"}
                        // handleClose={handleClose}
                        buttonName={"Assign"}
                        onSubmit={handleAssign}
                    />}

            />
            <AlertDialog open={isComplete}
                onClose={() => setIsComplete(false)}
                header={"Complete Inspection"}
                isNormal
                component={
                    // <CompleteInspection />
                    <Box>
                        <center>
                            <Box className={classes.completeInsp}>
                                <Typography className={classes.msg}>Are you Sure ,You want to Complete ?</Typography>
                                <Grid container p={1} mt={1} spacing={1}>
                                    <Grid item xs={6}>
                                        <Button fullWidth color="error" className={classes.cancelbtn} onClick={() => setIsComplete(false)} variant="contained">No</Button>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth sx={{ color: "white" }} className={classes.successbtn} color="success" variant="contained" onClick={completeInspection}>Yes</Button>

                                    </Grid>
                                </Grid>


                            </Box>
                        </center>
                    </Box>
                }

            />
            <AlertDialog
                open={isEdit}
                header="Edit Inspection Items"
                isNormal
                onClose={() => onCloseEditModal()}
                component={
                    <EditInspectionItems data={data} list={data?.inspectionItemData} selectedInspectionItem={data?.selectedInspectionItem} updateState={updateState} handleSearch={handleSearch} searchText={searchText} fetchMoreData={fetchMoreData} onUpdateItem={onUpdateInspectionItems} />
                }
            />
            <AlertDialog open={cancelModal}
                onClose={() => setCancelModal(false)}
                header={"Cancel Inspection"}
                isNormal
                component={
                    // <CompleteInspection />
                    <Box>
                        <center>
                            <Box className={classes.completeInsp}>
                                <Typography className={classes.msg}>Are you Sure ,You want to Cancel ?</Typography>
                                <Grid container p={1} mt={1} spacing={1}>
                                    <Grid item xs={6}>
                                        <Button fullWidth color="error" className={classes.cancelbtn} onClick={() => setCancelModal(false)} variant="contained">No</Button>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth sx={{ color: "white" }} className={classes.successbtn} color="success" variant="contained" onClick={cancelInspection}>Yes</Button>

                                    </Grid>
                                </Grid>


                            </Box>
                        </center>
                    </Box>
                }

            />
        </Box>
    )
}
const props = {
    boxShadow: false,
};
export default withNavBars(ViewServiceInspection, props)