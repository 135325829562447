import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { useStyleTimeLine } from "../styles";
import { TextBox } from "../../../components";
import {
  AlertProps,
  NetWorkCallMethods,
} from "../../../utils";
import { getNextstatus } from "../../../utils/agreementUtils";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";

export const Form = ({
  image = "",
  title = "",
  descrition = "",
  t,
  onChange = () => false,
  remarks = "",
  btnText = {},
  no=()=>false,
  company = "",
  data = {},
  type = "",
  details = {},
  isType = false,
  datas={},
  isUnderOnboard = false,
  reload=()=>false
}) => {
  const classes = useStyleTimeLine();
  const alert = React.useContext(AlertContext);
  // skip process
    const updateStatus = () => {
      const variables = {
        agreement_id: details?.id,
        remarks: remarks,
        assigned_id: datas?.id ?? data?.assignee?.id,
        status:isType ? data?.status :  getNextstatus(data?.status),
        type:"Approved"
      }; 

      if(getNextstatus(data?.status) === "Onboarding"){
        variables["is_skip"] = true
      }
      NetworkCall(
        `${config.api_url}/agreement_tracker`,
        NetWorkCallMethods.post,
        variables,
        null,
        true,
        false
      )
        .then((res) => {
          no()
          reload()
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Log Created Successfully",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
      // decline
      // const decline= () => {
      //   const variables = {
      //     agreement_id: details?.id,
      //     remarks: remarks,
      //     assigned_id: datas?.id ?? data?.assignee?.id,
      //     status: isType ? data?.status : getNextstatus(data?.status),
      //   };  
      //   NetworkCall(
      //     `${config.api_url}/agreement_tracker`,
      //     NetWorkCallMethods.post,
      //     variables,
      //     null,
      //     true,
      //     false
      //   )
      //     .then((res) => {
      //       no()
      //       reload()
      //       alert.setSnack({
      //         ...alert,
      //         open: true,
      //         severity: AlertProps.severity.success,
      //         msg: "Log Created Successfully",
      //       });
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      // };
  return (
    <Box p={2}>
      <Box display="flex" alignItems={"center"}>
        <Box>{image ?? ""}</Box>
        <Box width={"16px"} />
        <Box>
          <Typography className={classes.skipFormTitle}>{title}</Typography>
          <Box height={"4px"} />
          <Typography className={classes.skipFormsubTitle}>
            {descrition}
          </Typography>
        </Box>
      </Box>
      <Box height={"12px"} />
      <TextBox
        label={t("remarks")}
        multiline
        value={remarks}
        onChange={(e) => onChange(e.target.value)}
        placeholder={t("Type_here")}
      />
      <Box height="24px" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            className={classes.No}
            variant="outlined"
              onClick={no}
            fullWidth
          >
            {btnText?.btn1}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.Yes}
            variant="contained"
              onClick={updateStatus}
            fullWidth
            disabled={remarks?.length > 0 ? false : true}
          >
            {btnText?.btn2}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
