import gql from "graphql-tag";
export const GET_SHORTLIST_UNIT = gql`

query {
  unitCount: unit(where: { is_active: true, id: { in: $unit_id } }) {
    count_id
  }
  unit(where: { is_active: true, id: { in: $unit_id } }) {
    id
    name
    total_baths
    total_rooms
    total_area
    year_built
    total_bed_rooms
    room_rent: lease_or_sale_value
    carpet_area
    unit_no
    room_rent
    currency_id
    url:logo
    type:unit_purpose
    property_id
    property{
      id
      name
    }
    currencyByID {
      id
      symbol
    }
    unit_type{
      id
      name
    }
    area_metric
    furnishing
    description
    payment_period
    unit_category
    company_id
    company_masterByID {
      id
    }
  }
}





`