import { Box, Typography } from "@mui/material";
import { useStyles } from "./style";

export const CardDetail = ({ data }) => {

    const classes = useStyles();

    return <div className={classes.root}>
        <div className={classes.row}>
            <Typography className={classes.count}>{data?.count}</Typography>
            {data?.icon()}
        </div>
        <Box height={"6px"} />
        <div className={classes.row}>
            <Typography className={classes.label}>{data?.label}</Typography>
        </div>
    </div>
}