import { config } from "../../config";
import jwt_decode from "jwt-decode";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";

export const silentOTP = (login, alert, history, isIamValideToLogin, setDisable) => {
    let data = {
        password: login.password
    };
    if (isIamValideToLogin()) {
        // eslint-disable-next-line
        var isphone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(login.email);
        // eslint-disable-next-line
        var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            login.email
        );
        if (isphone) {
            data["mobile_no"] = login.email;
        } else if (email) {
            data["email_id"] = login.email;
        } else {
            data["ucid"] = login.email;
        }
        NetworkCall(
            `${config.authapi}/auth/login`,
            NetWorkCallMethods.post, data, null, false, false
        ).then((res) => {
            localStorage.setItem(
                LocalStorageKeys.authToken,
                res?.data?.token
            )
            const token = jwt_decode(res?.data?.token)
            const payload = {
                otp: "0000",
            };
            // eslint-disable-next-line
            var isphone_1 = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(token?.email_id);
            // eslint-disable-next-line
            var email_1 = /^\w+([\.-]?\ w+)*@\w+([\.-]?\ w+)*(\.\w{2,3})+$/.test(token?.email_id);
            if (isphone_1) {
                payload["mobile_no"] = token?.email_id;
            } else if (email_1) {
                payload["email_id"] = token?.email_id;
            } else {
                payload["ucid"] = token?.email_id;
            }
            NetworkCall(
                `${config.authapi}/auth/verifyOTP/?token=${res?.data?.token}`,
                NetWorkCallMethods.post, payload, null, false, false
            ).then((response) => {
                localStorage.setItem(
                    LocalStorageKeys.authToken,
                    response?.data?.token
                )
                history.push({
                    pathname: Routes.companyselect,
                    state: {
                        main: {
                            clientData: response?.data ?? "",
                        },
                    },
                });
                return false
            }).catch((err) => {
                localStorage.clear();
                history.push(Routes.login)
                alert.setSnack({
                    ...alert, open: true, msg: " Invalid OTP",
                    severity: AlertProps.severity.error,
                });
                setDisable(false)
            });
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: "Invalid Credientials",
                severity: AlertProps.severity.error,
            });
            setDisable(false)
        });
    } else {
        setDisable(false)
        return false;
    }
};