import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import * as React from "react";
import { SemiBold } from "../utils";

const useStyles = makeStyles((theme) => ({
    root: {
        fill: (props) => props?.fill ?? "#c1c1c1",
        "&:hover": {
            fill: (props) => props?.hoverFill ?? "#c1c1c1",
        },
    },
}))

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        borderRadius: "4px",
        padding: "12px",
        backgroundColor: "#FFFFFF",
        color: "#c1c1c1",
        border: "1px solid #E4E8EE",
        boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.04)",
        fontSize: "14px",
        fontFamily: SemiBold,
    },
}));

const InfoIcon = (props) => {
    const { info = "" } = props
    const classes = useStyles(props);

    return <LightTooltip title={info} placement="top">
        <svg
            className={classes.root}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={20}
            height={20}
            color="#c1c1c1"
            {...props}>
            <path
                d="M12 2C6.489 2 2 6.489 2 12s4.489 10 10 10 10-4.489 10-10S17.511 2 12 2zm0 2c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8zm-1 3v2h2V7h-2zm0 4v6h2v-6h-2z" />
        </svg>
    </LightTooltip>
}

export default InfoIcon
