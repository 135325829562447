import React, { useState, useContext, useRef, useEffect } from "react";
import {
  SearchFilter,
  TableWithPagination,
  Subheader,
  DialogBox,
  FilterGenerator,
  UseDebounce,
} from "../../components";
import { useStyles } from "./style";
import { Grid, Box, Stack, IconButton, Button } from "@mui/material";
import { Tab } from "./tab";
import {
  Announcement_Type,
  Announcement_Heading,
  Announcement_Path,
  getCompanyOption,
  getRoutePermission,
  accessCheckRender,
  NetWorkCallMethods,
  AlertProps,
} from "../../utils";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { ViewAnnounsment } from "./viewAnnounsment";
import { AuthContext, BackdropContext, AlertContext } from "../../contexts";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { enumSelect, enum_types ,convertTimeUtcToZoneCalander} from "../../utils";

const AnnounsmentNew = ({ t }) => {
  // useRef
  const permission = useRef([]);
  // classes
  const classes = useStyles();
  // history
  const history = useHistory();
  //debounce hook
  const debounce = UseDebounce();
  //   context
  const auth = useContext(AuthContext);
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);

  // ------------------------STATE-----------------------------------
  const [company, setCompany] = useState({
    companyList: [],
    selectedCompany: {},
    searchText: "",
    drawer: false,
  });
  const [details, setDetails] = useState({
    details: {},
    bool: false,
    property_ids: [],
    builds: [],
  });
  const [list, setList] = useState({
    tabValue: 0,
  });
  const [filterData, setFilterData] = useState({
    property: [],
    build: [],
  });
  const [listData, setListData] = useState({
    data: [],
    count: 0,
  });
  const [draftCount, setDraftCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [buildList, setBuildList] = useState([]);
  const [enumValue, setEnumValue] = useState({
    announcement_type: [],
  });

  //--------------------------FUNCTION-------------------------------
  const updateState = (k, v) => {
    setCompany({ ...company, [k]: v });
  };
  //tab change function
  const onClickTab = (value) => {
    setList({ ...list, tabValue: value });
    getList(
      company?.selectedCompany?.value,
      company?.searchText ?? "",
      0,
      10,
      value,
      filterData?.property,
      filterData?.build,
      enumValue?.announcement_type?.map((x) => x?.value)
    );
  };
  //   search function
  const searchFunction = (e) => {
    updateState("searchText", e);
    debounce(
      () =>
        getList(
          company?.selectedCompany?.value,
          e ?? "",
          0,
          10,
          list?.tabValue,
          filterData?.property,
          filterData?.build,
          enumValue?.announcement_type?.map((x) => x?.value)
        ),
      800
    );
  };
  // router change to create announsment
  const createAnnounsment = () => {
    history.push({
      pathname: Routes.createannounsment,
      state: {
        main: {
          company: company?.selectedCompany,
        },
      },
    });
  };
  // get notification list
  const getList = (
    company,
    search,
    offset,
    limit,
    tabValue,
    property_ids,
    build_ids,
    contentType
  ) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const requestPayload = {
      company_id: [company],
      type: ["Announcement"],
      is_draft: tabValue === 0 ? false : true,
      content_type: contentType ?? [],
      query: search ?? "",
      offset: offset,
      limit: limit,
      to_builds:
        build_ids?.length > 0 ? build_ids?.map((x) => x?.value) : undefined,
      property_id:
        property_ids?.length > 0
          ? property_ids?.map((x) => x?.value)
          : undefined,
    };
    NetworkCall(
      `${config.api_url}/notifications/list`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((res) => {
        const result = res?.data?.data?.response?.map((x) => {
          return {
            category: x?.category ?? "-",
            announcement_title: x?.title ?? "-",
            properties: x?.no_of_properties ?? "-",
            apps: x?.no_of_builds ?? "-",
            attachments: x?.description?.[1]?.attachment?.length ?? "-",
            published_by: (x?.first_name || x?.last_name) ? `${x?.first_name ?? ""} ${x?.last_name ?? ""}` : `-`,
            published_date: x?.triggered_at ? `${convertTimeUtcToZoneCalander(x?.triggered_at)}` : "-",
            created_by: (x?.created_by_first_name || x?.created_by_last_name) ?
              `${x?.created_by_first_name ?? ""} ${x?.created_by_last_name ?? ""}` : `-`,
            created_date: x?.created_at ? `${convertTimeUtcToZoneCalander(x?.created_at)}` : "-",
            id: x?.id,
            to_builds: x?.to_builds ?? [],
            property_ids: x?.property_ids ?? [],
            is_all_property: x?.is_all_property,
            build: x?.build ?? [],
          };
        });
        setListData({
          data: result ?? [],
          count: res?.data?.data?.totalRecords ?? 0,
        });
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong. Please try again",
        });
      });
  };
  // get notification list
  const getListForDraft = (company, search, offset, limit, contentType) => {
    const requestPayload = {
      company_id: [company],
      type: ["Announcement"],
      is_draft: true,
      content_type: contentType ?? [],
      query: search ?? "",
      offset: offset,
      limit: limit,
    };
    NetworkCall(
      `${config.api_url}/notifications/list`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((res) => {
        setDraftCount(res?.data?.data?.totalRecords);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong. Please try again",
        });
      });
  };
  //   get permission function
  const getPermission = async () => {
    const tempPermission = await getRoutePermission();
    permission.current = tempPermission;
    if (tempPermission?.read) {
      let company = getCompanyOption(backdrop, auth, alert);
      if (company) {
        getEnum(company?.selected?.value);
        getBuilds();
        setCompany({
          ...company,
          companyList: company?.list,
          selectedCompany: company?.selected,
        });
      }
    }
  };
  // pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getList(
      company?.selectedCompany?.value,
      "",
      offset,
      limit,
      list?.tabValue,
      filterData?.property,
      filterData?.build,
      enumValue?.announcement_type?.map((x) => x?.value)
    );
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getList(
      company?.selectedCompany?.value,
      "",
      0,
      value,
      list?.tabValue,
      filterData?.property,
      filterData?.build,
      enumValue?.announcement_type?.map((x) => x?.value)
    );
  };
  // get builds list
  const getBuilds = (search, offset, filter) => {
    NetworkCall(
      `${config.api_url}/app_build`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((res) => {
        const listData = res?.data?.data?.map((x) => {
          return {
            label: x?.build_name,
            value: x?.build_code,
          };
        });
        setBuildList(listData);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong. Please try again",
        });
      });
  };
  const handleIcon = (key, v) => {
    if (key === "edit") {
      history.push({
        pathname: "/createannounsment",
        state: {
          main: {
            id: v?.id,
            isEdit: true,
            property_ids: v?.is_all_property ? [] : v?.property_ids,
            build_ids: v?.to_builds,
            is_all_property: v?.is_all_property,
            company: company?.selectedCompany,
          },
        },
      });
    }
    if (key === "view") {
      getDetails(v?.id);
    }
  };
  const detailsUp = () => {
    setDetails({
      ...details,
      details: {},
      bool: !details?.bool,
      property_ids: [],
      builds: [],
    });
  };
  // onApplyFilter
  const onApplyFilter = (value) => {
    setFilterData(value);
    getList(
      company?.selectedCompany?.value,
      "",
      0,
      10,
      list?.tabValue,
      value?.property,
      value?.build,
      enumValue?.announcement_type?.map((x) => x?.value)
    );
  };
  // edit function
  const getDetails = (id) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const requestPayload = {
      id: id ?? "",
      user_profile_id: localStorage.getItem("profileID"),
      company_id: company?.selectedCompany?.value,

    };
    NetworkCall(
      `${config.api_url}/notifications/view`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((res) => {
        setDetails({
          ...details,
          details: res?.data?.data?.notification_details,
          bool: true,
          property_ids: [],
          builds: res?.data?.data?.buildName ?? [],
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong. Please try again",
        });
      });
  };
  //get Enum
  const getEnum = async (company) => {
    const result = await enumSelect([enum_types.notification_category_type]);
    setEnumValue({
      announcement_type: result?.notification_category_type,
    });
    getList(
      company,
      "",
      0,
      10,
      list?.tabValue,
      [],
      [],
      result?.notification_category_type?.map((x) => x?.value) ?? []
    );
    getListForDraft(
      company,
      "",
      0,
      10,
      [],
      [],
      result?.notification_category_type?.map((x) => x?.value) ?? []
    );
  };
  useEffect(() => {
    getPermission();
    // eslint-disable-next-line
  }, [auth]);

  const render = () => {
    return (
      <div>
        {/* sub nav bar */}
        <Subheader
          hideBackButton={true}
          title={t("announcement")}
          select
          options={company?.companyList ?? []}
          value={company?.selectedCompany}
          onchange={(e) => {
            updateState("selectedCompany", e);
            getListForDraft(
              e?.value,
              "",
              0,
              10,
              list?.tabValue,
              filterData?.property,
              filterData?.build
            );
            getList(
              e?.value,
              "",
              0,
              10,
              list?.tabValue,
              filterData?.property,
              filterData?.build
            );
            setFilterData({
              property: [],
              build: [],
            });
          }}
        />
        <div className={classes.root}>
          {/* tab */}
          <Tab
            onClickTab={onClickTab}
            tabValue={list?.tabValue ?? 0}
            t={t}
            count={draftCount ?? 0}
          />
          <Box p={3}>
            <Grid container className={classes.content} spacing={2}>
              <Grid item xs={4} className={classes.PaddingTop}>
                <Box>
                  {/* search box */}
                  <SearchFilter
                    value={company?.searchText}
                    placeholder={t("Search")}
                    handleChange={(value) => searchFunction(value)}
                    marginBottom="0px !important"
                  />
                </Box>
              </Grid>

              <Grid item xs={8} className={classes.PaddingTop}>
                <Box
                  display={"flex"}
                  alignItems="center"
                  sx={{ float: "right" }}
                >
                  <Stack
                    direction="row"
                    spacing={0.6}
                    display={"flex"}
                    alignItems="center"
                  >
                    <Box>
                      <IconButton
                        size="small"
                        className={classes.img}
                        onClick={() => updateState("drawer", true)}
                      >
                        <img src="/images/filter.svg" alt="filter" />
                      </IconButton>
                    </Box>
                    {permission?.current?.read && (
                      <Box>
                        <Button
                          variant="contained"
                          className={classes.next}
                          onClick={createAnnounsment}
                        >
                          {t("create")}
                        </Button>
                      </Box>
                    )}
                  </Stack>
                  <Box width={"15px"} />
                </Box>
              </Grid>

              {/* Main tableData */}
              <Grid item xs={12} className={classes.noPadding}>
                <TableWithPagination
                  heading={Announcement_Heading(t, list?.tabValue === 0 ? "publish" : "draft")}
                  rows={listData?.data ?? []}
                  path={Announcement_Path(list?.tabValue === 0 ? "publish" : "draft")}
                  showpagination={true}
                  showpdfbtn={false}
                  showexcelbtn={false}
                  totalRowsCount={listData?.count}
                  page={page ?? 1}
                  limit={limit ?? 10}
                  tableType="no-side"
                  dataType={Announcement_Type(
                    permission?.current?.update ? (list?.tabValue === 0 ? false : true) : false,
                    list?.tabValue === 0 ? "publish" : "draft"
                  )}
                  height={"calc(100vh - 370px)"}
                  view={permission?.current?.read}
                  handleIcon={handleIcon}
                  handlePagination={handlePagination}
                  handleChangeLimit={handleChangeLimit}
                />
              </Grid>
              {/* <Grid item xs={12}>

              </Grid> */}
              <DialogBox
                id={"id_123"}
                open={details?.bool}
                onClose={() => detailsUp(false)}
                handleClose={() => detailsUp(false)}
                header={`View Announcement`}
                isNormal
                width={"710px !important"}
                maxWidth={"md"}
                component={
                  <Box>
                    <ViewAnnounsment
                      t={t}
                      details={details?.details}
                      property={details?.property_ids ?? []}
                      company={company?.selectedCompany}
                      builds={details?.builds ?? []}
                    />
                  </Box>
                }
              />

              {/* end Main tableData */}
            </Grid>
          </Box>
        </div>
        {/* filter */}
        {company?.drawer && (
          <FilterGenerator
            open={company?.drawer}
            onClose={() => updateState("drawer", false)}
            components={[
              {
                component: "select",
                value: filterData?.property,
                options:
                  company?.selectedCompany?.property?.map((x) => {
                    return {
                      label: x?.name,
                      value: x?.id,
                    };
                  }) ?? [],
                isMulti: true,
                label: "Properties",
                state_name: "property",
              },
              {
                component: "select",
                value: filterData?.build,
                options: buildList ?? [],
                isMulti: true,
                label: "Build",
                state_name: "build",
              },
            ]}
            onApply={(value) => onApplyFilter(value)}
          />
        )}
      </div>
    );
  };
  return <>{accessCheckRender(render, permission?.current)}</>;
};
export default withNamespaces("announsment")(AnnounsmentNew);
