import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, SemiBold } from "../../../utils";
export const componantsStyles = makeStyles((theme) => ({
    cardRoot: {
        backgroundColor: "#5078E1",
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
        backgroundImage: `url("/images/quotationversionBg.svg")`
    },
    quottNo: {
        color: "white",
        fontFamily: Bold,
        fontSize: "16px"
    },
    quottCardsub: {
        color: "white",
        fontSize: "10px",
        marginTop: "4px"
    },
    active: {
        padding: "2px 6px",
        backgroundColor: "#5AC782",
        borderRadius: theme.palette.borderRadius,
        color: "white",
        fontSize: "12px",
        marginLeft: "8px"
    },
    arrowIcon: {
        backgroundColor: "white"
    },
    head: {
        fontSize: "10px",
        fontFamily: SemiBold,
        color: "#98A0AC"
    },
    date: {
        fontSize: "10px",
        fontFamily: SemiBold,
        color: "#98A0AC"
    },
    sub: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: "#091B29"
    },
    detailRoot: {
        borderBottom: "1px solid #"
    },
    titleText: {
        fontSize: "18px",
        fontFamily: ExtraBold,
    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        marginBottom: "12px",
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
        },
    },
    unitLeaddetails: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE"
    },
    rootcard: {
        cursor: "pointer",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    periodStyle: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    name: {
        fontSize: "14px",
        fontFamily: Bold,
    },
    call: {
        fontSize: "10px",
        color: theme.typography.color.secondary,
    },
    mail: {
        fontSize: "10px",
        color: theme.typography.color.secondary,
    },
    img: {
        objectFit: "cover",
        height: "54px",
        width: "54px",
        borderRadius: "10px"
    },
    unitImg: {
        borderRadius: "4px",
        padding: "16px 12px 12px 12px",
        backgroundColor: "#F1F7FF",
        width: "54px",
    },
    tagStyle: {
        backgroundColor: "#5078E11E",
        borderRadius: "5px",
        padding: "3px 10px 3px 10px",
        fontSize: "10px",
        fontFamily: Bold,
        color: theme.typography.color.primary,
        marginLeft: "8px"
    },
    leadContainer: {
        padding: "4px 4px 2px 4px"
    },
    btn: {
        margin: "16px 0px 8px 0px",
        padding: "8px",
        boxShadow: "none",
        borderRadius: theme.palette.borderRadius
    },
    border: {
        height: "4px",
        backgroundColor: "#E4E8EE"
    },
    formHead: {
        color: '#4E5A6B',
        fontFamily: Bold,
        fontSize: "14px",
        marginBottom: "12px"
    },
    add: {
        color: '#5078E1',
        fontFamily: Bold,
        fontSize: "12px",
        cursor: "pointer"
    },
    pin: {
        backgroundColor: "#EEF9EE",
        padding: "12px",
        borderRadius: theme.palette.borderRadius
    },
    fileRoot: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        padding: "8px"
    },
    fileName: {
        color: '#4E5A6B',
        fontFamily: Bold,
        fontSize: "12px",
        textTransform: "capitalize"
    },
    fileSize: {
        color: '#98A0AC',
        fontSize: "12px",
        textTransform: "capitalize"
    },
    title: {
        color: '#4E5A6B',
        fontFamily: Bold,
        fontSize: "10px",
        marginBottom: "8px"
    },
    title1: {
        color: '#4E5A6B',
        fontFamily: Bold,
        fontSize: "14px",
        marginBottom: "8px"
    },

    accountName: {
        color: '#091B29',
        fontFamily: Bold,
        fontSize: "14px",
    },
    accountNamelist: {
        color: '#091B29',
        fontFamily: SemiBold,
        fontSize: "14px",
    },
    accountNo: {
        color: "#4E5A6B",
        fontSize: "12px",
    },
    accountRoot: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
    },
    accountDropdownRoot: {
        borderTop: "2px solid #E4E8EE",

    },
    accountListRoot: {
        height: "200px",
        overflow: "auto"
    },
    AccountImg: {
        padding: "12px 14px 10px 14px",
        borderRadius: theme.palette.borderRadius
    },
    AccountImg1: {
        borderRadius: theme.palette.borderRadius,
        padding: "12px 14px 10px 14px",
        backgroundColor: "#F2F4F7",
    },
    profileimg: {
        backgroundColor: theme.palette.background.tertiary,
        color: "#E4E8EE",
        position: "relative",
    },
    profileicon: {
        fontSize: "80px",
        padding: "14px",
    },
    profileimgs: {
        backgroundColor: theme.palette.background.tertiary,
        color: "#E4E8EE",
        position: "absolute",
        bottom: "0px",
        right: "0px",
        border: "2px solid white",
        borderRadius: "50%",
    },

    profilesection: {
        marginTop: "14px",

    },
    imgDiv: {
        position: "relative",
        width: "90px"
    },
    close: {
        position: "absolute",
        top: "0px",
        right: "0px",
        backgroundColor: "red",
        "&:hover": {
            backgroundColor: "red",
        },
    },
    new: {
        backgroundColor: "#EEF9EE",
        borderRadius: "4px",
        color: "#5AC782",
        fontSize: "10px",
        fontFamily: Bold,
        display: "inline",
        padding: "4px 8px 0px 8px",
        marginLeft: "8px"
    },
    prospect: {
        backgroundColor: "#E1E8F8",
        borderRadius: "4px",
        color: "#091B29",
        fontSize: "10px",
        fontFamily: Bold,
        display: "inline",
        padding: "4px 8px 0px 8px",
        marginLeft: "8px"
    },
    alert: {
        padding: "12px",
        backgroundColor: theme.palette.error.light,
        borderRadius: "4px",
        marginBottom: "8px"
    },
    alertMsg: {
        fontSize: "12px",
        fontFamily: SemiBold,
        color: theme.palette.error.main,
        padding: "0px 20px 0px 0px",

    },
    sendLink: {
        color: '#98A0AC',
        fontSize: "14px",
        textAlign: "center",
        marginBottom: "18px",
        marginTop: "12px",
        padding: "0px 20px"
    },
    reservationCard: {
        padding: "12px",
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        cursor: "pointer"
    },
    paid: {
        fontSize: "12px",
        color: "#5AC782"
    },
    is_active: {
        padding: "2px 6px",
        backgroundColor: "#EEF9EE",
        borderRadius: theme.palette.borderRadius,
        color: "#5AC782",
        fontSize: "10px",
        marginLeft: "8px",
        fontFamily: Bold
    },
    in_active: {
        padding: "2px 6px",
        backgroundColor: "#FFECEC",
        borderRadius: theme.palette.borderRadius,
        color: "#FF4B4B",
        fontSize: "10px",
        marginLeft: "8px",
        fontFamily: Bold
    },
    cancelReserveBtn: {
        border: "1px solid #E4E8EE",
        padding: "8px 12px",
        "&:hover": {
            backgroundColor: "white"
        },
        backgroundColor: "white",
        color: "#FF4B4B",
        fontFamily: SemiBold
    },
    paymentLinkBtn: {
        border: "1px solid #E4E8EE",
        padding: "8px 12px",
        "&:hover": {
            backgroundColor: "white"
        },
        backgroundColor: "white",
        // color: "#FF4B4B",
        fontFamily: SemiBold
    }
}));