import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../../utils";
export const useStyles = makeStyles((theme) => ({
    box: {
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px",
        height: 'calc(100vh - 160px)',
        overflow: "auto",
        backgroundColor: "white"
    },
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        width: '100%',
        padding: theme.spacing(1),
        height: 'calc(100vh - 280px)',
        overflow: 'auto',
    },
    label: {
        fontFamily: SemiBold,
        fontSize: 14,
        color: theme.typography.color.tertiary,
        marginBottom: theme.spacing(1)
    }

}))