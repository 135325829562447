import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Bold } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    textTransform: "capitalize",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius

  },
  roots: {
    // borderBottom: "1px solid #E4E8EE",

    padding: "4px",
    margin: "0px 8px",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  name: {
    fontSize: "14px",
    fontFamily: Bold,
  },
  call: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  mail: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  img: {
    objectFit: "cover",
    height: "100%",
  },
  tagStyle: {
    backgroundColor: "#5078E11E",
    borderRadius: "5px",
    padding: "1px",
    fontSize: "10px",
    fontFamily: Bold,
    color: theme.typography.color.primary,
  },
}));
export const LeadsList = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.roots}>
          <Box display="flex">
            <Box>
              <img
                src={props.data.img}
                alt={props.data.name}
                className={classes.img}
              />
            </Box>
            <Box flexGrow={1} marginTop="10px" marginLeft="8px">
              <Box display="flex" alignItems="center" marginBottom="2px">
                <Typography className={classes.name}>
                  {props.data.name} &nbsp;{" "}
                  <span className={classes.tagStyle}>
                    {" "}
                    &nbsp;&nbsp;{props.data.status} &nbsp;&nbsp;
                  </span>
                </Typography>
              </Box>

              <Box display="flex">
                <Box>
                  <Typography className={classes.call}>
                    {props.data.level}
                  </Typography>
                </Box>
                <Box className={classes.periodStyle} />
                <Box>
                  <Typography className={classes.mail}>
                    {props.data.place}
                  </Typography>
                </Box>
                <Box className={classes.periodStyle} />
                <Box>
                  <Typography className={classes.mail}>
                    {props.data.date}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
