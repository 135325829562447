import { Box, Grid } from "@mui/material"
import React, { useState } from "react"
import { withNamespaces } from "react-i18next"
import { LeadIcon } from "../../assets/lead"
import { DialogBox } from "../../components"
import { CompletedJobDataType, CompletedJobHdr, CompletedJobPath, OngoingJobDataType, OngoingJobHdr, OngoingJobPath, ResourceDataType, ResourceHdr, ResourcePath } from "../../utils"
import { CardDetails } from "./components/card"
import { GraphComponent } from "./components/graphcomponent"
import { DashboardStyles } from "./style"

const DashboardComponent = ({ t, data = {} }) => {
    const classes = DashboardStyles()
    const [timeOffType, setTimeOffType] = React.useState("PTO")
    const [zoom, setZoom] = useState(false);
    const [zoomGraph, setZoomGraph] = useState("");
    // const scaleLine = {
    //     y: {
    //         ticks: {
    //             // Include a dollar sign in the ticks
    //             callback: function (value, index, ticks) {
    //                 return value + "%";
    //             },
    //         },
    //     },
    // };
    // const scale = {
    //     x: {
    //         stacked: true,
    //     },
    //     y: {
    //         stacked: true,
    //     },
    // };

    const utilityData = [
        {
            title: "Current Reading",
            reading: 123,
            image: "/images/propertdashboard/occupied.png"
        },
        {
            title: "Past Reading",
            reading: 123.8,
            image: "/images/propertdashboard/activeunits.png"

        },
    ]
    const otherData = [
        {
            image: "/images/propertdashboard/ParkingIcon.png",
            count: 10,
            title: "Amenity Pass"
        },
        {

            icon: <LeadIcon />,
            count: 4,
            title: "Utility Reading"
        },
        {
            image: "/images/invoice.svg",
            count: 4,
            title: "Utility Invoice"
        },
    ]
    const status = [
        {
            icon: <LeadIcon />,
            count: 10,
            title: "Unassigned"
        },
        {
            image: "/images/propertdashboard/block.svg",
            count: 4,
            title: "Commenced"
        },
        {
            image: "/images/propertdashboard/pausedIcon.png",
            count: 10,
            title: "Paused"
        },
        {
            image: "/images/propertdashboard/managerIcon.png",
            count: 10,
            title: "Manager Review"
        },
        {
            image: "/images/propertdashboard/assigned.svg",
            count: 10,
            title: "Assigned"
        },
        {
            image: "/images/propertdashboard/activeunits.png",
            count: 4,
            title: "Completed"
        },
    ]
    const workOrders = [
        {
            fill: "#F3E137",
            name: "In-progress",
            count: 3
        },
        {
            fill: "#FF9340",
            name: "Completed",
            count: 3
        },
        {
            fill: "#589CE0",
            name: "Open Orders",
            count: 3
        },
    ]
    const jobCompletionHistory = [
        {
            fill: "#F9A666",
            name: "On Time",
            count: 8
        },
        {
            fill: "#F66C02",
            name: "Delayed",
            count: 5
        },
    ]
    const TimeOffData = [
        {
            fill: "#FF4B4B",
            name: "Sick Leave",
            count: 3,
            count1: 8
        },
        {
            fill: "#5AC782",
            name: "Causal Leave",
            count: 4,
            count1: 8
        },
        {
            fill: "#58D0E0",
            name: "Earned Leave",
            count: 3,
            count1: 5
        },
    ]
    const sampleData = [
        {
            name: "Ram",
            job: 1,
            projected_hour: 4,
            actual_hour: 2,
            other_hour: 0,
            on_time: "10%",
            delayed: "0%",
            job_id: 123,
            assigned_date: "2022-11-29",
            completion: 50,
            job_type: "Move In",
            status: "In Progress",
            assigned: 4,
            ongoing: 2,
            completed: 1
        }
    ]
    const hoursData =
    {
        "labels": [
            "Projected",
            "Actual",
            "Others",
        ],
        "datasets": [
            {
                "label": "",
                "data": [15, 30, 10],
                "backgroundColor": ["#274BA9", "#F17360", "#98A0AC"],
                "borderWidth": 1,
                "barThickness": 40
            },
        ]
    }

    // const barChartData = {
    //     "labels": [
    //         "UnAssigned",
    //         "Commenced",
    //         "Paused",
    //         "Manager Review",
    //         "Assigned",
    //         "Completed"
    //     ],
    //     "datasets": [
    //         {
    //             "label": "",
    //             "data": [25, 30, 70, 10, 5, 9],
    //             "backgroundColor": ["#58D0E0", "#5AC782", "#FF9340", "#F3E137", "#5A8DC7", "#37C0F3"],
    //             "borderWidth": 1,
    //             "barThickness": 40
    //         },
    //     ]
    // }
    const lineChartData = {
        "labels": [
            "24 Nov 22",
            "25 Nov 22",
            "26 Nov 22",
            "27 Nov 22"
        ],
        "datasets": [
            {
                label: "ssjsj",
                data: [33, 53, 85, 41, 44, 65],
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            }
        ]
    }
    // zoom function
    const zoomGraphFunction = (e) => {
        setZoomGraph(e);
        setZoom(true);
    };
    const changeTimeOffType = (value) => {
        setTimeOffType(value)
    }
    // close popup
    const handleCloseModule = () => {
        setZoom(false);
    };
    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} lg={1.5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CardDetails data={{
                                icon: <LeadIcon />,
                                count: data?.details?.completed_inspection_orders ?? 0,
                                title: "Open Orders"
                            }} maxHeight={"130px"} />
                        </Grid>
                        <Grid item xs={12}>
                            <CardDetails data={{
                                image: "/images/propertdashboard/activeunits.png",
                                count: data?.details?.inspection_orders ?? 0,
                                title: "Completed"
                            }} maxHeight={"130px"} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8} lg={4}>
                    {/* work Orders */}
                    <GraphComponent
                        title={"Inspection Work Orders"}
                        graphData={workOrders}
                        isPie
                        margin={"20px"}
                        onZoomClick={zoomGraphFunction}
                        isZoom
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={6.5}>
                    {/* work Orders */}
                    <GraphComponent
                        title={"Inspection Job Type"}
                        graphData={data?.details?.inspection_order_graph}
                        innerRadius={60}
                        isPie
                        margin={"20px"}
                        onZoomClick={zoomGraphFunction}
                        isZoom
                    />


                </Grid>
            </Grid>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={4}>
                        {/* work Orders */}
                        <GraphComponent
                            title={"Inspection Job Status"}
                            graphData={data?.inspection_job_status}
                            // graphData={barChartData}
                            margin={"20px"}
                            isBar
                            isUtility={false}
                            isDatalabels={false}
                            isLegend={true}
                            onZoomClick={zoomGraphFunction}
                            isZoom
                        />

                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Grid container spacing={2}>
                            {status?.map((x) => {
                                return (
                                    <Grid item xs={6}>
                                        <CardDetails data={x} maxHeight={"112px"} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={5}>
                        {/* utility readings */}
                        <Box height={"240px"}>
                            <GraphComponent
                                title={"Utility Readings"}
                                graphData={lineChartData}
                                margin={"20px"}
                                isUtility
                                // scale={scaleLine}
                                utilityData={utilityData}
                            // onZoomClick={zoomGraphFunction}
                            // isZoom
                            />
                        </Box>
                        <Grid container spacing={2} mt={0.3}>
                            {otherData?.map((x) => {
                                return (
                                    <Grid item xs={4}>
                                        <CardDetails data={x} maxHeight={"112px"} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} lg={8}>
                        <GraphComponent
                            isTable
                            title={"Completed jobs"}
                            tableData={sampleData}
                            tabelheight={"calc(100vh - 450px)"}
                            heading={CompletedJobHdr}
                            path={CompletedJobPath}
                            dataType={CompletedJobDataType}
                            isSearch={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                        {/* work Orders */}
                        <GraphComponent
                            title={"Projected Vs Actual Vs Others"}
                            graphData={hoursData}
                            margin={"20px"}
                            isBar
                            isDatalabels={false}
                            isLegend={true}
                            onZoomClick={zoomGraphFunction}
                            isZoom
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} lg={8} alignItems={"center"}>
                        <GraphComponent
                            isTable
                            title={"Ram -Ongoing jobs (1)"}
                            tableData={sampleData}
                            tabelheight={"calc(100vh - 450px)"}
                            heading={OngoingJobHdr}
                            isSearch={true}
                            path={OngoingJobPath}
                            dataType={OngoingJobDataType}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4} >
                        {/* work Orders */}
                        <GraphComponent
                            title={"Job Completion History"}
                            graphData={jobCompletionHistory}
                            innerRadius={50}
                            isTotal={true}
                            total={13}
                            isPie
                            margin={"20px"}
                            onZoomClick={zoomGraphFunction}
                            isZoom
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} lg={8} alignItems={"center"}>
                        <GraphComponent
                            isTable
                            title={"Resources Vs Jobs"}
                            tableData={sampleData}
                            tabelheight={"calc(100vh - 450px)"}
                            heading={ResourceHdr}
                            path={ResourcePath}
                            isSearch={true}
                            dataType={ResourceDataType}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4} >
                        {/* Time Off */}
                        <GraphComponent
                            title={"Time off Graph"}
                            graphData={TimeOffData}
                            innerRadius={60}
                            timeOff={true}
                            isTotal={true}
                            total={13}
                            type={timeOffType}
                            onChangeType={changeTimeOffType}
                            isPie
                            margin={"40px"}
                            onZoomClick={zoomGraphFunction}
                            isZoom
                        />
                    </Grid>
                </Grid>
            </Box>
            {/* zoom dialog for each graph */}
            <DialogBox
                isnotTitle
                maxWidth={zoomGraph === "Map View" ? "lg" : "md"}
                handleClose={handleCloseModule}
                open={zoom}
                // height={"600px"}
                borderRadius={"12px"}
                component={
                    <div>
                        {zoomGraph === "Inspection Work Orders" && (
                            <GraphComponent
                            title={"Inspection Work Orders"}
                            graphData={workOrders}
                            isPie
                            margin={"20px"}
                        />
                        )}
                         {zoomGraph === "Inspection Job Type" && (
                           <GraphComponent
                           title={"Inspection Job Type"}
                           graphData={data?.details?.inspection_order_graph}
                           innerRadius={60}
                           isPie
                           margin={"20px"}
                       />
                        )}
                           {zoomGraph === "Inspection Job Status" && (
                            <GraphComponent
                            title={"Inspection Job Status"}
                            graphData={data?.inspection_job_status}
                            // graphData={barChartData}
                            margin={"20px"}
                            isBar
                            isUtility={false}
                            isDatalabels={false}
                            isLegend={true}
                        />
                        )}
                             {zoomGraph === "Projected Vs Actual Vs Others" && (
                             <GraphComponent
                             title={"Projected Vs Actual Vs Others"}
                             graphData={hoursData}
                             margin={"20px"}
                             isBar
                             isDatalabels={false}
                             isLegend={true}
                         />
                        )}
                        {zoomGraph === "Job Completion History" && (
                            <GraphComponent
                            title={"Job Completion History"}
                            graphData={jobCompletionHistory}
                            innerRadius={50}
                            isTotal={true}
                            total={13}
                            isPie
                            margin={"40px"}
                        />
                        )}
                        {zoomGraph === "Time off Graph" && (
                             <GraphComponent
                             title={"Time off Graph"}
                             graphData={TimeOffData}
                             innerRadius={60}
                             timeOff={true}
                             isTotal={true}
                             total={13}
                             type={timeOffType}
                             onChangeType={changeTimeOffType}
                             isPie
                             margin={"40px"}
                         />
                        )}
                    </div>
                }
            />
        </Box>
    )
}
export default withNamespaces("dashboard")(DashboardComponent)