import { useApolloClient } from "@apollo/client";
import { Box, Button, Grid } from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Stepper, Subheader } from "../../components";
import { AuthContext } from "../../contexts";
import { CreateShortListContext } from "../../contexts/shortlistLedContext";
import { GETCOMPANYDETAILS } from "../../graphql/quotationQueries";
import { Routes } from "../../router/routes";
import { accessCheckRender, getRoutePermissionNew } from "../../utils";
import { AddContact, LeadNew, LeadPreview } from "./steps";
import { useStyles } from "./styles";

export const Shortlistedlead = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();
  const { state } = useLocation();
  const auth = React.useContext(AuthContext)
  const [permission, setPermission] = React.useState({})

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        if (state) {
          //get company details
          getCompanyDetails()
        }
        else {
          history.push(Routes.propertyFinder)
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //context state
  const { step, previous, next, setDataNew, dataNew, disable } = React.useContext(CreateShortListContext);
  //new lead
  const stepsNew = [
    {
      label: "Add Prospective",
      step: 1,
      value: step
    },
    {
      label: "Opportunity Details",
      step: 2,
      value: step
    },
    {
      label: "Preview and Create Lead",
      step: 3,
      value: step
    }
  ]
  //exist lead step
  const stepsNewExist = [
    {
      label: "Preview and Create Lead",
      step: 1,
      value: step
    }
  ]
  //get company details
  const getCompanyDetails = () => {
    client.query({
      query: GETCOMPANYDETAILS,
      fetchPolicy: 'network-only',
      variables: {
        id: state?.companyID?.value,
      }
    }).then((res) => {

      if (state?.duration?.lead) {
        setDataNew({
          ...dataNew,
          custom_date: res?.data?.company_master[0]?.billing_day,
          bill_cycle_method:res?.data?.company_master[0]?.billing_cycle,
          bill_cycle_date:res?.data?.company_master[0]?.billing_cycle_date,
          purpose: {
            label: state?.data?.property_purpose,
            value: state?.data?.property_purpose
          },
          revenue_type: {
            label: state?.data?.revenue_type,
            value: state?.data?.revenue_type
          },
          quotation_revenue_based_lease: state?.duration?.lead?.revenue_based_lease,
          type: state?.type,
          lease_start_date: state?.duration?.lead?.lease_start_date,
          lease_end_date: state?.duration?.lead?.lease_end_date,

          quotation_lease_duration: {
            value: state?.duration?.lead?.value,
            select: state?.duration?.lead?.lease_period
          },
          lead_details: state?.duration?.lead,
          units: state?.unit,
          company: state?.companyID?.value,
          searchdata: state?.data,
          lead_id: state?.duration?.lead?.id,
          name: state?.duration?.lead?.contact?.first_name,
          mobile: {
            mobile: state?.duration?.lead?.contact?.mobile_no,
            mobile_code: "",
          },
          priority: state?.duration?.lead?.urgent,
          email_id: state?.duration?.lead?.contact?.email,
          image_url: state?.duration?.lead?.contact?.get_assets_url_id,
          quotation_billing_start_date: new Date(state?.duration?.lead?.billing_start_date),
          quotation_payment_peroid: state?.data?.revenue_type === "Sale" ? "" : {
            value: state?.duration?.lead?.payment_period,
            label: state?.duration?.lead?.payment_period
          },
          quotation_payment_option: {
            value: state?.duration?.lead?.payment_mode,
            label: state?.duration?.lead?.payment_mode
          },
          quotation_grace_period: state?.duration?.lead?.grace_period_value,
          apply_grace_peroid: state?.duration?.lead?.apply_grace_period,
          quotation_billing_cycle: state?.duration?.lead?.bill_generation,
          quotation_billing_cycle_date: state?.duration?.lead?.billing_cycle_date,
          quotation_renewal_based_on: {
            value: state?.duration?.lead?.auto_renewal_escalation,
            label: state?.duration?.lead?.auto_renewal_escalation
          },
          quotation_auto_renewal: state?.duration?.lead?.auto_renewal,
          quotation_escalculation_percent: state?.duration?.lead?.increment_escalation,
          quotation_include_market_place: state?.duration?.lead?.include_market,
          grace_peroid: state?.data?.stateFilter?.facilitesbool ?? "",
        })
        next()
      } else {

        setDataNew({
          ...dataNew,

          custom_date: res?.data?.company_master[0]?.billing_day,
          bill_cycle_method:res?.data?.company_master[0]?.billing_cycle,
          bill_cycle_date:res?.data?.company_master[0]?.billing_cycle_date,
          purpose: {
            label: state?.data?.property_purpose,
            value: state?.data?.property_purpose
          },
          revenue_type: {
            label: state?.data?.revenue_type,
            value: state?.data?.revenue_type
          },
          payment_period: state?.data?.revenue_type === "Sale" ? "" : {
            value: res?.data?.company_master[0]?.payment_period,
            label: res?.data?.company_master[0]?.payment_period,
          },
          type: state?.type,
          units: state?.unit,
          company: state?.companyID?.value,
          searchdata: state?.data,
          lease_start_date: state?.data?.duration?.fromDate,
          grace_peroid: state?.data?.stateFilter?.facilitesbool ?? "",
          billing_start_date: moment(state?.data?.duration?.fromDate).add(state?.data?.stateFilter?.facilitesbool ?? 0, 'days')?._d,
          lease_end_date: moment(state?.data?.duration?.toDate).subtract(1, 'days'),
          duration: {
            value: state?.duration?.duration?.input,
            select: state?.duration?.duration?.period === "Month" ? "Monthly" : "Yearly"
          },
          quotation_lease_duration: {
            value: state?.duration?.duration?.input,
            select: state?.duration?.duration?.period === "Month" ? "Monthly" : "Yearly"
          },
          required_area: {
            min: "",
            max: "",
            select: {
              value: res?.data?.company_master[0]?.uom,
              label: res?.data?.company_master[0]?.uom
            },
          },
          budget: {
            min: "",
            max: "",
            select: {
              value: res?.data?.company_master[0]?.currencyByID?.id,
              label: res?.data?.company_master[0]?.currencyByID?.symbol
            },
          },
        })
      }
    }).catch((err) => console.log(err))
  }


  const newLeadComponent = {
    1: <AddContact />,
    2: <LeadNew />,
    3: <Box className={classes.content}><LeadPreview /></Box>,
  }

  const existLeadComponent = {
    1: <Box className={classes.content}><LeadPreview /></Box>,
  }

  const render = () => {
    return <>
      {/*sub header*/}
      <Subheader goBack={() => history.goBack()} title={dataNew.type === "existing lead" ? "Create Quotation To Existing Opportunity" : "Create Quotation To New Opportunity"} />
      <Stepper step={step} steps={dataNew.type === "existing lead" ? stepsNewExist : stepsNew} />
      {/*conditional component*/}
      {
        dataNew.type === "existing lead" ?
          existLeadComponent[step] :
          newLeadComponent[step]
      }

      {/*btn container */}
      <Grid container className={classes.btnroot} spacing={1}>
        <Grid item xs={6}>
          <Button className={classes.previousbtn} variant="contained" onClick={previous} disabled={step === 1}>
            Previous
          </Button>
        </Grid>
        <Grid item xs={6} textAlign={"right"} >
          <Button className={classes.previousbtn} variant="contained" onClick={() => history.goBack()}>
            Cancel
          </Button>

          {permission?.create &&
            <Button disabled={disable} className={classes.submitbtn} variant="contained" onClick={next}>
             {step === 3 ? "Done" : "Next"}
            </Button>}
        </Grid>
      </Grid>
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>

  );
};
