import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import { Bold, Regular, SemiBold } from "../../utils";

export const componentType = makeStyles((theme) => ({
  root: {
    borderRadius: theme.palette.borderRadius,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: (props) => props?.padding ?? "12px",
    border: (props) =>
      props?.borderColor
        ? `1px solid ${props?.bordercolor}`
        : `1px solid ${theme?.palette?.border?.secondary}`,
    backgroundColor: (props) => props?.backgroundcolor ?? "#fff",
  },
  title: {
    color: (props)=>props?.bordercolor ?? theme?.typography?.color?.primary,
    fontFamily: SemiBold,
    fontSize: "14px",
    marginLeft: (props) => props?.marginLeft ?? "16px",
  },
  infoIcon: {
    color: theme?.palette?.border?.primary,
    cursor: "pointer",
    "&:hover": {
      color: theme?.typography?.color?.secondary,
    },
  },
  sub: {
    color: theme?.palette?.background?.primary,
    fontSize: "12px",
    fontFamily: Regular,
    marginTop: "4px",
    marginLeft: (props) => props?.marginLeft ?? "16px",
  },
}));

export const viewCardStyle = makeStyles((theme) => ({
  root: {
    borderRadius: theme.palette.borderRadius,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: (props) => props?.padding ?? "16px",
    backgroundColor: (props) =>
      props?.backgroundColor ?? theme?.palette?.pricingAppraisal?.secondary,
  },
  root1: {
    backgroundColor: (props) =>
      props?.backgroundColor ?? theme?.palette?.pricingAppraisal?.secondary,
    padding: (props) => props?.padding ?? "0px 16px",
    borderRadius: theme.palette.borderRadius,
  },
  main: {
    borderBottom: `2px solid ${theme?.palette?.border?.main}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: (props) => props?.padding ?? "16px 0px",
    "&>:nth-child(2)": {
      borderBottom: "none !important",
    },
  },
  title: {
    fontSize: "16px",
    fontFamily: Bold,
    color: (props) => props?.color ?? theme?.palette?.pricingAppraisal?.primary,
  },
  total: {
    fontSize: "16px",
    fontFamily: SemiBold,
    color: (props) => props?.color ?? theme?.palette?.pricingAppraisal?.primary,
  },
  units: {
    fontSize: "12px",
    fontFamily: SemiBold,
    color: (props) => props?.color ?? theme?.palette?.pricingAppraisal?.primary,
  },
  textAlign: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  dot: {
    backgroundColor: (props) =>
      props?.color ?? theme?.palette?.pricingAppraisal?.primary,
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    margin: "auto 10px",
  },
  subCatagory: {
    fontSize: "12px",
    fontFamily: SemiBold,
    color: (props) => props?.color ?? theme?.palette?.pricingAppraisal?.primary,
  },
  marginLest: {
    marginLeft: "20px",
  },
}));

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px",
    padding: "12px",
    backgroundColor: theme?.palette?.background?.tertiary1,
    color: theme?.typography?.color?.secondary,
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.04)",
    fontSize: "14px",
    fontFamily: SemiBold,
    cursor: "pointer",
  },
}));
