import { useApolloClient } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { GET_USER_PROFILE } from "../../graphql/resourceMaster";
import { UserCard } from "../../screens/resourceMaster/components/userCard";
import { LocalStorageKeys } from "../../utils";
import { TextBox } from "../textbox";
import { useStyles } from "./styles";

export const UserListDropDown = ({
    value = null,
    label = "",
    placeholder = "",
    onChange = () => false,
    isError = false,
    errorMessage = "",
    isrequired = false
}) => {
    const classes = useStyles();
    const client = useApolloClient()
    const [search, setSearh] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [offset, setOffset] = React.useState(0);
    const [options, setOptions] = React.useState([]);

    const getList = (off, searchText, reload) => {
        client.query({
            query: GET_USER_PROFILE,
            fetchPolicy: 'network-only',
            variables: {
                client: localStorage.getItem(LocalStorageKeys.clinetID),
                search: searchText,
                offset: off,
                limit: 10
            }
        }).then((res) => {
            if (reload) {
                setOptions(res?.data?.list)
            } else {
                setOptions(options.concat(res?.data?.list))
            }
        })
    }
    React.useEffect(() => {
        getList(0, "", true)
        // eslint-disable-next-line
    }, [])
    const handleChange = (val) => {
        setSearh(val)
        getList(0, val, true)
    }

    const fetchMoreData = () => {
        setOffset(offset + 10)
        getList(offset + 10, search, "")
    }

    return (
        <Box className={classes.root}>
            <Typography className={classes.Label} >{label}
                {isrequired && (
                    <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                        *
                    </Typography>
                )}

            </Typography>
            <Box onClick={() => setOpen(!open)}>
                {
                    value?.value ?
                        <UserCard

                            list={value}
                            details={true}
                        />
                        :
                        <Box className={classes.field}>
                            {placeholder}
                        </Box>
                }

            </Box>
            {
                open &&
                <Box className={classes.list} >

                    <TextBox
                        label={false}
                        placeholder="Search User"
                        value={search}
                        onChange={(e) => handleChange(e.target.value)}
                    />
                    <Box onClick={() => setOpen(false)} marginTop="12px">

                        <InfiniteScroll
                            dataLength={options.length}
                            next={fetchMoreData}
                            hasMore={true}
                            height={options.length < 10 ? "auto" : 200}
                        >
                            {
                                options.length > 0 ?
                                    options?.map((val) => {
                                        return <UserCard list={val} value={value?.value} onChange={onChange} />
                                    })
                                    :
                                    <Typography marginTop="8px" textAlign="center" className={classes.Label} >No Data Found</Typography>
                            }


                        </InfiniteScroll>

                    </Box>
                </Box>
            }
            {isError && (
                <Typography variant={"caption"} color={"error"}>
                    {errorMessage}
                </Typography>
            )}
        </Box>
    )
}