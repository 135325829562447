import { Grid, Box, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react'
import { weeklyCalendarStyle, eventListStyle } from './style'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { LoadingSection } from '../../../components';
export const WeeklyCalendar = ({ weekdates = [], data = [], openDeclinePopup = () => false, loading = false }) => {
    const classes = weeklyCalendarStyle()

    const hours = [
        { label: "00:00-01:00", start: "00", end: "01" },
        { label: "01:00-02:00", start: "01", end: "02" },
        { label: "02:00-03:00", start: "02", end: "03" },
        { label: "03:00-04:00", start: "03", end: "04" },
        { label: "04:00-05:00", start: "04", end: "05" },
        { label: "05:00-06:00", start: "05", end: "06" },
        { label: "06:00-07:00", start: "06", end: "07" },
        { label: "07:00-08:00", start: "07", end: "08" },
        { label: "08:00-09:00", start: "08", end: "09" },
        { label: "09:00-10:00", start: "09", end: "10" },
        { label: "10:00-11:00", start: "10", end: "11" },
        { label: "11:00-12:00", start: "11", end: "12" },
        { label: "12:00-13:00", start: "12", end: "13" },
        { label: "13:00-14:00", start: "13", end: "14" },
        { label: "14:00-15:00", start: "14", end: "15" },
        { label: "15:00-16:00", start: "15", end: "16" },
        { label: "16:00-17:00", start: "16", end: "17" },
        { label: "17:00-18:00", start: "17", end: "18" },
        { label: "18:00-19:00", start: "18", end: "19" },
        { label: "19:00-20:00", start: "19", end: "20" },
        { label: "20:00-21:00", start: "20", end: "21" },
        { label: "21:00-22:00", start: "21", end: "22" },
        { label: "22:00-23:00", start: "22", end: "23" },
        { label: "23:00-00:00", start: "23", end: "00" },

    ]


    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            maxWidth: "100%",
            border: '1px solid #dadde9',
        },
    }));
    return (
        <Box>
            <Grid container sx={{ width: "99.4%" }}>
                <Grid item md={1} xs={1} className={classes.cells}>
                    <Stack spacing={1} alignItems={"center"}>
                        <AccessTimeFilledIcon className={classes.listTitle} />
                    </Stack>
                </Grid>
                {weekdates?.map((e) => {
                    return (
                        <Grid item md={1.57} xs={1.57} className={classes.cells}>
                            <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
                                <Stack>
                                    <Typography className={classes.dayLabel}>
                                        {moment(e).format("ddd")}
                                    </Typography>
                                    <Typography className={classes.dateLabel}>
                                        {moment(e).format("DD")}
                                    </Typography>
                                </Stack>

                            </Stack>
                        </Grid>
                    );
                })}
            </Grid>
            <Grid container className={classes.calenderRows}>
                {loading ?
                    <Grid item xs={12}>
                        <LoadingSection />
                    </Grid>
                    :
                    hours?.map((val) => {
                        return (
                            <>
                                <Grid item md={1} xs={1} className={classes.cells}>
                                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                        <Typography className={classes.dayLabel}>{val?.label}</Typography>
                                    </Stack>
                                </Grid>

                                {

                                    weekdates?.map(date => {
                                        return (
                                            <Grid item md={1.57} className={classes.cells}>
                                                {
                                                    data?.map(wd => {
                                                        return (
                                                            <div>
                                                                {
                                                                    wd?.date === moment(date).format("YYYY-MM-DD") &&
                                                                    wd?.calendarData?.filter((item) =>
                                                                        (item?.start?.slice(11, 13) === val?.start && item?.end?.slice(11, 13) === val?.end)
                                                                    ).map((x) => {
                                                                        return (
                                                                            <Box>
                                                                                {
                                                                                    x?.booking?.slots?.map(d => {
                                                                                        return (
                                                                                            <Box>
                                                                                                {
                                                                                                    x?.booking?.slots?.length === 1 &&
                                                                                                    <Box>
                                                                                                        <HtmlTooltip
                                                                                                            title={
                                                                                                                // x?.parking_master_parking_type === "Reserved" ?
                                                                                                                <React.Fragment>
                                                                                                                    <CustomToolTip data={d} openDeclinePopup={openDeclinePopup} />
                                                                                                                </React.Fragment>
                                                                                                                // : ""
                                                                                                            }
                                                                                                            arrow className={classes.customTooltip}
                                                                                                        >
                                                                                                            <Stack direction={"column"} spacing={1} alignItems={"center"}
                                                                                                                sx={{
                                                                                                                    backgroundColor: d?.parking_master_parking_type === "Visitors" ? "#FEEAEA80" : "#DBF0F180",
                                                                                                                    color: d?.parking_master_parking_type === "Visitors" ? "#B3776D" : "#6DAFB3"
                                                                                                                }} p={0.7}>
                                                                                                                <Typography className={classes.event_label}> {d?.parking_no} , {d?.reference_id} </Typography>
                                                                                                            </Stack>
                                                                                                        </HtmlTooltip>
                                                                                                    </Box>
                                                                                                }

                                                                                            </Box>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                {
                                                                                    x?.booking?.slots?.length > 1 &&
                                                                                    <Box>
                                                                                        <HtmlTooltip
                                                                                            title={
                                                                                                <React.Fragment>
                                                                                                    <CustomToolTip data={x?.booking?.slots?.[0]} openDeclinePopup={openDeclinePopup} />
                                                                                                </React.Fragment>
                                                                                            }
                                                                                            arrow className={classes.customTooltip}
                                                                                        >
                                                                                            <Stack direction={"column"} spacing={1} alignItems={"center"}
                                                                                                sx={{
                                                                                                    backgroundColor: x?.booking?.slots?.[0]?.status === "Meter Reading" ? "#FFFAD880" : x?.booking?.slots?.[0]?.parking_master_parking_type === "Visitors" ? "#FEEAEA80" : "#DBF0F180",
                                                                                                    color: x?.booking?.slots?.[0]?.status === "Meter Reading" ? "#B3A16D" : x?.booking?.slots?.[0]?.status === "Visitors" ? "#B3776D" : "#6DAFB3"
                                                                                                }} p={0.7}>
                                                                                                <Typography className={classes.event_label}> {x?.booking?.slots?.[0]?.parking_no},{x?.booking?.slots?.[0]?.reference_id}
                                                                                                </Typography>

                                                                                            </Stack>
                                                                                        </HtmlTooltip>
                                                                                        <Typography className={classes.moreLabel}> +{x?.booking?.slots?.length - 1} more </Typography>
                                                                                    </Box>
                                                                                }
                                                                            </Box>



                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        )
                                    })
                                }


                            </>
                        );
                    })}
            </Grid>
        </Box >
    )
}

const CustomToolTip = ({ data = {}, startTime = null, endTime = null, openDeclinePopup = () => false }) => {
    const classes = eventListStyle()
    return (
        <Stack>
            <Stack direction={"column"} spacing={1}>
                <Stack>
                    <Typography className={classes.ttTitle}> {data?.parking_no} ,{data?.reference_id}  </Typography>
                </Stack>
                <Stack>
                    <Typography className={classes.ttsecTitle}> {data?.parking_master_parking_type} </Typography>
                </Stack>
                <Stack>
                    <Typography className={classes.ttassigned_by}>booked by : {data?.first_name}, {`${data?.mobile_no_country_code ?? ""} ${data?.mobile_no ?? ""}`} </Typography>
                </Stack>
                <Stack>
                    <Typography className={classes.moreLabel} onClick={() => openDeclinePopup(data)}> {"view more"}</Typography>
                </Stack>
            </Stack>

        </Stack>
    )
}