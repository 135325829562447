import {
  Lead,
  Agreement,
  Countinity,
  Finance,
  Inspection,
  Kyc,
  Oppertunity,
  TickImageComponent,
  Loader,
  Timer,
  TerminationRequest,
  RenewalRequest,
  AsPerAgreement,
  OppertunityTimeLine,
  LeadTimeLine
} from "../assets";
import { Badge , Avatar} from "@mui/material";
import {
  Assigned,
} from "../assets/agreementTimelineStatusIcon";
import {convertTimeUtcToZoneCalander} from './common'


export const getSettingList = (t) => {
  const result = [
    {
      label: t("customerInformation"),
      value: 1,
    },
    {
      label: t("opportunityInformation"),
      value: 2,
    },
    {
      label: t("quotationInformation"),
      value: 3,
    },
    {
      label: t("reservationInformation"),
      value: 4,
    },
    {
      label: t("agreementInformation"),
      value: 5,
    },
    {
      label: t("billingInformation"),
      value: 6,
    },
    // {
    //   label: t("renewalAndAddon"),
    //   value: 7,
    // },
    {
      label: t("revenueSummary"),
      value: 8,
    },
    {
      label: t("quotationPreview"),
      value: 9,
    },
    {
      label: t("agreementDocument"),
      value: 10,
    },
  ];
  return result;
};
export const paymentmethodPath = [
  "description",
  "start_date",
  "to_date",
  "payment_period",
  "total",
  "due_date",
];

export const getPaymentScheduleHeading = (t) => {
  const paymentmethodheading = [
    { title: t("scheduleDescription"), field: "description" },
    { title: t("startDate"), field: "start_date" },
    { title: t("toDate"), field: "to_date" },
    { title: t("paymentPeriod"), field: "payment_period" },
    { title: t("totalAmount"), field: "total" },
    { title: t("dueDate"), field: "due_date" },
  ];
  return paymentmethodheading;
};

export const paymentmethoddataType = [
  { type: ["text"], name: "description" },
  { type: ["date"], name: "start_date" },
  { type: ["date"], name: "to_date" },
  { type: ["text"], name: "payment_period" },
  { type: ["text"], name: "total" },
  { type: ["date"], name: "due_date" },
];

export const QuoteUnitsPath = ["image", "unit_no", "name", "type"];

export const getQuoteUnitHeading = (t) => {
  const QuoteUnitsheading = [
    { title: t("image"), field: "image" },
    { title: t("unitNo"), field: "unit_no" },
    { title: t("unitName"), field: "name" },
    { title: t("unitType"), field: "type" },
  ];
  return QuoteUnitsheading;
};

export const QuoteUnitsdataType = [
  { type: ["avatarmanagement"], name: "image" },
  { type: ["text"], name: "unit_no" },
  { type: ["text"], name: "name" },
  { type: ["text"], name: "type" },
];
export const invoicePath = [
  "invoice_no",
  "invoice_type",
  "payment_status",
  "invoice_date",
  "payment_due_date",
  "invoice_total_amount",
  "invoice_total_tax_amount",
  "tax_group",
  "invoice_due_amount",
];

export const getInvoiceHeading = (t) => {
  const invoiceheading = [
    { title: t("invoice_no"), field: "invoice_no" },
    { title: t("invoice_type"), field: "invoice_type" },
    { title: t("payment_status"), field: "payment_status" },
    { title: t("invoice_date"), field: "invoice_date" },
    { title: t("payment_due_date"), field: "payment_due_date" },
    { title: t("invoice_total_amount"), field: "invoice_total_amount" },
    { title: t("invoice_total_tax_amount"), field: "invoice_total_tax_amount" },
    { title: t("tax_group"), field: "tax_group" },
    { title: t("invoice_due_amount"), field: "invoice_due_amount" },
  ];
  return invoiceheading;
};

export const invoicedataType = [
  { type: ["text"], name: "invoice_no" },
  { type: ["text"], name: "invoice_type" },
  { type: ["status"], name: "payment_status" },
  { type: ["date"], name: "invoice_date" },
  { type: ["date"], name: "payment_due_date" },
  { type: ["text"], name: "invoice_total_amount" },
  { type: ["text"], name: "invoice_total_tax_amount" },
  { type: ["text"], name: "tax_group" },
  { type: ["text"], name: "invoice_due_amount" },
];

export const getTimeLineHeading = (status, t) => {
  switch (status) {
    case "Draft":
      return t("agreementRepresentativeAssigning");
    case "Review":
      return t("agreementReviewStatus");
    case "KYC Screening":
      return t("kycVerificationStatus");
    case "Finance Review":
      return t("financeVerificationStatus");
    case "Onboarding":
      return t("inspectionVerificationStatus");
    case "Live":
      return t("Live");
    default:
      return t("agreementRepresentativeAssigning");
  }
};

export const getTimeLineListHeading = (status, t, type) => {
  switch (status) {
    case "Draft":
      if (type?.length > 0) {
        return t("agreementCreated");
      } else {
        return t("assign_review");
      }
    case "Review":
      if (type?.length > 0) {
        return t("underReviewRepresentativeStatus");
      } else {
        return t("assign_review");
      }
    case "KYC Screening":
      if (type?.length > 0) {
        return t("kycVerificationStatuslist");
      } else {
        return t("assign_screen");
      }
    case "Finance Review":
      if (type?.length > 0) {
        return t("financeVerificationStatuslist");
      } else {
        return t("assign_Finace");
      }
    case "Onboarding":
      if (type?.length > 0) {
        return t("inspectionVerificationStatuslist");
      } else {
        return t("inspection_and_onBoarding");
      }
    case "Confirmed":
      return t("Confirmed");
    case "Live":
      return t("onboardedList");
    case "Declined":
      return t("declined");
    case "Terminated":
      return t("Terminated");
    case "Expired":
      return t("Expired");
    default:
      return t("agreementCreated");
  }
};

export const getNextstatus = (status) => {
  switch (status) {
    case "Draft":
      return "Review";
    case "Review":
      return "KYC Screening";
    case "KYC Screening":
      return "Finance Review";
    case "Finance Review":
      return "Onboarding";
    case "Onboarding":
      return "Live";
    default:
      return null;
  }
};
//inspection utils

export const inspectionPath = ["inspection_id", "unit_no", "property_name", "tenant_name", "inspector_name", "assigned_on", "status"]


export const getInspectionHeading = (t) => {
  const inspectionheading = [
    { title: t("inspection_id"), field: "inspection_id", },
    { title: t("unit_no"), field: "unit_no", },
    { title: t("property_name"), field: "property_name", },
    { title: t("tenant_name"), field: "tenant_name", },
    { title: t("inspector_name"), field: "inspector_name", },
    { title: t("assigned_on"), field: "assigned_on", },
    { title: t("status"), field: "status" },
  ];
  return inspectionheading;
};

export const inspectiondataType = [
  { type: ["text"], name: "inspection_id" },
  { type: ["text"], name: "unit_no" },
  { type: ["text"], name: "property_name" },
  { type: ["text"], name: "tenant_name" },
  { type: ["text"], name: "inspector_name" },
  { type: ["date"], name: "assigned_on" },
  { type: ["agreement_inspection_status"], name: "status" },
];

export const agreementTime = (t, step , is_show , indexOfOne) => [
  {
    image:
    is_show[0] ? (
        <Badge badgeContent={<TickImageComponent color={"#5AC782"} />}>
          <Lead color={"#5AC782"} />
        </Badge>
      ) : (
        <Lead color={step === 0 ? "#fff" : "#c1c5cb"} />
      ),
    title: `${t("Lead")}`,
    subTitle: `${t("Qualified")}`,
    color: is_show[0] ? "#5AC782" : step === 0 ? "#78B1FE" : "#4E5A6B",
    subcolor: "#5AC782",
    is_active:indexOfOne 
  },
  {
    image:
    is_show[indexOfOne ? 1 : 0] ? (
        <Badge badgeContent={<TickImageComponent color={"#5AC782"} />}>
          <Oppertunity color={"#5AC782"} />
        </Badge>
      ) : (
        <Oppertunity color={indexOfOne ? step === 1 ? "#fff" : "#c1c5cb" : step === 0 ? "#fff" : "#c1c5cb"} />
      ),
    title: `${t("Opportunity")}`,
    subTitle: is_show[indexOfOne ? 1 : 0]  ? `${t("Completed")}` : `${t("Won")}`,
    color: is_show[indexOfOne ? 1 : 0]  ? "#5AC782" : step === 1 ? "#78B1FE" : "#4E5A6B" ,
    subcolor: "#5AC782",
    is_active:true
  },
  {
    image:
    is_show[indexOfOne ? 2 : 1]  ? (
        <Badge badgeContent={<TickImageComponent color={"#5AC782"} />}>
          <Agreement color={"#5AC782"} />
        </Badge>
      ) : (
        <Agreement color={indexOfOne ? step === 2 ? "#fff" : "#c1c5cb" : step === 1 ? "#fff" : "#c1c5cb"} />
      ),
    title: `${t("Agreement")}`,
    subTitle:is_show[indexOfOne ? 2 : 1]  ? `${t("Reviewed")}` : `${t("Draft")}`,
    color: is_show[indexOfOne ? 2 : 1]  ?  "#5AC782" : step === 2 ? "#78B1FE" : "#4E5A6B",
    subcolor:is_show[indexOfOne ? 2 : 1]  ? "#5AC782" : "#FF9340",
    is_active:true
  },
  {
    image:
    is_show[indexOfOne ? 3 : 2]  ? (
        <Badge badgeContent={<TickImageComponent color={"#5AC782"} />}>
          <Kyc color={"#5AC782"} />
        </Badge>
      ) : (
        <Kyc color={indexOfOne ? step === 3 ? "#fff" : "#c1c5cb" : step === 2 ? "#fff" : "#c1c5cb"} />
      ),
    title: `${t("KYC")}`,
    subTitle:
    is_show[indexOfOne ? 3 : 2] 
        ? `${t("KYC Review")}`
        : `${t("Yet to start")}`,
    color: is_show[indexOfOne ? 3 : 2]  ? "#5AC782" : step === 3 ? "#78B1FE" : "#4E5A6B",
    subcolor: is_show[indexOfOne ? 3 : 2]  ? "#5AC782" : "#FF9340",
    is_active:true
  },
  {
    image:
    is_show[indexOfOne ? 4 : 3]  ? (
        <Badge badgeContent={<TickImageComponent color={"#5AC782"} />}>
          <Finance color={"#5AC782"} />
        </Badge>
      ) : (
        <Finance color={indexOfOne ? step === 4 ? "#fff" : "#c1c5cb" : step === 3 ? "#fff" : "#c1c5cb"} />
      ),
    title: `${t("Finance")}`,
    subTitle:
    is_show[indexOfOne ? 4 : 3]
        ? `${t("ConfirmedStatus")}`
        : step === 4
        ? `${t("Payment link")}`
        : `${t("Yet to start")}`,
    color: is_show[indexOfOne ? 4 : 3] ? "#5AC782" : step === 4 ? "#78B1FE" : "#4E5A6B",
    subcolor: is_show[indexOfOne ? 4 : 3] ? "#5AC782" : "#FF9340",
    is_active:true
  },
  {
    image:
    is_show[indexOfOne ? 5 : 4] ? (
        <Badge badgeContent={<TickImageComponent color={"#5AC782"} />}>
          <Inspection color={"#5AC782"} />
        </Badge>
      ) : (
        <Inspection color={indexOfOne ? step === 5 ? "#fff" : "#c1c5cb" : step === 4 ? "#fff" : "#c1c5cb"} />
      ),
    title: `${t("Inspections")}`,
    subTitle:is_show[indexOfOne ? 5 : 4] ? `${t("Onboarding")}` : `${t("Yet to start")}`,
    color: is_show[indexOfOne ? 5 : 4]  ? "#5AC782" : step === 5 ? "#78B1FE" : "#4E5A6B",
    subcolor: is_show[indexOfOne ? 5 : 4] ? "#5AC782" : "#FF9340",
    is_active:true
  },
  {
    image:
   is_show[indexOfOne ? 6 : 5] ? (
        <Badge badgeContent={<TickImageComponent color={"#5AC782"} />}>
          <Countinity color={"#5AC782"} />
        </Badge>
      ) : (
        <Countinity color={indexOfOne ? step === 6 ? "#fff" : "#c1c5cb" : step === 5 ? "#fff" : "#c1c5cb"} />
      ),
    title: `${t("Continuity")}`,
    subTitle:is_show[indexOfOne ? 6 : 5] ? `${t("Live")}` : `${t("Yet to start")}`,
    color:is_show[6]? "#5AC782" :  step === 6 ? "#78B1FE" : "#4E5A6B",
    subcolor:is_show[indexOfOne ? 6 : 5]? "#5AC782" : "#FF9340",
    is_active:true
  },
];


export const owner = (details) => [
  {
    title:`${details?.user_first_name ?? ''} ${details?.user_last_name ?? ''}`,
    subtitle: "Owner",
    image: <Avatar src={details?.contact_image_url ?? ""} />,
    color: "#896DB3",
    backgroundColor: "#EDE4FE80",
  },
];

export const ownerDay = (details) => [
  {
    title: details?.duration ?? "",
    subtitle: "Time taken for this Stage" ?? "",
    image: <Loader />,
    color: "#B3776D",
    backgroundColor: "#FEEAEA80",
  },
];

export const ownerStatus = (details, show) => [
  {
    title: details?.over_all_time_taken ?? "",
    subtitle: "Overall time taken" ?? "",
    image: <Timer />,
    color: "#499364",
    backgroundColor: "#EEF9EE",
    is_divider: show &&  details?.journey_period?.length > 0 ? true : false,
    is_active: true,
  },
  // {
  //   title: `${details?.journey_period ?? ""} journey period `,
  //   subtitle: `${convertTimeUtcToZoneCalander(details?.lease_start_date)} - Today` ?? "",
  //   image: <Period />,
  //   color: "#499364",
  //   backgroundColor: "#EEF9EE",
  //   is_active: show &&  details?.journey_period?.length > 0 ? true : false,
  // },
];

export const countuinity = (details) => [
  {
    title: `${details?.journey_period ?? ""} journey period `,
    // subtitle: `${convertTimeUtcToZoneCalander(details?.lead_details?.lease_start_date)} - Today` ?? "",
    image: <img src="images/icons8-timer-96.png" alt="timer" />,
    color: "#33691E",
    backgroundColor: "#FFFFFF",
    border:"1px solid #E4E8EE",
    boxShadow:'0px 0px 16px #00000014;'
  },
];

export const getCouniunityTimeLineHeading = (status, t, type) => {
  switch (status) {
    case "Draft":
      return t("Agreement_Termination_Requested");
    case "Review":
      return t("Agreement_Termination_Requested");
    case "KYC Screening":
      return t("Agreement_Termination_Requested");
    default:
      return t("Agreement_Termination_Requested");
  }
};


// --------------------------------agreement time line------------------------------------------
export const renderFunctionTitle = (val, type) => {
  switch (val) {
    case "Draft":
      return "Agreement Responsible";
    case "Review":
      if (type === "Declined") {
        return "Agreement Responsible";
      } else {
        return "KYC Screening Process";
      }
    case "KYC Screening":
      if (type === "Declined") {
        return "KYC Screening Process";
      } else {
        return "Simulate Invoice Schedule";
      }
    case "Finance Review":
      if (type === "Declined") {
        return "Simulate Invoice Schedule";
      } else {
        return "Inspection Orders";
      }
    case "Onboarding":
      return "Inspection Orders";
    default:
      return "";
  }
};
export const renderFunctionSub = (val, type) => {
  switch (val) {
    case "Draft":
      return "Requesting inspection team to inspect property for move in";
    case "Review":
      if (type === "Declined") {
        return "Person";
      } else {
        return "";
      }
    case "KYC Screening":
      return "";
    case "Finance Review":
      return "";
    case "Onboarding":
      return "";
    default:
      return "";
  }
};
export const renderFunctionTitleType = (val) => {
  switch (val) {
    case "Draft":
      return "Agreement Verification";
    case "Review":
      return "Agreement Verification";
    case "KYC Screening":
      return "KYC Verification";
    case "Finance Review":
      return "Finance Verification";
    case "Onboarding":
      return "Inspection Status";
    default:
      return "Agreement Verification";
  }
};
export const renderFunctionSubType = (val) => {
  switch (val) {
    case "Draft":
      return "Status";
    case "Review":
      return "Status";
    case "KYC Screening":
      return "Status";
    case "Finance Review":
      return "Status";
    case "Onboarding":
      return "";
    default:
      return "Status";
  }
};

export const request = (t,openDialog,cancelDialog , showType) =>[
  {
    icon: <TerminationRequest />,
    title: t("Termination_Request"),
    color: "#FFECEC",
    onClick: () => openDialog("Termination_Request"),
    is_active:true
  },
  {
    icon: <RenewalRequest />,
    title: t("Renewal_Request"),
    color: "#F1F7FF",
    onClick: () => openDialog("Renewal_Request"),
    is_active:true
  },
  {
    icon: <TerminationRequest />,
    title: t("Emergency Cancellation"),
    color: "#FFECEC",
    onClick: () => cancelDialog("Termination_Request"),
    is_active:showType
  }
]

export const renewalRequest = (t,onCloseDialog) =>[
  {
    icon: <AsPerAgreement />,
    title: t("As_Per_Agreement"),
    color: "#FFECEC",
    onClick: () => onCloseDialog("Agreement"),
    des:t("agreement_des"),
  },
  {
    icon: <AsPerAgreement />,
    title: t("Custom"),
    color: "#F1F7FF",
    onClick: () => onCloseDialog("custom"),
    des:t("custom_des"),
  },
]

export const leadTimeLine = (details) => [
  {
    title:"Lead Qualified",
    sub: `on ${convertTimeUtcToZoneCalander(details?.updated_at)}` ?? "",
    image: <LeadTimeLine  />
  },
  {
    title:"Owner",
    assignee: details ?? "",
    is_owner:true,
    sub:"",
    image:<Assigned/>
  },
  {
    title:"Lead Created",
    sub: `${details?.reference_no ?? ""} , on ${convertTimeUtcToZoneCalander(details?.created_at) ?? ""}`,
    image: <img src="/images/onBoard.svg" alt="onBoard" />
  },
];

export const oppertunityTimeLine = (details) => [
  {
    title:"Opportunity Won",
    is_qutation: true,
    image: <OppertunityTimeLine />,
    qutation:details ?? ""
  },
  {
    title:"Owner",
    assignee: details ?? "",
    is_owner:true,
    sub:"",
    image:<Assigned/>
  },
  {
    title:"Opportunity Created",
    sub: `${details?.lead_no ?? ""} , on ${convertTimeUtcToZoneCalander(details?.updated_at) ?? ""}`,
    image: <img src="/images/onBoard.svg" alt="onBoard" />
  },
];


export const statusTypeFunction = (list = [], status = []) => {
  let obj = {
    0: "",
    1: "",
    2: list
      ?.filter((x) => status.map((item) => item === x.status))
      ?.some((x) => x?.type === "Approved") ,
    3: list
      ?.filter((x) => status.map((item) => item !== x.status))
      ?.some((x) => x?.type === "Approved"),
  };
  return obj;
};

export const statusTypeFunctionFilter = (list = [], status = []) => {
  const arry=[]
  const array1=[]
  for (let i = 0; i < list?.length; i++) {
     if(status.some((item) => item === list[i].status)){
      return arry.push(list[i])
     }
  }

  for (let i = 0; i < list?.length; i++) {
    if(status.some((item) => item !== list[i].status)){
     return array1.push(list[i])
    }
 }
  let obj = {
    0: "",
    1: "",
    2: arry,
    3: list
      ?.filter((x) => status.map((item) => item !== x.status)),
  };
  return obj;
};
export const statusType = (list)=>{
  const obj ={
    0: true,
    1: true,
    2: list
      ?.filter((x) => x.status === "Review" || x.status === "Draft")
      ?.some((x) => x?.type === "Approved"),
      3:list
      ?.filter((x) => x.status === "KYC Screening")
      ?.some((x) => x?.type === "Approved"),
      4:list
      ?.filter((x) => x.status === "Finance Review")
      ?.some((x) => x?.type === "Approved"),
      5:list
      ?.filter((x) => x.status === "Onboarding")
      ?.some((x) => x?.type === "Approved"),
      6:list
      ?.filter(
        (x) =>
          x.status !== "Onboarding" &&
          x.status !== "Review" &&
          x.status !== "Draft" &&
          x.status !== "KYC Screening" &&
          x.status !== "Finance Review"&&
          x.status !== "Confirmed"
      )
      ?.some((x) => x?.type === "Approved"),
      7: list
      ?.filter((x) => x.status === "Review")
      ?.some((x) => x?.type === "Approved"),
  };
  return obj
}

export const statusType1 = (list)=>{
  const obj ={
    0: true,
    1: list
      ?.filter((x) => x.status === "Review" )
      ?.some((x) => x?.type === "Approved"),
      2:list
      ?.filter((x) => x.status === "KYC Screening")
      ?.some((x) => x?.type === "Approved"),
      3:list
      ?.filter((x) => x.status === "Finance Review")
      ?.some((x) => x?.type === "Approved"),
      4:list
      ?.filter((x) => x.status === "Onboarding")
      ?.some((x) => x?.type === "Approved"),
      5:list
      ?.filter(
        (x) =>
          x.status !== "Onboarding" &&
          x.status !== "Review" &&
          x.status !== "Draft" &&
          x.status !== "KYC Screening" &&
          x.status !== "Finance Review"&&
          x.status !== "Confirmed"
      )
      ?.some((x) => x?.type === "Approved"),
      7: list
      ?.filter((x) => x.status === "Review")
      ?.some((x) => x?.type === "Approved"),
  };
  return obj
}