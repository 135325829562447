import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Stack, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import React from 'react';
import { OwnerUnit, PropertyUnit } from "../../assets";
import { Bold } from "../../utils";
import { Owner } from "./component";
import { useStyles } from "./styles";

export const SelectUnitComponent = ({
    onChange = () => false,
    value = [],
    company = null,
    datas = {},
    isEdit = false,
    onDeleteUnits = () => false
}) => {
    const classes = useStyles();
    const types = [
        {
            value: 1,
            label: "Add against owner units",
            icon: <OwnerUnit />,
            search: "Search Owner"
        },
        {
            value: 2,
            label: "Add against property units",
            icon: <PropertyUnit />,
            search: "Search Property"
        }
    ]

    const [data, setData] = React.useState({
        type: null
    })

    const TypeButton = styled(Button)(({ theme }) => ({
        border: "1px solid #E4E8EE",
        padding: "12px",
        color: "#091B29",
        background: "white",
        //fontSize: 12,
        fontFamily: Bold,
        justifyContent: "space-between",
        cursor: "pointer",
        '&:hover': {
            background: "white"
        },
        borderRadius: theme.palette.borderRadius,
        marginTop: "8px"

    }));

    React.useEffect(() => {
        console.log(datas)
        if (isEdit) {
            if (datas?.edit_owner?.value) {
                setData({
                    type: types[0]
                })

            } else {
                setData({
                    type: types[1]
                })
            }
        }
        // eslint-disable-next-line
    }, [])

    //onclick type
    const onClick = (key, value) => {
        setData({
            ...data,
            [key]: value
        })
    }


    return (
        < >

            {
                data?.type ?
                    <Box className={classes.select}>
                        <Owner
                            datas={datas}
                            isEdit={isEdit}
                            company={company}
                            onChange={onChange}
                            type={data?.type}
                            onDeleteUnits={onDeleteUnits}
                            value={value}
                            onClose={() => {
                                onClick("type", null)
                                onDeleteUnits([], null, true)
                            }}
                        />
                    </Box>
                    :
                    <Box className={classes.root}>
                        <Box>
                            <Typography className={classes.title}>Add Units if you want</Typography>
                            <Typography className={classes.sub}>Units can be added to this lead while creating by two options</Typography>


                            {
                                types?.map((val) => {
                                    return (
                                        <TypeButton
                                            onClick={() => onClick("type", val)}
                                            fullWidth
                                            endIcon={<ArrowForwardIosIcon sx={{ color: "#98A0AC", fontSize: "15px" }} />}
                                            startIcon={
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    {val?.icon}
                                                    <Typography className={classes.btnTitle}>{val?.label}</Typography>
                                                </Stack>
                                            }
                                        >

                                        </TypeButton>
                                    )
                                })
                            }
                        </Box>
                    </Box>
            }

        </>
    )
}