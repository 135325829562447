import { useApolloClient } from '@apollo/client';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import React, { useState } from 'react';
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { AccordianWithDetails, AlertDialog, DialogBox, LoadingSection, Subheader } from "../../components";
import AssignModal from "../../components/assignModal/reAssignModal";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { updateAgreementInspections } from '../../graphql/inspectionTemplate';
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";
import InspectionRequestCard from "./components/card";
import { GatePassModal } from "./components/gatePassModal";
import { useStyles } from "./style";
const InspectionRequest = ({ t }) => {
    const alert = React.useContext(AlertContext);
    const [open, setOpen] = useState(false)
    const client = useApolloClient()
    const { state } = useLocation()
    const auth = React.useContext(AuthContext)
    const classes = useStyles()
    const history = useHistory();
    const [details, setDetails] = useState([])
    const [itemList, setItemList] = useState([
        {
            item: "",
            expand: [
                {
                    moveIn: {},
                    moveOut: {}
                }
            ],
        }
    ])
    const [assignModal, setAssignModal] = useState(false)
    const [permissions, setPermission] = React.useState({})
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = React.useState(true)
    const [disable, setDisable] = React.useState(false)
    const [cancelModal, setCancelModal] = useState(false)
    const [inspectionStatus, setInspectionStatus] = useState(state.status)
    const [notesData, setNotesData] = useState({
        managerNotes: [],
        inspectorNotes: []
    })

    //goback
    const goBack = () => {
        history.goBack()
    }

    //initial load
    React.useEffect(() => {
        if (auth) {
            const perm = getRoutePermissionNew(auth);
            if (perm) {
                setPermission(perm)
            }
        }
        // eslint-disable-next-line
    }, [auth])

    //get tableData 
    const getAgreementInspectionDetail = () => {
        setLoading(true)
        let payload = {}
        payload = {
            "id": state?.id,
            "agreement_inspection_id": state?.agreement_inspection_id,
            "agreement_id": state?.agreement_id,
        }
        
            state.type === "unit_handover" &&(
                payload.type_in=state.type
            )
          
        

        NetworkCall(
            `${config.api_url}/agreement_inspection/get_agreement_inspection_detail`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setDetails(response?.data?.data?.detail)
                setNotesData({ managerNotes: response?.data?.data?.detail?.inspection?.inspection_manager_notes ?? [], inspectorNotes: response?.data?.data?.detail?.inspection?.inspector_notes ?? null })
                if (!state?.data?.is_parking_component && !state?.data?.is_parking_unit) {
                    setItemList({
                        list: response?.data?.data?.list,
                        utility: response?.data?.data?.utility
                    })
                }
                setLoading(false)
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "data not found",
                });
                setLoading(false)
            })
    }
    //initial load
    React.useEffect(() => {
        getAgreementInspectionDetail()
        // eslint-disable-next-line
    }, [])
    const handleStatusClick = () => {
        setAssignModal(true)
    }
    //on download pdf
    const downloadPdf = () => {
        if (details?.inspection?.asset_url) {
            saveAs(details?.inspection?.asset_url, `${details?.inspection?.reference_id}.pdf`)
        }
    }
    //handleClose Assign Modal
    const handleClose = () => {
        setAssignModal(false)
    }
    const SwitchButtons = (val) => {
        switch (val) {
            case "Yet to assign":
                return <Button
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={() => setCancelModal(true)}
                >{t("cancel")}
                </Button>
            case "Assigned":
                return <Button
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={() => setCancelModal(true)}
                >{t("cancel")}
                </Button>
            case "Yet To Verify":
                return (<>
                    <Button
                        variant="outlined"
                        className={classes.cancelButton}
                        onClick={() => setCancelModal(true)}
                    >{t("cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.accoCardButton}
                        onClick={() => setShowModal(true)}>{t("completeInspection")}
                    </Button>
                </>)
            case "Completed":
                return <Button
                    variant="contained"
                    onClick={downloadPdf}
                    className={classes.accoCardButton}
                >{t("downloadInspection")}
                </Button>
            default:
                return null;
        }
    }
    const cancelInspection = (status) => {
        setDisable(true)
        const payload = {
            "agreement_unit_id": details?.inspection?.agreement_unit_id,
            "inspection_id": state?.agreement_inspection_id,
            request_id: details?.inspection?.request_id,
            type: state?.type
        }
        NetworkCall(
            `${config.api_url}/move-in-out/complete_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            if (status === "Completed") {
                const payload = {
                    agreement_inspection_id: state.agreement_inspection_id
                }
                NetworkCall(
                    `${config.api_url}/move-in-out/get_statement`,
                    NetWorkCallMethods.post,
                    payload,
                    null,
                    true,
                    false
                ).then((res) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: `${(status === "Rejected" ? "Cancelled" : "Completed")} Successfully`,
                    })
                    setInspectionStatus(status)
                    getAgreementInspectionDetail()
                    setCancelModal(false)
                    setShowModal(false)
                    setDisable(false)
                }).catch((err) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: `Cannot Generate Pdf`,
                    })
                    setDisable(false)
                })
            } else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${(status === "Rejected" ? "Cancelled" : "Completed")} Successfully`,
                })
                setInspectionStatus(status)
                getAgreementInspectionDetail()
                setCancelModal(false)
                setShowModal(false)
                setDisable(false)
            }


        }).catch((err) => {
            console.log(err)
        })
    }
    //reassign submit
    const reAssign = (payload) => {
        NetworkCall(
            `${config.api_url}/agreement_inspection/re_assign_resource`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: "Re Assigned Successfully",
                });
                handleClose()
                getAgreementInspectionDetail()
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "data not found",
                });
            })
    }
    const onButtonClick = (title) => {
        if (details?.user?.is_active === false && title === "Tenant Login") {
            const payload = {
                "agreement_unit_id": details?.inspection?.agreement_unit_id,
                "inspection_id": state?.agreement_inspection_id
            }
            NetworkCall(
                `${config.api_url}move-in-out/tenant_login_send`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((response) => {
                let msg = response?.data?.message
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${msg}`,
                })


            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: `Something Went Wrong`,
                })
            })
        }
        else if (title === "Gate Pass") {
            setOpen(true)
        }
    }
    const dialogHeader = () => {
        return (
            <Stack direction="row" spacing={1}>
                <Box onClick={handleClose} className={classes.iconbtn} display="flex" alignItems="center">
                    <ArrowBackIosIcon className={classes.icon} htmlColor="black" />
                </Box>
                <Typography className={classes.dialoghdrtext}>{t("reAssignInspector")}</Typography>
            </Stack>
        )
    }
    const onUpdateNotes = (val) => {
        const payload = {
            id: state?.agreement_inspection_id,
            update: {
                inspection_manager_notes: [...notesData?.managerNotes, { notes: val }],
            }
        }
        client.mutate({
            mutation: updateAgreementInspections,
            variables: payload
        }).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Notes Added Successfully",
            });
            setCancelModal(false)
            getAgreementInspectionDetail()
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something went wrong",
            });
        })
    }
    const render = () => {
        return (

            <Box>
                <Subheader goBack={goBack} title={state.title} />
                {
                    loading ?
                        <LoadingSection top="20vh" message={"Fetching Details"} />
                        :

                        <Box className={classes.content}>
                            <Box m={2}>
                                <InspectionRequestCard inspectionStatus={inspectionStatus} setAssignModal={() => {
                                    setAssignModal(true)
                                }}
                                    details={details} handleStatusClick={() => handleStatusClick()} onButtonClick={onButtonClick} />
                                <Box className={classes.root} mt={2}>
                                    <Stack direction={"row"} justifyContent={"space-between"} m={2}>
                                        <Typography className={classes.accoCardTitle}>{t("inspectionDetail")}</Typography>
                                        <Box>{SwitchButtons(inspectionStatus)}</Box>
                                    </Stack>

                                    <Box p={2} marginTop="-24px">
                                        <AccordianWithDetails state={state?.data} data={itemList?.list} MoveIn={true} MoveOut={state.type === "move-out" && true} notesData={notesData} setNotesData={setNotesData} onUpdateNotes={onUpdateNotes} status={inspectionStatus} />
                                    </Box>

                                    {itemList?.utility?.length > 0 &&
                                        <>
                                            {/* <Stack direction={"row"} justifyContent={"space-between"} m={2}>
                                                <Typography className={classes.accoCardTitle}>{t("utilities")}</Typography>

                                            </Stack> */}
                                            <Box p={2} marginTop="-24px">
                                                <AccordianWithDetails state={state?.data} data={itemList?.utility} utility={true} />
                                            </Box>
                                        </>
                                    }
                                </Box>
                            </Box>
                        </Box>
                }
                <DialogBox open={assignModal}
                    onClose={() => handleClose()}
                    header={dialogHeader()}
                    maxWidth="md"
                    fullScreen
                    component={
                        <AssignModal
                            agreement_inspection_id={state.agreement_inspection_id}
                            reassign={true}
                            moduleName={t("moveInInspectionRequest")}
                            handleClose={handleClose}
                            buttonName={"Re-Assign"}
                            onSubmit={reAssign}
                        />}

                />
                <AlertDialog
                    open={showModal}
                    onClose={() => {
                        if (!disable) {
                            setShowModal(false)
                        }
                    }
                    }
                    header={t("completeInspection")}
                    noTitle={true}
                    component={
                        <Box>
                            <center>
                                <Box className={classes.completeInsp}>
                                    <Typography className={classes.msg}>Are you Sure ,You want to Complete ?</Typography>
                                    <Grid container p={1} mt={1} spacing={1}>
                                        <Grid item xs={6}>
                                            <Button fullWidth color="error" onClick={() => setShowModal(false)} variant="contained">No</Button>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth sx={{ color: "white" }} color="success" disabled={disable} onClick={() => cancelInspection("Completed")} variant="contained">Yes</Button>

                                        </Grid>
                                    </Grid>

                                </Box>
                            </center>
                        </Box>
                    }
                    isNormal
                />
                {open &&
                    <GatePassModal open={open} setOpen={setOpen} title={details?.security_request[0]?.status} request_id={details?.security_request[0]?.id} />
                }
                <AlertDialog
                    open={cancelModal}
                    onClose={() => {
                        if (!disable) {
                            setCancelModal(false)
                        }
                    }}
                    header={t("cancelInspection")}
                    component={
                        <Box>
                            <center>
                                <Box className={classes.completeInsp}>
                                    <Typography className={classes.msg}>Are you Sure ,You want to cancel ?</Typography>
                                    <Grid container p={1} mt={1} spacing={1}>
                                        <Grid item xs={6}>
                                            <Button fullWidth color="error" onClick={() => setCancelModal(false)} variant="contained">No</Button>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth sx={{ color: "white" }} disabled={disable} color="success" onClick={() => cancelInspection("Rejected")} variant="contained">Yes</Button>

                                        </Grid>
                                    </Grid>


                                </Box>
                            </center>
                        </Box>
                    }
                    isNormal
                />
            </Box >
        )
    }
    return (
        <Box>
            {accessCheckRender(render, permissions)}
        </Box>
    )
}
export default withNamespaces("inspection")(InspectionRequest)