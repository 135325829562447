import React from 'react';
import { Box, Grid, Typography } from "@mui/material";
import { useStylesCreation } from "./style";
import { TextBox, NewDatePicker, SelectBox } from "../../../components";
import styled from '@mui/material/styles/styled';
import { Bold } from '../../../utils';
import { loadOptions } from '../../../utils/asyncPaginateLoadOptions';
import { GET_ALL_CUNTRIES } from '../../../graphql/quotationQueries';
export const Goverment = (props) => {
      // custom styles
      // start
      const CustomTypography = styled(Typography)(({ theme }) => ({
            fontSize: 12,
            fontFamily: Bold,
            color: theme.typography.color.secondary,
            marginBottom: theme.spacing(1)
      }))

      const textBoxStyle = {
            padding: "8px 14px",
            borderRadius: 8,
            height: 47
      }
      // end

      // style import
      const classes = useStylesCreation();
      const [loading2, setLoading2] = React.useState(false)

      const loadOptionData = async (search, array, type) => {
            setLoading2(type);
            if (type === "country") {
                  return loadOptions(search, array, setLoading2, GET_ALL_CUNTRIES, 'country_master');
            }

            else {
                  return {
                        options: [],
                        hasMore: false
                  }
            }
      }
      return (
            // ID DETAILS
            <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                  <CustomTypography>ID DETAILS</CustomTypography>
                  <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={4}>
                              {/* nationality */}
                              {/* <TextBox
                                    isrequired
                                    {...textBoxStyle}
                                    label="Nationality"
                                    placeholder="Enter Nationality"
                                    value={props?.data?.nationality ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("nationality", e.target.value);
                                    }}
                                    isError={props?.data?.error?.nationality?.length > 0}
                                    errorMessage={props?.data?.error?.nationality} /> */}
                              <SelectBox
                                    label="Nationality"
                                    placeholder="Enter Nationality"
                                    value={props?.data?.nationality ?? ""}
                                    isPaginate
                                    debounceTimeout={800}
                                    loading={loading2 === "country"}
                                    loadOptions={(search, array) => loadOptionData(search, array, "country")}
                                    onChange={(value) => props?.updateState("nationality", value)}
                                    isError={props?.data?.error?.nationality?.length > 0}
                                    errorMessage={props?.data?.error?.nationality}
                              />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              {/* National ID */}
                              <TextBox
                                    isrequired
                                    {...textBoxStyle}
                                    label="National ID"
                                    placeholder="Enter National ID"
                                    value={props?.data?.nationalid ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("nationalid", e.target.value);
                                    }}
                                    isError={props?.data?.error?.nationalid?.length > 0}
                                    errorMessage={props?.data?.error?.nationalid} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              {/* National ID Expiry */}
                              <NewDatePicker
                                    label="National ID Expiry"
                                    placeholder="National ID Expiry"
                                    value={props?.data?.expiry ?? ""}
                                    isRequired
                                    onChange={(value) => props?.updateState("expiry", value)}
                                    isError={props?.data?.error?.expiry?.length > 0}
                                    errorMessage={props?.data?.error?.expiry} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              {/* Passport ID */}
                              <TextBox
                                    isrequired
                                    {...textBoxStyle}
                                    label="Passport ID"
                                    placeholder="Enter Passport ID"
                                    value={props?.data?.passportid ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("passportid", e.target.value);
                                    }}
                                    isError={props?.data?.error?.passportid?.length > 0}
                                    errorMessage={props?.data?.error?.passportid} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              {/* Passport Expiry */}
                              <NewDatePicker
                                    label="Passport Expiry"
                                    placeholder="Passport Expiry"
                                    value={props?.data?.passportexpirt ?? ""}
                                    isRequired
                                    onChange={(value) => props?.updateState("passportexpirt", value)}
                                    isError={props?.data?.error?.passportexpirt?.length > 0}
                                    errorMessage={props?.data?.error?.passportexpirt} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              {/* Resident ID */}
                              <TextBox
                                    isrequired
                                    {...textBoxStyle}
                                    label="Resident ID"
                                    placeholder="Enter Resident ID"
                                    value={props?.data?.residentalid ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("residentalid", e.target.value);
                                    }}
                                    isError={props?.data?.error?.residentalid?.length > 0}
                                    errorMessage={props?.data?.error?.residentalid} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              {/* Resident ID Expiry */}
                              <NewDatePicker
                                    label="Resident ID Expiry"
                                    placeholder="Resident ID Expiry"
                                    value={props?.data?.residentalexpiry ?? ""}
                                    isRequired
                                    onChange={(value) => props?.updateState("residentalexpiry", value)}
                                    isError={props?.data?.error?.residentalexpiry?.length > 0}
                                    errorMessage={props?.data?.error?.residentalexpiry} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              {/* Visa Number */}
                              <TextBox
                                    isrequired
                                    {...textBoxStyle}
                                    label="Visa Number"
                                    placeholder="Enter Visa Numbers"
                                    value={props?.data?.vianumber ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("vianumber", e.target.value);
                                    }}
                                    isError={props?.data?.error?.vianumber?.length > 0}
                                    errorMessage={props?.data?.error?.vianumber} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                              {/* Visa Expiry */}
                              <NewDatePicker
                                    label="Visa Expiry"
                                    placeholder="Visa Expiry"
                                    value={props?.data?.visaexpiry ?? ""}
                                    isRequired
                                    onChange={(value) => props?.updateState("visaexpiry", value)}
                                    isError={props?.data?.error?.visaexpiry?.length > 0}
                                    errorMessage={props?.data?.error?.visaexpiry} />
                        </Grid>
                  </Grid>

            </Box>
      )
}