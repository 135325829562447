import { Typography } from "@mui/material";
import React from 'react'
import { TableWithInfinityScroll } from "../../../../components";
import { useStyles } from "./style";

export const TableDetail = ({ title, heading, path, dataType, tabelheight, tableData, fetchMoreData = () => false }) => {

    const classes = useStyles();

    return <div className={classes.root}>
        <Typography className={classes.title}>
            {title}
        </Typography>
        {/* As per CR commented */}
        {/* <TableWithPagination
            heading={heading}
            rows={tableData ?? []}
            path={path}
            showpdfbtn={false}
            showexcelbtn={false}
            showSearch={false}
            tableType="no-side"
            dataType={dataType}
            height={tabelheight}
            view={true}
            edit={true}
            delete={true} /> */}



        <TableWithInfinityScroll
            heading={heading}
            rows={tableData ?? []}
            path={path}
            showpdfbtn={false}
            showexcelbtn={false}
            showSearch={false}
            tableType="no-side"
            fetchMoreData={fetchMoreData}
            showpagination={false}
            view={true}
            edit={true}
            delete={true}
            dataType={dataType}
            height={tabelheight}
        />
    </div>
}