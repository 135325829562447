import React from "react";
import { withNavBars } from "../../HOCs";
import { GeneralRequest } from "./genralrequest";
class GeneralRequestParent extends React.Component {
  render() {
    return <GeneralRequest />;
  }
}
const props = {
  boxShadow: false,
};
export default withNavBars(GeneralRequestParent, props);
