import { Box, Grid, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';
import React, { useContext, useEffect, useState } from 'react';
import { SelectBox, TextBox, ToggleButtonComponent } from '../../../components';
import { AlertContext } from '../../../contexts';
import { GET_ALL_CUNTRIES } from '../../../graphql/quotationQueries';
import { AlertProps, enum_types } from '../../../utils';
import { loadOptions } from '../../../utils/asyncPaginateLoadOptions';
import { useStylesCreation } from '../../propertcreation/createproperty/style';
import { GET_ALL_CURRENCIES, networkCallback } from '../utils';
import { Bold } from '../../../utils';
const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    fontFamily: Bold,
    color: theme.typography.color.secondary,
    marginBottom: theme.spacing(1)
}));

const buttonStyle = {
    fontSize: "14px",
    height: "45px",
    minWidth: "45px",
    borderColor: "#E4E8EE",
    borderRadius: 1,
    backgroundColor: "#fff",
}

const textBoxStyle = {
    // padding: "8px 14px",
    // borderRadius: 8,
    // height: 44
}

export const BankingDetails = (props) => {

    const {
        data = {},
        updateState = null,
        error = {}
    } = props;

    const classes = useStylesCreation();
    const alert = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [bankAccountTypeOptions, setBankAccountTypeOptions] = useState([])

    useEffect(() => {
        getBankAccountType()
        // eslint-disable-next-line
    }, [])

    const getBankAccountType = async () => {
        let result = await networkCallback({ enumName: ["bank_account_type"] }, "enum");
        if (!result) {
            return []
        }
        setBankAccountTypeOptions(result?.bank_account_type ?? []);
    }

    const loadOptionData = async (search, array, type) => {
        setLoading(type);
        let result;
        let enumTypes = [enum_types.bank_routing_type]

        if (type === "currency") {
            return loadOptions(search, array, setLoading, GET_ALL_CURRENCIES, 'currency_master');
        }
        if (type === "country") {
            return loadOptions(search, array, setLoading, GET_ALL_CUNTRIES, 'country_master');
        }
        else if (enumTypes?.includes(type)) {
            result = await networkCallback({ enumName: [type] }, "enum", setLoading);
            if (!result) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something went wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                return {
                    options: [],
                    hasMore: false
                }
            }
            return {
                options: result?.[type] ?? [],
                hasMore: false
            }
        }
        else {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const {
        bankingBasicDetails = null,
    } = data;

    return (
        <div>
            <Box className={classes.imagebox1}>
                <CustomTypography>Banking Basic Details</CustomTypography>
                <Grid container rowSpacing={1} columnSpacing={3}>
                    <Grid item xs={12} sm={4} md={3} lg={2.7}>
                        <ToggleButtonComponent
                            label={"Account Type"}
                            isrequired
                            options={bankAccountTypeOptions}
                            value={bankingBasicDetails?.bankAccountType ?? []}
                            onChange={(value) => updateState('bankingBasicDetails', 'bankAccountType', value)}
                            isMulti={false}
                            fullWidth={false}
                            buttonStyle={buttonStyle}
                            isError={error?.bankingBasicDetails?.bankAccountType?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.bankAccountType?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            isrequired
                            label="Account Number"
                            placeholder="Enter Account Number"
                            value={bankingBasicDetails?.accountNumber ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "accountNumber", e.target.value)}
                            isError={error?.bankingBasicDetails?.accountNumber?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.accountNumber?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            isrequired
                            label="Bank Name"
                            placeholder="Enter Bank Name"
                            value={bankingBasicDetails?.bankName ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "bankName", e.target.value)}
                            isError={error?.bankingBasicDetails?.bankName?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.bankName?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            isrequired
                            label="Branch Name"
                            placeholder="Enter Branch Name"
                            value={bankingBasicDetails?.branchName ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "branchName", e.target.value)}
                            isError={error?.bankingBasicDetails?.branchName?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.branchName?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            isrequired
                            label="Address 1"
                            placeholder="Enter Address  1"
                            value={bankingBasicDetails?.addressLineOne ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "addressLineOne", e.target.value)}
                            isError={error?.bankingBasicDetails?.addressLineOne?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.addressLineOne?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2.7}>
                        <TextBox
                            {...textBoxStyle}
                            // isrequired
                            label="Address  2"
                            placeholder="Enter Address 2"
                            value={bankingBasicDetails?.addressLineTwo ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "addressLineTwo", e.target.value)}
                            isError={error?.bankingBasicDetails?.addressLineTwo?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.addressLineTwo?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            isrequired
                            label={'City'}
                            placeholder={'Select City'}
                            options={[]}
                            value={bankingBasicDetails?.city ?? null}
                            onChange={(e) => updateState('bankingBasicDetails', 'city', e.target.value)}
                            isError={error?.bankingBasicDetails?.city?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.city?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <SelectBox
                            isRequired={true}
                            label={'Country '}
                            placeholder={'Select Country'}
                            // options={[]}
                            loading={loading === "country"}
                            loadOptions={(search, array) => loadOptionData(search, array, "country")}
                            isPaginate
                            debounceTimeout={800}
                            value={bankingBasicDetails?.country ?? null}
                            onChange={(value) => updateState('bankingBasicDetails', 'country', value)}
                            isError={error?.bankingBasicDetails?.country?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.country?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <SelectBox
                            isRequired={true}
                            label={'Bank Routing Type'}
                            placeholder={'Select Bank Routing Type'}
                            // options={[]}
                            loadOptions={(search, array) => loadOptionData(search, array, enum_types.bank_routing_type)}
                            isPaginate
                            debounceTimeout={800}
                            loading={loading === enum_types.bank_routing_type}
                            isSearchable={false}
                            value={bankingBasicDetails?.bankRoutingType ?? null}
                            onChange={(value) => updateState('bankingBasicDetails', 'bankRoutingType', value)}
                            isError={error?.bankingBasicDetails?.bankRoutingType?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.bankRoutingType?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            isrequired
                            label="Bank Routing Code"
                            placeholder="Enter Bank Routing Code"
                            value={bankingBasicDetails?.bankRoutingCode ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "bankRoutingCode", e.target.value)}
                            isError={error?.bankingBasicDetails?.bankRoutingCode?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.bankRoutingCode?.errorMsg}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={2.7}>
                        <SelectBox
                            isRequired={true}
                            label={'Account Currency'}
                            placeholder={'Select Currency'}
                            // options={[]}
                            loading={loading === "currency"}
                            loadOptions={(search, array) => loadOptionData(search, array, "currency")}
                            isPaginate
                            debounceTimeout={800}
                            value={bankingBasicDetails?.currency ?? null}
                            onChange={(value) => updateState('bankingBasicDetails', 'currency', value)}
                            isError={error?.bankingBasicDetails?.currency?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.currency?.errorMsg ?? ''}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            isrequired
                            label="Preferred Cash Collection Office"
                            placeholder="Enter Preferred Cash Collection Office"
                            value={bankingBasicDetails?.preferredCashCollectionOffice ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "preferredCashCollectionOffice", e.target.value)}
                            isError={error?.bankingBasicDetails?.preferredCashCollectionOffice?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.preferredCashCollectionOffice?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            isrequired
                            label="Cheque Payment beneficiary Name"
                            placeholder="Enter Cheque Name"
                            value={bankingBasicDetails?.chequeName ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "chequeName", e.target.value)}
                            isError={error?.bankingBasicDetails?.chequeName?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.chequeName?.errorMsg}
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}