import CloseIcon from '@mui/icons-material/Close';
import {
  Box, Button, Dialog, Grid, IconButton, Typography
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { ApplyDiscount, ContactList, LoadingSection, QuotationSummery } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { CreateQuotationListContext } from "../../../contexts/createQuotationContext";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import useDimensions from "../../../utils/useWindowDimensions";
import { UnitSingleDetails } from "../../propertyFinder3/component/singleUnitDetail";
import { UnitCard } from "../../propertyFinder3/component/unitCard";
import { QuotationPreviewStyles as useStyles } from "./styles";
import { TemplateComponent } from '../component'

export const Preview = (props) => {
  const classes = useStyles();
  const { dataNew, next, previous, setDataNew, createShortlist } = React.useContext(CreateQuotationListContext);
  const [openUnit, setOpenUnit] = React.useState(false);
  const alert = React.useContext(AlertContext);
  const [selectedUnit, setSelectedUnit] = React.useState("")
  const [selectedUnitDetails, setSelectedUnitDetails] = React.useState({})
  const [unitDetails, setUnitDetails] = React.useState([])
  const [disable, setDisable] = React.useState(false)
  const [totalValue, setTotalValue] = React.useState({
    totalAmount: 0,
    totalrefundableTax: 0,
    totalTax: 0,
    totalDiscount: null
  })
  const history = useHistory();
  const size = useDimensions()
  const [loading, setLoading] = React.useState(true)
  //get unit pricing
  const getUnitPricing = (unit_id) => {
    setDisable(true)
    if (dataNew?.units?.length > 0) {

      const datas = {
        tenantId: `${config.tenantId}`,
        unitId: unit_id,
        startDate: dataNew?.lease_start_date,
        endDate: dataNew?.lease_end_date,
        gracePeriod: dataNew?.quotation_grace_period,
        revenue_type: dataNew?.revenue_type?.value
      }

      NetworkCall(
        `${config.api_url}/quotation/unitprice`,
        NetWorkCallMethods.post,
        datas,
        null,
        true,
        false
      )
        .then((response) => {
          let result = response?.data?.data?.units?.map((val) => {
            return {
              id: val?.id,
              name: val?.name,
              unit_no: val?.unitNo,
              total_area: val?.totalarea,
              area_metric: val?.areaMetric,
              total_bed_rooms: val?.totalbedroom,
              total_baths: val?.baths,
              unit_type: val?.unitType,
              discount: 0,
              discountValue: 0,
              room_rent: val?.totalPricing,
              url: val?.logo,
              pricing: val?.pricing?.map((val) => {
                return { ...val, discountedPrice: 0, discountValue: 0, fixTax: val?.appliedTaxAmount, refundableAmount: 0, vat_id: val?.vatGroup?.VGMid }
              }),
              total: val?.total,
              totalDiscount: 0,
              totalAmount: val?.totalPricing,
              fixTotal: val?.totalPricing,
              totalrefundableTax: val?.totalrefundableTax,
              totalDiscountValue: null,
              discountPrice: 0,
              discountedPrice: 0,
              appliedTaxAmount: 0,
              blockid: val?.blockid,
              propertyid: val?.propertyid,
              floorid: val?.floorid
            }

          })

          setUnitDetails(result)
          setTotalValue({
            totalAmount: response?.data?.data?.totalAmount,
            totalrefundableTax: response?.data?.data?.totalrefundableTax,
            totalTax: response?.data?.data?.totalTax,
            totalDiscount: 0
          })
          setLoading(false)
          setDisable(false)
        }).catch((err) => {
          console.log(err)
          setLoading(false)
          setDisable(false)
        })
    }
  }
  const getTotal = (data) => {
    // get total count
    let totalCount = data.reduce(function (prev, current) {
      return prev + +current.percentage_value;
    }, 0);

    return totalCount;
  };
  React.useEffect(() => {
    //initial load
    getUnitPricing(dataNew?.units?.map((val) => val?.id))
    // eslint-disable-next-line
  }, [])
  //on delete unit
  const onDelete = (id) => {
    if (unitDetails?.length > 1) {
      const deleted = unitDetails.filter((ids, index) => index !== id)
      getUnitPricing(deleted?.map((val) => val.id))
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.info,
        msg: "Cannot Delete",
      });
    }
  }
  //on open unit Details
  const openUnitDetails = (data) => {
    setSelectedUnitDetails(data)
  }
  //on apply btn clicked
  const applyDiscount = (data, total, unitId, discountValue) => {

    const edited = unitDetails.map((item) =>
      item.id === unitId ? { ...item, totalDiscount: total, totalDiscountValue: discountValue, totalAmount: total, discountedPrice: item?.fixTotal - total } : item
    );
    const discountTotal = edited?.map(i => i?.discountedPrice).reduce((a, b) => a + b, 0);
    // const refunTotal = edited?.flatMap(v => v?.pricing).filter(f => f.refundable).map(r => r?.discountPrice ?? r?.componentvalue).reduce((a, b) => a + b, 0);
    setTotalValue({ ...totalValue, totalDiscount: discountTotal })
    setUnitDetails(edited)
    setOpenUnit(false)


  }

  //on submit
  const onSubmit = () => {
    if( dataNew?.revenue_type?.value === "Sale" && dataNew?.quotation_payment_peroid?.value === "Milestone Based"){
      if(!dataNew?.typeValue.length > 0){
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please Fill Milestone Configuration",
        });
        return false
      }
      if (dataNew?.typeValue === "amount" && (dataNew?.mailstoneLists?.[0]?.percentage_value?.length === 0 || dataNew?.mailstoneLists?.[0]?.percentage_value === "0" || dataNew?.mailstoneLists?.[0]?.percentage_value === 0 || !dataNew?.mailstoneLists?.[0]?.percentage_value.length > 0 )) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please Enter Valid Milestone Configuration",
        });
        return false
      }
      if(dataNew?.typeValue === "variable"){
        const totalCount = getTotal(dataNew?.mailstoneLists)
        const totalAmount = (totalValue?.totalTax + totalValue?.totalAmount) - totalValue?.totalDiscount
        if (totalAmount !== totalCount) {
  
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please Enter Valid Milestone Configuration",
          });
          setDataNew({
            ...dataNew,
            isValid: false
          })
          return false
        }
      }
    }


    setDisable(true)
    let discoutValue = unitDetails?.map(i => i?.pricing).flat().map(val => parseInt(val?.discountValue)).reduce((a, b) => a + b, 0)
    let units = unitDetails?.map((val) => {
      return {
        "unit_id": val?.id,
        "unit_total_primary": val?.totalAmount,
        "unit_total_refundable": val?.unitTotalRefundable,
        "unit_total_others": null,
        "total_rent_breakup": null,
        "total_rent_discounts": null,
        "total_rent_security_deposit": null,
        "total_rent_tax": null,
        "total_rent_extended": null,
        "area_metric": val?.areaMetric,
        "property_id": val?.propertyid,
        "block_id": val?.blockid,
        "floor_id": val?.floorid
      }
    })
    let refundable = unitDetails?.map(i => i?.pricing).flat().map(val => {
      let applied_component_value = (val?.discountPrice ? val?.discountPrice : val?.componentvalue) + (val?.appliedTaxAmount ? val?.appliedTaxAmount : val?.fixTax);
      return {
        "unit_id": val?.unitid,
        "rent_breakup_id": val?.PCid,
        "rent_amount": val?.discountPrice ?? val?.componentvalue,
        "primary": val?.primary,
        "refundable": val?.refundable,
        "taxable": val?.taxable,
        "is_one_time": val?.isOnetime,
        "display_percentage": val?.discountValue,
        "payment_period": val?.paymentPeriod,
        "company_id": dataNew?.company,
        "tax": val?.taxPercentage ? val?.appliedTaxAmount === 0 ? val?.fixTax : val?.appliedTaxAmount : 0,
        "tax_percentage": val?.taxPercentage,
        "before_rent_amount": val?.componentvalue,
        "before_tax": val?.fixTax,
        "component_value": val?.value,
        "applied_value": applied_component_value,
        "vat_group_id": val?.vat_id,
        "is_quantity": val?.isQuantity,
        "quantity": val?.quantity,
        "value_basis_type": val?.valueBasisType
      }
    })
    let milestone_item = []

    if(dataNew?.revenue_type?.value === "Sale" && dataNew?.quotation_payment_peroid?.value === "Milestone Based"){
      if (dataNew?.typeValue === "amount") {
        milestone_item = dataNew?.mailstoneLists?.map((x , i)=>{
          return{
            milestone_item_id: x?.id,
            value: i === 0 ? x.percentage_value ? Number(x?.percentage_value) : 0 : x.percentage ? Number(x?.percentage) : 0,
            value_type: i === 0 ? "amount" : "percentage"
          }
        })
      }
      if (dataNew?.typeValue === "variable") {
        milestone_item = dataNew?.mailstoneLists.map((x) => {
          return {
            milestone_item_id: x?.id,
            value: x?.percentage_value ? Number(x?.percentage_value) : 0,
            value_type: x?.value_type
          }
        })
      }
      if (dataNew?.typeValue === "percentage") {
        milestone_item = dataNew?.mailstoneLists?.map((x , i)=>{
          return{
            milestone_item_id: x?.id,
            value: x.percentage ? Number(x?.percentage) : 0,
            value_type: "percentage"
          }
        })
      }
    }




    let payload = {
      "tenantId": `${config.tenantId}`,
      "lead_id": dataNew.type === "existing lead" ? dataNew?.lead_details?.id : dataNew?.lead_id,
      "grace_period": dataNew?.quotation_grace_period?.length > 0 ? dataNew?.quotation_grace_period : null,
      "total_amount": totalValue?.totalAmount,
      "description": dataNew?.quotation_description,
      "total_discount": totalValue?.totalDiscount,
      "total_refundable": totalValue?.totalrefundableTax,
      "total_tax": totalValue?.totalTax,
      "lease_period": dataNew?.quotation_lease_duration?.select ?? null,
      "lease_duration": dataNew?.quotation_lease_duration?.value ?? null,
      "lease_start_date":moment(dataNew?.lease_start_date).format('YYYY-MM-DD'),
      "lease_end_date": moment(dataNew?.lease_end_date).format('YYYY-MM-DD'),
      "quote_start_date": new Date(),
      "discount_percentage": discoutValue,
      "tax_percentage": discoutValue,
      "unit_usage": dataNew?.searchdata?.property_purpose,
      "company_id": dataNew?.company,
      "grace_period_type": "Days",
      "apply_grace_period": dataNew?.apply_grace_peroid?.length > 0 ? dataNew?.apply_grace_peroid : null,
      "revenue_type": dataNew?.searchdata?.revenue_type,
      "units": units,
      "rental_breakup": refundable,
      "billing_start_date": dataNew?.quotation_billing_start_date?.length !== 0 ? moment(dataNew?.quotation_billing_start_date).format('YYYY-MM-DD') : null,
      "bill_generation": dataNew?.quotation_billing_cycle ?? null,
      "billing_day": dataNew?.quotation_custom_date?.length !== 0 ? dataNew?.quotation_custom_date : null,
      "billing_cycle_date": dataNew?.quotation_billing_cycle_date === "As per the Agreement Date" ? dataNew?.quotation_billing_cycle_date : "Custom Date",
      "lease_payment_period": dataNew?.quotation_payment_peroid?.value,
      "total_quote_amount": (totalValue?.totalTax + totalValue?.totalAmount) - totalValue?.totalDiscount,
      "revenue_based_lease": dataNew?.quotation_revenue_based_lease,
      "payment_mode": dataNew?.quotation_payment_option?.value,
      "auto_renewal_escalation": dataNew?.quotation_renewal_based_on?.value,
      "auto_renewal": dataNew?.quotation_auto_renewal,
      "increment_escalation": (dataNew?.quotation_escalculation_percent !== null && dataNew?.quotation_escalculation_percent?.length > 0) ? dataNew?.quotation_escalculation_percent : null,
      "include_market": dataNew?.quotation_include_market_place,
      "open_agreement": dataNew?.convert_to_open_agreement?.is_active,
      "milestone_template": dataNew?.delivery_timestone_template?.value ?? null,
      "contract_start_date": dataNew?.quotation_contract_start_date?.length !== 0 && dataNew?.revenue_type?.value === "Sale" ? dataNew?.quotation_contract_start_date : undefined,
      "occpation_date": dataNew?.quotation_handover_date?.length !== 0 && dataNew?.revenue_type?.value === "Sale" ? dataNew?.quotation_handover_date : undefined,
      "milestone_item": milestone_item ?? []

    }

    NetworkCall(
      `${config.api_url}/quotation/create`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setDataNew({ ...dataNew, pdf: response.data.data })
        createShortlist(dataNew.type === "existing lead" ? dataNew?.lead_details?.id : dataNew?.lead_id, unitDetails)
        setDisable(false)

        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Quotation Create Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });

        next()

      }).catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        setDisable(true)
      })


  }


  return (
    <>
      <Box padding="16px 8px 16px 16px">
        <Grid container spacing={1} className={classes.root}>
          {/*lead details*/}
          <Grid item xs={3.5} p={1} >
            <Typography className={classes.title}>Opportunity Details</Typography>

            {dataNew.type === "new lead" && <ContactList data={{
              name: dataNew?.name,
              img: dataNew?.image_url?.src,
              phone: dataNew?.mobile?.mobile,
              mail: dataNew?.email_id
            }} />}

            {dataNew.type === "existing lead" && <ContactList
              data={{
                name: dataNew?.lead_details?.contact?.first_name,
                img: dataNew?.lead_details?.contact?.get_assets_url_id,
                arg: dataNew?.lead_details?.lead_no,
                phone: dataNew?.lead_details?.contact?.mobile_no,
                mail: dataNew?.lead_details?.contact?.email,
              }} />}


            <Box height="18px" />
            <Typography className={classes.title}>Quotation Details</Typography>
            <Box height="8px" />
            {
              dataNew?.revenue_type?.value === "Sale" ?
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className={classes.head}>HANDOVER DATE</Typography>
                    <Typography className={classes.sub}>{moment(dataNew?.occpation_date)
                      .tz(moment.tz.guess())
                      .format("DD MMM YYYY")}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.head}>CONTRACT START DATE</Typography>
                    <Typography className={classes.sub}>{moment(dataNew?.quotation_contract_start_date)
                      .tz(moment.tz.guess())
                      .format("DD MMM YYYY")}</Typography>
                  </Grid>

                </Grid>
                :
                <Grid container spacing={2}>
                  <Grid item xs={4}>

                    <Typography className={classes.head}>LEASE START DATE</Typography>
                    <Typography className={classes.sub}>{moment(dataNew?.lease_start_date)
                      .tz(moment.tz.guess())
                      .format("DD MMM YYYY")}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className={classes.head}>LEASE END DATE</Typography>
                    <Typography className={classes.sub}>{moment(dataNew?.lease_end_date)
                      .tz(moment.tz.guess())
                      .format("DD MMM YYYY")}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className={classes.head}>RENT START DATE</Typography>
                    <Typography className={classes.sub}>{moment(dataNew?.quotation_billing_start_date)
                      .tz(moment.tz.guess())
                      .format("DD MMMM YYYY")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box height="6px" />
                    <Typography className={classes.head}>GRACE PERIOD</Typography>
                    <Typography className={classes.sub}>{dataNew?.quotation_grace_period}
                      Days
                    </Typography>
                  </Grid>
                </Grid>
            }

          </Grid>
          {/*unit list*/}
          <Grid item xs={4.5}>
            {
              loading ? <LoadingSection top="30vh" /> :
                <div style={{ height: size?.height - 300, overflow: "scroll", }}>

                  <Typography className={classes.title}>Unit Details</Typography>
                  <Grid container spacing={1}>
                    {
                      unitDetails?.map((val, index) => {
                        return <Grid item xs={6}>
                          <UnitCard
                            data={val}
                            currency={val?.pricing?.[0]?.currencySymbol}
                            delete={true}
                            quotation={true}
                            onDelete={() => onDelete(index)}
                            onClick={(e) => {
                              setOpenUnit(true)
                              setSelectedUnit(e)
                            }}
                            onSelectedUnit={openUnitDetails}

                          />
                        </Grid>
                      })
                    }
                  </Grid>
                </div>
            }

          </Grid>
          {/*quotation summery*/}
          <Grid item xs={4}>
            <Box sx={{ backgroundColor: "#F5F7FAE6", overflow: "auto", height: `calc(100vh-465px)` }}>
              <Typography className={classes.title} >Quotation Summary</Typography>
              <QuotationSummery
                symbol={unitDetails[0]?.pricing?.[0]?.currencySymbol}
                datas={totalValue}
                unit={unitDetails}
                total={(totalValue?.totalTax + totalValue?.totalAmount) - totalValue?.totalDiscount}

              />
            </Box>
            {
             ( dataNew?.revenue_type?.value === "Sale" && dataNew?.quotation_payment_peroid?.value === "Milestone Based") && 
              <TemplateComponent total={(totalValue?.totalTax + totalValue?.totalAmount) - totalValue?.totalDiscount} symbol={unitDetails[0]?.pricing?.[0]?.currencySymbol} />
            }

          </Grid>
        </Grid>
      </Box>
      {/*unit Details*/}
      <Dialog className={classes.dialog} open={openUnit} onClose={() => {
        setOpenUnit(false)
      }} fullWidth maxWidth="md">
        <Box display="flex" alignItems="center" p={1} className={classes.titleBar}>
          <Box flexGrow={1}>
            <Typography className={classes.detailTitle}>Unit View</Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setOpenUnit(false)}>
              <CloseIcon style={{ fontSize: "20px" }} />
            </IconButton>
          </Box>
        </Box>
        <Grid container>
          <Grid item xs={6}>
            <UnitSingleDetails
              revenue_type={dataNew?.revenue_type?.value}
              startDate={dataNew?.lease_start_date}
              endDate={dataNew?.lease_end_date}
              grace={dataNew?.quotation_grace_period}
              setUnitDetails={setUnitDetails} dis={true} selectedUnits={selectedUnit} />
          </Grid>
          <Grid item xs={6}>
            <ApplyDiscount
              applyDiscountFun={applyDiscount}
              data={selectedUnitDetails?.pricing}
              onClose={() => setOpenUnit(false)}
              unit={selectedUnitDetails}
              totalValues={totalValue}
            />
          </Grid>
        </Grid>

      </Dialog>
      {/*btn container*/}
      <Grid container className={classes.btnroot} spacing={1}>
        <Grid item xs={6}>

          <Button
            className={classes.previousbtn}
            variant="contained"
            onClick={previous}
          >
            Previous
          </Button>

        </Grid>
        <Grid item xs={6} textAlign={"right"} >
          <Button
            className={classes.previousbtn}
            variant="contained"
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
          <Button
            disabled={disable}
            className={classes.submitbtn}
            variant="contained"
            onClick={onSubmit}
          >
            Submit

          </Button>
        </Grid>
      </Grid>
    </>

  );
};
