
export const WaitingForApprovalPath = ["image", "reference_id", "vehicle_type", "vehicle_number", "entry_gate", "requested_by", "unit_no", "requested_slot_date", "request_from", "request_to"];


export const WaitingForApprovalHeading = [
    { title: " ", field: "image" },
    { title: "Date", field: "requested_slot_date" },
    { title: "Reference ID", field: "reference_id" },
    { title: "Name", field: "requested_by" },
    { title: "Unit No", field: "unit_no" },
    { title: "Vehicle Type", field: "vehicle_type" },
    { title: "Vehicle Number", field: "vehicle_number" },
    { title: "Entry Gate", field: "entry_gate" },
    { title: "Start Date", field: "request_from" },
    { title: "End Date", field: "request_to" },
    { title: "", field: "icon" },
];
export const WaitingForApprovalType = [
    { type: ["avatarmanagement"], name: "image" },
    { type: ["text"], name: "requested_slot_date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "requested_by" },
    { type: ["text"], name: "unit_no" },
    { type: ["text"], name: "vehicle_type" },
    { type: ["text"], name: "vehicle_number" },
    { type: ["text"], name: "entry_gate" },
    { type: ["text"], name: "request_from" },
    { type: ["text"], name: "request_to" },
    { type: ["icon"], icon: "icon", is_active: "is_active" },
];


export const ApprovedPath = ["image", "reference_id", "vehicle_type", "vehicle_number", "entry_gate", "requested_by", "unit_no", "requested_slot_date", "allotted_slot"];



export const ApprovedHeading = [
    { title: " ", field: "image" },
    { title: " Date", field: "requested_slot_date" },
    { title: "Reference ID", field: "reference_id" },
    { title: "Vehicle Type", field: "vehicle_type" },
    { title: "Vehicle Number", field: "vehicle_number" },
    { title: "Entry Gate", field: "entry_gate" },
    { title: "Requested By", field: "requested_by" },
    { title: "Unit No", field: "unit_no" },
    { title: "Alloted Slot", field: "allotted_slot" },
    { title: "Start Date", field: "request_from" },
    { title: "End Date", field: "request_to" },
    { title: "", field: "icon" },
];
export const ApprovedType = [
    { type: ["avatarmanagement"], name: "image" },
    { type: ["text"], name: "requested_slot_date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "vehicle_type" },
    { type: ["text"], name: "vehicle_number" },
    { type: ["text"], name: "entry_gate" },
    { type: ["text"], name: "requested_by" },
    { type: ["text"], name: "unit_no" },
    { type: ["text"], name: "allotted_slot" },
    { type: ["text"], name: "request_from" },
    { type: ["text"], name: "request_to" },
    { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const DeclinedPath = ["image", "reference_id", "vehicle_type", "vehicle_number", "entry_gate", "requested_by", "requested_slot_date"];

export const DeclinedRow = [
    {
        id: "",
        reference_id: "PSR123",
        vehicle_type: "Two Wheeler",
        vehicle_number: "TN88AK1234",
        entry_gate: "Any",
        requested_by: "Bala Vignesh, K-F01-U25",
        requested_slot_date: "07 Jan 21, 09:00",
        allotted_slot: "-",
        status: "Declined",
        icon: "view"
    },
    {
        id: "",
        reference_id: "PSR123",
        vehicle_type: "Two Wheeler",
        vehicle_number: "TN88AK1234",
        entry_gate: "Any",
        requested_by: "Bala Vignesh, K-F01-U25",
        requested_slot_date: "07 Jan 21, 09:00",
        allotted_slot: "-",
        status: "Declined",
        icon: "view"
    },
    {
        id: "",
        reference_id: "PSR123",
        vehicle_type: "Two Wheeler",
        vehicle_number: "TN88AK1234",
        entry_gate: "Any",
        requested_by: "Bala Vignesh, K-F01-U25",
        requested_slot_date: "07 Jan 21, 09:00",
        allotted_slot: "-",
        status: "Declined",
        icon: "view"
    },
]

export const DeclinedHeading = [
    { title: " ", field: "image" },
    { title: "Date", field: "requested_slot_date" },
    { title: "Reference ID", field: "reference_id" },
    { title: "Vehicle Type", field: "vehicle_type" },
    { title: "Vehicle Number", field: "vehicle_number" },
    { title: "Entry Gate", field: "entry_gate" },
    { title: "Requested By", field: "requested_by" },
    { title: "Start Date", field: "request_from" },
    { title: "End Date", field: "request_to" },
    { title: "", field: "icon" },
];
export const DeclinedType = [
    { type: ["avatarmanagement"], name: "image" },
    { type: ["text"], name: "requested_slot_date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "vehicle_type" },
    { type: ["text"], name: "vehicle_number" },
    { type: ["text"], name: "entry_gate" },
    { type: ["text"], name: "requested_by" },
    { type: ["text"], name: "request_from" },
    { type: ["text"], name: "request_to" },
    { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const EntriesPath = ["image", "reference_id", "vehicle_type", "vehicle_number", "entry_gate", "requested_by", "unit_no", "allotted_slot", "checked_in", "checked_out", "payment_charges"];

export const EntriesRow = [
    {
        id: "",
        reference_id: "PSR123",
        vehicle_type: "Two Wheeler",
        vehicle_number: "TN88AK1234",
        entry_gate: "3",
        requested_by: "Bala Vignesh, K-F01-U25",
        allotted_slot: "-",
        checked_in: "By Sudharsan N, 28 Jan, 11:48",
        checked_out: "By Sudharsan N, 28 Jan, 11:48",
        payment_charges: "Nil"
    },
    {
        id: "",
        reference_id: "PSR123",
        vehicle_type: "Two Wheeler",
        vehicle_number: "TN88AK1234",
        entry_gate: "3",
        requested_by: "Bala Vignesh, K-F01-U25",
        allotted_slot: "-",
        checked_in: "By Sudharsan N, 28 Jan, 11:48",
        checked_out: "By Sudharsan N, 28 Jan, 11:48",
        payment_charges: "$ 80 (Bill to the unit)"
    },
    {
        id: "",
        reference_id: "PSR123",
        vehicle_type: "Two Wheeler",
        vehicle_number: "TN88AK1234",
        entry_gate: "3",
        requested_by: "Bala Vignesh, K-F01-U25",
        allotted_slot: "-",
        checked_in: "By Sudharsan N, 28 Jan, 11:48",
        checked_out: "By Sudharsan N, 28 Jan, 11:48",
        payment_charges: "$ 80 (Collected)"
    },
]

export const EntriesHeading = [
    { title: " ", field: "image" },
    { title: "Date", field: "requested_slot_date" },
    { title: "Reference ID", field: "reference_id" },
    { title: "Vehicle Type", field: "vehicle_type" },
    { title: "Vehicle Number", field: "vehicle_number" },
    { title: "Entry Gate", field: "entry_gate" },
    { title: "Requested By", field: "requested_by" },
    { title: "Unit", field: "unit_no" },
    { title: "Allotted Slot", field: "allotted_slot" },
    { title: "Checked In", field: "checked_in" },
    { title: "Checked Out", field: "checked_out" },
    { title: "Charges", field: "payment_charges" },
];
export const EntriesType = [
    { type: ["avatarmanagement"], name: "image" },
    { type: ["text"], name: "requested_slot_date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "vehicle_type" },
    { type: ["text"], name: "vehicle_number" },
    { type: ["text"], name: "entry_gate" },
    { type: ["text"], name: "requested_by" },
    { type: ["text"], name: "unit_no" },
    { type: ["text"], name: "allotted_slot" },
    { type: ["text"], name: "checked_in" },
    { type: ["text"], name: "checked_out" },
    { type: ["text"], name: "payment_charges" },
];