import React from "react";
import { withNavBars } from "../../HOCs";
import { VendorCreation } from "./vendorCreation";
import { VendorListing } from "./vendorListing";
import { VendorDetails } from "./vendorDetails";
import { withRouter } from "react-router-dom";
import { Routes } from "../../router/routes";
class VendorMasterParent extends React.Component {
    render() {
        const { match, history } = this.props;
        return (
            <>
                {match.path === Routes.vendorListing && (
                    <VendorListing {...this.props} />
                )}
                {match.path === Routes.vendorCreation && <VendorCreation {...this.props} />}
                {
                    (match.path === Routes.editVendor && history?.location?.state?.from === "edit") ? (
                        <VendorCreation isEdit={true} {...this.props} />
                    ) : (
                        <>
                            {
                                (match.path === Routes.editVendor) && <VendorDetails {...this.props} />
                                // (match.path === Routes.editVendor) && <VendorCreation isEdit={true} {...this.props} />
                            }
                        </>
                    )
                }
            </>
        );
    }
}
const props = {
    boxShadow: false
}

export default withRouter(withNavBars(VendorMasterParent, props));
