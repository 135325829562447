import React, { useState, useContext } from "react";
import { Stepper } from "../../../components";
import {
  againstPropertyStep,
  initialStateProperty,
  praicingJSON,
} from "../common";
import {
  PropertyDetails,
  ComponentType,
  ComponentDetails,
  CompanyPreview,
} from "../stepComponents";
import { Box, Button } from "@mui/material";
import { againstPropertyStyle } from "../style";
import {
  useWindowDimensions,
  AlertProps,
  NetWorkCallMethods,
} from "../../../utils";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";

export const CreateAgainstProperty = (props) => {
  // context
  const alert = useContext(AlertContext);
  //   classes
  const classes = againstPropertyStyle();
  const size = useWindowDimensions();
  // state
  const [step, setStep] = useState(1);
  const [state, setState] = useState({ ...initialStateProperty });
  const [unitDetails, setUnitDetails] = useState([]);

  /**------------------------------function------------------------------------------- */
  //   next
  const next = () => {
    if (step === 1) {
      if (validationPropertyDetails()) {
        setStep(step + 1);
      } else {
        return true;
      }
    }
    if (step === 2) {
      if (validationComponentType()) {
        setStep(step + 1);
      } else {
        return true;
      }
    }
    if (step === 3) {
      if (validationComponentDetails()) {
        setStep(step + 1);
        getDetails();
      } else {
        return true;
      }
    }
  };
  //   back
  const back = () => {
    setStep(step - 1);
  };
  // updateState
  const updateState = (key, value) => {
    let error = state?.error;
    error[key] = "";
    setState({ ...state, [key]: value, error });
  };
  // sumbit
  const sumbit = () => {
    const data = {
      company_id: props?.company?.value,
      component_type_applicablity: {
        is_primary: state?.componentType?.is_primary,
        is_one_time: state?.componentType?.is_one_time,
        is_refundable: state?.componentType?.is_refundable,
        is_item_based_component: state?.componentType?.is_item_based_component,
        is_parking_slot: state?.componentType?.is_parking_slot,
      },
      appraisal_type: state?.amountType?.value,
      new_appraisal_amount: state?.appraisal_amount,
      component_based: state?.Based_On,
      component_revenue_type: state?.revenueType,
      pricing_component_id: state?.component_name?.value,
      effective_start_date: new Date(state?.StartDate),
      applicable_property_ids: state?.property?.map((x) => x?.value),
      unit_category: state?.unitCatergory?.value ?? null,
      unit_purpose: state?.unitPurpose?.value ?? null,
      unit_type_id: state?.unitType?.value ?? null,
      present_specific_value:
        state?.amountType?.value === "Specific" ? state?.amount : null,
      present_range_value:
        state?.amountType?.value === "Range"
          ? `(${state?.minAmount},${state?.maxAmount})`
          : null,
    };
    NetworkCall(
      `${config.api_url}/pricing_appraisal/create`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        props?.onReload();
        setState({ ...initialStateProperty})
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Appraisal Created successfully.",
        });
      })
      .catch((error) => {
        props?.onReload();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Internal error. Please try again later.",
        });
      });
  };
  // validation select component type
  const validationComponentType = () => {
    let isValid = true;
    let error = state?.error;
    if (state?.componentType?.length === 0) {
      isValid = false;
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.info,
        msg: "Please Select Component Type",
      });
    }
    setState({ ...state, error });
    return isValid;
  };
  // validatecompoent Details
  const validationComponentDetails = () => {
    let isValid = true;
    let error = state?.error;
    if (state?.revenueType?.length === 0) {
      isValid = false;
      error.revenueType = "Component Revenue Type is Required";
    }
    if (state?.Based_On?.length === 0) {
      isValid = false;
      error.Based_On = "Component Based On is Required";
    }
    if (state?.component_name?.length === 0) {
      isValid = false;
      error.component_name = "Pricing Component Name On is Required";
    }
    if (state?.amountType?.value === "Range") {
      if (state?.minAmount?.length === 0) {
        isValid = false;
        error.minAmount = "Present Min Amount Value (Range) is Required";
      }
      if (state?.maxAmount < state?.minAmount) {
        isValid = false;
        error.maxAmount =
          "Present Max Amount Value (Range) is Must Grater Than Min Value";
      }
      if (state?.maxAmount?.length === 0) {
        isValid = false;
        error.maxAmount = "Present Max Amount Value (Range) is Required";
      }
      if (state?.maxAmount <= state?.minAmount) {
        isValid = false;
        error.maxAmount = "Max Amount (Range) must be greater than Min Amount";
      }
    } else {
      if (state?.amount?.length === 0) {
        isValid = false;
        error.amount = "Present Amount Value (Range) is Required";
      }
    }
    if (state?.appraisal_amount?.length === 0) {
      isValid = false;
      error.appraisal_amount = "New Appraisal Amount is Required";
    }
    if (state?.StartDate?.length === 0) {
      isValid = false;
      error.StartDate = "Effective Start Period is Required";
    }
    if (state?.amountType?.length === 0) {
      isValid = false;
      error.amountType = "amountType";
    }
    setState({ ...state, error });
    return isValid;
  };
  // validate Property Details
  const validationPropertyDetails = () => {
    let isValid = true;
    let error = state?.error;
    if (state?.type?.length === 0) {
      isValid = false;
      error.type = "Property Type is Required";
    }
    if (state?.property?.length === 0) {
      isValid = false;
      error.property = "Property is Required";
    }
    if (state?.unitPurpose?.length === 0) {
      isValid = false;
      error.unitPurpose = "Unit Purpose is Required";
    }
    if (state?.unitCatergory?.length === 0) {
      isValid = false;
      error.unitCatergory = "Unit Catergory is Required";
    }

    if (state?.unitType?.length === 0) {
      isValid = false;
      error.unitType = "Unit Type is Required";
    }
    setState({ ...state, error });
    return isValid;
  };
  // get pricing units details by filter of appraisal details
  const getDetails = () => {
    const data = {
      company_id: props?.company?.value,
      component_type_applicablity: {
        is_primary: state?.componentType?.is_primary,
        is_one_time: state?.componentType?.is_one_time,
        is_refundable: state?.componentType?.is_refundable,
        is_item_based_component: state?.componentType?.is_item_based_component,
        is_parking_slot: state?.componentType?.is_parking_slot,
      },
      based_on: state?.Based_On,
      component_id: state?.component_name?.value,
      properties: state?.property?.map((x) => x?.value),
      existing_price:
        state?.amountType?.value === "Specific" ? state?.amount : undefined,
      start: state?.amountType?.value === "Range" ? state?.minAmount : undefined,
      end: state?.amountType?.value === "Range" ? state?.maxAmount : undefined,
    };
    NetworkCall(
      `${config.api_url}/pricing_appraisal/get_by_filter`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setUnitDetails(response?.data?.data);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Internal error. Please try again later.",
        });
      });
  };

  const components = {
    1: (
      <PropertyDetails
        updateState={updateState}
        state={state}
        company={props?.company}
      />
    ),
    2: (
      <ComponentType
        handleChange={(e, value) => updateState("componentType", value)}
        list={praicingJSON ?? []}
        selectedComponent={state?.componentType?.id}
      />
    ),
    3: (
      <ComponentDetails
        updateState={updateState}
        state={state}
        company={props?.company}
      />
    ),
    4: <CompanyPreview isProperty state={state} unitDetails={unitDetails} />,
  };

  return (
    <div>
      <Stepper step={step} steps={againstPropertyStep(step)} />
      <Box
        className={classes.popUpParent}
        style={{ height: size?.height - 300 }}
      >
        {components[step]}
      </Box>
      {/* button */}
      <Box className={classes.btnParent}>
        <Box>
          {step !== 1 && (
            <Button onClick={back} className={classes.Cancel}>
              Back
            </Button>
          )}
        </Box>
        <Button onClick={step === 4 ? sumbit : next} className={classes.next}>
          {step === 4 ? "Create Appriasal" : "Next"}
        </Button>
      </Box>
    </div>
  );
};
