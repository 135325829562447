import { Typography, Box, Button, Stack, Popover } from '@mui/material';
import React from 'react';
import { useStyles } from './style'


export const Tab = ({
  options = [],
  onClick = () => false,
  value = 0,
  t,
  onAssign = () => false,
  onCloseRequest = () => false,
  onMenuClick = () => false,
  is_closeBtn = true,
  moveTo = true
}) => {
  // classes
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box className={classes.root}>
      <div className={classes.tabRoot}>
        {
          options &&
          options?.map((x, i) => {
            return (
              <Box onClick={() => onClick(i)} display="flex" alignItems="center" className={x?.selectBack}>
                {x?.image}
                <Box width={'16px'} />
                <Typography className={x?.className}>{x?.title}</Typography>
                {
                  value === i && <Box className={classes.bottom} />
                }
              </Box>
            )
          })
        }
      </div>
      <Stack spacing={2} direction="row" float="right">
        {moveTo &&
          <Button variant="outlined" aria-describedby={id} onClick={handleClick} className={classes.outline}>{t("Assign")}</Button>
        }
        {/* <Button variant="outlined"   onClick={onAssign} className={classes.outline}>{t("Assign")}</Button> */}
        {
          is_closeBtn && <Button variant="contained" className={classes.closeRequest} onClick={onCloseRequest}>{t("Close_Request")}</Button>
        }

        <Box width={'16px'} />
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.menu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography className={classes.menuItem} onClick={() => {return (onMenuClick("service"), setAnchorEl(null))}}>{t("create_service_inspection_order")}</Typography>
        <Typography className={classes.menuItem} onClick={() => { return (onMenuClick("facility"), setAnchorEl(null)) }}>{t("create_facility_work_order")}</Typography>
        <Typography className={classes.menuItem} onClick={() => { return (onMenuClick("serviceDelivery"), setAnchorEl(null)) }}>{t("service-delivery-order")}</Typography>
        <Typography className={classes.menuItem} onClick={() => { return (onMenuClick("generalDelivery"), setAnchorEl(null)) }}>{t("general-delivery-order")}</Typography>
      </Popover>
    </Box>

  )
}