import React from 'react';
import { useStylesCreation } from "./style";
import { Grid, Button, Box, Typography, Avatar } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


export const AddMemberCard = (props) => {
      const classes = useStylesCreation();
      return (
            // add member box
            <Grid container spacing={3}>
                  {
                        props?.data?.map((x, index) => {
                              return (

                                    <Grid item xs={6} md={3} lg={3}>
                                          <div className={classes.card} style={{ textAlign: 'center' }}>
                                                <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '23px' }}>
                                                      <Box className={classes.managericon}>
                                                            <img src='/images/manager.svg' alt='manager' />
                                                      </Box>
                                                      <Box >
                                                            <Typography className={classes.roles}>{x?.name}</Typography>
                                                      </Box>
                                                </Box>
                                                {
                                                      props?.member?.user_profileByID?.first_name?.length > 0 ? <Box className={classes.selectBox} onClick={() => props?.closeDrawer(props?.member, 'delete')}>
                                                            <Box>
                                                                  <Avatar src={props?.member?.user_profileByID?.image_url ?? ""} className={classes.avatarcard} />
                                                            </Box>
                                                            <Box>
                                                                  <Typography className={classes.select}>{props?.member?.user_profileByID?.first_name ?? ""}</Typography>
                                                            </Box>
                                                      </Box>
                                                            :
                                                            <Button variant='outlined' className={classes.addmember} onClick={() => props?.closeDrawer(x, 'add')}>Add Member</Button>
                                                }

                                          </div>
                                    </Grid>

                              )
                        })
                  }
            </Grid>
      )
}
export const Card = React.forwardRef((props, ref) => {
      const classes = useStylesCreation();
      return (
            <>
            {/* add member card */}
                  {
                        props?.data?.map((x, index) => {
                              return (
                                    <div
                                          id={props?.id}
                                          className={classes.card1} onClick={() => props?.selectUser(x, index)} style={{ cursor: 'pointer', borderBottom: "1px solid #E4E8EE", }}
                                    >
                                          <div className={classes.Cardcontent}>
                                                <Box alignItems="center" display="flex">
                                                      <Box style={{ marginRight: '4px' }}>
                                                            <Avatar src={x?.user_profileByID?.image_url} className={classes.avatarcard} />
                                                      </Box>
                                                      <Box flexGrow={1} marginLeft="10px">
                                                            <Box alignItems="center" display="flex" justifyContent='space-between'>
                                                                  <Box>
                                                                        <Typography
                                                                              variant="subtitle2"
                                                                              className={classes.title1}
                                                                              noWrap
                                                                        >
                                                                              {x?.user_profileByID?.first_name}&nbsp;{x?.user_profileByID?.last_name}
                                                                        </Typography>
                                                                  </Box>
                                                                  <Box>
                                                                        {
                                                                              props?.select?.user_profileByID?.id === x?.user_profileByID?.id && <img src="/images/ticknew.svg" alt="tick_img" />
                                                                        }

                                                                  </Box>

                                                            </Box>
                                                            <Box
                                                                  alignItems="center"
                                                                  display="flex"
                                                                  marginTop="4px"
                                                                  style={{ flexFlow: "wrap !important" }}
                                                                  className={classes.boxes}
                                                            >
                                                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Box>
                                                                              <Typography
                                                                                    variant="subtitle2"
                                                                                    className={classes.sub}
                                                                                    noWrap
                                                                              >
                                                                                    {x?.user_profileByID?.mobile_no_country_code}&nbsp;{x?.user_profileByID?.mobile_no}
                                                                              </Typography>
                                                                        </Box>
                                                                  </div>


                                                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Box className={classes.dot} />
                                                                        <Box>
                                                                              <Typography
                                                                                    variant="subtitle2"
                                                                                    className={classes.sub}
                                                                                    noWrap
                                                                              >
                                                                                    {x?.user_profileByID?.email_id}
                                                                              </Typography>
                                                                        </Box>
                                                                  </div>


                                                            </Box>
                                                      </Box>
                                                      <Box>

                                                      </Box>
                                                </Box>
                                          </div>
                                    </div>
                              )
                        })
                  }

            </>
      )
});

export const Card1 = (props) => {
      const classes = useStylesCreation();
      return (
            // delete member card
            <div
                  className={classes.card1}  style={{ cursor: 'pointer' }}
            >
                  <div className={classes.Cardcontent}>
                        <Box alignItems="center" display="flex">
                              <Box style={{ marginRight: '4px' }}>
                                    <Avatar src={props?.member?.user_profileByID?.image_url} className={classes.avatarcard} />
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                    <Box alignItems="center" display="flex" justifyContent='space-between'>
                                          <Box>
                                                <Typography
                                                      variant="subtitle2"
                                                      className={classes.title1}
                                                      noWrap
                                                >
                                                      {props?.member?.user_profileByID?.first_name}&nbsp;{props?.member?.user_profileByID?.last_name}
                                                </Typography>
                                          </Box>
                                          <Box>
                                                <DeleteOutlineIcon style={{ color: 'red' }} onClick={props?.deleteMember} />
                                          </Box>

                                    </Box>
                                    <Box
                                          alignItems="center"
                                          display="flex"
                                          marginTop="4px"
                                          style={{ flexFlow: "wrap !important" }}
                                          className={classes.boxes}
                                    >
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Box>
                                                      <Typography
                                                            variant="subtitle2"
                                                            className={classes.sub}
                                                            noWrap
                                                      >
                                                            {props?.member?.user_profileByID?.mobile_no_country_code}&nbsp;{props?.member?.user_profileByID?.mobile_no}
                                                      </Typography>
                                                </Box>
                                          </div>


                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Box className={classes.dot} />
                                                <Box>
                                                      <Typography
                                                            variant="subtitle2"
                                                            className={classes.sub}
                                                            noWrap
                                                      >
                                                            {props?.member?.user_profileByID?.email_id}
                                                      </Typography>
                                                </Box>
                                          </div>


                                    </Box>
                              </Box>
                              <Box>

                              </Box>
                        </Box>
                  </div>
            </div>
      )
}