import DoneIcon from '@mui/icons-material/Done';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, Typography } from "@mui/material";
import React from "react";
import { Account } from "../../../assets";
import { TextBox } from "../../../components";
import { componantsStyles } from "./styles";
export const AccountList = ({ value = {}, onChange = () => false, data = [], getAccountList = () => false, disable = false, iconColor = false, color = false }) => {
    const classes = componantsStyles()
    const [open, setOpen] = React.useState(false)
    const onOpen = () => {
        if (!disable) {
            setOpen(!open)
        }
    }
    const onSelectAccount = (val) => {
        onChange(val)
        setOpen(false)
    }
    return (
        <Box className={classes.accountRoot} >
            <Box display="flex" alignItems="center" p={2} onClick={onOpen}>
                <Box className={classes.AccountImg} backgroundColor={color ? color : "#EDE4FE80"}>
                    <Account color={iconColor ? iconColor : "#7B62A1"} />
                </Box>
                <Box flexGrow={1} marginLeft="8px">
                    <Typography className={classes.accountName}>
                        {value?.name}
                    </Typography>
                    <Typography className={classes.accountNo}>
                        {value?.account_no}
                    </Typography>
                </Box>
                <Box>
                    {
                        disable ? "" :
                            <>
                                {
                                    open ?
                                        <KeyboardArrowDownIcon style={{ color: "#4E5A6B" }} />
                                        :
                                        <KeyboardArrowUpIcon style={{ color: "#4E5A6B" }} />
                                }
                            </>
                    }


                </Box>

            </Box>
            {
                open &&
                <Box className={classes.accountDropdownRoot}>
                    <Box p={2}>
                        <TextBox
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            borderRadius="12px"
                            color="#F2F4F7"
                            onChange={(e) => getAccountList(e.target.value)}
                            border="none"
                            placeholder='Search Account'
                            label={false} />
                        <Box height="14px" />
                        <Typography className={classes.title}>LINKED EXISTING ACCOUNTS</Typography>
                        {
                            data?.length > 0 ?
                                <Box className={classes.accountListRoot}>
                                    {
                                        data?.map((val) => {
                                            return (
                                                <Box display="flex" alignItems="center" p={1} onClick={() => onSelectAccount(val)} >
                                                    <Box className={classes.AccountImg1}>
                                                        <Account color="#98A0AC" />
                                                    </Box>
                                                    <Box flexGrow={1} marginLeft="8px">
                                                        <Typography className={classes.accountNamelist}>
                                                            {val?.name}
                                                        </Typography>
                                                        <Typography className={classes.accountNo}>
                                                            {val?.account_no}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        {
                                                            val?.id === value?.id && <DoneIcon fontSize='small' color='primary' />
                                                        }

                                                    </Box>

                                                </Box>
                                            )
                                        })
                                    }
                                </Box>
                                :
                                <Typography className={classes.title} textAlign="center">No Data Found</Typography>


                        }

                    </Box>
                </Box>
            }
        </Box>
    )
}