import React from "react";
import { LeadNew } from './leadnew';
import { withNavBars } from "./../../HOCs";

class LeadNewParent extends React.Component {
  render() {
    return <LeadNew />;
  }
}
const props = {
      boxShadow: false
  }
export default withNavBars(LeadNewParent , props);
