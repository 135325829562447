import React from "react"
import { withNamespaces } from "react-i18next";
import { TableWithPagination,SearchFilter } from "../../../components";
import { domesticContactListDataType, domesticContactListHeading, domesticContactListPath } from "../../../utils";
import { Box ,Grid } from "@mui/material";
const DomesticContactsTable = ({
    handleIcon = () => false,
    list = [],
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    handleSearch=()=>false,
    searchText,
    totalRowsCount="",
    t
}) => {

    const heading = domesticContactListHeading(t)

    return (
        <Box>
            <Grid container>
                <Grid item xs={4}>
                <SearchFilter placeholder={t("searchContacts")} handleChange={(value) => handleSearch(value)} value={searchText} />
                </Grid>
            </Grid>
            <TableWithPagination
                heading={heading}
                rows={list}
                path={domesticContactListPath}
                dataType={domesticContactListDataType}
                showpagination={true}
                tableType="no-side"
                handleIcon={handleIcon}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={totalRowsCount}
                page={page}
                limit={limit}
                height={`calc(100vh - 300px)`}
            />
        </Box>
    )
}
export default withNamespaces("domesticContacts")(DomesticContactsTable); 