import { makeStyles } from "@mui/styles";
import { Bold, SemiBold } from "../../utils";
export const AddressCardStyles = makeStyles((theme) => ({
    addresscardBody: {
        border: "1px solid #071741",
        backgroundColor: "white",
        padding: "12px 12px 12px 0px",
        borderRadius: "4px",
        width: '330px'
    },
    firstSection: {
        display: "flex",
        justifyContent: "space-between"
    },
    subsection1: {
        display: "flex",
        justifyContent: "space-between"
    },
    button: {
        backgroundColor: "#071741",
        color: "white",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        height: "19px",
    },
    buttonText: {
        fontSize: "8px",
        textTransform: "uppercase",
        textAlign: "left",
        fontFamily: Bold,
        // letterSpacing: "0.4px",
        padding: "5px 10px",
    },
    edit: {
        color: "#5078E1",
        fontFamily: SemiBold,
        fontSize: "12px",
        cursor: 'pointer'
    },
    userInfo: {
        fontFamily: Bold,
        color: "#091B29",
        fontSize: "14px",
        marginLeft: "10px",
    },
    userAddressText: {
        fontFamily: SemiBold,
        color: "#98A0AC",
        fontSize: "12px"
    },
    addressList: {
        fontSize: "12px",
        color: "#98A0AC",
        fontFamily: SemiBold,
        margin: "10px 10px 0px 10px",
    }


}))