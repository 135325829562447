import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useStyleTimeLine } from "../styles";

export const CardComponent = ({
  image = "",
  title = "",
  subTitle = "",
  color = "",
  backgroundColor = "",
  is_divider = false,
  border = "none",
  boxShadow = "none",
}) => {
  const classes = useStyleTimeLine({
    color,
    backgroundColor,
    border,
    boxShadow,
  });
  return (
    <div>
      <Box display="flex" alignItems="center" className={classes.cardBox}>
        <Box>{image}</Box>
        <Box ml={1}>
          <Typography className={classes.componentTitle}>
            {title ?? ""}
          </Typography>
          <Typography className={classes.componentsubTitle}>
            {subTitle ?? ""}
          </Typography>
        </Box>
      </Box>
      {is_divider && <Divider />}
    </div>
  );
};
