import { makeStyles } from "@mui/styles";
import { SemiBold } from "../../../utils";

export const StepCardStyle = makeStyles((theme) => ({
    box: {
        background: theme.palette.info.light,
        borderRadius: 4,
        height:320,
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    disabledBox:{
        background: theme.palette.background.tertiary,
        borderRadius: 4,
        height:320,
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    step: {
        background: theme.palette.info.main,
        borderRadius: 4,
        height: 18,
        width: 46,
        padding: "2px 8px",
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    disabledStep:{
        background: theme.palette.background.primary,
        borderRadius: 4,
        height: 18,
        width: 46,
        padding: "2px 8px",
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    stepText: {
        color: "#fff",
        fontSize: 10,
        whiteSpace: "no-wrap"
    },
    content: {
        fontSize: 12,
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    },
    customBtn: {
        background: theme.palette.background.selected,
        borderRadius: 4,
        height: 32,
        padding: "0px 8px",
        textAlign: "center",
    },
    disabledCustomBtn:{
        background: theme.palette.border.secondary,
        borderRadius: 4,
        height: 32,
        padding: "0px 8px",
        textAlign: "center",
    },
    customBtnText: {
        color: theme.palette.primary.main,
        fontSize: 12,
        fontWeight: "bold",
        marginTop: 6,
        whiteSpace: "no-wrap",
        cursor:"pointer"
    },
    disabledCustomBtnText:{
        color: theme.typography.color.tertiary,
        fontSize: 12,
        fontWeight: "bold",
        marginTop: 6,
        whiteSpace: "no-wrap",
        cursor:"pointer"
    },
    step3Text:{
        fontSize:12,
        color:theme.typography.color.tertiary,
        fontWeight:SemiBold
    }
}))

export const PropertyUnitDetailsStyle = makeStyles((theme) => ({
    headCell: {
        padding: "4px",
        background: "#E8E5E5",
        border: `1px solid ${theme.palette.border.primary}`
    },
    headText: {
        fontSize: 10,
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    bodyCell: {
        padding: "4px",
        border: `1px solid ${theme.palette.border.primary}`
    },
    bodyText: {
        fontSize: 10,
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    },
    addBillLine: {
        fontSize: 10,
        color: theme.palette.primary.main,
        fontWeight: "bold",
        padding: "4px",
        cursor: "pointer",
        whiteSpace: "noWrap"
    },
    billLineCell: {
        border: `1px solid ${theme.palette.border.primary}`
    },
    customText: {
        '& .MuiFilledInput-input': {
            // height: "14px !important",
            padding: "0px !important",
            fontSize:10,
            height:35
        }
    }
}))

export const TotalTableStyle = makeStyles((theme) => ({
    normalCell: {
        padding: "4px",
        border: `1px solid ${theme.palette.border.primary}`
    },
    totalCell: {
        padding: "4px",
        background: "#E8E5E5",
        border: `1px solid ${theme.palette.border.primary}`
    },
    font10:{
        fontSize:10,
        color:theme.typography.color.primary,
        fontWeight:"bold",
    },
    font11:{
        fontSize:11,
        color:theme.typography.color.secondary,
        fontWeight:"bold"
    },
    font10sec:{
        fontSize:10,
        color:theme.typography.color.secondary,
        fontWeight:"bold"
    }
}))

export const PricingComponentCardStyle = makeStyles((theme)=>({
    title:{
        fontSize:14,
        fontWeight:SemiBold
    },
    selectedTitle:{
        fontSize:14,
        fontWeight:SemiBold,
        color:theme.palette.primary.main
    },
    content:{
        fontSize:12,
        fontWeight:SemiBold
    },
    selectedContent:{
        fontSize:12,
        fontWeight:SemiBold,
        color:theme.palette.primary.main
    },
}))