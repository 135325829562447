import { Avatar, Box, Typography } from '@mui/material';
import useTheme from "@mui/material/styles/useTheme";
import React from "react";
import Select, { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import SerachIMG from "../../assets/search";
import { Regular, SemiBold } from '../../utils';
import { CustomTypography } from '../filterGenerator/customTypography';
import { Label } from '../label';
import { useStyles } from './style';



const Card = ({ list, props = "" }) => {
    const classes = useStyles()
    return (
        <>
            {props?.selectProps?.memberDropdown === true ?
                <Box display="flex" justifyContent={"space-between"}>
                    <Box display="flex">
                        <Box style={{ marginRight: '4px' }}>
                            <Avatar src={list?.image_url?.length > 0 ? list?.image_url : ""} className={classes.avatar} />
                        </Box>
                        <Box flexGrow={1} marginLeft="10px">

                            <Box>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.title1}
                                    noWrap
                                >
                                    {list?.name} {list?.unit_no && `(${list?.unit_no ?? ""})`}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.sub}
                                    noWrap
                                >
                                    {list?.phone}
                                </Typography>
                            </Box>
                            {list?.email_id &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Box className={classes.dot} />
                                    <Box>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.sub}
                                            noWrap
                                        >
                                            {list?.email_id}
                                        </Typography>
                                    </Box>
                                </div>
                            }
                        </Box>

                    </Box>
                    <Box>

                        {
                            props?.selectProps?.selectedMember?.id === list?.id && <img src="/images/ticknew.svg" alt="tick_img" />
                        }

                    </Box>
                </Box>
                :
                <Box>
                    <Typography className={classes.role_name}>{list.role_name}</Typography>
                </Box>
            }
        </>

    )
}

const Option = ({ children, ...props }) => {
    return (
        <components.Option {...props}>
            <Card list={props?.data
            } props={props} />
        </components.Option>
    );
};

export const MemberCard = React.memo((props) => {

    const {
        key,
        value,
        placeholder,
        loadOptions,
        onChange,
        options,
        loading,
        isReadOnly,
        isMulti,
        debounceTimeout,
        reduceOptions,
        isClearable = true,
        styles = {},
        isPaginate = false,
        label = "",
        error = false,
        isRequired = false,
        noBorder = false,
        selectedMember = "",
        memberDropdown = false
    } = props;

    const theme = useTheme();
    const Control = ({ children, ...props }) => {
        return (
            <components.Control {...props}>
                {
                    noBorder ? "" :
                        <SerachIMG color={"#98A0AC"} />
                }

                {children}
            </components.Control>
        );
    };


    const customStyles = (props) => {
        return {
            control: (base) => ({
                ...base,
                borderColor:
                    props?.noBorder ? "white" : "#E4E8EE",

                borderRadius: 4,
                paddingLeft: props?.noBorder ? 0 : 8,
                '&:not(:focus)': {
                    boxShadow: "none"
                },
                '&:hover': {
                    borderColor:
                        props?.noBorder ? "white" : theme.palette.primary.main,
                },
                ...styles?.control ?? {},
            }),
            valueContainer: (base) => ({
                ...base,
                paddingBlock: 3
            }),
            placeholder: defaultStyles => {
                return {
                    ...defaultStyles,
                    fontFamily: Regular,
                    fontSize: 14,
                    whiteSpace: "nowrap",
                    color: "#98A0AC"
                };
            },
            menu: defaultStyles => {
                return {
                    ...defaultStyles,
                    zIndex: 2,
                    boxShadow: "0px 10px 25px #0000000A",
                    border: "1px solid #E4E8EE",
                    borderRadius: 4,
                    marginTop: "10px",
                    width: "100%",
                };
            },
            option: (defaultStyles, prop) => {
                return {
                    ...defaultStyles,
                    fontFamily: SemiBold,
                    fontSize: 14,
                    cursor: "pointer",
                    ...isSingleStyle(prop),
                };
            },
        }
    };

    const isSingleStyle = (prop) => {
        if (prop.isMulti) return {}
        return {
            color: prop?.isSelected ? "#5078E1" : "#091B29",
            backgroundColor: "transparent",
            '&:hover,&:active': {
                backgroundColor: "transparent",
                color: "#5078E1"
            },
        }
    }


    return (
        <>
            {label && <Label label={label} isRequired={isRequired} />}
            {
                isPaginate ? (
                    <AsyncPaginate
                        key={key}
                        isClearable={isClearable}
                        isSearchable
                        components={{
                            IndicatorSeparator: () => null,
                            Control,
                            Option,
                            MultiValueContainer: () => null,
                        }}
                        value={value}
                        placeholder={placeholder}
                        loadOptions={loadOptions}
                        onChange={onChange}
                        options={options}
                        isLoading={loading}
                        defaultOptions={options}
                        styles={customStyles(props)}
                        isDisabled={isReadOnly}
                        isMulti={isMulti}
                        debounceTimeout={debounceTimeout}
                        reduceOptions={reduceOptions}
                        selectedMember={selectedMember}
                        memberDropdown={memberDropdown}
                        maxMenuHeight="200px"
                    />
                ) : (
                    <Select
                        //isClearable={isClearable}
                        isSearchable
                        components={{
                            IndicatorSeparator: () => null,
                            Control,
                            Option,
                            MultiValueContainer: () => null,
                        }}
                        value={value}
                        placeholder={placeholder}
                        comp
                        options={options}
                        isLoading={loading}
                        onChange={onChange}
                        styles={customStyles(props)}
                        isMulti={isMulti}
                        isDisabled={isReadOnly}
                        selectedMember={selectedMember}
                        memberDropdown={memberDropdown}

                    />
                )
            }
            {error &&
                <CustomTypography fontFamily={Regular} marginTop={theme.spacing(1)} color={theme?.palette?.error?.main} fontSize={12}>{error}</CustomTypography>
            }
        </>

    )
})
