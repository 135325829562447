export const reqHeading = (t) => [
    { title: t("orderId"), field: "reference_id" },
    { title: t("agreementId"), field: "agreement_no" },
    // { title: t("propertyName"), field: "property_name", },
    { title: t("requestedOn"), field: "created_at" },
    { title: t("requested_by"), field: "requested_by" },
    { title: t("requests"), field: "no_of_units", },
    // { title: t("tentative_date"), field: "execution", },
    // { title: t("agreementManager"), field: "agreement_manager", },
    // { title: t("responsiblePerson"), field: "inspection_manager", },
    { title: t("status"), field: "status" },
    // { title: "", field: "" },

]

export const reqType = [
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "agreement_no" },
    { type: ["date"], name: "created_at" },
    { type: ["text"], name: "requested_by" },
    { type: ["text"], name: "no_of_units" },
    // { type: ["date"], name: "execution" },
    // { type: ["text"], name: "agreement_manager" },
    // { type: ["text"], name: "inspection_manager" },
    { type: ["agreement_inspection_status"], name: "status" },
    // { type: ["more_6"], icon: "icon" },
]
export const reqTypePath = ["reference_id",
    //  "date", 
    "property_name", "no_of_units", "created_at", "agreement_manager", "inspection_manager", "status"]

export const StatusOptionList = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Completed', value: 'Completed' },
]