import React from "react";
import { withNavBars } from "../../HOCs";
import { SalesManagerDashboard } from "./salesManagerDashboard";

class SalesManagerDashboardParent extends React.Component {
    render() {
        return <SalesManagerDashboard />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(SalesManagerDashboardParent, props);