import React, { useState, useEffect } from 'react';
import { withNamespaces } from "react-i18next";
import { Subheader, DialogBox, AlertDialog } from "../../components";
import { useHistory, useLocation } from "react-router-dom";
import { Tab } from './components';
import { maintanceRequestTabData, serviceRequestTabData } from '../../utils/requestTableData'
import { useStyles } from './style'
import { RequestView as View, MoveTo } from './steps'
import {
    GET_GENERAL_REQUEST,
    GET_MAINTANCE_REQUEST,
} from "../../graphql/queries";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import {
    NetWorkCallMethods,
    AlertProps,
    accessCheckRender,
    getRoutePermissionNew,
} from "../../utils";
import { AlertContext, AuthContext } from "../../contexts";
import Assign from "../../components/assign";
import { Box, Button, Dialog, Grid, Stack, Typography } from "@mui/material";
import { CloseRequest } from '../maintenancerequest/components/closerequest';
import { useApolloClient } from "@apollo/client";
import { UPDATE_NOTES_MAINTANCE_REQUEST, UPDATE_NOTES_GERNERAL_REQUEST } from '../../graphql/mutations'
import { Close } from '@mui/icons-material';

const RequestView = (props) => {
    const { loading, handleLoading, t } = props;
    const client = useApolloClient()
    const { state } = useLocation();
    // context
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    // classes
    const classes = useStyles()
    // history
    const history = useHistory();
    // state
    const [value, setValue] = useState({
        tabValue: 0,
        assignModal: false,
        closeRequest: false
    })
    const [datas, setDatas] = useState({
        data: {},
        imagesListForView: [],
        is_show: false,
        moveTo: true,
        showServiceCreate: false,
        showComplete: false,
    });
    const [load, setLoad] = useState(false)
    const [notes, setNotes] = useState("")
    const [permission, setPermission] = React.useState({})
    const [serviceDetails, setServiceDetails] = React.useState([])
    // functions
    const updateState = (key, value) => {
        setValue({ ...value, [key]: value })
    }
    //handleClose Assign Modal
    const handleClose = () => {
        setValue({ ...value, assignModal: !value?.assignModal })
    }
    const reload = () => {
        getDetails();
    }
    // get details
    const getDetails = () => {
        const data = {
            query:
                state?.main?.request === "Maintenance" ? GET_MAINTANCE_REQUEST : GET_GENERAL_REQUEST,
            variables: {
                generalRequestNo: state?.main?.value,
                maintenanceRequestNo: state?.main?.value,
                referenceId: state?.main?.value,
            },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                const tempImageList = response?.data?.data?.images?.map(
                    ({ url }) => url
                );
                setDatas({
                    ...datas,
                    data: response?.data?.data ?? {},
                    imagesListForView: tempImageList,
                    is_show: true,
                    showServiceCreate:false,
                    agreement_inspection:response?.data?.data?.agreement_inspection ?? []
                });
                if(response?.data?.data?.agreement_inspection?.length>0){
                    getServiceDetails()
                }
                handleLoading(false)
            })
            .catch((err) => {
                handleLoading(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something went wrong please try again",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            });
    };
    const noteChange = (value) => {
        setNotes(value)
    }
    // reAssign
    const reAssign = (payload) => {

    }
    const closeRequestDialog = () => {
        setValue({ ...value, closeRequest: !value?.closeRequest })
    }
    const getServiceDetails = () => {
        const payload = {
            "maintanance_request_id": state?.main?.value
        }
        NetworkCall(
            `${config.api_url}/general_inspection/get_request_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setServiceDetails(response?.data?.data)
        })
            .catch(function (error) {
            })
    }
    const MoveToService = () => {
        const payload = {
            "maintanance_request_id": state?.main?.value
        }
        NetworkCall(
            `${config.api_url}/general_inspection/convert_service_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setDatas({ ...datas, showServiceCreate: false, MoveTo: false, showComplete: true })
            getServiceDetails()
            getDetails()
        })
            .catch(function (error) {
                setDatas({ ...datas, showServiceCreate: false, MoveTo: false })
                if (error.response) {
                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: Object.keys(error.response.data.error.message).length !== 0 ? error.response.data.error.message : "Something went wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });

                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Something went wrong please try again",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                }

            });
    }
    const onMenuClick = (value) => {
        if (value === "service") {
            setDatas({ ...datas, showServiceCreate: true })
        }
    }
    const closeShowCreateModal = () => {
        setDatas({ ...datas, showServiceCreate: false })
    }
    const notesUpdate = () => {
        let status = datas?.data?.table?.[0]?.status?.some(x => x?.type === "Closed") ? "Closed" : "Open"
        const initialData = datas?.data?.table?.[0]?.status.find(x => x.type === status)
        const result = { notes: notes, created_at: new Date() }
        client.mutate({
            mutation: state?.main?.request === "Maintenance" ? UPDATE_NOTES_MAINTANCE_REQUEST : UPDATE_NOTES_GERNERAL_REQUEST,
            variables: {
                id: datas?.data?.table?.[0]?.status?.some(x => x.type === "Closed") ? datas?.data?.table?.[0]?.status?.find(x => x.type === "Closed")?.id : datas?.data?.table?.[0]?.status?.find(x => x.type === "Open")?.id,
                payload: {
                    notes: initialData?.notes?.length > 0 ? [...initialData?.notes, result] : [result]
                }
            }
        }).then((response) => {
            dialogClose();
            reload();
            setNotes("")
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Note added SuccessFully",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }).catch((err) => {
            setLoad(true);

        })
    }
    const dialogClose = () => {
        setLoad(!load)
    }
    // components
    const com = {
        0: <View datas={datas} t={t} notesUpdate={notesUpdate} dialogClose={dialogClose} load={load} noteChange={noteChange} notes={notes} type={datas?.data?.images?.length > 0 ? true : false} request={state?.main?.request === "Maintenance" ? true : false}/>,
        1: <MoveTo datas={datas} t={t} serviceDetails={serviceDetails} />,
        2: <MoveTo datas={datas} t={t} serviceDetails={serviceDetails} />,
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getDetails();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, state?.main?.value]);
    const render = () => {
        return (
            <div>
                {/* sub nav bar */}
                <Subheader
                    title={datas?.data?.table?.[0]?.request_no ?? '-'}
                    goBack={() => {
                        history.goBack(-1);
                    }}
                />
                <div>
                    {/* tab component */}
                    <Tab 
                    options={datas?.agreement_inspection?.length>0?serviceRequestTabData(t, value?.tabValue, classes, state?.main?.request === "Maintenance" ? true : false??[]):maintanceRequestTabData(t, value?.tabValue, classes, state?.main?.request === "Maintenance" ? true : false??[])} 
                    value={value?.tabValue} onClick={(e) => updateState('tabValue', e)}  t={t} onAssign={() => handleClose()} onCloseRequest={() => closeRequestDialog()} onMenuClick={onMenuClick} is_closeBtn={!datas?.data?.table?.[0]?.status?.some(x => x?.type === "Closed")} moveTo={state?.main?.request === "Maintenance"?datas?.moveTo:false} />
                    {/* components */}
                    {
                        datas?.is_show && <div className={value?.tabValue === 0 ? classes.root : classes.root1}>
                            {com[value?.tabValue]}
                        </div>
                    }

                    {/* assign */}
                    <DialogBox open={value?.assignModal}
                        onClose={() => handleClose()}
                        header={t("Assign")}
                        maxWidth="sm"
                        component={
                            <Assign
                                agreement_inspection_id={state.agreement_inspection_id}
                                // reassign={true}
                                moduleName={t("Module_Name_Here")}
                                handleClose={handleClose}
                                buttonName={"Assign"}
                                onSubmit={reAssign}
                            />}
                    />
                    {/* close request */}
                    <Dialog open={value?.closeRequest} className={classes.closeDialog}>
                        <CloseRequest
                            value={state?.main?.value}
                            request={state?.main?.request}
                            setRequestclose={() => closeRequestDialog()}
                            id={state?.main?.value}
                            reload={reload}
                        />
                    </Dialog>
                    <AlertDialog open={datas?.showServiceCreate}
                        onClose={() => handleClose()}
                        isnotTitle={true}
                        medium
                        component={
                            <Box className={classes.reqblock} p={1} alignItems="center">
                                <Box display={"flex"} justifyContent="end" onClick={closeShowCreateModal}>
                                    <Close className={classes.closeicon} />
                                </Box>
                                <Grid container mt={5}>
                                    <Grid item xs={4}></Grid>
                                    <Grid item xs={8}>
                                        <Typography className={classes.q1hdr}>
                                            Are you sure you want to create work order request?
                                        </Typography>
                                        <Box mt={1}>
                                            <Typography className={classes.q1subhdr}>
                                                The Process Cannot be reverted once done
                                            </Typography>
                                        </Box>
                                        <Stack mt={5} ml={2} direction="row" spacing={1}>
                                            <Button variant="contained" fullWidth className={classes.yesbtn} onClick={() => MoveToService()}>Yes</Button>
                                            <Button variant="outlined" fullWidth className={classes.nobtn} onClick={closeShowCreateModal}>No</Button>

                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    />
                    <AlertDialog open={datas?.showComplete}
                        // onClose={() => handleClose()}
                        isnotTitle={true}
                        medium
                        component={
                            <Box className={classes.showComplete} p={1} alignItems="center">
                                <Box display={"flex"} justifyContent="end" onClick={() => setDatas({ ...datas, showComplete: false })}>
                                    <Close className={classes.closeicon} />
                                </Box>
                                <Grid container mt={6}>
                                    <Grid item xs={4}></Grid>
                                    <Grid item xs={8}>
                                        <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                            <Typography className={classes.q1hdr}>
                                                Work order Request created SuccessFully
                                            </Typography>
                                        </Box>
                                        <Box mt={3} ml={2} display="flex" justifyContent={"center"} alignItems="center">
                                            <Button variant="contained" className={classes.yesbtn} onClick={() => setDatas({ ...datas, showComplete: false })} sx={{ width: "100px" }}>Okay</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    />
                </div>

            </div>
        )
    }


    return (
        <>
            {accessCheckRender(render, permission, "", loading)}
        </>
    );
}
export default withNamespaces("request")(RequestView);