export const WaitingForApprovalPath = [
  "id",
  "helper_id",
  "helper_name",
  "Profession",
  "category",
  "reference_id",
  "requested_by",
  "unit_no",
  "domestic_help_number",
  "raised_on"
];

export const WaitingForApprovalHeading = [
  { title: "Sno", field: "id", type: "numeric" },
  { title: "Domestic Help ID", field: "helper_id" },
  { title: "Domestic Helper Name", field: "helper_name" },
  { title: "Profession", field: "Profession" },
  { title: "Category", field: "category" },
  { title: "Reference ID", field: "reference_id" },
  { title: "Requested By", field: "requested_by" },
  { title: "Unit No", field: "unit_no" },
  { title: "Domestic Help Number", field: "domestic_help_number" },
  { title: "Raised On", field: "raised_on" },
  { title: "", field: "icon" },
];
export const WaitingForApprovalType = [
  { type: ["increment"], name: "id" },
  { type: ["text"], name: "helper_id" },
  { type: ["text"], name: "helper_name" },
  { type: ["text"], name: "Profession" },
  { type: ["text"], name: "category" },
  { type: ["text"], name: "reference_id" },
  { type: ["text"], name: "requested_by" },
  { type: ["text"], name: "unit_no" },
  { type: ["text"], name: "domestic_help_number" },
  { type: ["text"], name: "raised_on" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const ApprovedPath = [
  "id",
  "helper_id",
  "helper_name",
  "Profession",
  "category",
  "reference_id",
  "requested_by",
  "unit_no",
  "domestic_help_number",
  "raised_on"
];

export const ApprovedHeading = [
  { title: "Sno", field: "id", type: "numeric" },
  { title: "Domestic Help ID", field: "helper_id" },
  { title: "Domestic Helper Name", field: "helper_name" },
  { title: "Profession", field: "Profession" },
  { title: "Category", field: "category" },
  { title: "Reference ID", field: "reference_id" },
  { title: "Requested By", field: "requested_by" },
  { title: "Unit No", field: "unit_no" },
  { title: "Domestic Help Number", field: "domestic_help_number" },
  { title: "Raised On", field: "raised_on" },
  { title: "", field: "icon" },
];
export const ApprovedType = [
  { type: ["increment"], name: "id" },
  { type: ["text"], name: "helper_id" },
  { type: ["text"], name: "helper_name" },
  { type: ["text"], name: "Profession" },
  { type: ["text"], name: "category" },
  { type: ["text"], name: "reference_id" },
  { type: ["text"], name: "requested_by" },
  { type: ["text"], name: "unit_no" },
  { type: ["text"], name: "domestic_help_number" },
  { type: ["text"], name: "raised_on" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const DeclinedPath = [
  "id",
  "helper_id",
  "helper_name",
  "category",
  "reference_id",
  "requested_by",
  "unit_no",
  "domestic_help_number",
  "raised_on"
];

export const DeclinedHeading = [
  { title: "Sno", field: "id", type: "numeric" },
  { title: "Domestic Help ID", field: "helper_id" },
  { title: "Domestic Helper Name", field: "helper_name" },
  { title: "Profession", field: "Profession" },
  { title: "Category", field: "category" },
  { title: "Reference ID", field: "reference_id" },
  { title: "Requested By", field: "requested_by" },
  { title: "Unit No", field: "unit_no" },
  { title: "Domestic Help Number", field: "domestic_help_number" },
  { title: "Raised On", field: "raised_on" },
  { title: "", field: "icon" },
];
export const DeclinedType = [
  { type: ["increment"], name: "id" },
  { type: ["text"], name: "helper_id" },
  { type: ["text"], name: "helper_name" },
  { type: ["text"], name: "Profession" },
  { type: ["text"], name: "category" },
  { type: ["text"], name: "reference_id" },
  { type: ["text"], name: "requested_by" },
  { type: ["text"], name: "unit_no" },
  { type: ["text"], name: "domestic_help_number" },
  { type: ["text"], name: "raised_on" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const EntriesPath = [
  "id",
  "helper_id",
  "helper_name",
  "category",
  "reference_id",
  "requested_by",
  "unit_no",
  "domestic_help_number",
  "raised_on",
  "checked_in_gate",
  "checked_in_by",
  "checked_in",
  "checked_out_by",
  "checked_out_gate",
  "checked_out",
  "start_date",
  "end_date"
];

export const EntriesHeading = [
  { title: "Sno", field: "id", type: "numeric" },
  { title: "Domestic Help ID", field: "helper_id" },
  { title: "Domestic Help Name", field: "helper_name" },
  { title: "Category", field: "category" },
  { title: "Reference ID", field: "reference_id" },
  { title: "Requested By", field: "requested_by" },
  { title: "Unit", field: "unit_no" },
  { title: "Domestic Help Number", field: "domestic_help_number" },
  { title: "Raised On", field: "raised_on" },
  { title: "Checked In Gate", field: "checked_in_gate" },
  { title: "Checked In By", field: "check_in_by" },
  { title: "Checked In Date and Time", field: "checked_in" },
  { title: "Checked Out Gate", field: "checked_out_gate" },
  { title: "Checked Out By", field: "checked_out_by" },
  { title: "Checked Out Date and Time", field: "checked_out" },
  { title: "Start Date", field: "start_date" },
  { title: "End Date", field: "end_date" },
];
export const EntriesType = [
  { type: ["increment"], name: "id" },
  { type: ["text"], name: "helper_id" },
  { type: ["text"], name: "helper_name" },
  { type: ["text"], name: "category" },
  { type: ["text"], name: "reference_id" },
  { type: ["text"], name: "requested_by" },
  { type: ["textLink"], name: "unit_no" },
  { type: ["text"], name: "domestic_help_number" },
  { type: ["text"], name: "raised_on" },
  { type: ["text"], name: "checked_in_gate" },
  { type: ["text"], name: "checked_in_by" },
  { type: ["text"], name: "checked_in" },
  { type: ["text"], name: "checked_out_gate" },
  { type: ["text"], name: "checked_out_by" },
  { type: ["text"], name: "checked_out" },
  { type: ["text"], name: "start_date" },
  { type: ["text"], name: "end_date" },
];
