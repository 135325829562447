import { useApolloClient } from "@apollo/client";
import React from "react";
import { AlertContext } from "../../contexts";
import { GET_ACCESS_GATES_BY_PROPERTY_ID } from "../../graphql/securityManager/queries";
import { AlertProps, InAndOutOption, InOutStatusVW, SemiBold, StayTypetOption } from "../../utils";
import { FilterGenerator } from "../filterGenerator";

export const VisitorSecurityFilter = (
    {
        open = "",
        close = () => false,
        apply = () => false,
        filterData = {},
        clientId = "",
        property = {},
        filterKeys = []
    }
) => {
    const client = useApolloClient()
    const alert = React.useContext(AlertContext)
    // Function to load Async Select Options
    const loadOptions = async (
        search = "",
        array = [],
        handleLoading = null,
        type
    ) => {
        let result, query, offset, limit, variables = 0
        limit = 10
        if (search && !Boolean(array?.length)) { offset = 0 }
        else { offset = array?.length }
        switch (type) {
            case "access_gates_master":
                query = GET_ACCESS_GATES_BY_PROPERTY_ID
                variables = { clientID: clientId, property: property.label === "All Properties" ? property?.value : [property?.value], offset, limit, search }
                result = await networkCallBack(query, variables, handleLoading)
                return {
                    options: [...result?.access_gates_master],
                    hasMore: (array?.length + result?.access_gates_master?.length) < result?.count[0]?.count
                }
            default:
                return { options: [] }
        }
    }

    // Function for network callback for Async Select Options
    const networkCallBack = async (query, variables = {}, handleLoading) => {
        const options = await client.query({
            query, fetchPolicy: "network-only", variables
        }).then((r) => {
            let main = r?.data
            handleLoading()
            return main
        }).catch((e) => {
            handleLoading()
            alert.setSnack({
                ...alert, open: true, msg: "Some Thing Went Wrong",
                severity: AlertProps.severity.error,
            })
            return null
        })
        return options
    }

    const object = {
        unitID: {
            component: "select",
            value: filterData?.unit_id,
            options: [],
            isMulti: true,
            label: "Unit ID",
            state_name: "unit_id",
            debounceTimeout: 800,
            isPaginate: true,
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: SemiBold
        },
        accessGate: {
            component: "select",
            value: filterData?.gate,
            options: [],
            isMulti: true,
            label: "Gate",
            state_name: "gate",
            loadOptions: (search, array, handleLoading) =>
                loadOptions(search, array, handleLoading, "access_gates_master"),
            debounceTimeout: 800,
            isPaginate: true,
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: SemiBold
        },
        status: {
            component: "toggleButton",
            value: filterData?.status,
            options: InAndOutOption,
            isMulti: true,
            state_name: "status",
            label: "Status",
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: SemiBold
        },
        reqDate: {
            component: "minAndmaxDate",
            placeholder: {
                startLabel: "Start",
                endLabel: "End",
            },
            state_name: "createdDate",
            value: filterData?.createdDate,
            minMaxlabel: "Created Date",
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: SemiBold
        },
        checkInDate: {
            component: "minAndmaxDate",
            placeholder: {
                startLabel: "Start",
                endLabel: "End",
            },
            state_name: "checkInDate",
            value: filterData?.checkInDate,
            minMaxlabel: "Check In Date",
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: SemiBold
        },
        checkOutDate: {
            component: "minAndmaxDate",
            placeholder: {
                startLabel: "Start",
                endLabel: "End",
            },
            state_name: "checkOutDate",
            value: filterData?.checkOutDate,
            minMaxlabel: "Check Out Date",
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: SemiBold
        },
        requestedFromDate: {
            component: "minAndmaxDate",
            placeholder: {
                startLabel: "Start",
                endLabel: "End",
            },
            state_name: "requestedFromDate",
            value: filterData?.requestedFromDate,
            minMaxlabel: "Requested From",
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: SemiBold
        },
        requestedToDate: {
            component: "minAndmaxDate",
            placeholder: {
                startLabel: "Start",
                endLabel: "End",
            },
            state_name: "requestedToDate",
            value: filterData?.requestedToDate,
            minMaxlabel: "Requested To",
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: SemiBold
        },
        stayType: {
            component: "select",
            value: filterData?.stayType,
            options: StayTypetOption,
            isMulti: true,
            label: "Stay Type",
            state_name: "stayType",
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: SemiBold
        },
        inOutStatusVW: {
            component: "select",
            value: filterData?.inOutStatus,
            options: InOutStatusVW,
            isMulti: true,
            label: "In/Out Status",
            state_name: "inOutStatus",
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: SemiBold
        },
    }
    const components = () => {
        let data = filterKeys?.map(i => object[i])
        return data
    }
    return (
        <FilterGenerator open={open} onClose={close}
            onApply={(value) => apply(value)}
            components={components()} />
    )
}