import gql from "graphql-tag";
export const GET_COMPANY = gql`
query GET_FILTER_DATA_FOR_PROPERTY_FILTER($userProfileID: String , $clientID:String) {
  client_user_roles(
    where: {
      is_active: { eq: true }
      user_profile_id: { eq: $userProfileID }
      roles: { name: { eq: "Manager" } }
      client:{eq:$clientID}
    }
    offset: 0
    limit: 1000
  ) {
    id
    roles {
      id
      name
    }
    company:company_master {
      id
      name
      company_no
      uom
      payment_period
      currency_id
      currency{
        id
        name
      }
      company_addressByID {
        id
        mobile_no_country_code
        mobile_no
        alternative_mobile_no_country_code
        alternative_mobile_no
        email_id
        website
      }
      propertyByID(offset:0 limit:10000){
          id
          name
      }
    }
  }
}`;

export const GET_ACCOUNTS = (is_active = [], account_type = [], searchText = "") => gql`
query GET_ACCOUNT_LIST(
  $company: Int
  $client: String
  ${is_active?.length > 0 ? `$is_active: [Boolean]` : ``}
  ${account_type?.length > 0 ? `$account_type: [String]` : ``}
  $searchText: String
) {
  count: contact_account(
    where: {
      company_id: { eq: $company }
      client: { eq: $client }
      ${is_active?.length > 0 ? `is_active: { in: $is_active }` : ``}
      ${account_type?.length > 0 ? `relationship: { in: $account_type }` : ``}
      name: { iregex: $searchText }
    }
  ) {
    count: count_id
  }
  contact_account(
    where: {
      company_id: { eq: $company }
      client: { eq: $client }
      ${is_active?.length > 0 ? `is_active: { in: $is_active }` : ``}
      ${account_type?.length > 0 ? `relationship: { in: $account_type }` : ``}
      name: { iregex: $searchText }
    }
    offset: $offset
    limit: $limit
    orderby: { created_at: desc }
  ) {
    id
    account_id: account_no
    name
    acc_pic: logo
    email_id
    relationship
    mobile: mobile_no
    mobile_no_country_code
    is_active
    contact_account_address {
      id
      city
      country
    }
  }
}`

export const GET_ACCOUNT_DETAILS = gql`
query {
  contact_account(where: { id: $id }) {
    id
    created_at
    updated_at
    created_by
    updated_by
    contact_id
     contact {
      label: first_name
      value: id
      contact_id: id
    }
    account_no
    name
    company_id
    company {
      company_ids: id
      company_name: name
      currency {
      id
      name
      symbol
    }
    }
    is_external
    is_active
    property_id
    logo
    mobile_no
    mobile_no_country_code
    email_id
    currency_id
    vat_group_id
    vat_group{
      id
      name:group_name
    }
    client
    relationship
    description
    phone_code
    telephone
    bank_name
    branch_name
    bank_currency_id
    bank_currency:bank_currencyByID{
      id
      name
      symbol
    }
    bank_account_no
    account_type
    routing_code
    routing_type
    cash_collection_office
    cheque_name
    address_1
    address_2
    city
    country_master{
      value:id
      label:country_name
    }
    company_registration
    company_registration_no
    rera_type
    rera_registration_no
    rera_broker_registration_no
    tax_code
    tax_registration_no
    vendor_id
    vendor {
      label: name
      value: id
      vendor_id: id
    }
    provider_id
       provider {
      label: id
      value: name
      provider_id: id
    }
    title
    address: contact_account_address {
      id
      account_id
      door_no
      street_1
      street_2
      street_3: landmark
      area
      city
      state
      country
      zipcode
      is_active
      lat:latitude
      long:longitude
    }
     know_your_client(where:{is_active:true,kyc_type:"Both"}) {
      id
      url
      proof_id
      identification_masterByID{
      value:id
      label:type
       proof_type
      }
      proof_type
      kyc_type
      name
      number
      valid_from
      valid_to
      url
      is_mandatory
      country_id
      country_master{
        label:country_name
        value:id
      }
      is_active
    }
  }
}

`

