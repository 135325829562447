/* eslint-disable array-callback-return */
import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import React from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { SideNavBar } from "..";
import LogOut from "../../../assets/logout";
import MyProfile from "../../../assets/myprofile";
import { DialogBox } from "../../../components";
import { config } from "../../../config";
import {
  AlertContext,
  AuthContext,
  BackdropContext,
  FirebaseContext
} from "../../../contexts";
import { UNREADNOTIFICATION } from "../../../graphql/mutations";
import { NetworkCall } from "../../../networkcall";
import { LoginSuccess } from "../../../router/access";
import { Routes } from "../../../router/routes";
import {
  AlertProps, clearAccess,
  getAccessRoutes, getCompanyOption,
  getDirectRoutePermission, LocalStorageKeys,
  mobileApps,
  NetWorkCallMethods,
  useWindowDimensions,
  timeZoneConverter,
  supporDropdown,
  SemiBold
} from "../../../utils";
import { UserCard } from "../../userRole";
import { NotificationCard } from "./notificationComponent";
import { useStylesTopNavBar } from "./style";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import { Notification } from "../../../assets";
import { DocumentViewer } from "../../../components/fileViewer";
import { useStyles } from "../../../screens/announsmentNew/style";
import { EmailPreview } from "../../../screens/announsmentNew/viewAnnounsment";
const roleOptions = (data) => {
  let arry = [];

  // if (data?.length > 0) {
  //   data?.map((val) => {
  //     let options = {
  //       label: val?.name,
  //       value: val?.id,
  //       ...val,
  //     };
  //     arry.push(options);
  //   });
  // }
  // let role = Object.keys(RolesData).map(v => RolesData[v]);
  // let rolelist = arry.filter((va) => role.includes(va.label)) ?? [];
  // if (rolelist?.length > 0) {
  //   rolelist = rolelist.reduce((unique, o) => {
  //     if (!unique.some(obj => obj.label === o.label && obj.value === o.value)) {
  //       unique.push(o);
  //     }
  //     return unique;
  //   }, []);
  // }

  if (data?.length > 0) {
    data?.map((val) => {
      let options = {
        label: val?.role?.name,
        value: val?.client_roles?.[0]?.id,
        role_id: val?.client_roles?.[0]?.id,
        ...val,
      };
      arry.push(options);
    });
  }
  return arry;
};

const TopNavBar = (props) => {
  const { t, handleLoading } = props;
  const version = localStorage.getItem(LocalStorageKeys.version)
  const auth = React.useContext(AuthContext);
  const alert = React.useContext(AlertContext);
  const firebaseContext = React.useContext(FirebaseContext);
  const classes = useStylesTopNavBar({ ...props, app_Env: config?.app_env.toLowerCase() });
  const classes1 = useStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const [roleID, setRoleID] = React.useState({
    name: localStorage.getItem(LocalStorageKeys.activeRole),
    role_id: localStorage.getItem(LocalStorageKeys.roleID),
  });
  const [state, setState] = React.useState({
    openSideNavBar: false,
  });
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [details, setDetails] = React.useState({
    details: "",
    created_by: ""
  });
  const toogleSideNavBar = () => {
    setState({
      ...state,
      openSideNavBar: !state.openSideNavBar,
    });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationOpen, setNotificationOpen] = React.useState(false);
  const [helpOpen, setHelpOpen] = React.useState(false);
  const [languageOpen, setLanguageOpen] = React.useState(false);
  const [value, setValue] = React.useState("All");
  const [notificationList, setNotificationList] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [company, setCompany] = React.useState({
    companyList: [],
    selectedCompany: {},
  });
  const [pdf, setPdf] = React.useState({
    bool: false,
    data: "",
  });
  const [loading, setLoading] = React.useState(false)
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "All") {
      getNotification(0, false, [
        "Announcement",
        "Push-Notification",
      ], company?.companyList?.map((x) => x?.value));
    } else {
      getNotification(0, false, [newValue], company?.companyList?.map((x) => x?.value));
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const notificationopen = Boolean(notificationOpen);
  const helpopen = Boolean(helpOpen);
  const languageopen = Boolean(languageOpen);
  //const id = open ? "simple-popover" : undefined;
  const backdrop = React.useContext(BackdropContext);

  // Go to profile page
  const profileView = () => {
    history.push(Routes.profileView);
  };

  const changeRole = (e) => {
    handleLoading(true)
    handleCloseModule();
    handleClose();
    let path = LoginSuccess(auth?.auth?.auth?.routes);
    if (path !== "*") {
      localStorage.setItem(LocalStorageKeys.activeRole, e?.name ?? "");
      localStorage.setItem(LocalStorageKeys.activeRoleModuleId, e?.module_id ?? "");
      localStorage.setItem(LocalStorageKeys.roleID, e?.role_id);
      axios
        .get(`${config.authapi}/auth/select_role?role_id=${e.role_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              LocalStorageKeys.authToken
            )}`,
          },
        })
        .then((res) => {
          localStorage.removeItem(LocalStorageKeys.selectedCompany);
          localStorage.setItem(LocalStorageKeys.authToken, res?.data?.token);
          localStorage.setItem(
            LocalStorageKeys.clinetID,
            res?.data?.selected_client?.id
          );
          let access = res?.data?.access;
          let permissionData = Object.keys(access);
          let permissionJson =
            access?.[permissionData?.[0]]?.role?.permissions?.[0]?.structure;
          clearAccess();
          let permisionlist = getAccessRoutes(permissionJson);
          let permissionRoutes = permisionlist?.map((val) => val?.route);
          localStorage.setItem(
            LocalStorageKeys.permission,
            JSON.stringify(permissionRoutes)
          );
          path = LoginSuccess(permissionRoutes);
          history.push(path);
          // auth.setAuth({
          //   ...auth,
          //   auth: {
          //     ...auth.auth.auth,
          //     activeRole: e?.name ?? "",
          //     access: access,
          //     routes: permissionRoutes,
          //     prevHistoryKey: window?.history?.state?.key
          //   },
          // });
        })
        .catch(() => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Sorry You Don't Have Access",
          });
          localStorage.clear();
          history.push(Routes.login);
        });
      setTimeout(() => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      }, 2000);
    } else {
      history.push(path);
    }
    // let pathroute = LoginSuccess(auth?.auth?.auth?.routes);
  };
  // handleCloseModule
  const handleCloseModule = () => {
    setDialogOpen(!dialogOpen);
  };
  const selectRoleFunction = (e) => {
    setRoleID(e);
    changeRole(e);
  };
  const selectAppFunction = (val) => {
    if (val?.link) {
      window.open(val?.link, "_blank");
    }
  };

  // openNotification
  const openNotification = (event) => {
    let company = getCompanyOption(backdrop, auth, alert);
    if (company) {
      setCompany({
        ...company,
        companyList: company?.list,
        selectedCompany: company?.selected,
      });
      getNotification(0, false, ["Announcement", "Push-Notification",], company?.list?.map((x) => x?.value) ?? []);
      setNotificationOpen(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setNotificationOpen(null);
  };
  // openHelp
  const openHelp = (event) => {
    setHelpOpen(event.currentTarget);
  };
  const handleCloseHelp = () => {
    setHelpOpen(null);
  };
  // AS per CR Commented
  // openLanguage
  // const openLanguage = (event) => {
  //   setLanguageOpen(event.currentTarget);
  // };
  const handleCloseLanguage = () => {
    setLanguageOpen(null);
  };
  //get notification
  const getNotification = (offSet, filter, type, company) => {
    if (!filter) {
      setLoading(true)
    }
    const params = {
      offset: offSet,
      limit: 10,
      is_draft: false,
      user_profile_id:
        type !== ["Push-Notification"]
          ? localStorage.getItem(LocalStorageKeys?.profileID)
          : null,
      type: type,
      company_id: company ?? [],
      to_builds: [config.app_x_build],
    };
    NetworkCall(
      `${config.api_url}/notifications/list`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter) {
          setNotificationList(
            notificationList?.concat(response?.data?.data?.response)
          );
        } else {
          setNotificationList(response?.data?.data?.response);
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });
  };
  // fetchMoreData
  const fetchMoreData = () => {
    setOffset(offset + 10);
    getNotification(
      offset,
      true,
      value === "All"
        ? ["Announcement", "Push-Notification"]
        : [value],
      company?.companyList?.map((x) => x?.value)
    );
  };
  
  // read nitification
  const viewNotification = (id) => {
    const variables = {
      query: UNREADNOTIFICATION,
      variables: {
        id: id,
        updatedData: {
          is_read: true,
        },
      },
    };

    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        getDetails(id)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });
  };
  // logout
  const getLogoutdeviceToken = () => {
    const params = {
      token: localStorage.getItem(LocalStorageKeys?.deviceToken),
      app_build: config.app_x_build,
    };
    NetworkCall(
      `${config.api_url}/logout`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        localStorage.clear();
        firebaseContext.deleteToken();
        history.push(Routes.login);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });
  };


  // edit function
  const getDetails = (id) => {
    const payload = {
      id: id ?? "",
      user_profile_id: localStorage.getItem(LocalStorageKeys.profileID),
      company_id: company?.selectedCompany?.value,
    };
    NetworkCall(
      `${config.api_url}/notifications/view`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      setDetails({
        details: res?.data?.data?.notification_details,
        created_by: res?.data?.data?.created_user

      })
      setDialog(true)
    })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong. Please try again",
        });
      });
  };
  
  const openPdf = (value) => {
    setPdf({
      ...pdf,
      data: value,
      bool: !pdf?.bool,
    });
  };


  const openExternalLink = (url, type) => {
    switch (type) {
      case "email":
        // add CC and Subject use commented line 
        // window.open("mailto:email@example.com?subject=[Help]  Base Leisure&cc=email2@example.com;email3@example.com")
        window.open(`mailto:${url}`)
        break;
      case "link":
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
        break;
      default:
        break;
    }
  }

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <img
            className={classes.titleLogo}
            onClick={() => history.push("/")}
            src="/images/propgoto logo-01.svg"
            height="45px"
            alt="logo"
          />
          <Divider
            orientation="vertical"
            flexItem
            className={classes.border1}
            style={{ margin: "24px 16px" }}
          />
          <Typography
            className={classes.titleLogo}
            onClick={() => history.push("/")}
            style={{
              fontSize: "14px",
              color: "#FFFFFF",
              fontFamily:
                SemiBold,
            }}
          >
            Property and Facility Management Solution
          </Typography>
          {(config?.app_env !== undefined && config?.app_env !== "undefined") ?
            <Typography className={classes.env_tag}>
              {config?.app_env ?? ""}
            </Typography> : <></>}
          <div className={classes.grow} />
          <Box>
            {/* For Medium and below deviceses */}
            <Hidden mdDown>
              <div className={classes.titleContainer}>
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={1} justifyContent="center" alignItems="center"
                    divider={<Divider className={classes.divider} variant="middle" orientation="vertical" flexItem />}>
                    {getDirectRoutePermission(auth, Routes.fileManager).read &&
                      <Typography
                        className={classes.textButton}
                        onClick={() => history.push(Routes.fileManager)}>
                        Files
                      </Typography>}
                     {getDirectRoutePermission(auth, Routes.reports).read &&

                      <Typography
                        className={classes.textButton}
                        onClick={() => history.push(Routes.reports)}>
                        Reports
                      </Typography>}
                    <Typography
                      className={classes.textButton}
                      onClick={openHelp}>
                      Support
                    </Typography>
                    <Typography
                      className={classes.textButton}
                    // onClick={openLanguage}
                    >
                      English
                    </Typography>
                  </Stack>
                  <Notification
                    onClick={openNotification} />
                  <Avatar onClick={handleClick}
                    className={classes.username}
                    src={auth?.auth?.auth?.profile_img ?? "-"}
                    alt={auth?.auth?.auth?.first_name ?? " "}
                  />
                </Stack>
              </div>
            </Hidden>

            {/* For Medium and above Device  */}
            <Hidden mdUp>
              <div className={classes.titleContainer}>
                <Stack direction="row" spacing={2}>
                  <div className={classes.user}>
                    <Avatar
                      src={auth?.auth?.auth?.profile_img ?? "-"}
                      alt="user"
                    />

                    <IconButton
                      size="small"
                      className={classes.arrow}
                      onClick={handleClick}
                    >
                      <KeyboardArrowDownOutlined
                        style={{ color: "black" }}
                        id="fade-button"
                        aria-controls={open ? "fade-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      />{" "}
                    </IconButton>
                  </div>
                </Stack>
              </div>
            </Hidden>

            {/* profile switcher */}
            <Menu
              className={classes.menu}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <Grid container width="324px">
                <Grid item xs={12} className={classes.switcher}>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.borders}
                  >
                    <Box>
                      {auth?.auth?.auth?.profile_img ? (
                        <img
                          src={auth?.auth?.auth?.profile_img}
                          className={classes.userimg}
                          alt="user"
                        />
                      ) : (
                        <Avatar className={classes.userimg}>
                          {auth?.auth?.auth?.profile_img}
                        </Avatar>
                      )}
                    </Box>
                    <Box flexGrow={1} marginLeft="12px">
                      <Typography
                        noWrap
                        variant="body2"
                        className={classes.username1}
                      >
                        {auth?.auth?.auth?.first_name ?? " "}&nbsp;
                        {auth?.auth?.auth?.last_name ?? " "}
                      </Typography>
                      <Typography
                        noWrap
                        variant="body2"
                        className={classes.email}
                      >
                        {auth?.auth?.auth?.email_id ?? " "}
                      </Typography>
                      <Typography
                        noWrap
                        variant="body2"
                        className={classes.tag}
                      >
                        &nbsp;{auth?.auth?.auth?.activeRole}&nbsp;
                      </Typography>
                    </Box>
                  </Box>
                  {/* profile switch card*/}
                  <Box >
                    <Button
                      className={classes.switchBtn}
                      onClick={handleCloseModule}
                    >
                      {t("Switch_Workspace")}
                    </Button>
                    <Stack direction={"row"} spacing={"4px"} marginTop={"12px"}
                      justifyContent={"space-between"} alignItems={"center"}>
                      <Typography className={classes.lostLogin}>
                        {t("Last_login")}:{" "}
                        {timeZoneConverter(
                          new Date(auth?.auth?.auth?.last_logged_in)
                        )
                        }
                      </Typography>
                      {version && <Typography className={classes.lostLogin}>
                        {"v-" + version}
                      </Typography>}
                    </Stack>
                  </Box>
                  <Box height={"10px"} />
                </Grid>
                <Grid item xs={12} className={classes.profileCard}>
                  <Grid container spacing={1}>
                    {getDirectRoutePermission(auth, Routes.profileView).read && <Grid
                      item
                      xs={12}
                      onClick={profileView}
                      style={{ cursor: "pointer" }}>
                      <Box display="flex" alignItems="center">
                        <MyProfile />
                        <Typography className={classes.label}>
                          {t("My_Profile")}
                        </Typography>
                      </Box>
                    </Grid>}
                    {/* As per CR commented */}
                    {/* <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <DatePickerManangeInvoice color />
                        <Typography
                          className={classes.label}
                        >
                          {t("Time_entry")}
                        </Typography>
                      </Box>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <LogOut />
                        <Typography
                          className={classes.label2}
                          onClick={getLogoutdeviceToken}
                        >
                          {t("Sign_Out")}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                {/* As per CR Commented */}
                {/* <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.Reference}
                  >
                    <Typography className={classes.anchor}>
                      {t("Privacy_Policy")}
                    </Typography>
                    <Box className={classes.dot} />
                    <Typography className={classes.anchor}>
                      {t("Terms_and_conditions")}
                    </Typography>
                  </Box>
                </Grid> */}
              </Grid>
            </Menu>
            {/* noticfication */}
            <Menu
              className={classes.menu}
              anchorEl={notificationOpen}
              open={notificationopen}
              onClose={handleCloseNotification}
              PaperProps={{ sx: { width: '435px', marginTop: "5px" } }}
            >
              <TabContext value={value} p={0}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    className={classes.tabs}
                  >
                    <Tab label={t("All")} value="All" />
                    {/* As per CR commented */}
                    {/* <Tab
                      label={t("Announcement")}
                      value="Announcement"
                    />
                    <Tab
                      label={t("Notification")}
                      value="Push-Notification"
                    /> */}
                  </TabList>
                </Box>
                <InfiniteScroll
                  dataLength={notificationList?.length}
                  next={fetchMoreData}
                  hasMore={true}
                  height={size?.height - 100}
                >
                  {loading ? (
                    <div className={classes.loader}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className={classes.notificationBox}>
                      {notificationList?.length > 0 ? notificationList?.map((item) => {
                        return (
                          <NotificationCard
                            data={item}
                            onCardClick={viewNotification}
                          />
                        );
                      })
                        :
                        <Box mt={4}>
                          <Typography textAlign={"center"} className={classes.nodataFound}>No data Found</Typography>
                        </Box>
                      }

                    </div>
                  )}
                </InfiniteScroll>
              </TabContext>
            </Menu>
            {/* help */}
            <Menu
              className={classes.menu2}
              anchorEl={helpOpen}
              open={helpopen}
              onClose={handleCloseHelp}
            >
              {
                supporDropdown?.map((e) => {
                  return (
                    <MenuItem>
                      <Typography className={classes.helpText} onClick={() => openExternalLink(e?.link, e?.type)}>
                        {e?.name}
                      </Typography>
                    </MenuItem>
                  )
                })
              }
            </Menu>
            {/* language */}
            <Menu
              className={classes.menu2}
              anchorEl={languageOpen}
              open={languageopen}
              onClose={handleCloseLanguage}
            >
              <MenuItem>
                <Typography className={classes.helpText}>
                  English
                </Typography>
              </MenuItem>
              <MenuItem>
                <Typography className={classes.helpText}>
                  Arabic
                </Typography>
              </MenuItem>
              <MenuItem>
                <Typography className={classes.helpText}>
                  Urdu
                </Typography>
              </MenuItem>
              <MenuItem>
                <Typography className={classes.helpText}>
                  Tamil
                </Typography>
              </MenuItem>
            </Menu>
            {/* client select dialog */}
            <DialogBox
              header={t("Switch_Workspace")}
              onClose={handleCloseModule}
              open={dialogOpen}
              background
              // maxWidth={"md"}
              component={
                <Box style={{ height: size?.height - 200, padding: "0px 16px" }}>
                  <Typography className={classes.text}>{"Modules"}</Typography>
                  <Grid container spacing={1} p={1}>
                    {roleOptions(
                      JSON.parse(
                        localStorage.getItem(LocalStorageKeys.modules)
                      ) ?? []
                    )?.map((val) => {
                      return (
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <UserCard
                            val={val}
                            active={
                              roleID?.role_id === val?.role_id ? true : false
                            }
                            onClick={selectRoleFunction}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Box height={"18px"} />
                  <Divider className={classes.border} />
                  <Typography className={classes.text}>{"Apps"}</Typography>
                  <Grid container spacing={1} p={1}>
                    {mobileApps?.map((val) => {
                      return (
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <UserCard
                            val={val}
                            onClick={() => selectAppFunction(val)}
                            mobApp
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  {/* <Box className={classes.accordionBox} >
                    <Accordion className={classes.accordion} elevation={0} defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "#091B29" }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.text}>{"Modules"}</Typography>
                      </AccordionSummary>
                      <AccordionDetails >
                        <div>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"start"}
                            spacing={2}
                          >
                            {roleOptions(
                              JSON.parse(
                                localStorage.getItem(LocalStorageKeys.modules)
                              ) ?? []
                            )?.map((val) => {
                              return (
                                <UserCard
                                  val={val}
                                  active={
                                    roleID?.role_id === val?.role_id ? true : false
                                  }
                                  onClick={selectRoleFunction}
                                />
                              );
                            })}

                          </Stack>
                          <Grid container spacing={2}>
                            {roleOptions(
                              JSON.parse(
                                localStorage.getItem(LocalStorageKeys.modules)
                              ) ?? []
                            )?.map((val) => {
                              return (
                                <Grid item xs={4} sm={4} md={3} lg={2.5} xl={2.5} >
                                  <UserCard
                                    val={val}
                                    active={
                                      roleID?.role_id === val?.role_id ? true : false
                                    }
                                    onClick={selectRoleFunction}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Divider className={classes.border} />
                    <Grid container spacing={2}>
                      {mobileApps?.map((val) => {
                        return (
                          <Grid item xs={4} sm={4} md={3} lg={2.8} xl={2.8} >
                            <UserCard
                              val={val}
                              onClick={() => selectAppFunction(val)}
                              mobApp
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Accordion className={classes.accordion} elevation={0} defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "#091B29" }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.text}>{"Apps"}</Typography>
                      </AccordionSummary>
                      <AccordionDetails >
                        <div >

                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Box> */}
                  {/* <Box className={classes.popupBoady}>
                    <Button className={classes.switchBtn} onClick={changeRole}>
                      {t("Change_Workspace")}
                    </Button>
                  </Box> */}
                </Box>
              }
            />
          </Box>

          <Drawer
            open={state.openSideNavBar}
            variant={"temporary"}
            anchor="left"
            onClose={toogleSideNavBar}
          >
            <div style={{ width: 240 }}>
              <SideNavBar isMobile={true} />
            </div>
          </Drawer>
        </Toolbar>
      </AppBar>
      <DialogBox
        id={"id_123"}
        open={dialog}
        onClose={() => setDialog(false)}
        handleClose={() => setDialog(false)}
        header={`Announcement`}
        isNormal
        width={"710px !important"}
        maxWidth={"sm"}
        component={
          <Box>
            <EmailPreview details={details?.details} user={details?.created_by} classes={classes1} is_show openPdf={openPdf} />
          </Box>
        }
      />
      <DialogBox
        id={"id_123"}
        open={pdf?.bool}
        onClose={() => openPdf(false)}
        handleClose={() => openPdf(false)}
        header={pdf?.data?.name ?? ""}
        isNormal
        // width={"710px !important"}
        maxWidth={"sm"}
        component={
          <Box>
            <DocumentViewer url={pdf?.data?.url ?? ""} />
          </Box>
        }
      />
    </div >
  );
};
export default withNamespaces("profileswitcher")(TopNavBar);
