import React from "react";
import { withNavBars } from "../../HOCs";
import { OwnerTableView } from "./ownerTableView";

class OwnerTableViewParent extends React.Component {
    render() {
        return <OwnerTableView {...this.props} />
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(OwnerTableViewParent, props);
