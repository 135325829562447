import { Avatar, Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {Regular, SemiBold, timeZoneConverter} from '../../utils'
import { Bold } from "../../utils";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 2px 6px #53668523",
    borderRadius: theme.palette.borderRadius,
    cursor: "pointer",
    border: `1px solid ${theme.palette.border.secondary}`,
    backgroundColor: theme.palette.background.paper,
  },
  Cardcontent: {
    padding: "12px 12px 10px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  title: {
    fontSize: "14px",
    color: theme.typography.color.primary,
    fontFamily: Bold,
    marginTop: "8px",
  },
  name: {
    color: theme.typography.color.primary,
    fontFamily: Bold,
    fontSize: '14px',
  },
  unit: {
    fontSize: "12px",
    color: theme.palette.background.primary,
    fontFamily: Regular,
  },
  time: {
    fontSize: "12px",
    color: theme.palette.background.primary,
    fontFamily: Regular,
  },
  dis: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: SemiBold,
    marginTop: "4px",
  },
  titlecontainer: {
    borderBottom: "1px solid #E4E8EE",
    paddingBottom: "12px",
  },
  like: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: SemiBold,
    display: 'flex',
    alignItems: 'center',
  },
  property: {
    fontSize: "10px",
    color: "#5D427F",
    backgroundColor: "#F1E6FE",
    padding: "2px",
    borderRadius: theme.palette.borderRadius,
    fontFamily: Bold,
  },
  new: {
    fontSize: "10px",
    color: "#FFFFFF",
    backgroundColor: "#FF4B4B",
    padding: "2px",
    borderRadius: theme.palette.borderRadius,
    fontFamily: Bold,
  },
}));
export const CommunityCardComponent = React.forwardRef((props, ref) => {
  const classes = useStyles(props);

  return (
    <div>
      <div
        ref={ref}
        className={classes.card}
        onClick={() => {
          // history.push(Routes.discussions + "?discussionId=" + props.data.id);
          props?.commentShow(props?.data?.id, "first")

        }}
      >
        <div className={classes.Cardcontent}>
          <Box alignItems="center" display="flex">
            <Box>
              <Avatar src={props.data?.image_url} />
            </Box>
            <Box flexGrow={1} marginLeft="10px">
              <Box alignItems="center" display="flex">
                <Box>
                  <Typography variant="subtitle2" className={classes.name}>
                    {props.data.first_name}&nbsp;{props.data?.last_name}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.unit}>
                    {props.data.unit_name}
                  </Typography>
                </Box>
                <Box flexGrow={1} textAlign={"end"}>
                  {props.data.is_new && (
                    <Box marginTop="-22px">
                      <span className={classes.new}>
                        &nbsp;NEW&nbsp;
                      </span>
                    </Box>
                  )}
                </Box>
              </Box>

              <Typography variant="subtitle2" className={classes.time}>
                Posted at
                &nbsp;{timeZoneConverter(new Date(props?.data.created_at))}
              </Typography>
            </Box>
          </Box>
          <div className={classes.titlecontainer}>
            <Typography variant="subtitle2" className={classes.title} style={{ overflowWrap: 'anywhere' }}>
              {props.data.description}
            </Typography>
            <Typography variant="subtitle2" className={classes.dis}>
              {props.data.property_name}
            </Typography>
          </div>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop="6px"
              >
                <Box>
                  <img src="\images\like.svg" alt="like" />
                </Box>
                <Box>
                  <Typography variant="subtitle2" className={classes.like}>
                    &nbsp;{props.data.reactions} Likes
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop="10px"
              >
                <Box>
                  <img src="/images/chat.svg" alt="chat" style={{ marginTop: '8px' }} />
                </Box>
                <Box>
                  <Typography variant="subtitle2" className={classes.like}>
                    &nbsp;{props.data.comments} Comments
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
});
