import React from 'react'
import { Box, Typography, Stack, Avatar, Icon } from '@mui/material'
import { CustomerPopupStyle } from '../style'

export const UnitCard = ({ data = {}, selectedList = {}, click = () => false }) => {
    const classes = CustomerPopupStyle()
    return (
        <Box className={selectedList?.id === data?.id ? classes.selectedBox : classes.box} p={1} m={1} onClick={click}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Avatar src={data?.logo}></Avatar>
                    <Stack>
                        <Typography className={classes.name}>{data?.name}</Typography>
                        <Typography className={classes.desc}> {data?.content} </Typography>
                    </Stack>
                </Stack>
                {selectedList?.id === data?.id && <Icon color='primary'>check_circle</Icon>}
            </Stack>
        </Box>
    )
}