import React from "react";
import { withNavBars } from "../../HOCs";
import { ServiceProviderCreateEdit } from "./serviceProviderCreateEdit";

class ServiceProviderCreateEditParent extends React.Component {
    render() {
        return <ServiceProviderCreateEdit />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ServiceProviderCreateEditParent, props);
