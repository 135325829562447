import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import {
  SideNavBar, TopNavBar
} from '../components';
const drawerWidth = 72;

const useStyles = makeStyles((theme, props) => ({
  root: {
    flexGrow: 1,
    height: "100%"
  },
  content: {
    backgroundColor: theme.palette.background.secondary,
    marginLeft: drawerWidth,
    height: "100vh",
    overflow: props?.boxShadow ? "auto" : "hidden",
    [theme.breakpoints.up("sm")]: {
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      marginLeft: 0,
      width: "100%",
    },

  },
  sideNavbar: {
    [theme.breakpoints.down('md')]: {
      display: "none"
    }
  }
}));


const withNavBars = (Component, params) => (props) => {

  const classes = useStyles();
  const [menuItem, setMenuItem] = React.useState(null);
  const [subMenuItem, setSubMenuItem] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const handleLoading = (value) => { setLoading(value) }
  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      <div className={classes.topNavbar}>
        <TopNavBar
          {...params}
          handleLoading={handleLoading} />

      </div>

      {<div className={classes.sideNavbar}>
        <SideNavBar
          menuItem={menuItem}
          setMenuItem={setMenuItem}
          subMenuItem={subMenuItem}
          setSubMenuItem={setSubMenuItem}
          handleLoading={handleLoading} />
      </div>}
      {/* Content */}
      <div className={classes.content}
        style={{
          padding: params?.master ? "70px 0px 10px 0px" : params?.boxShadow === false ? "70px 0px 0px 0px" : "70px 10px 10px 10px",
        }}
      >
        <Component
          {...props}
          loading={loading}
          handleLoading={handleLoading}>
          {props.children}
        </Component>
      </div>
    </div>
  );
};

export default withNavBars;
