
import { Box, Button } from "@mui/material"
import React from "react"
import { withNamespaces } from "react-i18next"
import { FormGenerator } from "../../components"
import { config } from "../../config"
import { AlertContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { AlertProps, assestType, enum_types, LocalStorageKeys, multiImageUpload, networkCallback, NetWorkCallMethods } from "../../utils"
import { FIleUpload } from "./fileUpload"
import { ToolsMasterStyles } from "./style"
import { initialState, StatusOptionList } from "./toolsMasterUtils"

const AddPopup = ({ t, isEdit = false, company = "", setIsOpen = "", editData = {}, reload = () => false }) => {
    const [img, setImg] = React.useState([]);
    const [data, setData] = React.useState({ ...initialState() })
    const [loading, setLoading] = React.useState(null);
    const alert = React.useContext(AlertContext);
    const classes = ToolsMasterStyles()
    //initialLoad
    React.useEffect(() => {
        if (isEdit === true) {
            setImg(editData.tools_assets)
            setData({
                ...data,
                id: editData?.id,
                cost: editData?.rate,
                is_active: editData?.is_active,
                description: editData?.description,
                name: editData?.name,
                period: {
                    label: editData?.period, value: editData?.period
                },
            });
        }
        // eslint-disable-next-line
    }, [])
    //validateForm
    const validateForm = () => {
        let isValid = true;
        let error = data.error;

        if (data?.name?.length === 0) {
            isValid = false;
            error.name = `${t("toolName")} ${t("isRequired")}`;
        }
        if (data?.cost?.length === 0) {
            isValid = false;
            error.cost = `${t("cost")} ${t("isRequired")}`;
        }
        if (data?.period?.length === 0) {
            isValid = false;
            error.period = `${t("period")} ${t("isRequired")}`;
        }
        if (data?.status?.length === 0) {
            isValid = false;
            error.status = `${t("status")} ${t("isRequired")}`;
        }
        setData({ ...data, error });
        return isValid;
    };
    // img upload func
    const updateImgState = async (data, priority) => {
        const Images = await multiImageUpload(data, { type: assestType?.General_Images }, priority = false)
        let dataImage = ([...img, Images])
        setImg(dataImage)
    }
    //Form Submit
    const handleSubmit = () => {
        const userProfileId = localStorage.getItem(LocalStorageKeys.profileID)
        if (validateForm()) {
            const payload = data?.id ? {
                company_id: company?.value,
                name: data?.name,
                description: data?.description,
                is_active: data?.status,
                currency_id: company?.currency?.id,
                rate: data?.cost,
                period: data?.period?.value,
                assets: img,
                id: data?.id ?? null,
                user_profile_id: userProfileId ?? null
            } : {
                company_id: company?.value,
                name: data?.name,
                description: data?.description,
                is_active: data?.status,
                currency_id: company?.currency?.id,
                rate: data?.cost,
                period: data?.period?.value,
                assets: img,
                user_profile_id: userProfileId ?? null
            }
            NetworkCall(
                `${config.api_url}/tools-master/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    setIsOpen(false)
                    reload()
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: `Tools  Successfully ${isEdit === true ? "Updated" : "Created"}`,
                    });

                }).catch((err) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: `Cannot Be ${isEdit === true ? "Updated" : "Created"}`,
                    });
                })
        }
        else {
            return false
        }
    }
    // uploaded img close func
    const handleImgClose = (value) => {
        const delImg = img.filter((i) => {
            return (
                i.id !== value.id
            )
        })
        setImg(delImg)
    }
    //handle state update
    const updateState = (k, v) => {
        let error = data.error;
        error[k] = "";
        setData({ ...data, [k]: v, error })

    }
    //loadoption
    const loadOptionData = async (search, array, type) => {
        setLoading(type);
        let result;
        let enumTypes = [enum_types.vehicle_master_period_enum]

        if (enumTypes?.includes(type)) {
            result = await networkCallback({ enumName: [type] }, "enum", setLoading);
            if (!result) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something went wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                return {
                    options: [],
                    hasMore: false
                }
            }
            return {
                options: result?.[type] ?? [],
                hasMore: false
            }
        }
        else {
            return {
                options: [],
                hasMore: false
            }
        }
    }


    const changeInput = [
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            component: "text",
            isActive: true,
            label: t("toolName"),
            placeholder: t("enterToolName"),
            value: data?.name,
            onChange: (e) => updateState("name", e.target.value),
            error: data?.error?.name,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "toggle",
            label: t("currentStatus"),
            placeholder: t("status"),
            value: data?.status,
            onChange: (value) => updateState("status", value),
            error: data?.error?.status,
            isRequired: true,
            options: StatusOptionList
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "text",
            label: "Cost",
            placeholder: "Enter Rate",
            value: data?.cost,
            onChange: (value) => updateState("cost", value.target.value),
            error: data?.error?.cost,
            isRequired: true,
            type: "number",
            endAdornment: company?.currency_symbol
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "select",
            label: "Period",
            placeholder: "Select Period",
            value: data?.period,
            onChange: (value) => updateState("period", value),
            error: data?.error?.period,
            isRequired: true,
            loading: loading === enum_types.vehicle_master_period_enum,
            loadOptions: (search, array) => loadOptionData(search, array, enum_types.vehicle_master_period_enum),
            debounceTimeout: 800,
            isPaginate: true,
        },

        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            component: "text",
            isActive: true,
            label: t("description"),
            placeholder: t("enterDescription"),
            value: data?.description,
            onChange: (e) => updateState("description", e.target.value),
            multiline: true
        },

    ]


    return (
        <Box>
            <Box p={2} className={classes.fromSection}>
                <FormGenerator components={changeInput} />
                {/* ImageUpload */}
                <Box >
                    <FIleUpload updateImgState={updateImgState} img={img} setImg={setImg} handleImgClose={handleImgClose} />
                </Box>
            </Box>
            {/* buttons */}
            <Box >
                <Box display="flex" className={`${classes.btngrp}`} p={1}>
                    <Button variant="outlined" className={`${classes.btn_outlined}`} onClick={() => setIsOpen(false)}>Cancel</Button>
                    <Button variant="contained" className={`${classes.btn_contained}`} onClick={() => handleSubmit()}>{isEdit === true ? "Save Changes" : "Add Tool"}</Button>

                </Box>
            </Box>
        </Box>

    )
}
export default withNamespaces("toolsMaster")(AddPopup)