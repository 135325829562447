
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import {
  Avatar, Box,
  Button,
  FormControlLabel,
  Grid, Stack, Switch, Tooltip,
  tooltipClasses,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import InspectionTemplateImg from "../../assets/inspectionTemplate";
import { generalCardStyles } from "./style";


export const GeneralCard = ({
  list = "",
  buttonText = "",
  handleChange = () => false,
  decreaseQty = () => false,
  increaseQty = () => false,
  view = false,
  totalItems = "",
  isDot,
  handleUpdate = () => false,
  productId,
  imageAvatar,
  component = "",
  handleView = () => false,
  hideBtn = false,
  handleCheck = () => false,
  type = ""
}) => {
  const classes = generalCardStyles();
  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 35,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: "1px 0px",
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 18,
      height: 18,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const switchComponent = (val) => {
    switch (val) {
      case "toggleButton":
        return (
          <div>
            <FormControlLabel
              className={classes.toggle}
              checked={list?.is_active}
              onChange={(e) => handleCheck(e.target.checked)}
              control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
            />
          </div>
        );
      case "increment":
        return (
          <>
            {list.open ? (
              <div className={classes.cart}>
                <Button onClick={decreaseQty}> - </Button>
                <Typography className={classes.cartText}>{list.count}</Typography>
                <Button onClick={increaseQty}> + </Button>
              </div>

            ) : (
              type === "template" ?
                list?.is_active &&

                <div className={classes.addBtn} onClick={handleChange}>
                  {buttonText}
                </div>

                :

                <div className={classes.addBtn} onClick={handleChange}>
                  {buttonText}
                </div>
            )}
          </>
        )

      default:
        return null;
    }
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#DADDE9",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#FFFFFF",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 190,
      fontSize: "12px",
      border: "1px solid #DADDE9",
    },
  }));
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <div>
              <div className={classes.generalCard}>
                <div className={classes.imageText}>
                  <Avatar className={classes.avatar}>
                    {
                      list?.image ?
                        <img src={list?.image} style={{ objectFit: "cover" }} alt="" /> :
                        <InspectionTemplateImg />
                    }


                  </Avatar>
                  <div>
                    <div className={classes.title}>
                      <Typography className={classes.text}>
                        {list?.Name}
                      </Typography>
                      {list?.mandatory && (
                        <div className={classes.clipIcon}>
                          <BootstrapTooltip
                            placement="right-start"
                            title={
                              <React.Fragment>
                                <Typography className={classes.tooltipTitle}>
                                  Mandatory Inventory Item
                                </Typography>
                                <Typography className={classes.tooltipSubTitle}>
                                  This item need to be verified and need to be
                                  intact.
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AssignmentOutlinedIcon />
                          </BootstrapTooltip>
                        </div>
                      )}
                    </div>
                    {view === true && (
                      <div className={classes.subText}>
                        <div className={classes.items}>
                          <Typography>{totalItems} Items</Typography>
                        </div>
                        {isDot && <span className={classes.dot}></span>}
                        <div className={classes.viewText} onClick={() => handleView(list)}
                        >
                          <Typography sx={{ fontSize: "12px" }}>View</Typography>
                        </div>
                      </div>
                    )}
                    {productId === true && (

                      <div className={classes.subText}>
                        <div className={classes.items}>
                          <Typography>Product Id:</Typography>
                        </div>
                          <Stack marginLeft="8px" alignItems="center" direction={"row"} spacing={1} >
                            <Typography className={classes.tooltipTitle}>{list?.product_id??"-"}</Typography>
                            {/* {list?.item_type === "Inventory" &&
                              <Typography className={classes.viewText} onClick={handleUpdate}>Update</Typography>
                            } */}
                          </Stack>
                        

                      </div>
                    )}
                  </div>
                </div>
                {switchComponent(component)}
              </div>
              {/* {imageAvatar && (
                <div className={classes.inspectionItemCard}>
                  <div className={classes.product}>
                    <Typography>{list?.product}</Typography>
                  </div>
                  <div className={classes.AvatarGroup}>
                    <AvatarGroup className={classes.AvatarImg} max={3}>
                      {data.map((img) => {
                        return <Avatar src={img?.src} alt={img?.alt} />;
                      })}
                    </AvatarGroup>
                    <div className={classes.upload}>
                      <Typography>{list?.date}</Typography>
                    </div>
                  </div>
                  <Stack spacing={3} className={classes.textGroup}>
                    <Stack direction="row" spacing={5}>
                      <Grid container>
                        <Grid item xs={3}>
                          <Typography className={classes.subTexts}>{list?.condition}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography className={classes.hypen}>-</Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack direction="row" spacing={5}>
                      <Grid container>
                        <Grid item xs={3}>
                          <Typography className={classes.subTexts}>{list?.clean}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography className={classes.hypen}>-</Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack direction="row" spacing={5}>
                      <Grid container>
                        <Grid item xs={3}>
                          <Typography className={classes.subTexts}>{list?.description}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography className={classes.hypen}>-</Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </div>
              )} */}
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};