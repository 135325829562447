import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../utils";
export const LeadStylesParent = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    margin: "14px",
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "10px",
  },
  outerCircle: {
    padding: "2px",
    border: "1px solid #5078E1",
    borderRadius: "50%",
  },
  next: {
    marginLeft: "10px",
    fontFamily: SemiBold,
    color: "#fff",
    backgroundColor: "#5078E1",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
  },
  Cancel: {
    backgroundColor: "#FFFFFF ",
    color: "#091B29",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: SemiBold,
    border: "1px solid #E4E8EE",
    "&:hover": {
      backgroundColor: "#FFFFFF ",
    },
  },
  bottombtn: {
    padding: "11px 16px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 16px #00000014",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E8EE",
    position: "sticky",
    bottom: "0px",
    width: "100%",
    zIndex: 2,
  },
  Avatar: {
    backgroundColor: theme.palette.primary.main,
    width: "18px",
    height: "18px",
    fontSize: "9px",
  },
  tittle: {
    color: "#5078E1",
    fontSize: "14px",
  },
  tittle1: {
    color: "#98A0AC",
    fontSize: "14px",
  },
  outerCircle1: {
    padding: "2px",
    border: "1px solid #98A0AC",
    borderRadius: "50%",
  },
  Avatar1: {
    backgroundColor: "#98A0AC",
    width: "18px",
    height: "18px",
    fontSize: "9px",
  },
  stepper: {
    borderBottom: "1px solid #E4E8EE",
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  profileTilte: {
    color: "#091B29",
    fontSize: "16px",
    fontFamily: Bold,
  },
  leadnumber: {
    color: "#091B29",
    fontSize: "10px",
    fontFamily: Bold,
    backgroundColor: "#E1E8F8",
    padding: "4px",
    borderRadius: "4px",
    marginLeft: "10px",
  },
  leadcontact: {
    color: "#4E5A6B",
    fontSize: "12px",
    fontFamily: Regular,
    display: "flex",
    alignItems: "center",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  profileroot: {
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
  profileDescription: {
    color: "#98A0AC",
    fontSize: "10px",
    fontFamily: SemiBold,
  },
  profileDescriptioncontant: {
    color: "#4E5A6B",
    fontSize: "14px",
    fontFamily: Regular,
    marginTop: "8px",
    paddingRight: "5px",
  },
  allocatedroot: {
    border: "1px solid #E4E8EE",
    marginTop: "8px",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#fff",
  },
  timedot: {
    height: "8px",
    width: "8px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
  },
  timedotborder: {
    borderRadius: "50%",
    padding: "3px",
    border: "1px dashed #CED3DD",
  },
  timelinecreated: {
    color: "#4E5A6B",
    fontSize: "10px",
    fontFamily: Regular,
  },
  timelinecreatedat: {
    color: "#98A0AC",
    fontSize: "10px",
    fontFamily: Regular,
  },
  message: {
    color: "#091B29",
    fontSize: "12px",
    fontFamily: SemiBold,
    fontWeight: 600,
  },
  commandinput: {
    width: "100%",
    height: "35px",
    border: "none !important",
    outline: "none !important",
    color: "#091B29",
    fontSize: "12px",
    fontFamily: SemiBold,
    fontWeight: 600,

    "&:focus": {
      border: "none !important",
      outline: "none !important",
    },
  },
  commandbox: {
    width: "100%",
    padding: "12px",
    borderRadius: "10px",
    border: "1px solid #E4E8EE",
    margin: "0 auto",
  },
  profileroot2: {
    backgroundColor: "#F2F4F7",
    borderRadius: "8px",
    width: "100%",
    padding: "24px",
    margin: "0 auto",
  },
  commandbtn: {
    width: "100%",
    background: "#5078E1",
    color: "#fff",
    height: "32px",
    marginTop: "10px",
    "&:hover": {
      background: "#5078E1",
      color: "#fff",
    },
  },
  options: {
    color: "#091B29",
    fontSize: "14px",
    fontFamily: Bold,
  },
  leadtransfersbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px",
    cursor: "pointer",
    borderBottom: "1px solid #E4E8EE",
    "&:nth-child(3)": {
      borderBottom: "none !important",
    },
  },
  dot1: {
    border: "2px solid #CED3DD",
  },
  doted: {
    color: "#CED3DD",
    marginLeft: 6,
  },
  dot_line: {
    border: "1px dotted #CED3DD",
  },
  menuItem: {
    "&.MuiTimeline-root": {
      "& .MuiTimelineItem-root": {
        "&:before": {
          content: "",
          padding: 0,
        },
      },
    },
  },
  activityBorder: {
    padding: "0px",
  },
  activityPadding: {
    paddingLeft: "0 !important",
  },
  detailsub: {
    fontFamily: Regular,
    color: "#98A0AC",
    fontSize: "14px",
  },
  seeMoreLessTextStyle: {
    fontSize: "12px",
    fontFamily: SemiBold,
    color: theme.palette.primary.main,
  },
  fontArrow: {
    fontSize: "21px !important",
  },
  profilecard: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  profilecardContent: {
    display: "flex",
    alignItems: "center",
  },
  memberboxes: {
    flexFlow: "wrap !important",
    alignItems: "center",
    display: "flex",
    marginTop: "4px",
  },
  deleteIcon: {
    color: "red",
    fontSize: "19px",
    position: "absolute",
  },
  profilePadding: {
    padding: "14px",
  },
  cardBottomLine: {
    borderBottom: "1px solid #E4E8EE",
  },
  unitNo: {
    color: "#091B29",
    fontSize: "14px",
    fontFamily: Bold,
  },
  unitName: {
    color: "#4E5A6B",
    fontSize: "12px",
    fontFamily: Regular,
  },
  UserDetails: {
    marginLeft: "10px",
  },
  Cardcontent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "7px 5px",
    margin: "10px 0px",
  },
  unitList: {
    textAlign: "left",
    overflow: "auto",
    padding: "24px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableMain: {
    paddingTop: '5px !important'
  },
  formRoot: {
    backgroundColor: "white"
  }
}));
