import { Grid, IconButton, } from "@mui/material";
import { DocumentViewer } from "../../fileViewer";
import { proofComponentStyles } from "../styles";
import { AlertDialog } from "../../dialog";

export const ProofView = (props) => {
    const classes = proofComponentStyles()
    return (
        <Grid container>
            <Grid item xs={12} className={classes.imgContainer}>
                {props?.fileType !== "application/pdf" ?
                    <div
                        style={{ position: "relative", cursor: "pointer" }}
                    >
                        <img
                            type="file"
                            accept="image/*"
                            multiple
                            alt="not found"
                            width={"250px"}
                            src={props?.src}
                            style={{
                                objectFit: "cover",
                                height: "150px",
                                width: "100%",
                                borderRadius: "4px",
                            }}
                            onClick={props?.openImageViewer ? () => props?.openImageViewer([props?.src, "img"]) : () => props?.setOpenPdfBtn([props?.src, "img"])}

                        />
                        <br />
                        {
                            !props?.disable &&
                            <IconButton className={classes.delete} size="small" sx={{ marginTop: "-4px" }} onClick={props?.onDelete}>
                                <img src="/images/icons8-trash.svg" alt="" />
                            </IconButton>
                        }
                    </div>
                    :
                    <div
                        style={{
                            position: "relative",
                            overflow: "hidden",
                            height: "150px",
                        }}
                        onClick={props?.openImageViewer ? () => props?.openImageViewer([props?.src, "pdf"]) : () => props?.setOpenPdfBtn([props?.src, "pdf"])}
                    >
                        <DocumentViewer url={props?.src} />

                        <br />
                        {
                            !props?.disable &&
                            <IconButton className={classes.delete} size="small" sx={{ marginTop: "-4px" }} onClick={props?.onDelete}>
                                <img src="/images/icons8-trash.svg" alt="" />
                            </IconButton>
                        }
                    </div>
                }

            </Grid>
            {props?.isViewerOpen && (

                <AlertDialog
                    isNormal isnotTitle
                    component={
                        props?.currentImageType === "img" ?
                            <div style={{ display: 'flex' }}>
                                <img src={props?.currentImage} alt='' style={{ objectFit: 'cover', width: '444px' }} />
                            </div>
                            :
                            <div>
                                <DocumentViewer url={props?.currentImage} />
                            </div>
                    }
                    open={props?.isViewerOpen}
                    onClose={props?.closeImageViewer} />
            )}
        </Grid>
    )
}