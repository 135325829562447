import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js/auto";
// import ChartAnnotation from 'chartjs-plugin-annotation';

const useStyles = makeStyles((theme, props) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // "& svg": {
    //   // borderRadius: "50%",
    //   width: "100%",
    //   minWidth: "138px !important",
    //   height: "138px !important",
    //   position: "relative",
    //   [theme.breakpoints.up("sm")]: {
    //     width: "100% !important",
    //     margin: "0 auto",
    //   },
    //   "& .recharts-wrapper": {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
    // },
  },
  total: {
    color: "#637381",
    fontSize: "15px",
  },
  des: {
    fontWeight: 600,
    color: "#252F3A",
    fontSize: "25px",
  },
  chart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  lineChart: {
    minHeight: (props) => props?.height ?? "320px",
    width: (props) => props?.width ?? "600px",
  },
}));

export const GroupPieCharts = (props) => {
  const options = {
    indexAxis: props?.indexAxis ?? false,
    animation: false,
    responsive: true,
    plugins: {
      legend: {
        display: props?.datalabels || props?.legend ? false : true,
        position: "bottom",
        align: "start",
        width: "5px",
        labels: {
          usePointStyle: true,
        },
        marginTop: "20px",
      },
      datalabels: {
        display: props?.datalabels ?? false,
        color: "#0F0C1D",
        formatter: Math.round,
        anchor: "end",
        offset: -25,
        align: "start",
        font: {
          weight: "bold",
          size: 14,
        },
      },
      annotation: {
        annotations: [
          {
            type: "line",
            mode: "vertical",
            scaleID: "y-axis-0",
            value: 10,
            borderColor: "red",
            borderWidth: 4,
            label: {
              enabled: false,
              content: "Test label",
            },
          },
        ],
      },
    },
    scale: {
      ticks: {
        precision: 0
      },
    },
    scales: props?.scale,
    type: "bar",
    position: "bottom",
    align: "center",
  };
  useEffect(() => {
    Chart.register([ChartDataLabels]);
  }, []);


  const classes = useStyles(props);


  const constractData = {...props?.data , datasets:props?.data?.datasets.map((x)=>{
    return{
      ...x,
      barThickness:40
    }
  })}

  return (
    <div style={{ position: "relative" }} className={classes.root}>
      <>
        {props?.line ? (
          <div className={classes.lineChart}>
            <Line
              options={options}
              data={props?.data}
              width={props?.width ?? null}
              height={props?.height ?? null}
            />
          </div>
        ) : (
          <Bar
            data={constractData}
            width={props?.width ?? null}
            height={props?.height ?? null}
            options={options}
            plugins={[ChartDataLabels]}
          />
        )}
      </>
    </div>
  );
};
