import gql from "graphql-tag";

export const GET_ACCOUNT = gql`
query {
  contact_account(
    where: { name: { iregex: $search } }
    offset: $offset
    limit: $limit
  ) {
     id
    name
    subTitle1: account_no
    image_url: logo
    subTitle2: city
    account_type
    email_id
    mobile_no
    mobile_no_country_code
  }
}

`

export const GET_INVOICE_ACCOUNT = gql`
query {
  invoice(
    where: { account_no: $id, payment_status:"due" invoice_no: { iregex: $search } }
    offset: $offset
    limit: $limit
  ) {
    id
    created_at
    payment_status
    invoice_no
    invoice_due_amount
    invoice_total_amount
    asset_url
    currency_master{
      symbol
    }
    agreement {
      id
      agreement_no
    }
  }
}

`

export const GET_ACCOUNT_FILTER = (offset, limit, searchText, isActive = false) => gql`
query GET_COUNTRY_MASTER{
  count: contact_account(
    where: {
      name: { iregex: "${searchText}" }
      is_active:{eq:true}
    }) {
    count:count_id
  }
  contact_account(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
    where: {
      name: { iregex: "${searchText}" }
      is_active:{eq:true}
    }
    ){
    id
    account_no
    name
  }
}`;