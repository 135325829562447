import { useApolloClient } from "@apollo/client";
import React, { createContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../contexts";
import {
  ADD_COMMAND_PROXY_LEAD,
  CHECK_PROSPECTIVE,
  CREATE_CONTACT,
  CREATE_LEAD, CREATE_SHORTLIST, UPDATE_LEAD, UPDATE_LEAD_UNIT, UPDATE_PROSPECT
} from "../graphql/quotationMutations";
import { Routes } from "../router/routes";
import { AlertProps, ValidateEmail } from "../utils";
import {
  createState,
  returnContactCreatePayload,
  returnConvertLeadPayload,
  returnOppertunityCreatePayload
} from "../utils/createQuotationUtils";

export const CreateOppertunityContext = createContext();

const CreateOppertunityContextProvider = (props) => {
  const [step, setStep] = useState(1);
  const client = useApolloClient();
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const [dataNew, setDataNew] = React.useState({ ...createState });
  const [selectedAccount, setSelectedAccount] = React.useState({});
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  //update state
  const updateStateNew = (key, value, key1, value1) => {
    let error = dataNew.error;
    error[key] = "";
    setDataNew({ ...dataNew, [key]: value, [key1]: value1, error });
  };
  //validate sale oppertunity
  const validateSaleOppertunity = () => {
    let isValid = true;
    let error = dataNew.error;
    if (dataNew?.contract_start_date?.length === 0) {
      isValid = false;
      error.contract_start_date = "Contract Start Date is Required";
    }
    if (dataNew?.payment_period?.value === "Milestone Based") {
      if (dataNew?.delivery_timestone_template?.length === 0) {
        isValid = false;
        error.delivery_timestone_template = "Delivery Milstone is Required";
      }
    }

    if (dataNew?.payment_period?.length === 0) {
      isValid = false;
      error.payment_period = "Payment Period is Required";
    }
    if (dataNew?.payment_option?.length === 0) {
      isValid = false;
      error.payment_option = "Payment Options is Required";
    }
    if (dataNew?.oppertunity_subject?.length === 0) {
      isValid = false;
      error.oppertunity_subject = "Subject is Required";
    }
    if (dataNew?.source?.length === 0) {
      isValid = false;
      error.source = "Source is Required";
    }
    if (dataNew?.priority?.length === 0) {
      isValid = false;
      error.priority = "Priority is Required";
    }
    if (dataNew?.earlist_occupation_date?.length === 0) {
      isValid = false;
      error.earlist_occupation_date = "Earliest Occupation Date is Required";
    }
    if (dataNew?.payment_period?.length === 0) {
      isValid = false;
      error.payment_period = "Payment Period is Required";
    }
    // if(dataNew?.units?.length === 0){
    //   isValid = false;
    //   alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "Please select unit",
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center,
    //   });
    // }


    setDataNew({ ...dataNew, error });
    return isValid;
  };
  //validate contact
  const validataNewContact = () => {
    let isValid = true;
    let error = dataNew.error;
    console.log(dataNew,"dataNew")
    if (dataNew?.name?.length === 0) {
      isValid = false;
      error.name = "Name is Required";
    }
    if (dataNew?.mobile?.length === 0) {
      isValid = false;
      error.mobile = "Mobile is Required";
    }
    if (dataNew?.email_id?.length === 0) {
      isValid = false;
      error.email_id = "Email is Required";
    }
    if (!ValidateEmail(dataNew?.email_id)) {
      isValid = false;
      error.email_id = "Invalid Email";
    }
    setDataNew({ ...dataNew, error });
    return isValid;
  };
  //validate lead
  const validateLeadDetails = () => {
    let isValid = true;
    let error = dataNew.error;
    if (dataNew?.duration?.value?.length === 0) {
      isValid = false;
      error.duration = "Duration is Required";
    }
    if (dataNew?.lease_start_date === null) {
      isValid = false;
      error.lease_start_date = "Lease Start is Required";
    }
    if (dataNew?.payment_period?.length === 0) {
      isValid = false;
      error.payment_period = "Payment Period is Required";
    }
    if (dataNew?.payment_option?.length === 0) {
      isValid = false;
      error.payment_option = "Payment Options is Required";
    }
    if (dataNew?.grace_peroid?.length === 0) {
      isValid = false;
      error.grace_peroid = "Grace Peroid is Required";
    }
    if (dataNew?.grace_peroid?.length !== 0) {
      if (dataNew?.apply_grace_peroid?.length === 0) {
        isValid = false;
        error.apply_grace_peroid = "Apply Grace Peroid is Required";
      }
    }
    if (
      dataNew?.payment_period?.value !== "Prepaid" &&
      dataNew?.custom_date?.length === 0
    ) {
      if (dataNew?.bill_cycle_date?.length === 0) {
        isValid = false;
        error.bill_cycle_date = "Billing Cycle Date is Required";
      }
      if (dataNew?.bill_cycle_method?.length === 0) {
        isValid = false;
        error.bill_cycle_method = "Billing Cycle Method is Required";
      }
    }

    if (
      dataNew?.bill_cycle_date === null &&
      dataNew?.payment_period?.value !== "Prepaid"
    ) {
      if (dataNew?.custom_date?.length === 0) {
        isValid = false;
        error.custom_date = "Custom Date is Required";
      }
    }

    if (dataNew?.oppertunity_subject?.length === 0) {
      isValid = false;
      error.oppertunity_subject = "Subject is Required";
    }
    if (dataNew?.source?.length === 0) {
      isValid = false;
      error.source = "Source is Required";
    }
    if (dataNew?.priority?.length === 0) {
      isValid = false;
      error.priority = "Source is Required";
    }
    if (dataNew?.renewal_based_on?.length === 0) {
      isValid = false;
      error.renewal_based_on = "Renewal Based on is Required";
    }
    // if (dataNew?.renewal_based_on?.value === "Escalation %" || dataNew?.renewal_based_on?.value === "Increment %") {
    //     if (dataNew?.esclation_percentage?.length === 0) {
    //         isValid = false;
    //         error.esclation_percentage = "Increment/Escalation Percentage is Required";
    //     }
    // }
    // if(dataNew?.units?.length === 0){
    //   isValid = false;
    //   alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "Please select unit",
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center,
    //   });
    // }

    setDataNew({ ...dataNew, error });
    return isValid;
  };
  //previous btn function
  const previous = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };
  //create lead
  const createLead = (prospective_id) => {
    let payload = returnOppertunityCreatePayload(
      dataNew,
      prospective_id,
      selectedAccount,
      false
    );
    client
      .mutate({
        mutation: CREATE_LEAD,
        variables: {
          insert: [payload],
        },
      })
      .then((rs) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Lead Successfully Created",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        createShortlist(rs?.data?.lead?.[0]?.id);
      })
      .catch((er) => {
        console.log(er);
      });
  };
  //create contact
  const createContact = () => {
    const payload = returnContactCreatePayload(dataNew);
    client
      .mutate({
        mutation: CREATE_CONTACT,
        variables: {
          insert: [payload],
        },
      })
      .then((rs) => {
        setDataNew({ ...dataNew, contact_id: rs?.data?.prospective?.[0]?.id });
        createLead(rs?.data?.prospective?.[0]?.id);
      })
      .catch((er) => {
        console.log(er);
      });
  };
  //convert lost
  const convertLost = () => {
    const payload = returnConvertLeadPayload(dataNew);
    client
      .mutate({
        mutation: ADD_COMMAND_PROXY_LEAD,
        variables: payload,
      })
      .then((rs) => {
        history.push(Routes.leadnew);
      })
      .catch((er) => {
        console.log(er);
        setDisableButton(false)
      });
  };
  //check exist prospective
  const checkProspectiveCheck = () => {
    client
      .mutate({
        mutation: CHECK_PROSPECTIVE,
        variables: {
          email_id: dataNew?.email_id,
        },
      })
      .then((rs) => {
        if (rs?.data?.prospective?.length > 0) {
          createLead(rs?.data?.prospective?.[0]?.id);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.info,
            msg: "This Prospective is Already Exisit Create Oppertuntiy By That Prospect",
          });
        } else {
          createContact();
        }
      })
      .catch((er) => {
        console.log(er);
      });
  };
  //update lead
  const updateLead = (prospective_id) => {
    let payload = returnOppertunityCreatePayload(
      dataNew,
      dataNew?.contact_id,
      selectedAccount,
      true
    );
    client
      .mutate({
        mutation: UPDATE_LEAD,
        variables: {
          id: dataNew?.lead_id,
          updatePayload: payload,
        },
      })
      .then((rs) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Lead Successfully Updated",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        if (dataNew?.isEdit) {
          createShortlistEdit(rs?.data?.lead?.[0]?.id);
          updateLeadUnits(rs?.data?.lead?.[0]?.id);
        }
      })
      .catch((er) => {
        console.log(er);
        setDisableButton(false)
      });
  };
  // update prospect
  const updateProspect = () => {
    const payload = returnContactCreatePayload(dataNew);
    client
      .mutate({
        mutation: UPDATE_PROSPECT,
        variables: {
          id: dataNew?.contact_id,
          updatePayload: payload,
        },
      })
      .then((rs) => {
        updateLead();
      })
      .catch((er) => {
        console.log(er);
        setDisableButton(false)
      });
  };
  //next function if newlead
  const next = () => {
    if (step === 1) {
      if (validataNewContact()) {
        setStep(2);
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.info,
          msg: "Please Fill All Mandatory Fields",
        });
      }
    } else if (step === 2) {
      if (
        dataNew?.revenue_type?.value === "Lease" ||
        dataNew?.revenue_type?.value === "Manage"
      ) {
        if (validateLeadDetails()) {
          setStep(3);
        }
      } else if (dataNew?.revenue_type?.value === "Sale") {
        if (validateSaleOppertunity()) {
          setStep(3);
        }
      }
    } else if (step === 3) {
      if (dataNew?.lead_id?.length > 0) {
        setDisableButton(true)
        updateProspect();
      } else {
        setDisableButton(true)
        checkProspectiveCheck();
      }
    }
  };
  //create shortlist
  const createShortlist = (id) => {
    if (dataNew?.units?.length > 0) {
      client
        .mutate({
          mutation: CREATE_SHORTLIST,
          variables: {
            insert: dataNew?.units?.map((val) => {
              return {
                unit_id: val?.id,
                lead_id: id,
                company_id: dataNew?.company,
                is_active: true,
                property_id: val?.property_id ?? null
              };
            }),
          },
        })
        .then((rs) => {
          if (dataNew?.is_convert) {
            convertLost(id);
          } else {
            // history.push(Routes.leads);
            history.push(Routes.leads);
          }
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Shortlist Successfully Created",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          history.push(Routes.leads);
        })
        .catch((er) => {
          console.log(er);
        });
    }
    else {
      history.push(Routes.leads);
    }

  };
  //update lead
  const updateLeadUnits = (id) => {
    let arr2 = dataNew?.fixUnit?.map((x) => x?.id) ?? [];
    let arr = dataNew?.units?.map((x) => x?.id) ?? [];

    let deleted = arr2
      .map((x) => (!arr.includes(x) ? x : false))
      .filter(Boolean);

    if (deleted?.length > 0) {
      client
        .mutate({
          mutation: UPDATE_LEAD_UNIT,
          variables: {
            lead_id: id,
            id: deleted ?? [],
            updatePayload: {
              is_active: false,
            },
          },
        })
        .then((rs) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Lead Successfully Updated",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          history.push(Routes.leads);
        })
        .catch((er) => {
          console.log(er);
          setDisableButton(false)
        });
    } else {
      history.push(Routes.leads);
    }
  };
  //   update short list
  const createShortlistEdit = (id) => {
    let arr2 = dataNew?.fixUnit?.map((x) => x);
    let arr = dataNew?.units?.map((x) => x);
    let addedUnit = arr.filter(item => !arr2.map(i => i.id).includes(item?.id));

    if (addedUnit?.length > 0) {
      client
        .mutate({
          mutation: CREATE_SHORTLIST,
          variables: {
            insert: addedUnit?.map((val) => {
              return {
                unit_id: val?.id,
                lead_id: id,
                company_id: dataNew?.company,
                is_active: true,
                property_id: val?.property_id ?? null
              };
            }),
          },
        })
        .then((rs) => {
          if (dataNew?.is_convert) {
            convertLost(id);
          } else {
            history.push(Routes.leads);
          }
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Shortlist Successfully Created",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          history.goBack(-1);
        })
        .catch((er) => {
          console.log(er);
          setDisableButton(false)
        });
    } else {
      history.goBack(-1);
    }
  };
  return (
    <CreateOppertunityContext.Provider
      value={{
        step,
        previous,
        next,
        updateStateNew,
        dataNew,
        setDataNew,
        setShow,
        setStep,
        show,
        setShow1,
        show1,
        setSelectedAccount,
        selectedAccount,
        disableButton,
        setDisableButton,
      }}
    >
      {props.children}
    </CreateOppertunityContext.Provider>
  );
};

export default CreateOppertunityContextProvider;
