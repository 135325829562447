import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomPaper, CustomTypography, TitleDesc } from '../components';
import useTheme from '@mui/material/styles/useTheme';
import { returnValue } from '../utils';
import { Bold } from '../../../utils';
export const GovernmentalDetailsView = (props) => {

    const theme = useTheme();

    const {
        tax = null,
    } = props?.data ?? {};


    return (
        <div>
            {/* <CustomPaper marginBottom={theme.spacing(3)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTypography
                            fontSize={12}
                            fontFamily={Bold}
                            // marginBottom={theme?.spacing(2)}
                            color={theme?.typography?.color?.secondary}
                        >
                            Company & Real Estate Registration
                        </CustomTypography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Company Registration Type"}
                            desc={returnValue(companyEstateRegistration?.companyRegistrationType?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Company Registration Number"}
                            desc={returnValue(companyEstateRegistration?.companyRegistrationNumber, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Regulatory Authority Type"}
                            desc={returnValue(companyEstateRegistration?.realEstateRegulatoryAuthority?.label, "-")}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"RERA Registration Number"}
                            desc={returnValue(companyEstateRegistration?.RERARegistrationNumber, "-")}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"RERA Broker Registration Number"}
                            desc={returnValue(companyEstateRegistration?.RERABrokerRegistrationNumber, "-")}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={"Real Estate Business Advertising Permit"}
                            desc={typeCheck(companyEstateRegistration?.realEstateAdvertisingPermit) ? companyEstateRegistration?.realEstateAdvertisingPermit ? 'Yes' : 'No' : '-'}
                        />
                    </Grid>
                    {
                        companyEstateRegistration?.realEstateAdvertisingPermit &&
                        <Grid item xs={12} md={3}>
                            <TitleDesc
                                title={"RERA Advertising Permit Number"}
                                desc={returnValue(companyEstateRegistration?.advertisingPermitNumber, '-')}
                            />
                        </Grid>
                    }
                </Grid>
            </CustomPaper> */}
            <CustomPaper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTypography
                            fontSize={12}
                            fontFamily={Bold}
                            // marginBottom={theme?.spacing(2)}
                            color={theme?.typography?.color?.secondary}
                        >
                            TAX
                        </CustomTypography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Country Taxation System"}
                            desc={returnValue(tax?.taxCode?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Tax Registration Number"}
                            desc={returnValue(tax?.taxRegistrationNumber, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Tax Group"}
                            desc={returnValue(tax?.taxGroup?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={"Total Tax Rate"}
                            desc={returnValue(tax?.totalRateTax + " % ", '-')}
                        />
                    </Grid>
                </Grid>
            </CustomPaper>
        </div >
    )
}