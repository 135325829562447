import { useApolloClient } from "@apollo/client";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useState } from 'react';
import { SelectBox, ToggleButtonComponent } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext } from "../../contexts";
import {
    GET_CREATEDBY_BASED_ON_COMPANY, GET_PROPERTY_BASED_ON_COMPANY, GET_STATUS_FROM_QUOTATION_STATUS_MASTER,
    GET_UNIT_BASED_ON_PROPERTY
} from '../../graphql/quotationListingQuery';
import { NetworkCall } from '../../networkcall';
import { AlertProps, NetWorkCallMethods } from '../../utils';


const CustomTypography = styled(Typography)({
    color: "#4E5A6B",
    fontSize: "12px",
    marginTop: "10px",
})

const CustomGrid = styled(Grid)(({ theme }) => ({
    padding: `${theme.spacing(1)} 10px`
}))

const FINAL_STATUS_OPTIONS = [
    { label: 'Accepted', value: 'Accepted' },
    { label: 'Rejected', value: 'Rejected' },
    { label: 'All', value: 'All' }
]

export const FilterComponent = ({
    data = {},
    onChange = null,
    applyFilter = null,
    clearAllFilter = null
}) => {

    const client = useApolloClient();
    const auth = React.useContext(AuthContext);
    const [companyList, setCompanyList] = React.useState([]);
    const [loading, setLoading] = useState(null);

    const returnData = (name, delimiter = "") => name ? name?.toString()?.trim() : delimiter;
    const alert = React.useContext(AlertContext);
    const loadOptions = async (search = "", array, type) => {

        setLoading(type);
        let result, query, offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        }
        else {
            offset = array?.length;
        }

        let companyID = data?.companyName?.map(({ value }) => value);

        switch (type) {
            case 'propertyName':
                if (!companyID?.length) {
                    setLoading(null);
                    return { options: [] }
                }
                query = GET_PROPERTY_BASED_ON_COMPANY(offset, 10, search, companyID).loc.source.body;
                result = await networkCallBack(query);
                return {
                    options: [...result?.property],
                    hasMore: (array?.length + result?.property?.length) < result?.count[0]?.count
                }

            case 'unitName':
                if (!data?.propertyName?.length) {
                    setLoading(null);
                    return { options: [] }
                }
                let propertyID = data?.propertyName?.map(_ => _?.value);

                result = await client.query({
                    query: GET_UNIT_BASED_ON_PROPERTY,
                    variables: {
                        data: propertyID,
                        offset,
                        limit: 10,
                        search
                    }
                });

                setLoading(null);
                if (!result?.data?.unit.length) return { options: [] }
                return {
                    options: [...result?.data?.unit],
                    hasMore: (array?.length + result?.data?.unit?.length) < result?.data?.count?.[0]?.count
                }

            case 'createdBy':
                if (!companyID?.length) {
                    setLoading(null);
                    return { options: [] }
                }
                query = GET_CREATEDBY_BASED_ON_COMPANY(offset, 10, search, companyID).loc.source.body
                result = await networkCallBack(query);

                let mapResult = [...result?.quotation?.map((_) => ({
                    label: returnData(`${returnData(_?.created_by?.first_name)} ${returnData(_?.created_by?.last_name)}`,),
                    value: _?.created_by?.id
                }))];

                return {
                    options: mapResult,
                    hasMore: array?.length + result?.quotation?.length < result?.count[0]?.count
                }

            case 'status':
                query = GET_STATUS_FROM_QUOTATION_STATUS_MASTER(offset, 10, search).loc.source.body;
                result = await networkCallBack(query);
                return {
                    options: [...result?.quotation_status_master],
                    hasMore: array?.length + result?.quotation_status_master?.length < result?.count[0]?.count
                }

            default:
                return { options: [] }
        }
    }

    const networkCallBack = async (query, variable = {}) => {
        let payload = {
            query,
            variable: variable
        };

        const options = await NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let main = response.data.data;
                setLoading(null);
                return main
            })
            .catch((error) => {
                setLoading(null);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                return null
            });

        return options
    }
    React.useEffect(() => {

        let tempCompany = auth?.auth?.auth?.access?.[auth?.auth?.auth?.activeRole]?.company?.map((item) => {
            let _c;
            try {
                _c = {
                    value: item?.value,
                    label: item?.label,
                };
            } catch (err) {

            }
            return _c;
        });
        setCompanyList(tempCompany);
        // eslint-disable-next-line
    }, [auth]);
    return (
        <div>
            <Grid container>
                <CustomGrid item xs={12}>
                    <CustomTypography variant="body1" gutterBottom>
                        Company name
                    </CustomTypography>
                    <SelectBox
                        value={data?.companyName ?? []}
                        onChange={(value) => onChange('companyName', value)}
                        options={companyList}
                        isMulti
                    />
                </CustomGrid>
                <CustomGrid item xs={12}>
                    <CustomTypography variant="body1" gutterBottom>
                        Property Name
                    </CustomTypography>
                    <SelectBox
                        // options={[]}
                        key={data?.companyName}
                        value={data?.propertyName ?? []}
                        onChange={(value) => onChange('propertyName', value)}
                        isMulti
                        isPaginate
                        loadOptions={(search, array) => loadOptions(search, array, 'propertyName')}
                        loading={loading === "propertyName"}
                        debounceTimeout={800}
                    />
                </CustomGrid>
                <CustomGrid item xs={12}>
                    <CustomTypography variant="body1" gutterBottom>
                        Unit Name
                    </CustomTypography>
                    <SelectBox
                        // options={[]}
                        key={(data?.propertyName)}
                        value={data?.unitName ?? []}
                        onChange={(value) => onChange('unitName', value)}
                        isMulti
                        isPaginate
                        loadOptions={(search, array) => loadOptions(search, array, 'unitName')}
                        loading={loading === "unitName"}
                        debounceTimeout={800}
                    />
                </CustomGrid>
                <CustomGrid item xs={12}>
                    <CustomTypography variant="body1" gutterBottom>
                        Created by
                    </CustomTypography>
                    <SelectBox
                        key={data?.companyName}
                        value={data?.createdBy ?? []}
                        onChange={(value) => onChange('createdBy', value)}
                        isMulti
                        isPaginate
                        loadOptions={(search, array) => loadOptions(search, array, 'createdBy')}
                        loading={loading === "createdBy"}
                        debounceTimeout={800}
                    />
                </CustomGrid>
                <CustomGrid item xs={12}>
                    <CustomTypography variant="body1" gutterBottom>
                        Status
                    </CustomTypography>
                    <SelectBox
                        value={data?.status ?? []}
                        onChange={(value) => onChange('status', value)}
                        isMulti
                        loadOptions={(search, array) => loadOptions(search, array, 'status')}
                        loading={loading === "status"}
                        isPaginate
                        debounceTimeout={800}
                    />
                </CustomGrid>
                <CustomGrid item xs={12}>
                    <CustomTypography variant="body1" gutterBottom>
                        Final Status
                    </CustomTypography>
                    <ToggleButtonComponent
                        options={FINAL_STATUS_OPTIONS}
                        value={data?.finalStatus ?? []}
                        onChange={(value) => onChange('finalStatus', value)}
                    />
                </CustomGrid>
                <CustomGrid item xs={12} md={6}>
                    <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 3 }}
                        onClick={clearAllFilter}
                    >
                        Clear All
                    </Button>
                </CustomGrid>
                <CustomGrid item xs={12} md={6}>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        sx={{ mt: 3 }}
                        onClick={applyFilter}
                    >
                        Apply
                    </Button>
                </CustomGrid>
            </Grid>
        </div>
    )
}

