import React from "react";
import { withNavBars } from "../../HOCs";
import { EmergencyContact } from "./emergencyContact";

class EmergencyContactParent extends React.Component {
  render() {
    return <EmergencyContact />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(EmergencyContactParent, props);
