import React from "react";
import { withNavBars } from "../../../HOCs";
import { ParkingSlotMaster } from "./parkingSlotMaster";

class ParkingSlotMasterParent extends React.Component {
    render() {
        return <ParkingSlotMaster  {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ParkingSlotMasterParent, props);
