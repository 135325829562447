import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { CustomSelect } from "../filterGenerator/components";
import { useStyles } from "./styles";
export const CustomAmount = ({
    placeholder = {
        text: "amount",
        select: "Currency"
    },
    disable = {},
    value = {},
    options = [],
    onChange = () => false,
    isError = false,
    errorMessage = ""
}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false)



    const onChangeValue = (key, val) => {
        let updateValue = {
            ...value,
            [key]: val
        }
        onChange(updateValue);
    }

    return (
        <>
            <Box className={classes.root}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography className={classes.label}>RESERVATION AMOUNT</Typography>
                    <Typography
                        onClick={() => setOpen(!open)}
                        className={open ? classes.close : classes.add}> {open ? "Close Custom" : "Custom Amount"}</Typography>
                </Stack>
                {
                    open ?
                        <Box>
                            <Grid container className={classes.textRoot} >
                                <Grid item xs={8.5} p={1}>
                                    <TextField
                                        placeholder={placeholder?.text}
                                        fullWidth
                                        className={classes.textField}
                                        size="small"
                                        value={value?.amount}
                                        type="number"
                                        onChange={(e) => onChangeValue("amount", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3.5} marginTop="3px">
                                    <CustomSelect
                                        placeholder={placeholder?.select}
                                        noBorder={true}
                                        onChange={(val) => {
                                            onChangeValue(val)
                                        }}
                                        value={value?.currency}
                                        options={options}
                                        isReadOnly={disable?.select}
                                    />
                                </Grid>
                            </Grid>
                            <Typography marginTop="4px" className={classes.label}>* Enter custom amount for reservation</Typography>

                        </Box>
                        :
                        <Box display="flex" alignItems="center" className={classes.detailRoot}>
                            <Box flexGrow={1}>
                                <Typography className={classes.value}>
                                    {value?.currency?.label} {value?.amount}
                                </Typography>

                            </Box>

                            <Box marginRight="8px">
                                <Typography className={classes.reco}>(Recommended)</Typography>
                            </Box>
                            <Box>
                                <InfoOutlinedIcon className={classes.recoIcon} />
                            </Box>

                        </Box>
                }
            </Box>
            {isError && (
                <Typography marginTop="4px" variant={"caption"} color={"error"}>
                    {errorMessage}
                </Typography>
            )}
        </>
    )
}