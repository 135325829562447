import {
      Grid
  } from "@mui/material";
  import React from "react";
  import { SetPasswordPage } from "./setpasswordpage.js";
  import { useStyles } from "./style";

  export const SetPassword = (props) => {
      const classes = useStyles();

      return (
          <div className={classes.root}>
              <Grid container className={classes.grid}>
                  <Grid item xs={11} sm={6} md={4} lg={3} className={classes.child}>
                      <SetPasswordPage />
                  </Grid>
              </Grid>
          </div>
      );
  };
