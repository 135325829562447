export const ParkingSlotMasterPath = [
    "parkingSlot",
    "parkingUnitId",
    "perHourRate",
    "location",
    "propertyName",
    "groupMasterName",
    "status"
];


export const ParkingSlotMasterHeading = [
    { title: "Parking Slot", field: "parkingSlot" },
    { title: "Parking Unit ID", field: "parkingUnitId" },
    { title: "Parking Number", field: "parkingNumber" },
    { title: "Location", field: "location" },
    { title: "Per Hour Rate", field: "perHourRate" },
    { title: "Property", field: "propertyName" },
    { title: "Parking Group Master", field: "groupMasterName" },
    { title: "Status", field: "status" },
    { title: "", field: 'icon' },

];
