import CityProperty from '../assets/cityproperty';
import ContractProperty from '../assets/contractproperty';
// import ImagesProperty from '../assets/imagesproperty';
// import ContractProperty from '../assets/contractproperty';

export const Requestpath = ["sno", "requestName", "request_no", "category", "raisedDate", "unit",  "status", "icon"]

export const Requestheading = [
  { title: "S.No", field: "sno" },
  { title: "Description", field: "requestName", },
  { title: "ID", field: "request_no", },
  { title: "Category", field: 'category', },
  { title: "Raised Date", field: "raisedDate", },
  { title: "Unit", field: 'unit', },
  { title: "Assigned to", field: 'assignedTo', },
  { title: "Status", field: 'status', },
  { title: "", field: 'icon' },
]

export const GenralRequestheading = [
  { title: "S.No", field: "sno" },
  { title: "Description", field: "requestName", },
  { title: "ID", field: "request_no", },
  { title: "Category", field: 'category', },
  { title: "Raised Date", field: "raisedDate", },
  { title: "Unit", field: 'unit', },
  { title: "Status", field: 'status', },
  { title: "", field: 'icon' },
]
export const maintanceRequestTabData = (t,value,classes , type)=>[
  {
    title:type ? t("Maintenance_Requests"): t("General_Requests"),
    image:<CityProperty color={value === 0 ? '#5078E1' : '#98A0AC'}/>,
    className: value === 0 ? classes.tabtitle1 : classes.tabtitle,
    selectBack: value === 0 ? classes.selectBack1 : classes.selectBack,
  },

]
export const serviceRequestTabData = (t,value,classes , type)=>[
  {
    title:type ? t("Maintenance_Requests"): t("General_Requests"),
    image:<CityProperty color={value === 0 ? '#5078E1' : '#98A0AC'}/>,
    className: value === 0 ? classes.tabtitle1 : classes.tabtitle,
    selectBack: value === 0 ? classes.selectBack1 : classes.selectBack,
  },
  // {
  //   title:t("Inspection_Request"),
  //   image:<ImagesProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
  //   className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
  //   selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
  // },
  {
    title:t("Service_Request"),
    image:<ContractProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
    className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
    selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
  },
]