import gql from "graphql-tag";
import { LocalStorageKeys } from "../utils";



export const AGREEMENT_NUMBER = (offset, limit, searchText, obj, id = "") => {
  return gql`
query agreement_number{
  count: agreement(
    where:{
      account_id:{eq:"${obj?.agreement_id}"}
      agreement_no: { iregex: "${searchText}" }
    }
    offset: ${offset}
    limit: ${limit}
    ) {
    count:count_id
  }

  agreement(
    where:{
      account_id:{eq:"${obj?.agreement_id}"}
      agreement_no: { iregex: "${searchText}" }
    }
    offset: ${offset}
    limit: ${limit}
    )
    {
      value:id
      label:agreement_no
  }
}
`}

export const TAX_GROUP = (offset, limit, searchText) => gql`
query GET_VAT_GROUP{
  count: vat_group_master(
    where: {
      is_delete:{eq:false}
      group_name: { iregex: "${searchText}" }
     is_active:{eq:true}
     client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
    } ) {
    count:count_id
  }
  vat_group_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc,group_name:asc }
    where: {
      is_delete:{eq:false}
      group_name: { iregex: "${searchText}" }
      is_active:{eq:true}
      client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
    }
    ){
    value:id
    label:group_name
    vat_group_item{
      id
      group_name
      rate
    }
  }
}`;


export const UNIT_DETAILS = (offset, limit, searchText, agreement_id) => gql`
query unit_details {
  count: agreement_units(
    where: { agreement_id: "${agreement_id}" }
    offset: ${offset}
    limit: ${limit}
  ) {
    unit(where: { name: { iregex: "${searchText}" } }) {
      count: count_id
    }
  }

  agreement_units(
    where: { agreement_id: "${agreement_id}" }
    offset: ${offset}
    limit: ${limit}
  ) {
    unit_id:id
    unit(where: { name: { iregex: "${searchText}" } }) {
      id
      name
      unit_no
      unit_type
      unit_category
      logo
      unit_type_master{
        name
      }
      property {
        name
      }
      address{
        latitude
        longitude
      }
    }
  }
}`

export const PRICING_COMPONENT = (offset,limit,searchText,unit_id) => gql`
query pricing_component {
  pricing_table(
    where: {
      unit_id: "${unit_id}"
      is_active: true
      pricing_component: { name: { iregex: "${searchText}" } }
    }
    offset:${offset}
    limit:${limit}
  ) {
    pricing_component {
      id
      name
      revenue_type
    }
  }
}
`

export const UPDATE_STATUS = gql`
mutation {
  free_text_invoice(
    where: { id: { eq: $id } }
    update: $payload
  ) {
    id
  }
}
`