import React from "react";
import { withNavBars } from "../../HOCs";
import { ActivityCategory } from "./activityCategory";

class ActivityCategoryParent extends React.Component {
    render() {
        return <ActivityCategory />;
    }
}

const props = { boxShadow: false }

export default withNavBars(ActivityCategoryParent, props);
