import React, { useState, useEffect } from "react";
import { CustomMap, Location } from "../../../components";
import { LocationTextField } from "../../propertyFinder4/components";
import {
  Box,
  Typography,
  List,
  ListItem,
  IconButton,
  Grid,
} from "@mui/material";
import { useStylesCreation } from "./style";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useWindowDimensions } from "../../../utils";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { MinusIcon, PlusIcon } from '../../../assets';

export const MapComponent = (props) => {
  const classes = useStylesCreation(props);
  const size = useWindowDimensions();
  const [location, setLocation] = useState({});
  const [state , setState] = useState({
    value:0,
    zoom:null
  })

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(getCoordinates);
  };
  const getCoordinates = (position) => {
    setLocation({
      latitude: position?.coords?.latitude,
      longitude: position?.coords?.longitude,
    });
  };
  useEffect(() => {
    getLocation();
    // eslint-disable-next-line
  }, []);

  const zoomFunction =(value,bool)=>{
      setState({
        zoom:bool,
        value
      })
  }

  

  
  return (
    <div className={classes.card}>
      {
        (location?.latitude && location?.longitude) ? 
        <>
         <Box className={classes.flexBoxWithSpace}>
        <Box>
          <Typography className={classes.titleFont}>{props?.title}</Typography>
        </Box>
        <Box>
          <TabComponent
            tabList={props?.tabList}
            tabNameState={props?.tabNameState}
            keyType={props?.keyType}
            updateState={props?.updateState}
          />
        </Box>
        <IconButton onClick={() => props?.onZoomClick(props?.title)}>
          {props?.isZoom && (
            <img
              src="/images/propertdashboard/enlare.png"
              alt="zoom"
              onClick={() => props?.onZoomClick(props?.title)}
            />
          )}
             {
            props?.is_close && 
              <CancelOutlinedIcon/>
          }
        </IconButton>
      </Box>
      <br />
      <div
        className={classes.map}
        style={{ height: (props?.isZoom || props?.is_close) ? null : size?.height - 200 }}
      >
        <div className={classes.searchBox}>
          {/* search field */}
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={10}>
              <Location
                value={props?.data?.location}
                handleChange={(value) => props?.updateState("location", value)}
                textFieldComponent={(ref, value, handleChange) => (
                  <LocationTextField
                    label={"Location"}
                    inputRef={ref}
                    handleChange={handleChange}
                    value={value}
                    placeholder={"Select City"}
                    isPointer
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton className={classes.finder} onClick={props?.sumbit}>
                <MyLocationIcon style={{ color: "#fff" }} />
              </IconButton>
            </Grid>
          </Grid>
        </div>
        {/* map */}
        {!props?.mapLoading && (
          <CustomMap
            map_id="dashboad_map"
            lat={
              props?.data?.location?.latitude ??
              Number(
                props?.mapData?.[0]?.address?.latitude ?? location?.latitude
              )
            }
            lang={
              props?.data?.location?.longitude ??
              Number(
                props?.mapData?.[0]?.address?.longitude ?? location?.longitude
              )
            }
            radius={0}
            list={props?.mapData ?? []}
            zoom={10}
            hideControl={true}
            status={
              props?.tabNameState === "Occupied"
                ? "Occupied Units"
                : "Vacant Units"
            }
            isDashBoard
            mapIcon={
              props?.tabNameState === "Occupied"
                ? "images/occupiedmap.svg"
                : "images/vacantmap.svg"
            }
            state={state}
            is_zoom={true}
          />
        )}
        {/*  */}
        <Box className={classes.weatherBox}>
          <Typography className={classes.totalUnit}>
            {props?.total ?? 0}
          </Typography>
          <Box className={classes.dotParent}>
            <Box
              className={classes.dot}
              style={{
                backgroundColor:
                  props?.tabNameState === "Occupied" ? "red" : "green",
              }}
            />
            <Box>
              <Typography className={classes.graphDataTitle}>
                Total&nbsp;
                {props?.tabNameState === "Occupied" ? "Occupied" : "Vacant"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <span className={classes.zoomInOut}>
     <div className="zoom-control">
            <div className="plus" onClick={()=>{
              zoomFunction(state+5,true)
            }}>
              <PlusIcon />
            </div>
            <div className="minus" onClick={()=>{
              zoomFunction(state-5,false)
            }}>
              <MinusIcon />
            </div>
       </div>
     </span>
      </div></>
      :
      <Box className={classes.mapRoot}>
        <Typography className={classes.titleFont}>Please Check Your Location</Typography>
      </Box>
      }
     

    </div>
  );
};

// tab component
const TabComponent = (props) => {
  const classes = useStylesCreation(props);
  return (
    <List className={classes.list}>
      {props?.tabList?.map((x) => {
        return (
          <ListItem
            className={
              x === props?.tabNameState ? classes.listText : classes.listText1
            }
            onClick={() => props?.updateState(props?.keyType, x)}
          >
            <Typography className={classes.tabname}>{x}</Typography>
          </ListItem>
        );
      })}
    </List>
  );
};