import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme)=>({
    templateName:{
        fontWeight:"bold",
        fontSize:16,
    },
    templateItem:{
        fontWeight:"bold",
        fontSize:12,
        color:theme.typography.color.tertiary
    },
    itemList:{
        fontWeight:"bold",
        fontSize:12,
        color:theme.typography.color.tertiary,
        textTransform:"uppercase",
        margin:12
    },
    list:{
        overflow:"auto",
        height:"320px"
    },
    avatar:{
        background:"transparent"
    },
    inspectionName:{
        color:theme.typography.color.secondary,
        fontSize:14,
        fontWeight:"bold"
    },
    inspectionQty:{
        color:theme.typography.color.tertiary,
        fontSize:12,
        fontWeight:"bold"
    },
    editbtn:{
        width:"100%",
        '&:hover':{
            background:"transparent"
        }
    }
}))