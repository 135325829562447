import { Grid } from '@mui/material'
import React from 'react'
import { ImageGalleryStyle } from './style'

export const ImageGallery = (props) => {
    const classes = ImageGalleryStyle()
    return (
        <Grid container>
            <Grid item xs={12} minHeight={"400px"}>
                {props?.url ?
                    <img className={classes.img} src={props?.url} alt="url" />
                    :
                    <center style={{ marginTop: "120px" }}>
                        {"No Image found"}
                    </center>
                }
            </Grid>
        </Grid >
    )
}