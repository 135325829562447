import React from 'react';
import CityProperty from '../../assets/cityproperty';
import ContractProperty from '../../assets/contractproperty';
import ImagesProperty from '../../assets/imagesproperty';
import { PropertyTabs, Subheader } from "../../components";
import { useHistory, useLocation } from 'react-router-dom';
import { Routes } from "../../router/routes";
import { unitStyles } from "./style";
import { GovermentDetails, UnitDetail, BankDetails } from "./tabs";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";

export const SalesDetails = () => {
    const { state } = useLocation();
    // states
    const [value, setValue] = React.useState(1);
    const [details, setDetails] = React.useState(null)
    const classes = unitStyles()
    // history
    const history = useHistory()

    // context
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const [currentImage, setCurrentImage] = React.useState("");
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                GetEditData()
            }
        }
        // eslint-disable-next-line
    }, [auth, state?.main?.isView]);

    //openImageViewer
    const openImageViewer = React.useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    //closeImageViewer
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    // tab JSON contant
    const tabTittle = [
        {
            label: 'Sales Agent Basic Details',
            imgSelect: <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
            value: 1,
            className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: 'Governmental Details',
            imgSelect: <ImagesProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
            value: 2,
            className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: 'Banking Details',
            imgSelect: <ContractProperty color={value === 3 ? '#5078E1' : '#98A0AC'} />,
            value: 3,
            className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
        },
    ]

    // edit function

    const handleedit = () => {
        history.push({
            pathname: Routes.salesagent,
            state: {
                main: {
                    companyID: state?.main?.companyID ?? "",
                    EditData: state?.main?.ViewData ?? "",
                    isEdit: true
                },
            },
        });
    }

    // get details api
    const GetEditData = () => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        let datas = {
            tenantId: `${config.tenantId}`,
            company_id: state?.main?.companyID?.value ?? undefined,
            user_id: state?.main?.ViewData ?? ""

        }
        NetworkCall(
            `${config.api_url}/sales-agent/get`,
            NetWorkCallMethods.post,
            datas,
            null,
            true,
            false
        )
            .then((res) => {
                let editData = res?.data?.data
                setDetails(editData)
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            })
            .catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Internal error. Please try again later.",
                });
            });
    }

    const render = () => {
        return <>
            {/* sub nav bar */}
            <Subheader title={details?.first_name} goBack={() => history.goBack()} />
            {/* tabs */}
            <PropertyTabs isEdit={permission?.update} value={value} handleChange={handleChange}
                handleedit={handleedit}
                tabTittle={tabTittle}
                // tab1
                tab1={<UnitDetail data={details} closeImageViewer={closeImageViewer}
                    openImageViewer={openImageViewer}
                    isViewerOpen={isViewerOpen}
                    currentImage={currentImage} />}
                // tab2
                tab2={<GovermentDetails data={details} />}
                // tab3
                tab3={<BankDetails data={details} />}
                showBtns={true}

            />
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    )
}
