import React from "react";
import { withNavBars } from "../../HOCs";
import { OppertunityView } from "./opprtunityView";

class OppertunityViewParent extends React.Component {
    render() {
        return <OppertunityView />
    }
}
const props = {
    boxShadow: false
}

export default withNavBars(OppertunityViewParent, props);
