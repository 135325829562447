import styled from "@mui/material/styles/styled";
import { Typography } from "@mui/material";
import { Bold } from "../../utils";
export const initial = {
  Category: "",
  subCategory: "",
  Unit: "",
  Title: "",
  visitorDate: "",
  Description: "",
  workNo: "",
  workId: "",
  status: true,
  alternativeContact: "",
  PrimaryContact: "",
  Property: "",
  problemSince: "",
  contactName: "",
  member: "",
  workdate:null,
  vendor:"",
  workerStatus:"",
  error: {
    Category: "",
    subCategory: "",
    Unit: "",
    Title: "",
    visitorDate: "",
    Description: "",
    workNo: "",
    workId: "",
    status: "",
    alternativeContact: "",
    PrimaryContact: "",
    Property: "",
    problemSince: "",
    contactName: "",
    member: "",
  },
};
export const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontFamily: Bold,
  color: "#4E5A6B",
  marginBottom: theme.spacing(1),
}));

export const CustomTypographyWorker = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontFamily: Bold,
  color: "#4E5A6B",
  marginBottom: theme.spacing(1),
}));
