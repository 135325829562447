import { Box, Grid } from "@mui/material";
import styled from '@mui/material/styles/styled';
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Subheader } from "../../components";
import { AuthContext, BackdropContext } from "../../contexts";
import { getRoutePermissionNew, getCompanyOption, accessCheckRender } from "../../utils";
import { Routes } from "../../router/routes";
import StepsContent from "./stepsContent";
import { withNamespaces } from "react-i18next";

const CustomPaper = styled('div')(({ theme }) => ({
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 152px)`,
    overflow: "hidden",
    margin: "14px"
}))

const CreateInvoiceFreeText = ({ type = "",t }) => {


    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const history = useHistory()
    const state = useLocation()?.state

    const [companyList, setCompanyList] = React.useState([]);
    const [permission, setPermission] = React.useState({})
    const [selectedCompany, setSelectedCompany] = React.useState({});

    const [invoiceData, setInvoiceData] = React.useState({
        customerList: [],
        selectedAccountDetails: {},
        searchText: "",
        showCustomerList: false,
        accountType: false,
        showRemark: false,
        selectedType: "",
        showVendorList: false,
        step1: false,
        step2: false,
        step3: false
    })

    React.useEffect(() => {
        const _ = getRoutePermissionNew(auth)
        if (_) {
            setPermission(_)
            if (_?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth])


    //   company switcher
    const handleCompanyChange = (e) => {
        setSelectedCompany(e);
    };

    const editAccountDetail = () => {
        setInvoiceData({
            ...invoiceData,
            accountType: !invoiceData?.accountType,
            step1: !invoiceData?.step1
        })
    }

    const createInoice = () => {
        history.push(Routes.freeTextInvoice)
    }

    const render = () => {
        return <Box>
            {type.length === 0 && <Subheader
                title={t("CreateInvoice")}
                goBack={() => history.goBack()}
                select={true}
                options={companyList}
                value={selectedCompany}
                onchange={(e) => {
                    handleCompanyChange(e);
                }}
            />}
            <Grid container sx={{ justifyContent: "center", display: "flex" }}>
                <Grid item md={8}>
                    <CustomPaper>
                        <StepsContent
                            invoiceData={invoiceData}
                            state={state}
                            setInvoiceData={setInvoiceData}
                            editAccountDetail={editAccountDetail}
                            createInoice={createInoice}
                            selectedCompany={selectedCompany}
                        />
                    </CustomPaper>
                </Grid>
            </Grid>
        </Box>
    }
    return (
        <>{accessCheckRender(render, permission)}</>

    )
}

export default withNamespaces("freeTextInvoice")(CreateInvoiceFreeText)