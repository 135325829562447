import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { Bold } from "../../utils";
const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: "16px",
        fontFamily: Bold,
        color: theme.typography.color.primary,
        marginLeft: "8px"
    }
}));
export const DialogHeader = ({ title = "", onClose = () => false }) => {
    const classes = useStyles();
    return (
        <Box display="flex" p={0.5} alignItems="center" className={classes.root}>
            <Box flexGrow={1}>
                <Typography className={classes.title}>{title}</Typography>
            </Box>
            <Box>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>

        </Box>
    );
}
