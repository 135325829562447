import { Box, Button, Container, Grid, Hidden, Typography } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropGoToComponent from '../../assets/propgoto';
import { EnterOtps } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps } from "../../utils";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./style";

export const OtpPage = () => {
  const history = useHistory();
  const size = useWindowDimensions()
  const classes = useStyles(size);
  const alert = React.useContext(AlertContext);
  const [value, setValue] = React.useState("");
  const handleChange = (otp) => {
    setValue(otp);
  };
  const search = useLocation().search;
  const emailId = new URLSearchParams(search).get("data");
  const verifyOtp = (v) => {
    const payload = {
      otp: v,
    };
    // Check phone number
    // eslint-disable-next-line
    var isphone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(emailId);
    // Check email id
    // eslint-disable-next-line
    var email = /^\w+([\.-]?\ w+)*@\w+([\.-]?\ w+)*(\.\w{2,3})+$/.test(emailId);
    if (isphone) {
      payload["mobile_no"] = emailId;
    } else if (email) {
      payload["email_id"] = emailId;
    } else {
      payload["ucid"] = emailId;
    }
    NetworkCall(
      `${config.authapi}/auth/verifyOTP/?token=${localStorage.getItem('authToken')}`,
      NetWorkCallMethods.post,
      payload,
      null,
      false,
      false
    )
      .then((response) => {
        localStorage.setItem(
          LocalStorageKeys.authToken,
          response?.data?.token
        )
        // push client select page
        history.push({
          pathname: Routes.companyselect,
          state: {
            main: {
              clientData: response?.data ?? "",
            },
          },
        });
        return false
      })
      .catch((err) => {
        localStorage.clear();
        history.push(Routes.login)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: " Invalid OTP",
        });
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    verifyOtp(value)
  }
  return (
    <>
      <Hidden smDown>
        <div className={classes.root}>
          <form onSubmit={handleSubmit}>
            <Grid container className={classes.signupform}>
              <Grid item xs={12} className={classes.image}>
                <PropGoToComponent />
              </Grid>
              <Grid item xs={12} marginBottom={"45px"}>
                <Typography className={classes.title}>Enter OTP</Typography>
              </Grid>
              <Grid item xs={12} marginBottom={"16px"}>
                <EnterOtps
                  value={value}
                  handleChange={handleChange}
                  emailId={emailId}
                />
              </Grid>
              <Grid item xs={12} marginBottom={"60px"}>
                <Typography className={classes.verificationText}>
                  Verification code has been sent to your email & mobile number
                </Typography>
              </Grid>
              <Grid item xs={12} marginBottom={"10px"}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.btn}
                  type="submit"
                  disabled={value.length === 4 ? false : true}
                  onClick={handleSubmit}>
                  Verify
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Hidden>
      <Hidden smUp>
        <div>
          <form onSubmit={handleSubmit}>
            <Container maxWidth="sm" style={{ padding: 0 }}>
              <Grid container style={{ height: "100vh", backgroundColor: "white" }}>
                <Grid
                  item
                  className={classes.content}
                  style={{ height: size.height > 650 ? size.height - 82 : 568 }}
                >
                  <Grid item xs={12} className={classes.backgroundImage} />
                  <Grid style={{ padding: "0px 16px 0px 16px" }}>
                    <Box height={"24px"} />
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      justifyContent={"space-between"}
                      alignContent={"center"}
                    >
                      <img
                        src="/images/PA Logo_1.svg"
                        alt="logo"
                        className={classes.img}
                      />
                    </Grid>
                    <Box height={"16px"} />
                    <Grid item xs={12} marginBottom={"16px"}>
                      <Typography className={classes.title}>Enter OTP</Typography>
                    </Grid>
                    <Grid item xs={12} marginBottom={"16px"}>
                      <EnterOtps
                        value={value}
                        handleChange={handleChange}
                        emailId={emailId}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.verificationText}>
                        Verification code has been sent to your email & mobile number
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} alignSelf={"self-end"} style={{ padding: "16px 16px 25px 16px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btn}
                    type="submit"
                    disabled={value.length === 4 ? false : true}
                    onClick={handleSubmit}>
                    Verify
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </form>
        </div>
      </Hidden>
    </>
  );
};
