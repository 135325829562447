import { Button, Dialog, IconButton, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from "../style"
import { TextBox } from "../../../components";

export const Remarks = (props) => {

    const classes = useStyles()

    return <Dialog
        className={classes.acceptDeclineDialog}
        open={props?.acceptDeclineDialogOpen}
        onClose={() => props?.setAcceptDeclineDialogOpen(false)}>
        <div className={classes.viewDialogHeader}>
            <Typography className={classes.viewDialogHeaderTitle}>
                {props?.requestState?.formType === "Accept" ? "Accept Confirmation" :
                    props?.requestState?.formType === "Decline" ? "Decline Confirmation" :
                        "Accept Confirmation"}
            </Typography>
            <IconButton onClick={() => props?.setAcceptDeclineDialogOpen(false)}
                className={classes.viewDialogCloseButton}>
                <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
            </IconButton>
        </div>
        <div className={classes.acceptDeclineDialogBody}>
            <TextBox
                multiline
                isrequired
                isReadonly={props?.requestState?.formType === "view"}
                label="Remarks"
                placeholder="Enter Remarks"
                value={props?.requestState?.acceptDeclineRemarks ?? ""}
                onChange={(e) => props?.updateRequestDialogState("acceptDeclineRemarks", e.target.value)}
                isError={props?.requestState?.error?.acceptDeclineRemarks?.length > 0}
                errorMessage={props?.requestState?.error?.acceptDeclineRemarks} />
        </div>
        <div className={classes.acceptDeclineDialogFooter}>
            <Button fullWidth className={classes.cancelButton}
                onClick={() => {
                    props?.setRequestState({
                        ...props?.requestState,
                        formType: "", acceptDeclineRemarks: ""
                    })
                    props?.setRequestDialogOpen(true)
                    props?.setAcceptDeclineDialogOpen(false)
                }}>
                Cancel
            </Button>
            <Button fullWidth className={classes.acceptDeclineButton}
                onClick={props?.handleAcceptDecline}>
                {props?.requestState?.formType}
            </Button>
        </div>
    </Dialog>
}